import { Divider, Flex } from '@chakra-ui/react';
import React from 'react';

import CellPaletteMoreMenu from 'components/CellPalette/CellPaletteMoreMenu';
import EventGroupTag from 'components/CellPalette/EventGroupTag';
import { isBusinessObjectFieldCellRef } from 'helpers/cells';
import useAppSelector from 'hooks/useAppSelector';
import { prevailingCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';

import ColorPicker from './ColorPicker';

type Props = {
  omitColorPicker?: boolean;
  className?: string;
};

const CellPalette = React.forwardRef<HTMLDivElement, Props>(
  ({ omitColorPicker, className }, ref) => {
    const activeCellSelection = useAppSelector(prevailingCellSelectionSelector);
    // TODO: Once we support setting driver colors in AG Grid, we should be able to rely
    // on this check entirely to determine whether to show the color picker.
    const defaultShowColorPicker =
      activeCellSelection != null && !isBusinessObjectFieldCellRef(activeCellSelection.activeCell);
    const showColorPicker = omitColorPicker ? false : defaultShowColorPicker;

    return (
      <Flex
        direction="row"
        alignItems="center"
        borderRadius="md"
        boxShadow="menu"
        columnGap="8px"
        bgColor="surface"
        py="2px"
        px="8px"
        height="32px"
        ref={ref}
        className={className}
        data-testid="cell-palette"
      >
        <EventGroupTag />
        <Divider orientation="vertical" />
        {showColorPicker ? (
          <>
            <ColorPicker />
            <Divider orientation="vertical" />
          </>
        ) : null}
        <CellPaletteMoreMenu />
      </Flex>
    );
  },
);

export default React.memo(CellPalette);
