import { BlockFilterOperator, ValueType } from 'generated/graphql';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { FormulaTimeRange } from 'types/formula';

export enum FilterValueTypes {
  ENTITY = 'ID',
  FORMULA = 'FORMULA',
  EXT_TABLE = 'EXT_TABLE_COLUMN',
}

const IS_NULL_TEXT = 'is empty';
const IS_NOT_NULL_TEXT = 'is not empty';

export const FILTER_OPERATOR_DISPLAY_TEXT: Partial<
  Record<ValueType | FilterValueTypes, Partial<Record<BlockFilterOperator, string>>>
> = {
  [ValueType.Number]: {
    [BlockFilterOperator.Equals]: 'is',
    [BlockFilterOperator.NotEquals]: 'is not',
    [BlockFilterOperator.GreaterThan]: 'is greater than',
    [BlockFilterOperator.LessThan]: 'is less than',
    [BlockFilterOperator.GreaterThanOrEqualTo]: 'is greater or equal',
    [BlockFilterOperator.LessThanOrEqualTo]: 'is less or equal',
    [BlockFilterOperator.IsNull]: IS_NULL_TEXT,
    [BlockFilterOperator.IsNotNull]: IS_NOT_NULL_TEXT,
  },
  [FilterValueTypes.EXT_TABLE]: {
    [BlockFilterOperator.Equals]: 'is',
    [BlockFilterOperator.NotEquals]: 'is not',
    [BlockFilterOperator.GreaterThan]: 'is greater than',
    [BlockFilterOperator.LessThan]: 'is less than',
    [BlockFilterOperator.GreaterThanOrEqualTo]: 'is greater or equal',
    [BlockFilterOperator.LessThanOrEqualTo]: 'is less or equal',
  },
  [ValueType.Attribute]: {
    [BlockFilterOperator.Equals]: 'is one of',
    [BlockFilterOperator.NotEquals]: 'is none of',
    [BlockFilterOperator.IsNull]: IS_NULL_TEXT,
    [BlockFilterOperator.IsNotNull]: IS_NOT_NULL_TEXT,
  },
  [ValueType.Timestamp]: {
    [BlockFilterOperator.Equals]: 'is',
    [BlockFilterOperator.NotEquals]: 'is not',
    [BlockFilterOperator.GreaterThan]: 'is after',
    [BlockFilterOperator.LessThan]: 'is before',
    [BlockFilterOperator.GreaterThanOrEqualTo]: 'is on or after',
    [BlockFilterOperator.LessThanOrEqualTo]: 'is on or before',
    [BlockFilterOperator.IsNull]: IS_NULL_TEXT,
    [BlockFilterOperator.IsNotNull]: IS_NOT_NULL_TEXT,
  },
  [FilterValueTypes.ENTITY]: {
    [BlockFilterOperator.Equals]: 'is one of',
    [BlockFilterOperator.NotEquals]: 'is none of',
    [BlockFilterOperator.IsNull]: IS_NULL_TEXT,
    [BlockFilterOperator.IsNotNull]: IS_NOT_NULL_TEXT,
  },
  [FilterValueTypes.FORMULA]: {
    [BlockFilterOperator.IsNull]: IS_NULL_TEXT,
    [BlockFilterOperator.IsNotNull]: IS_NOT_NULL_TEXT,
  },
};

export type EntityIdFilter = {
  valueType: FilterValueTypes.ENTITY;
  expected?: string[];
  // Due to how object filtering works, we can't model "not equals" for the
  // "Name" filter. This is because that filer option uses a list of UUIDs of
  // object instances as opposed to an actual filter predicate that can be
  // negated.
  isNotNegatable?: boolean;
} & (
  | { entityType: 'object'; specId: BusinessObjectSpecId }
  | { entityType: 'model' | 'spec' | 'eventGroup' }
);

type NumberFilter = {
  valueType: ValueType.Number;
  expected?: number;
};
type TimestampFilter = {
  valueType: ValueType.Timestamp;
  expected?: FormulaTimeRange;
};
type AttributeFilter = {
  valueType: ValueType.Attribute;
  dimensionId: DimensionId;
  expected?: AttributeId[];
};

type ExtTableFilter = {
  valueType: FilterValueTypes.EXT_TABLE;
  expected?: string;
  extTableColumnId: string;
};

type DatabaseSourceFilter = {
  valueType: ValueType.Attribute;
  dimensionId: DimensionId;
  expected?: AttributeId[];
};

type FormulaFilter = {
  valueType: FilterValueTypes.FORMULA;
  expected?: string[];
  filterKey: 'formula' | 'actualsFormula';
};

// Filter ID is used to uniquely identify the filter. In the case of filtering
// on an object spec, the id is the field spec id.
type FilterItemCommon = {
  filterKey: string;
  label: string;
  operator?: BlockFilterOperator;
  error?: string;
  isNotNullable?: boolean;
};

export type NumberFilterItem = FilterItemCommon & NumberFilter;
export type TimestampFilterItem = FilterItemCommon & TimestampFilter;
export type AttributeFilterItem = FilterItemCommon & AttributeFilter;
type DatabaseSourceFilterItem = FilterItemCommon & DatabaseSourceFilter;
type ExtTableFilterItem = FilterItemCommon & ExtTableFilter;
export type ValueFilterItem = FilterItemCommon &
  (
    | NumberFilterItem
    | TimestampFilterItem
    | AttributeFilterItem
    | ExtTableFilterItem
    | DatabaseSourceFilterItem
  );
export type EntityIdFilterItem = FilterItemCommon & EntityIdFilter;
export type FormulaFilterItem = FilterItemCommon & FormulaFilter;
export type FilterItem =
  | ValueFilterItem
  | EntityIdFilterItem
  | FormulaFilterItem
  | ExtTableFilterItem
  | DatabaseSourceFilterItem;
export type EntityInfo = {
  id: string;
  label: string;
  type: 'object' | 'database' | 'driver' | 'plan';
};
export function isValueFilterItem(item: FilterItem): item is ValueFilterItem {
  return !isEntityIdFilterItem(item) && !isFormulaFilterItem(item);
}

export function isAttributeFilterItem(item: FilterItem): item is AttributeFilterItem {
  return item.valueType === ValueType.Attribute;
}

export function isEntityIdFilterItem(item: FilterItem): item is EntityIdFilterItem {
  return item.valueType === FilterValueTypes.ENTITY;
}

export function isFormulaFilterItem(item: FilterItem): item is FormulaFilterItem {
  return item.valueType === FilterValueTypes.FORMULA;
}

export function filterIsComplete(filter: FilterItem): boolean {
  return (
    filter.operator === BlockFilterOperator.IsNull ||
    filter.operator === BlockFilterOperator.IsNotNull ||
    (filter.expected != null && !(Array.isArray(filter.expected) && filter.expected.length === 0))
  );
}

export function isObjectEntityFilter(
  filter: FilterItem,
): filter is EntityIdFilterItem & { entityType: 'object' } {
  return isEntityIdFilterItem(filter) && filter.entityType === 'object';
}

export function isModelEntityFilter(
  filter: FilterItem,
): filter is EntityIdFilterItem & { entityType: 'model' } {
  return isEntityIdFilterItem(filter) && filter.entityType === 'model';
}

export function isSpectEntityFilter(
  filter: FilterItem,
): filter is EntityIdFilterItem & { entityType: 'spec' } {
  return isEntityIdFilterItem(filter) && filter.entityType === 'spec';
}

export function isEventGroupEntityFilter(
  filter: FilterItem,
): filter is EntityIdFilterItem & { entityType: 'eventGroup' } {
  return isEntityIdFilterItem(filter) && filter.entityType === 'eventGroup';
}
