import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LinkedAccount, SyncStatus, SyncStatusDocument, SyncStatusQuery } from 'generated/graphql';
import { getExtSource } from 'helpers/integrations';
import { RemoteItemFetchData } from 'reduxStore/models/remoteItem';
import { AsyncAppThunkConfig } from 'reduxStore/store';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';

export type FetchedSyncStatus = RemoteItemFetchData & {
  status: SyncStatus | null;
};

export type FetchedSyncMap = NullableRecord<string, FetchedSyncStatus>;

const initialState: FetchedSyncMap = {};

const syncStatusSlice = createSlice({
  name: 'syncStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatus.pending, (state, action) => {
        const key = getIntegrationKey(action.meta.arg.linkedAccount);
        const oldVal = state[key] ?? { status: null, error: null };
        state[key] = { ...oldVal, loading: 'pending' };
      })
      .addCase(fetchStatus.fulfilled, (state, action) => {
        const key = getIntegrationKey(action.meta.arg.linkedAccount);
        const oldVal = state[key] ?? { status: null, error: null };
        state[key] = { ...oldVal, loading: 'succeeded', error: null, status: action.payload };
      })
      .addCase(fetchStatus.rejected, (state, action) => {
        const key = getIntegrationKey(action.meta.arg.linkedAccount);
        const oldVal = state[key] ?? { status: null, error: null };
        state[key] = { ...oldVal, loading: 'failed', error: action.error };
      });
  },
});

interface FetchStatusArgs {
  linkedAccount: LinkedAccount;
}

export const fetchStatus = createAsyncThunk<SyncStatus, FetchStatusArgs, AsyncAppThunkConfig>(
  'integrations/fetchStatus',
  async ({ linkedAccount }, { extra, getState }) => {
    const { urqlClient } = extra;
    const state = getState();
    const org = selectedOrgSelector(state);
    if (org == null) {
      throw Error('could not find current org');
    }

    const response = await urqlClient.query<SyncStatusQuery>(SyncStatusDocument, {
      orgId: org.id,
      linkedAccountId: linkedAccount.id,
      provider: linkedAccount.integration.provider,
    });

    if (response.error != null) {
      throw response.error;
    }

    const syncStatus = response.data?.integrations?.syncStatus;
    if (syncStatus == null) {
      throw Error('could not fetch sync status for integration');
    }

    return syncStatus;
  },
);

export function getIntegrationKey(linkedAccount: LinkedAccount): string {
  const { category, id } = linkedAccount;
  const source = getExtSource(linkedAccount.integration);
  return `${source}-${category}-${id}`;
}

export default syncStatusSlice.reducer;
