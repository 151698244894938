import { BoxProps } from '@chakra-ui/react';
import React from 'react';

import Badge from 'components/Badge/Badge';
import AttributeFiltersBadges from 'components/Formula/AttributeFiltersBadges';
import FormulaEntityChunk from 'components/Formula/FormulaEntityChunk';
import { DriverFormulaDisplayChunk } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import useAppSelector from 'hooks/useAppSelector';
import { driverIsKpiSelector } from 'selectors/driversSelector';
import Star from 'vectors/Star';

interface Props {
  chunk: DriverFormulaDisplayChunk;
  textColor?: BoxProps['color'];
}

const DriverChunk: React.FC<Props> = ({ chunk, textColor }) => {
  const { driverId, attributeFilters, placeholder, timeRange, timeRangeDisplay, displayName } =
    chunk;
  const isKpi = useAppSelector((state) => driverIsKpiSelector(state, driverId));

  return (
    <FormulaEntityChunk
      label={displayName}
      timeRange={timeRange}
      timeRangeDisplay={timeRangeDisplay}
      icon={
        isKpi ? (
          <div data-testid="star-icon">
            <Star />
          </div>
        ) : undefined
      }
      attributes={
        <AttributeFiltersBadges
          attributeFilters={attributeFilters}
          includeEmpty
          textColor={textColor}
        />
      }
      placeholderBadge={
        placeholder != null ? <Badge text={placeholder} theme="gray" size="xs" /> : null
      }
    />
  );
};

export default DriverChunk;
