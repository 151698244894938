import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'PeaceSignIcon',
  viewBox: '0 0 12 16',
  defaultProps: {
    boxSize: 4,
  },
  d: 'M9.39005 1.2994C8.92909 1.07645 8.42673 1.28247 8.11057 1.79616L4.96892 6.81486L6.20713 7.46124C6.34485 7.52779 6.45832 7.61709 6.5548 7.72257L9.49683 3.00974C9.75266 2.61751 10.0965 1.8174 9.58466 1.42117C9.52119 1.37146 9.4559 1.33125 9.39005 1.2994ZM5.21288 0.0678757C5.14489 0.0412737 5.07114 0.0211801 4.99241 0.00866746C4.35982 -0.0927647 4.04364 0.72026 3.94739 1.18269L2.88167 5.93737C3.1777 5.96618 3.47007 6.04038 3.7437 6.17746C3.95656 6.28413 4.36183 6.50728 4.58851 6.61682L5.76315 1.35758C5.89645 0.767639 5.68881 0.254089 5.21288 0.0678757ZM9.40686 5.79823C8.85599 5.53202 8.40035 5.79481 8.2576 6.1084L6.95006 9.02121C6.71378 9.54026 6.8918 10.1223 7.33993 10.3389C7.78806 10.5554 8.33625 10.3243 8.57252 9.80527L9.9102 6.90702C10.0529 6.59343 9.95772 6.06443 9.40686 5.79823ZM11.4615 7.18268C11.0126 6.96572 10.5286 7.05373 10.2922 7.57307L9.0964 10.1423C8.88067 10.6162 9.07804 11.1788 9.53801 11.4011C9.99798 11.6234 10.544 11.42 10.7598 10.9461L11.8941 8.45407C12.1538 7.88365 11.9077 7.39831 11.4615 7.18268ZM3.55688 6.77962C3.06188 6.51617 2.49998 6.48027 1.9683 6.6665C1.53114 6.82224 1.16311 7.1147 0.903063 7.50143L0.490788 8.40713C-0.722238 11.0719 0.390717 14.2445 2.97702 15.4943C5.56333 16.7442 8.64245 15.5974 9.85547 12.9326L10.243 12.0813C9.92898 12.1511 9.59088 12.1191 9.27964 11.9687C8.82242 11.7477 8.52054 11.3178 8.43138 10.842C8.02072 11.0798 7.52226 11.1194 7.08156 10.9065C6.67117 10.7081 6.39771 10.3268 6.29175 9.8878C5.99404 10.0694 5.58774 10.1499 5.05777 10.015L3.11599 9.46817C3.09818 9.46302 3.0805 9.45778 3.06398 9.4498C2.93453 9.38724 2.85989 9.23343 2.90116 9.08372C2.94613 8.9185 3.11263 8.81887 3.27428 8.86237L5.21068 9.40661C5.74616 9.54421 6.13032 9.39405 6.26891 8.99363C6.3833 8.66114 6.28886 8.19663 5.93209 8.02076L3.55688 6.77962Z',
});
