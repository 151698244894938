import { getMonthKeysForRange } from 'helpers/dates';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  ImpactLoadingSelectorProps,
  entityLoadingAnyMonthInRangeSelector,
  impactLoadingAnyMonthInRangeSelector,
} from 'selectors/calculationsSelector';
import { pageDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { ParametricSelector } from 'types/redux';

export const entityLoadingForPageDateRangeSelector: ParametricSelector<
  FormulaEntityTypedId['id'],
  boolean
> = (state: RootState, id) => {
  const dateTimeRange = pageDateRangeDateTimeSelector(state);
  const monthKeys = getMonthKeysForRange(dateTimeRange[0], dateTimeRange[1]);
  return entityLoadingAnyMonthInRangeSelector(state, { id, monthKeys });
};

export const impactLoadingForPageDateRangeSelector: ParametricSelector<
  Omit<ImpactLoadingSelectorProps, 'monthKey'>,
  boolean
> = (state: RootState, props) => {
  const dateTimeRange = pageDateRangeDateTimeSelector(state);
  const monthKeys = getMonthKeysForRange(dateTimeRange[0], dateTimeRange[1]);
  return impactLoadingAnyMonthInRangeSelector(state, {
    id: props.id,
    monthKeys,
    ignoreEventIds: props.ignoreEventIds,
  });
};
