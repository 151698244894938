import { Text } from '@chakra-ui/react';
import React, { useCallback, useContext, useMemo } from 'react';

import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import ObjectFieldTypeIcon from 'components/ObjectFieldTypeIcon/ObjectFieldTypeIcon';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { businessObjectSpecNonRestrictedFieldsSelector } from 'selectors/businessObjectNameRestrictionsSelector';

const SECTIONS: Section[] = [{ id: 'fields' }, { id: 'none' }];

interface FieldSelectMenuItem extends SelectItem {
  field: { id: BusinessObjectFieldSpecId; name: string } | null;
}

interface Props {
  specId: BusinessObjectSpecId;
  onClose: () => void;
  isThisRef?: boolean;
}

const ObjectSpecPropertyMenu: React.FC<Props> = ({ specId, onClose, isThisRef }) => {
  const { onSelectObjectField } = useContext(FormulaDropdownContext);
  const { blockId } = useBlockContext();
  const fields = useAppSelector((state) =>
    businessObjectSpecNonRestrictedFieldsSelector(state, {
      objectSpecId: specId,
      blockId,
    }),
  );

  const onSelect = useCallback(
    (item: FieldSelectMenuItem) => {
      onSelectObjectField(item.field);
      onClose();
    },
    [onSelectObjectField, onClose],
  );

  const items = useMemo<FieldSelectMenuItem[]>(() => {
    return [
      ...fields.map(({ id, name }) => {
        return {
          id,
          sectionId: 'fields',
          icon: <ObjectFieldTypeIcon fieldSpecId={id} />,
          field: {
            id,
            name,
          },
          name,
        };
      }),
      ...(isThisRef != null && isThisRef
        ? []
        : [
            {
              id: 'none',
              sectionId: 'none',
              field: null,
              name: <Text fontStyle="italic">None</Text>,
            },
          ]),
    ];
  }, [fields, isThisRef]);

  return (
    <SelectMenu items={items} sections={SECTIONS} onSelect={onSelect} onClose={onClose}>
      {BaseSelectMenuItem}
    </SelectMenu>
  );
};

export default ObjectSpecPropertyMenu;
