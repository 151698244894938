import { Flex } from '@chakra-ui/react';
import React from 'react';

import { BackingType } from 'components/AgGridComponents/types/DatabaseColumnDef';
import DisplayValue from 'components/BusinessObjectsEditableValues/DisplayValue';
import { NUMERIC_FONT_SETTINGS } from 'config/styles';
import useAppSelector from 'hooks/useAppSelector';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DisplayConfiguration, Value } from 'reduxStore/models/value';
import { fieldSpecDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';

interface Props {
  fieldSpecId?: BusinessObjectFieldSpecId;
  displayConfiguration?: DisplayConfiguration;
  originalValue: Value;
  newValue: Value;
  backingType?: BackingType;
}

const ValueTransition: React.FC<Props> = ({
  fieldSpecId,
  originalValue,
  newValue,
  displayConfiguration,
  backingType = 'objectField',
}) => {
  const derivedDisplayConfiguration = useAppSelector((state) =>
    displayConfiguration != null
      ? displayConfiguration
      : fieldSpecId != null
        ? fieldSpecDisplayConfigurationSelector(state, fieldSpecId)
        : undefined,
  );

  if (originalValue.type !== newValue.type) {
    return null;
  }

  return (
    <Flex align="center" sx={NUMERIC_FONT_SETTINGS}>
      <DisplayValue
        value={originalValue}
        displayConfiguration={derivedDisplayConfiguration}
        backingType={backingType}
      />
      <Flex fontSize="xs" minWidth="1rem" align="center" justify="center">
        {'->'}
      </Flex>
      <DisplayValue
        value={newValue}
        displayConfiguration={derivedDisplayConfiguration}
        backingType={backingType}
      />
    </Flex>
  );
};

export default React.memo(ValueTransition);
