import { BoxProps, Flex, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import AttributeBadge from 'components/AttributeBadge/AttributeBadge';
import Badge from 'components/Badge/Badge';
import DimensionBadge from 'components/Formula/DimensionBadge';
import { isAttribute } from 'helpers/dimensionalDrivers';
import useAppSelector from 'hooks/useAppSelector';
import { useEmptyAttributesFromFilters } from 'hooks/useEmptyAttributesFromFilters';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { AttributeFilters, isAttributeFiltersEmpty } from 'types/formula';

const AttributeFiltersBadges: React.FC<{
  attributeFilters: AttributeFilters | undefined;
  includeEmpty: boolean;
  textColor?: BoxProps['color'];
}> = ({ attributeFilters, includeEmpty, textColor }) => {
  const dimById = useAppSelector(dimensionsByIdSelector);
  const emptyAttributes = useEmptyAttributesFromFilters(attributeFilters);

  const attributesFiltersIsEmpty = useMemo(
    () => isAttributeFiltersEmpty(attributeFilters),
    [attributeFilters],
  );

  if (attributeFilters == null || attributesFiltersIsEmpty) {
    return null;
  }

  return (
    <Flex columnGap="0.125rem">
      {attributeFilters.matchToSingleResult && <MatchOneBadge textColor={textColor} />}
      {attributeFilters.includeAllContextAttributes && (
        <AllContextAttributesBadge textColor={textColor} />
      )}
      {Object.entries(attributeFilters.byDimId).flatMap(([dimId, attrs]) =>
        attrs.map((attr) =>
          isAttribute(attr) ? (
            <AttributeBadge key={attr.id} attribute={attr} size="xs" textColor={textColor} />
          ) : (
            <DimensionBadge
              key={`${dimId}-${attr}`}
              dimension={dimById[dimId]}
              type={attr}
              textColor={textColor}
            />
          ),
        ),
      )}
      {includeEmpty &&
        emptyAttributes.map((attr) => (
          <AttributeBadge key={attr.id} attribute={attr} size="xs" textColor={textColor} />
        ))}
    </Flex>
  );
};

const AllContextAttributesBadge: React.FC<{ textColor?: BoxProps['color'] }> = React.memo(
  ({ textColor }) => {
    return (
      <Badge
        size="xs"
        text={
          <Text as="span" display="inline-flex" alignItems="center" noOfLines={1} width="full">
            <Text noOfLines={1} fontStyle="italic" px={1} color={textColor}>
              Match all context attributes
            </Text>
          </Text>
        }
        theme="gray"
      />
    );
  },
);

const MatchOneBadge: React.FC<{ textColor?: BoxProps['color'] }> = React.memo(({ textColor }) => {
  return (
    <Badge
      size="xs"
      text={
        <Text as="span" display="inline-flex" alignItems="center" noOfLines={1} width="full">
          <Text noOfLines={1} fontStyle="italic" px={1} color={textColor}>
            Match One
          </Text>
        </Text>
      }
      theme="gray"
    />
  );
});

export default React.memo(AttributeFiltersBadges);
