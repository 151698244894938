import { ParserRuleContext } from 'antlr4ts';
import { ErrorNode } from 'antlr4ts/tree/ErrorNode';
import { TerminalNode } from 'antlr4ts/tree/TerminalNode';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';
import max from 'lodash/max';
import mean from 'lodash/mean';
import memoize from 'lodash/memoize';
import min from 'lodash/min';
import round from 'lodash/round';
import lodashSum from 'lodash/sum';
import { DateTime } from 'luxon';

import { BuiltInDimensionType, CalculationErrorType, ValueType } from 'generated/graphql';
import { arraySameElements } from 'helpers/array';
import {
  DEFAULT_WEEKEND_MASK,
  extractMonthKey,
  formatISOWithoutMs,
  getDateTimeFromMonthKey,
  getFinancialQuarter,
  getFinancialYear,
  getISOTimeWithoutMsFromMonthKey,
  getLuxonUnitFromText,
  getMonthKey,
  getNumMonthsInclusive,
  nextMonthKey,
  parseLiteralDateString,
  weekendMaskStrInvalidReason,
  workDaysBetweenDates,
} from 'helpers/dates';
import { filterMatchesAttr } from 'helpers/dimensionalDrivers';
import { exhaustiveGuard } from 'helpers/exhaustiveGuard';
import {
  AddProductContext,
  AddSubContext,
  AddSubDurationContext,
  AddSubTimestampContext,
  AndExpressionContext,
  ArrayExpressionContext,
  AttributeContext,
  AttributeFilterContext,
  BaseIfContext,
  BlockFilterContext,
  BooleanExpressionContext,
  BuiltInAttributeFilterContext,
  CalculatorContext,
  CalendarFilterContext,
  CoalesceContext,
  CoalesceRefContext,
  CohortRelativeTimeContext,
  ConditionalContext,
  ContextAttributeContext,
  DateContext,
  DateDiffContext,
  DateExpressionContext,
  DateHelpersContext,
  DateRangeContext,
  DateRefContext,
  DateRelativeMonthsContext,
  DateRelativeQuartersContext,
  DateRelativeYearsContext,
  DaysInMonthContext,
  DimDriverFilteredContext,
  DimDriverRefContext,
  DimDriverViewContext,
  DriverContext,
  DriverFilterViewContext,
  DriverGroupFilterContext,
  DriverRefContext,
  DurationUnitContext,
  DurationValueContext,
  EndOfMonthContext,
  ExpressionContext,
  ExpressionOrViewContext,
  ExtDriverContext,
  ExtDriverRefContext,
  ExtObjectContext,
  ExtObjectFieldRefContext,
  ExtObjectRefContext,
  ExtQueryFilterViewContext,
  ExtQueryRefContext,
  IfErrorContext,
  IfErrorRefContext,
  InvalidExpressionContext,
  MatchFilterContext,
  MatchFilterViewContext,
  MinusContext,
  MulDivContext,
  NetWorkDaysContext,
  NullContext,
  NumberContext,
  ObjectContext,
  ObjectFieldFilterContext,
  ObjectFieldRefContext,
  ObjectFilterContext,
  ObjectFilterViewContext,
  ObjectRefContext,
  ObjectSpecRefContext,
  ObjectUUIDFilterContext,
  OrExpressionContext,
  ParenthesisContext,
  PowContext,
  ReduceExpressionsOrViewsContext,
  ReduceableViewsContext,
  ReducerContext,
  ReducerFnContext,
  RelativeContext,
  RelativeFilterContext,
  RoundContext,
  RoundDownContext,
  RoundPlacesContext,
  SimpleExpressionContext,
  SingleTimestampContext,
  StartOfMonthContext,
  StringBooleanExpressionContext,
  StringCalculatorContext,
  StringExpressionContext,
  StringGroupContext,
  StringIfContext,
  SubmodelFilterContext,
  SubmodelRefContext,
  SubmodelViewContext,
  SumProductContext,
  TimeRangeContext,
  TimestampBooleanExpressionContext,
  TimestampCalculatorContext,
  TimestampConditionalContext,
  TimestampExpressionContext,
  TimestampIfContext,
  TimestampValueContext,
  TimestampValueInExpressionsContext,
  UserAttributeFilterContext,
  UuidGroupContext,
  VariableRelativeTimeContext,
} from 'helpers/formulaEvaluation/ForecastCalculator/CalculatorParser';
import { Listener } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { CacheKey } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaCalculationContext } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCalculationContext';
import {
  FilterCacheKey,
  getEmptyMonthValues,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import {
  EvaluatorDriver,
  FormulaEntityTypedId,
  ReferenceEvaluator,
} from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { getNumber } from 'helpers/number';
import { getObjectFieldUUID } from 'helpers/object';
import { isNotNull } from 'helpers/typescript';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { Attribute, DimensionId } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { EventId } from 'reduxStore/models/events';
import {
  AttributeValue,
  EXPLICIT_NULL,
  EXPLICIT_NULL_TYPE,
  ExplicitNull,
  NullableValue,
  NumberValue,
  ObjectFieldEvaluation,
  ObjectSpecEvaluation,
  TimestampValue,
  Value,
  toAttributeValue,
  toNumberValue,
  toValueType,
} from 'reduxStore/models/value';
import {
  DateRange,
  ValueOrCalculationError,
  isAllNonCalculationError,
  isCalculationError,
} from 'types/dataset';
import { MonthKey } from 'types/datetime';
import {
  ANY_ATTR,
  COHORT_MONTH,
  DynamicAttributeFilterOption,
  FormulaBooleanOperator,
  NO_ATTR,
} from 'types/formula';

const memoGetMonthKey = memoize(
  (relativeString: string, monthKey: MonthKey): string => {
    const relativeInt = parseInt(relativeString);
    const relativeMonth = getDateTimeFromMonthKey(monthKey).plus({
      months: relativeInt,
    });
    return getMonthKey(relativeMonth);
  },
  (relativeString: string, monthKey: MonthKey) => [relativeString, monthKey].join(),
);
const errorStringForListOfEvaluations = (evals: Array<ValueOrCalculationError | undefined>) => {
  return evals
    .map((e) => (isCalculationError(e) ? e.details : undefined))
    .filter(isNotNull)
    .join(', ');
};

export const getDurationUnit = (ctx: DurationValueContext): string => {
  return ctx.durationUnit().text.slice(2);
};

const filterMatchesRelativeTime = (
  filter: Array<
    | string
    | {
        type: DynamicAttributeFilterOption | typeof COHORT_MONTH;
      }
  >,
  attrId: string | null,
  monthsSinceStart: number | undefined,
  lastMonth: number,
) => {
  const cohortAttrId = filter.find((f) => !isString(f) && f.type === COHORT_MONTH) != null;
  if (cohortAttrId && monthsSinceStart != null) {
    if (attrId === monthsSinceStart.toString()) {
      return true;
    }
    if (monthsSinceStart > lastMonth && attrId === lastMonth.toString()) {
      return true;
    }
  }
  const filterIds = filter.map((id) => (isString(id) ? id : id.type));
  return filterMatchesAttr(filterIds, false, attrId, undefined);
};

type ForecastCalculatorListenerArgs = {
  evaluator: ReferenceEvaluator;
  context?: FormulaCalculationContext;
  entityId: FormulaEntityTypedId;
  monthKey: string;
  visited: Set<CacheKey>;
  ignoreEventIds?: Set<EventId>;
  cacheKey: CacheKey;
  newlyAddedCacheKeys: Set<CacheKey>;
  recentlyEditedObjectIds?: BusinessObjectId[];
};

type DimDriverFilter = {
  type: 'dimDriver';
  dimId: string;
  attrId: string | { type: DynamicAttributeFilterOption | typeof COHORT_MONTH };
  operator: FormulaBooleanOperator;
};

type UUIDFilter = {
  type: 'uuid';
  uuids: string[];
  operator: FormulaBooleanOperator;
};

type ObjectFieldFilter = {
  type: 'objectField';
  fieldRef: FieldSpecRef;
  values: Array<string | undefined>;
  operator: FormulaBooleanOperator;
};

type ObjectFilter = UUIDFilter | ObjectFieldFilter;

const isUUIDFilter = (value: ObjectFilter): value is UUIDFilter => value.type === 'uuid';

const isObjectFieldFilter = (value: ObjectFilter): value is ObjectFieldFilter =>
  value.type === 'objectField';

type FieldSpecRef = {
  id: BusinessObjectFieldSpecId;
  dateRange: DateRange;
};

type ExtFieldSpecRef = {
  dateRange: DateRange;
};

type DimensionalEvaluation = {
  attributes: Attribute[];
  evaluation: () => Array<NumberValue | undefined>;
};

type GroupedDriverEvaluation = {
  driverGroupId: DriverGroupId;
  evaluation: () => Array<NumberValue | undefined>;
};

type BasicEvaluation = Array<Value | ExplicitNull | undefined>;

// Evaluations that result in some collection of "Value"s should end up here.
//
// TODO: It would be great if we could refactor this a bit to come up with a
// more genearlized representation. We need to ensure that we preserve the
// "deferred evaluation" that some of these use.
//
// TODO: Do we need the deferred evaluations now that we don't utilize it for
// preventing incorrect dependencies?
type EvalStackItem =
  | { type: 'basic'; evaluation: BasicEvaluation | undefined }
  | ({ type: 'dimensional' } & { evaluations: DimensionalEvaluation[] | undefined })
  | ({ type: 'group' } & { evaluations: GroupedDriverEvaluation[] | undefined })
  | ({ type: 'objectField' } & { evaluations: ObjectFieldEvaluation[] | undefined });

export class ForecastCalculatorListener implements Listener<ValueOrCalculationError | undefined> {
  private stack: Array<EvalStackItem | undefined>;
  private argumentStack: unknown[];
  private objectSpecStack: ObjectSpecEvaluation[];
  private errorStack: Array<{ err: CalculationErrorType; details?: string }>;
  private evaluator: ReferenceEvaluator;
  private context?: FormulaCalculationContext;
  private monthKey: string;
  private cacheKey: CacheKey;

  private visited: Set<CacheKey>;
  private entityId: FormulaEntityTypedId;
  private monthsSinceEntityCohortStart: number | undefined;
  private ignoreEventIds?: Set<EventId>;
  private newlyAddedCacheKeys: Set<CacheKey>;

  constructor({
    evaluator,
    context,
    entityId,
    monthKey,
    visited,
    ignoreEventIds,
    cacheKey,
    newlyAddedCacheKeys,
  }: ForecastCalculatorListenerArgs) {
    this.stack = [];
    this.objectSpecStack = [];
    this.argumentStack = [];
    this.errorStack = [];
    this.evaluator = evaluator;
    this.context = context;
    this.monthKey = monthKey;
    this.visited = visited;
    this.entityId = entityId;
    this.ignoreEventIds = ignoreEventIds;
    this.cacheKey = cacheKey;
    this.newlyAddedCacheKeys = newlyAddedCacheKeys;

    const driverCohortMonth =
      this.entityId.type === 'driver'
        ? this.evaluator.getDriverCohortMonth(this.entityId.id)
        : undefined;

    this.monthsSinceEntityCohortStart =
      driverCohortMonth == null
        ? undefined
        : getNumMonthsInclusive(driverCohortMonth, this.monthKey) - 1;
  }
  enterDate?: ((ctx: DateContext) => void) | undefined;
  enterDateRelativeMonths?: ((ctx: DateRelativeMonthsContext) => void) | undefined;
  enterDateRelativeQuarters?: ((ctx: DateRelativeQuartersContext) => void) | undefined;
  enterDateRelativeYears?: ((ctx: DateRelativeYearsContext) => void) | undefined;
  enterVariableRelativeTime?: ((ctx: VariableRelativeTimeContext) => void) | undefined;
  enterDateRange?: ((ctx: DateRangeContext) => void) | undefined;
  exitDateRange?: ((ctx: DateRangeContext) => void) | undefined;
  enterCohortRelativeTime?: ((ctx: CohortRelativeTimeContext) => void) | undefined;
  enterCalendarFilter?: ((ctx: CalendarFilterContext) => void) | undefined;
  enterRelativeFilter?: ((ctx: RelativeFilterContext) => void) | undefined;
  enterDaysInMonth?: ((ctx: DaysInMonthContext) => void) | undefined;
  enterDateDiff?: ((ctx: DateDiffContext) => void) | undefined;
  enterNetWorkDays?: ((ctx: NetWorkDaysContext) => void) | undefined;
  enterObjectUUIDFilter?: ((ctx: ObjectUUIDFilterContext) => void) | undefined;
  enterObjectFieldFilter?: ((ctx: ObjectFieldFilterContext) => void) | undefined;
  enterSingleTimestamp?: ((ctx: SingleTimestampContext) => void) | undefined;
  exitSingleTimestamp?: ((ctx: SingleTimestampContext) => void) | undefined;
  enterAddSubTimestamp?: ((ctx: AddSubTimestampContext) => void) | undefined;
  enterAddSubDuration?: ((ctx: AddSubDurationContext) => void) | undefined;
  enterEndOfMonth?: ((ctx: EndOfMonthContext) => void) | undefined;
  enterStartOfMonth?: ((ctx: StartOfMonthContext) => void) | undefined;
  enterTimestampConditional?: ((ctx: TimestampConditionalContext) => void) | undefined;
  exitTimestampConditional?: ((ctx: TimestampConditionalContext) => void) | undefined;
  enterNumber?: ((ctx: NumberContext) => void) | undefined;
  enterNull?: ((ctx: NullContext) => void) | undefined;
  enterDriver?: ((ctx: DriverContext) => void) | undefined;
  exitDriver?: ((ctx: DriverContext) => void) | undefined;
  enterExtDriver?: ((ctx: ExtDriverContext) => void) | undefined;
  exitExtDriver?: ((ctx: ExtDriverContext) => void) | undefined;
  enterReducer?: ((ctx: ReducerContext) => void) | undefined;
  enterAddProduct?: ((ctx: AddProductContext) => void) | undefined;
  exitAddProduct?: ((ctx: AddProductContext) => void) | undefined;
  enterMinus?: ((ctx: MinusContext) => void) | undefined;
  enterParenthesis?: ((ctx: ParenthesisContext) => void) | undefined;
  exitParenthesis?: ((ctx: ParenthesisContext) => void) | undefined;
  enterPow?: ((ctx: PowContext) => void) | undefined;
  enterMulDiv?: ((ctx: MulDivContext) => void) | undefined;
  enterAddSub?: ((ctx: AddSubContext) => void) | undefined;
  enterRound?: ((ctx: RoundContext) => void) | undefined;
  enterRoundDown?: ((ctx: RoundDownContext) => void) | undefined;
  enterRoundPlaces?: ((ctx: RoundPlacesContext) => void) | undefined;
  enterConditional?: ((ctx: ConditionalContext) => void) | undefined;
  enterIfError?: ((ctx: IfErrorContext) => void) | undefined;
  enterCoalesce?: ((ctx: CoalesceContext) => void) | undefined;
  enterObject?: ((ctx: ObjectContext) => void) | undefined;
  exitObject?: ((ctx: ObjectContext) => void) | undefined;
  enterExtObject?: ((ctx: ExtObjectContext) => void) | undefined;
  exitExtObject?: ((ctx: ExtObjectContext) => void) | undefined;
  enterDimDriverFiltered?: ((ctx: DimDriverFilteredContext) => void) | undefined;
  exitDimDriverFiltered?: ((ctx: DimDriverFilteredContext) => void) | undefined;
  enterMatchFilter?: ((ctx: MatchFilterContext) => void) | undefined;
  exitMatchFilter?: ((ctx: MatchFilterContext) => void) | undefined;
  enterDateExpression?: ((ctx: DateExpressionContext) => void) | undefined;
  exitDateExpression?: ((ctx: DateExpressionContext) => void) | undefined;
  enterInvalidExpression?: ((ctx: InvalidExpressionContext) => void) | undefined;
  exitInvalidExpression?: ((ctx: InvalidExpressionContext) => void) | undefined;
  enterTimestampValueInExpressions?:
    | ((ctx: TimestampValueInExpressionsContext) => void)
    | undefined;
  exitTimestampValueInExpressions?: ((ctx: TimestampValueInExpressionsContext) => void) | undefined;
  enterSimpleExpression?: ((ctx: SimpleExpressionContext) => void) | undefined;
  enterAndExpression?: ((ctx: AndExpressionContext) => void) | undefined;
  enterOrExpression?: ((ctx: OrExpressionContext) => void) | undefined;
  enterTimestampBooleanExpression?: ((ctx: TimestampBooleanExpressionContext) => void) | undefined;
  enterStringBooleanExpression?: ((ctx: StringBooleanExpressionContext) => void) | undefined;
  exitStringBooleanExpression?: ((ctx: StringBooleanExpressionContext) => void) | undefined;
  enterCalculator?: ((ctx: CalculatorContext) => void) | undefined;
  exitCalculator?: ((ctx: CalculatorContext) => void) | undefined;
  enterBlockFilter?: ((ctx: BlockFilterContext) => void) | undefined;
  enterTimestampCalculator?: ((ctx: TimestampCalculatorContext) => void) | undefined;
  exitTimestampCalculator?: ((ctx: TimestampCalculatorContext) => void) | undefined;
  enterTimestampExpression?: ((ctx: TimestampExpressionContext) => void) | undefined;
  exitTimestampExpression?: ((ctx: TimestampExpressionContext) => void) | undefined;
  enterStringCalculator?: ((ctx: StringCalculatorContext) => void) | undefined;
  exitStringCalculator?: ((ctx: StringCalculatorContext) => void) | undefined;
  enterStringExpression?: ((ctx: StringExpressionContext) => void) | undefined;
  enterTimestampValue?: ((ctx: TimestampValueContext) => void) | undefined;
  enterDurationValue?: ((ctx: DurationValueContext) => void) | undefined;
  exitDurationValue?: ((ctx: DurationValueContext) => void) | undefined;
  enterDurationUnit?: ((ctx: DurationUnitContext) => void) | undefined;
  exitDurationUnit?: ((ctx: DurationUnitContext) => void) | undefined;
  enterBooleanExpression?: ((ctx: BooleanExpressionContext) => void) | undefined;
  exitBooleanExpression?: ((ctx: BooleanExpressionContext) => void) | undefined;
  enterExpression?: ((ctx: ExpressionContext) => void) | undefined;
  exitExpression?: ((ctx: ExpressionContext) => void) | undefined;
  enterDateHelpers?: ((ctx: DateHelpersContext) => void) | undefined;
  exitDateHelpers?: ((ctx: DateHelpersContext) => void) | undefined;
  enterArrayExpression?: ((ctx: ArrayExpressionContext) => void) | undefined;
  enterSumProduct?: ((ctx: SumProductContext) => void) | undefined;
  enterBaseIf?: ((ctx: BaseIfContext) => void) | undefined;
  exitBaseIf?: ((ctx: BaseIfContext) => void) | undefined;
  enterStringIf?: ((ctx: StringIfContext) => void) | undefined;
  enterTimestampIf?: ((ctx: TimestampIfContext) => void) | undefined;
  enterIfErrorRef?: ((ctx: IfErrorRefContext) => void) | undefined;
  exitIfErrorRef?: ((ctx: IfErrorRefContext) => void) | undefined;
  enterCoalesceRef?: ((ctx: CoalesceRefContext) => void) | undefined;
  exitCoalesceRef?: ((ctx: CoalesceRefContext) => void) | undefined;
  enterDriverRef?: ((ctx: DriverRefContext) => void) | undefined;
  enterDimDriverRef?: ((ctx: DimDriverRefContext) => void) | undefined;
  enterSubmodelRef?: ((ctx: SubmodelRefContext) => void) | undefined;
  enterObjectRef?: ((ctx: ObjectRefContext) => void) | undefined;
  enterExtObjectRef?: ((ctx: ExtObjectRefContext) => void) | undefined;
  enterExtObjectFieldRef?: ((ctx: ExtObjectFieldRefContext) => void) | undefined;
  enterObjectSpecRef?: ((ctx: ObjectSpecRefContext) => void) | undefined;
  enterObjectFieldRef?: ((ctx: ObjectFieldRefContext) => void) | undefined;
  enterExtDriverRef?: ((ctx: ExtDriverRefContext) => void) | undefined;
  enterExtQueryRef?: ((ctx: ExtQueryRefContext) => void) | undefined;
  exitExtQueryRef?: ((ctx: ExtQueryRefContext) => void) | undefined;
  enterExtQueryFilterView?: ((ctx: ExtQueryFilterViewContext) => void) | undefined;
  exitExtQueryFilterView?: ((ctx: ExtQueryFilterViewContext) => void) | undefined;
  enterObjectFilterView?: ((ctx: ObjectFilterViewContext) => void) | undefined;
  enterObjectFilter?: ((ctx: ObjectFilterContext) => void) | undefined;
  exitObjectFilter?: ((ctx: ObjectFilterContext) => void) | undefined;
  enterUserAttributeFilter?: ((ctx: UserAttributeFilterContext) => void) | undefined;
  enterBuiltInAttributeFilter?: ((ctx: BuiltInAttributeFilterContext) => void) | undefined;
  exitBuiltInAttributeFilter?: ((ctx: BuiltInAttributeFilterContext) => void) | undefined;
  enterAttributeFilter?: ((ctx: AttributeFilterContext) => void) | undefined;
  exitAttributeFilter?: ((ctx: AttributeFilterContext) => void) | undefined;
  enterDriverGroupFilter?: ((ctx: DriverGroupFilterContext) => void) | undefined;
  enterSubmodelFilter?: ((ctx: SubmodelFilterContext) => void) | undefined;
  exitSubmodelFilter?: ((ctx: SubmodelFilterContext) => void) | undefined;
  enterDriverFilterView?: ((ctx: DriverFilterViewContext) => void) | undefined;
  enterMatchFilterView?: ((ctx: MatchFilterViewContext) => void) | undefined;
  enterAttribute?: ((ctx: AttributeContext) => void) | undefined;
  exitAttribute?: ((ctx: AttributeContext) => void) | undefined;
  enterContextAttribute?: ((ctx: ContextAttributeContext) => void) | undefined;
  enterRelative?: ((ctx: RelativeContext) => void) | undefined;
  exitRelative?: ((ctx: RelativeContext) => void) | undefined;
  enterDimDriverView?: ((ctx: DimDriverViewContext) => void) | undefined;
  exitDimDriverView?: ((ctx: DimDriverViewContext) => void) | undefined;
  enterSubmodelView?: ((ctx: SubmodelViewContext) => void) | undefined;
  enterReduceExpressionsOrViews?: ((ctx: ReduceExpressionsOrViewsContext) => void) | undefined;
  exitReduceExpressionsOrViews?: ((ctx: ReduceExpressionsOrViewsContext) => void) | undefined;
  enterExpressionOrView?: ((ctx: ExpressionOrViewContext) => void) | undefined;
  exitExpressionOrView?: ((ctx: ExpressionOrViewContext) => void) | undefined;
  enterReducerFn?: ((ctx: ReducerFnContext) => void) | undefined;
  exitReducerFn?: ((ctx: ReducerFnContext) => void) | undefined;
  enterReduceableViews?: ((ctx: ReduceableViewsContext) => void) | undefined;
  exitReduceableViews?: ((ctx: ReduceableViewsContext) => void) | undefined;
  enterTimeRange?: ((ctx: TimeRangeContext) => void) | undefined;
  exitTimeRange?: ((ctx: TimeRangeContext) => void) | undefined;
  enterDateRef?: ((ctx: DateRefContext) => void) | undefined;
  exitDateRef?: ((ctx: DateRefContext) => void) | undefined;
  enterStringGroup?: ((ctx: StringGroupContext) => void) | undefined;
  enterUuidGroup?: ((ctx: UuidGroupContext) => void) | undefined;
  visitTerminal?: ((node: TerminalNode) => void) | undefined;
  visitErrorNode?: ((node: ErrorNode) => void) | undefined;
  enterEveryRule?: ((ctx: ParserRuleContext) => void) | undefined;
  exitEveryRule?: ((ctx: ParserRuleContext) => void) | undefined;

  getResult(): ValueOrCalculationError | undefined {
    const lastErr = this.errorStack.pop();
    if (lastErr != null) {
      // TODO: We should surface more than just the last error we observed.
      return { error: lastErr.err, details: lastErr.details };
    }

    const val = this.popSingleValue();

    if (val == null || val.type === EXPLICIT_NULL_TYPE) {
      return undefined;
    }

    switch (val.type) {
      case ValueType.Number:
        return {
          ...toNumberValue(val.value),
          cacheKey: this.cacheKey,
          objectSpecEvaluations: this.objectSpecStack,
        };
      case ValueType.Attribute:
        return {
          ...val,
          cacheKey: this.cacheKey,
          objectSpecEvaluations: this.objectSpecStack,
        };
      case ValueType.Timestamp: {
        if (val.value == null) {
          return { error: CalculationErrorType.Unexpected, details: 'null value in timestamp' };
        }
        const dateTime = DateTime.fromISO(val.value.toString());
        if (!dateTime.isValid) {
          return { error: CalculationErrorType.Unexpected, details: 'invalid datetime' };
        }
        return {
          ...val,
          cacheKey: this.cacheKey,
          objectSpecEvaluations: this.objectSpecStack,
        };
      }
      default:
        exhaustiveGuard(val);
    }

    return undefined;
  }

  getFilteredObjectIds(): BusinessObjectId[] {
    return this.objectSpecStack.flatMap((o) =>
      o.fieldEvaluations.map((evaluation) => evaluation.objectId),
    );
  }

  exitNumber(ctx: NumberContext) {
    const numberText = ctx.text;
    this.pushScalarNumber(getNumber(numberText));
  }

  exitNull() {
    this.pushExplicitNull();
  }

  exitVariableRelativeTime() {
    const end = this.popSingleNumber();
    const start = this.popSingleNumber();
    if (start == null || end == null) {
      this.pushErr(CalculationErrorType.Unexpected, 'missing start or end for variable time');
      return;
    }
    // this must be an integer or else we will throw
    const startMonthKey = this.getMonthKey(String(start));
    const endMonthKey = this.getMonthKey(String(end));

    // we always use a range, but variable relative time refers to a single month
    this.argumentStack.push(startMonthKey);
    this.argumentStack.push(endMonthKey);
  }

  exitCohortRelativeTime() {
    const end = this.popSingleNumber();
    const start = this.popSingleNumber();
    if (start == null || end == null) {
      this.pushErr(CalculationErrorType.Unexpected, 'missing start or end for cohort time');
      return;
    }
    const cohortMonth = this.evaluator.getDriverCohortMonth(this.entityId.id);

    if (cohortMonth == null) {
      this.pushErr(CalculationErrorType.Unexpected, 'driver is not monthly cohort driver');
      return;
    }

    // this must be an integer or else we will throw
    const startMonthKey = memoGetMonthKey(String(start), cohortMonth);
    const endMonthKey = memoGetMonthKey(String(end), cohortMonth);
    this.argumentStack.push(startMonthKey);
    this.argumentStack.push(endMonthKey);
  }

  exitDate(ctx: DateContext) {
    this.argumentStack.push(ctx.DATE().text);
  }

  exitDateRelativeMonths(ctx: DateRelativeMonthsContext) {
    const relativeNum = ctx.NUMBER()?.text;
    if (relativeNum == null) {
      this.pushErr(CalculationErrorType.MissingEntity, 'missing relative months');
      return;
    }
    const pastMonthKey = this.getMonthKey(`${ctx.SUB()?.text ?? ''}${relativeNum}`);
    this.argumentStack.push(pastMonthKey);
  }

  exitDateRelativeQuarters(ctx: DateRelativeQuartersContext) {
    const relativeNum = ctx.NUMBER()?.text;
    if (relativeNum == null) {
      this.pushErr(CalculationErrorType.MissingEntity, 'missing relative quarters');
      return;
    }
    const monthKeyForStartOfFinancialQuarter = this.getStartOfFinancialQuarter(
      `${ctx.SUB()?.text ?? ''}${relativeNum}`,
    );
    this.argumentStack.push(monthKeyForStartOfFinancialQuarter);
  }

  exitDateRelativeYears(ctx: DateRelativeYearsContext) {
    const relativeNum = ctx.NUMBER()?.text;
    if (relativeNum == null) {
      this.pushErr(CalculationErrorType.MissingEntity, 'missing relative years');
      return;
    }
    const monthKeyForStartOfFinancialYear = this.getStartOfFinancialYear(
      `${ctx.SUB()?.text ?? ''}${relativeNum}`,
    );
    this.argumentStack.push(monthKeyForStartOfFinancialYear);
  }

  exitDriverRef(ctx: DriverRefContext) {
    const driverId = ctx.UUID().text;
    const attrs = this.evaluator.getDriverAttributes(driverId);
    let hasDeletedAttrs = false;
    attrs?.forEach((attr) => {
      if (attr.deleted) {
        this.pushErr(CalculationErrorType.MissingEntity);
        hasDeletedAttrs = true;
        return;
      }

      const dim = this.evaluator.getDim(attr.dimensionId);
      if (dim == null || dim.deleted) {
        hasDeletedAttrs = true;
        this.pushErr(CalculationErrorType.MissingEntity);
      }
    });

    if (hasDeletedAttrs) {
      return;
    }

    const dateRange = this.getDateRange();
    const values = this.getEvaluatedDriver(driverId, dateRange);
    if (values != null) {
      this.pushBasicEval(values);
    }
  }

  exitObjectFieldRef(ctx: ObjectFieldRefContext) {
    const fieldId = ctx.UUID().text;
    const dateRange = this.getDateRange();
    this.argumentStack.push({ id: fieldId, dateRange } as FieldSpecRef);
  }

  exitObjectRef(ctx: ObjectRefContext) {
    const filters =
      ctx.objectFilterView() != null ? (this.argumentStack.pop() as ObjectFilter[]) : [];

    const uuidFilters: UUIDFilter[] = filters.filter(isUUIDFilter) ?? [];
    const fieldFilters: ObjectFieldFilter[] = filters.filter(isObjectFieldFilter) ?? [];

    const fieldRef = this.argumentStack.pop() as FieldSpecRef;

    let targetObject: BusinessObject | undefined;
    const maybeUUID = ctx.UUID();
    if (maybeUUID != null) {
      targetObject = this.evaluator.getBusinessObjectById(maybeUUID.text);
    } else if (ctx.THIS() != null) {
      if (this.entityId.type !== 'objectField') {
        throw new Error('exected evaluation entity to be an object field');
      }
      targetObject = this.evaluator.getBusinessObjectByFieldId(this.entityId.id);
    } else {
      this.pushErr(CalculationErrorType.MissingEntity);
      return;
    }
    if (targetObject == null) {
      this.pushErr(CalculationErrorType.MissingEntity);
      return;
    }

    const targetObjectId = targetObject.id;
    // Skip computation if the targetObjectId does not match any of the uuid filters
    if (
      uuidFilters.length !== 0 &&
      uuidFilters.every((filter) => filter.uuids.every((id) => id !== targetObjectId))
    ) {
      this.pushBasicEval(getEmptyMonthValues(fieldRef.dateRange).monthValues as undefined[]);
      return;
    }

    const targetObjectSpec = this.evaluator.getDatabaseById(targetObject.specId);
    if (targetObjectSpec == null) {
      this.pushErr(CalculationErrorType.MissingEntity);
      return;
    }

    const field = targetObject.fields.find((f) => f.fieldSpecId === fieldRef.id);
    const fieldId = field?.id ?? getObjectFieldUUID(targetObjectId, fieldRef.id);
    if (fieldId == null) {
      this.pushErr(CalculationErrorType.MissingEntity);
      return;
    }

    let entityId: FormulaEntityTypedId = {
      type: 'objectField',
      id: fieldId,
    };
    const formulaProperty = this.evaluator.getDatabaseFormulaPropertyById(fieldRef.id);
    if (formulaProperty?.type === 'driverProperty') {
      const subDriverId = this.evaluator.getSubDriverIdForDriverProperty(
        targetObject.id,
        formulaProperty.id,
      );
      if (subDriverId != null) {
        entityId = { type: 'driver', id: subDriverId };
      }
    }

    const evaluatedOrErrors = this.evaluator.getCalculatedValuesForDateRange({
      context: this.context,
      entityId,
      dateRange: fieldRef.dateRange,
      visited: this.visited,
      ignoreEventIds: this.ignoreEventIds,
      undefinedBeforeEarliestDate: true,
      newlyAddedCacheKeys: this.newlyAddedCacheKeys,
    });

    if (evaluatedOrErrors.some(isCalculationError)) {
      this.pushErr(
        CalculationErrorType.Unexpected,
        `object ref dependency had error: ${errorStringForListOfEvaluations(evaluatedOrErrors)}`,
      );
      return;
    }

    let evaluated = evaluatedOrErrors.map((e) =>
      e != null && !isCalculationError(e) ? e : undefined,
    );

    // Mask computed time series based on field filters
    const [filterMasks, errors] = this.getFilterMasks(
      targetObject,
      fieldFilters,
      getNumMonthsInclusive(fieldRef.dateRange.start, fieldRef.dateRange.end),
    );
    errors.forEach((e) => this.pushErr(...e));
    filterMasks.forEach((mask) => {
      evaluated = this.maskTimeSeries(evaluated, mask);
    });

    this.pushBasicEval(evaluated);
  }

  exitExtObjectFieldRef() {
    const dateRange = this.getDateRange();
    this.argumentStack.push({ dateRange } as FieldSpecRef);
  }

  exitExtObjectRef() {
    const fieldRef = this.argumentStack.pop() as ExtFieldSpecRef;

    if (this.entityId.type !== 'objectField') {
      this.pushErr(CalculationErrorType.Unexpected, `non object field can't reference extObject`);
      return;
    }

    const calculatedValues = this.evaluator.getExtObjectFieldCalculatedValuesForDateRange(
      this.entityId.id,
      fieldRef.dateRange,
    );
    this.pushBasicEval(calculatedValues);
  }

  exitBlockFilter(ctx: BlockFilterContext) {
    this.exitObjectSpecFilteringShared({ type: 'block', ctx });
  }

  exitObjectSpecRef(ctx: ObjectSpecRefContext) {
    this.exitObjectSpecFilteringShared({ type: 'formula', ctx });
  }

  exitObjectSpecFilteringShared(
    options:
      | { type: 'formula'; ctx: ObjectSpecRefContext }
      | { type: 'block'; ctx: BlockFilterContext },
  ) {
    const { type, ctx } = options;
    const uuid = ctx.UUID().text;
    const filters =
      ctx.objectFilterView() != null ? (this.argumentStack.pop() as ObjectFilter[]) : [];

    const uuidFilters: UUIDFilter[] = filters.filter(isUUIDFilter) ?? [];
    const fieldFilters: ObjectFieldFilter[] = filters.filter(isObjectFieldFilter) ?? [];
    const fieldRef =
      type === 'formula' && ctx.objectFieldRef() != null
        ? (this.argumentStack.pop() as FieldSpecRef)
        : null;
    const objectSpec = this.evaluator.getDatabaseById(uuid);

    // N.B. fieldSpecId is not required in the grammar term, so only throw error if
    // an id exists and it does not map to a field in the object.
    const fieldMissing =
      fieldRef != null && !objectSpec?.formulaProperties.some((f) => f.id === fieldRef.id);
    if (objectSpec == null || fieldMissing) {
      this.pushErr(CalculationErrorType.MissingEntity);
      return;
    }

    // Check if field is referencing a dimension that is missing
    const formulaProperty =
      fieldRef != null ? this.evaluator.getDatabaseFormulaPropertyById(fieldRef.id) : null;
    if (formulaProperty != null) {
      let dimensionId;
      if (
        formulaProperty.type === 'fieldSpec' &&
        formulaProperty.fieldSpec.type === ValueType.Attribute
      ) {
        dimensionId = formulaProperty.fieldSpec.dimensionId;
      }
      if (formulaProperty.type === 'dimensionalProperty') {
        dimensionId = formulaProperty.dimensionalProperty.dimension.id;
      }
      if (dimensionId != null) {
        const dim = this.evaluator.getDim(dimensionId);
        if (dim == null || dim.deleted) {
          this.pushErr(CalculationErrorType.MissingEntity);
          return;
        }
      }
    }

    let numMonths = 0;
    if (type === 'formula') {
      const dateRange = ctx.timeRange() != null ? this.getDateRange() : fieldRef?.dateRange;
      if (dateRange != null) {
        numMonths = getNumMonthsInclusive(dateRange.start, dateRange.end);
      }
    }
    if (type === 'block') {
      numMonths = 1;
    }

    const evaluatedObjectFields: ObjectFieldEvaluation[] = [];

    const objectsToEvaluate = this.getPassingObjectsForFilters(
      objectSpec.id,
      objectSpec.objects,
      fieldFilters,
    );

    objectsToEvaluate.forEach((object) => {
      // Skip computation of objects that do not match the uuid filter
      if (
        uuidFilters.length !== 0 &&
        uuidFilters.every((filter) => filter.uuids.every((id) => id !== object.id))
      ) {
        return;
      }

      let evaluation: Array<Value | undefined> = [];
      let subDriverId: DriverId | undefined;
      if (fieldRef == null || formulaProperty == null) {
        // No field ref was provided, so generate a simple existence array of the objects.
        evaluation = new Array<NumberValue>(numMonths).fill(toNumberValue(1));
      } else if (formulaProperty.type === 'dimensionalProperty') {
        // In this case, we are calculating a specific dimensional property on the objects.
        const dimProp = formulaProperty.dimensionalProperty;
        const computedAttribute = this.evaluator.getComputedAttributeForDimensionalProperty(
          object.id,
          dimProp.id,
        );
        evaluation =
          computedAttribute != null
            ? new Array<AttributeValue>(numMonths).fill(toAttributeValue(computedAttribute.id))
            : [];
      } else if (formulaProperty.type === 'fieldSpec') {
        // In this case, we are calculating a specific field on the objects.
        const field = object.fields.find((f) => f.fieldSpecId === fieldRef.id);

        const fieldId = field?.id ?? getObjectFieldUUID(object.id, fieldRef.id);
        evaluation = this.getEvaluatedObjectField(fieldId, fieldRef.dateRange) ?? [];
      } else if (formulaProperty.type === 'driverProperty') {
        const driverId = formulaProperty.driverProperty.driverId;
        const dimDriver = this.evaluator.getDimDriverById(driverId);
        if (dimDriver == null) {
          this.pushErr(CalculationErrorType.MissingEntity, 'driver for object field was missing');
          return;
        }
        if (dimDriver.type !== DriverType.Dimensional) {
          this.pushErr(
            CalculationErrorType.Unexpected,
            'driver not dimensional for dim driver ref',
          );
          return;
        }

        subDriverId = this.evaluator.getSubDriverIdForDriverProperty(object.id, formulaProperty.id);
        if (subDriverId == null) {
          // Objects are not guaranteed to have a matching subdriver for a property. This is
          // technically a valid scenario, although it typically means the model itself is misconfigured.
          return;
        }
        evaluation = this.getEvaluatedDriver(subDriverId, fieldRef.dateRange) ?? [];
      }

      // Mask computed time series based on field filters
      const [filterMasks, errors] = this.getFilterMasks(object, fieldFilters, numMonths);
      errors.forEach((e) => this.pushErr(...e));
      filterMasks.forEach((mask) => {
        evaluation = this.maskTimeSeries(evaluation, mask);
      });

      evaluatedObjectFields.push({
        objectId: object.id,
        fieldSpecId: fieldRef?.id,
        evaluation,
        subDriverId,
      });
    });

    this.pushObjFieldEvals(evaluatedObjectFields);

    const fieldEvaluations = evaluatedObjectFields.filter(({ evaluation }) =>
      evaluation.every((e) => e != null),
    );
    this.objectSpecStack.push({ specId: uuid, fieldEvaluations, evaluation: undefined });
  }

  exitSimpleExpression(ctx: SimpleExpressionContext | TimestampBooleanExpressionContext) {
    const operator = ctx.BOOLEAN_OPERATOR().text;
    const right = this.popSingleValue();
    const left = this.popSingleValue();

    if (left?.type === EXPLICIT_NULL_TYPE || right?.type === EXPLICIT_NULL_TYPE) {
      if (left?.type === EXPLICIT_NULL_TYPE) {
        return this.argumentStack.push(
          this.compareSingleValueToExplicitNull(right?.value, operator),
        );
      }
      return this.argumentStack.push(this.compareSingleValueToExplicitNull(left?.value, operator));
    }

    if (right == null || left == null) {
      // Comparing against a null value should result in false
      return this.argumentStack.push(false);
    }

    if (right.type !== left.type) {
      this.pushErr(
        CalculationErrorType.Unexpected,
        `simple expression mismatch ${left.type} ${right.type}`,
      );
      return this.argumentStack.push(undefined);
    }

    return this.argumentStack.push(this.compareSingleValue(left.value, right.value, operator));
  }

  exitAndExpression() {
    const right = this.argumentStack.pop() as boolean | undefined;
    const left = this.argumentStack.pop() as boolean | undefined;
    if (right == null || left == null) {
      this.argumentStack.push(false);
      return;
    }
    const andResult = left && right;
    this.argumentStack.push(andResult);
  }

  exitOrExpression() {
    const right = this.argumentStack.pop() as boolean | undefined;
    const left = this.argumentStack.pop() as boolean | undefined;
    if (right == null || left == null) {
      this.argumentStack.push(false);
      return;
    }
    const orResult = left || right;
    this.argumentStack.push(orResult);
  }

  exitConditional() {
    this.evaluateConditionalExpression();
    // Peek at top and ensure that the return value is a number.
    // We should create a more standardized way of validating return types once we have
    // more non-numeric expressions.
    const num = this.popSingleNumber();
    this.pushScalarNumber(num);
  }

  exitIfError() {
    const right = this.popSingleNumber();
    const left = this.popSingleNumber();
    if (left != null && Number.isFinite(left)) {
      this.pushScalarNumber(left);
    } else {
      this.pushScalarNumber(right);
    }
  }

  // TODO: This should be combined with #exitConditional() once we have a better way of standardizing
  // the return type of general expressions.
  exitStringIf() {
    this.evaluateConditionalExpression();
  }

  // TODO: This should be combined with #exitConditional() once we have a better way of standardizing
  // the return type of general expressions.
  exitTimestampIf() {
    this.evaluateConditionalExpression();
  }

  evaluateConditionalExpression() {
    const expressionResult = this.argumentStack.pop() as boolean | undefined;

    if (expressionResult == null) {
      this.pushScalarValue(undefined);
      return;
    }

    if (expressionResult) {
      // N.B. make sure we pop the right eval, even though we aren't using it
      this.popEvalItem();
      const left = this.popSingleValue();
      this.pushScalarValue(left);
    } else {
      const right = this.popSingleValue();
      // // N.B. make sure we pop the left eval, even though we aren't using it
      this.popEvalItem();
      this.pushScalarValue(right);
    }
  }

  exitCoalesce(ctx: CoalesceContext) {
    const numExpressions = ctx.coalesceRef().expression().length;
    const vals = Array.from(
      { length: numExpressions },
      // this _has_ to be an arrow function because of `this` nonsense
      () => this.popSingleNumber(),
    ).reverse();
    const retVal = vals.find(isNotNull);
    this.pushScalarNumber(retVal);
  }

  exitReducer(ctx: ReducerContext) {
    const numExpressions = ctx.reduceExpressionsOrViews().expressionOrView().length;
    const reducer = ctx.reduceExpressionsOrViews().reducerFn().text;
    if (!isValidReducerFn(reducer)) {
      this.pushScalarNumber(undefined);
      return;
    }
    this.handleArithmaticReducer(numExpressions, reducer);
  }

  // -- HACK --
  // We need to be able to support operations like =sumproduct(Venues.TPV, 1 - Venues.AttachRate)
  // but this will require a more significant overhaul of the grammar &
  // calculator. So, we are adding in a hack to allow for these formulas for
  // now and will make it more robust after.
  exitArrayExpression(ctx: ArrayExpressionContext) {
    // Don't handle object spec ref here
    if (ctx.objectSpecRef() != null) {
      return;
    }
    const numNode = ctx.NUMBER();
    const arrayNode = ctx.arrayExpression();
    const isSub = !!ctx.SUB();
    const op = (l: number, r: number) => (isSub ? l - r : l + r);

    if (numNode == null || arrayNode == null) {
      this.pushObjFieldEvals(undefined);
      return;
    }

    const numOnLeft = numNode.symbol < arrayNode.start;

    const num = getNumber(numNode.text);
    if (isNaN(num)) {
      this.pushObjFieldEvals(undefined);
      return;
    }

    const evaluations = this.popObjFieldEvals();
    const result = (evaluations ?? []).map((objEval) => ({
      ...objEval,
      evaluation: objEval.evaluation.map((v) => {
        if (v == null || v.type !== ValueType.Number) {
          return undefined;
        }
        return toNumberValue(numOnLeft ? op(num, v.value) : op(v.value, num));
      }),
    }));

    this.pushObjFieldEvals(result);
  }

  exitSumProduct(ctx: SumProductContext) {
    // basic drivers
    let values1: Array<NumberValue | undefined> | undefined;
    let values2: Array<NumberValue | undefined> | undefined;
    let isObjectSpecEvaluation = false;
    const dimRefs = ctx.dimDriverRef();
    const basicRefs = ctx.driverRef();
    const arrayExpressions = ctx.arrayExpression();

    if (dimRefs.length === 2) {
      const dimEval1 = this.popDimensionalEvals();
      const dimEval2 = this.popDimensionalEvals();
      if (dimEval1 == null || dimEval2 == null) {
        this.pushErr(CalculationErrorType.MissingEntity);
        return;
      }
      values1 = [];
      values2 = [];
      for (const dimEval of dimEval1) {
        const { attributes } = dimEval;
        const evaluation = dimEval.evaluation();
        // Can only have 1 month if using sumProduct on a dim driver

        const match = dimEval2.find((e) =>
          arraySameElements(
            e.attributes.map((a) => a.id),
            attributes.map((a) => a.id),
          ),
        );

        const matchEval = match?.evaluation();
        if (evaluation.length !== 1 || matchEval?.length !== 1) {
          this.pushErr(CalculationErrorType.Unexpected);
          return;
        }

        values1.push(evaluation[0]);
        values2.push(matchEval[0]);
      }
    } else if (dimRefs.length === 1 && basicRefs.length === 1) {
      const dimRef = dimRefs[0];
      const basicRef = basicRefs[0];
      let dimEval: DimensionalEvaluation[] | undefined;
      let basicEval: Array<NumberValue | undefined> | undefined;

      if (dimRef.start < basicRef.start) {
        basicEval = this.popBasicNumericEval();
        dimEval = this.popDimensionalEvals();
      } else {
        dimEval = this.popDimensionalEvals();
        basicEval = this.popBasicNumericEval();
      }
      const evaluation = dimEval?.map((e) => e.evaluation());

      if (evaluation?.find((e) => e.length !== 1) != null) {
        this.pushErr(CalculationErrorType.Unexpected);
        return;
      }

      values1 = evaluation?.map((e) => e[0]);
      values2 = basicEval;
    } else if (arrayExpressions.length === 2) {
      const objectFieldEval1 = this.popObjFieldEvals();
      const objectFieldEval2 = this.popObjFieldEvals();
      isObjectSpecEvaluation = true;
      if (objectFieldEval1 == null || objectFieldEval2 == null) {
        this.pushErr(CalculationErrorType.MissingEntity);
        return;
      }

      values1 = [];
      values2 = [];
      for (const objFieldEval of objectFieldEval1) {
        const { evaluation } = objFieldEval;
        // Can only have 1 month if using sumProduct on a dim driver

        const match = objectFieldEval2.find(
          (otherEval) => otherEval.objectId === objFieldEval.objectId,
        );

        if (evaluation.length !== 1 || match?.evaluation.length !== 1) {
          this.pushErr(CalculationErrorType.Unexpected);
          return;
        }

        // Object fields can be undefined at some points of time (e.g. before
        // an object's start date) and so we need to handle this case
        // gracefully.
        const eval1 = evaluation[0] ?? toNumberValue(0);
        const eval2 = match.evaluation[0] ?? toNumberValue(0);

        if (eval1.type !== ValueType.Number || eval2.type !== ValueType.Number) {
          this.pushErr(CalculationErrorType.Unexpected, 'sum product without two numbers');
          return;
        }

        values1.push(eval1);
        values2.push(eval2);
      }
    } else {
      values1 = this.popBasicNumericEval();
      values2 = this.popBasicNumericEval();
    }

    if (values1 == null || values2 == null) {
      this.pushErr(CalculationErrorType.MissingEntity);
      return;
    }
    if (values1.length !== values2.length) {
      this.pushErr(CalculationErrorType.Unexpected);
      return;
    }

    const sumProduct = values1.reduce<number>(function (sum, v1, index) {
      const v2 = values2 != null ? values2[index] : undefined;
      if (v1 == null || v2 == null) {
        return sum;
      }
      return sum + v1.value * v2.value;
    }, 0);

    if (isObjectSpecEvaluation) {
      const objectSpecEvaluation = this.objectSpecStack.pop();
      if (objectSpecEvaluation != null) {
        objectSpecEvaluation.evaluation = sumProduct;
        this.objectSpecStack.push(objectSpecEvaluation);
      }
    }

    this.pushScalarNumber(sumProduct);
  }

  exitRound() {
    const numToRound = this.popSingleNumber();
    if (numToRound != null) {
      // Math.round rounds negative numbers differently than positive numbers.
      // Ex: It will round 10.5 to 11, but -10.5 to -10.
      // We don't want this behavior. Store the sign and round the absolute value.
      const sign = numToRound < 0 ? -1 : 1;
      this.pushScalarNumber(sign * Math.round(Math.abs(numToRound)));
    } else {
      this.pushScalarNumber(undefined);
    }
  }

  exitRoundDown() {
    const numToRound = this.popSingleNumber();
    if (numToRound != null) {
      this.pushScalarNumber(Math.floor(numToRound));
    } else {
      this.pushScalarNumber(undefined);
    }
  }

  exitRoundPlaces() {
    const places = this.popSingleNumber();
    const numToRound = this.popSingleNumber();
    if (numToRound != null && places != null) {
      this.pushScalarNumber(round(numToRound, places));
    } else {
      this.pushScalarNumber(undefined);
    }
  }

  exitAddSub(ctx: AddSubContext) {
    const isSub = !!ctx.SUB();
    const right = this.popSingleNumber();
    const left = this.popSingleNumber();
    // anything + or - undefined stays undefined
    if (right == null || left == null) {
      this.pushScalarNumber(undefined);
    } else if (isSub) {
      this.pushScalarNumber(left - right);
    } else {
      this.pushScalarNumber(left + right);
    }
  }

  exitMulDiv(ctx: MulDivContext) {
    const isDivide = !!ctx.DIV();
    const right = this.popSingleNumber();
    const left = this.popSingleNumber();
    if (right == null || left == null) {
      this.pushScalarNumber(undefined);
    } else if (isDivide) {
      this.pushScalarNumber(left / right);
    } else {
      this.pushScalarNumber(left * right);
    }
  }

  exitPow() {
    const exponent = this.popSingleNumber();
    const base = this.popSingleNumber();
    if (base == null || exponent == null) {
      this.pushScalarNumber(undefined);
    } else {
      this.pushScalarNumber(Math.pow(base, exponent));
    }
  }

  exitMinus() {
    const value = this.popSingleNumber();
    if (value != null) {
      this.pushScalarNumber(-1 * value);
    } else {
      this.pushScalarNumber(undefined);
    }
  }

  exitUserAttributeFilter(ctx: UserAttributeFilterContext) {
    const dimId = ctx.UUID(0).text;
    const hasRhs = ctx.UUID(1) != null;
    let attrId: string | null = null;
    if (hasRhs) {
      attrId = ctx.UUID(1).text;
    }
    const attrType = ctx.ANY() != null ? ANY_ATTR : NO_ATTR;
    const filter: DimDriverFilter = {
      type: 'dimDriver',
      dimId,
      attrId: attrId == null ? { type: attrType } : attrId,
      operator: FormulaBooleanOperator.Equals,
    };
    this.argumentStack.push(filter);
  }

  exitCalendarFilter(ctx: CalendarFilterContext) {
    const attrId = ctx.DATE()?.text;

    const attrType = ctx.ANY() != null ? ANY_ATTR : NO_ATTR;
    const filter: DimDriverFilter = {
      type: 'dimDriver',
      dimId: BuiltInDimensionType.CalendarTime,
      attrId: attrId == null ? { type: attrType } : attrId,
      operator: FormulaBooleanOperator.Equals,
    };
    this.argumentStack.push(filter);
  }

  exitRelativeFilter(ctx: RelativeFilterContext) {
    const hasRhs = ctx.NUMBER() != null;
    let attrId: string | null = null;

    if (hasRhs) {
      const number = this.argumentStack.pop() as number;
      attrId = number.toString();
    }

    const attrType =
      ctx.COHORT_MONTH() != null ? COHORT_MONTH : ctx.ANY() != null ? ANY_ATTR : NO_ATTR;
    if (ctx.COHORT_MONTH() != null) {
      // Can only use this within calendar cohorted drivers

      if (this.entityId.type !== 'driver') {
        this.pushErr(CalculationErrorType.Unexpected);
        return;
      }
      const cohortMonth = this.evaluator.getDriverCohortMonth(this.entityId.id);

      if (cohortMonth == null) {
        this.pushErr(CalculationErrorType.Unexpected);
        return;
      }
    }
    const filter: DimDriverFilter = {
      type: 'dimDriver',
      dimId: BuiltInDimensionType.RelativeTime,
      attrId: attrId == null ? { type: attrType } : attrId,
      operator: FormulaBooleanOperator.Equals,
    };
    this.argumentStack.push(filter);
  }

  exitObjectUUIDFilter(ctx: ObjectUUIDFilterContext) {
    const uuids = ctx.UUID().map((uuid) => uuid.text);
    const filter: UUIDFilter = { type: 'uuid', uuids, operator: FormulaBooleanOperator.Equals };
    this.argumentStack.push(filter);
  }

  exitDriverGroupFilter(ctx: DriverGroupFilterContext) {
    const driverGroupId = ctx.UUID().text;
    const filter: UUIDFilter = {
      type: 'uuid',
      uuids: [driverGroupId],
      operator: FormulaBooleanOperator.Equals,
    };
    this.argumentStack.push(filter);
  }

  exitObjectFieldFilter(ctx: ObjectFieldFilterContext) {
    const expectedValues: Array<string | undefined> = [];
    if (ctx.stringGroup() != null) {
      expectedValues.push(...(this.argumentStack.pop() as string[]));
    }
    if (ctx.uuidGroup() != null) {
      const uuids = this.argumentStack.pop() as string[];
      expectedValues.push(...uuids);
    }
    if (ctx.timeRange() != null) {
      expectedValues.push(getDateTimeFromMonthKey(this.getDateRange().start).toISO());
    }
    if (ctx.attributeGroup() != null) {
      // Single UUIDs are already handled by ctx.uuidGroup()
      // We're not handling the new contextAttribute grammar in the frontend
    }
    if (ctx.NULL() != null) {
      expectedValues.push(undefined);
    }
    const operator = ctx.BOOLEAN_OPERATOR().text as FormulaBooleanOperator;
    const fieldRef = this.argumentStack.pop() as FieldSpecRef;

    if (operator == null) {
      this.pushErr(CalculationErrorType.MissingEntity);
      return;
    }

    const filter: ObjectFieldFilter = {
      type: 'objectField',
      fieldRef,
      values: expectedValues,
      operator,
    };

    this.argumentStack.push(filter);
  }

  exitObjectFilterView(ctx: ObjectFilterViewContext) {
    const filterCount = ctx.objectFilter().length;
    const filters = this.getLastNArgumentsFromStack(filterCount) as ObjectFilter[];
    // We pop all individual filters and push the entire list as a single element onto the stack
    this.argumentStack.push(filters);
  }

  exitUuidGroup(ctx: UuidGroupContext) {
    this.argumentStack.push(ctx.UUID().map((s) => s.text));
  }

  exitExtDriverRef(ctx: ExtDriverRefContext) {
    const timeRange = this.getDateRange();
    const id = ctx.UUID().text;

    const values = this.evaluator
      .getExtDriverValues(id, timeRange)
      .map((value) => (isCalculationError(value) ? undefined : value));

    if (!this.isNumericTimeSeries(values)) {
      this.pushErr(CalculationErrorType.Unexpected, 'ext driver should have numeric time series');
      return;
    }

    this.pushBasicEval(values);
  }

  getEvaluatedSubdriver(
    dimDriver: EvaluatorDriver,
    subDriverId: DriverId,
    dateRange: DateRange,
  ): Array<NumberValue | AttributeValue | TimestampValue | undefined> | undefined {
    const evaluatedOrErrors = this.evaluator.getCalculatedValuesForDateRange({
      context: this.context,
      entityId: { type: 'driver', id: subDriverId },
      dateRange,
      visited: this.visited,
      ignoreEventIds: this.ignoreEventIds,
      newlyAddedCacheKeys: this.newlyAddedCacheKeys,
    });

    // if evaluating subdriver resulted in error, return error but continue evaluating rest
    // of the subdrivers.
    if (evaluatedOrErrors.some(isCalculationError)) {
      this.pushErr(
        CalculationErrorType.Unexpected,
        `error evaluating subdriver: ${errorStringForListOfEvaluations(evaluatedOrErrors)}`,
      );
      return undefined;
    }

    const evaluated = evaluatedOrErrors.map((e) => e as Value | undefined);
    return evaluated;
  }

  exitDriverFilterView(ctx: DriverFilterViewContext) {
    const filterCount = ctx.attributeFilter().length;
    const filters = this.getLastNArgumentsFromStack(filterCount) as DimDriverFilter[];
    const subdriversTimeSeries = this.popDimensionalEvals();
    if (subdriversTimeSeries === undefined) {
      this.pushScalarNumber(undefined);
      return;
    }
    if (subdriversTimeSeries.length === 0) {
      this.pushScalarNumber(0);
      return;
    }

    const filterByDimId = mapValues(groupBy(filters, 'dimId'), (fs) => fs.map((f) => f.attrId));

    // Check for deleted dimensions
    Object.keys(filterByDimId).some((dimId) => {
      const dim = this.evaluator.getDim(dimId);
      if (dim == null || dim.deleted) {
        this.pushErr(CalculationErrorType.MissingEntity);
        return true;
      }
      return false;
    });

    let subdriversEvaluated = subdriversTimeSeries.filter((ts) => {
      const hasEveryAttr = Object.entries(filterByDimId).every(([dimId, attrIds]) => {
        // Do relative time filters in their own step
        // Relative time needs to know what subdrivers match so that it can determine the max relative month
        if (dimId === BuiltInDimensionType.RelativeTime) {
          return true;
        }
        const tsAttrId = ts.attributes.find((el) => el.dimensionId === dimId)?.id ?? null;
        const filterIds = attrIds.map((attrId) => (isString(attrId) ? attrId : attrId.type));
        return filterMatchesAttr(filterIds, false, tsAttrId, this.getContextAttribute(dimId)?.id);
      });

      return hasEveryAttr;
    });

    const hasRelTimeFilter = Object.keys(filterByDimId).includes(BuiltInDimensionType.RelativeTime);
    if (hasRelTimeFilter) {
      // Get relative month from matching subdrivers M0, M1, M2... and get max
      const relativeMonths = subdriversEvaluated.map((ts) =>
        parseInt(
          ts.attributes
            .find((a) => a.dimensionId === BuiltInDimensionType.RelativeTime)
            ?.value.toString() ?? '0',
        ),
      );
      const lastMonth = Math.max(...relativeMonths);

      subdriversEvaluated = subdriversEvaluated.filter((ts) => {
        return Object.entries(filterByDimId).some(([dimId, attrIds]) => {
          const tsAttrId = ts.attributes.find((el) => el.dimensionId === dimId)?.id ?? null;

          return (
            dimId === BuiltInDimensionType.RelativeTime &&
            filterMatchesRelativeTime(
              attrIds,
              tsAttrId,
              this.monthsSinceEntityCohortStart,
              lastMonth,
            )
          );
        });
      });
    }

    const parent = ctx.parent;
    // Filter being used outside of a reducer.
    // Right now only done for cohort drivers
    if (
      subdriversEvaluated.length === 1 &&
      (parent == null || !(parent instanceof ReducerContext))
    ) {
      const timeSeries = subdriversEvaluated.map((t) => {
        return t.evaluation();
      });

      // TODO: Explicitly test this case for cohorts
      this.pushBasicEval(timeSeries[0]);
    } else {
      this.pushDimensionalEvals(subdriversEvaluated);
    }
  }

  private pushErr(err: CalculationErrorType, details?: string) {
    this.errorStack.push({ err, details });
  }

  private pushObjFieldEvals(evaluations: ObjectFieldEvaluation[] | undefined) {
    this.stack.push({ type: 'objectField', evaluations });
  }

  private popObjFieldEvals(): ObjectFieldEvaluation[] | undefined {
    const val = this.popEvalItem();
    if (val != null && val.type !== 'objectField') {
      throw new Error('expected to pop object field evaluations');
    }
    return val?.evaluations;
  }

  private pushDimensionalEvals(evaluations: DimensionalEvaluation[] | undefined) {
    this.stack.push({ type: 'dimensional', evaluations });
  }

  private popDimensionalEvals(): DimensionalEvaluation[] | undefined {
    const val = this.popEvalItem();
    if (val != null && val.type !== 'dimensional') {
      throw new Error('expected to pop dimensional evaluations');
    }
    return val?.evaluations;
  }

  private pushDriverGroupEvals(evaluations: GroupedDriverEvaluation[] | undefined) {
    this.stack.push({ type: 'group', evaluations });
  }

  private popDriverGroupEvals(): GroupedDriverEvaluation[] | undefined {
    const val = this.popEvalItem();
    if (val != null && val.type !== 'group') {
      throw new Error('expected to pop driver group evaluations');
    }
    return val?.evaluations;
  }

  private pushBasicEval(evaluation: Array<Value | undefined> | undefined) {
    this.stack.push({ type: 'basic', evaluation });
  }

  private popBasicEval(): Array<Value | ExplicitNull | undefined> | undefined {
    const val = this.popEvalItem();
    if (val != null && val.type !== 'basic') {
      throw new Error('expected to pop basic evaluation');
    }
    return val?.evaluation;
  }

  private popBasicNumericEval(): Array<NumberValue | undefined> | undefined {
    const val = this.popBasicEval();
    if (val != null && !this.isNumericTimeSeries(val)) {
      throw new Error('expected numeric evaluation');
    }
    return val;
  }

  private pushExplicitNull() {
    this.stack.push({ type: 'basic', evaluation: [EXPLICIT_NULL] });
  }

  private pushScalarNumber(num: number | undefined) {
    this.pushScalarValue(num != null ? toNumberValue(num) : undefined);
  }

  private pushScalarValue(value: Value | ExplicitNull | undefined) {
    this.stack.push({ type: 'basic', evaluation: value != null ? [value] : undefined });
  }

  private popEvalItem(): EvalStackItem | undefined {
    const res = this.stack.pop();
    return res;
  }

  private popSingleNumber(): number | undefined {
    const val = this.popSingleValue();
    if (val != null && val.type !== ValueType.Number && val.type !== EXPLICIT_NULL_TYPE) {
      throw new Error('expected to pop single number');
    }
    return val?.value;
  }

  private popSingleValue(): Value | ExplicitNull | undefined {
    const val = this.popEvalItem();
    if (val != null && val.type !== 'basic') {
      throw new Error('expected to pop basic evaluation');
    }

    const evaluation = val?.evaluation;
    if (evaluation != null && evaluation.length !== 1) {
      throw new Error('expected to pop single value');
    }

    return evaluation?.[0];
  }

  private maskTimeSeries(
    timeSeries: Array<Value | undefined>,
    mask: boolean[],
  ): Array<Value | undefined> {
    return timeSeries.map((e, i) => (mask[Math.min(i, mask.length - 1)] ? e : undefined));
  }

  private compareValues(
    fieldValue: NullableValue,
    filterValue: string | undefined,
    operator: FormulaBooleanOperator,
  ): boolean | undefined {
    if (filterValue == null) {
      if (operator === FormulaBooleanOperator.Equals) {
        return fieldValue.value == null;
      }
      if (operator === FormulaBooleanOperator.NotEquals) {
        return fieldValue.value != null;
      }
      // Can only do == null or != null
      return undefined;
    }

    // handle unset field values
    if (fieldValue.value == null || fieldValue.value === '') {
      if (operator === FormulaBooleanOperator.Equals) {
        return fieldValue.value === filterValue;
      }
      if (operator === FormulaBooleanOperator.NotEquals) {
        return fieldValue.value !== filterValue;
      }
      // Unset dates are treated as forever in the future
      if (
        (operator === FormulaBooleanOperator.GreaterThan ||
          operator === FormulaBooleanOperator.GreaterThanOrEqualTo) &&
        fieldValue.type === ValueType.Timestamp
      ) {
        return true;
      }
      return false;
    }

    const valueType = fieldValue.type;
    switch (valueType) {
      case ValueType.Attribute: {
        if (operator === FormulaBooleanOperator.Equals) {
          return fieldValue.value === filterValue;
        }
        if (operator === FormulaBooleanOperator.NotEquals) {
          return fieldValue.value !== filterValue;
        }
        return undefined;
      }
      case ValueType.Number: {
        return this.compareSingleValue(fieldValue.value, Number(filterValue), operator);
      }
      case ValueType.Timestamp: {
        return this.compareSingleValue(
          extractMonthKey(fieldValue.value),
          extractMonthKey(filterValue),
          operator,
        );
      }
      default: {
        return undefined;
      }
    }
  }

  private compareSingleValueToExplicitNull(
    val: number | string | undefined,
    operator: string,
  ): boolean | undefined {
    switch (operator) {
      case '==': {
        return val == null;
      }
      case '!=': {
        return val != null;
      }
      default: {
        return undefined;
      }
    }
  }

  private compareSingleValue(
    left: number | string,
    right: number | string,
    operator: string,
  ): boolean | undefined {
    switch (operator) {
      case '==': {
        return left === right;
      }
      case '!=': {
        return left !== right;
      }
      case '>': {
        return left > right;
      }
      case '>=': {
        return left >= right;
      }
      case '<': {
        return left < right;
      }
      case '<=': {
        return left <= right;
      }
      default: {
        return undefined;
      }
    }
  }

  exitDimDriverRef(ctx: DimDriverRefContext) {
    const uuid = ctx.UUID().text;
    const driver = this.evaluator.getDimDriverById(uuid);
    if (driver == null) {
      this.pushErr(CalculationErrorType.MissingEntity);
      return;
    }

    const dateRange = this.getDateRange();
    if (driver.type !== DriverType.Dimensional) {
      this.pushErr(CalculationErrorType.Unexpected, 'driver not dimensional for dim driver ref');
      this.pushDimensionalEvals(undefined);
      return;
    }

    const subdriversEvaluated: DimensionalEvaluation[] = driver.subdrivers.map((subdriver) => {
      return {
        attributes: subdriver.attributes,
        evaluation: (() =>
          this.getEvaluatedSubdriver(driver, subdriver.driverId, dateRange)) as () => Array<
          NumberValue | undefined
        >,
      };
    });

    // if any subdriver failed to be evaluated, return
    if (!subdriversEvaluated.every(isNotNull)) {
      this.pushScalarNumber(undefined);
      return;
    }

    this.pushDimensionalEvals(subdriversEvaluated);
  }

  exitSubmodelRef(ctx: SubmodelRefContext) {
    const submodelId = ctx.UUID().text;

    // If a driver is in the group it is referencing, filter it out to avoid a
    // circular dep.
    const driversForSubmodel = this.evaluator
      .getDriversBySubmodelId(submodelId)
      ?.filter((d) => d.id !== this.entityId.id);

    const submodelBlockId = this.evaluator.getBlockIdBySubmodelId(submodelId);

    if (driversForSubmodel == null) {
      this.pushErr(CalculationErrorType.MissingEntity, 'drivers for submodel was null');
      this.pushScalarNumber(undefined);
      return;
    }

    const dateRange =
      ctx.timeRange() != null ? this.getDateRange() : { start: this.monthKey, end: this.monthKey };

    const evaluateDriver = (driver: EvaluatorDriver): Array<NumberValue | undefined> => {
      const entityId: FormulaEntityTypedId = { type: 'driver', id: driver.id };

      const evaluated = this.evaluator.getCalculatedValuesForDateRange({
        context: this.context,
        entityId,
        dateRange,
        visited: this.visited,
        ignoreEventIds: this.ignoreEventIds,
        newlyAddedCacheKeys: this.newlyAddedCacheKeys,
      });

      if (!isAllNonCalculationError(evaluated) || !this.isNumericTimeSeries(evaluated)) {
        this.pushErr(
          CalculationErrorType.MissingEntity,
          `errors evaluating driver: ${errorStringForListOfEvaluations(evaluated)}`,
        );
        return [];
      }

      return evaluated;
    };

    const evaluatedDrivers: GroupedDriverEvaluation[] = driversForSubmodel
      .map((driver) => {
        const groupId = driver.driverReferences?.find(
          (ref) => ref.blockId === submodelBlockId,
        )?.groupId;
        if (groupId == null) {
          return null;
        }
        if (driver.type === DriverType.Dimensional) {
          return null;
        }
        return {
          driverGroupId: groupId,
          evaluation: () => evaluateDriver(driver),
        };
      })
      .filter(isNotNull);

    this.pushDriverGroupEvals(evaluatedDrivers);
  }

  exitSubmodelView() {
    const driverGroupFilter = this.argumentStack.pop() as UUIDFilter;
    const submodelDrivers = this.popDriverGroupEvals();
    if (submodelDrivers === undefined) {
      this.pushScalarNumber(undefined);
      return;
    }

    if (submodelDrivers.length === 0) {
      this.pushScalarNumber(0);
      return;
    }

    const filteredDrivers = submodelDrivers.filter((driver) =>
      driverGroupFilter.uuids.includes(driver.driverGroupId),
    );

    this.pushDriverGroupEvals(filteredDrivers);
  }

  // Can handle multiple expressions and reduceableViews. Will apply reducer
  // across all flattened elements if there are multiple reducible views /
  // expressions passed in to the reducer.
  private handleArithmaticReducer(numParams: number, reducer: ReducerFn) {
    const nonCountFn =
      reducer === 'sum'
        ? lodashSum
        : reducer === 'avg'
          ? mean
          : reducer === 'min'
            ? min
            : reducer === 'max'
              ? max
              : undefined;

    if (reducer !== 'count' && nonCountFn == null) {
      this.pushScalarNumber(undefined);
      return;
    }

    const toCombine: number[] = [];
    let totalCount = 0;

    for (let i = 0; i < numParams; i++) {
      const stackItem = this.popEvalItem();
      if (stackItem == null) {
        this.pushScalarNumber(undefined);
        return;
      }

      const { type } = stackItem;

      if (type === 'dimensional' || type === 'objectField' || type === 'group') {
        const evaluationValues =
          type === 'objectField'
            ? (stackItem.evaluations ?? []).map((e) => e.evaluation)
            : (stackItem.evaluations ?? []).map((e) => e.evaluation());

        const flattened = evaluationValues.flat();

        let subReducedValue = 0;
        if (reducer === 'count') {
          subReducedValue = flattened.filter(isNotNull).length;
          totalCount += subReducedValue;
        } else {
          if (!this.isNumericTimeSeries(flattened)) {
            this.pushErr(CalculationErrorType.Unexpected);
            this.pushScalarNumber(undefined);
            return;
          }
          const numericValues = flattened;
          subReducedValue = nonCountFn?.(numericValues.filter(isNotNull).map((v) => v.value)) ?? 0;
          toCombine.push(subReducedValue);
        }

        const isObjectSpecEvaluation =
          type === 'objectField' && (stackItem.evaluations ?? []).length > 0;
        if (isObjectSpecEvaluation) {
          const objectSpecEvaluation = this.objectSpecStack.pop();
          if (objectSpecEvaluation) {
            objectSpecEvaluation.evaluation = subReducedValue;
            this.objectSpecStack.push(objectSpecEvaluation);
          }
        }
      } else {
        const basicEvals = stackItem.evaluation;
        if (basicEvals == null) {
          continue;
        }

        if (reducer === 'count') {
          totalCount += basicEvals.filter(isNotNull).length;
        } else {
          if (!this.isNumericTimeSeries(basicEvals)) {
            this.pushErr(
              CalculationErrorType.Unexpected,
              `issue with values for ${reducer} with eval type ${type} basicEvals is null?: ${
                basicEvals == null
              }`,
            );
            this.pushScalarNumber(undefined);
            return;
          }

          toCombine.push(nonCountFn?.(basicEvals.filter(isNotNull).map((v) => v.value)) ?? 0);
        }
      }
    }

    const finalVal = reducer === 'count' ? totalCount : (nonCountFn?.(toCombine) ?? 0);
    this.pushScalarNumber(finalVal);
  }

  exitStringGroup(ctx: StringGroupContext) {
    // Unwrap string argument from quotes
    const strings = ctx.STRING().map((s) => cleanString(s.text));
    this.argumentStack.push(strings);
  }

  exitStringExpression(ctx: StringExpressionContext) {
    const string = ctx.STRING();
    if (string == null) {
      return;
    }

    if (ctx.stringIf() == null && ctx.objectRef() == null && ctx.extObjectRef() == null) {
      const stringVal = cleanString(string.text);
      const v: Value = { type: ValueType.Attribute, value: stringVal };
      this.pushScalarValue(v);
      return;
    }

    const fieldSpec = this.evaluator.getBusinessObjectFieldSpecByFieldId(this.entityId.id);
    if (fieldSpec == null) {
      return;
    }

    const stringVal = cleanString(string.text);
    const value: Value =
      fieldSpec.type === ValueType.Number
        ? { type: ValueType.Number, value: getNumber(stringVal) }
        : { type: fieldSpec.type, value: stringVal };

    this.pushScalarValue(value);
  }

  exitTimestampValue(ctx: TimestampValueContext) {
    const str = ctx.STRING();
    const timeRange = ctx.timeRange();
    if (str == null && timeRange == null) {
      return;
    }

    if (timeRange != null) {
      const { start, end } = this.getDateRange();
      // Can only do single month date ranges as timestamp value e.g. thisMonth
      if (start !== end) {
        this.pushErr(
          CalculationErrorType.Unexpected,
          `only single month values supported for timestamp value`,
        );
        return;
      }
      this.pushScalarValue({
        type: ValueType.Timestamp,
        value: getISOTimeWithoutMsFromMonthKey(start),
      });
      return;
    }

    const stringVal = cleanString(str?.text);
    if (isEmpty(stringVal)) {
      this.pushScalarValue(undefined);
    }
    const date = DateTime.fromISO(stringVal);
    if (!date.isValid) {
      this.pushErr(CalculationErrorType.Unexpected, `string date was invalid ${stringVal}`);
      return;
    }
    this.pushScalarValue({ type: ValueType.Timestamp, value: stringVal });
  }

  exitAddSubTimestamp(ctx: AddSubTimestampContext) {
    const isSub = !!ctx.SUB();
    const days = this.popSingleNumber();
    const date = this.popValidDate();
    // anything + or - undefined stays undefined
    if (date == null || days == null) {
      this.pushScalarValue(undefined);
      return;
    }

    let newDate = date;
    if (isSub) {
      newDate = date.minus({ days });
    } else {
      newDate = date.plus({ days });
    }

    this.pushScalarValue({ type: ValueType.Timestamp, value: formatISOWithoutMs(newDate) });
  }

  exitAddSubDuration(ctx: AddSubDurationContext) {
    const isSub = !!ctx.SUB();
    const count = this.popSingleNumber();
    const date = this.popValidDate();
    // anything + or - undefined stays undefined
    if (date == null || count == null) {
      this.pushScalarValue(undefined);
      return;
    }

    // remove the "to" off string e.g. toWeeks -> weeks
    const durationUnit = getDurationUnit(ctx.durationValue());
    let newDate = date;
    if (isSub) {
      newDate = date.minus({ [durationUnit]: count });
    } else {
      newDate = date.plus({ [durationUnit]: count });
    }

    this.pushScalarValue({ type: ValueType.Timestamp, value: formatISOWithoutMs(newDate) });
  }

  private popValidDate() {
    const dateVal = this.popSingleValue();

    if (dateVal == null || isEmpty(dateVal?.value)) {
      return undefined;
    }

    if (dateVal.type !== ValueType.Timestamp) {
      this.pushErr(CalculationErrorType.Unexpected, `value should be date got ${dateVal.type}`);
      return undefined;
    }

    const date = DateTime.fromISO(dateVal.value);
    if (!date.isValid) {
      this.pushErr(CalculationErrorType.Unexpected, `date string was not valid ${dateVal.value}`);
      return undefined;
    }
    return date;
  }

  exitEndOfMonth() {
    const date = this.popValidDate();
    if (date == null) {
      this.pushScalarValue(undefined);
      return;
    }
    const endOfMonth = date.endOf('month').startOf('day');
    this.pushScalarValue({ type: ValueType.Timestamp, value: formatISOWithoutMs(endOfMonth) });
  }

  exitStartOfMonth() {
    const date = this.popValidDate();
    if (date == null) {
      this.pushScalarValue(undefined);
      return;
    }
    const startOfMonth = date.startOf('month');
    this.pushScalarValue({ type: ValueType.Timestamp, value: formatISOWithoutMs(startOfMonth) });
  }

  exitTimestampBooleanExpression(ctx: TimestampBooleanExpressionContext) {
    this.exitSimpleExpression(ctx);
  }

  exitDaysInMonth() {
    const month = getDateTimeFromMonthKey(this.monthKey);
    this.pushScalarNumber(month.daysInMonth);
  }

  exitDateDiff(ctx: DateDiffContext) {
    const end = this.popValidDate();
    const start = this.popValidDate();
    if (start == null || !start.isValid || end == null || !end.isValid) {
      this.pushScalarValue(undefined);
      return;
    }

    const unit = cleanString(ctx.STRING().text);
    const luxonUnit = getLuxonUnitFromText(unit);
    if (luxonUnit == null) {
      this.pushErr(CalculationErrorType.Unexpected, `invalid date diff unit ${unit}`);
      return;
    }

    // This function currently gets the number of whole units only. Round off
    // bounds by the most granular unit to ensure the math works out correctly.
    const diff = Math.floor(
      end
        .endOf('day')
        .diff(start.startOf('day'), luxonUnit, { conversionAccuracy: 'longterm' })
        .as(luxonUnit),
    );

    this.pushScalarNumber(diff);
  }

  exitNetWorkDays(ctx: NetWorkDaysContext) {
    const end = this.popValidDate();
    const start = this.popValidDate();
    if (start == null || !start.isValid || end == null || !end.isValid) {
      this.pushScalarValue(undefined);
      return;
    }

    const strings = ctx.STRING().map((token) => cleanString(token?.text ?? ''));
    const weekendString = strings[0] ?? '';
    const holidaysString = strings[1] ?? '';

    const invalidWeekendStr = weekendMaskStrInvalidReason(weekendString);
    if (invalidWeekendStr != null) {
      this.pushScalarValue(undefined);
      return;
    }

    const weekendMask =
      weekendString.length === 0 ? DEFAULT_WEEKEND_MASK : [...weekendString].map((c) => c === '1');

    const trimmedHolidayString = holidaysString.trim();
    const holidays =
      trimmedHolidayString.length > 0
        ? trimmedHolidayString.split(',').map((s) => parseLiteralDateString(s.trim()))
        : [];

    const validHolidays = holidays.filter(isNotNull);
    if (holidays.length !== validHolidays.length) {
      this.pushScalarValue(undefined);
      return;
    }

    const numWorkDays = workDaysBetweenDates(
      start,
      end,
      weekendMask ?? DEFAULT_WEEKEND_MASK,
      validHolidays,
    );

    this.pushScalarNumber(numWorkDays);
  }

  private isNumericTimeSeries(
    ts: Array<Value | ExplicitNull | undefined>,
  ): ts is Array<NumberValue | undefined> {
    return ts.every((v) => v == null || v.type === ValueType.Number);
  }

  private getEvaluatedObjectFilter({
    filterFieldId,
    filterFieldSpec,
    dateRange,
    shouldFill = false,
  }: {
    filterFieldId: BusinessObjectFieldId | undefined;
    filterFieldSpec: BusinessObjectFieldSpec;
    dateRange: DateRange;
    shouldFill?: boolean;
  }) {
    if (filterFieldId == null) {
      return getEmptyMonthValues(dateRange).monthValues.map((e) => {
        if (e == null) {
          return { type: filterFieldSpec.type, value: undefined };
        }
        const ret = e as Value;
        return ret;
      });
    }

    return (
      (shouldFill
        ? this.getForwardFilledEvaluationForObjectField(filterFieldId, dateRange)
        : this.getEvaluatedObjectField(filterFieldId, dateRange)) ?? []
    ).map((e) => {
      if (e == null) {
        return { type: filterFieldSpec.type, value: undefined };
      }
      return e;
    });
  }

  private getEvaluatedDriver(driverId: DriverId, dateRange: DateRange) {
    const driverEntityId: FormulaEntityTypedId = { type: 'driver', id: driverId };
    const entity = this.evaluator.getEntityByKey(driverEntityId);
    if (entity?.id?.type !== 'driver') {
      this.pushErr(CalculationErrorType.MissingEntity);
      return undefined;
    }
    const evaluatedOrErrors = this.evaluator.getCalculatedValuesForDateRange({
      context: this.context,
      entityId: driverEntityId,
      dateRange,
      visited: this.visited,
      ignoreEventIds: this.ignoreEventIds,
      newlyAddedCacheKeys: this.newlyAddedCacheKeys,
    });

    if (evaluatedOrErrors.some(isCalculationError)) {
      this.pushErr(
        CalculationErrorType.Unexpected,
        `driver evaluation errors: ${errorStringForListOfEvaluations(evaluatedOrErrors)}`,
      );
      return undefined;
    }

    const evaluated = evaluatedOrErrors.map((e) => e as Value | undefined);
    return evaluated;
  }

  private getEvaluatedObjectField(fieldId: BusinessObjectFieldId, dateRange: DateRange) {
    const evaluatedOrErrors = this.evaluator.getCalculatedValuesForDateRange({
      context: this.context,
      entityId: { type: 'objectField', id: fieldId },
      dateRange,
      visited: this.visited,
      ignoreEventIds: this.ignoreEventIds,
      undefinedBeforeEarliestDate: true,
      newlyAddedCacheKeys: this.newlyAddedCacheKeys,
    });

    if (evaluatedOrErrors.some(isCalculationError)) {
      this.pushErr(
        CalculationErrorType.Unexpected,
        `evaluated object field had errors: ${errorStringForListOfEvaluations(evaluatedOrErrors)}`,
      );
      return undefined;
    }

    return evaluatedOrErrors.map((e) => e as Value | undefined);
  }

  // Returns an evaluation of the object field for the given date range,
  // with any undefined values filled with the next sequential valid evaluation.
  // e.g. [1, undefined, undefined, 2, undefined, 3] => [1, 2, 2, 2, 3, 3]
  private getForwardFilledEvaluationForObjectField(
    fieldId: BusinessObjectFieldId,
    dateRange: DateRange,
  ) {
    const dateRangeEvaluation = this.getEvaluatedObjectField(fieldId, dateRange);

    // Don't allow calculation to go on forever
    const latestCalculationDate = getDateTimeFromMonthKey(dateRange.end).plus({ years: 3 });
    let futureMonth = dateRange.end;
    let futureEvaluation: Value | undefined;
    while (
      futureEvaluation == null &&
      getDateTimeFromMonthKey(futureMonth) < latestCalculationDate
    ) {
      futureMonth = nextMonthKey(futureMonth);
      const evaluatedRange = this.getEvaluatedObjectField(fieldId, {
        start: futureMonth,
        end: futureMonth,
      });
      futureEvaluation =
        evaluatedRange == null || evaluatedRange.length === 0 ? undefined : evaluatedRange[0];
    }

    return dateRangeEvaluation?.map((e, idx) => {
      if (e == null) {
        let nextValidEvaluation;
        let nextValidIdx = idx;
        while (nextValidEvaluation == null && nextValidIdx < dateRangeEvaluation.length - 1) {
          nextValidEvaluation = dateRangeEvaluation[nextValidIdx];
          nextValidIdx += 1;
        }
        return nextValidEvaluation != null ? nextValidEvaluation : futureEvaluation;
      }
      return e;
    });
  }

  getDateRange(): DateRange {
    const [dateRangeStart, dateRangeEnd] = this.getLastNArgumentsFromStack(2) as [string, string];
    return {
      start: dateRangeStart,
      end: dateRangeEnd,
    };
  }

  private getMonthKey(relativeString: string): string {
    return memoGetMonthKey(relativeString, this.monthKey);
  }

  private getLastNArgumentsFromStack(argumentCount: number) {
    const lastArgumentIndex = this.argumentStack.length - 1;
    const zeroBasedArgumentCount = argumentCount - 1;

    return this.argumentStack.splice(lastArgumentIndex - zeroBasedArgumentCount, argumentCount);
  }

  private getStartOfFinancialQuarter(relativeString: string): string {
    const offset = parseInt(relativeString);
    return getFinancialQuarter(offset, this.monthKey);
  }

  private getStartOfFinancialYear(relativeString: string): string {
    const offset = parseInt(relativeString);
    return getFinancialYear(offset, this.monthKey);
  }

  private getPassingObjectsForFilters(
    objectSpecId: BusinessObjectSpecId,
    objects: BusinessObject[],
    fieldFilters: ObjectFieldFilter[],
  ): BusinessObject[] {
    if (fieldFilters.length === 0) {
      return objects;
    }

    const filterKey: FilterCacheKey = `${objectSpecId},${JSON.stringify(fieldFilters)}`;
    const cachedObjectIds = this.evaluator.getCachedFilterResult(filterKey);
    if (cachedObjectIds) {
      return objects.filter((o) => cachedObjectIds.has(o.id));
    }

    const passingObjects = new Set(objects);
    fieldFilters.forEach((filter) => {
      const formulaProperty = this.evaluator.getDatabaseFormulaPropertyById(filter.fieldRef.id);
      // skip if filter is based on deleted field spec
      if (formulaProperty == null || formulaProperty.type !== 'dimensionalProperty') {
        return;
      }

      const dimProp = formulaProperty.dimensionalProperty;
      passingObjects.forEach((o) => {
        const computedAttribute = this.evaluator.getComputedAttributeForDimensionalProperty(
          o.id,
          dimProp.id,
        );
        const f =
          computedAttribute == null
            ? {
                type: ValueType.Attribute,
                value: undefined,
              }
            : toAttributeValue(computedAttribute?.id);
        const passes =
          filter.operator === FormulaBooleanOperator.NotEquals
            ? filter.values.every((v) => this.compareValues(f, v, filter.operator))
            : filter.values.some((v) => this.compareValues(f, v, filter.operator));
        if (!passes) {
          passingObjects.delete(o);
        }
      });
    });
    const passingObjectsArr = Array.from(passingObjects);
    this.evaluator.setCachedFilterResult(filterKey, new Set(passingObjectsArr.map((o) => o.id)));
    return passingObjectsArr;
  }

  private getFilterMasks(
    object: BusinessObject,
    fieldFilters: ObjectFieldFilter[],
    numMonths: number,
  ): [boolean[][], Array<[CalculationErrorType, string]>] {
    const filterMasks: boolean[][] = [];
    const errors: Array<[CalculationErrorType, string]> = [];
    fieldFilters.forEach((filter) => {
      const formulaProperty = this.evaluator.getDatabaseFormulaPropertyById(filter.fieldRef.id);
      // skip if filter is based on deleted field spec
      if (formulaProperty == null || formulaProperty.type === 'dimensionalProperty') {
        return;
      }
      let evaluatedFilterField: NullableValue[] = [];
      if (formulaProperty.type === 'driverProperty') {
        const dimDriverId = formulaProperty.driverProperty.driverId;
        const dimDriver = this.evaluator.getDimDriverById(dimDriverId);
        const subDriverId = this.evaluator.getSubDriverIdForDriverProperty(
          object.id,
          formulaProperty.id,
        );
        if (dimDriver == null || subDriverId == null) {
          evaluatedFilterField = new Array<NullableValue>(numMonths).fill({
            type: ValueType.Number,
            value: undefined,
          });
          return;
        }
        const evaluatedSubDriverValues =
          this.getEvaluatedSubdriver(dimDriver, subDriverId, filter.fieldRef.dateRange) ?? [];

        evaluatedFilterField = evaluatedSubDriverValues
          .map((val) => (val != null ? toValueType(String(val.value), val.type) : undefined))
          .filter((val) => val != null) as NullableValue[];
      } else if (formulaProperty.type === 'fieldSpec') {
        const filterField = object.fields.find((f) => f.fieldSpecId === filter.fieldRef.id);
        evaluatedFilterField = this.getEvaluatedObjectFilter({
          filterFieldId:
            formulaProperty.fieldSpec.isFormula && filterField == null
              ? getObjectFieldUUID(object.id, filter.fieldRef.id)
              : filterField?.id,
          filterFieldSpec: formulaProperty.fieldSpec,
          dateRange: filter.fieldRef.dateRange,
          shouldFill: false,
        });
      }
      let filterMask: boolean[] = [];
      if (filter.operator !== FormulaBooleanOperator.NotEquals) {
        filterMask = evaluatedFilterField.map((f) =>
          filter.values.some((v) => this.compareValues(f, v, filter.operator)),
        );
      } else {
        // Title != Engineer,Designer should not include either
        filterMask = evaluatedFilterField.map((f) =>
          filter.values.every((v) => this.compareValues(f, v, filter.operator)),
        );
      }

      if (filterMask.some((f) => f == null)) {
        errors.push([CalculationErrorType.Unexpected, 'filters came out null']);
        return;
      }
      filterMasks.push(filterMask);
    });

    return [filterMasks, errors];
  }

  private getContextAttribute(dimensionId: DimensionId): Attribute | undefined {
    return this.evaluator
      .getDriverAttributes(this.entityId.id)
      ?.find((attr) => attr.dimensionId === dimensionId);
  }
}

const validReducerFns = ['sum', 'avg', 'count', 'min', 'max'] as const;
type ReducerFn = (typeof validReducerFns)[number];

function isValidReducerFn(fn: string): fn is ReducerFn {
  const elements: string[] = [...validReducerFns];
  return elements.includes(fn);
}

export function cleanString(s: string | undefined): string {
  return s?.replaceAll("'", '').trim() ?? '';
}
