import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'NetsuiteIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        d="M1.07727 4.72523H4.22155V12.1945H5.78705V15.1133H1.07727V4.72523ZM15.6312 11.5975H12.4869V4.12821H10.9214V1.20947H15.6312V11.5975Z"
        fill="#BACCDB"
      />
      <path
        d="M0.347656 0.49292H10.2846V9.0236L6.46374 4.10154H0.347656V0.49292ZM16.3477 15.8428H6.41067V7.31216L10.2316 12.2342H16.3477"
        fill="#125580"
      />
    </>
  ),
});
