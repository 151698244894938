import minBy from 'lodash/minBy';
import uniq from 'lodash/uniq';

import { EventGroupCreateInput } from 'generated/graphql';
import { getCommonAncestor } from 'helpers/events';
import { getNewDefaultName } from 'helpers/naming';
import { peekMutationStateChange, sortIndexMutationsForInsertion } from 'helpers/sortIndex';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { createDriverGroup } from 'reduxStore/actions/driverGroupMutations';
import { bulkCreateEventsAndGroups } from 'reduxStore/actions/eventMutations';
import {
  clearSelection,
  selectPlanTimelineItemRef,
  setAutoFocus,
} from 'reduxStore/reducers/pageSlice';
import { toggleExpand } from 'reduxStore/reducers/roadmapSlice';
import { AppThunk } from 'reduxStore/store';
import { allDriverGroupListSelector } from 'selectors/driverGroupSelector';
import {
  eventsByIdForLayerSelector,
  populatedEventGroupsByIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { isModalOpenSelector } from 'selectors/isModalOpenSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { isCurrentPageWritableSelector } from 'selectors/pageAccessResourcesSelector';
import { prevailingSelectedDriverIdsSelector } from 'selectors/prevailingCellSelectionSelector';
import {
  selectedEventGroupIdsWithoutSelectedParentSelector,
  selectedEventIdsWithoutSelectedParentSelector,
} from 'selectors/selectedEventSelector';
import { pageSelectionSelector } from 'selectors/selectionSelector';

export const groupSelected = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const selection = pageSelectionSelector(state);
    const authenticatedUser = authenticatedUserSelector(state);
    const isModalOpen = isModalOpenSelector(state);
    const isPageWritable = isCurrentPageWritableSelector(state);

    if (isModalOpen || selection == null || !isPageWritable) {
      return;
    }

    switch (selection.type) {
      case 'eventsAndGroups': {
        const eventIds = selectedEventIdsWithoutSelectedParentSelector(state);
        const eventGroupIds = selectedEventGroupIdsWithoutSelectedParentSelector(state);
        const blockId = selection.blockId;
        const populatedGroupsById = populatedEventGroupsByIdForLayerSelector(state);
        const eventsById = eventsByIdForLayerSelector(state);
        const events = eventIds.map((eventId) => eventsById[eventId]).filter(isNotNull);
        const eventGroups = eventGroupIds
          .map((groupId) => populatedGroupsById[groupId])
          .filter(isNotNull);
        const parentIds = uniq([
          ...events.map((ev) => ev.parentId),
          ...eventGroups.map((g) => g.parentId),
        ]);
        const parentId = getCommonAncestor(parentIds, populatedGroupsById, []);

        const id = uuidv4();
        const eventGroupCreateInput: EventGroupCreateInput = {
          id,
          name: 'New group',
          eventIds,
          eventGroupIds,
          parentId,
          ownerName: authenticatedUser?.name ?? '',
          ownerId: authenticatedUser?.id ?? '',
        };

        const insertBefore = minBy(
          [...events, ...eventGroups].filter((evOrGroup) => evOrGroup.parentId === parentId),
          'sortIndex',
        );
        const newState = peekMutationStateChange(state, {
          newEventGroups: [
            {
              ...eventGroupCreateInput,
              eventIds: [],
              eventGroupIds: [],
            },
          ],
        });
        const sortIndexUpdates = sortIndexMutationsForInsertion(newState, id, insertBefore?.id);

        dispatch(
          bulkCreateEventsAndGroups({
            events: [],
            groups: [eventGroupCreateInput],
            extras: sortIndexUpdates,
          }),
        );
        dispatch(setAutoFocus({ type: 'eventGroup', blockId, id }));
        dispatch(toggleExpand({ blockId, itemId: id }));
        dispatch(selectPlanTimelineItemRef({ blockId, type: 'group', id }));
        break;
      }
      case 'cell': {
        const newGroupId = uuidv4();
        const allDriverGroups = new Set(allDriverGroupListSelector(state).map((g) => g.name));
        const newGroupName = getNewDefaultName('New group', allDriverGroups);
        const driverIds = prevailingSelectedDriverIdsSelector(state);
        dispatch(createDriverGroup({ id: newGroupId, name: newGroupName, driverIds }));
        dispatch(setAutoFocus({ type: 'driverGroupRenameGroup', driverGroupId: newGroupId }));
        dispatch(clearSelection());
        break;
      }
      default:
        break;
    }
  };
};
