import { Flex, TableCellProps, Tag, Tbody, Td, Tr } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import RowText from 'components/CompareScenariosModalContent/RowText';
import {
  ComparisonColumnInfo,
  getDisplayableDiffTypes,
} from 'components/CompareScenariosModalContent/diffTypes';
import Formula from 'components/Formula/Formula';
import LayerName from 'components/LayerName/LayerName';
import { DRIVER_FORMAT_NAMES, VALUE_TYPE_NAMES } from 'config/drivers';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { BusinessObjectFieldSpec, isNumericFieldSpec } from 'reduxStore/models/businessObjectSpecs';
import { Layer } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import {
  objectSpecColumnEntityExistsSelector,
  objectSpecColumnEntitySelector,
} from 'selectors/collectionSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { fieldSpecFormulaDisplaySelector } from 'selectors/formulaDisplaySelector';
import { layersSelector } from 'selectors/layerSelector';
import {
  ColumnDiffType,
  ColumnType,
  isBaselineLayerSelector,
  scenarioComparisonBaselineLayerIdSelector,
} from 'selectors/scenarioComparisonSelector';

interface Props {
  comparisonLayerIds: string[];
  comparisonColumns: ComparisonColumnInfo[];
  objectSpecId: string;
}
const DatabaseColumnComparisonTableBody: React.FC<Props> = ({
  comparisonColumns,
  comparisonLayerIds,
  objectSpecId,
}) => {
  const layersById = useAppSelector(layersSelector);

  return (
    <Tbody border="1px solid" borderColor="gray.400">
      {...comparisonLayerIds.map((layerId) => (
        <DatabaseColumnComparisonTableRow
          key={layerId}
          comparisonLayer={layersById[layerId]}
          comparisonColumns={comparisonColumns}
          objectSpecId={objectSpecId}
        />
      ))}
    </Tbody>
  );
};

const DatabaseColumnComparisonTableRow: React.FC<{
  objectSpecId: string;
  comparisonLayer: Layer;
  comparisonColumns: ComparisonColumnInfo[];
}> = ({ comparisonLayer, comparisonColumns, objectSpecId }) => {
  const isBaselineLayer = useAppSelector((state) =>
    isBaselineLayerSelector(state, comparisonLayer.id),
  );

  return (
    <Tr>
      <TableCell>
        <LayerName
          layerId={comparisonLayer.id}
          isBaselineLayer={isBaselineLayer}
          isComparisonLayout
          noBgColor
          fontSize="xxs"
        />
      </TableCell>
      {comparisonColumns.map((column) => (
        <ComparisonRow
          key={column.id}
          column={column}
          layerId={comparisonLayer.id}
          objectSpecId={objectSpecId}
        />
      ))}
    </Tr>
  );
};

const ComparisonRow: React.FC<{
  column: ComparisonColumnInfo;
  layerId: string;
  objectSpecId: string;
}> = ({ column, layerId, objectSpecId }) => {
  const diffTypes = getDisplayableDiffTypes(column);
  return (
    <>
      {diffTypes.map((diffType) => (
        <ComparisonCell
          key={`${column.id}-${column.type}-${diffType}`}
          columnId={column.id}
          columnType={column.type}
          diffType={diffType}
          layerId={layerId}
          objectSpecId={objectSpecId}
        />
      ))}
    </>
  );
};

const ComparisonCell: React.FC<{
  diffType: ColumnDiffType;
  columnId: string;
  columnType: ColumnType;
  layerId: string;
  objectSpecId: string;
}> = ({ diffType, layerId, columnId, columnType, objectSpecId }) => {
  const baselineLayerId = useAppSelector(scenarioComparisonBaselineLayerIdSelector);
  const baselineColumnEntity = useAppSelector((state: RootState) =>
    objectSpecColumnEntitySelector(state, {
      columnId,
      layerId: baselineLayerId,
      columnType,
    }),
  );
  const columnEntity = useAppSelector((state: RootState) =>
    objectSpecColumnEntitySelector(state, { columnId, layerId, columnType }),
  );
  const isNonBaselineLayer = layerId !== baselineLayerId;

  const doesColumnExist = useAppSelector((state: RootState) =>
    objectSpecColumnEntityExistsSelector(state, { columnId, layerId, columnType }),
  );

  const getDefaultValues = useCallback((fieldSpec: BusinessObjectFieldSpec) => {
    if (fieldSpec.defaultValues == null) {
      return 'No default values';
    }
    const defaultValues = [];
    for (const [key, value] of Object.entries(fieldSpec.defaultValues)) {
      defaultValues.push(`${key}: ${value}`);
    }
    return defaultValues.join(', ');
  }, []);

  const isNewColumn = baselineColumnEntity == null && columnEntity != null;
  const isDeletedColumn = baselineColumnEntity != null && columnEntity == null;
  const baselineColumnName = baselineColumnEntity?.name;
  const comparisonColumnName = columnEntity?.name;
  const isDifferent =
    baselineColumnName != null && comparisonColumnName != null
      ? baselineColumnName !== comparisonColumnName
      : false;

  const statusTag = useMemo(() => {
    if (columnEntity == null) {
      return null;
    }
    if (isNewColumn) {
      return (
        <Tag size="sm" colorScheme="green">
          Added
        </Tag>
      );
    } else if (isDeletedColumn) {
      return (
        <Tag size="sm" colorScheme="red">
          -
        </Tag>
      );
    } else if (isDifferent) {
      return (
        <Tag size="sm" colorScheme="orange">
          Renamed
        </Tag>
      );
    }
    return null;
  }, [columnEntity, isDeletedColumn, isDifferent, isNewColumn]);

  const content = useAppSelector((state: RootState) => {
    switch (diffType) {
      case 'name': {
        return (
          <Flex flexDirection="row" gap={2} alignItems="center">
            <RowText text={columnEntity?.name} />
            {statusTag}
          </Flex>
        );
      }
      case 'formula': {
        const formulaDisplay = fieldSpecFormulaDisplaySelector(state, { id: columnId, layerId });
        if (formulaDisplay) {
          return <Formula formulaDisplay={formulaDisplay} />;
        }
        break;
      }
      case 'numericFormat': {
        if (columnEntity?.type === 'driver') {
          const driver = columnEntity.driver;
          if (driver?.format != null) {
            return <RowText text={DRIVER_FORMAT_NAMES[driver.format]} />;
          }
        } else if (columnEntity?.type === 'fieldSpec') {
          const fieldSpec = columnEntity.fieldSpec;
          if (fieldSpec?.numericFormat != null) {
            return <RowText text={DRIVER_FORMAT_NAMES[fieldSpec.numericFormat]} />;
          }
        }
        break;
      }
      case 'decimalPlaces': {
        if (columnEntity?.type === 'driver') {
          const driver = columnEntity.driver;
          return <RowText text={driver?.decimalPlaces?.toString()} />;
        } else if (columnEntity?.type === 'fieldSpec') {
          const fieldSpec = columnEntity.fieldSpec;
          if (fieldSpec == null) {
            break;
          }
          if (!isNumericFieldSpec(fieldSpec)) {
            return null;
          }
          return <RowText text={fieldSpec.decimalPlaces?.toString()} />;
        }
        break;
      }
      case 'currency': {
        if (columnEntity?.type === 'driver') {
          const driver = columnEntity.driver;
          return <RowText text={driver?.currencyISOCode} />;
        } else if (columnEntity?.type === 'fieldSpec') {
          const fieldSpec = columnEntity.fieldSpec;
          if (fieldSpec == null) {
            break;
          }
          const isNumericField = isNumericFieldSpec(fieldSpec);
          if (!isNumericField) {
            return null;
          }
          return <RowText text={fieldSpec.currencyISOCode} />;
        }
        break;
      }
      case 'valueType': {
        if (columnEntity?.type !== 'fieldSpec') {
          break;
        }
        const fieldSpec = columnEntity.fieldSpec;
        if (fieldSpec == null) {
          break;
        }
        return <RowText text={VALUE_TYPE_NAMES[fieldSpec.type]} />;
      }
      case 'deletedColumn': {
        const color = isNonBaselineLayer ? 'red' : undefined;
        if (!doesColumnExist) {
          return (
            <Tag size="sm" colorScheme={color}>
              Deleted
            </Tag>
          );
        }
        return (
          <Tag size="sm" colorScheme={color}>
            Exists
          </Tag>
        );
      }
      case 'dimension': {
        if (columnEntity == null) {
          return null;
        }
        if (columnEntity.type === 'fieldSpec' && columnEntity.fieldSpec?.dimensionId != null) {
          const dimensionId = columnEntity.fieldSpec.dimensionId;
          const dimensionsById = dimensionsByIdSelector(state);
          const dimension = safeObjGet(dimensionsById[dimensionId]);
          return <RowText text={dimension?.name} />;
        }
        break;
      }
      case 'nameAnonymization': {
        const objectSpecsById = businessObjectSpecsByIdForLayerSelector(state, { layerId });
        const objectSpec = safeObjGet(objectSpecsById[objectSpecId]);
        if (objectSpec == null) {
          return null;
        }
        const text = objectSpec.isRestricted ? 'Anonymized' : 'Not anonymized';
        return <RowText text={text} />;
      }
      case 'fieldAnonymization': {
        if (columnEntity?.type !== 'fieldSpec') {
          break;
        }
        const fieldSpec = columnEntity.fieldSpec;
        const text = fieldSpec?.isRestricted ? 'Anonymized' : 'Not anonymized';
        return <RowText text={text} />;
      }
      case 'defaultValues': {
        if (columnEntity?.type !== 'fieldSpec') {
          break;
        }
        const fieldSpec = columnEntity.fieldSpec;
        if (fieldSpec == null) {
          break;
        }
        const defaultValues =
          fieldSpec.defaultValues == null || Object.keys(fieldSpec.defaultValues).length === 0
            ? 'Default values removed'
            : getDefaultValues(fieldSpec);
        return <RowText text={defaultValues} />;
      }
      case 'databaseKey': {
        if (columnEntity?.type !== 'dimensionalProperty') {
          break;
        }
        const dimensionalProperty = columnEntity.property;
        if (dimensionalProperty == null) {
          return <Tag size="sm">-</Tag>;
        }
        return <RowText text={dimensionalProperty.isDatabaseKey ? 'Yes' : 'No'} />;
      }
      default:
        break;
    }
    return null;
  });

  return (
    <TableCell p={2}>
      <Flex alignItems="center">{content}</Flex>
    </TableCell>
  );
};

const TableCell: React.FC<{ children: React.ReactNode } & TableCellProps> = React.memo(
  ({ children, ...rest }) => {
    return (
      <Td
        borderRightWidth="1px"
        borderRightColor="gray.300"
        borderRightStyle="dashed"
        borderBottomWidth="1px"
        borderBottomColor="gray.300"
        borderBottomStyle="dashed"
        {...rest}
      >
        {children}
      </Td>
    );
  },
);

export default React.memo(DatabaseColumnComparisonTableBody);
