import React from 'react';

import CellContextProviderLight from 'components/CellContextProvider/CellContextProviderLight';
import DriverPropertyTableCellLight from 'components/DriverPropertyTableCell/DriverPropertyTableCellLight';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import useDriverCellRef from 'hooks/useDriverCellRef';

const COLUMN_TYPE: ModelViewColumnType = 'usedBy';

type Props = {
  columnLayerId: ColumnLayerId;
};

const DependentsTableCellLight: React.FC<Props> = ({ columnLayerId }) => {
  const cellRef = useDriverCellRef({ columnType: COLUMN_TYPE, columnLayerId });

  return (
    <CellContextProviderLight cellRef={cellRef}>
      <DriverPropertyTableCellLight
        columnType={COLUMN_TYPE}
        columnLayerId={columnLayerId}
        isSticky
      />
    </CellContextProviderLight>
  );
};

export default React.memo(DependentsTableCellLight);
