import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { DEFAULT_COMPARISON_LAYER_COLOR } from 'config/scenarioComparison';
import { formatDriverValue } from 'helpers/formatting';
import { nullSafeEqual } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartContext from 'hooks/useChartContext';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import {
  driverImpactShadingCursorValueSelector,
  driverTimeSeriesForLayerSelector,
} from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { comparisonLayerColorByIdForBlockSelector } from 'selectors/rollupSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { hasSelectedEventsSelector } from 'selectors/selectedEventSelector';
import { MonthKey } from 'types/datetime';

interface BaselineCursorValueProps {
  monthKey: MonthKey;
  value: number | null | undefined;
}

export const BaselineCursorValue: React.FC<BaselineCursorValueProps> = ({ monthKey, value }) => {
  const hasSelectedEvents = useAppSelector(hasSelectedEventsSelector);
  const formattedValue = useFormatValueForDisplay(value);

  return (
    <Flex alignItems="baseline" columnGap={1}>
      <Text color="gray.600" fontSize="lg" fontWeight="medium">
        {formattedValue}
      </Text>
      {hasSelectedEvents && <ImpactShadingValue monthKey={monthKey} baselineValue={value} />}
    </Flex>
  );
};

interface ImpactShadingValueProps {
  monthKey: MonthKey;
  baselineValue: number | null | undefined;
}

const ImpactShadingValue: React.FC<ImpactShadingValueProps> = ({ baselineValue }) => {
  const { driverIds } = useChartContext();
  const { blockId } = useBlockContext();

  // Dont show chart table header when there are more than one driver, so will always be 1 driver at this point
  const firstDriverId = driverIds[0];
  const withoutEventValue = useAppSelector((state) =>
    driverImpactShadingCursorValueSelector(state, {
      driverId: firstDriverId,
      includeEventsInSameRow: false,
      blockId,
    }),
  );
  const formattedValue = useFormatValueForDisplay(withoutEventValue);
  if (nullSafeEqual(withoutEventValue, baselineValue)) {
    return null;
  }

  return (
    <Text color="blue.500" fontSize="md" fontWeight="medium">
      {formattedValue}
    </Text>
  );
};

interface ComparisonCursorValuesProps {
  monthKey: MonthKey;
  driverId: DriverId;
}

export const ComparisonCursorValues: React.FC<ComparisonCursorValuesProps> = ({
  monthKey,
  driverId,
}) => {
  const { blockId } = useBlockContext();

  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  return (
    <Flex alignItems="baseline" columnGap={1}>
      {comparisonLayerIds.map((layerId, idx, arr) => (
        <ComparisonCursorForLayer
          key={layerId}
          monthKey={monthKey}
          layerId={layerId}
          driverId={driverId}
          addSeparator={idx < arr.length - 1}
        />
      ))}
    </Flex>
  );
};

const ComparisonCursorForLayer: React.FC<{
  monthKey: MonthKey;
  driverId: DriverId;
  layerId: LayerId;
  addSeparator: boolean;
}> = ({ monthKey, driverId, layerId, addSeparator }) => {
  const ts = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: driverId, layerId }),
  );

  return (
    <LayerFormattedValue
      key={layerId}
      layerId={layerId}
      value={ts[monthKey]}
      addSeparator={addSeparator}
    />
  );
};

interface LayerFormattedValueProps {
  layerId: LayerId;
  value: number | null | undefined;
  addSeparator?: boolean;
}

const LayerFormattedValue: React.FC<LayerFormattedValueProps> = React.memo(
  ({ layerId, value, addSeparator = false }) => {
    const { blockId } = useBlockContext();
    const layerColorsById = useAppSelector((state) =>
      comparisonLayerColorByIdForBlockSelector(state, blockId),
    );
    const color = layerColorsById[layerId] ?? DEFAULT_COMPARISON_LAYER_COLOR;
    const formattedValue = useFormatValueForDisplay(value);
    return (
      <Text
        color={color}
        fontSize="lg"
        fontWeight="medium"
        _after={{ content: `"${addSeparator ? ', ' : ''}"` }}
      >
        {formattedValue}
      </Text>
    );
  },
);

const useFormatValueForDisplay = (val: number | null | undefined) => {
  const { driverIds } = useChartContext();
  // Dont show chart table header when there are more than one driver, so will always be 1 driver at this point
  const firstDriverId = driverIds[0];
  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, firstDriverId),
  );
  const str = val == null ? null : formatDriverValue(val, displayConfiguration);
  return str == null || str.length === 0 ? '-' : str;
};
