import { times } from 'lodash';

import { DEFAULT_DRIVER_FORMAT } from 'config/drivers';
import { DriverFormatEvaluatorSingleton } from 'helpers/formulaEvaluation/DriverFormatResolver/DriverFormatEvaluator';
import {
  CoalesceContext,
  DriverRefContext,
  NumberContext,
  ObjectFieldRefContext,
  ReducerContext,
} from 'helpers/formulaEvaluation/ForecastCalculator/CalculatorParser';
import { Listener } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DriverFormat, DriverId } from 'reduxStore/models/drivers';

export class DriverFormatListener implements Listener<DriverFormat> {
  private formatStack: DriverFormat[];
  private freezes: number;
  private entityId: DriverId | BusinessObjectFieldSpecId;

  constructor({ entityId }: { entityId: DriverId | BusinessObjectFieldSpecId }) {
    this.formatStack = [];
    this.entityId = entityId;
    this.freezes = 0;
  }

  getResult(): DriverFormat {
    return this.formatStack.pop() ?? DEFAULT_DRIVER_FORMAT;
  }

  // don't permit changes to the format stack in contexts in which the expression
  // should have no impact on the format of the driver. filters, date ranges,
  // and boolean expressions are all exmaples

  enterRelativeFilter() {
    this.freeze();
  }

  exitRelativeFilter() {
    this.unfreeze();
  }

  enterCalendarFilter() {
    this.freeze();
  }

  exitCalendarFilter() {
    this.unfreeze();
  }

  enterSimpleExpression() {
    this.freeze();
  }

  exitSimpleExpression() {
    this.unfreeze();
  }

  enterAndExpression() {
    this.freeze();
  }

  exitAndExpression() {
    this.unfreeze();
  }

  enterOrExpression() {
    this.freeze();
  }

  exitOrExpression() {
    this.unfreeze();
  }

  enterObjectFieldFilter() {
    this.freeze();
  }

  exitObjectFieldFilter() {
    this.unfreeze();
  }

  enterVariableRelativeTime() {
    this.freeze();
  }

  exitVariableRelativeTime() {
    this.unfreeze();
  }

  enterDateRange() {
    this.freeze();
  }

  exitDateRange() {
    this.unfreeze();
  }

  enterCohortRelativeTime() {
    this.freeze();
  }

  exitCohortRelativeTime() {
    this.unfreeze();
  }

  enterReducer(ctx: ReducerContext) {
    if (!this.canResolveReducer(ctx)) {
      this.freeze();
    }
  }

  exitReducer(ctx: ReducerContext) {
    const canResolve = this.canResolveReducer(ctx);
    if (!canResolve) {
      this.unfreeze();
    }

    // fallback to number if couldn't resolve the reducer
    const format = canResolve ? (this.popFormat() ?? DriverFormat.Number) : DriverFormat.Number;
    const reducer = ctx.reduceExpressionsOrViews().reducerFn().text;
    switch (reducer) {
      case 'count': {
        this.pushFormat(DriverFormat.Integer);
        break;
      }
      case 'avg': {
        this.pushFormat(format === DriverFormat.Integer ? DriverFormat.Number : format);
        break;
      }
      default: {
        this.pushFormat(format);
        break;
      }
    }
  }

  exitNumber(ctx: NumberContext) {
    const numberText = ctx.text;
    let format = DriverFormat.Number;
    if (numberText.includes('$')) {
      format = DriverFormat.Currency;
    } else if (numberText.includes('%')) {
      format = DriverFormat.Percentage;
    }

    this.pushFormat(format);
  }

  exitRound() {
    const format = this.popFormat() ?? DriverFormat.Number;
    this.pushFormat(format !== DriverFormat.Number ? format : DriverFormat.Integer);
  }

  exitRoundDown() {
    const format = this.popFormat() ?? DriverFormat.Number;
    this.pushFormat(format !== DriverFormat.Number ? format : DriverFormat.Integer);
  }

  exitRoundPlaces() {
    // this term is how many places to round and does not impact format
    this.popFormat();
  }

  exitPow() {
    // this term is the exponent and does not impact format
    this.popFormat();
  }

  exitSumProduct() {
    const right = this.popFormat() ?? DriverFormat.Number;
    const left = this.popFormat() ?? DriverFormat.Number;

    this.pushFormat(this.combineTypes(left, right, { useMultiplicationPrecedence: false }));
  }

  exitDriverRef(ctx: DriverRefContext) {
    const uuid = ctx.UUID().text;
    // try to resolve self-references using the actuals formula, so that drivers that reflect
    // actuals can be formatted correctly
    const format = DriverFormatEvaluatorSingleton.getResolvedFormatById(uuid, {
      useActuals: uuid === this.entityId,
    });
    this.pushFormat(format);
  }

  exitExtDriverRef() {
    // all external drivers currently come from QBO
    this.pushFormat(DriverFormat.Currency);
  }

  exitDimDriverRef(ctx: DriverRefContext) {
    const uuid = ctx.UUID().text;
    const format = DriverFormatEvaluatorSingleton.getResolvedFormatById(uuid, {
      useActuals: uuid === this.entityId,
    });
    this.pushFormat(format);
  }

  exitObjectFieldRef(ctx: ObjectFieldRefContext) {
    const fieldSpecId = ctx.UUID().text;
    const format = DriverFormatEvaluatorSingleton.getResolvedFormatById(fieldSpecId);
    this.pushFormat(format);
  }

  exitConditional() {
    // arbitrarily use the first expression's type
    this.popFormat();
  }

  exitIfError() {
    // arbitrarily use the first expression's type
    this.popFormat();
  }

  exitCoalesce(ctx: CoalesceContext) {
    // arbitrarily use the first expression's type
    const numExpressions = ctx.coalesceRef().expression().length;
    times(numExpressions - 1, this.popFormat);
  }

  exitAddSub() {
    const right = this.popFormat() ?? DriverFormat.Number;
    const left = this.popFormat() ?? DriverFormat.Number;

    this.pushFormat(this.combineTypes(left, right, { useMultiplicationPrecedence: false }));
  }

  exitMulDiv() {
    const right = this.popFormat() ?? DriverFormat.Number;
    const left = this.popFormat() ?? DriverFormat.Number;

    this.pushFormat(this.combineTypes(left, right, { useMultiplicationPrecedence: true }));
  }

  private combineTypes(
    format1: DriverFormat,
    format2: DriverFormat,
    opts: { useMultiplicationPrecedence: boolean },
  ): DriverFormat {
    if (format1 === format2) {
      return format1;
    } else if (format1 === DriverFormat.Currency || format2 === DriverFormat.Currency) {
      return DriverFormat.Currency;
    } else if (
      !opts.useMultiplicationPrecedence &&
      (format1 === DriverFormat.Percentage || format2 === DriverFormat.Percentage)
    ) {
      // adding or substracting a number/integer to a percentage yields a percentage
      return DriverFormat.Percentage;
    }

    return DriverFormat.Number;
  }

  private popFormat() {
    if (!this.isFrozen) {
      return this.formatStack.pop();
    }

    return undefined;
  }

  private pushFormat(format: DriverFormat) {
    if (!this.isFrozen) {
      this.formatStack.push(format);
    }
  }

  private freeze() {
    this.freezes += 1;
  }

  private unfreeze() {
    this.freezes -= 1;
  }

  get isFrozen() {
    return this.freezes > 0;
  }

  private canResolveReducer(ctx: ReducerContext) {
    // N.B., for now, we don't handle an arbitrary number of expressions in the reducer
    // if it's an expression that resolves to a single format, we don't freeze before
    // entering the reducer
    const expressionOrView = ctx.reduceExpressionsOrViews().expressionOrView();
    if (expressionOrView.length === 1) {
      const reduceableView = expressionOrView[0].reduceableViews();
      return (
        reduceableView?.dimDriverView() != null ||
        reduceableView?.driverFilterView() != null ||
        reduceableView?.objectSpecRef() != null
      );
    }

    return false;
  }
}
