import { CheckIcon } from '@chakra-ui/icons';

import { ComparisonColumn, ValueType } from 'generated/graphql';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { LayerId } from 'reduxStore/models/layers';
import CalendarIcon from 'vectors/Calendar';
import CubeIcon from 'vectors/Cube';
import NumberSignIcon from 'vectors/NumberSign';

export const DEFAULT_OBJECT_FIELD_COLUMN_WIDTH = 200;
export const MIN_WIDTH_OBJECT_TABLE_PX = 580;
export const EMPTY_ATTRIBUTE_ID = 'none';
export const NONE_GROUP_INFO = {
  attributeId: EMPTY_ATTRIBUTE_ID,
  groupingType: 'none' as const,
  dimensionId: null,
  fieldSpecId: null,
  key: 'none' as const,
};
export type GroupInfo =
  | typeof NONE_GROUP_INFO
  | {
      // Currently, only attribute object fields are supported. We can
      // generalize later if needed since the backend technically just knows that
      // you're grouping by a field.
      attributeId: AttributeId;
      groupingType: 'attributeObjectField';
      dimensionId: DimensionId;
      fieldSpecId: BusinessObjectFieldSpecId;
      key: string;
      forceShowEvenWhenEmpty?: boolean;
    };

export type GroupRowWithoutAddItem = {
  type: 'object';
  objectId: BusinessObjectId;
  comparisonType?: ComparisonColumn;
  layerId?: LayerId;
};

export type GroupRow = GroupRowWithoutAddItem | { type: 'addItem' };

export function isObjectRow(data: GroupRow): data is GroupRow & { type: 'object' } {
  return data.type === 'object';
}

export function isAddItemRow(data: GroupRow): data is GroupRow & { type: 'addItem' } {
  return data.type === 'addItem';
}

export type DatabaseGroupKey = string;

export type Group = {
  groupInfo: GroupInfo;
  isExpanded: boolean;
  rows: GroupRow[];
};

export const DATABASE_PROPERTY_TYPE_NAMES: Record<ValueType, string> = {
  [ValueType.Number]: 'Number',
  [ValueType.Attribute]: 'Dimension',
  [ValueType.Timestamp]: 'Date',
  [ValueType.Boolean]: 'Boolean',
};

export const DATABASE_PROPERTY_TYPE_ICONS: Record<ValueType, JSX.Element> = {
  [ValueType.Number]: <NumberSignIcon />,
  [ValueType.Attribute]: <CubeIcon />,
  [ValueType.Timestamp]: <CalendarIcon />,
  [ValueType.Boolean]: <CheckIcon />,
};

export const OBJECT_BLOCK_COLUMN_DRAG_ITEM_TYPE = 'objectTableColumn';
export const NEW_OBJECT_SPEC_NAME = 'New database';
export const OBJECT_NAME_FIELD_NAME = 'Name';
export const OBJECT_ID_FIELD_NAME = 'ID';
