import { maintenanceModeFlagKey } from 'config/maintenanceMode';
import { emptyToUndefined } from 'helpers/string';
import { MaintenanceModeConfig } from 'reduxStore/reducers/launchDarklySlice';

export const launchDarklyClientSideId: string =
  emptyToUndefined(process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID) ?? '';

const globalLDContext = { kind: 'global', key: 'global', anonymous: true };

const initServerLDClient = async () => {
  if (typeof window === 'undefined') {
    const { init } = await import('@launchdarkly/node-server-sdk');
    return init(process.env.LD_SDK_KEY ?? '');
  }
  return null;
};

export const fetchMaintenanceModeFlag = async (
  userId?: string,
): Promise<MaintenanceModeConfig | null> => {
  const ldClient = await initServerLDClient();
  if (ldClient == null) {
    return null;
  }

  await ldClient.waitForInitialization({ timeout: 5 });
  const ldContext =
    userId != null ? { kind: 'user', key: userId, anonymous: false } : globalLDContext;
  const result = (await ldClient.variation(
    maintenanceModeFlagKey,
    ldContext,
    null,
  )) as MaintenanceModeConfig | null;
  ldClient.close();
  return result;
};
