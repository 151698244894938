import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'NightingaleChart',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    boxSize: '14px',
  },
  d: 'M15 8.5C14.9978 6.51157 14.2069 4.60522 12.8008 3.19918C11.3948 1.79315 9.48843 1.00225 7.5 1H7V4.02955C5.98652 4.14432 5.04196 4.59952 4.32074 5.32074C3.59952 6.04196 3.14432 6.98652 3.02955 8H2V8.5C2.00189 9.87132 2.5155 11.1926 3.44029 12.2051C4.36508 13.2177 5.63447 13.8487 7 13.9746V15H7.5C9.13601 14.9975 10.711 14.3785 11.911 13.2665C13.1109 12.1545 13.8478 10.6311 13.9746 9H15V8.5ZM8 2.01905C9.54581 2.14048 10.9975 2.80961 12.094 3.90604C13.1904 5.00246 13.8595 6.45419 13.981 8H8V2.01905ZM7 5.04V8H4.04C4.14882 7.25364 4.4957 6.56237 5.02903 6.02903C5.56237 5.4957 6.25364 5.14882 7 5.04ZM3.0276 9H7V12.9695C5.98635 12.8551 5.04151 12.4002 4.31994 11.6791C3.59837 10.9581 3.14276 10.0136 3.0276 9ZM8 13.9775V9H12.9775C12.8589 10.2801 12.2964 11.4783 11.3874 12.3874C10.4783 13.2964 9.28006 13.8589 8 13.9775Z',
});
