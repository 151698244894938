import { Box, Divider, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  name: string | undefined;
  isLast: boolean;
  children: React.ReactNode;
}

const SelectMenuSection: React.FC<Props> = ({ name, isLast, children }) => {
  return (
    <>
      <Box>
        {name != null && (
          <Text
            color="gray.500"
            pr={1}
            pl={3}
            fontSize="xxxs"
            textTransform="uppercase"
            fontWeight="bold"
            mt={2}
            mb={1}
          >
            {name}
          </Text>
        )}
        {children}
      </Box>
      {!isLast && <Divider my={2} width="unset" />}
    </>
  );
};

export default React.memo(SelectMenuSection);
