import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import remove from 'lodash/remove';

import {
  BlocksPageCreateInput,
  BlocksPageDeleteInput,
  BlocksPageUpdateInput,
} from 'generated/graphql';
import { BlocksPage } from 'reduxStore/models/blocks';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { DatasetSliceState } from 'reduxStore/reducers/datasetSlice';
import { handleDeleteBlock } from 'reduxStore/reducers/helpers/blocks';

export function setBlocksPagesFromDatasetSnapshot(
  state: Draft<DatasetSliceState>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null) {
    state.blocksPages = { byId: {}, allIds: [] };
    return;
  }

  const { blocksPages } = dataset;
  const pagesList = blocksPages.map((gqlPage) => {
    const page: BlocksPage = {
      ...gqlPage,
      blockIds: gqlPage.blockIds ?? [],
      internalPageType: gqlPage.internalPageType ?? undefined,
      internalPageVersion: gqlPage.internalPageVersion ?? undefined,
      sortIndex: gqlPage.sortIndex ?? undefined,
      parent: gqlPage.parent ?? undefined,
      dateRange: gqlPage.dateRange ?? undefined,
      createdByUserId: gqlPage.createdByUserId ?? undefined,
      layout: gqlPage.layout ?? undefined,
    };
    return page;
  });

  state.blocksPages = {
    byId: keyBy(pagesList, 'id'),
    allIds: pagesList.map((o) => o.id),
  };
}

export function handleCreateBlocksPage(
  state: Draft<DatasetSliceState>,
  newBlocksPageInput: BlocksPageCreateInput,
) {
  const {
    id,
    name,
    internalPageType,
    internalPageVersion,
    sortIndex,
    createdByUserId,
    parent,
    layout,
  } = newBlocksPageInput;
  const newPage: BlocksPage = {
    id,
    name,
    blockIds: [],
    internalPageType: internalPageType ?? undefined,
    internalPageVersion: internalPageVersion ?? undefined,
    sortIndex: sortIndex ?? undefined,
    createdByUserId: createdByUserId ?? undefined,
    parent: parent ?? undefined,
    layout: layout ?? undefined,
  };

  if (state.blocksPages.byId[id] != null) {
    return;
  }

  state.blocksPages.byId[id] = newPage;
  state.blocksPages.allIds.push(id);
}

export function handleDeleteBlocksPage(
  state: Draft<DatasetSliceState>,
  { id }: BlocksPageDeleteInput,
) {
  const page = state.blocksPages.byId[id];

  if (page == null) {
    return;
  }
  // delete blocks on page
  page.blockIds.map((blockId) => handleDeleteBlock(state, { id: blockId }));

  delete state.blocksPages.byId[id];
  remove(state.blocksPages.allIds, (i) => i === id);
}

export function handleUpdateBlocksPage(
  state: Draft<DatasetSliceState>,
  updateBlocksPageInput: BlocksPageUpdateInput,
) {
  const { id, name, sortIndex, dateRange, parent, layout, deleteLayout } = updateBlocksPageInput;

  const page = state.blocksPages.byId[id];
  if (page == null) {
    return;
  }

  if (name != null) {
    page.name = name;
  }

  if (sortIndex != null) {
    page.sortIndex = sortIndex;
  }

  if (dateRange != null) {
    page.dateRange = dateRange;
  }

  if (parent != null) {
    page.parent = parent;
  }

  if (layout != null) {
    page.layout = layout;
  } else if (deleteLayout != null && deleteLayout) {
    page.layout = undefined;
  }
}
