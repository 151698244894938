import { InternalPage } from 'config/internalPages/internalPage';
import { BlockCreateInput, BlockType, BlocksPageCreateInput } from 'generated/graphql';
import { uuidv4 } from 'helpers/uuidv4';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';

//
// NB: **Keep these constants in sync with object_spec.go**
//
// Increment this version if you make a change to the config below.
const VERSION = 3;
// Note: The page and block ids and names here use the deprecated name "object collections"
// instead of "databases". In order to avoid erasing any user configuration, we chose to
// retain the existing ids/names.
const DATABASE_PAGE_SUFFIX = 'objectCollectionPage';
export const DATABASE_BLOCK_NAME = 'objectCollectionBlock';
export const OBJECT_DETAIL_BLOCK_NAME = 'objectDetailBlock';

export const isDatabasePage = (
  internalPageType: string | null | undefined,
): internalPageType is string =>
  internalPageType != null && internalPageType.endsWith(DATABASE_PAGE_SUFFIX);

export const getDatabaseInternalPageType = (specId: BusinessObjectSpecId): string => {
  return `${specId}-${DATABASE_PAGE_SUFFIX}`;
};

export const getObjectSpecIdFromInternalPageType = (internalPageType: string): string => {
  const suffixStart = internalPageType.indexOf(DATABASE_PAGE_SUFFIX);
  return internalPageType.slice(0, suffixStart - 1);
};

export const getDatabasePage = ({
  specId,
  createdByUserId,
}: {
  specId: BusinessObjectSpecId;
  createdByUserId?: string;
}): InternalPage => {
  const id = uuidv4();
  const internalPageType = getDatabaseInternalPageType(specId);
  const version = VERSION;
  const blocksPageCreateInput: BlocksPageCreateInput = {
    id,
    name: '',
    internalPageType,
    internalPageVersion: version,
    createdByUserId,
  };
  const blocksCreateInputs: BlockCreateInput[] = [
    {
      id: uuidv4(),
      type: BlockType.ObjectTable,
      name: DATABASE_BLOCK_NAME,
      pageId: id,
      blockConfig: {
        businessObjectSpecId: specId,
      },
    },
    {
      id: uuidv4(),
      type: BlockType.ObjectGrid,
      name: OBJECT_DETAIL_BLOCK_NAME,
      pageId: id,
      blockConfig: {
        businessObjectSpecId: specId,
      },
    },
  ];
  return { internalPageType, version, blocksPageCreateInput, blocksCreateInputs };
};
