import { useContext } from 'react';

import { DriverChartContext } from 'config/driverChart';

export default function useChartContext() {
  const chartContext = useContext(DriverChartContext);
  if (chartContext == null) {
    throw new Error('cannot use chart context without driver chart');
  }

  return chartContext;
}
