import { createSelector } from 'reselect';

import { LeverType } from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import { Driver } from 'reduxStore/models/drivers';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { prevailingSelectedDriverIdsSelector } from 'selectors/prevailingCellSelectionSelector';
import { Selector } from 'types/redux';

/**
 * @returns null if the selected drivers do not have the same lever type. Otherwise returns the
 * single LeverType of all the selected drivers.
 */
export const driverSelectionLeverTypeSelector: Selector<LeverType | null> = createSelector(
  prevailingSelectedDriverIdsSelector,
  driversByIdForLayerSelector,
  (selectedIds, driversById) => {
    const selectedDrivers = selectedIds.map((id) => driversById[id]).filter(isNotNull) as Driver[];
    if (selectedDrivers.length === 0) {
      return null;
    }

    const firstDriverLeverType = selectedDrivers[0].leverType ?? LeverType.Unknown;
    const isSame = selectedDrivers.every(
      (driver) => (driver.leverType ?? LeverType.Unknown) === firstDriverLeverType,
    );
    return isSame ? firstDriverLeverType : null;
  },
);
