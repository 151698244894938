const Aws = () => {
  return (
    <svg viewBox="0 0 512 512">
      <g>
        <path
          fill="#252F3E"
          d="M144.36,214.08c0,6.31,0.7,11.36,1.82,15.15c1.4,3.79,3.09,7.85,5.47,12.2c0.84,1.4,1.12,2.66,1.12,3.93
          c0,1.68-0.98,3.37-3.23,5.05l-10.66,7.15c-1.54,0.98-3.09,1.54-4.49,1.54c-1.68,0-3.37-0.84-5.05-2.38
          c-2.38-2.52-4.49-5.33-6.17-7.99c-1.68-2.95-3.37-6.17-5.33-10.1c-13.32,15.71-30.02,23.56-50.07,23.56
          c-14.31,0-25.67-4.07-34.08-12.2c-8.28-8.14-12.62-19.08-12.62-32.68c0-14.45,5.05-26.23,15.43-35.07s24.12-13.32,41.66-13.32
          c5.75,0,11.78,0.56,18.09,1.4c6.31,0.84,12.76,2.24,19.64,3.79v-12.48c0-12.9-2.66-22.02-7.99-27.21
          c-5.47-5.33-14.59-7.85-27.77-7.85c-5.89,0-12.06,0.7-18.37,2.24c-6.31,1.54-12.48,3.37-18.37,5.75c-2.66,1.12-4.77,1.82-5.89,2.24
          c-1.12,0.28-2.1,0.56-2.66,0.56c-2.38,0-3.51-1.68-3.51-5.33v-8.28c0-2.66,0.28-4.77,1.12-5.89c0.84-1.12,2.38-2.38,4.77-3.51
          c5.89-3.09,13.04-5.61,21.46-7.71c8.28-2.24,17.25-3.23,26.51-3.23c20.2,0,35.07,4.63,44.6,13.75c9.4,9.26,14.17,23.14,14.17,41.94
          v55.12h0.42V214.08z M75.21,240.03c5.61,0,11.36-0.98,17.53-3.09s11.64-5.75,16.13-10.94c2.66-3.23,4.77-6.87,5.75-10.94
          c0.98-4.07,1.68-8.98,1.68-14.87v-7.15c-4.91-1.12-10.24-2.24-15.71-2.95c-5.47-0.7-10.66-0.98-15.99-0.98
          c-11.36,0-19.78,2.24-25.39,6.87c-5.61,4.63-8.28,11.08-8.28,19.64c0,7.99,2.1,14.03,6.31,18.09
          C61.33,237.93,67.36,240.03,75.21,240.03z M211.97,258.4c-3.09,0-5.05-0.56-6.45-1.68c-1.4-0.98-2.52-3.37-3.51-6.59l-39.97-131.56
          c-0.98-3.37-1.54-5.61-1.54-6.87c0-2.66,1.4-4.21,4.07-4.21h16.69c3.23,0,5.47,0.56,6.59,1.68c1.4,0.98,2.38,3.37,3.37,6.59
          l28.61,112.77l26.51-112.77c0.84-3.37,1.82-5.61,3.23-6.59c1.4-0.98,3.79-1.68,6.87-1.68h13.61c3.23,0,5.47,0.56,6.87,1.68
          c1.4,0.98,2.52,3.37,3.23,6.59l26.93,114.03l29.45-114.03c0.98-3.37,2.24-5.61,3.37-6.59c1.4-0.98,3.51-1.68,6.59-1.68h15.85
          c2.66,0,4.21,1.4,4.21,4.21c0,0.84-0.14,1.68-0.28,2.66c-0.14,0.98-0.56,2.38-1.12,4.21l-41.1,131.56
          c-0.98,3.37-2.24,5.61-3.51,6.59c-1.26,0.98-3.51,1.68-6.45,1.68h-14.59c-3.23,0-5.47-0.56-6.87-1.68
          c-1.4-1.12-2.52-3.37-3.23-6.87l-26.37-109.82l-26.23,109.68c-0.84,3.37-1.82,5.61-3.23,6.87c-1.4,1.12-3.79,1.68-6.87,1.68h-14.73
          V258.4z M430.63,263.03c-8.84,0-17.67-0.98-26.23-3.09c-8.56-2.1-15.15-4.21-19.64-6.87c-2.66-1.54-4.63-3.23-5.33-4.77
          c-0.7-1.54-0.98-3.23-0.98-4.77v-8.7c0-3.51,1.4-5.33,3.93-5.33c0.98,0,2.1,0.14,3.09,0.56c0.98,0.28,2.52,0.98,4.21,1.68
          c5.75,2.52,12.06,4.63,18.79,5.89c6.87,1.4,13.46,2.1,20.2,2.1c10.66,0,19.08-1.82,24.83-5.61s8.84-9.26,8.84-16.13
          c0-4.77-1.54-8.7-4.63-11.92c-3.09-3.23-8.84-6.17-17.25-8.84l-24.69-7.71c-12.48-3.93-21.6-9.68-27.21-17.39
          c-5.61-7.43-8.56-15.85-8.56-24.69c0-7.15,1.54-13.46,4.63-18.94c3.09-5.47,7.15-10.24,12.2-14.03c5.05-3.93,10.94-6.87,17.67-8.84
          s14.03-2.95,21.46-2.95c3.79,0,7.71,0.14,11.36,0.7c3.93,0.56,7.43,1.12,11.08,1.82c3.37,0.84,6.59,1.68,9.68,2.66
          c3.09,0.98,5.47,2.1,7.15,3.09c2.38,1.4,4.07,2.66,5.05,4.21c0.98,1.4,1.54,3.23,1.54,5.61v7.99c0,3.51-1.4,5.47-3.93,5.47
          c-1.4,0-3.51-0.7-6.45-2.1c-9.68-4.49-20.62-6.59-32.68-6.59c-9.68,0-17.39,1.54-22.58,4.77c-5.19,3.23-7.99,8.14-7.99,15.15
          c0,4.77,1.68,8.84,5.05,12.06s9.68,6.45,18.79,9.4l24.12,7.71c12.2,3.93,21.18,9.4,26.37,16.41c5.33,7.01,7.85,15.01,7.85,23.84
          c0,7.29-1.54,14.03-4.49,19.78c-3.09,5.75-7.15,10.94-12.48,15.01c-5.33,4.21-11.64,7.29-18.94,9.54
          C447.32,261.77,439.33,263.03,430.63,263.03z"
        />
        <g>
          <path
            id="XMLID_3_"
            fill="#FF9900"
            d="M462.89,345.79c-55.96,41.38-137.45,63.4-207.44,63.4c-98.04,0-186.41-36.33-253.17-96.5
			c-5.33-4.77-0.56-11.22,5.75-7.43c72.23,41.94,161.3,67.32,253.31,67.32c62.14,0,130.44-12.9,193.28-39.55
			C464.01,328.53,472.01,338.91,462.89,345.79z"
          />
          <path
            id="XMLID_1_"
            fill="#FF9900"
            d="M486.18,319.14c-7.15-9.26-47.27-4.49-65.5-2.24c-5.47,0.7-6.31-4.07-1.4-7.71
			c31.98-22.44,84.58-15.99,90.75-8.56c6.17,7.71-1.68,60.31-31.7,85.42c-4.63,3.93-8.98,1.82-7.01-3.23
			C478.18,366.12,493.33,328.25,486.18,319.14z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Aws;
