import { useToken } from '@chakra-ui/react';
import { AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import isNumber from 'lodash/isNumber';
import React, { useMemo } from 'react';

import { CHART_SIZE_OPTION_TO_BAR_WIDTH } from 'components/DriverChart/BarChart';
import { DEFAULT_DRIVER_CHART_SIZE } from 'config/block';
import theme from 'config/theme';
import { BlockViewAsType, ChartSize } from 'generated/graphql';
import { formatDriverValue } from 'helpers/formatting';
import { getTicks } from 'helpers/number';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartContext from 'hooks/useChartContext';
import { DriverId } from 'reduxStore/models/drivers';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';

const { fontWeights, fontSizes } = theme;

// This value was set through some trial and error to figure out what looked
// reasonable.
const LEFT_OFFSET = 36;

interface Props {
  driverId: DriverId;
}

const TOTAL_TICKS = 5;

const ValueAxis: React.FC<Props> = ({ driverId }) => {
  const { blockId } = useBlockContext();
  const { valueScale, width, format } = useChartContext();
  const [yMax] = valueScale.range();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const size = viewOptions?.chartSize ?? DEFAULT_DRIVER_CHART_SIZE;
  const type = viewOptions.viewAsType;

  const onlyShowZeroLine = type === BlockViewAsType.BarChart && size === ChartSize.Medium;
  const [minVal, maxVal] = valueScale.domain();

  const ticks: number[] = useMemo(() => {
    const crossesZero = minVal < 0 && maxVal > 0;
    if (onlyShowZeroLine) {
      return crossesZero ? [0] : [];
    }

    return getTicks(minVal, maxVal, TOTAL_TICKS);
  }, [maxVal, minVal, onlyShowZeroLine]);

  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );
  const widthOfLastElement =
    type === BlockViewAsType.BarChart ? CHART_SIZE_OPTION_TO_BAR_WIDTH[size] : 0;
  const gridWidth = onlyShowZeroLine
    ? width + widthOfLastElement
    : width + LEFT_OFFSET + widthOfLastElement;

  const [stroke, fill] = useToken('colors', 'gray.400');

  return (
    <Group pointerEvents="none">
      <GridRows
        left={!onlyShowZeroLine ? -LEFT_OFFSET : 0}
        tickValues={ticks}
        scale={valueScale}
        width={gridWidth}
        height={yMax}
        strokeDasharray={!onlyShowZeroLine ? '2,2' : undefined}
        stroke={stroke}
        pointerEvents="none"
      />
      {!onlyShowZeroLine && (
        <AxisLeft
          hideTicks
          hideAxisLine
          tickValues={ticks}
          left={-LEFT_OFFSET}
          scale={valueScale}
          tickFormat={(value: number | { valueOf: () => number }): string => {
            const v = isNumber(value) ? value : value.valueOf();
            return formatDriverValue(v, {
              ...displayConfiguration,
              format,
            });
          }}
          tickLabelProps={() => ({
            fill,
            stroke: 'white',
            strokeWidth: 3,
            paintOrder: 'stroke',
            textAnchor: 'start',
            verticalAnchor: 'middle',
            fontSize: fontSizes.xxxs,
            fontWeight: fontWeights.bold,
            style: {
              userSelect: 'none',
            },
          })}
        />
      )}
    </Group>
  );
};

export default React.memo(ValueAxis);
