import { flatMap, keyBy, sortBy, uniq } from 'lodash';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { DEFAULT_PLAN_NAME } from '@features/Plans';
import { EventEntity } from '@features/Plans/EventEntity';
import { ImpactType } from 'generated/graphql';
import {
  getFieldSpecIdFromCellRef,
  isBusinessObjectFieldTimeSeriesCellRef,
  isBusinessObjectPropertyFieldCellRef,
  isMonthColumnKey,
} from 'helpers/cells';
import { extractMonthKey } from 'helpers/dates';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { getImpactTooltipText } from 'helpers/eventGroups';
import {
  getCellImpactFromCurvePoints,
  getImpactedEntityId,
  getTotalMagnitude,
  getUpdateFromEvents,
} from 'helpers/events';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
  layerParamMemo,
  layerSelectorFactory,
} from 'helpers/layerSelectorFactory';
import { getObjectFieldUUID } from 'helpers/object';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectFieldId, BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverId } from 'reduxStore/models/drivers';
import {
  CellImpact,
  CurvePointTyped,
  DEFAULT_EVENT_GROUP_ID,
  DriverEvent,
  Event,
  EventGroup,
  EventGroupId,
  EventId,
  ObjectFieldEvent,
  PlanTimelineItemRef,
  PopulatedEventGroup,
  isDriverEvent,
  isObjectFieldEvent,
  isSetImpactEvent,
} from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { LiveEditType } from 'reduxStore/reducers/liveEditSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { isAccessibleDriverSelector } from 'selectors/accessibleDriversSelector';
import {
  businessObjectSelector,
  businessObjectsByIdForLayerSelector,
} from 'selectors/businessObjectsSelector';
import {
  planPickerSelectedEventGroupIdSelector,
  planPickerSelectedWithEventGroupSelector,
} from 'selectors/cellPaletteSelector';
import {
  businessObjectIdSelector,
  eventGroupIdSelector,
  fieldSelector,
  paramSelector,
} from 'selectors/constSelectors';
import { DriverForLayerProps } from 'selectors/driversSelector';
import { enableStackedImpactsSelector } from 'selectors/launchDarklySelector';
import {
  currentLayerIdSelector,
  getGivenOrCurrentLayerId,
  layersSelector,
} from 'selectors/layerSelector';
import {
  liveEditSelector,
  liveEditingEventGroupsSelector,
  liveEditingEventsSelector,
} from 'selectors/liveEditSelector';
import {
  cellSelectionMonthKeysByDriverIdSelector,
  objectFieldCellSelectionSelector,
  objectFieldTimeSeriesCellSelectionSelector,
  prevailingActiveCellDriverIdSelector,
  prevailingActiveCellMonthKeySelector,
  prevailingActiveCellObjectFieldId,
  prevailingSelectedDriverIdsSelector,
} from 'selectors/prevailingCellSelectionSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { MonthKey } from 'types/datetime';
import { ParametricSelector, Selector } from 'types/redux';

const EMPTY_EVENTS: Record<EventId, Event> = {};
const EMPTY_EVENT_GROUPS: Record<EventGroupId, EventGroup> = {};
const EMPTY_OBJECT_FIELD_EVENTS: ObjectFieldEvent[] = [];

/** Without live edits by ID */

export const eventsWithoutLiveEditsByIdForLayerSelector: SelectorWithLayerParam<
  Record<EventId, Event>
> = createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
  return layers[layerId]?.events.byId ?? EMPTY_EVENTS;
})(getCacheKeyForLayerSelector);

export const eventGroupsWithoutLiveEditsByIdForLayerSelector: SelectorWithLayerParam<
  Record<EventGroupId, EventGroup>
> = createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
  return layers[layerId]?.eventGroups.byId ?? EMPTY_EVENT_GROUPS;
})(getCacheKeyForLayerSelector);

/** Without live edits */
const eventsWithoutLiveEditsForLayerSelector: SelectorWithLayerParam<Event[]> =
  layerSelectorFactory(eventsWithoutLiveEditsByIdForLayerSelector, (eventsWithoutLiveEditsById) => {
    return Object.values(eventsWithoutLiveEditsById).filter(isNotNull);
  });

const eventGroupsWithoutLiveEditsForLayerSelector: SelectorWithLayerParam<EventGroup[]> =
  layerSelectorFactory(
    eventGroupsWithoutLiveEditsByIdForLayerSelector,
    (eventGroupsWithoutLiveEditsById) => {
      return Object.values(eventGroupsWithoutLiveEditsById).filter(isNotNull);
    },
  );

/** Utility selectors to get ID */
const getEventId: ParametricSelector<EventId, EventId> = (_state, eventId: EventId) => eventId;

const getEventGroupId: ParametricSelector<EventGroupId, EventGroupId> = (
  _state,
  eventGroupId: EventGroupId,
) => eventGroupId;

/** With live edits by ID */

export const eventsByIdForLayerSelector: SelectorWithLayerParam<Record<EventId, Event>> =
  createCachedSelector(
    addLayerParams(eventsWithoutLiveEditsByIdForLayerSelector),
    liveEditingEventsSelector,
    currentLayerIdSelector,
    getGivenOrCurrentLayerId,
    (eventsById, liveEditEvents, currentLayerId, layerId) => {
      // Don't add live-editing event if the given layer ID isn't the current layer ID.
      if (layerId !== currentLayerId) {
        return eventsById;
      }
      if (liveEditEvents != null) {
        return liveEditEvents.reduce(
          (o, event) => ({
            ...o,
            [event.id]: event,
          }),
          eventsById,
        );
      }
      return eventsById;
    },
  )(getCacheKeyForLayerSelector);

export const eventGroupsByIdForLayerSelector: SelectorWithLayerParam<
  Record<EventGroupId, EventGroup>
> = createCachedSelector(
  addLayerParams(eventGroupsWithoutLiveEditsByIdForLayerSelector),
  liveEditingEventGroupsSelector,
  currentLayerIdSelector,
  getGivenOrCurrentLayerId,
  (eventGroupsById, liveEditEventGroups, currentLayerId, layerId) => {
    // Don't add live-editing event group data if the given layer ID isn't
    // the current layer ID.
    if (layerId !== currentLayerId || liveEditEventGroups == null) {
      return eventGroupsById;
    }

    // NOTE: We currently only allow live updating the default start/end dates.
    const liveEventGroups: Record<EventGroupId, EventGroup> = liveEditEventGroups.reduce(
      (acc, update) => ({
        ...acc,
        [update.id]: {
          ...eventGroupsById[update.id],
          ...update,
        },
      }),
      {},
    );

    return {
      ...eventGroupsById,
      ...liveEventGroups,
    };
  },
)(getCacheKeyForLayerSelector);

/** With live edits */
const eventsWithLiveEditsForLayerSelector: SelectorWithLayerParam<Event[]> = createCachedSelector(
  addLayerParams(eventsByIdForLayerSelector),
  (eventsById) => {
    return Object.values(eventsById);
  },
)(getCacheKeyForLayerSelector);

/** Single item with live edits */
export const eventSelector: ParametricSelector<EventId | undefined, Event | undefined> =
  createCachedSelector(
    (state: RootState, _: EventId | undefined) => eventsByIdForLayerSelector(state),
    paramSelector<EventId | undefined>(),
    (eventsById, eventId) => {
      if (eventId == null) {
        return undefined;
      }
      return safeObjGet(eventsById[eventId]);
    },
  )((_state, eventId) => eventId ?? '');

export const eventGroupSelector: ParametricSelector<EventGroupId, EventGroup | undefined> =
  createCachedSelector(
    (state) => eventGroupsWithoutLiveEditsByIdForLayerSelector(state),
    getEventGroupId,
    (groupsById, eventGroupId) => {
      return safeObjGet(groupsById[eventGroupId]);
    },
  )((_state, eventGroupId) => eventGroupId);

/** Single item without live edits */

export const eventWithoutLiveEditsSelector: ParametricSelector<EventId, Event> =
  createCachedSelector(
    (state) => eventsWithoutLiveEditsByIdForLayerSelector(state),
    getEventId,
    (eventsById, eventId) => {
      return eventsById[eventId];
    },
  )((_state, eventId) => eventId);

/** Populated event groups */
export const populatedEventGroupsByIdForLayerSelector: SelectorWithLayerParam<
  NullableRecord<EventGroupId, PopulatedEventGroup>
> = createCachedSelector(
  addLayerParams(eventGroupsByIdForLayerSelector),
  addLayerParams(eventsByIdForLayerSelector),
  (groupsById, eventsById) => populateEventGroups(groupsById, eventsById),
)(getCacheKeyForLayerSelector);

export const populatedEventGroupSelector: ParametricSelector<
  EventGroupId,
  PopulatedEventGroup | undefined
> = createCachedSelector(
  eventGroupsByIdForLayerSelector,
  eventsByIdForLayerSelector,
  getEventGroupId,
  (groupsById, eventsById, eventGroupId) => {
    return populateEventGroup(eventGroupId, groupsById, eventsById);
  },
)((_state, eventGroupId) => eventGroupId);

export const planPickerSelectedEventGroupNameSelector: Selector<string | undefined> =
  createSelector(
    planPickerSelectedWithEventGroupSelector,
    eventGroupsByIdForLayerSelector,
    (withEventGroup, eventGroupsById) => {
      if (withEventGroup == null) {
        return undefined;
      }
      if (withEventGroup.type === 'existing') {
        return withEventGroup != null
          ? eventGroupsById[withEventGroup.eventGroupId]?.name
          : undefined;
      }
      if (withEventGroup.type === 'default') {
        return DEFAULT_PLAN_NAME;
      }
      return withEventGroup.newEventGroup.name;
    },
  );

export const parentIdsByEventAndGroupIdsSelector: SelectorWithLayerParam<
  Record<EventId | EventGroupId, EventGroupId | undefined>
> = createCachedSelector(
  eventsWithoutLiveEditsForLayerSelector,
  eventGroupsWithoutLiveEditsForLayerSelector,
  (events, eventGroups) => {
    const result: Record<EventId | EventGroupId, EventGroupId | undefined> = {};
    events.forEach((event) => {
      result[event.id] = event.parentId;
    });
    eventGroups.forEach((eventGroup) => {
      result[eventGroup.id] = eventGroup.parentId;
    });
    return result;
  },
)(getCacheKeyForLayerSelector);

/** Event and event group hiding */

const visibleEventsForLayerSelector: SelectorWithLayerParam<Event[]> = createCachedSelector(
  addLayerParams(eventsWithLiveEditsForLayerSelector),
  (events) => events.filter((event) => !event.hidden),
)(getCacheKeyForLayerSelector);

const visibleEventsWithoutLiveEditsForLayerSelector: SelectorWithLayerParam<Event[]> =
  createCachedSelector(addLayerParams(eventsWithoutLiveEditsForLayerSelector), (events) =>
    events.filter((event) => !event.hidden),
  )(getCacheKeyForLayerSelector);

export const eventsByDriverIdForLayerSelector: SelectorWithLayerParam<
  Record<DriverId, DriverEvent[]>
> = createCachedSelector(addLayerParams(eventsWithoutLiveEditsForLayerSelector), (events) =>
  groupBy(events.filter(isDriverEvent), ({ driverId }) => driverId),
)(getCacheKeyForLayerSelector);

export const eventsForDriverSelector: ParametricSelector<DriverForLayerProps, DriverEvent[]> =
  createCachedSelector(
    (state: RootState, { layerId }: DriverForLayerProps) =>
      eventsByDriverIdForLayerSelector(state, { layerId }),
    fieldSelector('id'),
    (eventsByDriverId, driverId) => safeObjGet(eventsByDriverId[driverId]) ?? [],
  )(getCacheKeyForLayerSelector);

export const eventsByEventGroupForDriverSelector: ParametricSelector<
  DriverForLayerProps,
  Record<EventGroupId, Event[]>
> = createCachedSelector(eventsForDriverSelector, (eventsForDriver) =>
  groupBy(eventsForDriver, (e) => e.parentId),
)(getCacheKeyForLayerSelector);

const EMPTY_EVENT_IDS: EventId[] = [];
export const driverEventIdsSelector: ParametricSelector<DriverId, EventId[]> = createCachedSelector(
  (state: RootState, driverId: DriverId) => eventsForDriverSelector(state, { id: driverId }),
  (events) => events.map((e) => e.id) ?? EMPTY_EVENT_IDS,
)((_state, driverId) => driverId);

const eventsByObjectFieldIdForLayerSelector: SelectorWithLayerParam<
  Record<BusinessObjectFieldId, ObjectFieldEvent[]>
> = createCachedSelector(addLayerParams(eventsWithoutLiveEditsForLayerSelector), (events) =>
  groupBy(events.filter(isObjectFieldEvent), ({ businessObjectFieldId }) => businessObjectFieldId),
)(getCacheKeyForLayerSelector);

type ObjectFieldForLayerProps = {
  layerId?: LayerId;
  id: BusinessObjectFieldId;
};

export const eventsForObjectFieldSelector: ParametricSelector<
  ObjectFieldForLayerProps,
  ObjectFieldEvent[]
> = createCachedSelector(
  (state: RootState, { layerId }: ObjectFieldForLayerProps) =>
    eventsByObjectFieldIdForLayerSelector(state, { layerId }),
  fieldSelector('id'),
  (eventsByObjectFieldId, objectFieldId) => safeObjGet(eventsByObjectFieldId[objectFieldId]) ?? [],
)(getCacheKeyForLayerSelector);

export const visibleEventsByObjectFieldIdForLayerSelector: SelectorWithLayerParam<
  Record<BusinessObjectFieldId, ObjectFieldEvent[]>
> = createCachedSelector(addLayerParams(visibleEventsForLayerSelector), (events) =>
  groupBy(events.filter(isObjectFieldEvent), ({ businessObjectFieldId }) => businessObjectFieldId),
)(getCacheKeyForLayerSelector);

const visibleEventsWithImpactForLayerSelector: SelectorWithLayerParam<Event[]> =
  createCachedSelector(addLayerParams(visibleEventsForLayerSelector), (visibleEvents) =>
    visibleEvents.filter((event) => {
      if (isSetImpactEvent(event)) {
        return event.customCurvePoints != null && !isEmpty(event.customCurvePoints);
      }
      if (isDriverEvent(event)) {
        const totalImpact = getTotalMagnitude(event);
        return totalImpact != null && totalImpact !== 0;
      }
      return true;
    }),
  )(getCacheKeyForLayerSelector);

export const impactingEventsByFormulaEntityIdForLayerSelector: SelectorWithLayerParam<
  Record<FormulaEntityTypedId['id'], Event[]>
> = createCachedSelector(addLayerParams(visibleEventsWithImpactForLayerSelector), (events) => {
  return groupBy(events, (event) =>
    isDriverEvent(event) ? event.driverId : event.businessObjectFieldId,
  );
})(getCacheKeyForLayerSelector);

/** Events that impact objects */

export const eventWithDirectImpactForObjectSelector: ParametricSelector<
  BusinessObjectId,
  ObjectFieldEvent[]
> = createCachedSelector(
  businessObjectSelector,
  (state) => eventsWithoutLiveEditsForLayerSelector(state),
  (businessObject, events) => {
    const fieldIds = businessObject ? businessObject.fields.map((f) => f.id) : [];
    return events.filter(
      (e) => isObjectFieldEvent(e) && fieldIds.includes(e.businessObjectFieldId),
    ) as ObjectFieldEvent[];
  },
)((_state, objectId) => objectId);

type BusinessObjectFieldForIdAndObjectIdSelectorProps = {
  objectId: BusinessObjectId;
  monthKey: MonthKey;
  fieldSpecId: BusinessObjectFieldSpecId;
  layerId: LayerId | undefined;
};

const getCacheKeyForEventObjectSelector = (
  state: RootState,
  { objectId, monthKey, fieldSpecId, layerId }: BusinessObjectFieldForIdAndObjectIdSelectorProps,
) => `${objectId};${monthKey};${fieldSpecId};${layerId ?? state.dataset.currentLayerId}`;

export const objectPlanImpactTooltipSelector: ParametricSelector<
  BusinessObjectFieldForIdAndObjectIdSelectorProps,
  string | undefined | null
> = createCachedSelector(
  eventGroupsByIdForLayerSelector,
  (state: RootState, { layerId, objectId }: BusinessObjectFieldForIdAndObjectIdSelectorProps) =>
    businessObjectsByIdForLayerSelector(state, layerParamMemo(layerId))[objectId],
  (state: RootState, { layerId }: BusinessObjectFieldForIdAndObjectIdSelectorProps) =>
    visibleEventsByObjectFieldIdForLayerSelector(state, layerParamMemo(layerId)),
  fieldSelector<BusinessObjectFieldForIdAndObjectIdSelectorProps, 'monthKey'>('monthKey'),
  fieldSelector('fieldSpecId'),
  function objectPlanImpactTooltipSelector(
    eventGroupsById,
    businessObject,
    visibleEventsByObjectFieldId,
    monthKey,
    fieldSpecId,
  ) {
    const fieldId = businessObject?.fields.find((f) => f.fieldSpecId === fieldSpecId)?.id;
    if (fieldId == null) {
      return undefined;
    }

    const eventsWithDirectImpact = (visibleEventsByObjectFieldId[fieldId] ?? []).filter((event) => {
      const { customCurvePoints = {} } = event;
      return monthKey in customCurvePoints;
    });

    if (eventsWithDirectImpact.length === 0) {
      return undefined;
    }

    const eventGroupIds = eventsWithDirectImpact.map((event) => event.parentId).filter(isNotNull);
    const tooltipText = getImpactTooltipText(eventGroupsById, eventGroupIds);
    return tooltipText;
  },
)(getCacheKeyForEventObjectSelector);

/** Event groups that impact drivers */

export const visibleEventsWithoutLiveEditsByEntityIdSelector: Selector<
  Record<FormulaEntityTypedId['id'], Event[]>
> = createSelector(
  (state: RootState) => visibleEventsWithoutLiveEditsForLayerSelector(state),
  function visibleEventsWithoutLiveEditsByEntityIdSelector(events) {
    return groupBy(events, getImpactedEntityId);
  },
);

export const populatedEventGroupsWithoutLiveEditsByIdForLayerSelector: SelectorWithLayerParam<
  NullableRecord<EventGroupId, PopulatedEventGroup>
> = createCachedSelector(
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  eventsWithoutLiveEditsByIdForLayerSelector,
  (groupsById, eventsById) => populateEventGroups(groupsById, eventsById),
)(getCacheKeyForLayerSelector);

export const populatedEventGroupsWithLiveEditsByIdSelector: Selector<
  NullableRecord<EventGroupId, PopulatedEventGroup>
> = createSelector(
  (state: RootState) => eventGroupsByIdForLayerSelector(state),
  (state: RootState) => eventsByIdForLayerSelector(state),
  (groupsById, eventsById) => populateEventGroups(groupsById, eventsById),
);

export const populatedEventGroupWithoutLiveEditsSelector: ParametricSelector<
  EventGroupId,
  PopulatedEventGroup | undefined
> = createCachedSelector(
  (state: RootState) => populatedEventGroupsWithoutLiveEditsByIdForLayerSelector(state),
  getEventGroupId,
  (populatedEventGroupsById, eventGroupId) => {
    return populatedEventGroupsById[eventGroupId];
  },
)((_state, eventGroupId) => eventGroupId);

export const rootInitiativesWithoutLiveEditsForLayerSelector: SelectorWithLayerParam<{
  rootEventGroups: EventGroupId[];
  rootEvents: EventId[];
}> = createCachedSelector(
  eventsWithoutLiveEditsForLayerSelector,
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  (events, groups) => ({
    rootEventGroups: Object.values(groups)
      .filter((group) => group.parentId == null)
      .map((g) => g.id),
    rootEvents: events.filter((event) => event.parentId == null).map((ev) => ev.id),
  }),
)({ keySelector: getCacheKeyForLayerSelector, selectorCreator: createDeepEqualSelector });

const populateEventGroup = (
  groupId: EventGroupId,
  eventGroupsById: Record<EventGroupId, EventGroup>,
  eventsById: Record<EventId, Event>,
): PopulatedEventGroup | undefined => {
  const group = safeObjGet(eventGroupsById[groupId]);
  if (group == null) {
    return undefined;
  }

  return {
    ...group,
    events: Object.keys(group.eventIds)
      .map((id) => eventsById[id])
      .filter(isNotNull),
    eventGroups: Object.keys(group.eventGroupIds)
      .map((id) => populateEventGroup(id, eventGroupsById, eventsById))
      .filter(isNotNull),
  };
};

const populateEventGroups = (
  groupsById: Record<EventGroupId, EventGroup>,
  eventsById: Record<EventId, Event>,
): NullableRecord<EventGroupId, PopulatedEventGroup> => {
  return Object.keys(groupsById).reduce(
    (out: NullableRecord<EventGroupId, PopulatedEventGroup>, groupId) => {
      out[groupId] = populateEventGroup(groupId, groupsById, eventsById);
      return out;
    },
    {},
  );
};

export const businessObjectContextByEventGroupIdSelector: Selector<
  Record<EventGroupId, { id: BusinessObjectId; eventGroupName: string }>
> = createCachedSelector(addLayerParams(businessObjectsByIdForLayerSelector), (objsById) => {
  return Object.values(objsById).reduce(
    (curr, obj) => {
      if (obj.defaultEventGroupId == null) {
        return curr;
      }
      return {
        ...curr,
        [obj.defaultEventGroupId]: {
          id: obj.id,
          eventGroupName: `${obj.name}`,
        },
      };
    },
    {} as Record<EventGroupId, { id: BusinessObjectId; eventGroupName: string }>,
  );
})(getCacheKeyForLayerSelector);

const alphabeticallySortedEventGroupsWithoutLiveEditsForLayerSelector: SelectorWithLayerParam<
  EventGroup[]
> = createCachedSelector(
  addLayerParams(eventGroupsWithoutLiveEditsForLayerSelector),
  addLayerParams(businessObjectContextByEventGroupIdSelector),
  (eventGroups, businessObjectContextByEventGroupId) => {
    // We remove auto-created default event groups for business objects (not the Default Plans
    // event group). We used to create them automatically and some orgs have a lot of them
    // that aren't needed in the plan picker.
    const filteredEventGroups = eventGroups.filter((eventGroup) => {
      const objContextForEventGroup = businessObjectContextByEventGroupId[eventGroup.id];
      const relatedObjectId = objContextForEventGroup?.id;

      return relatedObjectId == null || eventGroup.id === DEFAULT_EVENT_GROUP_ID;
    });

    return sortBy(filteredEventGroups, (eventGroup) => eventGroup.name.toLowerCase());
  },
)(getCacheKeyForLayerSelector);

export const directlyImpactedDriverIdsSelector = createCachedSelector(
  populatedEventGroupSelector,
  (eventGroup) => {
    return eventGroup?.events.filter(isDriverEvent).map((event) => event.driverId);
  },
)((_state, eventGroupId) => eventGroupId);

const businessObjectEventsSelector = createCachedSelector(
  eventsWithoutLiveEditsForLayerSelector,
  (allEvents) => {
    return allEvents.filter(isObjectFieldEvent).filter((o) => o.businessObjectFieldId != null);
  },
)(getGivenOrCurrentLayerId);

export const eventsForBusinessObjectSelector: ParametricSelector<
  BusinessObjectId,
  ObjectFieldEvent[]
> = createCachedSelector(
  businessObjectIdSelector,
  (state) => businessObjectEventsSelector(state),
  (state) => businessObjectsByIdForLayerSelector(state),
  (objId, objectEvents, objectsById) => {
    const obj = objectsById[objId];
    if (obj == null) {
      return EMPTY_OBJECT_FIELD_EVENTS;
    }

    const eventsByObjFieldId = groupBy(objectEvents, 'businessObjectFieldId');
    return obj.fields.flatMap((f) => eventsByObjFieldId[f.id] ?? EMPTY_OBJECT_FIELD_EVENTS);
  },
)((_state, objId) => objId);

export const defaultEventGroupSelector: Selector<EventGroup | undefined> = createSelector(
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  (eventGroupsById) => {
    return eventGroupsById[DEFAULT_EVENT_GROUP_ID];
  },
);

export const allRefsForGroupSelector: ParametricSelector<
  EventGroupId,
  PlanTimelineItemRef[] | undefined
> = createCachedSelector(
  eventGroupIdSelector,
  (state) => populatedEventGroupsByIdForLayerSelector(state),
  (groupId, populatedGroupsById) => {
    const group = populatedGroupsById[groupId];
    if (group == null) {
      return undefined;
    }

    const refs: PlanTimelineItemRef[] = [];
    const walk = (currGroup: PopulatedEventGroup | null) => {
      if (currGroup == null) {
        return;
      }
      const thisRef = { id: currGroup.id, type: 'group' as const };
      const eventRefs = currGroup.events.map((e) => ({ id: e.id, type: 'event' as const }));
      refs.push(thisRef, ...eventRefs);
      currGroup.eventGroups.forEach(walk);
    };
    walk(group);

    return refs;
  },
)((_state, id) => id);

export const eventLiveEditWillClearCurvePointsSelector: Selector<boolean> = createSelector(
  liveEditSelector,
  eventsWithoutLiveEditsByIdForLayerSelector,
  enableStackedImpactsSelector,
  (liveEdit, originalEventsById, enableStackedImpacts) => {
    if (liveEdit?.type !== LiveEditType.Event && liveEdit?.type !== LiveEditType.MultiEvent) {
      return false;
    }

    const originalDriverEvents = Object.values(originalEventsById).filter(isDriverEvent);
    const originalEventsByDriverId = groupBy(originalDriverEvents, (event) => event.driverId);

    const updatedEvents = liveEdit.type === LiveEditType.Event ? [liveEdit.event] : liveEdit.events;
    const updatedEventsById = keyBy(updatedEvents, (event) => event.id);

    return updatedEvents.some((updatedEvent) => {
      if (!isDriverEvent(updatedEvent)) {
        return false;
      }
      const originalEvent = originalEventsById[updatedEvent.id];
      const mutation = getUpdateFromEvents(originalEvent, updatedEvent);
      const curveUpdates = mutation?.update.customCurvePoints ?? [];

      return curveUpdates.some(({ time, value }) => {
        if (value == null) {
          return false;
        }

        const monthKey = extractMonthKey(time);
        const otherEventsForDriver = originalEventsByDriverId[updatedEvent.driverId];

        return otherEventsForDriver.some((otherEvent) => {
          if (
            enableStackedImpacts &&
            updatedEvent.impactType === ImpactType.Delta &&
            otherEvent.impactType === ImpactType.Delta
          ) {
            return false;
          }

          if (otherEvent.id === updatedEvent.id) {
            return false;
          }

          // If the event is also being updated, use the updated curve points
          const otherEventCurvePoints =
            updatedEventsById[otherEvent.id]?.customCurvePoints ?? otherEvent.customCurvePoints;

          const monthValue = otherEventCurvePoints?.[monthKey]?.value;

          return monthValue != null;
        });
      });
    });
  },
);

function curvePointOnMonth(
  { customCurvePoints, impactType }: Event,
  monthKey: MonthKey,
): CurvePointTyped | undefined {
  if (customCurvePoints == null || Object.keys(customCurvePoints).length === 0) {
    return undefined;
  }

  const maybeCurvePoint = customCurvePoints[monthKey];

  if (maybeCurvePoint == null) {
    return undefined;
  }

  return { ...maybeCurvePoint, impactType };
}

const activeCellEventsSelector: SelectorWithLayerParam<Event[]> = createCachedSelector(
  prevailingActiveCellMonthKeySelector,
  prevailingActiveCellDriverIdSelector,
  prevailingActiveCellObjectFieldId,
  eventsByDriverIdForLayerSelector,
  eventsByObjectFieldIdForLayerSelector,
  (monthKey, driverId, objectFieldId, eventsByDriverId, eventsByObjectFieldId) => {
    if (monthKey == null) {
      return [];
    }

    if (driverId != null) {
      const eventsForDriver = eventsByDriverId[driverId] ?? [];

      return eventsForDriver
        .map((e) => {
          const curvePoint = curvePointOnMonth(e, monthKey);
          return curvePoint != null ? e : null;
        })
        .filter(isNotNull);
    }

    if (objectFieldId != null) {
      const eventsForObjectField = eventsByObjectFieldId[objectFieldId] ?? [];

      return eventsForObjectField
        .map((e) => {
          const curvePoint = curvePointOnMonth(e, monthKey);
          return curvePoint != null ? e : null;
        })
        .filter(isNotNull);
    }

    return [];
  },
)(getCacheKeyForLayerSelector);

export const activeCellImpactSelector: SelectorWithLayerParam<CellImpact | null> =
  createCachedSelector(
    prevailingActiveCellMonthKeySelector,
    activeCellEventsSelector,
    (monthKey, events) => {
      if (events.length === 0 || monthKey == null) {
        return null;
      }

      const curvePointsOnMonth = events
        .map((event) => {
          const curvePoint = curvePointOnMonth(event, monthKey);
          return curvePoint != null
            ? { curvePoint, eventId: event.id, eventGroupId: event.parentId ?? null }
            : null;
        })
        .filter(isNotNull);

      if (curvePointsOnMonth.length === 0) {
        return null;
      }

      return getCellImpactFromCurvePoints(curvePointsOnMonth);
    },
  )(getCacheKeyForLayerSelector);

export const activeCellImpactEventGroupIdsSelector: SelectorWithLayerParam<EventGroupId[]> =
  createCachedSelector(activeCellImpactSelector, (cellImpact) => {
    if (cellImpact == null) {
      return [];
    }

    if (cellImpact.impactType === ImpactType.Delta) {
      return cellImpact.valuesWithEventGroups.map((v) => v.eventGroupId).filter(isNotNull);
    }

    return cellImpact.eventGroupId != null ? [cellImpact.eventGroupId] : [];
  })(getCacheKeyForLayerSelector);

export const cellSelectionMonthKeysWithObjectPropertiesSelector: Selector<
  Array<{
    monthKeys: MonthKey[];
    objectId: BusinessObjectId;
    objectFieldSpecId: BusinessObjectFieldSpecId;
    objectFieldId: BusinessObjectFieldId;
  }>
> = createSelector(
  objectFieldCellSelectionSelector,
  objectFieldTimeSeriesCellSelectionSelector,
  businessObjectsByIdForLayerSelector,
  (objectFieldCellSelection, objectFieldTimeSeriesCellSelection, businessObjectsById) => {
    const selectedCells = [
      ...(objectFieldCellSelection?.selectedCells ?? []),
      ...(objectFieldTimeSeriesCellSelection?.selectedCells ?? []),
    ];

    const selectedCellMonthsAndObjectFields = selectedCells
      ?.map((cell) => {
        if (
          !isBusinessObjectPropertyFieldCellRef(cell) &&
          !isBusinessObjectFieldTimeSeriesCellRef(cell)
        ) {
          return null;
        }

        const { objectId } = cell.rowKey;
        const objectFieldSpecId = getFieldSpecIdFromCellRef(cell);
        const monthKey = isMonthColumnKey(cell.columnKey) ? cell.columnKey.monthKey : null;

        if (objectId == null || monthKey == null || objectFieldSpecId == null) {
          return null;
        }

        const businessObject = businessObjectsById[objectId];
        const objectField = businessObject.fields.find(
          (field) => field.fieldSpecId === objectFieldSpecId,
        );

        return {
          monthKey,
          objectId,
          objectFieldSpecId,
          objectFieldId: objectField?.id ?? getObjectFieldUUID(objectId, objectFieldSpecId),
        };
      })
      .filter(isNotNull);

    const groupedByObjectFieldId = groupBy(
      selectedCellMonthsAndObjectFields,
      ({ objectFieldId }) => objectFieldId,
    );

    return Object.values(groupedByObjectFieldId).map((items) => {
      const { objectId, objectFieldSpecId, objectFieldId } = items[0];

      return {
        objectId,
        objectFieldSpecId,
        objectFieldId,
        monthKeys: items.map((item) => item.monthKey),
      };
    });
  },
);

const eventGroupIdsImpactingObjectFieldCellSelectionSelector: SelectorWithLayerParam<Set<string>> =
  createCachedSelector(
    cellSelectionMonthKeysWithObjectPropertiesSelector,
    eventsByObjectFieldIdForLayerSelector,
    (monthKeysWithObjectField, eventsByObjectFieldId) => {
      const eventGroupIds = new Set<EventGroupId>();

      monthKeysWithObjectField.forEach(({ objectFieldId, monthKeys }) => {
        const eventsForField = eventsByObjectFieldId[objectFieldId] ?? [];

        const eventsWithImpact = eventsForField.filter(
          (event) =>
            event.customCurvePoints != null &&
            monthKeys.some((monthKey) => event.customCurvePoints?.[monthKey] != null),
        );

        eventsWithImpact.forEach((event) => {
          if (event.parentId != null) {
            eventGroupIds.add(event.parentId);
          }
        });
      });

      return eventGroupIds;
    },
  )(getCacheKeyForLayerSelector);

const eventGroupIdsImpactingDriverCellSelectionSelector: SelectorWithLayerParam<Set<string>> =
  createCachedSelector(
    prevailingSelectedDriverIdsSelector,
    cellSelectionMonthKeysByDriverIdSelector,
    eventsByDriverIdForLayerSelector,
    (driverIds, monthKeysByDriverId, eventsByDriverId) => {
      const eventGroupIds = new Set<EventGroupId>();

      driverIds.forEach((driverId) => {
        const selectedMonthKeys = monthKeysByDriverId[driverId] ?? [];
        const eventsForDriver = eventsByDriverId[driverId] ?? [];

        const driverEventGroupIds = flatMap(eventsForDriver, (event) => {
          return selectedMonthKeys
            .filter(
              (monthKey) =>
                event.customCurvePoints != null && event.customCurvePoints[monthKey] != null,
            )
            .map(() => event.parentId)
            .filter(isNotNull);
        });

        uniq(driverEventGroupIds).forEach((groupId) => eventGroupIds.add(groupId));
      });

      return eventGroupIds;
    },
  )(getCacheKeyForLayerSelector);

export const eventGroupIdsImpactingCellSelectionSelector: SelectorWithLayerParam<Set<string>> =
  createCachedSelector(
    eventGroupIdsImpactingDriverCellSelectionSelector,
    eventGroupIdsImpactingObjectFieldCellSelectionSelector,

    (eventGroupIdsImpactingDriverCellSelection, eventGroupIdsImpactingObjectFieldCellSelection) => {
      return new Set([
        ...eventGroupIdsImpactingDriverCellSelection,
        ...eventGroupIdsImpactingObjectFieldCellSelection,
      ]);
    },
  )(getCacheKeyForLayerSelector);

// Let's say there is already a plan on the cell, and the user starts editing the cell
// and selects a new plan from the plan picker. At this point, we want the newly
// selected plan to be checked and the currently existing plan to be unchecked.
export const planPickerSelectedEventGroupIdsSelector: SelectorWithLayerParam<Set<EventGroupId>> =
  createCachedSelector(
    planPickerSelectedEventGroupIdSelector,
    eventGroupIdsImpactingCellSelectionSelector,
    (planPickerSelectedEventGroupId, eventGroupIdsImpactingCellSelection) => {
      if (planPickerSelectedEventGroupId != null) {
        return new Set([planPickerSelectedEventGroupId]);
      }
      return eventGroupIdsImpactingCellSelection;
    },
  )(getCacheKeyForLayerSelector);

type EventGroupForCellSelection = Pick<EventGroup, 'id' | 'name'> & { isNew?: boolean };
export const sortedEventGroupsForCellSelectionSelector: SelectorWithLayerParam<
  EventGroupForCellSelection[]
> = createCachedSelector(
  alphabeticallySortedEventGroupsWithoutLiveEditsForLayerSelector,
  planPickerSelectedEventGroupIdsSelector,
  planPickerSelectedWithEventGroupSelector,
  (
    alphabeticallySortedEventGroups,
    planPickerSelectedEventGroupIds,
    planPickerSelectedWithEventGroup,
  ) => {
    const selectedEventGroups: EventGroupForCellSelection[] =
      alphabeticallySortedEventGroups.filter((eventGroup) =>
        planPickerSelectedEventGroupIds.has(eventGroup.id),
      );

    // If the user created a new event group through the plan picker while editing an impact, it may
    // not have actually been created yet, so we inject it manually into the list.
    if (planPickerSelectedWithEventGroup?.type === 'new') {
      selectedEventGroups.push({ ...planPickerSelectedWithEventGroup.newEventGroup, isNew: true });
    }

    const nonSelectedEventGroups = alphabeticallySortedEventGroups.filter(
      (eventGroup) => !planPickerSelectedEventGroupIds.has(eventGroup.id),
    );
    return [...selectedEventGroups, ...nonSelectedEventGroups];
  },
)(getCacheKeyForLayerSelector);

export const cellSelectionHasCellWithoutEventSelector: SelectorWithLayerParam<boolean> =
  createCachedSelector(
    prevailingSelectedDriverIdsSelector,
    cellSelectionMonthKeysByDriverIdSelector,
    eventsByDriverIdForLayerSelector,
    cellSelectionMonthKeysWithObjectPropertiesSelector,
    eventsByObjectFieldIdForLayerSelector,
    (
      driverIds,
      monthKeysByDriverId,
      eventsByDriverId,
      objectFieldCellSelections,
      eventsByObjectFieldId,
    ) => {
      // Check drivers
      for (const driverId of driverIds) {
        const selectedMonthKeys = monthKeysByDriverId[driverId] ?? [];
        const eventsForDriver = eventsByDriverId[driverId] ?? [];

        const someMonthHasNoEvent = selectedMonthKeys.some((monthKey) =>
          eventsForDriver.every((event) => event.customCurvePoints?.[monthKey] == null),
        );

        if (someMonthHasNoEvent) {
          return true;
        }
      }

      // Check fields
      for (const { monthKeys, objectFieldId } of objectFieldCellSelections) {
        const eventsForObjectField = eventsByObjectFieldId[objectFieldId] ?? [];
        const someMonthHasNoEvent = monthKeys.some((monthKey) =>
          eventsForObjectField.every((event) => event.customCurvePoints?.[monthKey] == null),
        );

        if (someMonthHasNoEvent) {
          return true;
        }
      }

      return false;
    },
  )(getCacheKeyForLayerSelector);

export const eventGroupsImpactingCellSelectionSelector: SelectorWithLayerParam<EventGroup[]> =
  createCachedSelector(
    eventGroupIdsImpactingCellSelectionSelector,
    eventGroupsByIdForLayerSelector,
    (eventGroupIdsImpactingCellSelection, eventGroupsById) => {
      const eventGroupIds = Array.from(eventGroupIdsImpactingCellSelection);
      const eventGroups = eventGroupIds.map((id) => eventGroupsById[id]).filter(isNotNull);

      return eventGroups;
    },
  )(getCacheKeyForLayerSelector);

type CanTagCellWithEventGroupSelectorProps = {
  driverId: string;
  blockId: string;
};

export const isEditableDriverForecastSelector: ParametricSelector<
  { driverId: string; blockId: string },
  boolean
> = createCachedSelector(
  (state: RootState, { blockId }: CanTagCellWithEventGroupSelectorProps) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  (state: RootState, { driverId }: CanTagCellWithEventGroupSelectorProps) =>
    isAccessibleDriverSelector(state, { id: driverId }),
  (layerComparisons, isAccessibleDriver) => {
    const isComparingLayers = layerComparisons.length > 0;
    const isEditableForecast = !isComparingLayers && isAccessibleDriver;

    return isEditableForecast;
  },
)((_state, params) => `${params.driverId}-${params.blockId}`);

type DriverAtMonthKey = { driverId: DriverId; monthKey: MonthKey };
const cacheKeyForDriverAtMonth = (_state: RootState, { driverId, monthKey }: DriverAtMonthKey) =>
  `${driverId}:${monthKey}`;

export const eventsImpactingDriverAndMonthKeySelector: ParametricSelector<
  DriverAtMonthKey,
  Event[]
> = createCachedSelector(
  (state: RootState, { driverId }: DriverAtMonthKey) =>
    eventsForDriverSelector(state, { id: driverId }),
  fieldSelector('monthKey'),
  (events, monthKey) => {
    return events.filter((e) => e.customCurvePoints?.[monthKey] != null);
  },
)(cacheKeyForDriverAtMonth);

type ObjectFieldAtMonthKey = { objectFieldId: BusinessObjectFieldId; monthKey: MonthKey };
const cacheKeyForObjectFieldAtMonth = (
  _state: RootState,
  { objectFieldId, monthKey }: ObjectFieldAtMonthKey,
) => `${objectFieldId}:${monthKey}`;

export const eventsImpactingObjectFieldAndMonthKeySelector: ParametricSelector<
  ObjectFieldAtMonthKey,
  Event[]
> = createCachedSelector(
  (state: RootState, { objectFieldId }: ObjectFieldAtMonthKey) =>
    eventsForObjectFieldSelector(state, { id: objectFieldId }),
  fieldSelector('monthKey'),
  (events, monthKey) => {
    return events.filter((e) => e.customCurvePoints?.[monthKey] != null);
  },
)(cacheKeyForObjectFieldAtMonth);

export type EventEntityAndMonthKey = EventEntity & { monthKey: MonthKey };

const cacheKeyForEventEntityAtMonth = (_state: RootState, entity: EventEntityAndMonthKey) => {
  if (entity.type === 'driver') {
    return `${entity.driverId}:${entity.monthKey}`;
  }
  return `${entity.objectFieldId}:${entity.monthKey}`;
};

export const eventsImpactingEventEntityAndMonthKeySelector: ParametricSelector<
  EventEntityAndMonthKey,
  Event[]
> = createCachedSelector(
  (state: RootState, entity: EventEntityAndMonthKey) => {
    return entity.type === 'driver'
      ? eventsImpactingDriverAndMonthKeySelector(state, entity)
      : eventsImpactingObjectFieldAndMonthKeySelector(state, entity);
  },
  (curvePoint) => {
    return curvePoint;
  },
)(cacheKeyForEventEntityAtMonth);
