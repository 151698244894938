import { InternalPage } from 'config/internalPages/internalPage';
import {
  ACTUALS_FORMULA_COLUMN_TYPE,
  COLUMN_TYPE_TO_DEFAULT_WIDTH,
  FORECAST_FORMULA_COLUMN_TYPE,
  INITIAL_VALUE_COLUMN_TYPE,
  MODEL_VIEW_DATA_COLUMN_TYPE,
  NAME_COLUMN_TYPE,
  PROPERTY_COLUMN_TYPE,
} from 'config/modelView';
import {
  BlockColumnOptionsInput,
  BlockComparisonsInput,
  BlockCreateInput,
  BlockType,
  BlocksPageCreateInput,
} from 'generated/graphql';
import { uuidv4 } from 'helpers/uuidv4';

// Increment this version if you make a change to the config below.
const VERSION = 2;

export const SCENARIO_COMPARISON_PAGE_TYPE = 'scenarioComparison';

const DEFAULT_DRIVER_COLUMNS: BlockColumnOptionsInput[] = [
  {
    key: NAME_COLUMN_TYPE,
    visible: true,
    width:
      COLUMN_TYPE_TO_DEFAULT_WIDTH.name + COLUMN_TYPE_TO_DEFAULT_WIDTH[INITIAL_VALUE_COLUMN_TYPE],
  },
  {
    key: MODEL_VIEW_DATA_COLUMN_TYPE,
    visible: true,
    width: COLUMN_TYPE_TO_DEFAULT_WIDTH.data,
  },
];
export const DRIVER_FORMULA_COLUMNS: BlockColumnOptionsInput[] = [
  {
    key: FORECAST_FORMULA_COLUMN_TYPE,
    visible: true,
    width: COLUMN_TYPE_TO_DEFAULT_WIDTH.formula,
  },
  {
    key: ACTUALS_FORMULA_COLUMN_TYPE,
    visible: true,
    width: COLUMN_TYPE_TO_DEFAULT_WIDTH.actualsFormula,
  },
];

const DEFAULT_DATABASE_COLUMNS: BlockColumnOptionsInput[] = [
  {
    key: PROPERTY_COLUMN_TYPE,
    visible: true,
    width: COLUMN_TYPE_TO_DEFAULT_WIDTH.name,
  },
  {
    key: INITIAL_VALUE_COLUMN_TYPE,
    visible: true,
    width: COLUMN_TYPE_TO_DEFAULT_WIDTH[INITIAL_VALUE_COLUMN_TYPE],
  },
  {
    key: MODEL_VIEW_DATA_COLUMN_TYPE,
    visible: true,
    width: COLUMN_TYPE_TO_DEFAULT_WIDTH.data,
  },
];

export const scenarioComparisonPage = {
  internalPageType: SCENARIO_COMPARISON_PAGE_TYPE,
  version: VERSION,
  makeWithBlockComparisons: ({
    comparisons,
  }: {
    comparisons?: BlockComparisonsInput;
  }): InternalPage => {
    const pageId = uuidv4();
    const internalPageType = SCENARIO_COMPARISON_PAGE_TYPE;
    const version = VERSION;
    const blocksPageCreateInput: BlocksPageCreateInput = {
      id: pageId,
      name: 'Scenario Comparison',
      internalPageType,
      internalPageVersion: version,
    };
    const blocksCreateInputs: BlockCreateInput[] = [
      {
        id: uuidv4(),
        type: BlockType.DriverGrid,
        name: 'Drivers',
        pageId,
        blockConfig: {
          columns: [...DEFAULT_DRIVER_COLUMNS, ...DRIVER_FORMULA_COLUMNS],
          comparisons,
        },
      },
      {
        id: uuidv4(),
        type: BlockType.ObjectGrid,
        name: 'Databases',
        pageId,
        blockConfig: {
          columns: DEFAULT_DATABASE_COLUMNS,
          comparisons,
        },
      },
    ];
    return { internalPageType, version, blocksPageCreateInput, blocksCreateInputs };
  },
};
