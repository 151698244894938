import { Box } from '@chakra-ui/react';

const DriverChartBorder: React.FC = () => (
  <Box
    pointerEvents="none"
    position="absolute"
    top="-1px"
    bottom={0}
    w="full"
    borderColor="gray.300"
    borderStyle="dashed"
    borderTopWidth="px"
    borderBottomWidth="px"
    borderLeftWidth="px"
  />
);

export default DriverChartBorder;
