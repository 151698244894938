import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  layout: 'horizontal' | 'vertical';
  leftWidthPercent: number;
  topHeightPercent: number;
}

const initialState: State = {
  layout: 'horizontal',
  leftWidthPercent: 50,
  topHeightPercent: 50,
};

export type IntegrationQueryLayout = typeof initialState;

const integrationQueryLayoutSlice = createSlice({
  name: 'integrationQueryLayout',
  initialState,
  reducers: {
    setLeftWidth: (state, action: PayloadAction<number>) => {
      state.leftWidthPercent = action.payload;
    },
    setTopHeight: (state, action: PayloadAction<number>) => {
      state.topHeightPercent = action.payload;
    },
    setLayout: (state, action: PayloadAction<'horizontal' | 'vertical'>) => {
      state.layout = action.payload;
    },
  },
});

export const { setLeftWidth, setTopHeight, setLayout } = integrationQueryLayoutSlice.actions;

export default integrationQueryLayoutSlice.reducer;
