import classNames from 'classnames';
import React from 'react';

import { ValueType } from 'generated/graphql';

import styles from './DisplayCell.module.scss';

interface Props {
  align?: 'left' | 'right';
  children: JSX.Element | undefined;
  valueType: ValueType;
  isPlaceholder?: boolean;
}

const DisplayCellLight: React.FC<Props> = ({ children, valueType, align, isPlaceholder }) => {
  const isNumeric = valueType === ValueType.Number;

  return (
    <div
      data-testid="display-cell-light"
      className={classNames(styles.displayCell, {
        [styles.isLeftAligned]: align === 'left',
        [styles.isNumber]: isNumeric,
      })}
    >
      {!isPlaceholder && children}
    </div>
  );
};
export default React.memo(DisplayCellLight);
