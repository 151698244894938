import { keyBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { ValueType } from 'generated/graphql';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
  layerParamMemo,
} from 'helpers/layerSelectorFactory';
import { getObjectFieldUUID } from 'helpers/object';
import { safeObjGet } from 'helpers/typescript';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObjectField,
  BusinessObjectFieldId,
  BusinessObjectId,
  PopulatedBusinessObjectField,
} from 'reduxStore/models/businessObjects';
import { DimensionId } from 'reduxStore/models/dimensions';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  businessObjectSpecSelector,
  businessObjectSpecsByIdForLayerSelector,
  businessObjectSpecsForLayerSelector,
} from 'selectors/businessObjectSpecsSelector';
import {
  businessObjectSpecsByObjectIdSelector,
  businessObjectsByIdForLayerSelector,
  businessObjectsForAllLayersSelector,
  businessObjectsForLayerSelector,
} from 'selectors/businessObjectsSelector';
import {
  businessObjectFieldIdSelector,
  fieldSelector,
  paramSelector,
} from 'selectors/constSelectors';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { cacheKeyForDriverForLayerSelector } from 'selectors/driversSelector';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import { isRunwayEmployeeSelector } from 'selectors/loginSelector';
import { ParametricSelector } from 'types/redux';

export type FieldSpecForLayerProps = {
  id: BusinessObjectFieldSpecId;
  layerId?: LayerId;
};

export const cacheKeyForFieldSpecForLayer = (
  state: RootState,
  { layerId, id }: FieldSpecForLayerProps,
) => `${getGivenOrCurrentLayerId(state, { layerId })}.${id}`;

export const businessObjectFieldSpecByIdSelector: SelectorWithLayerParam<
  Record<BusinessObjectFieldSpecId, BusinessObjectFieldSpec | undefined>
> = createCachedSelector(
  addLayerParams(businessObjectSpecsForLayerSelector),
  function businessObjectFieldSpecByIdSelector(objectSpecs) {
    const byId: Record<BusinessObjectFieldSpecId, BusinessObjectFieldSpec | undefined> = {};
    objectSpecs.forEach((spec) => {
      spec.fields.forEach((field) => {
        byId[field.id] = field;
      });
    });
    return byId;
  },
)(getCacheKeyForLayerSelector);

/*
 * Note: this is quite inefficient, especially as the number of objects in a dataset increases.
 * If we run into performance issues, we should add the denormalized object id in the object field event.
 */
export const businessObjectFieldByIdForLayerSelector: SelectorWithLayerParam<
  Record<BusinessObjectFieldId, PopulatedBusinessObjectField>
> = createCachedSelector(
  addLayerParams(businessObjectsForLayerSelector),
  addLayerParams(businessObjectSpecsByIdForLayerSelector),
  addLayerParams(businessObjectFieldSpecByIdSelector),
  (objects, objectSpecs, objectFieldSpecs) => {
    return objects.reduce(
      (res, obj) => {
        const spec = objectSpecs[obj.specId];
        obj.fields.forEach((field) => {
          const fieldSpec = objectFieldSpecs[field.fieldSpecId];
          if (fieldSpec != null) {
            res[field.id] = {
              ...field,
              objectId: obj.id,
              objectName: obj.name,
              objectSpecId: spec.id,
              objectSpecName: spec.name,
              fieldSpec,
              isStartDate: spec.startFieldId === field.fieldSpecId,
            };
          }
        });
        return res;
      },
      {} as Record<BusinessObjectFieldId, PopulatedBusinessObjectField>,
    );
  },
)(getCacheKeyForLayerSelector);

/**
 * This selector fills in the field ids for objects that don't have their fields
 * populated yet.
 */
export const businessObjectFieldIdsByFieldSpecIdSelector: SelectorWithLayerParam<
  Record<BusinessObjectFieldSpecId, BusinessObjectFieldId[]>
> = createCachedSelector(
  addLayerParams(businessObjectsForLayerSelector),
  addLayerParams(businessObjectSpecsByIdForLayerSelector),
  (objects, objectSpecsById) => {
    return objects.reduce(
      (agg, object) => {
        const objectSpec = safeObjGet(objectSpecsById[object.specId]);
        // Not sure why but this happens in the large test org
        if (objectSpec == null) {
          object.fields.forEach((field) => {
            const fieldSpecId = field.fieldSpecId;
            if (agg[fieldSpecId] == null) {
              agg[fieldSpecId] = [];
            }
            agg[fieldSpecId].push(field.id);
          });
          return agg;
        }
        const objectFieldsByFieldSpecId = keyBy(object.fields, 'fieldSpecId');
        objectSpec.fields.forEach((fieldSpec) => {
          const fieldSpecId = fieldSpec.id;
          if (agg[fieldSpecId] == null) {
            agg[fieldSpecId] = [];
          }
          agg[fieldSpecId].push(
            objectFieldsByFieldSpecId[fieldSpecId]?.id ??
              getObjectFieldUUID(object.id, fieldSpecId),
          );
        });

        return agg;
      },
      {} as Record<BusinessObjectFieldSpecId, BusinessObjectFieldId[]>,
    );
  },
)(getCacheKeyForLayerSelector);

export const businessObjectFieldByFieldIdSelector: SelectorWithLayerParam<
  Record<
    BusinessObjectFieldId,
    { objectId: BusinessObjectId; fieldSpecId: BusinessObjectFieldSpecId }
  >
> = createCachedSelector(addLayerParams(businessObjectsForLayerSelector), (objects) => {
  return objects.reduce(
    (res, obj) => {
      obj.fields.forEach((field) => {
        res[field.id] = {
          objectId: obj.id,
          fieldSpecId: field.fieldSpecId,
        };
      });
      return res;
    },
    {} as Record<
      BusinessObjectFieldId,
      { objectId: BusinessObjectId; fieldSpecId: BusinessObjectFieldSpecId }
    >,
  );
})(getCacheKeyForLayerSelector);

type BusinessObjectFieldForLayerProps = { id: BusinessObjectFieldId; layerId?: LayerId };
export const businessObjectFieldSelector: ParametricSelector<
  BusinessObjectFieldForLayerProps,
  BusinessObjectField | undefined
> = createCachedSelector(
  fieldSelector('id'),
  (state: RootState, { layerId }: BusinessObjectFieldForLayerProps) =>
    businessObjectFieldByIdForLayerSelector(state, { layerId }),
  (fieldId, fieldsById) => fieldsById[fieldId],
)((state, { layerId, id }) => `${getGivenOrCurrentLayerId(state, { layerId })}.${id}`);

export const businessObjectFieldSpecForFieldIdSelector: ParametricSelector<
  BusinessObjectFieldForLayerProps,
  BusinessObjectFieldSpec | undefined
> = createSelector(
  (state: RootState) => state,
  (state: RootState, props: BusinessObjectFieldForLayerProps) =>
    businessObjectFieldSelector(state, props),
  paramSelector<BusinessObjectFieldForLayerProps>(),
  (
    state: RootState,
    field: BusinessObjectField | undefined,
    props: BusinessObjectFieldForLayerProps,
  ) => {
    if (field == null) {
      return undefined;
    }
    return businessObjectFieldSpecSelector(state, {
      id: field.fieldSpecId,
      layerId: props.layerId,
    });
  },
);

export const businessObjectFieldByObjectSpecIdForLayerSelector: SelectorWithLayerParam<
  Record<BusinessObjectSpecId, BusinessObjectField[]>
> = createCachedSelector(
  addLayerParams(businessObjectsForLayerSelector),
  addLayerParams(businessObjectSpecsByIdForLayerSelector),
  (objects, objectSpecs) => {
    return objects.reduce(
      (res, obj) => {
        const spec = objectSpecs[obj.specId];
        if (spec != null) {
          res[spec.id] = res[spec.id] ?? [];
          obj.fields.forEach((field) => {
            res[spec.id].push(field);
          });
        }

        return res;
      },
      {} as Record<BusinessObjectSpecId, BusinessObjectField[]>,
    );
  },
)(getCacheKeyForLayerSelector);

export const businessObjectFieldsExistForFieldSpecIdSelector: ParametricSelector<
  BusinessObjectFieldSpecId,
  boolean
> = createCachedSelector(
  businessObjectsForAllLayersSelector,
  (_state: RootState, fieldSpecId: BusinessObjectFieldSpecId) => {
    return fieldSpecId;
  },
  (allBusinessObjects, fieldSpecId) => {
    return allBusinessObjects.some((o) => o.fields.some((f) => f.fieldSpecId === fieldSpecId));
  },
)((_state, fieldSpecId) => fieldSpecId);

type BusinessObjectFieldSpecIdAndLayerIdProps = {
  layerId?: LayerId;
  fieldSpecId: BusinessObjectFieldSpecId;
};

type BusinessObjectIdAndLayerIdProps = {
  layerId?: LayerId;
  objectId: BusinessObjectId;
};

const EMPTY_FIELDS: BusinessObjectField[] = [];
/**
 * This includes fieldIds for fields that don't have corresponding field objects in their
 * businessObject.fields.
 */
export const businessObjectFieldsForObjectIdAndLayerSelector: ParametricSelector<
  BusinessObjectIdAndLayerIdProps,
  BusinessObjectField[]
> = createSelector(
  (state: RootState, props: BusinessObjectIdAndLayerIdProps) =>
    businessObjectsByIdForLayerSelector(
      state,
      layerParamMemo(getGivenOrCurrentLayerId(state, props)),
    ),
  (state: RootState, props: BusinessObjectIdAndLayerIdProps) =>
    businessObjectSpecsByObjectIdSelector(
      state,
      layerParamMemo(getGivenOrCurrentLayerId(state, props)),
    ),
  fieldSelector<BusinessObjectIdAndLayerIdProps, 'objectId'>('objectId'),
  function businessObjectFieldsForObjectIdAndLayerSelector(
    objectsById,
    objectSpecsByObjectId,
    objectId,
  ) {
    const object = safeObjGet(objectsById[objectId]);
    if (object == null) {
      return EMPTY_FIELDS;
    }
    const objectSpec = safeObjGet(objectSpecsByObjectId[objectId]);
    if (objectSpec == null) {
      return EMPTY_FIELDS;
    }
    const objectFieldsByFieldSpecId = keyBy(object.fields, 'fieldSpecId');
    return objectSpec.fields.map((fieldSpec) => {
      return (
        objectFieldsByFieldSpecId[fieldSpec.id] ?? {
          id: getObjectFieldUUID(objectId, fieldSpec.id),
          fieldSpecId: fieldSpec.id,
        }
      );
    });
  },
);

export const businessObjectFieldsForFieldSpecIdAndLayerSelector: ParametricSelector<
  BusinessObjectFieldSpecIdAndLayerIdProps,
  PopulatedBusinessObjectField[]
> = createCachedSelector(
  (state: RootState, props: BusinessObjectFieldSpecIdAndLayerIdProps) =>
    businessObjectFieldByIdForLayerSelector(state, layerParamMemo(props.layerId)),
  fieldSelector<BusinessObjectFieldSpecIdAndLayerIdProps, 'fieldSpecId'>('fieldSpecId'),
  (objectFields, objectFieldSpecId) => {
    return Object.values(objectFields).filter((field) => field.fieldSpecId === objectFieldSpecId);
  },
)(getCacheKeyForLayerSelector);

// Helper for getting the id prop
const getBusinessObjectFieldSpecId: ParametricSelector<
  BusinessObjectFieldSpecId,
  BusinessObjectFieldSpecId
> = (_state, fieldSpecId: BusinessObjectFieldSpecId) => fieldSpecId;

export const shortFieldNameSelector = createCachedSelector(
  (state) => businessObjectFieldSpecByIdSelector(state),
  getBusinessObjectFieldSpecId,
  (businessObjectFieldSpecById, businessObjectFieldSpecId) => {
    const fieldSpec = businessObjectFieldSpecById[businessObjectFieldSpecId];
    if (fieldSpec == null) {
      return undefined;
    }
    return fieldSpec.name;
  },
)((_state, objectFieldSpecId) => objectFieldSpecId);

export const businessObjectFieldNameSelector = createCachedSelector(
  (state) => businessObjectFieldByIdForLayerSelector(state),
  businessObjectFieldIdSelector,
  (populatedObjectFields, objectFieldId) => {
    const field = populatedObjectFields[objectFieldId];
    if (field == null) {
      return undefined;
    }
    return `${field.objectName}'s ${field.fieldSpec?.name}`;
  },
)((_state, objectFieldId) => objectFieldId);

// TODO
export const businessObjectFieldNameFromFieldSpecIdSelector = createCachedSelector(
  (state: RootState) => businessObjectFieldSpecByIdSelector(state),
  getBusinessObjectFieldSpecId,
  (state: RootState) => businessObjectSpecsForLayerSelector(state),
  (businessObjectFieldSpecById, businessObjectFieldSpecId, businessObjectSpecs) => {
    const fieldSpec = businessObjectFieldSpecById[businessObjectFieldSpecId];
    if (fieldSpec == null) {
      return undefined;
    }

    const objectSpecForField = businessObjectSpecs.find((spec) =>
      spec.fields.some((f) => f.id === businessObjectFieldSpecId),
    );
    if (objectSpecForField == null) {
      throw new Error('no spec found for field');
    }
    return `${objectSpecForField.name}'s ${fieldSpec?.name}`;
  },
)((_state, objectFieldSpecId) => objectFieldSpecId);

export const businessObjectFieldSpecSelector: ParametricSelector<
  FieldSpecForLayerProps,
  BusinessObjectFieldSpec | undefined
> = createCachedSelector(
  (state: RootState, { layerId }: FieldSpecForLayerProps) =>
    businessObjectFieldSpecByIdSelector(state, { layerId }),
  fieldSelector('id'),
  (fieldSpecsById, fieldSpecId) => {
    return fieldSpecsById[fieldSpecId];
  },
)(cacheKeyForDriverForLayerSelector);

export const businessObjectFieldSpecTypeSelector: ParametricSelector<
  FieldSpecForLayerProps,
  ValueType | undefined
> = createCachedSelector(businessObjectFieldSpecSelector, (fieldSpec) => {
  return fieldSpec?.type;
})(cacheKeyForFieldSpecForLayer);

export const dimensionIdForBusinessObjectFieldSpecSelector: ParametricSelector<
  FieldSpecForLayerProps,
  DimensionId | undefined
> = createCachedSelector(
  businessObjectFieldSpecSelector,
  (state: RootState) => dimensionsByIdSelector(state),
  (fieldSpec, dimensionsById) => {
    return fieldSpec?.type === ValueType.Attribute &&
      dimensionsById[fieldSpec.dimensionId] != null &&
      !dimensionsById[fieldSpec.dimensionId].deleted
      ? fieldSpec.dimensionId
      : undefined;
  },
)(cacheKeyForFieldSpecForLayer);

const fieldSpecsForBusinessObjectSpecSelector: ParametricSelector<
  BusinessObjectSpecId,
  BusinessObjectFieldSpec[]
> = createCachedSelector(
  (state: RootState, objectSpecId: BusinessObjectSpecId) =>
    businessObjectSpecSelector(state, objectSpecId),
  (objectSpec) => {
    return objectSpec?.fields ?? [];
  },
)((_state, fieldId) => fieldId);

export const attributeFieldSpecsForBusinessObjectSpecSelector: ParametricSelector<
  BusinessObjectSpecId,
  BusinessObjectFieldSpec[]
> = createCachedSelector(
  (state: RootState, objectSpecId: BusinessObjectSpecId) =>
    fieldSpecsForBusinessObjectSpecSelector(state, objectSpecId),
  (fieldSpecs) => {
    return fieldSpecs.filter((spec) => spec.type === ValueType.Attribute);
  },
)((_state, fieldId) => fieldId);

export const fieldSpecValueTypeSelector: ParametricSelector<
  FieldSpecForLayerProps,
  ValueType | undefined
> = createCachedSelector(businessObjectFieldSpecSelector, (spec) => {
  return spec?.type;
})(cacheKeyForFieldSpecForLayer);

export const fieldSpecDefaultForecastFormulaSelector: ParametricSelector<
  FieldSpecForLayerProps,
  string | null
> = createCachedSelector(businessObjectFieldSpecSelector, (spec) => {
  if (spec == null || spec.defaultForecast == null) {
    return null;
  }
  return spec.defaultForecast.formula;
})(cacheKeyForFieldSpecForLayer);

export const canConvertFieldSpecToDimensionalPropertySelector: ParametricSelector<
  FieldSpecForLayerProps,
  boolean
> = createCachedSelector(
  businessObjectFieldSpecSelector,
  isRunwayEmployeeSelector,
  (fieldSpec, isRunwayEmployee) => {
    return fieldSpec?.type === ValueType.Attribute && isRunwayEmployee;
  },
)(cacheKeyForFieldSpecForLayer);

type BusinessObjectFieldSpecProps = {
  fieldSpecId: BusinessObjectFieldSpecId;
  objectSpecId: BusinessObjectSpecId;
};
export const canConvertFieldSpecToDimensionalDriverSelector: ParametricSelector<
  BusinessObjectFieldSpecProps,
  boolean
> = createCachedSelector(
  (state: RootState, { objectSpecId }: BusinessObjectFieldSpecProps) =>
    businessObjectSpecSelector(state, objectSpecId),
  (state: RootState, { fieldSpecId }: BusinessObjectFieldSpecProps) =>
    businessObjectFieldSpecSelector(state, { id: fieldSpecId }),
  isRunwayEmployeeSelector,
  (objectSpec, fieldSpec, isRunwayEmployee) => {
    const dimensionalProperties = objectSpec?.collection?.dimensionalProperties ?? [];
    return (
      dimensionalProperties.length > 0 && fieldSpec?.type === ValueType.Number && isRunwayEmployee
    );
  },
)((_state, { fieldSpecId, objectSpecId }) => `${fieldSpecId}-${objectSpecId}`);

export const businessObjectFieldSpecIsLinkedSelector: ParametricSelector<
  FieldSpecForLayerProps,
  boolean
> = createCachedSelector(
  businessObjectFieldSpecSelector,
  (fieldSpec) => fieldSpec?.extFieldSpecKey != null,
)(cacheKeyForFieldSpecForLayer);

export const businessObjectFieldSpecShouldPropagateIntegrationDataSelector: ParametricSelector<
  FieldSpecForLayerProps,
  boolean
> = createCachedSelector(
  businessObjectFieldSpecSelector,
  (fieldSpec) => fieldSpec?.propagateIntegrationData ?? false,
)(cacheKeyForFieldSpecForLayer);

export const businessObjectFieldSpecShouldIntegrationDataOverridesForecast: ParametricSelector<
  FieldSpecForLayerProps,
  boolean
> = createCachedSelector(
  businessObjectFieldSpecSelector,
  (fieldSpec) => fieldSpec?.integrationDataOverridesForecast ?? false,
)(cacheKeyForFieldSpecForLayer);
