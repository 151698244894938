import { createSelector } from '@reduxjs/toolkit';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import type { FormulaInputState } from 'reduxStore/reducers/formulaInputSlice';
import { EntityType } from 'types/formula';
import { Selector } from 'types/redux';

const formulaInputSelector: Selector<FormulaInputState> = (state) => state.formulaInput;

export const insertedFormulaEntityReferenceSelector: Selector<FormulaEntityTypedId | null> =
  createSelector(
    formulaInputSelector,
    ({ formulaEntityReference }) => formulaEntityReference ?? null,
  );

export const canInsertFormulaEntityReferenceSelector: Selector<boolean> = createSelector(
  formulaInputSelector,
  ({ canInsertFormulaEntityReference }) => canInsertFormulaEntityReference ?? false,
);

export const recentFormulaEntitiesSelector = createSelector(
  formulaInputSelector,
  ({ recentEntities }) => recentEntities,
);

export const recentDriverIdsSelector = createDeepEqualSelector(
  recentFormulaEntitiesSelector,
  (entities) =>
    entities.filter((entity) => entity.type === EntityType.Driver).map((entity) => entity.data.id),
);
