import { useMemo } from 'react';

import { BlockId } from 'reduxStore/models/blocks';

export function useScenarioComparisonBlock(blockId: BlockId) {
  return useMemo(
    () => ({
      blockId: blockId ?? '',
      gutterWidthInPx: 0,
      gutterWidthInPxString: '0',
    }),
    [blockId],
  );
}
