import { BlockConfig, BlockExplanation, BlockRow, BlockType, DateRange } from 'generated/graphql';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DimensionalPropertyId } from 'reduxStore/models/collections';

export type BlocksPageId = string;
export type BlockId = string;
type BlockRowId = string;
type BlockColumnId = string;

export type BlocksPage = {
  id: BlocksPageId;
  name: string;
  createdByUserId?: string;
  blockIds: BlockId[];
  internalPageType?: string;
  internalPageVersion?: number;
  sortIndex?: number;
  parent?: string | null;
  dateRange?: DateRange;
  layout?: BlockRow[];
};

export type Block = {
  id: BlockId;
  name: string;
  type: BlockType;
  pageId: BlocksPageId;
  blockConfig: BlockConfig;
  sortIndex?: number;
  explanation?: BlockExplanation | null;
};

// We either use the layout field (in which case we have row/column ids),
// or the sort indices of the blocks (where we don't have row/column ids)
export type BlockRowWithOptionalId = {
  id?: BlockRowId;
  columns: BlockColumnWithOptionalId[];
};

export type BlockColumnWithOptionalId = {
  id?: BlockColumnId;
  blockIds: BlockId[];
};

export const OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY = 'name';

export type ObjectTableBlockColumnKey =
  | BusinessObjectFieldSpecId
  | DimensionalPropertyId
  | typeof OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY;
