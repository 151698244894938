import { createCachedSelector } from 're-reselect';

import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { isNamedVersion } from 'helpers/namedVersions';
import { ExtEntityTable } from 'reduxStore/models/common';
import { ExtObjectKey } from 'reduxStore/models/extObjectSpecs';
import { ExtObject } from 'reduxStore/models/extObjects';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { getGivenOrCurrentLayerId, layersSelector } from 'selectors/layerSelector';

const EMPTY_EXT_OBJECTS_TABLE: ExtEntityTable<ExtObject> = { byKey: {}, allKeys: [] };

const extObjectEntityTableForLayerSelector: SelectorWithLayerParam<ExtEntityTable<ExtObject>> =
  createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
    const layer = layers[layerId];
    if (isNamedVersion(layer)) {
      return layer.extObjects;
    }

    const defaultLayer = layers[DEFAULT_LAYER_ID];
    return defaultLayer?.extObjects ?? EMPTY_EXT_OBJECTS_TABLE;
  })(getCacheKeyForLayerSelector);

export const extObjectsByKeySelector: SelectorWithLayerParam<Record<ExtObjectKey, ExtObject>> =
  createCachedSelector(
    addLayerParams(extObjectEntityTableForLayerSelector),
    (extObjectsTable) => extObjectsTable.byKey,
  )(getCacheKeyForLayerSelector);

export const extObjectsSelector: SelectorWithLayerParam<ExtObject[]> = createCachedSelector(
  addLayerParams(extObjectsByKeySelector),
  function extObjectsSelector(extObjectsByKeys) {
    return Object.values(extObjectsByKeys);
  },
)(getCacheKeyForLayerSelector);
