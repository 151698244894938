import groupBy from 'lodash/groupBy';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { ThresholdDirection } from 'generated/graphql';
import { EntityTable } from 'reduxStore/models/common';
import { DriverId } from 'reduxStore/models/drivers';
import { Milestone, MilestoneId } from 'reduxStore/models/milestones';
import { driverIdSelector } from 'selectors/constSelectors';
import { currentLayerSelector } from 'selectors/layerSelector';
import { ParametricSelector, Selector } from 'types/redux';

const EMPTY_ENTITY_TABLE: EntityTable<Milestone> = { byId: {}, allIds: [] };

const milestonesSelector = createSelector(
  currentLayerSelector,
  (layer) => layer?.milestones ?? EMPTY_ENTITY_TABLE,
);

export const milestonesWithoutLiveEditsSelector: Selector<Milestone[]> = createSelector(
  milestonesSelector,
  ({ allIds, byId }) => {
    return allIds.map((id) => byId[id]);
  },
);

export const milestonesWithoutLiveEditsByIdSelector: Selector<Record<MilestoneId, Milestone>> =
  createSelector(milestonesSelector, ({ byId }) => byId);

const milestonesWithoutLiveEditsByDriverIdSelector = createSelector(
  milestonesWithoutLiveEditsSelector,
  (milestones) => groupBy(milestones, 'driverId'),
);

export const firstMilestoneByDriverIdSelector = createSelector(
  milestonesWithoutLiveEditsByDriverIdSelector,
  (milestonesByDriverId) => {
    const firstMilestoneByDriverId: Record<DriverId, Milestone> = {};
    Object.entries(milestonesByDriverId).forEach(([driverId, milestones]) => {
      if (milestones.length > 0) {
        firstMilestoneByDriverId[driverId] = milestones[0];
      }
    });
    return firstMilestoneByDriverId;
  },
);

export const firstMilestoneForDriverSelector: ParametricSelector<DriverId, Milestone | null> =
  createCachedSelector(
    firstMilestoneByDriverIdSelector,
    driverIdSelector,
    (firstMilestoneByDriverId, driverId) => {
      return firstMilestoneByDriverId[driverId] ?? null;
    },
  )((_state, driverId) => driverId);

export const driverMilestoneThresholdSelector: ParametricSelector<DriverId, ThresholdDirection> =
  createCachedSelector(firstMilestoneForDriverSelector, (milestone) => {
    return milestone?.thresholdDirection ?? ThresholdDirection.AboveOrEqual;
  })((_state, driverId) => driverId);
