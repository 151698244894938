import { QuestionIcon } from '@chakra-ui/icons';
import { Flex, Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import ContextMenuItems from 'components/ContextMenuItems/ContextMenuItems';
import MoreMenuButton, {
  MoreMenuOptionsColorScheme,
} from 'components/MoreMenuButton/MoreMenuButton';
import { Section } from 'components/SelectMenu/SelectMenu';
import { stopEventPropagation } from 'helpers/browserEvent';
import { HELP_CENTER_URL } from 'helpers/helpCenter';
import { routeTo } from 'helpers/navigation';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useControlledPopover from 'hooks/useControlledPopover';
import { deleteSelected } from 'reduxStore/actions/deleteSelected';
import { setCellPaletteState } from 'reduxStore/reducers/pageSlice';
import { eventGroupIdsImpactingCellSelectionSelector } from 'selectors/eventsAndGroupsSelector';
import ArrowUpLeft from 'vectors/ArrowUpLeft';

const HELP_CENTER_PLANS_ARTICLE_URL = `${HELP_CENTER_URL}/planning--collaborating/uSijm9sGNmbhAY2BeSCfmf/plans/4EhSMD1H1b5Wn7a9edFsp9`;

const MORE_MENU_COLOR_SCHEME: MoreMenuOptionsColorScheme = {
  unselected: {
    color: 'gray.500',
    hoverBackgroundColor: 'gray.300',
  },
};

const MAIN_SECTION_ID = 'main';
const HELP_SECTION_ID = 'help';

const SECTIONS: Section[] = [MAIN_SECTION_ID, HELP_SECTION_ID].map((id) => ({ id }));

const CellPaletteMoreMenu: React.FC = () => {
  const dispatch = useAppDispatch();

  const eventGroupIdsImpactingCellSelection = useAppSelector(
    eventGroupIdsImpactingCellSelectionSelector,
  );
  const cellSelectionHasImpact = eventGroupIdsImpactingCellSelection.size > 0;

  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();

  const onOpenCallback = useCallback(() => {
    dispatch(setCellPaletteState('color'));
    onOpen();
  }, [dispatch, onOpen]);

  const onOpenNewURL = useCallback(
    (url: string) => {
      routeTo(url, { openInNewTab: true });
      onClose();
    },
    [onClose],
  );

  const onResetToDefault = useCallback(() => {
    if (!cellSelectionHasImpact) {
      return;
    }

    dispatch(deleteSelected());
  }, [dispatch, cellSelectionHasImpact]);

  const items = useMemo(() => {
    const options: ContextMenuItem[] = [
      {
        text: 'Reset to default value',
        onSelect: onResetToDefault,
        icon: <ArrowUpLeft />,
        sectionId: 'main',
        isDisabled: !cellSelectionHasImpact,
      },
      {
        text: 'Learn more about plans',
        icon: <QuestionIcon color="gray.500" bgColor="surface" borderRadius="50%" />,
        onSelect: () => onOpenNewURL(HELP_CENTER_PLANS_ARTICLE_URL),
        sectionId: 'help',
      },
    ];

    return options;
  }, [cellSelectionHasImpact, onOpenNewURL, onResetToDefault]);

  return (
    <Flex onClick={stopEventPropagation}>
      <Popover
        isLazy
        placement="bottom-start"
        returnFocusOnClose={false}
        closeOnBlur
        isOpen={isOpen}
        onOpen={onOpenCallback}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Flex ref={triggerRef} zIndex="modal" alignItems="center">
            <MoreMenuButton orientation="horizontal" colorScheme={MORE_MENU_COLOR_SCHEME} />
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent ref={contentRef} padding={0} boxShadow="none">
            <ContextMenuItems sections={SECTIONS} items={items} closeMenu={onClose} />
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  );
};

export default React.memo(CellPaletteMoreMenu);
