import { createSelector } from '@reduxjs/toolkit';
import pluralize from 'pluralize';
import { createCachedSelector } from 're-reselect';

import { CELL_DATA_COLUMN_WIDTH_IN_PX, CellColumnKey } from 'config/cells';
import { ExtDriverColumn, ExtDriverColumnType, STICKY_COLUMN_TYPES } from 'config/extDriverTable';
import { getMonthColumnKey } from 'helpers/cells';
import { isNotNull } from 'helpers/typescript';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import type { DataSources } from 'reduxStore/reducers/dataSourcesSlice';
import { fieldSelector, paramSelector } from 'selectors/constSelectors';
import {
  extDriversByIdSelector,
  extDriversMonthKeysSelector,
  isViewingTransactionsQueriesSelector,
} from 'selectors/extDriversSelector';
import { pageGutterWidthInPxSelector } from 'selectors/pageSelector';
import { MonthKey } from 'types/datetime';
import { ParametricSelector, Selector } from 'types/redux';

const extDriverTableSelector: Selector<DataSources['extDriverTable']> = (state) =>
  state.dataSources.extDriverTable;

const extDriverTableVisibleStickyColumns: Selector<ExtDriverColumnType[]> = createSelector(
  isViewingTransactionsQueriesSelector,
  (viewingTransactionQueries) =>
    viewingTransactionQueries
      ? STICKY_COLUMN_TYPES
      : STICKY_COLUMN_TYPES.filter((col) => col !== 'query'),
);

export const extDriverTableColumnsSelector: Selector<ExtDriverColumn[]> = createSelector(
  extDriverTableSelector,
  extDriversMonthKeysSelector,
  extDriverTableVisibleStickyColumns,
  (extDriverTable, monthKeys, visibleStickyColumns) => {
    const { stickyColumns, dataColumnWidths } = extDriverTable;
    const dataColumns: ExtDriverColumn[] = monthKeys.map((monthKey) => ({
      type: 'data',
      monthKey,
      isSticky: false,
      isLastSticky: false,
      width: dataColumnWidths[monthKey] ?? CELL_DATA_COLUMN_WIDTH_IN_PX,
    }));
    return [
      ...stickyColumns
        .filter((col) => visibleStickyColumns.includes(col.type))
        .map((col, idx, arr) => ({ ...col, isLastSticky: idx === arr.length - 1 })),
      ...dataColumns,
    ];
  },
);

export const extDriverTableColumnOffsetSelector: ParametricSelector<
  ExtDriverColumnType,
  number | undefined
> = createCachedSelector(
  extDriverTableColumnsSelector,
  pageGutterWidthInPxSelector,
  paramSelector<ExtDriverColumnType>(),
  (columns, pageGutter, columnType) => {
    const columnIdx = columns.findIndex((col) => col.type === columnType && col.isSticky);
    if (columnIdx < 0) {
      return undefined;
    }
    // add up the widths of the previous sticky columns
    const prevStickyColumnWidths = columns.slice(0, columnIdx).map((col) => col.width);
    return prevStickyColumnWidths.length > 0
      ? prevStickyColumnWidths.reduce((prev, curr) => prev + curr, pageGutter)
      : 0;
  },
)((state, columnType) => columnType);

type ColumnProps = { columnType: ExtDriverColumnType; monthKey?: MonthKey };

export const extDriverTableColumnWidthSelector: ParametricSelector<
  ColumnProps,
  number | undefined
> = createCachedSelector(
  fieldSelector<ColumnProps, 'columnType'>('columnType'),
  fieldSelector('monthKey'),
  extDriverTableColumnsSelector,
  (columnType, monthKey, columns) =>
    columns.find((col) => col.type === columnType && col.monthKey === monthKey)?.width,
)((state, key: ColumnProps) => `${key.columnType}-${key.monthKey}`);

export const extDriverIdsToDeleteSelector: Selector<ExtDriverId[] | undefined> = createSelector(
  extDriverTableSelector,
  (extDriverTable) => extDriverTable.extDriverIdsToBeDeleted,
);

export const deleteExtDriverDialogWarningMessageSelector: Selector<string | null> = createSelector(
  extDriverIdsToDeleteSelector,
  extDriversByIdSelector,
  (extDriverIdsToDelete, extDriversById) => {
    if (extDriverIdsToDelete == null) {
      return null;
    }
    const matchingExtDriverIds = extDriverIdsToDelete
      .map((id) => extDriversById[id])
      .filter(isNotNull);
    const numExtDrivers = matchingExtDriverIds.length;
    return `Are you sure you want to delete ${numExtDrivers} ${pluralize(
      'driver',
      numExtDrivers,
    )}?`;
  },
);

export const orderedExtDriverColumnKeysSelector: Selector<CellColumnKey[]> = createSelector(
  extDriverTableColumnsSelector,
  (columns) => {
    return columns.map((col) =>
      col.type === 'data' && col.monthKey != null
        ? getMonthColumnKey(col.monthKey)
        : { columnType: col.type, columnLayerId: undefined },
    );
  },
);
