import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ListType, ScrollToInfo } from 'components/VirtualizedList/types';

interface Props {
  scrollerTargetRef: React.RefObject<HTMLElement>;
  itemPositionsInList: Record<number, number>;
  numListItems: number;
  listType: ListType;
  isFixedSized: boolean;
}

export const useListScrollToInternal = ({
  numListItems,
  scrollerTargetRef,
  itemPositionsInList,
  listType,
  isFixedSized,
}: Props) => {
  const [scrollToInfo, setScrollToInfo] = useState<ScrollToInfo | null>(null);
  const [listOffsetFromBoundary, setListOffset] = useState<number>(-1); //-1 means an indeterminate state i.e we don't know the initial offset top yet
  const onScrollToCompletionCallbackRef = useRef<(() => void) | null>(null);
  const scrollToItem = (itemIndex: number, onComplete?: () => void) => {
    setScrollToInfo({ indexToScrollTo: itemIndex });
    if (onComplete != null) {
      onScrollToCompletionCallbackRef.current = onComplete;
    }
  };

  const setListOffsetFromBoundaryForScrollToHook = useCallback((offset: number) => {
    setListOffset(offset);
  }, []);

  const onScrollItemInRange = useCallback((scrollTo: ScrollToInfo | null) => {
    setScrollToInfo(scrollTo);
    if (scrollTo == null && onScrollToCompletionCallbackRef.current != null) {
      onScrollToCompletionCallbackRef.current();
      onScrollToCompletionCallbackRef.current = null;
    }
  }, []);

  useEffect(() => {
    const moveToIndex = (itemIndex: number) => {
      if (itemIndex >= 0 && itemIndex < numListItems) {
        const scroller = scrollerTargetRef.current;
        const position = itemPositionsInList[itemIndex];
        if (scroller != null && position != null && listOffsetFromBoundary !== -1) {
          const centeringAdjustment =
            listType === 'vertical' ? scroller.offsetHeight / 2 : scroller.offsetWidth / 2; // to center the item in the middle of the scroller
          const positionInScroller = Math.max(
            listOffsetFromBoundary + position - centeringAdjustment,
            0,
          );
          if (listType === 'vertical') {
            scroller.scrollTo({ top: positionInScroller, behavior: 'instant' });
          } else {
            scroller.scrollTo({ left: positionInScroller, behavior: 'instant' });
          }
          //we are confident of our scrolled position since we know the we know the positions of all the items in the list given that it is fixed sized
          if (isFixedSized) {
            setScrollToInfo(null);
            if (onScrollToCompletionCallbackRef.current != null) {
              onScrollToCompletionCallbackRef.current();
              onScrollToCompletionCallbackRef.current = null;
            }
          }
        }
      }
    };
    /**
     * When there's scrollTo information, we scroll to item X at indexToScrollTo given the positions in itemPositionsInList. In the case of a variable size list,
     * we might not have accurate measurements of items before X so the positions in itemPositionsInList might not entirely get us to the right position.
     * In this case, we rely on ScrollToItem.tsx to give us help us get to the right position. If we are at the right position, onScrollItemInRange
     * is invoked with null from ScrollToItem.tsx and the search for the right position terminates. Otherwise, it provides us with a new scrollToInfo object that triggers
     * a re-run. itemPositionsInList will have updated values on the next run.
     */
    if (scrollToInfo != null) {
      moveToIndex(scrollToInfo.indexToScrollTo);
    }
  }, [
    isFixedSized,
    itemPositionsInList,
    listOffsetFromBoundary,
    listType,
    numListItems,
    scrollToInfo,
    scrollerTargetRef,
  ]);

  return useMemo(
    () => ({
      scrollToItem,
      onScrollItemInRange,
      scrollToInfo,
      setListOffsetFromBoundaryForScrollToHook,
    }),
    [onScrollItemInRange, scrollToInfo, setListOffsetFromBoundaryForScrollToHook],
  );
};
