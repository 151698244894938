import { useMemo } from 'react';

import { DriverChartDatum } from 'config/driverChart';
import { timeSeriesToChartData } from 'helpers/chart';
import { DriverFormat } from 'reduxStore/models/drivers';
import { NumericTimeSeries } from 'reduxStore/models/timeSeries';
import { MonthKey } from 'types/datetime';

const useChartForecastData = ({
  forecastData,
  monthKeys,
  format,
  lastActual,
}: {
  forecastData: NumericTimeSeries;
  monthKeys: MonthKey[];
  format: DriverFormat;
  lastActual?: DriverChartDatum | undefined;
}) => {
  return useMemo(() => {
    const datums = timeSeriesToChartData(forecastData, monthKeys, format);
    // Close the gap on the graph
    if (lastActual != null) {
      datums.unshift(lastActual);
    }
    return datums;
  }, [forecastData, monthKeys, lastActual, format]);
};

export default useChartForecastData;
