import { Box, Flex } from '@chakra-ui/react';
import Tippy from '@tippyjs/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useEventEntityContext } from '@features/Plans/EventEntity';
import NumericDisplayCell from 'components/DisplayCell/NumericDisplayCell';
import FormulaInput, { OnChangeFormulaArgs } from 'components/FormulaInput/FormulaInput';
import { useEnhancedCellEditing } from 'components/NumericActiveCell/useEnhancedCellEditing';
import { ActiveCellProps } from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapper';
import { CELL_DATA_COLUMN_WIDTH_IN_PX, CELL_HEIGHT_IN_PX } from 'config/cells';
import { NO_FLIP_POPPER_OPTIONS } from 'config/popper';
import theme from 'config/theme';
import { ImpactType } from 'generated/graphql';
import { getCellRefMonthKey } from 'helpers/cells';
import { isPrintableChar } from 'helpers/string';
import { toPxString } from 'helpers/styles';
import useActiveCell from 'hooks/useActiveCell';
import useAppSelector from 'hooks/useAppSelector';
import { useCellRef } from 'hooks/useCellRefContext';
import { DisplayConfiguration, NumberValue, toNumberValue } from 'reduxStore/models/value';
import {
  activeCellImpactEventGroupIdsSelector,
  activeCellImpactSelector,
} from 'selectors/eventsAndGroupsSelector';

export interface NumericActiveCellProps extends ActiveCellProps<NumberValue> {
  displayConfiguration: DisplayConfiguration;
  impactType?: ImpactType;
  align?: 'left' | 'right';
  isForecast?: boolean;
}

export const ENHANCED_CELL_EDITING_WIDTH_OVERFLOW_PX = 5;

const NumericActiveCell: React.FC<NumericActiveCellProps> = ({
  value,
  error,
  onSave,
  displayConfiguration,
  impactType,
  onStartEditing,
  align = 'right',
  isForecast = false,
}) => {
  const { cellRef } = useCellRef();
  const monthKey = getCellRefMonthKey(cellRef);
  const measureRef = useRef<HTMLDivElement | null>(null);
  const [cellWidth, setCellWidth] = useState(CELL_DATA_COLUMN_WIDTH_IN_PX);

  const eventGroupIds = useAppSelector(activeCellImpactEventGroupIdsSelector);
  const cellImpact = useAppSelector(activeCellImpactSelector);

  const [startEditingKey, setStartEditingKey] = useState<string | null>(null);

  const onEditTriggered = useCallback(
    (key: string) => {
      if (!isPrintableChar(key)) {
        return;
      }
      setStartEditingKey(key);
    },
    [setStartEditingKey],
  );

  const onEndEditing = useCallback(() => {
    setStartEditingKey(null);
  }, [setStartEditingKey]);

  const {
    isEditing,
    onStartEditingCallback,
    onEndEditingCallback,
    onSaveCallback,
    onCancel,
    onKeyDown,
  } = useActiveCell({
    onSave,
    onEditTriggered,
    onStartEditing,
    onEndEditing,
  });

  const enhancedCellEditingOnSaveActual = useCallback(
    (actual: number | null) => {
      onSaveCallback(actual != null ? toNumberValue(actual) : undefined, undefined);
    },
    [onSaveCallback],
  );

  const onSaveFromKeyboard = useCallback(
    ({ lastKeyboardEvent }: OnChangeFormulaArgs) => {
      if (lastKeyboardEvent == null) {
        return;
      }
      onKeyDown(lastKeyboardEvent);
    },
    [onKeyDown],
  );

  const eventEntity = useEventEntityContext();

  const {
    formulaDisplay,
    formulaError,
    onSaveFromKeyboard: enhancedPlansEntrypointOnSave,
    onBlur,
    formulaInputRef,
    onResetFormulaError,
    onFormulaChange,
  } = useEnhancedCellEditing({
    eventEntity,
    monthKey,
    value,
    cellImpact,
    eventGroupIds,
    displayConfiguration,
    isForecast,
    startEditingKey,
    onSaveFallback: enhancedCellEditingOnSaveActual,
    onSaveFromKeyboard,
    onSaveFromBlur: onEndEditingCallback,
  });

  useEffect(() => {
    setCellWidth(measureRef.current?.clientWidth ?? CELL_DATA_COLUMN_WIDTH_IN_PX);
  }, [measureRef]);

  useEffect(() => {
    if (!isEditing) {
      onResetFormulaError();
    }
  }, [isEditing, onResetFormulaError]);

  return (
    <>
      <NumericDisplayCell
        value={value}
        error={error}
        displayConfiguration={displayConfiguration}
        impactType={impactType}
        onDoubleClick={onStartEditingCallback}
        align={align}
      />
      {/* N.B., unfortunately, different cells handle their padding differently. using
      this to measure the cell without drilling down the width */}
      <Box
        ref={measureRef}
        visibility="hidden"
        pointerEvents="none"
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
      />
      <Tippy
        interactive
        placement={align === 'left' ? 'right' : 'left'}
        offset={[
          0,
          // Center the tippy, given the overflow
          -cellWidth - (ENHANCED_CELL_EDITING_WIDTH_OVERFLOW_PX - 1) / 2,
        ]}
        popperOptions={NO_FLIP_POPPER_OPTIONS}
        appendTo={document.body}
        visible
        reference={measureRef}
        zIndex={theme.zIndices.popover + 1}
        render={() =>
          isEditing ? (
            <Flex
              data-testid="numeric-cell-input"
              borderRadius="md"
              bg="surface"
              boxShadow="menu"
              minWidth={toPxString(cellWidth + ENHANCED_CELL_EDITING_WIDTH_OVERFLOW_PX)}
              height={toPxString(CELL_HEIGHT_IN_PX + 3)}
              alignItems="center"
            >
              <FormulaInput
                ref={formulaInputRef}
                isActuals={false}
                formulaDisplay={formulaDisplay}
                onCancel={onCancel}
                onSave={enhancedPlansEntrypointOnSave}
                onBlur={onBlur}
                minWidth="0px"
                width="100%"
                justifyContent="flex-end"
                disableDropdown
                formulaError={formulaError}
                onChange={onFormulaChange}
              />
            </Flex>
          ) : null
        }
      />
    </>
  );
};

export default React.memo(NumericActiveCell);
