import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';

const SelectHintContext = React.createContext<{
  hint: string | null;
  previousHint: string | null;
  setHint: (hint: string | null) => void;
}>({
  hint: null,
  previousHint: null,
  setHint: () => {},
});

export const SelectHintProvider = ({ children }: { children: ReactNode }) => {
  const [hint, setCurrent] = useState<string | null>(null);
  const [previousHint, setPrevious] = useState<string | null>(null);
  const setHint = useCallback((newHint: string | null) => {
    setCurrent((current) => {
      setPrevious(current);
      return newHint;
    });
  }, []);

  const context = useMemo(() => {
    return { hint, previousHint, setHint };
  }, [hint, previousHint, setHint]);
  return <SelectHintContext.Provider value={context}>{children}</SelectHintContext.Provider>;
};

export function useSelectHint() {
  return useContext(SelectHintContext);
}
