import React, { useEffect } from 'react';

export default function useIsKeyPressed(keyMatcher: (ev: KeyboardEvent) => boolean) {
  const [isKeyPressed, setIsKeyPressed] = React.useState(false);
  useEffect(() => {
    setIsKeyPressed(false);
    function onKeyDown(ev: KeyboardEvent) {
      if (keyMatcher(ev)) {
        setIsKeyPressed(true);
      }
    }

    function onKeyUp() {
      setIsKeyPressed(false);
    }

    function onBlur() {
      setIsKeyPressed(false);
    }

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('blur', onBlur);
    };
  }, [keyMatcher]);

  return isKeyPressed;
}
