import { EMPTY_ATTRIBUTE_ID } from 'config/businessObjects';
import {
  BuiltInDimensionType,
  Attribute as GQLAttribute,
  Dimension as GQLDimension,
  Scalars,
} from 'generated/graphql';
import { MonthKey } from 'types/datetime';

export type DimensionId = string;
export type AttributeId = string | typeof EMPTY_ATTRIBUTE_ID;

export const UserAddedDimensionType = 'UserAdded';
export const PseudoDimensionType = 'Pseudo';

export type DimensionType = BuiltInDimensionType | typeof UserAddedDimensionType;
// alias GQL types
export type Dimension = Pick<GQLDimension, 'id' | 'name' | 'deleted'> & {
  color?: string;
  attributes: Attribute[];
  type: DimensionType;
};

type AttributeCommon = Pick<GQLAttribute, 'id' | 'dimensionId' | 'deleted'>;
export type UserAddedAttribute = AttributeCommon & {
  type: typeof UserAddedDimensionType;
  value: string;
};
export type CalendarTimeAttribute = AttributeCommon & {
  type: BuiltInDimensionType.CalendarTime;
  value: MonthKey;
};
export type RelativeTimeAttribute = AttributeCommon & {
  type: BuiltInDimensionType.RelativeTime;
  value: number;
};
export type PseudoAttribute = AttributeCommon & {
  dimensionId: Scalars['ID'];
  type: typeof PseudoDimensionType;
  value: string;
};

type BuiltInAttribute = CalendarTimeAttribute | RelativeTimeAttribute;
export type Attribute = UserAddedAttribute | BuiltInAttribute | PseudoAttribute;

export type NormalizedDimension = {
  id: DimensionId;
  name: string;
  color?: string;
  type: DimensionType;
  attributeIds: AttributeId[];
  deleted: boolean;
};
