import { Box } from '@chakra-ui/react';

import DatabaseFieldHeaderCell from 'components/BusinessObjectTable/DatabaseFieldHeaderCell';
import DriverPropertyHeaderCell from 'components/BusinessObjectTable/DriverPropertyHeaderCell';
import { DatabaseGroupKey } from 'config/businessObjects';
import { CELL_DATA_COLUMN_WIDTH_IN_PX } from 'config/cells';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY } from 'reduxStore/models/blocks';
import { blockConfigObjectFieldSpecAsTimeSeriesIdSelector } from 'selectors/blocksSelector';
import { driverPropertySelector } from 'selectors/collectionSelector';
import {
  allStickyColumnsWidthSelector,
  lastStickyColumnTypeSelector,
} from 'selectors/tableColumnsSelector';

interface Props {
  groupKey: DatabaseGroupKey;
}

export const FIELD_HEADER_GUTTER_PX = 32;
const FIELD_HEADER_CELL_WIDTH = CELL_DATA_COLUMN_WIDTH_IN_PX;

const TimeSeriesFieldHeader: React.FC<Props> = ({ groupKey }) => {
  const { blockId, gutterWidthInPx } = useBlockContext();
  const asTimeSeriesFieldSpecId = useAppSelector((state) =>
    blockConfigObjectFieldSpecAsTimeSeriesIdSelector(state, blockId),
  );
  const dimDriverIdForProperty = useAppSelector((state) => {
    if (asTimeSeriesFieldSpecId != null) {
      return driverPropertySelector(state, asTimeSeriesFieldSpecId)?.driverId;
    }
    return null;
  });

  const stickyColumnType = useAppSelector((state) => lastStickyColumnTypeSelector(state, blockId));
  let stickyColumnsWidth =
    useAppSelector((state) => allStickyColumnsWidthSelector(state, blockId)) ?? 0;
  // N.B. all other sticky columns have the gutter width baked into their offset, but
  // we have to manually add it for the name column.
  if (stickyColumnType === OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY) {
    stickyColumnsWidth += gutterWidthInPx;
  }

  if (asTimeSeriesFieldSpecId == null) {
    return null;
  }

  return (
    <Box
      h={toPxString(FIELD_HEADER_GUTTER_PX)}
      w={toPxString(FIELD_HEADER_CELL_WIDTH)}
      position="sticky"
      left={toPxString(stickyColumnsWidth)}
      top={0}
      zIndex="sticky"
    >
      {dimDriverIdForProperty != null ? (
        <DriverPropertyHeaderCell
          key={asTimeSeriesFieldSpecId}
          driverPropertyId={asTimeSeriesFieldSpecId}
          groupKey={groupKey}
          isLast={false}
          topSpacingPx={FIELD_HEADER_GUTTER_PX}
          objectId={undefined}
        />
      ) : (
        <DatabaseFieldHeaderCell
          groupKey={groupKey}
          fieldSpecId={asTimeSeriesFieldSpecId}
          isLast={false}
          canDrag={false}
          fixedWidth="full"
          objectId={undefined}
        />
      )}
    </Box>
  );
};

export default TimeSeriesFieldHeader;
