import Button from './button';
import Divider from './divider';
import Input from './input';
import InputGroup from './input-group';
import Kbd from './kbd';
import List from './list';
import Menu from './menu';
import Modal from './modal';
import Popover from './popover';
import Switch from './switch';
import Tabs from './tabs';
import Textarea from './textarea';
import Tooltip from './tooltip';

export default {
  Button,
  Divider,
  Input,
  InputGroup,
  Kbd,
  List,
  Menu,
  Modal,
  Popover,
  Tabs,
  Tooltip,
  Textarea,
  Switch,
};
