import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import {
  GeneralSettings,
  MeQuery,
  NegativeDisplay,
  OrgImportStartTimeDocument,
  OrgImportStartTimeQuery,
  OrgImportStartTimeQueryVariables,
  OrgRole,
} from 'generated/graphql';
import { AsyncAppThunk } from 'reduxStore/store';

type SelectedOrgGQL = NonNullable<NonNullable<MeQuery['me']>['orgs']>[0];

type LoadedUserInvite = NonNullable<NonNullable<SelectedOrgGQL['invitedUsers']>[0]>;

export type LoadedSelectedOrg = {
  id: string;
  slug: string;
  loaded: true;
  name: string;
  isTemplateOrg: boolean;
  myOrgRole: OrgRole;
  generalSettings: GeneralSettings;
  invitedUsers: LoadedUserInvite[];
  importStartTime?: DateTime;
  createdAt: string;
};

type UnloadedSelectedOrg = {
  id: string;
  slug: string;
  loaded: false;
};

export type SelectedOrg = UnloadedSelectedOrg | LoadedSelectedOrg | null;

const initialState = null as SelectedOrg;

export function getLoadedOrgFromGqlOrg(org: SelectedOrgGQL): LoadedSelectedOrg {
  const {
    id,
    slug,
    name,
    myOrgRole,
    generalSettings,
    isTemplateOrg,
    invitedUsers,
    importStartTime,
    createdAt,
  } = org;

  return {
    id,
    slug,
    name,
    loaded: true,
    myOrgRole,
    isTemplateOrg,
    generalSettings,
    invitedUsers: invitedUsers ?? [],
    importStartTime: importStartTime != null ? DateTime.fromISO(importStartTime) : undefined,
    createdAt,
  };
}

export const updateImportStartTime = (orgId: string): AsyncAppThunk => {
  return async (dispatch, _, { urqlClient }) => {
    const result = await urqlClient.query<
      OrgImportStartTimeQuery,
      OrgImportStartTimeQueryVariables
    >(OrgImportStartTimeDocument, { id: orgId });
    if (result.error) {
      return;
    }
    dispatch(selectedOrgSlice.actions.updateOrgImportStartTime(result.data?.org?.importStartTime));
  };
};

const selectedOrgSlice = createSlice({
  name: 'selectedOrg',
  initialState,
  reducers: {
    setSelectedOrg: (_state, action: PayloadAction<{ id: string; slug: string }>) => {
      return { ...action.payload, loaded: false };
    },
    loadSelectedOrg: (state, action: PayloadAction<SelectedOrgGQL>) => {
      if (!state) {
        return null;
      }

      if (state.loaded && state.id === action.payload.id) {
        return state;
      }

      return getLoadedOrgFromGqlOrg(action.payload);
    },
    updateOrgCurrency(state, action: PayloadAction<string>) {
      if (state == null || !state.loaded) {
        return;
      }
      state.generalSettings.defaultCurrencyISOCode = action.payload;
    },
    updateOrgDefaultDecimalPrecision(state, action: PayloadAction<number | null>) {
      if (state == null || !state.loaded) {
        return;
      }
      state.generalSettings.defaultDecimalPrecision = action.payload;
    },
    updateOrgNegativeDisplay(state, action: PayloadAction<NegativeDisplay>) {
      if (state == null || !state.loaded) {
        return;
      }
      state.generalSettings.negativeDisplay = action.payload;
    },
    updateOrgImportStartTime(state, action: PayloadAction<string | null | undefined>) {
      if (state == null || !state.loaded) {
        return;
      }
      const importStartTime = action.payload;
      state.importStartTime =
        importStartTime != null ? DateTime.fromISO(importStartTime) : undefined;
    },
    setInvitedUsers(state, action: PayloadAction<LoadedUserInvite[]>) {
      if (state == null || !state.loaded) {
        return;
      }
      state.invitedUsers = action.payload;
    },
    removeInvitedUser(state, action: PayloadAction<string>) {
      const userEmail = action.payload;

      if (state == null || !state.loaded) {
        return;
      }
      state.invitedUsers = state.invitedUsers.filter((u) => u.email !== userEmail);
    },
  },
});

export const {
  setSelectedOrg,
  loadSelectedOrg,
  updateOrgCurrency,
  updateOrgNegativeDisplay,
  updateOrgDefaultDecimalPrecision,
  setInvitedUsers,
  removeInvitedUser,
} = selectedOrgSlice.actions;

export default selectedOrgSlice.reducer;
