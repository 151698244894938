import { createIcon, IconProps } from '@chakra-ui/react';
import React from 'react';

const Arrowhead = createIcon({
  displayName: 'ArrowheadIcon',
  defaultProps: { transition: 'transform 0.2s ease' },
  viewBox: '0 0 6 5',
  d: 'M2.57125 4.28542L0.454349 0.757248C0.254391 0.423986 0.494448 0 0.883095 0H5.1169C5.50555 0 5.74561 0.423986 5.54565 0.757248L3.42875 4.28542C3.23454 4.60909 2.76545 4.60909 2.57125 4.28542Z',
});

const ROTATION_BY_DIRECTION = {
  down: 0,
  left: 90,
  right: -90,
  up: 180,
} as const;

interface ArrowheadProps extends Omit<IconProps, 'css'> {
  direction: keyof typeof ROTATION_BY_DIRECTION;
}

const ArrowheadIcon = ({ direction, ...props }: ArrowheadProps) => {
  return <Arrowhead {...props} transform={`rotate(${ROTATION_BY_DIRECTION[direction]}deg)`} />;
};

export default React.memo(ArrowheadIcon);
