import { EntityInstance } from 'draft-js';
import React, { useCallback, useContext } from 'react';

import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import { EntityDataWithKey, ObjectSpecEntityData, ObjectSpecRefMetadata } from 'types/formula';

export default function useFormulaPillMouseDown(
  entityKey: string | undefined,
  entity: EntityInstance,
  { submenu = null, onCtrlClick }: { submenu?: string | null; onCtrlClick?: () => void } = {},
) {
  const { activeEntity, setActiveEntity, setDefaultSubmenu } = useContext(FormulaSelectionContext);

  const isSelectedEntity = activeEntity?.key === entityKey;

  const onMouseDown: React.MouseEventHandler = useCallback(
    (ev) => {
      ev.stopPropagation();
      if ((ev.ctrlKey || ev.metaKey) && onCtrlClick != null) {
        onCtrlClick();
      } else if (!isSelectedEntity) {
        const newData = {
          data: entity.getData() as ObjectSpecRefMetadata,
          type: entity.getType(),
          key: entityKey,
        } as EntityDataWithKey & ObjectSpecEntityData;
        setActiveEntity(newData);
        setDefaultSubmenu(submenu);
      } else {
        setDefaultSubmenu(submenu);
      }
    },
    [onCtrlClick, setActiveEntity, entity, entityKey, isSelectedEntity, setDefaultSubmenu, submenu],
  );

  return onMouseDown;
}
