import { isSSR } from 'helpers/environment';
import { safeLocalStorage } from 'helpers/localStorage';
import { ModelEntity, PageEntity } from 'reduxStore/models/folder';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { RestorableState } from 'selectors/restorableState';

const REDUX_STATE_LOCAL_STORAGE_KEY = 'restoreable-redux-state';
const LAST_VISITED_PAGE_STORAGE_KEY = 'last-visited-page';

function safeLoad(): Partial<RestorableState> {
  try {
    const raw = safeLocalStorage.getItem(REDUX_STATE_LOCAL_STORAGE_KEY);
    if (raw == null) {
      return {};
    }
    return JSON.parse(raw) as RestorableState;
  } catch (e) {
    console.warn('error parsing local storage state', e);
    return {};
  }
}

type LastVisitedPage =
  | Pick<ModelEntity | PageEntity, 'type' | 'id'>
  | { type: 'plansPage' }
  | { type: 'unlistedDriversPage' };

export function saveLastVisitedEntity(entity: LastVisitedPage) {
  safeLocalStorage.setItem(LAST_VISITED_PAGE_STORAGE_KEY, JSON.stringify(entity));
}

export function getLastVisitedPage(): LastVisitedPage | null {
  try {
    const raw = safeLocalStorage.getItem(LAST_VISITED_PAGE_STORAGE_KEY);
    if (raw == null) {
      return null;
    }
    return JSON.parse(raw) as LastVisitedPage;
  } catch (e) {
    console.warn('error parsing local storage state', e);
  }

  return null;
}

// TODO: currently this is not aware of users with multiple orgs. It kind of
// "just works" in that IDs across orgs are meaningless and nothing cares but it
// may be a cause of future bugs. At the time of this writing, we don't know the
// org when the redux store is initialized so it's not easy to just key off
// that. We may eventually need to solve this but for now, I think it's OK to punt.
export function restoreUserState(reduxState: RootState | undefined): RootState | undefined {
  if (isSSR() || reduxState == null) {
    return reduxState;
  }
  const savedState = safeLoad();
  return {
    ...reduxState,
    dataset: {
      ...reduxState.dataset,
      ...savedState.dataset,
    },
    roadmap: {
      ...reduxState.roadmap,
      ...savedState.roadmap,
    },
    modelView: {
      ...reduxState.modelView,
      ...savedState.modelView,
    },
    formulaInput: {
      ...reduxState.formulaInput,
      ...savedState.formulaInput,
    },
    navigationSidebar: {
      ...reduxState.navigationSidebar,
      ...savedState.navigationSidebar,
      sections: {
        ...reduxState.navigationSidebar.sections,
        ...(savedState.navigationSidebar?.sections ?? {}),
      },
    },
    blockLocalState: {
      ...reduxState.blockLocalState,
      ...savedState.blockLocalState,
    },
  };
}

export function saveUserState(state: RestorableState) {
  safeLocalStorage.setItem(REDUX_STATE_LOCAL_STORAGE_KEY, JSON.stringify(state));
}

export function clearRestorableState() {
  safeLocalStorage.removeItem(REDUX_STATE_LOCAL_STORAGE_KEY);
}
