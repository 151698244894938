import { useCallback, useContext } from 'react';

import { EditDriverView } from 'components/DimensionalDriverMenu/useEditDriverView';
import { DimDriverEditReactContext } from 'config/dimDriverEditContext';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { EnumSetter } from 'hooks/useEnum';
import { DriverId } from 'reduxStore/models/drivers';
import {
  dimensionalDriversByIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';

interface Props {
  setView: EnumSetter<EditDriverView>;
}

const useOnTargetDriverSelected = ({ setView }: Props) => {
  const { setTargetDriver } = useContext(DimDriverEditReactContext);

  const dimensionalDriversById = useAppSelector(dimensionalDriversByIdSelector);
  const driversById = useAppSelector(driversByIdForLayerSelector);

  const onTargetDriverSelected = useCallback(
    (selectedDriver: { name: string; id: DriverId | undefined }) => {
      const selectedDriverId = selectedDriver.id;
      if (selectedDriverId != null) {
        const dimDriver =
          safeObjGet(dimensionalDriversById[selectedDriverId]) ??
          safeObjGet(driversById[selectedDriverId]);
        if (dimDriver != null) {
          setTargetDriver(dimDriver);
        }
      }
      setView.dimensionalDriverMenu();
    },
    [dimensionalDriversById, driversById, setTargetDriver, setView],
  );

  return onTargetDriverSelected;
};

export default useOnTargetDriverSelected;
