import { Button, Flex, LayoutProps, Spinner } from '@chakra-ui/react';
import React from 'react';

import { CELL_HEIGHT_IN_PX } from 'config/cells';
import Plus from 'vectors/Plus';

interface AddButtonBaseProps {
  text: string;
  onClick?: React.MouseEventHandler;
  isDisabled?: boolean;
  loading?: boolean;
  testId?: string;
}

type AddButtonProps = AddButtonBaseProps & Pick<LayoutProps, 'width'>;

export const AddButton = React.forwardRef<HTMLButtonElement, AddButtonProps>(
  (
    {
      text,
      onClick,
      isDisabled,
      width = 'fit-content',
      testId = 'add-row-button',
      loading = false,
    },
    ref,
  ) => {
    return (
      <Button
        pr={2}
        pl={1} // account for icon spacing
        ref={ref}
        data-testid={testId}
        _focusVisible={{}}
        size="sm"
        leftIcon={<Plus />}
        onClick={onClick}
        isDisabled={isDisabled}
        iconSpacing={1}
        variant="iconText"
        width={width}
      >
        {text}
        {loading ? <Spinner size="sm" color="gray.500" ml={2} /> : null}
      </Button>
    );
  },
);

interface AddRowButtonProps extends AddButtonProps {
  isSelected?: boolean;
}

const AddRowButton = React.forwardRef<HTMLButtonElement, AddRowButtonProps>(
  ({ text, onClick, isSelected, isDisabled, loading = false }, ref) => {
    return (
      <Flex
        bg={isSelected ? 'selection.100' : undefined}
        alignItems="center"
        height={`${CELL_HEIGHT_IN_PX}px`}
        width="full"
        position="relative"
        borderBottomWidth="px"
        borderTopWidth="px"
        borderColor="gray.300"
        borderStyle="dashed"
      >
        <AddButton
          ref={ref}
          text={text}
          onClick={onClick}
          isDisabled={isDisabled || loading}
          loading={loading}
          data-testid="add-row-button"
        />
      </Flex>
    );
  },
);

export default AddRowButton;
