import isString from 'lodash/isString';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';

import { CellRef, CellSelection, CellType, DriverCellRef } from 'config/cells';
import { DriverType, ExtModel } from 'generated/graphql';
import { isColumnKeyEqual, isDriverRowKey, isFormulaColumn, isRowKeyEqual } from 'helpers/cells';
import { CopyPasteEntity, FormulaCopyData, MultiFormulaCopyData } from 'helpers/clipboard';
import { getAttrIdsByDimId } from 'helpers/dimensionalDrivers';
import {
  getObjectFilterAttributes,
  getParameterizedTermsForFormula,
  transformFormulaForNewDimensions,
} from 'helpers/driverFormulas';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { getContiguousKeys } from 'helpers/gridKeys';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { updateDriversFormulas } from 'reduxStore/actions/driverMutations';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { ExtDriverSource } from 'reduxStore/models/extDrivers';
import { setCopiedCells, setSelectedCells } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { selectedBlockCellKeysSelector } from 'selectors/activeCellSelectionSelector';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSelector } from 'selectors/businessObjectsSelector';
import {
  businessObjectForSubDriverIdSelector,
  dimensionalPropertyEvaluatorSelector,
} from 'selectors/collectionSelector';
import {
  dimensionalSubDriverSelector,
  driverFormulaSelector,
  driversByIdForLayerSelector,
  subDriversByDriverIdSelector,
} from 'selectors/driversSelector';
import { getFormulaDisplayForFormula } from 'selectors/formulaDisplaySelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import { prevailingCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';
import { visibleModelViewColumnTypesSelector } from 'selectors/visibleColumnTypesSelector';
export type ParameterizedFormulaTerm =
  | {
      type: 'driver';
      driverId: DriverId;
      dimDriverId: DriverId;
      dimensionIds: DimensionId[];
    }
  | {
      type: 'extDriver';
      extDriverId: string;
      source: ExtDriverSource;
      model: ExtModel;
    }
  | {
      type: 'objectFieldSpec';
      fieldSpecId: string;
      isThisRef?: boolean;
    };

export function isFormulaSelection(
  cellSelection: CellSelection<CellRef> | null,
): cellSelection is CellSelection<
  CellRef & { type: CellType.Driver; columnKey: { columnType: 'formula' | 'actualsFormula' } }
> {
  if (cellSelection == null) {
    return false;
  }

  const activeCell = cellSelection.activeCell;
  return (
    activeCell.type === CellType.Driver &&
    isFormulaColumn(activeCell.columnKey) &&
    cellSelection.selectedCells.every(
      (cell) => cell.type === CellType.Driver && isFormulaColumn(cell.columnKey),
    )
  );
}

export const getDriverFormulaCopyData = ({
  state,
  sourceDriverId,
  formulaType,
  formula: givenFormula,
}: {
  state: RootState;
  sourceDriverId: DriverId;
  formulaType: 'forecast' | 'actuals';
  formula?: string;
}): FormulaCopyData | null => {
  const driversById = driversByIdForLayerSelector(state);

  const driver = driversById[sourceDriverId];
  if (driver == null) {
    console.error(`copied driver is missing: ${sourceDriverId}`);
    return null;
  }
  // Need to check if the current cell is a forecast or actuals formula to ensure we copy the correct formula from the driver
  const isForecastFormula = formulaType === 'forecast';
  let formula = givenFormula;
  if (formula == null) {
    if (driver.type === DriverType.Dimensional) {
      formula = isForecastFormula
        ? driver.defaultForecast?.formula
        : driver.defaultActuals?.formula;
    } else {
      formula = isForecastFormula ? driver.forecast.formula : driver.actuals.formula;
    }
  }

  const subDriver = dimensionalSubDriverSelector(state, sourceDriverId);

  const sourceAttributesByDimensionId =
    driver.type === DriverType.Dimensional
      ? {}
      : Object.fromEntries(
          (subDriver?.attributes ?? []).map((attr) => [attr.dimensionId, attr.id]),
        );

  const parameterizedTerms =
    formula != null
      ? getParameterizedTermsForFormula(state, { id: sourceDriverId, type: 'driver' }, formula)
      : [];

  return {
    type: formulaType,
    sourceEntityId: { id: driver.id, type: 'driver' },
    sourceAttributes: sourceAttributesByDimensionId,
    formula: formula ?? '',
    parameterizedTerms,
  };
};

export const getFieldFormulaCopyData = ({
  state,
  sourceFieldSpecId,
  formula: givenFormula,
}: {
  state: RootState;
  sourceFieldSpecId: BusinessObjectFieldSpecId;
  formula?: string;
}): FormulaCopyData | null => {
  const fieldSpecById = businessObjectFieldSpecByIdSelector(state);
  const fieldSpec = safeObjGet(fieldSpecById[sourceFieldSpecId]);

  if (fieldSpec == null) {
    console.error(`copied field spec is missing: ${sourceFieldSpecId}`);
    return null;
  }

  const formula = givenFormula ?? fieldSpec.defaultForecast.formula;

  const parameterizedTerms =
    formula != null
      ? getParameterizedTermsForFormula(
          state,
          { id: sourceFieldSpecId, type: 'objectFieldSpec' },
          formula,
        )
      : [];
  return {
    type: 'forecast',
    sourceEntityId: { id: sourceFieldSpecId, type: 'objectFieldSpec' },
    formula: formula ?? '',
    parameterizedTerms,
    sourceAttributes: {},
  };
};

export const handleFormulaCopy =
  ({ setClipboardData }: { setClipboardData: (data: string) => void }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellSelection = prevailingCellSelectionSelector(state);
    if (!isFormulaSelection(cellSelection)) {
      return;
    }
    const activeCell = cellSelection.activeCell;
    if (activeCell.rowKey.driverId == null) {
      return;
    }
    const { orderedRowKeys } = selectedBlockCellKeysSelector(state);
    const rowRows = orderedRowKeys.filter(isDriverRowKey);

    // Create a set of unique driverId-groupId combinations
    const uniqueRows = new Set<string>();
    cellSelection.selectedCells.forEach((item) => {
      const driverGroupId = `${item.rowKey.driverId}`;
      uniqueRows.add(driverGroupId);
    });

    // Create a set of unique column types
    const uniqueColumns = new Set<string>();
    cellSelection.selectedCells.forEach((item) => {
      uniqueColumns.add(item.columnKey.columnType);
    });

    // Convert the sets to arrays
    const columns = Array.from(uniqueColumns);

    const rows = Array.from(uniqueRows);

    const orderMap = rowRows.reduce(
      (acc, curr, index) => {
        const rowDriverId = curr?.driverId;
        if (rowDriverId != null) {
          acc[rowDriverId] = index;
        }
        return acc;
      },
      {} as { [key: string]: number },
    );

    rows.sort((a, b) => orderMap[a] - orderMap[b]);

    // Create a 2D array to store the copied values of the the formula columns by driver row & initial values of null
    const numRows = rows.length;
    const numColumns = columns.length;

    const copyData: Array<Array<FormulaCopyData | null>> = Array.from({ length: numRows }, () =>
      new Array<FormulaCopyData | null>(numColumns).fill(null),
    );

    // Populate the array with values from the data
    cellSelection.selectedCells.forEach((cell) => {
      const driverGroupId = `${cell.rowKey.driverId}`;
      const rowIndex = rows.indexOf(driverGroupId);
      const columnIndex = columns.indexOf(cell.columnKey.columnType);

      // '+ Create Driver' button is counted as a cell row, but it is not a functional driver row so we should skip it
      const driverId = cell.rowKey.driverId;
      const nonFunctionalDriverRow = driverId == null;
      if (nonFunctionalDriverRow) {
        return;
      }

      const formulaCopyData = getDriverFormulaCopyData({
        state,
        sourceDriverId: driverId,
        formulaType: cell.columnKey.columnType === 'formula' ? 'forecast' : 'actuals',
      });

      if (formulaCopyData == null) {
        return;
      }
      copyData[rowIndex][columnIndex] = formulaCopyData;
    });

    const nonNullData: FormulaCopyData[][] = copyData
      .map((innerArray) => innerArray.filter(isNotNull))
      .filter((innerArray) => innerArray.length > 0);

    const updateData: MultiFormulaCopyData = {
      type: 'formula',
      data: nonNullData,
    };

    const update = JSON.stringify(updateData);

    setClipboardData(update);

    dispatch(
      setCopiedCells({
        blockId: cellSelection.blockId,
        selectedCells: cellSelection.selectedCells,
      }),
    );
  };

export const getFormulaFromFormulaCopyData = ({
  state,
  targetEntityId,
  pastedData,
  targetOriginalFormula,
  blockId,
  objectId,
  driverIsBeingNewlyCreated,
  newAttributes,
}: {
  state: RootState;
  targetEntityId: FormulaEntityTypedId;
  pastedData: FormulaCopyData;
  targetOriginalFormula: string | null;
  blockId: BlockId;
  // This is for when the target driver doesn't exist yet
  objectId?: BusinessObjectId;
  driverIsBeingNewlyCreated?: boolean;
  newAttributes?: Record<DimensionId, AttributeId>;
}) => {
  const pastedFormula = pastedData.formula;
  const pastedAttrs = pastedData.sourceAttributes;
  const pastedParameterizedTerms = pastedData.parameterizedTerms;
  const sourceEntityId = pastedData.sourceEntityId;
  const objectFilterAttributes = getObjectFilterAttributes(state, sourceEntityId, pastedFormula);

  const targetDriverId = targetEntityId.type === 'driver' ? targetEntityId.id : null;
  const relatedBusinessObjectSpec = businessObjectSpecForBlockSelector(state, blockId);
  const relatedBusinessObject =
    objectId != null
      ? (businessObjectSelector(state, objectId) ?? null)
      : relatedBusinessObjectSpec != null && targetDriverId != null
        ? businessObjectForSubDriverIdSelector(state, {
            driverId: targetDriverId,
            businessObjectSpecId: relatedBusinessObjectSpec.id,
          })
        : null;

  const targetFormulaDisplay =
    targetOriginalFormula == null
      ? null
      : getFormulaDisplayForFormula(state, targetOriginalFormula, targetEntityId);

  const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);
  const driversById = driversByIdForLayerSelector(state);
  const subDriversById = subDriversByDriverIdSelector(state);

  // Replace source attributes with destination driver's attributes
  // This handles any cases where the attribute uuid is included in the formula directly (such as filters)

  let destAttrIdsByDimIds;
  if (targetDriverId == null || subDriversById[targetDriverId] == null) {
    if (relatedBusinessObject == null) {
      destAttrIdsByDimIds = {};
    } else {
      const keyAttributes = dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(
        relatedBusinessObject.id,
      );
      destAttrIdsByDimIds = getAttrIdsByDimId(keyAttributes.map((attr) => attr.attribute));
    }
  } else {
    destAttrIdsByDimIds = getAttrIdsByDimId(subDriversById[targetDriverId].attributes);
  }

  if (newAttributes != null) {
    for (const [dimensionId, attributeId] of Object.entries(newAttributes)) {
      destAttrIdsByDimIds[dimensionId] = attributeId;
    }
  }

  // Replace parameterized terms with destination driver's variables
  const destFormula = transformFormulaForNewDimensions({
    origFormula: pastedFormula,
    sourceEntityId,
    sourceAttrs: pastedAttrs,
    objectFilterAttrs: objectFilterAttributes,
    parameterizedTerms: pastedParameterizedTerms,
    destEntityId: targetEntityId,
    destAttrs: destAttrIdsByDimIds,
    driversById,
    relatedBusinessObject,
    relatedBusinessObjectSpec,
    dimensionalPropertyEvaluator,
    targetFormulaDisplay,
    driverIsBeingNewlyCreated: driverIsBeingNewlyCreated ?? false,
  });

  return destFormula;
};

export const handleFormulaPaste =
  (pastedEntity: CopyPasteEntity | string): AppThunk =>
  (dispatch, getState) => {
    if (isString(pastedEntity) || pastedEntity.type !== 'formula') {
      return;
    }
    const state = getState();
    const cellSelection = prevailingCellSelectionSelector(state);

    if (!isFormulaSelection(cellSelection)) {
      return;
    }

    const pastedData = pastedEntity.data;

    // Repeat paste pattern as many times as possible to fit into target paste cells.
    // Paste pattern 1 time if paste pattern is longer than selected cells.
    // e.g. paste [1,2] into columns [Sep '21, Oct' 21, Nov '21, Dec '21, Jan '22] => [1,2,1,2]
    // e.g. paste [1,2,3] into columns [Sep '21] => [1,2,3]

    const numPastedRows = pastedData.length;
    const numPastedColumns = pastedData[0]?.length ?? 0;

    // ensure that for multi-paste, the pasted data is a rectangular array
    const isSymmetricArray = pastedData.every((row) => row.length === numPastedColumns);

    if (!isSymmetricArray) {
      return;
    }

    const { selectedCells, activeCell, blockId } = cellSelection;

    if (blockId == null) {
      return;
    }

    const activeCellColumnKey = activeCell.columnKey;
    const activeCellColumnKeyType = activeCellColumnKey.columnType;
    const selectedColumnKeys = uniqWith(
      selectedCells.map((ref) => ref.columnKey),
      isColumnKeyEqual,
    );

    // Check if both 'forecast' and 'actuals' columns are displayed and in which order to ensure valid copy/paste operations can occur
    const visibleModelViewColumnTypes = visibleModelViewColumnTypesSelector(state, blockId);
    const formulaCols = visibleModelViewColumnTypes.filter(
      (type) => type === 'formula' || type === 'actualsFormula',
    );

    // If pasting multiple columns, ensure that the active formula cell is first formula column
    const invalidMultiFormulaPaste =
      numPastedColumns > 1 && formulaCols.indexOf(activeCellColumnKeyType) !== 0;

    if (invalidMultiFormulaPaste) {
      return;
    }

    const { orderedRowKeys, orderedColumnKeys } = selectedBlockCellKeysSelector(state);

    const activeCellRowKey = activeCell.rowKey;
    const selectedRowKeys = uniq(selectedCells.map((c) => c.rowKey));

    const contiguousRows = getContiguousKeys(
      selectedRowKeys.map((key) => ({
        key,
        position: orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, key)),
      })),
      activeCellRowKey,
    );
    const contiguousColumns = getContiguousKeys(
      selectedColumnKeys.map((key) => ({
        key,
        position: orderedColumnKeys.findIndex((ck) => isColumnKeyEqual(ck, key)),
      })),
      activeCellColumnKey,
    );

    const isNonContiguousRangeSelected =
      selectedCells.length !== contiguousRows.length * contiguousColumns.length;

    const hasSinglePastedValue = numPastedRows === 1 && numPastedColumns === 1;

    // bail out if we're trying to paste complex patterns into a non-continguous range, or if we're
    // trying to do paste a non-contiguous collection
    if (isNonContiguousRangeSelected && !hasSinglePastedValue) {
      return;
    }

    const rowRepeat = Math.max(Math.floor(contiguousRows.length / numPastedRows), 1);
    const columnRepeat = Math.max(Math.floor(contiguousColumns.length / numPastedColumns), 1);
    const startRowIdx = orderedRowKeys.findIndex((rk) => isRowKeyEqual(rk, contiguousRows[0]));
    const startColIdx = orderedColumnKeys.findIndex((ck) =>
      isColumnKeyEqual(ck, contiguousColumns[0]),
    );

    const relevantColKeys = orderedColumnKeys.slice(
      startColIdx,
      startColIdx + Math.max(contiguousColumns.length, numPastedColumns),
    );

    const relevantRowKeys = orderedRowKeys.slice(
      startRowIdx,
      startRowIdx + Math.max(contiguousRows.length, numPastedRows),
    );

    if (!relevantRowKeys.every(isDriverRowKey)) {
      return;
    }

    const formulaUpdates: Array<{
      driverId: DriverId;
      type: 'actuals' | 'forecast';
      formula: string;
    }> = [];

    const rowSet = new Set<CellRef['rowKey']>();

    for (let idx = 0; idx < rowRepeat * numPastedRows; idx++) {
      const rowKey = orderedRowKeys[startRowIdx + idx] as DriverCellRef['rowKey'] | undefined;
      const driverId = rowKey?.driverId;

      if (driverId == null) {
        continue;
      }

      rowSet.add(orderedRowKeys[startRowIdx + idx]);

      for (let jdx = 0; jdx < columnRepeat * numPastedColumns; jdx++) {
        const pastedRow = pastedData[idx % numPastedRows][jdx % numPastedColumns];
        const pastedFormulaType = pastedRow.type;
        // When pasting into a single formula column use that column's type
        // otherwise use the type of the pasted formula type
        let formulaCellType: 'forecast' | 'actuals' = pastedFormulaType;
        if (numPastedColumns === 1) {
          formulaCellType = activeCellColumnKeyType === 'formula' ? 'forecast' : 'actuals';
        }

        const targetOriginalFormula = driverFormulaSelector(state, {
          id: driverId,
          type: formulaCellType,
        });

        formulaUpdates.push({
          formula: getFormulaFromFormulaCopyData({
            state,
            targetEntityId: { id: driverId, type: 'driver' },
            pastedData: pastedRow,
            targetOriginalFormula,
            blockId,
          }),
          driverId,
          type: formulaCellType,
        });
      }
    }

    dispatch(
      updateDriversFormulas({
        updates: formulaUpdates,
      }),
    );
    dispatch(
      setSelectedCells({
        ...cellSelection,
        isBackground: true,
        selectedCells: relevantColKeys.flatMap((columnKey) =>
          [...rowSet].map(
            (rowKey) =>
              ({
                ...activeCell,
                rowKey,
                columnKey,
              }) as CellRef,
          ),
        ),
      }),
    );
  };
