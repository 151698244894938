import React from 'react';

import NumericDisplayCell from 'components/DisplayCell/NumericDisplayCell';
import NumericActiveCell, {
  NumericActiveCellProps,
} from 'components/NumericActiveCell/NumericActiveCell';
import TimeSeriesCellWrapper, {
  TimeSeriesCellProps,
} from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapper';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import { NumberValue } from 'reduxStore/models/value';

const NumericTimeSeriesCell = React.forwardRef<
  HTMLDivElement,
  TimeSeriesCellProps<NumberValue> & {
    onContextMenu?: React.MouseEventHandler;
    isCellEditing?: boolean;
  } & NumericActiveCellProps
>(
  (
    {
      color,
      width,
      displayConfiguration,
      impactType,
      onSave,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onContextMenu,
      alert,
      value,
      error,
      onStartEditing,
      showComparisonHighlight,
      isForecast,
    },
    ref,
  ) => {
    const { isActive } = useCellSelectionStateContext();

    return (
      <TimeSeriesCellWrapper
        ref={ref}
        color={color}
        showComparisonHighlight={showComparisonHighlight}
        width={width}
        onContextMenu={onContextMenu}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {isActive ? (
          <NumericActiveCell
            value={value}
            error={error}
            displayConfiguration={displayConfiguration}
            impactType={impactType}
            onSave={onSave}
            onStartEditing={onStartEditing}
            isForecast={isForecast}
          />
        ) : (
          <NumericDisplayCell
            value={value}
            error={error}
            displayConfiguration={displayConfiguration}
            impactType={impactType}
          />
        )}
        {alert}
      </TimeSeriesCellWrapper>
    );
  },
);

export default React.memo(NumericTimeSeriesCell);
