import { clamp } from 'lodash';

export const MIN_MEDIA_WIDTH = 32;

function getImageDimensionsFromUrl(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };
    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };
  });
}

export async function getInitialMediaWidthFromUrl(
  url: string,
  type: 'image' | 'video',
): Promise<number> {
  const defaultBlockWidth = 720;

  // For videos use a default max width
  if (type === 'video') {
    return defaultBlockWidth;
  }
  // For images, fetch the dimensions from the URL
  // We allow images smaller than MIN_MEDIA_WIDTH to be uploaded as-is
  // This preserves small icons or thumbnails without forced enlargement
  // The width is capped at defaultBlockWidth to prevent oversized images
  // During resize operations, MIN_MEDIA_WIDTH will be enforced as the lower bound
  const { width } = await getImageDimensionsFromUrl(url);
  return clamp(width, 0, defaultBlockWidth);
}

export function getInitialMediaWidthFromFile(file: File, type: 'image' | 'video'): Promise<number> {
  return getInitialMediaWidthFromUrl(URL.createObjectURL(file), type);
}

export function getDomainFromUrl(url: string): string {
  // If the URL doesn't start with a protocol, prepend 'http://'
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    url = 'http://' + url;
  }

  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    console.error('Invalid URL:', url);
    return '';
  }
}
