import React from 'react';

import MultiImpactPlanPicker from 'components/PlanPicker/MultiImpactPlanPicker';
import SingleImpactPlanPicker from 'components/PlanPicker/SingleImpactPlanPicker';
import { ImpactType } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import { updatedMultiDeltaImpactsSelector } from 'selectors/cellPaletteSelector';
import { activeCellImpactSelector } from 'selectors/eventsAndGroupsSelector';
import { enableStackedImpactsSelector } from 'selectors/launchDarklySelector';
import { prevailingCellSelectionIsSingleCellSelector } from 'selectors/prevailingCellSelectionSelector';

type Props = {
  isEditingActiveCell: boolean;
  className?: string;
  disableMultiImpactPicker?: boolean;
};

const PlanPicker = React.forwardRef<HTMLDivElement, Props>(
  ({ isEditingActiveCell, className, disableMultiImpactPicker = false }, ref) => {
    const enableStackedImpacts = useAppSelector(enableStackedImpactsSelector);

    const multiDeltaImpacts = useAppSelector(updatedMultiDeltaImpactsSelector);
    const activeCellImpact = useAppSelector(activeCellImpactSelector);
    const isSingleCellSelected = useAppSelector(prevailingCellSelectionIsSingleCellSelector);

    const hasUpdatedMultiDeltaImpacts = multiDeltaImpacts != null && multiDeltaImpacts.length > 0;
    const activeCellHasMultiDeltaImpacts =
      activeCellImpact?.impactType === ImpactType.Delta &&
      activeCellImpact.valuesWithEventGroups.length > 1;

    if (
      enableStackedImpacts &&
      isSingleCellSelected &&
      !disableMultiImpactPicker &&
      (activeCellHasMultiDeltaImpacts || hasUpdatedMultiDeltaImpacts)
    ) {
      return <MultiImpactPlanPicker ref={ref} isEditingActiveCell={isEditingActiveCell} />;
    }

    return (
      <SingleImpactPlanPicker
        isEditingActiveCell={isEditingActiveCell}
        ref={ref}
        className={className}
      />
    );
  },
);

export default React.memo(PlanPicker);
