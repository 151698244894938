import { BlockFilter, BlockFilterType, ValueType } from 'generated/graphql';
import { getTimeRangeInput } from 'helpers/formula';
import {
  FilterItem,
  FilterValueTypes,
  isEntityIdFilterItem,
  isValueFilterItem,
} from 'types/filtering';

const VALID_TIMELINE_FILTER_KEYS = [
  'planStart',
  'planEnd',
  'impactsModelId',
  'impactsObjectSpecId',
  'eventGroupId',
] as const;
export type TimelineFilterKey = (typeof VALID_TIMELINE_FILTER_KEYS)[number];

export function isTimelineFilterKey(key: string): key is TimelineFilterKey {
  return VALID_TIMELINE_FILTER_KEYS.includes(key as TimelineFilterKey);
}

export const PLAN_FILTER_LABEL_BY_FILTER_KEY: Record<TimelineFilterKey, string> = {
  eventGroupId: 'Plan name',
  planStart: 'Plan start',
  planEnd: 'Plan end',
  impactsModelId: 'Model',
  impactsObjectSpecId: 'Database',
};

export const AVAILABLE_FILTERS: Array<FilterItem & { filterKey: TimelineFilterKey }> = [
  {
    valueType: FilterValueTypes.ENTITY,
    label: PLAN_FILTER_LABEL_BY_FILTER_KEY.eventGroupId,
    filterKey: 'eventGroupId',
    entityType: 'eventGroup',
    isNotNullable: true,
  },
  {
    valueType: ValueType.Timestamp,
    label: PLAN_FILTER_LABEL_BY_FILTER_KEY.planStart,
    filterKey: 'planStart',
    isNotNullable: true,
  },
  {
    valueType: ValueType.Timestamp,
    label: PLAN_FILTER_LABEL_BY_FILTER_KEY.planEnd,
    filterKey: 'planEnd',
    isNotNullable: true,
  },
  {
    valueType: FilterValueTypes.ENTITY,
    label: PLAN_FILTER_LABEL_BY_FILTER_KEY.impactsModelId,
    filterKey: 'impactsModelId',
    entityType: 'model',
  },
  {
    valueType: FilterValueTypes.ENTITY,
    label: PLAN_FILTER_LABEL_BY_FILTER_KEY.impactsObjectSpecId,
    filterKey: 'impactsObjectSpecId',
    entityType: 'spec',
  },
];

export type TimelineFilterItem = FilterItem & { filterKey: TimelineFilterKey };

export function isValidPlanTimelineFilter(filter: FilterItem): filter is TimelineFilterItem {
  if (!isTimelineFilterKey(filter.filterKey)) {
    return false;
  }

  const matching = AVAILABLE_FILTERS.find((avail) => avail.filterKey === filter.filterKey);
  if (matching == null) {
    return false;
  }

  if (matching.valueType !== filter.valueType || matching.label !== filter.label) {
    return false;
  }

  if (
    matching.valueType === FilterValueTypes.ENTITY &&
    filter.valueType === FilterValueTypes.ENTITY &&
    matching.entityType !== filter.entityType
  ) {
    return false;
  }

  return true;
}

export function blockFiltersFromTimelineFilters(items: FilterItem[]): BlockFilter[] {
  return items.filter(isValidPlanTimelineFilter).map((item) => ({
    filterType: BlockFilterType.KeyValue,
    filterKey: item.filterKey,
    filterOp: item.operator,
    filterValues: getValueFromFilterItem(item),
  }));
}

// This doesn't currently support the ValueType.Attribute case because it's not
// used outside of object expression filters. If we want to support those,
// we'll need to be persist the dimension ID.
function getValueFromFilterItem(item: FilterItem): string[] | undefined {
  let values: string[] | undefined;

  if (isEntityIdFilterItem(item)) {
    values = item.expected;
  } else if (isValueFilterItem(item)) {
    if (item.valueType === ValueType.Number) {
      values = item.expected != null ? [item.expected.toString()] : undefined;
    } else if (item.valueType === ValueType.Timestamp) {
      values = item.expected != null ? [getTimeRangeInput(item.expected)] : undefined;
    }
  }

  return values;
}
