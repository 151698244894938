import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'VerticalResizer',
  viewBox: '0 0 18 18',
  path: (
    <>
      <rect y="10" width="17.5" height="1" rx="0.5" fill="currentColor" />
      <rect y="14" width="17.5" height="1" rx="0.5" fill="currentColor" />
    </>
  ),
});
