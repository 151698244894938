import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { DRIVER_DETAIL_PAGE_TYPE } from 'config/internalPages/driverDetailPage';
import {
  getSubmodelIdFromInternalPageType,
  isSubmodelBlockPage,
} from 'config/internalPages/modelPage';
import { PLAN_DETAIL_PAGE_TYPE } from 'config/internalPages/planDetailPage';
import { Block } from 'generated/graphql';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverId } from 'reduxStore/models/drivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import type { ModelView } from 'reduxStore/reducers/modelViewSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blocksPagesByIdSelector } from 'selectors/blocksPagesSelector';
import { blocksPageByInternalPageTypeSelector } from 'selectors/blocksPagesTableSelector';
import { blockSelector, blocksByIdSelector } from 'selectors/blocksSelector';
import { blockIdSelector, driverIdSelector } from 'selectors/constSelectors';
import { currentSubmodelPageIdSelector } from 'selectors/submodelPageSelector';
import { ParametricSelector, Selector } from 'types/redux';

const modelViewSelector: Selector<ModelView> = (state) => state.modelView;

const modelPageBlockIdSelector: Selector<BlockId | null> = createSelector(
  currentSubmodelPageIdSelector,
  blocksPagesByIdSelector,
  (currentSubmodelPageId, blockPagesById) => {
    if (currentSubmodelPageId == null) {
      return null;
    }

    const page = blockPagesById[currentSubmodelPageId];

    if (page == null || page.blockIds.length === 0) {
      return null;
    }

    return page.blockIds[0];
  },
);

export const currentModelPageBlockSelector: Selector<Block | null> = createSelector(
  modelPageBlockIdSelector,
  blocksByIdSelector,
  (modelPageBlockId, blocksById) => {
    if (modelPageBlockId == null) {
      return null;
    }

    return blocksById[modelPageBlockId] ?? null;
  },
);

export const submodelPageIdForBlockIdSelector: ParametricSelector<BlockId, SubmodelId | undefined> =
  createCachedSelector(
    (state: RootState, blockId: BlockId) => blockSelector(state, blockId),
    blocksPagesByIdSelector,
    (block, blocksPagesById) => {
      if (block == null) {
        return undefined;
      }

      const blockPage = blocksPagesById[block.pageId];

      if (blockPage == null) {
        return undefined;
      }

      return isSubmodelBlockPage(blockPage.internalPageType)
        ? getSubmodelIdFromInternalPageType(blockPage.internalPageType)
        : undefined;
    },
  )(blockIdSelector);

export const isBlockIdInModelPageSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    submodelPageIdForBlockIdSelector,
    (submodelId) => submodelId != null,
  )(blockIdSelector);

const driverDetailPageBlockIdSelector: Selector<BlockId | null> = createSelector(
  blocksPageByInternalPageTypeSelector,
  (pagesByInternalPageType) => {
    const page = pagesByInternalPageType[DRIVER_DETAIL_PAGE_TYPE];
    if (page == null || page.blockIds.length === 0) {
      return null;
    }

    return page.blockIds[0];
  },
);

export const plansPageBlockIdSelector: Selector<BlockId | null> = createSelector(
  blocksPageByInternalPageTypeSelector,
  (pagesByInternalPageType) => {
    const page = pagesByInternalPageType.plans;
    if (page == null || page.blockIds.length === 0) {
      return null;
    }

    return page.blockIds[0];
  },
);

const planDetailPageBlockIdSelector: Selector<BlockId | null> = createSelector(
  blocksPageByInternalPageTypeSelector,
  (pagesByInternalPageType) => {
    const page = pagesByInternalPageType[PLAN_DETAIL_PAGE_TYPE];
    if (page == null || page.blockIds.length === 0) {
      return null;
    }

    return page.blockIds[0];
  },
);

export const driverDetailPageBlockSelector: Selector<Block | null> = createSelector(
  driverDetailPageBlockIdSelector,
  blocksByIdSelector,
  (driverDetailBlockId, blocksById) => {
    if (driverDetailBlockId == null) {
      return null;
    }

    return blocksById[driverDetailBlockId] ?? null;
  },
);

export const planDetailPageBlockSelector: Selector<Block | null> = createSelector(
  planDetailPageBlockIdSelector,
  blocksByIdSelector,
  (planDetailBlockId, blocksById) => {
    if (planDetailBlockId == null) {
      return null;
    }

    return blocksById[planDetailBlockId] ?? null;
  },
);

export const isBlockDriverDetailPageSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    driverDetailPageBlockSelector,
    blockIdSelector,
    (driverDetailBlock, blockId) => {
      return driverDetailBlock?.id === blockId;
    },
  )(blockIdSelector);

export const isBlockPlanDetailPageSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(planDetailPageBlockSelector, blockIdSelector, (planDetailBlock, blockId) => {
    return planDetailBlock?.id === blockId;
  })(blockIdSelector);

export const isEditingDriverSelector: ParametricSelector<DriverId, boolean> = createCachedSelector(
  (state: RootState) => modelViewSelector(state),
  driverIdSelector,
  (modelView, driverId) => modelView.editingDriver?.driverId === driverId,
)((_state, driverId) => driverId);
