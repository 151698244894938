import React, { useCallback, useMemo } from 'react';

import { EditDriverView } from 'components/DimensionalDriverMenu/useEditDriverView';
import useOnTargetDriverSelected from 'components/DimensionalDriverMenu/useOnTargetDriverSelected';
import { SECTIONS_BY_ID } from 'components/DriverBlockSelect/constants';
import DriverSearchResult from 'components/DriverSearchResult/DriverSearchResult';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import SelectMenu, { CustomOption, Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS } from 'config/selectMenu';
import { isNotNull } from 'helpers/typescript';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { EnumSetter } from 'hooks/useEnum';
import { jumpToDriver } from 'reduxStore/actions/jumpToDriver';
import { DimensionalDriver, Driver, DriverId } from 'reduxStore/models/drivers';
import { accessibleDriversByIdForLayerSelector } from 'selectors/accessibleDriversSelector';
import { blockDriverIdsSelector } from 'selectors/blocksSelector';
import { Plus } from 'vectors';

// This is the action that happens on Enter key press for each subdriver in the select menu.
// Subdrivers that do not already exist have the 'add-driver' action (creating the subdriver),
// and for subdrivers that do exist, the 'jump-to-driver' action closes the select menu
// and focus is moved to the driver cell.
type SelectAction = 'add-driver' | 'jump-to-driver';

type DimensionalDriverSelectItem = SelectItem & {
  driver: Driver;
  selectAction: SelectAction;
};

const SECTIONS: Section[] = [
  {
    ...SECTIONS_BY_ID.driver,
    name: 'Drivers',
    maxResults: 3,
    showMore: true,
  },
];

interface Props {
  addDrivers: (driverIds: DriverId[]) => void;
  onClose: () => void;
  driver: DimensionalDriver;
  setView: EnumSetter<EditDriverView>;
}

const DimensionalDriverSelect: React.FC<Props> = ({
  addDrivers,
  onClose,
  driver: parentDriver,
  setView,
}) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();

  const onTargetDriverSelected = useOnTargetDriverSelected({ setView });

  const driversById = useAppSelector(accessibleDriversByIdForLayerSelector);
  const blockDriverIds = useAppSelector((state) => blockDriverIdsSelector(state, blockId));
  const addedDriverIds = useMemo(() => new Set(blockDriverIds), [blockDriverIds]);

  const subDriverItems = useMemo<DimensionalDriverSelectItem[]>(() => {
    return parentDriver.subdrivers
      .map((subDriver) => {
        if (subDriver == null) {
          return null;
        }
        const { driverId } = subDriver;
        const driver = driversById[driverId];
        if (driver == null) {
          return null;
        }

        const hasNoPrimaryAction = addedDriverIds.has(driverId);
        const metaProps = hasNoPrimaryAction
          ? {
              color: 'white',
              _hover: { bgColor: 'selection.500', color: 'white' },
              _active: { bgColor: 'selection.500' },
            }
          : {
              color: 'white',
              _hover: { bgColor: 'selection.600' },
              _active: { bgColor: 'selection.600' },
            };

        const subDriverItem = {
          id: driverId,
          type: 'driver' as const,
          driver,
          name: driver.name,
          sectionId: 'driver' as const,
          selectAction: 'add-driver' as const,
          meta: (
            <OpenDetailsModalButton
              type="driver"
              id={driver.id}
              className={SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS}
              openInNewTab
              {...metaProps}
            />
          ),
        };

        if (hasNoPrimaryAction) {
          return {
            ...subDriverItem,
            hasNoPrimaryAction,
            selectAction: 'jump-to-driver' as const,
            onlyEnableMeta: true,
            shortcutHint: null,
          };
        }
        return subDriverItem;
      })
      .filter(isNotNull);
  }, [addedDriverIds, driversById, parentDriver.subdrivers]);

  const onSelect = useCallback(
    (driverItem: DimensionalDriverSelectItem) => {
      switch (driverItem.selectAction) {
        case 'jump-to-driver':
          dispatch(
            jumpToDriver({ driverId: driverItem.id, opts: { openInNewTab: false }, blockId }),
          );
          onClose();
          break;
        case 'add-driver':
          addDrivers([driverItem.id]);
          break;
        default:
          break;
      }
    },
    [addDrivers, blockId, dispatch, onClose],
  );

  const availableSubDriverIds = useMemo(() => {
    return subDriverItems
      .filter((driverItem) => !addedDriverIds.has(driverItem.id))
      .map((driverItem) => driverItem.id);
  }, [addedDriverIds, subDriverItems]);

  const customOptions: CustomOption[] = useMemo(() => {
    return [
      {
        id: 'addAllDrivers',
        icon: <Plus />,
        render: () => <CustomCreateOption label="Add all drivers" />,
        onSelect: () => addDrivers(availableSubDriverIds),
        showFirst: true,
        isDisabled: availableSubDriverIds.length === 0,
        shortcutHint: availableSubDriverIds.length === 0 ? null : undefined,
      },
      {
        id: 'create',
        icon: <Plus />,
        render: () => (
          <CustomCreateOption label="Create driver" data-testid="create-driver-custom-option" />
        ),
        onSelect: (query: string) => onTargetDriverSelected({ name: query, id: parentDriver.id }),
        showFirst: true,
      },
    ];
  }, [addDrivers, availableSubDriverIds, onTargetDriverSelected, parentDriver.id]);

  return (
    <SelectMenuContainer testId="add-dimensional-driver-select">
      <SelectMenu
        customOptions={customOptions}
        items={subDriverItems}
        onSelect={onSelect}
        sections={SECTIONS}
        width="25rem"
        allowEmptyQuery
      >
        {({ item, isFocused, idx }) => {
          return <DriverSearchResult {...item} isFocused={isFocused} idx={idx} />;
        }}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default DimensionalDriverSelect;
