import uniq from 'lodash/uniq';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { DriverId } from 'reduxStore/models/drivers';
import { blocksSelector } from 'selectors/blocksSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { Selector } from 'types/redux';

export const allKpiIdsSelector: Selector<DriverId[]> = createDeepEqualSelector(
  blocksSelector,
  driversByIdForLayerSelector,
  (blocks, driversById) => {
    return uniq(blocks.flatMap((b) => b.blockConfig.idFilter ?? [])).filter(
      (id) => driversById[id] != null,
    );
  },
);
