import noop from 'lodash/noop';
import { useCallback, useEffect, useRef, useState } from 'react';

import { preventEventDefault } from 'helpers/browserEvent';

const DEFAULT_MIN_COLUMN_WIDTH = 50;
interface Props {
  columnWidth: number;
  onLiveResize: (width: number) => void;
  onEndResize?: (width: number) => void;
  minColumnWidth?: number;
}

export default function useColumnResizer({
  columnWidth,
  onLiveResize,
  onEndResize,
  minColumnWidth = DEFAULT_MIN_COLUMN_WIDTH,
}: Props) {
  const newWidth = useRef<number | null>(null);
  const [dragState, setDragState] = useState<{
    initX: number;
    initWidth: number;
  } | null>(null);

  const isResizing = dragState != null;

  const startDrag = useCallback(
    (event: React.MouseEvent) => {
      preventEventDefault(event);
      setDragState({ initX: event.clientX, initWidth: columnWidth });
      newWidth.current = columnWidth;
    },
    [columnWidth],
  );

  const drag = useCallback(
    (event: MouseEvent) => {
      if (!isResizing) {
        return;
      }
      newWidth.current = Math.max(
        dragState.initWidth + (event.clientX - dragState.initX),
        minColumnWidth,
      );
      onLiveResize(newWidth.current);
    },
    [dragState, isResizing, minColumnWidth, onLiveResize],
  );

  const endDrag = useCallback(() => {
    if (!isResizing) {
      return;
    }

    onEndResize?.(newWidth.current ?? dragState.initWidth);
    setDragState(null);
    newWidth.current = null;
  }, [dragState, isResizing, onEndResize]);

  useEffect(() => {
    if (!isResizing) {
      return noop;
    }

    window.addEventListener('mouseup', endDrag);
    window.addEventListener('mousemove', drag);
    return () => {
      window.removeEventListener('mouseup', endDrag);
      window.removeEventListener('mousemove', drag);
    };
  }, [endDrag, isResizing, drag]);

  return {
    isResizing,
    startDrag,
  };
}
