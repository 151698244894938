import {
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

import { Tooltip } from 'chakra/tooltip';
import DatasetVersionsList from 'components/NamedDatasetVersionMenu/DatasetVersionsList';
import RecentDatasetVersionsList from 'components/NamedDatasetVersionMenu/RecentDatasetVersionsList';
import { GlobalNavIcon, TOOLTIP_OPEN_DELAY } from 'components/RunwayApp/GlobalNavigationMenu';
import useAppDispatch from 'hooks/useAppDispatch';
import useControlledPopover from 'hooks/useControlledPopover';
import { jumpToNamedVersion } from 'reduxStore/actions/jumpToNamedVersion';
import { LayerId } from 'reduxStore/models/layers';
import RepeatClock from 'vectors/RepeatClock';

const NamedDatasetVersionsMenu = () => {
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState(1);
  const [popoverLayerId, setPopoverLayerId] = useState<LayerId | undefined>(undefined);
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();

  const openNewLayerId = useCallback((layerId: string) => {
    setPopoverLayerId(layerId);
    setTabIndex(1);
  }, []);

  const onTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const jumpToVersion = useCallback(
    (layerId: string) => {
      dispatch(jumpToNamedVersion(layerId));
    },
    [dispatch],
  );

  return (
    <Flex alignItems="center" data-testid="versions-menu">
      <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen} isLazy placement="bottom-end">
        <>
          <Tooltip
            label="History"
            shouldWrapChildren
            placement="bottom-end"
            openDelay={TOOLTIP_OPEN_DELAY}
            closeOnPointerDown
            isDisabled={isOpen}
          >
            <PopoverTrigger>
              <Flex ref={triggerRef}>
                <GlobalNavIcon aria-label="Show versions" icon={<RepeatClock boxSize={4} />} />
              </Flex>
            </PopoverTrigger>
          </Tooltip>
          <Portal>
            <PopoverContent ref={contentRef} width="22rem" pb={0}>
              <PopoverCloseButton />
              <PopoverBody>
                <Tabs index={tabIndex} onChange={onTabsChange}>
                  <TabList>
                    <Tab px={1} mr={2}>
                      Recent versions
                    </Tab>
                    <Tab px={1}>Snapshots</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel py={0}>
                      <RecentDatasetVersionsList openNewLayerId={openNewLayerId} />
                    </TabPanel>
                    <TabPanel py={0}>
                      <DatasetVersionsList
                        setPopoverLayerId={setPopoverLayerId}
                        popoverLayerId={popoverLayerId}
                        onSelectVersion={jumpToVersion}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      </Popover>
    </Flex>
  );
};

export default React.memo(NamedDatasetVersionsMenu);
