import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'GoogleAnalyticsIcon',
  viewBox: '0 0 60 60',
  path: (
    <g id="surface1">
      <path
        fill="rgb(97.647059%,67.058824%,0%)"
        d="M 40.625 9.0625 L 40.625 50.3125 C 40.625 54.929688 43.808594 57.5 47.1875 57.5 C 50.3125 57.5 53.75 55.3125 53.75 50.3125 L 53.75 9.375 C 53.75 5.144531 50.625 2.5 47.1875 2.5 C 43.75 2.5 40.625 5.414062 40.625 9.0625 Z M 40.625 9.0625 "
      />
      <path
        fill="rgb(89.019608%,45.490196%,0%)"
        d="M 23.4375 30 L 23.4375 50.3125 C 23.4375 54.929688 26.621094 57.5 30 57.5 C 33.125 57.5 36.5625 55.3125 36.5625 50.3125 L 36.5625 30.3125 C 36.5625 26.082031 33.4375 23.4375 30 23.4375 C 26.5625 23.4375 23.4375 26.351562 23.4375 30 Z M 23.4375 30 "
      />
      <path
        fill="rgb(89.019608%,45.490196%,0%)"
        d="M 19.375 50.9375 C 19.375 54.5625 16.4375 57.5 12.8125 57.5 C 9.1875 57.5 6.25 54.5625 6.25 50.9375 C 6.25 47.3125 9.1875 44.375 12.8125 44.375 C 16.4375 44.375 19.375 47.3125 19.375 50.9375 Z M 19.375 50.9375 "
      />
    </g>
  ),
});
