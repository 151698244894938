import { Box } from '@chakra-ui/react';
import { Group } from '@visx/group';
import noop from 'lodash/noop';
import { useMemo } from 'react';

import ActualsChartLine from 'components/DriverChart/ActualsChartLine';
import ChartLine from 'components/DriverChart/ChartLine';
import { DriverChartContext } from 'config/driverChart';
import useAppSelector from 'hooks/useAppSelector';
import useChartData from 'hooks/useChartData';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { resolvedDriverFormatSelector } from 'selectors/resolvedEntityFormatSelector';

const DEFAULT_MARGINS = {
  top: 1,
  bottom: 1,
  left: 0,
  right: 0,
};

interface Props {
  driverId: DriverId;
  layerId?: LayerId;
  width: number;
  height: number;
  margins?: { top: number; bottom: number; left: number; right: number };
}

const DriverSparkline = ({
  driverId,
  layerId,
  width,
  height,
  margins = DEFAULT_MARGINS,
}: Props) => {
  const xMax = width - margins.left - margins.right;
  const yMax = height - margins.top - margins.bottom;
  const format = useAppSelector((state) => resolvedDriverFormatSelector(state, driverId));

  const {
    timeScale,
    valueScale,
    forecastLineData,
    startDateTime,
    endDateTime,
    monthKeysWithForecastData,
    baselineLayerId,
  } = useChartData({
    driverId,
    layerId,
    format,
    width: xMax,
    height: yMax,
    useMilestone: false,
    addHeadroom: false,
  });

  const chartContext = useMemo(
    () => ({
      height: yMax,
      width: xMax,
      timeScale,
      valueScale,
      driverIds: [driverId],
      allDriverIds: [driverId],
      toggleDriver: (_driverId: DriverId) => noop(),
      format,
      size: 'sparkline' as const,
      startDateTime,
      endDateTime,
      monthKeysWithForecastData,
      baselineLayerId,
      driverIdsToColors: {},
      fullAggregateChartData: {
        positiveValues: [],
        negativeValues: [],
      },
    }),
    [
      yMax,
      xMax,
      timeScale,
      valueScale,
      driverId,
      format,
      startDateTime,
      endDateTime,
      monthKeysWithForecastData,
      baselineLayerId,
    ],
  );

  return (
    <DriverChartContext.Provider value={chartContext}>
      <Box userSelect="none" position="relative" flex={0}>
        <svg width={width} height={height}>
          <Group top={margins.top} left={margins.left}>
            <ActualsChartLine driverId={driverId} layerId={layerId} />
            <ChartLine data={forecastLineData} type="forecast" />
          </Group>
        </svg>
      </Box>
    </DriverChartContext.Provider>
  );
};

export default DriverSparkline;
