import Tippy from '@tippyjs/react';
import React, { RefObject, useRef } from 'react';

import BlockContext from 'components/BlockContainer/BlockContext';
import CellPalette from 'components/CellPalette/CellPalette';
import {
  FLIP_POPPER_OPTIONS,
  OFFSET_BY_POPOVER_TYPE,
  PLACEMENT_BY_POPOVER_TYPE,
} from 'components/CellPopover/CellPopover';
import PlanPicker from 'components/PlanPicker/PlanPicker';
import theme from 'config/theme';
import useAppSelector from 'hooks/useAppSelector';
import { isPlanPickerOpenSelector } from 'selectors/cellPaletteSelector';
import { pageGutterWidthInPxSelector } from 'selectors/pageSelector';
import { prevailingCellSelectionBlockIdSelector } from 'selectors/prevailingCellSelectionSelector';

export const CELL_PALETTE_POPOVER_CLASS_NAME = 'cell-palette-popover';

const CellPaletteWithPlanPicker: React.FC<{
  tableCellRef: RefObject<HTMLDivElement>;
  omitColorPicker?: boolean;
  isEditingActiveCell: boolean;
  disableMultiImpactPicker?: boolean;
}> = ({
  tableCellRef,
  omitColorPicker = false,
  isEditingActiveCell,
  disableMultiImpactPicker = false,
}) => {
  const isPlanPickerOpen = useAppSelector(isPlanPickerOpenSelector);
  const blockId = useAppSelector(prevailingCellSelectionBlockIdSelector);
  const gutterWidthInPx = useAppSelector(pageGutterWidthInPxSelector);

  const cellPaletteRef = useRef<HTMLDivElement>(null);
  const planPickerRef = useRef<HTMLDivElement>(null);

  if (blockId == null) {
    return null;
  }

  return (
    <BlockContext blockId={blockId} gutterWidthInPx={gutterWidthInPx}>
      <>
        <Tippy
          interactive
          placement={PLACEMENT_BY_POPOVER_TYPE.cellPalette}
          offset={OFFSET_BY_POPOVER_TYPE.cellPalette}
          appendTo={document.body}
          visible
          reference={tableCellRef}
          popperOptions={FLIP_POPPER_OPTIONS}
          zIndex={theme.zIndices.popover}
          render={() => (
            <CellPalette
              omitColorPicker={omitColorPicker}
              ref={cellPaletteRef}
              className={CELL_PALETTE_POPOVER_CLASS_NAME}
            />
          )}
        />
        <Tippy
          interactive
          placement="bottom-start"
          offset={OFFSET_BY_POPOVER_TYPE.cellPalette}
          appendTo={document.body}
          visible
          reference={tableCellRef}
          zIndex={theme.zIndices.popover}
          render={() =>
            isPlanPickerOpen ? (
              <PlanPicker
                ref={planPickerRef}
                isEditingActiveCell={isEditingActiveCell}
                className={CELL_PALETTE_POPOVER_CLASS_NAME}
                disableMultiImpactPicker={disableMultiImpactPicker}
              />
            ) : null
          }
        />
      </>
    </BlockContext>
  );
};

export default React.memo(CellPaletteWithPlanPicker);
