import { useCallback, useMemo } from 'react';

import { CURRENCY_MENU_WIDTH_PX } from 'components/CurrencyMenu/CurrencySelectMenu';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { DRIVER_FORMAT_ICONS, DRIVER_FORMAT_NAMES } from 'config/drivers';
import { DriverFormat } from 'generated/graphql';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateSelectedDriverFormats } from 'reduxStore/actions/driverMutations';
import DollarSignIcon from 'vectors/DollarSign';

const SECTION: Section[] = [
  {
    id: 'format-submenu',
    name: 'Format',
  },
];

export const useDriverFormatSelectMenuItem = (format: DriverFormat): SelectItem => {
  const item: SelectItem = useMemo(
    () => ({
      id: 'format',
      icon: <DollarSignIcon />,
      meta: DRIVER_FORMAT_NAMES[format],
      name: 'Format',
      sectionId: 'format-options',
      submenu: () => <FormatSelectMenu format={format} />,
    }),
    [format],
  );

  return item;
};

const FormatSelectMenu: React.FC<{
  format: DriverFormat;
  onClose?: () => void;
}> = ({ format, onClose }) => {
  const dispatch = useAppDispatch();

  const items: SelectItem[] = Object.entries(DRIVER_FORMAT_NAMES).map<SelectItem>(([f, name]) => ({
    id: f,
    icon: DRIVER_FORMAT_ICONS[f as DriverFormat],
    name,
    isChecked: f === format,
    sectionId: 'format-submenu',
  }));

  const onSelectFormat = useCallback(
    (item: SelectItem) => {
      dispatch(updateSelectedDriverFormats({ format: item.id as DriverFormat }));
      onClose?.();
    },
    [dispatch, onClose],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu
        items={items}
        sections={SECTION}
        width={CURRENCY_MENU_WIDTH_PX}
        onSelect={onSelectFormat}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};
