import noop from 'lodash/noop';
import { createContext } from 'react';

import { CellRef, CellSelectionState } from 'config/cells';
import { BlockId } from 'reduxStore/models/blocks';

export type OpaqueCellId = string;
export type DerivedCellId = string;

export type CellSubscriber = {
  blockId: BlockId;
  // an opaque identifier tied to a specific cell component
  opaqueCellId: OpaqueCellId;
  // a derived identifier from the cell's unique block/row key/column key combination
  derivedCellId: DerivedCellId;
  cellRef: CellRef;
  columnKeyHash: string;
  rowKeyHash: string;
  setSelectionState: React.Dispatch<React.SetStateAction<CellSelectionState>>;
};

export type CellSelectionManagerState = {
  subscribe: (
    opaqueCellId: string,
    blockId: BlockId,
    ref: CellRef,
    setSelectionState: React.Dispatch<React.SetStateAction<CellSelectionState>>,
  ) => void;
  unsubscribe: (opaqueCellId: string) => void;
  setIsDragging: (isDragging: boolean) => void;
  subscribersByOpaqueCellId: Record<DerivedCellId, CellSubscriber>;
};

export const CellSelectionManagerContext = createContext<CellSelectionManagerState>({
  subscribe: noop,
  unsubscribe: noop,
  setIsDragging: noop,
  subscribersByOpaqueCellId: {},
});
