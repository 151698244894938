import { ContentState } from 'draft-js';
import { isEmpty } from 'lodash';

import { ExtSource } from 'helpers/integrations';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { Attribute, DimensionId } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { ExtDriverId, ExtDriverSource } from 'reduxStore/models/extDrivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { MonthKey, RelativeDate } from 'types/datetime';
import { FilterItem } from 'types/filtering';

export type RawFormula = string;
export type RawTimeInput = string;

export enum MathOperator {
  Sum = 'SUM',
  Avg = 'AVG',
  Min = 'MIN',
  Max = 'MAX',
  Count = 'COUNT',
  Round = 'ROUND',
  RoundDown = 'ROUNDDOWN',
  SumProduct = 'SUMPRODUCT',
  EndOfMonth = 'ENDOFMONTH',
  StartOfMonth = 'STARTOFMONTH',
  AsDays = 'ASDAYS',
  AsWeeks = 'ASWEEKS',
  AsMonths = 'ASMONTHS',
  AsYears = 'ASYEARS',
  ThisMonth = 'THISMONTH',
  If = 'IF',
  IfError = 'IFERROR',
  Coalesce = 'COALESCE',
  DaysInMonth = 'DAYSINMONTH',
  DateDiff = 'DATEDIFF',
  NetWorkDays = 'NETWORKDAYS',
  And = 'AND',
  Or = 'OR',
  Equals = '==',
  NotEquals = '!=',
  Greater = '>',
  Less = '<',
  GreaterOrEqual = '>=',
  LessOrEqual = '<=',
  Subtract = '-',
  Add = '+',
  Multiply = '*',
  Divide = '/',
  Pow = '^',
  LeftParen = '(',
  RightParen = ')',
  Null = 'NULL',
}

export enum FormulaBooleanOperator {
  Equals = '==',
  NotEquals = '!=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqualTo = '>=',
  LessThanOrEqualTo = '<=',
}

/**
 * N.B. these should correspond to operators defined by our ANTLR grammar `Calculator.g4`.
 * These types help transition from the raw formula to our UI representation, and vice
 * versa.
 */
export enum FormulaTimeSeriesOperator {
  Sum = 'sum',
  Average = 'avg',
  SumProduct = 'sumproduct',
  Min = 'min',
  Max = 'max',
}

export type FormulaTimeRange =
  | {
      type: 'range';
      start: DateReference;
      end: DateReference;
    }
  | {
      type: 'relative';
      start: number;
      end: number;
    }
  | {
      type: 'relativeVariable';
      start: DriverId;
      end: DriverId;
    };

export type DateReference =
  | {
      type: 'absolute';
      val: MonthKey;
    }
  | { type: 'relative'; val: RelativeDate };

export enum EntityType {
  Driver = 'REF',
  Submodel = 'SUBMODEL',
  ExtDriver = 'EXT_DRIVER',
  ObjectSpec = 'OBJECT_SPEC',
  ExtQuery = 'EXT_QUERY',
  AtomicNumber = 'ATOMIC_NUMBER',
}

export type EntityDataWithKey = EntityData & { key: string };

interface ExpressionRefMetadata {
  label: string;
  dateRange?: FormulaTimeRange;
  dateRangeDisplay?: string;
  operator?: FormulaTimeSeriesOperator;
  error?: string;
}

export const ANY_ATTR = 'ANY';
export const NO_ATTR = 'NULL';
export const CONTEXT_ATTR = 'CONTEXT_ATTR';
export const COHORT_MONTH = 'COHORTMONTH';
export type DynamicAttributeFilterOption = typeof ANY_ATTR | typeof NO_ATTR | typeof CONTEXT_ATTR;
type SpecialFilters = {
  includeAllContextAttributes?: boolean;
  matchToSingleResult?: boolean;
};
export type AttributeFilters = SpecialFilters & {
  byDimId: Record<DimensionId, Array<Attribute | DynamicAttributeFilterOption>>;
};

export interface DriverRefMetadata extends ExpressionRefMetadata {
  id: DriverId;
  attributeFilters?: AttributeFilters;
  placeholder?: string;
}

export interface SubmodelRefMetadata extends ExpressionRefMetadata {
  id: SubmodelId;
  driverGroupFilter?: DriverGroupId;
}

export interface ExtDriverRefMetadata extends ExpressionRefMetadata {
  id: ExtDriverId;
  source?: ExtDriverSource;
}

export type ObjectSpecFilters = SpecialFilters & {
  propertyFilters: FilterItem[];
};

export interface ObjectSpecRefMetadata extends ExpressionRefMetadata {
  id: BusinessObjectSpecId;
  fieldId?: BusinessObjectFieldSpecId;
  filters?: ObjectSpecFilters;
  isThisRef?: boolean;
}

export interface ExtQueryRefMetadata extends ExpressionRefMetadata {
  id: string;
  label: string;
  source?: ExtSource;
  attributeFilters?: AttributeFilters;
}

export type AtomicNumberIcon = 'formula';
export interface AtomicNumberMetadata extends ExpressionRefMetadata {
  tooltip?: string;
  icon?: AtomicNumberIcon;
}

export type DriverEntityData = {
  type: EntityType.Driver;
  data: DriverRefMetadata;
};

export type SubmodelEntityData = {
  type: EntityType.Submodel;
  data: SubmodelRefMetadata;
};

export type ExtDriverEntityData = {
  type: EntityType.ExtDriver;
  data: ExtDriverRefMetadata;
};

export type ObjectSpecEntityData = {
  type: EntityType.ObjectSpec;
  data: ObjectSpecRefMetadata;
};

export type ExtQueryEntityData = {
  type: EntityType.ExtQuery;
  data: ExtQueryRefMetadata;
};

export type AtomicNumberEntityData = {
  type: EntityType.AtomicNumber;
  data: AtomicNumberMetadata;
};

export type DraftEntity = {
  key: string;
  range: [start: number, end: number];
} & EntityData;

export type EntityData =
  | DriverEntityData
  | SubmodelEntityData
  | ExtDriverEntityData
  | ObjectSpecEntityData
  | ExtQueryEntityData
  | AtomicNumberEntityData;

// see https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecorator.js
/**
 * DraftDecoratorComponentProps are the core set of props that will be
 * passed to all DraftDecoratorComponents if a Custom Block Component is not used.
 * Note that a component may also accept additional props outside of this list.
 */
export type DraftDecoratorComponentProps = {
  blockKey: string;
  children?: React.ReactNode[];
  contentState: ContentState;
  decoratedText: string;
  end: number;
  entityKey?: string;
  offsetKey: string;
  start: number;
};

export type FormulaUpdate = {
  driverId: DriverId;
  type: 'actuals' | 'forecast';
  formula: RawFormula;
};

export const getEmptyAttributeFilters = (): AttributeFilters => ({
  byDimId: {},
});

export const isAttributeFiltersEmpty = (filters: AttributeFilters | undefined) =>
  filters == null ||
  (!filters.includeAllContextAttributes &&
    !filters.matchToSingleResult &&
    (isEmpty(filters.byDimId) || Object.values(filters.byDimId).every(isEmpty)));

export const getEmptyObjectSpecFilters = (): ObjectSpecFilters => ({
  propertyFilters: [],
});

// Making this generic so that it can be used for both ObjectSpecRefMetadata and ObjectFormulaDisplayChunk
export const isObjectSpecFiltersEmpty = <T>(
  filters:
    | {
        propertyFilters: T[];
        includeAllContextAttributes?: boolean;
        matchToSingleResult?: boolean;
      }
    | undefined,
): filters is
  | undefined
  | {
      propertyFilters: [];
    } =>
  filters == null ||
  (filters.propertyFilters.length === 0 &&
    !filters.includeAllContextAttributes &&
    !filters.matchToSingleResult);
