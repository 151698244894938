import { Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { CURRENCY_CONFIGS } from 'config/currency';

export const CURRENCY_MENU_WIDTH_PX = 180;

const CurrencySelectMenu: React.FC<{
  onSelectCurrency: (currency: string) => void;
  selected: string;
  onClose?: () => void;
}> = ({ onSelectCurrency, selected, onClose }) => {
  const items: SelectItem[] = useMemo(
    () =>
      CURRENCY_CONFIGS.map((currency) => ({
        id: currency.isoCode,
        name: currency.isoCode,
        isChecked: currency.isoCode === selected,
        meta: currency.symbol,
        footer: (
          <Flex flexDir="row" columnGap={1}>
            <Text fontWeight="semibold">{currency.isoCode}</Text>
            <Text fontWeight="medium">{currency.name}</Text>
          </Flex>
        ),
      })),
    [selected],
  );

  return (
    <SelectMenuContainer testId="currency-menu-dropdown">
      <SelectMenu
        width={`${CURRENCY_MENU_WIDTH_PX}px`}
        items={items}
        onSelect={(item) => {
          onSelectCurrency(item.id);
          onClose?.();
        }}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default CurrencySelectMenu;
