import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';

import { INSPECTOR_MIN_CHART_HEIGHT } from 'config/driverChart';
import {
  INSPECTOR_HEADER_HEIGHT,
  MAX_EVENTS_TO_RENDER_IN_PLAN,
  PLAN_TIMELINE_ROW_HEIGHT,
} from 'config/planTimeline';
import { isNotNull } from 'helpers/typescript';
import { Event, EventId, PlanTimelineItemRef, PopulatedEventGroup } from 'reduxStore/models/events';
import { PlanTimelineEntity, PlanTimelineRow } from 'selectors/planTimelineSelector';
import { ISOTime } from 'types/datetime';

export function getChartHeight(inspectorHeight: number): number {
  // 16px for horizontal scrollbar, 8px for the resizer, 16px for padding
  return Math.max(
    INSPECTOR_MIN_CHART_HEIGHT,
    inspectorHeight - PLAN_TIMELINE_ROW_HEIGHT * 3 - INSPECTOR_HEADER_HEIGHT - 32,
  );
}

export type PlanTimelineRowRef = PlanTimelineItemRef & {
  depth: number;
  sortImpact?: number;
  events?: Event[];
  eventGroups?: PopulatedEventGroup[];
};

export type SortRoadmapRefFn = (ref: PlanTimelineRowRef) => number | undefined;

const filterByDateRange = (start: ISOTime, end: ISOTime, dateRange: [DateTime, DateTime]) => {
  const [startDateTime, endDateTime] = dateRange;
  const itemStart = DateTime.fromISO(start);
  const itemEnd = DateTime.fromISO(end);

  return (
    Math.max(itemStart.toMillis(), startDateTime.toMillis()) <
    Math.min(itemEnd.toMillis(), endDateTime.toMillis())
  );
};

const traverseEventGroup = (eventGroup: PopulatedEventGroup, dateRange: [DateTime, DateTime]) => {
  if (eventGroup.events.length === 0 && eventGroup.eventGroups.length === 0) {
    const showEmptyGroup =
      (eventGroup.defaultEnd != null &&
        eventGroup.defaultStart != null &&
        filterByDateRange(eventGroup.defaultStart, eventGroup.defaultEnd, dateRange)) ||
      (eventGroup.defaultEnd == null && eventGroup.defaultStart == null);
    return showEmptyGroup ? eventGroup : null;
  }

  const events = eventGroup.events.filter((ev: Event) =>
    filterByDateRange(ev.start, ev.end, dateRange),
  );
  const eventGroups: PopulatedEventGroup[] = eventGroup.eventGroups
    .map((eGroup) => traverseEventGroup(eGroup, dateRange))
    .filter(isNotNull);

  return events.length !== 0 || eventGroups.length !== 0
    ? { ...eventGroup, events, eventGroups }
    : null;
};

export const getPlanTimelineRowRefs = ({
  rootEventsAndEventGroups,
  order,
  shouldTraverseRowChildren,
  blockDateRangeDateTime,
}: {
  rootEventsAndEventGroups: {
    rootEventGroups: PopulatedEventGroup[];
    rootEvents: Event[];
  };
  order: { sortFns: SortRoadmapRefFn[]; sortDirections: Array<'asc' | 'desc'> };
  shouldTraverseRowChildren?: (rowRef: PlanTimelineRowRef) => boolean;
  blockDateRangeDateTime?: [DateTime, DateTime];
}) => {
  let { rootEventGroups, rootEvents } = rootEventsAndEventGroups;

  if (blockDateRangeDateTime != null) {
    rootEventGroups = rootEventGroups
      .map((rootEventGroup) => traverseEventGroup(rootEventGroup, blockDateRangeDateTime))
      .filter(isNotNull);
    rootEvents = rootEvents.filter((ev) =>
      filterByDateRange(ev.start, ev.end, blockDateRangeDateTime),
    );
  }

  const { sortFns, sortDirections } = order;
  const allRowRefs: PlanTimelineRowRef[] = [];
  const sortedRootEventsAndGroups = orderBy(
    [...getEventRefs(rootEvents, 0), ...getEventGroupRefs(rootEventGroups, 0)],
    sortFns,
    sortDirections,
  );
  const stack: PlanTimelineRowRef[] = sortedRootEventsAndGroups;

  function walk(rowRef: PlanTimelineRowRef) {
    allRowRefs.push(rowRef);
    const { type, depth, eventGroups, events } = rowRef;
    const children =
      type === 'group'
        ? orderBy(
            [
              ...getEventGroupRefs(eventGroups ?? [], depth + 1),
              ...getEventRefs(events ?? [], depth + 1).slice(0, MAX_EVENTS_TO_RENDER_IN_PLAN),
            ],
            sortFns,
            sortDirections,
          )
        : [];
    if (shouldTraverseRowChildren != null && !shouldTraverseRowChildren(rowRef)) {
      return;
    }
    stack.unshift(...children);
  }

  while (stack.length > 0) {
    const curr = stack.shift();
    if (!curr) {
      break;
    }

    walk(curr);
  }

  return allRowRefs;
};

function getEventRef(event: Event, depth: number): PlanTimelineRowRef {
  return { type: 'event', id: event.id, depth };
}

function getEventGroupRef(group: PopulatedEventGroup, depth: number): PlanTimelineRowRef {
  const { id, events, eventGroups } = group;
  return { type: 'group', id, depth, eventGroups, events };
}

function getEventRefs(events: Event[], depth: number): PlanTimelineRowRef[] {
  return events.map((e) => getEventRef(e, depth));
}

function getEventGroupRefs(groups: PopulatedEventGroup[], depth: number): PlanTimelineRowRef[] {
  return groups.map((g) => getEventGroupRef(g, depth));
}

export function isPlanTimelineEntity(row: PlanTimelineRow): row is PlanTimelineEntity {
  return row.type === 'driver' || row.type === 'objectField';
}

export function findPlanTimelineRowWithEvent(
  rows: PlanTimelineRow[],
  eventId: EventId,
): PlanTimelineRow | null {
  const rowWithEvent = rows.find(
    (row) => isPlanTimelineEntity(row) && row.events.some((e) => e.id === eventId),
  );

  return rowWithEvent ?? null;
}
