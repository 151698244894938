import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import pluralize from 'pluralize';
import React, { useCallback, useContext, useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { SelectMenuContext } from 'components/SelectMenu/SelectMenuContext';
import SelectMenuFooter from 'components/SelectMenu/SelectMenuFooter';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { getSubmodelIdFromInternalPageType } from 'config/internalPages/modelPage';
import { extractEmoji } from 'helpers/emoji';
import { isNotNull } from 'helpers/typescript';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import {
  removeSelectedDriversSubmodel,
  updateSelectedDriversSubmodel,
} from 'reduxStore/actions/driverReferenceMutations';
import { numSelectedDriversSelector } from 'selectors/prevailingCellSelectionSelector';
import { orderedSubmodelPagesSelector } from 'selectors/submodelPageSelector';
import EmptyEmoji from 'vectors/EmptyEmoji';

// Note: we can probably generalize this component if there are other places we need a submodel select menu.
const MoveToSubmodelMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();

  const capabilitesProvider = useAccessCapabilities();
  const submodelPages = useAppSelector((state) =>
    orderedSubmodelPagesSelector(state).filter((page) => capabilitesProvider.canReadPage(page.id)),
  );
  const numSelectedDrivers = useAppSelector((state) => numSelectedDriversSelector(state));

  const driverCountPhrase = useMemo(
    () => `${numSelectedDrivers} ${pluralize('driver', numSelectedDrivers)}`,
    [numSelectedDrivers],
  );

  const submodelItems = useMemo<SelectItem[]>(() => {
    const items = submodelPages
      .map((sp) => {
        if (sp.internalPageType == null) {
          return null;
        }
        const [emoji, name] = extractEmoji(sp.name);
        const submodelId = getSubmodelIdFromInternalPageType(sp.internalPageType);
        return {
          id: submodelId,
          key: submodelId,
          icon:
            emoji != null ? (
              <Text fontFamily="AppleColorEmoji, system-ui">{emoji}</Text>
            ) : (
              <EmptyEmoji />
            ),
          name,
          footer: (
            <SelectMenuFooter
              icon={<Text> {emoji ?? <EmptyEmoji />} </Text>}
              title={name}
              subtitle={
                <Flex flexDir="column">
                  <Text>
                    Move {driverCountPhrase} to {name}.
                  </Text>
                </Flex>
              }
            />
          ),
        };
      })
      .filter(isNotNull);
    items.push({
      id: 'none',
      key: 'none',
      icon: <CloseIcon w="10px" />,
      name: 'None',
      footer: (
        <SelectMenuFooter
          icon={<CloseIcon />}
          title="Remove"
          subtitle={
            <Flex flexDir="column">
              <Text> Remove {driverCountPhrase} from table </Text>
            </Flex>
          }
        />
      ),
    });
    return items;
  }, [driverCountPhrase, submodelPages]);

  const { closeParentSelectMenu } = useContext(SelectMenuContext);
  const onSelect = useCallback(
    (item: SelectItem) => {
      if (item.id === 'none') {
        dispatch(removeSelectedDriversSubmodel({ sourceBlockId: blockId }));
      } else {
        dispatch(updateSelectedDriversSubmodel({ submodelId: item.id, sourceBlockId: blockId }));
      }

      closeParentSelectMenu?.();
    },
    [blockId, closeParentSelectMenu, dispatch],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu items={submodelItems} onSelect={onSelect} width="20rem">
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default React.memo(MoveToSubmodelMenu);
