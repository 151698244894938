import { deepEqual } from 'fast-equals';
import React, { useMemo } from 'react';

import ChartLine, { DATA_TYPE_TO_COLOR } from 'components/DriverChart/ChartLine';
import { DriverChartDatum } from 'config/driverChart';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartActualsData from 'hooks/useChartActualsData';
import useChartContext from 'hooks/useChartContext';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import {
  driverTimeSeriesForLayerSelector,
  driverTimeSeriesSourceByMonthKeySelector,
} from 'selectors/driverTimeSeriesSelector';
import { comparisonLayerColorByIdForBlockSelector } from 'selectors/rollupSelector';

type ActualsLine = { data: DriverChartDatum[]; source: 'actuals' | 'forecast' };

interface Props {
  driverId: DriverId;
  layerId?: LayerId;
}

const ActualsChartLine: React.FC<Props> = ({ driverId, layerId }) => {
  const { startDateTime, endDateTime, driverIdsToColors, baselineLayerId, format } =
    useChartContext();
  const { blockId } = useBlockContext();
  const driverTimeSeriesSourceByMonthKey = useAppSelector(
    (state) => driverTimeSeriesSourceByMonthKeySelector(state, { id: driverId }),
    deepEqual,
  );

  const ts = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: driverId, layerId: layerId ?? baselineLayerId }),
  );

  const comparisonLineColor = useAppSelector(
    (state) => comparisonLayerColorByIdForBlockSelector(state, blockId)[layerId ?? baselineLayerId],
  );

  const data = useChartActualsData(startDateTime, ts, format, endDateTime);

  const lines = useMemo(() => {
    const groups: ActualsLine[] = [];
    let currGroup: ActualsLine | undefined;
    let lastSource: ActualsLine['source'] | undefined;

    data.forEach((datum) => {
      const source = driverTimeSeriesSourceByMonthKey[datum.monthKey];
      if (source !== lastSource && source != null) {
        const newGroup = { data: [datum], source };
        groups.push(newGroup);
        lastSource = source;
        if (currGroup != null) {
          currGroup.data.push(datum);
        }
        currGroup = newGroup;
      } else if (source != null && currGroup != null) {
        currGroup.data.push(datum);
      }
    });

    return groups;
  }, [data, driverTimeSeriesSourceByMonthKey]);

  return (
    <>
      {lines.map((line, idx) => {
        let lineColor: string;

        if (comparisonLineColor != null) {
          lineColor = comparisonLineColor;
        } else if (line.source === 'actuals' || line.source === 'forecast') {
          lineColor = driverIdsToColors?.[driverId];
        } else {
          lineColor = DATA_TYPE_TO_COLOR[line.source];
        }

        return <ChartLine key={idx} data={line.data} type={line.source} color={lineColor} />;
      })}
    </>
  );
};

export default ActualsChartLine;
