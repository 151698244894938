import { Box, Flex, StyleProps, Text } from '@chakra-ui/react';
import { createSelector } from '@reduxjs/toolkit';
import { groupBy, sortBy } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import { useComparedLayers } from '@features/CompareScenarios';
import { Circle } from '@features/CompareScenarios/blocks/common/Circle';
import { ToggleSection } from '@features/CompareScenarios/blocks/common/ToggleSection';
import { BusinessObjectChange } from '@features/CompareScenarios/comparators/DatabaseComparator';
import { ChangeStatus } from '@features/CompareScenarios/compareLayers';
import AttributeBadge from 'components/AttributeBadge/AttributeBadge';
import ObjectFieldsHeaderRow from 'components/CompareScenariosModalContent/ObjectFieldsHeaderRow';
import ObjectFieldTimeSeriesRows from 'components/ObjectFieldTimeSeriesRows/ObjectFieldTimeSeriesRows';
import VariableSizeVirtualizedVerticalList from 'components/VirtualizedList/VariableSizeVirtualizedVerticalList';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import useAppSelector from 'hooks/useAppSelector';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { Attribute } from 'reduxStore/models/dimensions';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';

const STATUS_TO_SECTION_COLOR: Record<ChangeStatus, StyleProps['backgroundColor']> = {
  created: 'green.500',
  updated: 'yellow.500',
  deleted: 'red.500',
};

export const RowChangesByStatusBlock = ({ changes }: { changes: BusinessObjectChange[] }) => {
  const rowChangesByStatus = useRowChangesByStatus(changes);

  return (
    <ToggleSection
      heading="Row updates"
      fontSize="sm"
      isDefaultOpen
      sectionProps={{ paddingLeft: 6 }}
    >
      <Flex flexDirection="column" rowGap={2} pl={1}>
        {rowChangesByStatus.map((change) => {
          return (
            <RowChangesBlock
              key={`compare_scenarios_modal-databases-${change.status}`}
              change={change}
            />
          );
        })}
      </Flex>
    </ToggleSection>
  );
};

const STATUS_TO_ORDER: Record<ChangeStatus, number> = {
  created: 1,
  deleted: 2,
  updated: 3,
};

export const MAX_BUSINESS_OBJECTS = 20;

function useRowChangesByStatus(changes: BusinessObjectChange[]): RowChangeForStatus[] {
  return useMemo(() => {
    const rowChangesByStatusMap = groupBy(changes, ({ status }) => status);

    return sortBy(
      Object.entries(rowChangesByStatusMap).map(([status, rowChanges]) => {
        return {
          status: status as ChangeStatus,
          count: rowChanges.length,
          changes: rowChanges.slice(0, MAX_BUSINESS_OBJECTS),
        };
      }),
      ({ status }) => STATUS_TO_ORDER[status],
    );
  }, [changes]);
}

type RowChangeForStatus = {
  status: ChangeStatus;
  count: number;
  changes: BusinessObjectChange[];
};

const RowChangesBlock = ({ change }: { change: RowChangeForStatus }) => {
  const { currentLayer, mergeLayer } = useComparedLayers();
  const comparedLayerIds = useMemo(
    () => ({ currentLayerId: currentLayer.id, mergeLayerId: mergeLayer.id }),
    [currentLayer.id, mergeLayer.id],
  );
  const dimensionalPropertyEvaluators = useAppSelector((state) => {
    return dimensionalEvaluatorsSelector(state, comparedLayerIds);
  });

  return (
    <Box marginBottom="2" width="fit-content">
      <Box>
        <Flex py={1} alignItems="center" borderBottom="1px solid" borderBottomColor="gray.300">
          <Circle color={STATUS_TO_SECTION_COLOR[change.status]} size="sm" mr="2" />
          <Text fontSize="xs" fontWeight="500">
            {change.count} {change.status}
          </Text>
        </Flex>
        <Box>
          {change.changes.map((rowChange) => {
            return (
              <RowChangeBlock
                key={`compare_scenarios_modal-businessObject-${rowChange.object.id}`}
                change={rowChange}
                dimensionalEvaluators={dimensionalPropertyEvaluators}
              />
            );
          })}
          {change.changes.length < change.count && (
            <Box paddingY="1">
              <Text fontSize="xs" fontWeight="medium" color="gray.500">
                {change.count - change.changes.length} more{' '}
                {pluralize('row', change.count - change.changes.length)} hidden
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const dimensionalEvaluatorsSelector = createSelector(
  [
    (state: RootState, _) => state,
    (_: RootState, comparedLayers: { currentLayerId: LayerId; mergeLayerId: LayerId }) => {
      return comparedLayers;
    },
  ],
  (state, { currentLayerId, mergeLayerId }) => {
    return {
      currentLayer: dimensionalPropertyEvaluatorSelector(state, {
        layerId: currentLayerId,
      }),
      mergeLayer: dimensionalPropertyEvaluatorSelector(state, {
        layerId: mergeLayerId,
      }),
    };
  },
);

const RowChangeBlock = ({
  change,
  dimensionalEvaluators,
}: {
  change: BusinessObjectChange;
  dimensionalEvaluators: {
    currentLayer: DimensionalPropertyEvaluator;
    mergeLayer: DimensionalPropertyEvaluator;
  };
}) => {
  const { currentLayer: currentAttributes, mergeLayer: mergeAttributes } = useRowAttributes(
    change.object.id,
    change.status ?? 'created',
    dimensionalEvaluators,
  );

  const isNameOrAttributesUpdate =
    change.status === 'updated' &&
    (change.updatesByField.name != null || change.updatesByField.attributes != null);
  return (
    <Flex
      paddingY="1"
      direction="column"
      gap="2px"
      fontSize="xs"
      _notLast={{
        borderBottom: '1px dashed',
        borderColor: 'gray.300',
      }}
    >
      <Flex alignItems="center" gap="2">
        {isNameOrAttributesUpdate && (
          <Flex gap="1" alignItems="center">
            <Text
              fontWeight="medium"
              fontSize="xs"
              color="gray.500"
              textDecoration={change.updatesByField.name != null ? 'line-through' : undefined}
              maxWidth="140px"
              overflow="ellipsis"
            >
              {change.updatesByField.name?.from ?? change.object.name}
            </Text>
            {change.updatesByField.attributes != null && (
              <AttributesRow
                id={change.object.id}
                status={change.status ?? 'updated'}
                type="merge"
                attributes={mergeAttributes}
              />
            )}
            <Text color="gray.600" px={1}>
              →
            </Text>
            <Text fontWeight="medium" fontSize="xs">
              {change.updatesByField.name?.to ?? change.object.name}
            </Text>
            <AttributesRow
              id={change.object.id}
              status={change.status ?? 'updated'}
              type="current"
              attributes={currentAttributes}
            />
          </Flex>
        )}
        {change.status === 'deleted' && (
          <>
            <Text
              fontWeight="medium"
              fontSize="xs"
              color="gray.500"
              textDecoration="line-through"
              maxWidth="140px"
              overflow="ellipsis"
            >
              {change.object.name}
            </Text>
            <AttributesRow
              id={change.object.id}
              status={change.status}
              type="merge"
              attributes={mergeAttributes}
            />
          </>
        )}
        {(change.status === 'updated' || change.status === 'created') &&
          !isNameOrAttributesUpdate && (
            <>
              <Text fontSize="xs" color="gray.600" fontWeight="medium">
                {change.object.name}
              </Text>
              <AttributesRow
                id={change.object.id}
                status={change.status ?? 'updated'}
                type="current"
                attributes={currentAttributes}
              />
            </>
          )}
      </Flex>
      {change.updatesByField.fields != null && (
        <ToggleSection
          heading={
            <Text fontSize="xs" color="gray.600" fontWeight="medium">
              Field Changes
            </Text>
          }
          fontSize="xs"
          isNotRenderedUntilOpen
          sectionProps={{ marginBottom: 1, overflowX: 'scroll', paddingLeft: 6 }}
        >
          <Flex overflowX="scroll" maxWidth="78rem" flexDirection="column">
            <ObjectFieldsHeaderRow />
            <VariableSizeVirtualizedVerticalList estimatedItemHeight={100}>
              {change.updatesByField.fields.changes.map((fieldChange, idx) => {
                const { field, isStartField } = fieldChange.object;
                return (
                  <ObjectFieldTimeSeriesRows
                    key={`${change.object.id}/${field.fieldSpecId}`}
                    businessObjectId={change.object.id}
                    fieldSpecId={field.fieldSpecId}
                    isStartField={isStartField}
                    isFirstRow={idx === 0}
                    isLastRow={idx === (change.updatesByField.fields?.changes?.length ?? 0) - 1}
                  />
                );
              })}
            </VariableSizeVirtualizedVerticalList>
          </Flex>
        </ToggleSection>
      )}
    </Flex>
  );
};

const AttributesRow = ({
  id,
  status,
  type,
  attributes,
}: {
  id: BusinessObjectId;
  status: ChangeStatus;
  type: 'current' | 'merge';
  attributes: Attribute[];
}) => {
  return (
    <Flex gap="1" alignItems="center" opacity={status === 'deleted' ? '0.5' : 1}>
      {attributes.map((attribute) => {
        return (
          <AttributeBadge
            key={`compare_scenarios_modal-businessObject-${type}-${id}-${attribute.id}`}
            attribute={attribute}
          />
        );
      })}
    </Flex>
  );
};

function useRowAttributes(
  id: BusinessObjectId,
  status: ChangeStatus,
  dimensionalEvaluators: {
    currentLayer: DimensionalPropertyEvaluator;
    mergeLayer: DimensionalPropertyEvaluator;
  },
): LayerizedAttributes {
  const attributes: LayerizedAttributes = { currentLayer: [], mergeLayer: [] };
  if (status === 'deleted') {
    attributes.mergeLayer = dimensionalEvaluators.mergeLayer
      .getKeyAttributePropertiesForBusinessObject(id)
      .map(({ attribute }) => attribute);
  } else if (status === 'created') {
    attributes.currentLayer = dimensionalEvaluators.currentLayer
      .getKeyAttributePropertiesForBusinessObject(id)
      .map(({ attribute }) => attribute);
  } else {
    attributes.mergeLayer = dimensionalEvaluators.mergeLayer
      .getKeyAttributePropertiesForBusinessObject(id)
      .map(({ attribute }) => attribute);
    attributes.currentLayer = dimensionalEvaluators.currentLayer
      .getKeyAttributePropertiesForBusinessObject(id)
      .map(({ attribute }) => attribute);
  }
  return attributes;
}

type LayerizedAttributes = { currentLayer: Attribute[]; mergeLayer: Attribute[] };
