import {
  AccessResourceType,
  AccessRule,
  AccessorEntityType,
  AddControlEntriesDocument,
  AddControlEntriesMutation,
  AddControlEntriesMutationVariables,
  OrgDeleteUserResourceInviteDocument,
  OrgDeleteUserResourceInviteMutation,
  OrgDeleteUserResourceInviteMutationVariables,
  OrgInviteUserToResourceDocument,
  OrgInviteUserToResourceMutation,
  OrgInviteUserToResourceMutationVariables,
  OrgInviteUserToRoleDocument,
  OrgInviteUserToRoleMutation,
  OrgInviteUserToRoleMutationVariables,
  OrgRole,
  RemoveControlEntriesDocument,
  RemoveControlEntriesMutation,
  RemoveControlEntriesMutationVariables,
} from 'generated/graphql';
import { enqueueRequest } from 'helpers/requests';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { setInvitedUsers } from 'reduxStore/reducers/selectedOrgSlice';
import { initialize } from 'reduxStore/reducers/sharingSlice';
import { AppThunk } from 'reduxStore/store';
import { sharedGuestAccessResourceForPageAndLayerSelector } from 'selectors/layerAccessResourcesSelector';
import { resourceHasAccessEntriesSelector } from 'selectors/restrictedResourcesSelector';
import { selectedOrgIdSelector } from 'selectors/selectedOrgSelector';
import { allAccessResourcesSelector } from 'selectors/sharingSelector';

export const GuestAccessor = {
  id: OrgRole.Guest,
  type: AccessorEntityType.UserGroup,
};

// TODO: We are currently using this to implement the anonymized data by adding
// a resource entry for the object field/name and assuming that if one exist,
// the data is anoymized. Instead, we should just mark it as anonymized.
export const toggleOrgRoleAccessEntry =
  (resourceId: string, resourceType: AccessResourceType, minRole: OrgRole): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const orgId = selectedOrgIdSelector(state);
    if (orgId == null) {
      return;
    }

    const hasRestrictions = resourceHasAccessEntriesSelector(state, resourceId);
    if (hasRestrictions) {
      dispatch(
        removeControlEntries({
          resourceId,
          resourceType,
          accessor: { type: AccessorEntityType.UserGroup, id: minRole },
        }),
      );
    } else {
      dispatch(
        setControlEntries({
          resourceId,
          resourceType,
          accessor: { type: AccessorEntityType.UserGroup, id: minRole },
          accessRule: AccessRule.Read,
        }),
      );
    }
  };

export const setControlEntries =
  (controlEntries: AddControlEntriesMutationVariables['controlEntries']): AppThunk =>
  (dispatch, getState, { urqlClient, requestQueue }) => {
    const state = getState();

    const orgId = selectedOrgIdSelector(state);
    if (orgId == null) {
      return;
    }

    enqueueRequest(requestQueue, () =>
      urqlClient
        .mutation<AddControlEntriesMutation, AddControlEntriesMutationVariables>(
          AddControlEntriesDocument,
          { controlEntries, orgId },
        )
        .toPromise()
        .then((result) => {
          if (result == null || result.data == null) {
            return;
          }
          dispatch(initialize(result.data.addControlEntries));
        }),
    );
  };

export const addDefaultLayerGuestControlEntryToPage =
  (pageId: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const accessResources = allAccessResourcesSelector(state);
    const parentResource = accessResources.find(
      (resource) => resource.resourceId === pageId && resource.type === AccessResourceType.Page,
    );
    const hasExistingResource =
      parentResource != null &&
      accessResources.some(
        (resource) =>
          resource.resourceId === DEFAULT_LAYER_ID &&
          resource.type === AccessResourceType.Layer &&
          resource.parentId === parentResource.id,
      );

    if (hasExistingResource) {
      return;
    }

    const controlEntry = {
      resourceId: DEFAULT_LAYER_ID,
      resourceParent: { resourceId: pageId, type: AccessResourceType.Page },
      resourceType: AccessResourceType.Layer,
      accessRule: AccessRule.Read,
      accessor: GuestAccessor,
    };
    dispatch(setControlEntries(controlEntry));
  };

export const removeControlEntries =
  (controlEntries: RemoveControlEntriesMutationVariables['controlEntries']): AppThunk =>
  (dispatch, getState, { urqlClient, requestQueue }) => {
    const state = getState();

    const orgId = selectedOrgIdSelector(state);
    if (orgId == null) {
      return;
    }

    enqueueRequest(requestQueue, () =>
      urqlClient
        .mutation<RemoveControlEntriesMutation, RemoveControlEntriesMutationVariables>(
          RemoveControlEntriesDocument,
          { controlEntries, orgId },
        )
        .toPromise()
        .then((result) => {
          if (result == null || result.data == null) {
            return;
          }
          dispatch(initialize(result.data.removeControlEntries));
        }),
    );
  };

export const removeDefaultLayerGuestControlEntryFromPage =
  (pageId: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const guestAccessEntry = sharedGuestAccessResourceForPageAndLayerSelector(state, {
      pageId,
      layerId: DEFAULT_LAYER_ID,
    });

    if (guestAccessEntry?.parentId != null) {
      dispatch(
        removeControlEntries({
          resourceId: DEFAULT_LAYER_ID,
          resourceType: AccessResourceType.Layer,
          parentId: guestAccessEntry?.parentId,
          accessor: GuestAccessor,
        }),
      );
    }
  };

export const inviteUsersToResources =
  (invites: OrgInviteUserToResourceMutationVariables['invites']): AppThunk =>
  (dispatch, getState, { urqlClient, requestQueue }) => {
    const state = getState();

    const orgId = selectedOrgIdSelector(state);
    if (orgId == null) {
      return;
    }

    enqueueRequest(requestQueue, () =>
      urqlClient
        .mutation<OrgInviteUserToResourceMutation, OrgInviteUserToResourceMutationVariables>(
          OrgInviteUserToResourceDocument,
          {
            invites,
            orgId,
          },
        )
        .toPromise()
        .then((result) => {
          if (result == null || result.data == null) {
            return;
          }
          dispatch(setInvitedUsers(result.data.orgInviteUserToResource));
        }),
    );
  };

export const inviteUsersToRole =
  (invites: OrgInviteUserToRoleMutationVariables['invites']): AppThunk =>
  (dispatch, getState, { urqlClient, requestQueue }) => {
    const state = getState();

    const orgId = selectedOrgIdSelector(state);
    if (orgId == null) {
      return;
    }

    enqueueRequest(requestQueue, () =>
      urqlClient
        .mutation<OrgInviteUserToRoleMutation, OrgInviteUserToRoleMutationVariables>(
          OrgInviteUserToRoleDocument,
          {
            invites,
            orgId,
          },
        )
        .toPromise()
        .then((result) => {
          if (result == null || result.data == null) {
            return;
          }
          dispatch(setInvitedUsers(result.data.orgInviteUserToRole));
        }),
    );
  };

export const deleteUserResourceInvite =
  (params: Omit<OrgDeleteUserResourceInviteMutationVariables, 'orgId'>): AppThunk =>
  (dispatch, getState, { urqlClient, requestQueue }) => {
    const state = getState();

    const orgId = selectedOrgIdSelector(state);
    if (orgId == null) {
      return;
    }

    enqueueRequest(requestQueue, () =>
      urqlClient
        .mutation<
          OrgDeleteUserResourceInviteMutation,
          OrgDeleteUserResourceInviteMutationVariables
        >(OrgDeleteUserResourceInviteDocument, {
          ...params,
          orgId,
        })
        .toPromise()
        .then((result) => {
          if (result == null || result.data == null) {
            return;
          }
          dispatch(setInvitedUsers(result.data.orgDeleteUserResourceInvite));
        }),
    );
  };
