export function hexToRGBA(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r},${g},${b},${alpha})`;
}

export enum ColorLocation {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Accessory = 'Accessory',
}

type ColorLocations = Record<ColorLocation, string>;

const COLOR_MAP: NullableRecord<string, ColorLocations | undefined> = {
  gray: {
    [ColorLocation.Primary]: 'gray.200',
    [ColorLocation.Secondary]: 'gray.100',
    [ColorLocation.Accessory]: 'gray.500',
  },
  red: {
    [ColorLocation.Primary]: 'watermelon.200',
    [ColorLocation.Secondary]: 'watermelon.100',
    [ColorLocation.Accessory]: 'watermelon.400',
  },
  yellow: {
    [ColorLocation.Primary]: 'sunburst.200',
    [ColorLocation.Secondary]: 'sunburst.100',
    [ColorLocation.Accessory]: 'sunburst.500',
  },
  green: {
    [ColorLocation.Primary]: 'highlight.green2',
    [ColorLocation.Secondary]: 'highlight.green1',
    [ColorLocation.Accessory]: 'highlight.green3',
  },
};

export function formatColorForUsage(color: string, location: ColorLocation): string {
  const colorLocations = COLOR_MAP[color];
  let newColor;

  if (colorLocations == null) {
    newColor = color;
  } else {
    newColor = colorLocations[location] ?? color;
  }

  if (newColor != null && newColor !== '') {
    return newColor;
  }

  return 'surface';
}
