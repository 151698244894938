import { IconButton } from '@chakra-ui/react';
import React from 'react';

import SaveFormulaIcon from 'components/SaveFormulaIcon/SaveFormulaIcon';

interface Props {
  onClick: React.MouseEventHandler;
  showSavedCheckmark: boolean;
  isEditing: boolean;
  hasSavedError: boolean;
}

const EditFormulaIcon = React.forwardRef<HTMLButtonElement, Props>(
  ({ onClick, showSavedCheckmark, isEditing, hasSavedError }, ref) => {
    return (
      <IconButton
        ref={ref}
        data-testid="formula-button"
        flexShrink={0}
        variant="icon"
        aria-label="Edit formula"
        ml={1}
        onClick={onClick}
        _hover={{}}
        _active={{}}
        icon={
          <SaveFormulaIcon
            showSavedCheckmark={showSavedCheckmark}
            isEditing={isEditing}
            hasSavedError={hasSavedError}
          />
        }
      />
    );
  },
);

export default EditFormulaIcon;
