import { Draft } from '@reduxjs/toolkit';

import { getCurrentState } from 'helpers/immer';
import { DEFAULT_LAYER_ID, Layer, LayerId } from 'reduxStore/models/layers';

export const getParentLayer = ({
  currentLayer,
  layers,
}: {
  currentLayer: Layer | null;
  layers: Record<LayerId, Layer>;
}): Layer | undefined => {
  if (currentLayer?.parentLayerId == null) {
    return layers[DEFAULT_LAYER_ID];
  }
  return layers[currentLayer.parentLayerId];
};

type EntitiesInLayer = Omit<
  Layer,
  | 'id'
  | 'name'
  | 'description'
  | 'isDraft'
  | 'createdAt'
  | 'parentLayerId'
  | 'isDeleted'
  | 'lockedMutationId'
  | 'mutationBatches'
  | 'lastActualsTime'
>;
const getEntitiesInLayer = (layer: Draft<Layer>): EntitiesInLayer => {
  const currentState = getCurrentState(layer);
  const {
    id: _id,
    name: _name,
    description: _description,
    isDraft: _isDraft,
    createdByUserId: _createdByUserId,
    createdAt: _createdAt,
    parentLayerId: _parentLayerId,
    isDeleted: _isDeleted,
    lockedMutationId: _lockedMutationId,
    mutationBatches: _mutationBatches,
    lastActualsTime: _lastActualsTime,
    ...rest
  } = currentState;
  return rest;
};

type EntitiesInLightLayer = Omit<
  EntitiesInLayer,
  | 'extDrivers'
  | 'extObjects'
  | 'extObjectSpecs'
  | 'attributes'
  | 'dimensions'
  | 'extTables'
  | 'integrationQueries'
>;
export const getEntitiesInLightLayer = (layer: Draft<Layer>): EntitiesInLightLayer => {
  const entities = getEntitiesInLayer(layer);
  const {
    extDrivers: _extDrivers,
    extObjects: _extObjects,
    extObjectSpecs: _extObjectSpecs,
    attributes: _attributes,
    dimensions: _dimensions,
    extTables: _extTables,
    integrationQueries: _integrationQueries,
    ...rest
  } = entities;
  return rest;
};
