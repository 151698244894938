import { StickyColumnKey } from 'config/cells';
import { INITIAL_VALUE_COLUMN_TYPE, PROPERTY_COLUMN_TYPE } from 'config/modelView';

export type ObjectGridViewColumnType = 'name' | 'initialValue';

export const OBJECT_PROPERTY_COLUMN_KEY: StickyColumnKey = {
  columnType: PROPERTY_COLUMN_TYPE,
  columnLayerId: undefined,
};

export const OBJECT_INITIAL_VALUE_COLUMN_KEY: StickyColumnKey = {
  columnType: INITIAL_VALUE_COLUMN_TYPE,
  columnLayerId: undefined,
};
