import BaseFormulaChunk from 'components/Formula/BaseFormulaChunk';
interface Props {
  text: string;
}

const StringChunk: React.FC<Props> = ({ text }) => {
  return <BaseFormulaChunk text={text} />;
};

export default StringChunk;
