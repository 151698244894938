import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 12 12',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <g fill="none">
      <rect x="2.25" y="5.25" width="7.5" height="5.25" rx="0.75" fill="currentColor" />
      <path
        d="M3.75 5.25V4C3.75 2.75 4.3125 1.5 6 1.5C7.6875 1.5 8.25 2.75 8.25 4V5.25"
        stroke="currentColor"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});
