import { startCase } from 'lodash';
import last from 'lodash/last';

import { ExtDriver } from 'reduxStore/models/extDrivers';

const QUERY_PATH_REGEX = /^query\((?<id>.+?)\)$/;

export function extDriverDisplayName(extDriver?: ExtDriver): string {
  const lastPath = last(extDriver?.path);
  if (extDriver == null || lastPath == null) {
    return 'unknown';
  }

  if (extDriver.integrationQueryId == null) {
    return lastPath;
  }

  const regex = new RegExp(`^${extDriver.integrationQueryId}-`, 'g');
  const displayName = lastPath.replace(regex, '');
  const source = startCase(extDriver.source);

  if (source == null || source === '') {
    return displayName + ' - ' + extDriver.integrationQueryId;
  }

  return displayName + ' - ' + source;
}

export function extDriverDisplayPath(
  path: string[],
  integrationQueryNamesById: NullableRecord<string, string>,
): string[] {
  return path.map((part) => {
    // ext drivers created by a integration query will have a containing parent extDriver in its path
    // with a name like "query(UUID)". In that case, name should be the corresponding integration query name.
    const match = part.match(QUERY_PATH_REGEX);
    const queryId = match != null ? match.groups?.id : undefined;
    const queryName = queryId != null ? integrationQueryNamesById[queryId] : undefined;
    return queryName != null ? queryName : part;
  });
}
