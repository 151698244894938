import { DimensionalPropertyMapping } from 'generated/graphql';
import { Attribute, NormalizedDimension } from 'reduxStore/models/dimensions';
import { DriverId, NormalizedDimensionalDriver } from 'reduxStore/models/drivers';

export type DimensionalPropertyId = string;
export type DriverPropertyId = string;
type ExtQueryId = string;

export type Collection = {
  dimensionalProperties: DimensionalProperty[];
  driverProperties: DriverProperty[];
};

export type DriverProperty = {
  id: DriverPropertyId;
  driverId: DriverId;
  // TODO (T-19718): Remove driver in favor of driverId
  /** @deprecated use driverId instead, since driver can become out of sync with the underlying driver */
  driver: NormalizedDimensionalDriver;
  extQueryId?: ExtQueryId;
};

export type DimensionalProperty = {
  id: DimensionalPropertyId;
  name: string;
  dimension: NormalizedDimension;
  mapping?: DimensionalPropertyMapping;
  extFieldSpecKey?: string;
  isDatabaseKey?: boolean;
  extQueryId?: ExtQueryId;
};

export type CollectionEntry = {
  attributeProperties: AttributeProperty[];
};

export type AttributeProperty = {
  dimensionalPropertyId: DimensionalPropertyId;
  attribute: Attribute;
};

export const isKeyDimensionalProperty = (
  prop: DimensionalProperty,
): prop is DimensionalProperty & { isDatabaseKey: true } => {
  return prop.isDatabaseKey === true;
};
