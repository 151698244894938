import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'FormulaIcon',
  viewBox: '0 0 20 20',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <g fill="currentColor">
      <path d="M14.357 1.25H7.002a5.092 5.092 0 0 0-5.085 5.09v2.358a.98.98 0 0 0 .973.972.98.98 0 0 0 .973-.972V6.341c0-1.736 1.4-3.148 3.126-3.148h7.356c1.725 0 3.126 1.412 3.126 3.148v7.318c0 1.736-1.401 3.148-3.127 3.148h-2.386a.98.98 0 0 0-.973.972.98.98 0 0 0 .973.971h2.386c2.803 0 5.073-2.28 5.073-5.09V6.34c0-2.797-2.283-5.09-5.073-5.09h.013Z" />
      <path d="M11.646 11.937a.98.98 0 0 0-.973-.972H9.376l.61-2.979c.103-.531.57-.907 1.115-.907.299 0 .597.117.804.337a.98.98 0 0 0 1.376 0 .976.976 0 0 0 0-1.373 3.108 3.108 0 0 0-2.18-.907A3.105 3.105 0 0 0 8.08 7.597l-.688 3.368H5.484a.98.98 0 0 0-.973.972.98.98 0 0 0 .973.971H6.99l-.61 2.98c-.104.53-.57.906-1.115.906h-.208c-.298 0-.597-.116-.804-.337a.98.98 0 0 0-1.375 0 .976.976 0 0 0 0 1.373c.57.57 1.362.907 2.179.907h.208a3.105 3.105 0 0 0 3.022-2.46l.688-3.369h1.7a.98.98 0 0 0 .972-.971Z" />
    </g>
  ),
});
