import { TinyColor, mostReadable } from '@ctrl/tinycolor';

import { ThemeColors } from 'config/badge';

const GRAY_700_TINY_COLOR = new TinyColor('#2D3748');

export const COLOR_OPTIONS = [
  { bgColor: '#FBA393', displayName: 'Coral Pink' },
  { bgColor: '#F2C372', displayName: 'Apricot Orange' },
  { bgColor: '#F4A0B4', displayName: 'Rose Pink' },
  { bgColor: '#F7BD8F', displayName: 'Peach' },
  { bgColor: '#F3A977', displayName: 'Tangerine Orange' },
  { bgColor: '#C4B7C1', displayName: 'Dusty Pink' },
  { bgColor: '#ADDEFF', displayName: 'Sky Blue' },
  { bgColor: '#B2B4DE', displayName: 'Lavender Blue' },
  { bgColor: '#90BCE6', displayName: 'Cornflower Blue' },
  { bgColor: '#C1D7E8', displayName: 'Baby Blue' },
  { bgColor: '#A1C1D6', displayName: 'Pale Blue' },
  { bgColor: '#9AC7D4', displayName: 'Greenish Blue' },
  { bgColor: '#B2DDC8', displayName: 'Seafoam Green' },
  { bgColor: '#A7C8B5', displayName: 'Sage Green' },
  { bgColor: '#D5B5D3', displayName: 'Lavender Pink' },
  { bgColor: '#E8C3B9', displayName: 'Sandstone' },
  { bgColor: '#DBD0C5', displayName: 'Stone Grey' },
  { bgColor: '#D0B8A2', displayName: 'Taupe' },
  { bgColor: '#C0A8D9', displayName: 'Lilac' },
  { bgColor: '#81B3A6', displayName: 'Teal Green' },
] as const;

type ColorOptionsBgColors = (typeof COLOR_OPTIONS)[number]['bgColor'];
type BadgeColorsType = {
  [bgColor in ColorOptionsBgColors]: ThemeColors<ColorOptionsBgColors, string>;
};

export const EXTENDED_THEMES_TO_COLORS = Object.fromEntries(
  COLOR_OPTIONS.map(({ bgColor }) => {
    const bgTinyColor = new TinyColor(bgColor);
    const entries: [ColorOptionsBgColors, BadgeColorsType[ColorOptionsBgColors]] = [
      bgColor,
      {
        bgColor,
        color: (mostReadable(bgTinyColor, [GRAY_700_TINY_COLOR], {
          includeFallbackColors: true,
        })?.toHexString() ?? '#000000') as ColorOptionsBgColors,
        _hover: {
          bgColor: bgTinyColor.lighten(5).toHexString(),
        },
      },
    ];
    return entries;
  }),
) as BadgeColorsType;

const GRAY_600_TINY_COLOR = new TinyColor('#464A53');
const GRAY_500_TINY_COLOR = new TinyColor('#949DAD');
const GRAY_400_TINY_COLOR = new TinyColor('#B9C0CD');
const GRAY_300_TINY_COLOR = new TinyColor('#DEE3EB');
const GRAY_200_TINY_COLOR = new TinyColor('#EFF1F5');
const WHITE_TINY_COLOR = new TinyColor('#fff');
const READABILITY_COLORS = [
  WHITE_TINY_COLOR,
  GRAY_300_TINY_COLOR,
  GRAY_500_TINY_COLOR,
  GRAY_600_TINY_COLOR,
];
const GHOST_READABILITY_COLORS = [WHITE_TINY_COLOR, GRAY_400_TINY_COLOR, GRAY_500_TINY_COLOR];

const convertedThemesCache: Record<string, ThemeColors> = {};
export function convertColorToBadgeTheme(
  color: string,
  options?: { withBorder?: boolean; withHover?: boolean; asGhost?: boolean },
): ThemeColors {
  options ??= { withBorder: true, withHover: true };
  options.withBorder ??= true;
  options.withHover ??= true;
  options.asGhost ??= false;

  const cacheKey = `${color}-${options.withBorder}-${options.withHover}-${options.asGhost}`;
  if (color in convertedThemesCache) {
    return convertedThemesCache[cacheKey];
  }

  let tinyColor = options.asGhost ? getGhostColor(color) : new TinyColor(color);
  if (!tinyColor.isValid) {
    tinyColor = GRAY_200_TINY_COLOR;
  }
  const hoverTinyColor = tinyColor.lighten(10);
  const readabilityColors = options.asGhost ? GHOST_READABILITY_COLORS : READABILITY_COLORS;
  const theme = {
    color: mostReadable(tinyColor.toHexString(), readabilityColors)?.toHexString() ?? '#fff',
    bgColor: tinyColor.toHexString(),
    ...(options.withBorder && {
      borderColor: tinyColor.darken(10).toHexString(),
      borderWidth: 'px',
    }),
    ...(options.withHover && {
      _hover: {
        bgColor: hoverTinyColor.toHexString(),
        color: mostReadable(hoverTinyColor, READABILITY_COLORS)?.toHexString() ?? '#fff',
      },
    }),
  };

  convertedThemesCache[cacheKey] = theme;
  return theme;
}

const ghostColorCache: Record<string, TinyColor> = {};
function getGhostColor(color: string): TinyColor {
  if (color in ghostColorCache) {
    return ghostColorCache[color];
  }

  let ghostColor = new TinyColor(color).lighten(12);
  if (ghostColor.toHexString() === '#ffffff') {
    ghostColor = new TinyColor(color).lighten(2);
  }
  ghostColorCache[color] = ghostColor;
  return ghostColorCache[color];
}
