import { MutationBatch, MutationId } from 'reduxStore/models/mutations';
import { DatasetSliceState } from 'reduxStore/reducers/datasetSlice';
import { Selector } from 'types/redux';

export const datasetSelector: Selector<DatasetSliceState> = (state) => state.dataset;

export const datasetIsFreshSelector: Selector<boolean> = (state) => state.dataset.isFresh;

export const datasetInitialMutationIdSelector: Selector<string | undefined> = (state) =>
  state.dataset.initialMutationId;

export const lastMutationIdSelector: Selector<MutationId | undefined> = (state) =>
  state.dataset.previousMutationId;

export const localEphemeralMutationSelector: Selector<MutationBatch | undefined> = (state) =>
  state.dataset.localEphemeralMutation;

export const datasetIsFullyLoadedSelector: Selector<boolean> = (state) =>
  state.dataset.isFullDatasetLoaded;

export const pendingOrFailedMutationsSelector: Selector<
  DatasetSliceState['pendingOrFailedMutations']
> = (state) => state.dataset.pendingOrFailedMutations;
