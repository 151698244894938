import { createContext } from 'react';

import { GroupInfo, NONE_GROUP_INFO } from 'config/businessObjects';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';

export const DatabaseTableContext = createContext<{
  objectSpecId: BusinessObjectSpecId;
}>({
  objectSpecId: '',
});

export const DatabaseRowContext = createContext<{
  groupInfo: GroupInfo;
  objectId: BusinessObjectId;
}>({
  objectId: '',
  groupInfo: NONE_GROUP_INFO,
});
