import { Text } from '@chakra-ui/react';
import { groupBy } from 'lodash';
import { useMemo } from 'react';

import AutofillPropertySubmenu from 'components/AutofillDimensionalPropertySelectMenu/AutofillPropertySubmenu';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { extractEmoji } from 'helpers/emoji';
import useAppSelector from 'hooks/useAppSelector';
import { AutofillPropertyOption } from 'selectors/autofillDimensionalPropertySelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';

export function useAutofillDimensionalPropertySelectItems(
  autofillOptions: AutofillPropertyOption[],
  onClose?: () => void,
) {
  const specsById = useAppSelector(businessObjectSpecsByIdForLayerSelector);
  const optionsByLookupSpecId = useMemo(
    () => groupBy(autofillOptions, 'lookupSpecId'),
    [autofillOptions],
  );
  const items = useMemo<SelectItem[]>(() => {
    const ret: SelectItem[] = [];
    Object.keys(optionsByLookupSpecId).forEach((specId) => {
      const spec = specsById[specId];
      const [specEmoji, specName] = extractEmoji(spec.name);
      ret.push({
        id: specId,
        icon: <Text>{specEmoji}</Text>,
        name: specName,
        submenu: () => (
          <AutofillPropertySubmenu options={optionsByLookupSpecId[specId]} onClose={onClose} />
        ),
      });
    }, []);
    return ret;
  }, [onClose, optionsByLookupSpecId, specsById]);
  return items;
}
