import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import union from 'lodash/union';
import xor from 'lodash/xor';

import { INSPECTOR_DEFAULT_HEIGHT } from 'config/driverChart';
import { BlockId } from 'reduxStore/models/blocks';
import { EventGroupId } from 'reduxStore/models/events';
import { applyMutationLocally_INTERNAL } from 'reduxStore/reducers/datasetSlice';

type InspectorType = 'initiative';

export const INITIAL_INSPECTOR_HEIGHT_RECORD: Record<InspectorType, number> = {
  initiative: INSPECTOR_DEFAULT_HEIGHT,
};

type RoadmapDimensions = {
  panelWidth: number;
  inspectorHeight: Record<InspectorType, number>;
};

export type Roadmap = {
  draftInitiative: { parentId: EventGroupId | null; blockId: BlockId } | null;
  roadmapDimensions: RoadmapDimensions;
  expandedPlans?: Record<BlockId, EventGroupId[]>;
  mostRecentlyCreatedEventGroupId?: EventGroupId;
};

const initialState: Roadmap = {
  draftInitiative: null,
  roadmapDimensions: {
    panelWidth: 320,
    inspectorHeight: INITIAL_INSPECTOR_HEIGHT_RECORD,
  },
  expandedPlans: {},
  mostRecentlyCreatedEventGroupId: undefined,
};

const roadmapSlice = createSlice({
  name: 'roadmap',
  initialState,
  reducers: {
    setRoadmapDimensions(state, action: PayloadAction<Partial<RoadmapDimensions>>) {
      state.roadmapDimensions = {
        ...state.roadmapDimensions,
        ...action.payload,
      };
    },
    updateInspectorHeight(
      state,
      action: PayloadAction<{ inspectorType: InspectorType; height: number }>,
    ) {
      const { inspectorType, height } = action.payload;
      state.roadmapDimensions.inspectorHeight = {
        ...state.roadmapDimensions.inspectorHeight,
        [inspectorType]: height,
      };
    },
    createDraftInitiative(
      state,
      action: PayloadAction<{ parentId: EventGroupId | null; blockId: BlockId }>,
    ) {
      const { parentId, blockId } = action.payload;
      state.draftInitiative = { parentId, blockId };
    },
    clearDraftInitiative(state) {
      state.draftInitiative = null;
    },
    toggleExpand(state, action: PayloadAction<{ blockId: BlockId; itemId: EventGroupId }>) {
      const { blockId, itemId } = action.payload;
      if (state.expandedPlans == null) {
        state.expandedPlans = {};
      }
      const byBlockId = state.expandedPlans[blockId] ?? [];
      state.expandedPlans[blockId] = xor(byBlockId, [itemId]);
    },
    expandPlans(state, action: PayloadAction<{ blockId: BlockId; itemIds: EventGroupId[] }>) {
      const { blockId, itemIds } = action.payload;
      if (state.expandedPlans == null) {
        state.expandedPlans = {};
      }
      const byBlockId = state.expandedPlans[blockId] ?? [];
      state.expandedPlans[blockId] = union(byBlockId, itemIds);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(applyMutationLocally_INTERNAL, (state, action) => {
      if (action.payload.isRemote) {
        return;
      }

      const { newEventGroups, updateBlocks } = action.payload.mutationBatch.mutation;

      if (newEventGroups == null || newEventGroups.length === 0) {
        state.draftInitiative = null;
      }

      if (newEventGroups != null && newEventGroups.length > 0) {
        state.mostRecentlyCreatedEventGroupId = newEventGroups[0]?.id;
      }

      // Clear this field when changing any block.
      if (updateBlocks != null && updateBlocks.length > 0) {
        state.mostRecentlyCreatedEventGroupId = undefined;
      }
    });
  },
});

export const {
  clearDraftInitiative,
  createDraftInitiative,
  setRoadmapDimensions,
  toggleExpand,
  updateInspectorHeight,
  expandPlans,
} = roadmapSlice.actions;

export default roadmapSlice.reducer;
