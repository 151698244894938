import { Flex } from '@chakra-ui/react';

import { FilterPill, FilterPillEmojiIcon, FilterPillProps } from 'components/FilterPill/FilterPill';
import { Filters } from 'helpers/driverSearch';
import { extractEmoji } from 'helpers/emoji';
import useAppSelector from 'hooks/useAppSelector';
import { submodelNamesByIdSelector } from 'selectors/submodelPageSelector';
import { submodelsWithDefaultByIdSelector } from 'selectors/submodelSelector';

interface SubmodelFilterPillProps extends Pick<FilterPillProps, 'onClose' | 'onChange'> {
  selectedIds: string[];
}

const SubmodelFilterPill = ({ selectedIds, onClose, onChange }: SubmodelFilterPillProps) => {
  const submodels = useAppSelector(submodelsWithDefaultByIdSelector);
  const submodelNamesById = useAppSelector(submodelNamesByIdSelector);

  const submodelItems = Object.entries(submodelNamesById).map(([submodelId, pageName]) => {
    const [emoji, name] = extractEmoji(pageName);
    return {
      id: submodelId,
      name,
      isSelected: selectedIds.includes(submodelId),
      icon: <FilterPillEmojiIcon bgColor="gray.200" emoji={emoji} />,
    };
  });

  let name = 'All models';
  let emoji: string | null = null;
  if (selectedIds.length === 1) {
    const submodel = submodels[selectedIds[0]];
    if (submodel != null) {
      const parts = extractEmoji(submodelNamesById[submodel.id]);
      emoji = parts[0];
      name = parts[1];
    }
  } else if (selectedIds.length > 1) {
    name = `${selectedIds.length} models`;
  }

  return (
    <FilterPill
      name={name}
      emoji={emoji}
      items={submodelItems}
      onClose={onClose}
      onChange={onChange}
    />
  );
};

interface Props {
  onClose: () => void;
  filters: Filters;
  updateFilter: (update: Partial<Filters>) => void;
}

const FilterPills = ({ onClose, filters, updateFilter }: Props) => {
  return (
    <Flex w="full" px={1} pb={2} alignItems="center" borderBottomWidth="px" borderColor="gray.300">
      <SubmodelFilterPill
        onClose={onClose}
        selectedIds={filters.submodels}
        onChange={(newIds) => updateFilter({ submodels: newIds })}
      />
    </Flex>
  );
};

export default FilterPills;
