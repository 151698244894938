import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'DollarSignIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  d: 'M8 1.5C4.41038 1.5 1.5 4.41038 1.5 8C1.5 11.5896 4.41038 14.5 8 14.5C11.5896 14.5 14.5 11.5896 14.5 8C14.5 4.41038 11.5896 1.5 8 1.5ZM8.54167 11.1725V12.3333H7.45833V11.1758C6.30458 11.022 5.57821 10.3508 5.53758 9.35633H6.77313C6.83217 9.85142 7.33158 10.1688 8.05092 10.1688C8.71554 10.1688 9.18517 9.84708 9.18517 9.38613C9.18517 8.99667 8.88075 8.77242 8.13163 8.61154L7.33592 8.44254C6.22279 8.20963 5.67733 7.63004 5.67733 6.69892C5.67733 5.70767 6.36742 5.01108 7.45833 4.83613V3.66667H8.54167V4.8345C9.60117 5.00621 10.3162 5.6925 10.3492 6.63121H9.14725C9.08821 6.14858 8.63917 5.82738 8.02167 5.82738C7.3825 5.82738 6.95946 6.12367 6.95946 6.58896C6.95946 6.96542 7.25142 7.18154 7.96642 7.33375L8.70254 7.49029C9.92996 7.74867 10.4586 8.27733 10.4586 9.22525C10.4592 10.2988 9.74092 11.0084 8.54167 11.1725Z',
});
