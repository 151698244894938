import { createSelector } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import { SelectorWithLayerParam, getCacheKeyForLayerSelector } from 'helpers/layerSelectorFactory';
import { isNamedVersion } from 'helpers/namedVersions';
import { ExtObjectSpec } from 'reduxStore/models/extObjectSpecs';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import {
  defaultLayerSelector,
  getGivenOrCurrentLayerId,
  layersSelector,
} from 'selectors/layerSelector';

export const extObjectSpecsByKeySelector = createSelector(
  defaultLayerSelector,
  (layer) => layer.extObjectSpecs.byKey,
);

export const extObjectSpecsByKeyForLayerSelector: SelectorWithLayerParam<
  Record<string, ExtObjectSpec>
> = createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
  const layer = layers[layerId];
  if (layer == null) {
    return {};
  }
  if (isNamedVersion(layer)) {
    return layer.extObjectSpecs.byKey;
  }

  const defaultLayer = layers[DEFAULT_LAYER_ID];
  return defaultLayer.extObjectSpecs.byKey;
})(getCacheKeyForLayerSelector);
