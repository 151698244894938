import React, { createContext, useContext, useMemo } from 'react';

import { EventEntity } from './types';

const EventEntityContext = createContext<EventEntity | null>(null);

export const EventEntityContextProvider: React.FC<
  EventEntity & {
    children: React.ReactNode;
  }
> = ({ children, ...eventEntity }) => {
  const value = useMemo(() => eventEntity, [eventEntity]);

  return <EventEntityContext.Provider value={value}>{children}</EventEntityContext.Provider>;
};

export const useEventEntityContext = (): EventEntity | null => {
  const context = useContext(EventEntityContext);

  return context;
};
