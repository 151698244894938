import {
  CharacterMetadata,
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftDecorator,
  DraftModel,
  EditorState,
  Modifier,
  RawDraftContentState,
  RawDraftEntityRange,
  SelectionState,
  genKey,
} from 'draft-js';
import { findLastIndex } from 'lodash';

import AtomicNumberDecorator from 'components/FormulaInput/AtomicNumberDecorator';
import DriverDecorator from 'components/FormulaInput/DriverDecorator';
import ExternalDriverDecorator from 'components/FormulaInput/ExtDriverDecorator';
import ExtQueryDecorator from 'components/FormulaInput/ExtQueryDecorator';
import ObjectSpecDecorator from 'components/FormulaInput/ObjectSpecDecorator';
import SubmodelDecorator from 'components/FormulaInput/SubmodelDecorator';
import {
  PREVENT_FORMULA_QUERY_ARGS,
  getFirstMatchingMathOperator,
  matchesMathOperator,
} from 'config/formula';
import { DATE_DIFF_UNIT_ARG_IDX } from 'helpers/dates';
import { extractEmoji } from 'helpers/emoji';
import { filterDisplayToItem } from 'helpers/filterDisplayToItem';
import {
  WHITESPACE_REGEX,
  entityToAntlr,
  isAtomicNumberData,
  isDriverEntityData,
  isExtDriverEntityData,
  isExtQueryEntityData,
  isObjectSpecEntityData,
} from 'helpers/formula';
import {
  FormulaErrorEvaluationProps,
  getFormulaError,
} from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import {
  DriverFormulaDisplayChunk,
  FormulaDisplay,
  FormulaDisplayChunkType,
  ObjectFormulaDisplayChunk,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import {
  DatabaseFormulaProperty,
  DatabaseFormulaPropertyId,
} from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { Attribute } from 'reduxStore/models/dimensions';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import {
  AtomicNumberMetadata,
  DraftEntity,
  DriverRefMetadata,
  EntityData,
  EntityDataWithKey,
  EntityType,
  ExtDriverRefMetadata,
  ExtQueryRefMetadata,
  MathOperator,
  ObjectSpecRefMetadata,
  RawFormula,
  SubmodelRefMetadata,
} from 'types/formula';

function driverChunkToMetadata(
  chunk: DriverFormulaDisplayChunk & {
    error?: string | undefined;
  },
): DriverRefMetadata {
  return {
    id: chunk.driverId,
    dateRange: chunk.timeRange,
    dateRangeDisplay: chunk.timeRangeDisplay,
    label: chunk.displayName,
    attributeFilters: chunk.attributeFilters,
    placeholder: chunk.placeholder,
    error: chunk.error,
  };
}

function objectSpecChunkToMetadata(
  chunk: ObjectFormulaDisplayChunk & {
    error?: string | undefined;
  },
  databaseFormulaPropertiesById: Record<
    DatabaseFormulaPropertyId,
    DatabaseFormulaProperty | undefined
  >,
): ObjectSpecRefMetadata {
  return {
    label: chunk.displayName,
    dateRangeDisplay: chunk.timeRangeDisplay ?? chunk.field?.timeRangeDisplay,
    dateRange: chunk.timeRange ?? chunk.field?.timeRange,
    id: chunk.objectSpecId,
    fieldId: chunk.field?.fieldId,
    error: chunk.error ?? chunk.field?.error,
    filters: {
      includeAllContextAttributes: chunk.filters.includeAllContextAttributes,
      matchToSingleResult: chunk.filters.matchToSingleResult,
      propertyFilters: chunk.filters.propertyFilters
        .map((filter) =>
          filterDisplayToItem(databaseFormulaPropertiesById, chunk.objectSpecId, filter),
        )
        .filter(isNotNull),
    },
    isThisRef: chunk.isThisRef,
  };
}

export const displayToDraftJS = (
  databaseFormulaPropertiesById: Record<
    DatabaseFormulaPropertyId,
    DatabaseFormulaProperty | undefined
  >,
  display: FormulaDisplay,
): RawDraftContentState => {
  let text = '';
  const inlineStyleRanges: DraftModel.Encoding.RawDraftInlineStyleRange[] = [];
  const entityMap: RawDraftContentState['entityMap'] = {};

  const pushDisplayText = (idx: number, displayText: string) => {
    if (idx === 0) {
      text += ' ';
    }
    entityRanges.push({ key: idx, offset: [...text].length, length: [...displayText].length });
    text += displayText;
    if (idx === display.chunks.length - 1) {
      text += ' ';
    }
  };

  const entityRanges: RawDraftEntityRange[] = [];
  display.chunks.forEach((chunk, idx) => {
    if (chunk.type === FormulaDisplayChunkType.Driver) {
      const displayText = chunk.displayName;
      pushDisplayText(idx, displayText);

      entityMap[idx] = {
        type: EntityType.Driver,
        data: driverChunkToMetadata(chunk),
        mutability: 'IMMUTABLE',
      };
    } else if (chunk.type === FormulaDisplayChunkType.Submodel) {
      const displayText = chunk.displayName;
      pushDisplayText(idx, displayText);
      const data: SubmodelRefMetadata = {
        id: chunk.submodelId,
        label: displayText,
        driverGroupFilter: chunk.driverGroupId,
        dateRangeDisplay: chunk.timeRangeDisplay,
        dateRange: chunk.timeRange,
      };
      entityMap[idx] = {
        type: EntityType.Submodel,
        data,
        mutability: 'IMMUTABLE',
      };
    } else if (chunk.type === FormulaDisplayChunkType.Object) {
      const displayText = chunk.displayName;
      pushDisplayText(idx, displayText);

      entityMap[idx] = {
        type: EntityType.ObjectSpec,
        data: objectSpecChunkToMetadata(chunk, databaseFormulaPropertiesById),
        mutability: 'IMMUTABLE',
      };
    } else if (chunk.type === FormulaDisplayChunkType.ExtDriver) {
      const displayText = chunk.displayName;
      pushDisplayText(idx, displayText);
      const data: ExtDriverRefMetadata = {
        label: displayText,
        dateRangeDisplay: chunk.timeRangeDisplay,
        dateRange: chunk.timeRange,
        id: chunk.id,
        source: chunk.source,
      };

      entityMap[idx] = {
        type: EntityType.ExtDriver,
        data,
        mutability: 'IMMUTABLE',
      };
    } else if (chunk.type === FormulaDisplayChunkType.ExtQuery) {
      const displayText = chunk.displayName;
      pushDisplayText(idx, displayText);
      const data: ExtQueryRefMetadata = {
        id: chunk.id,
        label: displayText,
        source: chunk.source,
        attributeFilters: chunk.attributeFilters,
        dateRangeDisplay: chunk.timeRangeDisplay,
        dateRange: chunk.timeRange,
      };

      entityMap[idx] = {
        type: EntityType.ExtQuery,
        data,
        mutability: 'IMMUTABLE',
      };
    } else if (chunk.type === FormulaDisplayChunkType.AtomicNumber) {
      const displayText = chunk.value;
      pushDisplayText(idx, displayText);
      const data: AtomicNumberMetadata = {
        label: displayText,
        tooltip: chunk.tooltip,
        icon: chunk.icon,
      };

      entityMap[idx] = {
        type: EntityType.AtomicNumber,
        data,
        mutability: 'IMMUTABLE',
      };
    } else if (chunk.type === FormulaDisplayChunkType.String) {
      text += `'${chunk.text}'`;
    } else {
      text += chunk.text;
    }
  });

  return {
    blocks: [
      {
        key: genKey(),
        depth: 0,
        inlineStyleRanges,
        type: 'unstyled',
        text,
        entityRanges,
      },
    ],
    entityMap,
  };
};

const THIS_MONTH_REGEX = /thismonth\(\s*\)/g;

const getDecoratorConfig = (entityType: EntityType, decorator: DraftDecorator['component']) => {
  return {
    strategy: (
      block: ContentBlock,
      callback: (start: number, end: number) => void,
      content: ContentState,
    ) => {
      block.findEntityRanges((value: CharacterMetadata) => {
        const entityKey = value.getEntity();
        if (entityKey == null || entityKey.length === 0) {
          return false;
        }
        return content.getEntity(entityKey).getType() === entityType;
      }, callback);
    },
    component: decorator,
  };
};

export const compositeDecorator = new CompositeDecorator([
  getDecoratorConfig(EntityType.Driver, DriverDecorator),
  getDecoratorConfig(EntityType.ObjectSpec, ObjectSpecDecorator),
  getDecoratorConfig(EntityType.ExtDriver, ExternalDriverDecorator),
  getDecoratorConfig(EntityType.Submodel, SubmodelDecorator),
  getDecoratorConfig(EntityType.ExtQuery, ExtQueryDecorator),
  getDecoratorConfig(EntityType.AtomicNumber, AtomicNumberDecorator),
]);

export function draftContentStateToRawFormula(
  content: ContentState,
  driversById: Record<DriverId, Driver | undefined>,
): RawFormula | null {
  return buildRawFormula(content, driversById)?.formula ?? null;
}

function buildRawFormula(
  content: ContentState,
  driversById: Record<DriverId, Driver | undefined>,
  errStart?: number,
): { formula: RawFormula; errorLocation: number | undefined } | null {
  if (!content.hasText()) {
    return null;
  }

  let text = content.getPlainText().toLowerCase();
  const entities: DraftEntity[] = [];
  const block = content.getFirstBlock();
  block.findEntityRanges(
    (charMetadata) => charMetadata.getEntity() != null,
    (start, end) => {
      const entityKey = block.getEntityAt(start);
      const entity = content.getEntity(entityKey);
      entities.push({
        key: entityKey,
        type: entity.getType() as EntityType,
        range: [start, end],
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: entity.getData(),
      });
    },
  );

  let offset = 0;
  let errorLocation: number | undefined;
  for (const entity of entities) {
    const [start, end] = entity.range;
    const prevLength = text.length;
    if (errStart != null && errorLocation == null && errStart <= start + offset) {
      errorLocation = errStart - offset;
    }
    text =
      text.substring(0, start + offset) +
      entityToAntlr(entity, driversById) +
      text.substring(end + offset);
    offset += text.length - prevLength;
  }

  if (errStart != null && errorLocation == null) {
    errorLocation = errStart - offset;
  }
  let andIndex = text.indexOf(' and ');
  while (andIndex >= 0) {
    text = text.substring(0, andIndex) + ' && ' + text.substring(andIndex + 5);
    if (errStart != null && errorLocation != null && errStart > andIndex) {
      errorLocation += 1;
    }
    andIndex = text.indexOf(' and ');
  }

  text = text.replaceAll(' or ', ' || ');
  text = text.replaceAll(THIS_MONTH_REGEX, 'relative(0,0)');
  return { formula: text, errorLocation };
}

function draftErrorLocation(
  content: ContentState,
  driversById: Record<DriverId, Driver | undefined>,
  errStart?: number,
): number | undefined {
  return buildRawFormula(content, driversById, errStart)?.errorLocation;
}

export function getSelectionState(
  key: string,
  anchorOffset: number,
  focusOffset: number = anchorOffset,
): SelectionState {
  return new SelectionState({
    anchorKey: key,
    anchorOffset,
    focusKey: key,
    focusOffset,
    isBackward: false,
    hasFocus: true,
  });
}

export function getEntityKeysInSelection(block: ContentBlock, selection: SelectionState) {
  const keys = new Set<string>();

  for (let i = selection.getStartOffset(); i < selection.getEndOffset(); i++) {
    const key = block.getEntityAt(i);
    if (key != null && !keys.has(key)) {
      keys.add(key);
    }
  }
  return keys;
}

export function getEntityAtIndex(
  content: ContentState,
  index: number,
  refEntityOnly = false,
): (EntityData & { key: string }) | null {
  const editorBlock = content.getFirstBlock();

  const nextEntityKey = editorBlock.getEntityAt(index);
  if (nextEntityKey == null) {
    return null;
  }
  const nextEntity = content.getEntity(nextEntityKey);
  const entityData = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data: nextEntity.getData(),
    type: nextEntity.getType(),
  } as EntityData;
  if (
    !refEntityOnly ||
    isDriverEntityData(entityData) ||
    isObjectSpecEntityData(entityData) ||
    isExtDriverEntityData(entityData) ||
    isExtQueryEntityData(entityData) ||
    isAtomicNumberData(entityData)
  ) {
    return {
      ...entityData,
      key: nextEntityKey,
    };
  }
  return null;
}

export function deleteWordRange(content: ContentState, searchStart: number): [number, number] {
  const block = content.getFirstBlock();
  const plainText = block.getText();
  let textToDel = '';
  for (let i = searchStart; i >= 0; i--) {
    const entity = getEntityAtIndex(content, i, true);
    if (entity != null) {
      if (textToDel.trim().length === 0) {
        return [i - entity.data.label.length, searchStart];
      }
      return [i + 1, searchStart];
    }
    const char = plainText.charAt(i);
    if (char === ' ' && textToDel.trim().length > 0) {
      return [i, searchStart];
    }
    textToDel += char;
  }
  return [0, searchStart];
}

export function findReferenceEntity(
  content: ContentState,
  searchStart: number,
  direction: 'forward' | 'backward',
): EntityDataWithKey | null {
  const editorBlock = content.getFirstBlock();
  if (direction === 'backward') {
    for (let i = searchStart; i >= 0; i--) {
      const entity = getEntityAtIndex(content, i, true);
      if (entity) {
        return entity as EntityDataWithKey;
      }
    }
  } else if (direction === 'forward') {
    for (let i = searchStart; i < editorBlock.getLength(); i++) {
      const entity = getEntityAtIndex(content, i, true);
      if (entity) {
        return entity as EntityDataWithKey;
      }
    }
  }

  return null;
}

export function getEntityRange(block: ContentBlock, key: string): [number, number] {
  let entityStart = 0;
  let entityEnd = 0;
  block.findEntityRanges(
    (value) => value.getEntity() === key,
    (start, end) => {
      entityStart = start;
      entityEnd = end;
    },
  );
  return [entityStart, entityEnd];
}

const EMPTY_QUERY_REGEX = /^[(+\-*^></!]+$/;
const OPERATOR_REGEX = /^[()+\-*,^=></!]+$/;
const INCLUDES_LETTER = /.*[a-zA-Z].*/;
// any character that's not used for formula evaluation can be part of the query
const OBJECT_REGEX = /^[a-zA-Z\s]+\.[a-zA-Z]*$/;
const NUMBER_REGEX = /^\$?[0-9]+[0-9,]*\.?[0-9]*[kKmMbBtT]?%?$/;
const DECIMAL_REGEX = /^\$?\.?[0-9]+[kKmMbBtT]?%?$/;

const MONTH_KEY_REGEX = /^([1-9][0-9]{3})-([0-9]{2})$/;
const DATE_REGEX = /^([1-9][0-9]{3})-([0-9]{2})-([0-9]{2})$/;

function getSubstringQuery({
  editorText,
  index,
  offset,
  editorBlock,
  direction,
  previousWasOperator,
  withinQuotes,
  parsedFuncs,
}: {
  editorText: string;
  index: number;
  offset: number;
  editorBlock: ContentBlock;
  direction: 'forward' | 'backward';
  previousWasOperator: boolean;
  withinQuotes: boolean;
  parsedFuncs: ParsedFunction[];
}) {
  let query = '';
  const stringArray = [...editorText];
  const iterationAdd = direction === 'forward' ? 1 : -1;
  let checkOperators = !previousWasOperator;

  let currOffset = offset;
  for (let i = index; i < stringArray.length && i >= 0; i += iterationAdd) {
    const char = stringArray[i];
    // getEntityAt needs to use the position in the string considering that some characters have len > 1
    if (editorBlock.getEntityAt(currOffset) != null) {
      break;
    }

    const { func, argSlotIdx } = getMostSpecificFuncAtCursorIndex(parsedFuncs, i) ?? {};
    const arg = argSlotIdx != null ? func?.argSlots[argSlotIdx] : null;

    // If scanning backwards, we want to include the first character of the
    // function name so it doesn't get chopped off. The `start` index
    // represents first character of the function name.
    const atFuncNameStart = func != null && direction === 'backward' && i === func.start;
    if (atFuncNameStart) {
      query = char + query;
      break;
    }

    if (
      // Check if we are at the outer bounds of a function.
      (func != null && (func.start === i || (func.end != null && func.end - 1 === i))) ||
      // Check if we are at the bounds of an argument slot. Don't subtract 1
      // because we want to include the trailing comma/paren.
      (arg != null && (arg.start - 1 === i || (arg.end != null && arg.end === i)))
    ) {
      break;
    }

    // Halt on quote
    if (withinQuotes && char === `'`) {
      break;
    }

    if (!withinQuotes && OPERATOR_REGEX.test(char)) {
      if (checkOperators) {
        const afterOperatorIndex = i + iterationAdd;

        // Get query on other side of operator
        const afterOperatorResult =
          afterOperatorIndex < stringArray.length && afterOperatorIndex >= 0
            ? getSubstringQuery({
                editorText,
                index: afterOperatorIndex,
                offset: currOffset + iterationAdd * char.length,
                editorBlock,
                direction,
                previousWasOperator: true,
                withinQuotes: false,
                parsedFuncs,
              })
            : undefined;
        const afterOperatorQuery = afterOperatorResult?.query ?? '';
        // If query includes a letter, but it is not just a special word like ROUND add to query
        if (
          afterOperatorResult != null &&
          INCLUDES_LETTER.test(afterOperatorQuery) &&
          getFirstMatchingMathOperator(afterOperatorQuery.trim()) == null
        ) {
          query =
            direction === 'forward'
              ? query + char + afterOperatorQuery
              : afterOperatorQuery + char + query;
          currOffset = afterOperatorResult.offset;
        }
      }
      break;
    }

    query = direction === 'forward' ? query + char : char + query;
    currOffset = currOffset + iterationAdd * char.length;
    checkOperators = true;
  }

  return { query, offset: currOffset };
}

export interface CursorPositionFuncMetadata {
  func: ParsedFunction;
  argSlotIdx: number | null;
}

export function getFuncArgDisplayName(
  cursorFuncQueryMetadata: CursorPositionFuncMetadata | null,
): string | null {
  if (cursorFuncQueryMetadata == null) {
    return null;
  }

  const { func, argSlotIdx } = cursorFuncQueryMetadata;
  const arg = argSlotIdx != null ? func.argSlots[argSlotIdx] : null;
  if (arg == null) {
    return null;
  }

  if (
    matchesMathOperator(func.name, MathOperator.DateDiff) &&
    argSlotIdx === DATE_DIFF_UNIT_ARG_IDX
  ) {
    return 'Time Unit';
  }

  return null;
}

// Start/end represent a range so in order to represent an empty range, the
// start index is the location of the first character of a range and the end
// index is after the last character in the range. So, if start === end, the
// range is empty. If end is null, the end of the range is not yet known at
// that point.
export interface ParsedFunction {
  name: string;
  start: number;
  end: number | null; // func may not be complete yet

  // When parsing we don't know if a function should take an argument or not.
  // This info is just telling us where the slots for potential arguments are.
  argSlots: Array<{ start: number; end: number | null }>;
}

// A very loose parser for function calls which is used to determine where the
// cursor is in relation to functions and their arguments. This is useful for
// knowing how to terminate queries. It can also be used to provide live
// documentation for specific function arguments.
export function parseFunctions(input: string): ParsedFunction[] {
  const chars = [...input];
  const parsedFunctions: ParsedFunction[] = [];

  const stack: ParsedFunction[] = [];

  // We want to ignore closing parens that aren't part of the corresponding
  // opening paren for a function.
  let parenDepth = 0;

  // Keep track of the paren length that each function starts at so we know
  // which closing paren is associated to it.
  const funcDepthStack: number[] = [];

  for (let i = 0; i < chars.length; i++) {
    const c = chars[i];

    // Update paren depth
    if (c === '(') {
      parenDepth++;
    } else if (c === ')') {
      parenDepth--;
    }

    // We have a function name if we hit an opening parenthesis and we have characters before it
    if (c === '(' && i > 0) {
      const beforeParen = chars.slice(0, i);
      const lastDelimiterIdx = findLastIndex(
        beforeParen,
        (toTest) => WHITESPACE_REGEX.test(toTest) || OPERATOR_REGEX.test(toTest),
      );

      const funcStart = lastDelimiterIdx + 1;

      const name = chars.slice(funcStart, i).join('');

      if (name.length > 0) {
        const newFunc: ParsedFunction = {
          name,
          start: funcStart,
          end: null,
          argSlots: [],
        };

        stack.push(newFunc);
        funcDepthStack.push(parenDepth);
      }
    }

    const lastFunc = stack[stack.length - 1];
    const lastFuncParenDepth = funcDepthStack[funcDepthStack.length - 1];
    const lastArgSlot = lastFunc != null ? lastFunc.argSlots[lastFunc.argSlots.length - 1] : null;
    const hasIncompleteArg = lastArgSlot != null && lastArgSlot.end == null;

    // We are at the start of a new argument if we hit a comma or an opening
    // parenthesis and we're not currently processing an existing argument.
    if (lastFunc != null && !hasIncompleteArg && (c === ',' || c === '(')) {
      lastFunc.argSlots.push({ start: i + 1, end: null });
    }

    // We are at the end of an argument if we hit a comma or a closing
    // parenthesis. Add 1 to depth since we already would have decremented it
    // above.
    if (lastFunc != null && hasIncompleteArg && (c === ',' || c === ')')) {
      if (c !== ')' || parenDepth + 1 === lastFuncParenDepth) {
        lastArgSlot.end = i;
      }

      if (c === ',') {
        lastFunc.argSlots.push({ start: i + 1, end: null });
      }
    }

    // We have a function end if we hit a closing parenthesis that matches the
    // starting depth of the function.
    if (c === ')' && parenDepth + 1 === lastFuncParenDepth) {
      funcDepthStack.pop();
      const func = stack.pop();
      if (func != null) {
        func.end = i + 1;
        parsedFunctions.push(func);
      }
    }
  }

  // Include incomplete functions
  parsedFunctions.push(...stack);

  return parsedFunctions;
}

export function getCursorFuncQueryMetadata(
  editorState: EditorState,
  parsedFuncs: ParsedFunction[],
): CursorPositionFuncMetadata | null {
  const cursorPositionStringLength = editorState.getSelection().getEndOffset();
  const editorContent = editorState.getCurrentContent();
  const editorText = editorContent.getPlainText();
  const textBeforeCursor = editorText.substring(0, cursorPositionStringLength);
  // Long characters include things like emojis, but could also include foreign language characters
  const longChars = textBeforeCursor.length - [...textBeforeCursor].length;
  const cursorPosition = cursorPositionStringLength - longChars;

  return getMostSpecificFuncAtCursorIndex(parsedFuncs, cursorPosition);
}

function getMostSpecificFuncAtCursorIndex(parsedFuncs: ParsedFunction[], cursorPosition: number) {
  // Find the most inner function that the cursor is in as well as the argument slot it is in.
  const funcs = parsedFuncs.filter(
    (func) =>
      getFirstMatchingMathOperator(func.name) != null &&
      func.start <= cursorPosition &&
      (func.end == null || func.end >= cursorPosition),
  );

  const mostSpecificFunc = funcs.reduce(
    (curr, func) => {
      if (curr == null) {
        return func;
      }

      const moreSpecificStart = func.start >= curr.start;
      const moreSpecificEnd =
        // Neither have an end
        (func.end == null && curr.end == null) ||
        // Both have an end and func's end is more specific
        (func.end != null && curr.end != null && func.end <= curr.end);

      // If func is more specific than the current most specific func, replace it
      return moreSpecificStart && moreSpecificEnd ? func : curr;
    },
    null as ParsedFunction | null,
  );

  if (mostSpecificFunc == null) {
    return null;
  }

  const argSlotIdx = mostSpecificFunc.argSlots.findIndex(
    (argSlot) =>
      // start is the first character of the argument so it doesn't account for
      // the comma or paren. The end is already one past the last character.
      argSlot.start - 1 <= cursorPosition && (argSlot.end == null || argSlot.end >= cursorPosition),
  );

  return { func: mostSpecificFunc, argSlotIdx };
}

export function getQuery(
  editorState: EditorState,
  parsedFuncs: ParsedFunction[],
): [string, number] {
  const cursorPositionStringLength = editorState.getSelection().getEndOffset();
  const editorContent = editorState.getCurrentContent();
  const editorBlock = editorContent.getFirstBlock();
  const editorText = editorContent.getPlainText();
  const textBeforeCursor = editorText.substring(0, cursorPositionStringLength);
  // Long characters include things like emojis, but could also include foreign language characters
  const longChars = textBeforeCursor.length - [...textBeforeCursor].length;
  const cursorPosition = cursorPositionStringLength - longChars;

  const stringArray = [...editorText];
  let withinQuotes = false;
  let isTrailingCharQuote = false;
  let lastCharIndex = -1;

  // Specifically check for trailing quotes on string literals.
  if (cursorPosition === stringArray.length && stringArray.length > 0) {
    let i = cursorPosition - 1;
    while (i >= 0 && stringArray[i] === ' ') {
      --i;
    }
    lastCharIndex = i;
    if (stringArray[lastCharIndex] === `'`) {
      withinQuotes = true;
      isTrailingCharQuote = true;
    }
  }

  // Check if the cursor is within a string literal.
  for (let i = 0; i < stringArray.length && i < cursorPosition; i++) {
    if (stringArray[i] === `'`) {
      withinQuotes = !withinQuotes;
    }
  }

  const queryRight = getSubstringQuery({
    editorText,
    index: cursorPosition,
    offset: cursorPositionStringLength,
    editorBlock,
    direction: 'forward',
    previousWasOperator: false,
    parsedFuncs,
    withinQuotes,
  }).query.trimEnd();

  const leftOffset =
    // stringArray[cursorPosition] == null if stringArray is [] because the editorText is empty
    cursorPosition >= stringArray.length || stringArray[cursorPosition] == null
      ? cursorPositionStringLength - 1
      : cursorPositionStringLength - stringArray[cursorPosition].length;

  const queryLeft = getSubstringQuery({
    editorText,
    index: isTrailingCharQuote ? lastCharIndex - 1 : cursorPosition - 1,
    offset: leftOffset,
    editorBlock,
    direction: 'backward',
    previousWasOperator: false,
    parsedFuncs,
    withinQuotes,
  })
    .query.trimStart()
    .trimEnd();

  // Define the offset into the formula as the the cursor position minus the characters to the left of the cursor.
  const offset = stringArray.slice(0, cursorPosition).join('').lastIndexOf(queryLeft);
  const query = queryLeft + queryRight;

  return [query, offset];
}

export function preventQueryDropdown(
  editorState: EditorState,
  queryIndex: number,
  cursorFuncQueryMetadata: CursorPositionFuncMetadata | null,
): boolean {
  if (cursorFuncQueryMetadata != null) {
    const { func, argSlotIdx } = cursorFuncQueryMetadata;
    const mathOp = getFirstMatchingMathOperator(func.name);
    const preventQueryArgSlotIdxs = mathOp != null ? PREVENT_FORMULA_QUERY_ARGS[mathOp] : undefined;
    if (
      preventQueryArgSlotIdxs != null &&
      argSlotIdx != null &&
      preventQueryArgSlotIdxs.includes(argSlotIdx)
    ) {
      return true;
    }
  }
  return false;
}

export function showEmptyQueryDropdown(
  editorState: EditorState,
  queryIndex: number,
  cursorFuncQueryMetadata: CursorPositionFuncMetadata | null,
): boolean {
  if (cursorFuncQueryMetadata != null) {
    const { func, argSlotIdx } = cursorFuncQueryMetadata;
    if (
      matchesMathOperator(func.name, MathOperator.DateDiff) &&
      argSlotIdx === DATE_DIFF_UNIT_ARG_IDX
    ) {
      return true;
    }
  }

  const offset = editorState.getSelection().getEndOffset();
  const editorContent = editorState.getCurrentContent();
  const editorBlock = editorContent.getFirstBlock();
  const editorText = editorContent.getPlainText();
  if (editorText.trim().length === 0) {
    // Special case - if there is no text, don't show the dropdown
    // This is to allow the user to save an empty formula
    if (editorText === '') {
      return false;
    }
    return true;
  }

  const stringArray = [...editorText];
  let currOffset = offset;
  for (let i = queryIndex; i < stringArray.length && i >= 0; i -= 1) {
    const char = stringArray[i];
    // getEntityAt needs to use the position in the string considering that some characters have len > 1
    if (editorBlock.getEntityAt(currOffset) != null) {
      return false;
    }

    if (EMPTY_QUERY_REGEX.test(char)) {
      return true;
    } else if (char.trim().length !== 0) {
      return false;
    }

    currOffset -= 1;
  }

  return false;
}

// Add leading whitespace to formulas with an entity at the beginning, and trailing
// whitespace if there's an entity at the end, to ensure a good cursor experience
export function addWhitespace(e: EditorState): EditorState {
  const content = e.getCurrentContent();
  const block = content.getFirstBlock();
  const startsWithEntity = getEntityAtIndex(content, 0) != null;
  const endsWithEntity = getEntityAtIndex(content, block.getLength() - 1) != null;
  if (!startsWithEntity && !endsWithEntity) {
    return e;
  }

  let newContent = content;
  if (startsWithEntity) {
    newContent = Modifier.insertText(newContent, getSelectionState(block.getKey(), 0), ' ');
  }
  if (endsWithEntity) {
    newContent = Modifier.insertText(
      newContent,
      getSelectionState(block.getKey(), newContent.getFirstBlock().getLength()),
      ' ',
    );
  }
  return EditorState.set(e, { currentContent: newContent });
}

type ErrorHighlightingBaseProps = {
  driversById: Record<DriverId, Driver | undefined>;
  fieldSpecsById: Record<BusinessObjectSpecId, BusinessObjectFieldSpec | undefined>;
  attributesById: NullableRecord<DriverId, Attribute>;
  attributesBySubDriverId: NullableRecord<DriverId, Attribute[]>;
  objectsByFieldId: Record<BusinessObjectFieldId, BusinessObject>;
  objectsById: Record<BusinessObjectId, BusinessObject>;
  submodelIdByBlockId: NullableRecord<BlockId, SubmodelId>;
  errorEvaluatorProps: Pick<
    FormulaErrorEvaluationProps,
    'formulaEntityId' | 'isActualsFormula' | 'evaluator'
  >;
};
export function addErrorHighlightingToContent({
  content,
  driversById,
  fieldSpecsById,
  attributesById,
  attributesBySubDriverId,
  objectsByFieldId,
  objectsById,
  submodelIdByBlockId,
  errorEvaluatorProps,
}: ErrorHighlightingBaseProps & { content: ContentState }): ContentState {
  const rawFormula = draftContentStateToRawFormula(content, driversById);
  const formulaError =
    rawFormula != null
      ? getFormulaError({
          ...errorEvaluatorProps,
          driversById,
          fieldSpecsById,
          attributesById,
          attributesBySubDriverId,
          objectsByFieldId,
          objectsById,
          submodelIdByBlockId,
          rawFormula,
          skipDependencyLoopCheck: true,
        })
      : undefined;
  const errStart = formulaError?.charPosition;

  const block = content.getFirstBlock();
  let newContent = content;

  let errLocation = draftErrorLocation(newContent, driversById, errStart);
  newContent = Modifier.removeInlineStyle(
    newContent,
    getSelectionState(block.getKey(), 0, newContent.getFirstBlock().getLength()),
    'UNDERLINE',
  );
  newContent = Modifier.removeInlineStyle(
    newContent,
    getSelectionState(block.getKey(), 0, newContent.getFirstBlock().getLength()),
    'RED',
  );

  if (errLocation != null) {
    const length = formulaError?.length ?? 1;
    const text = block.getText();
    if (errLocation >= text.length || errLocation < 0) {
      // Showing underline at the end requires adding spaces.
      // Since the error message is descriptive in these cases won't add underlining for now
      return newContent;
    } else if (block.getEntityAt(errLocation) != null) {
      if (text.charAt(errLocation - 1) === ' ') {
        errLocation = errLocation - 1;
      } else {
        newContent = Modifier.insertText(
          newContent,
          getSelectionState(block.getKey(), errLocation),
          ' ',
        );
      }
    }

    // TODO: For some reason, the lexing error being handled in
    // handleModelingError sometimes has a chartPositionInLine which doesn't
    // correspond to the start of the text in the token recognition error. This
    // results in the location and length not lining up exactly. The
    // handleModelingError doesn't have all of the text to detect this, it only
    // has what hasn't been consumed yet so this can't be accounted for there.
    const end = Math.min(errLocation + length, text.length - 1);

    newContent = Modifier.applyInlineStyle(
      newContent,
      getSelectionState(block.getKey(), errLocation, end),
      'UNDERLINE',
    );
    newContent = Modifier.applyInlineStyle(
      newContent,
      getSelectionState(block.getKey(), errLocation, end),
      'RED',
    );
  }

  return newContent;
}

export function removeHighlightingFromQuery(e: EditorState, query: string, offset: number) {
  let newContent = e.getCurrentContent();
  const block = newContent.getFirstBlock();
  newContent = Modifier.removeInlineStyle(
    newContent,
    getSelectionState(block.getKey(), offset, offset + query.length),
    'UNDERLINE',
  );
  newContent = Modifier.removeInlineStyle(
    newContent,
    getSelectionState(block.getKey(), offset, offset + query.length),
    'RED',
  );
  return EditorState.set(e, {
    currentContent: newContent,
  });
}

export function updateErrorHighlighting({
  editorState,
  ...props
}: ErrorHighlightingBaseProps & { editorState: EditorState }): EditorState {
  const content = editorState.getCurrentContent();
  return EditorState.set(editorState, {
    currentContent: addErrorHighlightingToContent({
      content,
      ...props,
    }),
  });
}

export function findDifferenceIndex(str1: string, str2: string): number {
  const len = Math.min(str1.length, str2.length);
  for (let i = 0; i < len; i++) {
    const codePoint1 = str1.codePointAt(i);
    if (codePoint1 !== str2.codePointAt(i)) {
      return i;
    }

    // If current code point is part of a surrogate pair, skip the next index
    // Code points above 0xFFFF are represented using surrogate pairs in UTF-16
    if (codePoint1 != null && codePoint1 > 0xffff) {
      i++;
    }
  }

  // If one string is a prefix of the other, return the length of the shorter string
  return len < str1.length || len < str2.length ? len : -1;
}

export function queryIsNumeric(query: string) {
  return NUMBER_REGEX.test(query) || DECIMAL_REGEX.test(query);
}

export function queryIsDateTime(query: string) {
  return MONTH_KEY_REGEX.test(query) || DATE_REGEX.test(query);
}

export function queryIsObjectQuery(query: string) {
  return OBJECT_REGEX.test(query);
}

export function getObjectRefLabel(
  specName: string,
  fieldSpecName: string | null | undefined,
  isThisRef: boolean,
): string {
  const nameWithoutEmoji = extractEmoji(specName)[1];
  return `${isThisRef ? 'This ' : ''}${nameWithoutEmoji}${
    fieldSpecName != null ? `.${fieldSpecName}` : ''
  }`;
}
