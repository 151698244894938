import { createCachedSelector } from 're-reselect';

import {
  addLayerParams,
  getCacheKeyForLayerSelector,
  SelectorWithLayerParam,
} from 'helpers/layerSelectorFactory';
import { Driver, DriverType } from 'reduxStore/models/drivers';
import { denormalizeDimensionalDriver } from 'reduxStore/reducers/helpers/drivers';
import { attributesByIdSelector, dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { driverEntityTableForLayerSelector } from 'selectors/driverEntityTableSelector';

export const driversWithoutStandInForLayerSelector: SelectorWithLayerParam<Driver[]> =
  createCachedSelector(
    addLayerParams(driverEntityTableForLayerSelector),
    addLayerParams(dimensionsByIdSelector),
    addLayerParams(attributesByIdSelector),
    (drivers, dimensionsById, attributesById) => {
      return drivers.allIds.map((id) => {
        const driver = drivers.byId[id];
        if (driver.type !== DriverType.Dimensional) {
          return driver;
        }
        return denormalizeDimensionalDriver(driver, dimensionsById, attributesById);
      });
    },
  )(getCacheKeyForLayerSelector);
