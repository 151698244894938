import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'AzureIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <path
        d="M20.8362 4.09H37.11L20.2162 54.1444C20.0426 54.6587 19.7121 55.1057 19.2711 55.4223C18.8301 55.7389 18.301 55.9093 17.7581 55.9094H5.09312C4.68182 55.9094 4.27641 55.8115 3.91044 55.6238C3.54447 55.4361 3.22846 55.1639 2.98855 54.8298C2.74864 54.4957 2.59172 54.1093 2.53079 53.7026C2.46985 53.2958 2.50664 52.8804 2.63812 52.4906L18.3775 5.855C18.5511 5.34044 18.8817 4.8933 19.3228 4.57655C19.7639 4.2598 20.2932 4.09003 20.8362 4.09Z"
        fill="url(#paint0_linear_48_351)"
      />
      <path
        d="M44.4844 37.6631H18.6781C18.4382 37.6629 18.2038 37.7349 18.0053 37.8697C17.8069 38.0046 17.6537 38.1961 17.5657 38.4193C17.4777 38.6425 17.4589 38.887 17.5119 39.121C17.5648 39.355 17.687 39.5677 17.8625 39.7312L34.445 55.2087C34.9278 55.6591 35.5635 55.9095 36.2237 55.9094H50.8362L44.4844 37.6631Z"
        fill="#0078D4"
      />
      <path
        d="M20.8362 4.09C20.2873 4.08789 19.752 4.26137 19.3086 4.5851C18.8653 4.90883 18.537 5.36584 18.3719 5.88938L2.65749 52.4481C2.51716 52.8393 2.47311 53.2585 2.52905 53.6702C2.58499 54.082 2.73928 54.4742 2.97888 54.8138C3.21847 55.1533 3.53631 55.4301 3.90552 55.6208C4.27473 55.8115 4.68444 55.9105 5.09999 55.9094H18.0919C18.5757 55.8229 19.028 55.6096 19.4024 55.2911C19.7769 54.9727 20.0601 54.5606 20.2231 54.0969L23.3569 44.8613L34.5506 55.3019C35.0197 55.6899 35.6081 55.9044 36.2169 55.9094H50.775L44.39 37.6631L25.7769 37.6675L37.1687 4.09H20.8362Z"
        fill="url(#paint1_linear_48_351)"
      />
      <path
        d="M41.6219 5.8525C41.4486 5.33878 41.1185 4.89237 40.678 4.57618C40.2376 4.25998 39.7091 4.08994 39.1669 4.09H21.03C21.5722 4.09003 22.1007 4.26011 22.5411 4.57629C22.9815 4.89247 23.3116 5.33883 23.485 5.8525L39.225 52.49C39.3566 52.8798 39.3935 53.2953 39.3326 53.7022C39.2717 54.1091 39.1148 54.4956 38.8749 54.8298C38.635 55.164 38.319 55.4363 37.953 55.6242C37.5869 55.812 37.1814 55.91 36.77 55.91H54.9075C55.3189 55.9099 55.7243 55.8118 56.0902 55.624C56.4562 55.4361 56.7721 55.1638 57.012 54.8296C57.2518 54.4954 57.4087 54.1089 57.4695 53.7021C57.5303 53.2952 57.4935 52.8798 57.3619 52.49L41.6219 5.8525Z"
        fill="url(#paint2_linear_48_351)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_48_351"
          x1="26.7675"
          y1="7.93"
          x2="9.86687"
          y2="57.8588"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#114A8B" />
          <stop offset="1" stopColor="#0669BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_48_351"
          x1="32.0469"
          y1="31.1981"
          x2="28.1375"
          y2="32.52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.3" />
          <stop offset="0.071" stopOpacity="0.2" />
          <stop offset="0.321" stopOpacity="0.1" />
          <stop offset="0.623" stopOpacity="0.05" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_48_351"
          x1="29.8969"
          y1="6.47375"
          x2="48.4488"
          y2="55.8994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CCBF4" />
          <stop offset="1" stopColor="#2892DF" />
        </linearGradient>
      </defs>
    </>
  ),
});
