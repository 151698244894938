import { ScaleLinear } from 'd3-scale';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';

import { DEFAULT_DRIVER_CHART_SIZE } from 'config/block';
import {
  DEFAULT_MAX_VALUE_BY_DRIVER_FORMAT,
  DRIVER_CHART_MARGINS_BY_SIZE,
  DriverChartDatum,
} from 'config/driverChart';
import { Y_AXIS_OVERHEAD_FACTOR, Y_AXIS_OVERHEAD_FACTOR_NEGATIVE } from 'config/planTimeline';
import { BlockViewOptions, ChartSize } from 'generated/graphql';
import { getDateTimeFromMonthKey } from 'helpers/dates';
import { DriverFormat } from 'reduxStore/models/drivers';
import { NumericTimeSeries } from 'reduxStore/models/timeSeries';
import { isMultiDriverChart } from 'reduxStore/reducers/helpers/viewOptions';
import { MonthKey } from 'types/datetime';

export const roundChartValue = (
  value: number,
  valueScale: ScaleLinear<number, number, never>,
  format: DriverFormat,
): number => {
  const [minValue, maxValue] = valueScale.domain();
  const greatestMagnitudeValue = Math.max(Math.abs(minValue), Math.abs(maxValue));
  const orderOfMagnitude = Math.floor(Math.log(greatestMagnitudeValue) / Math.LN10);

  // round 10-100 to the tens place, 1-10 to the ones place, etc.
  let roundingPlaces = -orderOfMagnitude + 1;
  if (format === DriverFormat.Percentage) {
    roundingPlaces = Math.max(2, roundingPlaces);
  }

  return round(value, roundingPlaces);
};

export const getX = (d: DriverChartDatum) => d.x;
export const getY = (d: DriverChartDatum) => d.y;

export const timeSeriesToChartData = (
  timeSeries: NumericTimeSeries,
  monthKeys: MonthKey[],
  format: DriverFormat,
  pad = false,
): DriverChartDatum[] => {
  let chartData = monthKeys.map((monthKey) => ({
    x: getDateTimeFromMonthKey(monthKey).endOf('month').startOf('second').toJSDate(),
    y:
      format === DriverFormat.Integer && timeSeries[monthKey] != null
        ? round(timeSeries[monthKey])
        : timeSeries[monthKey],
    monthKey,
  }));

  if (!pad) {
    chartData = chartData.filter(({ y }) => y != null && Number.isFinite(y)) as DriverChartDatum[];
  }

  return sortBy(chartData, (datum) => datum.monthKey);
};

export const getMinMaxVals = (
  allValues: number[],
  format: DriverFormat,
  { addHeadroom }: { addHeadroom?: boolean } = { addHeadroom: true },
): { minValue: number; maxValue: number } => {
  if (!addHeadroom) {
    return {
      minValue: Math.min(...allValues),
      maxValue: Math.max(...allValues),
    };
  }

  const overheadFactor = Y_AXIS_OVERHEAD_FACTOR;
  const overheadFactorNegative = Y_AXIS_OVERHEAD_FACTOR_NEGATIVE;

  const minValue = Math.min(...allValues, 0) * overheadFactor;
  const hasNonZeroValue = allValues.some((value) => value !== 0);
  const minimumMaxValue =
    format === DriverFormat.Integer || !hasNonZeroValue
      ? DEFAULT_MAX_VALUE_BY_DRIVER_FORMAT[format]
      : minValue;
  const unadjustedMax = Math.max(...allValues);
  const maxValue = Math.max(
    unadjustedMax < 0 ? unadjustedMax * overheadFactorNegative : unadjustedMax * overheadFactor,
    minimumMaxValue,
  );

  return { minValue, maxValue };
};

// MEDIUM_BASE_WIDTH / ASPECT_RATIO = 70
const ASPECT_RATIO = 4.8;
const MEDIUM_BASE_WIDTH = 336;
const MEDIUM_BASE_HEIGHT = MEDIUM_BASE_WIDTH / ASPECT_RATIO;

const MEDIUM_MARGINS = DRIVER_CHART_MARGINS_BY_SIZE[ChartSize.Medium];
const LARGE_MARGINS = DRIVER_CHART_MARGINS_BY_SIZE[ChartSize.Medium];

const MEDIUM_HEIGHT = MEDIUM_BASE_HEIGHT + MEDIUM_MARGINS.top + MEDIUM_MARGINS.bottom;
const MEDIUM_WIDTH = MEDIUM_BASE_WIDTH + MEDIUM_MARGINS.left + MEDIUM_MARGINS.right;

// This number was landed on such that the total driver chart card was 720px in
// width and so that we have enough space for the veritcal axis.
// LARGE_BASE_WIDTH / ASPECT_RATIO = 145
const LARGE_BASE_WIDTH = 696;
const LARGE_BASE_HEIGHT = LARGE_BASE_WIDTH / ASPECT_RATIO;

const LARGE_HEIGHT = LARGE_BASE_HEIGHT + LARGE_MARGINS.top + LARGE_MARGINS.bottom;
const LARGE_WIDTH = LARGE_BASE_WIDTH + LARGE_MARGINS.left + LARGE_MARGINS.right;

const EXTRA_LARGE_WIDTH = 1100;
const EXTRA_LARGE_HEIGHT = EXTRA_LARGE_WIDTH / ASPECT_RATIO;

export const LEGEND_SIDEBAR_WIDTH = 280;
const HEADER_SIZE = 70;

const WIDTHS_BY_SIZE: Record<ChartSize, number> = {
  [ChartSize.Medium]: MEDIUM_WIDTH,
  [ChartSize.Large]: LARGE_WIDTH,
  [ChartSize.ExtraLarge]: EXTRA_LARGE_WIDTH,
};

const HEIGHTS_BY_SIZE: Record<ChartSize, number> = {
  [ChartSize.Medium]: MEDIUM_HEIGHT,
  [ChartSize.Large]: LARGE_HEIGHT,
  [ChartSize.ExtraLarge]: EXTRA_LARGE_HEIGHT,
};

export const getChartDimensions = (viewOptions: BlockViewOptions) => {
  const size = viewOptions?.chartSize ?? DEFAULT_DRIVER_CHART_SIZE;
  const multi = isMultiDriverChart(viewOptions);

  const widthSize = WIDTHS_BY_SIZE[size] ?? WIDTHS_BY_SIZE[DEFAULT_DRIVER_CHART_SIZE];
  const heightSize = HEIGHTS_BY_SIZE[size] ?? HEIGHTS_BY_SIZE[DEFAULT_DRIVER_CHART_SIZE];
  const marginSize =
    DRIVER_CHART_MARGINS_BY_SIZE[size] ?? DRIVER_CHART_MARGINS_BY_SIZE[DEFAULT_DRIVER_CHART_SIZE];

  const chartWidth = widthSize - (multi ? LEGEND_SIDEBAR_WIDTH : 0);
  const chartHeight = heightSize + (multi ? HEADER_SIZE : 0);
  const chartMargins = marginSize;

  const xMax = chartWidth - chartMargins.left - chartMargins.right;
  const yMax = chartHeight - chartMargins.top - chartMargins.bottom;

  return {
    xMax,
    yMax,
    chartMargins,
    chartWidth,
    chartHeight,
  };
};
