import { getCSSVar, useTheme } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { useBGColorForCell } from 'hooks/useBgColorForCell';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';

import styles from './RowActionsContainer.module.scss';

interface Props {
  children?: React.ReactNode;
  alwaysVisibleChildren?: React.ReactNode;
}

const RowActionsContainer: React.FC<Props> = ({ children, alwaysVisibleChildren }) => {
  const { isHighlighted } = useCellSelectionStateContext();
  const colorToUse = useBGColorForCell();
  const theme = useTheme();
  const styleObject = useMemo(() => {
    const bgColorVar = getCSSVar(theme, 'colors', colorToUse) as string;
    return { backgroundColor: bgColorVar };
  }, [colorToUse, theme]);
  const alwaysVisible = alwaysVisibleChildren != null;

  return (
    <div
      className={classNames(styles.rowActionsContainer, {
        [styles.notVisible]: !alwaysVisible,
      })}
    >
      <div
        className={classNames(styles.rowActions, {
          [styles.isHighlighted]: isHighlighted,
        })}
        style={styleObject}
      >
        {children}
      </div>
      {alwaysVisible && (
        <div className={styles.alwaysVisibleChildrenContainer} style={styleObject}>
          {alwaysVisibleChildren}
        </div>
      )}
    </div>
  );
};

export default RowActionsContainer;
