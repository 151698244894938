import { Flex, Skeleton } from '@chakra-ui/react';
import React, { useContext } from 'react';

import { RenderModeContext } from 'components/List/enhanceComponent';
import TimeSeriesCellWrapperLight from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapperLight';

interface Props {
  color: string;
  width: number;
}
const LoadingNumericTimeSeriesCell: React.FC<Props> = ({ color, width }) => {
  const renderMode = useContext(RenderModeContext);
  return (
    <TimeSeriesCellWrapperLight color={color} width={width}>
      {renderMode !== 'FULL' ? (
        <Flex alignItems="center" justifyContent="flex-end" pr={2} height="full">
          {/** Don't use Chakra skeleton in light mode. Animation is expensive */}
          <Flex width="50%" height={3} backgroundColor="gray.200" />
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="flex-end" pr={2} height="full">
          <Skeleton startColor="gray.200" endColor="gray.300" width="50%" height={3} />
        </Flex>
      )}
    </TimeSeriesCellWrapperLight>
  );
};

export default React.memo(LoadingNumericTimeSeriesCell);
