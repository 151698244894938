import { RefObject, useEffect, useMemo, useRef } from 'react';

import { ScrollHandler, StickyContextState } from 'components/StickyHeader/StickyContext';
import { isDevelopment } from 'helpers/environment';
import { useIsScrolling } from 'hooks/useIsScrolling';

const handlerOpts: AddEventListenerOptions = {
  capture: false,
  passive: true,
};

// inspired by https://github.com/streamich/react-use/blob/master/src/useScroll.ts
// sets state immediately instead of doing so within a `requestAnimationFrame`
const useStickyHeaderContextState = (pageContentRef: RefObject<HTMLDivElement>) => {
  if (isDevelopment) {
    if (typeof pageContentRef !== 'object' || typeof pageContentRef.current === 'undefined') {
      console.error('`useBlockingScrollY` expects a single ref argument.');
    }
  }

  const { setIsScrolling, getIsScrolling } = useIsScrolling();

  const subscribers = useRef<ScrollHandler[]>([]);
  const context = useMemo<StickyContextState>(
    () => ({
      verticalScrollingTargetRef: pageContentRef,
      subscribe: (handler) => {
        subscribers.current = subscribers.current.concat(handler);
      },
      unsubscribe: (handler) => {
        subscribers.current = subscribers.current.filter((h) => h !== handler);
      },
      getIsScrolling,
    }),
    [getIsScrolling, pageContentRef],
  );

  useEffect(() => {
    // see https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event#scroll_event_throttling
    let lastKnownScrollPosition = 0;
    let ticking = false;

    const element = pageContentRef.current;
    function scrollHandler() {
      if (element == null) {
        return;
      }
      lastKnownScrollPosition = element.scrollTop;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          subscribers.current.forEach((handler) => {
            handler(lastKnownScrollPosition);
          });
          setIsScrolling();
          ticking = false;
        });

        ticking = true;
      }
    }
    if (element != null) {
      element.addEventListener('scroll', scrollHandler, handlerOpts);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', scrollHandler, handlerOpts);
      }
    };
  }, [pageContentRef, setIsScrolling]);

  return context;
};

export default useStickyHeaderContextState;
