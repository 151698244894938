import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, ButtonProps, Flex, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import { RefObject } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import useControlledPopover from 'hooks/useControlledPopover';

const OptionRows: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Flex flexDir="column" rowGap={1} py={1}>
      {children}
    </Flex>
  );
};

interface OptionRowMenuButtonProps {
  hideChevron?: boolean;
  width: string;
  leftIcon?: ButtonProps['leftIcon'];
  currentValue: string;
}

const OptionRowMenuButton: React.FC<
  OptionRowMenuButtonProps & { triggerRef: RefObject<HTMLDivElement> }
> = ({ triggerRef, hideChevron, width, leftIcon, currentValue }) => {
  return (
    <MenuButton
      ref={triggerRef}
      as={Button}
      rightIcon={!hideChevron ? <ChevronDownIcon /> : undefined}
      size="xs"
      fontSize="xs"
      flexDir="row"
      alignItems="center"
      justifyContent="flex-start"
      width={width}
      h="1.75rem"
      leftIcon={leftIcon}
    >
      <Flex flexDir="row" alignItems="center" justifyContent="flex-start" columnGap={1}>
        {currentValue}
      </Flex>
    </MenuButton>
  );
};

interface OptionRowProps {
  title?: string;
  currentValue: string;
  leftIcon?: ButtonProps['leftIcon'];
  hideChevron?: boolean;
  allowedClickSelector?: string;
  width?: string;
}

export const OptionPopoverRow: React.FC<OptionRowProps & { children?: React.ReactNode }> = ({
  currentValue,
  title,
  children,
  leftIcon,
  hideChevron = false,
  allowedClickSelector,
  width = '8.5rem',
}) => {
  const { contentRef, triggerRef } = useControlledPopover({
    selector: allowedClickSelector,
  });

  return (
    <Flex alignItems="center" justifyContent="space-between">
      {title != null && <Text>{title}</Text>}
      <Menu variant="light" isLazy>
        <>
          <OptionRowMenuButton {...{ triggerRef, hideChevron, width, leftIcon, currentValue }} />
          <MenuList ref={contentRef}>{children}</MenuList>
        </>
      </Menu>
    </Flex>
  );
};

interface MenuRowProps<T> {
  items: T[];
  onSelectItem: (item: T) => void;
}

export const OptionMenuRow = <T extends SelectItem>({
  currentValue,
  title,
  leftIcon,
  hideChevron = false,
  allowedClickSelector,
  width = '8.5rem',
  items,
  onSelectItem,
}: OptionRowProps & MenuRowProps<T>) => {
  const { contentRef, triggerRef } = useControlledPopover({
    selector: allowedClickSelector,
  });

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text>{title}</Text>
      <Menu variant="light" isLazy closeOnSelect>
        {({ onClose }) => (
          <>
            <OptionRowMenuButton {...{ triggerRef, hideChevron, width, leftIcon, currentValue }} />
            <MenuList ref={contentRef}>
              <SelectMenu
                items={items}
                onSelect={(item) => {
                  onSelectItem?.(item);
                  onClose();
                }}
                width="unset"
              >
                {BaseSelectMenuItem}
              </SelectMenu>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
};

export default OptionRows;
