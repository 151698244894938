import { ComponentSingleStyleConfig } from '@chakra-ui/theme';

export default {
  baseStyle: {
    padding: 0,
    margin: 0,
    borderColor: 'gray.300',
    opacity: 1,
  },
  defaultProps: {
    variant: 'solid' as unknown,
  },
} as ComponentSingleStyleConfig;
