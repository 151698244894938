import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'DeltaIcon',
  viewBox: '0 0 16 16',
  defaultProps: { boxSize: 5 },
  path: (
    <g fill="currentColor">
      <path d="M2 3.09091V12.9091C2 13.5118 2.48818 14 3.09091 14H12.9091C13.5118 14 14 13.5118 14 12.9091V3.09091C14 2.48818 13.5118 2 12.9091 2H3.09091C2.48818 2 2 2.48818 2 3.09091Z" />
      <path
        d="M5 11L8 5L11 11H5Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});
