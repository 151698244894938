import { Flex, Text } from '@chakra-ui/react';

import { Layer } from 'reduxStore/models/layers';
import Branches from 'vectors/Branches';

export const IdenticalLayersBlock = ({
  currentLayer,
  mergeLayer,
}: {
  currentLayer: Layer | null;
  mergeLayer: Layer;
}) => {
  return (
    <Flex
      width="full"
      flexDir="column"
      rowGap={4}
      justify="center"
      align="center"
      py={4}
      pr={8}
      flexGrow={1}
    >
      <Flex
        color="gray.600"
        bgColor="gray.200"
        p={1}
        align="center"
        justify="center"
        borderRadius="md"
      >
        <Branches boxSize={5} />
      </Flex>
      <Flex flexDir="column" rowGap={2} justify="center" align="center">
        <Text fontSize="lg" fontWeight="bold">
          Nothing to merge
        </Text>
        <Text fontSize="xxs" fontWeight="medium" color="gray.500">
          {currentLayer != null ? (
            <>
              <LayerName layer={currentLayer} /> and <LayerName layer={mergeLayer} /> are identical.
            </>
          ) : (
            <>
              Only <LayerName layer={mergeLayer} /> is selected.
            </>
          )}
        </Text>
      </Flex>
    </Flex>
  );
};

const LayerName = ({ layer }: { layer: Layer }) => {
  return (
    <Text as="span" fontWeight="semibold">
      {layer.name}
    </Text>
  );
};
