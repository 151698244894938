import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'TextStyle',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <path d="M7.98433 2.1187C7.93336 2.11992 7.88253 2.1248 7.83225 2.13329H3.73329H3.19996C2.90556 2.13329 2.66663 2.37222 2.66663 2.66662V4.26662C2.66663 4.56102 2.90556 4.79995 3.19996 4.79995H3.73329C4.02769 4.79995 4.26663 4.56102 4.26663 4.26662V3.73329H6.93329V12.2666H6.39996C6.10556 12.2666 5.86663 12.5056 5.86663 12.8V13.3333C5.86663 13.6277 6.10556 13.8666 6.39996 13.8666H7.82704C7.94155 13.8854 8.05837 13.8854 8.17288 13.8666H9.59996C9.89436 13.8666 10.1333 13.6277 10.1333 13.3333V12.8C10.1333 12.5056 9.89436 12.2666 9.59996 12.2666H9.06663V3.73329H11.7333V4.26662C11.7333 4.56102 11.9722 4.79995 12.2666 4.79995H12.8C13.0944 4.79995 13.3333 4.56102 13.3333 4.26662V2.66662C13.3333 2.37222 13.0944 2.13329 12.8 2.13329H12.2666H8.17079C8.10919 2.12298 8.04679 2.1181 7.98433 2.1187Z" />
  ),
});
