import { isNotNull } from 'helpers/typescript';

export interface NameDeduper {
  dedupe: (name: string) => string;
  add: (name: string) => void;
}

const COPY_REGEX = / Copy ?\d*$/;
/**
 * @param name Any string that may or may not already be appended with a `Copy`
 *    suffix slug (e.g. Copy`, `Copy 2`)
 * @param otherNames Other strings of the same variety as `name`.
 * @returns A new string with the `Copy N` suffix added with an appropriate value for N.
 */
export function getNameWithCopySuffix(name: string, otherNames: string[]): string {
  const nameSansCopySuffix = name.replace(COPY_REGEX, '');
  const dedupeName = nameSansCopySuffix.length === 0 ? 'Copy' : `${nameSansCopySuffix} Copy`;
  return newNameDeduper([name, ...otherNames]).dedupe(dedupeName);
}

const NUMBER_SUFFIX_REGEX = /^(.*?) (\d+)$/;
export function newNameDeduper(names: string[]): NameDeduper {
  // When deduping large lists of names we want to avoid expensive searches through
  // the list of existing names for each subsequent name.
  //
  // To do this we keep a map of the names we've seen so far and the highest number.
  // Alternatively, we could keep a list of names associated with every number suffix,
  // but then you need to search through those numbers which adds log(n) to the complexity.
  const namesToNumber = new Map<string, number>();
  const add = (name: string) => {
    const lowerCaseName = name.toLowerCase();
    const match = lowerCaseName.match(NUMBER_SUFFIX_REGEX);

    if (match) {
      const lastNumber = namesToNumber.get(match[1]) ?? -1;
      const newNumber = parseInt(match[2], 10);
      namesToNumber.set(match[1], Math.max(lastNumber, newNumber));
      return;
    }

    if (!namesToNumber.has(lowerCaseName)) {
      namesToNumber.set(lowerCaseName, 1);
    }
  };

  names.forEach(add);

  return {
    dedupe: (name: string) => {
      const lowerCase = name.toLowerCase();
      if (!namesToNumber.has(lowerCase)) {
        return name;
      }
      const num = namesToNumber.get(lowerCase);
      return `${name} ${num + 1}`;
    },
    add,
  };
}

export function getNewDefaultName(defaultName: string, nameSet: Set<string>): string {
  if (!nameSet.has(defaultName)) {
    return defaultName;
  }

  let i = 2;
  while (nameSet.has(`${defaultName} ${i}`)) {
    i++;
  }

  return `${defaultName} ${i}`;
}

export function getInitialsFromName(name: string): string {
  const allInitials = name
    .trim()
    .split(/\s+/)
    .map((part) => (part.length > 0 ? part[0].toUpperCase() : null))
    .filter(isNotNull);
  const firstInitial = allInitials[0] ?? '';
  return allInitials.length >= 2
    ? `${firstInitial}${allInitials[allInitials.length - 1]}`
    : firstInitial;
}

export function checkNameIsUniqueCaseInsensitive(name: string, allNames: string[]) {
  const sanitizedName = name.toLowerCase().trim();
  return !allNames.some((n) => n.toLowerCase() === sanitizedName);
}
