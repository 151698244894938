import { createSelector } from '@reduxjs/toolkit';

import { PLAN_DETAIL_PAGE_TYPE } from 'config/internalPages/planDetailPage';
import { exhaustiveGuard } from 'helpers/exhaustiveGuard';
import { isNotNull } from 'helpers/typescript';
import { Block, BlockId } from 'reduxStore/models/blocks';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { MutationBatch } from 'reduxStore/models/mutations';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blocksPageByInternalPageTypeSelector } from 'selectors/blocksPagesTableSelector';
import { blocksByIdSelector, sortedBlockIdsForCurrentPageSelector } from 'selectors/blocksSelector';
import { isCompareScenariosModalOpenSelector } from 'selectors/compareScenariosModalSelector';
import { businessObjectDetailBlockIdSelector } from 'selectors/databaseSelector';
import { openDetailPaneTypeSelector } from 'selectors/detailPaneSelectors';
import { currentLayerIdSelector, layersSelector } from 'selectors/layerSelector';
import { objectDetailPaneObjectSpecIdSelector } from 'selectors/objectDetailPaneSelector';
import {
  scenarioComparisonPageDatabaseBlockIdSelector,
  scenarioComparisonPageDriverBlockIdSelector,
} from 'selectors/scenarioComparisonSelector';
import { currentSubmodelPageSelector } from 'selectors/submodelPageSelector';
import { OptionalParametricSelector, ParametricSelector, Selector } from 'types/redux';

const EMPTY_BLOCK_IDS: BlockId[] = [];

const visibleComparisonBlocksBlockIdSelector: Selector<BlockId[]> = createSelector(
  isCompareScenariosModalOpenSelector,
  scenarioComparisonPageDriverBlockIdSelector,
  scenarioComparisonPageDatabaseBlockIdSelector,
  (compareScenariosModalIsOpen, comparisonDriverBlockId, comparisonObjectBlockId) => {
    if (compareScenariosModalIsOpen) {
      return [comparisonDriverBlockId, comparisonObjectBlockId].filter(isNotNull);
    }

    return EMPTY_BLOCK_IDS;
  },
);

const visibleObjectDetailBlockIdSelector: Selector<BlockId | null> = createSelector(
  (state: RootState) => state,
  objectDetailPaneObjectSpecIdSelector,
  (state, objectSpecId) => {
    if (objectSpecId == null) {
      return null;
    }

    return businessObjectDetailBlockIdSelector(state, objectSpecId);
  },
);

const visibleBlocksForDetailPaneSelector: Selector<BlockId[]> = createSelector(
  visibleObjectDetailBlockIdSelector,
  openDetailPaneTypeSelector,
  blocksPageByInternalPageTypeSelector,
  currentSubmodelPageSelector,
  (objectDetailBlockId, openDetailPaneType, blocksPageByInternalPageType, currentSubmodelPage) => {
    if (openDetailPaneType == null) {
      return EMPTY_BLOCK_IDS;
    }
    switch (openDetailPaneType) {
      case PaneType.Driver:
      case PaneType.DimensionalDriver: {
        return currentSubmodelPage?.blockIds ?? EMPTY_BLOCK_IDS;
      }
      case PaneType.Object: {
        return objectDetailBlockId != null ? [objectDetailBlockId] : EMPTY_BLOCK_IDS;
      }
      case PaneType.Plan: {
        return blocksPageByInternalPageType[PLAN_DETAIL_PAGE_TYPE]?.blockIds ?? EMPTY_BLOCK_IDS;
      }
      default: {
        exhaustiveGuard(openDetailPaneType);
        return EMPTY_BLOCK_IDS;
      }
    }
  },
);

const visibleBlockIdsSelector: Selector<BlockId[]> = createSelector(
  sortedBlockIdsForCurrentPageSelector,
  currentSubmodelPageSelector,
  visibleBlocksForDetailPaneSelector,
  visibleComparisonBlocksBlockIdSelector,
  (blockIds, currentSubmodelPage, detailPaneBlockIds, comparisonPaneBlockIds) => {
    const currentBlockIds: BlockId[] = [];
    if (currentSubmodelPage) {
      currentSubmodelPage.blockIds.forEach((id) => currentBlockIds.push(id));
    }

    comparisonPaneBlockIds.forEach((id) => currentBlockIds.push(id));
    detailPaneBlockIds.forEach((id) => currentBlockIds.push(id));

    blockIds.forEach((id) => currentBlockIds.push(id));
    return currentBlockIds;
  },
);

const visibleBlocksSelector: Selector<Block[]> = createSelector(
  blocksByIdSelector,
  visibleBlockIdsSelector,
  (blocksById, visibleBlockIds) => {
    return visibleBlockIds.map((id) => blocksById[id]).filter((block) => block != null);
  },
);

export const activeComparisonLayerIdsSelector: OptionalParametricSelector<boolean, string[]> =
  createSelector(
    visibleBlocksSelector,
    currentLayerIdSelector,
    layersSelector,
    (state: RootState, includeLockedVersions?: boolean) =>
      includeLockedVersions == null ? false : includeLockedVersions,
    (blocks, currentLayerId, layers, includeLockedVersions) => {
      const layerIdSet = new Set<string>([DEFAULT_LAYER_ID, currentLayerId]);
      blocks.forEach((block) => {
        block.blockConfig.comparisons?.layerIds?.forEach((layerId) => {
          const layer = layers[layerId];
          if (
            layer != null &&
            !layer.isDeleted &&
            (includeLockedVersions ? true : layer.lockedMutationId == null)
          ) {
            layerIdSet.add(layerId);
          }
        });
      });
      return Array.from(layerIdSet);
    },
  );

export const activeComparisonLayerIdsForMutationBatchSelector: ParametricSelector<
  MutationBatch,
  string[]
> = createSelector(
  (state: RootState) => activeComparisonLayerIdsSelector(state),
  (state: RootState, mutationBatch: MutationBatch) => mutationBatch,
  (visibleLayerIds, mutationBatch) => {
    const layerIdSet = new Set<string>(visibleLayerIds);
    // NB if making a new layer, add that to active layer Ids
    mutationBatch.mutation.newLayers?.map((layer) => layer.id).forEach((id) => layerIdSet.add(id));
    return Array.from(layerIdSet);
  },
);
