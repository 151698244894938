import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'RampIcon',
  viewBox: '0 0 225 225',
  path: (
    <>
      <path
        fill="#F5FE78"
        opacity="1.0000"
        d="M112.000000,226.000000 C74.666672,226.000000 37.833336,226.000000 1.000005,226.000000 C1.000003,151.000000 1.000003,76.000015 1.000002,1.000015 C75.999992,1.000010 150.999985,1.000010 225.999969,1.000005 C225.999985,75.999985 225.999985,150.999969 226.000000,225.999969 C188.166672,226.000000 150.333328,226.000000 112.000000,226.000000 M155.471130,100.927513 C157.914978,93.015388 160.488647,85.140388 162.765518,77.180504 C164.963104,69.497726 164.223022,62.840603 156.954895,57.519684 C152.145355,53.998672 148.325989,49.125114 144.396088,45.190250 C133.366364,105.695717 98.336327,141.075119 38.084278,151.378922 C38.108650,151.467316 38.102238,151.678284 38.204533,151.781372 C43.013130,156.627762 48.218327,161.146744 52.532246,166.398071 C56.354336,171.050735 60.718086,171.928741 66.121567,170.920685 C107.742393,163.155975 137.260925,139.870285 155.471130,100.927513 M140.499817,171.321869 C154.647186,171.321869 168.794540,171.321869 184.468842,171.321869 C177.229111,164.207504 171.280716,158.205246 165.111450,152.439331 C163.954147,151.357712 161.922653,150.755890 160.279785,150.725281 C151.471802,150.561081 142.658722,150.604691 133.848541,150.692032 C132.446457,150.705917 130.726761,150.894547 129.702454,151.699982 C121.584244,158.083496 113.598877,164.635925 105.343765,171.321884 C116.713516,171.321884 128.106644,171.321884 140.499817,171.321869 z"
      />
      <path
        fill="#020201"
        opacity="1.0000"
        d="M155.297974,101.268295 C137.260925,139.870285 107.742393,163.155975 66.121567,170.920685 C60.718086,171.928741 56.354336,171.050735 52.532246,166.398071 C48.218327,161.146744 43.013130,156.627762 38.204533,151.781372 C38.102238,151.678284 38.108650,151.467316 38.084278,151.378922 C98.336327,141.075119 133.366364,105.695717 144.396088,45.190250 C148.325989,49.125114 152.145355,53.998672 156.954895,57.519684 C164.223022,62.840603 164.963104,69.497726 162.765518,77.180504 C160.488647,85.140388 157.914978,93.015388 155.297974,101.268295 z"
      />
      <path
        fill="#010100"
        opacity="1.0000"
        d="M139.999802,171.321869 C128.106644,171.321884 116.713516,171.321884 105.343765,171.321884 C113.598877,164.635925 121.584244,158.083496 129.702454,151.699982 C130.726761,150.894547 132.446457,150.705917 133.848541,150.692032 C142.658722,150.604691 151.471802,150.561081 160.279785,150.725281 C161.922653,150.755890 163.954147,151.357712 165.111450,152.439331 C171.280716,158.205246 177.229111,164.207504 184.468842,171.321869 C168.794540,171.321869 154.647186,171.321869 139.999802,171.321869 z"
      />
    </>
  ),
});
