import { Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useContext, useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { SelectMenuContext } from 'components/SelectMenu/SelectMenuContext';
import SelectMenuFooter from 'components/SelectMenu/SelectMenuFooter';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { getAttributeValueString } from 'helpers/dimensionalDrivers';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import useExpandableDimensions from 'hooks/useExpandableDimensions';
import { expandDriverSelectionByDimension } from 'reduxStore/actions/driverExpandByDimensionMutation';
import DimensionIcon from 'vectors/Dimension';

type ItemType = SelectItem & { attributeNames: string[] };

const NUM_ATTR_ROWS = 4;
const DriverExpandByDimensionMenu: React.FC = () => {
  const dimensions = useExpandableDimensions();
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();

  const dimensionItems = useMemo<ItemType[]>(() => {
    return dimensions.map((d) => {
      const attrs = d.attributes.filter((a) => !a.deleted);
      const menuItem: ItemType = {
        id: d.id,
        key: d.id,
        name: d.name,
        meta: `${attrs.length} items`,
        attributeNames: attrs.map((a) => getAttributeValueString(a)),
        footer: (
          <SelectMenuFooter
            icon={<DimensionIcon />}
            title={d.name}
            subtitle={
              <Flex flexDir="column">
                <Text>Creates drivers from attributes in the dimension {d.name}, including:</Text>
                {attrs
                  .slice(0, attrs.length > NUM_ATTR_ROWS ? NUM_ATTR_ROWS - 1 : attrs.length)
                  .map((a) => (
                    <Text key={a.id}>• {a.value}</Text>
                  ))}
                {attrs.length > NUM_ATTR_ROWS && (
                  <Text key="remaining">• and {attrs.length - NUM_ATTR_ROWS - 1} more</Text>
                )}
              </Flex>
            }
          />
        ),
      };
      return menuItem;
    });
  }, [dimensions]);

  const { closeParentSelectMenu } = useContext(SelectMenuContext);
  const onSelect = useCallback(
    (item: ItemType) => {
      dispatch(expandDriverSelectionByDimension({ dimensionId: item.id, blockId }));
      closeParentSelectMenu?.();
    },
    [blockId, closeParentSelectMenu, dispatch],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu items={dimensionItems} onSelect={onSelect} width="20rem">
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default React.memo(DriverExpandByDimensionMenu);
