import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import max from 'lodash/max';
import min from 'lodash/min';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { createCachedSelector } from 're-reselect';

import { CellRowKey } from 'config/cells';
import { CANONICAL_MODEL_ORDER } from 'config/integrations';
import { ExtModel, IntegrationCategory } from 'generated/graphql';
import { monthKeyRange } from 'helpers/dates';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { getIdsOfExtDriverDependencies } from 'helpers/driverDependencies';
import {
  ExtDriverRow,
  extDriverEntriesByParentPathKey,
  getTotalNumberOfExternalDriverRows,
  getVisibleExtDriverRows,
  keyToPath,
} from 'helpers/extDriverRows';
import { extDriverDisplayPath } from 'helpers/extDrivers';
import { formulaEntitiesByIdForLayerSelector } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluatorHelpers';
import {
  ExtSource,
  IntegrationTab,
  getExtSource,
  groupIntegrationsByCategory,
  isMergeSource,
  isModelPartOfIntegrationTab,
  isWorkatoSource,
} from 'helpers/integrations';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { isNamedVersion } from 'helpers/namedVersions';
import { NameDeduper, newNameDeduper } from 'helpers/naming';
import { isNotNull } from 'helpers/typescript';
import { EntityTable } from 'reduxStore/models/common';
import { DriverId } from 'reduxStore/models/drivers';
import {
  ExtDriver,
  ExtDriverEntry,
  ExtDriverId,
  ExtDriverSource,
} from 'reduxStore/models/extDrivers';
import { IntegrationQueryId } from 'reduxStore/models/integrationQueries';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { ExtDriverExpansionState } from 'reduxStore/reducers/extDriverSlice';
import { isDataSourcePage } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { allAccountingAccountsSelector } from 'selectors/accountingPropertiesSelector';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';
import { paramSelector } from 'selectors/constSelectors';
import {
  integrationCategorySelector,
  navDataSourceSelector,
  navDataSourceSubPageSelector,
  navDataSourceSupportsQueryReadsSelector,
  navDataSourceSupportsQueryWritesSelector,
  navDataSourceWithAccountSelector,
} from 'selectors/dataSourceSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { integrationQueryNameByIdSelector } from 'selectors/integrationQueriesSelector';
import { availableIntegrationsSelector } from 'selectors/integrationsSelector';
import { enableMergeCustomQueriesSelector } from 'selectors/launchDarklySelector';
import { getGivenOrCurrentLayerId, layersSelector } from 'selectors/layerSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { MonthKey } from 'types/datetime';
import { ParametricSelector, Selector } from 'types/redux';

import { dependenciesListenerEvaluatorSelector } from './dependenciesListenerEvaluatorSelector';

const EMPTY_EXT_DRIVERS_TABLE: EntityTable<ExtDriver> = { byId: {}, allIds: [] };

const extDriversEntityTableForLayerSelector: SelectorWithLayerParam<EntityTable<ExtDriver>> =
  createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
    const layer = layers[layerId];
    if (isNamedVersion(layer)) {
      return layer.extDrivers;
    }

    const defaultLayer = layers[DEFAULT_LAYER_ID];
    return defaultLayer?.extDrivers ?? EMPTY_EXT_DRIVERS_TABLE;
  })(getCacheKeyForLayerSelector);

export const extDriversByIdSelector: SelectorWithLayerParam<Record<ExtDriverId, ExtDriver>> =
  createCachedSelector(
    addLayerParams(extDriversEntityTableForLayerSelector),
    function extDriversByIdSelector(extDriversTable) {
      return extDriversTable.byId;
    },
  )(getCacheKeyForLayerSelector);

export const extDriversSelector: SelectorWithLayerParam<ExtDriver[]> = createCachedSelector(
  addLayerParams(extDriversByIdSelector),
  function extDriversSelector(extDriversById) {
    return Object.values(extDriversById);
  },
)(getCacheKeyForLayerSelector);

// N.B.: This selector is pulling extDrivers from the current layer only, not from all layers.
export const extDriverSelector: ParametricSelector<ExtDriverId, ExtDriver | undefined> =
  createCachedSelector(
    (state) => extDriversByIdSelector(state),
    paramSelector<ExtDriverId>(),
    (extDriversById, id) => extDriversById[id],
  )((_state, id) => id);

// N.B.: This selector is pulling extDrivers from the current layer only, not from all layers.
export const extDriverDisplayPathSelector: ParametricSelector<ExtDriverId, string[]> =
  createCachedSelector(
    (state) => extDriversByIdSelector(state),
    paramSelector<ExtDriverId>(),
    integrationQueryNameByIdSelector,
    (extDriversById, id, integrationQueryNamesById) => {
      const extDriver = extDriversById[id];
      const path = extDriver != null ? extDriver.path : keyToPath(id);
      const displayPath = extDriverDisplayPath(path, integrationQueryNamesById);
      return displayPath;
    },
  )((_state, id) => id);

export const extDriverNameSelector: ParametricSelector<ExtDriverId, string> = createCachedSelector(
  extDriverDisplayPathSelector,
  (displayPath) => {
    return displayPath[displayPath.length - 1];
  },
)((_state, id) => id);

export const selectedIntegrationQueryIdSelector: Selector<IntegrationQueryId | undefined> =
  createSelector(navDataSourceSubPageSelector, (navSubPage) => {
    if (navSubPage?.type !== 'integrationQuery') {
      return undefined;
    }
    return navSubPage.selectedIntegrationQueryId;
  });

export const extDriversBySourceSelector: Selector<Map<ExtDriverSource, ExtDriver[]>> =
  createSelector(
    extDriversSelector,
    selectedIntegrationQueryIdSelector,
    (extDrivers, selectedIntegrationQueryId) => {
      const driversBySource = new Map<ExtDriverSource, ExtDriver[]>();
      extDrivers.forEach((extDriver) => {
        const isMatched = extDriver.integrationQueryId === selectedIntegrationQueryId;
        if (
          selectedIntegrationQueryId == null ||
          isMatched ||
          selectedIntegrationQueryId === 'formattedOutput'
        ) {
          const drivers = driversBySource.get(extDriver.source) ?? [];
          drivers.push(extDriver);
          driversBySource.set(extDriver.source, drivers);
        }
      });
      return driversBySource;
    },
  );

export const extDriversForNavSourceSelector: Selector<ExtDriver[]> = createSelector(
  extDriversBySourceSelector,
  navDataSourceWithAccountSelector,
  (extDriversBySource, sourceWithAccount) => {
    if (sourceWithAccount == null || sourceWithAccount.source == null) {
      return [];
    }
    const { source, accountId } = sourceWithAccount;
    return (extDriversBySource.get(source) ?? []).filter(
      (extDriver) =>
        accountId == null || extDriver.accountId == null || accountId === extDriver.accountId,
    );
  },
);

const navSourceExtDriverNamesSelector: Selector<string[]> = createSelector(
  extDriversForNavSourceSelector,
  (extDrivers) =>
    extDrivers
      .filter((extDriver) => extDriver.path.length > 0)
      .map((extDriver) => extDriver.path[extDriver.path.length - 1])
      .filter((name) => name != null),
);

export const navSourceExtDriverNameDeduper: Selector<NameDeduper['dedupe']> = createSelector(
  navSourceExtDriverNamesSelector,
  (allNames) => newNameDeduper(allNames).dedupe,
);

export const extDriverDependencyMatrixSelector: Selector<Record<DriverId, ExtDriverId[]>> =
  createSelector(
    driversByIdForLayerSelector,
    dependenciesListenerEvaluatorSelector,
    dimensionalPropertyEvaluatorSelector,
    (driversById, evaluator, dimensionalPropertyEvaluator) => {
      return Object.entries(driversById).reduce(
        (result, [driverId, driver]) => ({
          ...result,
          [driverId]:
            driver != null
              ? getIdsOfExtDriverDependencies({
                  driver,
                  evaluator,
                  dimensionalPropertyEvaluator,
                })
              : [],
        }),
        {} as Record<DriverId, ExtDriverId[]>,
      );
    },
  );

export const reverseExtDriverDependencyMatrixSelector: Selector<Record<ExtDriverId, DriverId[]>> =
  createSelector(extDriverDependencyMatrixSelector, (extDriverDependencyMatrix) => {
    const result: Record<ExtDriverId, DriverId[]> = {};
    for (const driverId in extDriverDependencyMatrix) {
      for (const extDriverId of extDriverDependencyMatrix[driverId]) {
        if (result[extDriverId] == null) {
          result[extDriverId] = [driverId];
        } else if (!result[extDriverId].includes(driverId)) {
          result[extDriverId].push(driverId);
        }
      }
    }
    return result;
  });

export const availableModelsBySourceSelector: Selector<Partial<Record<ExtSource, ExtModel[]>>> =
  createSelector(
    extDriversSelector,
    availableIntegrationsSelector,
    allAccountingAccountsSelector,
    (extDrivers, availableIntegrations, accounts) => {
      const modelsBySource: Partial<Record<ExtSource, ExtModel[]>> = {};
      extDrivers.forEach((extDriver) => {
        const models = modelsBySource[extDriver.source] ?? [];
        models.push(extDriver.model);
        modelsBySource[extDriver.source] = models;
      });

      const accountingIntegrations = groupIntegrationsByCategory(availableIntegrations).accounting;
      const integration = accountingIntegrations.find(
        (accountingIntegration) => getExtSource(accountingIntegration) in modelsBySource,
      );
      const linkedAccountingSource = integration ? getExtSource(integration) : null;

      if (linkedAccountingSource != null && accounts.length > 0) {
        const models = modelsBySource[linkedAccountingSource] ?? [];
        models.push(ExtModel.RunwayCustomTransactionDrivers);
        modelsBySource[linkedAccountingSource] = models;
      } else if (linkedAccountingSource != null) {
        modelsBySource[linkedAccountingSource] = modelsBySource[linkedAccountingSource]?.filter(
          (model) => model !== ExtModel.RunwayCustomTransactionDrivers,
        );
      }
      return mapValues(modelsBySource, (models) =>
        sortBy(uniq(models), (model) => {
          const idx = CANONICAL_MODEL_ORDER.indexOf(model);
          return idx === -1 ? CANONICAL_MODEL_ORDER.length : idx;
        }),
      );
    },
  );

export const integrationTabsForNavSourceSelector: Selector<IntegrationTab[]> =
  createDeepEqualSelector(
    navDataSourceSelector,
    navDataSourceSubPageSelector,
    integrationCategorySelector,
    navDataSourceSupportsQueryReadsSelector,
    navDataSourceSupportsQueryWritesSelector,
    enableMergeCustomQueriesSelector,
    (
      navSource,
      navSubPage,
      category,
      supportsQueryReads,
      supportQueryWrites,
      enableMergeCustomQueries,
      // eslint-disable-next-line max-params
    ) => {
      if (navSource == null || navSubPage?.type === 'linkedObjects') {
        return [];
      }

      if (category === IntegrationCategory.Accounting && !isWorkatoSource(navSource)) {
        const tabs: IntegrationTab[] = ['syncedReports'];
        // custom queries only supported for Merge integrations currently
        if (isMergeSource(navSource) && enableMergeCustomQueries) {
          tabs.push('customQueries');
        }
        return tabs;
      }

      if (supportsQueryReads || supportQueryWrites) {
        return ['sqlOutput'];
      }

      return [];
    },
  );

export const selectedIntegrationTabSelector: Selector<IntegrationTab | undefined> = createSelector(
  pageSelector,
  integrationTabsForNavSourceSelector,
  (page, tabs) => {
    if (
      !isDataSourcePage(page) ||
      page.navSubPage == null ||
      page.navSubPage.type === 'linkedObjects'
    ) {
      return undefined;
    }
    return page.navSubPage.selectedTab ?? tabs[0];
  },
);

export const isViewingTransactionsQueriesSelector: Selector<boolean> = createSelector(
  selectedIntegrationTabSelector,
  (tab) => tab === 'customQueries',
);

const canonicalExtDriverOrder = [
  'Income',
  'Cost of Goods Sold',
  'Gross Profit',
  'Expenses',
  'Net Operating Income',
  'Other Income',
  'Other Expenses',
  'Net Income',
];
const extDriversByModelForNavSourceSelector: Selector<Partial<Record<ExtModel, ExtDriver[]>>> =
  createSelector(extDriversForNavSourceSelector, (extDrivers) => {
    const unsortedDriversByModel = groupBy(extDrivers, (extDriver) => extDriver.model);
    const sortedDriversByModel = Object.fromEntries(
      Object.keys(unsortedDriversByModel).map((model) => [
        model,
        sortBy(unsortedDriversByModel[model], (d) => {
          if (d.path.length > 1) {
            return canonicalExtDriverOrder.length;
          }
          const idx = canonicalExtDriverOrder.indexOf(d.path[0]);
          return idx === -1 ? canonicalExtDriverOrder.length : idx;
        }),
      ]),
    ) as Record<ExtModel, ExtDriver[]>;
    return sortedDriversByModel;
  });

const UNTABBED_SOURCE_MODELS = [ExtModel.Unknown];

export const extModelsForSelectedIntegrationTabSelector: Selector<ExtModel[]> =
  createDeepEqualSelector(
    extDriversByModelForNavSourceSelector,
    selectedIntegrationTabSelector,
    (extDriversByModel, tab) => {
      if (tab == null) {
        return UNTABBED_SOURCE_MODELS;
      }
      if (tab === 'customQueries') {
        return [ExtModel.RunwayCustomTransactionDrivers];
      }
      const modelsForSelectedTab = (Object.keys(extDriversByModel) as ExtModel[]).filter((model) =>
        isModelPartOfIntegrationTab(model, tab),
      );
      return modelsForSelectedTab;
    },
  );

const allExtDriversForNavModelSelector: Selector<ExtDriver[]> = createSelector(
  extDriversByModelForNavSourceSelector,
  (driversByModel) => Object.values(driversByModel).flat(),
);

export const extDriversMonthKeysSelector: Selector<MonthKey[]> = createSelector(
  allExtDriversForNavModelSelector,
  (extDrivers) => {
    const monthsRange = extDrivers.reduce(
      (curr, extDriver) => {
        const driverMonthKeys = Object.keys(extDriver.timeSeries);
        const minKey = min(driverMonthKeys);
        const maxKey = max(driverMonthKeys);

        if (minKey == null || maxKey == null) {
          return curr;
        }

        const newMin = curr == null || curr[0] > minKey ? minKey : curr[0];
        const newMax = curr == null || curr[1] < maxKey ? maxKey : curr[1];

        return [newMin, newMax] as [MonthKey, MonthKey];
      },
      null as [MonthKey, MonthKey] | null,
    );
    return monthsRange != null ? monthKeyRange(monthsRange[0], monthsRange[1]) : [];
  },
);

export const selectedIntegrationTabIndexSelector: Selector<number | undefined> = createSelector(
  navDataSourceSelector,
  navDataSourceSubPageSelector,
  integrationTabsForNavSourceSelector,
  (navSource, navSubPage, tabs) => {
    if (navSource == null || navSubPage == null || navSubPage?.type === 'linkedObjects') {
      return undefined;
    }

    return tabs.findIndex((tab) => tab === navSubPage.selectedTab);
  },
);

const expandedExtDriverKeysByModelSelector: Selector<ExtDriverExpansionState> = (
  state: RootState,
) => state.extDriver.expandedExtDriverKeysByModel;

export const extDriverEntriesByParentPathKeyByModelSelector: Selector<
  Partial<Record<ExtModel, NullableRecord<string, ExtDriverEntry[]>>>
> = createSelector(
  extModelsForSelectedIntegrationTabSelector,
  extDriversByModelForNavSourceSelector,
  (models, extDriversByModel) => {
    const res: Partial<Record<ExtModel, NullableRecord<string, ExtDriverEntry[]>>> = {};
    models.forEach((model) => {
      const extDrivers = extDriversByModel[model];
      if (extDrivers == null) {
        return;
      }
      res[model] = extDriverEntriesByParentPathKey(extDrivers);
    });
    return res;
  },
);

const numberOfAllExtDriverRowsByModelSelector: Selector<Partial<Record<ExtModel, number>>> =
  createSelector(
    extModelsForSelectedIntegrationTabSelector,
    extDriverEntriesByParentPathKeyByModelSelector,
    (models, childrenByParentPathByModel) => {
      const res: Partial<Record<ExtModel, number>> = {};
      models.forEach((model) => {
        res[model] = getTotalNumberOfExternalDriverRows(childrenByParentPathByModel[model] ?? {});
      });
      return res;
    },
  );

const visibleExtDriverRowsByModelSelector: Selector<Partial<Record<ExtModel, ExtDriverRow[]>>> =
  createSelector(
    extModelsForSelectedIntegrationTabSelector,
    extDriverEntriesByParentPathKeyByModelSelector,
    expandedExtDriverKeysByModelSelector,
    (models, childrenByParentPathByModel, expandedDriverKeysByModel) => {
      const res: Partial<Record<ExtModel, ExtDriverRow[]>> = {};
      models.forEach((model) => {
        res[model] = getVisibleExtDriverRows(
          childrenByParentPathByModel[model] ?? {},
          expandedDriverKeysByModel[model] ?? [],
        );
      });
      return res;
    },
  );

export const visibleExtDriverRowsForModelSelector: ParametricSelector<ExtModel, ExtDriverRow[]> =
  createCachedSelector(
    visibleExtDriverRowsByModelSelector,
    paramSelector<ExtModel>(),
    (rowsByModel, model) => rowsByModel[model] ?? [],
  )((_state, model) => model);

export const orderedVisibleExtDriverRowKeysSelector: Selector<CellRowKey[]> = createSelector(
  extModelsForSelectedIntegrationTabSelector,
  visibleExtDriverRowsByModelSelector,
  (models, rowsByModel) =>
    models
      .map((model) => rowsByModel[model]?.map((row) => row.key))
      .filter(isNotNull)
      .flat()
      .map((extDriverId) => ({ extDriverId })),
);

export const numberOfAllExtDriverRowsForModelSelector: ParametricSelector<ExtModel, number> =
  createCachedSelector(
    numberOfAllExtDriverRowsByModelSelector,
    paramSelector<ExtModel>(),
    (numberOfRowsByModel, model) => numberOfRowsByModel[model] ?? 0,
  )((_state, model) => model);

export const isViewingExtDriversTableSelector: Selector<boolean> = createSelector(
  pageSelector,
  (page) => isDataSourcePage(page) && page.navSubPage?.type === 'extDrivers',
);

const pattern = /extDriver\(([0-9a-fA-F-]{36})/;
const getExtDriverUUID = (formula?: string) => {
  if (formula == null) {
    return null;
  }
  const match = formula.match(pattern);
  return match?.[1];
};

export const danglingExtDriversSelector: SelectorWithLayerParam<ExtDriver[]> = createCachedSelector(
  extDriversSelector,
  addLayerParams(formulaEntitiesByIdForLayerSelector),
  function getDanglingExtDrivers(extDrivers, formulaEntities) {
    const allReferencedExtDrivers: Record<string, boolean> = {};
    Object.values(formulaEntities).forEach((entity) => {
      const extDriverId = getExtDriverUUID(entity?.actuals.formulaForCalculations);
      if (extDriverId != null) {
        allReferencedExtDrivers[extDriverId] = true;
      }
    });

    return Object.values(extDrivers).filter((extDriver) => {
      return !allReferencedExtDrivers[extDriver.id];
    });
  },
)(getCacheKeyForLayerSelector);
