import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/nextjs';
import throttle from 'lodash/throttle';
import { NextRouter, withRouter } from 'next/router';
import React, { ErrorInfo } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import ErrorPage from 'components/ErrorPage/ErrorPage';
import IntercomMessenger from 'components/IntercomMessenger/IntercomMessenger';
import { getComponentNameFromStack } from 'helpers/errorMessages';
import { showErrorModal } from 'reduxStore/reducers/errorSlice';

interface Props extends StateProps {
  children: React.ReactNode;
  preventMonopoly?: boolean;
  router: NextRouter;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  // N.B. used by react internals to handle exceptions
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  renderError(error: Error, errorInfo?: ErrorInfo) {
    console.error(error);
    const component = getComponentNameFromStack(errorInfo?.componentStack);

    Sentry.withScope((scope) => {
      if (component != null) {
        scope.setExtras({
          component,
        });
      }
      scope.setTag('errorBoundarySource', 'MonopolyErrorBoundary');
      scope.setTag('path', this.props.router.pathname);
      Sentry.captureException(error);
    });

    // see https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = errorInfo?.componentStack;
    renderingError.cause = error;

    datadogRum.addError(renderingError);

    // Note: This error boundary catches all errors that occur in the app, which prevents nested error boundaries from working properly.
    // Due to the window error event listeners this component gets the error event before any child error boundaries.
    // This is a hardcoded workaround to allow the nested error boundary around the source ('integration') page to work properly.
    // In the future, we should update how this top level error boundary works to allow nested error boundaries to work properly.

    const isSourcePage = window.location.pathname.includes('/source');
    const isPagePath = window.location.pathname.includes('/page');
    if (isSourcePage || isPagePath) {
      return;
    }

    if (!this.props.preventMonopoly) {
      this.props.showErrorModal(error.message);
    }
  }

  errorHandler = throttle((event: ErrorEvent | PromiseRejectionEvent) => {
    let error: Error | undefined;

    if ('error' in event) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error = event.error;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error = event.reason;
    }

    if (!error) {
      return;
    }

    this.renderError(error);
  }, 100);

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.renderError(error, errorInfo);
  }

  componentDidMount() {
    window.addEventListener('error', this.errorHandler);
    window.addEventListener('unhandledrejection', this.errorHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.errorHandler);
    window.removeEventListener('unhandledrejection', this.errorHandler);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <IntercomMessenger />
          <ErrorPage />
        </>
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps = {
  showErrorModal,
};

const connector = connect(null, mapDispatchToProps);

type StateProps = ConnectedProps<typeof connector>;

const ErrorBoundaryWithRouter = withRouter(ErrorBoundary);

export default connector(ErrorBoundaryWithRouter);
