import { Box } from '@chakra-ui/react';
import React from 'react';

import { MultiSelectMenuItem } from 'components/SearchableMultiSelectMenu/SearchableMultiSelectMenu';
import SelectedItemBadge from 'components/SearchableMultiSelectMenu/SelectedItemBadge';

interface Props {
  items: MultiSelectMenuItem[];
  onClearItem?: (itemIdx: number) => void;
}

const SelectedItemsDisplay: React.FC<Props> = ({ items, onClearItem }) => {
  return (
    <Box columnGap={1} display="inline-flex">
      {items.map((item, idx) => (
        <SelectedItemBadge
          key={item.id}
          label={item.name}
          onClear={
            onClearItem != null
              ? () => {
                  onClearItem(idx);
                }
              : undefined
          }
        />
      ))}
    </Box>
  );
};

export default SelectedItemsDisplay;
