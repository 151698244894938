import { createSelector } from 'reselect';

import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { businessObjectsByIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { prevailingSelectedObjectIdsSelector } from 'selectors/prevailingCellSelectionSelector';

export const canDeleteSelectedObjectsSelector = createSelector(
  accessCapabilitiesSelector,
  prevailingSelectedObjectIdsSelector,
  businessObjectsByIdForLayerSelector,
  ({ canWriteObjects }, selectedObjectIds, objectsById) => {
    if (!canWriteObjects || selectedObjectIds == null) {
      return false;
    }

    return selectedObjectIds.every((id) => {
      const obj = objectsById[id];
      const canDelete = obj != null && obj.remoteId == null;
      return canDelete;
    });
  },
);

export const numSelectedObjectsSelector = createSelector(
  prevailingSelectedObjectIdsSelector,
  (selectedObjectIds) => {
    return (selectedObjectIds ?? []).length;
  },
);
