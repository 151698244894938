import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import {
  GetHandleProps,
  GetTrackProps,
  Handles,
  Rail,
  Slider,
  SliderItem,
  Ticks,
  Tracks,
} from 'react-compound-slider';

interface Props {
  domain: [number, number];
  values: [number] | [number, number];
  tickValues: number[];
  onSubmit: () => void;
  onChange: (values: readonly number[]) => void;
  onUpdate: (values: readonly number[]) => void;
}

const sliderStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
};

export const RangeSlider = ({
  domain,
  values,
  tickValues,
  onChange,
  onUpdate,
  onSubmit,
}: Props) => {
  return (
    <Slider
      mode={2}
      reversed
      step={1}
      domain={domain}
      rootStyle={sliderStyle}
      onChange={onChange}
      onUpdate={onUpdate}
      values={values}
    >
      <Rail>
        {({ getRailProps }) => (
          <Box
            position="absolute"
            width="100%"
            height="5px"
            borderRadius="sm"
            cursor="pointer"
            backgroundColor="gray.400"
            {...getRailProps()}
          />
        )}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <Box>
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                onSubmit={onSubmit}
                getHandleProps={getHandleProps}
              />
            ))}
          </Box>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <Box>
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
          </Box>
        )}
      </Tracks>
      <Ticks count={tickValues.length} values={tickValues}>
        {({ ticks }) => (
          <Box>
            {ticks.map((tick) => (
              <Tick key={tick.id} tick={tick} count={ticks.length} />
            ))}
          </Box>
        )}
      </Ticks>
    </Slider>
  );
};

interface HandleProps {
  domain: [number, number];
  handle: SliderItem;
  onSubmit: () => void;
  getHandleProps: GetHandleProps;
}

const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  onSubmit,
  getHandleProps,
}: HandleProps) => {
  const handleRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    handleRef.current?.focus();
  }, []);

  return (
    <Box
      backgroundColor="selection.500"
      borderRadius="50%"
      color="white"
      fontSize="xs"
      fontWeight="medium"
      height={6}
      left={`${percent}%`}
      lineHeight={6}
      marginLeft="-11px"
      marginTop={-2}
      position="absolute"
      textAlign="center"
      width={6}
      zIndex={2}
    >
      {/* we want the handles to be focusable */}
      <Box
        as="button"
        role="slider"
        ref={handleRef}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            onSubmit();
          }
        }}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        height="full"
        width="full"
        {...getHandleProps(id)}
      >
        {value}
      </Box>
    </Box>
  );
};

interface TrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
}

const Track = ({ source, target, getTrackProps }: TrackProps) => (
  <Box
    position="absolute"
    height="5px"
    zIndex={1}
    backgroundColor="blue.500"
    borderRadius="sm"
    cursor="pointer"
    left={`${source.percent}%`}
    width={`${target.percent - source.percent}%`}
    {...getTrackProps()}
  />
);

interface TickProps {
  key: string;
  tick: SliderItem;
  count: number;
}

const Tick = ({ tick, count }: TickProps) => (
  <Box
    position="absolute"
    marginTop="1.125rem"
    fontSize="0.625rem"
    fontWeight="medium"
    textAlign="center"
    marginLeft={`${-(100 / count) / 2}%`}
    width={`${100 / count}%`}
    left={`${tick.percent}%`}
    color="gray.500"
  >
    {tick.value}
  </Box>
);
