import { DriverType, ValueType } from 'generated/graphql';
import { getMonthKeyRange } from 'helpers/dates';
import { resolveDependencyDateRange } from 'helpers/dependencies';
import { DependencyListenerEvaluator } from 'helpers/formulaEvaluation/DependenciesListenerEvaluator';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import {
  ALL_MONTHS_KEY,
  CacheKey,
  getFormulaCacheKey,
  getIdAndMonthKeyFromFormulaCacheKey,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { getDependencies } from 'helpers/getDependencies';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectFieldId } from 'reduxStore/models/businessObjects';
import { DriverPropertyId } from 'reduxStore/models/collections';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import {
  DimensionalDriver,
  Driver,
  DriverId,
  getDriverActualsFormulaForCalculation,
  getDriverForecastFormulaForCalculation,
} from 'reduxStore/models/drivers';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  businessObjectFieldIdsByFieldSpecIdSelector,
  businessObjectFieldSpecByIdSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import {
  dimensionalPropertyEvaluatorSelector,
  driverPropertyIdByDimDriverIdSelector,
} from 'selectors/collectionSelector';
import { dependenciesListenerEvaluatorSelector } from 'selectors/dependenciesListenerEvaluatorSelector';
import {
  dimensionalDriversBySubDriverIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import { isLayerNamedVersionSelector } from 'selectors/layerSelector';
import { MonthKey, RelativeDate, RelativeDateReference, TimeUnit } from 'types/datetime';
import {
  ALL_MONTH_DEPENDENCY_DATE_RANGE,
  Dependency,
  DependencyDateRange,
  THIS_MONTH_RELATIVE_RANGE,
} from 'types/dependencies';
import { DateReference } from 'types/formula';

type DependencyId = DriverId | BusinessObjectFieldSpecId | DriverGroupId;
type DependentId = DriverId | BusinessObjectFieldId;

type DateReferenceKey =
  | `absolute{${MonthKey}}`
  | `relative{${TimeUnit},${RelativeDate['val']},${RelativeDateReference}}`;

type DependencyDateRangeKey =
  | `${DateReferenceKey}-${DateReferenceKey}`
  | typeof ALL_MONTH_DEPENDENCY_DATE_RANGE;

// Changes to dependencies of these types can result in a cache becoming invalid
const INVALIDATION_DEPENDENCY_TYPES: Array<Dependency['type']> = [
  'driver',
  'extDriver',
  'driverGroup',
  'businessObjectFieldSpec',
  'businessObjectSpec',
  'businessObjectField',
];

// Formula to dependency cache
type DependencyCache = Map<string, Dependency[]>;

type InitializeGraphReturnParams = {
  driversById: ReturnType<typeof driversByIdForLayerSelector>;
  dimDriversBySubdriverId: ReturnType<typeof dimensionalDriversBySubDriverIdSelector>;
  driverPropertyIdByDimDriverId: ReturnType<typeof driverPropertyIdByDimDriverIdSelector>;
  fieldSpecsById: ReturnType<typeof businessObjectFieldSpecByIdSelector>;
  fieldIdsByFieldSpecId: ReturnType<typeof businessObjectFieldIdsByFieldSpecIdSelector>;
  cache: DependencyCache;
  evaluator: DependencyListenerEvaluator;
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
};

/**
 * Current dependencies that are acknowledged:
 *
 * - driverIds for
 *    any refenced driver
 *    any driver belonging to a refenced group
 *    any sub driver belonging to a refenced dim driver
 * - groupIds for any referenced group
 * - businessObjectFieldSpecIds for any referenced field spec (businessObjectFieldIds are never direct referenced)
 * - businessObjectSpecIds for any referenced object spec (for aggregation)
 *
 * In order to ensure that we can handle aggregations of field specs,
 * dimensional drivers, and groups, we need to track info about these
 * "auxilliary" dependencies even though they don't have any calculations
 * themselves. The relationship these auxilliary dependencies have with their
 * consituent entities (the ones that actually have values like drivers and
 * object fields) is a "this month" relationship so that we just pass through
 * the auxilliary dependencies when propagating month keys.
 *
 */
export class FormulaDependencyGraph {
  /**
   * dependents = { A: {dateRange1: [B, C], dateRange2: [D] } means B and C
   * depend on A within dateRange1 (if A changes within dateRange1 both B and C
   * should change). Similarily, D depends on A within dateRange2. The date
   * ranges are DependencyDateRanges instead of fixed month keys so that we can
   * use them to resolve the correct month keys for invalidating cache entries.
   */
  private dependents: Map<
    DependencyId,
    Map<DependencyDateRangeKey, { range: DependencyDateRange; dependentIds: Set<DependentId> }>
  >;

  /**
   * dependencies = { D: [E, F] } means D depends on E and F, i.e. if either E or F change, D should change.
   */
  private dependencies: Map<DependentId, Set<DependencyId>>;

  private layerId: LayerId;
  private initialized = false;

  constructor(layerId: LayerId) {
    this.dependents = new Map();
    this.dependencies = new Map();
    this.layerId = layerId;
  }

  public initialize(state: RootState): void {
    if (this.initialized) {
      return;
    }
    this.initializeGraphIfNotInitialized(state, []);
  }

  public reset(): void {
    this.dependents = new Map();
    this.dependencies = new Map();
    this.initialized = false;
  }

  public updateDriverDependencies(state: RootState, driverId: DriverId): void {
    const {
      cache,
      evaluator,
      driversById,
      dimDriversBySubdriverId,
      driverPropertyIdByDimDriverId,
      dimensionalPropertyEvaluator,
    } = this.initializeGraphIfNotInitialized(state, [
      'cache',
      'evaluator',
      'driversById',
      'dimDriversBySubdriverId',
      'driverPropertyIdByDimDriverId',
      'dimensionalPropertyEvaluator',
    ]);

    const driver = safeObjGet(driversById[driverId]);

    if (driver == null) {
      return;
    }

    this.updateDriverDependenciesHelper({
      driver,
      dimDriversBySubdriverId,
      driverPropertyIdByDimDriverId,
      evaluator,
      cache,
      dimensionalPropertyEvaluator,
    });
  }

  public updateBusinessObjectFieldSpecDependencies(
    state: RootState,
    fieldSpecId: BusinessObjectFieldSpecId,
  ): void {
    const {
      cache,
      evaluator,
      fieldSpecsById,
      fieldIdsByFieldSpecId,
      dimensionalPropertyEvaluator,
    } = this.initializeGraphIfNotInitialized(state, [
      'cache',
      'evaluator',
      'fieldSpecsById',
      'fieldIdsByFieldSpecId',
      'dimensionalPropertyEvaluator',
    ]);

    const fieldSpec = safeObjGet(fieldSpecsById[fieldSpecId]);

    if (fieldSpec == null) {
      return;
    }

    const fieldIds = safeObjGet(fieldIdsByFieldSpecId[fieldSpecId]) ?? [];

    this.updateBusinessObjectFieldSpecDependenciesHelper({
      fieldSpec,
      fieldIds,
      cache,
      evaluator,
      dimensionalPropertyEvaluator,
    });
  }

  public updateBusinessObjectFieldDependencies(
    state: RootState,
    fieldId: BusinessObjectFieldId,
    fieldSpecId: BusinessObjectFieldSpecId,
  ): void {
    const { cache, evaluator, fieldSpecsById, dimensionalPropertyEvaluator } =
      this.initializeGraphIfNotInitialized(state, [
        'cache',
        'evaluator',
        'fieldSpecsById',
        'dimensionalPropertyEvaluator',
      ]);

    const fieldSpec = safeObjGet(fieldSpecsById[fieldSpecId]);

    if (fieldSpec == null) {
      return;
    }

    this.updateBusinessObjectFieldDependenciesHelper({
      fieldSpec,
      fieldId,
      evaluator,
      cache,
      dimensionalPropertyEvaluator,
    });
  }

  // includes self
  public getAllDependentIds(state: RootState, id: DependencyId): Set<DependentId> {
    this.initializeGraphIfNotInitialized(state, []);

    const stack = [id];
    const seen: Set<DependentId> = new Set();

    while (stack.length > 0) {
      const currentId = stack.pop();
      if (currentId == null || seen.has(currentId)) {
        continue;
      }

      seen.add(currentId);

      const dependents = this.dependents.get(currentId) ?? [];

      dependents.forEach(({ dependentIds }) => {
        dependentIds.forEach((dependentId) => {
          if (!seen.has(dependentId)) {
            stack.push(dependentId);
          }
        });
      });
    }

    return seen;
  }

  public getAllDependentCacheKeys(
    state: RootState,
    keys: Set<CacheKey>,
    entityMonthKeys: Map<string, Set<MonthKey>>,
  ): Set<CacheKey> {
    // todo can we utilize caching here?
    this.initializeGraphIfNotInitialized(state, []);

    const frontier = [...keys];
    const visited = new Set<CacheKey>();

    while (frontier.length > 0) {
      const key = frontier.pop()!;

      if (visited.has(key)) {
        continue;
      }

      visited.add(key);

      const neighbors = this.getNeighborCacheKeys(key, entityMonthKeys);

      neighbors.forEach((k) => {
        frontier.push(k);
      });
    }

    return visited;
  }

  private initializeGraphIfNotInitialized<K extends Array<keyof InitializeGraphReturnParams>>(
    state: RootState,
    selectorNames: [...K],
  ): Pick<InitializeGraphReturnParams, K[number]> {
    const requestedParams = this.getInitializeGraphReturnParams(state, selectorNames);

    if (this.initialized) {
      return requestedParams;
    }

    // we don't need to track dependencies for named versions
    const isNamedVersion = isLayerNamedVersionSelector(state, this.layerId);
    if (isNamedVersion) {
      return requestedParams;
    }

    // Initializing graph, so have to run all selectors

    const {
      cache,
      evaluator,
      driversById,
      dimDriversBySubdriverId,
      driverPropertyIdByDimDriverId,
      fieldSpecsById,
      fieldIdsByFieldSpecId,
      dimensionalPropertyEvaluator,
    } = this.getInitializeGraphReturnParams(state, [
      'driversById',
      'dimDriversBySubdriverId',
      'driverPropertyIdByDimDriverId',
      'fieldSpecsById',
      'fieldIdsByFieldSpecId',
      'evaluator',
      'cache',
      'dimensionalPropertyEvaluator',
    ]);

    this.initialized = true;
    if (this.layerId === DEFAULT_LAYER_ID) {
      Object.keys(driversById).forEach((driverId) => {
        const driver = driversById[driverId];
        if (driver == null) {
          return;
        }
        this.updateDriverDependenciesHelper({
          driver,
          dimDriversBySubdriverId,
          driverPropertyIdByDimDriverId,
          evaluator,
          cache,
          dimensionalPropertyEvaluator,
        });
      });
    } else {
      // Scenarios use the same driver dependency graph as the main layer, since all driver changes happen in the main layer
      MainLayerFormulaDependencyGraph.initializeGraphIfNotInitialized(state, []);
      this.dependents = MainLayerFormulaDependencyGraph.dependents;
      this.dependencies = MainLayerFormulaDependencyGraph.dependencies;
    }

    Object.values(fieldSpecsById).forEach((fieldSpec) => {
      if (fieldSpec == null) {
        return;
      }
      this.updateBusinessObjectFieldSpecDependenciesHelper({
        fieldSpec,
        fieldIds: fieldIdsByFieldSpecId[fieldSpec.id] ?? [],
        evaluator,
        cache,
        dimensionalPropertyEvaluator,
      });
    });

    return requestedParams;
  }

  // Allows us to centralize selector/state logic while only running specific selectors based
  // on what operation the user is making. For each of the `public` methods, we only need
  // specific selectors to run, not all of them.
  private getInitializeGraphReturnParams<K extends Array<keyof InitializeGraphReturnParams>>(
    state: RootState,
    fields: [...K],
  ): Pick<InitializeGraphReturnParams, K[number]> {
    return runRequestedObjectFields({
      object: {
        cache: () => new Map(),
        evaluator: () => dependenciesListenerEvaluatorSelector(state, { layerId: this.layerId }),
        driversById: () => driversByIdForLayerSelector(state, { layerId: this.layerId }),
        dimDriversBySubdriverId: () =>
          dimensionalDriversBySubDriverIdSelector(state, {
            layerId: this.layerId,
          }),
        driverPropertyIdByDimDriverId: () =>
          driverPropertyIdByDimDriverIdSelector(state, {
            layerId: this.layerId,
          }),
        fieldSpecsById: () => businessObjectFieldSpecByIdSelector(state, { layerId: this.layerId }),
        fieldIdsByFieldSpecId: () => businessObjectFieldIdsByFieldSpecIdSelector(state),
        dimensionalPropertyEvaluator: () => dimensionalPropertyEvaluatorSelector(state),
      },
      fields,
    });
  }

  private updateDriverDependenciesHelper({
    driver,
    cache,
    dimDriversBySubdriverId,
    driverPropertyIdByDimDriverId,
    evaluator,
    dimensionalPropertyEvaluator,
  }: {
    driver: Driver;
    dimDriversBySubdriverId: NullableRecord<string, DimensionalDriver>;
    driverPropertyIdByDimDriverId: NullableRecord<string, DriverPropertyId>;
    cache: DependencyCache;
    evaluator: DependencyListenerEvaluator;
    dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
  }) {
    if (driver.type === DriverType.Basic) {
      const parentDimDriver = safeObjGet(dimDriversBySubdriverId[driver.id]);
      const forecastFormula = getDriverForecastFormulaForCalculation(
        driver,
        parentDimDriver,
      ).formula;
      const actualsFormula = getDriverActualsFormulaForCalculation(driver, parentDimDriver).formula;
      this.updateDependencies(
        driver.id,
        [forecastFormula, forecastFormula === actualsFormula ? undefined : actualsFormula].filter(
          isNotNull,
        ),
        cache,
        evaluator,
        dimensionalPropertyEvaluator,
        driver.valueType,
        'driver',
      );

      // Groups depend on their consituent drivers
      (driver.driverReferences?.map((ref) => ref.groupId) ?? [])
        .filter(isNotNull)
        .forEach((groupId) => {
          this.setDependent({
            dependency: { type: 'driver', id: driver.id, dateRange: THIS_MONTH_RELATIVE_RANGE },
            dependentId: groupId,
          });
        });

      const dimDriver = dimDriversBySubdriverId[driver.id];
      if (dimDriver != null) {
        // For aggregates, dim drivers depend on their subdrivers
        this.setDependent({
          dependency: { type: 'driver', id: driver.id, dateRange: THIS_MONTH_RELATIVE_RANGE },
          dependentId: dimDriver.id,
        });
      }
    } else {
      // For aggregates, dim drivers depend on their subdrivers
      driver.subdrivers.forEach((subdriver) => {
        this.setDependent({
          dependency: {
            type: 'driver',
            id: subdriver.driverId,
            dateRange: THIS_MONTH_RELATIVE_RANGE,
          },
          dependentId: driver.id,
        });
      });

      // there may be an aggregate on the corresponding driverPropertyId -
      // that should just be a proxy to the dimDriverId, so have it dependent
      // on the dimDriver and all of its subdrivers
      const driverPropertyId = safeObjGet(driverPropertyIdByDimDriverId[driver.id]);
      if (driverPropertyId != null) {
        this.setDependent({
          dependency: {
            type: 'driver',
            id: driver.id,
            dateRange: THIS_MONTH_RELATIVE_RANGE,
          },
          dependentId: driverPropertyId,
        });

        driver.subdrivers.forEach((subdriver) => {
          this.setDependent({
            dependency: {
              type: 'driver',
              id: subdriver.driverId,
              dateRange: THIS_MONTH_RELATIVE_RANGE,
            },
            dependentId: driverPropertyId,
          });
        });
      }
    }
  }

  private updateBusinessObjectFieldSpecDependenciesHelper({
    fieldSpec,
    fieldIds,
    cache,
    evaluator,
    dimensionalPropertyEvaluator,
  }: {
    fieldSpec: BusinessObjectFieldSpec;
    fieldIds: BusinessObjectFieldId[];
    cache: DependencyCache;
    evaluator: DependencyListenerEvaluator;
    dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
  }) {
    fieldIds.forEach((fieldId) => {
      this.updateBusinessObjectFieldDependenciesHelper({
        fieldSpec,
        fieldId,
        evaluator,
        cache,
        dimensionalPropertyEvaluator,
      });
    });
  }

  private removeEntity(dependentId: DependentId) {
    const oldDependencies = this.dependencies.get(dependentId);

    // Remove dependent from all the range dependent id sets
    oldDependencies?.forEach((dependencyId) => {
      this.dependents.get(dependencyId)?.forEach(({ dependentIds }, rangeKey) => {
        dependentIds.delete(dependentId);

        if (dependentIds.size === 0) {
          this.dependents.get(dependencyId)?.delete(rangeKey);
        }
      });

      if ((this.dependents.get(dependencyId)?.size ?? 0) === 0) {
        this.dependents.delete(dependencyId);
      }
    });

    this.dependencies.delete(dependentId);
  }

  /**
   * Field specs have a dependency on the fields so that aggregations over the
   * field specs are updated when the fields are updated. This is necessary as
   * formulas that sum/count/etc over fields only have the field spec as a
   * dependency for now and so we need to bridge the gap between the field spec
   * and the fields. We may want the DependencyListener to just add individual
   * fields as dependencies but that could result in a lot of dependencies.
   */
  private updateBusinessObjectFieldDependenciesHelper({
    fieldSpec,
    fieldId,
    evaluator,
    cache,
    dimensionalPropertyEvaluator,
  }: {
    fieldSpec: BusinessObjectFieldSpec;
    fieldId: BusinessObjectFieldId;
    evaluator: DependencyListenerEvaluator;
    cache: DependencyCache;
    dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
  }) {
    // Similar to what we're doing in updateBusinessObjectFieldSpecDependencies
    // but we want to handle situations like new object instances being added.
    if (fieldSpec.defaultForecast.formula != null) {
      this.updateDependencies(
        fieldId,
        [fieldSpec.defaultForecast.formula],
        cache,
        evaluator,
        dimensionalPropertyEvaluator,
        fieldSpec.type,
        'objectField',
      );
    }

    this.setDependent({
      dependency: {
        type: 'businessObjectField',
        id: fieldId,
        dateRange: THIS_MONTH_RELATIVE_RANGE,
      },
      dependentId: fieldSpec.id,
    });
  }

  /**
   * The cache should be local to one "update cycle", i.e. one formula update or one formula update + initialization.
   * This way we don't have to worry about invalidating the cache.
   */
  // eslint-disable-next-line max-params
  private updateDependencies(
    dependentId: DependentId,
    formulas: string[],
    cache: DependencyCache,
    evaluator: DependencyListenerEvaluator,
    dimensionalPropertyEvaluator: DimensionalPropertyEvaluator,
    valueType: ValueType,
    entityType: FormulaEntityTypedId['type'] | null = null,
  ) {
    this.removeEntity(dependentId);

    const dependencyIdsSet = new Set<DependencyId>();

    formulas.forEach((formula) => {
      let dependencies: Dependency[];
      if (cache.has(formula)) {
        dependencies = cache.get(formula);
      } else {
        dependencies = getDependencies({
          entityId: dependentId,
          formula,
          evaluator,
          dimensionalPropertyEvaluator,
          valueType,
          entityType,
        });

        cache.set(formula, dependencies);
      }

      dependencies.forEach((dependency) => {
        if (!INVALIDATION_DEPENDENCY_TYPES.includes(dependency.type)) {
          return;
        }

        this.setDependent({ dependency, dependentId });
        dependencyIdsSet.add(dependency.id);
      });
    });

    this.dependencies.set(dependentId, dependencyIdsSet);
  }

  private setDependent({
    dependency,
    dependentId,
  }: {
    dependency: Dependency;
    dependentId: DependentId;
  }) {
    const depId = dependency.id;
    if (!this.dependents.has(depId)) {
      this.dependents.set(depId, new Map());
    }

    // If driver A references another driver B at an X month offset, then
    // updating B has the downstream effect of updating A at a -X month offset.
    // In the case where no date range is provided, we currently just assume a
    // "this month" relationship.
    const range =
      'dateRange' in dependency && dependency.dateRange != null
        ? invertDependencyDateRange(dependency.dateRange)
        : THIS_MONTH_RELATIVE_RANGE;

    const rangeKey = depDateRangeKey(range);

    if (!this.dependents.get(depId)?.has(rangeKey)) {
      this.dependents.get(depId)?.set(rangeKey, { range, dependentIds: new Set() });
    }

    this.dependents.get(depId)?.get(rangeKey)?.dependentIds.add(dependentId);
  }

  // todo: Can we cache neighbors?
  private getNeighborCacheKeys(
    key: CacheKey,
    entityMonthKeys: Map<string, Set<MonthKey>>,
  ): Set<CacheKey> {
    const { id, monthKey } = getIdAndMonthKeyFromFormulaCacheKey(key);
    const dependentRanges = this.dependents.get(id);

    if (dependentRanges == null) {
      return new Set();
    }

    const toAddKeys = new Set<CacheKey>();

    dependentRanges.forEach(({ range, dependentIds }) => {
      dependentIds.forEach((dependentId) => {
        // If the `id` driver is being updated for all months then anything
        // dependent on it is updated for all months.
        if (monthKey === ALL_MONTHS_KEY) {
          const mks = entityMonthKeys.get(dependentId) ?? [];
          mks.forEach((mk) => toAddKeys.add(getFormulaCacheKey(dependentId, mk, [])));
        } else {
          const resolvedDateRange = resolveDependencyDateRange(range, monthKey);
          const monthKeys = getMonthKeyRange(resolvedDateRange.start, resolvedDateRange.end);
          monthKeys.forEach((mk) => {
            if (!entityMonthKeys.get(dependentId)?.has(mk)) {
              return;
            }
            toAddKeys.add(getFormulaCacheKey(dependentId, mk, []));
          });
        }
      });
    });

    return toAddKeys;
  }
}

function depDateRangeKey(dep: DependencyDateRange): DependencyDateRangeKey {
  if (dep === ALL_MONTH_DEPENDENCY_DATE_RANGE) {
    return ALL_MONTH_DEPENDENCY_DATE_RANGE;
  }
  return `${dateReferenceKey(dep.start)}-${dateReferenceKey(dep.end)}`;
}

function dateReferenceKey(ref: DateReference): DateReferenceKey {
  if (ref.type === 'absolute') {
    return `absolute{${ref.val}}`;
  }
  const { unit, val, reference } = ref.val;
  return `relative{${unit},${val},${reference}}`;
}

function invertDateReference(ref: DateReference): DateReference {
  if (ref.type === 'absolute') {
    return ref;
  }

  const { unit, val, isEnd } = ref.val;
  const inverted: DateReference & { type: 'relative' } = {
    type: 'relative',
    val: {
      ...ref.val,
      // For some reason JS has -0. Avoiding that because it messses up the
      // tests.
      val: val !== 0 ? -val : val,
    },
  };

  // If the reference the start of a quarter, we need to invert it to the
  // end of the quarter. Only do this in cases where it matters.
  if (unit !== TimeUnit.Month) {
    inverted.val.isEnd = !(isEnd ?? false);

    // For consistency, just set to undefined in the false case
    if (!inverted.val.isEnd) {
      inverted.val.isEnd = undefined;
    }
  }

  return inverted;
}

export function invertDependencyDateRange(dep: DependencyDateRange): DependencyDateRange {
  if (dep === ALL_MONTH_DEPENDENCY_DATE_RANGE) {
    return ALL_MONTH_DEPENDENCY_DATE_RANGE;
  }
  return {
    start: invertDateReference(dep.end),
    end: invertDateReference(dep.start),
  };
}

export const MainLayerFormulaDependencyGraph = new FormulaDependencyGraph(DEFAULT_LAYER_ID);

function runRequestedObjectFields<
  T,
  Obj extends Record<keyof T, () => T[keyof T]>,
  Fields extends Array<keyof T>,
>({ object, fields }: { object: Obj; fields: [...Fields] }): Pick<T, Fields[number]> {
  const ret: Partial<T> = {};

  fields.forEach((field) => {
    ret[field] = object[field]();
  });

  return ret as Pick<T, Fields[number]>;
}
