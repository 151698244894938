import { BoxProps } from '@chakra-ui/react';
import React from 'react';

import AttributeBadge from 'components/AttributeBadge/AttributeBadge';
import { Attribute } from 'reduxStore/models/dimensions';

interface Props {
  clippingIndex?: number;
  attribute: Attribute;
  fallbackTheme?: 'gray' | 'white';
  size?: 'sm' | 'xs' | 'md';
  textColor?: BoxProps['color'];
}

const DriverAttributeBadge: React.FC<Props> = ({ attribute, fallbackTheme, size, textColor }) => {
  return (
    <AttributeBadge
      attribute={attribute}
      fallbackTheme={fallbackTheme}
      size={size}
      textColor={textColor}
    />
  );
};

export default DriverAttributeBadge;
