import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MonthKey } from 'types/datetime';

const initialState = null as {
  monthKey: MonthKey;
  isOnDragHandle?: boolean;
} | null;

const cursorSlice = createSlice({
  name: 'cursor',
  initialState,
  reducers: {
    setCursor(_state, action: PayloadAction<typeof initialState>) {
      return action.payload;
    },
    setCursorMonthKey(state, action: PayloadAction<MonthKey>) {
      if (state?.monthKey !== action.payload) {
        if (state == null) {
          return {
            monthKey: action.payload,
          };
        } else {
          return {
            ...state,
            monthKey: action.payload,
          };
        }
      }

      return state;
    },
    setIsOnDragHandle(state, action: PayloadAction<boolean>) {
      if (state == null) {
        return state;
      }
      return {
        ...state,
        isOnDragHandle: action.payload,
      };
    },
  },
});

export const { setCursor, setCursorMonthKey, setIsOnDragHandle } = cursorSlice.actions;

export default cursorSlice.reducer;
