import { isEmpty } from 'lodash';
import { useRef } from 'react';

import { NumericTimeSeries } from 'reduxStore/models/timeSeries';

// if the timeseries is loading or is empty, returns the last value when it wasn't loading or empty
const useSmoothedTimeseries = (timeSeries: NumericTimeSeries, loading: boolean) => {
  const ref = useRef(timeSeries);
  ref.current = loading || isEmpty(timeSeries) ? ref.current : timeSeries;

  return ref.current;
};

// if the number is loading or is undefined, returns the last value when it wasn't loading or undefined
export const useSmoothedNumber = (n: number | null | undefined, loading: boolean) => {
  const ref = useRef(n);
  ref.current = loading || n == null ? ref.current : n;

  return ref.current;
};

export default useSmoothedTimeseries;
