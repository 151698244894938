import { Box } from '@chakra-ui/react';

interface Props {
  text: string;
  color?: string;
}

const monoTextStyles = {
  fontFamily: 'mono',
  webkitFontSmoothing: 'antialiased',
  mozOsxFontSmoothing: 'grayscale',
  fontStyle: 'normal',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: 'medium',
  whiteSpace: 'pre',
  wordSpacing: '2px',
};

const BaseFormulaChunk: React.FC<Props> = ({ text, color }) => {
  return (
    <Box as="span" className="mono" color={color} sx={monoTextStyles}>
      {text}
    </Box>
  );
};

export default BaseFormulaChunk;
