import { isNumber } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';

import { EventEntityContextProvider } from '@features/Plans/EventEntity';
import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import { useDisplayConfiguration } from 'components/DriverTimeSeriesCell/useDisplayConfiguration';
import LoadingNumericTimeSeriesCell from 'components/DriverTimeSeriesRow/LoadingNumericTimeSeriesCell';
import NumericTimeSeriesCell from 'components/TimeSeriesCell/NumericTimeSeriesCell';
import TimestampTimeSeriesCell from 'components/TimeSeriesCell/TimestampTimeSeriesCell';
import { CellType, DriverCellRef, ImpactDriverCellRef } from 'config/cells';
import { DriverRowContext } from 'config/driverRowContext';
import {
  ComparisonColumn,
  ComparisonTimePeriod,
  DriverFormat,
  RollupType,
  ValueType,
} from 'generated/graphql';
import { getMonthColumnKey } from 'helpers/cells';
import { TimeSeriesComparisonSubColumn, isEditableRollupType } from 'helpers/rollups';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useDragToSelectCells } from 'hooks/useDragToSelectCells';
import { useIsActuals } from 'hooks/useIsActuals';
import { useValueTooltip } from 'hooks/useValueTooltip';
import { updateDriverActuals, updateDriverFormat } from 'reduxStore/actions/driverMutations';
import { deleteOrUpdateEventImpact } from 'reduxStore/actions/eventMutations';
import { EventId } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import {
  NonNumericTimeSeriesWithEmpty,
  NumericTimeSeriesWithEmpty,
} from 'reduxStore/models/timeSeries';
import { NumberValue, TimestampValue } from 'reduxStore/models/value';
import { openCellPalettePopover } from 'reduxStore/reducers/pageSlice';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { isComparingLayersSelector } from 'selectors/scenarioComparisonSelector';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';
import { CalculationError, NOT_APPLICABLE_ERR_TYPE } from 'types/dataset';
import { MonthKey } from 'types/datetime';

interface Props {
  eventId?: EventId;
  monthKey: MonthKey;
  layerId: LayerId;
  subColumn: TimeSeriesComparisonSubColumn | undefined;
  rollupType: RollupType;
  color: string;
  value: number | string | undefined;
  isLoading: boolean;
  error?: CalculationError;
  valueType: ValueType;
}

const DriverTimeSeriesCell: React.FC<Props> = ({
  eventId,
  monthKey,
  layerId,
  subColumn,
  rollupType,
  color,
  value,
  valueType,
  error,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  const {
    driverId,
    comparisonRowLayerId,
    groupId,
    comparisonType: rowComparisonType,
    rowType: rowTypeFromContext,
    comparisonTimePeriod,
  } = useContext(DriverRowContext);

  const { blockId, readOnly } = useBlockContext();
  const isActuals = useIsActuals(monthKey, layerId);
  const isForecast = !isActuals;
  const isComparingLayers = useAppSelector((state) => isComparingLayersSelector(state, blockId));

  const isComparingTimePeriods = comparisonTimePeriod != null;

  const isComparisonRow =
    isComparingLayers ||
    (isComparingTimePeriods && comparisonTimePeriod !== ComparisonTimePeriod.CurrentPeriod);

  const isRowVersionColumn =
    subColumn?.column != null && subColumn.column === ComparisonColumn.RowVersion;

  const hasEvent = eventId != null;
  const rowType = rowTypeFromContext ?? CellType.Driver;

  let isEditable = !readOnly && isEditableRollupType(rollupType) && !isComparisonRow;

  if (comparisonTimePeriod != null) {
    if (comparisonTimePeriod === ComparisonTimePeriod.CurrentPeriod) {
      isEditable = isRowVersionColumn ?? false;
    } else {
      isEditable = false;
    }
  }

  // We don't want to show the cell palette on cells like the impact inspector ones...
  const isDriverGridCell = rowType === CellType.Driver;
  const isEditableForecast =
    isEditable && !hasEvent && isForecast && !isComparisonRow && isDriverGridCell;

  const valueTooltip = useValueTooltip({
    driverId,
    monthKey,
    layerId,
  });

  const onSave = useCallback(
    (
      newValue: NumberValue | TimestampValue | undefined,
      formatUpdate: DriverFormat | undefined,
    ) => {
      const numberOrTimestamp = newValue?.value;
      if (!isEditable || numberOrTimestamp === value) {
        return;
      }

      if (hasEvent) {
        dispatch(deleteOrUpdateEventImpact(eventId, monthKey, newValue));
      } else if (isActuals) {
        // This is stupid but you have to narrow the type.
        let values: NumericTimeSeriesWithEmpty | NonNumericTimeSeriesWithEmpty;
        if (isNumber(numberOrTimestamp)) {
          values = { [monthKey]: numberOrTimestamp };
        } else {
          values = { [monthKey]: numberOrTimestamp };
        }

        dispatch(updateDriverActuals([{ id: driverId, values, newFormat: formatUpdate }]));
      } else if (isEditableForecast && newValue != null) {
        if (formatUpdate != null) {
          dispatch(updateDriverFormat({ id: driverId, format: formatUpdate }));
        }
      }
    },
    [
      isEditable,
      hasEvent,
      isActuals,
      isEditableForecast,
      dispatch,
      eventId,
      monthKey,
      driverId,
      value,
    ],
  );

  const onStartEditing = useCallback(() => {
    if (isEditableForecast) {
      dispatch(openCellPalettePopover());
    }
  }, [dispatch, isEditableForecast]);

  const cellRef: ImpactDriverCellRef | DriverCellRef = useMemo(
    () =>
      rowType === CellType.ImpactDriver
        ? {
            type: CellType.ImpactDriver,
            rowKey: { driverId },
            columnKey: getMonthColumnKey(monthKey, rollupType, subColumn),
          }
        : {
            type: CellType.Driver,
            rowKey: {
              driverId,
              layerId: comparisonRowLayerId,
              groupId,
              comparisonType: rowComparisonType,
              comparisonTimePeriod,
            },
            columnKey: getMonthColumnKey(monthKey, rollupType, subColumn),
          },
    [
      rowType,
      driverId,
      monthKey,
      rollupType,
      subColumn,
      comparisonRowLayerId,
      groupId,
      rowComparisonType,
      comparisonTimePeriod,
    ],
  );

  const { onMouseDown, onMouseEnter, onMouseLeave } = useDragToSelectCells(cellRef, blockId);

  const width = useAppSelector((state) =>
    columnWidthSelector(state, { columnType: monthKey, blockId }),
  );

  const currentLayerId = useAppSelector(currentLayerIdSelector);

  const comparisonColumn = subColumn?.column ?? rowComparisonType;
  const isCurrentLayerCell = layerId === currentLayerId;

  const remappedErr: CalculationError | undefined = useMemo(() => {
    return error?.originEntity != null && error.originEntity.id === driverId && !isCurrentLayerCell
      ? {
          error: NOT_APPLICABLE_ERR_TYPE,
        }
      : error;
  }, [error, driverId, isCurrentLayerCell]);

  const displayConfiguration = useDisplayConfiguration({
    driverId,
    comparisonColumn,
  });

  return (
    <CellContextProvider cellRef={cellRef} valueTooltip={valueTooltip}>
      {isLoading ? (
        <LoadingNumericTimeSeriesCell width={width} color={color} />
      ) : (
        <EventEntityContextProvider type="driver" driverId={driverId}>
          {valueType === ValueType.Number && (
            <NumericTimeSeriesCell
              displayConfiguration={displayConfiguration}
              color={color}
              value={value as number}
              error={remappedErr}
              onSave={isEditable ? onSave : undefined}
              width={width}
              onMouseDown={onMouseDown}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onStartEditing={onStartEditing}
              isForecast={isForecast}
            />
          )}
          {valueType === ValueType.Timestamp && (
            <TimestampTimeSeriesCell
              color={color}
              value={value as string}
              error={remappedErr}
              onSave={isEditable ? onSave : undefined}
              width={width}
              onMouseDown={onMouseDown}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onStartEditing={onStartEditing}
            />
          )}
        </EventEntityContextProvider>
      )}
    </CellContextProvider>
  );
};

export default React.memo(DriverTimeSeriesCell);
