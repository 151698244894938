import { DateTime } from 'luxon';

import { convertToCustomRollupTypes } from 'components/CustomRollupMenu/helpers';
import { ComparisonTimePeriod, RollupType } from 'generated/graphql';
import { getMonthKey } from 'helpers/dates';
import { MonthKey } from 'types/datetime';

type RollupUnit =
  | 'month'
  | 'quarter'
  | 'year'
  | 'trailingThreeMonths'
  | 'trailingSixMonths'
  | 'trailingTwelveMonths'
  | 'halfYear';

const TRAILING_MONTHS_UNITS = ['trailingThreeMonths', 'trailingSixMonths', 'trailingTwelveMonths'];

export function getTimePeriodMonthKeysForRollupType(
  monthKeys: string[],
  timePeriod: ComparisonTimePeriod,
  rollupType: RollupType,
): MonthKey[] {
  return monthKeys.map((mk) =>
    getTimePeriodMonthKeyFromBaselineMonthKeyAndRollupType(mk, timePeriod, rollupType),
  );
}

export function getTimePeriodMonthKeyFromBaselineMonthKeyAndRollupType(
  monthKey: string,
  timePeriod: ComparisonTimePeriod,
  rollupType: RollupType,
): MonthKey {
  return getMonthKey(
    getDateRangeStartForTimePeriodAndRollupType(timePeriod, DateTime.fromISO(monthKey), rollupType),
  );
}

export function getDateRangeStartForTimePeriodAndRollupType(
  timePeriod: ComparisonTimePeriod,
  date: DateTime,
  rollupType: RollupType,
): DateTime {
  const [unit, numberOfTrailingMonths] = getRollupUnitAndTrailingMonths(rollupType);
  switch (timePeriod) {
    case ComparisonTimePeriod.PreviousPeriod:
      if (unit === 'month') {
        return date.minus({ months: 1 });
      }
      if (unit === 'quarter') {
        return date.minus({ months: 3 });
      }
      if (unit === 'halfYear') {
        return date.minus({ months: 6 });
      }
      if (unit === 'year') {
        return date.minus({ years: 1 });
      }
      if (TRAILING_MONTHS_UNITS.includes(unit) && numberOfTrailingMonths != null) {
        return date.minus({ months: numberOfTrailingMonths });
      }
      throw new Error(`Unknown time period unit: ${unit}`);
      break;
    case ComparisonTimePeriod.ThreeMonthsAgo:
      return date.minus({ months: 3 });
    case ComparisonTimePeriod.SixMonthsAgo:
      return date.minus({ months: 6 });
    case ComparisonTimePeriod.TwelveMonthsAgo:
      return date.minus({ months: 12 });
    case ComparisonTimePeriod.CurrentPeriod:
      return date;
    default:
      throw new Error(`Unknown time period: ${timePeriod}`);
  }
}

export function getDateRangeStartForComparisonTimePeriodsAndRollupTypes(
  comparisonTimePeriods: ComparisonTimePeriod[],
  start: DateTime,
  rollupTypes: RollupType[],
): DateTime {
  const convertedRollupTypes = convertToCustomRollupTypes(rollupTypes);
  const timePeriods =
    comparisonTimePeriods.length > 0 ? comparisonTimePeriods : [ComparisonTimePeriod.CurrentPeriod];

  const startDatesForRollUps = convertedRollupTypes.flatMap((rollupType) =>
    timePeriods.map((timePeriod) =>
      getDateRangeStartForTimePeriodAndRollupType(timePeriod, start, rollupType),
    ),
  );
  const earliestStartDate = DateTime.min(...startDatesForRollUps);
  return earliestStartDate;
}

const timePeriodComparisonSortOrder: Record<ComparisonTimePeriod, number> = {
  [ComparisonTimePeriod.CurrentPeriod]: 1,
  [ComparisonTimePeriod.PreviousPeriod]: 2,
  [ComparisonTimePeriod.ThreeMonthsAgo]: 3,
  [ComparisonTimePeriod.SixMonthsAgo]: 4,
  [ComparisonTimePeriod.TwelveMonthsAgo]: 5,
};

export const timePeriodComparisonSortComparator = (
  a: ComparisonTimePeriod,
  b: ComparisonTimePeriod,
) => {
  return timePeriodComparisonSortOrder[a] - timePeriodComparisonSortOrder[b];
};

function getRollupUnit(rollupType: RollupType): RollupUnit {
  let unit: RollupUnit;

  switch (rollupType) {
    case RollupType.Month:
    case RollupType.CurrentMonth:
    case RollupType.LastClose:
      unit = 'month';
      break;
    case RollupType.Quarter:
    case RollupType.QuarterToDate:
    case RollupType.QuarterToLastClose:
      unit = 'quarter';
      break;
    case RollupType.Trailing_3Months:
      unit = 'trailingThreeMonths';
      break;
    case RollupType.Trailing_6Months:
      unit = 'trailingSixMonths';
      break;
    case RollupType.Trailing_12Months:
      unit = 'trailingTwelveMonths';
      break;
    case RollupType.HalfYearToDate:
    case RollupType.HalfYearToLastClose:
      unit = 'halfYear';
      break;
    case RollupType.Annual:
    case RollupType.YearToDate:
    case RollupType.YearToLastClose:
      unit = 'year';
      break;
    default:
      throw new Error(`Unknown rollup type: ${rollupType}`);
  }
  return unit;
}

function getRollupUnitAndTrailingMonths(rollupType: RollupType): [RollupUnit, number | null] {
  switch (rollupType) {
    case RollupType.Trailing_3Months:
      return ['trailingThreeMonths', 3];
    case RollupType.Trailing_6Months:
      return ['trailingSixMonths', 6];
    case RollupType.Trailing_12Months:
      return ['trailingTwelveMonths', 12];
    default:
      return [getRollupUnit(rollupType), null];
  }
}
