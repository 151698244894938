import { Box, Flex, Text } from '@chakra-ui/react';
import React, { MouseEventHandler, useCallback } from 'react';

import DragButton from 'components/DriverCharts/DragButton';
import DriverName from 'components/DriverNameTableCell/DriverName';
import DriverRowContextProvider from 'components/DriverRowContextProvider/DriverRowContextProvider';
import { formatDriverValue } from 'helpers/formatting';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartContext from 'hooks/useChartContext';
import { DriverId } from 'reduxStore/models/drivers';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { driverNameSelector } from 'selectors/driversSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { MonthKey } from 'types/datetime';

const STOP_PROPAGATION: MouseEventHandler<HTMLDivElement> = (e) => e.stopPropagation();

interface Props {
  driverId: DriverId;
  monthKey: MonthKey;
  lastVisibleDriver: boolean;
  hidden?: boolean;
  hasActiveCursor?: boolean;
}

const DriverLegendRow: React.FC<Props> = ({
  driverId,
  monthKey,
  lastVisibleDriver,
  hidden,
  hasActiveCursor,
}) => {
  const { readOnly } = useBlockContext();

  const { baselineLayerId, toggleDriver, colorScale } = useChartContext();
  const driverName = useAppSelector((state) => driverNameSelector(state, { id: driverId })) ?? '';
  const formatConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );

  const driverTimeseries = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: driverId, layerId: baselineLayerId }),
  );

  let truncatedDecimalPlaces = 0;

  if (formatConfiguration.decimalPlaces != null) {
    // With limited space, only show 2 decimal places max for values
    truncatedDecimalPlaces = Math.min(formatConfiguration.decimalPlaces);
  }

  const value = formatDriverValue(driverTimeseries[monthKey], {
    ...formatConfiguration,
    decimalPlaces: truncatedDecimalPlaces,
  });

  // Dont show toggle if the driver is the only one left that isnt hidden
  const canToggleDriver = hidden ? true : !lastVisibleDriver;

  const getColor = (key: string) => (colorScale ? `${colorScale(key)}.300` : 'blue.300');

  const handleClick = useCallback(
    () => canToggleDriver && toggleDriver(driverId),
    [canToggleDriver, driverId, toggleDriver],
  );

  return (
    <DriverRowContextProvider driverId={driverId}>
      <Flex
        flexDirection="row"
        key={driverId}
        width="full"
        alignItems="center"
        gap={3}
        opacity={hidden ? 0.5 : 1}
        role="group"
        onClick={handleClick}
        cursor={canToggleDriver ? 'pointer' : 'default'}
        _hover={canToggleDriver ? { bgColor: 'gray.200', borderRadius: 'md' } : {}}
      >
        <Flex>
          <Box
            bgColor={getColor(driverId)}
            w={4}
            h={4}
            borderWidth="px"
            borderRadius="sm"
            borderColor={getColor(driverId)}
            flexBasis={4}
            ml={1}
          />
        </Flex>
        <Flex grow={1} overflowX="clip" overflow="hidden" wordBreak="break-word" direction="row">
          <DriverName name={driverName} fontWeight="medium" fontSize="xxs" />
        </Flex>
        {hasActiveCursor && (
          <Flex>
            <Text
              color="gray.500"
              noOfLines={1}
              fontWeight="medium"
              whiteSpace="nowrap"
              fontSize="xxs"
              textAlign="right"
              alignSelf="center"
            >
              {value}
            </Text>
          </Flex>
        )}
        {!readOnly && (
          <Flex onClick={STOP_PROPAGATION}>
            <DragButton driverId={driverId} canDrag={false} />
          </Flex>
        )}
      </Flex>
    </DriverRowContextProvider>
  );
};

export default React.memo(DriverLegendRow);
