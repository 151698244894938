import { keyBy } from 'lodash';

import { GroupRow } from 'config/businessObjects';
import { safeObjGet } from 'helpers/typescript';
import { BusinessObject, BusinessObjectId } from 'reduxStore/models/businessObjects';

export const reorderObjects = ({
  objects,
  objectIdSortOrder,
}: {
  objects: BusinessObject[];
  objectIdSortOrder?: BusinessObjectId[];
}) => {
  if (!objectIdSortOrder) {
    return objects;
  }
  const res: BusinessObject[] = [];
  const objectsById = keyBy(objects, 'id');
  const seen = new Set<BusinessObjectId>();

  objectIdSortOrder.forEach((oId) => {
    const obj = safeObjGet(objectsById[oId]);
    if (obj != null) {
      res.push(obj);
      seen.add(obj.id);
    }
  });

  // Add the rest of the objects that don't have an explicit order
  objects.forEach((o) => {
    if (!seen.has(o.id)) {
      res.push(o);
    }
  });

  return res;
};

export const reorderObjectGroups = ({
  groups,
  objectIdSortOrder,
}: {
  groups: GroupRow[][];
  objectIdSortOrder?: string[];
}) => {
  if (!objectIdSortOrder) {
    return groups;
  }

  return groups.map((rows) => {
    const res: GroupRow[] = [];
    const seen = new Set<BusinessObjectId>();
    const rowsByObjectId = keyBy(rows, 'objectId');
    objectIdSortOrder.forEach((oId) => {
      const obj = safeObjGet(rowsByObjectId[oId]);
      if (obj?.type === 'object') {
        res.push(obj);
        seen.add(obj.objectId);
      }
    });

    // Add the rest of the objects that don't have an explicit order
    rows.forEach((o) => {
      if (o.type !== 'object') {
        return;
      }
      if (!seen.has(o.objectId)) {
        res.push(o);
      }
    });

    return res;
  });
};
