import React, { useContext } from 'react';

import DataSourceLogo from 'components/DataSourceLogo/DataSourceLogo';
import AttributeFiltersBadges from 'components/Formula/AttributeFiltersBadges';
import DateRangePill from 'components/FormulaInput/DateRangePill';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ReferencePill from 'components/FormulaInput/ReferencePill';
import useFormulaPillMouseDown from 'hooks/useFormulaPillMouseDown';
import { DraftDecoratorComponentProps, ExtQueryRefMetadata } from 'types/formula';

const ExtQueryDecorator: React.FC<DraftDecoratorComponentProps> = ({
  contentState,
  entityKey,
  offsetKey,
}) => {
  const { activeEntity, keysInSelection } = useContext(FormulaSelectionContext);
  const entity = contentState.getEntity(entityKey ?? '');
  const { label, source, attributeFilters, dateRangeDisplay, error } =
    entity.getData() as ExtQueryRefMetadata;
  const isSelectedEntity = activeEntity?.key === entityKey;
  const onNameMouseDown = useFormulaPillMouseDown(entityKey, entity);
  const onDateMouseDown = useFormulaPillMouseDown(entityKey, entity, { submenu: 'timePeriod' });

  if (entityKey == null) {
    return null;
  }

  const isSelected = isSelectedEntity || keysInSelection.has(entityKey);
  return (
    <ReferencePill
      onMouseDown={onNameMouseDown}
      isSelected={isSelected}
      error={error}
      label={label}
      offsetKey={offsetKey}
      attributes={
        <AttributeFiltersBadges attributeFilters={attributeFilters} includeEmpty={false} />
      }
      icon={source != null ? <DataSourceLogo source={source} size="xs" /> : undefined}
    >
      {dateRangeDisplay != null && (
        <DateRangePill
          onMouseDown={onDateMouseDown}
          label={dateRangeDisplay}
          isSelected={isSelected}
        />
      )}
    </ReferencePill>
  );
};

export default ExtQueryDecorator;
