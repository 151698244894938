import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'WaterfallChart',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    boxSize: '14px',
  },
  path: (
    <>
      <path
        d="M2 9.25C2 9.11193 2.11193 9 2.25 9H3.93182C4.06989 9 4.18182 9.11193 4.18182 9.25V12.75C4.18182 12.8881 4.06989 13 3.93182 13H2.25C2.11193 13 2 12.8881 2 12.75V9.25Z"
        fill="#949DAD"
      />
      <path
        d="M5.27273 6.25C5.27273 6.11193 5.38466 6 5.52273 6H7.20455C7.34262 6 7.45455 6.11193 7.45455 6.25V8.75C7.45455 8.88807 7.34262 9 7.20455 9H5.52273C5.38466 9 5.27273 8.88807 5.27273 8.75V6.25Z"
        fill="#949DAD"
      />
      <path
        d="M8.54545 2.25C8.54545 2.11193 8.65738 2 8.79545 2H10.4773C10.6153 2 10.7273 2.11193 10.7273 2.25V5.75C10.7273 5.88807 10.6153 6 10.4773 6H8.79545C8.65738 6 8.54545 5.88807 8.54545 5.75V2.25Z"
        fill="#949DAD"
      />
      <path
        d="M11.8182 6.25C11.8182 6.11193 11.9301 6 12.0682 6H13.75C13.8881 6 14 6.11193 14 6.25V12.75C14 12.8881 13.8881 13 13.75 13H12.0682C11.9301 13 11.8182 12.8881 11.8182 12.75V6.25Z"
        fill="#949DAD"
      />
    </>
  ),
});
