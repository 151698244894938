import { Text } from '@chakra-ui/react';
import React from 'react';

import { timeSeriesToChartData } from 'helpers/chart';
import { progressTowardsMilestoneLabel } from 'helpers/milestones';
import useAppSelector from 'hooks/useAppSelector';
import useChartContext from 'hooks/useChartContext';
import { LayerId } from 'reduxStore/models/layers';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';
import { firstMilestoneByDriverIdSelector } from 'selectors/milestonesSelector';
import { monthKeysForPageDateRangeSelector } from 'selectors/pageDateRangeSelector';

interface Props {
  layerId: LayerId;
}

const MilestoneProgressLabel: React.FC<Props> = ({ layerId }) => {
  const { driverIds } = useChartContext();

  // We only show milestones for when we chart one driver, so we only need the first driver
  const firstDriverId = driverIds[0];
  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, firstDriverId),
  );
  const milestone = useAppSelector(firstMilestoneByDriverIdSelector)[firstDriverId];
  const monthKeys = useAppSelector(monthKeysForPageDateRangeSelector);
  const lastActualMonthKey = useAppSelector(datasetLastActualsMonthKeySelector);
  const ts = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: firstDriverId, layerId }),
  );

  if (milestone == null) {
    return null;
  }

  const chartData = timeSeriesToChartData(ts, monthKeys, displayConfiguration.format);
  const chartDataAfterLastActual = chartData.filter((data) => data.monthKey > lastActualMonthKey);

  const progressLabel = progressTowardsMilestoneLabel(
    milestone,
    chartDataAfterLastActual,
    displayConfiguration,
  );

  return (
    <Text color="milestone" fontSize="xxxs" fontWeight="bold" textTransform="uppercase">
      {progressLabel?.label}
    </Text>
  );
};

export default MilestoneProgressLabel;
