import memoize from 'lodash/memoize';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import { isDevelopment, isSSR } from 'helpers/environment';
import {
  FormulaDependencyGraph,
  MainLayerFormulaDependencyGraph,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDependencyGraph';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { PopulatedBusinessObjectField } from 'reduxStore/models/businessObjects';
import { DriverPropertyId } from 'reduxStore/models/collections';
import { DimensionalDriver, Driver, DriverId } from 'reduxStore/models/drivers';
import { EventId } from 'reduxStore/models/events';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectFieldByIdForLayerSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { driverPropertyIdByDimDriverIdSelector } from 'selectors/collectionSelector';
import {
  dimensionalDriversBySubDriverIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import { ValueOrCalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

export type CacheKey = string;

const TEMP_CACHE_PREFIX = 'temp/';
export const ALL_MONTHS_KEY = 'ALL_MONTHS';
export const ALL_LAYERS_KEY = 'ALL_LAYERS';

type LayerCacheStats = {
  hits: number;
  misses: number;
  resets: number;
};

export function getFormulaCacheKey(
  id: string,
  monthKey: MonthKey,
  ignoreEventIds: EventId[] = [],
): CacheKey {
  const prefix = ignoreEventIds.length === 0 ? '' : TEMP_CACHE_PREFIX;
  const eventIdsPostFix =
    ignoreEventIds.length === 0 ? '' : `.${ignoreEventIds.toSorted().join(',')}`;
  return `${prefix}${id}.${monthKey}${eventIdsPostFix}`;
}

export const getIdAndMonthKeyFromFormulaCacheKey = memoize(
  (
    key: CacheKey,
  ): {
    id: string;
    monthKey: MonthKey;
  } => {
    const [id, monthKey] = key.split('.');
    if (id.startsWith(TEMP_CACHE_PREFIX)) {
      return { id: id.split('/')[1], monthKey };
    }

    return { id, monthKey };
  },
);

class FormulaCacheStats {
  private statsByLayer = new Map<LayerId, LayerCacheStats>();

  resetStats() {
    this.statsByLayer.clear();
  }

  hit(layerId: LayerId) {
    this.forLayer(layerId).hits++;
  }

  miss(layerId: LayerId) {
    this.forLayer(layerId).misses++;
  }

  reset(layerId: LayerId) {
    this.forLayer(layerId).resets++;
  }

  print() {
    /* eslint-disable no-console */
    console.log('FormulaCacheStats:');
    for (const [layerId, stats] of this.statsByLayer.entries()) {
      const hitRatePct = ((100 * stats.hits) / (stats.hits + stats.misses)).toFixed(2);
      console.log(`   layerId: ${layerId}`);
      console.log(`     hits: ${stats.hits}`);
      console.log(`     misses: ${stats.misses}`);
      console.log(`     resets: ${stats.resets}`);
      console.log(`     hit rate: ${hitRatePct}%`);
    }
    /* eslint-enable no-console */
  }

  private forLayer(layerId: LayerId) {
    let layer = this.statsByLayer.get(layerId);
    if (layer == null) {
      layer = { hits: 0, misses: 0, resets: 0 };
      this.statsByLayer.set(layerId, layer);
    }
    return layer;
  }

  private _ensureLayer(layerId: LayerId) {
    if (!this.statsByLayer.has(layerId)) {
      this.statsByLayer.set(layerId, { hits: 0, misses: 0, resets: 0 });
    }
  }
}

/*
The FormulaCache caches values by key as well as which keys were depended on to
make that value. "key" here refers to the composite of driverId and monthKey.
Whenever we need to invalidate a value (because it's impacted by an
event), we invalidate it and all the things that depended on it (recursively).

How do we invalidate the cache? We have a Redux middleware which listens for
mutations that may require invalidating cache entries. See
formulaCacheInvalidator.ts for more details.
*/

// TODO: HMR-issues. When this file is updated, HMR seems to cause the data
// to become invalid. This manifests as the caching seemingly becoming empty.

export class LayerFormulaCache {
  private cache: Map<CacheKey, ValueOrCalculationError | null>;
  private stats: FormulaCacheStats;
  private _dependencyGraph: FormulaDependencyGraph;

  private layerId: LayerId;

  constructor(layerId: LayerId, stats: FormulaCacheStats) {
    this.layerId = layerId;
    this.cache = new Map();
    this.stats = stats;
    if (this.layerId === DEFAULT_LAYER_ID) {
      this._dependencyGraph = MainLayerFormulaDependencyGraph;
    } else {
      this._dependencyGraph = new FormulaDependencyGraph(this.layerId);
    }
  }

  reset() {
    this.cache = new Map();
    this.stats.reset(this.layerId);
    if (this.layerId === DEFAULT_LAYER_ID) {
      MainLayerFormulaDependencyGraph.reset();
    } else {
      this._dependencyGraph = new FormulaDependencyGraph(this.layerId);
    }
  }

  get(key: CacheKey) {
    const val = this.cache.get(key) ?? undefined;
    const hit = this.cache.has(key);

    if (hit) {
      this.stats.hit(this.layerId);
    } else {
      this.stats.miss(this.layerId);
    }

    return [val, hit] as const;
  }

  set(key: CacheKey, val: ValueOrCalculationError | undefined) {
    this.cache.set(key, val ?? null);
  }

  invalidateKey(key: CacheKey) {
    const { id, monthKey } = getIdAndMonthKeyFromFormulaCacheKey(key);
    if (monthKey === ALL_MONTHS_KEY) {
      this.deleteAllFromCache(id);
    } else {
      this.deleteFromCache(id, monthKey);
    }
  }

  /**
   * This includes the given keys and all dependents of those keys
   */
  getAllKeysToInvalidate(state: RootState, keys: Set<CacheKey>): Set<CacheKey> {
    const businessObjectFieldById = businessObjectFieldByIdForLayerSelector(state);
    const driversById = driversByIdForLayerSelector(state, {
      layerId: this.layerId,
    });
    const dimDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(state);
    const driverPropertyIdByDimDriverId = driverPropertyIdByDimDriverIdSelector(state);

    // Need some way of limiting the search space for monthly cache keys. When
    // the webworker is in use, `this.entityIdToMonthKeys` won't be populated
    // so we can refer to the calculations slice instead. Ideally we would have
    // the dependency graph & formula values cache always live in the same
    // place so we don't have to do this.
    const monthKeysByEntityId = DataArbiter.get().getMonthKeysByEntityIdForLayer(this.layerId);

    const monthKeysByEntityIdWithFieldSpecs = addAuxiliaryEntityMonthKeys(
      businessObjectFieldById,
      driversById,
      dimDriversBySubDriverId,
      driverPropertyIdByDimDriverId,
      monthKeysByEntityId ?? new Map<string, Set<MonthKey>>(),
    );

    return this.dependencyGraph.getAllDependentCacheKeys(
      state,
      keys,
      monthKeysByEntityIdWithFieldSpecs,
    );
  }

  private deleteFromCache(id: string, monthKey: MonthKey) {
    this.cache.delete(getFormulaCacheKey(id, monthKey, []));
  }

  private deleteAllFromCache(id: string) {
    const monthKeys = DataArbiter.get().getMonthKeysByEntityIdForLayer(this.layerId)?.get(id);
    monthKeys?.forEach((monthKey) => {
      this.deleteFromCache(id, monthKey);
    });
  }

  deleteTempKeys() {
    for (const key in this.cache.keys()) {
      if (key.startsWith(TEMP_CACHE_PREFIX)) {
        this.cache.delete(key);
      }
    }
  }

  get dependencyGraph() {
    return this._dependencyGraph;
  }
}

export class FormulaCache {
  private layerCaches: Record<LayerId, LayerFormulaCache>;
  private _stats = new FormulaCacheStats();

  constructor() {
    this.layerCaches = {};
  }

  forLayer(layerId: LayerId) {
    if (this.layerCaches[layerId] == null) {
      this.layerCaches[layerId] = new LayerFormulaCache(layerId, this._stats);
    }
    return this.layerCaches[layerId];
  }

  resetAll() {
    Object.values(this.layerCaches).forEach((cache) => cache.reset());
  }

  deleteTempKeys() {
    Object.values(this.layerCaches).forEach((cache) => cache.deleteTempKeys());
  }

  get stats() {
    return this._stats;
  }
}

export const FormulaCacheSingleton = new FormulaCache();

if (isDevelopment && !isSSR()) {
  // using `self` instead of window so that the code is web-worker friendly
  self.formulaCacheStats = FormulaCacheSingleton.stats;
}

// Field specs, dim drivers, and driver groups are not represented in the
// formula cache keys but they are used to determine how cache keys propagate.
// So, we need to make sure they are in the entityIdToMonthKeys map so that we
// can propagate month keys through references to them in aggregations.
function addAuxiliaryEntityMonthKeys(
  businessObjectFieldsById: NullableRecord<string, PopulatedBusinessObjectField>,
  driversById: NullableRecord<string, Driver | undefined>,
  dimDriversBySubdriverId: NullableRecord<string, DimensionalDriver>,
  driverPropertyIdByDimDriverId: Record<DriverId, DriverPropertyId>,
  entityIdToMonthKeys: Map<string, Set<MonthKey>>,
): Map<string, Set<MonthKey>> {
  const newMap = new Map<string, Set<MonthKey>>();

  const add = (id: string, monthKeys: Set<MonthKey>) => {
    if (newMap.has(id)) {
      monthKeys.forEach((monthKey) => newMap.get(id).add(monthKey));
    } else {
      newMap.set(id, new Set(monthKeys));
    }
  };

  entityIdToMonthKeys.forEach((monthKeys, entityId) => {
    newMap.set(entityId, monthKeys);

    const objField = safeObjGet(businessObjectFieldsById[entityId]);
    if (objField != null) {
      const { fieldSpecId } = objField;
      add(fieldSpecId, monthKeys);
      return;
    }

    const driver = safeObjGet(driversById[entityId]);
    if (driver != null) {
      const groupIds = driver.driverReferences?.map((ref) => ref.groupId).filter(isNotNull) ?? [];
      groupIds.forEach((groupId) => add(groupId, monthKeys));

      const parentDimDriver = safeObjGet(dimDriversBySubdriverId[entityId]);
      if (parentDimDriver != null) {
        add(parentDimDriver.id, monthKeys);

        const driverPropertyId = safeObjGet(driverPropertyIdByDimDriverId[parentDimDriver.id]);
        if (driverPropertyId != null) {
          add(driverPropertyId, monthKeys);
        }
      }
    }
  });

  return newMap;
}
