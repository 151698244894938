import { StarIcon } from '@chakra-ui/icons';
import React, { useCallback, useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { OLVY_WIDGET_ALIAS, OLVY_WORKSPACE_ALIAS } from 'config/olvy';
import { HELP_CENTER_URL } from 'helpers/helpCenter';
import useAppDispatch from 'hooks/useAppDispatch';
import { openDeveloperMenu } from 'reduxStore/reducers/pageSlice';
import LifeFloatIcon from 'vectors/LifeFloat';
import MegaphoneIcon from 'vectors/Megaphone';
import WrenchIcon from 'vectors/Wrench';

const FEEDBACK_FORM_URL = 'https://runway.height.app/?taskForm=Product-feedback-EBnXbstt5kXu';

interface HelpMenuItem
  extends Pick<SelectItem, 'id' | 'name' | 'icon' | 'hasNextMenu' | 'submenu'> {
  onSelect: (() => void) | null;
}

interface Props {
  closeMenu: () => void;
}

const HelpMenu: React.FC<Props> = ({ closeMenu }) => {
  const dispatch = useAppDispatch();
  const onSelect = useCallback((item: HelpMenuItem) => {
    item.onSelect?.();
  }, []);

  const onOpenNewURL = useCallback(
    (url: string) => {
      window.open(url, '_blank');
      closeMenu();
    },
    [closeMenu],
  );

  const items = useMemo<HelpMenuItem[]>(() => {
    return [
      {
        id: 'releaseNotes',
        name: 'Release notes',
        icon: <StarIcon />,
        onSelect: () => {
          import('@olvyhq/widget-react').then((Olvy) => {
            if ('OlvyUtils' in Olvy) {
              Olvy.OlvyUtils.showWidget(OLVY_WORKSPACE_ALIAS, OLVY_WIDGET_ALIAS);
              closeMenu();
            }
          });
        },
      },
      {
        id: 'helpCenter',
        name: 'Help center',
        icon: <LifeFloatIcon />,
        onSelect: () => onOpenNewURL(HELP_CENTER_URL),
      },
      {
        id: 'submitFeedback',
        name: 'Submit feedback',
        icon: <MegaphoneIcon />,
        onSelect: () => onOpenNewURL(FEEDBACK_FORM_URL),
      },
      {
        id: 'devTools',
        name: 'Dev tools',
        icon: <WrenchIcon />,
        onSelect: () => {
          dispatch(openDeveloperMenu());
          closeMenu();
        },
      },
    ];
  }, [onOpenNewURL, closeMenu, dispatch]);

  return (
    <SelectMenu
      items={items}
      onSelect={onSelect}
      onClose={closeMenu}
      width="unset"
      startFocusIdx={-1}
    >
      {BaseSelectMenuItem}
    </SelectMenu>
  );
};

export default HelpMenu;
