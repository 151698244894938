import React from 'react';

import TableCellLight from 'components/Table/TableCellLight';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import {
  driverPropertyColumnTableCellOffsetSelector,
  driverPropertyColumnTableCellWidthSelector,
} from 'selectors/tableColumnsSelector';

interface Props {
  children?: React.ReactNode;
  columnType: ModelViewColumnType;
  columnLayerId: ColumnLayerId;
  isSticky?: boolean;
}

const DriverPropertyTableCellLight = React.forwardRef<HTMLDivElement, Props>(
  ({ children, columnType, columnLayerId, isSticky = false }, ref) => {
    const { blockId, gutterWidthInPx } = useBlockContext();
    const width = useAppSelector((state) =>
      driverPropertyColumnTableCellWidthSelector(state, {
        columnType,
        blockId,
      }),
    );
    const left =
      useAppSelector((state) =>
        driverPropertyColumnTableCellOffsetSelector(state, {
          columnType,
          blockId,
          columnLayerId,
        }),
      ) + gutterWidthInPx;

    return (
      <TableCellLight ref={ref} isSticky={isSticky} left={left} width={width}>
        {children}
      </TableCellLight>
    );
  },
);

DriverPropertyTableCellLight.displayName = 'DriverPropertyTableCellLight';

export default React.memo(DriverPropertyTableCellLight);
