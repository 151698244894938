import classNames from 'classnames';
import React from 'react';

import DisplayCellLight from 'components/DisplayCell/DisplayCellLight';
import { ImpactType, ValueType } from 'generated/graphql';
import { getDisplayValue } from 'helpers/formatting';
import { DisplayConfiguration, NullableValue, NumberValue } from 'reduxStore/models/value';
import { CalculationError } from 'types/dataset';

import styles from './NumericDisplayCell.module.scss';

interface Props {
  align?: 'left' | 'right';
  value: NullableValue<NumberValue>['value'];
  error?: CalculationError;
  displayConfiguration?: DisplayConfiguration;
  impactType?: ImpactType;
  isPlaceholder?: boolean;
}

const NumericDisplayCellLight: React.FC<Props> = ({
  value,
  error,
  align = 'right',
  isPlaceholder,
  displayConfiguration,
}) => {
  const displayValue =
    displayConfiguration != null ? getDisplayValue(value, error, { displayConfiguration }) : value;

  return (
    <DisplayCellLight align={align} valueType={ValueType.Number} isPlaceholder={isPlaceholder}>
      <div
        className={classNames(styles.numericDisplayCell, {
          [styles.alignRight]: align === 'right',
        })}
      >
        {displayValue}
      </div>
    </DisplayCellLight>
  );
};

export default NumericDisplayCellLight;
