import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'MilestoneFlag',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <g fill="currentColor">
      <path d="M4.75 3H13.75L11.3269 6.5L13.75 10H4.75V3Z" />
      <path d="M2.25 2.75C2.25 2.33579 2.58579 2 3 2C3.41421 2 3.75 2.33579 3.75 2.75V14H2.25V2.75Z" />
    </g>
  ),
});
