import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'NumberSignIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  d: 'M3.09091 2C2.48818 2 2 2.48818 2 3.09091V12.9091C2 13.5118 2.48818 14 3.09091 14H12.9091C13.5118 14 14 13.5118 14 12.9091V3.09091C14 2.48818 13.5118 2 12.9091 2H3.09091ZM6.36364 4.18182C6.66527 4.18182 6.90909 4.42564 6.90909 4.72727V5.81818H9.09091V4.72727C9.09091 4.42564 9.33473 4.18182 9.63636 4.18182C9.938 4.18182 10.1818 4.42564 10.1818 4.72727V5.81818H11.2727C11.5744 5.81818 11.8182 6.062 11.8182 6.36364C11.8182 6.66527 11.5744 6.90909 11.2727 6.90909H10.1818V9.09091H11.2727C11.5744 9.09091 11.8182 9.33473 11.8182 9.63636C11.8182 9.938 11.5744 10.1818 11.2727 10.1818H10.1818V11.2727C10.1818 11.5744 9.938 11.8182 9.63636 11.8182C9.33473 11.8182 9.09091 11.5744 9.09091 11.2727V10.1818H6.90909V11.2727C6.90909 11.5744 6.66527 11.8182 6.36364 11.8182C6.062 11.8182 5.81818 11.5744 5.81818 11.2727V10.1818H4.72727C4.42564 10.1818 4.18182 9.938 4.18182 9.63636C4.18182 9.33473 4.42564 9.09091 4.72727 9.09091H5.81818V6.90909H4.72727C4.42564 6.90909 4.18182 6.66527 4.18182 6.36364C4.18182 6.062 4.42564 5.81818 4.72727 5.81818H5.81818V4.72727C5.81818 4.42564 6.062 4.18182 6.36364 4.18182ZM6.90909 6.90909V9.09091H9.09091V6.90909H6.90909Z',
});
