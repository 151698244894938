import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'SparklesIcon',
  viewBox: '0 0 16 16',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90868 3.63622L8.16627 2.83779C8.28313 2.47776 8.56627 2.1946 8.92787 2.07618L9.7263 1.8186L8.92787 1.56101C8.56627 1.44415 8.28312 1.16101 8.16627 0.799406L7.90868 0.000976562L7.6511 0.799406C7.53424 1.16101 7.25109 1.44416 6.88949 1.56101L6.09106 1.8186L6.88949 2.07618C7.25109 2.19304 7.53424 2.47619 7.6511 2.83779L7.90868 3.63622ZM4.33283 7.41527L3.90883 8.7289L3.48483 7.41527C3.30559 6.86002 2.86886 6.4233 2.31363 6.24407L1 5.8185L2.31363 5.3945C2.86889 5.21526 3.3056 4.77853 3.48483 4.2233L3.90883 2.90967L4.33283 4.2233C4.51207 4.77856 4.94881 5.21527 5.50403 5.3945L6.81767 5.8185L5.50403 6.2425C4.94878 6.4233 4.51363 6.85847 4.33283 7.41527ZM10.1232 13.649L9.36309 16.001L8.60304 13.649C8.24466 12.5369 7.37269 11.6649 6.26063 11.3066L3.90863 10.5465L6.26063 9.78645C7.37269 9.42807 8.24466 8.5561 8.60304 7.44404L9.36309 5.09204L10.1232 7.44404C10.4815 8.5561 11.3535 9.42807 12.4656 9.78645L14.8176 10.5465L12.4656 11.3066C11.3535 11.6649 10.4832 12.537 10.1232 13.649Z"
        fill="currentColor"
      />
    </g>
  ),
});
