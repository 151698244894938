import { createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { createCachedSelector } from 're-reselect';

import { IntegrationStatus, LinkedAccount } from 'generated/graphql';
import { ExtSource, getExtSource } from 'helpers/integrations';
import { businessObjectSpecsByExtSourceSelector } from 'selectors/businessObjectSpecsSelector';
import { paramSelector } from 'selectors/constSelectors';
import { availableModelsBySourceSelector } from 'selectors/extDriversSelector';
import { integrationQueriesBySourceSelector } from 'selectors/integrationQueriesSelector';
import {
  availableIntegrationsSelector,
  linkedAccountsSelector,
} from 'selectors/integrationsSelector';
import { selectedOrgIdSelector } from 'selectors/selectedOrgSelector';
import { ParametricSelector, Selector } from 'types/redux';

const extraSourcesSelector = createSelector(
  availableModelsBySourceSelector,
  businessObjectSpecsByExtSourceSelector,
  integrationQueriesBySourceSelector,
  (modelsBySource, specsBySource, queriesBySource) => {
    return uniq([
      ...Object.keys(modelsBySource),
      ...Object.keys(specsBySource),
      ...Object.keys(queriesBySource),
    ]);
  },
);

export const activeIntegrationSourcesSelector: Selector<LinkedAccount[]> = createSelector(
  availableIntegrationsSelector,
  linkedAccountsSelector,
  extraSourcesSelector,
  selectedOrgIdSelector,
  (availableIntegrations, linkedAccounts, extraSources, orgId) => {
    const accounts = [...linkedAccounts];
    extraSources.forEach((src) => {
      const integration = availableIntegrations.find((int) => getExtSource(int) === src);
      if (integration == null) {
        return;
      }
      const existing = accounts.find((acct) => acct.integration.slug === integration.slug);
      if (existing != null) {
        return;
      }

      accounts.push({
        status: IntegrationStatus.Unknown,
        category: integration.categories[0], // AFAIK there are no cases of 2 entries here so for now this will have to suffice
        integration,
        id: orgId,
        overrideForecastData: false,
        propagateDataForward: false,
      });
    });

    return accounts;
  },
);

export const imageForExtSourceSelector: ParametricSelector<ExtSource, string | undefined> =
  createCachedSelector(
    availableIntegrationsSelector,
    activeIntegrationSourcesSelector,
    paramSelector<ExtSource>(),
    (availableIntegrations, activeIntegrations, extSource) => {
      let foundIntegration;

      foundIntegration = activeIntegrations.find(
        ({ integration }) => getExtSource(integration) === extSource,
      )?.integration;

      foundIntegration ??= availableIntegrations.find(
        (integration) => getExtSource(integration) === extSource,
      );

      return foundIntegration?.image ?? undefined;
    },
  )((_state, source) => source.toString());
