import { Flex } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useKey } from 'react-use';

import CellBorder from 'components/CellBorder/CellBorder';
import { CellSelectionStateContext } from 'config/cells';
import { isInputKeyboardEvent } from 'helpers/browserEvent';
import useAppSelector from 'hooks/useAppSelector';
import { isAlertDialogOpenSelector } from 'selectors/alertDialogSelector';

interface Props {
  onEnter: () => void;
  children: React.ReactNode;
}

const NewObjectFieldCellContents: React.FC<Props> = ({ children, onEnter }) => {
  const isAlertDialogOpen = useAppSelector(isAlertDialogOpenSelector);
  const { isActive } = useContext(CellSelectionStateContext);
  useKey(
    'Enter',
    (ev) => {
      if (isInputKeyboardEvent(ev) || isAlertDialogOpen || !isActive) {
        return;
      }
      ev.preventDefault();
      onEnter();
    },
    undefined,
    [onEnter, isActive, isAlertDialogOpen],
  );

  return (
    <Flex justifyContent="space-between" width="full">
      {children}
      <CellBorder />
    </Flex>
  );
};

export default NewObjectFieldCellContents;
