import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const metaSelectActive = {
  bg: 'surface',
  color: 'gray.600',
  borderColor: 'selection.500',
  '.meta-value': {
    outlineColor: 'selection.500',
    outlineStyle: 'solid',
    outlineWidth: '1px',
  },
  '.add-value': {
    outlineColor: 'selection.500',
    outlineStyle: 'solid',
    outlineWidth: '1px',
    bg: 'gray.200',
  },
};

export default {
  parts: ['container', 'item', 'submenuLabel'],
  baseStyle: {
    container: {
      bgColor: 'surface',
      padding: '0.375rem',
      borderRadius: 'md',
      maxHeight: '16rem',
      overflowY: 'auto',
    },
    item: {
      alignItems: 'center',
      bgColor: 'surface',
      borderRadius: 'base',
      color: 'gray.600',
      cursor: 'pointer',
      display: 'flex',
      fontSize: 'xs',
      fontWeight: 'medium',
      justifyContent: 'space-between',
      lineHeight: '1.25rem',
      position: 'relative',
      px: '0.375rem',
      py: 1,
      transition: 'unset',
      _dark: {
        color: 'sidebarText',
      },
      _active: {
        bg: 'selection.500',
        color: 'surface',
        _dark: {
          bg: 'gray.200',
          color: 'runway.white',
        },
      },
      _focusVisible: {
        bg: 'selection.500',
        color: 'surface',
        _dark: {
          bg: 'gray.200',
          color: 'runway.white',
        },
      },
      _selected: {
        bg: 'selection.500',
        color: 'surface',
        _dark: {
          bg: 'gray.200',
          color: 'runway.white',
        },
      },
      _hover: {
        bg: 'selection.500',
        color: 'surface',
        _dark: {
          bg: 'gray.200',
          color: 'runway.white',
        },
      },
      _disabled: { cursor: 'not-allowed', opacity: 0.4 },
    },
    submenuLabel: {
      color: 'gray.400',
      _parentAriaSelected: {
        bg: 'selection.500',
        color: 'surface',
        fill: 'surface',
        _dark: {
          bg: 'gray.200',
          color: 'runway.white',
        },
      },
      _groupHover: {
        bg: 'selection.500',
        color: 'surface',
        fill: 'surface',
        _dark: {
          bg: 'gray.200',
          color: 'runway.white',
        },
      },
    },
  },
  variants: {
    dropdown: {
      container: {
        boxShadow: 'menu',
        border: 'none',
      },
    },
    select: {
      item: {
        cursor: 'pointer',
        _hover: null,
      },
    },
    metaSelect: {
      item: {
        cursor: 'auto',
        '.add-value': {
          borderRadius: 'md',
          cursor: 'pointer',
        },
        '.meta-value': {
          bg: 'gray.300',
          cursor: 'pointer',
        },
        _selected: metaSelectActive,
        _hover: { bg: 'surface', color: 'gray.600' },
      },
      submenuLabel: {
        bg: 'surface',
        color: 'gray.600',
      },
    },
  },
} as ComponentMultiStyleConfig;
