import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useBoolean,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect } from 'react';

import { ICON_BORDER_RADIUS, LIGHT_GRAY } from 'components/RunwayApp/GlobalNavigationMenu';
import DraftScenarioAlert from 'components/ScenarioManagement/DraftScenarioAlert';
import { ScenarioCreatedByUserTag } from 'components/ScenarioManagement/ScenarioCreatedByUserTag';
import ScenarioDescription from 'components/ScenarioManagement/ScenarioDescription';
import ScenariosOptions from 'components/ScenarioManagement/ScenarioOptions';
import ScenarioSwitcher from 'components/ScenarioManagement/ScenarioSwitcher';
import { ScenarioContext } from 'components/ScenarioManagement/ScenariosContextProvider';
import { isJest } from 'helpers/environment';
import { DRAFT_SCENARIO_ALERT_DISMISSED_KEY, safeLocalStorage } from 'helpers/localStorage';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useControlledPopover from 'hooks/useControlledPopover';
import { createLayer, updateCurrentLayerName } from 'reduxStore/actions/layerMutations';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { enableBranchingFromLayersSelector } from 'selectors/launchDarklySelector';
import { currentLayerIdSelector, currentLayerIsDraftSelector } from 'selectors/layerSelector';

export const SCENARIO_BUTTON_HEIGHT = '24px';

const ScenarioManagement = () => {
  const dispatch = useAppDispatch();
  const enableBranchingFromLayers = useAppSelector(enableBranchingFromLayersSelector);
  const currentLayerId = useAppSelector(currentLayerIdSelector);

  const currentLayerIsDraft = useAppSelector(currentLayerIsDraftSelector);

  const {
    isEditingCurrentLayerName,
    setIsEditingCurrentLayerName,
    setIsEditingCurrentLayerDescription,
  } = useContext(ScenarioContext);

  const [isCreatingNewLayer, setIsCreatingNewLayer] = useBoolean();
  const [wasPopoverDisplayed, setWasPopoverDisplayed] = useBoolean();

  const {
    isOpen: isPopoverOpen,
    onOpen: onOpenPopover,
    onClose: onClosePopover,
    contentRef,
    triggerRef,
  } = useControlledPopover();

  const onNewLayerCreated = useCallback(() => {
    setIsCreatingNewLayer.on();
    setIsEditingCurrentLayerName.on();
  }, [setIsCreatingNewLayer, setIsEditingCurrentLayerName]);

  const reset = useCallback(() => {
    setIsEditingCurrentLayerName.off();
    setIsEditingCurrentLayerDescription.off();
    setIsCreatingNewLayer.off();
    setWasPopoverDisplayed.off();
  }, [
    setIsEditingCurrentLayerName,
    setIsEditingCurrentLayerDescription,
    setIsCreatingNewLayer,
    setWasPopoverDisplayed,
  ]);

  useEffect(() => {
    reset();
  }, [currentLayerId, reset]);

  useEffect(() => {
    if (currentLayerId !== DEFAULT_LAYER_ID) {
      const isDraftScenarioAlertDismissed = safeLocalStorage.getItem(
        DRAFT_SCENARIO_ALERT_DISMISSED_KEY,
      );
      const isPopoverEnabled =
        isDraftScenarioAlertDismissed == null &&
        // For some reason the popover throws an error in Jest, so disable it
        !isJest();
      if (currentLayerIsDraft && !wasPopoverDisplayed && isPopoverEnabled) {
        onOpenPopover();
      }
    }
    //  currentLayerId is needed in the dependency array to re-evaluate displaying the popover when the layer changes
  }, [currentLayerIsDraft, wasPopoverDisplayed, onOpenPopover, currentLayerId]);

  const handleClosePopover = useCallback(() => {
    onClosePopover();
    setWasPopoverDisplayed.on();
  }, [onClosePopover, setWasPopoverDisplayed]);

  const onDismissPopover = useCallback(() => {
    safeLocalStorage.setItem(DRAFT_SCENARIO_ALERT_DISMISSED_KEY, 'true');
    onClosePopover();
  }, [onClosePopover]);

  const onSave = useCallback(
    (name: string) => {
      if (isCreatingNewLayer) {
        const id = uuidv4();
        dispatch(
          createLayer({
            name,
            id,
            isDraft: false,
            parentLayerId: enableBranchingFromLayers ? currentLayerId : undefined,
          }),
        );
      } else {
        dispatch(updateCurrentLayerName(name));
        reset();
      }
    },
    [isCreatingNewLayer, dispatch, enableBranchingFromLayers, currentLayerId, reset],
  );

  const handleDoubleClick = () => {
    if (isEditingCurrentLayerName) {
      return;
    }
    setIsEditingCurrentLayerName.on();
  };

  return (
    <Flex alignItems="center">
      <Flex flexShrink={0}>
        <Flex
          alignItems="center"
          borderRadius={ICON_BORDER_RADIUS}
          height={SCENARIO_BUTTON_HEIGHT}
          _hover={{
            bgColor: isEditingCurrentLayerName
              ? 'surface'
              : currentLayerIsDraft
                ? 'scenarios.beige'
                : LIGHT_GRAY,
          }}
          sx={{
            bgColor: isEditingCurrentLayerName ? 'surface' : 'inherit',
          }}
        >
          <Popover
            isLazy
            placement="bottom-start"
            returnFocusOnClose={false}
            closeOnBlur
            onClose={handleClosePopover}
            isOpen={isPopoverOpen}
          >
            <PopoverTrigger>
              <Flex ref={triggerRef}>
                <ScenarioSwitcher
                  isCreatingNewLayer={isCreatingNewLayer}
                  onNewLayerCreated={onNewLayerCreated}
                  onDoubleClick={handleDoubleClick}
                  onSave={onSave}
                  reset={reset}
                />
              </Flex>
            </PopoverTrigger>
            <Portal>
              <PopoverContent ref={contentRef} marginTop="8px" p={0}>
                <DraftScenarioAlert onClick={onDismissPopover} />
              </PopoverContent>
            </Portal>
          </Popover>
        </Flex>
        <ScenarioCreatedByUserTag />
        <ScenariosOptions />
      </Flex>
      <Flex mx="12px">
        <Flex position="relative">
          <ScenarioDescription />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ScenarioManagement;
