import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'ChartIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.09091C2 2.48818 2.48818 2 3.09091 2H12.9091C13.5118 2 14 2.48818 14 3.09091V12.9091C14 13.5118 13.5118 14 12.9091 14H3.09091C2.48818 14 2 13.5118 2 12.9091V3.09091ZM12.0678 5.79196C12.2604 5.52231 12.1979 5.14758 11.9283 4.95498C11.6586 4.76237 11.2839 4.82482 11.0913 5.09447L8.94614 8.09767L7.40151 6.55304C7.27679 6.42833 7.10353 6.3648 6.92776 6.37935C6.75198 6.3939 6.59152 6.48504 6.489 6.62856L3.93219 10.2081C3.73958 10.4778 3.80203 10.8525 4.07168 11.0451C4.34133 11.2377 4.71606 11.1752 4.90867 10.9056L7.05381 7.9024L8.59843 9.44702C8.72315 9.57174 8.89642 9.63526 9.07219 9.62072C9.24796 9.60617 9.40842 9.51502 9.51094 9.3715L12.0678 5.79196Z"
      fill="currentColor"
    />
  ),
});
