import { formatISOWithoutMs, getDateTimeFromMonthKey } from 'helpers/dates';
import { getMutationThunkAction } from 'reduxStore/actions/submitDatasetMutation';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';
import { MonthKey } from 'types/datetime';

const mutationActions = {
  updateLastActualsTime: getMutationThunkAction<MonthKey>((lastActualsMonthKey, getState) => {
    const state = getState();
    const currentLastCloseMonthKey = datasetLastActualsMonthKeySelector(state);
    if (currentLastCloseMonthKey === lastActualsMonthKey) {
      return null;
    }

    return {
      updateLastActualsTime: formatISOWithoutMs(
        getDateTimeFromMonthKey(lastActualsMonthKey).endOf('month'),
      ),
    };
  }),
};

export const { updateLastActualsTime } = mutationActions;
