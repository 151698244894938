import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export default {
  baseStyle: {
    fontWeight: 'medium',
    transition: 'none',
    _focusVisible: {
      zIndex: 2,
    },
  },
  variants: {
    white: {
      field: {
        _focus: { borderColor: 'selection.500' },
        _placeholder: {
          color: 'gray.400',
        },
        backgroundColor: 'surface',
        borderColor: 'gray.300',
        borderWidth: '2px',
        borderRadius: 'md',
        pl: 2,
      },
    },
    light: {
      field: {
        bgColor: 'gray.100',
        borderColor: 'transparent',
        borderRadius: 'md',
        borderWidth: '2px',
        fontSize: 'sm',
        height: 'unset',
        px: 2,
        py: 1,
        _placeholder: {
          color: 'gray.400',
        },
        _focusVisible: {
          borderColor: 'selection.500',
        },
        _invalid: {
          borderColor: 'red.400',
        },
      },
    },
    inline: {
      field: {
        background: 'none',
        border: 'none',
        borderRadius: 0,
        display: 'inline',
        height: 'unset',
        padding: 0,
        width: 'unset',
        _focusVisible: { border: 'none' },
      },
    },
  },
} as ComponentSingleStyleConfig;
