import { DriverFormat, ValueType } from 'generated/graphql';
import { getDefaultMappedDriverFormula } from 'helpers/formula';
import { DimensionalProperty } from 'reduxStore/models/collections';
import { Attribute } from 'reduxStore/models/dimensions';
import { DimensionalDriver, Driver, DriverId } from 'reduxStore/models/drivers';

export const inferDriverFormatFromString = (valueString: string) => {
  const isCurrency = valueString.includes('$');
  const isPercentage = valueString.includes('%');
  if (isCurrency && !isPercentage) {
    return DriverFormat.Currency;
  } else if (isPercentage && !isCurrency) {
    return DriverFormat.Percentage;
  }
  return undefined;
};

export const attributesBySubDriverIdForDimensionalDrivers = (dimDrivers: DimensionalDriver[]) => {
  const subDriverIdToAttributes: Record<DriverId, Attribute[]> = {};
  dimDrivers.forEach((driver) => {
    driver.subdrivers.forEach((sub) => {
      subDriverIdToAttributes[sub.driverId] = sub.attributes;
    });
  });
  return subDriverIdToAttributes;
};

export const createMappedSubdriverHelper = ({
  mappedDriver,
  businessObjectSpecProperties,
  attributes,
}: {
  mappedDriver?: DimensionalDriver;
  businessObjectSpecProperties: DimensionalProperty[];
  attributes: Array<{ id: string; dimensionId: string }>;
}): {
  defaultFormula?: string;
} => {
  if (mappedDriver == null) {
    return {};
  }

  const businessSpecKeyMap = new Set(
    businessObjectSpecProperties
      .filter((prop) => prop.isDatabaseKey)
      .map((prop) => prop.dimension.id),
  );
  const attrDimIdMap = new Map<string, string>(
    attributes.map((attr) => [attr.dimensionId, attr.id]),
  );

  const attrList = mappedDriver.dimensions.map((dim) => {
    const match = attrDimIdMap.get(dim.id);
    if (match == null && businessSpecKeyMap.has(dim.id)) {
      return `${dim.id}:NULL`;
    } else if (match == null) {
      return `${dim.id}:ANY`;
    }
    return `${dim.id}:${match}`;
  });

  const attrListString = attrList.join(' ');

  const mappedDriverId = mappedDriver.id;
  const defaultFormula = getDefaultMappedDriverFormula(mappedDriverId, attrListString);

  return {
    defaultFormula,
  };
};
export const getSearchName = ({ name, attributes }: { name: string; attributes?: Attribute[] }) => {
  return `${name} ${attributes != null ? attributes.map((attr) => attr.value).join(' ') : ''}`;
};

export const getDriverDecimalPlaces = (driver: Driver | undefined, format: DriverFormat) => {
  if (format === DriverFormat.Integer) {
    return 0;
  }

  if (driver?.decimalPlaces != null) {
    return driver?.decimalPlaces;
  }

  return null;
};

// Previously, ValueType was not a required field on Drivers.
// To make sure we can safely handle parsing dataset snapshots
// and mutations from the server for legacy drivers without ValueTypes,
// we sanitize the ValueType field to ensure we can always
// rely on the frontend Driver models to always have a valid ValueType.
const DEFAULT_VALUE_TYPE = ValueType.Number;
export const sanitizeValueType = (valueType: ValueType | undefined | null): ValueType => {
  if (valueType == null) {
    return DEFAULT_VALUE_TYPE;
  }
  if (!Object.values(ValueType).includes(valueType)) {
    return DEFAULT_VALUE_TYPE;
  }
  return valueType;
};
