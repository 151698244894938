import { Box } from '@chakra-ui/react';

import DriverContextMenu from 'components/DriverContextMenu/DriverContextMenu';
import ScrollSyncPane from 'components/ScrollSyncPane/ScrollSyncPane';

interface TableSectionProps {
  children: React.ReactNode;
  'data-testid'?: string;
}

const TableSection: React.FC<TableSectionProps> = ({ children, 'data-testid': dataTestId }) => {
  return (
    <DriverContextMenu>
      <ScrollSyncPane>
        <Box
          flexShrink={1}
          flexGrow={1}
          minW={1}
          w="full"
          position="relative"
          overflowX="auto"
          fontSize="xs"
          fontWeight="normal"
          data-testid={dataTestId}
        >
          {children}
        </Box>
      </ScrollSyncPane>
    </DriverContextMenu>
  );
};

export default TableSection;
