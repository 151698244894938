import { BadgeProps } from 'components/BaseAttributeBadges/BaseAttributeBadges';
import LightBadge from 'components/LightBadge/LightBadge';
import { FALLBACK_THEME, useBadgeTheme } from 'config/badge';
import { getAttributeValueString } from 'helpers/dimensionalDrivers';
import { PseudoDimensionType } from 'reduxStore/models/dimensions';

const LightDriverAttributeBadge: React.FC<BadgeProps> = ({ attribute, color, shrink }) => {
  const theme = useBadgeTheme(color, attribute.type === PseudoDimensionType, attribute.deleted);
  const value = getAttributeValueString(attribute);

  return <LightBadge text={value} theme={theme} shrink={shrink} />;
};

export default LightDriverAttributeBadge;

export const LightDriverNoneAttributeBadge = (props: Pick<BadgeProps, 'shrink'>) => {
  return <LightBadge text="None" theme={FALLBACK_THEME} {...props} />;
};
