import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { AttributeDisplayValue } from 'components/BusinessObjectsEditableValues/DisplayValue';
import CellValueTooltipContainer from 'components/CellTooltipContainer/CellTooltipContainer';
import DisplayCell from 'components/DisplayCell/DisplayCell';
import { ValueType } from 'generated/graphql';
import useCellTooltip from 'hooks/useCellTooltip';
import { AttributeValue, NullableValue } from 'reduxStore/models/value';

interface Props {
  onDoubleClick?: React.MouseEventHandler;
  value: NullableValue<AttributeValue>['value'];
}

const AttributeDisplayCell = React.forwardRef<HTMLDivElement, Props>(
  ({ value, onDoubleClick }, ref) => {
    const tooltipData = useCellTooltip();
    return (
      <CellValueTooltipContainer tooltipData={tooltipData}>
        <DisplayCell ref={ref} onDoubleClick={onDoubleClick} valueType={ValueType.Attribute}>
          {value != null && !isEmpty(value) ? (
            <AttributeDisplayValue value={value} badgeTheme="timeSeries" />
          ) : undefined}
        </DisplayCell>
      </CellValueTooltipContainer>
    );
  },
);

export default AttributeDisplayCell;
