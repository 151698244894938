import React, { useCallback } from 'react';

import { EditableAttributeValue } from 'components/BusinessObjectsEditableValues/EditableAttributeValue';
import AttributeDisplayCell from 'components/DisplayCell/AttributeDisplayCell';
import TableCellPopover from 'components/TableCellPopover/TableCellPopover';
import TimeSeriesCellWrapper, {
  ActiveCellProps,
  TimeSeriesCellProps,
} from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapper';
import useActiveCell from 'hooks/useActiveCell';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import { createAttribute } from 'reduxStore/actions/dimensionMutations';
import { DimensionId } from 'reduxStore/models/dimensions';
import { AttributeValue, NullableValue } from 'reduxStore/models/value';

type AttributeCellProps = {
  dimensionId?: DimensionId;
};

const AttributeTimeSeriesCell: React.FC<
  TimeSeriesCellProps<AttributeValue> & AttributeCellProps
> = ({
  value,
  color,
  alert,
  onMouseDown,
  onMouseEnter,
  onContextMenu,
  showComparisonHighlight,
  onSave,
  width,
  dimensionId,
}) => {
  const { isActive } = useCellSelectionStateContext();
  return (
    <TimeSeriesCellWrapper
      color={color}
      width={width}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onContextMenu={onContextMenu}
      showComparisonHighlight={showComparisonHighlight}
    >
      {isActive ? (
        <AttributeActiveCell value={value} dimensionId={dimensionId} onSave={onSave} />
      ) : (
        <AttributeDisplayCell value={value} />
      )}
      {alert}
    </TimeSeriesCellWrapper>
  );
};

const AttributeActiveCell: React.FC<ActiveCellProps<AttributeValue> & AttributeCellProps> = ({
  value,
  dimensionId,
  onSave,
}) => {
  const { readOnly } = useBlockContext();

  const dispatch = useAppDispatch();
  const { isEditing, onStartEditingCallback, onSaveCallback, onCancel, onEndEditingCallback } =
    useActiveCell({
      onSave,
    });

  const onClearValue = useCallback(() => {
    onSaveCallback(undefined, undefined);
  }, [onSaveCallback]);

  const onUpdateValue = useCallback(
    (newValue: NullableValue<AttributeValue>) => {
      if (newValue.value != null) {
        onSaveCallback(newValue, undefined);
      } else {
        onClearValue();
      }
    },
    [onSaveCallback, onClearValue],
  );

  const onCreateAttribute = useCallback(
    (props: { dimensionId: DimensionId; newValue: string }) => {
      dispatch(
        createAttribute({
          dimensionId: props.dimensionId,
          value: props.newValue,
        }),
      );
    },
    [dispatch],
  );

  return (
    <TableCellPopover
      content={
        <EditableAttributeValue
          value={value}
          dimensionId={dimensionId ?? null}
          onUpdateValue={onUpdateValue}
          onCreateAttribute={onCreateAttribute}
          onClearValue={onClearValue}
          onClose={onEndEditingCallback}
        />
      }
      visible={isEditing && !readOnly}
      onClose={onCancel}
      autoFocus={false}
      offset={[-10, -33]}
    >
      <AttributeDisplayCell value={value} onDoubleClick={onStartEditingCallback} />
    </TableCellPopover>
  );
};

export default React.memo(AttributeTimeSeriesCell);
