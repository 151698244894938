import { Box, Flex } from '@chakra-ui/react';
import { noop } from 'lodash';
import { useCallback } from 'react';

import AddNewFieldButton from 'components/BusinessObjectTable/AddNewFieldButton';
import AttributeFieldGroupHeadingRow from 'components/BusinessObjectTable/AttributeFieldGroupHeadingRow';
import DatabaseFieldHeaderCell from 'components/BusinessObjectTable/DatabaseFieldHeaderCell';
import DatabaseNameHeaderCell from 'components/BusinessObjectTable/DatabaseNameHeaderCell';
import DatabaseTableHeaderCell from 'components/BusinessObjectTable/DatabaseTableHeaderCell';
import DimensionalPropertyHeaderCell from 'components/BusinessObjectTable/DimensionalPropertyHeaderCell';
import DriverPropertyHeaderCell from 'components/BusinessObjectTable/DriverPropertyHeaderCell';
import { OBJECT_GROUP_HEADING_ROW_HEIGHT_IN_PX } from 'components/BusinessObjectTable/ObjectGroupHeadingRow';
import TimeSeriesFieldHeader, {
  FIELD_HEADER_GUTTER_PX,
} from 'components/BusinessObjectTable/TimeSeriesFieldHeader';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import TimeSeriesColumnHeaders from 'components/TimeSeriesColumnHeaders/TimeSeriesColumnHeaders';
import { DatabaseGroupKey } from 'config/businessObjects';
import { COLUMN_HEADER_CELL_HEIGHT_PX } from 'config/cells';
import { COLUMN_TYPE_TO_NAME } from 'config/modelView';
import theme from 'config/theme';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import {
  orderedColumnKeysForObjectTablePartitionedByPrimaryKeySelector,
  orderedDimensionalPropertyColumnsToShowForBlockSelector,
  orderedDriverPropertyColumnsToShowForBlockSelector,
  visibleOrderedColumnKeysForObjectTableSelector,
} from 'selectors/collectionBlocksSelector';
import { driverPropertiesByIdSelector } from 'selectors/collectionSelector';
import {
  objectTableBlockGroupAttributeIdSelector,
  objectTableBlockGroupGroupingTypeSelector,
  objectTableBlockGroupIsExpandedSelector,
} from 'selectors/objectTableBlockSelector';
import { timeSeriesColumnsForBlockSelector } from 'selectors/rollupSelector';
import { visibleFieldSpecTimeSeriesSelector } from 'selectors/visibleFieldSpecTimeSeriesSelector';

interface Props {
  groupKey: DatabaseGroupKey;
  showOnlyTimeseries?: boolean;
}

const DatabaseTableHeaderRow: React.FC<Props> = ({ groupKey, showOnlyTimeseries }) => {
  const { blockId, gutterWidthInPxString, readOnly } = useBlockContext();

  const isExpanded = useAppSelector((state) =>
    objectTableBlockGroupIsExpandedSelector(state, { blockId, groupKey }),
  );
  const groupingType = useAppSelector((state) =>
    objectTableBlockGroupGroupingTypeSelector(state, { blockId, groupKey }),
  );
  const attributeId = useAppSelector((state) =>
    objectTableBlockGroupAttributeIdSelector(state, { blockId, groupKey }),
  );

  const visibleColumnKeys = useAppSelector((state) =>
    visibleOrderedColumnKeysForObjectTableSelector(state, blockId),
  );

  const dimensionalPropertyColumns = useAppSelector((state) =>
    orderedDimensionalPropertyColumnsToShowForBlockSelector(state, blockId),
  );

  const driverPropertyColumns = useAppSelector((state) =>
    orderedDriverPropertyColumnsToShowForBlockSelector(state, blockId),
  );

  const hasTimeSeries = useAppSelector(
    (state) => visibleFieldSpecTimeSeriesSelector(state, blockId) != null,
  );

  const hasDriverPropertyTimeSeries = useAppSelector((state) => {
    const id = visibleFieldSpecTimeSeriesSelector(state, blockId);
    if (id == null) {
      return false;
    }
    return driverPropertiesByIdSelector(state)[id] != null;
  });
  const columns = useAppSelector((state) => timeSeriesColumnsForBlockSelector(state, blockId));

  let totalHeight = COLUMN_HEADER_CELL_HEIGHT_PX;
  if (hasTimeSeries) {
    totalHeight += COLUMN_HEADER_CELL_HEIGHT_PX;
  }

  const { primaryColumnKeys, remainder } = useAppSelector((state) =>
    orderedColumnKeysForObjectTablePartitionedByPrimaryKeySelector(state, blockId),
  );

  const renderHeaderCell = useCallback(
    (id: string, idx: number, headerColumns: string[]) => {
      if (!visibleColumnKeys.includes(id)) {
        return null;
      }
      if (dimensionalPropertyColumns.includes(id)) {
        return (
          <DimensionalPropertyHeaderCell
            key={id}
            dimensionalPropertyId={id}
            groupKey={groupKey}
            isLast={idx === headerColumns.length - 1}
            topSpacingPx={hasTimeSeries ? FIELD_HEADER_GUTTER_PX : undefined}
            objectId={undefined}
          />
        );
      } else if (driverPropertyColumns.includes(id)) {
        return (
          <DriverPropertyHeaderCell
            key={id}
            driverPropertyId={id}
            groupKey={groupKey}
            isLast={idx === headerColumns.length - 1}
            topSpacingPx={hasTimeSeries ? FIELD_HEADER_GUTTER_PX : undefined}
            objectId={undefined}
          />
        );
      }
      return (
        <DatabaseFieldHeaderCell
          key={id}
          fieldSpecId={id}
          groupKey={groupKey}
          isLast={idx === headerColumns.length - 1}
          topSpacingPx={hasTimeSeries ? FIELD_HEADER_GUTTER_PX : undefined}
          objectId={undefined}
        />
      );
    },
    [dimensionalPropertyColumns, driverPropertyColumns, groupKey, hasTimeSeries, visibleColumnKeys],
  );

  return (
    /**
     * +1 is applied on the sticky zIndex of certain fields of an object row so that they can be sticky and remaining fields can slide under them.
     */
    <StickyHeader
      stickyTop={OBJECT_GROUP_HEADING_ROW_HEIGHT_IN_PX}
      zIndex={theme.zIndices.sticky + 1}
    >
      <Box width={isExpanded ? 'fit-content' : undefined}>
        {groupingType === 'attributeObjectField' && (
          <AttributeFieldGroupHeadingRow attributeId={attributeId} isExpanded={isExpanded} />
        )}
        {isExpanded && (
          <Flex bgColor="surface" height={toPxString(totalHeight)}>
            {!showOnlyTimeseries && (
              <>
                <Flex
                  pl={gutterWidthInPxString}
                  left={0}
                  position="sticky"
                  zIndex="sticky"
                  bgColor="surface"
                >
                  <DatabaseNameHeaderCell groupKey={groupKey} />
                  {primaryColumnKeys.map((id, idx) => renderHeaderCell(id, idx, primaryColumnKeys))}
                </Flex>
                {remainder.map((id, idx) => renderHeaderCell(id, idx, remainder))}
                {!readOnly && <AddNewFieldButton groupKey={groupKey} />}
              </>
            )}
            {hasTimeSeries && (
              <>
                {hasDriverPropertyTimeSeries && (
                  <>
                    <DatabaseTableHeaderCell
                      groupKey={groupKey}
                      height={toPxString(2 * COLUMN_HEADER_CELL_HEIGHT_PX)}
                      columnKey="formula"
                      title={COLUMN_TYPE_TO_NAME.formula}
                      orientation="horizontal"
                      onClick={noop}
                    />
                    <DatabaseTableHeaderCell
                      groupKey={groupKey}
                      height={toPxString(2 * COLUMN_HEADER_CELL_HEIGHT_PX)}
                      columnKey="actualsFormula"
                      title={COLUMN_TYPE_TO_NAME.actualsFormula}
                      orientation="horizontal"
                      onClick={noop}
                    />
                  </>
                )}
                {/** We have had to do a bit of a trick to have this stick against the name cell while
            allowing the month column labels to scroll underneath. To get the sticky behavior
            working, the field time series header is placed in the same scroll context as the name
            cell but it is aligned to the top of the container. The month * columns are aligned at
            the end and then shifted with translateX to appear as though they are directly under
            the header. The topSpacingPx in the column headers are necessary to make the added
            space required and to make the column dividers extend upwards. */}
                <Flex flexDir="column" borderBottom="1px solid" borderColor="gray.400">
                  <Flex flexDir="row" alignItems="flex-end">
                    <TimeSeriesColumnHeaders columns={columns} />
                  </Flex>
                  <TimeSeriesFieldHeader groupKey={groupKey} />
                </Flex>
              </>
            )}
          </Flex>
        )}
      </Box>
    </StickyHeader>
  );
};

export default DatabaseTableHeaderRow;
