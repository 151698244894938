import { noop } from 'lodash';
import { Ref, useEffect, useRef, useState } from 'react';

type UseObserveSizeResult<T extends Element> = {
  ref: Ref<T>;
  width: number;
  height: number;
};

export const useObserveSize = <T extends Element>(): UseObserveSizeResult<T> => {
  const ref = useRef<T>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!ref.current) {
      return noop;
    }

    setWidth(Math.ceil(ref.current.clientWidth));
    setHeight(Math.ceil(ref.current.clientHeight));

    const observer = new ResizeObserver((result) => {
      const { width: w, height: h } = result[0].contentRect;
      setWidth(Math.ceil(w));
      setHeight(Math.ceil(h));
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return {
    ref,
    width,
    height,
  };
};
