import { DateTime } from 'luxon';
import React from 'react';

import CellValueTooltipContainer from 'components/CellTooltipContainer/CellTooltipContainer';
import DisplayCell from 'components/DisplayCell/DisplayCell';
import { ValueType } from 'generated/graphql';
import { shortMonthWithDayFormat } from 'helpers/dates';
import useCellTooltip from 'hooks/useCellTooltip';
import { NullableValue, TimestampValue } from 'reduxStore/models/value';

import styles from './TimestampDisplayCell.module.scss';

interface Props {
  onDoubleClick?: React.MouseEventHandler;
  value: NullableValue<TimestampValue>['value'];
}

const TimestampDisplayCell = React.forwardRef<HTMLDivElement, Props>(
  ({ value, onDoubleClick }, ref) => {
    const displayValue =
      value == null || value === '' ? undefined : shortMonthWithDayFormat(DateTime.fromISO(value));
    const tooltipData = useCellTooltip();

    return (
      <CellValueTooltipContainer tooltipData={tooltipData}>
        <DisplayCell ref={ref} onDoubleClick={onDoubleClick} valueType={ValueType.Timestamp}>
          <p className={styles.displayValue}>{displayValue}</p>
        </DisplayCell>
      </CellValueTooltipContainer>
    );
  },
);

export default TimestampDisplayCell;
