import { BoxProps } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import DriverPropertyTableCell from 'components/DriverPropertyTableCell/DriverPropertyTableCell';
import EditFormulasPopover from 'components/EditFormulasPopover/EditFormulasPopover';
import Formula from 'components/Formula/Formula';
import { useFormulaDisplay } from 'components/SubmodelTable/useFormulaDisplay';
import { DriverRowContext } from 'config/driverRowContext';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import { FormulaDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellRef } from 'hooks/useCellRefContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import useDriverCellRef from 'hooks/useDriverCellRef';
import useSelectMouseHandlers, { SelectHandler } from 'hooks/useSelectMouseHandlers';
import useTableCellPopover from 'hooks/useTableCellPopover';
import { selectCell } from 'reduxStore/actions/cellNavigation';
import { shouldDisableDriverEditingSelector } from 'selectors/scenarioComparisonSelector';

const OFFSET: [number, number] = [-2, 0];

interface Props {
  isActuals: boolean;
  columnType: ModelViewColumnType;
  columnLayerId: ColumnLayerId;
  formulaDisplay: FormulaDisplay | null;
  isSticky?: boolean;
  disableEditing?: boolean;
  color?: BoxProps['color'];
}

const FormulaTableCell: React.FC<Props> = ({
  isActuals,
  columnType,
  formulaDisplay,
  isSticky,
  disableEditing,
  columnLayerId,
  color,
}) => {
  const dispatch = useAppDispatch();
  const { driverId } = useContext(DriverRowContext);
  const { blockId } = useBlockContext();
  const { cellRef } = useCellRef();
  const { isSelected } = useCellSelectionStateContext();
  const { openPopover, showPopover, closePopoverImmediate } = useTableCellPopover(cellRef, {
    isEditable: !disableEditing,
  });

  const onSelect: SelectHandler = useCallback(
    ({ range, toggle }) => {
      dispatch(selectCell(blockId, cellRef, { range, toggle }));
    },
    [blockId, dispatch, cellRef],
  );
  const { onClick, onMouseDown } = useSelectMouseHandlers(isSelected, onSelect, {
    formulaReferenceEntityId: { type: 'driver', id: driverId },
  });

  return (
    <EditFormulasPopover
      offset={OFFSET}
      driverId={driverId}
      isOpen={showPopover}
      onClose={closePopoverImmediate}
      defaultActiveTab={isActuals ? 'actuals' : 'forecast'}
    >
      <DriverPropertyTableCell
        columnType={columnType}
        columnLayerId={columnLayerId}
        onMouseDown={onMouseDown}
        onClick={onClick}
        onDoubleClick={openPopover}
        disablePointerEventsOnContents
        isSticky={isSticky != null ? isSticky : true}
      >
        {formulaDisplay != null && <Formula formulaDisplay={formulaDisplay} color={color} />}
      </DriverPropertyTableCell>
    </EditFormulasPopover>
  );
};

interface FormulaTableCellWrapperProps {
  isSticky?: boolean;
  columnLayerId: ColumnLayerId;
  columnType: 'formula' | 'actualsFormula';
}

const FormulaTableCellWrapper: React.FC<FormulaTableCellWrapperProps> = (props) => {
  const { columnType, columnLayerId } = props;
  const cellRef = useDriverCellRef({ columnType, columnLayerId });
  const { driverId, comparisonRowLayerId } = useContext(DriverRowContext);
  const { blockId } = useBlockContext();

  const layerId = columnLayerId ?? comparisonRowLayerId;

  const shouldDisableEditing = useAppSelector((state) =>
    shouldDisableDriverEditingSelector(state, blockId),
  );

  const isActuals = columnType === 'actualsFormula';

  const { color, formulaDisplay } = useFormulaDisplay({
    isActuals,
    driverId,
    layerId,
  });

  return (
    <CellContextProvider cellRef={cellRef}>
      <FormulaTableCell
        {...props}
        isActuals={isActuals}
        formulaDisplay={formulaDisplay}
        color={color}
        disableEditing={shouldDisableEditing}
      />
    </CellContextProvider>
  );
};

export default React.memo(FormulaTableCellWrapper);
