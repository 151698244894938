import React, { useContext } from 'react';

import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import TimePeriodSelectionMenu from 'components/TimePeriodSelectionMenu/TimePeriodSelectionMenu';
import {
  DriverEntityData,
  ExtDriverEntityData,
  ExtQueryEntityData,
  ObjectSpecEntityData,
  SubmodelEntityData,
} from 'types/formula';

interface Props {
  entity:
    | DriverEntityData
    | ExtDriverEntityData
    | ObjectSpecEntityData
    | SubmodelEntityData
    | ExtQueryEntityData;
  onClose?: () => void;
}

const EntityTimePeriodSelectionMenu: React.FC<Props> = ({ entity, onClose }) => {
  const { onSelectTimePeriod } = useContext(FormulaDropdownContext);

  return (
    <TimePeriodSelectionMenu
      entityDateRange={entity.data.dateRange}
      entityDriverId={entity.data.id}
      onSelectTimePeriod={onSelectTimePeriod}
      onClose={onClose}
    />
  );
};

export default React.memo(EntityTimePeriodSelectionMenu);
