import { Box, Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS } from 'config/selectMenu';
import { extractEmoji } from 'helpers/emoji';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { Driver, DriverId, DriverType } from 'reduxStore/models/drivers';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import { driversByIdForLayerSelector, topLevelDriversSelector } from 'selectors/driversSelector';
import {
  submodelIdByBlockIdSelector,
  submodelNamesByIdSelector,
} from 'selectors/submodelPageSelector';

interface DimDriverItem extends SelectItem {
  sectionId: 'dimDrivers';
  name: string;
  id: DriverId;
}

export const useDimDriverSelectItems = ({
  driverIdsToExclude,
}: {
  driverIdsToExclude: DriverId[];
}): DimDriverItem[] => {
  const driversById = useAppSelector(driversByIdForLayerSelector);
  const topLevelDrivers = useAppSelector(topLevelDriversSelector);
  const submodelIdByBlockId = useAppSelector(submodelIdByBlockIdSelector);
  const submodelNamesById = useAppSelector(submodelNamesByIdSelector);
  const groupsById = useAppSelector(driverGroupsByIdSelector);

  const items: DimDriverItem[] = useMemo(() => {
    return topLevelDrivers
      .filter((d) => driverIdsToExclude.every((id) => d.id !== id))
      .map((d) => {
        const description = getDriverMeta(d.id, driversById, 'description');
        const driverRefs = d.driverReferences ?? [];
        const submodelRef = driverRefs.find((ref) => submodelIdByBlockId[ref.blockId] != null);
        const submodelId = submodelRef != null ? submodelIdByBlockId[submodelRef.blockId] : null;
        const submodelNameWithEmoji =
          submodelId != null ? safeObjGet(submodelNamesById[submodelId]) : undefined;
        const [emoji, submodelName] =
          submodelNameWithEmoji != null ? (extractEmoji(submodelNameWithEmoji) ?? []) : [];
        const groupName =
          submodelRef?.groupId != null ? groupsById[submodelRef.groupId]?.name : undefined;

        return {
          id: d.id,
          name: d.name,
          sectionId: 'dimDrivers',
          hasNextMenu: true,
          icon: <EmojiIcon size="sm" emoji={emoji ?? null} />,
          meta: (
            <OpenDetailsModalButton
              type="driver"
              id={d.id}
              className={SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS}
              color="white"
              _hover={{ bgColor: 'selection.600' }}
              _active={{ bgColor: 'selection.600' }}
              openInNewTab
            />
          ),
          alwaysShowMeta: false,
          footer: (
            <Footer
              name={d.name}
              submodelName={submodelName}
              emoji={emoji}
              groupName={groupName}
              description={description}
            />
          ),
        };
      });
  }, [
    topLevelDrivers,
    driverIdsToExclude,
    driversById,
    submodelNamesById,
    groupsById,
    submodelIdByBlockId,
  ]);

  return items;
};

function getDriverMeta(
  driverId: DriverId,
  driversById: Record<DriverId, Driver | undefined>,
  meta: 'description',
): string | undefined {
  const driver = driversById[driverId];
  if (driver == null) {
    return undefined;
  }
  if (driver[meta] != null) {
    return driver[meta] ?? undefined;
  }

  if (driver.type === DriverType.Basic) {
    return undefined;
  }

  return driver.subdrivers.map((s) => driversById[s.driverId]?.[meta]).filter(isNotNull)[0];
}

interface FooterProps {
  emoji: string | null | undefined;
  submodelName: string | null | undefined;
  groupName: string | null | undefined;
  description: string | null | undefined;
  name: string;
}
const Footer: React.FC<FooterProps> = ({ name, emoji, submodelName, groupName, description }) => {
  return (
    <Flex columnGap={2}>
      <EmojiIcon emoji={emoji ?? null} size="sm" />
      <Flex direction="column">
        <Box display="inline-block" color="gray.500" fontWeight="medium">
          {submodelName} Model{groupName == null ? '' : ` / ${groupName}`}
        </Box>
        <Text fontWeight="semibold">{name}</Text>
        {description !== null && (
          <Box display="inline-block" color="gray.500" fontWeight="medium">
            {description}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
