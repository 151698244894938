import { IconButton } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import { stopEventPropagation } from 'helpers/browserEvent';
import Arrowhead from 'vectors/Arrowhead';

interface Props {
  onToggle: () => void;
  isActive: boolean;
  isVisible?: boolean;
  'data-testid'?: string;
  disableHoverBg?: boolean;
}

const ArrowToggleButton: React.FC<Props> = ({
  onToggle,
  isActive,
  isVisible = true,
  'data-testid': dataTestId,
  disableHoverBg = false,
}) => {
  const onMouseDown = useCallback<React.MouseEventHandler>(
    (ev) => {
      ev.stopPropagation();
      onToggle();
    },
    [onToggle],
  );

  return (
    <IconButton
      icon={
        <Arrowhead
          boxSize={2}
          color="gray.500"
          position="relative"
          direction={isActive ? 'down' : 'right'}
        />
      }
      variant="icon"
      alignItems="center"
      height={6}
      width={6}
      aria-label="Toggle expanded"
      visibility={isVisible ? 'visible' : 'hidden'}
      _hover={{ color: 'gray.500', backgroundColor: disableHoverBg ? undefined : 'gray.200' }}
      _focus={{}}
      _active={{}}
      onClick={stopEventPropagation}
      onMouseDown={onMouseDown}
      data-testid={dataTestId}
    />
  );
};

export default React.memo(ArrowToggleButton);
