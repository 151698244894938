import { Flex } from '@chakra-ui/react';
import Joi from 'joi';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import EscapableInput, { SaveHandler } from 'components/EscapableInput/EscapableInput';
import SelectItemPopoverContext from 'components/SelectItemPopoverContents/SelectItemPopoverContext';
import SelectMenu, {
  Section,
  SelectItem,
  useBlockParentSelectMenuKeyboardEvents,
} from 'components/SelectMenu/SelectMenu';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import { SELECT_MENU_IGNORE_KEYSTROKE_CLASS } from 'config/selectMenu';
import { useSelectMenuItem } from 'hooks/useSelectMenuItem';
import Trash from 'vectors/Trash';
interface EditProps {
  validationSchema?: Joi.ObjectSchema;
  onRename: (value: string) => void;
  isDisabled?: boolean;
  defaultValue: string;
}

interface EditNameSelectItemProps extends EditProps {
  idx: number;
  isFocused: boolean;
}

const EditNameSelectItem: React.FC<EditNameSelectItemProps> = ({
  idx,
  isFocused = false,
  onRename,
  validationSchema,
  isDisabled,
  defaultValue,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { closeSelectItemPopover } = useContext(SelectItemPopoverContext);
  const { onMouseLeave, onMouseEnter } = useSelectMenuItem({
    isFocused,
    idx,
  });

  const onSave = useCallback<SaveHandler>(
    (value: string) => {
      onRename(value);
    },
    [onRename],
  );

  const onKeyDown = useCallback<React.KeyboardEventHandler>(
    (ev) => {
      if (ev.key === 'Enter') {
        window.requestAnimationFrame(() => closeSelectItemPopover());
      }
    },
    [closeSelectItemPopover],
  );

  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isFocused]);

  const onCancel = useCallback(
    (reason: 'escape' | 'noop') => {
      if (reason === 'escape') {
        closeSelectItemPopover();
      }
    },
    [closeSelectItemPopover],
  );

  return (
    <Flex mx={1} my={2} onMouseEnter={onMouseEnter} onMouseOut={onMouseLeave}>
      <EscapableInput
        ref={inputRef}
        data-testid="edit-select-item-menu-input"
        variant="white"
        className={SELECT_MENU_IGNORE_KEYSTROKE_CLASS}
        validationSchema={validationSchema}
        errorPlacement="top"
        size="sm"
        fontSize="xs"
        fontWeight="medium"
        name="value"
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        onSave={onSave}
        onCancel={onCancel}
        onKeyDown={onKeyDown}
        autoFocus
        selectOnFocus={false}
      />
    </Flex>
  );
};

type RenameItem = { id: 'renameItem'; name: null; sectionId: string };
type DeleteItem = SelectItem & { onSelect: () => void };

type EditSelectItem = RenameItem | DeleteItem;

function isRenameItem(item: EditSelectItem): item is RenameItem {
  return item.id === 'renameItem';
}

const EDIT_SECTION_ID = 'edit';
const DELETE_SECTION_ID = 'delete';
const SECTIONS: Section[] = [
  {
    id: EDIT_SECTION_ID,
  },
  { id: DELETE_SECTION_ID },
];

interface Props extends EditProps {
  onDelete: () => void;
}

const EditSelectItemMenu: React.FC<Props> = ({
  onDelete,
  onRename,
  validationSchema,
  isDisabled,
  defaultValue,
}) => {
  useBlockParentSelectMenuKeyboardEvents();

  const { closeSelectItemPopover } = useContext(SelectItemPopoverContext);
  const onSelect = useCallback(
    (item: EditSelectItem) => {
      if (isRenameItem(item)) {
        return;
      }

      item.onSelect();
      closeSelectItemPopover();
    },
    [closeSelectItemPopover],
  );

  const items = useMemo<EditSelectItem[]>(() => {
    return [
      {
        id: 'renameItem',
        sectionId: EDIT_SECTION_ID,
        name: null,
      },
      {
        id: 'delete',
        name: `Delete ${defaultValue}`,
        sectionId: DELETE_SECTION_ID,
        icon: <Trash />,
        onSelect: () => {
          onDelete();
          closeSelectItemPopover();
        },
      },
    ];
  }, [defaultValue, onDelete, closeSelectItemPopover]);

  return (
    <Flex flexDirection="column" rowGap={2} w="16rem">
      <SelectMenu
        width="full"
        items={items}
        onSelect={onSelect}
        onClose={closeSelectItemPopover}
        sections={SECTIONS}
      >
        {({ item, isFocused, idx }) =>
          isRenameItem(item) ? (
            <EditNameSelectItem
              idx={idx}
              isFocused={isFocused}
              onRename={onRename}
              validationSchema={validationSchema}
              isDisabled={isDisabled}
              defaultValue={defaultValue}
            />
          ) : (
            <SelectMenuItem
              key={item.id}
              name={item.name}
              icon={item.icon}
              idx={idx}
              isFocused={isFocused}
              shortcutHint={null}
            />
          )
        }
      </SelectMenu>
    </Flex>
  );
};

export default EditSelectItemMenu;
