import React from 'react';

import FormulaEntityChunk from 'components/Formula/FormulaEntityChunk';
import { AtomicNumberDisplayChunk } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';

interface Props {
  chunk: AtomicNumberDisplayChunk;
}

const AtomicNumberChunk: React.FC<Props> = ({ chunk }) => {
  return (
    <FormulaEntityChunk label={chunk.value} timeRange={undefined} timeRangeDisplay={undefined} />
  );
};

export default AtomicNumberChunk;
