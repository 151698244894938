import React, { useCallback } from 'react';

import ColumnResizerHandle from 'components/Table/ColumnResizerHandle';
import { ModelViewColumnType } from 'config/modelView';
import { BlockType } from 'generated/graphql';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useColumnResizer from 'hooks/useColumnResizer';
import { resizeBlockColumn } from 'reduxStore/actions/blockMutations';
import { ObjectTableBlockColumnKey } from 'reduxStore/models/blocks';
import { liveResizeBlockColumn } from 'reduxStore/reducers/liveEditSlice';
import { setRoadmapDimensions } from 'reduxStore/reducers/roadmapSlice';
import { blockTypeSelector } from 'selectors/blocksSelector';
import { roadmapPanelWidthSelector } from 'selectors/planTimelineSelector';
import { columnWidthSelector, lastStickyColumnTypeSelector } from 'selectors/tableColumnsSelector';
import { MonthKey } from 'types/datetime';

interface Props {
  columnType: ModelViewColumnType | ObjectTableBlockColumnKey | MonthKey;
}

const ColumnResizer: React.FC<Props> = ({ columnType }) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const lastStickyColumn = useAppSelector((state) => lastStickyColumnTypeSelector(state, blockId));
  const isLastSticky = lastStickyColumn === columnType;

  const blockType = useAppSelector((state) => blockTypeSelector(state, blockId));

  const columnWidth = useAppSelector((state) => {
    switch (blockType) {
      case BlockType.PlanTimeline:
        return roadmapPanelWidthSelector(state);
      case BlockType.DriverGrid:
      case BlockType.ObjectTable:
      case BlockType.ObjectGrid:
      default:
        return columnWidthSelector(state, { blockId, columnType });
    }
  });

  const onEndResize = useCallback(
    (width: number) => {
      if (blockType === BlockType.PlanTimeline) {
        dispatch(
          setRoadmapDimensions({
            panelWidth: width,
          }),
        );
      } else {
        dispatch(resizeBlockColumn({ columnKey: columnType, blockId, width }));
      }
    },
    [blockId, blockType, columnType, dispatch],
  );

  const onLiveResize = useCallback(
    (width: number) => {
      if (blockType === BlockType.PlanTimeline) {
        dispatch(
          setRoadmapDimensions({
            panelWidth: width,
          }),
        );
      } else {
        dispatch(liveResizeBlockColumn({ columnKey: columnType, blockId, width }));
      }
    },
    [blockId, blockType, columnType, dispatch],
  );

  const { isResizing, startDrag } = useColumnResizer({
    columnWidth,
    onLiveResize,
    onEndResize,
  });

  return (
    <ColumnResizerHandle
      isLastSticky={isLastSticky}
      isResizing={isResizing}
      startDrag={startDrag}
    />
  );
};

export default ColumnResizer;
