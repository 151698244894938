import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';

import { BlocksPage } from 'reduxStore/models/blocks';
import { EntityTable } from 'reduxStore/models/common';
import { datasetSelector } from 'selectors/datasetSelector';
import { Selector } from 'types/redux';

const EMPTY_ENTITY_TABLE: EntityTable<BlocksPage> = { byId: {}, allIds: [] };
export const blocksPagesTableSelector: Selector<EntityTable<BlocksPage>> = createSelector(
  datasetSelector,
  (dataset) => {
    const { blocksPages } = dataset;
    return blocksPages ?? EMPTY_ENTITY_TABLE;
  },
);

export const blocksPagesSelector: Selector<BlocksPage[]> = createSelector(
  blocksPagesTableSelector,
  (pages) => {
    return sortBy(
      pages.allIds.map((id) => pages.byId[id]),
      'sortIndex',
    );
  },
);

export const blocksPageByInternalPageTypeSelector: Selector<
  NullableRecord<string, BlocksPage & { internalPageType: string }>
> = createSelector(blocksPagesSelector, (pages) => {
  const pagesByInternalPageType: NullableRecord<string, BlocksPage & { internalPageType: string }> =
    {};
  pages.forEach((page) => {
    if (page.internalPageType != null) {
      pagesByInternalPageType[page.internalPageType] = page as BlocksPage & {
        internalPageType: string;
      };
    }
  });
  return pagesByInternalPageType;
});
