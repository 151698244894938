import { createCachedSelector } from 're-reselect';

import { MODEL_VIEW_DATA_COLUMN_TYPE } from 'config/modelView';
import { BlockId } from 'reduxStore/models/blocks';
import { blockConfigObjectFieldSpecAsTimeSeriesIdSelector } from 'selectors/blocksSelector';
import { configurableColumnsSelector } from 'selectors/configurableColumnsSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { ParametricSelector } from 'types/redux';

export const isDataVisibleSelector: ParametricSelector<BlockId, boolean> = createCachedSelector(
  configurableColumnsSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  (columns, asTimeSeriesFieldSpecId) => {
    if (asTimeSeriesFieldSpecId != null) {
      return true;
    }
    return columns.find((col) => col.key === MODEL_VIEW_DATA_COLUMN_TYPE)?.visible ?? false;
  },
)(blockIdSelector);
