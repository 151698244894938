import { createContext, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { VirtualizedListItemProps } from 'components/VirtualizedList/types';
import {
  RequestAnimationJobQueueManager,
  useRequestAnimationJobQueueManager,
} from 'hooks/useRequestAnimationJobQueueManager';

/**
 * To improve FPS, we have the concept of render modes
 * FULL - A full render mode means that the rendered component is as designed, is interactive and fully functional.
 *
 * LIGHT - A light render mode means that the rendered component is a lighter version of the full component.
 * Components here should be designed to be not fully interactive, should have no or few event listeners and minimum DOM elements.
 *
 * PLACEHOLDER - A placeholder render mode means that the rendered component is even lighter than the light version and is not functional.
 * Very useful for improving FPS during scrolling
 */
export type RenderMode = 'FULL' | 'LIGHT' | 'PLACEHOLDER';
export const RenderModeContext = createContext<RenderMode>('FULL');

interface Props extends VirtualizedListItemProps {
  id: string; // a globally unique id for this component - preferably a uuid.
  /**
   * The render mode for visible components in the list if not scrolling
   */
  firstMountVisibleRenderMode?: RenderMode;
  children: React.ReactNode;
  /**
   * By default, the render loop start from PLACEHOLDER, then LIGHT and finally FULL.
   * This prop allows you to specify which mode the render loop from starts from.
   */
  defaultRenderMode?: RenderMode;
}
type UpdateRenderModeFn = (renderMode: RenderMode) => void;
export const RenderModeContextProvider = ({
  scrolling,
  visible,
  firstMountVisibleRenderMode,
  id,
  children,
  defaultRenderMode,
}: Props) => {
  const [renderMode, setMode] = useState<RenderMode>(() => {
    return firstMountVisibleRenderMode != null && !scrolling && visible
      ? firstMountVisibleRenderMode
      : (defaultRenderMode ?? 'PLACEHOLDER');
  });

  const setRenderMode = (mode: RenderMode) => {
    setMode(mode);
  };

  useSwitchRenderMode(id, renderMode, setRenderMode, scrolling, visible);

  return <RenderModeContext.Provider value={renderMode}>{children}</RenderModeContext.Provider>;
};

export const enhanceComponent = ({
  id,
  FullComponent,
  LightComponent,
  PlaceholderComponent,
  firstMountVisibleRenderMode,
}: {
  id: string; // a globally unique id for this component - preferably a uuid.
  FullComponent: React.ComponentType;
  LightComponent?: React.ComponentType;
  PlaceholderComponent?: React.ComponentType;
  /**
   * When the list is mounted the first time, it is typically not in a scrolling state and some items may be visible at the onset.
   * You want to specifically override which render mode these visible items start with using this parameter.
   * It can help give a good first impression to the user because you bypass the PLACEHOLDER -> LIGHT -> FULL render loop.
   *
   * Whereas defaultRenderMode applies to all items, firstMountVisibleRenderMode applies to only items visible at first mount when the list isn't immediately scrolled.
   */
  firstMountVisibleRenderMode?: RenderMode;
}) => {
  return memo(({ scrolling, visible, key }: VirtualizedListItemProps) => {
    const [renderMode, setMode] = useState<RenderMode>(() => {
      return firstMountVisibleRenderMode != null && !scrolling && visible
        ? firstMountVisibleRenderMode
        : 'PLACEHOLDER';
    });

    const setRenderMode = (mode: RenderMode) => {
      setMode(mode);
    };

    useSwitchRenderMode(id, renderMode, setRenderMode, scrolling, visible);

    const shouldRenderPlaceholder = renderMode === 'PLACEHOLDER';
    const shouldRenderLight = renderMode === 'LIGHT' || shouldRenderPlaceholder;

    if (shouldRenderPlaceholder && PlaceholderComponent != null) {
      return <PlaceholderComponent key={key ?? id} />;
    } else if (shouldRenderLight && LightComponent != null) {
      return <LightComponent key={key ?? id} />;
    } else {
      return <FullComponent key={key ?? id} />;
    }
  });
};

const TIME_TO_SWITCH_TO_FULL_RENDER_MS = 60;

const addFullRenderJob = (
  requestAnimationJobManager: RequestAnimationJobQueueManager,
  id: string,
  setRenderMode: UpdateRenderModeFn,
) => {
  return setTimeout(() => {
    requestAnimationJobManager.submitJob({
      requestId: id,
      run: () => {
        setRenderMode('FULL');
      },
    });
  }, TIME_TO_SWITCH_TO_FULL_RENDER_MS);
};

let renderModeJobCounter = 0;
const useSwitchRenderMode = (
  id: string,
  renderMode: RenderMode,
  updateRenderMode: (renderMode: RenderMode) => void,
  isScrolling?: boolean,
  isVisible?: boolean,
) => {
  const animationManager = useRequestAnimationJobQueueManager();

  //memoize the ids. no need to recreate them on every render
  const ids = useMemo(() => {
    // Add unique id to the job since the same driver
    // maybe be on a page multiple times.
    renderModeJobCounter += 1;
    return {
      fullRenderJobId: `${id}-full-render-job-${renderModeJobCounter}`,
      lightRenderJobId: `${id}-light-render-job-${renderModeJobCounter}`,
    };
  }, [id]);

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const { fullRenderJobId, lightRenderJobId } = ids;

  const disposeFn = useCallback(() => {
    if (timeoutIdRef.current != null) {
      clearTimeout(timeoutIdRef.current);
    }
    animationManager?.cancelJob(lightRenderJobId);
    animationManager?.cancelJob(fullRenderJobId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullRenderJobId, lightRenderJobId]);

  useEffect(
    () => disposeFn,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // If the component is not visible, dispose of the render jobs
  useEffect(() => {
    if (!isVisible) {
      disposeFn();
    }
  }, [disposeFn, isVisible]);

  useEffect(() => {
    let isUnmounted = false;
    if (!isScrolling && isVisible) {
      animationManager?.submitJob({
        requestId: lightRenderJobId,
        run: () => {
          if (!isUnmounted && renderMode !== 'FULL') {
            if (renderMode === 'PLACEHOLDER') {
              updateRenderMode('LIGHT');
            }
            timeoutIdRef.current = addFullRenderJob(
              animationManager,
              fullRenderJobId,
              updateRenderMode,
            );
          }
        },
      });
    }
    return () => {
      isUnmounted = true;
      disposeFn();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrolling, isVisible]);
};
