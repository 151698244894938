import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react';
import memoize from 'lodash/memoize';
import { DateTime, MonthNumbers } from 'luxon';
import React, { useCallback, useContext } from 'react';

import DatePickerHeader from 'components/MonthPicker/DatePickerHeader';
import DatePickerPager from 'components/MonthPicker/DatePickerPager';
import DatePickerTextInput from 'components/MonthPicker/DatePickerTextInput';
import { MonthPickerProps } from 'components/MonthPicker/MonthPicker';
import { DatePickerReactContext } from 'config/datePickerContext';
import { shortMonthWithDayFormat, TODAY } from 'helpers/dates';

// Not indexed the same as weekdays in DateTime, which are
// 1 => Monday ... 7 => Sunday
const WEEKDAYS = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];

const alignWeekdayIndexToSunday = (weekday: number) => {
  // The DateTime weekday number are 1 => Monday ... 7 => Sunday
  // mod 7 turns it to 0 => Sunday ... 6 => Saturday which is what we want.
  return weekday % 7;
};

const getStartOfMonth = memoize(
  (monthNumber: MonthNumbers, yearNumber: number): DateTime => {
    return DateTime.utc(yearNumber, monthNumber);
  },
  (monthNumber: MonthNumbers, yearNumber: number) => `${monthNumber}-${yearNumber}`,
);

interface DaysOfMonth {
  (monthNumber: MonthNumbers, yearNumber: number): number[];
}

const getLastDaysOfPrevMonth: DaysOfMonth = (monthNumber, yearNumber) => {
  const month = getStartOfMonth(monthNumber, yearNumber);
  const prevMonth = month.minus({ day: 1 });

  const weekdayStartOfThisMonth = alignWeekdayIndexToSunday(month.weekday);

  return Array.from(
    { length: weekdayStartOfThisMonth },
    (_unused, i) => prevMonth.daysInMonth - weekdayStartOfThisMonth + i + 1,
  );
};

const getFirstDaysOfNextMonth: DaysOfMonth = (monthNumber, yearNumber) => {
  const month = getStartOfMonth(monthNumber, yearNumber);
  const nextMonth = month.endOf('month').plus({ day: 1 });

  const weekdayStartOfNextMonth = alignWeekdayIndexToSunday(nextMonth.weekday);

  return Array.from({ length: 7 - weekdayStartOfNextMonth }, (_unused, i) => i + 1);
};

const getDaysOfMonth: DaysOfMonth = (monthNumber, yearNumber) => {
  const month = getStartOfMonth(monthNumber, yearNumber);

  return Array.from({ length: month.daysInMonth }, (_unused, i) => i + 1);
};

const DayPicker: React.FC<MonthPickerProps> = ({
  onDateSelect,
  selected = TODAY,
  minDate,
  maxDate,
  focused = true,
}) => {
  return (
    <DatePickerHeader selected={selected} pageBy="month" onDateSelect={onDateSelect}>
      <DatePickerTextInput
        selected={selected}
        focused={focused}
        formatter={shortMonthWithDayFormat}
        minDate={minDate}
        maxDate={maxDate}
      />
      <Flex w="full" pl="0.25rem">
        <DatePickerPager />
      </Flex>
      <DayPickerBody selected={selected} minDate={minDate} maxDate={maxDate} />
    </DatePickerHeader>
  );
};

interface DayPickerBodyProps {
  minDate: DateTime;
  maxDate: DateTime;
  selected: DateTime;
}

const DayPickerBody: React.FC<DayPickerBodyProps> = ({ selected, minDate, maxDate }) => {
  const { pagedMonth, pagedYear, decrement, increment, onDateSelect } =
    useContext(DatePickerReactContext);

  const isDayDisabled = (day: number) => {
    const startOfDay = DateTime.utc(pagedYear, pagedMonth, day).startOf('day');
    return startOfDay < minDate || startOfDay > maxDate;
  };

  const onDaySelect = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      const day = Number(ev.currentTarget.value);
      const date = DateTime.utc(pagedYear, pagedMonth, day).startOf('day');
      onDateSelect(date, { reason: 'button' });
    },
    [onDateSelect, pagedMonth, pagedYear],
  );

  return (
    <Grid flex="1 0" templateColumns="repeat(7, 1fr)" rowGap={1} columnGap={1} w={60} mt={0}>
      {WEEKDAYS.map((weekdayName) => (
        <Box key={weekdayName} p={0} h={5} w={7}>
          <Text color="gray.500" fontSize="xxs" fontWeight="bold" textAlign="center">
            {weekdayName}
          </Text>
        </Box>
      ))}
      {getLastDaysOfPrevMonth(pagedMonth, pagedYear).map((day) => (
        <OutOfMonthDayButton key={`prev-${pagedMonth}-${day}`} onClick={decrement} value={day}>
          {day}
        </OutOfMonthDayButton>
      ))}
      {getDaysOfMonth(pagedMonth, pagedYear).map((day) => {
        const isSelected =
          selected != null &&
          selected.day === day &&
          selected.month === pagedMonth &&
          selected.year === pagedYear;

        return (
          <DayButton
            key={`${pagedMonth}-${day}`}
            data-selected={isSelected || undefined}
            isDisabled={isDayDisabled(day)}
            tabIndex={isSelected ? 0 : undefined}
            onClick={onDaySelect}
            value={day}
          >
            {day}
          </DayButton>
        );
      })}
      {getFirstDaysOfNextMonth(pagedMonth, pagedYear).map((day) => (
        <OutOfMonthDayButton key={`next-${pagedMonth}-${day}`} onClick={increment} value={day}>
          {day}
        </OutOfMonthDayButton>
      ))}
    </Grid>
  );
};

const DayButton: typeof Button = (props) => (
  <Button variant="text" size="sm" p={0} h={7} w={7} {...props} />
);

const OutOfMonthDayButton: typeof Button = (props) => <DayButton opacity={0.4} {...props} />;

export default React.memo(DayPicker);
