import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'AirTableIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <path
        d="M26.7952 5.55967L4.44623 14.8072C3.20341 15.3216 3.21629 17.0872 4.46689 17.5829L26.909 26.4825C28.8808 27.2645 31.0768 27.2645 33.0486 26.4825L55.4911 17.5826C56.7413 17.0872 56.7549 15.3219 55.5114 14.8076L33.1631 5.55933C31.1242 4.71564 28.8338 4.71564 26.7949 5.55933"
        fill="#FCB400"
      />
      <path
        d="M31.9701 31.342V53.5747C31.9701 54.6319 33.0364 55.3563 34.0193 54.9666L59.0267 45.2599C59.3056 45.1494 59.5449 44.9576 59.7134 44.7094C59.882 44.4612 59.9721 44.168 59.972 43.868V21.6356C59.972 20.5781 58.9058 19.8541 57.9228 20.2437L32.9154 29.9504C32.6366 30.061 32.3974 30.2529 32.2288 30.501C32.0603 30.7492 31.9701 31.042 31.9701 31.342Z"
        fill="#18BFFF"
      />
      <path
        d="M26.1304 32.4892L18.7088 36.0727L17.9552 36.4369L2.2886 43.9436C1.29584 44.4227 0.0279541 43.699 0.0279541 42.5957V21.7288C0.0279541 21.3297 0.232605 20.9851 0.507054 20.7259C0.619646 20.6137 0.747381 20.5179 0.886539 20.4412C1.2606 20.2166 1.79459 20.1566 2.24862 20.3362L26.0058 29.7495C27.2133 30.2286 27.3082 31.9203 26.1304 32.4892Z"
        fill="#F82B60"
      />
      <path
        d="M26.1305 32.4892L18.7088 36.0727L0.50708 20.7255C0.619694 20.6135 0.747428 20.5178 0.886565 20.4412C1.26063 20.2166 1.79462 20.1566 2.24865 20.3362L26.0058 29.7495C27.2134 30.2286 27.3082 31.9203 26.1305 32.4892Z"
        fill="black"
        fillOpacity="0.25"
      />
    </>
  ),
});
