import { createCachedSelector } from 're-reselect';

import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import {
  BusinessObjectFieldValueSelectorProps,
  getCacheKeyForFieldSelector,
} from 'selectors/businessObjectTimeSeriesSelector';
import {
  businessObjectSelector,
  materializedFieldForSpecIdAndObjectIdSelector,
} from 'selectors/businessObjectsSelector';
import { ParametricSelector } from 'types/redux';

export const businessObjectFieldIsLinkedToIntegrationSelector: ParametricSelector<
  BusinessObjectFieldValueSelectorProps,
  boolean
> = createCachedSelector(
  (state: RootState, { businessObjectId }: BusinessObjectFieldValueSelectorProps) =>
    businessObjectSelector(state, businessObjectId),
  (state: RootState) => businessObjectSpecsByIdForLayerSelector(state),
  (state: RootState, props: BusinessObjectFieldValueSelectorProps) =>
    materializedFieldForSpecIdAndObjectIdSelector(state, props),
  function businessObjectFieldIsLinkedToIntegrationSelector(object, specsById, field) {
    const formula = field?.value?.actuals?.formula ?? '';
    if (formula !== '' || object?.extKey == null || field == null) {
      return false;
    }

    const spec = specsById[object.specId];
    const fieldSpec = spec?.fields.find((f) => f.id === field.fieldSpecId);
    if (fieldSpec?.extFieldSpecKey == null) {
      return false;
    }
    return true;
  },
)(getCacheKeyForFieldSelector);
