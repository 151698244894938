import { DateTime } from 'luxon';
import { createSelector } from 'reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  getAllNestedImpactedDriverIds,
  getComputedEventGroupRangeWithDefault,
} from 'helpers/eventGroups';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId, PopulatedEventGroup } from 'reduxStore/models/events';
import { OrgUser } from 'reduxStore/models/user';
import { PaneType, PlanDetailPaneTab, isPlanDetailPane } from 'reduxStore/reducers/detailPaneSlice';
import { openDetailPaneSelector } from 'selectors/detailPaneSelectors';
import {
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  populatedEventGroupsWithLiveEditsByIdSelector,
  populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { usersInSelectedOrgByIdSelector } from 'selectors/selectedOrgSelector';
import { Selector } from 'types/redux';

export const detailPanePlanIdSelector: Selector<EventGroupId | null> = createSelector(
  openDetailPaneSelector,
  (detailPane) => {
    if (!isPlanDetailPane(detailPane)) {
      return null;
    }
    return detailPane?.eventGroupId;
  },
);

export const mustDetailPanePlanIdSelector: Selector<EventGroupId> = createSelector(
  detailPanePlanIdSelector,
  (planId) => {
    if (planId == null) {
      throw new Error('no plan detail pane open');
    }

    return planId;
  },
);

const mustDetailPanePlanSelector: Selector<PopulatedEventGroup> = createSelector(
  mustDetailPanePlanIdSelector,
  populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
  (id, groupsById) => {
    const group = groupsById[id];
    if (group == null) {
      throw new Error('unable to find plan');
    }
    return group;
  },
);

export const detailPanePlanSelector: Selector<PopulatedEventGroup | undefined | null> =
  createSelector(
    detailPanePlanIdSelector,
    populatedEventGroupsWithLiveEditsByIdSelector,
    (planId, groupsById) => {
      return planId == null ? null : groupsById[planId];
    },
  );

export const detailPanePlanName: Selector<string | null> = createSelector(
  detailPanePlanSelector,
  (eventGroup) => eventGroup?.name ?? null,
);

export const detailPanePlanRangeSelector: Selector<{ start: DateTime; end: DateTime }> =
  createSelector(mustDetailPanePlanSelector, (eventGroup) => {
    const { start, end } = getComputedEventGroupRangeWithDefault(eventGroup, {});
    return { start: DateTime.fromISO(start), end: DateTime.fromISO(end) };
  });

export const detailPanePlanOwner: Selector<OrgUser | null> = createSelector(
  detailPanePlanSelector,
  usersInSelectedOrgByIdSelector,
  (eventGroup, usersById) => {
    if (eventGroup?.ownerId == null) {
      return null;
    }

    return usersById[eventGroup.ownerId];
  },
);

export const detailPanePlanIdPath: Selector<EventGroupId[]> = createSelector(
  detailPanePlanIdSelector,
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  (eventGroupId, groupsById) => {
    const path = [];
    let curr: EventGroupId | null | undefined = eventGroupId;

    while (curr != null) {
      path.push(curr);
      curr = groupsById[curr]?.parentId;
    }

    return path.reverse();
  },
);

export const planDetailPaneTabSelector: Selector<PlanDetailPaneTab | null> = createSelector(
  openDetailPaneSelector,
  (detailPane) => {
    if (!isPlanDetailPane(detailPane)) {
      return null;
    }
    return detailPane.tab;
  },
);

export const planDetailPaneDriverIdsSelector: Selector<DriverId[]> = createDeepEqualSelector(
  detailPanePlanSelector,
  (plan) => (plan == null ? [] : getAllNestedImpactedDriverIds(plan)),
);

export const isPlanDetailPaneOpenSelector = createSelector(
  openDetailPaneSelector,
  (detailPaneState) => detailPaneState?.type === PaneType.Plan,
);
