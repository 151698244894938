import { createCachedSelector } from 're-reselect';

import { getCacheKeyForLayerSelector, SelectorWithLayerParam } from 'helpers/layerSelectorFactory';
import { EntityTable } from 'reduxStore/models/common';
import { Submodel } from 'reduxStore/models/submodels';
import { getGivenOrCurrentLayerId, layersSelector } from 'selectors/layerSelector';

const EMPTY_ENTITY_TABLE: EntityTable<Submodel> = { byId: {}, allIds: [] };

export const submodelEntityTableForLayerSelector: SelectorWithLayerParam<EntityTable<Submodel>> =
  createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
    const layer = layers[layerId];

    if (layer == null) {
      return EMPTY_ENTITY_TABLE;
    }

    return layer.submodels;
  })(getCacheKeyForLayerSelector);
