import emojiData from '@emoji-mart/data';
import EMOJI_REGEX from 'emojibase-regex';
import sample from 'lodash/sample';

const LABEL_REGEX = new RegExp(`^(?<emoji>${EMOJI_REGEX.source})?\\s?(?<label>.*)$`);

export const pickRandomEmoji = (currentEmoji: string | null): string | null => {
  const categoryId = sample(['objects', 'places', 'activity']);
  const category = emojiData.categories.find((cat) => cat.id === categoryId);

  if (!category) {
    throw new Error(`Could not find emoji category: ${category}`);
  }

  let newEmoji = currentEmoji ?? null;

  // Pick a random emoji until it's different than the current emoji
  while (newEmoji === currentEmoji) {
    const emojiName = sample(category.emojis) as string;
    newEmoji = emojiData.emojis[emojiName].skins[0].native;
  }

  return newEmoji;
};

export function extractEmoji(input: string): [string | null, string] {
  if (input.startsWith('null') && input[4] != null) {
    return [null, input.substring(4)];
  }
  const { label, emoji } = input.match(LABEL_REGEX)?.groups ?? {};
  return [emoji ?? null, label];
}

export function addEmoji(emoji: string | undefined | null, name: string) {
  if (emoji == null) {
    return name;
  }

  return `${emoji}${name}`;
}

export function formatEmojiName(emojiString: string) {
  const [emoji, label] = extractEmoji(emojiString);
  if (emoji == null) {
    return label;
  }

  return `${emoji} ${label}`;
}
