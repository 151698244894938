import { createSelector } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import {
  accessCapabilityAwareBlockConfigShowRestrictedSelector,
  blockConfigShowRestrictedSelector,
} from 'selectors/blocksSelector';
import {
  businessObjectFieldSpecByIdSelector,
  businessObjectFieldSpecSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { ParametricSelector, Selector } from 'types/redux';

import { blockIdSelector } from './constSelectors';
import { businessObjectSpecForBlockSelector } from './orderedFieldSpecIdsSelector';

type BusinessObjectFieldsSpecRestrictedProps = {
  fieldSpecId: BusinessObjectFieldSpecId;
  blockId?: BlockId;
};

export const allRestrictedBusinessObjectFieldSpecIdsSelector: Selector<
  Set<BusinessObjectFieldSpecId>
> = createSelector(businessObjectFieldSpecByIdSelector, (businessObjectFieldSpecs) => {
  return new Set(
    Object.keys(businessObjectFieldSpecs).filter((id) => {
      const spec = businessObjectFieldSpecs[id];
      return spec?.isRestricted;
    }),
  );
});

export const hasBusinessObjectFieldSpecRestrictsSelector: ParametricSelector<
  BusinessObjectFieldSpecId,
  boolean
> = createCachedSelector(
  (state: RootState, id: BusinessObjectFieldSpecId) =>
    businessObjectFieldSpecSelector(state, { id }),
  (fieldSpec) => {
    if (fieldSpec?.isRestricted == null) {
      return false;
    }

    return fieldSpec.isRestricted;
  },
)((_, businessObjectFieldSpecId) => businessObjectFieldSpecId);

export const isBusinessObjectFieldSpecRestrictedSelector: ParametricSelector<
  BusinessObjectFieldsSpecRestrictedProps,
  boolean
> = createCachedSelector(
  (state: RootState, props: BusinessObjectFieldsSpecRestrictedProps) =>
    businessObjectFieldSpecSelector(state, { id: props.fieldSpecId }),
  (state: RootState, props: BusinessObjectFieldsSpecRestrictedProps) => {
    if (props.blockId == null) {
      return false;
    }

    return blockConfigShowRestrictedSelector(state, props.blockId);
  },
  accessCapabilitiesSelector,
  (spec, showRestrictedForBlock, accessCapabilities) => {
    if (showRestrictedForBlock) {
      return false;
    }

    if (accessCapabilities.canWritePermissions) {
      return false;
    }

    if (spec == null || spec.isRestricted == null) {
      return false;
    }

    return spec.isRestricted;
  },
)((_, props: BusinessObjectFieldsSpecRestrictedProps) => `${props.blockId},${props.fieldSpecId}`);

export const getRestrictedBusinessObjectFieldsSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpec[]
> = createCachedSelector(
  accessCapabilityAwareBlockConfigShowRestrictedSelector,
  businessObjectSpecForBlockSelector,
  (showRestrictedForBlock, objectSpec) => {
    if (showRestrictedForBlock || objectSpec == null) {
      return [];
    }

    return objectSpec.fields.filter((field) => field.isRestricted);
  },
)({ keySelector: blockIdSelector });
