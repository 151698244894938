import { DateTime } from 'luxon';
import { useMemo } from 'react';

import {
  DisposeFn,
  RequestValueProps,
  useWebWorkerDataProviderContext,
} from 'components/WebWorkerDataProvider/Context';
import { getMonthKey } from 'helpers/dates';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { isNotNull } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { DisposeFnKey, useRequestPaginatedRows } from 'hooks/useRequestPaginatedRows';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { LayerId } from 'reduxStore/models/layers';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import {
  ObjectDiff,
  ObjectFieldDiff,
  comparisonLayerIdsForBlockSelector,
} from 'selectors/scenarioComparisonSelector';

type ComparisonRow = [BusinessObjectId, ObjectDiff[]];

const getKey = ({
  id,
  layerId,
  dateRange,
}: {
  id: FormulaEntityTypedId['id'];
  layerId: LayerId;
  dateRange: [DateTime, DateTime];
}): DisposeFnKey => {
  const startMonthKey = getMonthKey(dateRange[0]);
  const endMonthKey = getMonthKey(dateRange[1]);
  return `${id}.${layerId}.${startMonthKey}.${endMonthKey}`;
};

const makeGetKeys =
  (dateRange: [DateTime, DateTime], layerIds: Array<string | undefined>) =>
  (row: ComparisonRow): DisposeFnKey[] => {
    const diffs = row[1].filter((diff): diff is ObjectFieldDiff => !('diffType' in diff));

    return layerIds
      .flatMap((layerId) =>
        layerId == null
          ? null
          : diffs.map(({ fieldId }) => getKey({ id: fieldId, layerId, dateRange })),
      )
      .filter(isNotNull);
  };

const makeRequestRowValue =
  (
    dateRange: [DateTime, DateTime],
    layerIds: Array<string | undefined>,
    requestValue: (props: RequestValueProps) => DisposeFn,
  ) =>
  (row: ComparisonRow): Array<[DisposeFnKey, DisposeFn]> => {
    const diffs = row[1].filter((diff): diff is ObjectFieldDiff => !('diffType' in diff));

    return layerIds
      .flatMap((layerId) => {
        if (layerId == null) {
          return null;
        }

        return diffs.map(({ fieldId }) => {
          return [
            getKey({ id: fieldId, layerId, dateRange }),
            requestValue({
              id: fieldId,
              type: 'objectField',
              dateRange,
              layerId,
            }),
          ] as [DisposeFnKey, DisposeFn];
        });
      })
      .filter(isNotNull);
  };

export const useRequestDatabaseComparisonDataView = ({ rows }: { rows: ComparisonRow[] }) => {
  const { blockId } = useBlockContext();

  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const layerComparison: Array<string | undefined> = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const { requestValue } = useWebWorkerDataProviderContext();
  const requestRowValue = useMemo(
    () => makeRequestRowValue(dateRange, layerComparison, requestValue),
    [dateRange, layerComparison, requestValue],
  );

  useRequestPaginatedRows({
    rows,
    pageSize: rows.length,
    getKeys: makeGetKeys(dateRange, layerComparison),
    requestRowValue,
  });
};
