import React, { useContext } from 'react';

import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ReferencePill from 'components/FormulaInput/ReferencePill';
import {
  AtomicNumberIcon,
  AtomicNumberMetadata,
  DraftDecoratorComponentProps,
} from 'types/formula';
import FormulaIcon from 'vectors/Formula';

// Since the data should be serializable, we pass the icon as a string
// and map it to the icon component on render.
const ICONS: Record<AtomicNumberIcon, JSX.Element> = {
  formula: <FormulaIcon />,
};

const AtomicNumberDecorator: React.FC<DraftDecoratorComponentProps> = ({
  contentState,
  entityKey,
  offsetKey,
}) => {
  const { activeEntity, keysInSelection } = useContext(FormulaSelectionContext);
  const entity = contentState.getEntity(entityKey ?? '');
  const { label, tooltip, icon, error } = entity.getData() as AtomicNumberMetadata;
  const isSelectedEntity = activeEntity?.key === entityKey;

  if (entityKey == null) {
    return null;
  }

  const isSelected = isSelectedEntity || keysInSelection.has(entityKey);
  return (
    <ReferencePill
      icon={icon != null ? ICONS[icon] : undefined}
      isSelected={isSelected}
      error={error}
      label={label}
      offsetKey={offsetKey}
      tooltip={tooltip}
    >
      {null}
    </ReferencePill>
  );
};

export default AtomicNumberDecorator;
