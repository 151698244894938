import { ScaleLinear, ScaleOrdinal, ScaleTime } from 'd3-scale';
import { createContext } from 'react';

import { FullAggregateChartData } from 'components/DriverChartContextProvider/DriverChartContextProvider';
import { ChartSize } from 'generated/graphql';
import { DriverFormat, DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { MonthKey } from 'types/datetime';

export const POINT_RADIUS = 4;
export const EVENT_POINT_RADIUS = 6;
export const EVENT_COMPARE_LINE_COLOR = 'yellow.500';

export type DriverChartSize =
  | ChartSize.Medium
  | ChartSize.Large
  | ChartSize.ExtraLarge
  | 'inspector'
  | 'sparkline';

type ChartMargins = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export const INSPECTOR_MIN_CHART_HEIGHT = 180;
export const INSPECTOR_MIN_HEIGHT = 180;
export const INSPECTOR_MAX_HEIGHT = 650;
export const INSPECTOR_DEFAULT_HEIGHT = 240;
export const INSPECTOR_SNAP_DIMENSIONS = { y: [12] };

const KEY_METRIC_CHART_MARGINS: ChartMargins = { top: 16, bottom: 32, left: 12, right: 12 };

// Some tinkering was done to figure out the offsets to accomodate the y-axis labels.
const KEY_METRIC_LARGE_CHART_MARGINS: ChartMargins = {
  top: 22,
  bottom: 48,
  left: 56,
  right: 16,
};

// Some tinkering was done to figure out the offsets to accomodate the y-axis labels.
const KEY_METRIC_EXTRA_LARGE_CHART_MARGINS: ChartMargins = {
  top: 22,
  bottom: 48,
  left: 48,
  right: 40,
};

export const INSPECTOR_CHART_MARGINS: ChartMargins = { top: 0, bottom: 16, left: 0, right: 0 };

const ZERO_CHART_MARGINS: ChartMargins = { top: 0, bottom: 0, left: 0, right: 0 };

export const DRIVER_CHART_MARGINS_BY_SIZE: Record<DriverChartSize, ChartMargins> = {
  [ChartSize.Medium]: KEY_METRIC_CHART_MARGINS,
  [ChartSize.Large]: KEY_METRIC_LARGE_CHART_MARGINS,
  [ChartSize.ExtraLarge]: KEY_METRIC_EXTRA_LARGE_CHART_MARGINS,
  sparkline: ZERO_CHART_MARGINS,
  inspector: INSPECTOR_CHART_MARGINS,
};

export const STROKE_WIDTH_BY_SIZE: Record<DriverChartSize, number> = {
  [ChartSize.Medium]: 2,
  [ChartSize.Large]: 2,
  [ChartSize.ExtraLarge]: 2,
  sparkline: 2,
  inspector: 3,
};

export const DASH_ARRAY_BY_SIZE: Record<DriverChartSize, string> = {
  [ChartSize.Medium]: '6',
  [ChartSize.Large]: '6',
  [ChartSize.ExtraLarge]: '6',
  sparkline: '4',
  inspector: '8',
};

export type DriverChartDatum = {
  x: Date;
  y: number;
  monthKey: MonthKey;
};

export type DriverChartDatumWithLayerId = DriverChartDatum & { layerId: LayerId };

export const DEFAULT_MAX_VALUE_BY_DRIVER_FORMAT: Record<DriverFormat, number> = {
  // N.B. the "Auto" value here should never come into use
  [DriverFormat.Auto]: 10,
  [DriverFormat.Currency]: 10,
  [DriverFormat.Integer]: 10,
  [DriverFormat.Number]: 10,
  [DriverFormat.Percentage]: 1,
};

export type DriverChartContextData = {
  height: number;
  width: number;
  driverIds: DriverId[];
  allDriverIds: DriverId[];
  timeScale: ScaleTime<number, number, never>;
  valueScale: ScaleLinear<number, number, never>;
  format: DriverFormat;
  size: DriverChartSize;
  baselineLayerId: LayerId;
  endDateTime: MonthKey;
  startDateTime: MonthKey;
  monthKeysWithForecastData: string[];
  driverIdsToColors: Record<DriverId, string>;
  toggleDriver: (driverId: DriverId) => void;
  fullAggregateChartData: FullAggregateChartData;
  colorScale?: ScaleOrdinal<string, string>;
  isScenariosLegendVisible?: boolean;
  toggleIsScenariosLegendVisible?: () => void;
};

export const DriverChartContext = createContext<DriverChartContextData | null>(null);
