import { createSelector } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import { DEFAULT_DRIVER_GROUP_NAME } from 'config/driverGroups';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  SelectorWithLayerParam,
  getCacheKeyForLayerSelector,
  layerParamMemo,
} from 'helpers/layerSelectorFactory';
import { nullSafeEqual, safeObjGet } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import { Dimension, DimensionId } from 'reduxStore/models/dimensions';
import { DriverGroup, DriverGroupId, DriverGroupIdOrNull } from 'reduxStore/models/driverGroup';
import {
  BasicDriver,
  DimensionalDriver,
  Driver,
  DriverId,
  DriverType,
} from 'reduxStore/models/drivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { fieldSelector } from 'selectors/constSelectors';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import { dimensionalDriversBySubDriverIdSelector } from 'selectors/driversSelector';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import { navSubmodelIdSelector } from 'selectors/navSubmodelSelector';
import { blockIdBySubmodelIdSelector } from 'selectors/submodelPageSelector';
import { driversBySubmodelIdSelector } from 'selectors/submodelSelector';
import { submodelsByIdSelector } from 'selectors/submodelsByIdSelector';
import { ParametricSelector } from 'types/redux';

function driversSort({
  drivers,
  dimDriverBySubDriverId,
  dimensionsById,
  blockId,
}: {
  drivers: Driver[];
  dimDriverBySubDriverId: Record<string, DimensionalDriver>;
  dimensionsById: Record<DimensionId, Dimension>;
  blockId: BlockId;
}) {
  const sortIndexByDriverId: NullableRecord<DriverId, number> = Object.fromEntries(
    drivers.map((d) => {
      return [d.id, d.driverReferences?.find((r) => r.blockId === blockId)?.sortIndex ?? undefined];
    }),
  );

  return drivers.sort((lhs, rhs) => {
    const LEFT_IS_LESS = -1;
    const LEFT_IS_MORE = 1;

    const lsi = sortIndexByDriverId[lhs.id];
    const rsi = sortIndexByDriverId[rhs.id];
    if (lsi != null) {
      if (rsi == null) {
        return LEFT_IS_LESS;
      }

      if (lsi > rsi) {
        return LEFT_IS_MORE;
      }
      if (lsi < rsi) {
        return LEFT_IS_LESS;
      }
      return 0;
    }

    if (lsi == null && rsi != null) {
      return LEFT_IS_MORE;
    }

    const lhsDimDriver = dimDriverBySubDriverId[lhs.id];
    const rhsDimDriver = dimDriverBySubDriverId[rhs.id];
    if (lhsDimDriver != null && lhsDimDriver === rhsDimDriver) {
      const subdriverCompare = compareSubdrivers(lhs, rhs, lhsDimDriver, dimensionsById);
      if (subdriverCompare != null) {
        return subdriverCompare;
      }
    }

    return lhs.name.localeCompare(rhs.name);
  });
}

const compareSubdrivers = (
  lhs: Driver,
  rhs: Driver,
  dimDriver: DimensionalDriver,
  dimensionsById: Record<DimensionId, Dimension>,
) => {
  const lhsSubdriver = dimDriver.subdrivers.find((subdriver) => subdriver.driverId === lhs.id);
  const rhsSubdriver = dimDriver.subdrivers.find((subdriver) => subdriver.driverId === rhs.id);
  if (lhsSubdriver == null || rhsSubdriver == null) {
    return undefined;
  }
  for (const lhsAttr of lhsSubdriver.attributes) {
    const dim = dimensionsById[lhsAttr.dimensionId];
    const rhsAttr = rhsSubdriver?.attributes.find((a) => a.dimensionId === lhsAttr.dimensionId);
    if (rhsAttr == null || dim == null) {
      return undefined;
    }
    const lhsIdx = dim.attributes.findIndex((a) => a.id === lhsAttr.id);
    const rhsIdx = dim.attributes.findIndex((a) => a.id === rhsAttr.id);
    if (lhsIdx === -1 || rhsIdx === -1) {
      return undefined;
    }
    if (lhsIdx !== rhsIdx) {
      return lhsIdx - rhsIdx;
    }
  }
  return undefined;
};

export type GroupWithDrivers = {
  id?: DriverGroupId;
  name: string;
  driverIds: DriverId[];
};

export function getOrderedGroupDrivers({
  groupId,
  blockId,
  basicDrivers,
  dimDriverBySubDriverId,
  dimensionsById,
}: {
  groupId: DriverGroupIdOrNull;
  blockId: BlockId;
  basicDrivers: Driver[];
  dimDriverBySubDriverId: Record<string, DimensionalDriver>;
  dimensionsById: Record<string, Dimension>;
}): DriverId[] {
  const drivers = basicDrivers.filter((driver) => {
    const driverGroupRef = driver.driverReferences?.find((ref) => ref.blockId === blockId);
    return nullSafeEqual(groupId, driverGroupRef?.groupId);
  });
  return driversSort({ drivers, blockId, dimDriverBySubDriverId, dimensionsById }).map((d) => d.id);
}

interface SubmodelForLayerProps {
  submodelId: SubmodelId;
  layerId?: string;
}
const EMPTY_GROUP: GroupWithDrivers[] = [];
export const submodelGroupsSelector: ParametricSelector<SubmodelForLayerProps, GroupWithDrivers[]> =
  createCachedSelector(
    (state: RootState, { submodelId }: SubmodelForLayerProps) =>
      blockIdBySubmodelIdSelector(state)[submodelId],
    (state: RootState, { layerId }: SubmodelForLayerProps) =>
      driverGroupsByIdSelector(state, layerParamMemo(layerId)),
    (state: RootState, { submodelId, layerId }: SubmodelForLayerProps) => {
      const driversBySubmodelId = driversBySubmodelIdSelector(state, layerParamMemo(layerId));
      return driversBySubmodelId[submodelId];
    },
    (state: RootState, { submodelId, layerId }: SubmodelForLayerProps) => {
      const submodelsById = submodelsByIdSelector(state, layerParamMemo(layerId));
      const submodel = submodelsById[submodelId];
      return submodel?.sortedDriverGroupIds ?? [];
    },
    (state: RootState, { layerId }: SubmodelForLayerProps) =>
      dimensionalDriversBySubDriverIdSelector(state, layerParamMemo(layerId)),
    dimensionsByIdSelector,
    (
      blockId,
      driverGroupsById,
      submodelDrivers,
      submodelSortedDriverGroupIds,
      dimDriverBySubDriverId,
      dimensionsById,
      // eslint-disable-next-line max-params
    ) => {
      if (blockId == null) {
        return EMPTY_GROUP;
      }

      const groups = groupDriversForBlock(
        (submodelDrivers ?? []).filter((driver) => driver.type !== DriverType.Dimensional),
        blockId,
        {
          driverGroupsById,
          dimDriverBySubDriverId,
          submodelSortedDriverGroupIds,
          dimensionsById,
        },
      );

      return groups.filter((group, _idx, arr) => {
        const isNamedGroup = group.id != null;
        const isEmpty = group.driverIds.length === 0;
        const isOnlyGroup = arr.length === 1;
        return isNamedGroup || !isEmpty || isOnlyGroup;
      });
    },
  )({
    keySelector: (state, { submodelId, layerId }: SubmodelForLayerProps) =>
      `${submodelId}.${getCacheKeyForLayerSelector(state, { layerId })}`,
    selectorCreator: createDeepEqualSelector,
  });

const NULL_GROUP_ID = '__NULL__';

function groupDriversForBlock(
  drivers: BasicDriver[],
  blockId: BlockId,
  {
    driverGroupsById,
    submodelSortedDriverGroupIds,
    dimDriverBySubDriverId,
    dimensionsById,
  }: {
    driverGroupsById: Record<DriverGroupId, DriverGroup>;
    submodelSortedDriverGroupIds: DriverGroupIdOrNull[];
    dimDriverBySubDriverId: Record<DriverId, DimensionalDriver>;
    dimensionsById: Record<DimensionId, Dimension>;
  },
): GroupWithDrivers[] {
  const driverIdToSortIndex: Record<DriverId, number | null | undefined> = {};

  const groupIdToDrivers = submodelSortedDriverGroupIds.reduce<
    Record<DriverGroupId | typeof NULL_GROUP_ID, BasicDriver[]>
  >((acc, groupId) => {
    acc[groupId ?? NULL_GROUP_ID] = [];
    return acc;
  }, {});

  // We must always have a null group
  if (groupIdToDrivers[NULL_GROUP_ID] == null) {
    groupIdToDrivers[NULL_GROUP_ID] = [];
  }

  for (const driver of drivers) {
    const reference = driver.driverReferences?.find((ref) => {
      return ref.blockId === blockId;
    });

    const sortIndex = reference?.sortIndex;
    driverIdToSortIndex[driver.id] = sortIndex;

    let groupId = reference?.groupId ?? NULL_GROUP_ID;
    if (groupId !== NULL_GROUP_ID) {
      groupId = safeObjGet(driverGroupsById[groupId])?.id ?? NULL_GROUP_ID;
    }
    groupIdToDrivers[groupId] ??= [];
    groupIdToDrivers[groupId].push(driver);
  }

  for (const driversForGroup of Object.values(groupIdToDrivers)) {
    driversForGroup.sort((lhs, rhs) => {
      return sortDrivers(lhs, rhs, driverIdToSortIndex, dimDriverBySubDriverId, dimensionsById);
    });
  }

  const groups = Object.entries(groupIdToDrivers).map(([groupId, driversForGroup]) => {
    let name = DEFAULT_DRIVER_GROUP_NAME;
    if (groupId !== NULL_GROUP_ID) {
      name = safeObjGet(driverGroupsById[groupId])?.name ?? DEFAULT_DRIVER_GROUP_NAME;
    }

    return {
      id: groupId !== NULL_GROUP_ID ? groupId : undefined,
      name,
      driverIds: driversForGroup.map(({ id }) => id),
    };
  });

  return groups.sort(({ id: firstId }, { id: secondId }) => {
    const firstIndex = submodelSortedDriverGroupIds.indexOf(firstId ?? null);
    const secondIndex = submodelSortedDriverGroupIds.indexOf(secondId ?? null);
    return firstIndex - secondIndex;
  });
}

function sortDrivers(
  lhs: BasicDriver,
  rhs: BasicDriver,
  driverIdToSortIndex: Record<DriverId, number | null | undefined>,
  dimDriverBySubDriverId: Record<DriverId, DimensionalDriver>,
  dimensionsById: Record<DimensionId, Dimension>,
) {
  const lhsSort = driverIdToSortIndex[lhs.id];
  const rhsSort = driverIdToSortIndex[rhs.id];
  if (lhsSort == null || rhsSort == null) {
    if (lhsSort != null && rhsSort == null) {
      return -1;
    }
    if (lhsSort == null && rhsSort != null) {
      return 1;
    }
    return 0;
  }

  const sort = lhsSort - rhsSort;
  if (sort !== 0 || (sort === 0 && driverIdToSortIndex[lhs.id] !== Number.MIN_SAFE_INTEGER)) {
    return sort;
  }

  const lhsDimDriver = dimDriverBySubDriverId[lhs.id];
  const rhsDimDriver = dimDriverBySubDriverId[rhs.id];
  if (lhsDimDriver != null && lhsDimDriver === rhsDimDriver) {
    const subdriverCompare = compareSubdrivers(lhs, rhs, lhsDimDriver, dimensionsById);
    if (subdriverCompare != null) {
      return subdriverCompare;
    }
  }

  return lhs.name.localeCompare(rhs.name);
}

export const submodelTableGroupsSelector: SelectorWithLayerParam<GroupWithDrivers[]> =
  createSelector(
    (state: RootState) => state,
    getGivenOrCurrentLayerId,
    (state, layerId) => {
      const navSubmodelId = navSubmodelIdSelector(state);
      if (navSubmodelId == null) {
        return EMPTY_GROUP;
      }
      return submodelGroupsSelector(state, { submodelId: navSubmodelId, layerId });
    },
  );

interface DriverGroupForLayerProps {
  groupId: DriverGroupId | undefined;
  layerId?: string;
}

const cacheKeyForDriverGroupForLayerSelector = (
  state: RootState,
  { layerId, groupId }: DriverGroupForLayerProps,
) => `${getGivenOrCurrentLayerId(state, { layerId })}.${groupId}`;

export const submodelDriverIdsByGroupIdSelector: ParametricSelector<
  DriverGroupForLayerProps,
  DriverId[]
> = createCachedSelector(
  (state: RootState, { layerId }: DriverGroupForLayerProps) =>
    submodelTableGroupsSelector(state, layerParamMemo(layerId)),
  fieldSelector('groupId'),
  (submodelTableGroups, groupId) => {
    if (groupId == null) {
      return [];
    }

    const driverIds = submodelTableGroups.find((group) => group.id === groupId)?.driverIds;

    return driverIds ?? [];
  },
)(cacheKeyForDriverGroupForLayerSelector);
