import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'Star',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <path
      d="M7.53601 3.15543C7.70396 2.73719 8.29603 2.73719 8.46399 3.15542L9.43478 5.5729C9.5063 5.751 9.67345 5.87245 9.86494 5.88543L12.4641 6.06167C12.9137 6.09216 13.0967 6.65525 12.7508 6.94422L10.7517 8.61454C10.6044 8.7376 10.5405 8.9341 10.5874 9.12022L11.2229 11.6466C11.3329 12.0837 10.8539 12.4317 10.4722 12.1921L8.26585 10.8069C8.10331 10.7049 7.89669 10.7049 7.73415 10.8069L5.5278 12.1921C5.1461 12.4317 4.6671 12.0837 4.77706 11.6466L5.41263 9.12022C5.45945 8.9341 5.3956 8.7376 5.24832 8.61454L3.24916 6.94422C2.90329 6.65525 3.08625 6.09216 3.53592 6.06167L6.13506 5.88543C6.32655 5.87245 6.4937 5.751 6.56522 5.5729L7.53601 3.15543Z"
      fill="currentColor"
    />
  ),
});
