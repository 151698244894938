import { CellRef, CellType } from 'config/cells';
import { deepClone } from 'helpers/clone';
import { openObjectDetailPane } from 'reduxStore/reducers/openDetailPane';
import { AppThunk } from 'reduxStore/store';
import { businessObjectDetailBlockIdSelector } from 'selectors/databaseSelector';
import {
  detailPaneNavigationNextObjectsSelector,
  detailPaneNavigationObjectSpecIdSelector,
} from 'selectors/detailPaneNavigationSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { monthKeyForActualsOrDateRangeSelector } from 'selectors/pageDateRangeSelector';
import {
  focusFieldSpecIdSelector,
  prevailingActiveCellSelector,
} from 'selectors/prevailingCellSelectionSelector';

export const navigateDetailPane =
  (direction: 'up' | 'down'): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const surroundingObjIds = detailPaneNavigationNextObjectsSelector(state);
    if (surroundingObjIds == null) {
      return;
    }
    const objectId = surroundingObjIds[direction];
    if (objectId == null) {
      return;
    }

    const objectSpecId = detailPaneNavigationObjectSpecIdSelector(state);
    if (objectSpecId == null) {
      return;
    }
    const layerId = currentLayerIdSelector(state);

    const blockId = businessObjectDetailBlockIdSelector(state, objectSpecId);
    if (blockId == null) {
      return;
    }
    const focusFieldSpecId = focusFieldSpecIdSelector(state, {
      specId: objectSpecId,
      layerId,
    });
    if (focusFieldSpecId == null) {
      return;
    }
    const monthKey = monthKeyForActualsOrDateRangeSelector(state, { blockId, layerId });
    const activeCell = prevailingActiveCellSelector(state);
    let initialActiveCell: CellRef | undefined;

    if (activeCell?.type === CellType.ObjectFieldTimeSeries) {
      initialActiveCell = deepClone(activeCell);
      initialActiveCell.rowKey.objectId = objectId;
    }

    dispatch(
      openObjectDetailPane({
        objectId,
        objectSpecId,
        blockId,
        focusFieldSpecId,
        monthKey,
      }),
    );
  };
