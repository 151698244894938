import { IconButton } from '@chakra-ui/react';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import { SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS } from 'config/selectMenu';
import MoreMenu from 'vectors/MoreMenu';

type ColorOptions = {
  color?: string;
  backgroundColor?: string;
  hoverColor?: string;
  hoverBackgroundColor?: string;
  activeColor?: string;
  activeBackgroundColor?: string;
};

export type MoreMenuOptionsColorScheme = {
  [key in 'selected' | 'unselected']?: ColorOptions;
};

const COLOR_SCHEME: MoreMenuOptionsColorScheme = {
  selected: {
    color: 'white',
    hoverBackgroundColor: 'selection.600',
    activeBackgroundColor: 'selection.600',
  },
  unselected: {
    color: 'gray.500',
    hoverColor: 'gray.200',
    hoverBackgroundColor: 'gray.200',
    activeColor: 'gray.300',
    activeBackgroundColor: 'selection.600',
  },
};

interface Props {
  icon?: React.ReactElement;
  visibleOnlyOnGroupHover?: boolean;
  isOpen?: boolean;
  isSelected?: boolean;
  onMouseDown?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  tooltip?: React.ReactNode;
  colorScheme?: MoreMenuOptionsColorScheme;
  orientation?: 'horizontal' | 'vertical';
}

const MoreMenuButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      icon,
      isOpen = false,
      visibleOnlyOnGroupHover = false,
      isSelected = false,
      onMouseDown,
      onKeyDown,
      tooltip,
      colorScheme: givenColorScheme,
      orientation = 'vertical',
    },
    ref,
  ) => {
    const colorScheme = (givenColorScheme ?? COLOR_SCHEME)[isSelected ? 'selected' : 'unselected'];

    return (
      <Tooltip label={tooltip}>
        <IconButton
          ref={ref}
          data-testid="more-menu-button"
          className={SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS}
          padding={1}
          aria-label="More options"
          icon={
            icon ?? (
              <MoreMenu
                boxSize={4}
                transform={orientation === 'horizontal' ? 'rotate(90deg)' : undefined}
              />
            )
          }
          variant="icon"
          onMouseDown={onMouseDown}
          onKeyDown={onKeyDown}
          visibility={visibleOnlyOnGroupHover && !isOpen ? 'hidden' : undefined}
          _groupHover={visibleOnlyOnGroupHover ? { visibility: 'visible' } : undefined}
          _hover={{
            backgroundColor: colorScheme?.hoverBackgroundColor,
            color: colorScheme?.hoverColor,
          }}
          _active={{
            backgroundColor: colorScheme?.activeBackgroundColor,
            color: colorScheme?.activeColor,
          }}
          backgroundColor={colorScheme?.backgroundColor}
          color={colorScheme?.color}
        />
      </Tooltip>
    );
  },
);

export default MoreMenuButton;
