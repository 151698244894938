import {
  DependencyListener,
  DependencyListenerArgs,
} from 'helpers/formulaEvaluation/ForecastCalculator/DependencyListener';
import evaluate, {
  entrypointForEntityTypeAndValueType,
} from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { ModelingError } from 'helpers/formulaEvaluation/ForecastCalculator/ModelingError';
import { Dependency } from 'types/dependencies';

export function getDependencies(args: { formula: string } & DependencyListenerArgs): Dependency[] {
  const { formula, ...restArgs } = args;
  const { valueType, entityType } = restArgs;
  const listener = new DependencyListener(restArgs);
  const entrypoint = entrypointForEntityTypeAndValueType(entityType ?? null, valueType);
  try {
    return evaluate(formula, listener, entrypoint);
  } catch (e) {
    if (e instanceof ModelingError) {
      return [];
    } else {
      throw e;
    }
  }
}
