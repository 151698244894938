import { useMemo } from 'react';

import { RichText } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { blockConfigRichTextDriverMapSelector } from 'selectors/blocksSelector';

const DEFAULT_RICH_TEXT = { bold: false, italic: false, strike: false, underline: false };

const useCellTextStyles: (driverId: string | undefined, monthKey: string) => RichText = (
  driverId,
  monthKey,
) => {
  const { blockId } = useBlockContext();
  const { rows, cells, columns } = useAppSelector((state) =>
    blockConfigRichTextDriverMapSelector(state, blockId),
  );

  const richText = useMemo(() => {
    if (driverId == null) {
      return DEFAULT_RICH_TEXT;
    }

    const rowRichText = rows[driverId]?.richText ?? DEFAULT_RICH_TEXT;
    const compositeKey = `${driverId}_${monthKey}`;
    const cellRichText =
      cells[compositeKey] != null ? cells[compositeKey]?.richText : DEFAULT_RICH_TEXT;
    const columnRichText = columns[monthKey]?.richText ?? DEFAULT_RICH_TEXT;

    return mergeRichText(cellRichText, rowRichText, columnRichText);
  }, [rows, cells, columns, driverId, monthKey]);

  return richText;
};

const mergeRichText = (...richTexts: RichText[]): RichText => {
  return richTexts.reduce(
    (acc, curr) => ({
      bold: acc?.bold || curr?.bold,
      italic: acc?.italic || curr?.italic,
      strike: acc?.strike || curr?.strike,
      underline: acc?.underline || curr?.underline,
    }),
    DEFAULT_RICH_TEXT,
  );
};

export default useCellTextStyles;
