import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'Folder',
  viewBox: '0 0 16 16',
  defaultProps: { boxSize: 4 },
  path: (
    <g fill="currentColor">
      <path d="M2.90909 1.95459C1.85623 1.95459 1 2.81082 1 3.86368V12.1364C1 13.1893 1.85623 14.0455 2.90909 14.0455H13.0909C14.1438 14.0455 15 13.1893 15 12.1364V5.13641C15 4.08354 14.1438 3.22732 13.0909 3.22732H7.07839C6.79044 3.22732 6.5085 3.12838 6.28418 2.94829L5.73917 2.51265C5.28958 2.15311 4.72479 1.95459 4.14888 1.95459H2.90909ZM2.90909 3.22732H4.14888C4.43684 3.22732 4.71908 3.32626 4.94371 3.50635L5.4881 3.94198C5.93769 4.30153 6.50248 4.50004 7.07839 4.50004H13.0909C13.4419 4.50004 13.7273 4.78545 13.7273 5.13641V5.45459H2.27273V3.86368C2.27273 3.51273 2.55814 3.22732 2.90909 3.22732ZM2.27273 6.72732H13.7273V12.1364C13.7273 12.4874 13.4419 12.7728 13.0909 12.7728H2.90909C2.55814 12.7728 2.27273 12.4874 2.27273 12.1364V6.72732Z" />
    </g>
  ),
});
