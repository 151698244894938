import { useMemo } from 'react';

import useOnClickOutside from 'hooks/useOnClickOutside';

export default function useOnClickOutsidePopover(
  contentRef: React.MutableRefObject<HTMLElement | null>,
  triggerRef: React.MutableRefObject<HTMLElement | null> | null,
  handler: (event: MouseEvent) => unknown,
  { selector, disabled }: { selector?: string; disabled?: boolean },
) {
  const clickOutsideRef = useMemo(
    () => [contentRef, triggerRef ?? { current: null }],
    [contentRef, triggerRef],
  );
  useOnClickOutside(clickOutsideRef, handler, {
    disabled,
    selector,
  });
}
