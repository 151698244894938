import React, { useCallback, useEffect, useState } from 'react';

import { preventEventDefault } from 'helpers/browserEvent';

export function useRovingFocus(numItems: number, startFocusIdx = 0) {
  const [focusIdx, setFocusIdx] = useState(startFocusIdx);
  useEffect(() => {
    setFocusIdx(startFocusIdx);
  }, [numItems, startFocusIdx]);

  const onKeyDown = useCallback(
    (ev: KeyboardEvent | React.KeyboardEvent) => {
      switch (ev.key) {
        case 'ArrowUp': {
          preventEventDefault(ev);
          setFocusIdx((idx) => (idx === 0 ? numItems - 1 : idx - 1));
          break;
        }
        case 'ArrowDown': {
          preventEventDefault(ev);
          setFocusIdx((idx) => (idx === numItems - 1 ? 0 : idx + 1));
          break;
        }
        default:
          break;
      }
    },
    [numItems],
  );

  return { focusIdx, setFocusIdx, onKeyDown };
}
