import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'DimensionIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  d: 'M2.9929 1C2.44744 1 2 1.45369 2 2.00675V4.37888C2 4.93195 2.44744 5.38563 2.9929 5.38563H6.46165C7.0071 5.38563 7.45455 4.93195 7.45455 4.37888V2.00675C7.45455 1.45369 7.0071 1 6.46165 1H2.9929ZM3.09091 2.10613H6.36364V4.2795H3.09091V2.10613ZM4.18075 5.38671V11.6827C4.18075 12.5975 4.91493 13.3419 5.81712 13.3419H8.54545V13.9878C8.54545 14.5464 8.99277 15 9.54368 15H13.0018C13.5527 15 14 14.5464 14 13.9878V11.5876C14 11.029 13.5527 10.5755 13.0018 10.5755H9.54368C8.99277 10.5755 8.54545 11.029 8.54545 11.5876V12.2347H9.09091V13.3408L10.0444 12.5424V11.4374L8.54439 12.2358H5.81712C5.51603 12.2358 5.27166 11.988 5.27166 11.6827V7.81123H8.54439V6.7051H5.27166V5.38671H4.18075ZM9.54368 5.04483C8.99277 5.04483 8.54545 5.49838 8.54545 6.05698V8.4572C8.54545 9.01579 8.99277 9.46935 9.54368 9.46935H13.0018C13.5527 9.46935 14 9.01579 14 8.4572V6.05698C14 5.49838 13.5527 5.04483 13.0018 5.04483H9.54368Z',
});
