import { useMemo } from 'react';

import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { KEYBOARD_SHORTCUTS } from 'config/shortcuts';
import IndentLeft from 'vectors/IndentLeft';
import IndentRight from 'vectors/IndentRight';

export const useIndentSelectMenuItem = (): SelectItem[] => {
  const items: SelectItem[] = useMemo(() => {
    return [
      {
        id: 'indent-driver',
        icon: <IndentRight />,
        name: 'Indent',
        sectionId: 'display-options',
        meta: KEYBOARD_SHORTCUTS.indent,
      },
      {
        id: 'outdent-driver',
        icon: <IndentLeft />,
        name: 'Outdent',
        sectionId: 'display-options',
        meta: KEYBOARD_SHORTCUTS.outdent,
      },
    ];
  }, []);

  return items;
};
