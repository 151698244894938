import { createSelector } from 'reselect';

import { AuthenticatedUser } from 'generated/graphql';
import { isRunwayEmployee } from 'helpers/user';
import { LoggedInUser } from 'reduxStore/models/user';
import { LoginState } from 'reduxStore/reducers/loginSlice';
import { Selector } from 'types/redux';

export const loginSelector: Selector<LoginState> = (state) => state.login;

// loggedInUser could be impersonated, use authenticatedUser if you need the original user instead
export const loggedInUserSelector: Selector<LoggedInUser | undefined> = (state) => state.login.user;
export const authenticatedUserSelector: Selector<AuthenticatedUser | LoggedInUser | undefined> = (
  state,
) => state.login.authenticatedUser ?? state.login.user;

export const isImpersonatingSelector: Selector<boolean> = createSelector(
  authenticatedUserSelector,
  loggedInUserSelector,
  (authUser, loggedInUser) => {
    if (loggedInUser == null || authUser == null) {
      return false;
    }

    return authUser.id !== loggedInUser.id;
  },
);

export const authenticatedUserIdSelector: Selector<string | undefined> = createSelector(
  authenticatedUserSelector,
  (user) => user?.id,
);

export const isRunwayEmployeeSelector: Selector<boolean> = createSelector(
  authenticatedUserSelector,
  (authUser) => isRunwayEmployee(authUser),
);

export const isSuperPermissionSelector: Selector<boolean> = createSelector(
  authenticatedUserSelector,
  (authUser) => {
    return authUser?.hasSuperPermission ?? false;
  },
);
