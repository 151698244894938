import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { noop } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';

import FormulaInputWrapper from 'components/FormulaInputWrapper/FormulaInputWrapper';
import { DatabaseTableContext } from 'config/databaseTableContext';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useFormulaInput from 'hooks/useFormulaInput';
import { useShakeControls } from 'hooks/useShakeControls';
import { updatePropertyFormula } from 'reduxStore/actions/businessObjectSpecMutations';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import {
  businessObjectFieldSpecSelector,
  fieldSpecDefaultForecastFormulaSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { fieldSpecFormulaDisplaySelector } from 'selectors/formulaDisplaySelector';
import { RawFormula } from 'types/formula';
import FormulaIcon from 'vectors/Formula';
import TrashIcon from 'vectors/Trash';

interface Props {
  fieldSpecId: BusinessObjectFieldSpecId;
  onClose?: () => void;
}

const DatabasePropertyFormulaPopoverContents = React.forwardRef<HTMLDivElement, Props>(
  ({ fieldSpecId, onClose }, ref) => {
    const dispatch = useAppDispatch();
    const { objectSpecId } = useContext(DatabaseTableContext);
    const businessObjectFieldSpec = useAppSelector((state) =>
      businessObjectFieldSpecSelector(state, { id: fieldSpecId }),
    );
    const rawFormula = useAppSelector((state) =>
      fieldSpecDefaultForecastFormulaSelector(state, { id: fieldSpecId }),
    );
    const formulaDisplay = useAppSelector((state) =>
      fieldSpecFormulaDisplaySelector(state, { id: fieldSpecId }),
    );

    const onSaveFormula = useCallback(
      (formula: RawFormula | null) => {
        if (formula != null) {
          dispatch(updatePropertyFormula({ objectSpecId, fieldSpecId, formula }));
        }
        onClose?.();
      },
      [dispatch, fieldSpecId, objectSpecId, onClose],
    );

    const onDiscardFormula = useCallback(() => {
      dispatch(updatePropertyFormula({ objectSpecId, fieldSpecId, formula: '' }));
      onClose?.();
    }, [dispatch, fieldSpecId, objectSpecId, onClose]);

    const formulaEntityId: FormulaEntityTypedId = useMemo(
      () => ({ type: 'objectFieldSpec' as const, id: fieldSpecId }),
      [fieldSpecId],
    );

    const { controls, shake } = useShakeControls();
    const { onSave, onCancel, formulaError, onChange } = useFormulaInput({
      onSaveFormula,
      formulaEntityId,
      isActuals: false,
      onSaveFormulaWithError: shake,
      onClose: onClose ?? noop,
      rawFormula,
      formulaDisplay,
    });

    return (
      <Box
        ref={ref}
        as={motion.div}
        animate={controls}
        bgColor="surface"
        boxShadow="menu"
        borderRadius="md"
        p={2}
      >
        <Flex
          justifyContent="space-between"
          color="gray.500"
          fontWeight="medium"
          fontSize="xs"
          pb={2}
        >
          <Flex alignItems="center" columnGap={1}>
            <FormulaIcon />
            <Text> {businessObjectFieldSpec?.name} </Text>
          </Flex>
          <IconButton
            icon={<TrashIcon boxSize={3} />}
            size="xs"
            variant="icon"
            p={2}
            color="gray.500"
            aria-label="Clear formula"
            onClick={onDiscardFormula}
          />
        </Flex>
        <FormulaInputWrapper
          formulaEntityId={formulaEntityId}
          isActuals={false}
          onSave={onSave}
          onCancel={onCancel}
          formulaDisplay={formulaDisplay}
          formulaError={formulaError}
          onChange={onChange}
          canCreateDriver={false}
        />
      </Box>
    );
  },
);

export default React.memo(DatabasePropertyFormulaPopoverContents);
