const baseColors = {
  white: '#FDFCFC',
  surface: {
    _light: '#FDFCFC',
    _dark: '#1D2629',
  },
  gray: {
    50: { _light: '#F9FAFB', _dark: '#1D2327' },
    100: { _light: '#F7F8FA', _dark: '#192124' }, // top bar
    200: { _light: '#EFF1F5', _dark: '#3B474E' }, // heading highlights + dimension labels
    300: { _light: '#DEE3EB', _dark: '#424C53' }, // Keylines
    400: { _light: '#B9C0CD', _dark: '#626E74' }, // Keylines
    500: { _light: '#949DAD', _dark: '#8A9499' }, // Page Text
    600: { _light: '#464A53', _dark: '#FDFCFC' }, // Page Text
  },
  orange: {
    500: { _light: '#EFAE6E' },
  },
  blue: {
    100: '#F2F6FC',
    200: '#D6E4F8',
    300: { _light: '#B7D1F5', _dark: '#335177' }, // Blue Keylines
    500: { _light: '#4F7DBF', _dark: '#81CAFF' },
    600: '#335177',
    runway: '#AAC4E5',
  },
  selection: {
    100: { _light: '#F5FBFF', _dark: '#2D455B' }, // row select
    200: { _light: '#DDF1FE', _dark: '#2D455B' }, // filter selected
    300: { _light: '#C2E6FE' },
    400: { _light: '#90D2FE' },
    500: { _light: '#18A0FB', _dark: '#81CAFF' }, // cell outline
    600: { _light: '#097FCE' },
    os: 'SelectedItem',
  },
  red: {
    100: '#FFF7F5',
    200: '#FDDAD3',
    300: '#FCBFB5',
    400: '#FBA393',
    500: '#F88672',
    600: '#CB5C4D',
  },
  purple: {
    100: '#F9F1F9',
    150: '#F6EAF5',
    200: '#F0DBEF',
    300: '#E0B8DF',
    400: '#D194CF',
    500: '#C270BF',
    600: '#9D4D9A',
  },
  yellow: {
    100: '#FFF7F0',
    200: '#FEEAD7',
    300: '#FDD8B4',
    400: '#FCC592',
    500: '#FBB26F',
    600: '#DD893C',
  },
  green: {
    400: '#A1DBC6',
    500: '#89C588',
  },
  failure: '#F75E4A',
  success: '#2ECA2B',
  runway: {
    black: '#192227',
    white: '#FDFCFC',
    blue: '#2A85B9',
    yellow: '#F9A600',
    red: '#F0624F',
    orange: '#F9A600',
  },
  highlight: {
    green1: { _light: '#DEFADF', _dark: '#32423E' },
    green2: { _light: '#CBF2CF', _dark: '#32423E' },
    green3: { _light: '#56C179', _dark: '#32423E' },
  },
  watermelon: {
    100: { _light: '#FDE5E9', _dark: '#4C3C41' },
    200: { _light: '#FBC3C7', _dark: '#4C3C41' },
    300: { _light: '#F9A7A1', _dark: '#4C3C41' },
    400: { _light: '#F78375', _dark: '#4C3C41' },
    500: { _light: '#F0624F', _dark: '#4C3C41' },
    600: { _light: '#D9503E', _dark: '#4C3C41' },
  },
  seablue: {
    100: '#D6E8F8',
    200: '#B8D7F0',
    300: '#9AC2E8',
    400: '#63A1D2',
    500: '#2A85B9',
    600: '#2175A5',
  },
  sunburst: {
    100: { _light: '#FEF4DF', _dark: '#514A3A' },
    200: { _light: '#FDEEBF', _dark: '#514A3A' },
    300: { _light: '#FCDE80', _dark: '#514A3A' },
    400: { _light: '#FABF42', _dark: '#514A3A' },
    500: { _light: '#F9A600', _dark: '#514A3A' },
    600: { _light: '#E49300', _dark: '#514A3A' },
  },
  lilac: {
    100: '#F0E7FD',
    200: '#E7D9F9',
    300: '#D8C5F2',
    400: '#C5A6E9',
    500: '#B08CE1',
    600: '#9B75CC',
  },
  azuresky: {
    100: '#E9F9FF',
    200: '#CBF0FE',
    300: '#AEE4FD',
    400: '#7DD3FC',
    500: '#4FC3F7',
    600: '#3DA9E2',
  },
  fern: {
    100: '#D3EDD8',
    200: '#BCE2C0',
    300: '#98D8A6',
    400: '#91D49C',
    500: '#53B063',
    600: '#479D53',
  },
  daffodil: {
    100: '#F6E4CB',
    200: '#F1D9A1',
    300: '#E9CD6E',
    400: '#E0C33C',
    500: '#E5BA00',
    600: '#DAA21F',
  },
  tangerine: {
    100: '#FBDACD',
    200: '#F6D1B4',
    300: '#F0B58C',
    400: '#F2A779',
    500: '#E88D4A',
    600: '#D86C1A',
  },
  orchid: {
    100: '#F1DCF5',
    200: '#E6C7ED',
    300: '#D9AEE4',
    400: '#CA8BD8',
    500: '#BA68C8',
    600: '#A450B2',
  },
  aquamarine: {
    100: '#D9F7EF',
    200: '#B4F3E3',
    300: '#8AEBD6',
    400: '#70E0CB',
    500: '#00BFA5',
    600: '#009688',
  },
  rosemist: {
    100: '#FDE5EC',
    200: '#FACAD5',
    300: '#F6B0BE',
    400: '#F1909A',
    500: '#E57373',
    600: '#CF5651',
  },
  amethyst: {
    100: '#E9D9FA',
    200: '#D2BFF2',
    300: '#B8A4E7',
    400: '#9B81D8',
    500: '#7E57C2',
    600: '#6C4AB8',
  },
  carbon: {
    0: '#FFFFFF',
    50: '#F9FAFB',
    100: '#F7F8FA',
    200: '#EFF1F5',
    300: '#DCE1EA',
    400: '#B9C0CD',
    500: '#949DAD',
    600: '#464A53',
  },
  iron: {
    0: '#FDFCFC',
    50: '#E0E0E1',
    100: '#8A9499',
    200: '#6D7A80',
    300: '#626E74',
    400: '#3B474E',
    500: '#1D2327',
    600: '#1C2226',
  },
  popover: {
    _light: 'white',
    _dark: 'iron.600',
  },
  tooltip: {
    _light: '#232529',
    _dark: 'iron.600',
  },
  scrollbar: {
    background: { _light: '#fafafa', _dark: '#3B474E' },
    border: { _light: '#fafafa', _dark: '#3B474e' },
    hover: { _light: '#F7F8FA', _dark: '#3B474E' },
  },
  icons: {
    stroke: { _light: '#464A53', _dark: '#8A9499' },
    fill: { _light: '#464A53', _dark: '#8A9499' },
  },
  sidebarText: {
    _light: '#464A53',
    _dark: '#8A9499',
  },
  sidebar: {
    text: { _light: '#686F7D', _dark: '#FDFCFC' },
    itemBg: { _light: '#E2E5E9', _dark: '#3B474E' },
    inactiveText: { _light: '#8F96A3', _dark: '#8A9499' },
    background: { _light: '#F6F7F8', _dark: '#283035' },
    actionHoverBg: { _light: '#D5D8DD', _dark: '#626E74' },
  },
  scenarios: {
    beige: { _light: '#F2E3C7', _dark: '#1C2226' },
    dark_beige: { _light: '#E8D4AF', _dark: '#1C2226' },
  },
};

const colors = {
  ...baseColors,
  background: baseColors.gray[100],
  actuals: { _light: '#464A53', _dark: '#E0E0E1' },
  forecast: baseColors.blue[500],
  eventImpact: baseColors.blue[300],
  milestone: baseColors.purple[500],
  draft_scenario_hover: { _light: '#E6D1A8', _dark: '#1C2226' },
};
export default colors;
