import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'SadFaceIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  d: 'M8 1C4.14092 1 1 4.14092 1 8C1 11.8591 4.14092 15 8 15C11.8591 15 15 11.8591 15 8C15 4.14092 11.8591 1 8 1ZM8 2.16667C11.2286 2.16667 13.8333 4.77143 13.8333 8C13.8333 11.2286 11.2286 13.8333 8 13.8333C4.77143 13.8333 2.16667 11.2286 2.16667 8C2.16667 4.77143 4.77143 2.16667 8 2.16667ZM5.375 6.83333C5.14294 6.83333 4.92038 6.92552 4.75628 7.08961C4.59219 7.25371 4.5 7.47627 4.5 7.70833C4.5 7.9404 4.59219 8.16296 4.75628 8.32705C4.92038 8.49115 5.14294 8.58333 5.375 8.58333C5.60706 8.58333 5.82962 8.49115 5.99372 8.32705C6.15781 8.16296 6.25 7.9404 6.25 7.70833C6.25 7.47627 6.15781 7.25371 5.99372 7.08961C5.82962 6.92552 5.60706 6.83333 5.375 6.83333ZM10.625 6.83333C10.3929 6.83333 10.1704 6.92552 10.0063 7.08961C9.84219 7.25371 9.75 7.47627 9.75 7.70833C9.75 7.9404 9.84219 8.16296 10.0063 8.32705C10.1704 8.49115 10.3929 8.58333 10.625 8.58333C10.8571 8.58333 11.0796 8.49115 11.2437 8.32705C11.4078 8.16296 11.5 7.9404 11.5 7.70833C11.5 7.47627 11.4078 7.25371 11.2437 7.08961C11.0796 6.92552 10.8571 6.83333 10.625 6.83333ZM8 9.75C7.11528 9.75 6.41243 10.1037 5.94352 10.445C5.81848 10.5361 5.73475 10.6732 5.71074 10.826C5.68673 10.9789 5.72441 11.135 5.8155 11.2601C5.90658 11.3851 6.04361 11.4689 6.19645 11.493C6.34929 11.517 6.50543 11.4794 6.63053 11.3883C6.96313 11.1463 7.42639 10.9167 8 10.9167C8.57361 10.9167 9.03687 11.1463 9.36947 11.3883C9.43141 11.4335 9.50163 11.466 9.57613 11.484C9.65063 11.502 9.72795 11.5051 9.80366 11.4932C9.87938 11.4813 9.952 11.4546 10.0174 11.4147C10.0828 11.3747 10.1397 11.3222 10.1848 11.2603C10.2299 11.1983 10.2624 11.1281 10.2804 11.0536C10.2983 10.9791 10.3014 10.9018 10.2895 10.8261C10.2776 10.7503 10.2509 10.6777 10.2109 10.6123C10.1709 10.547 10.1184 10.4901 10.0565 10.445C9.58757 10.1037 8.88472 9.75 8 9.75Z',
});
