import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import { Center, Flex, ListItem, Text, useListStyles } from '@chakra-ui/react';
import React from 'react';

import useHover from 'hooks/useHover';
interface Props {
  name: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  submenuLabel?: string;
  showSubmenuArrow?: boolean;
  isHovered?: boolean;
  unselectedIcon?: JSX.Element; // Icon to show when the item is not selected
}
const CheckItem: React.FC<
  Pick<
    Props,
    'name' | 'isSelected' | 'submenuLabel' | 'showSubmenuArrow' | 'unselectedIcon' | 'isHovered'
  >
> = ({
  name,
  isSelected = false,
  submenuLabel,
  showSubmenuArrow,
  unselectedIcon = null,
  isHovered,
}) => {
  const listStyles = useListStyles();

  return (
    <Flex w="full" justifyContent="space-between">
      <Flex justifyContent="space-between" alignItems="center" columnGap={1}>
        <Center minW="24px">
          {isSelected ? <CheckIcon boxSize={3} fill="gray.600" /> : unselectedIcon}
        </Center>
        <Text aria-checked={isSelected}>{name}</Text>
      </Flex>
      <Flex
        alignItems="center"
        columnGap={1}
        sx={listStyles.submenuLabel}
        _disabled={{
          cursor: 'default',
          _groupHover: { bg: 'white', color: 'gray.400' },
        }}
      >
        {submenuLabel != null && (
          <Text sx={{ color: isHovered ? 'white' : 'gray.400' }}>{submenuLabel}</Text>
        )}
        {showSubmenuArrow && <ArrowForwardIcon sx={{ color: isHovered ? 'white' : 'gray.400' }} />}
      </Flex>
    </Flex>
  );
};

export const CheckListItem = React.forwardRef<HTMLLIElement, Props>(
  (
    { name, isDisabled, isSelected = false, onClick, showSubmenuArrow, unselectedIcon, ...props },
    ref,
  ) => {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();
    return (
      <div ref={hoverRef}>
        <ListItem ref={ref} onClick={isDisabled ? undefined : onClick}>
          <CheckItem
            name={name}
            isSelected={isSelected}
            showSubmenuArrow={showSubmenuArrow}
            unselectedIcon={unselectedIcon}
            isHovered={isHovered}
            {...props}
          />
        </ListItem>
      </div>
    );
  },
);
