import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'ChartLegend',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.75 4H14" stroke="#949DAD" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.75 8H14" stroke="#949DAD" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.75 12H14" stroke="#949DAD" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2 4H3" stroke="#949DAD" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2 8H3" stroke="#949DAD" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2 12H3" stroke="#949DAD" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  ),
});
