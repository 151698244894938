import isString from 'lodash/isString';

import { isInputKeyboardEvent } from 'helpers/browserEvent';
import {
  isDriverRowKey,
  isFormulaColumn,
  isMonthColumnKey,
  isStickyColumnKey,
} from 'helpers/cells';
import { parseClipboardJSON } from 'helpers/clipboard';
import { handleDriverNamePaste, handleStringPaste } from 'reduxStore/actions/cellCopyPaste';
import { eventCopyCell } from 'reduxStore/actions/eventCopyPaste';
import { handleFormulaCopy, handleFormulaPaste } from 'reduxStore/actions/formulaCopyPaste';
import { modelCopyCell } from 'reduxStore/actions/modelCopyPaste';
import {
  handleObjectTableCellCopy,
  isObjectFieldSelection,
} from 'reduxStore/actions/objectTableCopyPaste';
import { PasteType } from 'reduxStore/reducers/pasteModalSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { blockGridTypeSelector } from 'selectors/blocksSelector';
import { isCurrentPageWritableSelector } from 'selectors/pageAccessResourcesSelector';
import { isPasteModalOpenSelector, pasteDataTypeSelector } from 'selectors/pasteModalSelector';
import { prevailingCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';
import {
  pageSelectionBlockIdSelector,
  prevailingSelectionTypeSelector,
} from 'selectors/selectionSelector';

export const handleCopy =
  ({ ev, isCut }: { ev: ClipboardEvent; isCut: boolean }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selectionType = prevailingSelectionTypeSelector(state);
    if (selectionType == null) {
      return;
    }

    const cellSelection = prevailingCellSelectionSelector(state);
    if (cellSelection != null) {
      ev.preventDefault();
      const setClipboardData = (data: string) => ev.clipboardData?.setData('text/plain', data);
      if (selectionType === 'eventsAndGroups') {
        dispatch(eventCopyCell({ isCut, setClipboardData }));
      } else if (selectionType === 'cell') {
        const { activeCell } = cellSelection;

        if (isObjectFieldSelection(cellSelection)) {
          dispatch(handleObjectTableCellCopy({ setClipboardData }));
        } else if (isMonthColumnKey(activeCell.columnKey)) {
          // N.B. Treating cut as a copy for model cells for now until we have better
          // handling for deleting actuals values.
          dispatch(modelCopyCell({ setClipboardData }));
        } else if (isFormulaColumn(activeCell.columnKey)) {
          dispatch(handleFormulaCopy({ setClipboardData }));
        }
      }
    }
  };

/**
 * Attempts to parse clipboard state as a valid pasteable object and creates a clone of the object.
 * If the clipboard state is not a pasteable object, this fails silently.
 */
export const handlePaste =
  (ev: ClipboardEvent): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const clipboardData = ev.clipboardData?.getData('text/plain');
    const parsedClipboardData = parseClipboardJSON(clipboardData);
    const blockId = pageSelectionBlockIdSelector(state);
    const isPasteModalOpen = isPasteModalOpenSelector(state);
    if (parsedClipboardData == null) {
      return;
    }

    if (isInputKeyboardEvent(ev)) {
      return;
    }

    const isCurrentPageWritable = isCurrentPageWritableSelector(state);
    if (!isCurrentPageWritable) {
      return;
    }

    if (!isPasteModalOpen) {
      if (blockId == null) {
        return;
      }

      const gridType = blockGridTypeSelector(state, blockId);
      if (gridType === 'agGrid' || gridType === 'agGridTimeseries') {
        return;
      }
    }

    if (isString(parsedClipboardData)) {
      if (parsedClipboardData.trim() === '') {
        // We trim driver names, so even if the clipboard is full of whitespace we want to
        // fail this paste.
        return;
      }
      ev.preventDefault();
      if (isDriverNamePaste(state)) {
        dispatch(handleDriverNamePaste(parsedClipboardData));
      } else {
        dispatch(handleStringPaste(parsedClipboardData));
      }
    } else {
      const isFormulaType = parsedClipboardData.type === 'formula';
      if (!isFormulaType) {
        return;
      }
      ev.preventDefault();
      dispatch(handleFormulaPaste(parsedClipboardData));
    }
  };

const isDriverNamePaste = (state: RootState) => {
  // If the PasteModal is open, let it handle things
  const isPasteModalOpen = isPasteModalOpenSelector(state);
  const pasteType = pasteDataTypeSelector(state);
  if (isPasteModalOpen && pasteType === PasteType.Driver) {
    return false;
  }

  const cellSelection = prevailingCellSelectionSelector(state);
  return (
    cellSelection != null &&
    cellSelection.selectedCells.length === 1 &&
    !isMonthColumnKey(cellSelection.activeCell.columnKey) &&
    isStickyColumnKey(cellSelection.activeCell.columnKey) &&
    cellSelection.activeCell.columnKey.columnType === 'name' &&
    isDriverRowKey(cellSelection?.activeCell.rowKey)
  );
};
