import { scaleLinear, scaleUtc } from '@visx/scale';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { deepEqual } from 'fast-equals';
import last from 'lodash/last';
import { useMemo } from 'react';

import { DriverChartDatum } from 'config/driverChart';
import { getMinMaxVals, getY } from 'helpers/chart';
import { getMonthKey } from 'helpers/dates';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useBlockDateRangeDateTime from 'hooks/useBlockDateRangeDateTime';
import useChartActualsData from 'hooks/useChartActualsData';
import useChartForecastData from 'hooks/useChartForecastData';
import useChartForecastMonthKeys from 'hooks/useChartForecastMonthKeys';
import { DriverFormat, DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { firstMilestoneForDriverSelector } from 'selectors/milestonesSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { MonthKey } from 'types/datetime';

interface HookArgs {
  driverId: DriverId;
  layerId?: LayerId;
  format: DriverFormat;
  width: number;
  height: number;
  useMilestone?: boolean;
  addHeadroom?: boolean;
  startEndOfMonth?: boolean;
}

interface HookData {
  timeScale: ScaleTime<number, number, never>;
  valueScale: ScaleLinear<number, number, never>;
  linearScale: ScaleLinear<number, number, never>;
  lastActual: DriverChartDatum | undefined;
  actualsLineData: DriverChartDatum[];
  forecastLineData: DriverChartDatum[];
  startDateTime: MonthKey;
  endDateTime: MonthKey;
  monthKeysWithForecastData: string[];
  baselineLayerId: string;
}

// TODO: refactor DriverChart to use this hook and reduce duplication
const useChartData = ({
  driverId,
  layerId,
  format,
  width,
  height,
  useMilestone = false,
  addHeadroom = true,
  startEndOfMonth = false,
}: HookArgs): HookData => {
  const { blockId } = useBlockContext();
  const [blockStartDateTime, endDateTime] = useBlockDateRangeDateTime();
  const startDateTime = useMemo(
    () => (startEndOfMonth ? blockStartDateTime.endOf('month') : blockStartDateTime),
    [blockStartDateTime, startEndOfMonth],
  );

  const milestone = useAppSelector((state) => firstMilestoneForDriverSelector(state, driverId));
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const baselineLayerId = useAppSelector(
    (state) => layerId ?? baselineLayerIdForBlockSelector(state, blockId),
  );
  const comparisonValues = useAppSelector((state) => {
    return comparisonLayerIds
      .flatMap((lId) =>
        Object.values(driverTimeSeriesForLayerSelector(state, { id: driverId, layerId: lId })),
      )
      .flat();
  }, deepEqual);
  const baselineTimeSeriesData = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: driverId, layerId: baselineLayerId }),
  );
  const monthKeysWithForecastData = useChartForecastMonthKeys(startDateTime, endDateTime);
  // Get time series data for actuals
  const actualsLineData = useChartActualsData(
    getMonthKey(startDateTime),
    baselineTimeSeriesData,
    format,
    getMonthKey(endDateTime),
  );
  const lastActual = last(actualsLineData);
  const forecastLineData = useChartForecastData({
    forecastData: baselineTimeSeriesData,
    monthKeys: monthKeysWithForecastData,
    format,
    lastActual,
  });

  const allDataValues = [...forecastLineData, ...actualsLineData]
    .map((d) => getY(d))
    .filter((y) => isFinite(y));

  if (useMilestone && milestone != null) {
    allDataValues.push(milestone.value);
  }

  const { minValue, maxValue } = getMinMaxVals([...allDataValues, ...comparisonValues], format, {
    addHeadroom,
  });

  const linearScale = useMemo(
    () =>
      scaleLinear({
        range: [0, width],
        domain: [0, width],
      }),
    [width],
  );

  const timeScale = useMemo(
    () =>
      scaleUtc({
        range: [0, width],
        domain: [startDateTime.toJSDate(), endDateTime.toJSDate()],
      })
        // Clamp time scale to ensure charts always stay within x bounds
        .clamp(true),
    [width, startDateTime, endDateTime],
  );

  const valueScale = useMemo(
    () =>
      scaleLinear({
        range: [height, 0],
        domain: [minValue, maxValue],
        nice: true,
      }),
    [height, minValue, maxValue],
  );

  return {
    timeScale,
    valueScale,
    linearScale,
    lastActual,
    actualsLineData,
    forecastLineData,
    startDateTime: getMonthKey(startDateTime),
    endDateTime: getMonthKey(endDateTime),
    monthKeysWithForecastData,
    baselineLayerId,
  };
};

export default useChartData;
