import React from 'react';

import DataSourceLogo from 'components/DataSourceLogo/DataSourceLogo';
import AttributeFiltersBadges from 'components/Formula/AttributeFiltersBadges';
import FormulaEntityChunk from 'components/Formula/FormulaEntityChunk';
import { ExtQueryFormulaDisplayChunk } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';

interface Props {
  chunk: ExtQueryFormulaDisplayChunk;
}

const ExtQueryChunk: React.FC<Props> = ({ chunk }) => {
  const { timeRange, timeRangeDisplay, displayName, attributeFilters } = chunk;
  return (
    <FormulaEntityChunk
      timeRange={timeRange}
      icon={chunk.source != null ? <DataSourceLogo source={chunk.source} size="xs" /> : undefined}
      timeRangeDisplay={timeRangeDisplay}
      label={displayName}
      attributes={
        <AttributeFiltersBadges attributeFilters={attributeFilters} includeEmpty={false} />
      }
    />
  );
};

export default ExtQueryChunk;
