import { popperCSSVars, usePopper, UsePopperProps } from '@chakra-ui/popper';
import { PropGetter } from '@chakra-ui/react-types';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { mergeRefs } from '@chakra-ui/react-use-merge-refs';
import { callAllHandlers } from '@chakra-ui/shared-utils';
import React, { useCallback, useEffect, useId, useRef, type RefObject } from 'react';

export interface UseTooltipProps
  extends Pick<
    UsePopperProps,
    'modifiers' | 'gutter' | 'offset' | 'arrowPadding' | 'direction' | 'placement'
  > {
  /**
   * Delay (in ms) before showing the tooltip
   * @default 0ms
   */
  openDelay?: number;
  /**
   * Delay (in ms) before hiding the tooltip
   * @default 0ms
   */
  closeDelay?: number;
  /**
   * If `true`, the tooltip will hide on click
   * @default true
   */
  closeOnClick?: boolean;
  /**
   * If `true`, the tooltip will hide while the mouse is down
   * @deprecated - use `closeOnPointerDown` instead
   */
  closeOnMouseDown?: boolean;
  /**
   * If `true`, the tooltip will hide while the pointer is down
   * @default true
   */
  closeOnPointerDown?: boolean;
  /**
   * If `true`, the tooltip will hide on pressing Esc key
   * @default true
   */
  closeOnEsc?: boolean;
  /**
   * Callback to run when the tooltip shows
   */
  onOpen?(): void;
  /**
   * Callback to run when the tooltip hides
   */
  onClose?(): void;
  /**
   * Custom `id` to use in place of `uuid`
   */
  id?: string;
  /**
   * If `true`, the tooltip will be shown (in controlled mode)
   * @default false
   */
  isOpen?: boolean;
  /**
   * If `true`, the tooltip will be initially shown
   * @default false
   */
  defaultIsOpen?: boolean;
  /**
   * @default false
   */
  isDisabled?: boolean;
  /**
   * @default false
   */
  closeOnScroll?: boolean;
  /**
   * @default 10
   */
  arrowSize?: number;
  arrowShadowColor?: string;
}

const getDoc = (ref: React.RefObject<Element | null>) => ref.current?.ownerDocument || document;

const getWin = (ref: React.RefObject<Element | null>) =>
  ref.current?.ownerDocument?.defaultView || window;

export function useTooltip(props: UseTooltipProps = {}) {
  const {
    openDelay = 0,
    closeDelay = 0,
    closeOnClick = true,
    closeOnMouseDown,
    closeOnScroll,
    closeOnPointerDown = closeOnMouseDown,
    closeOnEsc = true,
    onOpen: onOpenProp,
    onClose: onCloseProp,
    placement,
    id,
    isOpen: isOpenProp,
    defaultIsOpen,
    arrowSize = 10,
    arrowShadowColor,
    arrowPadding,
    modifiers,
    isDisabled,
    gutter,
    offset,
    direction,
    ...htmlProps
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: isOpenProp,
    defaultIsOpen,
    onOpen: onOpenProp,
    onClose: onCloseProp,
  });

  const { referenceRef, getPopperProps, getArrowInnerProps, getArrowProps } = usePopper({
    enabled: isOpen,
    placement,
    arrowPadding,
    modifiers,
    gutter,
    offset,
    direction,
  });

  const uuid = useId();
  const uid = id ?? uuid;
  const tooltipId = `tooltip-${uid}`;

  const ref = useRef<Element>(null);

  const enterTimeout = useRef<number>();
  const clearEnterTimeout = useCallback(() => {
    if (enterTimeout.current != null) {
      clearTimeout(enterTimeout.current);
      enterTimeout.current = undefined;
    }
  }, []);

  const exitTimeout = useRef<number>();
  const clearExitTimeout = useCallback(() => {
    if (exitTimeout.current != null) {
      clearTimeout(exitTimeout.current);
      exitTimeout.current = undefined;
    }
  }, []);

  const cleanupHandlers = useRef<Array<() => void>>([]);

  const closeNow = useCallback(() => {
    clearExitTimeout();
    clearEnterTimeout();
    onClose();
    cleanupHandlers.current.forEach((fn) => fn());
    cleanupHandlers.current = [];
  }, [clearExitTimeout, clearEnterTimeout, onClose]);

  const [attachCloseEvent, dispatchCloseEvent] = useCloseEvent(ref, closeNow);

  const closeWithDelay = useCallback(() => {
    clearEnterTimeout();
    const win = getWin(ref);
    exitTimeout.current = win.setTimeout(closeNow, closeDelay);
  }, [closeDelay, closeNow, clearEnterTimeout]);

  const onClick = useCallback(() => {
    if (isOpen && closeOnClick) {
      closeWithDelay();
    }
  }, [closeOnClick, closeWithDelay, isOpen]);

  const onPointerDown = useCallback(() => {
    if (isOpen && closeOnPointerDown) {
      closeWithDelay();
    }
  }, [closeOnPointerDown, closeWithDelay, isOpen]);

  const attachCloseListeners = useCallback(() => {
    const doc = getDoc(ref);
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeWithDelay();
      }
    };

    const cleanup: Array<() => void> = [];

    cleanup.push(attachCloseEvent());

    if (ref.current != null) {
      /**
       * This allows for catching pointerleave events when the tooltip
       * trigger is disabled. There's currently a known issue in
       * React regarding the onPointerLeave polyfill.
       * @see https://github.com/facebook/react/issues/11972
       */
      const curr = ref.current;
      curr.addEventListener('pointerleave', closeWithDelay);
      cleanup.push(() => {
        curr.removeEventListener('pointerleave', closeWithDelay);
      });
    }

    if (closeOnEsc) {
      doc.addEventListener('keydown', onKeyDown);
      cleanup.push(() => {
        doc.removeEventListener('keydown', onKeyDown);
      });
    }

    if (closeOnScroll) {
      doc.addEventListener('scroll', closeWithDelay);
      cleanup.push(() => {
        doc.removeEventListener('scroll', closeWithDelay);
      });
    }

    cleanupHandlers.current.push(...cleanup);
  }, [attachCloseEvent, closeOnEsc, closeOnScroll, closeWithDelay]);

  const openWithDelay = useCallback(() => {
    if (!isDisabled && enterTimeout.current == null) {
      dispatchCloseEvent();
      attachCloseListeners();
      const win = getWin(ref);
      enterTimeout.current = win.setTimeout(onOpen, openDelay);
    }
  }, [attachCloseListeners, dispatchCloseEvent, isDisabled, onOpen, openDelay]);

  useEffect(() => {
    if (!isDisabled) {
      return;
    }
    clearEnterTimeout();
    if (isOpen) {
      onClose();
    }
  }, [isDisabled, isOpen, onClose, clearEnterTimeout]);

  useEffect(
    () => () => {
      clearEnterTimeout();
      clearExitTimeout();
    },
    [clearEnterTimeout, clearExitTimeout],
  );

  const getTriggerProps: PropGetter = useCallback(
    (callbackProps = {}, _ref = null) => {
      const triggerProps = {
        ...callbackProps,
        ref: mergeRefs(ref, _ref, referenceRef),
        onPointerEnter: callAllHandlers(callbackProps.onPointerEnter, (e) => {
          if (e.pointerType === 'touch') {
            return;
          }
          openWithDelay();
        }),
        onClick: callAllHandlers(callbackProps.onClick, onClick),
        onPointerDown: callAllHandlers(callbackProps.onPointerDown, onPointerDown),
        onFocus: callAllHandlers(callbackProps.onFocus, openWithDelay),
        onBlur: callAllHandlers(callbackProps.onBlur, closeWithDelay),
        'aria-describedby': isOpen ? tooltipId : undefined,
      };

      return triggerProps;
    },
    [openWithDelay, closeWithDelay, onPointerDown, isOpen, tooltipId, onClick, referenceRef],
  );

  const getTooltipPositionerProps: PropGetter = useCallback(
    (callbackProps = {}, forwardedRef = null) =>
      getPopperProps(
        {
          ...callbackProps,
          style: {
            ...callbackProps.style,
            [popperCSSVars.arrowSize.var]: arrowSize !== 0 ? `${arrowSize}px` : undefined,
            [popperCSSVars.arrowShadowColor.var]: arrowShadowColor,
          },
        },
        forwardedRef,
      ),
    [getPopperProps, arrowSize, arrowShadowColor],
  );

  const getTooltipProps: PropGetter = useCallback(
    (callbackProps = {}, forwardedRef = null) => {
      const styles: React.CSSProperties = {
        ...callbackProps.style,
        position: 'relative',
        transformOrigin: popperCSSVars.transformOrigin.varRef,
      };

      return {
        ref: forwardedRef,
        ...htmlProps,
        ...callbackProps,
        id: tooltipId,
        role: 'tooltip',
        style: styles,
      };
    },
    [htmlProps, tooltipId],
  );

  return {
    isOpen,
    show: openWithDelay,
    hide: closeWithDelay,
    getTriggerProps,
    getTooltipProps,
    getTooltipPositionerProps,
    getArrowProps,
    getArrowInnerProps,
  };
}

export type UseTooltipReturn = ReturnType<typeof useTooltip>;

const closeEventName = 'chakra-ui:close-tooltip';

function useCloseEvent(ref: RefObject<Element>, close: () => void) {
  const closeMe = useCallback(() => {
    const doc = getDoc(ref);
    doc.addEventListener(closeEventName, close);
    return () => doc.removeEventListener(closeEventName, close);
  }, [close, ref]);

  const closeOthers = useCallback(() => {
    const doc = getDoc(ref);
    const win = getWin(ref);
    doc.dispatchEvent(new win.CustomEvent(closeEventName));
  }, [ref]);

  return [closeMe, closeOthers] as const;
}
