import * as Sentry from '@sentry/nextjs';
import isString from 'lodash/isString';

import { appEnv, isProduction, isStaging, isTestBuild } from 'helpers/environment';

type NextjsOptions = Parameters<typeof Sentry.init>[0];
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_BREADCRUMB_URLS_TO_IGNORE = [
  'api.segment.io',
  'seg/api',
  'launchdarkly.com',
  'datadoghq.com',
];

// these events are triggered very frequently and will create more noise than signal
const REDUX_ACTIONS_TO_IGNORE: string[] = [
  'cursor/setCursor',
  'cursor/setIsOnDragHandle',
  'modelView/resizeColumn',
  'formulaInput/setCanInsertDriverReference',
  'liveEdit/eventLiveEditPoint',
  'liveEdit/startLiveEditingExistingEvent',
];

const APPLY_MUTATION_NAME = 'dataset/applyMutationLocally';

const REDUX_ACTIONS_TO_IGNORE_PAYLOAD: string[] = [
  'dataset/initializeWithSnapshot',
  'dataset/initializeLockedSnapshot',
  'dataset/initializeNamedVersionLite',
  APPLY_MUTATION_NAME,
];

function isReduxActionBreadcrumb(breadcrumb: Sentry.Breadcrumb): boolean {
  return breadcrumb.category === 'redux-action';
}

function shouldIgnoreBreadcrumb(breadcrumb: Sentry.Breadcrumb): boolean {
  const { category, data } = breadcrumb;
  const isFetchBreadcrumb = category === 'xhr' || category === 'fetch';
  const actionType = data?.type as string | undefined;
  return (
    (isFetchBreadcrumb &&
      data != null &&
      SENTRY_BREADCRUMB_URLS_TO_IGNORE.some((url) => (data.url as string).includes(url))) ||
    (isReduxActionBreadcrumb(breadcrumb) &&
      actionType != null &&
      REDUX_ACTIONS_TO_IGNORE.includes(actionType))
  );
}

function shouldIgnoreEvent(event: Sentry.ErrorEvent, hint: Sentry.EventHint): boolean {
  const error = hint.originalException;
  const { exception } = event;

  if (error == null || isString(error)) {
    return false;
  }

  // Note - We ignore these errors because they have no (known) effects on runtime.
  // This gets thrown when a render takes longer than an animation frame - AP
  const isResizeObserverError = exception?.values?.find(
    (ex) => ex.value === 'ResizeObserver loop limit exceeded',
  );

  if (isResizeObserverError) {
    return true;
  }

  return false;
}

// TODO: figure out how to get ts-prune to check files like sentry.server.config.ts
// ts-prune-ignore-next
export function getBaseSentryOptions(): NextjsOptions {
  return {
    dsn: SENTRY_DSN,
    environment: appEnv,
    enabled: (isProduction || isStaging) && !isTestBuild,
    beforeBreadcrumb: (breadcrumb: Sentry.Breadcrumb) => {
      if (shouldIgnoreBreadcrumb(breadcrumb)) {
        return null;
      }

      const actionType = breadcrumb.data?.type as string | undefined;
      if (
        isReduxActionBreadcrumb(breadcrumb) &&
        actionType != null &&
        REDUX_ACTIONS_TO_IGNORE_PAYLOAD.includes(actionType)
      ) {
        const newBreadcrumb: Sentry.Breadcrumb = {
          ...breadcrumb,
          data: {
            ...breadcrumb.data,
            payload: null,
          },
        };

        // Extract some useful info from the payload
        // TS doesn't acknowledge that the payload is set above so we have to do a null check.
        if (actionType === APPLY_MUTATION_NAME && newBreadcrumb.data != null) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const mutationId = breadcrumb.data?.payload?.mutationBatch?.id as string | undefined;
          if (mutationId != null) {
            newBreadcrumb.data.mutationId = mutationId;
          }
        }

        return newBreadcrumb;
      }

      return breadcrumb;
    },
    beforeSend: (event: Sentry.ErrorEvent, hint: Sentry.EventHint | undefined) => {
      if (hint != null && shouldIgnoreEvent(event, hint)) {
        return null;
      }

      return event;
    },
    // https://github.com/getsentry/sentry-javascript/issues/9760
    ignoreErrors: ['Event buffer exceeded maximum size'],
  };
}
