import React, { useCallback, useContext, useEffect } from 'react';

import { CellSelectionManagerContext } from 'components/CellSelectionManager/CellSelectionManagerContext';
import { CellRef, useIsDraggingToSelectCellsRef } from 'config/cells';
import useAppDispatch from 'hooks/useAppDispatch';
import { selectCell } from 'reduxStore/actions/cellNavigation';
import { BlockId } from 'reduxStore/models/blocks';
import { releaseCellSelectionDrag } from 'reduxStore/reducers/pageSlice';

// This is a global listener, as opposed to the other listeners like onMouseDown, onMouseOut, onMouseEnter,
// which are per table cell.
function useGlobalOnMouseUpEventListener() {
  const dispatch = useAppDispatch();
  const cellDragRef = useIsDraggingToSelectCellsRef();
  const { setIsDragging } = useContext(CellSelectionManagerContext);

  const onMouseUp = useCallback(() => {
    if (cellDragRef.current.isMouseDown) {
      cellDragRef.current.isMouseDown = false;
      cellDragRef.current.hasMouseLeftMouseDownCell = true;
    }

    if (cellDragRef.current.isDraggingToSelect) {
      cellDragRef.current.isDraggingToSelect = false;
      setIsDragging(false);
      dispatch(releaseCellSelectionDrag());
    }
  }, [cellDragRef, dispatch, setIsDragging]);

  useEffect(() => {
    // Only ever add a single mouse up listener - we want to avoid adding one per table cell.
    if (cellDragRef.current.isMouseUpEventListenerAdded) {
      return;
    }
    cellDragRef.current.isMouseUpEventListenerAdded = true;

    document.addEventListener('mouseup', onMouseUp);
  }, [cellDragRef, onMouseUp]);
}

type UseDragToSelectCellsReturn = {
  onMouseDown: React.MouseEventHandler;
  onMouseEnter: React.MouseEventHandler;
  onMouseLeave: React.MouseEventHandler;
};

export function useDragToSelectCells(
  cellRef: CellRef,
  blockId: BlockId | null,
): UseDragToSelectCellsReturn {
  const dispatch = useAppDispatch();
  const cellDragRef = useIsDraggingToSelectCellsRef();
  const { setIsDragging } = useContext(CellSelectionManagerContext);

  useGlobalOnMouseUpEventListener();

  const onMouseDown = useCallback(
    (ev: React.MouseEvent) => {
      // Don't drag to select on secondary-click, or otherwise we'll interfere with the
      // context menu. This check is Left-to-Right sensetive (i.e. works for right-click-
      // as-primary-click)
      // See: https://www.w3.org/TR/2000/REC-DOM-Level-2-Events-20001113/events.html#Events-MouseEvent
      if (ev.button === 2) {
        return;
      }
      // Control + primary click is an OSX secondary click
      if (ev.button === 0 && ev.ctrlKey) {
        return;
      }

      if ('objectId' in cellRef.rowKey && cellRef.rowKey.objectId === null) {
        return;
      }

      cellDragRef.current.isMouseDown = true;
      cellDragRef.current.hasMouseLeftMouseDownCell = false;

      // N.B. we do requestAnimationFrame here to allow the previously selected cell to
      // save any pending changes on blur before it's unmounted.
      window.requestAnimationFrame(() => {
        dispatch(
          selectCell(blockId, cellRef, {
            range: ev.shiftKey,
            toggle: ev.metaKey || ev.ctrlKey,
          }),
        );
      });

      ev.preventDefault();
      ev.stopPropagation();
    },
    [cellDragRef, cellRef, dispatch, blockId],
  );

  const onMouseLeave = useCallback(() => {
    // We only want the onMouseEnter callback to run after we've started dragging to another cell.
    cellDragRef.current.hasMouseLeftMouseDownCell = true;
  }, [cellDragRef]);

  const onMouseEnter = useCallback(() => {
    if (cellDragRef.current.isMouseDown && cellDragRef.current.hasMouseLeftMouseDownCell) {
      cellDragRef.current.isDraggingToSelect = true;
      setIsDragging(true);
      dispatch(selectCell(blockId, cellRef, { range: true, isDragging: true }));
    }
  }, [cellDragRef, setIsDragging, dispatch, blockId, cellRef]);

  return { onMouseDown, onMouseEnter, onMouseLeave };
}
