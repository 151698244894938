import { EventGroupUpdateInput, EventUpdateInput } from 'generated/graphql';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { updateEventsAndGroupsVisibility } from 'reduxStore/actions/eventMutations';
import { EventGroupId, EventId } from 'reduxStore/models/events';
import { AppThunk } from 'reduxStore/store';
import {
  eventGroupsByIdForLayerSelector,
  eventsByIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { hasExistingEntitySelectedSelector } from 'selectors/hasExistingEntitySelectedSelector';
import { isCurrentPageWritableSelector } from 'selectors/pageAccessResourcesSelector';
import { prevailingSelectedEventsAndEventGroupsIdsSelector } from 'selectors/prevailingCellSelectionSelector';

export const toggleSelectedVisible = (preventDefault?: () => void): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const isPageWritable = isCurrentPageWritableSelector(state);
    const hasExistingEntitySelected = hasExistingEntitySelectedSelector(state);
    if (!isPageWritable || !hasExistingEntitySelected) {
      return;
    }

    const { eventIds, eventGroupIds } = prevailingSelectedEventsAndEventGroupsIdsSelector(state);
    preventDefault?.();
    const eventsById = eventsByIdForLayerSelector(state);
    const groupsById = eventGroupsByIdForLayerSelector(state);
    const shouldHide =
      eventIds.every((id) => !eventsById[id].hidden) &&
      eventGroupIds.every((id) => !groupsById[id].hidden);

    const finalEventIds = new Set<EventId>();
    const finalEventGroupIds = new Set<EventGroupId>();

    const addSelfAndChildren = (eventGroupId: EventGroupId) => {
      finalEventGroupIds.add(eventGroupId);
      const eventGroup = groupsById[eventGroupId];
      Object.keys(eventGroup.eventIds).forEach((eventId) => finalEventIds.add(eventId));
      Object.keys(eventGroup.eventGroupIds).forEach(addSelfAndChildren);
    };

    const addSelfAndParents = (id: EventId | EventGroupId) => {
      let nextId: EventId | EventGroupId | undefined = id;
      while (nextId != null) {
        if (nextId in eventsById) {
          finalEventIds.add(nextId);
          nextId = eventsById[nextId].parentId;
        } else if (nextId in groupsById) {
          finalEventGroupIds.add(nextId);
          nextId = groupsById[nextId].parentId;
        }
      }
    };

    eventGroupIds.forEach(addSelfAndChildren);
    eventIds.forEach((eventId) => finalEventIds.add(eventId));
    if (!shouldHide) {
      eventGroupIds.forEach(addSelfAndParents);
      eventIds.forEach(addSelfAndParents);
    }

    const eventUpdates: EventUpdateInput[] = [...finalEventIds]
      .map((id) => safeObjGet(eventsById[id]))
      .filter(isNotNull)
      .filter((ev) => ev.hidden !== shouldHide)
      .map((ev) => ({ id: ev.id, hidden: shouldHide }));
    const eventGroupUpdates: EventGroupUpdateInput[] = [...finalEventGroupIds]
      .map((id) => safeObjGet(groupsById[id]))
      .filter(isNotNull)
      .filter((g) => g.hidden !== shouldHide)
      .map((g) => ({ id: g.id, hidden: shouldHide }));

    dispatch(updateEventsAndGroupsVisibility({ eventUpdates, eventGroupUpdates }));
  };
};
