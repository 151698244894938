import { Flex, Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { useMemo } from 'react';

import { Tooltip } from 'chakra/tooltip';
import DimensionalDatabaseNewProperty from 'components/BusinessObjectTable/SettingsPopoverContents/DimensionalDatabaseNewProperty';
import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import NewObjectFieldCellContents from 'components/NewObjectEditorRow/NewObjectFieldCellContents';
import { AddButton } from 'components/PlanTimelineNavigationPanelContents/AddRowButton';
import PlusIconButton from 'components/PlusIconButton/PlusIconButton';
import TableHeaderCell from 'components/Table/TableHeaderCell';
import { DatabaseGroupKey } from 'config/businessObjects';
import { CellRef, CellType, COLUMN_HEADER_CELL_HEIGHT_PX } from 'config/cells';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useControlledPopover from 'hooks/useControlledPopover';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import {
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  isObjectTimeseriesViewSelector,
} from 'selectors/blocksSelector';
import { columnWidthsForObjectTableBlockSelector } from 'selectors/columnWidthsForObjectTableBlockSelector';
import { newFieldColumnWidthSelector } from 'selectors/objectTableBlockSelector';

interface Props {
  objectId?: BusinessObjectId;
  groupKey: DatabaseGroupKey;
  label?: string;
}

const AddNewFieldButton: React.FC<Props> = ({ groupKey, objectId, label }) => {
  const { blockId } = useBlockContext();
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();

  const newFieldColumnWidth = useAppSelector((state) =>
    newFieldColumnWidthSelector(state, blockId),
  );
  const isObjectTimeseriesView = useAppSelector((state) =>
    isObjectTimeseriesViewSelector(state, blockId),
  );
  const propertyFieldWidth = useAppSelector(
    (state) => columnWidthsForObjectTableBlockSelector(state, blockId).property,
  );

  const showingTimeSeries = useAppSelector(
    (state) => blockConfigObjectFieldSpecAsTimeSeriesIdSelector(state, blockId) != null,
  );

  const height = (showingTimeSeries ? 2 : 1) * COLUMN_HEADER_CELL_HEIGHT_PX;

  const cellRef: CellRef = useMemo(() => {
    return isObjectTimeseriesView && objectId != null
      ? {
          type: CellType.ObjectFieldTimeSeries,
          rowKey: {
            objectId,
            fieldSpecId: undefined,
            layerId: undefined,
          },
          columnKey: { columnType: 'property', columnLayerId: undefined },
        }
      : {
          type: CellType.ObjectField,
          rowKey: {
            objectId: objectId ?? null,
            groupKey,
          },
          columnKey: { columnType: 'addNewColumn' },
        };
  }, [isObjectTimeseriesView, objectId, groupKey]);

  return (
    <CellContextProvider cellRef={cellRef}>
      <TableHeaderCell
        width={toPxString(isObjectTimeseriesView ? propertyFieldWidth : newFieldColumnWidth)}
        isData={false}
        isSticky={false}
        height={toPxString(height)}
        overflow="hidden"
        orientation={isObjectTimeseriesView ? 'vertical' : 'horizontal'}
      >
        <Popover
          isLazy
          placement="bottom-start"
          closeOnBlur={false}
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
        >
          <PopoverTrigger>
            <Flex ref={triggerRef} alignItems="center" mr={2}>
              {label == null || cellRef == null ? (
                <Tooltip label="Create new property" placement="top" openDelay={1000}>
                  <PlusIconButton
                    data-testid="create-new-property-button"
                    aria-label="Create new property"
                    isActive={isOpen}
                  />
                </Tooltip>
              ) : (
                <NewObjectFieldCellContents onEnter={onOpen}>
                  <AddButton text={label} />
                </NewObjectFieldCellContents>
              )}
            </Flex>
          </PopoverTrigger>
          <Portal>
            <PopoverContent padding={0} ref={contentRef}>
              <DimensionalDatabaseNewProperty
                onClose={onClose}
                groupKey={groupKey}
                objectId={objectId}
                isOpen
              />
            </PopoverContent>
          </Portal>
        </Popover>
      </TableHeaderCell>
    </CellContextProvider>
  );
};

export default AddNewFieldButton;
