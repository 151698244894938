import { Flex, IconButton } from '@chakra-ui/react';
import pluralize from 'pluralize';
import React, { useCallback, useContext, useMemo } from 'react';

import { useAddDrivers } from 'components/CustomizeDriverChartsBlock/hooks';
import { EditDriverView } from 'components/DimensionalDriverMenu/useEditDriverView';
import useOnTargetDriverSelected from 'components/DimensionalDriverMenu/useOnTargetDriverSelected';
import DimensionalDriverSelect from 'components/DriverBlockSelect/DimensionalDriverSelect';
import DriverGroupSelect from 'components/DriverBlockSelect/DriverGroupSelect';
import { useFocusedDriver } from 'components/DriverBlockSelect/FocusedDriverProvider';
import { useSelectHint } from 'components/DriverBlockSelect/SelectHintProvider';
import SubmodelDriverSelect from 'components/DriverBlockSelect/SubmodelDriverSelect';
import { SECTIONS_BY_ID } from 'components/DriverBlockSelect/constants';
import DriverSearchResult from 'components/DriverSearchResult/DriverSearchResult';
import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import DriverSelectMenuFooter from 'components/SelectMenu/DriverSelectMenuFooter';
import SelectMenu, {
  CustomOption,
  Section,
  SelectItem,
  SelectResult,
} from 'components/SelectMenu/SelectMenu';
import { DimDriverEditReactContext } from 'config/dimDriverEditContext';
import { DEFAULT_DRIVER_GROUP_NAME } from 'config/driverGroups';
import { SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS } from 'config/selectMenu';
import { DriverType } from 'generated/graphql';
import { getSearchName } from 'helpers/drivers';
import { extractEmoji } from 'helpers/emoji';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { EnumSetter } from 'hooks/useEnum';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { addDriverReferencesToBlock } from 'reduxStore/actions/driverReferenceMutations';
import { jumpToDriver } from 'reduxStore/actions/jumpToDriver';
import { Attribute, UserAddedDimensionType } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import {
  accessibleDriversByIdForLayerSelector,
  sortedAccessibleBasicDriverIdsByGroupBySubmodelIdSelector,
} from 'selectors/accessibleDriversSelector';
import { blockDriverIdsSelector } from 'selectors/blocksSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import { attributesBySubDriverIdSelector } from 'selectors/driversSelector';
import { recentDriverIdsSelector } from 'selectors/formulaInputSelector';
import {
  driversBySubmodelIdSelector,
  sortedAccessibleSubmodelPagesSelector,
} from 'selectors/submodelSelector';
import { Plus } from 'vectors';
import DimensionIcon from 'vectors/Dimension';

type SelectAction = 'create-subdriver' | 'add-driver' | 'jump-to-driver';
type DriverBlockSelectItem = SelectItem &
  (
    | {
        type: 'driver';
        driver: Driver;
        searchableName: string;
        selectAction?: SelectAction;
        onlyEnableMeta?: boolean;
      }
    | { type: 'model' }
    | { type: 'driverGroup' }
  );

const SEARCH_KEYS = ['searchableName', 'name'];

const getDummyAttribute = (attributeName: string): Attribute => {
  return {
    id: attributeName,
    type: UserAddedDimensionType,
    value: attributeName,
    deleted: false,
    dimensionId: attributeName,
  };
};

function getSelectAction(driver: Driver, hasNoPrimaryAction: boolean): SelectAction {
  if (hasNoPrimaryAction) {
    return 'jump-to-driver';
  } else if (driver.type === DriverType.Dimensional) {
    return 'create-subdriver';
  } else {
    return 'add-driver';
  }
}

function getHint(driver: Driver, selectAction: SelectAction | undefined): string {
  if (selectAction == null || driver.type === DriverType.Dimensional) {
    return '';
  }

  if (selectAction === 'create-subdriver') {
    return '— Create subdriver';
  }
  if (selectAction === 'add-driver') {
    return '— Add driver';
  }

  return '';
}

function useOnFocusChange() {
  const { setHint } = useSelectHint();
  const { setFocusedDriver } = useFocusedDriver();

  const onFocusChange = useCallback(
    (focusedResult: SelectResult<DriverBlockSelectItem> | null, query: string) => {
      if (focusedResult == null && query.length === 0) {
        setHint('Add driver...');
        return;
      }

      let hint: string = '';
      if (focusedResult != null && 'item' in focusedResult) {
        if (focusedResult.item.id === 'create') {
          hint = '— Create driver';
          setFocusedDriver(null);
        } else if ('driver' in focusedResult.item) {
          const { driver, selectAction } = focusedResult.item;
          setFocusedDriver(driver);
          hint = getHint(driver, selectAction);
        }
      }
      setHint(hint);
    },
    [setFocusedDriver, setHint],
  );

  return onFocusChange;
}

function useOnCreateSubdriverButtonHover() {
  const { previousHint, setHint } = useSelectHint();

  const onMouseEnter = useCallback(
    (driver: Driver) => {
      setHint(getHint(driver, 'create-subdriver'));
    },
    [setHint],
  );

  const onMouseLeave = useCallback(() => {
    setHint(previousHint);
  }, [setHint, previousHint]);

  return useMemo(() => ({ onMouseEnter, onMouseLeave }), [onMouseEnter, onMouseLeave]);
}

interface Props {
  query: string;
  onClose: () => void;
  groupId?: DriverGroupId;
  setView: EnumSetter<EditDriverView>;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

const DriverBlockSelectMenu: React.FC<Props> = ({
  query,
  onClose,
  groupId,
  setView,
  containerRef,
}) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();

  const isEmptyQuery = query.trim().length === 0;

  const addedDriverIds = useAppSelector((state) => blockDriverIdsSelector(state, blockId));
  const sortedSubmodelPages = useAppSelector(sortedAccessibleSubmodelPagesSelector);
  const driversBySubmodelId = useAppSelector(driversBySubmodelIdSelector);
  const attributesBySubDriverId = useAppSelector(attributesBySubDriverIdSelector);
  const driverGroupsById = useAppSelector(driverGroupsByIdSelector);
  const recentlyUsedDriverIds = useAppSelector(recentDriverIdsSelector);
  const driversById = useAppSelector(accessibleDriversByIdForLayerSelector);
  const groupDriversBySubmodelId = useAppSelector(
    sortedAccessibleBasicDriverIdsByGroupBySubmodelIdSelector,
  );

  const { onSave } = useContext(DimDriverEditReactContext);
  const { setHint } = useSelectHint();
  const onTargetDriverSelected = useOnTargetDriverSelected({ setView });
  const { onMouseEnter, onMouseLeave } = useOnCreateSubdriverButtonHover();
  const onFocusChange = useOnFocusChange();
  const { focusedDriver, setFocusedDriver } = useFocusedDriver();

  useOnClickOutside(containerRef, onClose);

  const handleAddDrivers = useAddDrivers();

  const addDrivers = useCallback(
    (driverIds: DriverId[]) => {
      handleAddDrivers(driverIds);
      dispatch(
        addDriverReferencesToBlock({
          driverIds,
          blockId,
          groupId,
        }),
      );
    },
    [handleAddDrivers, dispatch, blockId, groupId],
  );

  const driverItems = useMemo<Array<DriverBlockSelectItem & { type: 'driver' }>>(() => {
    return Object.values(driversById)
      .map((driver) => {
        if (driver == null) {
          return null;
        }
        const { id, name } = driver;

        const isDimensionalDriver = driver.type === DriverType.Dimensional;
        const isBasicDriver = driver.type === DriverType.Basic;
        const hasNoPrimaryAction = isBasicDriver && addedDriverIds.includes(id);
        const selectAction = getSelectAction(driver, hasNoPrimaryAction);
        const numSubDrivers = isDimensionalDriver ? driver.subdrivers.length : 0;
        let attributes =
          driver.type === DriverType.Dimensional
            ? driver.dimensions.flatMap((d) => [
                ...d.attributes,
                getDummyAttribute(`All ${pluralize(d.name)}`),
              ])
            : (attributesBySubDriverId[id] ?? []);
        attributes = attributes.length > 0 ? attributes : [getDummyAttribute('No attributes')];

        const metaProps = hasNoPrimaryAction
          ? {
              color: 'white',
              _hover: { bgColor: 'selection.500', color: 'surface' },
              _active: { bgColor: 'selection.500' },
            }
          : {
              color: 'white',
              _hover: { bgColor: 'selection.600' },
              _active: { bgColor: 'selection.600' },
            };

        const driverMenuItemProps = {
          id,
          type: 'driver' as const,
          driver,
          name,
          sectionId: 'driver' as const,
          footer: <DriverSelectMenuFooter driverId={id} />,
          searchableName: getSearchName({
            name: driver.name,
            attributes,
          }),
          selectAction,
          submenuType: 'options' as const,
          icon: selectAction === 'create-subdriver' ? <DimensionIcon /> : undefined,
          onlyEnableMeta: hasNoPrimaryAction,
          alwaysShowMeta: hasNoPrimaryAction,
          meta: (
            <>
              <IconButton
                data-testid={`open-add-subdrivers-button-${name}`}
                role="group"
                variant="icon"
                alignItems="center"
                h="unset"
                w="unset"
                border="none"
                minW="unset"
                boxSize={6}
                borderRadius="md"
                color="white"
                _hover={{ bgColor: 'selection.600' }}
                _active={{ bgColor: 'selection.600' }}
                onClick={() => {
                  setHint(getHint(driver, 'create-subdriver'));
                  onTargetDriverSelected({ id, name });
                }}
                onMouseEnter={() => onMouseEnter(driver)}
                onMouseLeave={onMouseLeave}
                className={SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS}
                aria-label={`Add subdriver for ${name}`}
                icon={<DimensionIcon />}
              />
              <OpenDetailsModalButton
                type="driver"
                id={id}
                className={SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS}
                openInNewTab
                {...metaProps}
              />
            </>
          ),
        };

        if (isDimensionalDriver) {
          return {
            ...driverMenuItemProps,
            hasNextMenu: true,
            submenuType: 'submenu' as const,
            submenu: () => (
              <DimensionalDriverSelect
                addDrivers={addDrivers}
                onClose={onClose}
                driver={driver}
                setView={setView}
              />
            ),
            meta: (
              <>
                <OpenDetailsModalButton
                  type="driver"
                  id={id}
                  className={SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS}
                  openInNewTab
                  {...metaProps}
                />
                {numSubDrivers} {pluralize('driver', numSubDrivers)}
              </>
            ),
          };
        }

        return driverMenuItemProps;
      })
      .filter(isNotNull);
  }, [
    addDrivers,
    addedDriverIds,
    attributesBySubDriverId,
    driversById,
    onClose,
    onMouseEnter,
    onMouseLeave,
    onTargetDriverSelected,
    setHint,
    setView,
  ]);

  const submodelItems = useMemo<Array<DriverBlockSelectItem & { type: 'model' }>>(() => {
    return sortedSubmodelPages
      .map(({ id, name, submodelId }) => {
        const drivers = (driversBySubmodelId[submodelId] ?? []).filter(
          (d) => d.type === DriverType.Basic,
        );
        const numDrivers = drivers.length;
        if (numDrivers === 0) {
          return null;
        }

        const [emoji, displayName] = extractEmoji(name);
        return {
          id,
          type: 'model' as const,
          name: displayName,
          icon: <EmojiIcon size="sm" emoji={emoji} />,
          sectionId: 'model',
          meta: `${numDrivers} ${pluralize('driver', numDrivers)}`,
          submenu: () => (
            <SubmodelDriverSelect
              addDrivers={addDrivers}
              onClose={onClose}
              submodelId={submodelId}
            />
          ),
          footer: <DriverSelectMenuFooter submodelId={submodelId} />,
        };
      })
      .filter(isNotNull);
  }, [sortedSubmodelPages, driversBySubmodelId, addDrivers, onClose]);

  const driverGroupItems = useMemo<Array<DriverBlockSelectItem & { type: 'driverGroup' }>>(() => {
    return Object.entries(groupDriversBySubmodelId).flatMap(([sid, groups]) => {
      return (groups ?? [])
        .map(({ groupId: gid, driverIds }) => {
          const numDrivers = driverIds.length;
          if (numDrivers === 0) {
            return null;
          }

          const name =
            (gid == null ? null : safeObjGet(driverGroupsById[gid])?.name) ??
            DEFAULT_DRIVER_GROUP_NAME;

          return {
            id: `${sid}.${gid}`,
            type: 'driverGroup' as const,
            name,
            sectionId: 'driverGroup',
            meta: `${numDrivers} ${pluralize('driver', numDrivers)}`,
            submenu: () => (
              <DriverGroupSelect driverIds={driverIds} addDrivers={addDrivers} onClose={onClose} />
            ),
            footer: <DriverSelectMenuFooter groupId={gid ?? undefined} submodelId={sid} />,
          };
        })
        .filter(isNotNull);
    });
  }, [groupDriversBySubmodelId, driverGroupsById, addDrivers, onClose]);

  const handleClose = useCallback(() => {
    if (focusedDriver && focusedDriver.type === DriverType.Basic) {
      if (addedDriverIds.includes(focusedDriver.id)) {
        dispatch(
          jumpToDriver({ driverId: focusedDriver.id, opts: { openInNewTab: false }, blockId }),
        );
      } else {
        addDrivers([focusedDriver.id]);
        setFocusedDriver(null);
      }
    }
    onClose();
  }, [addDrivers, addedDriverIds, blockId, dispatch, focusedDriver, onClose, setFocusedDriver]);

  const onSelect = useCallback(
    (item: DriverBlockSelectItem) => {
      switch (item.type) {
        case 'driver': {
          if (item.selectAction === 'add-driver') {
            addDrivers([item.driver.id]);
          } else if (item.selectAction === 'jump-to-driver') {
            dispatch(jumpToDriver({ driverId: item.id, opts: { openInNewTab: false }, blockId }));
            onClose();
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [addDrivers, blockId, dispatch, onClose],
  );

  const customOptions: CustomOption[] = useMemo(() => {
    const createNewDriverAndClose = () => {
      onSave();
      onClose();
    };
    return [
      {
        id: 'create',
        icon: <Plus />,
        render: (q: string) => <CustomCreateOption label="Create driver" helperText={q} />,
        showFirst: true,
        onSelect: createNewDriverAndClose,
      },
    ];
  }, [onClose, onSave]);

  const results = useMemo<{ items: DriverBlockSelectItem[]; sections: Section[] }>(() => {
    if (isEmptyQuery) {
      const recentDriverItems = driverItems
        .filter(
          ({ id }) => recentlyUsedDriverIds.length === 0 || recentlyUsedDriverIds.includes(id),
        )
        .map((item) => ({
          ...item,
          sectionId: 'recent',
        }));

      return {
        items: [
          ...(recentDriverItems.length > 0 ? recentDriverItems : driverItems),
          ...submodelItems,
        ],
        sections: [SECTIONS_BY_ID.recent, SECTIONS_BY_ID.driver, SECTIONS_BY_ID.model],
      };
    }

    return {
      items: [...driverItems, ...submodelItems, ...driverGroupItems],
      sections: [SECTIONS_BY_ID.driver, SECTIONS_BY_ID.model, SECTIONS_BY_ID.driverGroup],
    };
  }, [isEmptyQuery, driverItems, submodelItems, driverGroupItems, recentlyUsedDriverIds]);

  return (
    <Flex ref={containerRef} flexDir="column">
      <SelectMenu
        items={results.items}
        query={query}
        searchKeys={SEARCH_KEYS}
        onSelect={onSelect}
        onClose={handleClose}
        sections={results.sections}
        customOptions={customOptions}
        width="28rem"
        switchFocusToExactMatch
        onFocusChange={onFocusChange}
        startFocusIdx={query === '' ? null : 0}
      >
        {({ item, isFocused, idx }) => {
          switch (item.type) {
            case 'driver': {
              return <DriverSearchResult {...item} isFocused={isFocused} idx={idx} />;
            }
            case 'driverGroup':
            case 'model': {
              return <BaseSelectMenuItem item={item} isFocused={isFocused} idx={idx} />;
            }
            default: {
              return null;
            }
          }
        }}
      </SelectMenu>
    </Flex>
  );
};

export default DriverBlockSelectMenu;
