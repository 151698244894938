import { Draft } from '@reduxjs/toolkit';
import { keyBy, remove } from 'lodash';

import {
  Folder,
  FolderCreateInput,
  FolderDeleteInput,
  FolderType,
  FolderUpdateInput,
} from 'generated/graphql';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { DatasetSliceState } from 'reduxStore/reducers/datasetSlice';

export const emptyFolders = [
  {
    id: '2ef6800c-becc-4b57-b043-e7478a57b328',
    name: 'Models',
    type: FolderType.Model,
    sortIndex: 2251799813595247,
  },
  {
    id: 'bdc30958-b4d1-42a6-96f7-dd2de4d838b7',
    name: 'Databases',
    type: FolderType.Database,
    sortIndex: 2251799813600247,
  },
  {
    id: '10d0d775-6f78-4ddb-b706-10f14c7a1181',
    name: 'Pages',
    type: FolderType.Page,
    sortIndex: 2251799813605247,
  },
  {
    id: '066debaf-e3a8-411f-940c-8e0e26009634',
    name: 'Uncategorized',
    type: FolderType.Uncategorized,
    sortIndex: null,
  },
];

export function setFoldersFromSnapshot(state: Draft<DatasetSliceState>, dataset: DatasetSnapshot) {
  if (dataset == null) {
    state.folders = { byId: keyBy(emptyFolders, 'id'), allIds: emptyFolders.map(({ id }) => id) };
    return;
  }

  const { folders } = dataset;
  state.folders = {
    byId: keyBy(folders, 'id'),
    allIds: folders.map(({ id }) => id),
  };
}

export function handleCreateFolder(
  state: Draft<DatasetSliceState>,
  createFolderInput: FolderCreateInput,
) {
  const { id } = createFolderInput;
  const folder: Folder = {
    id,
    name: createFolderInput.name,
    type: createFolderInput.type,
    parent: createFolderInput.parent,
    sortIndex: createFolderInput.sortIndex ?? undefined,
  };

  if (state.folders.byId[id] != null) {
    return;
  }

  state.folders.byId[id] = folder;
  state.folders.allIds.push(id);
}

export function handleUpdateFolder(
  state: Draft<DatasetSliceState>,
  updateFolderInput: FolderUpdateInput,
) {
  const { id, name, sortIndex, parent, type } = updateFolderInput;

  const folder = state.folders.byId[id];
  if (folder == null) {
    return;
  }

  if (name != null) {
    folder.name = name;
  }

  if (sortIndex != null) {
    folder.sortIndex = sortIndex;
  }

  if (parent != null) {
    folder.parent = parent;
  }

  if (type != null) {
    folder.type = type;
  }
}

export function handleDeleteFolder(state: Draft<DatasetSliceState>, { id }: FolderDeleteInput) {
  const folder = state.folders.byId[id];

  if (folder == null) {
    return;
  }

  delete state.folders.byId[id];
  remove(state.folders.allIds, (currentId) => currentId === id);
}
