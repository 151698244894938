import { Flex } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AttributeItem } from 'components/AttributeTypeaheadMenu/AttributeTypeaheadMenu';
import { SEARCH_KEYS } from 'components/DimensionalDriverMenu/DimensionalDriverMenu';
import ManageDimensionsMenuButton from 'components/DimensionalDriverMenu/ManageDimensionsMenuButton';
import SearchInput from 'components/SearchInput/SearchInput';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import { Section } from 'components/SelectMenu/SelectMenu';
import SingleSelectMenu from 'components/SelectMenu/SingleSelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { DimDriverEditReactContext, isPlaceholderAttribute } from 'config/dimDriverEditContext';
import { SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS } from 'config/selectMenu';
import { invalidNameError } from 'helpers/errorMessages';
import { checkNameIsUniqueCaseInsensitive } from 'helpers/naming';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { createAttribute } from 'reduxStore/actions/dimensionMutations';
import {
  DimensionId,
  UserAddedAttribute,
  UserAddedDimensionType,
} from 'reduxStore/models/dimensions';
import { dimensionSelector } from 'selectors/dimensionsSelector';

interface DimensionAttributeProps {
  dimensionId: DimensionId;
  onUpdateDimension: () => void;
}

const ATTRIBUTE_SELECT_SECTIONS: Section[] = [
  {
    id: 'attributes',
    maxResults: 5,
    showMore: true,
  },
];

const DimensionAttributeSelectMenu: React.FC<DimensionAttributeProps> = ({
  dimensionId,
  onUpdateDimension,
}) => {
  const dimension = useAppSelector((state) => dimensionSelector(state, dimensionId));
  const dispatch = useAppDispatch();
  const { toggleAttr, attributes } = useContext(DimDriverEditReactContext);
  const selectedAttrOrPlaceholder = attributes.find((a) => a.dimensionId === dimensionId);
  const selectedAttrId = isPlaceholderAttribute(selectedAttrOrPlaceholder)
    ? undefined
    : selectedAttrOrPlaceholder?.id;
  const [query, setQuery] = useState('');
  const items: AttributeItem[] = useMemo(
    () =>
      dimension?.attributes
        .filter((v) => !v.deleted)
        .map((a) => ({
          ...a,
          name: a.value.toString(),
          sectionId: 'attributes',
        })) ?? [],
    [dimension?.attributes],
  );

  const onSelectCreate = useCallback(() => {
    const attributeId = uuidv4();
    dispatch(createAttribute({ dimensionId, value: query, attributeId }));
    const resultingAttr: UserAddedAttribute = {
      id: attributeId,
      type: UserAddedDimensionType,
      dimensionId,
      deleted: false,
      value: query,
    };
    onUpdateDimension();
    toggleAttr(resultingAttr);
  }, [dispatch, dimensionId, query, onUpdateDimension, toggleAttr]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, []);
  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, onUpdateDimension);

  const customOptions = useMemo(() => {
    const allAttrNames = dimension?.attributes.map((a) => a.value.toString()) ?? [];

    return [
      {
        id: 'create',
        errorMessage: (q: string) =>
          checkNameIsUniqueCaseInsensitive(q, allAttrNames)
            ? q.trim().length > 0
              ? null
              : ''
            : invalidNameError('attribute'),

        render: (_q: string) =>
          query.trim().length > 0 ? (
            <CustomCreateOption label="Create" helperText={query} />
          ) : (
            `Type to create a new ${dimension?.name ?? 'attribute'}`
          ),
        onSelect: onSelectCreate,
      },
    ];
  }, [dimension?.attributes, dimension?.name, onSelectCreate, query]);

  return (
    <SelectMenuContainer ref={ref}>
      <Flex flexDir="column" p={1} pt={3} data-testid="dimension-attribute-select-menu">
        <Flex columnGap={1} px={2} pb={0} className={SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS}>
          <SearchInput
            placeholder="Select or create an attribute"
            query={query}
            setQuery={setQuery}
            ref={inputRef}
          />
          <ManageDimensionsMenuButton name={dimension?.name} inputRef={inputRef} />
        </Flex>
        <SingleSelectMenu
          query={query}
          items={items}
          selectedItemId={selectedAttrId}
          sections={ATTRIBUTE_SELECT_SECTIONS}
          searchKeys={SEARCH_KEYS}
          allowEmptyQuery
          width="18rem"
          onSelect={toggleAttr}
          onClose={onUpdateDimension}
          customOptions={customOptions}
        >
          {BaseSelectMenuItem}
        </SingleSelectMenu>
      </Flex>
    </SelectMenuContainer>
  );
};

export default DimensionAttributeSelectMenu;
