import { keyBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useClient as useURQLClient } from 'urql';

import {
  CalculationRequest,
  CalculationUpdatesDocument,
  CalculationUpdatesSubscription,
  FormulaEntityType,
} from 'generated/graphql';
import { deepClone } from 'helpers/clone';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { enqueueCalculations } from 'reduxStore/actions/calculations';
import { Layer } from 'reduxStore/models/layers';
import { isUsingBackendCalculatorSelector } from 'selectors/calculationEngineSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';

export type OutstandingCalculations = Record<string, CalculationRequest>;

export function useCalculations(
  entities: { type: FormulaEntityType; ids: string[] },
  currentLayer: Layer,
  mergeLayer: Layer,
): OutstandingCalculations {
  const { type: entityType, ids } = entities;

  const client = useURQLClient();
  const org = useAppSelector(selectedOrgSelector);
  const isUsingBackendCalculator = useAppSelector(isUsingBackendCalculatorSelector);
  const [requestsById, setRequests] = useState<OutstandingCalculations>({});

  useEffect(() => {
    if (!isUsingBackendCalculator || org?.id == null) {
      return () => {};
    }

    const { unsubscribe } = client
      .subscription<CalculationUpdatesSubscription>(CalculationUpdatesDocument, {
        orgId: org.id,
      })
      .subscribe((result) => {
        if (result.error != null || result.data == null) {
          return;
        }

        const { requestCalculationResults } = result.data.calculationUpdates;
        if (requestCalculationResults != null) {
          setTimeout(() => {
            setRequests((current) => {
              let updated: OutstandingCalculations | null = null;
              for (const reqResult of requestCalculationResults) {
                const { requestId } = reqResult;
                const hasRequest = requestId in current;
                if (!hasRequest) {
                  continue;
                }

                if (updated == null) {
                  updated = deepClone(current);
                }
                delete updated[requestId];
              }

              return updated != null ? updated : current;
            });
          }, 1000);
        }
      });

    return unsubscribe;
  }, [client, isUsingBackendCalculator, org?.id]);

  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const dates = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const [start, end] = dates.map((date) => date.toISO());

  useEffect(() => {
    if (ids.length === 0 || currentLayer.id === mergeLayer.id) {
      return;
    }

    const requests: CalculationRequest[] = ids.flatMap((entityId) => {
      return [currentLayer.id, mergeLayer.id].map((layerId) => {
        return {
          entityType,
          entityId,
          dateRange: { start, end },
          layerId,
          requestId: uuidv4(),
        };
      });
    });
    setRequests(keyBy(requests, ({ requestId }) => requestId));
    dispatch(enqueueCalculations({ requests }));
  }, [dispatch, entityType, ids, currentLayer.id, mergeLayer.id, end, start]);

  return requestsById;
}
