import { Ref, useDeferredValue, useEffect, useMemo, useState } from 'react';

type IsTruncatedResult<T extends Element> = {
  ref: Ref<T>;
  isTruncated: boolean;
  width: number;
};

export default function useIsTruncated<
  T extends Element = HTMLParagraphElement,
>(): IsTruncatedResult<T> {
  const [element, ref] = useState<Element | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const deferredIsTruncated = useDeferredValue(isTruncated);

  const observer = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        const { width, height } = entry.contentRect;
        // sometimes this gets rounded in odd ways when the widths are equivalent
        setIsTruncated(
          width + 1 < entry.target.scrollWidth || height + 1 < entry.target.scrollHeight,
        );
        setContentWidth(Math.ceil(width));
      }),
    [],
  );

  useEffect(() => {
    if (!element) {
      return () => {};
    }

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  return { ref, isTruncated: deferredIsTruncated, width: contentWidth };
}
