import { noop } from 'lodash';
import { useEffect } from 'react';
import { useClient } from 'urql';

import {
  CalculatorStatus,
  CalculatorStatusDocument,
  CalculatorStatusQuery,
  CalculatorStatusQueryVariables,
} from 'generated/graphql';
import { DISCONNECTED_EVENT_NAME, eventEmitter } from 'helpers/appEvents';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { setCalculatorReady } from 'reduxStore/reducers/serverHealthSlice';
import { loggedInUserSelector } from 'selectors/loginSelector';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';

const POLL_FOR_HEALTH_INTERVAL_MS = 1000;

// A component that ensures the server is ready to receive requests
// from the given relevant org.
const ServerHealth = () => {
  const dispatch = useAppDispatch();
  const org = useAppSelector(selectedOrgSelector);
  const user = useAppSelector(loggedInUserSelector);

  const orgId = org?.id;
  const urqlClient = useClient();

  useEffect(
    function checkCalculatorHealth() {
      if (orgId == null || user == null) {
        return noop;
      }

      let currentPollTimeout: NodeJS.Timeout | null = null;

      // Whenever the web socket client disconnects, we assume the server has restarted
      async function pollUntilHealthy() {
        const response = await urqlClient
          .query<CalculatorStatusQuery, CalculatorStatusQueryVariables>(CalculatorStatusDocument, {
            orgId: orgId!,
          })
          .toPromise();

        const status = response.data?.calculatorStatus;
        switch (status) {
          case CalculatorStatus.Ready:
            dispatch(setCalculatorReady(true));
            break;
          case CalculatorStatus.Disabled:
          case CalculatorStatus.Error:
            dispatch(setCalculatorReady(false));
            break;
          case CalculatorStatus.Initializing:
          default:
            dispatch(setCalculatorReady(false));
            currentPollTimeout = setTimeout(() => {
              pollUntilHealthy();
            }, POLL_FOR_HEALTH_INTERVAL_MS);
            break;
        }
      }

      // The calculator is assumed to be ready, when the frontend first starts.
      //
      // For clients that default to the webworker, this is irrelevant.
      //
      // For those that use the backend calculator, this ensures that initial requests are not
      // mistakenly sent to the webworker as the frontend is loading (i.e. before the initial
      // request to the calculator status has been responded to).
      //
      // TODO: This is fragile. The frontend should be made to re-request calculations when the
      // status of the calculator changes.
      dispatch(setCalculatorReady(true));
      pollUntilHealthy();

      // Working around type issue. Need a function that returns void.
      const pollNoReturn = () => {
        pollUntilHealthy();
      };
      // Reset health anytime the web socket client disconnects
      eventEmitter.on(DISCONNECTED_EVENT_NAME, pollNoReturn);

      return () => {
        clearTimeout(currentPollTimeout!);
        eventEmitter.off(DISCONNECTED_EVENT_NAME, pollNoReturn);
      };
    },
    [urqlClient, orgId, dispatch, user],
  );

  return null;
};

export default ServerHealth;
