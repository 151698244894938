import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'PlansIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <path
      d="M12.444 5.556h-1.137c.009-.072.026-.143.026-.223V3.556c0-.854-.702-1.556-1.555-1.556H3.556C2.702 2 2 2.702 2 3.556v1.777c0 .854.702 1.556 1.556 1.556H6.47c-.009.071-.027.142-.027.222V8.89c0 .08.01.151.027.222H4.444c-.853 0-1.555.702-1.555 1.556v1.777c0 .854.702 1.556 1.555 1.556H8.89c.853 0 1.555-.702 1.555-1.556v-1.777a.896.896 0 0 0-.026-.223h2.026c.854 0 1.556-.702 1.556-1.555V7.11c0-.853-.702-1.555-1.556-1.555Zm-9.11-.223V3.556a.22.22 0 0 1 .222-.223h6.222a.22.22 0 0 1 .222.223v1.777a.22.22 0 0 1-.222.223H3.556a.22.22 0 0 1-.223-.223Zm5.777 5.334v1.777a.22.22 0 0 1-.222.223H4.444a.22.22 0 0 1-.222-.223v-1.777a.22.22 0 0 1 .222-.223H8.89a.22.22 0 0 1 .222.223Zm3.556-1.778a.22.22 0 0 1-.223.222H8a.22.22 0 0 1-.222-.222V7.11A.22.22 0 0 1 8 6.89h4.444a.22.22 0 0 1 .223.222V8.89Z"
      fill="currentColor"
    />
  ),
});
