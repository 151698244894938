import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { keyBy, omitBy } from 'lodash';

import {
  AccessorEntityType,
  AccessResourcesQuery,
  AccessResourceType,
  AccessRule,
  OrgRole,
} from 'generated/graphql';
import { uuidv4 } from 'helpers/uuidv4';
import { AccessResource } from 'reduxStore/models/accessResources';
import { EntityTable } from 'reduxStore/models/common';
import { LayerId } from 'reduxStore/models/layers';
import { applyMutationLocally_INTERNAL } from 'reduxStore/reducers/datasetSlice';
import { setAccessResourcesFromInitialLoad } from 'reduxStore/reducers/helpers/accessResources';

// TODO: We should probably rename to something like "accessResourcesSlice"
export interface SharingSliceState {
  accessResources: EntityTable<AccessResource>;
}

type AddTemporaryAccessResourceLayerLocallyArgs = {
  layerId: LayerId;
  orgId: string;
};

type AddAccessResourceLayerLocallyArgs = {
  accessResource: AccessResource;
};

type RemoveAccessResourceLayerLocallyArgs = {
  layerId: LayerId;
};

type UpdateAccessResourceLayerLocallyArgs = RemoveAccessResourceLayerLocallyArgs &
  AddAccessResourceLayerLocallyArgs;

type RemoveUserFromAcessControlListArgs = { userId: string };

const initialState: SharingSliceState = {
  accessResources: {
    allIds: [],
    byId: {},
  },
};

const sharingSlice = createSlice({
  name: 'sharing',
  initialState,
  reducers: {
    initialize: (state, action: PayloadAction<AccessResourcesQuery['accessResources']>) => {
      const accessResources = action.payload;
      setAccessResourcesFromInitialLoad(state, accessResources);
    },
    // adds a fake temporary access resource for a layer that is being published
    addTemporaryAccessResourceLayerLocally: (
      state,
      action: PayloadAction<AddTemporaryAccessResourceLayerLocallyArgs>,
    ) => {
      const { layerId, orgId } = action.payload;

      const accessResource: AccessResource = {
        id: layerId,
        resourceId: layerId,
        orgId,
        shouldInherit: false,
        parentId: null,
        type: AccessResourceType.Layer,
        accessControlList: [
          {
            entityWithAccess: {
              id: OrgRole.Member,
              type: AccessorEntityType.UserGroup,
            },
            accessRule: AccessRule.Write,
            grantedAt: new Date().toISOString(),
            grantedBy: '',
          },
        ],
      };

      state.accessResources = {
        byId: { ...state.accessResources.byId, [accessResource.id]: accessResource },
        allIds: [...state.accessResources.allIds, accessResource.id],
      };
    },
    // adds an actual access resource for a layer
    addAccessResourceLayerLocally: (
      state,
      action: PayloadAction<AddAccessResourceLayerLocallyArgs>,
    ) => {
      const { accessResource } = action.payload;

      state.accessResources = {
        byId: { ...state.accessResources.byId, [accessResource.id]: accessResource },
        allIds: [...state.accessResources.allIds, accessResource.id],
      };
    },
    // replaces a temporary access resource with the real one returned from endpoint
    updateAccessResourceLayerLocally: (
      state,
      action: PayloadAction<UpdateAccessResourceLayerLocallyArgs>,
    ) => {
      const { layerId, accessResource } = action.payload;

      if (layerId in state.accessResources.byId) {
        delete state.accessResources.byId[layerId];
      }

      state.accessResources = {
        byId: { ...state.accessResources.byId, [accessResource.id]: accessResource },
        allIds: [...state.accessResources.allIds.filter((id) => id !== layerId), accessResource.id],
      };
    },
    // removes a temporary access resource for a layer that is being unpublished in case of error
    removeAccessResourceLayerLocally: (
      state,
      action: PayloadAction<RemoveAccessResourceLayerLocallyArgs>,
    ) => {
      const { layerId } = action.payload;

      const filteredById = omitBy(
        state.accessResources.byId,
        (resource) => resource.resourceId === layerId && resource.parentId == null,
      );

      state.accessResources = {
        byId: filteredById,
        allIds: Object.keys(filteredById),
      };
    },

    removeUserFromAccessControlList: (
      state,
      action: PayloadAction<RemoveUserFromAcessControlListArgs>,
    ) => {
      const { userId } = action.payload;

      const resourcesById = { ...state.accessResources.byId };

      const accessResources = Object.values(resourcesById);

      accessResources.forEach((resource) => {
        resource.accessControlList = resource.accessControlList.filter(
          (acl) => acl.entityWithAccess.id !== userId,
        );
      });

      const updatedAccessControlListById = keyBy(accessResources, 'id');

      state.accessResources = {
        byId: updatedAccessControlListById,
        allIds: [...state.accessResources.allIds],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(applyMutationLocally_INTERNAL, (state, action) => {
      const orgId = action.payload.orgId;
      const mutation = action.payload.mutationBatch.mutation;
      const isLocal = !action.payload.isRemote;
      if (isLocal) {
        const { newBlocksPages } = mutation;
        if (newBlocksPages != null) {
          newBlocksPages.forEach((newBlocksPage) => {
            if (newBlocksPage.createdByUserId != null) {
              const accessResource: AccessResource = {
                id: uuidv4(),
                resourceId: newBlocksPage.id,
                orgId,
                shouldInherit: false,
                type: AccessResourceType.Page,
                accessControlList: [
                  {
                    entityWithAccess: {
                      id: newBlocksPage.createdByUserId,
                      type: AccessorEntityType.User,
                    },
                    accessRule: AccessRule.Full,
                    grantedAt: new Date().toISOString(),
                    grantedBy: '',
                  },
                ],
              };
              state.accessResources.byId[accessResource.id] = accessResource;
              state.accessResources.allIds.push(accessResource.id);
            }
          });
        }
      }
      return state;
    });
  },
});

export const {
  initialize,
  addAccessResourceLayerLocally,
  addTemporaryAccessResourceLayerLocally,
  updateAccessResourceLayerLocally,
  removeAccessResourceLayerLocally,
  removeUserFromAccessControlList,
} = sharingSlice.actions;
export default sharingSlice.reducer;
