import { Group } from '@visx/group';
import { DateTime } from 'luxon';

import MilestoneChartLine from 'components/DriverChart/MilestoneChartLine';
import MiniMilestoneMarker from 'components/DriverChart/MiniMilestoneMarker';
import useAppSelector from 'hooks/useAppSelector';
import useChartContext from 'hooks/useChartContext';
import { DriverId } from 'reduxStore/models/drivers';
import { firstMilestoneForDriverSelector } from 'selectors/milestonesSelector';

interface Props {
  driverId: DriverId;
}

const MilestoneMarker: React.FC<Props> = ({ driverId }) => {
  const milestone = useAppSelector((state) => firstMilestoneForDriverSelector(state, driverId));

  const { timeScale, valueScale } = useChartContext();

  if (milestone == null) {
    return null;
  }

  const { date, value } = milestone;
  if (date == null) {
    return (
      <MilestoneChartLine
        value={milestone.value}
        thresholdDirection={milestone.thresholdDirection}
        driverId={driverId}
      />
    );
  }

  const [start, end] = timeScale.domain();
  const milestoneDate = DateTime.fromISO(date).toJSDate();
  if (milestoneDate < start || milestoneDate > end) {
    return null;
  }

  const left = timeScale(DateTime.fromISO(date).toJSDate());
  const top = valueScale(value);

  return (
    <Group left={left} top={top}>
      <MiniMilestoneMarker value={value} editable={false} driverId={driverId} />
    </Group>
  );
};

export default MilestoneMarker;
