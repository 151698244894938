import { CalculationErrorType, ErrorOriginEntity } from 'generated/graphql';
import { CacheKey } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { ObjectSpecEvaluation, Value } from 'reduxStore/models/value';
import { MonthKey } from 'types/datetime';

export interface DateRange {
  start: MonthKey;
  end: MonthKey;
}

export const isCalculationError = function (
  valOrErr: ValueOrCalculationError | null | undefined,
): valOrErr is CalculationError {
  return valOrErr != null && 'error' in valOrErr;
};

export const isCalculationValue = function (
  valOrErr: ValueOrCalculationError | null | undefined,
): valOrErr is Value {
  return valOrErr != null && 'value' in valOrErr;
};

export const isAllNonCalculationError = (
  evaluation: Array<ValueOrCalculationError | undefined>,
): evaluation is Array<ValueWithCalculationContext | undefined> => {
  return !evaluation.some(isCalculationError);
};

export type ValueWithCalculationContext = Value & {
  cacheKey?: CacheKey;
  objectSpecEvaluations?: ObjectSpecEvaluation[];
};

export type ValueOrCalculationError = ValueWithCalculationContext | CalculationError;

export const NOT_APPLICABLE_ERR_TYPE = 'Not applicable';

export interface CalculationError {
  error: CalculationErrorType | typeof NOT_APPLICABLE_ERR_TYPE;
  details?: string;

  // Only provided by the BE calculator
  originEntity?: ErrorOriginEntity;
}
