import { useCallback, useMemo } from 'react';

import { CURRENCY_MENU_WIDTH_PX } from 'components/CurrencyMenu/CurrencySelectMenu';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { KEYBOARD_SHORTCUTS } from 'config/shortcuts';
import useAppDispatch from 'hooks/useAppDispatch';
import { handleDriverStyleTextKey, richTextType } from 'reduxStore/actions/cellNavigation';
import Bolding from 'vectors/Bolding';
import Italics from 'vectors/Italics';
import Strikethrough from 'vectors/Strikethrough';
import TextStyle from 'vectors/TextStyle';

const SECTION: Section[] = [
  {
    id: 'text-style-submenu',
    name: 'Text Style',
  },
];

export const useDriverTextStyleSelectMenuItems = (): SelectItem => {
  const item: SelectItem = useMemo(
    () => ({
      id: 'text-style',
      icon: <TextStyle />,
      submenu: () => <TextStyleSelectMenu />,
      name: 'Text Style',
      sectionId: 'style-options',
    }),
    [],
  );

  return item;
};

type TextStyleSelectItem = SelectItem & {
  textStyle: richTextType;
};

const TextStyleSelectMenu: React.FC<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const items: TextStyleSelectItem[] = useMemo(() => {
    return [
      {
        id: 'bold',
        icon: <Bolding />,
        name: 'Bold',
        sectionId: 'text-style-submenu',
        meta: KEYBOARD_SHORTCUTS.bold,
        textStyle: 'bold',
      },
      {
        id: 'italic',
        icon: <Italics />,
        name: 'Italic',
        sectionId: 'text-style-submenu',
        meta: KEYBOARD_SHORTCUTS.italic,
        textStyle: 'italic',
      },
      {
        id: 'strike',
        icon: <Strikethrough />,
        name: 'Strike',
        sectionId: 'text-style-submenu',
        meta: KEYBOARD_SHORTCUTS.strike,
        textStyle: 'strike',
      },
    ];
  }, []);

  const onSelect = useCallback(
    (item: TextStyleSelectItem) => {
      dispatch(handleDriverStyleTextKey({ richTextType: item.textStyle }));
      onClose?.();
    },
    [dispatch, onClose],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu
        items={items}
        sections={SECTION}
        width={CURRENCY_MENU_WIDTH_PX}
        onSelect={onSelect}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};
