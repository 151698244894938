import { BuiltInDimensionType } from 'generated/graphql';
import { isBuiltInAttribute, isUserAddedAttribute } from 'helpers/dimensionalDrivers';
import { Attribute, AttributeId } from 'reduxStore/models/dimensions';

export function attributeListToGqlAttrs(attrList: Attribute[]) {
  const userCreated = attrList.filter(isUserAddedAttribute);
  const builtIn = attrList.filter(isBuiltInAttribute);
  return {
    attributeIds: userCreated.map((a) => a.id),
    builtInAttributes: builtIn.map((a) => ({
      type: a.type,
      value: a.value.toString(),
    })),
  };
}

export function attributeListToGqlDims(attrList: Attribute[]) {
  const userCreated = attrList.filter(isUserAddedAttribute);
  const builtIn = attrList.filter(isBuiltInAttribute);
  return {
    dimensionIds: userCreated.map((a) => a.dimensionId),
    builtInDimensions: builtIn.map((a) => a.type),
  };
}

export function dimIdListToGqlDims(dimIds: string[] | undefined) {
  if (dimIds == null) {
    return {};
  }
  const userCreated = dimIds.filter(
    (d) => d !== BuiltInDimensionType.CalendarTime && d !== BuiltInDimensionType.RelativeTime,
  );
  const builtIn = dimIds.filter(
    (d) => d === BuiltInDimensionType.CalendarTime || d === BuiltInDimensionType.RelativeTime,
  ) as BuiltInDimensionType[];
  return {
    dimensionIds: userCreated,
    builtInDimensions: builtIn,
  };
}

export function getDimensionObjectFieldDefaultNameRegex(dimName: string) {
  return new RegExp(`^${dimName}\\s*\\d*$`);
}

export type AttributeFingerprint = string;

export function getAttributesFingerprint(attributeIds: AttributeId[]) {
  const sortedIds = [...attributeIds].sort();
  return `${sortedIds.join(':')}`;
}
