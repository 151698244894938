import { Button, Flex, FlexProps } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

import { LayerName } from '@features/CompareScenarios/common/LayerName';
import { LayerSelector } from '@features/CompareScenarios/Header/LayerSelector';
import { MergeChangesButton } from '@features/CompareScenarios/Header/MergeChangesButton';
import { Layer } from 'reduxStore/models/layers';
import { Close } from 'vectors';

export const LayerNameProps: FlexProps = {
  marginX: '2px',
  border: '0px solid',
  paddingLeft: '6px',
  paddingRight: '6px',
  paddingY: '2px',
};

export const Header = forwardRef(
  (
    {
      currentLayer,
      mergeLayer,
      onLayerChange,
      onClose,
      allowsSelect = false,
    }: {
      currentLayer: Layer | null;
      mergeLayer: Layer;
      onLayerChange: (currentLayer: Layer) => void;
      onClose: () => void;
      allowsSelect?: boolean;
    },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px={6}
        py={4}
        mb={0}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Flex alignItems="center" fontSize="xs" color="gray.600" fontWeight="medium">
          Comparing
          {!allowsSelect && currentLayer != null ? (
            <LayerName
              layer={currentLayer}
              layerType="current"
              color="gray.600"
              fontSize="xs"
              blockProps={LayerNameProps}
            />
          ) : (
            <LayerSelector
              currentLayer={currentLayer}
              mergeLayer={mergeLayer}
              color="gray.600"
              fontSize="xs"
              onSelect={onLayerChange}
            />
          )}
          to
          <LayerName
            layer={mergeLayer}
            layerType="merge"
            color="gray.600"
            fontSize="xs"
            blockProps={LayerNameProps}
          />
        </Flex>
        <Flex gap="1">
          {currentLayer != null && (
            <MergeChangesButton currentLayer={currentLayer} mergeLayer={mergeLayer} />
          )}
          <Button
            size="sm"
            paddingX="4px"
            onClick={onClose}
            border="0"
            boxShadow="none"
            ml={2}
            ref={ref}
          >
            <Close color="gray.500" />
          </Button>
        </Flex>
      </Flex>
    );
  },
);
