import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';

const RUNWAY_HELP_LINK =
  'https://help.runway.com/planning--collaborating/uSijm9sGNmbhAY2BeSCfmf/using-scenarios-if___-then___/s3V6QHoGAA1ZLEGoMKijz7';

const DraftScenarioAlert = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box width="431px">
      <Flex direction="column" gap="8px" p="16px" fontSize="xs">
        <Text fontWeight="semibold">Edits are captured in a private draft</Text>
        <Text fontWeight="medium" color="gray.500">
          Edits like modifying a forecast value or adding a new row to a database are captured in a
          private draft.
        </Text>
        <Text fontWeight="medium" color="gray.500">
          When ready, review and merge them to Main scenario or save them as a new scenario all
          together. Learn more {'  '}
          <Link textDecor="underline" target="_blank" href={RUNWAY_HELP_LINK}>
            here
          </Link>
          .
        </Text>
        <Button
          width="min-content"
          variant="outline"
          size="sm"
          alignSelf="flex-end"
          p="8px"
          onClick={onClick}
        >
          Dismiss
        </Button>
      </Flex>
    </Box>
  );
};

export default DraftScenarioAlert;
