import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Spacer } from '@chakra-ui/react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';

import { Tooltip } from 'chakra/tooltip';
import { EDITING_PILL_CLASS } from 'components/TimelinePill/TimelineImpactPill';
import { PILL_HEIGHT_PX, TIMELINE_PILL_BORDER_RADIUS_PX } from 'config/planTimeline';
import { getISOTimeWithoutMs, shortMonthFormat } from 'helpers/dates';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import { updateBlockConfig } from 'reduxStore/actions/blockMutations';

import styles from './TimelinePill.module.scss';

interface Props {
  children: React.ReactNode;
  hidden: boolean;
  type: 'plan' | 'impact';
  isEmpty?: boolean;
  isSelected: boolean;
  isActive: boolean;
  isAncestorHovered?: boolean;
  isAncestorSelected?: boolean;
  onClick?: React.MouseEventHandler;
  onMouseDown?: React.MouseEventHandler;
  onMouseOver?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  onContextMenu?: React.MouseEventHandler;
  actualEnd?: DateTime;
  actualStart?: DateTime;
  name: string;
}

const TimelinePill = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      name,
      type,
      isEmpty,
      isSelected,
      isActive,
      isAncestorHovered,
      isAncestorSelected,
      onClick,
      onMouseDown,
      onMouseOver,
      onMouseLeave,
      onContextMenu,
      hidden,
      children,
      actualEnd,
      actualStart,
    },
    ref,
  ) => {
    const { blockId } = useBlockContext();
    const dispatch = useAppDispatch();

    const setStart = useCallback(
      (start: DateTime) => {
        dispatch(
          updateBlockConfig({
            blockId,
            fn: (blockConfig) => {
              blockConfig.dateRange = {
                start: getISOTimeWithoutMs(start.toISO()),
                end: blockConfig.dateRange?.end,
              };
            },
          }),
        );
      },
      [blockId, dispatch],
    );

    const setEnd = useCallback(
      (end: DateTime) => {
        dispatch(
          updateBlockConfig({
            blockId,
            fn: (blockConfig) => {
              blockConfig.dateRange = {
                start: blockConfig.dateRange?.start,
                end: getISOTimeWithoutMs(end.toISO()),
              };
            },
          }),
        );
      },
      [blockId, dispatch],
    );

    return (
      <Box
        ref={ref}
        onContextMenu={onContextMenu}
        className={classNames(EDITING_PILL_CLASS, styles.pillEnhanced, {
          [styles.isHidden]: hidden,
          [styles.isSelected]: isSelected,
          [styles.isEmpty]: isEmpty,
          [styles.isActive]: isActive,
          [styles.isAncestorHovered]: isAncestorHovered,
          [styles.isAncestorSelected]: isAncestorSelected,
          [styles.isImpact]: type === 'impact',
        })}
        borderLeftRadius={actualStart != null ? 0 : `${TIMELINE_PILL_BORDER_RADIUS_PX}px`}
        borderRightRadius={actualEnd != null ? 0 : `${TIMELINE_PILL_BORDER_RADIUS_PX}px`}
        height={`${PILL_HEIGHT_PX}px`}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        // We no longer show the actual driver name in the pill, so we shove it in the data-testid
        // so we can still query for it in tests
        data-testid={`timeline-pill:${name}`}
      >
        {actualStart != null && (
          <Tooltip
            label={`Starts on ${shortMonthFormat(actualStart)}`}
            placement="top"
            openDelay={200}
            closeOnPointerDown
          >
            <ArrowBackIcon boxSize={4} color="gray.500" onClick={() => setStart(actualStart)} />
          </Tooltip>
        )}
        {children}
        {actualEnd != null && (
          <>
            <Spacer />
            <Tooltip
              label={`Ends on ${shortMonthFormat(actualEnd)}`}
              placement="top"
              openDelay={200}
              closeOnPointerDown
            >
              <ArrowForwardIcon boxSize={4} color="gray.500" onClick={() => setEnd(actualEnd)} />
            </Tooltip>
          </>
        )}
      </Box>
    );
  },
);

export default React.memo(TimelinePill);
