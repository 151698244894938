import { Draft } from '@reduxjs/toolkit';
import { createDraft, finishDraft } from 'immer';

import { DatasetMutationInput } from 'generated/graphql';
import { deepClone } from 'helpers/clone';
import { getCurrentState } from 'helpers/immer';
import { getEntitiesInLightLayer, getParentLayer } from 'helpers/layers';
import { isNamedVersion } from 'helpers/namedVersions';
import { safeObjGet } from 'helpers/typescript';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { DEFAULT_LAYER_ID, DEFAULT_LAYER_NAME, Layer, LayerId } from 'reduxStore/models/layers';
import { MutationBatch, MutationId } from 'reduxStore/models/mutations';
import {
  ApplyMutationArgs,
  DatasetSliceState,
  PendingOrFailedMutation,
} from 'reduxStore/reducers/datasetSlice';
import { setBlocksFromDatasetSnapshot } from 'reduxStore/reducers/helpers/blocks';
import { setBlocksPagesFromDatasetSnapshot } from 'reduxStore/reducers/helpers/blocksPages';
import { setBusinessObjectsFromDatasetSnapshot } from 'reduxStore/reducers/helpers/businessObjects';
import { setBusinessObjectSpecsFromDatasetSnapshot } from 'reduxStore/reducers/helpers/businessObjectSpecs';
import { setDatabaseConfigsFromDatasetSnapshot } from 'reduxStore/reducers/helpers/databaseConfigs';
import { setDimensionsAndAttributesFromDatasetSnapshot } from 'reduxStore/reducers/helpers/dimensions';
import { setDriverGroupsFromDatasetSnapshot } from 'reduxStore/reducers/helpers/driverGroup';
import { setDriversFromDatasetSnapshot } from 'reduxStore/reducers/helpers/drivers';
import { setEventsAndGroupsFromDatasetSnapshot } from 'reduxStore/reducers/helpers/events';
import { setExtDriversFromDatasetSnapshot } from 'reduxStore/reducers/helpers/extDrivers';
import { setExtObjectDataFromDatasetSnapshot } from 'reduxStore/reducers/helpers/extObjects';
import { setExtQueriesFromDatasetSnapshot } from 'reduxStore/reducers/helpers/extQueries';
import { setExtTablesFromDatasetSnapshot } from 'reduxStore/reducers/helpers/extTables';
import { setFoldersFromSnapshot } from 'reduxStore/reducers/helpers/folders';
import { setIntegrationQueriesFromDatasetSnapshot } from 'reduxStore/reducers/helpers/integrationQueries';
import {
  applyNonLayerModificationMutationBatch,
  assertDefaultLayerDraft,
  emptyLayer,
  handleCommitLayer,
  handleCreateLayer,
  handleDeleteLayer,
  handleUpdateLayer,
  setLayersFromDatasetSnapshot,
  setLayersMetadataFromSnapshot,
} from 'reduxStore/reducers/helpers/layers';
import { setMilestonesFromDatasetSnapshot } from 'reduxStore/reducers/helpers/milestones';
import {
  handleCreateNamedDatasetVersion,
  handleDeleteNamedDatasetVersion,
  handleUpdateNamedDatasetVersion,
} from 'reduxStore/reducers/helpers/namedDatasetVersion';
import { setSubmodelsFromDatasetSnapshot } from 'reduxStore/reducers/helpers/submodels';
import { CalculationEngine } from 'selectors/calculationEngineSelector';

export function initializeStateWithSnapshot(
  state: Draft<DatasetSliceState>,
  {
    snapshot,
    calculationEngineOnInitialization,
  }: {
    snapshot: DatasetSnapshot;
    calculationEngineOnInitialization?: CalculationEngine;
  },
) {
  const isCalculationEngineUnchanged =
    calculationEngineOnInitialization === state.calculationEngineOnInitialization;
  if (
    isCalculationEngineUnchanged &&
    state.snapshots[DEFAULT_LAYER_ID] != null &&
    state.isFresh &&
    state.isFullDatasetLoaded
  ) {
    return;
  }

  state.calculationEngineOnInitialization = calculationEngineOnInitialization;

  state.orgId = snapshot?.orgId;
  state.isFresh = true;

  // Maybe<T> results in this being a string | undefined | null. Just use
  // undefined for the empty case.
  state.previousMutationId = snapshot?.metadata?.lastMutationId ?? undefined;
  state.confirmedPreviousMutationId = state.previousMutationId;
  state.initialMutationId = snapshot?.metadata?.lastMutationId ?? undefined;

  resetDefaultLayerHistory(state);
  state.lastActualsTime = snapshot?.lastActualsTime ?? undefined;

  setLayersFromDatasetSnapshot(state, snapshot);
  state.snapshots[DEFAULT_LAYER_ID] = snapshot;

  const layerIds = snapshot?.layers.map((layer) => layer.id) || [];
  if (state.currentLayerId !== DEFAULT_LAYER_ID && !layerIds.includes(state.currentLayerId)) {
    setCurrentLayerId(state, DEFAULT_LAYER_ID, { shouldReload: false });
  }

  markLayersAsStaleAndReloadActiveLayers(state, { includeDefaultLayer: true });

  // N.B. some special handling for the main layer, which isn't handled the same way on the backend
  state.layers[DEFAULT_LAYER_ID].name = snapshot?.name ?? DEFAULT_LAYER_NAME;
  state.layers[DEFAULT_LAYER_ID].description = snapshot?.description ?? undefined;
}

function resetDefaultLayerHistory(state: Draft<DatasetSliceState>) {
  state.defaultLayerHistory = [];
  state.loadingHistoryAfterId = undefined;
}

export function markLayersAsStaleAndReloadActiveLayers(
  state: Draft<DatasetSliceState>,
  {
    includeDefaultLayer = false,
    activeLayerIds,
  }: { includeDefaultLayer?: boolean; activeLayerIds?: string[] } = {},
) {
  Object.values(state.layers)
    .filter((l) => !l.isDeleted)
    .forEach((layer) => {
      if (layer.id === DEFAULT_LAYER_ID && !includeDefaultLayer) {
        return;
      }
      state.isLayerUpToDate[layer.id] = false;
    });

  reloadActiveLayers(state, { includeDefaultLayer, activeLayerIds });
}

/**
 * "Active" layers are layers that are actively being looked at by the user's app in the current page.
 * These include the default layer, the current layer and any layers that are configured to be a
 * comparison layer via `block.blockConfig.comparisons.layerIds` for blocks in the current page (including
 * the details pane and the comparison modal).
 *
 * This means that non-active layers can get stale, which is tracked in `isLayerUpToDate`. This means
 * that we may have to reload a layer whenever it becomes "active". This can happen in two ways:
 *   - if the current layer changes: `setCurrentLayerId` handles that.
 *   - if a new layer is added to compare with: this is done by comparing the list of active layers
 * before and after applying mutations.
 *
 * For example, the Comparison Modal works by updating its `block.blockConfig.comparisons.layerIds` via
 * an `updateBlocks` mutations. When we call `applyMutationLocally` we can detect the new layerIds and
 * update them ensuring that we are seeing up-to-date data.
 *
 */
export function reloadActiveLayers(
  state: Draft<DatasetSliceState>,
  // It is often redundant to reload the default layer. We do want to do it
  // during initialization though as we need to build the initial state.
  {
    includeDefaultLayer = false,
    activeLayerIds,
  }: { includeDefaultLayer?: boolean; activeLayerIds?: string[] } = {},
) {
  if (includeDefaultLayer) {
    reloadLayer(state, DEFAULT_LAYER_ID);
  }
  const reloadLayersExDefault = (layerId: string) => {
    if (layerId === DEFAULT_LAYER_ID) {
      return;
    }
    reloadLayer(state, layerId);
  };

  if (activeLayerIds && activeLayerIds.length > 0) {
    activeLayerIds.forEach(reloadLayersExDefault);
  } else {
    // We need to ensure that the default layer is loaded before determining
    // activeComparisonLayerIds
    const activeComparisonLayerIds = getActiveComparisonLayerIds(state);
    activeComparisonLayerIds.forEach(reloadLayersExDefault);
  }
}

/**
 * Why are new layers skipped for reload? TLDR: Reloads for new layers are done elsewhere.
 *
 * "New layers" mutations require reload under two circumstances: either one of them is the current layer or they are used in comparisons.
 *
 * If a new layer is the current layer, which only happens when the mutation isn't remote,
 * then because the mutation was applied locally, the new layer inherits the parent layer's state on the client which should be up to date so there's no need to reload.
 *
 * Reload for new layers found in comparisons is handled in the condition block of addedComparisonLayerIds.length > 0 in applyMutationLocally.
 */
const MUTATION_TYPES_ON_DEFAULT_LAYER_TO_SKIP_RELOAD_FOR: Record<
  keyof DatasetMutationInput,
  boolean
> = {
  newBlocks: true,
  updateBlocks: true,
  deleteBlocks: true,

  newBlocksPages: true,
  updateBlocksPages: true,
  deleteBlocksPages: true,

  createExtDrivers: true,
  deleteExtDrivers: true,
  updateExtDrivers: true,

  createExtObjects: true,
  deleteExtObjects: true,

  createExtObjectSpecs: true,
  updateExtObjectSpecs: true,
  deleteExtObjectSpecs: true,

  newDimensions: true,
  updateDimensions: true,
  deleteDimensions: true,
  restoreDimensions: true,

  newLayers: true,
  updateLayers: true,
  deleteLayers: true,

  newFolders: true,
  updateFolders: true,
  deleteFolders: true,

  createExtTableRuns: false,

  commitLayerId: false,
  updateLastActualsTime: false,

  createNamedDatasetVersions: true,
  updateNamedDatasetVersions: true,
  importNamedVersionSnapshot: true,
  deleteNamedDatasetVersions: true,
  importSnapshot: true,

  createIntegrationQueries: true,
  updateIntegrationQueries: true,
  deleteIntegrationQueries: true,

  createExtTables: true,
  updateExtTables: true,
  deleteExtTables: true,

  newDrivers: false,
  updateDrivers: false,
  deleteDrivers: false,

  newDriverGroups: false,
  renameDriverGroups: false,
  deleteDriverGroups: false,

  newSubmodels: false,
  updateSubmodels: false,
  deleteSubmodels: false,

  newBusinessObjects: false,
  updateBusinessObjects: false,
  deleteBusinessObjects: false,

  newEvents: false,
  updateEvents: false,
  deleteEvents: false,

  newEventGroups: false,
  updateEventGroups: false,
  deleteEventGroups: false,

  newMilestones: false,
  updateMilestones: false,
  deleteMilestones: false,

  createExtQueries: false,
  updateExtQueries: false,
  deleteExtQueries: false,

  createDatabaseConfigs: false,
  updateDatabaseConfigs: false,
  deleteDatabaseConfigs: false,

  newBusinessObjectSpecs: false,
  updateBusinessObjectSpecs: false,
  deleteBusinessObjectSpecs: false,

  changeId: true,

  //deprecated
  newExports: false,
  updateExports: false,
  deleteExports: false,

  //deprecated
  newDriverFieldSpecs: false,
  updateDriverFieldSpecs: false,
  setDriverFields: false,
};

/**
 * Reloading all layers is expensive because it involves resetting all layers to their snapshots
 * and replaying all mutations from there. For that reason, it's helpful perf wise to avoid reloads whenever
 * possible. For certain mutations, like block mutations, it's unnecessary to reload all layers because
 * the data affected by the mutation doesn't live on layers so we can skip reloading all layers for those
 * mutations on the main layer
 */
export function shouldReloadAllLayersForMutation(
  state: DatasetSliceState,
  payload: ApplyMutationArgs,
) {
  const { mutationBatch, layerId, isEphemeral } = payload;
  const { commitLayerId } = mutationBatch.mutation;
  const isCommitLayerMutation = commitLayerId != null;

  const isRelevantMutationOnDefaultLayer =
    layerId === DEFAULT_LAYER_ID &&
    !(Object.keys(mutationBatch.mutation) as Array<keyof DatasetMutationInput>).every(
      (k) => MUTATION_TYPES_ON_DEFAULT_LAYER_TO_SKIP_RELOAD_FOR[k],
    );

  const reloadUnderEphemeral = state.localEphemeralMutation != null && !isEphemeral;

  // for now all commits are on the default layer so this is fine but once we have commits on other layers, we need to reassess this condition so we don't needlessly reload all layers
  return isCommitLayerMutation || isRelevantMutationOnDefaultLayer || reloadUnderEphemeral;
}

export const filterLayerMutations = (
  state: DatasetSliceState,
  { mutationBatch, userId, accessibleLayerIds }: ApplyMutationArgs,
): MutationBatch => {
  const { newLayers, updateLayers, deleteLayers, commitLayerId } = mutationBatch.mutation;
  let out = mutationBatch;

  if (newLayers) {
    out = deepClone(mutationBatch);

    // Omit new draft layers from other users.
    // Omit new layers you do not have permission to see.
    out.mutation.newLayers = newLayers
      .filter((layer) => !(layer.isDraft && layer.userId !== userId))
      .filter((layer) => layer.userId === userId || accessibleLayerIds.includes(layer.id));
  }

  // updateLayers, deleteLayers, and commitLayerId should not need to check access resources.
  // If the current user does not have access to a layer, it won't be sent to the client.

  if (updateLayers) {
    // Omit updates for layers you don't have.
    out = deepClone(mutationBatch);
    out.mutation.updateLayers = updateLayers.filter((layer) => layer.layerId in state.layers);
  }

  if (deleteLayers) {
    // Omit deleting layers you don't have.
    out = deepClone(out);
    out.mutation.deleteLayers = deleteLayers.filter((layer) => layer.layerId in state.layers);
  }

  if (commitLayerId != null && !(commitLayerId in state.layers)) {
    // Omit committing layers you don't have.
    out = deepClone(mutationBatch);
    out.mutation.commitLayerId = null;
  }

  return out;
};

function getChildLayerIds(state: Draft<DatasetSliceState>, parentLayerId: LayerId) {
  return Object.values(state.layers)
    .filter((layer) => layer.parentLayerId === parentLayerId)
    .map((layer) => layer.id);
}

function markAllDescendantLayersAsStale(state: Draft<DatasetSliceState>, parentLayerId: LayerId) {
  const childLayerIds = getChildLayerIds(state, parentLayerId);
  const seen = new Set<LayerId>();

  while (childLayerIds.length > 0) {
    const layerId = childLayerIds.shift();
    if (layerId == null) {
      continue;
    }
    if (seen.has(layerId)) {
      continue;
    }

    seen.add(layerId);
    markLayerAsStale(state, layerId);
    childLayerIds.push(...getChildLayerIds(state, layerId));
  }
}

export function getAllAliveMutations(
  state: Draft<DatasetSliceState>,
  layerId: LayerId,
): MutationBatch[] {
  const isUpToDate = state.isLayerUpToDate[layerId] ?? false;

  if (isUpToDate) {
    return [];
  }

  const layer = safeObjGet(state.layers[layerId]);
  if (layer == null || layer.isDeleted) {
    return [];
  }

  if (isNamedVersion(layer)) {
    return [];
  }

  const allAliveMutations: MutationBatch[] = layer.mutationBatches
    .filter((m) => !m.isUndone)
    .concat(
      state.localEphemeralMutation != null && !isNamedVersion(layer)
        ? state.localEphemeralMutation
        : [],
    );
  return allAliveMutations;
}

export function reloadLayer(
  state: Draft<DatasetSliceState>,
  layerId: LayerId,
  options?: {
    resolveDriverReferences?: boolean;
  },
) {
  const isUpToDate = state.isLayerUpToDate[layerId] ?? false;
  if (isUpToDate) {
    return;
  }

  const layer = safeObjGet(state.layers[layerId]);
  if (layer == null || layer.isDeleted) {
    return;
  }

  if (isNamedVersion(layer)) {
    return;
  }

  if (layerId === DEFAULT_LAYER_ID) {
    reloadFromSnapshot(state, state.snapshots[DEFAULT_LAYER_ID], layer.id, options);
  } else {
    let parentLayerId = DEFAULT_LAYER_ID;
    if (layer.parentLayerId != null && layer.parentLayerId !== '') {
      parentLayerId = layer.parentLayerId;
    }

    let parentLayer = safeObjGet(state.layers[parentLayerId]);
    if (parentLayer == null) {
      return;
    }

    const isParentLayerUpToDate = state.isLayerUpToDate[parentLayerId] ?? false;
    if (!isParentLayerUpToDate) {
      reloadLayer(state, parentLayerId);
      parentLayer = state.layers[parentLayerId];
    }
    const parentLayerEntities = getEntitiesInLightLayer(parentLayer);
    Object.assign(layer, parentLayerEntities);
  }

  const allAliveMutations: MutationBatch[] = getAllAliveMutations(state, layerId);

  applyMutations(state, allAliveMutations, layer.id, {
    onApplyMutationsStart: (layerDraft) => {
      layerDraft.deletedIdentifiers = {};
    },
  });

  markLayerAsUpToDate(state, layer.id);

  markAllDescendantLayersAsStale(state, layer.id);
}

export const setCurrentLayerId = (
  state: Draft<DatasetSliceState>,
  layerId: LayerId,
  options: { shouldReload: boolean } = { shouldReload: true },
) => {
  state.currentLayerId = layerId;
  if (options.shouldReload) {
    reloadLayer(state, state.currentLayerId);
  }
};

/**
 * Note: make sure the default layer is up-to-date before calling this function
 * or else state.blocks will not be up-to-date!
 */
export const getActiveComparisonLayerIds = (state: Draft<DatasetSliceState>) => {
  const layerIdSet = new Set<LayerId>([DEFAULT_LAYER_ID, state.currentLayerId]);
  Object.values(state.blocks.byId).forEach((block) => {
    block.blockConfig.comparisons?.layerIds?.forEach((layerId) => {
      const layer = state.layers[layerId];
      if (layer != null && !layer.isDeleted && layer.lockedMutationId == null) {
        layerIdSet.add(layerId);
      }
    });
  });

  return [...layerIdSet.values()];
};

function markLayerAsUpToDate(state: Draft<DatasetSliceState>, layerId: LayerId) {
  state.isLayerUpToDate[layerId] = true;
}

function markLayerAsStale(state: Draft<DatasetSliceState>, layerId: LayerId) {
  state.isLayerUpToDate[layerId] = false;
}

export function markMutationUndone(
  state: Draft<DatasetSliceState>,
  layerId: LayerId,
  toUndoId: MutationId,
): MutationBatch | undefined {
  const layer = state.layers[layerId];
  if (layer == null) {
    return undefined;
  }
  const mutation = layer.mutationBatches.find((m) => m.id === toUndoId);
  if (mutation == null) {
    return undefined;
  }
  mutation.isUndone = true;
  if (layerId === DEFAULT_LAYER_ID) {
    // reload history in these cases
    resetDefaultLayerHistory(state);
  }
  return mutation;
}

/**
 * Callers of applyMutation function will need to create and finish drafts in order to make additinal modifications to layers.
 * However, there's a cost to invoking finishDraft. The goal of onApplyMutationsStart and onApplyMutationsEnd is to help minimize the number of times we need to create new drafts for
 * additional modifications after applyMutations is called. It provides callers of applyMutations a way to tag along changes to the draft created by applyMutations.
 */
export function applyMutations(
  state: Draft<DatasetSliceState>,
  mutationBatches: MutationBatch[],
  layerId: LayerId,
  options?: {
    onApplyMutationsStart?: (layer: Draft<Layer>) => void;
    onApplyMutationsEnd?: (layer: Draft<Layer>) => void;
  },
) {
  let layer = safeObjGet(state.layers[layerId]);

  if (mutationBatches.length === 0) {
    return;
  }

  // Should not apply mutations to read only locked version
  if (isNamedVersion(layer)) {
    console.error(`Attempting to apply ${mutationBatches.length} mutation to locked named version`);
    return;
  }

  let layerDraft: Draft<Layer> | undefined;
  const { onApplyMutationsStart, onApplyMutationsEnd } = options ?? {};
  let invokedOnApplyMutationsStart = false;

  // N.B. Any change in the applied order of mutations here must also be
  // reflected in the backend
  mutationBatches.forEach((mutationBatch) => {
    // Handle layer stuff ahead of the other mutations because we need to call
    // applyNonLayerModificationMutationBatch from within handleCommitLayer and
    // we need to ensure that we have all layers loaded before
    // handleCommitLayer is called. These are a special case because they don't
    // actually act on a layer as they manipulate layers themselves.
    const {
      newLayers,
      deleteLayers,
      updateLayers,
      commitLayerId,
      createNamedDatasetVersions,
      updateNamedDatasetVersions,
      deleteNamedDatasetVersions,
    } = mutationBatch.mutation;
    newLayers?.forEach((newLayerInput) => handleCreateLayer(state, newLayerInput));

    layer = safeObjGet(state.layers[layerId]);
    if (layer == null) {
      return;
    }

    if (layerDraft == null) {
      layerDraft = createDraft(getCurrentState(layer));
    }

    deleteLayers?.forEach((deleteLayerInput) => handleDeleteLayer(state, deleteLayerInput));
    updateLayers?.forEach((updateLayerInput) => handleUpdateLayer(state, updateLayerInput));

    // Named Versions
    createNamedDatasetVersions?.forEach((newVersionInput) =>
      handleCreateNamedDatasetVersion(state, newVersionInput),
    );
    updateNamedDatasetVersions?.forEach((updateVersionInput) =>
      handleUpdateNamedDatasetVersion(state, updateVersionInput),
    );
    deleteNamedDatasetVersions?.forEach((deleteVersionInput) =>
      handleDeleteNamedDatasetVersion(state, deleteVersionInput),
    );

    if (!invokedOnApplyMutationsStart) {
      onApplyMutationsStart?.(layerDraft);
      invokedOnApplyMutationsStart = true;
    }

    if (commitLayerId != null) {
      layerDraft = handleCommitLayer(state, layerDraft, commitLayerId);
    }

    if (
      state.layers[state.currentLayerId] == null ||
      state.layers[state.currentLayerId].isDeleted
    ) {
      const parentLayer = getParentLayer({
        layers: state.layers,
        currentLayer: state.layers[state.currentLayerId],
      });
      setCurrentLayerId(state, parentLayer?.id ?? DEFAULT_LAYER_ID, {
        shouldReload: false,
      });
    }

    applyNonLayerModificationMutationBatch(state, layerDraft, mutationBatch);
  });

  if (layerDraft != null) {
    onApplyMutationsEnd?.(layerDraft);
    state.layers[layerId] = finishDraft(layerDraft);
  }
}

export function reloadFromSnapshot(
  state: Draft<DatasetSliceState>,
  snapshot: Draft<DatasetSnapshot>,
  layerId: LayerId,
  options?: {
    reloadNonLayerData?: boolean;
    resolveDriverReferences?: boolean;
  },
) {
  let layer = state.layers[layerId];
  if (layer == null) {
    return;
  }

  // since we use immer's closeDraft on layers, it is possible that the layer to be modified is immutable unless we use the createDraft/finishDraft pattern
  // since reloading from a snapshot is essentially resetting a layer to the entities in the snapshot, let's just create a new mutable layer and populate it instead.
  // this saves us the cost of invoking immer's finishDraft
  state.layers[layerId] = {
    ...emptyLayer(layerId, layer.name),
    isDraft: layer.isDraft,
    createdByUserId: layer.createdByUserId,
    createdAt: layer.createdAt,
    parentLayerId: layer.parentLayerId,
    mutationBatches: layer.mutationBatches,
    isDeleted: layer.isDeleted,
    lockedMutationId: layer.lockedMutationId,
    lastActualsTime: layer.lastActualsTime,
    description: layer.description,
  };
  layer = state.layers[layerId];

  const defaultLayer = layerId === DEFAULT_LAYER_ID ? layer : state.layers[DEFAULT_LAYER_ID];
  assertDefaultLayerDraft(defaultLayer);

  const reloadNonLayerData = options?.reloadNonLayerData ?? false;
  // Only reload data that pertains to the main layer when reloading the main layer
  // for perf reasons.
  if (layerId === DEFAULT_LAYER_ID || reloadNonLayerData) {
    setBlocksFromDatasetSnapshot(state, snapshot);

    setFoldersFromSnapshot(state, snapshot);
    setBlocksPagesFromDatasetSnapshot(state, snapshot);
  }

  setSubmodelsFromDatasetSnapshot(layer, snapshot);

  // Not a full reset to the snapshot as that would erase local changes. See
  // function comment.
  setLayersMetadataFromSnapshot(state, snapshot);

  // Dimensions/Attributes should only be present in the default layer and any named version.
  if (layerId === DEFAULT_LAYER_ID || isNamedVersion(layer)) {
    setDimensionsAndAttributesFromDatasetSnapshot(layer, snapshot);
  }

  setDriverGroupsFromDatasetSnapshot(layer, snapshot);

  // Note: setBlocksPagesFromDatasetSnapshot must be set before setDriversFromDatasetSnapshot
  // so that driverReferences can be back-filled.
  setDriversFromDatasetSnapshot(state, layer, defaultLayer, snapshot, options);

  // ExtDrivers should only be present in the default layer and any named version.
  if (
    (layerId === DEFAULT_LAYER_ID || isNamedVersion(layer) || reloadNonLayerData) &&
    state.calculationEngineOnInitialization !== 'backend-only'
  ) {
    setExtDriversFromDatasetSnapshot(layer, snapshot);
  }

  setEventsAndGroupsFromDatasetSnapshot(layer, snapshot);
  setMilestonesFromDatasetSnapshot(layer, snapshot);

  setBusinessObjectsFromDatasetSnapshot(layer, defaultLayer, snapshot);
  setBusinessObjectSpecsFromDatasetSnapshot(layer, defaultLayer, snapshot);

  // ExtObjects, ExtObjectSpecs should only be present in the default layer and any named version.
  if (
    (layerId === DEFAULT_LAYER_ID || isNamedVersion(layer) || reloadNonLayerData) &&
    state.calculationEngineOnInitialization !== 'backend-only'
  ) {
    setExtObjectDataFromDatasetSnapshot(layer, snapshot);
  }

  setIntegrationQueriesFromDatasetSnapshot(layer, snapshot);

  setExtTablesFromDatasetSnapshot(layer, snapshot);

  setDatabaseConfigsFromDatasetSnapshot(layer, snapshot);

  setExtQueriesFromDatasetSnapshot(layer, snapshot);
}

export const addPendingOrFailedMutationHelper = (
  state: Draft<DatasetSliceState>,
  pendingOrFailedMutations: PendingOrFailedMutation,
) => {
  state.pendingOrFailedMutations = state.pendingOrFailedMutations ?? [];
  state.pendingOrFailedMutations.push(pendingOrFailedMutations);
};
