import { measure } from '@features/CompareScenarios/comparators/common/performance';
import { Change } from '@features/CompareScenarios/compareLayers';
import { Layer } from 'reduxStore/models/layers';

export abstract class Comparator<ComparatorChange extends Change<any, any>, MarkReturnType = any> {
  compare(currentLayer: Layer, mergeLayer: Layer): ComparatorChange[] {
    return measure(this.constructor.name, () => {
      return this.sweep(currentLayer, mergeLayer, this.mark(currentLayer, mergeLayer));
    });
  }

  abstract mark(currentLayer: Layer, mergeLayer: Layer): MarkReturnType;

  abstract sweep(
    currentLayer: Layer,
    mergeLayer: Layer,
    changes: MarkReturnType,
  ): ComparatorChange[];
}
