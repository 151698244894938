import { Box, Link } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';

import { Tooltip } from 'chakra/tooltip';
import TrendArrowIcon from 'vectors/TrendArrow';

export const EmailLink = ({
  email,
  subject,
  body,
  children,
}: {
  email: string;
  subject: string;
  body: string;
  children: ReactNode;
}) => {
  const mailtoParams = useMemo(() => {
    return new URLSearchParams({
      subject,
      body,
    }).toString();
  }, [subject, body]);

  return (
    <Link href={`mailto:${email}?${mailtoParams}`} target="_blank" rel="nofollow noreferrer">
      <Tooltip label={email} placement="bottom" offset={[0, 3]} openDelay={350}>
        <Box as="span" textDecoration="underline">
          {children}
        </Box>
      </Tooltip>
      <TrendArrowIcon direction="up" color="gray.500" marginLeft="1" />
    </Link>
  );
};
