import { combineSlices } from '@reduxjs/toolkit';

import { ExtractState, rootReducerCreator } from 'reduxStore/reducers/sliceExt';

import agToastReducer from './agToastSlice';
import alertDialogReducer from './alertDialogSlice';
import blockLocalStateReducer from './blockLocalStateSlice';
import calculationsReducer from './calculationsSlice';
import cursorReducer from './cursorSlice';
import customizeMenuReducer from './customizeMenuSlice';
import dataSourcesReducer from './dataSourcesSlice';
import datasetReducer from './datasetSlice';
import detailPaneReducer from './detailPaneSlice';
import errorReducer from './errorSlice';
import extDriverReducer from './extDriverSlice';
import featureFlagsReducer from './featureFlagsSlice';
import formulaInputReducer from './formulaInputSlice';
import integrationLinkerReducer from './integrationLinkerSlice';
import integrationQueryLayoutReducer from './integrationQueryLayoutSlice';
import integrationsReducer from './integrationsSlice';
import liveEditingReducer from './liveEditSlice';
import loginReducer from './loginSlice';
import modelViewReducer from './modelViewSlice';
import navigationSidebarReducer from './navigationSidebarSlice';
import pageStateReducer from './pageSlice';
import pasteModalReducer from './pasteModalSlice';
import previewDatabaseReducer from './previewDatabaseSlice';
import roadmapReducer from './roadmapSlice';
import selectedOrgReducer from './selectedOrgSlice';
import serverHealthReducer from './serverHealthSlice';
import sharingReducer from './sharingSlice';
import syncStatusReducer from './syncStatusSlice';
import undoRedoReducer from './undoRedoSlice';

const sliceReducers = combineSlices({
  agToast: agToastReducer,
  alertDialog: alertDialogReducer,
  blockLocalState: blockLocalStateReducer,
  calculations: calculationsReducer,
  cursor: cursorReducer,
  customizeMenu: customizeMenuReducer,
  dataset: datasetReducer,
  dataSources: dataSourcesReducer,
  detailPane: detailPaneReducer,
  error: errorReducer,
  extDriver: extDriverReducer,
  featureFlags: featureFlagsReducer,
  formulaInput: formulaInputReducer,
  integrationLinker: integrationLinkerReducer,
  integrationQueryLayout: integrationQueryLayoutReducer,
  integrations: integrationsReducer,
  previewDatabase: previewDatabaseReducer,
  liveEdit: liveEditingReducer,
  login: loginReducer,
  modelView: modelViewReducer,
  navigationSidebar: navigationSidebarReducer,
  page: pageStateReducer,
  pasteModal: pasteModalReducer,
  roadmap: roadmapReducer,
  selectedOrg: selectedOrgReducer,
  serverHealth: serverHealthReducer,
  sharing: sharingReducer,
  syncStatus: syncStatusReducer,
  undoRedo: undoRedoReducer,
});

export type RootState = ExtractState<typeof sliceReducers>;

export const createRootReducers = rootReducerCreator<RootState>();

export default sliceReducers;
