import { Middleware, isAction } from '@reduxjs/toolkit';

import { saveLastVisitedEntity, saveUserState } from 'helpers/localState';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { restorableStateSelector } from 'selectors/restorableState';

export const saveUserStateMiddleware: Middleware<unknown, RootState> =
  (store) => (next) => (action) => {
    // TODO: devise a better system for filtering these out;
    // the performance impact here is marginal but we get no value out of
    // saving user state constantly here
    if (
      !isAction(action) ||
      action.type.startsWith('calculations/') ||
      action.type.startsWith('launchDarkly/')
    ) {
      return next(action);
    }

    const state = store.getState();
    const result = next(action);
    const nextState = store.getState();
    const restorableState = restorableStateSelector(nextState);
    if (nextState.dataset.isFullDatasetLoaded) {
      saveUserState(restorableState);

      if (
        state.page == null ||
        state.page.type !== nextState.page.type ||
        ('id' in nextState.page && 'id' in state.page && state.page.id !== nextState.page.id)
      ) {
        switch (nextState.page.type) {
          case 'blocksPage': {
            saveLastVisitedEntity({
              type: 'page',
              id: nextState.page.id,
            });
            break;
          }
          case 'submodelPage': {
            saveLastVisitedEntity({
              type: 'model',
              id: nextState.page.id,
            });
            break;
          }
          case 'plansPage': {
            saveLastVisitedEntity({
              type: 'plansPage',
            });
            break;
          }
          case 'unlistedDriversPage': {
            saveLastVisitedEntity({
              type: 'unlistedDriversPage',
            });
            break;
          }
          default:
            break;
        }
      }
    }

    return result;
  };
