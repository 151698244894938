import { Text } from '@chakra-ui/react';
import React from 'react';

import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import MoreHorizontalIcon from 'vectors/MoreHorizontal';

interface Props {
  idx: number;
  isFocused: boolean;
  extraResults: number;
}

const ShowMoreSelectMenuResult: React.FC<Props> = ({ isFocused, idx, extraResults }) => {
  return (
    <SelectMenuItem
      icon={<MoreHorizontalIcon />}
      name={
        <Text color={isFocused ? 'inherit' : 'gray.500'}>{`${extraResults} more results`}</Text>
      }
      idx={idx}
      isFocused={isFocused}
    />
  );
};

export default ShowMoreSelectMenuResult;
