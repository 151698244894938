import React, { useContext, useMemo } from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import DriverChartCard from 'components/DriverChartCard/DriverChartCard';
import DriverPropertyTableCell from 'components/DriverPropertyTableCell/DriverPropertyTableCell';
import DriverSparkline from 'components/DriverSparkline/DriverSparkline';
import TableCellPopover from 'components/TableCellPopover/TableCellPopover';
import { DriverRowContext } from 'config/driverRowContext';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import { ChartSize } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useDriverCellRef from 'hooks/useDriverCellRef';
import useTableCellPopover from 'hooks/useTableCellPopover';
import { DriverType } from 'reduxStore/models/drivers';
import { DRIVER_CHART_CARD_DIMENSIONS } from 'selectors/driverChartCardDimensionsSelector';
import { driverSelector } from 'selectors/driversSelector';
import { driverPropertyColumnTableCellWidthSelector } from 'selectors/tableColumnsSelector';

const COLUMN_TYPE: ModelViewColumnType = 'graph';

type Props = {
  columnLayerId: ColumnLayerId;
};

const DriverSparklineTableCell: React.FC<Props> = ({ columnLayerId }) => {
  const { driverId, comparisonRowLayerId } = useContext(DriverRowContext);
  const cellRef = useDriverCellRef({ columnType: COLUMN_TYPE, columnLayerId });
  const { selectCellAndOpenPopover, showPopover, closePopover } = useTableCellPopover(cellRef);
  const { blockId } = useBlockContext();
  const width = useAppSelector((state) =>
    driverPropertyColumnTableCellWidthSelector(state, {
      columnType: COLUMN_TYPE,
      blockId,
    }),
  );
  const layerId = comparisonRowLayerId ?? columnLayerId;
  const driver = useAppSelector((state) => driverSelector(state, { id: driverId, layerId }));

  const memoDriverId = useMemo(() => {
    return [driverId];
  }, [driverId]);

  return (
    <CellContextProvider cellRef={cellRef}>
      {driver?.type !== DriverType.Basic ? (
        <DriverPropertyTableCell columnType={COLUMN_TYPE} columnLayerId={columnLayerId} isSticky />
      ) : (
        <TableCellPopover
          visible={showPopover}
          autoFocus
          onClose={closePopover}
          width={DRIVER_CHART_CARD_DIMENSIONS[ChartSize.Medium]?.width}
          content={<DriverChartCard driverIds={memoDriverId} showDriverDetailsButton={false} />}
        >
          <DriverPropertyTableCell
            columnType={COLUMN_TYPE}
            columnLayerId={columnLayerId}
            onMouseDown={selectCellAndOpenPopover}
            isSticky
          >
            <DriverSparkline
              driverId={driverId}
              layerId={layerId}
              width={width}
              height={32}
              margins={{ top: 8, bottom: 8, left: 8, right: 8 }}
            />
          </DriverPropertyTableCell>
        </TableCellPopover>
      )}
    </CellContextProvider>
  );
};
export default React.memo(DriverSparklineTableCell);
