import { useCallback, useEffect, useState } from 'react';

import { CellRef } from 'config/cells';
import { isInputKeyboardEvent } from 'helpers/browserEvent';
import { isFormulaColumn } from 'helpers/cells';
import { hasFormulaInputOpen, hasTableCellPopoverOpen } from 'helpers/formula';
import { startFormulaOpenPerfMonitor } from 'helpers/performanceMonitor';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import { selectSingleCellIfNotActive } from 'reduxStore/actions/cellSelection';
import { isAlertDialogOpenSelector } from 'selectors/alertDialogSelector';

export default function useTableCellPopover(
  cellRef: CellRef,
  { isEditable = true }: { isEditable?: boolean } = {},
) {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const isAlertDialogOpen = useAppSelector(isAlertDialogOpenSelector);
  const [showPopover, setShowPopover] = useState(false);
  const closePopover = useCallback(() => {
    // let any blur handlers trigger before hiding the popover
    window.requestAnimationFrame(() => setShowPopover(false));
  }, []);

  // hacky; this doesn't request an animation frame in order to dismiss the popover,
  // which is useful for cases in which we don't need to respond to blur events
  const closePopoverImmediate = useCallback(() => {
    setShowPopover(false);
  }, []);

  const openPopover = useCallback(() => {
    if (isEditable && !hasFormulaInputOpen()) {
      if (isFormulaColumn(cellRef.columnKey)) {
        startFormulaOpenPerfMonitor();
      }
      setShowPopover(true);
    }
  }, [isEditable, cellRef.columnKey]);

  const { isActive } = useCellSelectionStateContext();
  const selectCellAndOpenPopover = useCallback(() => {
    // N.B. we do setTimeout here to allow the previously selected cell to
    // save any pending changes on blur before it's unmounted.
    if (!hasTableCellPopoverOpen()) {
      // if there's a table cell popover open, just blur and don't move the selection
      window.requestAnimationFrame(() => dispatch(selectSingleCellIfNotActive(blockId, cellRef)));
      openPopover();
    }
  }, [blockId, cellRef, dispatch, openPopover]);

  useEffect(() => {
    if (!isActive || !isEditable || showPopover) {
      return () => {};
    }

    function onEnter(ev: KeyboardEvent): void {
      if (ev.key !== 'Enter' || isInputKeyboardEvent(ev) || isAlertDialogOpen) {
        return;
      }

      if (!ev.shiftKey && !ev.metaKey && !ev.ctrlKey) {
        ev.preventDefault();
        openPopover();
      }
    }

    window.addEventListener('keydown', onEnter);

    return () => {
      window.removeEventListener('keydown', onEnter);
    };
  }, [isActive, openPopover, showPopover, isAlertDialogOpen, isEditable]);

  return {
    selectCellAndOpenPopover,
    isActive,
    showPopover,
    closePopover,
    openPopover,
    closePopoverImmediate,
  };
}
