import { sortBy } from 'lodash';
import lodashIntersection from 'lodash/intersection';
import { createCachedSelector } from 're-reselect';

import { safeObjGet } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectSpec, BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DimensionalProperty, DimensionalPropertyId } from 'reduxStore/models/collections';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { dimensionalPropertySelector } from 'selectors/collectionSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import { ParametricSelector } from 'types/redux';

export type AutofillPropertyOption = {
  lookupSpecId: BusinessObjectSpecId;
  resultPropertyId: DimensionalPropertyId;
  name: string;
  propertyId?: DimensionalPropertyId;
  selected?: boolean;
};
export const autofillPropertyOptionsBySpecIdForDimensionalPropertySelector: ParametricSelector<
  DimensionalPropertyId,
  AutofillPropertyOption[]
> = createCachedSelector(
  (state: RootState, dimensionalPropertyId: DimensionalPropertyId) =>
    dimensionalPropertySelector(state, dimensionalPropertyId),
  (state) => businessObjectSpecsByIdForLayerSelector(state),
  (dimensionalProperty, specsById) => {
    const dimensionId = dimensionalProperty?.dimension.id;
    if (dimensionId == null || dimensionalProperty == null) {
      return [];
    }
    const options: AutofillPropertyOption[] = [];
    Object.values(specsById).forEach((spec) => {
      // Possible result properties:
      // - not the same property we're trying to autofill
      // - of the same dimension as we're trying to autofill
      // - is not itself autofilled (mapped)
      const possibleResultProperties =
        spec.collection?.dimensionalProperties.filter(
          (p) =>
            p.id !== dimensionalProperty?.id && p.dimension.id === dimensionId && p.mapping == null,
        ) ?? [];
      if (possibleResultProperties.length === 0) {
        return;
      }
      const opts = possibleResultProperties.map((p) => ({
        propertyId: dimensionalProperty.id,
        dimensionalProperty,
        lookupSpecId: spec.id,
        resultPropertyId: p.id,
        name: p.name,
        selected: p.id === dimensionalProperty?.mapping?.resultPropertyId,
      }));
      options.push(...opts);
    });
    return options;
  },
)((_state, dimensionalPropertyId) => dimensionalPropertyId);

// Flattended key names to ensure the search filter logic can easily parse object
export type LookupOption = {
  id: string;
  name: string;
  lookup_spec_id?: BusinessObjectSpecId;
  lookup_spec_name: string;
  lookup_result_property_id?: DimensionalPropertyId;
  lookup_result_dimension_id?: DimensionalPropertyId;
  lookup_result_dimension_name: string;
  lookup_result_property_name: string;
  search_property_id?: DimensionalPropertyId;
  search_property_name: string;
  selected: boolean;
};
type LookupOptionsInput = {
  blockId: BlockId;
  dimensionalPropertyId: DimensionalPropertyId | undefined;
};
export const lookupDimensionOptionsByLookupSpecIdForBlockSelector: ParametricSelector<
  LookupOptionsInput,
  LookupOption[]
> = createCachedSelector(
  (state: RootState, { blockId }: LookupOptionsInput) =>
    businessObjectSpecForBlockSelector(state, blockId ?? ''),
  (state: RootState) => businessObjectSpecsByIdForLayerSelector(state),
  (state: RootState, { dimensionalPropertyId }) =>
    dimensionalPropertySelector(state, dimensionalPropertyId ?? ''),
  (spec, specsById, dimensionalProperty) => {
    const lookupOptions: LookupOption[] = [];
    if (spec == null) {
      return [];
    }

    const rootSpecDimIds = spec.collection?.dimensionalProperties.map((p) => p.dimension.id) ?? [];

    const rootSpecDimPropsByDimId = new Map<string, DimensionalProperty>(
      spec.collection?.dimensionalProperties.map((p) => [p.dimension.id, p]) ?? [],
    );
    const rootSpecDimNameByIds = new Map<string, string>(
      spec.collection?.dimensionalProperties.map((p) => [p.dimension.id, p.dimension.name]) ?? [],
    );

    const selectedMapping = dimensionalProperty?.mapping;
    Object.values(specsById).forEach((lookupSpec) => {
      // Don't include autofill suggestions from the same spec
      if (lookupSpec.id === spec.id) {
        return;
      }

      const lookupSpecDimIds =
        lookupSpec.collection?.dimensionalProperties.map((p) => p.dimension.id) ?? [];

      const specsShareCommonDimensions = lodashIntersection(rootSpecDimIds, lookupSpecDimIds);

      // Don't include lookup options if there are no common dimensions between the two specs
      if (specsShareCommonDimensions.length === 0) {
        return;
      }

      // Possible result properties:
      // - is not itself autofilled (mapped)
      const possibleResultProperties =
        lookupSpec.collection?.dimensionalProperties.filter((p) => p.mapping == null) ?? [];

      if (possibleResultProperties.length === 0) {
        return;
      }

      const generatedLookupIds = new Set<string>();
      for (const rootDimId of specsShareCommonDimensions) {
        for (const resultProp of possibleResultProperties) {
          const isSearchPropertySameAsResultProperty = resultProp.dimension.id === rootDimId;
          const lookupId = `${lookupSpec.id}-${resultProp.id}-${rootDimId}`;
          const lookupIdAlreadyGenerated = generatedLookupIds.has(lookupId);
          const isSamePropertyDimension =
            dimensionalProperty != null && rootDimId === dimensionalProperty.dimension.id;

          const mapping = rootSpecDimPropsByDimId.get(rootDimId)?.mapping;
          // Want to ensure you don't create lookups that are chained to each other
          const mappedSearchDimId = mapping?.searchDimensionPropertyId;
          if (mappedSearchDimId != null) {
            const searchDimProp = rootSpecDimPropsByDimId.get(mappedSearchDimId);

            if (searchDimProp != null) {
              const hasCycle = checkForMappingCycle({
                dimProp: searchDimProp,
                currSpecId: spec.id,
                visistedDimPropIds: new Set<string>(),
                specsById,
              });

              if (hasCycle) {
                continue;
              }
            }
          }

          if (
            !(
              !isSearchPropertySameAsResultProperty &&
              !lookupIdAlreadyGenerated &&
              !isSamePropertyDimension
            )
          ) {
            continue;
          }

          generatedLookupIds.add(lookupId);
          const isSelected =
            selectedMapping?.lookupSpecId === lookupSpec.id &&
            selectedMapping?.resultPropertyId === resultProp.id &&
            selectedMapping?.searchDimensionPropertyId === rootDimId;

          lookupOptions.push({
            id: lookupId,
            name: `${lookupSpec.name} -${lookupId}`,
            lookup_spec_id: lookupSpec.id,
            lookup_spec_name: lookupSpec.name,
            lookup_result_property_id: resultProp.id,
            lookup_result_property_name: resultProp.name,
            lookup_result_dimension_id: resultProp.dimension.id,
            lookup_result_dimension_name: resultProp.dimension.name,
            search_property_id: rootDimId,
            search_property_name: rootSpecDimNameByIds.get(rootDimId) ?? '',
            selected: isSelected,
          });
        }
      }
    });

    if (
      selectedMapping != null &&
      !lookupOptions.some(({ lookup_result_property_id, search_property_id, lookup_spec_id }) => {
        return (
          selectedMapping.lookupSpecId === lookup_spec_id &&
          selectedMapping.resultPropertyId === lookup_result_property_id &&
          selectedMapping.searchDimensionPropertyId === search_property_id
        );
      })
    ) {
      const lookupId = `${selectedMapping.lookupSpecId}-${selectedMapping.resultPropertyId}-${selectedMapping.searchDimensionPropertyId}`;
      const lookupSpec = safeObjGet(specsById[selectedMapping.lookupSpecId]);
      lookupOptions.unshift({
        id: lookupId,
        name: `${lookupSpec?.name ?? 'Unknown Database'} -${lookupId}`,
        lookup_spec_id: lookupSpec?.id,
        lookup_spec_name: lookupSpec?.name ?? 'Unknown Database',
        lookup_result_property_name: 'Unknown Property',
        lookup_result_dimension_name: 'Unknown Dimension',
        search_property_id: selectedMapping.searchDimensionPropertyId ?? '',
        search_property_name: 'Unknown Lookup',
        selected: true,
      });
    }

    return sortBy(lookupOptions, ({ selected }) => !selected);
  },
)((_state, { blockId, dimensionalPropertyId }) => `${blockId}-${dimensionalPropertyId ?? ''}`);

// Users can create lookups that are based on another lookup
// Need to ensure we don't allow them to lookups that are chained
// o/w an infinite loop will occur
// Want to check recursively the following dim properties:
// 1. the lookup spec result property
// 2. the lookup spec search property
// 3. the current specs search property

export const checkForMappingCycle = ({
  dimProp,
  currSpecId,
  visistedDimPropIds,
  specsById,
}: {
  dimProp: DimensionalProperty;
  currSpecId: string;
  visistedDimPropIds: Set<string>;
  specsById: Record<string, BusinessObjectSpec>;
}): boolean => {
  if (visistedDimPropIds.has(dimProp.id)) {
    return true;
  }
  const mapping = dimProp.mapping;
  if (mapping == null) {
    return false;
  }

  const { lookupSpecId, resultPropertyId, searchDimensionPropertyId } = mapping;

  if (visistedDimPropIds.has(resultPropertyId)) {
    return true;
  }

  const currSpec = specsById[currSpecId];
  if (currSpec == null) {
    return false;
  }

  const lookupSpec = specsById[lookupSpecId];
  if (lookupSpec == null) {
    return false;
  }

  const currSpecPropWithSearchDimension = currSpec.collection?.dimensionalProperties.filter(
    (p) => p.dimension.id === searchDimensionPropertyId,
  );

  const lookupSpecPropsWithSearchDimension = lookupSpec.collection?.dimensionalProperties.filter(
    (p) => p.dimension.id === searchDimensionPropertyId,
  );

  // There should always be at least a single match dim prop with the search dimension id in the curr and lookup specs
  if (
    currSpecPropWithSearchDimension == null ||
    currSpecPropWithSearchDimension?.length === 0 ||
    lookupSpecPropsWithSearchDimension == null ||
    lookupSpecPropsWithSearchDimension?.length === 0
  ) {
    return false;
  }

  visistedDimPropIds.add(dimProp.id);

  const dimPropertiesToValidate = [
    ...lookupSpecPropsWithSearchDimension,
    ...currSpecPropWithSearchDimension,
  ];

  for (const d of dimPropertiesToValidate) {
    const hasCycle = checkForMappingCycle({
      dimProp: d,
      currSpecId,
      visistedDimPropIds,
      specsById,
    });
    if (hasCycle) {
      return true;
    }
    visistedDimPropIds.add(dimProp.id);
  }
  return false;
};
