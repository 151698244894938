import Joi from 'joi';
import { useMemo } from 'react';

import { joiDefault } from 'helpers/errorMessages';
import useAppSelector from 'hooks/useAppSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';

const VALIDATOR_FIELD_NAME = 'dimensionName';

export function useDimensionNameValidation() {
  const allDimensions = useAppSelector(dimensionsByIdSelector);
  const validator = useMemo(() => {
    const dimensionNames = Object.values(allDimensions).map((dim) => dim.name);
    return Joi.object({
      [VALIDATOR_FIELD_NAME]: Joi.string()
        .required()
        .min(1)
        .insensitive()
        .invalid(...dimensionNames)
        .trim()
        .messages(joiDefault('dimension')),
    });
  }, [allDimensions]);

  return { validator, fieldName: VALIDATOR_FIELD_NAME };
}
