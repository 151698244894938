export const isRunwayEmployee = (loggedInUser: { email: string } | null | undefined) => {
  if (loggedInUser == null) {
    return false;
  }

  const { email } = loggedInUser;
  return (
    email.endsWith('@runway.com') ||
    email.endsWith('@runway.co') ||
    email.endsWith('@liveloveapp.com')
  );
};
