import { Flex } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import SearchInput from 'components/SearchInput/SearchInput';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';

const SEARCH_KEYS = ['name'];

type Props = {
  className?: string;
  onSelect: (item: SelectItem) => void;
  onSelectCreatePlan: (newEventGroupName: string) => void;
  onClose: () => void;
  items: SelectItem[];
};

const PlanPickerBase = React.forwardRef<HTMLDivElement, Props>(
  ({ className, onSelect, onSelectCreatePlan, onClose, items }, ref) => {
    const [query, setQuery] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }, []);

    const customOptions = useMemo(
      () => [
        {
          id: 'createPlan',
          render: (q: string) => <CustomCreateOption label="Create plan" helperText={q} />,
          onSelect: onSelectCreatePlan,
        },
      ],
      [onSelectCreatePlan],
    );

    const sections: Section[] = [
      {
        id: 'main',
        maxResults: 5,
        showMore: true,
      },
    ];

    return (
      <SelectMenuContainer ref={ref} className={className}>
        <Flex p={1} pt={2} rowGap={1} direction="column" width="22rem" data-testid="plan-picker">
          <Flex columnGap={1} px={2} pb={1}>
            <SearchInput
              placeholder="Search or create"
              query={query}
              setQuery={setQuery}
              ref={inputRef}
            />
          </Flex>
          <SelectMenu
            items={items}
            sections={sections}
            onSelect={onSelect}
            width="unset"
            query={query}
            searchKeys={SEARCH_KEYS}
            customOptions={customOptions}
            onClose={onClose}
          >
            {BaseSelectMenuItem}
          </SelectMenu>
        </Flex>
      </SelectMenuContainer>
    );
  },
);

export default React.memo(PlanPickerBase);
