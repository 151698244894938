// eslint-disable-next-line no-restricted-imports
import { ScrollSyncPane as RealScrollSyncPane, ScrollSyncPaneProps } from 'react-scroll-sync';

type Props = ScrollSyncPaneProps & { children: JSX.Element };

const ScrollSyncPane: React.FC<Props> = ({ enabled = true, attachTo, group, children }) => {
  // N.B. ScrollSyncPane does not support `enabled` changing, so we don't mount
  // it when it's not enabled.
  if (!enabled) {
    return children;
  }

  return (
    <RealScrollSyncPane attachTo={attachTo} group={group}>
      {children}
    </RealScrollSyncPane>
  );
};

export default ScrollSyncPane;
