import { useContext } from 'react';

import { CellRef, CellRefContext } from 'config/cells';

export const useCellRef = () => {
  const cellRef = useContext(CellRefContext);
  if (cellRef == null) {
    // TODO: @jlack -- we should probably throw an error here; this should never be null
    return {
      cellRef: { type: 'unknown', rowKey: {}, columnKey: {} } as unknown as CellRef,
      cellId: '',
    };
  }

  return cellRef;
};
