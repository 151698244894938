import React, { useMemo } from 'react';

import {
  DriverCellValueCacheContext,
  DriverCellValueInfo,
} from 'components/DriverTimeSeriesRow/LayerDriverTimeSeriesCell';

interface Props {
  children: React.ReactElement;
}

const DriverGridCellCacheProvider: React.FC<Props> = ({ children }) => {
  const driverValueCache = useMemo(() => {
    const cache: Record<string, DriverCellValueInfo> = {};
    const saveToCache = (key: string, value: DriverCellValueInfo) => {
      cache[key] = value;
    };
    return { cache, saveToCache };
  }, []);

  return (
    <DriverCellValueCacheContext.Provider value={driverValueCache}>
      {children}
    </DriverCellValueCacheContext.Provider>
  );
};

export default DriverGridCellCacheProvider;
