import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'DonutChart',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    boxSize: '14px',
  },
  path: (
    <path
      d="M9.15389 5.88715L11.2293 3.15809M9.56244 8.88995L12.2915 10.9653M12.6326 11.2248C10.6268 13.8624 6.86245 14.3746 4.22481 12.3687C1.58718 10.3628 1.07503 6.59851 3.0809 3.96087C5.08677 1.32323 8.85108 0.811081 11.4887 2.81695C14.1264 4.82283 14.6385 8.58714 12.6326 11.2248ZM9.90357 9.14937C9.04391 10.2798 7.43063 10.4993 6.30022 9.63962C5.1698 8.77996 4.95031 7.16669 5.80997 6.03627C6.66963 4.90585 8.2829 4.68636 9.41332 5.54602C10.5437 6.40568 10.7632 8.01896 9.90357 9.14937Z"
      stroke="#949DAD"
    />
  ),
});
