import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'PercentageSignIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  d: 'M3.09091 2C2.48818 2 2 2.48818 2 3.09091V12.9091C2 13.5118 2.48818 14 3.09091 14H12.9091C13.5118 14 14 13.5118 14 12.9091V3.09091C14 2.48818 13.5118 2 12.9091 2H3.09091ZM5.54545 3.63636C6.59818 3.63636 7.45455 4.49273 7.45455 5.54545C7.45455 6.59818 6.59818 7.45455 5.54545 7.45455C4.49273 7.45455 3.63636 6.59818 3.63636 5.54545C3.63636 4.49273 4.49273 3.63636 5.54545 3.63636ZM11.2727 4.18182C11.4122 4.18182 11.5517 4.23498 11.6584 4.34162C11.8717 4.55489 11.8717 4.89965 11.6584 5.11293L5.11293 11.6584C5.00656 11.7647 4.86691 11.8182 4.72727 11.8182C4.58764 11.8182 4.44798 11.7647 4.34162 11.6584C4.12835 11.4451 4.12835 11.1003 4.34162 10.8871L10.8871 4.34162C10.9937 4.23498 11.1332 4.18182 11.2727 4.18182ZM5.54545 4.72727C5.32846 4.72727 5.12035 4.81347 4.96691 4.96691C4.81347 5.12035 4.72727 5.32846 4.72727 5.54545C4.72727 5.76245 4.81347 5.97056 4.96691 6.124C5.12035 6.27744 5.32846 6.36364 5.54545 6.36364C5.76245 6.36364 5.97056 6.27744 6.124 6.124C6.27744 5.97056 6.36364 5.76245 6.36364 5.54545C6.36364 5.32846 6.27744 5.12035 6.124 4.96691C5.97056 4.81347 5.76245 4.72727 5.54545 4.72727ZM10.4545 8.54545C11.5073 8.54545 12.3636 9.40182 12.3636 10.4545C12.3636 11.5073 11.5073 12.3636 10.4545 12.3636C9.40182 12.3636 8.54545 11.5073 8.54545 10.4545C8.54545 9.40182 9.40182 8.54545 10.4545 8.54545ZM10.4545 9.63636C10.2376 9.63636 10.0294 9.72257 9.876 9.876C9.72257 10.0294 9.63636 10.2376 9.63636 10.4545C9.63636 10.6715 9.72257 10.8796 9.876 11.0331C10.0294 11.1865 10.2376 11.2727 10.4545 11.2727C10.6715 11.2727 10.8796 11.1865 11.0331 11.0331C11.1865 10.8796 11.2727 10.6715 11.2727 10.4545C11.2727 10.2376 11.1865 10.0294 11.0331 9.876C10.8796 9.72257 10.6715 9.63636 10.4545 9.63636Z',
});
