import { getMissingSubdrivers, isUserAddedDimension } from 'helpers/dimensionalDrivers';
import useAppSelector from 'hooks/useAppSelector';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';
import { dimensionsForLayerSelector } from 'selectors/dimensionsSelector';
import { dimensionalDriversBySubDriverIdSelector } from 'selectors/driversSelector';
import { prevailingSelectedDriverIdsSelector } from 'selectors/prevailingCellSelectionSelector';

export default function useExpandableDimensions() {
  const selectedDriverIds = useAppSelector(prevailingSelectedDriverIdsSelector);
  const dimDriverBySubdriverId = useAppSelector(dimensionalDriversBySubDriverIdSelector);
  const dimensionalPropertyEvaluator = useAppSelector(dimensionalPropertyEvaluatorSelector);

  const dimensions = useAppSelector(dimensionsForLayerSelector).filter(
    (d) =>
      !d.deleted &&
      isUserAddedDimension(d.id) &&
      d.attributes.filter((a) => !a.deleted).length > 0 &&
      selectedDriverIds.reduce((total, driverId) => {
        return (
          total +
          getMissingSubdrivers(
            dimDriverBySubdriverId[driverId],
            driverId,
            d,
            dimensionalPropertyEvaluator,
          ).length
        );
      }, 0) > 0,
  );
  return dimensions;
}
