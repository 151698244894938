import { createCachedSelector } from 're-reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { getDriverDecimalPlaces } from 'helpers/drivers';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  isNumericFieldSpec,
} from 'reduxStore/models/businessObjectSpecs';
import { DriverFormat, DriverId } from 'reduxStore/models/drivers';
import { DisplayConfiguration } from 'reduxStore/models/value';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { blockIdSelector, driverIdSelector } from 'selectors/constSelectors';
import { driverSelector } from 'selectors/driversSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import {
  resolvedBusinessObjectFieldSpecFormatSelector,
  resolvedDriverFormatSelector,
} from 'selectors/resolvedEntityFormatSelector';
import {
  orgDefaultCurrencyISOSelector,
  orgDefaultDecimalPrecisionSelector,
  orgNegativeDisplaySelector,
} from 'selectors/selectedOrgSelector';
import { ParametricSelector } from 'types/redux';

const driverCurrencySelector: ParametricSelector<DriverId, string> = createCachedSelector(
  (state: RootState, driverId: DriverId) => driverSelector(state, { id: driverId }),
  orgDefaultCurrencyISOSelector,
  (driver, defaultCurrency) => driver?.currencyISOCode ?? defaultCurrency,
)((_state, driverId) => driverId);

const fieldSpecCurrencySelector: ParametricSelector<BusinessObjectFieldSpecId, string> =
  createCachedSelector(
    (state: RootState, id: BusinessObjectFieldSpecId) =>
      businessObjectFieldSpecSelector(state, { id }),
    orgDefaultCurrencyISOSelector,
    (fieldSpec, defaultCurrency) => {
      if (fieldSpec == null || !isNumericFieldSpec(fieldSpec)) {
        return defaultCurrency;
      }
      return fieldSpec.currencyISOCode ?? defaultCurrency;
    },
  )((_state, fieldSpecId) => fieldSpecId);

export const fieldSpecDecimalSelector: ParametricSelector<
  BusinessObjectFieldSpecId,
  number | null
> = createCachedSelector(
  (state: RootState, id: BusinessObjectFieldSpecId) =>
    businessObjectFieldSpecSelector(state, { id }),
  resolvedBusinessObjectFieldSpecFormatSelector,
  (fieldSpec, format) => {
    if (fieldSpec == null || !isNumericFieldSpec(fieldSpec)) {
      return null;
    }

    if (format === DriverFormat.Integer) {
      return 0;
    }

    if (fieldSpec.decimalPlaces != null) {
      return fieldSpec.decimalPlaces;
    }

    return null;
  },
)((_state, fieldSpecId) => fieldSpecId);

export const driverDecimalPlacesSelector: ParametricSelector<DriverId, number | null> =
  createCachedSelector(
    (state, driverId) => driverSelector(state, { id: driverId }),
    resolvedDriverFormatSelector,
    getDriverDecimalPlaces,
  )((_state, driverId) => driverId);

const actualDriverDisplayConfigurationSelector: ParametricSelector<DriverId, DisplayConfiguration> =
  createCachedSelector(
    (state, driverId) => driverSelector(state, { id: driverId }),
    driverCurrencySelector,
    driverDecimalPlacesSelector,
    orgNegativeDisplaySelector,
    orgDefaultDecimalPrecisionSelector,
    (driver, currency, decimalPlaces, negativeDisplay, orgDefaultDecimalPrecision) => ({
      format: driver?.format ?? DriverFormat.Auto,
      currency,
      decimalPlaces: decimalPlaces ?? orgDefaultDecimalPrecision,
      negativeDisplay,
    }),
  )({ keySelector: driverIdSelector, selectorCreator: createDeepEqualSelector });

export const driverDisplayConfigurationSelector: ParametricSelector<
  DriverId,
  DisplayConfiguration
> = createCachedSelector(
  resolvedDriverFormatSelector,
  driverCurrencySelector,
  driverDecimalPlacesSelector,
  orgNegativeDisplaySelector,
  orgDefaultDecimalPrecisionSelector,
  (format, currency, decimalPlaces, negativeDisplay, orgDefaultDecimalPrecision) => ({
    format,
    currency,
    decimalPlaces: decimalPlaces ?? orgDefaultDecimalPrecision,
    negativeDisplay,
  }),
)({ keySelector: driverIdSelector, selectorCreator: createDeepEqualSelector });

export const fieldSpecDisplayConfigurationSelector: ParametricSelector<
  BusinessObjectFieldSpecId,
  DisplayConfiguration
> = createCachedSelector(
  resolvedBusinessObjectFieldSpecFormatSelector,
  fieldSpecCurrencySelector,
  fieldSpecDecimalSelector,
  orgNegativeDisplaySelector,
  orgDefaultDecimalPrecisionSelector,
  (format, currency, decimalPlaces, negativeDisplay, orgDefaultDecimalPrecision) => ({
    format,
    currency,
    decimalPlaces: decimalPlaces ?? orgDefaultDecimalPrecision,
    negativeDisplay,
  }),
)((_state, fieldSpecId) => fieldSpecId);

export const fieldSpecDisplayConfigurationsByIdForBlockSelector: ParametricSelector<
  BlockId,
  NullableRecord<string, DisplayConfiguration>
> = createCachedSelector(
  (state: RootState) => state,
  businessObjectSpecForBlockSelector,
  (state, objectSpec) => {
    const configs: NullableRecord<string, DisplayConfiguration> = {};
    if (objectSpec == null) {
      return configs;
    }

    for (const fieldSpec of objectSpec.fields) {
      configs[fieldSpec.id] = fieldSpecDisplayConfigurationSelector(state, fieldSpec.id);
    }

    for (const driverProperty of objectSpec.collection?.driverProperties ?? []) {
      configs[driverProperty.id] = actualDriverDisplayConfigurationSelector(
        state,
        driverProperty.driverId,
      );
    }

    return configs;
  },
  // deep equality check is required to prevent infinite loops caused by this selector
)({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });
