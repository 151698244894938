import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, Flex, HStack, IconButton, Spacer, Text } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useKey } from 'react-use';

import { RangeSlider } from 'components/RangeSlider/RangeSlider';
import TimeUnitSelectMenu from 'components/TimePeriodSelectionMenu/TimeUnitSelectionMenu';
import { preventEventDefault } from 'helpers/browserEvent';
import { getCustomRelativeFormulaTimeRange } from 'helpers/formula';
import { TimeUnit } from 'types/datetime';
import { FormulaTimeRange } from 'types/formula';

const CUSTOM_RANGE_BUTTON_FOCUS_STYLE = {
  outline: '1px solid selection.500',
};

interface Props {
  onBack: () => void;
  dateRange: FormulaTimeRange;
  onSelectTimePeriod: (input: FormulaTimeRange, options?: { closeMenu?: boolean }) => void;
}

const CustomTimePeriodSelectionMenu = ({ onBack, dateRange, onSelectTimePeriod }: Props) => {
  let initialStart = 2;
  if (dateRange.type === 'relative' && dateRange.end === dateRange.start) {
    initialStart = dateRange.start * -1;
  }
  if (dateRange.type === 'range' && dateRange.start.type === 'relative') {
    initialStart = dateRange.start.val.val * -1;
  }
  const [customUnitsAgo, setCustomUnitsAgo] = useState<number>(initialStart);

  const initialUnit =
    dateRange.type === 'range' && dateRange.start.type === 'relative'
      ? dateRange.start.val.unit
      : TimeUnit.Month;
  const [timeUnit, setTimeUnit] = useState<TimeUnit>(initialUnit);

  const onSelectRelativeDate = useCallback(() => {
    onSelectTimePeriod(
      getCustomRelativeFormulaTimeRange({ unit: timeUnit, val: customUnitsAgo * -1 }),
    );
  }, [onSelectTimePeriod, timeUnit, customUnitsAgo]);

  const onChangeUnitType = (value: TimeUnit) => {
    setTimeUnit(value);
    onSelectTimePeriod(
      getCustomRelativeFormulaTimeRange({ unit: value, val: customUnitsAgo * -1 }),
      { closeMenu: false },
    );
  };

  const onChangeRelativeUnitValue = (value: number) => {
    setCustomUnitsAgo(value);
    onSelectTimePeriod(getCustomRelativeFormulaTimeRange({ unit: timeUnit, val: value * -1 }), {
      closeMenu: false,
    });
  };

  const [previewUnitsAgo, setPreviewUnitsAgo] = useState(customUnitsAgo);

  useKey(
    'Escape',
    (ev) => {
      preventEventDefault(ev);
      onBack();
    },
    { options: { capture: true } },
    [onBack],
  );

  useKey(
    'Enter',
    (ev) => {
      ev.preventDefault();
      onSelectRelativeDate();
    },
    undefined,
    [onSelectRelativeDate],
  );

  return (
    <Flex
      flexDirection="column"
      bg="surface"
      padding={2}
      borderRadius={4}
      minWidth="21rem"
      maxWidth="32rem"
    >
      <Flex>
        <IconButton
          aria-label="Go back"
          variant="icon"
          boxSize={6}
          onClick={onBack}
          icon={<ArrowBackIcon />}
        />
        <Spacer />
        <Button
          size="xs"
          variant="coached"
          _focusVisible={CUSTOM_RANGE_BUTTON_FOCUS_STYLE}
          onClick={onSelectRelativeDate}
        >
          Done
        </Button>
      </Flex>
      <Divider mt={2} />
      <Flex justifyContent="center" mt={4} mb={2}>
        <Box width="90%" height="2.25rem">
          <RangeSlider
            values={[customUnitsAgo]}
            onChange={(values) => onChangeRelativeUnitValue(values[0])}
            onUpdate={(values) => setPreviewUnitsAgo(values[0])}
            onSubmit={onSelectRelativeDate}
            domain={[-24, 24]}
            tickValues={[-24, -18, -12, -6, 0, 6, 12, 18, 24]}
          />
        </Box>
      </Flex>
      <HStack justifyContent="center" alignContent="center" width="full">
        <Text fontSize="xs" textAlign="center">{`${Math.abs(previewUnitsAgo)}`}</Text>
        <TimeUnitSelectMenu onSelect={(value) => onChangeUnitType(value)} value={timeUnit} />
        <Text fontSize="xs" textAlign="center">
          {previewUnitsAgo < 0 ? 'from now' : 'ago'}
        </Text>
      </HStack>
    </Flex>
  );
};

export default CustomTimePeriodSelectionMenu;
