import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import { createContext } from 'react';

import { RollupType } from 'generated/graphql';

export type CustomizeDateRangeMenuContext = {
  start: DateTime;
  end: DateTime;
  setDateRange: (start: DateTime, end: DateTime) => void;
  rollupType?: RollupType[];
  fiscalYearStartMonth?: number;
};

export const CustomizeDateRangeMenuContext = createContext<CustomizeDateRangeMenuContext>({
  start: DateTime.now(),
  end: DateTime.now(),
  setDateRange: noop,
});
