import { Flex } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import BlockHeaderCell from 'components/BlockHeaderCell/BlockHeaderCell';
import DriverColumnHeaderPopoverContent from 'components/DriverColumnHeaderPopoverContent/DriverColumnHeaderPopoverContent';
import InteractiveColumnHeaderCell from 'components/InteractiveColumnHeaderCell/InteractiveColumnHeaderCell';
import { TIME_SERIES_HEADER_SUB_LABEL_HEIGHT } from 'components/TimeSeriesColumnHeaders/TimeSeriesColumnHeaders';
import TimeSeriesSubheaderLabel from 'components/TimeSeriesHeaderLabel/TimeSeriesSubheaderLabel';
import { CELL_HEIGHT_IN_PX } from 'config/cells';
import { ColumnLayerId, ModelViewColumnType, NAME_COLUMN_TYPE } from 'config/modelView';
import { toPxString } from 'helpers/styles';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { BaseDragItem } from 'hooks/useReorderable';
import { updateColumnPosition } from 'reduxStore/actions/blockMutations';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { isLayerNamedVersionSelector, layerNameByIdSelector } from 'selectors/layerSelector';
import { isBlockColumnComparisonLayoutSelector } from 'selectors/rollupSelector';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

const DRIVER_GROUP_COLUMN_DRAG_ITEM_TYPE = 'driverGroupColumn';

type DragItem = BaseDragItem & {
  columnKey: string;
};

const ColumnSubLabel: React.FC<{
  columnType: ModelViewColumnType;
  layerId: ColumnLayerId;
  numLayers: number;
}> = ({ columnType, layerId, numLayers }) => {
  const { blockId } = useBlockContext();
  const isLayerNamedVersion = useAppSelector(
    (state) => layerId != null && isLayerNamedVersionSelector(state, layerId),
  );

  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const layerNameById = useAppSelector(layerNameByIdSelector);
  const width = useAppSelector((state) => columnWidthSelector(state, { blockId, columnType }));
  const contentType = isLayerNamedVersion
    ? 'namedVersion'
    : layerId != null
      ? 'layerName'
      : 'comparisonType';

  return (
    <Flex
      width={toPxString(width / numLayers)}
      height={toPxString(TIME_SERIES_HEADER_SUB_LABEL_HEIGHT)}
      justifyContent="flex-start"
      alignItems="center"
      borderRight="1px dashed"
      borderRightColor="gray.300"
      borderBottom="1px solid"
      borderBottomColor="gray.400"
      px={2}
    >
      <TimeSeriesSubheaderLabel
        isActuals
        isBaselineLayer={layerId === baselineLayerId}
        label={layerNameById[layerId ?? baselineLayerId]}
        contentType={contentType}
      />
    </Flex>
  );
};

interface DriverGroupHeaderColumn {
  label: string;
  disabled: boolean;
  isLast: boolean;
  type: ModelViewColumnType;
  columnLayerIds: ColumnLayerId[];
  groupId?: DriverGroupId;
}

const DriverGroupHeaderColumn: React.FC<DriverGroupHeaderColumn> = ({
  disabled,
  type,
  label,
  isLast,
  columnLayerIds,
  groupId,
}) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const dragItem = useMemo(
    () => ({ type: DRIVER_GROUP_COLUMN_DRAG_ITEM_TYPE, columnKey: type }),
    [type],
  );
  const onDrop = useCallback(
    (dropItem: DragItem, relativeTo: DragItem, position: 'before' | 'after') => {
      dispatch(
        updateColumnPosition({
          blockId,
          columnKey: dropItem.columnKey,
          insertBeforeId: position === 'before' ? relativeTo.columnKey : 'end',
        }),
      );
    },
    [blockId, dispatch],
  );
  const isColumnComparisonLayout = useAppSelector((state) =>
    isBlockColumnComparisonLayoutSelector(state, blockId),
  );

  const isNameColumn = type === NAME_COLUMN_TYPE;
  const showLayerIdSubLabels = isColumnComparisonLayout && !isNameColumn;

  return (
    <Flex
      direction="column"
      justifyContent="flex-end"
      id="driver-group-header-cell"
      height={toPxString(CELL_HEIGHT_IN_PX)}
    >
      <InteractiveColumnHeaderCell
        key={type}
        item={dragItem}
        onDrop={onDrop}
        canDrag={!disabled}
        disabled={disabled}
        isLast={isLast}
        popoverContent={<DriverColumnHeaderPopoverContent columnKey={type} />}
      >
        <BlockHeaderCell groupId={groupId} key={type} columnType={type} label={label} />
      </InteractiveColumnHeaderCell>
      {showLayerIdSubLabels && (
        <Flex direction="row" id="driver-group-header-sublabel">
          {columnLayerIds.map((layerId) => {
            return (
              <ColumnSubLabel
                key={`${layerId}`}
                columnType={type}
                layerId={layerId}
                numLayers={columnLayerIds.length}
              />
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default React.memo(DriverGroupHeaderColumn);
