import { createSelector } from '@reduxjs/toolkit';

import { NavigationSidebarState } from 'reduxStore/reducers/navigationSidebarSlice';
import { Selector } from 'types/redux';

export const navigationSidebarStateSelector: Selector<NavigationSidebarState> = (state) =>
  state.navigationSidebar;

export const isNavigationSidebarCollapsedSelector: Selector<boolean> = createSelector(
  navigationSidebarStateSelector,
  (sidebarState) => sidebarState.isCollapsed,
);

export const navigationSidebarWidthSelector: Selector<number> = createSelector(
  navigationSidebarStateSelector,
  (sidebarState) => {
    return sidebarState.width;
  },
);
