import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'OperaGlassesIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      d="M10.6667 5.33318C9.81797 5.33318 9.00404 5.67032 8.40393 6.27043C7.80381 6.87055 7.46667 7.68448 7.46667 8.53318C7.46725 8.54846 7.46795 8.56374 7.46875 8.57901H7.22917C6.08783 8.57901 5.0353 9.18503 4.4625 10.1728L0.966667 16.2144C0.356533 17.2459 0 18.4468 0 19.7332C0 23.5625 3.104 26.6665 6.93333 26.6665C10.1969 26.6665 12.9251 24.4095 13.6625 21.3728C14.2468 22.0011 15.0742 22.3998 16 22.3998C16.9258 22.3998 17.7532 22.0011 18.3375 21.3728C19.0749 24.4095 21.8031 26.6665 25.0667 26.6665C28.896 26.6665 32 23.5625 32 19.7332C32 18.4468 31.6435 17.2459 31.0333 16.2144L27.5375 10.1728C26.9647 9.18609 25.9122 8.57901 24.7708 8.57901H24.5313C24.5321 8.56374 24.5327 8.54846 24.5333 8.53318C24.5333 7.68448 24.1962 6.87055 23.5961 6.27043C22.996 5.67032 22.182 5.33318 21.3333 5.33318C20.4846 5.33318 19.6707 5.67032 19.0706 6.27043C18.4705 6.87055 18.1333 7.68448 18.1333 8.53318C18.1339 8.54846 18.1346 8.56374 18.1354 8.57901H16H13.8646C13.8654 8.56374 13.8661 8.54846 13.8667 8.53318C13.8667 7.68448 13.5295 6.87055 12.9294 6.27043C12.3293 5.67032 11.5154 5.33318 10.6667 5.33318ZM6.93333 14.9332C9.584 14.9332 11.7333 17.0825 11.7333 19.7332C11.7333 22.3838 9.584 24.5332 6.93333 24.5332C4.28267 24.5332 2.13333 22.3838 2.13333 19.7332C2.13333 17.0825 4.28267 14.9332 6.93333 14.9332ZM25.0667 14.9332C27.7173 14.9332 29.8667 17.0825 29.8667 19.7332C29.8667 22.3838 27.7173 24.5332 25.0667 24.5332C22.416 24.5332 20.2667 22.3838 20.2667 19.7332C20.2667 17.0825 22.416 14.9332 25.0667 14.9332ZM16 18.1332C16.5888 18.1332 17.0667 18.611 17.0667 19.1998C17.0667 19.7886 16.5888 20.2665 16 20.2665C15.4112 20.2665 14.9333 19.7886 14.9333 19.1998C14.9333 18.611 15.4112 18.1332 16 18.1332Z"
      fill="currentColor"
    />
  ),
});
