import { Table, TableContainer } from '@chakra-ui/react';
import React from 'react';

import DatabaseColumnComparisonTableBody from 'components/CompareScenariosModalContent/DatabaseColumnComparisonTableBody';
import DatabaseColumnComparisonTableHeader from 'components/CompareScenariosModalContent/DatabaseColumnComparisonTableHeader';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import {
  comparisonColumnsForObjectSpecSelector,
  comparisonLayerIdsForBlockSelector,
} from 'selectors/scenarioComparisonSelector';

interface Props {
  objectSpecId: BusinessObjectSpecId;
}

export const DatabaseColumnComparisons: React.FC<Props> = ({ objectSpecId }) => {
  const { blockId } = useBlockContext();
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const columns = useAppSelector((state) =>
    comparisonColumnsForObjectSpecSelector(state, { objectSpecId }),
  );
  if (columns.length === 0) {
    return null;
  }

  return (
    <TableContainer key={objectSpecId}>
      <Table variant="simple" size="sm" width="fit-content">
        <DatabaseColumnComparisonTableHeader columns={columns} objectSpecId={objectSpecId} />
        <DatabaseColumnComparisonTableBody
          comparisonLayerIds={comparisonLayerIds}
          comparisonColumns={columns}
          objectSpecId={objectSpecId}
        />
      </Table>
    </TableContainer>
  );
};
