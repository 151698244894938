import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ReactElement, ReactNode, cloneElement } from 'react';

import { Circle } from '@features/CompareScenarios/blocks/common/Circle';
import { ToggleSection } from '@features/CompareScenarios/blocks/common/ToggleSection';
import {
  ActivityLog,
  ActivityObject,
} from '@features/CompareScenarios/comparators/getActivityFromLayer';
import {
  Calendar,
  Chart,
  Clock,
  Cube,
  Database,
  DownloadCSV,
  DriverTable,
  Folder,
  Formula,
  FormulaIcon,
  Group,
  IntegrationIcon,
  MilestoneFlag,
  PageIcon,
  PlansIcon,
  Table as TableIcon,
} from 'vectors';

const COLUMNS: ReactNode[] = [
  'Changes',
  <Flex gap="2" key="compare_scenarios_modal-activity_log-new-nested" alignItems="center">
    <Circle color="green.500" size="sm" /> Creates
  </Flex>,
  <Flex gap="2" key="compare_scenarios_modal-activity_log-new-nested" alignItems="center">
    <Circle color="yellow.500" size="sm" /> Updates
  </Flex>,
  <Flex gap="2" key="compare_scenarios_modal-activity_log-new-nested" alignItems="center">
    <Circle color="red.500" size="sm" /> Deletes
  </Flex>,
];

const ACTIVITY_OBJECT_TO_ICON: Record<ActivityObject, ReactElement> = {
  [ActivityObject.Page]: <PageIcon />,
  [ActivityObject.Block]: <Chart />,
  [ActivityObject.Model]: <FormulaIcon />,
  [ActivityObject.Database]: <Database />,
  [ActivityObject.Row]: <Group />,
  [ActivityObject.Driver]: <Formula />,
  [ActivityObject.DriverGroup]: <DriverTable />,
  [ActivityObject.Dimension]: <Cube />,
  [ActivityObject.Plan]: <PlansIcon />,
  [ActivityObject.PlanLineItem]: <Clock />,
  [ActivityObject.Milestone]: <MilestoneFlag />,
  [ActivityObject.LastClose]: <Calendar />,
  [ActivityObject.IntegrationQuery]: <IntegrationIcon />,
  [ActivityObject.ExtTable]: <TableIcon />,
  [ActivityObject.Workspace]: <Folder />,
  [ActivityObject.Export]: <DownloadCSV />,
};

export const ActivityLogBlock = ({ changes }: { changes: ActivityLog }) => {
  if (Object.keys(changes).length === 0) {
    return null;
  }

  return (
    <ToggleSection
      heading="Activity Log"
      textProps={{ fontSize: 'md', fontWeight: '600' }}
      sectionProps={{ marginY: 2 }}
      isDefaultOpen
    >
      <Table width="fit-content">
        <Thead>
          <Tr>
            {COLUMNS.map((content, i) => {
              return (
                <Th
                  key={`compare_scenarios_modal-activity_log-header-${i}`}
                  width={i === 0 ? '160px' : '125px'}
                  padding="2"
                  borderRight={i !== COLUMNS.length - 1 ? '1px solid' : undefined}
                  borderColor="gray.400"
                  textTransform="none"
                  letterSpacing="none"
                  fontWeight="600"
                  color="gray.500"
                >
                  {content}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(changes).map(([type, activity], i) => {
            const typeWithIcon = (
              <Flex gap="1" alignItems="center" whiteSpace="nowrap">
                {cloneElement(ACTIVITY_OBJECT_TO_ICON[type as ActivityObject], {
                  color: 'gray.500',
                })}
                {type}
              </Flex>
            );
            const changeCount = Object.keys(changes).length;

            return (
              <Tr key={`compare_scenarios_modal-activity_log-${i}`}>
                {[
                  typeWithIcon,
                  activity.created ?? '-',
                  activity.updated ?? '-',
                  activity.deleted ?? '-',
                ].map((content, j) => {
                  const border = j === 0 ? '1px solid' : '1px dashed';
                  return (
                    <Td
                      key={`compare_scenarios_modal-activity_log-${i}-${j}`}
                      padding="2"
                      borderRight={j !== COLUMNS.length - 1 ? border : undefined}
                      borderBottom={i !== changeCount - 1 ? '1px dashed' : '0'}
                      borderColor={j !== 0 ? 'gray.300' : 'gray.400'}
                      textTransform="none"
                      letterSpacing="none"
                      color="gray.600"
                      fontWeight="500"
                      fontSize="xs"
                      textAlign={j === 0 ? 'left' : 'right'}
                    >
                      {content}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </ToggleSection>
  );
};
