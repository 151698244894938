import { BoxProps, IconProps } from '@chakra-ui/react';

import { Branches } from 'vectors';

export const DEFAULT_SCENARIO_ICON_COLOR = '#EF8D77';

const DefaultScenarioIcon = ({
  color = DEFAULT_SCENARIO_ICON_COLOR,
  ...iconProps
}: {
  color?: BoxProps['color'];
} & IconProps) => {
  return <Branches boxSize={3} color={color} {...iconProps} />;
};

export default DefaultScenarioIcon;
