import { AccessorEntityType, OrgRole } from 'generated/graphql';
import { AccessorEntity } from 'reduxStore/models/accessResources';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';

export const HIDDEN_DATA_TEXT = '***';

export enum UnauthorizedReason {
  PageAccessDenied = 'page-access-denied',
  InvalidShareLink = 'invalid-share-link',
  CanNotAccessAnyScenario = 'can-not-access-any-scenario',
}

const SPEC_NAME_RESOURCE_ID_PREFIX = 'OBJECT_NAME_FIELD/';

export function getObjectSpecNameResourceId(specId: BusinessObjectSpecId) {
  return `${SPEC_NAME_RESOURCE_ID_PREFIX}${specId}`;
}

export function accessorEntityToRole(entity: AccessorEntity): OrgRole | null {
  if (entity.type !== AccessorEntityType.UserGroup) {
    return null;
  }

  if (!isOrgRoleValue(entity.id)) {
    return null;
  }

  return entity.id;
}

export function isOrgRoleValue(role: string): role is OrgRole {
  return Object.values(OrgRole).includes(role as OrgRole);
}
