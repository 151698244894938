import { Button, Flex, ModalCloseButton, ModalContent, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import { useModal } from '@features/common/GenericModal';
import { EmailLink } from 'components/EmailLink';
import { formatNumber } from 'helpers/formatting';
import useAppSelector from 'hooks/useAppSelector';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { launchDarklySelector } from 'selectors/launchDarklySelector';

const DEFAULT_EMAIL = 'rishi@runway.com';

export const OverDBLimitModal = ({
  databaseId,
  count,
  limit,
}: {
  databaseId: BusinessObjectSpecId;
  count: number;
  limit: number;
}) => {
  const { onCloseModal } = useModal();
  const { extTableLimitEmail } = useAppSelector(launchDarklySelector);
  const databasesById = useAppSelector(businessObjectSpecsByIdForLayerSelector);
  const database = databasesById[databaseId];

  const { email, subject, body } = useMemo(() => {
    return {
      email: extTableLimitEmail ?? DEFAULT_EMAIL,
      subject: '[Data Shaping] Updating my database configuration',
      body: `Hi,\n\nI am looking to bring in more than ${formatNumber(limit)} updates from a data source into my ${database.name} database.\n[ Add modeling context here ]\n\nThank you!`,
    };
  }, [database.name, extTableLimitEmail, limit]);

  return (
    <ModalContent padding="4" fontSize="xs" fontWeight="medium" minWidth="468px">
      <ModalCloseButton />
      <Text marginBottom="2" paddingRight="6">
        Refreshing <strong>{database.name}</strong> with this configuration returns over{' '}
        <strong>{formatNumber(count)}</strong> changes. The current limit is {formatNumber(limit)}{' '}
        changes.
      </Text>
      <Text marginBottom="2" as="div">
        We recommend bringing data into Runway at the granularity you plan to model at. Consider:
        <ul style={{ paddingLeft: '16px', marginTop: '8px' }}>
          <li>
            updating the &ldquo;Segment by&rdquo; from your data source to use less granular
            dimensions.
          </li>
          <li>selecting fewer drivers from your data source.</li>
        </ul>
      </Text>
      <Text marginBottom="2">
        We&rsquo;ve notified our team to increase your limits – if you need immediate assistance,{' '}
        <EmailLink email={email} subject={subject} body={body}>
          contact support
        </EmailLink>
        .
      </Text>
      <Flex justifyContent="end" alignItems="center" gap="2">
        <Button variant="accent" paddingX="2" paddingY="1" height="28px" onClick={onCloseModal}>
          Close
        </Button>
      </Flex>
    </ModalContent>
  );
};
