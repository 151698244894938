import { Box } from '@chakra-ui/react';
import React from 'react';

import DatabaseTableHeaderRow from 'components/BusinessObjectTable/DatabaseTableHeaderRow';
import DriverRowContextProvider from 'components/DriverRowContextProvider/DriverRowContextProvider';
import DriverTimeSeriesRow from 'components/DriverTimeSeriesRow/DriverTimeSeriesRow';
import FormulaTableCell from 'components/SubmodelTable/FormulaTableCell';
import { NONE_GROUP_INFO } from 'config/businessObjects';
import useAppSelector from 'hooks/useAppSelector';
import { DriverId } from 'reduxStore/models/drivers';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';

interface Props {
  driverId: DriverId;
  businessObjectId: string;
  objectGroupKey?: string;
  showFormula: boolean;
}

export const DriverPropertyAsTimeSeriesRow: React.FC<Props> = ({
  driverId,
  businessObjectId,
  objectGroupKey = NONE_GROUP_INFO.key,
  showFormula,
}) => {
  const dimensionalPropertyEvaluator = useAppSelector(dimensionalPropertyEvaluatorSelector);

  const attributes =
    dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(businessObjectId);
  const matchingSubDriverId = dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
    driverId,
    attributes.map((attr) => attr.attribute.id),
  );

  if (matchingSubDriverId == null) {
    //so stickiness of the row is preserved, render the timeseries columns of the table header to give the row the right width
    return (
      <Box height={0} overflow="hidden">
        <DatabaseTableHeaderRow showOnlyTimeseries groupKey={objectGroupKey} />
      </Box>
    );
  }

  return (
    <DriverRowContextProvider driverId={matchingSubDriverId}>
      {showFormula && (
        <>
          <FormulaTableCell columnType="formula" isSticky={false} columnLayerId={undefined} />
          <FormulaTableCell
            columnType="actualsFormula"
            isSticky={false}
            columnLayerId={undefined}
          />
        </>
      )}
      <DriverTimeSeriesRow />
    </DriverRowContextProvider>
  );
};
