import { createSelector } from '@reduxjs/toolkit';

import { ModalType } from 'reduxStore/reducers/pageSlice';
import type { PasteModalState, PasteType } from 'reduxStore/reducers/pasteModalSlice';
import { openModalTypeSelector } from 'selectors/pageBaseSelector';
import { Selector } from 'types/redux';

export const pasteModalSelector: Selector<PasteModalState> = (state) => state.pasteModal;

export const isPasteModalOpenSelector: Selector<boolean> = createSelector(
  openModalTypeSelector,
  (openModal) => openModal === ModalType.SmartPaste,
);

export const pasteDataSelector: Selector<string[][] | undefined> = createSelector(
  pasteModalSelector,
  (pasteModal) => pasteModal?.pasteData ?? undefined,
);

export const isPasteDataSetSelector: Selector<boolean> = createSelector(
  pasteDataSelector,
  (pasteData) => pasteData != null,
);

export const pasteDataTypeSelector: Selector<PasteType | undefined> = createSelector(
  pasteModalSelector,
  (pasteModal) => pasteModal?.type ?? undefined,
);

export const rowIndicesToSkipSelector: Selector<number[]> = createSelector(
  pasteModalSelector,
  (pasteModal) => pasteModal?.rowIndicesToSkip ?? [],
);
