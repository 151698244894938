import { Flex, Table, TableContainer } from '@chakra-ui/react';
import React from 'react';

import DriverPropertiesComparisonTableBody from 'components/CompareScenariosModalContent/DriverPropertiesComparisonTableBody';
import DriverPropertiesComparisonTableHeader from 'components/CompareScenariosModalContent/DriverPropertiesComparisonTableHeader';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import {
  comparisonLayerIdsForBlockSelector,
  driverPropertiesComparisonColumnsSelector,
} from 'selectors/scenarioComparisonSelector';

const DriverPropertiesComparisons: React.FC = () => {
  const { blockId } = useBlockContext();
  const columns = useAppSelector(driverPropertiesComparisonColumnsSelector);
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  if (columns.length === 0) {
    return null;
  }
  return (
    <Flex direction="column" rowGap={2}>
      <TableContainer>
        <Table variant="simple" size="sm" width="fit-content">
          <DriverPropertiesComparisonTableHeader columns={columns} />
          <DriverPropertiesComparisonTableBody
            comparisonLayerIds={comparisonLayerIds}
            comparisonColumns={columns}
          />
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default React.memo(DriverPropertiesComparisons);
