import { DriverGroupIdOrNull } from 'reduxStore/models/driverGroup';

export type SubmodelId = string;

export type Submodel = {
  id: SubmodelId;
  /** @deprecated use name on page instead */
  name?: string;
  description?: string;
  sortedDriverGroupIds?: DriverGroupIdOrNull[];
  sortIndex?: number;
};

export const DEFAULT_SUBMODEL_ID = 'default_submodel';
