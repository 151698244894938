import { deepEqual } from 'fast-equals';
import { Dictionary, keyBy, mapValues } from 'lodash';

import { Comparator } from '@features/CompareScenarios/comparators/Comparator';
import { measure } from '@features/CompareScenarios/comparators/common/performance';
import { toFormulaChangeData } from '@features/CompareScenarios/comparators/common/toComparatorChangeDataShape';
import {
  ColumnDimension,
  Currency,
  DecimalPlaces,
  FieldType,
  Formula,
  NumericFormat,
} from '@features/CompareScenarios/comparators/common/types';
import {
  Change,
  ChangeUpdate,
  ChangesUpdate,
  TouchUpdate,
  TransitionUpdate,
} from '@features/CompareScenarios/compareLayers';
import {
  BusinessObjectSpecCreateInput,
  BusinessObjectSpecDeleteInput,
  BusinessObjectSpecUpdateInput,
  DatabaseConfig,
  DatabaseConfigSourceType,
  DriverCreateInput,
  DriverDeleteInput,
  DriverType,
  DriverUpdateInput,
  EventCreateInput,
  EventDeleteInput,
  EventUpdateInput,
  ValueType,
} from 'generated/graphql';
import { areFormulasEqual } from 'helpers/formula';
import { safeObjGet } from 'helpers/typescript';
import {
  BusinessObjectFieldSpec,
  BusinessObjectSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectField,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { DimensionalProperty, DriverProperty } from 'reduxStore/models/collections';
import { DriverId } from 'reduxStore/models/drivers';
import { isObjectFieldEvent } from 'reduxStore/models/events';
import { Layer } from 'reduxStore/models/layers';
import { ValueTimeSeries } from 'reduxStore/models/timeSeries';

export class DatabaseComparator extends Comparator<DatabaseChange> {
  mark(currentLayer: Layer, mergeLayer: Layer) {
    const fieldIdToBusinessObjectId = measure(`${this.constructor.name} - Fields`, () => {
      return {
        currentLayer: this.#getFieldIdToBusinessObjectIdMapping(currentLayer),
        mergeLayer: this.#getFieldIdToBusinessObjectIdMapping(mergeLayer),
      };
    });
    const driverIdToSpecId = measure(`${this.constructor.name} - Driver Properties`, () => {
      return {
        currentLayer: this.#getDriverIdToDimensionalPropertyMapping(currentLayer),
        mergeLayer: this.#getDriverIdToDimensionalPropertyMapping(mergeLayer),
      };
    });

    const businessObjectSpecById: Dictionary<BusinessObjectSpec> = {};
    const businessObjectsBySpecId: Dictionary<Dictionary<BusinessObject>> = {};
    for (const batch of currentLayer.mutationBatches) {
      const businessObjectSpecMutations: Array<
        | BusinessObjectSpecCreateInput
        | BusinessObjectSpecUpdateInput
        | BusinessObjectSpecDeleteInput
      > = [
        ...(batch.mutation.newBusinessObjectSpecs ?? []),
        ...(batch.mutation.updateBusinessObjectSpecs ?? []),
        ...(batch.mutation.deleteBusinessObjectSpecs ?? []),
      ];

      for (const input of businessObjectSpecMutations) {
        const spec =
          currentLayer.businessObjectSpecs.byId[input.id] ??
          mergeLayer.businessObjectSpecs.byId[input.id];
        if (spec != null) {
          businessObjectSpecById[spec.id] = spec;
        }
      }

      const businessObjectMutations: Array<
        | BusinessObjectSpecCreateInput
        | BusinessObjectSpecUpdateInput
        | BusinessObjectSpecDeleteInput
      > = [
        ...(batch.mutation.newBusinessObjects ?? []),
        ...(batch.mutation.updateBusinessObjects ?? []),
        ...(batch.mutation.deleteBusinessObjects ?? []),
      ];

      for (const input of businessObjectMutations) {
        const businessObject =
          currentLayer.businessObjects.byId[input.id] ?? mergeLayer.businessObjects.byId[input.id];
        if (businessObject != null) {
          businessObjectsBySpecId[businessObject.specId] ??= {};
          businessObjectsBySpecId[businessObject.specId][businessObject.id] = businessObject;
        }
      }

      // NOTE: This can be deprecated when we deprecate fields
      const eventMutations: Array<EventCreateInput | EventUpdateInput | EventDeleteInput> = [
        ...(batch.mutation.newEvents ?? []),
        ...(batch.mutation.updateEvents ?? []),
        ...(batch.mutation.deleteEvents ?? []),
      ];
      for (const input of eventMutations) {
        const event = currentLayer.events.byId[input.id] ?? mergeLayer.events.byId[input.id];
        if (event == null || !isObjectFieldEvent(event)) {
          continue;
        }

        const businessObjectId =
          fieldIdToBusinessObjectId.currentLayer[event.businessObjectFieldId] ??
          fieldIdToBusinessObjectId.mergeLayer[event.businessObjectFieldId];
        if (businessObjectId == null) {
          continue;
        }

        const businessObject =
          currentLayer.businessObjects.byId[businessObjectId] ??
          mergeLayer.businessObjects.byId[businessObjectId];
        if (businessObject == null) {
          continue;
        }

        businessObjectsBySpecId[businessObject.specId] ??= {};
        businessObjectsBySpecId[businessObject.specId][businessObject.id] = businessObject;
      }

      const driverMutations: Array<DriverCreateInput | DriverUpdateInput | DriverDeleteInput> = [
        ...(batch.mutation.newDrivers ?? []),
        ...(batch.mutation.updateDrivers ?? []),
        ...(batch.mutation.deleteDrivers ?? []),
      ];

      for (const input of driverMutations) {
        const driver = currentLayer.drivers.byId[input.id] ?? mergeLayer.drivers.byId[input.id];
        if (driver == null || driver.type !== DriverType.Dimensional) {
          continue;
        }

        const specId =
          driverIdToSpecId.currentLayer[driver.id] ?? driverIdToSpecId.mergeLayer[driver.id];
        if (specId == null) {
          continue;
        }

        const spec =
          currentLayer.businessObjectSpecs.byId[specId] ??
          mergeLayer.businessObjectSpecs.byId[specId];
        if (spec == null) {
          continue;
        }

        businessObjectSpecById[spec.id] = spec;
      }
    }

    return {
      businessObjectSpecs: Object.values(businessObjectSpecById),
      businessObjectsBySpecId: mapValues(businessObjectsBySpecId, (businessObjectsById) =>
        Object.values(businessObjectsById),
      ),
    };
  }

  #getFieldIdToBusinessObjectIdMapping(
    layer: Layer,
  ): Record<BusinessObjectFieldId, BusinessObjectId> {
    return Object.values(layer.businessObjects.byId).reduce<
      Record<BusinessObjectFieldId, BusinessObjectId>
    >((acc, businessObject) => {
      for (const field of businessObject.fields) {
        acc[field.id] = businessObject.id;
      }
      return acc;
    }, {});
  }

  #getDriverIdToDimensionalPropertyMapping(layer: Layer): Record<DriverId, BusinessObjectSpecId> {
    return Object.values(layer.businessObjectSpecs.byId).reduce<
      Record<DriverId, BusinessObjectSpecId>
    >((acc, spec) => {
      for (const property of spec.collection?.driverProperties ?? []) {
        acc[property.driverId] = spec.id;
      }
      return acc;
    }, {});
  }

  sweep(
    currentLayer: Layer,
    mergeLayer: Layer,
    {
      businessObjectSpecs,
      businessObjectsBySpecId,
    }: {
      businessObjectSpecs: BusinessObjectSpec[];
      businessObjectsBySpecId: Dictionary<BusinessObject[]>;
    },
  ) {
    const changesBySpecId: Dictionary<Change<BusinessObjectSpec, Fields>> = {};
    for (const spec of businessObjectSpecs) {
      const change = this.#handleSpecChanges(spec, currentLayer, mergeLayer);

      if (change != null) {
        changesBySpecId[spec.id] = change;
      }
    }

    for (const [specId, businessObjects] of Object.entries(businessObjectsBySpecId)) {
      const spec =
        currentLayer.businessObjectSpecs.byId[specId] ??
        mergeLayer.businessObjectSpecs.byId[specId] ??
        null;

      // NOTE: This means the spec has been fully removed. In that case, nothing to do here.
      if (spec == null) {
        continue;
      }

      const update = this.#handleBusinessObjectChanges(
        spec,
        businessObjects,
        currentLayer,
        mergeLayer,
      );

      if (update != null) {
        changesBySpecId[spec.id] ??= {
          object: spec,
          status: 'updated',
          updatesByField: {},
        };
        changesBySpecId[spec.id].updatesByField.businessObjects = update;
      }
    }

    return Object.values(changesBySpecId);
  }

  #handleSpecChanges(spec: BusinessObjectSpec, currentLayer: Layer, mergeLayer: Layer) {
    const change: Change<BusinessObjectSpec, Fields> = {
      object: spec,
      status: null,
      updatesByField: {},
    };

    if (!(spec.id in mergeLayer.businessObjectSpecs.byId)) {
      change.status = 'created';
    } else if (!(spec.id in currentLayer.businessObjectSpecs.byId)) {
      change.status = 'deleted';
    }

    // NOTE: as long as the spec exists in the current layer,
    // this is a create or an update, but NOT a delete
    if (spec.id in currentLayer.businessObjectSpecs.byId) {
      const currentLayerSpec = safeObjGet(currentLayer.businessObjectSpecs.byId[spec.id]);
      const mergeLayerSpec = safeObjGet(mergeLayer.businessObjectSpecs.byId[spec.id]);
      if (change.status == null && currentLayerSpec?.name !== mergeLayerSpec?.name) {
        change.updatesByField.name = {
          field: 'Name',
          from: mergeLayerSpec?.name,
          to: currentLayerSpec?.name,
        };
      }

      if (currentLayerSpec != null) {
        const schemaUpdate = this.#handleSpecSchemaChanges(
          currentLayerSpec,
          mergeLayerSpec,
          currentLayer,
          mergeLayer,
        );
        if (schemaUpdate != null) {
          change.updatesByField.schema = schemaUpdate;
        }

        const configUpdate = this.#handleConfigChanges(
          currentLayerSpec,
          mergeLayerSpec,
          currentLayer,
          mergeLayer,
        );
        if (configUpdate != null) {
          change.updatesByField.config = { field: 'Config', change: configUpdate };
        }
      }

      if (change.status == null && Object.keys(change.updatesByField).length > 0) {
        change.status = 'updated';
      }
    }

    if (change.status == null) {
      return null;
    }
    return change;
  }

  #handleBusinessObjectChanges(
    spec: BusinessObjectSpec,
    businessObjects: BusinessObject[],
    currentLayer: Layer,
    mergeLayer: Layer,
  ) {
    const update: Fields['businessObjects'] = {
      field: 'Business Objects',
      changes: [],
    };
    for (const businessObject of businessObjects) {
      const change: BusinessObjectChange = {
        object: businessObject,
        status: null,
        updatesByField: {},
      };

      if (!(businessObject.id in mergeLayer.businessObjects.byId)) {
        change.status = 'created';
      } else if (!(businessObject.id in currentLayer.businessObjects.byId)) {
        change.status = 'deleted';
      } else {
        const currentLayerObject = currentLayer.businessObjects.byId[businessObject.id];
        const mergeLayerObject = mergeLayer.businessObjects.byId[businessObject.id];

        if (currentLayerObject.name !== mergeLayerObject.name) {
          change.updatesByField.name = {
            field: 'Name',
            from: mergeLayerObject.name,
            to: currentLayerObject.name,
          };
        }

        const attributeChanges = this.#handleAttributeUpdates(currentLayerObject, mergeLayerObject);
        if (attributeChanges != null) {
          change.updatesByField.attributes = attributeChanges;
        }

        const fieldChanges = this.#handleBusinessObjectFieldChanges(
          spec,
          currentLayerObject,
          mergeLayerObject,
        );
        if (fieldChanges != null) {
          change.updatesByField.fields = fieldChanges;
        }

        if (Object.keys(change.updatesByField).length > 0) {
          change.status = 'updated';
        }
      }

      if (change.status != null) {
        update.changes.push(change);
      }
    }

    if (update.changes.length === 0) {
      return null;
    }
    return update;
  }

  #handleAttributeUpdates(
    currentObject: BusinessObject,
    mergeObject: BusinessObject,
  ): TouchUpdate | null {
    const getAttributeIds = (object: BusinessObject) => {
      return (object.collectionEntry?.attributeProperties ?? [])
        .map(({ attribute }) => attribute.id)
        .toSorted();
    };
    const currentAttributeIds = getAttributeIds(currentObject);
    const mergeAttributeIds = getAttributeIds(mergeObject);

    if (deepEqual(currentAttributeIds, mergeAttributeIds)) {
      return null;
    }
    return {
      field: 'Attribute Updates',
    };
  }

  #handleConfigChanges(
    currentLayerSpec: BusinessObjectSpec,
    mergeLayerSpec: BusinessObjectSpec | undefined,
    currentLayer: Layer,
    mergeLayer: Layer,
  ) {
    const currentLayerConfig = safeObjGet(
      currentLayerSpec.databaseConfigId != null
        ? currentLayer.databaseConfigs.byId[currentLayerSpec.databaseConfigId]
        : undefined,
    );
    const mergeLayerConfig = safeObjGet(
      mergeLayerSpec?.databaseConfigId != null
        ? mergeLayer.databaseConfigs.byId[mergeLayerSpec.databaseConfigId]
        : undefined,
    );
    if (currentLayerConfig == null && mergeLayerConfig == null) {
      return null;
    }

    const change: ConfigChange = {
      object: currentLayerConfig ?? (mergeLayerConfig as DatabaseConfig),
      status: null,
      updatesByField: {},
    };

    if (currentLayerConfig == null && mergeLayerConfig != null) {
      change.status = 'deleted';
    }

    if (currentLayerConfig != null && mergeLayerConfig == null) {
      change.status = 'created';
    }

    const mergeSource = this.#getSourceFromConfig(mergeLayerConfig);
    const currentSource = this.#getSourceFromConfig(currentLayerConfig);
    if (!deepEqual(mergeSource, currentSource)) {
      change.updatesByField.source = {
        field: 'Source',
        from: mergeSource,
        to: currentSource,
      };
    }

    if (change.status == null && Object.keys(change.updatesByField).length > 0) {
      change.status = 'updated';
    }

    if (change.status == null) {
      return null;
    }
    return change;
  }

  #handleSpecSchemaChanges(
    currentLayerSpec: BusinessObjectSpec,
    mergeLayerSpec: BusinessObjectSpec | undefined,
    currentLayer: Layer,
    mergeLayer: Layer,
  ) {
    const update: ChangesUpdate<SchemaChange> = {
      field: 'Schema',
      changes: [
        ...this.#handleSpecDimensionalPropertyChanges(
          currentLayerSpec,
          mergeLayerSpec,
          currentLayer,
          mergeLayer,
        ),
        ...this.#handleSpecFieldChanges(currentLayerSpec, mergeLayerSpec, currentLayer, mergeLayer),
        ...this.#handleSpecDriverPropertyChanges(
          currentLayerSpec,
          mergeLayerSpec,
          currentLayer,
          mergeLayer,
        ),
      ],
    };

    if (update.changes.length === 0) {
      return null;
    }
    return update;
  }

  #handleSpecFieldChanges(
    currentLayerSpec: BusinessObjectSpec,
    mergeLayerSpec: BusinessObjectSpec | undefined,
    currentLayer: Layer,
    mergeLayer: Layer,
  ) {
    const changes: FieldColumnChange[] = [];

    const mergeFieldsById = keyBy(mergeLayerSpec?.fields ?? [], ({ id }) => id);
    for (const field of currentLayerSpec.fields) {
      const change: FieldColumnChange = {
        object: { type: 'field', data: field, name: field.name },
        status: null,
        updatesByField: {},
      };

      const mergeField = safeObjGet(mergeFieldsById[field.id]);
      if (mergeField == null) {
        change.status = 'created';
      }

      if (mergeField && mergeField.name !== field.name) {
        change.updatesByField.name = {
          field: 'Column Name',
          from: mergeField?.name,
          to: field.name,
        };
      }

      if (mergeField?.type !== field.type) {
        change.updatesByField.valueType = {
          field: 'Type',
          from: mergeField != null ? { type: 'valueType', value: mergeField.type } : undefined,
          to: { type: 'valueType', value: field.type },
        };
      }

      if (!areFormulasEqual(mergeField?.defaultForecast?.formula, field.defaultForecast.formula)) {
        change.updatesByField.formula = {
          field: 'Formula',
          from: toFormulaChangeData({
            id: mergeField?.id,
            type: 'objectFieldSpec',
            formulaType: 'forecast',
            layerId: mergeLayer.id,
          }),
          to: toFormulaChangeData({
            id: field?.id,
            type: 'objectFieldSpec',
            formulaType: 'forecast',
            layerId: currentLayer.id,
          }),
        };
      }

      const isMergeStartField = mergeLayerSpec?.startFieldId === mergeField?.id;
      const isCurrentStartField = currentLayerSpec?.startFieldId === field?.id;
      if (isMergeStartField !== isCurrentStartField) {
        change.status = 'updated';
        change.updatesByField.isStartField = {
          field: 'Start Field?',
          from: isMergeStartField,
          to: isCurrentStartField,
        };
      }

      const isMergeNumeric = mergeField?.type == null || mergeField?.type === ValueType.Number;
      if (
        isMergeNumeric &&
        field.type === ValueType.Number &&
        mergeField?.numericFormat !== field.numericFormat
      ) {
        change.updatesByField.numericFormat = {
          field: 'Format',
          from:
            mergeField != null
              ? { type: 'numericFormat', value: mergeField?.numericFormat }
              : undefined,
          to: { type: 'numericFormat', value: field.numericFormat },
        };
      }

      if (
        isMergeNumeric &&
        field.type === ValueType.Number &&
        mergeField?.currencyISOCode !== field.currencyISOCode
      ) {
        change.updatesByField.currency = {
          field: 'Currency',
          from:
            mergeField?.currencyISOCode != null
              ? { type: 'currency', value: mergeField.currencyISOCode }
              : undefined,
          to:
            field?.currencyISOCode != null
              ? { type: 'currency', value: field.currencyISOCode }
              : undefined,
        };
      }

      if (
        isMergeNumeric &&
        field.type === ValueType.Number &&
        mergeField?.decimalPlaces !== field.decimalPlaces
      ) {
        change.updatesByField.decimalPlaces = {
          field: 'Precision',
          from:
            mergeField?.decimalPlaces != null
              ? { type: 'decimalPlaces', value: mergeField.decimalPlaces }
              : undefined,
          to:
            field.decimalPlaces != null
              ? { type: 'decimalPlaces', value: field.decimalPlaces }
              : undefined,
        };
      }

      if ((mergeField?.isRestricted ?? false) !== (field.isRestricted ?? false)) {
        change.updatesByField.fieldAnonymization = {
          field: 'Anonymize?',
          from: mergeField?.isRestricted ?? false,
          to: field.isRestricted ?? false,
        };
      }

      if (mergeField?.dimensionId !== field.dimensionId) {
        change.updatesByField.dimension = {
          field: 'Dimension',
          from:
            mergeField?.dimensionId != null
              ? { type: 'dimension', value: mergeLayer.dimensions.byId[mergeField.dimensionId] }
              : null,
          to:
            field.dimensionId != null
              ? {
                  type: 'dimension',
                  // NOTE: right now, dimensions only exist on the main layer, which is implicitly always the merge layer
                  value:
                    currentLayer.dimensions.byId[field.dimensionId] ??
                    mergeLayer.dimensions.byId[field.dimensionId],
                }
              : null,
        };
      }

      if (change.status == null && Object.keys(change.updatesByField).length > 0) {
        change.status = 'updated';
      }

      if (change.status != null) {
        changes.push(change);
      }
    }

    const currentFieldsById = keyBy(currentLayerSpec.fields, ({ id }) => id);
    for (const field of mergeLayerSpec?.fields ?? []) {
      const change: FieldColumnChange = {
        object: { type: 'field', data: field, name: field.name },
        status: null,
        updatesByField: {},
      };

      const currentField = currentFieldsById[field.id];
      if (currentField == null) {
        change.status = 'deleted';
        change.updatesByField.existence = { field: 'Exists?', from: true, to: false };
        changes.push(change);
      }
    }

    return changes;
  }

  #handleSpecDimensionalPropertyChanges(
    currentLayerSpec: BusinessObjectSpec,
    mergeLayerSpec: BusinessObjectSpec | undefined,
    currentLayer: Layer,
    mergeLayer: Layer,
  ) {
    const changes: DimensionalPropertyColumnChange[] = [];

    const mergePropertyById = keyBy(
      mergeLayerSpec?.collection?.dimensionalProperties ?? [],
      ({ id }) => id,
    );
    for (const property of currentLayerSpec.collection?.dimensionalProperties ?? []) {
      const change: DimensionalPropertyColumnChange = {
        object: { type: 'dimensionalProperty', data: property, name: property.name },
        status: null,
        updatesByField: {},
      };

      const mergeProperty = safeObjGet(mergePropertyById[property.id]);
      if (mergeProperty == null) {
        change.status = 'created';
      }

      if (mergeProperty && mergeProperty.name !== property.name) {
        change.updatesByField.name = {
          field: 'Column Name',
          from: mergeProperty?.name,
          to: property.name,
        };
      }

      if (mergeProperty?.isDatabaseKey !== property.isDatabaseKey) {
        change.updatesByField.isKey = {
          field: 'Segment?',
          from: mergeProperty?.isDatabaseKey,
          to: property.isDatabaseKey ?? false,
        };
      }

      if (mergeProperty?.dimension !== property.dimension) {
        change.updatesByField.dimension = {
          field: 'Dimension',
          from:
            mergeProperty?.dimension != null
              ? { type: 'dimension', value: mergeLayer.dimensions.byId[mergeProperty.dimension.id] }
              : undefined,
          to: {
            type: 'dimension', // NOTE: right now, dimensions only exist on the main layer, which is implicitly always the merge layer
            value:
              currentLayer.dimensions.byId[property.dimension.id] ??
              mergeLayer.dimensions.byId[property.dimension.id],
          },
        };
      }

      if (change.status == null && Object.keys(change.updatesByField).length > 0) {
        change.status = 'updated';
      }

      if (change.status != null) {
        changes.push(change);
      }
    }

    const currentPropertyById = keyBy(
      currentLayerSpec.collection?.dimensionalProperties ?? [],
      ({ id }) => id,
    );
    for (const property of mergeLayerSpec?.collection?.dimensionalProperties ?? []) {
      const change: DimensionalPropertyColumnChange = {
        object: { type: 'dimensionalProperty', data: property, name: property.name },
        status: null,
        updatesByField: {},
      };

      const currentProperty = currentPropertyById[property.id];
      if (currentProperty == null) {
        change.status = 'deleted';
        change.updatesByField.existence = { field: 'Exists?', from: true, to: false };
        changes.push(change);
      }
    }

    return changes;
  }

  #handleSpecDriverPropertyChanges(
    currentLayerSpec: BusinessObjectSpec,
    mergeLayerSpec: BusinessObjectSpec | undefined,
    currentLayer: Layer,
    mergeLayer: Layer,
  ) {
    const changes: DriverPropertyColumnChange[] = [];

    const mergePropertyById = keyBy(
      mergeLayerSpec?.collection?.driverProperties ?? [],
      ({ id }) => id,
    );
    for (const property of currentLayerSpec.collection?.driverProperties ?? []) {
      const mergeProperty = safeObjGet(mergePropertyById[property.id]);
      const mergeDriver = safeObjGet(mergeLayer.drivers.byId[mergeProperty?.driverId ?? '']);
      const currentDriver = safeObjGet(currentLayer.drivers.byId[property.driverId ?? '']);

      const change: DriverPropertyColumnChange = {
        object: {
          type: 'driverProperty',
          data: property,
          name: currentDriver?.name ?? mergeDriver?.name ?? 'Unknown Driver Property',
        },
        status: null,
        updatesByField: {},
      };
      if (mergeProperty == null) {
        change.status = 'created';
      }

      if (mergeProperty?.driverId !== property.driverId) {
        change.updatesByField.name = {
          field: 'Driver',
          from: mergeDriver?.name,
          to: currentDriver?.name,
        };
      } else if (mergeDriver && currentDriver && mergeDriver.name !== currentDriver.name) {
        change.updatesByField.name = {
          field: 'Column Name',
          from: mergeDriver?.name,
          to: currentDriver?.name,
        };
      }

      if (mergeDriver?.format !== currentDriver?.format) {
        change.updatesByField.numericFormat = {
          field: 'Format',
          from:
            mergeDriver?.format != null
              ? { type: 'numericFormat', value: mergeDriver?.format }
              : undefined,
          to:
            currentDriver?.format != null
              ? { type: 'numericFormat', value: currentDriver?.format }
              : undefined,
        };
      }

      if (mergeDriver?.currencyISOCode !== currentDriver?.currencyISOCode) {
        change.updatesByField.currency = {
          field: 'Currency',
          from:
            mergeDriver?.currencyISOCode != null
              ? { type: 'currency', value: mergeDriver.currencyISOCode }
              : undefined,
          to:
            currentDriver?.currencyISOCode != null
              ? { type: 'currency', value: currentDriver.currencyISOCode }
              : undefined,
        };
      }

      if (mergeDriver?.decimalPlaces !== currentDriver?.decimalPlaces) {
        change.updatesByField.decimalPlaces = {
          field: 'Precision',
          from:
            mergeDriver?.decimalPlaces != null
              ? { type: 'decimalPlaces', value: mergeDriver.decimalPlaces }
              : undefined,
          to:
            currentDriver?.decimalPlaces != null
              ? { type: 'decimalPlaces', value: currentDriver.decimalPlaces }
              : undefined,
        };
      }

      if (
        (mergeDriver == null || mergeDriver.type === DriverType.Dimensional) &&
        (currentDriver == null || currentDriver.type === DriverType.Dimensional)
      ) {
        if (
          !areFormulasEqual(
            mergeDriver?.defaultForecast?.formula,
            currentDriver?.defaultForecast?.formula,
          )
        ) {
          change.updatesByField.defaultForecastFormula = {
            field: 'Default Forecast Formula',
            from: toFormulaChangeData({
              id: mergeDriver?.id,
              type: 'driver',
              formulaType: 'forecast',
              layerId: mergeLayer.id,
            }),
            to: toFormulaChangeData({
              id: currentDriver?.id,
              type: 'driver',
              formulaType: 'forecast',
              layerId: currentLayer.id,
            }),
          };
        }
        if (
          !areFormulasEqual(
            mergeDriver?.defaultActuals?.formula,
            currentDriver?.defaultActuals?.formula,
          )
        ) {
          change.updatesByField.defaultActualsFormula = {
            field: 'Default Actuals Formula',
            from: toFormulaChangeData({
              id: mergeDriver?.id,
              type: 'driver',
              formulaType: 'actuals',
              layerId: mergeLayer.id,
            }),
            to: toFormulaChangeData({
              id: currentDriver?.id,
              type: 'driver',
              formulaType: 'actuals',
              layerId: currentLayer.id,
            }),
          };
        }
      }

      if (change.status == null && Object.keys(change.updatesByField).length > 0) {
        change.status = 'updated';
      }

      if (change.status != null) {
        changes.push(change);
      }
    }

    const currentPropertyById = keyBy(
      currentLayerSpec.collection?.driverProperties ?? [],
      ({ id }) => id,
    );
    for (const property of mergeLayerSpec?.collection?.driverProperties ?? []) {
      const mergeDriver = safeObjGet(mergeLayer.drivers.byId[property.driverId ?? '']);

      const change: DriverPropertyColumnChange = {
        object: {
          type: 'driverProperty',
          data: property,
          name: mergeDriver?.name ?? 'Unknown Driver Property',
        },
        status: null,
        updatesByField: {},
      };

      const currentProperty = currentPropertyById[property.id];
      if (currentProperty == null) {
        change.status = 'deleted';
        change.updatesByField.existence = { field: 'Exists?', from: true, to: false };
        changes.push(change);
      }
    }

    return changes;
  }

  #handleBusinessObjectFieldChanges(
    spec: BusinessObjectSpec | null,
    currentLayerBusinessObject: BusinessObject,
    mergeLayerBusinessObject: BusinessObject,
  ) {
    const update: ChangesUpdate<BusinessObjectFieldChange> = { field: 'Fields', changes: [] };
    const mergeFieldsByID = keyBy(mergeLayerBusinessObject.fields, ({ id }) => id);
    for (const field of currentLayerBusinessObject.fields) {
      const mergeField = safeObjGet(mergeFieldsByID[field.id]);

      const change: BusinessObjectFieldChange = {
        object: { field, isStartField: spec?.startFieldId === field.fieldSpecId },
        status: null,
        updatesByField: {},
      };
      if (mergeField?.value?.initialValue !== field.value?.initialValue) {
        change.status = 'updated';
        change.updatesByField.update = {
          field: 'Initial Value',
          from: mergeField?.value?.actuals?.formula ?? null,
          to: field.value?.actuals?.formula ?? null,
        };
      }

      if (mergeField?.value?.actuals?.formula !== field.value?.actuals?.formula) {
        change.status = 'updated';
        change.updatesByField.update = {
          field: 'Actuals Formula',
          from: mergeField?.value?.actuals?.formula ?? null,
          to: field.value?.actuals?.formula ?? null,
        };
      }

      if (!deepEqual(mergeField?.value?.actuals.timeSeries, field.value?.actuals?.timeSeries)) {
        change.status = 'updated';
        change.updatesByField.update = {
          field: 'Actuals Time Series',
          from: mergeField?.value?.actuals?.timeSeries ?? null,
          to: field.value?.actuals?.timeSeries ?? null,
        };
      }

      if (change.status != null) {
        update.changes.push(change);
      }
    }

    if (update.changes.length === 0) {
      return null;
    }
    return update;
  }

  #getSourceFromConfig(config: DatabaseConfig | undefined): Source | null {
    if (config == null) {
      return null;
    }

    if (config.source === DatabaseConfigSourceType.Database) {
      return { type: config.source, id: config.database?.sourceBusinessObjectSpecId ?? '' };
    } else if (config.source === DatabaseConfigSourceType.ExtTable) {
      return { type: config.source, id: config.extTable?.extTableSourceKey ?? '' };
    }
    throw new Error(`Unknown source type "${config.source}"`);
  }
}

export type DatabaseChange = Change<BusinessObjectSpec, Fields>;

type Fields = {
  name: TransitionUpdate;
  config: ChangeUpdate<ConfigChange>;
  schema: ChangesUpdate<SchemaChange>;
  businessObjects: ChangesUpdate<BusinessObjectChange>;
};

export type Source = {
  type: DatabaseConfigSourceType;
  id: string;
};

export type ConfigChange = Change<
  DatabaseConfig,
  {
    source: TransitionUpdate<Source>;
  }
>;

export type SchemaChange =
  | FieldColumnChange
  | DimensionalPropertyColumnChange
  | DriverPropertyColumnChange;

type ColumnType<TypeName, ObjectType> = { type: TypeName; name: string; data: ObjectType };
type FieldColumn = ColumnType<'field', BusinessObjectFieldSpec>;
type DimensionalPropertyColumn = ColumnType<'dimensionalProperty', DimensionalProperty>;
type DriverPropertyColumn = ColumnType<'driverProperty', DriverProperty>;

type FieldColumnChange = Change<
  FieldColumn,
  {
    name: TransitionUpdate;
    formula: TransitionUpdate<Formula>;
    currency: TransitionUpdate<Currency>;
    decimalPlaces: TransitionUpdate<DecimalPlaces>;
    numericFormat: TransitionUpdate<NumericFormat>;
    fieldAnonymization: TransitionUpdate<boolean>;
    dimension: TransitionUpdate<ColumnDimension>;
    valueType: TransitionUpdate<FieldType>;
    isStartField: TransitionUpdate<boolean>;
    existence: TransitionUpdate<boolean>;
  }
>;

type DimensionalPropertyColumnChange = Change<
  DimensionalPropertyColumn,
  {
    name: TransitionUpdate;
    isKey: TransitionUpdate<boolean>;
    dimension: TransitionUpdate<ColumnDimension>;
    existence: TransitionUpdate<boolean>;
    // TODO: permissions are not layerized, so this cannot yet be done
    // anonymization: TransitionUpdate<boolean>;
  }
>;

type DriverPropertyColumnChange = Change<
  DriverPropertyColumn,
  {
    name: TransitionUpdate;
    existence: TransitionUpdate<boolean>;
    currency: TransitionUpdate<Currency>;
    decimalPlaces: TransitionUpdate<DecimalPlaces>;
    numericFormat: TransitionUpdate<NumericFormat>;
    defaultForecastFormula: TransitionUpdate<Formula>;
    defaultActualsFormula: TransitionUpdate<Formula>;
    // TODO: permissions are not layerized, so this cannot yet be done
    // anonymization: TransitionUpdate<boolean>;
  }
>;

export type BusinessObjectChange = Change<
  BusinessObject,
  {
    name: TransitionUpdate;
    attributes: TouchUpdate;
    fields: ChangesUpdate<BusinessObjectFieldChange>;
  }
>;

type BusinessObjectFieldChange = Change<
  { field: BusinessObjectField; isStartField: boolean },
  {
    update: TransitionUpdate<string | ValueTimeSeries>;
  }
>;
