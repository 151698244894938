import { Box, BoxProps } from '@chakra-ui/react';
import { ConnectDragSource } from 'react-dnd';

import ReorderMarker from 'components/ReorderMarker/ReorderMarker';

interface Props {
  startDropRef: ConnectDragSource;
  endDropRef: ConnectDragSource;
  isLast: boolean;
  isOverStart: boolean;
  isOverEnd: boolean;
  leftOffset?: BoxProps['left'];
  orientation: 'vertical' | 'horizontal';
}

const DropTargets: React.FC<Props> = ({
  isLast,
  startDropRef,
  endDropRef,
  orientation,
  isOverStart,
  isOverEnd,
  leftOffset,
}) => (
  <>
    {isOverStart && !isOverEnd && (
      <ReorderMarker
        leftOffset={leftOffset}
        position={orientation === 'vertical' ? 'top' : 'left'}
      />
    )}
    {isOverEnd && (
      <ReorderMarker
        leftOffset={leftOffset}
        position={orientation === 'vertical' ? 'bottom' : 'right'}
      />
    )}
    <Box
      ref={startDropRef}
      position="absolute"
      zIndex="overlay"
      top={0}
      left={0}
      height={orientation === 'vertical' ? 'full' : undefined}
      width={orientation === 'horizontal' ? 'full' : undefined}
      bottom={orientation === 'vertical' ? '50%' : 0}
      right={orientation === 'horizontal' ? '50%' : 0}
      data-testid="start-drop-target"
    />
    {isLast && (
      <Box
        ref={endDropRef}
        position="absolute"
        zIndex="overlay"
        right={0}
        bottom={0}
        top={orientation === 'vertical' ? '50%' : 0}
        left={orientation === 'horizontal' ? '50%' : 0}
        data-testid="end-drop-target"
      />
    )}
  </>
);

export default DropTargets;
