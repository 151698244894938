import { CELL_DATA_COLUMN_WIDTH_IN_PX } from 'config/cells';
import { MonthKey } from 'types/datetime';

export type ExtDriverColumnType = 'name' | 'query' | 'linkedDrivers' | 'data';
export const STICKY_COLUMN_TYPES: ExtDriverColumnType[] = ['name', 'query', 'linkedDrivers'];

export type ExtDriverColumn = {
  type: ExtDriverColumnType;
  monthKey?: MonthKey;
  isSticky: boolean;
  isLastSticky: boolean;
  width: number;
};

export const COLUMN_TYPE_TO_DEFAULT_WIDTH: Record<ExtDriverColumnType, number> = {
  name: 300,
  query: 300,
  linkedDrivers: 200,
  data: CELL_DATA_COLUMN_WIDTH_IN_PX,
};
