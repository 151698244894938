export function isPrintableChar(char: string) {
  return Array.from(char).length === 1;
}

export function emptyToUndefined(str: string | undefined) {
  if (str == null) {
    return undefined;
  }
  const trimmed = str.trim();
  if (trimmed.length === 0) {
    return undefined;
  }
  return trimmed;
}
