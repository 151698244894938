import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'CurrentValueChart',
  viewBox: '0 0 13 12',
  defaultProps: {
    fill: '#18A0FB',
    boxSize: '14px',
  },
  d: 'M1.42489 0C0.822166 0 0.333984 0.488182 0.333984 1.09091V10.9091C0.333984 11.5118 0.822166 12 1.42489 12H11.2431C11.8458 12 12.334 11.5118 12.334 10.9091V1.09091C12.334 0.488182 11.8458 0 11.2431 0H1.42489ZM4.69762 2.18182C4.99926 2.18182 5.24308 2.42564 5.24308 2.72727V3.81818H7.42489V2.72727C7.42489 2.42564 7.66871 2.18182 7.97035 2.18182C8.27198 2.18182 8.5158 2.42564 8.5158 2.72727V3.81818H9.60671C9.90835 3.81818 10.1522 4.062 10.1522 4.36364C10.1522 4.66527 9.90835 4.90909 9.60671 4.90909H8.5158V7.09091H9.60671C9.90835 7.09091 10.1522 7.33473 10.1522 7.63636C10.1522 7.938 9.90835 8.18182 9.60671 8.18182H8.5158V9.27273C8.5158 9.57436 8.27198 9.81818 7.97035 9.81818C7.66871 9.81818 7.42489 9.57436 7.42489 9.27273V8.18182H5.24308V9.27273C5.24308 9.57436 4.99926 9.81818 4.69762 9.81818C4.39598 9.81818 4.15217 9.57436 4.15217 9.27273V8.18182H3.06126C2.75962 8.18182 2.5158 7.938 2.5158 7.63636C2.5158 7.33473 2.75962 7.09091 3.06126 7.09091H4.15217V4.90909H3.06126C2.75962 4.90909 2.5158 4.66527 2.5158 4.36364C2.5158 4.062 2.75962 3.81818 3.06126 3.81818H4.15217V2.72727C4.15217 2.42564 4.39598 2.18182 4.69762 2.18182ZM5.24308 4.90909V7.09091H7.42489V4.90909H5.24308Z',
});
