import { Box, Flex, ListItem, Text, useMergeRefs } from '@chakra-ui/react';
import isString from 'lodash/isString';
import React from 'react';

import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { SelectMenuItemProps } from 'components/SelectMenu/SelectMenuItem';
import { useSelectMenuItem } from 'hooks/useSelectMenuItem';

interface Props {
  item: SelectItem;
  idx: number;
  isFocused: boolean;
}

const AttributesForDriverSelectMenuItem: React.FC<Props> = ({ item, idx, isFocused }) => {
  return (
    <AttributesMenuItem
      key={item.key ?? item.id}
      idx={idx}
      name={item.name}
      meta={item.meta}
      icon={item.icon}
      isFocused={isFocused}
    />
  );
};

const AttributesMenuItem = React.forwardRef<HTMLLIElement, SelectMenuItemProps>(
  ({ idx, name, isFocused = false, isDisabled = false, meta, icon }, ref) => {
    const { itemRef, onMouseDown, onMouseLeave, onMouseEnter } = useSelectMenuItem({
      isFocused,
      idx,
      showSubmenuOnHover: false,
    });
    const mergedRef = useMergeRefs(ref, itemRef);

    return (
      <Flex pointerEvents={isDisabled ? 'none' : undefined} opacity={isDisabled ? 0.5 : 1} w="full">
        <ListItem
          data-testid="select-menu-list-item"
          ref={mergedRef}
          aria-selected={isFocused}
          lineHeight="1.375rem"
          columnGap={2}
          w="full"
        >
          {icon}
          <Flex justifyContent="space-between" alignItems="center" columnGap={1} flex={1}>
            {isString(name) ? <Text noOfLines={1}>{name}</Text> : name}
          </Flex>
          <Flex flexShrink={0} minWidth={0} alignItems="center">
            {meta != null && (
              <Box
                onMouseDown={onMouseDown}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
              >
                {isString(meta) ? (
                  <Text fontSize="xs" flexShrink={0}>
                    {meta}
                  </Text>
                ) : (
                  meta
                )}
              </Box>
            )}
          </Flex>
        </ListItem>
      </Flex>
    );
  },
);

export default AttributesForDriverSelectMenuItem;
