import { Flex, Spacer, Text } from '@chakra-ui/react';
import React from 'react';

import AddAdditionalScenarioButton from 'components/CompareScenariosModalContent/AddAdditionalScenarioButton';
import LayerPill from 'components/CompareScenariosModalContent/LayerPill';
import MergeChangesButton from 'components/CompareScenariosModalContent/MergeChangesButton';
import CompareToLayerDropdown from 'components/CompareToLayerDropdown/CompareToLayerDropdown';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  removeComparisonLayer,
  replaceComparisonLayer,
} from 'reduxStore/actions/scenarioComparison';
import { LayerId } from 'reduxStore/models/layers';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import {
  additionalLayerIdsToCompareOptionsSelector,
  scenarioComparisonBaselineLayerIdSelector,
  uniqueAdditionalLayerIdsToCompareSelector,
} from 'selectors/scenarioComparisonSelector';

const CompareScenariosHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const baselineLayerId = useAppSelector(scenarioComparisonBaselineLayerIdSelector);
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const currentLayerIsBaseline = currentLayerId === baselineLayerId;
  const additionalLayerIdsToCompare = useAppSelector(uniqueAdditionalLayerIdsToCompareSelector);
  const numLayersToCompare = currentLayerIsBaseline
    ? additionalLayerIdsToCompare.length
    : additionalLayerIdsToCompare.length + 1;
  const layerIds = useAppSelector(additionalLayerIdsToCompareOptionsSelector);
  return (
    <Flex w="full" pr={8}>
      <Flex alignItems="center" fontSize="xxs" color="gray.600" columnGap={2}>
        Comparing
        <LayerPill layerId={baselineLayerId} enableIconBgColor />
        {numLayersToCompare > 0 && <Text>to</Text>}
        {!currentLayerIsBaseline && (
          <>
            <LayerPill layerId={currentLayerId} enableIconBgColor />
            {additionalLayerIdsToCompare.length > 0 && <Text>and</Text>}
          </>
        )}
        {additionalLayerIdsToCompare.map((layerId, index) => (
          <Flex key={layerId} alignItems="center" columnGap={2}>
            {index !== 0 && <Text>and</Text>}
            <CompareToLayerDropdown
              key={layerId}
              layerIds={layerIds}
              selectedLayerId={layerId}
              onChange={(selectedLayerId: LayerId) =>
                dispatch(replaceComparisonLayer({ layerId, newLayerId: selectedLayerId }))
              }
              onRemove={() => dispatch(removeComparisonLayer(layerId))}
            />
          </Flex>
        ))}
        <AddAdditionalScenarioButton />
      </Flex>
      <Spacer />
      <Flex>
        <MergeChangesButton />
      </Flex>
    </Flex>
  );
};

export default CompareScenariosHeader;
