import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const lightButton = {
  bgColor: 'surface',
  borderColor: 'gray.300',
  borderRadius: 'md',
  borderWidth: 'px',
  boxShadow: 'button',
  px: 2,
  py: 2,
  _hover: {
    bgColor: 'gray.200',
  },
  _focusVisible: {
    boxShadow: 'none',
    outlineColor: 'selection.500',
  },
  _active: {
    boxShadow: 'button',
  },
};

const textButton = {
  bgColor: 'surface',
  _hover: {
    boxShadow: 'unset',
    bg: 'gray.200',
    borderRadius: 'md',
  },
  _focusVisible: {
    boxShadow: 'unset',
    border: 'transparent',
  },
  _active: {
    bg: 'gray.300',
  },
  _selected: {
    bgColor: 'selection.500',
    borderRadius: 'md',
    color: 'surface',
  },
  margin: 0,
  borderRadius: 0,
  height: 'unset',
  width: 'unset',
  border: 'none',
  minWidth: 'unset',
  lineHeight: 1,
  fontSize: 'unset',
};

export default {
  baseStyle: {
    borderRadius: 'md',
    borderStyle: 'solid',
    borderWidth: '2px',
    flexShrink: 0,
    fontSize: 'sm',
    fontWeight: 'medium',
    lineHeight: '1.5rem',
    outlineOffset: 0,
    px: 2,
    py: 2,
    transition: 'all .15s, visibility 0',
    _active: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focusVisible: {
      outlineColor: 'selection.500',
      outlineOffset: 0,
      boxShadow: 'none',
    },
  },
  defaultProps: {
    variant: 'primary' as unknown,
  },
  sizes: {
    sm: {
      height: '1.75rem',
      fontSize: 'xs',
      fontWeight: 'medium',
    },
    md: {
      height: '2.25rem',
      fontSize: 'xs',
      fontWeight: 'medium',
    },
  },
  variants: {
    text: textButton,
    icon: {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      color: 'gray.600',
      height: 'unset',
      lineHeight: 'unset',
      minHeight: 'unset',
      minWidth: 'unset',
      px: 0,
      _hover: {
        bgColor: 'gray.100',
        border: 'none',
        boxShadow: 'none',
      },
    },
    iconText: {
      border: 'none',
      borderRadius: 'md',
      boxShadow: 'none',
      color: 'gray.500',
      fontWeight: 'medium',
      height: 'unset',
      justifyContent: 'flex-start',
      lineHeight: 1,
      px: 1,
      py: 1,
      _hover: {
        bgColor: 'gray.200',
        color: 'gray.500',
      },
    },
    light: lightButton,
    accent: {
      ...lightButton,
      bgColor: 'selection.200',
      color: 'selection.500',
      border: 'none',
      _hover: {
        ...lightButton._hover,
        bgColor: 'selection.500',
        color: 'surface',
      },
      _focusVisible: {
        ...lightButton._focusVisible,
        color: 'surface',
      },
      _active: {
        ...lightButton._active,
        color: 'surface',
      },
    },
    navText: {
      borderWidth: 0,
      color: 'gray.600',
      fontSize: 'sm',
      fontWeight: 'medium',
      height: 'unset',
      lineHeight: '1.125rem',
      _hover: {
        opacity: 0.6,
        boxShadow: 'none',
      },
    },
    primary: {
      bg: 'surface',
      borderColor: 'gray.300',
      borderRadius: 'md',
      borderWidth: 'px',
      boxShadow: 'primaryButton',
      color: 'gray.600',
      fontSize: 'sm',
      _hover: {
        bg: ' gray.200',
      },
    },
    list: {
      ...textButton,
      color: 'gray.400',
      fontSize: 'xs',
      fontWeight: 'semibold',
      justifyContent: 'flex-start',
      p: 2,
      width: 'full',
    },
    coached: {
      bg: 'selection.200',
      border: 'none',
      boxShadow: 'primaryButton',
      color: 'selection.500',
      _hover: {
        bg: 'selection.500',
        color: 'surface',
      },
      _active: {
        bg: 'selection.500',
        color: 'surface',
      },
      _disabled: {
        bg: 'selection.200',
        color: 'selection.400',
        pointerEvents: 'none',
        opacity: 1,
      },
    },
    error: {
      borderColor: 'failure',
      bg: 'failure',
      color: 'surface',
      _hover: {
        bg: 'red.500',
        borderColor: 'red.500',
      },
      _active: {
        bg: 'red.500',
        borderColor: 'red.500',
      },
      _disabled: {
        bg: 'gray.300',
        borderColor: 'gray.300',
      },
    },
    orangePrimary: {
      borderColor: 'yellow.500',
      bg: 'yellow.500',
      color: 'surface',
      _hover: {
        bg: 'yellow.600',
        borderColor: 'yellow.600',
      },
      _active: {
        bg: 'yellow.500',
        borderColor: 'yellow.500',
      },
      _disabled: {
        bg: 'gray.300',
        borderColor: 'gray.300',
      },
    },
    marketing: {
      color: 'runway.surface',
      bg: 'rgba(180, 175, 175, 0.1)',
      fontFamily: 'marketing',
      fontWeight: 'semibold',
      fontSize: 'sm',
      lineHeight: 6,
      _hover: {
        bg: 'rgba(180, 175, 175, 0.3)',
      },
      _active: {
        bg: 'rgba(180, 175, 175, 0.2)',
      },
      rounded: 16,
      borderWidth: 0,
      height: 'unset',
      display: 'flex',
      px: 5,
      py: 4,
      iconSpacing: 4,
      surfaceSpace: 'normal',
    },
  },
} as ComponentSingleStyleConfig;
