import { sortBy } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo } from 'react';

import DriverGroupSelect from 'components/DriverBlockSelect/DriverGroupSelect';
import { SECTIONS_BY_ID } from 'components/DriverBlockSelect/constants';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { DEFAULT_DRIVER_GROUP_NAME } from 'config/driverGroups';
import { isNotNull } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { DriverId } from 'reduxStore/models/drivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { sortedAccessibleBasicDriverIdsByGroupBySubmodelIdSelector } from 'selectors/accessibleDriversSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';

type SubmodelDriverSelectItem = SelectItem & ({ type: 'selectAll' } | { type: 'driverGroup' });

const SECTIONS: Section[] = [
  {
    id: 'selectAll',
  },
  {
    ...SECTIONS_BY_ID.driverGroup,
    maxResults: 10,
    showMore: true,
  },
];

interface Props {
  submodelId: SubmodelId;
  addDrivers: (driverIds: DriverId[]) => void;
  onClose: () => void;
}

const SubmodelDriverSelect: React.FC<Props> = ({ submodelId, addDrivers, onClose }) => {
  const driverGroupsById = useAppSelector(driverGroupsByIdSelector);
  const groupDriversBySubmodelId = useAppSelector(
    sortedAccessibleBasicDriverIdsByGroupBySubmodelIdSelector,
  );
  const allDriverIds = useMemo<DriverId[]>(
    () => (groupDriversBySubmodelId[submodelId] ?? []).flatMap(({ driverIds }) => driverIds),
    [groupDriversBySubmodelId, submodelId],
  );
  const items = useMemo<SubmodelDriverSelectItem[]>(() => {
    const groupItems = (groupDriversBySubmodelId[submodelId] ?? [])
      .map(({ groupId, driverIds }) => {
        const numDrivers = driverIds.length;
        if (numDrivers === 0) {
          return null;
        }
        const driverGroup = groupId != null ? driverGroupsById[groupId] : null;
        return {
          id: groupId ?? 'none',
          type: 'driverGroup' as const,
          name: driverGroup?.name ?? DEFAULT_DRIVER_GROUP_NAME,
          sectionId: 'driverGroup',
          meta: `${numDrivers} ${pluralize('driver', numDrivers)}`,
          submenu: () => (
            <DriverGroupSelect driverIds={driverIds} addDrivers={addDrivers} onClose={onClose} />
          ),
        };
      })
      .filter(isNotNull);

    return [
      {
        id: 'selectAll',
        type: 'selectAll',
        sectionId: 'selectAll',
        name: 'Add all drivers',
      },
      ...sortBy(groupItems, 'name'),
    ];
  }, [groupDriversBySubmodelId, submodelId, driverGroupsById, addDrivers, onClose]);

  const onSelect = useCallback(
    (item: SubmodelDriverSelectItem) => {
      switch (item.type) {
        case 'selectAll': {
          addDrivers(allDriverIds);
          break;
        }
        default: {
          break;
        }
      }
    },
    [addDrivers, allDriverIds],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu items={items} onSelect={onSelect} sections={SECTIONS} width="20rem">
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default SubmodelDriverSelect;
