import { createSelector } from 'reselect';

import { datasetSelector } from 'selectors/datasetSelector';

export const previousMutationIdSelector = createSelector(
  datasetSelector,
  (dataset) => dataset.previousMutationId,
);

export const confirmedPreviousMutationIdSelector = createSelector(
  datasetSelector,
  (dataset) => dataset.confirmedPreviousMutationId,
);
