import { Flex, Spinner, useBoolean } from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import { BlockViewOptionsInput, ChartDisplayInput } from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { updateBlockViewOptions } from 'reduxStore/actions/blockMutations';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';

const AgChartBackfillSeriesConfig = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const driversById = useAppSelector(driversByIdForLayerSelector);
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  const [migrated, { on: setMigrated }] = useBoolean(false);

  useEffect(() => {
    if (
      migrated ||
      !viewOptions.chartDisplay ||
      !viewOptions.chartDisplay.series.some((s) => isEmpty(s.driverId))
    ) {
      return;
    }

    // During early iteration, the series ID was a driver ID.
    // It is now a random UUID and the driverId field now exists on ChartSeries.
    const drivers = viewOptions.chartDisplay.series
      .map(({ id }) => driversById[id])
      .filter(isNotNull);

    // Warn because the mismatch in driver count will probably drop a driver from the chart.
    if (drivers.length !== viewOptions.chartDisplay.series.length) {
      Sentry.withScope((scope) => {
        scope.setLevel('warning');
        scope.setExtras({
          blockId,
          chartDisplay: viewOptions.chartDisplay,
          missingDrivers: Math.abs(drivers.length - (viewOptions.chartDisplay?.series.length ?? 0)),
        });
        Sentry.captureException('Unable to migrate driver chart block');
      });
    }

    const chartDisplay: ChartDisplayInput = {
      ...viewOptions.chartDisplay,
    };
    const remappedIds: Record<string, string> = {};

    chartDisplay.series = chartDisplay.series
      .map((s) => {
        const driver = driversById[s.id];
        if (driver == null) {
          return null;
        }

        const id = uuidv4();
        remappedIds[s.id] = id;
        return {
          ...s,
          id,
          driverId: s.id,
        };
      })
      .filter(isNotNull);
    chartDisplay.groups = chartDisplay.groups.map((g) => ({
      ...g,
      seriesIds: g.seriesIds.map((driverId) => remappedIds[driverId]).filter(isNotNull),
    }));

    const blockViewOptions: BlockViewOptionsInput = {
      ...viewOptions,
      chartDisplay,
    };

    setMigrated();
    dispatch(
      updateBlockViewOptions({
        blockId,
        blockViewOptions,
      }),
    );
  }, [
    blockId,
    dispatch,
    driversById,
    migrated,
    setMigrated,
    viewOptions,
    viewOptions.chartDisplay,
  ]);

  return (
    <Flex>
      <Spinner />
    </Flex>
  );
};

export default AgChartBackfillSeriesConfig;
