import { createSelector } from 'reselect';

import { AiEventGroupInput, AiEventInput, AiModelInput } from 'generated/graphql';
import { eventToAiEventInput } from 'helpers/events';
import { SubmodelId } from 'reduxStore/models/submodels';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { businessObjectsByFieldIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { attributesByIdSelector } from 'selectors/dimensionsSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import {
  allBasicDriversSelector,
  basicDriverResolvedNamesByIdSelector,
} from 'selectors/driversSelector';
import { populatedEventGroupsWithoutLiveEditsByIdForLayerSelector } from 'selectors/eventsAndGroupsSelector';
import {
  submodelIdByBlockIdSelector,
  submodelNamesByIdSelector,
} from 'selectors/submodelPageSelector';

export const aiGroupDriversContextSelector = createSelector(
  allBasicDriversSelector,
  basicDriverResolvedNamesByIdSelector,
  submodelNamesByIdSelector,
  submodelIdByBlockIdSelector,
  driverGroupsByIdSelector,
  (drivers, resolvedNamesById, pageNamesBySubmodelId, submodelIdByBlockId, driverGroups) => {
    const models: Record<SubmodelId, AiModelInput> = {};

    drivers.forEach((driver) => {
      driver.driverReferences?.forEach(({ blockId, groupId }) => {
        if (groupId == null) {
          return;
        }

        const submodelId = submodelIdByBlockId[blockId];
        if (submodelId == null) {
          return;
        }
        const submodelName = pageNamesBySubmodelId[submodelId];
        const groupName = driverGroups[groupId]?.name;
        if (submodelName == null || groupName == null) {
          return;
        }

        const model = models[submodelId] ?? {
          name: submodelName,
          groups: [],
        };
        models[submodelId] = model;

        let group = model.groups.find((g) => g.name === groupName);
        if (group == null) {
          group = {
            name: groupName,
            drivers: [],
          };
          model.groups.push(group);
        }

        const id = driver.id;
        group.drivers.push({ id, name: resolvedNamesById[id] });
      });
    });

    return Object.values(models);
  },
);

export const aiGroupEventsContextSelector = createSelector(
  populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
  basicDriverResolvedNamesByIdSelector,
  businessObjectsByFieldIdForLayerSelector,
  businessObjectFieldSpecByIdSelector,
  businessObjectSpecsByIdForLayerSelector,
  attributesByIdSelector,
  (
    populatedEventGroups,
    resolvedDriverNamesById,
    businessObjectsByFieldId,
    businessObjectFieldSpecById,
    businessObjectSpecsById,
    attributesById,
    // eslint-disable-next-line max-params
  ) => {
    const eventGroups: AiEventGroupInput[] = [];
    for (const group of Object.values(populatedEventGroups)) {
      if (group == null) {
        continue;
      }

      const events: AiEventInput[] = [];
      for (const event of group.events) {
        if (event == null) {
          continue;
        }

        const input = eventToAiEventInput(event, {
          businessObjectSpecsById,
          resolvedDriverNamesById,
          businessObjectsByFieldId,
          businessObjectFieldSpecById,
          attributesById,
        });
        if (input?.driver == null && input?.object == null) {
          continue;
        }

        events.push(input);
      }

      eventGroups.push({
        name: group.name,
        events,
      });
    }

    return eventGroups;
  },
);
