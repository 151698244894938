import React from 'react';

import DriverPropertyTableCellLight from 'components/DriverPropertyTableCell/DriverPropertyTableCellLight';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';

interface Props {
  title: string;
  columnType: ModelViewColumnType;
  columnLayerId: ColumnLayerId;
  children: React.ReactNode;
}

const PropertyPickerLight = ({ children, columnType, columnLayerId }: Props) => {
  return (
    <DriverPropertyTableCellLight columnType={columnType} columnLayerId={columnLayerId} isSticky>
      {children}
    </DriverPropertyTableCellLight>
  );
};

export default PropertyPickerLight;
