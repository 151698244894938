import mergeWith from 'lodash/mergeWith';

import { DatasetMutationInput } from 'generated/graphql';

function backfillCustomizer(
  objValue: DatasetMutationInput[keyof DatasetMutationInput],
  srcValue: DatasetMutationInput[keyof DatasetMutationInput],
) {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    return [...objValue, ...srcValue];
  }
  // When undefined is returned, the default merge behavior takes over
  return undefined;
}

export const mergeMutations = <T extends DatasetMutationInput, U extends DatasetMutationInput>(
  originalMutation: T,
  backfillMutation: U | undefined,
): T | (T & U) => {
  if (backfillMutation == null) {
    return originalMutation;
  }

  const merged = { ...originalMutation };
  return mergeWith(merged, backfillMutation, backfillCustomizer);
};

export const mergeAllMutations = (mutations: DatasetMutationInput[]): DatasetMutationInput => {
  return mutations.reduce((prev, mutation) => {
    return mergeWith(prev, mutation, backfillCustomizer);
  }, {});
};
