import { Text } from '@chakra-ui/react';
import { CustomTooltipProps } from 'ag-grid-react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import ExtSourceTooltip from 'components/BusinessObjectTable/ExtSourceTooltip';
import { CalcErrTooltipContent } from 'components/CalcErrTooltip/CalcErrTooltip';
import { CellValueTooltipData } from 'config/cells';
import useOnClickOutside from 'hooks/useOnClickOutside';

import styles from './Tooltip.module.scss';

const TOOLTIP_TYPE_TO_INTERACTIVE: Record<CellValueTooltipData['content']['type'], boolean> = {
  driver_calc_error: true,
  ext_source: false,
  autofilled: false,
  sourced_from_integration: false,
  'actuals-hardcoded': false,
  'actuals-formula': false,
  'forecast-formula': false,
  'forecast-impact': false,
  'transition-value': false,
  error: false,
};

const CustomTooltip: React.FC<CustomTooltipProps<any, CellValueTooltipData>> = ({
  node,
  hideTooltipCallback,
  value,
}) => {
  const isInteractive =
    (value != null ? TOOLTIP_TYPE_TO_INTERACTIVE[value.content.type] : false) ?? false;

  const ref = useRef<HTMLDivElement>(null);

  const hide = useCallback(() => {
    // Resume hiding the tooltip when mouse leaves
    hideTooltipCallback?.();
  }, [hideTooltipCallback]);

  useOnClickOutside(ref, hide, { selector: styles.customTooltip });

  // In order to get interactive tooltips to work we tell AG Grid that all
  // tooltips are interactive and then manually close the ones that shouldn't
  // actuall be. There isn't a way to do the inverse that I know of.
  useEffect(() => {
    if (value == null || isInteractive || node == null) {
      return noop;
    }

    node.addEventListener('mouseLeave', hide);
    node.addEventListener('selectableChanged', hide);

    return () => {
      node.removeEventListener('mouseLeave', hide);
      node.addEventListener('selectableChanged', hide);
    };
  }, [value, hide, isInteractive, node, hideTooltipCallback]);

  if (value == null) {
    return null;
  }

  const { content } = value;

  return (
    <div
      ref={ref}
      className={classNames(styles.customTooltip, {
        [styles.interactive]: isInteractive,
      })}
    >
      <TooltipBody {...content} hideTooltipCallback={hideTooltipCallback} />
    </div>
  );
};

type TooltipBodyProps = CellValueTooltipData['content'] & {
  hideTooltipCallback?: () => void;
};

const TooltipBody: React.FC<TooltipBodyProps> = (props) => {
  const { type, hideTooltipCallback } = props;
  switch (type) {
    case 'driver_calc_error':
      return <CalcErrTooltipContent {...props} onDone={hideTooltipCallback} />;
    case 'ext_source':
      return <ExtSourceTooltip {...props} />;
    default:
      if (props.msg != null) {
        return <Text>{props.msg}</Text>;
      }
      return null;
  }
};

export default CustomTooltip;
