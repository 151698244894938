import React, { createContext, createElement, useContext, useRef } from 'react';

export const createRefContext = <T>(defaultInitialValue?: T) => {
  const context = createContext<React.MutableRefObject<T> | undefined>(undefined);

  const providerFactory = (
    props: any,
    children: React.ReactNode,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  ) => createElement(context.Provider, props, children);

  const StateProvider = ({
    children,
    initialValue,
  }: {
    children?: React.ReactNode;
    initialValue?: T;
  }) => {
    const ref = useRef<T | undefined>(
      initialValue !== undefined ? initialValue : defaultInitialValue,
    );
    return providerFactory({ value: ref }, children);
  };

  const useRefContext = () => {
    const ref = useContext(context);
    if (ref == null) {
      throw new Error(`useRefContext must be used inside a StateProvider.`);
    }
    return ref;
  };

  return [useRefContext, StateProvider, context] as const;
};
