import { createSelector } from '@reduxjs/toolkit';
import { isNumber, isString, keyBy, sortBy, sum } from 'lodash';
import { createCachedSelector } from 're-reselect';

import { ADD_ITEM_TYPE } from 'components/AgGridComponents/config/grid';
import {
  DatabaseGroupKey,
  EMPTY_ATTRIBUTE_ID,
  Group,
  GroupRow,
  MIN_WIDTH_OBJECT_TABLE_PX,
  NONE_GROUP_INFO,
  isObjectRow,
} from 'config/businessObjects';
import {
  BusinessObjectFieldCellRef,
  BusinessObjectFieldSpecColumnKey,
  BusinessObjectTimeSeriesCellRef,
  CellRowKey,
} from 'config/cells';
import { INITIAL_VALUE_COLUMN_TYPE, PROPERTY_COLUMN_TYPE } from 'config/modelView';
import {
  BlockGroupByType,
  DriverType,
  ObjectSortOp,
  ObjectSortOrder,
  ObjectSpecDisplayAsType,
  ValueType,
} from 'generated/graphql';
import { isBusinessObjectFieldSpecColumnKey } from 'helpers/cells';
import { getCurrentMonthKey, getMonthKey } from 'helpers/dates';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { filterDisplayToItem } from 'helpers/filterDisplayToItem';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import {
  filterObjects,
  objectsDisplay,
} from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { FormulaCalculationContext } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCalculationContext';
import { FormulaEvaluator } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import {
  groupByObjectFieldOrDriver,
  makeGroupsForNewlyCreatedAttributes,
} from 'helpers/groupByObjectField';
import { isExpressionBlockFilter } from 'helpers/isExpressionBlockFilter';
import { isObjectTableGroupExpanded } from 'helpers/isObjectTableGroupExpanded';
import { getObjectFieldUUID } from 'helpers/object';
import {
  orderedObjectTableBlockColumnKeysForDatabase,
  orderedObjectTableBlockColumnKeysForTimeseries,
} from 'helpers/objectTableBlockHelpers';
import { reorderObjectGroups, reorderObjects } from 'helpers/reorderObjects';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { Attribute, AttributeId } from 'reduxStore/models/dimensions';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { newlyCreatedAttributeIdsForBlockSelector } from 'selectors/blockLocalStateSelector';
import {
  blockConfigBusinessObjectSpecIdSelector,
  blockConfigObjectSpecDisplayAsSelector,
  blockConfigSelector,
} from 'selectors/blocksSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import {
  BusinessObjectFieldTransitionValuesByFieldId,
  businessObjectFieldTransitionValuesByFieldSpecIdSelector,
  businessObjectFirstNonNullValueByFieldIdSelector,
} from 'selectors/businessObjectTimeSeriesSelector';
import {
  businessObjectsByIdForLayerSelector,
  businessObjectsBySpecIdForLayerSelector,
} from 'selectors/businessObjectsSelector';
import { evaluateBlockResultSelector } from 'selectors/calculationsSelector';
import {
  orderedColumnKeysForObjectTableSelector,
  orderedColumnKeysToShowForObjectTableSelector,
  orderedDriverPropertyColumnsForBlockSelector,
} from 'selectors/collectionBlocksSelector';
import {
  databaseFormulaPropertiesByIdSelector,
  dimensionIdByDatabasePropertyIdSelector,
  dimensionalPropertyEvaluatorSelector,
  driverPropertiesByIdSelector,
  driverPropertiesForBusinessObjectSpecSelector,
  subdriversByDimDriverIdForBusinessObjectSelector,
} from 'selectors/collectionSelector';
import { columnWidthsForObjectTableBlockSelector } from 'selectors/columnWidthsForObjectTableBlockSelector';
import { blockIdSelector, fieldSelector, paramSelector } from 'selectors/constSelectors';
import { openDetailPaneSelector } from 'selectors/detailPaneSelectors';
import { attributesByIdSelector, dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { formulaCalculationContextSelector } from 'selectors/formulaCalculationContextSelector';
import { formulaDisplayListenerSelector } from 'selectors/formulaDisplaySelector';
import { formulaEvaluatorForLayerSelector } from 'selectors/formulaEvaluatorSelector';
import { shouldDoSynchronousCalculationsSelector } from 'selectors/inspectorSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import {
  blockDateRangeTranslatedViewAtTimeSelector,
  blockMonthKeysSelector,
} from 'selectors/pageDateRangeSelector';
import { timeSeriesColumnsForBlockSelector } from 'selectors/rollupSelector';
import { visibleColumnTypesSelector } from 'selectors/visibleColumnTypesSelector';
import { visibleFieldSpecTimeSeriesSelector } from 'selectors/visibleFieldSpecTimeSeriesSelector';
import { MonthKey } from 'types/datetime';
import { FilterItem } from 'types/filtering';
import { ParametricSelector } from 'types/redux';

const ADD_ITEM_ROW: GroupRow = { type: ADD_ITEM_TYPE };

function getExpressionFilters({
  evaluator,
  context,
  blockId,
  monthKey,
  filterExpression,
}: {
  evaluator: FormulaEvaluator;
  context?: FormulaCalculationContext;
  blockId: BlockId;
  monthKey: MonthKey;
  filterExpression: string;
}): BusinessObjectId[] | undefined {
  evaluator.invalidateCache();
  return filterObjects({
    evaluator,
    context,
    blockId,
    filter: filterExpression,
    monthKey,
    visited: new Set(),
    newlyAddedCacheKeys: new Set(),
  });
}

export const filtersForObjectTableBlockSelector: ParametricSelector<BlockId, FilterItem[]> =
  createCachedSelector(
    blockIdSelector,
    blockConfigSelector,
    (state: RootState, blockId: BlockId) =>
      formulaDisplayListenerSelector(state, { type: 'block', id: blockId }),
    (state: RootState) => databaseFormulaPropertiesByIdSelector(state),
    (_blockId, blockConfig, displayListener, databaseFormulaPropertiesById) => {
      const { businessObjectSpecId, filterBy } = blockConfig ?? {};
      if (businessObjectSpecId == null || filterBy == null) {
        return [];
      }

      return filterBy
        .filter(isExpressionBlockFilter)
        .flatMap((filter) => {
          const display = objectsDisplay(filter.expression, displayListener);
          if (display?.objectSpecId !== businessObjectSpecId) {
            return [];
          }
          // Table filters can't use contextAttributes so it's okay to just return the property filters
          return display.filters.propertyFilters;
        })
        .map((display) =>
          filterDisplayToItem(databaseFormulaPropertiesById, businessObjectSpecId, display),
        )
        .filter(isNotNull);
    },
  )({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

export const sortedObjectIdsForObjectTableBlockSelector: ParametricSelector<
  BlockId,
  BusinessObjectId[] | undefined
> = createSelector(
  (state: RootState) => state,
  blockIdSelector,
  (state: RootState) => attributesByIdSelector(state),
  (state: RootState, blockId: string) => objectTableBlockSortSelector(state, blockId),
  blockConfigSelector,
  (state, blockId, attributesById, sortOps, blockConfig) => {
    const idFilter = blockConfig?.idFilter;
    if (sortOps.length === 0 && idFilter == null) {
      return undefined;
    }

    const objectValuesByFieldSpecId = businessObjectFieldTransitionValuesByFieldSpecIdSelector(
      state,
      {
        blockId,
        businessObjectFieldSpecIds: sortOps.map((op) => op.propertyId),
        layerId: state.dataset.currentLayerId,
      },
    );

    let sortedObjects;
    // no sort filters, default to block config id filter
    if (sortOps.length === 0) {
      sortedObjects = sortBy(objectValuesByFieldSpecId, (obj) => {
        const index = idFilter!.findIndex((id) => id === obj.objectId);
        return index === -1 ? Infinity : index;
      });
    } else {
      sortedObjects = applySortOps({
        objects: objectValuesByFieldSpecId,
        sortOps,
        attributesById,
      });
    }

    return sortedObjects.map((obj) => obj.objectId);
  },
);

const EMPTY_OBJECTS: BusinessObject[] = [];
const orderedObjectsForObjectTableBlockSelector: ParametricSelector<BlockId, BusinessObject[]> =
  createCachedSelector(
    blockIdSelector,
    blockConfigSelector,
    (state: RootState, blockId: BlockId) =>
      blockDateRangeTranslatedViewAtTimeSelector(state, blockId),
    (state: RootState) => businessObjectsBySpecIdForLayerSelector(state),
    (state: RootState) => formulaEvaluatorForLayerSelector(state),
    (state: RootState) => formulaCalculationContextSelector(state),
    (state: RootState, blockId: string) =>
      sortedObjectIdsForObjectTableBlockSelector(state, blockId),
    // eslint-disable-next-line max-params
    function orderedObjectsForObjectTableBlockSelector(
      blockId,
      blockConfig,
      viewAtTime,
      businessObjectsBySpecId,
      evaluator,
      context,
      sortedObjectIds,
    ) {
      const { businessObjectSpecId, filterBy, idFilter } = blockConfig ?? {};
      if (businessObjectSpecId == null || businessObjectsBySpecId[businessObjectSpecId] == null) {
        return EMPTY_OBJECTS;
      }

      let objects = businessObjectsBySpecId[businessObjectSpecId];
      let filteredIds: string[] | undefined;
      const filters = filterBy?.filter(isExpressionBlockFilter);

      if (filters != null && filters.length > 0) {
        const monthKey = viewAtTime != null ? getMonthKey(viewAtTime) : getCurrentMonthKey();
        filteredIds = filters
          .map(({ expression }) =>
            getExpressionFilters({
              filterExpression: expression,
              context,
              blockId,
              monthKey,
              evaluator,
            }),
          )
          .filter(isNotNull)
          .flat();
      }

      objects = objects.filter(({ id }) => filteredIds == null || filteredIds.includes(id));

      // Manual re-ordering should be off when sorting is applied.
      if (sortedObjectIds) {
        return reorderObjects({
          objects,
          objectIdSortOrder: sortedObjectIds,
        });
      } else if (idFilter) {
        objects = reorderObjects({
          objects,
          objectIdSortOrder: idFilter,
        });
      }

      return objects;
    },
  )(blockIdSelector);

const EMPTY_GROUPS: Group[] = [];

const objectTableBlockNoneGroupSelector: ParametricSelector<BlockId, Group[]> =
  createCachedSelector(
    orderedObjectsForObjectTableBlockSelector,
    accessCapabilitiesSelector,
    function objectTableBlockNoneGroupSelector(orderedBlockObjects, capabilities) {
      const objectIds = orderedBlockObjects.map((o) => o.id);
      const rows = objectIds.map((id) => ({ type: 'object' as const, objectId: id }));
      const groups: Group[] = [
        {
          groupInfo: NONE_GROUP_INFO,
          rows: capabilities.canWriteDatabase ? [...rows, ADD_ITEM_ROW] : rows,
          isExpanded: true,
        },
      ];

      return groups;
    },
  )(blockIdSelector);

type BlockIdFieldSpecIdObjectSpecId = {
  blockId: BlockId;
  fieldSpecId: BusinessObjectFieldSpecId;
  objectSpecId: BusinessObjectSpecId;
};
const objectTableBlockGroupForFieldSpecSelector: ParametricSelector<
  BlockIdFieldSpecIdObjectSpecId,
  Group[]
> = createCachedSelector(
  (state: RootState, { blockId }: BlockIdFieldSpecIdObjectSpecId) =>
    blockConfigSelector(state, blockId),
  (state: RootState) => dimensionsByIdSelector(state),
  (state: RootState, { blockId }: BlockIdFieldSpecIdObjectSpecId) =>
    orderedObjectsForObjectTableBlockSelector(state, blockId),
  (state: RootState) => dimensionIdByDatabasePropertyIdSelector(state),
  (state: RootState, { fieldSpecId, objectSpecId }: BlockIdFieldSpecIdObjectSpecId) =>
    businessObjectFirstNonNullValueByFieldIdSelector(state, {
      fieldSpecId,
      objectSpecId,
    }),
  accessCapabilitiesSelector,
  (state: RootState, { blockId }: BlockIdFieldSpecIdObjectSpecId) =>
    newlyCreatedAttributeIdsForBlockSelector(state, blockId),
  (state: RootState) => dimensionalPropertyEvaluatorSelector(state),
  (state: RootState, { objectSpecId }: BlockIdFieldSpecIdObjectSpecId) =>
    driverPropertiesForBusinessObjectSpecSelector(state, objectSpecId),
  // eslint-disable-next-line max-params
  function objectTableBlockGroupForFieldSpecSelector(
    blockConfig,
    dimensionsById,
    orderedBlockObjects,
    dimensionIdByDatabasePropertyId,
    businessObjectValueByFieldIdOrSubdriverId,
    capabilities,
    newlyCreatedAttributeIds,
    dimensionalPropertyEvaluator,
    driverProperties,
  ) {
    const groupBy = blockConfig?.groupBy;

    if (groupBy == null) {
      return EMPTY_GROUPS;
    }

    const collapsed = groupBy?.objectField?.collapsedAttributeIds;

    return (
      groupByObjectFieldOrDriver({
        objects: orderedBlockObjects,
        groupBy,
        dimensionsById,
        dimensionIdByDatabasePropertyId,
        businessObjectValueByFieldIdOrSubdriverId,
        newlyCreatedAttributeIds,
        dimensionalPropertyEvaluator,
        driverProperties,
      }) ?? []
    ).map((g) => ({
      ...g,
      isExpanded: isObjectTableGroupExpanded(g.groupInfo, collapsed),
      rows: capabilities.canWriteDatabase ? [...g.rows, ADD_ITEM_ROW] : g.rows,
    }));
  },
)(
  (state, { blockId, fieldSpecId, objectSpecId }: BlockIdFieldSpecIdObjectSpecId) =>
    `${blockId}/${objectSpecId}/${fieldSpecId}`,
);

const objectTableBlockGroupsSyncSelector: ParametricSelector<BlockId, Group[]> = createSelector(
  (state: RootState) => state,
  paramSelector<BlockId>(),
  (state: RootState, blockId: BlockId) => blockConfigSelector(state, blockId),
  function objectTableBlockGroupsSyncSelector(state, blockId, blockConfig) {
    const businessObjectSpecId = blockConfig?.businessObjectSpecId;
    const groupBy = blockConfig?.groupBy;
    if (businessObjectSpecId == null) {
      return EMPTY_GROUPS;
    }

    if (groupBy?.groupByType === BlockGroupByType.ObjectField) {
      const objectFieldSpecIdToGroupBy = groupBy.objectField?.businessObjectFieldId;
      if (objectFieldSpecIdToGroupBy == null) {
        throw Error('cannot group by object field without field specified');
      }

      return objectTableBlockGroupForFieldSpecSelector(state, {
        blockId,
        objectSpecId: businessObjectSpecId,
        fieldSpecId: objectFieldSpecIdToGroupBy,
      });
    }

    return objectTableBlockNoneGroupSelector(state, blockId);
  },
);

const objectTableBlockGroupsAsyncSelector: ParametricSelector<BlockId, Group[]> = createSelector(
  (state: RootState, blockId: BlockId) => blockConfigSelector(state, blockId),
  accessCapabilitiesSelector,
  (state: RootState, blockId: string) => evaluateBlockResultSelector(state, blockId),
  businessObjectsBySpecIdForLayerSelector,
  (state: RootState) => dimensionsByIdSelector(state),
  (state: RootState) => dimensionIdByDatabasePropertyIdSelector(state),
  newlyCreatedAttributeIdsForBlockSelector,
  // eslint-disable-next-line max-params
  function objectTableBlockGroupsAsyncSelector(
    blockConfig,
    capabilities,
    evaluateBlockResult,
    businessObjectsBySpecId,
    dimensionsById,
    dimensionIdByDatabasePropertyId,
    newlyCreatedAttributeIds,
  ) {
    const businessObjectSpecId = blockConfig?.businessObjectSpecId;
    if (businessObjectSpecId == null) {
      return EMPTY_GROUPS;
    }
    const idFilter = blockConfig?.idFilter;
    const groupBy = blockConfig?.groupBy;
    const blockConfigSortBy = blockConfig?.sortBy;

    let groups: Group[];
    if (evaluateBlockResult != null) {
      const collapsedAttributeIds = groupBy?.objectField?.collapsedAttributeIds ?? [];

      groups = evaluateBlockResult.groups.map((g) => ({
        ...g,
        isExpanded: isObjectTableGroupExpanded(g.groupInfo, collapsedAttributeIds),
        rows: g.rows,
      }));
    } else {
      const businessObjects = safeObjGet(businessObjectsBySpecId[businessObjectSpecId]);

      groups = [
        {
          groupInfo: NONE_GROUP_INFO,
          isExpanded: true,
          rows: (businessObjects ?? []).map(({ id }) => ({ type: 'object', objectId: id })),
        },
      ];
    }

    // Manual re-ordering should be off when sorting is applied.
    if (
      idFilter != null &&
      (blockConfigSortBy == null || blockConfigSortBy.object?.ops.length === 0)
    ) {
      const orderedRows = reorderObjectGroups({
        groups: groups.map(({ rows }) => rows),
        objectIdSortOrder: idFilter,
      });
      groups = groups.map((g, i) => ({
        ...g,
        rows: orderedRows[i],
      }));
    }

    if (groupBy?.objectField?.businessObjectFieldId != null) {
      const fieldSpecId = groupBy.objectField.businessObjectFieldId;
      const dimensionId = safeObjGet(dimensionIdByDatabasePropertyId[fieldSpecId]);
      if (dimensionId != null) {
        const attributesWithCorrespondingObjects = Object.keys(
          groups.map((g) => g.groupInfo.attributeId),
        );
        groups.push(
          ...makeGroupsForNewlyCreatedAttributes({
            newlyCreatedAttributeIds,
            attributesWithCorrespondingObjects,
            dimensionId,
            fieldSpecId,
            dimensionsById,
          }),
        );
      }
    }

    return capabilities.canWriteDatabase
      ? groups.map((g) => ({
          ...g,
          rows: [...g.rows, ADD_ITEM_ROW],
        }))
      : groups;
  },
);

const objectTableBlockGroupsSelector: ParametricSelector<BlockId, Group[]> =
  function objectTableBlockGroupsSelector(state, blockId) {
    const shouldDoSynchronousCalculations = shouldDoSynchronousCalculationsSelector(state);
    if (!shouldDoSynchronousCalculations) {
      return objectTableBlockGroupsAsyncSelector(state, blockId);
    }

    return objectTableBlockGroupsSyncSelector(state, blockId);
  };

const objectTableBlockGroupKeysSelector: ParametricSelector<BlockId, DatabaseGroupKey[]> =
  createCachedSelector(objectTableBlockGroupsSelector, (groups) => {
    return groups.map((g) => g.groupInfo.key);
  })({
    keySelector: blockIdSelector,
    selectorCreator: createDeepEqualSelector,
  });

export const objectTableBlockGroupsByKeySelector: ParametricSelector<
  BlockId,
  Record<DatabaseGroupKey, Group>
> = createCachedSelector(objectTableBlockGroupsSelector, (groups) => {
  return keyBy(groups, (g) => g.groupInfo.key);
})(blockIdSelector);

type BlockGroupKey = {
  blockId: BlockId;
  groupKey: DatabaseGroupKey;
};
const objectTableBlockGroupSelector: ParametricSelector<BlockGroupKey, Group | undefined> =
  createCachedSelector(
    fieldSelector<BlockGroupKey, 'groupKey'>('groupKey'),
    (state, { blockId }) => objectTableBlockGroupsByKeySelector(state, blockId),
    function objectTableBlockGroupSelector(groupKey, objectTableBlockGroupsByKey) {
      return safeObjGet(objectTableBlockGroupsByKey[groupKey]);
    },
  )((_state, { blockId, groupKey }) => `${blockId},${groupKey}`);

function applySortOps({
  objects,
  sortOps,
  attributesById,
}: {
  objects: BusinessObjectFieldTransitionValuesByFieldId[];
  sortOps: ObjectSortOp[];
  attributesById: NullableRecord<string, Attribute>;
}): BusinessObjectFieldTransitionValuesByFieldId[] {
  // Stores a map with the order for manual sorts.
  const manualOrderings = sortOps.reduce(
    (orderings, sort) => {
      if (sort.order === ObjectSortOrder.Manual && sort.manualOrdering != null) {
        orderings[sort.propertyId] = sort.manualOrdering.reduce(
          (fieldOrdering, attributeId, index) => {
            fieldOrdering[attributeId] = index;
            return fieldOrdering;
          },
          {} as Record<AttributeId, number>,
        );
      }
      return orderings;
    },
    {} as Record<BusinessObjectFieldSpecId, Record<AttributeId, number>>,
  );

  return objects.sort(
    (
      a: BusinessObjectFieldTransitionValuesByFieldId,
      b: BusinessObjectFieldTransitionValuesByFieldId,
    ) => {
      for (const { propertyId, order } of sortOps) {
        const GREATER_THAN_VALUE = order === ObjectSortOrder.Asc ? 1 : -1;
        const LESS_THAN_VALUE = order === ObjectSortOrder.Asc ? -1 : 1;

        const fieldA = a[propertyId];
        const fieldB = b[propertyId];

        // It's possible that a bad config tries to sort a column that doesn't exist.
        if (fieldA == null) {
          return LESS_THAN_VALUE;
        }
        if (fieldB == null) {
          return GREATER_THAN_VALUE;
        }

        const fieldAValue = fieldA.newValue ?? fieldA.originalValue;
        const fieldBValue = fieldB.newValue ?? fieldB.originalValue;

        if (
          order === ObjectSortOrder.Manual &&
          fieldAValue.type === ValueType.Attribute &&
          fieldBValue.type === ValueType.Attribute
        ) {
          const attrAId = fieldAValue.value as AttributeId;
          const attrBId = fieldBValue.value as AttributeId;

          // Do not allow bad sort metadata to crash.
          if (!(propertyId in manualOrderings)) {
            continue;
          }

          const orderA = manualOrderings[propertyId][attrAId] ?? Number.MAX_SAFE_INTEGER;
          const orderB = manualOrderings[propertyId][attrBId] ?? Number.MAX_SAFE_INTEGER;

          if (orderA < orderB) {
            return -1;
          }
          if (orderB < orderA) {
            return 1;
          }

          continue;
        }

        if (fieldAValue.value === fieldBValue.value) {
          // Continue to next sort op.
          continue;
        }

        // These are fixed values because we always want a NULLS LAST behavior.
        if (fieldAValue.value === undefined) {
          return 1;
        }
        if (fieldBValue.value === undefined) {
          return -1;
        }

        if (fieldAValue.type === ValueType.Number && fieldBValue.type === ValueType.Number) {
          if (fieldAValue.value < fieldBValue.value) {
            return LESS_THAN_VALUE;
          }
          return GREATER_THAN_VALUE;
        }

        if (fieldAValue.type === ValueType.Timestamp && fieldBValue.type === ValueType.Timestamp) {
          if (fieldAValue.value.localeCompare(fieldBValue.value) < 0) {
            return LESS_THAN_VALUE;
          }
          return GREATER_THAN_VALUE;
        }

        if (fieldAValue.type === ValueType.Attribute && fieldBValue.type === ValueType.Attribute) {
          const attrAValue = safeObjGet(attributesById[fieldAValue.value])?.value;
          const attrBValue = safeObjGet(attributesById[fieldBValue.value])?.value;

          if (isNumber(attrAValue) && isNumber(attrBValue) && attrAValue < attrBValue) {
            return LESS_THAN_VALUE;
          } else if (
            isString(attrAValue) &&
            isString(attrBValue) &&
            attrAValue.localeCompare(attrBValue) < 0
          ) {
            return LESS_THAN_VALUE;
          } else if (attrAValue == null && attrBValue != null) {
            return LESS_THAN_VALUE;
          } else if (attrAValue != null && attrBValue == null) {
            return GREATER_THAN_VALUE;
          } else if (attrAValue == null && attrBValue == null) {
            return 0;
          }

          return GREATER_THAN_VALUE;
        }
      }

      return 0;
    },
  );
}

export const objectTableBlockGroupIsExpandedSelector = createCachedSelector(
  objectTableBlockGroupSelector,
  (group) => group?.isExpanded ?? false,
)((_state, { blockId, groupKey }) => `${blockId},${groupKey}`);

export const objectTableBlockGroupGroupingTypeSelector = createCachedSelector(
  objectTableBlockGroupSelector,
  (group) => group?.groupInfo?.groupingType,
)((_state, { blockId, groupKey }) => `${blockId},${groupKey}`);

export const objectTableBlockGroupAttributeIdSelector = createCachedSelector(
  objectTableBlockGroupSelector,
  (group) => {
    return group?.groupInfo?.attributeId ?? EMPTY_ATTRIBUTE_ID;
  },
)((_state, { blockId, groupKey }) => `${blockId},${groupKey}`);

export const objectTimelineViewFieldIdsSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId[]
> = createCachedSelector(
  orderedColumnKeysForObjectTableSelector,
  blockConfigBusinessObjectSpecIdSelector,
  (state: RootState) => businessObjectSpecsByIdForLayerSelector(state),
  (orderedFieldSpecIdsToShow, objectSpecId, objectSpecsById) => {
    if (objectSpecId == null || objectSpecsById[objectSpecId] == null) {
      return [];
    }

    const objectSpec = objectSpecsById[objectSpecId];
    if (objectSpec == null) {
      return [];
    }

    const objectFieldSpecIds = new Set([
      ...objectSpec.fields.map((f) => f.id),
      ...(objectSpec.collection?.driverProperties ?? []).map((f) => f.id),
    ]);

    return orderedFieldSpecIdsToShow
      .filter((id) => objectSpec.startFieldId !== id)
      .filter((id) => objectFieldSpecIds.has(id));
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

type ObjectTableBlockColumnKey =
  | BusinessObjectFieldCellRef['columnKey']
  | BusinessObjectTimeSeriesCellRef['columnKey'];

export const orderedObjectTableBlockColumnKeysSelector: ParametricSelector<
  BlockId,
  ObjectTableBlockColumnKey[]
> = createCachedSelector(
  timeSeriesColumnsForBlockSelector,
  visibleColumnTypesSelector,
  visibleFieldSpecTimeSeriesSelector,
  (state: RootState) => driverPropertiesByIdSelector(state),
  blockConfigObjectSpecDisplayAsSelector,
  blockMonthKeysSelector,
  (
    tsColumns,
    visibleColumns,
    timeSeriesFieldSpecId,
    driverPropertyId,
    objectTableDisplayAs,
    blockMonthKeys,
    // eslint-disable-next-line max-params
  ) => {
    if (objectTableDisplayAs === ObjectSpecDisplayAsType.Timeseries) {
      return orderedObjectTableBlockColumnKeysForTimeseries({ blockMonthKeys });
    }

    return orderedObjectTableBlockColumnKeysForDatabase({
      tsColumns,
      visibleColumns,
      timeSeriesFieldSpecId,
      driverPropertyId,
    });
  },
)(blockIdSelector);

export const orderedObjectTableBlockColumnKeysForAgGridSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecColumnKey[]
> = createCachedSelector(
  timeSeriesColumnsForBlockSelector,
  visibleColumnTypesSelector,
  (state: RootState) => driverPropertiesByIdSelector(state),
  (tsColumns, visibleColumns, driverPropertyId) => {
    const columns = orderedObjectTableBlockColumnKeysForDatabase({
      tsColumns,
      visibleColumns,
      timeSeriesFieldSpecId: null,
      driverPropertyId,
    }).filter(isBusinessObjectFieldSpecColumnKey);
    return columns;
  },
)(blockIdSelector);

export const orderedObjectTableBlockColumnKeysForTimeseriesAgGridSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecColumnKey[]
> = createCachedSelector(orderedObjectTableBlockColumnKeysForAgGridSelector, (properties) => {
  return properties.filter(
    ({ objectFieldSpecId }) =>
      objectFieldSpecId !== PROPERTY_COLUMN_TYPE && objectFieldSpecId !== INITIAL_VALUE_COLUMN_TYPE,
  );
})(blockIdSelector);

export const newFieldColumnWidthSelector: ParametricSelector<BlockId, number> =
  createCachedSelector(
    orderedColumnKeysToShowForObjectTableSelector,
    columnWidthsForObjectTableBlockSelector,
    visibleFieldSpecTimeSeriesSelector,
    (columnKeys, columnWidths, visibleFieldSpecTimeSeries) => {
      const tableWidth = sum(columnKeys.map((key) => columnWidths[key]));
      return visibleFieldSpecTimeSeries != null ? 40 : MIN_WIDTH_OBJECT_TABLE_PX - tableWidth;
    },
  )(blockIdSelector);

const subdriversForOpenObjectDetailPane = createSelector(
  (state: RootState) => state,
  openDetailPaneSelector,
  (state, detailPane) => {
    if (detailPane?.type !== PaneType.Object) {
      return null;
    }
    return subdriversByDimDriverIdForBusinessObjectSelector(state, detailPane.objectId);
  },
);

const EMPTY_OBJECT_TABLE_BLOCK_ROW_KEYS: CellRowKey[] = [];
export const orderedObjectTableBlockRowKeysSelector: ParametricSelector<BlockId, CellRowKey[]> =
  createCachedSelector(
    objectTableBlockGroupKeysSelector,
    objectTableBlockGroupsByKeySelector,
    blockConfigObjectSpecDisplayAsSelector,
    objectTimelineViewFieldIdsSelector,
    visibleFieldSpecTimeSeriesSelector,
    (state) => driverPropertiesByIdSelector(state),
    subdriversForOpenObjectDetailPane,
    (
      groupKeys,
      groupsByKey,
      blockDisplayAs,
      fieldIds,
      visibleFieldSpecTimeSeries,
      driverPropertiesById,
      subdriversByDimDriverId,
      // eslint-disable-next-line max-params
    ) => {
      const groups = groupKeys.map((key) => groupsByKey[key]).filter(isNotNull);
      if (groups.length === 0 || subdriversByDimDriverId == null) {
        return EMPTY_OBJECT_TABLE_BLOCK_ROW_KEYS;
      }

      const isTimeSeriesView = blockDisplayAs === ObjectSpecDisplayAsType.Timeseries;
      const expandedGroups = groups.filter((g) => g.isExpanded);

      if (isTimeSeriesView) {
        return expandedGroups
          .flatMap((group) =>
            group.rows.flatMap((row) => {
              // in this view, each row is an object
              return isObjectRow(row)
                ? [
                    ...fieldIds.flatMap((fieldSpecId) => ({
                      objectId: row.objectId,
                      fieldSpecId,
                      layerId: undefined,
                    })),
                    // NB: we add a row for every group to represent the "add a new property" row
                    { objectId: row.objectId, fieldSpecId: undefined, layerId: undefined },
                  ]
                : undefined;
            }),
          )
          .filter(isNotNull);
      }

      return expandedGroups.flatMap((group) => [
        ...group.rows
          .map((row) => {
            if (isObjectRow(row)) {
              const driverProperty =
                visibleFieldSpecTimeSeries != null
                  ? driverPropertiesById[visibleFieldSpecTimeSeries]
                  : null;

              if (driverProperty != null) {
                const subdriver = safeObjGet(subdriversByDimDriverId[driverProperty.driverId]);
                if (subdriver == null) {
                  return undefined;
                }
                return {
                  driverId: subdriver.driverId,
                  layerId: undefined,
                  groupId: undefined,
                };
              }
              return { objectId: row.objectId, groupKey: group.groupInfo.key };
            }
            return undefined;
          })
          .filter(isNotNull),
        // NB: we add a row for every group to represent the "add a new object" row.
        { objectId: null, groupKey: group.groupInfo.key },
      ]);
    },
  )(blockIdSelector);

export const orderedObjectGridBlockRowKeysSelector: ParametricSelector<BlockId, CellRowKey[]> =
  createCachedSelector(
    businessObjectSpecForBlockSelector,
    openDetailPaneSelector,
    subdriversForOpenObjectDetailPane,
    (objectSpec, detailPane, subdriversByDimDriverId) => {
      if (
        objectSpec == null ||
        detailPane?.type !== PaneType.Object ||
        subdriversByDimDriverId == null
      ) {
        return EMPTY_OBJECT_TABLE_BLOCK_ROW_KEYS;
      }
      return [
        ...objectSpec.fields.map((f) => {
          return {
            objectId: detailPane.objectId,
            fieldSpecId: f.id,
            layerId: undefined,
          };
        }),
        ...(objectSpec.collection?.driverProperties.map((f) => {
          const subdriver = safeObjGet(subdriversByDimDriverId[f.driverId]);
          if (subdriver == null) {
            return undefined;
          }
          return {
            driverId: subdriver.driverId,
            groupId: undefined,
            layerId: undefined,
          };
        }) ?? []),
      ].filter(isNotNull);
    },
  )(blockIdSelector);

export const objectTableBlockSortSelector: ParametricSelector<BlockId, ObjectSortOp[]> =
  createSelector(blockIdSelector, blockConfigSelector, (_blockId, blockConfig) => {
    if (blockConfig?.sortBy?.object != null) {
      return blockConfig.sortBy.object.ops;
    }

    return [];
  });

export const objectTableBlockFieldSortOrderSelector = createSelector(
  (state: RootState, blockId: string, _fieldSpecId: string) =>
    objectTableBlockSortSelector(state, blockId),
  (_state: RootState, _blockId: string, fieldSpecId: string) => fieldSpecId,
  (ops, fieldSpecId) => {
    return ops.find((o) => o.propertyId === fieldSpecId)?.order;
  },
);

const resolveSubDriverForObject = (
  objectId: string,
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator,
  driver: Driver,
) => {
  if (driver.type === DriverType.Dimensional) {
    const attributes =
      dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(objectId);

    if (attributes.length > 0) {
      // Use dimensionalPropertyEvaluator because it is optimizied.
      const subDriverId = dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
        driver.id,
        attributes.map((attr) => attr.attribute.id),
      );

      if (subDriverId != null) {
        return subDriverId;
      }
    }
  }

  return null;
};

type DriverIdsFieldIdsByObjectId = Record<
  BusinessObjectId,
  { objectId: BusinessObjectId; fieldIds: BusinessObjectFieldId[]; driverIds: DriverId[] }
>;
/**
 * For the given block returns the fieldIds and driverIds for each object in the block
 */
export const objectTableBlockDriverIdsFieldIdsByObjectIdSelector: ParametricSelector<
  BlockId,
  DriverIdsFieldIdsByObjectId
> = createCachedSelector(
  objectTableBlockGroupsSelector,
  orderedColumnKeysForObjectTableSelector,
  visibleFieldSpecTimeSeriesSelector,
  orderedDriverPropertyColumnsForBlockSelector,
  businessObjectsByIdForLayerSelector,
  driverPropertiesByIdSelector,
  driversByIdForLayerSelector,
  dimensionalPropertyEvaluatorSelector,
  // eslint-disable-next-line max-params
  function objectTableBlockObjectIdsDriverIdsFieldIdsSelector(
    groups,
    columnKeys,
    visibleFieldSpecTimeSeries,
    driverPropertyColumns,
    objectsById,
    driverPropertiesById,
    driversById,
    dimensionalPropertyEvaluator,
  ): Record<
    BusinessObjectId,
    { objectId: BusinessObjectId; fieldIds: BusinessObjectFieldId[]; driverIds: DriverId[] }
  > {
    const fieldsToShow =
      visibleFieldSpecTimeSeries != null ? [...columnKeys, visibleFieldSpecTimeSeries] : columnKeys;

    const objects = groups.flatMap(({ rows }) =>
      rows
        .filter(isObjectRow)
        .map(({ objectId }) => safeObjGet(objectsById[objectId]))
        .filter(isNotNull),
    );

    return keyBy(
      objects.map((object) => {
        const fieldIds: string[] = [];
        const driverIds: string[] = [];

        const fieldsByFieldSpecId = keyBy(object.fields, 'fieldSpecId');
        // Check every object field for either field or driver evaluation.
        for (const objectFieldSpecId of fieldsToShow) {
          if (driverPropertyColumns.includes(objectFieldSpecId)) {
            if (objectFieldSpecId in driverPropertiesById) {
              const dimensionalDriverId = safeObjGet(
                driverPropertiesById[objectFieldSpecId],
              )?.driverId;
              const driver =
                dimensionalDriverId == null
                  ? undefined
                  : safeObjGet(driversById[dimensionalDriverId]);
              if (driver != null) {
                const subDriverId = resolveSubDriverForObject(
                  object.id,
                  dimensionalPropertyEvaluator,
                  driver,
                );
                if (subDriverId != null) {
                  driverIds.push(subDriverId);
                }
              }
            }
          } else {
            const field = safeObjGet(fieldsByFieldSpecId[objectFieldSpecId]);
            fieldIds.push(field?.id ?? getObjectFieldUUID(object.id, objectFieldSpecId));
          }
        }

        return {
          objectId: object.id,
          fieldIds,
          driverIds,
        };
      }),
      'objectId',
    );
  },
)({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });
