import { IconButton } from '@chakra-ui/react';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import { stopEventPropagation } from 'helpers/browserEvent';
import useChartContext from 'hooks/useChartContext';
import ChartLegend from 'vectors/ChartLegend';

const ScenarioLegendButton: React.FC = () => {
  const { isScenariosLegendVisible, toggleIsScenariosLegendVisible } = useChartContext();

  return (
    <Tooltip
      placement="top"
      label={isScenariosLegendVisible ? 'Hide legend' : 'Show legend'}
      shouldWrapChildren
    >
      <IconButton
        variant="icon"
        alignItems="center"
        h="unset"
        w="unset"
        border="none"
        minW="unset"
        boxSize={6}
        borderRadius="md"
        _hover={{ bgColor: 'gray.200' }}
        color="gray.500"
        onClick={toggleIsScenariosLegendVisible}
        onMouseDown={stopEventPropagation}
        data-testid="chart-legend"
        aria-label="Show Legend"
        icon={<ChartLegend padding={1} boxSize={6} />}
      />
    </Tooltip>
  );
};

export default ScenarioLegendButton;
