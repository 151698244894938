import { useBoolean } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';

import AddDriversToBlockPopover from 'components/AddDriversToBlockPopover/AddDriversToBlockPopover';
import CreateDriverRow from 'components/CreateDriverRow/CreateDriverRow';
import FixedSizeVirtualizedDriverRows from 'components/DriverGroupSectionContent/FixedSizeVirtualizedDriverRows';
import { AddDriversPopoverContextProvider, DriverGridContext } from 'config/driverGridContext';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { clearAutoFocus } from 'reduxStore/reducers/pageSlice';
import { isAutoFocusedCreateDriverSelector } from 'selectors/pageSelector';

interface Props {
  groupId?: DriverGroupId;
  driverIds: DriverId[];
}

const DriverGroupSectionContent: React.FC<Props> = ({ groupId, driverIds }) => {
  const dispatch = useAppDispatch();
  const { readOnly } = useBlockContext();
  const { hasCreateButton } = useContext(DriverGridContext);
  const [isAdding, setIsAdding] = useBoolean();
  const isAutoFocused = useAppSelector((state) =>
    isAutoFocusedCreateDriverSelector(state, groupId),
  );

  useEffect(() => {
    if (isAutoFocused) {
      setIsAdding.on();
      dispatch(clearAutoFocus());
    }
  }, [dispatch, setIsAdding, isAutoFocused]);

  return (
    <AddDriversPopoverContextProvider>
      <FixedSizeVirtualizedDriverRows groupId={groupId} driverIds={driverIds} />
      {!readOnly && hasCreateButton && (
        <>
          <CreateDriverRow
            belowDriverId={driverIds[driverIds.length - 1]}
            groupId={groupId}
            isAdding={isAdding}
            onOpenDriverSelect={setIsAdding.on}
          />
          <AddDriversToBlockPopover isAdding={isAdding} onClose={setIsAdding.off} />
        </>
      )}
    </AddDriversPopoverContextProvider>
  );
};

export default React.memo(DriverGroupSectionContent);
