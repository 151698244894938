import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

import {
  DriverGroupCreateInput,
  DriverGroupDeleteInput,
  DriverGroupRenameInput,
} from 'generated/graphql';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { DriverGroup } from 'reduxStore/models/driverGroup';
import { Layer, LightLayer } from 'reduxStore/models/layers';

export function handleCreateDriverGroup(
  layer: Draft<LightLayer>,
  newDriverGroupInput: DriverGroupCreateInput,
) {
  const { id, name } = newDriverGroupInput;
  if (layer.driverGroups.byId[id] != null) {
    return;
  }

  const newGroup: DriverGroup = {
    id,
    name,
  };
  layer.driverGroups.byId[id] = newGroup;
  layer.driverGroups.allIds.push(newGroup.id);
}

export function handleRenameDriverGroup(
  layer: Draft<LightLayer>,
  renameDriverGroupInput: DriverGroupRenameInput,
) {
  const { id, name } = renameDriverGroupInput;
  if (layer.driverGroups.byId[id] == null) {
    return;
  }

  layer.driverGroups.byId[id].name = name;
}

export function handleDeleteDriverGroup(
  layer: Draft<Layer>,
  deleteDriverGroupInput: DriverGroupDeleteInput,
) {
  const { id } = deleteDriverGroupInput;

  delete layer.driverGroups.byId[id];
  Object.values(layer.drivers.byId).forEach((driver) => {
    // eslint-disable-next-line deprecation/deprecation
    if (driver.groupId === id) {
      // eslint-disable-next-line deprecation/deprecation
      driver.groupId = null;
    }
  });

  Object.values(layer.submodels.byId).forEach((submodel) => {
    submodel.sortedDriverGroupIds = submodel.sortedDriverGroupIds?.filter((el) => el !== id);
  });
}

export function setDriverGroupsFromDatasetSnapshot(
  layer: Draft<LightLayer>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null) {
    layer.driverGroups.byId = {};
    return;
  }

  const driverGroups = dataset.driverGroups.map((driverGroup) => {
    return {
      id: driverGroup.id,
      name: driverGroup.name,
    };
  });

  layer.driverGroups.byId = keyBy(driverGroups, 'id');
  layer.driverGroups.allIds = driverGroups.map((e) => e.id);
}
