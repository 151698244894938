import { createSelector } from 'reselect';

import {
  getObjectSpecIdFromInternalPageType,
  isDatabasePage,
} from 'config/internalPages/databasePage';
import { BusinessObjectSpec, BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { currentInternalPageTypeSelector } from 'selectors/blocksPagesSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { Selector } from 'types/redux';

export const databaseSpecIdSelector: Selector<BusinessObjectSpecId | undefined> = createSelector(
  currentInternalPageTypeSelector,
  (internalPageType) => {
    if (!isDatabasePage(internalPageType)) {
      return undefined;
    }

    return getObjectSpecIdFromInternalPageType(internalPageType);
  },
);

export const databaseSelector: Selector<BusinessObjectSpec | null> = createSelector(
  databaseSpecIdSelector,
  businessObjectSpecsByIdForLayerSelector,
  (specId, objectSpecsById) => {
    if (specId == null) {
      return null;
    }
    return objectSpecsById[specId];
  },
);

export const mustDatabaseSpecIdSelector: Selector<BusinessObjectSpecId> = createSelector(
  databaseSpecIdSelector,
  (specId) => {
    if (specId == null) {
      throw new Error('not an object database page');
    }

    return specId;
  },
);

export const databaseObjectSpecSelector: Selector<BusinessObjectSpec> = createSelector(
  mustDatabaseSpecIdSelector,
  businessObjectSpecsByIdForLayerSelector,
  (specId, objectSpecsById) => {
    return objectSpecsById[specId];
  },
);

export const isViewingDatabasePageSelector: Selector<boolean> = createSelector(
  currentInternalPageTypeSelector,
  (internalPageType) => isDatabasePage(internalPageType),
);
