import { Flex } from '@chakra-ui/react';
import React from 'react';

import LayerName from 'components/LayerName/LayerName';
import { LayerId } from 'reduxStore/models/layers';

interface Props {
  layerId: LayerId;
  enableIconBgColor?: boolean;
}

const LayerPill: React.FC<Props> = ({ layerId, enableIconBgColor }) => {
  return (
    <Flex
      alignItems="center"
      px={2}
      borderColor="gray.300"
      borderWidth="px"
      borderRadius="6px"
      h="1.75rem"
      columnGap={1}
    >
      <LayerName layerId={layerId} enableIconBgColor={enableIconBgColor} />
    </Flex>
  );
};

export default LayerPill;
