import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'DropdownIcon',
  viewBox: '0 0 8 10',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.00042 0.199219C3.89812 0.199219 3.79581 0.238206 3.71761 0.316406L0.517606 3.51641C0.403206 3.63081 0.368887 3.80274 0.430887 3.95234C0.492487 4.10194 0.638818 4.19922 0.800418 4.19922H7.20042C7.36202 4.19922 7.50835 4.10194 7.56995 3.95234C7.63195 3.80274 7.59763 3.63081 7.48323 3.51641L4.28323 0.316406C4.20503 0.238206 4.10272 0.199219 4.00042 0.199219ZM0.800418 5.79922C0.638818 5.79922 0.492487 5.89649 0.430887 6.04609C0.368887 6.19569 0.403206 6.36763 0.517606 6.48203L3.71761 9.68203C3.79561 9.76003 3.89802 9.79922 4.00042 9.79922C4.10282 9.79922 4.20523 9.76003 4.28323 9.68203L7.48323 6.48203C7.59763 6.36763 7.63195 6.19569 7.56995 6.04609C7.50835 5.89649 7.36202 5.79922 7.20042 5.79922H0.800418Z"
      clipRule="evenodd"
    />
  ),
});
