import { Group } from '@visx/group';
import { Circle } from '@visx/shape';
import { Text as VisxText } from '@visx/text';

import { MilestoneFlagTop } from 'components/DriverChart/MilestoneFlagTop';
import { POINT_RADIUS } from 'config/driverChart';
import theme from 'config/theme';
import { formatDriverValue } from 'helpers/formatting';
import useAppSelector from 'hooks/useAppSelector';
import { DriverId } from 'reduxStore/models/drivers';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';

const { colors, fonts, fontSizes, fontWeights } = theme;

interface Props {
  value: number;
  editable: boolean;
  driverId: DriverId;
}

const MiniMilestoneMarker: React.FC<Props> = ({ value, editable, driverId }) => {
  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );
  return (
    <>
      <VisxText
        x={4}
        y={-16}
        textAnchor="end"
        verticalAnchor="start"
        fontSize={fontSizes.xxs}
        fontFamily={fonts.body}
        fontWeight={fontWeights.semibold}
        fill={colors.milestone}
        style={{ userSelect: 'none' }}
      >
        {`${formatDriverValue(value, displayConfiguration)}`}
      </VisxText>
      {/* The top of the flag */}
      <Group left={6} top={-18}>
        <MilestoneFlagTop />
      </Group>
      <Circle cx={0} cy={0} r={POINT_RADIUS} fill={colors.milestone} />
      {/* Make a bigger bounding box for drag if editable */}
      {editable && (
        <rect
          fill="transparent"
          // NB: this needs to be large enough to cover the full bounds of
          // the other contents. since the number field is dynamic, these are just constants
          // that "should" be big enough to cover everything.
          x={-60}
          width={80}
          y={-20}
          height={40}
        />
      )}
    </>
  );
};

export default MiniMilestoneMarker;
