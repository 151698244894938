import { noop } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect } from 'react';

import { useWebWorkerDataProviderContext } from 'components/WebWorkerDataProvider/Context';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import useAppSelector from 'hooks/useAppSelector';
import { EventId } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { calculationEngineSelector } from 'selectors/calculationEngineSelector';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';

/**
 * Dispatches a request to the webworker for cell value calculation.
 * Transparently batches requests to optimize render performance.
 */
export const useRequestCellValue = ({
  id,
  ids,
  type,
  dateRange,
  layerId,
  layerIds,
  ignoreEventIds,
  requireBackendOnly,
}: {
  id?: string | undefined;
  ids?: string[];
  type: FormulaEntityTypedId['type'];
  dateRange: [DateTime, DateTime] | undefined;
  layerId?: LayerId;
  layerIds?: LayerId[];
  ignoreEventIds?: EventId[];
  requireBackendOnly?: boolean;
}) => {
  if (id == null && ids == null) {
    throw new Error('useRequestCellValue must provided either `id` or `ids`');
  }

  const calculationEngine = useAppSelector(calculationEngineSelector);

  const { requestValue } = useWebWorkerDataProviderContext();
  const givenOrCurrentLayerId = useAppSelector((state) =>
    getGivenOrCurrentLayerId(state, { layerId }),
  );

  const idsKey = ids?.join(':');
  const layerIdsKey = layerIds?.join(':');
  const ignoreEventIdsKey = ignoreEventIds?.join(':');
  const [start, end] = dateRange ?? [];

  useEffect(() => {
    if ((id == null && ids == null) || dateRange == null) {
      return noop;
    }

    if (
      requireBackendOnly != null &&
      requireBackendOnly &&
      calculationEngine !== 'backend-only' &&
      calculationEngine !== 'backend-ww-disabled'
    ) {
      return noop;
    }

    if (id != null) {
      const dispose = requestValue({
        id,
        dateRange,
        type,
        layerId: givenOrCurrentLayerId,
        ignoreEventIds,
      });
      return () => {
        dispose();
      };
    }

    if (ids != null) {
      let disposals: Array<() => void>;
      if (layerIds == null) {
        disposals = ids.map((i) =>
          requestValue({ id: i, dateRange, type, layerId: givenOrCurrentLayerId, ignoreEventIds }),
        );
      } else {
        disposals = layerIds.flatMap((l) =>
          ids.map((i) => requestValue({ id: i, dateRange, type, layerId: l, ignoreEventIds })),
        );
      }
      return () => {
        for (const dispose of disposals) {
          dispose();
        }
      };
    }

    return noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calculationEngine,
    start,
    end,
    givenOrCurrentLayerId,
    id,
    idsKey,
    layerIdsKey,
    ignoreEventIdsKey,
    requestValue,
    requireBackendOnly,
    type,
  ]);
};
