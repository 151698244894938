import { Flex, TableCellProps, Tag, Tbody, Td, Tr } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import ColoringInfo from 'components/CompareScenariosModalContent/ColoringInfo';
import DriverReferencesInfo from 'components/CompareScenariosModalContent/DriverReferencesInfo';
import RowText from 'components/CompareScenariosModalContent/RowText';
import { DriverComparisonColumnInfo } from 'components/CompareScenariosModalContent/diffTypes';
import LayerName from 'components/LayerName/LayerName';
import { DRIVER_FORMAT_NAMES } from 'config/drivers';
import { LeverType } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import { Layer } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { driverSelector } from 'selectors/driversSelector';
import { layersSelector } from 'selectors/layerSelector';
import {
  DriverDiffType,
  isBaselineLayerSelector,
  scenarioComparisonBaselineLayerIdSelector,
} from 'selectors/scenarioComparisonSelector';

interface Props {
  comparisonLayerIds: string[];
  comparisonColumns: DriverComparisonColumnInfo[];
}
const DriverPropertiesComparisonTableBody: React.FC<Props> = ({
  comparisonColumns,
  comparisonLayerIds,
}) => {
  const layersById = useAppSelector(layersSelector);

  return (
    <Tbody border="1px solid" borderColor="gray.400">
      {comparisonLayerIds.map((layerId) => (
        <DriverPropertiesComparisonTableRow
          key={layerId}
          comparisonLayer={layersById[layerId]}
          comparisonColumns={comparisonColumns}
        />
      ))}
    </Tbody>
  );
};

const DriverPropertiesComparisonTableRow: React.FC<{
  comparisonLayer: Layer;
  comparisonColumns: DriverComparisonColumnInfo[];
}> = ({ comparisonLayer, comparisonColumns }) => {
  const isBaselineLayer = useAppSelector((state) =>
    isBaselineLayerSelector(state, comparisonLayer.id),
  );

  return (
    <Tr>
      <TableCell>
        <LayerName
          layerId={comparisonLayer.id}
          isBaselineLayer={isBaselineLayer}
          isComparisonLayout
          noBgColor
          fontSize="xxs"
        />
      </TableCell>
      {comparisonColumns.map((column) => (
        <ComparisonRow key={column.driverId} column={column} layerId={comparisonLayer.id} />
      ))}
    </Tr>
  );
};

const ComparisonRow: React.FC<{
  column: DriverComparisonColumnInfo;
  layerId: string;
}> = ({ column, layerId }) => {
  return (
    <>
      {column.diffTypes.map((diffType) => (
        <ComparisonCell
          key={`${column.driverId}-${diffType}`}
          driverId={column.driverId}
          diffType={diffType}
          layerId={layerId}
        />
      ))}
    </>
  );
};

const ComparisonCell: React.FC<{
  diffType: DriverDiffType;
  driverId: string;
  layerId: string;
}> = ({ diffType, layerId, driverId }) => {
  const baselineLayerId = useAppSelector(scenarioComparisonBaselineLayerIdSelector);
  const baselineDriver = useAppSelector((state: RootState) =>
    driverSelector(state, { id: driverId, layerId: baselineLayerId }),
  );
  const driver = useAppSelector((state: RootState) =>
    driverSelector(state, { id: driverId, layerId }),
  );

  const content = useMemo(() => {
    switch (diffType) {
      case 'updated_name': {
        return (
          <Flex flexDirection="row" gap={2} alignItems="center">
            <RowText text={driver?.name} />
            {baselineDriver?.name !== driver?.name && (
              <Tag size="sm" colorScheme="orange">
                Renamed
              </Tag>
            )}
          </Flex>
        );
      }
      case 'updated_format': {
        if (driver?.format != null) {
          return <RowText text={DRIVER_FORMAT_NAMES[driver.format]} />;
        }
        break;
      }
      case 'updated_decimal_places': {
        return <RowText text={driver?.decimalPlaces?.toString()} />;
      }
      case 'updated_currency': {
        return <RowText text={driver?.currencyISOCode} />;
      }
      case 'updated_lever_type': {
        const text = driver?.leverType === LeverType.Kpi ? 'KPI' : 'Not KPI';
        return <RowText text={text} />;
      }
      case 'updated_coloring': {
        if (driver?.coloring != null) {
          return <ColoringInfo coloring={driver.coloring} />;
        }
        break;
      }
      case 'updated_references': {
        return <DriverReferencesInfo driverId={driverId} layerId={layerId} />;
      }
      default:
        break;
    }
    return null;
  }, [baselineDriver?.name, diffType, driver, driverId, layerId]);

  return (
    <TableCell p={2}>
      <Flex alignItems="center">{content}</Flex>
    </TableCell>
  );
};

const TableCell: React.FC<{ children: React.ReactNode } & TableCellProps> = ({
  children,
  ...rest
}) => {
  return (
    <Td
      borderRightWidth="1px"
      borderRightColor="gray.300"
      borderRightStyle="dashed"
      borderBottomWidth="1px"
      borderBottomColor="gray.300"
      borderBottomStyle="dashed"
      {...rest}
    >
      {children}
    </Td>
  );
};

export default React.memo(DriverPropertiesComparisonTableBody);
