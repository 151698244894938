import { Spinner } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useContext, useMemo } from 'react';

import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import { TIME_PERIOD_TO_RELATIVE_MONTHS } from 'config/datetime';
import { getDateTimeFromMonthKey, getMonthKey } from 'helpers/dates';
import { formatDriverValue } from 'helpers/formatting';
import useAppSelector from 'hooks/useAppSelector';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { DriverId } from 'reduxStore/models/drivers';
import { entityLoadingForMonthKeySelector } from 'selectors/calculationsSelector';
import { driverTimeSeriesSelector } from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { datasetLastActualsDateTimeSelector } from 'selectors/lastActualsSelector';
import { TimePeriod } from 'types/datetime';

interface Props {
  driverId: DriverId;
  timePeriod: TimePeriod;
}

const DriverMetaValue: React.FC<Props> = ({ driverId, timePeriod }) => {
  const { isActuals } = useContext(FormulaSelectionContext);

  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );
  const lastActualsDateTime = useAppSelector(datasetLastActualsDateTimeSelector);

  // preview values relative to the actuals end (for actuals), or the first forecast month (for forecasts)
  const startDateTime = isActuals ? lastActualsDateTime : lastActualsDateTime.plus({ months: 1 });
  const relativeMonths = TIME_PERIOD_TO_RELATIVE_MONTHS[timePeriod];
  const monthKey = getMonthKey(startDateTime.plus({ months: relativeMonths ?? 0 }));

  const driverTimeSeriesWithoutDragData = useAppSelector((state) =>
    driverTimeSeriesSelector(state, {
      id: driverId,
      start: monthKey,
      end: monthKey,
    }),
  );
  const isDriverValueLoading = useAppSelector((state) =>
    entityLoadingForMonthKeySelector(state, { id: driverId, monthKey }),
  );

  const dateRange = useMemo(() => {
    const dt = getDateTimeFromMonthKey(monthKey);
    return [dt, dt] as [DateTime, DateTime];
  }, [monthKey]);

  useRequestCellValue({ id: driverId, type: 'driver', dateRange });

  if (relativeMonths == null) {
    return null;
  }

  const value = driverTimeSeriesWithoutDragData[monthKey];
  if (value == null) {
    return null;
  }
  const formatted = formatDriverValue(value, displayConfiguration, { abbreviate: true });
  return isDriverValueLoading ? <Spinner /> : <span>{formatted}</span>;
};

export default DriverMetaValue;
