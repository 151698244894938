import { Button, Flex, Grid } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { useCallback, useContext } from 'react';

import DatePickerHeader from 'components/MonthPicker/DatePickerHeader';
import DatePickerPager from 'components/MonthPicker/DatePickerPager';
import DatePickerTextInput from 'components/MonthPicker/DatePickerTextInput';
import { DatePickerReactContext } from 'config/datePickerContext';
import { MONTHS, TODAY, shortMonthFormat } from 'helpers/dates';

export interface MonthPickerProps {
  minDate: DateTime;
  maxDate: DateTime;
  selected?: DateTime;
  onDateSelect: (selected: DateTime) => void;
  hideYear?: boolean;
  focused?: boolean;
}

const MonthPicker: React.FC<MonthPickerProps> = ({
  onDateSelect,
  selected = TODAY,
  minDate,
  maxDate,
  hideYear = false,
  focused = true,
}) => {
  return (
    <DatePickerHeader onDateSelect={onDateSelect} selected={selected} pageBy="year">
      {!hideYear && (
        <DatePickerTextInput
          selected={selected}
          focused={focused}
          formatter={shortMonthFormat}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
      {!hideYear && (
        <Flex w="full" px="0.75rem">
          <DatePickerPager />
        </Flex>
      )}
      <MonthPickerBody selected={selected} minDate={minDate} maxDate={maxDate} />
    </DatePickerHeader>
  );
};
interface MonthPickerBodyProps {
  minDate: DateTime;
  maxDate: DateTime;
  selected: DateTime;
}

const MonthPickerBody: React.FC<MonthPickerBodyProps> = ({ selected, minDate, maxDate }) => {
  const { pagedYear, onDateSelect } = useContext(DatePickerReactContext);

  const isMonthDisabled = (month: number) => {
    const startOfMonth = DateTime.utc(pagedYear, month);
    return startOfMonth < minDate || startOfMonth > maxDate;
  };

  const onMonthSelect = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      const month = Number(ev.currentTarget.value);
      const date = DateTime.utc(pagedYear, month);
      onDateSelect(date, { reason: 'button' });
    },
    [onDateSelect, pagedYear],
  );

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLButtonElement>) => {
      if (ev.key === 'Enter') {
        onMonthSelect(ev);
      }
    },
    [onMonthSelect],
  );

  return (
    <Grid
      flex="1 0"
      templateColumns="repeat(3, 1fr)"
      rowGap={1}
      columnGap={2}
      w="11.75rem"
      h="7.5rem"
      mt={0}
    >
      {MONTHS.map((month, index) => {
        if (index === 0) {
          return null;
        }

        const isSelected =
          selected != null && selected.month === index && selected.year === pagedYear;
        return (
          <Button
            key={month}
            data-testid={month}
            data-selected={isSelected || undefined}
            isDisabled={isMonthDisabled(index)}
            tabIndex={isSelected ? 0 : undefined}
            variant="text"
            size="sm"
            py="0.375rem"
            onMouseDown={onMonthSelect}
            onKeyDown={onKeyDown}
            value={index}
          >
            {month}
          </Button>
        );
      })}
    </Grid>
  );
};

export default React.memo(MonthPicker);
