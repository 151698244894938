import React from 'react';

import DataSourceLogo from 'components/DataSourceLogo/DataSourceLogo';
import FormulaEntityChunk from 'components/Formula/FormulaEntityChunk';
import { ExtDriverFormulaDisplayChunk } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';

interface Props {
  chunk: ExtDriverFormulaDisplayChunk;
}

const ExtDriverChunk: React.FC<Props> = ({ chunk }) => {
  const { timeRange, timeRangeDisplay, displayName } = chunk;
  return (
    <FormulaEntityChunk
      timeRange={timeRange}
      icon={chunk.source != null ? <DataSourceLogo source={chunk.source} size="xs" /> : undefined}
      timeRangeDisplay={timeRangeDisplay}
      label={displayName}
    />
  );
};

export default ExtDriverChunk;
