import { useMemo } from 'react';

import { ComparisonColumn, DriverFormat } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import { DriverId } from 'reduxStore/models/drivers';
import { DisplayConfiguration } from 'reduxStore/models/value';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';

export const useDisplayConfiguration = ({
  driverId,
  comparisonColumn,
}: {
  driverId: DriverId;
  comparisonColumn: ComparisonColumn | undefined;
}): DisplayConfiguration => {
  const baseDisplayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );

  const isVariancePercentage = comparisonColumn === ComparisonColumn.VariancePercentage;
  return useMemo(
    () => ({
      ...baseDisplayConfiguration,
      format: isVariancePercentage ? DriverFormat.Percentage : baseDisplayConfiguration.format,
      comparisonType: comparisonColumn,
    }),
    [baseDisplayConfiguration, comparisonColumn, isVariancePercentage],
  );
};
