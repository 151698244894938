import * as Sentry from '@sentry/nextjs';
import clamp from 'lodash/clamp';
import omit from 'lodash/omit';
import round from 'lodash/round';
import { DateTime } from 'luxon';
import numeral from 'numeral';

import { CURRENCY_BY_ISO, DEFAULT_CURRENCY } from 'config/currency';
import {
  CalculationErrorType,
  ComparisonColumn,
  DriverFormat,
  ImpactType,
  NegativeDisplay,
} from 'generated/graphql';
import { DisplayConfiguration } from 'reduxStore/models/value';
import { CalculationError, NOT_APPLICABLE_ERR_TYPE } from 'types/dataset';

// Numbers greater than or equal to 100,000 need to be abbreviated
// in order to display properly in tables and charts
const ABBREVIATION_THRESHOLD = 1e5;
// Maximum number of digits that abbreviated numbers should display
const MAX_DIGITS = 9;
// Maximum number of digits that abbreviated numbers should display
const MAX_ABBREVIATED_DIGITS = 4;
// Maximum number of decimals that should be shown for any number
const MAX_DECIMALS = 5;

const SIGNED_NUMERAL_REGEX = /^(-?)(\d+)/;

numeral.locales.en.abbreviations = {
  thousand: 'K',
  million: 'M',
  billion: 'B',
  trillion: 'T',
};

// Some drivers were created in the past without a driver format
const MISSING_FORMAT_VALUE = '';

export function formatDriverValue(
  value: number,

  // These are the options that are set on the drivers themselves. If
  // decimalPlaces is set, it overrides other options.
  { format, currency, decimalPlaces, negativeDisplay, comparisonType }: DisplayConfiguration,

  {
    abbreviate = true,
    abbreviatedDecimals,
    includeCents,
    maxPrecision,
  }: {
    abbreviate?: boolean;
    abbreviatedDecimals?: number;
    includeCents?: boolean;
    maxPrecision?: number;
  } = {},
): string {
  if (!Number.isFinite(value)) {
    return '';
  }

  const isVariance = comparisonType === ComparisonColumn.Variance;
  const isVariancePercentage = comparisonType === ComparisonColumn.VariancePercentage;
  if (value === 0 && (isVariance || isVariancePercentage)) {
    return '-';
  }

  const fixedDecimals = decimalPlaces ?? undefined;

  switch (format) {
    case DriverFormat.Currency:
      return formatAsCurrency(value, currency ?? DEFAULT_CURRENCY, {
        fixedDecimals,
        includeCents: includeCents ?? Math.abs(value) < 10,
        abbreviate,
        abbreviatedDecimals,
        negativeDisplay,
      });
    case DriverFormat.Integer:
      return formatAsNumber(round(value), {
        abbreviate,
        fixedDecimals: decimalPlaces ?? undefined,
        negativeDisplay,
      });
    case DriverFormat.Number:
      return formatAsNumber(value, { abbreviate, fixedDecimals, negativeDisplay });
    case DriverFormat.Percentage:
      return formatAsPercent(value, {
        maxPrecision,
        fixedDecimals,
        abbreviate,
        negativeDisplay,
      });
    case DriverFormat.Auto:
      Sentry.withScope((scope: Sentry.Scope) => {
        scope.setLevel('warning');
        Sentry.captureMessage('Format value called on Auto format');
      });
      return formatAsNumber(value, { abbreviate, fixedDecimals, negativeDisplay });
    // @ts-expect-error: This is a catch-all for empty driver format "" values.
    case MISSING_FORMAT_VALUE:
      Sentry.withScope((scope: Sentry.Scope) => {
        scope.setLevel('warning');
        Sentry.captureMessage('Format value called on "EMPTY" format');
      });
      return formatAsNumber(value, { abbreviate, fixedDecimals, negativeDisplay });
    default:
      Sentry.withScope((scope: Sentry.Scope) => {
        scope.setExtra('value', value);
        Sentry.captureException(
          new Error(`Format value called with unexpected format (${format})`),
        );
      });
      return String(value);
  }
}

type PercentFormatOptions = Pick<NumberFormatOptions, 'abbreviate' | 'fixedDecimals'> & {
  maxPrecision?: number;
  negativeDisplay: NegativeDisplay;
};

// Format input numbers as a percentile where 1 => 100%
function formatAsPercent(input: number, formatOpts: PercentFormatOptions): string {
  const inputAsPercentValue = input * 100;

  const { fixedDecimals, maxPrecision, negativeDisplay } = formatOpts;
  const opts: NumberFormatOptions = { ...omit(formatOpts, 'maxPrecision') };

  if (fixedDecimals == null) {
    // Limit decimals in percentages to just 2 unless it is a small percentile
    //  then fall back to the default max decimals.
    const maxDecimals =
      Math.abs(inputAsPercentValue) < 1 ? (maxPrecision ?? MAX_DECIMALS) : (maxPrecision ?? 2);
    opts.maxDecimals = maxDecimals;
  }

  const formatString = getNumeralFormatString(inputAsPercentValue, opts);

  // N.B. we must append percentage sign after formatting through numeral.js, as it does not support abbreviating percentages
  const withPercent = numeral(inputAsPercentValue).format(formatString) + '%';

  return negativeDisplay === NegativeDisplay.Parentheses ? parenNegative(withPercent) : withPercent;
}

type CurrencyOptions = {
  includeCents: boolean;
  abbreviate: boolean;
  abbreviatedDecimals?: number;
  fixedDecimals?: number;
  negativeDisplay: NegativeDisplay;
};

function formatAsCurrency(
  value: number | string,
  currencyISO: string,
  {
    includeCents = false,
    abbreviate = false,
    abbreviatedDecimals,
    fixedDecimals,
    negativeDisplay,
  }: Partial<CurrencyOptions> = {},
): string {
  const valueAsNumber = Number(value);
  if (Number.isNaN(valueAsNumber)) {
    return '-';
  }

  const formatOpts: NumberFormatOptions = {
    abbreviate,
    abbreviatedDecimals,
    negativeDisplay: negativeDisplay ?? NegativeDisplay.NegativeSign,
  };

  if (fixedDecimals != null) {
    formatOpts.maxDecimals = fixedDecimals;
    formatOpts.minDecimals = fixedDecimals;
  } else {
    formatOpts.maxDecimals = includeCents ? 2 : 0;
    formatOpts.minDecimals = includeCents ? 2 : 0;
  }

  const formatString = getNumeralFormatString(valueAsNumber, formatOpts);
  const formattedValue = numeral(valueAsNumber).format(formatString);

  // N.B. ECMA replacement pattern for '$' is '$$'. Both USD and CAD have $ in
  // their symbol (CAD is "C$").
  const symbol = (CURRENCY_BY_ISO[currencyISO]?.symbol ?? '$').replaceAll('$', '$$$$');

  let formatted = formattedValue.replace(SIGNED_NUMERAL_REGEX, `$1${symbol}$2`);

  if (negativeDisplay === NegativeDisplay.Parentheses) {
    formatted = parenNegative(formatted);
  }

  return formatted;
}

type NumberFormatOptions = {
  abbreviate?: boolean;
  abbreviatedDecimals?: number;
  minDecimals?: number;
  maxDecimals?: number;
  fixedDecimals?: number;
  negativeDisplay: NegativeDisplay;
};

const ABBREVIATION_REGEX = /^\$?[0-9,.]+[kKmMbBtT]$/;
function expandAbbreviation(val: string) {
  if (val.match(ABBREVIATION_REGEX)) {
    const res = String(numeral(val.toUpperCase()).value());
    return res;
  }
  return val;
}

export function formatAsNumber(
  value: number | string,
  formatOptions?: NumberFormatOptions,
): string {
  const expanded = expandAbbreviation(String(value));
  const formatString = getNumeralFormatString(expanded, formatOptions);
  const formatted = numeral(expanded).format(formatString);
  return formatOptions?.negativeDisplay === NegativeDisplay.Parentheses
    ? parenNegative(formatted)
    : formatted;
}

/*
 * Generate a format string for values to be used with numeral.js
 */
function getNumeralFormatString(
  value: number | string,
  {
    fixedDecimals,
    abbreviate = true,
    abbreviatedDecimals,
    minDecimals = 0,
    maxDecimals = MAX_DECIMALS,
  }: Partial<NumberFormatOptions> = {},
): string {
  if (value === '') {
    return value;
  }

  if (fixedDecimals != null) {
    return getBaseNumeralFormat(fixedDecimals, fixedDecimals);
  }

  const valueAsNumber = Number(value);
  if (Number.isNaN(valueAsNumber)) {
    return String(value);
  }

  const absValue = Math.abs(valueAsNumber);
  if (abbreviate) {
    if (absValue >= ABBREVIATION_THRESHOLD) {
      return getAbbreviatedNumeralFormat(
        valueAsNumber,
        MAX_ABBREVIATED_DIGITS,
        abbreviatedDecimals,
      );
    }
  }

  let adjustedMaxDecimals = maxDecimals;
  if (absValue >= ABBREVIATION_THRESHOLD) {
    const logNum = Math.log(absValue) / Math.LN10;
    const numLeftDigits = Math.floor(logNum) + 1;
    adjustedMaxDecimals = clamp(Math.max(MAX_DIGITS - numLeftDigits, 0), maxDecimals);
  }

  return getBaseNumeralFormat(minDecimals, adjustedMaxDecimals);
}

/*
 * Helper function to get an abbreviated numeral format limiting the number of digits of the output to nSig.
 * Necessary since numeral.js currently doesn't have support for significant figures.
 */
function getAbbreviatedNumeralFormat(num: number, nSig: number, maxDecimals?: number) {
  // Log base 10 of the absolute value of the number
  const logNum = Math.log(Math.abs(num)) / Math.LN10;

  // Calculate number of digits on each side of the decimal,
  // preferring the more significant digits (left of the decimal)
  const numLeftDigits = (Math.floor(logNum) % 3) + 1;
  const numRightDigits = maxDecimals ?? Math.max(nSig - numLeftDigits, 0);

  return `0,0.[${'0'.repeat(numRightDigits)}]a`;
}

function getBaseNumeralFormat(minDecimals: number, maxDecimals: number) {
  const optionalRightDigits = Math.max(0, maxDecimals - minDecimals);
  const mandatoryDecimalString = minDecimals > 0 ? '0'.repeat(minDecimals) : '';
  const optionalDecimalString =
    optionalRightDigits > 0 ? `[${'0'.repeat(optionalRightDigits)}]` : '';

  return `0,0.${mandatoryDecimalString}${optionalDecimalString}`;
}

const NEGATIVE_SIGN = '-';
function parenNegative(val: string) {
  return val.includes(NEGATIVE_SIGN) ? `(${val.replace(NEGATIVE_SIGN, '')})` : val;
}

const ERROR_DISPLAYS: Record<CalculationError['error'], string> = {
  [CalculationErrorType.DivByZero]: 'DIV/0',
  [CalculationErrorType.CircularDependency]: 'CIRC',
  [CalculationErrorType.MissingEntity]: 'REF',
  [CalculationErrorType.Unexpected]: 'ERR',
  [CalculationErrorType.InvalidType]: 'TYPE',
  [CalculationErrorType.Formula]: 'FORM',
  [NOT_APPLICABLE_ERR_TYPE]: NEGATIVE_SIGN,
};

function getDisplayErr(error: string): string | null {
  return ERROR_DISPLAYS[error as CalculationErrorType] ?? null;
}

export function getDisplayValue(
  value: number | undefined | null,
  error: CalculationError | undefined | null,
  {
    displayConfiguration,
    impactType,
  }: {
    displayConfiguration: DisplayConfiguration;
    impactType?: ImpactType;
  },
) {
  if (error != null) {
    return getDisplayErr(error.error);
  }

  let displayValue =
    value == null
      ? undefined
      : formatDriverValue(value, displayConfiguration, { abbreviate: false });
  if (displayValue != null && impactType === ImpactType.Delta && value != null && value > 0) {
    displayValue = '+' + displayValue;
  }

  if (displayValue != null && impactType === ImpactType.Set && value != null) {
    displayValue = '=' + displayValue;
  }

  return displayValue;
}

export function formatNumber(number: number | undefined, precision: number = 0): string {
  if (number == null) {
    return '';
  }

  return new Intl.NumberFormat(navigator.language ?? 'en-US', {
    maximumFractionDigits: precision,
  }).format(number);
}

const onlyNumbers = /^\s*\d+\s*$/;
export function formatLabel(label: string): string {
  if (onlyNumbers.test(label)) {
    return label;
  }

  const date = DateTime.fromISO(label, { setZone: true });
  if (!date.isValid) {
    return label;
  }

  if (date.hour === 0 && date.minute === 0) {
    return date.toLocaleString({
      dateStyle: 'medium',
    });
  }

  return date.toLocaleString({
    dateStyle: 'medium',
    timeStyle: 'short',
  });
}
