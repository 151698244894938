import * as Sentry from '@sentry/nextjs';
import retry from 'async-retry';

import { Dataset } from 'generated/graphql';
import { apiFetch } from 'helpers/api';
import { stringifyUrlParams } from 'helpers/urls';
import { IndexedDB } from 'indexeddb/IndexedDB';

// Only retry download for a total of 1 min 50 sec.
// Both CloudFlare and the GCP load balancer will timeout at 2 mins;
// try to stay safely within those reasonable bounds.
// If there are continuous network timeouts, the network connection is unstable enough
// that it does not warrant leaving the user without feedback for a very long time.
const MAX_RETRY_TIME = 1000 * 110;

export type DatasetResult = { dataset: Dataset; mutationId: string; orgId: string };

const getCachedDataset = (mutationId: string, orgId: string) => {
  return IndexedDB.get(orgId).getOne('named_versions', mutationId);
};

const setCachedDataset = async (mutationId: string, orgId: string, dataset: Dataset) => {
  try {
    await IndexedDB.get(orgId).setOne('named_versions', mutationId, dataset);
  } catch (e) {
    console.error(e);

    // Log quota information to Sentry on all DB errors.
    let quota: StorageEstimate;
    if (navigator.storage != null) {
      quota = await navigator.storage.estimate();
    }

    Sentry.withScope((scope) => {
      scope.setExtras({ quota });
      Sentry.captureException(e);
    });

    // Attempt to clear the DB.
    if (e instanceof DOMException && e.name === 'QuotaExceededError') {
      IndexedDB.get(orgId).clear('named_versions');
    }
  }
};

export const evictCachedDataset = async (mutationId: string, orgId: string) => {
  await IndexedDB.get(orgId).deleteOne('named_versions', mutationId);
};

/**
 * Gets a named version from cache or, on miss, fetches from the network.
 */
export const getOrFetchNamedVersion = async (
  mutationId: string,
  orgId: string,
): Promise<DatasetResult> => {
  return retry<DatasetResult>(
    async (_, attempt) => {
      Sentry.addBreadcrumb({
        message: 'Downloading named version',
        data: {
          mutationId,
          orgId,
          attempt,
        },
      });

      let dataset = null;
      try {
        dataset = await getCachedDataset(mutationId, orgId);
      } catch {
        // If we fail to cache the dataset, we should still return the dataset.
        // We don't want to crash the page because of a cache error.
        Sentry.captureMessage('Failed to get cached named version');
      }

      if (dataset == null) {
        dataset = await apiFetch<Dataset>(
          `/dataset${stringifyUrlParams({
            orgId,
            mutationId,
          })}`,
        );

        try {
          await setCachedDataset(mutationId, orgId, dataset);
        } catch {
          // If we fail to cache the dataset, we should still return the dataset.
          // We don't want to crash the page because of a cache error.
          Sentry.captureMessage('Failed to cache named version');
        }
      }

      return { dataset, mutationId, orgId };
    },
    // Avoid crashing the page because the request timed out.
    { retries: 2, maxRetryTime: MAX_RETRY_TIME },
  );
};
