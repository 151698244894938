import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'CashChartIcon',
  viewBox: '0 0 344 176',
  defaultProps: {
    fill: 'none',
    width: '344px',
    height: '176px',
  },
  path: (
    <>
      <path
        d="M13.5753 10.1293C13.2273 7.9517 11.522 6.67898 9.36932 6.67898C6.73438 6.67898 4.79048 8.6527 4.79048 11.9091C4.79048 15.1655 6.72443 17.1392 9.36932 17.1392C11.6065 17.1392 13.2422 15.7372 13.5753 13.7237L12.0241 13.7188C11.7607 15.0213 10.6669 15.7372 9.37926 15.7372C7.63423 15.7372 6.31676 14.3999 6.31676 11.9091C6.31676 9.43821 7.62926 8.08097 9.38423 8.08097C10.6818 8.08097 11.7706 8.81179 12.0241 10.1293H13.5753ZM17.5165 17.169C18.7793 17.169 19.4902 16.5277 19.7736 15.956H19.8333V17H21.285V11.929C21.285 9.70668 19.535 9.2642 18.3219 9.2642C16.9398 9.2642 15.6671 9.82102 15.1699 11.2131L16.5669 11.5312C16.7857 10.9893 17.3425 10.4673 18.3418 10.4673C19.3013 10.4673 19.7935 10.9695 19.7935 11.8345V11.8693C19.7935 12.4112 19.2367 12.4013 17.8645 12.5604C16.4178 12.7294 14.9363 13.1072 14.9363 14.8423C14.9363 16.3438 16.0648 17.169 17.5165 17.169ZM17.8397 15.9759C16.9995 15.9759 16.3929 15.598 16.3929 14.8622C16.3929 14.0668 17.0989 13.7834 17.959 13.669C18.4412 13.6044 19.5847 13.4751 19.7985 13.2614V14.2457C19.7985 15.1506 19.0776 15.9759 17.8397 15.9759ZM28.9872 11.228C28.679 10.0398 27.7493 9.2642 26.0987 9.2642C24.3736 9.2642 23.1506 10.174 23.1506 11.5263C23.1506 12.6101 23.8068 13.331 25.2386 13.6491L26.5312 13.9325C27.267 14.0966 27.6101 14.4247 27.6101 14.902C27.6101 15.4936 26.9787 15.956 26.0043 15.956C25.1143 15.956 24.5426 15.5732 24.3636 14.8224L22.9268 15.0412C23.1754 16.3935 24.299 17.1541 26.0142 17.1541C27.8587 17.1541 29.1364 16.1747 29.1364 14.7926C29.1364 13.7138 28.4503 13.0476 27.0483 12.7244L25.8352 12.446C24.995 12.2472 24.6321 11.9638 24.6371 11.4467C24.6321 10.8601 25.2685 10.4425 26.1136 10.4425C27.0384 10.4425 27.4659 10.9545 27.6399 11.4666L28.9872 11.228ZM32.2573 12.4659C32.2573 11.2479 33.0279 10.5518 34.0868 10.5518C35.111 10.5518 35.7225 11.2031 35.7225 12.3217V17H37.209V12.1428C37.209 10.2386 36.165 9.2642 34.5939 9.2642C33.4057 9.2642 32.6898 9.78125 32.3319 10.6065H32.2374V6.81818H30.7708V17H32.2573V12.4659Z"
        fill="#B9C0CD"
      />
      <path
        d="M172 141V141C182.771 141 193.176 136.827 201.199 129.642C285.41 54.2245 300.855 72 340 72.0003"
        stroke="#B9C0CD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
      <path
        d="M172 141.001V141.001C161.19 141.001 151.074 136.198 142.618 129.463C86.4759 84.7437 2 105.001 2 105.001"
        stroke="#B9C0CD"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </>
  ),
});
