import { COLUMN_TYPE_TO_NAME } from 'config/modelView';
import { DriverFormat, ExtObjectSpec, Forecast, ValueType } from 'generated/graphql';
import { ExtSource } from 'helpers/integrations';
import { BusinessObject, BusinessObjectFieldValue } from 'reduxStore/models/businessObjects';
import { Collection } from 'reduxStore/models/collections';
import { DimensionId } from 'reduxStore/models/dimensions';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { ExtObjectSpecKey } from 'reduxStore/models/extObjectSpecs';
import { DatabaseConfigId } from 'selectors/databaseConfigSelector';

export type BusinessObjectSpecId = string;
export type BusinessObjectFieldSpecId = string;

export type PopulatedBusinessObjectSpec = {
  id: BusinessObjectSpecId;
  name: string;
  startFieldId?: BusinessObjectFieldSpecId;
  fields: BusinessObjectFieldSpec[];
  objects: BusinessObject[];
  defaultNameEntries: NullableRecord<string, string>;

  extObjectSpecs: ExtObjectSpec[];

  collection?: Collection;
};

export type BusinessObjectSpec = {
  id: BusinessObjectSpecId;
  name: string;
  fields: BusinessObjectFieldSpec[];
  databaseConfigId?: DatabaseConfigId;
  isRestricted?: boolean;
  defaultNameEntries: NullableRecord<string, string>;
  startFieldId?: BusinessObjectFieldSpecId;
  extSource?: ExtSource;
  extSpecKey?: ExtObjectSpecKey;
  extSpecKeys?: ExtObjectSpecKey[];
  collection?: Collection;
};

export type BusinessObjectFieldSpec = CommonObjectFieldSpec & {
  numericFormat?: DriverFormat;
  defaultForecast: Forecast;
  defaultValues: NullableRecord<string, BusinessObjectFieldValue>;
  id: BusinessObjectFieldSpecId;
  extFieldSpecKey?: string;
  isRestricted?: boolean;
  propagateIntegrationData?: boolean;
  integrationDataOverridesForecast?: boolean;
  dimensionId?: DimensionId;
};

export type CommonObjectFieldSpec = {
  name: string;
  isFormula?: boolean;
} & (
  | { type: ValueType.Timestamp }
  | {
      type: ValueType.Number;
      numericFormat: DriverFormat;
      currencyISOCode?: string;
      decimalPlaces?: number;
    }
  | { type: ValueType.Attribute; dimensionId: DimensionId }
);

export const isNumericFieldSpec = (
  fieldSpec: BusinessObjectFieldSpec,
): fieldSpec is BusinessObjectFieldSpec & { type: ValueType.Number } => {
  return fieldSpec.type === ValueType.Number;
};

export const getAllPropertyNames = ({
  objectSpec,
  driversById,
}: {
  objectSpec?: BusinessObjectSpec;
  driversById: NullableRecord<DriverId, Driver>;
}): NullableRecord<string, string> => {
  const fieldNamesById = objectSpec?.fields.reduce(
    (acc, field) => {
      acc[field.id] = field.name;
      return acc;
    },
    {} as NullableRecord<string, string>,
  );
  const dimPropertyNamesById = objectSpec?.collection?.dimensionalProperties.reduce(
    (acc, collection) => {
      acc[collection.id] = collection.name;
      return acc;
    },
    {} as NullableRecord<string, string>,
  );
  const driverPropertyNamesById = objectSpec?.collection?.driverProperties.reduce(
    (acc, { id, driverId }) => {
      const driver = driversById[driverId];
      if (driver == null) {
        return acc;
      }
      acc[id] = driver.name;
      return acc;
    },
    {} as NullableRecord<string, string>,
  );
  return {
    ...fieldNamesById,
    ...dimPropertyNamesById,
    ...driverPropertyNamesById,
    actualsFormula: COLUMN_TYPE_TO_NAME.actualsFormula,
    formula: COLUMN_TYPE_TO_NAME.formula,
  };
};
