import snakeCase from 'lodash/snakeCase';

import { InvalidDatabaseConfigError } from 'components/DatabasePageContent/DatabaseConfigurationEditor/DatabaseConfigErrorsProvider';
import {
  BlockType,
  BlockViewAsType,
  DatabaseConfig,
  DatasetMutationInput,
  FileMetadata,
  Integration,
  IntegrationCategory,
} from 'generated/graphql';
import { isSSR } from 'helpers/environment';
import { extDriverSourceDisplayName } from 'helpers/integrations';
import { getDomainFromUrl } from 'helpers/media';
import { isNamedVersion } from 'helpers/namedVersions';
import { AnalyticsEvent, trackEvent } from 'helpers/segment';
import { RelativePosition } from 'reduxStore/actions/blockMutations';
import { BlockId, BlocksPageId } from 'reduxStore/models/blocks';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { LayerId } from 'reduxStore/models/layers';
import { CopilotOperation, DataSourcePageState } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import {
  blocksPageNameSelector,
  currentPageIdWithSubmodelsSelector,
} from 'selectors/blocksPagesSelector';
import { UsableChartGroupingType, blockTypeSelector } from 'selectors/blocksSelector';
import { copilotOperationSelector } from 'selectors/copilotSelector';
import {
  currentLayerIdSelector,
  currentLayerNameSelector,
  layerMetadataSelector,
} from 'selectors/layerSelector';
import { pageSelector } from 'selectors/pageBaseSelector';

export const trackCreateBlocksPageEvent =
  ({ pageId }: { pageId: BlocksPageId }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const pageName = blocksPageNameSelector(state, pageId);
    dispatch(
      trackEventWithContext(AnalyticsEvent.CreatePage, {
        pageId,
        pageName,
      }),
    );
  };

export const trackDeleteBlocksPageEvent =
  ({ pageId }: { pageId: BlocksPageId }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const pageName = blocksPageNameSelector(state, pageId);
    dispatch(
      trackEventWithContext(AnalyticsEvent.DeletePage, {
        pageId,
        pageName,
      }),
    );
  };

export const trackViewPage = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const page = pageSelector(state);
  const pageType = page?.type;
  let pageId: string | undefined;
  let pageName: string | undefined;
  switch (pageType) {
    case 'blocksPage': {
      pageId = page?.id;
      pageName = page?.id != null ? blocksPageNameSelector(state, page.id) : undefined;
      break;
    }
    case 'submodelPage': {
      pageId = page?.id;
      pageName = page?.id != null ? blocksPageNameSelector(state, page.id) : undefined;
      break;
    }
    case 'dataSourcePage': {
      pageName = page != null ? getPageNameForDataSourcePage(page) : undefined;
      break;
    }
    case 'templatesPage': {
      pageName = 'templates';
      break;
    }
    default:
      break;
  }
  dispatch(
    trackEventWithContext(AnalyticsEvent.ViewPage, {
      pageType,
      pageId,
      pageName,
    }),
  );
};

export const trackCreateScenarioEvent =
  ({ id, name }: { id: LayerId; name: string }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContext(AnalyticsEvent.CreateScenario, {
        scenarioId: id,
        name,
      }),
    );
  };

export const trackDeleteScenarioEvent =
  ({ id }: { id: LayerId }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const name = layerMetadataSelector(state, id)?.name;
    dispatch(
      trackEventWithContext(AnalyticsEvent.DeleteScenario, {
        scenarioId: id,
        name,
      }),
    );
  };

export const trackSwitchScenarioEvent =
  ({ newLayerId }: { newLayerId: LayerId }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const currentLayerId = currentLayerIdSelector(state);
    const currentLayerName = currentLayerNameSelector(state);
    const newLayerMetadata = layerMetadataSelector(state, newLayerId);
    const newLayerName = newLayerMetadata?.name;
    dispatch(
      trackEventWithContext(AnalyticsEvent.SwitchScenario, {
        previousScenario: { id: currentLayerId, name: currentLayerName },
        newScenario: { id: newLayerId, name: newLayerName },
      }),
    );
  };

export const trackSubmitMutation =
  ({
    layerId,
    mutation,
    status,
  }: {
    layerId: LayerId;
    mutation: DatasetMutationInput;
    status: 'Success' | 'Pending' | 'Retrying';
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContext(AnalyticsEvent.SubmitMutation, {
        layerId,
        mutation: formatMutationForAnalytics(mutation),
        status,
      }),
    );
  };

export const trackSubmitMutationSuccess =
  ({ layerId, mutation }: { layerId: LayerId; mutation: DatasetMutationInput }): AppThunk =>
  (dispatch) => {
    dispatch(trackSubmitMutation({ layerId, mutation, status: 'Success' }));
  };

export const trackSubmitMutationFailure =
  ({
    layerId,
    mutation,
    error,
  }: {
    layerId: LayerId;
    mutation: DatasetMutationInput;
    error: { message: string };
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContext(AnalyticsEvent.SubmitMutation, {
        layerId,
        mutation: formatMutationForAnalytics(mutation),
        status: 'Failed',
        error,
      }),
    );
  };

export const trackUndoMutation =
  ({
    layerId,
    mutation,
    undoMutationId,
    status,
  }: {
    layerId: LayerId;
    mutation: DatasetMutationInput;
    undoMutationId: string;
    status: 'Success' | 'Pending' | 'Retrying';
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContext(AnalyticsEvent.UndoMutation, {
        layerId,
        mutation: formatMutationForAnalytics(mutation),
        undoMutationId,
        status,
      }),
    );
  };

export const trackUndoMutationSuccess =
  ({
    layerId,
    mutation,
    undoMutationId,
  }: {
    layerId: LayerId;
    mutation: DatasetMutationInput;
    undoMutationId: string;
  }): AppThunk =>
  (dispatch) => {
    dispatch(trackUndoMutation({ layerId, mutation, undoMutationId, status: 'Success' }));
  };

export const trackUndoMutationFailure =
  ({
    layerId,
    mutation,
    undoMutationId,
    error,
  }: {
    layerId: LayerId;
    mutation: DatasetMutationInput;
    undoMutationId: string;
    error: Error;
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContext(AnalyticsEvent.UndoMutation, {
        layerId,
        mutation: formatMutationForAnalytics(mutation),
        undoMutationId,
        status: 'Failed',
        error,
      }),
    );
  };

export const trackLinkIntegrationStart =
  ({
    integration,
    category,
  }: {
    integration: Integration;
    category: IntegrationCategory;
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackLinkIntegrationEvent({
        integration,
        category,
        status: 'incomplete',
      }),
    );
  };

export const trackLinkIntegrationComplete =
  ({
    integration,
    category,
  }: {
    integration: Integration;
    category: IntegrationCategory;
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackLinkIntegrationEvent({
        integration,
        category,
        status: 'complete',
      }),
    );
  };

export const trackLinkIntegrationFailed =
  ({
    integration,
    category,
    error,
  }: {
    integration: Integration;
    category: IntegrationCategory;
    error: ErrorEvent;
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackLinkIntegrationEvent({
        integration,
        category,
        status: 'failed',
        error,
      }),
    );
  };

export const trackLinkIntegrationAbandoned =
  ({
    integration,
    category,
  }: {
    integration: Integration;
    category: IntegrationCategory;
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackLinkIntegrationEvent({
        integration,
        category,
        status: 'abandoned',
      }),
    );
  };

const trackLinkIntegrationEvent =
  ({
    integration,
    category,
    status,
    error,
  }: {
    integration: Integration;
    category: IntegrationCategory;
    status: 'incomplete' | 'complete' | 'failed' | 'abandoned';
    error?: ErrorEvent;
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContext(AnalyticsEvent.LinkIntegration, {
        integrationName: integration.name,
        integrationSlug: integration.slug,
        integrationProvider: integration.provider,
        integrationCategory: category,
        status,
        error,
      }),
    );
  };

export enum FormatType {
  Currency = 'currency',
  Color = 'color',
  Negatives = 'negatives',
}

export enum FormatOptions {
  Cell = 'cell',
  Row = 'row',
  Default = 'default',
  Parens = 'parens',
}

export const trackEditFormatEvent =
  ({
    pageType,
    formatType,
    selectionType,
  }: {
    pageType: string;
    formatType: FormatType;
    selectionType?: FormatOptions;
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContextAndHighlight(AnalyticsEvent.EditFormat, {
        pageType,
        formatType,
        ...(selectionType != null && { selectionType: snakeCase(selectionType) }),
      }),
    );
  };

export const trackFeatureFlagToggle =
  ({ featureFlag, value }: { featureFlag: string; value: boolean }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContextAndHighlight(AnalyticsEvent.ToggleFeatureFlag, {
        featureFlag,
        value,
      }),
    );
  };

export const trackPlansPage = (): AppThunk => (dispatch) => {
  dispatch(trackEventWithContextAndHighlight(AnalyticsEvent.PlansPage));
};

export const trackUnlistedDriversPage = (): AppThunk => (dispatch) => {
  dispatch(trackEventWithContextAndHighlight(AnalyticsEvent.UnlistedDriversPage));
};

export const trackDetailPaneNav = (): AppThunk => (dispatch) => {
  dispatch(trackEventWithContextAndHighlight(AnalyticsEvent.DetailPaneNav));
};

export enum FilterType {
  TimePeriod = 'timePeriod',
}

export enum FilterSelectionType {
  AllTime = 'allTime',
  AsOf = 'asOf',
}
export const trackDatabaseFilter =
  ({
    pageType,
    filterType,
    selectionType,
  }: {
    pageType: string;
    filterType: FilterType;
    selectionType: FilterSelectionType;
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContextAndHighlight(AnalyticsEvent.Filter, {
        pageType,
        filterType,
        selectionType,
      }),
    );
  };

export const trackChartEdit =
  ({
    chartType,
    chartDisplay,
  }: {
    chartType?: BlockViewAsType;
    chartDisplay?: UsableChartGroupingType;
  }): AppThunk =>
  (dispatch) => {
    const chartTypeValue = snakeCase(chartType);
    const chartDisplayValue = snakeCase(chartDisplay);

    if (chartType == null && chartDisplayValue == null) {
      throw new Error("Can't track chart edit without chartType or chartDisplay");
    }

    dispatch(
      trackEventWithContextAndHighlight(AnalyticsEvent.EditChart, {
        ...(chartTypeValue != null && { chartType: chartTypeValue }),
        ...(chartDisplayValue != null && { chartDisplay: chartDisplayValue }),
      }),
    );
  };

const trackEventWithContextAndHighlight =
  (
    event: AnalyticsEvent,
    properties?: NullableRecord<string, unknown>,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ): AppThunk =>
  (dispatch) => {
    dispatch(trackEventWithContext(event, properties, options, callback));
  };

const trackEventWithContext =
  (
    event: AnalyticsEvent,
    properties?: NullableRecord<string, unknown>,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ): AppThunk =>
  (_dispatch, getState) => {
    const state = getState();
    const context = getEventContext(state);
    const propertiesWithContext = properties != null ? { ...properties, context } : { context };
    trackEvent(event, propertiesWithContext, options, callback);
  };

function getEventContext(state: RootState) {
  return {
    location: getLocationParams(),
    currentScenario: getCurrentScenarioInfo(state),
  };
}

function getLocationParams() {
  const location = !isSSR() ? window.location : undefined;
  if (location == null) {
    return undefined;
  }
  const { href, pathname, host } = location;
  return { href, pathname, host };
}

function getCurrentScenarioInfo(state: RootState) {
  const currentLayerId = currentLayerIdSelector(state);
  const currentLayerMetadata = layerMetadataSelector(state, currentLayerId);
  return {
    id: currentLayerMetadata?.id,
    name: currentLayerMetadata?.name,
    isDraft: currentLayerMetadata?.isDraft,
    isNamedVersion: isNamedVersion(currentLayerMetadata),
  };
}

const DATA_SOURCE_SUB_PAGE_DISPLAY_NAME = {
  extDrivers: 'External Drivers',
  linkedObjects: 'Linked Objects',
  integrationQuery: 'Integration Query',
};
const getPageNameForDataSourcePage = (pageState: DataSourcePageState) => {
  const nameParts = [];
  nameParts.push('Data Sources');
  if (pageState.source != null) {
    const sourceName = extDriverSourceDisplayName(pageState.source);
    nameParts.push(sourceName);
    const subPageType = pageState.navSubPage?.type;
    const subPageName =
      subPageType != null ? DATA_SOURCE_SUB_PAGE_DISPLAY_NAME[subPageType] : undefined;
    if (subPageName != null) {
      nameParts.push(subPageName);
    }
  }
  return nameParts.join(' > ');
};

const formatMutationForAnalytics = (input: DatasetMutationInput) => {
  return Object.fromEntries(
    Object.entries(input).filter(([_, v]) => {
      // remove null entries
      if (v == null) {
        return false;
      }
      // remove empty top level arrays (unused mutation types)
      if (Array.isArray(v) && v.length === 0) {
        return false;
      }
      return true;
    }),
  );
};

export const trackCopilotStart =
  (operation: CopilotOperation): AppThunk =>
  (dispatch) => {
    dispatch(trackEventWithContext(AnalyticsEvent.CopilotStart, { operation }));
  };

export const trackCopilotRetry = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const operation = copilotOperationSelector(state);
  dispatch(trackEventWithContext(AnalyticsEvent.CopilotRetry, { operation }));
};

export const trackCopilotRefinement =
  (refinementText: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const operation = copilotOperationSelector(state);
    dispatch(
      trackEventWithContext(AnalyticsEvent.CopilotRefinement, { operation, refinementText }),
    );
  };

export const trackCopilotEnd =
  (outcome: 'accept' | 'reject'): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const operation = copilotOperationSelector(state);
    dispatch(trackEventWithContext(AnalyticsEvent.CopilotEnd, { operation, outcome }));
  };

export const trackDatabaseConfigurationUpdate =
  ({
    databaseId,
    databaseName,
    existingConfig,
    newConfig,
    layerId,
  }: {
    databaseId: BusinessObjectSpecId;
    databaseName: string;
    existingConfig: DatabaseConfig | null;
    newConfig: DatabaseConfig;
    layerId: LayerId;
  }): AppThunk =>
  (dispatch) => {
    const props = {
      databaseId,
      databaseName,
      existingConfig,
      newConfig,
      status: 'Success',
      layerId,
    };
    dispatch(trackEventWithContext(AnalyticsEvent.DatabaseConfigurationUpdate, props));
  };

export const trackDatabaseConfigurationFailure =
  ({
    databaseId,
    databaseName,
    existingConfig,
    newConfig,
    layerId,
    error,
  }: {
    databaseId: BusinessObjectSpecId;
    databaseName: string;
    existingConfig: DatabaseConfig | null;
    newConfig: DatabaseConfig | null;
    layerId: LayerId;
    error: unknown;
  }): AppThunk =>
  (dispatch) => {
    const allErrorMessages: string[] = [];
    if (error instanceof InvalidDatabaseConfigError) {
      allErrorMessages.push(...error.all.map((e) => e.message));
    } else if (error instanceof Error) {
      allErrorMessages.push(error.message);
    } else {
      allErrorMessages.push(`unknown error: ${error}`);
    }
    const props = {
      databaseId,
      databaseName,
      existingConfig,
      newConfig,
      layerId,
      errors: allErrorMessages,
      status: 'Failed',
    };
    dispatch(trackEventWithContext(AnalyticsEvent.DatabaseConfigurationUpdate, props));
  };

export const trackCreateBlock =
  (type: BlockType, pageId?: BlocksPageId | null): AppThunk =>
  (dispatch) => {
    dispatch(trackEventWithContext(AnalyticsEvent.CreateBlock, { type, pageId }));
  };

export const trackUploadFile =
  (type: 'image' | 'video', blockId: BlockId, fileMetadata: FileMetadata): AppThunk =>
  (dispatch, getState) => {
    const pageId = currentPageIdWithSubmodelsSelector(getState());

    dispatch(
      trackEventWithContext(AnalyticsEvent.UploadFile, {
        type,
        blockId,
        pageId,
        fileId: fileMetadata.id,
        fileType: fileMetadata.type,
        fileName: fileMetadata.name,
        filePath: fileMetadata.path,
        fileSize: fileMetadata.size,
      }),
    );
  };

export const trackAddMediaLink =
  (type: 'image' | 'video', blockId: BlockId, url: string): AppThunk =>
  (dispatch, getState) => {
    const pageId = currentPageIdWithSubmodelsSelector(getState());
    const domain = getDomainFromUrl(url);
    dispatch(
      trackEventWithContext(AnalyticsEvent.AddMediaLink, { type, blockId, pageId, url, domain }),
    );
  };

export const trackMoveBlock =
  (
    blockId: BlockId,
    pageId: BlocksPageId,
    targetBlockId: BlockId,
    relativePosition: RelativePosition,
  ): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContext(AnalyticsEvent.MoveBlock, {
        blockId,
        pageId,
        targetBlockId,
        relativePosition,
      }),
    );
  };

export const trackToggleBlockHeader =
  (hideHeader: boolean, blockId: BlockId): AppThunk =>
  (dispatch, getState) => {
    const blockType = blockTypeSelector(getState(), blockId);
    const pageId = currentPageIdWithSubmodelsSelector(getState());
    dispatch(
      trackEventWithContext(AnalyticsEvent.ToggleBlockHeader, {
        isVisible: !hideHeader,
        blockId,
        blockType,
        pageId,
      }),
    );
  };

export const trackCreateSnapshotEvent =
  ({
    orgId,
    layerId,
    mutationId,
    millisecondsFromNow,
    reverseMutationIndex,
  }: {
    orgId: string;
    layerId: LayerId;
    mutationId: string;
    millisecondsFromNow: number;
    reverseMutationIndex: number;
  }): AppThunk =>
  (dispatch) => {
    dispatch(
      trackEventWithContext(AnalyticsEvent.CreateSnapshot, {
        orgId,
        layerId,
        mutationId,
        millisecondsFromNow,
        reverseMutationIndex,
      }),
    );
  };
