import React, { useContext, useEffect, useMemo, useRef } from 'react';

import DriverGridCellCacheProvider from 'components/DriverGridCellCacheProvider/DriverGridCellCacheProvider';
import VirtualizedDriverRow from 'components/DriverGroupSectionContent/VirtualizedDriverRow';
import { DriverCellValueCacheContext } from 'components/DriverTimeSeriesRow/LayerDriverTimeSeriesCell';
import { StickyContext } from 'components/StickyHeader/StickyContext';
import FixedSizeVirtualizedVerticalList from 'components/VirtualizedList/FixedSizeVirtualizedVerticalList';
import { VirtualizedListBaseRefProps } from 'components/VirtualizedList/types';
import { RequestValueProps } from 'components/WebWorkerDataProvider/Context';
import { CELL_HEIGHT_IN_PX, CellType } from 'config/cells';
import { ComparisonColumn } from 'generated/graphql';
import { hashRowCellKey } from 'helpers/cells';
import { getOrderedRowKeysForDrivers } from 'helpers/orderedDriverRowKeys';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useBatchCalculationProcessor } from 'hooks/useBatchCalculationProcessor';
import useBlockContext from 'hooks/useBlockContext';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { clearNavigatingToCell } from 'reduxStore/reducers/pageSlice';
import { comparisonTimePeriodsForBlockSelector } from 'selectors/comparisonTimePeriodsSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { blockDateRangeSelector } from 'selectors/pageDateRangeSelector';
import { navigatingToCellSelector } from 'selectors/pageSelector';
import {
  comparisonLayoutSelector,
  isShowingComparisonDriverHeaderRowSelector,
  timeSeriesComparisonColumnsForBlockSelector,
} from 'selectors/rollupSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

interface Props {
  driverIds: DriverId[];
  groupId?: DriverGroupId;
}

const LIST_OVERSCAN_COUNT = 100;

const getRowHeight = (_index: number) => CELL_HEIGHT_IN_PX;

const FixedSizeVirtualizedDriverRows: React.FC<Props> = ({ driverIds, groupId }) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const { verticalScrollingTargetRef } = useContext(StickyContext);

  const isShowingComparisonDriverHeaderRow = useAppSelector((state) =>
    isShowingComparisonDriverHeaderRowSelector(state, blockId),
  );

  const comparisonLayout = useAppSelector((state) => comparisonLayoutSelector(state, blockId));

  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const comparisonLayers = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const comparisonColumns = useAppSelector((state) =>
    timeSeriesComparisonColumnsForBlockSelector(state, blockId),
  );
  const comparisonTimePeriods = useAppSelector((state) =>
    comparisonTimePeriodsForBlockSelector(state, blockId),
  );

  const isComparingTimePeriods = comparisonTimePeriods.length > 0;

  const layers = useMemo(
    () => (comparisonLayers.length === 0 ? [undefined] : comparisonLayers),
    [comparisonLayers],
  );

  const dateRange = useAppSelector((state) => blockDateRangeSelector(state, blockId));

  const navigatingToCell = useAppSelector(navigatingToCellSelector);
  const batchCalculationProcessor = useBatchCalculationProcessor();
  const previousCalculationRequestsRef = useRef<RequestValueProps[]>([]);
  const listRef = useRef<VirtualizedListBaseRefProps | null>(null);
  const hasCache = useContext(DriverCellValueCacheContext) != null;

  useEffect(() => {
    // on unmount, cancel any pending calculation requests from this component
    return () => {
      if (batchCalculationProcessor != null && previousCalculationRequestsRef.current != null) {
        batchCalculationProcessor.cancelPendingCalculationRequests(
          previousCalculationRequestsRef.current,
        );
      }
    };
  }, [batchCalculationProcessor]);

  const driverRows = useMemo(
    () =>
      getOrderedRowKeysForDrivers({
        driverIds,
        comparisonLayout,
        comparisonColumns,
        layerComparisons: comparisonLayers,
        groupId,
        isShowingComparisonDriverHeaderRow,
        currentLayerId,
        comparisonTimePeriods,
      }),
    [
      driverIds,
      comparisonLayout,
      comparisonColumns,
      comparisonLayers,
      groupId,
      isShowingComparisonDriverHeaderRow,
      currentLayerId,
      comparisonTimePeriods,
    ],
  );

  useEffect(() => {
    if (batchCalculationProcessor == null) {
      return;
    }

    const requests: RequestValueProps[] = [];
    for (let i = 0; i < driverRows.length; i++) {
      const row = driverRows[i];
      if (row == null) {
        // happens when another user of the same org updates the same comparison layout this user is viewing
        continue;
      }

      // we always request driver values for the current layer
      requests.push({
        id: row.driverId,
        type: 'driver',
        dateRange,
        layerId: currentLayerId,
      });

      if (comparisonLayout === 'column-default') {
        layers.forEach((comparisonLayerId) => {
          if (comparisonLayerId == null) {
            return;
          }

          if (
            row.comparisonType === ComparisonColumn.BaselineVersion ||
            row.comparisonType === ComparisonColumn.LatestVersion
          ) {
            // In 'column-default' layout, the columns are the layers and the rows are the comparison types.
            // This check prevents duplicate values from being requested - even if the rows include Variance
            // and Variance %, we don't need to request values for them, since the necessary values will
            // already be requested via the BaselineVersion row.
            // Note that we don't allow the user to de-select the BaselineVersion comparison type in this view.
            return;
          }

          requests.push({
            id: row.driverId,
            type: 'driver',
            dateRange,
            layerId: comparisonLayerId,
          });
        });
      } else {
        if (row.layerId == null) {
          continue;
        }
        requests.push({
          id: row.driverId,
          type: 'driver',
          dateRange,
          layerId: row.layerId,
        });
      }
    }

    batchCalculationProcessor.requestBatchCalculations(requests);
    previousCalculationRequestsRef.current = requests;
  }, [batchCalculationProcessor, comparisonLayout, currentLayerId, dateRange, driverRows, layers]);

  useEffect(() => {
    //don't initiate scroll to driver if we haven't completed navigating to the driver group yet
    if (navigatingToCell == null || !navigatingToCell.hasNavigatedToGroup) {
      return;
    }
    const cellRef = navigatingToCell.cellRef;
    const cellType = cellRef.type;
    if (cellType === CellType.Driver && cellRef.rowKey.groupId === groupId) {
      const driverIndex = driverRows.findIndex((row) => row.driverId === cellRef.rowKey.driverId);
      const isVisible = listRef.current?.isItemVisible(driverIndex);
      if (driverIndex !== -1 && !isVisible) {
        listRef.current?.scrollToItem(driverIndex);
        dispatch(clearNavigatingToCell());
      }
    }
  }, [dispatch, groupId, navigatingToCell, driverRows]);

  const virtualizedList = useMemo(() => {
    const getRowId = (index: number) => {
      const row = driverRows[index];
      if (row == null) {
        return `/${index}`;
      }
      return hashRowCellKey(row);
    };

    return (
      <FixedSizeVirtualizedVerticalList
        ref={listRef}
        verticalScrollTargetRef={verticalScrollingTargetRef}
        getItemHeight={getRowHeight}
        itemKey={getRowId}
        overscanCount={LIST_OVERSCAN_COUNT}
      >
        {driverRows.map((row) => {
          const id = hashRowCellKey(row);
          return (
            <VirtualizedDriverRow
              key={id}
              id={id}
              driverId={row.driverId}
              groupId={row.groupId}
              comparisonRowLayerId={row.layerId}
              comparisonType={row.comparisonType}
              comparisonTimePeriod={row.comparisonTimePeriod}
              isHeaderRow={
                isShowingComparisonDriverHeaderRow &&
                (isComparingTimePeriods ? row.comparisonTimePeriod == null : row.layerId == null)
              }
            />
          );
        })}
      </FixedSizeVirtualizedVerticalList>
    );
  }, [
    driverRows,
    isComparingTimePeriods,
    isShowingComparisonDriverHeaderRow,
    verticalScrollingTargetRef,
  ]);

  if (hasCache) {
    return virtualizedList;
  }

  return <DriverGridCellCacheProvider>{virtualizedList}</DriverGridCellCacheProvider>;
};

export default React.memo(FixedSizeVirtualizedDriverRows);
