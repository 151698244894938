import * as Sentry from '@sentry/nextjs';
import { isEqual } from 'lodash';

import {
  DatabaseConfigDeletionDatasetMutationInput,
  getMutationsForDatabaseConfigDeletion,
} from 'components/DatabasePageContent/DatabaseConfigurationEditor/updateDatabase';
import {
  DATABASE_PROPERTY_TYPE_NAMES,
  DatabaseGroupKey,
  NEW_OBJECT_SPEC_NAME,
} from 'config/businessObjects';
import { MAX_DECIMAL_PLACES } from 'config/decimals';
import { getDatabaseInternalPageType, getDatabasePage } from 'config/internalPages/databasePage';
import {
  BlockCreateInput,
  BlockFilterType,
  BlockSortType,
  BlockType,
  BlockUpdateInput,
  BlocksPageCreateInput,
  BusinessObjectDeleteInput,
  BusinessObjectSpecCreateInput,
  BusinessObjectSpecDeleteInput,
  BusinessObjectSpecUpdateInput,
  BusinessObjectUpdateInput,
  CollectionInput,
  DatabaseConfigInput,
  DatasetMutationInput,
  DimensionCreateInput,
  DimensionRestoreInput,
  DimensionalSubDriverCreateInput,
  DriverCreateInput,
  DriverDeleteInput,
  DriverFormat,
  DriverPropertyCreateData,
  DriverType,
  ValueType,
} from 'generated/graphql';
import { getDimensionObjectFieldDefaultNameRegex } from 'helpers/dimensions';
import { getBlockFilterExpression, getDefaultMappedDriverFormula } from 'helpers/formula';
import { ComputedAttributeProperty } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import { mergeMutations } from 'helpers/mergeMutations';
import { getNewDefaultName } from 'helpers/naming';
import { bulkInsertSortIndexUpdates } from 'helpers/reorderList';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { createDimensionMutation } from 'reduxStore/actions/dimensionMutations';
import { navigateToBlocksPage, navigateToDefaultPage } from 'reduxStore/actions/navigateTo';
import {
  addDefaultLayerGuestControlEntryToPage,
  removeDefaultLayerGuestControlEntryFromPage,
} from 'reduxStore/actions/permissions';
import {
  getMutationThunkAction,
  submitAutoLayerizedMutations,
} from 'reduxStore/actions/submitDatasetMutation';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
  isNumericFieldSpec,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import {
  DimensionalProperty,
  DimensionalPropertyId,
  DriverPropertyId,
} from 'reduxStore/models/collections';
import { DimensionId } from 'reduxStore/models/dimensions';
import { Layer, LayerId } from 'reduxStore/models/layers';
import { setAutoFocus } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppDispatch, AppThunk } from 'reduxStore/store';
import {
  currentInternalPageTypeSelector,
  isCurrentPageDatabasePageSelector,
  orderedDatabasePagesSelector,
} from 'selectors/blocksPagesSelector';
import { blocksPageByInternalPageTypeSelector } from 'selectors/blocksPagesTableSelector';
import { blocksSelector } from 'selectors/blocksSelector';
import { hasBusinessObjectFieldSpecRestrictsSelector } from 'selectors/businessObjectFieldSpecRestrictedSelector';
import {
  businessObjectFieldSpecByIdSelector,
  businessObjectFieldSpecSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { hasBusinessObjectNameRestrictionsSelector } from 'selectors/businessObjectNameRestrictionsSelector';
import { businessObjectSpecFieldNamesSelector } from 'selectors/businessObjectPropertyNamesSelector';
import {
  businessObjectSpecNameSelector,
  businessObjectSpecNamesForLayerSelector,
  businessObjectSpecSelector,
} from 'selectors/businessObjectSpecsSelector';
import { businessObjectsBySpecIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { businessObjectSpecDimensionalPropertyKeysSelector } from 'selectors/collectionBlocksSelector';
import {
  computedAttributePropertySelector,
  dimensionalPropertyEvaluatorSelector,
  dimensionalPropertySelector,
} from 'selectors/collectionSelector';
import { dimensionSelector, dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { driverSelector } from 'selectors/driversSelector';
import { enableEagerSubDriverInitializationSelector } from 'selectors/launchDarklySelector';
import { currentLayerIdSelector, currentLayerSelector } from 'selectors/layerSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { filtersForObjectTableBlockSelector } from 'selectors/objectTableBlockSelector';
import { RawFormula } from 'types/formula';

export const getUpdateBusinessObjectSpecMutationInput = (
  updateInput: BusinessObjectSpecUpdateInput,
  getState: () => RootState,
): {
  updateBusinessObjectSpecs: BusinessObjectSpecUpdateInput[];
  updateBlocks: BlockUpdateInput[] | undefined;
} => {
  const state = getState();
  const { id, deleteFields, updateFields, updateCollection } = updateInput;
  const fieldSpecsById = businessObjectFieldSpecByIdSelector(state);
  updateFields?.forEach((fieldUpdate) => {
    if (fieldUpdate.name != null) {
      return;
    }

    const currField = fieldSpecsById[fieldUpdate.id];
    const currDimId = currField?.type === ValueType.Attribute ? currField.dimensionId : '';
    const currDimName = dimensionSelector(state, currDimId)?.name;
    const matchesCurrDimRegx = getDimensionObjectFieldDefaultNameRegex(currDimName ?? '');
    const newDimName = dimensionSelector(state, fieldUpdate.dimensionId ?? '')?.name;
    if (newDimName != null && currField != null && matchesCurrDimRegx.test(currField.name)) {
      fieldUpdate.name = newDimName;
    }

    if (fieldUpdate.type === ValueType.Number && currField?.numericFormat == null) {
      fieldUpdate.numericFormat = DriverFormat.Auto;
    }
  });

  // N.B. we use empty string to denote unsetting the field
  const updateStartFieldId =
    updateInput.updateStartFieldId === null ? '' : updateInput.updateStartFieldId;

  // Delete any column configs from blocks for deleted fields
  const relatedBlocks = blocksSelector(state).filter(
    (b) =>
      b.type === BlockType.ObjectTable &&
      b.blockConfig.businessObjectSpecId !== null &&
      b.blockConfig.businessObjectSpecId === id,
  );

  let blockUpdates: BlockUpdateInput[] | undefined;
  if (relatedBlocks.length > 0) {
    blockUpdates = [];
    [
      ...(deleteFields ?? []),
      ...(updateCollection?.removeDriverProperties ?? []),
      ...(updateCollection?.removeDimensionalProperties ?? []),
    ].forEach((deletedPropertyId) => {
      relatedBlocks.forEach((block) => {
        const { columns, filterBy, sortBy, groupBy, businessObjectSpecId } = block.blockConfig;
        const updatedConfig = { ...block.blockConfig };

        if (columns != null) {
          const filteredColumns = columns.filter((col) => col.key !== deletedPropertyId);
          updatedConfig.columns = filteredColumns.length > 0 ? filteredColumns : null;
        }

        if (filterBy != null) {
          const filterItems = filtersForObjectTableBlockSelector(state, block.id);
          const filteredFilters = filterItems.filter(
            (filter) => filter.filterKey !== deletedPropertyId,
          );
          if (filterItems.length !== filteredFilters.length) {
            const expression = getBlockFilterExpression(businessObjectSpecId!, filteredFilters);
            // Database blocks can only ever have one filter expression; replace instead of merge.
            updatedConfig.filterBy = [{ filterType: BlockFilterType.Expression, expression }];
          }
        }

        if (sortBy?.object != null) {
          const filteredSorts = sortBy.object.ops.filter(
            (op) => op.propertyId !== deletedPropertyId,
          );
          updatedConfig.sortBy =
            filteredSorts.length > 0
              ? { sortType: BlockSortType.Object, object: { ops: filteredSorts } }
              : null;
        }

        if (groupBy?.objectField?.businessObjectFieldId === deletedPropertyId) {
          updatedConfig.groupBy = null;
        }

        if (!isEqual(updatedConfig, block.blockConfig)) {
          blockUpdates?.push({ id: block.id, blockConfig: updatedConfig });
        }
      });
    });
  }

  return {
    updateBusinessObjectSpecs: [{ ...updateInput, updateStartFieldId }],
    updateBlocks: blockUpdates,
  };
};

const createBusinessObjectSpecMutations = (
  args: BusinessObjectSpecCreateInput,
  dbConfigArgs: DatabaseConfigInput | null,
  getState: () => RootState,
): {
  currentLayerMutation: {
    newDrivers: DriverCreateInput[];
    newBusinessObjectSpecs: BusinessObjectSpecCreateInput[];
    createDatabaseConfigs?: DatabaseConfigInput[];
  };
  defaultLayerMutation: {
    newDimensions: DimensionCreateInput[];
    restoreDimensions: DimensionRestoreInput[];
    newBlocksPages: BlocksPageCreateInput[];
    updateBlocksPages: BlockUpdateInput[];
    newBlocks: BlockCreateInput[];
  };
} => {
  const { id, collection } = args;
  const state = getState();
  const authenticatedUser = authenticatedUserSelector(state);
  const { blocksPageCreateInput, blocksCreateInputs } = getDatabasePage({
    specId: id,
    createdByUserId: authenticatedUser?.id,
  });
  const objectSpecNames = businessObjectSpecNamesForLayerSelector(state);
  const name =
    args.name !== ''
      ? args.name
      : getNewDefaultName(NEW_OBJECT_SPEC_NAME, new Set(objectSpecNames));
  const databasePages = orderedDatabasePagesSelector(state);
  const orderUpdates = bulkInsertSortIndexUpdates(databasePages, [blocksPageCreateInput], 'after');
  const sortIndexUpdates = Object.entries(orderUpdates).map(([pageId, sIndex]) => ({
    id: pageId,
    sortIndex: sIndex,
  }));

  let dimensionCreationsOrRestorations: DatasetMutationInput = {};
  let newDriverCreations: DriverCreateInput[] = [];

  if (collection != null) {
    const dimensionIds: string[] = [];

    if (collection?.dimensionalProperties != null) {
      dimensionCreationsOrRestorations = collection?.dimensionalProperties.reduce((acc, item) => {
        const createdDimensionMutation = createDimensionMutation(state, {
          id: item.dimensionId,
          name: item.name,
        });
        dimensionIds.push(item.dimensionId);
        return mergeMutations(acc, createdDimensionMutation);
      }, {});
    }

    if (collection?.driverProperties != null) {
      const fieldNames: Set<string> = new Set();

      newDriverCreations = collection?.driverProperties.map((item) => {
        const defaultName = `${DATABASE_PROPERTY_TYPE_NAMES[ValueType.Number]}`;
        const newFieldName = getNewDefaultName(defaultName, fieldNames);
        fieldNames.add(newFieldName);
        return {
          id: item.driverId,
          name: newFieldName.trim(),
          format: DriverFormat.Auto,
          valueType: ValueType.Number,
          driverType: DriverType.Dimensional,
          dimensional: {
            dimensionIds,
          },
          driverReferences: [],
        };
      });
    }
  }
  return {
    currentLayerMutation: {
      newDrivers: newDriverCreations,
      newBusinessObjectSpecs: [{ ...args, name }],
      createDatabaseConfigs: dbConfigArgs != null ? [dbConfigArgs] : undefined,
    },
    defaultLayerMutation: {
      newDimensions: dimensionCreationsOrRestorations.newDimensions ?? [],
      restoreDimensions: dimensionCreationsOrRestorations.restoreDimensions ?? [],
      newBlocksPages: [blocksPageCreateInput],
      newBlocks: blocksCreateInputs,
      updateBlocksPages: sortIndexUpdates,
    },
  };
};

const mutationActions = {
  createBusinessObjectSpec: (args: BusinessObjectSpecCreateInput): AppThunk<void> => {
    return (dispatch, getState) => {
      const mutations = createBusinessObjectSpecMutations(args, null, getState);
      dispatch(
        submitAutoLayerizedMutations('create-business-object-spec', [
          mutations.defaultLayerMutation,
          mutations.currentLayerMutation,
        ]),
      );
    };
  },
  deleteBusinessObjectSpec: (deleteInput: BusinessObjectSpecDeleteInput): AppThunk<void> => {
    return (dispatch, getState) => {
      const { id } = deleteInput;
      const state = getState();
      const layer = currentLayerSelector(state);
      const mutation = getMutationsForDatabaseDeletion(layer, id);

      dispatch(submitAutoLayerizedMutations('delete-business-object-spec', [mutation]));
    };
  },
  updateFieldName: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    name: string;
  }>(({ objectSpecId, fieldSpecId, name }, getState) => {
    const state = getState();
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
    if (fieldSpec?.name === name) {
      return null;
    }

    return {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          updateFields: [{ id: fieldSpecId, name }],
        },
      ],
    };
  }),
  updateNumericFormat: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    format: DriverFormat;
  }>(({ objectSpecId, fieldSpecId, format }, getState) => {
    const state = getState();
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
    if (fieldSpec?.type !== ValueType.Number || fieldSpec.numericFormat === format) {
      return null;
    }

    return {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          updateFields: [{ id: fieldSpecId, numericFormat: format }],
        },
      ],
    };
  }),
  updateCurrency: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    currency: string;
  }>(({ objectSpecId, fieldSpecId, currency }) => ({
    updateBusinessObjectSpecs: [
      {
        id: objectSpecId,
        updateFields: [{ id: fieldSpecId, currencyISOCode: currency }],
      },
    ],
  })),
  updateDecimalPlaces: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    decimalPlaces: number | null;
  }>(({ objectSpecId, fieldSpecId, decimalPlaces }) => ({
    updateBusinessObjectSpecs: [
      {
        id: objectSpecId,
        updateFields: [{ id: fieldSpecId, decimalPlaces: decimalPlaces ?? -1 }],
      },
    ],
  })),
  incrementDecrementObjectSpecDecimalPlaces: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    decrement: boolean;
  }>(({ objectSpecId, fieldSpecId, decrement }, getState) => {
    const state = getState();
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });

    if (fieldSpec == null) {
      return null;
    }

    const isNumericField = isNumericFieldSpec(fieldSpec);

    if (!isNumericField) {
      return null;
    }

    const currentDecimalPlaces = fieldSpec.decimalPlaces ?? 0;

    // If incrementing and already at max, ignore
    if (!decrement && currentDecimalPlaces === MAX_DECIMAL_PLACES) {
      return null;
    }

    // If Decrementing and already at 0, ignore
    if (decrement && currentDecimalPlaces === 0) {
      return null;
    }

    const newDecimalPlaces = decrement ? currentDecimalPlaces - 1 : currentDecimalPlaces + 1;

    return {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          updateFields: [{ id: fieldSpecId, decimalPlaces: newDecimalPlaces }],
        },
      ],
    };
  }),
  updateIsRestrictedSpec: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
  }>(({ objectSpecId }, getState) => {
    const state = getState();

    const isRestricted = hasBusinessObjectNameRestrictionsSelector(state, objectSpecId);

    return {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          isRestricted: !isRestricted,
        },
      ],
    };
  }),
  updateIsRestrictedField: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
  }>(({ objectSpecId, fieldSpecId }, getState) => {
    const state = getState();
    const isRestricted = hasBusinessObjectFieldSpecRestrictsSelector(state, fieldSpecId);

    return {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          updateFields: [{ id: fieldSpecId, isRestricted: !isRestricted }],
        },
      ],
    };
  }),
  updatePropertyFormula: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    formula: RawFormula;
  }>(({ objectSpecId, fieldSpecId, formula }, getState) => {
    const state = getState();
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
    if (fieldSpec?.name === name) {
      return null;
    }

    return {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          updateFields: [{ id: fieldSpecId, defaultForecast: { formula: formula.trim() } }],
        },
      ],
    };
  }),
  updateFieldShouldPropagateIntegrationData: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    shouldPropagateIntegrationData: boolean;
  }>(({ objectSpecId, fieldSpecId, shouldPropagateIntegrationData }, _) => {
    return {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          updateFields: [
            { id: fieldSpecId, propagateIntegrationData: shouldPropagateIntegrationData },
          ],
        },
      ],
    };
  }),
  updateFieldShouldIntegrationDataOverridesForecast: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    shouldIntegrationDataOverrideForecast: boolean;
  }>(({ objectSpecId, fieldSpecId, shouldIntegrationDataOverrideForecast }, _) => {
    return {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          updateFields: [
            {
              id: fieldSpecId,
              integrationDataOverridesForecast: shouldIntegrationDataOverrideForecast,
            },
          ],
        },
      ],
    };
  }),
  updateDatabasePropertyType: (args: {
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    newType: ValueType;
    dimensionId?: DimensionId;
  }): AppThunk<void> => {
    return (dispatch, getState) => {
      const { objectSpecId, fieldSpecId, newType, dimensionId } = args;
      const state = getState();
      const businessObjectSpec = businessObjectSpecSelector(state, objectSpecId);
      const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
      if (businessObjectSpec == null || fieldSpec == null) {
        return;
      }
      const isStartField = businessObjectSpec?.startFieldId === fieldSpecId;
      const updateObjectSpecMutation = getUpdateBusinessObjectSpecMutationInput(
        {
          id: objectSpecId,
          updateFields: [
            {
              id: fieldSpecId,
              type: newType,
              ...(newType === ValueType.Attribute ? { dimensionId } : {}),
            },
          ],
          updateStartFieldId: isStartField && newType !== ValueType.Timestamp ? null : undefined,
        },
        getState,
      );
      dispatch(
        submitAutoLayerizedMutations('update-database-property-type', [updateObjectSpecMutation]),
      );
    };
  },
  renameBusinessObjectSpec: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    name: string;
  }>(({ objectSpecId, name }, getState) => {
    const state = getState();
    const specName = businessObjectSpecNameSelector(state, objectSpecId);
    if (specName === name || name.length === 0) {
      return null;
    }

    return {
      updateBusinessObjectSpecs: [
        {
          id: objectSpecId,
          name,
        },
      ],
    };
  }),
  updateBusinessObjectSpec: (args: BusinessObjectSpecUpdateInput): AppThunk<void> => {
    return (dispatch, getState) => {
      const updateObjectSpecMutation = getUpdateBusinessObjectSpecMutationInput(args, getState);
      dispatch(
        submitAutoLayerizedMutations('update-business-object-spec', [updateObjectSpecMutation]),
      );
    };
  },
  createNewDimensionForObjectSpecField: (args: {
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    dimensionName: string;
    dimensionId?: DimensionId;
  }): AppThunk<void> => {
    return (dispatch, getState) => {
      const { objectSpecId, fieldSpecId, dimensionName, dimensionId } = args;
      const state = getState();
      const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
      if (fieldSpec == null || fieldSpec.type !== ValueType.Attribute) {
        return;
      }
      const dimId = dimensionId ?? uuidv4();

      const defaultLayerMutation = createDimensionMutation(state, {
        name: dimensionName,
        id: dimId,
      });

      const restoreId =
        defaultLayerMutation.restoreDimensions != null
          ? defaultLayerMutation.restoreDimensions[0].id
          : undefined;

      const currentLayerMutation = {
        updateBusinessObjectSpecs: [
          {
            id: objectSpecId,
            updateFields: [
              {
                id: fieldSpecId,
                dimensionId: restoreId ?? dimId,
              },
            ],
          },
        ],
      };
      dispatch(
        submitAutoLayerizedMutations('create-dimension-for-object-spec-field', [
          defaultLayerMutation,
          currentLayerMutation,
        ]),
      );
    };
  },
  removeDefaultValue: getMutationThunkAction<{
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    defaultValueKey: string;
  }>(({ objectSpecId, fieldSpecId, defaultValueKey }) => ({
    updateBusinessObjectSpecs: [
      {
        id: objectSpecId,
        updateFields: [
          {
            id: fieldSpecId,
            removeDefaultValues: [defaultValueKey],
          },
        ],
      },
    ],
  })),
};
function navigateToNewDatabasePage(
  mutation: {
    defaultLayerMutation: {
      newBlocksPages: BlocksPageCreateInput[];
    };
  },
  dispatch: AppDispatch,
) {
  const { newBlocksPages } = mutation.defaultLayerMutation;
  if (newBlocksPages != null && newBlocksPages.length === 1) {
    const internalPageId = newBlocksPages[0].id;
    dispatch(addDefaultLayerGuestControlEntryToPage(internalPageId));
    dispatch(navigateToBlocksPage(internalPageId));
  }
}

async function waitForLayerRedirectToComplete(
  getState: () => RootState,
  layerId: LayerId,
  retry: number,
) {
  let routedToLayer = false;
  for (let index = 0; index < retry; index++) {
    if (routedToLayer) {
      return;
    }

    await sleep(500);
    if (window.location.pathname.includes(layerId)) {
      routedToLayer = true;
      break;
    }
  }

  if (!routedToLayer) {
    throw new Error(`Failed to verify layer redirect for ${layerId} after ${retry + 1} attempts`);
  }
}

function sleep(millis: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, millis);
  });
}

export const createBusinessObjectSpecWithExtTableConfig = (
  creatSpecArgs: BusinessObjectSpecCreateInput,
  createDbConfigArgs: DatabaseConfigInput,
): AppThunk<void> => {
  return (dispatch, getState) => {
    const mutation = createBusinessObjectSpecMutations(creatSpecArgs, createDbConfigArgs, getState);
    dispatch(
      submitAutoLayerizedMutations('create-business-object-spec', [
        mutation.defaultLayerMutation,
        mutation.currentLayerMutation,
      ]),
    );
    const state = getState();
    const newLayerId = currentLayerIdSelector(state);
    waitForLayerRedirectToComplete(getState, newLayerId, 10)
      .then(() => {
        navigateToNewDatabasePage(mutation, dispatch);
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };
};
export const createBusinessObjectSpecAndNavigateToPage =
  ({
    name,
    parent = null,
  }: {
    name: string;
    parent?: string | null;
    cb?: (changeId: string) => void;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const id = uuidv4();

    const createObjectSpec: BusinessObjectSpecCreateInput = {
      id,
      name,
      fields: [],
      collection: {
        driverProperties: [],
      },
    };

    const mutation = createBusinessObjectSpecMutations(createObjectSpec, null, getState);

    if (parent !== null && mutation.defaultLayerMutation.newBlocksPages?.length === 1) {
      mutation.defaultLayerMutation.newBlocksPages[0].parent = parent;
    }

    dispatch(
      submitAutoLayerizedMutations('create-business-object-spec', [
        mutation.defaultLayerMutation,
        mutation.currentLayerMutation,
      ]),
    );
    navigateToNewDatabasePage(mutation, dispatch);
  };

export const deleteBusinessObjectSpec =
  (id: BusinessObjectSpecId): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    const internalPageType = getDatabaseInternalPageType(id);
    const blocksPage = blocksPageByInternalPageTypeSelector(state)[internalPageType];

    const shouldNavigateToDefaultPage =
      isCurrentPageDatabasePageSelector(state) &&
      currentInternalPageTypeSelector(state)?.includes(id);
    dispatch(mutationActions.deleteBusinessObjectSpec({ id }));
    if (blocksPage != null) {
      dispatch(removeDefaultLayerGuestControlEntryFromPage(blocksPage.id));
    }
    if (shouldNavigateToDefaultPage) {
      dispatch(navigateToDefaultPage());
    }
  };

export function getMutationsForDatabaseDeletion(
  layer: Layer,
  specId: BusinessObjectSpecId,
): DeleteDatabaseDatasetMutationInput {
  const mutation: DeleteDatabaseDatasetMutationInput = {
    deleteBusinessObjectSpecs: [],
    deleteBusinessObjects: [],
    deleteDrivers: [],
    deleteDatabaseConfigs: [],
    deleteExtQueries: [],
  };
  const spec = layer.businessObjectSpecs.byId[specId];
  if (spec == null) {
    return mutation;
  }
  mutation.deleteBusinessObjectSpecs.push({ id: spec.id });

  for (const row of Object.values(layer.businessObjects.byId)) {
    if (row.specId === spec.id) {
      mutation.deleteBusinessObjects.push({ id: row.id });
    }
  }

  // NOTE: when deleting databases with driver properties, it's important to clean up the drivers
  // Otherwise, these will be stranded and not possible to delete
  mutation.deleteDrivers = (spec.collection?.driverProperties ?? []).map(({ driverId }) => ({
    id: driverId,
  }));

  Object.assign(mutation, getMutationsForDatabaseConfigDeletion(layer, spec.databaseConfigId));
  return mutation;
}

interface DeleteDatabaseDatasetMutationInput extends DatabaseConfigDeletionDatasetMutationInput {
  deleteBusinessObjectSpecs: BusinessObjectSpecDeleteInput[];
  deleteBusinessObjects: BusinessObjectDeleteInput[];
  deleteDrivers: DriverDeleteInput[];
}

export const createNewBusinessObjectSpecField =
  ({
    objectSpecId,
    blockId,
    type,
    groupKey,
    objectId,
    dimensionId,
    newDimensionName,
  }: {
    blockId: BlockId;
    objectSpecId: BusinessObjectSpecId;
    type: ValueType;
    groupKey?: DatabaseGroupKey;
    objectId?: BusinessObjectId;
    dimensionId?: DimensionId;
    newDimensionName?: string;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    let dimensionMutation: DatasetMutationInput | undefined;
    const dimensionsById = dimensionsByIdSelector(state);
    let dimId = dimensionId ?? uuidv4();
    const dimensionName = newDimensionName ?? dimensionsById[dimId]?.name ?? 'New dimension';

    // NB: make a new dimension if there are none if the user chose dimension.
    if (type === ValueType.Attribute && dimensionId == null) {
      dimensionMutation = createDimensionMutation(state, { id: dimId, name: dimensionName });
      if (
        dimensionMutation.restoreDimensions != null &&
        dimensionMutation.restoreDimensions.length === 1
      ) {
        dimId = dimensionMutation.restoreDimensions[0].id;
      }
    }

    const fieldNames = new Set(businessObjectSpecFieldNamesSelector(state, objectSpecId));
    const fieldSpecId = uuidv4();
    const name = type === ValueType.Attribute ? dimensionName : DATABASE_PROPERTY_TYPE_NAMES[type];
    const updateObjectSpecMutation = getUpdateBusinessObjectSpecMutationInput(
      {
        id: objectSpecId,
        addFields: [
          {
            id: fieldSpecId,
            name: getNewDefaultName(name, fieldNames),
            type,
            ...(type === ValueType.Attribute ? { dimensionId: dimId } : {}),
            ...(type === ValueType.Number ? { numericFormat: DriverFormat.Auto } : {}),
          },
        ],
      },
      getState,
    );

    dispatch(
      submitAutoLayerizedMutations('create-business-object-spec-field', [
        updateObjectSpecMutation,
        dimensionMutation ?? {},
      ]),
    );
    dispatch(setAutoFocus({ type: 'objectField', blockId, fieldSpecId, groupKey, objectId }));
  };

export const createNewBusinessObjectSpecDimensionalProperty =
  ({
    objectSpecId,
    blockId,
    groupKey,
    dimensionId,
    newDimensionName,
    isDatabaseKey = false,
  }: {
    blockId: BlockId;
    objectSpecId: BusinessObjectSpecId;
    groupKey?: DatabaseGroupKey;
    dimensionId?: DimensionId;
    newDimensionName?: string;
    isDatabaseKey?: boolean;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    let dimensionMutation: DatasetMutationInput | undefined;
    const dimensionsById = dimensionsByIdSelector(state);
    let dimId = dimensionId ?? uuidv4();
    const dimensionName = newDimensionName ?? dimensionsById[dimId]?.name ?? 'New dimension';

    if (dimensionId == null) {
      dimensionMutation = createDimensionMutation(state, { id: dimId, name: dimensionName });
      if (
        dimensionMutation.restoreDimensions != null &&
        dimensionMutation.restoreDimensions.length === 1
      ) {
        dimId = dimensionMutation.restoreDimensions[0].id;
      }
    }

    const dimensionalPropertyId = uuidv4();
    const updateObjectSpecMutation = getUpdateBusinessObjectSpecMutationInput(
      {
        id: objectSpecId,
        updateCollection: {
          addDimensionalProperties: [
            {
              id: dimensionalPropertyId,
              name: dimensionName,
              dimensionId: dimId,
              isDatabaseKey,
            },
          ],
        },
      },
      getState,
    );

    dispatch(
      submitAutoLayerizedMutations('create-business-object-spec-dimensional-property', [
        dimensionMutation ?? {},
        updateObjectSpecMutation,
      ]),
    );
    dispatch(
      setAutoFocus({
        type: 'objectField',
        blockId,
        fieldSpecId: dimensionalPropertyId,
        groupKey,
      }),
    );
  };

export const autofillDimensionalProperty =
  ({
    objectSpecId,
    propertyId,
    lookupSpecId,
    resultPropertyId,
    searchDimensionPropertyId,
  }: {
    objectSpecId: BusinessObjectSpecId;
    lookupSpecId: BusinessObjectSpecId;
    resultPropertyId: DimensionalPropertyId;
    searchDimensionPropertyId?: DimensionalPropertyId;
    propertyId?: DimensionalPropertyId;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    const lookupSpec = businessObjectSpecSelector(state, lookupSpecId);
    const allLookupSpecProperties = lookupSpec?.collection?.dimensionalProperties ?? [];

    const resultProperty = allLookupSpecProperties.find((p) => p.id === resultPropertyId);
    if (resultProperty == null) {
      return;
    }

    const dimensionalProperty = dimensionalPropertySelector(state, propertyId ?? '');

    // create dimensional property if it doesn't exist, otherwise update it
    const updateCollectionInput: CollectionInput =
      propertyId == null
        ? {
            addDimensionalProperties: [
              {
                id: uuidv4(),
                name: resultProperty.name,
                dimensionId: resultProperty.dimension.id,
                mapping: {
                  lookupSpecId,
                  resultPropertyId,
                  searchDimensionPropertyId,
                },
                // Automapped columns should not be a database key
                isDatabaseKey: false,
              },
            ],
          }
        : {
            updateDimensionalProperties: [
              {
                id: propertyId,
                mapping: {
                  lookupSpecId,
                  resultPropertyId,
                  searchDimensionPropertyId,
                },
              },
            ],
          };
    const shouldUpdateDimensionName =
      propertyId != null &&
      dimensionalProperty != null &&
      dimensionalProperty.name !== resultProperty.name;
    if (shouldUpdateDimensionName) {
      dispatch(renameDimensionalProperty(objectSpecId, propertyId, resultProperty.name));
    }
    const updateObjectSpecMutation = getUpdateBusinessObjectSpecMutationInput(
      {
        id: objectSpecId,
        updateCollection: updateCollectionInput,
      },
      getState,
    );
    dispatch(
      submitAutoLayerizedMutations('autofill-dimensional-property', [updateObjectSpecMutation]),
    );
  };

export const clearAutofillForDimensionalProperty =
  (
    {
      objectSpecId,
      propertyId,
    }: {
      objectSpecId: BusinessObjectSpecId;
      propertyId: DimensionalPropertyId;
    },
    cb?: () => void,
  ): AppThunk =>
  (dispatch, getState) => {
    // Mutation to clear autofill from spec dimensional property
    const updateObjectSpecMutation = getUpdateBusinessObjectSpecMutationInput(
      {
        id: objectSpecId,
        updateCollection: {
          updateDimensionalProperties: [
            {
              id: propertyId,
              removeMapping: true,
            },
          ],
        },
      },
      getState,
    );

    // Get current computed property value for all objects and set the attribute properties
    // on each of of the objects before clearing autofill from the dimensional property
    const state = getState();
    const objects = businessObjectsBySpecIdForLayerSelector(state)[objectSpecId];
    const updateObjectsInputs: BusinessObjectUpdateInput[] = [];
    objects.forEach((object) => {
      // if object has a value for the dimensional property set, don't update it.
      if (
        object.collectionEntry?.attributeProperties?.find(
          (p) => p.dimensionalPropertyId === propertyId,
        )
      ) {
        return;
      }
      // Get computed attribute property for object
      const computedValue = computedAttributePropertySelector(state, {
        objectId: object.id,
        dimensionalPropertyId: propertyId,
      });
      if (computedValue != null) {
        updateObjectsInputs.push({
          id: object.id,
          updateCollectionEntry: {
            addAttributeProperties: [
              {
                dimensionalPropertyId: propertyId,
                attributeId: computedValue.attribute.id,
              },
            ],
          },
        });
      }
    });

    dispatch(
      submitAutoLayerizedMutations('clear-autofill-dimensional-property', [
        updateObjectSpecMutation,
        { updateBusinessObjects: updateObjectsInputs },
      ]),
    );

    // Execute callback if provided
    if (cb) {
      cb();
    }
  };

export const updateDriverPropertyMapping =
  ({
    objectSpecId,
    driverPropertyId,
    driverId,
    updatedMappedDriverId,
    onSuccess,
  }: {
    objectSpecId: string;
    driverPropertyId: string;
    driverId: string;
    updatedMappedDriverId: string;
    onSuccess?: (driverPropertyData: DriverPropertyCreateData) => void;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();

    const businessObjectSpec = businessObjectSpecSelector(state, objectSpecId);
    if (businessObjectSpec == null || businessObjectSpec.collection == null) {
      return;
    }

    const businessObjectSpecName = businessObjectSpec.name;
    const currentDriverProperty = businessObjectSpec.collection.driverProperties.find(
      (p) => p.id === driverPropertyId,
    );
    if (currentDriverProperty == null) {
      return;
    }

    const parentDriver = driverSelector(state, { id: driverId });
    const mappedDriver = driverSelector(state, { id: updatedMappedDriverId });
    const isValidParentDriver = parentDriver?.type === DriverType.Dimensional;
    const isValidMappedDriver =
      mappedDriver?.type === DriverType.Dimensional &&
      parentDriver?.driverMapping?.driverId !== updatedMappedDriverId;
    const canUpdateDriverPropertyMapping = isValidParentDriver && isValidMappedDriver;
    if (!canUpdateDriverPropertyMapping) {
      return;
    }

    const mappedDriverDimensions = mappedDriver?.dimensions ?? [];

    const businessSpecKeyMap = new Set(
      businessObjectSpec?.collection?.dimensionalProperties
        .filter((prop) => prop.isDatabaseKey)
        .map((prop) => prop.dimension.id),
    );

    const subDriverUpdates = parentDriver?.subdrivers.map((subdriver) => {
      const subDriverAttributes = subdriver.attributes.reduce((acc, curr) => {
        acc.set(curr.dimensionId, curr.id);
        return acc;
      }, new Map<string, string>());

      const attrFiltersString = mappedDriverDimensions
        .map((dim) => {
          if (subDriverAttributes.has(dim.id)) {
            return `${dim.id}:${subDriverAttributes.get(dim.id)}`;
          } else if (businessSpecKeyMap.has(dim.id)) {
            return `${dim.id}:NULL`;
          }
          return `${dim.id}:ANY`;
        })
        .join(' ');

      const updatedDriverName = `${businessObjectSpecName} - ${mappedDriver.name}`.trim();
      return {
        id: subdriver.driverId,
        name: updatedDriverName,
        actuals: {
          formula: getDefaultMappedDriverFormula(updatedMappedDriverId, attrFiltersString),
        },
        forecast: {
          formula: getDefaultMappedDriverFormula(updatedMappedDriverId, attrFiltersString),
        },
      };
    });

    const updatedParentDriverName = `${businessObjectSpecName} - ${mappedDriver.name}`.trim();
    const driverMutation: DatasetMutationInput = {
      updateDrivers: [
        {
          id: driverId,
          name: updatedParentDriverName,
          driverMapping: {
            driverId: updatedMappedDriverId,
          },
        },
        ...subDriverUpdates,
      ],
    };

    dispatch(submitAutoLayerizedMutations('update-driver-property-mapping', [driverMutation]));

    if (onSuccess) {
      onSuccess({ id: driverPropertyId, driverId });
    }
  };
export const addExistingDriverToBusinessObjectSpec =
  ({
    objectSpecId,
    driverId,
    type = ValueType.Number,
    onSuccess,
  }: {
    objectSpecId: BusinessObjectSpecId;
    driverId: string;
    type: ValueType;
    onSuccess?: (driverPropertyData: DriverPropertyCreateData) => void;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();

    const dimensionalProperties = businessObjectSpecDimensionalPropertyKeysSelector(
      state,
      objectSpecId,
    );

    const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);

    const businessObjectSpec = businessObjectSpecSelector(state, objectSpecId);
    const selectedDriver = driverSelector(state, { id: driverId });

    if (businessObjectSpec == null) {
      return;
    }
    if (selectedDriver == null || selectedDriver.type !== DriverType.Dimensional) {
      return;
    }

    const newDriverName = `${businessObjectSpec?.name} - ${selectedDriver?.name}`.trim();
    const newDriverId = uuidv4();

    const propertyId = uuidv4();

    const businessSpecPropertyDimensionKeyMap: Record<string, string> = {};
    const businessSpecDimensionIsKeyMap: Set<string> = new Set();
    const sharedDimensionIDMap: Set<string> = new Set();

    const nonSharedDriverDimensionFilters: string[] = [];

    businessObjectSpec.collection?.dimensionalProperties?.forEach((dimProp) => {
      if (dimProp.isDatabaseKey) {
        businessSpecPropertyDimensionKeyMap[dimProp.id] = dimProp.dimension.id;
        businessSpecDimensionIsKeyMap.add(dimProp.dimension.id);
      }
    });

    selectedDriver.dimensions.forEach((dimension) => {
      if (businessSpecDimensionIsKeyMap.has(dimension.id)) {
        sharedDimensionIDMap.add(dimension.id);
      } else {
        nonSharedDriverDimensionFilters.push(`${dimension.id}:ANY`);
      }
    });

    const currentLayerId = currentLayerIdSelector(state);

    const businessObjects = safeObjGet(
      businessObjectsBySpecIdForLayerSelector(state, {
        layerId: currentLayerId,
      })[objectSpecId],
    );
    const newSubDrivers: DimensionalSubDriverCreateInput[] = (businessObjects ?? [])
      .map((object) => {
        const attrFilterList: string[] = [];
        const attributeIds: string[] = [];

        const dimProps = dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(
          object.id,
        );
        const attributes = dimProps.map(({ attribute }) => ({
          id: attribute.id,
          value: attribute.value,
          dimId: attribute.dimensionId,
        }));

        const allKeyAttributesAreEmtpy = Array.from(businessSpecDimensionIsKeyMap).every(
          (dimId) => {
            return attributes.every((attr) => {
              return attr.dimId === dimId && attr.value == null;
            });
          },
        );
        if (allKeyAttributesAreEmtpy) {
          return null;
        }

        for (const attr of attributes ?? []) {
          if (attr.dimId == null) {
            continue;
          }
          attributeIds.push(attr.id);

          // If the attribute is a shared dimension, add it to the attribute filter list
          if (sharedDimensionIDMap.has(attr.dimId)) {
            attrFilterList.push(`${attr.dimId}:${attr.id}`);
          }
        }
        const objDimIds = new Set(attributes.map((attr) => attr.dimId));

        const missingDriverDimensions = Array.from(sharedDimensionIDMap).filter(
          (dimId) => !objDimIds.has(dimId),
        );

        // For each empty key dimension, add a NULL filter
        for (const dim of missingDriverDimensions) {
          attrFilterList.push(`${dim}:NULL`);
        }
        attrFilterList.push(...nonSharedDriverDimensionFilters);

        const selectedDriverId = selectedDriver.id;
        const defaultFormula = getDefaultMappedDriverFormula(
          selectedDriverId,
          attrFilterList.join(' '),
        );

        const selectedDriverFormat = selectedDriver.format;
        const subdriver: DimensionalSubDriverCreateInput = {
          attributeIds,
          driver: {
            id: uuidv4(),
            name: newDriverName,
            valueType: type,
            driverType: DriverType.Basic,
            format: selectedDriverFormat,
            basic: {
              actuals: {
                formula: defaultFormula,
              },
              forecast: {
                formula: defaultFormula,
              },
            },
          },
          existingDriverId: null,
        };
        return subdriver;
      })
      .filter(isNotNull);

    const driverMutation: DatasetMutationInput = {
      newDrivers: [
        {
          id: newDriverId,
          name: newDriverName,
          format: selectedDriver.format,
          valueType: ValueType.Number,
          driverType: DriverType.Dimensional,
          dimensional: {
            dimensionIds: dimensionalProperties.map((d) => d.dimension.id),
            subDrivers: newSubDrivers,
          },
          driverReferences: [],
          driverMapping: {
            driverId,
          },
        },
      ],
    };

    const updateObjectSpecMutation = getUpdateBusinessObjectSpecMutationInput(
      {
        id: objectSpecId,
        updateCollection: {
          addDriverProperties: [
            {
              id: propertyId,
              driverId: newDriverId,
            },
          ],
        },
      },
      getState,
    );

    dispatch(
      submitAutoLayerizedMutations('add-existing-driver-to-business-object-spec', [
        updateObjectSpecMutation,
        driverMutation,
      ]),
    );

    if (onSuccess) {
      onSuccess({ id: propertyId, driverId });
    }
  };

const createBusinessObjectSubDrivers = (
  state: RootState,
  {
    dimensionalProperties,
    objectSpecId,
    newFieldName,
    valueType,
  }: {
    dimensionalProperties: DimensionalProperty[];
    objectSpecId: BusinessObjectSpecId;
    newFieldName: string;
    valueType: ValueType;
  },
): DimensionalSubDriverCreateInput[] => {
  const subDrivers: DimensionalSubDriverCreateInput[] = [];

  // Do not attempt to create subdrivers if there are no key properties.
  const keyProperies = dimensionalProperties.filter((prop) => prop.isDatabaseKey);
  if (keyProperies.length === 0) {
    return [];
  }

  // Create subdriver attribute combinations.
  const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);
  const objectsBySpecId = businessObjectsBySpecIdForLayerSelector(state);
  const objects = objectsBySpecId[objectSpecId] ?? [];

  const uniqueAttributePairs: Record<string, ComputedAttributeProperty[]> = {};
  for (const object of objects) {
    const attributes = dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(
      object.id,
    );
    const key =
      attributes.length === 0
        ? 'none'
        : attributes
            .map((attr) => attr.attribute.id)
            .sort()
            .join('-');
    uniqueAttributePairs[key] ??= attributes;
  }

  for (const attributes of Object.values(uniqueAttributePairs)) {
    const subDriverId = uuidv4();
    const subDriverInput: DriverCreateInput = {
      id: subDriverId,
      name: newFieldName,
      format: DriverFormat.Auto,
      valueType,
      driverType: DriverType.Basic,
      basic: {
        actuals: {
          formula: '',
        },
        forecast: {
          formula: '',
        },
      },
    };
    subDrivers.push({
      driver: subDriverInput,
      attributeIds: attributes.map((attr) => attr.attribute.id),
    });
  }

  return subDrivers;
};

export const createNewBusinessObjectDriverProperty =
  ({
    objectSpecId,
    propertyName,
    driverPropertyId,
    type = ValueType.Number,
    dimensionId,
    renameOldColumn,
    onSuccess,
    newDimensionName,
  }: {
    objectSpecId: BusinessObjectSpecId;
    propertyName?: string;
    driverPropertyId?: string;
    type?: ValueType;
    dimensionId?: DimensionId;
    renameOldColumn?: boolean;
    newDimensionName?: string;
    onSuccess?: (driverPropertyData: DriverPropertyCreateData) => void;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();

    const dimensionalProperties = businessObjectSpecDimensionalPropertyKeysSelector(
      state,
      objectSpecId,
    );
    let fieldNames = businessObjectSpecFieldNamesSelector(state, objectSpecId);

    let renameMutation: DatasetMutationInput = {};
    // this column is being converted to a driver property
    if (renameOldColumn && driverPropertyId != null) {
      renameMutation = {
        updateBusinessObjectSpecs: [
          {
            id: objectSpecId,
            updateFields: [{ id: driverPropertyId, name: `${propertyName}-original-field` }],
          },
        ],
      };
      fieldNames = fieldNames.filter((name) => name !== propertyName);
    }
    let dimensionMutation: DatasetMutationInput | undefined;
    let dimId = type === ValueType.Attribute ? (dimensionId ?? uuidv4()) : undefined;
    const dimension = dimId != null ? dimensionSelector(state, dimId) : undefined;

    // NB: make a new dimension if there are none if the user chose dimension.
    if (type === ValueType.Attribute && newDimensionName != null) {
      dimensionMutation = createDimensionMutation(state, {
        id: dimId,
        name: newDimensionName,
      });
      if (
        dimensionMutation.restoreDimensions != null &&
        dimensionMutation.restoreDimensions.length === 1
      ) {
        dimId = dimensionMutation.restoreDimensions[0].id;
      }
    }

    const defaultName =
      propertyName ??
      dimension?.name ??
      newDimensionName ??
      `${DATABASE_PROPERTY_TYPE_NAMES[type]}`;
    const newFieldName = getNewDefaultName(defaultName, new Set(fieldNames)).trim();

    const driverId = uuidv4();

    const subDrivers = enableEagerSubDriverInitializationSelector(state)
      ? createBusinessObjectSubDrivers(state, {
          dimensionalProperties,
          objectSpecId,
          newFieldName,
          valueType: ValueType.Number,
        })
      : [];

    const dimDriverMutation: DriverCreateInput = {
      id: driverId,
      name: newFieldName,
      format: DriverFormat.Auto,
      valueType: ValueType.Number,
      driverType: DriverType.Dimensional,
      dimensional: {
        dimensionIds: dimensionalProperties.map((d) => d.dimension.id),
        subDrivers,
      },
      driverReferences: [],
    };

    const driverMutations: DatasetMutationInput = {
      newDrivers: [dimDriverMutation],
    };

    const propertyId = driverPropertyId ?? uuidv4();
    const updateObjectSpecMutation = getUpdateBusinessObjectSpecMutationInput(
      {
        id: objectSpecId,
        updateCollection: {
          addDriverProperties: [
            {
              id: propertyId,
              driverId,
            },
          ],
        },
      },
      getState,
    );

    dispatch(
      submitAutoLayerizedMutations('create-business-object-driver-property', [
        updateObjectSpecMutation,
        renameMutation,
        dimensionMutation ?? {},
        driverMutations,
      ]),
    );

    if (onSuccess) {
      onSuccess({ id: propertyId, driverId });
    }
  };

export function renameDimensionalProperty(
  businessObjectSpecId: BusinessObjectSpecId,
  dimensionalPropertyId: DimensionalPropertyId,
  newName: string,
): AppThunk<void> {
  return (dispatch, getState) => {
    const currentLayer = currentLayerSelector(getState());
    const businessObjectSpec = safeObjGet(
      currentLayer.businessObjectSpecs.byId[businessObjectSpecId],
    );
    if (businessObjectSpec == null) {
      return;
    }

    const dimensionalProperty = businessObjectSpec.collection?.dimensionalProperties?.find(
      ({ id }) => id === dimensionalPropertyId,
    );
    if (dimensionalProperty == null) {
      return;
    }

    dispatch(
      submitAutoLayerizedMutations('rename-dimensional-property', [
        {
          updateBusinessObjectSpecs: [
            {
              id: businessObjectSpecId,
              updateCollection: {
                updateDimensionalProperties: [{ id: dimensionalPropertyId, name: newName }],
              },
            },
          ],
        },
      ]),
    );
  };
}

export function renameDriverProperty(
  businessObjectSpecId: BusinessObjectSpecId,
  driverPropertyId: DriverPropertyId,
  newName: string,
): AppThunk<void> {
  return (dispatch, getState) => {
    const currentLayer = currentLayerSelector(getState());
    const businessObjectSpec = safeObjGet(
      currentLayer.businessObjectSpecs.byId[businessObjectSpecId],
    );
    if (businessObjectSpec == null) {
      return;
    }

    const driverProperty = businessObjectSpec.collection?.driverProperties?.find(
      ({ id }) => id === driverPropertyId,
    );
    if (driverProperty == null) {
      return;
    }

    const driver = safeObjGet(currentLayer.drivers.byId[driverProperty.driverId]);
    if (driver == null || driver.type !== DriverType.Dimensional) {
      return;
    }

    // NOTE: renaming a dimensional driver automatically renames all of its subdrivers' drivers
    dispatch(
      submitAutoLayerizedMutations('rename-driver-property', [
        {
          updateDrivers: [{ id: driver.id, name: newName }],
        },
      ]),
    );
  };
}

export const {
  createNewDimensionForObjectSpecField,
  renameBusinessObjectSpec,
  updateBusinessObjectSpec,
  updateFieldName,
  updateNumericFormat,
  updateCurrency,
  updateDecimalPlaces,
  updatePropertyFormula,
  updateFieldShouldPropagateIntegrationData,
  updateDatabasePropertyType,
  updateIsRestrictedField,
  updateIsRestrictedSpec,
  updateFieldShouldIntegrationDataOverridesForecast,
  incrementDecrementObjectSpecDecimalPlaces,
} = mutationActions;
