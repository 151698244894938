import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'ComboChart',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    boxSize: '14px',
  },
  d: 'M12.63 2.10501C12.0483 2.10501 11.5766 2.57668 11.5766 3.15668C11.5766 3.21668 11.5816 3.27668 11.5916 3.33668L9.83331 4.80168C9.71831 4.75834 9.59498 4.73668 9.47165 4.73668C9.26665 4.73668 9.06498 4.79668 8.89165 4.91001L7.36331 4.14668C7.32998 3.59168 6.86998 3.15834 6.31498 3.15668C5.73331 3.15668 5.26165 3.62834 5.26165 4.21001C5.26331 4.23168 5.26331 4.25501 5.26498 4.27834L3.62331 5.37168C3.47831 5.30001 3.31831 5.26334 3.15665 5.26168C2.57665 5.26168 2.10498 5.73334 2.10498 6.31501C2.10498 6.89668 2.57665 7.36668 3.15665 7.36668C3.73831 7.36668 4.20998 6.89668 4.20998 6.31501C4.20998 6.29168 4.20831 6.27001 4.20665 6.24834L5.84831 5.15168C5.99331 5.22334 6.15331 5.26168 6.31498 5.26168C6.51998 5.26168 6.72165 5.20168 6.89498 5.08834L8.42331 5.85168C8.45665 6.40668 8.91665 6.84001 9.47165 6.84168C10.0533 6.84168 10.525 6.37001 10.525 5.78834C10.525 5.72834 10.52 5.66834 10.5083 5.61001L12.2683 4.14334C12.3833 4.18668 12.505 4.21001 12.63 4.21001C13.21 4.21001 13.6816 3.73834 13.6816 3.15668C13.6816 2.57668 13.21 2.10501 12.63 2.10501ZM12.1033 6.31501C11.8133 6.31501 11.5766 6.55001 11.5766 6.84168V13.6817H13.6816V6.84168C13.6816 6.55001 13.4466 6.31501 13.155 6.31501H12.1033ZM5.78831 7.36668C5.49831 7.36668 5.26165 7.60334 5.26165 7.89334V13.6817H7.36665V7.89334C7.36665 7.60334 7.13165 7.36668 6.84165 7.36668H5.78831ZM8.94498 8.42001C8.65498 8.42001 8.41998 8.65501 8.41998 8.94501V13.6817H10.525V8.94501C10.525 8.65501 10.2883 8.42001 9.99831 8.42001H8.94498ZM2.63165 9.47168C2.33998 9.47168 2.10498 9.70834 2.10498 9.99834V13.6817H4.20998V9.99834C4.20998 9.70834 3.97331 9.47168 3.68331 9.47168H2.63165Z',
});
