import { InternalPage } from 'config/internalPages/internalPage';
import { COLUMN_TYPE_TO_DEFAULT_WIDTH, DEFAULT_COLUMN_TYPES } from 'config/modelView';
import { BlockCreateInput, BlockType, BlocksPageCreateInput } from 'generated/graphql';
import { uuidv4 } from 'helpers/uuidv4';
import { BlockId } from 'reduxStore/models/blocks';
import { SubmodelId } from 'reduxStore/models/submodels';
import { UserId } from 'reduxStore/models/user';

// Increment this version if you make a change to the config below.
const SUBMODEL_VERSION = 3;
const MODEL_PAGE_VERSION = 2;
const UNLISTED_DRIVERS_PAGE_VERSION = 0;
const SUBMODEL_PAGE_SUFFIX = 'submodelPage';
const MODEL_PAGE_TYPE = 'model';

export const UNLISTED_DRIVERS_PAGE_TYPE = 'unlistedDrivers';
export const UNLISTED_DRIVERS_PAGE_NAME = 'Unlisted Drivers';
const SUBMODEL_BLOCK_NAME = 'All Drivers';

export const isSubmodelBlockPage = (
  internalPageType: string | null | undefined,
): internalPageType is string =>
  internalPageType != null && internalPageType.endsWith(SUBMODEL_PAGE_SUFFIX);

export const getSubmodelInternalPageType = (submodelId: SubmodelId) => {
  return `${submodelId}-${SUBMODEL_PAGE_SUFFIX}`;
};

export const getSubmodelIdFromInternalPageType = (internalPageType: string): SubmodelId => {
  const suffixStart = internalPageType.indexOf(SUBMODEL_PAGE_SUFFIX);
  return internalPageType.slice(0, suffixStart - 1);
};

export const getModelPage = ({
  pageId,
  name,
  submodelId,
  blockId,
  createdByUserId,
}: {
  pageId: string;
  submodelId: SubmodelId;
  blockId?: BlockId;
  createdByUserId?: UserId;
  name: string | null;
}): InternalPage => {
  const internalPageType = getSubmodelInternalPageType(submodelId);
  const version = SUBMODEL_VERSION;
  const blocksPageCreateInput: BlocksPageCreateInput = {
    id: pageId,
    name: name ?? '',
    createdByUserId,
    internalPageType,
    internalPageVersion: version,
  };
  const blocksCreateInputs: BlockCreateInput[] = [
    {
      id: blockId ?? uuidv4(),
      type: BlockType.DriverGrid,
      name: SUBMODEL_BLOCK_NAME,
      pageId,
      blockConfig: {
        columns: DEFAULT_COLUMN_TYPES.map((key) => ({
          key,
          visible: true,
          width: COLUMN_TYPE_TO_DEFAULT_WIDTH[key],
        })),
      },
    },
  ];
  return { internalPageType, version, blocksPageCreateInput, blocksCreateInputs };
};

// This is actually used for the external data source page, not the All Drivers page
export const modelPage = {
  internalPageType: MODEL_PAGE_TYPE,
  version: MODEL_PAGE_VERSION,
  makeDefault: (): InternalPage => {
    const pageId = uuidv4();
    const internalPageType = MODEL_PAGE_TYPE;
    const version = MODEL_PAGE_VERSION;
    const blocksPageCreateInput: BlocksPageCreateInput = {
      id: pageId,
      name: 'Model',
      internalPageType,
      internalPageVersion: version,
    };
    const blocksCreateInputs: BlockCreateInput[] = [
      {
        id: uuidv4(),
        type: BlockType.DriverGrid,
        name: 'All Drivers',
        pageId,
        blockConfig: {
          columns: DEFAULT_COLUMN_TYPES.map((key) => ({
            key,
            visible: true,
            width: COLUMN_TYPE_TO_DEFAULT_WIDTH[key],
          })),
        },
      },
    ];
    return { internalPageType, version, blocksPageCreateInput, blocksCreateInputs };
  },
};

export const unlistedDriversPage = {
  internalPageType: UNLISTED_DRIVERS_PAGE_TYPE,
  version: UNLISTED_DRIVERS_PAGE_VERSION,
  makeDefault: ({ blockId }: { blockId?: BlockId } = {}): InternalPage => {
    const pageId = uuidv4();
    const internalPageType = UNLISTED_DRIVERS_PAGE_TYPE;
    const version = UNLISTED_DRIVERS_PAGE_VERSION;
    const blocksPageCreateInput: BlocksPageCreateInput = {
      id: pageId,
      name: UNLISTED_DRIVERS_PAGE_NAME,
      internalPageType,
      internalPageVersion: version,
    };
    const blocksCreateInputs: BlockCreateInput[] = [
      {
        id: blockId ?? uuidv4(),
        type: BlockType.DriverGrid,
        name: UNLISTED_DRIVERS_PAGE_NAME,
        pageId,
        blockConfig: {
          columns: DEFAULT_COLUMN_TYPES.map((key) => ({
            key,
            visible: true,
            width: COLUMN_TYPE_TO_DEFAULT_WIDTH[key],
          })),
        },
      },
    ];
    return { internalPageType, version, blocksPageCreateInput, blocksCreateInputs };
  },
};
