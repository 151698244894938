import uniq from 'lodash/uniq';
import React, { Fragment, useMemo } from 'react';

import ActualsChartLine from 'components/DriverChart/ActualsChartLine';
import CurrentPoints from 'components/DriverChart/CurrentPoints';
import ForecastLine from 'components/DriverChart/ForecastLine';
import MilestoneMarker from 'components/DriverChart/MilestoneMarker';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartContext from 'hooks/useChartContext';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { isMultiDriverChart } from 'reduxStore/reducers/helpers/viewOptions';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { pageDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

interface Props {
  inView?: boolean;
}

export const DriverSingleLineChart: React.FC<Props> = ({ inView = true }) => {
  const { driverIds, baselineLayerId } = useChartContext();
  const { blockId } = useBlockContext();
  const firstDriverId = driverIds[0];

  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const multiDriver = isMultiDriverChart(viewOptions ?? {});

  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  const isComparingLayers = comparisonLayerIds.length > 0;

  const renderedLayerIds = useMemo(() => {
    return isComparingLayers ? uniq([baselineLayerId, ...comparisonLayerIds]) : [baselineLayerId];
  }, [baselineLayerId, comparisonLayerIds, isComparingLayers]);

  return (
    <>
      {driverIds.map((driverId) => {
        return (
          <Fragment key={driverId}>
            {renderedLayerIds.map((layerId) => (
              <Fragment key={layerId}>
                <DataRequester driverId={driverId} layerId={layerId} />
                <ForecastLine isActiveChart={false} driverId={driverId} layerId={layerId} />
                <ActualsChartLine driverId={driverId} layerId={layerId} />
                {inView && <CurrentPoints driverId={driverId} layerId={layerId} />}
              </Fragment>
            ))}
          </Fragment>
        );
      })}
      {!multiDriver && <MilestoneMarker driverId={firstDriverId} />}
    </>
  );
};

interface DataRequesterProps {
  driverId: DriverId;
  layerId: LayerId;
}

const DataRequester: React.FC<DataRequesterProps> = React.memo(({ driverId, layerId }) => {
  const dateRange = useAppSelector(pageDateRangeDateTimeSelector);
  useRequestCellValue({
    id: driverId,
    type: 'driver',
    dateRange,
    layerId,
  });

  return null;
});
