import { Modal, ModalOverlay } from '@chakra-ui/react';
import { ReactElement, ReactNode, createContext, useCallback, useContext, useState } from 'react';

export function useModal(): ModalContextValue {
  return useContext(ModalContext);
}
export const GenericModal = () => {
  const { isOpen, content, onCloseModal: onClose } = useContext(ModalContext);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      variant="overlay"
      blockScrollOnMount={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      {isOpen && content}
    </Modal>
  );
};

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [context, setContext] = useState<{ isOpen: boolean; content: ReactElement | null }>({
    isOpen: false,
    content: null,
  });

  const onOpen = useCallback((passedContent: ReactElement) => {
    setContext({ isOpen: true, content: passedContent });
  }, []);

  const onClose = useCallback(() => {
    setContext({ isOpen: false, content: null });
  }, []);

  return (
    <ModalContext.Provider value={{ ...context, openModal: onOpen, onCloseModal: onClose }}>
      {children}
    </ModalContext.Provider>
  );
};

const ModalContext = createContext<ModalContextValue>({
  isOpen: false,
  content: null,
  openModal: () => {},
  onCloseModal: () => {},
});

type ModalContextValue = {
  isOpen: boolean;
  content: ReactElement | null;
  openModal: (content: ReactElement) => void;
  onCloseModal: () => void;
};
