import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path d="M5 5.5H11" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
      <path d="M6 8H10" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
      <path d="M7 10.5H9" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
      <rect
        x="1.6"
        y="1.6"
        width="12.8"
        height="12.8"
        rx="6.4"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </>
  ),
});
