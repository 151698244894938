import React from 'react';

import { getImpactTooltipText } from 'helpers/eventGroups';
import useAppStore from 'hooks/useAppStore';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import {
  eventGroupsByIdForLayerSelector,
  eventsByEventGroupForDriverSelector,
} from 'selectors/eventsAndGroupsSelector';
import { MonthKey } from 'types/datetime';

interface Props {
  driverId: DriverId;
  layerId: LayerId | undefined;
  monthKey: MonthKey;
}

/**
 * N.B. This component is only used to render tooltip content; it doesn't need to stay up to date with
 * changes in state because it's lazily rendered.
 */
const DriverImpactTooltipContent: React.FC<Props> = ({ driverId, layerId, monthKey }) => {
  const store = useAppStore();
  const state = store.getState();
  const eventsByEventGroupForDriver = eventsByEventGroupForDriverSelector(state, {
    id: driverId,
    layerId,
  });
  const eventGroupsById = eventGroupsByIdForLayerSelector(state, { layerId });
  const eventGroupIds = Object.entries(eventsByEventGroupForDriver)
    .filter(([_, events]) => {
      return events.some((event) => {
        const curvePoint = event.customCurvePoints?.[monthKey];
        return curvePoint != null;
      });
    })
    .map(([eventGroupId]) => eventGroupId);
  const content = getImpactTooltipText(eventGroupsById, eventGroupIds);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return content == null ? null : <>{content}</>;
};

export default React.memo(DriverImpactTooltipContent);
