import { useMemo } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import { exhaustiveGuard } from 'helpers/exhaustiveGuard';
import { getNavigationOpts } from 'helpers/navigation';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  navigateToDriverDetailPane,
  navigateToObjectDetailPane,
  navigateToPlanDetailPane,
} from 'reduxStore/actions/navigateTo';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId } from 'reduxStore/models/events';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import TrendArrow from 'vectors/TrendArrow';

type CommonArgs = {
  onOpen?: () => void;
  hideShortcut?: boolean;
};

export type DetailViewInfo =
  | { type: PaneType.Driver | PaneType.DimensionalDriver; id: DriverId }
  | { type: PaneType.Object; id: BusinessObjectId }
  | { type: PaneType.Plan; id: EventGroupId };

type Args = CommonArgs & DetailViewInfo;

const useOpenDetailViewContextMenu = ({
  type,
  id,
  onOpen,
  hideShortcut = false,
}: Args): ContextMenuItem | null => {
  const dispatch = useAppDispatch();
  const item = useMemo<ContextMenuItem>(() => {
    return {
      text: 'Open detail view',
      icon: <TrendArrow boxSize={4} direction="up" />,
      multiSelect: false,
      onSelect: (ev) => {
        const opts = getNavigationOpts(ev);
        switch (type) {
          case PaneType.Driver:
          case PaneType.DimensionalDriver: {
            dispatch(navigateToDriverDetailPane({ driverId: id }, opts));
            break;
          }
          case PaneType.Object: {
            dispatch(navigateToObjectDetailPane({ objectId: id }, opts));
            break;
          }
          case PaneType.Plan: {
            dispatch(navigateToPlanDetailPane({ eventGroupId: id }, opts));
            break;
          }
          default: {
            exhaustiveGuard(type);
            break;
          }
        }
        onOpen?.();
      },
      shortcut: hideShortcut ? undefined : ('details' as const),
    };
  }, [type, id, dispatch, onOpen, hideShortcut]);

  return item;
};

export default useOpenDetailViewContextMenu;
