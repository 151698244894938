import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'Clock',
  viewBox: '0 0 16 16',
  defaultProps: { boxSize: 4 },
  path: (
    <g stroke="currentColor" fill="none">
      <rect x="2.1" y="2.1" width="11.8" height="11.8" rx="5.9" strokeWidth="1.2" />
      <path d="M8 4V8" strokeWidth="1.3" strokeLinecap="round" />
      <path d="M8 8L6 9" strokeWidth="1.3" strokeLinecap="round" />
    </g>
  ),
});
