import { List } from '@chakra-ui/react';
import noop from 'lodash/noop';
import stubFalse from 'lodash/stubFalse';
import React, { Fragment, useContext, useMemo } from 'react';

import {
  MULTI_SELECT_CUSTOM_OPTION_ID,
  MultiSelectMenuItem,
} from 'components/SearchableMultiSelectMenu/SearchableMultiSelectMenu';
import { SearchableMultiSelectMenuContext } from 'components/SearchableMultiSelectMenu/SearchableMultiSelectMenuContext';
import { SelectMenuItemProps } from 'components/SelectMenu/SelectMenu';
import { SelectMenuContext, SelectMenuContextState } from 'components/SelectMenu/SelectMenuContext';
import FixedSizeVirtualizedVerticalList from 'components/VirtualizedList/FixedSizeVirtualizedVerticalList';

const VIRTUALIZATION_THRESHOLD = 100;
const ROW_HEIGHT = 32;

interface Props {
  children: (props: SelectMenuItemProps<MultiSelectMenuItem>) => React.ReactElement | null;
  rowHeight?: number;
  rowWidth?: number;
}

const MultiSelectResultsList: React.FC<Props> = ({ children, rowHeight }) => {
  const { results, itemCount, focusIdx, onSelectIdx, setFocusIdx, customOption } = useContext(
    SearchableMultiSelectMenuContext,
  );
  const selectMenuContextValue = useMemo<SelectMenuContextState>(() => {
    return {
      setFocusIdx,
      setShowSubmenu: noop,
      setIsChildShowingSubmenu: noop,
      onSelectIdx,
      getIsScrolling: stubFalse,
      setIsKeyboardEventsBlocked: noop,
      showSubmenu: false,
      itemCount,
    };
  }, [itemCount, onSelectIdx, setFocusIdx]);

  const getItemHeight = useMemo(() => () => rowHeight ?? ROW_HEIGHT, [rowHeight]);
  const shouldVirtualize = itemCount >= VIRTUALIZATION_THRESHOLD;

  const content = results.map((item, idx) => {
    if (item.id === MULTI_SELECT_CUSTOM_OPTION_ID && customOption != null) {
      return customOption.render({ query: item.name, isFocused: focusIdx === idx, idx });
    } else {
      return (
        <Fragment key={item.id}>
          {item.before}
          {children({ item, isFocused: focusIdx === idx, idx })}
          {item.after}
        </Fragment>
      );
    }
  });

  return (
    <SelectMenuContext.Provider value={selectMenuContextValue}>
      <List variant="select" w="full" px={0}>
        {shouldVirtualize ? (
          <FixedSizeVirtualizedVerticalList getItemHeight={getItemHeight}>
            {content}
          </FixedSizeVirtualizedVerticalList>
        ) : (
          content
        )}
      </List>
    </SelectMenuContext.Provider>
  );
};

export default MultiSelectResultsList;
