import { useBoolean } from '@chakra-ui/react';
import { noop } from 'lodash';
import { ReactNode, createContext, useCallback } from 'react';

import { booleanNoop } from 'types/booleanNoop';

interface ScenarioContext {
  setIsUndraftingLayer: ReturnType<typeof useBoolean>[1];
  setIsEditingCurrentLayerName: ReturnType<typeof useBoolean>[1];
  setIsEditingCurrentLayerDescription: ReturnType<typeof useBoolean>[1];
  isUndraftingLayer: boolean;
  isEditingCurrentLayerName: boolean;
  isEditingCurrentLayerDescription: boolean;
  onUndraftingLayer: () => void;
  onRenameLayer: () => void;
}

export const ScenarioContext = createContext<ScenarioContext>({
  setIsUndraftingLayer: booleanNoop,
  setIsEditingCurrentLayerName: booleanNoop,
  setIsEditingCurrentLayerDescription: booleanNoop,
  isUndraftingLayer: false,
  isEditingCurrentLayerName: false,
  isEditingCurrentLayerDescription: false,
  onUndraftingLayer: noop,
  onRenameLayer: noop,
});

ScenarioContext.displayName = 'ScenarioManagementContext';

export const ScenariosContextProvider = ({ children }: { children: ReactNode }) => {
  const [isEditingCurrentLayerName, setIsEditingCurrentLayerName] = useBoolean();
  const [isEditingCurrentLayerDescription, setIsEditingCurrentLayerDescription] = useBoolean();
  const [isUndraftingLayer, setIsUndraftingLayer] = useBoolean();

  const onUndraftingLayer = useCallback(() => {
    setIsUndraftingLayer.on();
    setIsEditingCurrentLayerName.on();
  }, [setIsEditingCurrentLayerName, setIsUndraftingLayer]);

  const onRenameLayer = useCallback(() => {
    setIsEditingCurrentLayerName.on();
  }, [setIsEditingCurrentLayerName]);

  const value = {
    setIsUndraftingLayer,
    setIsEditingCurrentLayerName,
    setIsEditingCurrentLayerDescription,
    isUndraftingLayer,
    isEditingCurrentLayerName,
    isEditingCurrentLayerDescription,
    onUndraftingLayer,
    onRenameLayer,
  };

  return <ScenarioContext.Provider value={value}>{children}</ScenarioContext.Provider>;
};
