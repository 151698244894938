import { Client } from 'urql';

import {
  DatasetMutationDocument,
  DatasetMutationInput,
  DatasetMutationMutation,
  DatasetMutationMutationVariables,
} from 'generated/graphql';

export const makeMutationGqlRequest = ({
  urqlClient,
  mutation,
  orgId,
  mutationId,
  prevMutationId,
  layerId,
}: {
  urqlClient: Client;
  mutation: DatasetMutationInput;
  orgId: string;
  mutationId: string;
  prevMutationId: string | undefined;
  layerId: string | undefined;
}) => {
  return urqlClient
    .mutation<DatasetMutationMutation, DatasetMutationMutationVariables>(DatasetMutationDocument, {
      orgId,
      mutationId,
      prevMutationId,
      mutation,
      layerId,
    })
    .toPromise();
};
