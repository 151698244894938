import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  COLUMN_TYPE_TO_DEFAULT_WIDTH,
  ExtDriverColumn,
  ExtDriverColumnType,
  STICKY_COLUMN_TYPES,
} from 'config/extDriverTable';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { MonthKey } from 'types/datetime';

type ExtDriverTableData = {
  stickyColumns: ExtDriverColumn[];
  dataColumnWidths: Record<MonthKey, number>;
  extDriverIdsToBeDeleted?: ExtDriverId[];
};

export type DataSources = {
  extDriverTable: ExtDriverTableData;
};

export const initialDataSourcesState: DataSources = {
  extDriverTable: {
    stickyColumns: STICKY_COLUMN_TYPES.map((type, idx, arr) => ({
      type,
      isSticky: true,
      isLastSticky: idx === arr.length - 1,
      width: COLUMN_TYPE_TO_DEFAULT_WIDTH[type],
    })),
    dataColumnWidths: {},
  },
};

const dataSourcesSlice = createSlice({
  name: 'dataSources',
  initialState: initialDataSourcesState,
  reducers: {
    resizeColumn(
      state,
      action: PayloadAction<
        | {
            columnType: Exclude<ExtDriverColumnType, 'data'>;
            width: number;
          }
        | {
            columnType: 'data';
            monthKey: MonthKey;
            width: number;
          }
      >,
    ) {
      const { columnType, width } = action.payload;
      if (columnType === 'data') {
        const { monthKey } = action.payload;
        state.extDriverTable.dataColumnWidths[monthKey] = width;
      } else {
        const column = state.extDriverTable.stickyColumns.find((col) => col.type === columnType);
        if (column != null) {
          column.width = width;
        }
      }
    },
    openDeleteExtDriversDialog(
      state,
      action: PayloadAction<{ extDriverIdsToDelete: ExtDriverId[] }>,
    ) {
      state.extDriverTable.extDriverIdsToBeDeleted = action.payload.extDriverIdsToDelete;
    },
    closeDeleteExtDriversDialog(state) {
      state.extDriverTable.extDriverIdsToBeDeleted = undefined;
    },
  },
});

export const { resizeColumn, openDeleteExtDriversDialog, closeDeleteExtDriversDialog } =
  dataSourcesSlice.actions;

export default dataSourcesSlice.reducer;
