import { useMemo } from 'react';

import { convertColorToBadgeTheme, EXTENDED_THEMES_TO_COLORS } from 'config/dimensionColors';

export interface ThemeColors<TColor = string, TBgColor = string> {
  color: TColor;
  bgColor: TBgColor;
  borderColor?: TBgColor;
  borderWidth?: string;
  _hover?: {
    bgColor?: TBgColor;
    color?: TColor;
  };
}

export type BadgeTheme =
  | keyof typeof BASIC_THEMES_TO_COLORS
  | keyof typeof EXTENDED_THEMES_TO_COLORS;

const BASIC_THEMES_TO_COLORS = {
  black: {
    color: 'gray.600',
    bgColor: 'surface',
    _hover: {
      bgColor: 'gray.100',
      color: 'gray.600',
    },
    borderColor: 'gray.400',
    borderWidth: 'px',
  },
  gray: {
    color: 'gray.600',
    bgColor: 'gray.200',
    _hover: {
      bgColor: 'gray.300',
      color: 'gray.600',
    },
  },
  hoverlessGray: {
    color: 'gray.600',
    bgColor: 'gray.200',
  },
  outlineGray: {
    color: 'gray.600',
    bgColor: 'gray.200',
    _hover: {
      bgColor: 'gray.300',
      color: 'gray.600',
    },
    borderColor: 'gray.300',
    borderWidth: 'px',
  },
  outlineWhite: {
    color: 'gray.600',
    bgColor: 'white',
    _hover: {
      bgColor: 'gray.100',
      color: 'gray.600',
    },
    borderColor: 'gray.300',
    borderWidth: 'px',
  },
  red: {
    color: 'red.600',
    bgColor: 'red.100',
    _hover: {
      bgColor: 'red.100',
      color: 'red.500',
    },
    borderColor: 'red.200',
    borderWidth: 'px',
  },
  white: {
    color: 'gray.600',
    bgColor: 'surface',
    _hover: {
      bgColor: 'white.300',
      color: 'gray.600',
    },
    borderColor: 'gray.300',
    borderWidth: 'px',
  },
  orange: {
    color: 'orange.600',
    bgColor: 'orange.100',
    _hover: {
      color: 'orange.500',
    },
    borderColor: 'orange.200',
    borderWidth: 'px',
  },
  yellow: {
    color: 'yellow.600',
    bgColor: 'yellow.100',
    _hover: {
      color: 'yellow.500',
    },
    borderColor: 'yellow.200',
    borderWidth: 'px',
  },
  green: {
    color: 'green.600',
    bgColor: 'green.100',
    _hover: {
      color: 'green.500',
    },
    borderColor: 'green.200',
    borderWidth: 'px',
  },
  blue: {
    color: 'selection.600',
    bgColor: 'selection.100',
    _hover: {
      color: 'green.500',
    },
    borderColor: 'selection.200',
    borderWidth: 'px',
  },
  ghost: {
    color: 'gray.500',
    bgColor: 'white.300',
    borderColor: 'gray.300',
    borderWidth: 'px',
  },
  error: {
    color: 'red.600',
    bgColor: 'red.100',
  },
  timeSeries: {
    color: 'gray.600',
    bgColor: 'gray.200',
  },
};

export const FALLBACK_THEME: BadgeTheme = 'gray';
export const DELETED_THEME: BadgeTheme = 'red';

export const THEMES_TO_COLORS: { [color in BadgeTheme]: ThemeColors } = {
  ...BASIC_THEMES_TO_COLORS,
  ...EXTENDED_THEMES_TO_COLORS,
};

export function isBadgeTheme(color?: string): color is BadgeTheme {
  if (color == null) {
    return false;
  }
  return THEMES_TO_COLORS[color as BadgeTheme] != null;
}

export const SIZE_TO_PROPS = {
  sm: {
    px: 2,
    lineHeight: 1.6,
    fontSize: 'xxs',
  },
  xs: {
    px: 1,
    lineHeight: 1.4,
    fontSize: 'xxs',
  },
  md: {
    px: 3,
    lineHeight: 1.8,
    fontSize: '0.9rem',
  },
};

export function useBadgeTheme(
  color: string | undefined,
  isPseudoAttribute: boolean,
  isDeleted: boolean,
  fallbackTheme: BadgeTheme = 'hoverlessGray',
): BadgeTheme | ThemeColors {
  return useMemo(() => {
    if (isDeleted) {
      return 'red';
    }

    if (color == null) {
      return isPseudoAttribute ? 'ghost' : fallbackTheme;
    }

    return convertColorToBadgeTheme(color, {
      withBorder: false,
      withHover: false,
      asGhost: isPseudoAttribute,
    });
  }, [color, isDeleted, isPseudoAttribute, fallbackTheme]);
}
