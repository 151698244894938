import { Button, Flex, Grid } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { useCallback, useContext } from 'react';

import DatePickerHeader from 'components/MonthPicker/DatePickerHeader';
import DatePickerPager from 'components/MonthPicker/DatePickerPager';
import DatePickerTextInput from 'components/MonthPicker/DatePickerTextInput';
import { MonthPickerProps } from 'components/MonthPicker/MonthPicker';
import { DatePickerReactContext } from 'config/datePickerContext';
import { shortQuarterFormat, TODAY } from 'helpers/dates';

const quarterToMonth = (quarter: number) => (quarter - 1) * 3 + 1;

const QuarterPicker: React.FC<Omit<MonthPickerProps, 'hideYear'>> = ({
  onDateSelect,
  selected = TODAY,
  minDate,
  maxDate,
  focused = true,
}) => {
  return (
    <DatePickerHeader onDateSelect={onDateSelect} selected={selected} pageBy="year">
      <DatePickerTextInput
        selected={selected}
        focused={focused}
        formatter={shortQuarterFormat}
        minDate={minDate}
        maxDate={maxDate}
      />
      <Flex w="full" px="0.75rem">
        <DatePickerPager />
      </Flex>
      <QuarterPickerBody selected={selected} minDate={minDate} maxDate={maxDate} />
    </DatePickerHeader>
  );
};

interface QuarterPickerBodyProps {
  minDate: DateTime;
  maxDate: DateTime;
  selected?: DateTime;
}

const QuarterPickerBody: React.FC<QuarterPickerBodyProps> = ({ selected, minDate, maxDate }) => {
  const { pagedYear, onDateSelect } = useContext(DatePickerReactContext);

  const isQuarterDisabled = (quarter: number) => {
    const month = quarterToMonth(quarter);
    const startOfMonth = DateTime.utc(pagedYear, month);
    return startOfMonth < minDate || startOfMonth > maxDate;
  };

  const onQuarterSelect = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      const month = quarterToMonth(Number(ev.currentTarget.value));
      const date = DateTime.utc(pagedYear, month);
      onDateSelect(date, { reason: 'button' });
    },
    [onDateSelect, pagedYear],
  );

  return (
    <Grid flex="1 0" templateColumns="repeat(4, 1fr)" columnGap={2} w="16rem" h="7.5rem">
      {[1, 2, 3, 4].map((quarter) => {
        const isSelected =
          selected != null && selected.quarter === quarter && selected.year === pagedYear;
        return (
          <Button
            key={quarter}
            data-selected={isSelected || undefined}
            data-testid={isSelected ? 'selected-quarter' : undefined}
            isDisabled={isQuarterDisabled(quarter)}
            variant="text"
            size="sm"
            py="0.375rem"
            onClick={onQuarterSelect}
            value={quarter}
          >
            Q{quarter}
          </Button>
        );
      })}
    </Grid>
  );
};

export default React.memo(QuarterPicker);
