import { ColorProps, Flex, IconButton, Text } from '@chakra-ui/react';
import React from 'react';

import CloseIcon from 'vectors/Close';
interface Props {
  title?: string;
  description: string;
  icon?: React.ReactNode;
  onClose?: () => void;
  titleColor?: ColorProps['color'];
  dark?: boolean;
}

const Toast: React.FC<Props> = ({
  title,
  description,
  icon,
  titleColor,
  onClose,
  dark = false,
}) => (
  <Flex
    aria-label={title}
    p={2}
    flexDir="column"
    bgColor={dark ? 'black' : 'white'}
    fontWeight="medium"
    fontSize="sm"
    borderRadius="md"
    boxShadow="card"
  >
    <Flex justify="space-between" gap="2">
      {(title != null || icon != null) && (
        <Flex columnGap={2} align="center">
          {icon}
          <Text color={titleColor ?? 'failure'}>{title}</Text>
        </Flex>
      )}
      {onClose && (
        <IconButton
          variant="text"
          borderRadius="md"
          icon={<CloseIcon boxSize={4} />}
          aria-label="Close"
          boxSize={6}
          onClick={onClose}
        />
      )}
    </Flex>
    <Flex pl={icon != null ? 6 : 2} pr={2}>
      <Text color={dark ? 'white' : 'gray.600'}>{description}</Text>
    </Flex>
  </Flex>
);

export default Toast;
