import { Center, IconProps, Image } from '@chakra-ui/react';
import React from 'react';

import { ExtStaticSource } from 'generated/graphql';
import { ExtSource, isFiveTranSource } from 'helpers/integrations';
import useAppSelector from 'hooks/useAppSelector';
import { imageForExtSourceSelector } from 'selectors/activeIntegrationsSelector';
import AirTableIcon from 'vectors/AirTableIcon';
import AmazonS3Icon from 'vectors/AmazonS3';
import AmplitudeIcon from 'vectors/Amplitude';
import Aws from 'vectors/Aws';
import Azure from 'vectors/Azure';
import ChargebeeIcon from 'vectors/Chargebee';
import CoupaIcon from 'vectors/Coupa';
import FacebookIcon from 'vectors/Facebook';
import GoogleAdsIcon from 'vectors/GoogleAdsIcon';
import GoogleAnalyticsIcon from 'vectors/GoogleAnalytics';
import GoogleBigQueryIcon from 'vectors/GoogleBigQuery';
import GoogleSheetsIcon from 'vectors/GoogleSheets';
import HTTPIcon from 'vectors/HTTPIcon';
import HubspotIcon from 'vectors/Hubspot';
import LookerIcon from 'vectors/Looker';
import Netsuite from 'vectors/Netsuite';
import OracleIcon from 'vectors/Oracle';
import PipedriveIcon from 'vectors/Pipedrive';
import PuzzleIcon from 'vectors/Puzzle';
import QuickBooksIcon from 'vectors/QuickBooks';
import RampIcon from 'vectors/Ramp';
import RedshiftIcon from 'vectors/Redshift';
import Rippling from 'vectors/Rippling';
import RunwayAPIIcon from 'vectors/RunwayAPI';
import SalesforceIcon from 'vectors/Salesforce';
import SnowflakeIcon from 'vectors/Snowflake';
import StripeIcon from 'vectors/Stripe';
import TableauIcon from 'vectors/Tableau';
import XeroIcon from 'vectors/Xero';
import ZuoraIcon from 'vectors/Zuora';

interface Props {
  source: ExtSource;
  size: 'xs' | 'sm' | 'md' | 'lg';
}

const defaultProps: IconProps = {
  width: 'full',
  height: 'full',
};

function preferredIcon(source: ExtSource) {
  switch (source) {
    case ExtStaticSource.Amplitude:
      return <AmplitudeIcon {...defaultProps} />;
    case ExtStaticSource.Rippling:
      return <Rippling {...defaultProps} color="selection.500" />;
    case ExtStaticSource.MergeQuickbooksOnline:
      return <QuickBooksIcon {...defaultProps} />;
    case ExtStaticSource.MergeXero:
      return <XeroIcon {...defaultProps} />;
    case ExtStaticSource.PuzzleAccounting:
      return <PuzzleIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoAmazonS3:
      return <AmazonS3Icon {...defaultProps} />;
    case ExtStaticSource.WorkatoAzureBlobStorage:
      return <Azure {...defaultProps} />;
    case ExtStaticSource.WorkatoAirtable:
      return <AirTableIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoChargebeeAdmin:
      return <ChargebeeIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoCoupa:
      return <CoupaIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoFacebookLeadAds:
      return <FacebookIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoGoogleAds:
      return <GoogleAdsIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoGoogleAnalytics:
      return <GoogleAnalyticsIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoGoogleBigQuery:
      return <GoogleBigQueryIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoGoogleSheets:
      return <GoogleSheetsIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoLooker:
      return <LookerIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoOracle:
    case ExtStaticSource.WorkatoOracleFinancialsCloud:
      return <OracleIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoRamp:
      return <RampIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoRedshift:
      return <RedshiftIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoAwsCostExplorer:
      return <Aws {...defaultProps} />;
    case ExtStaticSource.WorkatoHubspotDefault:
    case ExtStaticSource.WorkatoHubspot:
      return <HubspotIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoRest:
      return <HTTPIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoSalesforce:
      return <SalesforceIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoPipedrive:
      return <PipedriveIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoStripe:
      return <StripeIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoTableau:
      return <TableauIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoSnowflake:
      return <SnowflakeIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoZuora:
      return <ZuoraIcon {...defaultProps} />;
    case ExtStaticSource.WorkatoNetsuite:
      return <Netsuite {...defaultProps} />;
    case ExtStaticSource.RunwayApi:
      return <RunwayAPIIcon {...defaultProps} color="selection.500" />;
    default:
      return undefined;
  }
}

function sizeToNum(size: Props['size']) {
  switch (size) {
    case 'xs':
      return 3;
    case 'sm':
      return 4;
    case 'md':
      return 12;
    case 'lg':
      return 14;
    default:
      return 0;
  }
}

const DataSourceLogo: React.FC<Props> = ({ source, size }) => {
  const preferred = preferredIcon(source);
  const image = useAppSelector((state) => imageForExtSourceSelector(state, source));
  const sizeNum = sizeToNum(size);
  const backupImage = <RunwayAPIIcon {...defaultProps} color="selection.500" />;

  let padding = 0;
  const preferredIconRequiresPadding = preferred != null;
  const fiveTranImageRequiresPadding = image != null && isFiveTranSource(source);
  const backupIconRequiresPadding = image == null && preferred == null;
  if (
    size === 'md' &&
    (preferredIconRequiresPadding || fiveTranImageRequiresPadding || backupIconRequiresPadding)
  ) {
    padding = 2;
  }

  let imageElement;
  if (preferred == null && image != null) {
    imageElement = <Image src={image} maxH="100%" maxW="100%" />;
  }

  return (
    <Center flexShrink={0} w={sizeNum} h={sizeNum} p={padding}>
      {preferred ?? imageElement ?? backupImage}
    </Center>
  );
};

export default DataSourceLogo;
