import { INTEGRATION_SOURCE_FIELD_NAME } from 'config/integrations';
import { getInternalPageTypeForLinkedObjectsPage } from 'config/internalPages/dataSourceLinkedObjectsPage';
import {
  BlockFilter,
  BlockFilterInput,
  BlockFilterOperator,
  BlockFilterType,
  BlockType,
  BlockUpdateInput,
  ValueType,
} from 'generated/graphql';
import { getBlockFilterExpression } from 'helpers/formula';
import { ExtSource } from 'helpers/integrations';
import { updateBlock } from 'reduxStore/actions/blockMutations';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { AppThunk } from 'reduxStore/store';
import { blocksPageForInternalPageTypeSelector } from 'selectors/blocksPagesSelector';
import { blockSelector } from 'selectors/blocksSelector';
import { businessObjectSpecSelector } from 'selectors/businessObjectSpecsSelector';
import { dimensionSelector } from 'selectors/dimensionsSelector';
import { FilterItem } from 'types/filtering';

export const updateInternalPageBlockForDataSourceLinkedObjects =
  ({ source, specId }: { source: ExtSource; specId: BusinessObjectSpecId }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const spec = businessObjectSpecSelector(state, specId);
    if (spec == null) {
      return null;
    }
    const page = blocksPageForInternalPageTypeSelector(
      state,
      getInternalPageTypeForLinkedObjectsPage(source, specId),
    );
    if (page == null) {
      return null;
    }
    const tableBlock = page.blockIds
      .map((blockId) => blockSelector(state, blockId))
      .find((block) => {
        if (block == null) {
          return false;
        }
        return block.type === BlockType.ObjectTable;
      });
    if (tableBlock == null) {
      return null;
    }

    const { blockConfig } = blockSelector(state, tableBlock.id) ?? {};
    const blockUpdate: BlockUpdateInput = {
      id: tableBlock.id,
    };

    // Check if we should update filters
    const integrationSourceField = spec.fields.find(
      (f) => f.name === INTEGRATION_SOURCE_FIELD_NAME,
    );

    const filters: FilterItem[] = [];
    if (integrationSourceField != null && integrationSourceField.type === ValueType.Attribute) {
      const dimension = dimensionSelector(state, integrationSourceField.dimensionId);
      const attribute = dimension?.attributes.find((a) => a.value === source);
      if (attribute != null) {
        filters.push({
          filterKey: integrationSourceField.id,
          label: INTEGRATION_SOURCE_FIELD_NAME,
          operator: BlockFilterOperator.Equals,
          expected: [attribute.id],
          valueType: ValueType.Attribute,
          dimensionId: integrationSourceField.dimensionId,
        });
      }
    }
    const newFilters: BlockFilterInput[] =
      filters.length > 0
        ? [
            {
              filterType: BlockFilterType.Expression,
              expression: getBlockFilterExpression(spec.id, filters),
            },
          ]
        : [];
    if (
      tableBlock.blockConfig.filterBy == null ||
      !areFiltersIdentical(tableBlock.blockConfig.filterBy, newFilters)
    ) {
      blockUpdate.blockConfig = {
        ...blockConfig,
        filterBy: newFilters,
      };
    }

    // Check if we should update the name
    const newName = spec.name;
    if (newName !== tableBlock.name) {
      blockUpdate.name = newName;
    }

    if (blockUpdate.name == null && blockUpdate.blockConfig == null) {
      return null;
    }
    return dispatch(updateBlock(blockUpdate));
  };

const areFiltersIdentical = (origFilters: BlockFilter[], newFilters: BlockFilter[]) => {
  if (origFilters.length !== newFilters.length) {
    return false;
  }
  let filtersIdentical = true;
  origFilters.forEach((filter, idx) => {
    if (filter.filterType !== newFilters[idx].filterType) {
      filtersIdentical = false;
    }
    if (filter.filterType === BlockFilterType.Expression) {
      if (filter.expression !== newFilters[idx].expression) {
        filtersIdentical = false;
      }
    }
  });
  return filtersIdentical;
};
