import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'ArrowUpLeft',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M6.5962 3.20365C6.8891 3.49654 6.8891 3.97141 6.5962 4.26431L4.12133 6.73918L6.5962 9.21405C6.8891 9.50695 6.8891 9.98182 6.5962 10.2747C6.30331 10.5676 5.82844 10.5676 5.53554 10.2747L2.53034 7.26951C2.23745 6.97662 2.23745 6.50174 2.53034 6.20885L5.53554 3.20365C5.82844 2.91075 6.30331 2.91075 6.5962 3.20365Z"
        fill="#949DAD"
      />
      <path
        d="M11.0163 13.6895C11.0163 8.68945 11.0163 6.68945 3.51636 6.68945"
        stroke="#949DAD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </>
  ),
});
