import { createCachedSelector } from 're-reselect';

import { BlockId } from 'reduxStore/models/blocks';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { NumericTimeSeries } from 'reduxStore/models/timeSeries';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { fieldSelector } from 'selectors/constSelectors';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { timeSeriesByDriverByLayerIdCreator } from 'selectors/rollupSelector';
import { ParametricSelector } from 'types/redux';

import { comparisonLayerIdsForBlockSelector } from './scenarioComparisonSelector';

type DriverComparisonTimeSeriesParams = {
  driverId: DriverId;
  blockId: BlockId;
};

export const driverTimeSeriesByLayerIdForBlockSelector: ParametricSelector<
  DriverComparisonTimeSeriesParams,
  Record<LayerId, NumericTimeSeries>
> = createCachedSelector(
  fieldSelector<DriverComparisonTimeSeriesParams, 'driverId'>('driverId'),
  (state: RootState, { blockId }: DriverComparisonTimeSeriesParams) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  (state: RootState, { blockId }: DriverComparisonTimeSeriesParams) =>
    baselineLayerIdForBlockSelector(state, blockId),
  (state: RootState) => state,
  function driverTimeSeriesByLayerIdForBlockSelector(
    driverId,
    comparisonLayerIds,
    baselineLayerId,
    state,
  ) {
    const layerIds = [baselineLayerId, ...comparisonLayerIds];
    const result: Record<LayerId, NumericTimeSeries> = {};
    layerIds.forEach((layerId) => {
      result[layerId] = driverTimeSeriesForLayerSelector(state, { id: driverId, layerId });
    });

    return result;
  },
)({
  keySelector: (_state, { driverId, blockId }) => `${driverId};${blockId}`,
  selectorCreator: timeSeriesByDriverByLayerIdCreator,
});
