import { InputGroup, InputLeftElement } from '@chakra-ui/react';
import Joi from 'joi';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import EscapableInput, { SaveHandler } from 'components/EscapableInput/EscapableInput';
import { DatePickerReactContext } from 'config/datePickerContext';
import { SELECT_MENU_IGNORE_KEYSTROKE_CLASS } from 'config/selectMenu';
import { inferDate } from 'helpers/dates';
import Calendar from 'vectors/Calendar';

interface Props {
  selected: DateTime;
  formatter: (dt: DateTime) => string;
  focused: boolean;
  minDate: DateTime;
  maxDate: DateTime;
}

const DatePickerTextInput: React.FC<Props> = ({
  selected,
  formatter,
  focused,
  minDate,
  maxDate,
}) => {
  const { focusOnDatePicker, onDateSelect } = useContext(DatePickerReactContext);

  const onChangeDate: SaveHandler = useCallback(
    (value: string, saveExtraMetadata) => {
      const parsedDate = inferDate(value);
      if (parsedDate != null) {
        onDateSelect(parsedDate, saveExtraMetadata);
      }
    },
    [onDateSelect],
  );

  const validator = useMemo(
    () =>
      Joi.object({
        date: Joi.string().custom((value: string, helper) => {
          if (value === '') {
            return helper.message({ custom: 'Date cannot be empty.' });
          }

          const inferred = inferDate(value);

          if (inferred == null) {
            return helper.message({ custom: 'Date is not valid.' });
          }

          if (inferred < minDate) {
            return helper.message({ custom: 'Date is before minimum start date.' });
          }

          if (inferred > maxDate) {
            return helper.message({ custom: 'Date is after maximum end date.' });
          }

          return true;
        }),
      }),
    [minDate, maxDate],
  );

  const defaultValue = useMemo(() => formatter(selected), [selected, formatter]);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (focused) {
      setTimeout(() => {
        // N.B. we can't just use `autoFocus` on the input because sometimes this
        // input appears in a Tippy that also steals focus on mount so they contend.
        inputRef.current?.focus();
      }, 0);
    }
  }, [focused]);

  return (
    <InputGroup w="full" h="1.75rem" alignItems="center">
      <InputLeftElement h="full">
        <Calendar color="gray.500" />
      </InputLeftElement>
      <EscapableInput
        onCancel={focusOnDatePicker}
        validationSchema={validator}
        name="date"
        ref={inputRef}
        onSave={onChangeDate}
        variant="white"
        w="8rem"
        h="full"
        flex="1 0"
        fontSize="xs"
        fontWeight="medium"
        pl={8}
        defaultValue={defaultValue}
        allowDefaultValueSubmit
        reValidateMode="onChange"
        borderWidth="px"
        className={SELECT_MENU_IGNORE_KEYSTROKE_CLASS}
      />
    </InputGroup>
  );
};

export default React.memo(DatePickerTextInput);
