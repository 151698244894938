import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'PipedriveIcon',
  viewBox: '0 0 500 500',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250.973,37.192c39.326,0,78.665-0.602,117.977,0.179 c35.389,0.702,64.192,14.729,82.792,46.15c9.692,16.373,13.04,34.535,13.08,53.209c0.168,77.321,0.496,154.647-0.224,231.962 c-0.336,35.987-15.021,65.152-47.261,83.659c-16.168,9.281-34.031,12.438-52.349,12.476c-77.321,0.154-154.646,0.403-231.962-0.212 c-31.53-0.251-58.438-11.996-77.653-38.209c-10.303-14.056-15.666-30.025-17.226-47.263c-0.689-7.609-1.014-15.278-1.018-22.921 c-0.038-74.321-0.525-148.649,0.29-222.963c0.42-38.282,16.706-68.419,52.115-86.124c15.512-7.757,32.34-9.96,49.453-10.014 c37.328-0.116,74.656-0.036,111.984-0.036C250.973,37.122,250.973,37.157,250.973,37.192z M171.005,384.035 c18.191,0,35.747,0,54.061,0c0-29.471,0-58.597,0-86.498c8.958,4.257,17.399,9.617,26.619,12.409 c35.077,10.625,72.808-4.829,92.634-37.278c14.613-23.918,18.119-50.188,15.278-77.509c-2.271-21.844-9.653-41.712-24.29-58.59 c-26.487-30.542-81.296-38.488-112.841-3.579c-0.542,0.6-1.2,1.096-1.862,1.691c-4.104-9.86-6.854-13.404-13.732-15.402 c-4.574-1.328-9.473-2.02-14.242-2.111c-13.994-0.271-27.996-0.098-41.994-0.099c-1.941,0-3.882,0-6.078,0 c0,18.189,0,35.755,0,53.862c6.606,0,12.895-0.006,19.182,0.001c7.251,0.008,7.264,0.013,7.264,7.086 c0.004,66.327,0.003,132.654,0.003,198.981C171.005,379.147,171.005,381.294,171.005,384.035z"
      />{' '}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M171.005,384.035c0-2.741,0-4.888,0-7.035 c0-66.327,0.001-132.654-0.003-198.981c0-7.073-0.013-7.078-7.264-7.086c-6.287-0.007-12.575-0.001-19.182-0.001 c0-18.107,0-35.673,0-53.862c2.196,0,4.137,0,6.078,0c13.998,0.001,28-0.172,41.994,0.099c4.77,0.092,9.668,0.783,14.242,2.111 c6.879,1.998,9.628,5.542,13.732,15.402c0.662-0.596,1.32-1.092,1.862-1.691c31.545-34.909,86.354-26.963,112.841,3.579 c14.637,16.878,22.02,36.746,24.29,58.59c2.841,27.321-0.665,53.591-15.278,77.509c-19.826,32.449-57.557,47.903-92.634,37.278 c-9.22-2.792-17.661-8.152-26.619-12.409c0,27.901,0,57.027,0,86.498C206.752,384.035,189.196,384.035,171.005,384.035z M311.421,210.514c-0.017-9.38-1.581-20.055-6.731-29.856c-7.891-15.021-20.184-23.173-37.456-22.728 c-17.1,0.44-29.854,8.373-36.598,24.047c-8.35,19.405-8.355,39.305,0.503,58.634c6.979,15.229,19.074,23.89,36.154,23.84 c17.387-0.051,30.154-8.261,38.038-23.801C309.875,231.691,311.308,222.018,311.421,210.514z"
      />{' '}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M311.421,210.514c-0.113,11.504-1.546,21.178-6.09,30.136 c-7.884,15.54-20.651,23.75-38.038,23.801c-17.08,0.05-29.176-8.61-36.154-23.84c-8.858-19.329-8.853-39.229-0.503-58.634 c6.743-15.674,19.498-23.606,36.598-24.047c17.272-0.445,29.565,7.706,37.456,22.728 C309.84,190.459,311.404,201.134,311.421,210.514z"
      />
    </>
  ),
});
