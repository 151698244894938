import React, { useContext, useEffect } from 'react';

import DriverTimeSeriesCell from 'components/DriverTimeSeriesCell/DriverTimeSeriesCell';
import { TypedTimeSeries } from 'components/DriverTimeSeriesRow/DriverTimeSeriesRow';
import {
  CellColor,
  useDriverCellValueAndColor,
} from 'components/DriverTimeSeriesRow/useDriverCellValue';
import { DriverRowContext } from 'config/driverRowContext';
import { RollupType, ThresholdDirection, ValueType } from 'generated/graphql';
import { TimeSeriesColumn } from 'helpers/rollups';
import { EventId } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { ErrorTimeSeries } from 'reduxStore/models/timeSeries';
import { MonthKey } from 'types/datetime';

interface Props<T extends ValueType> {
  eventIdsByMonthKey?: Record<MonthKey, EventId>;
  label: TimeSeriesColumn['label'];
  uniqueLabel: TimeSeriesColumn['uniqueLabel'];
  monthKeys: TimeSeriesColumn['mks'];
  subLabel: TimeSeriesColumn['subLabel'];
  layerId: LayerId;
  rollupType: RollupType;
  sourceByMonthKeyBaseline: Record<string, 'forecast' | 'actuals'>;
  sourceByMonthKeyLayer: Record<string, 'forecast' | 'actuals'>;
  thresholdDirection: ThresholdDirection;
  valueType: T;
  timeSeriesBaseline: TypedTimeSeries;
  timeSeriesLayer: TypedTimeSeries;
  errorTimeSeriesBaseline: ErrorTimeSeries;
  errorTimeSeriesLayer: ErrorTimeSeries;
  isLoadingByMonthKeyBaseline: Record<string, boolean>;
  isLoadingByMonthKeyLayer: Record<string, boolean>;
}

export const DriverCellValueCacheContext = React.createContext<{
  cache: Record<string, DriverCellValueInfo>;
  saveToCache: (key: string, value: DriverCellValueInfo) => void;
} | null>(null);

export interface DriverCellValueInfo {
  value?: number | string;
  color: CellColor;
}
export const getCacheKey = ({
  driverId,
  layerId,
  monthKey,
}: {
  driverId: string;
  layerId: string;
  label: string;
  monthKey: string;
}) => `${driverId}-${layerId}-${monthKey}`;

const LayerDriverTimeSeriesCell = <T extends ValueType>({
  eventIdsByMonthKey,
  monthKeys,
  subLabel,
  layerId,
  rollupType,
  sourceByMonthKeyBaseline,
  sourceByMonthKeyLayer,
  thresholdDirection,
  timeSeriesBaseline,
  timeSeriesLayer,
  errorTimeSeriesBaseline,
  errorTimeSeriesLayer,
  isLoadingByMonthKeyBaseline,
  isLoadingByMonthKeyLayer,
  label,
  uniqueLabel,
}: Props<T>) => {
  const { driverId, valueType } = useContext(DriverRowContext);

  const firstMonthKey = monthKeys[0];

  const { value, error, isLoading, color } = useDriverCellValueAndColor({
    layerId,
    monthKeys,
    comparisonColumn: subLabel?.column,
    sourceByMonthKeyBaseline,
    sourceByMonthKeyLayer,
    thresholdDirection,
    timeSeriesBaseline,
    timeSeriesLayer,
    errorTimeSeriesBaseline,
    errorTimeSeriesLayer,
    isLoadingByMonthKeyBaseline,
    isLoadingByMonthKeyLayer,
    rollupType,
  });

  const saveToCache = useContext(DriverCellValueCacheContext)?.saveToCache;

  useEffect(() => {
    if (value != null && saveToCache != null) {
      const cacheKey = getCacheKey({
        driverId,
        layerId,
        monthKey: firstMonthKey,
        label: label ?? uniqueLabel,
      });
      saveToCache(cacheKey, { value, color });
    }
  }, [color, driverId, firstMonthKey, label, layerId, saveToCache, uniqueLabel, value]);

  return (
    <DriverTimeSeriesCell
      eventId={eventIdsByMonthKey?.[firstMonthKey]}
      color={color}
      error={error}
      value={value}
      valueType={valueType}
      layerId={layerId}
      monthKey={firstMonthKey}
      rollupType={rollupType}
      subColumn={subLabel}
      isLoading={isLoading}
    />
  );
};

export default React.memo(LayerDriverTimeSeriesCell);
