import React, { ReactNode, useMemo } from 'react';

import { BlockContextValue, BlockReactContext } from 'config/blockContext';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import { ResolvedBlockConfigurationProvider } from 'hooks/useResolvedBlockConfiguration';
import { BlockId } from 'reduxStore/models/blocks';
import { isCurrentPageWritableSelector } from 'selectors/pageAccessResourcesSelector';
import { pageGutterWidthInPxSelector } from 'selectors/pageSelector';

interface Props {
  blockId: BlockId;
  children: ReactNode;
  gutterWidthInPx?: number;
  disableBlockConfigChanges?: boolean;
  readOnly?: boolean;
}

const BlockContext: React.FC<Props> = (props) => {
  const stateGutterWidth = useAppSelector(pageGutterWidthInPxSelector);
  const { readOnly: isReadOnly = false } = props;
  const gutterWidthInPx = props.gutterWidthInPx ?? stateGutterWidth;
  const pageWritable = useAppSelector(isCurrentPageWritableSelector);
  const readOnly = !pageWritable || isReadOnly;

  const contextVal: BlockContextValue = useMemo(
    () => ({
      blockId: props.blockId,
      gutterWidthInPx,
      gutterWidthInPxString: toPxString(gutterWidthInPx),
      readOnly,
      disableBlockConfigChanges: props.disableBlockConfigChanges ?? false,
    }),
    [props.blockId, props.disableBlockConfigChanges, gutterWidthInPx, readOnly],
  );

  return (
    <BlockReactContext.Provider value={contextVal}>
      <ResolvedBlockConfigurationProvider>{props.children}</ResolvedBlockConfigurationProvider>
    </BlockReactContext.Provider>
  );
};

export default BlockContext;
