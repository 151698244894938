import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';

import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import { stopEventPropagation } from 'helpers/browserEvent';
import Arrowhead from 'vectors/Arrowhead';

export const FilterPillEmojiIcon = ({
  bgColor,
  emoji,
}: {
  bgColor: string;
  emoji: string | null;
}) => (
  <Center borderRadius="md" bg={bgColor} w={6} h={6}>
    <EmojiIcon size="sm" emoji={emoji} />
  </Center>
);

type FilterRow = {
  id: string;
  name: string;
  icon?: JSX.Element;
  isSelected: boolean;
};

const HOVER_STYLE = {
  boxShadow: 'none',
  bgColor: 'selection.500',
  color: 'selection.200',
  borderColor: 'selection.200',
};

export interface FilterPillProps {
  name: string;
  emoji?: string | null;
  items: FilterRow[];
  onOpen?: () => void;
  onClose: () => void;
  onChange: (newVals: string[]) => void;
}

export const FilterPill: React.FC<FilterPillProps> = ({
  name,
  emoji,
  items,
  onOpen,
  onClose,
  onChange,
}) => {
  const selectedIds = items.filter((item) => item.isSelected).map((item) => item.id);
  const isActive = selectedIds.length > 0;

  return (
    <Menu variant="light" onClose={onClose} isLazy closeOnSelect={false} onOpen={() => onOpen?.()}>
      <MenuButton
        as={Button}
        variant="light"
        boxShadow="none"
        borderRadius="3xl"
        borderWidth={isActive ? 0 : '1px'}
        fontWeight={isActive ? 'bold' : 'medium'}
        mx={1}
        size="xs"
        maxWidth="20rem"
        _hover={HOVER_STYLE}
        _focus={HOVER_STYLE}
        _active={{ boxShadow: 'none' }}
        _focusVisible={{ boxShadow: 'none' }}
        color={isActive ? 'selection.500' : 'gray.500'}
        bgColor={isActive ? 'selection.200' : 'surface'}
      >
        <Flex alignItems="center">
          {emoji != null && (
            <Text flex="0" mr={2} fontFamily="AppleColorEmoji, system-ui">
              {emoji}
            </Text>
          )}
          <Text noOfLines={1}>{name}</Text>
          <Arrowhead
            color="inherit"
            flexShrink={0}
            ml={1}
            boxSize={2}
            display="inline"
            direction="down"
          />
        </Flex>
      </MenuButton>
      <MenuList onKeyDown={stopEventPropagation}>
        <MenuOptionGroup
          fontSize="xs"
          type="checkbox"
          value={selectedIds}
          onChange={(value: string | string[]) => onChange(Array.isArray(value) ? value : [value])}
        >
          {items.map((item) => (
            <MenuItemOption key={item.id} value={item.id}>
              <Flex w="full" alignItems="center">
                {item.icon != null && (
                  <Box mr={2} color="black">
                    {item.icon}
                  </Box>
                )}
                {item.name}
              </Flex>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
