import { Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import DataSourceLogo from 'components/DataSourceLogo/DataSourceLogo';
import {
  extDriverSourceDisplayName,
  extModelDisplayName,
  linkedAccountDisplayName,
} from 'helpers/integrations';
import useAppSelector from 'hooks/useAppSelector';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { extDriverDisplayPathSelector, extDriverSelector } from 'selectors/extDriversSelector';
import { linkedAccountSelector } from 'selectors/integrationsSelector';

interface Props {
  extDriverId: ExtDriverId;
}

const ExtDriverSelectMenuFooter: React.FC<Props> = ({ extDriverId }) => {
  const extDriver = useAppSelector((state) => extDriverSelector(state, extDriverId));
  const path = useAppSelector((state) => extDriverDisplayPathSelector(state, extDriverId));
  const linkedAccount = useAppSelector((state) =>
    extDriver?.accountId != null
      ? linkedAccountSelector(state, { accountId: extDriver?.accountId, source: extDriver?.source })
      : undefined,
  );

  const sourceText = useMemo(() => {
    if (extDriver == null) {
      return '';
    }
    if (linkedAccount != null) {
      return linkedAccountDisplayName(linkedAccount);
    }
    return `${extDriverSourceDisplayName(extDriver.source)} / ${extModelDisplayName(
      extDriver.model,
    )}`;
  }, [extDriver, linkedAccount]);

  if (extDriver == null) {
    return null;
  }

  const pathWithoutLast = path.slice(0, -1);
  const formattedPath = pathWithoutLast
    .map((section, idx) => {
      if (idx === pathWithoutLast.length - 1) {
        return section;
      }

      return section.length > 8 ? `${section.substring(0, 8)}...` : section;
    })
    .join(' / ');

  return (
    <Flex flexDirection="column" rowGap="px">
      <Flex alignItems="center" flexDirection="row" columnGap={2}>
        <DataSourceLogo source={extDriver.source} size="xs" />
        <Text noOfLines={1} fontWeight="semibold" fontSize="xxs">
          {path[path.length - 1]}
        </Text>
      </Flex>
      <Flex flexDirection="column" fontSize="xxs">
        <Flex justifyContent="flex-start" gap={1} borderRadius="4px" pt={1} pb="px">
          <Text fontWeight="medium" color="gray.500" noOfLines={1} textTransform="capitalize">
            {sourceText}
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" overflow="hidden">
          <Text color="gray.500" fontWeight="medium" noOfLines={1}>
            {formattedPath}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ExtDriverSelectMenuFooter;
