import classnames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import { BackingType } from 'components/AgGridComponents/types/DatabaseColumnDef';
import ObjectAttributeBadge from 'components/ObjectAttributeBadge/ObjectAttributeBadge';
import { BadgeTheme, ThemeColors } from 'config/badge';
import { ImpactType, ValueType } from 'generated/graphql';
import { shortMonthWithDayFormat } from 'helpers/dates';
import { getAttributeValueString } from 'helpers/dimensionalDrivers';
import { getDisplayStringForImpact } from 'helpers/events';
import { getDisplayValue } from 'helpers/formatting';
import useAppSelector from 'hooks/useAppSelector';
import {
  AttributeValue,
  DisplayConfiguration,
  NullableValue,
  NumberValue,
  TimestampValue,
} from 'reduxStore/models/value';
import { attributesByIdSelector } from 'selectors/dimensionsSelector';
import { CalculationError } from 'types/dataset';

import styles from './DisplayValue.module.scss';

type DisplayValueStyle = 'actuals' | 'forecast' | 'new' | 'error';

export interface DisplayValueProps {
  value: NullableValue;
  error?: CalculationError;
  displayConfiguration?: DisplayConfiguration;
  badgeTheme?: BadgeTheme | ThemeColors;
  style?: DisplayValueStyle;
  backingType?: BackingType | undefined;
  // Sometimes we want to pass in the attribute label directly, instead of
  // just passing in the attribute id on value and letting the component look up the label.
  // This is helpful in places like AG grid, where we want to avoid using selectors
  // in cell components.
  attributeValueLabel?: string;
}

// This is useful if we already have a typed value and want to display it
// consistently with the object fields.
const DisplayValue: React.FC<DisplayValueProps> = ({
  value,
  error,
  style = 'actuals',
  badgeTheme,
  displayConfiguration,
  attributeValueLabel,
  backingType = 'objectField',
}) => {
  switch (value.type) {
    case ValueType.Attribute: {
      return attributeValueLabel != null ? (
        <ObjectAttributeBadge label={attributeValueLabel} badgeTheme={badgeTheme} />
      ) : (
        <AttributeDisplayValue value={value.value} badgeTheme={badgeTheme} />
      );
    }
    case ValueType.Timestamp: {
      return <TimestampDisplayValue value={value.value} style={style} />;
    }
    case ValueType.Number: {
      if (displayConfiguration == null) {
        return null;
      }
      return (
        <NumberDisplayValue
          value={value.value}
          error={error}
          displayConfiguration={displayConfiguration}
          style={style}
          backingType={backingType}
        />
      );
    }
    default: {
      return null;
    }
  }
};

interface AttributeDisplayValueProps {
  value: NullableValue<AttributeValue>['value'];
  badgeTheme: React.ComponentProps<typeof ObjectAttributeBadge>['badgeTheme'];
}

const ATTRIBUTE_PLACEHOLDER = 'Add attribute';

export const AttributeDisplayValue: React.FC<AttributeDisplayValueProps> = React.memo(
  ({ value, badgeTheme }) => {
    const attributeId = value;
    const displayValue = useAppSelector((state) =>
      attributeId != null && attributesByIdSelector(state)[attributeId] != null
        ? getAttributeValueString(attributesByIdSelector(state)[attributeId])
        : null,
    );

    if (displayValue == null) {
      return <p className={styles.placeholder}>{ATTRIBUTE_PLACEHOLDER}</p>;
    }

    return <ObjectAttributeBadge label={displayValue} badgeTheme={badgeTheme} />;
  },
);

interface TimestampDisplayValueProps {
  value: NullableValue<TimestampValue>['value'];
  style?: DisplayValueStyle;
}

const TIMESTAMP_PLACEHOLDER = 'Add date';

export const TimestampDisplayValue: React.FC<TimestampDisplayValueProps> = React.memo(
  ({ value, style = 'actuals' }) => {
    const isoTime = value;
    const date = isoTime != null && isoTime !== '' ? DateTime.fromISO(isoTime) : undefined;

    return (
      <p
        data-sentry-mask
        className={classnames({
          [styles.timestamp]: true,
          [styles.placeholder]: !date,
          [styles[style]]: true,
        })}
      >
        {date?.isValid ? shortMonthWithDayFormat(date) : TIMESTAMP_PLACEHOLDER}
      </p>
    );
  },
);

interface NumberDisplayValueProps {
  value: NullableValue<NumberValue>['value'];
  error?: CalculationError;
  style?: DisplayValueStyle;
  displayConfiguration: DisplayConfiguration;
  backingType?: BackingType | undefined;
}

const NumberDisplayValue: React.FC<NumberDisplayValueProps> = React.memo(
  ({ value, error, displayConfiguration, style = 'actuals', backingType = 'driver' }) => {
    const display =
      backingType === 'driver'
        ? getDisplayValue(value, error, { displayConfiguration })
        : getDisplayStringForImpact(
            { type: ValueType.Number, value },
            displayConfiguration,
            {},
            {},
            ImpactType.Set,
          );
    return (
      <p
        data-sentry-mask
        className={classnames(styles.number, styles[style], {
          [styles.italic]: value == null && error == null,
        })}
      >
        {display}
      </p>
    );
  },
);

export default DisplayValue;
