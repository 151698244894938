import React from 'react';

import { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import { KeyboardShortcut } from 'config/shortcuts';

export type ContextMenuItem = {
  text: string;
  onSelect: ((ev?: React.MouseEvent | KeyboardEvent) => void) | null;
  submenu?: SelectItem['submenu'];
  icon?: React.ReactElement;
  meta?: string;
  shortcut?: KeyboardShortcut;
  destructive?: boolean;
  multiSelect?: boolean;
  isEdit?: boolean;
  sectionId?: string;
  isDisabled?: boolean;
  isHidden?: boolean;
  isShown?: boolean;
  keepOpenOnSelect?: boolean;
  isChecked?: boolean;
  checkedStyle?: 'check' | 'switch';
};

export type ContextMenuSelectItem = SelectItem & {
  onSelect: ((ev?: React.MouseEvent | KeyboardEvent) => void) | null;
  keepOpenOnSelect?: boolean;
  isHidden?: boolean;
};

interface Props {
  item: ContextMenuSelectItem;
  idx: number;
  isFocused: boolean;
}

const ContextMenuItem: React.FC<Props> = ({ item, idx, isFocused }) => {
  return (
    <SelectMenuItem
      key={item.id}
      idx={idx}
      name={item.name}
      isFocused={isFocused}
      isDisabled={item.isDisabled}
      isChecked={item.isChecked}
      checkedStyle={item.checkedStyle}
      hasNextMenu={item.hasNextMenu}
      icon={item.icon}
      iconColor={item.iconColor}
      meta={item.meta}
      shortcutHint={item.shortcut ?? null}
      alwaysShowHint
    />
  );
};

export default React.memo(ContextMenuItem);
