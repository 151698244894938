import { ButtonProps } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import JumpToButton from 'components/JumpToButton/JumpToButton';
import { getNavigationOpts } from 'helpers/navigation';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  navigateToDatabasePage,
  navigateToDriverDetailPane,
  navigateToObjectDetailPane,
  navigateToPlanDetailPane,
} from 'reduxStore/actions/navigateTo';

interface Props extends Omit<ButtonProps, 'type'> {
  type: 'driver' | 'object' | 'database' | 'plan';
  id: string;
  openInNewTab?: boolean;
}

function getAriaLabel(type: Props['type']): string {
  switch (type) {
    case 'driver': {
      return 'Open driver details';
    }
    case 'object': {
      return 'Open object details';
    }
    case 'database': {
      return 'Open database details';
    }
    case 'plan': {
      return 'Open plan details';
    }
    default: {
      return '';
    }
  }
}

const OpenDetailsModalButton: React.FC<Props> = ({ type, id, onClick, openInNewTab, ...rest }) => {
  const dispatch = useAppDispatch();
  const capabilitesProvider = useAccessCapabilities();
  const { isOrgMember } = capabilitesProvider;

  const openDetailPane = useCallback<React.MouseEventHandler>(
    (ev) => {
      const opts = { ...getNavigationOpts(ev), ...(openInNewTab ? { openInNewTab } : {}) };
      switch (type) {
        case 'driver': {
          dispatch(navigateToDriverDetailPane({ driverId: id }, opts));
          break;
        }
        case 'object': {
          dispatch(navigateToObjectDetailPane({ objectId: id }, opts));
          break;
        }
        case 'database': {
          dispatch(navigateToDatabasePage({ objectSpecId: id }, opts));
          break;
        }
        case 'plan': {
          dispatch(navigateToPlanDetailPane({ eventGroupId: id }, opts));
          break;
        }
        default: {
          break;
        }
      }
    },
    [openInNewTab, type, dispatch, id],
  );

  const onClickCallback = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      onClick?.(e);
      openDetailPane(e);
    },
    [onClick, openDetailPane],
  );

  if (!isOrgMember) {
    return null;
  }

  return <JumpToButton aria-label={getAriaLabel(type)} onClick={onClickCallback} {...rest} />;
};

export default OpenDetailsModalButton;
