import { createCachedSelector } from 're-reselect';

import { AccessCapabilitiesProvider } from 'helpers/accessCapabilities/AccessCapabilitiesProvider';
import { AccessResource, AccessResourceId } from 'reduxStore/models/accessResources';
import { BlockId } from 'reduxStore/models/blocks';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { accessResourcesByIdSelector } from 'selectors/accessResourcesByIdSelector';
import { accessCapabilityAwareBlockConfigShowRestrictedSelector } from 'selectors/blocksSelector';
import { accessResourceIdSelector } from 'selectors/constSelectors';
import { ParametricSelector } from 'types/redux';

// Whether or not the resource is restricted at all. May still be viewable by the current user.
export const resourceHasAccessEntriesSelector: ParametricSelector<AccessResourceId, boolean> =
  createCachedSelector(
    accessResourceIdSelector,
    accessResourcesByIdSelector,
    (accessResourceId, accessResourcesById) => {
      const accessResourcesWithEntries = Object.values(accessResourcesById).filter(
        (resource) =>
          resource.resourceId === accessResourceId && resource.accessControlList.length > 0,
      );
      return accessResourcesWithEntries.length > 0;
    },
  )((_state, accessResourceId) => accessResourceId);

// Is the resource not meant to be seen by the current user
export const isResourceRestrictedForUser = (
  accessCapabilities: AccessCapabilitiesProvider,
  accessResourcesById: NullableRecord<string, AccessResource>,
  accessResourceId: AccessResourceId,
  showRestrictedForBlock: boolean,
) => {
  if (showRestrictedForBlock) {
    return false;
  }
  const restrictedAccessResources = Object.values(accessResourcesById).filter(
    (resource) =>
      resource?.resourceId === accessResourceId &&
      accessCapabilities.shouldDenyRoleAccessByAccessEntity(resource),
  );
  return restrictedAccessResources.length > 0;
};

export const isResourceRestrictedForUserSelector: ParametricSelector<
  { resourceId: AccessResourceId; blockId: BlockId },
  boolean
> = createCachedSelector(
  accessCapabilitiesSelector,
  (state: RootState, { resourceId }: { resourceId: AccessResourceId; blockId: BlockId }) =>
    accessResourceIdSelector(state, resourceId),
  accessResourcesByIdSelector,
  (state, { blockId }) => accessCapabilityAwareBlockConfigShowRestrictedSelector(state, blockId),
  (accessCapabilities, accessResourceId, accessResourcesById, showRestrictedForBlock) => {
    return isResourceRestrictedForUser(
      accessCapabilities,
      accessResourcesById,
      accessResourceId,
      showRestrictedForBlock,
    );
  },
)((_state, { resourceId, blockId }) => `${blockId},${resourceId}`);
