import { Ref, forwardRef, memo } from 'react';

import VirtualizedListBase from 'components/VirtualizedList/VirtualizedListBase';
import {
  VirtualizedListBaseRefProps,
  VirtualizedVerticalListBaseProps,
} from 'components/VirtualizedList/types';

export type VariableSizeVirtualizedVerticalListProps = {
  estimatedItemHeight: number;
} & Omit<VirtualizedVerticalListBaseProps, 'getItemHeight'>;

const VariableSizeVirtualizedVerticalList = forwardRef(
  (props: VariableSizeVirtualizedVerticalListProps, ref: Ref<VirtualizedListBaseRefProps>) => (
    <VirtualizedListBase
      {...props}
      estimatedItemSize={props.estimatedItemHeight}
      gapPx={props.rowGapPx}
      scrollTargetRef={props.verticalScrollTargetRef}
      ref={ref}
      listType="vertical"
    />
  ),
);

VariableSizeVirtualizedVerticalList.displayName = 'VariableSizeVirtualizedVerticalList';

export default memo(VariableSizeVirtualizedVerticalList);
