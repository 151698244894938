import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'MoreHorizontalIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <circle
        cx="13.6"
        cy="8.00001"
        r="1.4"
        transform="rotate(90 13.6 8.00001)"
        fill="currentColor"
      />
      <circle
        cx="7.9999"
        cy="8.00001"
        r="1.4"
        transform="rotate(90 7.9999 8.00001)"
        fill="currentColor"
      />
      <circle
        cx="2.40005"
        cy="8.00001"
        r="1.4"
        transform="rotate(90 2.40005 8.00001)"
        fill="currentColor"
      />
    </>
  ),
});
