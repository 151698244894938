import { mapValues, pickBy } from 'lodash';
import { createSelector } from 'reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { Layer, LayerId } from 'reduxStore/models/layers';
import { accessibleLayerIdsSelector } from 'selectors/accessibleLayerIdsSelector';
import { currentPageIdWithSubmodelsSelector } from 'selectors/blocksPagesSelector';
import { currentUserPageAccessibleLayersSelector } from 'selectors/layerAccessResourcesSelector';
import { layersSelector } from 'selectors/layerSelector';
import { Selector } from 'types/redux';

const EMPTY_LAYERS: Record<LayerId, Layer> = {};

function isActiveScenarioLayer(layer: Layer | undefined) {
  if (layer == null) {
    return false;
  }
  return !layer.isDeleted && layer.lockedMutationId == null;
}

function isNonDraftScenario(layer: Layer | undefined) {
  if (layer == null) {
    return false;
  }
  return !layer.isDraft && layer.lockedMutationId == null;
}

// layers available in the global (non page scoped) context
export const globalScenariosSelector: Selector<Record<LayerId, Layer>> = createSelector(
  layersSelector,
  accessibleLayerIdsSelector,
  (layersById, accessibleLayerIds) => {
    return pickBy(
      layersById,
      (layer) => accessibleLayerIds.includes(layer.id) && isActiveScenarioLayer(layer),
    );
  },
);

// layers available in the page scoped context
export const pageScenariosSelector: Selector<Record<LayerId, Layer>> = (state) => {
  const pageId = currentPageIdWithSubmodelsSelector(state);
  if (pageId == null) {
    return EMPTY_LAYERS;
  }
  const layersById = currentUserPageAccessibleLayersSelector(state, pageId);
  return pickBy(layersById, isActiveScenarioLayer);
};

export const nonDraftScenariosSelector: Selector<Record<LayerId, Layer>> = createSelector(
  globalScenariosSelector,
  (layersById) => pickBy(layersById, (layer) => !layer.isDraft),
);

export const nonDraftScenarioNamesSelector: Selector<Record<LayerId, string>> =
  createDeepEqualSelector(nonDraftScenariosSelector, (layersById) => mapValues(layersById, 'name'));

// Similar to the above selectors but scoped to the layers available on the current page
const pageNonDraftScenariosSelector: Selector<Record<LayerId, Layer>> = (state) => {
  const pageId = currentPageIdWithSubmodelsSelector(state);

  if (pageId == null) {
    return EMPTY_LAYERS;
  }
  const layersById = currentUserPageAccessibleLayersSelector(state, pageId);
  return pickBy(layersById, isNonDraftScenario);
};

export const pageNonDraftScenarioNamesSelector: Selector<Record<LayerId, string>> =
  createDeepEqualSelector(pageNonDraftScenariosSelector, (layersById) =>
    mapValues(layersById, 'name'),
  );
