import { createIcon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ClosedEye = createIcon({
  displayName: 'ClosedEyeIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'transparent',
    boxSize: 4,
  },
  path: (
    <>
      <path
        d="M3 8C3.00541 8.01126 4.86081 11.375 7.99999 11.375C11.1259 11.375 12.9815 8.03626 13 8"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M12.8849 11.3986C13.105 11.6463 13.4843 11.6686 13.732 11.4484C13.9796 11.2283 14.0019 10.8491 13.7818 10.6014L12.8849 11.3986ZM11.5516 9.89862L12.8849 11.3986L13.7818 10.6014L12.4484 9.10138L11.5516 9.89862Z"
        fill="currentColor"
      />
      <path
        d="M3.11511 11.3986C2.89496 11.6463 2.51572 11.6686 2.26805 11.4484C2.02038 11.2283 1.99807 10.8491 2.21822 10.6014L3.11511 11.3986ZM4.44845 9.89862L3.11511 11.3986L2.21822 10.6014L3.55155 9.10138L4.44845 9.89862Z"
        fill="currentColor"
      />
      <path
        d="M6.5756 13.4205C6.48147 13.7382 6.14759 13.9194 5.82987 13.8253C5.51215 13.7311 5.33091 13.3973 5.42505 13.0795L6.5756 13.4205ZM7.24227 11.1705L6.5756 13.4205L5.42505 13.0795L6.09171 10.8295L7.24227 11.1705Z"
        fill="currentColor"
      />
      <path
        d="M9.4244 13.4205C9.51853 13.7382 9.85241 13.9194 10.1701 13.8253C10.4878 13.7311 10.6691 13.3973 10.575 13.0795L9.4244 13.4205ZM8.75773 11.1705L9.4244 13.4205L10.575 13.0795L9.90829 10.8295L8.75773 11.1705Z"
        fill="currentColor"
      />
    </>
  ),
});

const OpenEye = createIcon({
  displayName: 'OpenEyeIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    color: 'gray.600',
    boxSize: 4,
    fill: 'transparent',
  },
  path: (
    <>
      <path
        d="M3 8C3.00541 8.01126 4.86081 11.375 7.99999 11.375C11.1259 11.375 12.9815 8.03626 13 8"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M3 8C3.00541 7.98874 4.86081 4.625 7.99999 4.625C11.1259 4.625 12.9815 7.96374 13 8"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <circle cx="8" cy="8" r="2" fill="currentColor" />
    </>
  ),
});

export const HideEye = createIcon({
  displayName: 'HideEyeIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    color: 'gray.600',
    boxSize: 4,
    fill: 'transparent',
  },
  path: (
    <>
      <g clipPath="url(#hideEyeClipPath)">
        <path
          d="M0.910417 0.15625L0.15625 0.910417L15.0896 15.8438L15.8438 15.0896L12.6302 11.876C14.8146 10.397 15.8929 8.32887 15.9073 8.30104C15.9678 8.21232 16.0001 8.10739 16 8C15.9999 7.8797 15.9592 7.76297 15.8844 7.66875C15.8288 7.5793 12.7564 2.66667 8 2.66667C6.65059 2.66667 5.43964 3.06677 4.39167 3.6375L0.910417 0.15625ZM8 4.26667C10.0619 4.26667 11.7333 5.93813 11.7333 8C11.7333 8.83838 11.4507 9.60685 10.9833 10.2292L9.83438 9.08021C10.0229 8.76379 10.1333 8.39558 10.1333 8C10.1333 6.82187 9.17813 5.86667 8 5.86667C7.60442 5.86667 7.23621 5.97707 6.91979 6.16563L5.77083 5.01667C6.39315 4.54931 7.16162 4.26667 8 4.26667ZM2.64063 4.84063C1.05555 6.17393 0.146656 7.61975 0.116667 7.66771C0.0412742 7.76207 0.000141568 7.87922 0 8C0.000105292 8.10448 0.0308933 8.20662 0.0885417 8.29375C0.0977116 8.31267 2.67671 13.3333 8 13.3333C8.98667 13.3333 9.87762 13.1592 10.6771 12.8781L9.29688 11.4969C8.89208 11.6473 8.45653 11.7333 8 11.7333C5.93813 11.7333 4.26667 10.0619 4.26667 8C4.26667 7.54347 4.35273 7.10793 4.50313 6.70313L2.64063 4.84063ZM5.87292 8.05938C5.90492 9.18844 6.81052 10.0951 7.93958 10.1271L5.87292 8.05938Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="hideEyeClipPath">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </>
  ),
});

interface Props extends IconProps {
  isOpen?: boolean;
}

const Eye: React.FC<Props> = ({ isOpen = true, ...props }) => {
  if (isOpen) {
    return <OpenEye {...props} />;
  }
  return <ClosedEye {...props} />;
};

export default Eye;
