import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'GroupIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
    fill: 'none',
  },
  path: (
    <>
      <rect
        x="2.5"
        y="2.5"
        width="11"
        height="4.5"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <rect
        x="2.5"
        y="9"
        width="11"
        height="4.5"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </>
  ),
});
