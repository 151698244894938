import groupBy from 'lodash/groupBy';

import { CellType, DriverCellRef } from 'config/cells';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import {
  DatasetMutationInput,
  DimensionalSubDriverCreateInput,
  DriverCreateInput,
  DriverType,
} from 'generated/graphql';
import { isDriverCellRef } from 'helpers/cells';
import { getNameWithCopySuffix } from 'helpers/naming';
import { computeSortIndexUpdates } from 'helpers/reorderList';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import {
  getDuplicateCreateInputFromExistingDriver,
  getSortIndexUpdateMutations,
} from 'reduxStore/actions/driverMutations';
import { submitAutoLayerizedMutations } from 'reduxStore/actions/submitDatasetMutation';
import { BlockId } from 'reduxStore/models/blocks';
import { BasicDriver, Driver, DriverId } from 'reduxStore/models/drivers';
import { setSelectedCells } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import {
  allDriverNamesSelector,
  dimensionalDriversBySubDriverIdSelector,
  dimensionalSubDriverSelector,
  driversByIdForCurrentLayerSelector,
  isDimensionalSubDriverSelector,
} from 'selectors/driversSelector';
import {
  prevailingCellSelectionSelector,
  prevailingSelectedDriverIdsSelector,
} from 'selectors/prevailingCellSelectionSelector';
import { submodelTableGroupsSelector } from 'selectors/submodelTableGroupsSelector';

export const duplicateSelectedDrivers = ({ blockId }: { blockId: BlockId }): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedDriverIds = prevailingSelectedDriverIdsSelector(state);
    const driversById = driversByIdForCurrentLayerSelector(state);
    const driversToDuplicate = selectedDriverIds.map((id) => driversById[id]).filter(isNotNull);
    const cellSelection = prevailingCellSelectionSelector(state);

    if (driversToDuplicate.length === 0) {
      return;
    }

    const basicDrivers: BasicDriver[] = [];
    const subDrivers: Driver[] = [];
    driversToDuplicate.forEach((d) => {
      if (isDimensionalSubDriverSelector(state, d.id)) {
        subDrivers.push(d);
      } else if (d.type === DriverType.Basic) {
        basicDrivers.push(d);
      }
    });

    // Pre-generate uuids for new drivers to make index updates easier
    const existingDriverNames = allDriverNamesSelector(state);
    const newDriverIds = driversToDuplicate.reduce(
      (res, d) => {
        res[d.id] = uuidv4();
        return res;
      },
      {} as Record<DriverId, DriverId>,
    );

    // Generate index updates for where duplicated drivers should be inserted
    const groups = submodelTableGroupsSelector(state);
    let indexUpdates: NullableRecord<string, number> = {};
    driversToDuplicate.forEach((driver) => {
      // When calculating the sort index for a duplicated driver, we place it after the contiguous list
      // of selected drivers in the same group
      const reference = driver.driverReferences?.find((ref) => ref.blockId === blockId);
      const group =
        reference != null
          ? groups.find(
              (g) => g.id === reference.groupId || (g.id == null && reference.groupId == null),
            )
          : null;

      if (group != null) {
        const driverIdsInSameGroup: DriverId[] = group == null ? [] : group.driverIds;
        const driversInSameGroup = driverIdsInSameGroup
          .map((groupDriverId) => driversById[groupDriverId])
          .filter(isNotNull);
        let idx = driverIdsInSameGroup.findIndex((belowId) => belowId === driver.id);
        while (selectedDriverIds.includes(driverIdsInSameGroup[idx])) {
          idx += 1;
        }

        indexUpdates = {
          ...indexUpdates,
          ...computeSortIndexUpdates(driversInSameGroup, {
            toInsertId: newDriverIds[driver.id],
            beforeId: driverIdsInSameGroup[idx],
          }),
        };
      }
    });

    const createInputs: DriverCreateInput[] = [];
    basicDrivers.forEach((d) => {
      const dupName = getNameWithCopySuffix(d.name, existingDriverNames);
      existingDriverNames.push(dupName);
      createInputs.push(
        getDuplicateCreateInputFromExistingDriver({
          name: dupName,
          existingDriver: d,
          newDriverId: newDriverIds[d.id],
          blockId,
        }),
      );
    });

    const dimDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(state);
    const groupedByDimDriverId = groupBy(subDrivers, (d) => {
      const dimDriver = dimDriversBySubDriverId[d.id];
      return dimDriver?.id ?? null;
    });
    Object.entries(groupedByDimDriverId)
      .filter(([dimDriverId]) => isNotNull(dimDriverId))
      .forEach(([dimDriverId, subDriversForDim]) => {
        const dimDriver = driversById[dimDriverId];
        if (dimDriver == null || dimDriver.type !== DriverType.Dimensional) {
          return;
        }

        const dupName = getNameWithCopySuffix(dimDriver.name, existingDriverNames);

        const subDriverCreateInputs: DimensionalSubDriverCreateInput[] = subDriversForDim
          .map((d) => {
            const subDriver = dimensionalSubDriverSelector(state, d.id);
            if (subDriver == null) {
              return null;
            }

            const input = getDuplicateCreateInputFromExistingDriver({
              name: dupName,
              existingDriver: d,
              newDriverId: newDriverIds[d.id],
              blockId,
            });
            return {
              attributeIds: subDriver?.attributes.map((a) => a.id),
              driver: input,
            };
          })
          .filter(isNotNull);

        existingDriverNames.push(dupName);
        const newDimDriverInput = getDuplicateCreateInputFromExistingDriver({
          name: dupName,
          existingDriver: dimDriver,
          newDriverId: uuidv4(),
          blockId,
        });
        newDimDriverInput.dimensional = {
          ...newDimDriverInput.dimensional,
          subDrivers: subDriverCreateInputs,
        };

        createInputs.push(newDimDriverInput);
      });

    const mutation: DatasetMutationInput = {
      newDrivers: createInputs,
      updateDrivers: getSortIndexUpdateMutations({
        state,
        blockId,
        sortIndexUpdates: indexUpdates,
        driverCreateInputs: createInputs,
      }),
    };

    dispatch(submitAutoLayerizedMutations('duplicate-selected-drivers', [mutation]));
    const activeDriverCell = cellSelection?.activeCell;
    if (cellSelection != null && isDriverCellRef(activeDriverCell)) {
      const basicDriverInputs = createInputs.filter(
        (input) => input.driverType === DriverType.Basic,
      );
      const dimensionalDriverInputs = createInputs
        .map((input) => input.dimensional?.subDrivers?.map((sub) => sub.driver).filter(isNotNull))
        .filter(isNotNull)
        .flat();
      const selectedCells: DriverCellRef[] = [...basicDriverInputs, ...dimensionalDriverInputs].map(
        (input) => ({
          type: CellType.Driver,
          rowKey: {
            ...activeDriverCell.rowKey,
            driverId: input.id,
          },
          columnKey: { columnType: NAME_COLUMN_TYPE, columnLayerId: undefined },
        }),
      );
      dispatch(
        setSelectedCells({
          type: 'cell',
          blockId: cellSelection.blockId,
          activeCell: selectedCells[0],
          selectedCells,
        }),
      );
    }
  };
};
