import { ColumnDiffType, ColumnType, DriverDiffType } from 'selectors/scenarioComparisonSelector';

export interface ComparisonColumnInfo {
  type: ColumnType;
  id: string;
  diffTypes: ColumnDiffType[];
}

export interface DriverComparisonColumnInfo {
  driverId: string;
  diffTypes: DriverDiffType[];
}

export const getDisplayableDiffTypes = (column: ComparisonColumnInfo) => {
  const diffTypes = column.diffTypes;
  return diffTypes.filter((diffType) => diffType !== 'newColumn');
};

export const getColumnDiffTypeText = (diffType: ColumnDiffType) => {
  switch (diffType) {
    case 'name':
      return 'Name';
    case 'newColumn':
    case 'deletedColumn':
      return '';
    case 'currency':
      return 'Currency';
    case 'formula':
      return 'Formula';
    case 'decimalPlaces':
      return 'Decimal places';
    case 'numericFormat':
      return 'Numeric format';
    case 'valueType':
      return 'Value type';
    case 'dimension':
      return 'Dimension change';
    case 'nameAnonymization':
    case 'fieldAnonymization':
      return 'Data anonymization';
    case 'defaultValues':
      return 'Default values';
    case 'databaseKey':
      return 'Used to segment database';
    default:
      return '';
  }
};

export const getDriverDiffTypeText = (diffType: DriverDiffType) => {
  switch (diffType) {
    case 'updated_name':
      return 'Name';
    case 'updated_coloring':
      return 'Coloring';
    case 'updated_format':
      return 'Format';
    case 'updated_currency':
      return 'Currency';
    case 'updated_decimal_places':
      return 'Precision';
    case 'updated_lever_type':
      return 'KPI';
    case 'updated_references':
      return 'References';
    default:
      return '';
  }
};
