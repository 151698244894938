import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'Bolding',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <path d="M13.105 8.29479C12.805 7.85729 12.3938 7.51229 11.8775 7.26478C12.1963 7.03978 12.4563 6.77978 12.6563 6.48729C12.9738 6.02103 13.135 5.43728 13.135 4.75229C13.135 4.12979 13.0275 3.59478 12.8175 3.16103C12.6038 2.71979 12.2975 2.36103 11.9075 2.09478C11.5288 1.83603 11.0725 1.64729 10.5488 1.53603C10.045 1.42854 9.48128 1.37354 8.87503 1.37354H3.06878C2.89253 1.37354 2.74878 1.51604 2.74878 1.69354V14.031C2.74878 14.2073 2.89253 14.351 3.06878 14.351H9.04753C9.62628 14.351 10.1975 14.2773 10.745 14.1323C11.3038 13.9848 11.8088 13.7535 12.2475 13.446C12.6963 13.1298 13.0575 12.7173 13.3225 12.2198C13.5875 11.721 13.7225 11.126 13.7225 10.4548C13.7225 9.62103 13.5138 8.89478 13.105 8.29479ZM5.78128 3.80103H8.32253C8.56378 3.80103 8.79753 3.82103 9.02253 3.86103C9.24628 3.90229 9.44503 3.97478 9.61878 4.07729C9.79128 4.18103 9.92878 4.32604 10.0325 4.50979C10.1363 4.69479 10.1888 4.93104 10.1888 5.21854C10.1888 5.73729 10.0325 6.11103 9.72128 6.34103C9.41003 6.57229 9.01253 6.68728 8.52878 6.68728H5.78128V3.80103ZM10.525 11.0935C10.4163 11.301 10.2688 11.4648 10.085 11.586C9.90003 11.7073 9.68753 11.7935 9.44503 11.846C9.20378 11.8973 8.95003 11.9235 8.68503 11.9235H5.78128V8.53604H8.73628C9.32503 8.53604 9.79628 8.67229 10.1538 8.94229C10.5113 9.21353 10.69 9.66603 10.69 10.2998C10.6888 10.621 10.6338 10.886 10.525 11.0935Z" />
  ),
});
