import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'PieChart',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    boxSize: '14px',
  },
  d: 'M8.53331 1.60001V7.46667H14.4C14.4 4.22667 11.7733 1.60001 8.53331 1.60001ZM7.46664 2.66667C4.22664 2.66667 1.59998 5.29334 1.59998 8.53334C1.59998 11.7733 4.22664 14.4 7.46664 14.4C10.7066 14.4 13.3333 11.7733 13.3333 8.53334H7.46664V2.66667Z',
});
