import { Draft } from '@reduxjs/toolkit';
import { keyBy, remove } from 'lodash';

import { DatabaseConfig, DatabaseConfigDeleteInput, DatabaseConfigInput } from 'generated/graphql';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { Layer } from 'reduxStore/models/layers';

export function setDatabaseConfigsFromDatasetSnapshot(
  layer: Draft<Layer>,
  dataset: DatasetSnapshot,
) {
  if (layer == null || layer.databaseConfigs == null) {
    layer.databaseConfigs = { byId: {}, allIds: [] };
  }

  const databaseConfigs = dataset?.databaseConfigs ?? [];
  layer.databaseConfigs = {
    byId: keyBy(databaseConfigs, ({ id }) => id),
    allIds: databaseConfigs.map(({ id }) => id),
  };
}

export function handleCreateDatabaseConfig(
  layer: Draft<Layer>,
  createDatabaseConfig: DatabaseConfigInput,
) {
  const config: DatabaseConfig = {
    id: createDatabaseConfig.id,
    businessObjectSpecId: createDatabaseConfig.businessObjectSpecId,
    source: createDatabaseConfig.source,
    ...(createDatabaseConfig.database != null && { database: createDatabaseConfig.database }),
    ...(createDatabaseConfig.extTable != null && { extTable: createDatabaseConfig.extTable }),
    refreshInfo: createDatabaseConfig.refreshInfo ?? undefined,
  };

  if (layer.databaseConfigs.byId[config.id] != null) {
    return;
  }

  layer.databaseConfigs.byId[config.id] = config;
  layer.databaseConfigs.allIds.push(config.id);
}

export function handleUpdateDatabaseConfig(
  layer: Draft<Layer>,
  updateDatabaseConfig: DatabaseConfigInput,
) {
  const existingConfig = layer.databaseConfigs.byId[updateDatabaseConfig.id];
  if (existingConfig == null) {
    return;
  }
  existingConfig.businessObjectSpecId = updateDatabaseConfig.businessObjectSpecId;
  if (updateDatabaseConfig.extTable != null) {
    existingConfig.source = updateDatabaseConfig.source;
    existingConfig.extTable = updateDatabaseConfig.extTable;
  }
  if (updateDatabaseConfig.database != null) {
    existingConfig.source = updateDatabaseConfig.source;
    existingConfig.database = updateDatabaseConfig.database;
  }
  if (updateDatabaseConfig.refreshInfo != null) {
    existingConfig.refreshInfo = updateDatabaseConfig.refreshInfo;
  }
}

export function handleDeleteDatabaseConfig(layer: Draft<Layer>, { id }: DatabaseConfigDeleteInput) {
  const existingConfig = layer.databaseConfigs.byId[id];
  if (existingConfig == null) {
    return;
  }

  delete layer.databaseConfigs.byId[id];
  remove(layer.databaseConfigs.allIds, (currentId) => currentId === id);
}
