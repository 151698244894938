import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BlockId, BlocksPageId } from 'reduxStore/models/blocks';

export type CustomizeMenuRef =
  | {
      type: 'block';
      id: BlockId;
    }
  | {
      type: 'page';
      id: BlocksPageId;
    };

export type CustomizeMenuState = {
  ref: CustomizeMenuRef;
  objectTableCustomizeMenuState?: ObjectTableCustomizeMenuState;
} | null;

export type ObjectTableCustomizeMenuState = {
  page:
    | 'root'
    | 'filter'
    | 'dateRange'
    | 'dateRangeStart'
    | 'dateRangeEnd'
    | 'columns'
    | 'displayAs'
    | 'asTimeSeries'
    | 'comparisons';
};

const initialState = null as CustomizeMenuState;

const customizeMenuStateSlice = createSlice({
  name: 'customizeMenuState',
  initialState,
  reducers: {
    toggleCustomizeMenu(state, action: PayloadAction<CustomizeMenuState>) {
      return state != null ? null : action.payload;
    },
    closeCustomizeMenu() {
      return null;
    },
  },
});

export const { toggleCustomizeMenu, closeCustomizeMenu } = customizeMenuStateSlice.actions;

export default customizeMenuStateSlice.reducer;
