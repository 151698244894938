import { Ref, forwardRef, memo } from 'react';

import VirtualizedListBase from 'components/VirtualizedList/VirtualizedListBase';
import {
  GetItemSize,
  VirtualizedListBaseRefProps,
  VirtualizedVerticalListBaseProps,
} from 'components/VirtualizedList/types';

export type FixedSizeVirtualizedVerticalListProps = {
  getItemHeight: GetItemSize;
} & Omit<VirtualizedVerticalListBaseProps, 'estimatedItemHeight'>;

const FixedSizeVirtualizedVerticalList: React.FC<FixedSizeVirtualizedVerticalListProps> =
  forwardRef((props, ref: Ref<VirtualizedListBaseRefProps>) => (
    <VirtualizedListBase
      {...props}
      getItemSize={props.getItemHeight}
      gapPx={props.rowGapPx}
      scrollTargetRef={props.verticalScrollTargetRef}
      ref={ref}
      listType="vertical"
    />
  ));

FixedSizeVirtualizedVerticalList.displayName = 'FixedSizeVirtualizedVerticalList';

export default memo(FixedSizeVirtualizedVerticalList);
