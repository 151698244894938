import { Flex, Text } from '@chakra-ui/react';
import Joi from 'joi';
import React, { useCallback } from 'react';

import EscapableInput from 'components/EscapableInput/EscapableInput';

type TimeUnitSelectItem = {
  id: string;
  name: string;
  multiplier: number;
};

const TIME_UNITS_ITEMS: TimeUnitSelectItem[] = [
  {
    id: 'months_ago',
    name: 'months ago',
    multiplier: -1,
  },
  {
    id: 'months_forward',
    name: 'months forward',
    multiplier: 1,
  },
];

const MONTH_INPUT_VALIDATOR = Joi.object({
  startMonths: Joi.number().integer().min(0).max(99),
  endMonths: Joi.number().integer().min(0).max(99),
});

interface Props {
  field: 'start' | 'end';
  numMonths: number;
  onUpdateNumMonths: (newNumMonths: number) => void;
}

const RangeFieldControls: React.FC<Props> = ({ field, numMonths, onUpdateNumMonths }) => {
  const numMonthsDisplayString = `${Math.abs(numMonths)}`;
  const selectedTimeUnitId = numMonths <= 0 ? 'months_ago' : 'months_forward';
  const selectedTimeUnit = TIME_UNITS_ITEMS.find((item) => item.id === selectedTimeUnitId);
  const onSaveNumMonths = useCallback(
    (newValue: string) => {
      const newNumMonths = Number(newValue);
      const multiplier = selectedTimeUnit?.multiplier;
      if (!isNaN(newNumMonths) && multiplier != null) {
        onUpdateNumMonths(newNumMonths * multiplier);
      }
    },
    [onUpdateNumMonths, selectedTimeUnit?.multiplier],
  );
  if (selectedTimeUnit == null) {
    return null;
  }
  return (
    <Flex columnGap={2} align="center">
      <EscapableInput
        variant="white"
        lineHeight="1rem"
        name={`${field}Months`}
        w="2.75rem"
        h="2.25rem"
        pl={3}
        pr={3}
        borderWidth="px"
        onSave={onSaveNumMonths}
        defaultValue={numMonthsDisplayString}
        validationSchema={MONTH_INPUT_VALIDATOR}
        maxLength={2}
      />
      <Text>{selectedTimeUnit.name}</Text>
    </Flex>
  );
};

export default RangeFieldControls;
