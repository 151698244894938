import React from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import TableCell from 'components/Table/TableCell';
import TimeSeriesCellWrapper from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapper';
import { CellRef, MonthColumnKey } from 'config/cells';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useDragToSelectCells } from 'hooks/useDragToSelectCells';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

interface Props {
  cellRef: CellRef & { columnKey: MonthColumnKey };
}

const EmptyTimeSeriesCell: React.FC<Props> = ({ cellRef }) => {
  const { blockId } = useBlockContext();
  const width = useAppSelector((state) =>
    columnWidthSelector(state, { columnType: cellRef.columnKey.monthKey, blockId }),
  );
  const { onMouseDown, onMouseEnter, onMouseLeave } = useDragToSelectCells(cellRef, blockId);

  return (
    <CellContextProvider cellRef={cellRef}>
      <TimeSeriesCellWrapper
        color="forecast"
        width={width}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <TableCell width={width} />
      </TimeSeriesCellWrapper>
    </CellContextProvider>
  );
};

export default React.memo(EmptyTimeSeriesCell);
