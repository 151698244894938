import createCache from '@emotion/cache';
import { useServerInsertedHTML } from 'next/navigation';
import React, { useMemo } from 'react';

// Fork from chakra-ui
// the original impl passes a new object as a dep to useMemo so every render
// will create a new cache

// https://github.com/chakra-ui/chakra-ui/blob/83c21773cdd330927730bc1b539e907c0a76ac0f/packages/integrations/next-js/src/emotion-cache-provider.tsx#L11-L14
export function useEmotionCache() {
  const val = useMemo(() => {
    const cache = createCache({ key: 'css', prepend: true });
    cache.compat = true;
    return cache;
  }, []);

  useServerInsertedHTML(() =>
    React.createElement('style', {
      key: val.key,
      'data-emotion': `${val.key} ${Object.keys(val.inserted).join(' ')}`,
      dangerouslySetInnerHTML: {
        __html: Object.values(val.inserted).join(' '),
      },
    }),
  );
  return val;
}
