import React, { memo, useEffect, useRef } from 'react';

export interface SizeInfo {
  width: number;
  height: number;
}

interface Props {
  children: React.ReactNode;
  /**
   * callback when a size measurement is available
   */
  onSize?: (props: SizeInfo) => void;
  /**
   * whether the component being measured is visible
   */
  isVisible?: boolean;
}

const ListItemMeasurer = ({ children, isVisible = true, onSize }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const visibility = isVisible ? 'visible' : 'hidden';

  useEffect(() => {
    const element = ref.current;
    if (element != null) {
      const resizeCallback = ([entry]: ResizeObserverEntry[]) => {
        const { width, height } = entry.contentRect;
        onSize?.({ width, height });
      };
      const resizeObserver = new ResizeObserver(resizeCallback);
      resizeObserver.observe(element);
      return () => {
        resizeObserver.unobserve(element);
        resizeObserver.disconnect();
      };
    }
    return () => {};
  }, [onSize]);

  return (
    <div style={{ visibility }} ref={ref}>
      {children}
    </div>
  );
};

export default memo(ListItemMeasurer);
