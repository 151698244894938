import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';

export function invalidNameError(type: string) {
  return `That ${lowerCase(type)} name is already taken.`;
}

export function joiDefault(type: string) {
  return {
    'any.invalid': invalidNameError(type),
    'string.empty': `${capitalize(type)} name cannot be empty.`,
  };
}

// see https://github.com/highlight/highlight/blob/12720bf9c4b00c8874dd1fd5848f90a854f8aab3/sdk/highlight-react/src/components/ErrorBoundary.tsx#L206
export function getComponentNameFromStack(componentStack: string | undefined): string | undefined {
  if (componentStack == null) {
    return undefined;
  }

  const stack = componentStack.split('\n');

  if (stack.length < 1) {
    return undefined;
  }

  const leafComponentLine = stack[1].trim();
  const tokens = leafComponentLine.split(' ');

  if (tokens.length !== 4) {
    return undefined;
  }

  return `<${tokens[1]}>`;
}
