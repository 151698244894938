import { Center, Text } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';

import { CURRENCY_MENU_WIDTH_PX } from 'components/CurrencyMenu/CurrencySelectMenu';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { ColorLocation, formatColorForUsage } from 'helpers/color';
import PaintBrush from 'vectors/PaintBrush';

const COLORS: Array<[string, string]> = [
  ['white', 'Default'],
  ['gray', 'Gray'],
  ['red', 'Red'],
  ['yellow', 'Yellow'],
  ['green', 'Green'],
];

const SECTION: Section[] = [
  {
    id: 'colors-submenu',
    name: 'Background Color',
  },
];

export const useColorSelectMenuItem = ({
  bgColor,
  showHashtag = false,
  onSelectBackgroundColor,
}: {
  bgColor: string | undefined;
  showHashtag?: boolean;
  onSelectBackgroundColor: (item: SelectItem) => void;
}): SelectItem => {
  const item: SelectItem = useMemo(
    () => ({
      id: 'background',
      icon: <PaintBrush />,
      submenu: () => (
        <ColorSelectMenu
          bgColor={bgColor}
          showHashtag={showHashtag}
          onSelect={onSelectBackgroundColor}
        />
      ),
      name: 'Background',
      sectionId: 'style-options',
    }),
    [bgColor, showHashtag, onSelectBackgroundColor],
  );

  return item;
};

export const ColorSelectMenu: React.FC<{
  bgColor: string | undefined;
  showHashtag?: boolean;
  onSelect: (item: SelectItem) => void;
  onClose?: () => void;
}> = ({ bgColor, showHashtag, onSelect, onClose }) => {
  const items: SelectItem[] = useMemo(
    () =>
      COLORS.map(([color, name]) => {
        const displayColor = formatColorForUsage(color, ColorLocation.Accessory);

        return {
          id: `${color}`,
          key: `${color}`,
          icon: (
            <Center
              bgColor={displayColor}
              rounded="3px"
              w="1rem"
              h="1rem"
              borderWidth={color === 'white' ? '1px' : '0px'}
              borderStyle="solid"
              borderColor="gray.300"
            >
              {showHashtag ? <Text color="gray.600">#</Text> : null}
            </Center>
          ),
          name: `${name}`,
          isChecked: bgColor != null ? bgColor === color : color === 'white',
          sectionId: 'colors-submenu',
        };
      }),
    [bgColor, showHashtag],
  );

  const handleSelect = useCallback(
    (item: SelectItem) => {
      onSelect(item);
      onClose?.();
    },
    [onSelect, onClose],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu
        items={items}
        sections={SECTION}
        width={CURRENCY_MENU_WIDTH_PX}
        onSelect={handleSelect}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};
