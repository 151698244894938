import { deepEqual } from 'fast-equals';
import { difference, differenceBy, intersection, isEqual, uniq, uniqBy } from 'lodash';

import { Comparator } from '@features/CompareScenarios/comparators/Comparator';
import { toFormulaChangeData } from '@features/CompareScenarios/comparators/common/toComparatorChangeDataShape';
import {
  Currency,
  DecimalPlaces,
  Formula,
  NumericFormat,
  WrappedType,
} from '@features/CompareScenarios/comparators/common/types';
import {
  Change,
  ChangeUpdate,
  ChangesUpdate,
  TouchUpdate,
  TransitionUpdate,
} from '@features/CompareScenarios/compareLayers';
import {
  DriverCreateInput,
  DriverDeleteInput,
  DriverType,
  DriverUpdateInput,
  EventCreateInput,
  EventDeleteInput,
  EventUpdateInput,
  LeverType,
} from 'generated/graphql';
import { areFormulasEqual } from 'helpers/formula';
import { safeObjGet } from 'helpers/typescript';
import { Coloring } from 'reduxStore/models/common';
import {
  BasicDriver,
  DriverId,
  NormalizedDimensionalDriver,
  NormalizedDriver,
  getDriverActualsFormulaForCalculation,
  getDriverForecastFormulaForCalculation,
} from 'reduxStore/models/drivers';
import {
  DriverEvent,
  Event,
  EventGroup,
  EventGroupId,
  isDriverEvent,
} from 'reduxStore/models/events';
import { Layer } from 'reduxStore/models/layers';
import { Value } from 'reduxStore/models/value';
import { MonthKey } from 'types/datetime';

export class DriverComparator extends Comparator<DriverChange> {
  mark(currentLayer: Layer, mergeLayer: Layer) {
    const driverById: Record<DriverId, NormalizedDriver> = {};
    const eventsByDriverId: Record<DriverId, DriverEvent[]> = {};

    for (const batch of currentLayer.mutationBatches) {
      const driverMutations: Array<DriverCreateInput | DriverUpdateInput | DriverDeleteInput> = [
        ...(batch.mutation.newDrivers ?? []),
        ...(batch.mutation.updateDrivers ?? []),
        ...(batch.mutation.deleteDrivers ?? []),
      ];

      for (const input of driverMutations) {
        const driver = currentLayer.drivers.byId[input.id] ?? mergeLayer.drivers.byId[input.id];
        if (driver != null) {
          driverById[driver.id] = driver;
        }
      }

      const eventMutations: Array<EventCreateInput | EventUpdateInput | EventDeleteInput> = [
        ...(batch.mutation.newEvents ?? []),
        ...(batch.mutation.updateEvents ?? []),
        ...(batch.mutation.deleteEvents ?? []),
      ];

      for (const input of eventMutations) {
        const event = safeObjGet(
          currentLayer.events.byId[input.id] ?? mergeLayer.events.byId[input.id],
        );
        if (event == null || !isDriverEvent(event)) {
          continue;
        }

        const driver =
          currentLayer.drivers.byId[event.driverId] ?? mergeLayer.drivers.byId[event.driverId];
        if (driver != null) {
          driverById[driver.id] = driver;

          if (eventsByDriverId[driver.id] == null) {
            eventsByDriverId[driver.id] = [];
          }
          eventsByDriverId[driver.id].push(event);
        }
      }
    }
    return { drivers: Object.values(driverById), eventsByDriverId };
  }

  sweep(
    currentLayer: Layer,
    mergeLayer: Layer,
    {
      drivers,
      eventsByDriverId,
    }: {
      drivers: Array<BasicDriver | NormalizedDimensionalDriver>;
      eventsByDriverId: Record<DriverId, DriverEvent[]>;
    },
  ): DriverChange[] {
    const changes: DriverChange[] = [];

    const currentDimDriverBySubdriverId = this.#getDimDriverBySubdriverId(currentLayer);
    const mergeDimDriverBySubdriverId = this.#getDimDriverBySubdriverId(mergeLayer);

    for (const driver of drivers) {
      if (driver.type === DriverType.Dimensional) {
        const change = this.#handleDimensionalDriverChange({
          driver,
          currentLayer,
          mergeLayer,
          currentDimDriverBySubdriverId,
          mergeDimDriverBySubdriverId,
        });
        if (change !== null) {
          changes.push(change);
        }
      } else if (driver.type === DriverType.Basic) {
        const events = eventsByDriverId[driver.id] ?? [];
        const change = this.#handleBasicDriverChange({
          driver,
          events,
          currentLayer,
          mergeLayer,
          currentDimDriverBySubdriverId,
          mergeDimDriverBySubdriverId,
        });
        if (change != null) {
          changes.push(change);
        }
      }
    }
    return changes;
  }

  #handleDimensionalDriverChange({
    driver,
    currentLayer,
    mergeLayer,
    currentDimDriverBySubdriverId,
    mergeDimDriverBySubdriverId,
  }: {
    driver: NormalizedDimensionalDriver;
    currentLayer: Layer;
    mergeLayer: Layer;
    currentDimDriverBySubdriverId: Record<DriverId, NormalizedDimensionalDriver | undefined>;
    mergeDimDriverBySubdriverId: Record<DriverId, NormalizedDimensionalDriver | undefined>;
  }): DimensionalDriverChange | null {
    let currentDimDriver = safeObjGet(currentLayer.drivers.byId[driver.id]);
    let mergeDimDriver = safeObjGet(mergeLayer.drivers.byId[driver.id]);

    if (currentDimDriver == null && mergeDimDriver == null) {
      return null;
    }

    if (
      (currentDimDriver?.type ?? DriverType.Dimensional) === DriverType.Basic ||
      (mergeDimDriver?.type ?? DriverType.Dimensional) === DriverType.Basic
    ) {
      return null;
    }

    currentDimDriver = currentDimDriver as NormalizedDimensionalDriver | undefined;
    mergeDimDriver = mergeDimDriver as NormalizedDimensionalDriver | undefined;

    const change: DimensionalDriverChange = { object: driver, status: null, updatesByField: {} };
    if (currentDimDriver == null && mergeDimDriver != null) {
      change.status = 'deleted';
      // NOTE: if the dim driver is deleted, we don't care about the updates it has for its subdrivers
      return change;
    }

    if (currentDimDriver != null && mergeDimDriver == null) {
      change.status = 'created';
    }

    const uniqueSubdriverDriverIds = new Set(
      [...(currentDimDriver?.subdrivers ?? []), ...(mergeDimDriver?.subdrivers ?? [])].map(
        ({ driverId }) => {
          return driverId;
        },
      ),
    );

    const propertyChange = this.#handleDimensionalDriverPropertyChange({
      currentDimDriver,
      mergeDimDriver,
      currentLayer,
      mergeLayer,
    });
    if (propertyChange != null) {
      change.updatesByField.properties = {
        field: 'Property Changes',
        change: propertyChange,
      };
    }

    const basicDriverUpdates: ChangesUpdate<BasicDriverChange> = {
      field: 'Basic Drivers',
      changes: [],
    };
    for (const driverId of uniqueSubdriverDriverIds) {
      const child =
        (currentLayer.drivers.byId[driverId] as BasicDriver | undefined) ??
        (mergeLayer.drivers.byId[driverId] as BasicDriver | undefined);
      if (child == null) {
        continue;
      }

      const basicDriverUpdate = this.#handleBasicDriverChange({
        driver: child,
        events: [],
        currentLayer,
        mergeLayer,
        currentDimDriverBySubdriverId,
        mergeDimDriverBySubdriverId,
      });
      if (basicDriverUpdate != null) {
        basicDriverUpdates.changes.push(basicDriverUpdate);
      }
    }
    if (basicDriverUpdates.changes.length > 0) {
      change.updatesByField.basicDrivers = basicDriverUpdates;
    }

    if (change.status == null && Object.keys(change.updatesByField).length > 0) {
      change.status = 'updated';
    }

    if (change.status == null) {
      return null;
    }
    return change;
  }

  #handleDimensionalDriverPropertyChange({
    currentDimDriver,
    mergeDimDriver,
    currentLayer,
    mergeLayer,
  }: {
    currentDimDriver: NormalizedDimensionalDriver | undefined;
    mergeDimDriver: NormalizedDimensionalDriver | undefined;
    currentLayer: Layer;
    mergeLayer: Layer;
  }): DimensionalDriverPropertyChange | null {
    // If the driver is deleted, we don't care about the properties
    if (currentDimDriver == null) {
      return null;
    }
    const change: DimensionalDriverPropertyChange = {
      object: currentDimDriver.id,
      status: null,
      updatesByField: {},
    };

    if (
      !areFormulasEqual(
        mergeDimDriver?.defaultForecast?.formula,
        currentDimDriver?.defaultForecast?.formula,
      )
    ) {
      change.updatesByField.defaultForecastFormula = {
        field: 'Default Forecast Formula',
        from: toFormulaChangeData({
          id: mergeDimDriver?.id,
          type: 'driver',
          formulaType: 'forecast',
          layerId: mergeLayer.id,
        }),
        to: toFormulaChangeData({
          id: currentDimDriver?.id,
          type: 'driver',
          formulaType: 'forecast',
          layerId: currentLayer.id,
        }),
      };
    }

    if (
      !areFormulasEqual(
        mergeDimDriver?.defaultActuals?.formula,
        currentDimDriver?.defaultActuals?.formula,
      )
    ) {
      change.updatesByField.defaultActualsFormula = {
        field: 'Default Actuals Formula',
        from: toFormulaChangeData({
          id: mergeDimDriver?.id,
          type: 'driver',
          formulaType: 'actuals',
          layerId: mergeLayer.id,
        }),
        to: toFormulaChangeData({
          id: currentDimDriver?.id,
          type: 'driver',
          formulaType: 'actuals',
          layerId: currentLayer.id,
        }),
      };
    }

    if (Object.keys(change.updatesByField).length > 0) {
      change.status = 'updated';
    }

    if (change.status == null) {
      return null;
    }
    return change;
  }

  #handleBasicDriverChange({
    driver,
    events,
    currentLayer,
    mergeLayer,
    currentDimDriverBySubdriverId,
    mergeDimDriverBySubdriverId,
  }: {
    driver: BasicDriver;
    events: DriverEvent[];
    currentLayer: Layer;
    mergeLayer: Layer;
    currentDimDriverBySubdriverId: Record<DriverId, NormalizedDimensionalDriver | undefined>;
    mergeDimDriverBySubdriverId: Record<DriverId, NormalizedDimensionalDriver | undefined>;
  }): BasicDriverChange | null {
    let currentDriver = safeObjGet(currentLayer.drivers.byId[driver.id]);
    let mergeDriver = safeObjGet(mergeLayer.drivers.byId[driver.id]);

    if (currentDriver == null && mergeDriver == null) {
      return null;
    }

    if (
      (currentDriver?.type ?? DriverType.Basic) === DriverType.Dimensional ||
      (mergeDriver?.type ?? DriverType.Basic) === DriverType.Dimensional
    ) {
      return null;
    }

    currentDriver = currentDriver as BasicDriver | undefined;
    mergeDriver = mergeDriver as BasicDriver | undefined;

    const change: BasicDriverChange = { object: driver, status: null, updatesByField: {} };

    if (currentDriver == null && mergeDriver != null) {
      change.status = 'deleted';
      // NOTE: if the basic driver is deleted, we don't care about its other properties
      return change;
    }

    if (currentDriver != null && mergeDriver == null) {
      change.status = 'created';
    }

    const updatedEvents = events.map((event) => {
      const currentLayerEvent = safeObjGet(currentLayer.events.byId[event.id]);
      const mergeLayerEvent = safeObjGet(mergeLayer.events.byId[event.id]);

      const currentLayerEventGroup =
        currentLayerEvent?.parentId != null
          ? safeObjGet(currentLayer.eventGroups.byId[currentLayerEvent.parentId])
          : null;
      const mergeLayerEventGroup =
        mergeLayerEvent?.parentId != null
          ? safeObjGet(mergeLayer.eventGroups.byId[mergeLayerEvent.parentId])
          : null;

      return { currentLayerEvent, mergeLayerEvent, currentLayerEventGroup, mergeLayerEventGroup };
    });

    const parent = currentDimDriverBySubdriverId[driver.id];
    const propertyChange = this.#handleBasicDriverPropertyChange({
      driverId: driver.id,
      currentDriver,
      mergeDriver,
      hasParent: parent != null,
      updatedEvents,
    });
    if (propertyChange != null) {
      change.updatesByField.properties = {
        field: 'Property Changes',
        change: propertyChange,
      };
    }

    const dataChange = this.#handleBasicDriverDataChange({
      driverId: driver.id,
      currentDriver,
      mergeDriver,
      currentDimDriverBySubdriverId,
      mergeDimDriverBySubdriverId,
    });
    if (dataChange != null) {
      change.updatesByField.data = {
        field: 'Data Changes',
        change: dataChange,
      };
    }

    if (change.status == null && Object.keys(change.updatesByField).length > 0) {
      change.status = 'updated';
    }

    if (change.status == null) {
      return null;
    }
    return change;
  }

  #handleBasicDriverPropertyChange({
    driverId,
    currentDriver,
    mergeDriver,
    hasParent,
    updatedEvents,
  }: {
    driverId: DriverId;
    currentDriver: BasicDriver | undefined;
    mergeDriver: BasicDriver | undefined;
    hasParent: boolean;
    updatedEvents: UpdatedEvent[];
  }) {
    const change: BasicDriverPropertyChange = {
      object: driverId,
      status: null,
      updatesByField: {},
    };

    if (!hasParent && mergeDriver?.name !== currentDriver?.name) {
      change.updatesByField.name = {
        field: 'Name',
        from: mergeDriver?.name,
        to: currentDriver?.name,
      };
    }

    if (!hasParent && mergeDriver?.format !== currentDriver?.format) {
      change.updatesByField.format = {
        field: 'Format',
        from:
          mergeDriver?.format != null
            ? { type: 'numericFormat', value: mergeDriver.format }
            : undefined,
        to:
          currentDriver?.format != null
            ? { type: 'numericFormat', value: currentDriver.format }
            : undefined,
      };
    }

    if (!hasParent && mergeDriver?.decimalPlaces !== currentDriver?.decimalPlaces) {
      change.updatesByField.decimalPlaces = {
        field: 'Precision',
        from:
          mergeDriver?.decimalPlaces != null
            ? { type: 'decimalPlaces', value: mergeDriver.decimalPlaces }
            : undefined,
        to:
          currentDriver?.decimalPlaces != null
            ? { type: 'decimalPlaces', value: currentDriver.decimalPlaces }
            : undefined,
      };
    }

    if (!hasParent && mergeDriver?.currencyISOCode !== currentDriver?.currencyISOCode) {
      change.updatesByField.currency = {
        field: 'Currency',
        from:
          mergeDriver?.currencyISOCode != null
            ? { type: 'currency', value: mergeDriver.currencyISOCode }
            : undefined,
        to:
          currentDriver?.currencyISOCode != null
            ? { type: 'currency', value: currentDriver.currencyISOCode }
            : undefined,
      };
    }

    if (!hasParent && mergeDriver?.leverType !== currentDriver?.leverType) {
      change.updatesByField.leverType = {
        field: 'KPI',
        from: { type: 'leverType', value: mergeDriver?.leverType ?? null },
        to: { type: 'leverType', value: currentDriver?.leverType ?? null },
      };
    }

    if (!hasParent && !deepEqual(mergeDriver?.coloring, currentDriver?.coloring)) {
      change.updatesByField.coloring = {
        field: 'Coloring',
        from:
          mergeDriver?.coloring != null
            ? { type: 'coloring', value: mergeDriver?.coloring }
            : undefined,
        to:
          currentDriver?.coloring != null
            ? { type: 'coloring', value: currentDriver?.coloring }
            : undefined,
      };
    }

    if (updatedEvents.length > 0) {
      const { monthKeysUpdated, eventGroupsImpacted } =
        this.#getDriverEventsComparison(updatedEvents);

      if (eventGroupsImpacted.length > 0) {
        change.updatesByField.plans = {
          field: 'Plans updated',
          from: undefined,
          to: { type: 'plans', value: eventGroupsImpacted.map(({ name }) => name) },
        };
      }

      if (monthKeysUpdated.length > 0) {
        change.updatesByField.months = {
          field: 'Months updated',
          from: undefined,
          to: { type: 'months', value: monthKeysUpdated },
        };
      }
    }

    const currentDriverRefrences = new Set(
      (currentDriver?.driverReferences ?? []).map((ref) => `${ref.blockId}-${ref.groupId}`),
    );
    const mergeDriverReferences = new Set(
      (mergeDriver?.driverReferences ?? []).map((ref) => `${ref.blockId}-${ref.groupId}`),
    );
    if (!deepEqual(currentDriverRefrences, mergeDriverReferences)) {
      change.updatesByField.references = {
        field: 'References',
        from:
          mergeDriver?.id != null
            ? {
                type: 'references',
                value: [...mergeDriverReferences],
              }
            : undefined,
        to:
          currentDriver?.id != null
            ? {
                type: 'references',
                value: [...currentDriverRefrences],
              }
            : undefined,
      };
    }

    if (Object.keys(change.updatesByField).length === 0) {
      return null;
    }

    change.status = 'updated';
    return change;
  }

  #handleBasicDriverDataChange({
    driverId,
    currentDriver,
    mergeDriver,
    currentDimDriverBySubdriverId,
    mergeDimDriverBySubdriverId,
  }: {
    driverId: DriverId;
    currentDriver: BasicDriver | undefined;
    mergeDriver: BasicDriver | undefined;
    currentDimDriverBySubdriverId: Record<DriverId, NormalizedDimensionalDriver | undefined>;
    mergeDimDriverBySubdriverId: Record<DriverId, NormalizedDimensionalDriver | undefined>;
  }) {
    const change: BasicDriverDataChange = {
      object: driverId,
      status: null,
      updatesByField: {},
    };

    const currentParent = currentDimDriverBySubdriverId[driverId];
    const mergeParent = mergeDimDriverBySubdriverId[driverId];

    if (
      !areFormulasEqual(
        currentDriver == null
          ? ''
          : getDriverForecastFormulaForCalculation(currentDriver, currentParent).formula,
        mergeDriver == null
          ? ''
          : getDriverForecastFormulaForCalculation(mergeDriver, mergeParent).formula,
      ) ||
      !areFormulasEqual(
        currentDriver == null
          ? ''
          : getDriverActualsFormulaForCalculation(currentDriver, currentParent).formula,
        mergeDriver == null
          ? ''
          : getDriverActualsFormulaForCalculation(mergeDriver, mergeParent).formula,
      )
    ) {
      change.updatesByField.formula = {
        field: 'Formula',
      };
    }

    if (
      !deepEqual(mergeDriver?.actuals?.timeSeries ?? {}, currentDriver?.actuals?.timeSeries ?? {})
    ) {
      change.updatesByField.timeSeries = {
        field: 'Actuals Time Series',
      };
    }

    if (Object.keys(change.updatesByField).length === 0) {
      return null;
    }

    change.status = 'updated';
    return change;
  }

  #getDriverEventsComparison(updatedEvents: UpdatedEvent[]): {
    monthKeysUpdated: MonthKey[];
    eventGroupsImpacted: Array<{ id: EventGroupId; name: string }>;
  } {
    const { currentImpactsAndEventsByMonthKey, mergeImpactsAndEventsByMonthKey } =
      this.#getLayerImpactsAndEventsByMonthKey(updatedEvents);

    const monthKeysUpdated = this.#getMonthKeysImpacted({
      mergeImpactsAndEventsByMonthKey,
      currentImpactsAndEventsByMonthKey,
    });

    const eventGroupsImpacted = this.#getEventGroupsImpacted({
      monthKeysUpdated,
      mergeImpactsAndEventsByMonthKey,
      currentImpactsAndEventsByMonthKey,
    });

    return { monthKeysUpdated, eventGroupsImpacted };
  }

  #getLayerImpactsAndEventsByMonthKey(updatedEvents: UpdatedEvent[]) {
    const currentImpactsAndEventsByMonthKey: Record<
      MonthKey,
      { value: Value; event: Event; eventGroup: EventGroup | undefined }
    > = {};
    const mergeImpactsAndEventsByMonthKey: Record<
      MonthKey,
      { value: Value; event: Event; eventGroup: EventGroup | undefined }
    > = {};
    updatedEvents.forEach(
      ({ currentLayerEvent, currentLayerEventGroup, mergeLayerEvent, mergeLayerEventGroup }) => {
        if (currentLayerEvent != null) {
          Object.entries(currentLayerEvent.customCurvePoints ?? {}).forEach(([monthKey, value]) => {
            currentImpactsAndEventsByMonthKey[monthKey] = {
              value,
              event: currentLayerEvent,
              eventGroup: currentLayerEventGroup ?? undefined,
            };
          });
        }

        if (mergeLayerEvent != null) {
          Object.entries(mergeLayerEvent.customCurvePoints ?? {}).forEach(([monthKey, value]) => {
            mergeImpactsAndEventsByMonthKey[monthKey] = {
              value,
              event: mergeLayerEvent,
              eventGroup: mergeLayerEventGroup ?? undefined,
            };
          });
        }
      },
    );

    return { currentImpactsAndEventsByMonthKey, mergeImpactsAndEventsByMonthKey };
  }

  #getMonthKeysImpacted({
    mergeImpactsAndEventsByMonthKey,
    currentImpactsAndEventsByMonthKey,
  }: {
    mergeImpactsAndEventsByMonthKey: ImpactsAndEventsByMonthKey;
    currentImpactsAndEventsByMonthKey: ImpactsAndEventsByMonthKey;
  }): MonthKey[] {
    const mergeDriverKeys = Object.keys(mergeImpactsAndEventsByMonthKey);
    const currentDriverKeys = Object.keys(currentImpactsAndEventsByMonthKey);

    const monthsAdded = differenceBy(currentDriverKeys, mergeDriverKeys);
    const monthsRemoved = difference(mergeDriverKeys, currentDriverKeys);
    const monthsUpdated = intersection(mergeDriverKeys, currentDriverKeys).filter(
      (key) =>
        !isEqual(
          safeObjGet(mergeImpactsAndEventsByMonthKey[key])?.value,
          safeObjGet(currentImpactsAndEventsByMonthKey[key])?.value,
        ),
    );

    const allMonthsChanged = uniq([...monthsAdded, ...monthsRemoved, ...monthsUpdated]);

    return allMonthsChanged;
  }

  #getEventGroupsImpacted({
    monthKeysUpdated,
    mergeImpactsAndEventsByMonthKey,
    currentImpactsAndEventsByMonthKey,
  }: {
    monthKeysUpdated: MonthKey[];
    mergeImpactsAndEventsByMonthKey: ImpactsAndEventsByMonthKey;
    currentImpactsAndEventsByMonthKey: ImpactsAndEventsByMonthKey;
  }): Array<{ id: EventGroupId; name: string }> {
    const eventGroupsImpacted: Array<{ id: EventGroupId; name: string }> = [];
    monthKeysUpdated.forEach((monthKey) => {
      const mergeEventGroup = safeObjGet(mergeImpactsAndEventsByMonthKey[monthKey])?.eventGroup;
      if (mergeEventGroup != null) {
        eventGroupsImpacted.push({ id: mergeEventGroup.id, name: mergeEventGroup.name });
      }

      const currentEventGroup = safeObjGet(currentImpactsAndEventsByMonthKey[monthKey])?.eventGroup;
      if (currentEventGroup != null) {
        eventGroupsImpacted.push({ id: currentEventGroup.id, name: currentEventGroup.name });
      }
    });

    return uniqBy(eventGroupsImpacted, (eventGroup) => eventGroup.id);
  }

  #getDimDriverBySubdriverId(layer: Layer) {
    return Object.values(layer.drivers.byId).reduce(
      (acc: Record<DriverId, NormalizedDimensionalDriver | undefined>, driver) => {
        if (driver.type === DriverType.Dimensional) {
          driver.subdrivers.forEach((subDriver) => {
            acc[subDriver.driverId] = driver;
          });
        }
        return acc;
      },
      {},
    );
  }
}

export type DriverChange = BasicDriverChange | DimensionalDriverChange;

export type BasicDriverChange = Change<
  BasicDriver,
  {
    properties: ChangeUpdate<BasicDriverPropertyChange>;
    data: ChangeUpdate<BasicDriverDataChange>;
  }
>;

type BasicDriverPropertyChangeFieldUpdates = {
  name: TransitionUpdate;
  format: TransitionUpdate<NumericFormat>;
  decimalPlaces: TransitionUpdate<DecimalPlaces>;
  currency: TransitionUpdate<Currency>;
  leverType: TransitionUpdate<Lever>;
  coloring: TransitionUpdate<WithColor>;
  references: TransitionUpdate<References>;
  plans: TransitionUpdate<Plans>;
  months: TransitionUpdate<Months>;
};

type BasicDriverPropertyChange = Change<DriverId, BasicDriverPropertyChangeFieldUpdates>;

type BasicDriverDataChange = Change<
  DriverId,
  {
    formula: TouchUpdate;
    timeSeries: TouchUpdate;
  }
>;

export type DimensionalDriverChange = Change<
  NormalizedDimensionalDriver,
  {
    basicDrivers: ChangesUpdate<BasicDriverChange>;
    properties: ChangeUpdate<DimensionalDriverPropertyChange>;
  }
>;

type DimensionalDriverPropertyChangeFieldUpdates = {
  defaultForecastFormula: TransitionUpdate<Formula>;
  defaultActualsFormula: TransitionUpdate<Formula>;
};

type DimensionalDriverPropertyChange = Change<
  DriverId,
  DimensionalDriverPropertyChangeFieldUpdates
>;

export type DriverPropertyChange = Change<
  DriverId,
  BasicDriverPropertyChangeFieldUpdates & DimensionalDriverPropertyChangeFieldUpdates
>;

type UpdatedEvent = {
  currentLayerEvent: Event | undefined;
  mergeLayerEvent: Event | undefined;
  currentLayerEventGroup: EventGroup | null | undefined;
  mergeLayerEventGroup: EventGroup | null | undefined;
};

type ImpactsAndEventsByMonthKey = Record<
  MonthKey,
  { value: Value; event: Event; eventGroup: EventGroup | undefined }
>;

export type Lever = WrappedType<'leverType', LeverType | null>;
export type WithColor = WrappedType<'coloring', Coloring>;
export type References = WrappedType<'references', string[]>;
export type Plans = WrappedType<'plans', EventGroupId[]>;
export type Months = WrappedType<'months', MonthKey[]>;
