import { updateColumnPosition } from 'reduxStore/actions/blockMutations';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { AppThunk } from 'reduxStore/store';
import { orderedColumnKeysForObjectTableSelector } from 'selectors/collectionBlocksSelector';

export const updateObjectTableColumnPosition = ({
  blockId,
  fieldSpecId,
  insertBeforeId,
}: {
  blockId: BlockId;
  fieldSpecId: BusinessObjectFieldSpecId;
  insertBeforeId: string | 'end';
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const currentOrder = orderedColumnKeysForObjectTableSelector(state, blockId);
    dispatch(
      updateColumnPosition({
        blockId,
        columnKey: fieldSpecId,
        insertBeforeId,
        currentOrder,
      }),
    );
  };
};
