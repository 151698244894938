import { createCachedSelector } from 're-reselect';

import { ComparisonTimePeriod } from 'generated/graphql';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { timePeriodComparisonSortComparator } from 'helpers/timeperiodComparisons';
import { BlockId } from 'reduxStore/models/blocks';
import { blockConfigSelector } from 'selectors/blocksSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { ParametricSelector } from 'types/redux';

export const comparisonTimePeriodsForBlockSelector: ParametricSelector<
  BlockId,
  ComparisonTimePeriod[]
> = createDeepEqualSelector(blockConfigSelector, (blockConfig) => {
  // current time period should always be selected
  let timePeriods = blockConfig?.comparisons?.timePeriods ?? [];
  if (timePeriods.length > 0 && !timePeriods.includes(ComparisonTimePeriod.CurrentPeriod)) {
    timePeriods = [ComparisonTimePeriod.CurrentPeriod, ...timePeriods];
  }
  return [...timePeriods].sort(timePeriodComparisonSortComparator);
});

export const isComparingTimePeriodsSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    comparisonTimePeriodsForBlockSelector,
    (comparisonTimePeriods) => comparisonTimePeriods.length > 0,
  )(blockIdSelector);
