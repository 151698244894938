import { Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';

interface Props {
  error: string | undefined;
}

const FormulaTooltipContents: React.FC<Props> = ({ error }) => {
  return (
    <Flex
      flexDirection="column"
      rowGap={2}
      width="15.25rem"
      textAlign="left"
      padding={3}
      pointerEvents="auto"
    >
      <Heading as="h4" size="xs" color="failure">
        Input error
      </Heading>
      <Box fontWeight="medium">{error}</Box>
    </Flex>
  );
};

export default FormulaTooltipContents;
