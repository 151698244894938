import { useDrag, useDrop } from 'react-dnd';

export type BaseDragItem = { type: string };

export type ReorderFunc<T extends BaseDragItem> = (
  item: T,
  relativeTo: T,
  position: 'before' | 'after',
) => void;

export interface UseReorderableProps<T extends BaseDragItem> {
  itemType: string;
  item: T;
  onDrop: ReorderFunc<T>;
  onDragStart?: React.DragEventHandler;
  disabled?: boolean;
}

const useReorderable = <T extends BaseDragItem>({
  itemType,
  item,
  onDrop,
  disabled,
}: UseReorderableProps<T>) => {
  const [, dragRef, previewRef] = useDrag<T, unknown, unknown>(
    () => ({
      type: itemType,
      item,
      canDrag: !disabled,
    }),
    [itemType, item, disabled],
  );

  const [{ isOverEnd }, endDropRef] = useDrop<T, unknown, { isOverEnd: boolean }>(
    () => ({
      accept: itemType,
      drop: (dropItem) => onDrop(dropItem, item, 'after'),
      canDrop: () => true,
      collect: (monitor) => ({
        isOverEnd: monitor.isOver() && monitor.canDrop(),
      }),
    }),
    [item, itemType, onDrop],
  );

  const [{ isOverStart, isDragging }, startDropRef] = useDrop<
    T,
    unknown,
    { isOverStart: boolean; isDragging: boolean }
  >(
    () => ({
      accept: itemType,
      drop: (dropItem) => onDrop(dropItem, item, 'before'),
      canDrop: () => true,
      collect: (monitor) => ({
        isOverStart: monitor.isOver() && monitor.canDrop(),
        isDragging: monitor.getItemType() === itemType,
      }),
    }),
    [itemType, onDrop, item],
  );

  return {
    dragRef,
    previewRef,
    startDropRef,
    endDropRef,
    isOverStart,
    isOverEnd,
    isDragging,
  };
};

export default useReorderable;
