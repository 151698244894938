import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const primary = defineStyle({
  borderColor: 'gray.300',
  borderWidth: 'px',
  fontWeight: 'medium',
  resize: 'none',
  _focus: { borderColor: 'selection.500' },
});

const textareaTheme = defineStyleConfig({
  variants: { primary },
});

export default textareaTheme;
