import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ServerHealthState = { calculatorReady: boolean };

const initialState: ServerHealthState = {
  calculatorReady: false,
};

const serverHealthSlice = createSlice({
  name: 'serverHealth',
  initialState,
  reducers: {
    setCalculatorReady: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        calculatorReady: action.payload,
      };
    },
  },
});

export const { setCalculatorReady } = serverHealthSlice.actions;

export default serverHealthSlice.reducer;
