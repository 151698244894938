import { Text } from '@chakra-ui/react';
import React from 'react';

const RowText: React.FC<{ text?: string | null }> = React.memo(({ text }) => {
  return (
    <Text fontSize="xs" fontWeight="medium">
      {text ?? '-'}
    </Text>
  );
});

export default RowText;
