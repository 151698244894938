import { FirebaseOptions } from '@firebase/app';

import { isProduction } from 'helpers/environment';

const apiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY as string;

const getFirebaseConfig = (): FirebaseOptions => {
  if (isProduction) {
    return {
      apiKey,
      authDomain: 'runway-production.firebaseapp.com',
      databaseURL: 'https://runway-production.firebaseio.com',
      projectId: 'runway-production',
      storageBucket: 'runway-production.appspot.com',
      messagingSenderId: '80102298618',
      appId: '1:80102298618:web:254cd926a14b444f2d8a43',
      measurementId: 'G-DQL2N6E3R4',
    };
  }

  return {
    apiKey,
    authDomain: 'runway-devel.firebaseapp.com',
    databaseURL: 'https://runway-devel.firebaseio.com',
    projectId: 'runway-devel',
    storageBucket: 'runway-devel.appspot.com',
    messagingSenderId: '20243548301',
    appId: '1:20243548301:web:8595af263bf8a419d83ac4',
    measurementId: 'G-KMMD8LWTGK',
  };
};

const firebaseConfig = getFirebaseConfig();

export default firebaseConfig;
