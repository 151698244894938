import { createSelector } from 'reselect';

import { safeObjGet } from 'helpers/typescript';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { Milestone } from 'reduxStore/models/milestones';
import { DriverDetailPaneTab, isDriverDetailPane } from 'reduxStore/reducers/detailPaneSlice';
import { openDetailPaneSelector } from 'selectors/detailPaneSelectors';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { firstMilestoneByDriverIdSelector } from 'selectors/milestonesSelector';
import { Selector } from 'types/redux';

export const mustDetailPaneDriverIdSelector: Selector<DriverId> = createSelector(
  openDetailPaneSelector,
  (detailPane) => {
    if (!isDriverDetailPane(detailPane)) {
      throw new Error('no driver detail pane open');
    }

    return detailPane.driverId;
  },
);

export const isDetailPaneDriverDimensionalSelector: Selector<boolean> = createSelector(
  mustDetailPaneDriverIdSelector,
  driversByIdForLayerSelector,
  (driverId, driversById) => {
    const driver = safeObjGet(driversById[driverId]);
    return driver?.type === DriverType.Dimensional;
  },
);

export const detailPaneDriverIdSelector: Selector<DriverId | null> = createSelector(
  openDetailPaneSelector,
  (detailPane) => {
    if (!isDriverDetailPane(detailPane)) {
      return null;
    }
    return detailPane.driverId;
  },
);

export const driverDetailPaneMilestoneSelector: Selector<Milestone | null> = createSelector(
  mustDetailPaneDriverIdSelector,
  firstMilestoneByDriverIdSelector,
  (driverId, firstMilestoneByDriverId) => firstMilestoneByDriverId[driverId] ?? null,
);

export const driverDetailPaneTabSelector: Selector<DriverDetailPaneTab | null> = createSelector(
  openDetailPaneSelector,
  (detailPane) => {
    if (!isDriverDetailPane(detailPane)) {
      return null;
    }
    return detailPane.tab;
  },
);

export const driverDetailPaneShowIndirectInputsSelector: Selector<boolean> = createSelector(
  openDetailPaneSelector,
  (detailPane) => {
    if (!isDriverDetailPane(detailPane)) {
      return false;
    }
    return detailPane.showIndirectInputs;
  },
);
