import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import { WritableDraft } from 'immer';
import { isEmpty } from 'lodash';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import {
  BlockResponse,
  ObjectRowsResponse,
} from 'components/AgGridComponents/helpers/gridDatasource/types';
import { Group } from 'config/businessObjects';
import { CalculationOpts } from 'generated/graphql';
import { combineDateRanges } from 'helpers/dates';
import {
  ALL_LAYERS_KEY,
  ALL_MONTHS_KEY,
  CacheKey,
  getFormulaCacheKey,
  getIdAndMonthKeyFromFormulaCacheKey,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { uuidv4 } from 'helpers/uuidv4';
import { BlockId } from 'reduxStore/models/blocks';
import { EventId } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { Value } from 'reduxStore/models/value';
import {
  ApplyMutationArgs,
  DatasetSliceState,
  UndoLatestArgs,
  setCurrentLayer as setCurrentLayerAction,
} from 'reduxStore/reducers/datasetSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import {
  CalculationEngine,
  calculationEngineSelector,
  isUsingBackendCalculatorSelector,
} from 'selectors/calculationEngineSelector';
import { MonthKey } from 'types/datetime';
import {
  AddToCalculationsQueueMessage,
  ApplyMutationBatchMessage,
  CalculatorResponseMessageHandledBySliceReducer,
  ClearLocalEphemeralMutationMessage,
  InitializeDatasetSliceMessage,
  RemoveFromCalculationsQueueMessage,
  RequestedCalculation,
  SetCurrentLayerMessage,
  UndoMutationBatchMessage,
} from 'workers/formulaCalculator/types';

export type EvaluateBlockResult = {
  cacheKey: string;
  groups: Array<Omit<Group, 'isExpanded'>>;
};

type CalculationsSlice = {
  groupsByBlockId: Record<BlockId, EvaluateBlockResult>;
  groupsLoading: Record<BlockId, boolean>;
  // This is for calculating impact of events (i.e. calculations ignoring certain events)
  // The cache keys here are of the shape `temp/${id}.${monthKey}.${eventIds}`
  impactCalculations: { valueLoading: Record<CacheKey, boolean>; values: Record<CacheKey, Value> };
  clientId?: string;
  // This is a hack to force the calculations slice state to change so that loading and timeseries selectors can
  // update at the right times
  calculationsStateVersion: number;

  calculationEngineOverride: CalculationEngine | null;
};

export const addToCalculationsQueue =
  (instanceId: string, data: RequestedCalculation[]): AppThunk =>
  (dispatch, getState) => {
    const state: RootState = getState();

    // Group all date ranges that we are requesting by  layer ID and entity ID
    // so that we can then combine all of the overlapping ranges to request
    // the minimal number of things all in a single batch.
    const dateRangesByEntityId: Record<
      LayerId,
      Record<
        FormulaEntityTypedId['id'],
        {
          entityId: FormulaEntityTypedId;
          range: Array<[MonthKey, MonthKey]>;
          ignoreEventIds?: EventId[];
          clientId: string | undefined;
          includeObjectSpecEvaluation?: boolean;
          opts?: CalculationOpts;
        }
      >
    > = {};

    data.forEach(
      ({
        layerId,
        entityId,
        dateRange,
        ignoreEventIds,
        clientId,
        includeObjectSpecEvaluation,
        opts,
      }) => {
        if (dateRangesByEntityId[layerId] == null) {
          dateRangesByEntityId[layerId] = {};
        }

        const monthlessKey = getFormulaCacheKey(entityId.id, '', ignoreEventIds ?? []);
        const existing = dateRangesByEntityId[layerId][monthlessKey];
        if (existing == null) {
          dateRangesByEntityId[layerId][monthlessKey] = {
            entityId,
            range: [],
            ignoreEventIds,
            clientId,
            includeObjectSpecEvaluation,
            opts,
          };
        } else if (!existing.includeObjectSpecEvaluation && includeObjectSpecEvaluation) {
          // If one of the requests includes object spec evaluation, we need to
          // include it in the combined request.
          existing.includeObjectSpecEvaluation = true;
        }

        dateRangesByEntityId[layerId][monthlessKey].range.push(dateRange);
      },
    );

    const engine = calculationEngineSelector(state);

    // Now, for each entity under each layer, combine the date ranges if they
    // are overlapping at all (inclusive on the start/end) such that we have
    // only non overlapping ranges left.
    const msgData: AddToCalculationsQueueMessage['data'] = [];
    Object.entries(dateRangesByEntityId).forEach(([layerId, forLayer]) => {
      Object.values(forLayer).forEach(
        ({ entityId, range, ignoreEventIds, clientId, includeObjectSpecEvaluation, opts }) => {
          const combined = combineDateRanges(range);
          combined.forEach((dateRange) => {
            msgData.push({
              layerId,
              entityId,
              dateRange,
              ignoreEventIds,
              clientId,
              includeObjectSpecEvaluation,
              opts,
            });
          });
        },
      );
    });

    const isUsingBackend = isUsingBackendCalculatorSelector(state);
    const requestId = uuidv4();
    DataArbiter.get().requestCalculation({
      instanceId,
      data: msgData,
      requestId,
      calculator: engine,
      dispatch: isUsingBackend ? dispatch : undefined,
    });
  };

export const removeFromCalculationsQueue =
  (data: RemoveFromCalculationsQueueMessage['data']): AppThunk =>
  () => {
    const requestId = uuidv4();
    const message: RemoveFromCalculationsQueueMessage = {
      type: 'removeFromCalculationsQueue',
      data,
      requestId,
    };

    window.formulaCalculator?.postMessage(message);
  };

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export const initializeDatasetSlice = (): AppThunk => (_, getState) => {
  const state = getState();

  const data: Optional<DatasetSliceState, 'defaultLayerHistory' | 'blocksPages'> = {
    ...state.dataset,
  };
  delete data.defaultLayerHistory;

  const message: InitializeDatasetSliceMessage = {
    type: 'initializeDatasetSlice',
    data,
  };

  window.formulaCalculator?.postMessage(message);
};

export const applyMutationBatch =
  (args: ApplyMutationArgs): AppThunk =>
  () => {
    const message: ApplyMutationBatchMessage = {
      type: 'applyMutationBatch',
      data: args,
    };

    // This check is to prevent errors in tests where window is undefined
    if (typeof window !== 'undefined') {
      window.formulaCalculator?.postMessage(message);
    }
  };

export const clearLocalEphemeralMutation = (): AppThunk => () => {
  const message: ClearLocalEphemeralMutationMessage = {
    type: 'clearLocalEphemeralMutation',
  };

  window.formulaCalculator?.postMessage(message);
};

export const undoMutationBatch =
  (args: UndoLatestArgs): AppThunk =>
  () => {
    const message: UndoMutationBatchMessage = {
      type: 'undoMutationBatch',
      data: args,
    };

    window.formulaCalculator?.postMessage(message);
  };

const setCurrentLayer = (layerId: LayerId) => {
  const message: SetCurrentLayerMessage = {
    type: 'setCurrentLayer',
    data: layerId,
  };
  window.formulaCalculator?.postMessage(message);
};

const initialState: CalculationsSlice = {
  groupsByBlockId: {},
  groupsLoading: {},
  impactCalculations: {
    valueLoading: {},
    values: {},
  },
  calculationsStateVersion: 0,
  calculationEngineOverride: null,
};

const calculationsSlice = createSlice({
  name: 'calculations',
  initialState,
  reducers: {
    setPendingBlockGroups(state, action: PayloadAction<{ blockId: string }>) {
      state.groupsLoading[action.payload.blockId] = true;
    },
    setResponse(
      state,
      action: PayloadAction<{
        batch: CalculatorResponseMessageHandledBySliceReducer[];
        isBackendCalculation?: boolean;
      }>,
    ) {
      const { batch, isBackendCalculation } = action.payload;
      batch.forEach((response) => {
        if (response.type === 'runCalculations') {
          incrementCalculationsStateVersion(state);
          const res: ObjectRowsResponse = {
            requestId: response.requestId,
            calculationReceivedAtTime: Date.now(),
            values: [],
          };

          response.data.forEach(
            ({ id, layerId, ignoreEventIds, monthKey, value, objectSpecEvaluations, error }) => {
              const cacheKey = getFormulaCacheKey(id, monthKey, ignoreEventIds ?? []);

              const isImpactRequest = ignoreEventIds != null && ignoreEventIds.length > 0;
              if (isImpactRequest) {
                state.impactCalculations.valueLoading[cacheKey] = false;
                if (value != null && !isEmpty(value)) {
                  state.impactCalculations.values[cacheKey] = value;
                } else {
                  delete state.impactCalculations.values[cacheKey];
                }
              } else {
                res.values.push({
                  id,
                  monthKey,
                  value,
                  cacheKey,
                  layerId,
                  objectSpecEvaluations,
                  error,
                });
              }
            },
          );
          DataArbiter.get().forwardObjectRowsResponse(res, isBackendCalculation ?? false);
        } else if (response.type === 'runCalculationsError') {
          // NOTE: This is currently only used by the webworker and thus will
          // become irrelevant. The backend sends calculation errors down
          // alongside the computed values.
          incrementCalculationsStateVersion(state);
          const res: ObjectRowsResponse = {
            requestId: response.requestId,
            calculationReceivedAtTime: Date.now(),
            values: response.data.map(({ id, layerId, monthKey }) => {
              const cacheKey = getFormulaCacheKey(id, monthKey, []);
              return {
                id,
                monthKey,
                value: undefined,
                cacheKey,
                layerId,
              };
            }),
          };
          DataArbiter.get().forwardObjectRowsResponse(res, isBackendCalculation ?? false);
          Sentry.withScope((scope) => {
            scope.setLevel('warning');
            Sentry.captureMessage(response.message);
          });
        } else if (response.type === 'evaluateBlock') {
          const { id, cacheKey, data, requestId, layerId } = response;
          state.groupsLoading[id] = false;
          state.groupsByBlockId[id] = {
            cacheKey,
            groups: data,
          };

          const res: BlockResponse = {
            id,
            requestId,
            layerId,
            groups: data,
          };

          DataArbiter.get().forwardBlockResponse(res, isBackendCalculation ?? false);
        } else if (response.type === 'error') {
          Sentry.withScope((scope) => {
            scope.setLevel('warning');
            Sentry.captureMessage(response.message);
          });
        }
      });
    },
    calculationsSliceInvalidateKeysForLayers(
      state,
      action: PayloadAction<Array<{ keysToDelete: CacheKey[]; layerId: LayerId }>>,
    ) {
      incrementCalculationsStateVersion(state);
      action.payload.forEach(({ keysToDelete, layerId }) => {
        const idToMonthKeys = DataArbiter.get().getMonthKeysByEntityIdForLayer(layerId);
        keysToDelete.forEach((key) => {
          let toDelete = [key];
          if (key.endsWith(ALL_MONTHS_KEY)) {
            const { id } = getIdAndMonthKeyFromFormulaCacheKey(key);
            const monthKeys = idToMonthKeys?.get(id);
            if (monthKeys != null) {
              toDelete = Array.from(monthKeys).map((monthKey) => getFormulaCacheKey(id, monthKey));
            }
          }
          toDelete.forEach((k) => {
            DataArbiter.get().setCacheValueIsStale({ cacheKey: k, layerId });
          });
        });
      });
    },
    calculationsSliceResetCacheForLayers(state, action: PayloadAction<LayerId[]>) {
      incrementCalculationsStateVersion(state);

      const layerIds = action.payload;
      if (layerIds.includes(ALL_LAYERS_KEY)) {
        // the GridDataArbiter is refreshed in the formulaCacheInvalidator
        return;
      }
      for (const layerId of layerIds) {
        DataArbiter.get().dropLayer(layerId);
      }
    },
    calculationsSliceDeleteTempKeys(state) {
      state.impactCalculations = {
        valueLoading: {},
        values: {},
      };
    },
    setClientId(state, action: PayloadAction<string>) {
      state.clientId = action.payload;
    },
    setCalculationEngineOverride(state, action: PayloadAction<CalculationEngine | null>) {
      state.calculationEngineOverride = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentLayerAction, (state, action) => {
      setCurrentLayer(action.payload);
    });
  },
});

/**
 * This should be called anytime the UI should change, e.g. to show loading or if a value changed
 */
function incrementCalculationsStateVersion(state: WritableDraft<CalculationsSlice>) {
  state.calculationsStateVersion = state.calculationsStateVersion + 1;
}

export const {
  calculationsSliceInvalidateKeysForLayers,
  calculationsSliceResetCacheForLayers,
  calculationsSliceDeleteTempKeys,
  setClientId,
  setResponse,
  setCalculationEngineOverride,
} = calculationsSlice.actions;

export default calculationsSlice.reducer;
