import { replaceEntity } from 'helpers/driverFormulas';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { DimensionalPropertyId } from 'reduxStore/models/collections';
import { AttributeId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';

export const replaceThisKeywordWithFiteredObjectSpec = (
  formula: string,
  objectSpecId: BusinessObjectSpecId,
  keyAttributeByDimPropertyId: Record<DimensionalPropertyId, AttributeId>,
): string => {
  const pattern = `object\\(this`;
  const re = new RegExp(pattern, 'gi');

  const replaceFunction = (substring: string) => {
    const substringWithoutFinalClosingParen = substring.substring(0, substring.length - 1);
    return `sum(${substringWithoutFinalClosingParen.replace(re, `objectSpec(${objectSpecId}`)}, ${createObjectFilter(keyAttributeByDimPropertyId)}))`;
  };

  return replaceEntity(formula, re, replaceFunction);
};

const createObjectFilter = (
  keyAttributeByDimPropertyId: Record<DimensionalPropertyId, AttributeId>,
) => {
  const filterConditions = Object.entries(keyAttributeByDimPropertyId).map(
    ([propertyId, attrId]) => {
      return `field(${propertyId}, relative(0,0)) == ${attrId}`;
    },
  );

  return `filter(${filterConditions.join(' && ')})`;
};

export const replaceThisKeywordWithDriver = (
  formula: string,
  fieldSpecId: BusinessObjectFieldSpecId,
  driverId: DriverId,
): string => {
  const pattern = `object\\(this,\\s*field\\(${fieldSpecId},\\s*(.+?)\\)(?![\\)])`;
  const re = new RegExp(pattern, 'gi');

  const replaceFunction = (substring: string) => {
    return substring.replace(re, `driver(${driverId}, $1`);
  };

  return replaceEntity(formula, re, replaceFunction);
};
