import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

/**
 * Finds all the keys in `items` that are contiguously connected to
 * `mustInclude`. So the return value includes all items contiguously
 * before/after `mustInclude`. If there is a missing value, between a value in
 * `items` and `mustInclude`, then it won't be in the result.
 * @param items
 * @param mustInclude
 * @returns
 */
export function getContiguousKeys<T>(
  items: Array<{ position: number; key: T }>,
  mustInclude: T,
): T[] {
  let res: Array<{ position: number; key: T }> = [];
  let seen = false;
  const sortedItems = sortBy(items, 'position');

  for (const item of sortedItems) {
    const { key, position } = item;
    if (isEqual(key, mustInclude)) {
      seen = true;
    }
    if (res.length === 0 || res[res.length - 1].position + 1 === position) {
      res.push(item);
    } else if (seen) {
      break;
    } else {
      res = [item];
    }
  }

  return res.map((item) => item.key);
}
