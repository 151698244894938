import { BoxProps } from '@chakra-ui/react';
import React, { useContext } from 'react';

import CellContextProviderLight from 'components/CellContextProvider/CellContextProviderLight';
import DriverPropertyTableCellLight from 'components/DriverPropertyTableCell/DriverPropertyTableCellLight';
import Formula from 'components/Formula/Formula';
import { useFormulaDisplay } from 'components/SubmodelTable/useFormulaDisplay';
import { DriverRowContext } from 'config/driverRowContext';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import { FormulaDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import useDriverCellRef from 'hooks/useDriverCellRef';

interface Props {
  columnType: ModelViewColumnType;
  columnLayerId: ColumnLayerId;
  formulaDisplay: FormulaDisplay | null;
  isSticky?: boolean;
  hideFormula?: boolean;
  color?: BoxProps['color'];
}

const FormulaTableCellLight: React.FC<Props> = ({
  columnType,
  columnLayerId,
  formulaDisplay,
  isSticky,
  hideFormula,
  color,
}) => {
  return (
    <DriverPropertyTableCellLight
      columnType={columnType}
      columnLayerId={columnLayerId}
      isSticky={isSticky != null ? isSticky : true}
    >
      {formulaDisplay != null && !hideFormula && (
        <Formula formulaDisplay={formulaDisplay} color={color} />
      )}
    </DriverPropertyTableCellLight>
  );
};

interface FormulaTableCellLightWrapperProps {
  columnLayerId: ColumnLayerId;
  isSticky?: boolean;
  hideFormula?: boolean;
  columnType: 'formula' | 'actualsFormula';
}

const FormulaTableCellLightWrapper: React.FC<FormulaTableCellLightWrapperProps> = (props) => {
  const { columnType, columnLayerId } = props;
  const cellRef = useDriverCellRef({ columnType, columnLayerId });

  const { driverId, comparisonRowLayerId } = useContext(DriverRowContext);

  const layerId = columnLayerId ?? comparisonRowLayerId;

  const isActuals = columnType === 'actualsFormula';

  const { color, formulaDisplay } = useFormulaDisplay({
    isActuals,
    driverId,
    layerId,
  });

  return (
    <CellContextProviderLight cellRef={cellRef}>
      <FormulaTableCellLight {...props} formulaDisplay={formulaDisplay} color={color} />
    </CellContextProviderLight>
  );
};

export default React.memo(FormulaTableCellLightWrapper);
