import classNames from 'classnames';
import React from 'react';

import CellValueTooltipContainer from 'components/CellTooltipContainer/CellTooltipContainer';
import DisplayCell from 'components/DisplayCell/DisplayCell';
import { ImpactType, ValueType } from 'generated/graphql';
import { stopEventPropagation } from 'helpers/browserEvent';
import { getDisplayValue } from 'helpers/formatting';
import useCellTooltip from 'hooks/useCellTooltip';
import { DisplayConfiguration, NullableValue, NumberValue } from 'reduxStore/models/value';
import { CalculationError } from 'types/dataset';

import styles from './NumericDisplayCell.module.scss';

const NUMERIC_DISPLAY_CELL_CLASS = 'numericDisplayCell';

interface Props {
  onDoubleClick?: React.MouseEventHandler;
  align?: 'left' | 'right';
  value: NullableValue<NumberValue>['value'];
  error?: CalculationError;
  displayConfiguration: DisplayConfiguration;
  impactType?: ImpactType;
}

const NumericDisplayCell = React.forwardRef<HTMLDivElement, Props>(
  ({ value, align = 'right', displayConfiguration, impactType, onDoubleClick, error }, ref) => {
    const displayValue = getDisplayValue(value, error, { displayConfiguration, impactType });
    const tooltipData = useCellTooltip();
    return (
      <CellValueTooltipContainer tooltipData={tooltipData}>
        <DisplayCell
          ref={ref}
          align={align}
          onDoubleClick={(ev) => {
            stopEventPropagation(ev);
            onDoubleClick?.(ev);
          }}
          valueType={ValueType.Number}
        >
          <div
            className={classNames(
              styles.numericDisplayCell,
              {
                [styles.alignRight]: align === 'right',
              },
              NUMERIC_DISPLAY_CELL_CLASS,
            )}
          >
            {displayValue}
          </div>
        </DisplayCell>
      </CellValueTooltipContainer>
    );
  },
);

export default NumericDisplayCell;
