import { Portal, useBoolean } from '@chakra-ui/react';
import { PropGetter } from '@chakra-ui/react-utils';
import { noop } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import ContextMenuItems from 'components/ContextMenuItems/ContextMenuItems';
import { ContextMenuSingletonReactContext } from 'components/ContextMenuSingleton/ContextMenuSingleton';
import { preventEventDefault } from 'helpers/browserEvent';
import useAppSelector from 'hooks/useAppSelector';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { hasActiveCopilotSessionSelector } from 'selectors/copilotSelector';

export const ContextMenuContent: React.FC<{ items: ContextMenuItem[]; closeMenu: () => void }> = ({
  items,
  closeMenu,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, closeMenu);

  return <ContextMenuItems ref={ref} items={items} closeMenu={closeMenu ?? noop} />;
};

ContextMenuContent.displayName = 'ContextMenuContent';

const ContextMenuReactContext = React.createContext<{
  openContextMenu: (event?: React.MouseEvent) => void;
  closeContextMenu: () => void;
  isOpen: boolean;
}>({ openContextMenu: noop, closeContextMenu: noop, isOpen: false });

export const useContextMenu = () => {
  return useContext(ContextMenuReactContext);
};

type Props = {
  children: React.ReactElement;
  items?: ContextMenuItem[];
  content?: React.ReactElement;
  shouldShowOverlay?: boolean;
  onContextMenuOpen?: (ev?: React.MouseEvent) => void;
  shouldHandleEvent?: (ev: React.MouseEvent) => boolean;
};

const ContextMenu: React.FC<Props> = ({
  children,
  items,
  content,
  shouldShowOverlay = true,
  shouldHandleEvent,
  onContextMenuOpen,
}) => {
  const { openSingletonContextMenu, closeSingletonContextMenu, containerRef } = useContext(
    ContextMenuSingletonReactContext,
  );
  const hasActiveCopilotSession = useAppSelector(hasActiveCopilotSessionSelector);
  const [isOpen, setIsOpen] = useBoolean();
  const child = React.Children.only(children) as React.ReactElement & {
    ref?: React.Ref<HTMLElement>;
  };

  const hasEmptyItems = items != null && items.length === 0;

  const openMenu = useCallback(
    (event?: React.MouseEvent) => {
      if (!hasEmptyItems) {
        openSingletonContextMenu(shouldShowOverlay);
        setIsOpen.on();
        onContextMenuOpen?.(event);
      }
    },
    [hasEmptyItems, onContextMenuOpen, openSingletonContextMenu, setIsOpen, shouldShowOverlay],
  );

  const closeMenu = useCallback(() => {
    setIsOpen.off();
    if (containerRef.current) {
      closeSingletonContextMenu();
    }
  }, [setIsOpen, containerRef, closeSingletonContextMenu]);

  const onContextMenu: React.MouseEventHandler = useCallback(
    (ev) => {
      if (shouldHandleEvent == null || shouldHandleEvent(ev)) {
        preventEventDefault(ev);
        openMenu(ev);
      }
    },
    [shouldHandleEvent, openMenu],
  );

  useEffect(() => {
    if (hasActiveCopilotSession) {
      closeMenu();
    }
  }, [hasActiveCopilotSession, closeMenu]);

  const trigger = React.cloneElement(child, {
    ...child.props,
    ref: child.ref,
    onContextMenu,
  } as ReturnType<PropGetter>);

  const contextValue = useMemo(() => {
    return {
      openContextMenu: openMenu,
      closeContextMenu: closeMenu,
      isOpen,
    };
  }, [closeMenu, isOpen, openMenu]);
  return (
    <ContextMenuReactContext.Provider value={contextValue}>
      {trigger}
      {isOpen && (
        <Portal containerRef={containerRef}>
          {content ?? (items != null && <ContextMenuContent items={items} closeMenu={closeMenu} />)}
        </Portal>
      )}
    </ContextMenuReactContext.Provider>
  );
};

ContextMenu.displayName = 'ContextMenu';

export default React.memo(ContextMenu);
