import { useMemo } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import { deleteDriverGroup } from 'reduxStore/actions/driverGroupMutations';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import TrashIcon from 'vectors/Trash';

export default function useDriverGroupContextMenuItems(
  driverGroupId?: DriverGroupId,
): ContextMenuItem[] {
  const { readOnly } = useBlockContext();
  const dispatch = useAppDispatch();

  const items = useMemo<ContextMenuItem[]>(() => {
    if (driverGroupId == null) {
      return [];
    }
    return [
      {
        text: 'Delete',
        icon: <TrashIcon />,
        isEdit: true,
        destructive: true,
        onSelect: () => {
          if (driverGroupId != null) {
            dispatch(deleteDriverGroup({ id: driverGroupId }));
          }
        },
        shortcut: 'delete' as const,
      },
    ].filter((item) => !readOnly || !item.isEdit);
  }, [readOnly, dispatch, driverGroupId]);

  return items;
}
