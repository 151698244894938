import { deepEqual } from 'fast-equals';
import { DateTime } from 'luxon';
import { createSelectorCreator, lruMemoize } from 'reselect';

export const createDeepEqualSelector = createSelectorCreator(lruMemoize, {
  resultEqualityCheck: deepEqual,
});

export const createDateTimeDateRangeSelector = createSelectorCreator(lruMemoize, {
  resultEqualityCheck: (a: [DateTime, DateTime], b: [DateTime, DateTime]) => {
    if (!Array.isArray(a) || !Array.isArray(b) || a.length !== 2 || b.length !== 2) {
      throw new Error('unexpected result type for createDateTimeDateRangeSelector');
    }
    return a[0].equals(b[0]) && a[1].equals(b[1]);
  },
});
