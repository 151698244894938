import { DriverFormat } from 'generated/graphql';

export const ZERO_VALUES = [
  '-', // dash
  '–', // en dash
  '—', // em dash
];

/**
 * Strips user-friendly formatting from a string and converts it to a number
 * @param numberText the number as a string
 * @returns the input as a number
 */
export function getNumber(numberText: string, format?: DriverFormat): number {
  const hasNumicCharacters = /[0-9]+/.test(numberText);
  if (!hasNumicCharacters && ZERO_VALUES.some((zeroVal) => numberText.includes(zeroVal))) {
    return 0;
  }

  let parsed = numeralFast(numberText) ?? Number.NaN;

  // ensure that we treat percentages correctly if the user omits a '%' sign
  if (format === DriverFormat.Percentage && !numberText.includes('%') && !Number.isNaN(parsed)) {
    parsed /= 100;
  }

  return parsed;
}

function newAbbrevRE(suffix: string) {
  const currencySymbol = '$';
  return new RegExp('[^a-zA-Z]' + suffix + '(?:\\)|(\\' + currencySymbol + ')?(?:\\))?)?$', 'i');
}

const abbreviations = {
  thousand: { exp: 3, re: newAbbrevRE('k') },
  million: { exp: 6, re: newAbbrevRE('mm?') },
  billion: { exp: 9, re: newAbbrevRE('b') },
  trillion: { exp: 12, re: newAbbrevRE('t') },
} as const;

const isDigitRE = /[^0-9.]+/g;

export function numeralFast(string: string) {
  const stringOriginal = string;
  let value = 1;

  let k: keyof typeof abbreviations;
  for (k in abbreviations) {
    const abb = abbreviations[k];

    if (stringOriginal.match(abb.re)) {
      value *= Math.pow(10, abb.exp);
      break;
    }
  }

  // check for negative number
  value *=
    (string.split('-').length +
      Math.min(string.split('(').length - 1, string.split(')').length - 1)) %
      2 ===
    1
      ? 1
      : -1;

  const isPercentage = string.includes('%');
  // remove non numbers
  string = string.replace(isDigitRE, '');

  if (string.length === 0) {
    return null;
  }

  value *= Number(string);
  if (isPercentage) {
    value /= 100;
  }

  return value;
}

export function getTicks(min: number, max: number, numTicks: number): number[] {
  const tickSpacing = (max - min) / (numTicks - 1);
  const ticks = [];
  const crossesZero = min < 0 && max > 0;

  for (let i = 0; i < numTicks; i++) {
    ticks.push(min + i * tickSpacing);
  }

  if (crossesZero) {
    const closest = ticks.reduce(function (prev, curr) {
      return Math.abs(curr) < Math.abs(prev) ? curr : prev;
    });

    ticks[ticks.indexOf(closest)] = 0;
  }

  return ticks.sort();
}

export function toBytes(mb: number, sizes: 'KB' | 'MB' | 'GB'): number {
  if (sizes === 'KB') {
    return mb * 1024;
  }
  if (sizes === 'MB') {
    return mb * 1024 * 1024;
  }
  return mb * 1024 * 1024 * 1024;
}

export function getReadableFileSize(bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '0 Byte';
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + '' + sizes[i];
}
