/**
 * Finds a child's offset from the top edge of a container. Potentially expensive operation. Use with caution.
 * @returns the offset from the top edge of the container
 */
export const findInitialOffsetTop = (container: HTMLElement, target: HTMLElement): number => {
  return (
    target.getBoundingClientRect().top - container.getBoundingClientRect().top + container.scrollTop
  );
};

/**
 * Finds a child's offset from the left edge of a container. Potentially expensive operation. Use with caution.
 * @returns the offset from the left edge of the container
 */
export const findInitialOffsetLeft = (container: HTMLElement, target: HTMLElement): number => {
  return (
    target.getBoundingClientRect().left -
    container.getBoundingClientRect().left +
    container.scrollLeft
  );
};
