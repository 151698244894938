import * as Sentry from '@sentry/nextjs';

import { getDatabaseInternalPageType } from 'config/internalPages/databasePage';
import { getSubmodelInternalPageType } from 'config/internalPages/modelPage';
import { DriverType } from 'generated/graphql';
import { getFirstNavigableEntity } from 'helpers/folders';
import { ExtSource, sourceSupportsQueryReads } from 'helpers/integrations';
import { getLastVisitedPage } from 'helpers/localState';
import { NavigationOptions, routeTo } from 'helpers/navigation';
import {
  startDetailPaneOpenPerfMonitor,
  startPageNavigationPerfMonitor,
} from 'helpers/performanceMonitor';
import { UnauthorizedReason } from 'helpers/permissions';
import { invert, isNotNull } from 'helpers/typescript';
import { jumpToDriver } from 'reduxStore/actions/jumpToDriver';
import {
  PAGE_TYPE_TO_PATH,
  buildUrlFromState,
} from 'reduxStore/actions/navigateTo/buildUrlFromState';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverGroupIdOrNull } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId } from 'reduxStore/models/events';
import { ExtDriverSource } from 'reduxStore/models/extDrivers';
import { LayerId } from 'reduxStore/models/layers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import {
  DataSourcePageState,
  PageState,
  PageType,
  closeModal,
  propagateDataSourcePageFromUrl,
  setNavigatingTo,
} from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { accessibleLayersIdsForPageTypeSelector } from 'selectors/accessibleLayerIdsSelector';
import { isViewingModelPageSelector } from 'selectors/blocksPagesSelector';
import { blocksPageByInternalPageTypeSelector } from 'selectors/blocksPagesTableSelector';
import { businessObjectSpecsByExtSourceSelector } from 'selectors/businessObjectSpecsSelector';
import { sourceSupportsQueryWritesSelector } from 'selectors/dataSourceSelector';
import { isDetailPaneOpenSelector } from 'selectors/detailPaneSelectors';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { dimensionalDriversBySubDriverIdSelector } from 'selectors/driversSelector';
import { extDriversBySourceSelector } from 'selectors/extDriversSelector';
import { extObjectSpecsByKeySelector } from 'selectors/extObjectSpecsSelector';
import { integrationQuerySelector } from 'selectors/integrationQueriesSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { navSubmodelIdSelector } from 'selectors/navSubmodelSelector';
import {
  navigationEntitiesSelector,
  navigationEntitiesTreeSelector,
} from 'selectors/navigationSelector';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';
import { blockIdBySubmodelIdSelector } from 'selectors/submodelPageSelector';
import { driversBySubmodelIdSelector } from 'selectors/submodelSelector';
import { getOrderedGroupDrivers } from 'selectors/submodelTableGroupsSelector';

const PAGE_PATH_TO_PAGE_TYPE = invert(PAGE_TYPE_TO_PATH);
type PagePath = keyof typeof PAGE_PATH_TO_PAGE_TYPE;

function validatePagePath(path: string): path is PagePath {
  return (Object.values(PAGE_TYPE_TO_PATH) as readonly string[]).includes(path);
}

export const pagePathToPageType = (pagePath: string): PageType | undefined => {
  const isValidPath = validatePagePath(pagePath);
  if (!isValidPath) {
    return undefined;
  }
  return PAGE_PATH_TO_PAGE_TYPE[pagePath];
};

const navigateTo = ({
  pageType,
  id,
  accountId,
  opts,
}: {
  pageType: NonNullable<PageState>['type'];
  id?: string;
  accountId?: string;
  opts?: NavigationOptions;
}): AppThunk => {
  return (_dispatch, getState) => {
    const state = getState();

    const selectedOrg = selectedOrgSelector(state);
    if (selectedOrg == null) {
      return;
    }

    const url = buildUrlFromState(
      state,
      { pageType, id },
      accountId != null ? { detailId: accountId } : undefined,
    );
    if (url == null) {
      return;
    }

    routeTo(url, opts);
  };
};

export const navigateToBlocksPage = (id: string, opts?: NavigationOptions): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const navigationEntities = navigationEntitiesSelector(state);
    const hasValidEntity = navigationEntities.some(
      (entity) =>
        entity.id === id &&
        entity.permissions.read &&
        (entity.type === 'page' || entity.type === 'database'),
    );
    if (!hasValidEntity) {
      return;
    }

    const pageType = 'blocksPage';
    startPageNavigationPerfMonitor({ pageType, id });
    dispatch(setNavigatingTo({ type: pageType, id }));
    dispatch(navigateTo({ pageType, id, opts }));
  };
};

export const navigateToDataSourcePage = ({
  source,
  accountId,
  opts,
}: {
  source?: ExtDriverSource;
  accountId?: string;
  opts?: NavigationOptions;
} = {}): AppThunk => {
  return (dispatch) => {
    const pageType = 'dataSourcePage';
    dispatch(setNavigatingTo({ type: pageType, source }));
    dispatch(navigateTo({ pageType, id: source, accountId, opts }));
  };
};

export const navigateToTemplatePage = (opts?: NavigationOptions): AppThunk => {
  return (dispatch) => {
    const pageType = 'templatesPage';
    dispatch(setNavigatingTo({ type: pageType }));
    dispatch(navigateTo({ pageType, opts }));
  };
};

export const navigateToDefaultPage = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const navigationEntitiesTreeRoot = navigationEntitiesTreeSelector(state);
    const navigationEntities = navigationEntitiesSelector(state);
    const capabilitiesProvider = accessCapabilitiesSelector(state);
    const firstNavigableEntity = getFirstNavigableEntity(navigationEntitiesTreeRoot);
    const lastVisitedPageState = getLastVisitedPage();
    // try the last page the user visited first
    if (lastVisitedPageState != null) {
      switch (lastVisitedPageState.type) {
        case 'plansPage': {
          if (capabilitiesProvider.canReadPlans) {
            dispatch(navigateToPlansPage());
            return;
          }
          break;
        }
        case 'unlistedDriversPage': {
          if (capabilitiesProvider.canReadUnlistedDriversPage) {
            dispatch(navigateToUnlistedDriversPage());
            return;
          }
          break;
        }
        default: {
          const hasValidEntity = navigationEntities.some(({ type, id, permissions, context }) => {
            const isSameEntity =
              id === lastVisitedPageState.id ||
              (type === 'model' && context === lastVisitedPageState.id);
            return isSameEntity && permissions.read;
          });
          if (hasValidEntity) {
            if (lastVisitedPageState.type === 'model') {
              dispatch(navigateToSubmodelPage(lastVisitedPageState.id));
            } else {
              dispatch(navigateToBlocksPage(lastVisitedPageState.id));
            }
            return;
          }

          break;
        }
      }
    }

    // then attempt to use the first item in the left nav
    // fallback to any non-folder entity
    const entitiesToTry = [firstNavigableEntity, ...navigationEntities]
      .filter(isNotNull)
      .filter((entity) => entity.type !== 'folder');

    const defaultEntity = entitiesToTry.find((entity) => entity.permissions.read);
    if (defaultEntity?.type === 'page' || defaultEntity?.type === 'database') {
      dispatch(navigateToBlocksPage(defaultEntity.id));
    } else if (defaultEntity?.type === 'model' && defaultEntity.context != null) {
      // N.B. we need to pass the submodel ID to this function
      dispatch(navigateToSubmodelPage(defaultEntity.context));
    } else if (!capabilitiesProvider.isOrgMember) {
      routeTo('/static/unauthorized', {
        openInNewTab: false,
        query: { reason: UnauthorizedReason.PageAccessDenied },
      });
    }
  };
};

export const navigateToSubmodelPage = (
  submodelId: SubmodelId,
  opts?: NavigationOptions,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const isViewingModelPage = isViewingModelPageSelector(state);
    const currentSubmodelId = navSubmodelIdSelector(state);

    if (
      isViewingModelPage &&
      submodelId === currentSubmodelId &&
      (opts == null || !opts.openInNewTab)
    ) {
      if (isDetailPaneOpenSelector(state)) {
        dispatch(closeDetailPane());
      }
    }

    const capabilitiesProvider = accessCapabilitiesSelector(state);
    const blocksPageByInternalPageType = blocksPageByInternalPageTypeSelector(state);
    const page = blocksPageByInternalPageType[getSubmodelInternalPageType(submodelId)];
    const resolvedSubmodelId =
      page != null && capabilitiesProvider.canReadPage(page.id) ? submodelId : null;
    if (resolvedSubmodelId == null) {
      return;
    }

    const pageType = 'submodelPage';
    const accessibleLayerIds = accessibleLayersIdsForPageTypeSelector(state, {
      pageType,
      pageId: resolvedSubmodelId,
    });

    if (accessibleLayerIds.length === 0) {
      const currentLayerId = currentLayerIdSelector(state);
      Sentry.withScope((scope) => {
        scope.setExtras({
          pageId: resolvedSubmodelId,
          currentLayerId,
        });
        Sentry.captureException('Unable to access any scenario');
      });
      routeTo('/static/unauthorized', {
        openInNewTab: false,
        query: { reason: UnauthorizedReason.CanNotAccessAnyScenario },
      });
      return;
    }

    startPageNavigationPerfMonitor({ pageType, id: resolvedSubmodelId });
    dispatch(setNavigatingTo({ type: pageType, id: resolvedSubmodelId }));
    dispatch(navigateTo({ pageType, id: resolvedSubmodelId, opts }));
  };
};

export const navigateToDriverGroup = (
  groupId: DriverGroupIdOrNull,
  submodelId: SubmodelId,
  opts: NavigationOptions,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const driversBySubmodelId = driversBySubmodelIdSelector(state);
    const blockId = blockIdBySubmodelIdSelector(state)[submodelId];
    if (blockId == null) {
      return;
    }

    const dimDriverBySubDriverId = dimensionalDriversBySubDriverIdSelector(state);
    const dimensionsById = dimensionsByIdSelector(state);
    const basicDrivers = (driversBySubmodelId[submodelId] ?? []).filter(
      (d) => d.type !== DriverType.Dimensional,
    );
    const driverIds = getOrderedGroupDrivers({
      groupId,
      blockId,
      basicDrivers,
      dimDriverBySubDriverId,
      dimensionsById,
    });
    if (driverIds.length === 0) {
      return;
    }

    dispatch(closeModal());
    dispatch(jumpToDriver({ driverId: driverIds[0], opts }));
  };
};

export const navigateToSourceIntegrationQuery = ({
  source,
  specId,
}: {
  source: ExtSource;
  specId: string;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const specsBySource = businessObjectSpecsByExtSourceSelector(state);
    const sourceSpecs = specsBySource[source] || [];
    const extDriversBySource = extDriversBySourceSelector(state);
    const drivers = source != null ? extDriversBySource.get(source) : undefined;
    const extSpecsById = extObjectSpecsByKeySelector(state);

    const isQueryable =
      sourceSupportsQueryReads(source) || sourceSupportsQueryWritesSelector(state, source);
    let subPage: DataSourcePageState['navSubPage'];

    if (isQueryable) {
      subPage = { type: 'integrationQuery' };
      const extSpecKeys =
        specId != null ? sourceSpecs.find((s) => s.id === specId)?.extSpecKeys : undefined;
      if (extSpecKeys && extSpecKeys.length > 0) {
        const extSpecKey = extSpecKeys[0];
        const extSpec = extSpecsById[extSpecKey];
        const queryId = extSpec.integrationQueryId ?? '';

        const query = integrationQuerySelector(state, queryId);
        const linkedAccountId = query?.linkedAccountId ?? '';

        // Navigate to the integration page and integration query tab
        dispatch(
          navigateToDefaultDataSourcePage({
            source,
            accountId: linkedAccountId,
            queryId,
          }),
        );
        return;
      }
    } else if (
      (drivers == null || drivers.length === 0) &&
      sourceSpecs != null &&
      sourceSpecs.length > 0
    ) {
      subPage = { type: 'linkedObjects', specId: sourceSpecs[0].id };
    } else {
      subPage = {
        type: 'extDrivers',
        selectedTab: isQueryable ? 'sqlOutput' : 'syncedReports',
      };
    }
    dispatch(propagateDataSourcePageFromUrl({ source, subPage }));
  };
};
export const navigateToDefaultDataSourcePage = ({
  source,
  accountId,
  queryId,
}: {
  source?: ExtSource;
  accountId?: string;
  queryId?: string;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const specsBySource = businessObjectSpecsByExtSourceSelector(state);
    const extDriversBySource = extDriversBySourceSelector(state);
    const drivers = source != null ? extDriversBySource.get(source) : undefined;
    const specs = source != null ? specsBySource[source] : undefined;
    const isQueryable =
      sourceSupportsQueryReads(source) || sourceSupportsQueryWritesSelector(state, source);
    let subPage: DataSourcePageState['navSubPage'];

    if (isQueryable) {
      subPage = { type: 'integrationQuery', selectedIntegrationQueryId: queryId };
    } else if ((drivers == null || drivers.length === 0) && specs != null && specs.length > 0) {
      subPage = { type: 'linkedObjects', specId: specs[0].id };
    } else {
      subPage = {
        type: 'extDrivers',
        selectedTab: isQueryable ? 'sqlOutput' : 'syncedReports',
      };
    }
    dispatch(propagateDataSourcePageFromUrl({ accountId, source, subPage }));
  };
};

export const navigateToDriverDetailPane = (
  { driverId }: { driverId: DriverId },
  opts: NavigationOptions,
): AppThunk => {
  return (_dispatch, getState) => {
    const state = getState();
    const url = buildUrlFromState(state, {}, { detail: PaneType.Driver, detailId: driverId });
    if (url == null) {
      return;
    }

    startDetailPaneOpenPerfMonitor({ paneType: PaneType.Driver, entityId: driverId });
    routeTo(url, opts);
  };
};

export const navigateToDatabasePage = (
  {
    objectSpecId,
  }: {
    objectSpecId: BusinessObjectSpecId;
  },
  opts: NavigationOptions,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const blocksPageByInternalPageType = blocksPageByInternalPageTypeSelector(state);
    const internalPageType = getDatabaseInternalPageType(objectSpecId);
    const databasePage = blocksPageByInternalPageType[internalPageType];
    if (databasePage == null) {
      return;
    }

    dispatch(navigateToBlocksPage(databasePage.id, opts));
  };
};

export const navigateToPlansPage = (): AppThunk => {
  return (dispatch) => {
    const pageType = 'plansPage';
    startPageNavigationPerfMonitor({ pageType, id: 'plansPage' });
    dispatch(setNavigatingTo({ type: pageType }));
    dispatch(navigateTo({ pageType }));
  };
};

export const navigateToUnlistedDriversPage = (): AppThunk => {
  return (dispatch) => {
    const pageType = 'unlistedDriversPage';
    dispatch(setNavigatingTo({ type: pageType }));
    dispatch(navigateTo({ pageType }));
  };
};

export const navigateToObjectDetailPane = (
  {
    objectId,
  }: {
    objectId: BusinessObjectId;
  },
  opts: NavigationOptions,
): AppThunk => {
  return (_dispatch, getState) => {
    const state = getState();
    const url = buildUrlFromState(state, {}, { detail: PaneType.Object, detailId: objectId });
    if (url == null) {
      return;
    }

    startDetailPaneOpenPerfMonitor({ paneType: PaneType.Object, entityId: objectId });
    routeTo(url, opts);
  };
};

export const navigateToPlanDetailPane = (
  {
    eventGroupId,
  }: {
    eventGroupId: EventGroupId;
  },
  opts: NavigationOptions,
): AppThunk => {
  return (_dispatch, getState) => {
    const state = getState();
    const url = buildUrlFromState(state, {}, { detail: PaneType.Plan, detailId: eventGroupId });
    if (url == null) {
      return;
    }

    startDetailPaneOpenPerfMonitor({ paneType: PaneType.Plan, entityId: eventGroupId });
    routeTo(url, opts);
  };
};

export const closeDetailPane = (): AppThunk => (dispatch) => {
  dispatch(closeModal());
  dispatch(navigateToUrlFromStateWithoutQueryParams());
};

export const navigateToScenarioComparisonModal =
  ({ compareToLayerIds }: { compareToLayerIds: LayerId[] }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const url = buildUrlFromState(state, {}, { compareTo: compareToLayerIds });
    if (url == null) {
      return;
    }
    routeTo(url);
  };

export const closeScenarioComparisonModal = (): AppThunk => (dispatch) => {
  dispatch(closeModal());
  dispatch(navigateToUrlFromStateWithoutQueryParams());
};

export const navigateToUrlFromStateWithoutQueryParams = (): AppThunk => (_dispatch, getState) => {
  const state = getState();
  const url = buildUrlFromState(state, {});
  if (url == null) {
    return;
  }

  routeTo(url);
};
