import { DateTime } from 'luxon';
import { useMemo } from 'react';

import {
  DisposeFn,
  RequestValueProps,
  useWebWorkerDataProviderContext,
} from 'components/WebWorkerDataProvider/Context';
import { getMonthKey } from 'helpers/dates';
import useAppSelector from 'hooks/useAppSelector';
import { DisposeFnKey, useRequestPaginatedRows } from 'hooks/useRequestPaginatedRows';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { GroupWithDrivers } from 'selectors/submodelTableGroupsSelector';

type Row = {
  driverId: DriverId;
  layerIds: LayerId[];
  dateRange: [DateTime, DateTime];
};

const getKey = ({
  driverId,
  layerId,
  dateRange,
}: Omit<Row, 'layerIds'> & { layerId: LayerId }): DisposeFnKey => {
  const startMonthKey = getMonthKey(dateRange[0]);
  const endMonthKey = getMonthKey(dateRange[1]);
  return `${driverId}.${layerId}.${startMonthKey}.${endMonthKey}`;
};

const getKeys = ({ driverId, layerIds, dateRange }: Row): DisposeFnKey[] => {
  return layerIds.map((layerId): DisposeFnKey => getKey({ driverId, layerId, dateRange }));
};

const makeRequestRowValue =
  (requestValue: (props: RequestValueProps) => DisposeFn) =>
  (row: Row): Array<[DisposeFnKey, DisposeFn]> => {
    const { driverId, layerIds, dateRange } = row;

    return layerIds.map((layerId) => {
      return [
        getKey({ driverId, layerId, dateRange }),
        requestValue({
          id: driverId,
          type: 'driver',
          dateRange,
          layerId,
        }),
      ];
    });
  };

export const useRequestDriverGridDataView = ({
  groups,
  blockId,
  pageSize,
  disable,
}: {
  groups: GroupWithDrivers[];
  blockId: BlockId;
  pageSize: number;
  disable?: boolean;
}) => {
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const blockLayerComparison = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  const { requestValue } = useWebWorkerDataProviderContext();

  const drivers = useMemo(() => groups.flatMap((g) => g.driverIds), [groups]);

  const rows: Row[] = useMemo(
    () =>
      drivers.map((driverId) => ({
        driverId,
        layerIds: [currentLayerId, ...blockLayerComparison],
        dateRange,
      })),
    [blockLayerComparison, currentLayerId, dateRange, drivers],
  );
  const requestRowValue = useMemo(() => makeRequestRowValue(requestValue), [requestValue]);

  return useRequestPaginatedRows({
    rows,
    pageSize,
    getKeys,
    requestRowValue,
    disable,
  });
};
