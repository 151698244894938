import classNames from 'classnames';
import React from 'react';

interface Props {
  isFirstRow: boolean;
  testId?: string;
  onContextMenu?: React.MouseEventHandler;
  children: React.ReactNode;
}

const RowContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ children, isFirstRow, onContextMenu, testId }, ref) => {
    return (
      <div
        data-testid={testId}
        ref={ref}
        onContextMenu={onContextMenu}
        className={classNames('rowContainer', { isFirstRow })}
        role="row"
      >
        {children}
      </div>
    );
  },
);

export default RowContainer;
