import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import { TOOLTIP_OPEN_DELAY } from 'components/RunwayApp/GlobalNavigationMenu';
import { KEYBOARD_SHORTCUTS } from 'config/shortcuts';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { EventGroup } from 'reduxStore/models/events';
import { openPlanPickerPopover } from 'reduxStore/reducers/pageSlice';
import {
  cellSelectionHasCellWithoutEventSelector,
  eventGroupsImpactingCellSelectionSelector,
  planPickerSelectedEventGroupNameSelector,
} from 'selectors/eventsAndGroupsSelector';
import { activeCellMultiDeltaImpactEventGroupNamesSelector } from 'selectors/planPickerSelector';
import { cellSelectionIncludesActualsSelector } from 'selectors/prevailingCellSelectionSelector';
import { PlansIcon } from 'vectors';

export const EVENT_GROUP_TAG_BUTTON_ID = 'event-group-tag-button';

type EventGroupTagDisplayProperties =
  | {
      disabled: true;
      tooltip: string;
    }
  | {
      disabled: false;
      label: string;
      color: string;
    };

function getEventGroupTagDisplayProperties(
  eventGroups: EventGroup[],
  planPickerEventGroupName: string | undefined,
  multiPlanPickerEventGroupNames: string[],
  isCellWithoutEventGroup: boolean,
  isActualsCellSelected: boolean,
): EventGroupTagDisplayProperties {
  if (isActualsCellSelected) {
    return { disabled: true, tooltip: 'Select only forecast cells to tag plan' };
  }

  if (multiPlanPickerEventGroupNames.length > 0) {
    const label =
      multiPlanPickerEventGroupNames.length === 1 ? multiPlanPickerEventGroupNames[0] : 'Mixed';
    return { disabled: false, label, color: 'gray.600' };
  }

  if (planPickerEventGroupName != null) {
    return { disabled: false, label: planPickerEventGroupName, color: 'gray.600' };
  }

  if (eventGroups.length === 0) {
    return { disabled: false, label: 'Tag plan', color: 'gray.500' };
  }

  if (eventGroups.length === 1 && !isCellWithoutEventGroup) {
    return { disabled: false, label: eventGroups[0].name, color: 'gray.600' };
  }

  return { disabled: false, label: 'Mixed', color: 'gray.600' };
}

const EventGroupTag: React.FC = () => {
  const dispatch = useAppDispatch();
  const eventGroupsImpactingCellSelection = useAppSelector(
    eventGroupsImpactingCellSelectionSelector,
  );
  const planPickerSelectedEventGroupName = useAppSelector(planPickerSelectedEventGroupNameSelector);
  const cellSelectionHasCellWithoutEvent = useAppSelector(cellSelectionHasCellWithoutEventSelector);
  const cellSelectionIncludesActuals = useAppSelector(cellSelectionIncludesActualsSelector);
  const activeCellMultiDeltaImpactEventGroupNames = useAppSelector(
    activeCellMultiDeltaImpactEventGroupNamesSelector,
  );

  const displayProperties = getEventGroupTagDisplayProperties(
    eventGroupsImpactingCellSelection,
    planPickerSelectedEventGroupName,
    activeCellMultiDeltaImpactEventGroupNames,
    cellSelectionHasCellWithoutEvent,
    cellSelectionIncludesActuals,
  );

  if (displayProperties.disabled) {
    return (
      <Tooltip
        label={displayProperties.tooltip}
        placement="top"
        gutter={2}
        openDelay={TOOLTIP_OPEN_DELAY}
      >
        <Button size="sm" variant="iconText" leftIcon={<PlansIcon />} isDisabled>
          <Text>Tag plan</Text>
        </Button>
      </Tooltip>
    );
  }

  const { color, label } = displayProperties;

  return (
    <Tooltip
      label={
        <Flex direction="column" align="flex-start">
          <Text>Tag with a plan</Text>
          <Text color="gray.500">{KEYBOARD_SHORTCUTS.metaEnter}</Text>
        </Flex>
      }
      placement="top"
      gutter={2}
      openDelay={TOOLTIP_OPEN_DELAY}
    >
      <Button
        data-testid={EVENT_GROUP_TAG_BUTTON_ID}
        id={EVENT_GROUP_TAG_BUTTON_ID}
        size="sm"
        variant="iconText"
        leftIcon={<PlansIcon />}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          dispatch(openPlanPickerPopover());
        }}
        _hover={{
          bgColor: 'gray.300',
        }}
        maxW="240px"
      >
        <Text color={color} isTruncated lineHeight="16px">
          {label}
        </Text>
      </Button>
    </Tooltip>
  );
};

export default React.memo(EventGroupTag);
