import { keyBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { PageAccessResource, isPageAccessResource } from 'reduxStore/models/accessResources';
import { BlockId, BlocksPageId } from 'reduxStore/models/blocks';
import { SubmodelId } from 'reduxStore/models/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import {
  blocksPagesByBlockIdSelector,
  blocksPagesByIdSelector,
  currentPageIdWithSubmodelsSelector,
} from 'selectors/blocksPagesSelector';
import { currentPageRefSelector } from 'selectors/pageSelector';
import { allAccessResourcesSelector } from 'selectors/sharingSelector';
import { submodelPageFromSubmodelIdSelector } from 'selectors/submodelPageSelector';
import { submodelsByIdSelector } from 'selectors/submodelsByIdSelector';
import { ParametricSelector, Selector } from 'types/redux';

const pageAccessResourcesSelector: Selector<PageAccessResource[]> = createSelector(
  allAccessResourcesSelector,
  (accessResources) => {
    return accessResources.filter(isPageAccessResource);
  },
);

export const accessResourceByPageSelector: Selector<
  Record<BlocksPageId, PageAccessResource | undefined>
> = createSelector(pageAccessResourcesSelector, (pageAccessResources) => {
  return keyBy(pageAccessResources, 'resourceId');
});

//For block pages and submodels, the backend filters out pages users don't have access to.
export const allPageIdsSelector: Selector<string[]> = createSelector(
  blocksPagesByIdSelector,
  submodelsByIdSelector,
  (blockPagesById, submodelsById) => [
    ...Object.keys(blockPagesById),
    ...Object.keys(submodelsById),
  ],
);

export const isWritableByBlockIdSelector: Selector<NullableRecord<BlockId, boolean>> =
  createSelector(
    accessCapabilitiesSelector,
    blocksPagesByBlockIdSelector,
    (accessCapabilities, blocksPagesByBlockId) => {
      const isWritableByBlockId: NullableRecord<BlockId, boolean> = {};

      Object.entries(blocksPagesByBlockId).forEach(([blockId, page]) => {
        if (page == null) {
          return;
        }
        isWritableByBlockId[blockId] = accessCapabilities.canWritePage(page.id);
      });

      return isWritableByBlockId;
    },
  );

export const isCurrentPageWritableSelector: Selector<boolean> = createSelector(
  accessCapabilitiesSelector,
  currentPageRefSelector,
  currentPageIdWithSubmodelsSelector,
  (accessCapabilities, pageRef, pageId) => {
    if (pageRef?.type === 'unlistedDriversPage') {
      return accessCapabilities.canWriteUnlistedDriversPage;
    }
    if (pageId == null) {
      return false;
    }
    return accessCapabilities.canWritePage(pageId);
  },
);

export const isCurrentPageReadableSelector: Selector<boolean> = createSelector(
  accessCapabilitiesSelector,
  currentPageIdWithSubmodelsSelector,
  (accessCapabilities, pageId) => {
    if (pageId == null) {
      return false;
    }
    return accessCapabilities.canReadPage(pageId);
  },
);

/**
 * We have logic in the backend (see /handlers/dataset.go) that filters out pages users don't have access except for databases.
 * For database pages, the backend returns all of such pages and then we filter them client-side.
 */
export const isSubmodelIdWritableSelector: ParametricSelector<SubmodelId, boolean> =
  createCachedSelector(
    accessCapabilitiesSelector,
    (state: RootState, submodelId: SubmodelId) =>
      submodelPageFromSubmodelIdSelector(state, submodelId),
    (accessCapabilities, page) => {
      if (page == null) {
        return false;
      }

      return accessCapabilities.canWritePage(page.id);
    },
  )((_state, pageId) => pageId);
