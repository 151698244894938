import { CELL_DATA_COLUMN_WIDTH_IN_PX } from 'config/cells';
import { BlockColumnOptions } from 'generated/graphql';
import { LayerId } from 'reduxStore/models/layers';
import { MonthKey } from 'types/datetime';

export type ColumnOrMonthKey = ModelViewColumnType | MonthKey;
export type ColumnOpts = BlockColumnOptions & { width: number };

export type ModelViewColumnType =
  | 'name'
  | 'formula'
  | 'actualsFormula'
  | 'usedBy'
  | 'graph'
  | 'data'
  | 'rollupType'
  | 'initialValue'
  | 'property';

// undefined represent the current layer. We allow undefined instead of
// enforcing we use the current layer id because it's more ergonomic.
export type ColumnLayerId = LayerId | undefined;

export type DriverPropertyColumn = {
  type: ModelViewColumnType;
  // layerIds will be [undefined] if no comparison layers are specified,
  // otherwise it will be the layer ids for the comparison layers
  layerIds: ColumnLayerId[];
};

export const MODEL_VIEW_DATA_COLUMN_TYPE = 'data';
export const NAME_COLUMN_TYPE = 'name';
export const ACTUALS_FORMULA_COLUMN_TYPE = 'actualsFormula';
export const FORECAST_FORMULA_COLUMN_TYPE = 'formula';
export const OPTIONS_COLUMN_TYPE = 'options';
export const PROPERTY_COLUMN_TYPE = 'property';
export const INITIAL_VALUE_COLUMN_TYPE = 'initialValue';
export const USED_BY_COLUMN_TYPE = 'usedBy';
export const GRAPH_COLUMN_TYPE = 'graph';
export const ROLLUP_COLUMN_TYPE = 'rollupType';

export const DEFAULT_COLUMN_TYPES: ModelViewColumnType[] = [
  NAME_COLUMN_TYPE,
  ACTUALS_FORMULA_COLUMN_TYPE,
  FORECAST_FORMULA_COLUMN_TYPE,
  MODEL_VIEW_DATA_COLUMN_TYPE,
];

const CONFIGURABLE_COLUMN_TYPES: ModelViewColumnType[] = [
  ACTUALS_FORMULA_COLUMN_TYPE,
  FORECAST_FORMULA_COLUMN_TYPE,
  USED_BY_COLUMN_TYPE,
  GRAPH_COLUMN_TYPE,
  ROLLUP_COLUMN_TYPE,
  MODEL_VIEW_DATA_COLUMN_TYPE,
  PROPERTY_COLUMN_TYPE,
  INITIAL_VALUE_COLUMN_TYPE,
];

export const ALL_COLUMN_TYPES: ModelViewColumnType[] = [
  NAME_COLUMN_TYPE,
  ...CONFIGURABLE_COLUMN_TYPES,
];

export const DRIVER_COLUMN_TYPES = ALL_COLUMN_TYPES.filter(
  (c) => ![PROPERTY_COLUMN_TYPE, INITIAL_VALUE_COLUMN_TYPE].includes(c),
);

export const COLUMN_TYPE_TO_NAME: Record<ModelViewColumnType, string> = {
  name: 'Name',
  formula: 'Forecast Formula',
  actualsFormula: 'Actuals Formula',
  graph: 'Graph',
  usedBy: 'Used By',
  [MODEL_VIEW_DATA_COLUMN_TYPE]: 'Data',
  rollupType: 'Rollup',
  [INITIAL_VALUE_COLUMN_TYPE]: 'Initial Value',
  [PROPERTY_COLUMN_TYPE]: 'Property',
};

export const COLUMN_TYPE_TO_DEFAULT_WIDTH: Record<ModelViewColumnType, number> = {
  [NAME_COLUMN_TYPE]: 220,
  formula: 300,
  actualsFormula: 300,
  graph: 125,
  usedBy: 250,
  [MODEL_VIEW_DATA_COLUMN_TYPE]: CELL_DATA_COLUMN_WIDTH_IN_PX,
  rollupType: 100,
  [INITIAL_VALUE_COLUMN_TYPE]: CELL_DATA_COLUMN_WIDTH_IN_PX,
  [PROPERTY_COLUMN_TYPE]: 220,
};
