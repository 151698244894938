import React, { useContext } from 'react';

import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ReferencePill from 'components/FormulaInput/ReferencePill';
import useFormulaPillMouseDown from 'hooks/useFormulaPillMouseDown';
import { DraftDecoratorComponentProps, SubmodelRefMetadata } from 'types/formula';

import DateRangePill from './DateRangePill';

const SubmodelDecorator: React.FC<DraftDecoratorComponentProps> = ({
  contentState,
  entityKey,
  offsetKey,
}) => {
  const { activeEntity, keysInSelection } = useContext(FormulaSelectionContext);
  const entity = contentState.getEntity(entityKey ?? '');
  const { label, error, dateRangeDisplay } = entity.getData() as SubmodelRefMetadata;
  const isSelectedEntity = activeEntity?.key === entityKey;
  const onNameMouseDown = useFormulaPillMouseDown(entityKey, entity);
  const onDateMouseDown = useFormulaPillMouseDown(entityKey, entity, { submenu: 'timePeriod' });

  if (entityKey == null) {
    return null;
  }

  const isSelected = isSelectedEntity || keysInSelection.has(entityKey);
  return (
    <ReferencePill
      onMouseDown={onNameMouseDown}
      isSelected={isSelected}
      error={error}
      label={label}
      offsetKey={offsetKey}
    >
      {dateRangeDisplay != null && (
        <DateRangePill
          onMouseDown={onDateMouseDown}
          label={dateRangeDisplay}
          isSelected={isSelected}
        />
      )}
    </ReferencePill>
  );
};

export default SubmodelDecorator;
