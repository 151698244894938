import { InternalPage } from 'config/internalPages/internalPage';
import { BlockCreateInput, BlockType, BlocksPageCreateInput } from 'generated/graphql';
import { ExtSource } from 'helpers/integrations';
import { uuidv4 } from 'helpers/uuidv4';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';

// Increment this version if you make a change to the config below.
const VERSION = 1;

export const getInternalPageTypeForLinkedObjectsPage = (
  source: ExtSource,
  specId: BusinessObjectSpecId,
): string => {
  return `${source}-${specId}-linkedObjectsPage`;
};

export const getDataSourceLinkedObjectsPage = ({
  source,
  specId,
}: {
  source: ExtSource;
  specId: BusinessObjectSpecId;
}): InternalPage => {
  const id = uuidv4();
  const name = `${source} ${specId} Linked Objects`;
  const internalPageType = getInternalPageTypeForLinkedObjectsPage(source, specId);
  const version = VERSION;
  const blocksPageCreateInput: BlocksPageCreateInput = {
    id,
    name,
    internalPageType,
    internalPageVersion: version,
  };
  const blocksCreateInputs: BlockCreateInput[] = [
    {
      id: uuidv4(),
      type: BlockType.ObjectTable,
      name: 'Linked Objects',
      pageId: id,
      blockConfig: {
        businessObjectSpecId: specId,
      },
    },
  ];
  return { internalPageType, version, blocksPageCreateInput, blocksCreateInputs };
};
