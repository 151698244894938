import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Avatar, Box, Center, Flex, IconButton, Spinner, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

import MutationBatchCard, {
  MutationBatchWithUserId,
  MutationBatchWithUserIdAndReverseIndex,
} from 'components/NamedDatasetVersionMenu/MutationBatchCard';
import { useDatasetMutationHistoryQuery } from 'generated/graphql';
import { formatIsoStringToLocalTime } from 'helpers/dates';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useAppStore from 'hooks/useAppStore';
import { MutationId, getMutationBatchDisplay } from 'reduxStore/models/mutations';
import { deletedIdentifiersSelector } from 'selectors/deletedIdentifierSelector';
import { getMutationActionsPageNames } from 'selectors/mutationAffectedEntitySelector';
import {
  selectedOrgIdSelector,
  usersInSelectedOrgByIdSelector,
} from 'selectors/selectedOrgSelector';

const MUTATION_HISTORY_FETCH_LIMIT = 50;
const MUTATIONS_PER_PAGE = 10;

interface Props {
  openNewLayerId: (layerId: string) => void;
}

const RecentDatasetVersionsList: React.FC<Props> = ({ openNewLayerId }) => {
  const orgId = useAppSelector(selectedOrgIdSelector);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [beforeMutationId, setBeforeMutationId] = useState<MutationId | undefined>(undefined);
  const [history, setHistory] = useState<MutationBatchWithUserId[]>([]);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [historyQueryResult] = useDatasetMutationHistoryQuery({
    variables: {
      orgId,
      limit: MUTATION_HISTORY_FETCH_LIMIT,
      layerId: '',
      includeUndo: false,
      beforeMutationId,
    },
  });

  const { data, fetching } = historyQueryResult;

  const mutationHistory = data?.datasetMutationHistory?.map<MutationBatchWithUserId>((m) => {
    return {
      createdAt: m.createdAt,
      isUndone: false,
      id: m.id,
      prevMutationId: m.prevMutationId ?? undefined,
      mutation: m,
      userId: m.userId,
    };
  });

  const fetchFrequency = MUTATION_HISTORY_FETCH_LIMIT / MUTATIONS_PER_PAGE;
  const requestedRangeStart =
    Math.floor(pageNumber / fetchFrequency) * MUTATION_HISTORY_FETCH_LIMIT;

  useEffect(() => {
    if (requestedRangeStart >= history.length - 1) {
      const shouldFetch = beforeMutationId !== history[history.length - 1]?.id;
      if (shouldFetch) {
        // trigger fetch
        setBeforeMutationId(history[history.length - 1]?.id);
      } else if (!fetching && mutationHistory != null) {
        // fetch has finished
        setHistory((h) => [...h, ...mutationHistory]);
      }
    }
  }, [mutationHistory, beforeMutationId, requestedRangeStart, history, fetching]);

  const pageContents = useMemo(
    () =>
      history
        .slice(pageNumber * MUTATIONS_PER_PAGE, (pageNumber + 1) * MUTATIONS_PER_PAGE)
        .map((mutation, index) => ({
          ...mutation,
          reverseIndex: pageNumber * MUTATIONS_PER_PAGE + index,
        })),
    [history, pageNumber],
  );

  const pageContentGroups = useGroupMutationBatchByPage(pageContents);

  const next = () => {
    if (history == null || history.length === 0) {
      return;
    }

    setPageNumber((p) => p + 1);
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const back = () => {
    if (history == null || history.length === 0 || pageNumber === 0) {
      return;
    }

    setPageNumber((p) => p - 1);
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Flex
        ref={containerRef}
        h="80vh"
        minH="20rem"
        flexDirection="column"
        rowGap={2}
        padding={2}
        overflowY="auto"
      >
        {fetching ? (
          <Center height="full">
            <Spinner color="gray.500" />
          </Center>
        ) : pageContents.length === 0 ? (
          <Text fontSize="xs" color="gray.500" pt={2}>
            No mutation history
          </Text>
        ) : (
          pageContentGroups.map(({ pageName, userId, groupedHistory, dateTimeText }) => (
            <GroupedMutationBatchCard
              key={groupedHistory[0].id}
              pageName={pageName}
              userId={userId}
              groupedHistory={groupedHistory}
              dateTimeText={dateTimeText}
              openNewLayerId={openNewLayerId}
            />
          ))
        )}
      </Flex>
      <Flex mt={2} alignItems="bottom" justifyContent="space-between">
        <Flex columnGap={2}>
          <IconButton
            aria-label="back"
            size="xs"
            onClick={back}
            isDisabled={pageNumber === 0 || fetching}
            icon={<ArrowBackIcon boxSize={3} />}
          />
          <IconButton
            aria-label="next"
            size="xs"
            onClick={next}
            isDisabled={pageContents.length < MUTATIONS_PER_PAGE || fetching}
            icon={<ArrowForwardIcon boxSize={3} />}
          />
        </Flex>
        <Text color="gray.500" fontSize="xs">
          Runway updates automatically
        </Text>
      </Flex>
    </>
  );
};

const GroupedMutationBatchCard: React.FC<
  MutationBatchGroup & { openNewLayerId: (layerId: string) => void }
> = React.memo(({ groupedHistory, pageName, userId, dateTimeText, openNewLayerId }) => {
  const orgUsersById = useAppSelector(usersInSelectedOrgByIdSelector);
  const user = safeObjGet(orgUsersById[userId]);
  const userName = user?.name ?? 'Runway';
  const userImage = user?.profileImage;

  return (
    <Flex flexDirection="column" pt={2} pb={1}>
      <Flex w="full" columnGap={3} alignItems="flex-start">
        <Avatar size="xs" src={userImage ?? undefined} flexShrink={0} />
        <Flex w="full" flexDir="column" overflow="hidden">
          <Box w="full" lineHeight={1.25}>
            <Text fontSize="xs" fontWeight="semibold" as="span">
              {userName}{' '}
            </Text>
            <Text fontSize="xs" as="span">
              edited{' '}
            </Text>
            <Text fontSize="xs" fontWeight="semibold" as="span">
              {pageName}
            </Text>
          </Box>
          <Flex w="full" pt={1} fontWeight="medium" fontSize="xxs" color="gray.500">
            <Text> {dateTimeText}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Box pl={9}>
        {groupedHistory?.map((mutation) => (
          <MutationBatchCard
            key={mutation.id}
            mutation={mutation}
            openNewLayerId={openNewLayerId}
          />
        ))}
      </Box>
    </Flex>
  );
});

type MutationBatchGroup = {
  pageName: string | undefined;
  userId: string;
  groupedHistory: MutationBatchWithUserIdAndReverseIndex[];
  dateTimeText: string | undefined;
};
const useGroupMutationBatchByPage = (
  history: MutationBatchWithUserIdAndReverseIndex[],
): MutationBatchGroup[] => {
  const [groups, setGroups] = useState<MutationBatchGroup[]>([]);
  const prevHistory = usePrevious(history);

  const { getState } = useAppStore();
  useEffect(() => {
    if (prevHistory != null && prevHistory === history) {
      return;
    }
    const newGroups: MutationBatchGroup[] = [];
    let currentGroup: MutationBatchGroup | undefined;

    const state = getState();
    const deletedIdentifiers = deletedIdentifiersSelector(state);

    history.forEach((mutationBatch) => {
      const actions = getMutationBatchDisplay({
        mutationBatch,
        deletedIdentifiers,
      }).actions;
      const pageNames = getMutationActionsPageNames(state, actions);
      const dateTimeText = formatIsoStringToLocalTime(mutationBatch.createdAt, 'MMM d');

      if (
        currentGroup == null ||
        pageNames !== currentGroup.pageName ||
        mutationBatch.userId !== currentGroup.userId ||
        dateTimeText !== currentGroup.dateTimeText
      ) {
        currentGroup = {
          pageName: pageNames,
          groupedHistory: [mutationBatch],
          userId: mutationBatch.userId,
          dateTimeText,
        };
        newGroups.push(currentGroup);
      } else {
        currentGroup.groupedHistory.push(mutationBatch);
      }
    });
    setGroups(newGroups);
  }, [getState, history, prevHistory]);

  return groups;
};

export default React.memo(RecentDatasetVersionsList);
