import { useEffect, useMemo } from 'react';

import { isSSR } from 'helpers/environment';

type MeasureTextProps = {
  font: string;
  size: string;
};

let fragment: DocumentFragment | null;
let ctx: CanvasRenderingContext2D | null | undefined;
let instances = 0;

export const useMeasureText = ({
  font,
  size,
}: MeasureTextProps): ((text: string) => TextMetrics | undefined) => {
  if (!ctx && !isSSR()) {
    fragment = document.createDocumentFragment();
    const canvas = document.createElement('canvas');
    fragment.append(canvas);
    ctx = canvas.getContext('2d');
  }

  useEffect(() => {
    ++instances;
    return () => {
      --instances;
      if (instances <= 0) {
        fragment = null;
        ctx = null;
      }
    };
  }, []);

  return useMemo(() => {
    if (!ctx) {
      return () => undefined;
    }

    const c = ctx;
    c.font = `${size} ${font}`;
    return (text) => c.measureText(text);
  }, [font, size]);
};
