import { BarStack } from '@visx/shape';
import React from 'react';

import { CHART_SIZE_OPTION_TO_BAR_WIDTH } from 'components/DriverChart/BarChart';
import { ChartSize } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartContext from 'hooks/useChartContext';
import { useColorMapForDriverCharts } from 'hooks/useColorMapForDriverCharts';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { DriverId } from 'reduxStore/models/drivers';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';

const getDate = (d: Datum) => d.date;
type Datum = {
  date: Date;
  [key: string]: number | Date;
};
type Props = {
  size: ChartSize;
};

export const StackedBarChart: React.FC<Props> = ({ size }) => {
  const { driverIds, valueScale, timeScale, fullAggregateChartData, colorScale } =
    useChartContext();

  const { positiveValues, negativeValues } = fullAggregateChartData;

  const formattedData = [...positiveValues, ...negativeValues].map((data) => {
    return {
      ...data.driverValues,
      date: data.date,
    };
  });

  const reversedKeys = [...driverIds].reverse();
  const colorMap = useColorMapForDriverCharts({
    keys: reversedKeys,
    shades: [500],
    colorScale,
  });

  return (
    <>
      {driverIds.map((driverId) => (
        <DriverWebWorkerLoader key={driverId} driverId={driverId} />
      ))}
      <BarStack
        data={formattedData}
        keys={reversedKeys}
        x={getDate}
        xScale={timeScale}
        yScale={valueScale}
        color={(d) => (colorScale ? `${colorScale(d)}.500` : 'blue.500')}
      >
        {(barStacks) =>
          barStacks.map((barStack) => {
            return barStack.bars.map((bar) => {
              if (bar.height < 0) {
                bar.y = bar.y + bar.height;
                bar.height = Math.abs(bar.height);
              }
              return (
                <rect
                  key={`bar-stack-${barStack.key}-${bar.index}`}
                  x={timeScale(getDate(bar.bar.data))}
                  y={bar.y}
                  height={bar.height}
                  width={CHART_SIZE_OPTION_TO_BAR_WIDTH[size]}
                  fill={colorMap[bar.color]}
                />
              );
            });
          })
        }
      </BarStack>
    </>
  );
};

// N.B copied from DriverChart.tsx
// It may have been more natural to put the loading within the
// StackedBarChart but that would result in it mounting when the drivers
// are loaded which would then re-trigger a load thus causing a loop.
const DriverWebWorkerLoader: React.FC<{ driverId: DriverId }> = ({ driverId }) => {
  const { blockId } = useBlockContext();
  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));

  useRequestCellValue({
    id: driverId,
    type: 'driver',
    dateRange,
  });

  return null;
};
