import { Button, Flex, Text, useMultiStyleConfig, useTab } from '@chakra-ui/react';
import React from 'react';

import { preventEventDefault } from 'helpers/browserEvent';
import FormulaIcon from 'vectors/Formula';
import FormulaError from 'vectors/FormulaError';

interface Props {
  isActive: boolean;
  label: string;
  hasError: boolean;
}

const EditFormulasPopoverTab = React.forwardRef<HTMLButtonElement, Props>(
  ({ isActive, hasError, label }, ref) => {
    // don't steal focus from the formula input
    const tabProps = useTab({ isFocusable: false, ref, onClick: preventEventDefault });
    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps);

    return (
      <Button __css={styles.tab} {...tabProps} display="flex" pt={2}>
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius="md"
          height={5}
          width={5}
          color={hasError ? 'white' : isActive ? 'selection.500' : 'gray.400'}
          bgColor={hasError ? 'failure' : undefined}
        >
          {hasError ? (
            <FormulaError boxSize={4} position="relative" />
          ) : (
            <FormulaIcon boxSize={4} position="relative" />
          )}
        </Flex>
        <Text ml={1}>{label}</Text>
      </Button>
    );
  },
);

export default EditFormulasPopoverTab;
