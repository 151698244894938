import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DatabaseGroupKey } from 'config/businessObjects';
import { BlockConfig } from 'generated/graphql';
import { BlockId, ObjectTableBlockColumnKey } from 'reduxStore/models/blocks';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { AttributeId } from 'reduxStore/models/dimensions';

export type BlocksLocalState = {
  recentlyCreatedAttributeIdsByBlockId: Record<BlockId, AttributeId[]>;
  currentlyEditingPropertyFormula?: DatabaseColumn;
  savedScrollLeftByBlockId: Record<BlockId, number>;
  // used for guests that cannot write to an org's block configs;
  // guests can update local copies of block configs
  localBlockConfigs: NullableRecord<BlockId, BlockConfig>;
};

export type DatabaseColumn = {
  blockId: BlockId;
  columnKey: ObjectTableBlockColumnKey;
  // objectId is used in the Timeseries view formula editor so only one editor is open
  // instead of one for all the objects
  objectId?: BusinessObjectId;
  groupKey?: DatabaseGroupKey;
};

const initialState: BlocksLocalState = {
  recentlyCreatedAttributeIdsByBlockId: {},
  savedScrollLeftByBlockId: {},
  localBlockConfigs: {},
};

const blockLocalStateSlice = createSlice({
  name: 'blockLocalState',
  initialState,
  reducers: {
    setEditingPropertyFormula(
      state,
      action: PayloadAction<{
        blockId: BlockId;
        fieldSpecId: BusinessObjectFieldSpecId;
        objectId: BusinessObjectId | undefined;
        groupKey: DatabaseGroupKey | undefined;
      }>,
    ) {
      const { blockId, fieldSpecId, groupKey, objectId } = action.payload;
      state.currentlyEditingPropertyFormula = {
        blockId,
        columnKey: fieldSpecId,
        groupKey,
        objectId,
      };
    },
    clearEditingPropertyFormula(state) {
      state.currentlyEditingPropertyFormula = undefined;
    },
    setScrollLeftForBlock(state, action: PayloadAction<{ blockId: BlockId; scrollLeft: number }>) {
      const { blockId, scrollLeft } = action.payload;
      state.savedScrollLeftByBlockId[blockId] = scrollLeft;
    },
    updateBlockConfigs(
      state,
      action: PayloadAction<Array<{ id: BlockId; blockConfig: BlockConfig }>>,
    ) {
      action.payload.forEach(({ id, blockConfig }) => {
        state.localBlockConfigs[id] = blockConfig;
      });
    },
  },
});

export const {
  setEditingPropertyFormula,
  clearEditingPropertyFormula,
  setScrollLeftForBlock,
  updateBlockConfigs,
} = blockLocalStateSlice.actions;

export default blockLocalStateSlice.reducer;
