import isChromatic from 'chromatic/isChromatic';
import { DateTime } from 'luxon';

export const appEnv = (process.env.APP_ENVIRONMENT ?? 'development') as
  | 'development'
  | 'production'
  | 'preview'
  | 'staging';

export const appEnvColor = (): 'red' | 'yellow' | 'green' => {
  switch (appEnv) {
    case 'development':
      return 'green';
    case 'preview':
      return 'yellow';
    case 'staging':
      return 'yellow';
    case 'production':
    default:
      return 'red';
  }
};

export const buildId = process.env.BUILD_ID;
export const bootTime = DateTime.now();

export const isDevelopment = appEnv === 'development';
export const isProduction = appEnv === 'production';
export const isPreview = appEnv === 'preview';
export const isStaging = appEnv === 'staging';
export const isTestBuild = process.env.NEXT_PUBLIC_BUILD_TYPE === 'test';

export const isSSR = () => typeof window === 'undefined';
// https://storybook.js.org/docs/react/faq#how-can-my-code-detect-if-it-is-running-in-storybook
export const isStorybook = process.env.STORYBOOK !== undefined || isChromatic();

export const isCypress = () => !isSSR() && Boolean(window.Cypress);
export const isJest = () => process.env.JEST_WORKER_ID !== undefined;
export const isUserBrowser = () => !isSSR() && !isStorybook && !isCypress() && !isJest();

export const platform = (): 'Mac' | 'Windows' | 'Other' => {
  if (isSSR()) {
    return 'Other';
  }

  // N.B. not currently using one of the many libraries out there because
  // they're huge and overkill for what we need, and ideally we have a minimal
  // platform switching.
  const userPlatform = window.navigator.platform;
  if (userPlatform.includes('Mac')) {
    return 'Mac';
  }

  if (userPlatform.includes('Win')) {
    return 'Windows';
  }

  return 'Other';
};

export const backendUrl = process.env.BACKEND_URL as string;
