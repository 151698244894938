import BaseFormulaChunk from 'components/Formula/BaseFormulaChunk';
import { formatAsNumber } from 'helpers/formatting';
import useAppSelector from 'hooks/useAppSelector';
import { orgNegativeDisplaySelector } from 'selectors/selectedOrgSelector';

interface Props {
  text: string;
}

const NumberChunk: React.FC<Props> = ({ text }) => {
  const negativeDisplay = useAppSelector(orgNegativeDisplaySelector);
  return <BaseFormulaChunk text={formatAsNumber(text, { abbreviate: false, negativeDisplay })} />;
};

export default NumberChunk;
