import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import { Driver, DriverType } from 'reduxStore/models/drivers';
export const resolveSubDriverForObject = (
  objectId: string,
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator,
  driver: Driver,
) => {
  if (driver.type === DriverType.Dimensional) {
    const attributes =
      dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(objectId);

    if (attributes.length > 0) {
      const subDriverId = dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
        driver.id,
        attributes.map((attr) => attr.attribute.id),
      );

      if (subDriverId != null) {
        return subDriverId;
      }
    }
  }
  return null;
};
