import { mapValues, sortBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import {
  getSubmodelIdFromInternalPageType,
  getSubmodelInternalPageType,
} from 'config/internalPages/modelPage';
import { BlocksPage } from 'generated/graphql';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { SelectorWithLayerParam, getCacheKeyForLayerSelector } from 'helpers/layerSelectorFactory';
import { isNotNull } from 'helpers/typescript';
import { BlockId, BlocksPageId } from 'reduxStore/models/blocks';
import { SubmodelId } from 'reduxStore/models/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blocksPageByInternalPageTypeSelector } from 'selectors/blocksPagesTableSelector';
import { submodelIdSelector } from 'selectors/constSelectors';
import { pageIdSelector } from 'selectors/pageSelector';
import { submodelIdsSelector } from 'selectors/submodelsByIdSelector';
import { ParametricSelector, Selector } from 'types/redux';

export const currentSubmodelPageSelector: Selector<BlocksPage | null> = createSelector(
  (state: RootState) => state,
  pageIdSelector,
  (state, submodelId) => {
    if (submodelId == null) {
      return null;
    }
    return submodelPageFromSubmodelIdSelector(state, submodelId);
  },
);

const submodelPagesBySubmodelIdSelector: SelectorWithLayerParam<Record<SubmodelId, BlocksPage>> =
  createCachedSelector(
    blocksPageByInternalPageTypeSelector,
    submodelIdsSelector,
    (blocksPageByInternalPageType, submodelIds) => {
      const entries: Record<SubmodelId, BlocksPage> = {};
      const submodelInternalPageTypes = submodelIds.map((id) => getSubmodelInternalPageType(id));
      const submodelPages = submodelInternalPageTypes
        .map((type) => blocksPageByInternalPageType[type])
        .filter(isNotNull);
      submodelPages.forEach((submodelPage) => {
        const submodelId = getSubmodelIdFromInternalPageType(submodelPage.internalPageType);
        if (submodelId != null) {
          const page = blocksPageByInternalPageType[submodelPage.internalPageType];
          if (page == null) {
            return;
          }
          entries[submodelId] = page;
        }
      });
      return entries;
    },
  )({ keySelector: getCacheKeyForLayerSelector, selectorCreator: createDeepEqualSelector });

export const submodelNamesByIdSelector: Selector<Record<SubmodelId, string>> = createSelector(
  submodelPagesBySubmodelIdSelector,
  (pages) => mapValues(pages, 'name'),
);

export const submodelIdsBySubmodelNameSelector: Selector<NullableRecord<string, SubmodelId[]>> =
  createSelector(submodelNamesByIdSelector, (submodelNamesById) => {
    const submodelIdsBySubmodel: NullableRecord<string, SubmodelId[]> = {};
    Object.entries(submodelNamesById).forEach(([submodelId, submodelName]) => {
      const submodelsWithName = submodelIdsBySubmodel[submodelName] ?? [];
      submodelsWithName.push(submodelId);
      submodelIdsBySubmodel[submodelName] = submodelsWithName;
    });

    return submodelIdsBySubmodel;
  });

export const blockIdBySubmodelIdSelector: SelectorWithLayerParam<
  NullableRecord<SubmodelId, BlockId>
> = createCachedSelector(submodelPagesBySubmodelIdSelector, (pages) =>
  mapValues(pages, (p) => p.blockIds[0]),
)(getCacheKeyForLayerSelector);

export const submodelIdByBlockIdSelector: Selector<NullableRecord<BlockId, SubmodelId>> =
  createSelector(blockIdBySubmodelIdSelector, (blockIdBySubmodelId) => {
    const submodelIdByBlockId: NullableRecord<BlockId, SubmodelId> = {};
    Object.entries(blockIdBySubmodelId).forEach(([submodelId, blockId]) => {
      if (blockId == null) {
        return;
      }
      submodelIdByBlockId[blockId] = submodelId;
    });
    return submodelIdByBlockId;
  });

export const submodelNamesSelector: Selector<string[]> = createDeepEqualSelector(
  submodelNamesByIdSelector,
  (submodelNamesById) => Object.values(submodelNamesById),
);

export const currentSubmodelPageIdSelector: Selector<BlocksPageId | null> = createSelector(
  currentSubmodelPageSelector,
  function currentSubmodelPageIdSelector(currentSubmodelPage) {
    return currentSubmodelPage?.id ?? null;
  },
);

export const orderedSubmodelPagesSelector: Selector<BlocksPage[]> = createSelector(
  blocksPageByInternalPageTypeSelector,
  submodelIdsSelector,
  (blocksPageByInternalPageType, submodelIds) => {
    const internalPageTypes = submodelIds.map((id) => getSubmodelInternalPageType(id));
    const submodelPages = internalPageTypes
      .map((type) => blocksPageByInternalPageType[type])
      .filter(isNotNull);

    return sortBy(submodelPages, (p) => p.sortIndex ?? 0);
  },
);

export const submodelPageFromSubmodelIdSelector: ParametricSelector<SubmodelId, BlocksPage | null> =
  createSelector(
    blocksPageByInternalPageTypeSelector,
    submodelIdSelector,
    function submodelPageFromSubmodelIdSelector(blocksPageByInternalPageType, submodelId) {
      return blocksPageByInternalPageType[getSubmodelInternalPageType(submodelId)] ?? null;
    },
  );

export const pageNameFromSubmodelIdSelector: ParametricSelector<SubmodelId, string> =
  createSelector(
    (state: RootState, submodelId: SubmodelId) =>
      submodelPageFromSubmodelIdSelector(state, submodelId),
    (submodelPage) => {
      return submodelPage?.name ?? '';
    },
  );
