import { CloseIcon } from '@chakra-ui/icons';
import { BoxProps, IconButton } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import Badge from 'components/Badge/Badge';
import { useBadgeTheme } from 'config/badge';
import { getAttributeValueString } from 'helpers/dimensionalDrivers';
import useAppSelector from 'hooks/useAppSelector';
import { Attribute, PseudoDimensionType } from 'reduxStore/models/dimensions';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';

interface Props {
  onRemove?: () => void;
  onSelect?: () => void;
  attribute: Attribute;
  fallbackTheme?: 'gray' | 'white';
  size?: 'sm' | 'xs' | 'md';
  textColor?: BoxProps['color'];
}

const AttributeBadge: React.FC<Props> = ({
  onSelect,
  onRemove,
  attribute,
  fallbackTheme = 'gray',
  size,
  textColor,
}) => {
  const dimensionsById = useAppSelector(dimensionsByIdSelector);
  const color = dimensionsById[attribute.dimensionId]?.color;
  const theme = useBadgeTheme(
    color,
    attribute.type === PseudoDimensionType,
    attribute.deleted,
    fallbackTheme,
  );
  const value = useMemo(() => {
    return getAttributeValueString(attribute);
  }, [attribute]);

  return (
    <Badge
      text={value}
      theme={attribute.deleted ? 'red' : theme}
      tooltipMsg={attribute.deleted ? 'deleted attribute' : undefined}
      size={size ?? 'sm'}
      minWidth="1.5rem"
      cursor={onSelect == null ? undefined : 'pointer'}
      onClick={onSelect}
      textColor={textColor}
      rightMeta={
        onRemove != null ? (
          <IconButton
            icon={<CloseIcon boxSize="0.375rem" />}
            onClick={(ev) => {
              onRemove();
              ev.stopPropagation();
            }}
            aria-label="Remove attribute"
            variant="icon"
            pr={2}
            _hover={{ bgColor: 'unset' }}
          />
        ) : undefined
      }
    />
  );
};

export default AttributeBadge;
