import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import xor from 'lodash/xor';

import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { SubmodelId } from 'reduxStore/models/submodels';

type DriverEdit = { driverId: DriverId };
export type DriverGroupExpansionState = Record<SubmodelId, DriverGroupId[]>;

export type ModelView = {
  scrollLeft?: number;
  editingDriver?: DriverEdit;
  collapsedGroupKeysBySubmodelId: DriverGroupExpansionState;
};

export const modelViewInitialState: ModelView = {
  collapsedGroupKeysBySubmodelId: {},
};

const modelView = createSlice({
  name: 'modelView',
  initialState: modelViewInitialState,
  reducers: {
    setEditingDriver(state, action: PayloadAction<DriverEdit>) {
      state.editingDriver = action.payload;
    },
    clearEditingDriver(state) {
      state.editingDriver = undefined;
    },
    toggleDriverGroupExpansion(
      state,
      action: PayloadAction<{ submodelId: SubmodelId | undefined; driverGroupId: DriverGroupId }>,
    ) {
      const { submodelId, driverGroupId } = action.payload;

      if (submodelId == null || driverGroupId == null) {
        return;
      }

      state.collapsedGroupKeysBySubmodelId[submodelId] = xor(
        state.collapsedGroupKeysBySubmodelId[submodelId] ?? [],
        [driverGroupId],
      );
    },
  },
});

export const { clearEditingDriver, setEditingDriver, toggleDriverGroupExpansion } =
  modelView.actions;

export default modelView.reducer;
