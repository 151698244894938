import React from 'react';

import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import { DriverReference } from 'generated/graphql';
import { extractEmoji } from 'helpers/emoji';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { blocksPagesByBlockIdSelector } from 'selectors/blocksPagesSelector';
import {
  submodelIdByBlockIdSelector,
  submodelNamesByIdSelector,
} from 'selectors/submodelPageSelector';

interface Props {
  refs: DriverReference[];
}

const DriverPageIcon: React.FC<Props> = ({ refs }) => {
  const submodelIdByBlockId = useAppSelector(submodelIdByBlockIdSelector);
  const submodelNamesById = useAppSelector(submodelNamesByIdSelector);
  const blocksPagesByBlockId = useAppSelector(blocksPagesByBlockIdSelector);
  const submodelRef = refs.find((ref) => submodelIdByBlockId[ref.blockId] != null);
  let name = null;
  if (submodelRef != null) {
    const submodelId = submodelIdByBlockId[submodelRef.blockId];
    name = submodelId != null ? safeObjGet(submodelNamesById[submodelId]) : null;
  } else {
    const pageRef = refs.find((ref) => blocksPagesByBlockId[ref.blockId] != null);
    if (pageRef != null) {
      name = safeObjGet(blocksPagesByBlockId[pageRef.blockId])?.name;
    }
  }

  return <EmojiIcon size="sm" emoji={name != null ? extractEmoji(name)[0] : null} />;
};

export default React.memo(DriverPageIcon);
