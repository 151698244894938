import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IntegrationProvider, OrgUser } from 'generated/graphql';
import { ExtSource } from 'helpers/integrations';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { DimensionalPropertyId, DriverPropertyId } from 'reduxStore/models/collections';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId } from 'reduxStore/models/events';
import { IntegrationQueryId } from 'reduxStore/models/integrationQueries';
import { SubmodelId } from 'reduxStore/models/submodels';
import { UserInvite } from 'reduxStore/models/userInvite';
import { applyMutationLocally_INTERNAL } from 'reduxStore/reducers/datasetSlice';

type DatabaseColumnToBeDeleted = {
  objectSpecId: BusinessObjectSpecId;
  objectFieldSpecId?: BusinessObjectFieldSpecId;
  deleteDimPropertyId?: DimensionalPropertyId;
  deleteDriverPropertyId?: DriverPropertyId;
};

type ObjectSpecToBeDeleted = {
  objectSpecId: BusinessObjectSpecId;
};

export type IntegrationToBeDeleted = {
  source: ExtSource;
  provider: IntegrationProvider;
  accountId: string;
};

type DeleteDialogAttribute = {
  attributeId: AttributeId;
  blockId: BlockId;
};

export type DeleteIntegrationQuery = {
  action: 'deleteQuery' | 'runQuery';
  integrationQueryId: IntegrationQueryId;
};

export type RemoveOrgUser = {
  user: OrgUser | UserInvite;
  orgId: string;
};

// TODO: this is should be refactored into a discriminated union so that it's easier to reason
// over
export type AlertDialog = {
  databaseColumnToBeDeleted?: DatabaseColumnToBeDeleted;
  objectSpecToBeDeleted?: ObjectSpecToBeDeleted;
  deleteDialogDriverIds?: DriverId[];
  deleteModelId?: SubmodelId;
  deleteDialogDimensionId?: DimensionId;
  deleteDialogAttribute?: DeleteDialogAttribute;
  // marks an integration to be completely unlinked
  integrationToBeDeleted?: IntegrationToBeDeleted;
  deleteIntegrationQuery?: DeleteIntegrationQuery;
  removeOrgUser?: RemoveOrgUser;
  deleteEventGroupId?: EventGroupId;
};

const initialState = {} as AlertDialog;

const alertDialogSlice = createSlice({
  name: 'alertDialog',
  initialState,
  reducers: {
    openDeleteObjectTableColumnDialog(
      _state,
      action: PayloadAction<{
        objectSpecId: BusinessObjectSpecId;
        deleteFieldId?: BusinessObjectFieldSpecId;
        deleteDimPropertyId?: DimensionalPropertyId;
        deleteDriverPropertyId?: DriverPropertyId;
      }>,
    ) {
      const { objectSpecId, deleteFieldId, deleteDimPropertyId, deleteDriverPropertyId } =
        action.payload;
      return {
        databaseColumnToBeDeleted: {
          objectSpecId,
          objectFieldSpecId: deleteFieldId,
          deleteDimPropertyId,
          deleteDriverPropertyId,
        },
      };
    },
    openDeleteObjectSpecDialog(
      _state,
      action: PayloadAction<{ specIdToDelete: BusinessObjectSpecId }>,
    ) {
      const { specIdToDelete } = action.payload;
      return {
        objectSpecToBeDeleted: {
          objectSpecId: specIdToDelete,
        },
      };
    },
    openDeleteDriverDialog(_state, action: PayloadAction<DriverId[]>) {
      return {
        deleteDialogDriverIds: action.payload,
      };
    },
    openDeleteModelDialog(_state, action: PayloadAction<SubmodelId>) {
      return {
        deleteModelId: action.payload,
      };
    },
    openDeleteDimensionDialog(_state, action: PayloadAction<DimensionId>) {
      return {
        deleteDialogDimensionId: action.payload,
      };
    },
    openDeleteAttributeDialog(_state, action: PayloadAction<DeleteDialogAttribute>) {
      return {
        deleteDialogAttribute: action.payload,
      };
    },
    openDeleteIntegrationDialog(_state, action: PayloadAction<IntegrationToBeDeleted>) {
      return {
        integrationToBeDeleted: action.payload,
      };
    },
    openDeleteIntegrationQueryDialog(_state, action: PayloadAction<DeleteIntegrationQuery>) {
      return {
        deleteIntegrationQuery: action.payload,
      };
    },
    openDeleteOrgUserDialog(_state, action: PayloadAction<RemoveOrgUser>) {
      return {
        removeOrgUser: action.payload,
      };
    },
    openDeleteEventGroupDialog(_state, action: PayloadAction<EventGroupId>) {
      return {
        deleteEventGroupId: action.payload,
      };
    },
    closeDialog() {
      return {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(applyMutationLocally_INTERNAL, (state, action) => {
      const mutation = action.payload.mutationBatch.mutation;
      const isLocal = !action.payload.isRemote;
      if (isLocal) {
        const {
          deleteDrivers,
          updateDrivers,
          deleteBusinessObjectSpecs,
          updateBusinessObjectSpecs,
          deleteSubmodels,
        } = mutation;

        const subdriverDeleted = updateDrivers?.some(
          (update) => update.dimensional?.deleteSubDrivers != null,
        );

        // when we delete, clear out this state
        if (
          deleteDrivers != null ||
          subdriverDeleted ||
          deleteBusinessObjectSpecs != null ||
          (updateBusinessObjectSpecs != null &&
            updateBusinessObjectSpecs.some(
              (update) =>
                (update.deleteFields != null && update.deleteFields.length > 0) ||
                (update.updateCollection != null &&
                  (update.updateCollection.removeDimensionalProperties != null ||
                    update.updateCollection.removeDriverProperties != null)),
            )) ||
          deleteSubmodels != null
        ) {
          return {};
        }
      }
      return state;
    });
  },
});

export const {
  closeDialog,
  openDeleteDriverDialog,
  openDeleteObjectSpecDialog,
  openDeleteObjectTableColumnDialog,
  openDeleteModelDialog,
  openDeleteDimensionDialog,
  openDeleteAttributeDialog,
  openDeleteIntegrationDialog,
  openDeleteIntegrationQueryDialog,
  openDeleteOrgUserDialog,
  openDeleteEventGroupDialog,
} = alertDialogSlice.actions;

export default alertDialogSlice.reducer;
