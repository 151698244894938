import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Divider, Flex } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';

import TimestampDisplayCell from 'components/DisplayCell/TimestampDisplayCell';
import DayPicker from 'components/MonthPicker/DayPicker';
import TableCellPopover from 'components/TableCellPopover/TableCellPopover';
import TimeSeriesCellWrapper, {
  ActiveCellProps,
  TimeSeriesCellProps,
} from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapper';
import { MAX_DATE, MIN_DATE } from 'config/datetime';
import { ValueType } from 'generated/graphql';
import { START_OF_MONTH } from 'helpers/dates';
import useActiveCell from 'hooks/useActiveCell';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import { TimestampValue, toTimestampValue } from 'reduxStore/models/value';

const TimestampTimeSeriesCell: React.FC<TimeSeriesCellProps<TimestampValue>> = ({
  value,
  color,
  onMouseDown,
  onMouseEnter,
  onContextMenu,
  showComparisonHighlight,
  onSave,
  width,
  alert,
}) => {
  const { isActive } = useCellSelectionStateContext();
  return (
    <TimeSeriesCellWrapper
      color={color}
      width={width}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onContextMenu={onContextMenu}
      showComparisonHighlight={showComparisonHighlight}
    >
      {isActive ? (
        <TimestampActiveCell value={value} onSave={onSave} />
      ) : (
        <TimestampDisplayCell value={value} />
      )}
      {alert}
    </TimeSeriesCellWrapper>
  );
};

const TimestampActiveCell: React.FC<ActiveCellProps<TimestampValue>> = ({ value, onSave }) => {
  const { isEditing, onStartEditingCallback, onSaveCallback, onCancel } = useActiveCell({
    onSave,
  });
  const selected = useMemo(() => {
    const isoTime = value;
    const date = isoTime != null && isoTime !== '' ? DateTime.fromISO(isoTime) : undefined;
    return date?.isValid ? date : START_OF_MONTH;
  }, [value]);
  const onPickMonth = useCallback(
    (dateTime: DateTime) => onSaveCallback(toTimestampValue(dateTime), undefined),
    [onSaveCallback],
  );

  const onClearDate = useCallback(() => {
    // TODO: Fix followup: T-17605
    const arg = { type: ValueType.Timestamp, value: undefined } as unknown as TimestampValue;
    onSaveCallback(arg, undefined);
  }, [onSaveCallback]);

  return (
    <TableCellPopover
      content={
        <Flex flexDir="column">
          <DayPicker
            onDateSelect={onPickMonth}
            selected={selected}
            minDate={MIN_DATE}
            maxDate={MAX_DATE}
          />
          <Divider borderColor="gray.400" my={2} />
          <Button
            onClick={onClearDate}
            variant="text"
            borderRadius="md"
            role="group"
            _hover={{ color: 'white', bgColor: 'selection.500' }}
            transition="none"
            alignItems="center"
            leftIcon={<DeleteIcon color="gray.400" _groupHover={{ color: 'white' }} />}
            justifyContent="flex-start"
          >
            Clear date
          </Button>
        </Flex>
      }
      visible={isEditing}
      onClose={onCancel}
      autoFocus
    >
      <TimestampDisplayCell value={value} onDoubleClick={onStartEditingCallback} />
    </TableCellPopover>
  );
};

export default React.memo(TimestampTimeSeriesCell);
