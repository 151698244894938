import React, { useContext } from 'react';

import DriverNameTableCell from 'components/DriverNameTableCell/DriverNameTableCell';
import DriverNameTableCellLight from 'components/DriverNameTableCell/DriverNameTableCellLight';
import { RenderModeContext } from 'components/List/enhanceComponent';
import DependentsTableCell from 'components/SubmodelTable/DependentsTableCell';
import DependentsTableCellLight from 'components/SubmodelTable/DependentsTableCellLight';
import DriverSparklineTableCell from 'components/SubmodelTable/DriverSparklineTableCell';
import DriverSparklineTableCellLight from 'components/SubmodelTable/DriverSparklineTableCellLight';
import FormulaTableCell from 'components/SubmodelTable/FormulaTableCell';
import FormulaTableCellLight from 'components/SubmodelTable/FormulaTableCellLight';
import RollupTypeTableCell from 'components/SubmodelTable/RollupTypeTableCell';
import RollupTypeTableCellLight from 'components/SubmodelTable/RollupTypeTableCellLight';
import { DriverRowContext } from 'config/driverRowContext';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { comparisonLayoutSelector } from 'selectors/rollupSelector';
import { driverPropertyColumnsSelector } from 'selectors/visibleColumnTypesSelector';

import { EmptyDriverTableCell, shouldHideColumnPropertyCell } from './EmptyDriverTableCell';

export const DriverTableRowColumns: React.FC<{ isLast: boolean }> = React.memo(({ isLast }) => {
  const { blockId } = useBlockContext();
  const { groupId, comparisonType: rowComparisonType } = useContext(DriverRowContext);
  const propertyColumns = useAppSelector((state) => driverPropertyColumnsSelector(state, blockId));

  const comparisonLayout = useAppSelector((state) => comparisonLayoutSelector(state, blockId));
  const renderMode = useContext(RenderModeContext);

  return (
    <>
      {propertyColumns.flatMap((column) => {
        const { type: columnType, layerIds } = column;
        return layerIds.map((columnLayerId) => {
          const k = `${columnType}.${columnLayerId}`;

          if (
            shouldHideColumnPropertyCell({
              comparisonLayout,
              columnType,
              rowComparisonType,
            })
          ) {
            return (
              <EmptyDriverTableCell key={k} columnType={columnType} columnLayerId={columnLayerId}>
                -
              </EmptyDriverTableCell>
            );
          }

          switch (columnType) {
            case 'name': {
              return renderMode === 'FULL' ? (
                <DriverNameTableCell key={k} groupId={groupId} isLast={isLast} />
              ) : (
                <DriverNameTableCellLight key={k} groupId={groupId} isLast={isLast} />
              );
            }
            case 'rollupType': {
              return renderMode === 'FULL' ? (
                <RollupTypeTableCell key={k} columnLayerId={columnLayerId} />
              ) : (
                <RollupTypeTableCellLight key={k} columnLayerId={columnLayerId} />
              );
            }
            case 'formula': {
              return renderMode === 'FULL' ? (
                <FormulaTableCell key={k} columnType="formula" columnLayerId={columnLayerId} />
              ) : (
                <FormulaTableCellLight key={k} columnType="formula" columnLayerId={columnLayerId} />
              );
            }
            case 'actualsFormula': {
              return renderMode === 'FULL' ? (
                <FormulaTableCell
                  key={k}
                  columnType="actualsFormula"
                  columnLayerId={columnLayerId}
                />
              ) : (
                <FormulaTableCellLight
                  key={k}
                  columnType="actualsFormula"
                  columnLayerId={columnLayerId}
                />
              );
            }
            case 'usedBy': {
              return renderMode === 'FULL' ? (
                <DependentsTableCell key={k} columnLayerId={columnLayerId} />
              ) : (
                <DependentsTableCellLight key={k} columnLayerId={columnLayerId} />
              );
            }
            case 'graph': {
              return renderMode === 'FULL' ? (
                <DriverSparklineTableCell key={k} columnLayerId={columnLayerId} />
              ) : (
                <DriverSparklineTableCellLight key={k} columnLayerId={columnLayerId} />
              );
            }
            default: {
              return null;
            }
          }
        });
      })}
    </>
  );
});

DriverTableRowColumns.displayName = 'DriverTableRowColumns';
