import { constant } from 'lodash';
import noop from 'lodash/noop';
import { createContext } from 'react';

export type ScrollHandler = (scrollTop: number) => void;
export type StickyContextState = {
  verticalScrollingTargetRef?: React.RefObject<HTMLDivElement>;
  subscribe: (handler: ScrollHandler) => void;
  unsubscribe: (handler: ScrollHandler) => void;
  getIsScrolling: () => boolean;
};

export const StickyContext = createContext<StickyContextState>({
  verticalScrollingTargetRef: undefined,
  subscribe: noop,
  unsubscribe: noop,
  getIsScrolling: constant(false),
});
