import { useContext } from 'react';

import { CellStyleContext, useIsDraggingToSelectCellsRef } from 'config/cells';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';

export function useBGColorForCell(layerColor?: string): string {
  const { isHighlighted, isSelected } = useCellSelectionStateContext();
  const { definedBgColor, defaultBgColor } = useContext(CellStyleContext);
  const { isDraggingToSelect } = useIsDraggingToSelectCellsRef().current;
  if (isSelected && isDraggingToSelect) {
    return 'selection.100';
  }

  if (definedBgColor != null) {
    return definedBgColor;
  }

  if (layerColor != null) {
    return layerColor;
  }

  if (isHighlighted) {
    return 'selection.100';
  }

  return defaultBgColor;
}
