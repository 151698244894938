import {
  BusinessObjectFieldCellRef,
  BusinessObjectTimeSeriesCellRef,
  OBJECT_FIELD_NAME_COLUMN_KEY,
} from 'config/cells';
import { ColumnOrMonthKey, ModelViewColumnType } from 'config/modelView';
import { OBJECT_INITIAL_VALUE_COLUMN_KEY, OBJECT_PROPERTY_COLUMN_KEY } from 'config/objectGridView';
import { OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DriverProperty, DriverPropertyId } from 'reduxStore/models/collections';
import { MonthKey } from 'types/datetime';

import { getMonthColumnKey } from './cells';
import { isValidMonthKey } from './dates';
import { TimeSeriesColumn } from './rollups';

export function orderedObjectTableBlockColumnKeysForTimeseries({
  blockMonthKeys,
}: {
  blockMonthKeys: MonthKey[];
}) {
  return [
    OBJECT_PROPERTY_COLUMN_KEY,
    OBJECT_INITIAL_VALUE_COLUMN_KEY,
    ...blockMonthKeys.map((monthKey) => getMonthColumnKey(monthKey)),
  ];
}

export function orderedObjectTableBlockColumnKeysForDatabase({
  tsColumns,
  visibleColumns,
  timeSeriesFieldSpecId,
  driverPropertyId,
}: {
  tsColumns: TimeSeriesColumn[];
  visibleColumns: ColumnOrMonthKey[];
  timeSeriesFieldSpecId: BusinessObjectFieldSpecId | null;
  driverPropertyId: Record<DriverPropertyId, DriverProperty | undefined>;
}) {
  const driverPropertyForDisplayedTimeSeriesColumn =
    timeSeriesFieldSpecId != null ? driverPropertyId[timeSeriesFieldSpecId] : null;

  const visibleColumnKeys: Array<
    BusinessObjectFieldCellRef['columnKey'] | BusinessObjectTimeSeriesCellRef['columnKey']
  > = visibleColumns
    .filter((col) => col !== timeSeriesFieldSpecId && !isValidMonthKey(col))
    .map((col) => {
      if (col === OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY) {
        return OBJECT_FIELD_NAME_COLUMN_KEY;
      }
      if (['actualsFormula', 'formula'].includes(col)) {
        return {
          columnType: col as ModelViewColumnType,
          columnLayerId: undefined,
        };
      }
      return {
        objectFieldSpecId: col,
      };
    });

  if (timeSeriesFieldSpecId != null) {
    tsColumns.forEach((col) => {
      const monthKey = col.mks[0];
      if (driverPropertyForDisplayedTimeSeriesColumn != null) {
        visibleColumnKeys.push(getMonthColumnKey(monthKey, col.rollupType));
      } else {
        visibleColumnKeys.push({ monthKey, objectFieldSpecId: timeSeriesFieldSpecId });
      }
    });
  }

  return visibleColumnKeys;
}
