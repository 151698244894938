import { Flex, IconButton, IconButtonProps, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import { GenericModal, ModalContextProvider } from '@features/common/GenericModal';
import CompareScenariosModal from 'components/CompareScenariosModal/CompareScenariosModal';
import ExpandCollapseNavigationSidebarButton from 'components/ExpandCollapseNavigationSidebarButton/ExpandCollapseNavigationSidebarButton';
import NamedDatasetVersionsMenu from 'components/NamedDatasetVersionMenu/NamedDatasetVersionsMenu';
import HelpMenuSidebarItem from 'components/NavigationSidebarItem/HelpMenuSidebarItem';
import SearchSidebarItem from 'components/NavigationSidebarItem/SearchSidebarItem';
import ScenarioCTAButton from 'components/ScenarioManagement/ScenarioCTAButton';
import ScenarioManagement from 'components/ScenarioManagement/ScenarioManagement';
import { ScenariosContextProvider } from 'components/ScenarioManagement/ScenariosContextProvider';
import UserSettingsMenu from 'components/TopNavigationBar/UserSettingsMenu';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppSelector from 'hooks/useAppSelector';
import { datasetIsFullyLoadedSelector } from 'selectors/datasetSelector';
import {
  currentLayerIsDefaultSelector,
  currentLayerIsDraftSelector,
} from 'selectors/layerSelector';
import { isImpersonatingSelector } from 'selectors/loginSelector';

export const TOOLTIP_OPEN_DELAY = 500;
export const PAGE_CONTENT_BORDER_RADIUS = '6px';
export const ICON_BORDER_RADIUS = '4px';
export const CTA_BUTTON_YELLOW = '#FBCF79';
export const CTA_BUTTON_YELLOW_GRADIENT =
  'linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FBCF79';
export const LIGHT_GRAY = 'rgba(0, 0, 0, 0.05)';
export const DARK_GRAY = 'rgba(0, 0, 0, 0.20)';

interface Props {
  children: React.ReactElement;
}

export const GlobalNavigationMenu: React.FC<Props> = ({ children }) => {
  const isImpersonating = useAppSelector(isImpersonatingSelector);
  const currentLayerIsDraft = useAppSelector(currentLayerIsDraftSelector);
  const currentLayerIsDefault = useAppSelector(currentLayerIsDefaultSelector);
  const isFullDatasetReady = useAppSelector(datasetIsFullyLoadedSelector);
  const { canSearchRunway } = useAccessCapabilities();

  const draftTopBarNav = useColorModeValue(
    '#FFF0D3',
    'linear-gradient(276deg, rgba(251, 207, 121, 0.00) -5.41%, rgba(251, 207, 121, 0.30) 46.19%, rgba(251, 207, 121, 0.00) 102.1%), #1D2327',
  );

  return (
    <ModalContextProvider>
      <Flex
        height="100vh"
        paddingX="6px"
        paddingBottom="6px"
        background={currentLayerIsDraft ? draftTopBarNav : 'gray.100'}
        overflow="hidden"
        flexDirection="column"
        overflowY="auto"
        borderRadius={PAGE_CONTENT_BORDER_RADIUS}
        borderTopRadius={isImpersonating ? '0px' : PAGE_CONTENT_BORDER_RADIUS}
        id="scenarios-navigation-container"
      >
        <ScenariosContextProvider>
          <Flex
            height="43px" // the relationship between this component's height and its parent's 100vh results in a smaller computed height on the browser (should be 40px computed height on the browser)
            alignItems="center"
            justifyContent="space-between"
            paddingY="6px"
            paddingX="14px"
            id="top-navigation"
          >
            <Flex alignItems="center" gap="2px">
              <ExpandCollapseNavigationSidebarButton />
              <ScenarioManagement />
            </Flex>
            <Flex gap="8px" alignItems="center">
              {!currentLayerIsDefault && <ScenarioCTAButton />}
              {canSearchRunway && <SearchSidebarItem isDisabled={!isFullDatasetReady} />}
              <NamedDatasetVersionsMenu />
              <HelpMenuSidebarItem />
              <Flex paddingLeft="4px">
                <UserSettingsMenu />
              </Flex>
            </Flex>
          </Flex>
          <CompareScenariosModal />
        </ScenariosContextProvider>
        <GenericModal />
        {children}
      </Flex>
    </ModalContextProvider>
  );
};

export const GlobalNavIcon = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const currentLayerIsDraft = useAppSelector(currentLayerIsDraftSelector);

  return (
    <IconButton
      ref={ref}
      variant="icon"
      color="icons.fill"
      boxSize="20px"
      borderRadius={ICON_BORDER_RADIUS}
      _hover={{
        bgColor: currentLayerIsDraft ? 'scenarios.beige' : LIGHT_GRAY,
        color: 'icons.fill',
      }}
      {...props}
    />
  );
});
