import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';

import { DatasetMutationInput, EventGroupUpdateInput, EventUpdateInput } from 'generated/graphql';
import { accessibleLayerIdsForMutation } from 'helpers/accessibleLayerIdsForMutation';
import { getComputedEventGroupRangeWithDefault } from 'helpers/eventGroups';
import {
  computeFreshSortIndexUpdates,
  computeSortIndexUpdates,
  hasNoNullSortIndex,
} from 'helpers/reorderList';
import { uuidv4 } from 'helpers/uuidv4';
import { Event, EventGroupId, EventId, PopulatedEventGroup } from 'reduxStore/models/events';
import { applyMutationLocally_INTERNAL } from 'reduxStore/reducers/datasetSlice';
import rootReducer from 'reduxStore/reducers/index';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { activeComparisonLayerIdsSelector } from 'selectors/activeBlocksSelector';
import {
  eventsWithoutLiveEditsByIdForLayerSelector,
  populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { previousMutationIdSelector } from 'selectors/previousMutationIdSelector';
import { ISOTime } from 'types/datetime';

type SortIndexMutations = {
  updateEvents: EventUpdateInput[];
  updateEventGroups: EventGroupUpdateInput[];
};

interface SortableEntities {
  id: EventId | EventGroupId;
  type: 'event' | 'group';
  sortIndex?: number;
  start: ISOTime;
}

// Get all sort index updates as a result of recomputing all siblings under a
// parent. This can be used to backfill stuff without having to set a
// toInsertId & beforeId.
export const backfillSiblingSortIndexMutations = (
  state: RootState,
  id: EventId | EventGroupId,
): SortIndexMutations => {
  const eventsById = eventsWithoutLiveEditsByIdForLayerSelector(state);
  const eventGroupsById = populatedEventGroupsWithoutLiveEditsByIdForLayerSelector(state);
  const entity = eventsById[id] ?? eventGroupsById[id];
  if (entity == null) {
    throw new Error('expected event or group to exist');
  }
  const sorted = getSortedSiblingEntities(eventsById, eventGroupsById, entity.parentId);

  // If all siblings have a sortIndex, then don't do anything
  if (hasNoNullSortIndex(sorted)) {
    return { updateEvents: [], updateEventGroups: [] };
  }

  const sortIndexUpdates = computeFreshSortIndexUpdates(sorted);
  return generateBackfillMutations(eventsById, eventGroupsById, sortIndexUpdates);
};

// Compute mutations to update event(group)s with a sort index under a parentId
// such that toInsertId is inserted before beforeId (or at the start if beforeId
// is undefined).
//
// NOTE: The entity associated with toInsertId has to exist in state for this to work.
export const sortIndexMutationsForInsertion = (
  state: RootState,
  toInsertId: EventId | EventGroupId,
  insertBeforeId: EventId | EventGroupId | 'start' | 'end' = 'end',
): SortIndexMutations => {
  const eventsById = eventsWithoutLiveEditsByIdForLayerSelector(state);
  const eventGroupsById = populatedEventGroupsWithoutLiveEditsByIdForLayerSelector(state);
  const entity = eventsById[toInsertId] ?? eventGroupsById[toInsertId];
  if (entity == null) {
    throw new Error('expected event or group to exist');
  }
  const sorted = getSortedSiblingEntities(eventsById, eventGroupsById, entity.parentId);
  const sortIndexUpdates =
    insertBeforeId === 'start' || insertBeforeId === 'end'
      ? computeSortIndexUpdates(sorted, { toInsertId, defaultPosition: insertBeforeId })
      : computeSortIndexUpdates(sorted, { toInsertId, beforeId: insertBeforeId });
  return generateBackfillMutations(eventsById, eventGroupsById, sortIndexUpdates);
};

export const peekMutationStateChange = (
  state: RootState,
  mutation: DatasetMutationInput,
): RootState => {
  const activeLayerIds = activeComparisonLayerIdsSelector(state);
  const accessibleLayerIds = accessibleLayerIdsForMutation(state, mutation);

  return rootReducer(
    state,
    applyMutationLocally_INTERNAL({
      layerId: currentLayerIdSelector(state),
      orgId: state.selectedOrg?.id ?? '',
      mutationBatch: {
        id: uuidv4(),
        prevMutationId: previousMutationIdSelector(state),
        mutation,
      },
      accessibleLayerIds,
      activeLayerIds,
    }),
  );
};

export const stripInsertBeforeId = (mutation: DatasetMutationInput): DatasetMutationInput => {
  const copy = { ...mutation };
  copy.updateEvents = (mutation?.updateEvents ?? []).map((m) => omit(m, 'insertBeforeId'));
  copy.updateEventGroups = (mutation?.updateEventGroups ?? []).map((m) =>
    omit(m, 'insertBeforeId'),
  );
  return copy;
};

function getSortedSiblingEntities(
  eventsById: Record<EventId, Event>,
  eventGroupsById: NullableRecord<EventGroupId, PopulatedEventGroup>,
  parentId: EventGroupId | undefined,
): SortableEntities[] {
  const allEvents = Object.values(eventsById);
  const allEventGroups = Object.values(eventGroupsById);

  const sortableEvents: SortableEntities[] = allEvents
    .filter((e) => e.parentId === parentId)
    .map((event) => ({ ...event, type: 'event' }));

  const sortableGroups: SortableEntities[] = allEventGroups
    .filter((e): e is PopulatedEventGroup => e?.parentId === parentId)
    .map((group) => ({
      ...group,
      type: 'group',
      start: getComputedEventGroupRangeWithDefault(group, {}).start,
    }));

  return sortBy([...sortableEvents, ...sortableGroups], 'sortIndex', 'start');
}

function generateBackfillMutations(
  eventsById: Record<EventId, Event>,
  eventGroupsById: NullableRecord<EventGroupId, PopulatedEventGroup>,
  sortIndexUpdates: NullableRecord<string, number>,
) {
  const backfillData: SortIndexMutations = { updateEvents: [], updateEventGroups: [] };
  Object.entries(sortIndexUpdates).forEach(([id, sortIndex]) => {
    const update = { id, sortIndex };
    if (eventsById[id] != null) {
      backfillData.updateEvents.push(update);
    } else if (eventGroupsById[id] != null) {
      backfillData.updateEventGroups.push(update);
    }
  });
  return backfillData;
}
