import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useToken,
} from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import SelectMenu, {
  SelectItem,
  useBlockParentSelectMenuKeyboardEvents,
} from 'components/SelectMenu/SelectMenu';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { COLOR_OPTIONS } from 'config/dimensionColors';
import useAppDispatch from 'hooks/useAppDispatch';
import useControlledPopover from 'hooks/useControlledPopover';
import { updateDimensionColor } from 'reduxStore/actions/dimensionMutations';
import { Dimension } from 'reduxStore/models/dimensions';

const DEFAULT_COLOR = 'gray.200';

const DimensionColorBox = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof Box>>(
  (props, ref) => <Box ref={ref} h={4} w={4} borderRadius="3px" {...props} />,
);

interface DimensionColorPickerMenuProps {
  onSelect: (color: string) => void;
  selectedColor: string;
}

const DimensionColorPickerMenu: React.FC<DimensionColorPickerMenuProps> = ({
  onSelect,
  selectedColor,
}) => {
  useBlockParentSelectMenuKeyboardEvents();
  const [defaultColor] = useToken('colors', ['gray.200']);

  const onSelectCallback = useCallback(
    (item: SelectItem) => {
      onSelect(item.id);
    },
    [onSelect],
  );

  const items: SelectItem[] = useMemo(() => {
    const options = [{ bgColor: defaultColor, displayName: 'Default' }, ...COLOR_OPTIONS];
    return options.map(({ bgColor, displayName }) => ({
      id: bgColor,
      name: (
        <Flex columnGap={1} alignItems="center">
          <DimensionColorBox backgroundColor={bgColor} />
          {displayName}
        </Flex>
      ),
      icon: <CheckIcon visibility={selectedColor === bgColor ? 'visible' : 'hidden'} />,
    }));
  }, [selectedColor, defaultColor]);

  return (
    <SelectMenuContainer boxShadow="none">
      <SelectMenu items={items} onSelect={onSelectCallback} clearFocusOnMouseLeave width="20rem">
        {({ item, idx, isFocused }) => (
          <SelectMenuItem
            key={item.id}
            idx={idx}
            name={item.name}
            isFocused={isFocused}
            isDisabled={item.isDisabled}
            icon={item.icon}
            shortcutHint={null}
          />
        )}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

interface DimensionColorPickerProps {
  dimension: Dimension | undefined;
  defaultIsOpen?: boolean;
}

const DimensionColorPicker: React.FC<DimensionColorPickerProps> = ({
  dimension,
  defaultIsOpen,
}) => {
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover({
    defaultIsOpen,
  });
  const selectedColorOrDefault = dimension?.color ?? DEFAULT_COLOR;
  const dispatch = useAppDispatch();

  const onUpdateDimensionColor = useCallback(
    (color: string) => {
      if (dimension?.id != null) {
        if (dimension?.color === color) {
          dispatch(updateDimensionColor({ id: dimension.id, color: DEFAULT_COLOR }));
        } else {
          dispatch(updateDimensionColor({ id: dimension.id, color }));
        }
        return;
      }
      onClose();
    },
    [dimension?.color, dimension?.id, dispatch, onClose],
  );

  return (
    <Popover
      isLazy
      placement="bottom-start"
      returnFocusOnClose={false}
      closeOnBlur={false}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <DimensionColorBox
          ref={triggerRef}
          cursor="pointer"
          backgroundColor={selectedColorOrDefault}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent ref={contentRef} padding={0} boxShadow="menu">
          <DimensionColorPickerMenu
            selectedColor={selectedColorOrDefault}
            onSelect={onUpdateDimensionColor}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default DimensionColorPicker;
