import { Box, Flex } from '@chakra-ui/react';
import Tippy from '@tippyjs/react';
import React, { useContext, useEffect, useState } from 'react';

import CreateDimensionalDriverMenu from 'components/DimensionalDriverMenu/CreateDimensionalDriverMenu';
import EditDriverInput from 'components/DimensionalDriverMenu/EditDriverInput';
import useEditDriverView from 'components/DimensionalDriverMenu/useEditDriverView';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { DimDriverEditReactContext } from 'config/dimDriverEditContext';
import { nullSafeEqual } from 'helpers/typescript';
import { DimensionId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';

interface EditDriverDimensionsProps {
  driverId?: DriverId;
  onCancel: () => void;
  dimMenuIsDefaultOpen: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
}

const EditDriverDimensions: React.FC<EditDriverDimensionsProps> = ({
  driverId,
  onCancel,
  dimMenuIsDefaultOpen,
  inputRef,
}) => {
  const [selectedDimensionId, setSelectedDimensionId] = useState<DimensionId | undefined>();

  const [view, setView] = useEditDriverView({ dimMenuIsDefaultOpen });

  const { subDriverName, targetDriver, originalDimDriver, originalDriverName } =
    useContext(DimDriverEditReactContext);

  // If the driver is being renamed but the target driver has not changed,
  // we need to allow the user to select a new target driver or create a standalone driver
  const isRenaming = originalDriverName !== subDriverName;
  const shouldAllowTargetDriverReselect =
    nullSafeEqual(originalDimDriver?.id, targetDriver?.id) && isRenaming;
  useEffect(() => {
    if (view !== 'driverSelectMenu' && shouldAllowTargetDriverReselect) {
      setView.driverSelectMenu();
    }
  }, [setView, shouldAllowTargetDriverReselect, view]);

  return (
    <Tippy
      placement="bottom-start"
      interactive
      visible
      // N.B. we disable flipping here because the `CreateDimensionalDriverMenu` below has
      // a height that depends on the selected element
      offset={[-10, 6]}
      render={() => (
        // Add a transparent wrapper so that we can have the nice spacing but
        // without a weird clickable gap between the selected cell and the
        // popover.
        <Box bg="transparent" mt={1}>
          <Box bg="surface" borderRadius="md" boxShadow="menu" overflow="hidden" w="28rem">
            <SelectMenuContainer>
              <CreateDimensionalDriverMenu
                view={view}
                setView={setView}
                selectedDimensionId={selectedDimensionId}
                onCancel={onCancel}
                allowAddingExistingDrivers={false}
                inputRef={inputRef}
              />
            </SelectMenuContainer>
          </Box>
        </Box>
      )}
    >
      <Flex bgColor="surface" width="full">
        <EditDriverInput
          driverId={driverId}
          onCancel={onCancel}
          setSelectedDimensionId={setSelectedDimensionId}
          view={view}
          setView={setView}
          placeholder="Enter driver name"
          inputRef={inputRef}
        />
      </Flex>
    </Tippy>
  );
};

export default EditDriverDimensions;
