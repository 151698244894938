import { IconButton } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import { Tooltip } from 'chakra/tooltip';
import { LeverType } from 'generated/graphql';
import { preventEventDefault, stopEventPropagation } from 'helpers/browserEvent';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { updateLeverType } from 'reduxStore/actions/driverMutations';
import { DriverId } from 'reduxStore/models/drivers';
import { driverIsKpiSelector } from 'selectors/driversSelector';
import Star from 'vectors/Star';

interface Props {
  id: DriverId;
}

const KPIIcon: React.FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { readOnly } = useBlockContext();
  const isKpi = useAppSelector((state) => driverIsKpiSelector(state, id));
  const onClick: React.MouseEventHandler = useCallback(() => {
    const leverType = isKpi ? LeverType.Unknown : LeverType.Kpi;
    dispatch(updateLeverType({ id, leverType }));
  }, [dispatch, isKpi, id]);

  return (
    <Tooltip placement="top" label={isKpi ? 'This driver is a KPI' : undefined} shouldWrapChildren>
      <IconButton
        role="checkbox"
        aria-checked={isKpi}
        variant="icon"
        alignItems="center"
        disabled={readOnly}
        h="unset"
        w="unset"
        border="none"
        minW="unset"
        boxSize={6}
        borderRadius="md"
        _hover={{ bgColor: 'gray.200' }}
        cursor={!readOnly ? 'pointer' : 'default'}
        onClick={(ev) => {
          if (!readOnly) {
            onClick(ev);
            preventEventDefault(ev);
          }
        }}
        color={isKpi ? 'gray.600' : 'gray.400'}
        onMouseDown={stopEventPropagation}
        data-testid="kpi-toggle"
        aria-label="Toggle KPI"
        icon={<Star padding={1} boxSize={6} />}
      />
    </Tooltip>
  );
};

export default KPIIcon;
