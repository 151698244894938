import { TabPanel } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { OnChangeFormulaArgs } from 'components/FormulaInput/FormulaInput';
import FormulaInputWrapper from 'components/FormulaInputWrapper/FormulaInputWrapper';
import { useFormulaDisplay } from 'components/SubmodelTable/useFormulaDisplay';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { DriverId } from 'reduxStore/models/drivers';

interface Props {
  driverId: DriverId;
  error: string | undefined;
  isActive: boolean;
  isActuals: boolean;
  onCancel: () => void;
  onChange: (args: OnChangeFormulaArgs) => void;
  onSave: () => void;
}

const FormulaTabPanel: React.FC<Props> = ({
  driverId,
  error,
  isActive,
  isActuals,
  onCancel,
  onChange,
  onSave,
}) => {
  const formulaEntityId: FormulaEntityTypedId = useMemo(
    () => ({ id: driverId, type: 'driver' }),
    [driverId],
  );

  const formulaDisplayProps = useFormulaDisplay({
    isActuals,
    driverId,
  });

  return (
    <TabPanel data-testid={isActive ? 'active-formula-tab' : undefined} px={2}>
      <FormulaInputWrapper
        formulaEntityId={formulaEntityId}
        formulaError={error}
        isActive={isActive}
        isActuals={isActuals}
        onCancel={onCancel}
        onChange={onChange}
        onSave={onSave}
        {...formulaDisplayProps}
      />
    </TabPanel>
  );
};

export default React.memo(FormulaTabPanel);
