import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { updateBlockDateRange } from 'reduxStore/actions/blockMutations';
import { BlockId } from 'reduxStore/models/blocks';
import { blockConfigFiscalYearStartMonthSelector } from 'selectors/blocksSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { rollupTypeForBlockSelector } from 'selectors/rollupSelector';

const useCustomizeBlockDateRangeContext = (blockId: BlockId) => {
  const dispatch = useAppDispatch();
  const rollupType = useAppSelector((state) => rollupTypeForBlockSelector(state, blockId));
  const fiscalYearStartMonth = useAppSelector((state) =>
    blockConfigFiscalYearStartMonthSelector(state, blockId),
  );

  const setDateRange = useCallback(
    (start: DateTime, end: DateTime) => {
      dispatch(updateBlockDateRange({ blockId, newDateRange: { start, end } }));
    },
    [blockId, dispatch],
  );

  const [start, end] = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));

  const value = useMemo(
    () => ({
      start,
      end,
      setDateRange,
      rollupType,
      fiscalYearStartMonth,
    }),
    [start, end, setDateRange, rollupType, fiscalYearStartMonth],
  );

  return value;
};

export default useCustomizeBlockDateRangeContext;
