import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'DriverTable',
  viewBox: '0 0 16 16',
  defaultProps: { boxSize: 4 },
  path: (
    <g fill="currentColor">
      <path d="M14 1.3335H2.00004C1.82323 1.3335 1.65366 1.40373 1.52864 1.52876C1.40361 1.65378 1.33337 1.82335 1.33337 2.00016V14.0002C1.33337 14.177 1.40361 14.3465 1.52864 14.4716C1.65366 14.5966 1.82323 14.6668 2.00004 14.6668H14C14.1769 14.6668 14.3464 14.5966 14.4714 14.4716C14.5965 14.3465 14.6667 14.177 14.6667 14.0002V2.00016C14.6667 1.82335 14.5965 1.65378 14.4714 1.52876C14.3464 1.40373 14.1769 1.3335 14 1.3335ZM5.33337 13.3335H2.66671V10.6668H5.33337V13.3335ZM5.33337 9.3335H2.66671V6.66683H5.33337V9.3335ZM5.33337 5.3335H2.66671V2.66683H5.33337V5.3335ZM13.3334 13.3335H6.66671V10.6668H13.3334V13.3335ZM13.3334 9.3335H6.66671V6.66683H13.3334V9.3335ZM13.3334 5.3335H6.66671V2.66683H13.3334V5.3335Z" />
    </g>
  ),
});
