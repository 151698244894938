import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'AutomaticIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.09091C2 2.48818 2.48818 2 3.09091 2H12.9091C13.5118 2 14 2.48818 14 3.09091V12.9091C14 13.5118 13.5118 14 12.9091 14H3.09091C2.48818 14 2 13.5118 2 12.9091V3.09091ZM6.87519 9.63184L6.41248 11H5L7.15221 5H8.85084L11 11H9.58752L9.12481 9.63184H6.87519Z"
      fill="currentColor"
    />
  ),
});
