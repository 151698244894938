import { Button, useColorModeValue, useToast } from '@chakra-ui/react';
import { useCallback, useContext } from 'react';

import { Tooltip } from 'chakra/tooltip';
import {
  CTA_BUTTON_YELLOW,
  CTA_BUTTON_YELLOW_GRADIENT,
  TOOLTIP_OPEN_DELAY,
} from 'components/RunwayApp/GlobalNavigationMenu';
import { ScenarioContext } from 'components/ScenarioManagement/ScenariosContextProvider';
import Toast from 'components/Toast/Toast';
import { KEYBOARD_SHORTCUTS } from 'config/shortcuts';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { navigateToScenarioComparisonModal } from 'reduxStore/actions/navigateTo';
import { currentLayerIsDraftSelector } from 'selectors/layerSelector';
import { allLayerIdsToCompareSelector } from 'selectors/scenarioComparisonSelector';
const TOAST_MESSAGE =
  'Psst! Next time use ' + KEYBOARD_SHORTCUTS.merge + ' to open the merge window faster 🌚';

const ScenarioCTAButton = () => {
  const dispatch = useAppDispatch();
  const currentLayerIsDraft = useAppSelector(currentLayerIsDraftSelector);
  const compareToLayerIds = useAppSelector(allLayerIdsToCompareSelector);

  const { canMergeScenarios } = useAccessCapabilities();
  const shortcutSuggestionToast = useToast();

  const sendToast = useCallback(() => {
    shortcutSuggestionToast({
      duration: 3000,
      render: () => <Toast description={TOAST_MESSAGE} dark />,
    });
  }, [shortcutSuggestionToast]);
  const { onUndraftingLayer } = useContext(ScenarioContext);

  const onReviewAndMerge = useCallback(() => {
    sendToast();
    dispatch(navigateToScenarioComparisonModal({ compareToLayerIds }));
  }, [compareToLayerIds, dispatch, sendToast]);

  const isDark = useColorModeValue(false, true);
  const buttonColors = isDark
    ? {
        color: 'surface',
        borderColor: CTA_BUTTON_YELLOW,
        bgColor: '#FBCF79',
        _hover: {
          background: CTA_BUTTON_YELLOW_GRADIENT,
          borderColor: CTA_BUTTON_YELLOW_GRADIENT,
        },
      }
    : currentLayerIsDraft
      ? {
          borderColor: CTA_BUTTON_YELLOW,
          bgColor: CTA_BUTTON_YELLOW,
          _hover: {
            background: CTA_BUTTON_YELLOW_GRADIENT,
            borderColor: CTA_BUTTON_YELLOW_GRADIENT,
          },
        }
      : {};

  return (
    <Tooltip
      label={KEYBOARD_SHORTCUTS.merge}
      shouldWrapChildren
      placement="bottom"
      openDelay={TOOLTIP_OPEN_DELAY}
      closeOnPointerDown
    >
      <Button
        id={canMergeScenarios ? 'merge' : 'saveAs'}
        onClick={canMergeScenarios ? onReviewAndMerge : onUndraftingLayer}
        fontSize="xs"
        height="24px"
        paddingX="6px"
        paddingY={0}
        {...buttonColors}
      >
        {canMergeScenarios ? 'Review and merge' : 'Save as new scenario'}
      </Button>
    </Tooltip>
  );
};

export default ScenarioCTAButton;
