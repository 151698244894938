import DefaultScenarioIcon from 'components/ScenarioManagement/DefaultScenarioIcon';
import TextWithTruncation from 'components/TextWithTruncation/TextWithTruncation';
import { extractEmoji } from 'helpers/emoji';
import RepeatClock from 'vectors/RepeatClock';
interface Props {
  label: string;
  isActuals?: boolean;
  isBaselineLayer?: boolean;
  contentType?: 'layerName' | 'namedVersion' | 'comparisonType';
}

const TimeSeriesSubheaderLabel: React.FC<Props> = ({
  label,
  isActuals = false,
  isBaselineLayer,
  contentType = 'comparisonType',
}) => {
  const color = isActuals ? 'gray.500' : 'blue.500';

  const isNamedVersion = contentType === 'namedVersion';
  const isLayerName = contentType === 'layerName';

  const [emoji, text] = extractEmoji(label);
  const layerIcon = emoji ?? <DefaultScenarioIcon />;
  const icon = isNamedVersion ? (
    <RepeatClock boxSize={3} color={color} mt="-2px" />
  ) : isLayerName ? (
    layerIcon
  ) : null;

  return (
    <TextWithTruncation
      id="timeseries-subheader-label"
      fontSize="xxs"
      fontWeight={isBaselineLayer ? 'semibold' : 'medium'}
      color={color}
      noOfLines={1}
      wordBreak="break-all"
    >
      {icon} {text}
    </TextWithTruncation>
  );
};

export default TimeSeriesSubheaderLabel;
