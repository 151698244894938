import { createSelector } from 'reselect';

import { DriverId } from 'reduxStore/models/drivers';
import { dimensionalSubDriverIdsSelector } from 'selectors/driversSelector';
import { prevailingSelectedDriverIdsSelector } from 'selectors/prevailingCellSelectionSelector';
import { Selector } from 'types/redux';

export const prevailingSelectedSubDriverIdsSelector: Selector<DriverId[]> = createSelector(
  prevailingSelectedDriverIdsSelector,
  dimensionalSubDriverIdsSelector,
  (ids, dimensionalSubDriverIds) => ids.filter((id) => dimensionalSubDriverIds.includes(id)),
);
