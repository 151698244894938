import { uniqBy } from 'lodash';

import { DriverType } from 'generated/graphql';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import { EvaluatorDriver } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { getDependencies } from 'helpers/getDependencies';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { Dependency, THIS_MONTH_RELATIVE_RANGE } from 'types/dependencies';

import { DependencyListenerEvaluator } from './formulaEvaluation/DependenciesListenerEvaluator';

export const getDriverDependencies = ({
  driver,
  evaluator,
  dimensionalPropertyEvaluator,
}: {
  driver: EvaluatorDriver | undefined;
  evaluator: DependencyListenerEvaluator;
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
}) => {
  let deps: Dependency[] = [];

  // could be a deleted driver
  if (driver == null) {
    return deps;
  }

  if (driver.type === DriverType.Dimensional) {
    deps = driver.subdrivers.map((s) => ({
      type: 'driver',
      id: s.driverId,
      dateRange: THIS_MONTH_RELATIVE_RANGE,
    }));
  } else if (driver.type === DriverType.Basic) {
    const forecastFormula = driver.forecast.formula;
    const actualsFormula = driver.actuals.formula;

    const forecastDeps =
      forecastFormula == null
        ? []
        : getDependencies({
            entityId: driver.id,
            formula: forecastFormula,
            evaluator,
            dimensionalPropertyEvaluator,
            valueType: driver.valueType,
            entityType: 'driver',
          });
    const actualsDeps =
      actualsFormula == null || actualsFormula === forecastFormula
        ? []
        : getDependencies({
            entityId: driver.id,
            formula: actualsFormula,
            evaluator,
            dimensionalPropertyEvaluator,
            valueType: driver.valueType,
            entityType: 'driver',
          });

    deps = uniqBy([...forecastDeps, ...actualsDeps], 'id');
  }

  return deps;
};

export const getIdsOfExtDriverDependencies = ({
  driver,
  evaluator,
  dimensionalPropertyEvaluator,
}: {
  driver: Driver;
  evaluator: DependencyListenerEvaluator;
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
}) => {
  // could be a deleted driver
  if (driver == null || driver.type === DriverType.Dimensional) {
    return [];
  }
  const formula = driver.actuals.formula;
  if (formula == null) {
    return [];
  }

  const extDriverDeps = getDependencies({
    entityId: driver.id,
    formula,
    evaluator,
    dimensionalPropertyEvaluator,
    valueType: driver.valueType,
    entityType: 'driver',
  }).filter((dep) => dep.type === 'extDriver');
  return extDriverDeps.map((dep) => dep.id);
};

export const getDriverIdsOfDependencies = ({
  driver,
  evaluator,
  dimensionalPropertyEvaluator,
}: {
  driver: EvaluatorDriver;
  evaluator: DependencyListenerEvaluator;
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
}) => {
  return getDriverDependencies({
    driver,
    evaluator,
    dimensionalPropertyEvaluator,
  })
    .filter((dep) => dep.type === 'driver' || dep.type === 'extDriver')
    .map((dep) => dep.id);
};

export const getDirectAndIndirectDependencies = ({
  driver,
  driversById,
  evaluator,
  dimensionalPropertyEvaluator,
}: {
  driver: EvaluatorDriver;
  driversById: Record<DriverId, EvaluatorDriver | undefined>;
  evaluator: DependencyListenerEvaluator;
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
}) => {
  const allDriverDepIds: DriverId[] = [];
  const visited = new Set<DriverId>();
  const toVisit: DriverId[] = [
    ...getDriverIdsOfDependencies({
      driver,
      evaluator,
      dimensionalPropertyEvaluator,
    }),
  ];
  while (toVisit.length > 0) {
    const curr = toVisit.pop() as DriverId;
    if (visited.has(curr)) {
      continue;
    }
    visited.add(curr);
    allDriverDepIds.push(curr);
    const currDriver = driversById[curr];
    if (currDriver == null) {
      continue;
    }
    toVisit.push(
      ...getDriverIdsOfDependencies({
        driver: currDriver,
        evaluator,
        dimensionalPropertyEvaluator,
      }),
    );
  }
  return allDriverDepIds.filter((id) => driversById[id]?.type === DriverType.Basic);
};
