import { IServerSideGetRowsParams } from 'ag-grid-community';
import { fromPairs, isEqual, mapValues } from 'lodash';

import { ADD_ITEM_TYPE, ADD_PROPERTY_TYPE } from 'components/AgGridComponents/config/grid';
import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import { ObjectDataSource } from 'components/AgGridComponents/helpers/gridDatasource/ObjectDataSource';
import {
  BaseRequestParams,
  BlockResponse,
  ITimeseriesDataSource,
  ObjectRowsRequest,
  ObjectRowsResponse,
  ObjectRowsUpdated,
  TimeseriesDataSourceUpdateStateParams,
} from 'components/AgGridComponents/helpers/gridDatasource/types';
import {
  TimeseriesAddItemRow,
  TimeseriesObjectGroupRow,
  TimeseriesObjectRow,
  TimeseriesPropertyRow,
} from 'components/AgGridComponents/types/TimeseriesColumnDef';
import {
  DatabaseGroupKey,
  EMPTY_ATTRIBUTE_ID,
  NONE_GROUP_INFO,
  isObjectRow,
} from 'config/businessObjects';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { DriverFormat, DriverType, ValueType } from 'generated/graphql';
import { getMonthKey, getMonthKeysForRange } from 'helpers/dates';
import { CurvePointWithEventMetadata, getCellImpactFromCurvePoints } from 'helpers/events';
import { getObjectFieldUUID } from 'helpers/object';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObjectField,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { DriverPropertyId } from 'reduxStore/models/collections';
import { AttributeId } from 'reduxStore/models/dimensions';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { MutationBatch } from 'reduxStore/models/mutations';
import { DEFAULT_DISPLAY_CONFIGURATION } from 'reduxStore/models/value';
import { isCalculationValue } from 'types/dataset';
import { MonthKey } from 'types/datetime';

type ObjectProperties = Record<BusinessObjectFieldSpecId, TimeseriesPropertyRow>;
type ObjectTree = Record<
  BusinessObjectId,
  TimeseriesObjectRow & {
    properties: ObjectProperties;
    hasAddItem: boolean;
  }
>;

type PreviousState = {
  objectTree?: ObjectTree;
  objectIdToGroupAttributeId?: Map<BusinessObjectId, AttributeId>;
  driversByIdByLayerId?: NullableRecord<LayerId, NullableRecord<string, Driver>>;
};

type AddObjectStateEvent = {
  type: 'addObject';
  rowIds: string[];
};
type UpdateObjectStateEvent = {
  type: 'updateObject';
  rowIds: string[];
};
type AddSubDriversStateEvent = {
  type: 'addSubDriver';
  driverIds: DriverId[];
};
type UpdateDriversStateEvent = {
  type: 'updateSubDriver';
  driverIds: DriverId[];
};
type RemoveObjectStateEvent = {
  type: 'removeObject';
  rowIds: string[];
};
type UpdateDateRangeEvent = {
  type: 'dateRange';
  missingMonthKeys: Set<MonthKey>;
};
type UpdateObjectSpecStateEvent = {
  type: 'updateSpec';
};
type AddPropertyStateEvent = {
  type: 'addProperty';
  propertyRowIds: Set<string>;
};
type RemovePropertyStateEvent = {
  type: 'removeProperty';
  propertyRowIds: string[];
};
type ObjectStateEvents = AddObjectStateEvent | UpdateObjectStateEvent | RemoveObjectStateEvent;
type PropertyStateEvent = AddPropertyStateEvent | RemovePropertyStateEvent;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UpdateStateEvent =
  | ObjectStateEvents
  | UpdateDateRangeEvent
  | UpdateObjectSpecStateEvent
  | PropertyStateEvent
  | AddSubDriversStateEvent
  | UpdateDriversStateEvent;

type PropertyRowsChanged = {
  valuesChanged: number;
  metasChanged: number;
};

export class TimeseriesDataSource extends ObjectDataSource implements ITimeseriesDataSource {
  // Derived from Redux.
  private eventsByEntityId?: TimeseriesDataSourceUpdateStateParams['eventsByEntityId'];
  private orderedProperties?: TimeseriesDataSourceUpdateStateParams['orderedProperties'];
  private attributesById?: TimeseriesDataSourceUpdateStateParams['attributesById'];
  private restrictedFieldIds?: TimeseriesDataSourceUpdateStateParams['restrictedFieldIds'];
  private accessCapabilities?: TimeseriesDataSourceUpdateStateParams['accessCapabilities'];
  private fieldSpecDisplayConfigurationsById?: TimeseriesDataSourceUpdateStateParams['fieldSpecDisplayConfigurationsById'];
  private driverPropertiesByDimDriverId?: TimeseriesDataSourceUpdateStateParams['driverPropertiesByDimDriverId'];
  private driverPropertiesBySubDriverId?: TimeseriesDataSourceUpdateStateParams['driverPropertiesBySubDriverId'];

  // Calculated state.
  private objectTree?: ObjectTree;
  private orderedObjectIds?: string[];
  private objectIdToGroupAttributeId: Map<BusinessObjectId, AttributeId>;
  private objectIdToGroupKey: Map<BusinessObjectId, DatabaseGroupKey>;

  // State for reacting to mutations.
  private previousUpdateStateParams?: TimeseriesDataSourceUpdateStateParams;
  private newObjectIdToRowIndex: Map<BusinessObjectId, number>;
  private objectIdsToRemove: Set<BusinessObjectId>;
  private objectIdsToUpdate: Set<BusinessObjectId>;
  private objectSpecUpdated: boolean;
  private driverIdsToUpdate: Set<DriverId>;
  private unmatchedSubDriverIds: Set<DriverId>;

  constructor(blockId: BlockId) {
    super(blockId);
    this.objectIdToGroupAttributeId = new Map();
    this.objectIdToGroupKey = new Map();
    this.newObjectIdToRowIndex = new Map();
    this.objectIdsToRemove = new Set();
    this.objectIdsToUpdate = new Set();
    this.objectSpecUpdated = false;
    this.driverIdsToUpdate = new Set();
    this.unmatchedSubDriverIds = new Set();
    DataArbiter.get().register(this);
  }

  public get instanceId(): string {
    return this.id;
  }

  public updateState(params: TimeseriesDataSourceUpdateStateParams): void {
    const prevState: PreviousState = {
      objectTree: this.objectTree,
      objectIdToGroupAttributeId: this.objectIdToGroupAttributeId,
      driversByIdByLayerId: this.driversByIdByLayerId,
    };

    if (this.shouldParamsChangeForceAgGridToRefresh(params)) {
      this.reset();
      this.emit({ type: 'refresh' });
    }

    if (this.undoneMutation != null) {
      this.updateStateForUndoneMutation(this.undoneMutation);
    }

    this.initialize(params);

    // Make event functions. These should not mutate the state of the data source.
    const addObjectsEvent = this.makeAddObjectEvent(params, this.previousUpdateStateParams);
    const removeObjectsEvent = this.makeRemoveObjectEvent(params, this.previousUpdateStateParams);
    const updateObjectEvent = this.makeUpdateObjectEvent(params, this.previousUpdateStateParams);
    const addDriverEvent = this.makeAddSubDriverEvent(params, this.previousUpdateStateParams);
    const updateDriverEvent = this.makeUpdateSubDriverEvent(params, this.previousUpdateStateParams);
    const addPropertiesEvent = this.makeAddPropertiesEvent(params, this.previousUpdateStateParams);
    const removePropertiesEvent = this.makeRemovePropertiesEvent(
      params,
      this.previousUpdateStateParams,
    );
    const updateObjectSpecEvent = this.makeUpdateObjectSpecEvent(
      params,
      this.previousUpdateStateParams,
    );
    const updateDateRangeEvent = this.makeUpdateDateRangeEvent(
      params,
      this.previousUpdateStateParams,
    );

    // Apply functions. These will mutate the state of the data source.
    if (addObjectsEvent) {
      this.applyAddObjectEvents(addObjectsEvent);
      this.applyMoveAddItemRow(prevState, [addObjectsEvent]);
    }
    if (removeObjectsEvent) {
      this.applyRemoveObjectsEvents(prevState, [removeObjectsEvent]);
      this.applyAddMissingAddItemRow(prevState, [removeObjectsEvent]);
    }
    if (updateObjectEvent) {
      this.applyUpdateObjectsEvents(prevState, [updateObjectEvent]);
    }
    if (addDriverEvent) {
      this.applyAddDriverEvents(prevState, [addDriverEvent]);
    }
    if (updateDriverEvent) {
      this.applyUpdateDriversEvents(prevState, [updateDriverEvent]);
    }
    if (updateObjectSpecEvent) {
      this.applyUpdateObjectSpecEvents(prevState, [updateObjectSpecEvent]);
    }
    if (updateDateRangeEvent) {
      this.applyUpdateDateRangeEvent(updateDateRangeEvent);
    }
    if (addPropertiesEvent) {
      this.applyAddPropertiesEvents(addPropertiesEvent);
    }
    if (removePropertiesEvent) {
      this.applyRemovePropertiesEvents(prevState, removePropertiesEvent);
    }

    this.previousUpdateStateParams = params;
  }

  private applyAddDriverEvents(prevState: PreviousState, events: AddSubDriversStateEvent[]): void {
    if (events.length === 0) {
      return;
    }

    if (!events.every((evt) => evt.type === 'addSubDriver')) {
      return;
    }

    const { driverIds } = events[0];
    for (const subDriverId of driverIds) {
      const driver = this.driversByIdByLayerId?.[this.currentLayerId]?.[subDriverId];
      if (driver == null) {
        continue;
      }

      const objectId =
        this.dimensionalPropertyEvaluator?.getBusinessObjectIdBySubDriverId(subDriverId);
      if (objectId == null) {
        continue;
      }
      const driverPropertyId = this.driverPropertiesBySubDriverId?.[subDriverId]?.id;
      if (driverPropertyId == null) {
        continue;
      }

      this.rebuildObjectTree({ objectIds: new Set([objectId]), preserveRowData: true });

      const objectRow = this.objectTree?.[objectId];
      const row = objectRow?.properties[driverPropertyId];
      if (row == null) {
        continue;
      }

      const route = this.getEventRoutes(objectId);

      this.emit({
        type: 'change',
        update: [row],
        route: route.propertyRoute,
      });
      this.requestObjectPropertyRows({}, objectId);
      this.unmatchedSubDriverIds.delete(subDriverId);
    }
  }

  private applyUpdateDateRangeEvent(event: UpdateDateRangeEvent): void {
    if (event.missingMonthKeys.size === 0) {
      return;
    }
    this.serverSideRefresh();
  }

  public destroy(): void {
    super.destroy();
    DataArbiter.get().unregister(this);
  }

  public reset(): void {
    super.reset();
    DataArbiter.get().unregister(this);
    this.id = uuidv4();
    this.objectTree = undefined;
    this.orderedObjectIds = undefined;
    this.previousUpdateStateParams = undefined;
    this.newObjectIdToRowIndex.clear();
    this.objectIdsToRemove.clear();
    this.objectIdsToUpdate.clear();
    this.driverIdsToUpdate.clear();
    DataArbiter.get().register(this);
  }

  private makeAddObjectEvent(
    next: TimeseriesDataSourceUpdateStateParams,
    _?: TimeseriesDataSourceUpdateStateParams,
  ): AddObjectStateEvent | null {
    if (this.newObjectIdToRowIndex.size === 0) {
      return null;
    }

    const objectIds: string[] = [];
    for (const objectId of this.newObjectIdToRowIndex.keys()) {
      if (next.objectsById[objectId] != null) {
        objectIds.push(objectId);
      }
    }

    if (objectIds.length === 0) {
      return null;
    }

    return {
      type: 'addObject',
      rowIds: objectIds,
    };
  }

  private makeRemoveObjectEvent(
    next: TimeseriesDataSourceUpdateStateParams,
    _?: TimeseriesDataSourceUpdateStateParams,
  ): RemoveObjectStateEvent | null {
    if (this.objectIdsToRemove.size === 0) {
      return null;
    }

    const objectIds: string[] = [];
    for (const objectId of this.objectIdsToRemove) {
      if (next?.objectsById[objectId] == null) {
        objectIds.push(objectId);
      }
    }

    return {
      type: 'removeObject',
      rowIds: objectIds,
    };
  }

  private makeUpdateObjectEvent(
    next: TimeseriesDataSourceUpdateStateParams,
    _?: TimeseriesDataSourceUpdateStateParams,
  ): UpdateObjectStateEvent | null {
    if (this.objectIdsToUpdate.size === 0) {
      return null;
    }

    const objectIds: string[] = [];
    for (const objectId of this.objectIdsToUpdate) {
      if (next?.objectsById[objectId] != null) {
        objectIds.push(objectId);
      }
    }

    return {
      type: 'updateObject',
      rowIds: objectIds,
    };
  }

  private makeUpdateSubDriverEvent(
    next: TimeseriesDataSourceUpdateStateParams,
    _?: TimeseriesDataSourceUpdateStateParams,
  ): UpdateDriversStateEvent | null {
    if (this.driverIdsToUpdate.size === 0) {
      return null;
    }

    const driverIds = [];
    for (const driverId of this.driverIdsToUpdate) {
      if (next?.driversByIdByLayerId?.[this.currentLayerId]?.[driverId] != null) {
        driverIds.push(driverId);
      }
    }

    return {
      type: 'updateSubDriver',
      driverIds,
    };
  }

  private makeAddSubDriverEvent(
    next: TimeseriesDataSourceUpdateStateParams,
    _?: TimeseriesDataSourceUpdateStateParams,
  ): AddSubDriversStateEvent | null {
    if (this.unmatchedSubDriverIds.size === 0) {
      return null;
    }

    const driverIds = [];
    for (const driverId of this.unmatchedSubDriverIds) {
      if (next?.driversByIdByLayerId?.[this.currentLayerId]?.[driverId] != null) {
        driverIds.push(driverId);
      }
    }

    return {
      type: 'addSubDriver',
      driverIds,
    };
  }

  private makeAddPropertiesEvent(
    next: TimeseriesDataSourceUpdateStateParams,
    prev?: TimeseriesDataSourceUpdateStateParams,
  ): AddPropertyStateEvent | null {
    if (prev == null) {
      return null;
    }
    const prevVisiblePropertyIds = prev?.orderedProperties.map((p) => p.objectFieldSpecId);
    const visiblePropertyIds = next.orderedProperties.map((p) => p.objectFieldSpecId);

    if (visiblePropertyIds.length > prevVisiblePropertyIds.length) {
      const propertyRowIds = visiblePropertyIds.filter(
        (propertyId) => !prevVisiblePropertyIds.includes(propertyId),
      );

      return {
        type: 'addProperty',
        propertyRowIds: new Set(propertyRowIds),
      };
    }

    return null;
  }

  private makeRemovePropertiesEvent(
    next: TimeseriesDataSourceUpdateStateParams,
    prev?: TimeseriesDataSourceUpdateStateParams,
  ): RemovePropertyStateEvent | null {
    const visiblePropertyIds = next.orderedProperties.map((p) => p.objectFieldSpecId);
    const prevVisiblePropertyIds = prev?.orderedProperties.map((p) => p.objectFieldSpecId) ?? [];

    if (visiblePropertyIds.length < prevVisiblePropertyIds.length) {
      const removedPropertyIds = prevVisiblePropertyIds.filter(
        (propertyId) =>
          next.orderedProperties.find((p) => p.objectFieldSpecId === propertyId) == null,
      );

      return {
        type: 'removeProperty',
        propertyRowIds: removedPropertyIds,
      };
    }

    return null;
  }

  private makeUpdateObjectSpecEvent(
    next: TimeseriesDataSourceUpdateStateParams,
    _?: TimeseriesDataSourceUpdateStateParams | undefined,
  ): UpdateObjectSpecStateEvent | null {
    if (next.objectSpec == null || !this.objectSpecUpdated) {
      return null;
    }

    return {
      type: 'updateSpec',
    };
  }

  private makeUpdateDateRangeEvent(
    next: TimeseriesDataSourceUpdateStateParams,
    prev?: TimeseriesDataSourceUpdateStateParams | undefined,
  ): UpdateDateRangeEvent | null {
    if (!prev || isEqual(next.dateRange, prev.dateRange)) {
      return null;
    }

    const nextMonthKeys = new Set(getMonthKeysForRange(next.dateRange[0], next.dateRange[1]));
    const prevMonthKeys = getMonthKeysForRange(prev.dateRange[0], prev.dateRange[1]);

    for (const monthKey of prevMonthKeys) {
      nextMonthKeys.delete(monthKey);
    }

    return {
      type: 'dateRange',
      missingMonthKeys: nextMonthKeys,
    };
  }

  private applyAddObjectEvents(event: AddObjectStateEvent): void {
    for (const objectId of event.rowIds) {
      const groupAttributeId = this.objectIdToGroupAttributeId.get(objectId);
      const group = this.groups?.find((g) => g.groupInfo.attributeId === groupAttributeId);
      if (group) {
        const index = group.rows.length;
        group.rows.splice(index, 0, { type: 'object', objectId });
      }
    }

    this.rebuildObjectTree({ objectIds: new Set(event.rowIds) });

    const lastObjectId = event.rowIds[event.rowIds.length - 1];
    for (const objectId of event.rowIds) {
      const objectRow = this.objectTree?.[objectId];
      if (objectRow == null) {
        continue;
      }

      // Newly added objects should have their name field focused for quick entry.
      objectRow.meta.autoFocusName =
        objectId === lastObjectId && this.accessCapabilities?.canWriteDatabase;

      // TODO: handle nested groups
      this.emit({
        type: 'change',
        add: [objectRow],
        addIndex: this.newObjectIdToRowIndex.get(objectId),
        route: [],
      });

      this.newObjectIdToRowIndex.delete(objectId);
    }
  }

  private applyAddPropertiesEvents(event: AddPropertyStateEvent): void {
    // need to rebuild the object tree to get the updated property rows
    // and their row indeces so they can be added in the correct order
    this.rebuildObjectTree();
    const objectRows = Object.values(this.objectTree ?? {});
    objectRows.forEach((objectRow) => {
      const allPropertyRows = this.getPropertyRows({ objectRow });
      for (let rowIndex = 0; rowIndex < allPropertyRows.length; rowIndex++) {
        const propertyRow = allPropertyRows[rowIndex];
        const fieldSpecId = propertyRow.fieldSpecId;
        if (fieldSpecId == null || propertyRow.type !== 'propertyRow') {
          continue;
        }
        // only add the property rows specified in the event
        if (event.propertyRowIds.has(fieldSpecId)) {
          const { propertyRoute } = this.getEventRoutes(propertyRow.objectId);
          this.emit({
            type: 'change',
            add: [propertyRow],
            addIndex: rowIndex,
            route: propertyRoute,
          });
        }
      }
    });
  }

  private applyRemovePropertiesEvents(
    prevState: PreviousState,
    event: RemovePropertyStateEvent,
  ): void {
    const previousObjectRows = Object.values(prevState.objectTree ?? {});
    previousObjectRows.forEach((prevObjectRow) => {
      const propertyRowsToRemove = Object.values(prevObjectRow.properties).filter(
        (propertyRow) =>
          propertyRow.fieldSpecId != null && event.propertyRowIds.includes(propertyRow.fieldSpecId),
      );

      for (const propertyRow of propertyRowsToRemove) {
        const { propertyRoute } = this.getEventRoutes(propertyRow.objectId);

        this.emit({
          type: 'change',
          remove: [{ id: propertyRow.id }],
          route: propertyRoute,
        });
      }
    });
  }

  private applyMoveAddItemRow(prevState: PreviousState, allEvents: ObjectStateEvents[]): void {
    if (!allEvents.every((e) => e.type === 'addObject')) {
      return;
    }

    // TODO: handle nested grouping.

    const allObjectIds = allEvents.flatMap((evt) => evt.rowIds);
    for (const objectId of allObjectIds) {
      const objectRow = this.objectTree?.[objectId];
      if (objectRow?.hasAddItem) {
        const group = this.groups?.find(
          (g) => g.groupInfo.attributeId === objectRow.groupByAttributeId,
        );
        if (!group) {
          continue;
        }

        const item = group.rows.find(
          (r) => r.objectId !== objectId && prevState.objectTree?.[r.objectId]?.hasAddItem,
        );
        const oldAddItemObjectId = item?.objectId;
        if (oldAddItemObjectId == null) {
          continue;
        }

        const rowId = `${oldAddItemObjectId}:${ADD_ITEM_TYPE}`;
        this.emit({
          type: 'change',
          remove: [{ id: rowId }],
          route: [oldAddItemObjectId],
        });
      }
    }
  }

  private applyRemoveObjectsEvents(
    prevState: PreviousState,
    removeEvents: ObjectStateEvents[],
  ): void {
    if (!removeEvents.every((e) => e.type === 'removeObject')) {
      return;
    }

    // TODO: handle nested grouping.

    const allObjectIds = removeEvents.flatMap((evt) => evt.rowIds);

    for (const objectId of allObjectIds) {
      const attributeId = this.objectIdToGroupAttributeId.get(objectId);
      const group = this.getGroupForAttribute(attributeId);
      if (!group) {
        continue;
      }

      // Remove from group.
      const index = group.rows.findIndex((r) => r.objectId === objectId);
      if (index !== -1) {
        group.rows.splice(index, 1);
      }

      // Remove from indexes.
      this.objectIdToGroupAttributeId.delete(objectId);
      this.objectIdToGroupKey.delete(objectId);
      this.objectIdsToRemove.delete(objectId);
    }

    this.rebuildObjectTree();

    for (const objectId of allObjectIds) {
      const objectRow = prevState.objectTree?.[objectId];
      if (objectRow == null) {
        continue;
      }

      this.emit({
        type: 'change',
        remove: [objectRow],
        route: [],
      });
    }
  }

  private applyAddMissingAddItemRow(
    prevState: PreviousState,
    removeEvents: ObjectStateEvents[],
  ): void {
    if (!removeEvents.every((e) => e.type === 'removeObject')) {
      return;
    }

    const allObjectIds = removeEvents.flatMap((evt) => evt.rowIds);
    for (const objectId of allObjectIds) {
      const objectRow = prevState.objectTree?.[objectId];
      if (objectRow == null || !objectRow.hasAddItem) {
        continue;
      }

      // TODO (T-21207): this will not handle the case where every row has been deleted.

      const group = this.getGroupForAttribute(objectRow.groupByAttributeId);
      if (!group) {
        continue;
      }

      const lastObjectRow = this.objectTree?.[group.rows[group.rows.length - 1].objectId];
      if (!lastObjectRow?.hasAddItem) {
        continue;
      }

      const addItemRow = this.createAddItemRow({ objectRow: lastObjectRow });

      // Insert the add item button after the object properties and after the add property button.
      // Omit `addIndex` to automatically insert at the end of the object properties group.
      this.emit({
        type: 'change',
        add: [addItemRow],
        route: [lastObjectRow.id],
      });
    }
  }

  private applyUpdateObjectsEvents(prevState: PreviousState, events: ObjectStateEvents[]): void {
    if (!events.every((e) => e.type === 'updateObject')) {
      return;
    }

    const allObjectIds = events.flatMap((evt) => evt.rowIds);
    for (const objectId of allObjectIds) {
      const prevObjectRow = prevState.objectTree?.[objectId];
      if (prevObjectRow == null) {
        continue;
      }

      // TODO: handle nested groupings.

      const objectRow = this.objectTree?.[objectId];
      if (objectRow == null) {
        continue;
      }

      this.emit({
        type: 'change',
        update: [objectRow],
        route: [],
      });
    }
  }

  private applyUpdateDriversEvents(
    prevState: PreviousState,
    events: UpdateDriversStateEvent[],
  ): void {
    const { driverPropertiesByDimDriverId } = this;
    if (driverPropertiesByDimDriverId == null) {
      return;
    }
    if (!events.every((e) => e.type === 'updateSubDriver')) {
      return;
    }

    const driverPropertyIds: DriverPropertyId[] = [];
    const updatedDriverIds = events.flatMap((event) => event.driverIds).filter(isNotNull);
    for (const driverId of updatedDriverIds) {
      const prevDriver = prevState.driversByIdByLayerId?.[this.currentLayerId]?.[driverId];
      const driver = this.driversByIdByLayerId?.[this.currentLayerId]?.[driverId];
      const driverProperty = this.driverPropertiesByDimDriverId?.[driverId];

      if (
        prevDriver === driver ||
        driver == null ||
        driverProperty == null ||
        // TODO: filtering out changes to all basic drivers is probably not the right long term approach.
        driver.type === DriverType.Basic
      ) {
        continue;
      }

      driverPropertyIds.push(driverProperty.id);
    }

    if (driverPropertyIds.length === 0) {
      return;
    }

    this.rebuildObjectTree({ preserveRowData: true });

    const { objectTree } = this;
    if (objectTree == null) {
      return;
    }
    const propertyRows = driverPropertyIds.flatMap((driverPropertyId) =>
      Object.values(objectTree)
        .map(({ properties }) => properties[driverPropertyId])
        .filter(isNotNull),
    );

    for (const propertyRow of propertyRows) {
      const { propertyRoute } = this.getEventRoutes(propertyRow.objectId);
      this.emit({
        type: 'change',
        update: [propertyRow],
        route: propertyRoute,
        forceRefreshRowIds: [propertyRow.id],
      });
    }
  }

  private applyUpdateObjectSpecEvents(_: PreviousState, __: UpdateObjectSpecStateEvent[]) {
    // Rebuild entire object tree.
    this.rebuildObjectTree();
    // Refetch all rows.
    this.serverSideRefresh();
  }

  // Server-side refresh instructs AG Grid to dump its internal cache and refetch the routes specified.
  // This is very powerful but also dangerous; use with caution.
  private serverSideRefresh(purge = false) {
    if (this.groups == null || this.orderedObjectIds == null || this.blockConfig == null) {
      return;
    }
    if (this.blockConfig.groupBy?.objectField == null) {
      for (const objectId of this.orderedObjectIds) {
        this.emit({ type: 'serverSideRefresh', route: [objectId], purge });
      }
      return;
    }

    for (const group of this.groups) {
      const {
        rows,
        groupInfo: { attributeId },
      } = group;
      for (const { objectId } of rows) {
        this.emit({ type: 'serverSideRefresh', route: [attributeId, objectId], purge });
      }
    }
  }

  /**
   * Do not mutate the core state here.
   */
  protected addObjectsMutationHook(objectIds: string[], groupIndex: number): void {
    if (this.groups == null || this.groups.length === 0) {
      return;
    }

    const group = safeObjGet(this.groups[groupIndex]);
    if (!group) {
      return;
    }

    const addIndex = group.rows.length;
    objectIds.forEach((id, idx) => {
      this.objectIdToGroupAttributeId.set(id, group.groupInfo.attributeId);
      this.objectIdToGroupKey.set(id, group.groupInfo.key);
      this.newObjectIdToRowIndex.set(id, idx + addIndex);
    });
  }

  protected removeObjectsMutationHook(objectIds: string[]): void {
    objectIds.forEach((objectId) => this.objectIdsToRemove.add(objectId));
  }

  protected updateObjectsMutationHook(objectIds: string[]): void {
    objectIds.forEach((objectId) => this.objectIdsToUpdate.add(objectId));
  }

  protected updateSubDriversMutationHook(driverIds: DriverId[]): void {
    driverIds.forEach((id) => this.driverIdsToUpdate.add(id));
  }

  protected addSubDriversMutationHook(subDriverIds: string[]): void {
    subDriverIds.forEach((id) => this.unmatchedSubDriverIds.add(id));
  }

  protected updateObjectSpecMutationHook(): void {
    this.objectSpecUpdated = true;
  }

  protected updateStateForUndoneMutation(undoneMutation: MutationBatch) {
    const { newBusinessObjects, deleteBusinessObjects } = undoneMutation.mutation;
    // If we are undoing creating/deleting objects, refresh the grid to
    // show the correct rows. We should make this more efficient by only
    // refreshing the rows that were affected, by triggering the inverse
    // updates needed for the undone mutation. For example, when undoing a
    // create object, we should delete the row that was created by firing
    // removeObjectsMutationHook([objectId]).
    if (
      (newBusinessObjects != null && newBusinessObjects.length > 0) ||
      (deleteBusinessObjects != null && deleteBusinessObjects.length > 0)
    ) {
      this.reset();
      this.emit({ type: 'refresh' });
    }
    this.undoneMutation = undefined;
  }

  public handleObjectRowsResponse(res: ObjectRowsResponse): void {
    const pending = res.requestId != null ? this.pendingRequests.get(res.requestId) : null;

    if (!pending || pending.type !== 'property') {
      // Try rows updated
      this.handleObjectRowsUpdated(res);
      return;
    }

    const { objectId } = pending;
    const { driversByIdByLayerId, objectTree } = this;
    if (driversByIdByLayerId == null || objectTree == null) {
      return;
    }

    const object = safeObjGet(objectTree[objectId]);
    if (!object) {
      return;
    }

    const fieldIds = new Set(this.extractMonthsByFieldId(res.values).keys());
    const propertyRows = Object.values(object.properties).filter((prop) =>
      fieldIds.has(prop.fieldId ?? prop.driverId ?? ''),
    );
    if (propertyRows.length === 0) {
      return;
    }

    const { valuesChanged, metasChanged } = this.copyValuesToPropertyRows(propertyRows);
    if (valuesChanged === 0 && metasChanged === 0) {
      return;
    }

    const { propertyRoute, objectRoute } = this.getEventRoutes(objectId);

    // TODO: handle force refresh on rows that only had metas change.

    // The properties and objects live on separate rows.
    this.emit({ type: 'change', update: propertyRows, route: propertyRoute });
    this.emit({ type: 'change', update: [object], route: objectRoute });
  }

  private copyValuesToPropertyRows(propertyRows: TimeseriesPropertyRow[]): PropertyRowsChanged {
    const { objectsById, driversByIdByLayerId, eventsByEntityId, dateRange, currentLayerId } = this;
    if (
      objectsById == null ||
      driversByIdByLayerId == null ||
      eventsByEntityId == null ||
      dateRange == null ||
      currentLayerId == null
    ) {
      return { valuesChanged: 0, metasChanged: 0 };
    }

    const monthKeys = getMonthKeysForRange(dateRange[0], dateRange[1]);
    const monthKeysSet = new Set(monthKeys);
    const arbiter = DataArbiter.get();

    let valuesChanged = 0;
    let metasChanged = 0;

    for (const row of propertyRows) {
      const id = row.fieldId ?? row.driverId;
      if (id == null) {
        continue;
      }

      const events = eventsByEntityId[id] ?? [];
      const curvePointsByMonthKey: NullableRecord<MonthKey, CurvePointWithEventMetadata[]> = {};
      events.forEach(({ id: eventId, parentId: eventGroupId, customCurvePoints, impactType }) =>
        Object.entries(customCurvePoints ?? {}).forEach(([mk, value]) => {
          if (!monthKeysSet.has(mk)) {
            return;
          }

          const newCurvePoint: CurvePointWithEventMetadata = {
            curvePoint: { ...value, impactType },
            eventId,
            eventGroupId: eventGroupId ?? null,
          };

          if (curvePointsByMonthKey[mk] == null) {
            curvePointsByMonthKey[mk] = [];
          }
          curvePointsByMonthKey[mk]?.push(newCurvePoint);
        }),
      );
      const cellImpactsByMonthKey = mapValues(
        curvePointsByMonthKey,
        (curvePoints) => getCellImpactFromCurvePoints(curvePoints ?? []) ?? undefined,
      );
      if (!isEqual(row.cellImpactsByMonthKey, cellImpactsByMonthKey)) {
        row.cellImpactsByMonthKey = cellImpactsByMonthKey;
        ++metasChanged;
      }

      const driver =
        row.driverId != null ? driversByIdByLayerId?.[this.currentLayerId]?.[row.driverId] : null;
      const field = objectsById[row.objectId]?.fields.find((f) => f.id === row.fieldId);

      for (const monthKey of monthKeys) {
        // TODO (T-21313): handle extObject hardcoded actuals.
        // See: DatabaseDataSource.ts:1176

        if (field) {
          const value = field.value?.actuals.timeSeries?.[monthKey];
          if (value) {
            row.data[monthKey] = value.value;
            row.hardCodedActuals.add(monthKey);
            ++valuesChanged;
            ++metasChanged;
            continue;
          }
        }

        if (driver) {
          const value =
            driver.type === DriverType.Basic ? driver.actuals.timeSeries?.[monthKey] : null;
          if (value != null) {
            row.data[monthKey] = value;
            row.hardCodedActuals.add(monthKey);
            ++valuesChanged;
            ++metasChanged;
            continue;
          }
        }

        // If a hardcoded actual was not found, ensure that the row is not underlined.
        row.hardCodedActuals.delete(monthKey);

        const value = arbiter.getCachedValue({ id, monthKey, layerId: currentLayerId });
        const oldValue = row.data[monthKey];
        const newValue = isCalculationValue(value) ? value.value : value;

        if (!isEqual(oldValue, newValue)) {
          row.data[monthKey] = newValue;
          ++valuesChanged;
        }
      }
    }

    return { valuesChanged, metasChanged };
  }

  /**
   * This used to be a public function required by the IDataSource interface.
   * Surface area has been reduced due to a change in dataflow from DataArbiter.
   * TODO: folder this directly into handleObjectRowsResponse.
   */
  private handleObjectRowsUpdated(res: ObjectRowsUpdated): void {
    const { objectTree } = this;

    if (objectTree == null) {
      return;
    }

    const monthsByFieldId = this.extractMonthsByFieldId(res.values);
    const rowsToUpdate = this.extractRowsToUpdate(new Set(monthsByFieldId.keys()));

    const { valuesChanged, metasChanged } = this.copyValuesToPropertyRows(rowsToUpdate);

    if (valuesChanged === 0 && metasChanged === 0) {
      return;
    }

    const rowsByRoute = rowsToUpdate.map((propertyRow) => {
      const groupByAttributeId = propertyRow.groupByAttributeId ?? EMPTY_ATTRIBUTE_ID;
      const propertyRoute =
        groupByAttributeId === EMPTY_ATTRIBUTE_ID
          ? [propertyRow.objectId]
          : [groupByAttributeId, propertyRow.objectId];
      return [propertyRoute, propertyRow] as [string[], TimeseriesPropertyRow];
    });

    for (const [route, row] of rowsByRoute) {
      this.emit({
        type: 'change',
        update: [row],
        forceRefreshRowIds: metasChanged > 0 ? [row.id] : undefined,
        route,
      });
    }
  }

  private extractRowsToUpdate(propertyIds: Set<string>): TimeseriesPropertyRow[] {
    const { objectTree } = this;
    if (objectTree == null) {
      return [];
    }

    // TODO: consider extracting this to be an indexed field on TimeseriesDataSource.
    const propertyRowsByPropertyId = Object.values(objectTree)
      .map((objectRow) => objectRow.properties)
      .reduce<NullableRecord<BusinessObjectFieldId | DriverId, TimeseriesPropertyRow>>(
        (allProperties, properties) => {
          for (const property of Object.values(properties)) {
            const id = property.fieldId ?? property.driverId;
            if (id == null) {
              continue;
            }
            allProperties[id] = property;
          }
          return allProperties;
        },
        {},
      );

    const out: TimeseriesPropertyRow[] = [];
    for (const id of propertyIds) {
      const row = propertyRowsByPropertyId[id];
      if (row == null) {
        continue;
      }
      out.push(row);
    }

    return out;
  }

  public handleBlockResponse(res: BlockResponse): void {
    super.handleBlockResponseHelper(res);

    if (this.groups == null || this.groups.length === 0) {
      return;
    }

    this.objectIdToGroupAttributeId.clear();
    this.objectIdToGroupKey.clear();

    for (const group of this.groups) {
      const {
        rows,
        groupInfo: { attributeId, key },
      } = group;
      for (const row of rows) {
        this.objectIdToGroupAttributeId.set(row.objectId, attributeId);
        this.objectIdToGroupKey.set(row.objectId, key);
      }
    }

    const pending = this.pendingRequests.get(res.requestId);
    if (pending == null || pending.type !== 'block') {
      return;
    }

    const { startRow, endRow } = pending;
    if (startRow == null || endRow == null) {
      throw new Error(`Expected startRow and endRow to be defined`);
    }

    if (pending.success) {
      this.rebuildObjectTree();

      const { objectTree } = this;
      if (objectTree == null) {
        throw new Error('Expected objectTree to be defined');
      }

      if ('groupByAttributeId' in pending && pending.groupByAttributeId != null) {
        const rows = this.getObjectGroupRows();
        pending.success({
          rowData: rows,
          rowCount: rows.length,
        });
        return;
      }

      const rows = this.groups[0].rows
        .slice(startRow, endRow)
        .filter(isObjectRow)
        .filter((r) => r.comparisonType == null && r.layerId == null)
        .filter((r) => r.objectId in objectTree);
      const groupRows: TimeseriesObjectRow[] = rows.map(({ objectId }) => objectTree[objectId]);
      pending.success({
        rowData: groupRows,
        rowCount: groupRows.length,
      });
      this.emit({
        type: 'rowCount',
        rowCount: groupRows.length * (this.orderedProperties?.length ?? 1),
      });
    }
  }

  public handleMutation(mutation: MutationBatch): void {
    this.handleMutationCommon(mutation);
  }

  public handleUndoneMutation(undoneMutation: MutationBatch): void {
    this.handleUndoneMutationCommon(undoneMutation);
  }

  public getRows(params: IServerSideGetRowsParams<any, any>): void {
    if (this.isDestroyed) {
      params.fail();
      return;
    }

    const { startRow, endRow, rowGroupCols, groupKeys } = params.request;
    if (groupKeys.length > 0) {
      // TODO: handle pagination within a group.
      const [attributeIdOrObjectId, maybeObjectId] = groupKeys;
      const groupingsApplied = rowGroupCols.length;

      let attributeId: string | null = null;
      let objectId: string | null = null;
      if (groupingsApplied === 2) {
        attributeId = attributeIdOrObjectId;
        objectId = maybeObjectId;
      } else if (groupingsApplied === 1) {
        objectId = attributeIdOrObjectId;
      } else {
        throw new Error(`Expected 1 or 2 groups to be applied but got ${groupingsApplied}`);
      }

      if (objectId != null) {
        const objectRow = this.objectTree?.[objectId];
        if (objectRow == null) {
          return;
        }

        const propertyRows = this.getPropertyRows({ objectRow });
        params.success({
          rowData: propertyRows,
          rowCount: propertyRows.length,
        });

        this.requestObjectPropertyRows(
          {
            startRow,
            endRow,
            success: params.success,
            fail: params.fail,
          },
          objectId,
        );
        return;
      }

      const group = this.groups?.find((g) => g.groupInfo.attributeId === attributeId);
      if (group == null) {
        params.fail();
        return;
      }

      const objectRows = group.rows
        .map((row) => {
          if (row.type === 'object') {
            return this.objectTree?.[row.objectId];
          }
          return undefined;
        })
        .filter(isNotNull);

      params.success({
        rowData: objectRows,
        rowCount: objectRows.length,
      });
      return;
    }

    if (rowGroupCols.length > 0) {
      const { objectTree, orderedObjectIds } = this;
      if (objectTree == null) {
        this.requestBlockHelper({
          startRow,
          endRow,
          success: params.success,
          fail: params.fail,
          // Applying group by is technically applying two group by operations.
          groupByAttributeId: rowGroupCols[1]?.id,
        });
        return;
      }

      if (orderedObjectIds == null) {
        params.fail();
        return;
      }

      const rowData = orderedObjectIds
        .slice(params.request.startRow, params.request.endRow)
        .map((id) => objectTree[id]);

      params.success({
        rowData,
        rowCount: orderedObjectIds.length,
      });

      return;
    }

    throw new Error('malformed request');
    params.fail();
  }

  protected initialize(params: TimeseriesDataSourceUpdateStateParams) {
    super.initialize(params);
    this.eventsByEntityId = params.eventsByEntityId;
    this.dateRange = params.dateRange;
    this.viewAtMonthKey = params.viewAtMonthKey;
    this.orderedProperties = params.orderedProperties;
    this.attributesById = params.attributesById;
    this.calculationEngine = params.calculationEngine;
    this.restrictedFieldIds = params.restrictedFieldIds;
    this.accessCapabilities = params.accessCapabilities;
    this.fieldSpecDisplayConfigurationsById = params.fieldSpecDisplayConfigurationsById;
    this.driverPropertiesByDimDriverId = params.driverPropertiesByDimDriverId;
    this.driverPropertiesBySubDriverId = params.driverPropertiesBySubDriverId;
  }

  private rebuildObjectTree({
    objectIds,
    preserveRowData,
  }: {
    objectIds?: Set<BusinessObjectId>;
    preserveRowData?: boolean;
  } = {}) {
    const {
      attributesById,
      objectSpec,
      objectsById,
      orderedProperties,
      dimensionalPropertyEvaluator,
      dateRange,
      driversByIdByLayerId,
      fieldSpecDisplayConfigurationsById,
    } = this;

    if (
      objectSpec == null ||
      objectsById == null ||
      orderedProperties == null ||
      attributesById == null ||
      dimensionalPropertyEvaluator == null ||
      dateRange == null ||
      driversByIdByLayerId == null
    ) {
      return;
    }
    const canWriteDatabase = this.accessCapabilities?.canWriteDatabase ?? false;

    const firstMonthKey = getMonthKey(dateRange[0]);
    const fieldSpecsById = fromPairs(objectSpec.fields.map((field) => [field.id, field]));
    const driverPropertiesById = fromPairs(
      objectSpec.collection?.driverProperties.map((prop) => [prop.id, prop]),
    );
    const dimensionalPropertiesById = fromPairs(
      objectSpec.collection?.dimensionalProperties.map((prop) => [prop.id, prop]),
    );

    const rows = this.groups?.flatMap((group) => group.rows) ?? [];
    const objects = rows
      .filter(isObjectRow)
      .map((r) => objectsById[r.objectId])
      .filter(isNotNull);

    const prevObjectTree = this.objectTree;
    this.objectTree = {};
    this.orderedObjectIds = [];
    for (const object of objects) {
      // Do not skip this step.
      this.orderedObjectIds.push(object.id);

      // Skip rebuilding objects that are not relevant when specified.
      if (objectIds != null && !objectIds.has(object.id)) {
        if (prevObjectTree?.[object.id] != null) {
          this.objectTree[object.id] = prevObjectTree[object.id];
        }
        continue;
      }

      const properties: ObjectProperties = {};
      for (const { objectFieldSpecId: fieldSpecId } of orderedProperties) {
        let valueType: ValueType = ValueType.Number;

        let driverPropertyId: string | undefined;
        let dimDriver: Driver | undefined;
        let driverId: DriverId | undefined;
        let driver: Driver | undefined;

        const driverProperty = safeObjGet(driverPropertiesById[fieldSpecId]);
        if (driverProperty != null) {
          driverPropertyId = driverProperty.id;
          dimDriver = safeObjGet(
            driversByIdByLayerId?.[this.currentLayerId]?.[driverProperty.driverId],
          );
          const attributes =
            dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(object.id);
          driverId = dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
            driverProperty.driverId,
            attributes.map((attr) => attr.attribute.id),
          );

          if (driverId != null) {
            driver = safeObjGet(driversByIdByLayerId?.[this.currentLayerId]?.[driverId]);
            valueType = driver?.valueType ?? ValueType.Number;
          } else {
            // This might cause issues with writing to a hard-coded actuals cell.
            driverId = uuidv4();
            valueType = ValueType.Number;
          }
        }

        let dimensionId: string | undefined;
        let attributeId: string | undefined;
        const dimensionalProperty = safeObjGet(dimensionalPropertiesById[fieldSpecId]);
        if (dimensionalProperty != null) {
          dimensionId = dimensionalProperty.dimension.id;
          const attributeProperty = dimensionalPropertyEvaluator.getAttributeProperty({
            dimensionalPropertyId: fieldSpecId,
            objectId: object.id,
          });
          attributeId = attributeProperty?.attribute.id;
          valueType = ValueType.Attribute;
        }

        let fieldId: string | undefined;
        let field: BusinessObjectField | undefined;
        const fieldSpec = safeObjGet(fieldSpecsById[fieldSpecId]);
        if (fieldSpec != null) {
          field = object.fields.find((f) => f.fieldSpecId === fieldSpecId);
          fieldId = field?.id;
          valueType = fieldSpec.type;
          if (field?.value?.type === ValueType.Attribute) {
            dimensionId = fieldSpecsById[fieldSpecId].dimensionId;
          }
        }

        // When the field could exist, but has not been created.
        // This happens when a field has not been added to a driver;
        // typing into the cell will create the field.
        if (fieldId == null && driverProperty == null && dimensionalProperty == null) {
          fieldId = getObjectFieldUUID(object.id, fieldSpecId);
        }

        // Use the dimensional driver to resolve the display configuration.
        // Resolving every subdriver is too slow to use at scale.
        const defaultDisplayConfiguration =
          driverProperty?.driverId != null
            ? this.getDriverDisplayConfiguration(driverProperty?.driverId)
            : DEFAULT_DISPLAY_CONFIGURATION;
        const fieldSpecDisplayConfiguration = fieldSpecDisplayConfigurationsById?.[fieldSpecId];

        const deferToSubdriverFormat =
          fieldSpecDisplayConfiguration?.format === DriverFormat.Auto &&
          defaultDisplayConfiguration != null;

        const preferredDisplayConfiguration = deferToSubdriverFormat
          ? defaultDisplayConfiguration
          : fieldSpecDisplayConfiguration;

        const prevRow = prevObjectTree?.[object.id]?.properties[fieldSpecId];
        const prevData = prevRow?.data;
        const isRestricted = Boolean(this.restrictedFieldIds?.includes(fieldSpecId));
        const isEditable = isRestricted ? false : canWriteDatabase;

        const rowKey =
          driverProperty?.driverId != null
            ? {
                driverId: driverProperty?.driverId,
                subDriverId: driverId,
                layerId: undefined,
                groupId: undefined,
              }
            : {
                objectId: object.id,
                fieldSpecId,
                layerId: undefined,
              };

        properties[fieldSpecId] = {
          id: `${object.id}:${fieldSpecId}`,
          rowKey,
          objectId: object.id,
          objectSpecId: objectSpec.id,
          groupByAttributeId: this.objectIdToGroupAttributeId.get(object.id) ?? EMPTY_ATTRIBUTE_ID,
          valueType,
          displayConfiguration: preferredDisplayConfiguration,
          fieldId,
          fieldSpecId,
          driverId,
          driverPropertyId,
          dimensionId,
          attributeId,
          dimensionalPropertyId: dimensionalProperty?.id,
          cellImpactsByMonthKey: {},
          hardCodedActuals: new Set(),
          actualsFormula: driver?.type === DriverType.Basic ? driver.actuals.formula : undefined,
          formula: driver?.type === DriverType.Basic ? driver.forecast.formula : undefined,
          data:
            preserveRowData && prevData != null
              ? prevData
              : {
                  property:
                    fieldSpecsById[fieldSpecId]?.name ??
                    dimDriver?.name ??
                    dimensionalProperty?.name ??
                    dimensionalProperty?.dimension.name,
                  initialValue: field?.value?.initialValue,
                },
          meta: {
            isRestricted,
            isMapped: dimensionalProperty?.mapping != null,
            isIntegrationObject: object.extKey != null,
            // TODO: driver properties can not yet be created from an integration.
            isIntegrationProperty:
              fieldSpec?.extFieldSpecKey != null || dimensionalProperty?.extFieldSpecKey != null,
            isStartDateField: objectSpec.startFieldId === fieldSpecId,
            isEditable,
            isDimensionalProperty: dimensionalProperty?.id != null,
          },
          type: 'propertyRow',
        };

        // Dimensional properties will always have a static value.
        if (dimensionalProperty?.id != null && attributeId != null) {
          properties[fieldSpecId].data[firstMonthKey] = attributeId;
        }

        if (canWriteDatabase) {
          properties.addProperty = {
            id: `${object.id}:${ADD_PROPERTY_TYPE}`,
            objectId: object.id,
            objectSpecId: objectSpec.id,
            groupByAttributeId:
              this.objectIdToGroupAttributeId.get(object.id) ?? EMPTY_ATTRIBUTE_ID,
            valueType: ADD_PROPERTY_TYPE,
            data: {},
            displayConfiguration: undefined,
            rowKey: {
              objectId: object.id,
              fieldSpecId: undefined,
              layerId: undefined,
            },
            meta: {
              isRestricted: false,
              isMapped: false,
              isIntegrationObject: false,
              isIntegrationProperty: false,
              isStartDateField: false,
              isDimensionalProperty: false,
              isEditable: true,
              isLastObjectRow: this.isLastObjectInGroup(object.id),
            },
            type: 'propertyRow',
            cellImpactsByMonthKey: {},
            hardCodedActuals: new Set(),
            actualsFormula: undefined,
            formula: undefined,
          };
        }
      }

      this.objectTree[object.id] = {
        id: object.id,
        objectId: object.id,
        objectSpecId: object.specId,
        groupByAttributeId: this.objectIdToGroupAttributeId.get(object.id) ?? EMPTY_ATTRIBUTE_ID,
        name: object.name,
        properties,
        hasAddItem: this.isLastObjectInGroup(object.id) && canWriteDatabase,
        rowKey: {
          objectId: object.id,
          groupKey: this.objectIdToGroupKey.get(object.id) ?? EMPTY_ATTRIBUTE_ID,
        },
        meta: {
          isNameRestricted: Boolean(this.restrictedFieldIds?.includes(NAME_COLUMN_TYPE)),
        },
        type: 'objectRow',
      };
    }
  }

  private isLastObjectInGroup(objectId: BusinessObjectId): boolean {
    if (this.groups == null) {
      return false;
    }

    for (const group of this.groups) {
      const index = group.rows.findIndex((r) => r.type === 'object' && r.objectId === objectId);
      if (index === -1) {
        continue;
      }

      return index === group.rows.length - 1;
    }

    return false;
  }

  private requestObjectPropertyRows(params: BaseRequestParams, objectId: string): void {
    const { dateRange, objectTree, objectSpec } = this;
    if (objectTree == null || dateRange == null || objectSpec == null) {
      return;
    }

    const objectRow = objectTree[objectId];
    if (objectRow == null) {
      return;
    }

    const objects: ObjectRowsRequest['objects'] = [];
    const properties: Array<{ driverId?: string; fieldId?: string }> = Object.values(
      objectRow.properties,
    );
    if (objectSpec.startFieldId != null) {
      properties.push({
        fieldId: getObjectFieldUUID(objectId, objectSpec.startFieldId),
      });
    }
    objects.push({
      driverIds: properties.map(({ driverId }) => driverId).filter(isNotNull),
      fieldIds: properties.map(({ fieldId }) => fieldId).filter(isNotNull),
      transitionDriverIds: [],
      transitionFieldIds: [],
    });

    super.requestObjectsHelper({ ...params, type: 'property', objectId }, objects);
  }

  private getObjectGroupRows(): TimeseriesObjectGroupRow[] {
    const { attributesById, objectSpec } = this;
    if (objectSpec == null || attributesById == null) {
      return [];
    }

    return (
      this.groups
        ?.map((group) => {
          const { groupInfo } = group;
          if (groupInfo.groupingType === 'attributeObjectField') {
            const { attributeId } = groupInfo;
            return {
              id: attributeId,
              name:
                attributeId === NONE_GROUP_INFO.key
                  ? NONE_GROUP_INFO.key
                  : String(attributesById[attributeId].value),
              groupByAttributeId: attributeId,
              objectId: EMPTY_ATTRIBUTE_ID,
              type: 'groupRow' as const,
            };
          }
          return undefined;
        })
        .filter(isNotNull) ?? []
    );
  }

  private createAddItemRow({
    objectRow,
  }: {
    objectRow: TimeseriesObjectRow;
  }): TimeseriesAddItemRow {
    return {
      id: `${objectRow.id}:${ADD_ITEM_TYPE}`,
      objectId: objectRow.id,
      objectSpecId: objectRow.objectSpecId,
      groupByAttributeId: this.objectIdToGroupAttributeId.get(objectRow.id) ?? EMPTY_ATTRIBUTE_ID,
      valueType: ADD_ITEM_TYPE,
      data: {},
      displayConfiguration: undefined,
      rowKey: {
        objectId: objectRow.id,
        fieldSpecId: '',
        layerId: '',
      },
      meta: {
        isRestricted: false,
        isMapped: false,
        isIntegrationObject: false,
        isIntegrationProperty: false,
        isStartDateField: false,
        isDimensionalProperty: false,
        isEditable: true,
      },
      type: 'addItemRow',
      cellImpactsByMonthKey: {},
      hardCodedActuals: new Set(),
      actualsFormula: undefined,
      formula: undefined,
    };
  }

  private getPropertyRows({
    objectRow,
  }: {
    objectRow: ObjectTree[string];
  }): Array<TimeseriesPropertyRow | TimeseriesAddItemRow> {
    // 'add item' rows may be included within the list of property rows,
    // even though they are not part of the object's properties
    if (this.orderedProperties == null) {
      return [];
    }
    const properties: TimeseriesPropertyRow[] = [];
    for (const { objectFieldSpecId: fieldSpecId } of this.orderedProperties) {
      const property = safeObjGet(objectRow.properties[fieldSpecId]);
      if (property == null) {
        console.warn(`Missing expected property ${fieldSpecId} on object ${objectRow.id}`);
        continue;
      }
      properties.push(property);
    }

    const propertyRows =
      'addProperty' in objectRow.properties
        ? [...properties, objectRow.properties.addProperty]
        : properties;

    // Silly has to ensure the add item row is inserted.
    if (objectRow.hasAddItem) {
      return [...propertyRows, this.createAddItemRow({ objectRow })];
    }

    return propertyRows;
  }

  private getEventRoutes(objectId: string): {
    propertyRoute: string[];
    objectRoute: string[] | undefined;
  } {
    const groupByAttributeId = this.objectIdToGroupAttributeId.get(objectId) ?? EMPTY_ATTRIBUTE_ID;
    const propertyRoute =
      groupByAttributeId === EMPTY_ATTRIBUTE_ID ? [objectId] : [groupByAttributeId, objectId];
    const objectRoute =
      groupByAttributeId === EMPTY_ATTRIBUTE_ID ? undefined : [groupByAttributeId];

    return { propertyRoute, objectRoute };
  }
}
