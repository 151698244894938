import { BusinessObject } from 'generated/graphql';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { ParameterizedFormulaTerm } from 'reduxStore/actions/formulaCopyPaste';
import { isGqlBusinessObject } from 'reduxStore/models/businessObjects';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';

export type MultiFormulaCopyData = {
  type: 'formula';
  data: FormulaCopyData[][];
};

export type FormulaCopyData = {
  type: 'actuals' | 'forecast';
  sourceEntityId: FormulaEntityTypedId;
  sourceAttributes: Record<DimensionId, AttributeId>;
  formula: string;
  parameterizedTerms: ParameterizedFormulaTerm[];
};

export type CopyPasteEntity =
  | {
      type: 'object';
      data: BusinessObject;
    }
  | MultiFormulaCopyData;

function isFormulaCopyData(o: unknown): o is FormulaCopyData {
  return (
    typeof o === 'object' &&
    o != null &&
    'type' in o &&
    'sourceEntityId' in o &&
    'sourceAttributes' in o &&
    'formula' in o &&
    'parameterizedTerms' in o
  );
}

function isMultiFormulaCopyData(o: unknown): o is MultiFormulaCopyData {
  return (
    typeof o === 'object' &&
    o != null &&
    'type' in o &&
    'data' in o &&
    Array.isArray(o.data) &&
    o.data.every((row) => Array.isArray(row) && row.every(isFormulaCopyData))
  );
}

function isCopyPasteEntity(o: unknown): o is CopyPasteEntity {
  return (
    typeof o === 'object' &&
    o != null &&
    'type' in o &&
    'data' in o &&
    (isMultiFormulaCopyData(o) || isGqlBusinessObject(o.data))
  );
}

export function writeToClipboard(text: string): Promise<void> {
  return window.navigator.clipboard.writeText(text).catch((err) => {
    // N.B. If the window is not in focus, writing to clipboard errors so we try
    // again until the window is in focus.
    if (err instanceof DOMException && err.name === 'NotAllowedError') {
      return new Promise((resolve) => {
        setTimeout(resolve, 1000);
      }).then(() => writeToClipboard(text));
    } else {
      throw err;
    }
  });
}

export function parseClipboardJSON(
  json: string | null | undefined,
): CopyPasteEntity | string | undefined {
  if (json == null) {
    return undefined;
  }

  try {
    const obj = JSON.parse(json) as unknown;
    if (obj != null && isCopyPasteEntity(obj)) {
      return obj;
    }
    // If its not a valid object, try to paste as a string
    // An example of this is "100"
    return json;
  } catch (e) {
    // If its not an object, try to paste as a string
    // An example of this is "Enterprise" (attribute value)
    return json;
  }
}

export function parseSingleFormulaFromClipboardJSON(
  json: string | null | undefined,
): FormulaCopyData | string | undefined {
  if (json == null) {
    return undefined;
  }

  // if we don't have a valid parsed object, return the original string
  // see examples in parseClipboardJSON
  try {
    const parsedValue = JSON.parse(json) as unknown;
    if (isFormulaCopyData(parsedValue)) {
      return parsedValue;
    }
    if (isMultiFormulaCopyData(parsedValue)) {
      return parsedValue.data[0]?.[0];
    }
    return json;
  } catch (e) {
    return json;
  }
}
