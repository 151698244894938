const borders = {
  none: '0',
  px: '1px solid',
};

const borderWidths = {
  px: '1px',
};

export default { borderWidths, borders };
