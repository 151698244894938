import { groupBy, mapValues } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { getEventAndParentIdsByMonthKey } from 'components/SelectionInspectorContents/helpers';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverId } from 'reduxStore/models/drivers';
import { Event, EventGroupId } from 'reduxStore/models/events';
import { LiveEditType } from 'reduxStore/reducers/liveEditSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { calculationEngineSelector } from 'selectors/calculationEngineSelector';
import { fieldSelector } from 'selectors/constSelectors';
import { isDetailPaneOpenSelector } from 'selectors/detailPaneSelectors';
import { eventsForDriverSelector } from 'selectors/eventsAndGroupsSelector';
import { enableBackendOnlyLiveEdit } from 'selectors/featureFlagsSelector';
import { liveEditTypeSelector } from 'selectors/liveEditSelector';
import { blockMonthKeysSelector } from 'selectors/pageDateRangeSelector';
import { hasSelectedEventsSelector } from 'selectors/selectedEventSelector';
import { pageSelectionSelector } from 'selectors/selectionSelector';
import { MonthKey } from 'types/datetime';
import { ParametricSelector, Selector } from 'types/redux';

const isSingleImpactSelectedSelector = createSelector(
  pageSelectionSelector,
  (pageSelection) =>
    pageSelection?.type === 'eventsAndGroups' &&
    pageSelection.refs.length === 1 &&
    (pageSelection.refs[0].type === 'event' || pageSelection.refs[0].type === 'entity'),
);

export const showInspectorSelector: Selector<boolean> = createSelector(
  isSingleImpactSelectedSelector,
  isDetailPaneOpenSelector,
  accessCapabilitiesSelector,
  (isSingleImpactSelected, isDetailPaneOpen, { isOrgMember }) => {
    return isSingleImpactSelected && !isDetailPaneOpen && isOrgMember;
  },
);

export const shouldDoSynchronousCalculationsSelector: Selector<boolean> = createSelector(
  showInspectorSelector,
  liveEditTypeSelector,
  hasSelectedEventsSelector,
  calculationEngineSelector,
  enableBackendOnlyLiveEdit,
  (showInspector, liveEditType, hasSelectedEvents, calculationEngine, isBackendOnlyLiveEdit) => {
    const isLiveEditing =
      liveEditType === LiveEditType.Event || liveEditType === LiveEditType.MultiEvent;
    if (calculationEngine === 'backend-only' || isBackendOnlyLiveEdit) {
      return false;
    }
    return !self.isMainThread || showInspector || isLiveEditing || hasSelectedEvents;
  },
);

type InspectorEventsByEventGroupIdParams = {
  driverId: DriverId;
  blockId: BlockId;
  selectedEventGroupId: EventGroupId | undefined;
};

const inspectorEventsByEventGroupIdKeySelector: ParametricSelector<
  InspectorEventsByEventGroupIdParams,
  string
> = (_state, params) => `${params.driverId}:${params.selectedEventGroupId}:${params.blockId}`;

export const inspectorEventsByEventGroupIdSelector: ParametricSelector<
  InspectorEventsByEventGroupIdParams,
  Record<EventGroupId, Record<MonthKey, Pick<Event, 'id' | 'parentId'>>>
> = createCachedSelector(
  (state: RootState, params: InspectorEventsByEventGroupIdParams) =>
    eventsForDriverSelector(state, { id: params.driverId }),
  (state: RootState, params: InspectorEventsByEventGroupIdParams) =>
    blockMonthKeysSelector(state, params.blockId),
  (events, monthKeys) => {
    const eventsInDateRange = events.filter((event) =>
      Object.keys(event.customCurvePoints ?? {}).some((mk) => monthKeys.includes(mk)),
    );
    const eventsByEventGroupId = groupBy(eventsInDateRange, (event) => event.parentId);
    const eventsByMonthKeyByEventGroupId = mapValues(eventsByEventGroupId, (eventsForEventGroup) =>
      getEventAndParentIdsByMonthKey(eventsForEventGroup),
    );
    return eventsByMonthKeyByEventGroupId;
  },
)({
  keySelector: inspectorEventsByEventGroupIdKeySelector,
  selectorCreator: createDeepEqualSelector,
});

export const sortedInspectorRowEventGroupIdsSelector: ParametricSelector<
  InspectorEventsByEventGroupIdParams,
  EventGroupId[]
> = createCachedSelector(
  inspectorEventsByEventGroupIdSelector,
  fieldSelector('selectedEventGroupId'),
  (eventsByEventGroupIdByMonthKey, selectedEventGroupId) => {
    const eventGroupIds = Object.keys(eventsByEventGroupIdByMonthKey);

    if (selectedEventGroupId != null && eventGroupIds.includes(selectedEventGroupId)) {
      return [selectedEventGroupId, ...eventGroupIds.filter((id) => id !== selectedEventGroupId)];
    }

    return eventGroupIds;
  },
)({
  keySelector: inspectorEventsByEventGroupIdKeySelector,
  selectorCreator: createDeepEqualSelector,
});
