import { DateTime } from 'luxon';

import { DEFAULT_CURRENCY } from 'config/currency';
import { DEFAULT_DRIVER_FORMAT } from 'config/drivers';
import { ComparisonColumn, NegativeDisplay, ValueType } from 'generated/graphql';
import { shortMonthFormat } from 'helpers/dates';
import { formatDriverValue } from 'helpers/formatting';
import { getNumber } from 'helpers/number';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { AttributeId } from 'reduxStore/models/dimensions';
import { DriverFormat, DriverId } from 'reduxStore/models/drivers';

export type DisplayConfiguration = {
  format: DriverFormat;
  currency?: string;
  // "null" uses the default behavior
  decimalPlaces?: number | null;
  negativeDisplay: NegativeDisplay;
  comparisonType?: ComparisonColumn;
};

export const DEFAULT_DISPLAY_CONFIGURATION: DisplayConfiguration = {
  format: DEFAULT_DRIVER_FORMAT,
  currency: DEFAULT_CURRENCY,
  decimalPlaces: null,
  negativeDisplay: NegativeDisplay.NegativeSign,
};

export type ObjectFieldEvaluation = {
  objectId: string;
  fieldSpecId?: BusinessObjectFieldSpecId;
  evaluation: Array<Value | undefined>;
  // This is populated if the evaluated field is a driver property
  subDriverId?: DriverId;
};

export type ObjectSpecEvaluation = {
  specId: BusinessObjectSpecId;
  evaluation: number | undefined;
  fieldEvaluations: ObjectFieldEvaluation[];
};

export type AttributeValue = {
  type: ValueType.Attribute;
  value: AttributeId;
};

export type TimestampValue = { type: ValueType.Timestamp; value: string };

export type NumberValue = { type: ValueType.Number; value: number };

// Represents a user-specified null as opposed to a nullish value from a calculation
export const EXPLICIT_NULL_TYPE = 'EXPLICIT_NULL';
export const EXPLICIT_NULL_DISPLAY = 'NULL';
export type ExplicitNull = { type: typeof EXPLICIT_NULL_TYPE; value: undefined };
export const EXPLICIT_NULL: ExplicitNull = { type: EXPLICIT_NULL_TYPE, value: undefined };

export type Value = AttributeValue | TimestampValue | NumberValue;

export type NullableValue<V extends Value = Value> = { type: ValueType; value: undefined } | V;

export function toNumberValue(value: number): NumberValue {
  return { type: ValueType.Number, value };
}

export function toTimestampValue(value: DateTime): TimestampValue {
  return { type: ValueType.Timestamp, value: value.toISO() };
}

export function toAttributeValue(value: AttributeId): AttributeValue {
  return { type: ValueType.Attribute, value };
}

export function toValueType(val: string, type: ValueType): Value {
  switch (type) {
    case ValueType.Number:
      return { type, value: getNumber(val) };
    default:
      return { type, value: val } as Value;
  }
}

export function toNumberValueOrUndefined(value?: number): NumberValue | undefined {
  return value == null ? undefined : toNumberValue(value);
}

export function formatValueToString(
  value: Value,
  numberDisplayConfig?: DisplayConfiguration,
  {
    abbreviate = true,
    includeCents,
  }: {
    abbreviate?: boolean;
    includeCents?: boolean;
  } = {},
): string {
  switch (value.type) {
    case ValueType.Number:
      return formatDriverValue(value.value, numberDisplayConfig ?? DEFAULT_DISPLAY_CONFIGURATION, {
        abbreviate,
        includeCents,
      });
    case ValueType.Timestamp:
      return shortMonthFormat(DateTime.fromISO(value.value));
    default:
      return value.value ?? EXPLICIT_NULL_DISPLAY;
  }
}

export function toNullableValue(value?: Value, type?: ValueType): NullableValue {
  if (value != null) {
    return value;
  }
  if (type != null) {
    return { type, value: undefined };
  }
  return { type: ValueType.Number, value: undefined };
}

export function hasValue(val?: NullableValue): val is Value {
  return val != null && val.value != null;
}

export function isTimestampValue(value: Value): value is TimestampValue {
  return value.type === ValueType.Timestamp;
}

export function isAttributeValue(value: Value): value is AttributeValue {
  return value.type === ValueType.Attribute;
}
