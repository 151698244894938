import { Box } from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';

import TableCell from 'components/Table/TableCell';
import TimeSeriesCellWrapper from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapper';
import {
  CellRefContext,
  CellSelectionStateContext,
  CellType,
  DEFAULT_CELL_SELECTION_STATE,
  DriverCellRef,
} from 'config/cells';
import { DriverRowContext } from 'config/driverRowContext';
import { getMonthColumnKey } from 'helpers/cells';
import { uuidv4 } from 'helpers/uuidv4';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

interface Props {
  color: string;
  columnKey: string;
  opacity?: number;
}

const PlaceholderTimeSeriesCell = ({ color, columnKey, opacity = 0.2 }: Props) => {
  const { blockId } = useBlockContext();
  const width = useAppSelector((state) =>
    columnWidthSelector(state, { columnType: columnKey, blockId }),
  );
  const { driverId } = useContext(DriverRowContext);

  const cellRef: DriverCellRef = useMemo(
    () => ({
      type: CellType.Driver,
      rowKey: { driverId, layerId: undefined, groupId: undefined },
      columnKey: getMonthColumnKey(columnKey),
    }),
    [driverId, columnKey],
  );
  const cellCtx = useMemo(() => {
    return {
      cellRef,
      cellId: uuidv4(),
    };
  }, [cellRef]);

  return (
    <CellRefContext.Provider value={cellCtx}>
      <CellSelectionStateContext.Provider value={DEFAULT_CELL_SELECTION_STATE}>
        <TimeSeriesCellWrapper color={color} width={width}>
          <TableCell width={width}>
            <Box opacity={opacity} bgColor={color} height="1rem" width="2rem" borderRadius="sm" />
          </TableCell>
        </TimeSeriesCellWrapper>
      </CellSelectionStateContext.Provider>
    </CellRefContext.Provider>
  );
};

export default React.memo(PlaceholderTimeSeriesCell);
