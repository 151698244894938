import { Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useContext, useMemo } from 'react';

import DimensionSearchMenu from 'components/AttributeTypeaheadMenu/DimensionSearchMenu';
import { splitAttributesFromQuery } from 'components/DimensionalDriverMenu/CreateDimensionalDriverMenu';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { CustomOption, Section } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { DimDriverEditReactContext } from 'config/dimDriverEditContext';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  createAttribute,
  createDimensionWithAttributes,
} from 'reduxStore/actions/dimensionMutations';
import {
  Attribute,
  UserAddedAttribute,
  UserAddedDimensionType,
} from 'reduxStore/models/dimensions';
import { dimensionsByIdSelector, validAttributesSelector } from 'selectors/dimensionsSelector';

const SEARCH_KEYS = ['name'];
const ATTRIBUTE_SECTIONS: Section[] = [
  {
    id: 'attributes',
    name: 'Attributes',
    maxResults: 5,
    showMore: true,
  },
];

export type AttributeItem = { sectionId: 'attributes'; meta?: string; name: string } & Attribute;

interface Props {}

const AttributeTypeaheadMenu: React.FC<Props> = () => {
  const allAttributes = useAppSelector(validAttributesSelector);
  const dimensionById = useAppSelector(dimensionsByIdSelector);
  const { toggleAttr, setSubDriverName, subDriverName } = useContext(DimDriverEditReactContext);
  const { driverNameQuery, attributeNameQuery } = splitAttributesFromQuery(subDriverName);
  const dispatch = useAppDispatch();
  const onSelectAttr = useCallback(
    (attr: Attribute) => {
      setSubDriverName(driverNameQuery);
      toggleAttr(attr);
    },
    [toggleAttr, setSubDriverName, driverNameQuery],
  );

  const items: AttributeItem[] = useMemo(
    () =>
      Object.values(allAttributes).map((a) => ({
        ...a,
        name: a.value.toString(),
        sectionId: 'attributes' as const,
        meta: dimensionById[a.dimensionId]?.name,
      })),
    [allAttributes, dimensionById],
  );

  const onSelectDimension = useCallback(
    (dimensionId: string) => {
      if (attributeNameQuery == null) {
        return;
      }
      const attrId = uuidv4();
      dispatch(createAttribute({ dimensionId, value: attributeNameQuery, attributeId: attrId }));
      const resultingAttr: UserAddedAttribute = {
        id: attrId,
        type: UserAddedDimensionType,
        dimensionId,
        deleted: false,
        value: attributeNameQuery,
      };
      onSelectAttr(resultingAttr);
    },
    [dispatch, attributeNameQuery, onSelectAttr],
  );

  const onCreateNewDimension = useCallback(
    (dimensionName: string) => {
      if (attributeNameQuery == null) {
        return;
      }
      const dimensionId = uuidv4();
      const attrId = uuidv4();
      dispatch(
        createDimensionWithAttributes({
          dimensionId,
          dimensionName,
          attributes: [
            {
              id: attrId,
              value: attributeNameQuery,
            },
          ],
        }),
      );
      const resultingAttr: UserAddedAttribute = {
        id: attrId,
        type: UserAddedDimensionType,
        dimensionId,
        deleted: false,
        value: attributeNameQuery,
      };
      onSelectAttr(resultingAttr);
    },
    [dispatch, attributeNameQuery, onSelectAttr],
  );

  const customOptions: CustomOption[] = useMemo(() => {
    return [
      {
        id: 'customOptions',
        render: (_q: string) => (
          <Flex alignItems="center" columnGap={1} width="full">
            <Text>Create</Text>
            <Text fontWeight="bold">&quot;{attributeNameQuery}&quot;</Text>
            <Text>in...</Text>
          </Flex>
        ),
        onSelect: () => {},
        submenu: () => (
          <DimensionSearchMenu
            onSelectDimension={onSelectDimension}
            onCreateDimension={onCreateNewDimension}
            onCancel={() => {}}
          />
        ),
      },
    ];
  }, [attributeNameQuery, onCreateNewDimension, onSelectDimension]);

  return (
    <SelectMenuContainer>
      <SelectMenu
        query={attributeNameQuery}
        items={items}
        sections={ATTRIBUTE_SECTIONS}
        searchKeys={SEARCH_KEYS}
        onSelect={(item: AttributeItem) => {
          onSelectAttr(item);
        }}
        customOptions={customOptions}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};
export default AttributeTypeaheadMenu;
