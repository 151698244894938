import { Flex, Tag } from '@chakra-ui/react';
import React from 'react';

import LayerName from 'components/LayerName/LayerName';
import { nameIndentWidth } from 'config/driverGridView';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import {
  DriverDiffType,
  scenarioComparisonDriverDiffsForLayerSelector,
  scenarioComparisonShouldShowDriverDiffs,
} from 'selectors/scenarioComparisonSelector';

interface Props {
  layerId: LayerId;
  driverId?: DriverId;
  isBaselineLayer?: boolean;
  isComparisonLayout?: boolean;
}

const DIFF_TYPE_TO_TAG: Record<DriverDiffType, () => JSX.Element | null> = {
  added: () => (
    <Tag size="sm" colorScheme="green">
      Added
    </Tag>
  ),
  deleted: () => (
    <Tag size="sm" colorScheme="red">
      Deleted
    </Tag>
  ),
  updated_formula: () => (
    <Tag size="sm" colorScheme="yellow">
      Formula
    </Tag>
  ),
  updated_name: () => (
    <Tag size="sm" colorScheme="orange">
      Renamed
    </Tag>
  ),
  updated_actuals_timeseries: () => null,
  updated_coloring: () => null,
  updated_currency: () => null,
  updated_decimal_places: () => null,
  updated_format: () => null,
  updated_lever_type: () => null,
  updated_references: () => null,
};

const DiffTag: React.FC<{ diffType: DriverDiffType }> = ({ diffType }) => {
  return DIFF_TYPE_TO_TAG[diffType]();
};

const LayerNameCellDiffTag: React.FC<Props> = ({ layerId, driverId }) => {
  const { blockId } = useBlockContext();
  const shouldShowDriverDiffs = useAppSelector((state) =>
    scenarioComparisonShouldShowDriverDiffs(state, blockId),
  );
  const driverDiffs = useAppSelector((state) =>
    shouldShowDriverDiffs && driverId != null
      ? scenarioComparisonDriverDiffsForLayerSelector(state, { id: driverId, layerId })
      : null,
  );

  if (driverDiffs == null) {
    return null;
  }

  return (
    <>
      {Array.from(driverDiffs).map((diffType) => (
        <DiffTag key={diffType} diffType={diffType} />
      ))}
    </>
  );
};

const LayerNameCellContents: React.FC<Props> = ({
  layerId,
  driverId,
  isBaselineLayer,
  isComparisonLayout,
}) => {
  const depth = layerId == null ? 0 : 1;

  return (
    <Flex columnGap={2} pl={nameIndentWidth(depth)} data-testid="layer-name-cell">
      <LayerName
        layerId={layerId}
        isBaselineLayer={isBaselineLayer}
        isComparisonLayout={isComparisonLayout}
        noBgColor
        fontSize={isComparisonLayout ? 'xxs' : 'sm'}
      />
      <LayerNameCellDiffTag layerId={layerId} driverId={driverId} />
    </Flex>
  );
};

export default LayerNameCellContents;
