import { Flex, Text } from '@chakra-ui/react';
import { useContext } from 'react';

import { Tooltip } from 'chakra/tooltip';
import { TIME_PERIOD_LABEL } from 'components/CustomizeBlock/constants';
import { nameIndentWidth } from 'config/driverGridView';
import { DriverRowContext } from 'config/driverRowContext';
import { comparisonColumnToNameForScenarios } from 'helpers/blockComparisons';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { layerNameByIdSelector } from 'selectors/layerSelector';

// This cell gets rendered when when comparison types are shown as rows,
// i.e. blockConfig.comparison.layout = Columns
const ComparisonNameCell: React.FC = () => {
  const { blockId } = useBlockContext();
  const { comparisonRowLayerId, comparisonType, comparisonTimePeriod } =
    useContext(DriverRowContext);
  const layerNameById = useAppSelector(layerNameByIdSelector);
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );

  let comparisonLabel =
    comparisonType != null
      ? comparisonColumnToNameForScenarios[comparisonType]
      : comparisonRowLayerId != null
        ? layerNameById[comparisonRowLayerId]
        : '';

  if (comparisonTimePeriod != null) {
    comparisonLabel = TIME_PERIOD_LABEL[comparisonTimePeriod];
  }

  if (comparisonLabel == null) {
    return null;
  }
  return (
    <Flex columnGap={2} pl={nameIndentWidth(1)}>
      <Tooltip label={`Comparing against ${layerNameById[baselineLayerId]}`}>
        <Text align="center" fontWeight="medium" color="gray.500" fontSize="xxs">
          {comparisonLabel}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export default ComparisonNameCell;
