import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/nextjs';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { noop } from 'lodash';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { CLIENT_VERSION } from 'config/clientVersion';
import { appEnv, backendUrl, isDevelopment, isUserBrowser, platform } from 'helpers/environment';
import { memoryMonitor } from 'helpers/memory';
import { trackSessionEnd, trackSessionStart } from 'helpers/segment';
import { isRunwayEmployee } from 'helpers/user';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { initializeFlagsFromLocalStorage } from 'reduxStore/reducers/featureFlagsSlice';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { loadedSelectedOrgSelector } from 'selectors/selectedOrgSelector';
import { InitializeSentryMessage } from 'workers/formulaCalculator/types';

// we want samples to be equally likely to occur at any point in time;
// use Poisson process to collect samples
const MEAN_INTERVAL_IN_MS = 1 * 60 * 1000;
// Computes a random interval in milliseconds such that on average there
// is one measurement every minute
const measurementInterval = () => {
  return -Math.log(Math.random()) * MEAN_INTERVAL_IN_MS;
};

const scheduleMemoryMeasurement = () => {
  const interval = measurementInterval();
  setTimeout(performMemoryMeasurement, interval);
};

const performMemoryMeasurement = () => {
  // N.B. these measurements are highly imperfect; see memory monitor for more details as well as
  // https://web.dev/articles/monitor-total-page-memory-usage
  const memoryUsage = memoryMonitor.getJSHeapSize();
  if (memoryUsage != null) {
    datadogRum.addAction('memory_usage', {
      jsHeapSizeLimit: memoryUsage.jsHeapSizeLimit,
      totalJSHeapSize: memoryUsage.totalJSHeapSize,
      usedJSHeapSize: memoryUsage.usedJSHeapSize,
    });
  }
  scheduleMemoryMeasurement();
};

const useDatadogInit = () => {
  useEffectOnce(() => {
    if (!isUserBrowser()) {
      return;
    }

    datadogLogs.init({
      clientToken: 'pub7d20755e7a72d62feb54cc76edea80a9',
      env: appEnv,
      version: CLIENT_VERSION,
      // this should only run once, but otherwise this gets annoying because of HMR
      silentMultipleInit: true,
    });
    // see options here: https://docs.datadoghq.com/real_user_monitoring/browser/setup/#setup
    datadogRum.init({
      applicationId: '6252ca1f-750a-49a1-a7f5-18a14305efb0',
      clientToken: 'pub7d20755e7a72d62feb54cc76edea80a9',
      site: 'datadoghq.com',
      service: 'runway-web',
      env: appEnv,
      version: CLIENT_VERSION,
      // see https://docs.datadoghq.com/real_user_monitoring/platform/connect_rum_and_traces/?tab=browserrum
      allowedTracingUrls: [
        (url) =>
          url.includes(backendUrl) && (url.includes('graphql') || url.includes('client_api')),
      ],
      traceSampleRate: isDevelopment ? 0 : 100,
      // TODO: considering proxying data through our servers if we end up losing valuable signal; see
      // https://docs.datadoghq.com/real_user_monitoring/guide/proxy-rum-data/?tab=npm
      sessionSampleRate: isDevelopment ? 0 : 100,
      // no session replays in Datadog for now; we can revisit this later but we use Sentry for this today
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
      excludedActivityUrls: [
        (url) => url.includes('seg/api'),
        (url) => url.includes('api/version'),
        (url) => url.includes('sentry.io'),
        (url) => url.includes('errors-tunnel'),
      ],
      // this should only run once, but otherwise this gets annoying because of HMR
      silentMultipleInit: true,
    });

    scheduleMemoryMeasurement();
  });
};

const AnalyticsInitializer = () => {
  const selectedOrg = useAppSelector(loadedSelectedOrgSelector);
  const user = useAppSelector(authenticatedUserSelector);
  const orgId = selectedOrg?.id;
  const orgSlug = selectedOrg?.slug;
  const orgName = selectedOrg?.name;
  const ldClient = useLDClient();
  const dispatch = useAppDispatch();

  useDatadogInit();
  useEffect(() => {
    if (!isUserBrowser()) {
      return noop;
    }

    if (orgId != null && user != null) {
      const isEmployee = isRunwayEmployee(user);
      datadogLogs.setGlobalContext({ org_id: orgId, org_slug: orgSlug });
      datadogLogs.setUser({ id: user.id, email: user.email, is_employee: isEmployee });
      datadogRum.setGlobalContext({
        org_id: orgId,
        org_slug: orgSlug,
        // see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/hardwareConcurrency
        hardwareConcurrency:
          'hardwareConcurrency' in navigator ? navigator.hardwareConcurrency : -1,
      });
      datadogRum.setUser({ id: user.id, email: user.email, is_employee: isEmployee });

      const nameParts = user.name.split(' ');
      Sentry.setUser({
        id: user.id,
        email: user.email,
        isEmployee,
      });
      Sentry.setTag('org_id', orgId);
      Sentry.setTag('org_slug', orgSlug);
      Sentry.setTag('is_employee', isEmployee);
      Sentry.setContext('org', {
        id: orgId,
        name: orgName,
      });

      const msg: InitializeSentryMessage = {
        type: 'initializeSentry',
        data: {
          user: {
            id: user.id,
            email: user.email,
            isEmployee,
          },
          orgId,
        },
      };
      window.formulaCalculator?.postMessage(msg);

      if (!isDevelopment) {
        window.analytics.identify(
          user.id,
          {
            organization_id: orgId,
            organization_name: orgName,
            name: user.name,
            first_name: nameParts[0],
            last_name: nameParts.slice(1).join(' '),
            company: {
              id: orgId,
              name: orgName,
            },
            email: user.email,
            is_employee: isRunwayEmployee(user),
            platform: platform(),
            created_at: String(user.createdAt) ?? new Date().toISOString(),
          },
          {},
          () => {
            window.analytics.group(orgId, { name: orgName });
            trackSessionStart();
          },
        );
      }

      window.addEventListener('beforeunload', trackSessionEnd);
    }

    dispatch(initializeFlagsFromLocalStorage());

    return () => {
      window.removeEventListener('beforeunload', trackSessionEnd);
    };
  }, [user, orgId, orgName, orgSlug, dispatch, ldClient]);

  return null;
};

export default AnalyticsInitializer;
