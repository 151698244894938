import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import zipObject from 'lodash/zipObject';
import { createCachedSelector } from 're-reselect';

import { CELL_DATA_COLUMN_WIDTH_IN_PX } from 'config/cells';
import { DETAIL_PANE_GUTTER_WIDTH_IN_PX } from 'config/detailPane';
import {
  ColumnLayerId,
  ColumnOrMonthKey,
  DriverPropertyColumn,
  INITIAL_VALUE_COLUMN_TYPE,
  ModelViewColumnType,
} from 'config/modelView';
import { BlockType } from 'generated/graphql';
import { isColumnKeyEqual, isMonthColumnKey } from 'helpers/cells';
import { previousMonthKey } from 'helpers/dates';
import { layerParamMemo } from 'helpers/layerSelectorFactory';
import { BlockId, OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY } from 'reduxStore/models/blocks';
import { isDataColumnType } from 'reduxStore/reducers/helpers/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { selectedBlockCellKeysSelector } from 'selectors/activeCellSelectionSelector';
import { blockTypeSelector, isObjectTimeseriesViewSelector } from 'selectors/blocksSelector';
import { lastDimensionalPropertyKeyForTable } from 'selectors/collectionBlocksSelector';
import { blockIdSelector, fieldSelector } from 'selectors/constSelectors';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import {
  isBlockDriverDetailPageSelector,
  isBlockPlanDetailPageSelector,
} from 'selectors/modelViewSelector';
import { pageGutterWidthInPxSelector } from 'selectors/pageSelector';
import { prevailingCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';
import {
  visibleColumnsSelector,
  visibleStickyColumnsSelector,
} from 'selectors/visibleColumnTypesSelector';
import { ParametricSelector } from 'types/redux';

import { driverPropertyColumnsSelector } from './visibleColumnTypesSelector';

export const columnWidthsByTypeSelector: ParametricSelector<
  BlockId,
  Record<ColumnOrMonthKey, number>
> = createCachedSelector(visibleColumnsSelector, (columns) => {
  const columnsByType = keyBy(columns, 'key');
  return mapValues(columnsByType, 'width');
})(blockIdSelector);

type DriverPropertyColumnSelectorProps = {
  blockId: BlockId;
  columnType: ModelViewColumnType;
};

function cacheKeyForDriverPropertyColumn(
  _: RootState,
  { blockId, columnType }: DriverPropertyColumnSelectorProps,
) {
  return `${blockId}.${columnType}`;
}

export const driverPropertyColumnSelector: ParametricSelector<
  DriverPropertyColumnSelectorProps,
  DriverPropertyColumn | undefined
> = createCachedSelector(
  (state: RootState, { blockId }: DriverPropertyColumnSelectorProps) =>
    driverPropertyColumnsSelector(state, blockId),
  fieldSelector('columnType'),
  (propertyColumns, columnType) => {
    return propertyColumns.find((col) => col.type === columnType);
  },
)(cacheKeyForDriverPropertyColumn);

export const driverPropertyColumnTableCellWidthSelector: ParametricSelector<
  DriverPropertyColumnSelectorProps,
  number
> = createCachedSelector(
  (state: RootState, { blockId, columnType }: DriverPropertyColumnSelectorProps) =>
    columnWidthSelector(state, { blockId, columnType }),
  driverPropertyColumnSelector,
  (columnWidth, propertyColumn) => {
    if (propertyColumn == null) {
      return 0;
    }

    const numLayers = propertyColumn.layerIds.length;

    if (numLayers === 0) {
      // Should never happen. Even when there are 0 comparison layers,
      // we still set propertyColumn.layerIds = [undefined]
      return 0;
    }

    return columnWidth / numLayers;
  },
)(cacheKeyForDriverPropertyColumn);

const stickyColumnWidthsByTypeSelector: ParametricSelector<
  BlockId,
  Record<ColumnOrMonthKey, number>
> = createCachedSelector(visibleStickyColumnsSelector, (columns) => {
  const columnsByType = keyBy(columns, 'key');
  return mapValues(columnsByType, 'width');
})(blockIdSelector);

type ColumnProps = { blockId: BlockId; columnType: ColumnOrMonthKey };

export const columnWidthSelector: ParametricSelector<ColumnProps, number> = createCachedSelector(
  (state: RootState, { blockId }: ColumnProps) => columnWidthsByTypeSelector(state, blockId),
  fieldSelector('columnType'),
  (columnWidthsByType, columnType) => {
    const width = columnWidthsByType[columnType];
    if (width != null) {
      return width;
    }

    return CELL_DATA_COLUMN_WIDTH_IN_PX;
  },
)((_state, { blockId, columnType }) => [blockId, columnType].join('.'));

const blockGutterWidthPx: ParametricSelector<BlockId, number> = createCachedSelector(
  pageGutterWidthInPxSelector,
  isBlockDriverDetailPageSelector,
  isBlockPlanDetailPageSelector,
  (pageGutterWidthInPx, isDriverDetailBlock, isPlanDetailBlock) => {
    if (isDriverDetailBlock || isPlanDetailBlock) {
      return DETAIL_PANE_GUTTER_WIDTH_IN_PX;
    }

    return pageGutterWidthInPx;
  },
)(blockIdSelector);

const columnOffsetsByTypeSelector: ParametricSelector<
  BlockId,
  Record<ColumnOrMonthKey, number>
> = createCachedSelector(visibleColumnsSelector, (columns) => {
  const offsets = [0];
  columns
    .slice(0, -1)
    .map(({ width }) => width)
    .forEach((width, idx) => {
      offsets.push(offsets[idx] + width);
    });

  return zipObject(
    columns.map(({ key }) => key),
    offsets,
  );
})(blockIdSelector);

type OffsetProps = DriverPropertyColumnSelectorProps & { columnLayerId: ColumnLayerId };
export const driverPropertyColumnTableCellOffsetSelector: ParametricSelector<OffsetProps, number> =
  createCachedSelector(
    (state: RootState, { blockId, columnType }: OffsetProps) =>
      columnOffsetSelector(state, { blockId, columnType }),
    driverPropertyColumnSelector,
    driverPropertyColumnTableCellWidthSelector,
    (state, { columnLayerId }) => getGivenOrCurrentLayerId(state, layerParamMemo(columnLayerId)),
    (offset, column, columnWidthPerLayer, columnLayerId) => {
      if (column == null) {
        // Should never happen
        return 0;
      }

      const columnLayerIndex = column.layerIds.indexOf(columnLayerId);

      if (columnLayerIndex === -1) {
        // Should never happen
        return offset;
      }

      // The first comparison column will start at `offset`
      // The second comparison column will start at `offset + columnWidthPerLayer`
      //
      return offset + columnWidthPerLayer * columnLayerIndex;
    },
  )((state, props) => `${cacheKeyForDriverPropertyColumn(state, props)}.${props.columnLayerId}`);

const stickyColumnOffsetsByTypeSelector: ParametricSelector<
  BlockId,
  Record<ColumnOrMonthKey, number>
> = createCachedSelector(
  visibleStickyColumnsSelector,
  blockGutterWidthPx,
  (columns, gutterWidthPx) => {
    const offsets = [0];
    columns
      .slice(0, -1)
      .map(({ width }) => width)
      .forEach((width, idx) => {
        offsets.push(offsets[idx] + width + (idx === 0 ? gutterWidthPx : 0));
      });

    return zipObject(
      columns.map(({ key }) => key),
      offsets,
    );
  },
)(blockIdSelector);

export const columnOffsetSelector: ParametricSelector<ColumnProps, number> = createCachedSelector(
  (state: RootState, { blockId }: ColumnProps) => columnOffsetsByTypeSelector(state, blockId),
  fieldSelector('columnType'),
  (columnOffsetsByType, columnType) => {
    return columnOffsetsByType[columnType];
  },
)((_state, { columnType, blockId }) => [columnType, blockId].join('.'));

export const lastStickyColumnTypeSelector: ParametricSelector<BlockId, ColumnOrMonthKey | null> =
  createCachedSelector(
    visibleStickyColumnsSelector,
    blockTypeSelector,
    isObjectTimeseriesViewSelector,
    lastDimensionalPropertyKeyForTable,
    (columnTypes, blockType, isObjectTimeseriesView, lastTableKey) => {
      if (isObjectTimeseriesView) {
        return INITIAL_VALUE_COLUMN_TYPE;
      }
      if (blockType === BlockType.ObjectTable) {
        if (lastTableKey != null) {
          return lastTableKey;
        }
        return OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY;
      }
      if (blockType === BlockType.ObjectGrid) {
        return INITIAL_VALUE_COLUMN_TYPE;
      }

      const nonDates = columnTypes.filter((c) => !isDataColumnType(c.key));
      return nonDates[nonDates.length - 1]?.key;
    },
  )(blockIdSelector);

export const allStickyColumnsWidthSelector: ParametricSelector<BlockId, number | null> =
  createCachedSelector(
    lastStickyColumnTypeSelector,
    stickyColumnOffsetsByTypeSelector,
    stickyColumnWidthsByTypeSelector,
    (lastSticky, columnOffsetsByType, columnWidthsByType) => {
      if (lastSticky == null) {
        return null;
      }

      return columnOffsetsByType[lastSticky] + columnWidthsByType[lastSticky];
    },
  )(blockIdSelector);

export const initialScrollOffsetSelector: ParametricSelector<BlockId, number> =
  createCachedSelector(
    columnOffsetsByTypeSelector,
    allStickyColumnsWidthSelector,
    (state: RootState) => lastActualsMonthKeyForLayerSelector(state),
    (offsetsByType, stickyWidth, lastClose) => {
      if (stickyWidth == null) {
        return 0;
      }

      return (offsetsByType[previousMonthKey(lastClose)] ?? stickyWidth) - stickyWidth;
    },
  )(blockIdSelector);

export const minSelectedCellOffsetSelector: ParametricSelector<BlockId, number | null> =
  createCachedSelector(
    selectedBlockCellKeysSelector,
    allStickyColumnsWidthSelector,
    prevailingCellSelectionSelector,
    blockIdSelector,
    ({ orderedColumnKeys }, stickyWidth, cellSelection, blockId) => {
      if (
        cellSelection == null ||
        stickyWidth == null ||
        cellSelection.blockId !== blockId ||
        !isMonthColumnKey(cellSelection.activeCell.columnKey)
      ) {
        return null;
      }

      const matchingColumnKeyIdx = orderedColumnKeys.findIndex((ck) =>
        cellSelection.selectedCells.some((sc) => isColumnKeyEqual(sc.columnKey, ck)),
      );
      if (matchingColumnKeyIdx === -1) {
        return null;
      }

      const firstMonthColumnIdx = orderedColumnKeys.findIndex((ck) => isMonthColumnKey(ck));
      const numMonthColumns = matchingColumnKeyIdx - firstMonthColumnIdx;
      const offset = numMonthColumns * CELL_DATA_COLUMN_WIDTH_IN_PX;
      return offset;
    },
  )(blockIdSelector);
