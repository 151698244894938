import BaseFormulaChunk from 'components/Formula/BaseFormulaChunk';

interface Props {
  text: string;
}

const OperationChunk: React.FC<Props> = ({ text }) => {
  return <BaseFormulaChunk text={text} />;
};

export default OperationChunk;
