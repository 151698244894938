import { Box, Flex, Input, InputProps, Spinner, useMergeRefs } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { isEmpty } from 'lodash';
import noop from 'lodash/noop';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ValidationMode, useForm } from 'react-hook-form';

import { Tooltip, TooltipProps } from 'chakra/tooltip';
import { NUMERIC_FONT_SETTINGS } from 'config/styles';

type SaveReason = 'blur' | 'enter';
export type SaveExtraMetadata = {
  reason: SaveReason;
  // Whether the input value has been edited since it was initialized or submitted
  wasEdited: boolean;
};

export type SaveHandler = (value: string, extraMetadata: SaveExtraMetadata) => void;

interface Props extends Omit<InputProps, 'isInvalid' | 'onChange' | 'value'> {
  validationSchema?: Joi.ObjectSchema;
  reValidateMode?: keyof ValidationMode;
  errorPlacement?: TooltipProps['placement'];
  name: string;
  onSave?: SaveHandler;
  onChange?: (value: string) => void;
  onCancel?: (reason: 'escape' | 'noop') => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  saveOnBlur?: boolean;
  saveOnEnter?: boolean;
  selectOnFocus?: boolean;
  isNumeric?: boolean;
  // when passing defaultValue, the input is stateful
  defaultValue?: string;
  // when passing value, the input is controlled by the calling component
  value?: string;
  autoSizing?: boolean;
  allowDefaultValueSubmit?: boolean;
  // helpful for when input focus interferes with other features
  renderAsSpan?: boolean;
  loading?: boolean;
}

const selectInputText = (event: React.FocusEvent<HTMLInputElement>) => {
  event.currentTarget.select();
};

const AUTO_SIZING_PROPS: InputProps = {
  bottom: 0,
  left: 0,
  margin: 0,
  mx: 0,
  my: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: 'auto',
};

const EscapableInput = React.forwardRef<HTMLInputElement, Props>((props: Props, passedRef) => {
  const {
    onSave = noop,
    onCancel,
    onFocus,
    onBlur,
    validationSchema,
    reValidateMode = 'onSubmit',
    errorPlacement,
    saveOnBlur = true,
    saveOnEnter = true,
    defaultValue,
    name,
    selectOnFocus = true,
    onChange,
    value,
    autoSizing,
    onKeyDown,
    isNumeric = false,
    allowDefaultValueSubmit = false,
    isReadOnly,
    renderAsSpan = false,
    loading = false,
    ...rest
  } = props;
  const { handleSubmit, register, formState } = useForm(
    validationSchema
      ? {
          mode: reValidateMode,
          resolver: joiResolver(validationSchema),
          shouldUnregister: true,
        }
      : undefined,
  );
  // We're using a ref here instead of a state because, though unlikely, it
  // is possible for an edit and a submit to happen in the same render cycle.
  // If we were using a state, the state's value would not be updated until
  // the next render cycle and so submit would have a stale value. With refs
  // the value is changed immediately.
  const wasEditedRef = useRef(false);
  // eslint-disable-next-line
  const error: string | undefined = formState.errors[name]?.message;
  const hasError = error != null;
  const useFormRegister = register(name);
  const { ref } = useFormRegister;
  const formOnBlur = useFormRegister.onBlur;
  const formOnChange = useFormRegister.onChange;
  const isControlledInput = value != null;
  const [currValue, setCurrValue] = useState(defaultValue);
  const inputValue = value ?? currValue;

  const inputRef = useRef<HTMLInputElement>(null);
  const mergedRef = useMergeRefs(passedRef, inputRef, ref);
  const blurInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }, []);

  useEffect(() => {
    setCurrValue(defaultValue);
    wasEditedRef.current = false;
  }, [defaultValue]);

  const saved = useRef(false);
  const saveValue = useCallback(
    (extraMetadata: SaveExtraMetadata) => {
      if (saved.current) {
        // N.B. we avoid calling save twice (once for enter and once for blur) to avoid
        // creating an extra save.
        return;
      }

      saved.current = true;
      blurInput();

      // a controlled input just uses this callback to indicate form submission
      if (value != null) {
        onSave(value, extraMetadata);
      } else if (currValue != null && (allowDefaultValueSubmit || currValue !== defaultValue)) {
        onSave(currValue, extraMetadata);
      } else if (onCancel != null) {
        onCancel('noop');
      }
    },
    [blurInput, value, currValue, allowDefaultValueSubmit, defaultValue, onCancel, onSave],
  );

  const onSubmit = useCallback(
    (saveReason: SaveReason) => {
      handleSubmit(() => {
        saveValue({ reason: saveReason, wasEdited: wasEditedRef.current });
        wasEditedRef.current = false;
      }, noop)();
    },
    [handleSubmit, saveValue],
  );

  const onKeyDownCallback = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(ev);
      if (ev.key === 'Escape') {
        ev.preventDefault();
        ev.stopPropagation();
        if (onCancel != null) {
          onCancel('escape');
        }

        // in a controlled input, it's up to the caller to update the `value` prop
        if (!isControlledInput) {
          setCurrValue(defaultValue);
        }

        // N.B. requestAnimationFrame here because we need to let the above change to
        // currValue propagate to the saveValue callback.
        window.requestAnimationFrame(blurInput);
      } else if (ev.key === 'Tab') {
        ev.preventDefault();
        onSubmit('blur');
      } else if (ev.key === 'Enter' && saveOnEnter) {
        ev.stopPropagation();
        onSubmit('enter');
      } else if (ev.key === 'ArrowLeft' || ev.key === 'ArrowRight') {
        ev.stopPropagation();
      }
    },
    [onKeyDown, saveOnEnter, onCancel, isControlledInput, blurInput, defaultValue, onSubmit],
  );

  const onChangeCallback = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      formOnChange(ev);
      const newValue = ev.currentTarget.value;
      saved.current = false;
      wasEditedRef.current = true;

      if (!isControlledInput) {
        setCurrValue(newValue);
      }

      if (onChange != null) {
        onChange(newValue);
      }
    },
    [formOnChange, onChange, isControlledInput],
  );

  const onBlurCallback = useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      formOnBlur(ev);

      if (saveOnBlur) {
        onSubmit('blur');
      }

      if (onBlur != null) {
        onBlur(ev);
      }
    },
    [formOnBlur, saveOnBlur, onBlur, onSubmit],
  );

  const onFocusCallback = useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus != null) {
        onFocus(ev);
      }

      if (selectOnFocus) {
        selectInputText(ev);
      }

      saved.current = false;
    },
    [onFocus, selectOnFocus],
  );

  const input = (
    <Tooltip placement={errorPlacement} isOpen={error != null} label={error}>
      <Flex direction="row" align="center" width="full" height="full">
        <Input
          as={renderAsSpan ? 'span' : undefined}
          name={name}
          ref={mergedRef}
          isInvalid={hasError}
          onBlur={onBlurCallback}
          onFocus={onFocusCallback}
          value={inputValue}
          _focus={{ borderColor: error != null ? 'red.500' : 'selection.500' }}
          _active={{ borderColor: error != null ? 'red.500' : 'selection.500' }}
          borderColor={error != null ? 'red.500' : undefined}
          onChange={onChangeCallback}
          onKeyDown={onKeyDownCallback}
          autoComplete="off"
          fontWeight="inherit"
          fontSize="inherit"
          sx={isNumeric ? NUMERIC_FONT_SETTINGS : undefined}
          isReadOnly={isReadOnly}
          disabled={loading}
          data-testid="escapable-input"
          {...(autoSizing ? AUTO_SIZING_PROPS : {})}
          {...rest}
        >
          {renderAsSpan ? inputValue : undefined}
        </Input>
        {loading ? <Spinner size="sm" color="gray.300" ml={2} /> : null}
      </Flex>
    </Tooltip>
  );

  if (!autoSizing) {
    return input;
  }

  return (
    <Box
      position="relative"
      height={props.height}
      margin={props.margin}
      mx={props.mx}
      my={props.my}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      minWidth={props.minWidth}
      maxWidth={props.maxWidth}
      px={props.px}
      padding={props.padding}
      pl={props.pl}
      pr={props.pr}
      data-value={`${isEmpty(inputValue) ? (props.placeholder ?? '') : inputValue} `}
      sx={{
        _after: {
          ...(isNumeric ? NUMERIC_FONT_SETTINGS : {}),
          content: 'attr(data-value)',
          fontSize: props.fontSize ?? 'inherit',
          fontStyle: props.fontStyle ?? 'inherit',
          fontWeight: props.fontWeight ?? 'inherit',
          letterSpacing: props.letterSpacing ?? 'inherit',
          textTransform: props.textTransform ?? 'inherit',
          visibility: 'hidden',
          whiteSpace: 'pre',
        },
      }}
    >
      {input}
    </Box>
  );
});

EscapableInput.displayName = 'EscapableInput';

export default EscapableInput;
