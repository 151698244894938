import { Box, Input, useMergeRefs } from '@chakra-ui/react';
import React, { useCallback, useContext, useRef } from 'react';
import { useEffectOnce } from 'react-use';

import { SearchableMultiSelectMenuContext } from 'components/SearchableMultiSelectMenu/SearchableMultiSelectMenuContext';

interface Props {
  children?: React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const MultiSelectSearchBar: React.FC<Props> = ({ children, inputRef }) => {
  const { query, setQuery, onKeyDown } = useContext(SearchableMultiSelectMenuContext);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (ev) => {
      setQuery(ev.target.value);
    },
    [setQuery],
  );

  const ref = useRef<HTMLInputElement>(null);
  useEffectOnce(() => {
    window.requestAnimationFrame(() => ref.current?.focus());
  });

  const mergedRef = useMergeRefs(ref, inputRef);

  return (
    <Box pb={1} w="full">
      {children}
      <Input
        variant="inline"
        ref={mergedRef}
        fontSize="xs"
        onChange={onChange}
        value={query}
        onKeyDown={onKeyDown}
        ml={1}
        data-testid="multi-select-search-bar-input"
      />
    </Box>
  );
};

export default MultiSelectSearchBar;
