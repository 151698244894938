import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'CupcakeIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  d: 'M8.00001 1.5C7.92416 1.5 7.84825 1.53645 7.80182 1.609C7.50654 2.06968 6.9853 2.93838 6.9853 3.33817C6.9853 3.8988 7.43938 4.35289 8.00001 4.35289C8.56064 4.35289 9.01472 3.8988 9.01472 3.33817C9.01472 2.93838 8.49297 2.06968 8.1982 1.609C8.15177 1.53645 8.07586 1.5 8.00001 1.5ZM7.49265 5.3676C7.21259 5.3676 6.9853 5.59489 6.9853 5.87495V6.88966H4.95588C3.84105 6.88966 2.92645 7.80426 2.92645 8.91909C2.92645 9.67536 3.35213 10.332 3.97089 10.681L4.33754 13.6111C4.40146 14.119 4.8324 14.5 5.34432 14.5H10.6557C11.1676 14.5 11.5991 14.119 11.6625 13.6111L12.0291 10.681C12.6479 10.332 13.0736 9.67536 13.0736 8.91909C13.0736 7.80426 12.159 6.88966 11.0441 6.88966H9.01472V5.87495C9.01472 5.59489 8.78743 5.3676 8.50737 5.3676H7.49265ZM4.95588 7.90438H11.0441C11.6107 7.90438 12.0589 8.35254 12.0589 8.91909C12.0589 9.48563 11.6107 9.9338 11.0441 9.9338C10.7839 9.9338 10.554 9.83549 10.3693 9.66922C10.2761 9.58511 10.155 9.53856 10.0294 9.53856C9.90387 9.53856 9.78277 9.58511 9.68954 9.66922C9.50487 9.83549 9.27496 9.9338 9.01472 9.9338C8.75448 9.9338 8.52457 9.83549 8.3399 9.66922C8.24667 9.58511 8.12557 9.53856 8.00001 9.53856C7.87445 9.53856 7.75335 9.58511 7.66012 9.66922C7.47545 9.83549 7.24554 9.9338 6.9853 9.9338C6.72506 9.9338 6.49515 9.83549 6.31048 9.66922C6.21725 9.58511 6.09615 9.53856 5.97059 9.53856C5.84503 9.53856 5.72393 9.58511 5.6307 9.66922C5.44603 9.83549 5.21611 9.9338 4.95588 9.9338C4.38933 9.9338 3.94116 9.48563 3.94116 8.91909C3.94116 8.35254 4.38933 7.90438 4.95588 7.90438Z',
});
