import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'MoreMenuIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <circle cx="8" cy="2.4" r="1.4" fill="currentColor" />
      <circle cx="8" cy="8" r="1.4" fill="currentColor" />
      <circle cx="8" cy="13.6" r="1.4" fill="currentColor" />
    </>
  ),
});
