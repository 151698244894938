import { BusinessObjectPropertyFieldCellRef } from 'config/cells';
import { BusinessObjectUpdateInput } from 'generated/graphql';
import { safeObjGet } from 'helpers/typescript';
import { BusinessObject } from 'reduxStore/models/businessObjects';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectFieldIsLinkedToIntegrationSelector } from 'selectors/businessObjectFromIntegrationSelector';
import { businessObjectsByIdForLayerSelector } from 'selectors/businessObjectsSelector';

export function deleteBusinessObjectCell(
  state: RootState,
  cells: BusinessObjectPropertyFieldCellRef[],
) {
  const businessObjectsById = businessObjectsByIdForLayerSelector(state);

  const updates: BusinessObjectUpdateInput[] = [];
  for (const cell of cells) {
    const businessObjectId = cell.rowKey.objectId;
    if (businessObjectId == null) {
      continue;
    }

    const businessObject = safeObjGet(businessObjectsById[businessObjectId]);
    if (businessObject == null) {
      continue;
    }

    const attributeUpdate = deleteBusinessObjectCellDimensionalAttribute(businessObject, cell);
    if (attributeUpdate != null) {
      updates.push(attributeUpdate);
      continue;
    }

    const fieldUpdate = deleteBusinessObjectCellField(businessObject, cell, state);
    if (fieldUpdate != null) {
      updates.push(fieldUpdate);
    }
  }
  return updates;
}

function deleteBusinessObjectCellDimensionalAttribute(
  businessObject: BusinessObject,
  cell: BusinessObjectPropertyFieldCellRef,
): BusinessObjectUpdateInput | null {
  if (businessObject.collectionEntry == null) {
    return null;
  }

  const attributeProperty = (businessObject.collectionEntry?.attributeProperties ?? []).find(
    (property) => {
      return property.dimensionalPropertyId === cell.columnKey.objectFieldSpecId;
    },
  );

  if (attributeProperty == null) {
    return null;
  }

  return {
    id: businessObject.id,
    updateCollectionEntry: {
      removeAttributeProperties: [attributeProperty.dimensionalPropertyId],
    },
  };
}

function deleteBusinessObjectCellField(
  businessObject: BusinessObject,
  cell: BusinessObjectPropertyFieldCellRef,
  state: RootState,
): BusinessObjectUpdateInput | null {
  const field = businessObject.fields.find((current) => {
    return current.fieldSpecId === cell.columnKey.objectFieldSpecId;
  });

  if (field == null) {
    return null;
  }

  const isIntegrated = businessObjectFieldIsLinkedToIntegrationSelector(state, {
    businessObjectId: businessObject.id,
    businessObjectFieldSpecId: field.fieldSpecId,
  });

  if (isIntegrated) {
    return null;
  }

  return {
    id: businessObject.id,
    deleteFields: [field.id],
  };
}
