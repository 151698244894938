import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Text, useBoolean } from '@chakra-ui/react';
import { keyBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import AddDriversPopover from 'components/AddDriversPopover/AddDriversPopover';
import { MenuSection } from 'components/MenuSection/MenuSection';
import { safeObjGet } from 'helpers/typescript';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { clearDriversFromBlock } from 'reduxStore/actions/driverReferenceMutations';
import { DriverId } from 'reduxStore/models/drivers';
import { blockConfigViewOptionsSelector, blockDriverIdsSelector } from 'selectors/blocksSelector';

export interface DriverItemProps {
  /**
   * Will only be set after AG Charts has rolled out.
   */
  seriesId?: string;
  driverId: DriverId;
  isLast: boolean;
  onOpenDetailPane?: () => void;
}

interface DriversListProps<ItemProps> {
  Item: React.ComponentType<DriverItemProps>;
  itemProps?: ItemProps;
  onOpenDetailPane?: () => void;
}

const SEARCH_POPUP_OFFSET: [number, number] = [30, -36];

// eslint-disable-next-line react/function-component-definition
export function DriversList<ItemProps>({
  Item,
  itemProps,
  onOpenDetailPane,
}: DriversListProps<ItemProps>) {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();

  const currentDriverIds = useAppSelector((state) => blockDriverIdsSelector(state, blockId));
  const chartDisplay = useAppSelector((state) =>
    blockConfigViewOptionsSelector(state, blockId),
  )?.chartDisplay;
  const [isAdding, setIsAdding] = useBoolean(false);

  const clearAll = useCallback(() => {
    dispatch(clearDriversFromBlock(blockId));
  }, [dispatch, blockId]);

  const seriesByDriverId = useMemo(
    () => (chartDisplay != null ? keyBy(chartDisplay.series, (series) => series.driverId) : {}),
    [chartDisplay],
  );

  return (
    <MenuSection
      isLast
      title="drivers"
      meta={
        <Button variant="text" fontSize="xxs" color="gray.500" onClick={clearAll} p={1}>
          Clear all
        </Button>
      }
    >
      <Box mt={1} overflow="hidden">
        <AddDriversPopover
          isAdding={isAdding}
          onClose={setIsAdding.off}
          offset={SEARCH_POPUP_OFFSET}
          placement="bottom-start"
          canFlip
        >
          <Button
            w="full"
            fontSize="xs"
            borderColor="gray.300"
            onClick={setIsAdding.on}
            alignItems="center"
            justifyContent="flex-start"
            columnGap={2}
            h="1.75rem"
            pl={2}
          >
            <SearchIcon boxSize={3} color="gray.500" />
            <Text color="gray.400" fontWeight="medium">
              Add drivers
            </Text>
          </Button>
        </AddDriversPopover>
        <Box mt={2}>
          {currentDriverIds.length === 0 && (
            <Text color="gray.500" fontSize="xs" fontWeight="medium" py={2} textAlign="center">
              No drivers selected
            </Text>
          )}
          {currentDriverIds.map((driverId, idx) => (
            <Item
              key={driverId}
              seriesId={safeObjGet(seriesByDriverId[driverId])?.id}
              driverId={driverId}
              isLast={idx === currentDriverIds.length - 1}
              onOpenDetailPane={onOpenDetailPane}
              {...itemProps}
            />
          ))}
        </Box>
      </Box>
    </MenuSection>
  );
}
