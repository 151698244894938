import { BoxProps } from '@chakra-ui/react';
import noop from 'lodash/noop';
import React, { useCallback, useMemo } from 'react';

import DatabaseTableHeaderCell from 'components/BusinessObjectTable/DatabaseTableHeaderCell';
import DimensionalPropertySettingsPopoverContent from 'components/BusinessObjectTable/SettingsPopoverContents/DimensionalPropertySettingsPopoverContent';
import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import InteractiveColumnHeaderCell from 'components/InteractiveColumnHeaderCell/InteractiveColumnHeaderCell';
import { ReorderableProps } from 'components/Reorderable/Reorderable';
import { DatabaseGroupKey, OBJECT_BLOCK_COLUMN_DRAG_ITEM_TYPE } from 'config/businessObjects';
import { COLUMN_HEADER_CELL_HEIGHT_PX } from 'config/cells';
import { toPxString } from 'helpers/styles';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useObjectCellRef from 'hooks/useObjectCellRef';
import { BaseDragItem } from 'hooks/useReorderable';
import { updateObjectTableColumnPosition } from 'reduxStore/actions/objectTableNavigation';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DimensionalPropertyId } from 'reduxStore/models/collections';
import { clearEditingPropertyFormula } from 'reduxStore/reducers/blockLocalStateSlice';
import { dimensionalPropertySelector } from 'selectors/collectionSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { isAutoFocusedDatabaseHeaderMenuSelector } from 'selectors/pageSelector';
import { isResourceRestrictedForUserSelector } from 'selectors/restrictedResourcesSelector';

type DragItem = BaseDragItem & {
  columnKey: DimensionalPropertyId;
};

interface Props {
  dimensionalPropertyId: DimensionalPropertyId;
  isLast: boolean;
  groupKey: DatabaseGroupKey;
  canDrag?: boolean;
  fixedWidth?: BoxProps['width'];
  topSpacingPx?: number;
  orientation?: ReorderableProps<DragItem>['orientation'];
  objectId: BusinessObjectId | undefined;
}

const DimensionalPropertyHeaderCell: React.FC<Props> = ({
  dimensionalPropertyId,
  isLast,
  canDrag = true,
  fixedWidth,
  topSpacingPx,
  groupKey,
  orientation,
  objectId,
}) => {
  const dispatch = useAppDispatch();
  const cellRef = useObjectCellRef(null, { fieldSpecId: dimensionalPropertyId }, groupKey);
  const { blockId, readOnly } = useBlockContext();
  const isAutoFocused = useAppSelector((state) =>
    isAutoFocusedDatabaseHeaderMenuSelector(state, {
      blockId,
      fieldSpecId: dimensionalPropertyId,
      groupKey,
      objectId,
    }),
  );
  const dimensionalProperty = useAppSelector((state) =>
    dimensionalPropertySelector(state, dimensionalPropertyId),
  );
  const onDrop = useCallback(
    (dropItem: DragItem, relativeTo: DragItem, position: 'before' | 'after') => {
      dispatch(
        updateObjectTableColumnPosition({
          blockId,
          fieldSpecId: dropItem.columnKey,
          insertBeforeId: position === 'before' ? relativeTo.columnKey : 'end',
        }),
      );
    },
    [blockId, dispatch],
  );

  const columnDragItem: DragItem = useMemo(
    () => ({ type: OBJECT_BLOCK_COLUMN_DRAG_ITEM_TYPE, columnKey: dimensionalPropertyId }),
    [dimensionalPropertyId],
  );

  const dimensionsById = useAppSelector(dimensionsByIdSelector);
  const errMsg = useMemo(() => {
    if (dimensionalProperty == null || dimensionalProperty.dimension == null) {
      return 'dimensional property not found';
    }
    const dim = dimensionsById[dimensionalProperty.dimension?.id];

    return dim == null || dim.deleted ? 'Dimension deleted' : null;
  }, [dimensionalProperty, dimensionsById]);

  const onClose = useCallback(() => {
    dispatch(clearEditingPropertyFormula());
  }, [dispatch]);

  const hasRestrictions = useAppSelector((state) =>
    isResourceRestrictedForUserSelector(state, { resourceId: dimensionalPropertyId, blockId }),
  );

  return (
    <CellContextProvider cellRef={cellRef}>
      <InteractiveColumnHeaderCell
        isAutoFocused={isAutoFocused}
        orientation={orientation}
        item={columnDragItem}
        onDrop={onDrop}
        canDrag={canDrag}
        disabled={readOnly}
        onClose={onClose}
        isLast={isLast}
        popoverContent={
          <DimensionalPropertySettingsPopoverContent
            isAutoFocused={false}
            dimensionalPropertyId={dimensionalPropertyId}
          />
        }
      >
        <DatabaseTableHeaderCell
          columnKey={dimensionalPropertyId}
          title={dimensionalProperty?.name ?? ''}
          fixedWidth={fixedWidth}
          topSpacingPx={topSpacingPx}
          errMsg={errMsg ?? undefined}
          height={toPxString(COLUMN_HEADER_CELL_HEIGHT_PX)}
          objectId={objectId}
          onClick={noop}
          orientation={orientation ?? 'horizontal'}
          hasRestrictions={hasRestrictions}
          groupKey={groupKey}
          isDatabaseKey={dimensionalProperty?.isDatabaseKey}
        />
      </InteractiveColumnHeaderCell>
    </CellContextProvider>
  );
};

export default DimensionalPropertyHeaderCell;
