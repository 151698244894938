import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { DateTime } from 'luxon';
import { createCachedSelector } from 're-reselect';
import { createSelector, createSelectorCreator, lruMemoize } from 'reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { getParentLayer } from 'helpers/layers';
import { isNamedVersion } from 'helpers/namedVersions';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { DEFAULT_LAYER_ID, Layer, LayerId, LayerMetadata } from 'reduxStore/models/layers';
import { UserId } from 'reduxStore/models/user';
import { layerIdSelector } from 'selectors/constSelectors';
import { loggedInUserSelector } from 'selectors/loginSelector';
import { usersInSelectedOrgByIdSelector } from 'selectors/selectedOrgSelector';
import { ParametricSelector, Selector } from 'types/redux';

export const currentLayerIdSelector: Selector<LayerId> = (state) => state.dataset.currentLayerId;

export const layersSelector: Selector<Record<LayerId, Layer>> = (state) => state.dataset.layers;

export const layerNameByIdSelector: Selector<Record<LayerId, string>> = createDeepEqualSelector(
  layersSelector,
  (layersById) => mapValues(layersById, (layer) => layer.name),
);

export const layerCreatedAtByIdSelector: Selector<Record<LayerId, string>> =
  createDeepEqualSelector(layersSelector, (layersById) =>
    mapValues(layersById, (layer) => layer.createdAt),
  );

export const layerCreatedByUserIdByIdSelector: Selector<Record<LayerId, UserId | undefined>> =
  createDeepEqualSelector(layersSelector, (layersById) =>
    mapValues(layersById, (layer) => layer.createdByUserId),
  );

const snapshotsSelector: Selector<Record<LayerId, DatasetSnapshot>> = (state) =>
  state.dataset.snapshots;

export const nonDeletedLayerIdsSelector = createDeepEqualSelector(layersSelector, (layers) =>
  Object.values(layers)
    .filter((layer) => !layer.isDeleted)
    .map((layer) => layer.id),
);

export const activeUnlockedLayerIdsSelector = createDeepEqualSelector(layersSelector, (layers) =>
  Object.values(layers)
    .filter((layer) => !layer.isDeleted && layer.lockedMutationId == null)
    .map((layer) => layer.id),
);

const namedVersionSelectorCreator = createSelectorCreator(lruMemoize, {
  resultEqualityCheck: (prevLayers: Record<LayerId, Layer>, currLayers: Record<LayerId, Layer>) => {
    const prevLayerIds = Object.keys(prevLayers);
    const currLayerIds = Object.keys(currLayers);

    if (prevLayerIds.length !== currLayerIds.length) {
      return false;
    }
    // Since named versions are locked it is safe to just do strict equality
    return prevLayerIds.every((layerId) => currLayers[layerId] === prevLayers[layerId]);
  },
});

export const namedDatasetVersionsByIdSelector: Selector<Record<LayerId, Layer>> =
  namedVersionSelectorCreator(layersSelector, (layersById) => {
    return pickBy(layersById, (layer) => !layer.isDeleted && isNamedVersion(layer));
  });

export const namedDatasetVersionListSelector: Selector<Layer[]> = createSelector(
  namedDatasetVersionsByIdSelector,
  (layersById) => {
    return Object.values(layersById);
  },
);

export const namedDatasetVersionNamesSelector: Selector<string[]> = createDeepEqualSelector(
  namedDatasetVersionListSelector,
  (layers) => {
    return layers.map((layer) => layer.name);
  },
);

export const defaultLayerSelector = createSelector(
  layersSelector,
  (layersById) => layersById[DEFAULT_LAYER_ID],
);

export const currentLayerSelector = createSelector(
  layersSelector,
  currentLayerIdSelector,
  (layersById, currentLayerId) => layersById[currentLayerId],
);

export const draftLayersSelector: Selector<Record<LayerId, Layer>> = createSelector(
  layersSelector,
  loggedInUserSelector,
  (layersById, user) =>
    pickBy(
      layersById,
      (layer) =>
        layer.isDraft &&
        !layer.isDeleted &&
        (layer.createdByUserId === user?.id || layer.createdByUserId == null),
    ),
);

export const draftLayersByParentSelector: Selector<Record<LayerId, Layer>> = createSelector(
  draftLayersSelector,
  (layersById) =>
    mapKeys(
      pickBy(layersById, (layer) => layer.isDraft),
      (v) => v.parentLayerId ?? DEFAULT_LAYER_ID,
    ),
);

export const parentLayerSelector: Selector<Layer | undefined> = createSelector(
  currentLayerSelector,
  layersSelector,
  (currentLayer, layers) => {
    return getParentLayer({ currentLayer, layers });
  },
);

export const layerSelector: ParametricSelector<LayerId, Layer> = createCachedSelector(
  layersSelector,
  layerIdSelector,
  (layersById, layerId) => {
    return layersById[layerId];
  },
)((state, layerId) => layerId);

export const layerMetadataSelector: ParametricSelector<LayerId, LayerMetadata | undefined> =
  createCachedSelector(layerSelector, (layer) => {
    if (layer == null) {
      return undefined;
    }

    const { name, id, createdAt, isDraft, isDeleted, lastActualsTime, lockedMutationId } = layer;
    return { name, id, createdAt, isDraft, isDeleted, lastActualsTime, lockedMutationId };
  })({
    keySelector: (_state, layerId) => layerId,
    selectorCreator: createDeepEqualSelector,
  });

export const isLayerNamedVersionSelector: ParametricSelector<LayerId, boolean> =
  createCachedSelector(
    layerMetadataSelector,
    (layerMetadata) =>
      layerMetadata != null && !layerMetadata.isDeleted && isNamedVersion(layerMetadata),
  )(layerIdSelector);

export const currentLayerNameSelector = createSelector(
  currentLayerSelector,
  (currentLayer) => currentLayer.name,
);

export const currentLayerDescriptionSelector = createSelector(
  currentLayerSelector,
  (currentLayer) => currentLayer.description,
);

const currentLayerCreatedByUserId = createSelector(
  currentLayerSelector,
  (currentLayer) => currentLayer.createdByUserId,
);

export const currentLayerCreatedByUserSelector = createSelector(
  currentLayerCreatedByUserId,
  usersInSelectedOrgByIdSelector,
  (userId, usersById) => (userId != null ? usersById[userId] : undefined),
);

export const currentLayerWasCreatedByUser = createSelector(
  currentLayerCreatedByUserId,
  loggedInUserSelector,
  (createdByUserId, loggedInUser) =>
    createdByUserId != null && loggedInUser != null && createdByUserId === loggedInUser?.id,
);

export const currentLayerIsDefaultSelector = createSelector(
  currentLayerIdSelector,
  (currentLayerId) => currentLayerId === DEFAULT_LAYER_ID,
);

export const currentLayerIsDraftSelector = createSelector(
  currentLayerSelector,
  (currentLayer) => currentLayer.isDraft,
);

export const currentLayerIsDraftOfDefaultLayerSelector = createSelector(
  currentLayerIsDraftSelector,
  parentLayerSelector,
  (currentLayerIsDraft, parentLayer) => {
    return currentLayerIsDraft && parentLayer?.id === DEFAULT_LAYER_ID;
  },
);

export const getGivenOrCurrentLayerId: ParametricSelector<
  { layerId?: LayerId } | undefined,
  LayerId
> = (state, params) => params?.layerId ?? state.dataset.currentLayerId;

export const currentLayerIsNamedVersionSelector = createSelector(
  currentLayerSelector,
  (currentLayer) => isNamedVersion(currentLayer),
);

export const lastUpdatedAtForCurrentNamedVersionSelector = createSelector(
  currentLayerSelector,
  snapshotsSelector,
  (currentLayer, snapshots) => {
    const snapshotForCurrentLayer = snapshots[currentLayer.id];
    const dateTime = snapshotForCurrentLayer?.metadata?.lastUpdatedAt;
    if (dateTime != null) {
      return DateTime.fromISO(dateTime);
    } else {
      return null;
    }
  },
);
