import { TippyProps } from '@tippyjs/react';

export const NO_FLIP_MODIFIER = {
  name: 'flip',
  options: {
    fallbackPlacements: [],
  },
};

// don't flip the popover when the user scrolls away
export const NO_FLIP_POPPER_OPTIONS: TippyProps['popperOptions'] = {
  modifiers: [NO_FLIP_MODIFIER],
};

export const FLIP_POPPER_OPTIONS = {
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top-start'],
      },
    },
  ],
};
