import { Middleware, isAction } from '@reduxjs/toolkit';

import {
  printSelectorProfile,
  printSelectorProfileIfChanged,
  resetSelectorProfile,
} from 'helpers/selectorProfiling';
import { RootState } from 'reduxStore/reducers/sliceReducers';

export const timeActions: Middleware<unknown, RootState> =
  (_store) => (next) => (action: unknown) => {
    if (!isAction(action)) {
      return next(action);
    }

    resetSelectorProfile();
    performance.mark(`${action.type}_start`);
    const result = next(action);
    performance.mark(`${action.type}_end`);
    const measure = performance.measure(
      `${action.type}`,
      `${action.type}_start`,
      `${action.type}_end`,
    );
    printSelectorProfile(`${action.type} (${measure.duration}ms)`);
    printSelectorProfileIfChanged();
    return result;
  };
