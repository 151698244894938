import { Spinner } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import ImpactBadge from 'components/ImpactBadge/ImpactBadge';
import { extractMonthKey, getDateTimeFromMonthKey } from 'helpers/dates';
import { getBeforeAfterImpactDisplay } from 'helpers/events';
import useAppSelector from 'hooks/useAppSelector';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { useSmoothedNumber } from 'hooks/useSmoothedTimeSeries';
import { DriverEvent } from 'reduxStore/models/events';
import {
  entityLoadingForMonthKeySelector,
  impactLoadingForMonthKeySelector,
} from 'selectors/calculationsSelector';
import {
  driverTimeSeriesWithoutLiveEditsSelector,
  driverValueForImpactDisplaySelector,
} from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';

interface Props {
  event: DriverEvent;
}

const DriverBeforeAfterImpactBadge: React.FC<Props> = ({ event }) => {
  const { driverId, end } = event;
  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );
  const endMonthKey = extractMonthKey(end);
  const beforeValue = useAppSelector((state) =>
    driverValueForImpactDisplaySelector(state, {
      driverId,
      monthKey: endMonthKey,
      eventIdToIgnore: event.id,
    }),
  );
  const afterValueWithoutDragData = useAppSelector(
    (state) =>
      driverTimeSeriesWithoutLiveEditsSelector(state, {
        driverId,
        start: endMonthKey,
        end: endMonthKey,
      })[endMonthKey],
  );

  const isDriverValueLoading = useAppSelector((state) =>
    entityLoadingForMonthKeySelector(state, { id: driverId, monthKey: endMonthKey }),
  );

  const ignoreEventIds = useMemo(() => {
    return [event.id];
  }, [event.id]);
  const isImpactLoading = useAppSelector((state) =>
    impactLoadingForMonthKeySelector(state, {
      id: driverId,
      monthKey: endMonthKey,
      ignoreEventIds,
    }),
  );

  const smoothedBeforeValue = useSmoothedNumber(beforeValue, isDriverValueLoading);
  const smoothedAfterValue = useSmoothedNumber(afterValueWithoutDragData, isImpactLoading);

  const dateRange = useMemo(() => {
    const dt = getDateTimeFromMonthKey(endMonthKey);
    return [dt, dt] as [DateTime, DateTime];
  }, [endMonthKey]);

  useRequestCellValue({ id: driverId, type: 'driver', dateRange, ignoreEventIds });
  useRequestCellValue({ id: driverId, type: 'driver', dateRange });

  if (smoothedBeforeValue == null || smoothedAfterValue == null) {
    if (isDriverValueLoading || isImpactLoading) {
      return <Spinner size="xs" />;
    }
    return null;
  }

  const { fullDisplayString, abbreviatedDisplayString } = getBeforeAfterImpactDisplay(
    smoothedBeforeValue,
    smoothedAfterValue,
    displayConfiguration,
  );

  return (
    <ImpactBadge
      fullDisplayString={fullDisplayString}
      abbreviatedString={abbreviatedDisplayString}
    />
  );
};

export default React.memo(DriverBeforeAfterImpactBadge);
