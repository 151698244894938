import { Center, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { useDriverValueChart } from 'components/DriverValueChart/useDriverValueChart';
import { DriverId } from 'reduxStore/models/drivers';

interface Props {
  driverId: DriverId;
}
const DriverValueChart: React.FC<Props> = ({ driverId }) => {
  const {
    driverValueLoading,
    currentFormattedValue,
    prevFormattedString,
    percentDiff,
    formattedPercentDiff,
  } = useDriverValueChart(driverId);

  return (
    <ValueChart
      isLoading={driverValueLoading}
      currentFormattedValue={currentFormattedValue}
      previousFormattedValue={prevFormattedString}
      percentDiff={percentDiff}
      formattedPercentDiff={formattedPercentDiff}
    />
  );
};

interface ValueChartProps {
  isLoading: boolean;
  currentFormattedValue: string;
  previousFormattedValue: string;
  percentDiff: number | null;
  formattedPercentDiff: string | null;
}
export const ValueChart = ({
  isLoading,
  currentFormattedValue,
  previousFormattedValue,
  percentDiff,
  formattedPercentDiff,
}: ValueChartProps) => (
  <Center h="full">
    {isLoading ? (
      <Spinner color="gray.500" />
    ) : (
      <VStack>
        <HStack>
          <Text data-sentry-mask fontSize="4xl">
            {currentFormattedValue}
          </Text>
          {percentDiff != null && formattedPercentDiff != null && (
            <HStack>
              <Text
                justifySelf="start"
                color={percentDiff > 0 ? 'green.500' : 'red.500'}
                fontSize="lg"
              >
                {percentDiff > 0 ? '+' : '-'}
                {formattedPercentDiff}
              </Text>
            </HStack>
          )}
        </HStack>
        <Text data-sentry-mask textAlign="center" color="gray.400" fontSize="lg">
          {previousFormattedValue}
        </Text>
      </VStack>
    )}
  </Center>
);
export default React.memo(DriverValueChart);
