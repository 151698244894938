import { useCallback } from 'react';

import useAppDispatch from 'hooks/useAppDispatch';
import { closeCustomizeMenu } from 'reduxStore/reducers/customizeMenuSlice';

export default function useCloseCustomizeMenu() {
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(closeCustomizeMenu());
  }, [dispatch]);
  return onClose;
}
