import { getModelPage } from 'config/internalPages/modelPage';
import {
  BlockCreateInput,
  BlocksPageCreateInput,
  BlocksPageUpdateInput,
  DatasetMutationInput,
  DriverUpdateInput,
  SubmodelCreateInput,
  SubmodelUpdateInput,
} from 'generated/graphql';
import { bulkInsertSortIndexUpdates } from 'helpers/reorderList';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { navigateToDefaultPage, navigateToSubmodelPage } from 'reduxStore/actions/navigateTo';
import {
  addDefaultLayerGuestControlEntryToPage,
  removeDefaultLayerGuestControlEntryFromPage,
} from 'reduxStore/actions/permissions';
import { submitAutoLayerizedMutations } from 'reduxStore/actions/submitDatasetMutation';
import { BlockId, BlocksPageId } from 'reduxStore/models/blocks';
import { DriverGroupIdOrNull } from 'reduxStore/models/driverGroup';
import { SubmodelId } from 'reduxStore/models/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { navSubmodelIdSelector } from 'selectors/navSubmodelSelector';
import {
  blockIdBySubmodelIdSelector,
  orderedSubmodelPagesSelector,
  submodelPageFromSubmodelIdSelector,
} from 'selectors/submodelPageSelector';
import { driversBySubmodelIdSelector } from 'selectors/submodelSelector';
import { submodelTableGroupsSelector } from 'selectors/submodelTableGroupsSelector';

export const createSubmodelAndBlocksMutation = (
  state: RootState,
  args: Required<Pick<SubmodelCreateInput, 'name' | 'id'>> & {
    pageId: BlocksPageId;
    blockId?: BlockId;
    parent?: string | null;
  },
): {
  currentLayerMutation: DatasetMutationInput & { newSubmodels: SubmodelCreateInput[] };
  defaultLayerMutation: DatasetMutationInput & {
    newBlocksPages: BlocksPageCreateInput[];
    newBlocks: BlockCreateInput[];
    updateBlocksPages: BlocksPageUpdateInput[];
  };
} => {
  const { name, id, pageId, blockId, parent } = args;
  const authenticatedUser = authenticatedUserSelector(state);

  const { blocksPageCreateInput, blocksCreateInputs } = getModelPage({
    pageId,
    name,
    submodelId: id,
    blockId,
    createdByUserId: authenticatedUser?.id,
  });
  if (parent !== null) {
    blocksPageCreateInput.parent = parent;
  }
  const submodelPages = orderedSubmodelPagesSelector(state);
  const orderUpdates = bulkInsertSortIndexUpdates(submodelPages, [blocksPageCreateInput], 'after');
  const sortIndexUpdates = Object.entries(orderUpdates).map(([pageIdToUpdate, sIndex]) => ({
    id: pageIdToUpdate,
    sortIndex: sIndex,
  }));

  return {
    defaultLayerMutation: {
      newBlocksPages: [blocksPageCreateInput],
      newBlocks: blocksCreateInputs,
      updateBlocksPages: sortIndexUpdates,
    },
    currentLayerMutation: {
      newSubmodels: [{ id }],
    },
  };
};

const mutationActions = {
  createSubmodelAndBlocks: (
    args: Required<Pick<SubmodelCreateInput, 'name' | 'id'>> & {
      pageId: BlocksPageId;
      blockId?: BlockId;
      parent?: string | null;
    },
  ): AppThunk<void> => {
    return (dispatch, getState) => {
      const mutations = createSubmodelAndBlocksMutation(getState(), args);
      dispatch(
        submitAutoLayerizedMutations('create-submodel', [
          mutations.defaultLayerMutation,
          mutations.currentLayerMutation,
        ]),
      );
    };
  },
  createAndNavigateToSubmodel: ({
    name,
    parent = null,
  }: {
    name: string;
    parent?: string | null;
  }): AppThunk<void> => {
    return (dispatch) => {
      const id = uuidv4();
      const pageId = uuidv4();

      dispatch(mutationActions.createSubmodelAndBlocks({ name, id, pageId, parent }));
      dispatch(addDefaultLayerGuestControlEntryToPage(pageId));
      dispatch(navigateToSubmodelPage(id));
    };
  },
  updateSubmodel: (updateSubmodel: SubmodelUpdateInput): AppThunk => {
    return (dispatch) => {
      dispatch(
        submitAutoLayerizedMutations('update-submodel', [{ updateSubmodels: [updateSubmodel] }]),
      );
    };
  },
  deleteSubmodel: ({ submodelId }: { submodelId: SubmodelId }): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const navSubmodelId = navSubmodelIdSelector(state);
      const submodelBlockId = blockIdBySubmodelIdSelector(state)[submodelId];
      const submodelPage = submodelPageFromSubmodelIdSelector(state, submodelId);
      const submodelDrivers = driversBySubmodelIdSelector(state)[submodelId];

      const driverUpdates: DriverUpdateInput[] = (submodelDrivers ?? [])
        ?.map((driver) => {
          const newDriverReferences =
            driver.driverReferences?.filter((ref) => ref.blockId !== submodelBlockId) ?? [];

          if (newDriverReferences.length === (driver.driverReferences ?? []).length) {
            return null;
          }

          return {
            id: driver.id,
            removeSubmodelId: true,
            driverReferences: newDriverReferences,
          };
        })
        .filter(isNotNull);

      const isSelected = submodelId === navSubmodelId;
      dispatch(
        submitAutoLayerizedMutations('delete-submodel', [
          {
            deleteSubmodels: [{ id: submodelId, deleteDrivers: false }],
            updateDrivers: driverUpdates,
          },
        ]),
      );

      if (submodelPage?.id != null) {
        dispatch(removeDefaultLayerGuestControlEntryFromPage(submodelPage.id));
      }

      if (isSelected) {
        dispatch(navigateToDefaultPage());
      }
    };
  },
  updateSortedDriverGroupIdsForNavSubmodel: ({
    groupId,
    beforeId,
    afterId,
  }: {
    groupId: DriverGroupIdOrNull;
    beforeId?: DriverGroupIdOrNull | 'bottom';
    afterId?: DriverGroupIdOrNull | 'bottom';
  }): AppThunk => {
    return (dispatch, getState) => {
      if (groupId === beforeId || groupId === afterId) {
        return;
      }

      const state = getState();
      const submodelId = navSubmodelIdSelector(state);

      if (submodelId == null) {
        return;
      }

      const groupWithDrivers = submodelTableGroupsSelector(state);
      const sortedDriverGroupIds = groupWithDrivers
        .map(({ id }) => id ?? null)
        .filter((gId) => gId !== groupId);

      let insertionIndex = -1;
      if (afterId != null) {
        if (afterId === 'bottom') {
          insertionIndex = sortedDriverGroupIds.length;
        } else {
          const indexOf = sortedDriverGroupIds.indexOf(afterId);
          if (indexOf !== -1) {
            insertionIndex = indexOf + 1;
          }
        }
      } else if (beforeId != null) {
        insertionIndex =
          beforeId === 'bottom'
            ? sortedDriverGroupIds.length - 1
            : sortedDriverGroupIds.indexOf(beforeId);
      }
      if (insertionIndex === -1) {
        return;
      }
      sortedDriverGroupIds.splice(insertionIndex, 0, groupId);
      dispatch(mutationActions.updateSubmodel({ id: submodelId, sortedDriverGroupIds }));
    };
  },
};

export const {
  createAndNavigateToSubmodel,
  deleteSubmodel,
  updateSortedDriverGroupIdsForNavSubmodel,
} = mutationActions;
