import { partition } from 'lodash';
import { DateTime } from 'luxon';

import { WithEventGroup, eventGroupIdFromWithEventGroup } from '@features/Plans';
import {
  DriverEventEntity,
  EventEntity,
  ObjectFieldEventEntity,
} from '@features/Plans/EventEntity';
import {
  BusinessObjectUpdateInput,
  CurveType,
  DatasetMutationInput,
  EventCreateInput,
  EventDeleteInput,
  EventGroupCreateInput,
  EventGroupDeleteInput,
  EventGroupUpdateInput,
  EventUpdateInput,
  ImpactType,
  Maybe,
  ModifierType,
  ValueType,
} from 'generated/graphql';
import {
  getDateTimeFromMonthKey,
  getISOTimeWithoutMs,
  getISOTimeWithoutMsFromMonthKey,
} from 'helpers/dates';
import {
  getDeltaMonthImpact,
  getEndFromMonthKey,
  getSortedEventGroupsForEntityAtMonthKeys,
  getStartFromMonthKey,
  getUpdateFromEvents,
} from 'helpers/events';
import { convertTimeSeriesToGql } from 'helpers/gqlDataset';
import { mergeAllMutations, mergeMutations } from 'helpers/mergeMutations';
import { getObjectFieldUUID } from 'helpers/object';
import {
  backfillSiblingSortIndexMutations,
  peekMutationStateChange,
  sortIndexMutationsForInsertion,
  stripInsertBeforeId,
} from 'helpers/sortIndex';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { updateEventGroups } from 'reduxStore/actions/eventGroupMutations';
import { selectPlanTimelineEntityRowForEvent } from 'reduxStore/actions/liveEdit';
import {
  getMutationThunkAction,
  submitAutoLayerizedMutations,
} from 'reduxStore/actions/submitDatasetMutation';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectFieldId, BusinessObjectId } from 'reduxStore/models/businessObjects';
import {
  CurvePointTyped,
  DEFAULT_EVENT_GROUP_ID,
  Event,
  EventGroupId,
  EventId,
  isDeltaImpactEvent,
  isDriverEvent,
  isObjectFieldEvent,
} from 'reduxStore/models/events';
import { ValueTimeSeriesWithEmpty } from 'reduxStore/models/timeSeries';
import { Value } from 'reduxStore/models/value';
import bulkCreateEventsAndGroupsMutations, {
  BulkCreateEventsAndGroupsReturnType,
  getDeleteEventsToClearStackedEvents,
} from 'reduxStore/reducers/helpers/bulkCreateEventsAndGroupsMutations';
import { defaultValueForField } from 'reduxStore/reducers/helpers/businessObjectSpecs';
import { NO_PARENT_ID, WILL_CLEAR_CURVE_POINTS_WARNING } from 'reduxStore/reducers/helpers/events';
import { LiveEditType, setEventLiveEditWarning } from 'reduxStore/reducers/liveEditSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import {
  businessObjectFieldByFieldIdSelector,
  businessObjectFieldSelector,
  businessObjectFieldSpecByIdSelector,
  businessObjectFieldSpecForFieldIdSelector,
  businessObjectFieldSpecSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectFieldValueForMonthKeySelector } from 'selectors/businessObjectTimeSeriesSelector';
import {
  businessObjectsByIdForLayerSelector,
  materializedFieldForSpecIdAndObjectIdSelector,
} from 'selectors/businessObjectsSelector';
import {
  driverTimeSeriesSelector,
  driverValueForMonthKeySelector,
} from 'selectors/driverTimeSeriesSelector';
import { driverSelector } from 'selectors/driversSelector';
import {
  eventGroupSelector,
  eventLiveEditWillClearCurvePointsSelector,
  eventWithoutLiveEditsSelector,
  eventsByIdForLayerSelector,
  eventsForDriverSelector,
  eventsForObjectFieldSelector,
  eventsImpactingEventEntityAndMonthKeySelector,
  parentIdsByEventAndGroupIdsSelector,
  populatedEventGroupSelector,
  visibleEventsByObjectFieldIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';
import { liveEditSelector, liveEditingSingleEventSelector } from 'selectors/liveEditSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import {
  selectedEventGroupIdsWithoutSelectedParentSelector,
  selectedEventIdsWithoutSelectedParentSelector,
} from 'selectors/selectedEventSelector';
import { prevailingSelectionBlockIdSelector } from 'selectors/selectionSelector';
import { MonthKey } from 'types/datetime';

function cleanTimestamps<T extends { start?: Maybe<string>; end?: Maybe<string> }>(orig: T) {
  // N.B. we clean these timestamps to avoid having milliseconds because it causes
  // problems in serialization with the backend.
  const copy = { ...orig };
  if (orig.start != null) {
    copy.start = getISOTimeWithoutMs(orig.start);
  }

  if (orig.end != null) {
    copy.end = getISOTimeWithoutMs(orig.end);
  }
  return copy;
}

type InsertBeforeId = EventId | EventGroupId | 'end';

// These are the core mutations that everything else should use if possible
const mutationActions = {
  bulkCreateEventsAndGroups: getMutationThunkAction<{
    events: EventCreateInput[];
    groups: EventGroupCreateInput[];
    // Any extra mutation actions that need to be in the same batch
    extras?: DatasetMutationInput;
  }>((newItems, getState) => {
    return bulkCreateEventsAndGroupsMutations(getState(), newItems);
  }),
  deleteEventIds: getMutationThunkAction<EventId[]>((eventIds) =>
    getDeleteMutation(
      eventIds.map((id) => ({ id })),
      [],
    ),
  ),
  deleteEventsAndGroups: getMutationThunkAction<{
    events: EventDeleteInput[];
    groups: EventGroupDeleteInput[];
  }>(({ events, groups }) => getDeleteMutation(events, groups)),
  updateEventsAndGroupsVisibility: getMutationThunkAction<{
    eventUpdates: Array<Pick<EventUpdateInput, 'id' | 'hidden'>>;
    eventGroupUpdates: Array<Pick<EventGroupUpdateInput, 'id' | 'hidden'>>;
  }>(({ eventUpdates, eventGroupUpdates }) => {
    return {
      updateEvents: eventUpdates,
      updateEventGroups: eventGroupUpdates,
    };
  }),
  updateEventsAndGroups: getMutationThunkAction<{
    events: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>;
    groups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>;
  }>(({ events, groups }, getState) => {
    return getUpdateMutation(events, groups, getState());
  }),
  deleteAndUpdateEventsAndGroups: getMutationThunkAction<{
    deleteEvents: EventDeleteInput[];
    deleteGroups: EventGroupDeleteInput[];
    updateEvents: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>;
    updateGroups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>;
  }>(({ updateEvents, updateGroups, deleteEvents, deleteGroups }, getState) => {
    const hasUpdate = updateEvents.length !== 0;
    const hasDelete = deleteEvents.length !== 0;

    if (hasUpdate && hasDelete) {
      const updateMutation = getUpdateMutation(updateEvents, updateGroups, getState());
      const deleteMutation = getDeleteMutation(deleteEvents, deleteGroups);
      return mergeMutations(updateMutation, deleteMutation);
    } else if (hasUpdate) {
      return getUpdateMutation(updateEvents, updateGroups, getState());
    }

    return getDeleteMutation(deleteEvents, deleteGroups);
  }),
};
export const updateEvents =
  (updates: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>): AppThunk =>
  (dispatch) => {
    dispatch(updateEventsAndGroups({ events: updates, groups: [] }));
  };

const getUpdateMutation = (
  events: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>,
  groups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>,
  beforeState: RootState,
): DatasetMutationInput => {
  const eventsCopy = events.map(cleanTimestamps);

  const deleteEvents = getDeleteEventsToClearStackedEvents(beforeState, eventsCopy);

  let mutations: DatasetMutationInput = {
    updateEvents: eventsCopy,
    updateEventGroups: groups,
    deleteEvents,
  };

  // In order to bulk backfill sortIndexes, it is easier to update the state
  // and then apply sort index updates (if necessary) afterwards.
  let state = peekMutationStateChange(beforeState, mutations);

  // N.B. mutations for events are handled before mutations for event groups.
  [...events, ...groups].forEach((update) => {
    const { id, insertBeforeId } = update;

    // If we are not updating the ordering, then we just need to make sure
    // that siblings are backfilled. Otherwise, we also update the relevant
    // entity. We differentiate here to avoid overriding the sort index every
    // time.
    const backfillData =
      insertBeforeId == null
        ? backfillSiblingSortIndexMutations(state, id)
        : sortIndexMutationsForInsertion(state, id, insertBeforeId);
    state = peekMutationStateChange(state, backfillData);
    mutations = mergeMutations(mutations, backfillData);
  });

  return stripInsertBeforeId(mutations);
};

const getDeleteMutation = (
  events: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>,
  groups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>,
): DatasetMutationInput => {
  return {
    deleteEvents: events,
    deleteEventGroups: groups,
  };
};

export const saveNewOrExistingEventLiveEdit = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const liveEditingEvent = liveEditingSingleEventSelector(state);

    // TODO: (T-6673) Handle non driver events
    if (liveEditingEvent == null || !isDriverEvent(liveEditingEvent)) {
      return;
    }

    dispatch(saveLiveEditedEvents());
  };
};

type NewEventBase = {
  monthKey: MonthKey;
  value: string;
  impactType: ImpactType;
};

export type NewEventForDriver = NewEventBase & DriverEventEntity;

export type NewEventForObjectField = NewEventBase & ObjectFieldEventEntity;

// If newEvents includes an event on an object field that doesn't exist yet, we need to add the field manually
function getAddFieldMutations(
  state: RootState,
  newEvents: Array<NewEventForDriver | NewEventForObjectField>,
): DatasetMutationInput {
  const addFieldMutations: Array<Pick<BusinessObjectUpdateInput, 'id' | 'addFields'>> = [];
  // We only need to add each field once, otherwise we'll get an error
  const alreadyAddedFieldIds = new Set();

  newEvents.forEach((e) => {
    if (e.type !== 'objectField') {
      return;
    }

    if (alreadyAddedFieldIds.has(e.objectFieldId)) {
      return;
    }

    const existingField = businessObjectFieldSelector(state, { id: e.objectFieldId });
    if (existingField != null) {
      return;
    }

    const fieldSpec = businessObjectFieldSpecSelector(state, { id: e.fieldSpecId });
    if (fieldSpec == null) {
      return;
    }

    const addFieldMutation = {
      id: e.objectId,
      addFields: [
        {
          fieldSpecId: e.fieldSpecId,
          id: e.objectFieldId,
          value: {
            type: fieldSpec.type,
          },
        },
      ],
    };

    addFieldMutations.push(addFieldMutation);
    alreadyAddedFieldIds.add(e.objectFieldId);
  });

  if (addFieldMutations.length === 0) {
    return {};
  }

  return {
    updateBusinessObjects: addFieldMutations,
  };
}

const getCreateEventInput = (
  state: RootState,
  newEvent: NewEventForDriver | NewEventForObjectField,
  authenticatedUserName: string,
): EventCreateInput | null => {
  const newEventId = uuidv4();

  const { type, impactType, monthKey, value } = newEvent;

  const startDateTime = getDateTimeFromMonthKey(monthKey);
  const endDateTime = startDateTime.endOf('month').startOf('second');

  let valueType: ValueType;
  if (type === 'driver') {
    const driver = driverSelector(state, { id: newEvent.driverId });
    valueType = driver?.valueType ?? ValueType.Number;
  } else {
    const objectSpec = businessObjectFieldSpecForFieldIdSelector(state, {
      id: newEvent.objectFieldId,
    });
    valueType = objectSpec?.type ?? ValueType.Number;
  }

  const createEvent: EventCreateInput = {
    id: newEventId,
    driverId: type === 'driver' ? newEvent.driverId : undefined,
    businessObjectFieldId: type === 'driver' ? undefined : newEvent.objectFieldId,
    start: startDateTime.toISO(),
    end: endDateTime.toISO(),
    modifierType: ModifierType.Add,
    impactType,
    curveType: CurveType.Custom,
    ownerName: authenticatedUserName,
    time: getISOTimeWithoutMsFromMonthKey(monthKey),
    value,
    setValueType: valueType,
  };

  return createEvent;
};

const createNewEventsWithEventGroups = ({
  newEvents,
  extras,
}: {
  newEvents: Array<
    (NewEventForDriver | NewEventForObjectField) & { withEventGroup: WithEventGroup }
  >;
  extras?: DatasetMutationInput;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const authenticatedUser = authenticatedUserSelector(state);
    if (!authenticatedUser) {
      return;
    }

    const newEventGroupsById: Record<EventGroupId, EventGroupCreateInput> = {};

    const createEvents = newEvents
      .map((newEventWithEventGroup) => {
        const { withEventGroup, ...newEvent } = newEventWithEventGroup;

        const createEvent = getCreateEventInput(state, newEvent, authenticatedUser.name);

        if (createEvent == null) {
          return null;
        }

        if (withEventGroup.type === 'existing') {
          createEvent.parentId = withEventGroup.eventGroupId;
        } else if (withEventGroup.type === 'new') {
          createEvent.parentId = withEventGroup.newEventGroup.id;
        } else {
          createEvent.parentId = DEFAULT_EVENT_GROUP_ID;
        }

        const newEventId = createEvent.id;

        if (withEventGroup.type === 'new') {
          if (newEventGroupsById[withEventGroup.newEventGroup.id] != null) {
            newEventGroupsById[withEventGroup.newEventGroup.id].eventIds?.push(newEventId);
          } else {
            newEventGroupsById[withEventGroup.newEventGroup.id] = {
              id: withEventGroup.newEventGroup.id,
              name: withEventGroup.newEventGroup.name,
              ownerName: authenticatedUser.name,
              ownerId: authenticatedUser.id,
              eventIds: [newEventId],
            };
          }
        }

        return createEvent;
      })
      .filter(isNotNull);

    const updateObjectsMutation = getAddFieldMutations(state, newEvents);
    const extraMutationInput =
      extras != null ? mergeMutations(extras, updateObjectsMutation) : updateObjectsMutation;

    const mutation = bulkCreateEventsAndGroupsMutations(state, {
      events: createEvents,
      groups: Object.values(newEventGroupsById),
      extras: extraMutationInput,
    });

    dispatch(submitAutoLayerizedMutations('create-events-and-groups', [mutation]));
  };
};

export const createNewEventsWithEventGroup = ({
  newEvents,
  withEventGroup,
  extras,
}: {
  newEvents: Array<NewEventForDriver | NewEventForObjectField>;
  withEventGroup: WithEventGroup;
  extras?: DatasetMutationInput;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const authenticatedUser = authenticatedUserSelector(state);
    if (!authenticatedUser) {
      return;
    }

    const createEvents = newEvents
      .map((newEvent) => getCreateEventInput(state, newEvent, authenticatedUser.name))
      .filter(isNotNull);

    const updateObjectsMutation = getAddFieldMutations(state, newEvents);
    const extraMutationInput =
      extras != null ? mergeMutations(extras, updateObjectsMutation) : updateObjectsMutation;

    const mutation = createNewEventsMutation({
      state,
      newEvents: createEvents,
      withEventGroup,
      extras: extraMutationInput,
    });

    if (mutation == null) {
      return;
    }

    dispatch(submitAutoLayerizedMutations('create-events-and-group', [mutation]));
  };
};

export const createNewEventsMutation = ({
  state,
  newEvents,
  withEventGroup,
  extras,
}: {
  state: RootState;
  newEvents: EventCreateInput[];
  withEventGroup: WithEventGroup;
  // These mutations will be returned along with the newEvent mutations.
  // These could be important because bulkCreateEventsAndGroupsMutations
  // needs to know the up-to-date state of the dataset in order to generate
  // the mutation properly (e.g. knowing about newly added fields to objects).
  extras?: DatasetMutationInput;
}) => {
  const authenticatedUser = authenticatedUserSelector(state);
  if (!authenticatedUser) {
    return undefined;
  }

  if (withEventGroup.type === 'existing') {
    newEvents.forEach((e) => {
      e.parentId = withEventGroup.eventGroupId;
    });
    return bulkCreateEventsAndGroupsMutations(state, { events: newEvents, groups: [], extras });
  }

  if (withEventGroup.type === 'new') {
    const { newEventGroup } = withEventGroup;
    return bulkCreateEventsAndGroupsMutations(state, {
      events: newEvents,
      groups: [
        {
          id: newEventGroup.id,
          name: newEventGroup.name,
          ownerName: authenticatedUser.name,
          ownerId: authenticatedUser.id,
          eventIds: newEvents.map((e) => e.id),
        },
      ],
      extras,
    });
  }

  // Default event group
  // N.B. We assume the default event group exists. If it doesn't, it will get created automatically.
  newEvents.forEach((e) => {
    e.parentId = DEFAULT_EVENT_GROUP_ID;
  });
  return bulkCreateEventsAndGroupsMutations(state, {
    events: newEvents,
    groups: [],
    extras,
  });
};

export const updateMultiImpactEventParentEventGroup = ({
  eventId,
  withEventGroup,
  newImpact,
  eventIdsToDelete,
}: {
  eventId: EventId;
  withEventGroup: WithEventGroup;
  newImpact?: {
    monthKey: string;
    value: number;
  };
  eventIdsToDelete?: EventId[];
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const authenticatedUser = authenticatedUserSelector(state);
    if (!authenticatedUser) {
      return;
    }

    const eventGroupId =
      withEventGroup.type === 'existing'
        ? withEventGroup.eventGroupId
        : withEventGroup.type === 'new'
          ? withEventGroup.newEventGroup.id
          : DEFAULT_EVENT_GROUP_ID;

    const updateEventInput: EventUpdateInput = {
      id: eventId,
      parentId: eventGroupId,
      ...(newImpact != null
        ? {
            time: newImpact.monthKey,
            value: `${newImpact.value}`,
          }
        : {}),
    };

    const deleteEvents: EventDeleteInput[] = eventIdsToDelete?.map((id) => ({ id })) ?? [];

    const createEventGroupInput: EventGroupCreateInput | null =
      withEventGroup.type === 'new'
        ? {
            id: withEventGroup.newEventGroup.id,
            name: withEventGroup.newEventGroup.name,
            ownerName: authenticatedUser.name,
            ownerId: authenticatedUser.id,
            eventIds: [eventId],
          }
        : null;

    dispatch(
      bulkCreateEventsAndGroups({
        events: [],
        groups: createEventGroupInput != null ? [createEventGroupInput] : [],
        extras: {
          updateEvents: [updateEventInput],
          deleteEvents,
        },
      }),
    );
  };
};

function createNewBusinessObjectFieldEventMutation({
  state,
  newEvent: newEventInput,
  withEventGroup,
}: {
  state: RootState;
  newEvent: {
    fieldIdOrUndefined?: BusinessObjectFieldId;
    businessObjectId: BusinessObjectId;
    businessObjectFieldSpecId: BusinessObjectFieldSpecId;
    monthKey: MonthKey;
    value: Value;
  };
  withEventGroup: WithEventGroup;
}) {
  const { fieldIdOrUndefined, businessObjectId, businessObjectFieldSpecId, monthKey, value } =
    newEventInput;

  const authenticatedUser = authenticatedUserSelector(state);
  if (authenticatedUser == null) {
    return undefined;
  }
  const ownerName = authenticatedUser.name;

  const businessObject = businessObjectsByIdForLayerSelector(state)[businessObjectId];
  const businessObjectFieldSpec =
    businessObjectFieldSpecByIdSelector(state)[businessObjectFieldSpecId];
  if (businessObjectFieldSpec == null) {
    throw new Error(
      `Error creating field event for deleted field spec ${businessObjectFieldSpecId}`,
    );
  }

  let newFieldId: BusinessObjectFieldId | undefined;
  if (fieldIdOrUndefined == null) {
    newFieldId = getObjectFieldUUID(businessObject.id, businessObjectFieldSpecId);
  } else {
    const field = businessObjectFieldSelector(state, { id: fieldIdOrUndefined });
    if (field == null) {
      newFieldId = fieldIdOrUndefined;
    }
  }

  const newEvent: EventCreateInput = {
    id: uuidv4(),
    businessObjectFieldId: fieldIdOrUndefined ?? newFieldId,
    start: getStartFromMonthKey(monthKey),
    end: getEndFromMonthKey(monthKey),
    ownerName,
    time: getISOTimeWithoutMsFromMonthKey(monthKey),
    value: `${value.value}`,
    setValueType: value.type,
  };

  let updateBusinessObject: BusinessObjectUpdateInput | undefined;

  // If no field exists for the given business object and field spec, then we need to
  // create one that will correspond to the newly created event.
  if (newFieldId != null) {
    const defaultValue = defaultValueForField(businessObjectFieldSpec);
    updateBusinessObject = {
      id: businessObject.id,
      ...(newFieldId != null
        ? {
            addFields: [
              {
                id: newFieldId,
                fieldSpecId: businessObjectFieldSpecId,
                value: {
                  type: businessObjectFieldSpec.type,
                  initialValue:
                    defaultValue?.value != null ? String(defaultValue.value) : undefined,
                },
              },
            ],
          }
        : {}),
    };
  }

  return createNewEventsMutation({
    state,
    newEvents: [newEvent],
    withEventGroup,
    extras: {
      updateBusinessObjects: updateBusinessObject != null ? [updateBusinessObject] : [],
    },
  });
}

const createNewBusinessObjectFieldEvent =
  ({
    newGroup,
    ...props
  }: {
    fieldIdOrUndefined?: BusinessObjectFieldId;
    businessObjectId: BusinessObjectId;
    businessObjectFieldSpecId: BusinessObjectFieldSpecId;
    monthKey: MonthKey;
    value: Value;
    newGroup?: {
      name: string;
      id: EventGroupId;
    };
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const withEventGroup: WithEventGroup =
      newGroup != null
        ? {
            type: 'new',
            newEventGroup: newGroup,
          }
        : {
            type: 'default',
          };
    const mutation = createNewBusinessObjectFieldEventMutation({
      state,
      newEvent: props,
      withEventGroup,
    });
    if (mutation == null) {
      return;
    }
    dispatch(submitAutoLayerizedMutations('create-business-object-field', [mutation]));
  };

export const updateObjectFieldForecastMutation = (
  state: RootState,
  id: BusinessObjectFieldId,
  values: ValueTimeSeriesWithEmpty,
  eventGroupId?: EventGroupId,
): DatasetMutationInput | undefined => {
  const authenticatedUser = authenticatedUserSelector(state);
  if (authenticatedUser == null) {
    return undefined;
  }

  const fieldsById = businessObjectFieldByFieldIdSelector(state);
  const field = fieldsById[id];
  if (field == null) {
    return undefined;
  }

  const lastActualsMonthKey = datasetLastActualsMonthKeySelector(state);
  const forecastMonthKeys = Object.keys(values).filter((mk) => mk > lastActualsMonthKey);

  const eventUpdates: EventUpdateInput[] = [];
  const newEventMutations: BulkCreateEventsAndGroupsReturnType[] = [];
  const eventDeletes: EventDeleteInput[] = [];

  forecastMonthKeys.forEach((mk) => {
    const value = values[mk];

    if (value == null) {
      // Should never happen
      return;
    }

    const existingEvents = eventsImpactingEventEntityAndMonthKeySelector(state, {
      type: 'objectField',
      objectId: field.objectId,
      fieldSpecId: field.fieldSpecId,
      objectFieldId: id,
      monthKey: mk,
    });

    if (existingEvents.length > 0) {
      eventUpdates.push(updateEventImpactsMutation(state, existingEvents[0].id, mk, value));
      eventDeletes.push(...existingEvents.slice(1).map((e) => ({ id: e.id })));
    } else {
      const newEventMutation = createNewBusinessObjectFieldEventMutation({
        state,
        newEvent: {
          fieldIdOrUndefined: id,
          businessObjectId: field.objectId,
          businessObjectFieldSpecId: field.fieldSpecId,
          monthKey: mk,
          value,
        },
        withEventGroup:
          eventGroupId != null ? { type: 'existing', eventGroupId } : { type: 'default' },
      });

      if (newEventMutation == null) {
        return;
      }

      newEventMutations.push(newEventMutation);
    }
  });

  return mergeAllMutations([
    ...newEventMutations,
    {
      updateEvents: eventUpdates,
      deleteEvents: eventDeletes.length > 0 ? eventDeletes : undefined,
    },
  ]);
};

export const updateObjectEventImpactDiffFromCell = ({
  businessObjectId,
  businessObjectFieldSpecId,
  monthKey,
  value,
}: {
  businessObjectId: BusinessObjectId;
  businessObjectFieldSpecId: BusinessObjectFieldSpecId;
  monthKey: MonthKey;
  value: Value;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const field = materializedFieldForSpecIdAndObjectIdSelector(state, {
      businessObjectId,
      businessObjectFieldSpecId,
    });

    const groupIds =
      field == null
        ? []
        : getSortedEventGroupsForEntityAtMonthKeys(
            visibleEventsByObjectFieldIdForLayerSelector(state),
            [field?.id],
            [monthKey],
            DEFAULT_EVENT_GROUP_ID,
          );

    // TODO(T-15297) choose event in a more sensible way, e.g. most recent updated event/plan
    const eventGroupId = safeObjGet(groupIds[0]);

    const eventGroup =
      eventGroupId == null ? null : populatedEventGroupSelector(state, eventGroupId);
    const existingEventOnField = eventGroup?.events.find(
      (ev) => isObjectFieldEvent(ev) && ev.businessObjectFieldId === field?.id,
    );

    if (existingEventOnField != null) {
      dispatch(updateEventImpact(existingEventOnField.id, monthKey, value));
    } else {
      dispatch(
        createNewBusinessObjectFieldEvent({
          fieldIdOrUndefined: field?.id,
          businessObjectId,
          businessObjectFieldSpecId,
          monthKey,
          value,
        }),
      );
    }
  };
};

// Uses a given Event as the template from which to create a new Event with a
// single curve point using the given monthKey and value.
function createEventInputWithSingleCurvePointFromBase(
  baseEvent: Event,
  monthKey: MonthKey,
  value: Value,
): EventCreateInput {
  const { version: _, ...baseEventProperties } = baseEvent;

  const start = getISOTimeWithoutMsFromMonthKey(monthKey);
  const end = DateTime.fromISO(start).endOf('month').toISO();

  const curvePointRelatedFields = {
    start,
    end,
    customCurvePoints: convertTimeSeriesToGql({
      [monthKey]: value,
    }),
  };

  if (baseEventProperties.impactType === ImpactType.Delta) {
    return {
      ...baseEventProperties,
      ...curvePointRelatedFields,
      totalImpact:
        baseEventProperties.totalImpact != null ? `${baseEventProperties.totalImpact}` : undefined,
    };
  }

  const { valueType: _valueType, ...rest } = baseEventProperties;

  return {
    ...rest,
    ...curvePointRelatedFields,
  };
}

function getNewStretchEvents(
  originalEvent: Event,
  updatedEvent: Event,
): { newEvents: EventCreateInput[]; newValue: Value } {
  const originalCurvePointMonthKeys = Object.keys(originalEvent.customCurvePoints ?? {});
  const updatedCurvePointsLength = Object.keys(updatedEvent.customCurvePoints ?? {}).length;
  const originalCurvePointMonthKey = originalCurvePointMonthKeys[0];
  const originalValue = (originalEvent.customCurvePoints ?? {})[originalCurvePointMonthKey];

  if (originalValue.type !== ValueType.Number) {
    return { newEvents: [], newValue: originalValue };
  }

  // For deltas we spread the original value over the # of newly stretched months.
  // For sets, we just use the original value
  const newValue: Value = {
    type: ValueType.Number,
    value:
      originalEvent.impactType === ImpactType.Delta
        ? originalValue.value / updatedCurvePointsLength
        : originalValue.value,
  };

  const newEvents = Object.keys(updatedEvent.customCurvePoints ?? {})
    .map((monthKey) => {
      if (originalEvent.customCurvePoints?.[monthKey] != null) {
        // Skip months that are already in the original event
        return null;
      }

      // Add new events for the newly stretched months. Use the properties from the
      // original curve point
      const newEvent: EventCreateInput = {
        ...createEventInputWithSingleCurvePointFromBase(originalEvent, monthKey, newValue),
        id: uuidv4(),
      };

      return newEvent;
    })
    .filter(isNotNull);

  return { newEvents, newValue };
}

export const saveLiveEditedEvents = ({
  shouldWarnToRemoveCurvePoints = false,
}: {
  shouldWarnToRemoveCurvePoints?: boolean;
} = {}): AppThunk => {
  return (dispatch, getState) => {
    let state = getState();
    const liveEdit = liveEditSelector(state);
    if (
      liveEdit == null ||
      liveEdit.type === LiveEditType.Forecast ||
      liveEdit.type === LiveEditType.BlockColumnResize
    ) {
      return;
    }

    if (liveEdit.type === LiveEditType.EventGroup) {
      dispatch(updateEventGroups(liveEdit.updates));
      return;
    }

    const updatedEvents =
      liveEdit.type === LiveEditType.MultiEvent ? liveEdit.events : [liveEdit.event];

    if (shouldWarnToRemoveCurvePoints) {
      const willClearCurvePoints = eventLiveEditWillClearCurvePointsSelector(state);

      if (willClearCurvePoints) {
        dispatch(
          setEventLiveEditWarning({
            warning: WILL_CLEAR_CURVE_POINTS_WARNING,
          }),
        );
        return;
      }
    }

    const mutationBatch = updatedEvents.reduce(
      (batch, updatedEvent) => {
        const originalEvent = eventWithoutLiveEditsSelector(state, updatedEvent.id);
        if (updatedEvent == null || originalEvent == null) {
          return batch;
        }

        const mutation = getUpdateFromEvents(originalEvent, updatedEvent);
        if (mutation == null) {
          return batch;
        }

        if ('update' in mutation) {
          const originalCurvePointMonthKeys = Object.keys(originalEvent.customCurvePoints ?? {});
          const updatedCurvePointsLength = Object.keys(updatedEvent.customCurvePoints ?? {}).length;
          const shouldCreateNewEvent =
            originalCurvePointMonthKeys.length === 1 &&
            updatedCurvePointsLength > originalCurvePointMonthKeys.length;

          if (shouldCreateNewEvent) {
            // This handles "stretching" events on the plan timeline, and we only want to use this behavior
            //  when the event only spans 1 month. If the event spans multiple months, we just use the
            // original "stretching" behavior.
            const { newEvents, newValue } = getNewStretchEvents(originalEvent, updatedEvent);

            if (newEvents.length > 0) {
              batch.creates.push(...newEvents);
              // We also need to update the original curve point's value to be the new value.
              const originalCurvePointMonthKey = originalCurvePointMonthKeys[0];
              const modifiedUpdate: EventUpdateInput = {
                ...mutation.update,
                time: getISOTimeWithoutMsFromMonthKey(originalCurvePointMonthKey),
                value: `${newValue.value}`,
              };
              batch.updates.push(modifiedUpdate);
            }
          } else {
            batch.updates.push(mutation.update);
          }
        }

        return batch;
      },
      {
        updates: [],
        creates: [],
      } as {
        updates: EventUpdateInput[];
        creates: EventCreateInput[];
      },
    );

    const createMutations =
      mutationBatch.creates.length > 0
        ? bulkCreateEventsAndGroupsMutations(getState(), {
            events: mutationBatch.creates,
            groups: [],
          })
        : {};
    const updateMutations =
      mutationBatch.updates.length > 0
        ? getUpdateMutation(mutationBatch.updates, [], getState())
        : {};

    dispatch(
      submitAutoLayerizedMutations('update-and-create-events', [createMutations, updateMutations]),
    );

    // If a new event was created, select the entire entity

    const createdEventIds = createMutations.newEvents?.map((event) => event.id) ?? [];

    if (createdEventIds.length === 0) {
      return;
    }

    state = getState();

    const blockId = prevailingSelectionBlockIdSelector(state);
    if (blockId == null) {
      return;
    }

    dispatch(
      selectPlanTimelineEntityRowForEvent({
        blockId,
        // All the new events should belong to the same entity, so just use the first one
        eventId: createdEventIds[0],
      }),
    );
  };
};

const updateEventImpactsMutation = (
  state: RootState,
  eventId: EventId,
  monthKey: MonthKey,
  value: Value,
): EventUpdateInput => {
  const eventsById = eventsByIdForLayerSelector(state);
  const event = eventsById[eventId];

  const makeCustom = isDeltaImpactEvent(event) && event.curveType !== CurveType.Custom;
  const eventUpdate: EventUpdateInput = {
    id: event.id,
    curveType: makeCustom ? CurveType.Custom : undefined,
    impactType: event.impactType,
    time: getISOTimeWithoutMsFromMonthKey(monthKey),
    value: `${value.value}`,
  };

  if (isDeltaImpactEvent(event) && value.type === ValueType.Number) {
    const { driverId } = event;
    const driverTimeSeries = driverTimeSeriesSelector(state, { id: driverId });

    const currentValue = driverTimeSeries[monthKey];
    const currentImpact = getDeltaMonthImpact(event, monthKey) ?? 0;
    const currentValueWithoutImpact = currentValue - currentImpact;
    const newValue = value.value - currentValueWithoutImpact;
    eventUpdate.value = `${newValue}`;
  }

  return eventUpdate;
};

export const deleteOrUpdateEventImpact = (
  eventId: EventId,
  monthKey: MonthKey,
  value: Value | undefined,
): AppThunk => {
  return (dispatch) => {
    if (value == null) {
      dispatch(mutationActions.deleteEventIds([eventId]));
      return;
    }

    dispatch(updateEventImpact(eventId, monthKey, value));
  };
};

const updateEventImpact = (eventId: EventId, monthKey: MonthKey, value: Value): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(updateEvents([updateEventImpactsMutation(state, eventId, monthKey, value)]));
  };
};

export const reparentSelectedEventAndEventGroups = (
  newParentGroupId: EventGroupId | undefined,
  insertBeforeId?: InsertBeforeId,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    // Trim to only ids that don't have a parent selected. If the parent is
    // moved, the child should follow and not break the hierarchy.
    const selectedEventIds = selectedEventIdsWithoutSelectedParentSelector(state);
    const selectedEventGroupIds = selectedEventGroupIdsWithoutSelectedParentSelector(state);

    const parentIdByChildId = parentIdsByEventAndGroupIdsSelector(state);

    const setHidden =
      newParentGroupId != null && eventGroupSelector(state, newParentGroupId)?.hidden === true;

    const updates: {
      events: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>;
      groups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>;
    } = { events: [], groups: [] };

    const groupFields = {
      parentId: newParentGroupId ?? NO_PARENT_ID,
      hidden: setHidden ? true : undefined,
    };

    selectedEventIds.forEach((eventId) => {
      updates.events.push({
        id: eventId,
        insertBeforeId,
        ...(newParentGroupId !== parentIdByChildId[eventId] ? groupFields : {}),
      });
    });
    selectedEventGroupIds.forEach((groupId) => {
      updates.groups.push({
        id: groupId,
        insertBeforeId,
        ...(newParentGroupId !== parentIdByChildId[groupId] ? groupFields : {}),
      });
    });
    dispatch(updateEventsAndGroups(updates));
  };
};

// If an event doesn't already exists, creates one
// Otherwise, it updates the existing one.
// If an undefined curvePoint is passed in, removes the existing curve point
type SetCurvePointOnEventEntityProps = {
  eventEntity: EventEntity;
  monthKey: MonthKey;
  curvePoint: CurvePointTyped | undefined;
  blockId: BlockId;
};
export function setCurvePointOnEventEntity({
  eventEntity,
  monthKey,
  curvePoint,
  withEventGroup,
  blockId,
}: SetCurvePointOnEventEntityProps & { withEventGroup?: WithEventGroup }): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const eventsForEntity =
      eventEntity.type === 'driver'
        ? eventsForDriverSelector(state, { id: eventEntity.driverId })
        : eventsForObjectFieldSelector(state, { id: eventEntity.objectFieldId });
    const existingEvents = eventsForEntity.filter(
      (event) => event.customCurvePoints?.[monthKey] != null,
    );

    if (curvePoint == null) {
      // Remove existing curve points, if exists
      if (existingEvents.length > 0) {
        const deleteEventMutation: Pick<DatasetMutationInput, 'deleteEvents'> = {
          deleteEvents: existingEvents.map((event) => ({ id: event.id })),
        };
        dispatch(submitAutoLayerizedMutations('delete-events', [deleteEventMutation]));
      } else if (withEventGroup != null) {
        // If there was no existing curve point but there is an eventGroupId,
        // create a new Set curve point with the existing value.
        let value: number | undefined;

        if (eventEntity.type === 'driver') {
          value = driverValueForMonthKeySelector(state, {
            driverId: eventEntity.driverId,
            monthKey,
          });
        } else {
          const { objectId, fieldSpecId } = eventEntity;
          const val = businessObjectFieldValueForMonthKeySelector(state, {
            businessObjectId: objectId,
            businessObjectFieldSpecId: fieldSpecId,
            monthKey,
            blockId,
          });
          if (val?.type !== ValueType.Number) {
            return;
          }
          value = val.value;
        }

        if (value == null) {
          return;
        }

        dispatch(
          createNewEventsWithEventGroup({
            newEvents: [
              {
                ...eventEntity,
                monthKey,
                value: `${value}`,
                impactType: ImpactType.Set,
              },
            ],
            withEventGroup,
          }),
        );
      }
      return;
    }

    if (Number.isNaN(curvePoint.value)) {
      // ignore non-number curve points for now
      return;
    }

    if (existingEvents.length === 1) {
      // When there is only one existing event, we can update it directly, making the
      // new curve point assume the same parentId as the existing event
      const existingEvent = existingEvents[0];

      const eventsToDelete = existingEvents.slice(1);
      const deleteEventMutation: DatasetMutationInput =
        eventsToDelete.length > 0
          ? {
              deleteEvents: eventsToDelete.map((event) => ({ id: event.id })),
            }
          : {};

      const eventGroupId = eventGroupIdFromWithEventGroup(withEventGroup);

      // Only send an update mutation if something has changed
      const hasUpdatedCurvePoints =
        existingEvent.customCurvePoints?.[monthKey]?.value !== curvePoint.value;
      // All events must have an parentId/eventGroupId, so if eventGroupId is empty
      // we consider it as "not updated" and fallback to the existing event's parentId
      const hasUpdatedEventGroup = eventGroupId != null && existingEvent.parentId !== eventGroupId;
      const hasUpdatedImpactType = existingEvent.impactType !== curvePoint.impactType;

      let updateEventMutation: DatasetMutationInput = {};
      if (hasUpdatedCurvePoints || hasUpdatedEventGroup || hasUpdatedImpactType) {
        updateEventMutation = {
          updateEvents: [
            {
              id: existingEvent.id,
              time: getISOTimeWithoutMsFromMonthKey(monthKey),
              value: `${curvePoint.value}`,
              impactType: curvePoint.impactType,
              parentId: eventGroupId ?? existingEvent.parentId,
            },
          ],
        };
      }

      // Create new event group if necessary
      const authenticatedUser = authenticatedUserSelector(state);
      if (authenticatedUser == null) {
        return;
      }
      const newEventGroupMutation: DatasetMutationInput =
        withEventGroup?.type === 'new'
          ? {
              newEventGroups: [
                {
                  id: withEventGroup.newEventGroup.id,
                  name: withEventGroup.newEventGroup.name,
                  ownerName: authenticatedUser.name,
                  ownerId: authenticatedUser.id,
                  eventIds: [existingEvent.id],
                },
              ],
            }
          : {};

      dispatch(
        submitAutoLayerizedMutations('update-curve-point-on-entity', [
          updateEventMutation,
          newEventGroupMutation,
          deleteEventMutation,
        ]),
      );
      return;
    }

    // If there are multiple existing events, we need to delete them all
    let deleteEventMutationInput: DatasetMutationInput | undefined;
    if (existingEvents.length > 1) {
      deleteEventMutationInput = {
        deleteEvents: existingEvents.map((event) => ({ id: event.id })),
      };
    }

    // create a new one
    dispatch(
      createNewEventsWithEventGroup({
        newEvents: [
          {
            ...eventEntity,
            monthKey,
            value: `${curvePoint.value}`,
            impactType: curvePoint.impactType,
          },
        ],
        withEventGroup: withEventGroup ?? { type: 'default' },
        extras: deleteEventMutationInput,
      }),
    );
  };
}

type SetDeltaImpactsOnEventEntityProps = {
  eventEntity: EventEntity;
  monthKey: MonthKey;
  impactsToSet: Array<{
    value: number;
    withEventGroup: WithEventGroup;
  }>;
};
export function setDeltaImpactsOnEventEntity({
  eventEntity,
  monthKey,
  impactsToSet,
}: SetDeltaImpactsOnEventEntityProps): AppThunk {
  return (dispatch, getState) => {
    const state = getState();

    const eventsForEntity =
      eventEntity.type === 'driver'
        ? eventsForDriverSelector(state, { id: eventEntity.driverId })
        : eventsForObjectFieldSelector(state, { id: eventEntity.objectFieldId });
    const existingEvents = eventsForEntity.filter(
      (event) => event.customCurvePoints?.[monthKey] != null,
    );

    const [eventsToDelete, eventsToUpdate] = partition(existingEvents, (event) => {
      return Object.keys(event.customCurvePoints ?? {}).length === 1;
    });

    const deleteEventInputs: EventDeleteInput[] = eventsToDelete.map((event) => ({ id: event.id }));
    const updateEventInputs: EventUpdateInput[] = eventsToUpdate.map((event) => {
      const { [monthKey]: _, ...newCustomCurvePoints } = event.customCurvePoints ?? {};
      return {
        id: event.id,
        customCurvePoints: convertTimeSeriesToGql(newCustomCurvePoints),
      };
    });

    const updateMutation =
      updateEventInputs.length > 0 ? getUpdateMutation(updateEventInputs, [], getState()) : null;
    const deleteMutation = getDeleteMutation(deleteEventInputs, []);
    const extraMutationInput =
      updateMutation != null ? mergeMutations(updateMutation, deleteMutation) : deleteMutation;

    const newEvents: Array<
      (NewEventForDriver | NewEventForObjectField) & { withEventGroup: WithEventGroup }
    > = impactsToSet.map((impact) => {
      return {
        ...eventEntity,
        monthKey,
        value: `${impact.value}`,
        impactType: ImpactType.Delta,
        withEventGroup: impact.withEventGroup,
      };
    });

    dispatch(
      createNewEventsWithEventGroups({
        newEvents,
        extras: extraMutationInput,
      }),
    );
  };
}

export const {
  bulkCreateEventsAndGroups,
  deleteEventsAndGroups,
  updateEventsAndGroups,
  updateEventsAndGroupsVisibility,
  deleteEventIds,
} = mutationActions;
