import { DriverChartDatum } from 'config/driverChart';
import useAppSelector from 'hooks/useAppSelector';
import useChartContext from 'hooks/useChartContext';
import useChartForecastData from 'hooks/useChartForecastData';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { MonthKey } from 'types/datetime';

const useForecastLineData = (
  monthKeys: MonthKey[],
  lastActual: DriverChartDatum | undefined,
  driverId: DriverId,
  layerId?: LayerId,
  type: 'line' | 'bar' = 'line',
): DriverChartDatum[] => {
  const { format } = useChartContext();

  const timeSeries = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, {
      id: driverId,
      layerId,
    }),
  );
  const forecastChartData = useChartForecastData({
    forecastData: timeSeries,
    monthKeys,
    format,
    lastActual,
  });

  // If it's a 'line' chart, we want to include the lastActual month in the forecast data
  // so there isn't a gap between the actuals line and the forecast line.
  return type === 'line'
    ? forecastChartData
    : forecastChartData.filter(({ monthKey }) => monthKey !== lastActual?.monthKey);
};

export default useForecastLineData;
