import { Box, Button, Flex, Input, Spacer, Text } from '@chakra-ui/react';
import { sum } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { eventGroupIdFromWithEventGroup, WithEventGroup } from '@features/Plans';
import { CELL_PALETTE_POPOVER_CLASS_NAME } from 'components/CellPalette/CellPaletteWithPlanPicker';
import PlanMoreMenu from 'components/PlanPicker/PlanMoreMenu';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { preventEventDefault, stopEventPropagation } from 'helpers/browserEvent';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { updateMultiImpactEventParentEventGroup } from 'reduxStore/actions/eventMutations';
import { DEFAULT_EVENT_GROUP_ID } from 'reduxStore/models/events';
import {
  setCellPaletteState,
  setMultiPlanPickerIndexSelectingPlan,
  setMultiPlanPickerSelectedEventGroups,
} from 'reduxStore/reducers/pageSlice';
import {
  multiPlanPickerIndexSelectingPlanSelector,
  multiPlanPickerSelectedEventGroupsSelector,
} from 'selectors/cellPaletteSelector';
import {
  eventGroupsByIdForLayerSelector,
  sortedEventGroupsForCellSelectionSelector,
} from 'selectors/eventsAndGroupsSelector';
import { activeCellMultiDeltaImpactsWithEditsSelector } from 'selectors/planPickerSelector';
import { prevailingActiveCellMonthKeySelector } from 'selectors/prevailingCellSelectionSelector';
import { PlansIcon } from 'vectors';
import ArrowIcon from 'vectors/Arrowhead';

import PlanPickerBase from './PlanPickerBase';

const EventGroupTag: React.FC<{ eventGroupName: string | null; onClick: () => void }> = ({
  eventGroupName,
  onClick,
}) => {
  const { label, color } =
    eventGroupName != null
      ? { label: eventGroupName, color: 'gray.600' }
      : { label: 'Tag plan', color: 'gray.500' };

  return (
    <Button
      className={CELL_PALETTE_POPOVER_CLASS_NAME}
      data-testid="multi-impact-event-group-tag"
      size="sm"
      variant="iconText"
      onClick={onClick}
      _hover={{
        bgColor: 'gray.100',
      }}
      width="200px"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="6px"
      px="8px"
      py="6px"
      leftIcon={<PlansIcon />}
    >
      <Text color={color} isTruncated lineHeight="16px">
        {label}
      </Text>
      <Spacer />
      <ArrowIcon direction="down" boxSize={2} color="gray.600" ml="4px" />
    </Button>
  );
};

function getEventGroupName(
  withEventGroup: WithEventGroup | null,
  eventGroupsById: Record<string, { name: string }>,
): string | null {
  if (withEventGroup?.type === 'new') {
    return withEventGroup.newEventGroup.name;
  }

  if (withEventGroup?.type === 'existing') {
    const eventGroupId = withEventGroup.eventGroupId;
    return eventGroupsById[eventGroupId]?.name ?? null;
  }

  if (withEventGroup?.type === 'default') {
    return eventGroupsById[DEFAULT_EVENT_GROUP_ID]?.name ?? null;
  }

  return null;
}

const ImpactRow: React.FC<{
  index: number;
  value: number;
  withEventGroup: WithEventGroup | null;
  eventGroupsById: Record<string, { name: string }>;
}> = ({ index, value, withEventGroup, eventGroupsById }) => {
  const dispatch = useAppDispatch();

  const setIndexSelectingPlan = useCallback(() => {
    dispatch(setMultiPlanPickerIndexSelectingPlan(index));
  }, [dispatch, index]);

  const eventGroupName = useMemo(() => {
    return getEventGroupName(withEventGroup, eventGroupsById);
  }, [withEventGroup, eventGroupsById]);

  return (
    <Flex
      className={CELL_PALETTE_POPOVER_CLASS_NAME}
      direction="row"
      justify="space-between"
      gap="8px"
      key={index}
      height="32px"
      py="2px"
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Flex>
        <EventGroupTag eventGroupName={eventGroupName} onClick={setIndexSelectingPlan} />
      </Flex>
      <Flex>
        <Input
          disabled
          value={`${value > 0 ? '+' : ''}${value}`}
          _hover={{}}
          width="60px"
          size="xs"
          height="28px"
          borderRadius="6px"
          bgColor="gray.200"
          border="1px solid"
          borderColor="gray.300"
          color="gray.500"
          _disabled={{
            opacity: 1,
          }}
        />
      </Flex>
    </Flex>
  );
};

type Props = {
  isEditingActiveCell?: boolean;
};

const MultiImpactPlanPicker = React.forwardRef<HTMLDivElement, Props>(
  ({ isEditingActiveCell = false }, ref) => {
    const dispatch = useAppDispatch();
    const sortedEventGroups = useAppSelector(sortedEventGroupsForCellSelectionSelector);
    const selectedEventGroups = useAppSelector(multiPlanPickerSelectedEventGroupsSelector);
    const eventGroupsById = useAppSelector(eventGroupsByIdForLayerSelector);
    const indexSelectingPlan = useAppSelector(multiPlanPickerIndexSelectingPlanSelector);

    const activeCellMultiDeltaImpacts = useAppSelector(
      activeCellMultiDeltaImpactsWithEditsSelector,
    );
    const activeCellMonthKey = useAppSelector(prevailingActiveCellMonthKeySelector);

    const updateMultiPlanPickerSelectedEventGroupAtIndex = useCallback(
      (index: number, withEventGroup: WithEventGroup) => {
        const newSelectedEventGroups =
          selectedEventGroups != null
            ? [...selectedEventGroups]
            : new Array<WithEventGroup | null>(activeCellMultiDeltaImpacts.length).fill(null);
        newSelectedEventGroups[index] = withEventGroup;

        dispatch(setMultiPlanPickerSelectedEventGroups(newSelectedEventGroups));

        dispatch(setMultiPlanPickerIndexSelectingPlan(null));
      },
      [activeCellMultiDeltaImpacts.length, dispatch, selectedEventGroups],
    );

    useHotkeys(
      'Enter, Tab',
      (ev) => {
        if (indexSelectingPlan != null) {
          return;
        }

        if (ev.metaKey) {
          return;
        }

        preventEventDefault(ev);
        stopEventPropagation(ev);

        dispatch(setCellPaletteState('default'));
      },
      [dispatch, indexSelectingPlan],
    );

    const onSelect = useCallback(
      ({ id: eventGroupId }: SelectItem) => {
        if (indexSelectingPlan == null) {
          return;
        }

        if (isEditingActiveCell) {
          updateMultiPlanPickerSelectedEventGroupAtIndex(indexSelectingPlan, {
            type: 'existing',
            eventGroupId,
          });
        } else {
          const impact = activeCellMultiDeltaImpacts[indexSelectingPlan];
          if (impact == null || impact.eventId == null) {
            return;
          }

          const impactsWithSameEventGroup = activeCellMultiDeltaImpacts.filter(
            ({ withEventGroup }, index) =>
              index !== indexSelectingPlan &&
              withEventGroup?.type === 'existing' &&
              withEventGroup.eventGroupId === eventGroupId,
          );

          const newValue =
            impactsWithSameEventGroup.length !== 0
              ? impact.value + sum(impactsWithSameEventGroup.map(({ value }) => value))
              : null;
          const eventIdsToDelete = impactsWithSameEventGroup
            .map(({ eventId }) => eventId)
            .filter(isNotNull);

          dispatch(
            updateMultiImpactEventParentEventGroup({
              eventId: impact.eventId,
              withEventGroup: {
                type: 'existing',
                eventGroupId,
              },
              newImpact:
                newValue != null && activeCellMonthKey != null
                  ? {
                      value: newValue,
                      monthKey: activeCellMonthKey,
                    }
                  : undefined,
              eventIdsToDelete,
            }),
          );
          dispatch(setMultiPlanPickerIndexSelectingPlan(null));
        }
      },
      [
        activeCellMultiDeltaImpacts,
        activeCellMonthKey,
        dispatch,
        indexSelectingPlan,
        isEditingActiveCell,
        updateMultiPlanPickerSelectedEventGroupAtIndex,
      ],
    );

    const onSelectCreatePlan = useCallback(
      (newEventGroupName: string) => {
        if (indexSelectingPlan == null) {
          return;
        }

        const newEventGroup = {
          id: uuidv4(),
          name: newEventGroupName,
        };

        if (isEditingActiveCell) {
          updateMultiPlanPickerSelectedEventGroupAtIndex(indexSelectingPlan, {
            type: 'new',
            newEventGroup,
          });
        } else {
          const impact = activeCellMultiDeltaImpacts[indexSelectingPlan];
          if (impact == null || impact.eventId == null) {
            return;
          }

          dispatch(
            updateMultiImpactEventParentEventGroup({
              eventId: impact.eventId,
              withEventGroup: {
                type: 'new',
                newEventGroup,
              },
            }),
          );
        }
        dispatch(setMultiPlanPickerIndexSelectingPlan(null));
      },
      [
        activeCellMultiDeltaImpacts,
        dispatch,
        indexSelectingPlan,
        isEditingActiveCell,
        updateMultiPlanPickerSelectedEventGroupAtIndex,
      ],
    );

    const onClose = useCallback(() => {
      dispatch(setMultiPlanPickerIndexSelectingPlan(null));
    }, [dispatch]);

    const items: SelectItem[] = useMemo(() => {
      const withEventGroup =
        indexSelectingPlan != null
          ? activeCellMultiDeltaImpacts[indexSelectingPlan]?.withEventGroup
          : undefined;
      const eventGroupId = eventGroupIdFromWithEventGroup(withEventGroup);

      return sortedEventGroups.map(({ id, name, isNew }) => ({
        id,
        name,
        sectionId: 'main',
        checkedStyle: 'check',
        isChecked: eventGroupId === id,
        icon: <PlansIcon />,
        submenu: () => <PlanMoreMenu eventGroupId={id} />,
        submenuType: 'options',
        isDisabled: isNew,
        disabledTooltipLabel: isNew ? 'Plan will be created once you save' : undefined,
      }));
    }, [activeCellMultiDeltaImpacts, indexSelectingPlan, sortedEventGroups]);

    if (indexSelectingPlan != null) {
      return (
        <PlanPickerBase
          ref={ref}
          onSelect={onSelect}
          onSelectCreatePlan={onSelectCreatePlan}
          onClose={onClose}
          items={items}
          className={CELL_PALETTE_POPOVER_CLASS_NAME}
        />
      );
    }

    return (
      <Box
        bgColor="surface"
        borderRadius="6px"
        boxShadow="menu"
        zIndex="popover"
        className={CELL_PALETTE_POPOVER_CLASS_NAME}
        data-testid="multi-impact-plan-picker"
      >
        <Flex
          direction="column"
          px="6px"
          py="4px"
          className={CELL_PALETTE_POPOVER_CLASS_NAME}
          borderRadius="6px"
        >
          {activeCellMultiDeltaImpacts.map(({ value, withEventGroup }, index) => (
            <ImpactRow
              key={index}
              index={index}
              value={value}
              withEventGroup={withEventGroup}
              eventGroupsById={eventGroupsById}
            />
          ))}
        </Flex>
      </Box>
    );
  },
);

export default React.memo(MultiImpactPlanPicker);
