import { Box, Flex, useBoolean } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import CollapsibleHeader from 'components/CollapsibleHeader/CollapsibleHeader';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import EscapableInput from 'components/EscapableInput/EscapableInput';
import RowOptions from 'components/RowOptions/RowOptions';
import { DriverGridContext } from 'config/driverGridContext';
import { useDriverGroupNameValidation } from 'config/driverGroups';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { toPxString } from 'helpers/styles';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useDriverGroupContextMenuItems from 'hooks/useDriverGroupContextMenuItems';
import { BaseDragItem } from 'hooks/useReorderable';
import { renameDriverGroup } from 'reduxStore/actions/driverGroupMutations';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverGroupId, DriverGroupIdOrNull } from 'reduxStore/models/driverGroup';
import { isAutoFocusedRenameGroupSelector } from 'selectors/pageSelector';
import { submodelDriverIdsByGroupIdSelector } from 'selectors/submodelTableGroupsSelector';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

export type DriverGroupDragItem = BaseDragItem & {
  groupId: DriverGroupIdOrNull;
  blockId: BlockId;
};

interface Props {
  groupId?: DriverGroupId;
  groupName: string;
  isCollapsed: boolean;
  onCollapse?: () => void;
  containerBgColor?: string;
}

export const DRIVER_GROUP_DRAG_ITEM_TYPE = 'driverGroup';
export const DRIVER_GROUP_TITLE_HEIGHT = 24;

const DriverGroupNameHeaderRow: React.FC<Props> = ({
  groupId,
  groupName,
  isCollapsed = false,
  onCollapse,
  containerBgColor = 'surface',
}) => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const { canEditGroups } = useContext(DriverGridContext);
  const [isRenaming, setIsRenaming] = useBoolean(false);
  const driverIdsForGroup = useAppSelector((state) =>
    submodelDriverIdsByGroupIdSelector(state, { groupId }),
  );

  const isDefaultGroup = groupId == null;
  const canRename = canEditGroups && !isDefaultGroup;
  const onRenameGroup = useCallback(
    (newName: string) => {
      if (canRename) {
        dispatch(renameDriverGroup({ id: groupId, name: newName }));
        setIsRenaming.off();
      }
    },
    [dispatch, groupId, canRename, setIsRenaming],
  );

  const onToggleExpansion = useCallback(() => {
    onCollapse?.();
  }, [onCollapse]);

  const contextMenuItems = useDriverGroupContextMenuItems(groupId);

  const width = useAppSelector((state) =>
    columnWidthSelector(state, { blockId, columnType: NAME_COLUMN_TYPE }),
  );
  const autoFocus = useAppSelector((state) => isAutoFocusedRenameGroupSelector(state, groupId));
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (autoFocus) {
      setIsRenaming.on();
      window.requestAnimationFrame(() => inputRef.current?.focus());
    }
  }, [autoFocus, setIsRenaming]);

  const validNames = useMemo(() => [groupName], [groupName]);
  const validator = useDriverGroupNameValidation(validNames);

  return (
    <ContextMenu items={contextMenuItems}>
      <Flex
        h={toPxString(DRIVER_GROUP_TITLE_HEIGHT)}
        data-testid="driver-group-name"
        position="sticky"
        role={isDefaultGroup ? undefined : 'row'}
        w="fit-content"
        left={0}
        alignItems="center"
        bgColor={containerBgColor}
      >
        <RowOptions items={contextMenuItems} ariaLabel="Drag to move" />
        {!isDefaultGroup &&
          (isRenaming ? (
            <EscapableInput
              ref={inputRef}
              _focus={{ backgroundColor: 'gray.100' }}
              autoFocus
              borderRadius="md"
              defaultValue={groupName}
              fontSize="xs"
              fontWeight="medium"
              height="full"
              name="groupName"
              onCancel={setIsRenaming.off}
              onSave={onRenameGroup}
              pl="0.625rem"
              py={1}
              saveOnBlur
              validationSchema={validator}
              variant="inline"
              width={`${width - 8}px`}
            />
          ) : (
            <CollapsibleHeader
              onToggle={onToggleExpansion}
              isCollapsed={isCollapsed}
              name={groupName}
              numItemsCollapsed={driverIdsForGroup.length ?? 0}
              bgColor={canRename ? 'gray.200' : 'transparent'}
              textColor={canRename ? undefined : 'gray.500'}
              onDoubleClick={canRename ? setIsRenaming.on : undefined}
            />
          ))}
        {/* This box makes the ghost when dragging wider*/}
        {!isDefaultGroup && <Box w="10rem" />}
      </Flex>
    </ContextMenu>
  );
};

export default React.memo(DriverGroupNameHeaderRow);
