import { BoxProps } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import DatabaseTableHeaderCell from 'components/BusinessObjectTable/DatabaseTableHeaderCell';
import DatabaseDriverPropertySettingsPopoverContent from 'components/BusinessObjectTable/SettingsPopoverContents/DatabaseDriverPropertySettingsPopoverContent';
import InteractiveColumnHeaderCell from 'components/InteractiveColumnHeaderCell/InteractiveColumnHeaderCell';
import { ReorderableProps } from 'components/Reorderable/Reorderable';
import { DatabaseGroupKey, OBJECT_BLOCK_COLUMN_DRAG_ITEM_TYPE } from 'config/businessObjects';
import { COLUMN_HEADER_CELL_HEIGHT_PX } from 'config/cells';
import { toPxString } from 'helpers/styles';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellRef } from 'hooks/useCellRefContext';
import { BaseDragItem } from 'hooks/useReorderable';
import { selectCellIfUnselected } from 'reduxStore/actions/cellSelection';
import { updateObjectTableColumnPosition } from 'reduxStore/actions/objectTableNavigation';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverPropertyId } from 'reduxStore/models/collections';
import { clearEditingPropertyFormula } from 'reduxStore/reducers/blockLocalStateSlice';
import { driverPropertySelector } from 'selectors/collectionSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { isAutoFocusedDatabaseHeaderMenuSelector } from 'selectors/pageSelector';
import { isResourceRestrictedForUserSelector } from 'selectors/restrictedResourcesSelector';

type DragItem = BaseDragItem & {
  columnKey: DriverPropertyId;
};

interface Props {
  driverPropertyId: DriverPropertyId;
  isLast: boolean;
  groupKey: DatabaseGroupKey;
  canDrag?: boolean;
  fixedWidth?: BoxProps['width'];
  topSpacingPx?: number;
  orientation?: ReorderableProps<DragItem>['orientation'];
  objectId: BusinessObjectId | undefined;
}

const DriverPropertyHeaderCell: React.FC<Props> = ({
  driverPropertyId,
  isLast,
  canDrag = true,
  fixedWidth,
  topSpacingPx,
  groupKey,
  orientation,
  objectId,
}) => {
  const dispatch = useAppDispatch();
  const { blockId, readOnly } = useBlockContext();
  const isAutoFocused = useAppSelector((state) =>
    isAutoFocusedDatabaseHeaderMenuSelector(state, {
      blockId,
      fieldSpecId: driverPropertyId,
      groupKey,
      objectId,
    }),
  );
  const driverProperty = useAppSelector((state) => driverPropertySelector(state, driverPropertyId));
  const onDrop = useCallback(
    (dropItem: DragItem, relativeTo: DragItem, position: 'before' | 'after') => {
      dispatch(
        updateObjectTableColumnPosition({
          blockId,
          fieldSpecId: dropItem.columnKey,
          insertBeforeId: position === 'before' ? relativeTo.columnKey : 'end',
        }),
      );
    },
    [blockId, dispatch],
  );

  const columnDragItem: DragItem = useMemo(
    () => ({ type: OBJECT_BLOCK_COLUMN_DRAG_ITEM_TYPE, columnKey: driverPropertyId }),
    [driverPropertyId],
  );

  const driversById = useAppSelector(driversByIdForLayerSelector);
  const driver = driverProperty != null ? driversById[driverProperty.driverId] : null;
  const errMsg = useMemo(() => {
    if (driverProperty == null || driverProperty.driverId == null) {
      return 'driver property not found';
    }
    return driver == null ? 'driver not found' : null;
  }, [driver, driverProperty]);

  const onClose = useCallback(() => {
    dispatch(clearEditingPropertyFormula());
  }, [dispatch]);

  const { cellRef } = useCellRef();
  const selectCell = useCallback(() => {
    dispatch(selectCellIfUnselected(blockId, cellRef));
  }, [dispatch, cellRef, blockId]);

  const hasRestrictions = useAppSelector((state) =>
    isResourceRestrictedForUserSelector(state, { resourceId: driverPropertyId, blockId }),
  );

  return (
    <InteractiveColumnHeaderCell
      isAutoFocused={isAutoFocused}
      orientation={orientation}
      item={columnDragItem}
      onDrop={onDrop}
      canDrag={canDrag}
      disabled={readOnly}
      onClose={onClose}
      isLast={isLast}
      popoverContent={
        <DatabaseDriverPropertySettingsPopoverContent
          isAutoFocused={false}
          driverPropertyId={driverPropertyId}
          businessObjectId={objectId}
          onClose={onClose}
        />
      }
    >
      <DatabaseTableHeaderCell
        columnKey={driverPropertyId}
        title={driver?.name ?? ''}
        fixedWidth={fixedWidth}
        topSpacingPx={topSpacingPx}
        errMsg={errMsg ?? undefined}
        height={toPxString(COLUMN_HEADER_CELL_HEIGHT_PX)}
        objectId={objectId}
        onClick={selectCell}
        orientation={orientation ?? 'horizontal'}
        hasRestrictions={hasRestrictions}
        groupKey={groupKey}
      />
    </InteractiveColumnHeaderCell>
  );
};

DriverPropertyHeaderCell.displayName = 'DriverPropertyHeaderCell';

export default DriverPropertyHeaderCell;
