import { ChangeStatus } from '@features/CompareScenarios/compareLayers';
import { DatasetMutationInput } from 'generated/graphql';
import { safeObjGet } from 'helpers/typescript';
import { Layer } from 'reduxStore/models/layers';

export enum ActivityObject {
  Block = 'Block',
  Page = 'Page',
  Model = 'Model',
  Database = 'Database',
  Row = 'Database Row',
  Dimension = 'Dimension',
  Driver = 'Driver',
  DriverGroup = 'Driver Group',
  Plan = 'Plan',
  PlanLineItem = 'Plan Line Item',
  Milestone = 'Milestone',
  LastClose = 'Last Close',
  ExtTable = 'External Table',
  IntegrationQuery = 'Integration Query',
  Export = 'Export',
  Workspace = 'Workspace',
}

const MUTATION_TO_ACTIVITY: Record<keyof MutationAttributes, Activity | null> = {
  createDatabaseConfigs: null,
  updateDatabaseConfigs: null,
  deleteDatabaseConfigs: null,
  createExtDrivers: null,
  updateExtDrivers: null,
  deleteExtDrivers: null,
  createExtObjects: null,
  deleteExtObjects: null,
  createExtObjectSpecs: null,
  updateExtObjectSpecs: null,
  deleteExtObjectSpecs: null,
  createExtQueries: null,
  updateExtQueries: null,
  deleteExtQueries: null,
  createExtTableRuns: null,
  createNamedDatasetVersions: null,
  deleteNamedDatasetVersions: null,
  updateNamedDatasetVersions: null,
  importNamedVersionSnapshot: null,
  importSnapshot: null,
  newLayers: null,
  updateLayers: null,
  deleteLayers: null,
  newDriverFieldSpecs: null,
  updateDriverFieldSpecs: null,
  setDriverFields: null,
  createIntegrationQueries: { type: 'created', name: ActivityObject.IntegrationQuery },
  updateIntegrationQueries: { type: 'updated', name: ActivityObject.IntegrationQuery },
  deleteIntegrationQueries: { type: 'deleted', name: ActivityObject.IntegrationQuery },
  createExtTables: { type: 'created', name: ActivityObject.ExtTable },
  updateExtTables: { type: 'updated', name: ActivityObject.ExtTable },
  deleteExtTables: { type: 'deleted', name: ActivityObject.ExtTable },
  newFolders: { type: 'created', name: ActivityObject.Workspace },
  updateFolders: { type: 'deleted', name: ActivityObject.Workspace },
  deleteFolders: { type: 'updated', name: ActivityObject.Workspace },
  newBlocksPages: { type: 'created', name: ActivityObject.Page },
  updateBlocksPages: { type: 'updated', name: ActivityObject.Page },
  deleteBlocksPages: { type: 'deleted', name: ActivityObject.Page },
  newBlocks: { type: 'created', name: ActivityObject.Block },
  updateBlocks: { type: 'updated', name: ActivityObject.Block },
  deleteBlocks: { type: 'deleted', name: ActivityObject.Block },
  newBusinessObjectSpecs: { type: 'created', name: ActivityObject.Database },
  updateBusinessObjects: { type: 'updated', name: ActivityObject.Database },
  deleteBusinessObjectSpecs: { type: 'deleted', name: ActivityObject.Database },
  newBusinessObjects: { type: 'created', name: ActivityObject.Row },
  updateBusinessObjectSpecs: { type: 'updated', name: ActivityObject.Row },
  deleteBusinessObjects: { type: 'deleted', name: ActivityObject.Row },
  newSubmodels: { type: 'created', name: ActivityObject.Model },
  updateSubmodels: { type: 'updated', name: ActivityObject.Model },
  deleteSubmodels: { type: 'deleted', name: ActivityObject.Model },
  deleteDimensions: { type: 'deleted', name: ActivityObject.Dimension },
  newDriverGroups: { type: 'created', name: ActivityObject.DriverGroup },
  renameDriverGroups: { type: 'updated', name: ActivityObject.DriverGroup },
  deleteDriverGroups: { type: 'deleted', name: ActivityObject.DriverGroup },
  newDrivers: { type: 'created', name: ActivityObject.Driver },
  updateDrivers: { type: 'updated', name: ActivityObject.Driver },
  deleteDrivers: { type: 'deleted', name: ActivityObject.Driver },
  newDimensions: { type: 'created', name: ActivityObject.Dimension },
  restoreDimensions: { type: 'updated', name: ActivityObject.Dimension },
  updateDimensions: { type: 'updated', name: ActivityObject.Dimension },
  newEventGroups: { type: 'created', name: ActivityObject.Plan },
  updateEventGroups: { type: 'updated', name: ActivityObject.Plan },
  deleteEventGroups: { type: 'deleted', name: ActivityObject.Plan },
  newEvents: { type: 'created', name: ActivityObject.PlanLineItem },
  updateEvents: { type: 'updated', name: ActivityObject.PlanLineItem },
  deleteEvents: { type: 'deleted', name: ActivityObject.PlanLineItem },
  newExports: { type: 'created', name: ActivityObject.Export },
  updateExports: { type: 'updated', name: ActivityObject.Export },
  deleteExports: { type: 'deleted', name: ActivityObject.Export },
  newMilestones: { type: 'created', name: ActivityObject.Milestone },
  updateMilestones: { type: 'updated', name: ActivityObject.Milestone },
  deleteMilestones: { type: 'deleted', name: ActivityObject.Milestone },
  updateLastActualsTime: { type: 'updated', name: ActivityObject.LastClose },
};

export type ActivityLog = Record<string, Partial<Record<ChangeStatus, number>>>;
export function getActivityFromLayer(layer: Layer): ActivityLog {
  const log: ActivityLog = {};

  for (const [mutationAttribute, activity] of Object.entries(MUTATION_TO_ACTIVITY)) {
    if (activity == null) {
      continue;
    }

    for (const batch of layer.mutationBatches) {
      const mutation = safeObjGet(batch.mutation[mutationAttribute as keyof MutationAttributes]);

      if (mutation == null) {
        continue;
      }

      log[activity.name] ??= {};
      const count =
        (log[activity.name][activity.type] ?? 0) + (Array.isArray(mutation) ? mutation.length : 1);
      log[activity.name][activity.type] = count;
    }
  }
  return log;
}

type MutationAttributes = Omit<DatasetMutationInput, 'changeId' | 'commitLayerId'>;
type Activity = { type: ChangeStatus; name: ActivityObject };
