import { createSelector } from '@reduxjs/toolkit';

import { DATABASE_BLOCK_NAME, isDatabasePage } from 'config/internalPages/databasePage';
import { isObjectRowKey } from 'helpers/cells';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blockCellKeysSelector } from 'selectors/activeCellSelectionSelector';
import { currentBlocksPageSelector } from 'selectors/blocksPagesSelector';
import { blocksByIdSelector } from 'selectors/blocksSelector';
import { isViewingDatabasePageSelector } from 'selectors/databasePageSelector';
import { openDetailPaneSelector } from 'selectors/detailPaneSelectors';
import { Selector } from 'types/redux';

/*
 * This currently only works in the database page because there's a clear
 * ordering to the rows. Theoretically, we could make this work in other places
 * by knowing which block opened the detail pane and then using that block's
 * cell keys to determine the next/previous object (or even driver/event). For
 * now, it was kept simple to test the concept.
 */

interface DetailNavigationContext {
  objectSpecId: BusinessObjectSpecId;
  objectId: BusinessObjectId;
  blockId: BlockId;
}

interface DetailNavigationSurroundingObjects {
  up: BusinessObjectId | null;
  down: BusinessObjectId | null;
}

const databaseTableNavigationContextSelector: Selector<DetailNavigationContext | null> =
  createSelector(
    isViewingDatabasePageSelector,
    currentBlocksPageSelector,
    blocksByIdSelector,
    openDetailPaneSelector,
    (isViewingDatabasePage, currentBlocksPage, blocksById, openDetailPane) => {
      if (
        !isViewingDatabasePage ||
        currentBlocksPage == null ||
        !isDatabasePage(currentBlocksPage.internalPageType) ||
        openDetailPane?.type !== PaneType.Object
      ) {
        return null;
      }

      const blockId = currentBlocksPage.blockIds.find(
        (id) => blocksById[id]?.name === DATABASE_BLOCK_NAME,
      );
      if (blockId == null) {
        return null;
      }

      const { objectId, objectSpecId } = openDetailPane;

      return { blockId, objectId, objectSpecId };
    },
  );

const detailPaneNavigationBlockIdSelector: Selector<BlockId | null> = createSelector(
  databaseTableNavigationContextSelector,
  (context) => context?.blockId ?? null,
);

const detailPaneNavigationObjectIdSelector: Selector<BusinessObjectId | null> = createSelector(
  databaseTableNavigationContextSelector,
  (context) => context?.objectId ?? null,
);

export const isNavigableDetailPaneOpen = createSelector(
  databaseTableNavigationContextSelector,
  (context) => context != null,
);

export const detailPaneNavigationObjectSpecIdSelector: Selector<BusinessObjectSpecId | null> =
  createSelector(
    databaseTableNavigationContextSelector,
    (context) => context?.objectSpecId ?? null,
  );

export const detailPaneNavigationNextObjectsSelector: Selector<DetailNavigationSurroundingObjects | null> =
  createSelector(
    (state: RootState) => {
      const blockId = detailPaneNavigationBlockIdSelector(state);
      if (blockId == null) {
        return null;
      }
      return blockCellKeysSelector(state, blockId)?.orderedRowKeys;
    },
    detailPaneNavigationObjectIdSelector,
    detailPaneNavigationObjectSpecIdSelector,
    (rowKeys, currObjectId) => {
      if (rowKeys == null) {
        return null;
      }
      const objRowKeys = rowKeys.filter(isObjectRowKey).filter(({ objectId }) => objectId != null);
      const currObjIdx = objRowKeys.findIndex((rowKey) => currObjectId === rowKey.objectId);

      const nextIdx = currObjIdx + 1 > objRowKeys.length - 1 ? 0 : currObjIdx + 1;
      const prevIdx = currObjIdx - 1 < 0 ? objRowKeys.length - 1 : currObjIdx - 1;

      const up = objRowKeys[prevIdx]?.objectId;
      const down = objRowKeys[nextIdx]?.objectId;

      return { up, down };
    },
  );
