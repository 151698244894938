import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'FormulaIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.164 11.33a.75.75 0 0 0-.335 1.006l.671-.335-.67.336v.002l.002.002.003.007.01.017a2.139 2.139 0 0 0 .125.208c.083.124.207.29.375.458.332.332.886.72 1.655.72.79 0 1.47-.095 2-.378.585-.31.908-.803 1.043-1.392.125-.538.094-1.162.023-1.806-.049-.434-.123-.93-.203-1.466l-.006-.04 1.718-.173a.75.75 0 0 0-.15-1.492l-1.787.178-.023-.152c-.077-.516-.145-.964-.19-1.37-.07-.623-.07-1.03-.007-1.304.052-.224.135-.325.286-.405.203-.108.586-.201 1.296-.201.23 0 .426.112.595.28a1.537 1.537 0 0 1 .23.3l.007.011a.75.75 0 0 0 1.339-.676L11.5 4l.67-.336-.001-.002-.002-.002-.003-.007-.01-.017a2.254 2.254 0 0 0-.125-.208 3.034 3.034 0 0 0-.375-.458c-.331-.332-.886-.72-1.655-.72-.79 0-1.47.095-2 .377-.585.31-.908.804-1.043 1.392-.125.539-.094 1.163-.023 1.806.049.434.123.93.203 1.467l.006.04-1.718.172a.75.75 0 0 0 .15 1.492l1.787-.178.023.153c.077.515.145.964.19 1.37.069.622.07 1.03.007 1.303-.052.224-.135.325-.286.405-.203.108-.586.201-1.296.201-.231 0-.427-.112-.595-.28a1.532 1.532 0 0 1-.23-.299l-.007-.012a.75.75 0 0 0-1.004-.329Z"
      fill="currentColor"
    />
  ),
});
