import { useContext } from 'react';

import { CellValueContext, CellValueTooltipData } from 'config/cells';
import { extDriverSourceToSlug } from 'helpers/integrations';

export default function useCellTooltip(): CellValueTooltipData | undefined {
  const { valueTooltip, extSource } = useContext(CellValueContext);

  if (extSource != null && extDriverSourceToSlug(extSource) != null) {
    return {
      hasUnderline: valueTooltip?.hasUnderline ?? true,
      content: { type: 'ext_source', extSource },
    };
  }

  return valueTooltip;
}
