import { InternalPage } from 'config/internalPages/internalPage';
import { COLUMN_TYPE_TO_DEFAULT_WIDTH } from 'config/modelView';
import {
  BlockColumnOptionsInput,
  BlockCreateInput,
  BlockType,
  BlocksPageCreateInput,
} from 'generated/graphql';
import { uuidv4 } from 'helpers/uuidv4';

// Increment this version if you make a change to the config below.
const VERSION = 0;

export const DRIVER_DETAIL_PAGE_TYPE = 'driverDetail';

const DEFAULT_COLUMNS: BlockColumnOptionsInput[] = [
  {
    key: 'name',
    visible: true,
    width: COLUMN_TYPE_TO_DEFAULT_WIDTH.name,
  },
  {
    key: 'data',
    visible: true,
    width: COLUMN_TYPE_TO_DEFAULT_WIDTH.data,
  },
];

export const driverDetailPage = {
  internalPageType: DRIVER_DETAIL_PAGE_TYPE,
  version: VERSION,
  makeDefault: (): InternalPage => {
    const pageId = uuidv4();
    const internalPageType = DRIVER_DETAIL_PAGE_TYPE;
    const version = VERSION;
    const blocksPageCreateInput: BlocksPageCreateInput = {
      id: pageId,
      name: 'Driver Detail Pane',
      internalPageType,
      internalPageVersion: version,
    };
    const blocksCreateInputs: BlockCreateInput[] = [
      {
        id: uuidv4(),
        type: BlockType.DriverGrid,
        name: 'Drivers',
        pageId,
        blockConfig: {
          columns: DEFAULT_COLUMNS,
        },
      },
    ];
    return { internalPageType, version, blocksPageCreateInput, blocksCreateInputs };
  },
};
