import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

export const MenuSection: React.FC<{
  title: string;
  meta?: React.ReactNode;
  isLast?: boolean;
  children: React.ReactNode;
}> = ({ title, meta, children, isLast = false }) => {
  return (
    <Box
      borderBottomWidth={isLast ? undefined : 'px'}
      borderColor="gray.300"
      mb={isLast ? 0 : 1}
      py={2}
    >
      <Flex flexDir="row" alignItems="baseline" justifyContent="space-between">
        <Text
          color="gray.500"
          textTransform="uppercase"
          fontWeight="700"
          fontSize="xxxs"
          px={1}
          py={1}
          pr={2}
        >
          {title}
        </Text>
        {meta}
      </Flex>
      {children}
    </Box>
  );
};
