import { createCachedSelector } from 're-reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { Submodel, SubmodelId } from 'reduxStore/models/submodels';
import { submodelEntityTableForLayerSelector } from 'selectors/submodelEntityTableSelector';

export const submodelsByIdSelector: SelectorWithLayerParam<Record<SubmodelId, Submodel>> =
  createCachedSelector(addLayerParams(submodelEntityTableForLayerSelector), (submodels) => {
    return submodels.byId;
  })(getCacheKeyForLayerSelector);

export const submodelIdsSelector: SelectorWithLayerParam<SubmodelId[]> = createCachedSelector(
  addLayerParams(submodelsByIdSelector),
  (submodelsById) => Object.keys(submodelsById),
)({ keySelector: getCacheKeyForLayerSelector, selectorCreator: createDeepEqualSelector });
