import theme from 'config/theme';

interface Props {
  opacity?: number;
}

export const MilestoneFlagTop = ({ opacity = 1 }: Props) => {
  return (
    <path
      d="M9.59038 5.28673L12.5397 9.5H1V0.5H12.5397L9.59038 4.71327L9.38967 5L9.59038 5.28673Z"
      fill={theme.colors.milestone}
      opacity={opacity}
    />
  );
};
