import { sum } from 'lodash';

import { EventEntity } from '@features/Plans/EventEntity';
import { ImpactType } from 'generated/graphql';
import { formatDriverValue } from 'helpers/formatting';
import {
  AtomicNumberDisplayChunk,
  FormulaDisplay,
  FormulaDisplayChunk,
  FormulaDisplayChunkType,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { isPrintableChar } from 'helpers/string';
import { CellImpact, DeltaCellImpact } from 'reduxStore/models/events';
import { DisplayConfiguration } from 'reduxStore/models/value';

const EMPTY_FORMULA_DISPLAY = { chunks: [] };

const DISPLAY_OPTIONS = {
  includeCents: true,
  abbreviate: false,
};

function getComputedFromRowFormulaChunk(value: string): AtomicNumberDisplayChunk {
  return {
    type: FormulaDisplayChunkType.AtomicNumber,
    value,
    tooltip: 'Computed from row formula',
    icon: 'formula',
  };
}

function getDeltaFormulaDisplay({
  value,
  cellImpact,
  displayConfiguration,
}: {
  value: number;
  cellImpact: DeltaCellImpact;
  displayConfiguration: DisplayConfiguration;
}): FormulaDisplay {
  const { valuesWithEventGroups } = cellImpact;

  const valueWithoutDeltas =
    value - sum(valuesWithEventGroups.map(({ value: impactValue }) => impactValue.value));
  const formattedValueWithoutDelta = formatDriverValue(
    valueWithoutDeltas,
    displayConfiguration,
    DISPLAY_OPTIONS,
  );

  const operationAndDeltaChunks: FormulaDisplayChunk[] = valuesWithEventGroups.flatMap(
    ({ value: impactValue }) => {
      const formattedDelta = formatDriverValue(
        Math.abs(impactValue.value),
        displayConfiguration,
        DISPLAY_OPTIONS,
      );

      return [
        {
          type: FormulaDisplayChunkType.Operation,
          text: impactValue.value >= 0 ? '+' : '-',
        },
        {
          type: FormulaDisplayChunkType.Number,
          text: formattedDelta,
        },
      ];
    },
  );

  return {
    chunks: [
      getComputedFromRowFormulaChunk(formattedValueWithoutDelta),
      ...operationAndDeltaChunks,
    ],
  };
}

export function getActiveCellFormulaDisplay({
  eventEntity,
  value,
  cellImpact,
  isForecast,
  displayConfiguration,
  startEditingKey,
}: {
  eventEntity: EventEntity | null | undefined;
  value: number | null | undefined;
  cellImpact: CellImpact | null;
  isForecast: boolean;
  displayConfiguration: DisplayConfiguration;
  startEditingKey: string | null;
}): FormulaDisplay {
  // If a user triggers edit mode by entering a printable character, that character should be the initial
  // value shown in the active cell.
  if (startEditingKey != null && isPrintableChar(startEditingKey)) {
    return {
      chunks: [
        {
          type: FormulaDisplayChunkType.Number,
          text: startEditingKey,
        },
      ],
    };
  }

  if (value == null) {
    return { ...EMPTY_FORMULA_DISPLAY };
  }

  const formattedValue = formatDriverValue(value, displayConfiguration, DISPLAY_OPTIONS);

  if (!isForecast || eventEntity == null) {
    // For actuals and cells that don't support events, always show static number value
    return {
      chunks: [
        {
          type: FormulaDisplayChunkType.Number,
          text: formattedValue,
        },
      ],
    };
  }

  if (cellImpact == null) {
    return {
      chunks: [getComputedFromRowFormulaChunk(formattedValue)],
    };
  }

  if (cellImpact.impactType === ImpactType.Set) {
    return {
      chunks: [
        {
          type: FormulaDisplayChunkType.Number,
          text: formattedValue,
        },
      ],
    };
  }

  return getDeltaFormulaDisplay({ value, cellImpact, displayConfiguration });
}
