import { createSelector } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import { LATEST_FORMULA_EVALUATION_MONTH_KEY } from 'config/formula';
import { FormulaCacheSingleton } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import {
  FormulaEvaluator,
  FormulaEvaluatorConstructorParams,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import {
  formulaEntitiesByIdForLayerSelector,
  formulaEvaluatorExtObjectIndicesSelector,
  getFormulaEvaluatorObjectIndices,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluatorHelpers';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { businessObjectFieldSpecByIdSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import {
  attributesBySubDriverIdSelector,
  driversByIdForLayerSelector,
  evaluatorDriversForLayerSelector,
} from 'selectors/driversSelector';
import {
  impactingEventsByFormulaEntityIdForLayerSelector,
  visibleEventsWithoutLiveEditsByEntityIdSelector,
} from 'selectors/eventsAndGroupsSelector';
import { extDriversSelector } from 'selectors/extDriversSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { earliestFormulaEvaluationMonthKeySelector } from 'selectors/launchDarklySelector';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import { populatedBusinessObjectSpecsForLayerSelector } from 'selectors/populatedBusinessObjectsAndSpecsSelector';
import { submodelIdByBlockIdSelector } from 'selectors/submodelPageSelector';

const formulaEvaluatorObjectRelatedPropsSelector: SelectorWithLayerParam<
  Pick<
    FormulaEvaluatorConstructorParams,
    'objectIdsByFieldId' | 'objectsById' | 'databasesById' | 'extObjectFieldsByKey'
  >
> = createCachedSelector(
  addLayerParams(populatedBusinessObjectSpecsForLayerSelector),
  addLayerParams(formulaEvaluatorExtObjectIndicesSelector),
  addLayerParams(driversByIdForLayerSelector),
  function fieldIdByObjectIdSelector(objectSpecs, extObjectIndices, driversById) {
    return {
      ...getFormulaEvaluatorObjectIndices(objectSpecs, driversById),
      ...extObjectIndices,
    };
  },
)(getCacheKeyForLayerSelector);

const formulaEvaluatorConstructorPropsForLayerSelector: SelectorWithLayerParam<
  Omit<
    FormulaEvaluatorConstructorParams,
    // Note: if you call impactingEventsByFormulaEntityIdForLayerSelector in this selector before using
    // in formulaEvaluatorWithoutLiveEditingSelector, formulaEvaluatorWithoutLiveEditingSelector will somehow
    // end up using the liveEdit event.
    'eventsByEntityId'
  >
> = createCachedSelector(
  addLayerParams(evaluatorDriversForLayerSelector),
  addLayerParams(lastActualsMonthKeyForLayerSelector),
  addLayerParams(attributesBySubDriverIdSelector),
  addLayerParams(dimensionsByIdSelector),
  addLayerParams(businessObjectFieldSpecByIdSelector),
  addLayerParams(dimensionalPropertyEvaluatorSelector),
  earliestFormulaEvaluationMonthKeySelector,
  addLayerParams(formulaEntitiesByIdForLayerSelector),
  addLayerParams(formulaEvaluatorObjectRelatedPropsSelector),
  addLayerParams(extDriversSelector),
  submodelIdByBlockIdSelector,
  getGivenOrCurrentLayerId,
  // eslint-disable-next-line max-params
  function formulaEvaluatorParamsForLayerSelector(
    drivers,
    lastActualsMonthKey,
    attributesByDriverId,
    dimsById,
    fieldSpecsById,
    dimPropertyEvaluator,
    earliestFormulaEvaluationMonthKey,
    formulaEntitiesById,
    objectConstructorProps,
    extDrivers,
    submodelIdByBlockId,
    layerId,
  ) {
    const latestFormulaEvaluationMonthKey = LATEST_FORMULA_EVALUATION_MONTH_KEY;
    return {
      drivers,
      lastActualsMonthKey,
      attributesByDriverId,
      dimsById,
      fieldSpecsById,
      dimPropertyEvaluator,
      earliestFormulaEvaluationMonthKey,
      latestFormulaEvaluationMonthKey,
      formulaEntitiesById,
      extDrivers,
      layerId,
      layerFormulaCache: FormulaCacheSingleton.forLayer(layerId),
      submodelIdByBlockId,
      ...objectConstructorProps,
    };
  },
)(getCacheKeyForLayerSelector);

export const formulaEvaluatorForLayerSelector: SelectorWithLayerParam<FormulaEvaluator> =
  createCachedSelector(
    addLayerParams(formulaEvaluatorConstructorPropsForLayerSelector),
    addLayerParams(impactingEventsByFormulaEntityIdForLayerSelector),
    function formulaEvaluatorForLayerSelector(formulaEvaluatorConstructorProps, eventsByEntityId) {
      return new FormulaEvaluator({ ...formulaEvaluatorConstructorProps, eventsByEntityId });
    },
  )(getCacheKeyForLayerSelector);

export const formulaEvaluatorWithoutLiveEditingSelector: SelectorWithLayerParam<FormulaEvaluator> =
  createSelector(
    formulaEvaluatorConstructorPropsForLayerSelector,
    visibleEventsWithoutLiveEditsByEntityIdSelector,
    function formulaEvaluatorWithoutLiveEditingSelector(
      formulaEvaluatorConstructorProps,
      eventsByEntityId,
    ) {
      return new FormulaEvaluator({
        ...formulaEvaluatorConstructorProps,
        eventsByEntityId,
      });
    },
  );
