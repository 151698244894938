import { DateTimeUnit } from 'luxon';
import React from 'react';

import MonthPicker, { MonthPickerProps } from 'components/MonthPicker/MonthPicker';
import QuarterPicker from 'components/MonthPicker/QuarterPicker';
import YearPicker from 'components/MonthPicker/YearPicker';

interface Props extends MonthPickerProps {
  dateType?: DateTimeUnit;
}

function componentForDateType(dateType: DateTimeUnit) {
  switch (dateType) {
    case 'quarter':
      return QuarterPicker;
    case 'year':
      return YearPicker;
    default:
      return MonthPicker;
  }
}

const DatePicker: React.FC<Props> = ({
  minDate,
  maxDate,
  selected,
  onDateSelect,
  focused = true,
  dateType = 'month',
}) => {
  const Picker = componentForDateType(dateType);
  return (
    <Picker
      minDate={minDate}
      maxDate={maxDate}
      selected={selected}
      onDateSelect={onDateSelect}
      focused={focused}
    />
  );
};

export default DatePicker;
