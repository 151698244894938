import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'AlertIcon',
  viewBox: '0 0 18 16',
  defaultProps: { color: '#EFAE6E' },
  path: (
    <svg>
      <path
        d="M7.25168 1.14697C8.01369 -0.224651 9.9863 -0.224653 10.7483 1.14697L17.3493 13.0287C18.0899 14.3618 17.1259 16 15.601 16H2.39903C0.87406 16 -0.0898759 14.3618 0.650714 13.0287L7.25168 1.14697Z"
        fill="currentColor"
      />
      <path
        d="M10.5 4H7.5L8 10H10L10.5 4ZM9.00007 14.169C9.7309 14.169 10.3822 13.5526 10.3921 12.777C10.3822 12.0213 9.7309 11.4048 9.00007 11.4048C8.22947 11.4048 7.59808 12.0213 7.60803 12.777C7.59808 13.5526 8.22947 14.169 9.00007 14.169Z"
        fill="white"
      />
    </svg>
  ),
});
