import { useToken } from '@chakra-ui/react';
import { useMemo } from 'react';

interface UseColorMapOptions {
  keys: string[];
  shades: number[];
  colorScale?: (key: string) => string;
}

export function useColorMapForDriverCharts(options: UseColorMapOptions): Record<string, string> {
  const { keys, shades, colorScale } = options;

  const colorTokens = useMemo(() => {
    const colorTokensSet = new Set<string>();

    keys.forEach((key) => {
      shades.forEach((shade) => {
        const colorBase = colorScale ? colorScale(key) : undefined;
        const colorToken = colorBase != null ? `${colorBase}.${shade}` : 'blue.100';
        colorTokensSet.add(colorToken);
      });
    });

    return Array.from(colorTokensSet);
  }, [keys, shades, colorScale]);

  const resolvedColors = useToken('colors', colorTokens);

  const colorMap = useMemo(() => {
    return colorTokens.reduce(
      (acc, token, index) => {
        acc[token] = resolvedColors[index];
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [colorTokens, resolvedColors]);

  return colorMap;
}
