import { deepEqual } from 'fast-equals';

import { AccessResourceId } from 'reduxStore/models/accessResources';
import { BlockId, BlocksPageId } from 'reduxStore/models/blocks';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectFieldId, BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DimensionId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId } from 'reduxStore/models/events';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { LayerId } from 'reduxStore/models/layers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';

export function paramSelector<T>() {
  let lastParam: T | null = null;
  return (_state: RootState, param: T) => {
    if (lastParam == null || !deepEqual(param, lastParam)) {
      lastParam = param;
    }
    return lastParam;
  };
}

export function fieldSelector<T, K extends keyof T>(k: K) {
  return (_state: RootState, param: T) => param[k];
}

export const accessResourceIdSelector = paramSelector<AccessResourceId>();
export const blocksPageIdSelector = paramSelector<BlocksPageId>();
export const blockIdSelector = paramSelector<BlockId>();
export const layerIdSelector = paramSelector<LayerId>();
export const dimensionIdSelector = paramSelector<DimensionId>();
export const driverIdSelector = paramSelector<DriverId>();
export const businessObjectIdSelector = paramSelector<BusinessObjectId>();
export const businessObjectSpecIdSelector = paramSelector<BusinessObjectSpecId>();
export const businessObjectFieldIdSelector = paramSelector<BusinessObjectFieldId>();
export const eventGroupIdSelector = paramSelector<EventGroupId>();
export const extDriverIdSelector = paramSelector<ExtDriverId>();
export const submodelIdSelector = paramSelector<SubmodelId>();
