import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';

import DriverPropertiesComparisons from 'components/CompareScenariosModalContent/DriverPropertiesComparisons';
import DriversComparisonGrid from 'components/CompareScenariosModalContent/DriversComparisonGrid';

const DriverComparisons = () => {
  return (
    <Flex flexDirection="column" gap={4}>
      <Heading fontSize="lg">Drivers</Heading>
      <DriversComparisonGrid />
      <DriverPropertiesComparisons />
    </Flex>
  );
};

export default React.memo(DriverComparisons);
