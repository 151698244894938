import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import remove from 'lodash/remove';

import {
  ExtDriverCreateInput,
  ExtDriverDeleteInput,
  ExtDriverUpdateInput,
} from 'generated/graphql';
import { extractMonthKey } from 'helpers/dates';
import { convertNumericTimeSeries } from 'helpers/gqlDataset';
import { toExtSource } from 'helpers/integrations';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { ExtDriver } from 'reduxStore/models/extDrivers';
import { DEFAULT_LAYER_ID, DefaultLayer, Layer } from 'reduxStore/models/layers';
import { valueToNumericTimeSeries } from 'reduxStore/models/timeSeries';

export function setExtDriversFromDatasetSnapshot(layer: Draft<Layer>, dataset: DatasetSnapshot) {
  if (dataset == null) {
    layer.extDrivers = { byId: {}, allIds: [] };
    return;
  }

  const extDrivers = dataset.extDrivers.map((extDriver) => {
    return {
      id: extDriver.id,
      source: toExtSource(extDriver.source),
      model: extDriver.model,
      path: extDriver.path,
      timeSeries: valueToNumericTimeSeries(extDriver.timeSeries ?? {}),
      query: extDriver.query ?? undefined,
      integrationQueryId: extDriver.integrationQueryId ?? undefined,
      accountId: extDriver.accountId ?? undefined,
    };
  });

  layer.extDrivers = {
    byId: keyBy(extDrivers, 'id'),
    allIds: extDrivers.map((e) => e.id),
  };
}

export function handleCreateExtDriver(
  defaultLayer: Draft<DefaultLayer>,
  newExtDriverInput: ExtDriverCreateInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not create extDriver on non-default layer');
  }
  const { id, source, path, model, timeSeries, query, integrationQueryId, accountId } =
    newExtDriverInput;
  const driver: ExtDriver = {
    id,
    source: toExtSource(source),
    path,
    model,
    timeSeries: convertNumericTimeSeries(timeSeries ?? []),
    query: query ?? undefined,
    integrationQueryId: integrationQueryId ?? undefined,
    accountId: accountId ?? undefined,
  };

  defaultLayer.extDrivers.allIds.push(driver.id);
  defaultLayer.extDrivers.byId[driver.id] = driver;
}

export function handleUpdateExtDriver(
  defaultLayer: Draft<DefaultLayer>,
  updateExtDriverInput: ExtDriverUpdateInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not create extDriver on non-default layer');
  }
  const { id, path, timeSeries, query, accountId } = updateExtDriverInput;
  const existingDriver = defaultLayer.extDrivers.byId[id];
  if (existingDriver == null) {
    return;
  }

  if (path != null) {
    existingDriver.path = path;
  }

  if (accountId != null) {
    existingDriver.accountId = accountId;
  }

  timeSeries?.forEach((point) => {
    if (existingDriver.timeSeries == null) {
      existingDriver.timeSeries = {};
    }

    const monthKey = extractMonthKey(point.time);
    if (point.value == null) {
      delete existingDriver.timeSeries[monthKey];
    } else {
      existingDriver.timeSeries[monthKey] = Number(point.value);
    }
  });

  if (query != null) {
    existingDriver.query = query;
  }
}

export function handleDeleteExtDriver(
  defaultLayer: Draft<DefaultLayer>,
  deleteExtDriverInput: ExtDriverDeleteInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not create extDriver on non-default layer');
  }
  const { id } = deleteExtDriverInput;
  const driver = defaultLayer.extDrivers.byId[id];
  if (driver == null) {
    return;
  }

  delete defaultLayer.extDrivers.byId[id];
  remove(defaultLayer.extDrivers.allIds, (i) => i === id);

  // TODO: We may need to modify deletedIdentifiers to allow for more info
  // such as path, model , source
  const name = last(driver.path);
  if (name != null) {
    defaultLayer.deletedIdentifiers[id] = name;
  }
}
