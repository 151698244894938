import { useCallback, useMemo, useState } from 'react';

export type EnumArray<T extends readonly unknown[]> =
  T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export type EnumSetter<T extends string> = Record<T, () => void>;

const useEnum = <T extends string, D extends T>(
  values: readonly T[],
  defaultValue?: D,
): [T | null, EnumSetter<T>, () => void] => {
  const [val, setVal] = useState<T | null>(defaultValue ?? null);

  const setter = useMemo(
    () =>
      Object.fromEntries(
        values.map((el) => {
          return [el, () => setVal(el)];
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values,
  );

  const clear = useCallback(() => setVal(null), []);

  return [val, setter as EnumSetter<T>, clear];
};

export default useEnum;
