import { BlockViewAsType, ChartDisplay, ChartSize, Maybe } from 'generated/graphql';

export type MenuState =
  | 'customize'
  | 'label'
  | 'axes'
  | 'groups'
  | 'legend'
  | 'advanced'
  | 'scenarios';
export const MENU_HEADER_LABELS: Record<MenuState, string> = {
  customize: 'Customize chart',
  label: 'Label settings',
  axes: 'Axis settings',
  groups: 'Group settings',
  legend: 'Legend settings',
  advanced: 'Advanced settings',
  scenarios: 'Scenario comparisons',
};
export type DeprecatedChartTypes = BlockViewAsType.Chart;
export const CHART_TYPE_LABELS: Record<Exclude<BlockViewAsType, DeprecatedChartTypes>, string> = {
  LINE_CHART: 'Line',
  AREA_CHART: 'Area',
  BAR_CHART: 'Bar',
  COMBO_CHART: 'Combination',
  CURRENT_VALUE: 'Value',
  PIE_CHART: 'Pie',
  DONUT_CHART: 'Donut',
  NIGHTINGALE_CHART: 'Nightingale',
  TREEMAP_CHART: 'Treemap',
  WATERFALL_CHART: 'Waterfall',
};

export const CHART_SIZE_LABELS: Record<Exclude<ChartSize, ChartSize.ExtraLarge>, string> = {
  LARGE: 'Large',
  MEDIUM: 'Medium',
  // EXTRA_LARGE is omitted because it is the static size of multidriver charts.
  // Someday multidriver charts might be resizable, but not currently supported.
};

export const CHART_SIZE_LABELS_WITH_STATIC: Record<ChartSize, string> = {
  [ChartSize.ExtraLarge]: 'Extra large',
  LARGE: 'Large',
  MEDIUM: 'Medium',
};

export const CHART_CAN_BE_NORMALIZED: Record<BlockViewAsType, boolean> = {
  AREA_CHART: true,
  BAR_CHART: true,
  COMBO_CHART: true,
  CURRENT_VALUE: false,
  LINE_CHART: false,
  PIE_CHART: false,
  DONUT_CHART: false,
  NIGHTINGALE_CHART: false,
  TREEMAP_CHART: false,
  WATERFALL_CHART: false,
  // Depecrated
  CHART: false,
};
export const CHART_CAN_BE_RESIZED: Record<BlockViewAsType, boolean> = {
  AREA_CHART: true,
  BAR_CHART: true,
  COMBO_CHART: false,
  CURRENT_VALUE: false,
  LINE_CHART: true,
  PIE_CHART: true,
  DONUT_CHART: true,
  NIGHTINGALE_CHART: true,
  TREEMAP_CHART: true,
  WATERFALL_CHART: true,
  // Depecrated
  CHART: true,
};

export const CHART_CAN_SWITCH_SINGLE_MULTI_DRIVER: Record<BlockViewAsType, boolean> = {
  [BlockViewAsType.AreaChart]: true,
  [BlockViewAsType.BarChart]: true,
  [BlockViewAsType.ComboChart]: false,
  [BlockViewAsType.CurrentValue]: false,
  [BlockViewAsType.LineChart]: true,
  [BlockViewAsType.PieChart]: true,
  [BlockViewAsType.DonutChart]: true,
  [BlockViewAsType.NightingaleChart]: true,
  [BlockViewAsType.TreemapChart]: true,
  [BlockViewAsType.WaterfallChart]: false,
  [BlockViewAsType.Chart]: true,
};

export const ensureChartDisplay = (chartDisplay: Maybe<ChartDisplay> | undefined): ChartDisplay => {
  const options: ChartDisplay = {
    axes: [],
    series: [],
    groups: [],
    ...chartDisplay,
  };

  return options;
};
