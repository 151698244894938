import { Flex } from '@chakra-ui/react';
import groupBy from 'lodash/groupBy';

import TimeSeriesHeaderLabel, {
  TimeSeriesHeaderDataType,
} from 'components/TimeSeriesHeaderLabel/TimeSeriesHeaderLabel';
import TimeSeriesSubheaderLabel from 'components/TimeSeriesHeaderLabel/TimeSeriesSubheaderLabel';
import {
  CELL_DATA_COLUMN_WIDTH_IN_PX,
  CELL_HEIGHT_IN_PX,
  COLUMN_HEADER_CELL_HEIGHT_PX,
} from 'config/cells';
import { getComparisonColumnName } from 'helpers/blockComparisons';
import { getMonthColumnKey, stringifyMonthColumnKey } from 'helpers/cells';
import {
  TimeSeriesColumn,
  TimeSeriesComparisonSubColumn,
  getRollupTooltipLabel,
} from 'helpers/rollups';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { isLayerNamedVersionSelector, layerNameByIdSelector } from 'selectors/layerSelector';
import { allComparisonLayersAreNamedVersionsForBlockSelector } from 'selectors/rollupSelector';

interface Props {
  columns: TimeSeriesColumn[];
  dataType?: TimeSeriesHeaderDataType;
}

// For rendering out column headers for specificially column headers
const TimeSeriesColumnHeaders: React.FC<Props> = ({ columns, dataType }) => {
  const columnsByLabel = groupBy(columns, (column) => column.uniqueLabel ?? column.label);
  const lastActualsMonthKey = useAppSelector(lastActualsMonthKeyForLayerSelector);
  const hasSubLabels = columns.some((col) => col.subLabel != null);

  return (
    <>
      {Object.entries(columnsByLabel).map(([uniqueLabel, comparisonColumns]) => {
        const monthKeys = comparisonColumns[0].mks;
        const label = comparisonColumns[0].label;
        const isActuals = monthKeys[monthKeys.length - 1] <= lastActualsMonthKey;

        const rt = comparisonColumns[0].rollupType;
        const isRollupColumn = ['MONTH', 'LAST_CLOSE'].includes(rt);
        const showLastActualsMonth =
          monthKeys.length === 1 && isRollupColumn && monthKeys.includes(lastActualsMonthKey);

        const rollupLabel = getRollupTooltipLabel(rt, uniqueLabel);

        return (
          <Flex
            id="timeseries-column-headers"
            key={uniqueLabel}
            flexDir="column"
            width="fit-content"
            height={toPxString(COLUMN_HEADER_CELL_HEIGHT_PX)}
            data-testid="driver-timeseries-column-headers"
          >
            <Flex
              key={uniqueLabel}
              data-testid="timeseries-column-header-label"
              height={toPxString(COLUMN_HEADER_CELL_HEIGHT_PX)}
              width={toPxString(CELL_DATA_COLUMN_WIDTH_IN_PX * comparisonColumns.length)}
              justifyContent="flex-end"
              alignItems="flex-end"
              position="relative"
              pt={3}
              pl={2}
              pr={3}
              borderBottomWidth="px"
              borderBottomStyle="solid"
              borderBottomColor={isActuals ? 'gray.400' : 'blue.300'}
            >
              <TimeSeriesHeaderLabel
                label={label}
                isLastActualsMonth={showLastActualsMonth}
                isActuals={isActuals}
                dataType={dataType}
                rollupLabel={rollupLabel}
              />
            </Flex>
            {hasSubLabels && (
              <Flex data-testid="timeseries-column-header-sublabels">
                {comparisonColumns.map(({ mks, rollupType, subLabel }, index) => (
                  <TimeSeriesHeaderSubLabel
                    key={stringifyMonthColumnKey(getMonthColumnKey(mks[0], rollupType, subLabel))}
                    subLabel={subLabel}
                    isActuals={isActuals}
                    isEnd={index === comparisonColumns.length - 1}
                  />
                ))}
              </Flex>
            )}
          </Flex>
        );
      })}
    </>
  );
};

interface SubLabelProps {
  subLabel?: TimeSeriesComparisonSubColumn;
  isActuals: boolean;
  isEnd: boolean;
}

export const TIME_SERIES_HEADER_SUB_LABEL_HEIGHT = CELL_HEIGHT_IN_PX;

const TimeSeriesHeaderSubLabel: React.FC<SubLabelProps> = ({ subLabel, isActuals, isEnd }) => {
  const { blockId } = useBlockContext();

  const isLayerNamedVersion = useAppSelector(
    (state) => subLabel?.layerId != null && isLayerNamedVersionSelector(state, subLabel.layerId),
  );

  const areNamedVersions = useAppSelector((state) =>
    allComparisonLayersAreNamedVersionsForBlockSelector(state, blockId),
  );
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const layerNameById = useAppSelector(layerNameByIdSelector);

  const label = getComparisonColumnName({
    subLabel,
    areNamedVersions,
    layerNameById,
  });

  const actualsRightBorderColor = isEnd ? 'gray.400' : 'gray.300';

  const contentType = isLayerNamedVersion
    ? 'namedVersion'
    : subLabel?.layerId != null
      ? 'layerName'
      : 'comparisonType';

  return (
    <Flex
      width={toPxString(CELL_DATA_COLUMN_WIDTH_IN_PX)}
      height={toPxString(TIME_SERIES_HEADER_SUB_LABEL_HEIGHT)}
      justifyContent="flex-end"
      alignItems="center"
      pl={4}
      pr={1}
      borderRightWidth="px"
      borderRightStyle={isEnd ? 'solid' : 'dashed'}
      borderRightColor={isActuals ? actualsRightBorderColor : 'blue.300'}
      borderBottom="1px solid"
      borderBottomColor={isActuals ? 'gray.400' : 'blue.300'}
      data-testid="timeseries-header-sublabel"
    >
      <TimeSeriesSubheaderLabel
        isActuals={subLabel?.isActuals}
        label={label}
        isBaselineLayer={baselineLayerId === subLabel?.layerId}
        contentType={contentType}
      />
    </Flex>
  );
};

export default TimeSeriesColumnHeaders;
