import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverId } from 'reduxStore/models/drivers';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import {
  PageState,
  isBlocksPage,
  isDataSourcePage,
  isSubmodelPage,
} from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';

export const PAGE_TYPE_TO_PATH = {
  submodelPage: 'submodel',
  blocksPage: 'page',
  dataSourcePage: 'source',
  templatesPage: 'templates',
  plansPage: 'plans',
  unlistedDriversPage: 'unlistedDrivers',
  empty: '',
} as const;

export type ParsedQueryInput = {
  detail?: PaneType;
  detailId?: DriverId | BusinessObjectSpecId | BusinessObjectId;
  compareTo?: LayerId[];
};

export interface NavigateOverrides {
  layerId?: LayerId;
  pageType?: NonNullable<PageState>['type'] | null;
  id?: string;
}

export function buildUrlFromState(
  state: RootState,
  overrides: NavigateOverrides,
  queryParams?: ParsedQueryInput,
) {
  const selectedOrg = selectedOrgSelector(state);

  if (selectedOrg == null) {
    return null;
  }

  const page = pageSelector(state);
  let id: string | undefined;
  if ('id' in overrides) {
    id = overrides.id;
  } else if (isBlocksPage(page) || isSubmodelPage(page)) {
    id = page.id;
  } else if (isDataSourcePage(page)) {
    id = page.source;
  }

  return {
    pathname: buildURL(
      selectedOrg.slug,
      overrides.layerId ?? currentLayerIdSelector(state),
      'pageType' in overrides ? overrides.pageType : page?.type,
      id,
    ),
    query: queryParams,
  };
}

export function buildURL(
  slug: string | undefined,
  layerId: LayerId,
  type?: NonNullable<PageState>['type'] | null,
  id?: string,
): URL['href'] {
  if (slug == null) {
    return '/';
  }

  const layerComponents = layerId !== DEFAULT_LAYER_ID ? ['proposal', layerId] : null;
  const layerPath = layerComponents != null ? `${layerComponents.join('/')}/` : '';

  const typePath = type != null ? `${PAGE_TYPE_TO_PATH[type]}/` : '';

  const idPath = type != null && id != null ? id : '';

  return `/${slug}/${layerPath}${typePath}${idPath}`;
}
