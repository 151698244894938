import { useBoolean } from '@chakra-ui/react';
import { PropGetter } from '@chakra-ui/react-utils';
import React, { useCallback, useEffect, useRef } from 'react';

import EditDriverDimensions from 'components/SubmodelTable/EditDriverDimensions';
import TableCellPopover from 'components/TableCellPopover/TableCellPopover';
import { CellRef } from 'config/cells';
import { DimDriverEditReactContext } from 'config/dimDriverEditContext';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { hasTableCellPopoverOpen } from 'helpers/formula';
import useAddDriverRow from 'hooks/useAddDriverRow';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import useDimDriverEditor, { OnError } from 'hooks/useDimDriverEditor';
import useDriverCellRef from 'hooks/useDriverCellRef';
import { useShakeControls } from 'hooks/useShakeControls';
import useTableCellPopover from 'hooks/useTableCellPopover';
import { jumpToDriver } from 'reduxStore/actions/jumpToDriver';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { clearEditingDriver } from 'reduxStore/reducers/modelViewSlice';
import { isEditingDriverSelector } from 'selectors/modelViewSelector';
import { isAutoFocusedCreateDriverSelector } from 'selectors/pageSelector';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

const usePopover = ({
  cellRef,
  isEditable,
  driverId,
  groupId,
}: {
  cellRef: CellRef;
  isEditable: boolean;
  driverId?: DriverId;
  groupId?: DriverGroupId;
}) => {
  const { showPopover, closePopover, openPopover } = useTableCellPopover(cellRef, {
    isEditable,
  });

  const isNewDriver = driverId == null;

  // This comes from the `DriverDimensionsMenu` Edit/Add dimensions
  const openDimMenu = useAppSelector((state) =>
    !isNewDriver ? isEditingDriverSelector(state, driverId) : false,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (openDimMenu && !showPopover) {
      openPopover();
    }
  }, [dispatch, openDimMenu, openPopover, showPopover]);

  const autoFocus = useAppSelector((state) => isAutoFocusedCreateDriverSelector(state, groupId));
  useEffect(() => {
    if (!isNewDriver) {
      return;
    }
    if (autoFocus) {
      openPopover();
    }
  }, [autoFocus, isNewDriver, openPopover]);

  const openPopoverCallback = useCallback(
    (ev?: React.MouseEvent) => {
      if (!isEditable || hasTableCellPopoverOpen()) {
        return;
      }
      ev?.stopPropagation();
      openPopover();
    },
    [isEditable, openPopover],
  );

  const closePopoverCallback = useCallback(() => {
    closePopover();
    dispatch(clearEditingDriver());
  }, [closePopover, dispatch]);

  const { isSelected } = useCellSelectionStateContext();
  useAddDriverRow({
    isAdding: showPopover,
    onEnter: isNewDriver ? openPopoverCallback : undefined,
    isSelected,
  });

  return {
    showPopover,
    openDimMenu,
    openPopover: openPopoverCallback,
    closePopover: closePopoverCallback,
  };
};

interface EditDriverPopoverProps {
  openOn: 'click' | 'doubleClick';
  belowDriverId?: DriverId;
  groupId?: DriverGroupId;
  isEditable: boolean;
  driverId?: DriverId;
}
const EditDriverPopover: React.FC<React.PropsWithChildren<EditDriverPopoverProps>> = ({
  openOn,
  children,
  belowDriverId,
  groupId,
  isEditable,
  driverId,
}) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const cellRef = useDriverCellRef({ columnType: NAME_COLUMN_TYPE, columnLayerId: undefined });
  const { showPopover, openDimMenu, openPopover, closePopover } = usePopover({
    cellRef,
    isEditable,
    driverId,
    groupId,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showPopover) {
      inputRef.current?.focus();
    }
  }, [showPopover]);

  const [attemptedSubmit, setAttemptedSubmit] = useBoolean(false);

  const { controls, shake } = useShakeControls();

  const focusOnExistingDriver = useCallback(
    (existingSubDriverId: DriverId) => {
      dispatch(
        jumpToDriver({
          driverId: existingSubDriverId,
          opts: { openInNewTab: false },
          blockId,
        }),
      );
      closePopover();
    },
    [blockId, closePopover, dispatch],
  );

  const onError: OnError = useCallback(
    (_message?: string, extras?) => {
      if (extras?.existingTargetSubDriverId != null) {
        focusOnExistingDriver(extras?.existingTargetSubDriverId);
        return;
      }
      shake();
      window.requestAnimationFrame(() => inputRef.current?.focus());
      setAttemptedSubmit.on();
    },
    [focusOnExistingDriver, setAttemptedSubmit, shake],
  );

  const onDoneSuccessful = useCallback(() => {
    closePopover();
    setAttemptedSubmit.off();
  }, [closePopover, setAttemptedSubmit]);

  const isNewDriver = driverId == null;

  const { dimDriverError, dimDriverContext, onSaveDimDriver, subDriverName, reset } =
    useDimDriverEditor({
      driverId,
      onDone: onDoneSuccessful,
      onError,
      belowDriverId,
      groupId,
      resetOnDone: isNewDriver,
    });

  const onCancel = useCallback(() => {
    onDoneSuccessful();
    reset();
  }, [onDoneSuccessful, reset]);

  const shouldCancelOnClickOutside = isNewDriver && subDriverName === '';

  const onClickOutside = useCallback(() => {
    if (!showPopover) {
      return;
    }

    if (shouldCancelOnClickOutside) {
      onCancel();
      return;
    }

    if (dimDriverError != null) {
      onError();
      return;
    }

    onSaveDimDriver({ focusOnExistingDriver: true });
  }, [dimDriverError, onCancel, onError, onSaveDimDriver, shouldCancelOnClickOutside, showPopover]);

  const width = useAppSelector((state) =>
    columnWidthSelector(state, { columnType: NAME_COLUMN_TYPE, blockId }),
  );

  const child = React.Children.only(children) as React.ReactElement;

  const trigger = React.cloneElement(child, {
    ...child.props,
    ...(openOn === 'click' ? { onClick: openPopover } : { onDoubleClick: openPopover }),
  } as ReturnType<PropGetter>);

  return (
    <DimDriverEditReactContext.Provider value={dimDriverContext}>
      <TableCellPopover
        visible={showPopover}
        autoFocus={false}
        onClose={onCancel}
        onClickOutside={onClickOutside}
        minWidth={Math.max(180, width)}
        errorMessage={attemptedSubmit ? dimDriverError : undefined}
        animationControls={controls}
        content={
          <EditDriverDimensions
            driverId={driverId}
            onCancel={onCancel}
            dimMenuIsDefaultOpen={openDimMenu}
            inputRef={inputRef}
          />
        }
      >
        {trigger}
      </TableCellPopover>
    </DimDriverEditReactContext.Provider>
  );
};

export default React.memo(EditDriverPopover);
