import { Middleware, isAction, isAnyOf } from '@reduxjs/toolkit';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import {
  extractMutation,
  getKeysToInvalidate,
  handleClearCacheForFormulaCacheSingleton,
} from 'helpers/formulaCache';
import {
  eventLiveEditPoint,
  startLiveEditingExistingEvent,
  startLiveEditingMultipleEvents,
} from 'reduxStore/reducers/liveEditSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { shouldDoSynchronousCalculationsSelector } from 'selectors/inspectorSelector';

/**
 * Main thread cache should be cleared *before* running next(action) so that
 * the cache does not have old state when the next action is run. The
 * calculationsSlice should be updated *after* the action is committed as
 * otherwise the `clearCacheKeyValues` action will finish before it and cause
 * state & selectors to re-evaluate before the current action is committed.
 * This can cause some unexpected behavior as some selectors may cause
 * calculations to run which will end up repopulating the cache with the old
 * values again.
 *
 * If cache is being cleared by this function, the lines around `return` should
 * look like
 * ```
 *   handleClearCache('main', ...);
 *   const updatedState = next(action);
 *   handleClearCache('webworker', ...);
 *
 *   return updatedState;
 * ```
 *
 */
const formulaCacheInvalidator: Middleware<unknown, RootState> =
  (store) => (next) => (action: unknown) => {
    if (!isAction(action)) {
      return next(action);
    }

    const state = store.getState();

    const extractedMutation = extractMutation(state, action);
    const mutation = extractedMutation?.mutation;
    const isUndone = extractedMutation?.isUndone ?? false;
    if (mutation != null) {
      if (isUndone) {
        DataArbiter.get().forwardUndoneMutation(mutation);
      } else {
        DataArbiter.get().forwardMutation(mutation);
      }
    }

    if (
      (shouldDoSynchronousCalculationsSelector(state) && mutation != null) ||
      // Handle getting keys to invalidate in main thread for live edits, so we don't
      // have to round trip to WW. Note that this will be very slow for big orgs,
      // but this feature is primarily used for demos.
      isAnyOf(
        eventLiveEditPoint,
        startLiveEditingExistingEvent,
        startLiveEditingMultipleEvents,
      )(action)
    ) {
      const { keysToInvalidate, newState } = getKeysToInvalidate(state, action);

      if (keysToInvalidate != null) {
        handleClearCacheForFormulaCacheSingleton({
          thread: 'main',
          state: newState ?? state,
          keysToInvalidate,
        });
      }
    }

    return next(action);
  };

export default formulaCacheInvalidator;
