import * as Sentry from '@sentry/nextjs';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import {
  ExtModel,
  ExtStaticSource,
  Integration,
  IntegrationCategory,
  IntegrationProvider,
  IntegrationStatus,
  LinkedAccount,
} from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import { ExtDriverSource } from 'reduxStore/models/extDrivers';

declare const validExtSource: unique symbol;
// This type can't be constructed unless you have access to the
// above symbol, which is _not_ exported. This forces users to use
// one of the asserts or translations below.
// See: https://evertpot.com/opaque-ts-types/
export type ExtSource =
  | ExtStaticSource
  | (string & {
      [validExtSource]: true;
    });

export const assertExtSource = (source: string): source is ExtSource => {
  const staticSourceStrs = Object.values(ExtStaticSource) as string[];
  if (staticSourceStrs.includes(source)) {
    return true;
  }

  for (const integrationProvider of Object.values(IntegrationProvider)) {
    if (source.startsWith(integrationProvider.toUpperCase() + '_')) {
      return true;
    }
  }

  return false;
};

export const toExtSource = (source: string): ExtSource => {
  if (assertExtSource(source)) {
    return source;
  }

  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setLevel('warning');
    scope.setExtra('sourceStr', source);
    Sentry.captureMessage('forcing malformed string to ext source');
  });

  return source as ExtSource;
};

export const getExtSource = (integration: {
  provider: IntegrationProvider;
  slug: string;
}): ExtSource => {
  const { provider, slug } = integration;
  switch (provider) {
    case IntegrationProvider.Puzzle:
      return 'PUZZLE_ACCOUNTING' as ExtSource;
    case IntegrationProvider.Rippling:
      return 'RIPPLING' as ExtSource;
    case IntegrationProvider.RunwayApi:
      return 'RUNWAY_API' as ExtSource;
    case IntegrationProvider.Workato:
      if (integration.slug === 'workato_netsuite') {
        return ExtStaticSource.WorkatoNetsuite;
      }
      break;
    case IntegrationProvider.Merge:
    case IntegrationProvider.Fivetran:
    default:
  }

  const suffix = slug.replaceAll('-', '_').toUpperCase();
  const extSourceStr = `${provider}_${suffix}`;
  assertExtSource(extSourceStr);
  return extSourceStr as ExtSource;
};

const MERGE_CATEGORIES_TO_DISPLAY_NAME: Record<IntegrationCategory, string> = {
  accounting: 'Accounting',
  analytics: 'Analytics',
  ats: 'ATS',
  billing: 'Billing',
  bisolutions: 'BI Solutions',
  crm: 'CRM',
  datawarehouse: 'Data Warehouse',
  filestorage: 'File Storage',
  hris: 'HRIS',
  spreadsheet: 'Spreadsheet',
  ticketing: 'Ticketing',
  uncategorized: 'Other',
};

export const CATEGORY_DISPLAY_ORDER: IntegrationCategory[] = Object.keys(
  MERGE_CATEGORIES_TO_DISPLAY_NAME,
) as IntegrationCategory[];

const EXT_MODEL_TO_DISPLAY_NAME: Record<ExtModel, string> = {
  [ExtModel.Account]: 'Account',
  [ExtModel.AccountingAttachment]: 'Accounting Attachment',
  [ExtModel.AccountingPhoneNumber]: 'Accounting Phone Number',
  [ExtModel.Address]: 'Address',
  [ExtModel.BalanceSheet]: 'Balance Sheet',
  [ExtModel.BankInfo]: 'Bank Info',
  [ExtModel.CashFlowStatement]: 'Cash Flow Statement',
  [ExtModel.Comment]: 'Comment',
  [ExtModel.CompanyInfo]: 'Company Info',
  [ExtModel.Contact]: 'Contact',
  [ExtModel.CreditNote]: 'Credit Note',
  [ExtModel.CrmAccount]: 'Account',
  [ExtModel.CrmContact]: 'Contact',
  [ExtModel.CrmUser]: 'User',
  [ExtModel.Department]: 'Department',
  [ExtModel.Employee]: 'Employee',
  [ExtModel.Employment]: 'Employment',
  [ExtModel.ExpenseLine]: 'Expense Line',
  [ExtModel.Expense]: 'Expense',
  [ExtModel.Group]: 'Group',
  [ExtModel.IncomeStatement]: 'Income Statement',
  [ExtModel.InvoiceLineItem]: 'Invoice Line Item',
  [ExtModel.Invoice]: 'Invoice',
  [ExtModel.Item]: 'Item',
  [ExtModel.Job]: 'Job',
  [ExtModel.JournalEntry]: 'Journal Entry',
  [ExtModel.JournalLine]: 'Journal Line',
  [ExtModel.LeadSource]: 'Lead Source',
  [ExtModel.Location]: 'Location',
  [ExtModel.Note]: 'Note',
  [ExtModel.Office]: 'Office',
  [ExtModel.Opportunity]: 'Opportunity',
  [ExtModel.PayGroup]: 'Pay Group',
  [ExtModel.Payment]: 'Payment',
  [ExtModel.Project]: 'Project',
  [ExtModel.ProfitAndLossStatement]: 'Profit And Loss Statement',
  [ExtModel.PurchaseOrderLineItem]: 'Purchase Order Line Item',
  [ExtModel.PurchaseOrder]: 'Purchase Order',
  [ExtModel.RemoteUser]: 'Remote User',
  [ExtModel.ReportItem]: 'Report Item',
  [ExtModel.Stage]: 'Stage',
  [ExtModel.TaxRate]: 'TaxRate',
  [ExtModel.Team]: 'Team',
  [ExtModel.Ticket]: 'Ticket',
  [ExtModel.TicketingAttachment]: 'Ticketing Attachment',
  [ExtModel.TicketingTag]: 'Ticketing Tag',
  [ExtModel.TicketingTeam]: 'Ticketing Team',
  [ExtModel.TimeOff]: 'Time Off',
  [ExtModel.TrackingCategory]: 'Tracking Category',
  [ExtModel.User]: 'User',
  [ExtModel.VendorCreditLine]: 'Vendor Credit Line',
  [ExtModel.VendorCredit]: 'Vendor Credit',
  [ExtModel.RunwayCustomTransactionDrivers]: 'Custom Queries',
  [ExtModel.Unknown]: '',
};

export const groupIntegrationsByCategory = (
  integrations: Integration[],
): Record<IntegrationCategory, Integration[]> => {
  const groups: Record<IntegrationCategory, Integration[]> = {
    uncategorized: [],
    accounting: [],
    analytics: [],
    ats: [],
    billing: [],
    bisolutions: [],
    crm: [],
    datawarehouse: [],
    filestorage: [],
    hris: [],
    spreadsheet: [],
    ticketing: [],
  };
  integrations.forEach((integration) => {
    integration.categories.forEach((category) => {
      if (groups[category] == null) {
        groups[category] = [];
      }
      groups[category].push(integration);
    });
  });
  return groups;
};

// For now, assume only 1 linked account per integration
export const groupActiveIntegrationsByName = (accounts: LinkedAccount[]) => {
  return Object.fromEntries(accounts.map((acct) => [acct.integration.name, acct]));
};

export const getLinkedAccountsAndRemainingIntegrationsForCategory = ({
  availableIntegrations,
  linkedAccountByName,
}: {
  availableIntegrations: Integration[];
  linkedAccountByName: NullableRecord<string, LinkedAccount>;
}) => {
  const linkedAccounts = availableIntegrations
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((integration) => linkedAccountByName[integration.name])
    .filter(isNotNull);

  const remainingIntegrations: Integration[] = availableIntegrations.filter(
    (integration) =>
      linkedAccounts.find((acc) => acc.integration.name === integration.name) == null,
  );
  remainingIntegrations.sort((a, b) => (a.name > b.name ? 1 : -1));

  return {
    linkedAccounts,
    remainingIntegrations,
  };
};

export const mergeCategoryDisplayName = (category: IntegrationCategory): string => {
  return MERGE_CATEGORIES_TO_DISPLAY_NAME[category] ?? category;
};

export const extDriverSourceToSlug = (source: ExtDriverSource | undefined): string | undefined => {
  const isValidFiveTranSource = source != null && isFiveTranSource(source);
  if (isValidFiveTranSource) {
    return source.replace('FIVETRAN_', '').toLowerCase();
  }
  return EXT_DRIVER_SOURCE_TO_SLUG[source as ExtStaticSource] ?? undefined;
};

export const extDriverSourceDisplayName = (source: ExtDriverSource) => {
  if (source === ExtStaticSource.RunwayApi) {
    return 'Runway API';
  }

  const slug = extDriverSourceToSlug(source);
  return startCase(camelCase(slug));
};

export const linkedAccountDisplayName = (linkedAccount: LinkedAccount) => {
  let sourceTitle = linkedAccount.integration.name;
  if (linkedAccount.alias != null) {
    sourceTitle += ` (${linkedAccount.alias})`;
  }
  return sourceTitle;
};

export const extModelDisplayName = (report: ExtModel) => {
  return EXT_MODEL_TO_DISPLAY_NAME[report] ?? 'Uncategorized';
};

const EXT_DRIVER_SOURCE_TO_SLUG: Record<ExtStaticSource, string> = {
  [ExtStaticSource.MergeActivecampaign]: 'activecampaign',
  [ExtStaticSource.MergeAdpWorkforceNow]: 'adp-workforce-now',
  [ExtStaticSource.MergeAlexishr]: 'alexishr',
  [ExtStaticSource.MergeAlteraPayroll]: 'altera-payroll',
  [ExtStaticSource.MergeApplicantstack]: 'applicantstack',
  [ExtStaticSource.MergeAsana]: 'asana',
  [ExtStaticSource.MergeAshby]: 'ashby',
  [ExtStaticSource.MergeBamboohr]: 'bamboohr',
  [ExtStaticSource.MergeBizmerlinhr]: 'bizmerlinhr',
  [ExtStaticSource.MergeBreathe]: 'breathe',
  [ExtStaticSource.MergeBreezyHr]: 'breezy-hr',
  [ExtStaticSource.MergeCapsule]: 'capsule',
  [ExtStaticSource.MergeCats]: 'cats',
  [ExtStaticSource.MergeCeridianDayforce]: 'ceridian-dayforce',
  [ExtStaticSource.MergeCharliehr]: 'charliehr',
  [ExtStaticSource.MergeCharthop]: 'charthop',
  [ExtStaticSource.MergeClearBooks]: 'clear-books',
  [ExtStaticSource.MergeClickup]: 'clickup',
  [ExtStaticSource.MergeClockwork]: 'clockwork',
  [ExtStaticSource.MergeClose]: 'close',
  [ExtStaticSource.MergeComeet]: 'comeet',
  [ExtStaticSource.MergeCopper]: 'copper',
  [ExtStaticSource.MergeCornerstoneTalentlink]: 'cornerstone-talentlink',
  [ExtStaticSource.MergeDeel]: 'deel',
  [ExtStaticSource.MergeEploy]: 'eploy',
  [ExtStaticSource.MergeFactorial]: 'factorial',
  [ExtStaticSource.MergeFountain]: 'fountain',
  [ExtStaticSource.MergeFreshbooks]: 'freshbooks',
  [ExtStaticSource.MergeFreshdesk]: 'freshdesk',
  [ExtStaticSource.MergeFreshteam]: 'freshteam',
  [ExtStaticSource.MergeFront]: 'front',
  [ExtStaticSource.MergeGithubIssues]: 'github-issues',
  [ExtStaticSource.MergeGitlab]: 'gitlab',
  [ExtStaticSource.MergeGreenhouse]: 'greenhouse',
  [ExtStaticSource.MergeGreenhouseJobBoardsApi]: 'greenhouse-job-boards-api',
  [ExtStaticSource.MergeGusto]: 'gusto',
  [ExtStaticSource.MergeHibob]: 'hibob',
  [ExtStaticSource.MergeHive]: 'hive',
  [ExtStaticSource.MergeHomerun]: 'homerun',
  [ExtStaticSource.MergeHrCloud]: 'hr-cloud',
  [ExtStaticSource.MergeHrPartner]: 'hr-partner',
  [ExtStaticSource.MergeHubspot]: 'hubspot',
  [ExtStaticSource.MergeHumaansio]: 'humaansio',
  [ExtStaticSource.MergeHumaans]: 'humaans',
  [ExtStaticSource.MergeIntellihr]: 'intellihr',
  [ExtStaticSource.MergeIntercom]: 'intercom',
  [ExtStaticSource.MergeJazzhr]: 'jazzhr',
  [ExtStaticSource.MergeJiraServiceManagement]: 'jira-service-management',
  [ExtStaticSource.MergeJiraSoftware]: 'jira-software',
  [ExtStaticSource.MergeJobscore]: 'jobscore',
  [ExtStaticSource.MergeJobsoid]: 'jobsoid',
  [ExtStaticSource.MergeJobvite]: 'jobvite',
  [ExtStaticSource.MergeJustworks]: 'justworks',
  [ExtStaticSource.MergeKeka]: 'keka',
  [ExtStaticSource.MergeLano]: 'lano',
  [ExtStaticSource.MergeLever]: 'lever',
  [ExtStaticSource.MergeLinear]: 'linear',
  [ExtStaticSource.MergeLucca]: 'lucca',
  [ExtStaticSource.MergeMicrosoftDynamics_365]: 'microsoft-dynamics-365',
  [ExtStaticSource.MergeNamely]: 'namely',
  [ExtStaticSource.MergeNetsuite]: 'netsuite',
  [ExtStaticSource.MergeNmbrs]: 'nmbrs',
  [ExtStaticSource.MergeOracleTaleo]: 'oracle-taleo',
  [ExtStaticSource.MergePaychex]: 'paychex',
  [ExtStaticSource.MergePaylocity]: 'paylocity',
  [ExtStaticSource.MergePeoplehr]: 'peoplehr',
  [ExtStaticSource.MergePersonio]: 'personio',
  [ExtStaticSource.MergePipedrive]: 'pipedrive',
  [ExtStaticSource.MergePivotalTracker]: 'pivotal-tracker',
  [ExtStaticSource.MergePolymer]: 'polymer',
  [ExtStaticSource.MergeProliant]: 'proliant',
  [ExtStaticSource.MergeQuickbooksOnline]: 'quickbooks-online',
  [ExtStaticSource.MergeRecruitee]: 'recruitee',
  [ExtStaticSource.MergeRecruiterflow]: 'recruiterflow',
  [ExtStaticSource.MergeSageBusinessCloudAccounting]: 'sage-business-cloud-accounting',
  [ExtStaticSource.MergeSageHr]: 'sage-hr',
  [ExtStaticSource.MergeSageIntacct]: 'sage-intacct',
  [ExtStaticSource.MergeSalesflare]: 'salesflare',
  [ExtStaticSource.MergeSalesforce]: 'merge-salesforce',
  [ExtStaticSource.MergeSapling]: 'sapling',
  [ExtStaticSource.MergeSapSuccessfactors]: 'sap-successfactors',
  [ExtStaticSource.MergeServicenow]: 'servicenow',
  [ExtStaticSource.MergeSesame]: 'sesame',
  [ExtStaticSource.MergeSharpspring]: 'sharpspring',
  [ExtStaticSource.MergeShortcut]: 'shortcut',
  [ExtStaticSource.MergeSmartrecruiters]: 'smartrecruiters',
  [ExtStaticSource.MergeSquarePayroll]: 'square-payroll',
  [ExtStaticSource.MergeTalentlyft]: 'talentlyft',
  [ExtStaticSource.MergeTalentreef]: 'talentreef',
  [ExtStaticSource.MergeTeamleader]: 'teamleader',
  [ExtStaticSource.MergeTeamtailor]: 'teamtailor',
  [ExtStaticSource.MergeTeamwork]: 'teamwork',
  [ExtStaticSource.MergeTeamworkCrm]: 'teamwork-crm',
  [ExtStaticSource.MergeTrello]: 'trello',
  [ExtStaticSource.MergeTrinetHrPlatform]: 'trinet-hr-platform',
  [ExtStaticSource.MergeTrinet]: 'trinet',
  [ExtStaticSource.MergeUkgPro]: 'ukg-pro',
  [ExtStaticSource.MergeUkgProRecruiting]: 'ukg-pro-recruiting',
  [ExtStaticSource.MergeUkgReady]: 'ukg-ready',
  [ExtStaticSource.MergeWorkable]: 'workable',
  [ExtStaticSource.MergeWorkday]: 'workday',
  [ExtStaticSource.MergeWrike]: 'wrike',
  [ExtStaticSource.MergeXero]: 'xero',
  [ExtStaticSource.MergeZendesk]: 'zendesk',
  [ExtStaticSource.MergeZendeskSell]: 'zendesk-sell',
  [ExtStaticSource.MergeZenefits]: 'zenefits',
  [ExtStaticSource.MergeZohoBooks]: 'zoho-books',
  [ExtStaticSource.MergeZohoCrm]: 'zoho-crm',
  [ExtStaticSource.PuzzleAccounting]: 'puzzle',
  [ExtStaticSource.Rippling]: 'rippling',
  [ExtStaticSource.RunwayApi]: 'runway-api',
  [ExtStaticSource.WorkatoAirtable]: 'airtable',
  [ExtStaticSource.WorkatoAmazonS3]: 'amazon_s3',
  [ExtStaticSource.WorkatoAwsCostExplorer]: 'aws_cost_explorer',
  [ExtStaticSource.WorkatoAzureBlobStorage]: 'azure_blob_storage',
  [ExtStaticSource.WorkatoChargebeeAdmin]: 'chargebee_admin',
  [ExtStaticSource.WorkatoCoupa]: 'coupa',
  [ExtStaticSource.WorkatoFacebookLeadAds]: 'facebook_lead_ads',
  [ExtStaticSource.WorkatoHubspot]: 'hubspot',
  [ExtStaticSource.WorkatoHubspotDefault]: 'hubspot_default',
  [ExtStaticSource.WorkatoGoogleAds]: 'google_ads',
  [ExtStaticSource.WorkatoGoogleAnalytics]: 'google_analytics',
  [ExtStaticSource.WorkatoGoogleBigQuery]: 'google_big_query',
  [ExtStaticSource.WorkatoGoogleSheets]: 'google_sheets',
  [ExtStaticSource.WorkatoLooker]: 'looker',
  [ExtStaticSource.WorkatoNetsuite]: 'workato_netsuite',
  [ExtStaticSource.WorkatoOracle]: 'oracle',
  [ExtStaticSource.WorkatoOracleFinancialsCloud]: 'oracle_financials_cloud',
  [ExtStaticSource.WorkatoPipedrive]: 'pipedrive',
  [ExtStaticSource.WorkatoRamp]: 'ramp',
  [ExtStaticSource.WorkatoRedshift]: 'redshift',
  [ExtStaticSource.WorkatoRest]: 'rest',
  [ExtStaticSource.WorkatoSalesforce]: 'salesforce',
  [ExtStaticSource.WorkatoSnowflake]: 'snowflake',
  [ExtStaticSource.WorkatoStripe]: 'stripe',
  [ExtStaticSource.WorkatoTableau]: 'tableau',
  [ExtStaticSource.WorkatoZuora]: 'zuora',
  [ExtStaticSource.Amplitude]: 'amplitude',
} as const;

export const isMergeSource = (source: ExtSource) => source.startsWith('MERGE_');
export const isWorkatoSource = (source: ExtSource) => source.startsWith('WORKATO_');
export const isFiveTranSource = (source: ExtSource) => source.startsWith('FIVETRAN_');

export const getExtSourceProvider = (source: ExtSource): IntegrationProvider | undefined => {
  if (isMergeSource(source)) {
    return IntegrationProvider.Merge;
  }
  if (isWorkatoSource(source)) {
    return IntegrationProvider.Workato;
  }
  if (isFiveTranSource(source)) {
    return IntegrationProvider.Fivetran;
  }
  return undefined;
};
const INTEGRATION_TABS = [
  'syncedReports',
  'customQueries',
  'formattedOutput',
  'sqlOutput',
] as const;
export type IntegrationTab = (typeof INTEGRATION_TABS)[number];
const INTEGRATION_TAB_DISPLAY_NAMES: Record<IntegrationTab, string> = {
  syncedReports: 'Synced Reports',
  customQueries: 'Custom Queries',
  formattedOutput: 'Formatted Output',
  sqlOutput: 'SQL Output',
};
export const integrationTabDisplayName = (tab: IntegrationTab) =>
  INTEGRATION_TAB_DISPLAY_NAMES[tab];

export const isModelPartOfIntegrationTab = (model: ExtModel, tab: IntegrationTab) =>
  tab === 'customQueries'
    ? model === ExtModel.RunwayCustomTransactionDrivers
    : model !== ExtModel.RunwayCustomTransactionDrivers;

export const isIntegrationUnhealthy = (acct?: LinkedAccount) => {
  const mergeNotLinked =
    acct != null &&
    [IntegrationStatus.Incomplete, IntegrationStatus.RelinkNeeded].includes(acct.status);
  const noAccountID = acct?.id == null;
  return mergeNotLinked || noAccountID;
};

export enum SupportedQueryLanguages {
  JSON = 'json',
  SQL = 'sql',
  PlainText = 'plaintext',
}

const QUERY_WRITE_SUPPORTING_INTEGRATION_SOURCES: Map<ExtSource, SupportedQueryLanguages> = new Map(
  [
    [ExtStaticSource.WorkatoRedshift, SupportedQueryLanguages.SQL],
    [ExtStaticSource.WorkatoSnowflake, SupportedQueryLanguages.SQL],
    [ExtStaticSource.WorkatoNetsuite, SupportedQueryLanguages.SQL],
    [ExtStaticSource.WorkatoGoogleBigQuery, SupportedQueryLanguages.SQL],
  ],
);
const RAW_QUERY_WRITE_SUPPORTING_INTEGRATION_SOURCES: Map<ExtSource, SupportedQueryLanguages> =
  new Map(
    Object.values(ExtStaticSource)
      .filter((source) => source.startsWith('WORKATO_'))
      .map((workatoSource) => [workatoSource, SupportedQueryLanguages.JSON]),
  );
RAW_QUERY_WRITE_SUPPORTING_INTEGRATION_SOURCES.set(
  ExtStaticSource.WorkatoGoogleSheets,
  SupportedQueryLanguages.JSON,
);

const QUERY_READ_SUPPORTING_INTEGRATION_SOURCES: Map<ExtSource, SupportedQueryLanguages> = new Map([
  ...RAW_QUERY_WRITE_SUPPORTING_INTEGRATION_SOURCES.entries(),
  ...QUERY_WRITE_SUPPORTING_INTEGRATION_SOURCES.entries(),
]);

export function getDefaultQueryLanguage(
  source: ExtSource | null | undefined,
): SupportedQueryLanguages {
  if (source == null) {
    return SupportedQueryLanguages.PlainText;
  }
  if (isFiveTranSource(source)) {
    return SupportedQueryLanguages.SQL;
  }
  return QUERY_READ_SUPPORTING_INTEGRATION_SOURCES.get(source) ?? SupportedQueryLanguages.PlainText;
}

export function sourceSupportsQueryReads(source: ExtSource | undefined): boolean {
  if (source == null) {
    return false;
  }
  return QUERY_READ_SUPPORTING_INTEGRATION_SOURCES.has(source);
}

export function sourceSupportsQueryWrites(source: ExtSource | undefined) {
  if (source === undefined) {
    return false;
  }
  if (QUERY_WRITE_SUPPORTING_INTEGRATION_SOURCES.has(source)) {
    return true;
  }
  if (isFiveTranSource(source)) {
    return true;
  }
  if (RAW_QUERY_WRITE_SUPPORTING_INTEGRATION_SOURCES.has(source)) {
    return true;
  }
  return false;
}

export const contactSupport = () => {
  window.open('https://runway.height.app/?taskForm=Integration-Issues-and-Feedback-U6HkIfd3kmnr');
};
