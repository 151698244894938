import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export default {
  parts: ['popper', 'content'],
  defaultProps: {
    isLazy: true,
  },
  baseStyle: {
    popper: {
      maxW: 'unset',
      width: 'unset',
      zIndex: 'popover',
    },
    content: {
      bg: 'surface',
      border: 'none',
      borderRadius: '6px',
      boxShadow: 'menu',
      color: 'gray.600',
      py: 2,
      width: 'auto',
      _focusVisible: {
        boxShadow: 'menu',
        outline: 'none',
      },
    },
  },
} as ComponentMultiStyleConfig;
