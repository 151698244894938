import { memoize } from 'lodash';
import { createCachedSelector } from 're-reselect';

import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import { OptionalParametricSelector } from 'types/redux';

export type SelectorWithLayerParam<T> = OptionalParametricSelector<{ layerId?: LayerId }, T>;

export const getCacheKeyForLayerSelector = getGivenOrCurrentLayerId;

// convenience function for creating a layer-aware selector with a single dependency
export function layerSelectorFactory<T, Q>(
  sel: SelectorWithLayerParam<Q>,
  combiner: (res: Q) => T,
): SelectorWithLayerParam<T> {
  return createCachedSelector(
    (state: RootState, params: { layerId?: LayerId } | undefined) =>
      sel(state, layerParamMemo(getGivenOrCurrentLayerId(state, params))),
    combiner,
  )(getCacheKeyForLayerSelector);
}

export const layerParamMemo = memoize((layerId?: LayerId) =>
  layerId == null ? undefined : { layerId },
);
// convenience function for passing in a given layer param to layer-aware selector
export function addLayerParams<T>(sel: SelectorWithLayerParam<T>): SelectorWithLayerParam<T> {
  return (state: RootState, params: { layerId?: LayerId } | undefined) =>
    sel(state, layerParamMemo(getGivenOrCurrentLayerId(state, params)));
}
