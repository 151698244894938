import { DateTime } from 'luxon';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import {
  BlockResponse,
  IBaseDataSource,
  ObjectRowsResponse,
} from 'components/AgGridComponents/helpers/gridDatasource/types';
import { CalculationOpts } from 'generated/graphql';
import { getMonthKey } from 'helpers/dates';
import { getFormulaCacheKey } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import { EventId } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { MutationBatch } from 'reduxStore/models/mutations';
import {
  addToCalculationsQueue,
  removeFromCalculationsQueue,
} from 'reduxStore/reducers/calculationsSlice';
import { AppDispatch } from 'reduxStore/store';
import { RequestedCalculation } from 'workers/formulaCalculator/types';

export interface RequestValueProps {
  id: string;
  type: FormulaEntityTypedId['type'];
  dateRange: [DateTime, DateTime];
  layerId: LayerId;
  ignoreEventIds?: EventId[];

  includeObjectSpecEvaluation?: boolean;
  opts?: CalculationOpts;
}

export type DisposeFn = () => void;

interface WebWorkerDataProviderContext {
  requestBatch: (fn: () => void) => void;
  requestValue: (props: RequestValueProps) => DisposeFn;
}

export const WebWorkerDataProviderContext = createContext<WebWorkerDataProviderContext>({
  requestBatch: () => {},
  requestValue: () => () => {},
});

export const useWebWorkerDataProviderContext = () => useContext(WebWorkerDataProviderContext);

class Dispatcher implements IBaseDataSource {
  private id: string;
  private dispatch: AppDispatch;

  private addQueue: RequestedCalculation[];
  private addQueueKeys: Set<string>;
  private removeQueue: RequestedCalculation[];
  private removeQueueKeys: Set<string>;
  private addTimeoutId: NodeJS.Timeout | null;
  private removeTimeoutId: NodeJS.Timeout | null;
  private paused: boolean;
  private destroyed: boolean;

  constructor(dispatch: AppDispatch) {
    this.id = uuidv4();
    this.dispatch = dispatch;
    this.addQueue = [];
    this.addQueueKeys = new Set();
    this.removeQueue = [];
    this.removeQueueKeys = new Set();
    this.addTimeoutId = null;
    this.removeTimeoutId = null;
    this.paused = false;
    this.destroyed = false;

    DataArbiter.get().register(this);
  }

  public destroy() {
    this.destroyed = true;

    if (this.addTimeoutId) {
      clearTimeout(this.addTimeoutId);
    }
    if (this.removeTimeoutId) {
      clearTimeout(this.removeTimeoutId);
    }
    this.addTimeoutId = null;
    this.removeTimeoutId = null;

    DataArbiter.get().unregister(this);
  }

  public get instanceId(): string {
    return this.id;
  }

  // None of these are currently relevant for this data source implementation
  // as it doesn't currently track much state internally and instead just
  // exposes the ability to route data requests to the arbiter via `enqueue`.
  handleObjectRowsResponse(_res: ObjectRowsResponse, _isBackendCalculation?: boolean): void {}
  handleBlockResponse(_res: BlockResponse): void {}
  handleMutation(_mutation: MutationBatch): void {}
  handleUndoneMutation(_undoneMutation: MutationBatch): void {}

  public enqueue(props: RequestedCalculation) {
    if (this.destroyed) {
      return;
    }

    if (this.isQueued(props)) {
      return;
    }

    this.addQueue.push(props);
    this.addQueueKeys.add(queueItemKey(props));
    this.dispatchAdd();
  }

  public dequeue(props: RequestedCalculation) {
    if (this.destroyed) {
      return;
    }

    this.removeQueue.push(props);
    this.removeQueueKeys.add(queueItemKey(props));
    this.dispatchRemove();
  }

  public pause() {
    if (this.destroyed) {
      return;
    }

    this.paused = true;
  }

  public resume() {
    if (this.destroyed) {
      return;
    }

    this.paused = false;
    this.dispatchAdd();
    this.dispatchRemove();
  }

  // Internal helper functions

  private isQueued(req: RequestedCalculation) {
    const rangeCacheKey = queueItemKey(req);
    const isEnqueued = this.addQueueKeys.has(rangeCacheKey);
    const isDequeued = this.removeQueueKeys.has(rangeCacheKey);
    return isEnqueued && !isDequeued;
  }

  private dispatchAdd() {
    if (this.addQueue.length > 0 && this.addTimeoutId == null && !this.paused) {
      this.addTimeoutId = setTimeout(this.makeAddHandler([...this.addQueue]), 0);
      this.addQueue = [];
      this.addQueueKeys = new Set();
    }
  }

  private makeAddHandler(queue: RequestedCalculation[]) {
    return () => {
      this.dispatch(addToCalculationsQueue(this.instanceId, queue));
      this.addTimeoutId = null;
      this.dispatchAdd();
    };
  }

  private makeRemoveHandler(queue: RequestedCalculation[]) {
    return () => {
      this.dispatch(removeFromCalculationsQueue(queue));

      this.removeTimeoutId = null;
      this.dispatchRemove();
    };
  }

  private dispatchRemove() {
    if (this.removeQueue.length > 0 && this.removeTimeoutId == null && !this.paused) {
      this.removeTimeoutId = setTimeout(this.makeRemoveHandler([...this.removeQueue]), 0);
      this.removeQueue = [];
      this.removeQueueKeys = new Set();
    }
  }
}

// Does not include clientId because it doesn't have an impact on the actual calculation
const queueItemKey = ({
  entityId,
  dateRange,
  layerId,
  ignoreEventIds,
  includeObjectSpecEvaluation,
}: Omit<RequestedCalculation, 'clientId'>) => {
  const [start, end] = dateRange;
  return `${getFormulaCacheKey(entityId.id, `${start}.${end}`, ignoreEventIds)}.${layerId}.${includeObjectSpecEvaluation}`;
};

export const useInitial = (): WebWorkerDataProviderContext => {
  const dispatch = useAppDispatch();

  // We need to do some interesting stuff with refs to ensure that we are
  // always acting on the current dispatcher and not a stale reference to one
  // in a closure. So, we always fetch it via a func. I adapted this approach
  // from https://github.com/reactwg/react-18/discussions/18
  const dispatcherRef = useRef<Dispatcher | null>(null);
  const getterRef = useRef(() => {
    if (dispatcherRef.current == null) {
      const d = new Dispatcher(dispatch);
      dispatcherRef.current = d;
    }
    return dispatcherRef.current;
  });

  useEffect(() => {
    return () => {
      // Clean up whatever the current dispatcher is
      if (dispatcherRef.current != null) {
        dispatcherRef.current.destroy();
        dispatcherRef.current = null;
      }
    };
  }, []);

  const requestBatch = useCallback((fn: () => void) => {
    const dispatcher = getterRef.current();
    dispatcher.pause();
    fn();
    dispatcher.resume();
  }, []);

  const requestValue = useCallback((requestedValue: RequestValueProps) => {
    const dispatcher = getterRef.current();

    const { id, type, dateRange, layerId, ignoreEventIds, includeObjectSpecEvaluation, opts } =
      requestedValue;

    const requestedCalculation: RequestedCalculation = {
      entityId: { id, type },
      dateRange: [getMonthKey(dateRange[0]), getMonthKey(dateRange[1])],
      layerId,
      ignoreEventIds,
      clientId: dispatcher.instanceId,
      includeObjectSpecEvaluation,
      opts,
    };

    dispatcher.enqueue(requestedCalculation);

    return () => {
      // Ensure that the disposer uses the current dispatcher
      getterRef.current().dequeue(requestedCalculation);
    };
  }, []);

  return useMemo(() => ({ requestBatch, requestValue }), [requestBatch, requestValue]);
};
