import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import { createCachedSelector } from 're-reselect';

import {
  UNLISTED_DRIVERS_PAGE_TYPE,
  getSubmodelIdFromInternalPageType,
} from 'config/internalPages/modelPage';
import {
  BlockConfig,
  BlockGroupBy,
  BlockGroupByType,
  BlockSortBy,
  BlockType,
  BlockViewAsType,
  BlockViewOptions,
  ChartGroupingType,
  ChartSize,
  DateRange,
  DateRangeComparisonType,
  DriverIndentation,
  DriverReference,
  DriverRichText,
  DriverType,
  ImpactDisplay,
  ImpactDisplayType,
  ObjectSpecDisplayAsType,
  RichTextScope,
  TimePeriod,
} from 'generated/graphql';
import { addDefaultBlockConfig, isLastBlockInRow } from 'helpers/blocks';
import { CURRENT_MONTH_KEY, previousMonthKey } from 'helpers/dates';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { SelectorWithLayerParam, getCacheKeyForLayerSelector } from 'helpers/layerSelectorFactory';
import { isTextBlockEmpty } from 'helpers/tiptap';
import { isNotNull } from 'helpers/typescript';
import { Block, BlockId, BlockRowWithOptionalId, BlocksPageId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { EntityTable } from 'reduxStore/models/common';
import { DriverId } from 'reduxStore/models/drivers';
import { isMultiDriverChart } from 'reduxStore/reducers/helpers/viewOptions';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { visibleLocalBlockConfigsSelector } from 'selectors/blockLocalStateSelector';
import { blocksPagesByIdSelector } from 'selectors/blocksPagesSelector';
import {
  blocksPageByInternalPageTypeSelector,
  blocksPagesSelector,
} from 'selectors/blocksPagesTableSelector';
import { businessObjectSpecStartFieldIdSelector } from 'selectors/businessObjectSpecsSelector';
import {
  businessObjectSpecIdsBySubDriverIdSelector,
  driverPropertiesByBusinessObjectSpecIdSelector,
} from 'selectors/collectionSelector';
import { blockIdSelector, blocksPageIdSelector } from 'selectors/constSelectors';
import { dataSourceLinkedObjectPagesSelector } from 'selectors/dataSourceLinkedObjectPagesSelector';
import { databaseSpecIdSelector } from 'selectors/databasePageSelector';
import { datasetSelector } from 'selectors/datasetSelector';
import {
  driversByIdForCurrentLayerSelector,
  driversByIdForLayerSelector,
  subDriversByDimDriverIdSelector,
} from 'selectors/driversSelector';
import { accessibleDatabasesSelector } from 'selectors/navigationSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { pageIdSelector } from 'selectors/pageSelector';
import { prevailingCellSelectionBlockIdSelector } from 'selectors/prevailingCellSelectionSelector';
import { submodelsByIdSelector } from 'selectors/submodelsByIdSelector';
import { ParametricSelector, Selector } from 'types/redux';

const EMPTY_BLOCK_VIEW_OPTIONS: BlockViewOptions = {};
const EMPTY_BLOCK_IDS: BlockId[] = [];
const EMPTY_DRIVER_IDS: DriverId[] = [];
const EMPTY_DRIVER_INDENTATION: DriverIndentation[] = [];
const EMPTY_RICH_TEXT_DRIVER_ARRAY: DriverRichText[] = [];
const EMPTY_ENTITY_TABLE: EntityTable<Block> = { byId: {}, allIds: [] };

export type UsableChartGroupingType = Exclude<ChartGroupingType, ChartGroupingType.Stacked>;
export type DriverRichTextMap = {
  rows: { [driverId: DriverId]: DriverRichText };
  cells: { [driverId: DriverId]: DriverRichText };
  columns: { [monthkey: string]: DriverRichText };
};

const blocksTableSelector: Selector<EntityTable<Block>> = createSelector(
  datasetSelector,
  (dataset) => {
    const { blocks = EMPTY_ENTITY_TABLE } = dataset;
    const blocksWithDefaults = mapValues(blocks.byId, (block) => addDefaultBlockConfig(block));

    return {
      ...blocks,
      byId: blocksWithDefaults,
    };
  },
);

export const blocksSelector: Selector<Block[]> = createSelector(blocksTableSelector, (blocks) => {
  return blocks.allIds.map((id) => blocks.byId[id]);
});

export const blocksByIdSelector: Selector<Record<string, Block>> = createDeepEqualSelector(
  blocksTableSelector,
  visibleLocalBlockConfigsSelector,
  (blocks, localBlockConfigs) => {
    if (localBlockConfigs == null) {
      return blocks.byId;
    }

    return mapValues(blocks.byId, (block) => {
      const localBlockConfig = localBlockConfigs[block.id];
      if (localBlockConfig == null || localBlockConfig.columns == null) {
        return block;
      }
      const datasetBlockColumns = (block.blockConfig ?? {}).columns ?? [];

      const allColumnKeys = new Set([
        ...localBlockConfig.columns.map((column) => column.key).filter(isNotNull),
        ...datasetBlockColumns.map((column) => column.key).filter(isNotNull),
      ]);

      const datasetBlockColumnConfigs = keyBy(datasetBlockColumns, 'key');
      const localColumnConfigs = keyBy(localBlockConfig.columns, 'key');

      const updatedBlockConfigs = {
        ...(block.blockConfig ?? {}),
        columns: Array.from(allColumnKeys)
          .map((key) => {
            // Prevent invalid configs from being propagated.
            const config = datasetBlockColumnConfigs[key];
            if (config == null) {
              return null;
            }

            // Only respect the width property from local block config columns.
            const localWidth = localColumnConfigs[key]?.width;
            if (localWidth == null) {
              return config;
            }

            return {
              ...config,
              width: localWidth,
            };
          })
          .filter(isNotNull),
      };

      return {
        ...block,
        blockConfig: updatedBlockConfigs,
      };
    });
  },
);

export const blockTypesByIdSelector: Selector<Record<BlockId, BlockType>> = createDeepEqualSelector(
  blocksByIdSelector,
  (blocksById) =>
    Object.fromEntries(Object.values(blocksById).map((block) => [block.id, block.type])),
);

export const blockSelector: ParametricSelector<BlockId, Block | undefined> = createCachedSelector(
  blocksByIdSelector,
  blockIdSelector,
  (blocks, blockId) => {
    return blocks[blockId];
  },
)(blockIdSelector);

export const blockTypeSelector: ParametricSelector<BlockId, BlockType | undefined> =
  createCachedSelector(blockSelector, (block) => block?.type)(blockIdSelector);

export const blockNameSelector: ParametricSelector<BlockId, string | undefined> =
  createCachedSelector(blockSelector, (block) => block?.name)(blockIdSelector);

export const blockConfigSelector: ParametricSelector<BlockId, BlockConfig | undefined> =
  createCachedSelector(blockSelector, (block) => block?.blockConfig)(blockIdSelector);

export const blockConfigsByBlockIdSelector: Selector<Record<BlockId, BlockConfig>> = createSelector(
  blocksByIdSelector,
  (blocksById) => mapValues(blocksById, (block) => block.blockConfig),
);

export const textBlockBodySelector: ParametricSelector<BlockId, string | undefined> =
  createCachedSelector(
    blockConfigSelector,
    (blockConfig) => blockConfig?.textBlockBody ?? undefined,
  )(blockIdSelector);

export const blockMediaWidthSelector: ParametricSelector<BlockId, number | undefined> =
  createCachedSelector(
    blockConfigSelector,
    (blockConfig) => blockConfig?.mediaWidth ?? undefined,
  )(blockIdSelector);

export const blockHideHeaderSelector: ParametricSelector<BlockId, boolean> = createCachedSelector(
  blockConfigSelector,
  (blockConfig) => blockConfig?.hideHeader ?? false,
)(blockIdSelector);

export const isInternalPageBlockSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(blockSelector, blocksPagesByIdSelector, (block, blockPagesById) => {
    return block != null && blockPagesById[block.pageId]?.internalPageType != null;
  })(blockIdSelector);

export const unlistedDriversPageBlockIdSelector: Selector<BlockId | undefined> = createSelector(
  blocksPageByInternalPageTypeSelector,
  (pagesByInternalPageType) => {
    const page = pagesByInternalPageType[UNLISTED_DRIVERS_PAGE_TYPE];
    if (page == null || page.blockIds.length === 0) {
      return undefined;
    }

    return page.blockIds[0];
  },
);

export const isUnlistedByDriverIdSelector: SelectorWithLayerParam<
  NullableRecord<DriverId, boolean>
> = createCachedSelector(
  businessObjectSpecIdsBySubDriverIdSelector,
  accessibleDatabasesSelector,
  driversByIdForLayerSelector,
  submodelsByIdSelector,
  blocksPagesByIdSelector,
  blocksByIdSelector,
  (
    specIdsBySubDriverId,
    accessibleSpecIds,
    driversById,
    submodelsById,
    blocksPagesById,
    blocksById,
    // eslint-disable-next-line max-params
  ) => {
    const accessibleSpecIdsSet = new Set(accessibleSpecIds);
    const isUnlistedDriverByDriverId: NullableRecord<DriverId, boolean> = {};
    Object.values(driversById).forEach((driver) => {
      if (driver == null) {
        return;
      }
      if (driver.type !== DriverType.Basic) {
        isUnlistedDriverByDriverId[driver.id] = false;
        return;
      }

      const specIds = specIdsBySubDriverId[driver.id] ?? [];
      if (specIds.some((id) => accessibleSpecIdsSet.has(id))) {
        isUnlistedDriverByDriverId[driver.id] = false;
        return;
      }

      if (driver.driverReferences == null || driver.driverReferences.length === 0) {
        isUnlistedDriverByDriverId[driver.id] = true;
        return;
      }

      // Filter out any deleted submodels/pages
      const filteredDriverReferences = driver.driverReferences.filter((ref) => {
        const block = blocksById[ref.blockId];
        if (block == null) {
          return false;
        }
        const page = blocksPagesById[block.pageId];
        if (page == null) {
          return false;
        }
        // if internalPageType is null, this is a regular page and the existance of the page is enough to know it's not deleted
        if (page.internalPageType == null) {
          return true;
        }

        const submodelId = getSubmodelIdFromInternalPageType(page.internalPageType);
        // if the submodelId being null is an edge case because driverReferences can only come from submodels or regular pages so we can ignore it
        // Note: this also effectively filters out the UnlistedDriversPage, which we want
        if (submodelId == null) {
          return false;
        }

        return submodelsById[submodelId] != null;
      });
      isUnlistedDriverByDriverId[driver.id] = filteredDriverReferences.length === 0;
    });
    return isUnlistedDriverByDriverId;
  },
)(getCacheKeyForLayerSelector);

/**
 * Not ordered
 */
export const unlistedDriverIdsSelector: Selector<DriverId[]> = createDeepEqualSelector(
  isUnlistedByDriverIdSelector,
  (isUnlistedByDriverId) => {
    return Object.entries(isUnlistedByDriverId)
      .filter(([, isUnlisted]) => isUnlisted)
      .map(([driverId]) => driverId);
  },
);

const subDriversInDatabaseBlockSelector: ParametricSelector<BlockId, DriverId[] | undefined> =
  createCachedSelector(
    blockConfigSelector,
    driverPropertiesByBusinessObjectSpecIdSelector,
    subDriversByDimDriverIdSelector,
    (blockConfig, driverPropertiesBySpecId, subDriversByDimDriverId) => {
      const specId = blockConfig?.businessObjectSpecId;
      const driverProperties = specId != null ? driverPropertiesBySpecId[specId] : null;
      if (driverProperties == null) {
        return undefined;
      }
      const dimDriverIds = driverProperties.map((dp) => dp.driverId);
      return dimDriverIds.flatMap((dimDriverId) => {
        return (subDriversByDimDriverId[dimDriverId] ?? []).map((sd) => sd.driverId);
      });
    },
  )({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

export const blockDriverIdsSelector: ParametricSelector<BlockId, DriverId[]> = createCachedSelector(
  blockSelector,
  unlistedDriversPageBlockIdSelector,
  driversByIdForCurrentLayerSelector,
  isUnlistedByDriverIdSelector,
  subDriversInDatabaseBlockSelector,
  (block, unlistedDriversBlockId, driversById, isUnlistedByDriverId, subDriversInDatabaseBlock) => {
    if (block == null) {
      return EMPTY_DRIVER_IDS;
    }

    if (subDriversInDatabaseBlock != null) {
      return subDriversInDatabaseBlock;
    }

    const { type, id } = block;

    const isUnlistedDriversPage = id === unlistedDriversBlockId;

    if (type !== BlockType.DriverCharts && type !== BlockType.DriverGrid) {
      return EMPTY_DRIVER_IDS;
    }

    let referencedDriverIdsAndRefs: Array<[DriverId, DriverReference | undefined]> = [];
    if (isUnlistedDriversPage) {
      referencedDriverIdsAndRefs = Object.values(driversById)
        .filter(isNotNull)
        .filter((driver) => {
          return isUnlistedByDriverId[driver.id] === true;
        })
        .map((driver) => [driver.id, driver.driverReferences?.[0]]);
    } else {
      referencedDriverIdsAndRefs = Object.values(driversById)
        .map((driver) => [driver?.id, driver?.driverReferences?.find((ref) => ref.blockId === id)])
        .filter(([_, ref]) => ref != null) as Array<[DriverId, DriverReference]>;
    }

    const referencedDriverIds = sortBy(
      referencedDriverIdsAndRefs,
      ([_, ref]) => ref?.sortIndex,
    ).map(([driverId]) => driverId);

    return referencedDriverIds;
  },
)({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

export const blockConfigSortBySelector: ParametricSelector<BlockId, BlockSortBy | undefined> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.sortBy ?? undefined;
  })(blockIdSelector);

export const blockConfigBusinessObjectSpecIdSelector: ParametricSelector<
  BlockId,
  BusinessObjectSpecId | undefined
> = createCachedSelector(blockConfigSelector, databaseSpecIdSelector, (blockConfig, pageSpecId) => {
  // sometimes, a database block does not have a spec ID in the block config and the spec ID must be inferred from the block page
  return blockConfig?.businessObjectSpecId ?? pageSpecId ?? undefined;
})(blockIdSelector);

export const blockConfigObjectSpecDisplayAsSelector: ParametricSelector<
  BlockId,
  ObjectSpecDisplayAsType
> = createCachedSelector(blockConfigSelector, (blockConfig) =>
  blockConfig?.objectSpecDisplayAs === ObjectSpecDisplayAsType.Timeseries
    ? ObjectSpecDisplayAsType.Timeseries
    : ObjectSpecDisplayAsType.Database,
)(blockIdSelector);

export const blockGridTypeSelector: ParametricSelector<
  BlockId,
  'agGrid' | 'agGridTimeseries' | 'table'
> = createCachedSelector(
  blockTypeSelector,
  blockConfigBusinessObjectSpecIdSelector,
  blockConfigObjectSpecDisplayAsSelector,
  (blockType, specId, displayAs) => {
    if (specId == null) {
      return 'table';
    }
    if (blockType !== BlockType.ObjectGrid && blockType !== BlockType.ObjectTable) {
      return 'table';
    }

    if (displayAs === ObjectSpecDisplayAsType.Timeseries) {
      return 'agGridTimeseries';
    }

    return 'agGrid';
  },
)(blockIdSelector);

export const blockConfigBusinessObjectSpecStartFieldIdSelector: ParametricSelector<
  BlockId,
  string | undefined
> = createCachedSelector(
  (state: RootState, blockId: BlockId) => {
    const objectSpecId = blockConfigBusinessObjectSpecIdSelector(state, blockId);
    if (objectSpecId == null) {
      return undefined;
    }
    return businessObjectSpecStartFieldIdSelector(state, objectSpecId);
  },
  (startFieldId) => startFieldId,
)(blockIdSelector);

export const blockConfigDateRangeSelector: ParametricSelector<BlockId, DateRange | undefined> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.dateRange ?? undefined;
  })(blockIdSelector);

export const blockConfigViewAtTimeSelector: ParametricSelector<BlockId, TimePeriod | undefined> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.viewAtTime ?? undefined;
  })(blockIdSelector);

export const blockConfigDateRangeDateTimesSelector: ParametricSelector<
  BlockId,
  [DateTime, DateTime] | null
> = createCachedSelector(blockConfigDateRangeSelector, (dateRange) => {
  if (dateRange?.start == null || dateRange?.end == null) {
    return null;
  }

  const range: [DateTime, DateTime] = [
    DateTime.fromISO(dateRange.start),
    DateTime.fromISO(dateRange.end),
  ];

  return range;
})(blockIdSelector);
export const blockConfigGroupBySelector: ParametricSelector<BlockId, BlockGroupBy | undefined> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.groupBy ?? undefined;
  })(blockIdSelector);

export const blockConfigGroupByFieldSpecIdSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId | undefined
> = createCachedSelector(blockConfigSelector, (blockConfig) => {
  return blockConfig?.groupBy?.groupByType === BlockGroupByType.ObjectField
    ? blockConfig.groupBy.objectField?.businessObjectFieldId
    : undefined;
})(blockIdSelector);

const blockConfigImpactDisplaySelector: ParametricSelector<BlockId, ImpactDisplay | undefined> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.impactDisplay ?? undefined;
  })(blockIdSelector);

export const blockConfigViewOptionsSelector: ParametricSelector<BlockId, BlockViewOptions> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    // Shallow copy to prevent modifiying a read-only property
    const options = blockConfig == null ? {} : { ...blockConfig.blockViewOptions };

    if (options.aggregateValues == null) {
      options.aggregateValues = false;
    }

    if (options.viewAsType == null) {
      options.viewAsType = BlockViewAsType.Chart;
    }

    if (isMultiDriverChart(options)) {
      options.chartSize = ChartSize.ExtraLarge;
    }

    if (options.chartSize === ChartSize.ExtraLarge && !isMultiDriverChart(options)) {
      options.chartSize = ChartSize.Large;
    }

    if (options.viewAsType === BlockViewAsType.WaterfallChart) {
      options.chartGroupingType = ChartGroupingType.Multi;
    }

    if (
      options.viewAsType === BlockViewAsType.CurrentValue &&
      options.dateRangeComparison == null
    ) {
      const currMonth = CURRENT_MONTH_KEY;
      const prevMonth = previousMonthKey(CURRENT_MONTH_KEY);

      options.dateRangeComparison = {
        type: DateRangeComparisonType.CurrentMonth,
        selectedPeriod: {
          start: currMonth,
          end: currMonth,
        },
        comparisonPeriod: {
          start: prevMonth,
          end: prevMonth,
        },
      };
    }

    return options ?? EMPTY_BLOCK_VIEW_OPTIONS;
  })({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

export const blockConfigChartGroupingTypeSelector: ParametricSelector<
  BlockId,
  UsableChartGroupingType
> = createCachedSelector(blockConfigSelector, (blockConfig) => {
  const options = blockConfig?.blockViewOptions ?? {};

  // Cant remove the stacked option yet, so if its returned, remove it
  if (options.chartGroupingType === ChartGroupingType.Stacked) {
    return ChartGroupingType.Single;
  }

  return options.chartGroupingType ?? ChartGroupingType.Single;
})(blockIdSelector);

export const blockConfigVideoURLSelector: ParametricSelector<BlockId, string | undefined> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.videoBlockUrl ?? undefined;
  })(blockIdSelector);

export const blockConfigImageURLSelector: ParametricSelector<BlockId, string | undefined> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.imageBlockUrl ?? undefined;
  })(blockIdSelector);

export const blockConfigDriverIndentsSelector: ParametricSelector<BlockId, DriverIndentation[]> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.driverIndentations ?? EMPTY_DRIVER_INDENTATION;
  })((_state, blockId) => blockId);

export const blockConfigRichTextDriverSelector: ParametricSelector<BlockId, DriverRichText[]> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.driverRichText ?? EMPTY_RICH_TEXT_DRIVER_ARRAY;
  })((_state, blockId) => blockId);

export const blockConfigRichTextDriverMapSelector: ParametricSelector<BlockId, DriverRichTextMap> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    const driverRichTextArray = blockConfig?.driverRichText ?? [];

    const driverRichTextMap = driverRichTextArray.reduce<DriverRichTextMap>(
      (acc, item) => {
        const { driverId, monthKey, richTextScope } = item;
        const compositeKey = `${driverId}_${monthKey}`;

        switch (richTextScope) {
          case RichTextScope.Row:
            acc.rows[driverId] = item;
            break;
          case RichTextScope.Cell:
            acc.cells[compositeKey] = item;
            break;
          case RichTextScope.Column:
            if (monthKey != null) {
              acc.columns[monthKey] = item;
            }
            break;
          default:
        }

        return acc;
      },
      { rows: {}, cells: {}, columns: {} },
    );

    return driverRichTextMap;
  })({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

export const blockConfigFiscalYearStartMonthSelector: ParametricSelector<BlockId, number> =
  createCachedSelector(
    blockConfigSelector,
    (blockConfig) => blockConfig?.fiscalYearStartMonth ?? 1,
  )(blockIdSelector);

export const blockConfigShowRestrictedSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    blockConfigSelector,
    (blockConfig) => blockConfig?.showRestricted ?? false,
  )((_state, blockId) => blockId);

// N.B. We should be using this selector instead of blockConfigShowRestrictedSelector
// in most places. Currently, we are independently checking accessCapabilities.canWritePermissions
// within other components/selectors.
// TODO: Use this selector instead of blockConfigShowRestrictedSelector in more places.
export const accessCapabilityAwareBlockConfigShowRestrictedSelector: ParametricSelector<
  BlockId,
  boolean
> = createCachedSelector(
  blockConfigSelector,
  accessCapabilitiesSelector,
  (blockConfig, accessCapabilities) => {
    const blockShowRestricted = blockConfig?.showRestricted ?? false;
    return blockShowRestricted || accessCapabilities.canWritePermissions;
  },
)((_state, blockId) => blockId);

export const isObjectTimeseriesViewSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    blockTypeSelector,
    blockConfigObjectSpecDisplayAsSelector,
    (blockType, displayAs) => {
      return (
        blockType === BlockType.ObjectGrid ||
        (blockType === BlockType.ObjectTable && displayAs === ObjectSpecDisplayAsType.Timeseries)
      );
    },
  )(blockIdSelector);

export const blockCanHidePropertiesSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(blockTypeSelector, (blockType) => {
    return blockType === BlockType.ObjectTable;
  })(blockIdSelector);

export const blockConfigObjectFieldSpecAsTimeSeriesIdSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId | null
> = createCachedSelector(
  isObjectTimeseriesViewSelector,
  blockConfigBusinessObjectSpecStartFieldIdSelector,
  blockConfigSelector,
  (isObjectTimeseriesView, startFieldId, blockConfig) => {
    if (isObjectTimeseriesView) {
      return null;
    }
    const fieldId = blockConfig?.objectFieldSpecAsTimeSeriesId ?? null;
    return fieldId !== startFieldId ? fieldId : null;
  },
)(blockIdSelector);

export const impactDisplayTypeForBlockSelector: ParametricSelector<BlockId, ImpactDisplayType> =
  createCachedSelector(blockConfigImpactDisplaySelector, (impactDisplay) => {
    return impactDisplay?.displayType ?? ImpactDisplayType.BeforeAfter;
  })(blockIdSelector);

export const autoFocusBlockIdSelector: Selector<BlockId | undefined> = createSelector(
  pageSelector,
  (page) =>
    page?.autoFocus != null && page?.autoFocus.type === 'block' ? page.autoFocus.id : undefined,
);

export const sortedBlockIdsByPageIdSelector: Selector<Record<BlocksPageId, BlockId[]>> =
  createDeepEqualSelector(blocksPagesSelector, blocksByIdSelector, (allPages, blocksById) => {
    const sortedBlockIdsByPageId: Record<BlocksPageId, BlockId[]> = {};
    allPages.forEach((page) => {
      const blocks = page.blockIds.map((blockId) => blocksById[blockId]).filter(isNotNull);
      // sort blocks based on sortIndex
      const sortedBlocks = sortBy(blocks, 'sortIndex');
      sortedBlockIdsByPageId[page.id] = sortedBlocks.map((b) => b.id);
    });
    return sortedBlockIdsByPageId;
  });

export const sortedBlockIdsForCurrentPageSelector: Selector<BlockId[]> = createSelector(
  sortedBlockIdsByPageIdSelector,
  pageIdSelector,
  (blocksByPageId, pageId) => {
    return pageId == null ? EMPTY_BLOCK_IDS : (blocksByPageId[pageId] ?? EMPTY_BLOCK_IDS);
  },
);

export const currentPageHasSingleBlockSelector: Selector<boolean> = createSelector(
  sortedBlockIdsForCurrentPageSelector,
  (blockIds) => blockIds.length === 1,
);

export const sortedBlockIdsForPageSelector: ParametricSelector<BlocksPageId, BlockId[]> =
  createCachedSelector(
    sortedBlockIdsByPageIdSelector,
    blocksPageIdSelector,
    (sortedBlockIdsByPageId, pageId) => sortedBlockIdsByPageId[pageId] ?? EMPTY_BLOCK_IDS,
  )((_state, pageId) => pageId);

export const isLastBlockOnPageSelector: ParametricSelector<BlockId, boolean> = createCachedSelector(
  blockSelector,
  sortedBlockIdsByPageIdSelector,
  blockIdSelector,
  (block, sortedBlockIdsByPageId, blockId) => {
    if (block == null) {
      return false;
    }

    const sortedBlockIds = sortedBlockIdsByPageId[block.pageId];
    return sortedBlockIds[sortedBlockIds.length - 1] === blockId;
  },
)(blockIdSelector);

const defaultDateRangeByPageIdSelector: Selector<Record<BlocksPageId, DateRange | undefined>> =
  createDeepEqualSelector(blocksPagesSelector, (allPages) => {
    const defaultDateRangeByPageId: Record<BlocksPageId, DateRange | undefined> = {};
    allPages.forEach((page) => {
      defaultDateRangeByPageId[page.id] = page.dateRange;
    });
    return defaultDateRangeByPageId;
  });

export const defaultDateRangeForCurrentPageSelector: Selector<DateRange | undefined> =
  createSelector(
    defaultDateRangeByPageIdSelector,
    pageIdSelector,
    (defaultDateRangeByPageId, pageId) =>
      pageId == null ? undefined : defaultDateRangeByPageId[pageId],
  );

export const isEmptyTextBlockSelector: ParametricSelector<BlockId, boolean> = createCachedSelector(
  blockSelector,
  (block) => {
    if (block == null || block.type !== BlockType.Text) {
      return false;
    }

    const { textBlockBody } = block.blockConfig;
    if (textBlockBody == null) {
      return true;
    }

    return isTextBlockEmpty(textBlockBody);
  },
)(blockIdSelector);

export const isIntegrationDatabaseBlockSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    blockSelector,
    dataSourceLinkedObjectPagesSelector,
    (block, linkedObjectPages) => {
      return (
        block != null &&
        block.type === BlockType.ObjectTable &&
        linkedObjectPages.map((page) => page.id).includes(block.pageId)
      );
    },
  )(blockIdSelector);

export const sortedBlockConfigsForCurrentPageSelector: Selector<BlockConfig[]> = createSelector(
  sortedBlockIdsForCurrentPageSelector,
  blocksByIdSelector,
  (sortedBlockIds, blocksById) => {
    return sortedBlockIds.map((id) => blocksById[id]?.blockConfig).filter(isNotNull);
  },
);

const sortedBlockRowsByPageIdSelector: Selector<Record<BlocksPageId, BlockRowWithOptionalId[]>> =
  createDeepEqualSelector(blocksPagesSelector, blocksByIdSelector, (allPages, blocksById) => {
    const sortedBlockRowsByPageId: Record<BlocksPageId, BlockRowWithOptionalId[]> = {};

    allPages.forEach((page) => {
      if (page.layout != null && page.layout.length > 0) {
        // If present, use the layout to determine the order of blocks
        const blockRows = page.layout.filter(isNotNull);
        sortedBlockRowsByPageId[page.id] = blockRows;
      } else {
        // Otherwise, sort blocks based on their sortIndex
        const blocks = page.blockIds.map((blockId) => blocksById[blockId]).filter(isNotNull);
        const sortedBlocks = sortBy(blocks, 'sortIndex');
        sortedBlockRowsByPageId[page.id] = sortedBlocks.map((block) => ({
          columns: [{ blockIds: [block.id] }],
        }));
      }
    });

    return sortedBlockRowsByPageId;
  });

export const sortedBlockRowsForCurrentPageSelector: Selector<BlockRowWithOptionalId[]> =
  createSelector(sortedBlockRowsByPageIdSelector, pageIdSelector, (blockRowsByPageId, pageId) => {
    return pageId == null ? [] : (blockRowsByPageId[pageId] ?? []);
  });

export const sortedBlockRowsForPageSelector: ParametricSelector<
  BlocksPageId,
  BlockRowWithOptionalId[]
> = createCachedSelector(
  sortedBlockRowsByPageIdSelector,
  blocksPageIdSelector,
  (blockRowsByPageId, pageId) => blockRowsByPageId[pageId] ?? [],
)((_state, pageId) => pageId);

export const prevailingCellSelectionBlockSelector: Selector<Block | null> = createSelector(
  prevailingCellSelectionBlockIdSelector,
  blocksByIdSelector,
  (blockId, blocksById) => {
    if (blockId == null) {
      return null;
    }

    return blocksById[blockId];
  },
);

export const isLastBlockInRowSelector: ParametricSelector<BlockId, boolean> = createSelector(
  sortedBlockRowsForCurrentPageSelector,
  blockIdSelector,
  (blockRows, blockId) => {
    if (blockRows.length === 0) {
      return true;
    }
    return isLastBlockInRow(blockRows, blockId);
  },
);
