import { Draft } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import union from 'lodash/union';

import {
  BusinessObjectFieldSpecInput,
  BusinessObjectSpecCreateInput,
  BusinessObjectSpecDeleteInput,
  BusinessObjectSpecUpdateInput,
  DriverFormat,
  BusinessObjectFieldSpec as GqlBusinessObjectFieldSpec,
  ValueType,
} from 'generated/graphql';
import { toExtSource } from 'helpers/integrations';
import { isNotNull } from 'helpers/typescript';
import { BusinessObjectFieldSpec, BusinessObjectSpec } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectFieldValue } from 'reduxStore/models/businessObjects';
import { DatasetSnapshot, ToValueTimeSeries } from 'reduxStore/models/dataset';
import { DefaultLayer, Layer, LightLayer } from 'reduxStore/models/layers';
import { NullableValue } from 'reduxStore/models/value';
import {
  handleDeleteBusinessObjects,
  mapBusinessObjectFieldValue,
  mapBusinessObjectFieldValueFromSnapshot,
} from 'reduxStore/reducers/helpers/businessObjects';
import { mapCollection, updateCollectionFromInput } from 'reduxStore/reducers/helpers/collections';

// This formula is used as a fallback for any fields where a default forecast formula
// has not been set explicitly in the model.
// In practice, this formula references the value of the field from last month,
// propagating its (unchanged) value continuously.
export const DefaultForecastFormula = (fieldSpecId: string) =>
  `object(this, field(${fieldSpecId}, relative(-1,-1)))`;

export const integrationDataOverridesForecastFormula = (forecastFormula: string | undefined) => {
  if (forecastFormula === '' || forecastFormula == null) {
    return 'extObject(linked, extField(linked, relative(0,0)))';
  }
  return `if(extObject(linked, extField(linked, relative(0,0))) == NULL, ${forecastFormula}, extObject(linked, extField(linked, relative(0,0))))`;
};

export const DefaultLinkedActualsFormula = 'extObject(linked, extField(linked, relative(0,0)))';

export function setBusinessObjectSpecsFromDatasetSnapshot(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null) {
    layer.businessObjectSpecs = { byId: {}, allIds: [] };
    return;
  }

  const { businessObjectSpecs } = dataset;
  const objectSpecsList = businessObjectSpecs.map((gqlObjectSpec) => {
    const {
      id,
      name,
      databaseConfigId,
      fields,
      startFieldId,
      extSource,
      extSpecKey,
      extSpecKeys,
      defaultNameEntries,
      collection,
      isRestricted,
    } = gqlObjectSpec;

    const objectSpec: BusinessObjectSpec = {
      id,
      name: name ?? undefined,
      ...(databaseConfigId != null && { databaseConfigId }),
      startFieldId: startFieldId ?? undefined,
      isRestricted: isRestricted != null ? isRestricted : undefined,
      extSource: extSource != null ? toExtSource(extSource) : undefined,
      extSpecKeys: union(extSpecKey != null ? [extSpecKey] : [], extSpecKeys ?? []),
      fields: fields?.map((f) => mapBusinessObjectFieldSpecFromSnapshot(f)) ?? [],
      defaultNameEntries: mapDefaultNameEntries(defaultNameEntries),
      collection: collection
        ? mapCollection(layer.drivers.byId, defaultLayer.dimensions.byId, collection)
        : undefined,
    };

    return objectSpec;
  });

  layer.businessObjectSpecs = {
    byId: keyBy(objectSpecsList, 'id'),
    allIds: objectSpecsList.map((o) => o.id),
  };
}

export function handleCreateBusinessObjectSpec(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  newObjectSpecCreateInput: BusinessObjectSpecCreateInput,
) {
  const {
    id,
    name,
    databaseConfigId,
    fields,
    startFieldId,
    extSource,
    extSpecKey,
    extSpecKeys,
    defaultNameEntries,
    isRestricted,
    collection,
  } = newObjectSpecCreateInput;
  const mappedFields = fields.map(mapBusinessObjectFieldSpec).filter(isNotNull);
  const newObjectSpec: BusinessObjectSpec = {
    id,
    name,
    ...(databaseConfigId != null && { databaseConfigId }),
    isRestricted: isRestricted != null ? isRestricted : undefined,
    startFieldId: startFieldId ?? undefined,
    fields: mappedFields,
    extSource: extSource != null ? toExtSource(extSource) : undefined,
    extSpecKeys: union(extSpecKey != null ? [extSpecKey] : [], extSpecKeys ?? []),
    defaultNameEntries: mapDefaultNameEntries(defaultNameEntries),
    collection: collection
      ? mapCollection(layer.drivers.byId, defaultLayer.dimensions.byId, collection)
      : undefined,
  };

  layer.businessObjectSpecs.byId[id] = newObjectSpec;
  layer.businessObjectSpecs.allIds.push(id);
}

export function handleDeleteBusinessObjectSpec(
  layer: Draft<Layer>,
  { id }: BusinessObjectSpecDeleteInput,
) {
  const objectSpec = layer.businessObjectSpecs.byId[id];

  if (objectSpec == null) {
    return;
  }

  delete layer.businessObjectSpecs.byId[id];
  layer.businessObjectSpecs.allIds = layer.businessObjectSpecs.allIds.filter((i) => i !== id);

  const extObjectSpecsToDelete = new Set<string>(...(objectSpec.extSpecKeys ?? []));
  const extObjectSpecs = layer.extObjectSpecs;
  if (objectSpec.extSpecKey != null) {
    extObjectSpecsToDelete.add(objectSpec.extSpecKey);
    delete extObjectSpecs.byKey[objectSpec.extSpecKey];
  }

  layer.extObjectSpecs.allKeys = extObjectSpecs.allKeys.filter(
    (key) => !extObjectSpecsToDelete.has(key),
  );

  layer.deletedIdentifiers[id] = objectSpec.name;

  const deleteObjs = Object.values(layer.businessObjects.byId)
    .filter((o) => o.specId === objectSpec.id)
    .map((o) => ({ id: o.id }));

  handleDeleteBusinessObjects(layer, deleteObjs);
}

export function handleUpdateBusinessObjectSpec(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  updateObjectSpecInput: BusinessObjectSpecUpdateInput,
) {
  const {
    id,
    name,
    databaseConfigId,
    addFields,
    updateFields,
    deleteFields,
    updateStartFieldId,
    extSpecKey,
    extSpecKeys,
    setDefaultNameEntries,
    deleteDefaultNameEntries,
    updateCollection,
    isRestricted,
  } = updateObjectSpecInput;

  const objectSpec = layer.businessObjectSpecs.byId[id];
  if (objectSpec == null) {
    return;
  }

  if (name != null) {
    objectSpec.name = name;
  }

  if (databaseConfigId != null) {
    objectSpec.databaseConfigId = databaseConfigId;
  }

  if (isRestricted != null) {
    objectSpec.isRestricted = isRestricted;
  }

  const allObjectsForSpec = Object.values(layer.businessObjects.byId).filter(
    (obj) => obj.specId === id,
  );

  addFields?.forEach((fieldToAdd) => {
    if (objectSpec.fields.find((f) => f.id === fieldToAdd.id)) {
      return;
    }

    const mapped = mapBusinessObjectFieldSpec(fieldToAdd);
    if (mapped == null) {
      return;
    }

    objectSpec.fields.push(mapped);
  });

  updateFields?.forEach((fieldToUpdate) => {
    const field = objectSpec.fields.find((f) => f.id === fieldToUpdate.id);
    if (field == null) {
      return;
    }

    const objectExistsWithFieldSet = allObjectsForSpec.some((obj) =>
      obj.fields.some((f) => f.fieldSpecId === id),
    );

    if (fieldToUpdate.name != null) {
      field.name = fieldToUpdate.name;
    }

    if (fieldToUpdate.type != null) {
      if (objectExistsWithFieldSet) {
        throw Error('cannot change type when there is an object with this field set');
      }
      if (fieldToUpdate.type === ValueType.Boolean) {
        throw Error(`cannot update to field type to boolean`);
      }
      field.type = fieldToUpdate.type;
    }

    if (fieldToUpdate.dimensionId != null) {
      if (objectExistsWithFieldSet) {
        throw Error('cannot change type when there is an object with this field set');
      }
      if (field.type !== ValueType.Attribute) {
        throw Error('cannot update dimensionId if not attribute value type');
      }
      field.dimensionId = fieldToUpdate.dimensionId;
    }

    if (fieldToUpdate.defaultForecast != null) {
      if (isEmpty(fieldToUpdate.defaultForecast.formula)) {
        field.defaultForecast = { formula: DefaultForecastFormula(field.id) };
        field.isFormula = false;
      } else {
        field.defaultForecast = fieldToUpdate.defaultForecast;
        field.isFormula = true;
      }
    }

    if (fieldToUpdate.removeDefaultForecast != null && fieldToUpdate.removeDefaultForecast) {
      field.defaultForecast = { formula: DefaultForecastFormula(field.id) };
      field.isFormula = false;
    }

    if (
      fieldToUpdate.removeDefaultForecast != null &&
      fieldToUpdate.removeDefaultForecast === true
    ) {
      fieldToUpdate.defaultForecast = null;
    }

    if (
      fieldToUpdate.removeDefaultForecast != null &&
      fieldToUpdate.removeDefaultForecast === true
    ) {
      fieldToUpdate.defaultForecast = null;
    }

    if (fieldToUpdate.numericFormat != null) {
      if (field.type !== ValueType.Number) {
        throw Error('cannot update numericFormat if not number value type');
      }
      field.numericFormat = fieldToUpdate.numericFormat;
    }

    if (fieldToUpdate.currencyISOCode != null) {
      if (field.type !== ValueType.Number) {
        throw Error('cannot update currency if not number value type');
      }
      field.currencyISOCode = fieldToUpdate.currencyISOCode;
    }

    if (fieldToUpdate.decimalPlaces != null) {
      if (field.type !== ValueType.Number) {
        throw Error('cannot update decimal places if not number value type');
      }
      field.decimalPlaces =
        fieldToUpdate.decimalPlaces < 0 ? undefined : fieldToUpdate.decimalPlaces;
    }

    if (fieldToUpdate.setDefaultValues != null) {
      fieldToUpdate.setDefaultValues.forEach((v) => {
        field.defaultValues[v.key] = mapBusinessObjectFieldValue(v.value);
      });
    }

    if (fieldToUpdate.removeDefaultValues != null) {
      fieldToUpdate.removeDefaultValues.forEach((k) => {
        delete field.defaultValues[k];
      });
    }

    if (fieldToUpdate.extFieldSpecKey != null) {
      field.extFieldSpecKey = fieldToUpdate.extFieldSpecKey;
    }

    if (fieldToUpdate.isRestricted != null) {
      field.isRestricted = fieldToUpdate.isRestricted;
    }

    if (fieldToUpdate.propagateIntegrationData != null) {
      field.propagateIntegrationData = fieldToUpdate.propagateIntegrationData;
    }

    if (fieldToUpdate.integrationDataOverridesForecast != null) {
      field.integrationDataOverridesForecast = fieldToUpdate.integrationDataOverridesForecast;
    }
  });

  deleteFields?.forEach((fieldToDelete) => {
    const field = objectSpec.fields.find((f) => f.id === fieldToDelete);
    objectSpec.fields = objectSpec.fields.filter((f) => f.id !== fieldToDelete);
    if (field != null) {
      layer.deletedIdentifiers[id] = field.name;
    }
    if (objectSpec.startFieldId === fieldToDelete) {
      objectSpec.startFieldId = undefined;
    }
  });

  if (updateStartFieldId != null) {
    objectSpec.startFieldId = updateStartFieldId;
    // Cannot have a formula on a start field since it should only ever evaluate to a single value
    const startFieldSpec = objectSpec.fields.find((f) => f.id === updateStartFieldId);
    if (startFieldSpec?.isFormula) {
      startFieldSpec.defaultForecast = { formula: DefaultForecastFormula(startFieldSpec.id) };
      startFieldSpec.isFormula = false;
    }
  } else if (updateStartFieldId === null) {
    objectSpec.startFieldId = undefined;
  }

  if (extSpecKey != null) {
    objectSpec.extSpecKeys = union(objectSpec.extSpecKeys, [extSpecKey]);
  }

  if (extSpecKeys != null) {
    objectSpec.extSpecKeys = extSpecKeys;
  }

  if (setDefaultNameEntries != null) {
    objectSpec.defaultNameEntries = mapDefaultNameEntries(setDefaultNameEntries);
  }

  if (deleteDefaultNameEntries != null) {
    deleteDefaultNameEntries.forEach((k) => {
      delete objectSpec.defaultNameEntries[k];
    });
  }

  if (updateCollection != null) {
    objectSpec.collection = updateCollectionFromInput(
      layer,
      defaultLayer,
      updateCollection,
      objectSpec.collection,
    );

    const { removeDimensionalProperties } = updateCollection;
    if (removeDimensionalProperties != null) {
      allObjectsForSpec.forEach((obj) => {
        if (obj.collectionEntry == null) {
          return;
        }
        obj.collectionEntry.attributeProperties = obj.collectionEntry.attributeProperties.filter(
          (p) => !removeDimensionalProperties?.includes(p.dimensionalPropertyId),
        );
      });
    }
  }
}

function mapBusinessObjectFieldSpec(input: BusinessObjectFieldSpecInput): BusinessObjectFieldSpec {
  const {
    id,
    name,
    type,
    dimensionId,
    defaultForecast,
    numericFormat,
    defaultValues,
    extFieldSpecKey,
    currencyISOCode,
    decimalPlaces,
    isRestricted,
    propagateIntegrationData,
  } = input;

  const defaultValueMap =
    defaultValues?.reduce(
      (res, v) => {
        res[v.key] = mapBusinessObjectFieldValue(v.value);
        return res;
      },
      {} as NullableRecord<string, BusinessObjectFieldValue>,
    ) ?? {};

  const baseMappedFieldSpec = {
    id,
    name,
    extFieldSpecKey: extFieldSpecKey ?? undefined,
    // Not all object fields have an explicit default forecast.
    // For those that don't, we fallback to the equivalent of f(ThisMonth) = This.LastMonth
    defaultForecast:
      defaultForecast?.formula == null ? { formula: DefaultForecastFormula(id) } : defaultForecast,
    defaultValues: defaultValueMap,
    isFormula: defaultForecast?.formula != null,
    isRestricted: isRestricted != null ? isRestricted : false,
    propagateIntegrationData: propagateIntegrationData ?? false,
  };

  switch (type) {
    case ValueType.Attribute:
      if (dimensionId == null) {
        throw Error(`expected attribute value ${id} to have dimension ID`);
      }
      return {
        ...baseMappedFieldSpec,
        type: ValueType.Attribute,
        dimensionId,
      };
    case ValueType.Number:
      return {
        ...baseMappedFieldSpec,
        type: ValueType.Number,
        numericFormat: numericFormat ?? DriverFormat.Number,
        currencyISOCode: currencyISOCode ?? undefined,
        decimalPlaces: decimalPlaces ?? undefined,
      };
    case ValueType.Boolean:
      throw Error(`found unsupported field type: ${type}`);
    default:
      return { ...baseMappedFieldSpec, type };
  }
}

function mapBusinessObjectFieldSpecFromSnapshot(
  input: ToValueTimeSeries<GqlBusinessObjectFieldSpec>,
): BusinessObjectFieldSpec {
  const {
    id,
    name,
    type,
    dimensionId,
    defaultForecast,
    numericFormat,
    defaultValues,
    extFieldSpecKey,
    currencyISOCode,
    decimalPlaces,
    isRestricted,
    propagateIntegrationData,
  } = input;

  const defaultValueMap =
    defaultValues?.reduce(
      (res, v) => {
        res[v.key] = mapBusinessObjectFieldValueFromSnapshot(v.value);
        return res;
      },
      {} as NullableRecord<string, BusinessObjectFieldValue>,
    ) ?? {};

  const baseMappedFieldSpec = {
    id,
    name,
    extFieldSpecKey: extFieldSpecKey ?? undefined,
    // Not all object fields have an explicit default forecast.
    // For those that don't, we fallback to the equivalent of f(ThisMonth) = This.LastMonth
    defaultForecast:
      defaultForecast?.formula == null ? { formula: DefaultForecastFormula(id) } : defaultForecast,
    defaultValues: defaultValueMap,
    isFormula: defaultForecast?.formula != null,
    isRestricted: isRestricted != null ? isRestricted : false,
    propagateIntegrationData: propagateIntegrationData ?? false,
  };

  switch (type) {
    case ValueType.Attribute:
      if (dimensionId == null) {
        throw Error(`expected attribute value ${id} to have dimension ID`);
      }
      return {
        ...baseMappedFieldSpec,
        type: ValueType.Attribute,
        dimensionId,
      };
    case ValueType.Number:
      return {
        ...baseMappedFieldSpec,
        type: ValueType.Number,
        numericFormat: numericFormat ?? DriverFormat.Number,
        currencyISOCode: currencyISOCode ?? undefined,
        decimalPlaces: decimalPlaces ?? undefined,
      };
    case ValueType.Boolean:
      throw Error(`found unsupported field type: ${type}`);
    default:
      return { ...baseMappedFieldSpec, type };
  }
}

function mapDefaultNameEntries(input: BusinessObjectSpecCreateInput['defaultNameEntries']) {
  const defaultNames: NullableRecord<string, string> = {};
  input?.forEach(({ key, value }) => {
    defaultNames[key] = value;
  });
  return defaultNames;
}

export const defaultValueForField = (fieldSpec: BusinessObjectFieldSpec): NullableValue => {
  return { type: fieldSpec.type, value: undefined };
};
