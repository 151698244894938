export class ModelingError extends Error {
  public message: string;
  public charPosition: number;
  public length: number;

  constructor(message: string, charPosition: number, length: number) {
    super(message);
    this.message = message;
    this.charPosition = charPosition;
    this.length = length;
  }
}
