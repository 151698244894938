import { isEqual } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  AttributePlaceholder,
  DIM_DRIVER_ERROR_MESSAGES,
  DimDriverEditContext,
  PLACEHOLDER_ATTRIBUTE_TYPE,
  filterOutPlaceholderAttributes,
  useDraftSubDriver,
} from 'config/dimDriverEditContext';
import { getSubDriverBySubDriverId } from 'helpers/dimensionalDrivers';
import { safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import {
  checkForExistingSubDrivers,
  createNewDriversInContext,
  getExistingSubDrivers,
  updateDriverNameAndAttributes,
} from 'reduxStore/actions/driverMutations';
import { addDriverReferencesToBlock } from 'reduxStore/actions/driverReferenceMutations';
import { Attribute } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import {
  DimensionalDriver,
  DimensionalSubDriver,
  DriverId,
  DriverType,
} from 'reduxStore/models/drivers';
import { blockDriverIdsSelector } from 'selectors/blocksSelector';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';
import { attributesByIdSelector, dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import {
  dimensionalDriversBySubDriverIdSelector,
  driverNameSelector,
  subDriversByDriverIdSelector,
} from 'selectors/driversSelector';

const EMPTY_ATTRS: Attribute[] = [];

export type OnError = (
  message?: string,
  extras?: { existingTargetSubDriverId: DriverId },
) => void | undefined;

const getStartingState = (
  subDriver?: DimensionalSubDriver,
  dimDriver?: DimensionalDriver,
): Array<Attribute | AttributePlaceholder> => {
  if (dimDriver == null) {
    return EMPTY_ATTRS;
  }
  return dimDriver.dimensions.map((dim) => {
    const attr = subDriver?.attributes.find((a) => a.dimensionId === dim.id);
    if (attr != null) {
      return attr;
    }
    return { dimensionId: dim.id, type: PLACEHOLDER_ATTRIBUTE_TYPE };
  });
};

export default function useDimDriverEditor({
  driverId,
  belowDriverId,
  groupId,
  pageName,
  onDone,
  onError,
  resetOnDone,
}: {
  driverId: DriverId | undefined;
  belowDriverId: DriverId | undefined;
  groupId: DriverGroupId | undefined;
  pageName?: string;
  onDone: (() => void) | undefined;
  onError: OnError;
  resetOnDone: boolean;
}) {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  // Get the subdriver we are editing - possible null for new subdriver
  const dimDriversBySubDriverId = useAppSelector(dimensionalDriversBySubDriverIdSelector);
  const dimensionalPropertyEvaluator = useAppSelector(dimensionalPropertyEvaluatorSelector);
  const subDriversByDriverId = useAppSelector(subDriversByDriverIdSelector);
  const dimensionsById = useAppSelector(dimensionsByIdSelector);
  const attributesById = useAppSelector(attributesByIdSelector);

  const currentDimDriver =
    driverId != null ? safeObjGet(dimDriversBySubDriverId[driverId]) : undefined;
  const subDriver = useMemo(() => {
    if (driverId == null || currentDimDriver == null) {
      return undefined;
    }
    return getSubDriverBySubDriverId(currentDimDriver, driverId);
  }, [driverId, currentDimDriver]);

  const driverName =
    useAppSelector((state) =>
      driverId != null ? driverNameSelector(state, { id: driverId }) : '',
    ) ?? '';

  const driverAttrs = useMemo(
    () => getStartingState(subDriver, currentDimDriver),
    [subDriver, currentDimDriver],
  );

  const {
    attributes,
    subDriverName,
    targetDriver,
    setSubDriverName,
    setTargetDriver,
    toggleAttr,
    removeAttr,
    draftSubDriverDispatch,
  } = useDraftSubDriver({
    attributes: EMPTY_ATTRS,
    subDriverName: driverName,
    targetDriver: currentDimDriver,
  });
  const blockDriverIds = useAppSelector((state) => blockDriverIdsSelector(state, blockId));
  const dimensionalDriversBySubDriverId = useAppSelector(dimensionalDriversBySubDriverIdSelector);
  const targetDimDriver =
    targetDriver?.type === DriverType.Basic
      ? safeObjGet(dimensionalDriversBySubDriverId[targetDriver.id])
      : targetDriver;

  useEffect(() => {
    if (!isEmpty(driverName)) {
      setSubDriverName(driverName);
    }
  }, [driverName, setSubDriverName]);

  useEffect(() => {
    // When user changes something, remove the error message
    setErrorMessage(undefined);
  }, [attributes, subDriverName]);

  const prevDimensionsById = useRef(dimensionsById);
  const prevAttributesById = useRef(attributesById);

  useEffect(() => {
    // sync draft attributes with external changes to drivers
    const dimensionsChanged = !isEqual(prevDimensionsById.current, dimensionsById);
    const attributesChanged = !isEqual(prevAttributesById.current, attributesById);

    prevDimensionsById.current = dimensionsById;
    prevAttributesById.current = attributesById;

    if (!dimensionsChanged && !attributesChanged) {
      draftSubDriverDispatch({ type: 'setAttrs', data: driverAttrs });
    }
  }, [draftSubDriverDispatch, driverAttrs, dimensionsById, attributesById]);

  const reset = useCallback(() => {
    draftSubDriverDispatch({ type: 'setAttrs', data: driverAttrs });
    setSubDriverName(driverName);
    setTargetDriver(currentDimDriver);
  }, [
    draftSubDriverDispatch,
    driverAttrs,
    setSubDriverName,
    driverName,
    setTargetDriver,
    currentDimDriver,
  ]);

  const onDoneCallback = useCallback(() => {
    onDone?.();
    if (resetOnDone) {
      reset();
    }
  }, [resetOnDone, onDone, reset]);

  const onUpdate = useCallback(
    (options?: { renamingOne?: boolean; focusOnExistingDriver?: boolean }) => {
      const attributeList: Attribute[] = filterOutPlaceholderAttributes(attributes);

      // New driver
      if (driverId == null) {
        const id = uuidv4();

        dispatch(
          createNewDriversInContext({
            // create new subdriver
            newDrivers: [
              {
                id,
                name: subDriverName,
                ...(targetDimDriver != null || attributeList.length > 0
                  ? {
                      dimDriver: {
                        driverId: targetDimDriver?.id,
                        attributes: attributeList,
                      },
                    }
                  : {}),
              },
            ],
            context: {
              belowDriverId,
              groupId,
              blockId,
              submodelName: pageName,
              mergingNonAttributeDriver:
                targetDriver?.type === DriverType.Basic && targetDimDriver == null
                  ? targetDriver
                  : undefined,
            },
            select: true,
          }),
        );
        return;
      }

      dispatch(
        updateDriverNameAndAttributes({
          driverId,
          driverName: subDriverName,
          attributes: attributeList,
          // If we are renaming a single driver, we will be creating a new dim driver so targetDriver will be undefined
          targetDriver: options?.renamingOne ? undefined : targetDriver,
        }),
      );
    },
    [
      attributes,
      driverId,
      dispatch,
      subDriverName,
      targetDriver,
      targetDimDriver,
      belowDriverId,
      groupId,
      blockId,
      pageName,
    ],
  );

  const getErrorMessage = useCallback(() => {
    if (subDriverName == null || subDriverName.length === 0) {
      return DIM_DRIVER_ERROR_MESSAGES.emptyName;
    }

    if (targetDriver == null) {
      return undefined;
    }

    const conflict = checkForExistingSubDrivers({
      targetDriver: targetDimDriver ?? targetDriver,
      subDriversByDriverId,
      dimensionalPropertyEvaluator,
      driverId,
      driverName: subDriverName,
      attributes: filterOutPlaceholderAttributes(attributes),
    });
    if (conflict) {
      return DIM_DRIVER_ERROR_MESSAGES.conflict;
    }

    return undefined;
  }, [
    attributes,
    dimensionalPropertyEvaluator,
    driverId,
    subDriverName,
    subDriversByDriverId,
    targetDimDriver,
    targetDriver,
  ]);

  const onSave = useCallback(
    (options?: { renamingOne?: boolean; focusOnExistingDriver?: boolean }) => {
      const err = getErrorMessage();
      if (err != null) {
        if (
          options?.focusOnExistingDriver === true &&
          err === DIM_DRIVER_ERROR_MESSAGES.conflict &&
          targetDriver != null
        ) {
          const { existingTargetSubDriverId } = getExistingSubDrivers({
            targetDriver: targetDimDriver ?? targetDriver,
            subDriversByDriverId,
            dimensionalPropertyEvaluator,
            attributes: filterOutPlaceholderAttributes(attributes),
          });

          if (existingTargetSubDriverId == null) {
            return;
          }

          const existsInBlock = blockDriverIds.includes(existingTargetSubDriverId);
          if (existsInBlock) {
            // if the dimensional driver exists int the block and focusOnExistingDriver is set,
            // we handle the error by closing the popover and focusing to the driver cell in the block
            // instead of displaying the error message
            onError?.(err, { existingTargetSubDriverId });
            reset();
            return;
          } else {
            // if the dimensional driver exists in a different block, we add the driver reference to the current block
            // instead of creating a new dimensional driver
            dispatch(
              addDriverReferencesToBlock({
                driverIds: [existingTargetSubDriverId],
                blockId,
                groupId,
              }),
            );
          }
          reset();
          onDoneCallback();
          return;
        }
        onError?.(err);
        setErrorMessage(err);
        return;
      }

      onUpdate(options);
      onDoneCallback();
    },
    [
      attributes,
      blockDriverIds,
      blockId,
      dimensionalPropertyEvaluator,
      dispatch,
      getErrorMessage,
      groupId,
      onDoneCallback,
      onError,
      onUpdate,
      reset,
      subDriversByDriverId,
      targetDimDriver,
      targetDriver,
    ],
  );

  const dimDriverContext: DimDriverEditContext = useMemo(() => {
    return {
      attributes,
      driverId,
      errorMessage,
      originalDriverName: driverName,
      originalDimDriver: currentDimDriver,
      subDriverName,
      targetDriver,
      onSave,
      removeAttr,
      setSubDriverName,
      setTargetDriver,
      toggleAttr,
    };
  }, [
    attributes,
    driverId,
    errorMessage,
    driverName,
    currentDimDriver,
    subDriverName,
    targetDriver,
    onSave,
    removeAttr,
    setSubDriverName,
    setTargetDriver,
    toggleAttr,
  ]);

  return {
    subDriverName,
    dimDriverContext,
    dimDriverError: errorMessage,
    onSaveDimDriver: onSave,
    reset,
  };
}
