import { useMemo, useState } from 'react';

import useAppSelector from 'hooks/useAppSelector';
import { Driver } from 'reduxStore/models/drivers';
import { accessibleBasicDriversSelector } from 'selectors/accessibleDriversSelector';
import { businessObjectSpecsForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { topLevelDimensionalDriversSelector } from 'selectors/driversSelector';

interface Opts {
  preSearchFilter?: (d: Driver) => boolean;
  excludeItemIds?: string[];
  // This is to ensure we can use the top-level drivers (dimensional drivers + basic drivers that do not have a parent dimensional driver)
  shouldUseTopLevelDrivers?: boolean;
}

export default function useDriverSearch(opts: Opts) {
  const { preSearchFilter, excludeItemIds = [], shouldUseTopLevelDrivers = false } = opts;

  const [query, setQuery] = useState('');
  const allDrivers = useAppSelector(
    shouldUseTopLevelDrivers ? topLevelDimensionalDriversSelector : accessibleBasicDriversSelector,
  );

  const allObjectSpecs = useAppSelector(businessObjectSpecsForLayerSelector);
  const objectSpecs = useMemo(
    () => allObjectSpecs.filter(({ id }) => excludeItemIds == null || !excludeItemIds.includes(id)),
    [allObjectSpecs, excludeItemIds],
  );

  const drivers = useMemo(() => {
    const filtered = allDrivers.filter(
      (driver) => !excludeItemIds.includes(driver.id) && (preSearchFilter?.(driver) ?? true),
    );

    return filtered;
  }, [allDrivers, excludeItemIds, preSearchFilter]);

  return {
    query,
    setQuery,
    drivers,
    objectSpecs,
  };
}
