import { Flex, Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { useCallback, useContext, useMemo } from 'react';

import { GlobalNavIcon } from 'components/RunwayApp/GlobalNavigationMenu';
import { ScenarioItem } from 'components/ScenarioManagement/ScenarioSwitcher';
import { ScenarioContext } from 'components/ScenarioManagement/ScenariosContextProvider';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu from 'components/SelectMenu/SelectMenu';
import { writeToClipboard } from 'helpers/clipboard';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useControlledPopover from 'hooks/useControlledPopover';
import usePublishScenario from 'hooks/usePublishScenario';
import { deleteLayer } from 'reduxStore/actions/layerMutations';
import { navigateToScenarioComparisonModal } from 'reduxStore/actions/navigateTo';
import { currentPageIdWithSubmodelsSelector } from 'selectors/blocksPagesSelector';
import {
  accessibleChildLayersSelector,
  canUserRenameCurrentLayerSelector,
  childLayersSelector,
} from 'selectors/layerAccessResourcesSelector';
import {
  currentLayerIdSelector,
  currentLayerIsDefaultSelector,
  currentLayerIsDraftSelector,
  currentLayerWasCreatedByUser,
} from 'selectors/layerSelector';
import { publishedLayersSelector } from 'selectors/layersAccessResourcesSelector';
import { allLayerIdsToCompareSelector } from 'selectors/scenarioComparisonSelector';
import { MoreMenu } from 'vectors';
import CompareIcon from 'vectors/Compare';
import CopyIcon from 'vectors/Copy';
import LinkHorizontalIcon from 'vectors/LinkHorizontal';
import LockIcon from 'vectors/Lock';
import MergeIcon from 'vectors/Merge';
import PencilIcon from 'vectors/Pencil';
import TrashIcon from 'vectors/Trash';

const CURRENT_SCENARIO_SECTION_ID = 'currentScenario';

const ScenariosOptions: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();

  const { onUndraftingLayer, onRenameLayer } = useContext(ScenarioContext);

  const currentLayerId = useAppSelector(currentLayerIdSelector);

  const publishedLayers = useAppSelector(publishedLayersSelector);
  const isPublished = publishedLayers[currentLayerId] != null;

  const isMainLayer = useAppSelector(currentLayerIsDefaultSelector);
  const currentLayerIsDraft = useAppSelector(currentLayerIsDraftSelector);

  const currentLayerCreatedByUser = useAppSelector(currentLayerWasCreatedByUser);
  const canRenameCurrentLayer = useAppSelector(canUserRenameCurrentLayerSelector);

  const { canMergeScenarios, canEditListStatusOfScenario, canEditAnyScenario } =
    useAccessCapabilities();

  const compareToLayerIds = useAppSelector(allLayerIdsToCompareSelector);
  const currentPageId = useAppSelector(currentPageIdWithSubmodelsSelector);

  const { publishScenario, unpublishScenario } = usePublishScenario(currentLayerId);

  const onPublishScenarioToggle = useCallback(() => {
    if (isPublished) {
      unpublishScenario();
    } else {
      publishScenario();
    }
  }, [publishScenario, unpublishScenario, isPublished]);

  const onCopyLink = useCallback(() => {
    writeToClipboard(window.location.href);
    onClose?.();
  }, [onClose]);

  const onReviewAndMerge = useCallback(() => {
    dispatch(navigateToScenarioComparisonModal({ compareToLayerIds }));
    onClose?.();
  }, [compareToLayerIds, dispatch, onClose]);

  const onDiscardLayer = useCallback(() => {
    dispatch(deleteLayer({ layerId: currentLayerId }));
    onClose?.();
  }, [currentLayerId, dispatch, onClose]);

  // todo: If another user has a draft off of the current layer, we should disable the delete button.
  // The issue is right now, we only send layers the current user has access to to the client, and
  // other people's draft layers aren't included.
  const childLayers = useAppSelector((state) => childLayersSelector(state, currentLayerId));
  const accessibleChildLayers = useAppSelector((state) =>
    accessibleChildLayersSelector(state, currentLayerId),
  );
  const isDeleteCurrentLayerDisabled = childLayers.length > 0;

  const isDeleteOptionDisabled = useMemo(() => {
    if (isMainLayer) {
      return true;
    }
    if (!canEditAnyScenario && !currentLayerCreatedByUser) {
      return true;
    }
    return false;
  }, [canEditAnyScenario, currentLayerCreatedByUser, isMainLayer]);

  const items = useMemo(() => {
    const allItems: ScenarioItem[] = [
      {
        id: 'publish',
        name: 'Publish scenario',
        icon: <LockIcon />,
        checkedStyle: 'switch' as const,
        isChecked: !currentLayerIsDraft && isPublished,
        isHidden: isMainLayer || currentLayerIsDraft || !canEditListStatusOfScenario,
        onSelect: () => onPublishScenarioToggle(),
        sectionId: CURRENT_SCENARIO_SECTION_ID,
      },
      {
        id: 'merge',
        name: 'Review and merge',
        isHidden: isMainLayer || !canMergeScenarios,
        sectionId: CURRENT_SCENARIO_SECTION_ID,
        icon: <MergeIcon />,
        onSelect: () => onReviewAndMerge(),
      },
      {
        id: 'copyLink',
        name: 'Copy link',
        isHidden: currentPageId === null || currentLayerIsDraft,
        sectionId: CURRENT_SCENARIO_SECTION_ID,
        icon: <LinkHorizontalIcon />,
        onSelect: () => onCopyLink(),
      },
      {
        id: 'saveAs',
        name: 'Save as new scenario',
        isHidden: !currentLayerIsDraft,
        sectionId: CURRENT_SCENARIO_SECTION_ID,
        icon: <CopyIcon />,
        onSelect: onUndraftingLayer,
      },
      {
        id: 'compare',
        name: 'Compare',
        isHidden: !isMainLayer,
        sectionId: CURRENT_SCENARIO_SECTION_ID,
        icon: <CompareIcon />,
        onSelect: onReviewAndMerge,
      },
      {
        id: 'rename',
        name: 'Rename',
        isHidden: !canRenameCurrentLayer,
        sectionId: CURRENT_SCENARIO_SECTION_ID,
        icon: <PencilIcon />,
        onSelect: onRenameLayer,
      },
      {
        id: 'delete',
        name: currentLayerIsDraft ? 'Discard draft' : 'Delete',
        isHidden: isDeleteOptionDisabled,
        sectionId: CURRENT_SCENARIO_SECTION_ID,
        icon: <TrashIcon />,
        onSelect: () => onDiscardLayer(),
        isDisabled: isDeleteCurrentLayerDisabled,
        disabledTooltipLabel: isDeleteCurrentLayerDisabled
          ? `You can't delete this layer because other layers (${
              accessibleChildLayers.length === 0
                ? `that you don't have access to`
                : `${accessibleChildLayers.map((l) => `"${l.name}"`).join(', ')}`
            }) branch off of it.`
          : undefined,
      },
    ];
    return allItems.filter((item) => !item.isHidden);
  }, [
    currentLayerIsDraft,
    isPublished,
    isMainLayer,
    canEditListStatusOfScenario,
    canMergeScenarios,
    currentPageId,
    onUndraftingLayer,
    onReviewAndMerge,
    canRenameCurrentLayer,
    onRenameLayer,
    isDeleteOptionDisabled,
    isDeleteCurrentLayerDisabled,
    accessibleChildLayers,
    onPublishScenarioToggle,
    onCopyLink,
    onDiscardLayer,
  ]);

  const onSelect = useCallback((item: ScenarioItem) => {
    item.onSelect?.();
  }, []);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} isLazy placement="bottom-start">
      <PopoverTrigger>
        <Flex ref={triggerRef} alignSelf="center">
          <GlobalNavIcon
            aria-label="Scenario Menu"
            icon={<MoreMenu />}
            onClick={onOpen}
            data-testid="scenario-options-button"
          />
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent ref={contentRef} p={0}>
          <SelectMenu
            items={items}
            startFocusIdx={-1}
            onSelect={onSelect}
            width="14rem"
            maxHeight="30rem"
          >
            {BaseSelectMenuItem}
          </SelectMenu>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ScenariosOptions;
