import { useToken } from '@chakra-ui/react';
import { LinePath } from '@visx/shape';

import { DriverChartDatum } from 'config/driverChart';
import useChartLine from 'hooks/useChartLine';

interface Props {
  data: DriverChartDatum[];
  strokeColor: string;
  strokeWidth: number;
  strokeDasharray?: string;
  opacity?: number;
}

const ChartLinePath = ({
  data,
  strokeColor,
  strokeWidth,
  opacity = 1.0,
  strokeDasharray = '8',
}: Props) => {
  const { xAccessor, yAccessor, definedAccessor } = useChartLine();
  const [resolvedColor] = useToken('colors', [strokeColor]);

  return (
    <LinePath
      data={data}
      x={xAccessor}
      y={yAccessor}
      defined={definedAccessor}
      stroke={resolvedColor}
      strokeDasharray={strokeDasharray}
      strokeWidth={strokeWidth}
      opacity={opacity}
      pointerEvents="none"
    />
  );
};

export default ChartLinePath;
