import { useMemo } from 'react';

import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';

export default function useBlockDateRangeDateTime() {
  const { blockId } = useBlockContext();
  const [start, end] = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const blockRange = useMemo(() => {
    // Downstream users of this may want to use the start/end of the bounds
    // depending on the use case but this seems like a sensible default.
    return [start.startOf('month'), end.endOf('month')];
  }, [end, start]);

  return blockRange;
}
