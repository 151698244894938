import { createCachedSelector } from 're-reselect';

import { SelectorWithLayerParam, getCacheKeyForLayerSelector } from 'helpers/layerSelectorFactory';
import { EntityTable } from 'reduxStore/models/common';
import { DriverGroup } from 'reduxStore/models/driverGroup';
import { getGivenOrCurrentLayerId, layersSelector } from 'selectors/layerSelector';

const EMPTY_ENTITY_TABLE: EntityTable<DriverGroup> = { byId: {}, allIds: [] };

export const driverGroupEntityTableForLayerSelector: SelectorWithLayerParam<
  EntityTable<DriverGroup>
> = createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
  const layer = layers[layerId];

  if (layer == null) {
    return EMPTY_ENTITY_TABLE;
  }

  return layer.driverGroups;
})(getCacheKeyForLayerSelector);
