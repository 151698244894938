import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import Arrowhead from 'vectors/Arrowhead';

interface Props {
  label: string;
  zIndex: number;
  isTruncated: boolean;
  icon?: JSX.Element;
  onMouseDown: React.MouseEventHandler;
  isSelected: boolean;
}

const ReferenceMetadataPill: React.FC<Props> = ({
  label,
  zIndex,
  isTruncated,
  icon,
  onMouseDown,
  isSelected = false,
}) => {
  return (
    <Box
      as="span"
      alignItems="center"
      bgColor={isSelected ? 'selection.200' : 'gray.50'}
      borderColor={isSelected ? 'selection.300' : 'gray.300'}
      borderRightRadius="xl"
      borderWidth="px"
      columnGap={1}
      cursor="pointer"
      display="inline-flex"
      flexShrink={isTruncated ? undefined : 0}
      fontWeight="medium"
      onMouseDown={onMouseDown}
      height={6}
      marginLeft="-0.625rem"
      maxWidth="28rem"
      pl={3}
      pr={1}
      zIndex={zIndex}
      _hover={{ bgColor: isSelected ? 'selection.100' : 'gray.200' }}
    >
      {icon}
      <Text
        noOfLines={isTruncated ? 1 : undefined}
        wordBreak="break-all"
        _before={{ content: `"${label}"` }}
      />
      <Arrowhead boxSize={2} direction="down" color="gray.500" />
    </Box>
  );
};

export default React.memo(ReferenceMetadataPill);
