import React from 'react';

import styles from './TimeSeriesRowContainer.module.scss';

interface Props {
  children?: React.ReactNode;
}

const TimeSeriesRowContainer = React.forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  return (
    <div ref={ref} data-testid="time-series-row" className={styles.container}>
      {children}
    </div>
  );
});

export default TimeSeriesRowContainer;
