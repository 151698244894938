import { Box, useBoolean } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import EscapableInput from 'components/EscapableInput/EscapableInput';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { CustomOption, SelectItem } from 'components/SelectMenu/SelectMenu';
import { SELECT_MENU_IGNORE_KEYSTROKE_CLASS } from 'config/selectMenu';
import { isUserAddedDimension } from 'helpers/dimensionalDrivers';
import useAppSelector from 'hooks/useAppSelector';
import { useDimensionNameValidation } from 'hooks/useDimensionNameValidation';
import { DimensionId } from 'reduxStore/models/dimensions';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import PlusIcon from 'vectors/Plus';

const DimensionSubmenu: React.FC<{
  onCreateDimension: (name: string) => void;
  modifyValueType: (dimensionId: DimensionId) => void;
  currentDimensionId: DimensionId | undefined;
}> = ({ onCreateDimension, modifyValueType, currentDimensionId }) => {
  const allDimensionsById = useAppSelector(dimensionsByIdSelector);
  const [isEnteringNewDimensionName, setIsEnteringNewDimensionName] = useBoolean();
  const [isCreateNewDimensionLoading, setIsCreateNewDimensionLoading] = useBoolean();

  const onCreateDimensionCallback = useCallback(
    (name: string) => {
      setIsCreateNewDimensionLoading.on();
      setTimeout(() => {
        onCreateDimension(name);
      }, 0);
      // This component is unmounted after the dimension is created, so no need to do:
      // setIsCreateNewDimensionLoading.off() or setIsEnteringNewDimensionName.off()
    },
    [onCreateDimension, setIsCreateNewDimensionLoading],
  );

  const dimensionMenuItems = useMemo<SelectItem[]>(() => {
    return Object.values(allDimensionsById)
      .filter((d) => !d.deleted && isUserAddedDimension(d.id))
      .map(({ id, name }) => {
        const item: SelectItem = {
          id,
          name,
          isChecked: currentDimensionId === id,
        };
        return item;
      });
  }, [allDimensionsById, currentDimensionId]);

  const { validator, fieldName } = useDimensionNameValidation();

  const customOptions = useMemo<CustomOption[]>(() => {
    return [
      {
        id: 'customOption',
        icon: <PlusIcon />,
        shortcutHint: null,
        render: () => {
          if (isEnteringNewDimensionName) {
            return (
              <EscapableInput
                data-testid="new-dimension-input"
                validationSchema={validator}
                className={SELECT_MENU_IGNORE_KEYSTROKE_CLASS}
                fontSize="xs"
                name={fieldName}
                size="xs"
                variant="white"
                color="gray.600"
                height="full"
                defaultValue=""
                placeholder="New dimension"
                autoFocus
                onSave={onCreateDimensionCallback}
                onCancel={setIsEnteringNewDimensionName.off}
                saveOnBlur={false}
                py={0}
                loading={isCreateNewDimensionLoading}
              />
            );
          }
          return 'Create new dimension';
        },
        onSelect: () => {
          setIsEnteringNewDimensionName.on();
        },
      },
    ];
  }, [
    isEnteringNewDimensionName,
    validator,
    fieldName,
    onCreateDimensionCallback,
    setIsEnteringNewDimensionName,
    isCreateNewDimensionLoading,
  ]);

  const onSelect = useCallback(
    (item: SelectItem) => {
      modifyValueType(item.id);
    },
    [modifyValueType],
  );

  return (
    <Box boxShadow="menu" borderRadius="md">
      <SelectMenu
        width="16rem"
        items={dimensionMenuItems}
        onSelect={onSelect}
        customOptions={customOptions}
        allowEmptyQuery
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </Box>
  );
};

export default DimensionSubmenu;
