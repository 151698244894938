import { mapValues } from 'lodash';

import { Event, EventGroupId, EventId } from 'reduxStore/models/events';
import { MonthKey } from 'types/datetime';

function getEventsByMonthKey(events: Event[]): Record<MonthKey, Event[]> {
  const eventsByMonthKey: Record<MonthKey, Event[]> = {};
  events.forEach((event) => {
    Object.keys(event.customCurvePoints ?? {}).forEach((mk) => {
      const eventsForMonthKey = eventsByMonthKey[mk] ?? [];
      eventsForMonthKey.push(event);
      eventsByMonthKey[mk] = eventsForMonthKey;
    });
  });
  return eventsByMonthKey;
}

export function getEventAndParentIdsByMonthKey(
  events: Event[],
  // If provided and there is a month key with multiple events, the event with this parentId will be returned
  eventGroupIdToPrioritize?: EventGroupId,
): Record<MonthKey, Pick<Event, 'id' | 'parentId'>> {
  const eventsByMonthKey = getEventsByMonthKey(events);

  return mapValues(eventsByMonthKey, (eventsForMonthKey) => {
    if (eventGroupIdToPrioritize != null) {
      const eventInEventGroup = eventsForMonthKey.find(
        (e) => e.parentId === eventGroupIdToPrioritize,
      );

      if (eventInEventGroup != null) {
        return { id: eventInEventGroup.id, parentId: eventInEventGroup.parentId };
      }
    }

    const event = eventsForMonthKey[0];
    return { id: event.id, parentId: event.parentId };
  });
}

export function eventIdsByMonthKeys(
  events: Event[],
  // If provided and there is a month key with multiple events, the event with this parentId will be returned
  eventGroupIdToPrioritize?: EventGroupId,
): Record<MonthKey, EventId> {
  const eventAndParentIdsByMonthKey = getEventAndParentIdsByMonthKey(
    events,
    eventGroupIdToPrioritize,
  );

  return mapValues(eventAndParentIdsByMonthKey, (eventAndParentId) => eventAndParentId.id);
}
