import { isEmpty } from 'lodash';
import max from 'lodash/max';
import min from 'lodash/min';
import uniq from 'lodash/uniq';

import { DEFAULT_PLAN_NAME } from '@features/Plans';
import { toStringList } from 'helpers/array';
import { START_OF_MONTH } from 'helpers/dates';
import { Range } from 'helpers/events';
import { isNotNull } from 'helpers/typescript';
import { DriverId } from 'reduxStore/models/drivers';
import {
  DEFAULT_EVENT_GROUP_ID,
  EventGroup,
  EventGroupId,
  EventId,
  Event as EventModel,
  PopulatedEventGroup,
  isDriverEvent,
} from 'reduxStore/models/events';

export function isEventGroupEmpty(eventGroup: EventGroup): boolean {
  return isEmpty(eventGroup.eventGroupIds) && isEmpty(eventGroup.eventIds);
}

/**
 * Gets the list of all event IDs that are nested anywhere
 * under the given event group.
 */
export function getAllNestedEventIds(eventGroup: PopulatedEventGroup | undefined): EventId[] {
  if (eventGroup == null) {
    return [];
  }

  return getAllNestedEvents(eventGroup).map((event) => event.id);
}

/**
 * Gets the list of all events that are nested anywhere
 * under the given event group.
 */
export function getAllNestedEvents(eventGroup: PopulatedEventGroup | undefined): EventModel[] {
  if (eventGroup == null) {
    return [];
  }

  const nestedEvents: Record<EventId, EventModel> = {};
  const groupsToVisit: PopulatedEventGroup[] = [eventGroup];
  while (groupsToVisit.length > 0) {
    const curr = groupsToVisit.pop();
    if (curr == null) {
      break;
    }

    curr.events.forEach((event) => {
      nestedEvents[event.id] = event;
    });
    groupsToVisit.push(...curr.eventGroups);
  }
  return Object.values(nestedEvents);
}

export function getAllNestedImpactedDriverIds(eventGroup: PopulatedEventGroup): DriverId[] {
  const allEvents = getAllNestedEvents(eventGroup);
  const allDriverEvents = allEvents.filter(isDriverEvent);
  return uniq(allDriverEvents.map((e) => e.driverId));
}

export function getAllNestedEventGroups(eventGroup: PopulatedEventGroup): PopulatedEventGroup[] {
  return eventGroup.eventGroups;
}

export function getComputedEventGroupRangeWithDefault(
  eventGroup: PopulatedEventGroup,
  rangeByGroupId: Record<EventGroupId, Range>,
): Range {
  const cachedResult = rangeByGroupId[eventGroup.id];
  if (cachedResult != null) {
    return cachedResult;
  }

  const ranges: Range[] = [
    ...eventGroup.events.map((ev) => ({ start: ev.start, end: ev.end })),
    ...eventGroup.eventGroups
      .filter((group) => !group.hidden)
      .map((group) => getComputedEventGroupRangeWithDefault(group, rangeByGroupId))
      .filter(isNotNull),
  ];
  const start = min(ranges.map((r) => r.start).filter(isNotNull));
  const end = max(ranges.map((r) => r.end).filter(isNotNull));

  const range: Range = {
    start: start ?? eventGroup.defaultStart ?? START_OF_MONTH.toISO(),
    end: end ?? eventGroup.defaultEnd ?? START_OF_MONTH.endOf('month').toISO(),
  };
  rangeByGroupId[eventGroup.id] = range;

  return range;
}

export function getImpactTooltipText(
  eventGroupsById: Record<EventGroupId, EventGroup>,
  eventGroupIds: Array<EventGroupId | null>,
) {
  const plans = eventGroupIds
    .filter((id) => id != null)
    .map((eventGroupId) => {
      if (eventGroupId === DEFAULT_EVENT_GROUP_ID) {
        return DEFAULT_PLAN_NAME;
      }

      const eventGroup = eventGroupsById[eventGroupId];
      return eventGroup?.name;
    })
    .filter((name) => name != null);

  if (plans.length === 0) {
    return null;
  }

  return `Impacted by ${toStringList(plans)}`;
}
