import { Box, Flex, Text } from '@chakra-ui/react';
import pluralize from 'pluralize';
import React from 'react';

import TableSection from 'components/DriverDetailPane/TableSection';
import DriverGroupHeaderRows from 'components/DriverGroupHeaderRows/DriverGroupHeaderRows';
import DriverGroupSectionContent from 'components/DriverGroupSectionContent/DriverGroupSectionContent';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import { DriverGridContext, DriverGridContextValue } from 'config/driverGridContext';
import { SCENARIO_COMPARISON_STICKY_HEADER_HEIGHT_IN_PX } from 'config/scenarioComparison';
import useAppSelector from 'hooks/useAppSelector';
import {
  identicalComparedDriversSelector,
  scenarioComparisonDriverIdsSelector,
  scenarioComparisonMaxDriverDiffsReachedSelector,
} from 'selectors/scenarioComparisonSelector';

const driverGridContext: DriverGridContextValue = {
  canEditGroups: false,
  hasCreateButton: false,
  hasDimensionsButton: false,
  canDelete: false,
  canCreateDriverGroup: false,
};

const DriversComparisonGrid: React.FC = () => {
  const tooLargeToDisplay = useAppSelector(scenarioComparisonMaxDriverDiffsReachedSelector);

  return (
    <Flex flexDir="column" rowGap={4}>
      {tooLargeToDisplay ? (
        <Text fontSize="xs" color="gray.500">
          This merge would impact more drivers than can be displayed in this modal.
        </Text>
      ) : (
        <DriversComparisonGridContents />
      )}
    </Flex>
  );
};

const DriversComparisonGridContents: React.FC = () => {
  const driverIds = useAppSelector(scenarioComparisonDriverIdsSelector);
  const areDriversIdentical = useAppSelector(identicalComparedDriversSelector);

  if (areDriversIdentical) {
    return (
      <Text fontSize="xs" color="gray.500">
        No difference in drivers.
      </Text>
    );
  }

  return (
    <DriverGridContext.Provider value={driverGridContext}>
      {driverIds.length > 0 ? (
        <Box data-testid="driver-comparison-grid">
          <TableSection>
            <StickyHeader stickyTop={SCENARIO_COMPARISON_STICKY_HEADER_HEIGHT_IN_PX}>
              <DriverGroupHeaderRows groupName={pluralize('Driver', driverIds.length)} />
            </StickyHeader>
            <DriverGroupSectionContent driverIds={driverIds} data-testid="driver-comparison-grid" />
          </TableSection>
        </Box>
      ) : null}
    </DriverGridContext.Provider>
  );
};

export default DriversComparisonGrid;
