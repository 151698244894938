// eslint-disable-next-line you-dont-need-lodash-underscore/find
import { find } from 'lodash';
import * as Urql from 'urql';

import {
  AiRefinementInput,
  AiRefinementType,
  BlockCreateInput,
  BlockType,
  DatasetMutationInput,
  DriverUpdateInput,
  InputMaybe,
} from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { aiGroupSelected } from 'reduxStore/actions/copilot/groupDrivers';
import { aiGroupEvents } from 'reduxStore/actions/copilot/groupEvents';
import { navigateToLayer } from 'reduxStore/actions/navigateTo';
import { submitEphemeralMutations, submitMutation } from 'reduxStore/actions/submitDatasetMutation';
import {
  trackCopilotRefinement,
  trackCopilotRetry,
  trackCopilotStart,
} from 'reduxStore/actions/trackEvent';
import { BlocksPageId } from 'reduxStore/models/blocks';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import rootReducer from 'reduxStore/reducers';
import {
  CopilotOperation,
  endCopilotSession,
  setCopilotCursor,
  setCopilotRequestStatus,
  startCopilotSession,
} from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppDispatch, AppThunk } from 'reduxStore/store';
import {
  copilotCursorSelector,
  copilotOperationSelector,
  hasActiveCopilotSessionSelector,
  isQueryingCopilotSelector,
} from 'selectors/copilotSelector';
import {
  driverIdsByNameSelector,
  driversByIdForCurrentLayerSelector,
} from 'selectors/driversSelector';
import { layersSelector } from 'selectors/layerSelector';
import { authenticatedUserIdSelector } from 'selectors/loginSelector';
import { pageIdSelector } from 'selectors/pageSelector';
import { selectedOrgIdSelector } from 'selectors/selectedOrgSelector';

import { getUpdateDriverReferenceMutation } from './driverMutations';

const copilotRequestConfig: Partial<Record<CopilotOperation, CopilotRequest<any, any>>> = {
  [CopilotOperation.GroupDrivers]: aiGroupSelected,
  [CopilotOperation.GroupEvents]: aiGroupEvents,
};

export type CopilotRequest<
  D,
  V extends { input: { context?: InputMaybe<unknown>; prompt?: InputMaybe<unknown> } },
> = {
  query: Urql.TypedDocumentNode<D, V>;
  getContext: (state: RootState) => V['input']['context'];
  getPrompt: (state: RootState) => V['input']['prompt'];
  extractCursor: (data: D) => string | null;
  handleResponse: (
    data: D | undefined,
    dispatch: AppDispatch,
    op: CopilotOperation,
    getState: () => RootState,
  ) => void;
};

type CopilotActionArgs = { rerun: true } | { refinement: string } | { operation: CopilotOperation };

function rewindStateToBeforeCopilotSession(state: RootState) {
  return rootReducer(state, endCopilotSession({ applyChanges: false }));
}

export const triggerCopilotRequest = (args: CopilotActionArgs): AppThunk => {
  return (dispatch, getState, { urqlClient }) => {
    const state = getState();
    const orgId = selectedOrgIdSelector(state);
    const cursor = copilotCursorSelector(state);
    const isRefinement = args != null && cursor != null;
    const isRerun = isRefinement && 'rerun' in args;
    let operation = copilotOperationSelector(state);

    if ('operation' in args) {
      if (operation != null && operation !== args.operation) {
        // if we are switching operations, commit the existing ephemeral mutations
        dispatch(submitEphemeralMutations());
      }

      // start new session
      operation = args.operation;
      dispatch(startCopilotSession(operation));
      dispatch(trackCopilotStart(operation));
    } else if (operation != null) {
      // preserve our existing session
      dispatch(setCopilotRequestStatus('pending'));
    } else if (operation == null || copilotRequestConfig[operation] == null) {
      throw new Error('unrecognized copilot operation');
    }

    if (operation === CopilotOperation.PlanScenario) {
      handlePlanScenarioVaporware(args, dispatch, getState);
      return;
    }

    const copilotRequest = copilotRequestConfig[operation];
    if (copilotRequest == null) {
      throw new Error(`unimplemented copilot operation: ${operation}`);
    }
    let context: unknown;
    let prompt: unknown;
    let refinement: InputMaybe<AiRefinementInput> | undefined;
    if (isRefinement) {
      const refinementText = 'refinement' in args ? args.refinement : undefined;
      refinement = {
        cursor,
        refinementType: isRerun ? AiRefinementType.GenericRerun : AiRefinementType.UserSpecified,
        refinementText,
      };
      if (isRerun) {
        dispatch(trackCopilotRetry());
      } else if (refinementText != null) {
        dispatch(trackCopilotRefinement(refinementText));
      }
    } else {
      const freshState = getState();
      context = copilotRequest.getContext(freshState);
      prompt = copilotRequest.getPrompt(freshState);
    }

    urqlClient
      .query(copilotRequest.query, {
        input: {
          orgId,
          context,
          prompt,
          refinement,
        },
      })
      .toPromise()
      .then((result) => {
        if (result.error) {
          dispatch(setCopilotRequestStatus('error'));
          return;
        }

        // bail out if the session was cancelled
        const newState = getState();
        if (!hasActiveCopilotSessionSelector(newState)) {
          return;
        }

        const sessionCursor = copilotRequest.extractCursor(result.data);
        if (sessionCursor == null) {
          throw new Error('missing cursor from copilot response');
        }

        copilotRequest.handleResponse(result.data, dispatch, operation, () => {
          return rewindStateToBeforeCopilotSession(getState());
        });

        dispatch(setCopilotCursor(sessionCursor));
      })
      .catch(() => {
        dispatch(setCopilotRequestStatus('error'));
      })
      .finally(() => {
        const newState = getState();
        const isQuerying = isQueryingCopilotSelector(newState);
        if (isQuerying) {
          dispatch(setCopilotRequestStatus('success'));
        }
      });
  };
};

function handlePlanScenarioVaporware(
  args: CopilotActionArgs,
  dispatch: AppDispatch,
  getState: () => RootState,
) {
  if (!('refinement' in args)) {
    // unhandled flow for the demo
    return;
  }

  const state = getState();
  const pageId = pageIdSelector(state);
  if (pageId == null) {
    // unhandled flow for the demo
    return;
  }

  const userId = authenticatedUserIdSelector(state);
  const layers = Object.values(layersSelector(state));
  const scenarioName = '3x Revenue';
  const existingLayerId = layers.find(
    (layer) => layer.name === scenarioName && !layer.isDeleted,
  )?.id;
  const newLayerId = existingLayerId ?? uuidv4();
  let newLayers: DatasetMutationInput['newLayers'];
  if (existingLayerId == null) {
    newLayers = [
      {
        id: newLayerId,
        name: '3x Revenue',
        isDraft: false,
        userId,
      },
    ];
  }

  setTimeout(() => {
    const mutation = genVaporwareMutation(pageId, state);
    dispatch(
      submitMutation(
        {
          newLayers,
          updateBlocksPages: [{ id: pageId, name: '🍯 2025 Revenue Planning' }],
          // NOTE: If genVaporwareMutation returns newLayers or
          // updateBlocksPages, we'll have to use mergeMutations instead.
          ...mutation,
        },
        { forceLayerId: DEFAULT_LAYER_ID, isEphemeral: true },
      ),
    );

    dispatch(setCopilotRequestStatus('success'));
    dispatch(navigateToLayer(newLayerId));
  }, 3700);
}

function genVaporwareMutation(
  pageId: BlocksPageId,
  state: RootState,
): Pick<DatasetMutationInput, 'updateDrivers' | 'newBlocks'> {
  const driverIdsByName = driverIdsByNameSelector(state);
  const driversById = driversByIdForCurrentLayerSelector(state);
  function findDriverByPrefix(prefix: string) {
    const id = find(driverIdsByName, (_ids, name) => name.startsWith(prefix))?.[0];
    return id != null ? driversById[id] : null;
  }

  const textBlock: BlockCreateInput = {
    id: uuidv4(),
    name: '',
    pageId,
    sortIndex: 0,
    type: BlockType.Text,
    blockConfig: {
      textBlockBody:
        '{"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"I have devised a scenario that triples our revenue in 2024. This plan focuses on three key areas: increasing sales headcount, decreasing churn, and boosting expansion MRR. By expanding the sales team, we aim to acquire new customers and tap into untapped markets. To reduce churn, we will address customer pain points and provide exceptional support. Leveraging our existing customer base and optimizing our offerings will drive expansion MRR. These strategies will position us for significant revenue growth in 2024."}]}]}',
    },
  };

  const chartDrivers = [
    findDriverByPrefix('ARR'),
    findDriverByPrefix('Revenue'),
    findDriverByPrefix('Cash'),
  ].filter(isNotNull);

  const chartBlock: BlockCreateInput = {
    id: uuidv4(),
    name: 'Impacted KPIs',
    pageId,
    sortIndex: 1,
    type: BlockType.DriverCharts,
    blockConfig: {
      idFilter: chartDrivers.map((d) => d.id),
    },
  };
  const planTimelineBlock: BlockCreateInput = {
    id: uuidv4(),
    name: 'Plans',
    pageId,
    sortIndex: 2,
    type: BlockType.PlanTimeline,
    blockConfig: {},
  };

  const tableDrivers = [findDriverByPrefix('AE Headcount'), findDriverByPrefix('New MRR')].filter(
    isNotNull,
  );
  const driverTableBlock: BlockCreateInput = {
    id: uuidv4(),
    name: 'Affected Drivers',
    pageId,
    sortIndex: 3,
    type: BlockType.DriverGrid,
    blockConfig: {
      idFilter: tableDrivers.map((d) => d.id),
    },
  };

  const updateDrivers: DriverUpdateInput[] = [
    ...chartDrivers
      .map((driver) => getUpdateDriverReferenceMutation({ driver, blockId: chartBlock.id }))
      .filter(isNotNull),
    ...tableDrivers
      .map((driver) => getUpdateDriverReferenceMutation({ driver, blockId: driverTableBlock.id }))
      .filter(isNotNull),
  ];

  const mutation: DatasetMutationInput = {
    newBlocks: [textBlock, chartBlock, planTimelineBlock, driverTableBlock],
    updateDrivers,
  };

  return mutation;
}
