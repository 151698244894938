import { createCachedSelector } from 're-reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { DriverForLayerProps } from 'selectors/driversSelector';
import { eventsForDriverSelector } from 'selectors/eventsAndGroupsSelector';
import { MonthKey } from 'types/datetime';
import { ParametricSelector } from 'types/redux';

export const driverMonthKeysWithEventImpactSelector: ParametricSelector<
  DriverForLayerProps,
  Set<MonthKey>
> = createCachedSelector(eventsForDriverSelector, (driverEvents) => {
  if (driverEvents.length === 0) {
    return new Set<MonthKey>();
  }

  return new Set(
    driverEvents.flatMap((ev) =>
      ev.customCurvePoints == null ? [] : Object.keys(ev.customCurvePoints),
    ),
  );
})({
  keySelector: (_state, props: DriverForLayerProps) => `${props.layerId},${props.id}`,
  selectorCreator: createDeepEqualSelector,
});
