import Joi from 'joi';
import { useMemo } from 'react';

import { joiDefault } from 'helpers/errorMessages';
import useAppSelector from 'hooks/useAppSelector';
import { submodelTableGroupsSelector } from 'selectors/submodelTableGroupsSelector';

export const useDriverGroupNameValidation = (ignoreGroupNames: string[] = []) => {
  const existingGroups = useAppSelector(submodelTableGroupsSelector);
  const validator = useMemo(() => {
    const groupNames = Object.values(existingGroups)
      .map((group) => group.name)
      .filter((name) => !ignoreGroupNames.includes(name));
    return Joi.object({
      groupName: Joi.string()
        .required()
        .min(1)
        .invalid(...groupNames)
        .messages(joiDefault('driver group')),
    });
  }, [existingGroups, ignoreGroupNames]);
  return validator;
};

export const DEFAULT_DRIVER_GROUP_NAME = 'Drivers';
