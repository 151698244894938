import lodashInvert from 'lodash/invert';

/**
 * N.B. type used to provide accurate typing of object lookup
 * e.g. const a: Record<string, number> = {}; const b = a['k'];
 * b is incorrectly typed as number instead of number | undefined
 */
export function safeObjGet<T>(val: T): T | undefined {
  return val;
}

export function isNotNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

export function isFiniteNumber(value: any): value is number {
  return Boolean(value) && Number.isFinite(value);
}

export function isNull<T>(value: T): value is NonNullable<T> {
  return value == null;
}

export function nullSafeEqual<T>(lhs: T | null | undefined, rhs: T | null | undefined) {
  return (lhs ?? null) === (rhs ?? null);
}

// From https://stackoverflow.com/questions/56415826/is-it-possible-to-precisely-type-invert-in-typescript
type InvertResult<T extends Record<PropertyKey, PropertyKey>> = {
  [P in keyof T as T[P]]: P;
};

export function invert<T extends Record<PropertyKey, PropertyKey>>(obj: T): InvertResult<T> {
  return lodashInvert(obj) as InvertResult<T>;
}

/**
 * Can be used in switch statements, for example, to assert that the default case
 * should never be reached and all switch cases should be accounted for.  This will
 * be caught at compile time.
 */
export function assertUnreachable(_: never): never {
  // This error should theoretically not be hit at runtime, since it should be caught by TS.
  throw new Error('Unreachable code hit. This should be caught at compile time.');
}
