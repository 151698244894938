import { DateTime } from 'luxon';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import {
  BlockFilterOperator,
  BlockSortType,
  BlockType,
  ImpactSortExtension,
  ValueType,
} from 'generated/graphql';
import {
  CURRENT_MONTH_KEY,
  TODAY,
  extractMonthKey,
  getMonthKey,
  numMonthsBetweenDates,
} from 'helpers/dates';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  getAllNestedEventGroups,
  getAllNestedEventIds,
  getAllNestedEvents,
  getComputedEventGroupRangeWithDefault,
} from 'helpers/eventGroups';
import { getTimeRangeInput } from 'helpers/formula';
import { evaluateTimeRange } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { FormulaEvaluator } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import { getCacheKeyForLayerSelector } from 'helpers/layerSelectorFactory';
import {
  PlanTimelineRowRef,
  SortRoadmapRefFn,
  findPlanTimelineRowWithEvent,
  getPlanTimelineRowRefs,
} from 'helpers/planTimeline';
import { TimelineFilterItem } from 'helpers/timelineFiltering';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectSpec } from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObjectFieldId,
  BusinessObjectId,
  PopulatedBusinessObjectField,
} from 'reduxStore/models/businessObjects';
import { DimensionalSubDriver, DriverId } from 'reduxStore/models/drivers';
import {
  DEFAULT_EVENT_GROUP_ID,
  DriverEvent,
  Event,
  EventGroupId,
  EventId,
  ObjectFieldEvent,
  PlanTimelineItemRef,
  PopulatedEventGroup,
  isDriverEvent,
  isObjectFieldEvent,
  isPlanTimelineItemRefEqual,
  refFromPlanTimelineItem,
} from 'reduxStore/models/events';
import { SubmodelId } from 'reduxStore/models/submodels';
import { OrgUser } from 'reduxStore/models/user';
import { Roadmap } from 'reduxStore/reducers/roadmapSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  accessCapabilityAwareBlockConfigShowRestrictedSelector,
  blockConfigSortBySelector,
  blockTypeSelector,
} from 'selectors/blocksSelector';
import { allRestrictedBusinessObjectFieldSpecIdsSelector } from 'selectors/businessObjectFieldSpecRestrictedSelector';
import { businessObjectFieldByIdForLayerSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { blockIdSelector, fieldSelector } from 'selectors/constSelectors';
import { openDetailPaneSelector } from 'selectors/detailPaneSelectors';
import { totalImpactOnSortDriver } from 'selectors/driverTimeSeriesSelector';
import {
  allDriverIdsSelector,
  basicDriverResolvedNamesByIdSelector,
  driverNamesByIdSelector,
  evaluatorDriversByIdForLayerSelector,
  subDriversByDimDriverIdSelector,
} from 'selectors/driversSelector';
import {
  businessObjectContextByEventGroupIdSelector,
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  eventsByIdForLayerSelector,
  eventsWithoutLiveEditsByIdForLayerSelector,
  populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
  rootInitiativesWithoutLiveEditsForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { formulaCalculationContextSelector } from 'selectors/formulaCalculationContextSelector';
import {
  formulaEvaluatorForLayerSelector,
  formulaEvaluatorWithoutLiveEditingSelector,
} from 'selectors/formulaEvaluatorSelector';
import { allKpiIdsSelector } from 'selectors/kpisSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { detailPanePlanSelector } from 'selectors/planDetailPaneSelector';
import { filtersForTimelineBlockSelector } from 'selectors/planTimelineBlockSelector';
import {
  flattenedSelectedEventIdsSelector,
  getFlattenedSelectedEventIds,
  selectedPlanItemsSelector,
} from 'selectors/selectedEventSelector';
import { usersInSelectedOrgByIdSelector } from 'selectors/selectedOrgSelector';
import { pageSelectionSelector } from 'selectors/selectionSelector';
import { driverIdToSubmodelIdSelector } from 'selectors/submodelSelector';
import { FilterValueTypes } from 'types/filtering';
import { ParametricSelector, Selector } from 'types/redux';

export type PlanTimelineItemBase = {
  name: string;
  depth: number;
  parentId?: EventGroupId;
  parentPath: Array<EventGroupId | undefined>;
  isExpandable: boolean;
  sortImpact?: number;
  hidden: boolean;
};
export type PlanTimelineEventOrGroupBase = PlanTimelineItemBase & {
  description?: string;
  endDate: DateTime;
  numMonths: number;
  sortIndex?: number;
  startDate: DateTime;
  actualStart?: DateTime;
  actualEnd?: DateTime;
};

export type PlanTimelineDriverEvent = PlanTimelineEventOrGroupBase & {
  type: 'driverEvent';
  id: EventId;
  event: DriverEvent;
};
export type PlanTimelineObjectEvent = PlanTimelineEventOrGroupBase & {
  type: 'objectEvent';
  id: EventId;
  event: ObjectFieldEvent;
};
export type PlanTimelineEvent = PlanTimelineDriverEvent | PlanTimelineObjectEvent;

export type PlanTimelineEventGroup = PlanTimelineEventOrGroupBase & {
  type: 'group';
  id: EventGroupId;
  eventGroup: PopulatedEventGroup;
  owner: OrgUser | string;
  relatedObjectId: BusinessObjectId;
};

export type PlanTimelineDriverEntity = PlanTimelineItemBase & {
  type: 'driver';
  // Since the same driver can have events in multiple event groups,
  // we need to include the event group id to ensure uniquness.
  id: `${EventGroupId}:${DriverId}`;
  entityId: DriverId;
  events: PlanTimelineEvent[];
};
export type PlanTimelineObjectFieldEntity = PlanTimelineItemBase & {
  type: 'objectField';
  // Since the same object field can have events in multiple event groups,
  // we need to include the event group id to ensure uniquness.
  id: `${EventGroupId}:${BusinessObjectFieldId}`;
  entityId: BusinessObjectFieldId;
  events: PlanTimelineEvent[];
};
export type PlanTimelineEntity = PlanTimelineDriverEntity | PlanTimelineObjectFieldEntity;

// These are the set of items that can be represented in the left navbar on the Plans timeline
export type PlanTimelineRow = PlanTimelineEventGroup | PlanTimelineEntity;

// These are the set of items that can be rendered on the Plan timeline. Namely, it's the
// set of entities that can be rows (in the left navbar) OR that can be rendered on the
// timeline itself.
type PlanTimelineItem = PlanTimelineRow | PlanTimelineEvent;

export const expandedInitiativeIdsSelector: ParametricSelector<
  BlockId,
  Array<EventGroupId | EventId>
> = createCachedSelector(
  (state: RootState, blockId: BlockId) => state.roadmap.expandedPlans?.[blockId] ?? [],
  (expanded) => expanded,
)({
  selectorCreator: createDeepEqualSelector,
  keySelector: blockIdSelector,
});

export const draftInitiativeSelector: ParametricSelector<
  BlockId,
  { parentId: EventGroupId | null; blockId: BlockId } | null
> = createCachedSelector(
  (state: RootState) => state.roadmap.draftInitiative,
  blockIdSelector,
  (draftInitiative, blockId) => {
    return draftInitiative?.blockId === blockId ? draftInitiative : null;
  },
)(blockIdSelector);

export const roadmapActiveSortForBlockSelector: ParametricSelector<BlockId, BlockSortType> =
  createCachedSelector(
    (state: RootState) => allDriverIdsSelector(state),
    blockConfigSortBySelector,
    (allDriverIds, sortBy) => {
      if (sortBy == null) {
        return BlockSortType.Manual;
      }
      const { sortType, impact } = sortBy;

      if (sortType !== BlockSortType.Impact) {
        return sortType;
      }

      if (impact == null || !allDriverIds.includes(impact.driverId)) {
        return BlockSortType.Manual;
      }
      return BlockSortType.Impact;
    },
  )(blockIdSelector);

const mostRecentlyCreatedEventGroupIdSelector: Selector<
  Roadmap['mostRecentlyCreatedEventGroupId']
> = (state) => state.roadmap.mostRecentlyCreatedEventGroupId;

export const roadmapDimensionsSelector: Selector<Roadmap['roadmapDimensions']> = (state) =>
  state.roadmap.roadmapDimensions;

export const roadmapPanelWidthSelector: Selector<number> = createSelector(
  roadmapDimensionsSelector,
  (dimensions) => dimensions.panelWidth,
);

const blockDefaultImpactSortSelector: Selector<ImpactSortExtension | null> =
  createDeepEqualSelector(allKpiIdsSelector, (allKpis) => {
    if (allKpis.length === 0) {
      return null;
    }

    return {
      driverId: allKpis[0],
      startMonthKey: CURRENT_MONTH_KEY,
      endMonthKey: getMonthKey(TODAY.plus({ years: 1 })),
    };
  });

export const blockImpactSortByExtensionForBlockSelector: ParametricSelector<
  BlockId,
  ImpactSortExtension | null
> = createCachedSelector(
  blockDefaultImpactSortSelector,
  roadmapActiveSortForBlockSelector,
  blockConfigSortBySelector,
  (defaultImpactSort, activeSort, blockConfigSortBy) => {
    return activeSort === BlockSortType.Impact && blockConfigSortBy != null
      ? (blockConfigSortBy?.impact ?? defaultImpactSort)
      : defaultImpactSort;
  },
)(blockIdSelector);

export const blockSortTitleSelector: ParametricSelector<BlockId, string> = createCachedSelector(
  roadmapActiveSortForBlockSelector,
  blockImpactSortByExtensionForBlockSelector,
  basicDriverResolvedNamesByIdSelector,
  (sort, impactSort, driverNamesById) => {
    switch (sort) {
      case BlockSortType.Impact: {
        if (impactSort != null) {
          return `Impact on ${driverNamesById[impactSort.driverId]}`;
        }
        break;
      }
      case BlockSortType.StartDate: {
        return 'Sort by start date';
      }
      default: {
        break;
      }
    }

    return 'Sort by';
  },
)(blockIdSelector);

const timelineRefOrderSelector: ParametricSelector<
  BlockId,
  {
    sortFns: SortRoadmapRefFn[];
    sortDirections: Array<'asc' | 'desc'>;
  }
> = createCachedSelector(
  roadmapActiveSortForBlockSelector,
  blockImpactSortByExtensionForBlockSelector,
  (state: RootState) => eventsWithoutLiveEditsByIdForLayerSelector(state),
  (state: RootState) => populatedEventGroupsWithoutLiveEditsByIdForLayerSelector(state),
  (state: RootState) => evaluatorDriversByIdForLayerSelector(state),
  (state: RootState) => formulaEvaluatorWithoutLiveEditingSelector(state),
  (state: RootState) => formulaCalculationContextSelector(state),
  // eslint-disable-next-line max-params
  function timelineRefOrderSelector(
    activeSort,
    impactSort,
    eventsById,
    eventGroupsById,
    evaluatorDriversById,
    evaluator,
    context,
  ) {
    const rangeByGroupId = {};
    const sortByStartDate = ({ id, type }: PlanTimelineRowRef) => {
      if (type === 'event') {
        return DateTime.fromISO(eventsById[id].start).toMillis();
      }

      const eventGroup = eventGroupsById[id];
      if (eventGroup == null) {
        return undefined;
      }

      const { start } = getComputedEventGroupRangeWithDefault(eventGroup, rangeByGroupId);

      return DateTime.fromISO(start).toMillis();
    };

    const sortByImpact = (ref: PlanTimelineRowRef) => {
      const { id, type } = ref;
      const eventIds = type === 'event' ? [id] : getAllNestedEventIds(eventGroupsById[id]);
      ref.sortImpact = totalImpactOnSortDriver({
        evaluatorDriversById,
        impactSort,
        evaluator,
        context,
        eventIds,
      });
      return ref.sortImpact;
    };

    const sortManually = ({ id, type }: PlanTimelineRowRef) => {
      return type === 'event' ? eventsById[id]?.sortIndex : eventGroupsById[id]?.sortIndex;
    };

    switch (activeSort) {
      case BlockSortType.StartDate: {
        return {
          sortFns: [sortByStartDate, sortManually],
          sortDirections: ['asc', 'asc'] as Array<'asc' | 'desc'>,
        };
      }
      case BlockSortType.Impact: {
        if (impactSort == null) {
          throw new Error('impact sort undefined');
        }
        return {
          sortFns: [sortByImpact, sortManually],
          sortDirections: ['desc', 'asc'] as Array<'asc' | 'desc'>,
        };
      }
      case BlockSortType.Manual:
      default: {
        return {
          sortFns: [sortManually],
          sortDirections: ['asc'] as Array<'asc' | 'desc'>,
        };
      }
    }
  },
)(blockIdSelector);

const planTimelineRowsRefSelector: ParametricSelector<BlockId, PlanTimelineRowRef[]> =
  createCachedSelector(
    (state: RootState) => rootInitiativesWithoutLiveEditsForLayerSelector(state),
    (state: RootState) => eventsByIdForLayerSelector(state),
    (state: RootState) => populatedEventGroupsWithoutLiveEditsByIdForLayerSelector(state),
    blockDateRangeDateTimeSelector,
    timelineRefOrderSelector,
    (state: RootState) => detailPanePlanSelector(state),
    expandedInitiativeIdsSelector,
    (
      rootEventsAndEventGroups,
      eventsById,
      populatedEventGroupsById,
      blockDateRangeDateTime,
      order,
      detailPanePlan,
      expandedInitiativeIds,
      // eslint-disable-next-line max-params
    ) => {
      const rootEventGroups =
        detailPanePlan != null
          ? [detailPanePlan]
          : rootEventsAndEventGroups.rootEventGroups
              .map((id) => populatedEventGroupsById[id])
              .filter(isNotNull)
              .filter(
                (eventGroup) =>
                  eventGroup.id !== DEFAULT_EVENT_GROUP_ID ||
                  eventGroup.events.length > 0 ||
                  eventGroup.eventGroups.length > 0,
              );
      const rootEvents =
        detailPanePlan != null
          ? []
          : rootEventsAndEventGroups.rootEvents.map((id) => eventsById[id]).filter(isNotNull);

      return getPlanTimelineRowRefs({
        rootEventsAndEventGroups: {
          rootEventGroups,
          rootEvents,
        },
        order,
        shouldTraverseRowChildren: (rowRef: PlanTimelineRowRef) =>
          expandedInitiativeIds.includes(rowRef.id),
        blockDateRangeDateTime,
      });
    },
  )(blockIdSelector);

// Exported for testing
export function getTimelineObjectSpecs({
  timelineRowRefs,
  eventsById,
  businessObjectSpecsById,
  businessObjectFieldsById,
  subDriversByDimDriverId,
}: {
  timelineRowRefs: PlanTimelineRowRef[];
  eventsById: Record<EventId, Event>;
  businessObjectSpecsById: Record<string, BusinessObjectSpec>;
  businessObjectFieldsById: Record<BusinessObjectFieldId, PopulatedBusinessObjectField>;
  subDriversByDimDriverId: Record<DriverId, DimensionalSubDriver[]>;
}): BusinessObjectSpec[] {
  const businessObjectSpecs = Object.values(businessObjectSpecsById);

  const timelineObjectSpecs = timelineRowRefs
    .map((timelineRow) => {
      const event = eventsById[timelineRow.id];

      if (event == null) {
        return null;
      }

      if (isObjectFieldEvent(event)) {
        const field = businessObjectFieldsById[event.businessObjectFieldId];
        if (field == null) {
          return null;
        }

        const spec = businessObjectSpecsById[field.objectSpecId];

        return spec ?? null;
      }

      const objectSpec = businessObjectSpecs.find((spec) =>
        driverEventMatchesObjectSpec(event, spec, subDriversByDimDriverId),
      );

      return objectSpec ?? null;
    })
    .filter(isNotNull);

  return timelineObjectSpecs;
}

export const planTimelineObjectSpecsSelector: ParametricSelector<BlockId, BusinessObjectSpec[]> =
  createCachedSelector(
    planTimelineRowsRefSelector,
    eventsByIdForLayerSelector,
    businessObjectSpecsByIdForLayerSelector,
    businessObjectFieldByIdForLayerSelector,
    subDriversByDimDriverIdSelector,
    (
      timelineRowRefs,
      eventsById,
      businessObjectSpecsById,
      businessObjectFieldsById,
      subDriversByDimDriverId,
    ) => {
      return getTimelineObjectSpecs({
        timelineRowRefs,
        eventsById,
        businessObjectSpecsById,
        businessObjectFieldsById,
        subDriversByDimDriverId,
      });
    },
  )(blockIdSelector);

export interface FilteringContext {
  blockId: BlockId;
  eventGroupsById: NullableRecord<EventGroupId, PopulatedEventGroup>;
  businessObjectFieldsById: Record<string, PopulatedBusinessObjectField>;
  businessObjectSpecsById: Record<string, BusinessObjectSpec>;
  submodelIdByDriverId: Record<DriverId, SubmodelId>;
  formulaEvaluator: FormulaEvaluator;
  subDriversByDimDriverId: Record<DriverId, DimensionalSubDriver[]>;
}

const filteringContextSelector: ParametricSelector<BlockId, FilteringContext> =
  createCachedSelector(
    blockIdSelector,
    driverIdToSubmodelIdSelector,
    formulaEvaluatorForLayerSelector,
    businessObjectFieldByIdForLayerSelector,
    populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
    businessObjectSpecsByIdForLayerSelector,
    subDriversByDimDriverIdSelector,
    (
      blockId,
      submodelIdByDriverId,
      formulaEvaluator,
      businessObjectFieldsById,
      eventGroupsById,
      businessObjectSpecsById,
      subDriversByDimDriverId,
      // eslint-disable-next-line max-params
    ) => {
      return {
        blockId,
        submodelIdByDriverId,
        formulaEvaluator,
        businessObjectFieldsById,
        eventGroupsById,
        businessObjectSpecsById,
        subDriversByDimDriverId,
      };
    },
  )(blockIdSelector);

export const planTimelineRowsSelector: ParametricSelector<BlockId, PlanTimelineRow[]> =
  createCachedSelector(
    planTimelineRowsRefSelector,
    (state: RootState) => eventsByIdForLayerSelector(state),
    (state: RootState) => driverNamesByIdSelector(state),
    usersInSelectedOrgByIdSelector,
    (state: RootState) => populatedEventGroupsWithoutLiveEditsByIdForLayerSelector(state),
    accessCapabilityAwareBlockConfigShowRestrictedSelector,
    allRestrictedBusinessObjectFieldSpecIdsSelector,
    businessObjectContextByEventGroupIdSelector,
    filtersForTimelineBlockSelector,
    mostRecentlyCreatedEventGroupIdSelector,
    blockDateRangeDateTimeSelector,
    filteringContextSelector,
    (
      timelineRowRefs,
      eventsById,
      driverNamesById,
      usersById,
      populatedEventGroupsById,
      showRestrictedForBlock,
      restrictedObjectFieldSpecIds,
      businessObjectContextByEventGroupId,
      timelineFilters,
      mostRecentlyCreatedEventGroupId,
      timelineDateRange,
      filteringContext,
      // eslint-disable-next-line max-params
    ) => {
      const rangeByGroupId = {};
      const hasActiveFilters = timelineFilters != null && timelineFilters.length > 0;
      const timelineStart = timelineDateRange[0];
      const timelineEnd = timelineDateRange[1].endOf('month').startOf('second');

      const { businessObjectFieldsById } = filteringContext;

      function eventGroupToInitiative(
        eventGroup: PopulatedEventGroup | undefined,
        filteredEvents: Event[],
        filteredEventGroups: PopulatedEventGroup[],
        depth: number,
      ): PlanTimelineEventGroup | null {
        if (eventGroup == null) {
          return null;
        }
        const { id, parentId } = eventGroup;

        // Always show the most recently created event group regardless of filter.
        if (hasActiveFilters) {
          const eventGroupIsShown =
            eventGroupPassesBlockFilters(eventGroup, timelineFilters, filteringContext) ||
            mostRecentlyCreatedEventGroupId === id;
          if (!eventGroupIsShown) {
            return null;
          }
        }

        const { start, end } = getComputedEventGroupRangeWithDefault(eventGroup, rangeByGroupId);
        // when the user creates the initial impact under a group, don't shrink the group down to size
        // until the user has begun editing the impact values
        const startDate = DateTime.fromISO(start);
        const endDate = DateTime.fromISO(end);

        const { description, hidden, ownerName, sortIndex, ownerId } = eventGroup;
        const ownerUser = ownerId != null ? usersById[ownerId] : null;

        // If an event group just contains events for an object, check if it is
        // marked as the default event group for that object. If this is the
        // case, set the name to be dynamic based off of the object's name and
        // spec name.
        const objContextForEventGroup = businessObjectContextByEventGroupId[eventGroup.id];
        const name =
          objContextForEventGroup != null
            ? objContextForEventGroup.eventGroupName
            : eventGroup.name;

        const visibleStart = startDate > timelineStart ? startDate : timelineStart;
        const visibleEnd = endDate < timelineEnd ? endDate : timelineEnd;
        return {
          type: 'group',
          eventGroup,
          id,
          name,
          description,
          hidden,
          startDate: visibleStart,
          endDate: visibleEnd,
          depth,
          numMonths: numMonthsBetweenDates(visibleStart, visibleEnd),
          owner: ownerUser ?? ownerName,
          sortIndex,
          parentId,
          isExpandable: filteredEventGroups.length + filteredEvents.length > 0,
          parentPath: getParentPath(eventGroup.parentId, populatedEventGroupsById),
          relatedObjectId: objContextForEventGroup?.id,
          actualEnd: visibleEnd.equals(endDate) ? undefined : endDate,
          actualStart: visibleStart.equals(startDate) ? undefined : startDate,
        };
      }

      function eventToInitiative(event: Event, depth: number): PlanTimelineEvent | null {
        const { id, hidden, parentId, start, end, sortIndex } = event;
        // If an event doesnt match the filters but its parent group was the most recently created
        // group, then we should show the event.
        const shouldShowEventThatDoesntMatchFilters =
          parentId != null && mostRecentlyCreatedEventGroupId === parentId;
        if (hasActiveFilters) {
          const eventIsShown =
            eventPassesTimelineBlockFilters(event, timelineFilters, filteringContext) ||
            shouldShowEventThatDoesntMatchFilters;
          if (!eventIsShown) {
            return null;
          }
        }

        const startDate = DateTime.fromISO(start);
        const endDate = DateTime.fromISO(end);
        const visibleStart = startDate > timelineStart ? startDate : timelineStart;
        const visibleEnd = endDate < timelineEnd ? endDate : timelineEnd;
        const commonEvent = {
          depth,
          startDate: visibleStart,
          endDate: visibleEnd,
          numMonths: numMonthsBetweenDates(visibleStart, visibleEnd),
          id,
          hidden,
          isExpandable: false,
          sortIndex,
          parentId,
          parentPath: getParentPath(event.parentId, populatedEventGroupsById),
          actualEnd: visibleEnd.equals(endDate) ? undefined : endDate,
          actualStart: visibleStart.equals(startDate) ? undefined : startDate,
        };

        if (isDriverEvent(event)) {
          return {
            type: 'driverEvent',
            name: driverNamesById[event.driverId],
            event,
            ...commonEvent,
          };
        } else if (isObjectFieldEvent(event)) {
          const field = businessObjectFieldsById[event.businessObjectFieldId];
          if (field == null) {
            return null;
          }
          if (!showRestrictedForBlock && restrictedObjectFieldSpecIds.has(field.fieldSpecId)) {
            return null;
          }
          return {
            type: 'objectEvent',
            name: `${field.fieldSpec?.name} (${field.objectName})`,
            event,
            ...commonEvent,
          };
        }
        return null;
      }

      const allInitiatives: Array<PlanTimelineEventGroup | PlanTimelineEvent> = timelineRowRefs
        .map((rowRef) => {
          const { id, type, depth, sortImpact, events, eventGroups } = rowRef;
          const event = eventsById[id];

          if (type === 'event') {
            const eventInitiative = eventToInitiative(event, depth);
            return eventInitiative == null ? null : { ...eventInitiative, sortImpact };
          } else if (type === 'group') {
            const eventGroupInitiative = eventGroupToInitiative(
              populatedEventGroupsById[id],
              events ?? [],
              eventGroups ?? [],
              depth,
            );
            if (eventGroupInitiative == null) {
              return null;
            }
            return {
              ...eventGroupInitiative,
              sortImpact,
            };
          }

          return null;
        })
        .filter(isNotNull);

      return collapseInitiatives(allInitiatives);
    },
  )(blockIdSelector);

function entityIdFromInitiative(
  initiative: PlanTimelineEventGroup | PlanTimelineEvent,
): EventGroupId | DriverId | BusinessObjectFieldId {
  if (initiative.type === 'group') {
    return initiative.eventGroup.id;
  }

  if (initiative.type === 'driverEvent') {
    return initiative.event.driverId;
  }

  return initiative.event.businessObjectFieldId;
}

// Collapse all initiatives within the same event group that belong to the same entity.
// Exported for testing
export function collapseInitiatives(
  allInitiatives: Array<PlanTimelineEventGroup | PlanTimelineEvent>,
): PlanTimelineRow[] {
  const rootEventGroupOrder: Array<PlanTimelineEventGroup['id']> = [];
  const eventGroupsById: Record<EventGroupId, PlanTimelineEventGroup> = {};

  const entityOrderByEventGroupId: Record<
    EventGroupId,
    Array<EventGroupId | DriverId | BusinessObjectFieldId>
  > = {};
  const eventsByEntityIdByEventGroupId: Record<
    EventGroupId,
    Record<PlanTimelineEntity['entityId'], PlanTimelineEvent[]>
  > = {};

  // The first step is to iterate over all initiatives and track two main things:
  // 1. The order in which rows should be rendered
  // 2. Which events need to be collapsed
  allInitiatives.forEach((initiative) => {
    const entityId = entityIdFromInitiative(initiative);

    const { parentId } = initiative;

    if (parentId == null) {
      if (initiative.type !== 'group') {
        // This should never happen - only event groups can be roots
        // TODO: There is a bug where this assumption is false - figure out how user
        // got into this state
        return;
      }
      // This is a root event group.
      // We track root event groups in a special way, since parentIds are undefined
      rootEventGroupOrder.push(entityId);

      eventGroupsById[entityId] = initiative;
      return;
    }

    // 1. Track order of events + event groups within parentId's event group
    const orderInParentEventGroup = entityOrderByEventGroupId[parentId] ?? [];
    if (!orderInParentEventGroup.includes(entityId)) {
      orderInParentEventGroup.push(entityId);
    }
    entityOrderByEventGroupId[parentId] = orderInParentEventGroup;

    // 2. Track which entities the various events belong to
    if (initiative.type === 'group') {
      eventGroupsById[entityId] = initiative;
    } else {
      const eventsByEntityId = eventsByEntityIdByEventGroupId[parentId]?.[entityId] ?? [];
      eventsByEntityId.push(initiative);

      eventsByEntityIdByEventGroupId[parentId] = eventsByEntityIdByEventGroupId[parentId] ?? {};
      eventsByEntityIdByEventGroupId[parentId][entityId] = eventsByEntityId;
    }
  });

  // Now that we know which events need to be collapsed into the same entity row,
  // we iterate through the established ordering and create the collapsed rows.
  const collapsedInitiatives: PlanTimelineRow[] = [];

  function collapseEvents(
    entityId: DriverId | BusinessObjectFieldId,
    events: PlanTimelineEvent[],
  ): PlanTimelineEntity {
    const baseEvent = events[0];
    const collapsedInitiative: PlanTimelineEntity = {
      ...baseEvent,
      // This needs to be unique
      id: `${baseEvent.parentId}:${entityId}`,
      type: baseEvent.type === 'driverEvent' ? 'driver' : 'objectField',
      entityId,
      events,
    };
    return collapsedInitiative;
  }

  function addGroupToCollapsedInitiatives(eventGroupId: EventGroupId) {
    const eventGroup = eventGroupsById[eventGroupId];

    // Add the group as the first row
    collapsedInitiatives.push(eventGroup);

    // Get the ordering of entities within the group
    const entityIdsOrdered = entityOrderByEventGroupId[eventGroupId];
    if (entityIdsOrdered == null) {
      // Should never happen
      return;
    }

    // Add each entity row, collapsing as necessary
    entityIdsOrdered.forEach((entityId) => {
      const maybeEventGroup = eventGroupsById[entityId];
      if (maybeEventGroup != null) {
        // If it's a group, recursively add it
        addGroupToCollapsedInitiatives(maybeEventGroup.eventGroup.id);
        return;
      }

      const events = eventsByEntityIdByEventGroupId[eventGroupId][entityId];
      if (events == null || events.length === 0) {
        // This can happen if it's an empty event group
        return;
      }

      // Collapse entity
      const collapsedInitiative = collapseEvents(entityId, events);
      collapsedInitiatives.push(collapsedInitiative);
    });
  }

  rootEventGroupOrder.forEach(addGroupToCollapsedInitiatives);

  return collapsedInitiatives;
}

export const planTimelineEventGroupsByIdSelector: ParametricSelector<
  BlockId,
  Record<EventGroupId, string>
> = createCachedSelector(
  planTimelineRowsRefSelector,
  (state: RootState) => populatedEventGroupsWithoutLiveEditsByIdForLayerSelector(state),
  businessObjectContextByEventGroupIdSelector,
  (timelineRowRefs, populatedEventGroupsById, businessObjectContextByEventGroupId) => {
    function eventGroupToFilter(eventGroup: PopulatedEventGroup) {
      const { id } = eventGroup;

      // If an event group just contains events for an object, check if it is
      // marked as the default event group for that object. If this is the
      // case, set the name to be dynamic based off of the object's name and
      // spec name.
      const objContextForEventGroup = businessObjectContextByEventGroupId[eventGroup.id];
      const name =
        objContextForEventGroup != null ? objContextForEventGroup.eventGroupName : eventGroup.name;

      return {
        id,
        name,
      };
    }

    const eventGroupNameById: Record<EventGroupId, string> = {};

    timelineRowRefs.forEach((rowRef) => {
      if (rowRef.type !== 'group') {
        return;
      }
      const eventGroup = populatedEventGroupsById[rowRef.id];
      if (eventGroup == null) {
        return;
      }

      const { id, name } = eventGroupToFilter(eventGroup);
      eventGroupNameById[id] = name;
    });

    return eventGroupNameById;
  },
)(blockIdSelector);

const NO_INITIATIVES: PlanTimelineItem[] = [];
export const selectedInitiativeSelector: ParametricSelector<BlockId, PlanTimelineItem | null> =
  createCachedSelector(
    (state: RootState, blockId: BlockId) => {
      const type = blockTypeSelector(state, blockId);
      return type != null && [BlockType.PlanTimeline, BlockType.ObjectTable].includes(type)
        ? planTimelineRowsSelector(state, blockId)
        : NO_INITIATIVES;
    },
    selectedPlanItemsSelector,
    (initiatives, selectedPlanItems) => {
      if (selectedPlanItems.length !== 1) {
        return null;
      }

      const selectedPlanItemRef = selectedPlanItems[0];

      // Look for the selected plan item in both the rows and the individual timeline items.
      for (const initiative of initiatives) {
        if (isPlanTimelineItemRefEqual(refFromPlanTimelineItem(initiative), selectedPlanItemRef)) {
          return initiative;
        }

        if (initiative.type === 'driver' || initiative.type === 'objectField') {
          for (const init of initiative.events) {
            if (isPlanTimelineItemRefEqual(refFromPlanTimelineItem(init), selectedPlanItemRef)) {
              return init;
            }
          }
        }
      }

      return null;
    },
  )(blockIdSelector);

export const noPlansInLayerSelector = createCachedSelector(
  (state: RootState) => rootInitiativesWithoutLiveEditsForLayerSelector(state),
  ({ rootEventGroups, rootEvents }) => {
    return rootEventGroups.length === 0 && rootEvents.length === 0;
  },
)(getCacheKeyForLayerSelector);

function getParentPath(
  parentId: EventGroupId | undefined,
  eventGroupsById: NullableRecord<EventGroupId, PopulatedEventGroup>,
): Array<EventGroupId | undefined> {
  const path: Array<EventGroupId | undefined> = [];
  let currParentId = parentId;

  while (currParentId != null) {
    const group = eventGroupsById[currParentId];
    if (group == null) {
      break;
    }
    path.push(currParentId);
    currParentId = group.parentId;
  }

  path.push(undefined);

  return path;
}

export function eventGroupPassesBlockFilters(
  eventGroup: PopulatedEventGroup,
  filters: TimelineFilterItem[],
  context: FilteringContext,
): boolean {
  function eventGroupPassesTimelineFilter(
    eg: PopulatedEventGroup,
    filter: TimelineFilterItem,
  ): boolean {
    switch (filter.filterKey) {
      case 'eventGroupId':
        return passesEventGroupFilter(
          eg,
          filter,
          context,
          // An event group passes the event group filter if either one of its ancestor
          // or children event groups passes the filter.
          'both',
        );
      default:
        return getAllNestedEvents(eg).some((ev) =>
          eventPassesTimelineBlockFilters(ev, filters, context),
        );
    }
  }

  return filters.every((f) => eventGroupPassesTimelineFilter(eventGroup, f));
}

// Exported for testing
export function eventPassesTimelineBlockFilters(
  event: Event,
  filters: TimelineFilterItem[],
  context: FilteringContext,
): boolean {
  function eventPassesTimelineFilter(ev: Event, filter: TimelineFilterItem): boolean {
    const parentEventGroup = ev.parentId != null ? context.eventGroupsById[ev.parentId] : null;

    switch (filter.filterKey) {
      case 'planStart':
        return passesPlanStartFilter(ev, filter, context.blockId, context.formulaEvaluator);
      case 'planEnd':
        return passesPlanEndFilter(ev, filter, context.blockId, context.formulaEvaluator);
      case 'impactsModelId':
        return passesModelFilter(ev, filter, context.submodelIdByDriverId);
      case 'impactsObjectSpecId':
        return passesDatabaseFilter(
          ev,
          filter,
          context.businessObjectFieldsById,
          context.businessObjectSpecsById,
          context.subDriversByDimDriverId,
        );
      case 'eventGroupId':
        return (
          parentEventGroup != null &&
          passesEventGroupFilter(
            parentEventGroup,
            filter,
            context,
            // An event passes the event group filter if any of its ancestor event groups pass the filter.
            'parents',
          )
        );
      default:
        return false;
    }
  }

  return filters.every((f) => eventPassesTimelineFilter(event, f));
}

function handleBooleanOperator(a: any, b: any, op: BlockFilterOperator): boolean {
  switch (op) {
    case BlockFilterOperator.Equals:
      return a === b;
    case BlockFilterOperator.NotEquals:
      return a !== b;
    case BlockFilterOperator.LessThan:
      return a < b;
    case BlockFilterOperator.LessThanOrEqualTo:
      return a <= b;
    case BlockFilterOperator.GreaterThan:
      return a > b;
    case BlockFilterOperator.GreaterThanOrEqualTo:
      return a >= b;
    default:
      return false;
  }
}

function passesPlanStartFilter(
  event: Event,
  filter: TimelineFilterItem,
  blockId: BlockId,
  evaluator: FormulaEvaluator,
): boolean {
  if (
    filter.valueType !== ValueType.Timestamp ||
    filter.filterKey !== 'planStart' ||
    filter.operator == null ||
    filter.expected == null
  ) {
    return false;
  }

  const timeRangeFormula = getTimeRangeInput(filter.expected);
  const range = evaluateTimeRange({ evaluator, blockId, timeRangeFormula });
  if (range == null) {
    return false;
  }

  return handleBooleanOperator(extractMonthKey(event.start), range.start, filter.operator);
}

function passesPlanEndFilter(
  event: Event,
  filter: TimelineFilterItem,
  blockId: BlockId,
  evaluator: FormulaEvaluator,
): boolean {
  if (
    filter.valueType !== ValueType.Timestamp ||
    filter.filterKey !== 'planEnd' ||
    filter.operator == null ||
    filter.expected == null
  ) {
    return false;
  }

  const timeRangeFormula = getTimeRangeInput(filter.expected);
  const range = evaluateTimeRange({ evaluator, blockId, timeRangeFormula });
  if (range == null) {
    return false;
  }

  return handleBooleanOperator(extractMonthKey(event.end), range.end, filter.operator);
}

// Though this function is recursive, we don't expect super deeply nested event groups, so
// perf shouldn't be an issue.
function passesEventGroupFilter(
  eventGroup: PopulatedEventGroup,
  filter: TimelineFilterItem,
  context: FilteringContext,
  // When checking whether an event group passes the filter, we must also consider whether any of
  // its parent or child event groups pass. However, we should not consider "sibling" event groups.
  // For example, if we have the following hierarchy:
  // EventGroupA
  //   EventGroupB
  //   EventGroupC
  // If we filter for EventGroupB, EventGroupA should also pass the filter.
  // However, EventGroupC, should NOT be included despite being a child of EventGroupA.
  // In essence, we want to only check the direct parents and children of the event group.
  checkDirection: 'both' | 'parents' | 'children',
): boolean {
  if (
    filter.valueType !== FilterValueTypes.ENTITY ||
    filter.filterKey !== 'eventGroupId' ||
    filter.operator == null ||
    filter.expected == null
  ) {
    return false;
  }

  const checkParents = checkDirection === 'both' || checkDirection === 'parents';
  const checkChildren = checkDirection === 'both' || checkDirection === 'children';

  let eventGroupPassesFilters = false;

  if (filter.operator === BlockFilterOperator.Equals) {
    eventGroupPassesFilters = filter.expected.includes(eventGroup.id);
  }

  if (filter.operator === BlockFilterOperator.NotEquals) {
    eventGroupPassesFilters = !filter.expected.includes(eventGroup.id);
  }

  if (eventGroupPassesFilters) {
    return true;
  }
  // If this event group doesn't pass the filter, check its parents
  if (checkParents) {
    const parentEventGroup =
      eventGroup.parentId != null ? context.eventGroupsById[eventGroup.parentId] : null;
    if (
      parentEventGroup != null &&
      passesEventGroupFilter(parentEventGroup, filter, context, 'parents')
    ) {
      return true;
    }
  }

  // If any of the children event group match filters
  if (checkChildren) {
    const nestedEventGroups = getAllNestedEventGroups(eventGroup);
    if (nestedEventGroups.some((eg) => passesEventGroupFilter(eg, filter, context, 'children'))) {
      return true;
    }
  }

  return false;
}

function passesModelFilter(
  event: Event,
  filter: TimelineFilterItem,
  submodelIdByDriverId: Record<DriverId, SubmodelId>,
): boolean {
  if (
    filter.valueType !== FilterValueTypes.ENTITY ||
    filter.filterKey !== 'impactsModelId' ||
    filter.operator == null
  ) {
    return false;
  }

  if (filter.operator === BlockFilterOperator.IsNull) {
    return !isDriverEvent(event);
  }

  if (filter.operator === BlockFilterOperator.IsNotNull) {
    return isDriverEvent(event);
  }

  if (!isDriverEvent(event) && filter.operator === BlockFilterOperator.NotEquals) {
    // Any non driver event matches a not equals filter on a model
    return true;
  }

  if (!isDriverEvent(event)) {
    return false;
  }

  const submodelId = submodelIdByDriverId[event.driverId];
  const result =
    filter.expected != null && submodelId != null && filter.expected.includes(submodelId);
  return filter.operator === BlockFilterOperator.NotEquals ? !result : result;
}

function passesDatabaseFilter(
  event: Event,
  filter: TimelineFilterItem,
  businessObjectFieldsById: Record<BusinessObjectFieldId, PopulatedBusinessObjectField>,
  businessObjectSpecsById: Record<string, BusinessObjectSpec>,
  subDriversByDimDriverId: Record<DriverId, DimensionalSubDriver[]>,
): boolean {
  if (
    filter.valueType !== FilterValueTypes.ENTITY ||
    filter.filterKey !== 'impactsObjectSpecId' ||
    filter.operator == null
  ) {
    return false;
  }

  if (filter.operator === BlockFilterOperator.IsNull) {
    return !isObjectFieldEvent(event);
  }

  if (filter.operator === BlockFilterOperator.IsNotNull) {
    return isObjectFieldEvent(event);
  }

  if (!isObjectFieldEvent(event) && filter.operator === BlockFilterOperator.NotEquals) {
    // Any non object event matches a not equals filter on an object spec
    return true;
  }

  if (!isObjectFieldEvent(event)) {
    if (filter.expected == null) {
      return false;
    }

    // Check if the event is a subdriver event associated with one of the databases
    // in the filter
    const expectedObjectSpecs = filter.expected
      .filter((id) => id in businessObjectSpecsById)
      .map((id) => businessObjectSpecsById[id]);

    return expectedObjectSpecs.some((objectSpec) =>
      driverEventMatchesObjectSpec(event, objectSpec, subDriversByDimDriverId),
    );
  }

  const field = businessObjectFieldsById[event.businessObjectFieldId];
  const result =
    filter.expected != null && field != null && filter.expected.includes(field.objectSpecId);

  return filter.operator === BlockFilterOperator.NotEquals ? !result : result;
}

function driverEventMatchesObjectSpec(
  event: DriverEvent,
  businessObjectSpec: BusinessObjectSpec,
  subDriversByDimDriverId: Record<DriverId, DimensionalSubDriver[]>,
): boolean {
  return (
    businessObjectSpec.collection?.driverProperties?.some(({ driverId }) =>
      subDriversByDimDriverId[driverId]?.some((subdriver) => subdriver.driverId === event.driverId),
    ) ?? false
  );
}

type ParentRowRefForEventProps = {
  blockId: BlockId;
  eventId: EventId;
};
export const parentRowRefForEventSelector: ParametricSelector<
  ParentRowRefForEventProps,
  PlanTimelineItemRef | null
> = createCachedSelector(
  (state: RootState, { blockId }: ParentRowRefForEventProps) =>
    planTimelineRowsSelector(state, blockId),
  fieldSelector('eventId'),
  (planTimelineRows, eventId) => {
    const row = findPlanTimelineRowWithEvent(planTimelineRows, eventId);

    if (row == null) {
      return null;
    }

    return refFromPlanTimelineItem(row);
  },
)((_state, { blockId, eventId }) => `${blockId}:${eventId}`);

export const flattenedSelectedEventIdsWithRowEventsSelector: ParametricSelector<
  BlockId,
  EventId[]
> = createCachedSelector(
  planTimelineRowsSelector,
  pageSelectionSelector,
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  openDetailPaneSelector,
  (timelineRows, selection, eventGroupsById, detailPane) => {
    return getFlattenedSelectedEventIds({
      selection,
      eventGroupsById,
      detailPane,
      timelineRows,
      includeEventsInSameRow: true,
    });
  },
)(blockIdSelector);

type SelectedEventIdsProps = {
  blockId: BlockId;
  includeEventsInSameRow: boolean;
};

export const selectedEventIdsMaybeWithRowEventsSelector: ParametricSelector<
  SelectedEventIdsProps,
  EventId[]
> = createCachedSelector(
  (state: RootState, { blockId }: SelectedEventIdsProps) =>
    flattenedSelectedEventIdsWithRowEventsSelector(state, blockId),
  flattenedSelectedEventIdsSelector,
  fieldSelector('includeEventsInSameRow'),
  function selectedEventIdsSelector(
    eventIdsWithRowEvents,
    eventIdsWithoutRowEvents,
    includeEventsInSameRow,
  ) {
    return includeEventsInSameRow ? eventIdsWithRowEvents : eventIdsWithoutRowEvents;
  },
)((_state, { blockId, includeEventsInSameRow }) => `${blockId}:${includeEventsInSameRow}`);
