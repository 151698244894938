import { Line } from '@visx/shape';
import React from 'react';

import { AreaChart } from 'components/DriverChart/AreaChart';
import useAppSelector from 'hooks/useAppSelector';
import useChartContext from 'hooks/useChartContext';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';

export const DriverAggregateLineChart: React.FC = () => {
  const { fullAggregateChartData, valueScale, timeScale } = useChartContext();
  const lastActualsMonthKey = useAppSelector(datasetLastActualsMonthKeySelector);
  const [minVal, maxVal] = valueScale.domain();
  const [minTime, maxTime] = timeScale.domain();

  const crossesZero = minVal < 0 && maxVal > 0;

  const { positiveValues, negativeValues } = fullAggregateChartData;

  const actualsIndex = positiveValues.findIndex((data) => {
    return data.monthKey === lastActualsMonthKey;
  });

  const positiveFormattedData = positiveValues.map((data) => {
    return {
      ...data.driverValues,
      date: data.date,
    };
  });

  const negativeFormattedData = negativeValues.map((data) => {
    return {
      ...data.driverValues,
      date: data.date,
    };
  });

  return (
    <>
      <AreaChart data={positiveFormattedData.slice(0, actualsIndex)} type="actuals" />
      <AreaChart data={positiveFormattedData.slice(actualsIndex - 1)} type="forecast" />
      <AreaChart data={negativeFormattedData.slice(0, actualsIndex)} type="actuals" />
      <AreaChart data={negativeFormattedData.slice(actualsIndex - 1)} type="forecast" />
      {crossesZero && (
        <Line
          className="zero-line"
          strokeWidth={1}
          stroke="gray"
          from={{ x: timeScale(minTime), y: valueScale(0) }}
          to={{ x: timeScale(maxTime), y: valueScale(0) }}
        />
      )}
    </>
  );
};
