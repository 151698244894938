import { createSlice } from '@reduxjs/toolkit';

import { uuidv4 } from 'helpers/uuidv4';
import {
  getMutationBatchDisplay,
  MutationBatch,
  MutationChangeId,
} from 'reduxStore/models/mutations';
import {
  addPendingMutation,
  applyMutationLocally_INTERNAL,
  setCurrentLayer,
  setDatasetIsStale,
  undoIncomingMutationForAlreadyUndoneChange,
  undoMutationLocally_INTERNAL,
} from 'reduxStore/reducers/datasetSlice';

export type UndoRedoToastMessage = string;
type ChangeStatus = 'NOT_UNDONE' | 'IS_UNDONE';
export type ActiveChangeIds = Record<MutationChangeId, ChangeStatus>;
type MutationChange = {
  id: MutationChangeId;
  mutationBatches: MutationBatch[];
};

/**
 * Undo/Redo state
 * Description: Group mutations undertaken in a single user action with a 'changeId'
 * This allows us to undo/redo all mutations in a single action.
 * 'activeChanges' is a map of changeIds to their undo status (undone or not undone).
 * This is used to determine if a change is already undone when receiving an change mutation from the server. This allows
 * us to add the mutation to the redo stack without applying it locally.
 */

export type UndoRedoState = {
  activeChanges: ActiveChangeIds;
  undoStack: MutationChange[];
  redoStack: MutationChange[];
  toastMessage: UndoRedoToastMessage;
};

export const initialState: UndoRedoState = {
  activeChanges: {} as ActiveChangeIds,
  undoStack: [],
  redoStack: [],
  toastMessage: '',
};

const getUndoRedoToastMessage = (mutationBatch: MutationBatch, isRedo: boolean): string => {
  const mutationDisplayMessage = getMutationBatchDisplay({ mutationBatch }).summary;
  if (mutationDisplayMessage === '') {
    return isRedo ? 'Redo' : 'Undo';
  }
  return `${isRedo ? 'Redo' : 'Undo'} - ${mutationDisplayMessage}`;
};

const undoRedoSlice = createSlice({
  name: 'undoRedo',
  initialState,
  reducers: {
    toastClosed: (state) => {
      state.toastMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentLayer, () => ({ ...initialState }));

    builder.addCase(setDatasetIsStale, () => ({ ...initialState }));
    builder.addCase(addPendingMutation, () => ({ ...initialState }));
    // This action is dispatched when a change mutation that has been undone has been received
    //from the server. We want to add it to the redo stack, and issue an undo mutation without
    // applying the mutation locally
    builder.addCase(undoIncomingMutationForAlreadyUndoneChange, (state, action) => {
      const { mutation, mutationId } = action.payload;

      const changeId = mutation?.changeId;

      if (changeId == null) {
        return;
      }

      const existingChangeRedoIndex = state.redoStack.findIndex((change) => change.id === changeId);
      const mutationBatch: MutationBatch = {
        id: mutationId,
        changeId,
        isUndone: true,
        mutation,
      };
      if (existingChangeRedoIndex !== -1) {
        state.redoStack[existingChangeRedoIndex].mutationBatches.push(mutationBatch);
      } else {
        state.redoStack.push({
          id: changeId,
          mutationBatches: [mutationBatch],
        });
      }
    });
    builder.addCase(undoMutationLocally_INTERNAL, (state, action) => {
      if (action.payload.isRemote) {
        return;
      }

      const undone = state.undoStack.pop();
      if (undone == null) {
        return;
      }

      const changeId = undone.id;
      const mutationToUndo = undone.mutationBatches.pop();
      if (undone.mutationBatches.length > 0) {
        state.undoStack.push(undone);
      }
      if (mutationToUndo == null) {
        return;
      }

      const existingChangeIndex = state.redoStack.findIndex((change) => change.id === changeId);
      if (existingChangeIndex !== -1) {
        state.redoStack[existingChangeIndex].mutationBatches.push(mutationToUndo);
      } else {
        state.redoStack.push({
          id: changeId,
          mutationBatches: [mutationToUndo],
        });
      }
      state.activeChanges[changeId] = 'IS_UNDONE';

      state.toastMessage = getUndoRedoToastMessage(mutationToUndo, false);
    });

    builder.addCase(applyMutationLocally_INTERNAL, (state, action) => {
      if (action.payload.isRemote) {
        return;
      }

      const { isRedo, mutationBatch } = action.payload;

      if (isRedo) {
        const redo = state.redoStack.pop();
        if (redo != null) {
          redo.mutationBatches.pop();
          if (redo.mutationBatches.length > 0) {
            state.redoStack.push(redo);
          }
        }
        state.toastMessage = getUndoRedoToastMessage(mutationBatch, isRedo);
      } else {
        state.redoStack = [];
      }
      const changeId = mutationBatch.mutation.changeId;

      if (changeId != null && state.activeChanges[changeId] != null) {
        const changeIndex = state.undoStack.findIndex((actionBatch) => actionBatch.id === changeId);
        if (changeIndex !== -1) {
          state.undoStack[changeIndex].mutationBatches.push(mutationBatch);
          return;
        }
      }

      const newChangeId = changeId != null ? changeId : uuidv4();

      state.activeChanges[newChangeId] = 'NOT_UNDONE';
      const mutationChange: MutationChange = {
        id: newChangeId,
        mutationBatches: [mutationBatch],
      };
      state.undoStack.push(mutationChange);
    });
  },
});

export const { toastClosed } = undoRedoSlice.actions;

export default undoRedoSlice.reducer;
