import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'RedshiftIcon',
  viewBox: '0 0 36 36',
  path: (
    <>
      <path d="M18 27.5253L31.44 30.7848V5.14258L18 8.40209V27.5253Z" fill="#205B97" />
      <path
        d="M31.44 5.14258L34 6.44638V29.481L31.44 30.7848V5.14258ZM18 27.5253L4.56 30.7848V5.14258L18 8.40209V27.5253Z"
        fill="#5193CE"
      />
      <path d="M4.56 5.14258L2 6.44638V29.481L4.56 30.7848V5.14258Z" fill="#205B97" />
      <path
        d="M21.414 36L26.534 33.3924V2.60761L21.414 0L19.8487 17.0948L21.414 36Z"
        fill="#5193CE"
      />
      <path
        d="M14.586 36L9.466 33.3924V2.60761L14.586 0L16.1513 17.0948L14.586 36Z"
        fill="#205B97"
      />
      <path d="M14.586 0H21.4833V35.9273H14.586V0Z" fill="#2E73B7" />
    </>
  ),
});
