import { SmallCloseIcon } from '@chakra-ui/icons';
import { Box, IconButton, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  label: string;
  onClear?: () => void;
}

const SelectedItemBadge: React.FC<Props> = ({ label, onClear }) => {
  return (
    <Box
      columnGap={2}
      bgColor="gray.200"
      px="0.375rem"
      color="gray.600"
      alignItems="center"
      justifyContent="center"
      borderRadius="md"
      whiteSpace="nowrap"
      lineHeight={6}
      display="inline-flex"
    >
      <Text fontSize="xs" fontWeight="medium">
        {label}
      </Text>
      {onClear != null && (
        <IconButton
          variant="text"
          onClick={onClear}
          icon={<SmallCloseIcon boxSize={3} />}
          aria-label="Clear attribute"
          _hover={{ color: 'gray.600' }}
        />
      )}
    </Box>
  );
};

export default SelectedItemBadge;
