import { deepEqual } from 'fast-equals';
import { Maybe } from 'graphql/jsutils/Maybe';
import { uniqBy, uniqWith } from 'lodash';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import partition from 'lodash/partition';

import { CellRef, CellType } from 'config/cells';
import { MAX_DECIMAL_PLACES } from 'config/decimals';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import {
  ColoringInput,
  CurveType,
  DatasetMutationInput,
  DimensionalSubDriverDeleteInput,
  DriverCreateInput,
  DriverFormat,
  DriverReferenceInput,
  DriverType,
  DriverUpdateInput,
  EventCreateInput,
  EventDeleteInput,
  EventUpdateInput,
  ImpactType,
  LeverType,
  ModifierType,
  RollupReducer,
  ValueType,
} from 'generated/graphql';
import { arraySameElements } from 'helpers/array';
import { getDateTimeFromMonthKey, getISOTimeWithoutMsFromMonthKey } from 'helpers/dates';
import { getSubDriverBySubDriverId, getSubdriverKey } from 'helpers/dimensionalDrivers';
import { attributeListToGqlAttrs, attributeListToGqlDims } from 'helpers/dimensions';
import { createMappedSubdriverHelper } from 'helpers/drivers';
import { extDriverDisplayName } from 'helpers/extDrivers';
import { THIS_MONTH_DATE_RANGE, createExtDriverRef } from 'helpers/formula';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import { convertNumericTimeSeriesToGql, convertTimeSeriesToGql } from 'helpers/gqlDataset';
import { mergeAllMutations, mergeMutations } from 'helpers/mergeMutations';
import { bulkInsertSortIndexUpdates } from 'helpers/reorderList';
import { isNotNull, nullSafeEqual, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { createNewEventsMutation } from 'reduxStore/actions/eventMutations';
import {
  getMutationThunkAction,
  submitAutoLayerizedMutations,
} from 'reduxStore/actions/submitDatasetMutation';
import { createSubmodelAndBlocksMutation } from 'reduxStore/actions/submodelMutations';
import { BlockId } from 'reduxStore/models/blocks';
import { Coloring } from 'reduxStore/models/common';
import { Attribute } from 'reduxStore/models/dimensions';
import { DriverGroupId, DriverGroupIdOrNull } from 'reduxStore/models/driverGroup';
import { BasicDriver, DimensionalSubDriver, Driver, DriverId } from 'reduxStore/models/drivers';
import { EventGroupId } from 'reduxStore/models/events';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import {
  NonNumericTimeSeriesWithEmpty,
  NumericTimeSeries,
  NumericTimeSeriesWithEmpty,
  ValueTimeSeriesWithEmpty,
} from 'reduxStore/models/timeSeries';
import { driverActualsUpdateMutations } from 'reduxStore/reducers/helpers/drivers';
import { setSelectedCells } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';
import { databaseObjectSpecSelector, databaseSpecIdSelector } from 'selectors/databasePageSelector';
import { orderedDriverGridBlockDriversSelector } from 'selectors/driverGridBlockSelector';
import {
  dimensionalDriversByIdSelector,
  dimensionalDriversBySubDriverIdSelector,
  driverColorSelector,
  driverNameDeduperSelector,
  driverSelector,
  driversByIdForCurrentLayerSelector,
  driversByIdForLayerSelector,
  subDriversByDriverIdSelector,
} from 'selectors/driversSelector';
import { eventsImpactingEventEntityAndMonthKeySelector } from 'selectors/eventsAndGroupsSelector';
import { extDriverSelector } from 'selectors/extDriversSelector';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { navSubmodelIdSelector } from 'selectors/navSubmodelSelector';
import {
  cellSelectionMonthKeysByDriverIdSelector,
  prevailingSelectedDriverIdsSelector,
} from 'selectors/prevailingCellSelectionSelector';
import {
  blockIdBySubmodelIdSelector,
  submodelIdByBlockIdSelector,
  submodelIdsBySubmodelNameSelector,
} from 'selectors/submodelPageSelector';
import {
  defaultSubmodelIdForExtDriver,
  sortedAccessibleSubmodelIdsSelector,
} from 'selectors/submodelSelector';
import { submodelGroupsSelector } from 'selectors/submodelTableGroupsSelector';
import { MonthKey } from 'types/datetime';

function inferDriverFormatFromName(name: string | undefined): DriverFormat | null {
  if (name == null) {
    return null;
  }

  if (name.includes('%')) {
    return DriverFormat.Percentage;
  } else if (name.includes('$')) {
    return DriverFormat.Currency;
  }

  return null;
}

const getDriverFormulaUpdate = (
  state: RootState,
  id: DriverId,
  type: 'actuals' | 'forecast',
  formula: string | null,
): string | null => {
  const driver = driversByIdForLayerSelector(state)[id];
  if (driver == null) {
    return null;
  }

  if (formula == null) {
    return null;
  }

  const currentFormula = (
    driver.type === DriverType.Basic
      ? driver[type].formula
      : (type === 'forecast' ? driver.defaultForecast : driver.defaultActuals)?.formula
  )?.trim();

  const newFormula = formula.trim();
  if (newFormula === currentFormula) {
    return null;
  }

  return newFormula;
};

export const getUpdateAllDriverFormulasMutation = (
  state: RootState,
  {
    id,
    actualsFormula,
    forecastFormula,
  }: {
    id: DriverId;
    actualsFormula: string | null;
    forecastFormula: string | null;
  },
) => {
  const driver = driversByIdForLayerSelector(state)[id];
  if (driver == null) {
    return null;
  }
  const updatedActualsFormula = getDriverFormulaUpdate(state, id, 'actuals', actualsFormula);
  const updatedForecastFormula = getDriverFormulaUpdate(state, id, 'forecast', forecastFormula);

  if (updatedActualsFormula == null && updatedForecastFormula == null) {
    return null;
  }

  if (driver.type === DriverType.Basic) {
    return {
      updateDrivers: [
        {
          id,
          ...(updatedActualsFormula != null ? { actuals: { formula: updatedActualsFormula } } : {}),
          ...(updatedForecastFormula != null
            ? { forecast: { formula: updatedForecastFormula } }
            : {}),
        },
      ],
    };
  }

  return {
    updateDrivers: [
      {
        id,
        dimensional: {
          ...(updatedActualsFormula != null
            ? { defaultActuals: { formula: updatedActualsFormula } }
            : {}),
          ...(updatedForecastFormula != null
            ? { defaultForecast: { formula: updatedForecastFormula } }
            : {}),
        },
      },
    ],
  };
};

export function generateNewDimDriverFromDriverAndAttributes({
  newDimDriverId,
  newDimDriverName,
  driverInput,
  attributes,
  mergingNonAttributeDriver,
  isExistingDriver,
}: {
  newDimDriverId?: DriverId;
  newDimDriverName?: string;
  attributes: Attribute[];
  mergingNonAttributeDriver?: BasicDriver;
} & (
  | { driverInput: DriverCreateInput; isExistingDriver: false }
  | {
      driverInput: Pick<
        DriverCreateInput,
        'name' | 'format' | 'leverType' | 'id' | 'driverReferences'
      >;
      isExistingDriver: true;
    }
)): DriverCreateInput {
  return {
    id: newDimDriverId ?? uuidv4(),
    name: newDimDriverName ?? driverInput.name,
    valueType: ValueType.Number,
    driverType: DriverType.Dimensional,
    format: driverInput.format,
    leverType: driverInput.leverType,
    dimensional: {
      ...attributeListToGqlDims(attributes),
      subDrivers: [
        // Order matters - add existing driver first before creating a new driver to prevent
        // the backend detecting a name conflict
        ...(mergingNonAttributeDriver != null
          ? [
              {
                existingDriverId: mergingNonAttributeDriver.id,
              },
            ]
          : []),
        {
          ...(isExistingDriver ? { existingDriverId: driverInput.id } : { driver: driverInput }),
          ...attributeListToGqlAttrs(attributes),
        },
      ],
    },
    driverReferences: driverInput.driverReferences,
  };
}

export function getExistingSubDrivers({
  targetDriver,
  subDriversByDriverId,
  dimensionalPropertyEvaluator,
  attributes = [],
}: {
  targetDriver: Driver;
  subDriversByDriverId: Record<DriverId, DimensionalSubDriver>;
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
  attributes?: Attribute[];
}): {
  existingTargetSubDriver: DimensionalSubDriver | null | undefined;
  existingTargetSubDriverId: DriverId | null | undefined;
} {
  const attributeIds = attributes.map((a) => a.id);
  const existingTargetSubDriverId = dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
    targetDriver.id,
    attributeIds,
  );
  if (existingTargetSubDriverId == null) {
    return { existingTargetSubDriver: null, existingTargetSubDriverId: null };
  }
  const existingTargetSubDriver = safeObjGet(subDriversByDriverId[existingTargetSubDriverId]);
  return {
    existingTargetSubDriver,
    existingTargetSubDriverId,
  };
}

export function checkForExistingSubDrivers({
  targetDriver,
  subDriversByDriverId,
  dimensionalPropertyEvaluator,
  driverId,
  driverName,
  attributes = [],
}: {
  targetDriver: Driver;
  subDriversByDriverId: Record<DriverId, DimensionalSubDriver>;
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
  driverId?: DriverId | null | undefined;
  driverName?: string | null | undefined;
  attributes?: Attribute[];
}): boolean {
  if (driverName !== targetDriver.name) {
    return false;
  }

  if (
    targetDriver.type !== DriverType.Dimensional &&
    targetDriver.id !== driverId &&
    attributes.length === 0
  ) {
    return true;
  }

  const { existingTargetSubDriver } = getExistingSubDrivers({
    targetDriver,
    subDriversByDriverId,
    dimensionalPropertyEvaluator,
    attributes,
  });

  return (
    existingTargetSubDriver != null &&
    (driverId == null || existingTargetSubDriver.driverId !== driverId)
  );
}

export function defaultDriverFormat(name: string | undefined) {
  return inferDriverFormatFromName(name) ?? DriverFormat.Auto;
}

function addOrUpdateColor(
  originalColors: Coloring,
  newColor: string | null,
  monthKeys?: MonthKey[],
) {
  const newColoring: ColoringInput = {};

  if (monthKeys != null) {
    const newCells: ValueTimeSeriesWithEmpty = {
      ...originalColors.cells,
    };

    if (newColor != null) {
      monthKeys.forEach((monthKey) => {
        newCells[monthKey] = {
          type: ValueType.Timestamp,
          value: newColor,
        };
      });
    } else {
      monthKeys.forEach((monthKey) => {
        newCells[monthKey] = undefined;
      });
    }

    newColoring.cells = convertTimeSeriesToGql(newCells);

    if (originalColors.row != null) {
      newColoring.row = originalColors.row;
    }
  } else {
    newColoring.row = newColor;

    if (originalColors.cells != null) {
      newColoring.cells = convertTimeSeriesToGql(originalColors.cells);
    }
  }

  return newColoring;
}

interface DriverCreateContext {
  belowDriverId?: DriverId;
  groupId?: DriverGroupId;
  submodelName?: string;
  skipAddingToSubmodel?: boolean;
  blockId: BlockId;
  // This is a basic driver that the user has explicitly chosen as the new parent for the subdrivers
  mergingNonAttributeDriver?: BasicDriver;
  skipSortIndexUpdates?: boolean;
}

function resolveSubmodelId(
  state: RootState,
  submodelName: string | undefined,
): SubmodelId | undefined {
  const navSubmodelId = navSubmodelIdSelector(state);
  if (navSubmodelId != null) {
    return navSubmodelId;
  }

  // If a submodel name is provided, try to fill the submodelId from the name
  // If it doesn't exist, a new submodel will be created
  if (submodelName != null) {
    const submodelIdsBySubmodelName = submodelIdsBySubmodelNameSelector(state);
    return submodelIdsBySubmodelName[submodelName]?.[0];
  }

  const submodelIds = sortedAccessibleSubmodelIdsSelector(state);
  return submodelIds[0];
}

export const getUpdateDriverReferenceMutation = ({
  driver,
  sortIndex,
  groupId,
  blockId,
}: {
  driver: { id: DriverId; driverReferences?: Maybe<DriverReferenceInput[]> };
  sortIndex?: number;
  groupId?: DriverGroupIdOrNull;
  blockId: BlockId;
}) => {
  const [[blockRef], otherRefs] = partition(
    driver?.driverReferences ?? [],
    (ref) => ref.blockId === blockId,
  );
  return {
    id: driver.id,
    driverReferences: [
      ...otherRefs,
      {
        ...(blockRef ?? { blockId }),
        ...(sortIndex == null ? {} : { sortIndex }),
        // null is a valid value for groupId
        ...(groupId === undefined ? {} : { groupId }),
      },
    ],
    ...(sortIndex == null ? {} : { sortIndex }),
    // null is a valid value for groupId
    ...(groupId === undefined ? {} : { groupId }),
  };
};

export const getSortIndexUpdateMutations = ({
  state,
  blockId,
  sortIndexUpdates,
  driverCreateInputs,
}: {
  state: RootState;
  blockId: BlockId;
  sortIndexUpdates: NullableRecord<DriverId, number>;
  driverCreateInputs?: DriverCreateInput[];
}): DriverUpdateInput[] => {
  const driversById = driversByIdForCurrentLayerSelector(state);

  return Object.entries(sortIndexUpdates)
    .map(([id, sortIndex]) => {
      const driver = driversById[id] ?? driverCreateInputs?.find((d) => d.id === id);
      if (driver == null) {
        return null;
      }
      return getUpdateDriverReferenceMutation({ driver, sortIndex, blockId });
    })
    .filter(isNotNull);
};

export const getNewDriversMutations = ({
  state,
  context,
  newDrivers,
}: {
  state: RootState;
  context: DriverCreateContext;
  newDrivers: Array<{
    id: DriverId;
    name?: string;
    dimDriver?: {
      driverId?: DriverId;
      attributes: Attribute[];
    };
    actuals?: {
      timeSeries?: ValueTimeSeriesWithEmpty;
      formula?: string;
    };
    forecastFormula?: string;
  }>;
}): {
  currentLayerMutation: {
    newDrivers: NonNullable<DatasetMutationInput['newDrivers']>;
    updateDrivers: NonNullable<DatasetMutationInput['updateDrivers']>;
    newSubmodels: NonNullable<DatasetMutationInput['newSubmodels']>;
  };
  defaultLayerMutation: {
    newBlocksPages: NonNullable<DatasetMutationInput['newBlocksPages']>;
    newBlocks: NonNullable<DatasetMutationInput['newBlocks']>;
    updateBlocksPages: NonNullable<DatasetMutationInput['updateBlocksPages']>;
    setDriverFields: NonNullable<DatasetMutationInput['setDriverFields']>;
  };
} => {
  const {
    belowDriverId,
    blockId,
    submodelName,
    mergingNonAttributeDriver,
    skipAddingToSubmodel = false,
  } = context;
  const driversById = driversByIdForCurrentLayerSelector(state);
  const blockIdBySubmodelId = blockIdBySubmodelIdSelector(state);
  const submodelIdByBlockId = submodelIdByBlockIdSelector(state);
  const blockSubmodelId = submodelIdByBlockId[blockId];

  const belowDriver = belowDriverId != null ? driversById[belowDriverId] : null;
  const groupId =
    context.groupId ??
    belowDriver?.driverReferences?.find((ref) => ref.blockId === blockId)?.groupId;
  const driverReferences: DriverReferenceInput[] = [{ blockId, groupId }];

  let submodelId = skipAddingToSubmodel ? undefined : resolveSubmodelId(state, submodelName);
  let submodelBlockId = submodelId == null ? undefined : blockIdBySubmodelId[submodelId];
  const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);
  const subDriversByDriverId = subDriversByDriverIdSelector(state);

  const defaultLayerMutation: {
    newBlocksPages: NonNullable<DatasetMutationInput['newBlocksPages']>;
    newBlocks: NonNullable<DatasetMutationInput['newBlocks']>;
    updateBlocksPages: NonNullable<DatasetMutationInput['updateBlocksPages']>;
    setDriverFields: NonNullable<DatasetMutationInput['setDriverFields']>;
  } = {
    setDriverFields: [],
    newBlocksPages: [],
    newBlocks: [],
    updateBlocksPages: [],
  };

  const currentLayerMutation: {
    newDrivers: NonNullable<DatasetMutationInput['newDrivers']>;
    updateDrivers: NonNullable<DatasetMutationInput['updateDrivers']>;
    newSubmodels: NonNullable<DatasetMutationInput['newSubmodels']>;
  } = {
    newDrivers: [],
    updateDrivers: [],
    newSubmodels: [],
  };

  if (submodelId == null && !skipAddingToSubmodel) {
    submodelId = uuidv4();
    submodelBlockId = uuidv4();
    // the driver won't go under any group in the new submodel
    driverReferences.push({ blockId: submodelBlockId });

    const mutations = createSubmodelAndBlocksMutation(state, {
      name: submodelName ?? 'New model',
      id: submodelId,
      pageId: uuidv4(),
      blockId: submodelBlockId,
    });
    currentLayerMutation.newSubmodels.push(...(mutations.currentLayerMutation.newSubmodels ?? []));
    defaultLayerMutation.newBlocksPages.push(...mutations.defaultLayerMutation.newBlocksPages);
    defaultLayerMutation.newBlocks.push(...mutations.defaultLayerMutation.newBlocks);
    defaultLayerMutation.updateBlocksPages.push(
      ...mutations.defaultLayerMutation.updateBlocksPages,
    );
  }

  const businessSpecId = databaseSpecIdSelector(state);
  const businessObjectSpec = businessSpecId != null ? databaseObjectSpecSelector(state) : null;

  const dimDriversById = dimensionalDriversByIdSelector(state);
  const nameDeduper = driverNameDeduperSelector(state);

  newDrivers.forEach(({ id, name, dimDriver, actuals, forecastFormula }) => {
    if (
      mergingNonAttributeDriver != null &&
      checkForExistingSubDrivers({
        targetDriver: mergingNonAttributeDriver,
        subDriversByDriverId,
        dimensionalPropertyEvaluator,
        driverId: id,
        driverName: name,
        attributes: dimDriver?.attributes,
      })
    ) {
      return;
    }

    const parentDimDriver = dimDriver?.driverId != null ? dimDriversById[dimDriver.driverId] : null;

    const mappedDriver =
      parentDimDriver?.driverMapping?.driverId != null
        ? dimDriversById[parentDimDriver.driverMapping.driverId]
        : null;
    if (name == null || name.length === 0) {
      name = parentDimDriver?.name ?? nameDeduper('New Driver');
    }

    const actualsTs =
      actuals?.timeSeries != null ? convertTimeSeriesToGql(actuals.timeSeries) : undefined;

    const actualsInput = {
      ...(actualsTs != null ? { timeSeries: actualsTs } : {}),
      ...(actuals?.formula != null ? { formula: actuals.formula } : {}),
    };

    const newDriverInput: DriverCreateInput = {
      id,
      name: name.trim(),
      format: parentDimDriver?.format ?? defaultDriverFormat(name),
      valueType: parentDimDriver?.valueType ?? ValueType.Number,
      driverType: DriverType.Basic,
      basic: {
        actuals: actualsInput,
        forecast: {
          formula: forecastFormula ?? '',
        },
      },
      submodelId,
      groupId,
      driverReferences,
    };

    // Adding to exisiting dimensional driver
    if (parentDimDriver != null) {
      // N.B. parentDimDriver != null implies dimDriver != null, but this makes TS happy
      const addedAttrs = dimDriver?.attributes ?? [];

      if (mappedDriver) {
        const mappedSubdriverInfo = createMappedSubdriverHelper({
          mappedDriver,
          businessObjectSpecProperties: businessObjectSpec?.collection?.dimensionalProperties ?? [],
          attributes: addedAttrs.map((attr) => ({ id: attr.id, dimensionId: attr.dimensionId })), // the business obj attributes
        });
        newDriverInput.name = parentDimDriver.name;
        if (newDriverInput.basic != null) {
          if (mappedSubdriverInfo.defaultFormula != null) {
            newDriverInput.basic.forecast.formula = mappedSubdriverInfo.defaultFormula;
            newDriverInput.basic.actuals.formula = mappedSubdriverInfo.defaultFormula;
          }
        }
      }

      const updateDimDriver = {
        id: parentDimDriver.id,
        newSubDrivers: [
          {
            driver: newDriverInput,
            ...attributeListToGqlAttrs(addedAttrs),
          },
          ...(mergingNonAttributeDriver != null
            ? [
                {
                  existingDriverId: mergingNonAttributeDriver.id,
                  ...attributeListToGqlAttrs([]),
                },
              ]
            : []),
        ],
      };

      currentLayerMutation.updateDrivers.push(updateDimDriver);
    } else if (dimDriver != null && dimDriver.attributes.length > 0) {
      // New driver has dimensions so create a dimensional driver

      const newDimDriver = generateNewDimDriverFromDriverAndAttributes({
        driverInput: newDriverInput,
        attributes: dimDriver.attributes,
        mergingNonAttributeDriver,
        isExistingDriver: false,
      });
      currentLayerMutation.newDrivers.push(newDimDriver);
    } else {
      currentLayerMutation.newDrivers.push(newDriverInput);
    }
  });

  if (!context.skipSortIndexUpdates) {
    let orderedDriverIds: DriverId[];
    if (blockSubmodelId != null) {
      const group = submodelGroupsSelector(state, { submodelId: blockSubmodelId }).find((g) =>
        nullSafeEqual(g.id, groupId),
      );
      orderedDriverIds = group == null ? [] : group.driverIds;
    } else {
      orderedDriverIds = orderedDriverGridBlockDriversSelector(state, blockId);
    }

    const orderedDriverSortIndices = orderedDriverIds
      .map((dId) => {
        const driver = driversById[dId];
        if (driver == null) {
          return null;
        }
        const sortIndex = driver.driverReferences?.find(
          (ref) => ref.blockId === blockId,
        )?.sortIndex;
        return { id: dId, sortIndex };
      })
      .filter(isNotNull);

    const sortIndexUpdates = bulkInsertSortIndexUpdates(
      orderedDriverSortIndices,
      newDrivers,
      'after',
      belowDriverId,
    );

    currentLayerMutation.updateDrivers.push(
      ...getSortIndexUpdateMutations({
        state,
        blockId,
        sortIndexUpdates,
        driverCreateInputs: currentLayerMutation.newDrivers,
      }),
    );
  }

  return {
    currentLayerMutation,
    defaultLayerMutation,
  };
};

export const createNewDriversInContext =
  ({
    context,
    newDrivers,
    select,
  }: {
    context: DriverCreateContext;
    newDrivers: Array<{
      id: DriverId;
      name?: string;
      dimDriver?: {
        driverId?: DriverId;
        attributes: Attribute[];
      };
      actuals?: {
        timeSeries?: ValueTimeSeriesWithEmpty;
        formula?: string;
      };
      forecastFormula?: string;
    }>;
    select: boolean;
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    const mutations = getNewDriversMutations({ state, context, newDrivers });

    const { belowDriverId, blockId } = context;
    const driversById = driversByIdForCurrentLayerSelector(state);
    const belowDriver = belowDriverId != null ? driversById[belowDriverId] : null;
    const groupId =
      context.groupId ??
      belowDriver?.driverReferences?.find((ref) => ref.blockId === blockId)?.groupId;

    dispatch(
      submitAutoLayerizedMutations('create-drivers-in-context', [
        mutations.defaultLayerMutation,
        mutations.currentLayerMutation,
      ]),
    );

    if (select) {
      const pastedDriverCellRefs: CellRef[] = newDrivers.map((newDriver) => {
        return {
          type: CellType.Driver,
          rowKey: {
            driverId: newDriver.id,
            layerId: undefined,
            groupId: groupId ?? undefined,
          },
          columnKey: { columnType: NAME_COLUMN_TYPE, columnLayerId: undefined },
        };
      });
      dispatch(
        setSelectedCells({
          type: 'cell',
          blockId,
          activeCell: pastedDriverCellRefs[0],
          selectedCells: pastedDriverCellRefs,
        }),
      );
    }
  };

export const generateUpdateDriverNameAndAttributesMutation = (
  {
    attributes,
    driverId,
    driverName,
    targetDriver,
  }: {
    attributes: Attribute[];
    driverId: DriverId;
    driverName: string;
    // targetDriver should be set only if the user has explicitly chosen a new
    // parent driver for the subdriver
    targetDriver?: Driver;
  },
  getState: () => RootState,
): { newDrivers: DriverCreateInput[]; updateDrivers: DriverUpdateInput[] } | null => {
  driverName = driverName.trim();

  const state = getState();
  const driver = driversByIdForLayerSelector(state)[driverId];

  // Something went wrong - bail
  if (driver == null) {
    return null;
  }

  const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);
  const subDriversByDriverId = subDriversByDriverIdSelector(state);

  const renaming = driverName !== driver.name;

  const attributeIds = attributes.map((a) => a.id);
  const dimensionalDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(state);
  const currentDimDriver = safeObjGet(dimensionalDriversBySubDriverId[driver.id]);
  const subDriver =
    currentDimDriver != null ? getSubDriverBySubDriverId(currentDimDriver, driverId) : null;
  const oldAttrIds = subDriver != null ? subDriver.attributes.map((a) => a.id) : [];
  const changingAttrs = !arraySameElements(oldAttrIds, attributeIds);

  const targetDimDriver =
    targetDriver?.type === DriverType.Basic
      ? safeObjGet(dimensionalDriversBySubDriverId[targetDriver.id])
      : targetDriver;

  const changingDimDrivers =
    targetDriver != null && !nullSafeEqual(currentDimDriver?.id, targetDimDriver?.id);

  if (!renaming && !changingAttrs && !changingDimDrivers) {
    return null;
  }

  if (
    targetDriver != null &&
    checkForExistingSubDrivers({
      targetDriver: targetDimDriver ?? targetDriver,
      subDriversByDriverId,
      dimensionalPropertyEvaluator,
      driverId,
      driverName,
      attributes,
    })
  ) {
    // N.B. We just drop creating dupes. the UX for editing a single
    // driver prevents these, and in the case of dupes I think dropping
    // them feels ok for now since this is a pretty edge rare edge case.
    return null;
  }

  const mutation: {
    newDrivers: NonNullable<DatasetMutationInput['newDrivers']>;
    updateDrivers: NonNullable<DatasetMutationInput['updateDrivers']>;
  } = {
    newDrivers: [],
    updateDrivers: [],
  };

  if (targetDriver != null) {
    // We are moving to an existing dim driver
    if (targetDimDriver != null) {
      const newGqlAttrs = attributeListToGqlAttrs(attributes);
      mutation.updateDrivers.push({
        id: targetDimDriver.id,
        newSubDrivers: [
          {
            ...newGqlAttrs,
            // this also updates the driver's name in the backend
            // and unlinks from the previous parent, if any
            existingDriverId: driverId,
          },
        ],
      });
      return mutation;
    }

    // We are moving to a non-dim driver, we need to create a new dim driver with both basic drivers

    // this also updates the driver's name in the backend
    // and unlinks from the previous parent, if any
    const newDimDriver = generateNewDimDriverFromDriverAndAttributes({
      driverInput: driver,
      newDimDriverName: driverName,
      attributes,
      mergingNonAttributeDriver: targetDriver?.type === DriverType.Basic ? targetDriver : undefined,
      isExistingDriver: true,
    });

    mutation.newDrivers.push(newDimDriver);
    return mutation;
  }

  if (attributes.length === 0) {
    // We're not renaming and didn't have any attributes before - noop
    if (!renaming && currentDimDriver == null) {
      return null;
    }

    if (renaming) {
      mutation.updateDrivers.push({ id: driverId, name: driverName });
    }

    if (currentDimDriver != null) {
      mutation.updateDrivers.push({
        id: currentDimDriver.id,
        dimensional: {
          updateSubDrivers: [
            {
              ...attributeListToGqlAttrs(subDriver?.attributes ?? []),
              // We used to have a parent
              // if we're renaming, we should unlink the driver from it
              // otherwise we need to explicitly tell the backend to remove all attributes
              ...(renaming ? { unlink: true } : { removeAllAttributes: true }),
            },
          ],
        },
      });
    }

    return mutation;
  }

  // We have some attributes
  if (renaming) {
    // create new dim driver

    // this also updates the driver's name in the backend
    // and unlinks from the previous parent, if any
    const newDimDriver = generateNewDimDriverFromDriverAndAttributes({
      driverInput: driver,
      newDimDriverName: driverName,
      attributes,
      isExistingDriver: true,
    });

    mutation.newDrivers.push(newDimDriver);
    return mutation;
  }

  // it used to be a non-attribute driver
  if (currentDimDriver == null) {
    // Adding attributes - create new dim driver
    const newDimDriver = generateNewDimDriverFromDriverAndAttributes({
      driverInput: driver,
      newDimDriverName: driverName,
      attributes,
      isExistingDriver: true,
    });

    mutation.newDrivers.push(newDimDriver);
    return mutation;
  }

  if (changingAttrs) {
    const newGqlAttrs = attributeListToGqlAttrs(attributes);
    mutation.updateDrivers.push({
      id: currentDimDriver.id,
      dimensional: {
        updateSubDrivers: [
          {
            ...attributeListToGqlAttrs(subDriver?.attributes ?? []),
            ...(attributes.length === 0
              ? { removeAllAttributes: true }
              : {
                  newAttributeIds: newGqlAttrs.attributeIds,
                  newBuiltInAttributes: newGqlAttrs.builtInAttributes,
                }),
          },
        ],
      },
    });
  }

  if (mutation.newDrivers.length === 0 && mutation.updateDrivers.length === 0) {
    return null;
  }
  return mutation;
};

export const removeDriverMapping =
  ({ driverId }: { driverId: string }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const driver = driversByIdForCurrentLayerSelector(state)[driverId];
    if (driver == null) {
      return;
    }
    const isValidDimDriverWithMapping =
      driver.type === DriverType.Dimensional && driver?.driverMapping?.driverId != null;
    if (!isValidDimDriverWithMapping) {
      return;
    }
    dispatch(
      submitAutoLayerizedMutations('remove-driver-mapping', [
        {
          updateDrivers: [
            {
              id: driverId,
              removeDriverMapping: true,
            },
          ],
        },
      ]),
    );
  };

export const updateDriverFormats =
  ({ format, driverIds }: { format: DriverFormat; driverIds: DriverId[] }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const driversById = driversByIdForCurrentLayerSelector(state);
    const selectedDrivers = driverIds
      .map((id) => driversById[id])
      .filter(isNotNull)
      .filter((d) => d.format !== format);

    if (selectedDrivers.length === 0) {
      return;
    }

    dispatch(
      submitAutoLayerizedMutations('update-driver-formats', [
        {
          updateDrivers: selectedDrivers.map(({ id }) => ({ id, format })),
        },
      ]),
    );
  };

export const updateSelectedDriverFormats =
  ({ format }: { format: DriverFormat }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selectedDriverIds = prevailingSelectedDriverIdsSelector(state);

    dispatch(
      updateDriverFormats({
        format,
        driverIds: selectedDriverIds,
      }),
    );
  };

export const updateDriverValueTypes =
  ({ valueType, driverIds }: { valueType: ValueType; driverIds: DriverId[] }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const driversById = driversByIdForCurrentLayerSelector(state);
    const selectedDrivers = driverIds
      .map((id) => driversById[id])
      .filter(isNotNull)
      .filter((d) => d.valueType !== valueType);

    if (selectedDrivers.length === 0) {
      return;
    }

    dispatch(
      submitAutoLayerizedMutations('update-driver-value-types', [
        {
          updateDrivers: selectedDrivers.map(({ id }) => ({ id, valueType })),
        },
      ]),
    );
  };

export const updateDriverCurrencies =
  ({ currency, driverIds }: { currency: string; driverIds: DriverId[] }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const driversById = driversByIdForCurrentLayerSelector(state);
    const selectedDrivers = driverIds
      .map((id) => driversById[id])
      .filter(isNotNull)
      .filter((d) => d.currencyISOCode !== currency);

    if (selectedDrivers.length === 0) {
      return;
    }

    dispatch(
      submitAutoLayerizedMutations('update-driver-currencies', [
        {
          updateDrivers: selectedDrivers.map(({ id }) => ({ id, currencyISOCode: currency })),
        },
      ]),
    );
  };

export const updateSelectedDriverCurrencies =
  ({ currency }: { currency: string }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selectedDriverIds = prevailingSelectedDriverIdsSelector(state);

    dispatch(
      updateDriverCurrencies({
        currency,
        driverIds: selectedDriverIds,
      }),
    );
  };

export const updateDriverDecimalPlaces =
  ({
    decimalPlaces,
    driverIds,
  }: {
    decimalPlaces: number | null;
    driverIds: DriverId[];
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const driversById = driversByIdForCurrentLayerSelector(state);
    const selectedDrivers = driverIds
      .map((id) => driversById[id])
      .filter(isNotNull)
      .filter((d) => d.decimalPlaces !== decimalPlaces);

    if (selectedDrivers.length === 0) {
      return;
    }

    dispatch(
      submitAutoLayerizedMutations('update-driver-decimal-places', [
        {
          updateDrivers: selectedDrivers.map(({ id }) => ({
            id,
            decimalPlaces: decimalPlaces ?? -1,
          })),
        },
      ]),
    );
  };

export const updateSelectedDriverDecimalPlaces =
  ({ decimalPlaces }: { decimalPlaces: number | null }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selectedDriverIds = prevailingSelectedDriverIdsSelector(state);

    dispatch(
      updateDriverDecimalPlaces({
        decimalPlaces,
        driverIds: selectedDriverIds,
      }),
    );
  };

export const getUpdateDriverFormulaMutation = (
  state: RootState,
  id: DriverId,
  type: 'actuals' | 'forecast',
  formula: string | null,
): Partial<DatasetMutationInput> | null => {
  const driver = driversByIdForLayerSelector(state)[id];
  if (driver == null) {
    return null;
  }
  const newFormula = getDriverFormulaUpdate(state, id, type, formula);
  if (newFormula == null) {
    return null;
  }

  if (driver.type === DriverType.Basic) {
    return {
      updateDrivers: [
        {
          id,
          [type]: { formula: newFormula },
        },
      ],
    };
  }

  return {
    updateDrivers: [
      {
        id,
        dimensional: {
          ...(type === 'forecast'
            ? { defaultForecast: { formula: newFormula } }
            : { defaultActuals: { formula: newFormula } }),
        },
      },
    ],
  };
};

const mutationActions = {
  createDriverFromExtDriver: getMutationThunkAction<{
    name: string;
    id: DriverId;
    extDriverId: ExtDriverId;
  }>(({ name, id, extDriverId }, getState) => {
    const state = getState();
    const extDriver = extDriverSelector(state, extDriverId);
    if (extDriver == null) {
      return null;
    }

    const mutation: DatasetMutationInput & {
      newDrivers: NonNullable<DatasetMutationInput['newDrivers']>;
      newSubmodels: NonNullable<DatasetMutationInput['newSubmodels']>;
      newBlocksPages: NonNullable<DatasetMutationInput['newBlocksPages']>;
      newBlocks: NonNullable<DatasetMutationInput['newBlocks']>;
      updateBlocksPages: NonNullable<DatasetMutationInput['updateBlocksPages']>;
    } = {
      newDrivers: [],
      newSubmodels: [],
      newBlocksPages: [],
      newBlocks: [],
      updateBlocksPages: [],
    };

    const { source } = extDriver;
    let submodelId = defaultSubmodelIdForExtDriver(state, extDriver.id);
    const blockIdBySubmodelId = blockIdBySubmodelIdSelector(state);
    let submodelBlockId = submodelId == null ? undefined : blockIdBySubmodelId[submodelId];

    const driverReferences: DriverReferenceInput[] = [];

    if (submodelId == null) {
      submodelId = uuidv4();
      submodelBlockId = uuidv4();

      driverReferences.push({ blockId: submodelBlockId });

      const mutations = createSubmodelAndBlocksMutation(state, {
        name: 'New model',
        id: submodelId,
        pageId: uuidv4(),
        blockId: submodelBlockId,
      });
      mutation.newSubmodels.push(...(mutations.currentLayerMutation.newSubmodels ?? []));
      mutation.newBlocksPages.push(...mutations.defaultLayerMutation.newBlocksPages);
      mutation.newBlocks.push(...mutations.defaultLayerMutation.newBlocks);
      mutation.updateBlocksPages.push(...mutations.defaultLayerMutation.updateBlocksPages);
    } else if (submodelBlockId != null) {
      driverReferences.push({ blockId: submodelBlockId });
    }

    mutation.newDrivers.push({
      id,
      name,
      valueType: ValueType.Number,
      format: DriverFormat.Currency,
      driverType: DriverType.Basic,
      submodelId,
      basic: {
        actuals: {
          formula: createExtDriverRef({
            id: extDriverId,
            source,
            label: extDriverDisplayName(extDriver),
            dateRange: THIS_MONTH_DATE_RANGE.dateRange,
          }),
        },
        forecast: {
          formula: '',
        },
      },
      driverReferences,
    });
    return mutation;
  }),
  updateDriverNameAndAttributes: getMutationThunkAction(
    generateUpdateDriverNameAndAttributesMutation,
  ),
  deleteDrivers: getMutationThunkAction<DriverId[]>((ids, getState) => {
    const state = getState();
    const dimDriversBySubDrivers = dimensionalDriversBySubDriverIdSelector(state);

    const [basicDrivers, subDrivers] = partition(ids, (id) => dimDriversBySubDrivers[id] == null);
    const withDimDriver = subDrivers.map((id) => [id, dimDriversBySubDrivers[id]] as const);

    const deletedAttributesByDriverId = mapValues(
      groupBy(withDimDriver, ([_id, dimDriver]) => dimDriver.id),
      (pairs) => {
        const dimDriver = pairs[0][1];

        return pairs
          .map(([subDriverId]) => getSubDriverBySubDriverId(dimDriver, subDriverId)?.attributes)
          .filter(isNotNull);
      },
    );

    const mutation = {
      deleteDrivers: basicDrivers.map((id) => ({ id })),
      updateDrivers: Object.entries(deletedAttributesByDriverId).map(
        ([dimDriverId, attrsLists]) => {
          const deleteSubDrivers: DimensionalSubDriverDeleteInput[] = [];

          // The backend expects the built-in and user created attributes to
          // be split up like this.
          attrsLists.forEach((attrList) => {
            deleteSubDrivers.push(attributeListToGqlAttrs(attrList));
          });

          // Only attempt to delete unique pairs of subdriver attributes.
          // Mutation handling will take care of deleting all matching drivers.
          const uniqueSubDrivers = uniqBy(
            deleteSubDrivers,
            ({ attributeIds }: DimensionalSubDriverDeleteInput) =>
              attributeIds != null ? getSubdriverKey(attributeIds) : '',
          );

          return {
            id: dimDriverId,
            dimensional: {
              deleteSubDrivers: uniqueSubDrivers,
            },
          };
        },
      ),
    };

    return mutation;
  }),
  updateAllDriverFormulas: getMutationThunkAction<{
    id: DriverId;
    actualsFormula: string | null;
    forecastFormula: string;
  }>((params, getState) => getUpdateAllDriverFormulasMutation(getState(), params)),
  updateDriverFormula: getMutationThunkAction<{
    id: DriverId;
    type: 'actuals' | 'forecast';
    formula: string | null;
  }>(({ id, type, formula }, getState) => {
    const state = getState();
    return getUpdateDriverFormulaMutation(state, id, type, formula);
  }),
  updateDriversFormulas: getMutationThunkAction<{
    updates: Array<{ driverId: DriverId; type: 'actuals' | 'forecast'; formula: string }>;
  }>(({ updates }) => {
    return {
      updateDrivers: updates.map(({ driverId, type, formula }) => {
        return {
          id: driverId,
          [type]: { formula },
        };
      }),
    };
  }),
  clearForecastFormulas: getMutationThunkAction<{ driverIds: DriverId[] }>(({ driverIds }) => {
    return {
      updateDrivers: driverIds.map((id) => ({ id, forecast: { formula: '' } })),
    };
  }),
  clearActualsFormulas: getMutationThunkAction<{ driverIds: DriverId[] }>(({ driverIds }) => {
    return {
      updateDrivers: driverIds.map((id) => ({ id, actuals: { formula: '' } })),
    };
  }),
  incrementSelectedDriverDecimalPlaces: getMutationThunkAction((_, getState) => {
    const state = getState();
    const selectedDriverIds = prevailingSelectedDriverIdsSelector(state);
    const driversById = driversByIdForLayerSelector(state);
    const selectedDrivers = selectedDriverIds.map((id) => driversById[id]).filter(isNotNull);
    const decimalPlacesByDriverId: Record<DriverId, number | null> = {};

    if (selectedDrivers.length !== selectedDriverIds.length) {
      return null;
    }

    selectedDrivers.forEach((d) => {
      if (d.decimalPlaces == null) {
        decimalPlacesByDriverId[d.id] = 1;
      } else if (d.decimalPlaces < MAX_DECIMAL_PLACES) {
        decimalPlacesByDriverId[d.id] = d.decimalPlaces + 1;
      }
    });

    if (Object.keys(decimalPlacesByDriverId).length === 0) {
      return null;
    }

    return {
      updateDrivers: Object.entries(decimalPlacesByDriverId).map(([id, value]) => ({
        id,
        decimalPlaces: value,
      })),
    };
  }),
  incrementDecrementSelectedDriverDecimalPlaces: getMutationThunkAction<{ decrement: boolean }>(
    ({ decrement }, getState) => {
      const state = getState();
      const selectedDriverIds = prevailingSelectedDriverIdsSelector(state);
      const driversById = driversByIdForLayerSelector(state);
      const selectedDrivers = selectedDriverIds.map((id) => driversById[id]).filter(isNotNull);
      const decimalPlacesByDriverId: Record<DriverId, number | null> = {};

      if (selectedDrivers.length !== selectedDriverIds.length) {
        return null;
      }

      selectedDrivers.forEach((d) => {
        if (d.decimalPlaces == null) {
          if (!decrement) {
            decimalPlacesByDriverId[d.id] = 1;
          }
        } else {
          decimalPlacesByDriverId[d.id] = decrement ? d.decimalPlaces - 1 : d.decimalPlaces + 1;
        }
      });

      if (Object.keys(decimalPlacesByDriverId).length === 0) {
        return null;
      }

      return {
        updateDrivers: Object.entries(decimalPlacesByDriverId).map(([id, value]) => ({
          id,
          decimalPlaces: value,
        })),
      };
    },
  ),
  updateSelectedDriverColor: getMutationThunkAction<{ color: string; monthKey?: MonthKey }>(
    ({ color, monthKey }, getState) => {
      const state = getState();
      const selectedDriverIds = prevailingSelectedDriverIdsSelector(state);
      const colors = selectedDriverIds.reduce<Record<string, Coloring>>((acc, driverId) => {
        const driverColors = driverColorSelector(state, { id: driverId });

        if (driverColors) {
          acc[driverId] = driverColors;
        }
        return acc;
      }, {});

      const newBgColor = color === 'white' ? '' : color;

      const monthKeys = monthKey != null ? [monthKey] : undefined;

      return {
        updateDrivers: selectedDriverIds.map((id) => {
          return { id, coloring: addOrUpdateColor(colors[id], newBgColor, monthKeys) };
        }),
      };
    },
  ),
  updateCellSelectionDriverColors: getMutationThunkAction<{
    color: string;
  }>(({ color }, getState) => {
    const state = getState();

    const selectedDriverIds = prevailingSelectedDriverIdsSelector(state);
    const selectedMonthKeysByDriverId = cellSelectionMonthKeysByDriverIdSelector(state);

    const colors = selectedDriverIds.reduce<Record<string, Coloring>>((acc, driverId) => {
      const driverColors = driverColorSelector(state, { id: driverId });

      if (driverColors) {
        acc[driverId] = driverColors;
      }
      return acc;
    }, {});

    const newBgColor = color === 'white' ? '' : color;

    const updateDrivers = Object.entries(selectedMonthKeysByDriverId).map(
      ([driverId, monthKeys]) => {
        return {
          id: driverId,
          coloring: addOrUpdateColor(colors[driverId], newBgColor, monthKeys),
        };
      },
    );

    return {
      updateDrivers,
    };
  }),
  updateDriverFormat: getMutationThunkAction<{ id: DriverId; format: DriverFormat }>(
    ({ id, format }, getState) => {
      const state = getState();
      const currentFormat = driverSelector(state, { id })?.format;
      if (format === currentFormat) {
        return null;
      }

      return {
        updateDrivers: [{ id, format }],
      };
    },
  ),
  updateDriverRollupReducer: getMutationThunkAction<{ id: DriverId; rollupReducer: RollupReducer }>(
    ({ id, rollupReducer }, getState) => {
      const state = getState();
      const driver = driversByIdForLayerSelector(state)[id];
      if (
        driver == null ||
        driver.type !== DriverType.Basic ||
        driver.rollup?.reducer === rollupReducer
      ) {
        return null;
      }

      return {
        updateDrivers: [{ id, rollup: { reducer: rollupReducer } }],
      };
    },
  ),
  updateSelectedDriversLeverType: getMutationThunkAction<LeverType>((leverType, getState) => {
    const state = getState();
    const ids = prevailingSelectedDriverIdsSelector(state);
    return getUpdateDriverLeverTypeMutation(leverType, ids, state);
  }),
  updateLeverType: getMutationThunkAction<{ leverType: LeverType; id: DriverId }>(
    ({ leverType, id }, getState) => {
      const state = getState();
      return getUpdateDriverLeverTypeMutation(leverType, [id], state);
    },
  ),
  updateDriverDescription: getMutationThunkAction<{ id: DriverId; description: string }>(
    ({ id, description }, getState) => {
      const state = getState();
      const driver = driverSelector(state, { id });
      const trimmed = description.trim();
      if (driver == null || driver.description === trimmed) {
        return null;
      }

      return {
        updateDrivers: [{ id, description: trimmed }],
      };
    },
  ),
  updateDriverActuals: getMutationThunkAction<
    Array<{
      id: DriverId;
      values: NumericTimeSeriesWithEmpty | NonNumericTimeSeriesWithEmpty;
      newFormat: DriverFormat | undefined;
    }>
  >((inputs, getState) => {
    const state = getState();
    return driverActualsUpdateMutations(state, inputs);
  }),
};

const getUpdateDriverLeverTypeMutation = (
  leverType: LeverType,
  ids: DriverId[],
  state: RootState,
) => {
  const updateDrivers = [];
  for (const id of ids) {
    const driver = driversByIdForLayerSelector(state)[id];
    if (driver == null) {
      continue;
    }
    if ((driver.leverType ?? LeverType.Unknown) === leverType) {
      continue;
    }
    updateDrivers.push({ id, leverType });
  }
  return updateDrivers.length === 0 ? null : { updateDrivers };
};

export function getDuplicateCreateInputFromExistingDriver({
  name,
  existingDriver,
  newDriverId,
  blockId,
  formulaTransform,
}: {
  name: string;
  existingDriver: Driver;
  newDriverId: DriverId;
  blockId: BlockId;
  formulaTransform?: (formula: string, isForecast: boolean) => string;
}): DriverCreateInput {
  if (formulaTransform == null) {
    formulaTransform = (formula: string, _isForecast: boolean) =>
      formula.replaceAll(id, newDriverId);
  }

  const { id, leverType, valueType, format, currencyISOCode, type, coloring, driverReferences } =
    existingDriver;

  // We shouldn't add the new driver to any block other than this specific block
  const existingDriverReference = driverReferences?.find((ref) => ref.blockId === blockId);
  const duplicateDriverReferences = [existingDriverReference ?? { blockId }];
  const createInput: DriverCreateInput = {
    id: newDriverId,
    name,
    format,
    currencyISOCode,
    leverType,
    valueType,
    driverType: type,
    driverReferences: duplicateDriverReferences,
  };

  if (coloring != null) {
    createInput.coloring = {
      cells: coloring.cells ? convertTimeSeriesToGql(coloring.cells) : undefined,
      row: coloring.row,
    };
  }

  if (type === DriverType.Basic) {
    const { actuals, forecast } = existingDriver;
    createInput.basic = {
      actuals: {
        formula: actuals.formula != null ? formulaTransform(actuals.formula, false) : undefined,
        // TODO (@ahmed): handle non-numeric time series
        timeSeries: convertNumericTimeSeriesToGql(actuals.timeSeries as NumericTimeSeries),
      },
      forecast: {
        formula: forecast.formula != null ? formulaTransform(forecast.formula, true) : '',
      },
    };
  } else if (type === DriverType.Dimensional) {
    const { defaultForecast, dimensions } = existingDriver;
    createInput.dimensional = {
      defaultForecast,
      dimensionIds: dimensions.map((d) => d.id),
    };
  }

  return createInput;
}

export const driverForecastsUpdateMutations = (
  state: RootState,
  inputs: Array<{
    id: DriverId;
    values: NumericTimeSeriesWithEmpty;
    newFormat: DriverFormat | undefined;
  }>,
  eventGroupId?: EventGroupId,
): Partial<DatasetMutationInput> | null => {
  const lastActualsMonthKey = datasetLastActualsMonthKeySelector(state);
  const driversById = driversByIdForLayerSelector(state);

  const authenticatedUser = authenticatedUserSelector(state);
  if (authenticatedUser == null) {
    return null;
  }

  const eventCreatesAndUpdates = inputs
    .map(({ id, values }) => {
      const originalDriver = driversById[id];
      if (originalDriver == null) {
        return null;
      }

      const monthKeys = Object.keys(values).filter((mk) => mk > lastActualsMonthKey);

      const newEvents: EventCreateInput[] = [];
      const updateEvents: EventUpdateInput[] = [];
      const deleteEvents: EventDeleteInput[] = [];

      monthKeys.toSorted().forEach((mk) => {
        const value = values[mk];
        if (value == null) {
          // Should never happen
          return;
        }

        const time = getISOTimeWithoutMsFromMonthKey(mk);

        const existingEvents = eventsImpactingEventEntityAndMonthKeySelector(state, {
          type: 'driver',
          driverId: id,
          monthKey: mk,
        });

        if (existingEvents.length === 1) {
          updateEvents.push({
            id: existingEvents[0].id,
            parentId: eventGroupId,
            // Even if existing event was a DELTA, we convert it to a SET on paste
            impactType: ImpactType.Set,
            time,
            value: `${value}`,
          });
        } else {
          deleteEvents.push(...existingEvents.map((e) => ({ id: e.id })));

          newEvents.push({
            id: uuidv4(),
            driverId: id,
            start: time,
            end: getDateTimeFromMonthKey(mk).endOf('month').startOf('second').toISO(),
            modifierType: ModifierType.Add,
            impactType: ImpactType.Set,
            curveType: CurveType.Custom,
            ownerName: authenticatedUser?.name ?? '',
            setValueType: originalDriver.valueType,
            time,
            value: `${value}`,
          });
        }
      });

      const newEventsMutation = createNewEventsMutation({
        state,
        newEvents,
        withEventGroup:
          eventGroupId != null
            ? {
                type: 'existing',
                eventGroupId,
              }
            : {
                type: 'default',
              },
      });

      return mergeMutations(newEventsMutation ?? {}, {
        updateEvents,
        deleteEvents: deleteEvents.length > 0 ? deleteEvents : undefined,
      });
    })
    .filter(isNotNull);

  const mutation = mergeAllMutations(eventCreatesAndUpdates);

  // mutations.newEventGroups could contain multiple of the same mutation
  // (e.g. if creating the default event group for the first time). We have
  // mutation handlers that throw errors on duplicates, so we need to dedupe here.
  mutation.newEventGroups = uniqWith(mutation.newEventGroups, deepEqual);

  return mutation;
};

export const {
  clearForecastFormulas,
  clearActualsFormulas,
  createDriverFromExtDriver,
  deleteDrivers,
  updateDriverNameAndAttributes,
  updateDriverFormula,
  updateAllDriverFormulas,
  updateDriverActuals,
  updateDriverDescription,
  updateDriverFormat,
  updateDriverRollupReducer,
  updateDriversFormulas,
  updateLeverType,
  updateSelectedDriverColor,
  updateCellSelectionDriverColors,
  incrementDecrementSelectedDriverDecimalPlaces,
  updateSelectedDriversLeverType,
} = mutationActions;
