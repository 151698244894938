import {
  AgHierarchyChartOptions,
  AgTreemapSeriesOptions,
  AgTreemapSeriesTooltipRendererParams,
} from 'ag-charts-community';
import React, { useMemo } from 'react';

import AgChart from 'components/AgGridComponents/AgChart/AgChart';
import { ChartDisplay, ChartGroupingType, ChartSize } from 'generated/graphql';
import { safeObjGet } from 'helpers/typescript';
import useBlockContext from 'hooks/useBlockContext';
import { DriverId } from 'reduxStore/models/drivers';

import { renderTooltip } from './agChartTooltips';
import { CHART_TOOLTIP_CLASSNAME } from './agCharts';
import { useChartDriverAggregatedData, useChartDriverConfig } from './chartDriverHooks';

interface AgTreemapChartProps {
  driverIds: DriverId[];
  chartDisplay: ChartDisplay;
  size?: ChartSize;
  groupingType?: ChartGroupingType;
  stacked?: boolean;
  chartIndex?: number;
}

const AgTreemapChart: React.FC<AgTreemapChartProps> = ({ chartIndex, driverIds, chartDisplay }) => {
  const { blockId } = useBlockContext();
  const {
    chartConfig,
    dateRange,
    driverDisplayConfigurationsById,
    height,
    width,
    driverNamesById,
    attributesBySubDriverId,
  } = useChartDriverConfig(blockId, driverIds, chartDisplay);

  const filteredDriverIds = useMemo(() => {
    if (chartIndex != null) {
      return [driverIds[chartIndex]];
    }
    return driverIds;
  }, [driverIds, chartIndex]);

  const { isAnyDriverLoading, data: ungroupedData } = useChartDriverAggregatedData(
    filteredDriverIds,
    dateRange,
    chartDisplay,
  );

  const data = useMemo(() => {
    if (!ungroupedData) {
      return [];
    }

    return chartDisplay.groups.map((group, index) => {
      const seriesForGroup = chartDisplay.series.filter((series) => {
        return group.seriesIds.includes(series.id);
      });

      const groupedData = ungroupedData.filter((driver) => {
        return seriesForGroup.some((series) => series.driverId === driver.id);
      });

      return {
        title: group.name ?? `Group ${index + 1}`,
        children: groupedData.map(({ id, value }) => {
          let title = `${driverNamesById[id]}`;
          const attributes = attributesBySubDriverId[id];

          if (attributes != null && attributes.length > 0) {
            for (const attr of attributes) {
              title += ` (${attr.value})`;
            }
          }

          return {
            id,
            title,
            total: value,
          };
        }),
      };
    });
  }, [chartDisplay, ungroupedData, attributesBySubDriverId, driverNamesById]);

  const series = useMemo((): AgTreemapSeriesOptions[] => {
    if (!chartConfig.chartDisplay) {
      return [];
    }

    return [
      {
        type: 'treemap',
        labelKey: 'title',
        sizeKey: 'total',
        sizeName: 'Total',
        highlightStyle: {
          series: {
            enabled: true,
            dimOpacity: 0.5,
          },
        },
        tooltip: {
          enabled: true,
          showArrow: false,

          renderer: ({
            datum,
          }: AgTreemapSeriesTooltipRendererParams<
            { title: string; children: unknown[] } | { id: string; title: string; total: number }
          >) => {
            if ('children' in datum) {
              return datum.title;
            }

            const id = datum.id;
            const key = driverNamesById[id];
            const value = datum.total;
            const displayConfiguration = driverDisplayConfigurationsById[datum.id];
            const attributes = safeObjGet(attributesBySubDriverId[datum.id]);
            return renderTooltip(key, { id, key, value }, displayConfiguration, attributes);
          },
        },
      },
    ];
  }, [
    chartConfig.chartDisplay,
    attributesBySubDriverId,
    driverDisplayConfigurationsById,
    driverNamesById,
  ]);

  const options = useMemo((): AgHierarchyChartOptions => {
    if (driverDisplayConfigurationsById == null) {
      return {
        width,
        height,
      };
    }

    return {
      data: isAnyDriverLoading ? [] : data,
      series,
      tooltip: {
        class: CHART_TOOLTIP_CLASSNAME,
        position: {
          type: 'pointer',
        },
      },
      legend: {
        enabled: driverIds.length > 1,
      },
      width,
      height,
    };
  }, [
    data,
    driverDisplayConfigurationsById,
    driverIds.length,
    isAnyDriverLoading,
    series,
    width,
    height,
  ]);

  return <AgChart isLoading={isAnyDriverLoading} options={options} />;
};

export default React.memo(AgTreemapChart);
