// eslint-disable-next-line you-dont-need-lodash-underscore/clone-deep
import { cloneDeep, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

import {
  BLOCK_VIEW_AS_TO_DEFAULT_CHART_SERIES_TYPE,
  CHART_TYPE_SUPPORTS_SCENARIO_COMPARISON,
  SERIES_COLORS,
  SeriesColor,
} from 'components/AgGridComponents/AgChart/agCharts';
import { getDateRangeFromRollupPeriod } from 'components/CustomizeDriverChartsBlock/CustomizeDriverChartsBlock';
import {
  BlockViewAsType,
  BlockViewOptions,
  BlockViewOptionsInput,
  ChartAxisInput,
  ChartAxisType,
  ChartDisplay,
  ChartDisplayInput,
  ChartElementPosition,
  ChartGroup,
  ChartGroupInput,
  ChartGroupingType,
  ChartPlotByType,
  ChartRollupType,
  ChartSeries,
  ChartSeriesInput,
  ChartSeriesLabel,
  ChartSeriesType,
  ChartSize,
  DateRangeComparisonType,
  DriverAxisExtensionInput,
} from 'generated/graphql';
import { getCurrentMonthKey, getDateTimeFromMonthKey } from 'helpers/dates';
import { safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { updateBlockConfig, updateBlockViewOptions } from 'reduxStore/actions/blockMutations';
import { DriverId } from 'reduxStore/models/drivers';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import {
  blockConfigSelector,
  blockConfigViewOptionsSelector,
  blockDriverIdsSelector,
} from 'selectors/blocksSelector';
import { driverNamesByIdSelector } from 'selectors/driversSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { layerNameByIdSelector } from 'selectors/layerSelector';

import { CHART_CAN_SWITCH_SINGLE_MULTI_DRIVER, ensureChartDisplay } from './types';

const makeSimpleChartDisplay = ({
  driverIds,
  seriesType,
}: {
  driverIds: DriverId[];
  seriesType: ChartSeriesType;
}): ChartDisplayInput => {
  const series = driverIds.map(
    (driverId, index): ChartSeriesInput => ({
      id: uuidv4(),
      driverId,
      type: seriesType,
      color: SERIES_COLORS[index % SERIES_COLORS.length].value,
    }),
  );

  const groupId = uuidv4();
  const groups: ChartGroupInput[] = [
    {
      id: groupId,
      seriesIds: series.map((s) => s.id),
      isDefault: true,
      isPositive: true,
    },
  ];

  const xAxis: ChartAxisInput = {
    id: uuidv4(),
    type: seriesType === ChartSeriesType.Waterfall ? ChartAxisType.Category : ChartAxisType.Time,
    position: ChartElementPosition.Bottom,
    time: {
      rollup: ChartRollupType.Monthly,
      plotBy: ChartPlotByType.Time,
    },
  };
  const yAxis: ChartAxisInput = {
    id: uuidv4(),
    type: ChartAxisType.Driver,
    position: ChartElementPosition.Left,
    driver: {
      groupIds: [groupId],
    },
  };

  return {
    series,
    groups,
    axes: [xAxis, yAxis],
  };
};

export const useChangeChartType = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const blockConfig = useAppSelector((state) => blockConfigSelector(state, blockId));
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const driverIds = useAppSelector((state) => blockDriverIdsSelector(state, blockId));

  const comparisonLayerIds = blockConfig?.comparisons?.layerIds;

  return useCallback(
    (type: BlockViewAsType) => {
      if (viewOptions.viewAsType === type) {
        return;
      }

      let chartSize: ChartSize | undefined | null = viewOptions.chartSize;
      let chartGroupingType: ChartGroupingType | undefined | null = viewOptions.chartGroupingType;
      let aggregateValues: boolean | undefined | null = viewOptions.aggregateValues;

      if (type === BlockViewAsType.ComboChart) {
        chartSize = ChartSize.ExtraLarge;
      } else if (type === BlockViewAsType.WaterfallChart) {
        chartSize = ChartSize.Medium;
      }
      if (type === BlockViewAsType.LineChart) {
        aggregateValues = false;
      }
      if (!CHART_CAN_SWITCH_SINGLE_MULTI_DRIVER[type]) {
        if ([BlockViewAsType.ComboChart, BlockViewAsType.WaterfallChart].includes(type)) {
          chartGroupingType = ChartGroupingType.Multi;
        }
        if (type === BlockViewAsType.CurrentValue) {
          chartGroupingType = ChartGroupingType.Single;
        }
      }

      let updatedChartDisplay: ChartDisplayInput = {
        ...ensureChartDisplay(viewOptions.chartDisplay),
      };
      const seriesType = BLOCK_VIEW_AS_TO_DEFAULT_CHART_SERIES_TYPE[type];

      if (seriesType != null) {
        const disableComparisons =
          !isEmpty(comparisonLayerIds) && !CHART_TYPE_SUPPORTS_SCENARIO_COMPARISON[type];

        // Only replace the chart definition if there is an enabled scenario comparison
        // and the target chart type does not support comparisons.
        if (disableComparisons) {
          updatedChartDisplay = { ...makeSimpleChartDisplay({ driverIds, seriesType }) };
        } else {
          // Change series types.
          const series = updatedChartDisplay.series.map((s) => ({ ...s, type: seriesType }));
          updatedChartDisplay.series = series;
        }

        dispatch(
          updateBlockViewOptions({
            blockId,
            blockViewOptions: {
              ...viewOptions,
              chartDisplay: updatedChartDisplay,
              viewAsType: type,
              chartSize,
              chartGroupingType,
              aggregateValues,
            },
          }),
        );
        if (disableComparisons) {
          dispatch(
            updateBlockConfig({
              blockId,
              fn: (cfg) => {
                if (cfg.comparisons != null) {
                  cfg.comparisons.layerIds = [];
                }
              },
            }),
          );
        }
      }
    },
    [blockId, comparisonLayerIds, dispatch, driverIds, viewOptions],
  );
};

export const useChangeChartSize = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (size: ChartSize) => {
      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions: {
            ...viewOptions,
            chartSize: size,
            chartDisplay: ensureChartDisplay(viewOptions.chartDisplay),
          },
        }),
      );
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useChangeChartGrouping = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (single: boolean) => {
      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions: {
            ...viewOptions,
            chartGroupingType: single ? ChartGroupingType.Single : ChartGroupingType.Multi,
            chartDisplay: ensureChartDisplay(viewOptions.chartDisplay),
          },
        }),
      );
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useChangeChartAggregateValues = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (aggregateValues: boolean) => {
      const chartDisplay: ChartDisplayInput = {
        ...ensureChartDisplay(viewOptions.chartDisplay),
      };
      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        aggregateValues,
        chartDisplay,
      };

      chartDisplay.axes = chartDisplay.axes.map(
        (axis): ChartAxisInput =>
          axis.driver != null ? { ...axis, driver: { ...axis.driver, isNormalized: false } } : axis,
      );

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useChangeNormalizeAxis = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (isNormalized: boolean) => {
      const chartDisplay: ChartDisplayInput = {
        ...ensureChartDisplay(viewOptions.chartDisplay),
      };
      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        aggregateValues: isNormalized,
        chartDisplay,
      };

      chartDisplay.axes = chartDisplay.axes.map(
        (axis): ChartAxisInput =>
          axis.driver != null ? { ...axis, driver: { ...axis.driver, isNormalized } } : axis,
      );

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useChangeShowLabels = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (showLabels: boolean) => {
      const chartDisplay: ChartDisplayInput = {
        ...ensureChartDisplay(viewOptions.chartDisplay),
      };
      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        chartDisplay,
      };

      chartDisplay.series = chartDisplay.series.map(
        (series): ChartSeriesInput => ({ ...series, showLabels }),
      );

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useChangeSeriesLabel = <T extends keyof ChartSeriesLabel>(prop: T) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (value: ChartSeriesLabel[T]) => {
      const chartDisplay: ChartDisplayInput = {
        ...ensureChartDisplay(viewOptions.chartDisplay),
      };
      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        chartDisplay,
      };

      chartDisplay.series = chartDisplay.series.map(
        (series): ChartSeriesInput => ({ ...series, label: { ...series.label, [prop]: value } }),
      );

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, dispatch, prop, viewOptions],
  );
};

export const useChangeResetSeriesLabels = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(() => {
    const chartDisplay: ChartDisplayInput = {
      ...ensureChartDisplay(viewOptions.chartDisplay),
    };
    const blockViewOptions: BlockViewOptionsInput = {
      ...viewOptions,
      chartDisplay,
    };

    chartDisplay.series = chartDisplay.series.map(
      (series): ChartSeriesInput => ({ ...series, label: {} }),
    );

    dispatch(
      updateBlockViewOptions({
        blockId,
        blockViewOptions,
      }),
    );
  }, [blockId, dispatch, viewOptions]);
};

const CHART_POSITION_SWAP: Record<ChartElementPosition, ChartElementPosition> = {
  [ChartElementPosition.Bottom]: ChartElementPosition.Top,
  [ChartElementPosition.Left]: ChartElementPosition.Right,
  [ChartElementPosition.Right]: ChartElementPosition.Left,
  [ChartElementPosition.Top]: ChartElementPosition.Bottom,
};

export const useChangeAxisPosition = (id: string) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (position: ChartElementPosition) => {
      const chartDisplay: ChartDisplayInput = { ...viewOptions.chartDisplay! };

      const existingAxis = chartDisplay.axes.find((a) => a.position === position);
      if (existingAxis) {
        const replacement = { ...existingAxis, position: CHART_POSITION_SWAP[position] };
        chartDisplay.axes = chartDisplay.axes.map((a) =>
          a.id === id ? { ...a, position } : a.id === existingAxis.id ? replacement : a,
        );
      } else {
        chartDisplay.axes = chartDisplay.axes.map((a) => (a.id === id ? { ...a, position } : a));
      }

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions: {
            ...viewOptions,
            chartDisplay,
          },
        }),
      );
    },
    [blockId, dispatch, id, viewOptions],
  );
};

export const useChangeAxisShowLabel = (id: string) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (showLabel: boolean) => {
      const chartDisplay: ChartDisplayInput = { ...viewOptions.chartDisplay! };

      chartDisplay.axes = chartDisplay.axes.map((a) => (a.id === id ? { ...a, showLabel } : a));

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions: {
            ...viewOptions,
            chartDisplay,
          },
        }),
      );
    },
    [blockId, dispatch, id, viewOptions],
  );
};

export const useChangeAxisName = (id: string) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (name: string) => {
      const chartDisplay: ChartDisplayInput = { ...viewOptions.chartDisplay! };

      chartDisplay.axes = chartDisplay.axes.map((a) => (a.id === id ? { ...a, name } : a));

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions: {
            ...viewOptions,
            chartDisplay,
          },
        }),
      );
    },
    [blockId, dispatch, id, viewOptions],
  );
};

export const useChangeGroup = (id: string) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (chartGroupInput: ChartGroupInput) => {
      const chartDisplay: ChartDisplayInput = { ...viewOptions.chartDisplay! };

      chartDisplay.groups = chartDisplay.groups.map((g) =>
        g.id === id ? { ...g, ...chartGroupInput } : g,
      );

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions: {
            ...viewOptions,
            chartDisplay,
          },
        }),
      );
    },
    [blockId, dispatch, id, viewOptions],
  );
};

export const useChangeDriverAxisValue = <
  FieldName extends keyof DriverAxisExtensionInput,
  Value extends DriverAxisExtensionInput[FieldName],
>(
  field: FieldName,
  id: string,
) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (value: Value) => {
      if (!viewOptions.chartDisplay) {
        return;
      }

      const chartDisplay: ChartDisplayInput = { ...viewOptions.chartDisplay };

      chartDisplay.axes = chartDisplay.axes.map((a) =>
        a.id === id && a.driver != null ? { ...a, driver: { ...a.driver, [field]: value } } : a,
      );

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions: {
            ...viewOptions,
            chartDisplay,
          },
        }),
      );
    },
    [blockId, dispatch, field, id, viewOptions],
  );
};

export const useAddAxis = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const driverNamesById = useAppSelector(driverNamesByIdSelector);

  return useCallback(
    (seriesId: string) => {
      const chartDisplay: ChartDisplayInput = { ...viewOptions.chartDisplay! };
      const { axes } = chartDisplay;

      const groupId = uuidv4();
      const axisId = uuidv4();
      const position =
        axes?.find((axis) => axis.position === ChartElementPosition.Right) == null
          ? ChartElementPosition.Right
          : ChartElementPosition.Left;

      const series = chartDisplay.series.find((s) => s.id === seriesId);
      if (!series) {
        return;
      }

      const axis: ChartAxisInput = {
        id: axisId,
        type: ChartAxisType.Driver,
        // Set default name to be less confusing.
        name: driverNamesById[
          // Backwards compatible.
          series.driverId ?? series.id
        ],
        showLabel: true,
        position,
        driver: {
          groupIds: [groupId],
        },
      };

      const existingGroup = chartDisplay.groups.find((g) => g.seriesIds.includes(seriesId));
      if (existingGroup) {
        const updatedGroup: ChartGroupInput = {
          ...existingGroup,
          seriesIds: existingGroup.seriesIds.filter(
            (id) =>
              // Backwards compatible.
              id !== seriesId && id !== series.driverId,
          ),
        };
        chartDisplay.groups = chartDisplay.groups.map((g) =>
          g.id === existingGroup.id ? updatedGroup : g,
        );

        // Push new group.
        chartDisplay.groups.push({
          id: groupId,
          // Set default name to be less confusing.
          name: driverNamesById[series.driverId],
          seriesIds: [seriesId],
          isDefault: false,
        });
      }

      // Push new axis.
      chartDisplay.axes = [...chartDisplay.axes, axis];

      const blockViewOptions: BlockViewOptions = {
        ...viewOptions,
        chartDisplay,
      };

      dispatch(updateBlockViewOptions({ blockId, blockViewOptions }));
    },
    [blockId, dispatch, driverNamesById, viewOptions],
  );
};

export const useRemoveAxis = (id: string) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(() => {
    const chartDisplay: ChartDisplayInput = { ...viewOptions.chartDisplay! };

    const existingAxis = chartDisplay.axes.find((a) => a.id === id);
    if (!existingAxis) {
      return;
    }

    // TODO: there could be multiple groups per axis in the future.
    const existingGroups = chartDisplay.groups.filter((g) =>
      existingAxis.driver?.groupIds.includes(g.id),
    );

    const otherAxis = chartDisplay.axes.find((a) => a.id !== id && a.type === ChartAxisType.Driver);
    if (!otherAxis) {
      return;
    }

    chartDisplay.axes = chartDisplay.axes
      .filter((a) => a.id !== existingAxis.id)
      .map((a) =>
        a.id === otherAxis.id && a.driver != null
          ? {
              ...a,
              driver: {
                ...a.driver,
                groupIds: [...a.driver.groupIds, ...existingGroups.map((g) => g.id)],
              },
            }
          : a,
      );

    const blockViewOptions = { ...viewOptions, chartDisplay };
    dispatch(updateBlockViewOptions({ blockId, blockViewOptions }));
  }, [blockId, dispatch, id, viewOptions]);
};

export const useChangeAxisShowTotals = (axisId: string) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (showTotals: boolean) => {
      const chartDisplay: ChartDisplayInput = { ...viewOptions.chartDisplay! };

      const axis = chartDisplay.axes.find(({ id }) => id === axisId);
      if (!axis) {
        return;
      }

      chartDisplay.axes = chartDisplay.axes.map((a) =>
        a.driver != null && a.id === axisId ? { ...a, driver: { ...a.driver, showTotals } } : a,
      );

      const blockViewOptions = { ...viewOptions, chartDisplay };
      dispatch(updateBlockViewOptions({ blockId, blockViewOptions }));
    },
    [axisId, blockId, dispatch, viewOptions],
  );
};

export const useChangeRollup = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const lastCloseMonthKey = useAppSelector(lastActualsMonthKeyForLayerSelector);

  return useCallback(
    (type: DateRangeComparisonType, date?: DateTime) => {
      const customMonth =
        type === DateRangeComparisonType.CustomMonth
          ? date != null
            ? date
            : getDateTimeFromMonthKey(getCurrentMonthKey())
          : undefined;
      const { currPeriod, prevPeriod } = getDateRangeFromRollupPeriod({
        rollupType: type,
        lastCloseMonthKey,
        customMonth,
      });

      dispatch(
        updateBlockConfig({
          blockId,
          fn: (blockConfig) => {
            blockConfig.dateRange = currPeriod;
            blockConfig.blockViewOptions = {
              ...blockConfig.blockViewOptions,
              dateRangeComparison: {
                type,
                selectedPeriod: currPeriod,
                comparisonPeriod: prevPeriod,
              },
            };
          },
        }),
      );
    },
    [blockId, dispatch, lastCloseMonthKey],
  );
};

export const useSeriesTypes = (chartDisplay: ChartDisplay | undefined | null) => {
  return useMemo(
    () =>
      chartDisplay?.series.reduce<Record<DriverId, ChartSeriesType>>((obj, series) => {
        obj[series.id] = series.type;
        return obj;
      }, {}),
    [chartDisplay],
  );
};

export const useGroupColors = (chartDisplay: ChartDisplay | undefined | null) => {
  return useMemo(
    () =>
      chartDisplay?.groups.reduce<Record<LayerId, SeriesColor>>(
        (obj, group, index) => {
          if (group.color == null) {
            obj[group.id] = SERIES_COLORS[index % SERIES_COLORS.length];
            return obj;
          }

          const color = SERIES_COLORS.find((c) => c.value === group.color);
          obj[group.id] = color ?? { name: 'custom', value: group.color };
          return obj;
        },
        {} as Record<string, SeriesColor>,
      ) ?? ({} as Record<string, SeriesColor>),
    [chartDisplay],
  );
};

export const useSeriesColors = (chartDisplay: ChartDisplay | undefined | null) => {
  return useMemo(
    () =>
      chartDisplay?.series.reduce<Record<DriverId, SeriesColor>>((obj, series, index) => {
        if (series.color == null) {
          obj[series.id] = SERIES_COLORS[index % SERIES_COLORS.length];
          return obj;
        }

        const color = SERIES_COLORS.find((c) => c.value === series.color);
        obj[series.id] = color ?? { name: 'custom', value: series.color };
        return obj;
      }, {}),
    [chartDisplay],
  );
};

export const useIsNormalized = (chartDisplay: ChartDisplay | undefined | null) => {
  return useMemo(
    () => Boolean(chartDisplay?.axes.some((axis) => axis.driver?.isNormalized)),
    [chartDisplay],
  );
};

export const useChangeDriverSeriesColor = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    ({ seriesId, color }: { seriesId: string; color: string }) => {
      const chartDisplay = {
        ...viewOptions.chartDisplay!,
      };
      const blockViewOptions = {
        ...viewOptions,
        chartDisplay,
      };

      chartDisplay.series = chartDisplay.series.map((series) =>
        series.id === seriesId ? { ...series, color } : series,
      );

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useChangeDriverSeriesType = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    ({ seriesId, type }: { seriesId: string; type: ChartSeriesType }) => {
      const chartDisplay = {
        ...viewOptions.chartDisplay!,
      };
      const blockViewOptions = {
        ...viewOptions,
        chartDisplay,
      };

      chartDisplay.series = chartDisplay.series.map((series) =>
        series.id === seriesId ? { ...series, type } : series,
      );

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, dispatch, viewOptions],
  );
};

const getWritableGroup = (
  chartDisplay: ChartDisplayInput,
  predicate: (group: ChartGroup) => boolean,
  fallbackProps: Partial<ChartGroupInput>,
): ChartGroupInput => {
  const group = chartDisplay.groups.find(predicate);
  if (group == null) {
    const newGroup: ChartGroupInput = {
      id: uuidv4(),
      isDefault: false,
      seriesIds: [],
      ...fallbackProps,
    };

    chartDisplay.groups.push(newGroup);
    return newGroup;
  }

  const clone = cloneDeep(group);
  chartDisplay.groups = chartDisplay.groups.map((g) => (g.id === clone.id ? clone : g));

  return clone;
};

export const useAddDrivers = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const blockConfig = useAppSelector((state) => blockConfigSelector(state, blockId));
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const layerNamesById = useAppSelector(layerNameByIdSelector);

  const { viewAsType } = viewOptions;
  const comparisonLayerIds = blockConfig?.comparisons?.layerIds;

  return useCallback(
    (driverIds: DriverId[]) => {
      const { chartDisplay } = viewOptions;
      const hasSeries = chartDisplay != null && chartDisplay.groups.length > 0;
      if (!hasSeries) {
        return;
      }

      const updatedChartDisplay: ChartDisplayInput = { ...chartDisplay };
      const seriesType = BLOCK_VIEW_AS_TO_DEFAULT_CHART_SERIES_TYPE[viewAsType!];
      const lastIndex = updatedChartDisplay.series.length - 1;
      const lastSeries = safeObjGet(updatedChartDisplay.series[lastIndex]);

      const defaultGroup = getWritableGroup(updatedChartDisplay, (g) => g.isDefault, {
        isDefault: true,
        layerId: DEFAULT_LAYER_ID,
      });

      if (comparisonLayerIds != null && comparisonLayerIds.length > 0) {
        const groups: ChartGroupInput[] = [];
        const series: ChartSeriesInput[] = driverIds.map((driverId, index) => ({
          id: uuidv4(),
          driverId,
          type: seriesType ?? lastSeries?.type ?? ChartSeriesType.Bar,
          color: SERIES_COLORS[(lastIndex + index + 1) % SERIES_COLORS.length].value,
        }));

        // Ensure that the new drivers are added to the default group.
        defaultGroup.seriesIds.push(...series.map((s) => s.id));

        let index = 0;
        for (const layerId of comparisonLayerIds) {
          const comparisonGroup = getWritableGroup(
            updatedChartDisplay,
            (g) => g.layerId === layerId,
            {
              layerId,
              name: layerNamesById[layerId] ?? null,
            },
          );

          const existingSeries = updatedChartDisplay.series.find((s) =>
            comparisonGroup.seriesIds.includes(s.id),
          );
          const type = existingSeries?.type ?? ChartSeriesType.Line;
          const color = existingSeries?.color ?? SERIES_COLORS[index % SERIES_COLORS.length].value;

          const comparisonSeriesIds = [];
          for (const driverId of driverIds) {
            const comparisonSeriesItem: ChartSeriesInput = {
              id: uuidv4(),
              driverId,
              type,
              color,
            };
            series.push(comparisonSeriesItem);
            comparisonSeriesIds.push(comparisonSeriesItem.id);
          }

          comparisonGroup.seriesIds = [...comparisonGroup.seriesIds, ...comparisonSeriesIds];

          ++index;
        }

        updatedChartDisplay.groups = [...updatedChartDisplay.groups, ...groups];
        updatedChartDisplay.series = [...updatedChartDisplay.series, ...series];

        const driverAxis = updatedChartDisplay.axes.find((a) => a.driver != null);
        updatedChartDisplay.axes = updatedChartDisplay.axes.map((a) =>
          a.id === driverAxis?.id && a.driver != null
            ? {
                ...a,
                driver: {
                  ...a.driver,
                  groupIds: [...a.driver.groupIds, ...groups.map((g) => g.id)],
                },
              }
            : a,
        );
      } else {
        const series: ChartSeriesInput[] = driverIds.map((driverId, index) => ({
          id: uuidv4(),
          driverId,
          type: seriesType ?? lastSeries?.type ?? ChartSeriesType.Bar,
          color: SERIES_COLORS[(lastIndex + index + 1) % SERIES_COLORS.length].value,
        }));

        updatedChartDisplay.groups = updatedChartDisplay.groups.map((g) =>
          g.isDefault ? { ...g, seriesIds: [...g.seriesIds, ...series.map((s) => s.id)] } : g,
        );
        updatedChartDisplay.series = [...updatedChartDisplay.series, ...series];
      }

      const blockViewOptions = { ...viewOptions, chartDisplay: updatedChartDisplay };
      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, comparisonLayerIds, dispatch, layerNamesById, viewAsType, viewOptions],
  );
};

export const useRemoveDriver = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (driverId: DriverId) => {
      const { chartDisplay } = viewOptions;
      const hasSeries = chartDisplay != null && chartDisplay.axes.length > 0;
      if (!hasSeries) {
        return;
      }

      const updatedChartDisplay: ChartDisplayInput = { ...chartDisplay };

      const seriesIds = new Set(
        updatedChartDisplay.series
          .filter(
            (s) =>
              // Backwards compatible use.
              s.id === driverId ||
              // Preferred use.
              s.driverId === driverId,
          )
          .map((s) => s.id),
      );
      if (seriesIds.size === 0) {
        return;
      }

      const groups = updatedChartDisplay.groups.filter((g) =>
        g.seriesIds.some((id) => seriesIds.has(id)),
      );
      for (const group of groups) {
        const isLastSeries = group.seriesIds.length < 2;
        if (isLastSeries) {
          // Remove group if left empty.
          updatedChartDisplay.groups = updatedChartDisplay.groups.filter((g) => g.id !== group.id);

          // It should not be possible to add a group to multiple axes, but check anyway.
          const axes = updatedChartDisplay.axes.filter((a) =>
            a.driver?.groupIds.includes(group.id),
          );
          for (const axis of axes) {
            if (axis.driver!.groupIds.length < 2) {
              // Remove axes if left empty.
              updatedChartDisplay.axes = updatedChartDisplay.axes.filter((a) => a.id !== axis.id);
            }
          }
        } else {
          // Remove series from group.
          updatedChartDisplay.groups = updatedChartDisplay.groups.map((g) => ({
            ...g,
            seriesIds: g.seriesIds.filter((id) => !seriesIds.has(id)),
          }));
        }
      }

      // Remove affected series.
      updatedChartDisplay.series = updatedChartDisplay.series.filter((s) => !seriesIds.has(s.id));

      const blockViewOptions = { ...viewOptions, chartDisplay: updatedChartDisplay };
      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useAddScenarioComparison = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const driverIds = useAppSelector((state) => blockDriverIdsSelector(state, blockId));
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const layerNameById = useAppSelector(layerNameByIdSelector);

  return useCallback(
    (layerId: LayerId) => {
      const { chartDisplay } = viewOptions;
      if (!chartDisplay) {
        return;
      }

      const updatedChartDisplay: ChartDisplayInput = {
        ...chartDisplay,
      };

      const groups: ChartGroup[] = [];
      const series: ChartSeries[] = [];

      const seriesIds = [];
      // Use a static index to generate the comparison series colors.
      const index = chartDisplay.groups.length;

      for (const driverId of driverIds) {
        const otherSeries = chartDisplay.series.find((s) => s.driverId === driverId);
        if (!otherSeries) {
          continue;
        }

        const seriesType = otherSeries.type;
        const seriesItem: ChartSeries = {
          id: uuidv4(),
          driverId,
          type: seriesType ?? ChartSeriesType.Line,
          color: SERIES_COLORS[index % SERIES_COLORS.length].value,
        };

        series.push(seriesItem);
        seriesIds.push(seriesItem.id);
      }

      // Place all series in a single new group with the comparison layer.
      groups.push({
        id: uuidv4(),
        seriesIds,
        name: layerNameById[layerId],
        layerId,
        isDefault: false,
      });

      updatedChartDisplay.groups = updatedChartDisplay.groups.map((g) =>
        g.isDefault && g.layerId == null ? { ...g, layerId: DEFAULT_LAYER_ID } : g,
      );

      updatedChartDisplay.series = [...updatedChartDisplay.series, ...series];
      updatedChartDisplay.groups = [...updatedChartDisplay.groups, ...groups];

      const driverAxis = chartDisplay.axes.find((a) => a.driver != null);
      if (!driverAxis) {
        return;
      }

      updatedChartDisplay.axes = updatedChartDisplay.axes.map((a) =>
        a.id === driverAxis.id && a.driver != null
          ? {
              ...a,
              driver: { ...a.driver, groupIds: [...a.driver.groupIds, ...groups.map((g) => g.id)] },
            }
          : a,
      );

      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        chartDisplay: updatedChartDisplay,
      };
      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, dispatch, driverIds, layerNameById, viewOptions],
  );
};

export const useRemoveScenarioComparison = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (layerId: LayerId | 'all') => {
      const { chartDisplay } = viewOptions;
      if (!chartDisplay) {
        return;
      }
      const updatedChartDisplay: ChartDisplayInput = {
        ...chartDisplay,
      };

      const removeGroups = chartDisplay.groups.filter((g) => {
        // Only keep the default group.
        if (layerId === 'all') {
          return !g.isDefault && g.layerId != null && g.layerId !== DEFAULT_LAYER_ID;
        }
        return g.layerId === layerId;
      });
      const groupIds = new Set(removeGroups.map((g) => g.id));
      const seriesIds = new Set(removeGroups.flatMap((g) => g.seriesIds));

      updatedChartDisplay.groups = updatedChartDisplay.groups.map((g) =>
        g.isDefault && g.layerId == null ? { ...g, layerId: DEFAULT_LAYER_ID } : g,
      );

      updatedChartDisplay.groups = chartDisplay.groups.filter((g) => !groupIds.has(g.id));
      updatedChartDisplay.series = chartDisplay.series.filter((s) => !seriesIds.has(s.id));
      updatedChartDisplay.axes = chartDisplay.axes.map((a) =>
        a.driver != null
          ? {
              ...a,
              driver: {
                ...a.driver,
                groupIds: a.driver.groupIds.filter((id) => !groupIds.has(id)),
              },
            }
          : a,
      );

      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        chartDisplay: updatedChartDisplay,
      };
      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useShowLegend = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (showLegend: boolean) => {
      const { chartDisplay } = viewOptions;
      if (!chartDisplay) {
        return;
      }
      const updatedChartDisplay: ChartDisplayInput = {
        ...chartDisplay,
      };

      updatedChartDisplay.legend = {
        ...updatedChartDisplay.legend,
        showLegend,
      };

      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        chartDisplay: updatedChartDisplay,
      };
      dispatch(updateBlockViewOptions({ blockId, blockViewOptions }));
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useChangeLegendPosition = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (position: ChartElementPosition) => {
      const { chartDisplay } = viewOptions;
      if (!chartDisplay) {
        return;
      }
      const updatedChartDisplay: ChartDisplayInput = {
        ...chartDisplay,
      };

      updatedChartDisplay.legend = {
        ...updatedChartDisplay.legend,
        position,
      };

      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        chartDisplay: updatedChartDisplay,
      };
      dispatch(updateBlockViewOptions({ blockId, blockViewOptions }));
    },
    [blockId, dispatch, viewOptions],
  );
};

export const useChangeLegendContainer = (option: 'maxWidth' | 'maxHeight') => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (value: number) => {
      const { chartDisplay } = viewOptions;
      if (!chartDisplay) {
        return;
      }
      const updatedChartDisplay: ChartDisplayInput = {
        ...chartDisplay,
      };

      if (updatedChartDisplay.legend?.container?.[option] === value) {
        return;
      }

      updatedChartDisplay.legend = {
        ...updatedChartDisplay.legend,
        container: {
          ...updatedChartDisplay.legend?.container,
          [option]: value,
        },
      };

      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        chartDisplay: updatedChartDisplay,
      };
      dispatch(updateBlockViewOptions({ blockId, blockViewOptions }));
    },
    [blockId, dispatch, option, viewOptions],
  );
};

export const useChangeLegendItem = (option: 'maxWidth' | 'paddingX' | 'paddingY') => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(
    (value: number) => {
      const { chartDisplay } = viewOptions;
      if (!chartDisplay) {
        return;
      }
      const updatedChartDisplay: ChartDisplayInput = {
        ...chartDisplay,
      };

      if (updatedChartDisplay.legend?.item?.[option] === value) {
        return;
      }

      updatedChartDisplay.legend = {
        ...updatedChartDisplay.legend,
        item: {
          ...updatedChartDisplay.legend?.item,
          [option]: value,
        },
      };

      const blockViewOptions: BlockViewOptionsInput = {
        ...viewOptions,
        chartDisplay: updatedChartDisplay,
      };
      dispatch(updateBlockViewOptions({ blockId, blockViewOptions }));
    },
    [blockId, dispatch, option, viewOptions],
  );
};

export const useResetLegend = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  return useCallback(() => {
    const { chartDisplay } = viewOptions;
    if (!chartDisplay) {
      return;
    }
    const updatedChartDisplay: ChartDisplayInput = {
      ...chartDisplay,
      legend: {
        showLegend: true,
        position: ChartElementPosition.Right,
        container: {},
        item: {},
      },
    };
    const blockViewOptions: BlockViewOptionsInput = {
      ...viewOptions,
      chartDisplay: updatedChartDisplay,
    };
    dispatch(updateBlockViewOptions({ blockId, blockViewOptions }));
  }, [blockId, dispatch, viewOptions]);
};
