import { InternalPage } from 'config/internalPages/internalPage';
import { deleteBlocksPagesMutation } from 'reduxStore/actions/blocksPageMutations';
import {
  submitAutoLayerizedMutations,
  submitMutation,
} from 'reduxStore/actions/submitDatasetMutation';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { AppThunk } from 'reduxStore/store';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { blocksPageForInternalPageTypeSelector } from 'selectors/blocksPagesSelector';

export const createInternalPage =
  (internalPage: InternalPage): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { canWritePages } = accessCapabilitiesSelector(state);
    // we don't need to block creating internal pages on access resources being loaded. if the access resources
    // have a bearing on whether pages can be created, we'll need to update this logic
    if (!canWritePages) {
      return;
    }

    const existingPage = blocksPageForInternalPageTypeSelector(
      state,
      internalPage.internalPageType,
    );
    // If page exists, check its internalPageVersion. If the new page version is higher
    // than the existing page version, delete the existing page and create the new page.
    // Otherwise, leave the existing page as-is.
    if (existingPage != null) {
      const existingVersion = existingPage.internalPageVersion;
      const newVersion = internalPage.version;
      if (newVersion != null && (existingVersion == null || newVersion > existingVersion)) {
        dispatch(
          submitAutoLayerizedMutations(
            'create-blocks-page',
            [
              {
                newBlocksPages: [internalPage.blocksPageCreateInput],
                newBlocks: internalPage.blocksCreateInputs,
                ...deleteBlocksPagesMutation(state, [existingPage.id]),
              },
            ],
            {
              // Normally updateDrivers gets routed to the current layer, but in this
              // case we want it to follow delete blocksPages.
              updateDrivers: 'deleteBlocksPages',
            },
          ),
        );
      }
    } else {
      dispatch(
        submitMutation(
          {
            newBlocksPages: [internalPage.blocksPageCreateInput],
            newBlocks: internalPage.blocksCreateInputs,
          },
          { forceLayerId: DEFAULT_LAYER_ID },
        ),
      );
    }
  };
