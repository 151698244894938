import isString from 'lodash/isString';
import { useMemo } from 'react';

import { DatabaseGroupKey } from 'config/businessObjects';
import { BusinessObjectFieldCellRef, CellType } from 'config/cells';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { MonthKey } from 'types/datetime';

export default function useObjectCellRef(
  objectId: BusinessObjectId | null,
  columnInfo:
    | typeof NAME_COLUMN_TYPE
    | 'addNewColumn'
    | { fieldSpecId: BusinessObjectFieldSpecId; monthKey?: MonthKey },
  groupKey: DatabaseGroupKey,
): BusinessObjectFieldCellRef {
  const objectFieldSpecId = isString(columnInfo) ? NAME_COLUMN_TYPE : columnInfo.fieldSpecId;
  const monthKey =
    !isString(columnInfo) && columnInfo.monthKey != null ? columnInfo.monthKey : null;
  const columnType = isString(columnInfo) ? columnInfo : null;
  const cellRef: BusinessObjectFieldCellRef = useMemo(() => {
    return {
      type: CellType.ObjectField,
      rowKey: { objectId, groupKey },
      columnKey:
        columnType != null
          ? { columnType, columnLayerId: undefined }
          : monthKey != null
            ? { monthKey, objectFieldSpecId }
            : { objectFieldSpecId },
    };
  }, [groupKey, columnType, monthKey, objectFieldSpecId, objectId]);

  return cellRef;
}
