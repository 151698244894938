import { createSelector } from 'reselect';

import { extractMonthKey } from 'helpers/dates';
import { getComputedEventGroupRangeWithDefault } from 'helpers/eventGroups';
import { cursorMonthKeySelector } from 'selectors/cursorSelector';
import {
  eventsWithoutLiveEditsByIdForLayerSelector,
  populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';
import { selectedPlanTimelineItemRefSelector } from 'selectors/selectionSelector';
import { ISOTime, MonthKey } from 'types/datetime';
import { Selector } from 'types/redux';

export const chartDisplayMonthKeySelector: Selector<MonthKey | undefined> = createSelector(
  cursorMonthKeySelector,
  selectedPlanTimelineItemRefSelector,
  populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
  eventsWithoutLiveEditsByIdForLayerSelector,
  (cursorMonthKey, selectedPlanTimelineItemRef, populatedEventGroupsById, eventsById) => {
    if (cursorMonthKey != null) {
      return cursorMonthKey;
    }

    const activeSelectedItem = selectedPlanTimelineItemRef?.active;
    if (activeSelectedItem != null) {
      let endISO: ISOTime | undefined;
      if (activeSelectedItem.type === 'event') {
        const event = eventsById[activeSelectedItem.id];
        if (event != null) {
          endISO = event.end;
        }
      } else {
        const eventGroup = populatedEventGroupsById[activeSelectedItem.id];
        if (eventGroup != null) {
          const { end } = getComputedEventGroupRangeWithDefault(eventGroup, {});
          endISO = end;
        }
      }

      if (endISO != null) {
        return extractMonthKey(endISO);
      }
    }

    return undefined;
  },
);

export const chartDisplayMonthKeyWithDefaultSelector: Selector<MonthKey> = createSelector(
  chartDisplayMonthKeySelector,
  datasetLastActualsMonthKeySelector,
  (chartDisplayMonthKey, datasetLastActualsMonthKey) => {
    return chartDisplayMonthKey ?? datasetLastActualsMonthKey;
  },
);
