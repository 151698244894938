import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';

import DragHandle from 'vectors/DragHandle';

interface Props {
  isDisabled?: boolean;
  bgColor?: IconButtonProps['bgColor'];
  'aria-label': string;
  orientation?: 'horizontal' | 'vertical';
  draggable?: boolean;
}

const DragHandleButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ orientation = 'vertical', draggable = true, ...rest }, ref) => (
    <IconButton
      icon={<DragHandle orientation={orientation} />}
      size="xs"
      variant="text"
      p={1}
      borderRadius="md"
      color="gray.400"
      transition="none"
      cursor={draggable ? 'grab' : 'pointer'}
      _hover={{ bgColor: 'gray.200', color: 'gray.500' }}
      _active={{ bgColor: 'gray.300', color: 'gray.500' }}
      ref={ref}
      {...rest}
    />
  ),
);

export default DragHandleButton;
