import { useBoolean } from '@chakra-ui/react';
import { chunk, noop } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  DisposeFn,
  useWebWorkerDataProviderContext,
} from 'components/WebWorkerDataProvider/Context';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { LayerId } from 'reduxStore/models/layers';
import { MonthKey } from 'types/datetime';

export type DisposeFnKey = `${FormulaEntityTypedId['id']}.${LayerId}.${MonthKey}.${MonthKey}`;

export function useRequestPaginatedRows<T>({
  rows,
  pageSize,
  getKeys,
  requestRowValue,
  disable,
}: {
  rows: T[];
  pageSize: number;
  getKeys: (row: T) => DisposeFnKey[];
  requestRowValue: (row: T) => Array<[DisposeFnKey, DisposeFn]>;
  disable?: boolean;
}) {
  const { requestBatch, requestValue } = useWebWorkerDataProviderContext();

  const [pageNumber, setPageNumber] = useState(0);
  const [lastPageLoaded, setLastPageLoaded] = useState(-1);
  const [isLoading, setIsLoading] = useBoolean(false);

  const batches = useMemo(() => chunk(rows, pageSize), [rows, pageSize]);

  const allActiveKeys: Set<DisposeFnKey> = useMemo(
    () => new Set(rows.flatMap(getKeys)),
    [getKeys, rows],
  );

  const disposeFns = useRef<Map<DisposeFnKey, DisposeFn>>(new Map());
  // Final cleanup
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      disposeFns.current.forEach((fn) => fn());
    };
  }, []);

  const requestPageBatch = useCallback(
    async (batch: T[]) => {
      // Defer requests to allow the page to load.
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          requestBatch(() => {
            for (const row of batch) {
              requestRowValue(row).forEach(([key, disposeFn]) =>
                disposeFns.current.set(key, disposeFn),
              );
            }
          });
          resolve();
        }, 0);
      });
    },
    [requestBatch, requestRowValue],
  );

  useEffect(() => {
    if (
      disable ||
      batches == null ||
      pageNumber >= batches.length ||
      (lastPageLoaded != null && lastPageLoaded >= pageNumber)
    ) {
      return;
    }

    setIsLoading.on();
    // Fast scrolling means we might need to fetch more pages.
    if (pageNumber - lastPageLoaded > 1) {
      for (let i = lastPageLoaded + 1; i < pageNumber; i++) {
        requestPageBatch(batches[i]);
      }
      setLastPageLoaded(pageNumber);
    } else {
      // Track the last page loaded so that superfluous rerenders
      // do not accidentally dispatch again for the same page of data.
      setLastPageLoaded((i) => ++i);
    }
    requestPageBatch(batches[pageNumber]).then(() => setIsLoading.off());
  }, [
    batches,
    lastPageLoaded,
    pageNumber,
    requestBatch,
    requestValue,
    setIsLoading,
    requestPageBatch,
    disable,
  ]);

  useEffect(() => {
    if (disable) {
      return;
    }

    const previouslyLoadedBatches = batches.slice(0, pageNumber + 2).flatMap((batch) => batch);
    const addedOrChangedRows = previouslyLoadedBatches.filter((row) => {
      return getKeys(row).some((key) => !disposeFns.current.has(key));
    });
    requestPageBatch(addedOrChangedRows);

    if (disposeFns.current.size > 0) {
      // loop through disposeFns to see if there is a key that doesn't match any row
      // and dispose accordingly
      const entries = disposeFns.current.entries();
      let [disposeKey, disposeFn] = entries.next().value as [DisposeFnKey | undefined, () => void];
      while (disposeKey != null) {
        if (!allActiveKeys.has(disposeKey)) {
          disposeFn();
          disposeFns.current.delete(disposeKey);
        }
        [disposeKey, disposeFn] = (entries.next().value as
          | undefined
          | [DisposeFnKey, () => void]) ?? [undefined, noop];
      }
    }
  }, [batches, pageNumber, requestPageBatch, getKeys, allActiveKeys, disable]);

  const loadMore = useCallback(() => {
    if (rows.length === 0) {
      return;
    }

    if (pageNumber * pageSize >= rows.length) {
      return;
    }

    // Avoid possibility of the page number exceeding batches due to fast scrolling.
    setPageNumber((i) => Math.min(++i, batches.length - 1));
  }, [batches.length, rows.length, pageNumber, pageSize]);

  return {
    isLoading,
    pageNumber,
    totalRows: rows.length,
    lastPageIndex: batches.length - 1,
    loadMore,
  };
}
