import { BoxProps, List, ListProps } from '@chakra-ui/react';

interface Props extends ListProps {
  children: React.ReactNode;
  maxHeight?: BoxProps['maxHeight'];
  overflow?: BoxProps['overflow'];
}

const CustomizeBlockList: React.FC<Props> = ({
  children,
  maxHeight = '96',
  overflow = 'visible',
  ...rest
}) => {
  return (
    <List
      data-testid="customize-menu"
      spacing="px"
      py={0}
      fontSize="xs"
      maxHeight={maxHeight}
      overflow={overflow}
      {...rest}
    >
      {children}
    </List>
  );
};

export default CustomizeBlockList;
