import { Section } from 'components/SelectMenu/SelectMenu';

export const SECTIONS_BY_ID: Record<'recent' | 'driver' | 'model' | 'driverGroup', Section> = {
  recent: {
    id: 'recent',
    name: 'Recent',
    maxResults: 5,
    showMore: false,
  },
  driver: {
    id: 'driver',
    name: 'Drivers',
    maxResults: 3,
    showMore: true,
  },
  model: {
    id: 'model',
    name: 'Models',
    maxResults: 3,
    showMore: true,
  },
  driverGroup: {
    id: 'driverGroup',
    name: 'Groups',
    maxResults: 3,
    showMore: true,
  },
};
