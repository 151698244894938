import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'TextIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  d: 'M2 3.09091V12.9091C2 13.5118 2.48818 14 3.09091 14H12.9091C13.5118 14 14 13.5118 14 12.9091V3.09091C14 2.48818 13.5118 2 12.9091 2H3.09091C2.48818 2 2 2.48818 2 3.09091ZM4.18182 11.2727C4.18182 10.9716 4.42618 10.7273 4.72727 10.7273H9.09091C9.392 10.7273 9.63636 10.9716 9.63636 11.2727C9.63636 11.5738 9.392 11.8182 9.09091 11.8182H4.72727C4.42618 11.8182 4.18182 11.5738 4.18182 11.2727ZM4.18182 9.09091C4.18182 8.78982 4.42618 8.54545 4.72727 8.54545H11.2727C11.5738 8.54545 11.8182 8.78982 11.8182 9.09091C11.8182 9.392 11.5738 9.63636 11.2727 9.63636H4.72727C4.42618 9.63636 4.18182 9.392 4.18182 9.09091ZM4.18182 6.90909C4.18182 6.608 4.42618 6.36364 4.72727 6.36364H9.09091C9.392 6.36364 9.63636 6.608 9.63636 6.90909C9.63636 7.21018 9.392 7.45455 9.09091 7.45455H4.72727C4.42618 7.45455 4.18182 7.21018 4.18182 6.90909ZM4.18182 4.72727C4.18182 4.42618 4.42618 4.18182 4.72727 4.18182H11.2727C11.5738 4.18182 11.8182 4.42618 11.8182 4.72727C11.8182 5.02836 11.5738 5.27273 11.2727 5.27273H4.72727C4.42618 5.27273 4.18182 5.02836 4.18182 4.72727Z',
});
