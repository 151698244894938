import { createCachedSelector } from 're-reselect';

import { getCacheKeyForLayerSelector, SelectorWithLayerParam } from 'helpers/layerSelectorFactory';
import { getGivenOrCurrentLayerId, layersSelector } from 'selectors/layerSelector';

export const deletedIdentifiersSelector: SelectorWithLayerParam<NullableRecord<string, string>> =
  createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
    const layer = layers[layerId];
    if (layer == null) {
      return {};
    }

    return layer.deletedIdentifiers;
  })(getCacheKeyForLayerSelector);
