import { ThunkAction, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import { Action } from 'redux';
import { Client } from 'urql';

import { isDevelopment, isPreview, isSSR, isUserBrowser } from 'helpers/environment';
import { initMemoryHelpers } from 'helpers/memory';
import { RequestQueue } from 'helpers/requests';
import { initTestHelpers } from 'helpers/testHelpers';
import formulaCacheInvalidator from 'reduxStore/middleware/formulaCacheInvalidator';
import { mutationsMonitor } from 'reduxStore/middleware/mutationsMonitor';
import { saveUserStateMiddleware } from 'reduxStore/middleware/saveUserState';
import { timeActions } from 'reduxStore/middleware/timeActions';
import { enableFullReduxLogging } from 'reduxStore/reducers/featureFlagsSlice';
import rootReducer from 'reduxStore/reducers/index';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { isRunwayEmployeeSelector } from 'selectors/loginSelector';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: RootState) => {
    // just send feature flags & page information for now
    return {
      launchDarkly: state.dataset.launchDarklyFlags,
      featureFlags: state.featureFlags,
      page: state.page,
    };
  },
});

export type ThunkExtras = { urqlClient: Client; requestQueue: RequestQueue };

function makeStore(initialState: Partial<RootState> | undefined, thunkExtras: ThunkExtras) {
  return configureStore({
    reducer: rootReducer,
    // TODO: we can get rid of this cast by passing in the appropriate type as a generic for configureStore
    preloadedState: initialState as RootState,
    middleware: (getDefaultMiddleware) => {
      if (isSSR()) {
        return getDefaultMiddleware({
          serializableCheck: false,
          thunk: { extraArgument: thunkExtras },
        });
      } else if (isDevelopment && isUserBrowser()) {
        return getDefaultMiddleware({
          serializableCheck: false,
          thunk: { extraArgument: thunkExtras },
        }).concat([
          saveUserStateMiddleware,
          formulaCacheInvalidator,
          mutationsMonitor,
          timeActions,
        ]);
      }

      return getDefaultMiddleware({
        serializableCheck: false,
        thunk: { extraArgument: thunkExtras },
      }).concat([saveUserStateMiddleware, formulaCacheInvalidator]);
    },
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
    devTools: enableFullReduxLogging()
      ? undefined
      : {
          maxAge: 2,
          serialize: {
            replacer: (key, value) => {
              const scrubMessage =
                'scrubbed for size. enable full redux dev tool logging in runway feature flags';

              if (key === 'timeSeries' && value instanceof Object) {
                return {
                  [scrubMessage]: true,
                  __length: Object.keys(value).length,
                };
              }
              if (key === 'snapshots' && value instanceof Object) {
                const scrubbed: { [key: string]: string } = {};
                for (const snapshotKey of Object.keys(value)) {
                  scrubbed[snapshotKey] = scrubMessage;
                }
                return scrubbed;
              }
              return value;
            },
          },
        },
  });
}

export type ReduxStore = ReturnType<typeof makeStore>;

let clientSideSingleton: ReduxStore;

export const getOrCreateStore = (
  state: RootState | undefined,
  urqlClient: Client,
  requestQueue: RequestQueue,
) => {
  if (!isSSR() && clientSideSingleton != null) {
    return clientSideSingleton;
  }
  const thunkExtras: ThunkExtras = { urqlClient, requestQueue };

  let store: ReduxStore;
  if (isDevelopment && module.hot) {
    // During hot-module reloading, this file is reloaded, which wipes out clientSideSingleton,
    // however the app keeps using the old store, so we need to make sure we return the old store
    // which is available in window.reduxStore
    store = window.reduxStore ?? makeStore(state, thunkExtras);
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  } else {
    store = makeStore(state, thunkExtras);
  }

  if (!isSSR()) {
    clientSideSingleton = store;
    const currentState = store.getState();
    if (
      window.Cypress === true ||
      isDevelopment ||
      isPreview ||
      isRunwayEmployeeSelector(currentState)
    ) {
      window.reduxStore = store;

      initMemoryHelpers(store);

      if (!isSSR() && window.Cypress != null) {
        initTestHelpers(store);
      }
    }
  }

  return store;
};

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, ThunkExtras, Action>;

export type AsyncAppThunk<ReturnType = void> = AppThunk<Promise<ReturnType>>;

export type AppDispatch = ReduxStore['dispatch'];

export type AsyncAppThunkConfig = { state: RootState; extra: ThunkExtras };
