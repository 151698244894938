import React, { useCallback, useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { VALUE_TYPE_ICONS, VALUE_TYPE_NAMES } from 'config/drivers';
import { ValueType } from 'generated/graphql';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { updateDriverValueTypes } from 'reduxStore/actions/driverMutations';
import { DriverId } from 'reduxStore/models/drivers';
import { driverSelector } from 'selectors/driversSelector';

const SUPPORTED_VALUE_TYPES: ValueType[] = [ValueType.Number, ValueType.Timestamp];

interface Props {
  driverId: DriverId;
}

const DriverValueTypeSelectMenu: React.FC<Props> = ({ driverId }) => {
  const dispatch = useAppDispatch();
  const valueType = useAppSelector((state) => driverSelector(state, { id: driverId })?.valueType);

  const items = useMemo(() => {
    return SUPPORTED_VALUE_TYPES.map((vt) => ({
      id: vt,
      icon: VALUE_TYPE_ICONS[vt],
      name: VALUE_TYPE_NAMES[vt],
      isChecked: valueType === vt,
    }));
  }, [valueType]);

  const onSelect = useCallback(
    (item: SelectItem) => {
      if (driverId == null) {
        return;
      }
      dispatch(updateDriverValueTypes({ driverIds: [driverId], valueType: item.id as ValueType }));
    },
    [dispatch, driverId],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu
        maxHeight="auto"
        onSelect={onSelect}
        items={items}
        width="16rem"
        startFocusIdx={-1}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default DriverValueTypeSelectMenu;
