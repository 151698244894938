import React from 'react';

import { TimeseriesColumnValueType } from 'components/AgGridComponents/types/TimeseriesColumnDef';
import SettingsPopoverContentBaseComponent from 'components/BusinessObjectTable/SettingsPopoverContents/SettingsPopoverContentBaseComponent';
import { DISPLAY_SECTION_ID } from 'components/BusinessObjectTable/SettingsPopoverContents/useContextMenuItems';
import { Section } from 'components/SelectMenu/SelectMenu';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { toggleColumnVisibility } from 'reduxStore/actions/blockMutations';
import {
  blockCanHidePropertiesSelector,
  blockConfigShowRestrictedSelector,
} from 'selectors/blocksSelector';
import { Eye } from 'vectors';

const SECTIONS: Section[] = [{ id: DISPLAY_SECTION_ID }];

interface Props {
  isAutoFocused: boolean;
  columnType: TimeseriesColumnValueType;
  onClose?: () => void;
  isOpen?: boolean;
}

const TimeseriesColumnPropertySettingsPopoverContent = React.forwardRef<HTMLDivElement, Props>(
  ({ isAutoFocused, columnType, onClose, isOpen }, ref) => {
    const dispatch = useAppDispatch();
    const { blockId } = useBlockContext();
    const showRestricted = useAppSelector((state) =>
      blockConfigShowRestrictedSelector(state, blockId),
    );
    const canHideProperties = useAppSelector((state) =>
      blockCanHidePropertiesSelector(state, blockId),
    );
    const items = [
      ...(canHideProperties && !showRestricted
        ? [
            {
              id: 'hide',
              name: 'Hide in block',
              sectionId: DISPLAY_SECTION_ID,
              icon: <Eye isOpen={false} />,
              onSelect: () => {
                dispatch(
                  toggleColumnVisibility({ blockId, columnKey: columnType, visible: false }),
                );
              },
            },
          ]
        : []),
    ];

    if (items.length === 0) {
      return null;
    }

    return (
      <SettingsPopoverContentBaseComponent
        ref={ref}
        isAutoFocused={isAutoFocused}
        isOpen={isOpen}
        items={items}
        onClose={onClose}
        sections={SECTIONS}
        fieldSpecId={columnType}
      />
    );
  },
);

export default React.memo(TimeseriesColumnPropertySettingsPopoverContent);
