import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'X-Circle',
  viewBox: '0 0 14 14',
  path: (
    <path
      d="M6.99998 0.600098C3.46558 0.600098 0.599976 3.4657 0.599976 7.0001C0.599976 10.5345 3.46558 13.4001 6.99998 13.4001C10.5344 13.4001 13.4 10.5345 13.4 7.0001C13.4 3.4657 10.5344 0.600098 6.99998 0.600098ZM7.75411 7.0001C7.75411 7.0001 9.42824 8.67423 9.51038 8.75636C9.71891 8.9649 9.71891 9.3025 9.51038 9.5105C9.30184 9.71903 8.96424 9.71903 8.75624 9.5105C8.67411 9.4289 6.99998 7.75423 6.99998 7.75423C6.99998 7.75423 5.32584 9.42836 5.24371 9.5105C5.03518 9.71903 4.69758 9.71903 4.48958 9.5105C4.28104 9.30196 4.28104 8.96436 4.48958 8.75636C4.57118 8.67423 6.24584 7.0001 6.24584 7.0001C6.24584 7.0001 4.57171 5.32596 4.48958 5.24383C4.28104 5.0353 4.28104 4.6977 4.48958 4.4897C4.69811 4.28116 5.03571 4.28116 5.24371 4.4897C5.32584 4.5713 6.99998 6.24597 6.99998 6.24597C6.99998 6.24597 8.67411 4.57183 8.75624 4.4897C8.96478 4.28116 9.30238 4.28116 9.51038 4.4897C9.71891 4.69823 9.71891 5.03583 9.51038 5.24383C9.42878 5.32596 7.75411 7.0001 7.75411 7.0001Z"
      fill="currentColor"
    />
  ),
});
