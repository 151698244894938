import range from 'lodash/range';
import { useCallback, useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { MAX_DECIMAL_PLACES } from 'config/decimals';
import { toPxString } from 'helpers/styles';

export const DECIMAL_META_STRINGS = ['1', '1.0', '1.00', '1.000', '1.0000', '1.00000'];
const DEFAULT_DEFAULT_DECIMAL_PRECISION_ID = 'default';
const DECIMAL_PLACES_DISPLAY_MENU_WIDTH_PX = 180;

interface PrecisionSelectItem extends SelectItem {
  places: number | null;
}

interface Props {
  selected: number | null;
  onSelect: (newDecimalPlaces: number | null) => void;
}

const DecimalPlacesSelectMenu: React.FC<Props> = ({ selected, onSelect }) => {
  const items = useMemo<PrecisionSelectItem[]>(
    () => [
      {
        id: DEFAULT_DEFAULT_DECIMAL_PRECISION_ID,
        name: 'Default',
        places: null,
        isChecked: selected == null,
      },
      ...range(MAX_DECIMAL_PLACES + 1).map((decimalPlaces, idx) => ({
        id: String(decimalPlaces),
        name: DECIMAL_META_STRINGS[idx],
        places: decimalPlaces,
        isChecked: decimalPlaces === selected,
      })),
    ],
    [selected],
  );

  const onSelectCallback = useCallback(
    (item: PrecisionSelectItem) => {
      onSelect(item.places);
    },
    [onSelect],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu
        items={items}
        width={toPxString(DECIMAL_PLACES_DISPLAY_MENU_WIDTH_PX)}
        onSelect={onSelectCallback}
        startFocusIdx={-1}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default DecimalPlacesSelectMenu;
