import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

import { KEYBOARD_SHORTCUTS, KeyboardShortcut } from 'config/shortcuts';

interface Props {
  shortcut: KeyboardShortcut;
  color?: TextProps['color'];
}

const KeyboardShortcutHint: React.FC<Props> = ({ shortcut, color }) => {
  return (
    <Text
      as="kbd"
      flexShrink={0}
      fontFamily="body"
      fontSize="xxs"
      fontWeight="medium"
      minWidth={0}
      color={color}
    >
      {KEYBOARD_SHORTCUTS[shortcut]}
    </Text>
  );
};

export default KeyboardShortcutHint;
