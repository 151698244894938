import { Flex, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

import {
  DriverComparisonColumnInfo,
  getDriverDiffTypeText,
} from 'components/CompareScenariosModalContent/diffTypes';
import useAppSelector from 'hooks/useAppSelector';
import { driverSelector } from 'selectors/driversSelector';
import {
  DriverDiffType,
  scenarioComparisonBaselineLayerIdSelector,
} from 'selectors/scenarioComparisonSelector';

interface Props {
  columns: DriverComparisonColumnInfo[];
}

const DriverPropertiesComparisonTableHeader: React.FC<Props> = ({ columns }) => {
  return (
    <Thead>
      <Tr>
        <Th border="1px solid" borderColor="gray.400" rowSpan={2} />
        {columns.map((column) => (
          <Header key={column.driverId} column={column} />
        ))}
      </Tr>
      <Tr>
        {columns.map((column) => {
          return column.diffTypes.map((diffType, index) => {
            const key = `${column.driverId}-${diffType}`;
            return (
              <SubHeader
                key={key}
                diffType={diffType}
                isLast={index === column.diffTypes.length - 1}
              />
            );
          });
        })}
      </Tr>
    </Thead>
  );
};

const SubHeader: React.FC<{ isLast?: boolean; diffType: DriverDiffType }> = ({
  isLast,
  diffType,
}) => {
  return (
    <Th
      borderRightWidth="1px"
      borderRightColor={isLast ? 'gray.400' : 'gray.300'}
      borderRightStyle={isLast ? 'solid' : 'dashed'}
      borderBottomWidth="1px"
      borderBottomColor="gray.400"
      borderBottomStyle="solid"
      p={2}
    >
      <Flex alignItems="center">
        <Text
          mr="1"
          noOfLines={1}
          whiteSpace="nowrap"
          fontWeight="medium"
          fontSize="xxs"
          casing="initial"
          color="gray.500"
        >
          {getDriverDiffTypeText(diffType)}
        </Text>
      </Flex>
    </Th>
  );
};

const Header: React.FC<{
  column: DriverComparisonColumnInfo;
}> = ({ column }) => {
  const baselineLayerId = useAppSelector(scenarioComparisonBaselineLayerIdSelector);

  const baselineDriver = useAppSelector((state) =>
    driverSelector(state, { layerId: baselineLayerId, id: column.driverId }),
  );
  const baselineDriverName = baselineDriver?.name;
  const colSpan = column.diffTypes?.length ?? 1;
  return (
    <Th border="1px solid" borderColor="gray.400" p={2} colSpan={colSpan}>
      <Flex alignItems="center">
        <Text
          mr="1"
          noOfLines={1}
          whiteSpace="nowrap"
          fontWeight="bold"
          fontSize="xxs"
          casing="initial"
        >
          {baselineDriverName}
        </Text>
      </Flex>
    </Th>
  );
};

export default React.memo(DriverPropertiesComparisonTableHeader);
