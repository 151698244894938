import { createSelector } from 'reselect';

import { CellSelection, ImpactCellRef, ImpactDriverCellRef } from 'config/cells';
import { isImpactCellRef } from 'helpers/cells';
import { Event } from 'reduxStore/models/events';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  eventsImpactingDriverAndMonthKeySelector,
  eventsImpactingEventEntityAndMonthKeySelector,
} from 'selectors/eventsAndGroupsSelector';
import { pageSelectionSelector } from 'selectors/selectionSelector';
import { MonthKey } from 'types/datetime';

// TODO: the cell selection type narrowing should really live on `pageSlice`
// Also, this doesn't work for field time series cells.
export const selectedTimelineCellSelector = createSelector(
  pageSelectionSelector,
  (selection) =>
    (selection?.type === 'eventsAndGroups' ? (selection.cellSelection ?? null) : null) as
      | CellSelection<ImpactCellRef>
      | CellSelection<ImpactDriverCellRef>
      | null,
);

export const getSelectedTimelineCellEventsByMonthKey = (
  state: RootState,
): Record<MonthKey, Event> => {
  const cellSelection = selectedTimelineCellSelector(state);

  if (cellSelection == null) {
    return {};
  }

  const eventIdsByMonthKey: Record<MonthKey, Event> = {};

  cellSelection.selectedCells.forEach((cell) => {
    const { columnKey } = cell;
    const { monthKey } = columnKey;
    const events = isImpactCellRef(cell)
      ? eventsImpactingEventEntityAndMonthKeySelector(state, {
          ...cell.rowKey,
          monthKey,
        })
      : eventsImpactingDriverAndMonthKeySelector(state, {
          driverId: cell.rowKey.driverId,
          monthKey,
        });

    // TODO: Update to support multiple events per cell
    const firstEvent = events[0];

    if (firstEvent == null) {
      return;
    }

    eventIdsByMonthKey[monthKey] = firstEvent;
  });

  return eventIdsByMonthKey;
};
