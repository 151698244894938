import {
  GridApi,
  IServerSideGetRowsParams,
  ProcessCellForExportParams,
  ShouldRowBeSkippedParams,
} from 'ag-grid-community';
import { deepEqual } from 'fast-equals';
import { fromPairs, groupBy, isEmpty, isEqual, keyBy, mapValues, partition, uniq } from 'lodash';
import { DateTime } from 'luxon';

import {
  ADD_ITEM_TYPE,
  EMPTY_ROW_GROUP,
  isAddItemRowId,
} from 'components/AgGridComponents/config/grid';
import {
  appendSuffix,
  getDriverColId,
  getDriverFormulaColId,
  isAddColumn,
  isDimensionalPropertyColumn,
  isFormulaColumn,
  isNameColumn,
  isOptionsColumn,
  isValidComparisonType,
} from 'components/AgGridComponents/helpers/deriveColumnDef';
import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import {
  GroupWithoutAddItem,
  ObjectDataSource,
} from 'components/AgGridComponents/helpers/gridDatasource/ObjectDataSource';
import {
  BlockResponse,
  DataSourceChangeEvent,
  DatabaseDataSourceUpdateStateParams,
  GroupRequestParams,
  IDatabaseDataSource,
  ObjectRowsRequest,
  ObjectRowsResponse,
  ObjectRowsUpdated,
  RowGroup,
  RowRequestParams,
} from 'components/AgGridComponents/helpers/gridDatasource/types';
import { getNewValue } from 'components/AgGridComponents/helpers/gridDatasource/utils';
import {
  AgGridDatabaseContext,
  ColumnDef,
  ColumnGroupDef,
  DatabaseObjectRow,
} from 'components/AgGridComponents/types/DatabaseColumnDef';
import {
  EMPTY_ATTRIBUTE_ID,
  Group,
  GroupInfo,
  GroupRow,
  NONE_GROUP_INFO,
  isAddItemRow,
  isObjectRow,
} from 'config/businessObjects';
import { BlockComparisonLayout, ComparisonColumn, DriverType } from 'generated/graphql';
import { getValueForColumn } from 'helpers/blockComparisons';
import { getMonthKeysForRange, previousMonthKey } from 'helpers/dates';
import { extractEmoji } from 'helpers/emoji';
import { CurvePointWithEventMetadata, getCellImpactFromCurvePoints } from 'helpers/events';
import { shouldUseActual } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluatorHelpers';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { getObjectFieldUUID } from 'helpers/object';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { DriverProperty } from 'reduxStore/models/collections';
import { AttributeId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { MutationBatch } from 'reduxStore/models/mutations';
import {
  NonNumericTimeSeries,
  NumericTimeSeries,
  ValueTimeSeries,
} from 'reduxStore/models/timeSeries';
import { CalculationError, isCalculationValue } from 'types/dataset';
import { MonthKey } from 'types/datetime';

const getAddItemRowId = ({ group }: { group: Group }) =>
  `${group.groupInfo.attributeId}-${ADD_ITEM_TYPE}`;

export const generateEmptyRowData = ({
  objectId,
  objectSpecId,
  isIntegration = false,
  groupAttributeId = NONE_GROUP_INFO.attributeId,
  isObjectWithDuplicateKeys = false,
  layerId,
  comparisonType,
}: {
  objectId: string;
  objectSpecId: string;
  isObjectWithDuplicateKeys?: boolean;
  isIntegration?: boolean;
  groupAttributeId?: string;
  layerId: LayerId | undefined;
  comparisonType: ComparisonColumn | undefined;
}): DatabaseObjectRow => ({
  id: `${objectId}${appendSuffix(layerId)}${appendSuffix(comparisonType)}`,
  objectId,
  name: objectId,
  objectSpecId,
  isObjectWithDuplicateKeys,
  isIntegration,
  groupAttributeId,
  subDriverMetaByColId: {},
  data: {},
  rowKey: {},
  hardCodedActuals: new Set<MonthKey>(),
  cellImpactsByMonthKey: {},
  fieldIdByFieldSpecId: {},
  loading: false,
  type: 'objectRow',
  comparisonType,
  layerId,
});

const shouldSkipRow = (row: GroupRow) => isAddItemRow(row);

const isMetaColumn = (column: ColumnDef) => isOptionsColumn(column) || isAddColumn(column);

const shouldSkipColumn = (column: ColumnDef) =>
  isMetaColumn(column) ||
  isNameColumn(column) ||
  isFormulaColumn(column) ||
  isDimensionalPropertyColumn(column);

type ObjectRowsChanged = {
  valuesChanged: number;
  metasChanged: number;
  rowIdsWithUpdates: Set<string>;
};

class InvalidTimeSeriesFormatError extends Error {}

export class DatabaseDataSource extends ObjectDataSource implements IDatabaseDataSource {
  // Derived from Redux.
  private columnDefs?: DatabaseDataSourceUpdateStateParams['columnDefs'];
  private accessCapabilities?: DatabaseDataSourceUpdateStateParams['accessCapabilities'];
  private eventsByEntityId?: DatabaseDataSourceUpdateStateParams['eventsByEntityId'];
  private lastActualsMonthKey?: DatabaseDataSourceUpdateStateParams['lastActualsMonthKey'];
  private extObjectsByExtKey?: DatabaseDataSourceUpdateStateParams['extObjectsByExtKey'];
  private extObjectSpecsByKey?: DatabaseDataSourceUpdateStateParams['extObjectSpecsByKey'];

  // Calculated state.
  private rowData?: DatabaseObjectRow[];
  private furthestRow: number;
  private groupBounds?: Array<[number, number]>;
  private groupAttributeIds?: AttributeId[];

  // Other state.
  private gridApi?: GridApi<DatabaseObjectRow>;

  // State for reacting to mutations.
  private newRowsToListenForToAddIndex: Map<BusinessObjectId, number> = new Map();
  private unmatchedSubDriverIds: Set<DriverId>;
  private staleRowIds: Set<BusinessObjectId>;
  private loadingCreateObjectIdsToAddItemRow: Map<BusinessObjectId, DatabaseObjectRow>;

  public constructor(blockId: BlockId) {
    super(blockId);
    this.furthestRow = 0;
    this.unmatchedSubDriverIds = new Set();
    this.staleRowIds = new Set();
    this.loadingCreateObjectIdsToAddItemRow = new Map();
    DataArbiter.get().register(this);
  }

  public exportToCsv(fileName: string): void {
    if (this.gridApi == null) {
      throw new Error('gridApi is not defined');
    }
    const processCellCallback = (params: ProcessCellForExportParams) => {
      const column = params.column;
      const colDef = column.getColDef() as ColumnDef;
      if (params.value == null) {
        return '';
      }

      const value = `${params.value}`;

      if (colDef.fieldSpec.valueType === 'TIMESTAMP') {
        const dateString = DateTime.fromISO(value).setLocale('en-gb').toLocaleString(); // converts to 02/01/2024 format
        return dateString;
      }

      if (colDef.attributeRefData != null) {
        const attribute = colDef.attributeRefData[value];
        return attribute?.name ?? value;
      }

      return value;
    };

    const shouldRowBeSkipped = (
      params: ShouldRowBeSkippedParams<DatabaseObjectRow, AgGridDatabaseContext>,
    ) => {
      const rowData = params.node.data;
      if (rowData == null) {
        // when the table has been grouped and the rows at the bottom have not rendered yet rowData comes in as undefined
        return true;
      }

      if (isAddItemRowId(rowData.id)) {
        return true;
      }

      if ('rowKey' in rowData && isEmpty(rowData.rowKey)) {
        // skips row headers (when table has been grouped)
        return true;
      }

      return false;
    };

    const OMMITTED_COLUMN_IDS = ['options', 'addColumn'];
    const filteredColumnIds = this.getColumnIds(this.columnDefs ?? []).filter(
      (colId) => !OMMITTED_COLUMN_IDS.includes(colId),
    );
    const [_, updatedFileName] = extractEmoji(fileName);

    this.gridApi.exportDataAsCsv({
      fileName: updatedFileName,
      processCellCallback,
      columnKeys: filteredColumnIds,
      shouldRowBeSkipped,
    });
  }

  public destroy(): void {
    super.destroy();
    DataArbiter.get().unregister(this);
  }

  protected initialize(params: DatabaseDataSourceUpdateStateParams): void {
    super.initialize(params);
    this.dateRange = params.dateRange;
    this.columnDefs = params.columnDefs;
    this.accessCapabilities = params.accessCapabilities;
    this.eventsByEntityId = params.eventsByEntityId;
    this.extObjectsByExtKey = params.extObjectsByExtKey;
    this.lastActualsMonthKey = params.lastActualsMonthKey;
    this.extObjectSpecsByKey = params.extObjectSpecsByKey;
    this.gridApi = params.gridApi;
    this.calculationEngine = params.calculationEngine;
  }

  public updateState(params: DatabaseDataSourceUpdateStateParams): void {
    const { dimensionalPropertyEvaluator } = params;

    const oldObjectsById = this.objectsById;
    const oldLayerId = this.currentLayerId;
    const oldColumnDefs = this.columnDefs;

    let hasEnteredDraftLayer = false;
    if (this.shouldParamsChangeForceAgGridToRefresh(params)) {
      // don't refresh if explicitly told not to
      // scenarios we would not want to refresh:
      // - when user is on the default layer and makes a change that creates a new draft layer,
      //   (e.g. adding or deleting rows) this prevents the flicker & scroll to top that a full
      //   refresh would cause
      if (this.suppressRefreshOnLayerChange && oldLayerId !== params.currentLayerId) {
        hasEnteredDraftLayer = true;
        // skipped refresh this time, don't skip future checks
        this.suppressRefreshOnLayerChange = false;
      } else {
        this.reset();
        this.emit({ type: 'refresh' });
      }
    }

    if (this.undoneMutation != null) {
      this.updateStateForUndoneMutation(this.undoneMutation);
    }

    // as we update the instance, we need to make sure that the gridApi definition is not lost
    // otherwise we'll lose the ability to call exportToCsv
    const gridApi = params.gridApi ?? this.gridApi;

    this.initialize({ ...params, gridApi });

    if (hasEnteredDraftLayer && this.calculationEngine === 'webworker') {
      // The WW doesn't push updates to the client the same way BE calc does,
      // so we need to manually re-fetch. We could probably be smarter about
      // which rows we fetch, but this does the job for now.
      this.requestObjectRows({
        startRow: 0,
        endRow: this.furthestRow,
        type: 'row',
      });
    }

    if (hasEnteredDraftLayer) {
      this.requestBlockHelper({
        startRow: 0,
        endRow: this.furthestRow,
      });
    }

    // If the columns have changed, lets rerender the whole table since some columns may have been removed
    const columnsChanged = this.haveColumnDefsChanged(oldColumnDefs, params.columnDefs);

    if (oldObjectsById != null) {
      const rowsToRebuild = new Set<BusinessObjectId>();

      // TODO: consider only passing fields we know about being created via mutations.
      // Search for fields being added.
      const newFieldsByObjectId = this.findNewFieldIdsByObjectId(
        oldObjectsById,
        params.objectsById,
      );
      Object.keys(newFieldsByObjectId).forEach((id) => rowsToRebuild.add(id));

      // Search for objects added to Redux that were anticipated to be added.
      for (const objectId of this.newRowsToListenForToAddIndex.keys()) {
        const object = params.objectsById[objectId];
        if (object == null) {
          continue;
        }
        rowsToRebuild.add(objectId);
      }

      if (rowsToRebuild.size > 0) {
        this.rebuildRows({ rowsToRebuild: Array.from(rowsToRebuild) });

        const { rowData } = this;
        if (rowData) {
          for (const row of rowData) {
            if (!rowsToRebuild.has(row.id)) {
              continue;
            }

            // add the row to ag grid
            this.emit({
              type: 'change',
              add: [row],
              addIndex: this.newRowsToListenForToAddIndex.get(row.id) ?? 0,
              ...this.getAddItemButtonLoadingUpdate(row),
            });
            this.newRowsToListenForToAddIndex.delete(row.id);
          }
        }
      }
      if (Object.keys(newFieldsByObjectId).length > 0) {
        this.requestObjectFieldsAndDrivers({ fieldsByObjectId: newFieldsByObjectId });
      }
    }

    // When a driver is created lazily, we may need to recreate the row that the driver exists in.
    const newDriversByObjectId: Record<BusinessObjectId, DriverId[]> = {};
    this.unmatchedSubDriverIds.forEach((driverId) => {
      const objectId = dimensionalPropertyEvaluator.getBusinessObjectIdBySubDriverId(driverId);
      if (objectId != null) {
        if (objectId in newDriversByObjectId) {
          newDriversByObjectId[objectId].push(driverId);
        } else {
          newDriversByObjectId[objectId] = [driverId];
        }
      }
    });

    if (this.staleRowIds.size > 0) {
      const rowsToUpdate = Array.from(this.staleRowIds.values());

      const { rowIdsWithUpdates, metasChanged } = this.rebuildRows({
        rowsToRebuild: rowsToUpdate,
        // NOTE: we want to only skip updating values if we're using the backend,
        // as those will be handled by the `requestObjectRows` below
        preserveRowData: this.calculationEngine !== 'webworker',
      });

      // NOTE: in the non-webworker case, we want to update if there are any substantive changes
      const shouldUpdateNonWebworker =
        this.calculationEngine !== 'webworker' && rowIdsWithUpdates.size > 0;

      // NOTE: in the webworker case:
      // If some rows have metadata that have changed, emit an event to force the grid
      // to re-render those rows. Note that if calculation values change, the call
      // to requestObjectRows() below would already cause to those rows to be re-rendered.
      // So this is to specifically handle cases where only metadata has changed.
      const shouldUpdateWebworker = this.calculationEngine === 'webworker' && metasChanged >= 0;

      // NOTE: no need to rerender here if columns have changed since it will cause the whole table to rerender anyways
      if (shouldUpdateNonWebworker || shouldUpdateWebworker || !columnsChanged) {
        this.emit({
          type: 'change',
          forceRefreshRowIds: rowsToUpdate,
          update: this.rowData?.filter((row) => this.staleRowIds.has(row.id)),
        });
      }

      this.requestObjectRows({ type: 'row', rowIds: new Set(this.staleRowIds) });
      this.staleRowIds.clear();
    }

    // Propagate row rebuilds for objects with new drivers.
    const rowsToUpdate = Object.keys(newDriversByObjectId);
    if (rowsToUpdate.length > 0) {
      this.rebuildRows({ rowsToRebuild: rowsToUpdate });
      this.requestObjectFieldsAndDrivers({ driversByObjectId: newDriversByObjectId });

      Object.values(newDriversByObjectId).forEach((ids) =>
        ids.forEach((id) => this.unmatchedSubDriverIds.delete(id)),
      );
    }

    if (columnsChanged) {
      // Rebuild row shape.
      this.rebuildRows();

      // Immediately update all rows to ensure integrity after changing row shape.
      this.groups?.forEach((group, index) => {
        const bounds = this.groupBounds?.[index];
        const update =
          bounds != null ? this.rowData?.slice(bounds[0], bounds[0] + bounds[1] - 1) : this.rowData;
        this.emit({ type: 'change', update });
      });

      // When group by is applied, make sure to request the individual row groups.
      const { groupAttributeIds, groupBounds, blockConfig } = this;
      const groupByFieldId = blockConfig?.groupBy?.objectField?.businessObjectFieldId;
      if (
        groupByFieldId != null &&
        groupAttributeIds != null &&
        groupBounds != null &&
        groupAttributeIds.length > 0
      ) {
        groupBounds.forEach(([startIndex, groupSize], i) => {
          if (startIndex < this.furthestRow) {
            this.requestObjectRows({
              startRow: startIndex,
              endRow: startIndex + groupSize - 1,
              groupByAttributeId: groupAttributeIds[i],
              type: 'group',
            });
          }
        });
      } else {
        this.requestObjectRows({
          startRow: 0,
          endRow: this.furthestRow,
          type: 'row',
        });
      }
    }
  }

  public reset(): void {
    super.reset();
    DataArbiter.get().unregister(this);
    this.id = uuidv4();
    this.furthestRow = 0;
    this.groups = undefined;
    this.rowData = undefined;
    this.pendingRequests = new Map();
    this.groupBounds = undefined;
    this.groupAttributeIds = undefined;
    this.gridApi = undefined;
    this.newRowsToListenForToAddIndex.clear();
    this.loadingCreateObjectIdsToAddItemRow.clear();
    this.suppressRefreshOnLayerChange = false;
    DataArbiter.get().register(this);
  }

  public handleObjectRowsResponse(res: ObjectRowsResponse): void {
    // Do NOT delete object row requests from this map.
    // Results will stream in a non-deterministic order.
    // We will not know when the request is done.
    // Preseve the mapping of requestId to request metadata.
    const pending = res.requestId != null ? this.pendingRequests.get(res.requestId) : null;
    if (pending == null || pending.type !== 'row') {
      // Try handling as an update instead.
      this.handleObjectRowsUpdated(res);
      return;
    }

    const { startRow, endRow, rowIds } = pending;
    if ((startRow == null || endRow == null) && rowIds == null) {
      throw new Error(`Expected either startRow and endRow to be defined or rowIds to be defined`);
    }

    let rowData: DatabaseObjectRow[] | undefined;
    if (rowIds != null) {
      rowData = this.rowData?.filter((row) => rowIds?.has(row.id));
    } else {
      rowData = this.rowData?.slice(startRow, endRow);
    }

    if (rowData == null) {
      return;
    }

    const { objectsById } = this;
    if (objectsById == null) {
      return;
    }

    if (rowIds != null) {
      const attributeIdToRows = groupBy(rowData, 'attributeId');
      Object.entries(attributeIdToRows).forEach(([, rows]) => {
        // TODO: @ahmed figure out grouping for rows
        this.emit({ type: 'change', update: rows });
      });
    }

    const monthsByFieldId = this.extractMonthsByFieldId(res.values);
    const rowsToUpdate = this.extractRowsToUpdate(rowData, new Set(monthsByFieldId.keys()));

    const { rowIdsWithUpdates } = this.copyValuesToGivenRows(rowsToUpdate, monthsByFieldId);

    if (endRow != null && endRow > this.furthestRow) {
      this.furthestRow = endRow;
    }

    this.emit({ type: 'change', update: rowsToUpdate, forceRefreshRowIds: [...rowIdsWithUpdates] });
  }

  public handleBlockResponse(res: BlockResponse): void {
    // NOTE: we want to only respond to block changes on the layer we care about
    if (this.currentLayerId !== res.layerId) {
      return;
    }

    // Do not handle malformed groups. It is impossible to have no groups within an object block.
    if (res.groups.length === 0) {
      return;
    }

    // Handles copying response data to local state.
    // Do not move above checks above.
    super.handleBlockResponseHelper(res);

    const pending = this.pendingRequests.get(res.requestId);
    // If there is no pending request then this means that the backend sent
    // back a block eval udpate preemptively. Given that block eval responses
    // tend to cause the ordering of rows to change, we just rebuild the rows
    // entirely.
    //
    // The "refresh" event will cause `getRows` to fire which will:
    // (1) use the newly received block eval results that are now cached and
    // (2) request the calculation values as the set of rows has likely
    // changed.
    if (pending == null) {
      // NOTE: if we're just changing the width of the columns,
      // we don't wanna refresh the entire block.
      if (!this.doesBlockMatchRows(res)) {
        this.rebuildRows();
        this.emit({ type: 'refresh' });
      }
      return;
    }

    // In this case, we explicitly requested the data
    this.rebuildRows();
    if (this.rowData == null) {
      throw new Error(`Expected rowData to be built`);
    }

    const { startRow, endRow } = pending;
    if (startRow == null || endRow == null) {
      throw new Error(`Expected startRow and endRow to be defined`);
    }

    if (pending.success) {
      if ('groupByAttributeId' in pending && pending.groupByAttributeId != null) {
        const groups = this.getGroupRows(pending.groupByAttributeId);
        pending.success({
          rowData: groups,
          rowCount: groups.length,
        });

        // This is only an estimate of the number of rows to inform the
        // top-level grid about data scale for rendering behavior.
        const rowCount =
          this.groups?.reduce((count, group) => count + group.rows.filter(isObjectRow).length, 0) ??
          0;
        // This is the only function that should emit this type of event.
        this.emit({ type: 'rowCount', rowCount });
        return;
      }

      pending.success({
        rowData: this.rowData.slice(startRow, endRow),
        rowCount: this.rowData.length,
      });

      // This is the only function that should emit this type of event.
      this.emit({ type: 'rowCount', rowCount: this.rowData.length });
    }

    if (endRow > this.furthestRow) {
      this.furthestRow = endRow;
    }

    // Request row values.
    this.requestObjectRows({ ...pending, type: 'row' });
  }

  private doesBlockMatchRows(block: BlockResponse) {
    const rowIds = block.groups.flatMap((group) => {
      return group.rows.map((row) => {
        if (row.type === 'object') {
          return row.objectId;
        } else if (row.type === 'addItem') {
          return getAddItemRowId({ group: { ...group, isExpanded: false } });
        } else {
          throw new Error('Unknown row type');
        }
      });
    });

    return deepEqual(rowIds, this.getRowIds());
  }

  /**
   * This used to be a public function required by the IDataSource interface.
   * Surface area has been reduced due to a change in dataflow from DataArbiter.
   * TODO: folder this directly into handleObjectRowsResponse.
   */
  private handleObjectRowsUpdated(updated: ObjectRowsUpdated): void {
    const { rowData, objectsById } = this;

    if (rowData == null || objectsById == null || updated.values.length === 0) {
      return;
    }

    const monthsByFieldId = this.extractMonthsByFieldId(updated.values);
    const rowsToUpdate = this.extractRowsToUpdate(rowData, new Set(monthsByFieldId.keys()));

    // TODO prune if there are effectively no updates
    const { valuesChanged, metasChanged, rowIdsWithUpdates } = this.copyValuesToGivenRows(
      rowsToUpdate,
      monthsByFieldId,
    );

    if (valuesChanged > 0 || metasChanged > 0) {
      // Note: this changes the value `undefined` to the string literal 'undefined'
      this.emit({
        type: 'change',
        forceRefreshRowIds: [...rowIdsWithUpdates],
        update: rowsToUpdate,
      });
    }
  }

  public handleMutation(mutation: MutationBatch): void {
    this.handleMutationCommon(mutation);
  }

  public handleUndoneMutation(undoneMutation: MutationBatch): void {
    this.handleUndoneMutationCommon(undoneMutation);
  }

  protected updateStateForUndoneMutation(undoneMutation: MutationBatch) {
    const { newBusinessObjects, deleteBusinessObjects } = undoneMutation.mutation;
    // If we are undoing creating/deleting objects, refresh the grid to
    // show the correct rows. We should make this more efficient by only
    // refreshing the rows that were affected, by triggering the inverse
    // updates needed for the undone mutation. For example, when undoing a
    // create object, we should delete the row that was created by firing
    // removeObjectsMutationHook
    if (
      (newBusinessObjects != null && newBusinessObjects.length > 0) ||
      (deleteBusinessObjects != null && deleteBusinessObjects.length > 0)
    ) {
      this.reset();
      this.emit({ type: 'refresh' });
    }
    this.undoneMutation = undefined;
  }

  public getRows(params: IServerSideGetRowsParams<DatabaseObjectRow>): void {
    if (this.isDestroyed) {
      params.fail();
      return;
    }

    const { startRow, endRow, rowGroupCols, groupKeys } = params.request;
    if (startRow == null || endRow == null) {
      throw new Error(`Expected startRow and endRow`);
    }

    // If the block has not be resolved, do that first.
    if (this.rowData == null) {
      this.requestBlockHelper({
        startRow,
        endRow,
        groupByAttributeId: rowGroupCols.length > 0 ? rowGroupCols[0].id : undefined,
        success: params.success,
        fail: params.fail,
      });
      return;
    }

    // Handle pagination within a group.
    if (groupKeys.length > 0) {
      const [rowData, rowCount] = this.getGroup(groupKeys[0], startRow, endRow);
      params.success({
        rowData,
        rowCount,
      });
      this.requestGroupObjectRows({
        startRow,
        endRow,
        groupByAttributeId: groupKeys[0],
        type: 'group',
      });
      return;
    }

    // Handle row groups.
    if (rowGroupCols.length > 0) {
      const groups = this.getGroupRows(rowGroupCols[0].id);
      params.success({
        rowData: groups,
        rowCount: groups.length,
      });
      return;
    }

    // Request row range calculation data.
    this.requestObjectRows({
      startRow,
      endRow,
      type: 'row',
    });

    // Resolve specific row range with partial data immediately.
    params.success({
      rowData: this.rowData.slice(startRow, endRow),
      rowCount: this.rowData.length,
    });
  }

  public getGroupInfoForAttribute(attributeId: AttributeId): GroupInfo {
    return (
      this.groups?.find(({ groupInfo }) => groupInfo.attributeId === attributeId)?.groupInfo ??
      NONE_GROUP_INFO
    );
  }

  public setAddItemLoading({
    addItemRowId,
    newObjectId,
  }: {
    addItemRowId: DatabaseObjectRow['id'];
    newObjectId: string;
  }) {
    const row = this.rowData?.find(({ id }) => id === addItemRowId);
    if (row == null) {
      return;
    }

    row.loading = true;
    this.loadingCreateObjectIdsToAddItemRow.set(newObjectId, row);

    this.emit({
      type: 'change',
      update: [row],
      forceRefreshRowIds: [row.id],
      synchronous: true,
    });
  }

  public getGroupBoundsByRowIndex(rowIndex: number): [number, number] | undefined {
    return this.groupBounds?.find(([startIndex, size]) => {
      return rowIndex >= startIndex && rowIndex < startIndex + size;
    });
  }

  public getRowIndexById(id: string): number | undefined {
    return this.rowData?.findIndex((row) => row.id === id);
  }

  public getRowIds(): string[] {
    return this.rowData?.map((row) => row.id) ?? [];
  }

  private rebuildRows(params?: {
    rowsToRebuild?: BusinessObjectId[];
    preserveRowData?: boolean;
  }): ObjectRowsChanged {
    let rowsToRebuild: BusinessObjectId[] | undefined;
    let preserveRowData: boolean | undefined = false;
    if (params) {
      ({ preserveRowData, rowsToRebuild } = params);
    }
    const { objectSpec, groups } = this;

    if (objectSpec == null || groups == null) {
      return { valuesChanged: 0, metasChanged: 0, rowIdsWithUpdates: new Set() };
    }

    const flatColumnDefs = this.getFlatColumnDefs();

    const allColumnsNotEditables = flatColumnDefs.every(
      (def) => def.editable === undefined || def?.editable === false,
    );

    const canWriteDatabase =
      (this.accessCapabilities?.canWriteDatabase && !allColumnsNotEditables) ?? false;

    // Array of pairs containing the starting index and size of the group.
    const groupBounds = groups.reduce<Array<[number, number]>>((bounds, group, i) => {
      const size = canWriteDatabase ? group.rows.length + 1 : group.rows.length; // +1 here because for each group, there's an "addItem" row
      if (i === 0) {
        return [[0, size]];
      }

      const [lastStartIndex, lastSize] = bounds[i - 1];
      bounds.push([lastStartIndex + lastSize, size]);

      return bounds;
    }, []);
    this.groupBounds = groupBounds;

    // Map attributes by index. Necessary for pagination within groups.
    this.groupAttributeIds = groups.map(({ groupInfo }) => groupInfo.attributeId);

    const specId = objectSpec.id;
    this.rowData = this.rebuildRowsForGroups(
      specId,
      groups,
      this.rowData == null ? null : this.rowData,
      {
        rowsToRebuild,
        preserveRowData,
        hasAddItemButton: canWriteDatabase,
      },
    );

    return this.copyValuesToGivenRows(this.rowData, undefined, preserveRowData);
  }

  private rebuildRowsForGroups(
    specId: BusinessObjectSpecId,
    groups: GroupWithoutAddItem[],
    existingRows: DatabaseObjectRow[] | null,
    {
      rowsToRebuild,
      hasAddItemButton,
      preserveRowData = false,
    }: {
      rowsToRebuild?: string[];
      hasAddItemButton: boolean;
      preserveRowData?: boolean;
    },
  ): DatabaseObjectRow[] {
    const { columnDefinitions, driverPropertiesById, objectsWithDuplicateKeys } =
      this.getPropertiesForRowUpdates(specId);

    const updatedRows: DatabaseObjectRow[] = [];
    for (const group of groups) {
      const rows = hasAddItemButton ? [...group.rows, { type: 'addItem' as const }] : group.rows;
      for (const [index, rowOptions] of rows.entries()) {
        // NOTE: if we're rebuilding only specific rows, reuse the old row data of the ones we're not rebuilding
        const [existingRow, shouldReuseData] = this.getExistingRow(
          rowOptions,
          index,
          existingRows,
          rowsToRebuild,
        );
        if (shouldReuseData && existingRow != null) {
          updatedRows.push(existingRow);
          continue;
        }

        const object = this.getObjectFromRowOptions(rowOptions, specId, group);
        if (object == null) {
          continue;
        }

        const row: DatabaseObjectRow = generateEmptyRowData({
          objectId: object.id,
          objectSpecId: specId,
          groupAttributeId: group.groupInfo.attributeId,
          isIntegration: object.extKey != null,
          isObjectWithDuplicateKeys: objectsWithDuplicateKeys.has(object.id),
          comparisonType: 'comparisonType' in rowOptions ? rowOptions.comparisonType : undefined,
          layerId: 'layerId' in rowOptions ? rowOptions.layerId : undefined,
        });
        row.name = object.name;

        const objectFieldsByFieldSpecId = keyBy(object.fields, ({ fieldSpecId }) => fieldSpecId);
        for (const column of columnDefinitions) {
          row.data[column.colId] =
            preserveRowData && existingRow ? (existingRow.data[column.colId] ?? null) : null;
          row.rowKey[column.colId] = {
            objectId: object.id,
            groupKey: group.groupInfo.key ?? NONE_GROUP_INFO.attributeId,
          };

          if (!shouldSkipRow(rowOptions) && !shouldSkipColumn(column)) {
            const fieldSpecId = column.fieldSpec.objectFieldSpecId;
            // reuse the computed fieldId if it already exists to avoid expensive uuid generation
            row.fieldIdByFieldSpecId[fieldSpecId] =
              safeObjGet(row.fieldIdByFieldSpecId[fieldSpecId]) ??
              safeObjGet(objectFieldsByFieldSpecId[fieldSpecId])?.id ??
              getObjectFieldUUID(object.id, column.fieldSpec.objectFieldSpecId);
          }

          const driverProperty = driverPropertiesById[column.fieldSpec.objectFieldSpecId];
          if (driverProperty != null) {
            this.updateRowForDriverProperty(row, column, driverProperty);
          }

          // NOTE: per derrick, both of these should be refreshed in the copying phase
          // i'm going to punt on this for now since it's involved
          if (column.fieldSpec.valueType === 'formula') {
            this.updateRowForFormula(row, column);
          } else if (column.fieldSpec.backingType === 'dimension') {
            this.updateRowForDimension(row, column);
          }
        }

        updatedRows.push(row);
      }
    }

    return updatedRows;
  }

  private getPropertiesForRowUpdates(specId: BusinessObjectSpecId) {
    if (this.dimensionalPropertyEvaluator == null || this.objectSpec == null) {
      return {
        objectsWithDuplicateKeys: new Set<string>(),
        columnDefinitions: [],
        driverPropertiesById: {},
      };
    }

    return {
      objectsWithDuplicateKeys: new Set(
        this.dimensionalPropertyEvaluator.getObjectsWithDuplicateKeysForBusinessObjectSpec(specId),
      ),
      columnDefinitions: this.getFlatColumnDefs(),
      driverPropertiesById: fromPairs(
        (this.objectSpec.collection?.driverProperties ?? []).map((prop) => [prop.id, prop]),
      ),
    };
  }

  private getObjectFromRowOptions(
    options: Group['rows'][number],
    specId: BusinessObjectSpecId,
    group: Group,
  ) {
    let object: BusinessObject | undefined;
    if (isObjectRow(options)) {
      object = safeObjGet((this.objectsById ?? {})[options.objectId]);
    } else if (isAddItemRow(options)) {
      object = {
        id: getAddItemRowId({ group }),
        name: ADD_ITEM_TYPE,
        specId,
        fields: [],
      };
    }
    return object;
  }

  private getExistingRow(
    options: Group['rows'][number],
    index: number,
    existingRows: DatabaseObjectRow[] | null,
    rowsToRebuild?: string[],
  ): [DatabaseObjectRow | null, boolean] {
    const canAttemptToReuseData =
      existingRows != null && rowsToRebuild != null && options.type === 'object';

    if (canAttemptToReuseData) {
      const shouldReuseData = !rowsToRebuild.includes(options.objectId);
      if (existingRows[index]?.id === options.objectId) {
        return [existingRows[index], shouldReuseData];
      }

      const existingRow = existingRows.find((current) => current.id === options.objectId);
      if (existingRow != null) {
        return [existingRow, shouldReuseData];
      }
    }
    return [null, false];
  }

  private getCellLayerId(row: DatabaseObjectRow, column: ColumnDef) {
    return row.layerId ?? column.fieldSpec.layerId ?? this.currentLayerId;
  }

  private getCellComparisonType(row: DatabaseObjectRow, column: ColumnDef) {
    return row.comparisonType ?? column.fieldSpec.comparisonType;
  }

  private updateRowForDriverProperty(
    row: DatabaseObjectRow,
    column: ColumnDef,
    driverProperty: DriverProperty,
  ) {
    if (this.dimensionalPropertyEvaluator == null || this.driversByIdByLayerId == null) {
      return;
    }

    const attributes = this.dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(
      row.objectId,
    );

    row.subDriverMetaByColId[column.colId] = {
      // Copy the property ID so that subdrivers can be created if needed.
      driverPropertyId: driverProperty.id,
    };
    const layerId = this.getCellLayerId(row, column);
    const subDriverId = this.dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
      driverProperty.driverId,
      attributes.map((attr) => attr.attribute.id),
    );
    row.rowKey[column.colId] = {
      driverId: driverProperty.driverId,
      layerId,
      // There are no driver groups in databases
      groupId: undefined,
      subDriverId: subDriverId ?? undefined,
    };

    if (subDriverId != null) {
      const subDriver = safeObjGet(this.driversByIdByLayerId[layerId]?.[subDriverId]);
      row.subDriverMetaByColId[column.colId] = {
        driverPropertyId: driverProperty.id,
        subDriverId,
        // Use the dimensional driver to resolve the display configuration.
        // Resolving every subdriver is too slow to use at scale.
        subDriverDisplayConfiguration: this.getDriverDisplayConfiguration(driverProperty.driverId),
        actualsFormula:
          subDriver?.type === DriverType.Basic ? subDriver.actuals.formula : undefined,
      };
    }
  }

  private updateRowForFormula(row: DatabaseObjectRow, column: ColumnDef) {
    if (this.dimensionalPropertyEvaluator == null || this.driversByIdByLayerId == null) {
      return;
    }
    const attributes = this.dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(
      row.objectId,
    );
    const layerId = this.getCellLayerId(row, column);
    const subDriverId = row.subDriverMetaByColId[column.colId]?.subDriverId;
    const driver =
      subDriverId == null ? undefined : this.driversByIdByLayerId[layerId]?.[subDriverId];
    const shouldUseDriver = driver != null && driver.type === DriverType.Basic;

    const formulaKey = getDriverFormulaColId({
      objectFieldSpecId: column.fieldSpec.objectFieldSpecId,
      layerId: column.fieldSpec.layerId,
      formulaType: 'forecast',
    });
    const actualsFormulaKey = getDriverFormulaColId({
      objectFieldSpecId: column.fieldSpec.objectFieldSpecId,
      layerId: column.fieldSpec.layerId,
      formulaType: 'actuals',
    });

    const driverId = shouldUseDriver ? driver.id : uuidv4(); // this is the driver id that will be used when creating a new driver
    row.data[formulaKey] = {
      subDriverId: driverId,
      rawFormula: shouldUseDriver ? (driver.forecast.formula ?? null) : null,
      keyAttributes: attributes,
    };
    row.data[actualsFormulaKey] = {
      subDriverId: driverId,
      rawFormula: shouldUseDriver ? (driver.actuals.formula ?? null) : null,
      keyAttributes: attributes,
    };
    row.rowKey[formulaKey] = {
      driverId,
      layerId,
      // There are no driver groups in databases
      groupId: undefined,
    };
    row.rowKey[actualsFormulaKey] = row.rowKey[formulaKey];
  }

  private updateRowForDimension(row: DatabaseObjectRow, column: ColumnDef) {
    if (this.dimensionalPropertyEvaluator == null) {
      return;
    }

    const attributeProperty = this.dimensionalPropertyEvaluator.getAttributeProperty({
      dimensionalPropertyId: column.fieldSpec.objectFieldSpecId,
      objectId: row.objectId,
    });
    // NOTE: if the new value is blank, we wanna update this right away
    if (attributeProperty != null) {
      row.data[column.colId] = attributeProperty.attribute.id;
    } else {
      row.data[column.colId] = null;
    }
  }

  private requestObjectRows(params: RowRequestParams | GroupRequestParams): void {
    const rowData = this.rowData;

    if (rowData == null) {
      return;
    }

    const flatColumnDefs = this.getFlatColumnDefs();

    const objects: ObjectRowsRequest['objects'] = [];
    const { startRow, endRow, type } = params;

    let filteredRows: DatabaseObjectRow[] = [];

    if (type === 'row' && params.rowIds != null) {
      const { rowIds } = params;
      filteredRows = rowData.filter((existingRow) => rowIds.has(existingRow.id));
    } else if (startRow != null && endRow != null) {
      filteredRows = rowData.slice(startRow, endRow);
    } else {
      filteredRows = rowData;
    }

    for (const row of filteredRows) {
      const fieldIds: BusinessObjectFieldId[] = [];
      const transitionFieldIds: BusinessObjectFieldId[] = [];
      const driverIds: DriverId[] = [];
      const transitionDriverIds: DriverId[] = [];

      for (const def of flatColumnDefs) {
        if (def.fieldSpec.backingType === 'objectField') {
          const fieldId = row.fieldIdByFieldSpecId[def.fieldSpec.objectFieldSpecId];
          if (fieldId != null) {
            if (def.fieldSpec.displayAs === 'value') {
              transitionFieldIds.push(fieldId);
            } else {
              fieldIds.push(fieldId);
            }
          }
        } else if (def.fieldSpec.backingType === 'driver') {
          const driverId = row.subDriverMetaByColId[def.colId]?.subDriverId;
          if (driverId != null) {
            if (def.fieldSpec.displayAs === 'value') {
              transitionDriverIds.push(driverId);
            } else {
              driverIds.push(driverId);
            }
          }
        }
      }

      objects.push({
        fieldIds: uniq(fieldIds),
        driverIds: uniq(driverIds),
        transitionFieldIds: uniq(transitionFieldIds),
        transitionDriverIds: uniq(transitionDriverIds),
      });
    }

    super.requestObjectsHelper(params, objects);
  }

  private requestObjectFieldsAndDrivers({
    fieldsByObjectId,
    driversByObjectId,
  }: {
    fieldsByObjectId?: Record<BusinessObjectId, BusinessObjectFieldId[]>;
    driversByObjectId?: Record<BusinessObjectId, DriverId[]>;
  }): void {
    const dateRange = this.dateRange;
    if (dateRange == null) {
      return;
    }

    const objectsByObjectId: Record<BusinessObjectId, ObjectRowsRequest['objects'][0]> = {};

    for (const [objectId, fieldIds] of Object.entries(fieldsByObjectId ?? {})) {
      objectsByObjectId[objectId] = {
        fieldIds,
        driverIds: [],
        transitionFieldIds: [],
        transitionDriverIds: [],
      };
    }

    for (const [objectId, driverIds] of Object.entries(driversByObjectId ?? {})) {
      objectsByObjectId[objectId] ??= {
        fieldIds: [],
        driverIds: [],
        transitionFieldIds: [],
        transitionDriverIds: [],
      };
      objectsByObjectId[objectId].driverIds = driverIds;
    }

    const objects = Object.values(objectsByObjectId);

    if (objects.length === 0) {
      return;
    }

    [this.currentLayerId, ...this.comparisonLayerIds].forEach((layerId) => {
      const request: ObjectRowsRequest = {
        instanceId: this.instanceId,
        objects,
        layerId,
        dateRange,
        calculator: this.calculationEngine,
      };

      // Intentionally do not put put requestId into `requests`.
      // This will force the handler to treat it as an async update instead of bulk request.
      DataArbiter.get().requestObjectRows(request);
    });
  }

  private requestGroupObjectRows(params: GroupRequestParams): void {
    const [offset, rowCount] = this.getGroupOffsetAndRowCount(params.groupByAttributeId);
    if (offset === -1) {
      if (params.fail != null) {
        params.fail();
      }
      return;
    }
    const { startRow, endRow } = params;
    if (startRow == null || endRow == null) {
      throw new Error(`Expected startRow and endRow`);
    }

    // Construct a request for row calculation for the proper row range.
    const adjustedStartRow = offset + startRow;
    const adjustedEndRow = offset + (rowCount < endRow ? rowCount : endRow);
    this.requestObjectRows({
      startRow: adjustedStartRow,
      endRow: adjustedEndRow,
      groupByAttributeId: params.groupByAttributeId,
      type: 'group',
    });
  }

  private getGroupRows(colId: string): DatabaseObjectRow[] {
    const objectSpecId = this.objectSpec?.id;
    if (objectSpecId == null) {
      return [];
    }

    return (
      this.groups
        ?.map((group) => {
          if (group.groupInfo.groupingType === 'attributeObjectField') {
            const { key, attributeId } = group.groupInfo;
            return {
              id: key,
              name: key,
              objectId: '',
              objectSpecId,
              isIntegration: false,
              subDriverMetaByColId: {},
              data: {
                [colId]: attributeId,
              },
              rowKey: {},
              actualsFormula: undefined,
              hardCodedActuals: new Set<MonthKey>(),
              cellImpactsByMonthKey: {},
              fieldIdByFieldSpecId: {},
              groupAttributeId: attributeId,
              loading: false,
              type: 'objectRow' as const,
              isObjectWithDuplicateKeys: false,
            };
          }

          return null;
        })
        .filter(isNotNull) ?? []
    );
  }

  private getGroup(attributeId: AttributeId, startRow: number, endRow: number): RowGroup {
    const [offset, rowCount] = this.getGroupOffsetAndRowCount(attributeId);
    if (offset === -1) {
      return EMPTY_ROW_GROUP;
    }

    const rowData =
      this.rowData?.slice(offset + startRow, offset + (rowCount < endRow ? rowCount : endRow)) ??
      [];

    if (offset + endRow > this.furthestRow) {
      this.furthestRow = offset + endRow;
    }

    return [rowData, rowCount];
  }

  private getGroupOffsetAndRowCount(attributeId: AttributeId): [number, number] {
    const { groups, groupBounds } = this;
    if (groups == null || groupBounds == null) {
      return [-1, 0];
    }

    const index = this.groupAttributeIds?.findIndex((id) => id === attributeId);
    if (index == null || index === -1) {
      return [-1, 0];
    }

    const rowCount = groupBounds[index][1];
    const offset = groupBounds.reduce(
      (count, bounds, i) => (i < index ? count + bounds[1] : count),
      0,
    );

    return [offset, rowCount];
  }

  // We may get updates for a row that are incomplete. This function relies
  // on being told which months per entity we are actually updating values for
  // during this specific call (or, if we are updating everything).
  private copyValuesToGivenRows(
    rowsToUpdate: DatabaseObjectRow[],
    updatedMonthsForEntity?: Map<BusinessObjectFieldId, Set<MonthKey>>,
    preserveRowData: boolean = false,
  ): ObjectRowsChanged {
    const { dateRange, objectSpec, objectsById, driversByIdByLayerId } = this;
    if (
      dateRange == null ||
      objectSpec == null ||
      objectsById == null ||
      driversByIdByLayerId == null
    ) {
      return { valuesChanged: 0, metasChanged: 0, rowIdsWithUpdates: new Set() };
    }

    const flatColumnDefs = this.getFlatColumnDefs();
    const arbiter = DataArbiter.get();

    let valuesChanged = 0;
    let metasChanged = 0;

    const shouldSkipMonth = (entityId: string, mk: MonthKey): boolean => {
      if (updatedMonthsForEntity == null) {
        return false;
      }
      const monthsForEntity = updatedMonthsForEntity.get(entityId);
      return monthsForEntity == null || !monthsForEntity.has(mk);
    };

    const monthKeysInRange = getMonthKeysForRange(dateRange[0], dateRange[1]);
    const monthKeysInRangeSet = new Set(monthKeysInRange);

    const rowIdsWithUpdates = new Set<string>();
    for (const row of rowsToUpdate) {
      // This is a comparison row and will be handled in updateComparisonRows
      if (row.comparisonType != null) {
        continue;
      }
      const object = safeObjGet(objectsById[row.objectId]);
      if (object == null) {
        continue;
      }

      for (const def of flatColumnDefs) {
        if (shouldSkipColumn(def)) {
          continue;
        }
        // This is a comparison column and will be handled in updateComparisonRows
        if (def.fieldSpec.comparisonType != null) {
          continue;
        }
        const layerId = row.layerId ?? def.fieldSpec.layerId ?? this.currentLayerId;

        if (def.fieldSpec.displayAs === 'timeseries' && def.fieldSpec.monthKey != null) {
          const oldValue = row.data[def.colId];
          let id: FormulaEntityTypedId['id'];
          let hardCodedActual: string | number | undefined;
          const monthKey = def.fieldSpec.monthKey;
          const useActuals =
            this.lastActualsMonthKey != null && shouldUseActual(this.lastActualsMonthKey, monthKey);

          if (def.colId in row.subDriverMetaByColId) {
            const subdriverId = row.subDriverMetaByColId[def.colId]?.subDriverId;
            if (subdriverId == null) {
              continue;
            }

            const driver = safeObjGet(driversByIdByLayerId[layerId]?.[subdriverId]);
            if (driver == null) {
              continue;
            }

            id = subdriverId;
            if (
              useActuals &&
              driver.type === DriverType.Basic &&
              driver.actuals.timeSeries?.[monthKey] != null
            ) {
              hardCodedActual = driver.actuals.timeSeries[monthKey];
            }
          } else {
            const fieldId = row.fieldIdByFieldSpecId[def.fieldSpec.objectFieldSpecId];
            if (fieldId == null) {
              continue;
            }

            id = fieldId;

            const field = object.fields.find((f) => f.id === fieldId);
            const actualsValue = field?.value?.actuals.timeSeries?.[monthKey];
            if (useActuals) {
              const fieldSpec = this.objectSpec?.fields?.find((f) => f.id === field?.fieldSpecId);
              const extObjectSpecs = (this.objectSpec?.extSpecKeys ?? [])
                .map((key) => this.extObjectSpecsByKey?.[key])
                .filter(isNotNull);
              const hasHardCodedActual = actualsValue != null;
              if (hasHardCodedActual) {
                hardCodedActual = actualsValue.value;
              } else if (
                fieldSpec != null &&
                (fieldSpec.propagateIntegrationData ||
                  extObjectSpecs.some((spec) => spec.propagateDataForward))
              ) {
                // very regrettably, this is a duplicate of the logic in `mapBusinessObjectField` in `FormulaEvaluatorHelpers`.
                // we should explore a better way to handle both cases; the duplication here is a consequence of the
                // fact that we duplicate all logic for pulling actuals
                const linkedExtObject =
                  object.extKey != null ? this.extObjectsByExtKey?.[object.extKey] : null;
                const linkedExtObjectField = linkedExtObject?.fields?.find(
                  (f) => f.extFieldSpecKey === fieldSpec.extFieldSpecKey,
                );
                if (linkedExtObjectField && !isEmpty(linkedExtObjectField.timeSeries)) {
                  const linkedTimeSeries = linkedExtObjectField.timeSeries;
                  const integrationMonthKeys = Object.keys(linkedTimeSeries).sort();
                  let mk = monthKey;
                  let setValue = false;
                  while (mk >= integrationMonthKeys[0]) {
                    const linkedValue = linkedTimeSeries[mk];
                    const actualsTsValue = field?.value?.actuals.timeSeries?.[mk];
                    if (linkedValue != null || actualsTsValue != null) {
                      row.data[def.colId] = actualsTsValue?.value ?? linkedValue?.value;
                      ++metasChanged;
                      rowIdsWithUpdates.add(row.id);
                      setValue = true;
                      break;
                    }

                    mk = previousMonthKey(mk);
                  }

                  if (setValue) {
                    continue;
                  }
                }
              }
            }
          }

          if (shouldSkipMonth(id, monthKey)) {
            continue;
          }

          // Add curve points to row
          const events = this.eventsByEntityId?.[id] ?? [];
          const curvePointsByMonthKey: NullableRecord<MonthKey, CurvePointWithEventMetadata[]> = {};
          events.forEach(({ id: eventId, parentId: eventGroupId, customCurvePoints, impactType }) =>
            Object.entries(customCurvePoints ?? {}).forEach(([mk, value]) => {
              if (!monthKeysInRangeSet.has(mk)) {
                return;
              }

              const newCurvePoint: CurvePointWithEventMetadata = {
                curvePoint: { ...value, impactType },
                eventId,
                eventGroupId: eventGroupId ?? null,
              };

              curvePointsByMonthKey[mk] ??= [];
              curvePointsByMonthKey[mk]?.push(newCurvePoint);
            }),
          );
          const cellImpactsByMonthKey = mapValues(
            curvePointsByMonthKey,
            (curvePoints) => getCellImpactFromCurvePoints(curvePoints ?? []) ?? undefined,
          );
          if (!isEqual(row.cellImpactsByMonthKey, cellImpactsByMonthKey)) {
            row.cellImpactsByMonthKey = cellImpactsByMonthKey;
            ++metasChanged;
            rowIdsWithUpdates.add(row.id);
          }

          if (hardCodedActual != null) {
            row.data[def.colId] = hardCodedActual;
            row.hardCodedActuals.add(def.fieldSpec.monthKey);
            ++metasChanged;
            rowIdsWithUpdates.add(row.id);
            continue;
          }

          row.hardCodedActuals.delete(def.fieldSpec.monthKey);

          const newValueOrErr = arbiter.getCachedValue({
            id,
            monthKey: def.fieldSpec.monthKey,
            layerId,
          });

          const newValue = isCalculationValue(newValueOrErr) ? newValueOrErr.value : newValueOrErr;

          if (!preserveRowData && !isEqual(oldValue, newValue)) {
            ++valuesChanged;
            rowIdsWithUpdates.add(row.id);
          }

          row.data[def.colId] = newValue == null ? null : newValue;
        } else {
          // NOTE: this path handles displaying values
          let timeseries = null;
          try {
            timeseries = this.getTimeseries(row, def);
          } catch (error) {
            if (error instanceof InvalidTimeSeriesFormatError) {
              continue;
            }
            throw error;
          }

          const id =
            row.subDriverMetaByColId[def.colId]?.subDriverId ??
            row.fieldIdByFieldSpecId[def.fieldSpec.objectFieldSpecId];
          if (id == null) {
            continue;
          }

          const monthKeys = monthKeysInRange.filter((mk) => !shouldSkipMonth(id, mk));
          // Skip columns for this row with no applicable month keys present.
          // If the code below runs without any month keys specified,
          // the columns will be cleared by the first-last logic.
          if (monthKeys.length === 0) {
            continue;
          }

          const oldValue = row.data[def.colId];
          const newValue = this.getNewValue(monthKeysInRange, timeseries, id, layerId);

          // flatten deals with Array vs string/number type and filter(isNotNull) deals with null/undefined
          if (
            !preserveRowData &&
            !isEqual([oldValue].flat().filter(isNotNull), [newValue].flat().filter(isNotNull))
          ) {
            row.data[def.colId] = newValue;
            ++valuesChanged;
            rowIdsWithUpdates.add(row.id);
          }
        }
      }
    }

    const {
      rowIdsWithUpdates: comparisonRowIdsWithUpdates,
      valuesChanged: comparisonValuesChanged,
      metasChanged: comparisonMetasChanged,
    } = this.updateComparisonRows();
    comparisonRowIdsWithUpdates.forEach((id) => rowIdsWithUpdates.add(id));
    return {
      valuesChanged: valuesChanged + comparisonValuesChanged,
      metasChanged: metasChanged + comparisonMetasChanged,
      rowIdsWithUpdates,
    };
  }

  private updateComparisonRows(): ObjectRowsChanged {
    let valuesChanged = 0;
    const metasChanged = 0;
    const rowIdsWithUpdates = new Set<string>();
    if (this.comparisonLayerIds.length === 0) {
      return { valuesChanged, metasChanged, rowIdsWithUpdates };
    }
    const { rowData, objectsById } = this;
    if (rowData == null || objectsById == null) {
      return { valuesChanged, metasChanged, rowIdsWithUpdates };
    }
    const colDefs = this.getFlatColumnDefs();
    const rowByRowId = keyBy(rowData, 'id');

    for (const comparisonRow of rowData) {
      const object = safeObjGet(objectsById[comparisonRow.objectId]);
      if (object == null) {
        continue;
      }

      for (const def of colDefs) {
        if (shouldSkipColumn(def)) {
          continue;
        }

        const monthKey = def.fieldSpec.monthKey;
        // For now we are only doing comparisons for the timeseries view columns
        if (def.fieldSpec.displayAs !== 'timeseries' || monthKey == null) {
          continue;
        }

        // This is the current layer value row
        // We are using the objectId as the rowId because the base row's rowId matches the objectId
        const baseRow = safeObjGet(rowByRowId[comparisonRow.objectId]);
        if (baseRow == null) {
          continue;
        }

        const comparisonType = this.getCellComparisonType(comparisonRow, def);
        // This is the `value` row/col which was already filled - skip
        if (comparisonType == null) {
          continue;
        }

        const layerId = def.fieldSpec.layerId ?? comparisonRow.layerId;

        if (layerId == null) {
          // current layer doesn't have any values in the comparison fields
          continue;
        }

        // In the Column view all the values live the base row at the current column
        // In the Row view all the values live in this column
        const baseColumnKey = getDriverColId({
          objectFieldSpecId: def.fieldSpec.objectFieldSpecId,
          monthKey,
        });
        const baseRowValue = baseRow.data[baseColumnKey];

        const baseRowValueNumber =
          typeof baseRowValue === 'string'
            ? parseFloat(baseRowValue)
            : typeof baseRowValue === 'number'
              ? baseRowValue
              : null;
        const rowValue =
          this.comparisonLayout === BlockComparisonLayout.Columns
            ? baseRow.data[def.colId]
            : comparisonRow.data[baseColumnKey];

        const rowValueFloat =
          typeof rowValue === 'string'
            ? parseFloat(rowValue)
            : typeof rowValue === 'number'
              ? rowValue
              : null;

        const oldValue = comparisonRow.data[def.colId];
        if (baseRowValueNumber == null || rowValueFloat == null) {
          if (oldValue == null) {
            continue;
          }
          comparisonRow.data[def.colId] = undefined;
          ++valuesChanged;
          rowIdsWithUpdates.add(comparisonRow.id);
          continue;
        }
        if (isValidComparisonType(comparisonType)) {
          const newValue = getValueForColumn({
            rowValue: rowValueFloat,
            baselineValue: baseRowValueNumber,
            column: comparisonType,
          });
          if (oldValue === newValue) {
            continue;
          }
          comparisonRow.data[def.colId] = newValue;
          ++valuesChanged;
          rowIdsWithUpdates.add(comparisonRow.id);
          continue;
        }
      }
    }

    return { valuesChanged, metasChanged, rowIdsWithUpdates };
  }

  private getTimeseries(
    row: DatabaseObjectRow,
    column: ColumnDef,
  ): NumericTimeSeries | NonNumericTimeSeries | ValueTimeSeries | null {
    if (column.colId in row.subDriverMetaByColId) {
      const subdriverId = row.subDriverMetaByColId[column.colId]?.subDriverId;
      return this.getDriverTimeseries(subdriverId);
    } else {
      return this.getObjectTimeseries(row, column);
    }
  }

  private getDriverTimeseries(subdriverId: string | undefined) {
    if (this.driversByIdByLayerId == null) {
      throw new InvalidTimeSeriesFormatError(
        'Expected there to be drivers by id for a layer, but found none',
      );
    }

    if (subdriverId == null) {
      throw new InvalidTimeSeriesFormatError('Expected a subdriver id, but found none');
    }

    const driver = safeObjGet(this.driversByIdByLayerId[this.currentLayerId]?.[subdriverId]);
    if (driver == null) {
      throw new InvalidTimeSeriesFormatError(
        `Expected a subdriver for id ${subdriverId}, but found none`,
      );
    }

    if (driver.type !== DriverType.Basic || driver.actuals.timeSeries == null) {
      return null;
    }
    return driver.actuals.timeSeries ?? null;
  }

  private getObjectTimeseries(row: DatabaseObjectRow, column: ColumnDef) {
    if (this.objectsById == null) {
      throw new InvalidTimeSeriesFormatError('Expected there to be objects by id, but found none');
    }

    const object = safeObjGet(this.objectsById[row.objectId]);
    if (object == null) {
      throw new InvalidTimeSeriesFormatError(
        `Expected there to be an object with id ${row.objectId}, but found none`,
      );
    }
    const fieldId = row.fieldIdByFieldSpecId[column.fieldSpec.objectFieldSpecId];
    const field = object.fields.find((f) => f.id === fieldId);

    return field?.value?.actuals.timeSeries ?? null;
  }

  private getNewValue(
    monthKeysInRange: string[],
    timeseries: NumericTimeSeries | NonNumericTimeSeries | ValueTimeSeries | null,
    entityId: string,
    layerId: LayerId,
  ): DatabaseObjectRow['data'][string] {
    return getNewValue(monthKeysInRange, (monthKey: string) => {
      return this.resolveValueForMonthKey(monthKey, timeseries, entityId, layerId);
    });
  }

  private resolveValueForMonthKey(
    monthKey: string,
    timeseries: NumericTimeSeries | NonNumericTimeSeries | ValueTimeSeries | null,
    entityId: string,
    layerId: LayerId,
  ): string | number | CalculationError | undefined {
    const value = timeseries != null ? timeseries[monthKey] : undefined;
    if (value != null) {
      if (typeof value === 'string' || typeof value === 'number') {
        return value;
      }

      return value.value;
    }

    const arbiter = DataArbiter.get();
    const valOrErr = arbiter.getCachedValue({
      id: entityId,
      monthKey,
      layerId,
    });

    return isCalculationValue(valOrErr) ? valOrErr.value : valOrErr;
  }

  private extractRowsToUpdate(
    rowData: DatabaseObjectRow[],
    fieldIds: Set<string>,
  ): DatabaseObjectRow[] {
    const { objectsById, objectSpec } = this;
    if (objectsById == null || objectSpec == null) {
      return [];
    }

    // Keep this as imperative as possible. i.e. avoid .forEach()
    const rows = [];

    for (const row of rowData) {
      const { subDriverMetaByColId } = row;

      if (
        Object.values(subDriverMetaByColId).some(
          (meta) => meta?.subDriverId != null && fieldIds.has(meta.subDriverId),
        )
      ) {
        rows.push(row);
        continue;
      }

      if (isAddItemRowId(row.id)) {
        continue;
      }

      const object = safeObjGet(objectsById[row.objectId]);
      if (object == null) {
        continue;
      }

      if (
        objectSpec.fields
          .map((fieldSpec) => row.fieldIdByFieldSpecId[fieldSpec.id])
          .some((fieldId) => fieldIds.has(fieldId))
      ) {
        rows.push(row);
      }
    }

    return rows;
  }

  private getFlatColumnDefs(): ColumnDef[] {
    const { objectSpec } = this;
    if (objectSpec == null) {
      return [];
    }

    return (
      this.columnDefs?.flatMap((def) => {
        return this.getFlatColumnDefsHelper(def);
      }) ?? []
    );
  }

  private getFlatColumnDefsHelper(colDef: ColumnDef | ColumnGroupDef): ColumnDef[] {
    if ('children' in colDef) {
      return (colDef.children as Array<ColumnDef | ColumnGroupDef>).flatMap((childDef) =>
        'children' in childDef ? this.getFlatColumnDefsHelper(childDef) : childDef,
      );
    }
    return [colDef];
  }

  protected addObjectsMutationHook(objectIds: BusinessObjectId[], groupIndex: number): void {
    if (this.groups == null || this.groups.length === 0) {
      return;
    }

    // TODO: redo this
    // add rows to group
    const addIndex = this.groups[groupIndex].rows.length;
    this.groups[groupIndex].rows = [
      ...this.groups[groupIndex].rows,
      ...objectIds.map((objectId) => ({ type: 'object' as const, objectId })),
    ];

    // TODO: update group bounds

    // objectsById has not yet been updated, wait for that to go through selectors updateState
    // and then emit the change into ag grid
    objectIds.forEach((id, idx) => {
      this.newRowsToListenForToAddIndex.set(id, idx + addIndex);
    });
  }

  protected updateObjectsMutationHook(objectIds: string[]): void {
    objectIds.forEach((id) => this.staleRowIds.add(id));
  }

  protected removeObjectsMutationHook(objectIds: BusinessObjectId[]): void {
    // remove rows from this.rowData
    this.rowData = this.rowData?.filter((row) => !objectIds.includes(row.objectId));

    if (this.groups == null || this.groupBounds == null) {
      return;
    }

    // remove rows from groups/groupBounds & emit change event per group
    const groupIndexesToDelete: number[] = [];
    for (let i = 0; i < (this.groups != null ? this.groups.length : 0); i++) {
      const group = this.groups[i];
      const [removedRows, remainingRows] = partition(group.rows, (row) => {
        return row.type === 'object' && objectIds.includes(row.objectId);
      });

      if (removedRows.length === 0) {
        continue;
      }
      group.rows = remainingRows;

      const totalRemoveCount = removedRows.length;
      for (let j = i; j < this.groupBounds.length; j++) {
        const [startIndex, size] = this.groupBounds[i];
        if (i === j) {
          // size - 1 for add item row
          if (totalRemoveCount >= size - 1) {
            groupIndexesToDelete.push(i);
            this.groupBounds[i] = [startIndex, 0];
          } else {
            this.groupBounds[i] = [startIndex, size - totalRemoveCount];
          }
        } else {
          this.groupBounds[i] = [startIndex - totalRemoveCount, size];
        }
      }

      this.emit({
        type: 'change',
        remove: (removedRows as Array<{ objectId: string }>).map(({ objectId }) => ({
          id: objectId,
          groupAttributeId: group.groupInfo.attributeId,
        })),
      });
    }

    if (groupIndexesToDelete.length > 0) {
      this.groups = this.groups.filter((_, idx) => !groupIndexesToDelete.includes(idx));
      this.groupBounds = this.groupBounds.filter((_, idx) => !groupIndexesToDelete.includes(idx));

      // If all groups are removed, we need to make sure to create the default
      // NONE_GROUP, so that newly added objects have a place to go.
      //
      // TODO(T-21328): Fix related issue with Group By and Add Objects.
      // If there is a Group By applied and we delete all the objects in that group,
      // and then somebody creates an object with that group attribute, it will not show up
      // since the group will not exist. To fix this, when processing addObjectsMutationHook()
      // we need to check if the group exists and if not, create it.
      if (this.groups.length === 0) {
        this.groups = [{ groupInfo: NONE_GROUP_INFO, rows: [], isExpanded: false }];
        this.groupBounds = [[0, 0]];
      }
    }
  }

  protected addSubDriversMutationHook(driverIds: string[]): void {
    driverIds.forEach((id) => this.unmatchedSubDriverIds.add(id));
  }

  protected updateSubDriversMutationHook(): void {}

  protected updateObjectSpecMutationHook(): void {
    if (this.rowData == null) {
      return;
    }

    // Fairly brute force way to refresh all rows.
    const rows = this.rowData.filter((r) => isAddItemRowId(r.id));
    for (const row of rows) {
      if (!isAddItemRowId(row.id)) {
        this.staleRowIds.add(row.id);
      }
    }
  }

  public get isGridGrouped(): boolean {
    const isNotGrouped =
      this.groups == null ||
      (this.groups.length === 1 && this.groups[0].groupInfo.groupingType === EMPTY_ATTRIBUTE_ID);

    return !isNotGrouped;
  }

  private getAddItemButtonLoadingUpdate(
    newObjectRow: DatabaseObjectRow,
  ): Pick<DataSourceChangeEvent, 'update' | 'forceRefreshRowIds'> | null {
    const addItemRow = this.loadingCreateObjectIdsToAddItemRow.get(newObjectRow.id);
    if (addItemRow == null) {
      return null;
    }

    this.loadingCreateObjectIdsToAddItemRow.delete(newObjectRow.id);

    // If multiple objects are being created at once, we don't want to remove the loading state
    // until all objects have been created
    const loadingButtonRowIds = [...this.loadingCreateObjectIdsToAddItemRow.values()].map(
      ({ id }) => id,
    );
    const isStillLoading = loadingButtonRowIds.includes(addItemRow.id);

    if (isStillLoading) {
      return null;
    }

    addItemRow.loading = false;

    return {
      update: [addItemRow],
      // AG Grid change detection doesn't automatically re-render this cell, since the actual value doesn't change
      // so we need to force a refresh for component to receive the new `loading` property.
      forceRefreshRowIds: [addItemRow.id],
    };
  }
}
