import { useCallback, useMemo } from 'react';

import DimensionSubmenu from 'components/BusinessObjectTable/DimensionSubmenu';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem, SelectMenuItemWithSelect } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import {
  DATABASE_PROPERTY_TYPE_ICONS,
  DATABASE_PROPERTY_TYPE_NAMES,
  DatabaseGroupKey,
} from 'config/businessObjects';
import { DRIVER_FORMATS, DRIVER_FORMAT_ICONS, DRIVER_FORMAT_NAMES } from 'config/drivers';
import { DriverFormat, ValueType } from 'generated/graphql';
import { safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useCurrencySelectMenuItem } from 'hooks/useCurrencySelectMenuItem';
import { useDecimalPlacesSelectMenuItem } from 'hooks/useDecimalPlacesSelectMenuItem';
import {
  createNewDimensionForObjectSpecField,
  updateBusinessObjectSpec,
  updateCurrency,
  updateDatabasePropertyType,
  updateDecimalPlaces,
  updateNumericFormat,
} from 'reduxStore/actions/businessObjectSpecMutations';
import { createDimension } from 'reduxStore/actions/dimensionMutations';
import { FormatType, trackEditFormatEvent } from 'reduxStore/actions/trackEvent';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
  isNumericFieldSpec,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DimensionId } from 'reduxStore/models/dimensions';
import { setEditingPropertyFormula } from 'reduxStore/reducers/blockLocalStateSlice';
import {
  businessObjectFieldSpecSelector,
  businessObjectFieldsExistForFieldSpecIdSelector,
  businessObjectFieldsForFieldSpecIdAndLayerSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSpecSelector } from 'selectors/businessObjectSpecsSelector';
import { dimensionsByIdSelector, dimensionsForLayerSelector } from 'selectors/dimensionsSelector';
import {
  fieldSpecDecimalSelector,
  fieldSpecDisplayConfigurationSelector,
} from 'selectors/entityDisplayConfigurationSelector';
import CupcakeIcon from 'vectors/Cupcake';
import FormulaIcon from 'vectors/Formula';

const VALID_PROPERTY_VALUE_TYPES = [ValueType.Attribute, ValueType.Number, ValueType.Timestamp];

function useValueTypeSelectItem({
  objectSpecId,
  fieldSpecId,
  currentType,
}: {
  objectSpecId: BusinessObjectSpecId;
  fieldSpecId: BusinessObjectFieldSpecId;
  currentType: ValueType;
}): SelectMenuItemWithSelect {
  const fieldsExistForSpec = useAppSelector((state) =>
    businessObjectFieldsExistForFieldSpecIdSelector(state, fieldSpecId),
  );
  const canEditField = !fieldsExistForSpec;
  const allDimensions = useAppSelector(dimensionsForLayerSelector);
  const firstDimension = allDimensions[0];
  const dispatch = useAppDispatch();

  const item = useMemo(() => {
    const onSelect = ({ databasePropertyType }: ValueTypeItem) => {
      const hasNoDimensions = firstDimension == null;
      const newDimensionId = uuidv4();
      // NB: make a new dimension if there are none if the user chose dimension.
      if (databasePropertyType === ValueType.Attribute && hasNoDimensions) {
        dispatch(createDimension({ id: newDimensionId, name: 'New dimension' }));
      }

      dispatch(
        updateDatabasePropertyType({
          objectSpecId,
          fieldSpecId,
          newType: databasePropertyType,
          dimensionId: hasNoDimensions ? newDimensionId : firstDimension.id,
        }),
      );
    };

    type ValueTypeItem = SelectItem & { databasePropertyType: ValueType };
    const formatItems = VALID_PROPERTY_VALUE_TYPES.map<ValueTypeItem>((vt) => ({
      id: vt,
      name: DATABASE_PROPERTY_TYPE_NAMES[vt],
      isChecked: currentType === vt,
      databasePropertyType: vt,
      icon: DATABASE_PROPERTY_TYPE_ICONS[vt],
    }));

    return {
      id: 'valueType',
      name: 'Value type',
      icon: DATABASE_PROPERTY_TYPE_ICONS[currentType],
      meta: DATABASE_PROPERTY_TYPE_NAMES[currentType],
      isDisabled: !canEditField,
      hasNextMenu: canEditField,
      submenu: () => (
        <SelectMenuContainer testId="value-type-dropdown">
          <SelectMenu items={formatItems} onSelect={onSelect} width="10rem" startFocusIdx={-1}>
            {BaseSelectMenuItem}
          </SelectMenu>
        </SelectMenuContainer>
      ),
    };
  }, [canEditField, currentType, firstDimension, dispatch, fieldSpecId, objectSpecId]);

  return item;
}

export const useCustomizePropertyItems = ({
  blockId,
  objectSpecId,
  fieldSpecId,
  groupKey,
  objectId,
}: {
  blockId: BlockId;
  objectSpecId: BusinessObjectSpecId;
  fieldSpecId: BusinessObjectFieldSpecId;
  groupKey?: DatabaseGroupKey;
  objectId?: BusinessObjectId;
}) => {
  const fieldSpec = useAppSelector((state) =>
    businessObjectFieldSpecSelector(state, { id: fieldSpecId }),
  );
  const dispatch = useAppDispatch();
  const businessObjectFieldsForSpecId = useAppSelector((state) =>
    businessObjectFieldsForFieldSpecIdAndLayerSelector(state, { fieldSpecId }),
  );
  const businessObjectSpec = useAppSelector((state) =>
    businessObjectSpecSelector(state, objectSpecId),
  );
  const isStartField = businessObjectSpec?.startFieldId === fieldSpecId;

  const valueTypeSelectItem = useValueTypeSelectItem({
    objectSpecId,
    fieldSpecId,
    currentType: fieldSpec != null ? fieldSpec.type : ValueType.Number,
  });
  const canEditDimension = businessObjectFieldsForSpecId.length === 0;
  const allDimensionsById = useAppSelector(dimensionsByIdSelector);

  const modifyValueType = useCallback(
    (dimensionId: DimensionId) => {
      dispatch(
        updateBusinessObjectSpec({
          id: objectSpecId,
          updateFields: [
            {
              id: fieldSpecId,
              type: ValueType.Attribute,
              dimensionId,
            },
          ],
        }),
      );
    },
    [dispatch, fieldSpecId, objectSpecId],
  );
  const onCreateDimension = useCallback(
    (newDimensionName: string) => {
      if (newDimensionName.length > 0) {
        dispatch(
          createNewDimensionForObjectSpecField({
            objectSpecId,
            fieldSpecId,
            dimensionName: newDimensionName,
          }),
        );
      }
    },
    [dispatch, fieldSpecId, objectSpecId],
  );
  const modifyNumericFormat = useCallback(
    (format: DriverFormat) => {
      dispatch(
        updateNumericFormat({
          objectSpecId,
          fieldSpecId,
          format,
        }),
      );
    },
    [dispatch, fieldSpecId, objectSpecId],
  );

  const { currency } = useAppSelector((state) =>
    fieldSpecDisplayConfigurationSelector(state, fieldSpecId),
  );

  const fieldSpecDecimal = useAppSelector((state) => fieldSpecDecimalSelector(state, fieldSpecId));

  const onChangeCurrency = useCallback(
    (newCurrency: string) => {
      dispatch(updateCurrency({ objectSpecId, fieldSpecId, currency: newCurrency }));
      dispatch(trackEditFormatEvent({ pageType: 'submodelPage', formatType: FormatType.Currency }));
    },
    [dispatch, objectSpecId, fieldSpecId],
  );
  const currencySelectItem = useCurrencySelectMenuItem(currency ?? null, onChangeCurrency);

  const onChangeDecimalPlaces = useCallback(
    (newDecimalPlaces: number | null) => {
      dispatch(updateDecimalPlaces({ objectSpecId, fieldSpecId, decimalPlaces: newDecimalPlaces }));
    },
    [dispatch, objectSpecId, fieldSpecId],
  );
  const decimalPlacesSelectItem = useDecimalPlacesSelectMenuItem(
    fieldSpecDecimal,
    onChangeDecimalPlaces,
  );

  const items = useMemo<SelectMenuItemWithSelect[]>(() => {
    switch (fieldSpec?.type) {
      case ValueType.Attribute: {
        const currDim = safeObjGet(allDimensionsById[fieldSpec.dimensionId]);
        const meta =
          currDim == null ? '(deleted)' : currDim.name + (currDim.deleted ? ' (deleted)' : '');
        return [
          valueTypeSelectItem,
          {
            id: 'dimension',
            name: 'Dimension',
            icon: DATABASE_PROPERTY_TYPE_ICONS[ValueType.Attribute],
            meta,
            isDisabled: !canEditDimension,
            hasNextMenu: canEditDimension,
            submenu: () => (
              <DimensionSubmenu
                onCreateDimension={onCreateDimension}
                currentDimensionId={fieldSpec.dimensionId}
                modifyValueType={modifyValueType}
              />
            ),
          },
        ];
      }
      case ValueType.Number: {
        const formatItems = [
          ...DRIVER_FORMATS.map<SelectItem>((f) => ({
            id: f,
            name: DRIVER_FORMAT_NAMES[f],
            icon: DRIVER_FORMAT_ICONS[f],
            isChecked: fieldSpec.numericFormat === f,
          })),
          ...(isNumericFieldSpec(fieldSpec) && fieldSpec.numericFormat === DriverFormat.Currency
            ? [currencySelectItem]
            : []),
          ...(isNumericFieldSpec(fieldSpec) && fieldSpec.numericFormat !== DriverFormat.Integer
            ? [decimalPlacesSelectItem]
            : []),
        ];

        const formulaEditItem = {
          id: 'editFormula',
          name: `${fieldSpec?.isFormula ? 'Edit' : 'Add'} formula`,
          icon: <FormulaIcon />,
          onSelect: () =>
            dispatch(setEditingPropertyFormula({ blockId, fieldSpecId, groupKey, objectId })),
          shouldClose: false,
        };

        const onSelect = (item: SelectItem) => {
          if (item.id === 'currency') {
            return;
          }
          modifyNumericFormat(item.id as DriverFormat);
        };

        return [
          valueTypeSelectItem,
          formulaEditItem,
          {
            id: 'format',
            name: 'Format as…',
            icon: DRIVER_FORMAT_ICONS[fieldSpec.numericFormat],
            meta: DRIVER_FORMAT_NAMES[fieldSpec.numericFormat],
            hasNextMenu: true,
            submenu: () => (
              <SelectMenuContainer>
                <SelectMenu
                  items={formatItems}
                  onSelect={onSelect}
                  width="10rem"
                  startFocusIdx={-1}
                >
                  {BaseSelectMenuItem}
                </SelectMenu>
              </SelectMenuContainer>
            ),
          },
        ];
      }
      case ValueType.Timestamp: {
        const formulaEditItems = isStartField
          ? []
          : [
              {
                id: 'editFormula',
                name: `${fieldSpec?.isFormula ? 'Edit' : 'Add'} formula`,
                icon: <FormulaIcon />,
                onSelect: () =>
                  dispatch(setEditingPropertyFormula({ blockId, fieldSpecId, groupKey, objectId })),
                shouldClose: false,
              },
            ];
        return [
          valueTypeSelectItem,
          ...formulaEditItems,
          {
            id: 'startDate',
            name: 'Set as start date',
            isDisabled: isStartField,
            icon: <CupcakeIcon />,
            onSelect: () => {
              dispatch(
                updateBusinessObjectSpec({
                  id: objectSpecId,
                  updateStartFieldId: fieldSpecId,
                }),
              );
            },
          },
        ];
      }
      default: {
        return [];
      }
    }
  }, [
    fieldSpec,
    allDimensionsById,
    valueTypeSelectItem,
    canEditDimension,
    onCreateDimension,
    modifyValueType,
    currencySelectItem,
    decimalPlacesSelectItem,
    dispatch,
    blockId,
    fieldSpecId,
    groupKey,
    modifyNumericFormat,
    isStartField,
    objectId,
    objectSpecId,
  ]);
  return items;
};
