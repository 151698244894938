import React, { useCallback, useContext, useMemo } from 'react';

import BusinessObjectFieldCell from 'components/BusinessObjectFieldCell/BusinessObjectFieldCell';
import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import { BusinessObjectTimeSeriesCellRef, CellType } from 'config/cells';
import { ObjectFieldTimeSeriesRowContext } from 'config/objectFieldTimeSeriesRowContext';
import { OBJECT_INITIAL_VALUE_COLUMN_KEY } from 'config/objectGridView';
import { shouldShowComparisonHighlight } from 'helpers/blockComparisons';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { updateBusinessObjectFieldInitialValue } from 'reduxStore/actions/businessObjectMutations';
import { Value } from 'reduxStore/models/value';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { businessObjectStartFieldValueSelector } from 'selectors/businessObjectTimeSeriesSelector';
import { businessObjectFieldInitialValueForSpecIdAndObjectIdSelector } from 'selectors/businessObjectsSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

interface Props {
  isStartField?: boolean;
}

const BusinessObjectFieldInitialValueCell: React.FC<Props> = ({ isStartField = false }) => {
  const dispatch = useAppDispatch();
  const { fieldSpecId, businessObjectId, layerId } = useContext(ObjectFieldTimeSeriesRowContext);
  const { blockId } = useBlockContext();
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const isEmpty = comparisonLayerIds.length > 1 && layerId == null;
  const initialFieldValue = useAppSelector((state) => {
    return isStartField
      ? businessObjectStartFieldValueSelector(state, {
          layerId,
          businessObjectId,
          businessObjectFieldSpecId: fieldSpecId,
        })
      : businessObjectFieldInitialValueForSpecIdAndObjectIdSelector(state, {
          businessObjectId,
          businessObjectFieldSpecId: fieldSpecId,
          layerId,
        });
  });

  const baselineValue = useAppSelector((state) => {
    return isStartField
      ? businessObjectStartFieldValueSelector(state, {
          layerId: baselineLayerId,
          businessObjectId,
          businessObjectFieldSpecId: fieldSpecId,
        })
      : businessObjectFieldInitialValueForSpecIdAndObjectIdSelector(state, {
          businessObjectId,
          businessObjectFieldSpecId: fieldSpecId,
          layerId: baselineLayerId,
        });
  });

  const onSave = useCallback(
    (value: Value | undefined) => {
      if (value == null || value?.value === initialFieldValue?.value) {
        return;
      }

      dispatch(
        updateBusinessObjectFieldInitialValue({ fieldSpecId, objectId: businessObjectId, value }),
      );
    },
    [dispatch, businessObjectId, fieldSpecId, initialFieldValue?.value],
  );

  const cellRef: BusinessObjectTimeSeriesCellRef = useMemo(
    () => ({
      type: CellType.ObjectFieldTimeSeries,
      rowKey: { fieldSpecId, objectId: businessObjectId, layerId },
      columnKey: OBJECT_INITIAL_VALUE_COLUMN_KEY,
    }),
    [fieldSpecId, businessObjectId, layerId],
  );

  const showComparisonHighlight = shouldShowComparisonHighlight({
    rowValue: initialFieldValue?.value,
    baselineValue: baselineValue?.value,
    layerId,
    baselineLayerId,
  });

  return (
    <CellContextProvider cellRef={cellRef}>
      <BusinessObjectFieldCell
        fieldSpecId={fieldSpecId}
        value={isEmpty ? undefined : initialFieldValue}
        onSave={onSave}
        isActuals
        showComparisonHighlight={showComparisonHighlight && !isEmpty}
      />
    </CellContextProvider>
  );
};

export default React.memo(BusinessObjectFieldInitialValueCell);
