import { createCachedSelector } from 're-reselect';
import { isDesktop, isElectron } from 'react-device-detect';
import { createSelector } from 'reselect';

import { DatabaseGroupKey } from 'config/businessObjects';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { isDevelopment } from 'helpers/environment';
import { isRunwayEmployee } from 'helpers/user';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DimensionalPropertyId, DriverPropertyId } from 'reduxStore/models/collections';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import {
  AutoFocusItem,
  CellNavigationState,
  FormulaEditorState,
  PageRef,
  PageType,
  PopoverState,
  isBlocksPage,
  isPlansPage,
  isUnlistedDriversPage,
} from 'reduxStore/reducers/pageSlice';
import { paramSelector } from 'selectors/constSelectors';
import { featureFlagsSelector } from 'selectors/featureFlagsSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { isNavigationSidebarCollapsedSelector } from 'selectors/navigationSidebarSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { isTemplateOrgSelectedSelector } from 'selectors/selectedOrgSelector';
import { ParametricSelector, Selector } from 'types/redux';

export const pageTypeSelector: Selector<PageType | undefined> = (state) =>
  state?.page?.type ?? undefined;

export const currentPageRefSelector: Selector<PageRef | null> = createDeepEqualSelector(
  pageSelector,
  (page) => {
    switch (page?.type) {
      case 'blocksPage':
        return { type: page.type, id: page.id };
      case 'submodelPage':
        return { type: page.type, id: page.id };
      case 'templatesPage':
        return { type: page.type };
      case 'plansPage':
        return { type: page.type, id: page.id };
      case 'dataSourcePage':
        return { type: 'dataSourcePage' as const, source: page.source, accountId: page.accountId };
      case 'unlistedDriversPage':
        return { type: page.type, id: page.id };
      default:
        return null;
    }
  },
);

export const pageIdSelector = createSelector(pageSelector, (page) =>
  page != null && 'id' in page ? page.id : undefined,
);

export const isViewingBlocksPageSelector: Selector<boolean> = createSelector(
  pageSelector,
  isBlocksPage,
);

export const isViewingPlansPageSelector: Selector<boolean> = createSelector(
  pageSelector,
  isPlansPage,
);

export const isViewingUnlistedDriversPageSelector: Selector<boolean> = createSelector(
  pageSelector,
  isUnlistedDriversPage,
);

export const popoverStateSelector: Selector<PopoverState> = createSelector(
  pageSelector,
  (page) => page?.popover ?? null,
);

export const isViewingDataSourcePageSelector: Selector<boolean> = createSelector(
  pageTypeSelector,
  (page) => page === 'dataSourcePage',
);

export const navigatingToCellSelector: Selector<CellNavigationState | undefined> = createSelector(
  pageSelector,
  (page) => page?.navigatingToCell,
);

const EXPANDED_GUTTER_PX = isDesktop ? 56 : 12;
const COLLAPSED_GUTTER_PX = isDesktop ? 96 : 12;

export const pageGutterWidthInPxSelector: Selector<number> = createSelector(
  isNavigationSidebarCollapsedSelector,
  (isNavigationSidebarCollapsed) =>
    isNavigationSidebarCollapsed ? COLLAPSED_GUTTER_PX : EXPANDED_GUTTER_PX,
);

export const pageGutterWidthStrSelector: Selector<string> = createSelector(
  pageGutterWidthInPxSelector,
  (n) => `${n}px`,
);

export const rightGutterWidth = isElectron ? 2 : 4;

export const showIntercomOnPageSelector: Selector<boolean> = createSelector(
  authenticatedUserSelector,
  isTemplateOrgSelectedSelector,
  featureFlagsSelector,
  (loggedInUser, isTemplateOrg, featureFlags) => {
    const { enableIntercom } = featureFlags;
    if (loggedInUser == null || isTemplateOrg) {
      return false;
    }

    return (!isRunwayEmployee(loggedInUser) || enableIntercom) && !isDevelopment;
  },
);

const autoFocusSelector: Selector<AutoFocusItem | undefined> = createSelector(
  pageSelector,
  (page) => page?.autoFocus,
);

export const isAutoFocusedRenameGroupSelector: ParametricSelector<
  DriverGroupId | undefined,
  boolean
> = createCachedSelector(
  pageTypeSelector,
  autoFocusSelector,
  paramSelector<DriverGroupId | undefined>(),
  (pageType, autoFocus, driverGroupId) =>
    driverGroupId != null &&
    pageType === 'submodelPage' &&
    autoFocus?.type === 'driverGroupRenameGroup'
      ? autoFocus.driverGroupId === driverGroupId
      : false,
)((_state, driverGroupId) => String(driverGroupId));

export const isAutoFocusedCreateDriverSelector: ParametricSelector<
  DriverGroupId | undefined,
  boolean
> = createCachedSelector(
  pageTypeSelector,
  autoFocusSelector,
  paramSelector<DriverGroupId | undefined>(),
  (pageType, autoFocus, driverGroupId) =>
    driverGroupId != null &&
    pageType === 'submodelPage' &&
    autoFocus?.type === 'driverGroupCreateDriver'
      ? autoFocus.driverGroupId === driverGroupId
      : false,
)((_state, driverGroupId) => String(driverGroupId));

const autoFocusedObjectFieldSelector = createSelector(autoFocusSelector, (autoFocus) =>
  autoFocus?.type === 'objectField' ? autoFocus : null,
);

type ObjectFieldForBlockProps = {
  blockId: BlockId;
  fieldSpecId: DriverPropertyId | DimensionalPropertyId;
  groupKey: DatabaseGroupKey;
  objectId: BusinessObjectId | undefined;
};

export const isAutoFocusedDatabaseHeaderMenuSelector: ParametricSelector<
  ObjectFieldForBlockProps,
  boolean
> = createCachedSelector(
  autoFocusedObjectFieldSelector,
  paramSelector<ObjectFieldForBlockProps>(),
  (autoFocusItem, { blockId, fieldSpecId, groupKey, objectId }: ObjectFieldForBlockProps) => {
    return (
      autoFocusItem != null &&
      autoFocusItem.blockId === blockId &&
      autoFocusItem.fieldSpecId === fieldSpecId &&
      ((autoFocusItem.groupKey != null && autoFocusItem.groupKey === groupKey) ||
        (autoFocusItem.objectId != null && autoFocusItem.objectId === objectId))
    );
  },
)(
  (_state, params: ObjectFieldForBlockProps) =>
    `${params.blockId},${params.fieldSpecId},${params.groupKey},${params.objectId}`,
);

export const isFormulaEditorOpenSelector: Selector<boolean> = createSelector(
  pageSelector,
  (page) => page?.formulaEditor != null,
);

export const formulaEditorSelector: Selector<FormulaEditorState | undefined> = createSelector(
  pageSelector,
  (page) => page?.formulaEditor,
);

export const isFormulaEditorPinnedSelector: Selector<boolean> = createSelector(
  pageSelector,
  (page) => page?.formulaEditor?.isPinned ?? false,
);

export const formulaEditorEntityIdSelector: Selector<DriverId | undefined> = createSelector(
  pageSelector,
  (page) => {
    const formulaEntityId = page?.formulaEditor?.formulaEntityId;
    // For now, we only support editing driver formulas through the universal formula editor
    if (formulaEntityId == null || formulaEntityId.type !== 'driver') {
      return undefined;
    } else {
      return formulaEntityId.id;
    }
  },
);

export const isNavigatingSelector: Selector<boolean> = createSelector(pageSelector, (page) => {
  if (page == null) {
    return false;
  }
  const navTo = page.navigatingTo;

  if (navTo == null) {
    return false;
  }
  const pageType = page.type;
  const navToPageType = navTo.type;
  const pageId = 'id' in page ? page.id : undefined;
  const pageSource = 'source' in page ? page.source : undefined;
  if (navToPageType !== pageType) {
    return true;
  }
  switch (navToPageType) {
    case 'blocksPage':
    case 'submodelPage':
      return navTo.id !== pageId;
    case 'dataSourcePage':
      return navTo.source !== pageSource;
    case 'templatesPage':
    default:
      return false;
  }
});
