import { createCachedSelector } from 're-reselect';

import { getCacheKeyForLayerSelector, SelectorWithLayerParam } from 'helpers/layerSelectorFactory';
import { EntityTable } from 'reduxStore/models/common';
import { NormalizedDriver } from 'reduxStore/models/drivers';
import { getGivenOrCurrentLayerId, layersSelector } from 'selectors/layerSelector';

const EMPTY_ENTITY_TABLE: EntityTable<NormalizedDriver> = { byId: {}, allIds: [] };

export const driverEntityTableForLayerSelector: SelectorWithLayerParam<
  EntityTable<NormalizedDriver>
> = createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
  const layer = layers[layerId];

  if (layer == null) {
    return EMPTY_ENTITY_TABLE;
  }

  return layer.drivers;
})(getCacheKeyForLayerSelector);
