import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'TableauIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <g clipPath="url(#clip0_48_558)">
        <path
          d="M28.2834 40.872H31.7166V31.5086H40.3518V28.1793H31.7166V18.8159H28.2834V28.1793H19.7522V31.5086H28.2834V40.872Z"
          fill="#E8762D"
        />
        <path
          d="M12.3654 54.397H15.2784V46.074H22.9773V43.473H15.2784V35.0459H12.3654V43.473H4.6665V46.074H12.3654V54.397Z"
          fill="#C72037"
        />
        <path
          d="M44.7214 24.3299H47.6345V16.0068H55.4374V13.5099H47.6345V5.08276H44.7214V13.5099H37.0226V16.0068H44.7214V24.3299Z"
          fill="#5B879B"
        />
        <path
          d="M28.8035 60.9515H31.3004V55.0213H36.6064V52.8365H31.3004V46.9063H28.8035V52.8365H23.6016V55.0213H28.8035V60.9515Z"
          fill="#5C6692"
        />
        <path
          d="M12.4694 24.3299H15.1744V15.9028H22.9773V13.5099H15.1744V5.08276H12.4694V13.5099H4.6665V15.9028H12.4694V24.3299Z"
          fill="#EB9129"
        />
        <path
          d="M53.5647 36.8145H56.0616V30.9884H61.3676V28.6995H56.0616V22.8734H53.5647V28.6995H48.3628V30.9884H53.5647V36.8145Z"
          fill="#5C6692"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.7214 54.397H47.6345V46.074H55.4374V43.473H47.6345V35.0459H44.7214V43.473H37.0226V46.074H44.7214V54.397Z"
          fill="#1F457E"
        />
        <path
          d="M36.1902 6.5393V4.77064H30.9883V-0.951477H29.1156V4.77064H23.9137V6.5393H29.1156V12.2614H30.9883V6.5393H36.1902ZM3.83425 36.3984H5.70695V30.6762H10.9089V28.9076H5.70695V23.2895H3.83425V28.9076H-1.36768V30.7803L3.83425 30.6762V36.3984Z"
          fill="#7199A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_48_558">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
