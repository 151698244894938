import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { camelCaseKeys } from 'launchdarkly-react-client-sdk';
import { keyBy, without } from 'lodash';
import last from 'lodash/last';
import { DateTime } from 'luxon';

import {
  Dataset,
  DatasetMutatedSubscription,
  DatasetMutationInput,
  ExtTableSnapshot,
  Folder,
  SqlQueryResult,
} from 'generated/graphql';
import { logDatasetReload } from 'helpers/logging';
import { isNamedVersion } from 'helpers/namedVersions';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { Block, BlocksPage } from 'reduxStore/models/blocks';
import { EntityTable } from 'reduxStore/models/common';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { DEFAULT_LAYER_ID, Layer, LayerId } from 'reduxStore/models/layers';
import { MutationBatch, MutationId } from 'reduxStore/models/mutations';
import { setBusinessObjectSpecsFromDatasetSnapshot } from 'reduxStore/reducers/helpers/businessObjectSpecs';
import {
  addPendingOrFailedMutationHelper,
  applyMutations,
  filterLayerMutations,
  getActiveComparisonLayerIds,
  initializeStateWithSnapshot,
  markLayersAsStaleAndReloadActiveLayers,
  markMutationUndone,
  reloadActiveLayers,
  reloadFromSnapshot,
  reloadLayer,
  setCurrentLayerId,
  shouldReloadAllLayersForMutation,
} from 'reduxStore/reducers/helpers/datasetSlice';
import { setDriversFromDatasetSnapshotLite } from 'reduxStore/reducers/helpers/drivers';
import {
  assertDefaultLayerDraft,
  setComputedSnapshotForExtTables,
  undoDeleteLayer,
} from 'reduxStore/reducers/helpers/layers';
import {
  LaunchDarklyFlags,
  LaunchDarklySliceState,
  initialState as launchDarklyInitialState,
} from 'reduxStore/reducers/launchDarklySlice';
import {
  endCopilotSession,
  propagateBlocksPageFromUrl,
  propagateSubModelPageFromUrl,
} from 'reduxStore/reducers/pageSlice';
import { CalculationEngine } from 'selectors/calculationEngineSelector';

import { emptyFolders } from './helpers/folders';

export type ApplyMutationArgs = {
  layerId: LayerId;
  orgId: string;
  userId?: string;
  mutationBatch: MutationBatch;
  isRedo?: boolean;
  isRemote?: boolean;
  isEphemeral?: boolean;
  activeLayerIds: string[];
  accessibleLayerIds: string[];
};

export type UndoLatestArgs = {
  layerId: LayerId;
  toUndoId: MutationId;
  updatedPreviousMutationId: MutationId;
  isRemote?: boolean;
  activeLayerIds: string[];
};

export type InitializeLockedSnapshotArgs = {
  snapshot: DatasetSnapshot;
  mutationId: MutationId;
  // Pass true if the current layer is loaded from this locked snapshot.
  isSelectedLayer?: boolean;
};

type InitializeNamedVersionArgs = {
  snapshot: DatasetSnapshot;
  mutationId: MutationId;
};

export type PendingOrFailedMutation = {
  layerId: LayerId;
  mutation?: DatasetMutationInput;
  retryCount: number;
  undoMutationId?: MutationId;
  pendingMutationId?: MutationId;
  pendingRemoteMutation?: DatasetMutatedSubscription['datasetMutated'];
};

export type DatasetSliceState = {
  // underlying data
  layers: Record<LayerId, Layer>;
  isLayerUpToDate: Record<LayerId, boolean>;
  snapshots: Record<LayerId, DatasetSnapshot>;
  defaultLayerHistory: MutationBatch[];
  loadingHistoryAfterId?: MutationId;

  localEphemeralMutation?: MutationBatch;

  // user state
  currentLayerId: LayerId;

  // model data
  blocks: EntityTable<Block>;
  blocksPages: EntityTable<BlocksPage>;
  folders: EntityTable<Folder>;
  lastActualsTime?: string;

  extTableDataByKey: NullableRecord<string, SqlQueryResult>;

  // internal tracking
  previousMutationId?: MutationId;
  confirmedPreviousMutationId?: MutationId;
  appliedMutationIds: Record<MutationId, unknown>;
  isFresh: boolean;
  pendingOrFailedMutations?: PendingOrFailedMutation[];
  initialMutationId?: MutationId;
  isFullDatasetLoaded: boolean;
  orgId?: string;
  calculationEngineOnInitialization?: CalculationEngine;
  launchDarklyFlags: LaunchDarklySliceState;
};

export const initialState: DatasetSliceState = {
  layers: {},
  snapshots: {},
  currentLayerId: DEFAULT_LAYER_ID,
  defaultLayerHistory: [],
  isFresh: true,
  appliedMutationIds: {},
  blocks: { byId: {}, allIds: [] },
  blocksPages: { byId: {}, allIds: [] },
  folders: { byId: keyBy(emptyFolders, 'id'), allIds: emptyFolders.map(({ id }) => id) },
  extTableDataByKey: {},
  isLayerUpToDate: {},
  isFullDatasetLoaded: false,
  launchDarklyFlags: launchDarklyInitialState,
};

const emptyDataset = {
  orgId: '',
  metadata: {},
  dimensions: [],
  drivers: [],
  driverGroups: [],
  events: [],
  milestones: [],
  eventGroups: [],
  layers: [],
  exports: [],
  submodels: [],
  businessObjectSpecs: [],
  businessObjects: [],
  // This field is deprecated.
  driverFieldSpecs: [],
  // This field is deprecated.
  driverFields: [],
  blocks: [],
  blocksPages: [],
  folders: emptyFolders,
  extDrivers: [],
  extObjectSpecs: [],
  extObjects: [],
  extTables: [],
  extQueries: [],
  databaseConfigs: [],
  integrationQueries: [],
  namedVersions: [],
};

export const emptyGQLDataset: Dataset = { ...emptyDataset };
export const emptySnapshot: DatasetSnapshot = { ...emptyDataset };

const datasetSlice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    setIsDatasetFullyLoaded: (state, action: PayloadAction<boolean>) => {
      state.isFullDatasetLoaded = action.payload;
    },
    initializeWithSnapshot: (
      state,
      action: PayloadAction<{
        snapshot: DatasetSnapshot;
        calculationEngineOnInitialization?: CalculationEngine;
      }>,
    ) => {
      initializeStateWithSnapshot(state, action.payload);
    },
    initializeLockedSnapshot: (state, action: PayloadAction<InitializeLockedSnapshotArgs>) => {
      const { mutationId, snapshot, isSelectedLayer } = action.payload;
      if (state.snapshots[mutationId] != null) {
        return;
      }
      reloadFromSnapshot(state, snapshot, mutationId, { reloadNonLayerData: isSelectedLayer });
      state.snapshots[mutationId] = emptySnapshot; // used to mark that we have already fetched and loaded this locked snapshot

      // Named versions can have different lastActualsTime
      const layer = state.layers[mutationId];
      layer.lastActualsTime = snapshot?.lastActualsTime ?? undefined;
    },
    /**
     * In the main thread, named versions are only needed for retrieving entity metadata like names, formulas and so we load the redux state
     * in the main thread with minimal amount of information from a given snapshot via initializeNamedVersionLite.
     *
     * The main distinction between initializeNamedVersionLite and initializeLockedSnapshot is that initializeNamedVersionLite is only used in the main thread and initializeLockedSnapshot is currently only used by
     * the webworker thread since the webwork needs a lot more from the named version snapshot for calculations.
     */
    initializeNamedVersionLite: (state, action: PayloadAction<InitializeNamedVersionArgs>) => {
      const { mutationId, snapshot } = action.payload;
      const layer = safeObjGet(state.layers[mutationId]);
      if (layer == null || !isNamedVersion(layer) || state.snapshots[mutationId] != null) {
        return;
      }

      const defaultLayer = state.layers[DEFAULT_LAYER_ID];
      assertDefaultLayerDraft(defaultLayer);

      setDriversFromDatasetSnapshotLite(layer, snapshot); // initialize redux state with minimal information about drivers in the named version
      setBusinessObjectSpecsFromDatasetSnapshot(layer, defaultLayer, snapshot);
      state.snapshots[mutationId] = emptySnapshot; // used to mark that we have already fetched and loaded this named version
      layer.lastActualsTime = snapshot?.lastActualsTime ?? undefined;
    },
    undoIncomingMutationForAlreadyUndoneChange: (
      _state,
      _action: PayloadAction<{ mutationId: string; mutation: DatasetMutationInput }>,
    ) => {},
    undoMutationLocally: (state, action: PayloadAction<UndoLatestArgs>) => {
      const { layerId, toUndoId, isRemote, updatedPreviousMutationId, activeLayerIds } =
        action.payload;

      state.previousMutationId = updatedPreviousMutationId;
      state.appliedMutationIds[updatedPreviousMutationId] = 0;

      const undoneMutation = markMutationUndone(state, layerId, toUndoId)?.mutation;
      const undoDeletes = [
        ...(undoneMutation?.deleteLayers?.map((mutation) => mutation.layerId) ?? []),
        ...(undoneMutation?.deleteNamedDatasetVersions?.map((mutation) => mutation.mutationId) ??
          []),
      ].filter(isNotNull);

      undoDeletes.forEach((id) => undoDeleteLayer(state, id));
      (undoneMutation?.newLayers ?? []).forEach((newLayer) => {
        if (state.currentLayerId === newLayer.id) {
          setCurrentLayerId(state, newLayer.parentLayerId ?? DEFAULT_LAYER_ID, {
            shouldReload: false,
          });
        }
        delete state.layers[newLayer.id];
      });

      if (undoneMutation?.updateLastActualsTime != null) {
        state.lastActualsTime = state.snapshots[DEFAULT_LAYER_ID]?.lastActualsTime ?? undefined;
      }

      // If we're un-committing a layer, we need to switch back to the layer that was committed.
      // Only do this if this was a local mutation, e.g. this user was the one to issue the undo.
      if (undoneMutation?.commitLayerId != null && !isRemote) {
        const layer = state.layers[undoneMutation.commitLayerId];
        if (layer != null) {
          layer.isDeleted = false;

          setCurrentLayerId(state, undoneMutation?.commitLayerId ?? DEFAULT_LAYER_ID, {
            shouldReload: false,
          });
        }
      }

      markLayersAsStaleAndReloadActiveLayers(state, {
        includeDefaultLayer: layerId === DEFAULT_LAYER_ID,
        activeLayerIds,
      });
    },
    applyMutationLocally: (state, action: PayloadAction<ApplyMutationArgs>) => {
      const {
        mutationBatch,
        layerId,
        isEphemeral = false,
        activeLayerIds,
        isRemote,
      } = action.payload;

      // Omit mutations to layers that are not relevant to the client.
      const filteredMutationBatch = filterLayerMutations(state, action.payload);

      // Track when the layer being committed is missing from the client.
      // The dataset will need to be reloaded after this mutation is applied.
      const isMissingCommitLayer =
        mutationBatch.mutation.commitLayerId != null &&
        !(mutationBatch.mutation.commitLayerId in state.layers);

      const previousComparisonLayerIds = getActiveComparisonLayerIds(state);
      applyMutations(state, [filteredMutationBatch], layerId, {
        onApplyMutationsEnd: (layerDraft) => {
          if (layerDraft.id === layerId && !isEphemeral) {
            layerDraft.mutationBatches.push(mutationBatch);
          }
        },
      });
      const currentComparisonLayerIds = getActiveComparisonLayerIds(state);

      if (!isRemote) {
        const lastNewLayerId = last(mutationBatch.mutation.newLayers)?.id;
        if (lastNewLayerId != null) {
          setCurrentLayerId(state, lastNewLayerId, { shouldReload: false });
        }

        mutationBatch.mutation.deleteLayers?.forEach((mutation) => {
          if (mutation.layerId === state.currentLayerId) {
            setCurrentLayerId(
              state,
              state.layers[mutation.layerId]?.parentLayerId ?? DEFAULT_LAYER_ID,
              {
                shouldReload: false,
              },
            );
          }
        });
      }

      // If layer ids were added, they may need to be reloaded, but we can ignore any layers that
      // were removed.
      const addedComparisonLayerIds = without(
        currentComparisonLayerIds,
        ...previousComparisonLayerIds,
      );

      // this may be a remote mutation on a layer we do not have access to
      const isMissingLayer = state.layers[layerId] == null;

      // Store new mutation
      if (isEphemeral) {
        if (state.localEphemeralMutation != null) {
          throw new Error('Cannot have more than one ephemeral mutation at a time');
        }
        state.localEphemeralMutation = mutationBatch;
      } else {
        state.appliedMutationIds[mutationBatch.id] = 0;
        state.previousMutationId = mutationBatch.id;
        if (layerId === DEFAULT_LAYER_ID) {
          state.defaultLayerHistory.unshift({
            ...mutationBatch,
            createdAt: mutationBatch.createdAt ?? DateTime.now().toISO(),
          });
        }
      }

      // we can have a newLayer or commitLayerId set in a mutation batch with a feature layer ID.
      // reload for those 2 cases too
      if (shouldReloadAllLayersForMutation(state, action.payload)) {
        const { commitLayerId } = mutationBatch.mutation;
        if (isMissingLayer || isMissingCommitLayer) {
          // reload the whole dataset if we are missing the layer being referenced;
          // if a draft layer we don't have access to is being committed, we don't know how to
          // update the main layer from here
          state.isFresh = false;
          logDatasetReload({ cause: 'missingLayer' });
        } else {
          markLayersAsStaleAndReloadActiveLayers(state, {
            includeDefaultLayer: isRemote && commitLayerId != null && layerId === DEFAULT_LAYER_ID,
            activeLayerIds,
          });
        }
      } else if (addedComparisonLayerIds.length > 0) {
        addedComparisonLayerIds.forEach((addedLayerId) => reloadLayer(state, addedLayerId));
      }
    },
    setConfirmedPreviousMutationId: (state, action: PayloadAction<MutationId>) => {
      state.confirmedPreviousMutationId = action.payload;
    },
    setCurrentLayer: (state, action: PayloadAction<LayerId>) => {
      setCurrentLayerId(state, action.payload);
    },
    setDatasetIsStale: (state, action: PayloadAction<PendingOrFailedMutation | undefined>) => {
      state.isFresh = false;
      if (action?.payload != null) {
        addPendingOrFailedMutationHelper(state, {
          ...action.payload,
          retryCount: action.payload.retryCount + 1,
        });
      }
    },
    addPendingMutation: (state, action: PayloadAction<PendingOrFailedMutation>) => {
      addPendingOrFailedMutationHelper(state, action.payload);
    },
    clearPendingOrFailedMutations: (state) => {
      delete state.pendingOrFailedMutations;
    },
    clearLocalEphemeralMutation: (state) => {
      if (state.localEphemeralMutation != null) {
        state.localEphemeralMutation = undefined;
        markLayersAsStaleAndReloadActiveLayers(state, { includeDefaultLayer: true });
      }
    },
    updateSnapshotForExtTables: (
      state,
      action: PayloadAction<{ snapshotBySourceKey: NullableRecord<string, ExtTableSnapshot> }>,
    ) => {
      const { snapshotBySourceKey } = action.payload;
      setComputedSnapshotForExtTables({ state, snapshotBySourceKey });
    },
    // Launch Darkly reducers–these used to be in their own slice, but we need
    // convenient access to them from datasetSlice so we moved them here.
    syncFlags(state, action: PayloadAction<LaunchDarklyFlags>) {
      state.launchDarklyFlags.flags = camelCaseKeys(action.payload);
      state.launchDarklyFlags.isInitialized = true;
    },
    setIsLaunchDarklyEnabled(state, action: PayloadAction<boolean>) {
      state.launchDarklyFlags.isEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(endCopilotSession, (state) => {
      if (state.localEphemeralMutation != null) {
        state.localEphemeralMutation = undefined;
        markLayersAsStaleAndReloadActiveLayers(state, { includeDefaultLayer: true });
      }
    });
    builder.addCase(propagateSubModelPageFromUrl, (state) => {
      reloadActiveLayers(state);
    });
    builder.addCase(propagateBlocksPageFromUrl, (state) => {
      reloadActiveLayers(state);
    });
  },
});

export const {
  addPendingMutation,
  clearPendingOrFailedMutations,
  initializeLockedSnapshot,
  initializeNamedVersionLite,
  initializeWithSnapshot,
  setConfirmedPreviousMutationId,
  setCurrentLayer,
  setDatasetIsStale,
  setIsDatasetFullyLoaded,
  updateSnapshotForExtTables,
  syncFlags,
  setIsLaunchDarklyEnabled,
} = datasetSlice.actions;

// Do NOT call there internal actions directly.

// Prefer to use applyMutationLocallyForActiveLayers
export const applyMutationLocally_INTERNAL = datasetSlice.actions.applyMutationLocally;
// Prefer to use undoMutationLocallyForActiveLayers.
export const undoMutationLocally_INTERNAL = datasetSlice.actions.undoMutationLocally;

export const undoIncomingMutationForAlreadyUndoneChange =
  datasetSlice.actions.undoIncomingMutationForAlreadyUndoneChange;
// Prefer to use clearLocalEphemeralMutation.
export const clearLocalEphemeralMutation_INTERNAL =
  datasetSlice.actions.clearLocalEphemeralMutation;

export default datasetSlice.reducer;
