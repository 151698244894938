import pluralize from 'pluralize';

import { DatasetMutationData, DatasetMutationInput, InputMaybe } from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import { ISOTime } from 'types/datetime';

export type MutationId = string;
export type MutationChangeId = string;
export type MutationBatch = Pick<
  DatasetMutationData,
  'undoMutationId' | 'prevMutationId' | 'id' | 'layerId' | 'changeId'
> & {
  id: MutationId;
  changeId?: MutationChangeId;
  prevMutationId?: MutationId;
  mutation: DatasetMutationInput;
  isUndone?: boolean;
  createdAt?: ISOTime;
};

type Input<T> = InputMaybe<T[] | undefined>;
type ActionName = 'Created' | 'Deleted' | 'Renamed' | 'Updated' | 'Set' | 'Committed';
export type MutationBatchAction = {
  message: string;
  entityType?: MutationAffectedEntityType;
  entityIds?: string[];
};

export type MutationAffectedEntityType =
  | 'block'
  | 'businessObject'
  | 'businessObjectSpec'
  | 'dimension'
  | 'driver'
  | 'driverField'
  | 'driverGroup'
  | 'export'
  | 'extDriver'
  | 'goal'
  | 'impact'
  | 'integrationQuery'
  | 'layer'
  | 'model'
  | 'page'
  | 'plan'
  | 'scenario'
  | 'snapshot'
  | 'submodel';

const entityTypeToName = (entityType: MutationAffectedEntityType) => {
  switch (entityType) {
    case 'businessObject':
      return 'object';
    case 'businessObjectSpec':
      return 'object type';
    case 'driverField':
      return 'driver field';
    case 'driverGroup':
      return 'driver group';
    case 'extDriver':
      return 'integration driver';
    case 'integrationQuery':
      return 'integration query';
    case 'block':
    case 'dimension':
    case 'driver':
    case 'export':
    case 'goal':
    case 'impact':
    case 'layer':
    case 'model':
    case 'page':
    case 'plan':
    case 'scenario':
    case 'snapshot':
    case 'submodel':
    default:
      return entityType;
  }
};

const collectEntityIds = <T>(input: Input<T>) => {
  const entityIds =
    input
      ?.map((item) =>
        item != null && typeof item === 'object' && 'id' in item && typeof item.id === 'string'
          ? item.id
          : undefined,
      )
      .filter(isNotNull) ?? [];
  return entityIds;
};

export const getMutationBatchDisplay = ({
  mutationBatch,
}: {
  mutationBatch: MutationBatch;
  deletedIdentifiers?: NullableRecord<string, string>;
}) => {
  const {
    commitLayerId,
    createExtDrivers,
    createIntegrationQueries,
    createNamedDatasetVersions,
    deleteBlocks,
    deleteBlocksPages,
    deleteBusinessObjectSpecs,
    deleteBusinessObjects,
    deleteDrivers,
    deleteEventGroups,
    deleteEvents,
    deleteExports,
    deleteExtDrivers,
    deleteIntegrationQueries,
    deleteLayers,
    deleteMilestones,
    deleteNamedDatasetVersions,
    deleteSubmodels,
    newBlocks,
    newBlocksPages,
    newBusinessObjectSpecs,
    newBusinessObjects,
    newDimensions,
    newDriverFieldSpecs,
    newDriverGroups,
    newDrivers,
    newEventGroups,
    newEvents,
    newExports,
    newLayers,
    newMilestones,
    newSubmodels,
    renameDriverGroups,
    setDriverFields,
    updateBlocks,
    updateBlocksPages,
    updateBusinessObjectSpecs,
    updateBusinessObjects,
    updateDimensions,
    updateDriverFieldSpecs,
    updateDrivers,
    updateEventGroups,
    updateEvents,
    updateExports,
    updateExtDrivers,
    updateIntegrationQueries,
    updateLastActualsTime,
    updateLayers,
    updateMilestones,
    updateNamedDatasetVersions,
    updateSubmodels,
  } = mutationBatch.mutation;

  const actions: MutationBatchAction[] = [];

  const wasMutated = <T>(input: Input<T>) => {
    return input != null && input?.length != null && input?.length > 0;
  };

  const addMessage = <T>(
    action: ActionName,
    input: Input<T>,
    entityType: MutationAffectedEntityType,
  ) => {
    if (!wasMutated(input)) {
      return;
    }

    actions.push({
      message: `${action} ${pluralize(entityTypeToName(entityType), input?.length ?? 0, true)}`,
      entityType,
      entityIds: collectEntityIds(input),
    });
  };

  const deleted = <T>(input: Input<T>, entityType: MutationAffectedEntityType) => {
    return addMessage('Deleted', input, entityType);
  };

  const created = <T>(input: Input<T>, entityType: MutationAffectedEntityType) => {
    return addMessage('Created', input, entityType);
  };

  const renamed = <T>(input: Input<T>, entityType: MutationAffectedEntityType) => {
    return addMessage('Renamed', input, entityType);
  };

  const set = <T>(input: Input<T>, entityType: MutationAffectedEntityType) => {
    return addMessage('Set', input, entityType);
  };

  const updated = <T>(input: Input<T>, entityType: MutationAffectedEntityType) => {
    return addMessage('Updated', input, entityType);
  };

  const committed = <T>(input: Input<T>, entityType: MutationAffectedEntityType) => {
    return addMessage('Committed', input, entityType);
  };

  if (commitLayerId != null) {
    committed([{ id: commitLayerId }], 'layer');
  }

  if (updateLastActualsTime != null) {
    actions.push({ message: 'Updated last close date' });
  }

  created(newDimensions, 'dimension');
  created(newDriverGroups, 'driverGroup');
  created(newDrivers, 'driver');
  created(newEventGroups, 'plan');
  created(newEvents, 'impact');
  created(newExports, 'export');
  created(newLayers, 'scenario');
  created(newMilestones, 'goal');
  created(newSubmodels, 'submodel');
  created(createExtDrivers, 'extDriver');
  created(createNamedDatasetVersions, 'snapshot');
  created(newBlocks, 'block');
  created(newBlocksPages, 'page');
  created(newBusinessObjectSpecs, 'businessObjectSpec');
  created(newBusinessObjects, 'businessObject');
  created(newDriverFieldSpecs, 'driverField');
  created(createIntegrationQueries, 'integrationQuery');

  deleted(deleteDrivers, 'driver');
  deleted(deleteEventGroups, 'plan');
  deleted(deleteEvents, 'impact');
  deleted(deleteExports, 'export');
  deleted(deleteLayers, 'scenario');
  deleted(deleteMilestones, 'goal');
  deleted(deleteSubmodels, 'model');
  deleted(deleteNamedDatasetVersions, 'snapshot');
  deleted(deleteExtDrivers, 'extDriver');
  deleted(deleteBlocks, 'block');
  deleted(deleteBlocksPages, 'page');
  deleted(deleteBusinessObjectSpecs, 'businessObjectSpec');
  deleted(deleteBusinessObjects, 'businessObject');
  deleted(deleteIntegrationQueries, 'integrationQuery');

  renamed(renameDriverGroups, 'driverGroup');

  updated(updateDimensions, 'dimension');
  if (wasMutated(updateDrivers)) {
    updated(updateDrivers, 'driver');
  }

  updated(updateEventGroups, 'plan');
  updated(updateEvents, 'impact');
  updated(updateExports, 'export');
  updated(updateLayers, 'scenario');
  updated(updateMilestones, 'goal');
  updated(updateSubmodels, 'submodel');
  updated(updateNamedDatasetVersions, 'snapshot');
  updated(updateExtDrivers, 'extDriver');
  updated(updateBlocks, 'block');
  updated(updateBlocksPages, 'page');
  updated(updateBusinessObjectSpecs, 'businessObjectSpec');
  updated(updateBusinessObjects, 'businessObject');
  updated(updateDriverFieldSpecs, 'driverField');
  updated(updateIntegrationQueries, 'integrationQuery');

  set(setDriverFields, 'driverField');

  const summary = actions
    .map((action, idx) => {
      switch (idx) {
        case 0:
          return action.message;
        case actions.length - 1:
          return ', and ' + action.message.toLowerCase();
        default:
          return ', ' + action.message.toLowerCase();
      }
    })
    .join('');

  return {
    summary,
    actions,
  };
};
