export const SCENARIO_COMPARISON_STICKY_HEADER_HEIGHT_IN_PX = 52;

// Use Hex colors below since Chakra theme colors are not supported for chart SVG components.
export const DEFAULT_COMPARISON_LAYER_COLOR = '#7092C2';
export const COMPARISON_LAYER_COLORS = ['#FC9460', '#C270BF', '#92DBD7', '#F490C0'];
export const ALL_COMPARISON_LAYER_COLORS = [
  DEFAULT_COMPARISON_LAYER_COLOR,
  ...COMPARISON_LAYER_COLORS,
];
export const DEFAULT_COMPARISON_LAYER_HIGHLIGHT_COLOR = '#F7FAFF';
export const COMPARISON_LAYER_HIGHLIGHT_COLORS = ['#FFF4EF', '#FFF4FF', '#EFFCFC', '#FFF5F9'];
