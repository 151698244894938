import lodashGroupBy from 'lodash/groupBy';

import { EMPTY_ATTRIBUTE_ID, Group, GroupInfo, GroupRow } from 'config/businessObjects';
import { BlockGroupBy, BlockGroupByType, ValueType } from 'generated/graphql';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import { safeObjGet } from 'helpers/typescript';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObject, BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverProperty } from 'reduxStore/models/collections';
import { AttributeId, Dimension, DimensionId } from 'reduxStore/models/dimensions';
import { Value } from 'reduxStore/models/value';

export function makeGroupsForNewlyCreatedAttributes({
  newlyCreatedAttributeIds,
  attributesWithCorrespondingObjects,
  dimensionId,
  fieldSpecId,
  dimensionsById,
}: {
  newlyCreatedAttributeIds: AttributeId[];
  attributesWithCorrespondingObjects: AttributeId[];
  dimensionId: DimensionId;
  fieldSpecId: BusinessObjectFieldSpecId;
  dimensionsById: Record<DimensionId, Dimension>;
}): Group[] {
  const dimension = safeObjGet(dimensionsById[dimensionId]);
  const getAttr = (id: AttributeId) => dimension?.attributes.find((att) => att.id === id);

  return newlyCreatedAttributeIds
    .filter(
      (attributeId) =>
        getAttr(attributeId) != null && !attributesWithCorrespondingObjects.includes(attributeId),
    )
    .map((attributeId) => ({
      groupInfo: {
        groupingType: 'attributeObjectField' as const,
        dimensionId,
        attributeId,
        fieldSpecId,
        key: `${fieldSpecId},${dimensionId},${attributeId}`,
        forceShowEvenWhenEmpty: true,
      },
      isExpanded: true,
      rows: [],
    }));
}

export function groupByObjectFieldOrDriver({
  objects,
  groupBy,
  dimensionsById,
  dimensionIdByDatabasePropertyId,
  businessObjectValueByFieldIdOrSubdriverId,
  newlyCreatedAttributeIds,
  dimensionalPropertyEvaluator,
  driverProperties,
}: {
  objects: BusinessObject[];
  groupBy: BlockGroupBy;
  dimensionsById: Record<DimensionId, Dimension>;
  dimensionIdByDatabasePropertyId: NullableRecord<string, DimensionId | undefined>;
  businessObjectValueByFieldIdOrSubdriverId: Record<string, Value>;
  newlyCreatedAttributeIds: AttributeId[];
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
  driverProperties: DriverProperty[] | undefined;
}): Array<Omit<Group, 'isExpanded'>> | null {
  if (groupBy.groupByType === BlockGroupByType.None) {
    return null;
  }

  const objectFieldIdToGroupBy =
    groupBy.objectField?.businessObjectFieldId ?? groupBy.driverProperty?.driverPropertyId;
  if (objectFieldIdToGroupBy == null) {
    throw Error('cannot group by object field without field specified');
  }

  const dimensionId = dimensionIdByDatabasePropertyId[objectFieldIdToGroupBy];
  if (dimensionId == null) {
    return null;
  }
  const dimension = safeObjGet(dimensionsById[dimensionId]);
  const getAttr = (id: AttributeId) => dimension?.attributes.find((att) => att.id === id);

  const driverProperty =
    groupBy.driverProperty == null
      ? undefined
      : driverProperties?.find((prop) => prop.id === groupBy.driverProperty?.driverPropertyId);

  const objIdToAttrId = objects.reduce(
    (curr, obj) => {
      if (driverProperty != null) {
        const attributes = dimensionalPropertyEvaluator.getKeyAttributePropertiesForBusinessObject(
          obj.id,
        );
        if (attributes.length === 0) {
          return curr;
        }

        const subDriverId = dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
          driverProperty?.driverId,
          attributes.map((i) => i.attribute.id),
        );

        if (subDriverId == null) {
          return curr;
        }

        const value = businessObjectValueByFieldIdOrSubdriverId[subDriverId];
        if (value == null || value.type !== ValueType.Attribute) {
          return curr;
        }

        return { ...curr, [obj.id]: value.value };
      }

      //Use evaluator to handle integrated and mapped dimensions
      const computedAttributeProperty = dimensionalPropertyEvaluator.getAttributeProperty({
        objectId: obj.id,
        dimensionalPropertyId: objectFieldIdToGroupBy,
      });
      if (computedAttributeProperty != null) {
        return { ...curr, [obj.id]: computedAttributeProperty.attribute.id };
      }

      const matchingField = obj.fields.find((f) => f.fieldSpecId === objectFieldIdToGroupBy);
      if (matchingField != null) {
        const fieldValue = businessObjectValueByFieldIdOrSubdriverId[matchingField.id];
        if (fieldValue?.type !== ValueType.Attribute) {
          return curr;
        }

        const attrId = fieldValue?.value;
        if (attrId == null) {
          return curr;
        }

        return { ...curr, [obj.id]: attrId };
      }

      return curr;
    },
    {} as Record<BusinessObjectId, AttributeId | undefined>,
  );

  const objectFieldsGroupedByAttrId = lodashGroupBy(
    objects.filter((obj) => {
      const val = objIdToAttrId[obj.id];
      return val != null && getAttr(val) != null;
    }),
    (obj) => objIdToAttrId[obj.id],
  );

  const objectIdsWithUnsetField: BusinessObjectId[] = objects
    .filter((obj) => {
      const val = objIdToAttrId[obj.id];
      return val == null || getAttr(val) == null;
    })
    .map((obj) => obj.id);

  const attributesWithCorrespondingObjects = Object.keys(objectFieldsGroupedByAttrId);
  const groupsForNewlyCreatedAttributes = makeGroupsForNewlyCreatedAttributes({
    newlyCreatedAttributeIds,
    attributesWithCorrespondingObjects,
    dimensionId,
    fieldSpecId: objectFieldIdToGroupBy,
    dimensionsById,
  });

  return [
    ...Object.entries(objectFieldsGroupedByAttrId).map(([attrId, objs]) => {
      const matchedAttributeValue = getAttr(attrId);
      const attributeId = matchedAttributeValue?.id ?? EMPTY_ATTRIBUTE_ID;
      const groupInfo: GroupInfo = {
        groupingType: 'attributeObjectField',
        attributeId,
        dimensionId,
        fieldSpecId: objectFieldIdToGroupBy,
        key: `${objectFieldIdToGroupBy},${dimensionId},${attributeId}`,
      };

      const rows: GroupRow[] = objs.map((obj) => ({ type: 'object' as const, objectId: obj.id }));

      return {
        groupInfo,
        rows,
      };
    }),
    ...(objectIdsWithUnsetField.length > 0
      ? [
          {
            groupInfo: {
              attributeId: EMPTY_ATTRIBUTE_ID,
              groupingType: 'attributeObjectField' as const,
              dimensionId,
              fieldSpecId: objectFieldIdToGroupBy,
              key: `${objectFieldIdToGroupBy},${dimensionId}`,
              forceShowEvenWhenEmpty: undefined,
            },
            rows: objectIdsWithUnsetField.map((id) => ({ type: 'object' as const, objectId: id })),
          },
        ]
      : []),
    ...groupsForNewlyCreatedAttributes,
  ].filter(
    (grouping) =>
      grouping.rows.length > 0 ||
      (grouping.groupInfo.groupingType === 'attributeObjectField' &&
        grouping.groupInfo.forceShowEvenWhenEmpty),
  );
}
