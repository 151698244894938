import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { getMonthKeysForRange } from 'helpers/dates';
import useAppSelector from 'hooks/useAppSelector';
import { datasetLastActualsDateTimeSelector } from 'selectors/lastActualsSelector';

/**
 *  Get the list of month keys which should be used for rendering forecasts in charts.
 *  Uses the date range and lastClose to figure out the first forecast month.
 */
const useChartForecastMonthKeys = (startDateTime: DateTime, endDateTime: DateTime) => {
  // Fetch the bordering date between actuals and forecast
  const actualsEndDateTime = useAppSelector(datasetLastActualsDateTimeSelector);

  // If actuals exist, then exclude the actuals end date from the forecast range
  const monthKeysWithForecastData = useMemo(() => {
    if (startDateTime > actualsEndDateTime) {
      return getMonthKeysForRange(startDateTime, endDateTime);
    }

    return getMonthKeysForRange(actualsEndDateTime, endDateTime);
  }, [actualsEndDateTime, startDateTime, endDateTime]);

  return monthKeysWithForecastData;
};

export default useChartForecastMonthKeys;
