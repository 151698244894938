import * as Sentry from '@sentry/nextjs';
import { deepEqual } from 'fast-equals';
import { memoize, omitBy } from 'lodash';

import { uuidv5 } from 'helpers/uuidv4';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';

export const getObjectSpecKey = (
  specId: BusinessObjectSpecId,
  fieldSpecId?: BusinessObjectFieldSpecId,
  namedVersionId?: string,
): string => {
  return `${specId},${fieldSpecId},${namedVersionId}`;
};

export const getObjectFieldUUID = memoize(
  (objectId: BusinessObjectId, fieldSpecId: BusinessObjectFieldSpecId) => {
    try {
      return uuidv5(fieldSpecId, objectId);
    } catch (err) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          objectId,
          fieldSpecId,
        });
        Sentry.captureException(err);
      });
      throw err;
    }
  },
  (objectId: BusinessObjectId, fieldSpecId: BusinessObjectFieldSpecId) =>
    `${objectId}:${fieldSpecId}`,
);

export function isEqualIgnoringNullish(obj1: object, obj2: object) {
  return deepEqual(
    omitBy(obj1, (v) => v == null),
    omitBy(obj2, (v) => v == null),
  );
}
