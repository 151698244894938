import * as Sentry from '@sentry/nextjs';

import { exhaustiveGuard } from 'helpers/exhaustiveGuard';
import { routeTo } from 'helpers/navigation';
import {
  NavigateOverrides,
  ParsedQueryInput,
  buildUrlFromState,
} from 'reduxStore/actions/navigateTo/buildUrlFromState';
import { LayerId } from 'reduxStore/models/layers';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { detailPaneSelector, isDetailPaneOpenSelector } from 'selectors/detailPaneSelectors';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';

export const navigateToLayer = (
  layerId: LayerId,
  overrides: NavigateOverrides = {},
): AppThunk<Promise<void>> => {
  return async (_dispatch, getState) => {
    try {
      await confirmLayerExists(getState, layerId, 10);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }

    const state = getState();
    const selectedOrg = selectedOrgSelector(state);
    const detailPaneState = detailPaneSelector(state);
    const isDetailPaneOpen = isDetailPaneOpenSelector(state);
    let query: ParsedQueryInput | undefined;
    if (isDetailPaneOpen && detailPaneState != null) {
      const { type } = detailPaneState;
      switch (type) {
        case PaneType.Driver:
        case PaneType.DimensionalDriver: {
          query = { detail: type, detailId: detailPaneState.driverId };
          break;
        }
        case PaneType.Object: {
          query = { detail: PaneType.Object, detailId: detailPaneState.objectId };
          break;
        }
        case PaneType.Plan: {
          query = { detail: PaneType.Plan, detailId: detailPaneState.eventGroupId };
          break;
        }
        default: {
          exhaustiveGuard(type);
          break;
        }
      }
    }

    if (selectedOrg == null) {
      return;
    }

    const url = buildUrlFromState(state, { ...overrides, layerId }, query);
    if (url == null) {
      return;
    }

    routeTo(url);
  };
};

// NOTE: If the layer ID comes from a REST/GraphQL endpoint and not from the websocket,
// we have to wait until the websocket layer ID becomes available.
// This is a hack to await its presence. In the future, would prefer to apply the mutation directly.
async function confirmLayerExists(getState: () => RootState, layerId: LayerId, retry: number) {
  let state = getState();
  let newLayer = state.dataset.layers[layerId];
  for (let index = 0; index < retry; index++) {
    if (newLayer != null && !newLayer.isDeleted) {
      return;
    }

    await sleep(500);
    state = getState();
    newLayer = state.dataset.layers[layerId];
  }

  if (newLayer == null || newLayer.isDeleted) {
    throw new Error(`Failed to get layer with id ${layerId} after ${retry + 1} attempts`);
  }
}

function sleep(millis: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, millis);
  });
}
