import { createSelector } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { DriverGroup, DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { DriverGroupExpansionState } from 'reduxStore/reducers/modelViewSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { fieldSelector, paramSelector } from 'selectors/constSelectors';
import { driverGroupEntityTableForLayerSelector } from 'selectors/driverGroupEntityTableSelector';
import { navSubmodelIdSelector } from 'selectors/navSubmodelSelector';
import { ParametricSelector, Selector } from 'types/redux';

export const NO_GROUP_ID = 'NO_GROUP';

export const driverGroupsByIdSelector: SelectorWithLayerParam<Record<DriverGroupId, DriverGroup>> =
  createCachedSelector(addLayerParams(driverGroupEntityTableForLayerSelector), (driverGroups) => {
    return driverGroups.byId;
  })(getCacheKeyForLayerSelector);

export const allDriverGroupListSelector = createSelector(driverGroupsByIdSelector, (groupsById) =>
  Object.values(groupsById),
);

const collapsedSubmodelGroupsSelector: Selector<DriverGroupExpansionState> = (state: RootState) =>
  state.modelView.collapsedGroupKeysBySubmodelId ?? {};

export const collapsedGroupIdsForCurrentSubmodelPageSelector: Selector<DriverGroupId[]> =
  createSelector(
    collapsedSubmodelGroupsSelector,
    navSubmodelIdSelector,
    (collapsedGroupKeysBySubmodelId, submodelId) => {
      if (submodelId == null) {
        return [];
      }

      return collapsedGroupKeysBySubmodelId[submodelId] ?? [];
    },
  );

const collapsedGroupIdsForSubmodelSelector: ParametricSelector<SubmodelId, DriverGroupId[]> =
  createCachedSelector(
    collapsedSubmodelGroupsSelector,
    paramSelector<SubmodelId>(),
    (collapsedGroupKeysBySubmodelId, submodelId) => {
      return collapsedGroupKeysBySubmodelId[submodelId] ?? [];
    },
  )((_state, submodelId) => submodelId);

const isGroupCollapsed = (collapsedGroupKeys: DriverId[], groupId: DriverGroupId | undefined) => {
  if (groupId == null) {
    return false;
  }

  if (collapsedGroupKeys.length === 0) {
    return false;
  }

  return collapsedGroupKeys.includes(groupId);
};

export const isGroupIdForCurrentSubmodelPageCollapsedSelector: ParametricSelector<
  DriverGroupId | undefined,
  boolean
> = createCachedSelector(
  collapsedGroupIdsForCurrentSubmodelPageSelector,
  paramSelector<DriverGroupId | undefined>(),
  isGroupCollapsed,
)((_state, groupId) => groupId ?? NO_GROUP_ID);

type SubmodelAndGroupId = {
  submodelId: SubmodelId;
  groupId: DriverGroupId | undefined;
};
export const isSubmodelGroupCollapsedSelector: ParametricSelector<SubmodelAndGroupId, boolean> =
  createCachedSelector(
    (state, { submodelId }) => collapsedGroupIdsForSubmodelSelector(state, submodelId),
    fieldSelector<SubmodelAndGroupId, 'groupId'>('groupId'),
    isGroupCollapsed,
  )((_state, { submodelId, groupId }) => `${submodelId}-${groupId ?? NO_GROUP_ID}`);
