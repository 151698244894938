import { BoxProps } from '@chakra-ui/react';
import classNames from 'classnames';

import DropTargets from 'components/Reorderable/DropTargets';
import useReorderable, { BaseDragItem, UseReorderableProps } from 'hooks/useReorderable';

import styles from './Reorderable.module.scss';

export type ReorderableProps<T extends BaseDragItem> = UseReorderableProps<T> & {
  children?: React.ReactNode;
  isLast: boolean;
  orientation?: 'vertical' | 'horizontal';
  leftOffset?: BoxProps['left'];
};

// This component simplifies implementing draggable components. The drag target
// and the drop targtes are both defined by the child component passed in. To
// handle more complex cases (e.g. different drag/drop targets) you may want to
// use useReorderable directly.
const Reorderable = <T extends BaseDragItem>({
  itemType,
  item,
  isLast,
  onDrop,
  onDragStart,
  disabled = false,
  orientation = 'vertical',
  leftOffset,
  children,
}: ReorderableProps<T>) => {
  const { dragRef, startDropRef, endDropRef, isOverStart, isOverEnd, isDragging } = useReorderable({
    itemType,
    item,
    onDrop,
    disabled,
  });
  return (
    <div
      data-testid="reorderable-container"
      className={classNames(styles.reorderable, {
        [styles.isEnabled]: !disabled,
      })}
      ref={dragRef}
      onDragStart={onDragStart}
    >
      {children}
      {isDragging && !disabled && (
        <DropTargets
          startDropRef={startDropRef}
          endDropRef={endDropRef}
          orientation={orientation}
          isLast={isLast}
          isOverStart={isOverStart}
          isOverEnd={isOverEnd}
          leftOffset={leftOffset}
        />
      )}
    </div>
  );
};
export default Reorderable;
