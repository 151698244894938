import noop from 'lodash/noop';
import { createContext, useContext } from 'react';

import { EntityInfo, FilterItem } from 'types/filtering';

export type BlockFilterContextState<T extends FilterItem> = {
  entityInfo?: EntityInfo;
  onDoneFilters?: () => void;
  onUpdateFilters: (newFilters: T[]) => void;
  // The filters that are actually persisted
  activeFilters: T[];
  // The filters currently (possibly partially) configured in the UI
  filters: T[];
  availableFilters: T[];
  includeAllContextAttributes?: boolean;
  toggleIncludeAllContextAttributes?: () => void;
  matchToSingleResult?: boolean;
  toggleMatchToSingleResult?: () => void;
};

const BlockFilterContext = createContext<BlockFilterContextState<any>>({
  onUpdateFilters: noop,
  activeFilters: [],
  filters: [],
  availableFilters: [],
});

type FilterProviderProps<T extends FilterItem> = {
  value: BlockFilterContextState<T>;
  children: React.ReactNode;
};

export const BlockFilterContextProvider = <T extends FilterItem>({
  value,
  children,
}: FilterProviderProps<T>) => {
  return <BlockFilterContext.Provider value={value}>{children}</BlockFilterContext.Provider>;
};

export function useBlockFilterContext<T extends FilterItem>(): BlockFilterContextState<T> {
  return useContext<BlockFilterContextState<T>>(BlockFilterContext);
}
