/**
 * This used to be its own slice, but we've since consolidated the flags into
 * the datasetSlice since we need convenient access to the flags there.
 */
import { CalculationEngine } from 'selectors/calculationEngineSelector';
import { MonthKey } from 'types/datetime';

export interface LaunchDarklyFlags {
  hardRefreshMaxAgeDays?: number;
  softRefreshMaxAgeDays?: number;

  hardRefreshMinTimestamp?: number;
  softRefreshMinTimestamp?: number;

  disableAgGridCsvExport?: boolean;
  enableAgCharts?: boolean;
  enableAgGridDriverTables?: boolean;

  enableEnhancedPlansEntrypointInternal?: boolean;
  enableStackedImpacts?: boolean;

  earliestFormulaEvaluationMonthKey?: MonthKey;
  calculationEngine?: CalculationEngine;
  enableDatabaseDriverAggregations?: boolean;
  enableBranchingFromLayers?: boolean;
  enableMergeCustomQueries?: boolean;
  enableAiExplanationsInFrontend?: boolean;
  maintenanceMode?: {
    estimatedEndISO: string;
    startTimeISO: string;
    title: string;
    warnStartTimeISO: string;
  };
  enableDatabaseShaping?: boolean;
  enableBlockLayouts?: boolean;
  enableNewMergeScreen?: boolean;
  enableDefaultFormula?: boolean;
  maxExttableRows?: number;
  maxDatabaseRows?: number;
  extTableLimitEmail?: string;
  enableEagerSubDriverInitialization?: boolean;
  enableTreemapCharts?: boolean;
  enableWaterfallCharts?: boolean;
  enableTimePeriodComparisons?: boolean;
  enableDriverThisSegment?: boolean;
  enableBvaInDatabases?: boolean;
  enableBackendOnlyLiveEdit?: boolean;
  enableDateDrivers?: boolean;
  enableDarkMode?: boolean;
}

export type MaintenanceModeConfig = LaunchDarklyFlags['maintenanceMode'];

export interface LaunchDarklySliceState {
  flags: LaunchDarklyFlags;
  isInitialized: boolean;
  isEnabled: boolean;
}

export const initialState: LaunchDarklySliceState = {
  flags: {},
  isInitialized: false,
  isEnabled: false,
};
