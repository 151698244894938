import { CellType } from 'config/cells';
import { isImpactCellRef } from 'helpers/cells';
import { getMonthValueImpact } from 'helpers/events';
import { formatDriverCellValuesForClipboard } from 'reduxStore/actions/cellCopyPaste';
import { roadmapCellDeleteSelected } from 'reduxStore/actions/timelineCells';
import { isNumericFieldSpec } from 'reduxStore/models/businessObjectSpecs';
import { isDriverEvent, isObjectFieldEvent } from 'reduxStore/models/events';
import {
  DEFAULT_DISPLAY_CONFIGURATION,
  Value,
  toNumberValueOrUndefined,
} from 'reduxStore/models/value';
import { setCopiedCells } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import {
  businessObjectFieldByIdForLayerSelector,
  businessObjectFieldSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectFieldForecastTimeSeriesSelector } from 'selectors/businessObjectTimeSeriesSelector';
import { businessObjectForFieldIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { driverTimeSeriesSelector } from 'selectors/driverTimeSeriesSelector';
import {
  driverDisplayConfigurationSelector,
  fieldSpecDisplayConfigurationSelector,
} from 'selectors/entityDisplayConfigurationSelector';
import {
  getSelectedTimelineCellEventsByMonthKey,
  selectedTimelineCellSelector,
} from 'selectors/selectedTimelineCellSelector';
import { prevailingSelectionBlockIdSelector } from 'selectors/selectionSelector';

export const eventCopyCell =
  ({
    setClipboardData,
    isCut,
  }: {
    setClipboardData: (data: string) => void;
    isCut: boolean;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const selectedEventsByMonthKey = getSelectedTimelineCellEventsByMonthKey(state);
    const cellSelection = selectedTimelineCellSelector(state);
    if (cellSelection == null) {
      return;
    }
    let blockId = cellSelection.blockId;
    if (blockId == null) {
      blockId = prevailingSelectionBlockIdSelector(state);
    }
    const { activeCell, selectedCells } = cellSelection;
    if (!isImpactCellRef(activeCell)) {
      return;
    }

    const selectedMonthKeys = selectedCells.map((c) => c.columnKey.monthKey).sort();

    // Assume displayConfig for all events are the same
    const firstEvent = Object.values(selectedEventsByMonthKey)[0];
    let displayConfig = DEFAULT_DISPLAY_CONFIGURATION;
    if (isDriverEvent(firstEvent)) {
      displayConfig = driverDisplayConfigurationSelector(state, firstEvent.driverId);
    } else {
      const field =
        businessObjectFieldByIdForLayerSelector(state)[firstEvent.businessObjectFieldId];
      const fieldSpec = field.fieldSpec;
      if (field != null && isNumericFieldSpec(fieldSpec)) {
        displayConfig = fieldSpecDisplayConfigurationSelector(state, fieldSpec.id);
      }
    }

    const isImpact = activeCell.type === CellType.Impact;
    const values: Array<Value | undefined> = selectedMonthKeys.map((mk) => {
      const event = selectedEventsByMonthKey[mk];
      if (event == null || event.customCurvePoints == null) {
        return undefined;
      }

      if (isImpact) {
        return getMonthValueImpact(event, mk);
      } else {
        if (isDriverEvent(event)) {
          const timeSeries = driverTimeSeriesSelector(state, { id: event.driverId });
          return toNumberValueOrUndefined(timeSeries[mk]);
        } else if (isObjectFieldEvent(event)) {
          const field = businessObjectFieldSelector(state, {
            id: event.businessObjectFieldId,
          });
          const object = businessObjectForFieldIdForLayerSelector(
            state,
            event.businessObjectFieldId,
          );
          if (field == null || object == null) {
            return undefined;
          }
          const timeSeries = businessObjectFieldForecastTimeSeriesSelector(state, {
            businessObjectId: object.id,
            businessObjectFieldSpecId: field?.fieldSpecId,
            blockId: blockId ?? '',
          });
          return timeSeries[mk];
        }
        return undefined;
      }
    });

    setClipboardData(formatDriverCellValuesForClipboard(values, displayConfig));
    dispatch(setCopiedCells({ blockId, selectedCells }));
    if (isCut) {
      dispatch(roadmapCellDeleteSelected());
    }
  };
