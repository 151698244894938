import { createSelector } from '@reduxjs/toolkit';

import { ExtSource, getExtSource } from 'helpers/integrations';
import { BlocksPage, BlocksPageId } from 'reduxStore/models/blocks';
import { blocksPagesSelector } from 'selectors/blocksPagesTableSelector';
import { linkedAccountsSelector } from 'selectors/integrationsSelector';
import { Selector } from 'types/redux';

// TODO: why are we using string parsing to differentiate internal pages?
export const dataSourceLinkedObjectPagesSelector: Selector<BlocksPage[]> = createSelector(
  blocksPagesSelector,
  (allPages) =>
    allPages.filter(
      (page) =>
        page.internalPageType != null && page.internalPageType.endsWith('-linkedObjectsPage'),
    ),
);

export const dataSourceLinkedObjectPageIdsToDeleteSelector: Selector<BlocksPageId[]> =
  createSelector(
    dataSourceLinkedObjectPagesSelector,
    linkedAccountsSelector,
    (pages, linkedAccounts) => {
      const sources: ExtSource[] = linkedAccounts.map((la) => getExtSource(la.integration));
      const pagesForUnlinkedSources = pages.filter(
        (page) => !sources.some((source) => page.internalPageType?.startsWith(source) ?? false),
      );
      return pagesForUnlinkedSources.map((page) => page.id);
    },
  );
