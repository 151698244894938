import { OBJECT_TABLE_BLOCK_UNDEFINED_OPTION_ID_PLACEHOLDER } from 'config/block';
import { GroupInfo } from 'config/businessObjects';

export const isObjectTableGroupExpanded = (
  groupInfo: GroupInfo,
  collapsed: string[] | undefined | null,
): boolean => {
  if (collapsed == null) {
    return true;
  }

  const { groupingType } = groupInfo;
  switch (groupingType) {
    case 'attributeObjectField': {
      const { attributeId } = groupInfo;
      return !collapsed.includes(attributeId ?? OBJECT_TABLE_BLOCK_UNDEFINED_OPTION_ID_PLACEHOLDER);
    }
    default: {
      return true;
    }
  }
};
