import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { DimensionalProperty, DriverProperty } from 'reduxStore/models/collections';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { blockConfigShowRestrictedSelector } from 'selectors/blocksSelector';
import { businessObjectSpecSelector } from 'selectors/businessObjectSpecsSelector';
import { paramSelector } from 'selectors/constSelectors';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import { ParametricSelector } from 'types/redux';

export const hasBusinessObjectNameRestrictionsSelector: ParametricSelector<
  BusinessObjectSpecId,
  boolean
> = createCachedSelector(businessObjectSpecSelector, (spec) => {
  if (spec == null) {
    return false;
  }

  return spec?.isRestricted ?? false;
})((_state, objectSpecId) => objectSpecId);

type BusinessObjectNameRestrictedProps = {
  objectSpecId: BusinessObjectSpecId;
  blockId?: BlockId;
};

export const isBusinessObjectNameRestrictedSelector: ParametricSelector<
  BusinessObjectNameRestrictedProps,
  boolean
> = createCachedSelector(
  (state: RootState, props: BusinessObjectNameRestrictedProps) =>
    hasBusinessObjectNameRestrictionsSelector(state, props.objectSpecId),
  (state: RootState, props: BusinessObjectNameRestrictedProps) => {
    if (props.blockId == null) {
      return false;
    }

    return blockConfigShowRestrictedSelector(state, props.blockId);
  },
  accessCapabilitiesSelector,
  (isRestricted, showRestrictedForBlock, accessCapabilities) => {
    if (showRestrictedForBlock) {
      return false;
    }

    if (accessCapabilities.canWritePermissions) {
      return false;
    }

    return isRestricted;
  },
)((_, props: BusinessObjectNameRestrictedProps) => `${props.blockId}.${props.objectSpecId}`);

export const isBlockBusinessObjectNameRestrictedSelector: ParametricSelector<BlockId, boolean> =
  createSelector(
    (state: RootState) => state,
    paramSelector<BlockId>(),
    businessObjectSpecForBlockSelector,
    (state, blockId, objectSpec) => {
      return (
        objectSpec != null &&
        isBusinessObjectNameRestrictedSelector(state, {
          objectSpecId: objectSpec.id,
          blockId,
        })
      );
    },
  );

type FieldInfo = { id: string; name: string } & (
  | {
      type: 'fieldSpec';
      fieldSpec: BusinessObjectFieldSpec;
    }
  | {
      type: 'dimensionalProperty';
      dimensionalProperty: DimensionalProperty;
    }
  | {
      type: 'driverProperty';
      driverProperty: DriverProperty;
    }
);

export const businessObjectSpecNonRestrictedFieldsSelector: ParametricSelector<
  BusinessObjectNameRestrictedProps,
  FieldInfo[]
> = createCachedSelector(
  (state: RootState, props: BusinessObjectNameRestrictedProps) =>
    businessObjectSpecSelector(state, props.objectSpecId),
  (state: RootState, props: BusinessObjectNameRestrictedProps) => {
    if (props.blockId == null) {
      return false;
    }

    return blockConfigShowRestrictedSelector(state, props.blockId);
  },
  accessCapabilitiesSelector,
  driversByIdForLayerSelector,
  (objectSpec, showRestricted, accessCapabilities, driversById) => {
    const fieldInfoById: Map<string, FieldInfo> = new Map();
    const objectSpecFields = objectSpec != null ? objectSpec.fields : [];
    if (showRestricted || accessCapabilities.canWritePermissions) {
      objectSpecFields.forEach((field) => {
        fieldInfoById.set(field.id, {
          id: field.id,
          name: field.name,
          type: 'fieldSpec',
          fieldSpec: field,
        });
      });
    } else {
      const restricted = objectSpecFields.filter((field) => !field.isRestricted);
      restricted.forEach((field) => {
        fieldInfoById.set(field.id, {
          id: field.id,
          name: field.name,
          type: 'fieldSpec',
          fieldSpec: field,
        });
      });
    }
    //TODO: Filter out restricted driver properties & dimensional properties when these properties support restrictions. T-15687
    objectSpec?.collection?.dimensionalProperties?.forEach((dimProperty) => {
      fieldInfoById.set(dimProperty.id, {
        id: dimProperty.id,
        name: dimProperty.name,
        type: 'dimensionalProperty',
        dimensionalProperty: dimProperty,
      });
    });
    objectSpec?.collection?.driverProperties?.forEach((driverProperty) => {
      const driver = driversById[driverProperty.driverId];
      if (driver == null) {
        return;
      }
      fieldInfoById.set(driverProperty.id, {
        id: driverProperty.id,
        name: driver.name,
        type: 'driverProperty',
        driverProperty,
      });
    });
    return [...fieldInfoById.values()];
  },
)((_state, props) => `${props.objectSpecId}.${props.blockId}`);
