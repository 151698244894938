import { CellRef, CellType } from 'config/cells';
import { isDatabasePage } from 'config/internalPages/databasePage';
import { isSubmodelBlockPage, unlistedDriversPage } from 'config/internalPages/modelPage';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { ComparisonColumn } from 'generated/graphql';
import { NavigationOptions } from 'helpers/navigation';
import { safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { createInternalPage } from 'reduxStore/actions/internalPages/createInternalPage';
import {
  navigateToBlocksPage,
  navigateToSubmodelPage,
  navigateToUnlistedDriversPage,
} from 'reduxStore/actions/navigateTo';
import { DriverId } from 'reduxStore/models/drivers';
import { toggleDriverGroupExpansion } from 'reduxStore/reducers/modelViewSlice';
import {
  navigatingToCell,
  propagateBlocksPageFromUrl,
  propagateSubModelPageFromUrl,
  selectSingleCell,
} from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppDispatch, AppThunk } from 'reduxStore/store';
import { blocksPagesByBlockIdSelector } from 'selectors/blocksPagesSelector';
import {
  isUnlistedByDriverIdSelector,
  unlistedDriversPageBlockIdSelector,
} from 'selectors/blocksSelector';
import {
  dimensionalPropertyEvaluatorSelector,
  driverPropertyIdByDimDriverIdSelector,
  driverPropertySelector,
} from 'selectors/collectionSelector';
import { isSubmodelGroupCollapsedSelector } from 'selectors/driverGroupSelector';
import { dimensionalDriversBySubDriverIdSelector, driverSelector } from 'selectors/driversSelector';
import {
  comparisonLayerCountSelector,
  isBlockColumnComparisonLayoutSelector,
} from 'selectors/rollupSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { submodelIdByBlockIdSelector } from 'selectors/submodelPageSelector';

export const jumpToDriver = ({
  driverId,
  opts = { openInNewTab: false },
  blockId: blockIdProvided,
}: {
  driverId: DriverId;
  opts?: NavigationOptions;
  blockId?: string;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const submodelIdByBlockId = submodelIdByBlockIdSelector(state);
    const driver = driverSelector(state, { id: driverId });
    if (driver == null) {
      return;
    }

    if (blockIdProvided != null) {
      navigateToAndSelectDriver(state, dispatch, {
        blockId: blockIdProvided,
        driverId,
        groupId:
          driver.driverReferences?.find((driverRef) => driverRef.blockId === blockIdProvided)
            ?.groupId ?? undefined,
      });
      return;
    }

    const isUnlisted = isUnlistedByDriverIdSelector(state)[driverId];
    let unlistedDriversPageBlockId = unlistedDriversPageBlockIdSelector(state);

    if (isUnlisted) {
      // If the unlisted drivers page doesn't exist yet, create it.
      if (unlistedDriversPageBlockId == null) {
        unlistedDriversPageBlockId = uuidv4();
        dispatch(
          createInternalPage(
            unlistedDriversPage.makeDefault({ blockId: unlistedDriversPageBlockId }),
          ),
        );
      }

      dispatch(navigateToUnlistedDriversPage());

      navigateToAndSelectDriver(state, dispatch, {
        blockId: unlistedDriversPageBlockId,
        driverId,
      });
      return;
    }

    const blockPagesByBlockId = blocksPagesByBlockIdSelector(state);
    const blockId = driver.driverReferences?.find(
      ({ blockId: id }) => id !== unlistedDriversPageBlockId && blockPagesByBlockId[id] != null,
    )?.blockId;

    if (blockId == null) {
      console.warn('Could not find a block to navigate to for driver', driverId);
      return;
    }

    const page = blockPagesByBlockId[blockId];

    if (isDatabasePage(page?.internalPageType)) {
      const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);
      const objectId = dimensionalPropertyEvaluator.getBusinessObjectIdBySubDriverId(driver.id);
      if (objectId == null) {
        return;
      }

      const dimDriversBySubDriverId = dimensionalDriversBySubDriverIdSelector(state);
      const dimDriver = safeObjGet(dimDriversBySubDriverId[driver.id]);
      if (dimDriver == null) {
        return;
      }

      const driverPropertyIdByDimDriverId = driverPropertyIdByDimDriverIdSelector(state);
      const driverPropertyId = driverPropertyIdByDimDriverId[dimDriver.id];
      const driverProperty = driverPropertySelector(state, driverPropertyId);
      if (driverProperty == null) {
        return;
      }

      const columnId = driverProperty.id;
      dispatch(propagateBlocksPageFromUrl(page.id));
      dispatch(navigateToBlocksPage(page.id, { ...opts, query: { scrollTo: objectId, columnId } }));
      return;
    }

    if (isSubmodelBlockPage(page?.internalPageType)) {
      const submodelId = submodelIdByBlockId[blockId]!;

      const groupId =
        driver.driverReferences?.find((ref) => ref.blockId === blockId)?.groupId ?? undefined;

      const isGroupCollapsed = isSubmodelGroupCollapsedSelector(state, { submodelId, groupId });

      if (isGroupCollapsed && groupId != null) {
        dispatch(toggleDriverGroupExpansion({ submodelId, driverGroupId: groupId }));
      }

      dispatch(propagateSubModelPageFromUrl({ id: submodelId }));
      dispatch(navigateToSubmodelPage(submodelId, opts));
      navigateToAndSelectDriver(state, dispatch, { blockId, driverId, groupId });
      return;
    }

    if (page != null) {
      dispatch(propagateBlocksPageFromUrl(page.id));
      dispatch(navigateToBlocksPage(page.id, opts));
      navigateToAndSelectDriver(state, dispatch, { blockId, driverId });
    }
  };
};

const navigateToAndSelectDriver = (
  state: RootState,
  dispatch: AppDispatch,
  { blockId, driverId, groupId }: { blockId: string; driverId: string; groupId?: string },
) => {
  /**
   * Note: when there are 0 comparison layers, comparisonLayerCount is 0, when
   * there are n comparison layers, comparisonLayerCount is n + 1 with the base
   * later as the first layer.
   */
  const comparisonLayerCount = comparisonLayerCountSelector(state, blockId);
  const isBlockColumnComparisonLayout = isBlockColumnComparisonLayoutSelector(state, blockId);
  /**
   * This code imitates what happens when you click a driver name cell.
   * If there are 0 comparison layers or you are in the column comparison layer
   * when you click the cell, the driver's group ID is set on the active cell
   * when that cell is selected.
   * Otherwise it gets set to undefined.
   */
  const groupIdIsRequiredForSelectingTheCell =
    comparisonLayerCount === 0 || isBlockColumnComparisonLayout;

  /**
   * This code imitates what happens when you click a driver name cell.
   * If the page is in the column layout and showing 1 comparison layer, the layer
   * ID of the active cell is set to the comparison layer's ID. If there are 0 or 2+
   * comparison layers, the layer ID on the active cell is set to undefined.
   * If there are 2+ comparison layers, the active cell also has a comparisonType field,
   * which needs to be set.
   */
  let layerId;
  let comparisonType: ComparisonColumn | undefined;
  if (isBlockColumnComparisonLayout) {
    if (comparisonLayerCount === 2) {
      layerId = comparisonLayerIdsForBlockSelector(state, blockId)[1];
    } else if (comparisonLayerCount > 2) {
      comparisonType = ComparisonColumn.RowVersion;
    }
  }

  const cellRef: CellRef = {
    type: CellType.Driver,
    rowKey: {
      driverId,
      layerId,
      groupId: groupIdIsRequiredForSelectingTheCell ? groupId : undefined,
      ...(comparisonType != null ? { comparisonType } : {}),
    },
    columnKey: { columnType: NAME_COLUMN_TYPE, columnLayerId: undefined },
  };

  dispatch(navigatingToCell({ hasNavigatedToGroup: false, cellRef }));
  dispatch(
    selectSingleCell({
      blockId,
      cellRef,
    }),
  );
};
