import { Box, StyleProps, theme as globalTheme, useTheme } from '@chakra-ui/react';
import { getCSSVar } from '@chakra-ui/utils';
import classNames from 'classnames';
import React from 'react';

import CellBorder from 'components/CellBorder/CellBorder';
import { CELL_HEIGHT_IN_PX_STR } from 'config/cells';
import { useBGColorForCell } from 'hooks/useBgColorForCell';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';

import styles from './TableCell.module.scss';

interface Props {
  children?: React.ReactNode;
  isLastSticky?: boolean;
  isSticky?: boolean;
  isExpandable?: boolean;
  left?: StyleProps['left'];
  width?: StyleProps['width'];
  isGroupHeading?: boolean;
  justifyContent?: StyleProps['justifyContent'] & 'center';
}

const TableCellLight = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      isLastSticky = false,
      isSticky = false,
      isExpandable = false,
      left,
      width,
      isGroupHeading = false,
      justifyContent,
    },
    passedRef,
  ) => {
    const { isActive } = useCellSelectionStateContext();
    const bgColor = useBGColorForCell();
    const theme = useTheme();
    const bgColorVar = getCSSVar(theme, 'colors', bgColor) as string;
    return (
      <div
        ref={passedRef}
        data-sentry-mask
        style={{
          width: `${width}px`,
          backgroundColor: bgColorVar,
          height: CELL_HEIGHT_IN_PX_STR,
          left: isSticky ? `${left}px` : undefined,
          // avoid borders being clipped when active
          zIndex: isActive
            ? globalTheme.zIndices.docked + 1
            : isSticky
              ? globalTheme.zIndices.docked
              : undefined,
        }}
        className={classNames(styles.tableCellBox, {
          [styles.isExpandable]: isExpandable,
          [styles.isSticky]: isSticky,
          [styles.isGroupHeading]: isGroupHeading,
        })}
      >
        <div
          className={classNames(styles.tableCell, {
            [styles.noPointerEvents]: true,
          })}
          style={justifyContent != null ? { justifyContent } : undefined}
        >
          {children}
        </div>
        <CellBorder />
        {isLastSticky && !isActive && (
          <Box
            h="full"
            w="px"
            zIndex="sticky"
            position="absolute"
            right={0}
            bgColor="gray.400"
            top={0}
          />
        )}
      </div>
    );
  },
);

TableCellLight.displayName = 'TableCellLight';

export default React.memo(TableCellLight);
