import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import {
  AccountingProperties,
  AllIntegrationsDocument,
  AllIntegrationsQuery,
  Integration,
  IntegrationQueryRun,
  IntegrationQueryRunStatus,
  IntegrationStatus,
  LinkedAccount,
  SetLinkedIntegrationMetadataDocument,
  SetLinkedIntegrationMetadataMutation,
} from 'generated/graphql';
import { RemoteItemFetchData } from 'reduxStore/models/remoteItem';
import { validatePageIntegrationData } from 'reduxStore/reducers/pageSlice';
import { AsyncAppThunkConfig } from 'reduxStore/store';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';

export type Integrations = RemoteItemFetchData & {
  linked: LinkedAccount[];
  available: Integration[];
  merge: {
    accountingProperties: AccountingProperties;
  };
  runningIntegrationQueries: {
    [queryId: string]: IntegrationQueryRun;
  };
};

interface ToggleLinkedIntegrationMetadataArgs {
  linkedAccount: LinkedAccount;
  toToggle: 'propagateDataForward' | 'overrideForecastData';
}

const toggleLinkedIntegrationMetadata = createAsyncThunk<
  void,
  ToggleLinkedIntegrationMetadataArgs,
  AsyncAppThunkConfig
>(
  'integrations/toggleLinkedIntegrationMetadata',
  async ({ linkedAccount, toToggle }, { getState, extra }) => {
    const state = getState();
    const org = selectedOrgSelector(state);

    if (org == null) {
      throw Error('could not find current org');
    }

    const orgId = org.id;
    const linkedAccountId = linkedAccount.id;
    const provider = linkedAccount.integration.provider;
    const overrideForecastData =
      toToggle === 'overrideForecastData'
        ? !linkedAccount.overrideForecastData
        : linkedAccount.overrideForecastData;
    const propagateDataForward =
      toToggle === 'propagateDataForward'
        ? !linkedAccount.propagateDataForward
        : linkedAccount.propagateDataForward;

    const { urqlClient } = extra;

    const response = await urqlClient
      .mutation<SetLinkedIntegrationMetadataMutation>(SetLinkedIntegrationMetadataDocument, {
        orgId,
        linkedAccountId,
        provider,
        propagateDataForward,
        overrideForecastData,
      })
      .toPromise();

    if (response.error != null) {
      throw response.error;
    }
  },
);

export const toggleOverrideForecastData = (linkedAccount: LinkedAccount) => {
  return toggleLinkedIntegrationMetadata({ linkedAccount, toToggle: 'overrideForecastData' });
};

export const togglePropagateDataForward = (linkedAccount: LinkedAccount) => {
  return toggleLinkedIntegrationMetadata({ linkedAccount, toToggle: 'propagateDataForward' });
};

type FetchIntegrationsResponse = {
  linked: LinkedAccount[];
  available: Integration[];
  merge: {
    accountingProperties: AccountingProperties;
  };
};

export const fetchIntegrations = createAsyncThunk<
  FetchIntegrationsResponse,
  void,
  AsyncAppThunkConfig
>('integrations/fetchIntegrations', async (_, { extra, getState, dispatch }) => {
  const { urqlClient } = extra;

  const state = getState();

  const org = selectedOrgSelector(state);
  if (org == null) {
    throw Error('could not find current org');
  }

  const response = await urqlClient
    .query<AllIntegrationsQuery>(AllIntegrationsDocument, { orgId: org?.id ?? '' })
    .toPromise();

  const integrations = response.data?.integrations;
  if (integrations == null) {
    throw Error("couldn't fetch integrations");
  }

  dispatch(validatePageIntegrationData(integrations));

  return {
    linked: integrations.linkedAccounts,
    available: integrations.availableIntegrations,
    merge: {
      accountingProperties: integrations.merge?.accountingProperties ?? {
        accounts: [],
        contacts: [],
        trackingCategories: [],
      },
    },
    runningIntegrationQueries: {},
  };
});

const initialState = {
  loading: 'idle',
  error: null,
  linked: [],
  available: [],
  merge: {
    accountingProperties: { accounts: [], contacts: [], trackingCategories: [] },
  },
  runningIntegrationQueries: {},
} as Integrations;

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    startIntegrationQueryRun(state, action: PayloadAction<{ queryId: string; runId: string }>) {
      const { queryId, runId } = action.payload;
      state.runningIntegrationQueries[queryId] = {
        id: runId,
        queryId,
        status: IntegrationQueryRunStatus.NotStarted,
        startedAt: DateTime.now().toISO(),
      };
    },
    cancelIntegrationQueryRun(state, action: PayloadAction<{ queryId: string; runId: string }>) {
      const { queryId, runId } = action.payload;
      const isSelectedQueryRunning =
        queryId in state.runningIntegrationQueries &&
        state.runningIntegrationQueries[queryId]?.id === runId;

      if (!isSelectedQueryRunning) {
        // ignore updates if the query and run ID is not matching our redux state
        return;
      }
      state.runningIntegrationQueries[queryId] = {
        id: runId,
        queryId,
        status: IntegrationQueryRunStatus.Cancelled,
      };
    },
    updateIntegrationQueryRun(
      state,
      action: PayloadAction<{ queryId: string; run: IntegrationQueryRun }>,
    ) {
      const { queryId, run } = action.payload;
      const isSelectedQueryRunning =
        queryId in state.runningIntegrationQueries &&
        state.runningIntegrationQueries[queryId]?.id === run?.id;
      if (!isSelectedQueryRunning) {
        // ignore updates if the query and run ID is not matching our redux state
        return;
      }
      state.runningIntegrationQueries[queryId] = run;
    },
    setIntegrationAsLinked(state, action: PayloadAction<{ slug: string; id: string }>) {
      const alreadyLinked = state.linked.find(
        (linkedAccount) => linkedAccount.integration.slug === action.payload.slug,
      );
      if (alreadyLinked != null) {
        return;
      }
      const integrationToLink = state.available.find(
        (integration) => integration.slug === action.payload.slug,
      );
      if (integrationToLink == null || integrationToLink.categories[0] == null) {
        return;
      }

      state.linked = [
        ...state.linked,
        {
          id: action.payload.id,
          integration: integrationToLink,
          category: integrationToLink.categories[0],
          status: IntegrationStatus.Complete,
          overrideForecastData: false,
          propagateDataForward: false,
        },
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntegrations.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchIntegrations.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.linked = action.payload.linked;
        state.available = action.payload.available;
        state.merge = {
          accountingProperties: action.payload.merge.accountingProperties,
        };
      })
      .addCase(fetchIntegrations.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error;
        state.linked = [];
        state.available = [];
        state.merge = {
          accountingProperties: { accounts: [], contacts: [], trackingCategories: [] },
        };
      })
      .addMatcher(
        // If the async thunk is pending, optimistically update the local state.
        // If it later rejects, revert the local state by toggling again.
        // (If it later succeeds, there's nothing left to do.)
        (action: PayloadAction) => {
          return (
            action.type === 'integrations/toggleLinkedIntegrationMetadata/pending' ||
            action.type === 'integrations/toggleLinkedIntegrationMetadata/rejected'
          );
        },
        (
          state,
          action: ReturnType<(typeof toggleLinkedIntegrationMetadata)['pending' | 'rejected']>,
        ) => {
          state.linked = state.linked.map((linkedAccount) => {
            if (linkedAccount.id !== action.meta.arg.linkedAccount.id) {
              return linkedAccount;
            }
            const toggled = action.meta.arg.toToggle;

            return {
              ...linkedAccount,
              propagateDataForward:
                toggled === 'propagateDataForward'
                  ? !linkedAccount.propagateDataForward
                  : linkedAccount.propagateDataForward,
              overrideForecastData:
                toggled === 'overrideForecastData'
                  ? !linkedAccount.overrideForecastData
                  : linkedAccount.overrideForecastData,
            };
          });
        },
      );
  },
});

export const {
  startIntegrationQueryRun,
  cancelIntegrationQueryRun,
  updateIntegrationQueryRun,
  setIntegrationAsLinked,
} = integrationsSlice.actions;

export default integrationsSlice.reducer;
