import { Box, Portal } from '@chakra-ui/react';
import React from 'react';

interface Props {
  onClick?: React.MouseEventHandler;
}

// we don't want elements under the context to capture scroll or click events
const AppOverlay: React.FC<Props> = ({ onClick }) => {
  return (
    <Portal>
      <Box
        bottom={0}
        left={0}
        position="fixed"
        right={0}
        onClick={onClick}
        top={0}
        zIndex="overlay"
      />
    </Portal>
  );
};

export default AppOverlay;
