/**
 * Finds the nearest horizontal scroll parent of the given element. Potentially expensive operation. Use with caution.
 * @param elm the element to find the nearest horizontal scroll parent for
 * @returns the nearest horizontal scroll parent or null if none found
 */
export const findNearestHorizontalScrollParent = (elm?: HTMLElement | null): HTMLElement | null => {
  if (elm == null) {
    return null;
  }
  const style = getComputedStyle(elm);
  if (
    elm.scrollWidth > elm.clientWidth &&
    (style.overflowX === 'scroll' || style.overflowX === 'auto')
  ) {
    return elm;
  } else {
    return findNearestHorizontalScrollParent(elm.parentElement);
  }
};

/**
 * Finds the nearest vertical scroll parent of the given element. Potentially expensive operation. Use with caution.
 * @param elm the element to find the nearest vertical scroll parent for
 * @returns  the nearest vertical scroll parent or null if none found
 */
export const findNearestVerticalScrollParent = (elm?: HTMLElement | null): HTMLElement | null => {
  if (elm == null) {
    return null;
  }
  const style = getComputedStyle(elm);
  if (
    elm.scrollHeight > elm.clientHeight &&
    (style.overflowY === 'scroll' || style.overflowY === 'auto')
  ) {
    return elm;
  } else {
    return findNearestVerticalScrollParent(elm.parentElement);
  }
};
