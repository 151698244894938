import { JSONContent } from '@tiptap/react';
import isString from 'lodash/isString';

export function isTextBlockEmpty(input: string | JSONContent): boolean {
  let jsonContent = input;
  if (isString(input)) {
    try {
      jsonContent = JSON.parse(input) as JSONContent;
    } catch {
      // bail out if we can't parse; it means we're passing invalid input
      return false;
    }
  }

  if (jsonContent == null || isString(jsonContent) || jsonContent.content == null) {
    return true;
  }

  const { content } = jsonContent;
  return (
    content.length === 0 ||
    (content.length === 1 && content[0].type === 'paragraph' && !('content' in content[0]))
  );
}
