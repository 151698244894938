import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import LayerName from 'components/LayerName/LayerName';
import { ChartSize } from 'generated/graphql';
import { formatDriverValue } from 'helpers/formatting';
import useAppSelector from 'hooks/useAppSelector';
import useChartContext from 'hooks/useChartContext';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { MonthKey } from 'types/datetime';

interface Props {
  driverId: DriverId;
  layerId: LayerId;
  monthKey: MonthKey;
  hasActiveCursor?: boolean;
}

const ScenarioLegendRow: React.FC<Props> = ({ driverId, layerId, monthKey, hasActiveCursor }) => {
  const { size } = useChartContext();

  const formatConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );

  const driverTimeseries = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: driverId, layerId }),
  );

  let truncatedDecimalPlaces = 0;

  if (formatConfiguration.decimalPlaces != null) {
    // With limited space, only show 2 decimal places max for values
    truncatedDecimalPlaces = Math.min(formatConfiguration.decimalPlaces);
  }

  const value = formatDriverValue(driverTimeseries[monthKey], {
    ...formatConfiguration,
    decimalPlaces: truncatedDecimalPlaces,
  });

  const isMediumChart = size === ChartSize.Medium;

  return (
    <Flex flexDirection="row" key={layerId} width="full" alignItems="center" gap={3} role="group">
      <Flex
        grow={1}
        overflowX="clip"
        overflow="hidden"
        wordBreak="break-word"
        direction="row"
        pl={1}
      >
        <LayerName
          size={isMediumChart ? 'sm' : 'md'}
          layerId={layerId}
          fontWeight="medium"
          fontSize="xxs"
          enableIconBgColor
        />
      </Flex>
      {hasActiveCursor && !isMediumChart && (
        <Flex>
          <Text
            color="gray.500"
            noOfLines={1}
            fontWeight="medium"
            whiteSpace="nowrap"
            fontSize="xxs"
            textAlign="right"
            alignSelf="center"
          >
            {value}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default React.memo(ScenarioLegendRow);
