import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

import {
  DriverFormat,
  ExtObjectCreateInput,
  ExtObjectDeleteInput,
  ExtObjectFieldCreateInput,
  ExtObjectFieldSpecInput,
  ValueType,
} from 'generated/graphql';
import { convertTimeSeries } from 'helpers/gqlDataset';
import { toExtSource } from 'helpers/integrations';
import { isNotNull } from 'helpers/typescript';
import { DatasetSnapshot, ToValueTimeSeries } from 'reduxStore/models/dataset';
import { ExtObjectFieldSpec, ExtObjectSpec } from 'reduxStore/models/extObjectSpecs';
import { ExtObject, ExtObjectField } from 'reduxStore/models/extObjects';
import { DEFAULT_LAYER_ID, DefaultLayer, Layer } from 'reduxStore/models/layers';

export function setExtObjectDataFromDatasetSnapshot(layer: Draft<Layer>, dataset: DatasetSnapshot) {
  if (dataset == null) {
    layer.extObjects = { byKey: {}, allKeys: [] };
    layer.extObjectSpecs = { byKey: {}, allKeys: [] };
    return;
  }

  const extObjectSpecs: ExtObjectSpec[] = dataset.extObjectSpecs.map((spec) => {
    return {
      extKey: spec.extKey,
      source: toExtSource(spec.source),
      model: spec.model,
      startFieldExtKey: spec.startFieldExtKey ?? undefined,
      fields: spec.fields.map((field) => mapExtObjectFieldSpec(field)),
      integrationQueryId: spec.integrationQueryId ?? undefined,
      overrideForecastData: spec.overrideForecastData,
      propagateDataForward: spec.propagateDataForward,
    };
  });

  layer.extObjectSpecs = {
    byKey: keyBy(extObjectSpecs, 'extKey'),
    allKeys: extObjectSpecs.map((e) => e.extKey),
  };

  const extObjects: ExtObject[] = dataset.extObjects
    .map((extObj) => {
      const objSpec = layer.extObjectSpecs.byKey[extObj.extSpecKey];
      if (objSpec == null) {
        return undefined;
      }
      return {
        extKey: extObj.extKey,
        remoteId: extObj.remoteId,
        name: extObj.name,
        extSpecKey: extObj.extSpecKey,
        fields: extObj.fields
          .map((field) => {
            return mapExtObjectFieldFromSnapshot(field, objSpec);
          })
          .filter(isNotNull),
      };
    })
    .filter(isNotNull);

  layer.extObjects = {
    byKey: keyBy(extObjects, 'extKey'),
    allKeys: extObjects.map((e) => e.extKey),
  };
}

export function handleCreateExtObject(
  defaultLayer: Draft<DefaultLayer>,
  newExtObjectInput: ExtObjectCreateInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not create extObject on non-default layer');
  }
  const { extKey, remoteId, name, extSpecKey, fields } = newExtObjectInput;
  const objSpec = defaultLayer.extObjectSpecs.byKey[extSpecKey];
  if (objSpec == null) {
    return;
  }

  const obj: ExtObject = {
    extKey,
    remoteId,
    name,
    extSpecKey,
    fields: fields
      .map((field) => {
        return mapExtObjectField(field, objSpec);
      })
      .filter(isNotNull),
  };

  defaultLayer.extObjects.allKeys.push(obj.extKey);
  defaultLayer.extObjects.byKey[obj.extKey] = obj;
}

export function handleDeleteExtObjects(
  defaultLayer: Draft<DefaultLayer>,
  deleteExtObjectInputs: ExtObjectDeleteInput[],
) {
  if (deleteExtObjectInputs.length === 0) {
    return;
  }

  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not delete extObject on non-default layer');
  }
  const extObjects = defaultLayer.extObjects;
  const extKeysToDelete = new Set(deleteExtObjectInputs.map(({ extKey }) => extKey));
  extKeysToDelete.forEach((extKey) => {
    delete extObjects.byKey[extKey];
  });
  extObjects.allKeys = extObjects.allKeys.filter((key) => !extKeysToDelete.has(key));
}

export function mapExtObjectFieldSpec(fieldInput: ExtObjectFieldSpecInput): ExtObjectFieldSpec {
  const { extKey, name, type, dimensionId, numericFormat } = fieldInput;
  const baseSpec = {
    extKey,
    name,
  };
  switch (type) {
    case ValueType.Attribute:
      if (dimensionId == null) {
        throw Error(`missing dimension for attribute ext field ${extKey}`);
      }
      return {
        ...baseSpec,
        type: ValueType.Attribute,
        dimensionId,
      };
    case ValueType.Number:
      return {
        ...baseSpec,
        type: ValueType.Number,
        numericFormat: numericFormat ?? DriverFormat.Number,
      };
    case ValueType.Boolean:
      throw Error(`found unsupported field type: ${type}`);
    default:
      return { ...baseSpec, type };
  }
}

function mapExtObjectField(
  fieldInput: ExtObjectFieldCreateInput,
  objSpec: ExtObjectSpec,
): ExtObjectField | undefined {
  const fieldSpec = objSpec.fields.find((field) => field.extKey === fieldInput.extFieldSpecKey);
  if (fieldSpec == null) {
    return undefined;
  }
  return {
    extFieldSpecKey: fieldInput.extFieldSpecKey,
    timeSeries: convertTimeSeries(fieldInput.timeSeries, fieldSpec.type),
  };
}

function mapExtObjectFieldFromSnapshot(
  fieldInput: ToValueTimeSeries<ExtObjectField>,
  objSpec: ExtObjectSpec,
): ExtObjectField | undefined {
  const fieldSpec = objSpec.fields.find((field) => field.extKey === fieldInput.extFieldSpecKey);
  if (fieldSpec == null) {
    return undefined;
  }
  return {
    extFieldSpecKey: fieldInput.extFieldSpecKey,
    timeSeries: fieldInput.timeSeries,
  };
}
