import ImpactBadge from 'components/ImpactBadge/ImpactBadge';
import { ImpactType } from 'generated/graphql';
import { getDisplayStringForImpact } from 'helpers/events';
import useAppSelector from 'hooks/useAppSelector';
import { ObjectFieldEvent } from 'reduxStore/models/events';
import { getLastValueInTimeSeries } from 'reduxStore/models/timeSeries';
import { toNullableValue } from 'reduxStore/models/value';
import { businessObjectFieldByIdForLayerSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { attributesByIdSelector } from 'selectors/dimensionsSelector';
import { fieldSpecDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';

interface Props {
  event: ObjectFieldEvent;
}

const ObjectImpactBadge: React.FC<Props> = ({ event }) => {
  const attributesById = useAppSelector(attributesByIdSelector);
  const { customCurvePoints, valueType } = event;
  const latestSetValue =
    getLastValueInTimeSeries(customCurvePoints) ?? toNullableValue(undefined, valueType);
  const businessObjectField = useAppSelector(
    (state) => businessObjectFieldByIdForLayerSelector(state)[event.businessObjectFieldId],
  );
  const displayConfig = useAppSelector((state) =>
    fieldSpecDisplayConfigurationSelector(state, businessObjectField.fieldSpecId),
  );

  const fullDisplayString = getDisplayStringForImpact(
    latestSetValue,
    displayConfig,
    { abbreviate: false },
    attributesById,
    ImpactType.Set,
  );

  const abbreviatedDisplayString = getDisplayStringForImpact(
    latestSetValue,
    displayConfig,
    { abbreviate: true },
    attributesById,
    ImpactType.Set,
  );

  return (
    <ImpactBadge
      fullDisplayString={fullDisplayString}
      abbreviatedString={abbreviatedDisplayString}
    />
  );
};

export default ObjectImpactBadge;
