import React, { useContext, useMemo } from 'react';

import DriverNameTableCell from 'components/DriverNameTableCell/DriverNameTableCell';
import DriverRowContextProvider from 'components/DriverRowContextProvider/DriverRowContextProvider';
import { EmptyDriverTableCell } from 'components/DriverTableRow/EmptyDriverTableCell';
import EmptyTimeSeriesCell from 'components/DriverTimeSeriesCell/EmptyTimeSeriesCell';
import RowContainer from 'components/Table/RowContainer';
import { ListItemContext } from 'components/VirtualizedList/listItemContext';
import { CellType, MonthColumnKey } from 'config/cells';
import { DriverRowContext } from 'config/driverRowContext';
import { getMonthColumnKey, stringifyMonthColumnKey } from 'helpers/cells';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { DriverId } from 'reduxStore/models/drivers';
import { timeSeriesColumnsForBlockSelector } from 'selectors/rollupSelector';
import { driverPropertyColumnsSelector } from 'selectors/visibleColumnTypesSelector';

interface Props {
  driverId: DriverId;
}

const DriverComparisonHeaderRow: React.FC<Props> = ({ driverId }) => {
  const { blockId } = useBlockContext();
  const { index } = useContext(ListItemContext);
  const isFirstRow = index === 0;
  const propertyColumns = useAppSelector((state) => driverPropertyColumnsSelector(state, blockId));
  const columns = useAppSelector((state) => timeSeriesColumnsForBlockSelector(state, blockId));

  return (
    <DriverRowContextProvider driverId={driverId}>
      <RowContainer testId="driver-comparison-header-row" isFirstRow={isFirstRow}>
        {propertyColumns.flatMap((column) => {
          const { type, layerIds } = column;
          return layerIds.map((columnLayerId) => {
            const k = `${type}.${columnLayerId}`;

            switch (type) {
              case 'name': {
                return <DriverNameTableCell key={k} isLast={false} />;
              }
              case 'rollupType':
              case 'usedBy':
              case 'graph':
              case 'formula':
              case 'actualsFormula': {
                return (
                  <EmptyDriverTableCell key={k} columnType={type} columnLayerId={columnLayerId} />
                );
              }
              default: {
                return null;
              }
            }
          });
        })}
        {columns.map(({ mks, subLabel, rollupType, uniqueLabel }) => {
          const columnKey = getMonthColumnKey(mks[0], rollupType, subLabel, uniqueLabel);
          return (
            <EmptyDriverTimeSeriesCell
              key={stringifyMonthColumnKey(columnKey)}
              columnKey={columnKey}
            />
          );
        })}
      </RowContainer>
    </DriverRowContextProvider>
  );
};

const EmptyDriverTimeSeriesCell: React.FC<{
  columnKey: MonthColumnKey;
}> = React.memo(({ columnKey }) => {
  const { driverId } = useContext(DriverRowContext);
  const cellRef = useMemo(() => {
    return {
      type: CellType.Driver as const,
      rowKey: { driverId, layerId: undefined, groupId: undefined },
      columnKey,
    };
  }, [columnKey, driverId]);

  return <EmptyTimeSeriesCell cellRef={cellRef} />;
});

export default React.memo(DriverComparisonHeaderRow);
