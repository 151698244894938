import { createSelector } from 'reselect';

import { popoverStateSelector } from 'selectors/pageSelector';
import { isPlanDetailPaneOpenSelector } from 'selectors/planDetailPaneSelector';

// N.B. this should match the implementation in `globalKeypress.ts`
export const isPopoverOpenSelector = createSelector(
  popoverStateSelector,
  isPlanDetailPaneOpenSelector,
  (popoverState, isPlanDetailPaneOpen) => {
    return popoverState != null && !isPlanDetailPaneOpen;
  },
);

export const popoverTypeSelector = createSelector(popoverStateSelector, (popoverState) => {
  return popoverState != null ? popoverState.type : null;
});
