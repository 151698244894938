import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

import {
  AttributeCreateInput,
  AttributeDeleteInput,
  AttributeRestoreInput,
  AttributeUpdateInput,
  BuiltInAttributeInput,
  BuiltInDimensionType,
  DimensionCreateInput,
  DimensionDeleteInput,
  DimensionRestoreInput,
  DimensionUpdateInput,
  Dimension as GQLDimension,
} from 'generated/graphql';
import { extractMonthKey } from 'helpers/dates';
import { isNotNull } from 'helpers/typescript';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import {
  Attribute,
  AttributeId,
  Dimension,
  DimensionType,
  NormalizedDimension,
  UserAddedAttribute,
  UserAddedDimensionType,
} from 'reduxStore/models/dimensions';
import { DEFAULT_LAYER_ID, DefaultLayer, Layer } from 'reduxStore/models/layers';

export function handleCreateDimension(
  defaultLayer: Draft<DefaultLayer>,
  input: DimensionCreateInput,
  dimType: DimensionType = UserAddedDimensionType,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not create dimensions on non-default layer');
  }
  const { id, name, attributes, color } = input;
  const type = dimType ?? UserAddedDimensionType;
  if (attributes != null) {
    if (dimType === UserAddedDimensionType) {
      addAttributesToLayer(
        defaultLayer,
        attributes.map((a) => attributeCreateInputToAttribute(a)),
      );
    } else {
      addAttributesToLayer(
        defaultLayer,
        attributes.map((a) => builtInAttributeToAttribute({ type: dimType, value: a.value })),
      );
    }
  }
  const dimension: NormalizedDimension = {
    id,
    name,
    type,
    attributeIds: attributes?.map((a) => a.id) ?? [],
    deleted: false,
    color: color ?? undefined,
  };
  defaultLayer.dimensions.byId[dimension.id] = dimension;
  defaultLayer.dimensions.allIds.push(dimension.id);
}

export function handleUpdateDimension(
  defaultLayer: Draft<DefaultLayer>,
  input: DimensionUpdateInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not update dimensions on non-default layer');
  }

  const { id, name, newAttributes, updateAttributes, deleteAttributes, restoreAttributes, color } =
    input;

  const existingDimension = defaultLayer.dimensions.byId[id];
  if (existingDimension == null) {
    return;
  }

  if (name != null) {
    existingDimension.name = name;
  }

  if (color != null) {
    existingDimension.color = color;
  }

  if (newAttributes != null) {
    const addAttributes = newAttributes.map((attr) => attributeCreateInputToAttribute(attr));
    addAttributesToLayer(defaultLayer, addAttributes);
    existingDimension.attributeIds.push(...addAttributes.map((attr) => attr.id));
  }

  if (updateAttributes != null) {
    updateAttributes.map((updateAttr) => handleUpdateAttribute(defaultLayer, updateAttr));
  }

  if (deleteAttributes != null) {
    deleteAttributes.forEach((deleteAttr) => {
      handleDeleteAttribute(defaultLayer, deleteAttr);
    });
  }

  if (restoreAttributes != null) {
    restoreAttributes.forEach((deleteAttr) => {
      handleRestoreAttribute(defaultLayer, deleteAttr);
    });
  }
}

export function handleDeleteDimension(
  defaultLayer: Draft<DefaultLayer>,
  input: DimensionDeleteInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not create dimensions on non-default layer');
  }

  const { id } = input;
  const attrs = defaultLayer.dimensions.byId[input.id]?.attributeIds;

  const dim = defaultLayer.dimensions.byId[id];
  if (dim == null) {
    return;
  }

  dim.deleted = true;
  attrs.forEach((attrId) => handleDeleteAttribute(defaultLayer, { id: attrId }));
}

export function handleRestoreDimension(
  defaultLayer: Draft<DefaultLayer>,
  input: DimensionRestoreInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not restore dimensions on non-default layer');
  }

  const { id } = input;

  const dim = defaultLayer.dimensions.byId[id];
  if (dim == null) {
    return;
  }

  dim.deleted = false;
}

export function addAttributesToLayer(defaultLayer: Draft<DefaultLayer>, attributes: Attribute[]) {
  attributes.forEach((attr) => {
    defaultLayer.attributes.byId[attr.id] = attr;
    defaultLayer.attributes.allIds.push(attr.id);
  });
}

export function builtInAttributeToAttribute(input: BuiltInAttributeInput) {
  const { type, value } = input;
  if (type === BuiltInDimensionType.CalendarTime) {
    return {
      id: value,
      value: extractMonthKey(value),
      type,
      dimensionId: type,
      deleted: false,
    };
  }
  return {
    id: value,
    value: parseInt(value),
    type,
    dimensionId: type,
    deleted: false,
  };
}

export function attributeCreateInputToAttribute(input: AttributeCreateInput): UserAddedAttribute {
  const { id, value, dimensionId } = input;

  return {
    id,
    value,
    type: UserAddedDimensionType,
    dimensionId,
    deleted: false,
  };
}

function handleUpdateAttribute(defaultLayer: Draft<DefaultLayer>, input: AttributeUpdateInput) {
  const { id, value } = input;
  const existingAttribute = defaultLayer.attributes.byId[id];
  if (existingAttribute == null) {
    return;
  }
  if (value != null) {
    existingAttribute.value = value;
  }
}

function handleDeleteAttribute(defaultLayer: Draft<DefaultLayer>, input: AttributeDeleteInput) {
  const { id } = input;
  const attribute = defaultLayer.attributes.byId[id];
  if (attribute == null) {
    return;
  }
  attribute.deleted = true;
}

function handleRestoreAttribute(defaultLayer: Draft<DefaultLayer>, input: AttributeRestoreInput) {
  const { id } = input;
  const attribute = defaultLayer.attributes.byId[id];
  if (attribute == null) {
    return;
  }
  attribute.deleted = false;
}

export function setDimensionsAndAttributesFromDatasetSnapshot(
  layer: Draft<Layer>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null || dataset.dimensions == null) {
    layer.dimensions = { byId: {}, allIds: [] };
    return;
  }
  const normalizedDimensions = dataset.dimensions.map((dim) => normalizeDimension(dim));
  layer.dimensions = {
    byId: keyBy(normalizedDimensions, 'id'),
    allIds: normalizedDimensions.map((d) => d.id),
  };

  // Don't just copy all of the attributes by reference as this could result in
  // modifications to the attributes being reflected in the snapshot which
  // would break undo/redo.
  const allAttributes: Attribute[] = dataset.dimensions
    .flatMap((d) =>
      d.attributes?.map((a) => ({
        ...a,
        deleted: a.deleted ?? false,
        type: 'UserAdded' as const,
      })),
    )
    .flat()
    .filter(isNotNull);
  layer.attributes = {
    byId: keyBy(allAttributes, 'id'),
    allIds: allAttributes.map((a) => a.id),
  };
}

function normalizeDimension(dimension: GQLDimension): NormalizedDimension {
  return {
    id: dimension.id,
    name: dimension.name,
    color: dimension.color ?? undefined,
    type: UserAddedDimensionType,
    // TODO: Debug why we need to add these null checks
    attributeIds: dimension.attributes != null ? dimension.attributes.map((a) => a.id) : [],
    deleted: dimension.deleted ?? false,
  };
}

export function denormalizeDimension(
  dimension: NormalizedDimension,
  attributesById: Record<AttributeId, Attribute>,
): Dimension {
  return {
    id: dimension.id,
    name: dimension.name,
    color: dimension.color,
    type: dimension.type,
    attributes: dimension.attributeIds.map((attrId) => attributesById[attrId]).filter(isNotNull),
    deleted: dimension.deleted,
  };
}
