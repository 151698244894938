import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'AreaChart',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    boxSize: '14px',
  },
  d: 'M7.88331 2.105C7.76665 2.10666 7.65498 2.14833 7.56498 2.22L5.17331 4.13333L2.82665 3.195C2.66498 3.13 2.47998 3.15 2.33665 3.24833C2.19165 3.34666 2.10498 3.50833 2.10498 3.68333V12.63C2.10498 13.205 2.58165 13.6817 3.15665 13.6817H12.63C13.205 13.6817 13.6816 13.205 13.6816 12.63V2.63166C13.6816 2.45666 13.595 2.29333 13.45 2.195C13.3066 2.09833 13.1216 2.07833 12.96 2.14333L10.525 3.11666L8.08831 2.14333C8.02331 2.11666 7.95331 2.10333 7.88331 2.105ZM12.63 6.73666V12.63H3.15665V8.945L5.26165 11.05L7.89331 7.89333L10.525 8.42L12.63 6.73666Z',
});
