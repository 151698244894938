import { Flex, Text } from '@chakra-ui/react';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import { useCallback, useMemo, useRef } from 'react';

import AttributeEditMenu from 'components/AttributeEditMenu/AttributeEditMenu';
import ObjectAttributeBadge from 'components/ObjectAttributeBadge/ObjectAttributeBadge';
import MultiSelectResultsList from 'components/SearchableMultiSelectMenu/MultiSelectResultsList';
import MultiSelectSearchBar from 'components/SearchableMultiSelectMenu/MultiSelectSearchBar';
import SearchableMultiSelectMenu, {
  CustomMultiSelectOption,
} from 'components/SearchableMultiSelectMenu/SearchableMultiSelectMenu';
import SelectedItemsDisplay from 'components/SearchableMultiSelectMenu/SelectedItemsDisplay';
import SelectItemPopover from 'components/SelectItemPopover/SelectItemPopover';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import { ValueType } from 'generated/graphql';
import { getAttributeValueString, isUserAddedAttribute } from 'helpers/dimensionalDrivers';
import useAppSelector from 'hooks/useAppSelector';
import { DimensionId } from 'reduxStore/models/dimensions';
import { AttributeValue, NullableValue } from 'reduxStore/models/value';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import Plus from 'vectors/Plus';

interface Props {
  value: NullableValue<AttributeValue>['value'];
  dimensionId: DimensionId | null;
  onUpdateValue: (newValue: NullableValue<AttributeValue>) => void;
  onClearValue: () => void;
  onCreateAttribute: (params: { dimensionId: DimensionId; newValue: string }) => void;
  onClose: () => void;
  initialQuery?: string;
}

export const EditableAttributeValue: React.FC<Props> = ({
  value,
  dimensionId,
  onUpdateValue,
  onClearValue,
  onCreateAttribute,
  onClose,
  initialQuery,
}) => {
  const dimensionsById = useAppSelector(dimensionsByIdSelector);
  const dimension = dimensionId != null ? dimensionsById[dimensionId] : null;
  const attributes = dimension != null ? sortBy(dimension.attributes, 'value') : [];
  const attrById = keyBy(attributes, 'id');
  const allItems = attributes
    .filter((a) => !a.deleted)
    .map((attr) => ({ id: attr.id, name: getAttributeValueString(attr) }));
  const selectedItemId = value;
  const customOption: CustomMultiSelectOption = useMemo(() => {
    return {
      position: 'last',
      onSelect: (query: string) => {
        if (dimension != null) {
          onCreateAttribute({ dimensionId: dimension.id, newValue: query });
        }
      },
      render: ({ query, isFocused, idx }) => (
        <SelectMenuItem
          key={`custom-option-${idx}`}
          icon={<Plus />}
          name={
            <Flex columnGap={1}>
              <Text>Create</Text>
              <ObjectAttributeBadge label={query} />
            </Flex>
          }
          isFocused={isFocused}
          idx={idx}
        />
      ),
    };
  }, [dimension, onCreateAttribute]);

  const selectedItems = useMemo(() => {
    const selectedItem = allItems.find((item) => item.id === selectedItemId);
    return selectedItem != null ? [selectedItem] : [];
  }, [allItems, selectedItemId]);

  const selectedItemIds = selectedItemId != null ? [selectedItemId] : [];
  const setCurrentlySelectedItems = useCallback(
    (newSelectedItemIds: string[]) => {
      if (newSelectedItemIds.length === 0) {
        onClearValue();
        return;
      }
      const newAttributeId = newSelectedItemIds[0];
      onUpdateValue({ type: ValueType.Attribute, value: newAttributeId });
      onClose();
    },
    [onUpdateValue, onClose, onClearValue],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const onClosePopover = useCallback(
    () => window.requestAnimationFrame(() => inputRef.current?.focus()),
    [],
  );

  return (
    <SearchableMultiSelectMenu
      items={allItems}
      selectedItemIds={selectedItemIds}
      onUpdateSelectedItemIds={setCurrentlySelectedItems}
      onClose={onClose}
      maxSelectedItems={1}
      customOption={customOption}
      includeSelectedItemsInResults
      initialQuery={initialQuery}
    >
      <MultiSelectSearchBar inputRef={inputRef}>
        <SelectedItemsDisplay items={selectedItems} onClearItem={onClearValue} />
      </MultiSelectSearchBar>
      <MultiSelectResultsList>
        {({ item, idx, isFocused }) => (
          <SelectMenuItem
            key={item.id}
            name={
              <>
                <Text noOfLines={1}>{item.name}</Text>
                {isUserAddedAttribute(attrById[item.id]) && (
                  <SelectItemPopover onClose={onClosePopover}>
                    <AttributeEditMenu id={item.id} />
                  </SelectItemPopover>
                )}
              </>
            }
            idx={idx}
            shortcutHint={null}
            isFocused={isFocused}
          />
        )}
      </MultiSelectResultsList>
    </SearchableMultiSelectMenu>
  );
};
