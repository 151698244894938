import { Box, Center, Flex, Text } from '@chakra-ui/react';

import DriverAttributeBadges from 'components/DriverAttributeBadges/DriverAttributeBadges';
import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import DimensionBadge from 'components/Formula/DimensionBadge';
import { DEFAULT_DRIVER_GROUP_NAME } from 'config/driverGroups';
import { extractEmoji } from 'helpers/emoji';
import useAppSelector from 'hooks/useAppSelector';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import { mustDriverSelector } from 'selectors/driversSelector';
import {
  pageNameFromSubmodelIdSelector,
  submodelIdByBlockIdSelector,
} from 'selectors/submodelPageSelector';

interface Props {
  driverId?: DriverId;
  groupId?: DriverGroupId;
  submodelId?: SubmodelId;
}

const DriverSelectMenuFooter: React.FC<Props> = (props) => {
  const { driverId } = props;
  const driver = useAppSelector((state) =>
    driverId == null ? null : mustDriverSelector(state, { id: driverId }),
  );
  const submodelIdByBlockId = useAppSelector(submodelIdByBlockIdSelector);
  const driverSubmodelRef = driver?.driverReferences?.find(
    (ref) => submodelIdByBlockId[ref.blockId] != null,
  );
  const driverSubmodelId =
    driverSubmodelRef?.blockId != null ? submodelIdByBlockId[driverSubmodelRef.blockId] : null;
  const driverSubmodelGroupId = driverSubmodelRef?.groupId;
  const submodelId = props.submodelId ?? driverSubmodelId;
  const groupId = props.groupId ?? driverSubmodelGroupId;
  const driverName = driver?.name;
  const submodelFullName = useAppSelector((state) =>
    submodelId != null ? pageNameFromSubmodelIdSelector(state, submodelId) : undefined,
  );
  const groupName = useAppSelector((state) =>
    groupId === undefined
      ? undefined
      : groupId === null
        ? DEFAULT_DRIVER_GROUP_NAME
        : driverGroupsByIdSelector(state)[groupId]?.name,
  );

  if (driverId == null && groupId == null && submodelId == null) {
    return null;
  }

  const [emoji, submodelName] = extractEmoji(submodelFullName ?? '');
  const path = groupName != null ? `${submodelName} / ${groupName}` : submodelName;

  return (
    <Flex flexDirection="column">
      <Flex columnGap={2} py="0.125rem">
        <Box flexShrink={0} width={4}>
          <Center height="full" color="gray.500">
            <EmojiIcon size="sm" emoji={emoji} />
          </Center>
        </Box>
        <Flex flexDirection="column" flexGrow={1} rowGap="0.25rem">
          {driverName != null ? (
            <>
              <Text color="gray.500" fontWeight="medium" noOfLines={1}>
                {path}
              </Text>
              <Text noOfLines={1} fontWeight="bold">
                {driverName}
              </Text>
            </>
          ) : (
            <Center height="full">
              <Text fontWeight="bold" noOfLines={1}>
                {path}
              </Text>
            </Center>
          )}
        </Flex>
      </Flex>
      {driverId != null && driver?.type === DriverType.Dimensional ? (
        <Flex>
          {driver.dimensions.map((dim) => (
            <DimensionBadge key={dim.id} dimension={dim} />
          ))}
        </Flex>
      ) : (
        <Flex rowGap="0.25rem" pl={5} pt={0.5} flexWrap="wrap">
          <DriverAttributeBadges
            flexWrap="wrap"
            rowGap="0.25rem"
            size="xs"
            overflow="visible"
            driverId={driverId}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default DriverSelectMenuFooter;
