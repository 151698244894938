import { Flex, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

import {
  ComparisonColumnInfo,
  getColumnDiffTypeText,
  getDisplayableDiffTypes,
} from 'components/CompareScenariosModalContent/diffTypes';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { objectSpecColumnEntitySelector } from 'selectors/collectionSelector';
import {
  ColumnDiffType,
  comparisonLayerIdsForBlockSelector,
} from 'selectors/scenarioComparisonSelector';

interface Props {
  objectSpecId: string;
  columns: ComparisonColumnInfo[];
}

const DatabaseColumnComparisonTableHeader: React.FC<Props> = ({ columns }) => {
  return (
    <Thead>
      <Tr>
        <Th border="1px solid" borderColor="gray.400" rowSpan={2} />
        {columns.map((column) => (
          <Header key={column.id} column={column} />
        ))}
      </Tr>
      <Tr>
        {columns.map((column) => {
          const diffTypes = getDisplayableDiffTypes(column);
          return diffTypes.map((diffType, index) => {
            const key = `${column.id}-${diffType}`;
            return (
              <SubHeader key={key} diffType={diffType} isLast={index === diffTypes.length - 1} />
            );
          });
        })}
      </Tr>
    </Thead>
  );
};

const SubHeader: React.FC<{ isLast?: boolean; diffType: ColumnDiffType }> = ({
  isLast,
  diffType,
}) => {
  return (
    <Th
      borderRightWidth="1px"
      borderRightColor={isLast ? 'gray.400' : 'gray.300'}
      borderRightStyle={isLast ? 'solid' : 'dashed'}
      borderBottomWidth="1px"
      borderBottomColor="gray.400"
      borderBottomStyle="solid"
      p={2}
    >
      <Flex alignItems="center">
        <Text
          mr="1"
          noOfLines={1}
          whiteSpace="nowrap"
          fontWeight="medium"
          fontSize="xxs"
          casing="initial"
          color="gray.500"
        >
          {getColumnDiffTypeText(diffType)}
        </Text>
      </Flex>
    </Th>
  );
};

const Header: React.FC<{ column: ComparisonColumnInfo }> = ({ column }) => {
  const { blockId } = useBlockContext();
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  const columnName = useAppSelector((state: RootState) => {
    for (const layerId of comparisonLayerIds) {
      const columnEntity = objectSpecColumnEntitySelector(state, {
        columnId: column.id,
        layerId,
        columnType: column.type,
      });
      if (columnEntity != null) {
        return columnEntity.name;
      }
    }
    return null;
  });

  const diffTypes = getDisplayableDiffTypes(column);
  const colSpan = diffTypes.length;

  return (
    <Th border="1px solid" borderColor="gray.400" p={2} colSpan={colSpan}>
      <Flex alignItems="center">
        <Text
          mr="1"
          noOfLines={1}
          whiteSpace="nowrap"
          fontWeight="bold"
          fontSize="xxs"
          casing="initial"
        >
          {columnName}
        </Text>
      </Flex>
    </Th>
  );
};

export default DatabaseColumnComparisonTableHeader;
