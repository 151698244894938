import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'DuplicateIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
    fill: 'none',
  },
  path: (
    <>
      <rect
        x="2.5"
        y="6.5"
        width="4.5"
        height="7"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <rect
        x="9"
        y="6.5"
        width="4.5"
        height="7"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
        strokeDasharray="1 1"
      />
      <path
        d="M5 4.50001C7 1.5 9 1.49991 11 4.50001"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 2.5V4.5H9"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
