import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'DatabaseFilledIcon',
  viewBox: '0 0 12 12',
  defaultProps: {
    boxSize: 3,
  },
  path: (
    <>
      <rect
        x="6.94"
        y="6.94"
        width="4.55"
        height="4.55"
        rx="1.05"
        ry="1.05"
        transform="translate(9.21 -3.82) rotate(45)"
        fill="currentColor"
      />
      <path
        d="M9.97.42l1.91,3.31c.32.56-.08,1.25-.72,1.25h-3.83c-.64,0-1.04-.7-.72-1.25l1.91-3.31c.32-.56,1.12-.56,1.45,0Z"
        fill="currentColor"
      />
      <circle fill="currentColor" cx="2.56" cy="9.36" r="2.6" />
      <rect fill="none" y="1.35" width="12" height="12" />
      <rect fill="currentColor" x="0" y="0" width="5" height="5" rx="1.15" ry="1.15" />
    </>
  ),
});
