import { useCallback } from 'react';

import ObjectGroupHeadingRow from 'components/BusinessObjectTable/ObjectGroupHeadingRow';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { toggleAttributeCollapsed } from 'reduxStore/actions/blockMutations';
import { AttributeId } from 'reduxStore/models/dimensions';
import { attributeNamesByIdSelector } from 'selectors/driversSelector';

interface Props {
  attributeId: AttributeId | undefined;
  isExpanded: boolean;
}

const AttributeFieldGroupHeadingRow: React.FC<Props> = ({ attributeId, isExpanded }) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const attributeNamesById = useAppSelector(attributeNamesByIdSelector);
  const onToggleExpansion = useCallback(() => {
    dispatch(toggleAttributeCollapsed({ blockId, attributeId }));
  }, [blockId, dispatch, attributeId]);

  return (
    <ObjectGroupHeadingRow
      name={attributeId == null ? 'No value set' : attributeNamesById[attributeId]}
      isExpanded={isExpanded}
      onToggleExpansion={onToggleExpansion}
    />
  );
};

export default AttributeFieldGroupHeadingRow;
