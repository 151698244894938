import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

import { Tooltip, TooltipProps } from 'chakra/tooltip';
import useIsTruncated from 'hooks/useIsTruncated';

interface Props extends TextProps {
  children: React.ReactNode;
  label?: string;
  shouldWrapChildren?: TooltipProps['shouldWrapChildren'];
}

const TextWithTruncation: React.FC<Props> = ({
  children,
  label,
  shouldWrapChildren,
  ...textProps
}) => {
  const { ref: textRef, isTruncated } = useIsTruncated();
  const text = (
    <Text ref={textRef} {...textProps}>
      {children}
    </Text>
  );

  if (isTruncated) {
    return (
      <Tooltip label={label ?? children} shouldWrapChildren={shouldWrapChildren} placement="top">
        {text}
      </Tooltip>
    );
  }

  return text;
};

export default React.memo(TextWithTruncation);
