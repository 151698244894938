import { CellType } from 'config/cells';
import { deleteEventIds } from 'reduxStore/actions/eventMutations';
import { selectSingleCell } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import { sortedInspectorRowEventGroupIdsSelector } from 'selectors/inspectorSelector';
import { selectedInitiativeSelector } from 'selectors/planTimelineSelector';
import {
  getSelectedTimelineCellEventsByMonthKey,
  selectedTimelineCellSelector,
} from 'selectors/selectedTimelineCellSelector';
import { pageSelectionSelector as prevailingSelectionSelector } from 'selectors/selectionSelector';

export const timelineCellNavigate =
  (direction: 'up' | 'down'): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const timelineCell = selectedTimelineCellSelector(state);
    const selection = prevailingSelectionSelector(state);
    const selectedInitiative =
      timelineCell?.blockId != null
        ? selectedInitiativeSelector(state, timelineCell.blockId)
        : null;
    const blockId = selection != null && 'blockId' in selection ? selection.blockId : undefined;
    if (selection?.type !== 'eventsAndGroups' || timelineCell == null || blockId == null) {
      return;
    }

    const { rowKey, columnKey, type } = timelineCell.activeCell;

    // If someone is navigating up from an impact driver, we want to navigate to the last impact row
    if (type === CellType.ImpactDriver) {
      if (direction === 'up') {
        const driverId = rowKey.driverId;
        const sortedEventGroupIds = sortedInspectorRowEventGroupIdsSelector(state, {
          driverId,
          selectedEventGroupId: selectedInitiative?.parentId,
          blockId,
        });

        const lastEventGroupId = sortedEventGroupIds[sortedEventGroupIds.length - 1];

        dispatch(
          selectSingleCell({
            blockId: selection.blockId,
            cellRef: {
              columnKey,
              type: CellType.Impact,
              rowKey: {
                type: 'driver',
                driverId: rowKey.driverId,
                eventGroupId: lastEventGroupId,
              },
            },
          }),
        );
      }
    }

    if (type === CellType.Impact) {
      if (rowKey.type === 'objectField') {
        // unsupported
        return;
      }

      const driverId = rowKey.driverId;
      const sortedEventGroupIds = sortedInspectorRowEventGroupIdsSelector(state, {
        driverId,
        selectedEventGroupId: selectedInitiative?.parentId,
        blockId,
      });

      let nextEventGroupIndex: number | null = null;
      const currentEventGroupId = rowKey.eventGroupId;
      if (currentEventGroupId != null) {
        const currentEventGroupIndex = sortedEventGroupIds.indexOf(currentEventGroupId);

        if (direction === 'up') {
          nextEventGroupIndex = Math.max(0, currentEventGroupIndex - 1);
        } else {
          nextEventGroupIndex = currentEventGroupIndex + 1;
        }
      }

      if (nextEventGroupIndex == null) {
        return;
      }

      // Navigating down from the last impact row goes to the impact driver row
      if (nextEventGroupIndex >= sortedEventGroupIds.length) {
        dispatch(
          selectSingleCell({
            blockId: selection.blockId,
            cellRef: {
              columnKey,
              type: CellType.ImpactDriver,
              rowKey: { driverId: rowKey.driverId },
            },
          }),
        );
        return;
      }

      // Otherwise, navigate to the next/previous impact row
      const nextEventGroupId = sortedEventGroupIds[nextEventGroupIndex];

      dispatch(
        selectSingleCell({
          blockId: selection.blockId,
          cellRef: {
            columnKey,
            type: CellType.Impact,
            rowKey: {
              type: 'driver',
              driverId: rowKey.driverId,
              eventGroupId: nextEventGroupId,
            },
          },
        }),
      );
    }
  };

export const roadmapCellDeleteSelected = (): AppThunk => (dispatch, getState) => {
  const state = getState();

  const selectedEventsByMonthKey = getSelectedTimelineCellEventsByMonthKey(state);

  const deleteInputs = Object.values(selectedEventsByMonthKey).map((e) => e.id);

  dispatch(deleteEventIds(deleteInputs));
};
