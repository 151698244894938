import { hasDupes } from 'helpers/array';

export interface RowWithSortIndex {
  id: string;
  sortIndex?: number | null;
}

export const SortIndexNums = {
  minSafe: 0,
  maxSafe: Number.MAX_SAFE_INTEGER,
  interval: 10000,
  initial: Math.floor(Number.MAX_SAFE_INTEGER / 4),
};

export const hasNoNullSortIndex = (
  list: RowWithSortIndex[],
): list is Array<{ id: string; sortIndex: number }> => list.every((row) => row.sortIndex != null);

export function computeFreshSortIndexUpdates(order: RowWithSortIndex[]) {
  const updates: NullableRecord<string, number> = {};
  order.forEach((row, i) => {
    updates[row.id] = SortIndexNums.initial + i * SortIndexNums.interval;
  });
  return updates;
}

export function bulkInsertSortIndexUpdates(
  currOrder: RowWithSortIndex[],
  newIds: Array<{ id: string }>,
  position: 'before' | 'after',
  relativeToId?: string,
) {
  if (newIds.length === 1) {
    return computeSortIndexUpdates(currOrder, {
      toInsertId: newIds[0].id,
      beforeId: position === 'before' ? relativeToId : undefined,
      afterId: position === 'after' ? relativeToId : undefined,
      defaultPosition: position === 'after' ? 'end' : 'start',
    });
  }

  const newOrder = currOrder.flatMap((row) => {
    if (newIds.some(({ id }) => id === row.id)) {
      return [];
    }
    if (row.id === relativeToId) {
      if (position === 'after') {
        return [row, ...newIds];
      } else {
        return [...newIds, row];
      }
    }
    return [row];
  });

  if (relativeToId == null) {
    if (position === 'after') {
      newOrder.push(...newIds);
    } else {
      newOrder.unshift(...newIds);
    }
  }

  return computeFreshSortIndexUpdates(newOrder);
}

export function computeSortIndexUpdates(
  currOrder: RowWithSortIndex[],
  {
    toInsertId,
    beforeId,
    afterId,
    defaultPosition = 'end',
  }: {
    toInsertId: string;
    beforeId?: string;
    afterId?: string;
    defaultPosition?: 'start' | 'end';
  },
) {
  if (afterId != null && beforeId != null) {
    throw new Error(
      'invalid use of computeSortIndexUpdates, specify only one of beforeId or afterId',
    );
  }

  if (toInsertId === beforeId || toInsertId === afterId) {
    return {};
  }

  const exclInsert = currOrder.filter((row) => row.id !== toInsertId);

  if (exclInsert.length === 0) {
    return {};
  }

  const newOrder = currOrder.flatMap((row) => {
    if (row.id === toInsertId) {
      return [];
    }

    if (row.id === beforeId) {
      return [{ id: toInsertId }, row];
    }

    if (row.id === afterId) {
      return [row, { id: toInsertId }];
    }

    return [row];
  });

  // In the case where beforeId is not set, newOrder will not contain
  // toInsertId. The default behavior is to have it inserted at the end of
  // the sort order.
  if (beforeId == null && afterId == null) {
    if (defaultPosition === 'start') {
      newOrder.unshift({ id: toInsertId });
    } else {
      newOrder.push({ id: toInsertId });
    }
  }

  // In the case where a sortIndex is missing, we recompute all of them based
  // on the provided order. Don't recompute everything if only the toInsertId
  // has a missing sortIndex as this can happen if the related item is being
  // inserted for the first time. We just need the other items to have a
  // sortIndex so that we can compute the new one.
  if (!hasNoNullSortIndex(exclInsert)) {
    return computeFreshSortIndexUpdates(newOrder);
  }

  // Handle the case where everything has a sortIndex already
  const newIndex =
    exclInsert.findIndex((row) => row.id === beforeId || row.id === afterId) +
    (afterId != null ? 1 : 0);

  let newSortIndex: number;
  switch (newIndex) {
    case -1:
      newSortIndex =
        defaultPosition === 'start'
          ? exclInsert[0].sortIndex - SortIndexNums.interval
          : exclInsert[exclInsert.length - 1].sortIndex + SortIndexNums.interval;
      break;

    case 0:
      newSortIndex = exclInsert[0].sortIndex - SortIndexNums.interval;
      break;

    default: {
      const before = exclInsert[newIndex - 1];
      if (exclInsert.length === newIndex) {
        newSortIndex = before.sortIndex + SortIndexNums.interval;
      } else {
        const after = exclInsert[newIndex];
        newSortIndex = Math.floor((before.sortIndex + after.sortIndex) / 2);
      }
    }
  }

  const newSortIndexes = [...exclInsert.map((el) => el.sortIndex), newSortIndex];
  if (hasDupes(newSortIndexes)) {
    return computeFreshSortIndexUpdates(newOrder);
  }

  return {
    [toInsertId]: newSortIndex,
  };
}
