import { BlockFilterOperator, ValueType } from 'generated/graphql';
import { ObjectFilterDisplayChunk } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import {
  DatabaseFormulaProperty,
  DatabaseFormulaPropertyId,
} from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { getNumber } from 'helpers/number';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { FilterItem, FilterValueTypes } from 'types/filtering';
import { FormulaBooleanOperator } from 'types/formula';

const FORMULA_BOOLEAN_OP_TO_FILTER_OP: Record<FormulaBooleanOperator, BlockFilterOperator> = {
  [FormulaBooleanOperator.Equals]: BlockFilterOperator.Equals,
  [FormulaBooleanOperator.NotEquals]: BlockFilterOperator.NotEquals,
  [FormulaBooleanOperator.LessThan]: BlockFilterOperator.LessThan,
  [FormulaBooleanOperator.LessThanOrEqualTo]: BlockFilterOperator.LessThanOrEqualTo,
  [FormulaBooleanOperator.GreaterThan]: BlockFilterOperator.GreaterThan,
  [FormulaBooleanOperator.GreaterThanOrEqualTo]: BlockFilterOperator.GreaterThanOrEqualTo,
};

export const filterDisplayToItem = (
  databaseFormulaPropertiesById: Record<
    DatabaseFormulaPropertyId,
    DatabaseFormulaProperty | undefined
  >,
  specId: BusinessObjectSpecId,
  filter: ObjectFilterDisplayChunk,
): FilterItem | null => {
  const { field, valueType, operator, value, dateRangeValue, attributeIds, ids, isNull } = filter;
  const databaseFormulaProperty = databaseFormulaPropertiesById[field.fieldId];

  // Map from the formula syntax to our internal representation of filter operators
  const blockFilterOperator =
    isNull && operator === FormulaBooleanOperator.Equals
      ? BlockFilterOperator.IsNull
      : isNull && operator === FormulaBooleanOperator.NotEquals
        ? BlockFilterOperator.IsNotNull
        : FORMULA_BOOLEAN_OP_TO_FILTER_OP[operator];

  const filterCommon = {
    filterKey: field.fieldId,
    label: field.name,
    operator: blockFilterOperator,
    error: field.error,
  };

  if (databaseFormulaProperty && databaseFormulaProperty.type === 'dimensionalProperty') {
    return {
      ...filterCommon,
      valueType: ValueType.Attribute,
      expected: attributeIds,
      dimensionId: databaseFormulaProperty.dimensionalProperty.dimension.id,
    };
  }

  switch (valueType) {
    case ValueType.Attribute: {
      if (databaseFormulaProperty?.type !== 'fieldSpec') {
        return null;
      }
      const objFieldSpec = databaseFormulaProperty?.fieldSpec;

      if (objFieldSpec == null || objFieldSpec.type !== ValueType.Attribute) {
        return null;
      }

      return {
        ...filterCommon,
        valueType,
        expected: attributeIds,
        dimensionId: objFieldSpec.dimensionId,
      };
    }
    case ValueType.Timestamp: {
      return { ...filterCommon, valueType, expected: dateRangeValue };
    }
    case ValueType.Number: {
      const num = !isNull ? getNumber(value) : undefined;
      return {
        ...filterCommon,
        valueType,
        expected: num,
      };
    }
    case FilterValueTypes.ENTITY: {
      return {
        ...filterCommon,
        valueType,
        expected: ids,
        entityType: 'object',
        specId,
      };
    }
    default: {
      return null;
    }
  }
};
