import { IRowNode } from 'ag-grid-community';

import {
  AgGridDatabaseContext,
  ColumnDef,
} from 'components/AgGridComponents/types/DatabaseColumnDef';
import {
  AgGridTimeseriesContext,
  TimeseriesColumnDef,
} from 'components/AgGridComponents/types/TimeseriesColumnDef';

export function isShowingCellPaletteOnCurrentCell(
  currentCell: { rowNode: IRowNode; colDef: ColumnDef | TimeseriesColumnDef },
  context: AgGridDatabaseContext | AgGridTimeseriesContext,
) {
  const { cellPaletteAnchorCell, isShowingCellPalette } = context;
  if (cellPaletteAnchorCell == null || !isShowingCellPalette) {
    return false;
  }

  const { rowNode, colDef } = currentCell;

  return (
    rowNode.id === cellPaletteAnchorCell.rowNode.id && colDef.colId === cellPaletteAnchorCell.colId
  );
}
