import { GridApi } from 'ag-grid-community';

import { writeToClipboard } from 'helpers/clipboard';
import { toggleFeatureFlag } from 'reduxStore/actions/featureFlags';
import { syncFlags } from 'reduxStore/reducers/datasetSlice';
import { FeatureFlagsRecord } from 'reduxStore/reducers/featureFlagsSlice';
import { LaunchDarklyFlags } from 'reduxStore/reducers/launchDarklySlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppDispatch } from 'reduxStore/store';
import { lastMutationIdSelector } from 'selectors/datasetSelector';
import { featureFlagsSelector } from 'selectors/featureFlagsSelector';
import { prevailingCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';
// eslint-disable-next-line no-restricted-imports
import { dispatchCopy, dispatchPaste } from 'testHelpers/copyPasteHelpers';

/**
 * Intializes helpers useful for both Cypress and React Testing Library.
 */
export const initTestHelpers = ({
  dispatch,
  getState,
}: {
  dispatch: AppDispatch;
  getState: () => RootState;
}) => {
  let gridApi: GridApi | undefined;
  let clipboardData: string | null = null;

  const helpers = {
    set agGridApi(api: GridApi) {
      gridApi = api;
    },
    get agGridApi(): GridApi | undefined {
      return gridApi;
    },
    set agGridClipboardData(data: string) {
      clipboardData = data;
    },
    get agGridClipboardData(): string | null {
      return clipboardData;
    },

    // Expose this globally because paste events are not supported natively in testing environments.
    paste(text: string) {
      dispatchPaste(dispatch, text);
    },
    copy() {
      return dispatchCopy(dispatch);
    },
    async agGridPaste(text: string): Promise<void> {
      if (gridApi != null) {
        await writeToClipboard(text);
        gridApi.pasteFromClipboard();
      }
    },
    agGridCopy() {
      if (gridApi != null) {
        gridApi.copyToClipboard();
        return clipboardData;
      }
      return '';
    },
    lastMutationId() {
      return lastMutationIdSelector(getState());
    },
    numSelectedCells() {
      return prevailingCellSelectionSelector(getState())?.selectedCells.length ?? 0;
    },
    setLDFlags(flags: LaunchDarklyFlags) {
      dispatch(syncFlags(flags));
    },
    ensureFeatureFlagsOn(flagsToTurnOn: Array<keyof FeatureFlagsRecord>) {
      flagsToTurnOn.forEach((flagName) => {
        const turnedOn = featureFlagsSelector(getState())[flagName];
        if (!turnedOn) {
          dispatch(toggleFeatureFlag(flagName));
        }
      });
    },
    ensureAllFeatureFlagsOff() {
      const featureFlags = featureFlagsSelector(getState());
      (Object.keys(featureFlags) as Array<keyof FeatureFlagsRecord>).forEach((flagName) => {
        const turnedOn = featureFlags[flagName];
        if (turnedOn) {
          dispatch(toggleFeatureFlag(flagName));
        }
      });
    },
  };

  window.testHelpers = helpers;

  return helpers;
};

// ts-prune does not realize that this is used in a .d.ts file
// ts-prune-ignore-next
export type TestHelpers = NonNullable<ReturnType<typeof initTestHelpers>> & {
  get agGridApi(): GridApi | undefined;
  set agGridApi(api: GridApi);
  get agGridClipboardData(): string | null;
  set agGridClipboardData(data: string);
};
