import * as Sentry from '@sentry/nextjs';

import { Dataset } from 'generated/graphql';
import { DatasetResult, getOrFetchNamedVersion } from 'helpers/fetchNamedVersion';
import { logNamedVersionsFetchedElapsedTimeMs } from 'helpers/logging';

export const downloadNamedVersions = async (
  mutationIds: string[],
  orgId: string,
  callback?: (result: DatasetResult) => void,
): Promise<Array<Dataset | Error>> => {
  performance.mark('fetchNamedVersionsStart');

  // Allow all results to be downloaded simultaneously and handled asynchronously.
  const results = await Promise.allSettled(
    mutationIds.map(async (mutationId) => {
      const result = await getOrFetchNamedVersion(mutationId, orgId);
      if (callback) {
        callback(result);
      }
      return result;
    }),
  );

  const measure = performance.measure('fetchNamedVersions', 'fetchNamedVersionsStart');
  logNamedVersionsFetchedElapsedTimeMs(measure.duration, mutationIds.length);

  return results.map((result) => {
    if (result.status === 'fulfilled') {
      return result.value.dataset;
    }

    console.error(result.reason);
    const err = new Error('Failed to download named version', { cause: result.reason });
    Sentry.captureException(err);

    return err;
  });
};
