import noop from 'lodash/noop';
import stubFalse from 'lodash/stubFalse';
import { createContext } from 'react';

export type SelectMenuContextState = {
  setFocusIdx: (value: number) => void;
  setShowSubmenu: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectIdx: (idx: number, ev: React.MouseEvent | KeyboardEvent) => void;
  closeParentSelectMenu?: () => void;
  setIsChildShowingSubmenu: (show: boolean) => void;
  showSubmenu: boolean;
  getIsScrolling: () => boolean;
  setIsKeyboardEventsBlocked: (isBlocked: boolean) => void;
};

export const SelectMenuContext = createContext<SelectMenuContextState>({
  setFocusIdx: noop,
  setShowSubmenu: noop,
  onSelectIdx: noop,
  closeParentSelectMenu: noop,
  setIsChildShowingSubmenu: noop,
  showSubmenu: false,
  getIsScrolling: stubFalse,
  setIsKeyboardEventsBlocked: noop,
});
