import { Flex, Spacer, Text } from '@chakra-ui/react';
import Tippy from '@tippyjs/react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import DimensionSearchMenu from 'components/AttributeTypeaheadMenu/DimensionSearchMenu';
import AttributesForDriverSelectMenuItem from 'components/DimensionalDriverMenu/AttributesForDriverSelectMenuItem';
import DimensionAttributeSelectMenu from 'components/DimensionalDriverMenu/DimensionalAttributeSelectMenu';
import DimensionColorPicker from 'components/OrgSettingsModalDimensionsTab/DimensionColorPicker';
import SelectMenu, { Section, SelectItem, SelectResult } from 'components/SelectMenu/SelectMenu';
import {
  DimDriverEditReactContext,
  PLACEHOLDER_ATTRIBUTE_TYPE,
  isPlaceholderAttribute,
} from 'config/dimDriverEditContext';
import theme from 'config/theme';
import { preventEventDefault } from 'helpers/browserEvent';
import { safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { createDimension } from 'reduxStore/actions/dimensionMutations';
import { DimensionId, DimensionType } from 'reduxStore/models/dimensions';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import Arrowhead from 'vectors/Arrowhead';
import Plus from 'vectors/Plus';

export const SEARCH_KEYS = ['name'];
type SelectItemBase = SelectItem & {
  sectionId: 'attributes';
  dimensionId: DimensionId;
  type: DimensionType | typeof PLACEHOLDER_ATTRIBUTE_TYPE | 'Pseudo';
};

type DimensionAttributeItem = SelectItemBase;

interface Props {
  initDimensionId?: DimensionId;
  onCancel?: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const DimensionalDriverMenu: React.FC<Props> = ({ initDimensionId, onCancel, inputRef }) => {
  const dispatch = useAppDispatch();

  const { onSave, subDriverName, attributes, toggleAttr, errorMessage } =
    useContext(DimDriverEditReactContext);

  const dimensionsById = useAppSelector(dimensionsByIdSelector);

  // this state is used to keep track of the focused dimension element from within the Select Menu component
  const [focusedDimElementIndex, setFocusedDimElementIndex] = useState<number | undefined>(
    undefined,
  );
  const [selectedDimensionId, setSelectedDimId] = useState<DimensionId | undefined | 'add'>(
    initDimensionId ?? (attributes.length === 0 ? 'add' : undefined),
  );
  const [prevSelectedDimId, setPrevSelectedDimId] = useState<DimensionId | undefined | 'add'>(
    undefined,
  );

  useEffectOnce(() => {
    if (items.length === 0 && startFocusIdx === undefined) {
      setSelectedDimId('add');
    }
  });

  useEffect(() => {
    // We only want this to happen when the user clicks on one of the attribute pills
    if (initDimensionId != null) {
      setSelectedDimId(initDimensionId);
    }
  }, [initDimensionId]);

  const setSelectedDimensionId = useCallback(
    (dimensionId: DimensionId | undefined | 'add') => {
      setPrevSelectedDimId(selectedDimensionId);
      setSelectedDimId(dimensionId);
    },
    [selectedDimensionId],
  );

  const sections: Section[] = useMemo(
    () => [
      {
        id: 'attributes',
        name: `Dimensions for ${subDriverName}`,
      },
    ],
    [subDriverName],
  );

  const dimensionIds: DimensionId[] = useMemo(
    () => attributes.map((a) => a.dimensionId),
    [attributes],
  );

  const dimensionIdToCheck = selectedDimensionId ?? prevSelectedDimId;

  const { items, startFocusIdx } = useMemo(() => {
    const itemList: DimensionAttributeItem[] = [];
    let startIdx: number | undefined;

    attributes.forEach((attr, idx) => {
      const attrId = isPlaceholderAttribute(attr) ? PLACEHOLDER_ATTRIBUTE_TYPE : attr.id;
      const dimension = safeObjGet(dimensionsById[attr.dimensionId]);
      itemList.push({
        type: attr.type,
        dimensionId: attr.dimensionId,
        id: `${attr.dimensionId}.${attrId}`,
        name: dimension?.name,
        meta: (
          <Tippy
            interactive
            offset={[0, 4]}
            placement="bottom-start"
            appendTo={document.body}
            visible={selectedDimensionId === attr.dimensionId}
            zIndex={theme.zIndices.popover}
            render={() => {
              return selectedDimensionId === attr.dimensionId ? (
                <DimensionAttributeSelectMenu
                  dimensionId={attr.dimensionId}
                  onUpdateDimension={() => {
                    setSelectedDimensionId(undefined);
                  }}
                />
              ) : null;
            }}
          >
            <Flex
              data-testid="meta-value"
              className="meta-value"
              alignItems="center"
              px={2}
              borderRadius="md"
              height={6}
              width="14rem"
              flexDir="row"
            >
              <Text noOfLines={1} fontSize="xs">
                {isPlaceholderAttribute(attr) ? 'None' : attr.value.toString()}
              </Text>
              <Spacer />
              <Arrowhead boxSize={2} direction="down" />
            </Flex>
          </Tippy>
        ),
        sectionId: 'attributes' as const,
        isChecked: undefined,
        icon: <DimensionColorPicker dimension={dimension} />,
      });

      if (attr.dimensionId === dimensionIdToCheck) {
        startIdx = idx;
      }
    });
    return { startFocusIdx: startIdx, items: itemList };
  }, [attributes, dimensionsById, selectedDimensionId, dimensionIdToCheck, setSelectedDimensionId]);

  useEffect(() => {
    // we want to make sure the text input element is focused when there is
    // no dimension select element selected
    if (items.length !== 0 && selectedDimensionId === undefined) {
      inputRef?.current?.focus();
      setFocusedDimElementIndex(-1);
    }
  }, [inputRef, items.length, selectedDimensionId]);

  const handleSelectDimension = useCallback(
    (dimensionId: string) => {
      toggleAttr({ dimensionId, type: PLACEHOLDER_ATTRIBUTE_TYPE });
      setSelectedDimensionId(dimensionId);
    },
    [toggleAttr, setSelectedDimensionId],
  );

  const onSelect = useCallback(
    (item: DimensionAttributeItem) => {
      const activeElementId = document.activeElement?.id;
      const isInputElementFocused = activeElementId === 'create-driver-input';

      const noFocusedDimensionElements =
        focusedDimElementIndex === -1 || focusedDimElementIndex === undefined;

      if (selectedDimensionId === item.dimensionId) {
        setSelectedDimensionId(undefined);
        return;
      }

      if (isInputElementFocused && noFocusedDimensionElements) {
        return;
      }

      setSelectedDimensionId(item.dimensionId);
    },
    [focusedDimElementIndex, selectedDimensionId, setSelectedDimensionId],
  );

  const onCreateNewDimension = useCallback(
    (dimensionName: string) => {
      const dimensionId = uuidv4();
      dispatch(
        createDimension({
          id: dimensionId,
          name: dimensionName,
        }),
      );
      toggleAttr({ dimensionId, type: PLACEHOLDER_ATTRIBUTE_TYPE });
      setSelectedDimensionId(dimensionId);
    },
    [dispatch, toggleAttr, setSelectedDimensionId],
  );

  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      const activeElementId = document.activeElement?.id;
      const isCreateDriverInputFocused = activeElementId === 'create-driver-input';
      const isSubdriverInputFocused = activeElementId === 'subdriver-driver-rename-input';
      const noFocusedDimensionElements =
        focusedDimElementIndex === -1 || focusedDimElementIndex === undefined;
      switch (ev.key) {
        case 'Enter': {
          if (
            (isCreateDriverInputFocused || isSubdriverInputFocused) &&
            noFocusedDimensionElements
          ) {
            // saving when the input element is the only one focused (no dimension select element is focused)
            preventEventDefault(ev);
            onSave({ focusOnExistingDriver: true });
          }

          break;
        }
        case 'Escape': {
          if (isSubdriverInputFocused) {
            onCancel?.();
            return;
          }
          if (!isCreateDriverInputFocused && selectedDimensionId == null) {
            inputRef?.current?.focus();
            setSelectedDimensionId(undefined);
            preventEventDefault(ev);
            setFocusedDimElementIndex(-1);
            break;
          }

          if (selectedDimensionId != null) {
            setSelectedDimensionId(undefined);
            preventEventDefault(ev);
          } else {
            onCancel?.();
          }
          break;
        }

        default: {
          break;
        }
      }
    },
    [
      focusedDimElementIndex,
      selectedDimensionId,
      onSave,
      inputRef,
      onCancel,
      setSelectedDimensionId,
    ],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true);
    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onKeyDown]);

  const onFocusChange = (res: SelectResult<SelectItemBase> | null, _query: string) => {
    if (res == null) {
      return;
    }
    setFocusedDimElementIndex(res.idx);
    inputRef?.current?.blur();
  };

  const resetFocus = useCallback(() => {
    setFocusedDimElementIndex(-1);
  }, []);

  const onClickOutside = useCallback(() => {
    if (errorMessage != null) {
      onCancel?.();
      return;
    }

    onSave({ focusOnExistingDriver: true });
    resetFocus();
  }, [errorMessage, onCancel, onSave, resetFocus]);

  const contentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(contentRef, onClickOutside);

  return (
    <Flex ref={contentRef}>
      <SelectMenu
        hasPopoverOpen={selectedDimensionId != null}
        clearFocusOnMouseLeave
        allowEmptyQuery
        items={items}
        startFocusIdx={-1}
        onFocusChange={onFocusChange}
        sections={sections}
        searchKeys={SEARCH_KEYS}
        onSelect={onSelect}
        variant="metaSelect"
        customOptions={[
          {
            id: 'create',
            shortcutHint: null,
            render: (_q: string) => (
              <Tippy
                interactive
                offset={[0, -30]}
                placement="bottom-start"
                appendTo={document.body}
                zIndex={theme.zIndices.popover + 1}
                visible={selectedDimensionId === 'add'}
                render={() =>
                  selectedDimensionId === 'add' ? (
                    <DimensionSearchMenu
                      excludeDimensionIds={dimensionIds}
                      onSelectDimension={handleSelectDimension}
                      onCreateDimension={onCreateNewDimension}
                      onCancel={() => setSelectedDimensionId(undefined)}
                    />
                  ) : null
                }
              >
                <Flex
                  align="center"
                  data-testid="add-dimension-custom-option"
                  className="add-value"
                  columnGap={1}
                  color="gray.500"
                  px={2}
                >
                  <Plus />
                  <Text>Add dimension</Text>
                </Flex>
              </Tippy>
            ),
            onSelect: () => {
              const activeElementId = document.activeElement?.id;
              const isCreateDriverInputFocused = activeElementId === 'create-driver-input';
              const isSubdriverInputFocused = activeElementId === 'subdriver-driver-rename-input';
              if (isCreateDriverInputFocused || isSubdriverInputFocused) {
                return;
              }
              setSelectedDimensionId('add');
            },
          },
        ]}
      >
        {AttributesForDriverSelectMenuItem}
      </SelectMenu>
    </Flex>
  );
};

export default DimensionalDriverMenu;
