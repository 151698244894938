import { createSelector } from 'reselect';

import { CellSelection, DriverDataCellRef } from 'config/cells';
import { isDriverDataCellRef } from 'helpers/cells';
import { BlockId } from 'reduxStore/models/blocks';
import {
  EventGroupId,
  EventId,
  PlanTimelineItemRef,
  isEntityType,
  isEventType,
} from 'reduxStore/models/events';
import { SelectionState } from 'reduxStore/reducers/pageSlice';
import { Selector } from 'types/redux';

export const pageSelectionSelector: Selector<SelectionState> = (state) =>
  state.page.selection ?? null;

export const pageSelectionBlockIdSelector: Selector<BlockId | null> = createSelector(
  pageSelectionSelector,
  (selection) => {
    return selection != null && 'blockId' in selection ? (selection.blockId ?? null) : null;
  },
);

const modalSelectionSelector: Selector<CellSelection | null> = (state) =>
  state.page.modalSelection ?? null;

export const prevailingSelectionSelector: Selector<SelectionState> = createSelector(
  modalSelectionSelector,
  pageSelectionSelector,
  (modalSelection, pageSelection) => modalSelection ?? pageSelection,
);

export const prevailingSelectionTypeSelector: Selector<NonNullable<SelectionState>['type'] | null> =
  createSelector(prevailingSelectionSelector, (selection) => selection?.type ?? null);

export const prevailingSelectionBlockIdSelector: Selector<BlockId | null> = createSelector(
  prevailingSelectionSelector,
  (selection) => {
    return selection != null && 'blockId' in selection ? (selection.blockId ?? null) : null;
  },
);

export const selectedPlanTimelineItemRefSelector = createSelector(
  pageSelectionSelector,
  (selection) => (selection?.type === 'eventsAndGroups' ? selection : null),
);

export const activeEventGroupIdSelector: Selector<EventGroupId | null> = createSelector(
  selectedPlanTimelineItemRefSelector,
  (selection) => {
    if (selection == null || selection.active.type !== 'group') {
      return null;
    }

    return selection.active.id;
  },
);

export function eventIdsFromSelection(
  selection: SelectionState & { type: 'eventsAndGroups' },
): EventId[] {
  const directlySelectedEventIds = selection.refs.filter(isEventType).map((ref) => ref.id) ?? [];
  // If the user clicks on an entity row, we want to include all the events associated with that entity.
  const selectedThroughEntityEventIds = selection.refs
    .filter(isEntityType)
    .flatMap((ref) => ref.refs.map((r) => r.id));
  return [...directlySelectedEventIds, ...selectedThroughEntityEventIds];
}

export const selectedEventIdsSelector = createSelector(
  selectedPlanTimelineItemRefSelector,
  (selection) => {
    if (selection == null) {
      return [];
    }

    return eventIdsFromSelection(selection);
  },
);

export const activePlanTimelineItemRefSelector: Selector<PlanTimelineItemRef | undefined> =
  createSelector(selectedPlanTimelineItemRefSelector, (selection) => {
    return selection?.active;
  });

const pageCellSelectionSelector: Selector<CellSelection | null> = createSelector(
  pageSelectionSelector,
  (selection) => {
    switch (selection?.type) {
      case 'eventsAndGroups': {
        return selection.cellSelection ?? null;
      }
      case 'cell': {
        return selection;
      }
      default: {
        return null;
      }
    }
  },
);

export const pageDriverDataCellsSelector: Selector<DriverDataCellRef[] | null> = createSelector(
  pageCellSelectionSelector,
  (cellSelection) => {
    if (cellSelection == null) {
      return null;
    }

    return cellSelection.selectedCells.filter(isDriverDataCellRef);
  },
);
