import BaseFormulaChunk from 'components/Formula/BaseFormulaChunk';

interface Props {
  text: string;
}

const InvalidChunk: React.FC<Props> = ({ text }) => {
  return <BaseFormulaChunk text={text} color="red.600" />;
};

export default InvalidChunk;
