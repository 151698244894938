import { Flex, FlexProps, StyleProps, useMergeRefs } from '@chakra-ui/react';
import React, { useContext } from 'react';

import CellBorder from 'components/CellBorder/CellBorder';
import { CellSelectionStateContext, COLUMN_HEADER_CELL_HEIGHT_PX } from 'config/cells';
import { toPxString } from 'helpers/styles';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useScrollToCell from 'hooks/useScrollToCell';
import { OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY } from 'reduxStore/models/blocks';

interface Props {
  children?: React.ReactNode;
  isData: boolean;
  isSticky?: boolean;
  left?: string | number;
  width: StyleProps['width'];
  onClick?: React.MouseEventHandler;
  'data-testid'?: string;
  height?: StyleProps['height'];
  overflow?: StyleProps['overflow'];
  columnKey?: string;
  orientation?: 'vertical' | 'horizontal';
  headerProps?: FlexProps;
}

const TableHeaderCell = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    isData,
    left,
    width = 'full',
    isSticky = false,
    onClick,
    height = toPxString(COLUMN_HEADER_CELL_HEIGHT_PX),
    overflow,
    columnKey = '',
    orientation = 'horizontal',
    headerProps: flexProps = {},
  } = props;

  const { canWritePermissions } = useAccessCapabilities();
  const isNameColumn = columnKey === OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY;

  const isNonEditableNameColumn = isNameColumn && !canWritePermissions;

  const { isSelected, isBackgroundSelected } = useContext(CellSelectionStateContext);

  const scrollRef = useScrollToCell(isSelected && !isBackgroundSelected);
  const mergedRef = useMergeRefs(ref, scrollRef);

  return (
    <Flex
      ref={mergedRef}
      data-testid="table-header-cell"
      alignItems="flex-end"
      color="gray.500"
      fontWeight="medium"
      fontSize="xs"
      justifyContent={isData ? 'flex-end' : undefined}
      left={isSticky && left != null ? `${left}px` : undefined}
      position={isSticky ? 'sticky' : 'relative'}
      userSelect="none"
      pb={1}
      px={2}
      pt={2}
      width={width}
      height={height}
      onClick={onClick}
      zIndex={isSticky ? 'docked' : undefined}
      flexShrink={0}
      overflow={overflow}
      _hover={
        onClick != null ? { bgColor: isNonEditableNameColumn ? undefined : 'white' } : undefined
      }
      borderTopLeftRadius="md"
      borderTopRightRadius="md"
      {...flexProps}
    >
      {children}
      <CellBorder isRowHeader={orientation === 'horizontal'} />
    </Flex>
  );
});

export default React.memo(TableHeaderCell);
