import { DateTime, MonthNumbers } from 'luxon';
import { createContext } from 'react';

import { SaveExtraMetadata } from 'components/EscapableInput/EscapableInput';
import { TODAY } from 'helpers/dates';

const CURRENT_YEAR = TODAY.year;
const CURRENT_MONTH = TODAY.month;

const NOOP = () => {};

export type DatePickerContext = {
  pagedMonth: MonthNumbers;
  pagedYear: number;
  pageBy: 'month' | 'year';
  decrement: React.MouseEventHandler;
  increment: React.MouseEventHandler;
  focusOnDatePicker: () => void;
  onDateSelect: (value: DateTime, metadata: SaveExtraMetadata | { reason: 'button' }) => void;
};

export const DatePickerReactContext = createContext<DatePickerContext>({
  pagedMonth: CURRENT_MONTH,
  pagedYear: CURRENT_YEAR,
  decrement: NOOP,
  increment: NOOP,
  focusOnDatePicker: NOOP,
  onDateSelect: NOOP,
  pageBy: 'year',
});
