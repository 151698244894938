import React, { cloneElement, useMemo } from 'react';

import DriverComparisonHeaderRow from 'components/DriverComparisonHeaderRow/DriverComparisonHeaderRow';
import DriverTableRow from 'components/DriverTableRow/DriverTableRow';
import { RenderModeContextProvider } from 'components/List/enhanceComponent';
import { VirtualizedListItemProps } from 'components/VirtualizedList/types';
import { ComparisonColumn, ComparisonTimePeriod } from 'generated/graphql';

interface Props {
  isHeaderRow: boolean;
  driverId: string;
  groupId?: string;
  comparisonRowLayerId?: string;
  comparisonType?: ComparisonColumn;
  comparisonTimePeriod?: ComparisonTimePeriod;
}

const VirtualizedDriverRow: React.FC<
  {
    id: string;
  } & Props &
    VirtualizedListItemProps
> = ({
  id,
  isHeaderRow,
  driverId,
  groupId,
  comparisonRowLayerId,
  comparisonType,
  visible,
  scrolling,
  comparisonTimePeriod,
}) => {
  const element = useMemo(() => {
    return (
      <RenderModeContextProvider key={id} id={id} defaultRenderMode="LIGHT">
        {isHeaderRow ? (
          <DriverComparisonHeaderRow key={driverId} driverId={driverId} />
        ) : (
          <DriverTableRow
            key={`${driverId}/${comparisonRowLayerId}`}
            driverId={driverId}
            groupId={groupId}
            comparisonType={comparisonType}
            comparisonRowLayerId={comparisonRowLayerId}
            comparisonTimePeriod={comparisonTimePeriod}
          />
        )}
      </RenderModeContextProvider>
    );
  }, [
    id,
    isHeaderRow,
    driverId,
    comparisonRowLayerId,
    groupId,
    comparisonType,
    comparisonTimePeriod,
  ]);

  // we want to keep element as stable as possible so we don't include visible or scrolling in the useMemo deps above. Instead we use cloneElement to update the props
  return cloneElement(element, { scrolling, visible });
};

export default React.memo(VirtualizedDriverRow);
