import { createSelector } from '@reduxjs/toolkit';

import { launchDarklySelector } from 'selectors/launchDarklySelector';
import { serverHealthSelector } from 'selectors/serverHealthSelector';
import { Selector } from 'types/redux';

export type CalculationEngine = 'webworker' | 'backend' | 'backend-ww-disabled' | 'backend-only';
export function shouldUseBackend(engine: CalculationEngine | string): boolean {
  return engine.includes('backend');
}

const calculationOverrideSelector: Selector<CalculationEngine | null> = (state) =>
  state.calculations.calculationEngineOverride;

export const calculationEngineSelector = createSelector(
  launchDarklySelector,
  serverHealthSelector,
  calculationOverrideSelector,
  ({ calculationEngine }, serverHealth, calculationEngineOverride) => {
    const { calculatorReady } = serverHealth;
    // Anytime the calculator is _not_ ready,
    // e.g.,
    //   * Backend calc is disabled for this org
    //   * The calculator is still initializing
    //   * The calculator is in an error state
    // ...we should gracefully degrade to the webworker.
    if (!calculatorReady) {
      return 'webworker';
    }

    if (calculationEngineOverride != null) {
      return calculationEngineOverride;
    }

    return calculationEngine ?? 'backend-ww-disabled';
  },
);

export const isUsingBackendCalculatorSelector = createSelector(
  calculationEngineSelector,
  (engine) => shouldUseBackend(engine),
);

export const enableBackendBlockEvalSelector = createSelector(
  calculationEngineSelector,
  (calcEngine) => (calcEngine === 'backend-only' || calcEngine === 'backend-ww-disabled') ?? false,
);
