import { Point } from '@visx/point';
import sortedIndex from 'lodash/sortedIndex';
import React, { useMemo } from 'react';

import { DEFAULT_DRIVER_CHART_SIZE } from 'config/block';
import { DRIVER_CHART_MARGINS_BY_SIZE } from 'config/driverChart';
import { getDateTimeFromMonthKey } from 'helpers/dates';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartContext from 'hooks/useChartContext';
import { setCursorMonthKey } from 'reduxStore/reducers/cursorSlice';
import { blockMonthKeysSelector } from 'selectors/pageDateRangeSelector';

interface Props {
  getPoint: (event: React.MouseEvent<SVGElement>) => Point | null;
}

const CursorMonthTracker: React.FC<Props> = ({ getPoint }) => {
  const { timeScale, size, width, height } = useChartContext();
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();

  const chartMargins =
    DRIVER_CHART_MARGINS_BY_SIZE[size] ?? DRIVER_CHART_MARGINS_BY_SIZE[DEFAULT_DRIVER_CHART_SIZE];
  const monthKeys = useAppSelector((state) => blockMonthKeysSelector(state, blockId));
  const handlePointer = useMemo(() => {
    const chartDates = monthKeys
      .map(getDateTimeFromMonthKey)
      .map((dt) => dt.endOf('month').toJSDate())
      .sort((a, b) => a.getTime() - b.getTime());

    return (ev: React.MouseEvent<SVGElement>) => {
      // SVG coordinates
      const point = getPoint(ev);
      if (point == null) {
        return;
      }

      // Adjusting for the margins
      const timeX = point.x - chartMargins.left;
      const adjustedTimeX = Math.min(Math.max(timeX, 0), width);
      const date = timeScale.invert(adjustedTimeX);
      const chartDateIdx = sortedIndex(chartDates, date);

      // the cursor is between two dates (larger and smaller), and we need to figure out which one is closer
      // the largest index can be at most equal to the length of the array minus 1 (if the cursor is at the last element of the chart)
      const larger = chartDateIdx <= chartDates.length - 1 ? chartDates[chartDateIdx] : null;
      const smaller = chartDateIdx > 0 ? chartDates[chartDateIdx - 1] : null;

      const largerDiff =
        larger != null ? Math.abs(date.getTime() - larger.getTime()) : Number.MAX_VALUE;
      const smallerDiff =
        smaller != null ? Math.abs(date.getTime() - smaller.getTime()) : Number.MAX_VALUE;

      const idx = largerDiff <= smallerDiff ? chartDateIdx : chartDateIdx - 1;

      const newMonthKey = monthKeys[idx];
      dispatch(setCursorMonthKey(newMonthKey));
    };
  }, [timeScale, chartMargins, getPoint, dispatch, monthKeys, width]);

  return (
    <rect
      onMouseMove={handlePointer}
      x={0}
      y={0}
      width={width + chartMargins.left + chartMargins.right}
      height={height + chartMargins.top + chartMargins.bottom}
      fill="transparent"
    />
  );
};

export default React.memo(CursorMonthTracker);
