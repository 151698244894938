import { uniq } from 'lodash';
import pluralize from 'pluralize';
import { createSelector } from 'reselect';

import { toStringList } from 'helpers/array';
import { isNotNull } from 'helpers/typescript';
import { CopilotOperation } from 'reduxStore/reducers/pageSlice';
import { localEphemeralMutationSelector } from 'selectors/datasetSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { numSelectedDriversSelector } from 'selectors/prevailingCellSelectionSelector';

const copilotSessionSelector = createSelector(pageSelector, (page) => page.copilotSession ?? null);

export const copilotRequestStatusSelector = createSelector(
  copilotSessionSelector,
  (copilot) => copilot?.requestStatus ?? null,
);

export const copilotOperationSelector = createSelector(
  copilotSessionSelector,
  (copilot) => copilot?.operation ?? null,
);

export const isQueryingCopilotSelector = createSelector(
  copilotSessionSelector,
  (copilot) => copilot?.requestStatus === 'pending',
);

export const copilotCursorSelector = createSelector(
  copilotSessionSelector,
  (copilot) => copilot?.cursor ?? null,
);

export const hasActiveCopilotSessionSelector = createSelector(
  copilotSessionSelector,
  (copilot) => copilot != null,
);

export const copilotActionMessageSelector = createSelector(
  copilotSessionSelector,
  localEphemeralMutationSelector,
  numSelectedDriversSelector,
  (copilot, ephemeralMutation, numSelectedDrivers) => {
    if (copilot == null) {
      return null;
    }

    if (copilot.requestStatus === 'pending') {
      return 'Thinking';
    }

    if (copilot.requestStatus === 'error') {
      return 'Hmmm, something went wrong. Please try again.';
    }

    if (ephemeralMutation == null) {
      return null;
    }

    switch (copilot.operation) {
      case CopilotOperation.GroupDrivers: {
        const updateDrivers = ephemeralMutation.mutation.updateDrivers ?? [];
        const newDriverGroups = ephemeralMutation.mutation.newDriverGroups ?? [];
        const numNewGroups = newDriverGroups.length;
        const numUpdatedDrivers = updateDrivers.length;
        const numGroups = uniq(
          updateDrivers
            .map((d) => d.driverReferences?.find((ref) => ref.groupId)?.groupId)
            .filter(isNotNull),
        ).length;
        if (numGroups === 0 || numUpdatedDrivers === 0) {
          return null;
        }

        const updatedAllDrivers = updateDrivers.length === numSelectedDrivers;
        const allNewGroups = numNewGroups === numGroups;
        const drivers = pluralize('driver', numUpdatedDrivers);
        const groups = pluralize('group', numGroups);
        const newGroups = pluralize('group', numNewGroups);
        return [
          'I grouped',
          updatedAllDrivers
            ? `the ${numSelectedDrivers} selected ${drivers}`
            : `${numUpdatedDrivers} ${drivers}`,
          'into',
          allNewGroups || numNewGroups === 0
            ? `${numGroups}${numNewGroups > 0 ? ' new' : ''} ${groups}.`
            : `${numGroups} ${groups} and created ${numNewGroups} new ${newGroups}.`,
        ].join(' ');
      }
      case CopilotOperation.GroupEvents: {
        const newEventGroups = ephemeralMutation.mutation.newEventGroups ?? [];
        const numNewGroups = newEventGroups.length;
        const deletedEventGroups = ephemeralMutation.mutation.deleteEventGroups ?? [];
        const numDeletedEventGroups = deletedEventGroups.length;

        const updatedEventGroups = (ephemeralMutation.mutation.updateEventGroups ?? []).filter(
          (g) => 'name' in g,
        );
        const numUpdatedEventGroups = updatedEventGroups.length;

        if (numNewGroups === 0 && numDeletedEventGroups === 0 && numUpdatedEventGroups === 0) {
          return null;
        }

        const onlyUpdate =
          numUpdatedEventGroups > 0 && numDeletedEventGroups === 0 && numNewGroups === 0;
        if (onlyUpdate) {
          return `I renamed the selected ${pluralize('plan', numUpdatedEventGroups)}`;
        }

        const renameClause =
          numUpdatedEventGroups > 0
            ? `renamed ${numUpdatedEventGroups} ${pluralize('plan', numUpdatedEventGroups)}`
            : null;
        const deletedClause =
          numDeletedEventGroups > 0
            ? `deleted ${numDeletedEventGroups} empty ${pluralize('plan', numDeletedEventGroups)}`
            : null;
        const newClause =
          numNewGroups > 0
            ? `created ${numNewGroups} new ${pluralize('plan', numNewGroups)}`
            : null;

        const clauses = [renameClause, newClause, deletedClause].filter(isNotNull);
        return `I ${toStringList(clauses)}.`;
      }
      default: {
        return null;
      }
    }
  },
);
