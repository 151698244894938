import { ITooltipParams } from 'ag-grid-community';

import { isTimeSeriesCellError } from 'components/AgGridComponents/CellRenderer/TimeseriesCellRenderer';
import { HARD_CODED_ACTUAL_TOOLTIP } from 'components/AgGridComponents/CellRenderer/constants';
import { agGridCellValueStyle } from 'components/AgGridComponents/CellRenderer/helpers';
import { CellValueStyle } from 'components/AgGridComponents/CellRenderer/types';
import {
  isObjectPropertyRowCellError,
  objectPropertyDeriveValueStyle,
} from 'components/AgGridComponents/ObjectPropertyCellRenderer/ObjectPropertyCellRenderer';
import {
  ColumnDef as DatabaseColumnDef,
  DatabaseObjectRow,
} from 'components/AgGridComponents/types/DatabaseColumnDef';
import { DriverGridRow } from 'components/AgGridComponents/types/DriverColumnDef';
import {
  TimeseriesColumnDef,
  TimeseriesPropertyRow,
} from 'components/AgGridComponents/types/TimeseriesColumnDef';
import CellImpactTooltipContent from 'components/PlanImpactTooltipContent/CellImpactTooltipContent';
import { CellValueTooltipData } from 'config/cells';
import { ValueType } from 'generated/graphql';
import { shortMonthFormat } from 'helpers/dates';
import { nullSafeEqual } from 'helpers/typescript';
import { CellImpact } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';

type Data = DatabaseObjectRow | TimeseriesPropertyRow | DriverGridRow;

function isDatabaseRow(data: Data | null | undefined): data is DatabaseObjectRow {
  if (data == null) {
    return false;
  }
  return data.type === 'objectRow';
}

function isTimeSeriesPropertyRow(data: Data | null | undefined): data is TimeseriesPropertyRow {
  if (data == null) {
    return false;
  }
  return data.type === 'propertyRow';
}

// Helper to handle some of the common cases.
function getDefaultCellTooltipData({
  isMapped,
  isRowIntegration,
  isColumnIntegration,
  valueStyle,
  cellImpact,
  layerId = undefined,
}: {
  isMapped: boolean;
  isRowIntegration: boolean;
  isColumnIntegration: boolean;
  valueStyle: CellValueStyle;
  cellImpact: CellImpact | null;
  layerId?: LayerId | undefined;
}): CellValueTooltipData | undefined {
  if (isMapped) {
    return { content: { type: 'autofilled', msg: 'Value is autofilled' } };
  }

  if (isRowIntegration && isColumnIntegration) {
    return {
      content: { type: 'sourced_from_integration', msg: 'Value is sourced from an integration' },
    };
  }

  if (valueStyle === 'actuals-hardcoded') {
    return { content: { type: 'actuals-hardcoded', msg: HARD_CODED_ACTUAL_TOOLTIP } };
  }

  if (valueStyle === 'forecast-impact' && cellImpact != null) {
    return {
      content: {
        type: 'forecast-impact',
        msg: <CellImpactTooltipContent cellImpact={cellImpact} layerId={layerId} />,
      },
    };
  }

  return undefined;
}

// We need to have a different tooltip value getter for each row/col definition
// because the shape of the data is different.

export const databaseRowToolTipValueGetter = (
  tooltipParams: ITooltipParams<DatabaseObjectRow, DatabaseObjectRow['data'][string]>,
) => {
  const { data, value, colDef } = tooltipParams;
  if (
    colDef == null ||
    'children' in colDef ||
    colDef.colId == null ||
    !isDatabaseRow(data) ||
    value == null
  ) {
    return undefined;
  }

  const { colId } = colDef;
  const databaseColumnDef = colDef as DatabaseColumnDef;

  const fieldSpec = databaseColumnDef.fieldSpec;
  const isMapped = Boolean(fieldSpec.isMapped);
  const isRowIntegration = Boolean(data?.isIntegration);
  const isColumnIntegration = Boolean(fieldSpec.isIntegration);

  const valueStyle = agGridCellValueStyle(value, data, databaseColumnDef);
  const { monthKey, layerId } = databaseColumnDef.fieldSpec;

  const subdriverMeta = data?.subDriverMetaByColId[colId];

  const vals = Array.isArray(value) ? value : [value];
  const firstErr = vals.find(isTimeSeriesCellError);
  if (firstErr != null) {
    const detailDriverId = subdriverMeta?.subDriverId;
    if (detailDriverId != null) {
      return { content: { type: 'driver_calc_error', error: firstErr, detailDriverId } };
    }
  }

  // If there is a transition value, display that only if we don't already
  // have a tooltip.
  const displayAsTransitionValue = Array.isArray(value) && !nullSafeEqual(value[0], value[1]);
  if (displayAsTransitionValue) {
    const [dateRangeStart, dateRangeEnd] = fieldSpec.blockDateRangeDateTime;
    const msg = `Showing Start -> End values from ${shortMonthFormat(
      dateRangeStart,
    )} to ${shortMonthFormat(dateRangeEnd)}`;
    return { content: { type: 'transition-value', msg } };
  }

  return getDefaultCellTooltipData({
    isMapped,
    isRowIntegration,
    isColumnIntegration,
    valueStyle,
    cellImpact: monthKey != null ? (data.cellImpactsByMonthKey[monthKey] ?? null) : null,
    layerId,
  });
};

export const timeSeriesRowToolTipValueGetter = (
  tooltipParams: ITooltipParams<TimeseriesPropertyRow, TimeseriesPropertyRow['data'][string]>,
) => {
  const { data, value, colDef } = tooltipParams;
  if (colDef == null || 'children' in colDef || colDef.colId == null) {
    return undefined;
  }

  const databaseColumnDef = colDef as TimeseriesColumnDef;
  const valueStyle = objectPropertyDeriveValueStyle(value, data, databaseColumnDef);
  const { monthKey } = databaseColumnDef.meta;
  if (!isTimeSeriesPropertyRow(data) || value == null || valueStyle == null) {
    return undefined;
  }

  if (
    (data.valueType === ValueType.Number ||
      data.valueType === ValueType.Timestamp ||
      data.valueType === ValueType.Attribute) &&
    isObjectPropertyRowCellError(value) &&
    data.driverId != null
  ) {
    return {
      content: { type: 'driver_calc_error', error: value, detailDriverId: data.driverId },
    };
  }

  const isMapped = Boolean(data.meta.isMapped);
  const isRowIntegration = Boolean(data.meta.isIntegrationObject);
  const isColumnIntegration = Boolean(data.meta.isIntegrationProperty);

  return getDefaultCellTooltipData({
    isMapped,
    isRowIntegration,
    isColumnIntegration,
    valueStyle,
    cellImpact: monthKey != null ? (data.cellImpactsByMonthKey[monthKey] ?? null) : null,
  });
};
