import { keyBy } from 'lodash';

import { BlockFilterOperator, ValueType } from 'generated/graphql';
import { CURRENT_MONTH_KEY, nextMonthKey, previousMonthKey } from 'helpers/dates';
import { getTimeRangeInput } from 'helpers/formula';
import { evaluateTimeRange } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { NullableValue } from 'reduxStore/models/value';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectSpecSelector } from 'selectors/businessObjectSpecsSelector';
import { validAttributesForDimensionSelector } from 'selectors/dimensionsSelector';
import { formulaCalculationContextSelector } from 'selectors/formulaCalculationContextSelector';
import { formulaEvaluatorForLayerSelector } from 'selectors/formulaEvaluatorSelector';
import { filtersForObjectTableBlockSelector } from 'selectors/objectTableBlockSelector';
import { MonthKey } from 'types/datetime';
import {
  AttributeFilterItem,
  FilterItem,
  NumberFilterItem,
  TimestampFilterItem,
} from 'types/filtering';
import { FormulaTimeRange } from 'types/formula';

function getNumberDefaultValue({ filter }: { filter: NumberFilterItem }): NullableValue | null {
  switch (filter.operator) {
    case BlockFilterOperator.Equals:
    case BlockFilterOperator.GreaterThanOrEqualTo:
    case BlockFilterOperator.LessThanOrEqualTo:
      return { type: ValueType.Number, value: filter.expected };
    case BlockFilterOperator.GreaterThan:
      return { type: ValueType.Number, value: (filter.expected ?? 0) + 1 };
    case BlockFilterOperator.LessThan:
      return { type: ValueType.Number, value: (filter.expected ?? 0) - 1 };
    case BlockFilterOperator.IsNotNull:
      return { type: ValueType.Number, value: 0 };
    case BlockFilterOperator.NotEquals:
    case BlockFilterOperator.IsNull:
    default:
      return null;
  }
}

function getAttributeDefaultValue({
  state,
  filter,
}: {
  state: RootState;
  filter: AttributeFilterItem;
}): NullableValue | null {
  switch (filter.operator) {
    case BlockFilterOperator.Equals:
      return { type: filter.valueType, value: filter.expected?.[0] };
    case BlockFilterOperator.IsNotNull: {
      const attribute = validAttributesForDimensionSelector(state, filter.dimensionId)[0];
      if (attribute == null) {
        return null;
      }
      return { type: filter.valueType, value: attribute.id };
    }
    case BlockFilterOperator.NotEquals:
    case BlockFilterOperator.IsNull:
    default:
      return null;
  }
}

function getTimestampDefaultValue({
  state,
  blockId,
  filter,
}: {
  state: RootState;
  blockId: BlockId;
  filter: TimestampFilterItem;
}): NullableValue | null {
  if (filter.operator === BlockFilterOperator.IsNotNull) {
    return { type: filter.valueType, value: CURRENT_MONTH_KEY };
  }
  if (
    filter.operator === BlockFilterOperator.NotEquals ||
    filter.operator === BlockFilterOperator.IsNull
  ) {
    return null;
  }

  if (filter.expected == null) {
    return null;
  }
  const matchingMonthKey = formulaTimeRangeToMatchingDate({
    state,
    blockId,
    formulaTimeRange: filter.expected,
  });
  if (matchingMonthKey == null) {
    return null;
  }

  switch (filter.operator) {
    case BlockFilterOperator.Equals:
    case BlockFilterOperator.GreaterThanOrEqualTo:
    case BlockFilterOperator.LessThanOrEqualTo:
      return { type: filter.valueType, value: matchingMonthKey };
    case BlockFilterOperator.GreaterThan:
      return { type: filter.valueType, value: nextMonthKey(matchingMonthKey) };
    case BlockFilterOperator.LessThan:
      return { type: filter.valueType, value: previousMonthKey(matchingMonthKey) };
    default:
      return null;
  }
}

function getFilterFieldDefaultValue({
  state,
  filter,
  blockId,
}: {
  state: RootState;
  filter: FilterItem;
  blockId: BlockId;
}): NullableValue | null {
  if (filter == null) {
    return null;
  }

  switch (filter.valueType) {
    case ValueType.Number:
      return getNumberDefaultValue({ filter });
    case ValueType.Attribute: {
      return getAttributeDefaultValue({ state, filter });
    }
    case ValueType.Timestamp:
      return getTimestampDefaultValue({ state, blockId, filter });
    default:
      return null;
  }
}

export function getFilterDefaultValueByFieldSpecId(
  state: RootState,
  {
    blockId,
    objectSpecId,
  }: {
    blockId: BlockId;
    objectSpecId: BusinessObjectSpecId;
  },
): Record<BusinessObjectFieldSpecId, NullableValue> {
  const objectSpec = businessObjectSpecSelector(state, objectSpecId);
  if (objectSpec == null) {
    return {};
  }

  const objectTableFilters = filtersForObjectTableBlockSelector(state, blockId);

  const objectTableFiltersByFieldSpecId = keyBy(objectTableFilters, 'filterKey');

  return [
    ...objectSpec.fields,
    ...(objectSpec.collection?.dimensionalProperties ?? []),
    ...(objectSpec.collection?.driverProperties ?? []),
  ].reduce(
    (res, fieldSpec) => {
      const fieldSpecId = fieldSpec.id;
      const filter = objectTableFiltersByFieldSpecId[fieldSpecId];

      if (filter == null) {
        return res;
      }

      const defaultValue = getFilterFieldDefaultValue({ state, blockId, filter });

      if (defaultValue != null) {
        res[fieldSpecId] = defaultValue;
      }

      return res;
    },
    {} as Record<BusinessObjectFieldSpecId, NullableValue>,
  );
}

/**
 * Returns a month key that matches the start of the given formula time range.
 */
function formulaTimeRangeToMatchingDate({
  state,
  blockId,
  formulaTimeRange,
}: {
  state: RootState;
  blockId: BlockId;
  formulaTimeRange: FormulaTimeRange;
}): MonthKey | null {
  const timeRangeFormula = getTimeRangeInput(formulaTimeRange);
  const evaluator = formulaEvaluatorForLayerSelector(state);
  const context = formulaCalculationContextSelector(state);
  const range = evaluateTimeRange({ evaluator, context, blockId, timeRangeFormula });

  if (range == null) {
    return null;
  }

  return range.start;
}
