import { useBoolean } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

export default function useHover<T extends Element = HTMLDivElement>(): [
  React.MutableRefObject<T | null>,
  boolean,
] {
  const [value, setValue] = useBoolean(false);
  const ref = useRef<T | null>(null);
  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', setValue.on);
      node.addEventListener('mouseleave', setValue.off);
      return () => {
        node.removeEventListener('mouseover', setValue.on);
        node.removeEventListener('mouseleave', setValue.off);
      };
    }

    return () => {};
  }, [setValue]);

  return [ref, value];
}
