import { Flex, useBoolean } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';

import LightBadge from 'components/LightBadge/LightBadge';
import { LightDriverNoneAttributeBadge } from 'components/LightDriverAttributeBadge/LightDriverAttributeBadge';
import { DELETED_THEME, FALLBACK_THEME, isBadgeTheme } from 'config/badge';
import useAppSelector from 'hooks/useAppSelector';
import { useMeasureText } from 'hooks/useMeasureText';
import { Attribute } from 'reduxStore/models/dimensions';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';

export const COL_GAP = 4;
const PADDING = 8;
const MIN_WIDTH = 32;

const useAttributesWidths = (
  attributes: Attribute[],
  availableWidth?: number,
  hasNoneBadge: boolean = false,
): number[] => {
  const measure = useMeasureText({ font: 'Inter', size: '12px' });
  return useMemo(() => {
    if (availableWidth == null) {
      return [];
    }

    const results = attributes.map((attr) => Math.ceil(measure(String(attr.value))?.width ?? 0));

    if (hasNoneBadge) {
      results.push(measure('None')?.width ?? 0);
    }

    return results;
  }, [attributes, measure, availableWidth, hasNoneBadge]);
};

const DefaultBadge = ({ attribute, color, shrink }: BadgeProps) => {
  const theme = attribute.deleted ? DELETED_THEME : isBadgeTheme(color) ? color : FALLBACK_THEME;
  return <LightBadge text={String(attribute.value)} theme={theme} shrink={shrink} />;
};

export type BadgeProps = {
  attribute: Attribute;
  color: string | undefined;
  shrink: boolean;
};

const BaseAttributeBadges = ({
  availableWidth,
  attributes,
  renderBadge,
  onAllClippedUpdated,
  hasNoneBadge = false,
}: {
  availableWidth?: number;
  attributes: Attribute[];
  renderBadge?: (props: BadgeProps) => React.ReactElement;
  onAllClippedUpdated?: (value: boolean) => void;
  hasNoneBadge?: boolean;
}) => {
  const [clipAll, setClipAll] = useBoolean(false);
  const [clippedIndex, setClippedIndex] = useState(-1);
  const widths = useAttributesWidths(attributes, availableWidth, hasNoneBadge);

  const dimensionsById = useAppSelector(dimensionsByIdSelector);

  const themes = useMemo(() => {
    return attributes.map((attr) => {
      const dimensionId = attr.dimensionId;
      const color = dimensionId != null ? dimensionsById[dimensionId].color : undefined;
      return isBadgeTheme(color) ? color : undefined;
    });
  }, [attributes, dimensionsById]);

  useEffect(() => {
    if (availableWidth == null) {
      setClippedIndex(-1);
      return;
    }

    if (MIN_WIDTH + 2 * PADDING > availableWidth) {
      setClipAll.on();
      onAllClippedUpdated?.(true);
      setClippedIndex(-1);
      return;
    }

    setClipAll.off();
    onAllClippedUpdated?.(false);

    let totalWidth = 0;
    let index = -1;
    for (let i = 0; i < widths.length; i++) {
      // If there is insufficient room for 3 chars & ellipsis, clip at the item before.
      if (totalWidth + MIN_WIDTH + COL_GAP + 2 * PADDING > availableWidth) {
        // If no items can be placed, collapse all pills.
        if (i === 0) {
          setClipAll.on();
          onAllClippedUpdated?.(true);
          setClippedIndex(-1);
          return;
        }

        index = i - 1;
        break;
      }

      totalWidth += widths[i] + COL_GAP + 2 * PADDING;
      if (totalWidth > availableWidth) {
        index = i;
        break;
      }
    }

    setClippedIndex(index);
  }, [widths, availableWidth, setClipAll, onAllClippedUpdated]);

  // Include the first clipped attribute to possibly render with ellipsis.
  const visibleAttributes = clipAll
    ? []
    : clippedIndex === -1
      ? attributes
      : attributes.slice(0, clippedIndex + 1);
  const clippedCount = clipAll ? attributes.length : attributes.length - visibleAttributes.length;

  return (
    <>
      <Flex columnGap={1} flexShrink={1} overflow="hidden">
        {visibleAttributes.map((attr, i) =>
          renderBadge ? (
            React.cloneElement(
              renderBadge({
                attribute: attr,
                color: themes[i],
                shrink: clippedIndex !== -1 && i >= clippedIndex,
              }),
              { key: attr.id },
            )
          ) : (
            <DefaultBadge
              key={attr.id}
              attribute={attr}
              color={themes[i]}
              shrink={clippedIndex !== -1 && i >= clippedIndex}
            />
          ),
        )}
        {clippedCount === 0 && hasNoneBadge && <LightDriverNoneAttributeBadge shrink={false} />}
      </Flex>
      {clippedCount > 0 && (
        <>
          <LightBadge text={`+${clippedCount}`} theme="black" shrink={false} />
          {hasNoneBadge && <LightDriverNoneAttributeBadge shrink={false} />}
        </>
      )}
    </>
  );
};

export default BaseAttributeBadges;
