import React, { PropsWithChildren } from 'react';

import { WebWorkerDataProviderContext, useInitial } from 'components/WebWorkerDataProvider/Context';
import { BatchCalculationProcessorProvider } from 'hooks/useBatchCalculationProcessor';
import { RequestAnimationJobQueueManagerProvider } from 'hooks/useRequestAnimationJobQueueManager';

const WebWorkerDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const value = useInitial();

  return (
    <WebWorkerDataProviderContext.Provider value={value}>
      <BatchCalculationProcessorProvider>
        <RequestAnimationJobQueueManagerProvider>
          {children}
        </RequestAnimationJobQueueManagerProvider>
      </BatchCalculationProcessorProvider>
    </WebWorkerDataProviderContext.Provider>
  );
};

WebWorkerDataProvider.displayName = 'WebWorkerDataProvider';

export default WebWorkerDataProvider;
