import { Text, useDisclosure } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { TimeUnit } from 'types/datetime';

interface Props {
  onSelect: (unit: TimeUnit) => void;
  value: TimeUnit;
}

const TimeUnitSelectMenu = ({ onSelect, value }: Props) => {
  const timePeriodItems: SelectItem[] = useMemo(() => {
    return [
      {
        id: TimeUnit.Month,
        name: pluralize(TimeUnit.Month).toLowerCase(),
      },
      {
        id: TimeUnit.Quarter,
        name: pluralize(TimeUnit.Quarter).toLowerCase(),
      },
      {
        id: TimeUnit.Year,
        name: pluralize(TimeUnit.Year).toLowerCase(),
      },
    ];
  }, []);

  const { isOpen, onClose, onOpen } = useDisclosure();

  return isOpen ? (
    <SelectMenu
      items={timePeriodItems}
      onSelect={(item) => {
        onSelect(item.id as TimeUnit);
        onClose();
      }}
      onClose={onClose}
      width="fit-content"
    >
      {BaseSelectMenuItem}
    </SelectMenu>
  ) : (
    <Text onClick={onOpen} fontSize="xs" fontWeight="normal">
      {pluralize(value).toLowerCase()}
    </Text>
  );
};

export default TimeUnitSelectMenu;
