import { keyBy } from 'lodash';

import {
  AccessorEntityType,
  AccessResource as AccessResourceGQL,
  AccessResourceType,
  AccessRule,
  BasicDriverExtension,
  BlocksPage,
  BusinessObject,
  BusinessObjectField,
  BusinessObjectFieldSpec,
  BusinessObjectFieldValue,
  BusinessObjectSpec,
  ColoringData,
  DimensionalDriverExtension,
  Driver,
  Event,
  Dataset as GQLDataset,
  Layer,
  OrgRole,
  TimeSeriesPoint,
  ValueType,
} from 'generated/graphql';
import { sanitizeValueType } from 'helpers/drivers';
import { convertTimeSeries } from 'helpers/gqlDataset';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { AccessResource } from 'reduxStore/models/accessResources';
import { LayerId } from 'reduxStore/models/layers';
import { ValueTimeSeries } from 'reduxStore/models/timeSeries';

// Set of types to convert types containing the GraphQL TimeSeriesPoint type
// to use the Value type instead.
export type ToValueTimeSeries<T> =
  T extends Array<infer V>
    ? [V] extends [TimeSeriesPoint]
      ? ValueTimeSeries
      : Array<PropertiesToValueTimeSeries<V>>
    : T extends object
      ? PropertiesToValueTimeSeries<T>
      : T;
export type PropertiesToValueTimeSeries<T> = {
  [K in keyof T]: ToValueTimeSeries<T[K]>;
};

type Dataset = NonNullable<GQLDataset>;
export type DatasetSnapshot =
  | (Omit<ToValueTimeSeries<Dataset>, 'layers'> & {
      // For now, don't transform Layers from the dataset to use the ValueTimeSeries.
      // Layers include scopedMutations (DatasetMutationData[]) which include
      // timeseries fields but we have not yet updated mutation processing to
      // assume the timeseries fields are ValueTimeSeries.
      layers: Dataset['layers'];
    })
  | null;

export function convertDatasetGQLToDatasetSnapshot(dataset: GQLDataset): DatasetSnapshot {
  if (dataset == null) {
    return null;
  }
  const businessObjectSpecsById = keyBy(dataset.businessObjectSpecs, 'id');
  const snapshot: DatasetSnapshot = {
    ...dataset,
    drivers: dataset.drivers.map((driver) => convertDriverToSnapshot(driver)),
    businessObjectSpecs: dataset.businessObjectSpecs.map((spec) =>
      convertBusinessObjectSpecToSnapshot(spec),
    ),
    businessObjects:
      dataset.businessObjects
        ?.map((obj) => convertBusinessObjectToSnapshot(obj, businessObjectSpecsById))
        .filter(isNotNull) ?? [],
    description: dataset.description,
    // This field is deprecated and should be removed once code references are removed on the front end.
    driverFields: [],
    driverFieldSpecs: [],
    events: dataset.events.map((event) => convertEventToSnapshot(event)),
    exports: dataset.exports.map((exportData) => ({
      ...exportData,
      drivers: exportData.drivers.map((driver) => convertDriverToSnapshot(driver)),
    })),
    extDrivers: dataset.extDrivers.map((extDriver) => ({
      ...extDriver,
      timeSeries:
        extDriver.timeSeries != null
          ? convertTimeSeries(extDriver.timeSeries, ValueType.Number)
          : undefined,
    })),
    extObjectSpecs: dataset.extObjectSpecs,
    extObjects: convertExtObjectsToSnapshot(dataset),
    name: dataset.name,
    layers:
      dataset.layers?.map((layer) => ({
        ...layer,
        scopedMutations: layer.scopedMutations ?? [],
      })) ?? [],
    milestones: dataset.milestones.map((milestone) => ({
      ...milestone,
      driver: convertDriverToSnapshot(milestone.driver),
    })),
    namedVersions: dataset.namedVersions ?? [],
    folders: dataset.folders ?? [],
  };

  return snapshot;
}

function convertDriverToSnapshot(driver: Driver) {
  return {
    ...driver,
    coloring: driver.coloring != null ? convertColoringDataToSnapshot(driver.coloring) : undefined,
    basic:
      driver.basic != null
        ? convertBasicDriverExtensionToSnapshot(driver.basic, driver.valueType)
        : undefined,
    dimensional:
      driver.dimensional != null
        ? convertDimensionalDriverExtensionToSnapshot(driver.dimensional)
        : undefined,
  };
}

function convertColoringDataToSnapshot(coloring: ColoringData): ToValueTimeSeries<ColoringData> {
  return {
    ...coloring,
    cells:
      coloring.cells != null ? convertTimeSeries(coloring.cells, ValueType.Timestamp) : undefined,
  };
}

function convertBasicDriverExtensionToSnapshot(
  basic: BasicDriverExtension,
  type: ValueType | undefined | null,
): ToValueTimeSeries<BasicDriverExtension> {
  const valueType = sanitizeValueType(type);
  return {
    ...basic,
    actuals: {
      ...basic.actuals,
      timeSeries:
        basic.actuals.timeSeries != null
          ? convertTimeSeries(basic.actuals.timeSeries, valueType)
          : undefined,
    },
  };
}

function convertDimensionalDriverExtensionToSnapshot(
  dimensional: DimensionalDriverExtension,
): ToValueTimeSeries<DimensionalDriverExtension> {
  return {
    ...dimensional,
    subDrivers:
      dimensional.subDrivers?.map((subDriver) => ({
        ...subDriver,
        driver: {
          ...subDriver.driver,
          coloring:
            subDriver.driver.coloring != null
              ? convertColoringDataToSnapshot(subDriver.driver.coloring)
              : undefined,
          basic:
            subDriver.driver.basic != null
              ? convertBasicDriverExtensionToSnapshot(
                  subDriver.driver.basic,
                  subDriver.driver.valueType,
                )
              : undefined,
          dimensional: undefined,
        },
      })) ?? [],
  };
}

function convertBusinessObjectSpecToSnapshot(
  spec: BusinessObjectSpec,
): ToValueTimeSeries<BusinessObjectSpec> {
  return {
    ...spec,
    fields: spec.fields?.map((field) => ({
      ...field,
      defaultValues: field.defaultValues?.map((defaultValue) => ({
        ...defaultValue,
        value: convertBusinessObjectFieldValueToSnapshot(defaultValue.value),
      })),
    })),
  };
}

function convertBusinessObjectFieldValueToSnapshot(
  fieldValue: BusinessObjectFieldValue,
  valueType: ValueType = ValueType.Number,
): ToValueTimeSeries<BusinessObjectFieldValue> {
  return {
    ...fieldValue,
    actuals:
      fieldValue.actuals != null
        ? {
            ...fieldValue.actuals,
            timeSeries:
              fieldValue.actuals.timeSeries != null
                ? convertTimeSeries(fieldValue.actuals.timeSeries, valueType)
                : undefined,
          }
        : undefined,
  };
}

function convertBusinessObjectToSnapshot(
  businessObject: BusinessObject,
  businessObjectSpecsById: NullableRecord<string, BusinessObjectSpec>,
): ToValueTimeSeries<BusinessObject> | null {
  const businessObjectSpec = businessObjectSpecsById[businessObject.specId];
  if (businessObjectSpec == null) {
    return null;
  }
  const fieldSpecById = keyBy(businessObjectSpec.fields, 'id');
  return {
    ...businessObject,
    fields: businessObject.fields
      ?.map((field) => convertBusinessObjectFieldToSnapshot(field, fieldSpecById))
      .filter(isNotNull),
  };
}

function convertBusinessObjectFieldToSnapshot(
  field: BusinessObjectField,
  fieldSpecById: NullableRecord<string, BusinessObjectFieldSpec>,
): ToValueTimeSeries<BusinessObjectField> | null {
  const fieldSpec = fieldSpecById[field.fieldSpecId];
  if (fieldSpec == null) {
    return null;
  }
  return {
    ...field,
    value:
      field.value != null
        ? convertBusinessObjectFieldValueToSnapshot(field.value, fieldSpec.type)
        : undefined,
  };
}

function convertExtObjectsToSnapshot(dataset: Dataset): ToValueTimeSeries<Dataset['extObjects']> {
  const extObjectSpecByKey = keyBy(dataset.extObjectSpecs, 'extKey');
  return dataset.extObjects
    ?.map((extObject) => {
      const extObjectSpec = extObjectSpecByKey[extObject.extSpecKey];
      if (extObjectSpec == null) {
        return null;
      }
      return {
        ...extObject,
        fields: extObject.fields
          .map((field) => {
            const fieldSpec = extObjectSpec.fields.find(
              (spec) => spec.extKey === field.extFieldSpecKey,
            );
            if (fieldSpec == null) {
              return null;
            }
            return {
              ...field,
              timeSeries: convertTimeSeries(field.timeSeries, fieldSpec.type),
            };
          })
          .filter(isNotNull),
      };
    })
    .filter(isNotNull);
}

function convertEventToSnapshot(event: Event): ToValueTimeSeries<Event> {
  return {
    ...event,
    businessObjectField:
      event.businessObjectField != null
        ? {
            ...event.businessObjectField,
            value:
              event.businessObjectField.value != null
                ? convertBusinessObjectFieldValueToSnapshot(event.businessObjectField.value)
                : undefined,
          }
        : undefined,
    customCurvePoints:
      event.customCurvePoints != null
        ? convertTimeSeries(event.customCurvePoints, event.setValueType ?? ValueType.Number)
        : undefined,
    driver: event.driver != null ? convertDriverToSnapshot(event.driver) : undefined,
  };
}

export const filterLayersAndBlocksPages = ({
  dataset,
  accessResources,
  userId,
  isEmployee,
  orgRole,
  requestedLayerId,
}: {
  dataset: Dataset;
  accessResources: AccessResourceGQL[] | AccessResource[];
  userId: string;
  isEmployee: boolean;
  orgRole?: OrgRole;
  requestedLayerId?: LayerId;
}): Dataset => {
  // Anyone without a role is a Runway employee.
  if (isEmployee || orgRole == null) {
    return dataset;
  }

  const hasAdminAccess = orgRole === OrgRole.Admin || orgRole === OrgRole.Owner;
  const layerAccessResources = accessResources.filter(
    (r): r is AccessResource & { type: AccessResourceType.Layer } =>
      r.type === AccessResourceType.Layer,
  );
  const blocksPageAccessResources = accessResources.filter(
    (r): r is AccessResource & { type: AccessResourceType.Page } =>
      r.type === AccessResourceType.Page,
  );

  const out = {
    ...dataset,
    layers:
      // Named versions might have layers set to null.
      safeObjGet(dataset.layers)?.filter(
        layerPredicate({
          hasAdminAccess,
          accessResources: layerAccessResources,
          requestedLayerId,
          userId,
        }),
      ) ?? [],
    blocksPages:
      safeObjGet(dataset.blocksPages)?.filter(
        blocksPagePredicate({
          hasAdminAccess,
          isManager: orgRole === OrgRole.Manager,
          accessResources: blocksPageAccessResources,
          userId,
        }),
      ) ?? [],
  };

  return out;
};

const layerPredicate =
  ({
    hasAdminAccess,
    requestedLayerId,
    accessResources,
    userId,
  }: {
    hasAdminAccess: boolean;
    accessResources: AccessResource[];
    requestedLayerId?: LayerId;
    userId: string;
  }) =>
  (layer: Layer): boolean => {
    if (layer.createdByUserId === userId) {
      return true;
    }

    // Filter out draft layers unless it was specifically requested by the user.
    if (hasAdminAccess && layer.isDraft && requestedLayerId !== layer.id) {
      return false;
    }

    if (hasAdminAccess) {
      return true;
    }

    return accessResources.some(({ resourceId }) => resourceId === layer.id);
  };

const blocksPagePredicate =
  ({
    hasAdminAccess,
    isManager,
    accessResources,
    userId,
  }: {
    hasAdminAccess: boolean;
    isManager: boolean;
    accessResources: AccessResource[];
    userId: string;
  }) =>
  (blocksPage: BlocksPage) => {
    if (hasAdminAccess) {
      return true;
    }

    if (blocksPage.internalPageType === 'model') {
      return true;
    }

    if (isManager) {
      // Managers have access to all pages unless explicitly denied access.
      const pageAccessResource = accessResources.find(
        ({ resourceId }) => resourceId === blocksPage.id,
      );
      if (
        pageAccessResource != null &&
        pageAccessResource.accessControlList.some(
          (ar) =>
            ar.accessRule === AccessRule.Revoked &&
            ar.entityWithAccess.type === AccessorEntityType.User &&
            ar.entityWithAccess.id === userId,
        )
      ) {
        return false;
      }

      return true;
    }

    return accessResources.some(({ resourceId }) => resourceId === blocksPage.id);
  };
