import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';

import { BuiltInDimensionType, DriverType } from 'generated/graphql';
import { getDateTimeFromMonthKey, shortMonthFormat } from 'helpers/dates';
import { formatLabel } from 'helpers/formatting';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import { nullSafeEqual, safeObjGet } from 'helpers/typescript';
import {
  Attribute,
  AttributeId,
  CalendarTimeAttribute,
  Dimension,
  DimensionId,
  RelativeTimeAttribute,
  UserAddedAttribute,
  UserAddedDimensionType,
} from 'reduxStore/models/dimensions';
import {
  DimensionalDriver,
  DimensionalSubDriver,
  Driver,
  DriverId,
} from 'reduxStore/models/drivers';
import {
  ANY_ATTR,
  AttributeFilters,
  COHORT_MONTH,
  CONTEXT_ATTR,
  DynamicAttributeFilterOption,
  NO_ATTR,
} from 'types/formula';

export const getSubDriverBySubDriverId = (dimDriver: DimensionalDriver, subDriverId: DriverId) => {
  return dimDriver.subdrivers.find((s) => s.driverId === subDriverId);
};

export const ATTRIBUTE_DELIMETER = ':';
export const getSubdriverKey = (attributeIds: AttributeId[]) => {
  return attributeIds.length === 0 ? 'none' : attributeIds.sort().join(ATTRIBUTE_DELIMETER);
};

export const getDimensionalDrivers = (drivers: Driver[]) => {
  return drivers.filter(
    (driver): driver is DimensionalDriver => driver.type === DriverType.Dimensional,
  );
};

export const getDimensionalDriverBySubDriverIdMap = (allDimDrivers: DimensionalDriver[]) => {
  const dimensionalDriverBySubDriverId: Record<DriverId, DimensionalDriver> = {};
  allDimDrivers.forEach((dimDriver) => {
    dimDriver.subdrivers.forEach((subDriver) => {
      dimensionalDriverBySubDriverId[subDriver.driverId] = dimDriver;
    });
  });
  return dimensionalDriverBySubDriverId;
};

export const getSubDriverName = (dimDriverName: string, attributeNames: string[]) => {
  if (attributeNames.length === 0) {
    return dimDriverName;
  }
  return `${dimDriverName} (${attributeNames.join(', ')})`;
};

export const attributeFilterForSubDriver = (
  dimDriver: DimensionalDriver,
  subDriver: DimensionalSubDriver,
): AttributeFilters => {
  return {
    byDimId: Object.fromEntries(
      dimDriver.dimensions.map((dim) => {
        const attr = subDriver.attributes.find((el) => el.dimensionId === dim.id);
        return [dim.id, attr == null ? [] : [attr]];
      }),
    ),
  };
};

export function isAttribute(
  attr: Attribute | DynamicAttributeFilterOption | typeof COHORT_MONTH,
): attr is Attribute {
  return !isString(attr) && isObject(attr) && 'id' in attr;
}

// TODO This does not deal with context attributes
export function getMatchingSubDrivers(
  driver: DimensionalDriver,
  attrFilters: AttributeFilters,
  contextAttributesByDimensionId: NullableRecord<DimensionId, Attribute> | undefined,
) {
  return driver.subdrivers.filter((subdriver) => {
    return driver.dimensions.every((dim) => {
      const attrFiltersForDim = safeObjGet(attrFilters.byDimId[dim.id]) ?? [];
      const attrForSubdriver = subdriver.attributes.find((el) => el.dimensionId === dim.id);
      const contextAttr = contextAttributesByDimensionId?.[dim.id];

      return filterMatchesAttr(
        attrFiltersForDim.map((attr) => (isAttribute(attr) ? attr.id : attr)),
        attrFilters.includeAllContextAttributes,
        attrForSubdriver?.id,
        contextAttr?.id,
      );
    });
  });
}

export const filterMatchesAttr = (
  filters: string[],
  includeAllContextAttributes: boolean | undefined,
  attrId: AttributeId | undefined | null,
  contextAttrId: AttributeId | undefined | null,
): boolean => {
  if (filters.length === 0) {
    if (includeAllContextAttributes) {
      return nullSafeEqual(contextAttrId, attrId);
    }
    return attrId == null;
  }
  const isMatchContext = filters.find((f) => f === CONTEXT_ATTR) != null;
  if (isMatchContext) {
    return nullSafeEqual(contextAttrId, attrId);
  }
  const anyAttrId = filters.find((f) => f === ANY_ATTR) != null;
  const noAttrId = filters.find((f) => f === NO_ATTR) != null;

  if (anyAttrId && attrId != null) {
    return true;
  }

  if (attrId == null) {
    return noAttrId;
  }

  return filters.includes(attrId);
};

export function getAttrsByDimId(attrs: Attribute[]) {
  return Object.fromEntries(attrs.map((attr) => [attr.dimensionId, attr]));
}

export function getAttrIdsByDimId(attrs: Attribute[]) {
  return mapValues(getAttrsByDimId(attrs), (attr) => attr.id);
}

/*
Gets the list of subdrivers that need to be added if you expand `driverId` by `dimension`
Subdrivers returned as the map of attributes
Resulting subdrivers will have same attributes as driverId except for on `dimension`
`driverId` points to a subdriver of `dimDriver` if `driverId` has dimensions
*/
export const getMissingSubdrivers = (
  dimDriver: DimensionalDriver | undefined,
  driverId: DriverId,
  dimension: Dimension,
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator,
): Array<Record<DimensionId, Attribute>> => {
  const dimAttrs = dimension.attributes.filter((attr) => !attr.deleted);

  if (dimDriver == null) {
    return dimAttrs.map((a) => ({ [dimension.id]: a }));
  }

  const subDriver = getSubDriverBySubDriverId(dimDriver, driverId);
  if (subDriver == null) {
    return dimAttrs.map((a) => ({ [dimension.id]: a }));
  }

  const subdriverAttrs = Object.fromEntries(
    subDriver.attributes.filter((a) => !a.deleted).map((a) => [a.dimensionId, a]),
  );

  const expandedAttrs: Array<Record<DimensionId, Attribute>> = dimAttrs.map((attr) => ({
    ...subdriverAttrs,
    [dimension.id]: attr,
  }));
  // Exclude any subdrivers which already exist
  return expandedAttrs.filter(
    (attrs) =>
      dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(
        dimDriver.id,
        Object.values(attrs).map((a) => a.id),
      ) == null,
  );
};
export const builtInDimensionToName: Record<BuiltInDimensionType, string> = {
  [BuiltInDimensionType.CalendarTime]: 'Absolute month',
  [BuiltInDimensionType.RelativeTime]: 'Relative month',
};

export function isUserAddedDimension(dimId: string) {
  return dimId !== BuiltInDimensionType.CalendarTime && dimId !== BuiltInDimensionType.RelativeTime;
}

export function isUserAddedAttribute(attr: Attribute): attr is UserAddedAttribute {
  return attr.type === UserAddedDimensionType;
}

export function isCalendarTimeAttribute(attr: Attribute): attr is CalendarTimeAttribute {
  return attr.type === BuiltInDimensionType.CalendarTime;
}

function isRelativeTimeAttribute(attr: Attribute): attr is RelativeTimeAttribute {
  return attr.type === BuiltInDimensionType.RelativeTime;
}

export function isBuiltInAttribute(
  attr: Attribute,
): attr is CalendarTimeAttribute | RelativeTimeAttribute {
  return isCalendarTimeAttribute(attr) || isRelativeTimeAttribute(attr);
}

// TODO: last relative month should have + added e.g. M4+
export function getAttributeValueString(attr: Attribute, lastRelativeTimeValue?: number): string {
  if (isCalendarTimeAttribute(attr)) {
    return shortMonthFormat(getDateTimeFromMonthKey(attr.value));
  }

  if (isRelativeTimeAttribute(attr)) {
    let postfix = '';
    if (lastRelativeTimeValue != null && lastRelativeTimeValue === attr.value) {
      postfix = '+';
    }
    return `M${attr.value}${postfix}`;
  }

  return formatLabel(attr.value);
}
