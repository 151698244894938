import { DateTime } from 'luxon';
import React from 'react';

import DisplayCellLight from 'components/DisplayCell/DisplayCellLight';
import { ValueType } from 'generated/graphql';
import { shortMonthWithDayFormat } from 'helpers/dates';
import { NullableValue, TimestampValue } from 'reduxStore/models/value';

import styles from './TimestampDisplayCell.module.scss';

interface Props {
  value: NullableValue<TimestampValue>['value'];
  isPlaceholder?: boolean;
}

const TimestampDisplayCellLight: React.FC<Props> = ({ value, isPlaceholder }) => {
  const displayValue =
    value == null || value === '' ? undefined : shortMonthWithDayFormat(DateTime.fromISO(value));
  return (
    <DisplayCellLight valueType={ValueType.Timestamp} isPlaceholder={isPlaceholder}>
      <p className={styles.displayValue}>{displayValue}</p>
    </DisplayCellLight>
  );
};

export default TimestampDisplayCellLight;
