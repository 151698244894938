import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'HamburgerMenuIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M2 4h12M2 8h12M2 12h12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  ),
});
