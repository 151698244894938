import Tippy, { TippyProps } from '@tippyjs/react';
import React, { useCallback } from 'react';

import DriverBlockSelect from 'components/DriverBlockSelect/DriverBlockSelect';
import { FLIP_POPPER_OPTIONS, NO_FLIP_POPPER_OPTIONS } from 'config/popper';
import theme from 'config/theme';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';

const OFFSET: [number, number] = [0, 0];

interface Props {
  isAdding: boolean;
  groupId?: DriverGroupId;
  belowDriverId?: DriverId;
  onClose: () => void;
  children?: JSX.Element;
  reference?: TippyProps['reference'];
  placement?: TippyProps['placement'];
  offset?: TippyProps['offset'];
  canFlip?: boolean;
  appendToParent?: boolean;
  mounted?: boolean;
}

const AddDriversPopover: React.FC<Props> = ({
  isAdding,
  groupId,
  belowDriverId,
  onClose,
  children,
  reference,
  placement = 'bottom',
  offset = OFFSET,
  canFlip = false,
  appendToParent = false,
}) => {
  const renderFn = useCallback(() => {
    return isAdding ? (
      <DriverBlockSelect onClose={onClose} groupId={groupId} belowDriverId={belowDriverId} />
    ) : null;
  }, [belowDriverId, groupId, isAdding, onClose]);

  const popperOptions = canFlip ? FLIP_POPPER_OPTIONS : NO_FLIP_POPPER_OPTIONS;
  const appendTo = appendToParent ? undefined : document.body;

  const hasChildren = React.Children.count(children) > 0;

  if (!hasChildren || children == null) {
    return (
      <Tippy
        visible
        render={renderFn}
        interactive
        reference={reference}
        placement={placement}
        popperOptions={popperOptions}
        appendTo={appendTo}
        zIndex={theme.zIndices.popover}
        offset={offset}
      />
    );
  }

  return (
    <Tippy
      visible
      render={renderFn}
      interactive
      placement={placement}
      popperOptions={popperOptions}
      appendTo={appendTo}
      zIndex={theme.zIndices.popover}
      offset={offset}
    >
      {children}
    </Tippy>
  );
};

export default AddDriversPopover;
