import { Box, Flex, Spinner, useBoolean } from '@chakra-ui/react';
import React, { useEffect, useRef, useTransition } from 'react';
import { ScrollSync } from 'react-scroll-sync';

import BlockContext from 'components/BlockContainer/BlockContext';
import CompareScenariosHeader from 'components/CompareScenariosModalContent/CompareScenariosHeader';
import DatabaseComparisons from 'components/CompareScenariosModalContent/DatabaseComparisons';
import DriverComparisons from 'components/CompareScenariosModalContent/DriverComparisons';
import IdenticalScenarios from 'components/CompareScenariosModalContent/IdenticalScenarios';
import KpisComparison from 'components/CompareScenariosModalContent/KpisComparison';
import { StickyContext } from 'components/StickyHeader/StickyContext';
import WebWorkerDataProvider from 'components/WebWorkerDataProvider/WebWorkerDataProvider';
import useAppSelector from 'hooks/useAppSelector';
import { useScenarioComparisonBlock } from 'hooks/useScenarioComparisonBlock';
import useStickyHeaderContextState from 'hooks/useStickyHeaderContextState';
import {
  identicalComparedScenariosSelector,
  scenarioComparisonPageDatabaseBlockIdSelector,
  scenarioComparisonPageDriverBlockIdSelector,
} from 'selectors/scenarioComparisonSelector';

const CompareScenariosModalContent: React.FC = () => {
  const driverBlockId = useAppSelector(scenarioComparisonPageDriverBlockIdSelector);
  const databaseBlockId = useAppSelector(scenarioComparisonPageDatabaseBlockIdSelector);
  const driverBlockCtx = useScenarioComparisonBlock(driverBlockId ?? '');
  const databaseBlockCtx = useScenarioComparisonBlock(databaseBlockId ?? '');
  const comparingScenariosAreIdentical = useAppSelector(identicalComparedScenariosSelector);
  const ref = useRef<HTMLDivElement>(null);
  const stickyContextState = useStickyHeaderContextState(ref);
  const [isLoading, setIsLoading] = useBoolean(true);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      setIsLoading.off();
    });
  }, [setIsLoading]);

  return (
    <Box minWidth="50rem" maxWidth="calc(100vw - 16rem)" data-testid="scenario-comparison-modal">
      <StickyContext.Provider value={stickyContextState}>
        <WebWorkerDataProvider>
          {driverBlockId == null || databaseBlockId == null ? (
            <Spinner />
          ) : (
            <ScrollSync horizontal vertical={false}>
              <BlockContext {...driverBlockCtx}>
                <Flex
                  ref={ref}
                  pt={8}
                  pl={8}
                  w="full"
                  flexDir="column"
                  height="calc(100vh - 80px)"
                  overflowY="auto"
                  fontWeight="medium"
                  lineHeight="1rem"
                  rowGap={4}
                >
                  <CompareScenariosHeader />
                  {(isLoading || isPending) && !comparingScenariosAreIdentical ? (
                    <Flex
                      h="full"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      gap={2}
                    >
                      <Spinner color="gray.500" />
                      Computing differences...
                    </Flex>
                  ) : comparingScenariosAreIdentical ? (
                    <IdenticalScenarios />
                  ) : (
                    <Flex flexDirection="column" gap={4} pb={8}>
                      <KpisComparison />
                      <DriverComparisons />
                      <BlockContext {...databaseBlockCtx}>
                        <DatabaseComparisons />
                      </BlockContext>
                    </Flex>
                  )}
                </Flex>
              </BlockContext>
            </ScrollSync>
          )}
        </WebWorkerDataProvider>
      </StickyContext.Provider>
    </Box>
  );
};

export default CompareScenariosModalContent;
