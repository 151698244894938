import { createSelector } from '@reduxjs/toolkit';

import { ExtEntityTable } from 'reduxStore/models/common';
import { ExtTable } from 'reduxStore/models/extTables';
import { defaultLayerSelector } from 'selectors/layerSelector';
import { Selector } from 'types/redux';

const EMPTY_EXT_TABLES_ENTITY_TABLE: ExtEntityTable<ExtTable> = { byKey: {}, allKeys: [] };

const extTablesEntityTableSelector: Selector<ExtEntityTable<ExtTable>> = createSelector(
  defaultLayerSelector,
  (layer) => {
    if (layer == null) {
      return EMPTY_EXT_TABLES_ENTITY_TABLE;
    }

    return layer.extTables;
  },
);

export const extTablesByKeySelector: Selector<Record<string, ExtTable>> = createSelector(
  extTablesEntityTableSelector,
  (extTablesEntityTable) => {
    return extTablesEntityTable.byKey;
  },
);

export const extTablesByQueryIdSelector: Selector<Record<string, ExtTable>> = createSelector(
  extTablesByKeySelector,
  (extTablesByKey) => {
    const extTablesByQueryId: Record<string, ExtTable> = {};
    Object.values(extTablesByKey).forEach((extTable) => {
      if (extTable.queryId == null) {
        return;
      }
      extTablesByQueryId[extTable.queryId] = extTable;
    });
    return extTablesByQueryId;
  },
);

export const extTableKeysWithStaleSnapshotsSelector: Selector<string[]> = createSelector(
  extTablesEntityTableSelector,
  (extTablesEntityTable) => {
    return extTablesEntityTable.allKeys.filter((extTableKey) => {
      const extTable = extTablesEntityTable.byKey[extTableKey];
      return extTable?.snapshotIsStale ?? false;
    });
  },
);
