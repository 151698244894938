import { BuiltInDimensionType, ValueType } from 'generated/graphql';
import { getAttrIdsByDimId, isAttribute } from 'helpers/dimensionalDrivers';
import {
  replaceThisKeywordWithDriver,
  replaceThisKeywordWithFiteredObjectSpec,
} from 'helpers/fieldFormulas';
import { getSingleSubdriver } from 'helpers/formula';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import {
  FormulaDisplay,
  FormulaDisplayChunkType,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { ParameterizedFormulaTerm } from 'reduxStore/actions/formulaCopyPaste';
import { BusinessObjectSpec } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObject } from 'reduxStore/models/businessObjects';
import { DimensionalPropertyId } from 'reduxStore/models/collections';
import { Attribute, AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DimensionalDriver, Driver, DriverId, DriverType } from 'reduxStore/models/drivers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { attributesByIdSelector } from 'selectors/dimensionsSelector';
import {
  dimensionalSubDriverSelector,
  driverActualsFormulaSelector,
  driverForecastFormulaSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import { extDriversByIdSelector } from 'selectors/extDriversSelector';
import {
  driverActualsFormulaDisplaySelector,
  driverForecastFormulaDisplaySelector,
  getFormulaDisplayForFormula,
} from 'selectors/formulaDisplaySelector';
import { AttributeFilters } from 'types/formula';

// This is only used for copy/paste. Should be okay not to include context attributes here.
export const getObjectFilterAttributes = (
  state: RootState,
  sourceEntityId: FormulaEntityTypedId,
  formula: string,
): Attribute[] => {
  const filterAttributes: Attribute[] = [];
  const attributesById = attributesByIdSelector(state);
  const formulaDisplay = getFormulaDisplayForFormula(state, formula, sourceEntityId);
  (formulaDisplay?.chunks ?? []).reduce((attrIds, ch) => {
    if (ch.type === FormulaDisplayChunkType.Object) {
      ch.filters?.propertyFilters.forEach((filter) => {
        if (filter.valueType === ValueType.Attribute) {
          (filter.attributeIds ?? []).forEach((attrId) => {
            if (!attrIds.has(attrId) && attributesById[attrId] != null) {
              filterAttributes.push(attributesById[attrId]);
              attrIds.add(attrId);
            }
          });
        }
      });
    }
    return attrIds;
  }, new Set<AttributeId>());
  return filterAttributes;
};

export const getParameterizedTermsForFormula = (
  state: RootState,
  sourceEntityId: FormulaEntityTypedId,
  formula: string,
): ParameterizedFormulaTerm[] => {
  const parameterizedTerms: ParameterizedFormulaTerm[] = [];
  const formulaDisplay = getFormulaDisplayForFormula(state, formula, sourceEntityId);

  const driversById = driversByIdForLayerSelector(state);
  const extDriversById = extDriversByIdSelector(state);
  const sourceSubDriver =
    sourceEntityId.type !== 'driver'
      ? null
      : dimensionalSubDriverSelector(state, sourceEntityId.id);

  const sourceFieldSpecId = sourceEntityId.type === 'objectFieldSpec' ? sourceEntityId.id : null;

  formulaDisplay?.chunks.forEach((c) => {
    const isSubDriverRef = c.type === FormulaDisplayChunkType.Driver && c.attributeFilters != null;
    if (isSubDriverRef && sourceSubDriver != null) {
      const matchableAttributeFilters = Object.entries(c.attributeFilters?.byDimId ?? {}).reduce(
        (res, [dimId, attrList]) => {
          // Ignore any filters that match multiple attributes
          if (attrList.length === 1 && isAttribute(attrList[0])) {
            res[dimId] = attrList[0];
          }
          return res;
        },
        {} as Record<DimensionId, Attribute>,
      );
      const attrIdsByDimIds = getAttrIdsByDimId(sourceSubDriver.attributes ?? []);
      const dimDriverRef = driversById[c.driverId];
      const subDriverRef =
        dimDriverRef != null ? getSingleSubdriver(dimDriverRef, c.attributeFilters) : null;
      const matchingDimensions = Object.entries(matchableAttributeFilters)
        .filter(([dimId, attr]) => {
          return attr.id === attrIdsByDimIds[dimId];
        })
        .map(([dimId]) => dimId);
      if (subDriverRef != null) {
        parameterizedTerms.push({
          type: 'driver',
          driverId: subDriverRef.driverId,
          dimDriverId: c.driverId,
          dimensionIds: matchingDimensions,
        });
      }
    }

    if (c.type === FormulaDisplayChunkType.ExtDriver) {
      const extDriver = extDriversById[c.id];
      parameterizedTerms.push({
        type: 'extDriver',
        extDriverId: c.id,
        source: extDriver.source,
        model: extDriver.model,
      });
    }

    if (
      c.type === FormulaDisplayChunkType.Object &&
      sourceFieldSpecId != null &&
      c.field?.fieldId === sourceFieldSpecId
    ) {
      parameterizedTerms.push({
        type: 'objectFieldSpec',
        fieldSpecId: sourceFieldSpecId,
        isThisRef: c.isThisRef,
      });
    }
  });
  return parameterizedTerms;
};

export const transformFormulaForNewDimensions = ({
  origFormula,
  sourceEntityId,
  sourceAttrs,
  objectFilterAttrs,
  parameterizedTerms,
  destEntityId,
  destAttrs,
  driversById,
  relatedBusinessObject,
  relatedBusinessObjectSpec,
  dimensionalPropertyEvaluator,
  targetFormulaDisplay,
  driverIsBeingNewlyCreated,
}: {
  origFormula: string;
  sourceEntityId: FormulaEntityTypedId;
  sourceAttrs: Record<DimensionId, AttributeId>;
  objectFilterAttrs: Attribute[];
  parameterizedTerms: ParameterizedFormulaTerm[];
  destEntityId: FormulaEntityTypedId;
  destAttrs: Record<DimensionId, AttributeId>;
  driversById: NullableRecord<string, Driver | undefined>;
  relatedBusinessObject: BusinessObject | null;
  relatedBusinessObjectSpec: BusinessObjectSpec | null;
  dimensionalPropertyEvaluator: DimensionalPropertyEvaluator;
  targetFormulaDisplay: FormulaDisplay | null;
  driverIsBeingNewlyCreated: boolean;
}) => {
  // Extend destAttrs with attributes that can be inherited from a related business object
  const keyAttributeByDimPropertyId: Record<DimensionalPropertyId, AttributeId> = {};
  if (relatedBusinessObject != null && relatedBusinessObjectSpec != null) {
    (relatedBusinessObjectSpec.collection?.dimensionalProperties ?? []).forEach((dimProp) => {
      const computedAttributeProperty = dimensionalPropertyEvaluator.getAttributeProperty({
        objectId: relatedBusinessObject.id,
        dimensionalPropertyId: dimProp.id,
      });
      if (computedAttributeProperty != null) {
        destAttrs[dimProp.dimension.id] = computedAttributeProperty.attribute.id;
        keyAttributeByDimPropertyId[dimProp.id] = computedAttributeProperty.attribute.id;
      }
    });
  }

  let destFormula = Object.entries(sourceAttrs).reduce(
    (formula, [dimId, srcAttrId]) => {
      const destAttrId = destAttrs[dimId];
      return replaceAttrFilters(formula, dimId, srcAttrId, destAttrId);
    },
    parameterizedTerms.length > 0
      ? origFormula
      : sourceEntityId.type === destEntityId.type
        ? origFormula.replaceAll(sourceEntityId.id, destEntityId.id)
        : origFormula,
  );

  destFormula = objectFilterAttrs.reduce((formula, attribute) => {
    const destAttrId = destAttrs[attribute.dimensionId];
    return replaceAttrFilters(formula, attribute.dimensionId, attribute.id, destAttrId);
  }, destFormula);

  const sourceDriverId = sourceEntityId.type === 'driver' ? sourceEntityId.id : null;
  const destDriverId = destEntityId.type === 'driver' ? destEntityId.id : null;

  destFormula = parameterizedTerms.reduce((formula, parameterizedTerm) => {
    if (parameterizedTerm.type === 'extDriver') {
      if (formula.includes('extDriver')) {
        const { extDriverId, source } = parameterizedTerm;
        targetFormulaDisplay?.chunks.forEach((c) => {
          if (c.type !== FormulaDisplayChunkType.ExtDriver) {
            return;
          }
          if (c.source === source) {
            formula = formula.replaceAll(extDriverId, c.id);
          }
        });
      }
    } else if (parameterizedTerm.type === 'driver') {
      const formulaTermSourceDriverId = parameterizedTerm.driverId;
      const { dimDriverId, dimensionIds } = parameterizedTerm;
      const dimDriver = driversById[dimDriverId];
      if (dimDriver?.type !== DriverType.Dimensional) {
        return formula;
      }
      const formulaTermSourceSubDriver = dimDriver.subdrivers.find(
        (s) => s.driverId === formulaTermSourceDriverId,
      );
      if (formulaTermSourceSubDriver == null) {
        return formula;
      }

      // First try a perfect match with the parameterized dimensions replaced by the target.
      // Then, try to find a subdriver with only the parameterized dimensions, ignoring any unmatched dimensions.
      // Then, try a fuzzy match against drivers that may have more than the given dimensions.
      const matchingDimIds = dimensionIds.filter((dId) => destAttrs[dId] != null);
      const matchingAttrsByDimId = matchingDimIds.reduce(
        (agg: Record<DimensionId, AttributeId>, dId) => {
          const attrId = destAttrs[dId];
          if (attrId == null) {
            return agg;
          }
          agg[dId] = attrId;
          return agg;
        },
        {},
      );
      const nonMatchingAttrsByDimId = formulaTermSourceSubDriver.attributes.reduce(
        (agg: Record<DimensionId, AttributeId>, attr) => {
          const dId = attr.dimensionId;
          if (matchingAttrsByDimId[dId] != null) {
            return agg;
          }
          agg[dId] = attr.id;
          return agg;
        },
        {},
      );
      const matchingAttrIds = Object.values(matchingAttrsByDimId);
      const nonMatchingAttrIds = Object.values(nonMatchingAttrsByDimId);

      const destSubDriverId =
        dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(dimDriver.id, [
          ...matchingAttrIds,
          ...nonMatchingAttrIds,
        ]) ??
        dimensionalPropertyEvaluator.getSubDriverIdForAttributeIds(dimDriver.id, matchingAttrIds) ??
        getFuzzySubDriverIdForDriverAndAttributeIds(dimDriver, matchingAttrIds);
      if (destSubDriverId != null) {
        return formula.replaceAll(formulaTermSourceDriverId, destSubDriverId);
      }

      // If the driver hasn't been created yet, `destSubDriverId` will be null above. If there is a self reference in
      // it is safe to replace it with the provided `destDriverId` (which will be created afterwards).
      if (
        driverIsBeingNewlyCreated &&
        sourceDriverId != null &&
        formula.includes(sourceDriverId) &&
        destDriverId != null
      ) {
        return formula.replaceAll(sourceDriverId, destDriverId);
      }

      // Otherwise, replace the term with a filter on the dim driver
      const attrFilters = [matchingAttrsByDimId, nonMatchingAttrsByDimId]
        .map((attrsByDimId) =>
          Object.entries(attrsByDimId)
            .map(([dimId, attrId]) => `${dimId}:${attrId}`)
            .join(' '),
        )
        .join(' ');

      return replaceBasicDriverWithDimDriver(
        formula,
        formulaTermSourceDriverId,
        dimDriver.id,
        attrFilters,
        true,
      );
    } else if (
      // We are only handling copy from field formula and paste to driver here.
      // We haven't implemented copy/paste between fields because fields are slated to be deprecated.
      parameterizedTerm.type === 'objectFieldSpec' &&
      parameterizedTerm.isThisRef === true &&
      destDriverId != null
    ) {
      const formulaTermSourceFieldSpecId = parameterizedTerm.fieldSpecId;
      return replaceThisKeywordWithDriver(formula, formulaTermSourceFieldSpecId, destDriverId);
    }
    return formula;
  }, destFormula);

  // If we are pasting into a driver and the formula contains a `this` reference, replace it with the filtered object spec.
  if (
    destDriverId != null &&
    destFormula.includes('this') &&
    relatedBusinessObjectSpec != null &&
    relatedBusinessObject != null
  ) {
    destFormula = replaceThisKeywordWithFiteredObjectSpec(
      destFormula,
      relatedBusinessObjectSpec.id,
      keyAttributeByDimPropertyId,
    );
  }

  return destFormula;
};

const getFuzzySubDriverIdForDriverAndAttributeIds = (
  dimDriver: DimensionalDriver | undefined,
  attributeIds: AttributeId[],
) => {
  if (dimDriver == null) {
    return undefined;
  }

  const match = dimDriver.subdrivers.find((sub) => {
    const attrIds = new Set(sub.attributes.map((attr) => attr.id));
    return attributeIds.every((id) => attrIds.has(id));
  });

  return match?.driverId;
};

/**
 * Given a formula and an index, find the substring up to the end of the first matching parenthesis pair.
 *
 * E.g. given the formula `sum(driver(0fbbbbe9-d0cd-4ea0-9ed1-a09a955b8dfc, relative(0,0)))`, the index 4,
 * the substring would be `driver(0fbbbbe9-d0cd-4ea0-9ed1-a09a955b8dfc, relative(0,0))`.
 */
export const getMatchingParenSubstring = (formula: string, startIndex: number) => {
  let count = 0;
  let firstParenFound = false;
  for (let i = startIndex; i < formula.length; i++) {
    if (formula[i] === '(') {
      count++;
      firstParenFound = true;
    } else if (formula[i] === ')') {
      count--;
    }
    if (firstParenFound && count === 0) {
      return {
        substring: formula.substring(startIndex, i + 1),
        startIndex,
        endIndex: i,
      };
    }
  }
  return null;
};

/**
 * Given the start of an expression of an entity, replace the entity with the given replace function.
 * Usage:
 * - to replace all drivers, partialMatchRegex should be `driver\\(`
 * - to replace all dimDrivers, partialMatchRegex should be `dimDriver\\(`
 * - to replace a specific driver, partialMatchRegex should be `driver\\(${driverId}`
 * - etc.
 *
 * The argument to the replaceFunction is the substring of the formula that starts with the partialMatchRegex and ends
 * with the matching closing parenthesis.
 */
export const replaceEntity = (
  formula: string,
  partialMatchRegex: RegExp,
  replaceFunction: (substring: string) => string,
): string => {
  const matches = [...formula.matchAll(partialMatchRegex)];
  if (matches.length === 0) {
    return formula;
  }
  // split the string into matching and non-matching parts
  const matchingAndNonMatchingParts: Array<{ substring: string; isMatching: boolean }> = [];

  const matchingIndices = matches.map((match) => match.index);

  let lastEndIndex = -1;
  for (const matchingIndex of matchingIndices) {
    // add the non-matching part before the current matching part
    matchingAndNonMatchingParts.push({
      substring: formula.substring(lastEndIndex + 1, matchingIndex),
      isMatching: false,
    });
    // add the matching part
    const matchingParenSubstring = getMatchingParenSubstring(formula, matchingIndex);
    if (matchingParenSubstring == null) {
      // This shouldn't happen
      return formula;
    }
    matchingAndNonMatchingParts.push({
      substring: matchingParenSubstring.substring,
      isMatching: true,
    });
    lastEndIndex = matchingParenSubstring.endIndex;
  }
  // Add the remaining non-matching part
  matchingAndNonMatchingParts.push({
    substring: formula.substring(lastEndIndex + 1),
    isMatching: false,
  });

  // do string replace for the matching parts
  const partsWithReplacements = matchingAndNonMatchingParts.map((part) => {
    if (!part.isMatching) {
      return part.substring;
    }
    return replaceFunction(part.substring);
  });

  return partsWithReplacements.join('');
};

export const replaceBasicDriverWithDimDriver = (
  formula: string,
  sourceBasicDriverId: string,
  destDimDriverId: string,
  attrFilters: string,
  includeSum = false,
): string => {
  const driverRegexPattern = `driver\\(${sourceBasicDriverId}`;
  const re = new RegExp(driverRegexPattern, 'gi');

  const replaceFunction = (substring: string) => {
    const updatedPart = `filter(${substring.replace(re, `dimDriver(${destDimDriverId}`)}, ${attrFilters})`;
    if (includeSum) {
      return `sum(${updatedPart})`;
    }
    return updatedPart;
  };

  return replaceEntity(formula, re, replaceFunction);
};

export const replaceBasicDriverId = (
  formula: string,
  sourceDriverId: DriverId,
  destDriverId: DriverId,
) => {
  return formula.replaceAll(sourceDriverId, destDriverId);
};

export const replaceDestAttrFilters = (
  formula: string,
  dimId: DimensionId,
  destAttrId: AttributeId | null,
) => {
  if (destAttrId == null) {
    return formula;
  }
  if (dimId === BuiltInDimensionType.CalendarTime || dimId === BuiltInDimensionType.RelativeTime) {
    return formula;
  }
  const attrFilterRegex = new RegExp(`${dimId}:[a-z0-9-]+`, 'gi');
  return formula.replace(attrFilterRegex, `${dimId}:${destAttrId}`);
};

const replaceAttrFilters = (
  formula: string,
  dimId: DimensionId,
  srcAttrId: AttributeId,
  destAttrId: AttributeId | null,
) => {
  if (destAttrId == null) {
    return formula;
  }

  if (dimId === BuiltInDimensionType.CalendarTime) {
    return formula.replaceAll(`RELATIVE:${srcAttrId}`, `RELATIVE:${destAttrId}`);
  }
  if (dimId === BuiltInDimensionType.RelativeTime) {
    return formula.replaceAll(`CALENDAR:${srcAttrId}`, `CALENDAR:${destAttrId}`);
  }

  return formula.replaceAll(srcAttrId, destAttrId);
};

export const transformFormulaForExpandedDimension = ({
  state,
  isForecast,
  sourceDriverId,
  sourceAttrIds,
  destDriverId,
  destAttrs,
  driversById,
  expandedDimensionId,
}: {
  state: RootState;
  sourceDriverId: DriverId;
  isForecast: boolean;
  sourceAttrIds: Record<DimensionId, AttributeId>;
  destDriverId: DriverId;
  destAttrs: Record<DimensionId, Attribute>;
  driversById: NullableRecord<string, Driver | undefined>;
  expandedDimensionId: DimensionId;
}) => {
  const origFormula = isForecast
    ? driverForecastFormulaSelector(state, { id: sourceDriverId })
    : driverActualsFormulaSelector(state, {
        id: sourceDriverId,
      });
  if (origFormula == null) {
    return null;
  }
  const formulaDisplay = isForecast
    ? driverForecastFormulaDisplaySelector(state, { id: sourceDriverId })
    : driverActualsFormulaDisplaySelector(state, { id: sourceDriverId });

  const destExpandedAttr = destAttrs[expandedDimensionId];
  const srcExpandedAttrId = sourceAttrIds[expandedDimensionId];

  let destFormula = origFormula.replaceAll(sourceDriverId, destDriverId);
  destFormula = replaceAttrFilters(
    destFormula,
    expandedDimensionId,
    srcExpandedAttrId,
    destExpandedAttr.id,
  );

  destFormula =
    formulaDisplay?.chunks.reduce((formula, c) => {
      if (c.type !== FormulaDisplayChunkType.Driver || c.attributeFilters == null) {
        return formula;
      }

      const dimDriverRef = driversById[c.driverId];
      const subDriverRef =
        dimDriverRef != null ? getSingleSubdriver(dimDriverRef, c.attributeFilters) : null;
      if (dimDriverRef == null || subDriverRef == null) {
        return formula;
      }

      const destFilters: AttributeFilters = {
        includeAllContextAttributes: c.attributeFilters.includeAllContextAttributes,
        matchToSingleResult: c.attributeFilters.matchToSingleResult,
        byDimId: Object.fromEntries(
          Object.entries(c.attributeFilters.byDimId).map(([dimId, filter]) => {
            if (
              filter.length === 1 &&
              isAttribute(filter[0]) &&
              filter[0].id === srcExpandedAttrId
            ) {
              return [dimId, [destAttrs[expandedDimensionId]]];
            }
            return [dimId, filter];
          }),
        ),
      };

      const destSubDriverRef = getSingleSubdriver(dimDriverRef, destFilters);
      if (destSubDriverRef == null) {
        return formula;
      }
      return formula.replaceAll(subDriverRef.driverId, destSubDriverRef.driverId);
    }, destFormula) ?? destFormula;
  return destFormula;
};
