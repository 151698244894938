import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'StripeIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <g clipPath="url(#clip0_50_682)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M129.683 15.8813L129.116 13.1925H121.249V45.075H130.339V23.4375C132.489 20.625 136.117 21.1725 137.272 21.5307V13.1963C136.061 12.7613 131.811 11.9625 129.683 15.8775V15.8813ZM111.439 5.29503L102.559 7.18128L102.521 36.36C102.521 41.7413 106.567 45.7219 111.967 45.7219C114.939 45.7219 117.135 45.1557 118.352 44.5163V37.11C117.186 37.575 111.446 39.2438 111.446 33.885V20.9475H118.352V13.1907H111.446L111.439 5.29503ZM86.8125 22.4438C86.8125 21.0225 87.9938 20.475 89.9025 20.475C93.0622 20.5434 96.1626 21.3458 98.9587 22.8188V14.25C96.0756 13.1164 93.0004 12.551 89.9025 12.585C82.545 12.585 77.6081 16.44 77.6081 22.875C77.6081 32.9438 91.4362 31.3088 91.4362 35.6494C91.4362 37.3482 89.9737 37.8732 87.9431 37.8732C84.9319 37.8732 81.0431 36.6282 77.9925 34.9669V43.6482C81.1329 45.0141 84.5185 45.7267 87.9431 45.7425C95.505 45.7425 100.717 42.0075 100.717 35.4582C100.717 24.5907 86.8106 26.5332 86.8106 22.44L86.8125 22.4438ZM26.025 17.8407C26.025 15.2719 28.1625 14.2782 31.6162 14.2782C37.3324 14.4016 42.9414 15.853 48 18.5175V3.01128C42.7838 0.961364 37.2204 -0.0605193 31.6162 0.00190308C18.3037 0.00190308 9.375 6.97503 9.375 18.6188C9.375 36.8363 34.3912 33.8775 34.3912 41.73C34.3912 44.8013 31.7663 45.7538 28.0725 45.7538C22.6256 45.7538 15.5906 43.5 10.0725 40.5V56.2088C15.7538 58.6808 21.8787 59.9707 28.0744 60C41.7562 60 51.1875 53.2407 51.1875 41.3944C51.1875 21.7332 26.025 25.245 26.025 17.8444V17.8407Z"
          fill="#6772E5"
        />
      </g>
      <defs>
        <clipPath id="clip0_50_682">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
