import { Flex } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import DriverGridAddRowButton from 'components/DriverGridAddRowButton/DriverGridAddRowButton';
import ReorderMarker from 'components/ReorderMarker/ReorderMarker';
import { CELL_HEIGHT_IN_PX_STR, CellSelectionStateContext } from 'config/cells';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellRef } from 'hooks/useCellRefContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import { useAddDriverCellRef } from 'hooks/useDriverCellRef';
import useDriverDND from 'hooks/useDriverDND';
import { selectCell } from 'reduxStore/actions/cellNavigation';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { numSelectedDriversSelector } from 'selectors/prevailingCellSelectionSelector';

interface Props {
  isAdding: boolean;
  onOpenDriverSelect: () => void;
  belowDriverId?: DriverId;
  groupId?: DriverGroupId;
}

const CreateDriverRow: React.FC<Props> = ({
  belowDriverId,
  groupId,
  onOpenDriverSelect,
  isAdding,
}) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const numSelectedDriverIds = useAppSelector(numSelectedDriversSelector);
  const { cellRef } = useCellRef();
  const cellSelectionState = useCellSelectionStateContext();
  const displayAsActive = cellSelectionState.isHighlighted && numSelectedDriverIds === 0;
  const newCellSelectionState = useMemo(() => {
    return {
      ...cellSelectionState,
      isHighlighted: displayAsActive,
      isActive: displayAsActive,
      isSelected: displayAsActive,
    };
  }, [cellSelectionState, displayAsActive]);
  const [isOver, dropRef] = useDriverDND(groupId, 'end');

  const bgColor = displayAsActive ? 'selection.100' : 'surface';

  const onClickCell = useCallback(() => {
    dispatch(selectCell(blockId, cellRef));
  }, [cellRef, blockId, dispatch]);

  return (
    <CellSelectionStateContext.Provider value={newCellSelectionState}>
      <Flex height={CELL_HEIGHT_IN_PX_STR}>
        <Flex position="sticky" left={0} bgColor="surface" zIndex="docked">
          <Flex position="relative" bgColor={bgColor} ref={dropRef} onClick={onClickCell}>
            {isOver && <ReorderMarker position="top" />}
            <DriverGridAddRowButton
              groupId={groupId}
              belowDriverId={belowDriverId}
              isAdding={isAdding}
              onOpenDriverSelect={onOpenDriverSelect}
            />
          </Flex>
        </Flex>
      </Flex>
    </CellSelectionStateContext.Provider>
  );
};

const CreateDriverRowWrapper: React.FC<Props> = ({
  belowDriverId,
  groupId,
  isAdding,
  onOpenDriverSelect,
}) => {
  const createDriverRef = useAddDriverCellRef({ groupId });
  return (
    <CellContextProvider cellRef={createDriverRef}>
      <CreateDriverRow
        belowDriverId={belowDriverId}
        groupId={groupId}
        isAdding={isAdding}
        onOpenDriverSelect={onOpenDriverSelect}
      />
    </CellContextProvider>
  );
};

export default React.memo(CreateDriverRowWrapper);
