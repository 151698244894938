import { deepEqual } from 'fast-equals';

import useAppSelector from 'hooks/useAppSelector';
import { LayerId } from 'reduxStore/models/layers';
import { driverNameSelector } from 'selectors/driversSelector';

export const useDriverName = ({
  driverId,
  baselineLayerId,
  shouldShowDriverDiffs,
  comparisonLayerIds,
}: {
  driverId: string;
  baselineLayerId: string;
  comparisonLayerIds: string[];
  shouldShowDriverDiffs: boolean;
}) => {
  const [baselineDriverName, updatedDriverNamesByLayerId] = useAppSelector<
    [string, Record<LayerId, string>]
  >((state) => {
    if (!shouldShowDriverDiffs) {
      const currentLayerDriverName = driverNameSelector(state, { id: driverId });
      return [currentLayerDriverName ?? '', {}];
    }

    const baselineLayerDriverName = driverNameSelector(state, {
      id: driverId,
      layerId: baselineLayerId,
    });

    if (baselineLayerDriverName != null) {
      // The driver exists in the baseline layer - check if its name was
      // changed in other layers.
      const changedNames: Record<LayerId, string> = {};
      comparisonLayerIds.forEach((lId) => {
        const name = driverNameSelector(state, { id: driverId, layerId: lId });
        if (name != null && name !== baselineLayerDriverName) {
          changedNames[lId] = name;
        }
      });
      return [baselineLayerDriverName, changedNames];
    }

    // The driver does not exist in the baseline layer. This means it was
    // created in another layer. Use the name of the driver in that layer.
    for (const lId of comparisonLayerIds) {
      const name = driverNameSelector(state, { id: driverId, layerId: lId });
      if (name != null) {
        return [name, {}];
      }
    }

    return ['', {}];
  }, deepEqual);

  return { baselineDriverName, updatedDriverNamesByLayerId };
};
