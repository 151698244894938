import { useTimelineHoverState } from 'config/hoveringEventOrGroupRef';
import useAppSelector from 'hooks/useAppSelector';
import { PlanTimelineItemRef, isPlanTimelineItemRefEqual } from 'reduxStore/models/events';
import { allRefsForGroupSelector } from 'selectors/eventsAndGroupsSelector';
import { allDescendentRefsOfSelectedEventsAndGroupsSelector } from 'selectors/selectedEventSelector';

const useAncestorState = (ref: PlanTimelineItemRef) => {
  const [hoveredRef, _setHoveredRef] = useTimelineHoverState();
  const hoveredGroup = hoveredRef?.type === 'group' ? hoveredRef : undefined;
  const allDescendentRefsOfHovered = useAppSelector((state) =>
    hoveredGroup != null ? allRefsForGroupSelector(state, hoveredGroup.id) : undefined,
  );

  const allDescendentRefsOfSelected = useAppSelector(
    allDescendentRefsOfSelectedEventsAndGroupsSelector,
  );

  const isAncestorSelected = allDescendentRefsOfSelected.some((el) =>
    isPlanTimelineItemRefEqual(ref, el),
  );
  const isAncestorHovered =
    allDescendentRefsOfHovered?.some((decendentRef) =>
      isPlanTimelineItemRefEqual(ref, decendentRef),
    ) ?? false;

  return {
    isAncestorSelected,
    isAncestorHovered,
  };
};

export default useAncestorState;
