import React from 'react';

import FormulaEntityChunk from 'components/Formula/FormulaEntityChunk';
import { SubmodelFormulaDisplayChunk } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';

interface Props {
  chunk: SubmodelFormulaDisplayChunk;
}

const SubmodelChunk: React.FC<Props> = ({ chunk }) => {
  const { displayName, timeRange, timeRangeDisplay } = chunk;

  return (
    <FormulaEntityChunk
      label={displayName}
      timeRange={timeRange}
      timeRangeDisplay={timeRangeDisplay}
    />
  );
};

export default SubmodelChunk;
