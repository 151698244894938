import { TippyProps } from '@tippyjs/react';
import noop from 'lodash/noop';
import { createContext } from 'react';
import { createStateContext } from 'react-use';

import { DriverGroupDragItem } from 'components/DriverGroupHeaderRows/DriverGroupNameHeaderRow';
import { DriverDragItem } from 'components/DriverNameTableCell/DriverNameTableCell';
import { ReorderFunc } from 'hooks/useReorderable';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';

export interface DriverGridContextValue {
  onReorder?: ReorderFunc<DriverDragItem>;
  onDropGroup?: ReorderFunc<DriverGroupDragItem>;
  onDropOnGroup?: (
    driverId: DriverId,
    groupId: DriverGroupId | undefined,
    position: 'start' | 'end',
  ) => void;
  canReorderGroups?: boolean;
  canEditGroups: boolean;
  hasCreateButton: boolean;
  hasDimensionsButton: boolean;
  canDelete: boolean;
  canCreateDriverGroup: boolean;
}

export const DriverGridContext = createContext<DriverGridContextValue>({
  onReorder: noop,
  canEditGroups: false,
  hasCreateButton: false,
  hasDimensionsButton: true,
  canDelete: true,
  canCreateDriverGroup: false,
});

export const [useAddDriversPopoverContext, AddDriversPopoverContextProvider] = createStateContext<{
  groupId?: DriverGroupId;
  belowDriverId?: DriverId;
  reference: TippyProps['reference'] | null;
  placement?: TippyProps['placement'];
  offset?: TippyProps['offset'];
  canFlip?: boolean;
  appendToParent?: boolean;
}>({
  reference: null,
});
