import { Client, createClient } from 'graphql-ws';

import { graphqlWSUrl } from 'config/graphql/query';
import { DISCONNECTED_EVENT_NAME, eventEmitter } from 'helpers/appEvents';

let client: Client | null = null;

// copied from
// https://github.com/enisdenjo/graphql-ws/blob/1f2ad89899eaa6ce4d4d98615ea583870e765342/src/client.ts#L476-L489
// added a max delay
async function randomisedExponentialBackoff(retries: number) {
  let retryDelay = 1000; // start with 1s delay
  for (let i = 0; i < retries; i++) {
    retryDelay *= 2;
  }
  const MAX_DELAY = 120 * 1000; /* 2 minutes */
  await new Promise((resolve) =>
    setTimeout(
      resolve,
      Math.min(retryDelay, MAX_DELAY) +
        // add random timeout from 300ms to 3s
        Math.floor(Math.random() * (3000 - 300) + 300),
    ),
  );
}

export function getWsClient() {
  if (client != null) {
    return client;
  }
  client = createClient({
    url: graphqlWSUrl,
    shouldRetry: () => {
      return true;
    },
    retryAttempts: Infinity,
    retryWait: randomisedExponentialBackoff,
  });

  client.on('closed', () => {
    eventEmitter.emit(DISCONNECTED_EVENT_NAME);
  });
  return client;
}
