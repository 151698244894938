import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AttributePlaceholder } from 'config/dimDriverEditContext';
import { isValidMonthKey, nextMonthKey } from 'helpers/dates';
import { inverseRecord } from 'helpers/record';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DimensionalPropertyId } from 'reduxStore/models/collections';
import { Attribute } from 'reduxStore/models/dimensions';
import { closeModal } from 'reduxStore/reducers/pageSlice';
import { MonthKey } from 'types/datetime';

export enum PasteType {
  Driver = 'driver',
  Object = 'object',
}

type CommonPasteModalState = {
  pasteData: string[][] | undefined;
  rowIndicesToSkip: number[];
};

export type PasteDriverColumn = 'name' | MonthKey;

export type DriverPasteModalState = CommonPasteModalState & {
  type: PasteType.Driver;
  driverColumnMap: Record<number, PasteDriverColumn>;
  showDimensionsColumn: boolean;
  attributesByRowIdx: Record<number, Array<Attribute | AttributePlaceholder>>;
};

export type PasteObjectColumn =
  | { type: 'name'; value: 'name' }
  | { type: 'month'; value: MonthKey }
  | { type: 'fieldSpec'; value: BusinessObjectFieldSpecId }
  | { type: 'dimensionalProperty'; value: DimensionalPropertyId };

export function getPasteObjectColumnKey(column: PasteObjectColumn) {
  return `${column.type}:${column.value}`;
}

export type ObjectPasteModalState = CommonPasteModalState & {
  type: PasteType.Object;
  objectColumnMap: Record<number, PasteObjectColumn>;
  timeSeriesFieldId?: BusinessObjectFieldSpecId;
};

export type PasteModalState = DriverPasteModalState | ObjectPasteModalState | null;

const getNameColIdx = (state: PasteModalState) => {
  if (isDriverPasteModal(state)) {
    const driverColumnToIdxMap: Record<PasteDriverColumn, number> = inverseRecord(
      state.driverColumnMap,
    );
    return driverColumnToIdxMap.name;
  } else if (isObjectPasteModal(state)) {
    const colIdxStr = Object.entries(state.objectColumnMap).find(
      ([_colIdx, col]) => col.type === 'name',
    )?.[0];

    if (colIdxStr == null) {
      return undefined;
    }

    return parseInt(colIdxStr);
  }
  return undefined;
};

export const isDriverPasteModal = (state: PasteModalState): state is DriverPasteModalState =>
  state?.type === PasteType.Driver;

export const isObjectPasteModal = (state: PasteModalState): state is ObjectPasteModalState =>
  state?.type === PasteType.Object;

const pasteModalSlice = createSlice({
  name: 'pasteModal',
  initialState: null as PasteModalState,
  reducers: {
    setPasteDriverColumnForColumnIdx(
      state,
      action: PayloadAction<{ columnIdx: number; column?: PasteDriverColumn }>,
    ) {
      if (state == null || !isDriverPasteModal(state)) {
        return;
      }
      const { columnIdx, column } = action.payload;

      const existingValue = Object.entries(state.driverColumnMap).find(
        ([_unused, v]) => v === column,
      );
      if (column == null) {
        delete state.driverColumnMap[columnIdx];
      } else {
        if (existingValue != null) {
          delete state.driverColumnMap[parseInt(existingValue[0])];
        }
        state.driverColumnMap = { ...state.driverColumnMap, [columnIdx]: column };
      }
    },
    setStartMonthColumnIdx(
      state,
      action: PayloadAction<{ columnIdx: number; monthKey: MonthKey }>,
    ) {
      if (state?.pasteData == null) {
        return;
      }
      const { columnIdx, monthKey } = action.payload;
      const columnCount = state.pasteData[0].length;
      if (isDriverPasteModal(state)) {
        const prevValue = state.driverColumnMap[columnIdx];
        state.driverColumnMap = { ...state.driverColumnMap, [columnIdx]: monthKey };
        let currMonthKey = monthKey;
        let expectedPrevValue =
          prevValue != null && isValidMonthKey(prevValue) ? prevValue : undefined;
        for (let i = columnIdx + 1; i < columnCount; i++) {
          expectedPrevValue =
            expectedPrevValue != null ? nextMonthKey(expectedPrevValue) : undefined;
          currMonthKey = nextMonthKey(currMonthKey);
          // if next column has been modified do not update its value
          if (state.driverColumnMap[i] != null && state.driverColumnMap[i] !== expectedPrevValue) {
            break;
          }
          state.driverColumnMap = { ...state.driverColumnMap, [i]: currMonthKey };
        }
      } else if (isObjectPasteModal(state)) {
        const prevValue = state.objectColumnMap[columnIdx];
        state.timeSeriesFieldId =
          state.timeSeriesFieldId ??
          (prevValue?.type === 'fieldSpec' ? prevValue.value : undefined);
        state.objectColumnMap = {
          ...state.objectColumnMap,
          [columnIdx]: { type: 'month', value: monthKey },
        };
        let currMonthKey = monthKey;
        let expectedPrevValue =
          prevValue != null && isValidMonthKey(prevValue.value) ? prevValue : undefined;
        for (let i = columnIdx + 1; i < columnCount; i++) {
          expectedPrevValue =
            expectedPrevValue != null
              ? { type: 'month', value: nextMonthKey(expectedPrevValue.value) }
              : undefined;
          currMonthKey = nextMonthKey(currMonthKey);
          // if next column has been modified do not update its value
          if (state.objectColumnMap[i] != null && state.objectColumnMap[i] !== expectedPrevValue) {
            break;
          }
          state.objectColumnMap = {
            ...state.objectColumnMap,
            [i]: {
              type: 'month',
              value: currMonthKey,
            },
          };
        }
      }
    },
    setPasteObjectColumnForColumnIdx(
      state,
      action: PayloadAction<{ columnIdx: number; column?: PasteObjectColumn }>,
    ) {
      if (state == null || !isObjectPasteModal(state)) {
        return;
      }
      const { columnIdx, column } = action.payload;

      const existingValue = Object.entries(state.objectColumnMap).find(
        ([_unused, v]) => v === column,
      );
      if (column == null) {
        delete state.objectColumnMap[columnIdx];
      } else {
        if (existingValue != null) {
          delete state.objectColumnMap[parseInt(existingValue[0])];
        }
        state.objectColumnMap = { ...state.objectColumnMap, [columnIdx]: column };
      }
    },
    setObjectTimeSeriesField(state, action: PayloadAction<BusinessObjectFieldSpecId>) {
      if (state == null || !isObjectPasteModal(state)) {
        return;
      }
      state.timeSeriesFieldId = action.payload;
    },
    skipRow(state, action: PayloadAction<number>) {
      if (state == null) {
        return;
      }
      const rowIdx = action.payload;
      state.rowIndicesToSkip = [...state.rowIndicesToSkip.filter((i) => i !== rowIdx), rowIdx];
    },
    includeRow(state, action: PayloadAction<number>) {
      if (state == null) {
        return;
      }
      const rowIdx = action.payload;
      state.rowIndicesToSkip = state.rowIndicesToSkip.filter((i) => i !== rowIdx);
    },
    showDimensionsColumn(state) {
      if (state == null || !isDriverPasteModal(state)) {
        return;
      }
      state.showDimensionsColumn = true;

      if (state.pasteData == null) {
        state.attributesByRowIdx = {};
        return;
      }

      state.attributesByRowIdx = Object.fromEntries(
        state.pasteData.map((data, rowIdx) => [rowIdx, []]),
      );
    },
    hideDimensionsColumn(state) {
      if (state == null || !isDriverPasteModal(state)) {
        return;
      }
      state.showDimensionsColumn = false;
      state.attributesByRowIdx = {};
    },
    setDraftSubDriverForRowIdx(
      state,
      action: PayloadAction<{
        rowIdx: number;
        attributes: Array<Attribute | AttributePlaceholder>;
      }>,
    ) {
      if (state == null || !isDriverPasteModal(state)) {
        return;
      }
      const { rowIdx, attributes } = action.payload;
      state.attributesByRowIdx[rowIdx] = attributes;
    },
    updatePasteRowName(state, action: PayloadAction<{ rowIdx: number; newName: string }>) {
      if (state?.pasteData == null) {
        return;
      }

      const nameColumnIdx = getNameColIdx(state);
      if (nameColumnIdx == null) {
        return;
      }

      const { rowIdx, newName } = action.payload;
      state.pasteData[rowIdx][nameColumnIdx] = newName;
    },
    updatePasteData(
      state,
      action: PayloadAction<{ rowIdx: number; colIdx: number; newValue: string }>,
    ) {
      if (state?.pasteData == null) {
        return;
      }
      const { rowIdx, colIdx, newValue } = action.payload;
      state.pasteData[rowIdx][colIdx] = newValue;
    },
    setPasteData(state, action: PayloadAction<string[][]>) {
      if (state == null) {
        return;
      }

      const pastedValues = action.payload;
      const isTabularData = pastedValues.length > 0 && pastedValues[0].length > 0;
      if (!isTabularData) {
        return;
      }

      state.pasteData = pastedValues;
      state.rowIndicesToSkip = getRowsToSkip(pastedValues);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeModal, () => {
      return null;
    });
  },
});

const getRowsToSkip = (pasteData: string[][]) => {
  const emptyRows = pasteData
    .map((row, idx) => ({ row, idx }))
    .filter(({ row }) => row.every((cell) => cell.trim() === ''));

  return emptyRows.map(({ idx }) => idx);
};

export const {
  hideDimensionsColumn,
  includeRow,
  setDraftSubDriverForRowIdx,
  setPasteDriverColumnForColumnIdx,
  setPasteObjectColumnForColumnIdx,
  setStartMonthColumnIdx,
  setObjectTimeSeriesField,
  showDimensionsColumn,
  skipRow,
  updatePasteData,
  updatePasteRowName,
  setPasteData,
} = pasteModalSlice.actions;

export default pasteModalSlice.reducer;
