import { Flex, useDisclosure } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

import { Tooltip } from 'chakra/tooltip';
import EditFormulaIcon from 'components/EditFormulaIcon/EditFormulaIcon';
import EditFormulasPopover from 'components/EditFormulasPopover/EditFormulasPopover';
import { stopEventPropagation } from 'helpers/browserEvent';
import { hasFormulaInputOpen } from 'helpers/formula';
import useAppSelector from 'hooks/useAppSelector';
import { DriverId } from 'reduxStore/models/drivers';
import {
  driverActualsFormulaDisplaySelector,
  driverForecastFormulaDisplaySelector,
} from 'selectors/formulaDisplaySelector';

const OFFSET: [number, number] = [-18, 4];

interface Props {
  driverId: DriverId;
}

const FormulaButton: React.FC<Props> = ({ driverId }) => {
  const [showSavedCheckmark, setShowSavedCheckmark] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onCloseCallback = useCallback(() => {
    onClose();
  }, [onClose]);

  const actualsFormulaDisplay = useAppSelector((state) =>
    driverActualsFormulaDisplaySelector(state, { id: driverId }),
  );
  const forecastFormulaDisplay = useAppSelector((state) =>
    driverForecastFormulaDisplaySelector(state, { id: driverId }),
  );
  const actualsFormulaError = actualsFormulaDisplay?.error;
  const forecastFormulaError = forecastFormulaDisplay?.error;
  const savedFormulaError = forecastFormulaError ?? actualsFormulaError;
  const defaultActiveTab =
    actualsFormulaError != null && forecastFormulaError == null ? 'actuals' : 'forecast';

  const onClick: React.MouseEventHandler = useCallback(() => {
    if (!isOpen && !hasFormulaInputOpen()) {
      onOpen();
      setShowSavedCheckmark(false);
    }
  }, [isOpen, onOpen]);

  const isHidden = !isOpen && !showSavedCheckmark && savedFormulaError == null;
  return (
    <Tooltip
      placement="top"
      isDisabled={savedFormulaError == null || isOpen}
      label={savedFormulaError}
    >
      <Flex
        position="relative"
        onClick={stopEventPropagation}
        flex={0}
        visibility={isHidden ? undefined : 'visible'}
      >
        <EditFormulasPopover
          driverId={driverId}
          offset={OFFSET}
          isOpen={isOpen}
          onClose={onCloseCallback}
          defaultActiveTab={defaultActiveTab}
        >
          <EditFormulaIcon
            onClick={onClick}
            showSavedCheckmark={showSavedCheckmark}
            isEditing={isOpen}
            hasSavedError={savedFormulaError != null}
          />
        </EditFormulasPopover>
      </Flex>
    </Tooltip>
  );
};

export default FormulaButton;
