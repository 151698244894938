import { Flex } from '@chakra-ui/react';
import { DateTime, MonthNumbers } from 'luxon';
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

import { DatePickerContext, DatePickerReactContext } from 'config/datePickerContext';
interface DatePickerPagerState {
  pagedMonth: MonthNumbers;
  pagedYear: number;
  pageBy: 'month' | 'year';
}

type DateChangeAction =
  | { type: 'set'; data: DatePickerPagerState }
  | { type: 'change'; direction: 'increment' }
  | { type: 'change'; direction: 'decrement' };

const dateChangeReducer: React.Reducer<DatePickerPagerState, DateChangeAction> = (
  state,
  action,
) => {
  const { pagedMonth, pagedYear, pageBy } = state;
  if (action.type === 'set') {
    return {
      ...state,
      ...action.data,
    };
  }

  const thisMonth = DateTime.utc(pagedYear, pagedMonth);
  const changeMethod = action.direction === 'increment' ? 'plus' : 'minus';
  const newMonth = thisMonth[changeMethod]({ [pageBy]: 1 });
  return {
    ...state,
    pagedMonth: newMonth.month,
    pagedYear: newMonth.year,
  };
};

interface Props {
  selected: DateTime;
  pageBy: 'month' | 'year';
  onDateSelect: (selected: DateTime) => void;
}

const DatePickerHeader: React.FC<React.PropsWithChildren<Props>> = ({
  selected,
  pageBy,
  children,
  onDateSelect,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [{ pagedMonth, pagedYear }, localDispatch] = useReducer(dateChangeReducer, {
    pagedMonth: selected.month,
    pagedYear: selected.year,
    pageBy,
  });

  useEffect(() => {
    if (selected != null) {
      localDispatch({
        type: 'set',
        data: { pagedMonth: selected.month, pagedYear: selected.year, pageBy },
      });
    }
  }, [pageBy, selected]);

  // This allows you to "Escape" out of the DatePicker input and then "Escape" out of the
  // DatePicker itself.
  const focusOnDatePicker = useCallback(() => {
    containerRef.current?.focus();
  }, []);

  const decrement: React.MouseEventHandler = useCallback((ev) => {
    ev.stopPropagation();
    localDispatch({ type: 'change', direction: 'decrement' });
  }, []);

  const increment: React.MouseEventHandler = useCallback((ev) => {
    ev.stopPropagation();
    localDispatch({ type: 'change', direction: 'increment' });
  }, []);

  const onDateSelectWithMetadata: DatePickerContext['onDateSelect'] = useCallback(
    (newDate, metadata) => {
      if (metadata.reason === 'blur' && !metadata.wasEdited) {
        return;
      }
      onDateSelect(newDate);
    },
    [onDateSelect],
  );

  const contextValue: DatePickerContext = useMemo(() => {
    return {
      decrement,
      increment,
      focusOnDatePicker,
      onDateSelect: onDateSelectWithMetadata,
      pageBy,
      pagedMonth,
      pagedYear,
    };
  }, [
    decrement,
    increment,
    focusOnDatePicker,
    onDateSelectWithMetadata,
    pageBy,
    pagedMonth,
    pagedYear,
  ]);

  return (
    <Flex ref={containerRef} tabIndex={0} w="full" justifyContent="center" py={2}>
      <Flex flexDir="column" gap={1} alignItems="center">
        <DatePickerReactContext.Provider value={contextValue}>
          {children}
        </DatePickerReactContext.Provider>
      </Flex>
    </Flex>
  );
};

export default React.memo(DatePickerHeader);
