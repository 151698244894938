import { Draft } from 'immer';

import { getCurrentState } from 'helpers/immer';

/**
 * structuredClone is very sensitive to proxy objects. It throws when used with proxy objects.
 * Most proxy objects in our app comes from logic within reducers. Use this to be safe.
 */
export const deepClone = <T>(obj: Draft<T> | T): T => {
  const toBeCloned = getCurrentState(obj);
  return structuredClone(toBeCloned);
};
