import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'LinkIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  d: 'M12.5963 3.40372C10.058 0.865426 5.94202 0.865426 3.40372 3.40372C0.865426 5.94202 0.865426 10.058 3.40372 12.5963C5.94202 15.1346 10.058 15.1346 12.5963 12.5963C15.1346 10.058 15.1346 5.94202 12.5963 3.40372ZM10.2981 9.30764C9.99862 9.30764 9.75654 9.06557 9.75654 8.76605L9.75655 7.0095L6.08488 10.6812C5.87346 10.8926 5.53065 10.893 5.31884 10.6812C5.10703 10.4694 5.10741 10.1265 5.31884 9.91512L8.9905 6.24346H7.23395C6.93443 6.24346 6.69236 6.00138 6.69236 5.70186C6.69236 5.40234 6.93443 5.16027 7.23395 5.16027H10.2981C10.4475 5.16027 10.5831 5.22078 10.6812 5.31884C10.7792 5.41689 10.8397 5.55248 10.8397 5.70186V8.76605C10.8397 9.06557 10.5977 9.30764 10.2981 9.30764Z',
});
