import { createContext } from 'react';

import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { LayerId } from 'reduxStore/models/layers';

export const ObjectFieldTimeSeriesRowContext = createContext<{
  businessObjectId: BusinessObjectId;
  layerId: LayerId | undefined;
  fieldSpecId: BusinessObjectFieldSpecId;
}>({
  businessObjectId: '',
  layerId: undefined,
  fieldSpecId: '',
});
