import { BusinessObjectFieldCellRef, CellType, OBJECT_FIELD_NAME_COLUMN_KEY } from 'config/cells';
import { BusinessObjectCreateInput, DatasetMutationInput, ValueType } from 'generated/graphql';
import { convertTimeSeriesToGql } from 'helpers/gqlDataset';
import { mergeMutations } from 'helpers/mergeMutations';
import { getNameWithCopySuffix } from 'helpers/naming';
import { peekMutationStateChange } from 'helpers/sortIndex';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { cloneEventsAndEventGroupFromObject } from 'reduxStore/actions/businessObjectMutations';
import { submitAutoLayerizedMutations } from 'reduxStore/actions/submitDatasetMutation';
import { BusinessObject } from 'reduxStore/models/businessObjects';
import { setSelectedCells } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import {
  businessObjectSelector,
  businessObjectsBySpecIdForLayerSelector,
} from 'selectors/businessObjectsSelector';
import {
  prevailingCellSelectionSelector,
  prevailingSelectedObjectIdsSelector,
} from 'selectors/prevailingCellSelectionSelector';

const getMutationInputForDuplicateBusinessObject = (
  state: RootState,
  objectToClone: BusinessObject,
  name: string,
) => {
  const objectCloneToCreate: BusinessObjectCreateInput = {
    id: uuidv4(),
    name,
    specId: objectToClone.specId,
    fields: objectToClone.fields
      .filter((f) => f.value != null)
      .map((f) => {
        return {
          id: uuidv4(),
          fieldSpecId: f.fieldSpecId,
          value: {
            actuals: {
              formula: f.value?.actuals.formula,
              timeSeries: convertTimeSeriesToGql(f.value?.actuals.timeSeries),
            },
            initialValue: f.value?.initialValue,
            type: f.value?.type ?? ValueType.Number,
          },
        };
      }),
    collectionEntry: {
      attributeProperties: objectToClone.collectionEntry?.attributeProperties.map(
        ({ attribute, dimensionalPropertyId }) => {
          return { attributeId: attribute.id, dimensionalPropertyId };
        },
      ),
    },
  };

  const { mutationsToCreate } = cloneEventsAndEventGroupFromObject({
    state: peekMutationStateChange(state, { newBusinessObjects: [objectCloneToCreate] }),
    clonedObject: objectToClone,
    targetObject: objectCloneToCreate,
  });

  return mergeMutations({ newBusinessObjects: [objectCloneToCreate] }, mutationsToCreate);
};

export const duplicateSelectedBusinessObjects = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedObjectIds = prevailingSelectedObjectIdsSelector(state);
    const cellSelection = prevailingCellSelectionSelector(state);
    if (selectedObjectIds === null || selectedObjectIds.length === 0) {
      return;
    }

    const objectsToClone: BusinessObject[] = selectedObjectIds
      .map((id) => businessObjectSelector(state, id))
      .filter(isNotNull);

    let mutation: DatasetMutationInput = {};
    const addedNames: string[] = [];
    // `mergeMutations` is going to prepend the duplicated business objects to the final
    // mutation array.
    // Short of having a defined sort order in the UI, this causes weird UX where
    //  duplicating [A, B, C] leads to [A, B, C, C Copy, B Copy, A Copy].
    // Thus, we reverse the order of the objs array as a quick workaround.
    objectsToClone.reverse().forEach((businessObject) => {
      const otherBusinessObjectNames = [
        ...businessObjectsBySpecIdForLayerSelector(state)[businessObject.specId].map(
          (obj) => obj.name,
        ),
        ...addedNames,
      ];
      const newName = getNameWithCopySuffix(businessObject.name, otherBusinessObjectNames);
      addedNames.push(newName);
      mutation = mergeMutations(
        getMutationInputForDuplicateBusinessObject(state, businessObject, newName),
        mutation,
      );
    });

    dispatch(submitAutoLayerizedMutations('duplicate-selected-business-objects', [mutation]));

    const activeDriverCell = cellSelection?.activeCell;
    if (cellSelection != null && activeDriverCell?.type === CellType.ObjectField) {
      const selectedCells: BusinessObjectFieldCellRef[] = (mutation.newBusinessObjects ?? []).map(
        (input) => ({
          type: CellType.ObjectField,
          rowKey: {
            ...activeDriverCell.rowKey,
            objectId: input.id,
          },
          columnKey: OBJECT_FIELD_NAME_COLUMN_KEY,
        }),
      );
      dispatch(
        setSelectedCells({
          type: 'cell',
          blockId: cellSelection.blockId,
          activeCell: selectedCells[0],
          selectedCells,
        }),
      );
    }
  };
};
