import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import {
  getObjectSpecIdFromInternalPageType,
  isDatabasePage,
} from 'config/internalPages/databasePage';
import {
  BlockRow,
  BlockType,
  BlocksPageDeleteInput,
  BlocksPageUpdateInput,
  DatasetMutationInput,
  DriverUpdateInput,
} from 'generated/graphql';
import { getBlockCreateInput, removeBlocksFromLayout } from 'helpers/blocks';
import { getISOTimeWithoutMs } from 'helpers/dates';
import { addEmoji, pickRandomEmoji } from 'helpers/emoji';
import { computeSortIndexUpdates } from 'helpers/reorderList';
import { isNotNull } from 'helpers/typescript';
import { createBlockAndUpdateCurrentPageLayoutAndOrdering } from 'reduxStore/actions/blockMutations';
import { renameBusinessObjectSpec } from 'reduxStore/actions/businessObjectSpecMutations';
import {
  blockDeletionDriverReferenceMutations,
  blockDuplicateDriverReferenceMutations,
} from 'reduxStore/actions/driverReferenceMutations';
import { navigateToBlocksPage, navigateToDefaultPage } from 'reduxStore/actions/navigateTo';
import {
  addDefaultLayerGuestControlEntryToPage,
  removeDefaultLayerGuestControlEntryFromPage,
} from 'reduxStore/actions/permissions';
import {
  getMutationThunkAction,
  submitAutoLayerizedMutations,
  submitMutation,
} from 'reduxStore/actions/submitDatasetMutation';
import {
  trackCreateBlocksPageEvent,
  trackDeleteBlocksPageEvent,
} from 'reduxStore/actions/trackEvent';
import { BlockId, BlocksPageId } from 'reduxStore/models/blocks';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { openDeleteObjectSpecDialog } from 'reduxStore/reducers/alertDialogSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import {
  blocksPageSelector,
  blocksPagesByIdSelector,
  userCreatedBlockPagesSelector,
} from 'selectors/blocksPagesSelector';
import {
  blockSelector,
  blocksByIdSelector,
  sortedBlockIdsForPageSelector,
} from 'selectors/blocksSelector';
import { enableAgChartsSelector } from 'selectors/launchDarklySelector';
import { authenticatedUserIdSelector, authenticatedUserSelector } from 'selectors/loginSelector';
import { defaultDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { pageIdSelector } from 'selectors/pageSelector';

const updateBlocksPageNameTransformerFn = (
  { id, name }: BlocksPageUpdateInput,
  getState: () => RootState,
) => {
  const state = getState();
  const blocksPage = blocksPageSelector(state, id);
  if (blocksPage == null || name === '' || blocksPage.name === name) {
    return null;
  }

  return {
    updateBlocksPages: [{ id, name }],
  };
};

const duplicateLayout = (layout: BlockRow[], blockIdMap: Record<BlockId, BlockId>) => {
  return layout.map((row) => ({
    id: uuidv4(),
    columns: row.columns.map((column) => ({
      id: uuidv4(),
      blockIds: column.blockIds.map((blockId) => blockIdMap[blockId]),
    })),
  }));
};

const mutationActions = {
  updateBlocksPageName: getMutationThunkAction<BlocksPageUpdateInput>(
    updateBlocksPageNameTransformerFn,
  ),
  createAndNavigateToNewPage: ({
    name = addEmoji(pickRandomEmoji(null), 'Untitled'),
    parent = null,
  }: { name?: string; parent?: string | null } = {}): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const createdByUserId = authenticatedUserIdSelector(state);
      const pageId = uuidv4();
      dispatch(
        submitAutoLayerizedMutations('create-blocks-page', [
          {
            newBlocksPages: [
              {
                id: pageId,
                name,
                createdByUserId,
                ...(parent !== null ? { parent } : null),
              },
            ],
          },
        ]),
      );
      dispatch(addDefaultLayerGuestControlEntryToPage(pageId));
      dispatch(trackCreateBlocksPageEvent({ pageId }));
      dispatch(navigateToBlocksPage(pageId));
    };
  },
  deletePageAndNavigateToDefaultPage: ({ pageId }: { pageId: BlocksPageId }): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const page = blocksPagesByIdSelector(state)[pageId];

      const pageType = page?.internalPageType;
      if (isDatabasePage(pageType)) {
        const specId = getObjectSpecIdFromInternalPageType(pageType);
        dispatch(
          openDeleteObjectSpecDialog({
            specIdToDelete: specId,
          }),
        );
        return;
      }

      dispatch(trackDeleteBlocksPageEvent({ pageId }));
      dispatch(deletePages({ pageIds: [pageId] }));
      dispatch(removeDefaultLayerGuestControlEntryFromPage(pageId));
      dispatch(navigateToDefaultPage());
    };
  },
  updatePageDefaultDateRange: getMutationThunkAction<[DateTime, DateTime]>((range, getState) => {
    const state = getState();
    const [currentStart, currentEnd] = defaultDateRangeDateTimeSelector(state);
    const [newStart, newEnd] = range;

    const currentPageId = pageIdSelector(state);
    if (currentPageId == null) {
      return null;
    }

    if (newStart === currentStart && newEnd === currentEnd) {
      return null;
    }

    return {
      updateBlocksPages: [
        {
          id: currentPageId,
          dateRange: {
            start: getISOTimeWithoutMs(newStart.startOf('month').toISO()),
            end: getISOTimeWithoutMs(newEnd.endOf('month').toISO()),
          },
        },
      ],
    };
  }),
  duplicateBlocksPageAndNavigateToNewPage: ({ pageId }: { pageId: BlocksPageId }): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const authenticatedUser = authenticatedUserSelector(state);
      const pages = userCreatedBlockPagesSelector(state);
      const page = blocksPagesByIdSelector(state)[pageId];
      if (!page) {
        return;
      }

      const newPageId = uuidv4();
      const pageOrderUpdates = computeSortIndexUpdates(pages, {
        toInsertId: newPageId,
        afterId: pageId,
      });
      const blocksToCopy = page.blockIds
        .map((blockId) => blockSelector(state, blockId))
        .filter(isNotNull);
      const blockIdMap = Object.fromEntries(blocksToCopy.map((block) => [block.id, uuidv4()]));
      const updateBlocksPages: BlocksPageUpdateInput[] = [
        {
          id: newPageId,
          layout: page.layout == null ? null : duplicateLayout(page.layout, blockIdMap),
        },
        ...Object.entries(pageOrderUpdates).map(([id, sortIndex]) => ({
          id,
          sortIndex,
        })),
      ];
      const mutation: DatasetMutationInput = {
        newBlocksPages: [
          {
            id: newPageId,
            name: `${page.name} (Copy)`,
            createdByUserId: authenticatedUser?.id,
            parent: page.parent,
          },
        ],
        newBlocks: blocksToCopy.map((blockToCopy) => ({
          ...blockToCopy,
          id: blockIdMap[blockToCopy.id],
          pageId: newPageId,
        })),
        updateBlocksPages,
      };

      dispatch(
        submitAutoLayerizedMutations('duplicate-blocks-page', [
          {
            updateDrivers: blockDuplicateDriverReferenceMutations(state, blockIdMap),
          },
        ]),
      );

      dispatch(trackCreateBlocksPageEvent({ pageId: newPageId }));
      dispatch(submitMutation(mutation, { forceLayerId: DEFAULT_LAYER_ID }));
      dispatch(navigateToBlocksPage(newPageId));
    };
  },
};

export const updatePageName = ({
  id,
  name,
  userPressedEnter,
}: {
  id: BlocksPageId;
  name: string;
  userPressedEnter?: boolean;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const page = blocksPageSelector(state, id);
    if (page == null) {
      return;
    }
    const enableAgCharts = enableAgChartsSelector(state);

    if (isDatabasePage(page.internalPageType)) {
      const objectSpecId = getObjectSpecIdFromInternalPageType(page.internalPageType);
      dispatch(renameBusinessObjectSpec({ objectSpecId, name }));
    } else {
      const renameMutation = updateBlocksPageNameTransformerFn({ id, name }, getState);
      if (renameMutation == null) {
        return;
      }

      const sortedBlockIds = sortedBlockIdsForPageSelector(state, id);
      const firstBlockId = sortedBlockIds[0];
      const blocksById = blocksByIdSelector(state);
      if (
        userPressedEnter &&
        (firstBlockId == null || blocksById[firstBlockId].type === BlockType.Text)
      ) {
        const newTextBlockInput = getBlockCreateInput(BlockType.Text, { enableAgCharts });
        dispatch(
          createBlockAndUpdateCurrentPageLayoutAndOrdering(
            {
              blockCreateInput: newTextBlockInput,
              mergeMutation: renameMutation,
            },
            {
              targetBlockId: firstBlockId,
              relativePosition: 'above',
            },
            {
              addBeforeBlockId: firstBlockId,
            },
          ),
        );
      } else {
        dispatch(mutationActions.updateBlocksPageName({ id, name }));
      }
    }
  };
};

export const deleteBlocksPagesMutation = (
  state: RootState,
  pageIds: BlocksPageId[],
): { deleteBlocksPages: BlocksPageDeleteInput[]; updateDrivers: DriverUpdateInput[] } => {
  const blockIds = pageIds.map((id) => blocksPageSelector(state, id)?.blockIds ?? []).flat();

  return {
    deleteBlocksPages: pageIds.map((id) => ({ id })),
    ...blockDeletionDriverReferenceMutations(state, blockIds),
  };
};

export const deleteBlocksUpdateBlocksPagesMutations = (
  state: RootState,
  blockIds: BlockId[],
): { updateBlocksPages: BlocksPageUpdateInput[] } => {
  const blocks = blockIds.map((id) => blockSelector(state, id)).filter(isNotNull);
  const blocksByPageId = groupBy(blocks, (block) => block.pageId);

  const updateBlocksPages = Object.entries(blocksByPageId)
    .map(([pageId, blocksForPage]) => {
      const blocksPage = blocksPageSelector(state, pageId);
      const layout = blocksPage?.layout;

      if (layout == null) {
        return null;
      }

      const newLayout = removeBlocksFromLayout(
        layout,
        blocksForPage.map((block) => block.id),
      );
      const updateInput: BlocksPageUpdateInput = {
        id: pageId,
        layout: newLayout?.length === 0 ? undefined : newLayout,
        deleteLayout: newLayout.length === 0,
      };

      return updateInput;
    })
    .filter(isNotNull);

  return {
    updateBlocksPages,
  };
};

export const deletePages = ({ pageIds }: { pageIds: BlocksPageId[] }): AppThunk => {
  return (dispatch, getState) => {
    dispatch(
      submitAutoLayerizedMutations(
        'delete-blocks-page',
        [deleteBlocksPagesMutation(getState(), pageIds)],
        {
          // Normally updateDrivers gets routed to the current layer, but in this
          // case we want it to follow delete blocksPages.
          updateDrivers: 'deleteBlocksPages',
        },
      ),
    );
  };
};

export const {
  createAndNavigateToNewPage,
  deletePageAndNavigateToDefaultPage,
  updatePageDefaultDateRange,
  duplicateBlocksPageAndNavigateToNewPage,
  updateBlocksPageName,
} = mutationActions;
