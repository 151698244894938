import React from 'react';

import TimestampDisplayCellLight from 'components/DisplayCell/TimestampDisplayCellLight';
import TimeSeriesCellWrapperLight, {
  ActiveCellLightProps,
  TimeSeriesCellLightProps,
} from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapperLight';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import { TimestampValue } from 'reduxStore/models/value';

const TimestampTimeSeriesCellLight: React.FC<TimeSeriesCellLightProps<TimestampValue>> = ({
  value,
  color,
  width,
  alert,
}) => {
  const { isActive } = useCellSelectionStateContext();

  return (
    <TimeSeriesCellWrapperLight color={color} width={width}>
      {isActive ? (
        <TimestampActiveCellLight value={value} />
      ) : (
        <TimestampDisplayCellLight value={value} />
      )}
      {alert}
    </TimeSeriesCellWrapperLight>
  );
};

const TimestampActiveCellLight: React.FC<ActiveCellLightProps<TimestampValue>> = ({
  value,
  isPlaceholder,
}) => {
  return <TimestampDisplayCellLight value={value} isPlaceholder={isPlaceholder} />;
};

export default React.memo(TimestampTimeSeriesCellLight);
