import { createContext } from 'react';

import { BlockId } from 'reduxStore/models/blocks';

export type BlockContextValue = {
  readOnly: boolean;
  blockId: BlockId;
  gutterWidthInPx: number;
  gutterWidthInPxString: string;
  disableBlockConfigChanges: boolean;
};

export const BlockReactContext = createContext<BlockContextValue | null>(null);
