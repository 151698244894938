import { Draft } from '@reduxjs/toolkit';
import remove from 'lodash/remove';

import { ALL_COLUMN_TYPES, ModelViewColumnType } from 'config/modelView';
import { SubmodelCreateInput, SubmodelDeleteInput, SubmodelUpdateInput } from 'generated/graphql';
import { isValidMonthKey } from 'helpers/dates';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { LightLayer } from 'reduxStore/models/layers';
import { Submodel, SubmodelId } from 'reduxStore/models/submodels';
import { handleDeleteDrivers } from 'reduxStore/reducers/helpers/drivers';
import { MonthKey } from 'types/datetime';

export function isDataColumnType(
  columnType: ModelViewColumnType | MonthKey,
): columnType is MonthKey {
  // we use month keys for data column types; they have a yyyy-MM format
  return isValidMonthKey(columnType);
}

export function isModelViewColumnType(
  columnType: ModelViewColumnType | MonthKey,
): columnType is ModelViewColumnType {
  return !isDataColumnType(columnType) && ALL_COLUMN_TYPES.includes(columnType);
}

export function handleCreateSubmodel(
  layer: Draft<LightLayer>,
  newSubmodelInput: SubmodelCreateInput,
) {
  // eslint-disable-next-line deprecation/deprecation
  const { id, name, description, sortedDriverGroupIds, sortIndex } = newSubmodelInput;
  if (layer.submodels.byId[id] != null) {
    return;
  }

  const newSubmodel: Submodel = {
    id,
    name: name ?? undefined,
    description: description ?? undefined,
    sortedDriverGroupIds: sortedDriverGroupIds ?? [],
    sortIndex: sortIndex ?? undefined,
  };
  layer.submodels.byId[id] = newSubmodel;
  layer.submodels.allIds.push(newSubmodel.id);
}

export function handleUpdateSubmodel(
  layer: Draft<LightLayer>,
  updateSubmodelInput: SubmodelUpdateInput,
) {
  // TODO: Keep submodel name until supporting old mutations is no longer needed.
  // eslint-disable-next-line deprecation/deprecation
  const { id, name, description, sortedDriverGroupIds, sortIndex } = updateSubmodelInput;
  const submodel = layer.submodels.byId[id];
  if (submodel == null) {
    return;
  }

  if (name != null) {
    // eslint-disable-next-line deprecation/deprecation
    submodel.name = name;
  }

  if (description != null) {
    submodel.description = description;
  }

  if (sortedDriverGroupIds != null) {
    submodel.sortedDriverGroupIds = sortedDriverGroupIds;
  }
  if (sortIndex != null) {
    submodel.sortIndex = sortIndex;
  }
}

export function handleDeleteSubmodel(
  layer: Draft<LightLayer>,
  // TODO: Keep deleteDrivers until supporting old mutations is no longer needed.
  // eslint-disable-next-line deprecation/deprecation
  { id, deleteDrivers }: SubmodelDeleteInput,
) {
  // This function may be called for several layers after being called for the main layer
  // so the submodel has probably already been deleted, but we still need to keep going and
  // update the drivers.
  delete layer.submodels.byId[id];
  remove(layer.submodels.allIds, (i) => i === id);

  const drivers = layer.drivers;
  if (deleteDrivers) {
    // Referencing drivers by `submodelId` is deprecated in preference of `driverReferences`.
    // This exists purely for old mutations that might be lingering in layers.
    const driverIdsToDelete = Object.values(drivers.byId)
      // eslint-disable-next-line deprecation/deprecation
      .filter(({ submodelId }) => id === submodelId)
      .map((driver) => ({ id: driver.id }));
    handleDeleteDrivers(layer, driverIdsToDelete);
  } else {
    // Maintain this code for the deprecated `submodelId` for old mutations.
    Object.values(drivers.byId).forEach((driver) => {
      // eslint-disable-next-line deprecation/deprecation
      if (driver.submodelId === id) {
        // eslint-disable-next-line deprecation/deprecation
        driver.submodelId = undefined;
      }
    });
  }
}

export function setSubmodelsFromDatasetSnapshot(
  layer: Draft<LightLayer>,
  dataset: DatasetSnapshot,
) {
  const initialSubmodels: Record<SubmodelId, Submodel> = {};
  if (dataset == null) {
    layer.submodels.byId = initialSubmodels;
    return;
  }

  layer.submodels.byId = dataset.submodels.reduce((submodels, submodel) => {
    submodels[submodel.id] = {
      id: submodel.id,
      name: submodel.name ?? undefined,
      description: submodel.description ?? undefined,
      sortedDriverGroupIds: submodel.sortedDriverGroupIds ?? [],
      sortIndex: submodel.sortIndex ?? undefined,
    };
    return submodels;
  }, initialSubmodels);
}
