import { Flex, Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { useCallback, useContext, useMemo } from 'react';

import MoreMenuButton from 'components/MoreMenuButton/MoreMenuButton';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { DimDriverEditReactContext } from 'config/dimDriverEditContext';
import theme from 'config/theme';
import useAppDispatch from 'hooks/useAppDispatch';
import useControlledPopover from 'hooks/useControlledPopover';
import { openSettingsModal } from 'reduxStore/reducers/pageSlice';
import TrendArrowIcon from 'vectors/TrendArrow';

interface Props {
  name?: string;
  inputRef: React.RefObject<HTMLInputElement>;
}

const ManageDimensionsMenuButton: React.FC<Props> = ({ name, inputRef }) => {
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();
  const dispatch = useAppDispatch();
  const { onSave } = useContext(DimDriverEditReactContext);
  const items: SelectItem[] = useMemo(
    () => [
      {
        id: 'manage',
        name:
          name != null ? (
            <CustomCreateOption label="Manage" helperText={name} />
          ) : (
            'Manage dimension'
          ),
        icon: <TrendArrowIcon direction="up" />,
      },
    ],
    [name],
  );

  const onSelect = useCallback(() => {
    if (isOpen) {
      onSave();
      dispatch(openSettingsModal({ tabIndex: 'dimensions' }));
    }
  }, [onSave, dispatch, isOpen]);

  return (
    <Popover
      isLazy
      placement="end-end"
      closeOnBlur
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={inputRef}
    >
      <PopoverTrigger>
        <Flex
          data-testid="manage-dimensions"
          zIndex={theme.zIndices.popover + 1}
          ref={triggerRef}
          alignItems="center"
        >
          <MoreMenuButton isOpen={isOpen} />
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent ref={contentRef} padding={0} boxShadow="none">
          <SelectMenuContainer>
            <SelectMenu width="16rem" items={items} onClose={onClose} onSelect={onSelect}>
              {BaseSelectMenuItem}
            </SelectMenu>
          </SelectMenuContainer>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
export default ManageDimensionsMenuButton;
