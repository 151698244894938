import React from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import DriverPropertyTableCell from 'components/DriverPropertyTableCell/DriverPropertyTableCell';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import { ComparisonColumn } from 'generated/graphql';
import { ComparisonLayout } from 'helpers/blockComparisons';
import useDriverCellRef from 'hooks/useDriverCellRef';

// In column comparison layout, we don't want to show the column property value
// (e.g. "Forecast Formula") in "variance" rows or "variance %" rows.
export function shouldHideColumnPropertyCell({
  comparisonLayout,
  columnType,
  rowComparisonType,
}: {
  comparisonLayout: ComparisonLayout;
  columnType: ModelViewColumnType;
  rowComparisonType: ComparisonColumn | undefined;
}) {
  return (
    comparisonLayout === 'column-default' &&
    columnType !== 'name' &&
    rowComparisonType !== ComparisonColumn.RowVersion
  );
}

export const EmptyDriverTableCell: React.FC<{
  columnType: ModelViewColumnType;
  columnLayerId: ColumnLayerId;
  children?: React.ReactNode;
}> = React.memo(({ columnType, columnLayerId, children }) => {
  const cellRef = useDriverCellRef({ columnType, columnLayerId });
  return (
    <CellContextProvider cellRef={cellRef}>
      <DriverPropertyTableCell columnType={columnType} columnLayerId={columnLayerId} isSticky>
        {children}
      </DriverPropertyTableCell>
    </CellContextProvider>
  );
});
