import { deepEqual } from 'fast-equals';
import { useContext, useMemo } from 'react';

import { TypedTimeSeries } from 'components/DriverTimeSeriesRow/DriverTimeSeriesRow';
import { DriverRowContext } from 'config/driverRowContext';
import {
  CalculationErrorType,
  ComparisonColumn,
  ComparisonTimePeriod,
  RollupType,
  ThresholdDirection,
  ValueType,
} from 'generated/graphql';
import {
  getColorWithComparisonSources,
  getErrorForColumn,
  getValueColor,
  getValueForColumn,
} from 'helpers/blockComparisons';
import { applyRollupReducer } from 'helpers/rollups';
import { getTimePeriodMonthKeysForRollupType } from 'helpers/timeperiodComparisons';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { LayerId } from 'reduxStore/models/layers';
import {
  ErrorTimeSeries,
  NonNumericTimeSeries,
  NumericTimeSeries,
} from 'reduxStore/models/timeSeries';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { entityLoadingByMonthKeySelector } from 'selectors/calculationsSelector';
import {
  driverErrorTimeSeriesForLayerSelector,
  driverTimeSeriesForLayerSelector,
  driverTimeSeriesSourceByMonthKeySelector,
} from 'selectors/driverTimeSeriesSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { driverMilestoneThresholdSelector } from 'selectors/milestonesSelector';
import { CalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

const EMPTY_TIMESERIES: NumericTimeSeries = {};
const EMPTY_ERROR_TIMESERIES: ErrorTimeSeries = {};
const EMPTY_SOURCE_BY_MONTH_KEY: Record<string, 'actuals' | 'forecast'> = {};

export const useTimeSeriesBaseline = (): TypedTimeSeries => {
  const { blockId } = useBlockContext();
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const { driverId, requiresBaselineLayer } = useContext(DriverRowContext);

  const timeSeries = useAppSelector(
    (state) =>
      requiresBaselineLayer
        ? driverTimeSeriesForLayerSelector(state, {
            id: driverId,
            layerId: baselineLayerId,
          })
        : EMPTY_TIMESERIES,
    deepEqual,
  );

  return {
    type: ValueType.Number,
    timeSeries,
  };
};

export const useErrorTimeSeriesBaseline = (): ErrorTimeSeries => {
  const { blockId } = useBlockContext();
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const { driverId, requiresBaselineLayer } = useContext(DriverRowContext);

  return useAppSelector(
    (state) =>
      requiresBaselineLayer
        ? driverErrorTimeSeriesForLayerSelector(state, {
            id: driverId,
            layerId: baselineLayerId,
          })
        : EMPTY_ERROR_TIMESERIES,
    deepEqual,
  );
};

/**
 * When comparing layers, the baseline layer is the layer that is used as the baseline for comparison,
 * and not necessarily the current layer. If no baselineLayerId is set, we default to the currentLayerId.
 *
 * When comparing time periods, the baseline month-keys will correspond to the page's date range (the ComparisonTimePeriod.CurrentPeriod),
 * which the other time periods will compare against
 */
export const useIsLoadingByMonthKeyBaseline = (): Record<string, boolean> => {
  const { blockId } = useBlockContext();
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const { driverId, requiresBaselineLayer, monthKeys } = useContext(DriverRowContext);
  const notLoading = useMemo(
    () => monthKeys.reduce((acc, monthKey) => ({ ...acc, [monthKey]: false }), {}),
    [monthKeys],
  );
  return useAppSelector(
    (state) =>
      requiresBaselineLayer
        ? entityLoadingByMonthKeySelector(state, {
            id: driverId,
            layerId: baselineLayerId,
            monthKeys,
          })
        : notLoading,
    deepEqual,
  );
};

export const useSourceByMonthKeyBaseline = () => {
  const { blockId } = useBlockContext();

  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const { driverId, requiresBaselineLayer } = useContext(DriverRowContext);

  return useAppSelector(
    (state) =>
      requiresBaselineLayer
        ? driverTimeSeriesSourceByMonthKeySelector(state, {
            id: driverId,
            layerId: baselineLayerId,
          })
        : EMPTY_SOURCE_BY_MONTH_KEY,
    deepEqual,
  );
};

export const useThresholdDirection = () => {
  const { driverId, requiresVariance } = useContext(DriverRowContext);

  return useAppSelector((state) =>
    requiresVariance
      ? driverMilestoneThresholdSelector(state, driverId)
      : ThresholdDirection.AboveOrEqual,
  );
};

export type CellColor = 'actuals' | 'forecast' | 'red.600' | 'green.600';

type UnwrapValueType<T extends ValueType> = T extends ValueType.Number ? number : string;

type DriverCellValueAndColor<T extends ValueType> = {
  value: UnwrapValueType<T> | undefined;
  error: CalculationError | undefined;
  isLoading: boolean;
  rowValue: UnwrapValueType<T> | undefined;
  baselineValue: UnwrapValueType<T> | undefined;
  color: CellColor;
};

function isNumberValueType(ts: TypedTimeSeries): ts is {
  type: ValueType.Number;
  timeSeries: NumericTimeSeries;
} {
  return ts.type === ValueType.Number;
}

function isNonNumericValueType(
  ts: TypedTimeSeries,
): ts is { type: ValueType.Timestamp; timeSeries: NonNumericTimeSeries } {
  return ts.type !== ValueType.Number;
}

export const useDriverCellValueAndColor = ({
  layerId,
  monthKeys: baselineMonthKeys,
  comparisonColumn,
  sourceByMonthKeyBaseline,
  sourceByMonthKeyLayer,
  thresholdDirection,
  timeSeriesBaseline,
  timeSeriesLayer,
  errorTimeSeriesBaseline,
  errorTimeSeriesLayer,
  isLoadingByMonthKeyBaseline,
  isLoadingByMonthKeyLayer,
  rollupType,
}: {
  layerId: LayerId;
  monthKeys: MonthKey[];
  comparisonColumn: ComparisonColumn | undefined;
  sourceByMonthKeyBaseline: Record<string, 'forecast' | 'actuals'>;
  sourceByMonthKeyLayer: Record<string, 'forecast' | 'actuals'>;
  thresholdDirection?: ThresholdDirection;
  timeSeriesBaseline: TypedTimeSeries;
  timeSeriesLayer: TypedTimeSeries;
  errorTimeSeriesBaseline: ErrorTimeSeries;
  errorTimeSeriesLayer: ErrorTimeSeries;
  isLoadingByMonthKeyBaseline: Record<string, boolean>;
  isLoadingByMonthKeyLayer: Record<string, boolean>;
  rollupType: RollupType;
}): DriverCellValueAndColor<TypedTimeSeries['type']> => {
  const {
    comparisonType: comparisonTypeRow,
    rollupReducer,
    driverId,
    comparisonTimePeriod,
  } = useContext(DriverRowContext);
  // Depending on the comparison orientation, we either get the comparisonType from the Row or the Column
  const comparisonType = comparisonColumn ?? comparisonTypeRow;
  let value: number | string | undefined;
  let error: CalculationError | undefined;
  let isLoading: boolean = false;
  let rowValue: number | string | undefined;
  let baselineValue: number | string | undefined;
  let color: CellColor = 'actuals';

  const currentLayerId = useAppSelector(currentLayerIdSelector);

  const timePeriod =
    comparisonTimePeriod != null ? comparisonTimePeriod : ComparisonTimePeriod.CurrentPeriod;

  const monthKeys = getTimePeriodMonthKeysForRollupType(baselineMonthKeys, timePeriod, rollupType);

  if (isNumberValueType(timeSeriesLayer) && isNumberValueType(timeSeriesBaseline)) {
    switch (comparisonType) {
      case ComparisonColumn.LatestVersion:
      case ComparisonColumn.BaselineVersion: {
        const reduced = applyRollupReducer({
          monthKeys: baselineMonthKeys,
          values: timeSeriesBaseline.timeSeries,
          errors: errorTimeSeriesBaseline,
          reducer: rollupReducer,
        });
        value = reduced.value;
        error = reduced.error;
        isLoading = baselineMonthKeys.some(
          (monthKey) => safeObjGet(isLoadingByMonthKeyLayer[monthKey]) ?? true,
        );
        color = getValueColor(baselineMonthKeys, sourceByMonthKeyBaseline);
        break;
      }
      case ComparisonColumn.Variance:
      case ComparisonColumn.VariancePercentage: {
        isLoading = baselineMonthKeys.some(
          (monthKey) =>
            (safeObjGet(isLoadingByMonthKeyLayer[monthKey]) ?? true) ||
            (safeObjGet(isLoadingByMonthKeyBaseline[monthKey]) ?? true),
        );

        const reducedBaseline = applyRollupReducer({
          monthKeys: baselineMonthKeys,
          values: timeSeriesBaseline.timeSeries,
          errors: errorTimeSeriesBaseline,
          reducer: rollupReducer,
        });
        baselineValue = reducedBaseline.value;

        const reducedRow = applyRollupReducer({
          monthKeys,
          values: timeSeriesLayer.timeSeries,
          errors: errorTimeSeriesLayer,
          reducer: rollupReducer,
        });
        rowValue = reducedRow.value;

        value = getValueForColumn({
          rowValue,
          baselineValue,
          column: comparisonType,
        });

        error = getErrorForColumn({
          rowError: reducedRow.error,
          baselineError: reducedBaseline.error,
          column: comparisonType,
        });

        color = getColorWithComparisonSources({
          monthKeys,
          value,
          baselineValue,
          rowValue,
          subLabel: comparisonType,
          thresholdDirection: thresholdDirection ?? ThresholdDirection.AboveOrEqual,
          baselineVersionTimeSeriesSourceByMonthKey: sourceByMonthKeyBaseline,
          compareVersionTimeSeriesSourceByMonthKey: sourceByMonthKeyLayer,
        });
        break;
      }
      default: {
        const reduced = applyRollupReducer({
          monthKeys,
          values: timeSeriesLayer.timeSeries,
          errors: errorTimeSeriesLayer,
          reducer: rollupReducer,
        });
        value = reduced.value;
        error = reduced.error;
        isLoading = monthKeys.some(
          (monthKey) => safeObjGet(isLoadingByMonthKeyLayer[monthKey]) ?? true,
        );
        color = getValueColor(monthKeys, sourceByMonthKeyLayer);
      }
    }
  } else if (isNonNumericValueType(timeSeriesLayer)) {
    // Rollups on non-numeric drivers is undefined behavior.
    // Just pick the first month key until this is figured out.
    const monthKey = monthKeys[0];
    value = timeSeriesLayer.timeSeries[monthKey];
    error = errorTimeSeriesLayer[monthKey];

    isLoading = baselineMonthKeys.some((mk) => safeObjGet(isLoadingByMonthKeyLayer[mk]) ?? true);
    color = getValueColor(baselineMonthKeys, sourceByMonthKeyBaseline);
  }

  // A reference error that originates from the top level driver of a BvA cell
  // should show as "-" without the default color as it is common for drivers
  // to have not existed when a snapshot was taken.
  const isCurrentLayerCell = layerId === currentLayerId;
  const isOriginOfError = error?.originEntity != null && error.originEntity.id === driverId;
  const isRefErr = error?.error === CalculationErrorType.MissingEntity;
  const shouldSuppressErr = isRefErr && !isCurrentLayerCell && isOriginOfError;

  if (error != null && !shouldSuppressErr) {
    color = 'red.600';
  }

  return useMemo(
    () => ({ value, error, isLoading, rowValue, baselineValue, color }),
    [value, error, isLoading, rowValue, baselineValue, color],
  );
};
