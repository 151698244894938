import {
  Box,
  Button,
  ColorProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';

import { LayerNameProps } from '@features/CompareScenarios/Header';
import { LayerName } from '@features/CompareScenarios/common/LayerName';
import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import DefaultScenarioIcon from 'components/ScenarioManagement/DefaultScenarioIcon';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { stopEventPropagation } from 'helpers/browserEvent';
import { extractEmoji } from 'helpers/emoji';
import useAppSelector from 'hooks/useAppSelector';
import useControlledPopover from 'hooks/useControlledPopover';
import { Layer, LayerId } from 'reduxStore/models/layers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { layersSelector } from 'selectors/layerSelector';
import Arrowhead from 'vectors/Arrowhead';

export const LayerSelector = ({
  mergeLayer,
  currentLayer,
  fontSize,
  color,
  onSelect: onSelectLayer,
}: {
  mergeLayer: Layer;
  currentLayer: Layer | null;
  color?: ColorProps['color'];
  fontSize?: string;
  onSelect: (currentLayer: Layer) => void;
}) => {
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();
  const layers = useAppSelector(comparableLayersSelector);

  const items = useSelectMenuItems(layers, currentLayer, mergeLayer);
  const onSelectItem = useCallback(
    ({ id }: SelectItem) => {
      onSelectLayer(layers[id]);
      onClose();
    },
    [layers, onClose, onSelectLayer],
  );

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
      placement="bottom-start"
      offset={[8, 4]}
      closeOnBlur
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <PopoverTrigger>
        <Box ref={triggerRef}>
          {currentLayer != null ? (
            <LayerName
              layer={currentLayer}
              layerType="current"
              color={color}
              fontSize={fontSize}
              blockProps={{
                ...LayerNameProps,
                cursor: 'pointer',
                transition: 'all 0.1s ease-in-out',
                border: '1px solid',
                borderColor: 'gray.300',
                borderRadius: '6px',
                marginX: 2,
                _hover: {
                  backgroundColor: 'gray.200',
                },
              }}
              icon={<Arrowhead boxSize="2" marginLeft="1" color="gray.600" direction="down" />}
            />
          ) : (
            <Button
              fontSize="xs"
              paddingX="6px"
              paddingY="2px"
              marginX="2"
              height="27px"
              rightIcon={<Arrowhead boxSize="2" color="gray.600" direction="down" />}
            >
              Scenario
            </Button>
          )}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent onClick={stopEventPropagation} padding="0" ref={contentRef}>
          {items.length > 0 ? (
            <SelectMenu items={items} dismissIfEmpty onSelect={onSelectItem} width="18rem">
              {BaseSelectMenuItem}
            </SelectMenu>
          ) : (
            <Box fontSize="xs" fontWeight="medium" padding="3" maxWidth="450px">
              <Text marginBottom="2">No alternate scenarios to compare.</Text>
              <Text>
                Create a new scenario by:
                <ol style={{ paddingLeft: '20px' }}>
                  <li>Modifying your main scenario </li>
                  <li>Saving the draft changes as a scenario</li>
                </ol>
              </Text>
            </Box>
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

function useSelectMenuItems(
  layersById: LayersById,
  currentLayer: Layer | null,
  mergeLayer: Layer,
): SelectItem[] {
  return useMemo(() => {
    return Object.values(layersById).flatMap(({ id, name }) => {
      if (id === mergeLayer.id) {
        return [];
      }

      const [emoji, displayName] = extractEmoji(name);
      const isChecked = id === currentLayer?.id;

      const item: SelectItem = {
        id,
        name: displayName,
        isChecked,
        icon: !isChecked ? (
          <EmojiIcon
            size="sm"
            emoji={emoji}
            emptyIcon={
              <DefaultScenarioIcon
                _groupHover={{ color: 'white' }}
                _groupFocus={{ color: 'white' }}
              />
            }
          />
        ) : undefined,
        checkedStyle: isChecked ? 'check' : 'none',
      };

      return item;
    });
  }, [layersById, mergeLayer, currentLayer]);
}

const comparableLayersSelector = createSelector(
  [layersSelector, accessCapabilitiesSelector],
  (layersById, accessCapabilities) => {
    return Object.values(layersById).reduce<LayersById>((acc, layer) => {
      const isNamedVersion = layer.lockedMutationId != null;
      if (
        !layer.isDraft &&
        !layer.isDeleted &&
        !isNamedVersion &&
        accessCapabilities.canReadLayerGlobally(layer.id)
      ) {
        acc[layer.id] = layer;
      }
      return acc;
    }, {});
  },
);

type LayersById = Record<LayerId, Layer>;
