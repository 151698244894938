import { DatasetMutationInput } from 'generated/graphql';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';

export const accessibleLayerIdsForMutation = (state: RootState, mutation: DatasetMutationInput) => {
  const capabilities = accessCapabilitiesSelector(state);

  const accessibleLayerIds = [
    // If the backend sent you this layer, you have access to it.
    ...Object.keys(state.dataset.layers),
    // Right now the only layer accessibility checks that matter for `applyMutationLocally` are
    // new layers. We can safely not check accessibility for all other mutations for now.
    ...(mutation.newLayers
      ? mutation.newLayers
          .filter((layer) => capabilities.canReadLayerGlobally(layer.id))
          .map((layer) => layer.id)
      : []),
  ];

  return accessibleLayerIds;
};
