import React from 'react';

import { getCellBorderClasses } from 'components/CellBorder/CellBorder';
import { DEFAULT_CELL_SELECTION_STATE } from 'config/cells';

const CELL_STATE = {
  ...DEFAULT_CELL_SELECTION_STATE,
  isActive: true,
  hasSolidRightBorder: true,
  hasRightBorder: true,
  hasSolidBottomBorder: true,
  hasBottomBorder: true,
};

const ActiveCellBorder: React.FC = () => {
  return (
    <div className={getCellBorderClasses(CELL_STATE, false)} data-testid="active-cell-border" />
  );
};

export default ActiveCellBorder;
