import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'ExpandSidebarIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M8.53 3.53a.75.75 0 0 0-1.06-1.06l1.06 1.06ZM3 8l-.53-.53a.75.75 0 0 0 0 1.06L3 8Zm4.47 5.53a.75.75 0 0 0 1.06-1.06l-1.06 1.06Zm0-11.06-5 5 1.06 1.06 5-5-1.06-1.06Zm-5 6.06 5 5 1.06-1.06-5-5-1.06 1.06ZM13.53 3.53a.75.75 0 0 0-1.06-1.06l1.06 1.06ZM8 8l-.53-.53a.75.75 0 0 0 0 1.06L8 8Zm4.47 5.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm0-11.06-5 5 1.06 1.06 5-5-1.06-1.06Zm-5 6.06 5 5 1.06-1.06-5-5-1.06 1.06Z"
      fill="currentColor"
    />
  ),
});
