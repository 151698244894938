import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export default {
  parts: ['tab', 'tabIcon', 'tabpanel', 'tabpanels', 'root', 'tablist', 'indicator'],
  variants: {
    line: {
      tab: {
        color: 'gray.400',
        fontWeight: 'bold',
        fontSize: 'xs',
        py: 1,
        _selected: { color: 'selection.500' },

        // Overlap the selected tab line with the tab list divider
        marginBottom: '-1px',
      },
      tablist: {
        borderColor: 'gray.300',
        borderBottomWidth: 'px',
      },
      tabpanel: {
        px: 0,
        py: 2,
      },
    },
    vertical: {
      tab: {
        borderRadius: 'lg',
        color: 'gray.600',
        fontSize: 'xs',
        fontWeight: 'medium',
        justifyContent: 'start',
        mx: 2,
        px: 4,
        transition: 'unset',
        _selected: { bg: 'selection.500', color: 'surface' },
        _hover: { bg: 'selection.500', color: 'surface' },
      },
      tabIcon: {
        color: 'gray.500',
        _parentAriaSelected: { color: 'surface' },
        _tabHover: { color: 'surface' },
      },
      tablist: {
        w: '13rem',
        bg: 'gray.100',
        alignContent: 'start',
        py: 2,
      },
    },
  },
} as ComponentMultiStyleConfig;
