import { Box, useBoolean } from '@chakra-ui/react';
import Tippy from '@tippyjs/react/headless';
import noop from 'lodash/noop';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { followCursor } from 'tippy.js/headless';

import AppOverlay from 'components/AppOverlay/AppOverlay';
import CopilotMenu from 'components/CopilotMenu/CopilotMenu';
import theme from 'config/theme';
import useAppSelector from 'hooks/useAppSelector';
import { hasActiveCopilotSessionSelector } from 'selectors/copilotSelector';

export const ContextMenuSingletonReactContext = React.createContext<{
  openSingletonContextMenu: (shouldShowOverlay: boolean) => void;
  closeSingletonContextMenu: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
}>({
  openSingletonContextMenu: noop,
  closeSingletonContextMenu: noop,
  containerRef: { current: null },
});

const tippyPlugins = [followCursor];

const ContextMenuSingleton: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useBoolean();
  const [shouldShowOverlay, setShouldShowOverlay] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hasActiveCopilotSession = useAppSelector(hasActiveCopilotSessionSelector);
  const showOverlay = shouldShowOverlay && isOpen && !hasActiveCopilotSession;
  const previousHasActiveCopilotSession = usePrevious(hasActiveCopilotSession);

  const renderMenu = useCallback(() => {
    if (!isOpen) {
      containerRef.current = null;
      return null;
    }

    if (hasActiveCopilotSession) {
      containerRef.current = null;
      return <CopilotMenu />;
    } else {
      return <Box pointerEvents="auto" ref={containerRef} />;
    }
  }, [isOpen, hasActiveCopilotSession]);

  const ctx = useMemo(() => {
    return {
      openSingletonContextMenu: (newShouldShowOverlay: boolean) => {
        setIsOpen.on();
        setShouldShowOverlay(newShouldShowOverlay);
      },
      closeSingletonContextMenu: setIsOpen.off,
      containerRef,
    };
  }, [setIsOpen]);

  useEffect(() => {
    if (!hasActiveCopilotSession && previousHasActiveCopilotSession) {
      setIsOpen.off();
    }
  }, [hasActiveCopilotSession, previousHasActiveCopilotSession, setIsOpen]);

  return (
    <ContextMenuSingletonReactContext.Provider value={ctx}>
      {children}
      {showOverlay && <AppOverlay />}
      <Tippy
        render={renderMenu}
        plugins={tippyPlugins}
        followCursor="initial"
        placement="bottom-start"
        // disable pointer events on the tippy itself, since the contents might move around and
        // we don't want to capture events on the tippy itself
        interactive={false}
        visible={isOpen}
        appendTo={document.body}
        zIndex={theme.zIndices.popover}
      />
    </ContextMenuSingletonReactContext.Provider>
  );
};

ContextMenuSingleton.displayName = 'ContextMenuSingleton';

export default ContextMenuSingleton;
