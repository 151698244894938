import React, { useCallback, useContext, useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { DatabaseTableContext } from 'config/databaseTableContext';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  autofillDimensionalProperty,
  clearAutofillForDimensionalProperty,
} from 'reduxStore/actions/businessObjectSpecMutations';
import { AutofillPropertyOption } from 'selectors/autofillDimensionalPropertySelector';
import Link from 'vectors/Link';

interface AutofillPropertySelectItem extends SelectItem {
  option: AutofillPropertyOption;
}

interface Props {
  options: AutofillPropertyOption[];
  onClose?: () => void;
}

const AutofillPropertySubmenu: React.FC<Props> = ({ options, onClose }) => {
  const dispatch = useAppDispatch();
  const { objectSpecId } = useContext(DatabaseTableContext);
  const items = useMemo(
    () =>
      options.map((option) => ({
        id: option.resultPropertyId,
        name: option.name,
        icon: <Link />,
        option,
        isChecked: option.selected,
      })),
    [options],
  );
  const onSelect = useCallback(
    (item: AutofillPropertySelectItem) => {
      if (item.option.selected === true) {
        if (item.option.propertyId != null) {
          dispatch(
            clearAutofillForDimensionalProperty({
              objectSpecId,
              propertyId: item.option.propertyId,
            }),
          );
        }
      } else {
        dispatch(
          autofillDimensionalProperty({
            objectSpecId,
            propertyId: item.option.propertyId,
            lookupSpecId: item.option.lookupSpecId,
            resultPropertyId: item.option.resultPropertyId,
          }),
        );
      }
      onClose?.();
    },
    [dispatch, objectSpecId, onClose],
  );
  return (
    <SelectMenuContainer>
      <SelectMenu items={items} onSelect={onSelect} width="18rem">
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default AutofillPropertySubmenu;
