import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'GoogleAdsIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <path
        d="M1.24603 42.5277L21.1912 7.97919L38.474 17.9579L18.5313 52.5064L1.24603 42.5277Z"
        fill="#FBBC04"
      />
      <path
        d="M58.5862 42.4074L38.6288 7.84658C36.0524 2.97388 30.0156 1.11223 25.1429 3.68857C20.2702 6.26491 18.411 12.3018 20.9849 17.1745C21.0979 17.3881 21.2182 17.5969 21.3459 17.8032L41.3033 52.364C43.9951 57.1728 50.0762 58.8896 54.885 56.1978C59.6938 53.506 61.4106 47.425 58.7188 42.6161C58.6746 42.5375 58.6304 42.4614 58.5862 42.3853V42.4074Z"
        fill="#4285F4"
      />
      <path
        d="M9.98695 57.4184C15.498 57.4184 19.9657 52.9508 19.9657 47.4397C19.9657 41.9286 15.498 37.461 9.98695 37.461C4.47586 37.461 0.00823975 41.9286 0.00823975 47.4397C0.00823975 52.9508 4.47586 57.4184 9.98695 57.4184Z"
        fill="#34A853"
      />
    </>
  ),
});
