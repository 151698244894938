import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'OracleIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <g clipPath="url(#clip0_48_345)">
        <path
          d="M18.9746 48.9714C8.5002 48.9714 0 40.4924 0 30.0148C0 19.5378 8.5002 11.0286 18.9746 11.0286H41.0286C51.5061 11.0286 60 19.5378 60 30.0148C60 40.4924 51.5061 48.9714 41.0286 48.9714H18.9746ZM40.5381 42.2789C47.3242 42.2789 52.8206 36.7983 52.8206 30.0148C52.8206 23.2319 47.3242 17.7206 40.5381 17.7206H19.4619C12.679 17.7206 7.17942 23.2319 7.17942 30.0148C7.17942 36.7983 12.6785 42.2789 19.4619 42.2789H40.5381Z"
          fill="#C74634"
        />
      </g>
      <defs>
        <clipPath id="clip0_48_345">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
