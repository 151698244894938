import { Flex, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { BadgeTheme, SIZE_TO_PROPS, ThemeColors, THEMES_TO_COLORS } from 'config/badge';
import { formatLabel } from 'helpers/formatting';

interface Props {
  text: string;
  theme: BadgeTheme | ThemeColors;
  fontStyle?: 'normal' | 'italic';
  cursor?: 'pointer';
  size?: 'sm' | 'xs' | 'md';
  shrink?: boolean;
}

const LightBadge: React.FC<Props> = ({ text, theme, size = 'sm', fontStyle, cursor, shrink }) => {
  const themeProps = typeof theme === 'string' ? THEMES_TO_COLORS[theme] : theme;

  const label = useMemo(() => formatLabel(text), [text]);

  return (
    <Flex
      {...themeProps}
      {...SIZE_TO_PROPS[size]}
      flexShrink={shrink ? 1 : 0}
      minWidth={shrink ? `min(${text.length}ch, 3rem)` : undefined}
      overflow={shrink ? 'hidden' : undefined}
      cursor={cursor}
      display="inline-flex"
      // color={color}
      // bgColor={bgColor}
      // px={px}
      // fontSize={fontSize}
      fontStyle={fontStyle}
      fontWeight="medium"
      // lineHeight={lineHeight}
      borderRadius="xl"
      maxWidth="full"
      // borderColor={borderColor}
      // borderWidth={borderWidth}
      columnGap={1}
      alignItems="center"
      whiteSpace="nowrap"
      py={0}
      _last={{ marginRight: 0 }}
    >
      <Text
        as="span"
        textOverflow={shrink ? 'ellipsis' : undefined}
        overflow={shrink ? 'hidden' : undefined}
        whiteSpace="nowrap"
      >
        {label}
      </Text>
    </Flex>
  );
};

export default LightBadge;
