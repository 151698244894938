import { Flex, Text, TextProps } from '@chakra-ui/react';
import { partition } from 'lodash';
import React, { useContext } from 'react';

import ObjectFieldsHeaderRow from 'components/CompareScenariosModalContent/ObjectFieldsHeaderRow';
import ObjectFieldTimeSeriesRows from 'components/ObjectFieldTimeSeriesRows/ObjectFieldTimeSeriesRows';
import { StickyContext } from 'components/StickyHeader/StickyContext';
import VariableSizeVirtualizedVerticalList from 'components/VirtualizedList/VariableSizeVirtualizedVerticalList';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import {
  ObjectAddOrDeleteDiff,
  ObjectDiff,
  ObjectRenameDiff,
} from 'selectors/scenarioComparisonSelector';

interface Props {
  objectId: BusinessObjectId;
  objectName: string;
  diffs: ObjectDiff[];
}

const ESTIMATED_ROW_HEIGHT = 100;
const ObjectComparisonGrid: React.FC<Props> = ({ objectName, diffs }) => {
  const [objectDiffs, fieldDiffs] = partition(diffs, (diff) => 'diffType' in diff);

  const objectDiff = objectDiffs[0];
  const { verticalScrollingTargetRef } = useContext(StickyContext);

  return (
    <Flex flexDirection="column" rowGap={4}>
      <ObjectName objectName={objectName} objectDiff={objectDiff} />
      <Flex flexDirection="column" data-testid="object-comparison-grid" overflowX="auto">
        {fieldDiffs.length > 0 && (
          <>
            <ObjectFieldsHeaderRow />
            <VariableSizeVirtualizedVerticalList
              estimatedItemHeight={ESTIMATED_ROW_HEIGHT}
              verticalScrollTargetRef={verticalScrollingTargetRef}
            >
              {fieldDiffs.map(({ objectId, fieldSpecId, isStartField }, idx) => {
                return (
                  <ObjectFieldTimeSeriesRows
                    key={`${objectId}/${fieldSpecId}`}
                    businessObjectId={objectId}
                    fieldSpecId={fieldSpecId}
                    isStartField={isStartField}
                    isFirstRow={idx === 0}
                    isLastRow={idx === fieldDiffs.length - 1}
                  />
                );
              })}
            </VariableSizeVirtualizedVerticalList>
          </>
        )}
      </Flex>
    </Flex>
  );
};

const ObjectName = React.memo(
  ({
    objectName,
    objectDiff,
  }: {
    objectName: string;
    objectDiff: ObjectAddOrDeleteDiff | ObjectRenameDiff | undefined;
  }) => {
    return (
      <Flex>
        <ObjectNameText bgColor="gray.200" borderRadius="md">
          {objectName}
        </ObjectNameText>
        {objectDiff != null ? (
          objectDiff.diffType === 'rename' ? (
            <>
              <ObjectNameText>{'->'}</ObjectNameText>
              <ObjectNameText bgColor="gray.200" borderRadius="md">
                {objectDiff.newName}
              </ObjectNameText>
            </>
          ) : objectDiff.diffType === 'add' ? (
            <ObjectNameText>Added</ObjectNameText>
          ) : (
            <ObjectNameText color="red.500">
              <i>Deleted</i>
            </ObjectNameText>
          )
        ) : null}
      </Flex>
    );
  },
);

const ObjectNameText: React.FC<TextProps> = React.memo(({ children, ...rest }) => {
  return (
    <Text
      noOfLines={1}
      fontSize="xs"
      fontWeight="medium"
      px={2}
      py={1}
      display="inline-block"
      {...rest}
    >
      {children}
    </Text>
  );
});

export default React.memo(ObjectComparisonGrid);
