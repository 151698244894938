import { Flex, Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import ContextMenuItems from 'components/ContextMenuItems/ContextMenuItems';
import DragHandleButton from 'components/DragHandleButton/DragHandleButton';
import { ROW_HOVER_VISIBLE_STYLES } from 'config/styles';
import theme from 'config/theme';
import useBlockContext from 'hooks/useBlockContext';
import useControlledPopover from 'hooks/useControlledPopover';

import styles from './RowOptions.module.scss';

interface Props {
  onOpen?: () => void;
  onDrag?: () => void;
  items?: ContextMenuItem[];
  ariaLabel: string;
  dragHandleRef?: React.ForwardedRef<HTMLButtonElement>;
  draggable?: boolean;
  children?: JSX.Element;
}

const RowOptions: React.FC<Props> = (props) => {
  const { items, ariaLabel, dragHandleRef, draggable, children } = props;
  const onOpenProp = props.onOpen;
  const { gutterWidthInPxString, gutterWidthInPx, readOnly } = useBlockContext();
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();
  const canOpen = !readOnly && ((items != null && items.length > 0) || children != null);

  const onOpenCallback = useCallback(() => {
    if (!canOpen) {
      return;
    }
    onOpen();
    onOpenProp?.();
  }, [canOpen, onOpen, onOpenProp]);

  if (gutterWidthInPx === 0) {
    return null;
  }

  return (
    <div className={styles.rowOptions} style={{ minWidth: gutterWidthInPxString }}>
      <Popover
        isLazy
        placement="bottom-start"
        returnFocusOnClose={false}
        autoFocus={false}
        closeOnBlur
        isOpen={isOpen}
        onOpen={onOpenCallback}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Flex
            ref={triggerRef}
            alignItems="center"
            sx={canOpen ? ROW_HOVER_VISIBLE_STYLES : undefined}
            visibility={isOpen ? 'visible' : 'hidden'}
            zIndex={theme.zIndices.overlay + 1}
            data-testid="row-options-trigger"
          >
            <DragHandleButton ref={dragHandleRef} aria-label={ariaLabel} draggable={draggable} />
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent ref={contentRef} padding={0} boxShadow="none">
            {items != null ? <ContextMenuItems items={items} closeMenu={onClose} /> : children}
          </PopoverContent>
        </Portal>
      </Popover>
    </div>
  );
};

export default React.memo(RowOptions);
