import { FocusLock } from '@chakra-ui/focus-lock';
import React, { useCallback, useMemo } from 'react';

import ContextMenuItem, {
  ContextMenuSelectItem,
  ContextMenuItem as Item,
} from 'components/ContextMenuItems/ContextMenuItem';
import { resolveIsShown } from 'components/PageActionsMenu/helpers';
import SelectMenu, { Section } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';

interface Props {
  items: Item[];
  sections?: Section[];
  closeMenu: () => void;
  menuHeader?: React.ReactNode;
}

const ContextMenuItems = React.forwardRef<HTMLDivElement, Props>(
  ({ items, closeMenu, sections, menuHeader }, ref) => {
    const selectMenuItems = useMemo<ContextMenuSelectItem[]>(() => {
      return items.filter(resolveIsShown).map((item) => ({
        id: item.text,
        name: item.text,
        meta: item.meta,
        isChecked: item.isChecked,
        checkedStyle: item.checkedStyle,
        icon: item.icon,
        hasNextMenu: item.submenu != null,
        shortcut: item.shortcut,
        onSelect: item.onSelect,
        submenu: item.submenu,
        sectionId: item.sectionId,
        isDisabled: item.isDisabled,
        keepOpenOnSelect: item.keepOpenOnSelect,
      }));
    }, [items]);

    const onSelect = useCallback(
      (item: ContextMenuSelectItem, ev: React.MouseEvent | KeyboardEvent) => {
        if (item.onSelect) {
          item.onSelect(ev);

          if (!item.keepOpenOnSelect) {
            closeMenu();
          }
        }
      },
      [closeMenu],
    );

    return (
      <FocusLock autoFocus>
        <SelectMenuContainer ref={ref} testId="context-menu">
          {menuHeader}
          <SelectMenu
            items={selectMenuItems}
            startFocusIdx={-1}
            sections={sections}
            onSelect={onSelect}
            onClose={closeMenu}
            width="fit-content"
            minWidth="10rem"
            maxHeight="30rem"
          >
            {({ item, idx, isFocused }) => (
              <ContextMenuItem key={item.id} item={item} idx={idx} isFocused={isFocused} />
            )}
          </SelectMenu>
        </SelectMenuContainer>
      </FocusLock>
    );
  },
);

export default ContextMenuItems;
