import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Message = { message: string; severity: 'warning' | 'error' };
type AgToastState = {
  messages: Message[];
};

const initialState: AgToastState = {
  messages: [],
};

const agToastSlice = createSlice({
  name: 'agToast',
  initialState,
  reducers: {
    pushToast: (state, action: PayloadAction<Message>) => {
      const message = action.payload;
      state.messages = [...state.messages, message];
    },
    popToast: (state) => {
      const [_, ...messages] = state.messages;
      state.messages = messages;
    },
  },
});

export const { pushToast, popToast } = agToastSlice.actions;

export default agToastSlice.reducer;
