import { Box, Button, Flex, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useCallback, useEffect, useRef } from 'react';
import { useKey } from 'react-use';

import { preventEventDefault } from 'helpers/browserEvent';
import { pickRandomEmoji } from 'helpers/emoji';

type EmojiPickerOnChangeHandler = (emoji: string | null, opts?: { isExplicit?: boolean }) => void;

interface Props {
  onChange: EmojiPickerOnChangeHandler;
  onEscape: () => void;
  selectedEmoji?: string | null;
}

const EmojiPicker: React.FC<Props> = ({ onChange, onEscape, selectedEmoji }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const selectRandomEmoji = useCallback(() => {
    const newEmoji = pickRandomEmoji(selectedEmoji ?? null);
    onChange(newEmoji, { isExplicit: false });
  }, [onChange, selectedEmoji]);

  const isDark = useColorModeValue(false, true);

  const onEmojiSelect = useCallback(
    (pickedEmoji: EmojiData) => {
      onChange(pickedEmoji.native, { isExplicit: true });
    },
    [onChange],
  );

  const removeEmoji = useCallback(() => {
    onChange(null, { isExplicit: true });
  }, [onChange]);

  useKey('Escape', onEscape);

  // N.B. have to do some chicanery here to get around the fact that the emoji-mart emoji picker
  // uses the shadow DOM, which conceals events
  useEffect(() => {
    let searchInput: HTMLInputElement | undefined;
    function handler(ev: KeyboardEvent) {
      if (ev.key === 'Escape') {
        preventEventDefault(ev);
        onEscape();
      }
    }

    // required to let the emoji picker render
    const timeout = setTimeout(() => {
      const emojiPicker = containerRef.current?.querySelector('em-emoji-picker');
      searchInput = emojiPicker?.shadowRoot?.querySelector('input[type="search"]') ?? undefined;
      searchInput?.focus();
      searchInput?.addEventListener('keydown', handler, { capture: true });
    }, 0);

    return () => {
      clearTimeout(timeout);
      searchInput?.removeEventListener('keydown', handler);
    };
  }, [onEscape, containerRef]);

  return (
    <Box
      ref={containerRef}
      bgColor="surface"
      borderWidth="px"
      borderColor="gray.300"
      borderRadius="base"
      overflow="hidden"
    >
      <Flex py={2} px={4} alignItems="center">
        <Text fontWeight="bold">Emoji</Text>
        <Spacer />
        <Flex columnGap={2}>
          <Button variant="light" size="xs" fontSize="xxs" onClick={selectRandomEmoji}>
            Random
          </Button>
          <Button variant="light" size="xs" fontSize="xxs" onClick={removeEmoji}>
            Remove
          </Button>
        </Flex>
      </Flex>
      <Picker
        data={data}
        onEmojiSelect={onEmojiSelect}
        theme={isDark ? 'dark' : 'light'}
        emojiSize={16}
        emojiButtonSize={24}
        autoFocus
        previewPosition="none"
      />
    </Box>
  );
};

export default EmojiPicker;
