import { createSelector } from 'reselect';

import { LayerId } from 'reduxStore/models/layers';
import { PageType } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { pageIdWithSubmodelsSelector } from 'selectors/blocksPagesSelector';
import { currentUserPageAccessibleLayerIdsSelector } from 'selectors/layerAccessResourcesSelector';
import { layersSelector } from 'selectors/layerSelector';
import { ParametricSelector } from 'types/redux';

// Accessible layers not scoped to a page
export const accessibleLayerIdsSelector = createSelector(
  layersSelector,
  accessCapabilitiesSelector,
  (layersById, accessCapabilities) =>
    Object.values(layersById)
      .filter((layer) => !layer.isDeleted && accessCapabilities.canReadLayerGlobally(layer.id))
      .map((layer) => layer.id),
);

export const accessibleLayersIdsForPageTypeSelector: ParametricSelector<
  { pageType: PageType | undefined; pageId: string | undefined },
  LayerId[]
> = (state: RootState, { pageType, pageId }) => {
  // pageId can either be a page or a submodel
  const accessPageId =
    pageType == null || pageId == null || pageType === 'empty'
      ? null
      : pageIdWithSubmodelsSelector(state, {
          type: pageType,
          id: pageId,
        });
  const allActiveLayerIds =
    accessPageId != null
      ? currentUserPageAccessibleLayerIdsSelector(state, accessPageId)
      : accessibleLayerIdsSelector(state);

  return allActiveLayerIds;
};
