import { useMemo } from 'react';

import { DriverChartDatum } from 'config/driverChart';
import { getX, getY } from 'helpers/chart';
import useChartContext from 'hooks/useChartContext';

export default function useChartLine() {
  const { valueScale, timeScale } = useChartContext();
  const { xAccessor, yAccessor, definedAccessor } = useMemo(() => {
    return {
      xAccessor: (d: DriverChartDatum) => timeScale(getX(d)),
      yAccessor: (d: DriverChartDatum) => valueScale(getY(d)),
      definedAccessor: (d: DriverChartDatum) => getY(d) != null,
    };
  }, [valueScale, timeScale]);

  return { xAccessor, yAccessor, definedAccessor };
}
