import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

import {
  DatabaseDbSource,
  DatabaseSource,
} from 'components/DatabasePageContent/DatabaseConfigurationEditor/DatabaseConfigProvider';
import { DatabaseConfig, DatabaseConfigSourceType } from 'generated/graphql';
import { extractEmoji } from 'helpers/emoji';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { linkedAccountsSelector } from 'selectors/integrationsSelector';
import { currentLayerSelector, defaultLayerSelector } from 'selectors/layerSelector';

export type DatabaseConfigId = string;

export const databaseConfigByIdSelector = createSelector(
  currentLayerSelector,
  (layer) => layer.databaseConfigs.byId,
);

export const databaseConfigSelector = createSelector(
  [
    currentLayerSelector,
    (state, databaseConfigId: DatabaseConfigId | undefined) => databaseConfigId,
  ],
  (layer, databaseConfigId) => {
    if (databaseConfigId == null) {
      return null;
    }
    return layer.databaseConfigs.byId[databaseConfigId] ?? null;
  },
);

export const databaseConfigSourcesSelector = createSelector(
  [currentLayerSelector, defaultLayerSelector, linkedAccountsSelector],
  (layer, defaultLayer, linkedAccounts) => {
    const linkedAccountsById = keyBy(linkedAccounts, ({ id }) => id);

    const extTables = Object.values(defaultLayer.extTables.byKey);
    const businessObjectSpecsById = layer.businessObjectSpecs.byId;
    const queriesById = defaultLayer.integrationQueries.byId;

    const extSources = extTables.flatMap((table) => {
      if (table.queryId == null) {
        return [];
      }

      const query = queriesById[table.queryId];
      if (query == null) {
        return [];
      }

      const linkedAccount =
        query.linkedAccountId != null ? linkedAccountsById[query.linkedAccountId] : undefined;
      const item: DatabaseSource = {
        id: table.sourceKey,
        name: query.name,
        sourceName: linkedAccount?.alias ?? linkedAccount?.integration?.name ?? null,
        key: `${linkedAccount?.alias ?? linkedAccount?.integration?.name ?? ''} - ${query.name}`,
        type: DatabaseConfigSourceType.ExtTable,
        table: {
          sourceKey: table.sourceKey,
          schema: table.schema,
        },
        query: {
          id: query.id,
          source: query.source,
          name: query.name,
        },
        ...(linkedAccount != null && {
          integration: {
            alias: linkedAccount.alias,
            name: linkedAccount.integration.name,
            accountId: linkedAccount.id,
          },
        }),
      };
      return item;
    });

    const dbSources: DatabaseDbSource[] = Object.values(businessObjectSpecsById).map(
      (businessSpec) => {
        const [emoji, label] = extractEmoji(businessSpec.name);
        return {
          id: businessSpec.id,
          name: label,
          emoji,
          key: businessSpec.id,
          type: DatabaseConfigSourceType.Database,
          dimensionalColumns: businessSpec?.collection?.dimensionalProperties ?? [],
          metadataColumns: businessSpec?.collection?.dimensionalProperties ?? [],
          driverColumns: businessSpec?.collection?.driverProperties ?? [],
        };
      },
    );

    extSources.sort((first, second) => {
      return first.key.localeCompare(second.key);
    });

    dbSources.sort((first, second) => {
      return first.key.localeCompare(second.key);
    });

    return [...dbSources, ...extSources];
  },
);

export const databaseConfigBySpecIdSelector = createSelector(currentLayerSelector, (layer) => {
  return Object.values(layer.databaseConfigs.byId).reduce<
    Record<BusinessObjectSpecId, DatabaseConfig>
  >((acc, config) => {
    acc[config.businessObjectSpecId] = config;
    return acc;
  }, {});
});

export const databaseConfigForSpecSelector = createSelector(
  [databaseConfigBySpecIdSelector, (_state, specId: string) => specId],
  (databaseConfigsBySpecId, specId) => {
    return databaseConfigsBySpecId[specId];
  },
);
