import { ArrowUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Spacer,
  Spinner,
  Textarea,
  useBoolean,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable, { DraggableBounds } from 'react-draggable';
import ResizeTextarea from 'react-textarea-autosize';

import { Tooltip } from 'chakra/tooltip';
import CopilotAssistant from 'components/CopilotAssistant/CopilotAssistant';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { triggerCopilotRequest } from 'reduxStore/actions/copilot';
import {
  revertEphemeralMutations,
  submitEphemeralMutations,
  undoLatest,
} from 'reduxStore/actions/submitDatasetMutation';
import { CopilotOperation, CopilotRequestStatus } from 'reduxStore/reducers/pageSlice';
import {
  copilotActionMessageSelector,
  copilotCursorSelector,
  copilotOperationSelector,
  copilotRequestStatusSelector,
} from 'selectors/copilotSelector';
import ArrowClockwiseIcon from 'vectors/ArrowClockwise';
import ArrowIcon from 'vectors/Arrowhead';
import Branches from 'vectors/Branches';
import CheckIcon from 'vectors/Check';
import CloseIcon from 'vectors/Close';
import TimelineIcon from 'vectors/Timeline';

const COPILOT_MENU_MARGIN = {
  bottom: 8,
  left: 20,
  right: 20,
  top: 40,
};

function getOperationLabel(operation: CopilotOperation | null) {
  switch (operation) {
    case CopilotOperation.GroupEvents:
      return 'Group selected plans';
    case CopilotOperation.GroupDrivers:
      return 'Group selected drivers';
    case CopilotOperation.PlanScenario:
      return 'Plan scenario';
    default:
      return 'Unknown';
  }
}

function userInputPlaceholder(
  operation: CopilotOperation | null,
  copilotStatus: CopilotRequestStatus | null,
): string | undefined {
  switch (operation) {
    case CopilotOperation.GroupEvents:
      return 'Refine grouping…';
    case CopilotOperation.GroupDrivers:
      return 'Refine grouping…';
    case CopilotOperation.PlanScenario:
      return copilotStatus === 'ask-user'
        ? 'Describe the scenario you want me to create…'
        : 'Refine scenario…';
    default:
      return 'Unknown';
  }
}

function iconForCopilotOperation(operation: CopilotOperation | null) {
  return operation === CopilotOperation.PlanScenario ? (
    <Branches boxSize={3} />
  ) : (
    <TimelineIcon boxSize={3} />
  );
}

const CopilotMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const copilotStatus = useAppSelector(copilotRequestStatusSelector);
  const isQuerying = copilotStatus === 'pending';
  const isAskingUser = copilotStatus === 'ask-user';
  const actionMessage = useAppSelector(copilotActionMessageSelector);
  const operation = useAppSelector(copilotOperationSelector);
  const dropdownLabel = getOperationLabel(operation);

  const containerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const cursor = useAppSelector(copilotCursorSelector);
  const [isDragging, setIsDragging] = useBoolean(false);
  const [bounds, setBounds] = useState<DraggableBounds | undefined>();
  const [requestedRefinement, setRequestedRefinement] = useState<string>('');
  const lastRefinement = useRef<string | null>(null);

  const submitChanges = useCallback(() => {
    dispatch(submitEphemeralMutations());
  }, [dispatch]);

  const onClickOutside = useCallback(() => {
    switch (copilotStatus) {
      case 'error': {
        dispatch(revertEphemeralMutations());
        break;
      }
      case 'success': {
        submitChanges();
        break;
      }
      case 'pending':
      default: {
        break;
      }
    }
  }, [dispatch, copilotStatus, submitChanges]);

  useOnClickOutside(containerRef, onClickOutside);

  const focusTextArea = useCallback(() => {
    textAreaRef.current?.focus();
  }, []);

  const setDragBounds = useCallback(() => {
    const boundingBox = containerRef.current?.getBoundingClientRect();
    if (boundingBox == null) {
      return;
    }

    // the general idea here is to constrain the menu to the window
    // see https://github.com/react-grid-layout/react-draggable/blob/b127397202da57a112bb1227ff26043de71bbd33/lib/utils/positionFns.js#L9
    // for the logic for setting these
    const { left, bottom, width, top } = boundingBox;
    const spaceToLeft = left;
    const spaceToRight = window.innerWidth - left - width;
    const spaceAbove = top;
    const spaceBelow = window.innerHeight - bottom;
    setBounds({
      left: -(spaceToLeft - COPILOT_MENU_MARGIN.left),
      right: spaceToRight - COPILOT_MENU_MARGIN.right,
      top: -(spaceAbove - COPILOT_MENU_MARGIN.top),
      bottom: spaceBelow - COPILOT_MENU_MARGIN.bottom,
    });
  }, []);

  useEffect(() => {
    if (!isQuerying) {
      focusTextArea();
      setDragBounds();
    }
  }, [isQuerying, setDragBounds, focusTextArea]);

  const onDragEnd = useCallback(() => {
    focusTextArea();
    setIsDragging.off();
  }, [setIsDragging, focusTextArea]);

  const onChangeRefinement = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((ev) => {
    setRequestedRefinement(ev.currentTarget.value);
  }, []);

  useEffect(() => {
    setDragBounds();
  }, [setDragBounds]);

  const isRefinementEnabled =
    requestedRefinement.length > 0 && lastRefinement.current !== requestedRefinement;

  const onSubmitRefinement = useCallback(() => {
    if (isRefinementEnabled) {
      lastRefinement.current = requestedRefinement;
      dispatch(triggerCopilotRequest({ refinement: requestedRefinement }));
      setRequestedRefinement('');
    }
  }, [dispatch, isRefinementEnabled, requestedRefinement]);

  const onKeyDown = useCallback<React.KeyboardEventHandler<HTMLTextAreaElement>>(
    (ev) => {
      const refinement = ev.currentTarget.value;
      // if the input is empty, enable Cmd/Ctrl+Z to undo out of the copilot session
      if (refinement.length === 0 && ev.key === 'z' && (ev.metaKey || ev.ctrlKey) && !ev.shiftKey) {
        ev.preventDefault();
        dispatch(undoLatest());
      } else if (ev.key === 'Enter') {
        onSubmitRefinement();
      }
    },
    [dispatch, onSubmitRefinement],
  );

  const regenerateResponse = useCallback(() => {
    dispatch(triggerCopilotRequest({ rerun: true }));
  }, [dispatch]);

  const hideTextArea = isQuerying && (cursor == null || requestedRefinement.length === 0);

  const endCopilotSession = useCallback(() => {
    dispatch(revertEphemeralMutations());
  }, [dispatch]);

  return (
    <Box ref={containerRef}>
      <Draggable onStart={setIsDragging.on} onStop={onDragEnd} bounds={bounds}>
        <Flex
          flexDirection="column"
          pointerEvents="auto"
          rowGap={2}
          bgColor="surface"
          px={4}
          py={2}
          width="32rem"
          borderRadius="md"
          boxShadow="menu"
        >
          <Flex
            cursor={isDragging ? 'grabbing' : 'grab'}
            className="dragHandle"
            w="full"
            alignItems="center"
            columnGap={2}
          >
            <CopilotAssistant />
            <Button
              size="sm"
              variant="light"
              leftIcon={iconForCopilotOperation(operation)}
              iconSpacing={2}
              isDisabled
              rightIcon={<ArrowIcon boxSize={2} direction="down" />}
            >
              {dropdownLabel}
            </Button>
            <Spacer />
            <IconButton
              variant="iconText"
              size="sm"
              padding={1}
              minWidth="unset"
              onClick={endCopilotSession}
              aria-label="Close copilot"
              icon={<CloseIcon />}
            />
          </Flex>
          {(isQuerying || actionMessage != null) && (
            <Flex
              bgColor="purple.100"
              fontSize="xs"
              fontWeight="medium"
              borderRadius="md"
              padding={2}
              columnGap={2}
              alignItems="center"
            >
              <Box flex={1}>{isQuerying ? 'Okay, let me think for a minute…' : actionMessage}</Box>
              {isQuerying && <Spinner size="sm" color="purple.500" />}
              {!isQuerying && actionMessage != null && (
                <Tooltip hasArrow placement="top" label="Regenerate response">
                  <IconButton
                    variant="iconText"
                    size="sm"
                    padding={1}
                    minWidth="unset"
                    color="purple.500"
                    _hover={{ bgColor: 'purple.50' }}
                    onClick={regenerateResponse}
                    aria-label="Regenerate response"
                    icon={<ArrowClockwiseIcon />}
                  />
                </Tooltip>
              )}
            </Flex>
          )}
          {!hideTextArea && (
            <Textarea
              as={ResizeTextarea}
              ref={textAreaRef}
              resize="none"
              minRows={1}
              maxRows={8}
              minH="unset"
              fontSize="xs"
              fontWeight="medium"
              overflow="hidden"
              variant="unstyled"
              isDisabled={isQuerying}
              _disabled={{ opacity: 1, cursor: 'default' }}
              onKeyDown={onKeyDown}
              border="none"
              value={requestedRefinement}
              onChange={onChangeRefinement}
              placeholder={userInputPlaceholder(operation, copilotStatus)}
            />
          )}
          <Flex columnGap={2} alignItems="center">
            <Spacer />
            {!isAskingUser && (
              <Button
                variant="light"
                size="sm"
                onClick={endCopilotSession}
                leftIcon={<CloseIcon />}
              >
                {isQuerying ? 'Cancel' : 'Discard'}
              </Button>
            )}
            {!isQuerying && !isAskingUser && (
              <Button
                size="sm"
                variant="accent"
                onClick={submitChanges}
                leftIcon={<CheckIcon boxSize={2} />}
              >
                Save
              </Button>
            )}
            {isAskingUser && (
              <IconButton
                aria-label="Ask Copilot"
                size="sm"
                variant="accent"
                onClick={onSubmitRefinement}
                icon={<ArrowUpIcon boxSize={4} />}
              />
            )}
          </Flex>
        </Flex>
      </Draggable>
    </Box>
  );
};

export default CopilotMenu;
