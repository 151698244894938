import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'LookerIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <path
        d="M29.8727 0.0254563C28.9224 0.0258708 27.9901 0.2846 27.1755 0.773971C26.3609 1.26334 25.6947 1.96494 25.248 2.80374C24.8014 3.64253 24.5911 4.58696 24.6398 5.53601C24.6885 6.48505 24.9942 7.40302 25.5243 8.19172L27.7633 5.95595C27.6893 5.73292 27.6519 5.49936 27.6527 5.26437C27.6527 4.82717 27.7824 4.39979 28.0253 4.03628C28.2682 3.67276 28.6134 3.38943 29.0173 3.22212C29.4212 3.05482 29.8657 3.01104 30.2945 3.09633C30.7233 3.18163 31.1172 3.39216 31.4263 3.7013C31.7355 4.01045 31.946 4.40433 32.0313 4.83312C32.1166 5.26192 32.0728 5.70638 31.9055 6.1103C31.7382 6.51422 31.4549 6.85945 31.0913 7.10235C30.7278 7.34524 30.3004 7.47489 29.8632 7.47489C29.6293 7.4754 29.3968 7.43808 29.1748 7.36436L26.9391 9.60014C27.6176 10.0603 28.3947 10.3549 29.2078 10.46C30.0209 10.5652 30.8474 10.478 31.6207 10.2055C32.394 9.93308 33.0925 9.48293 33.6601 8.89129C34.2277 8.29965 34.6486 7.58305 34.8888 6.79914C35.129 6.01522 35.1818 5.18587 35.0431 4.37781C34.9043 3.56974 34.5778 2.80552 34.0899 2.14662C33.602 1.48773 32.9662 0.952546 32.2338 0.584134C31.5013 0.215722 30.6926 0.0243652 29.8727 0.0254563Z"
        fill="#34A853"
      />
      <path
        d="M27.7886 15.2938C27.792 13.5241 27.2189 11.8014 26.156 10.3864L23.2507 13.2885C23.6925 14.0966 23.8541 15.0283 23.7101 15.9379C23.5661 16.8475 23.1247 17.6837 22.455 18.3159L24.0339 22.1748C25.1852 21.4333 26.1322 20.4149 26.7881 19.2128C27.4441 18.0106 27.7881 16.6632 27.7886 15.2938Z"
        fill="#FBBC04"
      />
      <path
        d="M19.6696 19.459H19.5938C18.9442 19.4591 18.3036 19.3073 17.7231 19.0158C17.1425 18.7243 16.6382 18.3012 16.2502 17.7802C15.8622 17.2592 15.6014 16.6547 15.4885 16.015C15.3756 15.3753 15.4138 14.718 15.6 14.0957C15.7862 13.4734 16.1153 12.9032 16.561 12.4306C17.0067 11.958 17.5567 11.5961 18.1671 11.3739C18.7775 11.1516 19.4313 11.075 20.0766 11.1503C20.7218 11.2256 21.3405 11.4506 21.8833 11.8075L24.7601 8.93064C23.742 8.10522 22.5406 7.53616 21.2569 7.27127C19.9733 7.00637 18.6448 7.05338 17.3831 7.40833C16.1214 7.76329 14.9632 8.41583 14.006 9.31115C13.0487 10.2065 12.3203 11.3184 11.8818 12.5536C11.4434 13.7888 11.3077 15.1112 11.4863 16.4097C11.6648 17.7082 12.1524 18.9448 12.9079 20.0159C13.6635 21.0869 14.6651 21.9609 15.8284 22.5646C16.9918 23.1683 18.2831 23.4841 19.5938 23.4853C20.1495 23.4856 20.7038 23.4306 21.2485 23.3211L19.6696 19.459Z"
        fill="#EA4335"
      />
      <path
        d="M29.9833 22.7969C28.165 22.795 26.3563 23.06 24.6149 23.5832L26.917 29.2074C27.9191 28.959 28.9477 28.8339 29.9801 28.8347C32.8811 28.8356 35.6921 29.8423 37.9342 31.6832C40.1763 33.5242 41.7107 36.0856 42.276 38.931C42.8413 41.7765 42.4026 44.7299 41.0345 47.2881C39.6665 49.8463 37.4538 51.8511 34.7734 52.9608C32.093 54.0706 29.1108 54.2166 26.3348 53.3742C23.5588 52.5317 21.1607 50.7528 19.5492 48.3405C17.9377 45.9282 17.2125 43.0318 17.497 40.1448C17.7815 37.2577 19.0582 34.5586 21.1096 32.5074C21.9923 31.6274 23 30.8826 24.1001 30.2968L21.8233 24.6885C17.7237 26.6962 14.5062 30.1421 12.7837 34.3695C11.0613 38.5969 10.9545 43.3102 12.4835 47.6113C14.0126 51.9124 17.0707 55.5006 21.075 57.6921C25.0794 59.8836 29.7501 60.5252 34.197 59.4946C38.644 58.464 42.5563 55.8333 45.1885 52.1038C47.8207 48.3743 48.9887 43.8067 48.4699 39.2715C47.9512 34.7362 45.7819 30.5504 42.3756 27.5116C38.9692 24.4728 34.5639 22.7935 29.9991 22.7937L29.9833 22.7969Z"
        fill="#4285F4"
      />
    </>
  ),
});
