import {
  Action,
  createSlice,
  CreateSliceOptions,
  Reducer,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export type ExtractState<R> = R extends Reducer<infer S, any> ? S : never;

export function rootReducerCreator<S>() {
  return <CR extends SliceCaseReducers<S>>(
    name: string,
    reducers: CreateSliceOptions<S, CR, string>['reducers'],
  ) => {
    return createSlice({
      name,
      reducers,
      initialState: null as never as S,
    });
  };
}

export function mergeRootReducers<S, A extends Action<any>>(
  roots: Array<Reducer<S, A>>,
): Reducer<S, A> {
  const [first, ...rest] = roots;
  return (state: S | undefined, action: A) => {
    return rest.reduce((s, r) => r(s, action), first(state, action));
  };
}
