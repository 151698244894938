import useEnum from 'hooks/useEnum';

const EDIT_DRIVER_VIEWS = ['driverSelectMenu', 'dimensionalDriverMenu'] as const;
export type EditDriverView = (typeof EDIT_DRIVER_VIEWS)[number];

const useEditDriverView = ({ dimMenuIsDefaultOpen }: { dimMenuIsDefaultOpen: boolean }) => {
  return useEnum(
    EDIT_DRIVER_VIEWS,
    dimMenuIsDefaultOpen ? 'dimensionalDriverMenu' : 'driverSelectMenu',
  );
};

export default useEditDriverView;
