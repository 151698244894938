import React, { useContext } from 'react';

import AttributeTypeaheadMenu from 'components/AttributeTypeaheadMenu/AttributeTypeaheadMenu';
import DimensionalDriverMenu from 'components/DimensionalDriverMenu/DimensionalDriverMenu';
import DriverSelectMenu from 'components/DimensionalDriverMenu/DriverSelectMenu';
import { EditDriverView } from 'components/DimensionalDriverMenu/useEditDriverView';
import DriverBlockSelectMenu from 'components/DriverBlockSelect/DriverBlockSelectMenu';
import { DimDriverEditReactContext } from 'config/dimDriverEditContext';
import { EnumSetter } from 'hooks/useEnum';
import { DimensionId } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';

const ATTRIBUTE_TYPEAHEAD_REGEX = /^(?<driverNameQuery>.*)\[(?<attributeNameQuery>[^\]]*)$/;

export const splitAttributesFromQuery = (query: string) => {
  const matchList = query.match(ATTRIBUTE_TYPEAHEAD_REGEX);
  if (matchList == null || matchList.groups == null) {
    return { driverNameQuery: query, attributeNameQuery: undefined };
  }
  return {
    driverNameQuery: matchList.groups.driverNameQuery,
    attributeNameQuery: matchList.groups.attributeNameQuery,
  };
};

interface Props {
  onCancel: () => void;
  groupId?: DriverGroupId;
  view: EditDriverView | null;
  setView: EnumSetter<EditDriverView>;
  selectedDimensionId?: DimensionId;
  allowAddingExistingDrivers: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const CreateDimensionalDriverMenu: React.FC<Props> = ({
  setView,
  view,
  groupId,
  selectedDimensionId,
  onCancel,
  allowAddingExistingDrivers,
  inputRef,
  containerRef,
}) => {
  const { subDriverName } = useContext(DimDriverEditReactContext);
  const query = subDriverName ?? '';
  const { attributeNameQuery } = splitAttributesFromQuery(query);

  if (attributeNameQuery != null) {
    return <AttributeTypeaheadMenu />;
  }

  return view === 'driverSelectMenu' ? (
    allowAddingExistingDrivers && containerRef != null ? (
      <DriverBlockSelectMenu
        query={query}
        onClose={onCancel}
        groupId={groupId}
        setView={setView}
        containerRef={containerRef}
      />
    ) : (
      <DriverSelectMenu query={query} setView={setView} onCancel={onCancel} />
    )
  ) : (
    <DimensionalDriverMenu
      initDimensionId={selectedDimensionId}
      onCancel={onCancel}
      inputRef={inputRef}
    />
  );
};

export default CreateDimensionalDriverMenu;
