import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { timeSeriesToChartData } from 'helpers/chart';
import { getDateTimeFromMonthKey, getMonthKeysForRange } from 'helpers/dates';
import useAppSelector from 'hooks/useAppSelector';
import { DriverFormat } from 'reduxStore/models/drivers';
import { NumericTimeSeries } from 'reduxStore/models/timeSeries';
import { datasetLastActualsDateTimeSelector } from 'selectors/lastActualsSelector';
import { MonthKey } from 'types/datetime';

const useChartActualsData = (
  startDateTime: MonthKey,
  driverTimeSeries: NumericTimeSeries,
  format: DriverFormat,
  endDateTime?: MonthKey,
) => {
  // Fetch the bordering date between actuals and forecast
  const lastActualsDateTime = useAppSelector(datasetLastActualsDateTimeSelector);

  // Filter to actuals datums
  const actualsLineData = useMemo(() => {
    const monthKeysWithActualsData = getMonthKeysForRange(
      getDateTimeFromMonthKey(startDateTime),
      endDateTime == null
        ? lastActualsDateTime
        : DateTime.min(lastActualsDateTime, getDateTimeFromMonthKey(endDateTime)),
    );

    return timeSeriesToChartData(driverTimeSeries, monthKeysWithActualsData, format);
  }, [startDateTime, endDateTime, lastActualsDateTime, driverTimeSeries, format]);

  return actualsLineData;
};

export default useChartActualsData;
