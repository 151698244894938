import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'AmplitudeIcon',
  viewBox: '0 0 36 36',
  path: (
    <g fill="rgb(30,97,240)">
      <path d="M15.2319 7.77917C15.1343 7.65437 15.0303 7.58557 14.9055 7.58557C14.8159 7.59197 14.7327 7.62077 14.6559 7.66877C13.7327 8.39037 12.4767 11.4512 11.4431 15.4896L12.3599 15.496C14.1647 15.5168 16.0303 15.5376 17.8703 15.5648C17.3839 13.7184 16.9263 12.136 16.5039 10.8528C15.8847 8.98717 15.4687 8.14717 15.2319 7.77917Z" />
      <path d="M17.28 0C7.7376 0 0 7.7376 0 17.28C0 26.8224 7.7376 34.56 17.28 34.56C26.8224 34.56 34.56 26.8224 34.56 17.28C34.56 7.7376 26.8224 0 17.28 0ZM30.0352 17.072C29.9872 17.2656 29.8688 17.4544 29.7024 17.592C29.6816 17.6064 29.6608 17.6192 29.64 17.6336L29.6192 17.648L29.5776 17.6752L29.5424 17.696C29.4112 17.7648 29.264 17.8 29.112 17.8H20.9232C20.9856 18.0704 21.0624 18.3824 21.1376 18.7168C21.5888 20.6528 22.776 25.8016 24.0448 25.8016H24.072H24.0864H24.1136C25.0992 25.8016 25.6064 24.3728 26.7168 21.2416L26.7312 21.2064C26.912 20.7072 27.1136 20.1376 27.328 19.5344L27.384 19.3824C27.4672 19.1808 27.696 19.0768 27.8976 19.16C28.0432 19.216 28.1472 19.3616 28.1472 19.5216C28.1472 19.5632 28.1408 19.5984 28.1328 19.632L28.0848 19.784C27.9664 20.1584 27.8496 20.6656 27.7024 21.256C27.0432 23.9904 26.0432 28.12 23.4896 28.12H23.4688C21.8176 28.1056 20.832 25.4688 20.408 24.3376C19.6176 22.2272 19.0208 19.9856 18.4448 17.808H10.9232L9.3616 22.8112L9.3408 22.7904C9.1056 23.1584 8.6128 23.2688 8.2448 23.0336C8.016 22.888 7.8768 22.6384 7.8768 22.368V22.3408L7.9744 21.7712C8.1888 20.488 8.4528 19.1472 8.744 17.8016H5.552L5.5376 17.7872C4.8848 17.6896 4.4336 17.08 4.5312 16.4272C4.608 15.92 4.9968 15.5184 5.496 15.4272C5.6208 15.4128 5.7456 15.4064 5.8704 15.4128H6.0224C7.0288 15.4272 8.0976 15.448 9.2768 15.4608C10.936 8.7152 12.8576 5.2864 14.9952 5.28C17.2848 5.28 18.9856 10.4912 20.3456 15.592L20.352 15.6128C23.1424 15.6688 26.1264 15.752 29.0192 15.96L29.144 15.9744C29.192 15.9744 29.2336 15.9808 29.2832 15.9888H29.2976L29.312 15.9952H29.3184C29.8128 16.0928 30.1392 16.5792 30.0352 17.072Z" />
    </g>
  ),
});
