import { Box, Flex, Text } from '@chakra-ui/react';
import { Dictionary, keyBy } from 'lodash';
import { ReactNode } from 'react';

import { useComparedLayers } from '@features/CompareScenarios';
import { ConfigChange, Source } from '@features/CompareScenarios/comparators/DatabaseComparator';
import DataSourceLogo from 'components/DataSourceLogo/DataSourceLogo';
import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import { DatabaseConfigSourceType, LinkedAccount } from 'generated/graphql';
import { extractEmoji } from 'helpers/emoji';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { Layer } from 'reduxStore/models/layers';
import { linkedAccountsSelector } from 'selectors/integrationsSelector';
import { defaultLayerSelector } from 'selectors/layerSelector';

export const ConfigChangeBlock = ({ change }: { change: ConfigChange }) => {
  const { currentLayer, mergeLayer } = useComparedLayers();
  const defaultLayer = useAppSelector(defaultLayerSelector);
  const linkedAccountsById = keyBy(useAppSelector(linkedAccountsSelector), ({ id }) => id);

  const mergeSource = getConfigSourceFromLayer(
    change.updatesByField.source?.from,
    mergeLayer,
    defaultLayer,
    linkedAccountsById,
  );

  const currentSource = getConfigSourceFromLayer(
    change.updatesByField.source?.to,
    currentLayer,
    defaultLayer,
    linkedAccountsById,
  );

  return (
    <Box fontSize="xs" fontWeight="500" color="gray.600">
      {change.status === 'created' && (
        <Flex
          alignItems="center"
          gap="2"
          whiteSpace="nowrap"
          flexWrap="wrap"
          marginBottom="1"
          mt={1}
        >
          Data source is
          <SourceIcon source={currentSource} alternate="a new source" />
        </Flex>
      )}
      {change.status === 'deleted' && (
        <Box marginBottom="1">
          Data source changes
          <Flex alignItems="center" gap="1" flexWrap="wrap" marginTop="1">
            <SourceIcon source={mergeSource} alternate="a source" /> →
            <Flex
              alignItems="center"
              gap="1"
              borderRadius="md"
              backgroundColor="gray.200"
              border="1px solid"
              borderColor="gray.300"
              paddingY="2px"
              paddingX="1"
              fontWeight="medium"
            >
              None
            </Flex>
          </Flex>
        </Box>
      )}
      {change.status === 'updated' && (
        <Box marginBottom="1">
          Data source changes
          <Flex alignItems="center" gap="1" flexWrap="wrap" marginTop="1">
            <SourceIcon source={mergeSource} alternate="a source" /> →
            <SourceIcon source={currentSource} alternate="another source" />
          </Flex>
        </Box>
      )}
    </Box>
  );
};

const SourceIcon = ({
  source,
  alternate,
}: {
  source: { name: string; icon: ReactNode } | null;
  alternate: string;
}) => {
  if (source == null) {
    return <Text>{alternate}</Text>;
  }

  return (
    <Flex alignItems="center" gap="1">
      {source.icon}
      <Text fontWeight="600" whiteSpace="nowrap">
        {source.name}
      </Text>
    </Flex>
  );
};

function getConfigSourceFromLayer(
  source: Source | null | undefined,
  layer: Layer,
  defaultLayer: Layer,
  linkedAccountsById: Dictionary<LinkedAccount>,
): { name: string; icon: ReactNode } | null {
  if (source == null) {
    return null;
  }

  const { type, id } = source;
  if (type === DatabaseConfigSourceType.Database) {
    const [emoji, name] = extractEmoji(safeObjGet(layer.businessObjectSpecs.byId[id])?.name ?? '');
    return {
      name,
      icon: <EmojiIcon emoji={emoji} size="sm" />,
    };
  } else if (type === DatabaseConfigSourceType.ExtTable) {
    const extTable = safeObjGet(defaultLayer.extTables.byKey[id]);
    if (extTable == null) {
      return null;
    }

    const query = safeObjGet(
      extTable.queryId != null ? defaultLayer.integrationQueries.byId[extTable.queryId] : undefined,
    );
    if (query == null) {
      return null;
    }

    const account =
      query.linkedAccountId != null
        ? safeObjGet(linkedAccountsById[query.linkedAccountId])
        : undefined;
    if (account == null) {
      return {
        name: query.name,
        icon: <DataSourceLogo source={query.source} size="sm" />,
      };
    }

    return {
      name: `${account.alias ?? account.integration?.name}${query.name !== '' ? ` - ${query.name}` : ''}`,
      icon: <DataSourceLogo source={query.source} size="sm" />,
    };
  }
  throw new Error(`Did not expect config with source "${type}"`);
}
