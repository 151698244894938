import { Image } from '@chakra-ui/react';
import React from 'react';

import useAppSelector from 'hooks/useAppSelector';
import { integrationSquareImage } from 'selectors/integrationsSelector';

interface Props {
  sourceSlug: string;
}

const ExtSourceImage: React.FC<Props> = ({ sourceSlug }) => {
  const image = useAppSelector((state) => integrationSquareImage(state, sourceSlug));
  if (image == null) {
    return null;
  }

  return <Image src={image} h={4} w={4} color="gray.200" border="px" borderRadius="md" />;
};

export default React.memo(ExtSourceImage);
