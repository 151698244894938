import { compact } from 'lodash';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  BlockFilterContextProvider,
  BlockFilterContextState,
} from 'components/BlockFilterContext/BlockFilterContext';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import FormulaDropdownContext from 'components/FormulaInput/FormulaDropdownContext';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import { BlockFilterOperator, ValueType } from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { useSubmenuState } from 'hooks/useSubmenuState';
import { Attribute, Dimension } from 'reduxStore/models/dimensions';
import {
  dimensionalDriversByIdSelector,
  dimensionalDriversBySubDriverIdSelector,
} from 'selectors/driversSelector';
import {
  AttributeFilterItem,
  EntityInfo,
  FilterItem,
  isAttributeFilterItem,
} from 'types/filtering';
import {
  ANY_ATTR,
  CONTEXT_ATTR,
  DriverEntityData,
  DynamicAttributeFilterOption,
  NO_ATTR,
  getEmptyAttributeFilters,
} from 'types/formula';

interface Props {
  entity: DriverEntityData;
}

const EMPTY_ARRAY: Dimension[] = [];
const EMPTY_ATTRIBUTE_FILTERS = getEmptyAttributeFilters();

type SubmenuType = 'property' | 'filters';

const DEFAULT_MENU = 'filters';

const DriverEntityDropdownMenu: React.FC<Props> = ({ entity }) => {
  const activeEntityKey = entity.data.id;
  const { resetSelectionState } = useContext(FormulaSelectionContext);

  const { submenu, setSubmenu, clearSubmenu } = useSubmenuState<SubmenuType>(DEFAULT_MENU);

  useEffect(() => {
    setSubmenu(DEFAULT_MENU);
  }, [setSubmenu, activeEntityKey]);

  const dimensionalDriversBySubDriver = useAppSelector(dimensionalDriversBySubDriverIdSelector);
  const dimensionalDrivers = useAppSelector(dimensionalDriversByIdSelector);
  const { onSelectSubDriverAttributes } = useContext(FormulaDropdownContext);

  const dimensionalDriver =
    dimensionalDrivers[activeEntityKey] ?? dimensionalDriversBySubDriver[activeEntityKey];

  const dimensions = sortBy(
    dimensionalDriver != null ? dimensionalDriver.dimensions : EMPTY_ARRAY,
    'deleted',
  ).reverse();
  const dimsById = keyBy(dimensions, 'id');

  const attributeFilters = entity.data?.attributeFilters ?? EMPTY_ATTRIBUTE_FILTERS;
  const attributeFiltersRef = useRef(attributeFilters);
  attributeFiltersRef.current = attributeFilters;

  const [filters, setFilters] = useState<FilterItem[] | undefined>(undefined);

  const defaultFilters = useMemo(() => {
    return compact(
      Object.keys(attributeFilters.byDimId).map((dimId) => {
        const dimension = dimsById[dimId];
        if (dimension == null) {
          return null;
        }
        const item: FilterItem = {
          dimensionId: dimId,
          filterKey: dimId,
          label: dimension.name,
          expected: attributeFilters.byDimId[dimId].filter(isNotNull).map((attr) => {
            if (attr === NO_ATTR || attr === ANY_ATTR || attr === CONTEXT_ATTR) {
              return attr;
            }
            return attr.id;
          }),
          operator: BlockFilterOperator.Equals,
          valueType: ValueType.Attribute,
        };
        return item;
      }),
    );
  }, [attributeFilters.byDimId, dimsById]);

  const filtersToUse = filters || defaultFilters;

  const dimensionAttributesById = useMemo(() => {
    return keyBy(
      dimensions.flatMap((dim) => dim.attributes ?? []),
      'id',
    );
  }, [dimensions]);

  const availableFilters: FilterItem[] = useMemo(() => {
    return (dimensionalDriver?.dimensions ?? []).map(
      (dim) =>
        ({
          id: dim.id,
          filterKey: dim.id,
          dimensionId: dim.id,
          label: dim.name,
          valueType: ValueType.Attribute,
        }) as AttributeFilterItem,
    );
  }, [dimensionalDriver]);

  const onUpdateFilters = useCallback(
    (newFilters: FilterItem[]) => {
      setFilters(newFilters);

      const updatedAttributeFilters = newFilters.reduce(
        (acc: Record<string, Array<Attribute | DynamicAttributeFilterOption>>, filter) => {
          if (!isAttributeFilterItem(filter)) {
            return acc;
          }
          const dimensionId = filter.dimensionId;
          if (dimensionId == null) {
            return acc;
          }
          const expectedDimensionAttributeIds = filter?.expected;
          if (!Array.isArray(expectedDimensionAttributeIds)) {
            return acc;
          }
          const expectedDimensionAttributes = expectedDimensionAttributeIds.map(
            (attributeId: string) => {
              if (attributeId === CONTEXT_ATTR) {
                return CONTEXT_ATTR;
              } else if (attributeId === ANY_ATTR) {
                return ANY_ATTR;
              } else if (attributeId !== NO_ATTR) {
                return dimensionAttributesById[attributeId];
              }
              return NO_ATTR;
            },
          );
          //NO_ATTRS_ID
          const dimension = dimsById[dimensionId];
          const numDimensionAttributes = dimension?.attributes?.length ?? 0;

          const allAttributesSelected =
            expectedDimensionAttributes.filter((f) => f !== NO_ATTR).length ===
            numDimensionAttributes;

          const noneAttributesSelected = expectedDimensionAttributes.some((f) => f === NO_ATTR);

          if (allAttributesSelected && noneAttributesSelected) {
            acc[dimensionId] = [ANY_ATTR, NO_ATTR];
          } else if (allAttributesSelected) {
            acc[dimensionId] = [ANY_ATTR];
          } else {
            acc[dimensionId] = expectedDimensionAttributes;
          }
          return acc;
        },
        {},
      );

      onSelectSubDriverAttributes({
        ...attributeFiltersRef.current,
        byDimId: { ...updatedAttributeFilters },
      });
    },
    [dimensionAttributesById, dimsById, onSelectSubDriverAttributes],
  );

  const toggleIncludeAllContextAttributes = useCallback(() => {
    onSelectSubDriverAttributes({
      ...attributeFiltersRef.current,
      includeAllContextAttributes: !attributeFiltersRef.current.includeAllContextAttributes,
    });
  }, [onSelectSubDriverAttributes]);

  const toggleMatchToSingleResult = useCallback(() => {
    onSelectSubDriverAttributes({
      ...attributeFiltersRef.current,
      matchToSingleResult: !attributeFiltersRef.current.matchToSingleResult,
    });
  }, [onSelectSubDriverAttributes]);

  const blockFilterContext: BlockFilterContextState<FilterItem> = useMemo(() => {
    const entityInfo: EntityInfo = { id: entity.data.id, label: entity.data.label, type: 'driver' };
    return {
      entityInfo,
      filters: filtersToUse,
      availableFilters,
      activeFilters: [],
      onUpdateFilters,
      onDoneFilters: () => {
        clearSubmenu();
        resetSelectionState();
      },
      toggleIncludeAllContextAttributes,
      toggleMatchToSingleResult,
      includeAllContextAttributes: attributeFilters.includeAllContextAttributes ?? false,
      matchToSingleResult: attributeFilters.matchToSingleResult ?? false,
    };
  }, [
    availableFilters,
    entity.data.id,
    entity.data.label,
    attributeFilters.includeAllContextAttributes,
    attributeFilters.matchToSingleResult,
    filtersToUse,
    clearSubmenu,
    onUpdateFilters,
    toggleIncludeAllContextAttributes,
    toggleMatchToSingleResult,
    resetSelectionState,
  ]);

  if (submenu !== DEFAULT_MENU) {
    return null;
  }
  return (
    <BlockFilterContextProvider value={blockFilterContext}>
      <FilterMenu onClose={clearSubmenu} />
    </BlockFilterContextProvider>
  );
};

export default DriverEntityDropdownMenu;
