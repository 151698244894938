import { CellValueStyle, TimeSeriesStyle } from 'components/AgGridComponents/CellRenderer/types';
import { DisplayValueProps } from 'components/BusinessObjectsEditableValues/DisplayValue';

export const CELL_VALUE_STYLE_TIMESERIES_STYLE: Record<CellValueStyle, TimeSeriesStyle> = {
  'actuals-hardcoded': 'actual-hardcoded',
  'actuals-formula': 'none',
  'forecast-impact': 'plan-impacted',
  'forecast-formula': 'none',
  error: 'error',
};

export const CELL_VALUE_DISPLAY_VALUE_STYLE: Record<CellValueStyle, DisplayValueProps['style']> = {
  'actuals-hardcoded': 'actuals',
  'actuals-formula': 'actuals',
  'forecast-impact': 'forecast',
  'forecast-formula': 'forecast',
  error: 'error',
};

export const HARD_CODED_ACTUAL_TOOLTIP = 'Hardcoded actual';
