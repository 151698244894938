import React from 'react';

import ReferenceMetadataPill from 'components/FormulaInput/ReferenceMetadataPill';

interface Props {
  label: string;
  onMouseDown: React.MouseEventHandler;
  isSelected: boolean;
}

const DateRangePill: React.FC<Props> = ({ label, onMouseDown, isSelected = false }) => {
  return (
    <ReferenceMetadataPill
      label={label}
      isSelected={isSelected}
      onMouseDown={onMouseDown}
      isTruncated={false}
      zIndex={4}
    />
  );
};

export default DateRangePill;
