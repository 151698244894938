import noop from 'lodash/noop';
import { createContext } from 'react';

import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import {
  AttributeFilters,
  EntityDataWithKey,
  FormulaTimeRange,
  MathOperator,
  ObjectSpecFilters,
} from 'types/formula';

export interface FormulaDropdownContextState {
  activeEntity: EntityDataWithKey | null;
  insertFormulaEntity: (
    driverId: DriverId,
    opts?: { name?: string; select?: boolean; attributeFilters?: AttributeFilters },
  ) => void;
  insertDriverGroup: (submodelId: SubmodelId, driverGroupId: DriverGroupId) => void;
  insertExtDriver: (id: ExtDriverId) => void;
  insertObjectSpec: (
    id: BusinessObjectSpecId,
    fieldId: BusinessObjectFieldSpecId | undefined,
    isThisRef: boolean,
  ) => void;
  insertExtQuery: (id: string) => void;
  onSelectTimePeriod: (input: FormulaTimeRange, options?: { closeMenu?: boolean }) => void;
  onSelectMathOperator: (operator: MathOperator) => void;
  onSelectStringLiteral: (literal: string) => void;
  onSelectDateTimeLiteral: (datetime: string) => void;
  onSelectSubDriverAttributes: (attributeFilters: AttributeFilters) => void;
  onSelectObjectFilters: (filters: ObjectSpecFilters) => void;
  onSelectRawFormulaQuery: (query: string) => void;
  onSelectObjectField: (field: { id: BusinessObjectFieldSpecId; name: string } | null) => void;
  setLastCreatedDriverId: (driverId: DriverId) => void;
  lastCreatedDriverId?: string;
  canCreateDriver?: boolean;
  onCloseMenu?: () => void;
}

export default createContext<FormulaDropdownContextState>({
  activeEntity: null,
  insertFormulaEntity: noop,
  insertDriverGroup: noop,
  insertExtDriver: noop,
  insertObjectSpec: noop,
  insertExtQuery: noop,
  onSelectTimePeriod: noop,
  onSelectMathOperator: noop,
  onSelectStringLiteral: noop,
  onSelectDateTimeLiteral: noop,
  onSelectSubDriverAttributes: noop,
  onSelectObjectFilters: noop,
  onSelectRawFormulaQuery: noop,
  onSelectObjectField: noop,
  setLastCreatedDriverId: noop,
  onCloseMenu: noop,
});
