import { Flex } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';

import ObjectFieldNameInput from 'components/ObjectFieldNameInput/ObjectFieldNameInput';
import { DatabaseTableContext } from 'config/databaseTableContext';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useSelectMenuItem } from 'hooks/useSelectMenuItem';
import {
  renameDimensionalProperty,
  renameDriverProperty,
  updateFieldName,
} from 'reduxStore/actions/businessObjectSpecMutations';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DimensionalPropertyId, DriverPropertyId } from 'reduxStore/models/collections';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { dimensionalPropertySelector, driverPropertySelector } from 'selectors/collectionSelector';
import { driverNameSelector, driverSelector } from 'selectors/driversSelector';

interface Props {
  idx: number;
  isFocused: boolean;
  onClose: () => void;
  fieldSpecId?: BusinessObjectFieldSpecId;
  dimPropertyId?: DimensionalPropertyId;
  driverPropertyId?: DriverPropertyId;
}

const DatabasePropertyNameSelectItem = React.forwardRef<HTMLInputElement, Props>(
  ({ idx, isFocused = false, onClose, fieldSpecId, dimPropertyId, driverPropertyId }, ref) => {
    const { objectSpecId } = useContext(DatabaseTableContext);
    const { onMouseLeave, onMouseEnter } = useSelectMenuItem({
      isFocused,
      idx,
    });

    const dispatch = useAppDispatch();

    const driver = useAppSelector((state) => {
      if (driverPropertyId != null) {
        const driverProperty = driverPropertySelector(state, driverPropertyId);
        if (driverProperty != null) {
          return driverSelector(state, { id: driverProperty.driverId });
        }
      }
      return null;
    });

    const onUpdate = useCallback(
      (name: string) => {
        if (fieldSpecId != null) {
          dispatch(updateFieldName({ objectSpecId, fieldSpecId, name }));
        } else if (driverPropertyId != null) {
          dispatch(renameDriverProperty(objectSpecId, driverPropertyId, name));
        } else if (dimPropertyId != null) {
          dispatch(renameDimensionalProperty(objectSpecId, dimPropertyId, name));
        }
      },
      [objectSpecId, fieldSpecId, driverPropertyId, dimPropertyId, dispatch],
    );

    const currentName = useAppSelector((state) => {
      if (fieldSpecId != null) {
        return businessObjectFieldSpecSelector(state, { id: fieldSpecId })?.name ?? '';
      } else if (driver != null) {
        return driverNameSelector(state, { id: driver.id }) ?? '';
      } else if (dimPropertyId != null) {
        return dimensionalPropertySelector(state, dimPropertyId)?.name ?? '';
      }
      return '';
    });

    return (
      <Flex margin={1} onMouseEnter={onMouseEnter} onMouseOut={onMouseLeave}>
        <ObjectFieldNameInput
          ref={ref}
          isFocused={isFocused}
          onUpdate={onUpdate}
          currentName={currentName}
          objectSpecId={objectSpecId}
          onClose={onClose}
        />
      </Flex>
    );
  },
);

DatabasePropertyNameSelectItem.displayName = 'DatabasePropertyNameSelectItem';

export default React.memo(DatabasePropertyNameSelectItem);
