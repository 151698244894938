import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import remove from 'lodash/remove';

import { BlockCreateInput, BlockDeleteInput, BlockUpdateInput } from 'generated/graphql';
import { Block } from 'reduxStore/models/blocks';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { DatasetSliceState } from 'reduxStore/reducers/datasetSlice';

export function setBlocksFromDatasetSnapshot(
  state: Draft<DatasetSliceState>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null) {
    state.blocks = { byId: {}, allIds: [] };
    return;
  }

  const { blocks } = dataset;
  const blocksList = blocks.map((gqlBlock) => {
    const block: Block = { ...gqlBlock, sortIndex: gqlBlock.sortIndex ?? undefined };
    return block;
  });

  state.blocks = {
    byId: keyBy(blocksList, 'id'),
    allIds: blocksList.map((o) => o.id),
  };
}

export function handleCreateBlock(
  state: Draft<DatasetSliceState>,
  newBlockInput: BlockCreateInput,
) {
  const { id, name, type, pageId, blockConfig, sortIndex } = newBlockInput;
  const newBlock: Block = {
    id,
    name,
    type,
    pageId,
    blockConfig,
    sortIndex: sortIndex ?? undefined,
  };
  if (state.blocks.byId[id] != null) {
    return;
  }

  state.blocks.byId[id] = newBlock;
  state.blocks.allIds.push(id);

  // Do not make a parent page required so that ephemeral mutations can create preview blocks.
  // A page is not required because the block will not be persisted.
  const page = state.blocksPages.byId[pageId];
  if (page != null && !page.blockIds.includes(id)) {
    page.blockIds.push(id);
  }
}

export function handleDeleteBlock(state: Draft<DatasetSliceState>, { id }: BlockDeleteInput) {
  const block = state.blocks.byId[id];

  if (block == null) {
    return;
  }

  delete state.blocks.byId[id];
  remove(state.blocks.allIds, (i) => i === id);

  const page = state.blocksPages.byId[block.pageId];
  if (page != null) {
    remove(page.blockIds, (i) => i === id);
  }
}

export function handleUpdateBlock(
  state: Draft<DatasetSliceState>,
  updateBlockInput: BlockUpdateInput,
) {
  const { id, name, type, blockConfig, sortIndex } = updateBlockInput;

  const block = state.blocks.byId[id];
  if (block == null) {
    return;
  }

  if (name != null) {
    block.name = name;
  }

  if (type != null) {
    block.type = type;
  }

  if (blockConfig != null) {
    block.blockConfig = blockConfig;
  }

  if (sortIndex != null) {
    block.sortIndex = sortIndex;
  }
}
