import { ModalType } from 'reduxStore/reducers/pageSlice';
import {
  DriverPasteModalState,
  ObjectPasteModalState,
  PasteType,
} from 'reduxStore/reducers/pasteModalSlice';
import { createRootReducers } from 'reduxStore/reducers/sliceReducers';

const INITIAL_COMMON_PASTE_MODAL_STATE = {
  rowIndicesToSkip: [],
  pasteData: undefined,
};

const INITIAL_DRIVER_PASTE_MODAL_STATE: DriverPasteModalState = {
  type: PasteType.Driver,
  driverColumnMap: {
    0: 'name',
  },
  showDimensionsColumn: false,
  attributesByRowIdx: {},
  ...INITIAL_COMMON_PASTE_MODAL_STATE,
};

const INITIAL_OBJECT_PASTE_MODAL_STATE: ObjectPasteModalState = {
  type: PasteType.Object,
  objectColumnMap: {
    0: { type: 'name', value: 'name' },
  },
  ...INITIAL_COMMON_PASTE_MODAL_STATE,
};

const openPasteModal = createRootReducers('openPasteModal', {
  openDriverPasteDataModal(state) {
    state.page.openModal = ModalType.SmartPaste;
    state.pasteModal = INITIAL_DRIVER_PASTE_MODAL_STATE;
  },
  openObjectPasteDataModal(state) {
    state.page.openModal = ModalType.SmartPaste;
    state.pasteModal = INITIAL_OBJECT_PASTE_MODAL_STATE;
  },
});

export const { openDriverPasteDataModal, openObjectPasteDataModal } = openPasteModal.actions;

export default openPasteModal.reducer;
