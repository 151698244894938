import { omit } from 'lodash';
import sizeof from 'object-sizeof';

import {
  BlockResponse,
  IBaseDataSource,
  IDataArbiter,
  InstanceId,
  ObjectRowsRequest,
  ObjectRowsResponse,
  RequestBlockEvalParams,
  RequestCalculationParams,
  RequestId,
} from 'components/AgGridComponents/helpers/gridDatasource/types';
import {
  CURRENT_MONTH_KEY,
  getISOTimeWithoutMsFromMonthKey,
  getMonthKey,
  getMonthKeyRange,
  getMonthsBetweenMonths,
} from 'helpers/dates';
import {
  ALL_LAYERS_KEY,
  CacheKey,
  FormulaCacheSingleton,
  getFormulaCacheKey,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { recordCalculationPerformance } from 'helpers/performanceMonitor';
import { uuidv4 } from 'helpers/uuidv4';
import {
  enqueueBlockEvaluation,
  enqueueCalculations,
  webworkerCalcBatchToBackendCalcBatch,
} from 'reduxStore/actions/calculations';
import { BlockId } from 'reduxStore/models/blocks';
import { LayerId } from 'reduxStore/models/layers';
import { MutationBatch } from 'reduxStore/models/mutations';
import { ObjectSpecEvaluation } from 'reduxStore/models/value';
import { AppDispatch } from 'reduxStore/store';
import { CalculationEngine, shouldUseBackend } from 'selectors/calculationEngineSelector';
import { ValueOrCalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';
import {
  AddToCalculationsQueueMessage,
  EvaluateBlockMessage,
  PurgeQueueMessage,
  RequestedCalculation,
  ResetCacheForLayersMessage,
  UntrackEntitiesMessage,
} from 'workers/formulaCalculator/types';

type Request = {
  datasource: IBaseDataSource;
  type: 'rows' | 'update' | 'block';
};

type EntityId = FormulaEntityTypedId['id'];
type CacheValue = {
  value: ValueOrCalculationError | undefined;
  isStale: boolean;
};
type LayerCache = Map<LayerId, Map<CacheKey, CacheValue>>;

type ObjectEvaluationsLayerCache = Map<LayerId, Map<CacheKey, ObjectSpecEvaluation[]>>;

// Get a value that we can key by which contains a combination of layer ID, entity ID,
// entity type, and the ignored event IDs. This is so we can group the requested
// date ranges by these things. Use a quick, non cryptographic hash to combine
// these things.
const DELIMITER = '.';
type RequestedDataRangeMetaKey = string;
type RequestedDataRangeMeta = Omit<RequestedCalculation, 'dateRange'>;

function getRequestedDataRangeMetaKey(data: RequestedDataRangeMeta): RequestedDataRangeMetaKey {
  const { entityId, layerId, ignoreEventIds } = data;
  const parts = [layerId, entityId.id, entityId.type, ...(ignoreEventIds ?? [])];
  return parts.join(DELIMITER);
}

export class DataArbiter implements IDataArbiter {
  private static instance: IDataArbiter;
  private datasources: Map<InstanceId, IBaseDataSource>;
  private requests: Map<RequestId, Request>;
  private keys: Map<InstanceId, Set<CacheKey>>;

  // Tracks the maximum contiguous ranges of months requested for each entity
  // per layer and ignored event IDs. This is used to determine what to
  // re-request in the case where the backend sends a ForceRefresh message.
  private requestedDataRanges: Map<
    InstanceId,
    Map<
      RequestedDataRangeMetaKey,
      { meta: RequestedDataRangeMeta; ranges: Array<[MonthKey, MonthKey]> }
    >
  >;
  private requestedBlocks: Map<RequestId, RequestBlockEvalParams>;

  // Track the instance IDs  associated with a block when we have requested
  // data from it. This way when we get a preemptive server block eval update
  // we know where to route it.
  private requestedBlockEvalInstanceIds: Map<BlockId, InstanceId>;

  private cachedValues: LayerCache;
  private cachedObjectEvaluations: ObjectEvaluationsLayerCache = new Map();
  private layerizedMonthKeysByEntityId: Map<LayerId, Map<EntityId, Set<MonthKey>>>;
  private encounteredDateRange: { start: MonthKey; end: MonthKey };
  // See: T-17767 (Clean this up when parity testing is no longer needed)
  // Backend parity testing
  private enableParityTesting: boolean;
  private beCachedValues: LayerCache;
  private dispatch: AppDispatch | undefined;

  // Values for performance measurement
  private totalRequests: number;
  private pendingRequests: number;
  private calcStartTime: number;
  private requestIds: Set<string>;

  public constructor() {
    this.datasources = new Map();
    this.requests = new Map();
    this.keys = new Map();
    this.requestedDataRanges = new Map();
    this.requestedBlocks = new Map();
    this.requestedBlockEvalInstanceIds = new Map();
    this.cachedValues = new Map();
    this.cachedObjectEvaluations = new Map();
    this.layerizedMonthKeysByEntityId = new Map();
    this.beCachedValues = new Map();
    this.enableParityTesting = false;
    this.encounteredDateRange = { start: CURRENT_MONTH_KEY, end: CURRENT_MONTH_KEY };
    this.totalRequests = 0;
    this.pendingRequests = 0;
    this.calcStartTime = performance.now();
    this.requestIds = new Set();
  }

  public purgeQueue() {
    const purgeMessage: PurgeQueueMessage = {
      type: 'purgeQueue',
    };

    window.formulaCalculator?.postMessage(purgeMessage);
  }

  public reset() {
    const resetMessage: ResetCacheForLayersMessage = {
      type: 'resetCacheForLayers',
      data: [ALL_LAYERS_KEY],
    };
    window.formulaCalculator?.postMessage(resetMessage);

    // Clear data arbiter caches
    this.requests.clear();
    this.keys.clear();
    this.cachedValues.clear();
    this.cachedObjectEvaluations.clear();
    this.layerizedMonthKeysByEntityId.clear();
    this.beCachedValues.clear();
    this.encounteredDateRange = { start: CURRENT_MONTH_KEY, end: CURRENT_MONTH_KEY };
    this.requestedDataRanges.clear();
    this.clearPerfMonitoring();
  }

  public refresh(engine: CalculationEngine): void {
    const data: Map<InstanceId, RequestedCalculation[]> = new Map();

    // Get each requested key for each data source and request it again
    for (const { instanceId } of this.datasources.values()) {
      const forInstance: RequestedCalculation[] = [];
      data.set(instanceId, forInstance);

      const requested = this.requestedDataRanges.get(instanceId)?.values();
      if (requested == null) {
        continue;
      }

      for (const { meta, ranges } of requested) {
        for (const dateRange of ranges) {
          forInstance.push({ ...meta, dateRange });
        }
      }
    }

    // Block requests must be refetched.
    const blockRequests = Array.from(this.requestedBlocks.entries()).map(
      ([requestId, request]) => ({
        instance: this.requests.get(requestId)?.datasource,
        requestId,
        request,
      }),
    );

    this.purgeQueue();
    this.reset();

    for (const [instanceId, requested] of data.entries()) {
      const requestId = uuidv4();
      this.requestCalculation({
        instanceId,
        data: requested,
        requestId,
        calculator: engine ?? 'webworker',
        dispatch: engine === 'webworker' ? undefined : this.dispatch,
      });
    }

    // Reuse the previously registered requestId because the datasource
    // will only respond to responses with that accompanying requestId.
    for (const { instance, request, requestId } of blockRequests) {
      if (instance == null) {
        continue;
      }
      this.requests.set(requestId, { datasource: instance, type: 'block' });
      this.requestBlockInternal(requestId, request);
    }
  }

  public static get(): IDataArbiter {
    if (DataArbiter.instance == null) {
      DataArbiter.instance = new this();
    }

    return DataArbiter.instance;
  }

  public setDispatch(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  public register(ds: IBaseDataSource): void {
    this.datasources.set(ds.instanceId, ds);
    this.keys.set(ds.instanceId, new Set());
    this.clearPerfMonitoring();
  }

  public unregister(ds: IBaseDataSource): void {
    this.datasources.delete(ds.instanceId);
    this.keys.delete(ds.instanceId);

    const requestIds = [];
    for (const [key, { datasource }] of this.requests.entries()) {
      if (datasource === ds) {
        requestIds.push(key);
      }
    }

    for (const requestId of requestIds) {
      this.requests.delete(requestId);
    }

    for (const [blockId, instanceId] of this.requestedBlockEvalInstanceIds.entries()) {
      if (instanceId === ds.instanceId) {
        this.requestedBlockEvalInstanceIds.delete(blockId);
      }
    }

    this.untrackEntities({ clientId: ds.instanceId });
    this.clearPerfMonitoring();
  }

  public requestObjectRows(req: ObjectRowsRequest): RequestId {
    const requestId = this.registerRequest(req.instanceId, 'rows');
    const start = req.dateRange[0];
    const end = req.dateRange[1];
    const dateRange = [
      typeof start === 'string' ? start : getMonthKey(start),
      typeof end === 'string' ? end : getMonthKey(end),
    ] as [MonthKey, MonthKey];

    const startRange = [dateRange[0], dateRange[0]] as [MonthKey, MonthKey];
    const endRange = [dateRange[1], dateRange[1]] as [MonthKey, MonthKey];

    const data: RequestedCalculation[] = [];
    for (const { fieldIds, driverIds, transitionFieldIds, transitionDriverIds } of req.objects) {
      for (const id of fieldIds) {
        data.push({
          entityId: { type: 'objectField', id },
          dateRange,
          layerId: req.layerId,
          clientId: req.instanceId,
        });
      }
      for (const id of driverIds) {
        data.push({
          entityId: { type: 'driver', id },
          dateRange,
          layerId: req.layerId,
          clientId: req.instanceId,
        });
      }
      for (const id of transitionFieldIds) {
        data.push({
          entityId: { type: 'objectField', id },
          dateRange: startRange,
          layerId: req.layerId,
          clientId: req.instanceId,
        });
        data.push({
          entityId: { type: 'objectField', id },
          dateRange: endRange,
          layerId: req.layerId,
          clientId: req.instanceId,
        });
      }
      for (const id of transitionDriverIds) {
        data.push({
          entityId: { type: 'driver', id },
          dateRange: startRange,
          layerId: req.layerId,
          clientId: req.instanceId,
        });
        data.push({
          entityId: { type: 'driver', id },
          dateRange: endRange,
          layerId: req.layerId,
          clientId: req.instanceId,
        });
      }
    }

    this.requestCalculation({
      instanceId: req.instanceId,
      data,
      requestId,
      calculator: req.calculator ?? 'webworker',
      dispatch: req.calculator === 'webworker' ? undefined : this.dispatch,
    });

    return requestId;
  }

  public requestBlock(req: RequestBlockEvalParams): RequestId {
    const requestId = this.registerRequest(req.instanceId, 'block');
    this.requestBlockInternal(requestId, req);
    return requestId;
  }

  private requestBlockInternal(requestId: RequestId, req: RequestBlockEvalParams): void {
    this.requestedBlocks.set(requestId, req);

    const { data, calculator, instanceId } = req;
    this.requestedBlockEvalInstanceIds.set(data.blockId, instanceId);

    const useBackend = shouldUseBackend(calculator);
    if (!useBackend) {
      const msg: EvaluateBlockMessage = {
        requestId,
        type: 'evaluateBlock',
        data: {
          blockId: data.blockId,
          dateRange: [getMonthKey(data.dateRange[0]), getMonthKey(data.dateRange[1])],
          monthKey: data.viewAtMonthKey,
          layerId: data.layerId,
        },
      };
      window.formulaCalculator?.postMessage(msg);
    } else {
      const { blockId, dateRange, viewAtMonthKey, layerId } = data;
      setTimeout(() => {
        this.dispatch?.(
          enqueueBlockEvaluation({
            requestId,
            blockId,
            layerId,
            dateRange: {
              start: getISOTimeWithoutMsFromMonthKey(getMonthKey(dateRange[0])),
              end: getISOTimeWithoutMsFromMonthKey(getMonthKey(dateRange[1])),
            },
            monthKey: viewAtMonthKey,
          }),
        );
      }, 0);
    }
  }

  private objectRowsResponseByInstanceId(
    res: ObjectRowsResponse,
  ): Map<InstanceId, ObjectRowsResponse> {
    const result = new Map<InstanceId, ObjectRowsResponse>();

    for (const [instanceId, relevantKeys] of this.keys.entries()) {
      result.set(instanceId, {
        ...res,
        values: res.values.filter((value) => relevantKeys.has(value.cacheKey)),
      });
    }

    return result;
  }

  public forwardObjectRowsResponse(
    rawRes: ObjectRowsResponse,
    isBackendCalculation: boolean,
  ): void {
    // Decrement from the running count of pending requests. We only want to
    // consider responses from requests that we sent.
    if (rawRes.requestId != null && this.requestIds.has(rawRes.requestId)) {
      const prevPendingRequests = this.pendingRequests;

      // Ensure we never drop below 0 as a guardrail
      this.pendingRequests = Math.max(0, this.pendingRequests - rawRes.values.length);

      // If we've reached 0 pending requests, we know we're done and can record
      // the total time.
      if (this.pendingRequests === 0 && prevPendingRequests > 0) {
        const elapsedTime = performance.now() - this.calcStartTime;
        recordCalculationPerformance(elapsedTime, this.totalRequests);
      }
    }

    rawRes.values.forEach(({ value, error, objectSpecEvaluations, cacheKey, layerId }) => {
      const valOrErr = error != null ? error : value;

      this.setCacheValue({
        key: cacheKey,
        layerId,
        value: valOrErr,
        cache: this.cachedValues,
      });

      // In addition to updating the DataArbiter's cache, we also invalidate the main thread's
      // cache to prevent it from going stale.
      FormulaCacheSingleton.forLayer(layerId).invalidateKey(cacheKey);

      // We allow for submitting calculation queries that do or do not include
      // the objectSpecEvaluations for a particular driver. We store the
      // evaluations separately so that a request that doesn't require them
      // doesn't override the evaluations.
      if (objectSpecEvaluations != null) {
        this.setCacheDriverObjectSpecEvaluations({
          key: cacheKey,
          layerId,
          objectSpecEvaluations,
          cache: this.cachedObjectEvaluations,
        });
      }
    });

    const responsesByInstanceId = this.objectRowsResponseByInstanceId(rawRes);

    for (const [instanceId, res] of responsesByInstanceId.entries()) {
      const datasource = this.datasources.get(instanceId);

      if (datasource == null) {
        return;
      }

      datasource.handleObjectRowsResponse(res, this.shouldUseBackendCache(isBackendCalculation));
    }
  }

  public forwardBlockResponse(res: BlockResponse, isBackendCalculation: boolean): void {
    const useBackendCache = this.shouldUseBackendCache(isBackendCalculation);
    const request = this.requests.get(res.requestId);
    let datasource: IBaseDataSource | undefined;
    if (request != null) {
      if (request.type === 'block') {
        datasource = request.datasource;
      }
    } else {
      // If there is no request ID then this is being sent down from the
      // backend preemptively instead of us requesting it explicitly. We need
      // to infer the datasource based off of the block ID.
      const instanceId = this.requestedBlockEvalInstanceIds.get(res.id);
      if (instanceId != null) {
        datasource = this.datasources.get(instanceId);
      }
    }

    if (datasource == null) {
      return;
    }

    datasource.handleBlockResponse(res, useBackendCache);
  }

  public forwardMutation(mutation: MutationBatch): void {
    for (const ds of this.datasources.values()) {
      ds.handleMutation(mutation);
    }
  }

  public forwardUndoneMutation(undoneMutation: MutationBatch): void {
    for (const ds of this.datasources.values()) {
      ds.handleUndoneMutation(undoneMutation);
    }
  }

  public getCachedValue({
    id,
    monthKey,
    layerId,
    isBackendCalculation,
  }: {
    id: FormulaEntityTypedId['id'];
    monthKey: MonthKey;
    layerId: LayerId;
    isBackendCalculation?: boolean;
  }): ValueOrCalculationError | undefined {
    const cacheKey = getFormulaCacheKey(id, monthKey);
    const entry = this.getLayerCache(layerId, isBackendCalculation)?.get(cacheKey);
    return entry?.value;
  }

  public getCachedObjectSpecEvaluations({
    id,
    monthKey,
    layerId,
  }: {
    id: FormulaEntityTypedId['id'];
    monthKey: MonthKey;
    layerId: LayerId;
  }): ObjectSpecEvaluation[] | undefined {
    const cacheKey = getFormulaCacheKey(id, monthKey);
    return this.getObjectEvaluationsLayerCache(layerId)?.get(cacheKey);
  }

  public getCacheSize(): number {
    const cacheAsObject: Record<LayerId, Record<CacheKey, CacheValue>> = {};
    this.cachedValues.forEach((dataPerLayer, layerId) => {
      cacheAsObject[layerId] = {};
      dataPerLayer.forEach((value, cacheKey) => {
        cacheAsObject[layerId][cacheKey] = value;
      });
    });

    return sizeof(cacheAsObject);
  }

  public isCachedValueStale(
    props: { layerId: LayerId; isBackendCalculation?: boolean } & (
      | {
          cacheKey: CacheKey;
        }
      | {
          id: FormulaEntityTypedId['id'];
          monthKey: MonthKey;
        }
    ),
  ): boolean {
    const { layerId, isBackendCalculation } = props;
    const cacheKey =
      'cacheKey' in props ? props.cacheKey : getFormulaCacheKey(props.id, props.monthKey);

    // if there is not a corresponding entry, it means the value has never been requested
    // and can be considered stale
    return this.getLayerCache(layerId, isBackendCalculation)?.get(cacheKey)?.isStale ?? true;
  }

  public setCacheValueIsStale({
    cacheKey,
    layerId,
  }: {
    cacheKey: CacheKey;
    layerId: LayerId;
  }): void {
    this.setCacheValueIsStaleHelper({ cacheKey, layerId, cache: this.cachedValues });
    if (this.enableParityTesting) {
      this.setCacheValueIsStaleHelper({ cacheKey, layerId, cache: this.cachedValues });
    }
  }

  public cloneLayerCache(currentLayerId: LayerId, newLayerID: LayerId): void {
    const currentLayerCache = this.getLayerCache(currentLayerId);
    const newLayerCache = new Map<CacheKey, CacheValue>();

    for (const [cacheKey, { value, isStale }] of currentLayerCache.entries()) {
      newLayerCache.set(cacheKey, { value, isStale });
    }

    this.cachedValues.set(newLayerID, newLayerCache);
  }

  private setCacheValueIsStaleHelper({
    cacheKey,
    layerId,
    cache,
  }: {
    cacheKey: CacheKey;
    layerId: LayerId;
    cache: LayerCache;
  }): void {
    const layerValues = this.getLayerCache(layerId);
    layerValues.set(cacheKey, { value: layerValues.get(cacheKey)?.value, isStale: true });
    cache.set(layerId, layerValues);
  }

  // any cache key that have been requested so far
  public dropLayer(layerId: LayerId) {
    this.cachedValues.delete(layerId);
    if (this.enableParityTesting) {
      this.beCachedValues.delete(layerId);
    }
  }

  public setEnableParityTesting(enableParityTesting: boolean): void {
    this.enableParityTesting = enableParityTesting;
  }

  public getMonthKeysByEntityIdForLayer(
    layerId: LayerId,
  ): Map<EntityId, Set<MonthKey>> | undefined {
    return this.layerizedMonthKeysByEntityId.get(layerId);
  }

  public untrackEntities({ clientId }: { clientId: string }): void {
    const msg: UntrackEntitiesMessage = {
      type: 'untrackEntities',
      data: {
        clientId,
      },
    };

    window.formulaCalculator?.postMessage(msg);
  }

  private registerRequest(instanceId: InstanceId, type: 'rows' | 'block'): RequestId {
    const ds = this.datasources.get(instanceId);
    if (ds == null) {
      throw new Error('DataSource not registered');
    }

    const requestId = uuidv4();
    this.requests.set(requestId, { datasource: ds, type });

    return requestId;
  }

  // Track data ranges as well as keys that were requested from this source.
  // One use of this is to allow refreshing of data per data source instance.
  // Note, this doesn't shrink for a particular data source other than when the
  // source is unregistered. We could fix this by having the source able to
  // explicitly mark entities as evicted.
  private trackRequestedData(instanceId: InstanceId, calculations: RequestedCalculation[]) {
    const ds = this.datasources.get(instanceId);
    if (ds == null) {
      throw new Error('DataSource not registered');
    }

    let keys: Set<CacheKey> | undefined = this.keys.get(instanceId);
    if (keys == null) {
      keys = new Set();
      this.keys.set(instanceId, keys);
    }

    for (const calculation of calculations) {
      this.updateEntityIndices({
        entityId: calculation.entityId.id,
        layerId: calculation.layerId,
        dateRange: calculation.dateRange,
      });

      const { dateRange, entityId } = calculation;

      const monthKeys = getMonthKeyRange(dateRange[0], dateRange[1]);

      for (const monthKey of monthKeys) {
        const key = getFormulaCacheKey(entityId.id, monthKey);
        keys.add(key);
      }

      this.updateRequestedDataRanges(instanceId, calculation);
    }
  }

  private getLayerCache(
    layerId: LayerId,
    isBackendCalculation?: boolean,
  ): Map<CacheKey, CacheValue> {
    const cacheByLayerId = isBackendCalculation ? this.beCachedValues : this.cachedValues;

    let cache = cacheByLayerId.get(layerId);
    if (cache == null) {
      cache = new Map<CacheKey, CacheValue>();
      cacheByLayerId.set(layerId, cache);
    }

    return cache;
  }

  private getObjectEvaluationsLayerCache(layerId: LayerId): Map<CacheKey, ObjectSpecEvaluation[]> {
    return this.cachedObjectEvaluations.get(layerId) ?? new Map<CacheKey, ObjectSpecEvaluation[]>();
  }

  private setCacheValue({
    key,
    layerId,
    value,
    cache,
  }: {
    key: CacheKey;
    layerId: LayerId;
    value: ValueOrCalculationError | undefined;
    cache: LayerCache;
  }): void {
    const cachedValuesForLayer = this.getLayerCache(layerId);
    cachedValuesForLayer.set(key, { value, isStale: false });
    cache.set(layerId, cachedValuesForLayer);
  }

  private setCacheDriverObjectSpecEvaluations({
    key,
    layerId,
    objectSpecEvaluations,
    cache,
  }: {
    key: CacheKey;
    layerId: LayerId;
    objectSpecEvaluations: ObjectSpecEvaluation[];
    cache: ObjectEvaluationsLayerCache;
  }): void {
    const cachedValuesForLayer = this.getObjectEvaluationsLayerCache(layerId);
    cachedValuesForLayer.set(key, objectSpecEvaluations);
    cache.set(layerId, cachedValuesForLayer);
  }

  private shouldUseBackendCache(isBackendCalculation: boolean | undefined): boolean {
    return this.enableParityTesting && isBackendCalculation === true;
  }

  public requestCalculation({
    instanceId,
    requestId,
    data,
    calculator,
    dispatch,
  }: RequestCalculationParams) {
    // Calculate the total number of requests that will be made
    // and increment the private running total.
    let pendingRequests = 0;
    for (const calculation of data) {
      const monthKeys = getMonthKeyRange(calculation.dateRange[0], calculation.dateRange[1]);
      pendingRequests += monthKeys.length;
    }

    // If there are no pending requests, this is a start event.
    // Start the timer.
    if (this.pendingRequests === 0) {
      this.calcStartTime = performance.now();
    }
    this.totalRequests += pendingRequests;
    this.pendingRequests += pendingRequests;

    this.requestIds.add(requestId);

    this.trackRequestedData(instanceId, data);

    if (shouldUseBackend(calculator)) {
      setTimeout(() => {
        dispatch?.(enqueueCalculations(webworkerCalcBatchToBackendCalcBatch({ data, requestId })));
      }, 0);
    } else {
      const msg: AddToCalculationsQueueMessage = {
        type: 'addToCalculationsQueue',
        data,
        requestId,
      };

      window.formulaCalculator?.postMessage(msg);
    }
  }

  // Keep track of all the keys that are being calculated
  // so that we can use them in LayerFormulaCache.getAllKeysToInvalidate
  // to limit the amount of fanning out we have to do
  public updateEntityIndices({
    entityId,
    dateRange,
    layerId,
  }: {
    entityId: string;
    dateRange: [MonthKey, MonthKey];
    layerId: string;
  }): void {
    getMonthsBetweenMonths(...dateRange).forEach((monthKey) => {
      if (!this.layerizedMonthKeysByEntityId.has(layerId)) {
        this.layerizedMonthKeysByEntityId.set(layerId, new Map());
      }
      if (!this.layerizedMonthKeysByEntityId.get(layerId)?.has(entityId)) {
        this.layerizedMonthKeysByEntityId.get(layerId)?.set(entityId, new Set());
      }
      this.layerizedMonthKeysByEntityId.get(layerId)?.get(entityId)?.add(monthKey);

      if (monthKey < this.encounteredDateRange.start) {
        this.encounteredDateRange.start = monthKey;
      }
      if (monthKey > this.encounteredDateRange.end) {
        this.encounteredDateRange.end = monthKey;
      }
    });
  }

  private updateRequestedDataRanges(instanceId: string, data: RequestedCalculation) {
    if (!this.requestedDataRanges.has(instanceId)) {
      this.requestedDataRanges.set(instanceId, new Map());
    }
    const forInstance = this.requestedDataRanges.get(instanceId);
    if (forInstance == null) {
      return;
    }

    const meta = omit(data, 'dateRange');
    const key: RequestedDataRangeMetaKey = getRequestedDataRangeMetaKey(meta);
    const existing = forInstance.get(key);

    const newRange: [MonthKey, MonthKey] = data.dateRange;
    if (existing == null) {
      forInstance.set(key, { meta, ranges: [newRange] });
    } else {
      const allRanges = existing.ranges.concat([newRange]);

      // Sort by start, ascending
      allRanges.sort((a, b) => (a[0] > b[0] ? 1 : -1));

      const distinctRanges: Array<[MonthKey, MonthKey]> = [];
      let prevRange: [MonthKey, MonthKey] | undefined;
      for (const range of allRanges) {
        if (prevRange == null) {
          prevRange = range;
          continue;
        }

        if (prevRange[1] >= range[0]) {
          const maxEnd = prevRange[1] > range[1] ? prevRange[1] : range[1];
          prevRange = [prevRange[0], maxEnd];
        } else {
          distinctRanges.push(prevRange);
          prevRange = range;
        }
      }

      if (prevRange != null) {
        distinctRanges.push(prevRange);
      }

      forInstance.set(key, { meta, ranges: distinctRanges });
    }
  }

  public getEncounteredDateRange(): { start: MonthKey; end: MonthKey } {
    return { ...this.encounteredDateRange };
  }

  private clearPerfMonitoring() {
    this.totalRequests = 0;
    this.pendingRequests = 0;
    this.calcStartTime = performance.now();
    this.requestIds.clear();
  }
}
