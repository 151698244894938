import { Box, BoxProps } from '@chakra-ui/react';
import classNames from 'classnames';
import React from 'react';

import { FORMULA_REFERENCE_CLASS } from 'config/formula';
import useAppSelector from 'hooks/useAppSelector';
import {
  canInsertFormulaEntityReferenceSelector,
  insertedFormulaEntityReferenceSelector,
} from 'selectors/formulaInputSelector';

interface Props extends BoxProps {
  children: React.ReactNode;
}

const ClickableFormulaReferenceWrapper: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...rest }, ref) => {
    const canInsertDriverReference = useAppSelector(canInsertFormulaEntityReferenceSelector);
    const insertedDriverReference = useAppSelector(insertedFormulaEntityReferenceSelector);
    const canClickToInsert = canInsertDriverReference || insertedDriverReference != null;
    return (
      <>
        <Box ref={ref} {...rest}>
          {children}
        </Box>
        {canClickToInsert && (
          <Box
            position="absolute"
            pointerEvents="auto"
            className={classNames({
              [FORMULA_REFERENCE_CLASS]: canClickToInsert,
            })}
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex="overlay"
            _hover={{
              borderColor: 'selection.500',
              borderWidth: '2px',
              borderStyle: 'dashed',
            }}
          />
        )}
      </>
    );
  },
);

export default ClickableFormulaReferenceWrapper;
