import { createSelector } from '@reduxjs/toolkit';

import { FeatureFlagsRecord } from 'reduxStore/reducers/featureFlagsSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { enableLaunchDarklyBackendOnlyLiveEdit as launchDarklyBackendOnlyLiveEdit } from 'selectors/launchDarklySelector';
import { Selector } from 'types/redux';

export const featureFlagsSelector: Selector<FeatureFlagsRecord> = (state) =>
  state.featureFlags.flags;

export const enableFormulaCalculationExplanationsSelector: Selector<boolean> = (state) =>
  state.featureFlags.flags.enableFormulaCalculationExplanations;

export const enableBackendOnlyLiveEdit = createSelector(
  [
    launchDarklyBackendOnlyLiveEdit,
    (state: RootState) => state.featureFlags.flags.enableBackendOnlyLiveEdit,
  ],
  (launchDarklyFlag, employeeFlag) => {
    return employeeFlag || launchDarklyFlag;
  },
);
