import Joi from 'joi';
import { useMemo } from 'react';

import { joiDefault } from 'helpers/errorMessages';
import useAppSelector from 'hooks/useAppSelector';
import { namedDatasetVersionNamesSelector } from 'selectors/layerSelector';

export default function useNamedVersionValidator() {
  const namedDatasetVersionNames = useAppSelector(namedDatasetVersionNamesSelector);
  const namedVersionValidator = useMemo(
    () =>
      Joi.object({
        versionName: Joi.string()
          .required()
          .min(1)
          .invalid(...namedDatasetVersionNames)
          .trim()
          .messages(joiDefault('version')),
      }),
    [namedDatasetVersionNames],
  );
  return namedVersionValidator;
}
