import { trackFeatureFlagToggle } from 'reduxStore/actions/trackEvent';
import { FeatureFlag, toggleFeatureFlagInternal } from 'reduxStore/reducers/featureFlagsSlice';
import { AppThunk } from 'reduxStore/store';
import { featureFlagsSelector } from 'selectors/featureFlagsSelector';

export const toggleFeatureFlag = (flag: FeatureFlag): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const featureFlags = featureFlagsSelector(state);

    dispatch(toggleFeatureFlagInternal(flag));
    dispatch(trackFeatureFlagToggle({ featureFlag: flag, value: !featureFlags[flag] }));
  };
};
