import { groupBy, isEmpty, mapValues } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
  layerParamMemo,
} from 'helpers/layerSelectorFactory';
import { getObjectFieldUUID } from 'helpers/object';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectField,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { EntityTable } from 'reduxStore/models/common';
import { ExtObjectSpec } from 'reduxStore/models/extObjectSpecs';
import { LayerId } from 'reduxStore/models/layers';
import { Value, toValueType } from 'reduxStore/models/value';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import {
  businessObjectFieldIdSelector,
  businessObjectIdSelector,
  businessObjectSpecIdSelector,
  fieldSelector,
} from 'selectors/constSelectors';
import { extObjectSpecsByKeySelector } from 'selectors/extObjectSpecsSelector';
import { extObjectsByKeySelector } from 'selectors/extObjectsSelector';
import { getGivenOrCurrentLayerId, layersSelector } from 'selectors/layerSelector';
import { MonthKey } from 'types/datetime';
import { ParametricSelector, Selector } from 'types/redux';

const EMPTY_ENTITY_TABLE: EntityTable<BusinessObject> = { byId: {}, allIds: [] };

const businessObjectsTableForLayerSelector: SelectorWithLayerParam<EntityTable<BusinessObject>> =
  createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
    const layer = layers[layerId];
    if (layer == null) {
      return EMPTY_ENTITY_TABLE;
    }
    return layer.businessObjects;
  })(getCacheKeyForLayerSelector);

export const businessObjectsForAllLayersSelector: Selector<BusinessObject[]> = createSelector(
  layersSelector,
  (layers) => Object.values(layers).flatMap((l) => Object.values(l.businessObjects.byId)),
);

export const businessObjectsForLayerSelector: SelectorWithLayerParam<BusinessObject[]> =
  createCachedSelector(addLayerParams(businessObjectsTableForLayerSelector), (objects) => {
    return objects.allIds.map((id) => objects.byId[id]);
  })(getCacheKeyForLayerSelector);

export const businessObjectsByIdForLayerSelector: SelectorWithLayerParam<
  Record<string, BusinessObject>
> = createCachedSelector(
  addLayerParams(businessObjectsTableForLayerSelector),
  (objects) => objects.byId,
)(getCacheKeyForLayerSelector);

const businessObjectNameByIdForLayerSelector: SelectorWithLayerParam<Record<string, string>> =
  createCachedSelector(businessObjectsByIdForLayerSelector, (objectsById) =>
    mapValues(objectsById, (o) => o.name),
  )({
    keySelector: getCacheKeyForLayerSelector,
    selectorCreator: createDeepEqualSelector,
  });

export const businessObjectSelector: ParametricSelector<
  BusinessObjectId,
  BusinessObject | undefined
> = createCachedSelector(
  businessObjectIdSelector,
  (state) => businessObjectsByIdForLayerSelector(state),
  function businessObjectSelector(objId, objsById) {
    return objsById[objId];
  },
)((_state, objId) => objId);

export const businessObjectSpecIdForObjectSelector: ParametricSelector<
  BusinessObjectId,
  BusinessObjectSpecId | undefined
> = createCachedSelector(businessObjectSelector, (obj) => obj?.specId)((state, objId) => objId);

type BusinessObjectFieldProps = {
  objectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
};

const EMPTY_ARRAY: MonthKey[] = [];
export const businessObjectFieldActualsOverrideMonthKeysSelector: ParametricSelector<
  BusinessObjectFieldProps,
  MonthKey[]
> = createCachedSelector(
  (state: RootState, { objectId }: BusinessObjectFieldProps) =>
    businessObjectSelector(state, objectId),
  fieldSelector<BusinessObjectFieldProps, 'fieldSpecId'>('fieldSpecId'),
  function businessObjectFieldActualsOverrideMonthKeysSelector(object, fieldSpecId) {
    const field = object?.fields.find((f) => f.fieldSpecId === fieldSpecId);
    return field?.value?.actuals?.timeSeries != null
      ? Object.keys(field.value.actuals.timeSeries)
      : EMPTY_ARRAY;
  },
)({
  keySelector: (_state, { objectId, fieldSpecId }) => [objectId, fieldSpecId].join(','),
  selectorCreator: createDeepEqualSelector,
});

export const businessObjectNameSelector: ParametricSelector<BusinessObjectId, string | undefined> =
  createCachedSelector(businessObjectSelector, (object) => object?.name)((_state, objId) => objId);

export const businessObjectExtObjectSpecSelector: ParametricSelector<
  BusinessObjectId,
  ExtObjectSpec | null
> = createCachedSelector(
  businessObjectSelector,
  (state: RootState) => extObjectsByKeySelector(state),
  (state: RootState) => extObjectSpecsByKeySelector(state),
  (object, extObjectsByKey, extObjectSpecsByKey) => {
    if (object?.extKey == null) {
      return null;
    }

    const { extKey } = object;
    const extObject = extObjectsByKey[extKey];
    if (extObject == null) {
      return null;
    }

    return extObjectSpecsByKey[extObject.extSpecKey] ?? null;
  },
)((_state, objId) => objId);

/*
 * Note: this is quite inefficient, especially as the number of objects in a dataset increases.
 * If we run into performance issues, we should add the denormalized object id in the object field event.
 *
 * This selector fills in field ids for objects that don't have their fields populated yet.
 */
export const businessObjectsByFieldIdForLayerSelector: SelectorWithLayerParam<
  Record<BusinessObjectFieldId, BusinessObject>
> = createCachedSelector(
  addLayerParams(businessObjectsForLayerSelector),
  addLayerParams(businessObjectSpecsByIdForLayerSelector),
  (objects, specsById) => {
    return objects.reduce(
      (res, obj) => {
        const spec = specsById[obj.specId];
        if (spec == null) {
          return res;
        }

        // If there are any leftover field specs not accounted for in the
        // objet, we need to add them with `getObjectFieldUUID` since we lazily
        // create fields.
        const fieldSpecs = new Set(spec.fields.map((f) => f.id));

        obj.fields.forEach((field) => {
          fieldSpecs.delete(field.fieldSpecId);
          res[field.id] = obj;
        });

        fieldSpecs.forEach((fieldSpecId) => {
          const fieldId = getObjectFieldUUID(obj.id, fieldSpecId);
          res[fieldId] = obj;
        });

        return res;
      },
      {} as Record<BusinessObjectFieldId, BusinessObject>,
    );
  },
)(getCacheKeyForLayerSelector);

export const businessObjectForFieldIdForLayerSelector: ParametricSelector<
  BusinessObjectFieldId,
  BusinessObject | null
> = createCachedSelector(
  businessObjectFieldIdSelector,
  (state: RootState) => businessObjectsByFieldIdForLayerSelector(state),
  (fieldId, objectsByFieldId) => (isEmpty(fieldId) ? null : objectsByFieldId[fieldId]),
)((state, fieldId) => fieldId);

export const businessObjectsBySpecIdForLayerSelector: SelectorWithLayerParam<
  Record<BusinessObjectSpecId, BusinessObject[]>
> = createCachedSelector(addLayerParams(businessObjectsForLayerSelector), (objects) => {
  return groupBy(objects, (obj) => obj.specId);
})(getCacheKeyForLayerSelector);

const businessObjectIdsForSpecSelector: ParametricSelector<
  BusinessObjectSpecId,
  BusinessObjectId[]
> = createCachedSelector(
  (state) => businessObjectsForLayerSelector(state),
  businessObjectSpecIdSelector,
  (objects, objectSpecId) => {
    return objects.filter((o) => o.specId === objectSpecId).map((o) => o.id);
  },
)({
  keySelector: (_state, objectSpecId) => objectSpecId,
  selectorCreator: createDeepEqualSelector,
});

export const businessObjectNamesByIdForSpecSelector: ParametricSelector<
  BusinessObjectSpecId,
  Record<BusinessObjectId, string>
> = createCachedSelector(
  businessObjectIdsForSpecSelector,
  (state: RootState) => businessObjectNameByIdForLayerSelector(state),
  (objectIds, objectNamesById) => {
    return Object.fromEntries(objectIds.map((id) => [id, objectNamesById[id]]));
  },
)((_state, objectSpecId) => objectSpecId);

type BusinessObjectFieldForSpecIdAndObjectIdSelectorProps = {
  businessObjectId: BusinessObjectId;
  businessObjectFieldSpecId: BusinessObjectFieldSpecId;
  layerId?: LayerId;
};

const getCacheKeyForBusinessObjectFieldSelector = (
  state: RootState,
  {
    layerId,
    businessObjectFieldSpecId,
    businessObjectId,
  }: BusinessObjectFieldForSpecIdAndObjectIdSelectorProps,
) => `${layerId ?? state.dataset.currentLayerId},${businessObjectId},${businessObjectFieldSpecId}`;

/**
 * This selector retrieves a MATERIALIZED/existing field for a given spec id and object id.
 * When objects are created or new columns are added to a db, the fields on the object are
 * lazily initialized. This selector will return null if the field has not yet been initialized.
 * If you want the field id regardless of whether it's been materialized or not, use
 * `fieldIdForFieldSpecIdAndObjectIdSelector`
 */
export const materializedFieldForSpecIdAndObjectIdSelector: ParametricSelector<
  BusinessObjectFieldForSpecIdAndObjectIdSelectorProps,
  BusinessObjectField | null
> = createCachedSelector(
  (state: RootState, { layerId }: BusinessObjectFieldForSpecIdAndObjectIdSelectorProps) =>
    businessObjectsByIdForLayerSelector(state, layerParamMemo(layerId)),
  fieldSelector('businessObjectId'),
  fieldSelector('businessObjectFieldSpecId'),
  (businessObjectsById, businessObjectId, businessObjectFieldSpecId) => {
    const businessObject = businessObjectsById[businessObjectId];
    return businessObject?.fields.find((f) => f.fieldSpecId === businessObjectFieldSpecId) ?? null;
  },
)(getCacheKeyForBusinessObjectFieldSelector);

export const fieldIdForFieldSpecIdAndObjectIdSelector: ParametricSelector<
  BusinessObjectFieldForSpecIdAndObjectIdSelectorProps,
  BusinessObjectFieldId
> = createCachedSelector(
  fieldSelector<BusinessObjectFieldForSpecIdAndObjectIdSelectorProps, 'businessObjectId'>(
    'businessObjectId',
  ),
  fieldSelector('businessObjectFieldSpecId'),
  materializedFieldForSpecIdAndObjectIdSelector,
  function fieldIdForFieldSpecIdAndObjectIdSelector(
    businessObjectId,
    businessObjectFieldSpecId,
    field,
  ) {
    // fieldId is not always equal to getObjectFieldUUID(objectId, fieldSpecId) (e.g. the field was created
    // before we started using getObjectFieldUUID) so we must always the the existing field first.
    return field?.id ?? getObjectFieldUUID(businessObjectId, businessObjectFieldSpecId);
  },
)(getCacheKeyForBusinessObjectFieldSelector);

export const businessObjectFieldInitialValueForSpecIdAndObjectIdSelector: ParametricSelector<
  BusinessObjectFieldForSpecIdAndObjectIdSelectorProps,
  Value | undefined
> = createCachedSelector(materializedFieldForSpecIdAndObjectIdSelector, (businessObjectField) => {
  const initialValueStr = businessObjectField?.value?.initialValue;
  const initialValueType = businessObjectField?.value?.type;
  if (initialValueStr == null || initialValueType == null) {
    return undefined;
  }
  const value = toValueType(initialValueStr, initialValueType);
  return value;
})(getCacheKeyForBusinessObjectFieldSelector);

export const syncedObjectsForSpec: ParametricSelector<BusinessObjectFieldSpecId, BusinessObject[]> =
  createCachedSelector(
    businessObjectIdsForSpecSelector,
    (state: RootState) => businessObjectsByIdForLayerSelector(state),
    (objectIds, objectsById) => {
      return objectIds
        .map((id) => objectsById[id])
        .filter(isNotNull)
        .filter((o) => o.remoteId != null);
    },
  )((_state, specId) => specId);

export const businessObjectSpecsByObjectIdSelector: SelectorWithLayerParam<
  Record<BusinessObjectId, BusinessObjectSpec | undefined>
> = createCachedSelector(
  businessObjectsForLayerSelector,
  businessObjectSpecsByIdForLayerSelector,
  (objects, objectSpecsById) => {
    return Object.fromEntries(
      objects.map((obj) => [obj.id, safeObjGet(objectSpecsById[obj.specId])]),
    );
  },
)(getCacheKeyForLayerSelector);

const businessObjectSpecIdsByObjectIdSelector: SelectorWithLayerParam<
  Record<BusinessObjectId, BusinessObjectSpecId | null>
> = createSelector(businessObjectsForLayerSelector, (objects) => {
  const specIdsByObjectId: Record<BusinessObjectId, BusinessObjectSpecId> = {};
  objects.forEach((obj) => {
    specIdsByObjectId[obj.id] = obj.specId;
  });
  return specIdsByObjectId;
});

export const businessObjectSpecIdForObjectIdSelector: ParametricSelector<
  BusinessObjectId,
  BusinessObjectSpecId | null
> = createCachedSelector(
  (state) => businessObjectSpecIdsByObjectIdSelector(state, undefined),
  businessObjectIdSelector,
  (specIdsByObjectId, objectId) => specIdsByObjectId[objectId] ?? null,
)((_state, objectId) => objectId);

export const businessObjectSpecForObjectIdSelector: ParametricSelector<
  BusinessObjectId,
  BusinessObjectSpec | null
> = createCachedSelector(
  businessObjectSpecIdForObjectIdSelector,
  (state: RootState) => businessObjectSpecsByIdForLayerSelector(state),
  (specId, specsById) => (specId != null ? specsById[specId] : null),
)((_state, objectId) => objectId);
