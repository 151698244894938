import { keyBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { AccessorEntityType, OrgRole } from 'generated/graphql';
import { getDiffFromNow } from 'helpers/dates';
import { safeObjGet } from 'helpers/typescript';
import { LayerAccessResource, isLayerAccessResource } from 'reduxStore/models/accessResources';
import { BlocksPageId } from 'reduxStore/models/blocks';
import { Layer, LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { blocksPageIdSelector, paramSelector } from 'selectors/constSelectors';
import {
  currentLayerIsDraftSelector,
  currentLayerWasCreatedByUser,
  layersSelector,
} from 'selectors/layerSelector';
import { accessResourceByPageSelector } from 'selectors/pageAccessResourcesSelector';
import { allAccessResourcesSelector } from 'selectors/sharingSelector';
import { ParametricSelector, Selector } from 'types/redux';

const layerAccessResourcesSelector: Selector<LayerAccessResource[]> = createSelector(
  allAccessResourcesSelector,
  (accessResources) => {
    return accessResources.filter(isLayerAccessResource);
  },
);

const EMPTY_ACCESS_RESOURCES: LayerAccessResource[] = [];

export const layerAccessResourcesForPageSelector: ParametricSelector<
  BlocksPageId,
  LayerAccessResource[]
> = createCachedSelector(
  blocksPageIdSelector,
  accessResourceByPageSelector,
  layerAccessResourcesSelector,
  layersSelector,
  (pageId, accessResourcesByPage, layerAccessResources, layers) => {
    if (pageId == null) {
      return EMPTY_ACCESS_RESOURCES;
    }

    const pageResource = safeObjGet(accessResourcesByPage[pageId]);
    if (pageResource == null) {
      return EMPTY_ACCESS_RESOURCES;
    }

    return Object.values(layerAccessResources).filter(
      (ar) =>
        layers[ar.resourceId] != null &&
        !layers[ar.resourceId].isDeleted &&
        (ar.parentId === pageResource.id || ar.parentId == null),
    );
  },
)((_state, pageId: BlocksPageId) => pageId);

type PageAndLayerProps = { pageId: BlocksPageId; layerId: LayerId };

export const sharedGuestAccessResourceForPageAndLayerSelector: ParametricSelector<
  PageAndLayerProps,
  LayerAccessResource | undefined
> = createCachedSelector(
  (_state: RootState, { pageId }: PageAndLayerProps) =>
    layerAccessResourcesForPageSelector(_state, pageId),
  (_, { layerId }: PageAndLayerProps) => layerId,
  (pageLayerResources, layerId) => {
    const guestEntry = pageLayerResources.find(
      (ar) =>
        ar.resourceId === layerId &&
        ar.parentId != null &&
        ar.accessControlList.find(
          (acl) =>
            acl.entityWithAccess.type === AccessorEntityType.UserGroup &&
            acl.entityWithAccess.id === OrgRole.Guest,
        ),
    );
    return guestEntry;
  },
)((_state, { pageId, layerId }) => `${pageId},${layerId}`);

export const currentUserPageAccessibleLayerIdsSelector: ParametricSelector<
  BlocksPageId,
  LayerId[]
> = createCachedSelector(
  blocksPageIdSelector,
  accessCapabilitiesSelector,
  layersSelector,
  (pageId, accessCapabilities, layers) => {
    return (
      Object.values(layers)
        .filter(
          (layer) => !layer.isDeleted && accessCapabilities.canReadLayerOnPage(pageId, layer.id),
        )
        // return layers from oldest to newest
        .sort(
          (layerId1, layerId2) =>
            getDiffFromNow(layerId1.createdAt).toMillis() -
            getDiffFromNow(layerId2.createdAt).toMillis(),
        )
        .map((layer) => layer.id)
    );
  },
)((_state, pageId: BlocksPageId) => pageId);

export const currentUserPageAccessibleLayersSelector: ParametricSelector<
  BlocksPageId,
  Record<LayerId, Layer>
> = createCachedSelector(
  currentUserPageAccessibleLayerIdsSelector,
  layersSelector,
  (layerIds, layers) => {
    return keyBy(
      layerIds.map((layerId) => layers[layerId]),
      'id',
    );
  },
)((_state, pageId: BlocksPageId) => pageId);

export const childLayersSelector: ParametricSelector<LayerId, Layer[]> = createCachedSelector(
  paramSelector<LayerId>(),
  layersSelector,
  (layerId, layers) => {
    return Object.values(layers).filter(
      (layer) => !layer.isDeleted && layer.parentLayerId === layerId,
    );
  },
)((_state, layerId) => layerId);

export const accessibleChildLayersSelector: ParametricSelector<LayerId, Layer[]> =
  createCachedSelector(
    childLayersSelector,
    accessCapabilitiesSelector,
    (childLayers, accessCapabilities) => {
      return Object.values(childLayers).filter((layer) =>
        accessCapabilities.canReadLayerGlobally(layer.id),
      );
    },
  )((_state, layerId) => layerId);

export const canUserRenameCurrentLayerSelector: Selector<boolean> = createSelector(
  currentLayerWasCreatedByUser,
  currentLayerIsDraftSelector,
  accessCapabilitiesSelector,
  (currentLayerCreatedByUser, currentLayerIsDraft, { canEditAnyScenario }) => {
    // admins can always rename
    if (canEditAnyScenario) {
      return true;
    }

    // other users can only rename non-draft scenarios that they created
    return !currentLayerIsDraft && currentLayerCreatedByUser;
  },
);
