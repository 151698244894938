import { Flex, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { DriverNameDisplay } from 'components/DriverNameTableCell/DriverName';
import { AttributePlaceholder, filterOutPlaceholderAttributes } from 'config/dimDriverEditContext';
import { Attribute } from 'reduxStore/models/dimensions';

// Arbitrary values that look kindof good with the menu width of 28rem
const EDIT_NAME_WITH_ATTRIBUTES_MAX_WIDTH = 214;
const RENAME_NAME_WITH_ATTRIBUTES_MAX_WIDTH = 260;
const RENAME_ALL_NAME_MAX_WIDTH = 112;

const EMPTY_ATTRIBUTES: Attribute[] = [];

interface NameWithAttributesProps {
  name: string;
  maxWidth: number;
  attributes: Array<Attribute | AttributePlaceholder>;
}
const NameWithAttributes: React.FC<NameWithAttributesProps> = ({ name, maxWidth, attributes }) => {
  const filteredAttrs = useMemo(() => filterOutPlaceholderAttributes(attributes), [attributes]);

  return (
    <Flex>
      &quot;
      <Text fontWeight="bold" as="span">
        <DriverNameDisplay
          name={name}
          showDescription={false}
          width={maxWidth}
          maxWidth={maxWidth}
          attributes={filteredAttrs}
        />
      </Text>
      &quot;
    </Flex>
  );
};

interface OptionProps {
  name: string;
  originalName: string;
}

export const RenameSubDriverOption: React.FC<
  OptionProps & { attributes: Array<Attribute | AttributePlaceholder> }
> = ({ name, attributes }) => {
  return (
    <Flex alignItems="center" columnGap={1}>
      <Text whiteSpace="nowrap">Rename to</Text>
      <NameWithAttributes
        name={name}
        maxWidth={RENAME_NAME_WITH_ATTRIBUTES_MAX_WIDTH}
        attributes={attributes}
      />
    </Flex>
  );
};

export const RenameAllSubDriversOption: React.FC<OptionProps> = ({ name, originalName }) => {
  return (
    <Flex alignItems="center" columnGap={1} whiteSpace="nowrap">
      Rename all
      <NameWithAttributes
        name={originalName}
        maxWidth={RENAME_ALL_NAME_MAX_WIDTH}
        attributes={EMPTY_ATTRIBUTES}
      />
      to
      <NameWithAttributes
        name={name}
        maxWidth={RENAME_ALL_NAME_MAX_WIDTH}
        attributes={EMPTY_ATTRIBUTES}
      />
    </Flex>
  );
};

export const RenameSelectedSubDriversOption: React.FC<OptionProps> = ({ name, originalName }) => {
  return (
    <Flex alignItems="center" columnGap={1} whiteSpace="nowrap">
      Rename selected
      <NameWithAttributes
        name={originalName}
        maxWidth={RENAME_ALL_NAME_MAX_WIDTH}
        attributes={EMPTY_ATTRIBUTES}
      />
      to
      <NameWithAttributes
        name={name}
        maxWidth={RENAME_ALL_NAME_MAX_WIDTH}
        attributes={EMPTY_ATTRIBUTES}
      />
    </Flex>
  );
};

export const EditAttributesOption: React.FC<
  OptionProps & { isNewDriver: boolean; attributes: Array<Attribute | AttributePlaceholder> }
> = ({ name, isNewDriver, attributes }) => {
  const numAttrs = filterOutPlaceholderAttributes(attributes).length;
  const text = isNewDriver || numAttrs === 0 ? 'Add attributes to' : 'Edit attributes on';

  return (
    <Flex alignItems="center" columnGap={1}>
      <Text whiteSpace="nowrap">{text}</Text>
      <NameWithAttributes
        name={name}
        maxWidth={EDIT_NAME_WITH_ATTRIBUTES_MAX_WIDTH}
        attributes={attributes}
      />
    </Flex>
  );
};
