import { Flex, useBoolean, useToken } from '@chakra-ui/react';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import last from 'lodash/last';
import React, { useCallback, useRef } from 'react';

import CurrentBar from 'components/DriverChart/CurrentBar';
import CursorMonthTracker from 'components/DriverChart/CursorMonthTracker';
import DriverChartLegend from 'components/DriverChart/DriverChartLegend';
import EventDriverImpact from 'components/DriverChart/EventDriverImpact';
import MilestoneMarker from 'components/DriverChart/MilestoneMarker';
import { StackedBarChart } from 'components/DriverChart/StackedBarChart';
import TimeAxis from 'components/DriverChart/TimeAxis';
import ValueAxis from 'components/DriverChart/ValueAxis';
import { DEFAULT_DRIVER_CHART_SIZE } from 'config/block';
import { DriverChartDatum } from 'config/driverChart';
import { ChartSize } from 'generated/graphql';
import { getChartDimensions } from 'helpers/chart';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartActualsData from 'hooks/useChartActualsData';
import useChartContext from 'hooks/useChartContext';
import useChartLine from 'hooks/useChartLine';
import useForecastLineData from 'hooks/useForecastLineData';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { setCursor } from 'reduxStore/reducers/cursorSlice';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { firstMilestoneForDriverSelector } from 'selectors/milestonesSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { resolvedDriverFormatSelector } from 'selectors/resolvedEntityFormatSelector';

// in case of a value of 0, we still need some indication that a value exists
const MINIMUM_BAR_HEIGHT = 2;
export const CHART_SIZE_OPTION_TO_BAR_WIDTH = {
  [ChartSize.Medium]: 6,
  [ChartSize.Large]: 12,
  [ChartSize.ExtraLarge]: 14,
};

type BarsProps = {
  d: DriverChartDatum;
  fill: string;
  size: ChartSize;
  yMax: number;
};

const BarLine: React.FC<BarsProps> = ({ d, fill, size, yMax }) => {
  const { xAccessor, yAccessor } = useChartLine();
  const { valueScale } = useChartContext();
  const yMin = valueScale.range()[1];
  const yAtZeroLine = valueScale(0);
  const yAtMinLine = valueScale(yMin);
  const dataPointY = yAccessor(d);
  let y, height;

  if (d.y < 0) {
    y = Math.max(yAtZeroLine, yMin);
    height = dataPointY - y;
  } else {
    y = dataPointY - MINIMUM_BAR_HEIGHT;
    height = Math.abs(Math.min(yMax, yAtMinLine) - dataPointY) + MINIMUM_BAR_HEIGHT;
  }

  return (
    <Bar
      x={xAccessor(d)}
      y={y}
      height={height}
      width={CHART_SIZE_OPTION_TO_BAR_WIDTH[size]}
      ry={1}
      fill={fill}
    />
  );
};

interface Props {
  showEventImpact?: boolean;
  inView?: boolean;
  multiDriver: boolean;
}

const BarChart: React.FC<Props> = ({ showEventImpact = true, inView = true, multiDriver }) => {
  const { blockId } = useBlockContext();
  const { driverIds, startDateTime, endDateTime, monthKeysWithForecastData, baselineLayerId } =
    useChartContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const size = viewOptions?.chartSize ?? DEFAULT_DRIVER_CHART_SIZE;

  // Bar charts atm are only 1 driver
  const firstDriverId = driverIds[0];
  const [showLabels, setShowLabels] = useBoolean();
  const dispatch = useAppDispatch();
  const milestone = useAppSelector((state) =>
    firstMilestoneForDriverSelector(state, firstDriverId),
  );
  const format = useAppSelector((state) => resolvedDriverFormatSelector(state, firstDriverId));

  const onMouseLeave = useCallback(() => {
    dispatch(setCursor(null));
    setShowLabels.off();
  }, [dispatch, setShowLabels]);

  const { chartMargins, chartWidth, chartHeight, yMax } = getChartDimensions(viewOptions);

  const ts = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: firstDriverId, layerId: baselineLayerId }),
  );

  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  useRequestCellValue({ id: firstDriverId, type: 'driver', dateRange });

  // Get time series data for actuals
  const actualsLineData = useChartActualsData(startDateTime, ts, format, endDateTime);

  const lastActual = last(actualsLineData);
  const svgRef = useRef<SVGSVGElement>(null);
  const getPoint = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      if (svgRef?.current == null) {
        return null;
      }
      return localPoint(svgRef.current, event);
    },
    [svgRef],
  );

  const forecastLineData = useForecastLineData(
    monthKeysWithForecastData,
    lastActual,
    firstDriverId,
    baselineLayerId,
    'bar',
  );

  const [resolvedActualsColor, resolvedForecastColor] = useToken('colors', ['actuals', 'forecast']);

  return (
    <Flex userSelect="none" position="relative" flexDirection="row">
      <svg
        ref={svgRef}
        width={chartWidth}
        height={chartHeight}
        onMouseEnter={setShowLabels.on}
        onMouseLeave={onMouseLeave}
        overflow="visible"
      >
        <Group left={chartMargins.left} top={chartMargins.top}>
          <ValueAxis driverId={firstDriverId} />
          {showEventImpact && <EventDriverImpact driverId={firstDriverId} />}
          <TimeAxis showLabels={showLabels || size !== ChartSize.Medium} size={size} />
          {multiDriver ? (
            <StackedBarChart size={size} />
          ) : (
            <>
              {actualsLineData.map((d) => (
                <BarLine
                  fill={resolvedActualsColor}
                  key={d.monthKey}
                  size={size}
                  d={d}
                  yMax={yMax}
                />
              ))}
              {forecastLineData.map((d) => (
                <BarLine
                  fill={resolvedForecastColor}
                  key={d.monthKey}
                  size={size}
                  d={d}
                  yMax={yMax}
                />
              ))}
            </>
          )}
          {inView && <CurrentBar size={size} />}
          {milestone != null && !multiDriver && <MilestoneMarker driverId={firstDriverId} />}
          {inView && <CursorMonthTracker getPoint={getPoint} />}
        </Group>
      </svg>
      {multiDriver && <DriverChartLegend type="driver" />}
    </Flex>
  );
};

export default React.memo(BarChart);
