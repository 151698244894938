import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import { getAllNestedEvents } from 'helpers/eventGroups';
import { BlockId } from 'reduxStore/models/blocks';
import {
  isPlanTimelineItemRefEqual,
  PlanTimelineItemRef,
  refFromPlanTimelineItem,
} from 'reduxStore/models/events';
import {
  clearSelection,
  selectPlanTimelineItemRef,
  setSelectedEventsAndGroups,
} from 'reduxStore/reducers/pageSlice';
import { toggleExpand } from 'reduxStore/reducers/roadmapSlice';
import { AppThunk } from 'reduxStore/store';
import { populatedEventGroupSelector } from 'selectors/eventsAndGroupsSelector';
import {
  parentRowRefForEventSelector,
  planTimelineRowsSelector,
} from 'selectors/planTimelineSelector';
import { selectedEventGroupIdsSelector } from 'selectors/selectedEventSelector';
import { selectedPlanTimelineItemRefSelector } from 'selectors/selectionSelector';

/**
 * If the two refs are in the same row, select everything between them.
 * Otherwise, just select the newly selected ref.
 */
const maybeRangeSelectEventRefs =
  ({
    active,
    newlySelected,
    blockId,
  }: {
    active: PlanTimelineItemRef & { type: 'event' };
    newlySelected: PlanTimelineItemRef & { type: 'event' };
    blockId: BlockId;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const newlySelectedRowRef = parentRowRefForEventSelector(state, {
      blockId,
      eventId: newlySelected.id,
    });
    const activeRowRef = parentRowRefForEventSelector(state, { blockId, eventId: active.id });

    const isInSameRow = isPlanTimelineItemRefEqual(newlySelectedRowRef, activeRowRef);

    if (!isInSameRow) {
      // Just select the newly selected event
      dispatch(
        setSelectedEventsAndGroups({
          blockId,
          active: newlySelected,
          refs: [newlySelected],
        }),
      );

      return;
    }

    // This is mostly just a type check, since if the two refs are in the same row, the row must be an Entity row
    // (as opposed to a Plan row)
    if (newlySelectedRowRef?.type !== 'entity') {
      return;
    }

    const { refs: eventRefsInRow } = newlySelectedRowRef;

    const activeEventIdx = eventRefsInRow.findIndex((r) => isPlanTimelineItemRefEqual(r, active));
    const selectedEventIdx = eventRefsInRow.findIndex((r) =>
      isPlanTimelineItemRefEqual(r, newlySelected),
    );

    const selectedEventRefs = eventRefsInRow.slice(
      Math.min(activeEventIdx, selectedEventIdx),
      Math.max(activeEventIdx, selectedEventIdx) + 1,
    );

    dispatch(
      setSelectedEventsAndGroups({
        blockId,
        active,
        refs: selectedEventRefs,
      }),
    );
  };

export const selectPlanTimelineInitiative =
  (
    ref: PlanTimelineItemRef,
    blockId: BlockId,
    { range = false, toggle = false }: { range?: boolean; toggle?: boolean } = {},
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const timelineRows = planTimelineRowsSelector(state, blockId);
    const selection = selectedPlanTimelineItemRefSelector(state);

    const selectedEventGroupIds = selectedEventGroupIdsSelector(state);
    const isSelectedGroup = ref.type === 'group' && selectedEventGroupIds.includes(ref.id);
    const eventGroup = populatedEventGroupSelector(state, ref.id);
    const isEmpty = getAllNestedEvents(eventGroup).length === 0;

    const shouldToggleExpand = !isEmpty && isSelectedGroup && !range && toggle;
    if (shouldToggleExpand) {
      dispatch(toggleExpand({ blockId, itemId: ref.id }));
    }

    // for now, disallow multiselect across blocks
    if (selection == null || blockId !== selection.blockId || (!range && !toggle)) {
      dispatch(selectPlanTimelineItemRef({ ...ref, blockId }));
      return;
    }

    const { active, refs } = selection;
    const orderedRowRefs = timelineRows.map(refFromPlanTimelineItem);

    const currRowKeyIdx = orderedRowRefs.findIndex((r) => r.id === active.id);
    if (range) {
      // "Horizontal" range select. Potentially select all the in-between events.
      if (ref.type === 'event' && active.type === 'event') {
        dispatch(maybeRangeSelectEventRefs({ active, newlySelected: ref, blockId }));
        return;
      }

      // If one of the refs is an event but not the other, just select it.
      if (ref.type === 'event' || active.type === 'event') {
        dispatch(
          setSelectedEventsAndGroups({
            blockId,
            active: ref,
            refs: [ref],
          }),
        );
        return;
      }

      // If neither of the cases above are true, try to do a
      // "Vertical" range select, selecting all the in-between rows.
      const selectRowKeyIdx = orderedRowRefs.findIndex((r) => r.id === ref.id);
      const selectedRowRefs = orderedRowRefs.slice(
        Math.min(currRowKeyIdx, selectRowKeyIdx),
        Math.max(currRowKeyIdx, selectRowKeyIdx) + 1,
      );

      dispatch(
        setSelectedEventsAndGroups({
          blockId,
          active,
          refs: selectedRowRefs,
        }),
      );
      return;
    }

    if (toggle) {
      const newSelectedRefs = refs.filter((r) => !isEqual(r, ref));
      const alreadySelected = newSelectedRefs.length !== refs.length;
      if (!alreadySelected) {
        // append to the selection
        dispatch(
          setSelectedEventsAndGroups({
            blockId,
            active: ref,
            refs: [...newSelectedRefs, ref],
          }),
        );
        return;
      }

      if (newSelectedRefs.length === 0) {
        // nothing left to select; clear the selection
        dispatch(clearSelection());
        return;
      }

      const deselectActive = isEqual(ref, active);
      const newActive = deselectActive
        ? orderBy(newSelectedRefs, [(r) => orderedRowRefs.findIndex((or) => or.id === r.id)])[0]
        : active;

      dispatch(
        setSelectedEventsAndGroups({
          blockId,
          active: newActive,
          refs: newSelectedRefs,
        }),
      );
    }
  };

export const selectTimelineInitiativeIfUnselected = (
  blockId: BlockId,
  ref: PlanTimelineItemRef,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const selection = selectedPlanTimelineItemRefSelector(state);
    const isSelected =
      selection != null &&
      selection.blockId === blockId &&
      selection.refs.some((r) => isEqual(r, ref));
    if (isSelected) {
      return;
    }

    dispatch(
      selectPlanTimelineItemRef({
        ...ref,
        blockId,
      }),
    );
  };
};
