import React from 'react';

import { ImpactType } from 'generated/graphql';
import { getImpactTooltipText } from 'helpers/eventGroups';
import useAppStore from 'hooks/useAppStore';
import { CellImpact } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { eventGroupsByIdForLayerSelector } from 'selectors/eventsAndGroupsSelector';

interface Props {
  cellImpact: CellImpact;
  layerId: LayerId | undefined;
}

/**
 * N.B. This component is only used to render tooltip content; it doesn't need to stay up to date with
 * changes in state because it's lazily rendered.
 */
const CellImpactTooltipContent: React.FC<Props> = ({ cellImpact, layerId }) => {
  const store = useAppStore();
  const state = store.getState();
  const eventGroupsById = eventGroupsByIdForLayerSelector(state, { layerId });
  const eventGroupIds =
    cellImpact.impactType === ImpactType.Set
      ? [cellImpact.eventGroupId]
      : cellImpact.valuesWithEventGroups.map((v) => v.eventGroupId);

  const content = getImpactTooltipText(eventGroupsById, eventGroupIds);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return content == null ? null : <>{content}</>;
};

export default React.memo(CellImpactTooltipContent);
