import { BusinessObject as GqlBusinessObject, ValueType } from 'generated/graphql';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { CollectionEntry } from 'reduxStore/models/collections';
import { EventGroupId } from 'reduxStore/models/events';
import { ValueTimeSeries } from 'reduxStore/models/timeSeries';

export const BASE_DEFAULT_VALUE_KEY = 'none';

export type BusinessObjectId = string;
export type BusinessObjectFieldId = string;

export type BusinessObject = {
  id: BusinessObjectId;
  name: string;
  specId: BusinessObjectSpecId;
  fields: BusinessObjectField[];
  defaultEventGroupId?: EventGroupId;
  remoteId?: string;
  extKey?: string;
  databaseConfigId?: string;
  collectionEntry?: CollectionEntry;
};

export type BusinessObjectField = {
  id: BusinessObjectFieldId;
  fieldSpecId: BusinessObjectFieldSpecId;
  value?: BusinessObjectFieldValue;
};

export type BusinessObjectFieldValue = {
  type: ValueType;
  actuals: {
    formula?: string;
    timeSeries?: ValueTimeSeries;
  };
  initialValue?: string;
};

export type PopulatedBusinessObjectField = BusinessObjectField & {
  objectId: BusinessObjectId;
  objectName: string;
  objectSpecId: string;
  objectSpecName: string;
  fieldSpec: BusinessObjectFieldSpec;
  isStartDate: boolean;
};

export function isGqlBusinessObject(o: unknown): o is GqlBusinessObject {
  return (
    typeof o === 'object' && o != null && 'fields' in o && 'id' in o && 'name' in o && 'specId' in o
  );
}
