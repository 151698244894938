import { Box } from '@chakra-ui/react';
import { noop } from 'lodash';
import { useCallback } from 'react';

import DriverSubMenu from 'components/BusinessObjectTable/SettingsPopoverContents/DimensionalDatabaseNewProperty/DriverSubMenu';
import { DriverPropertyCreateData } from 'generated/graphql';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { toggleShowColumnAsTimeSeries } from 'reduxStore/actions/blockMutations';
import { updateDriverPropertyMapping } from 'reduxStore/actions/businessObjectSpecMutations';
import { removeDriverMapping } from 'reduxStore/actions/driverMutations';
import { blockConfigBusinessObjectSpecIdSelector } from 'selectors/blocksSelector';
import { visibleFieldSpecTimeSeriesSelector } from 'selectors/visibleFieldSpecTimeSeriesSelector';

interface Props {
  driverPropertyId: string;
  mappedDriverId?: string;
  existingDriverId: string;
  onClose?: () => void;
}
const UpdateDriverMappingPopover = ({
  driverPropertyId,
  existingDriverId,
  mappedDriverId,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const businessObjectSpecId = useAppSelector((state) =>
    blockConfigBusinessObjectSpecIdSelector(state, blockId),
  );
  const visibleTimeSeriesColumns = useAppSelector((state) =>
    visibleFieldSpecTimeSeriesSelector(state, blockId),
  );
  const driverPropertyDisplayedAsTimerSeries = visibleTimeSeriesColumns?.includes(driverPropertyId);

  const onAddExistingDriver = useCallback(
    (driverId: string) => {
      if (businessObjectSpecId == null) {
        return;
      }
      if (driverId === mappedDriverId) {
        dispatch(removeDriverMapping({ driverId: existingDriverId }));
        if (onClose != null) {
          onClose();
        }
        return;
      }

      const onSuccess = (driverPropertyData: DriverPropertyCreateData) => {
        // If the target driver property is not displayed as a time series, we want to toggle it
        if (!driverPropertyDisplayedAsTimerSeries) {
          dispatch(toggleShowColumnAsTimeSeries({ blockId, columnKey: driverPropertyData.id }));
        }
        if (onClose != null) {
          onClose();
        }
      };

      dispatch(
        updateDriverPropertyMapping({
          objectSpecId: businessObjectSpecId,
          driverPropertyId,
          driverId: existingDriverId,
          updatedMappedDriverId: driverId,
          onSuccess,
        }),
      );
    },
    [
      mappedDriverId,
      businessObjectSpecId,
      dispatch,
      driverPropertyId,
      existingDriverId,
      onClose,
      driverPropertyDisplayedAsTimerSeries,
      blockId,
    ],
  );

  return (
    <Box boxShadow="menu" borderRadius="md" backgroundColor="surface">
      <DriverSubMenu
        onBack={noop}
        onClose={onClose ?? noop}
        onAddExistingDriver={onAddExistingDriver}
        onCreateNewDriver={noop}
        existingDriverId={existingDriverId}
        disableNewDriver
      />
    </Box>
  );
};

export default UpdateDriverMappingPopover;
