import { DriverGroupCreateInput, SubmodelUpdateInput } from 'generated/graphql';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { getUpdateDriverReferenceMutation } from 'reduxStore/actions/driverMutations';
import {
  getMutationThunkAction,
  submitAutoLayerizedMutations,
} from 'reduxStore/actions/submitDatasetMutation';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { AppThunk } from 'reduxStore/store';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import { driversByIdForCurrentLayerSelector } from 'selectors/driversSelector';
import { navSubmodelIdSelector } from 'selectors/navSubmodelSelector';
import { blockIdBySubmodelIdSelector } from 'selectors/submodelPageSelector';
import {
  submodelDriverIdsByGroupIdSelector,
  submodelTableGroupsSelector,
} from 'selectors/submodelTableGroupsSelector';
import { submodelsByIdSelector } from 'selectors/submodelsByIdSelector';

const mutationActions = {
  createDriverGroup: ({
    driverIds,
    ...input
  }: DriverGroupCreateInput & { driverIds?: DriverId[] }): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const { name } = input;
      const submodelId = navSubmodelIdSelector(state);
      if (submodelId == null) {
        return;
      }
      const submodelBlockId = blockIdBySubmodelIdSelector(state)[submodelId];
      if (submodelBlockId == null) {
        return;
      }
      const groupWithDrivers = submodelTableGroupsSelector(state);
      const sortedDriverGroupIds = groupWithDrivers.map(({ id }) => id ?? null);
      const existingGroup = groupWithDrivers.find((group) => group.name === name);
      if (existingGroup != null) {
        return;
      }

      const driversById = driversByIdForCurrentLayerSelector(state);

      dispatch(
        submitAutoLayerizedMutations('create-driver-group', [
          {
            newDriverGroups: [input],
            updateSubmodels: [
              {
                id: submodelId,
                sortedDriverGroupIds: [...sortedDriverGroupIds, input.id],
              },
            ],
            updateDrivers: driverIds
              ?.map((driverId) => {
                const driver = driversById[driverId];
                if (driver == null) {
                  return null;
                }
                return getUpdateDriverReferenceMutation({
                  driver,
                  groupId: input.id,
                  blockId: submodelBlockId,
                });
              })
              .filter(isNotNull),
          },
        ]),
      );
    };
  },
  renameDriverGroup: getMutationThunkAction<{ id: DriverGroupId; name: string }>(
    ({ id, name }, getState) => {
      const state = getState();
      const group = driverGroupsByIdSelector(state)[id];
      const currentName = group?.name;
      if (group == null || name === currentName) {
        return null;
      }

      const groupWithDrivers = submodelTableGroupsSelector(state);
      const existingGroup = groupWithDrivers.find((g) => g.name === name);
      if (existingGroup != null) {
        return null;
      }

      return {
        renameDriverGroups: [
          {
            id,
            name,
          },
        ],
      };
    },
  ),
  deleteDriverGroup: ({ id }: { id: DriverGroupId }): AppThunk<void> => {
    return (dispatch, getState) => {
      const state = getState();
      const submodelId = navSubmodelIdSelector(state) ?? '';
      const driverIds = submodelDriverIdsByGroupIdSelector(state, { groupId: id });
      let updateSubmodels: SubmodelUpdateInput[] | undefined;
      const submodel = safeObjGet(submodelsByIdSelector(state)[submodelId]);
      if (submodel != null) {
        updateSubmodels = [
          {
            id: submodelId,
            sortedDriverGroupIds: submodel.sortedDriverGroupIds?.filter((el) => el !== id),
          },
        ];
      }

      const driversById = driversByIdForCurrentLayerSelector(state);
      const mutation = {
        updateSubmodels,
        updateDrivers: driverIds
          .map((driverId) => {
            const driver = safeObjGet(driversById[driverId]);
            if (driver == null) {
              return null;
            }
            const existingRef = driver.driverReferences?.find((ref) => ref.groupId === id);
            // eslint-disable-next-line deprecation/deprecation
            if (existingRef == null && driver.groupId !== id) {
              return null;
            }

            if (existingRef != null) {
              return {
                id: driverId,
                groupId: '',
                driverReferences: [
                  ...(driver.driverReferences ?? []).filter((ref) => ref.groupId !== id),
                  { ...existingRef, groupId: undefined },
                ],
              };
              // eslint-disable-next-line deprecation/deprecation
            } else if (driver.groupId === id) {
              return {
                id: driverId,
                groupId: '',
              };
            }
            return null;
          })
          .filter(isNotNull),
        deleteDriverGroups: [{ id }],
      };
      dispatch(submitAutoLayerizedMutations('delete-driver-group', [mutation]));
    };
  },
};

export const { createDriverGroup, renameDriverGroup, deleteDriverGroup } = mutationActions;
