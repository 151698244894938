import useAppSelector from 'hooks/useAppSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { MonthKey } from 'types/datetime';

export function useIsActuals(monthKey: MonthKey, layerId?: string) {
  const lastActualsMonthKey = useAppSelector((state) =>
    lastActualsMonthKeyForLayerSelector(state, { layerId }),
  );
  return monthKey <= lastActualsMonthKey;
}
