import { useMergeRefs } from '@chakra-ui/react';
import Joi from 'joi';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import EscapableInput, { SaveHandler } from 'components/EscapableInput/EscapableInput';
import { joiDefault } from 'helpers/errorMessages';
import useAppSelector from 'hooks/useAppSelector';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { businessObjectSpecFieldNamesSelector } from 'selectors/businessObjectPropertyNamesSelector';

interface Props {
  objectSpecId: BusinessObjectSpecId;
  currentName: string;
  onUpdate: (name: string) => void;
  isFocused?: boolean;
  onClose: () => void;
}

const ObjectFieldNameInput = React.forwardRef<HTMLInputElement, Props>(
  ({ isFocused, currentName, onUpdate, objectSpecId, onClose }, passedRef) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const ref = useMergeRefs(passedRef, inputRef);
    const fieldNames = useAppSelector((state) =>
      businessObjectSpecFieldNamesSelector(state, objectSpecId),
    );
    const onSave = useCallback<SaveHandler>(
      (name: string, { reason }) => {
        onUpdate(name);
        if (reason === 'enter') {
          onClose();
        }
      },
      [onClose, onUpdate],
    );

    const objectFieldNameValidator = useMemo(() => {
      return Joi.object({
        objectFieldName: Joi.string()
          .required()
          .min(1)
          .insensitive()
          .invalid(...fieldNames.filter((n) => n !== currentName))
          .trim()
          .messages(joiDefault('property')),
      });
    }, [fieldNames, currentName]);

    useEffect(() => {
      if (isFocused === true) {
        // hacky: ensure the input is rendered before focusing
        window.requestAnimationFrame(() => inputRef.current?.focus());
      } else if (isFocused === false) {
        inputRef.current?.blur();
      }
    }, [isFocused]);

    const onCancel = useCallback(
      (reason: 'escape' | 'noop') => {
        if (reason === 'escape') {
          onClose();
        }
      },
      [onClose],
    );

    return (
      <EscapableInput
        name="objectFieldName"
        ref={ref}
        validationSchema={objectFieldNameValidator}
        variant="white"
        size="sm"
        fontSize="xs"
        fontWeight="medium"
        onSave={onSave}
        onCancel={onCancel}
        _focus={{ borderColor: 'selection.500' }}
        defaultValue={currentName}
        selectOnFocus={false}
      />
    );
  },
);

export default ObjectFieldNameInput;
