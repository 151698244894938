import { createSelector } from '@reduxjs/toolkit';
import { deepEqual } from 'fast-equals';
import { createCachedSelector } from 're-reselect';

import { DatabaseGroupKey } from 'config/businessObjects';
import { BlockConfig } from 'generated/graphql';
import { BlockId, ObjectTableBlockColumnKey } from 'reduxStore/models/blocks';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { AttributeId } from 'reduxStore/models/dimensions';
import { DatabaseColumn } from 'reduxStore/reducers/blockLocalStateSlice';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { blockIdSelector, fieldSelector } from 'selectors/constSelectors';
import { ParametricSelector, Selector } from 'types/redux';

const recentlyCreatedAttributeIdsByBlockIdSelector: Selector<Record<BlockId, AttributeId[]>> = (
  state,
) => state.blockLocalState.recentlyCreatedAttributeIdsByBlockId;

const currentlyEditingPropertyFormulaSelector: Selector<DatabaseColumn | undefined> = (state) =>
  state.blockLocalState.currentlyEditingPropertyFormula;

const savedScrollLeftByBlockIdSelector: Selector<Record<BlockId, number>> = (state) =>
  state.blockLocalState.savedScrollLeftByBlockId;

const EMPTY_NEWLY_CREATED_ATTRIBUTE_IDS: AttributeId[] = [];
export const newlyCreatedAttributeIdsForBlockSelector: ParametricSelector<BlockId, AttributeId[]> =
  createCachedSelector(
    recentlyCreatedAttributeIdsByBlockIdSelector,
    blockIdSelector,
    (recentlyCreatedAttributeIdsByBlockId, blockId) => {
      if (recentlyCreatedAttributeIdsByBlockId == null) {
        return EMPTY_NEWLY_CREATED_ATTRIBUTE_IDS;
      }
      const recentlyCreatedAttributeIdsForBlock =
        recentlyCreatedAttributeIdsByBlockId[blockId] ?? EMPTY_NEWLY_CREATED_ATTRIBUTE_IDS;
      return recentlyCreatedAttributeIdsForBlock;
    },
  )(blockIdSelector);

type ColumnProps = {
  blockId: BlockId;
  columnKey: ObjectTableBlockColumnKey;
  objectId?: BusinessObjectId;
  groupKey?: DatabaseGroupKey;
};

export const isEditingPropertyFormulaForColumnSelector: ParametricSelector<ColumnProps, boolean> =
  createCachedSelector(
    currentlyEditingPropertyFormulaSelector,
    fieldSelector<ColumnProps, 'blockId'>('blockId'),
    fieldSelector('columnKey'),
    fieldSelector('objectId'),
    fieldSelector('groupKey'),
    (currentlyEditingPropertyFormula, blockId, columnKey, objectId, groupKey) => {
      let groupKeyToCompare = groupKey;
      if (currentlyEditingPropertyFormula == null) {
        return false;
      }
      if (currentlyEditingPropertyFormula.groupKey == null) {
        // Ignore groupKey if editing column is null
        groupKeyToCompare = undefined;
      }

      const formulaCopy = { ...currentlyEditingPropertyFormula };
      // If the requesting databaseColumn is not specific about the object id or group id then
      // we will ignore it
      if (objectId == null) {
        formulaCopy.objectId = undefined;
      }
      if (groupKey == null) {
        formulaCopy.groupKey = undefined;
      }
      return deepEqual(formulaCopy, { blockId, columnKey, objectId, groupKey: groupKeyToCompare });
    },
  )(
    (_state, column) =>
      `${column.blockId},${column.columnKey},${column.objectId},${column.groupKey}`,
  );

export const savedBlockScrollLeftSelector: ParametricSelector<BlockId, number> =
  createCachedSelector(
    savedScrollLeftByBlockIdSelector,
    blockIdSelector,
    (savedScrollLeftByBlockId, blockId) => {
      if (savedScrollLeftByBlockId == null) {
        return 0;
      }
      return savedScrollLeftByBlockId[blockId] ?? 0;
    },
  )(blockIdSelector);

const localBlockConfigsSelector: Selector<NullableRecord<BlockId, BlockConfig>> = (state) =>
  state.blockLocalState.localBlockConfigs;

export const visibleLocalBlockConfigsSelector: Selector<NullableRecord<
  BlockId,
  BlockConfig
> | null> = createSelector(
  localBlockConfigsSelector,
  accessCapabilitiesSelector,
  (localBlockConfigs, { isOrgGuest }) => {
    return isOrgGuest ? localBlockConfigs : null;
  },
);
