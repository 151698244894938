import CursorPoint from 'components/DriverChart/CursorPoint';
import { DEFAULT_COMPARISON_LAYER_COLOR } from 'config/scenarioComparison';
import { getDateTimeFromMonthKey, isDateBetween } from 'helpers/dates';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartContext from 'hooks/useChartContext';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { chartDisplayMonthKeySelector } from 'selectors/driverChartSelectors';
import {
  driverTimeSeriesForLayerSelector,
  driverTimeSeriesSourceByMonthKeySelector,
} from 'selectors/driverTimeSeriesSelector';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';
import { comparisonLayerColorByIdForBlockSelector } from 'selectors/rollupSelector';

interface Props {
  driverId: DriverId;
  layerId: LayerId;
}

// Show the hovered point when a cursor is over the graph or just show the
// default point (last close).
const CurrentPoints: React.FC<Props> = ({ driverId, layerId }) => {
  const { blockId } = useBlockContext();
  const { timeScale, valueScale, driverIdsToColors, startDateTime, endDateTime } =
    useChartContext();
  const lastActualsMonthKey = useAppSelector(datasetLastActualsMonthKeySelector);
  const cursorMonthKey = useAppSelector(chartDisplayMonthKeySelector);

  const isLastActualVisible = isDateBetween(lastActualsMonthKey, startDateTime, endDateTime);
  const defaultDate = isLastActualVisible ? lastActualsMonthKey : endDateTime;

  const monthKey = cursorMonthKey ?? defaultDate;
  const layerColor = useAppSelector(
    (state) => comparisonLayerColorByIdForBlockSelector(state, blockId)[layerId],
  );

  const isActuals = useAppSelector(
    (state) =>
      driverTimeSeriesSourceByMonthKeySelector(state, { id: driverId })[monthKey] === 'actuals',
  );
  const chartX = timeScale(
    getDateTimeFromMonthKey(monthKey).endOf('month').startOf('second').toJSDate(),
  );

  const ts = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: driverId, layerId }),
  );

  const timeValue = ts[monthKey];
  const chartY = valueScale(timeValue);
  let color: string;

  if (layerColor != null || driverIdsToColors[driverId] != null) {
    color = layerColor ?? driverIdsToColors[driverId];
  } else {
    color = isActuals ? 'actuals' : DEFAULT_COMPARISON_LAYER_COLOR;
  }

  if (chartY == null || isNaN(chartY)) {
    return null;
  }

  return <CursorPoint x={chartX} y={chartY} color={color} />;
};

export default CurrentPoints;
