import { ComponentSingleStyleConfig } from '@chakra-ui/theme';

export default {
  baseStyle: {
    borderColor: 'gray.300',
    borderRadius: '0.125rem',
    boxShadow: 'none',
    borderWidth: '0.0625rem',
    borderBottomWidth: '0.0625rem',
    bg: 'transparent',
    width: '1.125rem',
    height: '1.125rem',
    fontSize: '0.6875rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
} as ComponentSingleStyleConfig;
