import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import {
  RequestValueProps,
  useWebWorkerDataProviderContext,
} from 'components/WebWorkerDataProvider/Context';

const DELIMITER = '.';
const getHash = ({
  id,
  layerId,
  dateRange,
  type,
  ignoreEventIds,
  includeObjectSpecEvaluation,
}: RequestValueProps) => {
  const dateRangeHash = dateRange.map((date) => date.toMillis()).join(DELIMITER);
  const eventIdsHash = ignoreEventIds?.map((eventId) => eventId).join(DELIMITER) ?? '';
  return [id, layerId, dateRangeHash, type, eventIdsHash, includeObjectSpecEvaluation].join(
    DELIMITER,
  );
};

/**
 * Not experimentally determined.
 * But these seem to work after playing around with multiple combinations.
 */
const FLUSH_DELAY_MS = 500;
const REQUEST_BATCH_SIZE = 60;

/**
 * Process calculation requests in batches and periodically to boost UI responsiveness
 */
const useCreateCalculationProcessor = (): BatchCalculationProcessor => {
  const { requestBatch, requestValue } = useWebWorkerDataProviderContext();
  const queueIsRunningRef = useRef(false);
  const disposeFnsRef = useRef<Map<string, () => void>>(new Map());
  const pendingRequestsRef = useRef<RequestValueProps[]>([]);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const process = useCallback(
    (requests: RequestValueProps[]) => {
      if (requests.length === 0) {
        return;
      }

      requestBatch(() => {
        requests.forEach((request) => {
          const disposeFn = requestValue(request);
          const hash = getHash(request);
          disposeFnsRef.current.set(hash, disposeFn);
        });
      });
    },
    [requestBatch, requestValue],
  );

  const stopQueue = useCallback(() => {
    if (!queueIsRunningRef.current) {
      return;
    }

    queueIsRunningRef.current = false;
    if (timeoutIdRef.current != null) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  }, []);

  const processQueuePeriodically = useCallback(() => {
    const flush = () => {
      const toBeProcessed = pendingRequestsRef.current.splice(0, REQUEST_BATCH_SIZE);
      process(toBeProcessed);
    };
    if (pendingRequestsRef.current.length > 0) {
      flush();
      timeoutIdRef.current = setTimeout(processQueuePeriodically, FLUSH_DELAY_MS);
    } else {
      stopQueue();
    }
  }, [process, stopQueue]);

  const startQueue = useCallback(() => {
    if (queueIsRunningRef.current) {
      return;
    }

    queueIsRunningRef.current = true;
    timeoutIdRef.current = setTimeout(processQueuePeriodically, FLUSH_DELAY_MS);
  }, [processQueuePeriodically]);

  const requestBatchCalculations = useCallback(
    (requests: RequestValueProps[]) => {
      if (requests.length === 0) {
        return;
      }
      pendingRequestsRef.current.push(...requests);
      startQueue();
    },
    [startQueue],
  );

  const cancelPendingCalculationRequests = useCallback((requests: RequestValueProps[]) => {
    const toBeCanceled = new Set(requests.map((request) => getHash(request)));
    pendingRequestsRef.current = pendingRequestsRef.current.filter(
      (pendingRequest) => !toBeCanceled.has(getHash(pendingRequest)),
    );
  }, []);

  useEffect(() => {
    const fns = Array.from(disposeFnsRef.current.values());
    return () => {
      stopQueue();
      fns.forEach((fn) => fn());
    };
  }, [stopQueue]);

  return useMemo(
    () => ({ requestBatchCalculations, cancelPendingCalculationRequests, startQueue, stopQueue }),
    [cancelPendingCalculationRequests, requestBatchCalculations, startQueue, stopQueue],
  );
};

interface BatchCalculationProcessor {
  requestBatchCalculations: (requests: RequestValueProps[]) => void;
  cancelPendingCalculationRequests: (requests: RequestValueProps[]) => void;
  startQueue: () => void;
  stopQueue: () => void;
}

interface BatchCalculationProcessorProviderProps {
  children: React.ReactNode;
}

const BatchCalculationProcessorContext = createContext<BatchCalculationProcessor | null>(null);

export const useBatchCalculationProcessor = () => useContext(BatchCalculationProcessorContext);

export const BatchCalculationProcessorProvider: React.FC<
  BatchCalculationProcessorProviderProps
> = ({ children }) => {
  const processor = useCreateCalculationProcessor();
  return (
    <BatchCalculationProcessorContext.Provider value={processor}>
      {children}
    </BatchCalculationProcessorContext.Provider>
  );
};
