import { createSelector } from '@reduxjs/toolkit';

import { FormulaCalculationContext } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCalculationContext';
import {
  attributesBySubDriverIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import { enableFormulaCalculationExplanationsSelector } from 'selectors/featureFlagsSelector';
import { formulaEvaluatorForLayerSelector } from 'selectors/formulaEvaluatorSelector';

export const formulaCalculationContextSelector = createSelector(
  formulaEvaluatorForLayerSelector,
  driversByIdForLayerSelector,
  attributesBySubDriverIdSelector,
  enableFormulaCalculationExplanationsSelector,
  (evaluator, driversById, attributesBySubDriverId, enableFormulaCalculationExplanations) => {
    return enableFormulaCalculationExplanations
      ? new FormulaCalculationContext({ evaluator, driversById, attributesBySubDriverId })
      : undefined;
  },
);
