import { uniq } from 'lodash';

import { AccessResourceType, AccessRule, AccessorEntityType, OrgRole } from 'generated/graphql';
import { accessorEntityToRole } from 'helpers/permissions';
import { isNotNull } from 'helpers/typescript';
import { AccessResource } from 'reduxStore/models/accessResources';
import { BlocksPageId } from 'reduxStore/models/blocks';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import { UserId } from 'reduxStore/models/user';

enum RequiredRole {
  OwnerRole,
  AdminRole,
  ManagerRole,
  GuestRole,
  MemberRole,
}

const ORG_ROLE_TO_REQUIRED_ROLE: Record<OrgRole, RequiredRole> = {
  [OrgRole.Owner]: RequiredRole.OwnerRole,
  [OrgRole.Admin]: RequiredRole.AdminRole,
  [OrgRole.Manager]: RequiredRole.ManagerRole,
  [OrgRole.Member]: RequiredRole.MemberRole,
  [OrgRole.Guest]: RequiredRole.GuestRole,
};

const REQUIRED_ROLE_TO_ALLOWED_ORG_ROLES: Record<RequiredRole, OrgRole[]> = {
  [RequiredRole.OwnerRole]: [OrgRole.Owner],
  [RequiredRole.AdminRole]: [OrgRole.Owner, OrgRole.Admin],
  [RequiredRole.ManagerRole]: [OrgRole.Owner, OrgRole.Admin, OrgRole.Manager],
  [RequiredRole.MemberRole]: [OrgRole.Owner, OrgRole.Admin, OrgRole.Manager, OrgRole.Member],
  [RequiredRole.GuestRole]: [
    OrgRole.Owner,
    OrgRole.Admin,
    OrgRole.Manager,
    OrgRole.Member,
    OrgRole.Guest,
  ],
};

// Granting more permissive rules means that you also implicitly have the less permissive rules                                                                                                               █
const ACCESS_RULE_INHERITANCE_MATRIX: Record<AccessRule, AccessRule[]> = {
  [AccessRule.Read]: [AccessRule.Read, AccessRule.Write, AccessRule.Full],
  [AccessRule.Write]: [AccessRule.Write, AccessRule.Full],
  [AccessRule.Full]: [AccessRule.Full],
  [AccessRule.Revoked]: [AccessRule.Revoked],
};

const minRuleSatisfied = (minRule: AccessRule, grantedRule: AccessRule) =>
  ACCESS_RULE_INHERITANCE_MATRIX[minRule].includes(grantedRule);

type ResourceLookup = Pick<AccessResource, 'resourceId' | 'type'> & {
  parent?: ResourceLookup;
};

export class AccessCapabilitiesProvider {
  private currUserId: UserId;
  private assignedRole: OrgRole;
  private isRunwaySuperUser: boolean;
  // Assumes that this is filtered down to only have entries that are relevant
  // to the user or their role.
  private accessResources: AccessResource[];
  private layerCreatedByUserIdById: Record<LayerId, UserId | undefined>;

  constructor({
    orgRole,
    userId,
    isRunwaySuperUser,
    accessResources,
    layerCreatedByUserIdById,
  }: {
    orgRole: OrgRole | undefined;
    userId: UserId | undefined;
    isRunwaySuperUser: boolean;
    accessResources: AccessResource[];
    layerCreatedByUserIdById: Record<LayerId, UserId | undefined>;
  }) {
    this.currUserId = userId ?? '';
    this.assignedRole = orgRole ?? OrgRole.Guest;
    this.isRunwaySuperUser = isRunwaySuperUser;
    this.accessResources = accessResources;
    this.layerCreatedByUserIdById = layerCreatedByUserIdById;
  }

  private _allows({ requiredRole }: { requiredRole: RequiredRole; useOverride?: boolean }) {
    if (this.isRunwaySuperUser) {
      return true;
    }

    const asRole = this.assignedRole;
    return REQUIRED_ROLE_TO_ALLOWED_ORG_ROLES[requiredRole]?.includes(asRole) ?? false;
  }

  // HELPERS
  private _isOrgOwner(): boolean {
    return this.assignedRole === OrgRole.Admin || this.assignedRole === OrgRole.Owner;
  }

  private _isOrgMember(): boolean {
    return (
      this.isOrgAdmin ||
      this._allows({
        requiredRole: RequiredRole.MemberRole,
      })
    );
  }

  private _isOrgManager(): boolean {
    return (
      this.assignedRole === OrgRole.Manager ||
      this._allows({
        requiredRole: RequiredRole.ManagerRole,
      })
    );
  }

  private _isOrgGuest(): boolean {
    return this.assignedRole === OrgRole.Guest;
  }

  private hasRevokedPageAccess(resourceId: string): boolean {
    const currentAccessResource = this.accessResources.find(
      (r) => r.resourceId === resourceId && r.type === AccessResourceType.Page,
    );

    return (
      currentAccessResource != null &&
      currentAccessResource.accessControlList.some(
        (accessControlEntry) =>
          accessControlEntry.accessRule === AccessRule.Revoked &&
          accessControlEntry.entityWithAccess.type === AccessorEntityType.User &&
          accessControlEntry.entityWithAccess.id === this.currUserId,
      )
    );
  }

  private hasRestrictedPageAccess(resourceId: string): boolean {
    const currentAccessResource = this.accessResources.find(
      (r) => r.resourceId === resourceId && r.type === AccessResourceType.Page,
    );

    return (
      currentAccessResource != null &&
      currentAccessResource.accessControlList.some(
        (accessControlEntry) =>
          accessControlEntry.accessRule !== AccessRule.Full &&
          accessControlEntry.entityWithAccess.type === AccessorEntityType.User &&
          accessControlEntry.entityWithAccess.id === this.currUserId,
      )
    );
  }

  private get canShareAllPages(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  // Data level permissions
  canSharePage(pageId: BlocksPageId): boolean {
    return this.hasFullPageAccess(pageId);
  }

  canWritePage(pageId: BlocksPageId): boolean {
    if (this.canSharePage(pageId)) {
      return true;
    }
    return this.hasAccessOfMinRule(
      { resourceId: pageId, type: AccessResourceType.Page },
      AccessRule.Write,
    );
  }

  hasFullPageAccess(pageId: BlocksPageId): boolean {
    if (this.canShareAllPages) {
      return true;
    }

    if (this.isOrgManager && !this.hasRestrictedPageAccess(pageId)) {
      return true;
    }

    return this.hasAccessOfMinRule(
      { resourceId: pageId, type: AccessResourceType.Page },
      AccessRule.Full,
    );
  }

  canReadPage(pageId: BlocksPageId): boolean {
    if (this.isRunwaySuperUser) {
      return true;
    }

    if (this.hasRevokedPageAccess(pageId)) {
      return false;
    }

    if (this.canWritePage(pageId)) {
      return true;
    }

    return this.hasAccessOfMinRule(
      { resourceId: pageId, type: AccessResourceType.Page },
      AccessRule.Read,
    );
  }

  private get canReadAllLayersOnAllPages(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  canReadLayerGlobally(layerId: LayerId): boolean {
    // Currently we don't create an access resource for the owenr
    // automatically. If we change that, we won't need this.
    const layerCreatedByUserId = this.layerCreatedByUserIdById[layerId];
    if (layerCreatedByUserId === this.currUserId) {
      return true;
    }

    if (this.canReadAllLayersOnAllPages) {
      return true;
    }

    // guests, unlike any other role, must be manually given access to layers for each page
    if (!this.isOrgMember) {
      return false;
    }

    // Members+ always have access to the default layer. If we want to allow
    // managing this more granularly in the future we should remove this and
    // instead create access entries for the default layer like any other.
    if (layerId === DEFAULT_LAYER_ID) {
      return true;
    }

    return this.hasAccessOfMinRule(
      { resourceId: layerId, type: AccessResourceType.Layer },
      AccessRule.Read,
    );
  }

  canReadLayerOnPage(pageId: BlocksPageId, layerId: LayerId): boolean {
    if (!this.canReadPage(pageId)) {
      return false;
    }

    // If the user has explicit global layer access, assume they can read it on
    // any page. This assumption may not hold true in the future but for now I
    // think it's ok.
    if (this.canReadLayerGlobally(layerId)) {
      return true;
    }

    // Currently we don't create an access resource for the owner
    // automatically. If we change that, we won't need this.
    const layerCreatedByUserId = this.layerCreatedByUserIdById[layerId];

    if (layerCreatedByUserId === this.currUserId || this.canReadAllLayersOnAllPages) {
      return true;
    }

    return this.hasAccessOfMinRule(
      {
        resourceId: layerId,
        type: AccessResourceType.Layer,
        parent: { resourceId: pageId, type: AccessResourceType.Page },
      },
      AccessRule.Read,
    );
  }

  private hasAccessOfMinRule(lookupResource: ResourceLookup, minRule: AccessRule): boolean {
    const visitedParentIds = new Set<string>();

    const doLookup = (resource: ResourceLookup): AccessResource | undefined => {
      const resolvedParent = resource.parent != null ? doLookup(resource.parent) : null;
      if (resolvedParent != null) {
        if (visitedParentIds.has(resolvedParent.id)) {
          throw new Error('parent resource loop detected');
        }
        visitedParentIds.add(resolvedParent.id);
      }

      return findMatchingResource(this.accessResources, {
        ...resource,
        parentId: resolvedParent?.id,
      });
    };

    const resource = doLookup(lookupResource);
    if (resource == null) {
      return false;
    }

    return resource.accessControlList.some((accessControlEntry) => {
      if (accessControlEntry.entityWithAccess.type === AccessorEntityType.User) {
        return (
          accessControlEntry.entityWithAccess.id === this.currUserId &&
          minRuleSatisfied(minRule, accessControlEntry.accessRule)
        );
      }

      return minRuleSatisfied(minRule, accessControlEntry.accessRule);
    });
  }

  // TODO: This is a bit of an old approach for implementing restricted data.
  // We may want to instead come up with a bit more of a general approach that
  // doens't rely on inferring restrictions based off of the existence of
  // access resource entries. Instead, we could have a concept of allowing
  // users to configure "minimum role" requirements for resources. Or, more
  // generally, an idea of constraints where "minimum role" is one such
  // constraint.
  //
  // If there are no access rules, then the resource is not restricted. If there
  // are access rules, then the resource is restricted if there isn't an access
  // entry for the user's role or a role underneath them in the hierarchy.
  shouldDenyRoleAccessByAccessEntity(resource: AccessResource | undefined): boolean {
    if (resource == null) {
      return false;
    }

    const roleEntries = uniq(
      resource.accessControlList
        .map((entry) => accessorEntityToRole(entry.entityWithAccess))
        .filter(isNotNull),
    );

    if (roleEntries.length === 0) {
      return false;
    }

    const hasAccess = roleEntries.some((role) =>
      this._allows({ requiredRole: ORG_ROLE_TO_REQUIRED_ROLE[role] }),
    );
    return !hasAccess;
  }

  canImpersonateRole(targetRole: OrgRole): boolean {
    if (this.isRunwaySuperUser) {
      return true;
    }

    if (this.isOrgAdmin) {
      return targetRole !== OrgRole.Admin && targetRole !== OrgRole.Owner;
    }

    return false;
  }

  get isOrgAdmin(): boolean {
    return this._isOrgOwner();
  }

  get isOrgManager(): boolean {
    return this._isOrgManager();
  }

  get isOrgMember(): boolean {
    return this._isOrgMember();
  }

  get isOrgGuest(): boolean {
    return this._isOrgGuest();
  }

  // Integrations
  get canReadIntegrations(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  get canWriteIntegrations(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }

  // Scenarios
  // publish or unpublish scenarios
  get canEditListStatusOfScenario(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  get canMergeScenarios(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  // delete, rename, etc.
  get canEditAnyScenario(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  get canWriteScenarios(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }

  // Plans
  get canReadPlans(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  // MODELS
  get canReadModels(): boolean {
    return this._allows({
      requiredRole: RequiredRole.GuestRole,
    });
  }

  get canWriteModels(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }

  get canCreateModels(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  get canMoveLastClosed(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  // TEMPLATES
  get canReadTemplates(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  // DATABASES
  get canReadDatabases(): boolean {
    return this._allows({
      requiredRole: RequiredRole.GuestRole,
    });
  }

  get canWriteDatabase(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }

  get canCreateDatabases(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  // FOLDERS
  get canCreateFolders(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  get canDestroyFolders(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  get canWriteFolders() {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  get canReadFoldersWithChildren() {
    return this._allows({
      requiredRole: RequiredRole.GuestRole,
    });
  }

  get canReadFoldersWithoutChildren() {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  // DATABASE OBJECTS
  get canWriteObjects(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }

  // PAGES
  get canWritePages(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }

  get canCreatePages(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  // UNLISTED DRIVERS PAGE
  get canReadUnlistedDriversPage(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  get canWriteUnlistedDriversPage(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  // DETAIL PANES
  get canReadIndirectInputs(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  get canReadUsedBy(): boolean {
    return this._allows({
      requiredRole: RequiredRole.ManagerRole,
    });
  }

  // ORGANIZATION
  get canWriteRoles(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  // PERMISSIONS
  get canWritePermissions() {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  get canOverrideRole(): boolean {
    // Dont want to use our overridden role to check if we can override roles.
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  get canEditOperatingModel(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }

  // The main scenario.
  get canViewOperatingModel(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }

  get canCompareLayers(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }

  get canViewOrgHistory(): boolean {
    return this._allows({
      requiredRole: RequiredRole.AdminRole,
    });
  }

  get canSearchRunway(): boolean {
    return this._allows({
      requiredRole: RequiredRole.MemberRole,
    });
  }
}

export function findMatchingResource(
  resources: AccessResource[],
  {
    resourceId,
    type,
    parentId,
  }: Pick<AccessResource, 'resourceId' | 'type'> & {
    parentId?: string;
  },
) {
  return resources.find(
    (r) =>
      r.resourceId === resourceId && r.type === type && (r.parentId ?? null) === (parentId ?? null),
  );
}
