import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import {
  useAddScenarioComparison,
  useRemoveScenarioComparison,
} from 'components/CustomizeDriverChartsBlock/hooks';
import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import LayerName from 'components/LayerName/LayerName';
import DefaultScenarioIcon, {
  DEFAULT_SCENARIO_ICON_COLOR,
} from 'components/ScenarioManagement/DefaultScenarioIcon';
import MainScenarioTag from 'components/ScenarioManagement/MainScenarioTag';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import { extractEmoji } from 'helpers/emoji';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { updateBlockConfig } from 'reduxStore/actions/blockMutations';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { currentLayerIdSelector, namedDatasetVersionListSelector } from 'selectors/layerSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { nonDraftScenarioNamesSelector } from 'selectors/scenariosSelector';
import RepeatClock from 'vectors/RepeatClock';
import XCircleIcon from 'vectors/X-Circle';

interface SelectionListProps {
  blockId: string;
}

interface SelectionProps {
  layerId: string;
  clearSelection?: () => void;
}

const Selection: React.FC<SelectionProps> = ({ layerId, clearSelection }) => {
  return (
    <Box p={1}>
      <Flex alignItems="center">
        <Flex flex={1}>
          <LayerName layerId={layerId} enableIconBgColor />
        </Flex>
        <IconButton
          variant="icon"
          icon={<XCircleIcon float="right" boxSize={3} />}
          aria-label="Close"
          boxSize={5}
          color="gray.400"
          onClick={clearSelection}
        />
      </Flex>
    </Box>
  );
};

const SelectionList: React.FC<SelectionListProps> = (props: SelectionListProps) => {
  const { blockId } = props;
  const dispatch = useAppDispatch();

  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const currLayerId = useAppSelector(currentLayerIdSelector);
  const nonDraftScenariosIncludingCurrent = useAppSelector(nonDraftScenarioNamesSelector);
  const namedDatasetVersions = useAppSelector(namedDatasetVersionListSelector);

  const selectedComparisonLayerIds = useMemo(
    () => comparisonLayerIds.filter((id) => id !== currLayerId),
    [comparisonLayerIds, currLayerId],
  );

  const comparisonItems = useMemo(() => {
    const nonDraftScenarioItems = Object.entries(nonDraftScenariosIncludingCurrent)
      .filter(([id, _name]) => id !== currLayerId)
      .map<SelectItem>(([id, name]) => {
        const [emoji, displayName] = extractEmoji(name);
        return {
          key: id,
          id,
          name: displayName,
          iconColor: DEFAULT_SCENARIO_ICON_COLOR,
          icon: (
            <EmojiIcon
              size="sm"
              emoji={emoji}
              emptyIcon={<DefaultScenarioIcon color="inherit" />}
            />
          ),
        };
      });

    const namedVersionItems = namedDatasetVersions.map<SelectItem>((namedVersion) => ({
      key: namedVersion.id,
      id: namedVersion.id,
      name: namedVersion.name,
      iconColor: 'gray.500',
      icon: <RepeatClock fill="inherit" />,
    }));

    return (
      [...nonDraftScenarioItems, ...namedVersionItems]
        .filter(({ id }) => !comparisonLayerIds.includes(id))
        // always put main scenario at the top
        .sort((a) => (a.id === DEFAULT_LAYER_ID ? -1 : 0))
    );
  }, [currLayerId, namedDatasetVersions, nonDraftScenariosIncludingCurrent, comparisonLayerIds]);

  const addScenarioComparison = useAddScenarioComparison();
  const removeScenarioComparison = useRemoveScenarioComparison();

  const toggleComparisonLayer = useCallback(
    (layerId: string) => {
      dispatch(
        updateBlockConfig({
          blockId,
          fn: (blockConfig) => {
            if (blockConfig.comparisons == null) {
              blockConfig.comparisons = {
                layerIds: [layerId],
                columns: [],
              };
            } else if (blockConfig.comparisons.layerIds.includes(layerId)) {
              blockConfig.comparisons.layerIds = blockConfig.comparisons.layerIds.filter(
                (id) => id !== layerId,
              );
            } else {
              blockConfig.comparisons.layerIds.push(layerId);
            }
          },
        }),
      );

      const isAdd = !comparisonLayerIds.includes(layerId);
      if (isAdd) {
        addScenarioComparison(layerId);
      }
    },
    [addScenarioComparison, blockId, comparisonLayerIds, dispatch],
  );

  const onClickClearAll = useCallback(() => {
    removeScenarioComparison('all');
    dispatch(
      updateBlockConfig({
        blockId,
        fn: (blockConfig) => {
          if (blockConfig.comparisons != null) {
            blockConfig.comparisons.layerIds = [];
          }
        },
      }),
    );
  }, [blockId, dispatch, removeScenarioComparison]);

  const clearSelection = useCallback(
    (layerId: string) => () => {
      dispatch(
        updateBlockConfig({
          blockId,
          fn: (blockConfig) => {
            if (blockConfig.comparisons != null) {
              blockConfig.comparisons.layerIds = blockConfig.comparisons.layerIds.filter(
                (id) => id !== layerId,
              );
            }
          },
        }),
      );
      removeScenarioComparison(layerId);
    },
    [blockId, dispatch, removeScenarioComparison],
  );
  return (
    <>
      <Flex alignItems="center" mb={1}>
        {selectedComparisonLayerIds.length === 0 ? (
          <Text color="gray.400" fontWeight="medium" fontSize="xs">
            No comparisons selected
          </Text>
        ) : (
          <>
            <Text
              flex={1}
              color="gray.500"
              fontWeight="bold"
              fontSize="xxxs"
              textTransform="uppercase"
            >
              Selected
            </Text>
            <Button variant="text" fontSize="xxs" color="gray.500" p={1} onClick={onClickClearAll}>
              Clear all
            </Button>
          </>
        )}
      </Flex>
      <Flex rowGap={1} flexDirection="column" mb={2}>
        {selectedComparisonLayerIds.map((layerId) => (
          <Selection key={layerId} layerId={layerId} clearSelection={clearSelection(layerId)} />
        ))}
      </Flex>
      <Box color="gray.500" fontWeight="bold" fontSize="xxxs" textTransform="uppercase" mb={1}>
        Other scenarios and snapshots
      </Box>
      <SelectMenu
        items={comparisonItems}
        onSelect={(item) => toggleComparisonLayer(item.id)}
        maxHeight="18rem"
        width="unset"
        omitPadding
      >
        {/* N.B. this should be consolidated with the logic in ScenarioSwitcher */}
        {({ item, isFocused, idx }) => (
          <SelectMenuItem
            key={item.id}
            idx={idx}
            isFocused={isFocused}
            icon={item.icon}
            name={item.name}
            iconColor={item.iconColor}
            meta={item.id === DEFAULT_LAYER_ID ? <MainScenarioTag /> : undefined}
            shortcutHint={null}
          />
        )}
      </SelectMenu>
    </>
  );
};

const DriverChartForecastComparisonSelection = () => {
  const { blockId } = useBlockContext();

  return (
    <Flex flexDirection="column" minHeight="200px" pl={2}>
      <SelectionList blockId={blockId} />
    </Flex>
  );
};

export default DriverChartForecastComparisonSelection;
