import { ArrowDownIcon, ArrowUpIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Center, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import RestrictedColumnHeaderWrapper from 'components/BusinessObjectTable/RestrictedColumnHeaderWrapper';
import ColumnResizer from 'components/ColumnResizer/ColumnResizer';
import EditFormulaIcon from 'components/EditFormulaIcon/EditFormulaIcon';
import ObjectFieldTypeIcon from 'components/ObjectFieldTypeIcon/ObjectFieldTypeIcon';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import TableHeaderCell from 'components/Table/TableHeaderCell';
import { ObjectSortOrder } from 'generated/graphql';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import {
  OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY,
  ObjectTableBlockColumnKey,
} from 'reduxStore/models/blocks';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { setEditingPropertyFormula } from 'reduxStore/reducers/blockLocalStateSlice';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { isEditingPropertyFormulaForColumnSelector } from 'selectors/blockLocalStateSelector';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import {
  driverPropertySelector,
  subdriversByDimDriverIdForBusinessObjectSelector,
} from 'selectors/collectionSelector';
import { columnWidthsForObjectTableBlockSelector } from 'selectors/columnWidthsForObjectTableBlockSelector';
import { Star } from 'vectors';
import TextIcon from 'vectors/Text';

interface Props {
  columnKey: ObjectTableBlockColumnKey;
  groupKey: string;
  title: string;
  fixedWidth?: BoxProps['width'];
  topSpacingPx?: number;
  errMsg?: string;
  height?: BoxProps['height'];
  objectId?: BusinessObjectId;
  onClick?: React.MouseEventHandler;
  orientation: 'vertical' | 'horizontal';
  sortOrder?: ObjectSortOrder;
  hasRestrictions?: boolean;
  isDatabaseKey?: boolean;
}

const DatabaseTableHeaderCell = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      columnKey,
      fixedWidth,
      topSpacingPx,
      errMsg,
      height,
      objectId,
      onClick,
      orientation,
      hasRestrictions,
      groupKey,
      sortOrder,
      isDatabaseKey,
    },
    ref,
  ) => {
    const { blockId } = useBlockContext();
    const dispatch = useAppDispatch();
    const fieldWidth = useAppSelector(
      (state) => columnWidthsForObjectTableBlockSelector(state, blockId)[columnKey],
    );
    const width = fixedWidth ?? fieldWidth;
    const isNameColumn = columnKey === OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY;

    const isFormula = useAppSelector(
      (state) => businessObjectFieldSpecSelector(state, { id: columnKey })?.isFormula,
    );

    const isEditingFormula = useAppSelector((state) =>
      isEditingPropertyFormulaForColumnSelector(state, { blockId, columnKey }),
    );

    const subdriversByDimDriverId = useAppSelector((state) =>
      objectId != null ? subdriversByDimDriverIdForBusinessObjectSelector(state, objectId) : {},
    );

    const dimDriverIdForProperty =
      useAppSelector((state) => driverPropertySelector(state, columnKey))?.driverId ?? null;

    const matchingSubDriver =
      dimDriverIdForProperty != null ? subdriversByDimDriverId[dimDriverIdForProperty] : null;

    return (
      <TableHeaderCell
        ref={ref}
        isData={false}
        width={width}
        height={height}
        onClick={onClick}
        columnKey={columnKey}
        orientation={orientation}
        headerProps={{ alignItems: 'center', paddingY: 0 }}
      >
        <Tooltip placement="right-end" label={errMsg} isDisabled={errMsg == null}>
          <Box w="full" data-testid={`table-header-cell-${title}`}>
            {topSpacingPx != null && <Box h={`${topSpacingPx}px`} w="full" />}
            <Flex h="full" w="full" justifyContent="space-between" alignItems="center">
              <RestrictedColumnHeaderWrapper hasRestrictions={hasRestrictions}>
                <Center flex={0}>
                  {isNameColumn ? <TextIcon /> : <ObjectFieldTypeIcon fieldSpecId={columnKey} />}
                </Center>
                <Text noOfLines={1} whiteSpace="nowrap" fontSize="13px">
                  {title}
                </Text>
              </RestrictedColumnHeaderWrapper>
              {isDatabaseKey && (
                <Tooltip label={`${title} is used to segment this database`}>
                  <Star color="selection.500" />
                </Tooltip>
              )}
              {matchingSubDriver != null && (
                <OpenDetailsModalButton type={PaneType.Driver} id={matchingSubDriver.driverId} />
              )}
              {isFormula && (
                <EditFormulaIcon
                  showSavedCheckmark={false}
                  hasSavedError={false}
                  isEditing={isEditingFormula}
                  onClick={() => {
                    dispatch(
                      setEditingPropertyFormula({
                        blockId,
                        fieldSpecId: columnKey,
                        groupKey,
                        objectId,
                      }),
                    );
                  }}
                />
              )}
              {sortOrder != null && (
                <Flex h="full" alignItems="center">
                  {sortOrder === ObjectSortOrder.Asc ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </Flex>
              )}
              {errMsg != null && <WarningTwoIcon color="failure" />}
            </Flex>
            {fixedWidth == null && <ColumnResizer columnType={columnKey} />}
          </Box>
        </Tooltip>
      </TableHeaderCell>
    );
  },
);

export default React.memo(DatabaseTableHeaderCell);
