import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import xor from 'lodash/xor';

import { ExtModel } from 'generated/graphql';
import { ExtDriverId, ExtDriverKey } from 'reduxStore/models/extDrivers';

export type ExtDriverExpansionState = Partial<Record<ExtModel, ExtDriverId[]>>;

type State = {
  expandedExtDriverKeysByModel: ExtDriverExpansionState;
};

const initialState = {
  expandedExtDriverKeysByModel: {},
} as State;

const extDriverSlice = createSlice({
  name: 'extDriver',
  initialState,
  reducers: {
    toggleExtDriverRowExpansion(
      state,
      action: PayloadAction<{ model: ExtModel; driverId: ExtDriverKey }>,
    ) {
      const { model, driverId } = action.payload;
      state.expandedExtDriverKeysByModel[model] = xor(
        state.expandedExtDriverKeysByModel[model] ?? [],
        [driverId],
      );
    },
    expandExtDriverRows(
      state,
      action: PayloadAction<{ model: ExtModel; driverKeys: ExtDriverKey[] }>,
    ) {
      const { model, driverKeys } = action.payload;
      state.expandedExtDriverKeysByModel[model] = [
        ...(state.expandedExtDriverKeysByModel[model] ?? []),
        ...driverKeys,
      ];
    },
  },
});

export const { toggleExtDriverRowExpansion, expandExtDriverRows } = extDriverSlice.actions;

export default extDriverSlice.reducer;
