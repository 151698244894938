import { Box } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { SetCellPopoverReferenceContext } from 'components/CellPopoverReferenceContextProvider/CellPopoverReferenceContextProvider';
import EventImpactBadge from 'components/EventImpactBadge/EventImpactBadge';
import PlanTimelineContextMenu from 'components/PlanTimelineContextMenu/PlanTimelineContextMenu';
import TimelinePill from 'components/TimelinePill/TimelinePill';
import { useTimelineHoverState } from 'config/hoveringEventOrGroupRef';
import useAncestorState from 'hooks/useAncestorState';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import useSelectMouseHandlers, { SelectHandler } from 'hooks/useSelectMouseHandlers';
import { selectPlanTimelineInitiative } from 'reduxStore/actions/blockSelection';
import { PlanTimelineItemRef, refFromPlanTimelineItem } from 'reduxStore/models/events';
import { PlanTimelineDriverEvent, PlanTimelineObjectEvent } from 'selectors/planTimelineSelector';

export const EDITING_PILL_CLASS = 'editingPill';

interface Props {
  timelineEvent: PlanTimelineDriverEvent | PlanTimelineObjectEvent;
  isSelected: boolean;
  isActive: boolean;
}

const TimelineImpactPill: React.FC<Props> = ({ timelineEvent, isSelected, isActive }) => {
  const { id, name, event, hidden, actualEnd, actualStart } = timelineEvent;

  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();

  const impactRef: PlanTimelineItemRef = useMemo(() => ({ id, type: 'event' }), [id]);

  const elRef = useRef<HTMLDivElement>(null);
  const setTimeSeriesPopoverRef = useContext(SetCellPopoverReferenceContext);

  useEffect(() => {
    if (isSelected && elRef.current != null) {
      setTimeSeriesPopoverRef(elRef.current);
    }
  }, [setTimeSeriesPopoverRef, isSelected]);

  const onSelect: SelectHandler = useCallback(
    ({ toggle, range }) => {
      dispatch(selectPlanTimelineInitiative(impactRef, blockId, { toggle, range }));
    },
    [dispatch, impactRef, blockId],
  );

  const { onClick, onMouseDown } = useSelectMouseHandlers(isSelected, onSelect);
  const onClickCallback: React.MouseEventHandler = useCallback(
    (ev) => {
      onClick(ev);
    },
    [onClick],
  );

  const { isAncestorHovered, isAncestorSelected } = useAncestorState(impactRef);
  const [_, setHovered] = useTimelineHoverState();
  const onMouseOver = useCallback(() => setHovered(impactRef), [setHovered, impactRef]);
  const onMouseLeave = useCallback(() => setHovered(null), [setHovered]);

  const ref = useMemo(() => refFromPlanTimelineItem(timelineEvent), [timelineEvent]);

  return (
    <PlanTimelineContextMenu timelineItemRef={ref}>
      <TimelinePill
        name={name}
        ref={elRef}
        type="impact"
        isSelected={isSelected}
        isActive={isActive}
        isAncestorHovered={isAncestorHovered}
        isAncestorSelected={isAncestorSelected}
        onClick={onClickCallback}
        onMouseDown={onMouseDown}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        hidden={hidden}
        actualEnd={actualEnd}
        actualStart={actualStart}
      >
        <Box ml={2}>
          <EventImpactBadge event={event} />
        </Box>
      </TimelinePill>
    </PlanTimelineContextMenu>
  );
};

export default React.memo(TimelineImpactPill);
