import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button, Divider, Flex, IconButton, Spacer, Text } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';

import RangeFieldControls from 'components/TimePeriodSelectionMenu/RangeFieldControls';
import {
  getCustomRelativeFormulaTimeRange,
  getRelativeDateRangeFromFormulaTimeRange,
} from 'helpers/formula';
import { TimeUnit } from 'types/datetime';
import { FormulaTimeRange, FormulaTimeSeriesOperator } from 'types/formula';
import AlertIcon from 'vectors/Alert';

const DEFAULT_START_MONTHS = -12;
const DEFAULT_END_MONTHS = -1;
const INVALID_TIME_RANGE_ERROR = 'Invalid time range';

interface Props {
  onBack: () => void;
  dateRange: FormulaTimeRange;
  onSelectTimeRange: (
    input: FormulaTimeRange,
    operator?: FormulaTimeSeriesOperator,
    options?: { closeMenu?: boolean },
  ) => void;
}

const CustomTimeRangeSelectionMenu = ({ onBack, dateRange, onSelectTimeRange }: Props) => {
  const relativeDateRange = getRelativeDateRangeFromFormulaTimeRange(dateRange);
  const initialStartMonths =
    relativeDateRange != null && relativeDateRange.start.unit === TimeUnit.Month
      ? relativeDateRange.start.val
      : DEFAULT_START_MONTHS;
  const initialEndMonths =
    relativeDateRange != null && relativeDateRange.end.unit === TimeUnit.Month
      ? relativeDateRange.end.val
      : DEFAULT_END_MONTHS;
  const [startMonths, setStartMonths] = useState(initialStartMonths);
  const [endMonths, setEndMonths] = useState(initialEndMonths);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const numMonths = useMemo(() => endMonths - startMonths + 1, [endMonths, startMonths]);
  const validateTimeRange = useCallback((startNumMonths: number, endNumMonths: number) => {
    if (startNumMonths > endNumMonths) {
      setErrorMessage(INVALID_TIME_RANGE_ERROR);
      return false;
    }
    setErrorMessage(undefined);
    return true;
  }, []);
  const onUpdateTimeRange = useCallback(
    (startNumMonths: number, endNumMonths: number, closeMenu: boolean) => {
      if (startNumMonths > endNumMonths) {
        return;
      }
      onSelectTimeRange(
        getCustomRelativeFormulaTimeRange(
          { unit: TimeUnit.Month, val: startNumMonths },
          { unit: TimeUnit.Month, val: endNumMonths },
        ),
        undefined,
        { closeMenu },
      );
    },
    [onSelectTimeRange],
  );
  const onDone = useCallback(
    () => onUpdateTimeRange(startMonths, endMonths, true),
    [endMonths, onUpdateTimeRange, startMonths],
  );
  const onUpdateStartMonths = useCallback(
    (newStartMonths: number) => {
      setStartMonths(newStartMonths);
      if (!validateTimeRange(newStartMonths, endMonths)) {
        return;
      }
      onUpdateTimeRange(newStartMonths, endMonths, false);
    },
    [endMonths, onUpdateTimeRange, validateTimeRange],
  );
  const onUpdateEndMonths = useCallback(
    (newEndMonths: number) => {
      setEndMonths(newEndMonths);
      if (!validateTimeRange(startMonths, newEndMonths)) {
        return;
      }
      onUpdateTimeRange(startMonths, newEndMonths, false);
    },
    [onUpdateTimeRange, startMonths, validateTimeRange],
  );
  return (
    <Flex
      flexDirection="column"
      bg="surface"
      padding={2}
      borderRadius={4}
      minWidth="21rem"
      maxWidth="32rem"
    >
      <Flex>
        <Flex columnGap={1} align="center">
          <IconButton
            aria-label="Go back"
            variant="icon"
            boxSize={6}
            onClick={onBack}
            icon={<ArrowBackIcon />}
          />
          <Text fontSize="xs">{`${numMonths} ${pluralize('month', numMonths)}`}</Text>
        </Flex>
        <Spacer />
        <Button size="xs" variant="coached" onClick={onDone}>
          Done
        </Button>
      </Flex>
      <Divider my={2} />
      <Flex w="full" fontSize="xs" columnGap={2} align="center" justify="center">
        <RangeFieldControls
          field="start"
          numMonths={startMonths}
          onUpdateNumMonths={onUpdateStartMonths}
        />
        <Flex justify="center">
          <Text>to</Text>
        </Flex>
        <RangeFieldControls
          field="end"
          numMonths={endMonths}
          onUpdateNumMonths={onUpdateEndMonths}
        />
      </Flex>
      {errorMessage != null && <TimeRangeError message={errorMessage} />}
    </Flex>
  );
};

const TimeRangeError: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Flex w="full" mt={2} columnGap={2} align="center" fontSize="xs" fontWeight="medium" pl={4}>
      <AlertIcon boxSize={4} m={0} />
      <Text color="red.500">{message}</Text>
    </Flex>
  );
};

export default CustomTimeRangeSelectionMenu;
