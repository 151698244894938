import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'BarChartVertical',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    boxSize: '14px',
  },
  d: `M11.5766 2.10501C11.2866 2.10501 11.05 2.34001 11.05 2.63168V13.6817H14.2083V2.63168C14.2083 2.34001 13.9716 2.10501 13.6816 2.10501H11.5766ZM2.10497 4.21001C1.81497 4.21001 1.57831 4.44501 1.57831 4.73668V13.6817H4.73664V4.73668C4.73664 4.44501 4.49997 4.21001 4.20998 4.21001H2.10497ZM6.84164 6.31501C6.54997 6.31501 6.31497 6.55001 6.31497 6.84168V13.6817H9.47164V6.84168C9.47164 6.55001 9.23664 6.31501 8.94497 6.31501H6.84164Z`,
});
