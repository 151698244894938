import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'Strikethrough',
  viewBox: '0 0 12 14',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <g fill="currentColor">
      <path d="M8.68216 3.9916C8.54683 2.82894 7.5195 2.15226 6.07386 2.15226C4.52364 2.15226 3.54553 2.94582 3.54553 4.01621C3.53938 5.22194 4.84968 5.68331 5.83394 5.93553L7.06428 6.25541C8.66371 6.64912 10.6876 7.52881 10.6876 9.79877C10.6876 11.9334 8.98359 13.4898 6.02465 13.4898C3.20103 13.4898 1.43551 12.081 1.31247 9.79877H3.21949C3.33022 11.1706 4.54825 11.8227 6.01234 11.8227C7.63023 11.8227 8.80519 11.0106 8.80519 9.78031C8.80519 8.66071 7.76557 8.21779 6.41835 7.861L4.92965 7.45499C2.90575 6.90749 1.65082 5.88016 1.65082 4.12694C1.65082 1.95541 3.58859 0.509766 6.11692 0.509766C8.67601 0.509766 10.46 1.97386 10.5154 3.9916H8.68216Z" />
      <path d="M0.426636 7.60878H11.5734V8.94984H0.426636V7.60878Z" />
    </g>
  ),
});
