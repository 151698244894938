import * as Sentry from '@sentry/nextjs';
import keyBy from 'lodash/keyBy';

import { DriverCellRef } from 'config/cells';
import {
  AiGroupDriversDocument,
  AiGroupDriversQuery,
  AiGroupDriversQueryVariables,
  DriverGroupCreateInput,
  DriverUpdateInput,
} from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { type CopilotRequest } from 'reduxStore/actions/copilot';
import { submitMutation } from 'reduxStore/actions/submitDatasetMutation';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { setSelectedCells } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { aiGroupDriversContextSelector } from 'selectors/aiSelector';
import { allDriverGroupListSelector } from 'selectors/driverGroupSelector';
import {
  basicDriverResolvedNamesByIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import {
  navSubmodelIdSelector,
  sortedDriverGroupIdsForNavSubmodelSelector,
} from 'selectors/navSubmodelSelector';
import {
  driverCellSelectionSelector,
  prevailingSelectedDriverIdsSelector,
} from 'selectors/prevailingCellSelectionSelector';
import { blockIdBySubmodelIdSelector } from 'selectors/submodelPageSelector';

function updateDriverCellGroupId(
  cellRef: DriverCellRef,
  updates: Record<DriverId, DriverUpdateInput>,
) {
  return {
    ...cellRef,
    rowKey: {
      ...cellRef.rowKey,
      groupId: updates[cellRef.rowKey.driverId ?? '']?.groupId ?? cellRef.rowKey.groupId,
    },
  };
}

export const aiGroupSelected: CopilotRequest<AiGroupDriversQuery, AiGroupDriversQueryVariables> = {
  query: AiGroupDriversDocument,
  getContext: (state: RootState) => {
    return aiGroupDriversContextSelector(state);
  },
  getPrompt: (state: RootState) => {
    const driverIds = prevailingSelectedDriverIdsSelector(state);
    // use resolved names so that dimensional drivers have their attributes included
    const resolvedDriverNamesById = basicDriverResolvedNamesByIdSelector(state);
    return driverIds
      .map((id) => {
        const name = resolvedDriverNamesById[id];
        return name == null ? null : { id, name };
      })
      .filter(isNotNull);
  },
  extractCursor: (data) => data.aiGroupDrivers?.cursor,
  handleResponse: (data, dispatch, _, getState) => {
    const state = getState();
    const existingGroups = allDriverGroupListSelector(state);
    const driverIds = prevailingSelectedDriverIdsSelector(state);
    const driverCellSelection = driverCellSelectionSelector(state);
    const driversById = driversByIdForLayerSelector(state);
    // use resolved names so that dimensional drivers have their attributes included
    const resolvedDriverNamesById = basicDriverResolvedNamesByIdSelector(state);
    const navSubmodelId = navSubmodelIdSelector(state);
    if (driverIds.length === 0 || driverCellSelection == null || navSubmodelId == null) {
      return;
    }

    const submodelBlockId = blockIdBySubmodelIdSelector(state)[navSubmodelId];
    if (submodelBlockId == null) {
      return;
    }

    const sortedDriverGroupIds = sortedDriverGroupIdsForNavSubmodelSelector(state);
    const activeCellGroupId = driverCellSelection.activeCell.rowKey.groupId ?? null;
    const generatedGroups = (data?.aiGroupDrivers?.groups ?? []).filter(
      (g) => g.drivers.length > 0 && g.name.length > 0,
    );
    if (generatedGroups.length === 0) {
      return;
    }

    const groupsByName = keyBy(existingGroups, 'name');
    const driverUpdates: DriverUpdateInput[] = [];
    const newGroups: DriverGroupCreateInput[] = [];
    generatedGroups.forEach((group) => {
      let isValidGroup = false;
      let isNewGroup = false;
      let groupId: DriverGroupId | undefined = groupsByName[group.name]?.id;
      if (groupId == null) {
        groupId = uuidv4();
        isNewGroup = true;
      }

      group.drivers
        .filter((driver) => driverIds.includes(driver.id) && driversById[driver.id] != null)
        .forEach((driver) => {
          const resolvedName = resolvedDriverNamesById[driver.id];
          const reduxDriver = driversById[driver.id];
          const driverReferences = reduxDriver?.driverReferences;
          const isInGroup = driverReferences?.some(
            (ref) => ref.blockId === submodelBlockId && ref.groupId === groupId,
          );
          // ensure that the response didn't modify the ID/name combination
          if (resolvedName === driver.name && driverReferences != null && !isInGroup) {
            isValidGroup = true;
            const existingRef = driverReferences.find(
              ({ blockId }) => blockId === submodelBlockId,
            ) ?? { blockId: submodelBlockId };
            const otherRefs = driverReferences.filter(({ blockId }) => blockId !== submodelBlockId);
            driverUpdates.push({
              id: driver.id,
              driverReferences: [...otherRefs, { ...existingRef, groupId }],
            });
          }
        });

      if (group.drivers.some((d) => !driverIds.includes(d.id))) {
        Sentry.withScope((scope: Sentry.Scope) => {
          scope.setLevel('warning');
          Sentry.captureMessage('Unrequested ID returned from AI driver grouping');
        });
      }

      if (isNewGroup && isValidGroup) {
        newGroups.push({ id: groupId, name: group.name });
      }
    });

    const newGroupIds = newGroups.map((g) => g.id);
    let newSortedDriverGroupIds;
    const activeCellGroupIndex = sortedDriverGroupIds.indexOf(activeCellGroupId);
    if (activeCellGroupIndex !== -1) {
      newSortedDriverGroupIds = [
        ...sortedDriverGroupIds.slice(0, activeCellGroupIndex + 1),
        ...newGroupIds,
        ...sortedDriverGroupIds.slice(activeCellGroupIndex + 1),
      ];
    } else {
      newSortedDriverGroupIds = [...sortedDriverGroupIds, activeCellGroupId, ...newGroupIds];
    }

    if (driverUpdates.length === 0 && newGroups.length === 0) {
      return;
    }

    dispatch(
      submitMutation(
        {
          updateDrivers: driverUpdates,
          newDriverGroups: newGroups,
          updateSubmodels: [
            {
              id: navSubmodelId,
              sortedDriverGroupIds: newSortedDriverGroupIds,
            },
          ],
        },
        { forceLayerId: DEFAULT_LAYER_ID, isEphemeral: true },
      ),
    );

    const driverUpdatesById = keyBy(driverUpdates, 'id');

    // maintain the existing cell selection, which includes the group IDs
    dispatch(
      setSelectedCells({
        ...driverCellSelection,
        activeCell: updateDriverCellGroupId(driverCellSelection.activeCell, driverUpdatesById),
        selectedCells: driverCellSelection.selectedCells.map((cell) =>
          updateDriverCellGroupId(cell, driverUpdatesById),
        ),
      }),
    );
  },
};
