import React, { useCallback, useContext, useMemo } from 'react';

import DriverExpandByDimensionMenu from 'components/DriverExpandByDimensionMenu/DriverExpandByDimensionMenu';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import { SelectMenuContext } from 'components/SelectMenu/SelectMenuContext';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useExpandableDimensions from 'hooks/useExpandableDimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { setEditingDriver } from 'reduxStore/reducers/modelViewSlice';
import { openRenameSubDriverModal } from 'reduxStore/reducers/pageSlice';
import { isDimensionalSubDriverSelector } from 'selectors/driversSelector';
import { numSelectedDriversSelector } from 'selectors/prevailingCellSelectionSelector';
import { prevailingSelectedSubDriverIdsSelector } from 'selectors/prevailingSelectedSubDriverIdsSelector';
import DimensionIcon from 'vectors/Dimension';
import PencilIcon from 'vectors/Pencil';

interface DriverDimensionsItem extends SelectItem {
  multiSelect: boolean;
  isHidden: boolean;
  onSelect: (() => void) | null;
}

const CREATE_SECTION_ID = 'createDrivers';
const EDIT_SECTION_ID = 'editDrivers';

const SECTIONS: Section[] = [
  {
    id: CREATE_SECTION_ID,
    name: 'Create drivers',
  },
  {
    id: EDIT_SECTION_ID,
    name: 'Edit driver',
  },
];

interface Props {
  driverId: DriverId;
  hasDimensionsButton: boolean;
}

const DriverDimensionsMenu: React.FC<Props> = ({ driverId, hasDimensionsButton }) => {
  const isSubDriver = useAppSelector((state) => isDimensionalSubDriverSelector(state, driverId));
  const dispatch = useAppDispatch();
  const numExpandableDimensions = useExpandableDimensions().length;

  const selectedSubDriverIds = useAppSelector(prevailingSelectedSubDriverIdsSelector);
  const numSelectedDrivers = useAppSelector(numSelectedDriversSelector);
  const canBulkRenameDimDrivers =
    selectedSubDriverIds.length > 0 && numSelectedDrivers === selectedSubDriverIds.length;

  const items = useMemo(() => {
    const allItems: DriverDimensionsItem[] = [
      {
        id: 'expand',
        name: 'Expand by dimension',
        icon: <DimensionIcon />,
        multiSelect: true,
        isHidden: numExpandableDimensions === 0,
        onSelect: null,
        sectionId: CREATE_SECTION_ID,
        submenu: () => <DriverExpandByDimensionMenu />,
      },
      {
        id: 'rename',
        name: numSelectedDrivers === 1 ? 'Rename all subdrivers' : 'Rename selected',
        icon: <PencilIcon />,
        multiSelect: true,
        isHidden: !canBulkRenameDimDrivers,
        sectionId: EDIT_SECTION_ID,
        onSelect: () => {
          dispatch(openRenameSubDriverModal());
        },
      },
      {
        id: 'addDimensions',
        name: isSubDriver ? 'Edit dimensions' : 'Add dimensions',
        icon: <DimensionIcon />,
        multiSelect: false,
        isHidden: !hasDimensionsButton,
        sectionId: EDIT_SECTION_ID,
        onSelect: () => {
          dispatch(setEditingDriver({ driverId }));
        },
      },
    ];
    return allItems
      .filter((item) => numSelectedDrivers < 2 || item.multiSelect)
      .filter((item) => !item.isHidden);
  }, [
    isSubDriver,
    hasDimensionsButton,
    numSelectedDrivers,
    numExpandableDimensions,
    dispatch,
    driverId,
    canBulkRenameDimDrivers,
  ]);

  const { closeParentSelectMenu } = useContext(SelectMenuContext);
  const onSelect = useCallback(
    (item: DriverDimensionsItem) => {
      item.onSelect?.();
      closeParentSelectMenu?.();
    },
    [closeParentSelectMenu],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu
        items={items}
        startFocusIdx={-1}
        sections={SECTIONS}
        onSelect={onSelect}
        onClose={closeParentSelectMenu}
        width="14rem"
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default React.memo(DriverDimensionsMenu);
