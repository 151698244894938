import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import PlusIconButton from 'components/PlusIconButton/PlusIconButton';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { addNewComparisonLayer } from 'reduxStore/actions/scenarioComparison';
import { nextAdditionalLayerIdToCompareOptionSelector } from 'selectors/scenarioComparisonSelector';

const AddAdditionalScenarioButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const showButton = useAppSelector(nextAdditionalLayerIdToCompareOptionSelector) != null;
  return (
    <Tooltip placement="top" label="Add scenario to compare">
      <PlusIconButton
        data-testid="add-additional-scenario-button"
        aria-label="Add additional scenario"
        onClick={() => dispatch(addNewComparisonLayer())}
        visibility={showButton ? 'visible' : 'hidden'}
      />
    </Tooltip>
  );
};

export default AddAdditionalScenarioButton;
