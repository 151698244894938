import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';

import SearchInput from 'components/SearchInput/SearchInput';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { ValueType } from 'generated/graphql';
import { FilterValueTypes } from 'types/filtering';
import CalendarIcon from 'vectors/Calendar';
import DimensionIcon from 'vectors/Cube';
import NumberSignIcon from 'vectors/NumberSign';
import TextIcon from 'vectors/Text';

const SEARCH_KEYS = ['name'];
const EMPTY_ARRAY: string[] = [];

interface SearchBarProps {
  numberSelected: number;
  isMultiSelect: boolean;
  query: string;
  setQuery: (query: string) => void;
  onClearAll: () => void;
}

const MenuSearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
  ({ numberSelected, query, setQuery, onClearAll, isMultiSelect }, ref) => {
    const searching = query.length > 0;

    return (
      <>
        <SearchInput ref={ref} placeholder="Search" query={query} setQuery={setQuery} />
        {!searching && isMultiSelect && (
          <Flex justifyContent="space-between" px="0.375rem" pt="0.3rem">
            <Box>
              <Text as="span" color="gray.600" fontSize="xs" fontWeight="medium">
                Selected{' '}
              </Text>
              <Text as="span" color="gray.500" fontSize="xs" fontWeight="medium">
                ({numberSelected})
              </Text>
            </Box>
            <Button
              color="gray.500"
              size="sm"
              fontSize="xs"
              fontWeight="medium"
              variant="iconText"
              alignItems="center"
              aria-label="Clear all"
              onClick={onClearAll}
            >
              Clear all
            </Button>
          </Flex>
        )}
      </>
    );
  },
);
type FilterOption = {
  value: string;
  type?: ValueType | FilterValueTypes;
};
interface MenuListWithSearchProps {
  options: Map<string, FilterOption>; // to respect insertion order
  selected?: string[] | string;
  onChange: (value: string | string[]) => void;
  searchEnabled?: boolean;
  isMultiSelect?: boolean;
  onClose?: () => void;
}

const getAttributeIcon = (type: ValueType | FilterValueTypes | undefined) => {
  switch (type) {
    case ValueType.Attribute:
      return <DimensionIcon />;
    case ValueType.Number:
      return <NumberSignIcon />;
    case ValueType.Timestamp:
      return <CalendarIcon />;
    case FilterValueTypes.ENTITY:
      return <TextIcon />;
    default:
      return undefined;
  }
};
const MenuListWithSearch = React.forwardRef<HTMLInputElement, MenuListWithSearchProps>(
  (
    { selected, onChange, options, searchEnabled = false, isMultiSelect = false, onClose },
    searchInputRef,
  ) => {
    const items: SelectItem[] = useMemo(() => {
      return [...options.entries()].map(([id, { value, type }]) => {
        const itemIsSelected = Array.isArray(selected) ? selected.includes(id) : selected === id;
        return {
          id,
          name: value,
          isChecked: itemIsSelected,
          checkedStyle: 'none',
          icon: getAttributeIcon(type),
        };
      });
    }, [options, selected]);

    const onSelect = useCallback(
      ({ id }: SelectItem) => {
        if (!isMultiSelect && selected !== id) {
          onChange(id);
          onClose?.();
          return;
        }

        const newSelected = items
          .filter((item) => {
            // Flip the selection of the matching item
            if (item.id === id) {
              return !item.isChecked;
            }
            return item.isChecked;
          })
          .map((item) => item.id);

        onChange(newSelected.length > 0 ? newSelected : EMPTY_ARRAY);
      },
      [items, onChange, isMultiSelect, selected, onClose],
    );

    const [query, setQuery] = useState('');

    const clearAll = useCallback(() => {
      onChange(EMPTY_ARRAY);
    }, [onChange]);

    return (
      <Flex padding="0.375rem" overflowY="auto">
        {searchEnabled ? (
          <Flex rowGap={1} direction="column">
            <MenuSearchBar
              numberSelected={selected?.length ?? 0}
              isMultiSelect={isMultiSelect}
              query={query}
              setQuery={setQuery}
              onClearAll={clearAll}
              ref={searchInputRef}
            />
            <SelectMenu
              items={items}
              onSelect={onSelect}
              width="unset"
              query={query}
              searchKeys={SEARCH_KEYS}
            >
              {BaseSelectMenuItem}
            </SelectMenu>
          </Flex>
        ) : (
          <SelectMenu items={items} onSelect={onSelect} width="unset">
            {BaseSelectMenuItem}
          </SelectMenu>
        )}
      </Flex>
    );
  },
);

export default MenuListWithSearch;
