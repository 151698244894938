import React, { useCallback, useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { DriverCellRef, MonthColumnKey } from 'config/cells';
import { DRIVER_FORMAT_ICONS, DRIVER_FORMAT_NAMES } from 'config/drivers';
import { DriverFormat } from 'generated/graphql';
import { getColorFromCellRef, isDriverDataCellRef, isDriverNameCellRef } from 'helpers/cells';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useColorSelectMenuItem } from 'hooks/useColorSelectMenuItem';
import { useCurrencySelectMenuItem } from 'hooks/useCurrencySelectMenuItem';
import { useDecimalPlacesSelectMenuItem } from 'hooks/useDecimalPlacesSelectMenuItem';
import { useDriverFormatSelectMenuItem } from 'hooks/useDriverFormatSelectMenuItem';
import { useDriverTextStyleSelectMenuItems } from 'hooks/useDriverTextStyleMenuItems';
import { useIndentSelectMenuItem } from 'hooks/useIndentSelectMenuItem';
import { handleDriverIndentKey } from 'reduxStore/actions/cellNavigation';
import {
  updateDriverCurrencies,
  updateDriverDecimalPlaces,
  updateDriverFormats,
  updateSelectedDriverColor,
  updateSelectedDriverCurrencies,
  updateSelectedDriverDecimalPlaces,
} from 'reduxStore/actions/driverMutations';
import { FormatOptions, FormatType, trackEditFormatEvent } from 'reduxStore/actions/trackEvent';
import { DriverId } from 'reduxStore/models/drivers';
import { DisplayConfiguration } from 'reduxStore/models/value';
import { driverColorSelector } from 'selectors/driversSelector';
import { isModalOpenSelector } from 'selectors/isModalOpenSelector';

const SECTIONS: Section[] = [
  {
    id: 'format-options',
  },
  {
    id: 'style-options',
  },
  {
    id: 'display-options',
  },
];

interface Props extends Pick<DisplayConfiguration, 'currency' | 'format' | 'decimalPlaces'> {
  driverId: DriverId;
  cellRef: DriverCellRef;
}

const DriverFormatSelectMenu: React.FC<Props> = ({
  currency,
  format,
  decimalPlaces,
  driverId,
  cellRef,
}) => {
  const dispatch = useAppDispatch();
  const driverColor = useAppSelector((state) => driverColorSelector(state, { id: driverId }));
  const isModalOpen = useAppSelector(isModalOpenSelector);
  const bgColor = getColorFromCellRef(cellRef, driverColor);

  const onSelect = useCallback(
    (item: SelectItem) => {
      switch (item.sectionId) {
        case 'display-options':
          switch (item.id) {
            case 'indent-driver':
              dispatch(handleDriverIndentKey({ increase: true }, () => {}));
              break;
            case 'outdent-driver':
              dispatch(handleDriverIndentKey({ increase: false }, () => {}));
              break;
            default:
          }
          break;
        default:
      }
    },
    [dispatch],
  );

  const onSelectBackgroundColor = useCallback(
    (item: SelectItem) => {
      const monthKey = (cellRef.columnKey as MonthColumnKey)?.monthKey ?? undefined;
      dispatch(updateSelectedDriverColor({ color: item.id, monthKey }));
      dispatch(
        trackEditFormatEvent({
          pageType: isModalOpen ? 'submodelPage' : 'blocksPage',
          formatType: FormatType.Color,
          selectionType: monthKey != null ? FormatOptions.Cell : FormatOptions.Row,
        }),
      );
    },
    [dispatch, isModalOpen, cellRef],
  );

  const onSelectCurrency = useCallback(
    (newCurrency: string) => {
      dispatch(updateSelectedDriverCurrencies({ currency: newCurrency }));
      dispatch(trackEditFormatEvent({ pageType: 'blocksPage', formatType: FormatType.Currency }));
    },
    [dispatch],
  );

  const onSelectDecimalPlaces = useCallback(
    (newDecimalPlaces: number | null) => {
      dispatch(updateSelectedDriverDecimalPlaces({ decimalPlaces: newDecimalPlaces }));
    },
    [dispatch],
  );

  const decimalPlacesSelectItem = useDecimalPlacesSelectMenuItem(
    decimalPlaces ?? null,
    onSelectDecimalPlaces,
  );

  const formatSelectItems = useDriverFormatSelectMenuItem(format);
  const indentSelectItems = useIndentSelectMenuItem();
  const textStyleSelectItems = useDriverTextStyleSelectMenuItems();
  const currencySelectItem = useCurrencySelectMenuItem(currency ?? null, onSelectCurrency);
  const colorSelectItems = useColorSelectMenuItem({ bgColor, onSelectBackgroundColor });
  const isDataCell = isDriverDataCellRef(cellRef);
  const isNameGroupCell = isDriverNameCellRef(cellRef);

  const items = useMemo(
    () => [
      ...(!isDataCell ? [formatSelectItems] : []),
      ...(format === DriverFormat.Currency ? [currencySelectItem] : []),
      ...(format !== DriverFormat.Integer ? [decimalPlacesSelectItem] : []),
      ...[colorSelectItems],
      ...(isNameGroupCell ? indentSelectItems : []),
      ...(isNameGroupCell || isDataCell ? [textStyleSelectItems] : []),
    ],
    [
      isDataCell,
      format,
      currencySelectItem,
      decimalPlacesSelectItem,
      colorSelectItems,
      isNameGroupCell,
      indentSelectItems,
      textStyleSelectItems,
      formatSelectItems,
    ],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu
        maxHeight="auto"
        onSelect={onSelect}
        items={items}
        sections={SECTIONS}
        width="16rem"
        startFocusIdx={-1}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export const DatabaseDriverFormatSelectMenu: React.FC<Omit<Props, 'cellRef'>> = ({
  currency,
  format,
  decimalPlaces,
  driverId,
}) => {
  const dispatch = useAppDispatch();

  const DB_SECTIONS: Section[] = [
    {
      id: 'format',
    },
    {
      id: 'format-options',
    },
  ];

  // NOTE: we're only updating the default driver as in databases, its properties are used
  // additionally, trying to only set is for the currently available sudrivers on a db will mean that new data
  // from integrations won't be updated
  // if you wanna change it on a particular subdriver, only change on the model
  const onSelect = useCallback(
    (item: SelectItem) => {
      switch (item.sectionId) {
        case 'format':
          dispatch(updateDriverFormats({ format: item.id as DriverFormat, driverIds: [driverId] }));
          break;

        default:
      }
    },
    [dispatch, driverId],
  );

  const onSelectCurrency = useCallback(
    (newCurrency: string) => {
      dispatch(updateDriverCurrencies({ currency: newCurrency, driverIds: [driverId] }));
      dispatch(trackEditFormatEvent({ pageType: 'blocksPage', formatType: FormatType.Currency }));
    },
    [dispatch, driverId],
  );

  const onSelectDecimalPlaces = useCallback(
    (newDecimalPlaces: number | null) => {
      dispatch(
        updateDriverDecimalPlaces({ decimalPlaces: newDecimalPlaces, driverIds: [driverId] }),
      );
    },
    [dispatch, driverId],
  );

  const decimalPlacesSelectItem = useDecimalPlacesSelectMenuItem(
    decimalPlaces ?? null,
    onSelectDecimalPlaces,
  );

  const currencySelectItem = useCurrencySelectMenuItem(currency ?? null, onSelectCurrency);

  const items = useMemo(
    () => [
      ...Object.entries(DRIVER_FORMAT_NAMES).map<SelectItem>(([f, name]) => ({
        id: f,
        icon: DRIVER_FORMAT_ICONS[f as DriverFormat],
        name,
        isChecked: f === format,
        sectionId: 'format',
      })),
      ...(format === DriverFormat.Currency ? [currencySelectItem] : []),
      ...(format !== DriverFormat.Integer ? [decimalPlacesSelectItem] : []),
    ],
    [format, currencySelectItem, decimalPlacesSelectItem],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu
        maxHeight="auto"
        onSelect={onSelect}
        items={items}
        sections={DB_SECTIONS}
        width="16rem"
        startFocusIdx={-1}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default DriverFormatSelectMenu;
