import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';

type SelectMenuProps = Omit<React.ComponentProps<typeof SelectMenuItem>, 'name'>;

interface Props extends SelectMenuProps {
  errorMessage?: string | null;
  children: React.ReactNode;
}

const CustomSelectMenuResult: React.FC<Props> = ({
  isFocused,
  errorMessage,
  isDisabled,
  idx,
  children,
  ...rest
}) => {
  return (
    <Tooltip placement="right" isOpen={isFocused} label={errorMessage}>
      <SelectMenuItem
        {...rest}
        name={children}
        idx={idx}
        isFocused={isFocused}
        isDisabled={isDisabled || errorMessage != null}
      />
    </Tooltip>
  );
};

export default CustomSelectMenuResult;
