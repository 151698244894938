import { Button, Flex, Grid } from '@chakra-ui/react';
import range from 'lodash/range';
import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';

import { MonthPickerProps } from 'components/MonthPicker/MonthPicker';

const YearPicker: React.FC<Omit<MonthPickerProps, 'hideYear'>> = ({
  onDateSelect,
  selected,
  minDate,
  maxDate,
  focused = true,
}) => {
  const onYearSelect = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    const year = Number(ev.currentTarget.value);
    const date = DateTime.utc(year).startOf('month');
    onDateSelect(date);
  };

  const selectedRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (focused) {
      selectedRef.current?.scrollIntoView({ block: 'center' });
    }
  }, [focused]);

  return (
    <Flex w="full" pt={2} overflowY="scroll">
      <Grid flex="1 0" templateColumns="4rem" rowGap={1} h="7.5rem" justifyContent="center">
        {range(minDate.year + 1, maxDate.year).map((year) => {
          const isSelected = year === selected?.year;
          return (
            <Button
              key={year}
              ref={isSelected ? selectedRef : undefined}
              data-selected={isSelected || undefined}
              data-testid={isSelected ? 'selected-year' : undefined}
              variant="text"
              py="0.375rem"
              size="sm"
              onClick={onYearSelect}
              value={year}
            >
              {year}
            </Button>
          );
        })}
      </Grid>
    </Flex>
  );
};

export default React.memo(YearPicker);
