import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'WarnIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8C1 4.13425 4.13425 1 8 1C11.8657 1 15 4.13425 15 8C15 11.8657 11.8657 15 8 15C4.13425 15 1 11.8657 1 8ZM8 4C8.35504 4 9.5 4.27982 9.5 4.625L8.64286 8.375C8.64286 8.72018 8.35504 9 8 9C7.64496 9 7.35714 8.72018 7.35714 8.375L6.5 4.625C6.5 4.27982 7.64496 4 8 4ZM8 10C8.55228 10 9 10.4477 9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10Z"
      fill="currentColor"
    />
  ),
});
