import { createSelector } from 'reselect';

import { isAlertDialogOpenSelector } from 'selectors/alertDialogSelector';
import { openModalTypeSelector } from 'selectors/pageBaseSelector';

// N.B. this should match the implementation in `globalKeypress.ts`
export const isModalOpenSelector = createSelector(
  openModalTypeSelector,
  isAlertDialogOpenSelector,
  (openModal, isAlertDialogOpen) => openModal != null && !isAlertDialogOpen,
);
