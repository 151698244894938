import {
  BackgroundProps,
  Box,
  BoxProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  TextProps,
  useMergeRefs,
} from '@chakra-ui/react';
import React, { ComponentProps, ReactNode, forwardRef, useCallback, useEffect } from 'react';

import { Tooltip } from 'chakra/tooltip';
import EmojiPicker from 'components/EmojiPicker/EmojiPicker';
import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import { stopEventPropagation } from 'helpers/browserEvent';
import { nullSafeEqual } from 'helpers/typescript';
import useControlledPopover from 'hooks/useControlledPopover';

interface Props {
  emoji?: string | null;
  icon?: ReactNode;
  bgColor?: BackgroundProps['bgColor'];
  size: ComponentProps<typeof EmojiIcon>['size'];
  readonly?: boolean;
  onChange: (emoji: string | null) => void;
  emptyIcon?: React.ReactNode;
  borderRadius?: BoxProps['borderRadius'];
  color?: TextProps['color'];
  hoverBgColor?: BoxProps['bgColor'];
  activeBgColor?: BoxProps['bgColor'];
  onOpen?: () => void;
  onClose?: () => void;
}

const EmojiWidget = forwardRef<HTMLDivElement, Props>(
  (
    {
      emoji = null,
      icon = null,
      bgColor = 'transparent',
      size,
      readonly = false,
      onChange,
      emptyIcon,
      borderRadius = 'md',
      color = 'gray.500',
      hoverBgColor = 'gray.200',
      activeBgColor = 'gray.300',
      onOpen: onOpenWidget = () => {},
      onClose: onCloseWidget = () => {},
    },
    ref,
  ) => {
    const {
      isOpen,
      onOpen: onOpenPopover,
      onClose,
      contentRef,
      triggerRef,
    } = useControlledPopover();

    const onOpen = useCallback(() => {
      onOpenPopover();
      onOpenWidget();
    }, [onOpenPopover, onOpenWidget]);

    // NOTE: I expect the onClose function to be called when the popover is closed
    // It is not. Thus, I'm monitoring the state when the popover is closed and only then closing the widget.
    useEffect(() => {
      if (isOpen) {
        return;
      }
      onCloseWidget();
    });
    const mergedRef = useMergeRefs(triggerRef, ref);

    const onEmojiChange: ComponentProps<typeof EmojiPicker>['onChange'] = useCallback(
      (selectedEmoji, { isExplicit = false } = {}) => {
        if (nullSafeEqual(selectedEmoji, emoji)) {
          return;
        }

        onChange(selectedEmoji);
        if (isExplicit) {
          onClose();
        }
      },
      [onChange, emoji, onClose],
    );

    return (
      <Popover
        isLazy
        isOpen={isOpen}
        onOpen={onOpen}
        placement="bottom-start"
        closeOnBlur={false}
        returnFocusOnClose={false}
        onClose={onClose}
        autoFocus={false}
      >
        <PopoverTrigger>
          <Box
            ref={mergedRef}
            pointerEvents={readonly ? 'none' : undefined}
            cursor="pointer"
            color={color}
            onClick={stopEventPropagation}
            onMouseDown={stopEventPropagation}
            borderRadius={borderRadius}
            _hover={{ bgColor: hoverBgColor }}
            _active={{ bgColor: activeBgColor }}
          >
            <Tooltip placement="top" isDisabled={emoji != null} label="Add an emoji">
              {icon !== null ? (
                icon
              ) : (
                <EmojiIcon emoji={emoji} size={size} bgColor={bgColor} emptyIcon={emptyIcon} />
              )}
            </Tooltip>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            padding={0}
            // width="22.25rem"
            onClick={stopEventPropagation}
            ref={contentRef}
          >
            <EmojiPicker selectedEmoji={emoji} onChange={onEmojiChange} onEscape={onClose} />
          </PopoverContent>
        </Portal>
      </Popover>
    );
  },
);

export default React.memo(EmojiWidget);
