import { Line } from '@visx/shape';
import React from 'react';

import useChartContext from 'hooks/useChartContext';

interface Props {
  x: number;
  color: string;
}

const CursorLine: React.FC<Props> = ({ x, color }) => {
  const { height } = useChartContext();

  return (
    <Line
      from={{ x, y: 0 }}
      to={{ x, y: height }}
      strokeWidth={1}
      strokeDasharray={2}
      stroke={color}
      pointerEvents="none"
    />
  );
};

export default React.memo(CursorLine);
