import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';

import { isInputKeyboardEvent } from 'helpers/browserEvent';
import useOnClickOutsidePopover from 'hooks/useOnClickOutsidePopover';

interface Props {
  onClickOutside?: () => void;
  selector?: string;
  closeOnEsc?: boolean;
  defaultIsOpen?: boolean;
  // If you want to pass in your own external disclosure state you can
  disclosureProps?: UseDisclosureReturn;
}

export default function useControlledPopover<
  T extends HTMLElement = HTMLDivElement,
  U extends HTMLElement = HTMLDivElement,
>({ onClickOutside, selector, closeOnEsc = true, defaultIsOpen, disclosureProps }: Props = {}) {
  const disclosure = useDisclosure({ defaultIsOpen });
  const contentRef = useRef<T>(null);
  const triggerRef = useRef<U>(null);

  const resolvedDisclosureProps = disclosureProps ?? disclosure;

  const { isOpen, onClose } = resolvedDisclosureProps;

  const clickOutsideHandler = useCallback(() => {
    if (onClickOutside != null) {
      onClickOutside();
    }
    onClose();
  }, [onClickOutside, onClose]);

  useOnClickOutsidePopover(contentRef, triggerRef, clickOutsideHandler, {
    disabled: !isOpen,
    selector,
  });

  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (isInputKeyboardEvent(ev) || ev.key !== 'Escape') {
        return;
      }
      ev.preventDefault();
      ev.stopPropagation();
      onClose();
    },
    [onClose],
  );

  useEffect(() => {
    if (!isOpen || !closeOnEsc) {
      return () => {};
    }

    document.addEventListener('keydown', onKeyDown, true);
    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onKeyDown, closeOnEsc, isOpen]);

  return {
    ...resolvedDisclosureProps,
    contentRef,
    triggerRef,
  };
}
