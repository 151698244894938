import { useContext } from 'react';
import { useDrop } from 'react-dnd';

import { DriverDragItem } from 'components/DriverNameTableCell/DriverNameTableCell';
import { DriverGridContext } from 'config/driverGridContext';
import { DRIVER_DRAG_ITEM_TYPE } from 'config/drivers';
import { DriverGroupId } from 'reduxStore/models/driverGroup';

export default function useDriverDND(
  groupId: DriverGroupId | undefined,
  position: 'start' | 'end',
) {
  const { onDropOnGroup } = useContext(DriverGridContext);
  const [{ isOver }, dropRef] = useDrop<DriverDragItem, unknown, { isOver: boolean }>(
    () => ({
      accept: DRIVER_DRAG_ITEM_TYPE,
      drop: (dropItem) => onDropOnGroup?.(dropItem.driverId, groupId, position),
      canDrop: () => onDropOnGroup != null,
      collect: (monitor) => ({
        isOver: monitor.isOver() && monitor.canDrop(),
      }),
    }),
    [groupId, onDropOnGroup, position],
  );

  return [isOver, dropRef] as const;
}
