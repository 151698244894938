// Generated from ../antlr/Calculator.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class CalculatorLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly T__8 = 9;
	public static readonly T__9 = 10;
	public static readonly T__10 = 11;
	public static readonly T__11 = 12;
	public static readonly T__12 = 13;
	public static readonly T__13 = 14;
	public static readonly T__14 = 15;
	public static readonly T__15 = 16;
	public static readonly T__16 = 17;
	public static readonly T__17 = 18;
	public static readonly T__18 = 19;
	public static readonly T__19 = 20;
	public static readonly T__20 = 21;
	public static readonly T__21 = 22;
	public static readonly T__22 = 23;
	public static readonly T__23 = 24;
	public static readonly T__24 = 25;
	public static readonly T__25 = 26;
	public static readonly T__26 = 27;
	public static readonly T__27 = 28;
	public static readonly T__28 = 29;
	public static readonly T__29 = 30;
	public static readonly T__30 = 31;
	public static readonly T__31 = 32;
	public static readonly T__32 = 33;
	public static readonly T__33 = 34;
	public static readonly T__34 = 35;
	public static readonly T__35 = 36;
	public static readonly T__36 = 37;
	public static readonly T__37 = 38;
	public static readonly T__38 = 39;
	public static readonly T__39 = 40;
	public static readonly T__40 = 41;
	public static readonly T__41 = 42;
	public static readonly T__42 = 43;
	public static readonly T__43 = 44;
	public static readonly T__44 = 45;
	public static readonly T__45 = 46;
	public static readonly T__46 = 47;
	public static readonly DATE = 48;
	public static readonly UUID = 49;
	public static readonly NUMBER = 50;
	public static readonly FLOAT = 51;
	public static readonly NUMBER_SUFFIX = 52;
	public static readonly STRING = 53;
	public static readonly LPAREN = 54;
	public static readonly RPAREN = 55;
	public static readonly ADD = 56;
	public static readonly SUB = 57;
	public static readonly MUL = 58;
	public static readonly DIV = 59;
	public static readonly POW = 60;
	public static readonly NULL = 61;
	public static readonly ANY = 62;
	public static readonly CALENDAR = 63;
	public static readonly RELATIVE = 64;
	public static readonly COHORT_MONTH = 65;
	public static readonly ROUND = 66;
	public static readonly ROUNDDOWN = 67;
	public static readonly WS = 68;
	public static readonly BOOLEAN_OPERATOR = 69;
	public static readonly THIS = 70;
	public static readonly LINKED = 71;
	public static readonly INVALID_WORD_CHARS = 72;
	public static readonly INVALID_WORD = 73;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "T__6", "T__7", "T__8", 
		"T__9", "T__10", "T__11", "T__12", "T__13", "T__14", "T__15", "T__16", 
		"T__17", "T__18", "T__19", "T__20", "T__21", "T__22", "T__23", "T__24", 
		"T__25", "T__26", "T__27", "T__28", "T__29", "T__30", "T__31", "T__32", 
		"T__33", "T__34", "T__35", "T__36", "T__37", "T__38", "T__39", "T__40", 
		"T__41", "T__42", "T__43", "T__44", "T__45", "T__46", "DATE", "YEAR", 
		"UUID", "HEX4", "HEX", "DIGIT", "LETTER", "NUMBER", "FLOAT", "NUMBER_SUFFIX", 
		"STRING", "LPAREN", "RPAREN", "ADD", "SUB", "MUL", "DIV", "POW", "NULL", 
		"ANY", "CALENDAR", "RELATIVE", "COHORT_MONTH", "ROUND", "ROUNDDOWN", "WS", 
		"BOOLEAN_OPERATOR", "THIS", "LINKED", "INVALID_WORD_CHARS", "INVALID_WORD",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'='", "'objectSpec('", "','", "'endofmonth('", "'startofmonth('", 
		"'asdays'", "'asweeks'", "'asmonths'", "'asyears'", "'&&'", "'||'", "'daysinmonth'", 
		"'datediff'", "'networkdays'", "'sumproduct('", "'if('", "'iferror('", 
		"'coalesce('", "'driver('", "'dimDriver('", "'submodel('", "'object('", 
		"'extObject('", "'extField('", "'field('", "'extDriver('", "'extQuery('", 
		"'filter('", "':'", "'DRIVER_GROUP:'", "'match('", "'ALL_CONTEXT_ATTRIBUTES'", 
		"'formulaFilter('", "'contextAttribute('", "'sum'", "'count'", "'avg'", 
		"'min'", "'max'", "'relative('", "'range('", "'cohort('", "'relMonths('", 
		"'relQuarters('", "'relYears('", "'\"'", "'|'", undefined, undefined, 
		undefined, undefined, undefined, undefined, "'('", "')'", "'+'", "'-'", 
		"'*'", "'/'", "'^'", undefined, "'ANY'", "'CALENDAR'", "'RELATIVE'", "'COHORTMONTH'", 
		undefined, undefined, undefined, undefined, "'this'", "'linked'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, "DATE", 
		"UUID", "NUMBER", "FLOAT", "NUMBER_SUFFIX", "STRING", "LPAREN", "RPAREN", 
		"ADD", "SUB", "MUL", "DIV", "POW", "NULL", "ANY", "CALENDAR", "RELATIVE", 
		"COHORT_MONTH", "ROUND", "ROUNDDOWN", "WS", "BOOLEAN_OPERATOR", "THIS", 
		"LINKED", "INVALID_WORD_CHARS", "INVALID_WORD",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(CalculatorLexer._LITERAL_NAMES, CalculatorLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return CalculatorLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(CalculatorLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "Calculator.g4"; }

	// @Override
	public get ruleNames(): string[] { return CalculatorLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return CalculatorLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return CalculatorLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return CalculatorLexer.modeNames; }

	private static readonly _serializedATNSegments: number = 2;
	private static readonly _serializedATNSegment0: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02K\u031E\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
		"\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t" +
		"\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t" +
		"\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x04(\t(\x04)\t)\x04*\t*\x04" +
		"+\t+\x04,\t,\x04-\t-\x04.\t.\x04/\t/\x040\t0\x041\t1\x042\t2\x043\t3\x04" +
		"4\t4\x045\t5\x046\t6\x047\t7\x048\t8\x049\t9\x04:\t:\x04;\t;\x04<\t<\x04" +
		"=\t=\x04>\t>\x04?\t?\x04@\t@\x04A\tA\x04B\tB\x04C\tC\x04D\tD\x04E\tE\x04" +
		"F\tF\x04G\tG\x04H\tH\x04I\tI\x04J\tJ\x04K\tK\x04L\tL\x04M\tM\x04N\tN\x04" +
		"O\tO\x03\x02\x03\x02\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x04\x03\x04\x03\x05\x03" +
		"\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03" +
		"\x05\x03\x05\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03" +
		"\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\b\x03\b\x03\b\x03\b\x03\b\x03" +
		"\b\x03\b\x03\b\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03" +
		"\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\v\x03\v\x03\v\x03\f\x03" +
		"\f\x03\f\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03" +
		"\r\x03\r\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E" +
		"\x03\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F" +
		"\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10" +
		"\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x11\x03\x11" +
		"\x03\x11\x03\x11\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12" +
		"\x03\x12\x03\x12\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13" +
		"\x03\x13\x03\x13\x03\x13\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14" +
		"\x03\x14\x03\x14\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15" +
		"\x03\x15\x03\x15\x03\x15\x03\x15\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16" +
		"\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16\x03\x17\x03\x17\x03\x17\x03\x17" +
		"\x03\x17\x03\x17\x03\x17\x03\x17\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18" +
		"\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x19\x03\x19\x03\x19" +
		"\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x1A\x03\x1A" +
		"\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1B\x03\x1B" +
		"\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1C\x03\x1C" +
		"\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1D" +
		"\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1E\x03\x1E" +
		"\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F" +
		"\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03 \x03 \x03 \x03 \x03 \x03" +
		" \x03 \x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03" +
		"!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03\"\x03\"\x03\"" +
		"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03" +
		"\"\x03#\x03#\x03#\x03#\x03#\x03#\x03#\x03#\x03#\x03#\x03#\x03#\x03#\x03" +
		"#\x03#\x03#\x03#\x03#\x03$\x03$\x03$\x03$\x03%\x03%\x03%\x03%\x03%\x03" +
		"%\x03&\x03&\x03&\x03&\x03\'\x03\'\x03\'\x03\'\x03(\x03(\x03(\x03(\x03" +
		")\x03)\x03)\x03)\x03)\x03)\x03)\x03)\x03)\x03)\x03*\x03*\x03*\x03*\x03" +
		"*\x03*\x03*\x03+\x03+\x03+\x03+\x03+\x03+\x03+\x03+\x03,\x03,\x03,\x03" +
		",\x03,\x03,\x03,\x03,\x03,\x03,\x03,\x03-\x03-\x03-\x03-\x03-\x03-\x03" +
		"-\x03-\x03-\x03-\x03-\x03-\x03-\x03.\x03.\x03.\x03.\x03.\x03.\x03.\x03" +
		".\x03.\x03.\x03/\x03/\x030\x030\x031\x031\x031\x031\x031\x032\x032\x03" +
		"2\x032\x032\x033\x033\x033\x033\x033\x033\x033\x033\x033\x033\x033\x03" +
		"3\x033\x034\x034\x034\x034\x034\x035\x035\x036\x036\x037\x037\x038\x05" +
		"8\u0259\n8\x038\x038\x058\u025D\n8\x038\x058\u0260\n8\x038\x068\u0263" +
		"\n8\r8\x0E8\u0264\x038\x038\x038\x038\x038\x078\u026C\n8\f8\x0E8\u026F" +
		"\v8\x038\x058\u0272\n8\x038\x038\x038\x038\x068\u0278\n8\r8\x0E8\u0279" +
		"\x038\x038\x038\x038\x038\x078\u0281\n8\f8\x0E8\u0284\v8\x038\x038\x05" +
		"8\u0288\n8\x039\x069\u028B\n9\r9\x0E9\u028C\x039\x039\x039\x039\x039\x07" +
		"9\u0294\n9\f9\x0E9\u0297\v9\x039\x039\x079\u029B\n9\f9\x0E9\u029E\v9\x03" +
		"9\x039\x069\u02A2\n9\r9\x0E9\u02A3\x059\u02A6\n9\x03:\x03:\x03;\x03;\x07" +
		";\u02AC\n;\f;\x0E;\u02AF\v;\x03;\x03;\x03<\x03<\x03=\x03=\x03>\x03>\x03" +
		"?\x03?\x03@\x03@\x03A\x03A\x03B\x03B\x03C\x03C\x03C\x03C\x03C\x03D\x03" +
		"D\x03D\x03D\x03E\x03E\x03E\x03E\x03E\x03E\x03E\x03E\x03E\x03F\x03F\x03" +
		"F\x03F\x03F\x03F\x03F\x03F\x03F\x03G\x03G\x03G\x03G\x03G\x03G\x03G\x03" +
		"G\x03G\x03G\x03G\x03G\x03H\x03H\x03H\x03H\x03H\x03H\x03I\x03I\x03I\x03" +
		"I\x03I\x03I\x03I\x03I\x03I\x03I\x03J\x06J\u02F9\nJ\rJ\x0EJ\u02FA\x03J" +
		"\x03J\x03K\x03K\x03K\x03K\x03K\x03K\x03K\x03K\x03K\x05K\u0308\nK\x03L" +
		"\x03L\x03L\x03L\x03L\x03M\x03M\x03M\x03M\x03M\x03M\x03M\x03N\x03N\x05" +
		"N\u0318\nN\x03O\x06O\u031B\nO\rO\x0EO\u031C\x02\x02\x02P\x03\x02\x03\x05" +
		"\x02\x04\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13" +
		"\x02\v\x15\x02\f\x17\x02\r\x19\x02\x0E\x1B\x02\x0F\x1D\x02\x10\x1F\x02" +
		"\x11!\x02\x12#\x02\x13%\x02\x14\'\x02\x15)\x02\x16+\x02\x17-\x02\x18/" +
		"\x02\x191\x02\x1A3\x02\x1B5\x02\x1C7\x02\x1D9\x02\x1E;\x02\x1F=\x02 ?" +
		"\x02!A\x02\"C\x02#E\x02$G\x02%I\x02&K\x02\'M\x02(O\x02)Q\x02*S\x02+U\x02" +
		",W\x02-Y\x02.[\x02/]\x020_\x021a\x022c\x02\x02e\x023g\x02\x02i\x02\x02" +
		"k\x02\x02m\x02\x02o\x024q\x025s\x026u\x027w\x028y\x029{\x02:}\x02;\x7F" +
		"\x02<\x81\x02=\x83\x02>\x85\x02?\x87\x02@\x89\x02A\x8B\x02B\x8D\x02C\x8F" +
		"\x02D\x91\x02E\x93\x02F\x95\x02G\x97\x02H\x99\x02I\x9B\x02J\x9D\x02K\x03" +
		"\x02\x11\x05\x022;CHch\x03\x022;\x04\x02C\\c|\n\x02DDMMOOVVddmmoovv\x03" +
		"\x02))\x04\x02PPpp\x04\x02WWww\x04\x02NNnn\x04\x02TTtt\x04\x02QQqq\x04" +
		"\x02FFff\x04\x02YYyy\x05\x02\v\f\x0F\x0F\"\"\x04\x02>>@@\x07\x02$%\'\'" +
		"]]__aa\x02\u0330\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02\x02\x07" +
		"\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02\r\x03" +
		"\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03" +
		"\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02\x19\x03" +
		"\x02\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03" +
		"\x02\x02\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03\x02\x02" +
		"\x02\x02\'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x02" +
		"-\x03\x02\x02\x02\x02/\x03\x02\x02\x02\x021\x03\x02\x02\x02\x023\x03\x02" +
		"\x02\x02\x025\x03\x02\x02\x02\x027\x03\x02\x02\x02\x029\x03\x02\x02\x02" +
		"\x02;\x03\x02\x02\x02\x02=\x03\x02\x02\x02\x02?\x03\x02\x02\x02\x02A\x03" +
		"\x02\x02\x02\x02C\x03\x02\x02\x02\x02E\x03\x02\x02\x02\x02G\x03\x02\x02" +
		"\x02\x02I\x03\x02\x02\x02\x02K\x03\x02\x02\x02\x02M\x03\x02\x02\x02\x02" +
		"O\x03\x02\x02\x02\x02Q\x03\x02\x02\x02\x02S\x03\x02\x02\x02\x02U\x03\x02" +
		"\x02\x02\x02W\x03\x02\x02\x02\x02Y\x03\x02\x02\x02\x02[\x03\x02\x02\x02" +
		"\x02]\x03\x02\x02\x02\x02_\x03\x02\x02\x02\x02a\x03\x02\x02\x02\x02e\x03" +
		"\x02\x02\x02\x02o\x03\x02\x02\x02\x02q\x03\x02\x02\x02\x02s\x03\x02\x02" +
		"\x02\x02u\x03\x02\x02\x02\x02w\x03\x02\x02\x02\x02y\x03\x02\x02\x02\x02" +
		"{\x03\x02\x02\x02\x02}\x03\x02\x02\x02\x02\x7F\x03\x02\x02\x02\x02\x81" +
		"\x03\x02\x02\x02\x02\x83\x03\x02\x02\x02\x02\x85\x03\x02\x02\x02\x02\x87" +
		"\x03\x02\x02\x02\x02\x89\x03\x02\x02\x02\x02\x8B\x03\x02\x02\x02\x02\x8D" +
		"\x03\x02\x02\x02\x02\x8F\x03\x02\x02\x02\x02\x91\x03\x02\x02\x02\x02\x93" +
		"\x03\x02\x02\x02\x02\x95\x03\x02\x02\x02\x02\x97\x03\x02\x02\x02\x02\x99" +
		"\x03\x02\x02\x02\x02\x9B\x03\x02\x02\x02\x02\x9D\x03\x02\x02\x02\x03\x9F" +
		"\x03\x02\x02\x02\x05\xA1\x03\x02\x02\x02\x07\xAD\x03\x02\x02\x02\t\xAF" +
		"\x03\x02\x02\x02\v\xBB\x03\x02\x02\x02\r\xC9\x03\x02\x02\x02\x0F\xD0\x03" +
		"\x02\x02\x02\x11\xD8\x03\x02\x02\x02\x13\xE1\x03\x02\x02\x02\x15\xE9\x03" +
		"\x02\x02\x02\x17\xEC\x03\x02\x02\x02\x19\xEF\x03\x02\x02\x02\x1B\xFB\x03" +
		"\x02\x02\x02\x1D\u0104\x03\x02\x02\x02\x1F\u0110\x03\x02\x02\x02!\u011C" +
		"\x03\x02\x02\x02#\u0120\x03\x02\x02\x02%\u0129\x03\x02\x02\x02\'\u0133" +
		"\x03\x02\x02\x02)\u013B\x03\x02\x02\x02+\u0146\x03\x02\x02\x02-\u0150" +
		"\x03\x02\x02\x02/\u0158\x03\x02\x02\x021\u0163\x03\x02\x02\x023\u016D" +
		"\x03\x02\x02\x025\u0174\x03\x02\x02\x027\u017F\x03\x02\x02\x029\u0189" +
		"\x03\x02\x02\x02;\u0191\x03\x02\x02\x02=\u0193\x03\x02\x02\x02?\u01A1" +
		"\x03\x02\x02\x02A\u01A8\x03\x02\x02\x02C\u01BF\x03\x02\x02\x02E\u01CE" +
		"\x03\x02\x02\x02G\u01E0\x03\x02\x02\x02I\u01E4\x03\x02\x02\x02K\u01EA" +
		"\x03\x02\x02\x02M\u01EE\x03\x02\x02\x02O\u01F2\x03\x02\x02\x02Q\u01F6" +
		"\x03\x02\x02\x02S\u0200\x03\x02\x02\x02U\u0207\x03\x02\x02\x02W\u020F" +
		"\x03\x02\x02\x02Y\u021A\x03\x02\x02\x02[\u0227\x03\x02\x02\x02]\u0231" +
		"\x03\x02\x02\x02_\u0233\x03\x02\x02\x02a\u0235\x03\x02\x02\x02c\u023A" +
		"\x03\x02\x02\x02e\u023F\x03\x02\x02\x02g\u024C\x03\x02\x02\x02i\u0251" +
		"\x03\x02\x02\x02k\u0253\x03\x02\x02\x02m\u0255\x03\x02\x02\x02o\u0287" +
		"\x03\x02\x02\x02q\u02A5\x03\x02\x02\x02s\u02A7\x03\x02\x02\x02u\u02A9" +
		"\x03\x02\x02\x02w\u02B2\x03\x02\x02\x02y\u02B4\x03\x02\x02\x02{\u02B6" +
		"\x03\x02\x02\x02}\u02B8\x03\x02\x02\x02\x7F\u02BA\x03\x02\x02\x02\x81" +
		"\u02BC\x03\x02\x02\x02\x83\u02BE\x03\x02\x02\x02\x85\u02C0\x03\x02\x02" +
		"\x02\x87\u02C5\x03\x02\x02\x02\x89\u02C9\x03\x02\x02\x02\x8B\u02D2\x03" +
		"\x02\x02\x02\x8D\u02DB\x03\x02\x02\x02\x8F\u02E7\x03\x02\x02\x02\x91\u02ED" +
		"\x03\x02\x02\x02\x93\u02F8\x03\x02\x02\x02\x95\u0307\x03\x02\x02\x02\x97" +
		"\u0309\x03\x02\x02\x02\x99\u030E\x03\x02\x02\x02\x9B\u0317\x03\x02\x02" +
		"\x02\x9D\u031A\x03\x02\x02\x02\x9F\xA0\x07?\x02\x02\xA0\x04\x03\x02\x02" +
		"\x02\xA1\xA2\x07q\x02\x02\xA2\xA3\x07d\x02\x02\xA3\xA4\x07l\x02\x02\xA4" +
		"\xA5\x07g\x02\x02\xA5\xA6\x07e\x02\x02\xA6\xA7\x07v\x02\x02\xA7\xA8\x07" +
		"U\x02\x02\xA8\xA9\x07r\x02\x02\xA9\xAA\x07g\x02\x02\xAA\xAB\x07e\x02\x02" +
		"\xAB\xAC\x07*\x02\x02\xAC\x06\x03\x02\x02\x02\xAD\xAE\x07.\x02\x02\xAE" +
		"\b\x03\x02\x02\x02\xAF\xB0\x07g\x02\x02\xB0\xB1\x07p\x02\x02\xB1\xB2\x07" +
		"f\x02\x02\xB2\xB3\x07q\x02\x02\xB3\xB4\x07h\x02\x02\xB4\xB5\x07o\x02\x02" +
		"\xB5\xB6\x07q\x02\x02\xB6\xB7\x07p\x02\x02\xB7\xB8\x07v\x02\x02\xB8\xB9" +
		"\x07j\x02\x02\xB9\xBA\x07*\x02\x02\xBA\n\x03\x02\x02\x02\xBB\xBC\x07u" +
		"\x02\x02\xBC\xBD\x07v\x02\x02\xBD\xBE\x07c\x02\x02\xBE\xBF\x07t\x02\x02" +
		"\xBF\xC0\x07v\x02\x02\xC0\xC1\x07q\x02\x02\xC1\xC2\x07h\x02\x02\xC2\xC3" +
		"\x07o\x02\x02\xC3\xC4\x07q\x02\x02\xC4\xC5\x07p\x02\x02\xC5\xC6\x07v\x02" +
		"\x02\xC6\xC7\x07j\x02\x02\xC7\xC8\x07*\x02\x02\xC8\f\x03\x02\x02\x02\xC9" +
		"\xCA\x07c\x02\x02\xCA\xCB\x07u\x02\x02\xCB\xCC\x07f\x02\x02\xCC\xCD\x07" +
		"c\x02\x02\xCD\xCE\x07{\x02\x02\xCE\xCF\x07u\x02\x02\xCF\x0E\x03\x02\x02" +
		"\x02\xD0\xD1\x07c\x02\x02\xD1\xD2\x07u\x02\x02\xD2\xD3\x07y\x02\x02\xD3" +
		"\xD4\x07g\x02\x02\xD4\xD5\x07g\x02\x02\xD5\xD6\x07m\x02\x02\xD6\xD7\x07" +
		"u\x02\x02\xD7\x10\x03\x02\x02\x02\xD8\xD9\x07c\x02\x02\xD9\xDA\x07u\x02" +
		"\x02\xDA\xDB\x07o\x02\x02\xDB\xDC\x07q\x02\x02\xDC\xDD\x07p\x02\x02\xDD" +
		"\xDE\x07v\x02\x02\xDE\xDF\x07j\x02\x02\xDF\xE0\x07u\x02\x02\xE0\x12\x03" +
		"\x02\x02\x02\xE1\xE2\x07c\x02\x02\xE2\xE3\x07u\x02\x02\xE3\xE4\x07{\x02" +
		"\x02\xE4\xE5\x07g\x02\x02\xE5\xE6\x07c\x02\x02\xE6\xE7\x07t\x02\x02\xE7" +
		"\xE8\x07u\x02\x02\xE8\x14\x03\x02\x02\x02\xE9\xEA\x07(\x02\x02\xEA\xEB" +
		"\x07(\x02\x02\xEB\x16\x03\x02\x02\x02\xEC\xED\x07~\x02\x02\xED\xEE\x07" +
		"~\x02\x02\xEE\x18\x03\x02\x02\x02\xEF\xF0\x07f\x02\x02\xF0\xF1\x07c\x02" +
		"\x02\xF1\xF2\x07{\x02\x02\xF2\xF3\x07u\x02\x02\xF3\xF4\x07k\x02\x02\xF4" +
		"\xF5\x07p\x02\x02\xF5\xF6\x07o\x02\x02\xF6\xF7\x07q\x02\x02\xF7\xF8\x07" +
		"p\x02\x02\xF8\xF9\x07v\x02\x02\xF9\xFA\x07j\x02\x02\xFA\x1A\x03\x02\x02" +
		"\x02\xFB\xFC\x07f\x02\x02\xFC\xFD\x07c\x02\x02\xFD\xFE\x07v\x02\x02\xFE" +
		"\xFF\x07g\x02\x02\xFF\u0100\x07f\x02\x02\u0100\u0101\x07k\x02\x02\u0101" +
		"\u0102\x07h\x02\x02\u0102\u0103\x07h\x02\x02\u0103\x1C\x03\x02\x02\x02" +
		"\u0104\u0105\x07p\x02\x02\u0105\u0106\x07g\x02\x02\u0106\u0107\x07v\x02" +
		"\x02\u0107\u0108\x07y\x02\x02\u0108\u0109\x07q\x02\x02\u0109\u010A\x07" +
		"t\x02\x02\u010A\u010B\x07m\x02\x02\u010B\u010C\x07f\x02\x02\u010C\u010D" +
		"\x07c\x02\x02\u010D\u010E\x07{\x02\x02\u010E\u010F\x07u\x02\x02\u010F" +
		"\x1E\x03\x02\x02\x02\u0110\u0111\x07u\x02\x02\u0111\u0112\x07w\x02\x02" +
		"\u0112\u0113\x07o\x02\x02\u0113\u0114\x07r\x02\x02\u0114\u0115\x07t\x02" +
		"\x02\u0115\u0116\x07q\x02\x02\u0116\u0117\x07f\x02\x02\u0117\u0118\x07" +
		"w\x02\x02\u0118\u0119\x07e\x02\x02\u0119\u011A\x07v\x02\x02\u011A\u011B" +
		"\x07*\x02\x02\u011B \x03\x02\x02\x02\u011C\u011D\x07k\x02\x02\u011D\u011E" +
		"\x07h\x02\x02\u011E\u011F\x07*\x02\x02\u011F\"\x03\x02\x02\x02\u0120\u0121" +
		"\x07k\x02\x02\u0121\u0122\x07h\x02\x02\u0122\u0123\x07g\x02\x02\u0123" +
		"\u0124\x07t\x02\x02\u0124\u0125\x07t\x02\x02\u0125\u0126\x07q\x02\x02" +
		"\u0126\u0127\x07t\x02\x02\u0127\u0128\x07*\x02\x02\u0128$\x03\x02\x02" +
		"\x02\u0129\u012A\x07e\x02\x02\u012A\u012B\x07q\x02\x02\u012B\u012C\x07" +
		"c\x02\x02\u012C\u012D\x07n\x02\x02\u012D\u012E\x07g\x02\x02\u012E\u012F" +
		"\x07u\x02\x02\u012F\u0130\x07e\x02\x02\u0130\u0131\x07g\x02\x02\u0131" +
		"\u0132\x07*\x02\x02\u0132&\x03\x02\x02\x02\u0133\u0134\x07f\x02\x02\u0134" +
		"\u0135\x07t\x02\x02\u0135\u0136\x07k\x02\x02\u0136\u0137\x07x\x02\x02" +
		"\u0137\u0138\x07g\x02\x02\u0138\u0139\x07t\x02\x02\u0139\u013A\x07*\x02" +
		"\x02\u013A(\x03\x02\x02\x02\u013B\u013C\x07f\x02\x02\u013C\u013D\x07k" +
		"\x02\x02\u013D\u013E\x07o\x02\x02\u013E\u013F\x07F\x02\x02\u013F\u0140" +
		"\x07t\x02\x02\u0140\u0141\x07k\x02\x02\u0141\u0142\x07x\x02\x02\u0142" +
		"\u0143\x07g\x02\x02\u0143\u0144\x07t\x02\x02\u0144\u0145\x07*\x02\x02" +
		"\u0145*\x03\x02\x02\x02\u0146\u0147\x07u\x02\x02\u0147\u0148\x07w\x02" +
		"\x02\u0148\u0149\x07d\x02\x02\u0149\u014A\x07o\x02\x02\u014A\u014B\x07" +
		"q\x02\x02\u014B\u014C\x07f\x02\x02\u014C\u014D\x07g\x02\x02\u014D\u014E" +
		"\x07n\x02\x02\u014E\u014F\x07*\x02\x02\u014F,\x03\x02\x02\x02\u0150\u0151" +
		"\x07q\x02\x02\u0151\u0152\x07d\x02\x02\u0152\u0153\x07l\x02\x02\u0153" +
		"\u0154\x07g\x02\x02\u0154\u0155\x07e\x02\x02\u0155\u0156\x07v\x02\x02" +
		"\u0156\u0157\x07*\x02\x02\u0157.\x03\x02\x02\x02\u0158\u0159\x07g\x02" +
		"\x02\u0159\u015A\x07z\x02\x02\u015A\u015B\x07v\x02\x02\u015B\u015C\x07" +
		"Q\x02\x02\u015C\u015D\x07d\x02\x02\u015D\u015E\x07l\x02\x02\u015E\u015F" +
		"\x07g\x02\x02\u015F\u0160\x07e\x02\x02\u0160\u0161\x07v\x02\x02\u0161" +
		"\u0162\x07*\x02\x02\u01620\x03\x02\x02\x02\u0163\u0164\x07g\x02\x02\u0164" +
		"\u0165\x07z\x02\x02\u0165\u0166\x07v\x02\x02\u0166\u0167\x07H\x02\x02" +
		"\u0167\u0168\x07k\x02\x02\u0168\u0169\x07g\x02\x02\u0169\u016A\x07n\x02" +
		"\x02\u016A\u016B\x07f\x02\x02\u016B\u016C\x07*\x02\x02\u016C2\x03\x02" +
		"\x02\x02\u016D\u016E\x07h\x02\x02\u016E\u016F\x07k\x02\x02\u016F\u0170" +
		"\x07g\x02\x02\u0170\u0171\x07n\x02\x02\u0171\u0172\x07f\x02\x02\u0172" +
		"\u0173\x07*\x02\x02\u01734\x03\x02\x02\x02\u0174\u0175\x07g\x02\x02\u0175" +
		"\u0176\x07z\x02\x02\u0176\u0177\x07v\x02\x02\u0177\u0178\x07F\x02\x02" +
		"\u0178\u0179\x07t\x02\x02\u0179\u017A\x07k\x02\x02\u017A\u017B\x07x\x02" +
		"\x02\u017B\u017C\x07g\x02\x02\u017C\u017D\x07t\x02\x02\u017D\u017E\x07" +
		"*\x02\x02\u017E6\x03\x02\x02\x02\u017F\u0180\x07g\x02\x02\u0180\u0181" +
		"\x07z\x02\x02\u0181\u0182\x07v\x02\x02\u0182\u0183\x07S\x02\x02\u0183" +
		"\u0184\x07w\x02\x02\u0184\u0185\x07g\x02\x02\u0185\u0186\x07t\x02\x02" +
		"\u0186\u0187\x07{\x02\x02\u0187\u0188\x07*\x02\x02\u01888\x03\x02\x02" +
		"\x02\u0189\u018A\x07h\x02\x02\u018A\u018B\x07k\x02\x02\u018B\u018C\x07" +
		"n\x02\x02\u018C\u018D\x07v\x02\x02\u018D\u018E\x07g\x02\x02\u018E\u018F" +
		"\x07t\x02\x02\u018F\u0190\x07*\x02\x02\u0190:\x03\x02\x02\x02\u0191\u0192" +
		"\x07<\x02\x02\u0192<\x03\x02\x02\x02\u0193\u0194\x07F\x02\x02\u0194\u0195" +
		"\x07T\x02\x02\u0195\u0196\x07K\x02\x02\u0196\u0197\x07X\x02\x02\u0197" +
		"\u0198\x07G\x02\x02\u0198\u0199\x07T\x02\x02\u0199\u019A\x07a\x02\x02" +
		"\u019A\u019B\x07I\x02\x02\u019B\u019C\x07T\x02\x02\u019C\u019D\x07Q\x02" +
		"\x02\u019D\u019E\x07W\x02\x02\u019E\u019F\x07R\x02\x02\u019F\u01A0\x07" +
		"<\x02\x02\u01A0>\x03\x02\x02\x02\u01A1\u01A2\x07o\x02\x02\u01A2\u01A3" +
		"\x07c\x02\x02\u01A3\u01A4\x07v\x02\x02\u01A4\u01A5\x07e\x02\x02\u01A5" +
		"\u01A6\x07j\x02\x02\u01A6\u01A7\x07*\x02\x02\u01A7@\x03\x02\x02\x02\u01A8" +
		"\u01A9\x07C\x02\x02\u01A9\u01AA\x07N\x02\x02\u01AA\u01AB\x07N\x02\x02" +
		"\u01AB\u01AC\x07a\x02\x02\u01AC\u01AD\x07E\x02\x02\u01AD\u01AE\x07Q\x02" +
		"\x02\u01AE\u01AF\x07P\x02\x02\u01AF\u01B0\x07V\x02\x02\u01B0\u01B1\x07" +
		"G\x02\x02\u01B1\u01B2\x07Z\x02\x02\u01B2\u01B3\x07V\x02\x02\u01B3\u01B4" +
		"\x07a\x02\x02\u01B4\u01B5\x07C\x02\x02\u01B5\u01B6\x07V\x02\x02\u01B6" +
		"\u01B7\x07V\x02\x02\u01B7\u01B8\x07T\x02\x02\u01B8\u01B9\x07K\x02\x02" +
		"\u01B9\u01BA\x07D\x02\x02\u01BA\u01BB\x07W\x02\x02\u01BB\u01BC\x07V\x02" +
		"\x02\u01BC\u01BD\x07G\x02\x02\u01BD\u01BE\x07U\x02\x02\u01BEB\x03\x02" +
		"\x02\x02\u01BF\u01C0\x07h\x02\x02\u01C0\u01C1\x07q\x02\x02\u01C1\u01C2" +
		"\x07t\x02\x02\u01C2\u01C3\x07o\x02\x02\u01C3\u01C4\x07w\x02\x02\u01C4" +
		"\u01C5\x07n\x02\x02\u01C5\u01C6\x07c\x02\x02\u01C6\u01C7\x07H\x02\x02" +
		"\u01C7\u01C8\x07k\x02\x02\u01C8\u01C9\x07n\x02\x02\u01C9\u01CA\x07v\x02" +
		"\x02\u01CA\u01CB\x07g\x02\x02\u01CB\u01CC\x07t\x02\x02\u01CC\u01CD\x07" +
		"*\x02\x02\u01CDD\x03\x02\x02\x02\u01CE\u01CF\x07e\x02\x02\u01CF\u01D0" +
		"\x07q\x02\x02\u01D0\u01D1\x07p\x02\x02\u01D1\u01D2\x07v\x02\x02\u01D2" +
		"\u01D3\x07g\x02\x02\u01D3\u01D4\x07z\x02\x02\u01D4\u01D5\x07v\x02\x02" +
		"\u01D5\u01D6\x07C\x02\x02\u01D6\u01D7\x07v\x02\x02\u01D7\u01D8\x07v\x02" +
		"\x02\u01D8\u01D9\x07t\x02\x02\u01D9\u01DA\x07k\x02\x02\u01DA\u01DB\x07" +
		"d\x02\x02\u01DB\u01DC\x07w\x02\x02\u01DC\u01DD\x07v\x02\x02\u01DD\u01DE" +
		"\x07g\x02\x02\u01DE\u01DF\x07*\x02\x02\u01DFF\x03\x02\x02\x02\u01E0\u01E1" +
		"\x07u\x02\x02\u01E1\u01E2\x07w\x02\x02\u01E2\u01E3\x07o\x02\x02\u01E3" +
		"H\x03\x02\x02\x02\u01E4\u01E5\x07e\x02\x02\u01E5\u01E6\x07q\x02\x02\u01E6" +
		"\u01E7\x07w\x02\x02\u01E7\u01E8\x07p\x02\x02\u01E8\u01E9\x07v\x02\x02" +
		"\u01E9J\x03\x02\x02\x02\u01EA\u01EB\x07c\x02\x02\u01EB\u01EC\x07x\x02" +
		"\x02\u01EC\u01ED\x07i\x02\x02\u01EDL\x03\x02\x02\x02\u01EE\u01EF\x07o" +
		"\x02\x02\u01EF\u01F0\x07k\x02\x02\u01F0\u01F1\x07p\x02\x02\u01F1N\x03" +
		"\x02\x02\x02\u01F2\u01F3\x07o\x02\x02\u01F3\u01F4\x07c\x02\x02\u01F4\u01F5" +
		"\x07z\x02\x02\u01F5P\x03\x02\x02\x02\u01F6\u01F7\x07t\x02\x02\u01F7\u01F8" +
		"\x07g\x02\x02\u01F8\u01F9\x07n\x02\x02\u01F9\u01FA\x07c\x02\x02\u01FA" +
		"\u01FB\x07v\x02\x02\u01FB\u01FC\x07k\x02\x02\u01FC\u01FD\x07x\x02\x02" +
		"\u01FD\u01FE\x07g\x02\x02\u01FE\u01FF\x07";
	private static readonly _serializedATNSegment1: string =
		"*\x02\x02\u01FFR\x03\x02\x02\x02\u0200\u0201\x07t\x02\x02\u0201\u0202" +
		"\x07c\x02\x02\u0202\u0203\x07p\x02\x02\u0203\u0204\x07i\x02\x02\u0204" +
		"\u0205\x07g\x02\x02\u0205\u0206\x07*\x02\x02\u0206T\x03\x02\x02\x02\u0207" +
		"\u0208\x07e\x02\x02\u0208\u0209\x07q\x02\x02\u0209\u020A\x07j\x02\x02" +
		"\u020A\u020B\x07q\x02\x02\u020B\u020C\x07t\x02\x02\u020C\u020D\x07v\x02" +
		"\x02\u020D\u020E\x07*\x02\x02\u020EV\x03\x02\x02\x02\u020F\u0210\x07t" +
		"\x02\x02\u0210\u0211\x07g\x02\x02\u0211\u0212\x07n\x02\x02\u0212\u0213" +
		"\x07O\x02\x02\u0213\u0214\x07q\x02\x02\u0214\u0215\x07p\x02\x02\u0215" +
		"\u0216\x07v\x02\x02\u0216\u0217\x07j\x02\x02\u0217\u0218\x07u\x02\x02" +
		"\u0218\u0219\x07*\x02\x02\u0219X\x03\x02\x02\x02\u021A\u021B\x07t\x02" +
		"\x02\u021B\u021C\x07g\x02\x02\u021C\u021D\x07n\x02\x02\u021D\u021E\x07" +
		"S\x02\x02\u021E\u021F\x07w\x02\x02\u021F\u0220\x07c\x02\x02\u0220\u0221" +
		"\x07t\x02\x02\u0221\u0222\x07v\x02\x02\u0222\u0223\x07g\x02\x02\u0223" +
		"\u0224\x07t\x02\x02\u0224\u0225\x07u\x02\x02\u0225\u0226\x07*\x02\x02" +
		"\u0226Z\x03\x02\x02\x02\u0227\u0228\x07t\x02\x02\u0228\u0229\x07g\x02" +
		"\x02\u0229\u022A\x07n\x02\x02\u022A\u022B\x07[\x02\x02\u022B\u022C\x07" +
		"g\x02\x02\u022C\u022D\x07c\x02\x02\u022D\u022E\x07t\x02\x02\u022E\u022F" +
		"\x07u\x02\x02\u022F\u0230\x07*\x02\x02\u0230\\\x03\x02\x02\x02\u0231\u0232" +
		"\x07$\x02\x02\u0232^\x03\x02\x02\x02\u0233\u0234\x07~\x02\x02\u0234`\x03" +
		"\x02\x02\x02\u0235\u0236\x05c2\x02\u0236\u0237\x07/\x02\x02\u0237\u0238" +
		"\x05k6\x02\u0238\u0239\x05k6\x02\u0239b\x03\x02\x02\x02\u023A\u023B\x05" +
		"k6\x02\u023B\u023C\x05k6\x02\u023C\u023D\x05k6\x02\u023D\u023E\x05k6\x02" +
		"\u023Ed\x03\x02\x02\x02\u023F\u0240\x05g4\x02\u0240\u0241\x05g4\x02\u0241" +
		"\u0242\x07/\x02\x02\u0242\u0243\x05g4\x02\u0243\u0244\x07/\x02\x02\u0244" +
		"\u0245\x05g4\x02\u0245\u0246\x07/\x02\x02\u0246\u0247\x05g4\x02\u0247" +
		"\u0248\x07/\x02\x02\u0248\u0249\x05g4\x02\u0249\u024A\x05g4\x02\u024A" +
		"\u024B\x05g4\x02\u024Bf\x03\x02\x02\x02\u024C\u024D\x05i5\x02\u024D\u024E" +
		"\x05i5\x02\u024E\u024F\x05i5\x02\u024F\u0250\x05i5\x02\u0250h\x03\x02" +
		"\x02\x02\u0251\u0252\t\x02\x02\x02\u0252j\x03\x02\x02\x02\u0253\u0254" +
		"\t\x03\x02\x02\u0254l\x03\x02\x02\x02\u0255\u0256\t\x04\x02\x02\u0256" +
		"n\x03\x02\x02\x02\u0257\u0259\x07&\x02\x02\u0258\u0257\x03\x02\x02\x02" +
		"\u0258\u0259\x03\x02\x02\x02\u0259\u025A\x03\x02\x02\x02\u025A\u025C\x05" +
		"q9\x02\u025B\u025D\x05s:\x02\u025C\u025B\x03\x02\x02\x02\u025C\u025D\x03" +
		"\x02\x02\x02\u025D\u0288\x03\x02\x02\x02\u025E\u0260\x07&\x02\x02\u025F" +
		"\u025E\x03\x02\x02\x02\u025F\u0260\x03\x02\x02\x02\u0260\u0262\x03\x02" +
		"\x02\x02\u0261\u0263\x05k6\x02\u0262\u0261\x03\x02\x02\x02\u0263\u0264" +
		"\x03\x02\x02\x02\u0264\u0262\x03\x02\x02\x02\u0264\u0265\x03\x02\x02\x02" +
		"\u0265\u026D\x03\x02\x02\x02\u0266\u0267\x07.\x02\x02\u0267\u0268\x05" +
		"k6\x02\u0268\u0269\x05k6\x02\u0269\u026A\x05k6\x02\u026A\u026C\x03\x02" +
		"\x02\x02\u026B\u0266\x03\x02\x02\x02\u026C\u026F\x03\x02\x02\x02\u026D" +
		"\u026B\x03\x02\x02\x02\u026D\u026E\x03\x02\x02\x02\u026E\u0271\x03\x02" +
		"\x02\x02\u026F\u026D\x03\x02\x02\x02\u0270\u0272\x05s:\x02\u0271\u0270" +
		"\x03\x02\x02\x02\u0271\u0272\x03\x02\x02\x02\u0272\u0288\x03\x02\x02\x02" +
		"\u0273\u0274\x05q9\x02\u0274\u0275\x07\'\x02\x02\u0275\u0288\x03\x02\x02" +
		"\x02\u0276\u0278\x05k6\x02\u0277\u0276\x03\x02\x02\x02\u0278\u0279\x03" +
		"\x02\x02\x02\u0279\u0277\x03\x02\x02\x02\u0279\u027A\x03\x02\x02\x02\u027A" +
		"\u0282\x03\x02\x02\x02\u027B\u027C\x07.\x02\x02\u027C\u027D\x05k6\x02" +
		"\u027D\u027E\x05k6\x02\u027E\u027F\x05k6\x02\u027F\u0281\x03\x02\x02\x02" +
		"\u0280\u027B\x03\x02\x02\x02\u0281\u0284\x03\x02\x02\x02\u0282\u0280\x03" +
		"\x02\x02\x02\u0282\u0283\x03\x02\x02\x02\u0283\u0285\x03\x02\x02\x02\u0284" +
		"\u0282\x03\x02\x02\x02\u0285\u0286\x07\'\x02\x02\u0286\u0288\x03\x02\x02" +
		"\x02\u0287\u0258\x03\x02\x02\x02\u0287\u025F\x03\x02\x02\x02\u0287\u0273" +
		"\x03\x02\x02\x02\u0287\u0277\x03\x02\x02\x02\u0288p\x03\x02\x02\x02\u0289" +
		"\u028B\x05k6\x02\u028A\u0289\x03\x02\x02\x02\u028B\u028C\x03\x02\x02\x02" +
		"\u028C\u028A\x03\x02\x02\x02\u028C\u028D\x03\x02\x02\x02\u028D\u0295\x03" +
		"\x02\x02\x02\u028E\u028F\x07.\x02\x02\u028F\u0290\x05k6\x02\u0290\u0291" +
		"\x05k6\x02\u0291\u0292\x05k6\x02\u0292\u0294\x03\x02\x02\x02\u0293\u028E" +
		"\x03\x02\x02\x02\u0294\u0297\x03\x02\x02\x02\u0295\u0293\x03\x02\x02\x02" +
		"\u0295\u0296\x03\x02\x02\x02\u0296\u0298\x03\x02\x02\x02\u0297\u0295\x03" +
		"\x02\x02\x02\u0298\u029C\x070\x02\x02\u0299\u029B\x05k6\x02\u029A\u0299" +
		"\x03\x02\x02\x02\u029B\u029E\x03\x02\x02\x02\u029C\u029A\x03\x02\x02\x02" +
		"\u029C\u029D\x03\x02\x02\x02\u029D\u02A6\x03\x02\x02\x02\u029E\u029C\x03" +
		"\x02\x02\x02\u029F\u02A1\x070\x02\x02\u02A0\u02A2\x05k6\x02\u02A1\u02A0" +
		"\x03\x02\x02\x02\u02A2\u02A3\x03\x02\x02\x02\u02A3\u02A1\x03\x02\x02\x02" +
		"\u02A3\u02A4\x03\x02\x02\x02\u02A4\u02A6\x03\x02\x02\x02\u02A5\u028A\x03" +
		"\x02\x02\x02\u02A5\u029F\x03\x02\x02\x02\u02A6r\x03\x02\x02\x02\u02A7" +
		"\u02A8\t\x05\x02\x02\u02A8t\x03\x02\x02\x02\u02A9\u02AD\x07)\x02\x02\u02AA" +
		"\u02AC\n\x06\x02\x02\u02AB\u02AA\x03\x02\x02\x02\u02AC\u02AF\x03\x02\x02" +
		"\x02\u02AD\u02AB\x03\x02\x02\x02\u02AD\u02AE\x03\x02\x02\x02\u02AE\u02B0" +
		"\x03\x02\x02\x02\u02AF\u02AD\x03\x02\x02\x02\u02B0\u02B1\x07)\x02\x02" +
		"\u02B1v\x03\x02\x02\x02\u02B2\u02B3\x07*\x02\x02\u02B3x\x03\x02\x02\x02" +
		"\u02B4\u02B5\x07+\x02\x02\u02B5z\x03\x02\x02\x02\u02B6\u02B7\x07-\x02" +
		"\x02\u02B7|\x03\x02\x02\x02\u02B8\u02B9\x07/\x02\x02\u02B9~\x03\x02\x02" +
		"\x02\u02BA\u02BB\x07,\x02\x02\u02BB\x80\x03\x02\x02\x02\u02BC\u02BD\x07" +
		"1\x02\x02\u02BD\x82\x03\x02\x02\x02\u02BE\u02BF\x07`\x02\x02\u02BF\x84" +
		"\x03\x02\x02\x02\u02C0\u02C1\t\x07\x02\x02\u02C1\u02C2\t\b\x02\x02\u02C2" +
		"\u02C3\t\t\x02\x02\u02C3\u02C4\t\t\x02\x02\u02C4\x86\x03\x02\x02\x02\u02C5" +
		"\u02C6\x07C\x02\x02\u02C6\u02C7\x07P\x02\x02\u02C7\u02C8\x07[\x02\x02" +
		"\u02C8\x88\x03\x02\x02\x02\u02C9\u02CA\x07E\x02\x02\u02CA\u02CB\x07C\x02" +
		"\x02\u02CB\u02CC\x07N\x02\x02\u02CC\u02CD\x07G\x02\x02\u02CD\u02CE\x07" +
		"P\x02\x02\u02CE\u02CF\x07F\x02\x02\u02CF\u02D0\x07C\x02\x02\u02D0\u02D1" +
		"\x07T\x02\x02\u02D1\x8A\x03\x02\x02\x02\u02D2\u02D3\x07T\x02\x02\u02D3" +
		"\u02D4\x07G\x02\x02\u02D4\u02D5\x07N\x02\x02\u02D5\u02D6\x07C\x02\x02" +
		"\u02D6\u02D7\x07V\x02\x02\u02D7\u02D8\x07K\x02\x02\u02D8\u02D9\x07X\x02" +
		"\x02\u02D9\u02DA\x07G\x02\x02\u02DA\x8C\x03\x02\x02\x02\u02DB\u02DC\x07" +
		"E\x02\x02\u02DC\u02DD\x07Q\x02\x02\u02DD\u02DE\x07J\x02\x02\u02DE\u02DF" +
		"\x07Q\x02\x02\u02DF\u02E0\x07T\x02\x02\u02E0\u02E1\x07V\x02\x02\u02E1" +
		"\u02E2\x07O\x02\x02\u02E2\u02E3\x07Q\x02\x02\u02E3\u02E4\x07P\x02\x02" +
		"\u02E4\u02E5\x07V\x02\x02\u02E5\u02E6\x07J\x02\x02\u02E6\x8E\x03\x02\x02" +
		"\x02\u02E7\u02E8\t\n\x02\x02\u02E8\u02E9\t\v\x02\x02\u02E9\u02EA\t\b\x02" +
		"\x02\u02EA\u02EB\t\x07\x02\x02\u02EB\u02EC\t\f\x02\x02\u02EC\x90\x03\x02" +
		"\x02\x02\u02ED\u02EE\t\n\x02\x02\u02EE\u02EF\t\v\x02\x02\u02EF\u02F0\t" +
		"\b\x02\x02\u02F0\u02F1\t\x07\x02\x02\u02F1\u02F2\t\f\x02\x02\u02F2\u02F3" +
		"\t\f\x02\x02\u02F3\u02F4\t\v\x02\x02\u02F4\u02F5\t\r\x02\x02\u02F5\u02F6" +
		"\t\x07\x02\x02\u02F6\x92\x03\x02\x02\x02\u02F7\u02F9\t\x0E\x02\x02\u02F8" +
		"\u02F7\x03\x02\x02\x02\u02F9\u02FA\x03\x02\x02\x02\u02FA\u02F8\x03\x02" +
		"\x02\x02\u02FA\u02FB\x03\x02\x02\x02\u02FB\u02FC\x03\x02\x02\x02\u02FC" +
		"\u02FD\bJ\x02\x02\u02FD\x94\x03\x02\x02\x02\u02FE\u02FF\x07?\x02\x02\u02FF" +
		"\u0308\x07?\x02\x02\u0300\u0301\x07#\x02\x02\u0301\u0308\x07?\x02\x02" +
		"\u0302\u0308\t\x0F\x02\x02\u0303\u0304\x07@\x02\x02\u0304\u0308\x07?\x02" +
		"\x02\u0305\u0306\x07>\x02\x02\u0306\u0308\x07?\x02\x02\u0307\u02FE\x03" +
		"\x02\x02\x02\u0307\u0300\x03\x02\x02\x02\u0307\u0302\x03\x02\x02\x02\u0307" +
		"\u0303\x03\x02\x02\x02\u0307\u0305\x03\x02\x02\x02\u0308\x96\x03\x02\x02" +
		"\x02\u0309\u030A\x07v\x02\x02\u030A\u030B\x07j\x02\x02\u030B\u030C\x07" +
		"k\x02\x02\u030C\u030D\x07u\x02\x02\u030D\x98\x03\x02\x02\x02\u030E\u030F" +
		"\x07n\x02\x02\u030F\u0310\x07k\x02\x02\u0310\u0311\x07p\x02\x02\u0311" +
		"\u0312\x07m\x02\x02\u0312\u0313\x07g\x02\x02\u0313\u0314\x07f\x02\x02" +
		"\u0314\x9A\x03\x02\x02\x02\u0315\u0318\x05m7\x02\u0316\u0318\t\x10\x02" +
		"\x02\u0317\u0315\x03\x02\x02\x02\u0317\u0316\x03\x02\x02\x02\u0318\x9C" +
		"\x03\x02\x02\x02\u0319\u031B\x05\x9BN\x02\u031A\u0319\x03\x02\x02\x02" +
		"\u031B\u031C\x03\x02\x02\x02\u031C\u031A\x03\x02\x02\x02\u031C\u031D\x03" +
		"\x02\x02\x02\u031D\x9E\x03\x02\x02\x02\x16\x02\u0258\u025C\u025F\u0264" +
		"\u026D\u0271\u0279\u0282\u0287\u028C\u0295\u029C\u02A3\u02A5\u02AD\u02FA" +
		"\u0307\u0317\u031C\x03\x02\x03\x02";
	public static readonly _serializedATN: string = Utils.join(
		[
			CalculatorLexer._serializedATNSegment0,
			CalculatorLexer._serializedATNSegment1,
		],
		"",
	);
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!CalculatorLexer.__ATN) {
			CalculatorLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(CalculatorLexer._serializedATN));
		}

		return CalculatorLexer.__ATN;
	}

}

