import { useCallback, useMemo, useRef, useState } from 'react';

import { AnchorItem, GetItemSize, ListType } from 'components/VirtualizedList/types';

interface Props {
  listType: ListType;
  numListItems: number;
  getItemSize: GetItemSize;
  overscanCount: number;
}

interface SlidingWindow {
  /**
   * The very first item in the visible range
   */
  firstVisibleAnchor: AnchorItem;
  /**
   * The last item in the visible range
   */
  lastVisibleAnchor: AnchorItem;
  /**
   * Index of the start of the sliding window. Typically this is the anchor item index minus the overscan count
   */
  windowStart: number;
  /**
   * Index of the end of the sliding window. Typically this is the last screen item index plus the overscan count
   */
  windowEnd: number;
}

export const useGetSlidingWindowInternal = ({
  listType,
  numListItems,
  getItemSize,
  overscanCount,
}: Props) => {
  //since scrollTop/scrollLeft is non-negative, let default value = -1 to trigger refresh on setting scroll top to zero for the first time
  const [scrollOffset, setScrollOffset] = useState<number>(-1); //this is to trigger a re-render when the scroll delta changes.
  const [boundaryOffset, setBoundaryOffset] = useState<number>(-1); //-1 means an indeterminate state i.e we don't know the initial offset top yet
  const scrollerTarget = useRef<HTMLElement | null>(null);
  const listRef = useRef<HTMLElement | null>(null);

  //If you scroll delta from an initial anchor, newAnchor =  initialAnchor.index + (the number of items that fit in delta)
  const calculateAnchorItem = useCallback(
    (initialAnchor: AnchorItem, delta: number) => {
      if (delta === 0) {
        return initialAnchor;
      }
      delta += initialAnchor.offset;
      let i = initialAnchor.index;
      let itemSize = getItemSize(i);
      while (delta > 0 && i < numListItems && itemSize < delta) {
        delta -= Math.max(0, itemSize);
        i++;
        itemSize = getItemSize(i);
      }
      return {
        index: Math.min(i, numListItems - 1), // possiblility to overshoot, so clamp to the last item
        offset: delta,
      };
    },
    [getItemSize, numListItems],
  );

  const getSlidingWindow = useCallback(
    (delta: number): SlidingWindow | undefined => {
      const maxVisibleIntersectingSpace = () => {
        const scroller = scrollerTarget.current;
        if (scroller == null) {
          return 0;
        }
        /**
         * In the case of vertical list,
         * if scroll top is non-negative but is less than initial offset top,
         * it means we have moved the list up a bit but haven't scrolled enough to have the list at the top of the scroller.
         * This means the list is partially visible in the scroller view area.
         * Note: if we scroll up, scrollTop increases and offsetTop decreases by scrollTop.
         * So newOffsetTop = (initialOffsetTop - scroller.scrollTop) represents the current offset top since the last scroll.
         * Partial visible height = scroller.offsetHeight - newOffsetTop
         *
         * The same logic applies for horizontal lists as well.
         */
        const scrollerOffset = listType === 'vertical' ? scroller.scrollTop : scroller.scrollLeft;
        const scrollerSize = listType === 'vertical' ? scroller.offsetHeight : scroller.offsetWidth;
        if (scrollerOffset <= boundaryOffset) {
          /**
           * In the case of vertical list,
           * if scroller.offsetHeight - (initialOffsetTop - scroller.scrollTop) is negative, it means the list is not visible in the scroller's view
           * because the list is visible if its offset top is less than the scroller's height so intersecting height is 0;
           * The same logic applies for horizontal lists as well.
           */
          return Math.max(0, scrollerSize - (boundaryOffset - scrollerOffset));
        } else {
          /**
           * In the case of vertical list,we have scrolled past the initial offset top, we are the top and going and the scroller height is the max intersecting height
           * The same logic applies for horizontal lists as well.
           */
          return scrollerSize;
        }
      };

      const defaultAnchor = { index: 0, offset: 0 };
      const firstVisibleAnchor = calculateAnchorItem(defaultAnchor, delta);
      const lastVisibleAnchor = calculateAnchorItem(
        firstVisibleAnchor,
        maxVisibleIntersectingSpace(),
      );
      const extendByOverscan = (start: number, end: number) => {
        const itemIndexAtWindowStart = Math.max(0, start - overscanCount);
        const itemIndexAtWindowEnd = Math.min(numListItems - 1, end + overscanCount);
        return { itemIndexAtWindowStart, itemIndexAtWindowEnd };
      };
      let windowStart = 0;
      let windowEnd = 0;
      const { itemIndexAtWindowStart, itemIndexAtWindowEnd } = extendByOverscan(
        firstVisibleAnchor.index,
        lastVisibleAnchor.index,
      );
      windowStart = itemIndexAtWindowStart;
      windowEnd = itemIndexAtWindowEnd;
      return { firstVisibleAnchor, lastVisibleAnchor, windowStart, windowEnd };
    },
    [boundaryOffset, calculateAnchorItem, listType, numListItems, overscanCount],
  );

  const updateScrollOffset = useCallback((offset: number) => {
    setScrollOffset(offset);
  }, []);

  const setListOffsetFromBoundaryForSlidingWindowHook = useCallback((offset: number) => {
    setBoundaryOffset(offset);
  }, []);

  const slidingWindow = useMemo(() => {
    if (boundaryOffset === -1) {
      return undefined;
    }
    //For eg. a vertical list, 1000px from the top of the scrollable container, only has a scroll delta for anchor calculations only if we have scrolled at least 1000px. True for horizontal lists as well.
    const delta = Math.max(0, scrollOffset - boundaryOffset);
    return getSlidingWindow(delta);
  }, [boundaryOffset, getSlidingWindow, scrollOffset]);

  const spaceFillerSize = useMemo(() => {
    if (slidingWindow == null) {
      return 0;
    }
    let height = 0;
    for (let index = 0; index < slidingWindow.windowStart; index++) {
      height += getItemSize(index);
    }
    return height;
  }, [getItemSize, slidingWindow]);

  const setRefs = useCallback(
    ({
      scrollElement,
      listElement,
    }: {
      scrollElement: HTMLElement;
      listElement: HTMLDivElement;
    }) => {
      scrollerTarget.current = scrollElement;
      listRef.current = listElement;
    },
    [],
  );

  return useMemo(
    () => ({
      slidingWindow,
      spaceFillerSize,
      updateScrollOffset,
      setRefs,
      setListOffsetFromBoundaryForSlidingWindowHook,
    }),
    [
      setListOffsetFromBoundaryForSlidingWindowHook,
      setRefs,
      slidingWindow,
      spaceFillerSize,
      updateScrollOffset,
    ],
  );
};
