import React from 'react';

import { AnonymizedObjectTimeSeriesCell } from 'components/BusinessObjectTable/AnonymizedObjectFieldCell';
import TableCell from 'components/Table/TableCell';
import AttributeTimeSeriesCell from 'components/TimeSeriesCell/AttributeTimeSeriesCell';
import NumericTimeSeriesCell from 'components/TimeSeriesCell/NumericTimeSeriesCell';
import TimestampTimeSeriesCell from 'components/TimeSeriesCell/TimestampTimeSeriesCell';
import { CELL_DATA_COLUMN_WIDTH_IN_PX } from 'config/cells';
import { DriverFormat, ValueType } from 'generated/graphql';
import { isStickyColumnKey } from 'helpers/cells';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellRef } from 'hooks/useCellRefContext';
import { useDragToSelectCells } from 'hooks/useDragToSelectCells';
import { useOpenForecastEditPopover } from 'reduxStore/actions/forecastEditPopover';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { Value } from 'reduxStore/models/value';
import { isBusinessObjectFieldSpecRestrictedSelector } from 'selectors/businessObjectFieldSpecRestrictedSelector';
import {
  businessObjectFieldSpecTypeSelector,
  dimensionIdForBusinessObjectFieldSpecSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { fieldSpecDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

interface Props {
  fieldSpecId: BusinessObjectFieldSpecId;
  value: Value | undefined;
  onSave?: (value: Value | undefined, formatUpdate: DriverFormat | undefined) => void;
  onContextMenu?: React.MouseEventHandler;
  isActuals: boolean;
  showComparisonHighlight: boolean;
}

const BusinessObjectFieldCell: React.FC<Props> = ({
  fieldSpecId,
  value,
  onSave,
  onContextMenu,
  isActuals,
  showComparisonHighlight,
}) => {
  const { blockId } = useBlockContext();
  const { cellRef } = useCellRef();
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const isComparison = comparisonLayerIds.length > 1;

  const fieldSpecType = useAppSelector((state) =>
    businessObjectFieldSpecTypeSelector(state, { id: fieldSpecId }),
  );
  const dimensionId = useAppSelector((state) =>
    dimensionIdForBusinessObjectFieldSpecSelector(state, { id: fieldSpecId }),
  );
  const displayConfiguration = useAppSelector((state) =>
    fieldSpecDisplayConfigurationSelector(state, fieldSpecId),
  );
  const startEditingForecastValue = useOpenForecastEditPopover();

  const width = useAppSelector((state) =>
    isStickyColumnKey(cellRef.columnKey)
      ? columnWidthSelector(state, { columnType: cellRef.columnKey.columnType, blockId })
      : CELL_DATA_COLUMN_WIDTH_IN_PX,
  );

  const onSaveCell = isComparison ? undefined : onSave;
  const { onMouseDown, onMouseEnter, onMouseLeave } = useDragToSelectCells(cellRef, blockId);
  const color = isActuals ? 'actuals' : 'forecast';

  const isRestrictedData = useAppSelector((state) =>
    isBusinessObjectFieldSpecRestrictedSelector(state, {
      fieldSpecId,
      blockId,
    }),
  );

  if (isRestrictedData) {
    return (
      <AnonymizedObjectTimeSeriesCell
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    );
  }

  switch (fieldSpecType) {
    case ValueType.Number: {
      return (
        <NumericTimeSeriesCell
          color={color}
          displayConfiguration={displayConfiguration}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onContextMenu={onContextMenu}
          onSave={onSaveCell}
          showComparisonHighlight={showComparisonHighlight}
          value={value?.type === ValueType.Number ? value.value : undefined}
          onStartEditing={isActuals ? undefined : startEditingForecastValue}
          width={width}
          isForecast={!isActuals}
        />
      );
    }
    case ValueType.Timestamp: {
      return (
        <TimestampTimeSeriesCell
          color={color}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onContextMenu={onContextMenu}
          onSave={onSaveCell}
          showComparisonHighlight={showComparisonHighlight}
          value={value?.type === ValueType.Timestamp ? value.value : undefined}
          width={width}
        />
      );
    }
    case ValueType.Attribute: {
      return (
        <AttributeTimeSeriesCell
          color={color}
          dimensionId={dimensionId}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onContextMenu={onContextMenu}
          showComparisonHighlight={showComparisonHighlight}
          onSave={onSaveCell}
          value={value?.type === ValueType.Attribute ? value.value : undefined}
          width={width}
        />
      );
    }
    default: {
      // empty non-editable cell
      return <TableCell width={width} />;
    }
  }
};

BusinessObjectFieldCell.displayName = 'BusinessObjectFieldCell';

export default React.memo(BusinessObjectFieldCell);
