import React from 'react';

import SettingsPopoverContentBaseComponent from 'components/BusinessObjectTable/SettingsPopoverContents/SettingsPopoverContentBaseComponent';
import { useDatabaseNameContextMenuItems } from 'components/BusinessObjectTable/SettingsPopoverContents/useContextMenuItems';
import { Section } from 'components/SelectMenu/SelectMenu';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';

const SECTIONS: Section[] = [];

interface Props {
  specId: BusinessObjectSpecId;
  onClose?: () => void;
  isOpen?: boolean;
}

const DatabaseNameSettingsPopoverContent = React.forwardRef<HTMLDivElement, Props>(
  ({ specId, onClose, isOpen }, ref) => {
    const items = useDatabaseNameContextMenuItems({ objectSpecId: specId });

    return (
      <SettingsPopoverContentBaseComponent
        ref={ref}
        isAutoFocused={false}
        isOpen={isOpen}
        items={items}
        onClose={onClose}
        sections={SECTIONS}
      />
    );
  },
);

export default React.memo(DatabaseNameSettingsPopoverContent);
