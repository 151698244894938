import { createSelector } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { ExtSource } from 'helpers/integrations';
import {
  addLayerParams,
  getCacheKeyForLayerSelector,
  SelectorWithLayerParam,
} from 'helpers/layerSelectorFactory';
import { isNotNull } from 'helpers/typescript';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { EntityTable } from 'reduxStore/models/common';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { paramSelector } from 'selectors/constSelectors';
import { navDataSourceSelector } from 'selectors/dataSourceSelector';
import { extObjectSpecsByKeySelector } from 'selectors/extObjectSpecsSelector';
import { getGivenOrCurrentLayerId, layersSelector } from 'selectors/layerSelector';
import { ParametricSelector, Selector } from 'types/redux';

const EMPTY_ENTITY_TABLE: EntityTable<BusinessObjectSpec> = { byId: {}, allIds: [] };

const businessObjectSpecsTableForLayerSelector: SelectorWithLayerParam<
  EntityTable<BusinessObjectSpec>
> = createCachedSelector(layersSelector, getGivenOrCurrentLayerId, (layers, layerId) => {
  const layer = layers[layerId];
  if (layer == null) {
    return EMPTY_ENTITY_TABLE;
  }

  return layer.businessObjectSpecs;
})(getCacheKeyForLayerSelector);

export const businessObjectSpecIdsForLayerSelector: SelectorWithLayerParam<BusinessObjectSpecId[]> =
  createCachedSelector(addLayerParams(businessObjectSpecsTableForLayerSelector), (objectSpecs) => {
    return objectSpecs.allIds;
  })(getCacheKeyForLayerSelector);

export const businessObjectSpecsForLayerSelector: SelectorWithLayerParam<BusinessObjectSpec[]> =
  createCachedSelector(addLayerParams(businessObjectSpecsTableForLayerSelector), (objectSpecs) => {
    return objectSpecs.allIds.map((id) => objectSpecs.byId[id]);
  })(getCacheKeyForLayerSelector);

export const businessObjectSpecNamesForLayerSelector: SelectorWithLayerParam<string[]> =
  createCachedSelector(businessObjectSpecsForLayerSelector, (objectSpecs) => {
    return objectSpecs.map((spec) => spec.name);
  })({
    keySelector: getCacheKeyForLayerSelector,
    selectorCreator: createDeepEqualSelector,
  });

export const businessObjectSpecsByIdForLayerSelector: SelectorWithLayerParam<
  Record<string, BusinessObjectSpec>
> = createCachedSelector(
  addLayerParams(businessObjectSpecsTableForLayerSelector),
  (objectSpecs) => {
    return objectSpecs.byId;
  },
)(getCacheKeyForLayerSelector);

export const businessObjectSpecsByFieldSpecIdForLayerSelector: SelectorWithLayerParam<
  Record<BusinessObjectFieldSpecId, BusinessObjectSpec>
> = createCachedSelector(businessObjectSpecsByIdForLayerSelector, (specsById) => {
  const allSpecs = Object.values(specsById);

  const specsByFieldSpecId: Record<BusinessObjectFieldSpecId, BusinessObjectSpec> = {};
  allSpecs.forEach((spec) => {
    spec.fields.forEach((fieldSpec) => {
      specsByFieldSpecId[fieldSpec.id] = spec;
    });
  });

  return specsByFieldSpecId;
})(getCacheKeyForLayerSelector);

// Helper for getting the id prop
const getBusinessObjectSpecId: ParametricSelector<BusinessObjectSpecId, BusinessObjectSpecId> = (
  _state,
  objectSpecId: BusinessObjectSpecId,
) => objectSpecId;

export const businessObjectSpecSelector: ParametricSelector<
  BusinessObjectSpecId,
  BusinessObjectSpec | undefined
> = createCachedSelector(
  (state) => businessObjectSpecsByIdForLayerSelector(state),
  getBusinessObjectSpecId,
  (specsById, objectSpecId) => {
    return specsById[objectSpecId];
  },
)((_state, objectSpecId) => objectSpecId);

type P = {
  specId: string;
  layerId?: string;
};
export const businessObjectSpecForLayerSelector: ParametricSelector<
  P,
  BusinessObjectSpec | undefined
> = createCachedSelector(
  paramSelector<P>(),
  businessObjectSpecsByIdForLayerSelector,
  ({ specId }, specsById) => {
    return specsById[specId];
  },
)(getCacheKeyForLayerSelector);

export const businessObjectSpecNamesByIdSelector: Selector<Record<BusinessObjectSpecId, string>> =
  createSelector(businessObjectSpecsForLayerSelector, (specs) => {
    const namesById: Record<BusinessObjectSpecId, string> = {};
    specs.forEach((spec) => {
      namesById[spec.id] = spec.name;
    });
    return namesById;
  });

export const businessObjectSpecNameSelector: ParametricSelector<
  BusinessObjectSpecId,
  string | undefined
> = createCachedSelector(
  businessObjectSpecSelector,
  (spec) => spec?.name,
)((_state, objectSpecId) => objectSpecId);

export const businessObjectSpecStartFieldIdSelector: ParametricSelector<
  BusinessObjectSpecId,
  string | undefined
> = createCachedSelector(
  businessObjectSpecSelector,
  (spec) => spec?.startFieldId,
)((_state, objectSpecId) => objectSpecId);

export const businessObjectSpecsByExtSourceSelector: Selector<
  Partial<Record<ExtSource, BusinessObjectSpec[]>>
> = createSelector(
  businessObjectSpecsForLayerSelector,
  extObjectSpecsByKeySelector,
  (allSpecs, allExtSpecs) => {
    return allSpecs.reduce(
      (agg, spec) => {
        if (spec.extSpecKeys != null) {
          spec.extSpecKeys.forEach((key) => {
            const extSpec = allExtSpecs[key];
            if (extSpec == null || extSpec.source == null) {
              return;
            }
            if (agg[extSpec.source] == null) {
              agg[extSpec.source] = [spec];
            } else {
              agg[extSpec.source].push(spec);
            }
          });
        }
        return agg;
      },
      {} as Record<ExtSource, BusinessObjectSpec[]>,
    );
  },
);

export const extSourcesForBusinessObjectSpecSelector: ParametricSelector<
  BusinessObjectSpecId,
  ExtSource[]
> = createCachedSelector(
  businessObjectSpecSelector,
  extObjectSpecsByKeySelector,
  (spec, extSpecsByKey) => {
    if (spec?.extSpecKeys != null) {
      return spec.extSpecKeys.map((key) => extSpecsByKey[key]?.source).filter(isNotNull);
    }
    if (spec?.extSource != null) {
      // TODO: we should deprecate this entirely once all orgs have resynced
      return [spec?.extSource];
    }
    return [];
  },
)((_state, objectSpecId) => objectSpecId);

export const businessObjectSpecsForNavExtSourceSelector: Selector<BusinessObjectSpec[]> =
  createSelector(
    businessObjectSpecsByExtSourceSelector,
    navDataSourceSelector,
    (specsBySource, navSource) => (navSource != null ? (specsBySource[navSource] ?? []) : []),
  );

export const businessObjectSpecForDimensionalDriverId = createCachedSelector(
  businessObjectSpecsByIdForLayerSelector,
  (_: RootState, dimensionalDriverId: string) => dimensionalDriverId,
  (allObjectSpecs, dimensionalDriverId) => {
    const matchingSpec = Object.values(allObjectSpecs).find((spec) =>
      spec.collection?.driverProperties?.some((driver) => driver.driverId === dimensionalDriverId),
    );

    return matchingSpec;
  },
)((_state, dimensionalDriverId: string) => dimensionalDriverId);
