import { createCachedSelector } from 're-reselect';

import { DEFAULT_DRIVER_CHART_SIZE, DEFAULT_DRIVER_VIEW_AS_TYPE } from 'config/block';
import { BlockViewAsType, ChartSize } from 'generated/graphql';
import { BlockId } from 'reduxStore/models/blocks';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { ParametricSelector } from 'types/redux';

export const DRIVER_CHART_CARD_DIMENSIONS: Record<ChartSize, { width: number; height: number }> = {
  [ChartSize.Medium]: { width: 360, height: 198 },
  [ChartSize.Large]: { width: 720, height: 274 },
  [ChartSize.ExtraLarge]: { width: 1100, height: 375 },
};

export const driverChartCardDimensionsSelector: ParametricSelector<
  BlockId,
  { width: number; height: number }
> = createCachedSelector(blockConfigViewOptionsSelector, (viewOptions) => {
  const viewAs = viewOptions?.viewAsType ?? DEFAULT_DRIVER_VIEW_AS_TYPE;

  // Only show medium size for the "current value" views
  const chartSize =
    viewAs === BlockViewAsType.CurrentValue
      ? ChartSize.Medium
      : (viewOptions?.chartSize ?? DEFAULT_DRIVER_CHART_SIZE);

  return DRIVER_CHART_CARD_DIMENSIONS[chartSize] ?? DRIVER_CHART_CARD_DIMENSIONS[ChartSize.Medium];
})(blockIdSelector);
