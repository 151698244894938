import { Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';

import { DatabaseColumnComparisons } from 'components/CompareScenariosModalContent/DatabaseColumnComparisons';
import DatabaseComparisonGrid from 'components/CompareScenariosModalContent/DatabaseComparisonGrid';
import { extractEmoji } from 'helpers/emoji';
import useAppSelector from 'hooks/useAppSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import {
  identicalComparedDatabasesSelector,
  objectSpecIdsToCompareSelector,
  scenarioComparisonBaselineLayerIdSelector,
  scenarioComparisonMaxObjectDiffsReachedSelector,
} from 'selectors/scenarioComparisonSelector';

const DatabaseComparisons: React.FC = () => {
  const baselineLayerId = useAppSelector(scenarioComparisonBaselineLayerIdSelector);
  const areDatabasesIdentical = useAppSelector(identicalComparedDatabasesSelector);
  const objectSpecIds = useAppSelector(objectSpecIdsToCompareSelector);

  const objectSpecById = useAppSelector((state) =>
    businessObjectSpecsByIdForLayerSelector(state, { layerId: baselineLayerId }),
  );

  const tooLargeToDisplay = useAppSelector(scenarioComparisonMaxObjectDiffsReachedSelector);

  if (tooLargeToDisplay) {
    return (
      <Text fontSize="xs" color="gray.500">
        This merge would impact more database objects than can be displayed in this modal.
      </Text>
    );
  }

  return (
    <Flex flexDirection="column" position="relative" overflowX="auto" overflowY="hidden" rowGap={4}>
      <Heading fontSize="lg" position="sticky" left={0} mb={2}>
        Databases
      </Heading>
      {areDatabasesIdentical ? (
        <Text fontSize="xs" color="gray.500">
          No difference in databases.
        </Text>
      ) : (
        objectSpecIds.map((objectSpecId) => {
          const objectSpec = objectSpecById[objectSpecId];
          if (objectSpec == null) {
            return null;
          }
          const [emoji, name] = extractEmoji(objectSpec.name);
          return (
            <Flex key={objectSpecId} flexDirection="column" gap={4}>
              <Flex fontSize="sm" fontWeight="bold" columnGap={1} position="sticky" left={0}>
                {emoji != null && <Text>{emoji}</Text>}
                <Text>{name}</Text>
              </Flex>
              <Flex flexDirection="column" position="relative" overflowX="auto">
                <DatabaseComparisonGrid key={objectSpecId} objectSpecId={objectSpecId} />
              </Flex>
              <DatabaseColumnComparisons objectSpecId={objectSpecId} />
            </Flex>
          );
        })
      )}
    </Flex>
  );
};

export default DatabaseComparisons;
