import { BoxProps, Flex, LayoutProps, Text } from '@chakra-ui/react';
import isString from 'lodash/isString';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import { BadgeTheme, SIZE_TO_PROPS, ThemeColors, THEMES_TO_COLORS } from 'config/badge';

interface Props {
  text: React.ReactNode;
  theme: BadgeTheme | ThemeColors;
  fontStyle?: 'normal' | 'italic';
  fontSize?: string;
  cursor?: 'pointer';
  borderRadius?: 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  size?: 'sm' | 'xs' | 'md';
  leftMeta?: React.ReactNode;
  rightMeta?: React.ReactNode;
  onClick?: () => void;
  clippingIndex?: number;
  tooltipMsg?: string;
  minWidth?: LayoutProps['minWidth'];
  textColor?: BoxProps['color'];
}

const Badge: React.FC<Props> = ({
  text,
  theme,
  size = 'sm',
  leftMeta,
  rightMeta,
  fontStyle,
  fontSize,
  cursor,
  borderRadius = 'xl',
  onClick,
  tooltipMsg,
  minWidth = '3rem',
  textColor,
}) => {
  const { _hover, ...restTheme } = typeof theme === 'string' ? THEMES_TO_COLORS[theme] : theme;
  const { px, ...restSize } = SIZE_TO_PROPS[size];

  return (
    <Tooltip isDisabled={tooltipMsg == null} label={tooltipMsg} placement="top">
      <Flex
        {...restSize}
        {...restTheme}
        minWidth={minWidth}
        cursor={cursor}
        display="inline-flex"
        pl={px}
        pr={rightMeta == null ? px : 0}
        fontStyle={fontStyle}
        fontSize={fontSize ?? restSize.fontSize}
        fontWeight="medium"
        borderRadius={borderRadius}
        maxWidth="full"
        _hover={
          onClick != null
            ? {
                backgroundColor: _hover?.bgColor ?? restTheme.bgColor,
                color: _hover?.color ?? restTheme.color,
              }
            : undefined
        }
        columnGap={1}
        alignItems="center"
        whiteSpace="nowrap"
        py={0}
        onClick={onClick}
        _last={{ marginRight: 0 }}
        justify="center"
      >
        {leftMeta}
        {isString(text) ? (
          <Text
            as="span"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            color={textColor}
          >
            {text}
          </Text>
        ) : (
          text
        )}
        {rightMeta}
      </Flex>
    </Tooltip>
  );
};

export default Badge;
