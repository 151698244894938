import { useClient } from 'urql';

import {
  PublishScenarioDocument,
  PublishScenarioMutation,
  UnpublishScenarioDocument,
  UnpublishScenarioMutation,
} from 'generated/graphql';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { LayerId } from 'reduxStore/models/layers';
import {
  addAccessResourceLayerLocally,
  addTemporaryAccessResourceLayerLocally,
  removeAccessResourceLayerLocally,
  updateAccessResourceLayerLocally,
} from 'reduxStore/reducers/sharingSlice';
import { publishedLayersSelector } from 'selectors/layersAccessResourcesSelector';
import { requireSelectedOrgSelector } from 'selectors/selectedOrgSelector';

export default function usePublishScenario(layerId: LayerId) {
  const dispatch = useAppDispatch();
  const selectedOrg = useAppSelector(requireSelectedOrgSelector);
  const publishedLayers = useAppSelector(publishedLayersSelector);

  const urql = useClient();

  return {
    publishScenario: async () => {
      dispatch(addTemporaryAccessResourceLayerLocally({ layerId, orgId: selectedOrg.id }));

      return urql
        .mutation<PublishScenarioMutation>(PublishScenarioDocument, {
          orgId: selectedOrg.id,
          layerId,
        })
        .toPromise()
        .then((res) => {
          if (res.data?.publishScenario == null || res.data?.publishScenario.length === 0) {
            dispatch(removeAccessResourceLayerLocally({ layerId }));
          } else {
            dispatch(
              updateAccessResourceLayerLocally({
                layerId,
                accessResource: res.data.publishScenario[0],
              }),
            );
          }
        })
        .catch(() => {
          // Do not log to Sentry.
          // Call GraphQL errors are automatically logged via handleUrqlError.
          dispatch(removeAccessResourceLayerLocally({ layerId }));
        });
    },
    unpublishScenario: async () => {
      const layerToBeDeleted = publishedLayers[layerId][0];
      dispatch(removeAccessResourceLayerLocally({ layerId }));

      const mutation = urql.mutation<UnpublishScenarioMutation>(UnpublishScenarioDocument, {
        orgId: selectedOrg.id,
        layerId,
      });

      return mutation.toPromise().then((res) => {
        if (!res.data?.unpublishScenario?.success) {
          dispatch(
            addAccessResourceLayerLocally({
              accessResource: layerToBeDeleted,
            }),
          );
        }
        // Do not log to Sentry.
        // Call GraphQL errors are automatically logged via handleUrqlError.
      });
    },
  };
}
