import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AppError = string | null;

export type ErrorState = {
  errorMessage: AppError;
  showModal: boolean;
};

const initialState: ErrorState = {
  errorMessage: null as AppError,
  showModal: false,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showErrorModal(state, action: PayloadAction<string>) {
      return { errorMessage: action.payload, showModal: true };
    },
    clearError: () => {
      return { ...initialState };
    },
  },
});

export const { showErrorModal, clearError } = errorSlice.actions;

export default errorSlice.reducer;
