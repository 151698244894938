import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'ARRChartIcon',
  viewBox: '0 0 344 176',
  defaultProps: {
    fill: 'none',
    width: '344px',
    height: '176px',
  },
  path: (
    <>
      <path
        d="M5.97869 17L6.91335 14.3054H10.8906L11.8203 17H13.451L9.78693 6.81818H8.01207L4.34801 17H5.97869ZM7.3608 13.0128L8.86222 8.66761H8.94176L10.4432 13.0128H7.3608ZM14.9213 17H16.4576V13.147H18.5456C18.5804 13.147 18.6103 13.147 18.6451 13.147L20.7132 17H22.4632L20.2061 12.8786C21.454 12.4162 22.0755 11.3771 22.0755 10.0099C22.0755 8.13565 20.9121 6.81818 18.5506 6.81818H14.9213V17ZM16.4576 11.8246V8.13565H18.3865C19.9029 8.13565 20.5293 8.85653 20.5293 10.0099C20.5293 11.1584 19.9029 11.8246 18.4064 11.8246H16.4576ZM23.9448 17H25.481V13.147H27.5691C27.6039 13.147 27.6337 13.147 27.6685 13.147L29.7367 17H31.4867L29.2296 12.8786C30.4775 12.4162 31.0989 11.3771 31.0989 10.0099C31.0989 8.13565 29.9355 6.81818 27.574 6.81818H23.9448V17ZM25.481 11.8246V8.13565H27.41C28.9263 8.13565 29.5527 8.85653 29.5527 10.0099C29.5527 11.1584 28.9263 11.8246 27.4299 11.8246H25.481Z"
        fill="#B9C0CD"
      />
      <path
        d="M143.5 139H163C339 139 339 89.2313 339 70"
        stroke="#B9C0CD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
      <path d="M4 139H152" stroke="#B9C0CD" strokeWidth="2" />
    </>
  ),
});
