import { BoxProps } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import DatabaseTableHeaderCell from 'components/BusinessObjectTable/DatabaseTableHeaderCell';
import DatabasePropertySettingsPopoverContent from 'components/BusinessObjectTable/SettingsPopoverContents/DatabasePropertySettingsPopoverContent';
import DatabasePropertyFormulaPopoverContents from 'components/DatabasePropertyFormulaPopoverContents/DatabasePropertyFormulaPopoverContents';
import InteractiveColumnHeaderCell from 'components/InteractiveColumnHeaderCell/InteractiveColumnHeaderCell';
import { ReorderableProps } from 'components/Reorderable/Reorderable';
import { DatabaseGroupKey, OBJECT_BLOCK_COLUMN_DRAG_ITEM_TYPE } from 'config/businessObjects';
import { COLUMN_HEADER_CELL_HEIGHT_PX } from 'config/cells';
import { ValueType } from 'generated/graphql';
import { toPxString } from 'helpers/styles';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellRef } from 'hooks/useCellRefContext';
import { BaseDragItem } from 'hooks/useReorderable';
import { selectCellIfUnselected } from 'reduxStore/actions/cellSelection';
import { updateObjectTableColumnPosition } from 'reduxStore/actions/objectTableNavigation';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { clearEditingPropertyFormula } from 'reduxStore/reducers/blockLocalStateSlice';
import { isEditingPropertyFormulaForColumnSelector } from 'selectors/blockLocalStateSelector';
import { blockConfigObjectFieldSpecAsTimeSeriesIdSelector } from 'selectors/blocksSelector';
import { hasBusinessObjectFieldSpecRestrictsSelector } from 'selectors/businessObjectFieldSpecRestrictedSelector';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { objectTableBlockFieldSortOrderSelector } from 'selectors/objectTableBlockSelector';
import { isAutoFocusedDatabaseHeaderMenuSelector } from 'selectors/pageSelector';

type DragItem = BaseDragItem & {
  columnKey: BusinessObjectFieldSpecId;
};

interface Props {
  fieldSpecId: BusinessObjectFieldSpecId;
  isLast: boolean;
  groupKey: DatabaseGroupKey;
  canDrag?: boolean;
  fixedWidth?: BoxProps['width'];
  topSpacingPx?: number;
  orientation?: ReorderableProps<DragItem>['orientation'];
  objectId: BusinessObjectId | undefined;
}

const DatabaseFieldHeaderCell: React.FC<Props> = ({
  fieldSpecId,
  isLast,
  canDrag = true,
  fixedWidth,
  topSpacingPx,
  groupKey,
  orientation,
  objectId,
}) => {
  const dispatch = useAppDispatch();
  const { blockId, readOnly } = useBlockContext();

  const isAutoFocused = useAppSelector((state) =>
    isAutoFocusedDatabaseHeaderMenuSelector(state, { blockId, fieldSpecId, groupKey, objectId }),
  );

  const fieldSpec = useAppSelector((state) =>
    businessObjectFieldSpecSelector(state, { id: fieldSpecId }),
  );

  const sortOrder = useAppSelector((state) =>
    objectTableBlockFieldSortOrderSelector(state, blockId, fieldSpecId),
  );

  const onDrop = useCallback(
    (dropItem: DragItem, relativeTo: DragItem, position: 'before' | 'after') => {
      dispatch(
        updateObjectTableColumnPosition({
          blockId,
          fieldSpecId: dropItem.columnKey,
          insertBeforeId: position === 'before' ? relativeTo.columnKey : 'end',
        }),
      );
    },
    [blockId, dispatch],
  );

  const columnDragItem: DragItem = useMemo(
    () => ({ type: OBJECT_BLOCK_COLUMN_DRAG_ITEM_TYPE, columnKey: fieldSpecId }),
    [fieldSpecId],
  );

  const dimensionsById = useAppSelector(dimensionsByIdSelector);
  const errMsg = useMemo(() => {
    const fieldDimId = fieldSpec?.type === ValueType.Attribute ? fieldSpec.dimensionId : null;
    if (fieldDimId == null) {
      return null;
    }

    const dim = dimensionsById[fieldDimId];

    return dim == null || dim.deleted ? 'Dimension deleted' : null;
  }, [fieldSpec, dimensionsById]);

  const isEditingFormula = useAppSelector((state) =>
    isEditingPropertyFormulaForColumnSelector(state, {
      blockId,
      columnKey: fieldSpecId,
      groupKey,
      objectId,
    }),
  );

  const onClose = useCallback(() => {
    dispatch(clearEditingPropertyFormula());
  }, [dispatch]);

  const showingAsTimeSeries = useAppSelector((state) =>
    blockConfigObjectFieldSpecAsTimeSeriesIdSelector(state, blockId),
  );
  const isTimeSeriesField = showingAsTimeSeries === fieldSpecId;
  const hasTimeSeriesField = showingAsTimeSeries != null;
  const numRowsHeight = isTimeSeriesField || !hasTimeSeriesField ? 1 : 2;
  const height = numRowsHeight * COLUMN_HEADER_CELL_HEIGHT_PX;

  const { cellRef } = useCellRef();
  const selectCell = useCallback(() => {
    dispatch(selectCellIfUnselected(blockId, cellRef));
  }, [dispatch, cellRef, blockId]);

  const hasRestrictions = useAppSelector((state) =>
    hasBusinessObjectFieldSpecRestrictsSelector(state, fieldSpecId),
  );

  return (
    <InteractiveColumnHeaderCell
      orientation={orientation}
      item={columnDragItem}
      isAutoFocused={isAutoFocused || isEditingFormula}
      onDrop={onDrop}
      canDrag={canDrag}
      disabled={readOnly}
      onClose={onClose}
      isLast={isLast}
      popoverContent={
        isEditingFormula ? (
          <DatabasePropertyFormulaPopoverContents fieldSpecId={fieldSpecId} />
        ) : (
          <DatabasePropertySettingsPopoverContent
            isAutoFocused={isAutoFocused}
            fieldSpecId={fieldSpecId}
            groupKey={groupKey}
            objectId={objectId}
          />
        )
      }
    >
      <DatabaseTableHeaderCell
        columnKey={fieldSpecId}
        groupKey={groupKey}
        title={fieldSpec?.name ?? ''}
        fixedWidth={fixedWidth}
        topSpacingPx={topSpacingPx}
        errMsg={errMsg ?? undefined}
        height={toPxString(height)}
        objectId={objectId}
        onClick={selectCell}
        orientation={orientation ?? 'horizontal'}
        hasRestrictions={hasRestrictions}
        sortOrder={sortOrder}
      />
    </InteractiveColumnHeaderCell>
  );
};

export default DatabaseFieldHeaderCell;
