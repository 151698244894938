import { IDBPDatabase, IDBPTransaction, StoreNames } from 'idb';

import { Dataset } from 'generated/graphql';

type Version = number;

type Schema = {
  [objectStoreName: string]: {
    key: string;
    value: any;
  };
};

// Give object stores in the schema a normal name, but anticipate that each store will be prefixed with the org ID.
type PrefixSchema<TSchema extends Schema> = {
  [TObjectStoreName in Extract<
    keyof TSchema,
    string
  > as `${string}:${TObjectStoreName}`]: TSchema[TObjectStoreName];
};

export type LatestDatabase = IDBPDatabase<PrefixedLatestSchema>;

type AllSchemas = PrefixedSchemaVersions[keyof SchemaVersionsType]['schema'];
type CompatDatabase = IDBPDatabase<AllSchemas>;
type CompatTransaction<M extends IDBTransactionMode, T = AllSchemas> = IDBPTransaction<
  T,
  ArrayLike<StoreNames<T>>,
  M
>;

interface SchemaVersion {
  version: Version;
  before?: Version;
  schema: Schema;
  upgrade?: (
    orgId: string,
    db: CompatDatabase,
    txn: CompatTransaction<'versionchange'>,
    evt: IDBVersionChangeEvent,
    current: SchemaVersion,
    previous?: SchemaVersion,
  ) => void;
}

interface SchemaV1 extends SchemaVersion {
  version: 1;
  schema: {
    named_versions: {
      key: string;
      value: Dataset | null | undefined;
    };
  };
}

/**
 * You must treat every schema as immutable.
 * If you need to change the shape of any existing object store or add another or add an index -- do not modify this type.
 * You are required to create a new schema version entry and write an upgrade function to make the changes.
 * A transaction should be automatically created for you to perform the upgrade by the idb library.
 * You must incredement `latestVersion` below to see your changes reflected.
 */
const v1: SchemaV1 = {
  version: 1,
  schema: {
    named_versions: {
      key: '',
      value: null,
    },
  },
  // eslint-disable-next-line max-params
  upgrade(orgId, db, _txn, _evt, _current, _previous) {
    db.createObjectStore(`${orgId}:named_versions`);
  },
};

/**
 * Add your schemas here.
 */
const SchemaVersions = {
  1: v1,
} as const;

type SchemaVersionsType = typeof SchemaVersions;
type PrefixedSchemaVersions = {
  [TVersionNumber in keyof SchemaVersionsType]: {
    version: SchemaVersionsType[TVersionNumber]['version'];
    before?: SchemaVersionsType[TVersionNumber]['before'];
    schema: PrefixSchema<SchemaVersionsType[TVersionNumber]['schema']>;
    upgrade?: SchemaVersionsType[TVersionNumber]['upgrade'];
  };
};

export type SchemaVersionNumber = keyof SchemaVersionsType;

export const getSchemaVersion = <T extends SchemaVersionNumber>(
  version: T,
): SchemaVersionsType[T] => {
  return SchemaVersions[version];
};

// Increment when the schema is updated to a newer version.
export const LATEST_VERSION: SchemaVersionNumber = 1;

export type LatestSchema = SchemaVersionsType[typeof LATEST_VERSION]['schema'];
export type PrefixedLatestSchema = PrefixedSchemaVersions[typeof LATEST_VERSION]['schema'];
