import { ReactNode } from 'react';
import { createStateContext } from 'react-use';

type DriverDescriptionState = {
  name: ReactNode | undefined;
  description: ReactNode | undefined;
};

export const [useDriverDescriptionContext, DriverDescriptionContextProvider] =
  createStateContext<DriverDescriptionState>({ name: undefined, description: undefined });
