import { useMergeRefs } from '@chakra-ui/react';
import classNames from 'classnames';
import React from 'react';

import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import useScrollToCell from 'hooks/useScrollToCell';

import styles from './NameCellWrapper.module.scss';

interface Props {
  isSticky?: boolean;
  testId?: string;
  children?: React.ReactNode;
}

const NameCellWrapper = React.forwardRef<HTMLDivElement, Props>(
  ({ children, testId, isSticky = false }, passedRef) => {
    const { isSelected, isBackgroundSelected } = useCellSelectionStateContext();
    const elementRef = useScrollToCell(isSelected && !isBackgroundSelected);
    const merged = useMergeRefs(elementRef, passedRef);

    return (
      <div
        ref={merged}
        data-testid={testId}
        className={classNames(styles.nameCellWrapper, {
          [styles.isSticky]: isSticky,
        })}
      >
        {children}
      </div>
    );
  },
);

export default NameCellWrapper;
