import React, { useCallback, useMemo } from 'react';

import PlanMoreMenu from 'components/PlanPicker/PlanMoreMenu';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { tagCellSelectionWithEventGroup } from 'reduxStore/actions/eventGroupMutations';
import { EventGroupId } from 'reduxStore/models/events';
import {
  openCellPalettePopover,
  setCellPaletteState,
  setPlanPickerSelectedEventGroupId,
} from 'reduxStore/reducers/pageSlice';
import {
  planPickerSelectedEventGroupIdsSelector,
  sortedEventGroupsForCellSelectionSelector,
} from 'selectors/eventsAndGroupsSelector';
import { PlansIcon } from 'vectors';

import PlanPickerBase from './PlanPickerBase';

type Props = {
  isEditingActiveCell: boolean;
  className?: string;
};

const SingleImpactPlanPicker = React.forwardRef<HTMLDivElement, Props>(
  ({ isEditingActiveCell, className }, ref) => {
    const dispatch = useAppDispatch();
    const sortedEventGroups = useAppSelector(sortedEventGroupsForCellSelectionSelector);
    const planPickerSelectedEventGroupIds = useAppSelector(planPickerSelectedEventGroupIdsSelector);

    const onSelectEventGroup = useCallback(
      (eventGroupId: EventGroupId) => {
        if (isEditingActiveCell) {
          dispatch(setPlanPickerSelectedEventGroupId({ type: 'existing', eventGroupId }));
        } else {
          dispatch(tagCellSelectionWithEventGroup({ type: 'existing', eventGroupId }));
        }
      },
      [dispatch, isEditingActiveCell],
    );

    const onSelect = useCallback(
      ({ id }: SelectItem) => {
        onSelectEventGroup(id);
        dispatch(setCellPaletteState('default'));
      },
      [dispatch, onSelectEventGroup],
    );

    const onClose = useCallback(() => {
      dispatch(openCellPalettePopover());
    }, [dispatch]);

    const onSelectCreatePlan = useCallback(
      (newEventGroupName: string) => {
        const newEventGroup = {
          id: uuidv4(),
          name: newEventGroupName,
        };

        if (isEditingActiveCell) {
          dispatch(setPlanPickerSelectedEventGroupId({ type: 'new', newEventGroup }));
        } else {
          dispatch(tagCellSelectionWithEventGroup({ type: 'new', newEventGroup }));
        }

        dispatch(openCellPalettePopover());
      },
      [dispatch, isEditingActiveCell],
    );

    const items: SelectItem[] = useMemo(() => {
      return sortedEventGroups.map(({ id, name, isNew }) => ({
        id,
        name,
        sectionId: 'main',
        checkedStyle: 'check',
        isChecked: planPickerSelectedEventGroupIds.has(id),
        icon: <PlansIcon />,
        submenu: () => <PlanMoreMenu eventGroupId={id} />,
        submenuType: 'options',
        isDisabled: isNew,
        disabledTooltipLabel: isNew ? 'Plan will be created once you save' : undefined,
      }));
    }, [planPickerSelectedEventGroupIds, sortedEventGroups]);

    return (
      <PlanPickerBase
        ref={ref}
        className={className}
        onSelect={onSelect}
        onSelectCreatePlan={onSelectCreatePlan}
        onClose={onClose}
        items={items}
      />
    );
  },
);

export default React.memo(SingleImpactPlanPicker);
