import isEmpty from 'lodash/isEmpty';

import { ModalType } from 'reduxStore/reducers/pageSlice';
import { createRootReducers } from 'reduxStore/reducers/sliceReducers';

const globalKeypress = createRootReducers('globalKeypress', {
  escapeKeypress(state) {
    // N.B.; using selectors here would invalidate the selectors because the state object here is
    // an Immer Writeable Draft; for now, we accept some duplication of logic

    // the implementation of this should match the implemenation of `isModalOpenSelector`
    const isModalOpen = state.page.openModal != null;
    const isAlertDialogOpen = !isEmpty(state.alertDialog);
    const isPlanDetailPaneOpen =
      state.page.openModal === ModalType.DetailPane && state.detailPane?.type === 'plan';
    // the implementation of this should match the implemenation of `isPopoverOpenSelector`
    const isPopoverOpen = state.page.popover != null && !isPlanDetailPaneOpen;

    // N.B. Chakra stops propagation on escape keyboard events within modals;
    // see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/modal/src/use-modal.ts#L100
    // it shouldn't be possible to focus outside the modal, but this code is just defensive

    // close modals
    state.liveEdit = null;

    if (state.page.popover != null) {
      state.page.popover = null;
    } else {
      state.page.openModal = null;
      state.page.modalSelection = null;
      state.alertDialog = {};
    }

    state.page.copiedCells = null;

    state.customizeMenu = null;

    if (isModalOpen || isPopoverOpen || isAlertDialogOpen) {
      return;
    }

    // clear selection
    state.page.selection = null;
  },
});

export const { escapeKeypress } = globalKeypress.actions;

export default globalKeypress.reducer;
