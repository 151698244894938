import { DateTime } from 'luxon';

import {
  TimeSeriesPoint as TimeSeriesPointGQL,
  TimeSeriesPointInput,
  ValueType,
} from 'generated/graphql';
import { extractMonthKey, getDateTimeFromMonthKey } from 'helpers/dates';
import {
  NumericTimeSeries,
  ValueTimeSeries,
  ValueTimeSeriesWithEmpty,
  numericToValueTimeSeries,
} from 'reduxStore/models/timeSeries';
import { toValueType } from 'reduxStore/models/value';
import { MonthKey } from 'types/datetime';

export function convertNumericTimeSeries(
  timeSeries: TimeSeriesPointGQL[] | TimeSeriesPointInput[],
): NumericTimeSeries {
  const timeSeriesObject: NumericTimeSeries = {};
  timeSeries
    .filter((point) => point.value != null)
    .map(({ time, value }) => ({
      time: extractMonthKey(time),
      value: Number(value),
    }))
    .forEach(({ time, value }) => {
      timeSeriesObject[time] = value;
    });

  return timeSeriesObject;
}

export function convertTimeSeries(
  timeSeries: TimeSeriesPointGQL[] | TimeSeriesPointInput[],
  type: ValueType,
): ValueTimeSeries {
  const timeSeriesObject: ValueTimeSeries = Object.fromEntries(
    timeSeries
      .filter((point) => isDefinedTimeseriesPoint(point))
      .map(({ time, value }) => [extractMonthKey(time), toValueType(value ?? '', type)]),
  );
  return timeSeriesObject;
}

// This is used to migrate from all components using a strictly numeric time series
// to the more generic ValueTimeSeries. Once NumericTimeSeries is deprecated, we can
// remove this as well.
export function convertNumericTimeSeriesToGql(
  timeSeries: NumericTimeSeries | undefined,
  transformDate?: (dt: DateTime) => DateTime,
): TimeSeriesPointInput[] | undefined {
  if (timeSeries == null) {
    return undefined;
  }
  return convertTimeSeriesToGql(numericToValueTimeSeries(timeSeries), transformDate);
}

export function convertTimeSeriesToGql(
  timeSeries: ValueTimeSeriesWithEmpty | undefined,
  transformDate?: (dt: DateTime) => DateTime,
): TimeSeriesPointInput[] | undefined {
  if (timeSeries == null) {
    return undefined;
  }
  const timeSeriesArray: TimeSeriesPointInput[] = [];
  for (const monthKey in timeSeries) {
    const dateTime = getDateTimeFromMonthKey(monthKey);
    const val = timeSeries[monthKey];
    timeSeriesArray.push({
      time: transformDate ? transformDate(dateTime).toISO() : dateTime.toISO(),
      value: val != null ? String(val.value) : undefined,
    });
  }

  return timeSeriesArray;
}

export function getNumericMonthValue(timeSeries: ValueTimeSeries | undefined, monthKey: MonthKey) {
  if (timeSeries == null) {
    return 0;
  }
  const monthValue = timeSeries[monthKey];
  return monthValue?.type === ValueType.Number ? monthValue.value : 0;
}

export function isDefinedTimeseriesPoint(
  point: TimeSeriesPointInput,
): point is { time: string; value: string } {
  // At some point the string literal 'undefined' managed to get pushed into the data - scrub that.
  return (
    point.time != null && point.value != null && point.value !== '' && point.value !== 'undefined'
  );
}
