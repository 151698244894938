import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AttributeBadge from 'components/AttributeBadge/AttributeBadge';
import { EditDriverView } from 'components/DimensionalDriverMenu/useEditDriverView';
import { useSelectHint } from 'components/DriverBlockSelect/SelectHintProvider';
import EscapableInput from 'components/EscapableInput/EscapableInput';
import {
  DimDriverEditReactContext,
  filterOutPlaceholderAttributes,
} from 'config/dimDriverEditContext';
import { nullSafeEqual } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { EnumSetter } from 'hooks/useEnum';
import { DimensionId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { pseudoAttributeSelectorBySubDriverIdSelector } from 'selectors/driversSelector';

interface EditDriverInputProps {
  driverId: DriverId | undefined;
  onCancel: () => void;
  setSelectedDimensionId: (dimensionId: DimensionId) => void;
  view: EditDriverView | null;
  setView: EnumSetter<EditDriverView>;
  placeholder: string;
  inputRef: React.RefObject<HTMLInputElement>;
}

const EditDriverInput: React.FC<EditDriverInputProps> = ({
  driverId,
  onCancel,
  setSelectedDimensionId,
  view,
  setView,
  placeholder,
  inputRef,
}) => {
  const onSelectAttributeDimension = useCallback(
    (dimensionId: DimensionId) => {
      setView.dimensionalDriverMenu();
      setSelectedDimensionId(dimensionId);
    },
    [setView, setSelectedDimensionId],
  );
  const isNewDriver = driverId == null;

  const {
    attributes,
    removeAttr,
    subDriverName,
    targetDriver,
    originalDimDriver,
    originalDriverName,
  } = useContext(DimDriverEditReactContext);

  const emptyAttributes =
    useAppSelector((state) =>
      driverId != null ? pseudoAttributeSelectorBySubDriverIdSelector(state, driverId) : undefined,
    ) ?? [];

  // If the driver is being renamed but the target driver has not changed,
  // we need to allow the user to select a new target driver or create a standalone driver
  const isRenaming = originalDriverName !== subDriverName;
  const shouldAllowTargetDriverReselect =
    nullSafeEqual(originalDimDriver?.id, targetDriver?.id) && isRenaming;

  useEffect(() => {
    if (view !== 'driverSelectMenu' && shouldAllowTargetDriverReselect) {
      setView.driverSelectMenu();
    }
  }, [setView, shouldAllowTargetDriverReselect, view]);

  const onRenameInputClick = useCallback(() => {
    if (view === null) {
      setView.driverSelectMenu();
    }
  }, [setView, view]);

  const containerOnClick: React.MouseEventHandler<HTMLDivElement> = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const { hint } = useSelectHint();

  return (
    <Flex width="full" cursor="text" onClick={containerOnClick} overflowX="auto" overflowY="hidden">
      <DriverNameInput
        onCancel={onCancel}
        isNewDriver={isNewDriver}
        onClick={onRenameInputClick}
        placeholder={placeholder}
        inputRef={inputRef}
      />
      <Flex columnGap={1}>
        {filterOutPlaceholderAttributes(attributes).map((attr) => (
          <AttributeBadge
            key={attr.id}
            onRemove={() => removeAttr(attr)}
            onSelect={() => onSelectAttributeDimension(attr.dimensionId)}
            attribute={attr}
          />
        ))}
        {emptyAttributes.map((attr) => (
          <AttributeBadge
            key={attr.id}
            onSelect={() => onSelectAttributeDimension(attr.dimensionId)}
            attribute={attr}
          />
        ))}
      </Flex>
      <Box position="relative" width={0}>
        <Flex height="full" position="absolute" alignItems="center">
          <Text color="gray.500" fontSize="xs" whiteSpace="nowrap">
            {hint}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

interface DriverNameInputProps {
  onCancel: (reason?: 'escape' | 'noop') => void;
  onClick: () => void;
  isNewDriver: boolean;
  placeholder: string;
  inputRef: React.Ref<HTMLInputElement>;
}

const DriverNameInput: React.FC<DriverNameInputProps> = ({
  onCancel,
  onClick,
  isNewDriver,
  placeholder,
  inputRef,
}) => {
  const { setSubDriverName, subDriverName } = useContext(DimDriverEditReactContext);

  // setSubDriverName trims the input so we're keeping a local state to maintain trailing spaces the user types
  const [driverNameInput, setDriverNameInputLocal] = useState(subDriverName);

  const setDriverNameInput = useCallback(
    (value: string) => {
      setDriverNameInputLocal(value);
      setSubDriverName(value.trim());
    },
    [setSubDriverName],
  );

  useEffect(() => {
    if (subDriverName !== driverNameInput.trim()) {
      setDriverNameInputLocal(subDriverName);
    }
  }, [subDriverName, driverNameInput]);

  return (
    <EscapableInput
      ref={inputRef}
      data-testid={isNewDriver ? 'create-driver-input' : 'subdriver-driver-rename-input'}
      id={isNewDriver ? 'create-driver-input' : 'subdriver-driver-rename-input'}
      name="driverName"
      border="none"
      autoFocus
      borderRadius="md"
      value={driverNameInput}
      onChange={setDriverNameInput}
      onClick={onClick}
      fontSize="xs"
      fontWeight="medium"
      onCancel={onCancel}
      // Save on enter is handled in the sibling component: DimensionalDriverMenu
      saveOnEnter={false}
      // Since the input shape is weird, it's easy to accidentally blur when we don't want it saving
      // handle saving on blur in a parent component
      saveOnBlur={false}
      selectOnFocus={false}
      size="xs"
      variant="white"
      height="full"
      autoSizing
      overflow="hidden"
      p={0}
      placeholder={placeholder}
    />
  );
};

export default EditDriverInput;
