import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, IconButton } from '@chakra-ui/react';
import React from 'react';

interface Props {
  expanded: boolean;
  onToggle: () => void;
}

const ExpandControl: React.FC<Props> = ({ expanded, onToggle }) => {
  return (
    <Flex p={1} position="sticky" left={0} zIndex="docked">
      <IconButton
        icon={expanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
        color="gray.500"
        aria-label={expanded ? 'Click to collapse' : 'Click to expand'}
        variant="icon"
        boxSize={6}
        _hover={{ bgColor: 'gray.200' }}
        onClick={onToggle}
      />
    </Flex>
  );
};

export default ExpandControl;
