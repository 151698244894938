import noop from 'lodash/noop';
import { createContext } from 'react';

import {
  CustomMultiSelectOption,
  MultiSelectMenuItem,
} from 'components/SearchableMultiSelectMenu/SearchableMultiSelectMenu';

export type SearchableMultiSelectMenuContextState = {
  query: string;
  setQuery: (newQuery: string) => void;
  onKeyDown: (ev: KeyboardEvent | React.KeyboardEvent) => void;
  focusIdx: number;
  setFocusIdx: (value: number) => void;
  onSelectIdx: (idx: number) => void;
  results: MultiSelectMenuItem[];
  itemCount: number;
  customOption?: CustomMultiSelectOption;
};

export const SearchableMultiSelectMenuContext =
  createContext<SearchableMultiSelectMenuContextState>({
    query: '',
    setQuery: noop,
    onKeyDown: noop,
    focusIdx: 0,
    setFocusIdx: noop,
    onSelectIdx: noop,
    results: [],
    itemCount: 0,
  });
