import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';

import { getDateRangeFromRollupPeriod } from 'components/CustomizeDriverChartsBlock/CustomizeDriverChartsBlock';
import { DateRangeComparisonType } from 'generated/graphql';
import { getCurrentMonthKey, getMonthKey, getMonthKeyRange } from 'helpers/dates';
import { formatDriverValue } from 'helpers/formatting';
import { applyRollupReducer } from 'helpers/rollups';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { updateBlockConfig } from 'reduxStore/actions/blockMutations';
import { DriverFormat, DriverId } from 'reduxStore/models/drivers';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { entityLoadingAnyMonthInRangeSelector } from 'selectors/calculationsSelector';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { driverRollupReducerSelector } from 'selectors/rollupSelector';

function getPreviousPeriodDescription(type: string | null) {
  switch (type) {
    case 'CURRENT_MONTH':
      return 'previous month';
    case 'LAST_MONTH':
      return 'previous month';
    case 'CURRENT_QUARTER':
      return 'previous quarter';
    case 'LAST_QUARTER':
      return 'previous quarter';
    case 'CURRENT_YEAR':
      return 'previous year';
    case 'LAST_YEAR':
      return 'previous year';
    case 'CUSTOM_MONTH':
      return 'previous month';
    case 'LAST_CLOSE':
      return 'previous month';
    default:
      return '';
  }
}

export const useDriverValueChart = (driverId: DriverId) => {
  const { blockId } = useBlockContext();
  // const [blockStartDateTime, endDateTime] = useBlockDateRangeDateTime();
  const dispatch = useAppDispatch();

  const layerId = useAppSelector(currentLayerIdSelector);

  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const blockViewOptions = useAppSelector((state) =>
    blockConfigViewOptionsSelector(state, blockId),
  );

  const lastCloseMonthKey = useAppSelector(lastActualsMonthKeyForLayerSelector);
  const currMonthKey = getCurrentMonthKey();

  const selectedPeriod = blockViewOptions?.dateRangeComparison?.selectedPeriod;
  const comparisonPeriod = blockViewOptions?.dateRangeComparison?.comparisonPeriod;

  const timeSeriesLayer = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, {
      id: driverId,
      layerId,
      start: comparisonPeriod?.start ?? undefined,
      end: selectedPeriod?.end ?? undefined,
    }),
  );

  const type = blockViewOptions?.dateRangeComparison?.type ?? null;

  const requestDateRange: [DateTime, DateTime] = useMemo(() => {
    return comparisonPeriod?.start != null && selectedPeriod?.end != null
      ? [DateTime.fromISO(comparisonPeriod.start), DateTime.fromISO(selectedPeriod.end)]
      : dateRange;
  }, [comparisonPeriod?.start, dateRange, selectedPeriod?.end]);

  useRequestCellValue({
    id: driverId,
    type: 'driver',
    dateRange: requestDateRange,
  });

  const rollupReducer = useAppSelector((state) =>
    driverRollupReducerSelector(state, { id: driverId }),
  );
  const selectedPeriodMonthKeys = getMonthKeyRange(
    selectedPeriod?.start ?? '',
    selectedPeriod?.end ?? '',
  );

  const comparisonPeriodMonthKeys = getMonthKeyRange(
    comparisonPeriod?.start ?? '',
    comparisonPeriod?.end ?? '',
  );

  const driverValueLoading = useAppSelector((state) =>
    entityLoadingAnyMonthInRangeSelector(state, {
      id: driverId,
      monthKeys: [...comparisonPeriodMonthKeys, ...selectedPeriodMonthKeys],
    }),
  );

  const reducedCurrent = applyRollupReducer({
    monthKeys: selectedPeriodMonthKeys,
    values: timeSeriesLayer,
    reducer: rollupReducer,
  });
  const currentValue = reducedCurrent.value;

  const reducedPrevious = applyRollupReducer({
    monthKeys: comparisonPeriodMonthKeys,
    values: timeSeriesLayer,
    reducer: rollupReducer,
  });
  const previousValue = reducedPrevious.value;

  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );

  const currentFormattedValue =
    currentValue != null && !isNaN(currentValue) && isFinite(currentValue)
      ? formatDriverValue(currentValue, displayConfiguration)
      : `No data`;
  const previousFormattedValue =
    previousValue != null && !isNaN(previousValue) && isFinite(previousValue)
      ? formatDriverValue(previousValue, displayConfiguration)
      : 'No data for';

  const prevFormattedString = `${previousFormattedValue} ${getPreviousPeriodDescription(type)}`;
  const percentDiff =
    currentValue != null && previousValue != null
      ? (currentValue - previousValue) / previousValue
      : null;

  const absPercentDiff = percentDiff != null ? Math.abs(percentDiff) : undefined;
  const formattedPercentDiff =
    absPercentDiff != null
      ? formatDriverValue(
          absPercentDiff,
          // For % diff it doesn't make sense to preserve the decimal places of the driver
          { ...displayConfiguration, format: DriverFormat.Percentage, decimalPlaces: null },
          {
            // Only show decimal diffs if under 10%
            maxPrecision: absPercentDiff < 0.1 ? 2 : 0,
          },
        )
      : null;

  useEffect(
    // Need to validate if we need to update the block view period to match the rollup type
    function validateViewPeiod() {
      if (type == null || type === DateRangeComparisonType.CustomMonth) {
        return;
      }
      const expectedRollupPeriod = getDateRangeFromRollupPeriod({
        rollupType: type,
        lastCloseMonthKey,
      });

      const startPeriodMatchesExpected =
        selectedPeriod?.start === expectedRollupPeriod.currPeriod.start &&
        selectedPeriod?.end === expectedRollupPeriod.currPeriod.end;
      const endPeriodMatchesExpected =
        comparisonPeriod?.start === expectedRollupPeriod.prevPeriod.start &&
        comparisonPeriod?.end === expectedRollupPeriod.prevPeriod.end;
      const blockViewPeriodMatchesExpected = startPeriodMatchesExpected && endPeriodMatchesExpected;

      const startBlockMonthKey = getMonthKey(dateRange[0]);
      const endBlockMonthKey = getMonthKey(dateRange[1]);

      const blockRangeMatchesPeriod =
        startBlockMonthKey === selectedPeriod?.start && endBlockMonthKey === selectedPeriod?.end;

      if (blockViewPeriodMatchesExpected && blockRangeMatchesPeriod) {
        return;
      }

      const updatedDateRangeComparison = {
        type,
        selectedPeriod: expectedRollupPeriod.currPeriod,
        comparisonPeriod: expectedRollupPeriod.prevPeriod,
      };

      dispatch(
        updateBlockConfig({
          blockId,
          fn: (blockConfig) => {
            blockConfig.dateRange = updatedDateRangeComparison.selectedPeriod;
            blockConfig.blockViewOptions = {
              ...blockConfig.blockViewOptions,
              dateRangeComparison: updatedDateRangeComparison,
            };
          },
        }),
      );
    },
    [
      type,
      lastCloseMonthKey,
      selectedPeriod,
      dispatch,
      blockId,
      currMonthKey,
      comparisonPeriod?.start,
      comparisonPeriod?.end,
      comparisonPeriod,
      dateRange,
    ],
  );

  // When switching to Value chart for first time or from another chart type
  // need to update the block date range
  // useEffect(
  //   function validateBlockDateRange() {
  //     if (type !== DateRangeComparisonType.CurrentMonth) {
  //       return;
  //     }
  //     const startBlockMonthKey = getMonthKey(dateRange[0]);
  //     const endBlockMonthKey = getMonthKey(dateRange[1]);

  //     const blockRangeMatchesPeriod =
  //       startBlockMonthKey === selectedPeriod?.start && endBlockMonthKey === selectedPeriod?.end;
  //     if (!blockRangeMatchesPeriod) {
  //       dispatch(
  //         updateBlockConfig({
  //           blockId,
  //           fn: (blockConfig) => {
  //             blockConfig.dateRange = selectedPeriod;
  //           },
  //         }),
  //       );
  //     }
  //   },
  //   [type, dateRange, selectedPeriod, dispatch, blockId],
  // );

  return {
    driverValueLoading,
    currentFormattedValue,
    prevFormattedString,
    percentDiff,
    formattedPercentDiff,
  };
};
