import { Flex, Spinner } from '@chakra-ui/react';
import {
  AgCartesianChartOptions,
  AgHierarchyChartOptions,
  AgPolarChartOptions,
} from 'ag-charts-community';
import { AgCharts } from 'ag-charts-react';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import ErrorBoundary from 'components/ErrorBoundary';
import { toPxString } from 'helpers/styles';

interface Props {
  isLoading: boolean;
  options: AgCartesianChartOptions | AgPolarChartOptions | AgHierarchyChartOptions;
}

const AgChart: React.FC<Props> = ({ options, isLoading }) => {
  // AG Charts will often timeout rendering if it is trying to render while the chart is scrolled away.
  // Just prevent the library from initializing when the chart is not visible.
  const { ref, inView } = useInView();

  return (
    // AG Charts tends to throw mysterious errors when chart rerenders are slow.
    // Prevent those errors from crashing the page; they are not critical.
    <ErrorBoundary preventMonopoly>
      <Flex ref={ref}>
        {isLoading && (
          <Flex
            justify="center"
            align="center"
            w={toPxString(options.width!)}
            h={toPxString(options.height!)}
          >
            <Spinner />
          </Flex>
        )}
        {inView && !isLoading && <AgCharts options={options} />}
      </Flex>
    </ErrorBoundary>
  );
};

export default React.memo(AgChart);
