import React, { useCallback, useContext, useMemo } from 'react';

import {
  EditAttributesOption,
  RenameAllSubDriversOption,
  RenameSubDriverOption,
} from 'components/DimensionalDriverMenu/DimDriverSelectItemRenderComponents';
import { useDimDriverSelectItems } from 'components/DimensionalDriverMenu/useDimDriverSelectItems';
import { EditDriverView } from 'components/DimensionalDriverMenu/useEditDriverView';
import useOnTargetDriverSelected from 'components/DimensionalDriverMenu/useOnTargetDriverSelected';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import SelectMenu, { CustomOption, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuFooter from 'components/SelectMenu/SelectMenuFooter';
import { DimDriverEditReactContext } from 'config/dimDriverEditContext';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { EnumSetter } from 'hooks/useEnum';
import { DriverId } from 'reduxStore/models/drivers';
import { openRenameSubDriverModal } from 'reduxStore/reducers/pageSlice';
import {
  dimensionalDriversBySubDriverIdSelector,
  isDimensionalSubDriverSelector,
} from 'selectors/driversSelector';
import Dimension from 'vectors/Dimension';
import Pencil from 'vectors/Pencil';
import Plus from 'vectors/Plus';

const SEARCH_KEYS = ['name'];

interface DimDriverItem extends SelectItem {
  sectionId: 'dimDrivers';
  name: string;
  id: DriverId;
}

interface DriverSelectMenuProps {
  query: string;
  setView: EnumSetter<EditDriverView>;
  onCancel: () => void;
}

const DriverSelectMenu: React.FC<DriverSelectMenuProps> = ({ query, setView, onCancel }) => {
  const dispatch = useAppDispatch();

  const { driverId, targetDriver, subDriverName, originalDriverName, onSave, attributes } =
    useContext(DimDriverEditReactContext);

  const isNewDriver = driverId == null;
  const isRenaming = originalDriverName !== subDriverName;
  const numAttrs = attributes.length;

  const dimensionalDriversBySubDriverId = useAppSelector(dimensionalDriversBySubDriverIdSelector);
  const originalDimDriver =
    driverId != null ? safeObjGet(dimensionalDriversBySubDriverId[driverId]) : undefined;
  const isSubDriver = useAppSelector((state) =>
    isNewDriver ? false : isDimensionalSubDriverSelector(state, driverId),
  );

  const driverIdsToExclude = useMemo(
    () => [originalDimDriver?.id, driverId].filter(isNotNull),
    [driverId, originalDimDriver?.id],
  );
  const items: DimDriverItem[] = useDimDriverSelectItems({
    driverIdsToExclude,
  });

  const sections = useMemo(
    () => [
      {
        id: 'dimDrivers',
        name: isNewDriver ? 'Create subdriver for…' : 'Convert to subdriver for…',
      },
    ],
    [isNewDriver],
  );

  const onTargetDriverSelected = useOnTargetDriverSelected({ setView });

  const onCreate = useCallback(() => {
    onSave();
  }, [onSave]);

  const createOption = useCallback(
    (): CustomOption => ({
      id: 'create',
      icon: <Plus />,
      render: (q: string) => <CustomCreateOption label="Create driver" helperText={q} />,
      showFirst: true,
      onSelect: onCreate,
    }),
    [onCreate],
  );

  const onRenameAllSelect = useCallback(
    (newName: string) => {
      onCancel();
      dispatch(openRenameSubDriverModal({ newName }));
    },
    [dispatch, onCancel],
  );

  const onRenameOne = useCallback(() => {
    onSave({ renamingOne: true });
  }, [onSave]);

  const renameOptions = useCallback(() => {
    if (driverId == null) {
      return [];
    }

    const renameOne: CustomOption = {
      id: 'rename',
      icon: <Plus />,
      render: (q: string) => (
        <RenameSubDriverOption name={q} originalName={originalDriverName} attributes={attributes} />
      ),
      showFirst: true,
      onSelect: onRenameOne,
      footer: (
        <SelectMenuFooter
          icon={<Pencil />}
          title="Rename driver"
          subtitle={`Updates the name of the selected driver.${
            isSubDriver
              ? ` References to "${originalDriverName}" will no longer include the selected driver.`
              : ''
          }`}
        />
      ),
    };
    const renameAll: CustomOption = {
      id: 'renameAll',
      icon: <Plus />,
      render: (q: string) => (
        <RenameAllSubDriversOption name={q} originalName={originalDriverName} />
      ),
      showFirst: true,
      onSelect: onRenameAllSelect,
      footer: (
        <SelectMenuFooter
          icon={<Pencil />}
          title="Rename all drivers"
          subtitle={`Updates all instances of "${originalDriverName}". References to "${originalDriverName}" will be updated.`}
        />
      ),
    };
    if (isSubDriver) {
      return [renameOne, renameAll];
    }

    return [renameOne];
  }, [driverId, onRenameOne, isSubDriver, originalDriverName, onRenameAllSelect, attributes]);

  const editAttributesOption = useCallback((): CustomOption => {
    return {
      id: 'editAttributes',
      icon: <Plus />,
      render: (name: string) => (
        <EditAttributesOption
          name={name}
          originalName={originalDriverName}
          isNewDriver={isNewDriver}
          attributes={attributes}
        />
      ),
      showFirst: true,
      onSelect: (q: string) => onTargetDriverSelected({ name: q, id: targetDriver?.id }),
      footer: (
        <SelectMenuFooter
          icon={<Dimension />}
          title={numAttrs === 0 ? 'Add attributes' : 'Edit attributes'}
          subtitle={
            numAttrs === 0
              ? 'Segment the selected driver with attributes.'
              : 'Update attributes assigned to the selected driver.'
          }
        />
      ),
    };
  }, [
    attributes,
    isNewDriver,
    numAttrs,
    onTargetDriverSelected,
    originalDriverName,
    targetDriver?.id,
  ]);

  const customOptions: CustomOption[] = useMemo(() => {
    if (isNewDriver) {
      return [createOption()];
    }
    if (isRenaming) {
      return renameOptions();
    }

    return [editAttributesOption()];
  }, [isNewDriver, isRenaming, editAttributesOption, createOption, renameOptions]);

  if (query.length === 0) {
    return null;
  }

  return (
    <SelectMenu
      query={query}
      items={items}
      sections={sections}
      searchKeys={SEARCH_KEYS}
      onSelect={onTargetDriverSelected}
      clearFocusOnMouseLeave
      customOptions={customOptions}
      switchFocusToExactMatch
    >
      {BaseSelectMenuItem}
    </SelectMenu>
  );
};

export default DriverSelectMenu;
