import { useContext } from 'react';

import { CellStyleContext } from 'config/cells';

interface textStyleObj {
  bolding?: string;
  italics?: string;
  textDecoration?: string;
}

export function useRichText(): textStyleObj {
  const { richTextStyle } = useContext(CellStyleContext);
  if (richTextStyle == null) {
    return {};
  }

  const { bold, italic, strike } = richTextStyle;

  const bolding = bold ? 'bold' : undefined;
  const italics = italic ? 'italic' : undefined;
  const textDecoration = strike ? 'line-through' : undefined;

  return { bolding, italics, textDecoration };
}
