import React, { useMemo } from 'react';

import CellStyleContextProvider from 'components/CellContextProvider/CellStyleContextProvider';
import {
  CellRef,
  CellRefContext,
  CellSelectionStateContext,
  CellValueContext,
  DEFAULT_CELL_SELECTION_STATE,
} from 'config/cells';

interface Props {
  cellRef: CellRef;
  children: JSX.Element;
}

let id = 1;

const CellContextProviderLight: React.FC<Props> = ({ cellRef, children }) => {
  const cellRefCtx = useMemo(() => {
    return {
      cellRef,
      cellId: String(id++),
    };
  }, [cellRef]);

  const valueCtx = useMemo(() => {
    return {
      valueTooltip: undefined,
    };
  }, []);

  return (
    <CellRefContext.Provider value={cellRefCtx}>
      <CellSelectionStateContext.Provider value={DEFAULT_CELL_SELECTION_STATE}>
        <CellStyleContextProvider>
          <CellValueContext.Provider value={valueCtx}>{children}</CellValueContext.Provider>
        </CellStyleContextProvider>
      </CellSelectionStateContext.Provider>
    </CellRefContext.Provider>
  );
};

export default React.memo(CellContextProviderLight);
