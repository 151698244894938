import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import Formula from 'components/Formula/Formula';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import { FormulaDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import useAppSelector from 'hooks/useAppSelector';
import useIsTruncated from 'hooks/useIsTruncated';
import { DriverId, FormulaSourceType } from 'reduxStore/models/drivers';
import { driverSelector } from 'selectors/driversSelector';
import { Formula as FormulaIcon } from 'vectors';

import styles from './FormulaInputInheritedtFormulaPill.module.scss';

interface InheritedFormulaPillProps {
  formulaDisplay: FormulaDisplay;
  onClick: React.MouseEventHandler;
  formulaSourceDriverId: DriverId;
  formulaSourceType: FormulaSourceType;
}
const getFormulaSourceText = (formulaSourceType: FormulaSourceType) => {
  switch (formulaSourceType) {
    case 'forecast':
    case 'actuals':
      return formulaSourceType;
    case 'defaultForecast':
      return 'default forecast';
    case 'defaultActuals':
      return 'default actuals';
    default:
      return 'unknown';
  }
};

const openDetailsModalButtonOnClick: React.MouseEventHandler = (e) => {
  e.stopPropagation();
};

export const InheritedFormulaPill: React.FC<InheritedFormulaPillProps> = React.memo(
  ({ formulaDisplay, onClick, formulaSourceDriverId, formulaSourceType }) => {
    const { ref: truncationRef, isTruncated } = useIsTruncated();

    const driver = useAppSelector((state) => driverSelector(state, { id: formulaSourceDriverId }));

    if (driver == null) {
      return null;
    }

    return (
      <Tooltip
        label={`Inherited from ${driver.name} ${getFormulaSourceText(formulaSourceType)}. Click, tab or -> to edit`}
      >
        <div
          onClick={onClick}
          className={styles.inheritedFormulaPill}
          data-testid="inherited-formula-pill"
        >
          <FormulaIcon padding={1} boxSize={6} position="relative" />
          <Box overflow="hidden" ref={truncationRef}>
            <Formula formulaDisplay={formulaDisplay} color="gray.500" />
          </Box>
          {isTruncated && <Text px="1">...</Text>}
          <Box className={styles.openDetailsModalButton}>
            <OpenDetailsModalButton
              type="driver"
              id={formulaSourceDriverId}
              openInNewTab
              onClick={openDetailsModalButtonOnClick}
            />
          </Box>
        </div>
      </Tooltip>
    );
  },
);
