import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'BarChart',
  viewBox: '0 0 14 16',
  defaultProps: {
    fill: '#18A0FB',
    boxSize: '14px',
  },
  d: 'M12.9997 12.333H12.333V2.33301C12.333 2.1562 12.2628 1.98663 12.1377 1.8616C12.0127 1.73658 11.8432 1.66634 11.6663 1.66634C11.4895 1.66634 11.32 1.73658 11.1949 1.8616C11.0699 1.98663 10.9997 2.1562 10.9997 2.33301V12.333H9.66634V7.66634C9.66634 7.48953 9.5961 7.31996 9.47108 7.19494C9.34605 7.06991 9.17649 6.99967 8.99967 6.99967C8.82286 6.99967 8.65329 7.06991 8.52827 7.19494C8.40325 7.31996 8.33301 7.48953 8.33301 7.66634V12.333H6.99967V4.99967C6.99967 4.82286 6.92944 4.65329 6.80441 4.52827C6.67939 4.40325 6.50982 4.33301 6.33301 4.33301C6.1562 4.33301 5.98663 4.40325 5.8616 4.52827C5.73658 4.65329 5.66634 4.82286 5.66634 4.99967V12.333H4.33301V10.333C4.33301 10.1562 4.26277 9.98663 4.13775 9.8616C4.01272 9.73658 3.84315 9.66634 3.66634 9.66634C3.48953 9.66634 3.31996 9.73658 3.19494 9.8616C3.06991 9.98663 2.99967 10.1562 2.99967 10.333V12.333H1.66634V0.999674C1.66634 0.822863 1.5961 0.653294 1.47108 0.52827C1.34605 0.403246 1.17649 0.333008 0.999674 0.333008C0.822863 0.333008 0.653294 0.403246 0.52827 0.52827C0.403246 0.653294 0.333008 0.822863 0.333008 0.999674V12.9997C0.333008 13.1765 0.403246 13.3461 0.52827 13.4711C0.653294 13.5961 0.822863 13.6663 0.999674 13.6663H12.9997C13.1765 13.6663 13.3461 13.5961 13.4711 13.4711C13.5961 13.3461 13.6663 13.1765 13.6663 12.9997C13.6663 12.8229 13.5961 12.6533 13.4711 12.5283C13.3461 12.4032 13.1765 12.333 12.9997 12.333Z',
});
