import { createSelector } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

import { ExtStaticSource, Integration, LinkedAccount } from 'generated/graphql';
import { ExtSource, getExtSource, getExtSourceProvider } from 'helpers/integrations';
import type { Integrations } from 'reduxStore/reducers/integrationsSlice';
import { paramSelector } from 'selectors/constSelectors';
import { integrationQueryIdsForSourceSelector } from 'selectors/integrationQueriesSelector';
import { ParametricSelector, Selector } from 'types/redux';

export const integrationsSelector: Selector<Integrations> = (state) => state.integrations;

export const integrationsFetching: Selector<boolean> = (state) =>
  state.integrations.loading === 'idle' || state.integrations.loading === 'pending';

export const mergeIntegrationsSelector: Selector<Integrations['merge']> = createSelector(
  integrationsSelector,
  (integrations) => integrations.merge,
);

export const linkedAccountsSelector: Selector<LinkedAccount[]> = createSelector(
  integrationsSelector,
  (integrations) => integrations.linked,
);

const linkedAccountsByIdSelector: Selector<NullableRecord<string, LinkedAccount>> = createSelector(
  integrationsSelector,
  (integrations) => keyBy(integrations.linked, 'id'),
);

export const linkedAccountSelector: ParametricSelector<
  { accountId: string | null | undefined; source: ExtSource | null | undefined },
  LinkedAccount | undefined
> = createSelector(
  paramSelector<{ accountId: string | null | undefined; source: ExtSource | null | undefined }>(),
  linkedAccountsByIdSelector,
  (params, integrations) => {
    const { accountId, source } = params;
    if (accountId == null || source == null) {
      return undefined;
    }
    const linkedAccount = integrations[accountId];

    if (linkedAccount == null) {
      return undefined;
    }

    const sourceProvider = getExtSourceProvider(source);
    const linkedAccountProviderMatchesSourceProvider =
      linkedAccount?.integration?.provider === sourceProvider;

    if (!linkedAccountProviderMatchesSourceProvider) {
      return undefined;
    }
    return linkedAccount;
  },
);

export const linkedAccountsByExtSourceSelector: Selector<Map<ExtSource, LinkedAccount[]>> =
  createSelector(linkedAccountsSelector, (linkedAccounts) => {
    const result: Map<ExtSource, LinkedAccount[]> = new Map();
    for (const linkedAccount of linkedAccounts) {
      const { integration } = linkedAccount;
      const key = getExtSource(integration);
      const toExtend = result.has(key) ? result.get(key) : [];
      result.set(key, toExtend);
    }
    return result;
  });

export const availableIntegrationsSelector: Selector<Integration[]> = createSelector(
  integrationsSelector,
  (integrations) => integrations.available,
);

export const availableIntegrationsByExtSourceSelector: Selector<Map<ExtSource, Integration>> =
  createSelector(integrationsSelector, (integrations) => {
    const result: Map<ExtSource, Integration> = new Map();
    for (const integration of integrations.available) {
      const key = getExtSource(integration);
      result.set(key, integration);
    }
    return result;
  });

const availableIntegrationBySlug: Selector<NullableRecord<string, Integration>> = createSelector(
  availableIntegrationsSelector,
  (available) => keyBy(available, 'slug'),
);

export const integrationSquareImage: ParametricSelector<string, string | undefined> =
  createSelector(
    availableIntegrationBySlug,
    paramSelector<string>(),
    (integrationsBySlug, slug) => integrationsBySlug[slug]?.image ?? undefined,
  );

export const integrationsFetchStatusSelector: Selector<
  'idle' | 'pending' | 'succeeded' | 'failed'
> = createSelector(integrationsSelector, (integrations) => integrations.loading);

export const integrationsSourceSupportsResyncSelector: ParametricSelector<
  ExtSource | undefined,
  boolean
> = createSelector(
  paramSelector<ExtSource | undefined>(),
  integrationQueryIdsForSourceSelector,
  (source, _) => {
    if (source == null) {
      return false;
    }
    // TODO: generalize this somehow
    if (source === ExtStaticSource.RunwayApi) {
      return false;
    }

    return true;
  },
);
