import { BackgroundProps, Flex, StyleProps } from '@chakra-ui/react';
import classNames from 'classnames';
import React from 'react';
import { isSafari } from 'react-device-detect';

import EmptyEmojiIcon from 'vectors/EmptyEmoji';

import styles from './EmojiIcon.module.scss';

const FONT_SIZE_STYLE_PROPS: Record<Props['size'], StyleProps> = {
  xs: {
    fontSize: 'sm',
    height: 4,
    width: 4,
  },
  sm: {
    fontSize: 'sm',
    height: 6,
    width: 6,
  },
  lg: {
    fontSize: 'xl',
    height: 8,
    width: 8,
  },
};

interface Props {
  emoji: string | null;
  size: 'xs' | 'sm' | 'lg';
  bgColor?: BackgroundProps['bgColor'];
  onPointerEnter?: React.MouseEventHandler;
  onPointerLeave?: React.MouseEventHandler;
  emptyIcon?: React.ReactNode;
}

const EmojiIcon = React.forwardRef<HTMLDivElement, Props>(
  ({ emoji, size, bgColor, emptyIcon, ...rest }, ref) => {
    return (
      <Flex
        ref={ref}
        bgColor={bgColor}
        borderRadius="md"
        alignItems="center"
        justifyContent="center"
        fontFamily="AppleColorEmoji, system-ui"
        flex={0}
        transition="all .15s"
        className={classNames({
          // TODO: add support for all emoji sizes, not just xs
          [styles.isSafariXS]: isSafari && size === 'xs',
        })}
        {...rest}
        // spread in props from tooltip
        {...FONT_SIZE_STYLE_PROPS[size]}
      >
        {emoji ?? emptyIcon ?? <EmptyEmojiIcon />}
      </Flex>
    );
  },
);

export default EmojiIcon;
