import { Center, Fade, Flex } from '@chakra-ui/react';
import React from 'react';

import Check from 'vectors/Check';
import FormulaIcon from 'vectors/Formula';
import FormulaError from 'vectors/FormulaError';

interface Props {
  showSavedCheckmark: boolean;
  isEditing: boolean;
  hasSavedError: boolean;
}

const FADE_TRANSITION = { enter: { duration: 0 }, exit: { duration: 0.3 } };

const SaveFormulaIcon: React.FC<Props> = ({ showSavedCheckmark, isEditing, hasSavedError }) => {
  return (
    <Flex
      borderRadius="md"
      overflow="hidden"
      position="relative"
      color={hasSavedError ? 'white' : isEditing ? 'selection.500' : 'gray.400'}
      bgColor={hasSavedError ? 'failure' : undefined}
      _hover={isEditing || hasSavedError ? {} : { bgColor: 'gray.200', color: 'gray.500' }}
    >
      {hasSavedError ? (
        <FormulaError padding={1} boxSize={6} position="relative" />
      ) : (
        <FormulaIcon padding={1} boxSize={6} position="relative" />
      )}
      <Fade in={showSavedCheckmark} transition={FADE_TRANSITION}>
        <Center
          position="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          bgColor="#5FDC5C"
          color="white"
          pointerEvents="none"
        >
          <Check padding={1} boxSize={5} />
        </Center>
      </Fade>
    </Flex>
  );
};

export default React.memo(SaveFormulaIcon);
