import {
  BlockComparisonDatasetDocument,
  BlockComparisonDatasetQuery,
  BlockComparisonDatasetQueryVariables,
  Dataset,
} from 'generated/graphql';
import { convertDatasetGQLToDatasetSnapshot } from 'reduxStore/models/dataset';
import { emptyGQLDataset, initializeNamedVersionLite } from 'reduxStore/reducers/datasetSlice';
import { AppThunk } from 'reduxStore/store';

/**
 * Fetch a small subset of named version data, used in block comparisons, and push
 * it into the redux store
 */
export const loadCompareBlocksDataAsync =
  (orgId: string, namedVersions: string[]): AppThunk =>
  async (dispatch, getState, { urqlClient }) => {
    const state = getState();

    // If there already full named versions in the store, we don't need to fetch the
    // skinny version
    const namedVersionsToFetch = namedVersions.filter(
      (namedVersion) => state.dataset.snapshots[namedVersion] == null,
    );
    if (namedVersionsToFetch.length === 0) {
      return;
    }

    for (const mutationId of namedVersionsToFetch) {
      // Fetch a subset of the dataset over graphql, instead of fetching the
      // whole dataset via the REST api. This minimizes the amount of data
      // held in memory, via the response objects.
      const queryVariables = { orgId, mutationId };
      const query = urqlClient.query<
        BlockComparisonDatasetQuery,
        BlockComparisonDatasetQueryVariables
      >(BlockComparisonDatasetDocument, queryVariables);

      // We await these responses to minimize the number of concurrent responses
      // held in memory simultaneously.
      await query.toPromise().then((resp) => {
        const datasetSubset = resp.data?.dataset;
        if (datasetSubset == null || mutationId == null) {
          return;
        }
        const syntheticGQLDataset: Dataset = { ...emptyGQLDataset, ...datasetSubset };
        const snapshot = convertDatasetGQLToDatasetSnapshot(syntheticGQLDataset);
        dispatch(initializeNamedVersionLite({ snapshot, mutationId }));
      });
    }
  };
