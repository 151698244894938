import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';

import { preventEventDefault } from 'helpers/browserEvent';
import TrendArrow from 'vectors/TrendArrow';

interface Props extends IconButtonProps {
  onClick: React.MouseEventHandler;
}

const JumpToButton: React.FC<Props> = ({ onClick, ...rest }) => {
  return (
    <IconButton
      data-testid="jump-to-button"
      role="group"
      variant="icon"
      alignItems="center"
      h="unset"
      w="unset"
      border="none"
      minW="unset"
      boxSize={6}
      borderRadius="md"
      _hover={{ bgColor: 'gray.200' }}
      color="gray.400"
      _groupHover={{ color: 'gray.500' }}
      onClick={(ev) => {
        onClick(ev);
        preventEventDefault(ev);
      }}
      icon={<TrendArrow direction="up" />}
      {...rest}
    />
  );
};

export default React.memo(JumpToButton);
