import { useMergeRefs } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { useContext } from 'react';

import { CellSelectionStateContext } from 'config/cells';
import { ValueType } from 'generated/graphql';
import useScrollToCell from 'hooks/useScrollToCell';

import styles from './DisplayCell.module.scss';

interface Props {
  onDoubleClick?: React.MouseEventHandler;
  align?: 'left' | 'right';
  children: JSX.Element | undefined;
  valueType: ValueType;
}

const DisplayCell = React.forwardRef<HTMLDivElement, Props>(
  ({ children, valueType, onDoubleClick, align }, passedRef) => {
    const { isSelected, isBackgroundSelected } = useContext(CellSelectionStateContext);
    const isNumeric = valueType === ValueType.Number;
    const isTimestamp = valueType === ValueType.Timestamp;

    const scrollRef = useScrollToCell(isSelected && !isBackgroundSelected);
    const ref = useMergeRefs(passedRef, scrollRef);

    return (
      <div
        ref={ref}
        data-sentry-mask
        data-testid="display-cell"
        onDoubleClick={onDoubleClick}
        className={classNames(styles.displayCell, {
          [styles.isLeftAligned]: align === 'left',
          [styles.isNumber]: isNumeric,
          [styles.isTimestamp]: isTimestamp,
        })}
      >
        {children}
      </div>
    );
  },
);

export default React.memo(DisplayCell);
