import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthenticatedUser, MeQuery, OrgRole } from 'generated/graphql';
import { LoggedInUser, UserOrg } from 'reduxStore/models/user';

export enum LoginStatus {
  LoggedIn,
  LoggedOut,
  Pending,
  Error,
}

export type LoginState = {
  status: LoginStatus;
  user?: LoggedInUser;
  authenticatedUser?: AuthenticatedUser;
};

const initialState: LoginState = {
  status: LoginStatus.Pending,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loggedIn(_state, action: PayloadAction<{ user: MeQuery['me']; isNew: boolean }>) {
      const { user, isNew } = action.payload;
      return {
        status: LoginStatus.LoggedIn,
        user: getLoggedInUserFromMeQuery(user, isNew),
        authenticatedUser: user?.authenticatedUser ?? undefined,
      };
    },
    loggedOut() {
      return {
        status: LoginStatus.LoggedOut,
        user: undefined,
        authenticatedUser: undefined,
      };
    },
    loginError() {
      return {
        status: LoginStatus.Error,
      };
    },
    updateUserOrgRole(
      state,
      action: PayloadAction<{ orgId: string; userId: string; role: OrgRole }>,
    ) {
      const { orgId, userId, role } = action.payload;

      if (state.user == null) {
        return;
      }

      const org = state.user.orgs?.find((o) => o.id === orgId);
      if (org == null) {
        return;
      }

      const user = org.users?.find((u) => u.id === userId);
      if (user == null) {
        return;
      }

      user.orgRole = role;
    },
    removeOrgUser(state, action: PayloadAction<{ orgId: string; userId: string }>) {
      const { orgId, userId } = action.payload;

      if (state.user == null) {
        return;
      }

      const org = state.user.orgs?.find((o) => o.id === orgId);
      if (org == null) {
        return;
      }

      org.users = org.users?.filter((u) => u.id !== userId);
    },
  },
});

export function getLoggedInUserFromMeQuery(
  me: MeQuery['me'],
  isNew: boolean,
): LoggedInUser | undefined {
  if (me == null) {
    return undefined;
  }

  const user: LoggedInUser = {
    id: me.id,
    name: me.name,
    email: me.email,
    profileImage: me.profileImage,
    createdAt: me.createdAt,
    intercomHash: me.intercomHash,
    hasSuperPermission: me.hasSuperPermission || false,
    authenticatedUser: me.authenticatedUser,
    orgs: [],
    isNew,
  };

  me.orgs?.map((org) => {
    const userOrg: UserOrg = {
      id: org.id,
      slug: org.slug,
      name: org.name,
      isActive: org.isActive,
      isTemplateOrg: org.isTemplateOrg,
      generalSettings: org.generalSettings,
      createdAt: org.createdAt,
    };

    if (org.users != null) {
      userOrg.users = org.users;
    }

    user.orgs?.push(userOrg);
  });
  return user;
}

export const { loggedIn, loggedOut, loginError, updateUserOrgRole, removeOrgUser } =
  loginSlice.actions;

export default loginSlice.reducer;
