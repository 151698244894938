import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { uuidv4 } from 'helpers/uuidv4';

type SubmitJob = (renderJob: RenderJob) => string | number;
type CancelJob = (requestId: string | number) => void;
export interface RequestAnimationJobQueueManager {
  submitJob: SubmitJob;
  cancelJob: CancelJob;
}

type RenderJob = { run: () => void; requestId?: string };
const MAX_PROCESSING_TIME_MS = 1000 / 60;
const MAX_JOBS_TO_PROCESS_PER_FRAME = 100;

/**
 * The idea behind this queue to control the number of UI updates per frame.
 * This queue tries to only batch updates that can be completed within a frame.
 */
const useInitial = (): RequestAnimationJobQueueManager => {
  const rafIdRef = useRef<number | null>(null);
  const jobQueue = useRef<RenderJob[]>([]);
  const queueIsRunning = useRef(false);
  const canceledJobs = useRef<Set<string | number>>(new Set());
  const runningJobsRafIds = useRef<number[]>([]);

  const stopQueue = useCallback(() => {
    if (queueIsRunning.current === true) {
      queueIsRunning.current = false;
      if (rafIdRef.current != null) {
        cancelAnimationFrame(rafIdRef.current);
        rafIdRef.current = null;
        runningJobsRafIds.current.forEach((id) => cancelAnimationFrame(id));
        runningJobsRafIds.current = [];
      }
    }
  }, []);

  const runJobAsync = useCallback((job: RenderJob) => {
    return new Promise<void>((resolve) => {
      const id = requestAnimationFrame(() => {
        const notCanceled = !canceledJobs.current.has(job.requestId ?? '');
        if (notCanceled) {
          job.run();
        } else {
          canceledJobs.current.delete(job.requestId ?? '');
        }
        resolve();
      });
      runningJobsRafIds.current.push(id);
    });
  }, []);

  const processQueuePeriodically = useCallback(
    (frameStart: number) => {
      const queue = jobQueue.current;
      if (queue.length > 0) {
        const run = () => {
          //only perform jobs that can be completed within MAX_PROCESSING_TIME_MS since the start of the frame; also,we want to avoid committing to a lot of work in a single frame
          let numProcessed = 0;
          while (
            queue.length > 0 &&
            performance.now() - frameStart < MAX_PROCESSING_TIME_MS &&
            numProcessed < MAX_JOBS_TO_PROCESS_PER_FRAME
          ) {
            const job = queue.shift();
            numProcessed++;
            if (job != null) {
              runJobAsync(job);
            }
          }
        };
        /**
         * not awaiting to not block the main thread is fine because given the single threaded nature of JS, the order of execution is guaranteed.
         * subsequent calls to processQueuePeriodically will not be executed previous calls have finished. These previous calls may empty the queue guaranteeing that the recursion will stop eventually.
         */
        run();
        rafIdRef.current = requestAnimationFrame(processQueuePeriodically);
      } else {
        stopQueue();
      }
    },
    [runJobAsync, stopQueue],
  );

  const startQueue = useCallback(() => {
    if (queueIsRunning.current === false) {
      queueIsRunning.current = true;
      rafIdRef.current = requestAnimationFrame((frameStart: number) => {
        processQueuePeriodically(frameStart);
      });
    }
  }, [processQueuePeriodically]);

  const submitJob = useCallback(
    (renderJob: RenderJob) => {
      const requestId = renderJob.requestId ?? uuidv4();
      canceledJobs.current.delete(requestId);
      renderJob.requestId = requestId;
      jobQueue.current.push(renderJob);
      startQueue();
      return requestId;
    },
    [startQueue],
  );

  const cancelJob = useCallback((requestId: string | number) => {
    canceledJobs.current.add(requestId);
  }, []);

  useEffect(() => {
    return () => {
      stopQueue();
    };
  }, [stopQueue]);

  return useMemo(
    () => ({
      submitJob,
      cancelJob,
    }),
    [cancelJob, submitJob],
  );
};

const RequestAnimationJobQueueManagerContext =
  createContext<RequestAnimationJobQueueManager | null>(null);

export const useRequestAnimationJobQueueManager = () =>
  useContext(RequestAnimationJobQueueManagerContext);

interface RequestAnimationJobQueueManagerProviderProps {
  children: React.ReactNode;
}

export const RequestAnimationJobQueueManagerProvider: React.FC<
  RequestAnimationJobQueueManagerProviderProps
> = ({ children }) => {
  const manager = useInitial();
  return (
    <RequestAnimationJobQueueManagerContext.Provider value={manager}>
      {children}
    </RequestAnimationJobQueueManagerContext.Provider>
  );
};
