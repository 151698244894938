import { Box, BoxProps, Placement, StyleProps, useMergeRefs } from '@chakra-ui/react';
import { PropGetter } from '@chakra-ui/react-utils';
import Tippy from '@tippyjs/react/headless';
import { AnimationControls, motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef } from 'react';

import { Tooltip } from 'chakra/tooltip';
import { SELECT_MENU_SUBMENU } from 'config/selectMenu';
import { CELL_POPOVER_CLASS } from 'config/submodels';
import theme from 'config/theme';

const CLEARANCE_IN_PX = 4;
const PADDING_X_IN_PX = 4;
const PADDING_Y_IN_PX = 4;
const CELL_BORDER_WIDTH_IN_PX = 1;

const DEFAULT_POPOVER_OFFSET: [number, number] = [-2, -33];
const TABLE_CELL_POPOVER_CONTENT_TOOLTIP_OFFSET: [number, number] = [0, 12];

interface Props {
  children: JSX.Element;
  content: JSX.Element;
  width?: number;
  visible: boolean;
  onCancel?: () => void;
  onClickOutside?: () => void;
  onClose: () => void;
  autoFocus: boolean;
  minWidth?: StyleProps['minWidth'];
  errorMessage?: string;
  animationControls?: AnimationControls;
  placement?: Placement;
  disabled?: boolean;
  offset?: [number, number];
}

const TableCellPopover: React.FC<Props> = (props) => {
  const { children, visible, placement, disabled, offset, ...rest } = props;
  const cellRef = useRef<HTMLDivElement>(null);
  const child = React.Children.only(children) as React.ReactElement & {
    ref?: React.Ref<any>;
  };
  const mergedRef = useMergeRefs(child.ref, cellRef);
  const tableCell = React.cloneElement(child, {
    ...child.props,
    ref: mergedRef,
  } as ReturnType<PropGetter>);

  return (
    <>
      {tableCell}
      {visible && cellRef.current != null && (
        <Tippy
          offset={offset ?? DEFAULT_POPOVER_OFFSET}
          interactive
          placement={placement == null ? 'bottom-start' : placement}
          appendTo={document.body}
          visible
          zIndex={theme.zIndices.popover}
          render={() => (!disabled ? <TableCellPopoverContent {...rest} /> : null)}
          disabled={disabled}
          reference={cellRef.current}
        />
      )}
    </>
  );
};

const TableCellPopoverContent: React.FC<Omit<Props, 'children' | 'visible'>> = ({
  animationControls,
  autoFocus,
  content,
  errorMessage,
  minWidth,
  onCancel,
  onClickOutside,
  onClose,
  width,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const onClickOutsideHandler = useCallback(() => {
    if (onClickOutside != null) {
      onClickOutside();
    } else {
      onClose();
    }
  }, [onClickOutside, onClose]);

  useEffect(() => {
    if (autoFocus) {
      window.requestAnimationFrame(() => popoverRef.current?.focus());
    }
  }, [autoFocus]);

  const onKeyDown: React.KeyboardEventHandler = useCallback(
    (ev) => {
      if (ev.defaultPrevented || !autoFocus) {
        return;
      }

      switch (ev.key) {
        case 'Escape': {
          ev.preventDefault();
          ev.stopPropagation();
          if (onCancel != null) {
            onCancel();
          } else {
            onClose();
          }
          break;
        }
        case 'Enter': {
          ev.preventDefault();
          onClose();
          break;
        }
        default: {
          break;
        }
      }
    },
    [onClose, onCancel, autoFocus],
  );

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // Do nothing if clicking ref's element or descendent elements
      if (
        !containerRef.current ||
        containerRef.current.contains(target) ||
        target.closest(`.${SELECT_MENU_SUBMENU}`) != null
      ) {
        return;
      }

      onClickOutsideHandler();
    };

    // avoid inadvertently closing the menu with the mousedown event that opened the menu
    const timeout = setTimeout(() => document.addEventListener('mousedown', listener), 0);
    return () => {
      clearTimeout(timeout);
      document.removeEventListener('mousedown', listener);
    };
  }, [onClickOutsideHandler]);

  const hasError = errorMessage != null;

  return (
    <Box
      bg="surface"
      as={motion.div}
      animate={animationControls}
      ref={containerRef}
      className={CELL_POPOVER_CLASS}
      borderRadius="base"
      boxShadow="popover"
      minWidth={minWidth}
      width={width != null ? `${width + 2 * CLEARANCE_IN_PX}px` : undefined}
      left={`${-1 * CLEARANCE_IN_PX}px`}
      px={`${PADDING_X_IN_PX + CLEARANCE_IN_PX + CELL_BORDER_WIDTH_IN_PX}px`}
      py={`${PADDING_Y_IN_PX}px`}
      borderWidth="px"
      borderColor={hasError ? 'red.500' : 'transparent'}
    >
      <Tooltip
        placement="top-start"
        offset={TABLE_CELL_POPOVER_CONTENT_TOOLTIP_OFFSET}
        label={errorMessage}
        isOpen={hasError}
        data-testid="table-cell-popup-tooltip"
      >
        <Box ref={popoverRef} tabIndex={0} onKeyDown={onKeyDown}>
          {content}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default React.memo(TableCellPopover);

export const TableCellPopoverBox = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box
        {...props}
        bg="surface"
        as={motion.div}
        className={CELL_POPOVER_CLASS}
        borderRadius="base"
        boxShadow="popover"
        left={`${-1 * CLEARANCE_IN_PX}px`}
        px={`${PADDING_X_IN_PX + CLEARANCE_IN_PX + CELL_BORDER_WIDTH_IN_PX}px`}
        py={`${PADDING_Y_IN_PX}px`}
        border={0}
        fontSize="sm"
        ref={ref}
      >
        {children}
      </Box>
    );
  },
);
