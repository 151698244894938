import { handleCopy, handlePaste } from 'reduxStore/actions/copyPaste';
import { AppDispatch } from 'reduxStore/store';

export function dispatchPaste(dispatch: AppDispatch, text: string) {
  const clipboardData = new DataTransfer();
  clipboardData.setData('text/plain', text);
  const clipboardEvent = new ClipboardEvent('paste', { clipboardData });

  // This is called so that the event has a `target` set
  (document.activeElement ?? document.body).dispatchEvent(clipboardEvent);

  dispatch(handlePaste(clipboardEvent));
}

export function dispatchCopy(dispatch: AppDispatch) {
  const data = new DataTransfer();
  const ev = new ClipboardEvent('copy', { clipboardData: data });

  dispatch(handleCopy({ ev, isCut: false }));
  return data.getData('text/plain');
}
