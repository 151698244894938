import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'GoogleSheetsIcon',
  viewBox: '0 0 22 22',
  path: (
    <>
      <g>
        <defs>
          <path
            id="SVGID_1_"
            d="M14,1H5.5C4.7,1,4,1.7,4,2.5v19C4,22.3,4.7,23,5.5,23h13c0.8,0,1.5-0.7,1.5-1.5V7L14,1z"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_2_)"
          fill="#0F9D58"
          d="M14,1H5.5C4.7,1,4,1.7,4,2.5v19C4,22.3,4.7,23,5.5,23h13        c0.8,0,1.5-0.7,1.5-1.5V7l-3.5-2.5L14,1z"
        />
      </g>
      <g>
        <defs>
          <path
            id="SVGID_3_"
            d="M14,1H5.5C4.7,1,4,1.7,4,2.5v19C4,22.3,4.7,23,5.5,23h13c0.8,0,1.5-0.7,1.5-1.5V7L14,1z"
          />
        </defs>
        <clipPath id="SVGID_4_">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#SVGID_3_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_4_)"
          fill="#F1F1F1"
          d="M8,11.8V19h8v-7.2H8z M11.5,18H9v-1.2h2.5V18z M11.5,16H9v-1.2h2.5V16z         M11.5,14H9v-1.2h2.5V14z M15,18h-2.5v-1.2H15V18z M15,16h-2.5v-1.2H15V16z M15,14h-2.5v-1.2H15V14z"
        />
      </g>
      <g>
        <defs>
          <path
            id="SVGID_5_"
            d="M14,1H5.5C4.7,1,4,1.7,4,2.5v19C4,22.3,4.7,23,5.5,23h13c0.8,0,1.5-0.7,1.5-1.5V7L14,1z"
          />
        </defs>
        <clipPath id="SVGID_6_">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#SVGID_5_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_6_)">
          <g>
            <path fill="#87CEAC" d="M14,1v4.5C14,6.3,14.7,7,15.5,7H20L14,1z" />
          </g>
        </g>
      </g>
    </>
  ),
});
