import { useDisclosure } from '@chakra-ui/react';
import Tippy from '@tippyjs/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import MoreMenuButton from 'components/MoreMenuButton/MoreMenuButton';
import SelectItemPopoverContents from 'components/SelectItemPopoverContents/SelectItemPopoverContents';
import SelectItemPopoverContext from 'components/SelectItemPopoverContents/SelectItemPopoverContext';
import theme from 'config/theme';

interface Props {
  children: React.ReactNode;
  onClose?: () => void;
}

const SelectItemPopover: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onCloseProp = props.onClose;

  const onCloseCallback = useCallback(() => {
    onClose();
    onCloseProp?.();
  }, [onClose, onCloseProp]);

  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // Do nothing if clicking ref's element or descendent elements
      if (!isOpen || contentRef.current?.contains(target)) {
        return;
      }

      onCloseCallback();
    };

    // N.B. we have to to use capture here because we stop propagation on menu item mouse down events
    document.addEventListener('mousedown', listener, { capture: true });

    return () => {
      document.removeEventListener('mousedown', listener, { capture: true });
    };
  }, [onCloseCallback, isOpen]);

  const ctx = useMemo(() => ({ closeSelectItemPopover: onCloseCallback }), [onCloseCallback]);

  return (
    <Tippy
      interactive
      placement="right-start"
      visible
      appendTo={document.body}
      zIndex={theme.zIndices.popover + 2}
      render={() =>
        isOpen && (
          <SelectItemPopoverContext.Provider value={ctx}>
            <SelectItemPopoverContents ref={contentRef}>{props.children}</SelectItemPopoverContents>
          </SelectItemPopoverContext.Provider>
        )
      }
    >
      <MoreMenuButton isSelected isOpen={isOpen} onMouseDown={isOpen ? onClose : onOpen} />
    </Tippy>
  );
};

export default SelectItemPopover;
