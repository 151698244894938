import { Box, Flex } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';

const CopilotAssistant: React.FC = () => {
  return (
    <Flex alignItems="center">
      <Box pointerEvents="none">
        <Image
          src="/images/copilot.gif"
          alt="Copilot assistant"
          unoptimized
          data-chromatic="ignore"
          height={24}
          width={24}
          quality={100}
          style={{ borderRadius: '50%' }}
          // this will add a link preload at the top of the page so
          // the user doesn't have to wait for the image to load when
          // they use Copilot for the first time
          priority
        />
      </Box>
    </Flex>
  );
};

export default CopilotAssistant;
