import { createStateContext } from 'react-use';

export interface RequestQueue {
  pending: Promise<unknown> | null;
  queue: Array<() => Promise<unknown>>;
}

export const newRequestQueue = (): RequestQueue => ({
  pending: null,
  queue: [],
});

export function enqueueRequest(reqQueue: RequestQueue | undefined, req: () => Promise<unknown>) {
  // When running tests we don't have a request queue
  if (reqQueue == null) {
    return;
  }

  reqQueue.queue.push(req);

  // The first request into the queue is responsible for draining the queue
  // through a recursive callback (dequeue, below).  All subsequent requests
  // just enter the queue, and when the first one finishes, it will dequeue the
  // next and run it.
  const isResponsibleForDequeueing = reqQueue.pending == null;
  if (!isResponsibleForDequeueing) {
    return;
  }

  const dequeue = (): Promise<unknown> => {
    if (reqQueue.queue.length === 0) {
      return Promise.resolve();
    }
    return reqQueue.queue[0]()
      .then(() => {
        reqQueue.queue.shift();
        return dequeue();
      })
      .catch(() => {}); // noop, stop processing requests
  };

  reqQueue.pending = dequeue().finally(() => {
    reqQueue.pending = null;
  });
}

export function onQueueEmpty(reqQueue: RequestQueue) {
  return new Promise<void>((resolve) => {
    if (reqQueue.pending == null) {
      resolve();
      return;
    }

    reqQueue.pending.finally(resolve);
  });
}

const [useRequestQueueWithNull, RequestQueueContextProvider] =
  createStateContext<RequestQueue | null>(null);

export function useRequestQueue() {
  const [queue] = useRequestQueueWithNull();
  if (queue == null) {
    throw new Error('requestQueue is null, missing RequestQueueContextProvider');
  }
  return queue;
}

export { RequestQueueContextProvider };
