import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'DatabaseIcon',
  viewBox: '0 0 16 17',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <>
      <path
        d="M5.61539 0.597458H2.38462C1.35077 0.597458 0.5 1.44823 0.5 2.48207V5.71284C0.5 6.74669 1.35077 7.59746 2.38462 7.59746H5.61539C6.64923 7.59746 7.5 6.74669 7.5 5.71284V2.48207C7.5 1.44823 6.64923 0.597458 5.61539 0.597458ZM5.88462 5.71284C5.88462 5.86361 5.76615 5.98207 5.61539 5.98207H2.38462C2.23385 5.98207 2.11538 5.86361 2.11538 5.71284V2.48207C2.11538 2.3313 2.23385 2.21284 2.38462 2.21284H5.61539C5.76615 2.21284 5.88462 2.3313 5.88462 2.48207V5.71284Z"
        fill="currentColor"
      />
      <path
        d="M14.9576 10.8428L13.2538 9.13898C12.5558 8.44104 11.4166 8.44104 10.7186 9.13898L9.01479 10.8428C8.31685 11.5407 8.31685 12.68 9.01479 13.3883L10.7186 15.0921C11.0676 15.441 11.5295 15.6155 11.9913 15.6155C12.4532 15.6155 12.9151 15.441 13.2641 15.0921L14.9679 13.3883C15.6658 12.6903 15.6658 11.551 14.9679 10.8428H14.9576ZM13.8696 12.3003L12.1658 14.0041C12.0632 14.1067 11.899 14.1067 11.8066 14.0041L10.1028 12.3003C10.0001 12.1976 10.0001 12.0334 10.1028 11.941L11.8066 10.2372C11.8066 10.2372 11.9195 10.1654 11.9913 10.1654C12.0632 10.1654 12.1248 10.1859 12.1761 10.2372L13.8799 11.941C13.9825 12.0437 13.9825 12.2079 13.8799 12.3003H13.8696Z"
        fill="currentColor"
      />
      <path
        d="M10.0965 7.59746H13.9035C14.4868 7.59746 14.9985 7.27777 15.2851 6.73762C15.5716 6.19746 15.5716 5.55809 15.2851 5.01793L13.3816 1.4573C13.095 0.917143 12.5731 0.597458 12 0.597458C11.4269 0.597458 10.905 0.917143 10.6184 1.4573L8.71491 5.01793C8.42836 5.55809 8.42836 6.19746 8.71491 6.73762C9.00146 7.27777 9.52339 7.59746 10.0965 7.59746ZM12 2.251C12 2.251 12.0512 2.27305 12.0512 2.28407L13.9547 5.8447L13.9035 5.94391H10.0965L10.0453 5.8447L12.0102 2.251H12Z"
        fill="currentColor"
      />
      <path
        d="M4 8.59746C2.07231 8.59746 0.5 10.1698 0.5 12.0975C0.5 14.0252 2.07231 15.5975 4 15.5975C5.92769 15.5975 7.5 14.0252 7.5 12.0975C7.5 10.1698 5.92769 8.59746 4 8.59746ZM4 13.9821C2.96615 13.9821 2.11538 13.1313 2.11538 12.0975C2.11538 11.0636 2.96615 10.2128 4 10.2128C5.03385 10.2128 5.88462 11.0636 5.88462 12.0975C5.88462 13.1313 5.03385 13.9821 4 13.9821Z"
        fill="currentColor"
      />
    </>
  ),
});
