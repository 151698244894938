import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { createCachedSelector } from 're-reselect';

import { DEFAULT_OBJECT_FIELD_COLUMN_WIDTH } from 'config/businessObjects';
import { CELL_DATA_COLUMN_WIDTH_IN_PX } from 'config/cells';
import {
  BlockId,
  OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY,
  ObjectTableBlockColumnKey,
} from 'reduxStore/models/blocks';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { isNewDimensionalTableSelector } from 'selectors/collectionBlocksSelector';
import { configurableColumnsSelector } from 'selectors/configurableColumnsSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { liveEditBlockColumnResizeSelector } from 'selectors/liveEditSelector';
import { ParametricSelector } from 'types/redux';

export const columnWidthsForObjectTableBlockSelector: ParametricSelector<
  BlockId,
  Record<ObjectTableBlockColumnKey, number>
> = createCachedSelector(
  liveEditBlockColumnResizeSelector,
  (state: RootState, blockId: BlockId) => configurableColumnsSelector(state, blockId),
  (state: RootState, blockId: BlockId) => isNewDimensionalTableSelector(state, blockId),
  (columnLiveEdit, businessObjectTableColumns, isNewDimensionalTable) => {
    const columnsByKey = keyBy(businessObjectTableColumns, (c) => c.key);
    function getColumnWidth(columnKey: ObjectTableBlockColumnKey) {
      const savedColumn = columnsByKey[columnKey];
      const liveResizeWidth = columnLiveEdit?.columnKey === columnKey ? columnLiveEdit.width : null;
      if (isNewDimensionalTable && columnKey === OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY) {
        return CELL_DATA_COLUMN_WIDTH_IN_PX;
      }
      return liveResizeWidth ?? savedColumn?.width ?? DEFAULT_OBJECT_FIELD_COLUMN_WIDTH;
    }

    return mapValues(columnsByKey, (c) => getColumnWidth(c.key));
  },
)(blockIdSelector);
