import qs from 'qs';

export function stripProtocol(url: string): string {
  return url.replace(/^(https?:)?\/\//, '');
}

export const stringifyUrlParams = (params: any) => {
  return qs.stringify(params, {
    arrayFormat: 'repeat',
    addQueryPrefix: true,
  });
};
