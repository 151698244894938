import { Flex, Text } from '@chakra-ui/react';

import { Tooltip } from 'chakra/tooltip';
import LastActualsMenu from 'components/LastActualsMenu/LastActualsMenu';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { isComparingTimePeriodsSelector } from 'selectors/comparisonTimePeriodsSelector';
import { isComparingLayersSelector } from 'selectors/scenarioComparisonSelector';

export type TimeSeriesHeaderDataType = 'driver' | 'event' | 'extDriver';

interface Props {
  label: string;
  isActuals?: boolean;
  isLastActualsMonth?: boolean;
  dataType?: TimeSeriesHeaderDataType;
  onChangeLastClose?: () => void;
  lastActualsTopPositionProps?: { top: string; py: number };
  rollupLabel?: string | null;
}

const TimeSeriesHeaderLabel: React.FC<Props> = ({
  label,
  isActuals = false,
  isLastActualsMonth = false,
  dataType = 'driver',
  onChangeLastClose,
  lastActualsTopPositionProps,
  rollupLabel,
}) => {
  const { blockId } = useBlockContext();
  const isComparingLayers = useAppSelector((state) => isComparingLayersSelector(state, blockId));
  const isComparingTimePeriods = useAppSelector((state) =>
    isComparingTimePeriodsSelector(state, blockId),
  );
  const isComparing = isComparingLayers || isComparingTimePeriods;

  return (
    <Tooltip label={rollupLabel} placement="top">
      <Flex
        direction="row"
        height="full"
        width="full"
        align="flex-end"
        justify="flex-end"
        pb="2px"
        data-testid="timeseries-header-label"
      >
        <Text
          fontSize={isComparing ? 'xxs' : 'xs'}
          fontWeight="medium"
          color={isActuals || dataType === 'extDriver' ? 'gray.500' : 'blue.500'}
        >
          {label}
        </Text>
        {isLastActualsMonth && (
          <LastActualsMenu
            dataType={dataType}
            onUpdated={onChangeLastClose}
            topPositionProps={lastActualsTopPositionProps}
          />
        )}
      </Flex>
    </Tooltip>
  );
};

export default TimeSeriesHeaderLabel;
