import noop from 'lodash/noop';
import { createContext } from 'react';

import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { EntityDataWithKey } from 'types/formula';

export default createContext<{
  // There isn't always going to be an associated entity to the FormulaInput.
  // For example, we use the FormulaInput to enter Plans for timeseries cells.
  formulaEntityId: FormulaEntityTypedId | undefined;
  defaultSubmenu: string | null;
  isActuals: boolean;
  hasError: boolean;
  activeEntity: EntityDataWithKey | null;
  setActiveEntity: (key: EntityDataWithKey | null) => void;
  closeFormulaInput: () => void;
  anchorOffset: number;
  focusOffset: number;
  resetSelectionState: () => void;
  setDefaultSubmenu: (submenu: string | null) => void;
  keysInSelection: Set<string>;
}>({
  formulaEntityId: { type: 'driver', id: '' },
  defaultSubmenu: null,
  isActuals: false,
  hasError: false,
  activeEntity: null,
  setActiveEntity: noop,
  closeFormulaInput: noop,
  anchorOffset: 0,
  focusOffset: 0,
  setDefaultSubmenu: noop,
  resetSelectionState: noop,
  keysInSelection: new Set(),
});
