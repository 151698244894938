import { FocusLock } from '@chakra-ui/focus-lock';
import { SearchIcon } from '@chakra-ui/icons';
import { Flex, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import CreateDimensionalDriverMenu from 'components/DimensionalDriverMenu/CreateDimensionalDriverMenu';
import EditDriverInput from 'components/DimensionalDriverMenu/EditDriverInput';
import useEditDriverView from 'components/DimensionalDriverMenu/useEditDriverView';
import { FocusedDriverProvider } from 'components/DriverBlockSelect/FocusedDriverProvider';
import { SelectHintProvider } from 'components/DriverBlockSelect/SelectHintProvider';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { DimDriverEditReactContext } from 'config/dimDriverEditContext';
import { stopEventPropagation } from 'helpers/browserEvent';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useDimDriverEditor, { OnError } from 'hooks/useDimDriverEditor';
import { jumpToDriver } from 'reduxStore/actions/jumpToDriver';
import { DimensionId } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { navPageNameSelector } from 'selectors/blocksPagesSelector';

export const ADD_DRIVERS_POPOVER_CLASSNAME = 'add-drivers-popover';

interface Props {
  onClose: () => void;
  groupId?: DriverGroupId;
  belowDriverId?: DriverId;
}

const DriverBlockSelect: React.FC<Props> = ({ onClose, groupId, belowDriverId }) => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { blockId } = useBlockContext();
  const [view, setView] = useEditDriverView({ dimMenuIsDefaultOpen: false });

  const [selectedDimensionId, setSelectedDimensionId] = useState<DimensionId | undefined>();
  const pageName = useAppSelector(navPageNameSelector);

  const focusOnInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const focusOnExistingDriver: OnError = useCallback(
    (_message?: string, extras?) => {
      if (extras?.existingTargetSubDriverId != null) {
        dispatch(
          jumpToDriver({
            driverId: extras?.existingTargetSubDriverId,
            opts: { openInNewTab: false },
            blockId,
          }),
        );
      }
      onClose();
    },
    [blockId, dispatch, onClose],
  );

  useEffect(() => {
    // requestAnimationFrame doesn't result in focusing correctly; adding a short timeout as a workaround
    setTimeout(() => focusOnInput(), 50);
  }, [focusOnInput]);

  const { dimDriverContext, dimDriverError } = useDimDriverEditor({
    driverId: undefined,
    onDone: onClose,
    onError: focusOnExistingDriver,
    belowDriverId,
    groupId,
    resetOnDone: true,
    pageName,
  });

  const hasSearchInputIcon = view === 'driverSelectMenu';

  return (
    <FocusLock restoreFocus initialFocusRef={inputRef}>
      <DimDriverEditReactContext.Provider value={dimDriverContext}>
        <SelectMenuContainer
          ref={containerRef}
          testId="driver-block-select"
          className={ADD_DRIVERS_POPOVER_CLASSNAME}
        >
          <FocusedDriverProvider>
            <SelectHintProvider>
              <Flex flexDir="column" w="full" onClick={stopEventPropagation} p={2} pb="px">
                <Flex
                  columnGap={1}
                  alignItems="center"
                  bgColor="surface"
                  _focusWithin={{
                    outlineColor: 'selection.500',
                    outlineOffset: '-1px',
                  }}
                  width="100%"
                  minWidth="0px"
                  outline="transparent solid 2px"
                  position="relative"
                  appearance="none"
                  transitionProperty="common"
                  transitionDuration="normal"
                  borderRadius="md"
                  borderWidth="1px"
                  borderColor={dimDriverError == null ? 'gray.300' : 'runway.red'}
                  height={8}
                  onClick={focusOnInput}
                  boxSizing="border-box"
                >
                  <InputGroup>
                    {hasSearchInputIcon && (
                      <InputLeftElement pointerEvents="none" h="full">
                        <SearchIcon color="gray.400" boxSize={3} />
                      </InputLeftElement>
                    )}
                    <Flex pl={hasSearchInputIcon ? 9 : 2} width="full">
                      <EditDriverInput
                        inputRef={inputRef}
                        driverId={undefined}
                        onCancel={onClose}
                        setSelectedDimensionId={setSelectedDimensionId}
                        view={view}
                        setView={setView}
                        placeholder=""
                      />
                    </Flex>
                  </InputGroup>
                </Flex>
              </Flex>
              <CreateDimensionalDriverMenu
                view={view}
                setView={setView}
                groupId={groupId}
                onCancel={onClose}
                selectedDimensionId={selectedDimensionId}
                allowAddingExistingDrivers
                inputRef={inputRef}
                containerRef={containerRef}
              />
            </SelectHintProvider>
          </FocusedDriverProvider>
        </SelectMenuContainer>
      </DimDriverEditReactContext.Provider>
    </FocusLock>
  );
};

export default DriverBlockSelect;
