import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import DriverAttributeBadges from 'components/DriverAttributeBadges/DriverAttributeBadges';
import CursorDisplayRow from 'components/DriverChartCardHeader/CursorDisplayRow';
import ScenarioLegendButton from 'components/DriverChartCardHeader/ScenarioLegendButton';
import DragButton from 'components/DriverCharts/DragButton';
import KPIIcon from 'components/KPIIcon/KPIIcon';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { DriverId } from 'reduxStore/models/drivers';
import { driverIsKpiSelector, driverNameSelector } from 'selectors/driversSelector';
import { enableAgChartsSelector } from 'selectors/launchDarklySelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

interface Props {
  showDriverDetailsButton: boolean;
  showCursorValue: boolean;
  dragHandleRef?: React.ForwardedRef<HTMLButtonElement>;
  disableRemoving?: boolean;
  disableAgCharts?: boolean;
  driverId: DriverId;
}

const DriverChartCardHeader: React.FC<Props> = ({
  showDriverDetailsButton,
  showCursorValue,
  dragHandleRef,
  disableRemoving,
  disableAgCharts = false,
  driverId,
}) => {
  const title = useAppSelector((state) => driverNameSelector(state, { id: driverId }));
  const { blockId, readOnly } = useBlockContext();
  const isKpi = useAppSelector((state) => driverIsKpiSelector(state, driverId));
  const enableAgCharts = useAppSelector(enableAgChartsSelector);

  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const displayScenarioLegendButton = comparisonLayerIds.length > 0;

  return (
    <Flex w="full" px={3} flexDir="column" position="relative">
      <Flex w="full" alignItems="center" justifyContent="space-between">
        <Flex fontSize="xs" alignItems="center">
          <Flex alignItems="center" columnGap={1}>
            <Text fontWeight="600" fontSize="xs" noOfLines={1} wordBreak="break-all">
              {title}
            </Text>
            <DriverAttributeBadges driverId={driverId} />
          </Flex>
          {!readOnly && (disableAgCharts || !enableAgCharts) && (
            <Box flexShrink={0} height={6}>
              <Box
                display={isKpi ? 'inline-block' : 'none'}
                _groupHover={{ display: 'inline-block' }}
              >
                <KPIIcon id={driverId} />
              </Box>
              {displayScenarioLegendButton && (
                <Box display="none" _groupHover={{ display: 'inline-block' }}>
                  <ScenarioLegendButton />
                </Box>
              )}
              <Box
                display="none"
                _groupHover={{ display: showDriverDetailsButton ? 'inline-block' : 'none' }}
              >
                <OpenDetailsModalButton type="driver" id={driverId} />
              </Box>
            </Box>
          )}
        </Flex>
        {!readOnly && (
          <DragButton
            ref={dragHandleRef}
            driverId={driverId}
            disableRemoving={disableRemoving}
            canDrag
          />
        )}
      </Flex>
      {showCursorValue && !enableAgCharts && <CursorDisplayRow driverId={driverId} />}
    </Flex>
  );
};

export default DriverChartCardHeader;
