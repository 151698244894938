import { Box, Flex, Popover, PopoverContent, PopoverTrigger, Portal, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import MonthPicker from 'components/MonthPicker/MonthPicker';
import { TimeSeriesHeaderDataType } from 'components/TimeSeriesHeaderLabel/TimeSeriesHeaderLabel';
import { MAX_DATE, MIN_DATE } from 'config/datetime';
import { getMonthKey } from 'helpers/dates';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useControlledPopover from 'hooks/useControlledPopover';
import { updateLastActualsTime } from 'reduxStore/actions/lastActualsMutations';
import { datasetLastActualsDateTimeSelector } from 'selectors/lastActualsSelector';

interface HeaderSuperscriptLabelProps {
  isActuals: boolean;
  dataType: TimeSeriesHeaderDataType;
}

const ACTUALS_HEADER_TEXT: Record<TimeSeriesHeaderDataType, string> = {
  driver: 'Last Close',
  event: '<- Past',
  extDriver: '',
};

const FORECAST_HEADER_TEXT: Record<TimeSeriesHeaderDataType, string> = {
  driver: 'Forecast ->',
  event: 'Planned ->',
  extDriver: '',
};

const HeaderSuperscriptLabel: React.FC<HeaderSuperscriptLabelProps> = ({ isActuals, dataType }) => {
  const text = isActuals ? ACTUALS_HEADER_TEXT[dataType] : FORECAST_HEADER_TEXT[dataType];
  const color = isActuals ? 'gray.500' : 'blue.400';

  return (
    <Text
      textTransform="uppercase"
      fontSize="xxxs"
      color={color}
      fontWeight="bold"
      userSelect="none"
    >
      {text}
    </Text>
  );
};

interface LastActualsMenuProps {
  dataType: TimeSeriesHeaderDataType;
  onUpdated?: () => void;
  topPositionProps?: { top: string; py: number };
}

const LastActualsMenu: React.FC<LastActualsMenuProps> = ({
  dataType,
  onUpdated,
  topPositionProps = { top: '-5px', py: '2px' },
}) => {
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();
  const { canMoveLastClosed } = useAccessCapabilities();
  const dispatch = useAppDispatch();
  const lastActualsDateTime = useAppSelector(datasetLastActualsDateTimeSelector);
  const onDateSelect = useCallback(
    (dateTime: DateTime) => {
      dispatch(updateLastActualsTime(getMonthKey(dateTime)));
      onUpdated?.();
      onClose();
    },
    [onClose, dispatch, onUpdated],
  );

  if (!canMoveLastClosed) {
    return (
      <Box position="absolute" top={0} right={2}>
        <HeaderSuperscriptLabel isActuals dataType={dataType} />
      </Box>
    );
  }

  return (
    <Popover
      isLazy
      placement="bottom-start"
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      autoFocus={false}
    >
      <PopoverTrigger>
        <Flex
          data-testid="last-actuals-menu-trigger"
          ref={triggerRef}
          alignItems="center"
          cursor="pointer"
          mr={2}
          position="absolute"
          right="-6px"
          px="6px"
          {...topPositionProps}
          borderRadius="md"
          transition="all .2s"
          _hover={{ bg: 'gray.200' }}
        >
          <HeaderSuperscriptLabel isActuals dataType={dataType} />
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent padding={2} ref={contentRef}>
          <MonthPicker
            onDateSelect={onDateSelect}
            selected={lastActualsDateTime}
            minDate={MIN_DATE}
            maxDate={MAX_DATE}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default LastActualsMenu;
