import { Theme } from '@chakra-ui/react';

const bannedSpaces: Array<keyof Theme['space']> = [0.5, 1.5, 2.5, 3.5];
const bannedSizes: Array<keyof Theme['sizes']> = [0.5, 1.5, 2.5, 3.5];

export const removeBannedThemeVariables = (config: Theme) => {
  const { space, sizes } = config;
  bannedSpaces.forEach((key) => {
    delete space[key];
  });

  bannedSizes.forEach((key) => {
    delete sizes[key];
  });
};
