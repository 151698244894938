import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'TimelineIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4C1 3.44772 1.44772 3 2 3H10C10.5523 3 11 3.44772 11 4C11 4.55228 10.5523 5 10 5H2C1.44772 5 1 4.55228 1 4ZM5 8C5 7.44772 5.44772 7 6 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H6C5.44772 9 5 8.55228 5 8ZM4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H10C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11H4Z"
      fill="currentColor"
    />
  ),
});
