import { BoxProps, Text } from '@chakra-ui/react';
import pluralize from 'pluralize';
import React from 'react';

import Badge from 'components/Badge/Badge';
import { isBadgeTheme } from 'config/badge';
import { Dimension } from 'reduxStore/models/dimensions';
import { ANY_ATTR, CONTEXT_ATTR, DynamicAttributeFilterOption, NO_ATTR } from 'types/formula';
import LayersIcon from 'vectors/Layers';

interface Props {
  dimension: Dimension;
  type?: DynamicAttributeFilterOption;
  textColor?: BoxProps['color'];
}

const FALLBACK_COLOR = 'gray';

const DimensionBadge: React.FC<Props> = ({ dimension, textColor, type = ANY_ATTR }) => {
  const color = dimension.color;
  const theme = isBadgeTheme(color) ? color : FALLBACK_COLOR;
  return (
    <Badge
      size="xs"
      text={
        <Text as="span" display="inline-flex" alignItems="center" noOfLines={1} width="full">
          <Text noOfLines={1} fontStyle="italic" px={1} color={textColor}>
            {type === ANY_ATTR && (
              <>
                <LayersIcon color="gray.500" />
                &nbsp;All {pluralize(dimension.name)}
              </>
            )}
            {type === NO_ATTR && 'None'}
            {type === CONTEXT_ATTR && 'Context Attribute'}
          </Text>
        </Text>
      }
      theme={theme}
    />
  );
};

export default React.memo(DimensionBadge);
