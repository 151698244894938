import { Text } from '@chakra-ui/react';
import React, { useContext } from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import PropertyPickerLight from 'components/SubmodelTable/PropertyPickerLight';
import { DriverRowContext } from 'config/driverRowContext';
import { ColumnLayerId } from 'config/modelView';
import useAppSelector from 'hooks/useAppSelector';
import useDriverCellRef from 'hooks/useDriverCellRef';
import { driverRollupReducerSelector } from 'selectors/rollupSelector';

const COLUMN_TYPE = 'rollupType';

type Props = {
  columnLayerId: ColumnLayerId;
};

const RollupTypeTableCellLight: React.FC<Props> = ({ columnLayerId }) => {
  const { driverId } = useContext(DriverRowContext);
  const rollupType = useAppSelector((state) =>
    driverRollupReducerSelector(state, { id: driverId }),
  );
  const cellRef = useDriverCellRef({ columnType: COLUMN_TYPE, columnLayerId });

  return (
    <CellContextProvider cellRef={cellRef}>
      <PropertyPickerLight
        title="Set rollup"
        columnType={COLUMN_TYPE}
        columnLayerId={columnLayerId}
      >
        <Text>{rollupType}</Text>
      </PropertyPickerLight>
    </CellContextProvider>
  );
};

export default React.memo(RollupTypeTableCellLight);
