import { Flex } from '@chakra-ui/react';
import React from 'react';

import BlockHeaderCell from 'components/BlockHeaderCell/BlockHeaderCell';
import StickyColumnsAndTimeSeriesHeader from 'components/StickyColumnsAndTimeSeriesHeader/StickyColumnsAndTimeSeriesHeader';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import {
  COLUMN_TYPE_TO_NAME,
  INITIAL_VALUE_COLUMN_TYPE,
  PROPERTY_COLUMN_TYPE,
} from 'config/modelView';
import { SCENARIO_COMPARISON_STICKY_HEADER_HEIGHT_IN_PX } from 'config/scenarioComparison';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { timeSeriesColumnsForBlockSelector } from 'selectors/rollupSelector';

const ObjectFieldsHeaderRow: React.FC = () => {
  const { blockId } = useBlockContext();
  const columns = useAppSelector((state) => timeSeriesColumnsForBlockSelector(state, blockId));
  return (
    <StickyHeader stickyTop={SCENARIO_COMPARISON_STICKY_HEADER_HEIGHT_IN_PX}>
      <StickyColumnsAndTimeSeriesHeader columns={columns}>
        <Flex>
          <BlockHeaderCell
            columnType={PROPERTY_COLUMN_TYPE}
            label={COLUMN_TYPE_TO_NAME[PROPERTY_COLUMN_TYPE]}
          />
          <BlockHeaderCell
            columnType={INITIAL_VALUE_COLUMN_TYPE}
            label={COLUMN_TYPE_TO_NAME[INITIAL_VALUE_COLUMN_TYPE]}
          />
        </Flex>
      </StickyColumnsAndTimeSeriesHeader>
    </StickyHeader>
  );
};

export default React.memo(ObjectFieldsHeaderRow);
