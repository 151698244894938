import { Placement } from '@chakra-ui/react';
import Tippy, { TippyProps } from '@tippyjs/react';
import React, { useContext, useEffect, useRef } from 'react';

import BlockContext from 'components/BlockContainer/BlockContext';
import CellPalette from 'components/CellPalette/CellPalette';
import { CellPopoverReferenceContext } from 'components/CellPopoverReferenceContextProvider/CellPopoverReferenceContextProvider';
import PlanPicker from 'components/PlanPicker/PlanPicker';
import { EDITING_PILL_CLASS } from 'components/TimelinePill/TimelineImpactPill';
import { CELL_POPOVER_CLASS } from 'config/submodels';
import theme from 'config/theme';
import { BlockType } from 'generated/graphql';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { closePopover, setCellPaletteState } from 'reduxStore/reducers/pageSlice';
import { prevailingCellSelectionBlockSelector } from 'selectors/blocksSelector';
import {
  isCellPalettePopoverOpenSelector,
  isPlanPickerOpenSelector,
  multiPlanPickerSelectedEventGroupsSelector,
} from 'selectors/cellPaletteSelector';
import { pageGutterWidthInPxSelector } from 'selectors/pageSelector';
import { isPopoverOpenSelector, popoverTypeSelector } from 'selectors/popoverStateSelector';
import {
  isEditingPrevailingActiveCellSelector,
  prevailingCellSelectionBlockIdSelector,
} from 'selectors/prevailingCellSelectionSelector';

export const PLACEMENT_BY_POPOVER_TYPE: Record<
  'forecast' | 'objectTimeSeries' | 'cellPalette' | 'default',
  Placement
> = {
  forecast: 'bottom-end',
  objectTimeSeries: 'bottom-start',
  cellPalette: 'top',
  default: 'bottom-start',
};

export const OFFSET_BY_POPOVER_TYPE: Record<
  'forecast' | 'objectTimeSeries' | 'cellPalette' | 'default',
  TippyProps['offset']
> = {
  forecast: [12, 4],
  objectTimeSeries: undefined,
  cellPalette: [0, 4],
  default: undefined,
};

export const FLIP_POPPER_OPTIONS = {
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top-end'],
      },
    },
  ],
};

const CellPopover: React.FC = () => {
  const dispatch = useAppDispatch();
  const anchorRef = useContext(CellPopoverReferenceContext);
  const isOpen = useAppSelector(isPopoverOpenSelector) && anchorRef != null;
  const isCellPalettePopoverOpen = useAppSelector(isCellPalettePopoverOpenSelector);
  const isPlanPickerOpen = useAppSelector(isPlanPickerOpenSelector);
  const popoverType = useAppSelector(popoverTypeSelector);
  const blockId = useAppSelector(prevailingCellSelectionBlockIdSelector) ?? '';
  const gutterWidthInPx = useAppSelector(pageGutterWidthInPxSelector);
  const isEditingActiveCell = useAppSelector(isEditingPrevailingActiveCellSelector);
  const selectedBlock = useAppSelector(prevailingCellSelectionBlockSelector);
  const multiPlanPickerSelectedEventGroups = useAppSelector(
    multiPlanPickerSelectedEventGroupsSelector,
  );

  const isDatabaseBlockSelected = selectedBlock?.type === BlockType.ObjectTable;

  const primaryPopoverContent = useRef<HTMLDivElement>(null);
  const planPickerPopoverContent = useRef<HTMLDivElement>(null);

  const shouldNavigateToDefaultState =
    isPlanPickerOpen &&
    isEditingActiveCell &&
    (multiPlanPickerSelectedEventGroups?.length ?? 0) > 0;

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // Do nothing if clicking popover content or anchor
      if (
        !isOpen ||
        !primaryPopoverContent.current ||
        primaryPopoverContent.current.contains(target) ||
        planPickerPopoverContent.current?.contains(target) ||
        anchorRef.contains(target) ||
        target.closest(`.${CELL_POPOVER_CLASS}`) != null ||
        target.closest(`.${EDITING_PILL_CLASS}`) != null
      ) {
        return;
      }

      if (shouldNavigateToDefaultState) {
        setTimeout(() => {
          dispatch(setCellPaletteState('default'));
        }, 0);
        return;
      }

      // N.B. let an onBlur propagate to a save before clearing state.
      setTimeout(() => {
        dispatch(closePopover());
      }, 0);
    };

    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [
    primaryPopoverContent,
    dispatch,
    isOpen,
    anchorRef,
    planPickerPopoverContent,
    isCellPalettePopoverOpen,
    shouldNavigateToDefaultState,
  ]);

  if (!isOpen) {
    return null;
  }

  // We have a different way of showing the cell palette and plan picker for database tables.
  // The object table row popover is still handled globally
  const isMainPopoverVisible = !isDatabaseBlockSelected && isCellPalettePopoverOpen;
  const isPlanPickerVisible = !isDatabaseBlockSelected && isPlanPickerOpen;

  return (
    <BlockContext blockId={blockId} gutterWidthInPx={gutterWidthInPx}>
      <>
        <Tippy
          interactive
          placement={PLACEMENT_BY_POPOVER_TYPE[popoverType ?? 'default']}
          offset={OFFSET_BY_POPOVER_TYPE[popoverType ?? 'default']}
          appendTo={document.body}
          visible={isMainPopoverVisible}
          reference={anchorRef}
          popperOptions={FLIP_POPPER_OPTIONS}
          zIndex={theme.zIndices.popover}
          render={() =>
            isOpen && isCellPalettePopoverOpen ? <CellPalette ref={primaryPopoverContent} /> : null
          }
        />
        <Tippy
          interactive
          placement="bottom-start"
          offset={OFFSET_BY_POPOVER_TYPE[popoverType ?? 'default']}
          appendTo={document.body}
          visible={isPlanPickerVisible}
          reference={anchorRef}
          zIndex={theme.zIndices.popover}
          render={() =>
            isPlanPickerVisible ? (
              <PlanPicker
                ref={planPickerPopoverContent}
                isEditingActiveCell={isEditingActiveCell}
              />
            ) : null
          }
        />
      </>
    </BlockContext>
  );
};

export default React.memo(CellPopover);
