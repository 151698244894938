import { ChakraProvider } from '@chakra-ui/react';
import { CacheProvider } from '@emotion/react';
import { LDProvider, basicLogger } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { Provider } from 'react-redux';

import AnalyticsInitializer from 'components/AnalyticsInitializer/AnalyticsInitializer';
import ErrorBoundary from 'components/ErrorBoundary';
import LaunchDarklySyncer from 'components/LaunchDarklySyncer/LaunchDarklySyncer';
import ServerHealth from 'components/ServerHealth/ServerHealth';
import { launchDarklyClientSideId } from 'config/launchDarkly';
import theme from 'config/theme';
import { isUserBrowser } from 'helpers/environment';
import { RequestQueue, RequestQueueContextProvider } from 'helpers/requests';
import { useEmotionCache } from 'hooks/useEmotionCache';
import type { ReduxStore } from 'reduxStore/store';

interface Props {
  children: React.ReactElement;
  store: ReduxStore;
  requestQueue: RequestQueue;
}

const useLaunchDarkly = isUserBrowser();

const AppShell: React.FC<Props> = ({ children, store, requestQueue }) => {
  const emotionCache = useEmotionCache();
  return (
    <CacheProvider value={emotionCache}>
      <ChakraProvider theme={theme} resetCSS>
        <RequestQueueContextProvider initialValue={requestQueue}>
          <Provider store={store}>
            <ErrorBoundary>
              <ServerHealth />
              <LDProvider
                clientSideID={launchDarklyClientSideId}
                deferInitialization={!useLaunchDarkly}
                timeout={2}
                options={{
                  // Avoid logging garbage to the console.
                  logger: basicLogger({ level: 'warn' }),
                }}
              >
                {children}
                <LaunchDarklySyncer />
                <AnalyticsInitializer />
              </LDProvider>
            </ErrorBoundary>
          </Provider>
        </RequestQueueContextProvider>
      </ChakraProvider>
    </CacheProvider>
  );
};

export default AppShell;
