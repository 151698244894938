import sizeof from 'object-sizeof';
import prettyBytes from 'pretty-bytes';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import { DatasetSliceState } from 'reduxStore/reducers/datasetSlice';
// eslint-disable-next-line import/order
import { ReduxStore } from 'reduxStore/store';

type SizeEntry = string | { totalSize: string; sizes: NullableRecord<string, SizeEntry> };

export const getDatasetSize = (
  dataset: DatasetSliceState,
): {
  totalSizeInBytes: number;
  totalSize: string;
  sizes: NullableRecord<string, SizeEntry>;
} => {
  const datasetSizes: NullableRecord<string, SizeEntry> = {};
  let totalDatasetSize = 0;

  const { layers, snapshots, ...rest } = dataset;

  for (const layer of Object.values(layers)) {
    const sizes: NullableRecord<string, string> = {};
    let totalSize = 0;

    for (const [key, value] of Object.entries(layer)) {
      const size = sizeof(value);
      sizes[key] = prettyBytes(size);
      totalSize += size;
    }

    datasetSizes[`layer.${layer.id}`] = {
      totalSize: prettyBytes(totalSize),
      sizes,
    };
    totalDatasetSize += totalSize;
  }

  for (const [id, snapshot] of Object.entries(snapshots)) {
    const sizes: NullableRecord<string, string> = {};
    let totalSize = 0;

    for (const [key, value] of Object.entries(snapshot as object)) {
      const size = sizeof(value);
      sizes[key] = prettyBytes(size);
      totalSize += size;
    }

    datasetSizes[`snapshot.${id}`] = {
      totalSize: prettyBytes(totalSize),
      sizes,
    };
    totalDatasetSize += totalSize;
  }

  for (const [key, entry] of Object.entries(rest)) {
    const size = sizeof(entry);
    datasetSizes[key] = prettyBytes(size);
    totalDatasetSize += size;
  }

  return {
    totalSize: prettyBytes(totalDatasetSize),
    totalSizeInBytes: totalDatasetSize,
    sizes: datasetSizes,
  };
};

export const initMemoryHelpers = (store: ReduxStore) => {
  const helpers = {
    getStoreMemoryOverhead() {
      const s = store.getState();
      let totalStoreSizeInBytes = 0;
      const sizes: NullableRecord<string, SizeEntry> = {};

      const calculationsSize = sizeof(s.calculations);
      sizes.calculations = prettyBytes(calculationsSize);
      totalStoreSizeInBytes += calculationsSize;

      const dataArbiterSize = DataArbiter.get().getCacheSize();
      sizes.dataArbiterCache = prettyBytes(dataArbiterSize);
      totalStoreSizeInBytes += calculationsSize;

      const datasetSize = getDatasetSize(s.dataset);
      sizes.dataset = {
        totalSize: datasetSize.totalSize,
        sizes: datasetSize.sizes,
      };
      totalStoreSizeInBytes += datasetSize.totalSizeInBytes;

      const sharingSize = sizeof(s.sharing);
      sizes.sharing = prettyBytes(sharingSize);
      totalStoreSizeInBytes += sharingSize;

      // eslint-disable-next-line no-console
      console.log({
        sizes,
        totalStoreSize: prettyBytes(totalStoreSizeInBytes),
      });
    },
  };

  window.memoryHelpers = helpers;
  return helpers;
};

import { isUserBrowser } from 'helpers/environment';

type MemoryInfo = {
  totalJSHeapSize: number;
  usedJSHeapSize: number;
  jsHeapSizeLimit: number;
};

type MemoryAttribution = {
  scope:
    | 'Window'
    | 'DedicatedWorkerGlobalScope'
    | 'SharedWorkerGlobalScope'
    | 'ServiceWorkerGlobalScope';
  url: string;
};

type MemoryBreakdown = {
  bytes: number;
  types: string[];
  attribution: MemoryAttribution[];
};

type MemorySample = {
  breakdown: MemoryBreakdown[];
  bytes: number;
};

class MemoryMonitor {
  // see https://developer.mozilla.org/en-US/docs/Web/API/Performance/memory
  // Note that the information this API provides is unreliable as it might overestimate actual memory usage if web pages share the same heap, or might underestimate actual memory usage if web pages use workers and/or cross-site iframes that are allocated in separate heaps. It is not standardized what "heap" means exactly. The API is only available in Chromium-based browsers.
  getJSHeapSize(): MemoryInfo | undefined {
    if (
      !isUserBrowser() ||
      !('memory' in window.performance) ||
      typeof window.performance.memory !== 'object'
    ) {
      return undefined;
    }

    const { totalJSHeapSize, usedJSHeapSize, jsHeapSizeLimit } = window.performance
      .memory as MemoryInfo;

    return {
      totalJSHeapSize,
      usedJSHeapSize,
      jsHeapSizeLimit,
    };
  }

  async getDetailedMemorySample(): Promise<
    | {
        domBytes: number | undefined;
        jsBytes: number | undefined;
        sharedBytes: number | undefined;
        totalBytes: number | undefined;
      }
    | undefined
  > {
    // see https://web.dev/articles/monitor-total-page-memory-usage
    // performance.measureUserAgentSpecificMemory() is only available in cross-origin-isolated pages &
    // is not available in all browsers. not all images we depend on support CORS, so we can't set the
    // appropriate headers to enable cross-origin-isolation. a Cross-Origin-Embedder-Policy header set
    // to 'require-corp' results in 403 errors loading some images.
    if (
      !isUserBrowser() ||
      !window.crossOriginIsolated ||
      !('measureUserAgentSpecificMemory' in window.performance) ||
      typeof window.performance.measureUserAgentSpecificMemory !== 'function'
    ) {
      return undefined;
    }

    let memorySample: MemorySample | undefined;
    try {
      memorySample = (await window.performance.measureUserAgentSpecificMemory()) as MemorySample;
    } catch (error) {
      return undefined;
    }

    const domBytes = memorySample.breakdown.find(
      (b) => b.types.length === 1 && b.types[0] === 'DOM',
    )?.bytes;
    const sharedBytes = memorySample.breakdown.find(
      (b) => b.types.length === 1 && b.types[0] === 'Shared',
    )?.bytes;
    const jsBytes = memorySample.breakdown.find(
      (b) =>
        b.types.length === 1 &&
        b.types[0] === 'JavaScript' &&
        b.attribution.length === 1 &&
        b.attribution[0].scope === 'Window',
    )?.bytes;

    return {
      domBytes,
      sharedBytes,
      jsBytes,
      totalBytes: memorySample.bytes,
    };
  }
}

export const memoryMonitor = new MemoryMonitor();

// ts-prune does not realize that this is used in a .d.ts file
// ts-prune-ignore-next
export type MemoryHelpers = NonNullable<ReturnType<typeof initMemoryHelpers>>;
