import noop from 'lodash/noop';
import partition from 'lodash/partition';
import { Dispatch, createContext, useCallback, useReducer } from 'react';

import { safeObjGet } from 'helpers/typescript';
import { Attribute, DimensionId } from 'reduxStore/models/dimensions';
import { Driver, DriverId, DriverType } from 'reduxStore/models/drivers';

export const PLACEHOLDER_ATTRIBUTE_TYPE = 'placeholder';

export type AttributePlaceholder = {
  dimensionId: DimensionId;
  type: typeof PLACEHOLDER_ATTRIBUTE_TYPE;
};

export function isPlaceholderAttribute(
  attr?: Attribute | AttributePlaceholder,
): attr is AttributePlaceholder {
  return attr?.type === PLACEHOLDER_ATTRIBUTE_TYPE;
}

export function filterOutPlaceholderAttributes(
  attributes: Array<Attribute | AttributePlaceholder>,
): Attribute[] {
  return attributes.filter((a): a is Attribute => !isPlaceholderAttribute(a));
}

interface DraftSubDriver {
  attributes: Array<Attribute | AttributePlaceholder>;
  subDriverName: string;
  targetDriver: Driver | undefined;
}

type DraftSubDriverAction =
  | { type: 'toggleAttr'; data: Attribute | AttributePlaceholder }
  | { type: 'removeAttr'; data: Attribute }
  | { type: 'setSubDriverName'; data: string }
  | { type: 'setTargetDriver'; data: Driver | undefined }
  | { type: 'setAttrs'; data: Array<Attribute | AttributePlaceholder> };

export type DimDriverEditContext = {
  attributes: Array<Attribute | AttributePlaceholder>;
  driverId: DriverId | undefined;
  errorMessage: string | undefined;
  originalDriverName: string;
  subDriverName: string;
  targetDriver: Driver | undefined;
  originalDimDriver: Driver | undefined;

  onSave: (options?: { renamingOne?: boolean; focusOnExistingDriver?: boolean }) => void;
  removeAttr: (attr: Attribute) => void;
  setSubDriverName: (name: string) => void;
  setTargetDriver: (dimDriver: Driver | undefined) => void;
  toggleAttr: (attr: Attribute | AttributePlaceholder) => void;
};

export const DimDriverEditReactContext = createContext<DimDriverEditContext>({
  attributes: [],
  driverId: undefined,
  errorMessage: undefined,
  originalDriverName: '',
  subDriverName: '',
  targetDriver: undefined,
  originalDimDriver: undefined,

  onSave: noop,
  removeAttr: noop,
  setSubDriverName: noop,
  setTargetDriver: noop,
  toggleAttr: noop,
});

export const draftSubDriverReducer: (
  originalDimDriver: Driver | undefined,
) => React.Reducer<DraftSubDriver, DraftSubDriverAction> =
  (originalDimDriver: Driver | undefined) => (state, action) => {
    switch (action.type) {
      case 'setSubDriverName': {
        return {
          ...state,
          attributes: filterOutPlaceholderAttributes(state.attributes),
          subDriverName: action.data.trim(),
          targetDriver: originalDimDriver,
        };
      }
      case 'setTargetDriver': {
        if (action.data == null) {
          return { ...state, targetDriver: undefined };
        }
        const attributes = [...state.attributes];
        if (action.data.type === DriverType.Dimensional) {
          // Add placeholder attributes for matching dim driver
          action.data.dimensions.forEach((dim) => {
            const attrForDim = attributes.find((a) => a.dimensionId === dim.id);
            if (attrForDim == null) {
              attributes.push({
                dimensionId: dim.id,
                type: PLACEHOLDER_ATTRIBUTE_TYPE,
              });
            }
          });
        }
        return { subDriverName: action.data.name, attributes, targetDriver: action.data };
      }
      case 'setAttrs': {
        const newAttrs = action.data;
        return {
          ...state,
          attributes: newAttrs,
        };
      }
      case 'toggleAttr': {
        const newAttr = action.data;
        const [existingAttrs, attrsWithoutDim] = partition(
          state.attributes,
          (a) => a.dimensionId === newAttr.dimensionId,
        );
        // there should be at most one
        const existingAttr = safeObjGet(existingAttrs[0]);
        // if it is the same item, remove it
        const isToggleOffPlaceholder =
          newAttr.type === PLACEHOLDER_ATTRIBUTE_TYPE &&
          existingAttr?.type === PLACEHOLDER_ATTRIBUTE_TYPE;
        const isToggleOffAttr =
          newAttr.type !== PLACEHOLDER_ATTRIBUTE_TYPE &&
          existingAttr?.type !== PLACEHOLDER_ATTRIBUTE_TYPE &&
          newAttr.id === existingAttr?.id;
        if (isToggleOffAttr || isToggleOffPlaceholder) {
          return {
            ...state,
            attributes: attrsWithoutDim,
          };
        }

        return {
          ...state,
          attributes: [...attrsWithoutDim, newAttr],
        };
      }
      case 'removeAttr':
        return {
          ...state,
          attributes: state.attributes.filter(
            (a) => !isPlaceholderAttribute(a) && a.id !== action.data.id,
          ),
        };
      default:
        return state;
    }
  };

export const useDraftSubDriver = (
  initialState: DraftSubDriver,
): DraftSubDriver & {
  draftSubDriverDispatch: Dispatch<DraftSubDriverAction>;
  toggleAttr: DimDriverEditContext['toggleAttr'];
  removeAttr: DimDriverEditContext['removeAttr'];
  setSubDriverName: DimDriverEditContext['setSubDriverName'];
  setTargetDriver: DimDriverEditContext['setTargetDriver'];
} => {
  const [state, draftSubDriverDispatch] = useReducer(
    draftSubDriverReducer(initialState.targetDriver),
    initialState,
  );

  const toggleAttr = useCallback(
    (attr: Attribute | AttributePlaceholder) => {
      draftSubDriverDispatch({ type: 'toggleAttr', data: attr });
    },
    [draftSubDriverDispatch],
  );

  const setSubDriverName = useCallback(
    (name: string) => {
      draftSubDriverDispatch({
        type: 'setSubDriverName',
        data: name,
      });
    },
    [draftSubDriverDispatch],
  );

  const setTargetDriver = useCallback(
    (dimDriver: Driver | undefined) => {
      draftSubDriverDispatch({
        type: 'setTargetDriver',
        data: dimDriver,
      });
    },
    [draftSubDriverDispatch],
  );

  const removeAttr = useCallback(
    (attr: Attribute) => draftSubDriverDispatch({ type: 'removeAttr', data: attr }),
    [draftSubDriverDispatch],
  );

  return {
    ...state,
    draftSubDriverDispatch,
    toggleAttr,
    removeAttr,
    setSubDriverName,
    setTargetDriver,
  };
};

export const DIM_DRIVER_ERROR_MESSAGES = {
  emptyName: 'Driver name cannot be empty',
  conflict: 'Driver already exists - please rename or edit attributes',
};
