import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'FacebookIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <g clipPath="url(#clip0_48_634)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60 30C60 13.4327 46.5673 0 30 0C13.4327 0 0 13.4327 0 30C0 44.9725 10.969 57.3843 25.313 59.6374V38.674H17.6937V30H25.313V23.3891C25.313 15.8711 29.7933 11.7155 36.6446 11.7155C39.9268 11.7155 43.361 12.3019 43.361 12.3019V19.685H39.5766C35.8515 19.685 34.687 21.9969 34.687 24.372V29.9998H43.0064L41.6775 38.6737H34.6868V59.6371C49.0308 57.3882 59.9998 44.9765 59.9998 29.9998L60 30Z"
          fill="#1977F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.6777 38.6737L43.0067 29.9998H34.6873V24.372C34.6873 22.0011 35.8474 19.685 39.5769 19.685H43.3613V12.3019C43.3613 12.3019 39.9273 11.7155 36.6449 11.7155C29.7936 11.7155 25.3132 15.8669 25.3132 23.3891V30H17.694V38.674H25.3132V59.6374C26.8404 59.8775 28.4056 60 30.0003 60C31.595 60 33.1602 59.8734 34.6873 59.6374V38.674H41.678L41.6777 38.6737Z"
          fill="#FEFEFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_48_634">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
