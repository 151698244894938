import { ArrowDownIcon, ArrowUpIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { IHeaderGroupParams, IHeaderParams } from 'ag-grid-enterprise';
import React, { useCallback, useContext, useMemo, useRef } from 'react';

import { Tooltip } from 'chakra/tooltip';
import { useDatabaseContextMenu } from 'components/AgGridComponents/DatabaseTableAgGrid/DatabaseContextMenu';
import { ADD_COLUMN_TYPE } from 'components/AgGridComponents/helpers/deriveColumnDef';
import { ColumnDef, ColumnGroupDef } from 'components/AgGridComponents/types/DatabaseColumnDef';
import RestrictedColumnHeaderWrapper from 'components/BusinessObjectTable/RestrictedColumnHeaderWrapper';
import { useHasLookupError } from 'components/BusinessObjectTable/SettingsPopoverContents/useContextMenuItems/LookupStatusIcon';
import { useContextMenu } from 'components/ContextMenu/ContextMenu';
import EditFormulaIcon from 'components/EditFormulaIcon/EditFormulaIcon';
import ObjectFieldTypeIcon from 'components/ObjectFieldTypeIcon/ObjectFieldTypeIcon';
import PlusIconButton from 'components/PlusIconButton/PlusIconButton';
import TimeSeriesHeaderLabel from 'components/TimeSeriesHeaderLabel/TimeSeriesHeaderLabel';
import { DatabaseTableContext } from 'config/databaseTableContext';
import { COLUMN_TYPE_TO_NAME, NAME_COLUMN_TYPE, OPTIONS_COLUMN_TYPE } from 'config/modelView';
import {
  BlockComparisonLayout,
  ComparisonColumn,
  ObjectSortOrder,
  ValueType,
} from 'generated/graphql';
import { getComparisonColumnName } from 'helpers/blockComparisons';
import { getDateTimeFromMonthKey, isValidMonthKey, shortMonthFormat } from 'helpers/dates';
import { safeObjGet } from 'helpers/typescript';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useDatabasePropertyType } from 'hooks/useDatabasePropertyType';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { DriverPropertyId } from 'reduxStore/models/collections';
import { setEditingPropertyFormula } from 'reduxStore/reducers/blockLocalStateSlice';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { isEditingPropertyFormulaForColumnSelector } from 'selectors/blockLocalStateSelector';
import { blockConfigSelector } from 'selectors/blocksSelector';
import { hasBusinessObjectFieldSpecRestrictsSelector } from 'selectors/businessObjectFieldSpecRestrictedSelector';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { hasBusinessObjectNameRestrictionsSelector } from 'selectors/businessObjectNameRestrictionsSelector';
import { isNewDimensionalTableSelector } from 'selectors/collectionBlocksSelector';
import {
  dimensionalPropertySelector,
  driverPropertySelector,
  keyDimensionalPropertiesForBusinessObjectSpecSelector,
} from 'selectors/collectionSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import { dimDriverSelector, driversByIdForLayerSelector } from 'selectors/driversSelector';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';
import { enableDefaultFormulaSelector } from 'selectors/launchDarklySelector';
import { currentLayerIdSelector, layerNameByIdSelector } from 'selectors/layerSelector';
import { objectTableBlockFieldSortOrderSelector } from 'selectors/objectTableBlockSelector';
import { resourceHasAccessEntriesSelector } from 'selectors/restrictedResourcesSelector';
import { allComparisonLayersAreNamedVersionsForBlockSelector } from 'selectors/rollupSelector';
import { MonthKey } from 'types/datetime';
import TextIcon from 'vectors/Text';

const useErrorMessage = ({ fieldSpecId }: { fieldSpecId: BusinessObjectFieldSpecId }) => {
  const dimensionsById = useAppSelector(dimensionsByIdSelector);
  const fieldSpec = useAppSelector((state) =>
    businessObjectFieldSpecSelector(state, { id: fieldSpecId }),
  );
  const driverProperty = useAppSelector((state) => driverPropertySelector(state, fieldSpecId));
  const driversById = useAppSelector(driversByIdForLayerSelector);
  const dimensionalProperty = useAppSelector((state) =>
    dimensionalPropertySelector(state, fieldSpecId),
  );

  const regularErrorMessage = useMemo(() => {
    const fieldDimId = fieldSpec?.type === ValueType.Attribute ? fieldSpec.dimensionId : undefined;
    if (fieldDimId == null) {
      return undefined;
    }

    const dim = safeObjGet(dimensionsById[fieldDimId]);

    return dim == null || dim.deleted ? 'Dimension deleted' : undefined;
  }, [fieldSpec, dimensionsById]);

  const { objectSpecId: databaseId } = useContext(DatabaseTableContext);
  const hasLookupError = useHasLookupError(databaseId, dimensionalProperty);
  const dimensionalPropertyErrorMsg = useMemo(() => {
    if (hasLookupError) {
      return 'Could not find the lookup for this column';
    }

    if (dimensionalProperty == null) {
      return undefined;
    }

    if (dimensionalProperty.dimension == null) {
      return 'Dimensional property not found';
    }

    const dim = safeObjGet(dimensionsById[dimensionalProperty.dimension.id]);

    return dim == null || dim.deleted ? 'Dimension deleted' : undefined;
  }, [dimensionalProperty, dimensionsById, hasLookupError]);

  const driverPropertyErrorMsg = useMemo(() => {
    if (driverProperty == null) {
      return undefined;
    }

    if (driverProperty.driverId == null) {
      return 'Driver property not found';
    }

    const driver = safeObjGet(driversById[driverProperty.driverId]);

    return driver == null ? 'Driver not found' : undefined;
  }, [driverProperty, driversById]);

  const propertyType = useDatabasePropertyType({ fieldSpecId });
  switch (propertyType) {
    case 'dimensionalProperty':
      return dimensionalPropertyErrorMsg;
    case 'driverProperty':
      return driverPropertyErrorMsg;
    case 'nameProperty':
    case 'legacyProperty':
    default:
      return regularErrorMessage;
  }
};

const MonthHeaderComponent: React.FC<{
  monthKey: MonthKey;
  refreshCells?: () => void;
}> = React.memo(({ monthKey, refreshCells }) => {
  const lastActualsMonthKey = useAppSelector(datasetLastActualsMonthKeySelector);
  const isActuals = monthKey <= lastActualsMonthKey;

  const onChangeLastClose = useCallback(() => {
    if (refreshCells == null) {
      return;
    }
    setTimeout(() => {
      refreshCells();
    }, 0);
  }, [refreshCells]);

  return (
    <Flex height="full" width="full" pr={2}>
      <TimeSeriesHeaderLabel
        label={shortMonthFormat(getDateTimeFromMonthKey(monthKey))}
        isLastActualsMonth={monthKey === lastActualsMonthKey}
        isActuals={isActuals}
        onChangeLastClose={onChangeLastClose}
      />
    </Flex>
  );
});

export const HeaderBaseComponent: React.FC<{
  displayName: string;
  fieldSpecId: BusinessObjectFieldSpecId;
  objectSpecId: BusinessObjectSpecId;
  isGroupHeader: boolean;
}> = React.memo(({ displayName, fieldSpecId, objectSpecId, isGroupHeader }) => {
  const dispatch = useAppDispatch();
  const { blockId, readOnly } = useBlockContext();
  const setDatabaseContextMenuIds = useDatabaseContextMenu();
  const { canWriteDatabase } = useAppSelector(accessCapabilitiesSelector);

  const isNameColumn = fieldSpecId === NAME_COLUMN_TYPE;

  const isFormula = useAppSelector(
    (state) => businessObjectFieldSpecSelector(state, { id: fieldSpecId })?.isFormula,
  );

  const isEditingFormula = useAppSelector((state) =>
    isEditingPropertyFormulaForColumnSelector(state, { blockId, columnKey: fieldSpecId }),
  );

  const sortOrder = useAppSelector((state) =>
    objectTableBlockFieldSortOrderSelector(state, blockId, fieldSpecId),
  );

  const hasNameRestrictions = useAppSelector((state) =>
    hasBusinessObjectNameRestrictionsSelector(state, objectSpecId),
  );
  const hasFieldRestrictions = useAppSelector(
    (state) =>
      hasBusinessObjectFieldSpecRestrictsSelector(state, fieldSpecId) ||
      resourceHasAccessEntriesSelector(state, fieldSpecId),
  );
  const hasRestrictions = isNameColumn ? hasNameRestrictions : hasFieldRestrictions;

  const driverProperty = useAppSelector((state) => driverPropertySelector(state, fieldSpecId));
  const isDriverProperty = driverProperty != null;

  const keyDimProps = useAppSelector((state) =>
    keyDimensionalPropertiesForBusinessObjectSpecSelector(state, objectSpecId),
  );

  const errorMessage = useErrorMessage({ fieldSpecId });

  // TODO: this check is only needed to avoid affecting existing non-dimensional tables
  // Once all models are migrated, we can remove this.
  // This flag effectively turns this cell into an ID header.
  const isDimensionalTable = useAppSelector((state) =>
    isNewDimensionalTableSelector(state, blockId),
  );

  const { canWritePermissions } = useAccessCapabilities();
  const isReadOnly = !canWritePermissions || (isNameColumn && isDimensionalTable);

  const { openContextMenu } = useContextMenu();
  const ref = useRef<HTMLDivElement>(null);
  const onClick = useCallback(
    (event?: React.MouseEvent) => {
      if (isReadOnly) {
        return;
      }

      openContextMenu(event);
      event?.preventDefault();
      event?.stopPropagation();
      setDatabaseContextMenuIds({ fieldSpecId, objectSpecId });
    },
    [fieldSpecId, isReadOnly, objectSpecId, openContextMenu, setDatabaseContextMenuIds],
  );

  const onClickFormulaButton = useCallback(() => {
    if (isReadOnly) {
      return;
    }
    dispatch(
      setEditingPropertyFormula({
        blockId,
        fieldSpecId,
        groupKey: undefined,
        objectId: undefined,
      }),
    );
    onClick();
  }, [blockId, dispatch, fieldSpecId, isReadOnly, onClick]);

  if (fieldSpecId === OPTIONS_COLUMN_TYPE) {
    return <Box ref={ref} width="full" height="full" />;
  }

  if (fieldSpecId === ADD_COLUMN_TYPE) {
    if (!canWriteDatabase || readOnly) {
      return <Box ref={ref} width="full" height="full" />;
    }

    return (
      <Flex
        ref={ref}
        width="full"
        height="full"
        justifyContent="center"
        alignItems="flex-end"
        paddingBottom={1}
        onClick={onClick}
        onContextMenu={onClick}
        data-testid="create-new-property-button"
      >
        <Tooltip label="Create new property" placement="top" openDelay={1000}>
          <PlusIconButton aria-label="Create new property" />
        </Tooltip>
      </Flex>
    );
  }

  const isFormulaHeader =
    displayName === COLUMN_TYPE_TO_NAME.formula ||
    displayName === COLUMN_TYPE_TO_NAME.actualsFormula;

  return (
    <Flex
      ref={ref}
      w={isGroupHeader ? undefined : 'full'}
      h="full"
      data-testid="table-header-cell"
      justifyContent="flex-end"
      cursor={!isReadOnly ? 'pointer' : 'default'}
      onClick={onClick}
      onContextMenu={onClick}
      px={2}
      direction="column"
      className={!isReadOnly ? 'ag-header-hover' : ''}
    >
      <Flex
        // We set a min height to ensure the headers look the same whether there's a formula icon or not.
        minH={8}
        data-testid={`table-header-cell-${displayName}`}
        w="full"
        justify="space-between"
        align="center"
      >
        <RestrictedColumnHeaderWrapper hasRestrictions={hasRestrictions}>
          <Center flex={0}>
            {isNameColumn ? <TextIcon /> : <ObjectFieldTypeIcon fieldSpecId={fieldSpecId} />}
          </Center>
          <Text noOfLines={1} whiteSpace="nowrap" size="xs" fontWeight="medium" color="gray.500">
            {displayName}
          </Text>
        </RestrictedColumnHeaderWrapper>
        <Flex>
          {isFormula && (
            <EditFormulaIcon
              showSavedCheckmark={false}
              hasSavedError={false}
              isEditing={isEditingFormula}
              onClick={onClickFormulaButton}
            />
          )}
          {sortOrder != null && (
            <Flex h="full" alignItems="center">
              {sortOrder === ObjectSortOrder.Asc ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </Flex>
          )}
          {errorMessage != null && (
            <Tooltip placement="right" label={errorMessage}>
              <WarningTwoIcon ml={2} color="failure" />
            </Tooltip>
          )}
          {isDriverProperty && !isFormulaHeader && (
            <>
              {keyDimProps.length === 0 && (
                <Box data-testid="no-keys-warning-icon">
                  <Tooltip label="To use drivers in this database, select at least one dimension to segment drivers by">
                    <WarningIcon ml={2} color="yellow.500" />
                  </Tooltip>
                </Box>
              )}
              <DatabaseHeaderFormulaButton driverPropertyId={driverProperty.id} />
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
});

HeaderBaseComponent.displayName = 'HeaderBaseComponent';

export const HeaderComponent: React.FC<IHeaderParams> = React.memo((props) => {
  const { column, displayName, api } = props;
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const layerNameById = useAppSelector(layerNameByIdSelector);
  const { blockId } = useBlockContext();
  const blockConfig = useAppSelector((state) => blockConfigSelector(state, blockId));
  const areNamedVersions = useAppSelector((state) =>
    allComparisonLayersAreNamedVersionsForBlockSelector(state, blockId),
  );
  const hasComparison = (blockConfig?.comparisons?.layerIds ?? []).length > 0;
  const comparisonLayout = blockConfig?.comparisons?.layout;

  const colDef = column.getColDef() as ColumnDef;
  const {
    objectFieldSpecId,
    objectSpecId,
    monthKey,
    layerId = currentLayerId,
    comparisonType,
    valueType,
    displayAs,
  } = colDef.fieldSpec;

  const isColumnLayout =
    comparisonLayout == null || comparisonLayout === BlockComparisonLayout.Columns;
  if (hasComparison && (displayAs === 'timeseries' || valueType === 'formula')) {
    const text = getComparisonColumnName({
      subLabel: isColumnLayout
        ? { layerId }
        : { column: comparisonType ?? ComparisonColumn.RowVersion },
      areNamedVersions,
      layerNameById,
    });
    return <SimpleTextHeader displayName={text} />;
  }

  if (monthKey != null) {
    return <MonthHeaderComponent monthKey={monthKey} refreshCells={api.refreshCells} />;
  }
  return (
    <HeaderBaseComponent
      displayName={displayName}
      fieldSpecId={objectFieldSpecId}
      objectSpecId={objectSpecId}
      isGroupHeader={false}
    />
  );
});

HeaderComponent.displayName = 'HeaderComponent';

// For some reason the colDef returned by this sometimes has its fieldSpec undefined,
// so return a Partial<ColDef> to protect the rest of the code
const getLeafColDef = (colDef: ColumnDef | ColumnGroupDef): Partial<ColumnDef> | undefined => {
  if ('children' in colDef) {
    if (colDef.children.length === 0) {
      return undefined;
    }
    return getLeafColDef(colDef.children[0] as ColumnDef | ColumnGroupDef);
  }
  return colDef;
};

export const HeaderGroupComponent: React.FC<IHeaderGroupParams> = React.memo((props) => {
  const { columnGroup, displayName, api } = props;

  const headerColDef = columnGroup.getColGroupDef() as ColumnGroupDef;

  if (headerColDef.headerGroupType === 'comparison') {
    if (isValidMonthKey(displayName)) {
      return <MonthHeaderComponent monthKey={displayName} refreshCells={api.refreshCells} />;
    }
    return <SimpleTextHeader displayName={displayName} />;
  }

  const colDef = getLeafColDef(headerColDef);
  if (colDef == null) {
    return null;
  }

  const objectFieldSpecId = colDef.fieldSpec?.objectFieldSpecId;
  const objectSpecId = colDef.fieldSpec?.objectSpecId;

  if (objectFieldSpecId == null || objectSpecId == null) {
    return null;
  }

  return (
    <HeaderBaseComponent
      displayName={displayName}
      fieldSpecId={objectFieldSpecId}
      objectSpecId={objectSpecId}
      isGroupHeader
    />
  );
});

HeaderGroupComponent.displayName = 'HeaderGroupComponent';

const SimpleTextHeader: React.FC<{ displayName: string }> = ({ displayName }) => {
  return (
    <Text width="full" px={2} overflow="hidden" fontWeight="medium" color="gray.500">
      {displayName}
    </Text>
  );
};

interface FormulaButtonProps {
  driverPropertyId: DriverPropertyId;
}

export const DatabaseHeaderFormulaButton: React.FC<FormulaButtonProps> = ({ driverPropertyId }) => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const onClick: React.MouseEventHandler = useCallback(() => {
    dispatch(
      setEditingPropertyFormula({
        blockId,
        fieldSpecId: driverPropertyId,
        groupKey: '',
        objectId: undefined,
      }),
    );
  }, [blockId, dispatch, driverPropertyId]);
  const enableDefaultFormula = useAppSelector(enableDefaultFormulaSelector);
  const driverProperty = useAppSelector((state) => driverPropertySelector(state, driverPropertyId));
  const isDriverProperty = driverProperty != null;
  const dimDriver = useAppSelector((state) =>
    driverProperty != null ? dimDriverSelector(state, { id: driverProperty.driverId }) : null,
  );
  const hasDefaultFormula =
    (dimDriver?.defaultActuals?.formula != null && dimDriver.defaultActuals.formula !== '') ||
    (dimDriver?.defaultForecast?.formula != null && dimDriver.defaultForecast.formula !== '');

  if (!enableDefaultFormula || !isDriverProperty || !hasDefaultFormula) {
    return null;
  }
  return (
    <EditFormulaIcon
      onClick={onClick}
      showSavedCheckmark={false}
      isEditing={false}
      hasSavedError={false}
    />
  );
};
