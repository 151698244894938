import { DateTime } from 'luxon';

import { emptyToUndefined } from 'helpers/string';

export const CLIENT_VERSION = process.env.NEXT_PUBLIC_GITHUB_COMMIT_SHA;

const CLIENT_COMMIT_TIME = process.env.NEXT_PUBLIC_COMMIT_TIME;

export const COMMIT_TIME = Number(
  emptyToUndefined(CLIENT_COMMIT_TIME) ?? String(DateTime.now().toUnixInteger()),
);

export const COMMIT_DATETIME = DateTime.fromSeconds(COMMIT_TIME);
