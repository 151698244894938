import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'ZuoraIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <g clipPath="url(#clip0_50_714)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9444 59.6017H14.1426C12.2773 59.6017 10.6957 58.467 10.0428 56.761C9.39388 55.0589 9.96901 53.1431 11.4146 52.0084L19.3926 45.5499H4.43922C2.57005 45.5499 0.988445 44.4152 0.339481 42.7092C-0.309483 41.0071 0.195699 39.1613 1.63352 37.9566L17.0882 25.2532C18.3783 24.1846 20.2475 24.3983 21.3317 25.6108C22.412 26.8892 22.1944 28.7351 20.9664 29.796L9.04414 39.6509H26.7993L50.9509 20.2014H10.1206C7.45865 20.2014 4.9444 19.1367 3.0014 17.2947C1.13223 15.4488 0.125751 12.9657 0.125751 10.3387C0.125751 7.71179 1.13223 5.22863 3.07912 3.31282C4.9444 1.46697 7.46642 0.398315 10.1206 0.398315H44.9081C46.7773 0.398315 48.3589 1.53303 49.0079 3.23899C49.6568 4.94106 49.0856 6.78692 47.64 7.99158L39.8796 14.3141H55.5519C57.4211 14.3141 58.9988 15.4488 59.6517 17.1509C60.2928 18.8569 59.7255 20.7727 58.2877 21.9074L36.2151 39.6509H49.9444C52.6102 39.6509 55.1206 40.7196 56.9897 42.5615C58.855 44.4074 59.9392 46.8905 59.9392 49.5835V49.7273C59.9392 52.3543 58.9327 54.8374 56.9897 56.7532C55.1206 58.6108 52.6024 59.6017 49.9444 59.6017ZM18.8175 53.7105H49.9444C51.0286 53.7105 52.0351 53.2869 52.82 52.5758C53.6089 51.7947 53.9703 50.8038 53.9703 49.7351V49.5913C53.9703 48.5266 53.539 47.5317 52.82 46.7545C52.0273 45.9734 51.0208 45.6198 49.9444 45.6198H28.8783L18.8175 53.7105ZM10.1206 6.28562C9.03637 6.28562 8.03378 6.7092 7.24492 7.42034C6.45217 8.20142 6.09466 9.19236 6.09466 10.261C6.09466 11.3297 6.52601 12.3206 7.24492 13.0978C8.03767 13.8789 9.04414 14.2364 10.1206 14.2364H30.4599L40.3071 6.28562H10.1206Z"
          fill="#00D2B9"
        />
      </g>
      <defs>
        <clipPath id="clip0_50_714">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
