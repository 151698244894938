import { createCachedSelector } from 're-reselect';

import { extDriverSourceDisplayName } from 'helpers/integrations';
import { isNotNull } from 'helpers/typescript';
import { MutationAffectedEntityType, MutationBatchAction } from 'reduxStore/models/mutations';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  blocksPageNameSelector,
  blocksPagesByBlockIdSelector,
} from 'selectors/blocksPagesSelector';
import { blockNameSelector, blockSelector } from 'selectors/blocksSelector';
import { businessObjectSpecNameSelector } from 'selectors/businessObjectSpecsSelector';
import {
  businessObjectNameSelector,
  businessObjectSpecForObjectIdSelector,
} from 'selectors/businessObjectsSelector';
import { fieldSelector } from 'selectors/constSelectors';
import { deletedIdentifiersSelector } from 'selectors/deletedIdentifierSelector';
import { dimensionSelector } from 'selectors/dimensionsSelector';
import { driverGroupsByIdSelector } from 'selectors/driverGroupSelector';
import { driverNameSelector, driverSelector } from 'selectors/driversSelector';
import { eventGroupSelector, eventSelector } from 'selectors/eventsAndGroupsSelector';
import { extDriverDisplayPathSelector, extDriverSelector } from 'selectors/extDriversSelector';
import { layerNameByIdSelector, namedDatasetVersionsByIdSelector } from 'selectors/layerSelector';
import { milestonesWithoutLiveEditsByIdSelector } from 'selectors/milestonesSelector';
import {
  submodelIdByBlockIdSelector,
  submodelNamesByIdSelector,
} from 'selectors/submodelPageSelector';
import { ParametricSelector } from 'types/redux';

type EntityTypeAndId = {
  type: MutationAffectedEntityType;
  id: string;
};

const mutationAffectedEntityPageInfoSelector: ParametricSelector<
  EntityTypeAndId,
  string | undefined
> = createCachedSelector(
  (state) => state,
  fieldSelector<EntityTypeAndId, 'type'>('type'),
  fieldSelector('id'),
  submodelIdByBlockIdSelector,
  (state, type, id, submodelIdByBlockId) => {
    switch (type) {
      case 'driver': {
        const driver = driverSelector(state, { id });
        const blockId = driver?.driverReferences?.find(
          (ref) => submodelIdByBlockId[ref.blockId] != null,
        )?.blockId;
        if (blockId == null) {
          return undefined;
        }

        const submodelId = submodelIdByBlockId[blockId];
        if (submodelId == null) {
          return undefined;
        }
        return submodelNamesByIdSelector(state)?.[submodelId];
      }
      case 'submodel': {
        return submodelNamesByIdSelector(state)?.[id];
      }
      case 'plan':
      case 'impact': {
        return 'Plans';
      }
      case 'businessObjectSpec': {
        return businessObjectSpecNameSelector(state, id);
      }
      case 'businessObject': {
        return businessObjectSpecForObjectIdSelector(state, id)?.name;
      }
      case 'block': {
        const block = blockSelector(state, id);
        if (block == null) {
          return undefined;
        }
        return blocksPageNameSelector(state, block.pageId);
      }
      case 'page': {
        return blocksPageNameSelector(state, id);
      }
      case 'extDriver': {
        const source = extDriverSelector(state, id)?.source;
        if (source == null) {
          return undefined;
        }
        return `${extDriverSourceDisplayName(source)} drivers`;
      }
      default:
        return undefined;
    }
  },
)((state, { type, id }) => `${type}.${id}`);

export const getMutationActionsPageNames = (state: RootState, actions: MutationBatchAction[]) => {
  if (actions == null) {
    return undefined;
  }
  const pageNames = new Set(
    actions.flatMap((action) => {
      if (action.entityIds == null) {
        return [];
      }
      return action.entityIds
        .map((entityId) => {
          if (action.entityType == null) {
            return undefined;
          }
          return mutationAffectedEntityPageInfoSelector(state, {
            type: action.entityType,
            id: entityId,
          });
        })
        .filter(isNotNull);
    }),
  );

  if (pageNames.size === 0) {
    return undefined;
  }

  return [...pageNames].toSorted().join(', ');
};

export const mutationAffectedEntityNameSelector: ParametricSelector<
  EntityTypeAndId,
  string | undefined
> = createCachedSelector(
  (state) => state,
  fieldSelector<EntityTypeAndId, 'type'>('type'),
  fieldSelector('id'),
  (state, type, id) => {
    switch (type) {
      case 'dimension':
        return dimensionSelector(state, id)?.name;
      case 'driverGroup':
        return driverGroupsByIdSelector(state)?.[id]?.name;
      case 'driver':
        return driverNameSelector(state, { id });
      case 'plan':
        return eventGroupSelector(state, id)?.name;
      case 'impact':
        return eventSelector(state, id)?.name;
      case 'scenario':
        return layerNameByIdSelector(state)[id];
      case 'goal':
        return milestonesWithoutLiveEditsByIdSelector(state)[id]?.name;
      case 'submodel':
        return submodelNamesByIdSelector(state)[id];
      case 'extDriver': {
        const extDriverPath = extDriverDisplayPathSelector(state, id);
        return extDriverPath != null ? extDriverPath[extDriverPath.length - 1] : undefined;
      }
      case 'snapshot':
        return namedDatasetVersionsByIdSelector(state)?.[id]?.name;
      case 'block': {
        const page = blocksPagesByBlockIdSelector(state)[id];
        if (page == null) {
          return undefined;
        }
        // page.internalPageType != null indicates it is an internal page with only a single block
        if (page.internalPageType != null) {
          return blocksPageNameSelector(state, page.id);
        }
        return blockNameSelector(state, id);
      }
      case 'page':
        return blocksPageNameSelector(state, id);
      case 'businessObjectSpec':
        return businessObjectSpecNameSelector(state, id);
      case 'businessObject':
        return businessObjectNameSelector(state, id);

      default: {
        const deletedIdentfiers = deletedIdentifiersSelector(state);
        return deletedIdentfiers[id];
      }
    }
  },
)((state, { type, id }) => `${type}.${id}`);
