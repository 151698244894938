import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'PuzzleIcon',
  viewBox: '0 0 36 36',
  path: (
    <>
      <path
        d="M18.1135 35.3196C27.6789 35.3196 35.4331 27.5654 35.4331 18C35.4331 8.43466 27.6789 0.68042 18.1135 0.68042C8.54819 0.68042 0.793945 8.43466 0.793945 18C0.793945 27.5654 8.54819 35.3196 18.1135 35.3196Z"
        fill="#50FAAB"
      />
      <path
        d="M15.5152 7.60825L10.3193 12.8041H15.5152V18L10.3193 23.1959H15.5152V28.3917L20.7111 23.1959H15.5152V18L20.7111 12.8041H15.5152V7.60825Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7111 23.1959C21.7346 23.1959 22.7481 22.9943 23.6937 22.6026C24.6392 22.2109 25.4984 21.6368 26.2221 20.9131C26.9459 20.1894 27.52 19.3302 27.9116 18.3846C28.3033 17.439 28.5049 16.4256 28.5049 15.4021C28.5049 14.3786 28.3033 13.3651 27.9116 12.4195C27.52 11.4739 26.9459 10.6147 26.2221 9.891C25.4984 9.16728 24.6392 8.59319 23.6937 8.20151C22.7481 7.80984 21.7346 7.60825 20.7111 7.60825H15.5152V12.8041H10.3193V18H15.5152V23.1959H10.3193V28.3917H15.5152V23.1959H20.7111ZM20.7111 18V12.8041H15.5152V18H20.7111Z"
        fill="#211F35"
      />
    </>
  ),
});
