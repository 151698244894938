import keyBy from 'lodash/keyBy';
import { DateTime } from 'luxon';

import { ValueType } from 'generated/graphql';
import {
  getDateTimeFromMonthKey,
  getMonthKey,
  getMonthKeysForRange,
  monthKeyRange,
} from 'helpers/dates';
import { isCalendarTimeAttribute } from 'helpers/dimensionalDrivers';
import { DimensionalPropertyEvaluator } from 'helpers/formulaEvaluation/DimensionalPropertyEvaluator';
import { calculate } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { LayerFormulaCache } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaCalculationContext } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCalculationContext';
import {
  getActualTimeSeriesData,
  getExtObjectFieldKey,
  getImpact,
  shouldUseActual,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluatorHelpers';
import {
  DatabaseFormulaProperty,
  DatabaseFormulaPropertyId,
  EvaluatorDatabase,
  EvaluatorDriver,
  FormulaEntity,
  FormulaEntityTypedId,
  ReferenceEvaluator,
} from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { DimensionalPropertyId } from 'reduxStore/models/collections';
import { Attribute, Dimension, DimensionId } from 'reduxStore/models/dimensions';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { Event, EventId } from 'reduxStore/models/events';
import { ExtDriver, ExtDriverId } from 'reduxStore/models/extDrivers';
import { ExtObjectField } from 'reduxStore/models/extObjects';
import { LayerId } from 'reduxStore/models/layers';
import { SubmodelId } from 'reduxStore/models/submodels';
import {
  DateRange,
  ValueOrCalculationError,
  ValueWithCalculationContext,
  isCalculationError,
} from 'types/dataset';
import { MonthKey } from 'types/datetime';

type CacheKey = string;
export type FilterCacheKey = `${BusinessObjectSpecId},${string}`;

export const getEmptyMonthValues = (
  dateRange: DateRange,
): { monthValues: Array<ValueOrCalculationError | undefined>; monthKeys: string[] } => {
  const startMonth = getDateTimeFromMonthKey(dateRange.start);
  const endMonth = getDateTimeFromMonthKey(dateRange.end);
  const monthKeys = getMonthKeysForRange(startMonth, endMonth);
  return {
    monthValues: new Array<ValueOrCalculationError | undefined>(monthKeys.length).fill(undefined),
    monthKeys,
  };
};

type FormulaEntityId = FormulaEntityTypedId['id'];

export type FormulaEvaluatorConstructorParams = {
  drivers: EvaluatorDriver[];
  lastActualsMonthKey: MonthKey;
  eventsByEntityId: Record<FormulaEntityId, Event[]>;
  extDrivers: ExtDriver[];
  extObjectFieldsByKey: NullableRecord<string, ExtObjectField>;
  attributesByDriverId: Record<DriverId, Attribute[] | undefined>;
  dimsById: Record<DimensionId, Dimension | undefined>;
  fieldSpecsById: Record<BusinessObjectFieldSpecId, BusinessObjectFieldSpec | undefined>;
  dimPropertyEvaluator: DimensionalPropertyEvaluator;
  layerId: LayerId;
  layerFormulaCache: LayerFormulaCache;
  earliestFormulaEvaluationMonthKey: MonthKey;
  latestFormulaEvaluationMonthKey: MonthKey;
  formulaEntitiesById: NullableRecord<FormulaEntityId, FormulaEntity>;
  objectIdsByFieldId: Record<BusinessObjectId, BusinessObjectId>;
  objectsById: Record<BusinessObjectId, BusinessObject>;
  databasesById: Record<BusinessObjectSpecId, EvaluatorDatabase>;
  submodelIdByBlockId: NullableRecord<BlockId, SubmodelId>;
};

export class FormulaEvaluator implements ReferenceEvaluator {
  private formulaEntitiesById: Record<FormulaEntityId, FormulaEntity | undefined>;
  private dimDriversById: Record<DriverId, EvaluatorDriver>;
  private subDriversById: Record<DriverId, EvaluatorDriver & { attributes: Attribute[] }>;
  private driversBySubmodelId: Record<SubmodelId, EvaluatorDriver[]>;
  private objectIdsByFieldId: Record<BusinessObjectId, BusinessObjectFieldId>;
  private objectsById: Record<BusinessObjectId, BusinessObject>;
  private databasesById: Record<BusinessObjectSpecId, EvaluatorDatabase>;
  private databasePropertyById: Record<DatabaseFormulaPropertyId, DatabaseFormulaProperty>;
  private lastActualsMonthKey: MonthKey;
  private eventsByEntityId: Record<FormulaEntityId, Event[]>;
  private cachedCalculationResults: Record<CacheKey, ValueOrCalculationError | undefined>;
  private earliestAllowDate: DateTime;
  private latestAllowDate: DateTime;
  private layerId: LayerId;
  private layerFormulaCache: LayerFormulaCache;
  private extDriversById: Record<ExtDriverId, ExtDriver>;
  private extObjectFieldsByKey: NullableRecord<string, ExtObjectField>;
  private attributesByDriverId: Record<DriverId, Attribute[] | undefined>;
  private dimsById: Record<DimensionId, Dimension | undefined>;
  private fieldSpecsById: Record<BusinessObjectFieldSpecId, BusinessObjectFieldSpec | undefined>;
  private blockIdBySubmodelId: NullableRecord<SubmodelId, BlockId>;
  private submodelIdByBlockId: NullableRecord<BlockId, SubmodelId>;
  // This is a specific reference evaluator used to compute dimensional properties on databases. For
  // now, this exists outside of the scope of the standard ReferenceEvaluator because dimensional
  // properties are relatively simple and do not use formulas. Once we support more generalized
  // dimension formulas, we should fold this into the standard ReferenceEvaluator.
  private dimPropertyEvaluator: DimensionalPropertyEvaluator;
  private subdriverForDriverPropertyCache: NullableRecord<string, DriverId>;
  private databaseFilterCache: Record<FilterCacheKey, Set<BusinessObjectId>>;

  constructor({
    drivers,
    lastActualsMonthKey,
    eventsByEntityId,
    extDrivers,
    extObjectFieldsByKey,
    attributesByDriverId,
    dimsById,
    fieldSpecsById,
    dimPropertyEvaluator,
    layerId,
    layerFormulaCache,
    earliestFormulaEvaluationMonthKey,
    latestFormulaEvaluationMonthKey,
    formulaEntitiesById,
    objectsById,
    objectIdsByFieldId,
    databasesById,
    submodelIdByBlockId,
  }: FormulaEvaluatorConstructorParams) {
    this.formulaEntitiesById = formulaEntitiesById;
    this.dimDriversById = this.mapDimDrivers(drivers);
    this.subDriversById = this.mapSubDrivers(drivers);
    // Note: this.submodelIdByBlockId needs to be set before calling mapDriversBySubmodelId
    this.submodelIdByBlockId = submodelIdByBlockId;
    this.blockIdBySubmodelId = Object.fromEntries(
      Object.entries(submodelIdByBlockId)
        .map(([blockId, submodelId]) => {
          if (submodelId == null) {
            return null;
          }
          return [submodelId, blockId] as const;
        })
        .filter(isNotNull),
    );
    this.driversBySubmodelId = this.mapDriversBySubmodelId(drivers);
    this.databasesById = databasesById;
    this.databasePropertyById = this.mapDatabasePropertyById(this.databasesById);
    this.objectsById = objectsById;
    this.objectIdsByFieldId = objectIdsByFieldId;
    this.dimPropertyEvaluator = dimPropertyEvaluator;
    this.lastActualsMonthKey = lastActualsMonthKey;
    // N.B. we still need this cache. For some reason (TBD) removing it
    // dramatically reduces performance. It may be due to the caching of
    // undefined values.
    this.cachedCalculationResults = {};
    this.eventsByEntityId = eventsByEntityId;
    this.extDriversById = keyBy(extDrivers, 'id');
    this.extObjectFieldsByKey = extObjectFieldsByKey;

    this.attributesByDriverId = attributesByDriverId;
    this.dimsById = dimsById;
    this.fieldSpecsById = fieldSpecsById;

    this.layerId = layerId;
    this.layerFormulaCache = layerFormulaCache;
    this.subdriverForDriverPropertyCache = {};
    this.databaseFilterCache = {};

    // Don't look too far in the past. Stop the recursion somewhere.

    // Note: We previously leveraged the start date for ending the recursion; the webworker lacks access to this state, so
    // we now set a hard cap on not looking at actuals prior to 2018. The previous behavior enabled access to older
    // actuals by changing the date range, but with inconsistent and potentially unexpected results.

    // The start date of the range is used to determine the earliest
    // allowable date, which is used in dependency loop calculation and for calculations involving self-
    // referencing drivers that don't have any actuals set. This should be properly resolved by moving calculations to
    // the backend; on the frontend, adjusting this date significantly impacts performance.
    this.earliestAllowDate = getDateTimeFromMonthKey(earliestFormulaEvaluationMonthKey);

    // Use a similar cap for the end date to stop recursion somewhere for drivers
    // which reference themselves at a future month.
    this.latestAllowDate = getDateTimeFromMonthKey(latestFormulaEvaluationMonthKey);
  }

  private mapDatabasePropertyById(
    databasesById: Record<BusinessObjectSpecId, EvaluatorDatabase>,
  ): Record<DatabaseFormulaPropertyId, DatabaseFormulaProperty> {
    return Object.values(databasesById).reduce(
      (res, database) => {
        database.formulaProperties.forEach((formulaProperty) => {
          const existing = res[formulaProperty.id];
          // if driver property, take preference over field spec
          if (existing == null || existing.type === 'fieldSpec') {
            res[formulaProperty.id] = formulaProperty;
          }
        });
        return res;
      },
      {} as Record<DatabaseFormulaPropertyId, DatabaseFormulaProperty>,
    );
  }

  private mapDimDrivers(drivers: EvaluatorDriver[]) {
    return drivers.reduce<Record<DriverId, EvaluatorDriver>>((map, driver) => {
      if (driver.type === DriverType.Dimensional) {
        map[driver.id] = driver;
      }
      return map;
    }, {});
  }

  private mapSubDrivers(drivers: EvaluatorDriver[]) {
    const driverIdToDriver = keyBy(drivers, 'id');
    return Object.values(this.dimDriversById).reduce<
      Record<DriverId, EvaluatorDriver & { attributes: Attribute[] }>
    >((out, dimDriver) => {
      if (dimDriver.type === DriverType.Dimensional) {
        for (const subdriver of dimDriver.subdrivers) {
          const driver = driverIdToDriver[subdriver.driverId];
          if (driver === undefined) {
            continue;
          }
          out[driver.id] = {
            ...driver,
            attributes: subdriver.attributes,
          };
        }
      }
      return out;
    }, {});
  }

  private mapDriversBySubmodelId(
    drivers: EvaluatorDriver[],
  ): Record<SubmodelId, EvaluatorDriver[]> {
    const driversBySubmodelId: Record<SubmodelId, EvaluatorDriver[]> = {};
    drivers.forEach((driver) => {
      driver.driverReferences?.forEach((ref) => {
        const submodelId = this.submodelIdByBlockId[ref.blockId];
        if (submodelId != null) {
          const submodelDrivers = driversBySubmodelId[submodelId] ?? [];
          submodelDrivers.push(driver);
          driversBySubmodelId[submodelId] = submodelDrivers;
        }
      });
    });
    return driversBySubmodelId;
  }

  invalidateCache() {
    this.cachedCalculationResults = {};
  }

  getLayerId() {
    return this.layerId;
  }

  getFormulaCache() {
    return this.layerFormulaCache;
  }

  private getCacheKey(
    entityId: FormulaEntityId,
    monthKey: MonthKey,
    ignoreEventIds: Set<EventId> = new Set(),
  ): CacheKey {
    const sortedIgnoreIds = [...ignoreEventIds.values()].sort();
    const ignoredKeyPart = `ignore=[${sortedIgnoreIds.join(',')}]`;

    return `${entityId},${monthKey},${ignoredKeyPart}`;
  }

  getEntityByKey(entityId: FormulaEntityTypedId) {
    const key = entityId.id.includes(';') ? entityId.id.split(';')[0] : entityId.id;
    return this.formulaEntitiesById[key];
  }

  getDriverAttributes(driverId: DriverId): Attribute[] | undefined {
    return this.attributesByDriverId[driverId];
  }

  getDimDriverById(driverId: DriverId) {
    return this.dimDriversById[driverId];
  }

  getSubDriverById(driverId: DriverId) {
    return this.subDriversById[driverId];
  }

  getDim(dimId: DimensionId) {
    return this.dimsById[dimId];
  }

  getDriversBySubmodelId(submodelId: SubmodelId) {
    return this.driversBySubmodelId[submodelId];
  }

  getDatabaseById(objectSpecId: BusinessObjectSpecId) {
    return this.databasesById[objectSpecId];
  }

  getDatabaseFormulaPropertyById(fieldId: string) {
    return this.databasePropertyById[fieldId];
  }

  getBusinessObjectById(objectId: string) {
    return this.objectsById[objectId];
  }

  getBusinessObjectByFieldId(fieldId: string) {
    const objectId = this.objectIdsByFieldId[fieldId];
    return this.objectsById[objectId];
  }

  getBusinessObjectFieldSpecByFieldId(entityId: string): BusinessObjectFieldSpec | undefined {
    const businessObject = this.getBusinessObjectByFieldId(entityId);
    const spec = this.getDatabaseById(businessObject.specId);
    const businessObjectField = businessObject?.fields.find((field) => field.id === entityId);
    const formulaField = spec.formulaProperties.find(
      (field) => field.id === businessObjectField?.fieldSpecId,
    );
    return formulaField?.type === 'fieldSpec' ? formulaField.fieldSpec : undefined;
  }

  getBusinessObjectFieldSpecById(fieldSpecId: string): BusinessObjectFieldSpec | undefined {
    return this.fieldSpecsById[fieldSpecId];
  }

  getExtObjectFieldCalculatedValuesForDateRange(
    entityId: string,
    dateRange: DateRange,
  ): Array<ValueWithCalculationContext | undefined> {
    const emptyValues = getEmptyMonthValues(dateRange);
    const { monthKeys } = emptyValues;
    const monthValues = emptyValues.monthValues as Array<ValueWithCalculationContext | undefined>;
    const businessObject = this.getBusinessObjectByFieldId(entityId);
    const fieldSpec = this.getBusinessObjectFieldSpecByFieldId(entityId);
    if (businessObject?.extKey == null || fieldSpec?.extFieldSpecKey == null) {
      return monthValues;
    }
    const extField =
      this.extObjectFieldsByKey[
        getExtObjectFieldKey(businessObject.extKey, fieldSpec?.extFieldSpecKey)
      ];

    monthKeys.forEach((monthKey, index) => {
      const value = extField?.timeSeries[monthKey];
      monthValues[index] =
        value != null
          ? {
              ...value,
              cacheKey: this.getCacheKey(entityId, monthKey),
            }
          : undefined;
    });
    return monthValues;
  }

  getLastActualsMonthKey(): MonthKey {
    return this.lastActualsMonthKey;
  }

  getEarliestAllowMonthKey(): MonthKey {
    return getMonthKey(this.earliestAllowDate);
  }

  getLatestAllowMonthKey(): MonthKey {
    return getMonthKey(this.latestAllowDate);
  }

  shouldUseActual(monthKey: MonthKey) {
    return shouldUseActual(this.lastActualsMonthKey, monthKey);
  }

  getDriverCohortMonth(entityId: FormulaEntityId): MonthKey | undefined {
    const attrs = this.getDriverAttributes(entityId);
    if (attrs == null || attrs.length === 0) {
      return undefined;
    }
    // Cohort month is represented as calendar time attributes on the driver
    // e.g. NewCustomers[Aug '22]
    const calendarAttr = attrs.find((a) => isCalendarTimeAttribute(a));
    if (calendarAttr == null || !isCalendarTimeAttribute(calendarAttr)) {
      return undefined;
    }
    return calendarAttr.value;
  }

  getActualTimeSeriesData(entityKey: FormulaEntityTypedId, monthKey: MonthKey) {
    return getActualTimeSeriesData(entityKey.id, monthKey, this.formulaEntitiesById);
  }

  getLastActualsData(entityKey: FormulaEntityTypedId) {
    return this.getActualTimeSeriesData(entityKey, this.lastActualsMonthKey);
  }

  getFormula(entityKey: FormulaEntityTypedId, monthKey: MonthKey) {
    if (this.shouldUseActual(monthKey)) {
      const actualsFormula = this.formulaEntitiesById[entityKey.id]?.actuals.formulaForCalculations;
      if (actualsFormula != null && actualsFormula !== '') {
        return actualsFormula;
      }
    }

    const forecastFormula = this.formulaEntitiesById[entityKey.id]?.forecast.formula;
    return forecastFormula ?? '';
  }

  getRawActualsFormula(entityKey: FormulaEntityTypedId) {
    const formulaEntity = this.getEntityByKey(entityKey);
    return formulaEntity?.actuals.formula;
  }

  getActualsFormulaForCalculations(entityKey: FormulaEntityTypedId) {
    const formulaEntity = this.getEntityByKey(entityKey);
    return formulaEntity?.actuals.formulaForCalculations;
  }

  getImpact(entityKey: FormulaEntityTypedId, monthKey: MonthKey, ignoreEventIds?: Set<EventId>) {
    return getImpact(
      entityKey.id,
      monthKey,
      this.formulaEntitiesById,
      this.eventsByEntityId,
      ignoreEventIds,
    );
  }

  getEventsByEntityId(entityKey: FormulaEntityTypedId) {
    return this.eventsByEntityId[entityKey.id];
  }

  getCalculatedValuesForDateRange(options: {
    entityId: FormulaEntityTypedId;
    context?: FormulaCalculationContext;
    dateRange: DateRange;
    visited: Set<CacheKey>;
    ignoreEventIds?: Set<EventId>;
    undefinedBeforeEarliestDate?: boolean;
    newlyAddedCacheKeys: Set<CacheKey>;
  }) {
    let monthValues: Array<ValueOrCalculationError | undefined>;
    let monthKeys: MonthKey[];
    if (options.dateRange.start === options.dateRange.end) {
      monthValues = [undefined];
      monthKeys = [options.dateRange.start];
    } else {
      ({ monthValues, monthKeys } = getEmptyMonthValues(options.dateRange));
    }
    if (this.getEntityByKey(options.entityId) == null) {
      throw new Error(`References non-existent ${options.entityId.type} ${options.entityId.id}`);
    }

    const getValueForMonth = (monthKey: MonthKey, visited: Set<string>) => {
      const cacheKey = this.getCacheKey(options.entityId.id, monthKey, options.ignoreEventIds);
      // Check for a cached result first. If one is present, use that.
      if (cacheKey in this.cachedCalculationResults) {
        return this.cachedCalculationResults[cacheKey];
      }

      if (visited.has(cacheKey)) {
        throw new Error('Error updating driver: driver has circular dependency');
      }
      visited.add(cacheKey);

      const result = calculate({
        evaluator: this,
        context: options.context,
        entityId: options.entityId,
        monthKey,
        visited,
        ignoreEventIds: options.ignoreEventIds,
        newlyAddedCacheKeys: options.newlyAddedCacheKeys,
      });
      this.cachedCalculationResults[cacheKey] = result;
      return result;
    };

    monthKeys.forEach((monthKey, index) => {
      const monthDateTime = getDateTimeFromMonthKey(monthKey);

      // return 0 if outside valid reference interval.
      // this allows planning on self-referencing drivers that don't have any actuals set
      if (this.earliestAllowDate >= monthDateTime || this.latestAllowDate <= monthDateTime) {
        if (!options.undefinedBeforeEarliestDate) {
          monthValues[index] = { type: ValueType.Number, value: 0 };
        }
        return;
      }

      const monthValueOrError = getValueForMonth(monthKey, options.visited);
      if (isCalculationError(monthValueOrError)) {
        monthValues[index] = { error: monthValueOrError.error };
      } else {
        monthValues[index] = monthValueOrError;
      }
    });

    return monthValues;
  }

  getExtDriverValues(id: ExtDriverId, dateRange: DateRange) {
    const extDriver = this.extDriversById[id];

    const monthKeys = monthKeyRange(dateRange.start, dateRange.end);
    return monthKeys.map((mk) => {
      const value = extDriver.timeSeries[mk];
      return value != null ? { type: ValueType.Number as const, value } : undefined;
    });
  }

  getDimPropertyEvaluator() {
    return this.dimPropertyEvaluator;
  }

  getComputedAttributeForDimensionalProperty(
    objectId: BusinessObjectId,
    dimensionalPropertyId: DimensionalPropertyId,
  ) {
    const computedAttribute = this.dimPropertyEvaluator.getAttributeProperty({
      objectId,
      dimensionalPropertyId,
    });

    return computedAttribute?.attribute;
  }

  getSubDriverIdForDriverProperty(objectId: string, driverPropertyId: string) {
    const driverProperty = this.getDatabaseFormulaPropertyById(driverPropertyId);
    if (driverProperty == null || driverProperty.type !== 'driverProperty') {
      return undefined;
    }
    const cacheKey = `${objectId}-${driverPropertyId}`;
    if (cacheKey in this.subdriverForDriverPropertyCache) {
      return this.subdriverForDriverPropertyCache[cacheKey];
    }

    const dimDriverId = driverProperty.driverProperty.driverId;
    const computedAttributeIds = this.getDimPropertyEvaluator()
      .getKeyAttributePropertiesForBusinessObject(objectId)
      .map((a) => a.attribute.id);

    const subdriverId = this.getDimPropertyEvaluator().getSubDriverIdForAttributeIds(
      dimDriverId,
      computedAttributeIds,
    );
    if (subdriverId != null) {
      this.subdriverForDriverPropertyCache[cacheKey] = subdriverId;
    }
    return subdriverId;
  }

  getCachedFilterResult(filterKey: FilterCacheKey) {
    return this.databaseFilterCache[filterKey];
  }

  setCachedFilterResult(filterKey: FilterCacheKey, objectIds: Set<string>) {
    this.databaseFilterCache[filterKey] = objectIds;
  }

  getBlockIdBySubmodelId(submodelId: SubmodelId) {
    return this.blockIdBySubmodelId[submodelId];
  }
}
