import { PayloadAction } from '@reduxjs/toolkit';

import { BusinessObjectTimeSeriesCellRef, CellRef, CellSelection, CellType } from 'config/cells';
import { getMonthColumnKey } from 'helpers/cells';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId } from 'reduxStore/models/events';
import {
  PaneType,
  isDriverDetailPane,
  isPlanDetailPane,
} from 'reduxStore/reducers/detailPaneSlice';
import { ModalType, handleSelectSingleCell } from 'reduxStore/reducers/pageSlice';
import { createRootReducers } from 'reduxStore/reducers/sliceReducers';
import { dimensionalDriversByIdSelector } from 'selectors/driversSelector';

const openDetailPane = createRootReducers('openDetailPane', {
  openDriverDetailPane(state, action: PayloadAction<{ driverId: DriverId }>) {
    const { driverId } = action.payload;
    const isDimensionalDriver = dimensionalDriversByIdSelector(state)[driverId];
    if (
      state.page.openModal === ModalType.DetailPane &&
      isDriverDetailPane(state.detailPane) &&
      state.detailPane?.driverId === driverId
    ) {
      return;
    }

    const currTab = isDriverDetailPane(state.detailPane) ? state.detailPane.tab : undefined;
    const currShowIndirectInputsTab = isDriverDetailPane(state.detailPane)
      ? state.detailPane.showIndirectInputs
      : false;

    state.page.openModal = ModalType.DetailPane;
    state.detailPane = {
      type: isDimensionalDriver != null ? PaneType.DimensionalDriver : PaneType.Driver,
      driverId,
      tab: currTab ?? 'inputs',
      showIndirectInputs: currShowIndirectInputsTab,
    };
  },
  openObjectDetailPane(
    state,
    action: PayloadAction<{
      objectId: BusinessObjectId;
      objectSpecId: BusinessObjectSpecId;
      initialActiveCell?: CellRef;
      blockId: string;
      focusFieldSpecId?: string;
      monthKey: string;
    }>,
  ) {
    if (state.page == null) {
      return;
    }

    const { objectId, objectSpecId, initialActiveCell, blockId, focusFieldSpecId, monthKey } =
      action.payload;

    state.page.openModal = ModalType.DetailPane;

    if (focusFieldSpecId != null) {
      const cellRef: BusinessObjectTimeSeriesCellRef = {
        type: CellType.ObjectFieldTimeSeries,
        columnKey: getMonthColumnKey(monthKey),
        rowKey: { fieldSpecId: focusFieldSpecId, objectId, layerId: undefined },
      };

      handleSelectSingleCell(state.page, { blockId, cellRef });
    }

    state.detailPane = {
      type: PaneType.Object,
      objectSpecId,
      objectId,
    };

    if (initialActiveCell != null) {
      const selection: CellSelection = {
        type: 'cell',
        activeCell: initialActiveCell,
        selectedCells: [initialActiveCell],
        blockId,
      };
      state.page.modalSelection = selection;
    }
  },
  openPlanDetailPane(state, action: PayloadAction<{ eventGroupId: EventGroupId }>) {
    const { eventGroupId } = action.payload;
    const currTab = isPlanDetailPane(state.detailPane) ? state.detailPane.tab : undefined;
    state.page.openModal = ModalType.DetailPane;
    state.detailPane = {
      type: PaneType.Plan,
      eventGroupId,
      tab: currTab ?? 'timeline',
    };
  },
});

export const { openObjectDetailPane, openDriverDetailPane, openPlanDetailPane } =
  openDetailPane.actions;

export default openDetailPane.reducer;
