import { useEffect } from 'react';

// see https://usehooks.com/useOnClickOutside/
// You can also pass in a list of refs, which triggers the handler only if the click is
// outside _all_ of them. Useful for nested menus.
export default function useOnClickOutside(
  ref: React.MutableRefObject<HTMLElement | null> | Array<React.RefObject<HTMLElement>>,
  handler?: (event: MouseEvent) => unknown,
  {
    selector,
    disabled,
    handleOnCapture,
  }: {
    selector?: string;
    disabled?: boolean;
    // Whether or not to handle the event on the capture phase of the event propagation.
    handleOnCapture?: boolean;
  } = { disabled: false },
) {
  useEffect(
    () => {
      const listener = (event: MouseEvent) => {
        if (disabled) {
          return;
        }

        if (handler == null) {
          return;
        }

        const refs = Array.isArray(ref) ? ref : [ref];

        // Do nothing if there isn't any element to click outside of.
        if (!refs.some((r) => r.current)) {
          return;
        }

        const target = event.target as HTMLElement;
        // Do nothing if clicking ref's element or given selector or descendent elements

        const isTargetChild = refs.some((r) => r.current?.contains(target));
        if (isTargetChild || (selector != null && target.closest(selector) != null)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener, handleOnCapture);

      return () => {
        document.removeEventListener('mousedown', listener, handleOnCapture);
      };
    },
    // It's worth noting that because passed in handler is a new function on every render
    // that will cause this effect callback/cleanup to run every render. To optimize you can
    // wrap handler in useCallback before passing it into this hook.
    [ref, handler, selector, disabled, handleOnCapture],
  );
}
