// For dependencies, we want to represent dates in a more abstract way than

import { exhaustiveGuard } from 'helpers/exhaustiveGuard';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectFieldId } from 'reduxStore/models/businessObjects';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { TimeUnit } from 'types/datetime';
import { DateReference } from 'types/formula';

export const ALL_MONTH_DEPENDENCY_DATE_RANGE = 'ALL_MONTH_DEPENDENCY_DATE_RANGE';

// exact month keys. That is, unless they are actualy fixed month keys.
export type DependencyDateRange =
  | { start: DateReference; end: DateReference }
  | typeof ALL_MONTH_DEPENDENCY_DATE_RANGE;

const THIS_MONTH_RELATIVE_DATE: DateReference = {
  type: 'relative',
  val: { unit: TimeUnit.Month, val: 0, reference: 'today' },
};

export const THIS_MONTH_RELATIVE_RANGE: DependencyDateRange = {
  start: THIS_MONTH_RELATIVE_DATE,
  end: THIS_MONTH_RELATIVE_DATE,
};

export type Dependency =
  | {
      type: 'driver';
      id: DriverId;
      dateRange?: DependencyDateRange;
    }
  | {
      type: 'extDriver';
      id: ExtDriverId;
      dateRange?: DependencyDateRange;
    }
  | {
      type: 'businessObjectSpec';
      id: BusinessObjectSpecId;
      dateRange?: DependencyDateRange;
    }
  | {
      type: 'businessObjectFieldSpec';
      id: BusinessObjectFieldSpecId;
      dateRange?: DependencyDateRange;
    }
  | {
      type: 'businessObjectField';
      id: BusinessObjectFieldId;
      dateRange?: DependencyDateRange;
    }
  | {
      type: 'dimension';
      id: DimensionId;
    }
  | {
      type: 'attribute';
      id: AttributeId;
    }
  | {
      type: 'objectFieldSpecFormula';
      id: BusinessObjectFieldSpecId;
      dateRange?: DependencyDateRange;
    }
  | {
      type: 'driverGroup';
      id: DriverGroupId;
    };

// A dependency that we should recurse into in order to detect more dependencies
export type CalculableDependency = Dependency & {
  type: 'driver' | 'extDriver' | 'objectFieldSpecFormula';
};

export function isCalculableDependency(dep: Dependency): dep is CalculableDependency {
  return dep.type === 'driver' || dep.type === 'extDriver' || dep.type === 'objectFieldSpecFormula';
}

export function formulaEntityTypeFromDependencyType(
  depType: Dependency['type'],
): FormulaEntityTypedId['type'] | null {
  switch (depType) {
    case 'businessObjectField':
      return 'objectField';
    case 'businessObjectFieldSpec':
      return 'objectFieldSpec';
    case 'extDriver':
      return 'extDriver';
    case 'driver':
      return 'driver';
    case 'objectFieldSpecFormula':
      return 'objectFieldSpec';
    case 'attribute':
    case 'businessObjectSpec':
    case 'dimension':
    case 'driverGroup':
      return null;
    default:
      exhaustiveGuard(depType);
  }
  return null;
}
