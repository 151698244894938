import { BlockType, BlockViewAsType, ChartSize, ImpactDisplayType } from 'generated/graphql';
import { BlockId } from 'reduxStore/models/blocks';

export const BLOCK_TYPE_TO_NAME: Record<BlockType, string> = {
  DRIVER_CHARTS: 'Driver charts',
  DRIVER_GRID: 'Driver table',
  PLAN_TIMELINE: 'Plan timeline',
  OBJECT_TABLE: 'Database table',
  TEXT: 'Text',
  VIDEO: 'Video',
  IMAGE: 'Image',
  /** @deprecated Users can no longer create this BlockType. Use OBJECT_TABLE instead */
  OBJECT_GRID: 'Object table',
};

export const IMPACT_DISPLAY_TYPE_TO_NAME: Record<ImpactDisplayType, string> = {
  [ImpactDisplayType.BeforeAfter]: 'Before & after',
  [ImpactDisplayType.Total]: 'Impact',
};

export const CUSTOMIZE_BLOCK_MENU_TITLE = 'Customize block';
export const CUSTOMIZE_BLOCK_MENU_SHARING = 'SHARING';

export const OBJECT_TABLE_BLOCK_UNDEFINED_OPTION_ID_PLACEHOLDER =
  'placeholderForObjectTableGroupWithNoOptionId';

export const DEFAULT_DRIVER_VIEW_AS_TYPE = BlockViewAsType.Chart;
export const DEFAULT_DRIVER_CHART_SIZE = ChartSize.Medium;

export type BlockProps = {
  blockId: BlockId;
};

export const BLOCK_TYPES_WITH_INLINE_CONTROLS = [BlockType.Text, BlockType.Video, BlockType.Image];

export const BLOCK_RESTRICTED_TOOLTIP_TEXT = {
  SHOW_RESTRICTED: 'Anonymized data is being shown for this block',
  RESTRICTED: 'Only admins can view anonymized data',
};
