import { useCallback, useRef } from 'react';

import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { insertFormulaEntityReference } from 'reduxStore/reducers/formulaInputSlice';
import { canInsertFormulaEntityReferenceSelector } from 'selectors/formulaInputSelector';

export type SelectHandler = (opts: { range: boolean; toggle: boolean }) => void;

const useSelectMouseHandlers = (
  isSelected: boolean,
  onSelect: SelectHandler,
  { formulaReferenceEntityId }: { formulaReferenceEntityId?: FormulaEntityTypedId } = {},
) => {
  const dispatch = useAppDispatch();
  const canInsertDriverReference = useAppSelector(canInsertFormulaEntityReferenceSelector);
  const mouseDownSelected = useRef(false);
  const shouldInsertReference = formulaReferenceEntityId != null && canInsertDriverReference;
  const onMouseDown: React.MouseEventHandler = useCallback(
    (ev) => {
      const isRightClick = ev.button === 2 || (ev.button === 0 && ev.ctrlKey);
      if (isRightClick) {
        return;
      }

      if (shouldInsertReference) {
        dispatch(insertFormulaEntityReference(formulaReferenceEntityId));
        return;
      }

      // if selected, ignore mouse down and handle the click event
      if (isSelected) {
        return;
      }
      onSelect({ range: ev.shiftKey, toggle: ev.metaKey || ev.ctrlKey });
      mouseDownSelected.current = true;
      document.addEventListener(
        'mouseup',
        () => {
          setTimeout(() => {
            mouseDownSelected.current = false;
          }, 0);
        },
        { once: true },
      );
    },
    [formulaReferenceEntityId, isSelected, shouldInsertReference, onSelect, dispatch],
  );

  const onClick: React.MouseEventHandler = useCallback(
    (ev) => {
      if (shouldInsertReference || mouseDownSelected.current) {
        ev.preventDefault();
        return;
      }
      onSelect({ range: ev.shiftKey, toggle: ev.metaKey || ev.ctrlKey });
    },
    [onSelect, shouldInsertReference],
  );

  return { onClick, onMouseDown };
};

export default useSelectMouseHandlers;
