import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'TreemapChart',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    boxSize: '14px',
  },
  d: 'M2 4.16667C2 2.97005 2.97005 2 4.16667 2H11.8333C13.0299 2 14 2.97005 14 4.16667V11.8333C14 13.0299 13.0299 14 11.8333 14H4.16667C2.97005 14 2 13.0299 2 11.8333V4.16667ZM4.16667 3C3.52233 3 3 3.52233 3 4.16667V11.8333C3 12.4777 3.52233 13 4.16667 13H6V3H4.16667ZM7 3V9.33333H13V4.16667C13 3.52233 12.4777 3 11.8333 3H7ZM13 10.3333H7V13H11.8333C12.4777 13 13 12.4777 13 11.8333V10.3333Z',
});
