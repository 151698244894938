import { groupBy } from 'lodash';
import { createSelector } from 'reselect';

import { OrgRole } from 'generated/graphql';
import { accessorEntityToRole } from 'helpers/permissions';
import { LayerAccessResource, isLayerAccessResource } from 'reduxStore/models/accessResources';
import { LayerId } from 'reduxStore/models/layers';
import { allAccessResourcesSelector } from 'selectors/sharingSelector';
import { Selector } from 'types/redux';

const layerAccessResourcesSelector: Selector<LayerAccessResource[]> = createSelector(
  allAccessResourcesSelector,
  (accessResources) => {
    return accessResources.filter(isLayerAccessResource);
  },
);

// return access control resources for root level layers shared with members of the org
export const publishedLayersSelector: Selector<Record<LayerId, LayerAccessResource[]>> =
  createSelector(layerAccessResourcesSelector, (layerAccessResources) => {
    return groupBy(
      layerAccessResources.filter(
        (resource) =>
          resource.parentId == null &&
          resource.accessControlList.some(
            (accessControlEntry) =>
              accessorEntityToRole(accessControlEntry.entityWithAccess) === OrgRole.Member,
          ),
      ),
      'resourceId',
    );
  });
