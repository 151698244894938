import React from 'react';

import { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';

export interface BaseSelectMenuItemProps<T extends SelectItem = SelectItem> {
  item: T;
  idx: number;
  isFocused: boolean;
  isDraggable?: boolean;
  draggingDisabled?: boolean;
}

export function mapBaseSelectMenuProps<T extends SelectItem>(props: BaseSelectMenuItemProps<T>) {
  const { item, idx, isFocused, isDraggable, draggingDisabled } = props;
  return {
    idx,
    name: item.name,
    hasNextMenu: item.hasNextMenu || item.submenu != null,
    submenuType: item.submenuType,
    icon: item.icon,
    iconColor: item.iconColor,
    isChecked: item.isChecked,
    checkedStyle: item.checkedStyle,
    isDisabled: item.isDisabled,
    isFocused,
    meta: item.meta,
    shortcutHint: item.shortcut ?? null,
    isDraggable,
    draggingDisabled,
  };
}

const BaseSelectMenuItem: React.FC<BaseSelectMenuItemProps<SelectItem>> = (props) => {
  return (
    <SelectMenuItem key={props.item.key ?? props.item.id} {...mapBaseSelectMenuProps(props)} />
  );
};

BaseSelectMenuItem.displayName = 'BaseSelectMenuItem';

export default BaseSelectMenuItem;
