import { isElectron } from 'react-device-detect';

export const NAVIGATION_PANEL_MIN_WIDTH_PX = 212;
export const NAVIGATION_PANEL_MAX_WIDTH_PX = 500;

export const NAVIGATION_TOP_BAR_HEIGHT_PX = isElectron ? 38 : 48;
export const NAVIGATION_TOP_BAR_HEIGHT = `${NAVIGATION_TOP_BAR_HEIGHT_PX}px`;

const SCENARIO_NAVIGATION_TOP_BAR_HEIGHT_PX = 41;

export const STICKY_TOP = SCENARIO_NAVIGATION_TOP_BAR_HEIGHT_PX + NAVIGATION_TOP_BAR_HEIGHT_PX;
