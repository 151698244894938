import { useCallback, useState } from 'react';

import { applyDriverFilters, Filters } from 'helpers/driverSearch';
import useAppSelector from 'hooks/useAppSelector';
import useDriverSearch from 'hooks/useDriverSearch';
import { Driver } from 'reduxStore/models/drivers';
import { blockIdBySubmodelIdSelector } from 'selectors/submodelPageSelector';

interface Opts extends Omit<Parameters<typeof useDriverSearch>[0], 'preSearchFilter'> {}

export default function useFilteredDriverSearch(driverSearchOpts: Opts) {
  const blockIdBySubmodelId = useAppSelector(blockIdBySubmodelIdSelector);
  const [filters, setFilters] = useState<Filters>({
    submodels: [],
  });

  const filterFn = useCallback(
    (driver: Driver) => {
      return applyDriverFilters(driver, blockIdBySubmodelId, filters);
    },
    [blockIdBySubmodelId, filters],
  );

  const searchResults = useDriverSearch({
    ...driverSearchOpts,
    preSearchFilter: filterFn,
  });

  // TODO: We should implement the proper filtering for these results. Some of
  // them may not make sense, but theoretically the "impacts" one could. For
  // now, just dont' show object specs when you have any filters enabled.
  const hasFilters = filters.submodels.length > 0;

  return {
    ...searchResults,
    objectSpecs: hasFilters ? [] : searchResults.objectSpecs,
    filters,
    setFilters,
  };
}
