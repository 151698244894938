import { Box, Divider, Flex } from '@chakra-ui/react';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ItemSelectMenu from 'components/ItemSelectMenu/ItemSelectMenu';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import ExtDriverSelectMenuFooter from 'components/SelectMenu/ExtDriverSelectMenuFooter';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import CustomTimePeriodSelectionMenu from 'components/TimePeriodSelectionMenu/CustomTimePeriodSelectionMenu';
import CustomTimeRangeSelectionMenu from 'components/TimePeriodSelectionMenu/CustomTimeRangeSelectionMenu';
import DriverMetaValue from 'components/TimePeriodSelectionMenu/DriverMetaValue';
import {
  TIME_PERIOD_DISPLAY_NAME,
  TIME_PERIOD_ICON,
  TIME_RANGE_DISPLAY_NAME,
  TIME_RANGE_ICON,
} from 'config/datetime';
import { DriverType } from 'generated/graphql';
import { getPeriodFormulaTimeRange, getRangeFormulaTimeRange } from 'helpers/formula';
import useAppSelector from 'hooks/useAppSelector';
import { DriverId } from 'reduxStore/models/drivers';
import { driverSelector } from 'selectors/driversSelector';
import { TimePeriod, TimeRange } from 'types/datetime';
import { EntityType, FormulaTimeRange } from 'types/formula';

const FORMULA_TIME_PERIOD_OPTIONS = [
  TimePeriod.ThisMonth,
  TimePeriod.LastMonth,
  TimePeriod.OneYearAgo,
  TimePeriod.Driver,
  TimePeriod.Custom,
];

const FORMULA_TIME_RANGE_OPTIONS = [
  TimeRange.LastSixMonths,
  TimeRange.LastTwelveMonths,
  TimeRange.YearToDate,
  TimeRange.QuarterToDate,
  TimeRange.Custom,
];

const SINGLE_VALUE_SECTION_ID = 'single';
const RANGE_SECTION_ID = 'range';
const SECTIONS: Section[] = [
  {
    id: SINGLE_VALUE_SECTION_ID,
    name: 'Single Value',
  },
  {
    id: RANGE_SECTION_ID,
    name: 'Range',
  },
];

interface Props {
  entityDateRange?: FormulaTimeRange;
  entityDriverId?: DriverId;
  onClose?: () => void;
  onSelectTimePeriod: (input: FormulaTimeRange) => void;
}

const TimePeriodSelectionMenu: React.FC<Props> = ({
  entityDateRange,
  entityDriverId,
  onClose,
  onSelectTimePeriod,
}) => {
  const { formulaEntityId, activeEntity } = useContext(FormulaSelectionContext);
  const dateRange = entityDateRange ?? { type: 'relative', start: 0, end: 0 };

  const isFormulaDriver = entityDriverId === formulaEntityId?.id;
  const isExtDriver = activeEntity?.type === EntityType.ExtDriver;
  const entityDriver = useAppSelector((state) =>
    driverSelector(state, { id: entityDriverId ?? '' }),
  );

  const [submenu, setSubmenu] = useState<'driver' | 'custom_period' | 'custom_range' | null>(null);

  const onSelect = useCallback(
    (item: SelectItem) => {
      if (item.id === TimePeriod.Driver) {
        setSubmenu('driver');
      } else if (item.id === TimePeriod.Custom) {
        setSubmenu('custom_period');
      } else if (item.id === TimeRange.Custom) {
        setSubmenu('custom_range');
      } else {
        let formulaTimeRange: FormulaTimeRange | null = null;
        if (item.sectionId === SINGLE_VALUE_SECTION_ID) {
          formulaTimeRange = getPeriodFormulaTimeRange(item.id as TimePeriod);
        } else if (item.sectionId === RANGE_SECTION_ID) {
          formulaTimeRange = getRangeFormulaTimeRange(item.id as TimeRange);
        }
        if (formulaTimeRange != null) {
          onSelectTimePeriod(formulaTimeRange);
        }
      }
    },
    [onSelectTimePeriod],
  );

  const items = useMemo<SelectItem[]>(() => {
    return [
      ...FORMULA_TIME_PERIOD_OPTIONS.filter(
        (option) => {
          return !isFormulaDriver || option !== TimePeriod.ThisMonth;
        },
        // don't allow selecting "This month" when referencing active cell (no self-references)
      ).map((period) => {
        return {
          id: period,
          name: TIME_PERIOD_DISPLAY_NAME[period],
          sectionId: SINGLE_VALUE_SECTION_ID,
          icon: TIME_PERIOD_ICON[period],
          meta:
            entityDriver?.type === DriverType.Basic ? (
              <DriverMetaValue driverId={entityDriver.id} timePeriod={period} />
            ) : undefined,
          hasNextMenu: period === TimePeriod.Custom || period === TimePeriod.Driver,
        };
      }),
      ...FORMULA_TIME_RANGE_OPTIONS.map((range) => {
        return {
          id: range,
          name: TIME_RANGE_DISPLAY_NAME[range],
          sectionId: RANGE_SECTION_ID,
          icon: TIME_RANGE_ICON[range],
          // TODO: add meta value
        };
      }),
    ];
  }, [isFormulaDriver, entityDriver?.type, entityDriver?.id]);

  const clearSubmenu = useCallback(() => {
    setSubmenu(null);
  }, []);

  return (
    <Box bg="surface">
      {submenu == null && (
        <>
          {isExtDriver && (
            <Box>
              <Flex width="full" p={3}>
                <ExtDriverSelectMenuFooter extDriverId={activeEntity?.data.id} />
              </Flex>
              <Divider />
            </Box>
          )}
          <SelectMenu
            items={items}
            sections={SECTIONS}
            onSelect={onSelect}
            onClose={onClose}
            startFocusIdx={-1}
          >
            {BaseSelectMenuItem}
          </SelectMenu>
        </>
      )}
      {submenu === 'custom_period' && (
        <CustomTimePeriodSelectionMenu
          onSelectTimePeriod={onSelectTimePeriod}
          dateRange={dateRange}
          onBack={clearSubmenu}
        />
      )}
      {submenu === 'custom_range' && (
        <CustomTimeRangeSelectionMenu
          onSelectTimeRange={onSelectTimePeriod}
          dateRange={dateRange}
          onBack={clearSubmenu}
        />
      )}
      {submenu === 'driver' && (
        <ItemSelectMenu
          onClose={clearSubmenu}
          onSelectItem={(item) => {
            onSelectTimePeriod({ type: 'relativeVariable', start: item.id, end: item.id });
          }}
          onBack={clearSubmenu}
        />
      )}
    </Box>
  );
};

export default React.memo(TimePeriodSelectionMenu);
