import { createSelector } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import {
  Integration,
  IntegrationCategory,
  IntegrationProvider,
  LinkedAccount,
  SyncStatusValue,
} from 'generated/graphql';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  ExtSource,
  extDriverSourceDisplayName,
  getExtSource,
  linkedAccountDisplayName,
  sourceSupportsQueryReads,
  sourceSupportsQueryWrites,
} from 'helpers/integrations';
import { ExtDriverSource } from 'reduxStore/models/extDrivers';
import { DataSourcePageState } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { paramSelector } from 'selectors/constSelectors';
import {
  availableIntegrationsSelector,
  linkedAccountsByExtSourceSelector,
  linkedAccountsSelector,
} from 'selectors/integrationsSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { integrationSyncStatusSelector } from 'selectors/syncStatusSelector';
import { ParametricSelector, Selector } from 'types/redux';

interface DataSourceWithAccount {
  source?: ExtSource;
  accountId?: string;
}

export const navDataSourceWithAccountSelector: Selector<DataSourceWithAccount | undefined> =
  createDeepEqualSelector(pageSelector, (page) => {
    return page?.type === 'dataSourcePage'
      ? { source: page.source, accountId: page.accountId }
      : undefined;
  });

export const navDataSourceSelector: Selector<ExtDriverSource | undefined> = createSelector(
  navDataSourceWithAccountSelector,
  (navDataSourceWithAccount) =>
    navDataSourceWithAccount != null ? navDataSourceWithAccount.source : undefined,
);

//TODO: We need to change the page state so that it tracks a source+category
//uniquely as a source can have several categories. For now, we just assume
//that there is only one.
export const navLinkedAccountSelector: Selector<LinkedAccount | undefined> =
  createDeepEqualSelector(
    (state: RootState) => state,
    navDataSourceWithAccountSelector,
    (state, extSource) => {
      if (extSource == null) {
        return undefined;
      }
      return linkedAccountForDataSourceSelector(state, extSource);
    },
  );

export const navDataSourceTitleSelector: Selector<string | undefined> = createSelector(
  (state: RootState) => state,
  navDataSourceWithAccountSelector,
  navLinkedAccountSelector,
  (_state, navDataSourceWithAccount, navLinkedAccount) => {
    if (navLinkedAccount) {
      return linkedAccountDisplayName(navLinkedAccount);
    }
    const source = navDataSourceWithAccount?.source;
    if (source != null) {
      return extDriverSourceDisplayName(source);
    }
    return undefined;
  },
);

export const linkedAccountsForDataSourceSelector: ParametricSelector<ExtSource, LinkedAccount[]> =
  createCachedSelector(
    linkedAccountsByExtSourceSelector,
    paramSelector<ExtSource>(),
    (accountsByExtSource, source) => {
      return accountsByExtSource.get(source) ?? [];
    },
  )((_state, source) => source);

export const linkedAccountForDataSourceSelector: ParametricSelector<
  DataSourceWithAccount,
  LinkedAccount | undefined
> = createCachedSelector(
  linkedAccountsSelector,
  paramSelector<DataSourceWithAccount>(),
  (accounts, dataSource) => {
    const { source, accountId } = dataSource;
    if (source == null) {
      return undefined;
    }
    return accounts.find((account) => {
      return (
        getExtSource(account.integration) === source &&
        (accountId == null || account.id === accountId)
      );
    });
  },
)((_state, dataSource) => `${dataSource.source}-${dataSource.accountId}`);

const findIntegrationGivenDataSource = (
  integrations: Integration[],
  source: ExtDriverSource | undefined,
) => {
  if (source == null) {
    return undefined;
  }
  const integration = integrations.find((int) => getExtSource(int) === source);

  if (integration == null || integration.categories.length === 0) {
    return undefined;
  }

  // N.B. We just return the first category since right now we don't have any
  // multi-category integrations we use and we aren't tracking which category
  // you're on.
  return { integration, category: integration.categories[0] };
};

export const integrationCategorySelector: Selector<IntegrationCategory | undefined> =
  createSelector(
    availableIntegrationsSelector,
    navDataSourceSelector,
    (integrations: Integration[], source: ExtDriverSource | undefined) =>
      findIntegrationGivenDataSource(integrations, source)?.category,
  );

export const navDataSourceSubPageSelector: Selector<DataSourcePageState['navSubPage']> = (
  state,
) => {
  return state.page.type === 'dataSourcePage' ? state.page.navSubPage : undefined;
};

export const integrationInitialSyncStatusSelector: Selector<'loaded' | 'pending' | 'syncing'> =
  createSelector(
    (state: RootState) => state,
    linkedAccountsSelector,
    navDataSourceWithAccountSelector,
    (state, linkedAccounts, navDataSource) => {
      if (navDataSource == null || navDataSource.source == null) {
        return 'pending';
      }
      const navSource = navDataSource.source;
      const account = linkedAccounts.find(
        (acct) =>
          getExtSource(acct.integration) === navSource &&
          (navDataSource.accountId == null || acct.id === navDataSource.accountId),
      );
      if (account == null) {
        // N.B. "Local only" pages won't have a linked account
        return 'loaded';
      }
      const syncStatus = integrationSyncStatusSelector(state, account);

      // Workato integrations are immediately available, and won't sync until you run a query
      if (IntegrationProvider.Workato === account.integration.provider) {
        return 'loaded';
      }

      if (syncStatus?.status == null) {
        return 'pending';
      }

      const isSyncing =
        syncStatus?.status?.__typename === 'SyncStatusResult' &&
        [SyncStatusValue.SyncPending, SyncStatusValue.Syncing].includes(
          syncStatus?.status?.status,
        ) &&
        syncStatus?.status?.lastSyncTime == null;

      return isSyncing ? 'syncing' : 'loaded';
    },
  );

export const navDataSourceSupportsQueryReadsSelector = createSelector(
  navDataSourceSelector,
  sourceSupportsQueryReads,
);

export const sourceSupportsQueryWritesSelector: ParametricSelector<ExtSource | undefined, boolean> =
  createSelector(paramSelector<ExtSource | undefined>(), (source) =>
    sourceSupportsQueryWrites(source),
  );

export const navDataSourceSupportsQueryWritesSelector = createSelector(
  navDataSourceSelector,
  (source) => sourceSupportsQueryWrites(source),
);
