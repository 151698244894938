import { Text } from '@chakra-ui/react';

import TableCell from 'components/Table/TableCell';
import TimeSeriesCellWrapper from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapper';
import { isMonthColumnKey, isStickyColumnKey } from 'helpers/cells';
import { HIDDEN_DATA_TEXT } from 'helpers/permissions';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellRef } from 'hooks/useCellRefContext';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

const AnonText = () => <Text color="gray.500">{HIDDEN_DATA_TEXT}</Text>;

export const AnonymizedObjectTimeSeriesCell: React.FC<{
  onMouseDown?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
}> = ({ onMouseDown, onMouseEnter, onMouseLeave }) => {
  const { blockId } = useBlockContext();
  const { cellRef } = useCellRef();

  const columnKey = cellRef?.columnKey;
  let columnType = '';
  if (columnKey != null) {
    if (isMonthColumnKey(columnKey)) {
      columnType = columnKey.monthKey;
    } else if (isStickyColumnKey(columnKey)) {
      columnType = columnKey.columnType;
    }
  }
  const width = useAppSelector((state) => columnWidthSelector(state, { columnType, blockId }));

  return (
    <TimeSeriesCellWrapper
      color="actuals"
      width={width}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TableCell width={width}>
        <AnonText />
      </TableCell>
    </TimeSeriesCellWrapper>
  );
};
