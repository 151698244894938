import { uniqBy } from 'lodash';
import { createSelector } from 'reselect';

import { DEFAULT_PLAN_NAME, WithEventGroup } from '@features/Plans';
import { ImpactType } from 'generated/graphql';
import { getWithEventGroupKey } from 'helpers/events';
import { assertUnreachable } from 'helpers/typescript';
import { EventId } from 'reduxStore/models/events';
import {
  multiPlanPickerSelectedEventGroupsSelector,
  updatedMultiDeltaImpactsSelector,
} from 'selectors/cellPaletteSelector';
import {
  activeCellImpactSelector,
  eventGroupsByIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { Selector } from 'types/redux';

// For the active cell, get its current multi-delta impact values and associated event groups
// and event ids. We prioritize impact values from the edited formula over the saved impact values
// and prioritize plans selected in the multi-impact plan picker over the plans already associated
// with the impacts.
export const activeCellMultiDeltaImpactsWithEditsSelector: Selector<
  Array<{
    value: number;
    withEventGroup: WithEventGroup | null;
    eventId: EventId | null;
  }>
> = createSelector(
  updatedMultiDeltaImpactsSelector,
  activeCellImpactSelector,
  multiPlanPickerSelectedEventGroupsSelector,
  (updatedMultiDeltaImpacts, activeCellImpact, selectedEventGroups) => {
    // If updatedMultiDeltaImpacts is defined, it is the source of truth for impact values
    // as it has the impact values from the cell editor
    let currentMultiDeltaImpactValues = updatedMultiDeltaImpacts ?? [];

    // Otherwise, we use the values from the active cell impact (as long as it's a multi-delta impact)
    if (
      currentMultiDeltaImpactValues.length === 0 &&
      activeCellImpact?.impactType === ImpactType.Delta &&
      activeCellImpact.valuesWithEventGroups.length > 1
    ) {
      currentMultiDeltaImpactValues = activeCellImpact.valuesWithEventGroups.map(
        ({ value }) => value,
      );
    }

    const valuesWithEventGroups = currentMultiDeltaImpactValues.map(({ value }, index) => {
      const selectedEventGroup = selectedEventGroups?.[index];

      // If the user has selected a plan for this impact from the multi-impact plan picker,
      // use the selected plan
      if (selectedEventGroup != null) {
        return { value, withEventGroup: selectedEventGroup, eventId: null };
      }

      // Otherwise, use the plan already associated with the impact
      if (activeCellImpact?.impactType === ImpactType.Delta) {
        const cellImpact = activeCellImpact.valuesWithEventGroups[index];

        const eventGroupId = cellImpact?.eventGroupId;
        const eventId = cellImpact?.eventId;

        if (eventGroupId != null) {
          const withEventGroup: WithEventGroup = {
            type: 'existing',
            eventGroupId,
          };

          return { value, withEventGroup, eventId };
        }
      }

      // There is no selected plan for this impact or plan associated with this impact already
      return { value, withEventGroup: null, eventId: null };
    });

    return valuesWithEventGroups;
  },
);

const activeCellMultiDeltaImpactEventGroupsSelector: Selector<WithEventGroup[]> = createSelector(
  activeCellMultiDeltaImpactsWithEditsSelector,
  (impacts) => {
    if (impacts.every(({ withEventGroup }) => withEventGroup == null)) {
      return [];
    }

    const withEventGroups: WithEventGroup[] = impacts.map(({ withEventGroup }) => {
      return withEventGroup ?? { type: 'default' };
    });
    const uniqueWithEventGroups = uniqBy(withEventGroups, getWithEventGroupKey);

    return uniqueWithEventGroups;
  },
);

export const activeCellMultiDeltaImpactEventGroupNamesSelector: Selector<string[]> = createSelector(
  activeCellMultiDeltaImpactEventGroupsSelector,
  eventGroupsByIdForLayerSelector,
  (withEventGroups, eventGroupsById) => {
    const eventGroupNames = withEventGroups.map((withEventGroup) => {
      switch (withEventGroup.type) {
        case 'existing':
          return eventGroupsById[withEventGroup.eventGroupId]?.name ?? '';
        case 'new':
          return withEventGroup.newEventGroup.name;
        case 'default':
          return DEFAULT_PLAN_NAME;
        default:
          return assertUnreachable(withEventGroup);
      }
    });

    return eventGroupNames;
  },
);
