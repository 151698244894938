import React, { useContext, useMemo } from 'react';

import ObjectComparisonGrid from 'components/CompareScenariosModalContent/ObjectComparisonGrid';
import { StickyContext } from 'components/StickyHeader/StickyContext';
import VariableSizeVirtualizedVerticalList from 'components/VirtualizedList/VariableSizeVirtualizedVerticalList';
import { CELL_HEIGHT_IN_PX } from 'config/cells';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { useRequestDatabaseComparisonDataView } from 'hooks/useRequestDatabaseComparisonDataView';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { scenarioComparisonFieldsByObjectIdByObjectSpecIdSelector } from 'selectors/scenarioComparisonSelector';

interface Props {
  objectSpecId: BusinessObjectSpecId;
}

const estimateRowHeight = (diffCount: number) => 26.5 + diffCount * CELL_HEIGHT_IN_PX;

const DatabaseComparisonGrid: React.FC<Props> = ({ objectSpecId }) => {
  const comparisonObjects = useAppSelector((state) =>
    safeObjGet(scenarioComparisonFieldsByObjectIdByObjectSpecIdSelector(state)[objectSpecId]),
  );

  const { verticalScrollingTargetRef } = useContext(StickyContext);
  const entries = useMemo(() => Object.entries(comparisonObjects || {}), [comparisonObjects]);
  useRequestDatabaseComparisonDataView({ rows: entries });

  if (entries.length === 0) {
    return null;
  }

  return (
    <VariableSizeVirtualizedVerticalList
      verticalScrollTargetRef={verticalScrollingTargetRef}
      estimatedItemHeight={estimateRowHeight(entries.length > 0 ? (entries[0][1]?.length ?? 0) : 0)}
      overscanCount={1}
    >
      {entries.map(([objectId, diffs]) => {
        return (
          <ObjectComparisonGrid
            key={objectId}
            objectId={objectId}
            objectName={diffs[0].objectName}
            diffs={diffs}
          />
        );
      })}
    </VariableSizeVirtualizedVerticalList>
  );
};

export default DatabaseComparisonGrid;
