import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';

import { LayerName } from '@features/CompareScenarios/common/LayerName';
import { stopEventPropagation } from 'helpers/browserEvent';
import useAppDispatch from 'hooks/useAppDispatch';
import useControlledPopover from 'hooks/useControlledPopover';
import { commitLayer, deleteLayer } from 'reduxStore/actions/layerMutations';
import { closeScenarioComparisonModal } from 'reduxStore/actions/navigateTo';
import { Layer } from 'reduxStore/models/layers';
import { Trash } from 'vectors';

export const MergeChangesButton = ({
  currentLayer,
  mergeLayer,
}: {
  currentLayer: Layer;
  mergeLayer: Layer;
}) => {
  return (
    <Flex columnGap={2}>
      <DiscardButton currentLayer={currentLayer} />
      <MergeButton currentLayer={currentLayer} mergeLayer={mergeLayer} />
    </Flex>
  );
};

const MergeButton = ({ currentLayer, mergeLayer }: { currentLayer: Layer; mergeLayer: Layer }) => {
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover<
    HTMLDivElement,
    HTMLButtonElement
  >();

  const dispatch = useAppDispatch();

  const onCommitLayer = debounce(() => {
    if (currentLayer == null) {
      return;
    }
    dispatch(commitLayer(currentLayer.id));
    dispatch(closeScenarioComparisonModal());
  }, 300);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
      placement="bottom-end"
      offset={[0, 4]}
      closeOnBlur
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <PopoverTrigger>
        <Button ref={triggerRef} variant="accent" size="sm" aria-label="Merge changes">
          Merge Changes
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          ref={contentRef}
          onClick={stopEventPropagation}
          padding="4"
          minWidth="320px"
          maxWidth="520px"
          fontSize="xs"
        >
          <Box marginBottom="4">
            <Flex display="inline-flex" flexWrap="wrap" columnGap={3} rowGap="1" mb={4}>
              <LayerName layer={currentLayer} layerType="current" color="gray.600" />
              {'->'}
              <LayerName layer={mergeLayer} layerType="merge" color="gray.600" />
            </Flex>
            <Box>
              <Flex
                display="inline-flex"
                flexWrap="wrap"
                color="gray.600"
                fontSize="xs"
                fontWeight="500"
                mb={3}
              >
                Changes from
                <Text px={1} fontWeight="600">
                  {currentLayer.name}
                </Text>
                will be merged into
                <Text paddingLeft={1} fontWeight="600">
                  {mergeLayer.name}
                </Text>
                .
              </Flex>
              <Flex color="gray.600" fontSize="xs">
                After merging,
                <Text color="gray.600" fontWeight="600" px={1}>
                  {currentLayer.name}
                </Text>
                will be deleted.
              </Flex>
            </Box>
          </Box>
          <Flex columnGap="1" align="center" justify="end">
            <Button size="sm" variant="light" paddingY="2px" paddingX="6px" onClick={onClose}>
              Cancel
            </Button>
            <Button
              size="sm"
              variant="accent"
              aria-label={`Merge ${currentLayer.name}`}
              onClick={onCommitLayer}
            >
              Merge &quot;{currentLayer.name}&quot;
            </Button>
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const DiscardButton = ({ currentLayer }: { currentLayer: Layer }) => {
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover<
    HTMLDivElement,
    HTMLButtonElement
  >();

  const dispatch = useAppDispatch();

  const onCommitLayer = debounce(() => {
    if (currentLayer == null) {
      return;
    }
    dispatch(deleteLayer({ layerId: currentLayer.id }));
    dispatch(closeScenarioComparisonModal());
  }, 300);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
      placement="bottom-end"
      offset={[0, 4]}
      closeOnBlur
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <PopoverTrigger>
        <Button paddingX="2px" paddingY="2px" ref={triggerRef} size="sm" role="group">
          <Trash color="gray.500" boxSize="3" />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          ref={contentRef}
          onClick={stopEventPropagation}
          padding="5"
          fontSize="xs"
          minWidth="320px"
          maxWidth="460px"
        >
          <Box marginBottom="4" fontWeight="500">
            <Flex display="inline-flex" whiteSpace="nowrap" flexWrap="wrap" rowGap="1">
              <Text marginRight="2">Are you sure you want to discard</Text>
              <LayerName layer={currentLayer} layerType="current" color="gray.600" />?
            </Flex>
            <Flex mt={3}>All changes on this scenario will be removed.</Flex>
          </Box>
          <Flex columnGap="1" align="center" justify="end">
            <Button size="sm" variant="light" paddingY="2px" paddingX="6px" onClick={onClose}>
              Cancel
            </Button>
            <Button
              size="sm"
              backgroundColor="red.200"
              border="1px solid"
              px={2}
              borderColor="red.200"
              _hover={{ backgroundColor: 'red.300', borderColor: 'red.300' }}
              aria-label={`Discard ${currentLayer.name}`}
              color="red.600"
              fontSize="xs"
              onClick={onCommitLayer}
            >
              Discard &quot;{currentLayer.name}&quot;
            </Button>
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
