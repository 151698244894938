import { createCachedSelector } from 're-reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { isNotNull } from 'helpers/typescript';
import { BusinessObjectSpecId, getAllPropertyNames } from 'reduxStore/models/businessObjectSpecs';
import { businessObjectSpecSelector } from 'selectors/businessObjectSpecsSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { ParametricSelector } from 'types/redux';

export const businessObjectSpecPropertyNamesByIdSelector: ParametricSelector<
  BusinessObjectSpecId,
  NullableRecord<string, string>
> = createCachedSelector(
  businessObjectSpecSelector,
  driversByIdForLayerSelector,
  (spec, driversById) => {
    return getAllPropertyNames({ objectSpec: spec, driversById });
  },
)((_state, objectSpecId) => objectSpecId);

export const businessObjectSpecFieldNamesSelector: ParametricSelector<
  BusinessObjectSpecId,
  string[]
> = createCachedSelector(
  businessObjectSpecSelector,
  driversByIdForLayerSelector,
  (spec, driversById) => {
    const fieldNames = spec?.fields.map((f) => f.name) ?? [];
    const dimPropertyNames = spec?.collection?.dimensionalProperties.map((p) => p.name) ?? [];
    const driverPropertyNames =
      spec?.collection?.driverProperties
        .map((p) => driversById[p.driverId]?.name)
        .filter(isNotNull) ?? [];
    return [...fieldNames, ...dimPropertyNames, ...driverPropertyNames];
  },
)({
  keySelector: (_state, objectSpecId) => objectSpecId,
  selectorCreator: createDeepEqualSelector,
});
