import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import Formula from 'components/Formula/Formula';
import FormulaInput, { FormulaInputProps } from 'components/FormulaInput/FormulaInput';
import UnsupportedFormula from 'components/FormulaInput/UnsupportedFormula';
import useBlockContext from 'hooks/useBlockContext';

const FormulaInputWrapper: React.FC<FormulaInputProps> = (props) => {
  const { formulaDisplay, onCancel } = props;
  const isSupportedFormula = formulaDisplay == null || formulaDisplay.isEditingSupported;
  const { readOnly } = useBlockContext();
  // let the formula input handle this otherwise, since it has context about selection that this
  // component does not
  useHotkeys(
    'Escape',
    () => {
      if (readOnly || !isSupportedFormula) {
        onCancel();
      }
    },
    undefined,
    [readOnly, isSupportedFormula, onCancel],
  );

  if (readOnly) {
    return formulaDisplay != null ? <Formula formulaDisplay={formulaDisplay} /> : null;
  }

  return isSupportedFormula ? <FormulaInput {...props} /> : <UnsupportedFormula />;
};

export default FormulaInputWrapper;
