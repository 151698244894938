import {
  AgPieSeriesTooltipRendererParams,
  AgPolarChartOptions,
  AgPolarSeriesOptions,
} from 'ag-charts-community';
import React, { useMemo } from 'react';

import AgChart from 'components/AgGridComponents/AgChart/AgChart';
import { ChartDisplay, ChartGroupingType, ChartSize } from 'generated/graphql';
import { safeObjGet } from 'helpers/typescript';
import useBlockContext from 'hooks/useBlockContext';
import { DriverId } from 'reduxStore/models/drivers';

import { renderTooltip } from './agChartTooltips';
import { CHART_TOOLTIP_CLASSNAME } from './agCharts';
import { useChartDriverConfig, useChartDriverTimeSeriesData } from './chartDriverHooks';

interface AgNightingaleChartProps {
  driverIds: DriverId[];
  chartDisplay: ChartDisplay;
  size?: ChartSize;
  groupingType?: ChartGroupingType;
  stacked?: boolean;
  chartIndex?: number;
}

const AgNightingaleChart: React.FC<AgNightingaleChartProps> = ({
  driverIds,
  chartDisplay,
  chartIndex,
}) => {
  const { blockId } = useBlockContext();
  const {
    attributesBySubDriverId,
    chartConfig,
    colorById,
    dateRange,
    driverDisplayConfigurationsById,
    driverNamesById,
    height,
    width,
  } = useChartDriverConfig(blockId, driverIds, chartDisplay);

  const filteredDriverIds = useMemo(() => {
    if (chartIndex != null) {
      return [driverIds[chartIndex]];
    }
    return driverIds;
  }, [driverIds, chartIndex]);

  const { isAnyDriverLoading, data } = useChartDriverTimeSeriesData(
    filteredDriverIds,
    dateRange,
    chartDisplay,
  );

  const series = useMemo((): AgPolarSeriesOptions[] => {
    if (!chartConfig.chartDisplay) {
      return [];
    }

    return filteredDriverIds.map((driverId) => ({
      type: 'nightingale',
      angleKey: 'monthKey',
      radiusKey: driverId,
      radiusName: driverNamesById[driverId],
      grouped: true,
      fill: colorById[driverId],
      highlightStyle: {
        series: {
          enabled: true,
          dimOpacity: 0.5,
        },
      },
      label: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        showArrow: false,
        renderer: ({
          angleKey,
          radiusKey,
          title,
          datum,
        }: AgPieSeriesTooltipRendererParams<Record<string, number>>) => {
          const id = String(radiusKey);
          const key = String(angleKey);
          const value = Number(datum[id]);
          const displayConfiguration = driverDisplayConfigurationsById[id];
          const attributes = safeObjGet(attributesBySubDriverId[datum.id]);

          return renderTooltip(title, { id, key, value }, displayConfiguration, attributes);
        },
      },
    }));
  }, [
    attributesBySubDriverId,
    driverDisplayConfigurationsById,
    chartConfig,
    driverNamesById,
    filteredDriverIds,
    colorById,
  ]);

  const options = useMemo((): AgPolarChartOptions => {
    if (data == null || driverDisplayConfigurationsById == null) {
      return {
        width,
        height,
      };
    }

    return {
      data: isAnyDriverLoading ? [] : data,
      series,
      tooltip: {
        class: CHART_TOOLTIP_CLASSNAME,
        position: {
          type: 'pointer',
        },
      },
      legend: {
        enabled: typeof chartIndex === 'number' ? false : driverIds.length > 1,
        reverseOrder: true,
        position: 'right',
        preventHidingAll: true,
        item: {
          maxWidth: 200,
          label: {
            formatter: ({ itemId, value }) => {
              const driverId = String(itemId);
              const attributes = safeObjGet(attributesBySubDriverId[driverId]);

              if (attributes != null && attributes.length > 0) {
                let base = value;
                for (const attr of attributes) {
                  base += ` (${attr.value})`;
                }
                return base;
              }

              return value;
            },
          },
        },
      },
      width,
      height,
    };
  }, [
    attributesBySubDriverId,
    data,
    driverDisplayConfigurationsById,
    driverIds,
    isAnyDriverLoading,
    series,
    width,
    height,
    chartIndex,
  ]);

  return <AgChart isLoading={isAnyDriverLoading} options={options} />;
};

export default React.memo(AgNightingaleChart);
