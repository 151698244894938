import { Box, Center, Flex, Text } from '@chakra-ui/react';

interface Props {
  icon?: JSX.Element;
  title: string;
  subtitle: string | JSX.Element;
}

const SelectMenuFooter: React.FC<Props> = ({ icon, title, subtitle }) => {
  return (
    <Flex flexDirection="column" rowGap={1}>
      <Flex alignItems="center" columnGap={2}>
        {icon && (
          <Center flexShrink={0} width={4} height={4} color="gray.500">
            {icon}
          </Center>
        )}
        <Text fontWeight="bold" flexGrow={1} noOfLines={1}>
          {title}
        </Text>
      </Flex>
      <Box display="inline-block" color="gray.500" fontWeight="medium">
        {subtitle}
      </Box>
    </Flex>
  );
};

export default SelectMenuFooter;
