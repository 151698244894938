import { Button, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import React from 'react';

import MergeChangesPopoverContent from 'components/CompareScenariosModalContent/MergeChangesPopoverContent';
import Arrowhead from 'vectors/Arrowhead';

const MergeChangesButton: React.FC = () => {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button
          variant="accent"
          size="md"
          px={2}
          py={0}
          height="unset"
          aria-label="Merge changes"
          rightIcon={<Arrowhead boxSize={2} direction="down" color="currentColor" />}
        >
          Merge Changes
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <MergeChangesPopoverContent />
      </PopoverContent>
    </Popover>
  );
};

export default MergeChangesButton;
