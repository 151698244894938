import { Flex } from '@chakra-ui/react';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import { BLOCK_RESTRICTED_TOOLTIP_TEXT } from 'config/block';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { blockConfigShowRestrictedSelector } from 'selectors/blocksSelector';
import Lock from 'vectors/Lock';
import Unlock from 'vectors/Unlock';

const WithLock = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{ hasRestrictions: boolean }>
>(({ children, hasRestrictions }, ref) => {
  const { blockId } = useBlockContext();

  const showingRestrictedFields = useAppSelector((state) =>
    blockConfigShowRestrictedSelector(state, blockId),
  );

  return (
    <Flex
      ref={ref}
      columnGap={1}
      alignItems="center"
      overflow="hidden"
      color={showingRestrictedFields && hasRestrictions ? 'yellow.600' : 'gray.500'}
      width="fit-content"
    >
      {children}
      {hasRestrictions && (showingRestrictedFields ? <Unlock boxSize={3} /> : <Lock boxSize={3} />)}
    </Flex>
  );
});

const RestrictedColumnHeaderWrapper: React.FC<
  React.PropsWithChildren<{
    hasRestrictions?: boolean;
    withTooltip?: boolean;
  }>
> = ({ children, hasRestrictions = false, withTooltip = true }) => {
  const { blockId } = useBlockContext();

  const showingRestrictedFields = useAppSelector((state) =>
    blockConfigShowRestrictedSelector(state, blockId),
  );

  const tooltipText = showingRestrictedFields
    ? BLOCK_RESTRICTED_TOOLTIP_TEXT.SHOW_RESTRICTED
    : BLOCK_RESTRICTED_TOOLTIP_TEXT.RESTRICTED;

  if (!withTooltip) {
    return <WithLock hasRestrictions={hasRestrictions}>{children}</WithLock>;
  }
  return (
    <Tooltip label={tooltipText} isDisabled={!hasRestrictions}>
      <WithLock hasRestrictions={hasRestrictions}>{children}</WithLock>
    </Tooltip>
  );
};

export default React.memo(RestrictedColumnHeaderWrapper);
