import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'Italics',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <path d="M7.39504 14.3523H5.75254C5.65629 14.3523 5.56504 14.3085 5.50504 14.2335C5.44379 14.1585 5.42004 14.0598 5.44004 13.966L8.03254 1.62854C8.06254 1.47979 8.19379 1.37354 8.34504 1.37354H9.98754C10.0838 1.37354 10.175 1.41729 10.235 1.49229C10.2963 1.56729 10.32 1.66478 10.3 1.75978L7.70754 14.0985C7.67629 14.246 7.54629 14.3523 7.39504 14.3523Z" />
  ),
});
