import { createCachedSelector } from 're-reselect';

import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { blockConfigObjectFieldSpecAsTimeSeriesIdSelector } from 'selectors/blocksSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { visibleColumnsSelector } from 'selectors/visibleColumnTypesSelector';
import { ParametricSelector } from 'types/redux';

export const visibleFieldSpecTimeSeriesSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId | null
> = createCachedSelector(
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  visibleColumnsSelector,
  (fieldSpecId, visibleColumns) =>
    visibleColumns.find((col) => col.visible && col.key === fieldSpecId)?.key ?? null,
)(blockIdSelector);
