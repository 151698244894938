import { Box, Button, Center } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef } from 'react';
import { Instance } from 'tippy.js';

import { useAddDriversPopoverContext } from 'config/driverGridContext';
import Plus from 'vectors/Plus';

interface Props {
  onOpenDriverSelect: () => void;
  isAdding: boolean;
}

const DriverBlockEmptyStateButton: React.FC<Props> = ({ onOpenDriverSelect, isAdding }) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [{ reference }, setAddDriverPopoverContext] = useAddDriversPopoverContext();

  const onClick = useCallback(() => {
    setAddDriverPopoverContext({
      reference: anchorRef.current,
    });
    onOpenDriverSelect();
  }, [onOpenDriverSelect, setAddDriverPopoverContext]);

  useEffect(() => {
    // ensures reference is maintained for the Driver Popover's tippy instance to consume
    setAddDriverPopoverContext({
      reference: anchorRef.current,
    });
  }, [reference, setAddDriverPopoverContext]);

  useEffect(() => {
    // Ensures that the tippy instance does not cause flicker when the reference
    // for the tippy instance changes.
    // https://atomiks.github.io/tippyjs/v5/tippy-instance/#-property
    const tippy = (reference as unknown as undefined | { _tippy: Instance | undefined })?._tippy;
    return () => {
      if (!tippy || tippy.state.isDestroyed || !tippy.state.isMounted) {
        return;
      }
      tippy.unmount();
    };
  }, [reference, setAddDriverPopoverContext]);

  return (
    <Center
      role="group"
      position="absolute"
      cursor="pointer"
      top={0}
      left={0}
      width="full"
      height="full"
      onClick={onClick}
      bgColor={isAdding ? 'blackAlpha.50' : undefined}
      _hover={{ bgColor: 'blackAlpha.50' }}
    >
      <Button
        leftIcon={<Plus color="gray.500" />}
        visibility="hidden"
        _groupHover={{ visibility: 'visible' }}
      >
        Add drivers
      </Button>
      <Box ref={anchorRef} position="absolute" left="50%" top={0} />
    </Center>
  );
};

export default DriverBlockEmptyStateButton;
