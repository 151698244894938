import { FocusLock } from '@chakra-ui/focus-lock';
import { Box, Checkbox, Flex, Portal, Text } from '@chakra-ui/react';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';

import { useBlockFilterContext } from 'components/BlockFilterContext/BlockFilterContext';
import FilterMenuItem from 'components/FilterMenuItem/FilterMenuItem';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import { AddButton } from 'components/PlanTimelineNavigationPanelContents/AddRowButton';
import { BlockFilterOperator, ValueType } from 'generated/graphql';
import { copyWithRemovedItem, copyWithReplacedItem } from 'helpers/array';
import { preventEventDefault, stopEventPropagation } from 'helpers/browserEvent';
import useAppSelector from 'hooks/useAppSelector';
import { enableDriverThisSegmentSelector } from 'selectors/launchDarklySelector';
import { FilterItem, FilterValueTypes } from 'types/filtering';
import { NO_ATTR } from 'types/formula';

interface Props {
  onClose?: () => void;
  renderInPortal?: boolean;
}

const DEFAULT_OPERATORS: Partial<Record<ValueType | FilterValueTypes, BlockFilterOperator>> = {
  [ValueType.Number]: BlockFilterOperator.Equals,
  [ValueType.Attribute]: BlockFilterOperator.Equals,
  [ValueType.Timestamp]: BlockFilterOperator.Equals,
  [FilterValueTypes.ENTITY]: BlockFilterOperator.Equals,
  [FilterValueTypes.FORMULA]: BlockFilterOperator.IsNull,
  [FilterValueTypes.EXT_TABLE]: BlockFilterOperator.Equals,
};

const FilterMenu: React.FC<Props> = ({ onClose, renderInPortal = false }) => {
  const {
    onUpdateFilters,
    onDoneFilters,
    filters,
    availableFilters,
    entityInfo,
    includeAllContextAttributes,
    matchToSingleResult,
    toggleIncludeAllContextAttributes,
    toggleMatchToSingleResult,
  } = useBlockFilterContext();
  // To use inside callbacks
  const filtersRef = useRef(filters);
  filtersRef.current = filters;

  const isValidEntity = entityInfo != null;
  const firstFilter = availableFilters[0];
  const enableContextAttributes = useAppSelector(enableDriverThisSegmentSelector);

  const onAdd = useCallback(() => {
    // TODO: Should just add the first filter field that is not being used yet.
    if (firstFilter == null) {
      return;
    }
    // ID is a special case where we don't want to add a default value of 'NONE'
    const shouldAddDefaultFilterValue = firstFilter.valueType !== FilterValueTypes.ENTITY;
    const newFilter = {
      ...firstFilter,
      operator: DEFAULT_OPERATORS[firstFilter.valueType] ?? BlockFilterOperator.Equals,
      expected: shouldAddDefaultFilterValue ? [NO_ATTR] : [],
    } as FilterItem;

    onUpdateFilters([...filtersRef.current, newFilter]);
  }, [onUpdateFilters, firstFilter]);

  const onUpdateIdx = useCallback(
    (idx: number, filter: FilterItem) => {
      const newFilters = copyWithReplacedItem(filtersRef.current, idx, filter);
      onUpdateFilters(newFilters);
    },
    [onUpdateFilters],
  );

  const onDelete = useCallback(
    (idx: number) => {
      const newFilters = copyWithRemovedItem(filtersRef.current, idx);
      onUpdateFilters(newFilters);
    },
    [onUpdateFilters],
  );

  // TODO: This is not great separation of concerns.
  // Goal would be to have logic for handling closing in one place not here and in containing component
  // Handling keypress may cause issues if multiple components are listening
  useEffect(() => {
    const onKeyDown = (ev: KeyboardEvent) => {
      switch (ev.key) {
        case 'ArrowLeft':
        case 'ArrowRight': {
          if (onClose != null) {
            onClose();
          }
          break;
        }
        case 'Escape': {
          preventEventDefault(ev);
          if (onClose != null) {
            onClose();
          }
          break;
        }
        case 'Enter': {
          preventEventDefault(ev);
          onDoneFilters?.();
          break;
        }
        default: {
          // Prevent key press from bubbling up and closing the filter menu
          stopEventPropagation(ev);
          break;
        }
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose, onDoneFilters]);

  return (
    <FocusLock persistentFocus>
      <PortalWrapper shouldRenderInPortal={renderInPortal}>
        <Flex
          data-testid="filter-menu"
          minW="30rem"
          bgColor="surface"
          flexDirection="column"
          py={4}
          pt={3}
          borderRadius={6}
          pb={availableFilters.length === 0 ? 4 : 2}
          rowGap={2}
        >
          <Flex justifyContent="space-between" width="full" alignItems="center">
            {isValidEntity && (
              <Flex gap={1} alignItems="center">
                <Text fontSize="xs" fontWeight="semibold" pl={4} borderBottomColor="gray.600">
                  {entityInfo.label}
                </Text>
                <OpenDetailsModalButton
                  type={entityInfo.type}
                  id={entityInfo.id}
                  onClick={onClose}
                />
              </Flex>
            )}
            {enableContextAttributes &&
              matchToSingleResult != null &&
              toggleMatchToSingleResult != null && (
                <Checkbox
                  defaultChecked={matchToSingleResult}
                  alignSelf="flex-end"
                  size="sm"
                  onChange={toggleMatchToSingleResult}
                  pr={4}
                >
                  <Text fontSize="xs">Return single result</Text>
                </Checkbox>
              )}
          </Flex>
          {isValidEntity && (
            <FilterMenuItem
              filter={{
                valueType: ValueType.Timestamp,
                filterKey: 'entityTimePeriod',
                label: 'Date',
                operator: BlockFilterOperator.Equals,
              }}
              updateFilter={noop}
              deleteFilter={noop}
              isFirst
            />
          )}
          {filters.map((filter, idx) => (
            <FilterMenuItem
              key={idx}
              filter={filter}
              updateFilter={(update) => onUpdateIdx(idx, update)}
              deleteFilter={() => onDelete(idx)}
              isFirst={idx === 0 && !isValidEntity}
            />
          ))}
          <Flex justifyContent="space-between" width="full" alignItems="center">
            <Box ml={2}>
              {availableFilters.length > 0 && <AddButton text="Add rule" onClick={onAdd} />}
            </Box>
            {enableContextAttributes &&
              includeAllContextAttributes != null &&
              toggleIncludeAllContextAttributes != null && (
                <Checkbox
                  defaultChecked={includeAllContextAttributes}
                  size="sm"
                  onChange={toggleIncludeAllContextAttributes}
                  pr={4}
                >
                  <Text fontSize="xs"> Match all context attributes</Text>
                </Checkbox>
              )}
          </Flex>
        </Flex>
      </PortalWrapper>
    </FocusLock>
  );
};

interface PortalWrapperProps {
  shouldRenderInPortal: boolean;
  children: React.ReactNode;
}
const PortalWrapper: React.FC<PortalWrapperProps> = ({ children, shouldRenderInPortal }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  if (shouldRenderInPortal) {
    return (
      <Flex ref={containerRef}>
        <Portal containerRef={containerRef}>{children}</Portal>
      </Flex>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default FilterMenu;
