import { useContext } from 'react';

import { BlockReactContext } from 'config/blockContext';

export default function useBlockContext() {
  const blockReactContext = useContext(BlockReactContext);
  if (blockReactContext == null) {
    throw new Error('cannot use blockReactContext');
  }

  return blockReactContext;
}

export function useOptionalBlockId() {
  const blockReactContext = useContext(BlockReactContext);
  return blockReactContext?.blockId;
}
