import { createCachedSelector } from 're-reselect';

import { HARD_CODED_ACTUAL_TOOLTIP } from 'components/AgGridComponents/CellRenderer/constants';
import { cellValueStyle } from 'components/AgGridComponents/CellRenderer/helpers';
import { CellValueTooltipData } from 'config/cells';
import { layerParamMemo } from 'helpers/layerSelectorFactory';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectFieldSpecIsLinkedSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectFieldActualsOverrideMonthKeysSelector } from 'selectors/businessObjectsSelector';
import { fieldSelector } from 'selectors/constSelectors';
import { objectPlanImpactTooltipSelector } from 'selectors/eventsAndGroupsSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { MonthKey } from 'types/datetime';
import { ParametricSelector } from 'types/redux';

type BusinessObjectFieldTooltipValueSelectorProps = {
  objectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  monthKey: MonthKey;
  blockId: BlockId;
  layerId: LayerId;
};

export const objectFieldValueTooltipSelector: ParametricSelector<
  BusinessObjectFieldTooltipValueSelectorProps,
  CellValueTooltipData | undefined
> = createCachedSelector(
  (state: RootState, { layerId }: BusinessObjectFieldTooltipValueSelectorProps) =>
    lastActualsMonthKeyForLayerSelector(state, layerParamMemo(layerId)),
  (state: RootState, { objectId, fieldSpecId }: BusinessObjectFieldTooltipValueSelectorProps) =>
    businessObjectFieldActualsOverrideMonthKeysSelector(state, { objectId, fieldSpecId }),
  fieldSelector('monthKey'),
  (
    state: RootState,
    { objectId, monthKey, fieldSpecId, layerId }: BusinessObjectFieldTooltipValueSelectorProps,
  ) => objectPlanImpactTooltipSelector(state, { objectId, monthKey, fieldSpecId, layerId }),
  (state: RootState, { fieldSpecId, layerId }) =>
    businessObjectFieldSpecIsLinkedSelector(state, { id: fieldSpecId, layerId }),
  (
    lastActualsMonthKey,
    overrideMonthKeys,
    monthKey,
    objectPlanImpactTooltip,
    fieldSpecIsLinked,
  ) => {
    const isActuals = monthKey <= lastActualsMonthKey;
    const isOverridden = overrideMonthKeys.includes(monthKey);

    const valueStyle = cellValueStyle({
      backingType: 'objectField',
      isActuals,
      hasHardcodedActual: isOverridden,
      fieldHasIntegrationActuals: fieldSpecIsLinked,
      hasImpact: objectPlanImpactTooltip != null,
      // not a field
      driverHasActualsFormula: false,
    });

    let msg: string | undefined;
    switch (valueStyle) {
      case 'actuals-hardcoded':
        msg = HARD_CODED_ACTUAL_TOOLTIP;
        break;
      default:
        msg = objectPlanImpactTooltip ?? undefined;
        break;
    }

    if (msg == null) {
      return undefined;
    }

    const tooltipData: CellValueTooltipData = {
      hasUnderline: true,
      content: { type: valueStyle, msg },
    };

    return tooltipData;
  },
)((_state, { objectId, fieldSpecId, monthKey, blockId, layerId }) =>
  [objectId, fieldSpecId, monthKey, blockId, layerId].join(','),
);
