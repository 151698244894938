import { useBoolean } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import Script from 'next/script';
import { useEffect } from 'react';

import { isDevelopment } from 'helpers/environment';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { showIntercomOnPageSelector } from 'selectors/pageSelector';
import { loadedSelectedOrgSelector } from 'selectors/selectedOrgSelector';

// from https://app.intercom.com/a/apps/seuccbcq/home?step=set_up_messenger_visitors
const SCRIPT_SOURCE = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/seuccbcq';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

const IntercomMessenger = () => {
  const authenticatedUser = useAppSelector(authenticatedUserSelector);
  const selectedOrg = useAppSelector(loadedSelectedOrgSelector);
  const dispatch = useAppDispatch();
  const page = useAppSelector(pageSelector);
  const [booted, setBooted] = useBoolean(false);
  const showIntercomOnPage = useAppSelector(showIntercomOnPageSelector);

  useEffect(() => {
    if (authenticatedUser == null || !('Intercom' in window)) {
      return;
    }

    if (!showIntercomOnPage) {
      if (booted) {
        window.Intercom('update', { hide_default_launcher: true });
      }
      return;
    }

    if (booted) {
      window.Intercom('update', { hide_default_launcher: false });
    } else {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'seuccbcq',
        name: authenticatedUser.name,
        user_id: authenticatedUser.id,
        email: authenticatedUser.email,
        created_at: DateTime.fromISO(authenticatedUser.createdAt).toUnixInteger(),
        company: selectedOrg != null ? { id: selectedOrg.id, name: selectedOrg.name } : undefined,
        user_hash: authenticatedUser.intercomHash ?? undefined,
      });
      setBooted.on();
    }
  }, [booted, dispatch, authenticatedUser, selectedOrg, setBooted, showIntercomOnPage]);

  useEffect(() => {
    if (booted && 'Intercom' in window) {
      window.Intercom('update');
    }
  }, [booted, page]);

  if (isDevelopment) {
    return null;
  }

  return (
    <Script
      id="intercom"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: SCRIPT_SOURCE,
      }}
    />
  );
};

export default IntercomMessenger;
