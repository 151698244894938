import { DeleteIcon, TimeIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Spacer, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import EscapableInput from 'components/EscapableInput/EscapableInput';
import { stopEventPropagation } from 'helpers/browserEvent';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useNamedVersionValidator from 'hooks/useNamedVersionValidator';
import {
  deleteNamedDatasetVersion,
  updateNamedDatasetVersion,
} from 'reduxStore/actions/layerMutations';
import { Layer } from 'reduxStore/models/layers';
import { featureFlagsSelector } from 'selectors/featureFlagsSelector';
import { namedDatasetVersionListSelector } from 'selectors/layerSelector';

interface DatasetVersionsListItemProps {
  popoverLayerId: string | undefined;
  setPopoverLayerId: React.Dispatch<React.SetStateAction<string | undefined>>;
  version: Layer;
  onSelectVersion: (layerId: string) => void;
}

const DatasetVersionsListItem: React.FC<DatasetVersionsListItemProps> = ({
  popoverLayerId,
  setPopoverLayerId,
  version,
  onSelectVersion,
}) => {
  const dispatch = useAppDispatch();

  const deleteVersion = useCallback(() => {
    dispatch(deleteNamedDatasetVersion(version.id));
  }, [dispatch, version.id]);

  const namedVersionValidator = useNamedVersionValidator();
  const jumpToNamedVersionEnabled = useAppSelector(featureFlagsSelector).enableTimeTravels;

  const saveCurrentVersion = useCallback(
    (versionId: string, name: string) => {
      dispatch(updateNamedDatasetVersion(versionId, name));
      setPopoverLayerId(undefined);
    },
    [dispatch, setPopoverLayerId],
  );
  return (
    <Flex fontWeight="medium" fontSize="xs">
      <Box flex={1} position="relative" left={-1}>
        {popoverLayerId === version.id ? (
          <EscapableInput
            onBlur={(ev) => {
              setPopoverLayerId(undefined);
              stopEventPropagation(ev);
            }}
            autoFocus
            validationSchema={namedVersionValidator}
            variant="white"
            borderRadius="base"
            fontWeight="medium"
            size="xs"
            name="versionName"
            defaultValue={version.name}
            onSave={(name) => saveCurrentVersion(version.id, name)}
            onCancel={() => {
              setPopoverLayerId(undefined);
            }}
            position="relative"
            top="-1px"
            pl={1}
            left="-2px"
            pb="2px"
          />
        ) : (
          <Text
            cursor="pointer"
            display="inline-block"
            borderRadius="md"
            px={1}
            _hover={{ bgColor: 'gray.200' }}
            onClick={() => setPopoverLayerId(version.id)}
          >
            {version.name}
          </Text>
        )}
        <Spacer />
      </Box>
      {jumpToNamedVersionEnabled && (
        <IconButton
          data-testid="time-travel"
          variant="icon"
          aria-label="Select version"
          color="gray.500"
          boxSize={6}
          _hover={{ color: 'red.500', bgColor: 'gray.200' }}
          onClick={() => onSelectVersion(version.id)}
          icon={<TimeIcon boxSize={3} />}
        />
      )}
      <IconButton
        variant="icon"
        aria-label="Delete version"
        color="gray.500"
        boxSize={6}
        _hover={{ color: 'red.500', bgColor: 'gray.200' }}
        onClick={deleteVersion}
        icon={<DeleteIcon boxSize={3} />}
      />
    </Flex>
  );
};

interface Props {
  popoverLayerId: string | undefined;
  setPopoverLayerId: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSelectVersion: (layerId: string) => void;
}

const DatasetVersionsList: React.FC<Props> = ({
  popoverLayerId,
  setPopoverLayerId,
  onSelectVersion,
}) => {
  const namedDatasetVersions = useAppSelector(namedDatasetVersionListSelector);
  return (
    <Flex flexDirection="column" padding={2} rowGap={1}>
      {namedDatasetVersions.map((version) => (
        <DatasetVersionsListItem
          key={version.id}
          version={version}
          popoverLayerId={popoverLayerId}
          setPopoverLayerId={setPopoverLayerId}
          onSelectVersion={onSelectVersion}
        />
      ))}
    </Flex>
  );
};

export default DatasetVersionsList;
