import { Checkbox as ChakraCheckbox, CheckboxProps } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const Checkbox = ({
  _checked: defaultChecked,
  _indeterminate: defaultIndeterminate,
  borderColor: defaultBorderColor,
  ...props
}: CheckboxProps) => {
  const [_checked, setChecked] = useState<CheckboxProps['_checked']>(() => {
    return buildChecked(defaultChecked);
  });
  useEffect(() => {
    setChecked((currentChecked) => {
      const newChecked = buildChecked(defaultChecked);
      if (isEqual(currentChecked, newChecked)) {
        return currentChecked;
      }
      return newChecked;
    });
  }, [defaultChecked]);

  const [_indeterminate, setIndeterminate] = useState<CheckboxProps['_indeterminate']>(() => {
    return buildIndeterminate(defaultIndeterminate);
  });
  useEffect(() => {
    setIndeterminate((currentIndeterminate) => {
      const newIndeterminate = buildIndeterminate(defaultIndeterminate);
      if (isEqual(currentIndeterminate, newIndeterminate)) {
        return currentIndeterminate;
      }
      return newIndeterminate;
    });
  }, [defaultIndeterminate]);

  const borderColor = useMemo(() => {
    return defaultBorderColor ?? 'gray.300';
  }, [defaultBorderColor]);

  return (
    <ChakraCheckbox
      {...props}
      borderColor={borderColor}
      _checked={_checked}
      aria-checked={props.isIndeterminate}
      _indeterminate={_indeterminate}
    />
  );
};

function buildChecked(defaultChecked: CheckboxProps['_checked']) {
  return {
    '& .chakra-checkbox__control': {
      borderRadius: '4px',
      background: 'selection.500',
      borderColor: 'selection.500',
    },
    ...defaultChecked,
  };
}

function buildIndeterminate(defaultIndeterminate: CheckboxProps['_indeterminate']) {
  return {
    '& [data-indeterminate]': {
      borderRadius: '4px',
      background: 'selection.500',
      borderColor: 'selection.500',
    },
    ...defaultIndeterminate,
  };
}
