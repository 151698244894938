import React, { memo, useEffect, useRef } from 'react';

import { ListType, ScrollToInfo } from 'components/VirtualizedList/types';

interface Props {
  listType: ListType;
  scrollInfo: ScrollToInfo | null;
  scroller: HTMLElement | null;
  onScrollItemInRange?: (scrollInfo: ScrollToInfo | null) => void;
  children: React.ReactNode;
  id: string | number;
  style?: React.CSSProperties;
}

const DELAY_MS = 500; // a small delay to allow for measurements to be taken. See comment below.

/**
 * This component is used to detect when an item X, to be scrolled to, is in view. This is
 * important for variable size lists since measurements of the items are refined over time.
 * This means when we scroll to item X, we might get the initial position wrong. A next attempt
 * after a delay gives us the right position since by that time, measurement of items before X has been taken
 * and so we can get the right position of X.
 */
const ScrollToItem: React.FC<Props> = ({
  children,
  onScrollItemInRange,
  listType,
  scrollInfo,
  scroller,
  id,
  style,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const element = ref.current;
      if (element != null && scrollInfo != null && scroller != null) {
        let newScrollInfo: ScrollToInfo;
        const elementBoundingRect = element.getBoundingClientRect();
        if (listType === 'vertical') {
          newScrollInfo = {
            indexToScrollTo: scrollInfo.indexToScrollTo,
            lastKnownPosition: elementBoundingRect.top,
          };
        } else {
          newScrollInfo = {
            indexToScrollTo: scrollInfo.indexToScrollTo,
            lastKnownPosition: elementBoundingRect.left,
          };
        }

        const scrollerBoundingRect = scroller.getBoundingClientRect();
        if (
          listType === 'vertical' &&
          newScrollInfo?.lastKnownPosition != null &&
          scrollerBoundingRect.y <= newScrollInfo?.lastKnownPosition &&
          newScrollInfo.lastKnownPosition <= scrollerBoundingRect.y + scroller.offsetHeight
        ) {
          /**
           * Setting to null helps us stop being in "scrollTo" mode.
           * Otherwise the useEffect in useListScrollToInternal that tries to find the right item will keep running and
           * it'll prevent scrolling on the list
           */
          onScrollItemInRange?.(null);
        } else if (
          listType === 'horizontal' &&
          newScrollInfo?.lastKnownPosition != null &&
          scrollerBoundingRect.x <= newScrollInfo.lastKnownPosition &&
          newScrollInfo.lastKnownPosition <= scrollerBoundingRect.x + scroller.offsetWidth
        ) {
          // See comment above
          onScrollItemInRange?.(null);
        } else {
          onScrollItemInRange?.(newScrollInfo);
        }
      }
    }, DELAY_MS);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [listType, onScrollItemInRange, scrollInfo, scroller]);

  return (
    <div key={id} style={style} ref={ref}>
      {children}
    </div>
  );
};

export default memo(ScrollToItem);
