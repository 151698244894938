import { Flex, Spacer, Text } from '@chakra-ui/react';
import React from 'react';

import {
  BaselineCursorValue,
  ComparisonCursorValues,
} from 'components/DriverChartCardHeader/FormattedCursorValue';
import MilestoneProgressLabel from 'components/DriverChartCardHeader/MilestoneProgressLabel';
import { getDateTimeFromMonthKey, shortMonthFormat } from 'helpers/dates';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { DriverId } from 'reduxStore/models/drivers';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import {
  chartDisplayMonthKeySelector,
  chartDisplayMonthKeyWithDefaultSelector,
} from 'selectors/driverChartSelectors';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

interface Props {
  driverId: DriverId;
}

const CursorDisplayRow: React.FC<Props> = ({ driverId }) => {
  const { blockId } = useBlockContext();
  const cursorMonthKey = useAppSelector(chartDisplayMonthKeySelector);
  const monthKey = useAppSelector(chartDisplayMonthKeyWithDefaultSelector);
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );

  const ts = useAppSelector((state) =>
    driverTimeSeriesForLayerSelector(state, { id: driverId, layerId: baselineLayerId }),
  );

  const isComparing = comparisonLayerIds.length > 0;
  const baselineValue = ts[monthKey];
  const hasBaselineData = baselineValue != null;

  return (
    <Flex flexDir="column">
      {!hasBaselineData ? (
        <Flex alignItems="baseline" columnGap={1}>
          <Text color="gray.600" fontSize="lg" fontWeight="medium">
            -
          </Text>
        </Flex>
      ) : isComparing ? (
        <ComparisonCursorValues monthKey={monthKey} driverId={driverId} />
      ) : (
        <BaselineCursorValue monthKey={monthKey} value={baselineValue} />
      )}
      <Flex>
        <Text color="gray.500" fontSize="xxxs" fontWeight="bold" textTransform="uppercase">
          {cursorMonthKey == null
            ? 'Last Close'
            : shortMonthFormat(getDateTimeFromMonthKey(cursorMonthKey))}
        </Text>
        <Spacer />
        {!isComparing && <MilestoneProgressLabel layerId={currentLayerId} />}
      </Flex>
    </Flex>
  );
};

export default CursorDisplayRow;
