import React, { useCallback, useMemo } from 'react';

import DriverSearchResult from 'components/DriverSearchResult/DriverSearchResult';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS } from 'config/selectMenu';
import { isNotNull } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { accessibleDriversByIdForLayerSelector } from 'selectors/accessibleDriversSelector';

type DriverGroupSelectItem = SelectItem &
  ({ type: 'selectAll' } | { type: 'driver'; driver: Driver });

const SECTIONS: Section[] = [
  {
    id: 'selectAll',
  },
  {
    id: 'driver',
    name: 'Drivers',
    maxResults: 10,
    showMore: true,
  },
];

interface Props {
  driverIds: DriverId[];
  addDrivers: (driverIds: DriverId[]) => void;
  onClose: () => void;
}

const DriverGroupSelect: React.FC<Props> = ({ driverIds, addDrivers, onClose }) => {
  const driversById = useAppSelector(accessibleDriversByIdForLayerSelector);

  const items = useMemo<DriverGroupSelectItem[]>(() => {
    const driverItems = driverIds
      .map((driverId) => {
        const driver = driversById[driverId];
        if (driver == null) {
          return null;
        }
        const { name } = driver;
        return {
          id: driverId,
          type: 'driver' as const,
          driver,
          name,
          sectionId: 'driver',
          meta: (
            <OpenDetailsModalButton
              type="driver"
              id={driverId}
              onClick={onClose}
              className={SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS}
            />
          ),
        };
      })
      .filter(isNotNull);

    return [
      {
        id: 'selectAll',
        type: 'selectAll',
        sectionId: 'selectAll',
        name: 'Add all drivers',
      },
      ...driverItems,
    ];
  }, [driverIds, driversById, onClose]);

  const onSelect = useCallback(
    (item: DriverGroupSelectItem) => {
      switch (item.type) {
        case 'selectAll': {
          addDrivers(driverIds);
          break;
        }
        case 'driver': {
          addDrivers([item.id]);
          break;
        }
        default: {
          break;
        }
      }
    },
    [addDrivers, driverIds],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu items={items} onSelect={onSelect} sections={SECTIONS} width="20rem">
        {({ item, isFocused, idx }) => {
          switch (item.type) {
            case 'driver': {
              return (
                <DriverSearchResult
                  driver={item.driver}
                  isFocused={isFocused}
                  idx={idx}
                  isChecked={item.isChecked}
                  meta={item.meta}
                />
              );
            }
            default: {
              return <BaseSelectMenuItem item={item} isFocused={isFocused} idx={idx} />;
            }
          }
        }}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default DriverGroupSelect;
