import partition from 'lodash/partition';
import { createCachedSelector } from 're-reselect';

import { NAME_COLUMN_TYPE } from 'config/modelView';
import { toSortedArray } from 'helpers/array';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { BlockId, ObjectTableBlockColumnKey } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  DimensionalProperty,
  DimensionalPropertyId,
  DriverProperty,
  DriverPropertyId,
} from 'reduxStore/models/collections';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  blockCanHidePropertiesSelector,
  blockConfigBusinessObjectSpecIdSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  blockConfigSelector,
} from 'selectors/blocksSelector';
import {
  businessObjectSpecSelector,
  businessObjectSpecsByIdForLayerSelector,
} from 'selectors/businessObjectSpecsSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import {
  businessObjectFieldSpecIdsSelector,
  businessObjectSpecForBlockSelector,
  configurableObjectTableColumnsSelector,
  orderedFieldSpecIdsToShowForObjectTableSelector,
} from 'selectors/orderedFieldSpecIdsSelector';
import { ParametricSelector } from 'types/redux';

const dimensionalPropertyIdsForBlockSelector: ParametricSelector<BlockId, DimensionalPropertyId[]> =
  createCachedSelector(businessObjectSpecForBlockSelector, (spec) => {
    if (spec == null || spec.collection == null) {
      return [];
    }
    return spec.collection.dimensionalProperties.map((f) => f.id);
  })({
    keySelector: blockIdSelector,
    selectorCreator: createDeepEqualSelector,
  });

const businessObjectSpecDimensionalPropertiesSelector: ParametricSelector<
  BusinessObjectSpecId,
  DimensionalProperty[]
> = createCachedSelector(businessObjectSpecSelector, (spec) => {
  if (spec == null || spec.collection == null) {
    return [];
  }
  return spec.collection.dimensionalProperties;
})({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const businessObjectSpecDimensionalPropertyKeysSelector: ParametricSelector<
  BusinessObjectFieldSpecId,
  DimensionalProperty[]
> = createCachedSelector(businessObjectSpecDimensionalPropertiesSelector, (dimProperties) => {
  return dimProperties.filter((d) => d.isDatabaseKey);
})({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

const orderedDimensionalPropertyColumnsForBlockSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId[]
> = createCachedSelector(
  dimensionalPropertyIdsForBlockSelector,
  configurableObjectTableColumnsSelector,
  (dimensionalPropertyIds, orderedColumns) => {
    return toSortedArray(
      dimensionalPropertyIds,
      orderedColumns.map((c) => c.key),
    );
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const orderedDimensionalPropertyColumnsToShowForBlockSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId[]
> = createCachedSelector(
  orderedDimensionalPropertyColumnsForBlockSelector,
  configurableObjectTableColumnsSelector,
  blockCanHidePropertiesSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  (orderedDimPropertyIds, orderedColumns, canHide, fieldSpecAsTimeSeriesId) => {
    const hiddenColumnKeys = orderedColumns
      .filter((c) => canHide && c.visible === false)
      .map((c) => c.key);
    return orderedDimPropertyIds.filter(
      (id) => !hiddenColumnKeys.includes(id) && id !== fieldSpecAsTimeSeriesId,
    );
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

const driverPropertiesForBlockSelector: ParametricSelector<BlockId, DriverProperty[]> =
  createCachedSelector(businessObjectSpecForBlockSelector, (spec) => {
    if (spec == null || spec.collection == null) {
      return [];
    }
    return spec.collection.driverProperties;
  })({
    keySelector: blockIdSelector,
    selectorCreator: createDeepEqualSelector,
  });

const driverPropertyIdsForBlockSelector: ParametricSelector<BlockId, DriverPropertyId[]> =
  createCachedSelector(driverPropertiesForBlockSelector, (driverProperties) => {
    return driverProperties.map((f) => f.id);
  })({
    keySelector: blockIdSelector,
    selectorCreator: createDeepEqualSelector,
  });

export const orderedDriverPropertyColumnsForBlockSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId[]
> = createCachedSelector(
  driverPropertyIdsForBlockSelector,
  configurableObjectTableColumnsSelector,
  (driverPropertyIds, orderedColumns) => {
    return toSortedArray(
      driverPropertyIds,
      orderedColumns.map((c) => c.key),
    );
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const orderedDriverPropertyColumnsToShowForBlockSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId[]
> = createCachedSelector(
  orderedDriverPropertyColumnsForBlockSelector,
  configurableObjectTableColumnsSelector,
  blockCanHidePropertiesSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  (orderedDriverPropertyIds, orderedColumns, canHide, fieldSpecAsTimeSeriesId) => {
    const hiddenColumnKeys = orderedColumns
      .filter((c) => canHide && c.visible === false)
      .map((c) => c.key);
    return orderedDriverPropertyIds.filter(
      (id) => !hiddenColumnKeys.includes(id) && id !== fieldSpecAsTimeSeriesId,
    );
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const orderedColumnKeysForObjectTableSelector: ParametricSelector<
  BlockId,
  ObjectTableBlockColumnKey[]
> = createCachedSelector(
  dimensionalPropertyIdsForBlockSelector,
  driverPropertyIdsForBlockSelector,
  businessObjectFieldSpecIdsSelector,
  configurableObjectTableColumnsSelector,
  (dimensionalPropertyIds, driverPropertyIds, fieldSpecIds, orderedColumns) => {
    return toSortedArray(
      [...dimensionalPropertyIds, ...driverPropertyIds, ...fieldSpecIds],
      orderedColumns.map((c) => c.key),
    );
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const visibleOrderedColumnKeysForObjectTableSelector: ParametricSelector<
  BlockId,
  ObjectTableBlockColumnKey[]
> = createCachedSelector(
  blockCanHidePropertiesSelector,
  orderedColumnKeysForObjectTableSelector,
  blockConfigSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  (canHide, columnKeys, blockConfig, fieldSpecAsTimeSeriesId) => {
    if (!canHide) {
      return columnKeys;
    }
    const columnConfigs = (blockConfig?.columns ?? []).filter(
      (col) => col.key !== NAME_COLUMN_TYPE,
    );
    const isHidden = (columnKey: string) => {
      const config = columnConfigs.find((c) => c.key === columnKey);
      return config?.visible === false;
    };
    return columnKeys.filter((key) => !isHidden(key) && fieldSpecAsTimeSeriesId !== key);
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const orderedColumnKeysForObjectTablePartitionedByPrimaryKeySelector: ParametricSelector<
  BlockId,
  { primaryColumnKeys: ObjectTableBlockColumnKey[]; remainder: ObjectTableBlockColumnKey[] }
> = createCachedSelector(
  orderedColumnKeysForObjectTableSelector,
  businessObjectSpecForBlockSelector,
  (columnKeys, spec) => {
    if (spec == null) {
      return { primaryColumnKeys: [], remainder: [] };
    }
    const primaryDimensionalProperties = (spec.collection?.dimensionalProperties ?? []).filter(
      (property) => property.isDatabaseKey,
    );
    const [primaryColumnKeys, remainder] = partition(
      columnKeys,
      (key) => primaryDimensionalProperties.find((property) => property.id === key) != null,
    );
    return {
      primaryColumnKeys,
      remainder,
    };
  },
)(blockIdSelector);

export const orderedColumnKeysToShowForObjectTableSelector: ParametricSelector<
  BlockId,
  ObjectTableBlockColumnKey[]
> = createCachedSelector(
  orderedDimensionalPropertyColumnsToShowForBlockSelector,
  orderedDriverPropertyColumnsToShowForBlockSelector,
  orderedFieldSpecIdsToShowForObjectTableSelector,
  (dimensionalPropertyIds, driverPropertyIds, fieldSpecIds) => {
    return [...dimensionalPropertyIds, ...driverPropertyIds, ...fieldSpecIds];
  },
)((_state, blockId) => blockId);

export const isNewDimensionalTableSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    blockConfigBusinessObjectSpecIdSelector,
    businessObjectSpecsByIdForLayerSelector,
    (specId, objectSpecsById) => {
      if (specId == null) {
        return false;
      }
      const objectSpec = objectSpecsById[specId];
      return (
        (objectSpec?.collection?.dimensionalProperties.length ?? 0) > 0 &&
        (objectSpec?.collection?.driverProperties.length ?? 0) > 0
      );
    },
  )((_state, businessObjectSpecId) => businessObjectSpecId);

export const lastDimensionalPropertyKeyForTable: ParametricSelector<
  string,
  DimensionalPropertyId | undefined
> = createCachedSelector(
  (state: RootState, blockId: string) => businessObjectSpecForBlockSelector(state, blockId),
  (state: RootState, blockId: string) => orderedColumnKeysForObjectTableSelector(state, blockId),
  (spec, orderedColumnKeys) => {
    const primaryDimensionalProperties = (spec?.collection?.dimensionalProperties ?? []).filter(
      (property) => property.isDatabaseKey,
    );
    const dimPropColumns = orderedColumnKeys.filter((key) =>
      primaryDimensionalProperties.find((dimProp) => dimProp.id === key),
    );
    return dimPropColumns.length > 0 ? dimPropColumns[dimPropColumns.length - 1] : undefined;
  },
)(blockIdSelector);
