import { sample } from 'lodash';

import { COLOR_OPTIONS } from 'config/dimensionColors';
import {
  BusinessObjectSpecUpdateInput,
  DatasetMutationInput,
  DimensionDeleteInput,
  DimensionUpdateInput,
  ValueType,
} from 'generated/graphql';
import { getDimensionObjectFieldDefaultNameRegex } from 'helpers/dimensions';
import { mergeMutations } from 'helpers/mergeMutations';
import { peekMutationStateChange } from 'helpers/sortIndex';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import {
  getMutationThunkAction,
  submitAutoLayerizedMutations,
} from 'reduxStore/actions/submitDatasetMutation';
import { BusinessObjectSpec } from 'reduxStore/models/businessObjectSpecs';
import {
  AttributeId,
  Dimension,
  DimensionId,
  UserAddedDimensionType,
} from 'reduxStore/models/dimensions';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { businessObjectSpecsForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import {
  attributesByIdSelector,
  dimensionSelector,
  dimensionsByIdSelector,
  dimensionsForLayerSelector,
} from 'selectors/dimensionsSelector';

const mutationActions = {
  createAttribute: getMutationThunkAction<{
    dimensionId: DimensionId;
    value: string;
    attributeId?: AttributeId;
    onCreate?: (newAttribute: { value: string; id: AttributeId }) => void;
  }>(({ dimensionId, value, attributeId, onCreate }, getState) => {
    const attributeIdForCreation = attributeId ?? uuidv4();
    const state = getState();
    const dimension = dimensionsByIdSelector(state)[dimensionId];

    return createAttributeMutation({
      dimension,
      attributeValue: value,
      attributeId: attributeIdForCreation,
      onCreate,
    });
  }),
  createAttributes: getMutationThunkAction<{
    dimensionId: DimensionId;
    attributes: Array<{
      value: string;
      attributeId?: AttributeId;
    }>;
    onCreate?: (newAttributes: Array<{ value: string; id: AttributeId }>) => void;
  }>(({ dimensionId, attributes, onCreate }, getState) => {
    const state = getState();
    const dimension = dimensionsByIdSelector(state)[dimensionId];

    return createAttributesMutation({
      dimension,
      attributes: attributes.map((attr) => ({
        attributeValue: attr.value,
        attributeId: attr.attributeId,
      })),
      onCreate,
    });
  }),
  deleteAttribute: getMutationThunkAction<{ dimensionId: DimensionId; attributeId: AttributeId }>(
    ({ dimensionId, attributeId }) => {
      const updateDimension: DimensionUpdateInput = {
        id: dimensionId,
        deleteAttributes: [{ id: attributeId }],
      };
      return {
        updateDimensions: [updateDimension],
      };
    },
  ),
  deleteAttributes: getMutationThunkAction<{
    dimensionId: DimensionId;
    attributeIds: AttributeId[];
  }>(({ dimensionId, attributeIds }) => {
    const updateDimension: DimensionUpdateInput = {
      id: dimensionId,
      deleteAttributes: attributeIds.map((attributeId) => ({ id: attributeId })),
    };
    return {
      updateDimensions: [updateDimension],
    };
  }),
  createDimension: getMutationThunkAction<{
    name: string;
    id?: DimensionId;
  }>((params, getState) => createDimensionMutation(getState(), params)),
  createDimensionWithAttributes: getMutationThunkAction<{
    dimensionName: string;
    dimensionId?: DimensionId;
    attributes?: Array<{ value: string; id?: AttributeId }>;
  }>(({ dimensionName, dimensionId, attributes }, getState) => {
    const state = getState();
    return createDimensionWithAttributesMutation(state, {
      dimensionName,
      dimensionId,
      attributes,
    });
  }),
  deleteDimension: getMutationThunkAction<DimensionId>((id) => {
    return {
      deleteDimensions: [
        {
          id,
        },
      ],
    };
  }),
  updateDimensionColor: getMutationThunkAction<{
    id: DimensionId;
    color: string;
  }>(({ id, color }, getState) => {
    const dimension = dimensionSelector(getState(), id);
    if (dimension == null || dimension.type !== UserAddedDimensionType) {
      return null;
    }
    return {
      updateDimensions: [{ id, color }],
    };
  }),
  renameAttribute: getMutationThunkAction<{
    id: AttributeId;
    value: string;
  }>(({ id, value }, getState) => {
    const state = getState();
    const attrsById = attributesByIdSelector(state);
    const attr = attrsById[id];
    return {
      updateDimensions: [
        {
          id: attr.dimensionId,
          updateAttributes: [
            {
              id: attr.id,
              value,
            },
          ],
        },
      ],
    };
  }),
  deleteDimensions: getMutationThunkAction<DimensionDeleteInput[]>((dimensions) => {
    return {
      deleteDimensions: dimensions,
    };
  }),
};

const createAttributesMutation = ({
  dimension,
  attributes,
  onCreate,
}: {
  dimension?: Dimension;
  attributes: Array<{
    attributeValue: string;
    attributeId?: AttributeId;
  }>;
  onCreate?: (
    newAttributes: Array<{
      value: string;
      id: AttributeId;
    }>,
  ) => void;
}): NonNullable<Pick<DatasetMutationInput, 'updateDimensions'>> | null => {
  if (dimension == null) {
    return null;
  }

  const restoreAttributes = [];
  const newAttributes = [];

  for (let i = 0; i < attributes.length; i++) {
    const maybeNewAttribute = attributes[i];

    const existingAttribute = dimension.attributes.find(
      (a) => a.value === maybeNewAttribute.attributeValue,
    );
    if (existingAttribute != null) {
      if (existingAttribute.deleted) {
        restoreAttributes.push({
          id: existingAttribute.id,
          value: existingAttribute.value as string,
        });
      }
      continue;
    } else {
      newAttributes.push({
        id: maybeNewAttribute.attributeId ?? uuidv4(),
        value: maybeNewAttribute.attributeValue,
        dimensionId: dimension.id,
      });
    }
  }

  onCreate?.([...newAttributes, ...restoreAttributes]);

  return {
    updateDimensions: [
      {
        id: dimension.id,
        newAttributes,
        // The GraphQL API does not accept a value for a restore. Just send the id.
        restoreAttributes: restoreAttributes.map(({ id }) => ({
          id,
        })),
      },
    ],
  };
};

export const createAttributeMutation = ({
  dimension,
  attributeValue,
  attributeId,
  onCreate,
}: {
  attributeValue: string;
  dimension?: Dimension;
  attributeId?: AttributeId;
  // onCreate is given net new and restored attributes. For consistency, they
  // are both considered to be newly created. Though, the underlying mutation
  // shape for the two cases are slightly different.
  onCreate?: (newAttribute: { value: string; id: AttributeId }) => void;
}): NonNullable<Pick<DatasetMutationInput, 'updateDimensions'>> | null => {
  return createAttributesMutation({
    dimension,
    attributes: [{ attributeValue, attributeId }],
    onCreate: onCreate
      ? (newAttributes) => {
          if (newAttributes.length > 0) {
            onCreate(newAttributes[0]);
          }
        }
      : undefined,
  });
};

export const createDimensionMutation = (
  state: RootState,
  { name, id }: { name: string; id?: DimensionId },
): Pick<DatasetMutationInput, 'newDimensions' | 'restoreDimensions'> => {
  const dimensions = dimensionsForLayerSelector(state);
  const toRestore = dimensions.find((dim) => dim.name === name && dim.deleted);
  if (toRestore != null) {
    return {
      restoreDimensions: [{ id: toRestore.id }],
    };
  }

  return {
    newDimensions: [
      {
        id: id ?? uuidv4(),
        name,
        color: sample(COLOR_OPTIONS.map((c) => c.bgColor)),
      },
    ],
  };
};

const createDimensionWithAttributesMutation = (
  state: RootState,
  {
    dimensionName,
    dimensionId,
    attributes,
  }: {
    dimensionName: string;
    dimensionId?: DimensionId;
    attributes?: Array<{ value: string; id?: AttributeId }>;
  },
): Pick<DatasetMutationInput, 'newDimensions' | 'updateDimensions' | 'restoreDimensions'> => {
  const dimensionIdForCreation = dimensionId ?? uuidv4();
  const dimMutation = createDimensionMutation(state, {
    name: dimensionName,
    id: dimensionIdForCreation,
  });
  const peekState = peekMutationStateChange(state, dimMutation);
  const dimension = dimensionsByIdSelector(peekState)[dimensionIdForCreation];
  const attrMutation: DatasetMutationInput | undefined =
    attributes != null
      ? {
          updateDimensions: [
            {
              id: dimension.id,
              newAttributes: attributes.map((attr) => ({
                id: attr.id ?? uuidv4(),
                value: attr.value,
                dimensionId: dimension.id,
              })),
            },
          ],
        }
      : undefined;
  return attrMutation == null ? dimMutation : mergeMutations(dimMutation, attrMutation);
};

export const renameDimension =
  ({ id, name }: { id: DimensionId; name: string }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const currName = dimensionSelector(state, id)?.name;
    // Update field name of any object fields matching this id and use default
    let specUpdates: BusinessObjectSpecUpdateInput[] = [];
    if (currName != null) {
      const defaultNameRegex = getDimensionObjectFieldDefaultNameRegex(currName);
      const objectSpecs: BusinessObjectSpec[] = businessObjectSpecsForLayerSelector(state);
      specUpdates = objectSpecs
        .flatMap((objSpec) => {
          return objSpec.fields.map((fieldSpec) => {
            if (fieldSpec?.type !== ValueType.Attribute || fieldSpec.dimensionId !== id) {
              return null;
            }
            if (!defaultNameRegex.test(fieldSpec.name)) {
              return null;
            }

            const update: BusinessObjectSpecUpdateInput = {
              id: objSpec.id,
              updateFields: [
                {
                  id: fieldSpec.id,
                  name: fieldSpec.name.replace(currName, name),
                },
              ],
            };
            return update;
          });
        })
        .filter(isNotNull);
    }

    const mutation = {
      updateDimensions: [{ id, name }],
      updateBusinessObjectSpecs: specUpdates.length === 0 ? null : specUpdates,
    };

    dispatch(
      submitAutoLayerizedMutations('rename-dimension', [mutation], {
        // This is a "dimension" operation, so we want it to behave like dimensions, i.e. scoped to default layer.
        updateBusinessObjectSpecs: true,
      }),
    );
  };

export const {
  createAttribute,
  createAttributes,
  createDimensionWithAttributes,
  createDimension,
  deleteDimension,
  deleteDimensions,
  updateDimensionColor,
  renameAttribute,
  deleteAttribute,
  deleteAttributes,
} = mutationActions;
