import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'MergeIcon',
  viewBox: '0 0 12 14',
  defaultProps: {
    fill: 'none',
  },
  d: 'M5.99178 0.591846C5.91144 0.593106 5.83243 0.612497 5.76064 0.648572C5.68886 0.684646 5.62614 0.736472 5.57719 0.80018C5.57649 0.801219 5.5758 0.802261 5.57511 0.803305L2.95636 3.42206C2.90517 3.4712 2.86431 3.53006 2.83616 3.5952C2.808 3.66033 2.79313 3.73043 2.79241 3.80139C2.79169 3.87234 2.80513 3.94273 2.83195 4.00842C2.85877 4.07411 2.89843 4.1338 2.94861 4.18397C2.99878 4.23415 3.05847 4.27381 3.12416 4.30063C3.18985 4.32745 3.26024 4.34089 3.3312 4.34017C3.40215 4.33945 3.47225 4.32458 3.53738 4.29642C3.60252 4.26827 3.66138 4.22741 3.71053 4.17622L5.46678 2.41997V8.06581C5.46678 10.4288 3.56313 12.3325 1.20011 12.3325C1.12944 12.3315 1.05927 12.3445 0.993686 12.3709C0.928103 12.3972 0.868412 12.4364 0.818081 12.486C0.767751 12.5356 0.727785 12.5947 0.700506 12.6599C0.673227 12.7252 0.65918 12.7951 0.65918 12.8658C0.65918 12.9365 0.673227 13.0065 0.700506 13.0717C0.727785 13.1369 0.767751 13.196 0.818081 13.2456C0.868412 13.2952 0.928103 13.3344 0.993686 13.3607C1.05927 13.3871 1.12944 13.4001 1.20011 13.3991C4.13949 13.3991 6.53344 11.0052 6.53344 8.06581V2.41997L8.28969 4.17622C8.33884 4.22741 8.3977 4.26827 8.46283 4.29642C8.52797 4.32458 8.59807 4.33945 8.66902 4.34017C8.73998 4.34089 8.81036 4.32745 8.87606 4.30063C8.94175 4.27381 9.00143 4.23415 9.05161 4.18397C9.10178 4.1338 9.14145 4.07412 9.16827 4.00842C9.19509 3.94273 9.20853 3.87234 9.20781 3.80139C9.20709 3.73043 9.19221 3.66033 9.16406 3.5952C9.13591 3.53006 9.09504 3.4712 9.04386 3.42206L6.42198 0.80018C6.37142 0.734383 6.30621 0.681291 6.23152 0.645124C6.15684 0.608958 6.07475 0.590715 5.99178 0.591846ZM7.18344 10.3179C7.03944 10.6998 6.86027 11.0635 6.64907 11.4064C7.62773 12.6203 9.12331 13.3991 10.8001 13.3991C11.0945 13.3991 11.3334 13.1602 11.3334 12.8658C11.3334 12.5714 11.0945 12.3325 10.8001 12.3325C9.27478 12.3325 7.93811 11.5248 7.18344 10.3179Z',
});
