export type ISOTime = string;

// e.g., 2020-01
export type MonthKey = string;

export type MonthKeyOrMonthKeyRange =
  | {
      type: 'month';
      monthKey: MonthKey;
    }
  | {
      type: 'range';
      from: MonthKey;
      to: MonthKey;
    };

export enum TimeUnit {
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year',
}

export enum TimePeriod {
  ThisMonth = 'ThisMonth',
  LastMonth = 'LastMonth',
  OneYearAgo = 'OneYearAgo',
  Custom = 'CustomPeriod',
  Driver = 'Driver',
}

export enum TimeRange {
  QuarterToDate = 'QuarterToDate',
  YearToDate = 'YearToDate',
  LastSixMonths = 'LastSixMonths',
  LastTwelveMonths = 'LastTwelveMonths',
  Custom = 'CustomRange',
}

export type RelativeDateReference =
  | 'actualsStart'
  | 'yearStart'
  | 'today'
  | 'lastActual'
  | 'quarterStart';

export type RelativeDate = {
  unit: TimeUnit;
  val: number;
  reference: RelativeDateReference;

  // Is the time period based on the end of the unit. For instance, the end of
  // a quarter as opposed to the start of one.
  isEnd?: boolean;
};
