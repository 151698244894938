import { createIcon, IconProps } from '@chakra-ui/react';
import React from 'react';

const DragHandle = createIcon({
  displayName: 'DragHandleIcon',
  viewBox: '0 0 8 14',
  path: (
    <>
      <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="1.5" cy="7" r="1.5" fill="currentColor" />
      <circle cx="1.5" cy="12.5" r="1.5" fill="currentColor" />
      <circle cx="6.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="6.5" cy="7" r="1.5" fill="currentColor" />
      <circle cx="6.5" cy="12.5" r="1.5" fill="currentColor" />
    </>
  ),
});

interface Props {
  orientation?: 'horizontal' | 'vertical';
}

const DragHandleIcon: React.FC<Props & IconProps> = ({
  orientation = 'vertical',
  ...iconProps
}) => {
  const transform = orientation === 'horizontal' ? 'rotate(90deg)' : undefined;
  return <DragHandle transform={transform} {...iconProps} />;
};

export default DragHandleIcon;
