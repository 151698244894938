import React from 'react';

import NumericDisplayCellLight from 'components/DisplayCell/NumericDisplayCellLight';
import TimeSeriesCellWrapperLight, {
  TimeSeriesCellLightProps,
} from 'components/TimeSeriesCellWrapper/TimeSeriesCellWrapperLight';
import { DisplayConfiguration, NumberValue } from 'reduxStore/models/value';
import { CalculationError } from 'types/dataset';

const NumericTimeSeriesCellLight: React.FC<
  TimeSeriesCellLightProps<NumberValue> & {
    displayConfiguration: DisplayConfiguration;
    isPlaceholder?: boolean;
    error: CalculationError | undefined;
  }
> = ({ width, displayConfiguration, value, error, isPlaceholder, color }) => {
  return (
    <TimeSeriesCellWrapperLight color={color} width={width}>
      <NumericDisplayCellLight
        value={value}
        error={error}
        displayConfiguration={displayConfiguration}
        isPlaceholder={isPlaceholder}
      />
    </TimeSeriesCellWrapperLight>
  );
};

export default React.memo(NumericTimeSeriesCellLight);
