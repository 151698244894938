import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'GlobeIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
  },
  d: 'M8 1.5C4.41656 1.5 1.5 4.41656 1.5 8C1.5 11.5834 4.41656 14.5 8 14.5C11.5834 14.5 14.5 11.5834 14.5 8C14.5 4.41656 11.5834 1.5 8 1.5ZM8 2.58333C8.21294 2.58333 8.43357 2.67154 8.68555 2.90601C8.93752 3.14047 9.19829 3.51862 9.42188 4.0105C9.588 4.37598 9.72357 4.81879 9.84082 5.29167H6.15918C6.27643 4.81879 6.412 4.37598 6.57812 4.0105C6.80171 3.51862 7.06248 3.14047 7.31445 2.90601C7.56643 2.67154 7.78706 2.58333 8 2.58333ZM5.88094 3.01286C5.779 3.18668 5.68099 3.36747 5.59212 3.56299C5.36052 4.07251 5.17464 4.65686 5.03459 5.29167H3.3112C3.89594 4.27936 4.79518 3.4733 5.88094 3.01286ZM10.1191 3.01286C11.2048 3.4733 12.1041 4.27936 12.6888 5.29167H10.9654C10.8254 4.65686 10.6395 4.07251 10.4079 3.56299C10.319 3.36747 10.221 3.18668 10.1191 3.01286ZM2.83089 6.375H4.8505C4.78683 6.89507 4.75 7.43771 4.75 8C4.75 8.56229 4.78683 9.10493 4.8505 9.625H2.83089C2.67058 9.11228 2.58333 8.5665 2.58333 8C2.58333 7.4335 2.67058 6.88772 2.83089 6.375ZM5.95394 6.375H10.0461C10.1167 6.89179 10.1667 7.42988 10.1667 8C10.1667 8.57012 10.1167 9.10821 10.0461 9.625H5.95394C5.88329 9.10821 5.83333 8.57012 5.83333 8C5.83333 7.42988 5.88329 6.89179 5.95394 6.375ZM11.1495 6.375H13.1691C13.3294 6.88772 13.4167 7.4335 13.4167 8C13.4167 8.5665 13.3294 9.11228 13.1691 9.625H11.1495C11.2132 9.10493 11.25 8.56229 11.25 8C11.25 7.43771 11.2132 6.89507 11.1495 6.375ZM3.3112 10.7083H5.03459C5.17464 11.3431 5.36052 11.9275 5.59212 12.437C5.68099 12.6325 5.779 12.8133 5.88094 12.9871C4.79518 12.5267 3.89594 11.7206 3.3112 10.7083ZM6.15918 10.7083H9.84082C9.72357 11.1812 9.588 11.624 9.42188 11.9895C9.19829 12.4814 8.93752 12.8595 8.68555 13.094C8.43357 13.3285 8.21294 13.4167 8 13.4167C7.78706 13.4167 7.56643 13.3285 7.31445 13.094C7.06248 12.8595 6.80171 12.4814 6.57812 11.9895C6.412 11.624 6.27643 11.1812 6.15918 10.7083ZM10.9654 10.7083H12.6888C12.1041 11.7206 11.2048 12.5267 10.1191 12.9871C10.221 12.8133 10.319 12.6325 10.4079 12.437C10.6395 11.9275 10.8254 11.3431 10.9654 10.7083Z',
});
