import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import DriverLegendRow from 'components/DriverChart/DriverLegendRow';
import ScenarioLegendRow from 'components/DriverChart/ScenarioLegendRow';
import { ChartSize } from 'generated/graphql';
import { LEGEND_SIDEBAR_WIDTH } from 'helpers/chart';
import { getDateTimeFromMonthKey, shortMonthFormat } from 'helpers/dates';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useChartContext from 'hooks/useChartContext';
import {
  chartDisplayMonthKeySelector,
  chartDisplayMonthKeyWithDefaultSelector,
} from 'selectors/driverChartSelectors';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

interface Props {
  type: 'driver' | 'scenarios';
}

const DriverChartLegend: React.FC<Props> = ({ type }) => {
  const { blockId } = useBlockContext();

  const {
    driverIds,
    allDriverIds,
    startDateTime,
    endDateTime,
    height,
    size,
    toggleIsScenariosLegendVisible,
  } = useChartContext();

  const cursorMonthKey = useAppSelector(chartDisplayMonthKeySelector);
  const hasActiveCursor = cursorMonthKey != null;
  const monthKey = useAppSelector(chartDisplayMonthKeyWithDefaultSelector);

  const parsedStartDateTime = getDateTimeFromMonthKey(startDateTime);
  const parsedendDateTime = getDateTimeFromMonthKey(endDateTime);

  const date = !hasActiveCursor
    ? `${shortMonthFormat(parsedStartDateTime)}-${shortMonthFormat(parsedendDateTime)}`
    : `${shortMonthFormat(getDateTimeFromMonthKey(monthKey))}`;

  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  useEffect(() => {
    // resets the legend to a hidden state when all the comparison layers are de-selected
    // preventing the legend from being automatically displayed when comparison layers are selected again
    if (comparisonLayerIds.length === 0 && toggleIsScenariosLegendVisible != null) {
      toggleIsScenariosLegendVisible();
    }
  }, [comparisonLayerIds, toggleIsScenariosLegendVisible]);

  const isScenariosLegend = type === 'scenarios';
  if (isScenariosLegend && comparisonLayerIds.length === 0) {
    return null;
  }

  const isMediumChart = size === ChartSize.Medium;
  const scenariosHeight = isMediumChart ? 115 : 170;
  const scenariosWidth = isMediumChart ? 200 : 250;

  return (
    <Flex
      id="driver-chart-legend"
      direction="column"
      width={toPxString(isScenariosLegend ? scenariosWidth : LEGEND_SIDEBAR_WIDTH)}
      maxHeight={toPxString(isScenariosLegend ? scenariosHeight : height)}
      gap={1}
      ml={isScenariosLegend ? '16px' : 0}
      mx={2}
      py={1}
    >
      {!isMediumChart && (
        <Box px={isScenariosLegend ? '3px' : 1}>
          <Text
            noOfLines={1}
            fontWeight="semibold"
            whiteSpace="nowrap"
            lineHeight="1.4rem"
            mt={1}
            fontSize="xxs"
          >
            {date}
          </Text>
        </Box>
      )}
      <Flex direction="column" gap={1} overflowY="auto" width="full">
        {isScenariosLegend
          ? comparisonLayerIds.map((layerId) => {
              return (
                <ScenarioLegendRow
                  // the scenarios chart (and legend) only displays data for one driver
                  driverId={driverIds[0]}
                  layerId={layerId}
                  monthKey={monthKey}
                  key={layerId}
                  hasActiveCursor={hasActiveCursor}
                />
              );
            })
          : allDriverIds.map((id) => {
              const isHidden = !driverIds.includes(id);
              return (
                <DriverLegendRow
                  driverId={id}
                  monthKey={monthKey}
                  key={id}
                  lastVisibleDriver={driverIds.length === 1}
                  hidden={isHidden}
                  hasActiveCursor={hasActiveCursor}
                />
              );
            })}
      </Flex>
    </Flex>
  );
};

export default React.memo(DriverChartLegend);
