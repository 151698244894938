import { Box, StyleProps } from '@chakra-ui/react';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';

type ThemeColor = 'blue' | 'red';
type Theme = Pick<StyleProps, 'backgroundColor' | 'borderColor' | 'color'>;
const THEME_TO_COLORS: Record<ThemeColor, Theme> = {
  blue: {
    backgroundColor: 'blue.100',
    color: 'blue.500',
  },
  red: {
    backgroundColor: 'red.100',
    color: 'red.500',
  },
} as const;

interface Props {
  fullDisplayString: string;
  abbreviatedString: string;
  themeColor?: ThemeColor;
}

const ImpactBadge: React.FC<Props> = ({
  themeColor = 'blue',
  fullDisplayString,
  abbreviatedString,
}) => {
  const { backgroundColor, color } = THEME_TO_COLORS[themeColor];
  return (
    <Tooltip
      placement="top"
      isDisabled={fullDisplayString === abbreviatedString}
      label={fullDisplayString}
    >
      <Box
        data-sentry-mask
        backgroundColor={backgroundColor}
        borderRadius="xl"
        color={color}
        fontSize="xxs"
        fontWeight="medium"
        px={1}
        py="0.125rem"
        whiteSpace="nowrap"
      >
        {abbreviatedString}
      </Box>
    </Tooltip>
  );
};

export default React.memo(ImpactBadge);
