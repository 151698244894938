import { useCallback, useMemo } from 'react';

import SelectMenu, { SelectItem, SelectMenuProps } from 'components/SelectMenu/SelectMenu';

type SingleSelectMenuProps<T extends SelectItem> = SelectMenuProps<T> & {
  selectedItemId: string | undefined;
  onClose: () => void;
};

/**
 * A select menu that only allows one item to be selected at a time.
 * Ensures consistent UX by ensuring that the selected item is always at the top
 * and that the menu closes after the user selects an item.
 */
const SingleSelectMenu = <T extends SelectItem>({
  items,
  selectedItemId,
  onSelect,
  onClose,
  ...props
}: SingleSelectMenuProps<T>) => {
  // For single select menus, the selected item should always be at the top
  const itemsWithSelectedAtTop = useMemo(() => {
    if (selectedItemId == null) {
      return items;
    }

    let selectedItem: T | null = null;
    const beforeSelectedItem: T[] = [];
    const afterSelectedItem: T[] = [];

    items.forEach((item) => {
      // We're unable to use TS to restrict the items to Omit<T, 'isChecked'>,
      // so we need to do this manually
      if (item.id === selectedItemId) {
        selectedItem = { ...item, isChecked: true };
        return;
      }

      if (selectedItem == null) {
        beforeSelectedItem.push({ ...item, isChecked: false });
        return;
      }

      afterSelectedItem.push({ ...item, isChecked: false });
    });

    if (selectedItem != null) {
      return [selectedItem, ...beforeSelectedItem, ...afterSelectedItem];
    }

    return [...beforeSelectedItem, ...afterSelectedItem];
  }, [items, selectedItemId]);

  // When an item from a single select menu is selected, close the menu
  const onSelectHandler = useCallback(
    (item: T, ev: React.MouseEvent | KeyboardEvent) => {
      onSelect(item, ev);
      onClose();
    },
    [onClose, onSelect],
  );

  return <SelectMenu items={itemsWithSelectedAtTop} onSelect={onSelectHandler} {...props} />;
};

export default SingleSelectMenu;
