import { createSelector, SerializedError } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import { IntegrationStatus, LinkedAccount, SyncStatusValue } from 'generated/graphql';
import { prettyPrintTimestamp } from 'helpers/dates';
import {
  FetchedSyncMap,
  FetchedSyncStatus,
  getIntegrationKey,
} from 'reduxStore/reducers/syncStatusSlice';
import { paramSelector } from 'selectors/constSelectors';
import { ParametricSelector, Selector } from 'types/redux';

const syncStatusSelector: Selector<FetchedSyncMap> = (state) => state.syncStatus;

export const integrationSyncStatusSelector: ParametricSelector<
  LinkedAccount,
  FetchedSyncStatus | null
> = createCachedSelector(
  paramSelector<LinkedAccount>(),
  syncStatusSelector,
  (linkedAccount, statues) => {
    const key = getIntegrationKey(linkedAccount);
    return statues[key] ?? null;
  },
)((_state, linkedAccount) => getIntegrationKey(linkedAccount));

export const syncStatusTextSelector: ParametricSelector<LinkedAccount, string> =
  createCachedSelector(
    paramSelector<LinkedAccount>(),
    syncStatusSelector,
    (linkedAccount, statuses) => {
      if (linkedAccount.status === IntegrationStatus.Unknown) {
        return 'Local data only';
      }

      const status = statuses[getIntegrationKey(linkedAccount)];

      if (status == null) {
        return 'Loading sync status';
      }

      if (status.error != null || status?.status?.__typename === 'SyncStatusError') {
        return 'Error fetching status';
      }

      const syncStatus =
        status?.status?.__typename === 'SyncStatusResult' ? status?.status : undefined;

      switch (syncStatus?.status) {
        case undefined:
          return 'Last Synced Unknown';
        case SyncStatusValue.SyncPending:
        case SyncStatusValue.Syncing:
          return 'Syncing…';
        default: {
          const lastSyncTime = syncStatus?.lastSyncTime;
          if (lastSyncTime != null) {
            return `Last Synced ${prettyPrintTimestamp(lastSyncTime)}`;
          }
        }
      }

      return 'Last Synced Unknown';
    },
  )((_state, linkedAccount) => getIntegrationKey(linkedAccount));

export const resyncButtonTextSelector: ParametricSelector<LinkedAccount, string> =
  createCachedSelector(
    paramSelector<LinkedAccount>(),
    syncStatusSelector,
    (linkedAccount, statuses) => {
      if (linkedAccount?.status === IntegrationStatus.Unknown) {
        return 'Local data only';
      }

      const status = statuses[getIntegrationKey(linkedAccount)];

      const statusVal =
        status?.status?.__typename === 'SyncStatusResult' ? status?.status?.status : undefined;

      switch (statusVal) {
        case undefined:
          return 'Unknown';
        case SyncStatusValue.SyncPending:
        case SyncStatusValue.Syncing:
          return 'Syncing…';
        default: {
          return 'Resync';
        }
      }
    },
  )((_state, linkedAccount) => getIntegrationKey(linkedAccount));

export const syncStatusErrorByIntegrationKeySelector: Selector<Record<string, SerializedError>> =
  createSelector(syncStatusSelector, (syncStatusMap) => {
    const errors: Record<string, SerializedError> = {};
    Object.entries(syncStatusMap).forEach(([key, status]) => {
      if (status?.error != null) {
        errors[key] = status.error;
      }
    });
    return errors;
  });
