import { Box } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';

import DatabaseFieldHeaderCell from 'components/BusinessObjectTable/DatabaseFieldHeaderCell';
import DriverPropertyHeaderCell from 'components/BusinessObjectTable/DriverPropertyHeaderCell';
import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import LayerNameCellContents from 'components/LayerNameCellContents/LayerNameCellContents';
import TableCell from 'components/Table/TableCell';
import { BusinessObjectTimeSeriesCellRef, CellType } from 'config/cells';
import { DatabaseTableContext } from 'config/databaseTableContext';
import { PROPERTY_COLUMN_TYPE } from 'config/modelView';
import { ObjectFieldTimeSeriesRowContext } from 'config/objectFieldTimeSeriesRowContext';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { DEFAULT_LAYER_ID } from 'reduxStore/models/layers';
import { businessObjectSpecIdForObjectSelector } from 'selectors/businessObjectsSelector';
import { driverPropertySelector } from 'selectors/collectionSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

const ObjectPropertyCell: React.FC<{ isLast: boolean }> = ({ isLast }) => {
  const { blockId } = useBlockContext();
  const width = useAppSelector((state) =>
    columnWidthSelector(state, { columnType: PROPERTY_COLUMN_TYPE, blockId }),
  );
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const isComparison = comparisonLayerIds.length > 1;

  const { fieldSpecId, businessObjectId, layerId } = useContext(ObjectFieldTimeSeriesRowContext);
  const cellRef: BusinessObjectTimeSeriesCellRef = useMemo(
    () => ({
      type: CellType.ObjectFieldTimeSeries,
      rowKey: { fieldSpecId, objectId: businessObjectId, layerId },
      columnKey: { columnType: PROPERTY_COLUMN_TYPE, columnLayerId: undefined },
    }),
    [fieldSpecId, businessObjectId, layerId],
  );

  const objectSpecId = useAppSelector(
    (state) => businessObjectSpecIdForObjectSelector(state, businessObjectId) ?? '',
  );
  const ctx = useMemo(() => ({ objectSpecId }), [objectSpecId]);

  const dimDriverIdForProperty = useAppSelector((state) => {
    return driverPropertySelector(state, fieldSpecId)?.driverId;
  });

  return (
    <CellContextProvider cellRef={cellRef}>
      <Box fontSize="xs">
        {isComparison && layerId != null ? (
          <TableCell width={width}>
            <LayerNameCellContents
              layerId={layerId}
              isComparisonLayout
              isBaselineLayer={layerId === DEFAULT_LAYER_ID}
            />
          </TableCell>
        ) : (
          <DatabaseTableContext.Provider value={ctx}>
            {dimDriverIdForProperty != null ? (
              <DriverPropertyHeaderCell
                key={fieldSpecId}
                driverPropertyId={fieldSpecId}
                groupKey="groupKey"
                isLast={isLast}
                fixedWidth={toPxString(width)}
                objectId={businessObjectId}
                orientation="vertical"
              />
            ) : (
              <DatabaseFieldHeaderCell
                orientation="vertical"
                fixedWidth={toPxString(width)}
                fieldSpecId={fieldSpecId}
                groupKey="groupKey"
                isLast={isLast}
                objectId={businessObjectId}
              />
            )}
          </DatabaseTableContext.Provider>
        )}
      </Box>
    </CellContextProvider>
  );
};

export default ObjectPropertyCell;
