import { datadogLogs } from '@datadog/browser-logs';

import { isDevelopment, isUserBrowser } from 'helpers/environment';

export function measure<T extends (...args: unknown[]) => unknown>(
  name: string,
  func: T,
  context: Record<string, any> = {},
): ReturnType<T> {
  const startingAt = performance.now();
  const result = func() as ReturnType<T>;
  logInfo(`[COMPARE SCENARIOS - ${name}]`, {
    durationInMillis: performance.now() - startingAt,
    ...context,
  });
  return result;
}

const logInfo = (name: string, context?: NullableRecord<string, any>) => {
  if (!isUserBrowser()) {
    return;
  }

  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.info(name, context);
  } else {
    datadogLogs.logger.info(name, context);
  }
};
