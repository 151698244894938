import { Button, Flex, Text, usePopoverContext } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';

import { Tooltip } from 'chakra/tooltip';
import LayerPill from 'components/CompareScenariosModalContent/LayerPill';
import CompareToLayerDropdown from 'components/CompareToLayerDropdown/CompareToLayerDropdown';
import { KEYBOARD_SHORTCUTS } from 'config/shortcuts';
import { extractEmoji } from 'helpers/emoji';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { commitLayer } from 'reduxStore/actions/layerMutations';
import { LayerId } from 'reduxStore/models/layers';
import { layerNameByIdSelector } from 'selectors/layerSelector';
import {
  nonBaselineLayerIdsToCompareSelector,
  scenarioComparisonBaselineLayerIdSelector,
} from 'selectors/scenarioComparisonSelector';

const MergeChangesPopoverContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const baselineLayerId = useAppSelector(scenarioComparisonBaselineLayerIdSelector);
  const nonBaselineLayerIds = useAppSelector(nonBaselineLayerIdsToCompareSelector);
  const { onClose } = usePopoverContext();
  const [layerIdToMerge, setLayerIdToMerge] = useState<LayerId | undefined>();

  useEffect(() => {
    const firstComparisonLayerId =
      nonBaselineLayerIds.length > 0 ? nonBaselineLayerIds[0] : undefined;
    setLayerIdToMerge(firstComparisonLayerId);
  }, [nonBaselineLayerIds]);

  const baselineLayerName = useAppSelector(
    (state) => layerNameByIdSelector(state)[baselineLayerId],
  );
  const [_, baselineLayerDisplayName] = extractEmoji(baselineLayerName);
  const onSelectLayer = useCallback((layerId: LayerId) => setLayerIdToMerge(layerId), []);

  const onCommitLayer = useCallback(() => {
    if (layerIdToMerge == null) {
      return;
    }
    dispatch(commitLayer(layerIdToMerge));
  }, [layerIdToMerge, dispatch]);

  if (layerIdToMerge == null) {
    return null;
  }
  return (
    <Flex flexDir="column" rowGap={4} maxW="30rem" p={4} lineHeight="1.125rem" fontSize="xs">
      <Flex columnGap={1} align="center">
        <Flex w="fit-content">
          <CompareToLayerDropdown
            layerIds={nonBaselineLayerIds}
            selectedLayerId={layerIdToMerge}
            onChange={onSelectLayer}
          />
        </Flex>
        {'->'}
        <LayerPill layerId={baselineLayerId} />
      </Flex>
      <Text>
        Changes from <LayerName layerId={layerIdToMerge} /> will be merged into{' '}
        <LayerName layerId={baselineLayerId} />.
      </Text>
      <Text>
        After the merge, <LayerName layerId={layerIdToMerge} /> will be automatically deleted.
      </Text>
      <Flex columnGap={1} align="center" justify="end">
        <Button size="sm" variant="light" py={1} onClick={onClose}>
          Cancel
        </Button>
        <Tooltip
          label={KEYBOARD_SHORTCUTS.merge}
          shouldWrapChildren
          placement="bottom"
          openDelay={500}
          closeOnPointerDown
        >
          <Button
            size="sm"
            variant="accent"
            py={1}
            aria-label={`Merge into ${baselineLayerDisplayName}`}
            onClick={onCommitLayer}
          >
            <Text>
              Merge into <LayerName layerId={baselineLayerId} />
            </Text>
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

const LayerName: React.FC<{ layerId: LayerId }> = ({ layerId }) => {
  const name = useAppSelector((state) => layerNameByIdSelector(state)[layerId]);
  const [_, layerDisplayName] = extractEmoji(name);

  return (
    <Text as="span" fontWeight="semibold">
      {layerDisplayName}
    </Text>
  );
};

export default MergeChangesPopoverContent;
