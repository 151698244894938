import { createIcon, IconProps } from '@chakra-ui/react';
import React from 'react';

const TrendArrow = createIcon({
  displayName: 'TrendArrowIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3.25C4.58579 3.25 4.25 3.58579 4.25 4C4.25 4.41421 4.58579 4.75 5 4.75H10.1893L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L11.25 5.81066V11C11.25 11.4142 11.5858 11.75 12 11.75C12.4142 11.75 12.75 11.4142 12.75 11V4C12.75 3.80806 12.6768 3.61612 12.5303 3.46967C12.4584 3.39776 12.3755 3.34351 12.2871 3.30691C12.1987 3.27024 12.1017 3.25 12 3.25H5Z"
      fill="currentColor"
    />
  ),
});

const DIRECTIONS = {
  up: undefined,
  down: 'rotateZ(90deg)',
  flat: 'rotateZ(45deg)',
};

interface Props extends IconProps {
  direction?: keyof typeof DIRECTIONS;
}

const TrendArrowIcon: React.FC<Props> = ({ direction = 'down', ...props }) => {
  const transform = DIRECTIONS[direction];
  return <TrendArrow {...props} transform={transform} />;
};

export default TrendArrowIcon;
