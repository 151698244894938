import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'BarChartHorizontal',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    boxSize: '14px',
  },
  d: `M13.895 4.42333C13.895 4.71333 13.66 4.94999 13.3684 4.94999H2.31835V1.79166H13.3684C13.66 1.79166 13.895 2.02833 13.895 2.31833V4.42333ZM11.79 13.895C11.79 14.185 11.555 14.4217 11.2634 14.4217H2.31835V11.2633H11.2634C11.555 11.2633 11.79 11.5 11.79 11.79V13.895ZM9.68502 9.15833C9.68502 9.44999 9.45002 9.68499 9.15835 9.68499H2.31835V6.52833L9.15835 6.52833C9.45002 6.52833 9.68502 6.76333 9.68502 7.05499V9.15833Z`,
});
