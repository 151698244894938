import React from 'react';

import SettingsPopoverContentBaseComponent from 'components/BusinessObjectTable/SettingsPopoverContents/SettingsPopoverContentBaseComponent';
import {
  DELETE_SECTION_ID,
  DISPLAY_SECTION_ID,
  EDIT_SECTION_ID,
  FILTER_AND_SORT_SECTION_ID,
  INTEGRATION_SECTION_ID,
  useDatabasePropertyContextMenuItems,
} from 'components/BusinessObjectTable/SettingsPopoverContents/useContextMenuItems';
import { Section } from 'components/SelectMenu/SelectMenu';
import { DatabaseGroupKey } from 'config/businessObjects';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';

const SECTIONS: Section[] = [
  { id: EDIT_SECTION_ID },
  { id: INTEGRATION_SECTION_ID },
  { id: FILTER_AND_SORT_SECTION_ID },
  { id: DISPLAY_SECTION_ID },
  { id: DELETE_SECTION_ID },
];

interface Props {
  isAutoFocused: boolean;
  fieldSpecId: BusinessObjectFieldSpecId;
  groupKey: DatabaseGroupKey;
  objectId: BusinessObjectId | undefined;
  onClose?: () => void;
  isOpen?: boolean;
}

const DatabasePropertySettingsPopoverContent = React.forwardRef<HTMLDivElement, Props>(
  ({ isAutoFocused, fieldSpecId, groupKey, objectId, onClose, isOpen }, ref) => {
    const items = useDatabasePropertyContextMenuItems({ fieldSpecId, groupKey, objectId });

    return (
      <SettingsPopoverContentBaseComponent
        ref={ref}
        isAutoFocused={isAutoFocused}
        isOpen={isOpen}
        items={items}
        onClose={onClose}
        sections={SECTIONS}
        fieldSpecId={fieldSpecId}
      />
    );
  },
);

export default React.memo(DatabasePropertySettingsPopoverContent);
