import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'IntegrationIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <path
      d="M12 9.111c-1.102 0-2 .898-2 2v.222c0 .738-.596 1.334-1.333 1.334a1.332 1.332 0 0 1-1.334-1.334v-.515c1.77-.32 3.111-1.858 3.111-3.707v-2h.223a.671.671 0 0 0 .666-.667.671.671 0 0 0-.666-.666h-2V2.667A.672.672 0 0 0 8 2a.672.672 0 0 0-.667.667v1.11H6v-1.11A.672.672 0 0 0 5.333 2a.672.672 0 0 0-.666.667v1.11h-2A.672.672 0 0 0 2 4.445c0 .365.302.667.667.667h.222v2A3.763 3.763 0 0 0 6 10.818v.515C6 12.8 7.2 14 8.667 14c1.466 0 2.666-1.2 2.666-2.667v-.222c0-.364.303-.667.667-.667.364 0 .667.303.667.667v1.333c0 .365.302.667.666.667a.672.672 0 0 0 .667-.667v-1.333c0-1.102-.898-2-2-2Zm-7.778-2v-2h4.89v2a2.443 2.443 0 0 1-2.445 2.445A2.443 2.443 0 0 1 4.222 7.11Z"
      fill="currentColor"
    />
  ),
});
