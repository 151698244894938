import React from 'react';

import TableCell from 'components/Table/TableCell';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import {
  driverPropertyColumnTableCellOffsetSelector,
  driverPropertyColumnTableCellWidthSelector,
} from 'selectors/tableColumnsSelector';

interface Props {
  children?: React.ReactNode;
  columnType: ModelViewColumnType;
  columnLayerId: ColumnLayerId;
  isSticky?: boolean;
  onContextMenu?: React.MouseEventHandler;
  onMouseDown?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
  onDoubleClick?: React.MouseEventHandler;
  disablePointerEventsOnContents?: boolean;
}

const DriverPropertyTableCell = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      columnType,
      columnLayerId,
      isSticky = false,
      onContextMenu,
      onClick,
      onDoubleClick,
      onMouseDown,
      disablePointerEventsOnContents,
    },
    ref,
  ) => {
    const { blockId, gutterWidthInPx } = useBlockContext();
    const width = useAppSelector((state) =>
      driverPropertyColumnTableCellWidthSelector(state, {
        columnType,
        blockId,
      }),
    );
    const left =
      useAppSelector((state) =>
        driverPropertyColumnTableCellOffsetSelector(state, {
          columnType,
          blockId,
          columnLayerId,
        }),
      ) + gutterWidthInPx;

    return (
      <TableCell
        ref={ref}
        disablePointerEventsOnContents={disablePointerEventsOnContents}
        isSticky={isSticky}
        left={left}
        onClick={onClick}
        onContextMenu={onContextMenu}
        onDoubleClick={onDoubleClick}
        onMouseDown={onMouseDown}
        width={width}
        testId={columnType}
      >
        {children}
      </TableCell>
    );
  },
);

DriverPropertyTableCell.displayName = 'DriverPropertyTableCell';

export default React.memo(DriverPropertyTableCell);
