import { createCachedSelector } from 're-reselect';

import { BlockFilter, BlockFilterType, ValueType } from 'generated/graphql';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { formulaTimeRange } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import FormulaDisplayListener from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { getNumber } from 'helpers/number';
import {
  PLAN_FILTER_LABEL_BY_FILTER_KEY,
  TimelineFilterItem,
  TimelineFilterKey,
  isTimelineFilterKey,
  isValidPlanTimelineFilter,
} from 'helpers/timelineFiltering';
import { BlockId } from 'reduxStore/models/blocks';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blockConfigSelector } from 'selectors/blocksSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { formulaDisplayListenerSelector } from 'selectors/formulaDisplaySelector';
import { EntityIdFilter, EntityIdFilterItem, FilterItem, FilterValueTypes } from 'types/filtering';
import { ParametricSelector } from 'types/redux';

const EMPTY_FILTER_ITEMS: TimelineFilterItem[] = [];

export const filtersForTimelineBlockSelector: ParametricSelector<BlockId, TimelineFilterItem[]> =
  createCachedSelector(
    blockConfigSelector,
    (state: RootState, blockId: BlockId) =>
      formulaDisplayListenerSelector(state, { type: 'block', id: blockId }),
    (blockConfig, displayListener) => {
      const { filterBy } = blockConfig ?? {};
      if (filterBy == null) {
        return EMPTY_FILTER_ITEMS;
      }
      const filters = timelineFiltersFromBlockFilters(displayListener, filterBy);
      return filters.filter(isValidPlanTimelineFilter);
    },
  )({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

const VALUE_TYPE_BY_FILTER_KEY: Record<
  TimelineFilterKey,
  Exclude<FilterItem['valueType'], ValueType.Attribute>
> = {
  planStart: ValueType.Timestamp,
  planEnd: ValueType.Timestamp,
  impactsModelId: FilterValueTypes.ENTITY,
  impactsObjectSpecId: FilterValueTypes.ENTITY,
  eventGroupId: FilterValueTypes.ENTITY,
};

const ENTITY_TYPE_BY_FILTER_KEY: Record<
  TimelineFilterKey,
  EntityIdFilter['entityType'] | undefined
> = {
  impactsModelId: 'model',
  impactsObjectSpecId: 'spec',
  planStart: undefined,
  planEnd: undefined,
  eventGroupId: 'eventGroup',
};

function timelineFiltersFromBlockFilters(
  displayListener: FormulaDisplayListener,
  blockFilters: BlockFilter[],
): FilterItem[] {
  const validFilters: FilterItem[] = [];
  blockFilters.forEach((blockFilter) => {
    if (
      blockFilter.filterType !== BlockFilterType.KeyValue ||
      blockFilter.filterKey == null ||
      !isTimelineFilterKey(blockFilter.filterKey)
    ) {
      return;
    }

    const filterKey = blockFilter.filterKey;
    const valueType = VALUE_TYPE_BY_FILTER_KEY[filterKey];
    const label = PLAN_FILTER_LABEL_BY_FILTER_KEY[filterKey];
    const operator = blockFilter.filterOp ?? undefined;
    const entityType = ENTITY_TYPE_BY_FILTER_KEY[filterKey];

    const itemCommon = {
      filterKey,
      label,
      operator,
    };

    if (valueType === FilterValueTypes.ENTITY && entityType != null) {
      const entityFilterCommon = {
        ...itemCommon,
        valueType,
        expected: blockFilter.filterValues ?? undefined,
      };
      const entityFilterItem: EntityIdFilterItem =
        entityType === 'object'
          ? { ...entityFilterCommon, entityType, specId: blockFilter.businessObjectSpecId ?? '' }
          : {
              ...entityFilterCommon,
              entityType,
            };
      validFilters.push(entityFilterItem);
    } else if (valueType !== FilterValueTypes.ENTITY) {
      const values = blockFilter.filterValues ?? undefined;
      const firstVal = values?.[0];

      // NOTE: We don't handle the ValueType.Attribute case for now since there
      // isn't a case for timeline filtering yet.
      if (valueType === ValueType.Number) {
        const expected = firstVal != null ? getNumber(firstVal) : undefined;
        validFilters.push({ ...itemCommon, expected, valueType });
      } else if (valueType === ValueType.Timestamp) {
        const expected = firstVal != null ? formulaTimeRange(firstVal, displayListener) : undefined;
        validFilters.push({ ...itemCommon, valueType, expected });
      }
    }
  });

  return validFilters;
}
