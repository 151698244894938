import { DateTime } from 'luxon';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { DynamicDate, RollupType } from 'generated/graphql';
import {
  START_OF_MONTH,
  TODAY,
  getMonthKey,
  getMonthKeysForRange,
  getMonthsBetweenDates,
  nextMonthKey,
} from 'helpers/dates';
import { createDateTimeDateRangeSelector } from 'helpers/deepEqualSelector';
import { getComputedEventGroupRangeWithDefault } from 'helpers/eventGroups';
import { exhaustiveGuard } from 'helpers/exhaustiveGuard';
import { getDateRangeStartForComparisonTimePeriodsAndRollupTypes } from 'helpers/timeperiodComparisons';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import { LayerId } from 'reduxStore/models/layers';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  blockConfigDateRangeSelector,
  blockConfigSelector,
  blockConfigViewAtTimeSelector,
  blocksByIdSelector,
  defaultDateRangeForCurrentPageSelector,
  sortedBlockIdsForCurrentPageSelector,
  unlistedDriversPageBlockIdSelector,
} from 'selectors/blocksSelector';
import { isCompareScenariosModalOpenSelector } from 'selectors/compareScenariosModalSelector';
import { comparisonTimePeriodsForBlockSelector } from 'selectors/comparisonTimePeriodsSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import {
  databaseSpecIdSelector,
  isViewingDatabasePageSelector,
} from 'selectors/databasePageSelector';
import {
  businessObjectDetailBlockIdSelector,
  databaseBlockIdSelector,
} from 'selectors/databaseSelector';
import { detailPaneSelector, isDetailPaneOpenSelector } from 'selectors/detailPaneSelectors';
import {
  eventsWithoutLiveEditsByIdForLayerSelector,
  populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
  rootInitiativesWithoutLiveEditsForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import {
  datasetLastActualsDateTimeSelector,
  lastActualsMonthKeyForLayerSelector,
} from 'selectors/lastActualsSelector';
import { milestonesWithoutLiveEditsSelector } from 'selectors/milestonesSelector';
import {
  currentModelPageBlockSelector,
  driverDetailPageBlockSelector,
  planDetailPageBlockSelector,
  plansPageBlockIdSelector,
} from 'selectors/modelViewSelector';
import {
  isViewingBlocksPageSelector,
  isViewingPlansPageSelector,
  isViewingUnlistedDriversPageSelector,
} from 'selectors/pageSelector';
import { detailPanePlanSelector } from 'selectors/planDetailPaneSelector';
import { scenarioComparisonPageBlockSelector } from 'selectors/scenarioComparisonSelector';
import { ParametricSelector, Selector } from 'types/redux';

const FALLBACK_DATE_RANGE = [TODAY, TODAY] as [DateTime, DateTime];

/**
 * NOTE: See https://runway.height.app/T-6752
 * The use of createDateRangeDateTimeSelector with a custom equality check
 * allows us to avoid recomputing downstream selectors if the resulting date range
 * is the same. However, it doesn't address what inputs are causing this selector to have to run again.
 */
export const defaultDateRangeDateTimeSelector: Selector<[DateTime, DateTime]> =
  createDateTimeDateRangeSelector(
    rootInitiativesWithoutLiveEditsForLayerSelector,
    eventsWithoutLiveEditsByIdForLayerSelector,
    populatedEventGroupsWithoutLiveEditsByIdForLayerSelector,
    datasetLastActualsDateTimeSelector,
    milestonesWithoutLiveEditsSelector,
    defaultDateRangeForCurrentPageSelector,
    (
      { rootEventGroups, rootEvents },
      eventsById,
      populatedEventGroupsById,
      lastActualsDateTime,
      milestones,
      currentPageDateRange,
      // eslint-disable-next-line max-params
    ) => {
      // If the user has set a date range on the page, use it as the default range.
      const pageRangeDateTimes: [DateTime, DateTime] | null =
        currentPageDateRange?.start != null && currentPageDateRange?.end != null
          ? [
              DateTime.fromISO(currentPageDateRange.start),
              DateTime.fromISO(currentPageDateRange.end),
            ]
          : null;
      if (pageRangeDateTimes != null) {
        return pageRangeDateTimes;
      }

      const groupRangesById = {};
      const eventGroupEndDateTimes = rootEventGroups
        .map((id) => populatedEventGroupsById[id])
        .filter(isNotNull)
        .map((g) =>
          DateTime.fromISO(getComputedEventGroupRangeWithDefault(g, groupRangesById).end),
        );
      const eventEndDateTimes = rootEvents
        .map((id) => eventsById[id])
        .filter(isNotNull)
        .map((ev) => DateTime.fromISO(ev.end));
      const milestoneDateTimes = milestones
        .map((m) => (m.date != null ? DateTime.fromISO(m.date) : undefined))
        .filter(isNotNull);

      // N.B. if you are increasing this 1y number, we may want to make some
      // optimizations to lazy-load some data that's on-screen for performance.
      const defaultEnd = START_OF_MONTH.plus({ years: 1 });
      const endPadding = { months: 3 };

      const end = DateTime.max(
        ...eventGroupEndDateTimes,
        ...eventEndDateTimes,
        ...milestoneDateTimes,
        defaultEnd.minus(endPadding),
      );

      return [
        lastActualsDateTime.minus({ months: 6 }).startOf('month'),
        end.plus(endPadding).endOf('month'),
      ];
    },
  );

const modelDateRangeSelector = createSelector(
  currentModelPageBlockSelector,
  (state: RootState) => state,
  (block, state) => {
    return block != null
      ? blockDateRangeDateTimeSelector(state, block.id)
      : // N.B. the internal page creation happens on-demand so we need to handle
        // the case that this hasn't been created.
        FALLBACK_DATE_RANGE;
  },
);

const plansPageDateRangeSelector: Selector<[DateTime, DateTime]> =
  function plansPageDateRangeSelector(state: RootState) {
    const blockId = plansPageBlockIdSelector(state);
    return blockId != null
      ? blockDateRangeDateTimeSelector(state, blockId)
      : // N.B. the internal page creation happens on-demand so we need to handle
        // the case that this hasn't been created.
        FALLBACK_DATE_RANGE;
  };

const unlistedDriversPageDateRangeSelector: Selector<[DateTime, DateTime]> =
  function unlistedDriversPageDateRangeSelector(state: RootState) {
    const blockId = unlistedDriversPageBlockIdSelector(state);
    return blockId != null
      ? blockDateRangeDateTimeSelector(state, blockId)
      : // N.B. the internal page creation happens on-demand so we need to handle
        // the case that this hasn't been created.
        FALLBACK_DATE_RANGE;
  };

const databasePageDateRangeSelector = createSelector(
  (state: RootState) => state,
  databaseSpecIdSelector,
  (state, specId) => {
    if (specId == null) {
      return FALLBACK_DATE_RANGE;
    }

    const databaseBlockId = databaseBlockIdSelector(state, specId);
    return databaseBlockId != null
      ? blockDateRangeDateTimeSelector(state, databaseBlockId)
      : // N.B. the internal page creation happens on-demand so we need to handle
        // the case that this hasn't been created.
        FALLBACK_DATE_RANGE;
  },
);

/**
 * The maximum date range extent of all the blocks on the page. The range
 * includes any date range that is set as the page default. This is useful for
 * deriving the date range for which we should perform formula calculations.
 */
const blocksPageDateRangeSelector: Selector<[DateTime, DateTime]> = createDateTimeDateRangeSelector(
  defaultDateRangeDateTimeSelector,
  sortedBlockIdsForCurrentPageSelector,
  blocksByIdSelector,
  (blockDefaultDateRange, blockIds, blocksById) => {
    const dateRanges = [
      blockDefaultDateRange,
      ...blockIds
        .map((blockId) => blocksById[blockId].blockConfig.dateRange)
        .filter(isNotNull)
        .map(({ start, end }) =>
          start != null && end != null
            ? ([DateTime.fromISO(start), DateTime.fromISO(end)] as [DateTime, DateTime])
            : null,
        )
        .filter(isNotNull),
    ];

    const minimum = DateTime.min(...dateRanges.map(([start]) => start));
    const maximum = DateTime.max(...dateRanges.map(([, end]) => end));
    return [minimum, maximum];
  },
);

const detailPaneBlockIdSelector: Selector<string | null> = createSelector(
  detailPaneSelector,
  (state: RootState) => state,
  (detailPane, state) => {
    if (detailPane == null || detailPane.type == null) {
      return null;
    }
    const type = detailPane.type;
    switch (type) {
      case PaneType.Driver:
      case PaneType.DimensionalDriver:
        return driverDetailPageBlockSelector(state)?.id ?? null;
      case PaneType.Plan:
        return planDetailPageBlockSelector(state)?.id ?? null;
      case PaneType.Object:
        try {
          return businessObjectDetailBlockIdSelector(state, detailPane.objectSpecId);
        } catch (e) {
          return null;
        }
      default:
        exhaustiveGuard(type);
        return null;
    }
  },
);

export const detailPaneDateRangeSelector: Selector<[DateTime, DateTime]> = createSelector(
  detailPaneBlockIdSelector,
  (state: RootState) => state,
  function detailPaneDateRangeSelector(blockId, state) {
    if (blockId != null) {
      return blockDateRangeDateTimeSelector(state, blockId);
    }
    // N.B. the internal page creation happens on-demand so we need to handle
    // the case that this hasn't been created.
    return FALLBACK_DATE_RANGE;
  },
);

const scenarioComparisonPageBlockDateRangeSelector: Selector<[DateTime, DateTime]> = createSelector(
  scenarioComparisonPageBlockSelector,
  (state: RootState) => state,
  function scenarioComparisonPageBlockDateRangeSelector(block, state) {
    if (block != null) {
      return blockDateRangeDateTimeSelector(state, block.id);
    }
    // N.B. the internal page creation happens on-demand so we need to handle
    // the case that this hasn't been created.
    return FALLBACK_DATE_RANGE;
  },
);

export const pageDateRangeDateTimeSelector: Selector<[DateTime, DateTime]> =
  createDateTimeDateRangeSelector(
    isDetailPaneOpenSelector,
    isCompareScenariosModalOpenSelector,
    isViewingBlocksPageSelector,
    isViewingDatabasePageSelector,
    isViewingPlansPageSelector,
    isViewingUnlistedDriversPageSelector,
    detailPaneDateRangeSelector,
    scenarioComparisonPageBlockDateRangeSelector,
    blocksPageDateRangeSelector,
    modelDateRangeSelector,
    databasePageDateRangeSelector,
    plansPageDateRangeSelector,
    unlistedDriversPageDateRangeSelector,
    // eslint-disable-next-line max-params
    function pageDateRangeDateTimeSelector(
      isDetailPaneOpen,
      isCompareScenariosModalOpen,
      isViewingBlocksPage,
      isViewingDatabasePage,
      isViewingPlansPage,
      isViewingUnlistedDriversPage,
      detailPaneDateRange,
      scenarioComparisonBlockDateRange,
      blocksPageDateRange,
      modelDateRange,
      databaseDateRange,
      plansPageDateRange,
      unlistedDriversPageDateRange,
    ) {
      // Note: databasePage is a blocksPage
      const basePageDateRange = isViewingDatabasePage
        ? databaseDateRange
        : isViewingBlocksPage
          ? blocksPageDateRange
          : isViewingPlansPage
            ? plansPageDateRange
            : isViewingUnlistedDriversPage
              ? unlistedDriversPageDateRange
              : modelDateRange;

      const [basePageStart, basePageEnd] = basePageDateRange;

      let modalStart = basePageStart;
      let modalEnd = basePageEnd;

      // When a modal is open, you can still see page behind the modal, so we want
      // choose a range encompassing both the modal date range and the base page's range.
      if (isDetailPaneOpen) {
        const [detailStart, detailEnd] = detailPaneDateRange;
        modalStart = detailStart;
        modalEnd = detailEnd;
      }
      if (isCompareScenariosModalOpen) {
        const [scenarioComparisonStart, scenarioComparisonEnd] = scenarioComparisonBlockDateRange;
        modalStart = scenarioComparisonStart;
        modalEnd = scenarioComparisonEnd;
      }

      return [DateTime.min(modalStart, basePageStart), DateTime.max(modalEnd, basePageEnd)];
    },
  );

export const monthKeysForPageDateRangeSelector = createSelector(
  pageDateRangeDateTimeSelector,
  function monthKeysForPageDateRangeSelector([start, end]) {
    return getMonthKeysForRange(start, end);
  },
);

/**
 * This selector returns the date range that should be shown by default for a given block,
 * in the case that an explicit config has not been set.
 *
 * This default is generally set based on the state of the dataset, but may be
 * dynamically adjusted based on the type of block. The page's date range is
 * taken into consideration via the defaultDateRangeDateTimeSelector.
 */
const blockDynamicDefaultDateRangeDateTimeSelector = createCachedSelector(
  defaultDateRangeDateTimeSelector,
  detailPanePlanSelector,
  (blockDefaultDateRange, detailPanePlan): [DateTime, DateTime] => {
    const [defaultStart, defaultEnd] = blockDefaultDateRange;
    if (detailPanePlan != null) {
      const { start, end } = getComputedEventGroupRangeWithDefault(detailPanePlan, {});
      return [
        DateTime.min(defaultStart, DateTime.fromISO(start).startOf('month')),
        DateTime.max(defaultEnd, DateTime.fromISO(end).endOf('month')),
      ];
    }

    return blockDefaultDateRange;
  },
)({ keySelector: blockIdSelector, selectorCreator: createDateTimeDateRangeSelector });

export const blockDateRangeDateTimeSelector = createCachedSelector(
  blockDynamicDefaultDateRangeDateTimeSelector,
  blockConfigDateRangeSelector,
  function blockDateRangeDateTimeSelector(
    blockDefaultDateRange,
    blockConfigDateRange,
  ): [DateTime, DateTime] {
    const start = blockConfigDateRange?.start;
    const end = blockConfigDateRange?.end;

    return [
      start != null ? DateTime.fromISO(start).startOf('month') : blockDefaultDateRange[0],
      end != null ? DateTime.fromISO(end).endOf('month') : blockDefaultDateRange[1],
    ];
  },
)({ keySelector: blockIdSelector, selectorCreator: createDateTimeDateRangeSelector });

/**
 * returns the date ranges used to request calculations
 * shifts start of date range back to earliest date corresponding to the given time period and rollup types
 * i.e. ComparisonTimePeriod.PreviousPeriod with rollupType.Month will shift the start of the date range back to the previous month
 */
export const blockDateRangeSelector = createSelector(
  blockDateRangeDateTimeSelector,
  blockConfigSelector,
  comparisonTimePeriodsForBlockSelector,
  (blockDateRange, blockConfig, comparisonTimePeriods): [DateTime, DateTime] => {
    const rollupTypes = blockConfig?.rollupTypes ?? [blockConfig?.rollupType ?? RollupType.Month];

    let start = blockDateRange[0];
    const end = blockDateRange[1];

    start = getDateRangeStartForComparisonTimePeriodsAndRollupTypes(
      comparisonTimePeriods,
      start,
      rollupTypes,
    );

    return [start, end];
  },
);

export const blockDateRangeTranslatedViewAtTimeSelector: ParametricSelector<
  BlockId,
  DateTime | null
> = createSelector(
  blockConfigViewAtTimeSelector,
  datasetLastActualsDateTimeSelector,
  function blockDateRangeTranslatedViewAtTimeSelector(blockConfigViewAtTime, lastActualsTime) {
    if (!blockConfigViewAtTime) {
      return null;
    }

    switch (blockConfigViewAtTime.dynamicDateType) {
      case null:
        return blockConfigViewAtTime.time != null
          ? DateTime.fromISO(blockConfigViewAtTime.time).startOf('month')
          : null;
      case DynamicDate.LastClose:
        return lastActualsTime;
      case DynamicDate.ThisMonth:
        return START_OF_MONTH;
      default:
        return null;
    }
  },
);

export const blockDateRangeOriginalViewAtTimeSelector: ParametricSelector<
  BlockId,
  DateTime | null
> = createSelector(blockConfigViewAtTimeSelector, (blockConfigViewAtTime) => {
  if (!blockConfigViewAtTime) {
    return null;
  }

  return blockConfigViewAtTime.time == null
    ? null
    : DateTime.fromISO(blockConfigViewAtTime.time).startOf('month');
});

export const blockDateRangeViewAtTimeDynamicDateSelector = createSelector(
  blockConfigViewAtTimeSelector,
  (blockConfigViewAtTime): DynamicDate | null => {
    if (!blockConfigViewAtTime) {
      return null;
    }

    return blockConfigViewAtTime?.dynamicDateType ?? null;
  },
);

export const blockMonthsSelector = createSelector(
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
  ([start, end], startOverride) => getMonthsBetweenDates(startOverride || start, end),
);

export const blockMonthKeysSelector = createSelector(
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
  ([start, end]) => getMonthKeysForRange(start, end),
);

type MonthKeyProps = {
  blockId: string;
  layerId: LayerId;
};

export const monthKeyForActualsOrDateRangeSelector: ParametricSelector<MonthKeyProps, string> =
  createCachedSelector(
    lastActualsMonthKeyForLayerSelector,
    (state: RootState, { blockId }: MonthKeyProps) =>
      blockDateRangeDateTimeSelector(state, blockId),
    (lastCloseMonthKey: string, dateRange: [DateTime, DateTime]) => {
      const afterLastClose = nextMonthKey(lastCloseMonthKey);
      const [start, end] = dateRange;
      const startMonthKey = getMonthKey(start);
      const endMonthKey = getMonthKey(end);
      const monthKey =
        afterLastClose >= startMonthKey && afterLastClose <= endMonthKey
          ? afterLastClose
          : endMonthKey;
      return monthKey;
    },
  )((_state, monthKeyProps) => {
    return `${monthKeyProps.blockId}`;
  });
