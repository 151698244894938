import { Box } from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import { useContext } from 'react';

import { CustomizeDateRangeDatePicker } from 'components/CustomizeDateRangeMenu/CustomizeDateRangeMenu';
import { CustomizeDateRangeMenuContext } from 'components/CustomizeDateRangeMenu/CustomizeDateRangeMenuContext';
import OptionRows, { OptionPopoverRow } from 'components/CustomizeOptionRows/OptionRows';
import Calendar from 'vectors/Calendar';

const DateRangeOptionRow: React.FC<{ bound: 'start' | 'end' }> = ({ bound }) => {
  const { start, end } = useContext(CustomizeDateRangeMenuContext);
  const formatted = (bound === 'start' ? start : end).toFormat('MMM yyyy');

  return (
    <OptionPopoverRow
      currentValue={formatted}
      title={capitalize(bound)}
      leftIcon={<Calendar color="gray.500" />}
      hideChevron
    >
      <Box px={1}>
        <CustomizeDateRangeDatePicker bound={bound} />
      </Box>
    </OptionPopoverRow>
  );
};

const DateRangeOptionRows = () => (
  <OptionRows>
    <DateRangeOptionRow bound="start" />
    <DateRangeOptionRow bound="end" />
  </OptionRows>
);

export default DateRangeOptionRows;
