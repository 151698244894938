import { ArrowDownIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import React from 'react';

import { RelativeDate, TimePeriod, TimeRange, TimeUnit } from 'types/datetime';
import { FormulaTimeRange } from 'types/formula';
import Customize from 'vectors/Customize';
import RoundedArrow from 'vectors/RoundedArrow';
import VariableIcon from 'vectors/Variable';

export const MIN_DATE = DateTime.utc(2010, 1, 1);
export const MAX_DATE = DateTime.utc(2040, 1, 1);
export const LAST_MONTH_TIME_RANGE: FormulaTimeRange = {
  type: 'relative',
  start: -1,
  end: -1,
};

interface TimeRangeItemIconProps {
  color: string;
  icon: React.ReactElement;
}

const TimeRangeItemIcon = ({ color, icon }: TimeRangeItemIconProps) => {
  return (
    <Flex
      as="span"
      width="1rem"
      height="1rem"
      bg={color}
      rounded={4}
      align="center"
      justify="center"
    >
      {icon}
    </Flex>
  );
};

export const TIME_PERIOD_ICON: Record<TimePeriod, JSX.Element> = {
  [TimePeriod.ThisMonth]: (
    <TimeRangeItemIcon
      color="gray.300"
      icon={<ArrowDownIcon direction="down" color="gray.600" />}
    />
  ),
  [TimePeriod.LastMonth]: (
    <TimeRangeItemIcon color="gray.300" icon={<RoundedArrow color="gray.600" />} />
  ),
  [TimePeriod.OneYearAgo]: (
    <TimeRangeItemIcon color="gray.300" icon={<RoundedArrow color="gray.600" />} />
  ),
  [TimePeriod.Custom]: <TimeRangeItemIcon color="gray.300" icon={<Customize color="gray.600" />} />,
  [TimePeriod.Driver]: (
    <TimeRangeItemIcon
      color="gray.300"
      icon={<VariableIcon boxSize="0.625rem" color="gray.600" />}
    />
  ),
};

export const TIME_RANGE_ICON: Record<TimeRange, JSX.Element> = {
  [TimeRange.LastSixMonths]: (
    <TimeRangeItemIcon color="gray.300" icon={<RoundedArrow color="gray.600" />} />
  ),
  [TimeRange.LastTwelveMonths]: (
    <TimeRangeItemIcon color="gray.300" icon={<RoundedArrow color="gray.600" />} />
  ),
  [TimeRange.YearToDate]: (
    <TimeRangeItemIcon color="gray.300" icon={<RoundedArrow color="gray.600" />} />
  ),
  [TimeRange.QuarterToDate]: (
    <TimeRangeItemIcon color="gray.300" icon={<RoundedArrow color="gray.600" />} />
  ),
  [TimeRange.Custom]: <TimeRangeItemIcon color="gray.300" icon={<Customize color="gray.600" />} />,
};

export const TIME_PERIOD_TO_RELATIVE_MONTHS: Partial<Record<TimePeriod, number>> = {
  [TimePeriod.ThisMonth]: 0,
  [TimePeriod.LastMonth]: -1,
  [TimePeriod.OneYearAgo]: -12,
};

export const TIME_PERIOD_DISPLAY_NAME: Record<TimePeriod, string> = {
  [TimePeriod.ThisMonth]: 'This month',
  [TimePeriod.LastMonth]: 'Last month',
  [TimePeriod.OneYearAgo]: 'One year ago',
  [TimePeriod.Driver]: '<Driver value> months ago',
  [TimePeriod.Custom]: 'Custom…',
};

export const TIME_RANGE_DISPLAY_NAME: Record<TimeRange, string> = {
  [TimeRange.QuarterToDate]: 'Quarter-to-date',
  [TimeRange.YearToDate]: 'Year-to-date',
  [TimeRange.LastSixMonths]: 'Last 6 months',
  [TimeRange.LastTwelveMonths]: 'Last 12 months',
  [TimeRange.Custom]: 'Custom range…',
};

export const formatRelativeDate = (relDate: RelativeDate) => {
  const absValue = Math.abs(relDate.val);
  const formattedUnit = (absValue > 1 ? pluralize(relDate.unit) : relDate.unit).toLowerCase();

  let count;
  switch (relDate.val) {
    case 0:
      count = 'This';
      break;
    case -1:
      count = 'Last';
      break;
    case 1:
      count = 'Next';
      break;
    default:
      count = absValue;
  }

  const prefix = relDate.unit !== TimeUnit.Month ? 'Start of ' : '';
  const postfix = relDate.val < -1 ? ' ago' : relDate.val > 1 ? ' from now' : '';

  return `${prefix}${count} ${formattedUnit}${postfix}`;
};
