import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAVIGATION_PANEL_MIN_WIDTH_PX } from 'config/layout/navigationPanel';

type SectionState = {
  isCollapsed: boolean;
};

const DEFAULT_SECTION_STATE: SectionState = { isCollapsed: false };

export type NavigationSidebarState = {
  isCollapsed: boolean;
  width: number;
  sections: {
    models: SectionState;
    databases: SectionState;
    pages: SectionState;
    integrations: SectionState;
    templates: SectionState;
    plans: SectionState;
    unlistedDrivers: SectionState;
  };
};

export type NavigationSidebarSection = keyof NavigationSidebarState['sections'];

export const initialState: NavigationSidebarState = {
  isCollapsed: false,
  width: NAVIGATION_PANEL_MIN_WIDTH_PX,
  sections: {
    models: DEFAULT_SECTION_STATE,
    databases: DEFAULT_SECTION_STATE,
    pages: DEFAULT_SECTION_STATE,
    integrations: DEFAULT_SECTION_STATE,
    templates: DEFAULT_SECTION_STATE,
    plans: DEFAULT_SECTION_STATE,
    unlistedDrivers: DEFAULT_SECTION_STATE,
  },
};

const navigationSidebarSlice = createSlice({
  name: 'navigationSidebar',
  initialState,
  reducers: {
    toggleCollapsed(state) {
      return {
        ...state,
        isCollapsed: !state.isCollapsed,
      };
    },
    expandNavigationSection(state, action: PayloadAction<NavigationSidebarSection>) {
      const section = action.payload;
      state.sections[section].isCollapsed = false;
    },
    toggleNavigationSectionCollapsed(state, action: PayloadAction<NavigationSidebarSection>) {
      const section = action.payload;
      state.sections[section].isCollapsed = !state.sections[section].isCollapsed;
    },
    resizeNavigationSidebar(state, action: PayloadAction<number>) {
      state.width = action.payload;
    },
  },
});

export const {
  toggleCollapsed,
  toggleNavigationSectionCollapsed,
  expandNavigationSection,
  resizeNavigationSidebar,
} = navigationSidebarSlice.actions;

export default navigationSidebarSlice.reducer;
