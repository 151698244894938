import { ADD_COLUMN_TYPE } from 'components/AgGridComponents/helpers/deriveColumnDef';
import { NAME_COLUMN_TYPE, OPTIONS_COLUMN_TYPE } from 'config/modelView';
import useAppSelector from 'hooks/useAppSelector';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { dimensionalPropertySelector, driverPropertySelector } from 'selectors/collectionSelector';

type DatabasePropertyType =
  | 'nameProperty'
  | 'optionsProperty'
  | 'dimensionalProperty'
  | 'driverProperty'
  | 'addNewProperty'
  | 'legacyProperty';

export const useDatabasePropertyType = ({
  fieldSpecId,
}: {
  fieldSpecId: BusinessObjectFieldSpecId;
}): DatabasePropertyType => {
  const dimensionalProperty = useAppSelector((state) =>
    dimensionalPropertySelector(state, fieldSpecId),
  );

  const dimDriverProperty = useAppSelector((state) => driverPropertySelector(state, fieldSpecId));

  if (fieldSpecId === NAME_COLUMN_TYPE) {
    return 'nameProperty';
  }

  if (fieldSpecId === OPTIONS_COLUMN_TYPE) {
    return 'optionsProperty';
  }

  if (fieldSpecId === ADD_COLUMN_TYPE) {
    return 'addNewProperty';
  }

  if (dimensionalProperty != null) {
    return 'dimensionalProperty';
  }

  if (dimDriverProperty != null) {
    return 'driverProperty';
  }

  return 'legacyProperty';
};
