import { isEmpty, keyBy, partition } from 'lodash';
import { DateTime } from 'luxon';
import { createCachedSelector } from 're-reselect';

import { ImpactType, ValueType } from 'generated/graphql';
import { getMonthKeyRange, isValidMonthKey } from 'helpers/dates';
import { getEventImpactOnMonth } from 'helpers/events';
import { FormulaEvaluatorConstructorParams } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import {
  DatabaseFormulaProperty,
  EvaluatorDatabase,
  FormulaEntity,
  FormulaEntityTypedId,
} from 'helpers/formulaEvaluation/ReferenceEvaluator';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { FormulaEntities, mapDriverFormulaEntities } from 'helpers/mapDriverFormulaEntities';
import { getObjectFieldUUID } from 'helpers/object';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import {
  BusinessObjectFieldSpec,
  BusinessObjectSpecId,
  PopulatedBusinessObjectSpec,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectField,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { Event, EventId } from 'reduxStore/models/events';
import { ExtObject, ExtObjectField } from 'reduxStore/models/extObjects';
import { NumberValue, Value } from 'reduxStore/models/value';
import {
  DefaultLinkedActualsFormula,
  integrationDataOverridesForecastFormula,
} from 'reduxStore/reducers/helpers/businessObjectSpecs';
import {
  dimensionalDriversBySubDriverIdSelector,
  driversForLayerSelector,
} from 'selectors/driversSelector';
import { extObjectsSelector } from 'selectors/extObjectsSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { populatedBusinessObjectSpecsForLayerSelector } from 'selectors/populatedBusinessObjectsAndSpecsSelector';
import { isCalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

export function getActualTimeSeriesData(
  entityId: FormulaEntityTypedId['id'],
  monthKey: MonthKey,
  formulaEntitiesById: NullableRecord<string, FormulaEntity | undefined>,
) {
  const formulaEntity = formulaEntitiesById[entityId];
  const actualValue = formulaEntity?.actuals?.timeSeries?.[monthKey];

  if (
    actualValue == null ||
    isCalculationError(actualValue) ||
    // Currently, integrations are sending done "none" values as "". This
    // gets coerced to 0 for numbers and undefined for attribute lookups
    // elsewhere but leaving it as an empty string breaks date parsing. So,
    // just explicitly set to undefined for these cases here.
    actualValue.value === ''
  ) {
    return undefined;
  }

  return actualValue;
}

export function getImpact(
  entityId: FormulaEntityTypedId['id'],
  monthKey: MonthKey,
  formulaEntitiesById: NullableRecord<string, FormulaEntity | undefined>,
  eventsByEntityId: NullableRecord<string, Event[]>,
  ignoreEventIds?: Set<EventId>,
):
  | {
      type: ImpactType.Set | ImpactType.Delta;
      impact: Value;
    }
  | undefined {
  const formulaEntity = formulaEntitiesById[entityId];
  const events = safeObjGet(eventsByEntityId[entityId]);
  if (events == null || formulaEntity == null) {
    return undefined;
  }
  const filteredEventImpacts = events
    .map((ev) => {
      const impact = getEventImpactOnMonth(
        ev,
        monthKey,
        ignoreEventIds,
        formulaEntity.isStartField ?? false,
      );
      return impact == null
        ? null
        : {
            type: ev.impactType,
            impact,
          };
    })
    .filter(isNotNull);

  // Delta impacts cannot be applied to null values
  const [deltaImpacts, setImpacts] = partition(
    filteredEventImpacts,
    (i) => i.type === ImpactType.Delta,
  );
  let deltaImpact:
    | {
        type: ImpactType.Delta;
        impact: NumberValue;
      }
    | undefined;
  let setImpact:
    | {
        type: ImpactType.Set;
        impact: Value;
      }
    | undefined;
  deltaImpacts.forEach((i) => {
    if (i.impact.type !== ValueType.Number) {
      return;
    }
    if (deltaImpact == null) {
      deltaImpact = {
        type: ImpactType.Delta,
        impact: { value: 0, type: ValueType.Number },
      };
    }
    deltaImpact.impact.value = deltaImpact.impact.value + (i.impact.value ?? 0);
  });

  setImpacts.forEach((i) => {
    setImpact = { type: ImpactType.Set, impact: i.impact };
  });

  // We don't support mixed impact types, it's either all ImpactType.Delta or all ImpactType.Set
  return deltaImpact ?? setImpact;
}

export function shouldUseActual(lastActualsMonthKey: MonthKey, monthKey: MonthKey) {
  return monthKey <= lastActualsMonthKey;
}

const driverFormulaEntitiesByIdForLayerSelector: SelectorWithLayerParam<FormulaEntities> =
  createCachedSelector(
    addLayerParams(driversForLayerSelector),
    addLayerParams(dimensionalDriversBySubDriverIdSelector),
    function driverFormulaEntitiesByIdForLayerSelector(drivers, dimensionalDriversBySubDriverId) {
      return mapDriverFormulaEntities(drivers, dimensionalDriversBySubDriverId);
    },
  )(getCacheKeyForLayerSelector);

export const getExtObjectFieldKey = (extObjectKey: string, extFieldSpecKey: string) => {
  return `${extObjectKey}:${extFieldSpecKey}`;
};

type ExtObjectIndices = {
  extObjectFieldsByKey: NullableRecord<string, ExtObjectField>;
  extObjectsByExtKey: NullableRecord<string, ExtObject>;
};

// Exported for testing
export function getFormulaEvaluatorExtObjectIndices(extObjects: ExtObject[]): ExtObjectIndices {
  const extObjectFieldsByKey: NullableRecord<string, ExtObjectField> = {};
  const extObjectsByExtKey: NullableRecord<string, ExtObject> = {};

  extObjects.forEach((extObject) => {
    extObjectsByExtKey[extObject.extKey] = extObject;

    extObject.fields.forEach((field) => {
      const extKey = getExtObjectFieldKey(extObject.extKey, field.extFieldSpecKey);
      extObjectFieldsByKey[extKey] = field;
    });
  });

  return {
    extObjectFieldsByKey,
    extObjectsByExtKey,
  };
}

export const formulaEvaluatorExtObjectIndicesSelector: SelectorWithLayerParam<ExtObjectIndices> =
  createCachedSelector(
    addLayerParams(extObjectsSelector),
    function formulaEvaluatorExtObjectIndicesSelector(extObjects) {
      return getFormulaEvaluatorExtObjectIndices(extObjects);
    },
  )(getCacheKeyForLayerSelector);

const objectFormulaEntitiesByIdForLayerSelector: SelectorWithLayerParam<
  NullableRecord<string, FormulaEntity>
> = createCachedSelector(
  addLayerParams(populatedBusinessObjectSpecsForLayerSelector),
  addLayerParams(formulaEvaluatorExtObjectIndicesSelector),
  addLayerParams(lastActualsMonthKeyForLayerSelector),
  function objectFormulaEntitiesByIdForLayerSelector(
    objectSpecs,
    { extObjectsByExtKey },
    lastActualsMonthKey,
  ) {
    return mapObjectFormulaEntities(objectSpecs, extObjectsByExtKey, lastActualsMonthKey);
  },
)(getCacheKeyForLayerSelector);

export const formulaEntitiesByIdForLayerSelector: SelectorWithLayerParam<
  NullableRecord<string, FormulaEntity>
> = createCachedSelector(
  addLayerParams(driverFormulaEntitiesByIdForLayerSelector),
  addLayerParams(objectFormulaEntitiesByIdForLayerSelector),
  function formulaEntitiesByIdForLayerSelector(driverFormulaEntities, objectFormulaEntities) {
    return {
      ...driverFormulaEntities,
      ...objectFormulaEntities,
    };
  },
)(getCacheKeyForLayerSelector);

// Exported for tests
export function mapObjectFormulaEntities(
  objectSpecs: PopulatedBusinessObjectSpec[],
  extObjectsByExtKey: NullableRecord<string, ExtObject>,
  lastActualsMonthKey: MonthKey,
): FormulaEntities {
  const formulaEntities: FormulaEntities = {};

  objectSpecs.forEach((objectSpec) => {
    objectSpec.objects.forEach((object) => {
      const linkedExtObject = object.extKey != null ? extObjectsByExtKey[object.extKey] : null;

      const objectFieldsByFieldSpecId = keyBy(object.fields, (f) => f.fieldSpecId);

      const linkedExtedObjectFieldsByFieldSpecId = keyBy(
        linkedExtObject?.fields ?? [],
        (f) => f.extFieldSpecKey,
      );

      objectSpec.fields.forEach((objectFieldSpec) => {
        const field = safeObjGet(objectFieldsByFieldSpecId[objectFieldSpec.id]);
        if (field == null) {
          // Create virtual field with deterministic ID for fields that have
          // yet to be created.
          const id = getObjectFieldUUID(object.id, objectFieldSpec.id);
          formulaEntities[id] = createFormulaEntityForMissingField(id, objectFieldSpec);
          return;
        }
        const linkedExtObjectField =
          objectFieldSpec.extFieldSpecKey != null
            ? safeObjGet(linkedExtedObjectFieldsByFieldSpecId[objectFieldSpec.extFieldSpecKey])
            : undefined;
        const mappedFormulaEntity = mapBusinessObjectField(
          object,
          field,
          objectFieldSpec,
          objectSpec,
          linkedExtObjectField,
          lastActualsMonthKey,
        );
        if (mappedFormulaEntity != null) {
          formulaEntities[field.id] = mappedFormulaEntity;
        }
      });
    });
  });

  return formulaEntities;
}

function getEvaluatorDatabase(
  objectSpec: PopulatedBusinessObjectSpec,
  driversById: NullableRecord<DriverId, Driver>,
): EvaluatorDatabase {
  const formulaFields: DatabaseFormulaProperty[] = [];
  objectSpec.fields.forEach((fieldSpec) => {
    formulaFields.push({
      id: fieldSpec.id,
      name: fieldSpec.name,
      type: 'fieldSpec',
      fieldSpec,
    });
  });
  if (objectSpec.collection != null) {
    objectSpec.collection.dimensionalProperties.forEach((dimProp) => {
      formulaFields.push({
        id: dimProp.id,
        name: dimProp.name,
        type: 'dimensionalProperty',
        dimensionalProperty: dimProp,
      });
    });
    objectSpec.collection.driverProperties.forEach((driverProp) => {
      const driver = driversById[driverProp.driverId];
      if (driver != null) {
        formulaFields.push({
          id: driverProp.id,
          name: driver.name,
          type: 'driverProperty',
          driverProperty: driverProp,
        });
      }
    });
  }
  return {
    formulaProperties: formulaFields,
    ...objectSpec,
  };
}

export function getFormulaEvaluatorObjectIndices(
  objectSpecs: PopulatedBusinessObjectSpec[],
  driversById: NullableRecord<DriverId, Driver>,
): Pick<FormulaEvaluatorConstructorParams, 'objectIdsByFieldId' | 'objectsById' | 'databasesById'> {
  const objectsById: Record<BusinessObjectId, BusinessObject> = {};
  const objectIdsByFieldId: Record<BusinessObjectFieldId, BusinessObjectId> = {};
  const databasesById: Record<BusinessObjectSpecId, EvaluatorDatabase> = {};

  objectSpecs.forEach((objectSpec) => {
    databasesById[objectSpec.id] = getEvaluatorDatabase(objectSpec, driversById);

    objectSpec.objects.forEach((object) => {
      objectsById[object.id] = object;

      const fieldsByFieldSpecId = keyBy(object.fields, 'fieldSpecId');

      objectSpec.fields.forEach((fieldSpec) => {
        const field = fieldsByFieldSpecId[fieldSpec.id];
        const fieldId = field != null ? field.id : getObjectFieldUUID(object.id, fieldSpec.id);
        objectIdsByFieldId[fieldId] = object.id;
      });
    });
  });

  return { objectsById, objectIdsByFieldId, databasesById };
}

function createFormulaEntityForMissingField(
  id: string,
  fieldSpec: BusinessObjectFieldSpec,
): FormulaEntity {
  return {
    id: { type: 'objectField', id },
    valueType: fieldSpec.type,
    actuals: {},
    forecast: {
      formula: mapObjectFieldSpecFormula(fieldSpec.defaultForecast.formula, fieldSpec.type),
    },
  };
}

// TODO: This is a temporary solution to allow for the accommodation of
// formulas that are not wrapped in single quotes. This should be removed,
// as we're not even sure there are formulas of this type in the wild.
function mapObjectFieldSpecFormula(formula: string, type: ValueType) {
  if (formula === '') {
    return undefined;
  }

  if (type === ValueType.Number) {
    return formula;
  }
  const lowerFormula = formula.trim().toLowerCase();

  if (lowerFormula.startsWith("'") && lowerFormula.endsWith("'")) {
    return formula;
  }
  if (type === ValueType.Timestamp) {
    const dateTime = DateTime.fromISO(formula);
    const isValidDate = dateTime.isValid || isValidMonthKey(formula);
    return isValidDate ? `'${formula}'` : formula;
  }
  // formula engine expects strings to be wrapped in single quotes, but old logic wrote actuals formulas without surrounding quotes
  // Add quotes if formula was hardcoded value like a date string or UUID
  const formulaWithQuotes =
    lowerFormula.includes('object') || lowerFormula.includes('extobject')
      ? formula
      : `'${formula}'`;
  return formulaWithQuotes;
}

// eslint-disable-next-line max-params
function mapBusinessObjectField(
  object: BusinessObject,
  field: BusinessObjectField,
  fieldSpec: BusinessObjectFieldSpec,
  objectSpec: PopulatedBusinessObjectSpec,
  linkedExtObjectField: ExtObjectField | undefined,
  lastActualsMonthKey: MonthKey,
): FormulaEntity | null {
  if (field.value == null) {
    return null;
  }
  let initialValue: FormulaEntity['initialValue'];
  const initialValueStr = field.value.initialValue;
  if (initialValueStr != null) {
    const startField = object.fields.find((f) => f.fieldSpecId === objectSpec.startFieldId);
    initialValue = {
      value: initialValueStr,
      startFieldId: startField != null ? startField.id : undefined,
    };
  }

  let actualsFormula = field.value?.actuals.formula;
  const actualsTimeSeries = { ...(field.value?.actuals?.timeSeries ?? {}) };
  let forecastFormula = mapObjectFieldSpecFormula(
    fieldSpec.defaultForecast.formula,
    fieldSpec.type,
  );

  if (linkedExtObjectField != null) {
    if (actualsFormula == null) {
      actualsFormula = DefaultLinkedActualsFormula;

      if (
        fieldSpec.propagateIntegrationData ||
        objectSpec.extObjectSpecs.some((s) => s?.propagateDataForward)
      ) {
        // to propagate integration data, we fill in the field time series here rather than lean on the formula
        // evaluation engine. this is a lot more performant because it avoids any ANTLR overhead, and avoids
        // bloating the dataset with a lot more integration data than is necessary. it's still a bit of a hack,
        // though, and it might make sense to revisit this implementation when we move evaluation to the backend
        if (fieldSpec.propagateIntegrationData && !isEmpty(linkedExtObjectField.timeSeries)) {
          const linkedTimeSeries = linkedExtObjectField.timeSeries;
          const integrationMonthKeys = Object.keys(linkedTimeSeries).sort();
          const lastIntegrationMonthKey = integrationMonthKeys[integrationMonthKeys.length - 1];
          const end =
            lastActualsMonthKey >= lastIntegrationMonthKey
              ? lastActualsMonthKey
              : lastIntegrationMonthKey;
          const allMonthKeys = getMonthKeyRange(integrationMonthKeys[0], end);
          let integrationValue = linkedTimeSeries[integrationMonthKeys[0]];
          allMonthKeys.forEach((mk) => {
            // don't overwrite the field time series if it already has a value
            if (actualsTimeSeries[mk] == null) {
              if (linkedTimeSeries[mk] != null) {
                integrationValue = linkedTimeSeries[mk];
              }

              actualsTimeSeries[mk] = integrationValue;
            }
          });
        }
      }
    }

    if (
      fieldSpec.integrationDataOverridesForecast ||
      objectSpec.extObjectSpecs.some((s) => s?.overrideForecastData)
    ) {
      forecastFormula = integrationDataOverridesForecastFormula(forecastFormula);
    }
  }

  const isStartField = objectSpec.startFieldId === fieldSpec.id;
  return {
    id: { type: 'objectField', id: field.id },
    valueType: field.value.type,
    actuals: {
      formulaForCalculations:
        actualsFormula != null
          ? mapObjectFieldSpecFormula(actualsFormula, fieldSpec.type)
          : undefined,
      timeSeries: actualsTimeSeries,
    },
    initialValue,
    forecast: {
      formula: forecastFormula,
    },
    isStartField,
  };
}
