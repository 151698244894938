import { useCallback, useMemo } from 'react';

import { RequestValueProps } from 'components/WebWorkerDataProvider/Context';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { useBatchCalculationProcessor } from 'hooks/useBatchCalculationProcessor';
import { resolveSubDriverForObject } from 'hooks/useRequestObjectTableDataRequester';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import {
  dimensionalPropertyEvaluatorSelector,
  driverPropertiesByIdSelector,
} from 'selectors/collectionSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { currentLayerIdSelector, layersSelector } from 'selectors/layerSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';

interface RequestProps {
  objectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  layerId?: string;
}
interface RequestObjectFieldSpecDataRequester {
  requestDataForFieldSpec: (propsArr: RequestProps[]) => void;
}

export const useRequestObjectFieldSpecDataRequester = (
  blockId: BlockId,
): RequestObjectFieldSpecDataRequester => {
  const layersById = useAppSelector(layersSelector);
  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const driversById = useAppSelector(driversByIdForLayerSelector);
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const driverPropertiesById = useAppSelector(driverPropertiesByIdSelector);
  const batchCalculationProcessor = useBatchCalculationProcessor();
  const dimensionalPropertyEvaluator = useAppSelector(dimensionalPropertyEvaluatorSelector);

  const requestDataForFieldSpec = useCallback(
    (propsArr: RequestProps[]) => {
      const requests: RequestValueProps[] = [];
      propsArr.forEach(({ objectId, fieldSpecId, layerId }) => {
        const layerIdForRequest = layerId ?? currentLayerId;
        const objectsById = layersById[layerIdForRequest]?.businessObjects.byId ?? {};
        if (fieldSpecId in driverPropertiesById) {
          let subDriverId: string | null = null;
          const dimensionalDriverId = safeObjGet(driverPropertiesById[fieldSpecId])?.driverId;
          const driver =
            dimensionalDriverId == null ? undefined : safeObjGet(driversById[dimensionalDriverId]);
          if (driver != null) {
            subDriverId = resolveSubDriverForObject(objectId, dimensionalPropertyEvaluator, driver);
          }
          if (subDriverId != null) {
            requests.push({
              type: 'driver',
              id: subDriverId,
              dateRange,
              layerId: layerIdForRequest,
            });
          }
        } else {
          const object = safeObjGet(objectsById[objectId]);
          if (object != null) {
            const field = object.fields.find((f) => f.fieldSpecId === fieldSpecId);
            if (field != null) {
              requests.push({
                type: 'objectField',
                id: field.id,
                dateRange,
                layerId: layerIdForRequest,
              });
            }
          }
        }
      });
      if (requests.length > 0 && batchCalculationProcessor != null) {
        batchCalculationProcessor?.requestBatchCalculations(requests);
      }
    },
    [
      batchCalculationProcessor,
      currentLayerId,
      dateRange,
      dimensionalPropertyEvaluator,
      driverPropertiesById,
      driversById,
      layersById,
    ],
  );

  return useMemo(() => ({ requestDataForFieldSpec }), [requestDataForFieldSpec]);
};
