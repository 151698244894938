import { Box } from '@chakra-ui/react';
import React from 'react';

import { stopEventPropagation } from 'helpers/browserEvent';
import useBlockContext from 'hooks/useBlockContext';

const RESIZER_WIDTH = 4;

interface Props {
  isResizing: boolean;
  isLastSticky?: boolean;
  startDrag: (event: React.MouseEvent) => void;
}

const ColumnResizerHandle = ({ isResizing, isLastSticky = false, startDrag }: Props) => {
  const { disableBlockConfigChanges } = useBlockContext();

  if (disableBlockConfigChanges) {
    return null;
  }

  return (
    <Box
      data-testid="column-resizer"
      position="absolute"
      top={0}
      right={isLastSticky ? '-1px' : 0}
      w={`${RESIZER_WIDTH}px`}
      h="full"
      _hover={{ bg: 'selection.500' }}
      cursor="ew-resize"
      onMouseDown={startDrag}
      onClick={stopEventPropagation}
      bg={isResizing ? 'blue.500' : 'transparent'}
      zIndex="sticky"
    />
  );
};

export default React.memo(ColumnResizerHandle);
