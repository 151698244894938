import { createCachedSelector } from 're-reselect';

import { getCacheKeyForLayerSelector } from 'helpers/layerSelectorFactory';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DriverProperty } from 'reduxStore/models/collections';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blockConfigBusinessObjectSpecIdSelector } from 'selectors/blocksSelector';
import { businessObjectSpecForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { ParametricSelector } from 'types/redux';

const driverPropertiesForBusinessObjectSpecForLayerSelector: ParametricSelector<
  {
    specId: BusinessObjectSpecId;
    layerId?: string;
  },
  DriverProperty[]
> = createCachedSelector(businessObjectSpecForLayerSelector, (spec) => {
  if (spec == null) {
    return [];
  }
  return spec.collection?.driverProperties ?? [];
})((_state, { specId }) => specId);

const driverPropertiesByDimDriverIdForObjectSpecSelector: ParametricSelector<
  {
    specId: BusinessObjectSpecId;
    layerId?: string;
  },
  NullableRecord<DriverId, DriverProperty>
> = createCachedSelector(
  driverPropertiesForBusinessObjectSpecForLayerSelector,
  (driverProperties) => {
    const byId: NullableRecord<DriverId, DriverProperty> = {};
    for (const property of driverProperties) {
      byId[property.driverId] = property;
    }
    return byId;
  },
)(getCacheKeyForLayerSelector);

export const driverPropertiesByDimDriverIdForBlockSelector: ParametricSelector<
  BlockId,
  NullableRecord<DriverId, DriverProperty>
> = createCachedSelector(
  (state: RootState) => state,
  blockConfigBusinessObjectSpecIdSelector,
  currentLayerIdSelector,
  (state, specId, layerId) => {
    if (specId == null) {
      return {};
    }
    return driverPropertiesByDimDriverIdForObjectSpecSelector(state, { specId, layerId });
  },
)(
  // Cache the block per layer
  (state, block) => `${block}.${currentLayerIdSelector(state)}`,
);

const driverPropertiesBySubDriverIdForObjectSpecSelector: ParametricSelector<
  {
    specId: BusinessObjectSpecId;
    layerId?: string;
  },
  NullableRecord<DriverId, DriverProperty>
> = createCachedSelector(
  driverPropertiesForBusinessObjectSpecForLayerSelector,
  driversByIdForLayerSelector,
  (driverProperties, driversById) => {
    const byId: NullableRecord<DriverId, DriverProperty> = {};
    for (const property of driverProperties) {
      const dimDriver = driversById[property.driverId];
      if (dimDriver == null || dimDriver.type === DriverType.Basic) {
        continue;
      }
      for (const subdriver of dimDriver.subdrivers) {
        byId[subdriver.driverId] = property;
      }
    }
    return byId;
  },
)(getCacheKeyForLayerSelector);

export const driverPropertiesBySubDriverIdForBlockSelector: ParametricSelector<
  BlockId,
  NullableRecord<DriverId, DriverProperty>
> = createCachedSelector(
  (state: RootState) => state,
  blockConfigBusinessObjectSpecIdSelector,
  currentLayerIdSelector,
  (state, specId, layerId) => {
    if (specId == null) {
      return {};
    }
    return driverPropertiesBySubDriverIdForObjectSpecSelector(state, { specId, layerId });
  },
)(
  // Cache the block per layer
  (state, block) => `${block}.${currentLayerIdSelector(state)}`,
);
