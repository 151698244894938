import { DateTime } from 'luxon';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import {
  DatasetMutationInput,
  LayerCreateInput,
  LayerDeleteInput,
  LayerUpdateInput,
} from 'generated/graphql';
import { shortMonthFormat } from 'helpers/dates';
import { loadCompareBlocksDataAsync } from 'reduxStore/actions/namedVersions';
import {
  submitAutoLayerizedMutations,
  submitMutation,
} from 'reduxStore/actions/submitDatasetMutation';
import {
  trackCreateScenarioEvent,
  trackCreateSnapshotEvent,
  trackDeleteScenarioEvent,
} from 'reduxStore/actions/trackEvent';
import { DEFAULT_LAYER_ID, Layer, LayerId } from 'reduxStore/models/layers';
import { MutationBatch } from 'reduxStore/models/mutations';
import { closeModal } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import { lastMutationIdSelector } from 'selectors/datasetSelector';
import { datasetLastActualsTimeSelector } from 'selectors/lastActualsSelector';
import {
  currentLayerIdSelector,
  currentLayerSelector,
  layerSelector,
} from 'selectors/layerSelector';
import { authenticatedUserIdSelector } from 'selectors/loginSelector';

const mutationActions = {
  // Layer creation mutations must be on the main layer.
  createLayer: (newLayer: LayerCreateInput): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const userId = authenticatedUserIdSelector(state);
      dispatch(trackCreateScenarioEvent({ id: newLayer.id, name: newLayer.name }));
      dispatch(
        submitAutoLayerizedMutations('create-layer', [{ newLayers: [{ ...newLayer, userId }] }]),
      );
    };
  },
  createDraftLayerAndApplyMutation: (
    layer: Layer,
    newLayerId: LayerId,
    mutation: DatasetMutationInput,
  ): AppThunk<Promise<boolean>> => {
    return (dispatch, getState) => {
      const state = getState();
      const userId = authenticatedUserIdSelector(state);
      const newLayer: LayerCreateInput = {
        name: `${layer.name} (Draft)`,
        id: newLayerId,
        parentLayerId: layer.id === DEFAULT_LAYER_ID ? undefined : layer.id,
        userId,
        isDraft: true,
      };

      return dispatch(
        submitMutation({ newLayers: [newLayer], ...mutation }, { forceLayerId: newLayerId }),
      );
    };
  },
  deleteLayer: (deleteLayer: LayerDeleteInput): AppThunk => {
    return (dispatch) => {
      dispatch(trackDeleteScenarioEvent({ id: deleteLayer.layerId }));
      // do the actual deletion first so we bookkeep draft layer state correctly
      // before we navigate away, which may redirect and relies on that being correct
      dispatch(submitMutation({ deleteLayers: [deleteLayer] }, { forceLayerId: DEFAULT_LAYER_ID }));
    };
  },
  commitLayer: (layerId: LayerId): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const layer = layerSelector(state, layerId);

      const parentLayerId = layer.parentLayerId ?? DEFAULT_LAYER_ID;
      dispatch(
        submitMutation(
          { commitLayerId: layerId },
          {
            forceLayerId: parentLayerId,
          },
        ),
      );
      dispatch(closeModal());
      DataArbiter.get().cloneLayerCache(layerId, parentLayerId);
    };
  },
  updateLayer: (updateLayer: LayerUpdateInput): AppThunk => {
    return (dispatch) => {
      dispatch(submitAutoLayerizedMutations('update-layer', [{ updateLayers: [updateLayer] }]));
    };
  },

  updateCurrentLayerName: (newName: string): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const currentLayer = currentLayerSelector(state);
      const currentLayerId = currentLayerIdSelector(state);
      if (newName !== currentLayer.name && newName.trim() !== '') {
        // updating a draft layer's name is the same as 'save as' to a feature layer
        dispatch(
          mutationActions.updateLayer({ layerId: currentLayerId, name: newName, isDraft: false }),
        );
      }
    };
  },

  updateCurrentLayerDescription: (newDescription: string): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const currentLayer = currentLayerSelector(state);
      const currentLayerId = currentLayerIdSelector(state);

      if (newDescription !== currentLayer.description) {
        dispatch(
          mutationActions.updateLayer({ layerId: currentLayerId, description: newDescription }),
        );
      }
    };
  },

  createNamedVersionFromCurrent: (name: string): AppThunk => {
    return (dispatch, getState) => {
      const state = getState();
      const lastMutationId = lastMutationIdSelector(state);
      if (lastMutationId == null) {
        return;
      }
      dispatch(
        submitMutation(
          { createNamedDatasetVersions: [{ mutationId: lastMutationId, name }] },
          { forceLayerId: DEFAULT_LAYER_ID },
        ),
      );
    };
  },

  createNamedVersionFromMutationBatch: (
    mutationBatch: MutationBatch,
    reverseMutationIndex: number,
  ): AppThunk<Promise<void>> => {
    return async (dispatch, getState) => {
      const state = getState();
      const lastCloseDate = datasetLastActualsTimeSelector(state);
      await dispatch(
        submitMutation(
          {
            createNamedDatasetVersions: [
              {
                mutationId: mutationBatch.id,
                name: `${
                  mutationBatch.createdAt != null
                    ? `${shortMonthFormat(DateTime.fromISO(lastCloseDate))} Close`
                    : `Previous version`
                }`,
              },
            ],
          },
          { forceLayerId: DEFAULT_LAYER_ID },
        ),
      );
      const orgId = state.dataset.orgId;
      if (orgId != null) {
        dispatch(loadCompareBlocksDataAsync(orgId, [mutationBatch.id]));
      }
      dispatch(
        trackCreateSnapshotEvent({
          orgId: orgId ?? '',
          layerId: DEFAULT_LAYER_ID,
          mutationId: mutationBatch.id,
          millisecondsFromNow: Math.abs(
            Math.ceil(DateTime.fromISO(mutationBatch.createdAt ?? '').diffNow().milliseconds),
          ),
          reverseMutationIndex,
        }),
      );
    };
  },

  deleteNamedDatasetVersion: (mutationId: string): AppThunk => {
    return (dispatch, _getState) => {
      dispatch(
        submitMutation(
          { deleteNamedDatasetVersions: [{ mutationId }] },
          { forceLayerId: DEFAULT_LAYER_ID },
        ),
      );
    };
  },

  updateNamedDatasetVersion: (mutationId: string, name: string): AppThunk => {
    return (dispatch, _getState) => {
      dispatch(
        submitMutation(
          { updateNamedDatasetVersions: [{ mutationId, name }] },
          { forceLayerId: DEFAULT_LAYER_ID },
        ),
      );
    };
  },
};

export const {
  createLayer,
  createDraftLayerAndApplyMutation,
  commitLayer,
  deleteLayer,
  updateCurrentLayerName,
  updateCurrentLayerDescription,
  createNamedVersionFromCurrent,
  createNamedVersionFromMutationBatch,
  deleteNamedDatasetVersion,
  updateNamedDatasetVersion,
} = mutationActions;
