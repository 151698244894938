import groupBy from 'lodash/groupBy';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';

import { BuiltInDimensionType } from 'generated/graphql';
import { filterMatchesAttr } from 'helpers/dimensionalDrivers';
import {
  DateRangeContext,
  DriverFilterViewContext,
  InvalidExpressionContext,
} from 'helpers/formulaEvaluation/ForecastCalculator/CalculatorParser';
import { getAttributeIdFilters } from 'helpers/formulaEvaluation/ForecastCalculator/DependencyListener';
import { Listener } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { ModelingError } from 'helpers/formulaEvaluation/ForecastCalculator/ModelingError';
import { EvaluatorDriver } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { Attribute, DimensionId } from 'reduxStore/models/dimensions';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { COHORT_MONTH } from 'types/formula';

export class FormulaErrorListener implements Listener<boolean> {
  private entityId: string;
  private rawFormula: string;
  private insideAggregator: number;
  private driversById: NullableRecord<DriverId, EvaluatorDriver>;
  private attributesBySubDriverId: NullableRecord<DriverId, Attribute[]>;

  constructor(
    entityId: string,
    rawFormula: string,
    driversById: NullableRecord<DriverId, EvaluatorDriver>,
    attributesBySubDriverId: NullableRecord<DriverId, Attribute[]>,
  ) {
    this.entityId = entityId;
    this.rawFormula = rawFormula;
    this.driversById = driversById;
    this.attributesBySubDriverId = attributesBySubDriverId;
    this.insideAggregator = 0;
  }
  getResult() {
    return true;
  }

  getTimestampResult() {
    return this.getResult();
  }

  exitDateRange(ctx: DateRangeContext) {
    if (this.insideAggregator > 0) {
      return;
    }

    const parts = ctx.dateRef().map((c) => c.text);
    if (parts[0] !== parts[1]) {
      const parent = ctx.parent ?? ctx;
      const start = parent.start.charPositionInLine;
      throw new ModelingError(`Missing aggregator (e.g. SUM)`, start, 1);
    }
  }

  exitDriverFilterView(ctx: DriverFilterViewContext) {
    if (this.insideAggregator > 0) {
      return;
    }

    const dimDriverCtx = ctx.dimDriverView().dimDriverRef();
    const driverId = dimDriverCtx.UUID().text;
    const dimDriver = this.driversById[driverId];
    if (dimDriver?.type !== DriverType.Dimensional) {
      return;
    }

    const { attributeIdFilters, includeAllContextAttributes } = getAttributeIdFilters(ctx, {
      getAttribute: () => undefined,
    });
    const includesCohort = attributeIdFilters.some(
      ({ attrId }) => !isString(attrId) && attrId.type === COHORT_MONTH,
    );
    const start = ctx.start.charPositionInLine;
    // No aggregator for dim driver is only allowed for cohort dim drivers with thisCohortMonth filter
    if (!includesCohort) {
      throw new ModelingError(`Missing aggregator (e.g. SUM)`, start, 1);
    }

    const filterByDimId = mapValues(groupBy(attributeIdFilters, 'dimId'), (fs) =>
      fs.map((f) => f.attrId),
    );

    const subDrivers = dimDriver.subdrivers.filter((subdriver) => {
      return Object.entries(filterByDimId).every(([dimId, attrIds]) => {
        const attrId = subdriver.attributes.find((attr) => attr.dimensionId === dimId)?.id ?? null;
        if (dimId === BuiltInDimensionType.RelativeTime && includesCohort) {
          return true;
        }
        const filterIds = attrIds.map((id) => (isString(id) ? id : id.type));
        return filterMatchesAttr(
          filterIds,
          includeAllContextAttributes,
          attrId,
          this.getContextAttribute(dimId)?.id,
        );
      });
    });
    const subdriversPerRelativeTime = groupBy(
      subDrivers,
      (subdriver) =>
        subdriver.attributes.find((attr) => attr.dimensionId === BuiltInDimensionType.RelativeTime)
          ?.id,
    );
    if (Object.values(subdriversPerRelativeTime).some((val) => val.length > 1)) {
      throw new ModelingError(`Missing aggregator (e.g. SUM)`, start, 1);
    }
  }

  incrAggregator() {
    this.insideAggregator++;
  }

  decrAggregator() {
    this.insideAggregator--;
  }

  enterReducer = this.incrAggregator;
  exitReducer = this.decrAggregator;
  enterMatchFilter = this.incrAggregator;
  exitMatchFilter = this.decrAggregator;

  exitInvalidExpression(ctx: InvalidExpressionContext) {
    const start = ctx.start.charPositionInLine;
    const end =
      ctx.stop == null ? start : ctx.stop.charPositionInLine + (ctx.stop.text?.length ?? 0);
    const text = this.rawFormula.slice(start, end);
    throw new ModelingError(`"${text}" could not be found in any models.`, start, end - start);
  }

  private getContextAttribute(dimensionId: DimensionId): Attribute | undefined {
    return this.attributesBySubDriverId[this.entityId]?.find(
      (attr) => attr.dimensionId === dimensionId,
    );
  }
}
