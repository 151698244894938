export type RunwayError = 'NoLoggedInUser' | 'NotAuthorized' | 'NetworkError';

export const RUNWAY_ERROR_MESSAGES = {
  NoLoggedInUser: 'no logged in user',
  NotAuthorized: 'not authorized',
  NetworkError: 'network error',
} as const;

export const getRunwayErrorMessage = (error: RunwayError) => {
  return RUNWAY_ERROR_MESSAGES[error];
};
