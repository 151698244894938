import { Flex, Spinner, useBoolean } from '@chakra-ui/react';
import { useEffect } from 'react';

import {
  BLOCK_VIEW_AS_TO_DEFAULT_CHART_SERIES_TYPE,
  SERIES_COLORS,
} from 'components/AgGridComponents/AgChart/agCharts';
import {
  BlockViewOptions,
  ChartAxisType,
  ChartDisplayInput,
  ChartElementPosition,
  ChartGroup,
  ChartGroupInput,
  ChartPlotByType,
  ChartRollupType,
  ChartSeries,
  ChartSeriesInput,
  ChartSeriesType,
} from 'generated/graphql';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { updateBlockViewOptions } from 'reduxStore/actions/blockMutations';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { blockConfigViewOptionsSelector, blockDriverIdsSelector } from 'selectors/blocksSelector';
import { layerNameByIdSelector } from 'selectors/layerSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';

const AgChartMigrateBlockConfig = () => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const layerNameById = useAppSelector(layerNameByIdSelector);
  const driverIds = useAppSelector((state) => blockDriverIdsSelector(state, blockId));
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));

  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  const [migrated, { on: setMigrated }] = useBoolean(false);
  const { viewAsType, aggregateValues } = viewOptions;

  useEffect(() => {
    if (migrated || viewAsType == null || viewOptions.chartDisplay != null) {
      return;
    }

    const migrate = () => {
      setMigrated();

      const groupId = uuidv4();
      const yAxisId = uuidv4();
      const xAxisId = uuidv4();
      const seriesType = BLOCK_VIEW_AS_TO_DEFAULT_CHART_SERIES_TYPE[viewAsType];

      const series: ChartSeriesInput[] =
        seriesType == null
          ? []
          : driverIds.map((driverId, index) => ({
              id: uuidv4(),
              driverId,
              type: seriesType,
              color: SERIES_COLORS[index % SERIES_COLORS.length].value,
            }));
      const groups: ChartGroupInput[] = [
        {
          id: groupId,
          seriesIds: series.map((s) => s.id),
          isDefault: true,
          isPositive: true,
        },
      ];

      const chartDisplay: ChartDisplayInput = {
        axes: [
          {
            id: xAxisId,
            type: ChartAxisType.Time,
            position: ChartElementPosition.Bottom,
            time: {
              rollup: ChartRollupType.Monthly,
              plotBy: ChartPlotByType.Time,
            },
          },
          {
            id: yAxisId,
            type: ChartAxisType.Driver,
            position: ChartElementPosition.Left,
            driver: {
              groupIds: [groupId],
            },
          },
        ],
        series,
        groups,
      };

      const blockViewOptions: BlockViewOptions = {
        ...viewOptions,
        chartDisplay,
      };

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    };

    const migrateWithLayerComparisons = () => {
      setMigrated();

      if (comparisonLayerIds == null) {
        return;
      }

      const yAxisId = uuidv4();
      const xAxisId = uuidv4();
      const seriesType = BLOCK_VIEW_AS_TO_DEFAULT_CHART_SERIES_TYPE[viewAsType];

      const groups: ChartGroup[] = [];
      const series: ChartSeries[] = [];

      let groupIndex = 0;
      for (const layerId of new Set([baselineLayerId, ...comparisonLayerIds])) {
        const seriesIds = [];

        let seriesIndex = driverIds.length;
        for (const driverId of driverIds) {
          const seriesItem: ChartSeries = {
            id: uuidv4(),
            driverId,
            type: seriesType ?? ChartSeriesType.Line,
            color:
              SERIES_COLORS[
                // The baseline layer will have a unique color; comparison layer colors aren't unique.
                layerId === baselineLayerId
                  ? seriesIndex % SERIES_COLORS.length
                  : (driverIds.length + groupIndex) % SERIES_COLORS.length
              ].value,
          };
          series.push(seriesItem);
          seriesIds.push(seriesItem.id);

          ++seriesIndex;
        }

        const groupItem: ChartGroup = {
          id: uuidv4(),
          seriesIds,
          name: layerNameById[layerId],
          layerId,
          isDefault: groupIndex === 0,
        };
        groups.push(groupItem);

        ++groupIndex;
      }

      const chartDisplay: ChartDisplayInput = {
        axes: [
          {
            id: xAxisId,
            type: ChartAxisType.Time,
            position: ChartElementPosition.Bottom,
            time: {
              rollup: ChartRollupType.Monthly,
              plotBy: ChartPlotByType.Time,
            },
          },
          {
            id: yAxisId,
            type: ChartAxisType.Driver,
            position: ChartElementPosition.Left,
            driver: {
              groupIds: groups.map((g) => g.id),
            },
          },
        ],
        series,
        groups,
      };

      const blockViewOptions: BlockViewOptions = {
        ...viewOptions,
        chartDisplay,
      };

      dispatch(
        updateBlockViewOptions({
          blockId,
          blockViewOptions,
        }),
      );
    };

    if (comparisonLayerIds == null || comparisonLayerIds.length === 0) {
      migrate();
      return;
    }

    // Legacy layer comparisons will need to be migrated into different blocks.
    migrateWithLayerComparisons();
  }, [
    aggregateValues,
    baselineLayerId,
    blockId,
    comparisonLayerIds,
    dispatch,
    driverIds,
    layerNameById,
    migrated,
    setMigrated,
    viewAsType,
    viewOptions,
  ]);

  return (
    <Flex>
      <Spinner />
    </Flex>
  );
};

export default AgChartMigrateBlockConfig;
