import * as snippet from '@segment/snippet';
import { errorExchange, fetchExchange, subscriptionExchange } from '@urql/core';
import { retryExchange } from '@urql/exchange-retry';
import { getApps, initializeApp } from 'firebase/app';
import { MotionGlobalConfig } from 'framer-motion';
import { SubscribePayload } from 'graphql-ws';
import isEmpty from 'lodash/isEmpty';
import { Settings } from 'luxon';
import { NextComponentType } from 'next';
import { NextUrqlContext, NextUrqlPageContext, WithUrqlProps, withUrqlClient } from 'next-urql';
import { AppContext } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect, useMemo, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { useIsomorphicLayoutEffect } from 'react-use';
import { useClient } from 'urql';
// Uncomment the following line when you want to use Why Did You Render
// import '../src/wdyr';

// all global styles must be imported here; generally these are overrides for libraries with CSS
// https://nextjs.org/docs/messages/css-global
import 'components/AgGridComponents/AgChartsStyleOverrides.scss';
import 'components/AgGridComponents/AgGridStyleOverrides.scss';
import 'components/EmojiPicker/EmojiPicker.scss';
import 'components/FormulaInput/FormulaInput.scss';
import 'components/GlobalStyles.scss';
import 'components/Table/RowContainer.scss';
import 'components/TextBlock/TextBlock.scss';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import AppShell from 'components/AppShell';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import { RUNWAY_ERROR_MESSAGES } from 'config/errors';
import firebaseConfig from 'config/firebase';
import { graphqlUrl } from 'config/graphql/query';
import { fetchMaintenanceModeFlag } from 'config/launchDarkly';
import { maintenanceModePathName } from 'config/maintenanceMode';
import {
  appEnv,
  buildId,
  isCypress,
  isDevelopment,
  isProduction,
  isSSR,
} from 'helpers/environment';
import handleUrqlError from 'helpers/handleUrqlError';
import { restoreUserState } from 'helpers/localState';
import { NextRedirect, queryLoggedInUser } from 'helpers/next';
import { newRequestQueue } from 'helpers/requests';
import { getWsClient } from 'helpers/wsClient';
import { MaintenanceModeConfig } from 'reduxStore/reducers/launchDarklySlice';
import { loggedIn, loginError } from 'reduxStore/reducers/loginSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { getOrCreateStore } from 'reduxStore/store';
import { loggedInUserSelector } from 'selectors/loginSelector';
import { loadedSelectedOrgSelector } from 'selectors/selectedOrgSelector';
import { RunwayPageContext } from 'types/RunwayPageContext';

// Globally disable animations when running Cypress tests.
// This significantly reduces tests flake.
MotionGlobalConfig.skipAnimations = isCypress();

const segmentWriteKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

function renderSnippet() {
  const opts: snippet.Options = {
    host: isProduction ? 'app.runway.com' : 'runwaydev.com',
    apiKey: segmentWriteKey,
    ajsPath: `/seg/cdn/analytics.js/v1/${segmentWriteKey}/analytics.min.js`,
    load: true,
    // note: the page option only covers SSR tracking
    page: true,
  };

  // N.B. use unminified version in development
  return isDevelopment ? snippet.max(opts) : snippet.min(opts);
}

interface RunwayNextAppContext extends AppContext {
  Component: NextComponentType<RunwayPageContext>;
  ctx: NextUrqlPageContext;
}

interface InitialProps {
  cookies: string | undefined;
  reduxState?: RootState;
  error: Error | undefined;
}

type RunwayNextAppProps = WithUrqlProps & InitialProps;

const RunwayNextAppRoot: NextComponentType<
  RunwayNextAppContext,
  InitialProps,
  RunwayNextAppProps
> = ({ Component, pageProps, reduxState, error }: RunwayNextAppProps) => {
  // see https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a
  useIsomorphicLayoutEffect(() => {
    // Using a layout effect here to make sure nothing else runs before this is set, especially the
    // redux store, which uses this value.
    window.isMainThread = true;
  }, []);

  const urqlClient = useClient();
  const requestQueue = useMemo(newRequestQueue, []);
  const restoredState = restoreUserState(reduxState);
  const reduxStore = useRef(getOrCreateStore(restoredState, urqlClient, requestQueue));
  const orgName: string = loadedSelectedOrgSelector(reduxStore.current.getState())?.name ?? '';

  useEffect(() => {
    if (getApps().length === 0) {
      initializeApp(firebaseConfig);
    }
    Settings.defaultZone = 'utc';
    TagManager.initialize({ gtmId: 'GTM-TTMLP33' });
    DataArbiter.get().setDispatch(reduxStore.current.dispatch);
  }, []);

  let title = 'Runway';
  switch (appEnv) {
    case 'development':
      title = 'runwaylocal';
      break;
    case 'preview':
      title = 'Runway Preview';
      break;
    case 'staging':
      title = 'Runwaydev.com';
      break;
    case 'production':
    default:
      break;
  }

  let titleSuffix = '';
  if (orgName !== '') {
    titleSuffix += ` - ${orgName}`;
  }

  return (
    <>
      <Head>
        <title>{`${title}${titleSuffix}`}</title>
      </Head>
      <Script src="https://apis.google.com/js/api.js" />
      <Script id="segment-script" dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
      <AppShell store={reduxStore.current} requestQueue={requestQueue}>
        {error ? <ErrorPage /> : <Component {...pageProps} />}
      </AppShell>
    </>
  );
};

RunwayNextAppRoot.getInitialProps = async ({ Component, ctx }: RunwayNextAppContext) => {
  const cookies = ctx.req?.headers?.cookie;
  let reduxState: RootState | undefined;
  let pageProps = {};

  let queryError: Error | undefined;
  let maintenanceModeConfig: MaintenanceModeConfig | null | undefined;
  try {
    const requestQueue = newRequestQueue();

    const reduxStore = getOrCreateStore(undefined, ctx.urqlClient, requestQueue);

    const isServerSideRender = isSSR();
    const isNavigatingToMaintenance = ctx.pathname === maintenanceModePathName;
    // fetch the user -- this ensures that the user is authenticated on all pages
    const { loggedInUser, error } = await queryLoggedInUser(ctx);

    // if the user's query has failed, or they're trying to navigate to the maintenance page,
    // we fetch if we're in maintenance mode
    if (isServerSideRender && (error != null || isNavigatingToMaintenance)) {
      const userId = loggedInUser?.id;
      maintenanceModeConfig = await fetchMaintenanceModeFlag(userId);
      const isInMaintenance = !isEmpty(maintenanceModeConfig);
      if (isNavigatingToMaintenance) {
        // don't let users navigate to the maintenance page if we're not in maintenance mode
        if (!isInMaintenance) {
          NextRedirect(ctx, '/');
          return { pageProps, reduxState, cookies, error };
        } else {
          // pass in the user ID to the maintenance page
          return {
            pageProps: { userId },
            reduxState,
            cookies,
            error,
          };
        }
      } else if (isInMaintenance && error != null) {
        // we encountered a query error on a non-maintenance page, and expect to be in maintenance mode.
        // redirect to the maintenance page
        NextRedirect(ctx, maintenanceModePathName);
        return {
          pageProps: { userId },
          reduxState,
          cookies,
          error,
        };
      }

      // otherwise we want to run our regular error handling logic
    }

    const state = reduxStore.getState();
    if (error) {
      reduxStore.dispatch(loginError());
      throw error;
    } else if (loggedInUser && loggedInUserSelector(state) == null) {
      reduxStore.dispatch(loggedIn({ user: loggedInUser, isNew: ctx.query.isNew === 'true' }));
    }

    pageProps = Component.getInitialProps
      ? await Component.getInitialProps({ ...ctx, reduxStore })
      : {};

    if (isServerSideRender) {
      reduxState = reduxStore.getState();
    }
  } catch (err) {
    console.error(err);
    queryError = err as Error;
  }

  return { pageProps, reduxState, cookies, error: queryError };
};

type WithUrqlClientType = (
  AppOrPage: typeof RunwayNextAppRoot,
) => NextComponentType<NextUrqlContext, InitialProps, WithUrqlProps>;

export default (
  withUrqlClient(
    (ssrExchange, ctx) => ({
      url: graphqlUrl,
      fetchOptions: {
        headers: {
          Cookie: ctx?.req?.headers?.cookie ?? '',
          'X-Runway-Client': 'webapp',
          'X-Runway-Client-Version': buildId ?? 'unknown',
        },
        credentials: 'include',
      },
      exchanges: [
        // see https://formidable.com/open-source/urql/docs/advanced/subscriptions/#setting-up-graphql-ws
        subscriptionExchange({
          forwardSubscription: (operation) => ({
            subscribe: (sink) => ({
              unsubscribe: getWsClient().subscribe(operation as SubscribePayload, sink),
            }),
          }),
        }),
        ssrExchange,
        errorExchange({
          onError: (error, operation) => {
            // Do not log these errors to Sentry (just noise).
            if (
              error.message.endsWith(RUNWAY_ERROR_MESSAGES.NoLoggedInUser) ||
              error.message.endsWith(RUNWAY_ERROR_MESSAGES.NotAuthorized)
            ) {
              return;
            }
            handleUrqlError(error, operation);
          },
        }),
        retryExchange({
          initialDelayMs: 1000,
          maxDelayMs: 5000,
          maxNumberAttempts: 2,
          retryIf: (err) => err.networkError != null,
        }),
        fetchExchange,
      ],
    }),
    {
      ssr: true,
      neverSuspend: true,
    },
  ) as WithUrqlClientType
)(RunwayNextAppRoot);
