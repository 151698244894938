import { SearchIcon, TriangleDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  useMergeRefs,
} from '@chakra-ui/react';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { SearchableMultiSelectMenuContext } from 'components/SearchableMultiSelectMenu/SearchableMultiSelectMenuContext';

interface Props {
  children?: React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const SingleSelectSearchBar: React.FC<Props & InputProps> = ({ children, inputRef, ...props }) => {
  const [focus, setFocus] = useState<boolean>(false);
  const { query, setQuery, onKeyDown } = useContext(SearchableMultiSelectMenuContext);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (ev) => {
      setQuery(ev.target.value);
    },
    [setQuery],
  );

  const ref = useRef<HTMLInputElement>(null);
  useEffectOnce(() => {
    if (props.autoFocus === false) {
      return;
    }

    window.requestAnimationFrame(() => ref.current?.focus());
  });

  const mergedRef = useMergeRefs(ref, inputRef);

  return (
    <Box pb={1} w="full">
      {children}
      <InputGroup size="md">
        <Input
          variant="outline"
          size="sm"
          borderRadius="lg"
          ref={mergedRef}
          fontSize="xs"
          onChange={onChange}
          value={query}
          onKeyDown={onKeyDown}
          ml={1}
          borderColor="gray.300"
          focusBorderColor="selection.500"
          paddingLeft={focus ? 7 : undefined}
          cursor={focus ? undefined : 'pointer'}
          {...props}
          onFocus={(e) => {
            setFocus(true);
            props?.onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocus(false);
            props?.onBlur?.(e);
          }}
        />
        {focus && (
          <InputLeftElement>
            <SearchIcon fontSize={12} mb={2} color="gray.500" />
          </InputLeftElement>
        )}
        {!focus && (
          <InputRightElement cursor="pointer">
            <TriangleDownIcon fontSize={10} mb={2} color="gray.600" />
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
};

export default SingleSelectSearchBar;
