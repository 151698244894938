import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import { DateRange } from 'types/dataset';
import { MonthKey, TimeUnit } from 'types/datetime';
import { ALL_MONTH_DEPENDENCY_DATE_RANGE, DependencyDateRange } from 'types/dependencies';
import { DateReference } from 'types/formula';

import { getFinancialQuarter, getFinancialYear, offsetMonthKey } from './dates';

export function resolveDependencyDateRange(
  range: DependencyDateRange,
  referenceMonth: MonthKey,
): DateRange {
  if (range === ALL_MONTH_DEPENDENCY_DATE_RANGE) {
    return DataArbiter.get().getEncounteredDateRange();
  }
  return {
    start: resolveDateRef(range.start, referenceMonth),
    end: resolveDateRef(range.end, referenceMonth),
  };
}

// TODO: This currently doesn't use the `reference` on `DateReference`. From
// what I can tell this isn't really used. Should we just remove it?
function resolveDateRef(ref: DateReference, referenceMonth: MonthKey): MonthKey {
  if (ref.type === 'absolute') {
    return ref.val;
  }

  const { unit, val, isEnd } = ref.val;
  if (unit === TimeUnit.Month) {
    return offsetMonthKey(val, referenceMonth);
  }

  if (unit === TimeUnit.Quarter) {
    return getFinancialQuarter(val, referenceMonth, isEnd);
  }

  if (unit === TimeUnit.Year) {
    return getFinancialYear(val, referenceMonth, isEnd);
  }

  throw new Error('unable to resolve date ref');
}
