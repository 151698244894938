import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'PageIcon',
  viewBox: '0 0 20 20',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <g fill="currentColor">
      <path d="m16.883 6.475-4.262-4.262a2.174 2.174 0 0 0-1.55-.638H5.346a3.414 3.414 0 0 0-3.438 3.413V15c0 .912.35 1.788 1 2.438a3.415 3.415 0 0 0 2.425 1.012h8.75c1.9 0 3.438-1.537 3.438-3.438V8.025c0-.587-.225-1.137-.638-1.55Zm-2.8 10.088h-8.75c-.412 0-.812-.163-1.1-.463-.3-.3-.462-.688-.462-1.1V4.988c0-.863.712-1.55 1.575-1.55h5.3v2.4c0 1.437 1.162 2.6 2.6 2.6h2.4V15c0 .863-.7 1.563-1.563 1.563Z" />
      <path d="M6.583 10.938h2.5a.944.944 0 0 0 .938-.938.944.944 0 0 0-.938-.938h-2.5a.944.944 0 0 0-.937.938c0 .512.425.938.938.938ZM12.834 12.813h-6.25a.944.944 0 0 0-.938.937c0 .512.425.938.938.938h6.25a.944.944 0 0 0 .937-.938.944.944 0 0 0-.938-.938Z" />
    </g>
  ),
});
