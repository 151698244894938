import { Flex, Text } from '@chakra-ui/react';

import ArrowToggleButton from 'components/ArrowToggleButton/ArrowToggleButton';
import { CELL_HEIGHT_IN_PX } from 'config/cells';
import useBlockContext from 'hooks/useBlockContext';

export const OBJECT_GROUP_HEADING_ROW_HEIGHT_IN_PX = CELL_HEIGHT_IN_PX + 16;

interface Props {
  name: string;
  isExpanded: boolean;
  onToggleExpansion: () => void;
}

const ObjectGroupHeadingRow: React.FC<Props> = ({ name, onToggleExpansion, isExpanded }) => {
  const { gutterWidthInPxString } = useBlockContext();
  return (
    <Flex
      position="sticky"
      left={0}
      alignItems="center"
      bgColor="surface"
      role="row"
      w="fit-content"
      my={2}
    >
      <Flex bgColor="gray.200" ml={gutterWidthInPxString} borderRadius="md" pr={2}>
        <ArrowToggleButton
          isActive={isExpanded}
          onToggle={onToggleExpansion}
          data-testid="object-group-header"
        />
        <Text
          noOfLines={1}
          bgColor="gray.200"
          fontWeight="medium"
          whiteSpace="nowrap"
          lineHeight="1.4rem"
        >
          {name}
        </Text>
      </Flex>
    </Flex>
  );
};
export default ObjectGroupHeadingRow;
