import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Image from 'next/image';
import { useCallback } from 'react';
import Tilt from 'react-parallax-tilt';

import ChanceBorder from 'components/ErrorPage/ChanceBorder.svg';
import RunwayLogo from 'components/ErrorPage/RunwayLogo.svg';
import { openChat } from 'helpers/intercom';

const MonopolyBoard = styled.div`
  background: #000;
  -webkit-app-region: drag;
  height: 100vh;
  width: 100vw;
  display: grid;
  gap: 4px 4px;
  grid-template-columns: 16px 1fr 16px;
  grid-template-rows: 16px 1fr 16px;
  grid-template-areas:
    'top top top'
    'left content right'
    'bottom bottom bottom';

  .top {
    grid-area: top;
  }

  .bottom {
    grid-area: bottom;
  }

  .left {
    grid-area: left;
  }

  .right {
    grid-area: right;
  }

  .red {
    background: #ee3a23;
  }

  .blue {
    background: #284ea1;
  }

  .lightBlue {
    background: #87a5d7;
  }

  .white {
    background: #fff;
  }

  .yellow {
    background: #fce704;
  }

  .green {
    background: #13a55c;
  }

  .orange {
    background: #eb7141;
  }

  .pink {
    background: #ee3978;
  }

  .brown {
    background: #590c38;
  }
`;

const Content = styled.div`
  background: #dbefda;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 32px;
  grid-area: content;
  padding: 48px;
`;

const HorizonalBorder = styled.div`
  display: grid;
  grid-template-columns: 16px repeat(9, 1fr) 16px;
  grid-template-rows: 1fr;
  gap: 4px;
`;

const VerticalBorder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(9, 1fr);
  gap: 4px;
`;

const BoardCardOutline = styled.div`
  --aspect-ratio: 15/9;
  padding-bottom: calc(100% / (var(--aspect-ratio)));

  background: url(${ChanceBorder});
  background-size: contain;
  width: 100%;
  height: 0;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;

  span {
    padding: 10px;
    position: absolute;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    text-align: center;
    text-transform: uppercase;
  }
`;

const BoardCardLeft = styled(BoardCardOutline)`
  transform: rotate(165deg);
`;

const BoardCardRight = styled(BoardCardOutline)`
  transform: rotate(-15deg);
  margin-top: auto;
`;

const RunwayCenter = styled.div`
  background: url(${RunwayLogo}) no-repeat center;
  background-size: contain;
`;

const ErrorPageWrapper = styled.div`
  position: relative;
`;

const Fold = styled.div`
  position: absolute;
  width: 16px;
  height: 100%;
  margin: auto;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.09) 41.66%,
    rgba(255, 255, 255, 0.22) 44.79%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: matrix(-1, 0, 0, 1, 0, 0);
  left: 0;
  right: 0;
`;

const ErrorCardWrapper = styled.div`
  max-width: 100vw;
  min-width: 420px;
  transform: rotate(8deg);
  width: 30%;
  z-index: 1;

  .glare-wrapper {
    pointer-events: none;
  }

  > div {
    --aspect-ratio: 16/9;
    background: #eb7141;
    box-shadow:
      2px 2px 0 #a8431b,
      4px 4px 0 #923815,
      0 4px 4px rgba(0, 0, 0, 0.25),
      6px 14px 40px rgba(0, 0, 0, 0.2),
      30px 50px 60px rgba(0, 0, 0, 0.2);
    height: calc(100% / (var(--aspect-ratio)));
    width: 100%;
  }
`;

const ErrorCardContent = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2% 5%;
  color: #000;
`;

const ErrorCardMiddle = styled.div`
  display: flex;
  flex: 1 0;

  > * {
    display: flex;
    flex: 1 0;
  }

  span {
    align-self: center;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 2.25rem;
    text-align: center;
    text-transform: uppercase;

    @media only screen and (min-width: 1650px) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }

  .monopolyImg {
    margin: 20px 0;
    transform: translateZ(60px);
  }
`;

const ErrorCardBottom = styled.div`
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;

  @media only screen and (min-width: 1650px) {
    font-size: 1rem;
  }

  a {
    font-weight: 800;
  }
`;

const ErrorCardLabel = styled.span`
  font-family: 'Pacifico', cursive;
  font-size: 1.5rem;
`;

interface ErrorCardProps {
  tiltMaxAngleX?: number;
  tiltMaxAngleY?: number;
  glareMaxOpacity?: number;
  tiltAngleXInitial?: number;
  tiltAngleYInitial?: number;
  trackOnWindow?: boolean;
}

const ErrorPage: React.FC<ErrorCardProps> = (props) => {
  return (
    <ErrorPageWrapper>
      {/* See https://nextjs.org/docs/messages/no-page-custom-font; we may want to fix this */}
      {/* eslint-disable-next-line @next/next/no-page-custom-font */}
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      {/* See https://nextjs.org/docs/messages/no-page-custom-font; we may want to fix this */}
      {/* eslint-disable-next-line @next/next/no-page-custom-font */}
      <link
        href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap"
        rel="stylesheet"
      />
      <ErrorCard {...props} />
      <Fold />
      <MonopolyBoard>
        <Content>
          <BoardCardLeft>
            <span>Chance</span>
          </BoardCardLeft>
          <RunwayCenter />
          <BoardCardRight>
            <span>Community Chest</span>
          </BoardCardRight>
        </Content>
        <MonopolyBoardBorders />
      </MonopolyBoard>
    </ErrorPageWrapper>
  );
};

const ErrorCard = ({
  tiltMaxAngleX = 7,
  tiltMaxAngleY = 7,
  glareMaxOpacity = 0.2,
  tiltAngleXInitial = 5,
  tiltAngleYInitial = 5,
  trackOnWindow = true,
}: ErrorCardProps) => {
  const chatWithUs = useCallback(() => {
    openChat('Hey I ran into an error. Can you help me?');
  }, []);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
      pos="absolute"
      top="0"
      left="0"
    >
      <ErrorCardWrapper>
        <Tilt
          tiltMaxAngleX={tiltMaxAngleX}
          tiltMaxAngleY={tiltMaxAngleY}
          glareEnable
          glareMaxOpacity={glareMaxOpacity}
          glareColor="lightblue"
          glarePosition="all"
          tiltAngleXInitial={tiltAngleXInitial}
          tiltAngleYInitial={tiltAngleYInitial}
          trackOnWindow={trackOnWindow}
          gyroscope
        >
          <ErrorCardContent>
            <ErrorCardLabel>Chance</ErrorCardLabel>
            <ErrorCardMiddle>
              <span>Runway encountered an error</span>
              <Flex alignItems="center" justifyContent="center" flex="1">
                <Image
                  alt="Monopoly man"
                  src="/images/error_page_monopoly.png"
                  className="monopolyImg"
                  width={124}
                  height={124}
                />
              </Flex>
            </ErrorCardMiddle>
            <ErrorCardBottom>
              Check our{' '}
              <a href="https://status.runway.com/" target="_blank" rel="noopener noreferrer">
                <u>status page</u>
              </a>
              {' for updates or '}
              <a href="#" onClick={chatWithUs}>
                <u>contact support</u>
              </a>{' '}
              for further assistance.
            </ErrorCardBottom>
          </ErrorCardContent>
        </Tilt>
      </ErrorCardWrapper>
    </Flex>
  );
};

const BORDER_COLORS = {
  top: [
    'white',
    'red',
    'red',
    'white',
    'red',
    'white',
    'yellow',
    'yellow',
    'white',
    'yellow',
    'white',
  ],
  bottom: [
    'orange',
    'lightBlue',
    'lightBlue',
    'white',
    'lightBlue',
    'white',
    'white',
    'brown',
    'white',
    'brown',
    'white',
  ],
  left: ['orange', 'orange', 'white', 'orange', 'white', 'pink', 'pink', 'white', 'pink'],
  right: ['green', 'green', 'white', 'green', 'white', 'white', 'blue', 'white', 'blue'],
};

const MonopolyBoardBorders = () => (
  <>
    <HorizonalBorder className="top">
      {BORDER_COLORS.top.map((color, index) => (
        <div key={index} className={color} />
      ))}
    </HorizonalBorder>
    <HorizonalBorder className="bottom">
      {BORDER_COLORS.bottom.map((color, index) => (
        <div key={index} className={color} />
      ))}
    </HorizonalBorder>
    <VerticalBorder className="left">
      {BORDER_COLORS.left.map((color, index) => (
        <div key={index} className={color} />
      ))}
    </VerticalBorder>
    <VerticalBorder className="right">
      {BORDER_COLORS.right.map((color, index) => (
        <div key={index} className={color} />
      ))}
    </VerticalBorder>
  </>
);

export default ErrorPage;
