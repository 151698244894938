import { ComparisonColumn } from 'generated/graphql';

const REQUIRES_BASELINE_LAYER = [
  ComparisonColumn.BaselineVersion,
  ComparisonColumn.LatestVersion,
  ComparisonColumn.Variance,
  ComparisonColumn.VariancePercentage,
];

// BaselineVersion represents the current layer, and we toggle layers separately and not as part of comparison columns.
export const EXCLUDED_COMPARISON_COLUMNS = [
  ComparisonColumn.LatestVersion,
  ComparisonColumn.BaselineVersion,
];

const REQUIRES_VARIANCE = [ComparisonColumn.Variance, ComparisonColumn.VariancePercentage];

export const getRequiresBaselineLayer = (
  comparisonColumn: ComparisonColumn | undefined,
): boolean => {
  return comparisonColumn != null && REQUIRES_BASELINE_LAYER.includes(comparisonColumn);
};

export const getRequiresVariance = (comparisonColumn: ComparisonColumn | undefined): boolean => {
  return comparisonColumn != null && REQUIRES_VARIANCE.includes(comparisonColumn);
};
