import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export default {
  parts: ['list', 'item', 'divider'],
  variants: {
    light: {
      list: {
        bg: 'surface',
        boxShadow: 'menu',
        border: 'none',
      },
      item: {
        color: 'gray.600',
      },
      divider: {
        color: 'gray.300',
      },
    },
  },
  baseStyle: {
    list: {
      borderRadius: '0.375rem',
      px: 2,
      py: 2,
      maxHeight: '20rem',
      overflowY: 'auto',
    },
    divider: {
      my: 1,
      mx: 0,
    },
    item: {
      alignItems: 'center',
      borderRadius: '0.375rem',
      display: 'flex',
      fontSize: 'xs',
      fontWeight: 'medium',
      height: '1.75rem',
      minWidth: '12rem',
      px: 2,
      py: 1,
      transition: 'unset',
      _active: { bg: 'selection.500', color: 'surface' },
      _focusVisible: { bg: 'selection.500' },
      _hover: { bg: 'selection.500', color: 'surface' },
    },
  },
} as ComponentMultiStyleConfig;
