import { Text } from '@chakra-ui/react';
import React, { useCallback, useContext, useMemo } from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import DriverPropertyTableCell from 'components/DriverPropertyTableCell/DriverPropertyTableCell';
import PropertyPicker from 'components/SubmodelTable/PropertyPicker';
import { DriverRowContext } from 'config/driverRowContext';
import { ColumnLayerId } from 'config/modelView';
import { DriverType, RollupReducer } from 'generated/graphql';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useDriverCellRef from 'hooks/useDriverCellRef';
import { updateDriverRollupReducer } from 'reduxStore/actions/driverMutations';
import { driverSelector } from 'selectors/driversSelector';
import { driverRollupReducerSelector } from 'selectors/rollupSelector';
import { shouldDisableDriverEditingSelector } from 'selectors/scenarioComparisonSelector';

const COLUMN_TYPE = 'rollupType';

type Props = {
  columnLayerId: ColumnLayerId;
};

const RollupTypeTableCell: React.FC<Props> = ({ columnLayerId }) => {
  const dispatch = useAppDispatch();
  const { driverId, comparisonRowLayerId } = useContext(DriverRowContext);
  const { blockId } = useBlockContext();
  const onSelect = useCallback(
    (rollupReducer: RollupReducer) => {
      dispatch(updateDriverRollupReducer({ id: driverId, rollupReducer }));
    },
    [dispatch, driverId],
  );
  const shouldDisableEditing = useAppSelector((state) =>
    shouldDisableDriverEditingSelector(state, blockId),
  );

  const layerId = comparisonRowLayerId ?? columnLayerId;

  const driver = useAppSelector((state) => driverSelector(state, { id: driverId, layerId }));
  const rollupType = useAppSelector((state) =>
    driverRollupReducerSelector(state, { id: driverId, layerId }),
  );

  const options = useMemo(
    () =>
      Object.values(RollupReducer).map((type) => ({
        item: type,
        isSelected: type === rollupType,
      })),
    [rollupType],
  );

  const cellRef = useDriverCellRef({ columnType: COLUMN_TYPE, columnLayerId });

  return (
    <CellContextProvider cellRef={cellRef}>
      {driver?.type !== DriverType.Basic ? (
        <DriverPropertyTableCell columnType={COLUMN_TYPE} columnLayerId={columnLayerId} isSticky />
      ) : (
        <PropertyPicker
          title="Set rollup"
          options={options}
          onSelect={onSelect}
          columnType={COLUMN_TYPE}
          columnLayerId={columnLayerId}
          renderItem={({ item }) => <Text>{item}</Text>}
          disabled={shouldDisableEditing}
        >
          <Text>{rollupType}</Text>
        </PropertyPicker>
      )}
    </CellContextProvider>
  );
};

export default React.memo(RollupTypeTableCell);
