import { DateTime } from 'luxon';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { extractMonthKey } from 'helpers/dates';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { getCacheKeyForLayerSelector, SelectorWithLayerParam } from 'helpers/layerSelectorFactory';
import { getGivenOrCurrentLayerId, layersSelector } from 'selectors/layerSelector';
import { MonthKey } from 'types/datetime';
import { Selector } from 'types/redux';

const DEFAULT_LAST_ACTUALS_TIME = '2020-04';
export const datasetLastActualsTimeSelector: Selector<string> = (state) => {
  const { lastActualsTime } = state.dataset;

  return lastActualsTime ?? DEFAULT_LAST_ACTUALS_TIME;
};

/**
 * Gets the last date where all drivers have an actuals value populated.
 */
export const datasetLastActualsMonthKeySelector: Selector<MonthKey> = createSelector(
  datasetLastActualsTimeSelector,
  (lastActualsTime) => extractMonthKey(lastActualsTime),
);

export const datasetLastActualsDateTimeSelector: Selector<DateTime> = createDeepEqualSelector(
  datasetLastActualsTimeSelector,
  (lastActualsTime) => {
    return DateTime.fromISO(lastActualsTime).endOf('month').startOf('second');
  },
);

export const datasetFiveYearsFromLastActualsDateTimeRangeSelector: Selector<[DateTime, DateTime]> =
  createSelector(datasetLastActualsDateTimeSelector, (lastActualsDateTime) => {
    const start = lastActualsDateTime;
    const end = lastActualsDateTime.plus({ years: 5 });
    return [start, end];
  });

const lastActualsTimeForLayerSelector: SelectorWithLayerParam<string> = createCachedSelector(
  layersSelector,
  getGivenOrCurrentLayerId,
  datasetLastActualsTimeSelector,
  (layers, layerId, lastActualsTime) => {
    const layer = layers[layerId];
    return layer?.lastActualsTime ?? lastActualsTime;
  },
)(getCacheKeyForLayerSelector);

export const lastActualsMonthKeyForLayerSelector: SelectorWithLayerParam<MonthKey> =
  createCachedSelector(
    lastActualsTimeForLayerSelector,
    function lastActualsMonthKeyForLayerSelector(lastActualsTime) {
      return extractMonthKey(lastActualsTime);
    },
  )(getCacheKeyForLayerSelector);
