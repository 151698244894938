import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import remove from 'lodash/remove';

import {
  IntegrationQueryCreateInput,
  IntegrationQueryDeleteInput,
  IntegrationQueryUpdateInput,
} from 'generated/graphql';
import { toExtSource } from 'helpers/integrations';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { IntegrationQuery } from 'reduxStore/models/integrationQueries';
import { LightLayer } from 'reduxStore/models/layers';

export function setIntegrationQueriesFromDatasetSnapshot(
  layer: Draft<LightLayer>,
  dataset: DatasetSnapshot,
) {
  if (dataset == null) {
    layer.integrationQueries = { byId: {}, allIds: [] };
    return;
  }

  const integrationQueries = dataset.integrationQueries.map((query) => {
    const queryCopy = { ...query };
    queryCopy.source = toExtSource(query.source);
    return queryCopy as IntegrationQuery;
  });

  layer.integrationQueries = {
    byId: keyBy(integrationQueries, 'id'),
    allIds: integrationQueries.map((q) => q.id),
  };
}

export function handleCreateIntegrationQuery(
  layer: Draft<LightLayer>,
  input: IntegrationQueryCreateInput,
) {
  const { id, name, content, source, enableExtTable, createdAt, linkedAccountId } = input;

  const query: IntegrationQuery = {
    id,
    name,
    content,
    source: toExtSource(source),
    isScheduled: false,
    isScheduledExtTable: false,
    enableExtTable: enableExtTable ?? false,
    createdAt,
    linkedAccountId,
    lastSyncedAt: undefined,
  };
  layer.integrationQueries.byId[id] = query;
  layer.integrationQueries.allIds.push(id);
}

export function handleUpdateIntegrationQuery(
  layer: Draft<LightLayer>,
  input: IntegrationQueryUpdateInput,
) {
  const {
    id,
    name,
    content,
    isScheduled,
    isScheduledExtTable,
    enableExtTable,
    linkedAccountId,
    lastSyncedAt,
  } = input;
  const query = layer.integrationQueries.byId[id];
  if (query == null) {
    return;
  }
  if (name != null) {
    query.name = name;
  }
  if (content != null) {
    query.content = content;
  }
  if (isScheduled != null) {
    query.isScheduled = isScheduled;
  }
  if (isScheduledExtTable != null) {
    query.isScheduledExtTable = isScheduledExtTable;
  }
  if (lastSyncedAt != null) {
    query.lastSyncedAt = lastSyncedAt;
  }

  if (enableExtTable != null) {
    query.enableExtTable = enableExtTable;
  }

  if (linkedAccountId != null) {
    query.linkedAccountId = linkedAccountId;
  }
}

export function handleDeleteIntegrationQuery(
  layer: Draft<LightLayer>,
  input: IntegrationQueryDeleteInput,
) {
  const { id } = input;
  const query = layer.integrationQueries.byId[id];
  if (query == null) {
    return;
  }
  delete layer.integrationQueries.byId[id];
  remove(layer.integrationQueries.allIds, (i) => i === id);
}
