import { Box } from '@chakra-ui/react';
import React from 'react';

import { getTimeseriesCellTestId } from 'components/AgGridComponents/CellRenderer/helpers';
import {
  TimeseriesColumnDef,
  TimeseriesPropertyRow,
} from 'components/AgGridComponents/types/TimeseriesColumnDef';
import Formula from 'components/Formula/Formula';
import { useFormulaDisplay } from 'components/SubmodelTable/useFormulaDisplay';
import { ACTUALS_FORMULA_COLUMN_TYPE } from 'config/modelView';
import { DriverId } from 'reduxStore/models/drivers';

const ObjectSubDriverFormulaCellRendererContent = ({
  colDef,
  driverId,
  isActuals,
}: {
  colDef: TimeseriesColumnDef;
  driverId: DriverId;
  isActuals: boolean;
}) => {
  const { color, formulaDisplay } = useFormulaDisplay({
    isActuals,
    driverId,
  });

  if (formulaDisplay == null) {
    return <EmptyObjectSubDriverFormulaCellRenderer colDef={colDef} />;
  }

  return (
    <Box data-testid={getTimeseriesCellTestId(colDef)} width="full" height="full">
      <Formula formulaDisplay={formulaDisplay} color={color} />
    </Box>
  );
};

interface Props {
  data: TimeseriesPropertyRow | undefined;
  colDef: TimeseriesColumnDef;
}

const ObjectSubDriverFormulaCellRenderer = (props: Props) => {
  const { data: row, colDef } = props;
  const driverId = row?.driverId;
  const isActuals = colDef.colId === ACTUALS_FORMULA_COLUMN_TYPE;

  if (row == null || driverId == null) {
    return <EmptyObjectSubDriverFormulaCellRenderer colDef={colDef} />;
  }

  return (
    <ObjectSubDriverFormulaCellRendererContent
      colDef={colDef}
      driverId={driverId}
      isActuals={isActuals}
    />
  );
};

const EmptyObjectSubDriverFormulaCellRenderer: React.FC<{ colDef: TimeseriesColumnDef }> =
  React.memo(({ colDef }) => {
    return <Box data-testid={getTimeseriesCellTestId(colDef)} width="full" height="full" />;
  });

export default ObjectSubDriverFormulaCellRenderer;
