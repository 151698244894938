import React, { useContext, useMemo } from 'react';

import EmptyTimeSeriesCell from 'components/DriverTimeSeriesCell/EmptyTimeSeriesCell';
import { CellType } from 'config/cells';
import { ObjectFieldTimeSeriesRowContext } from 'config/objectFieldTimeSeriesRowContext';
import { RollupType } from 'generated/graphql';
import { getMonthColumnKey } from 'helpers/cells';
import { TimeSeriesComparisonSubColumn } from 'helpers/rollups';
import { LayerId } from 'reduxStore/models/layers';
import { MonthKey } from 'types/datetime';

export type EmptyObjectFieldTimeSeriesCellProps = {
  subLabel: TimeSeriesComparisonSubColumn | undefined;
  layerId: LayerId | undefined;
  monthKey: MonthKey;
};

const EmptyObjectFieldTimeSeriesCell: React.FC<EmptyObjectFieldTimeSeriesCellProps> = ({
  layerId,
  subLabel,
  monthKey,
}) => {
  const { businessObjectId, fieldSpecId } = useContext(ObjectFieldTimeSeriesRowContext);
  const cellRef = useMemo(() => {
    return {
      type: CellType.ObjectFieldTimeSeries,
      rowKey: { objectId: businessObjectId, fieldSpecId, layerId },
      columnKey: getMonthColumnKey(monthKey, RollupType.Month, subLabel),
    } as const;
  }, [businessObjectId, fieldSpecId, layerId, monthKey, subLabel]);

  return <EmptyTimeSeriesCell cellRef={cellRef} />;
};
export default EmptyObjectFieldTimeSeriesCell;
