import { deepEqual } from 'fast-equals';
import { useCallback, useMemo } from 'react';

import { HARD_CODED_ACTUAL_TOOLTIP } from 'components/AgGridComponents/CellRenderer/constants';
import { cellValueStyle } from 'components/AgGridComponents/CellRenderer/helpers';
import DriverImpactTooltipContent from 'components/PlanImpactTooltipContent/DriverImpactTooltipContent';
import { CellValueTooltipData } from 'config/cells';
import useAppSelector from 'hooks/useAppSelector';
import { useIsActuals } from 'hooks/useIsActuals';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { entityMonthKeyValueForLayerSelector } from 'selectors/calculationsSelector';
import { driverMonthKeysWithEventImpactSelector } from 'selectors/driverMonthKeysWithEventImpactSelector';
import {
  driverActualsFormulaSelector,
  driverActualsOverrideMonthKeysSelector,
} from 'selectors/driversSelector';
import { objectFieldValueTooltipSelector } from 'selectors/objectFieldValueTooltipSelector';
import { isCalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

const DEFAULT_ERROR_TOOLTIP = 'There was an error computing the value';

export function useValueTooltip({
  driverId,
  monthKey,
  layerId,
}: {
  driverId: DriverId;
  monthKey: MonthKey;
  layerId: LayerId | undefined;
}): CellValueTooltipData | undefined {
  const isActuals = useIsActuals(monthKey, layerId);
  const hasHardcodedActual = useAppSelector((state) =>
    driverActualsOverrideMonthKeysSelector(state, { id: driverId, layerId }).has(monthKey),
  );
  const driverActualsFormula = useAppSelector((state) =>
    driverActualsFormulaSelector(state, {
      id: driverId,
      layerId,
    }),
  );
  const hasImpact = useAppSelector((state) =>
    driverMonthKeysWithEventImpactSelector(state, { id: driverId, layerId }).has(monthKey),
  );

  // If there is an error with a specific message that we want to display, we
  // should return that here. Otherwise, fall back to the fixed tooltips per
  // value style.

  const monthKeyValue = useAppSelector(
    (state) => entityMonthKeyValueForLayerSelector(state, { id: driverId, layerId, monthKey }),
    deepEqual,
  );

  const valueStyle = cellValueStyle({
    backingType: 'driver',
    isActuals,
    hasHardcodedActual,
    driverHasActualsFormula: driverActualsFormula != null,
    fieldHasIntegrationActuals: false,
    hasImpact,
  });

  let label: React.ReactNode | undefined;
  switch (valueStyle) {
    case 'actuals-hardcoded':
      label = HARD_CODED_ACTUAL_TOOLTIP;
      break;
    case 'error':
      label = DEFAULT_ERROR_TOOLTIP;
      break;
    case 'forecast-impact':
      label = (
        <DriverImpactTooltipContent driverId={driverId} layerId={layerId} monthKey={monthKey} />
      );
      break;
    default:
  }

  const errorTooltip = useMemo(() => {
    if (!isCalculationError(monthKeyValue)) {
      return null;
    }

    return {
      hasUnderline: false,
      content: {
        type: 'driver_calc_error' as const,
        error: monthKeyValue,
        detailDriverId: driverId,
      },
    };
  }, [monthKeyValue, driverId]);

  const underlineTooltip = useMemo(() => {
    if (label == null) {
      return undefined;
    }

    return { hasUnderline: true, content: { type: valueStyle, msg: label } };
  }, [valueStyle, label]);

  return errorTooltip ?? underlineTooltip;
}

export function useObjectFieldValueTooltip({
  objectId,
  fieldSpecId,
  monthKey,
  blockId,
  layerId,
}: {
  objectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  monthKey: MonthKey;
  blockId: BlockId;
  layerId: LayerId;
}): CellValueTooltipData | undefined {
  const tooltipSelector = useCallback(
    (state: RootState) => {
      return objectFieldValueTooltipSelector(state, {
        objectId,
        fieldSpecId,
        monthKey,
        blockId,
        layerId,
      });
    },
    [objectId, fieldSpecId, monthKey, blockId, layerId],
  );
  return useAppSelector(tooltipSelector);
}
