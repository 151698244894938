import { createCachedSelector } from 're-reselect';

import { BlockId } from 'reduxStore/models/blocks';
import { LayerId } from 'reduxStore/models/layers';
import { blockConfigSelector } from 'selectors/blocksSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { ParametricSelector } from 'types/redux';

/**
 * The baselineLayerId is (optionally) set on the block config, and does not need to be the currentLayerId.
 * One example is the Merge Screen - the baselineLayerId is always the parentLayer of the scenario you're merging in.
 * if no baselineLayerId is set, we default to the currentLayerId
 */
export const baselineLayerIdForBlockSelector: ParametricSelector<BlockId, LayerId> =
  createCachedSelector(
    blockConfigSelector,
    currentLayerIdSelector,
    (blockConfig, currentLayerId) => {
      return blockConfig?.comparisons?.baselineLayerId ?? currentLayerId;
    },
  )(blockIdSelector);
