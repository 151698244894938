import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { BlockId } from 'reduxStore/models/blocks';
import { AttributeId, DimensionId } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId } from 'reduxStore/models/events';
import { SubmodelId } from 'reduxStore/models/submodels';
import type {
  AlertDialog,
  DeleteIntegrationQuery,
  IntegrationToBeDeleted,
  RemoveOrgUser,
} from 'reduxStore/reducers/alertDialogSlice';
import { Selector } from 'types/redux';

const alertDialogSelector: Selector<AlertDialog> = (state) => state.alertDialog;

export const isAlertDialogOpenSelector = createSelector(
  alertDialogSelector,
  (dialog) => !isEmpty(dialog),
);

export const deleteDatabaseColumnStateSelector = createSelector(
  alertDialogSelector,
  ({ databaseColumnToBeDeleted }) => databaseColumnToBeDeleted,
);

export const deleteObjectSpecIdSelector = createSelector(
  alertDialogSelector,
  ({ objectSpecToBeDeleted }) => objectSpecToBeDeleted?.objectSpecId,
);

export const deleteDialogDriverIdsSelector: Selector<DriverId[] | undefined> = createSelector(
  alertDialogSelector,
  ({ deleteDialogDriverIds }) => deleteDialogDriverIds,
);

export const deleteModelIdSelector: Selector<SubmodelId | undefined> = createSelector(
  alertDialogSelector,
  ({ deleteModelId }) => deleteModelId,
);

export const deleteDialogDimensionIdSelector: Selector<DimensionId | undefined> = createSelector(
  alertDialogSelector,
  ({ deleteDialogDimensionId }) => deleteDialogDimensionId,
);

export const deleteDialogAttributeIdSelector: Selector<AttributeId | undefined> = createSelector(
  alertDialogSelector,
  ({ deleteDialogAttribute }) => deleteDialogAttribute?.attributeId,
);

export const deleteDialogAttributeBlockIdSelector: Selector<BlockId | undefined> = createSelector(
  alertDialogSelector,
  ({ deleteDialogAttribute }) => deleteDialogAttribute?.blockId,
);

export const deleteIntegrationSelector: Selector<IntegrationToBeDeleted | undefined> =
  createSelector(alertDialogSelector, ({ integrationToBeDeleted }) => integrationToBeDeleted);

export const deleteIntegrationQuerySelector: Selector<DeleteIntegrationQuery | undefined> =
  createSelector(alertDialogSelector, ({ deleteIntegrationQuery }) => deleteIntegrationQuery);

export const removeOrgUserSelector: Selector<RemoveOrgUser | undefined> = createSelector(
  alertDialogSelector,
  ({ removeOrgUser }) => removeOrgUser,
);

export const deleteEventGroupIdSelector: Selector<EventGroupId | undefined> = createSelector(
  alertDialogSelector,
  ({ deleteEventGroupId }) => deleteEventGroupId,
);
