import { accessibleLayerIdsForMutation } from 'helpers/accessibleLayerIdsForMutation';
import { LayerId } from 'reduxStore/models/layers';
import { MutationBatch, MutationId } from 'reduxStore/models/mutations';
import {
  applyMutationBatch as webworkerApplyMutationBatch,
  undoMutationBatch as webworkerUndoMutationBatch,
} from 'reduxStore/reducers/calculationsSlice';
import {
  applyMutationLocally_INTERNAL,
  undoMutationLocally_INTERNAL,
} from 'reduxStore/reducers/datasetSlice';
import { AppThunk } from 'reduxStore/store';
import {
  activeComparisonLayerIdsForMutationBatchSelector,
  activeComparisonLayerIdsSelector,
} from 'selectors/activeBlocksSelector';
import { authenticatedUserIdSelector } from 'selectors/loginSelector';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';

type UndoLatestThunkArgs = {
  layerId: LayerId;
  toUndoId: MutationId;
  updatedPreviousMutationId: MutationId;
  isRemote?: boolean;
};

export const undoMutationLocallyForActiveLayers =
  (undoLatestArgs: UndoLatestThunkArgs): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const visibleLayerIds = activeComparisonLayerIdsSelector(state);

    // Forward mutation to webworker first to ensure the cache is fresh.
    // This affects cell editing and cache invalidation.
    dispatch(
      webworkerUndoMutationBatch({
        ...undoLatestArgs,
        activeLayerIds: visibleLayerIds,
      }),
    );

    dispatch(undoMutationLocally_INTERNAL({ ...undoLatestArgs, activeLayerIds: visibleLayerIds }));
  };

type ApplyMutationThunkArgs = {
  layerId: LayerId;
  mutationBatch: MutationBatch;
  isRedo?: boolean;
  isRemote?: boolean;
  isEphemeral?: boolean;
};

export const applyMutationLocallyForActiveLayers =
  (applyMutationArgs: ApplyMutationThunkArgs): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const userId = authenticatedUserIdSelector(state);
    const orgId = selectedOrgSelector(state)?.id ?? '';
    const activeLayerIds = activeComparisonLayerIdsForMutationBatchSelector(
      state,
      applyMutationArgs.mutationBatch,
    );
    const accessibleLayerIds = accessibleLayerIdsForMutation(
      state,
      applyMutationArgs.mutationBatch.mutation,
    );

    // Forward mutation to webworker first to ensure the cache is fresh.
    // This affects cell editing and cache invalidation.
    dispatch(
      webworkerApplyMutationBatch({
        ...applyMutationArgs,
        userId,
        orgId,
        activeLayerIds,
        accessibleLayerIds,
      }),
    );

    dispatch(
      applyMutationLocally_INTERNAL({
        ...applyMutationArgs,
        userId,
        orgId,
        activeLayerIds,
        accessibleLayerIds,
      }),
    );
  };
