import React, { useCallback, useContext } from 'react';

import { Tooltip } from 'chakra/tooltip';
import AttributeFiltersBadges from 'components/Formula/AttributeFiltersBadges';
import AttributePill from 'components/FormulaInput/AttributePill';
import DateRangePill from 'components/FormulaInput/DateRangePill';
import FormulaSelectionContext from 'components/FormulaInput/FormulaSelectionContext';
import ReferencePill from 'components/FormulaInput/ReferencePill';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useFormulaPillMouseDown from 'hooks/useFormulaPillMouseDown';
import { jumpToDriver } from 'reduxStore/actions/jumpToDriver';
import { driverIsKpiSelector } from 'selectors/driversSelector';
import { DraftDecoratorComponentProps, DriverRefMetadata } from 'types/formula';
import Star from 'vectors/Star';

const DriverDecorator: React.FC<DraftDecoratorComponentProps> = ({
  contentState,
  entityKey,
  offsetKey,
}) => {
  const dispatch = useAppDispatch();
  const { activeEntity, closeFormulaInput, hasError, keysInSelection } =
    useContext(FormulaSelectionContext);
  const entity = contentState.getEntity(entityKey ?? '');
  const { id, label, dateRangeDisplay, error, attributeFilters, placeholder } =
    entity.getData() as DriverRefMetadata;
  const isSelectedEntity = activeEntity?.key === entityKey;
  const onCtrlClick = useCallback(() => {
    if (!hasError) {
      closeFormulaInput();
      dispatch(jumpToDriver({ driverId: id }));
    }
  }, [closeFormulaInput, hasError, dispatch, id]);
  const isKpi = useAppSelector((state) => driverIsKpiSelector(state, id));
  const onNameMouseDown = useFormulaPillMouseDown(entityKey, entity, { onCtrlClick });
  const onDateMouseDown = useFormulaPillMouseDown(entityKey, entity, { submenu: 'timePeriod' });

  if (entityKey == null) {
    return null;
  }

  const placeholderBadge = placeholder != null ? <AttributePill label={placeholder} /> : null;

  let kpiIcon: JSX.Element | undefined;
  if (isKpi) {
    kpiIcon = (
      <Tooltip label="This driver is a KPI" placement="top">
        <Star mr={1} />
      </Tooltip>
    );
  }

  const isSelected = isSelectedEntity || keysInSelection.has(entityKey);
  return (
    <ReferencePill
      isSelected={isSelectedEntity || keysInSelection.has(entityKey)}
      error={error}
      label={label}
      icon={kpiIcon}
      attributes={
        <AttributeFiltersBadges attributeFilters={attributeFilters} includeEmpty={false} />
      }
      placeholder={placeholderBadge}
      offsetKey={offsetKey}
      onMouseDown={onNameMouseDown}
    >
      {dateRangeDisplay != null && (
        <DateRangePill
          onMouseDown={onDateMouseDown}
          label={dateRangeDisplay}
          isSelected={isSelected}
        />
      )}
    </ReferencePill>
  );
};

export default DriverDecorator;
