import { Center, ColorProps, Flex, FlexProps, Text, TypographyProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import DefaultScenarioIcon from 'components/ScenarioManagement/DefaultScenarioIcon';
import {
  COMPARISON_LAYER_COLORS,
  COMPARISON_LAYER_HIGHLIGHT_COLORS,
  DEFAULT_COMPARISON_LAYER_COLOR,
  DEFAULT_COMPARISON_LAYER_HIGHLIGHT_COLOR,
} from 'config/scenarioComparison';
import { extractEmoji } from 'helpers/emoji';
import { Layer } from 'reduxStore/models/layers';
import RepeatClock from 'vectors/RepeatClock';

type TextProps = {
  fontSize?: TypographyProps['fontSize'];
  fontWeight?: string;
  color?: string;
};

export const LayerName = ({
  layer,
  layerType,
  textProps = { fontSize: 'xs', fontWeight: '500', color: 'gray.600' },
  blockProps = {},
  icon,
}: {
  layer: Layer;
  textProps?: TextProps;
  layerType: 'current' | 'merge';
  color?: ColorProps['color'];
  fontWeight?: string;
  fontSize?: string;
  blockProps?: FlexProps;
  icon?: ReactNode;
}) => {
  const layerName = layer.name;
  const [layerEmoji, layerDisplayName] = extractEmoji(layerName);

  const layerColor =
    layerType === 'current' ? COMPARISON_LAYER_COLORS[0] : DEFAULT_COMPARISON_LAYER_COLOR;
  const highlightColor =
    layerType === 'current'
      ? COMPARISON_LAYER_HIGHLIGHT_COLORS[0]
      : DEFAULT_COMPARISON_LAYER_HIGHLIGHT_COLOR;
  const isLayerNamedVersion = layer?.lockedMutationId != null;

  return (
    <Flex align="center" gap="1" {...textProps} {...blockProps}>
      <Center width="5" height="5" padding="1" bgColor={layerColor} borderRadius="md">
        {isLayerNamedVersion ? (
          <RepeatClock boxSize={4} color={highlightColor} />
        ) : (
          <EmojiIcon
            emoji={layerEmoji}
            size="sm"
            emptyIcon={<DefaultScenarioIcon color={highlightColor} />}
          />
        )}
      </Center>
      <Text {...textProps} whiteSpace="nowrap" pl={0}>
        {layerDisplayName}
      </Text>
      {icon}
    </Flex>
  );
};
