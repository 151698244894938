import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { deepEqual } from 'fast-equals';

import { WithEventGroup } from '@features/Plans';
import { DatabaseGroupKey } from 'config/businessObjects';
import { CellRef, CellSelection, CopiedCells, DriverDataCellRef } from 'config/cells';
import { LinkedAccount } from 'generated/graphql';
import { cellRefSupportsCellPalette } from 'helpers/cells';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { ExtSource, IntegrationTab } from 'helpers/integrations';
import { routeTo } from 'helpers/navigation';
import { nullSafeEqual } from 'helpers/typescript';
import { BlockId, BlocksPageId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DimensionId } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { PlanTimelineItemRef } from 'reduxStore/models/events';
import { ExtDriverSource } from 'reduxStore/models/extDrivers';
import { IntegrationQueryId } from 'reduxStore/models/integrationQueries';
import { SubmodelId } from 'reduxStore/models/submodels';
import { NumberValue } from 'reduxStore/models/value';
import {
  applyMutationLocally_INTERNAL,
  initializeWithSnapshot,
} from 'reduxStore/reducers/datasetSlice';

export type PageType = NonNullable<PageState>['type'];

export type AutoFocusItem =
  | {
      type: 'submodel';
      id: SubmodelId;
    }
  | {
      type: 'blocksPage';
      id: BlocksPageId;
    }
  | {
      type: 'block';
      id: BlockId;
    }
  | {
      type: 'eventGroup';
      id: string;
      blockId: BlockId;
    }
  | {
      type: 'objectField';
      fieldSpecId: BusinessObjectFieldSpecId;
      blockId: BlockId;
      groupKey?: DatabaseGroupKey;
      objectId?: BusinessObjectId;
    }
  | {
      type: 'driverGroupRenameGroup';
      driverGroupId: DriverGroupId;
    }
  | {
      type: 'driverGroupCreateDriver';
      driverGroupId: DriverGroupId;
    }
  | {
      type: 'object';
      id: BusinessObjectId;
      blockId: BlockId;
    }
  | {
      type: 'datawarehouseNewQueryRename';
      queryId: IntegrationQueryId;
    }
  | {
      type: 'databaseCreateNewPropertyMenu';
      propertyType: 'driverProperty' | 'dimensionalProperty';
    }
  | {
      type: 'segmentByMenu';
    };

export type SelectionState =
  | {
      type: 'eventsAndGroups';
      blockId: BlockId;
      active: PlanTimelineItemRef;
      refs: PlanTimelineItemRef[];
      cellSelection?: CellSelection;
    }
  | CellSelection
  | null;

export type PopoverState = CellPalettePopoverState | null;

type CellPaletteState = 'default' | 'plans' | 'color' | 'more';
export type CellPalettePopoverState = {
  type: 'cellPalette';
  state: CellPaletteState;
  // If the user selects an event group from the Plan Picker while editing
  // an impact in a cell, we store it here. Once the user saves the cell, we
  // then use this value to create/update the impact with the correct event group.
  planPickerSelectedWithEventGroup?: WithEventGroup;

  // If the user selects an event group from the Plan Picker while editing
  // multiple delta impacts in a cell, we store it here. Once the user saves the cell,
  // we then use this field to create/update the impacts with the correct event groups.
  multiPlanPickerSelectedEventGroups?: Array<WithEventGroup | null>;

  // If the formula is edited, we store the updated multi delta impacts here
  updatedMultiDeltaImpacts?: NumberValue[];

  multiPlanPickerIndexSelectingPlan?: number | null;
};

export const isCellPalettePopover = (state: PopoverState): state is CellPalettePopoverState =>
  state?.type === 'cellPalette';

export enum ModalType {
  DeveloperMenu,
  DetailPane,
  Search,
  SmartPaste,
  CompareScenarios,
  Settings,
  CreateNavigationEntity,
  NewModel,
  RenameSubDrivers,
  PageTemplates,
  Cohort,
}

export const SETTINGS_TABS = ['general', 'users', 'api', 'dimensions'] as const;

type TabIndex = (typeof SETTINGS_TABS)[number];

export type SettingsModalState = { tabIndex: TabIndex; dimensionId?: DimensionId };

export type CopilotRequestStatus = 'ask-user' | 'pending' | 'success' | 'error';

export enum CopilotOperation {
  GroupDrivers,
  GroupEvents,
  PlanScenario,
}

type CopilotSession = {
  operation: CopilotOperation;
  cursor: string | null;
  requestStatus: CopilotRequestStatus;
  previousSelection: SelectionState | null;
};

export type FormulaEditorState = {
  initialOffset: { left: number; top: number };
  formulaEntityId: FormulaEntityTypedId;
  returnUrl: string;
  isPinned: boolean;
};

export type CellNavigationState = {
  hasNavigatedToGroup: boolean;
  cellRef: CellRef;
};

type CommonPageState = {
  compareScenariosModal: { showMerge: boolean };
  copiedCells: CopiedCells;
  copilotSession: CopilotSession | null;
  modalSelection: CellSelection | null;
  openModal: ModalType | null;
  popover: PopoverState;
  renameSubDriversModal: { newName?: string };
  settingsModal?: SettingsModalState;
  showObjectInspector?: boolean;
  formulaEditor?: FormulaEditorState;
  navigatingTo?: PageRef;
  navigatingToCell?: CellNavigationState;
  autoFocus?: AutoFocusItem;
};

type SubmodelPageState = CommonPageState & {
  type: 'submodelPage';
  id: SubmodelId;
  selection: CellSelection | null;
};

type BlocksPageState = CommonPageState & {
  type: 'blocksPage';
  id: BlocksPageId;
  selection: SelectionState | null;
};

export type DataSourcePageState = CommonPageState & {
  type: 'dataSourcePage';
  source?: ExtDriverSource;
  accountId?: string;
  navSubPage?:
    | {
        type: 'extDrivers';
        selectedTab?: IntegrationTab;
        selectedIntegrationQueryId?: IntegrationQueryId;
      }
    | {
        type: 'linkedObjects';
        specId: BusinessObjectSpecId;
      }
    | {
        type: 'integrationQuery';
        selectedTab?: IntegrationTab;
        selectedIntegrationQueryId?: IntegrationQueryId;
      };
  selection: SelectionState | null;
};

type TemplatesPageState = CommonPageState & {
  type: 'templatesPage';
  selection: SelectionState | null;
};

type PlansPageState = CommonPageState & {
  type: 'plansPage';
  selection: SelectionState | null;
  id?: BlocksPageId;
};

type UnlistedDriversPageState = CommonPageState & {
  type: 'unlistedDriversPage';
  id?: BlocksPageId;
  selection: CellSelection | null;
};

type EmptyPageState = CommonPageState & {
  type: 'empty';
  selection: null;
};

export type PageState =
  | SubmodelPageState
  | BlocksPageState
  | DataSourcePageState
  | TemplatesPageState
  | PlansPageState
  | UnlistedDriversPageState
  | EmptyPageState;

export const isSubmodelPage = (state: PageState): state is SubmodelPageState =>
  state.type === 'submodelPage';

export const isBlocksPage = (state: PageState): state is BlocksPageState =>
  state.type === 'blocksPage';

export const isDataSourcePage = (state: PageState): state is DataSourcePageState =>
  state.type === 'dataSourcePage';

export const isPlansPage = (state: PageState): state is TemplatesPageState =>
  state.type === 'plansPage';

export const isUnlistedDriversPage = (state: PageState): state is TemplatesPageState =>
  state.type === 'unlistedDriversPage';

export type PageRef =
  | {
      type: 'dataSourcePage';
      source?: ExtSource;
      accountId?: string;
    }
  | { type: 'submodelPage'; id?: SubmodelId }
  | { type: 'blocksPage'; id: BlocksPageId }
  | { type: 'templatesPage' }
  | { type: 'plansPage'; id?: BlocksPageId }
  | { type: 'unlistedDriversPage'; id?: BlocksPageId };

const isSameDataSourcePage = (
  state: PageState,
  source: ExtDriverSource | undefined,
): state is DataSourcePageState => state.type === 'dataSourcePage' && state.source === source;

const isSameBlocksPage = (state: PageState, blocksPageId: BlocksPageId): state is BlocksPageState =>
  state.type === 'blocksPage' && state.id === blocksPageId;

const isSameSubmodel = (state: PageState, submodelId?: SubmodelId): state is SubmodelPageState =>
  state.type === 'submodelPage' && nullSafeEqual(state.id, submodelId);

const initialState = { type: 'empty' } as PageState;

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setNavigatingTo(state, action: PayloadAction<PageRef>) {
      state.navigatingTo = action.payload;
    },
    propagateUnlistedDriversPageFromUrl(
      state,
      action: PayloadAction<{
        id?: BlocksPageId;
      }>,
    ) {
      const { id } = action.payload;
      const selection = state.selection?.type === 'cell' ? state.selection : null;
      return {
        type: 'unlistedDriversPage',
        id,
        copilotSession: null,
        autoFocus: undefined,
        compareScenariosModal: { showMerge: false },
        copiedCells: null,
        modalSelection: null,
        openModal: null,
        popover: null,
        renameSubDriversModal: {},
        selection,
      };
    },
    propagateSubModelPageFromUrl(state, action: PayloadAction<{ id: SubmodelId }>) {
      const { id } = action.payload;
      const selection = state.selection?.type === 'cell' ? state.selection : null;

      return {
        type: 'submodelPage',
        id,
        copilotSession: state.copilotSession ? state.copilotSession : null,
        autoFocus: isSameSubmodel(state, id) ? state.autoFocus : undefined,
        compareScenariosModal: { showMerge: false },
        copiedCells: isSameSubmodel(state, id) ? state.copiedCells : null,
        modalSelection: null,
        openModal: isSameSubmodel(state, id) ? state.openModal : null,
        popover: isSameSubmodel(state, id) ? state.popover : null,
        createNavigationEntityModal: { type: 'model', parent: null },
        selection,
        settingsModal: undefined,
        renameSubDriversModal: {},
        formulaEditor:
          state.formulaEditor != null ? { ...state.formulaEditor, isPinned: true } : undefined,
      };
    },
    propagateBlocksPageFromUrl(state, action: PayloadAction<BlocksPageId>) {
      const id = action.payload;
      const selection = state.selection?.type === 'cell' ? state.selection : null;

      return {
        type: 'blocksPage',
        id,
        copilotSession: state.copilotSession ? state.copilotSession : null,
        autoFocus: state.autoFocus,
        compareScenariosModal: { showMerge: false },
        copiedCells: isSameBlocksPage(state, id) ? state.copiedCells : null,
        modalSelection: null,
        openModal: isSameBlocksPage(state, id) ? state.openModal : null,
        popover: isSameBlocksPage(state, id) ? state.popover : null,
        selection,
        settingsModal: undefined,
        renameSubDriversModal: {},
        formulaEditor: state.formulaEditor,
        navigatingToCell: state.navigatingToCell,
      };
    },
    propagateTemplatesPageFromUrl(state) {
      return {
        type: 'templatesPage',
        copilotSession: null,
        autoFocus: undefined,
        copiedCells: null,
        compareScenariosModal: { showMerge: false },
        modalSelection: null,
        settingsModal: undefined,
        openModal: null,
        popover: null,
        selection: null,
        renameSubDriversModal: {},
        formulaEditor: state.formulaEditor,
      };
    },
    propagatePlansPageFromUrl(
      state,
      action: PayloadAction<{
        id?: BlocksPageId;
      }>,
    ) {
      const { id } = action.payload;

      return {
        type: 'plansPage',
        id,
        copilotSession: null,
        autoFocus: undefined,
        copiedCells: null,
        compareScenariosModal: { showMerge: false },
        modalSelection: null,
        settingsModal: undefined,
        openModal: null,
        popover: null,
        selection: null,
        renameSubDriversModal: {},
        formulaEditor: state.formulaEditor,
      };
    },
    propagateDataSourcePageFromUrl(
      state,
      action: PayloadAction<{
        source?: ExtDriverSource;
        accountId?: string;
        subPage?: DataSourcePageState['navSubPage'];
      }>,
    ) {
      const { accountId, source, subPage } = action.payload;

      return {
        type: 'dataSourcePage',
        accountId,
        copilotSession: state.copilotSession ? state.copilotSession : null,
        navSubPage:
          subPage == null && isSameDataSourcePage(state, source) ? state.navSubPage : subPage,
        compareScenariosModal: { showMerge: false },
        copiedCells: isSameDataSourcePage(state, source) ? state.copiedCells : null,
        modalSelection: null,
        openModal: isSameDataSourcePage(state, source) ? state.openModal : null,
        popover: isSameDataSourcePage(state, source) ? state.popover : null,
        selection: null,
        settingsModal: undefined,
        source,
        renameSubDriversModal: {},
        formulaEditor: state.formulaEditor,
      };
    },
    setNavSubPage(state, action: PayloadAction<DataSourcePageState['navSubPage']>) {
      if (!isDataSourcePage(state)) {
        return;
      }
      state.navSubPage = action.payload;
    },
    setAutoFocus(state, action: PayloadAction<AutoFocusItem>) {
      state.autoFocus = action.payload;
    },
    clearAutoFocus(state) {
      state.autoFocus = undefined;
    },
    toggleSearchModalOpen(state) {
      state.openModal = state.openModal === ModalType.Search ? null : ModalType.Search;
    },
    closeModal(state) {
      state.openModal = null;
      state.modalSelection = null;
      state.settingsModal = undefined;
    },
    openRenameSubDriverModal(state, action: PayloadAction<{ newName: string } | undefined>) {
      state.openModal = ModalType.RenameSubDrivers;
      if (action.payload == null) {
        state.renameSubDriversModal = {};
      } else {
        state.renameSubDriversModal = { newName: action.payload.newName };
      }
    },
    openCompareScenariosModal(state, action: PayloadAction<{ showMerge: boolean }>) {
      const { showMerge } = action.payload;
      state.openModal = ModalType.CompareScenarios;
      state.compareScenariosModal = { showMerge };
    },
    setSelectedEventsAndGroups(
      state,
      action: PayloadAction<{
        blockId: BlockId;
        active: PlanTimelineItemRef;
        refs: PlanTimelineItemRef[];
      }>,
    ) {
      state.selection = {
        type: 'eventsAndGroups',
        ...action.payload,
      };
    },
    selectPlanTimelineItemRef(
      state,
      action: PayloadAction<
        PlanTimelineItemRef & {
          blockId: BlockId;
        }
      >,
    ) {
      const { blockId, ...ref } = action.payload;
      state.selection = { type: 'eventsAndGroups', active: ref, refs: [ref], blockId };
    },
    setTracedCell(
      state,
      action: PayloadAction<{ blockId: BlockId | null; cellRef: DriverDataCellRef }>,
    ) {
      handleSelectSingleCell(state, { ...action.payload, isTracing: true });
    },
    selectSingleCell(
      state,
      action: PayloadAction<{
        blockId: BlockId | null;
        cellRef: CellRef;
        isTracing?: boolean;
      }>,
    ) {
      handleSelectSingleCell(state, action.payload);
    },
    setSelectedCells(state, action: PayloadAction<CellSelection | null>) {
      const cellSelection = action.payload;
      if (state.selection?.type === 'eventsAndGroups') {
        state.selection.cellSelection = cellSelection ?? undefined;
      } else if (state.openModal != null) {
        state.modalSelection = cellSelection;
      } else {
        state.selection = cellSelection;
      }

      if (cellSelection == null) {
        state.popover = null;
      } else if (!cellSelection.isDragging) {
        if (cellSelection.selectedCells.length > 1 && state.popover?.type !== 'cellPalette') {
          openCellPalettePopoverInternal(state);
        }
      }
    },
    clearSelection(state) {
      if (state.openModal != null) {
        state.modalSelection = null;
      } else {
        state.selection = null;
      }

      state.popover = null;
    },
    releaseCellSelectionDrag(state) {
      const prevailingSelection = getPrevailingSelection(state);

      if (prevailingSelection?.type !== 'cell') {
        return;
      }

      prevailingSelection.isDragging = false;

      if (prevailingSelection.selectedCells.length > 1) {
        openCellPalettePopoverInternal(state);
      }
    },
    setCopiedCells(state, action: PayloadAction<CopiedCells>) {
      state.copiedCells = action.payload;
    },
    clearCopiedCells(state) {
      state.copiedCells = null;
    },
    openCellPalettePopover(state) {
      openCellPalettePopoverInternal(state);
    },
    openPlanPickerPopover(state) {
      state.popover = {
        type: 'cellPalette',
        state: 'plans',

        planPickerSelectedWithEventGroup:
          state.popover?.type === 'cellPalette'
            ? state.popover.planPickerSelectedWithEventGroup
            : undefined,
        multiPlanPickerSelectedEventGroups:
          state.popover?.type === 'cellPalette'
            ? state.popover.multiPlanPickerSelectedEventGroups
            : undefined,
        updatedMultiDeltaImpacts:
          state.popover?.type === 'cellPalette'
            ? state.popover.updatedMultiDeltaImpacts
            : undefined,
      };
    },
    setCellPaletteState(state, action: PayloadAction<CellPaletteState>) {
      if (state.popover?.type !== 'cellPalette') {
        return;
      }

      state.popover.state = action.payload;
    },
    setPlanPickerSelectedEventGroupId(state, action: PayloadAction<WithEventGroup | undefined>) {
      if (state.popover?.type !== 'cellPalette') {
        return;
      }
      state.popover.planPickerSelectedWithEventGroup = action.payload;
    },
    setMultiPlanPickerSelectedEventGroups(
      state,
      action: PayloadAction<Array<WithEventGroup | null>>,
    ) {
      if (state.popover?.type !== 'cellPalette') {
        return;
      }
      state.popover.multiPlanPickerSelectedEventGroups = action.payload;
    },
    setMultiPlanPickerIndexSelectingPlan(state, action: PayloadAction<number | null>) {
      if (state.popover?.type !== 'cellPalette') {
        return;
      }
      state.popover.multiPlanPickerIndexSelectingPlan = action.payload;
    },
    setUpdatedMultiDeltaImpacts(state, action: PayloadAction<NumberValue[] | undefined>) {
      if (state.popover?.type !== 'cellPalette') {
        return;
      }
      state.popover.updatedMultiDeltaImpacts = action.payload;
    },
    setIsEditingActiveCell(state, action: PayloadAction<boolean>) {
      const isEditing = action.payload;

      if (state.modalSelection?.type === 'cell') {
        state.modalSelection.activeCell.isEditing = isEditing;
        return;
      }

      if (state.selection?.type !== 'cell') {
        return;
      }

      state.selection.activeCell.isEditing = isEditing;
    },
    closePopover(state) {
      state.popover = null;
    },
    openDeveloperMenu(state) {
      state.openModal = ModalType.DeveloperMenu;
    },
    openSettingsModal(
      state,
      action: PayloadAction<{ tabIndex: TabIndex; dimensionId?: DimensionId }>,
    ) {
      state.openModal = ModalType.Settings;
      state.settingsModal = {
        tabIndex: action.payload.tabIndex,
        dimensionId: action.payload.dimensionId,
      };
    },
    startCopilotSession(state, action: PayloadAction<CopilotOperation>) {
      state.copilotSession = {
        operation: action.payload,
        cursor: null,
        requestStatus: copilotRequiresInstruction(action.payload) ? 'ask-user' : 'pending',
        previousSelection: state.selection,
      };
    },
    endCopilotSession(state, action: PayloadAction<{ applyChanges: boolean }>) {
      if (state.copilotSession == null) {
        return;
      }

      if (!action.payload.applyChanges) {
        state.selection = state.copilotSession.previousSelection;
      }

      state.copilotSession = null;
    },
    setCopilotCursor(state, action: PayloadAction<string>) {
      if (state?.copilotSession == null) {
        return;
      }

      state.copilotSession.cursor = action.payload;
      state.copilotSession.requestStatus = 'success';
    },
    setCopilotRequestStatus(state, action: PayloadAction<CopilotRequestStatus>) {
      if (state?.copilotSession == null) {
        return;
      }

      state.copilotSession.requestStatus = action.payload;
    },
    openFormulaEditor(
      state,
      action: PayloadAction<{
        initialOffset: { left: number; top: number };
        formulaEntityId: FormulaEntityTypedId;
        returnUrl: string;
      }>,
    ) {
      state.formulaEditor = {
        initialOffset: action.payload.initialOffset,
        formulaEntityId: action.payload.formulaEntityId,
        returnUrl: action.payload.returnUrl,
        isPinned: false,
      };
    },
    closeFormulaEditor(state) {
      if (state == null || state.formulaEditor == null) {
        return;
      }

      routeTo(state.formulaEditor.returnUrl, { openInNewTab: false });
      state.formulaEditor = undefined;
    },
    pinFormulaEditor(state) {
      if (state == null || state.formulaEditor == null) {
        return;
      }

      state.formulaEditor.isPinned = true;
    },
    navigatingToCell(state, action: PayloadAction<CellNavigationState>) {
      if (state == null) {
        return;
      }
      state.navigatingToCell = action.payload;
    },
    updateCellNavigationState(state, action: PayloadAction<{ hasNavigatedToGroup: boolean }>) {
      if (state == null || state.navigatingToCell == null) {
        return;
      }
      state.navigatingToCell = {
        ...state.navigatingToCell,
        ...action.payload,
      };
    },
    clearNavigatingToCell(state) {
      if (state == null) {
        return;
      }
      state.navigatingToCell = undefined;
    },
    validatePageIntegrationData(state, action: PayloadAction<{ linkedAccounts: LinkedAccount[] }>) {
      if (state == null || state.type !== 'dataSourcePage' || state.accountId == null) {
        return;
      }
      if (action.payload.linkedAccounts.map((l) => l.id).includes(state.accountId)) {
        return;
      }
      state.accountId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeWithSnapshot, (state) => {
      if (state == null) {
        return;
      }
      state.selection = null;
      state.modalSelection = null;
    });

    builder.addCase(applyMutationLocally_INTERNAL, (state, action) => {
      // TODO: it's kinda sucky that we have to access the mutation within.
      const { newBlocks } = action.payload.mutationBatch.mutation;

      if (state == null) {
        return;
      }

      if (
        !action.payload.isRemote &&
        isBlocksPage(state) &&
        newBlocks != null &&
        newBlocks.length === 1 &&
        newBlocks[0].pageId === state.id
      ) {
        state.autoFocus = { type: 'block', id: newBlocks[0].id };
      } else {
        state.autoFocus = undefined;
      }

      state.copiedCells = null;
    });
  },
});

function copilotRequiresInstruction(operation: CopilotOperation) {
  return operation === CopilotOperation.PlanScenario;
}

export function handleSelectSingleCell(
  state: Draft<PageState>,
  payload: {
    blockId: BlockId | null;
    cellRef: CellRef;
    isTracing?: boolean;
  },
) {
  if (state == null) {
    return;
  }
  const { cellRef, blockId, isTracing } = payload;
  const cellSelection: CellSelection = {
    type: 'cell',
    blockId,
    activeCell: cellRef,
    selectedCells: [cellRef],
    isTracing,
  };

  const isSameSelection = deepEqual(state.selection, cellSelection);
  if (state.selection?.type === 'eventsAndGroups' && state.selection.blockId === blockId) {
    state.selection.cellSelection = cellSelection;
    return;
  } else if (state.openModal != null) {
    state.modalSelection = cellSelection;
  } else {
    state.selection = cellSelection;
  }

  if (!isSameSelection) {
    state.popover = null;
  }
}

function getPrevailingSelection(state: PageState): SelectionState {
  return state.modalSelection ?? state.selection;
}

export const {
  setNavigatingTo,
  clearAutoFocus,
  clearCopiedCells,
  clearSelection,
  closeFormulaEditor,
  closeModal,
  closePopover,
  endCopilotSession,
  openCompareScenariosModal,
  openDeveloperMenu,
  openCellPalettePopover,
  openPlanPickerPopover,
  setCellPaletteState,
  setPlanPickerSelectedEventGroupId,
  setMultiPlanPickerSelectedEventGroups,
  setMultiPlanPickerIndexSelectingPlan,
  setUpdatedMultiDeltaImpacts,
  setIsEditingActiveCell,
  openFormulaEditor,
  openRenameSubDriverModal,
  openSettingsModal,
  pinFormulaEditor,
  propagateUnlistedDriversPageFromUrl,
  propagateBlocksPageFromUrl,
  propagateDataSourcePageFromUrl,
  propagateSubModelPageFromUrl,
  propagateTemplatesPageFromUrl,
  propagatePlansPageFromUrl,
  selectPlanTimelineItemRef,
  selectSingleCell,
  setAutoFocus,
  setCopiedCells,
  validatePageIntegrationData,
  setCopilotCursor,
  setCopilotRequestStatus,
  setNavSubPage,
  setSelectedCells,
  setSelectedEventsAndGroups,
  startCopilotSession,
  toggleSearchModalOpen,
  navigatingToCell,
  clearNavigatingToCell,
  updateCellNavigationState,
  releaseCellSelectionDrag,
} = pageSlice.actions;

export default pageSlice.reducer;

function openCellPalettePopoverInternal(state: Draft<PageState>) {
  const prevailingSelection = getPrevailingSelection(state);

  if (prevailingSelection?.type !== 'cell') {
    return;
  }

  const { selectedCells } = prevailingSelection;

  if (!selectedCells.every(cellRefSupportsCellPalette)) {
    return;
  }

  state.popover = {
    type: 'cellPalette',
    state: 'default',

    // Preserve the selectedEventGroupId if there is one
    planPickerSelectedWithEventGroup:
      state.popover?.type === 'cellPalette'
        ? state.popover.planPickerSelectedWithEventGroup
        : undefined,

    multiPlanPickerSelectedEventGroups:
      state.popover?.type === 'cellPalette'
        ? state.popover.multiPlanPickerSelectedEventGroups
        : undefined,

    updatedMultiDeltaImpacts:
      state.popover?.type === 'cellPalette' ? state.popover.updatedMultiDeltaImpacts : undefined,
  };
}
