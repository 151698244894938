import { useMemo } from 'react';

import DecimalPlacesSelectMenu from 'components/DecimalPlacesSelectMenu/DecimalPlacesSelectMenu';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import DecimalIcon from 'vectors/Decimal';

export const useDecimalPlacesSelectMenuItem = (
  currentDecimalPlaces: number | null,
  onChangeDecimalPlaces: (decimalPlaces: number | null) => void,
): SelectItem => {
  const item: SelectItem = useMemo(
    () => ({
      id: 'decimalPlaces',
      icon: <DecimalIcon />,
      submenu: () => (
        <DecimalPlacesSelectMenu onSelect={onChangeDecimalPlaces} selected={currentDecimalPlaces} />
      ),
      meta: currentDecimalPlaces != null ? String(currentDecimalPlaces) : 'Default',
      name: 'Precision',
      sectionId: 'format-options',
    }),
    [onChangeDecimalPlaces, currentDecimalPlaces],
  );

  return item;
};
