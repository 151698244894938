import {
  AccessResourceType,
  AccessControlEntry as GQLAccessControlEntry,
  AccessorEntity as GQLAccessorEntity,
  AccessResource as GQLAccessResource,
} from 'generated/graphql';

export type AccessResourceId = GQLAccessResource['id'];
export type AccessResource = Pick<
  GQLAccessResource,
  'id' | 'parentId' | 'orgId' | 'shouldInherit' | 'type'
> & {
  resourceId: AccessResourceId;
  accessControlList: AccessControlEntry[];
};
export type AccessorEntity = Pick<GQLAccessorEntity, 'id' | 'type'>;

export type AccessControlEntry = Pick<
  GQLAccessControlEntry,
  'accessRule' | 'grantedAt' | 'grantedBy'
> & {
  entityWithAccess: AccessorEntity;
};

export type PageAccessResource = AccessResource & { type: AccessResourceType.Page };
export type LayerAccessResource = AccessResource & { type: AccessResourceType.Layer };

export function isPageAccessResource(ar: AccessResource): ar is PageAccessResource {
  return ar.type === AccessResourceType.Page;
}

export function isLayerAccessResource(ar: AccessResource): ar is LayerAccessResource {
  return ar.type === AccessResourceType.Layer;
}
