import { DATE_DIFF_UNIT_TO_DURATION_UNIT } from 'helpers/dates';
import { EXPLICIT_NULL_DISPLAY } from 'reduxStore/models/value';
import { MathOperator } from 'types/formula';

export const LATEST_FORMULA_EVALUATION_MONTH_KEY = '2040-01';

export const FORMULA_EDITOR_CLASS = 'formulaEditor';
export const FORMULA_REFERENCE_CLASS = 'formulaReference';
export const FORMULA_DROPDOWN_CLASS = 'formulaDropdown';

export const MATH_OPERATOR_TO_LABEL: Record<MathOperator, [string, string]> = {
  [MathOperator.Round]: ['round(', ')'],
  [MathOperator.RoundDown]: ['roundDown(', ')'],
  [MathOperator.Sum]: ['sum(', ')'],
  [MathOperator.Avg]: ['avg(', ')'],
  [MathOperator.Min]: ['min(', ')'],
  [MathOperator.Max]: ['max(', ')'],
  [MathOperator.Count]: ['count(', ')'],
  [MathOperator.SumProduct]: ['sumProduct(', ', )'],
  [MathOperator.EndOfMonth]: ['endOfMonth(', ')'],
  [MathOperator.StartOfMonth]: ['startOfMonth(', ')'],
  [MathOperator.AsDays]: ['asDays(', ')'],
  [MathOperator.AsWeeks]: ['asWeeks(', ')'],
  [MathOperator.AsMonths]: ['asMonths(', ')'],
  [MathOperator.AsYears]: ['asYears(', ')'],
  [MathOperator.ThisMonth]: ['thisMonth()', ''],
  [MathOperator.DaysInMonth]: ['daysInMonth()', ''],
  [MathOperator.DateDiff]: ['dateDiff(', ', , )'],
  [MathOperator.NetWorkDays]: ['netWorkDays(', ', )'],
  [MathOperator.If]: ['if(', ', , )'],
  [MathOperator.IfError]: ['ifError(', ', )'],
  [MathOperator.Coalesce]: ['coalesce(', ', )'],
  [MathOperator.And]: ['AND ', ''],
  [MathOperator.Or]: ['OR ', ''],
  [MathOperator.Equals]: ['==', ''],
  [MathOperator.NotEquals]: ['!=', ''],
  [MathOperator.Greater]: ['>', ''],
  [MathOperator.Less]: ['<', ''],
  [MathOperator.GreaterOrEqual]: ['>=', ''],
  [MathOperator.LessOrEqual]: ['<=', ''],
  [MathOperator.Subtract]: ['-', ''],
  [MathOperator.Add]: ['+', ''],
  [MathOperator.Multiply]: ['*', ''],
  [MathOperator.Divide]: ['/', ''],
  [MathOperator.Pow]: ['^', ''],
  [MathOperator.LeftParen]: ['(', ''],
  [MathOperator.RightParen]: [')', ''],
  [MathOperator.Null]: ['NULL', ''],
};

export const PREVENT_FORMULA_QUERY_ARGS: Partial<Record<MathOperator, number[]>> = {
  // The optional 'weekend' and 'holiday' arguments are strings so the queries
  // would just get in the way.
  [MathOperator.NetWorkDays]: [2, 3],
};

type MathOperatorSelectItem = {
  operator: MathOperator;
  name: string;
  help: {
    title: string;
    subtitle: string;
  };
};

export const matchesMathOperator = (value: string, operator: MathOperator): boolean => {
  const upcased = value.toUpperCase();
  return upcased === operator;
};

export const getFirstMatchingMathOperator = (value: string): MathOperator | undefined => {
  const upcased = value.toUpperCase();
  return Object.values(MathOperator).find((operator) => upcased === operator);
};

export const MATH_OPERATOR_SELECT_ITEMS: MathOperatorSelectItem[] = [
  {
    operator: MathOperator.Round,
    name: 'round()',
    help: {
      title: 'round(value, [places])',
      subtitle:
        'Rounds a number to a certain number of decimal places according to standard rules.',
    },
  },
  {
    operator: MathOperator.RoundDown,
    name: 'roundDown()',
    help: {
      title: 'roundDown(value)',
      subtitle:
        'Rounds a number to a certain number of decimal places, always rounding down to the next valid increment.',
    },
  },
  {
    operator: MathOperator.Sum,
    name: 'sum()',
    help: {
      title: 'sum(reference)',
      subtitle: 'Returns the sum of a database field or dimensional driver reference.',
    },
  },
  {
    operator: MathOperator.SumProduct,
    name: 'sumProduct()',
    help: {
      title: 'sumProduct(reference1, reference2)',
      subtitle:
        'Calculates the sum of the products of corresponding entries in two database field or dimensional driver reference.',
    },
  },
  {
    operator: MathOperator.Count,
    name: 'count()',
    help: {
      title: 'count(reference)',
      subtitle: 'Returns the number of values in a database field or dimensional driver reference.',
    },
  },
  {
    operator: MathOperator.Avg,
    name: 'avg()',
    help: {
      title: 'avg(reference)',
      subtitle:
        'Returns the numerical average value in a database field or dimensional driver reference.',
    },
  },
  {
    operator: MathOperator.Min,
    name: 'min()',
    help: {
      title: 'min(reference...)',
      subtitle:
        'Returns the numerical minimum value in a database field, dimensional driver reference, or set of numeric evaluations.',
    },
  },
  {
    operator: MathOperator.Max,
    name: 'max()',
    help: {
      title: 'max(reference...)',
      subtitle:
        'Returns the numerical maximum value in a database field, dimensional driver reference, or set of numeric evaluations.',
    },
  },

  {
    operator: MathOperator.If,
    name: 'if()',
    help: {
      title: 'IF(logical_expression, value_if_true, value_if_false)',
      subtitle: 'Returns one value if a logical expression is TRUE and another if it is FALSE.',
    },
  },
  {
    operator: MathOperator.IfError,
    name: 'ifError()',
    help: {
      title: 'ifError(value, value_if_error)',
      subtitle: `Returns value if it does not evaluate to an error and value_if_error otherwise.
        The following error types are handled: NULL, DIV/0`,
    },
  },
  {
    operator: MathOperator.Coalesce,
    name: 'coalesce()',
    help: {
      title: 'coalesce(val1, val2, ..., val_n)',
      subtitle: `Returns the first non-null value.`,
    },
  },
  {
    operator: MathOperator.StartOfMonth,
    name: 'startOfMonth()',
    help: {
      title: 'startOfMonth(date_expression)',
      subtitle: 'Returns the first day of the month for the date expression.',
    },
  },
  {
    operator: MathOperator.EndOfMonth,
    name: 'endOfMonth()',
    help: {
      title: 'endOfMonth(date_expression)',
      subtitle: 'Returns the last day of the month for the date expression.',
    },
  },
  {
    operator: MathOperator.AsDays,
    name: 'asDays()',
    help: {
      title: 'asDays(value)',
      subtitle: 'Treats a value as number of days for date math.',
    },
  },
  {
    operator: MathOperator.AsWeeks,
    name: 'asWeeks()',
    help: {
      title: 'asWeeks(value)',
      subtitle: 'Treats a value as number of weeks for date math.',
    },
  },
  {
    operator: MathOperator.AsMonths,
    name: 'asMonths()',
    help: {
      title: 'asMonths(value)',
      subtitle: 'Treats a value as number of months for date math.',
    },
  },
  {
    operator: MathOperator.AsYears,
    name: 'asYears()',
    help: {
      title: 'asYears(value)',
      subtitle: 'Treats a value as number of years for date math.',
    },
  },
  {
    operator: MathOperator.ThisMonth,
    name: 'thisMonth()',
    help: {
      title: 'thisMonth()',
      subtitle: 'Returns the first day of the month being evaluated.',
    },
  },
  {
    operator: MathOperator.DaysInMonth,
    name: 'daysInMonth()',
    help: {
      title: 'daysInMonth()',
      subtitle: 'Returns the number of days in the month being evaluated.',
    },
  },
  {
    operator: MathOperator.DateDiff,
    name: 'dateDiff()',
    help: {
      title: 'dateDiff(start_date, end_date, unit)',
      subtitle: `Returns the number of whole "units" between the start_date and end_date. Valid units are ${Object.keys(
        DATE_DIFF_UNIT_TO_DURATION_UNIT,
      )
        .map((unit) => `"${unit}"`)
        .join(', ')} (${Object.values(DATE_DIFF_UNIT_TO_DURATION_UNIT)
        .map((v) => v)
        .join(', ')}).`,
    },
  },
  {
    operator: MathOperator.NetWorkDays,
    name: 'netWorkDays()',
    help: {
      title: 'netWorkDays(start_date, end_date, [weekends], [holidays])',
      subtitle:
        'Returns the number of net work days between start_date (inclusive) and end_date (exclusive) exclusing holidays and weekends. ' +
        'The optional weekend parameter expects a string of 7 characters, each representing a day of the week. 1 means the day is a weekend, 0 means it is a work day. ' +
        "The first character represents Monday (default is '0000077'). " +
        'The holiday optional parameter allows you to specify a list of holidays as a string of comma separated dates in the format YYYY-MM-DD (default is empty).',
    },
  },
];

export const SPECIAL_MATH_SELECT_ITEMS: MathOperatorSelectItem[] = [
  {
    operator: MathOperator.Null,
    name: EXPLICIT_NULL_DISPLAY,
    help: {
      title: EXPLICIT_NULL_DISPLAY,
      subtitle: 'Represents the NULL value',
    },
  },
];
