import { useEffect } from 'react';

import { preventEventDefault } from 'helpers/browserEvent';

type Args = {
  isAdding?: boolean;
  onEnter?: () => void;
  isSelected: boolean;
};

export default function useAddDriverRow({ isAdding, isSelected, onEnter }: Args) {
  useEffect(() => {
    if (onEnter == null) {
      return () => {};
    }

    const onKeyDown = (ev: KeyboardEvent) => {
      if (!isAdding && isSelected && ev.key === 'Enter') {
        preventEventDefault(ev);
        onEnter();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onEnter, isAdding, isSelected]);
}
