import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { extractEmoji } from 'helpers/emoji';
import useAppSelector from 'hooks/useAppSelector';
import { LayerId } from 'reduxStore/models/layers';
import { layerNameByIdSelector } from 'selectors/layerSelector';
import {
  nonBaselineLayerIdsToCompareSelector,
  scenarioComparisonBaselineLayerIdSelector,
} from 'selectors/scenarioComparisonSelector';
import Branches from 'vectors/Branches';

const IdenticalScenarios: React.FC = () => {
  const baselineLayerId = useAppSelector(scenarioComparisonBaselineLayerIdSelector);
  const nonBaselineLayerIds = useAppSelector(nonBaselineLayerIdsToCompareSelector);
  const firstComparisonLayerId =
    nonBaselineLayerIds.length > 0 ? nonBaselineLayerIds[0] : undefined;
  return (
    <Flex
      w="full"
      flexDir="column"
      rowGap={4}
      justify="center"
      align="center"
      py={4}
      pr={8}
      flexGrow={1}
    >
      <Flex
        color="gray.600"
        bgColor="gray.200"
        p={1}
        align="center"
        justify="center"
        borderRadius="md"
      >
        <Branches boxSize={5} />
      </Flex>
      <Flex flexDir="column" rowGap={2} justify="center" align="center">
        <Text fontSize="lg" fontWeight="bold">
          Nothing to compare
        </Text>
        <Text fontSize="xxs" fontWeight="medium" color="gray.500">
          {firstComparisonLayerId == null ? (
            'Add a scenario to compare'
          ) : (
            <>
              <LayerName layerId={baselineLayerId} /> and{' '}
              <LayerName layerId={firstComparisonLayerId} /> are identical.
            </>
          )}
        </Text>
      </Flex>
    </Flex>
  );
};

const LayerName: React.FC<{ layerId: LayerId }> = ({ layerId }) => {
  const name = useAppSelector((state) => layerNameByIdSelector(state)[layerId]);
  const [_, displayName] = extractEmoji(name);

  return (
    <Text as="span" fontWeight="semibold">
      {displayName}
    </Text>
  );
};

export default IdenticalScenarios;
