import { useToken } from '@chakra-ui/react';
import { Group } from '@visx/group';
import { Circle } from '@visx/shape';
import React from 'react';

interface Props {
  x: number;
  y: number;
  color: string;
}

const CursorPoint: React.FC<Props> = ({ x, y, color }) => {
  const [resolvedColor] = useToken('colors', [color]);
  return (
    <Group left={x} top={y}>
      <Circle r={4} fill={resolvedColor} stroke="white" strokeWidth={2} pointerEvents="none" />
    </Group>
  );
};

export default React.memo(CursorPoint);
