import { Box } from '@chakra-ui/react';
import fastdom from 'fastdom';
import debounce from 'lodash/debounce';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { StickyContext } from 'components/StickyHeader/StickyContext';
import { findInitialOffsetTop } from 'components/VirtualizedList/findInitialOffset';
import { findNearestHorizontalScrollParent } from 'helpers/scroll';
import { toPxString } from 'helpers/styles';

interface Props {
  stickyTop: number;
  zIndex?: number;
  children: JSX.Element;
  backgroundColor?: string;
  width?: string;
}

const DEBOUNCE_MS = 1000 / 60;
const StickyHeader: React.FC<Props> = ({
  children,
  zIndex,
  stickyTop,
  backgroundColor = 'white',
  width,
}) => {
  const stickyElementRef = useRef<HTMLDivElement>(null);
  const stickyElementReplacementRef = useRef<HTMLDivElement | null>(null);
  const nearestHorizontalScrollParentRef = useRef<HTMLElement | null>(null);
  const horizontalScrollMeasureDisposerFn = useRef<(() => void) | null>(null);
  const setStickyMeasureDisposeFnRef = useRef<(() => void) | null>(null);
  const horizontalScrollMutateDisposerFn = useRef<(() => void) | null>(null);
  const resetStickyMutateDisposeFnRef = useRef<(() => void) | null>(null);
  const setStickyMutateDisposeFnRef = useRef<(() => void) | null>(null);
  const setOffsetTopMutateDisposeFnRef = useRef<(() => void) | null>(null);
  const fixedDivRef = useRef<HTMLDivElement | null>(null);
  const [stickyElementOffsetTopFromScrollBoundary, setStickyElementOffsetTopFromScrollBoundary] =
    useState<number>(-1);
  const [stickyParentOffsetTopFromScrollBoundary, setStickyParentOffsetTopFromScrollBoundary] =
    useState<number>(-1);
  const [scrollTop, setScrollTop] = useState<number>(-1); //scrollTop is non-negative so -1 is used to indicate that it hasn't been set yet
  const [stickyParentOffsetHeight, setStickyParentOffsetHeight] = useState<number>(0);
  const [stickyElementOffsetHeight, setStickyElementOffsetHeight] = useState<number>(0);
  const { subscribe, unsubscribe, verticalScrollingTargetRef } = useContext(StickyContext);

  const shouldBeSticky = useMemo((): boolean | undefined => {
    if (scrollTop === -1 || stickyElementOffsetTopFromScrollBoundary === -1) {
      return undefined; // we don't have enough information to determine if the sticky header should be sticky yet
    }
    //if you've scrolled offset top from the boundary, then the sticky header has intersected the top of the scrolling container.
    //stickyTop here because sometimes we want the header to stick at stickyTop from the top boundary of the scrolling container.
    return scrollTop >= stickyElementOffsetTopFromScrollBoundary - stickyTop;
  }, [scrollTop, stickyElementOffsetTopFromScrollBoundary, stickyTop]);

  const shouldHideStickyElement = useMemo((): boolean | undefined => {
    if (shouldBeSticky == null || stickyParentOffsetTopFromScrollBoundary === -1) {
      return undefined; // we don't have enough information to conclude
    }
    /**
     * There's a point, nearing the bottom of the sticky parent, at which the sticky header should disappear
     * because it covers the content of the sticky parent because of its height.
     * This point is reached when you've scrolled offsetTop + (stickyParentHeight - stickyElementHeight) from the boundary.
     * Minus stickyTop here to account for the custom offset from the scroll boundary.
     */
    return (
      shouldBeSticky &&
      scrollTop >=
        stickyParentOffsetTopFromScrollBoundary +
          stickyParentOffsetHeight -
          stickyElementOffsetHeight -
          stickyTop
    );
  }, [
    shouldBeSticky,
    scrollTop,
    stickyParentOffsetTopFromScrollBoundary,
    stickyParentOffsetHeight,
    stickyElementOffsetHeight,
    stickyTop,
  ]);

  const setStickyStyles = useCallback(() => {
    const stickyElement = stickyElementRef.current;
    const stickyElementReplacement = stickyElementReplacementRef.current;
    const stickyParentElement = stickyElementReplacement?.parentElement; //use replacement instead because when sticky element becomes fixed, its parent changes
    const fixedDivElement = fixedDivRef.current;
    if (
      stickyElement == null ||
      stickyParentElement == null ||
      stickyElementReplacement == null ||
      fixedDivElement == null
    ) {
      return;
    }
    if (setStickyMeasureDisposeFnRef.current != null) {
      fastdom.clear(setStickyMeasureDisposeFnRef.current); //clear a previously queued job in the 'measure' queue.
    }
    if (setStickyMutateDisposeFnRef.current != null) {
      fastdom.clear(setStickyMutateDisposeFnRef.current); //clear a previously queued job in the 'mutate' queue.
    }
    setStickyMeasureDisposeFnRef.current = fastdom.measure(() => {
      const stickyParentScrollWidth = stickyParentElement.scrollWidth;
      const horizontalScrollingParentWidth = nearestHorizontalScrollParentRef.current?.clientWidth;
      const horizontalScrollingParentScrollLeft =
        nearestHorizontalScrollParentRef.current?.scrollLeft ?? 0;
      const horizontalParentScrollWidth =
        nearestHorizontalScrollParentRef.current?.scrollWidth ?? 0;
      const extraPadding = Math.max(0, horizontalParentScrollWidth - stickyParentScrollWidth);
      setStickyMutateDisposeFnRef.current = fastdom.mutate(() => {
        if (horizontalScrollingParentWidth != null) {
          fixedDivElement.style.width = `${horizontalScrollingParentWidth}px`;
        }
        fixedDivElement.style.overflowX = 'hidden'; //without this, horizontal stickiness is broken in child elements
        //add extra right padding to sticky header if there is a difference in scroll width between the horizontal scrolling parent and the sticky parent
        fixedDivElement.style.paddingRight = `${extraPadding}px`;
        fixedDivElement.appendChild(stickyElement); // insert the sticky element into the fixed div to simulate the intended sticky behavior
        stickyElementReplacement.style.height = `${stickyElementOffsetHeight}px`; //set height of the replacement to use the most recent value of the sticky element's height because the sticky header's height can change
        //since setting the position of the sticky element to fixed removes it from the layout, we need the replacement element to maintain the height of the parent element so we set the display property to '' to add it back to the layout.
        fixedDivElement.scrollLeft = horizontalScrollingParentScrollLeft;
      });
    });
  }, [stickyElementOffsetHeight]);

  const resetStickyStyles = useCallback(() => {
    const stickyElement = stickyElementRef.current;
    const stickyElementReplacement = stickyElementReplacementRef.current;
    const stickyParentElement = stickyElementReplacement?.parentElement; //use replacement instead because when sticky element becomes fixed, its parent changes
    const fixedDivElement = fixedDivRef.current;
    if (
      stickyElement == null ||
      stickyParentElement == null ||
      stickyElementReplacement == null ||
      fixedDivElement == null
    ) {
      return;
    }
    if (resetStickyMutateDisposeFnRef.current != null) {
      fastdom.clear(resetStickyMutateDisposeFnRef.current); //clear a previously queued job in the 'mutate' queue.
    }
    resetStickyMutateDisposeFnRef.current = fastdom.mutate(() => {
      fixedDivElement.style.width = '';
      fixedDivElement.style.overflowX = '';
      fixedDivElement.style.position = '';
      fixedDivElement.style.top = '';
      fixedDivElement.style.paddingRight = '';
      stickyElementReplacement.style.height = '';
      stickyElementReplacement.after(stickyElement); //return the sticky element to its original position in the dom
    });
  }, []);

  const setStickyHeaderOpacity = useCallback((hidden: boolean) => {
    const fixedElement = fixedDivRef.current;
    if (fixedElement == null) {
      return;
    }
    if (hidden) {
      fixedElement.style.opacity = '0';
      fixedElement.style.pointerEvents = 'none';
    } else {
      fixedElement.style.opacity = '1';
      fixedElement.style.pointerEvents = 'auto';
    }
  }, []);

  //use this to sync scroll position of fixed sticky header with scroll position of horizontal scrolling parent
  const handleHorizontalParentScroll = useCallback(() => {
    const fixedDivElement = fixedDivRef.current;
    const nearestHorizontalScrollParentElement = nearestHorizontalScrollParentRef.current;
    if (fixedDivElement == null || nearestHorizontalScrollParentElement == null) {
      return;
    }
    if (horizontalScrollMeasureDisposerFn.current != null) {
      fastdom.clear(horizontalScrollMeasureDisposerFn.current); //clear a previously queued job in the 'measure' queue.
    }
    if (horizontalScrollMutateDisposerFn.current != null) {
      fastdom.clear(horizontalScrollMutateDisposerFn.current); //clear a previously queued job in the 'mutate' queue.
    }
    horizontalScrollMeasureDisposerFn.current = fastdom.measure(() => {
      const scrollLeft = nearestHorizontalScrollParentElement.scrollLeft;
      horizontalScrollMutateDisposerFn.current = fastdom.mutate(() => {
        fixedDivElement.scrollLeft = scrollLeft;
      });
    });
  }, []);

  const attachHorizontalScrollListenerToNearestHorizontalScrollParent = useCallback(() => {
    nearestHorizontalScrollParentRef.current?.addEventListener(
      'scroll',
      handleHorizontalParentScroll,
      { passive: true },
    );
  }, [handleHorizontalParentScroll]);

  const unattachHorizontalScrollListenerToNearestHorizontalScrollParent = useCallback(() => {
    nearestHorizontalScrollParentRef?.current?.removeEventListener(
      'scroll',
      handleHorizontalParentScroll,
    );
  }, [handleHorizontalParentScroll]);

  const getNearestHorizontalScrollParent = useCallback(() => {
    const stickyParentElement = stickyElementReplacementRef.current?.parentElement; //use replacement instead because when sticky element becomes fixed, its parent changes
    if (stickyParentElement == null) {
      return;
    }
    nearestHorizontalScrollParentRef.current =
      findNearestHorizontalScrollParent(stickyParentElement);
  }, []);

  const handleVerticalParentScroll = useCallback(() => {
    const scrollTarget = verticalScrollingTargetRef?.current;
    if (scrollTarget != null) {
      setScrollTop(scrollTarget.scrollTop);
    }
  }, [verticalScrollingTargetRef]);

  const setInitialOffsetTopFromScrollBoundaryAndScrollTop = useCallback(() => {
    const scroller = verticalScrollingTargetRef?.current;
    const stickyElement = stickyElementReplacementRef.current; //use replacement instead because when sticky element becomes fixed, its parent changes and offset calculation become inaccurate
    const stickyParentElement = stickyElementReplacementRef.current?.parentElement; //use replacement instead because when sticky element becomes fixed, its parent changes
    if (scroller != null && stickyElement != null && stickyParentElement != null) {
      const offsetTopStickyParentElement = findInitialOffsetTop(scroller, stickyParentElement);
      const offsetTopStickyElement: number = findInitialOffsetTop(scroller, stickyElement);
      setOffsetTopMutateDisposeFnRef.current = fastdom.mutate(() => {
        setStickyElementOffsetTopFromScrollBoundary(offsetTopStickyElement);
        setStickyParentOffsetTopFromScrollBoundary(offsetTopStickyParentElement);
      });
    }
  }, [verticalScrollingTargetRef]);

  useEffect(() => {
    const scroller = verticalScrollingTargetRef?.current;
    if (scroller != null) {
      setInitialOffsetTopFromScrollBoundaryAndScrollTop();
      getNearestHorizontalScrollParent();
      subscribe(handleVerticalParentScroll);
      setScrollTop(scroller.scrollTop);
    }
    return () => {
      unsubscribe(handleVerticalParentScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verticalScrollingTargetRef?.current]);

  /**
   * Observe dom tree for mutations and also size changes as offset top calculations can change if elements
   * above the sticky header change size or new nodes are added/removed above the sticky element
   */
  useEffect(() => {
    const observeCallback = debounce(() => {
      setInitialOffsetTopFromScrollBoundaryAndScrollTop();
    }, DEBOUNCE_MS);

    const resizeObserver: ResizeObserver = new ResizeObserver(observeCallback);
    const mutationObserver: MutationObserver = new MutationObserver(observeCallback);

    const stickyElement = stickyElementReplacementRef.current; //use replacement instead because when sticky element becomes fixed
    const scroller = verticalScrollingTargetRef?.current;
    if (scroller != null && stickyElement != null) {
      let elm: HTMLElement | null = stickyElement;
      //for a given element, observe size changes for siblings above it within a parent element
      //guaranteed to terminate since we will eventually reach the scroller element
      while (elm != null && !elm.isSameNode(scroller)) {
        if (elm.parentElement != null) {
          const childrenArr = Array.from(elm.parentElement.children);
          const idx = childrenArr.findIndex((child) => child.isSameNode(elm));
          for (let i = 0; i < idx; i++) {
            resizeObserver.observe(childrenArr[i]);
          }
        }
        elm = elm.parentElement;
      }
      mutationObserver.observe(scroller, {
        childList: true,
        subtree: true,
      });
    }
    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verticalScrollingTargetRef?.current]);

  //we want this change to run before the browser repaints the screen.
  useLayoutEffect(() => {
    if (shouldBeSticky != null) {
      if (shouldBeSticky) {
        attachHorizontalScrollListenerToNearestHorizontalScrollParent();
        setStickyStyles();
      } else {
        unattachHorizontalScrollListenerToNearestHorizontalScrollParent();
        resetStickyStyles();
      }
      return () => {
        unattachHorizontalScrollListenerToNearestHorizontalScrollParent();
      };
    }
    return () => {};
  }, [
    attachHorizontalScrollListenerToNearestHorizontalScrollParent,
    handleHorizontalParentScroll,
    resetStickyStyles,
    setStickyStyles,
    shouldBeSticky,
    unattachHorizontalScrollListenerToNearestHorizontalScrollParent,
  ]);

  //we want this change to run before the browser repaints the screen since we are modifying the dom
  useLayoutEffect(() => {
    // only when shouldHideStickyElement is not in an indeterminate state
    if (shouldHideStickyElement != null) {
      setStickyHeaderOpacity(shouldHideStickyElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHideStickyElement]);

  //we want this change to run before the browser repaints the screen since we are modifying the dom
  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver | undefined;
    const stickyElement = stickyElementRef.current;
    const stickyParentElement = stickyElementReplacementRef.current?.parentElement; //use replacement instead because when sticky element becomes fixed, its parent changes
    if (stickyElement != null && stickyParentElement != null) {
      const elementHeight = stickyElement.offsetHeight;
      const parentHeight = stickyParentElement.offsetHeight;

      const initializeHeights = () => {
        setStickyElementOffsetHeight(elementHeight);
        setStickyParentOffsetHeight(parentHeight);
      };

      const setObservers = () => {
        const observeCallback = debounce((entries: ResizeObserverEntry[]) => {
          for (const entry of entries) {
            if (entry.target.isSameNode(stickyElement)) {
              setStickyElementOffsetHeight(entry.contentRect.height);
            } else if (entry.target.isSameNode(stickyParentElement)) {
              setStickyParentOffsetHeight(entry.contentRect.height);
            }
          }
        }, DEBOUNCE_MS);
        resizeObserver = new ResizeObserver(observeCallback);
        resizeObserver.observe(stickyParentElement);
        resizeObserver.observe(stickyElement);
      };

      initializeHeights();
      setObservers();
    }
    return () => {
      resizeObserver?.disconnect();
    };
  }, []);

  return (
    <>
      <Box
        zIndex={zIndex ?? 'sticky'}
        backgroundColor={backgroundColor}
        width={width}
        willChange="top"
        transition="opacity 0.05s ease-in"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        ref={fixedDivRef}
        top={toPxString(stickyTop)}
        position="fixed"
        display="flex"
      />
      {/**
       * A replacement element is necessary because when the sticky element moves into the fixed div, it is removed from the dom flow of its original parent
       * causing a reduction in height of the parent element.
       * In order to prevent content jumps, the replacement element is used to maintain the height of the parent element.
       */}
      <div ref={stickyElementReplacementRef} />
      <div ref={stickyElementRef} style={{ position: 'relative' }}>
        {children}
      </div>
    </>
  );
};

export default React.memo(StickyHeader);
