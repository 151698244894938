import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface Props {
  position: 'top' | 'bottom' | 'left' | 'right';
  leftOffset?: BoxProps['left'];
  topOffset?: BoxProps['top'];
  bottomOffset?: BoxProps['bottom'];
}

const ReorderMarker: React.FC<Props> = ({
  position,
  leftOffset = 0,
  topOffset = 0,
  bottomOffset = 0,
}) => {
  let top: BoxProps['top'] | undefined;
  let bottom: BoxProps['bottom'] | undefined;
  let left: BoxProps['left'] | undefined;
  let right: BoxProps['right'] | undefined;
  let width: BoxProps['width'] | undefined;
  let height: BoxProps['height'] | undefined;

  switch (position) {
    case 'top':
      top = topOffset;
      left = leftOffset;
      right = 0;
      height = 1;
      break;
    case 'bottom':
      bottom = bottomOffset;
      left = leftOffset;
      right = 0;
      height = 1;
      break;
    case 'left':
      left = leftOffset;
      top = 0;
      bottom = 0;
      width = 1;
      break;
    case 'right':
      right = 0;
      top = 0;
      bottom = 0;
      width = 1;
      break;
    default:
      break;
  }

  return (
    <Box
      bgColor="selection.500"
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      h={height}
      w={width}
      pointerEvents="none"
      position="absolute"
      zIndex="overlay"
      opacity={0.6}
    />
  );
};

export default ReorderMarker;
