import React, { useCallback } from 'react';

import ContextMenu from 'components/ContextMenu/ContextMenu';
import RowOptions from 'components/RowOptions/RowOptions';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import useTimelineContextMenuItems from 'hooks/useTimelineContextMenuItems';
import { selectTimelineInitiativeIfUnselected } from 'reduxStore/actions/blockSelection';
import { PlanTimelineItemRef } from 'reduxStore/models/events';

interface Props {
  children: JSX.Element;
  timelineItemRef: PlanTimelineItemRef;
}

const PlanTimelineContextMenu: React.FC<Props> = ({ children, timelineItemRef }) => {
  const { blockId, readOnly } = useBlockContext();
  const dispatch = useAppDispatch();
  const onContextMenuOpen = useCallback(() => {
    dispatch(selectTimelineInitiativeIfUnselected(blockId, timelineItemRef));
  }, [dispatch, timelineItemRef, blockId]);
  const items = useTimelineContextMenuItems(timelineItemRef);

  return !readOnly ? (
    <ContextMenu items={items} onContextMenuOpen={onContextMenuOpen}>
      {children}
    </ContextMenu>
  ) : (
    children
  );
};

export const PlanTimelineRowOptions: React.FC<{
  timlineItemRef: PlanTimelineItemRef;
}> = ({ timlineItemRef }) => {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const onContextMenuOpen = useCallback(() => {
    dispatch(selectTimelineInitiativeIfUnselected(blockId, timlineItemRef));
  }, [dispatch, timlineItemRef, blockId]);
  const items = useTimelineContextMenuItems(timlineItemRef);

  return <RowOptions items={items} onOpen={onContextMenuOpen} ariaLabel="Click to open menu" />;
};

export default PlanTimelineContextMenu;
