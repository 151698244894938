import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'SidebarIcon',
  viewBox: '0 0 16 16',
  path: (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0.5C1.34315 0.5 0 1.84315 0 3.5V10.5C0 12.1569 1.34315 13.5 3 13.5H11C12.6569 13.5 14 12.1569 14 10.5V3.5C14 1.84315 12.6569 0.5 11 0.5H3ZM12.5 10.5C12.5 11.3284 11.8284 12 11 12H7V2H11C11.8284 2 12.5 2.67157 12.5 3.5V10.5ZM1.5 3.75C1.5 3.33579 1.83579 3 2.25 3H4.75C5.16421 3 5.5 3.33579 5.5 3.75C5.5 4.16421 5.16421 4.5 4.75 4.5H2.25C1.83579 4.5 1.5 4.16421 1.5 3.75ZM1.5 6.25C1.5 5.83579 1.83579 5.5 2.25 5.5H4.75C5.16421 5.5 5.5 5.83579 5.5 6.25C5.5 6.66421 5.16421 7 4.75 7H2.25C1.83579 7 1.5 6.66421 1.5 6.25ZM2.25 8C1.83579 8 1.5 8.33579 1.5 8.75C1.5 9.16421 1.83579 9.5 2.25 9.5H4.75C5.16421 9.5 5.5 9.16421 5.5 8.75C5.5 8.33579 5.16421 8 4.75 8H2.25Z"
        fill="currentColor"
      />
    </svg>
  ),
});
