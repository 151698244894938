import { createSelector } from 'reselect';

import { isEventGroupCellRef } from 'helpers/cells';
import { DetailPaneState, isDriverDetailPane, PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { ModalType } from 'reduxStore/reducers/pageSlice';
import { openModalTypeSelector } from 'selectors/pageBaseSelector';
import { Selector } from 'types/redux';

export const detailPaneSelector: Selector<DetailPaneState> = (state) => {
  return state.detailPane;
};

export const isDetailPaneOpenSelector: Selector<boolean> = createSelector(
  openModalTypeSelector,
  detailPaneSelector,
  (modalType, detailPane) => {
    if (modalType !== ModalType.DetailPane || detailPane?.type == null) {
      return false;
    }
    return detailPane != null;
  },
);

export const openDetailPaneSelector: Selector<DetailPaneState | null> = createSelector(
  isDetailPaneOpenSelector,
  detailPaneSelector,
  (isDetailPaneOpen, detailPane) => (isDetailPaneOpen ? detailPane : null),
);

export const openDetailPaneTypeSelector: Selector<PaneType | null> = createSelector(
  openDetailPaneSelector,
  (openDetailPane) => openDetailPane?.type ?? null,
);

const detailPaneSelectedCellSelector = createSelector(openDetailPaneSelector, (detailPane) =>
  isDriverDetailPane(detailPane) ? detailPane.selectedCell : null,
);

export const detailPaneSelectedEventGroupCellSelector = createSelector(
  detailPaneSelectedCellSelector,
  (cellRef) => (isEventGroupCellRef(cellRef) ? cellRef : null),
);
