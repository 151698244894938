import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'Tada',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="m9.867 1.6-.8.8.8.8.8-.8-.8-.8ZM5.6 2.133a.8.8 0 0 0 0 1.6h.806c.293 0 .53.233.534.525.01.883-.007 2.675-.007 2.675S8 5.333 8 3.733c0-.817-.533-1.6-1.6-1.6h-.8Zm7.2 0c-.583 0-1.067.484-1.067 1.067v1.067H11.2c-.583 0-1.067.483-1.067 1.066v.534h-.04c-.435 0-.86.141-1.209.403l-.038.03-1.238 1.338.784.725 1.15-1.246a.945.945 0 0 1 .552-.184h.04c.582 0 1.066-.483 1.066-1.066v-.534h.533c.583 0 1.067-.483 1.067-1.066V3.2h1.6V2.133h-1.6Zm-9.867.534-.8.8.8.8.8-.8-.8-.8Zm-1.066 3.2-.8.8.8.8.8-.8-.8-.8Zm11.733 0-.8.8.8.8.8-.8-.8-.8Zm-9.339.528a.533.533 0 0 0-.371.916l.161.161-2.396 5.99a.68.68 0 0 0 .884.883l5.99-2.396.16.162a.532.532 0 1 0 .755-.755L7.057 8.97 4.644 6.556a.533.533 0 0 0-.383-.161ZM12.267 8c-1.6 0-3.2 1.067-3.2 1.067s1.792-.017 2.675-.006a.531.531 0 0 1 .525.533v.806a.8.8 0 0 0 1.6 0v-.8c0-1.067-.783-1.6-1.6-1.6Zm-.8 3.733-.8.8.8.8.8-.8-.8-.8Z"
      fill="currentColor"
    />
  ),
});
