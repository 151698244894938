import { Flex, LayoutProps, SpaceProps, Text } from '@chakra-ui/react';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Checkbox } from 'components/MultiSelectSearchMenu/Checkbox';
import MultiSelectSearchMenuItem from 'components/MultiSelectSearchMenu/MultiSelectSearchMenuItem';
import SearchInput from 'components/SearchInput/SearchInput';
import VariableSizeVirtualizedVerticalList from 'components/VirtualizedList/VariableSizeVirtualizedVerticalList';
import { NO_ATTR } from 'types/formula';

type MenuOptionItem = {
  id: string;
  value: string;
  isSecondary?: boolean;
};
export interface MultiSelectSearchMenuProps {
  items: MenuOptionItem[];
  selectedIds: Set<string>;
  onSelectedIdsChange: (selectedIds: Set<string>) => void;
  searchInputPadding?: SpaceProps['padding'];
  maxHeight?: LayoutProps['maxHeight'];
}

type SearchItem = {
  id: string;
  value: string;
  isSelected: boolean;
  variant: 'primary' | 'secondary';
};
const MultiSelectSearchMenu = forwardRef<HTMLInputElement, MultiSelectSearchMenuProps>(
  (
    { items, selectedIds, onSelectedIdsChange, searchInputPadding, maxHeight = '12rem' },
    searchInputRef,
  ) => {
    const [query, setQuery] = useState<string>('');
    searchInputPadding ??= '0.375rem';

    // This is used to keep track of the initial selected ids.
    // as we want to sort the options based on the initial selected ids so they show
    // up at the top of the filter list on open, but not during activity filter updates
    // const initialySelectedIds = useRef<Set<string> | undefined>(undefined);
    const [initialySelectedIds, setInitiallySelectedIds] = useState<Set<string> | undefined>(
      undefined,
    );
    useEffect(() => {
      if (initialySelectedIds == null) {
        setInitiallySelectedIds(selectedIds);
      }
    }, [initialySelectedIds, selectedIds]);

    const options: SearchItem[] = useMemo(() => {
      return items.map((item) => ({
        id: item.id,
        value: item.value,
        isSelected: selectedIds.has(item.id),
        variant: item?.isSecondary ? 'secondary' : 'primary',
      }));
    }, [selectedIds, items]);

    const filteredOptions = useMemo(() => {
      const selectedIdSet = initialySelectedIds ?? new Set([]);
      return options
        .filter((option) =>
          query === '' ? true : option.value.toLowerCase().includes(query.toLowerCase()),
        )
        .sort((a, b) => {
          if (a.id === NO_ATTR || b.id === NO_ATTR) {
            return 1;
          }
          if (selectedIdSet.has(a.id) && !selectedIdSet.has(b.id)) {
            return -1;
          }
          if (!selectedIdSet.has(a.id) && selectedIdSet.has(b.id)) {
            return 1;
          }
          if (selectedIdSet.has(a.id) && selectedIdSet.has(b.id)) {
            return a.value.localeCompare(b.value);
          }
          return 0;
        });
    }, [options, initialySelectedIds, query]);

    const canClearAll = useMemo(() => {
      return selectedIds.size > 0;
    }, [selectedIds]);

    const filteredSelectedIds = useMemo(() => {
      return new Set(
        filteredOptions.filter((option) => selectedIds.has(option.id)).map((option) => option.id),
      );
    }, [filteredOptions, selectedIds]);

    const isIndeterminate = useMemo(() => {
      const nonSecondaryOptions = filteredOptions.filter(
        (option) => option.variant !== 'secondary',
      );
      const nonSecondarySelectedIds = new Set(
        nonSecondaryOptions
          .filter((option) => selectedIds.has(option.id))
          .map((option) => option.id),
      );
      return (
        nonSecondarySelectedIds.size > 0 &&
        nonSecondarySelectedIds.size < nonSecondaryOptions.length
      );
    }, [filteredOptions, selectedIds]);

    const selectedCountLabel = useMemo(() => {
      return `${filteredSelectedIds.size}/${filteredOptions.length}`;
    }, [filteredOptions, filteredSelectedIds]);

    const filterLabel = useMemo(() => {
      const noItemsSelected = filteredSelectedIds.size === 0;
      if (noItemsSelected || isIndeterminate) {
        return 'Select All';
      }
      return 'Deselect All';
    }, [isIndeterminate, filteredSelectedIds]);

    const toggleAll = useCallback(() => {
      if (isIndeterminate || selectedIds.size === 0) {
        const nonSecondaryOptionIds = new Set(
          filteredOptions
            .filter((option) => option.variant !== 'secondary')
            .map((option) => option.id),
        );
        onSelectedIdsChange(nonSecondaryOptionIds);
      } else {
        onSelectedIdsChange(new Set());
      }
    }, [isIndeterminate, onSelectedIdsChange, filteredOptions, selectedIds.size]);

    const onChange = useCallback(
      (itemId: string) => {
        const updatedSelectedIds = new Set(selectedIds);
        if (selectedIds.has(itemId)) {
          updatedSelectedIds.delete(itemId);
          onSelectedIdsChange(updatedSelectedIds);
        } else {
          updatedSelectedIds.add(itemId);
          onSelectedIdsChange(updatedSelectedIds);
        }
      },
      [selectedIds, onSelectedIdsChange],
    );

    return (
      <Flex direction="column" backgroundColor="surface" borderRadius={6} overflow="hidden">
        <Flex rowGap={1} direction="column" padding={searchInputPadding} pb={2}>
          <SearchInput
            placeholder="Search"
            query={query}
            setQuery={setQuery}
            ref={searchInputRef}
          />
        </Flex>
        <Flex rowGap={1} direction="column">
          <Flex
            justifyContent="space-between"
            py={1}
            px={2}
            gap={2}
            pb={2}
            borderBottom="1px solid"
            borderBottomColor="gray.200"
          >
            <Checkbox
              isChecked={canClearAll}
              isIndeterminate={isIndeterminate}
              onChange={toggleAll}
            />
            <Flex flexGrow="1">
              <Text color="gray.500" fontWeight="medium" fontSize="xs">
                {filterLabel}
              </Text>
            </Flex>
            <Text color="gray.400" fontSize="xs" fontWeight="medium">
              {selectedCountLabel}
            </Text>
          </Flex>
          <List options={filteredOptions} maxHeight={maxHeight} onChange={onChange} />
        </Flex>
      </Flex>
    );
  },
);

const ESTIMATED_ROW_HEIGHT = 29;

interface ListProps {
  options: SearchItem[];
  onChange: (itemId: string) => void;
  maxHeight?: LayoutProps['maxHeight'];
}

/**
 * Container for virtualizing select options. This vastly improves render performance for long lists.
 * This utilizes a variable height virtualized list to prevent options from being truncated.
 */
const List: React.FC<ListProps> = ({ options, maxHeight, onChange }) => {
  const listRef = useRef(null);

  return (
    <Flex ref={listRef} direction="column" maxHeight={maxHeight} overflowY="auto">
      <VariableSizeVirtualizedVerticalList
        estimatedItemHeight={ESTIMATED_ROW_HEIGHT}
        verticalScrollTargetRef={listRef}
      >
        {options.map((option) => (
          <MultiSelectSearchMenuItem key={option.id} {...option} onChange={onChange} />
        ))}
      </VariableSizeVirtualizedVerticalList>
      {options.length === 0 && (
        <Text py={2} px={2} fontWeight="medium" fontSize="xs" color="gray.500">
          No results found
        </Text>
      )}
    </Flex>
  );
};

export default MultiSelectSearchMenu;
