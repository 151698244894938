// Generated from ../antlr/Calculator.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { CalculatorListener } from "./CalculatorListener";

export class CalculatorParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly T__8 = 9;
	public static readonly T__9 = 10;
	public static readonly T__10 = 11;
	public static readonly T__11 = 12;
	public static readonly T__12 = 13;
	public static readonly T__13 = 14;
	public static readonly T__14 = 15;
	public static readonly T__15 = 16;
	public static readonly T__16 = 17;
	public static readonly T__17 = 18;
	public static readonly T__18 = 19;
	public static readonly T__19 = 20;
	public static readonly T__20 = 21;
	public static readonly T__21 = 22;
	public static readonly T__22 = 23;
	public static readonly T__23 = 24;
	public static readonly T__24 = 25;
	public static readonly T__25 = 26;
	public static readonly T__26 = 27;
	public static readonly T__27 = 28;
	public static readonly T__28 = 29;
	public static readonly T__29 = 30;
	public static readonly T__30 = 31;
	public static readonly T__31 = 32;
	public static readonly T__32 = 33;
	public static readonly T__33 = 34;
	public static readonly T__34 = 35;
	public static readonly T__35 = 36;
	public static readonly T__36 = 37;
	public static readonly T__37 = 38;
	public static readonly T__38 = 39;
	public static readonly T__39 = 40;
	public static readonly T__40 = 41;
	public static readonly T__41 = 42;
	public static readonly T__42 = 43;
	public static readonly T__43 = 44;
	public static readonly T__44 = 45;
	public static readonly T__45 = 46;
	public static readonly T__46 = 47;
	public static readonly DATE = 48;
	public static readonly UUID = 49;
	public static readonly NUMBER = 50;
	public static readonly FLOAT = 51;
	public static readonly NUMBER_SUFFIX = 52;
	public static readonly STRING = 53;
	public static readonly LPAREN = 54;
	public static readonly RPAREN = 55;
	public static readonly ADD = 56;
	public static readonly SUB = 57;
	public static readonly MUL = 58;
	public static readonly DIV = 59;
	public static readonly POW = 60;
	public static readonly NULL = 61;
	public static readonly ANY = 62;
	public static readonly CALENDAR = 63;
	public static readonly RELATIVE = 64;
	public static readonly COHORT_MONTH = 65;
	public static readonly ROUND = 66;
	public static readonly ROUNDDOWN = 67;
	public static readonly WS = 68;
	public static readonly BOOLEAN_OPERATOR = 69;
	public static readonly THIS = 70;
	public static readonly LINKED = 71;
	public static readonly INVALID_WORD_CHARS = 72;
	public static readonly INVALID_WORD = 73;
	public static readonly RULE_calculator = 0;
	public static readonly RULE_blockFilter = 1;
	public static readonly RULE_timestampCalculator = 2;
	public static readonly RULE_timestampExpression = 3;
	public static readonly RULE_stringCalculator = 4;
	public static readonly RULE_stringExpression = 5;
	public static readonly RULE_timestampValue = 6;
	public static readonly RULE_durationValue = 7;
	public static readonly RULE_durationUnit = 8;
	public static readonly RULE_booleanExpression = 9;
	public static readonly RULE_expression = 10;
	public static readonly RULE_dateHelpers = 11;
	public static readonly RULE_arrayExpression = 12;
	public static readonly RULE_sumProduct = 13;
	public static readonly RULE_baseIf = 14;
	public static readonly RULE_stringIf = 15;
	public static readonly RULE_timestampIf = 16;
	public static readonly RULE_ifErrorRef = 17;
	public static readonly RULE_coalesceRef = 18;
	public static readonly RULE_driverRef = 19;
	public static readonly RULE_dimDriverRef = 20;
	public static readonly RULE_submodelRef = 21;
	public static readonly RULE_objectRef = 22;
	public static readonly RULE_extObjectRef = 23;
	public static readonly RULE_extObjectFieldRef = 24;
	public static readonly RULE_objectSpecRef = 25;
	public static readonly RULE_objectFieldRef = 26;
	public static readonly RULE_extDriverRef = 27;
	public static readonly RULE_extQueryRef = 28;
	public static readonly RULE_extQueryFilterView = 29;
	public static readonly RULE_objectFilterView = 30;
	public static readonly RULE_objectFilter = 31;
	public static readonly RULE_userAttributeFilter = 32;
	public static readonly RULE_builtInAttributeFilter = 33;
	public static readonly RULE_attributeFilter = 34;
	public static readonly RULE_contextOrHardcodedAttributeFilter = 35;
	public static readonly RULE_driverGroupFilter = 36;
	public static readonly RULE_submodelFilter = 37;
	public static readonly RULE_driverFilterView = 38;
	public static readonly RULE_matchFilterView = 39;
	public static readonly RULE_allContextAttributesFilter = 40;
	public static readonly RULE_attribute = 41;
	public static readonly RULE_attributeGroup = 42;
	public static readonly RULE_formulaFilter = 43;
	public static readonly RULE_contextAttribute = 44;
	public static readonly RULE_relative = 45;
	public static readonly RULE_dimDriverView = 46;
	public static readonly RULE_submodelView = 47;
	public static readonly RULE_reduceExpressionsOrViews = 48;
	public static readonly RULE_expressionOrView = 49;
	public static readonly RULE_reducerFn = 50;
	public static readonly RULE_reduceableViews = 51;
	public static readonly RULE_timeRange = 52;
	public static readonly RULE_dateRef = 53;
	public static readonly RULE_stringGroup = 54;
	public static readonly RULE_uuidGroup = 55;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"calculator", "blockFilter", "timestampCalculator", "timestampExpression", 
		"stringCalculator", "stringExpression", "timestampValue", "durationValue", 
		"durationUnit", "booleanExpression", "expression", "dateHelpers", "arrayExpression", 
		"sumProduct", "baseIf", "stringIf", "timestampIf", "ifErrorRef", "coalesceRef", 
		"driverRef", "dimDriverRef", "submodelRef", "objectRef", "extObjectRef", 
		"extObjectFieldRef", "objectSpecRef", "objectFieldRef", "extDriverRef", 
		"extQueryRef", "extQueryFilterView", "objectFilterView", "objectFilter", 
		"userAttributeFilter", "builtInAttributeFilter", "attributeFilter", "contextOrHardcodedAttributeFilter", 
		"driverGroupFilter", "submodelFilter", "driverFilterView", "matchFilterView", 
		"allContextAttributesFilter", "attribute", "attributeGroup", "formulaFilter", 
		"contextAttribute", "relative", "dimDriverView", "submodelView", "reduceExpressionsOrViews", 
		"expressionOrView", "reducerFn", "reduceableViews", "timeRange", "dateRef", 
		"stringGroup", "uuidGroup",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'='", "'objectSpec('", "','", "'endofmonth('", "'startofmonth('", 
		"'asdays'", "'asweeks'", "'asmonths'", "'asyears'", "'&&'", "'||'", "'daysinmonth'", 
		"'datediff'", "'networkdays'", "'sumproduct('", "'if('", "'iferror('", 
		"'coalesce('", "'driver('", "'dimDriver('", "'submodel('", "'object('", 
		"'extObject('", "'extField('", "'field('", "'extDriver('", "'extQuery('", 
		"'filter('", "':'", "'DRIVER_GROUP:'", "'match('", "'ALL_CONTEXT_ATTRIBUTES'", 
		"'formulaFilter('", "'contextAttribute('", "'sum'", "'count'", "'avg'", 
		"'min'", "'max'", "'relative('", "'range('", "'cohort('", "'relMonths('", 
		"'relQuarters('", "'relYears('", "'\"'", "'|'", undefined, undefined, 
		undefined, undefined, undefined, undefined, "'('", "')'", "'+'", "'-'", 
		"'*'", "'/'", "'^'", undefined, "'ANY'", "'CALENDAR'", "'RELATIVE'", "'COHORTMONTH'", 
		undefined, undefined, undefined, undefined, "'this'", "'linked'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, "DATE", 
		"UUID", "NUMBER", "FLOAT", "NUMBER_SUFFIX", "STRING", "LPAREN", "RPAREN", 
		"ADD", "SUB", "MUL", "DIV", "POW", "NULL", "ANY", "CALENDAR", "RELATIVE", 
		"COHORT_MONTH", "ROUND", "ROUNDDOWN", "WS", "BOOLEAN_OPERATOR", "THIS", 
		"LINKED", "INVALID_WORD_CHARS", "INVALID_WORD",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(CalculatorParser._LITERAL_NAMES, CalculatorParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return CalculatorParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "Calculator.g4"; }

	// @Override
	public get ruleNames(): string[] { return CalculatorParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return CalculatorParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(CalculatorParser._ATN, this);
	}
	// @RuleVersion(0)
	public calculator(): CalculatorContext {
		let _localctx: CalculatorContext = new CalculatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, CalculatorParser.RULE_calculator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 113;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === CalculatorParser.T__0) {
				{
				this.state = 112;
				this.match(CalculatorParser.T__0);
				}
			}

			this.state = 115;
			this.expression(0);
			this.state = 116;
			this.match(CalculatorParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public blockFilter(): BlockFilterContext {
		let _localctx: BlockFilterContext = new BlockFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, CalculatorParser.RULE_blockFilter);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 118;
			this.match(CalculatorParser.T__1);
			this.state = 119;
			this.match(CalculatorParser.UUID);
			this.state = 122;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === CalculatorParser.T__2) {
				{
				this.state = 120;
				this.match(CalculatorParser.T__2);
				this.state = 121;
				this.objectFilterView();
				}
			}

			this.state = 124;
			this.match(CalculatorParser.RPAREN);
			this.state = 125;
			this.match(CalculatorParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timestampCalculator(): TimestampCalculatorContext {
		let _localctx: TimestampCalculatorContext = new TimestampCalculatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, CalculatorParser.RULE_timestampCalculator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 128;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === CalculatorParser.T__0) {
				{
				this.state = 127;
				this.match(CalculatorParser.T__0);
				}
			}

			this.state = 130;
			this.timestampExpression(0);
			this.state = 131;
			this.match(CalculatorParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public timestampExpression(): TimestampExpressionContext;
	public timestampExpression(_p: number): TimestampExpressionContext;
	// @RuleVersion(0)
	public timestampExpression(_p?: number): TimestampExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: TimestampExpressionContext = new TimestampExpressionContext(this._ctx, _parentState);
		let _prevctx: TimestampExpressionContext = _localctx;
		let _startState: number = 6;
		this.enterRecursionRule(_localctx, 6, CalculatorParser.RULE_timestampExpression, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 144;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.T__18:
			case CalculatorParser.T__21:
			case CalculatorParser.T__22:
			case CalculatorParser.T__39:
			case CalculatorParser.T__40:
			case CalculatorParser.T__41:
			case CalculatorParser.STRING:
				{
				_localctx = new SingleTimestampContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 134;
				this.timestampValue();
				}
				break;
			case CalculatorParser.T__3:
				{
				_localctx = new EndOfMonthContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 135;
				this.match(CalculatorParser.T__3);
				this.state = 136;
				this.timestampExpression(0);
				this.state = 137;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			case CalculatorParser.T__4:
				{
				_localctx = new StartOfMonthContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 139;
				this.match(CalculatorParser.T__4);
				this.state = 140;
				this.timestampExpression(0);
				this.state = 141;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			case CalculatorParser.T__15:
				{
				_localctx = new TimestampConditionalContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 143;
				this.timestampIf();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 154;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 5, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 152;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 4, this._ctx) ) {
					case 1:
						{
						_localctx = new AddSubTimestampContext(new TimestampExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, CalculatorParser.RULE_timestampExpression);
						this.state = 146;
						if (!(this.precpred(this._ctx, 5))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 5)");
						}
						this.state = 147;
						_la = this._input.LA(1);
						if (!(_la === CalculatorParser.ADD || _la === CalculatorParser.SUB)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 148;
						this.expression(0);
						}
						break;

					case 2:
						{
						_localctx = new AddSubDurationContext(new TimestampExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, CalculatorParser.RULE_timestampExpression);
						this.state = 149;
						if (!(this.precpred(this._ctx, 4))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
						}
						this.state = 150;
						_la = this._input.LA(1);
						if (!(_la === CalculatorParser.ADD || _la === CalculatorParser.SUB)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 151;
						this.durationValue();
						}
						break;
					}
					}
				}
				this.state = 156;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 5, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public stringCalculator(): StringCalculatorContext {
		let _localctx: StringCalculatorContext = new StringCalculatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, CalculatorParser.RULE_stringCalculator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 158;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === CalculatorParser.T__0) {
				{
				this.state = 157;
				this.match(CalculatorParser.T__0);
				}
			}

			this.state = 160;
			this.stringExpression();
			this.state = 161;
			this.match(CalculatorParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public stringExpression(): StringExpressionContext {
		let _localctx: StringExpressionContext = new StringExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, CalculatorParser.RULE_stringExpression);
		try {
			this.state = 167;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.T__21:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 163;
				this.objectRef();
				}
				break;
			case CalculatorParser.T__22:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 164;
				this.extObjectRef();
				}
				break;
			case CalculatorParser.STRING:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 165;
				this.match(CalculatorParser.STRING);
				}
				break;
			case CalculatorParser.T__15:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 166;
				this.stringIf();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timestampValue(): TimestampValueContext {
		let _localctx: TimestampValueContext = new TimestampValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, CalculatorParser.RULE_timestampValue);
		try {
			this.state = 174;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.T__21:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 169;
				this.objectRef();
				}
				break;
			case CalculatorParser.T__22:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 170;
				this.extObjectRef();
				}
				break;
			case CalculatorParser.T__18:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 171;
				this.driverRef();
				}
				break;
			case CalculatorParser.T__39:
			case CalculatorParser.T__40:
			case CalculatorParser.T__41:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 172;
				this.timeRange();
				}
				break;
			case CalculatorParser.STRING:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 173;
				this.match(CalculatorParser.STRING);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public durationValue(): DurationValueContext {
		let _localctx: DurationValueContext = new DurationValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, CalculatorParser.RULE_durationValue);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 176;
			this.durationUnit();
			this.state = 177;
			this.match(CalculatorParser.LPAREN);
			this.state = 178;
			this.expression(0);
			this.state = 179;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public durationUnit(): DurationUnitContext {
		let _localctx: DurationUnitContext = new DurationUnitContext(this._ctx, this.state);
		this.enterRule(_localctx, 16, CalculatorParser.RULE_durationUnit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 181;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << CalculatorParser.T__5) | (1 << CalculatorParser.T__6) | (1 << CalculatorParser.T__7) | (1 << CalculatorParser.T__8))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public booleanExpression(): BooleanExpressionContext;
	public booleanExpression(_p: number): BooleanExpressionContext;
	// @RuleVersion(0)
	public booleanExpression(_p?: number): BooleanExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: BooleanExpressionContext = new BooleanExpressionContext(this._ctx, _parentState);
		let _prevctx: BooleanExpressionContext = _localctx;
		let _startState: number = 18;
		this.enterRecursionRule(_localctx, 18, CalculatorParser.RULE_booleanExpression, _p);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 196;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 9, this._ctx) ) {
			case 1:
				{
				_localctx = new SimpleExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 184;
				this.expression(0);
				this.state = 185;
				this.match(CalculatorParser.BOOLEAN_OPERATOR);
				this.state = 186;
				this.expression(0);
				}
				break;

			case 2:
				{
				_localctx = new TimestampBooleanExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 188;
				this.timestampExpression(0);
				this.state = 189;
				this.match(CalculatorParser.BOOLEAN_OPERATOR);
				this.state = 190;
				this.timestampExpression(0);
				}
				break;

			case 3:
				{
				_localctx = new StringBooleanExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 192;
				this.stringExpression();
				this.state = 193;
				this.match(CalculatorParser.BOOLEAN_OPERATOR);
				this.state = 194;
				this.stringExpression();
				}
				break;
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 206;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 11, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 204;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 10, this._ctx) ) {
					case 1:
						{
						_localctx = new AndExpressionContext(new BooleanExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, CalculatorParser.RULE_booleanExpression);
						this.state = 198;
						if (!(this.precpred(this._ctx, 4))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
						}
						this.state = 199;
						this.match(CalculatorParser.T__9);
						this.state = 200;
						this.booleanExpression(5);
						}
						break;

					case 2:
						{
						_localctx = new OrExpressionContext(new BooleanExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, CalculatorParser.RULE_booleanExpression);
						this.state = 201;
						if (!(this.precpred(this._ctx, 3))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
						}
						this.state = 202;
						this.match(CalculatorParser.T__10);
						this.state = 203;
						this.booleanExpression(4);
						}
						break;
					}
					}
				}
				this.state = 208;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 11, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}

	public expression(): ExpressionContext;
	public expression(_p: number): ExpressionContext;
	// @RuleVersion(0)
	public expression(_p?: number): ExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, _parentState);
		let _prevctx: ExpressionContext = _localctx;
		let _startState: number = 20;
		this.enterRecursionRule(_localctx, 20, CalculatorParser.RULE_expression, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 253;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 13, this._ctx) ) {
			case 1:
				{
				_localctx = new NumberContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 210;
				this.match(CalculatorParser.NUMBER);
				}
				break;

			case 2:
				{
				_localctx = new NullContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 211;
				this.match(CalculatorParser.NULL);
				}
				break;

			case 3:
				{
				_localctx = new DriverContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 212;
				this.driverRef();
				}
				break;

			case 4:
				{
				_localctx = new ExtDriverContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 213;
				this.extDriverRef();
				}
				break;

			case 5:
				{
				_localctx = new ReducerContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 214;
				this.reduceExpressionsOrViews();
				}
				break;

			case 6:
				{
				_localctx = new AddProductContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 215;
				this.sumProduct();
				}
				break;

			case 7:
				{
				_localctx = new MinusContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 216;
				this.match(CalculatorParser.SUB);
				this.state = 217;
				this.expression(18);
				}
				break;

			case 8:
				{
				_localctx = new ParenthesisContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 218;
				this.match(CalculatorParser.LPAREN);
				this.state = 219;
				this.expression(0);
				this.state = 220;
				this.match(CalculatorParser.RPAREN);
				}
				break;

			case 9:
				{
				_localctx = new RoundContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 222;
				this.match(CalculatorParser.ROUND);
				this.state = 223;
				this.match(CalculatorParser.LPAREN);
				this.state = 224;
				this.expression(0);
				this.state = 225;
				this.match(CalculatorParser.RPAREN);
				}
				break;

			case 10:
				{
				_localctx = new RoundDownContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 227;
				this.match(CalculatorParser.ROUNDDOWN);
				this.state = 228;
				this.match(CalculatorParser.LPAREN);
				this.state = 229;
				this.expression(0);
				this.state = 230;
				this.match(CalculatorParser.RPAREN);
				}
				break;

			case 11:
				{
				_localctx = new RoundPlacesContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 232;
				this.match(CalculatorParser.ROUND);
				this.state = 233;
				this.match(CalculatorParser.LPAREN);
				this.state = 234;
				this.expression(0);
				this.state = 235;
				this.match(CalculatorParser.T__2);
				this.state = 236;
				this.expression(0);
				this.state = 237;
				this.match(CalculatorParser.RPAREN);
				}
				break;

			case 12:
				{
				_localctx = new ConditionalContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 239;
				this.baseIf();
				}
				break;

			case 13:
				{
				_localctx = new IfErrorContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 240;
				this.ifErrorRef();
				}
				break;

			case 14:
				{
				_localctx = new CoalesceContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 241;
				this.coalesceRef();
				}
				break;

			case 15:
				{
				_localctx = new ObjectContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 242;
				this.objectRef();
				}
				break;

			case 16:
				{
				_localctx = new ExtObjectContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 243;
				this.extObjectRef();
				}
				break;

			case 17:
				{
				_localctx = new DimDriverFilteredContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 244;
				this.driverFilterView();
				}
				break;

			case 18:
				{
				_localctx = new MatchFilterContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 245;
				this.matchFilterView();
				}
				break;

			case 19:
				{
				_localctx = new DateExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 246;
				this.dateHelpers();
				}
				break;

			case 20:
				{
				_localctx = new InvalidExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 248;
				this._errHandler.sync(this);
				_alt = 1;
				do {
					switch (_alt) {
					case 1:
						{
						{
						this.state = 247;
						this.match(CalculatorParser.INVALID_WORD);
						}
						}
						break;
					default:
						throw new NoViableAltException(this);
					}
					this.state = 250;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 12, this._ctx);
				} while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER);
				}
				break;

			case 21:
				{
				_localctx = new TimestampValueInExpressionsContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 252;
				this.timestampValue();
				}
				break;
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 266;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 15, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 264;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 14, this._ctx) ) {
					case 1:
						{
						_localctx = new PowContext(new ExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, CalculatorParser.RULE_expression);
						this.state = 255;
						if (!(this.precpred(this._ctx, 16))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 16)");
						}
						this.state = 256;
						this.match(CalculatorParser.POW);
						this.state = 257;
						this.expression(17);
						}
						break;

					case 2:
						{
						_localctx = new MulDivContext(new ExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, CalculatorParser.RULE_expression);
						this.state = 258;
						if (!(this.precpred(this._ctx, 15))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 15)");
						}
						this.state = 259;
						_la = this._input.LA(1);
						if (!(_la === CalculatorParser.MUL || _la === CalculatorParser.DIV)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 260;
						this.expression(16);
						}
						break;

					case 3:
						{
						_localctx = new AddSubContext(new ExpressionContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, CalculatorParser.RULE_expression);
						this.state = 261;
						if (!(this.precpred(this._ctx, 14))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 14)");
						}
						this.state = 262;
						_la = this._input.LA(1);
						if (!(_la === CalculatorParser.ADD || _la === CalculatorParser.SUB)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 263;
						this.expression(15);
						}
						break;
					}
					}
				}
				this.state = 268;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 15, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dateHelpers(): DateHelpersContext {
		let _localctx: DateHelpersContext = new DateHelpersContext(this._ctx, this.state);
		this.enterRule(_localctx, 22, CalculatorParser.RULE_dateHelpers);
		let _la: number;
		try {
			this.state = 296;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.T__11:
				_localctx = new DaysInMonthContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 269;
				this.match(CalculatorParser.T__11);
				this.state = 270;
				this.match(CalculatorParser.LPAREN);
				this.state = 271;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			case CalculatorParser.T__12:
				_localctx = new DateDiffContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 272;
				this.match(CalculatorParser.T__12);
				this.state = 273;
				this.match(CalculatorParser.LPAREN);
				this.state = 274;
				this.timestampExpression(0);
				this.state = 275;
				this.match(CalculatorParser.T__2);
				this.state = 276;
				this.timestampExpression(0);
				this.state = 277;
				this.match(CalculatorParser.T__2);
				this.state = 278;
				this.match(CalculatorParser.STRING);
				this.state = 279;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			case CalculatorParser.T__13:
				_localctx = new NetWorkDaysContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 281;
				this.match(CalculatorParser.T__13);
				this.state = 282;
				this.match(CalculatorParser.LPAREN);
				this.state = 283;
				this.timestampExpression(0);
				this.state = 284;
				this.match(CalculatorParser.T__2);
				this.state = 285;
				this.timestampExpression(0);
				this.state = 288;
				this._errHandler.sync(this);
				switch ( this.interpreter.adaptivePredict(this._input, 16, this._ctx) ) {
				case 1:
					{
					this.state = 286;
					this.match(CalculatorParser.T__2);
					this.state = 287;
					this.match(CalculatorParser.STRING);
					}
					break;
				}
				this.state = 292;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === CalculatorParser.T__2) {
					{
					this.state = 290;
					this.match(CalculatorParser.T__2);
					this.state = 291;
					this.match(CalculatorParser.STRING);
					}
				}

				this.state = 294;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public arrayExpression(): ArrayExpressionContext;
	public arrayExpression(_p: number): ArrayExpressionContext;
	// @RuleVersion(0)
	public arrayExpression(_p?: number): ArrayExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ArrayExpressionContext = new ArrayExpressionContext(this._ctx, _parentState);
		let _prevctx: ArrayExpressionContext = _localctx;
		let _startState: number = 24;
		this.enterRecursionRule(_localctx, 24, CalculatorParser.RULE_arrayExpression, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 303;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.T__1:
				{
				this.state = 299;
				this.objectSpecRef();
				}
				break;
			case CalculatorParser.NUMBER:
				{
				this.state = 300;
				this.match(CalculatorParser.NUMBER);
				this.state = 301;
				_la = this._input.LA(1);
				if (!(_la === CalculatorParser.ADD || _la === CalculatorParser.SUB)) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				this.state = 302;
				this.arrayExpression(1);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 310;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 20, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					{
					_localctx = new ArrayExpressionContext(_parentctx, _parentState);
					this.pushNewRecursionContext(_localctx, _startState, CalculatorParser.RULE_arrayExpression);
					this.state = 305;
					if (!(this.precpred(this._ctx, 2))) {
						throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
					}
					this.state = 306;
					_la = this._input.LA(1);
					if (!(_la === CalculatorParser.ADD || _la === CalculatorParser.SUB)) {
					this._errHandler.recoverInline(this);
					} else {
						if (this._input.LA(1) === Token.EOF) {
							this.matchedEOF = true;
						}

						this._errHandler.reportMatch(this);
						this.consume();
					}
					this.state = 307;
					this.match(CalculatorParser.NUMBER);
					}
					}
				}
				this.state = 312;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 20, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public sumProduct(): SumProductContext {
		let _localctx: SumProductContext = new SumProductContext(this._ctx, this.state);
		this.enterRule(_localctx, 26, CalculatorParser.RULE_sumProduct);
		try {
			this.state = 343;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 21, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 313;
				this.match(CalculatorParser.T__14);
				this.state = 314;
				this.driverRef();
				this.state = 315;
				this.match(CalculatorParser.T__2);
				this.state = 316;
				this.driverRef();
				this.state = 317;
				this.match(CalculatorParser.RPAREN);
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 319;
				this.match(CalculatorParser.T__14);
				this.state = 320;
				this.dimDriverRef();
				this.state = 321;
				this.match(CalculatorParser.T__2);
				this.state = 322;
				this.dimDriverRef();
				this.state = 323;
				this.match(CalculatorParser.RPAREN);
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 325;
				this.match(CalculatorParser.T__14);
				this.state = 326;
				this.dimDriverRef();
				this.state = 327;
				this.match(CalculatorParser.T__2);
				this.state = 328;
				this.driverRef();
				this.state = 329;
				this.match(CalculatorParser.RPAREN);
				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 331;
				this.match(CalculatorParser.T__14);
				this.state = 332;
				this.driverRef();
				this.state = 333;
				this.match(CalculatorParser.T__2);
				this.state = 334;
				this.dimDriverRef();
				this.state = 335;
				this.match(CalculatorParser.RPAREN);
				}
				break;

			case 5:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 337;
				this.match(CalculatorParser.T__14);
				this.state = 338;
				this.arrayExpression(0);
				this.state = 339;
				this.match(CalculatorParser.T__2);
				this.state = 340;
				this.arrayExpression(0);
				this.state = 341;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public baseIf(): BaseIfContext {
		let _localctx: BaseIfContext = new BaseIfContext(this._ctx, this.state);
		this.enterRule(_localctx, 28, CalculatorParser.RULE_baseIf);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 345;
			this.match(CalculatorParser.T__15);
			this.state = 346;
			this.booleanExpression(0);
			this.state = 347;
			this.match(CalculatorParser.T__2);
			this.state = 348;
			this.expression(0);
			this.state = 349;
			this.match(CalculatorParser.T__2);
			this.state = 350;
			this.expression(0);
			this.state = 351;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public stringIf(): StringIfContext {
		let _localctx: StringIfContext = new StringIfContext(this._ctx, this.state);
		this.enterRule(_localctx, 30, CalculatorParser.RULE_stringIf);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 353;
			this.match(CalculatorParser.T__15);
			this.state = 354;
			this.booleanExpression(0);
			this.state = 355;
			this.match(CalculatorParser.T__2);
			this.state = 356;
			this.stringExpression();
			this.state = 357;
			this.match(CalculatorParser.T__2);
			this.state = 358;
			this.stringExpression();
			this.state = 359;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timestampIf(): TimestampIfContext {
		let _localctx: TimestampIfContext = new TimestampIfContext(this._ctx, this.state);
		this.enterRule(_localctx, 32, CalculatorParser.RULE_timestampIf);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 361;
			this.match(CalculatorParser.T__15);
			this.state = 362;
			this.booleanExpression(0);
			this.state = 363;
			this.match(CalculatorParser.T__2);
			this.state = 364;
			this.timestampExpression(0);
			this.state = 365;
			this.match(CalculatorParser.T__2);
			this.state = 366;
			this.timestampExpression(0);
			this.state = 367;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public ifErrorRef(): IfErrorRefContext {
		let _localctx: IfErrorRefContext = new IfErrorRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 34, CalculatorParser.RULE_ifErrorRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 369;
			this.match(CalculatorParser.T__16);
			this.state = 370;
			this.expression(0);
			this.state = 371;
			this.match(CalculatorParser.T__2);
			this.state = 372;
			this.expression(0);
			this.state = 373;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public coalesceRef(): CoalesceRefContext {
		let _localctx: CoalesceRefContext = new CoalesceRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 36, CalculatorParser.RULE_coalesceRef);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 375;
			this.match(CalculatorParser.T__17);
			this.state = 376;
			this.expression(0);
			this.state = 381;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === CalculatorParser.T__2) {
				{
				{
				this.state = 377;
				this.match(CalculatorParser.T__2);
				this.state = 378;
				this.expression(0);
				}
				}
				this.state = 383;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 384;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public driverRef(): DriverRefContext {
		let _localctx: DriverRefContext = new DriverRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 38, CalculatorParser.RULE_driverRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 386;
			this.match(CalculatorParser.T__18);
			this.state = 387;
			this.match(CalculatorParser.UUID);
			this.state = 388;
			this.match(CalculatorParser.T__2);
			this.state = 389;
			this.timeRange();
			this.state = 390;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dimDriverRef(): DimDriverRefContext {
		let _localctx: DimDriverRefContext = new DimDriverRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 40, CalculatorParser.RULE_dimDriverRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 392;
			this.match(CalculatorParser.T__19);
			this.state = 393;
			this.match(CalculatorParser.UUID);
			this.state = 394;
			this.match(CalculatorParser.T__2);
			this.state = 395;
			this.timeRange();
			this.state = 396;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public submodelRef(): SubmodelRefContext {
		let _localctx: SubmodelRefContext = new SubmodelRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 42, CalculatorParser.RULE_submodelRef);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 398;
			this.match(CalculatorParser.T__20);
			this.state = 399;
			this.match(CalculatorParser.UUID);
			this.state = 402;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === CalculatorParser.T__2) {
				{
				this.state = 400;
				this.match(CalculatorParser.T__2);
				this.state = 401;
				this.timeRange();
				}
			}

			this.state = 404;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectRef(): ObjectRefContext {
		let _localctx: ObjectRefContext = new ObjectRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 44, CalculatorParser.RULE_objectRef);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 406;
			this.match(CalculatorParser.T__21);
			this.state = 407;
			_la = this._input.LA(1);
			if (!(_la === CalculatorParser.UUID || _la === CalculatorParser.THIS)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			this.state = 408;
			this.match(CalculatorParser.T__2);
			this.state = 409;
			this.objectFieldRef();
			this.state = 412;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === CalculatorParser.T__2) {
				{
				this.state = 410;
				this.match(CalculatorParser.T__2);
				this.state = 411;
				this.objectFilterView();
				}
			}

			this.state = 414;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extObjectRef(): ExtObjectRefContext {
		let _localctx: ExtObjectRefContext = new ExtObjectRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 46, CalculatorParser.RULE_extObjectRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 416;
			this.match(CalculatorParser.T__22);
			{
			this.state = 417;
			this.match(CalculatorParser.LINKED);
			}
			this.state = 418;
			this.match(CalculatorParser.T__2);
			this.state = 419;
			this.extObjectFieldRef();
			this.state = 420;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extObjectFieldRef(): ExtObjectFieldRefContext {
		let _localctx: ExtObjectFieldRefContext = new ExtObjectFieldRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 48, CalculatorParser.RULE_extObjectFieldRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 422;
			this.match(CalculatorParser.T__23);
			{
			this.state = 423;
			this.match(CalculatorParser.LINKED);
			}
			this.state = 424;
			this.match(CalculatorParser.T__2);
			this.state = 425;
			this.timeRange();
			this.state = 426;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectSpecRef(): ObjectSpecRefContext {
		let _localctx: ObjectSpecRefContext = new ObjectSpecRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 50, CalculatorParser.RULE_objectSpecRef);
		let _la: number;
		try {
			this.state = 448;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 27, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 428;
				this.match(CalculatorParser.T__1);
				this.state = 429;
				this.match(CalculatorParser.UUID);
				this.state = 430;
				this.match(CalculatorParser.T__2);
				this.state = 431;
				this.objectFieldRef();
				this.state = 434;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === CalculatorParser.T__2) {
					{
					this.state = 432;
					this.match(CalculatorParser.T__2);
					this.state = 433;
					this.objectFilterView();
					}
				}

				this.state = 436;
				this.match(CalculatorParser.RPAREN);
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 438;
				this.match(CalculatorParser.T__1);
				this.state = 439;
				this.match(CalculatorParser.UUID);
				this.state = 440;
				this.match(CalculatorParser.T__2);
				this.state = 441;
				this.timeRange();
				this.state = 444;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === CalculatorParser.T__2) {
					{
					this.state = 442;
					this.match(CalculatorParser.T__2);
					this.state = 443;
					this.objectFilterView();
					}
				}

				this.state = 446;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectFieldRef(): ObjectFieldRefContext {
		let _localctx: ObjectFieldRefContext = new ObjectFieldRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 52, CalculatorParser.RULE_objectFieldRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 450;
			this.match(CalculatorParser.T__24);
			this.state = 451;
			this.match(CalculatorParser.UUID);
			this.state = 452;
			this.match(CalculatorParser.T__2);
			this.state = 453;
			this.timeRange();
			this.state = 454;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extDriverRef(): ExtDriverRefContext {
		let _localctx: ExtDriverRefContext = new ExtDriverRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 54, CalculatorParser.RULE_extDriverRef);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 456;
			this.match(CalculatorParser.T__25);
			this.state = 457;
			this.match(CalculatorParser.UUID);
			this.state = 458;
			this.match(CalculatorParser.T__2);
			this.state = 459;
			this.timeRange();
			this.state = 460;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extQueryRef(): ExtQueryRefContext {
		let _localctx: ExtQueryRefContext = new ExtQueryRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 56, CalculatorParser.RULE_extQueryRef);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 462;
			this.match(CalculatorParser.T__26);
			this.state = 463;
			this.match(CalculatorParser.UUID);
			this.state = 464;
			this.match(CalculatorParser.T__2);
			this.state = 465;
			this.timeRange();
			this.state = 468;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === CalculatorParser.T__2) {
				{
				this.state = 466;
				this.match(CalculatorParser.T__2);
				this.state = 467;
				this.extQueryFilterView();
				}
			}

			this.state = 470;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public extQueryFilterView(): ExtQueryFilterViewContext {
		let _localctx: ExtQueryFilterViewContext = new ExtQueryFilterViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 58, CalculatorParser.RULE_extQueryFilterView);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 472;
			this.match(CalculatorParser.T__27);
			this.state = 474;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 473;
				this.attributeFilter();
				}
				}
				this.state = 476;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (((((_la - 32)) & ~0x1F) === 0 && ((1 << (_la - 32)) & ((1 << (CalculatorParser.T__31 - 32)) | (1 << (CalculatorParser.T__32 - 32)) | (1 << (CalculatorParser.T__33 - 32)) | (1 << (CalculatorParser.UUID - 32)) | (1 << (CalculatorParser.CALENDAR - 32)))) !== 0) || _la === CalculatorParser.RELATIVE);
			this.state = 478;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectFilterView(): ObjectFilterViewContext {
		let _localctx: ObjectFilterViewContext = new ObjectFilterViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 60, CalculatorParser.RULE_objectFilterView);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 480;
			this.match(CalculatorParser.T__27);
			this.state = 481;
			this.objectFilter();
			this.state = 486;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === CalculatorParser.T__9) {
				{
				{
				this.state = 482;
				this.match(CalculatorParser.T__9);
				this.state = 483;
				this.objectFilter();
				}
				}
				this.state = 488;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 489;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public objectFilter(): ObjectFilterContext {
		let _localctx: ObjectFilterContext = new ObjectFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 62, CalculatorParser.RULE_objectFilter);
		let _la: number;
		try {
			this.state = 510;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.UUID:
				_localctx = new ObjectUUIDFilterContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 491;
				this.match(CalculatorParser.UUID);
				this.state = 496;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === CalculatorParser.T__2) {
					{
					{
					this.state = 492;
					this.match(CalculatorParser.T__2);
					this.state = 493;
					this.match(CalculatorParser.UUID);
					}
					}
					this.state = 498;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				}
				break;
			case CalculatorParser.T__24:
				_localctx = new ObjectFieldFilterContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 499;
				this.objectFieldRef();
				this.state = 500;
				this.match(CalculatorParser.BOOLEAN_OPERATOR);
				this.state = 507;
				this._errHandler.sync(this);
				switch ( this.interpreter.adaptivePredict(this._input, 32, this._ctx) ) {
				case 1:
					{
					this.state = 501;
					this.stringGroup();
					}
					break;

				case 2:
					{
					this.state = 502;
					this.uuidGroup();
					}
					break;

				case 3:
					{
					this.state = 503;
					this.timeRange();
					}
					break;

				case 4:
					{
					this.state = 504;
					this.attributeGroup();
					}
					break;

				case 5:
					{
					this.state = 505;
					this.formulaFilter();
					}
					break;

				case 6:
					{
					this.state = 506;
					this.match(CalculatorParser.NULL);
					}
					break;
				}
				}
				break;
			case CalculatorParser.T__31:
				_localctx = new ObjectAllContextAttributesFilterContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 509;
				this.allContextAttributesFilter();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public userAttributeFilter(): UserAttributeFilterContext {
		let _localctx: UserAttributeFilterContext = new UserAttributeFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 64, CalculatorParser.RULE_userAttributeFilter);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 512;
			this.match(CalculatorParser.UUID);
			this.state = 513;
			this.match(CalculatorParser.T__28);
			this.state = 514;
			_la = this._input.LA(1);
			if (!(((((_la - 49)) & ~0x1F) === 0 && ((1 << (_la - 49)) & ((1 << (CalculatorParser.UUID - 49)) | (1 << (CalculatorParser.NULL - 49)) | (1 << (CalculatorParser.ANY - 49)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public builtInAttributeFilter(): BuiltInAttributeFilterContext {
		let _localctx: BuiltInAttributeFilterContext = new BuiltInAttributeFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 66, CalculatorParser.RULE_builtInAttributeFilter);
		let _la: number;
		try {
			this.state = 522;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.CALENDAR:
				_localctx = new CalendarFilterContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 516;
				this.match(CalculatorParser.CALENDAR);
				this.state = 517;
				this.match(CalculatorParser.T__28);
				this.state = 518;
				_la = this._input.LA(1);
				if (!(((((_la - 48)) & ~0x1F) === 0 && ((1 << (_la - 48)) & ((1 << (CalculatorParser.DATE - 48)) | (1 << (CalculatorParser.NULL - 48)) | (1 << (CalculatorParser.ANY - 48)))) !== 0))) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				}
				break;
			case CalculatorParser.RELATIVE:
				_localctx = new RelativeFilterContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 519;
				this.match(CalculatorParser.RELATIVE);
				this.state = 520;
				this.match(CalculatorParser.T__28);
				this.state = 521;
				_la = this._input.LA(1);
				if (!(((((_la - 50)) & ~0x1F) === 0 && ((1 << (_la - 50)) & ((1 << (CalculatorParser.NUMBER - 50)) | (1 << (CalculatorParser.NULL - 50)) | (1 << (CalculatorParser.ANY - 50)) | (1 << (CalculatorParser.COHORT_MONTH - 50)))) !== 0))) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public attributeFilter(): AttributeFilterContext {
		let _localctx: AttributeFilterContext = new AttributeFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 68, CalculatorParser.RULE_attributeFilter);
		try {
			this.state = 529;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 35, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 524;
				this.builtInAttributeFilter();
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 525;
				this.userAttributeFilter();
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 526;
				this.contextOrHardcodedAttributeFilter();
				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 527;
				this.allContextAttributesFilter();
				}
				break;

			case 5:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 528;
				this.formulaFilter();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public contextOrHardcodedAttributeFilter(): ContextOrHardcodedAttributeFilterContext {
		let _localctx: ContextOrHardcodedAttributeFilterContext = new ContextOrHardcodedAttributeFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 70, CalculatorParser.RULE_contextOrHardcodedAttributeFilter);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 531;
			this.attribute();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public driverGroupFilter(): DriverGroupFilterContext {
		let _localctx: DriverGroupFilterContext = new DriverGroupFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 72, CalculatorParser.RULE_driverGroupFilter);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 533;
			this.match(CalculatorParser.T__29);
			this.state = 534;
			this.match(CalculatorParser.UUID);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public submodelFilter(): SubmodelFilterContext {
		let _localctx: SubmodelFilterContext = new SubmodelFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 74, CalculatorParser.RULE_submodelFilter);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 536;
			this.driverGroupFilter();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public driverFilterView(): DriverFilterViewContext {
		let _localctx: DriverFilterViewContext = new DriverFilterViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 76, CalculatorParser.RULE_driverFilterView);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 538;
			this.match(CalculatorParser.T__27);
			this.state = 539;
			this.dimDriverView();
			this.state = 540;
			this.match(CalculatorParser.T__2);
			{
			this.state = 542;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 541;
				this.attributeFilter();
				}
				}
				this.state = 544;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (((((_la - 32)) & ~0x1F) === 0 && ((1 << (_la - 32)) & ((1 << (CalculatorParser.T__31 - 32)) | (1 << (CalculatorParser.T__32 - 32)) | (1 << (CalculatorParser.T__33 - 32)) | (1 << (CalculatorParser.UUID - 32)) | (1 << (CalculatorParser.CALENDAR - 32)))) !== 0) || _la === CalculatorParser.RELATIVE);
			}
			this.state = 546;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public matchFilterView(): MatchFilterViewContext {
		let _localctx: MatchFilterViewContext = new MatchFilterViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 78, CalculatorParser.RULE_matchFilterView);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 548;
			this.match(CalculatorParser.T__30);
			this.state = 551;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.T__27:
				{
				this.state = 549;
				this.driverFilterView();
				}
				break;
			case CalculatorParser.T__1:
				{
				this.state = 550;
				this.objectSpecRef();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this.state = 553;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public allContextAttributesFilter(): AllContextAttributesFilterContext {
		let _localctx: AllContextAttributesFilterContext = new AllContextAttributesFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 80, CalculatorParser.RULE_allContextAttributesFilter);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 555;
			this.match(CalculatorParser.T__31);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public attribute(): AttributeContext {
		let _localctx: AttributeContext = new AttributeContext(this._ctx, this.state);
		this.enterRule(_localctx, 82, CalculatorParser.RULE_attribute);
		try {
			this.state = 559;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.T__33:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 557;
				this.contextAttribute();
				}
				break;
			case CalculatorParser.UUID:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 558;
				this.match(CalculatorParser.UUID);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public attributeGroup(): AttributeGroupContext {
		let _localctx: AttributeGroupContext = new AttributeGroupContext(this._ctx, this.state);
		this.enterRule(_localctx, 84, CalculatorParser.RULE_attributeGroup);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 561;
			this.attribute();
			this.state = 566;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === CalculatorParser.T__2) {
				{
				{
				this.state = 562;
				this.match(CalculatorParser.T__2);
				this.state = 563;
				this.attribute();
				}
				}
				this.state = 568;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public formulaFilter(): FormulaFilterContext {
		let _localctx: FormulaFilterContext = new FormulaFilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 86, CalculatorParser.RULE_formulaFilter);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 569;
			this.match(CalculatorParser.T__32);
			this.state = 570;
			this.matchFilterView();
			this.state = 571;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public contextAttribute(): ContextAttributeContext {
		let _localctx: ContextAttributeContext = new ContextAttributeContext(this._ctx, this.state);
		this.enterRule(_localctx, 88, CalculatorParser.RULE_contextAttribute);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 573;
			this.match(CalculatorParser.T__33);
			this.state = 574;
			this.match(CalculatorParser.UUID);
			this.state = 575;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public relative(): RelativeContext {
		let _localctx: RelativeContext = new RelativeContext(this._ctx, this.state);
		this.enterRule(_localctx, 90, CalculatorParser.RULE_relative);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 577;
			this.match(CalculatorParser.T__27);
			this.state = 578;
			this.dimDriverView();
			this.state = 579;
			this.match(CalculatorParser.T__2);
			this.state = 581;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 580;
				this.attributeFilter();
				}
				}
				this.state = 583;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (((((_la - 32)) & ~0x1F) === 0 && ((1 << (_la - 32)) & ((1 << (CalculatorParser.T__31 - 32)) | (1 << (CalculatorParser.T__32 - 32)) | (1 << (CalculatorParser.T__33 - 32)) | (1 << (CalculatorParser.UUID - 32)) | (1 << (CalculatorParser.CALENDAR - 32)))) !== 0) || _la === CalculatorParser.RELATIVE);
			this.state = 585;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dimDriverView(): DimDriverViewContext {
		let _localctx: DimDriverViewContext = new DimDriverViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 92, CalculatorParser.RULE_dimDriverView);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 587;
			this.dimDriverRef();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public submodelView(): SubmodelViewContext {
		let _localctx: SubmodelViewContext = new SubmodelViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 94, CalculatorParser.RULE_submodelView);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 589;
			this.match(CalculatorParser.T__27);
			this.state = 590;
			this.submodelRef();
			this.state = 591;
			this.match(CalculatorParser.T__2);
			this.state = 592;
			this.submodelFilter();
			this.state = 593;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public reduceExpressionsOrViews(): ReduceExpressionsOrViewsContext {
		let _localctx: ReduceExpressionsOrViewsContext = new ReduceExpressionsOrViewsContext(this._ctx, this.state);
		this.enterRule(_localctx, 96, CalculatorParser.RULE_reduceExpressionsOrViews);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 595;
			this.reducerFn();
			this.state = 596;
			this.match(CalculatorParser.LPAREN);
			this.state = 597;
			this.expressionOrView();
			this.state = 602;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === CalculatorParser.T__2) {
				{
				{
				this.state = 598;
				this.match(CalculatorParser.T__2);
				this.state = 599;
				this.expressionOrView();
				}
				}
				this.state = 604;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 605;
			this.match(CalculatorParser.RPAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public expressionOrView(): ExpressionOrViewContext {
		let _localctx: ExpressionOrViewContext = new ExpressionOrViewContext(this._ctx, this.state);
		this.enterRule(_localctx, 98, CalculatorParser.RULE_expressionOrView);
		try {
			this.state = 609;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 42, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 607;
				this.expression(0);
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 608;
				this.reduceableViews();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public reducerFn(): ReducerFnContext {
		let _localctx: ReducerFnContext = new ReducerFnContext(this._ctx, this.state);
		this.enterRule(_localctx, 100, CalculatorParser.RULE_reducerFn);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 611;
			_la = this._input.LA(1);
			if (!(((((_la - 35)) & ~0x1F) === 0 && ((1 << (_la - 35)) & ((1 << (CalculatorParser.T__34 - 35)) | (1 << (CalculatorParser.T__35 - 35)) | (1 << (CalculatorParser.T__36 - 35)) | (1 << (CalculatorParser.T__37 - 35)) | (1 << (CalculatorParser.T__38 - 35)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public reduceableViews(): ReduceableViewsContext {
		let _localctx: ReduceableViewsContext = new ReduceableViewsContext(this._ctx, this.state);
		this.enterRule(_localctx, 102, CalculatorParser.RULE_reduceableViews);
		try {
			this.state = 618;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 43, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 613;
				this.dimDriverView();
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 614;
				this.driverFilterView();
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 615;
				this.objectSpecRef();
				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 616;
				this.submodelView();
				}
				break;

			case 5:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 617;
				this.extQueryRef();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timeRange(): TimeRangeContext {
		let _localctx: TimeRangeContext = new TimeRangeContext(this._ctx, this.state);
		this.enterRule(_localctx, 104, CalculatorParser.RULE_timeRange);
		try {
			this.state = 638;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.T__39:
				_localctx = new VariableRelativeTimeContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 620;
				this.match(CalculatorParser.T__39);
				this.state = 621;
				this.expression(0);
				this.state = 622;
				this.match(CalculatorParser.T__2);
				this.state = 623;
				this.expression(0);
				this.state = 624;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			case CalculatorParser.T__40:
				_localctx = new DateRangeContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 626;
				this.match(CalculatorParser.T__40);
				this.state = 627;
				this.dateRef();
				this.state = 628;
				this.match(CalculatorParser.T__2);
				this.state = 629;
				this.dateRef();
				this.state = 630;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			case CalculatorParser.T__41:
				_localctx = new CohortRelativeTimeContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 632;
				this.match(CalculatorParser.T__41);
				this.state = 633;
				this.expression(0);
				this.state = 634;
				this.match(CalculatorParser.T__2);
				this.state = 635;
				this.expression(0);
				this.state = 636;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public dateRef(): DateRefContext {
		let _localctx: DateRefContext = new DateRefContext(this._ctx, this.state);
		this.enterRule(_localctx, 106, CalculatorParser.RULE_dateRef);
		try {
			this.state = 662;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.DATE:
				_localctx = new DateContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 640;
				this.match(CalculatorParser.DATE);
				}
				break;
			case CalculatorParser.T__42:
				_localctx = new DateRelativeMonthsContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 641;
				this.match(CalculatorParser.T__42);
				this.state = 645;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
				case CalculatorParser.NUMBER:
					{
					this.state = 642;
					this.match(CalculatorParser.NUMBER);
					}
					break;
				case CalculatorParser.SUB:
					{
					this.state = 643;
					this.match(CalculatorParser.SUB);
					this.state = 644;
					this.match(CalculatorParser.NUMBER);
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				this.state = 647;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			case CalculatorParser.T__43:
				_localctx = new DateRelativeQuartersContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 648;
				this.match(CalculatorParser.T__43);
				this.state = 652;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
				case CalculatorParser.NUMBER:
					{
					this.state = 649;
					this.match(CalculatorParser.NUMBER);
					}
					break;
				case CalculatorParser.SUB:
					{
					this.state = 650;
					this.match(CalculatorParser.SUB);
					this.state = 651;
					this.match(CalculatorParser.NUMBER);
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				this.state = 654;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			case CalculatorParser.T__44:
				_localctx = new DateRelativeYearsContext(_localctx);
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 655;
				this.match(CalculatorParser.T__44);
				this.state = 659;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
				case CalculatorParser.NUMBER:
					{
					this.state = 656;
					this.match(CalculatorParser.NUMBER);
					}
					break;
				case CalculatorParser.SUB:
					{
					this.state = 657;
					this.match(CalculatorParser.SUB);
					this.state = 658;
					this.match(CalculatorParser.NUMBER);
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				this.state = 661;
				this.match(CalculatorParser.RPAREN);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public stringGroup(): StringGroupContext {
		let _localctx: StringGroupContext = new StringGroupContext(this._ctx, this.state);
		this.enterRule(_localctx, 108, CalculatorParser.RULE_stringGroup);
		let _la: number;
		try {
			this.state = 675;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case CalculatorParser.T__45:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 664;
				this.match(CalculatorParser.T__45);
				this.state = 665;
				this.match(CalculatorParser.STRING);
				this.state = 670;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === CalculatorParser.T__46) {
					{
					{
					this.state = 666;
					this.match(CalculatorParser.T__46);
					this.state = 667;
					this.match(CalculatorParser.STRING);
					}
					}
					this.state = 672;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 673;
				this.match(CalculatorParser.T__45);
				}
				break;
			case CalculatorParser.STRING:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 674;
				this.match(CalculatorParser.STRING);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public uuidGroup(): UuidGroupContext {
		let _localctx: UuidGroupContext = new UuidGroupContext(this._ctx, this.state);
		this.enterRule(_localctx, 110, CalculatorParser.RULE_uuidGroup);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 677;
			this.match(CalculatorParser.UUID);
			this.state = 682;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === CalculatorParser.T__46) {
				{
				{
				this.state = 678;
				this.match(CalculatorParser.T__46);
				this.state = 679;
				this.match(CalculatorParser.UUID);
				}
				}
				this.state = 684;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 3:
			return this.timestampExpression_sempred(_localctx as TimestampExpressionContext, predIndex);

		case 9:
			return this.booleanExpression_sempred(_localctx as BooleanExpressionContext, predIndex);

		case 10:
			return this.expression_sempred(_localctx as ExpressionContext, predIndex);

		case 12:
			return this.arrayExpression_sempred(_localctx as ArrayExpressionContext, predIndex);
		}
		return true;
	}
	private timestampExpression_sempred(_localctx: TimestampExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 5);

		case 1:
			return this.precpred(this._ctx, 4);
		}
		return true;
	}
	private booleanExpression_sempred(_localctx: BooleanExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 2:
			return this.precpred(this._ctx, 4);

		case 3:
			return this.precpred(this._ctx, 3);
		}
		return true;
	}
	private expression_sempred(_localctx: ExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 4:
			return this.precpred(this._ctx, 16);

		case 5:
			return this.precpred(this._ctx, 15);

		case 6:
			return this.precpred(this._ctx, 14);
		}
		return true;
	}
	private arrayExpression_sempred(_localctx: ArrayExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 7:
			return this.precpred(this._ctx, 2);
		}
		return true;
	}

	private static readonly _serializedATNSegments: number = 2;
	private static readonly _serializedATNSegment0: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03K\u02B0\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04" +
		"\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12\x04" +
		"\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17\x04" +
		"\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t\x1C\x04" +
		"\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t\"\x04#" +
		"\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x04(\t(\x04)\t)\x04*\t*\x04+\t+" +
		"\x04,\t,\x04-\t-\x04.\t.\x04/\t/\x040\t0\x041\t1\x042\t2\x043\t3\x044" +
		"\t4\x045\t5\x046\t6\x047\t7\x048\t8\x049\t9\x03\x02\x05\x02t\n\x02\x03" +
		"\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03\x03\x03\x03\x05\x03}\n\x03\x03" +
		"\x03\x03\x03\x03\x03\x03\x04\x05\x04\x83\n\x04\x03\x04\x03\x04\x03\x04" +
		"\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05" +
		"\x03\x05\x03\x05\x05\x05\x93\n\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03" +
		"\x05\x03\x05\x07\x05\x9B\n\x05\f\x05\x0E\x05\x9E\v\x05\x03\x06\x05\x06" +
		"\xA1\n\x06\x03\x06\x03\x06\x03\x06\x03\x07\x03\x07\x03\x07\x03\x07\x05" +
		"\x07\xAA\n\x07\x03\b\x03\b\x03\b\x03\b\x03\b\x05\b\xB1\n\b\x03\t\x03\t" +
		"\x03\t\x03\t\x03\t\x03\n\x03\n\x03\v\x03\v\x03\v\x03\v\x03\v\x03\v\x03" +
		"\v\x03\v\x03\v\x03\v\x03\v\x03\v\x03\v\x05\v\xC7\n\v\x03\v\x03\v\x03\v" +
		"\x03\v\x03\v\x03\v\x07\v\xCF\n\v\f\v\x0E\v\xD2\v\v\x03\f\x03\f\x03\f\x03" +
		"\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03" +
		"\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03" +
		"\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x03\f\x06" +
		"\f\xFB\n\f\r\f\x0E\f\xFC\x03\f\x05\f\u0100\n\f\x03\f\x03\f\x03\f\x03\f" +
		"\x03\f\x03\f\x03\f\x03\f\x03\f\x07\f\u010B\n\f\f\f\x0E\f\u010E\v\f\x03" +
		"\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03" +
		"\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x05\r\u0123\n\r\x03\r\x03\r\x05" +
		"\r\u0127\n\r\x03\r\x03\r\x05\r\u012B\n\r\x03\x0E\x03\x0E\x03\x0E\x03\x0E" +
		"\x03\x0E\x05\x0E\u0132\n\x0E\x03\x0E\x03\x0E\x03\x0E\x07\x0E\u0137\n\x0E" +
		"\f\x0E\x0E\x0E\u013A\v\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03" +
		"\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03" +
		"\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03" +
		"\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x05\x0F\u015A\n\x0F" +
		"\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x11" +
		"\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x12\x03\x12" +
		"\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x13\x03\x13\x03\x13" +
		"\x03\x13\x03\x13\x03\x13\x03\x14\x03\x14\x03\x14\x03\x14\x07\x14\u017E" +
		"\n\x14\f\x14\x0E\x14\u0181\v\x14\x03\x14\x03\x14\x03\x15\x03\x15\x03\x15" +
		"\x03\x15\x03\x15\x03\x15\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16" +
		"\x03\x17\x03\x17\x03\x17\x03\x17\x05\x17\u0195\n\x17\x03\x17\x03\x17\x03" +
		"\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x05\x18\u019F\n\x18\x03\x18" +
		"\x03\x18\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x1A\x03\x1A" +
		"\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B" +
		"\x03\x1B\x05\x1B\u01B5\n\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03" +
		"\x1B\x03\x1B\x03\x1B\x05\x1B\u01BF\n\x1B\x03\x1B\x03\x1B\x05\x1B\u01C3" +
		"\n\x1B\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1D\x03\x1D" +
		"\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E" +
		"\x03\x1E\x05\x1E\u01D7\n\x1E\x03\x1E\x03\x1E\x03\x1F\x03\x1F\x06\x1F\u01DD" +
		"\n\x1F\r\x1F\x0E\x1F\u01DE\x03\x1F\x03\x1F\x03 \x03 \x03 \x03 \x07 \u01E7" +
		"\n \f \x0E \u01EA\v \x03 \x03 \x03!\x03!\x03!\x07!\u01F1\n!\f!\x0E!\u01F4" +
		"\v!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x05!\u01FE\n!\x03!\x05!\u0201" +
		"\n!\x03\"\x03\"\x03\"\x03\"\x03#\x03#\x03#\x03#\x03#\x03#\x05#\u020D\n" +
		"#\x03$\x03$\x03$\x03$\x03$\x05$\u0214\n$\x03%\x03%\x03&\x03&\x03&\x03" +
		"\'\x03\'\x03(\x03(\x03(\x03(\x06(\u0221\n(\r(\x0E(\u0222\x03(\x03(\x03" +
		")\x03)\x03)\x05)\u022A\n)\x03)\x03)\x03*\x03*\x03+\x03+\x05+\u0232\n+" +
		"\x03,\x03,\x03,\x07,\u0237\n,\f,\x0E,\u023A\v,\x03-\x03-\x03-\x03-\x03" +
		".\x03.\x03.\x03.\x03/\x03/\x03/\x03/\x06/\u0248\n/\r/\x0E/\u0249\x03/" +
		"\x03/\x030\x030\x031\x031\x031\x031\x031\x031\x032\x032\x032\x032\x03" +
		"2\x072\u025B\n2\f2\x0E2\u025E\v2\x032\x032\x033\x033\x053\u0264\n3\x03" +
		"4\x034\x035\x035\x035\x035\x035\x055\u026D\n5\x036\x036\x036\x036\x03" +
		"6\x036\x036\x036\x036\x036\x036\x036\x036\x036\x036\x036\x036\x036\x05" +
		"6\u0281\n6\x037\x037\x037\x037\x037\x057\u0288\n7\x037\x037\x037\x037" +
		"\x037\x057\u028F\n7\x037\x037\x037\x037\x037\x057\u0296\n7\x037\x057\u0299" +
		"\n7\x038\x038\x038\x038\x078\u029F\n8\f8\x0E8\u02A2\v8\x038\x038\x058" +
		"\u02A6\n8\x039\x039\x039\x079\u02AB\n9\f9\x0E9\u02AE\v9\x039\x02\x02\x06" +
		"\b\x14\x16\x1A:\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10" +
		"\x02\x12\x02\x14\x02\x16\x02\x18\x02\x1A\x02\x1C\x02\x1E\x02 \x02\"\x02" +
		"$\x02&\x02(\x02*\x02,\x02.\x020\x022\x024\x026\x028\x02:\x02<\x02>\x02" +
		"@\x02B\x02D\x02F\x02H\x02J\x02L\x02N\x02P\x02R\x02T\x02V\x02X\x02Z\x02" +
		"\\\x02^\x02`\x02b\x02d\x02f\x02h\x02j\x02l\x02n\x02p\x02\x02\n\x03\x02" +
		":;\x03\x02\b\v\x03\x02<=\x04\x0233HH\x04\x0233?@\x04\x0222?@\x05\x024" +
		"4?@CC\x03\x02%)\x02\u02D9\x02s\x03\x02\x02\x02\x04x\x03\x02\x02\x02\x06" +
		"\x82\x03\x02\x02\x02\b\x92\x03\x02\x02\x02\n\xA0\x03\x02\x02\x02\f\xA9" +
		"\x03\x02\x02\x02\x0E\xB0\x03\x02\x02\x02\x10\xB2\x03\x02\x02\x02\x12\xB7" +
		"\x03\x02\x02\x02\x14\xC6\x03\x02\x02\x02\x16\xFF\x03\x02\x02\x02\x18\u012A" +
		"\x03\x02\x02\x02\x1A\u0131\x03\x02\x02\x02\x1C\u0159\x03\x02\x02\x02\x1E" +
		"\u015B\x03\x02\x02\x02 \u0163\x03\x02\x02\x02\"\u016B\x03\x02\x02\x02" +
		"$\u0173\x03\x02\x02\x02&\u0179\x03\x02\x02\x02(\u0184\x03\x02\x02\x02" +
		"*\u018A\x03\x02\x02\x02,\u0190\x03\x02\x02\x02.\u0198\x03\x02\x02\x02" +
		"0\u01A2\x03\x02\x02\x022\u01A8\x03\x02\x02\x024\u01C2\x03\x02\x02\x02" +
		"6\u01C4\x03\x02\x02\x028\u01CA\x03\x02\x02\x02:\u01D0\x03\x02\x02\x02" +
		"<\u01DA\x03\x02\x02\x02>\u01E2\x03\x02\x02\x02@\u0200\x03\x02\x02\x02" +
		"B\u0202\x03\x02\x02\x02D\u020C\x03\x02\x02\x02F\u0213\x03\x02\x02\x02" +
		"H\u0215\x03\x02\x02\x02J\u0217\x03\x02\x02\x02L\u021A\x03\x02\x02\x02" +
		"N\u021C\x03\x02\x02\x02P\u0226\x03\x02\x02\x02R\u022D\x03\x02\x02\x02" +
		"T\u0231\x03\x02\x02\x02V\u0233\x03\x02\x02\x02X\u023B\x03\x02\x02\x02" +
		"Z\u023F\x03\x02\x02\x02\\\u0243\x03\x02\x02\x02^\u024D\x03\x02\x02\x02" +
		"`\u024F\x03\x02\x02\x02b\u0255\x03\x02\x02\x02d\u0263\x03\x02\x02\x02" +
		"f\u0265\x03\x02\x02\x02h\u026C\x03\x02\x02\x02j\u0280\x03\x02\x02\x02" +
		"l\u0298\x03\x02\x02\x02n\u02A5\x03\x02\x02\x02p\u02A7\x03\x02\x02\x02" +
		"rt\x07\x03\x02\x02sr\x03\x02\x02\x02st\x03\x02\x02\x02tu\x03\x02\x02\x02" +
		"uv\x05\x16\f\x02vw\x07\x02\x02\x03w\x03\x03\x02\x02\x02xy\x07\x04\x02" +
		"\x02y|\x073\x02\x02z{\x07\x05\x02\x02{}\x05> \x02|z\x03\x02\x02\x02|}" +
		"\x03\x02\x02\x02}~\x03\x02\x02\x02~\x7F\x079\x02\x02\x7F\x80\x07\x02\x02" +
		"\x03\x80\x05\x03\x02\x02\x02\x81\x83\x07\x03\x02\x02\x82\x81\x03\x02\x02" +
		"\x02\x82\x83\x03\x02\x02\x02\x83\x84\x03\x02\x02\x02\x84\x85\x05\b\x05" +
		"\x02\x85\x86\x07\x02\x02\x03\x86\x07\x03\x02\x02\x02\x87\x88\b\x05\x01" +
		"\x02\x88\x93\x05\x0E\b\x02\x89\x8A\x07\x06\x02\x02\x8A\x8B\x05\b\x05\x02" +
		"\x8B\x8C\x079\x02\x02\x8C\x93\x03\x02\x02\x02\x8D\x8E\x07\x07\x02\x02" +
		"\x8E\x8F\x05\b\x05\x02\x8F\x90\x079\x02\x02\x90\x93\x03\x02\x02\x02\x91" +
		"\x93\x05\"\x12\x02\x92\x87\x03\x02\x02\x02\x92\x89\x03\x02\x02\x02\x92" +
		"\x8D\x03\x02\x02\x02\x92\x91\x03\x02\x02\x02\x93\x9C\x03\x02\x02\x02\x94" +
		"\x95\f\x07\x02\x02\x95\x96\t\x02\x02\x02\x96\x9B\x05\x16\f\x02\x97\x98" +
		"\f\x06\x02\x02\x98\x99\t\x02\x02\x02\x99\x9B\x05\x10\t\x02\x9A\x94\x03" +
		"\x02\x02\x02\x9A\x97\x03\x02\x02\x02\x9B\x9E\x03\x02\x02\x02\x9C\x9A\x03" +
		"\x02\x02\x02\x9C\x9D\x03\x02\x02\x02\x9D\t\x03\x02\x02\x02\x9E\x9C\x03" +
		"\x02\x02\x02\x9F\xA1\x07\x03\x02\x02\xA0\x9F\x03\x02\x02\x02\xA0\xA1\x03" +
		"\x02\x02\x02\xA1\xA2\x03\x02\x02\x02\xA2\xA3\x05\f\x07\x02\xA3\xA4\x07" +
		"\x02\x02\x03\xA4\v\x03\x02\x02\x02\xA5\xAA\x05.\x18\x02\xA6\xAA\x050\x19" +
		"\x02\xA7\xAA\x077\x02\x02\xA8\xAA\x05 \x11\x02\xA9\xA5\x03\x02\x02\x02" +
		"\xA9\xA6\x03\x02\x02\x02\xA9\xA7\x03\x02\x02\x02\xA9\xA8\x03\x02\x02\x02" +
		"\xAA\r\x03\x02\x02\x02\xAB\xB1\x05.\x18\x02\xAC\xB1\x050\x19\x02\xAD\xB1" +
		"\x05(\x15\x02\xAE\xB1\x05j6\x02\xAF\xB1\x077\x02\x02\xB0\xAB\x03\x02\x02" +
		"\x02\xB0\xAC\x03\x02\x02\x02\xB0\xAD\x03\x02\x02\x02\xB0\xAE\x03\x02\x02" +
		"\x02\xB0\xAF\x03\x02\x02\x02\xB1\x0F\x03\x02\x02\x02\xB2\xB3\x05\x12\n" +
		"\x02\xB3\xB4\x078\x02\x02\xB4\xB5\x05\x16\f\x02\xB5\xB6\x079\x02\x02\xB6" +
		"\x11\x03\x02\x02\x02\xB7\xB8\t\x03\x02\x02\xB8\x13\x03\x02\x02\x02\xB9" +
		"\xBA\b\v\x01\x02\xBA\xBB\x05\x16\f\x02\xBB\xBC\x07G\x02\x02\xBC\xBD\x05" +
		"\x16\f\x02\xBD\xC7\x03\x02\x02\x02\xBE\xBF\x05\b\x05\x02\xBF\xC0\x07G" +
		"\x02\x02\xC0\xC1\x05\b\x05\x02\xC1\xC7\x03\x02\x02\x02\xC2\xC3\x05\f\x07" +
		"\x02\xC3\xC4\x07G\x02\x02\xC4\xC5\x05\f\x07\x02\xC5\xC7\x03\x02\x02\x02" +
		"\xC6\xB9\x03\x02\x02\x02\xC6\xBE\x03\x02\x02\x02\xC6\xC2\x03\x02\x02\x02" +
		"\xC7\xD0\x03\x02\x02\x02\xC8\xC9\f\x06\x02\x02\xC9\xCA\x07\f\x02\x02\xCA" +
		"\xCF\x05\x14\v\x07\xCB\xCC\f\x05\x02\x02\xCC\xCD\x07\r\x02\x02\xCD\xCF" +
		"\x05\x14\v\x06\xCE\xC8\x03\x02\x02\x02\xCE\xCB\x03\x02\x02\x02\xCF\xD2" +
		"\x03\x02\x02\x02\xD0\xCE\x03\x02\x02\x02\xD0\xD1\x03\x02\x02\x02\xD1\x15" +
		"\x03\x02\x02\x02\xD2\xD0\x03\x02\x02\x02\xD3\xD4\b\f\x01\x02\xD4\u0100" +
		"\x074\x02\x02\xD5\u0100\x07?\x02\x02\xD6\u0100\x05(\x15\x02\xD7\u0100" +
		"\x058\x1D\x02\xD8\u0100\x05b2\x02\xD9\u0100\x05\x1C\x0F\x02\xDA\xDB\x07" +
		";\x02\x02\xDB\u0100\x05\x16\f\x14\xDC\xDD\x078\x02\x02\xDD\xDE\x05\x16" +
		"\f\x02\xDE\xDF\x079\x02\x02\xDF\u0100\x03\x02\x02\x02\xE0\xE1\x07D\x02" +
		"\x02\xE1\xE2\x078\x02\x02\xE2\xE3\x05\x16\f\x02\xE3\xE4\x079\x02\x02\xE4" +
		"\u0100\x03\x02\x02\x02\xE5\xE6\x07E\x02\x02\xE6\xE7\x078\x02\x02\xE7\xE8" +
		"\x05\x16\f\x02\xE8\xE9\x079\x02\x02\xE9\u0100\x03\x02\x02\x02\xEA\xEB" +
		"\x07D\x02\x02\xEB\xEC\x078\x02\x02\xEC\xED\x05\x16\f\x02\xED\xEE\x07\x05" +
		"\x02\x02\xEE\xEF\x05\x16\f\x02\xEF\xF0\x079\x02\x02\xF0\u0100\x03\x02" +
		"\x02\x02\xF1\u0100\x05\x1E\x10\x02\xF2\u0100\x05$\x13\x02\xF3\u0100\x05" +
		"&\x14\x02\xF4\u0100\x05.\x18\x02\xF5\u0100\x050\x19\x02\xF6\u0100\x05" +
		"N(\x02\xF7\u0100\x05P)\x02\xF8\u0100\x05\x18\r\x02\xF9\xFB\x07K\x02\x02" +
		"\xFA\xF9\x03\x02\x02\x02\xFB\xFC\x03\x02\x02\x02\xFC\xFA\x03\x02\x02\x02" +
		"\xFC\xFD\x03\x02\x02\x02\xFD\u0100\x03\x02\x02\x02\xFE\u0100\x05\x0E\b" +
		"\x02\xFF\xD3\x03\x02\x02\x02\xFF\xD5\x03\x02\x02\x02\xFF\xD6\x03\x02\x02" +
		"\x02\xFF\xD7\x03\x02\x02\x02\xFF\xD8\x03\x02\x02\x02\xFF\xD9\x03\x02\x02" +
		"\x02\xFF\xDA\x03\x02\x02\x02\xFF\xDC\x03\x02\x02\x02\xFF\xE0\x03\x02\x02" +
		"\x02\xFF\xE5\x03\x02\x02\x02\xFF\xEA\x03\x02\x02\x02\xFF\xF1\x03\x02\x02" +
		"\x02\xFF\xF2\x03\x02\x02\x02\xFF\xF3\x03\x02\x02\x02\xFF\xF4\x03\x02\x02" +
		"\x02\xFF\xF5\x03\x02\x02\x02\xFF\xF6\x03\x02\x02\x02\xFF\xF7\x03\x02\x02" +
		"\x02\xFF\xF8\x03\x02\x02\x02\xFF\xFA\x03\x02\x02\x02\xFF\xFE\x03\x02\x02" +
		"\x02\u0100\u010C\x03\x02\x02\x02\u0101\u0102\f\x12\x02\x02\u0102\u0103" +
		"\x07>\x02\x02\u0103\u010B\x05\x16\f\x13\u0104\u0105\f\x11\x02\x02\u0105" +
		"\u0106\t\x04\x02\x02\u0106\u010B\x05\x16\f\x12\u0107\u0108\f\x10\x02\x02" +
		"\u0108\u0109\t\x02\x02\x02\u0109\u010B\x05\x16\f\x11\u010A\u0101\x03\x02" +
		"\x02\x02\u010A\u0104\x03\x02\x02\x02\u010A\u0107\x03\x02\x02\x02\u010B" +
		"\u010E\x03\x02\x02\x02\u010C\u010A\x03\x02\x02\x02\u010C\u010D\x03\x02" +
		"\x02\x02\u010D\x17\x03\x02\x02\x02\u010E\u010C\x03\x02\x02\x02\u010F\u0110" +
		"\x07\x0E\x02\x02\u0110\u0111\x078\x02\x02\u0111\u012B\x079\x02\x02\u0112" +
		"\u0113\x07\x0F\x02\x02\u0113\u0114\x078\x02\x02\u0114\u0115\x05\b\x05" +
		"\x02\u0115\u0116\x07\x05\x02\x02\u0116\u0117\x05\b\x05\x02\u0117\u0118" +
		"\x07\x05\x02\x02\u0118\u0119\x077\x02\x02\u0119\u011A\x079\x02\x02\u011A" +
		"\u012B\x03\x02\x02\x02\u011B\u011C\x07\x10\x02\x02\u011C\u011D\x078\x02" +
		"\x02\u011D\u011E\x05\b\x05\x02\u011E\u011F\x07\x05\x02\x02\u011F\u0122" +
		"\x05\b\x05\x02\u0120\u0121\x07\x05\x02\x02\u0121\u0123\x077\x02\x02\u0122" +
		"\u0120\x03\x02\x02\x02\u0122\u0123\x03\x02\x02\x02\u0123\u0126\x03\x02" +
		"\x02\x02\u0124\u0125\x07\x05\x02\x02\u0125\u0127\x077\x02\x02\u0126\u0124" +
		"\x03\x02\x02\x02\u0126\u0127\x03\x02\x02\x02\u0127\u0128\x03\x02\x02\x02" +
		"\u0128\u0129\x079\x02\x02\u0129\u012B\x03\x02\x02\x02\u012A\u010F\x03" +
		"\x02\x02\x02\u012A\u0112\x03\x02\x02\x02\u012A\u011B\x03\x02\x02\x02\u012B" +
		"\x19\x03\x02\x02\x02\u012C\u012D\b\x0E\x01\x02\u012D\u0132\x054\x1B\x02" +
		"\u012E\u012F\x074\x02\x02\u012F\u0130\t\x02\x02\x02\u0130\u0132\x05\x1A" +
		"\x0E\x03\u0131\u012C\x03\x02\x02\x02\u0131\u012E\x03\x02\x02\x02\u0132" +
		"\u0138\x03\x02\x02\x02\u0133\u0134\f\x04\x02\x02\u0134\u0135\t\x02\x02" +
		"\x02\u0135\u0137\x074\x02\x02\u0136\u0133\x03\x02\x02\x02\u0137\u013A" +
		"\x03\x02\x02\x02\u0138\u0136\x03\x02\x02\x02\u0138\u0139\x03\x02\x02\x02" +
		"\u0139\x1B\x03\x02\x02\x02\u013A\u0138\x03\x02\x02\x02\u013B\u013C\x07" +
		"\x11\x02\x02\u013C\u013D\x05(\x15\x02\u013D\u013E\x07\x05\x02\x02\u013E" +
		"\u013F\x05(\x15\x02\u013F\u0140\x079\x02\x02\u0140\u015A\x03\x02\x02\x02" +
		"\u0141\u0142\x07\x11\x02\x02\u0142\u0143\x05*\x16\x02\u0143\u0144\x07" +
		"\x05\x02\x02\u0144\u0145\x05*\x16\x02\u0145\u0146\x079\x02\x02\u0146\u015A" +
		"\x03\x02\x02\x02\u0147\u0148\x07\x11\x02\x02\u0148\u0149\x05*\x16\x02" +
		"\u0149\u014A\x07\x05\x02\x02\u014A\u014B\x05(\x15\x02\u014B\u014C\x07" +
		"9\x02\x02\u014C\u015A\x03\x02\x02\x02\u014D\u014E\x07\x11\x02\x02\u014E" +
		"\u014F\x05(\x15\x02\u014F\u0150\x07\x05\x02\x02\u0150\u0151\x05*\x16\x02" +
		"\u0151\u0152\x079\x02\x02\u0152\u015A\x03\x02\x02\x02\u0153\u0154\x07" +
		"\x11\x02\x02\u0154\u0155\x05\x1A\x0E\x02\u0155\u0156\x07\x05\x02\x02\u0156" +
		"\u0157\x05\x1A\x0E\x02\u0157\u0158\x079\x02\x02\u0158\u015A\x03\x02\x02" +
		"\x02\u0159\u013B\x03\x02\x02\x02\u0159\u0141\x03\x02\x02\x02\u0159\u0147" +
		"\x03\x02\x02\x02\u0159\u014D\x03\x02\x02\x02\u0159\u0153\x03\x02\x02\x02" +
		"\u015A\x1D\x03\x02\x02\x02\u015B\u015C\x07\x12\x02\x02\u015C\u015D\x05" +
		"\x14\v\x02\u015D\u015E\x07\x05\x02\x02\u015E\u015F\x05\x16\f\x02\u015F" +
		"\u0160\x07\x05\x02\x02\u0160\u0161\x05\x16\f\x02\u0161\u0162\x079\x02" +
		"\x02\u0162\x1F\x03\x02\x02\x02\u0163\u0164\x07\x12\x02\x02\u0164\u0165" +
		"\x05\x14\v\x02\u0165\u0166\x07\x05\x02\x02\u0166\u0167\x05\f\x07\x02\u0167" +
		"\u0168\x07\x05\x02\x02\u0168\u0169\x05\f\x07\x02\u0169\u016A\x079\x02" +
		"\x02\u016A!\x03\x02\x02\x02\u016B\u016C\x07\x12\x02\x02\u016C\u016D\x05" +
		"\x14\v\x02\u016D\u016E\x07\x05\x02\x02\u016E\u016F\x05\b\x05\x02\u016F" +
		"\u0170\x07\x05\x02\x02\u0170\u0171\x05\b\x05\x02\u0171\u0172\x079\x02" +
		"\x02\u0172#\x03\x02\x02\x02\u0173\u0174\x07\x13\x02\x02\u0174\u0175\x05" +
		"\x16\f\x02\u0175\u0176\x07\x05\x02\x02\u0176\u0177\x05\x16\f\x02\u0177" +
		"\u0178\x079\x02\x02\u0178%\x03\x02\x02\x02\u0179\u017A\x07\x14\x02\x02" +
		"\u017A\u017F\x05\x16\f\x02\u017B\u017C\x07\x05\x02\x02\u017C\u017E\x05" +
		"\x16\f\x02\u017D\u017B\x03\x02\x02\x02\u017E\u0181\x03\x02\x02\x02\u017F" +
		"\u017D\x03\x02\x02\x02\u017F\u0180\x03\x02\x02\x02\u0180\u0182\x03\x02" +
		"\x02\x02\u0181\u017F\x03\x02\x02\x02\u0182\u0183\x079\x02\x02\u0183\'" +
		"\x03\x02\x02\x02\u0184\u0185\x07\x15\x02\x02\u0185\u0186\x073\x02\x02" +
		"\u0186\u0187\x07\x05\x02\x02\u0187\u0188\x05j6\x02\u0188\u0189\x079\x02" +
		"\x02\u0189)\x03\x02\x02\x02\u018A\u018B\x07\x16\x02\x02\u018B\u018C\x07" +
		"3\x02\x02\u018C\u018D\x07\x05\x02\x02\u018D\u018E\x05j6\x02\u018E\u018F" +
		"\x079\x02\x02\u018F+\x03\x02\x02\x02\u0190\u0191\x07\x17\x02\x02\u0191" +
		"\u0194\x073\x02\x02\u0192\u0193\x07\x05\x02\x02\u0193\u0195\x05j6\x02" +
		"\u0194\u0192\x03\x02\x02\x02\u0194\u0195\x03\x02\x02\x02\u0195\u0196\x03" +
		"\x02\x02\x02\u0196\u0197\x079\x02\x02\u0197-\x03\x02\x02\x02\u0198\u0199" +
		"\x07\x18\x02\x02\u0199\u019A\t\x05\x02\x02\u019A\u019B\x07\x05\x02\x02" +
		"\u019B\u019E\x056\x1C\x02\u019C\u019D\x07\x05\x02\x02\u019D\u019F\x05" +
		"> \x02\u019E\u019C\x03\x02\x02\x02\u019E\u019F\x03\x02\x02\x02\u019F\u01A0" +
		"\x03\x02\x02\x02\u01A0\u01A1\x079\x02\x02\u01A1/\x03\x02\x02\x02\u01A2" +
		"\u01A3\x07\x19\x02\x02\u01A3\u01A4\x07I\x02\x02\u01A4\u01A5\x07\x05\x02" +
		"\x02\u01A5\u01A6\x052\x1A\x02\u01A6\u01A7\x079\x02\x02\u01A71\x03\x02" +
		"\x02\x02\u01A8\u01A9\x07\x1A\x02\x02\u01A9\u01AA\x07I\x02\x02\u01AA\u01AB" +
		"\x07\x05\x02\x02\u01AB\u01AC\x05j6\x02\u01AC\u01AD\x079\x02\x02\u01AD" +
		"3\x03\x02\x02\x02\u01AE\u01AF\x07\x04\x02\x02\u01AF\u01B0\x073\x02\x02" +
		"\u01B0\u01B1\x07\x05\x02\x02\u01B1\u01B4\x056\x1C\x02\u01B2\u01B3\x07" +
		"\x05\x02\x02\u01B3\u01B5\x05> \x02\u01B4\u01B2\x03\x02\x02\x02\u01B4\u01B5" +
		"\x03\x02\x02\x02\u01B5\u01B6\x03\x02\x02\x02\u01B6\u01B7\x079\x02\x02" +
		"\u01B7\u01C3\x03\x02\x02\x02\u01B8\u01B9\x07\x04\x02\x02\u01B9\u01BA\x07" +
		"3\x02\x02\u01BA\u01BB\x07\x05\x02\x02\u01BB\u01BE\x05j6\x02\u01BC\u01BD" +
		"\x07\x05\x02\x02\u01BD\u01BF\x05> \x02\u01BE\u01BC\x03\x02\x02\x02\u01BE" +
		"\u01BF\x03\x02\x02\x02\u01BF\u01C0\x03\x02\x02\x02\u01C0\u01C1\x079\x02" +
		"\x02\u01C1\u01C3\x03\x02\x02\x02\u01C2\u01AE\x03\x02\x02\x02\u01C2\u01B8" +
		"\x03\x02\x02\x02\u01C35\x03\x02\x02\x02\u01C4\u01C5\x07\x1B\x02\x02\u01C5" +
		"\u01C6\x073\x02\x02\u01C6\u01C7\x07\x05\x02\x02\u01C7\u01C8\x05j6\x02" +
		"\u01C8\u01C9\x079\x02\x02\u01C97\x03\x02\x02\x02\u01CA\u01CB\x07\x1C\x02" +
		"\x02\u01CB\u01CC\x073\x02\x02\u01CC\u01CD\x07\x05\x02\x02\u01CD\u01CE" +
		"\x05j6\x02\u01CE\u01CF\x079\x02\x02\u01CF9\x03\x02\x02\x02\u01D0\u01D1" +
		"\x07\x1D\x02\x02\u01D1\u01D2\x073\x02\x02\u01D2\u01D3\x07\x05\x02\x02" +
		"\u01D3\u01D6\x05j6\x02\u01D4\u01D5\x07\x05\x02\x02\u01D5\u01D7\x05<\x1F" +
		"\x02\u01D6\u01D4\x03\x02\x02\x02\u01D6\u01D7\x03\x02\x02\x02\u01D7\u01D8" +
		"\x03\x02\x02\x02\u01D8\u01D9\x079\x02\x02\u01D9;\x03\x02\x02\x02\u01DA" +
		"\u01DC\x07\x1E\x02\x02\u01DB\u01DD\x05F$\x02\u01DC\u01DB\x03\x02\x02\x02" +
		"\u01DD\u01DE\x03\x02\x02\x02\u01DE\u01DC\x03\x02\x02\x02\u01DE\u01DF\x03" +
		"\x02\x02\x02\u01DF\u01E0\x03\x02\x02\x02\u01E0\u01E1\x079\x02\x02\u01E1" +
		"=\x03\x02\x02\x02\u01E2\u01E3\x07\x1E\x02\x02\u01E3\u01E8\x05@!\x02\u01E4" +
		"\u01E5\x07\f\x02\x02\u01E5\u01E7\x05@!\x02\u01E6\u01E4\x03\x02\x02\x02" +
		"\u01E7\u01EA\x03\x02\x02\x02\u01E8\u01E6\x03\x02\x02\x02\u01E8\u01E9\x03" +
		"\x02\x02\x02\u01E9\u01EB\x03\x02\x02\x02\u01EA\u01E8\x03\x02\x02\x02\u01EB" +
		"\u01EC\x079\x02\x02\u01EC?\x03\x02\x02\x02\u01ED\u01F2\x073\x02\x02\u01EE" +
		"\u01EF\x07\x05\x02\x02\u01EF\u01F1\x073\x02\x02\u01F0\u01EE\x03\x02\x02" +
		"\x02\u01F1\u01F4\x03\x02\x02\x02\u01F2\u01F0\x03\x02\x02\x02\u01F2\u01F3" +
		"\x03\x02\x02\x02\u01F3\u0201\x03\x02\x02\x02\u01F4\u01F2\x03\x02\x02\x02" +
		"\u01F5\u01F6\x056\x1C\x02\u01F6\u01FD\x07G\x02\x02\u01F7\u01FE\x05n8\x02" +
		"\u01F8\u01FE\x05p9\x02\u01F9\u01FE\x05j6\x02\u01FA\u01FE\x05V,\x02\u01FB" +
		"\u01FE\x05X-\x02\u01FC\u01FE\x07?\x02\x02\u01FD\u01F7\x03\x02\x02\x02" +
		"\u01FD\u01F8\x03\x02\x02\x02\u01FD\u01F9\x03\x02\x02\x02\u01FD\u01FA\x03" +
		"\x02\x02\x02\u01FD\u01FB\x03\x02\x02\x02\u01FD\u01FC\x03\x02\x02\x02\u01FE" +
		"\u0201\x03\x02\x02\x02\u01FF\u0201\x05R*\x02\u0200\u01ED\x03\x02\x02\x02" +
		"\u0200\u01F5\x03\x02\x02\x02\u0200\u01FF\x03\x02\x02\x02\u0201A\x03\x02" +
		"\x02\x02\u0202\u0203\x073\x02\x02\u0203\u0204\x07\x1F\x02\x02\u0204\u0205" +
		"\t\x06\x02\x02\u0205C\x03\x02\x02\x02\u0206\u0207\x07A\x02\x02\u0207\u0208" +
		"\x07\x1F\x02\x02\u0208\u020D\t\x07\x02\x02\u0209\u020A\x07B\x02\x02\u020A" +
		"\u020B\x07\x1F\x02\x02\u020B\u020D\t\b\x02\x02\u020C\u0206\x03\x02\x02" +
		"\x02\u020C\u0209\x03\x02\x02\x02\u020DE\x03\x02\x02\x02\u020E\u0214\x05" +
		"D#\x02\u020F\u0214\x05B\"\x02\u0210\u0214\x05H%\x02\u0211\u0214\x05R*" +
		"\x02\u0212\u0214\x05X-\x02\u0213\u020E\x03\x02\x02\x02\u0213\u020F\x03" +
		"\x02\x02\x02\u0213\u0210\x03\x02\x02\x02\u0213\u0211\x03\x02\x02\x02\u0213" +
		"\u0212\x03\x02\x02\x02\u0214G\x03\x02\x02\x02\u0215\u0216\x05T+\x02\u0216" +
		"I\x03\x02\x02\x02\u0217\u0218\x07 \x02\x02\u0218\u0219\x073\x02\x02\u0219" +
		"K\x03\x02\x02\x02\u021A\u021B\x05J&\x02\u021BM\x03\x02\x02\x02\u021C\u021D" +
		"\x07\x1E\x02\x02\u021D\u021E\x05^0\x02\u021E\u0220\x07\x05\x02\x02\u021F" +
		"\u0221\x05F$\x02\u0220\u021F\x03\x02\x02\x02\u0221\u0222\x03\x02\x02\x02" +
		"\u0222\u0220";
	private static readonly _serializedATNSegment1: string =
		"\x03\x02\x02\x02\u0222\u0223\x03\x02\x02\x02\u0223\u0224\x03\x02\x02\x02" +
		"\u0224\u0225\x079\x02\x02\u0225O\x03\x02\x02\x02\u0226\u0229\x07!\x02" +
		"\x02\u0227\u022A\x05N(\x02\u0228\u022A\x054\x1B\x02\u0229\u0227\x03\x02" +
		"\x02\x02\u0229\u0228\x03\x02\x02\x02\u022A\u022B\x03\x02\x02\x02\u022B" +
		"\u022C\x079\x02\x02\u022CQ\x03\x02\x02\x02\u022D\u022E\x07\"\x02\x02\u022E" +
		"S\x03\x02\x02\x02\u022F\u0232\x05Z.\x02\u0230\u0232\x073\x02\x02\u0231" +
		"\u022F\x03\x02\x02\x02\u0231\u0230\x03\x02\x02\x02\u0232U\x03\x02\x02" +
		"\x02\u0233\u0238\x05T+\x02\u0234\u0235\x07\x05\x02\x02\u0235\u0237\x05" +
		"T+\x02\u0236\u0234\x03\x02\x02\x02\u0237\u023A\x03\x02\x02\x02\u0238\u0236" +
		"\x03\x02\x02\x02\u0238\u0239\x03\x02\x02\x02\u0239W\x03\x02\x02\x02\u023A" +
		"\u0238\x03\x02\x02\x02\u023B\u023C\x07#\x02\x02\u023C\u023D\x05P)\x02" +
		"\u023D\u023E\x079\x02\x02\u023EY\x03\x02\x02\x02\u023F\u0240\x07$\x02" +
		"\x02\u0240\u0241\x073\x02\x02\u0241\u0242\x079\x02\x02\u0242[\x03\x02" +
		"\x02\x02\u0243\u0244\x07\x1E\x02\x02\u0244\u0245\x05^0\x02\u0245\u0247" +
		"\x07\x05\x02\x02\u0246\u0248\x05F$\x02\u0247\u0246\x03\x02\x02\x02\u0248" +
		"\u0249\x03\x02\x02\x02\u0249\u0247\x03\x02\x02\x02\u0249\u024A\x03\x02" +
		"\x02\x02\u024A\u024B\x03\x02\x02\x02\u024B\u024C\x079\x02\x02\u024C]\x03" +
		"\x02\x02\x02\u024D\u024E\x05*\x16\x02\u024E_\x03\x02\x02\x02\u024F\u0250" +
		"\x07\x1E\x02\x02\u0250\u0251\x05,\x17\x02\u0251\u0252\x07\x05\x02\x02" +
		"\u0252\u0253\x05L\'\x02\u0253\u0254\x079\x02\x02\u0254a\x03\x02\x02\x02" +
		"\u0255\u0256\x05f4\x02\u0256\u0257\x078\x02\x02\u0257\u025C\x05d3\x02" +
		"\u0258\u0259\x07\x05\x02\x02\u0259\u025B\x05d3\x02\u025A\u0258\x03\x02" +
		"\x02\x02\u025B\u025E\x03\x02\x02\x02\u025C\u025A\x03\x02\x02\x02\u025C" +
		"\u025D\x03\x02\x02\x02\u025D\u025F\x03\x02\x02\x02\u025E\u025C\x03\x02" +
		"\x02\x02\u025F\u0260\x079\x02\x02\u0260c\x03\x02\x02\x02\u0261\u0264\x05" +
		"\x16\f\x02\u0262\u0264\x05h5\x02\u0263\u0261\x03\x02\x02\x02\u0263\u0262" +
		"\x03\x02\x02\x02\u0264e\x03\x02\x02\x02\u0265\u0266\t\t\x02\x02\u0266" +
		"g\x03\x02\x02\x02\u0267\u026D\x05^0\x02\u0268\u026D\x05N(\x02\u0269\u026D" +
		"\x054\x1B\x02\u026A\u026D\x05`1\x02\u026B\u026D\x05:\x1E\x02\u026C\u0267" +
		"\x03\x02\x02\x02\u026C\u0268\x03\x02\x02\x02\u026C\u0269\x03\x02\x02\x02" +
		"\u026C\u026A\x03\x02\x02\x02\u026C\u026B\x03\x02\x02\x02\u026Di\x03\x02" +
		"\x02\x02\u026E\u026F\x07*\x02\x02\u026F\u0270\x05\x16\f\x02\u0270\u0271" +
		"\x07\x05\x02\x02\u0271\u0272\x05\x16\f\x02\u0272\u0273\x079\x02\x02\u0273" +
		"\u0281\x03\x02\x02\x02\u0274\u0275\x07+\x02\x02\u0275\u0276\x05l7\x02" +
		"\u0276\u0277\x07\x05\x02\x02\u0277\u0278\x05l7\x02\u0278\u0279\x079\x02" +
		"\x02\u0279\u0281\x03\x02\x02\x02\u027A\u027B\x07,\x02\x02\u027B\u027C" +
		"\x05\x16\f\x02\u027C\u027D\x07\x05\x02\x02\u027D\u027E\x05\x16\f\x02\u027E" +
		"\u027F\x079\x02\x02\u027F\u0281\x03\x02\x02\x02\u0280\u026E\x03\x02\x02" +
		"\x02\u0280\u0274\x03\x02\x02\x02\u0280\u027A\x03\x02\x02\x02\u0281k\x03" +
		"\x02\x02\x02\u0282\u0299\x072\x02\x02\u0283\u0287\x07-\x02\x02\u0284\u0288" +
		"\x074\x02\x02\u0285\u0286\x07;\x02\x02\u0286\u0288\x074\x02\x02\u0287" +
		"\u0284\x03\x02\x02\x02\u0287\u0285\x03\x02\x02\x02\u0288\u0289\x03\x02" +
		"\x02\x02\u0289\u0299\x079\x02\x02\u028A\u028E\x07.\x02\x02\u028B\u028F" +
		"\x074\x02\x02\u028C\u028D\x07;\x02\x02\u028D\u028F\x074\x02\x02\u028E" +
		"\u028B\x03\x02\x02\x02\u028E\u028C\x03\x02\x02\x02\u028F\u0290\x03\x02" +
		"\x02\x02\u0290\u0299\x079\x02\x02\u0291\u0295\x07/\x02\x02\u0292\u0296" +
		"\x074\x02\x02\u0293\u0294\x07;\x02\x02\u0294\u0296\x074\x02\x02\u0295" +
		"\u0292\x03\x02\x02\x02\u0295\u0293\x03\x02\x02\x02\u0296\u0297\x03\x02" +
		"\x02\x02\u0297\u0299\x079\x02\x02\u0298\u0282\x03\x02\x02\x02\u0298\u0283" +
		"\x03\x02\x02\x02\u0298\u028A\x03\x02\x02\x02\u0298\u0291\x03\x02\x02\x02" +
		"\u0299m\x03\x02\x02\x02\u029A\u029B\x070\x02\x02\u029B\u02A0\x077\x02" +
		"\x02\u029C\u029D\x071\x02\x02\u029D\u029F\x077\x02\x02\u029E\u029C\x03" +
		"\x02\x02\x02\u029F\u02A2\x03\x02\x02\x02\u02A0\u029E\x03\x02\x02\x02\u02A0" +
		"\u02A1\x03\x02\x02\x02\u02A1\u02A3\x03\x02\x02\x02\u02A2\u02A0\x03\x02" +
		"\x02\x02\u02A3\u02A6\x070\x02\x02\u02A4\u02A6\x077\x02\x02\u02A5\u029A" +
		"\x03\x02\x02\x02\u02A5\u02A4\x03\x02\x02\x02\u02A6o\x03\x02\x02\x02\u02A7" +
		"\u02AC\x073\x02\x02\u02A8\u02A9\x071\x02\x02\u02A9\u02AB\x073\x02\x02" +
		"\u02AA\u02A8\x03\x02\x02\x02\u02AB\u02AE\x03\x02\x02\x02\u02AC\u02AA\x03" +
		"\x02\x02\x02\u02AC\u02AD\x03\x02\x02\x02\u02ADq\x03\x02\x02\x02\u02AE" +
		"\u02AC\x03\x02\x02\x026s|\x82\x92\x9A\x9C\xA0\xA9\xB0\xC6\xCE\xD0\xFC" +
		"\xFF\u010A\u010C\u0122\u0126\u012A\u0131\u0138\u0159\u017F\u0194\u019E" +
		"\u01B4\u01BE\u01C2\u01D6\u01DE\u01E8\u01F2\u01FD\u0200\u020C\u0213\u0222" +
		"\u0229\u0231\u0238\u0249\u025C\u0263\u026C\u0280\u0287\u028E\u0295\u0298" +
		"\u02A0\u02A5\u02AC";
	public static readonly _serializedATN: string = Utils.join(
		[
			CalculatorParser._serializedATNSegment0,
			CalculatorParser._serializedATNSegment1,
		],
		"",
	);
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!CalculatorParser.__ATN) {
			CalculatorParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(CalculatorParser._serializedATN));
		}

		return CalculatorParser.__ATN;
	}

}

export class CalculatorContext extends ParserRuleContext {
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public EOF(): TerminalNode { return this.getToken(CalculatorParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_calculator; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterCalculator) {
			listener.enterCalculator(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitCalculator) {
			listener.exitCalculator(this);
		}
	}
}


export class BlockFilterContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public EOF(): TerminalNode { return this.getToken(CalculatorParser.EOF, 0); }
	public objectFilterView(): ObjectFilterViewContext | undefined {
		return this.tryGetRuleContext(0, ObjectFilterViewContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_blockFilter; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterBlockFilter) {
			listener.enterBlockFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitBlockFilter) {
			listener.exitBlockFilter(this);
		}
	}
}


export class TimestampCalculatorContext extends ParserRuleContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public EOF(): TerminalNode { return this.getToken(CalculatorParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_timestampCalculator; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterTimestampCalculator) {
			listener.enterTimestampCalculator(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitTimestampCalculator) {
			listener.exitTimestampCalculator(this);
		}
	}
}


export class TimestampExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_timestampExpression; }
	public copyFrom(ctx: TimestampExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class SingleTimestampContext extends TimestampExpressionContext {
	public timestampValue(): TimestampValueContext {
		return this.getRuleContext(0, TimestampValueContext);
	}
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterSingleTimestamp) {
			listener.enterSingleTimestamp(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitSingleTimestamp) {
			listener.exitSingleTimestamp(this);
		}
	}
}
export class AddSubTimestampContext extends TimestampExpressionContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public ADD(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.ADD, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.SUB, 0); }
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterAddSubTimestamp) {
			listener.enterAddSubTimestamp(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitAddSubTimestamp) {
			listener.exitAddSubTimestamp(this);
		}
	}
}
export class AddSubDurationContext extends TimestampExpressionContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public durationValue(): DurationValueContext {
		return this.getRuleContext(0, DurationValueContext);
	}
	public ADD(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.ADD, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.SUB, 0); }
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterAddSubDuration) {
			listener.enterAddSubDuration(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitAddSubDuration) {
			listener.exitAddSubDuration(this);
		}
	}
}
export class EndOfMonthContext extends TimestampExpressionContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterEndOfMonth) {
			listener.enterEndOfMonth(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitEndOfMonth) {
			listener.exitEndOfMonth(this);
		}
	}
}
export class StartOfMonthContext extends TimestampExpressionContext {
	public timestampExpression(): TimestampExpressionContext {
		return this.getRuleContext(0, TimestampExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterStartOfMonth) {
			listener.enterStartOfMonth(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitStartOfMonth) {
			listener.exitStartOfMonth(this);
		}
	}
}
export class TimestampConditionalContext extends TimestampExpressionContext {
	public timestampIf(): TimestampIfContext {
		return this.getRuleContext(0, TimestampIfContext);
	}
	constructor(ctx: TimestampExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterTimestampConditional) {
			listener.enterTimestampConditional(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitTimestampConditional) {
			listener.exitTimestampConditional(this);
		}
	}
}


export class StringCalculatorContext extends ParserRuleContext {
	public stringExpression(): StringExpressionContext {
		return this.getRuleContext(0, StringExpressionContext);
	}
	public EOF(): TerminalNode { return this.getToken(CalculatorParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_stringCalculator; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterStringCalculator) {
			listener.enterStringCalculator(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitStringCalculator) {
			listener.exitStringCalculator(this);
		}
	}
}


export class StringExpressionContext extends ParserRuleContext {
	public objectRef(): ObjectRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectRefContext);
	}
	public extObjectRef(): ExtObjectRefContext | undefined {
		return this.tryGetRuleContext(0, ExtObjectRefContext);
	}
	public STRING(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.STRING, 0); }
	public stringIf(): StringIfContext | undefined {
		return this.tryGetRuleContext(0, StringIfContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_stringExpression; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterStringExpression) {
			listener.enterStringExpression(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitStringExpression) {
			listener.exitStringExpression(this);
		}
	}
}


export class TimestampValueContext extends ParserRuleContext {
	public objectRef(): ObjectRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectRefContext);
	}
	public extObjectRef(): ExtObjectRefContext | undefined {
		return this.tryGetRuleContext(0, ExtObjectRefContext);
	}
	public driverRef(): DriverRefContext | undefined {
		return this.tryGetRuleContext(0, DriverRefContext);
	}
	public timeRange(): TimeRangeContext | undefined {
		return this.tryGetRuleContext(0, TimeRangeContext);
	}
	public STRING(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.STRING, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_timestampValue; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterTimestampValue) {
			listener.enterTimestampValue(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitTimestampValue) {
			listener.exitTimestampValue(this);
		}
	}
}


export class DurationValueContext extends ParserRuleContext {
	public durationUnit(): DurationUnitContext {
		return this.getRuleContext(0, DurationUnitContext);
	}
	public LPAREN(): TerminalNode { return this.getToken(CalculatorParser.LPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_durationValue; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDurationValue) {
			listener.enterDurationValue(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDurationValue) {
			listener.exitDurationValue(this);
		}
	}
}


export class DurationUnitContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_durationUnit; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDurationUnit) {
			listener.enterDurationUnit(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDurationUnit) {
			listener.exitDurationUnit(this);
		}
	}
}


export class BooleanExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_booleanExpression; }
	public copyFrom(ctx: BooleanExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class SimpleExpressionContext extends BooleanExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public BOOLEAN_OPERATOR(): TerminalNode { return this.getToken(CalculatorParser.BOOLEAN_OPERATOR, 0); }
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterSimpleExpression) {
			listener.enterSimpleExpression(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitSimpleExpression) {
			listener.exitSimpleExpression(this);
		}
	}
}
export class AndExpressionContext extends BooleanExpressionContext {
	public booleanExpression(): BooleanExpressionContext[];
	public booleanExpression(i: number): BooleanExpressionContext;
	public booleanExpression(i?: number): BooleanExpressionContext | BooleanExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(BooleanExpressionContext);
		} else {
			return this.getRuleContext(i, BooleanExpressionContext);
		}
	}
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterAndExpression) {
			listener.enterAndExpression(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitAndExpression) {
			listener.exitAndExpression(this);
		}
	}
}
export class OrExpressionContext extends BooleanExpressionContext {
	public booleanExpression(): BooleanExpressionContext[];
	public booleanExpression(i: number): BooleanExpressionContext;
	public booleanExpression(i?: number): BooleanExpressionContext | BooleanExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(BooleanExpressionContext);
		} else {
			return this.getRuleContext(i, BooleanExpressionContext);
		}
	}
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterOrExpression) {
			listener.enterOrExpression(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitOrExpression) {
			listener.exitOrExpression(this);
		}
	}
}
export class TimestampBooleanExpressionContext extends BooleanExpressionContext {
	public timestampExpression(): TimestampExpressionContext[];
	public timestampExpression(i: number): TimestampExpressionContext;
	public timestampExpression(i?: number): TimestampExpressionContext | TimestampExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TimestampExpressionContext);
		} else {
			return this.getRuleContext(i, TimestampExpressionContext);
		}
	}
	public BOOLEAN_OPERATOR(): TerminalNode { return this.getToken(CalculatorParser.BOOLEAN_OPERATOR, 0); }
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterTimestampBooleanExpression) {
			listener.enterTimestampBooleanExpression(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitTimestampBooleanExpression) {
			listener.exitTimestampBooleanExpression(this);
		}
	}
}
export class StringBooleanExpressionContext extends BooleanExpressionContext {
	public stringExpression(): StringExpressionContext[];
	public stringExpression(i: number): StringExpressionContext;
	public stringExpression(i?: number): StringExpressionContext | StringExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(StringExpressionContext);
		} else {
			return this.getRuleContext(i, StringExpressionContext);
		}
	}
	public BOOLEAN_OPERATOR(): TerminalNode { return this.getToken(CalculatorParser.BOOLEAN_OPERATOR, 0); }
	constructor(ctx: BooleanExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterStringBooleanExpression) {
			listener.enterStringBooleanExpression(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitStringBooleanExpression) {
			listener.exitStringBooleanExpression(this);
		}
	}
}


export class ExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_expression; }
	public copyFrom(ctx: ExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class NumberContext extends ExpressionContext {
	public NUMBER(): TerminalNode { return this.getToken(CalculatorParser.NUMBER, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterNumber) {
			listener.enterNumber(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitNumber) {
			listener.exitNumber(this);
		}
	}
}
export class NullContext extends ExpressionContext {
	public NULL(): TerminalNode { return this.getToken(CalculatorParser.NULL, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterNull) {
			listener.enterNull(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitNull) {
			listener.exitNull(this);
		}
	}
}
export class DriverContext extends ExpressionContext {
	public driverRef(): DriverRefContext {
		return this.getRuleContext(0, DriverRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDriver) {
			listener.enterDriver(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDriver) {
			listener.exitDriver(this);
		}
	}
}
export class ExtDriverContext extends ExpressionContext {
	public extDriverRef(): ExtDriverRefContext {
		return this.getRuleContext(0, ExtDriverRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterExtDriver) {
			listener.enterExtDriver(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitExtDriver) {
			listener.exitExtDriver(this);
		}
	}
}
export class ReducerContext extends ExpressionContext {
	public reduceExpressionsOrViews(): ReduceExpressionsOrViewsContext {
		return this.getRuleContext(0, ReduceExpressionsOrViewsContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterReducer) {
			listener.enterReducer(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitReducer) {
			listener.exitReducer(this);
		}
	}
}
export class AddProductContext extends ExpressionContext {
	public sumProduct(): SumProductContext {
		return this.getRuleContext(0, SumProductContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterAddProduct) {
			listener.enterAddProduct(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitAddProduct) {
			listener.exitAddProduct(this);
		}
	}
}
export class MinusContext extends ExpressionContext {
	public SUB(): TerminalNode { return this.getToken(CalculatorParser.SUB, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterMinus) {
			listener.enterMinus(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitMinus) {
			listener.exitMinus(this);
		}
	}
}
export class ParenthesisContext extends ExpressionContext {
	public LPAREN(): TerminalNode { return this.getToken(CalculatorParser.LPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterParenthesis) {
			listener.enterParenthesis(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitParenthesis) {
			listener.exitParenthesis(this);
		}
	}
}
export class PowContext extends ExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public POW(): TerminalNode { return this.getToken(CalculatorParser.POW, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterPow) {
			listener.enterPow(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitPow) {
			listener.exitPow(this);
		}
	}
}
export class MulDivContext extends ExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public MUL(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.MUL, 0); }
	public DIV(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.DIV, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterMulDiv) {
			listener.enterMulDiv(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitMulDiv) {
			listener.exitMulDiv(this);
		}
	}
}
export class AddSubContext extends ExpressionContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public ADD(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.ADD, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.SUB, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterAddSub) {
			listener.enterAddSub(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitAddSub) {
			listener.exitAddSub(this);
		}
	}
}
export class RoundContext extends ExpressionContext {
	public ROUND(): TerminalNode { return this.getToken(CalculatorParser.ROUND, 0); }
	public LPAREN(): TerminalNode { return this.getToken(CalculatorParser.LPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterRound) {
			listener.enterRound(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitRound) {
			listener.exitRound(this);
		}
	}
}
export class RoundDownContext extends ExpressionContext {
	public ROUNDDOWN(): TerminalNode { return this.getToken(CalculatorParser.ROUNDDOWN, 0); }
	public LPAREN(): TerminalNode { return this.getToken(CalculatorParser.LPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterRoundDown) {
			listener.enterRoundDown(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitRoundDown) {
			listener.exitRoundDown(this);
		}
	}
}
export class RoundPlacesContext extends ExpressionContext {
	public ROUND(): TerminalNode { return this.getToken(CalculatorParser.ROUND, 0); }
	public LPAREN(): TerminalNode { return this.getToken(CalculatorParser.LPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterRoundPlaces) {
			listener.enterRoundPlaces(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitRoundPlaces) {
			listener.exitRoundPlaces(this);
		}
	}
}
export class ConditionalContext extends ExpressionContext {
	public baseIf(): BaseIfContext {
		return this.getRuleContext(0, BaseIfContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterConditional) {
			listener.enterConditional(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitConditional) {
			listener.exitConditional(this);
		}
	}
}
export class IfErrorContext extends ExpressionContext {
	public ifErrorRef(): IfErrorRefContext {
		return this.getRuleContext(0, IfErrorRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterIfError) {
			listener.enterIfError(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitIfError) {
			listener.exitIfError(this);
		}
	}
}
export class CoalesceContext extends ExpressionContext {
	public coalesceRef(): CoalesceRefContext {
		return this.getRuleContext(0, CoalesceRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterCoalesce) {
			listener.enterCoalesce(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitCoalesce) {
			listener.exitCoalesce(this);
		}
	}
}
export class ObjectContext extends ExpressionContext {
	public objectRef(): ObjectRefContext {
		return this.getRuleContext(0, ObjectRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterObject) {
			listener.enterObject(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitObject) {
			listener.exitObject(this);
		}
	}
}
export class ExtObjectContext extends ExpressionContext {
	public extObjectRef(): ExtObjectRefContext {
		return this.getRuleContext(0, ExtObjectRefContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterExtObject) {
			listener.enterExtObject(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitExtObject) {
			listener.exitExtObject(this);
		}
	}
}
export class DimDriverFilteredContext extends ExpressionContext {
	public driverFilterView(): DriverFilterViewContext {
		return this.getRuleContext(0, DriverFilterViewContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDimDriverFiltered) {
			listener.enterDimDriverFiltered(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDimDriverFiltered) {
			listener.exitDimDriverFiltered(this);
		}
	}
}
export class MatchFilterContext extends ExpressionContext {
	public matchFilterView(): MatchFilterViewContext {
		return this.getRuleContext(0, MatchFilterViewContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterMatchFilter) {
			listener.enterMatchFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitMatchFilter) {
			listener.exitMatchFilter(this);
		}
	}
}
export class DateExpressionContext extends ExpressionContext {
	public dateHelpers(): DateHelpersContext {
		return this.getRuleContext(0, DateHelpersContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDateExpression) {
			listener.enterDateExpression(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDateExpression) {
			listener.exitDateExpression(this);
		}
	}
}
export class InvalidExpressionContext extends ExpressionContext {
	public INVALID_WORD(): TerminalNode[];
	public INVALID_WORD(i: number): TerminalNode;
	public INVALID_WORD(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(CalculatorParser.INVALID_WORD);
		} else {
			return this.getToken(CalculatorParser.INVALID_WORD, i);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterInvalidExpression) {
			listener.enterInvalidExpression(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitInvalidExpression) {
			listener.exitInvalidExpression(this);
		}
	}
}
export class TimestampValueInExpressionsContext extends ExpressionContext {
	public timestampValue(): TimestampValueContext {
		return this.getRuleContext(0, TimestampValueContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterTimestampValueInExpressions) {
			listener.enterTimestampValueInExpressions(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitTimestampValueInExpressions) {
			listener.exitTimestampValueInExpressions(this);
		}
	}
}


export class DateHelpersContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_dateHelpers; }
	public copyFrom(ctx: DateHelpersContext): void {
		super.copyFrom(ctx);
	}
}
export class DaysInMonthContext extends DateHelpersContext {
	public LPAREN(): TerminalNode { return this.getToken(CalculatorParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: DateHelpersContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDaysInMonth) {
			listener.enterDaysInMonth(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDaysInMonth) {
			listener.exitDaysInMonth(this);
		}
	}
}
export class DateDiffContext extends DateHelpersContext {
	public LPAREN(): TerminalNode { return this.getToken(CalculatorParser.LPAREN, 0); }
	public timestampExpression(): TimestampExpressionContext[];
	public timestampExpression(i: number): TimestampExpressionContext;
	public timestampExpression(i?: number): TimestampExpressionContext | TimestampExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TimestampExpressionContext);
		} else {
			return this.getRuleContext(i, TimestampExpressionContext);
		}
	}
	public STRING(): TerminalNode { return this.getToken(CalculatorParser.STRING, 0); }
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: DateHelpersContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDateDiff) {
			listener.enterDateDiff(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDateDiff) {
			listener.exitDateDiff(this);
		}
	}
}
export class NetWorkDaysContext extends DateHelpersContext {
	public LPAREN(): TerminalNode { return this.getToken(CalculatorParser.LPAREN, 0); }
	public timestampExpression(): TimestampExpressionContext[];
	public timestampExpression(i: number): TimestampExpressionContext;
	public timestampExpression(i?: number): TimestampExpressionContext | TimestampExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TimestampExpressionContext);
		} else {
			return this.getRuleContext(i, TimestampExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public STRING(): TerminalNode[];
	public STRING(i: number): TerminalNode;
	public STRING(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(CalculatorParser.STRING);
		} else {
			return this.getToken(CalculatorParser.STRING, i);
		}
	}
	constructor(ctx: DateHelpersContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterNetWorkDays) {
			listener.enterNetWorkDays(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitNetWorkDays) {
			listener.exitNetWorkDays(this);
		}
	}
}


export class ArrayExpressionContext extends ParserRuleContext {
	public objectSpecRef(): ObjectSpecRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectSpecRefContext);
	}
	public arrayExpression(): ArrayExpressionContext | undefined {
		return this.tryGetRuleContext(0, ArrayExpressionContext);
	}
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.NUMBER, 0); }
	public ADD(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.ADD, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.SUB, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_arrayExpression; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterArrayExpression) {
			listener.enterArrayExpression(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitArrayExpression) {
			listener.exitArrayExpression(this);
		}
	}
}


export class SumProductContext extends ParserRuleContext {
	public driverRef(): DriverRefContext[];
	public driverRef(i: number): DriverRefContext;
	public driverRef(i?: number): DriverRefContext | DriverRefContext[] {
		if (i === undefined) {
			return this.getRuleContexts(DriverRefContext);
		} else {
			return this.getRuleContext(i, DriverRefContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public dimDriverRef(): DimDriverRefContext[];
	public dimDriverRef(i: number): DimDriverRefContext;
	public dimDriverRef(i?: number): DimDriverRefContext | DimDriverRefContext[] {
		if (i === undefined) {
			return this.getRuleContexts(DimDriverRefContext);
		} else {
			return this.getRuleContext(i, DimDriverRefContext);
		}
	}
	public arrayExpression(): ArrayExpressionContext[];
	public arrayExpression(i: number): ArrayExpressionContext;
	public arrayExpression(i?: number): ArrayExpressionContext | ArrayExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ArrayExpressionContext);
		} else {
			return this.getRuleContext(i, ArrayExpressionContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_sumProduct; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterSumProduct) {
			listener.enterSumProduct(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitSumProduct) {
			listener.exitSumProduct(this);
		}
	}
}


export class BaseIfContext extends ParserRuleContext {
	public booleanExpression(): BooleanExpressionContext {
		return this.getRuleContext(0, BooleanExpressionContext);
	}
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_baseIf; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterBaseIf) {
			listener.enterBaseIf(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitBaseIf) {
			listener.exitBaseIf(this);
		}
	}
}


export class StringIfContext extends ParserRuleContext {
	public booleanExpression(): BooleanExpressionContext {
		return this.getRuleContext(0, BooleanExpressionContext);
	}
	public stringExpression(): StringExpressionContext[];
	public stringExpression(i: number): StringExpressionContext;
	public stringExpression(i?: number): StringExpressionContext | StringExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(StringExpressionContext);
		} else {
			return this.getRuleContext(i, StringExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_stringIf; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterStringIf) {
			listener.enterStringIf(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitStringIf) {
			listener.exitStringIf(this);
		}
	}
}


export class TimestampIfContext extends ParserRuleContext {
	public booleanExpression(): BooleanExpressionContext {
		return this.getRuleContext(0, BooleanExpressionContext);
	}
	public timestampExpression(): TimestampExpressionContext[];
	public timestampExpression(i: number): TimestampExpressionContext;
	public timestampExpression(i?: number): TimestampExpressionContext | TimestampExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TimestampExpressionContext);
		} else {
			return this.getRuleContext(i, TimestampExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_timestampIf; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterTimestampIf) {
			listener.enterTimestampIf(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitTimestampIf) {
			listener.exitTimestampIf(this);
		}
	}
}


export class IfErrorRefContext extends ParserRuleContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_ifErrorRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterIfErrorRef) {
			listener.enterIfErrorRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitIfErrorRef) {
			listener.exitIfErrorRef(this);
		}
	}
}


export class CoalesceRefContext extends ParserRuleContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_coalesceRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterCoalesceRef) {
			listener.enterCoalesceRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitCoalesceRef) {
			listener.exitCoalesceRef(this);
		}
	}
}


export class DriverRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_driverRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDriverRef) {
			listener.enterDriverRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDriverRef) {
			listener.exitDriverRef(this);
		}
	}
}


export class DimDriverRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_dimDriverRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDimDriverRef) {
			listener.enterDimDriverRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDimDriverRef) {
			listener.exitDimDriverRef(this);
		}
	}
}


export class SubmodelRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public timeRange(): TimeRangeContext | undefined {
		return this.tryGetRuleContext(0, TimeRangeContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_submodelRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterSubmodelRef) {
			listener.enterSubmodelRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitSubmodelRef) {
			listener.exitSubmodelRef(this);
		}
	}
}


export class ObjectRefContext extends ParserRuleContext {
	public objectFieldRef(): ObjectFieldRefContext {
		return this.getRuleContext(0, ObjectFieldRefContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public UUID(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.UUID, 0); }
	public THIS(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.THIS, 0); }
	public objectFilterView(): ObjectFilterViewContext | undefined {
		return this.tryGetRuleContext(0, ObjectFilterViewContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_objectRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterObjectRef) {
			listener.enterObjectRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitObjectRef) {
			listener.exitObjectRef(this);
		}
	}
}


export class ExtObjectRefContext extends ParserRuleContext {
	public extObjectFieldRef(): ExtObjectFieldRefContext {
		return this.getRuleContext(0, ExtObjectFieldRefContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public LINKED(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.LINKED, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_extObjectRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterExtObjectRef) {
			listener.enterExtObjectRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitExtObjectRef) {
			listener.exitExtObjectRef(this);
		}
	}
}


export class ExtObjectFieldRefContext extends ParserRuleContext {
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public LINKED(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.LINKED, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_extObjectFieldRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterExtObjectFieldRef) {
			listener.enterExtObjectFieldRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitExtObjectFieldRef) {
			listener.exitExtObjectFieldRef(this);
		}
	}
}


export class ObjectSpecRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	public objectFieldRef(): ObjectFieldRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectFieldRefContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public objectFilterView(): ObjectFilterViewContext | undefined {
		return this.tryGetRuleContext(0, ObjectFilterViewContext);
	}
	public timeRange(): TimeRangeContext | undefined {
		return this.tryGetRuleContext(0, TimeRangeContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_objectSpecRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterObjectSpecRef) {
			listener.enterObjectSpecRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitObjectSpecRef) {
			listener.exitObjectSpecRef(this);
		}
	}
}


export class ObjectFieldRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_objectFieldRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterObjectFieldRef) {
			listener.enterObjectFieldRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitObjectFieldRef) {
			listener.exitObjectFieldRef(this);
		}
	}
}


export class ExtDriverRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_extDriverRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterExtDriverRef) {
			listener.enterExtDriverRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitExtDriverRef) {
			listener.exitExtDriverRef(this);
		}
	}
}


export class ExtQueryRefContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	public timeRange(): TimeRangeContext {
		return this.getRuleContext(0, TimeRangeContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public extQueryFilterView(): ExtQueryFilterViewContext | undefined {
		return this.tryGetRuleContext(0, ExtQueryFilterViewContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_extQueryRef; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterExtQueryRef) {
			listener.enterExtQueryRef(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitExtQueryRef) {
			listener.exitExtQueryRef(this);
		}
	}
}


export class ExtQueryFilterViewContext extends ParserRuleContext {
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public attributeFilter(): AttributeFilterContext[];
	public attributeFilter(i: number): AttributeFilterContext;
	public attributeFilter(i?: number): AttributeFilterContext | AttributeFilterContext[] {
		if (i === undefined) {
			return this.getRuleContexts(AttributeFilterContext);
		} else {
			return this.getRuleContext(i, AttributeFilterContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_extQueryFilterView; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterExtQueryFilterView) {
			listener.enterExtQueryFilterView(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitExtQueryFilterView) {
			listener.exitExtQueryFilterView(this);
		}
	}
}


export class ObjectFilterViewContext extends ParserRuleContext {
	public objectFilter(): ObjectFilterContext[];
	public objectFilter(i: number): ObjectFilterContext;
	public objectFilter(i?: number): ObjectFilterContext | ObjectFilterContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ObjectFilterContext);
		} else {
			return this.getRuleContext(i, ObjectFilterContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_objectFilterView; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterObjectFilterView) {
			listener.enterObjectFilterView(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitObjectFilterView) {
			listener.exitObjectFilterView(this);
		}
	}
}


export class ObjectFilterContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_objectFilter; }
	public copyFrom(ctx: ObjectFilterContext): void {
		super.copyFrom(ctx);
	}
}
export class ObjectUUIDFilterContext extends ObjectFilterContext {
	public UUID(): TerminalNode[];
	public UUID(i: number): TerminalNode;
	public UUID(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(CalculatorParser.UUID);
		} else {
			return this.getToken(CalculatorParser.UUID, i);
		}
	}
	constructor(ctx: ObjectFilterContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterObjectUUIDFilter) {
			listener.enterObjectUUIDFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitObjectUUIDFilter) {
			listener.exitObjectUUIDFilter(this);
		}
	}
}
export class ObjectFieldFilterContext extends ObjectFilterContext {
	public objectFieldRef(): ObjectFieldRefContext {
		return this.getRuleContext(0, ObjectFieldRefContext);
	}
	public BOOLEAN_OPERATOR(): TerminalNode { return this.getToken(CalculatorParser.BOOLEAN_OPERATOR, 0); }
	public stringGroup(): StringGroupContext | undefined {
		return this.tryGetRuleContext(0, StringGroupContext);
	}
	public uuidGroup(): UuidGroupContext | undefined {
		return this.tryGetRuleContext(0, UuidGroupContext);
	}
	public timeRange(): TimeRangeContext | undefined {
		return this.tryGetRuleContext(0, TimeRangeContext);
	}
	public attributeGroup(): AttributeGroupContext | undefined {
		return this.tryGetRuleContext(0, AttributeGroupContext);
	}
	public formulaFilter(): FormulaFilterContext | undefined {
		return this.tryGetRuleContext(0, FormulaFilterContext);
	}
	public NULL(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.NULL, 0); }
	constructor(ctx: ObjectFilterContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterObjectFieldFilter) {
			listener.enterObjectFieldFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitObjectFieldFilter) {
			listener.exitObjectFieldFilter(this);
		}
	}
}
export class ObjectAllContextAttributesFilterContext extends ObjectFilterContext {
	public allContextAttributesFilter(): AllContextAttributesFilterContext {
		return this.getRuleContext(0, AllContextAttributesFilterContext);
	}
	constructor(ctx: ObjectFilterContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterObjectAllContextAttributesFilter) {
			listener.enterObjectAllContextAttributesFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitObjectAllContextAttributesFilter) {
			listener.exitObjectAllContextAttributesFilter(this);
		}
	}
}


export class UserAttributeFilterContext extends ParserRuleContext {
	public UUID(): TerminalNode[];
	public UUID(i: number): TerminalNode;
	public UUID(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(CalculatorParser.UUID);
		} else {
			return this.getToken(CalculatorParser.UUID, i);
		}
	}
	public ANY(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.ANY, 0); }
	public NULL(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.NULL, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_userAttributeFilter; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterUserAttributeFilter) {
			listener.enterUserAttributeFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitUserAttributeFilter) {
			listener.exitUserAttributeFilter(this);
		}
	}
}


export class BuiltInAttributeFilterContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_builtInAttributeFilter; }
	public copyFrom(ctx: BuiltInAttributeFilterContext): void {
		super.copyFrom(ctx);
	}
}
export class CalendarFilterContext extends BuiltInAttributeFilterContext {
	public CALENDAR(): TerminalNode { return this.getToken(CalculatorParser.CALENDAR, 0); }
	public DATE(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.DATE, 0); }
	public ANY(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.ANY, 0); }
	public NULL(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.NULL, 0); }
	constructor(ctx: BuiltInAttributeFilterContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterCalendarFilter) {
			listener.enterCalendarFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitCalendarFilter) {
			listener.exitCalendarFilter(this);
		}
	}
}
export class RelativeFilterContext extends BuiltInAttributeFilterContext {
	public RELATIVE(): TerminalNode { return this.getToken(CalculatorParser.RELATIVE, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.NUMBER, 0); }
	public ANY(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.ANY, 0); }
	public NULL(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.NULL, 0); }
	public COHORT_MONTH(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.COHORT_MONTH, 0); }
	constructor(ctx: BuiltInAttributeFilterContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterRelativeFilter) {
			listener.enterRelativeFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitRelativeFilter) {
			listener.exitRelativeFilter(this);
		}
	}
}


export class AttributeFilterContext extends ParserRuleContext {
	public builtInAttributeFilter(): BuiltInAttributeFilterContext | undefined {
		return this.tryGetRuleContext(0, BuiltInAttributeFilterContext);
	}
	public userAttributeFilter(): UserAttributeFilterContext | undefined {
		return this.tryGetRuleContext(0, UserAttributeFilterContext);
	}
	public contextOrHardcodedAttributeFilter(): ContextOrHardcodedAttributeFilterContext | undefined {
		return this.tryGetRuleContext(0, ContextOrHardcodedAttributeFilterContext);
	}
	public allContextAttributesFilter(): AllContextAttributesFilterContext | undefined {
		return this.tryGetRuleContext(0, AllContextAttributesFilterContext);
	}
	public formulaFilter(): FormulaFilterContext | undefined {
		return this.tryGetRuleContext(0, FormulaFilterContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_attributeFilter; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterAttributeFilter) {
			listener.enterAttributeFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitAttributeFilter) {
			listener.exitAttributeFilter(this);
		}
	}
}


export class ContextOrHardcodedAttributeFilterContext extends ParserRuleContext {
	public attribute(): AttributeContext {
		return this.getRuleContext(0, AttributeContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_contextOrHardcodedAttributeFilter; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterContextOrHardcodedAttributeFilter) {
			listener.enterContextOrHardcodedAttributeFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitContextOrHardcodedAttributeFilter) {
			listener.exitContextOrHardcodedAttributeFilter(this);
		}
	}
}


export class DriverGroupFilterContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_driverGroupFilter; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDriverGroupFilter) {
			listener.enterDriverGroupFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDriverGroupFilter) {
			listener.exitDriverGroupFilter(this);
		}
	}
}


export class SubmodelFilterContext extends ParserRuleContext {
	public driverGroupFilter(): DriverGroupFilterContext {
		return this.getRuleContext(0, DriverGroupFilterContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_submodelFilter; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterSubmodelFilter) {
			listener.enterSubmodelFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitSubmodelFilter) {
			listener.exitSubmodelFilter(this);
		}
	}
}


export class DriverFilterViewContext extends ParserRuleContext {
	public dimDriverView(): DimDriverViewContext {
		return this.getRuleContext(0, DimDriverViewContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public attributeFilter(): AttributeFilterContext[];
	public attributeFilter(i: number): AttributeFilterContext;
	public attributeFilter(i?: number): AttributeFilterContext | AttributeFilterContext[] {
		if (i === undefined) {
			return this.getRuleContexts(AttributeFilterContext);
		} else {
			return this.getRuleContext(i, AttributeFilterContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_driverFilterView; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDriverFilterView) {
			listener.enterDriverFilterView(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDriverFilterView) {
			listener.exitDriverFilterView(this);
		}
	}
}


export class MatchFilterViewContext extends ParserRuleContext {
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public driverFilterView(): DriverFilterViewContext | undefined {
		return this.tryGetRuleContext(0, DriverFilterViewContext);
	}
	public objectSpecRef(): ObjectSpecRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectSpecRefContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_matchFilterView; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterMatchFilterView) {
			listener.enterMatchFilterView(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitMatchFilterView) {
			listener.exitMatchFilterView(this);
		}
	}
}


export class AllContextAttributesFilterContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_allContextAttributesFilter; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterAllContextAttributesFilter) {
			listener.enterAllContextAttributesFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitAllContextAttributesFilter) {
			listener.exitAllContextAttributesFilter(this);
		}
	}
}


export class AttributeContext extends ParserRuleContext {
	public contextAttribute(): ContextAttributeContext | undefined {
		return this.tryGetRuleContext(0, ContextAttributeContext);
	}
	public UUID(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.UUID, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_attribute; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterAttribute) {
			listener.enterAttribute(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitAttribute) {
			listener.exitAttribute(this);
		}
	}
}


export class AttributeGroupContext extends ParserRuleContext {
	public attribute(): AttributeContext[];
	public attribute(i: number): AttributeContext;
	public attribute(i?: number): AttributeContext | AttributeContext[] {
		if (i === undefined) {
			return this.getRuleContexts(AttributeContext);
		} else {
			return this.getRuleContext(i, AttributeContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_attributeGroup; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterAttributeGroup) {
			listener.enterAttributeGroup(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitAttributeGroup) {
			listener.exitAttributeGroup(this);
		}
	}
}


export class FormulaFilterContext extends ParserRuleContext {
	public matchFilterView(): MatchFilterViewContext {
		return this.getRuleContext(0, MatchFilterViewContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_formulaFilter; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterFormulaFilter) {
			listener.enterFormulaFilter(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitFormulaFilter) {
			listener.exitFormulaFilter(this);
		}
	}
}


export class ContextAttributeContext extends ParserRuleContext {
	public UUID(): TerminalNode { return this.getToken(CalculatorParser.UUID, 0); }
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_contextAttribute; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterContextAttribute) {
			listener.enterContextAttribute(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitContextAttribute) {
			listener.exitContextAttribute(this);
		}
	}
}


export class RelativeContext extends ParserRuleContext {
	public dimDriverView(): DimDriverViewContext {
		return this.getRuleContext(0, DimDriverViewContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public attributeFilter(): AttributeFilterContext[];
	public attributeFilter(i: number): AttributeFilterContext;
	public attributeFilter(i?: number): AttributeFilterContext | AttributeFilterContext[] {
		if (i === undefined) {
			return this.getRuleContexts(AttributeFilterContext);
		} else {
			return this.getRuleContext(i, AttributeFilterContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_relative; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterRelative) {
			listener.enterRelative(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitRelative) {
			listener.exitRelative(this);
		}
	}
}


export class DimDriverViewContext extends ParserRuleContext {
	public dimDriverRef(): DimDriverRefContext {
		return this.getRuleContext(0, DimDriverRefContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_dimDriverView; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDimDriverView) {
			listener.enterDimDriverView(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDimDriverView) {
			listener.exitDimDriverView(this);
		}
	}
}


export class SubmodelViewContext extends ParserRuleContext {
	public submodelRef(): SubmodelRefContext {
		return this.getRuleContext(0, SubmodelRefContext);
	}
	public submodelFilter(): SubmodelFilterContext {
		return this.getRuleContext(0, SubmodelFilterContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_submodelView; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterSubmodelView) {
			listener.enterSubmodelView(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitSubmodelView) {
			listener.exitSubmodelView(this);
		}
	}
}


export class ReduceExpressionsOrViewsContext extends ParserRuleContext {
	public reducerFn(): ReducerFnContext {
		return this.getRuleContext(0, ReducerFnContext);
	}
	public LPAREN(): TerminalNode { return this.getToken(CalculatorParser.LPAREN, 0); }
	public expressionOrView(): ExpressionOrViewContext[];
	public expressionOrView(i: number): ExpressionOrViewContext;
	public expressionOrView(i?: number): ExpressionOrViewContext | ExpressionOrViewContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionOrViewContext);
		} else {
			return this.getRuleContext(i, ExpressionOrViewContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_reduceExpressionsOrViews; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterReduceExpressionsOrViews) {
			listener.enterReduceExpressionsOrViews(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitReduceExpressionsOrViews) {
			listener.exitReduceExpressionsOrViews(this);
		}
	}
}


export class ExpressionOrViewContext extends ParserRuleContext {
	public expression(): ExpressionContext | undefined {
		return this.tryGetRuleContext(0, ExpressionContext);
	}
	public reduceableViews(): ReduceableViewsContext | undefined {
		return this.tryGetRuleContext(0, ReduceableViewsContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_expressionOrView; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterExpressionOrView) {
			listener.enterExpressionOrView(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitExpressionOrView) {
			listener.exitExpressionOrView(this);
		}
	}
}


export class ReducerFnContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_reducerFn; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterReducerFn) {
			listener.enterReducerFn(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitReducerFn) {
			listener.exitReducerFn(this);
		}
	}
}


export class ReduceableViewsContext extends ParserRuleContext {
	public dimDriverView(): DimDriverViewContext | undefined {
		return this.tryGetRuleContext(0, DimDriverViewContext);
	}
	public driverFilterView(): DriverFilterViewContext | undefined {
		return this.tryGetRuleContext(0, DriverFilterViewContext);
	}
	public objectSpecRef(): ObjectSpecRefContext | undefined {
		return this.tryGetRuleContext(0, ObjectSpecRefContext);
	}
	public submodelView(): SubmodelViewContext | undefined {
		return this.tryGetRuleContext(0, SubmodelViewContext);
	}
	public extQueryRef(): ExtQueryRefContext | undefined {
		return this.tryGetRuleContext(0, ExtQueryRefContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_reduceableViews; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterReduceableViews) {
			listener.enterReduceableViews(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitReduceableViews) {
			listener.exitReduceableViews(this);
		}
	}
}


export class TimeRangeContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_timeRange; }
	public copyFrom(ctx: TimeRangeContext): void {
		super.copyFrom(ctx);
	}
}
export class VariableRelativeTimeContext extends TimeRangeContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: TimeRangeContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterVariableRelativeTime) {
			listener.enterVariableRelativeTime(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitVariableRelativeTime) {
			listener.exitVariableRelativeTime(this);
		}
	}
}
export class DateRangeContext extends TimeRangeContext {
	public dateRef(): DateRefContext[];
	public dateRef(i: number): DateRefContext;
	public dateRef(i?: number): DateRefContext | DateRefContext[] {
		if (i === undefined) {
			return this.getRuleContexts(DateRefContext);
		} else {
			return this.getRuleContext(i, DateRefContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: TimeRangeContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDateRange) {
			listener.enterDateRange(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDateRange) {
			listener.exitDateRange(this);
		}
	}
}
export class CohortRelativeTimeContext extends TimeRangeContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	constructor(ctx: TimeRangeContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterCohortRelativeTime) {
			listener.enterCohortRelativeTime(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitCohortRelativeTime) {
			listener.exitCohortRelativeTime(this);
		}
	}
}


export class DateRefContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_dateRef; }
	public copyFrom(ctx: DateRefContext): void {
		super.copyFrom(ctx);
	}
}
export class DateContext extends DateRefContext {
	public DATE(): TerminalNode { return this.getToken(CalculatorParser.DATE, 0); }
	constructor(ctx: DateRefContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDate) {
			listener.enterDate(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDate) {
			listener.exitDate(this);
		}
	}
}
export class DateRelativeMonthsContext extends DateRefContext {
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.NUMBER, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.SUB, 0); }
	constructor(ctx: DateRefContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDateRelativeMonths) {
			listener.enterDateRelativeMonths(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDateRelativeMonths) {
			listener.exitDateRelativeMonths(this);
		}
	}
}
export class DateRelativeQuartersContext extends DateRefContext {
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.NUMBER, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.SUB, 0); }
	constructor(ctx: DateRefContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDateRelativeQuarters) {
			listener.enterDateRelativeQuarters(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDateRelativeQuarters) {
			listener.exitDateRelativeQuarters(this);
		}
	}
}
export class DateRelativeYearsContext extends DateRefContext {
	public RPAREN(): TerminalNode { return this.getToken(CalculatorParser.RPAREN, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.NUMBER, 0); }
	public SUB(): TerminalNode | undefined { return this.tryGetToken(CalculatorParser.SUB, 0); }
	constructor(ctx: DateRefContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterDateRelativeYears) {
			listener.enterDateRelativeYears(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitDateRelativeYears) {
			listener.exitDateRelativeYears(this);
		}
	}
}


export class StringGroupContext extends ParserRuleContext {
	public STRING(): TerminalNode[];
	public STRING(i: number): TerminalNode;
	public STRING(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(CalculatorParser.STRING);
		} else {
			return this.getToken(CalculatorParser.STRING, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_stringGroup; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterStringGroup) {
			listener.enterStringGroup(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitStringGroup) {
			listener.exitStringGroup(this);
		}
	}
}


export class UuidGroupContext extends ParserRuleContext {
	public UUID(): TerminalNode[];
	public UUID(i: number): TerminalNode;
	public UUID(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(CalculatorParser.UUID);
		} else {
			return this.getToken(CalculatorParser.UUID, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return CalculatorParser.RULE_uuidGroup; }
	// @Override
	public enterRule(listener: CalculatorListener): void {
		if (listener.enterUuidGroup) {
			listener.enterUuidGroup(this);
		}
	}
	// @Override
	public exitRule(listener: CalculatorListener): void {
		if (listener.exitUuidGroup) {
			listener.exitUuidGroup(this);
		}
	}
}


