import { useEffect, useRef } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

const useScrollToCell = (shouldScroll: boolean) => {
  const firstCellRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (shouldScroll && firstCellRef.current != null) {
      scrollIntoView(firstCellRef.current, { scrollMode: 'if-needed' });
    }
  }, [shouldScroll]);

  return firstCellRef;
};

export default useScrollToCell;
