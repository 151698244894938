import React from 'react';

import styles from './AttributePill.module.scss';

interface Props {
  label: string;
  icon?: JSX.Element;
}

const AttributePill: React.FC<Props> = ({ label, icon }) => {
  const textContent = icon == null ? label : ' ' + label;
  return (
    <span className={styles.attributePill} data-content={textContent}>
      {icon}
    </span>
  );
};

export default React.memo(AttributePill);
