import { useCallback } from 'react';

import useAppDispatch from 'hooks/useAppDispatch';
import { openCellPalettePopover } from 'reduxStore/reducers/pageSlice';

export function useOpenForecastEditPopover(): () => void {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(openCellPalettePopover());
  }, [dispatch]);
}
