import { Button, Flex, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';

import { DatePickerReactContext } from 'config/datePickerContext';
import { MONTHS } from 'helpers/dates';

const DatePickerPager: React.FC = () => {
  const { pagedMonth, pagedYear, pageBy, decrement, increment } =
    useContext(DatePickerReactContext);
  return (
    <Flex justifyContent="space-between" alignItems="center" w="full">
      <Text
        data-testid="paged-by-text"
        fontWeight="semibold"
        lineHeight="1.125rem"
        fontSize="xs"
        py={1}
      >
        {pageBy === 'month' && `${MONTHS[pagedMonth]} `}
        {pagedYear}
      </Text>
      <Flex columnGap={2}>
        <Button
          data-testid="decrement-button"
          onClick={decrement}
          variant="icon"
          py="px"
          w="1.5rem"
          color="gray.500"
          fontFamily="body"
        >
          {'<-'}
        </Button>
        <Button
          data-testid="increment-button"
          onClick={increment}
          variant="icon"
          py="px"
          w="1.5rem"
          color="gray.500"
          fontFamily="body"
        >
          {'->'}
        </Button>
      </Flex>
    </Flex>
  );
};

export default React.memo(DatePickerPager);
