import {
  BusinessObjectSpecUpdateInput,
  BusinessObjectUpdateInput,
  DimensionalPropertyCreateInput,
  ValueType,
} from 'generated/graphql';
import { newNameDeduper } from 'helpers/naming';
import { isNotNull } from 'helpers/typescript';
import { submitMutation } from 'reduxStore/actions/submitDatasetMutation';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { AppThunk } from 'reduxStore/store';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSpecSelector } from 'selectors/businessObjectSpecsSelector';
import { businessObjectFieldForecastTimeSeriesSyncCalculationsSelector } from 'selectors/businessObjectTimeSeriesSelector';
import { businessObjectsBySpecIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import { blockMonthKeysSelector } from 'selectors/pageDateRangeSelector';

export const convertFieldSpecToDimensionalProperty =
  ({
    blockId,
    fieldSpecId,
  }: {
    blockId: BlockId;
    fieldSpecId: BusinessObjectFieldSpecId;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const objectSpecId = businessObjectSpecForBlockSelector(state, blockId)?.id;
    if (objectSpecId == null) {
      return;
    }
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
    const spec = businessObjectSpecSelector(state, objectSpecId);
    if (fieldSpec == null || fieldSpec.type !== ValueType.Attribute || spec == null) {
      return;
    }
    // Create a new dimensional property based on the field spec
    const dimensionId = fieldSpec.dimensionId;
    const nameDeduper = newNameDeduper(
      spec.collection?.dimensionalProperties.map((p) => p.name) ?? [],
    );
    const dimensionalProperty: DimensionalPropertyCreateInput = {
      dimensionId,
      // Create the dimensional property with the same id as the field spec
      id: fieldSpecId,
      name: nameDeduper.dedupe(fieldSpec.name),
    };

    const updateObjectSpecMutation: BusinessObjectSpecUpdateInput = {
      id: objectSpecId,
      updateCollection: {
        addDimensionalProperties: [dimensionalProperty],
      },
      deleteFields: [fieldSpecId],
    };
    dispatch(
      submitMutation({
        updateBusinessObjectSpecs: [updateObjectSpecMutation],
      }),
    );

    // Update object property values based on object field values
    const objectsBySpecId = businessObjectsBySpecIdForLayerSelector(state);
    const objects = objectsBySpecId[objectSpecId] ?? [];
    const monthKeys = blockMonthKeysSelector(state, blockId);
    if (monthKeys.length === 0) {
      return;
    }
    const monthKey = monthKeys[monthKeys.length - 1];
    const updateObjectsMutation: BusinessObjectUpdateInput[] = objects
      .map((object) => {
        // Use the synchronous calculation here because the rows that have not
        // been loaded into view are not requested from the web worker and won't be in the cache
        // Once we are fully on BE calc, we'll have to replace this with calculations requests to the BE
        // + some loaders while we wait.
        const value = businessObjectFieldForecastTimeSeriesSyncCalculationsSelector(state, {
          businessObjectId: object.id,
          businessObjectFieldSpecId: fieldSpecId,
          blockId,
        })[monthKey];
        if (value == null) {
          return null;
        }
        return {
          id: object.id,
          updateCollectionEntry: {
            addAttributeProperties: [
              {
                attributeId: value.value.toString(),
                dimensionalPropertyId: dimensionalProperty.id,
              },
            ],
          },
        };
      })
      .filter(isNotNull);

    // N.B. Send two mutations since object specs must be updated on the default layer
    // but there might be objects on the current layer that need to be updated.
    dispatch(
      submitMutation({
        updateBusinessObjects: updateObjectsMutation,
      }),
    );
  };
