import { Formula } from '@features/CompareScenarios/comparators/common/types';

export const toFormulaChangeData = ({
  id,
  type,
  formulaType,
  layerId,
}: Omit<Formula['value'], 'id'> & { id: Formula['value']['id'] | undefined }):
  | Formula
  | undefined => {
  return id != null
    ? {
        type: 'formula',
        value: {
          id,
          type,
          formulaType,
          layerId,
        },
      }
    : undefined;
};
