import { Flex } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import DriverPropertyTableCell from 'components/DriverPropertyTableCell/DriverPropertyTableCell';
import DriverChunk from 'components/Formula/DriverChunk';
import TableCellPopover from 'components/TableCellPopover/TableCellPopover';
import { DriverRowContext } from 'config/driverRowContext';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import {
  DriverFormulaDisplayChunk,
  FormulaDisplayChunkType,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { isNotNull } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useDriverCellRef from 'hooks/useDriverCellRef';
import useTableCellPopover from 'hooks/useTableCellPopover';
import { DimensionalDriver, Driver } from 'reduxStore/models/drivers';
import { reverseDirectDriverDependencyFlattenedDimMatrixSelector } from 'selectors/dependenciesSelector';
import {
  dimensionalDriversBySubDriverIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import { driverPropertyColumnTableCellWidthSelector } from 'selectors/tableColumnsSelector';

const COLUMN_TYPE: ModelViewColumnType = 'usedBy';

type Props = {
  columnLayerId: ColumnLayerId;
};

const getChunkForDriver = (
  driver: Driver,
  dimDriverBySubdriverId: NullableRecord<string, DimensionalDriver>,
) => {
  const chunk: DriverFormulaDisplayChunk = {
    type: FormulaDisplayChunkType.Driver,
    driverId: driver.id,
    displayName: driver.name,
  };
  const dimDriverParent = dimDriverBySubdriverId[driver.id];
  if (dimDriverParent != null) {
    const subdriverInfo = dimDriverParent.subdrivers.find((d) => d.driverId === driver.id);
    if (subdriverInfo != null) {
      chunk.displayName = dimDriverParent.name;
      chunk.attributeFilters = {
        byDimId: Object.fromEntries(
          subdriverInfo.attributes.map((attr) => [attr.dimensionId, [attr]]),
        ),
      };
    }
  }
  return chunk;
};

const DependentsTableCell: React.FC<Props> = ({ columnLayerId }) => {
  const { blockId } = useBlockContext();
  const { driverId, comparisonRowLayerId } = useContext(DriverRowContext);
  const cellRef = useDriverCellRef({ columnType: COLUMN_TYPE, columnLayerId });
  const { selectCellAndOpenPopover, showPopover, closePopover } = useTableCellPopover(cellRef);
  const width = useAppSelector((state) =>
    driverPropertyColumnTableCellWidthSelector(state, {
      blockId,
      columnType: COLUMN_TYPE,
    }),
  );

  const layerId = comparisonRowLayerId ?? columnLayerId;

  const dimDriverMap = useAppSelector((state) =>
    dimensionalDriversBySubDriverIdSelector(state, { layerId }),
  );
  const drivers = useAppSelector((state) => driversByIdForLayerSelector(state, { layerId }));
  const dependents = (
    useAppSelector((state) =>
      reverseDirectDriverDependencyFlattenedDimMatrixSelector(state, { layerId }),
    )[driverId] ?? []
  )
    .filter((depId) => depId !== driverId)
    .map((depId) => drivers[depId])
    .filter(isNotNull);

  const onClick = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();
      closePopover();
    },
    [closePopover],
  );

  return (
    <CellContextProvider cellRef={cellRef}>
      <TableCellPopover
        visible={showPopover && dependents.length > 0}
        onClose={closePopover}
        autoFocus
        width={width}
        content={
          <Flex fontSize="xs" lineHeight={2} onClick={onClick} columnGap={1} flexWrap="wrap">
            {dependents.map((dep) => (
              <DriverChunk
                key={`${dep.id}-dependentChunk`}
                chunk={getChunkForDriver(dep, dimDriverMap)}
              />
            ))}
          </Flex>
        }
      >
        <DriverPropertyTableCell
          columnType={COLUMN_TYPE}
          columnLayerId={columnLayerId}
          onMouseDown={selectCellAndOpenPopover}
          disablePointerEventsOnContents
          isSticky
        >
          <Flex fontSize="xs" lineHeight={2} whiteSpace="nowrap" columnGap={1}>
            {dependents != null &&
              dependents.map((dep) => (
                <DriverChunk
                  key={`${dep.id}-dependentChunk`}
                  chunk={getChunkForDriver(dep, dimDriverMap)}
                />
              ))}
          </Flex>
        </DriverPropertyTableCell>
      </TableCellPopover>
    </CellContextProvider>
  );
};

export default React.memo(DependentsTableCell);
