import { createSelector } from 'reselect';

import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { openDetailPaneSelector } from 'selectors/detailPaneSelectors';
import { Selector } from 'types/redux';

export const mustDetailPaneObjectIdSelector: Selector<BusinessObjectId> = createSelector(
  openDetailPaneSelector,
  (detailPane) => {
    if (detailPane?.type !== PaneType.Object) {
      throw new Error('no object detail pane open');
    }

    return detailPane.objectId;
  },
);

export const mustObjectDetailPaneObjectSpecIdSelector: Selector<BusinessObjectSpecId> =
  createSelector(openDetailPaneSelector, (detailPane) => {
    if (detailPane?.type !== PaneType.Object) {
      throw new Error('no object detail pane open');
    }

    return detailPane.objectSpecId;
  });

export const objectDetailPaneObjectSpecIdSelector: Selector<BusinessObjectSpecId | null> =
  createSelector(openDetailPaneSelector, (detailPane) => {
    if (detailPane?.type !== PaneType.Object) {
      return null;
    }

    return detailPane.objectSpecId;
  });
