import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Input,
  InputProps,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import isEmpty from 'lodash/isEmpty';
import Image from 'next/image';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useEffectOnce } from 'react-use';

import { TODAY } from 'helpers/dates';
import { backendUrl } from 'helpers/environment';
import useAppSelector from 'hooks/useAppSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';
import PlaneIcon from 'vectors/Plane';

const LIGHT_ACCENT_COLOR = '#9E9494';
const TICKET_BG = '#EBEBEB';
const TICKET_TEXT_BLACK = '#192227';

const validationSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .trim()
    .required()
    .messages({
      'string.empty': 'email required',
      'string.email': 'invalid email',
    }),
  name: Joi.string().trim().empty('').optional(),
});

type FormValues = {
  email: string;
  name: string | null;
};

const WAITLIST_URL = `${backendUrl}/waitlist`;
export const ReferralContent: React.FC = () => {
  return (
    <Flex flexDir="row" fontFamily="boardingPass" color={TICKET_TEXT_BLACK}>
      <ReferralTicketSection width="40rem" padding="1.875rem">
        <Flex h="full" flexDir="column" justifyContent="space-between">
          <Text fontSize="5rem" fontWeight="semibold" lineHeight="initial">
            Priority Boarding Pass
          </Text>
          <ReferralFlightPath />
          <Flex flexDir="row" alignItems="center" justifyContent="flex-start" columnGap={4}>
            <Image
              draggable={false}
              alt="runway logo"
              src="/images/logo_animated.gif"
              width={50}
              height={50}
            />
            <Text flexGrow={0} fontSize="xs" w="26.25rem">
              Know someone who would love Runway? Type their work email here, and we’ll place them
              at the top of the list.
            </Text>
          </Flex>
        </Flex>
      </ReferralTicketSection>
      <ReferralTicketSection width="20rem" padding="1rem">
        <Flex flexDir="column" h="full" justifyContent="space-between">
          <TopRowMetadata />
          <Form />
        </Flex>
        <PerforatedEdge />
      </ReferralTicketSection>
    </Flex>
  );
};

const Form: React.FC = () => {
  const orgSlug = useAppSelector(selectedOrgSelector)?.slug;
  const authenticatedUser = useAppSelector(authenticatedUserSelector);
  const userId = authenticatedUser?.id;

  const resolver = joiResolver(validationSchema);

  const formMethods = useForm<FormValues>({
    resolver: (values, context, options) => {
      return resolver(values, context, options);
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const { reset, handleSubmit, setFocus, watch } = formMethods;

  useEffectOnce(() => {
    setFocus('email');
  });

  const [wasSubmitted, setWasSubmitted] = useBoolean();

  const submit = useCallback(
    ({ email, name }: FormValues) => {
      if (orgSlug == null || userId == null) {
        return;
      }

      setWasSubmitted.on();
      setFocus('email');
      reset();

      const body = { email, name, referrerUserId: userId, inviteCode: orgSlug };

      // Not using the auth'd user because it's not used to perform any actions
      // on behalf of the user so it's not critical.
      window.fetch(WAITLIST_URL, {
        method: 'POST',
        body: JSON.stringify(body),
      });
    },
    [orgSlug, userId, setWasSubmitted, reset, setFocus],
  );

  const submissionHandler = handleSubmit(submit);

  useEffect(() => {
    if (!wasSubmitted) {
      return undefined;
    }

    const subscription = watch((values) => {
      if (isEmpty(values)) {
        return;
      }

      const hasAnyValues = Object.values(values).some((v) => v != null && v.trim().length > 0);
      if (hasAnyValues) {
        setWasSubmitted.off();
      }
    });
    return subscription.unsubscribe;
  }, [watch, wasSubmitted, setWasSubmitted]);

  useEffect(() => {
    if (wasSubmitted) {
      const timeout = setTimeout(setWasSubmitted.off, 4000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [wasSubmitted, setWasSubmitted]);

  return (
    <FormProvider {...formMethods}>
      <Flex
        flexGrow={0}
        as="form"
        flexDir="column"
        onSubmit={(e) => {
          submissionHandler(e);
        }}
        alignItems="center"
        rowGap="2rem"
      >
        <ReferralEmailInput />
        <ReferralNameInput />
        <ReferralSubmitButton wasSubmitted={wasSubmitted} />
      </Flex>
    </FormProvider>
  );
};

const TopRowMetadata: React.FC = () => {
  const date = TODAY.toFormat('dd LLL. kkkk');
  return (
    <Flex flexDir="column" rowGap={2}>
      <Flex
        flexDir="row"
        w="full"
        justifyContent="space-between"
        fontWeight="semibold"
        fontSize="xs"
      >
        <Flex alignItems="center">
          <Text color={LIGHT_ACCENT_COLOR}>#</Text>
          <Text>RNW8279</Text>
        </Flex>
        <Flex alignItems="center" columnGap={1}>
          <Text color={LIGHT_ACCENT_COLOR}>DATE </Text>
          <Text>{date}</Text>
        </Flex>
      </Flex>
      <Image draggable={false} alt="bar code" src="/images/bar_code.png" width={288} height={36} />
    </Flex>
  );
};

const PerforatedEdge: React.FC = () => {
  return (
    <Box
      position="absolute"
      left="-1px"
      top="18px"
      bottom="18px"
      w={0}
      borderColor={LIGHT_ACCENT_COLOR}
      borderLeftWidth="2px"
      borderStyle="dashed"
    />
  );
};

const ReferralTicketSection: React.FC<{
  width: BoxProps['width'];
  padding: BoxProps['padding'];
  children: React.ReactNode;
}> = ({ width, padding, children }) => {
  return (
    <Box
      position="relative"
      bg={TICKET_BG}
      flexShrink={0}
      borderRadius="3xl"
      width={width}
      h="25rem"
      p={padding}
    >
      {children}
    </Box>
  );
};

const ReferralEmailInput: React.FC = () => {
  const { register } = useFormContext();
  return <ReferralInput {...register('email')} placeholder="work email" name="email" />;
};

const ReferralNameInput: React.FC = () => {
  const { register } = useFormContext();
  return <ReferralInput {...register('name')} placeholder="name (optional)" name="name" />;
};

const ReferralInput = React.forwardRef<
  HTMLInputElement,
  InputProps & { placeholder: string; name: keyof FormValues }
>(({ placeholder, name, ...rest }, ref) => {
  const { formState } = useFormContext<FormValues>();
  const error = formState.errors[name];
  const hasError = error != null;

  return (
    <Box w="full" h={6} borderBottomWidth="px" borderColor={TICKET_TEXT_BLACK}>
      <Input
        {...rest}
        name={name}
        ref={ref}
        isInvalid={hasError}
        autoComplete="off"
        type="text"
        variant="inline"
        p={0}
        m={0}
        w="full"
        h="full"
        fontSize="sm"
        placeholder={placeholder}
        _placeholder={{
          color: LIGHT_ACCENT_COLOR,
          textTransform: 'uppercase',
          fontWeight: 'semibold',
          fontSize: 'xxs',
        }}
      />
      {error != null && (
        <Text mt={1} fontSize="xs" fontWeight="semibold" color="red.500">
          {error?.message}
        </Text>
      )}
    </Box>
  );
});

const ReferralSubmitButton: React.FC<{ wasSubmitted: boolean }> = ({ wasSubmitted }) => {
  return (
    <Button
      h="fit-content"
      w="full"
      flexDir="row"
      alignItems="center"
      type="submit"
      p={4}
      bg="runway.orange"
      borderWidth={0}
      _hover={{ bg: 'runway.orange' }}
      justifyContent="space-between"
      borderRadius="3xl"
    >
      <Text fontSize="lg" fontWeight="semibold" color={TICKET_TEXT_BLACK}>
        {wasSubmitted ? 'Sent!' : 'Invite to Runway'}
      </Text>
      <Center borderRadius="full" bg={TICKET_TEXT_BLACK}>
        <ArrowForwardIcon boxSize={8} m={1} color="runway.yellow" />
      </Center>
    </Button>
  );
};

const ReferralFlightPath: React.FC = () => {
  return (
    <Flex
      flexDir="row"
      columnGap={2}
      alignItems="center"
      fontSize="sm"
      fontWeight="semibold"
      w="20rem"
      h={8}
    >
      <Text>EXL</Text>
      <Flex flexGrow={1} flexDir="row" alignItems="center" columnGap={2}>
        <Line flexGrow={4} />
        <Center flexGrow={0} px={3} py={1} borderRadius="3xl" bg="runway.orange">
          <PlaneIcon />
        </Center>
        <Line flexGrow={1} />
      </Flex>
      <Text>RNW</Text>
    </Flex>
  );
};

const Line: React.FC<{ flexGrow: BoxProps['flexGrow'] }> = ({ flexGrow }) => (
  <Box
    h={0}
    borderBottomWidth="px"
    borderColor={TICKET_TEXT_BLACK}
    overflow="visible"
    flexGrow={flexGrow}
  />
);
