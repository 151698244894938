import { createContext, useMemo } from 'react';

type ItemContext = {
  numItems: number;
  index: number;
};

//TODO: Deprecate usage of React.cloneElement in VirtualizedListBase.tsx and pass VirtualizedListItemProps via this context instead. https://runway.height.app/T-18648 to track.
export const ListItemContext = createContext<ItemContext>({ numItems: 0, index: -1 });

interface ListItemContextProviderProps {
  index: number;
  numItems: number;
  children: React.ReactNode;
}

export const ListItemContextProvider: React.FC<ListItemContextProviderProps> = ({
  children,
  index,
  numItems,
}) => {
  const context: ItemContext = useMemo(() => ({ numItems, index }), [numItems, index]);
  return <ListItemContext.Provider value={context}>{children}</ListItemContext.Provider>;
};
