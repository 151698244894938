import { RollupType } from 'generated/graphql';

type MultiSelectRollupType =
  | RollupType.MonthlyQuarterly
  | RollupType.MonthlyAnnually
  | RollupType.MonthlyQuarterlyAnnually;

const MULTI_SELECT_ROLLUP_MAPPING = {
  [RollupType.MonthlyQuarterly]: [RollupType.Month, RollupType.Quarter],
  [RollupType.MonthlyAnnually]: [RollupType.Month, RollupType.Annual],
  [RollupType.MonthlyQuarterlyAnnually]: [RollupType.Month, RollupType.Quarter, RollupType.Annual],
};

/**
 * Converts multi-select rollup types to custom rollup types
 * i.e. [MonthlyQuarterlyAnnually] => [Month, Quarter, Annual]
 */
export const convertToCustomRollupTypes = (rollupTypes: RollupType[]) => {
  return rollupTypes.flatMap((rollup) => {
    if (Object.keys(MULTI_SELECT_ROLLUP_MAPPING).includes(rollup)) {
      return MULTI_SELECT_ROLLUP_MAPPING[rollup as MultiSelectRollupType];
    }
    return [rollup];
  });
};
