import React, { FocusEvent, MouseEvent } from 'react';

export const stopEventPropagation = (ev: Event | MouseEvent | FocusEvent | React.KeyboardEvent) =>
  ev.stopPropagation();

export const preventEventDefault = (ev: Event | MouseEvent | FocusEvent | React.KeyboardEvent) => {
  ev.preventDefault();
  ev.stopPropagation();
};

export const isInputKeyboardEvent = (
  ev: KeyboardEvent | React.KeyboardEvent | ClipboardEvent | React.ClipboardEvent,
) => {
  const target = ev.target as HTMLElement;
  const tagName = target?.tagName;
  return (
    tagName === 'INPUT' ||
    tagName === 'TEXTAREA' ||
    target.getAttribute('contenteditable') === 'true'
  );
};

export const isInputSelected = () => {
  const activeEl = document.activeElement;
  if (activeEl == null) {
    return false;
  }
  const tag = activeEl.tagName.toLowerCase();
  return ['textarea', 'input'].includes(tag) || activeEl.getAttribute('contenteditable') === 'true';
};
