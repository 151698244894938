import { createIcon } from '@chakra-ui/react';

const Unlock = createIcon({
  displayName: 'UnlockIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <rect x="4" y="8" width="8" height="6" rx="1" fill="currentColor" />
      <path
        d="M6 8V5C6 4 5.5 3 4 3C2.5 3 2 4 2 5V5.5"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default Unlock;
