import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import FormulaEntityChunk from 'components/Formula/FormulaEntityChunk';
import {
  ObjectFilterDisplayChunk,
  ObjectFormulaDisplayChunk,
} from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { CONTEXT_ATTR, FormulaBooleanOperator, isObjectSpecFiltersEmpty } from 'types/formula';
import { DatabaseFilled } from 'vectors';
import FilterIcon from 'vectors/Filter';

interface Props {
  chunk: ObjectFormulaDisplayChunk;
}

const FORMULA_BOOLEAN_OP_TO_DISPLAY: Record<FormulaBooleanOperator, string> = {
  [FormulaBooleanOperator.Equals]: '=',
  [FormulaBooleanOperator.NotEquals]: '≠',
  [FormulaBooleanOperator.GreaterThan]: '>',
  [FormulaBooleanOperator.LessThan]: '<',
  [FormulaBooleanOperator.GreaterThanOrEqualTo]: '≥',
  [FormulaBooleanOperator.LessThanOrEqualTo]: '≤',
};

const BusinessObjectChunk: React.FC<Props> = ({ chunk }) => {
  const timeRange = chunk.timeRange ?? chunk.field?.timeRange;
  const timeRangeDisplay = chunk.timeRangeDisplay ?? chunk.field?.timeRangeDisplay;
  const showFilters = !isObjectSpecFiltersEmpty(chunk.filters) && chunk.objectId == null;

  return (
    <FormulaEntityChunk
      timeRange={timeRange}
      timeRangeDisplay={timeRangeDisplay}
      icon={<DatabaseFilled color="gray.500" />}
      label={chunk.displayName}
    >
      {showFilters && (
        <Box
          as="span"
          alignItems="center"
          borderColor="gray.300"
          bgColor="gray.100"
          borderRadius="xl"
          borderWidth="px"
          columnGap={1}
          display="inline-flex"
          flexShrink={0}
          fontWeight="medium"
          height={6}
          px={1}
        >
          <FilterIcon color="gray.500" boxSize={4} />
          <Text noOfLines={1} wordBreak="break-all">
            {getCombinedFilterDisplayString(chunk.filters)}
          </Text>
        </Box>
      )}
    </FormulaEntityChunk>
  );
};

function getCombinedFilterDisplayString(filters: ObjectFormulaDisplayChunk['filters']) {
  return [
    ...(filters.matchToSingleResult ? ['match to single result'] : []),
    ...(filters.includeAllContextAttributes ? ['match all context attributes'] : []),
    ...filters.propertyFilters.map((filterChunk) => getChunkFilterDisplayString(filterChunk)),
  ].join(' and ');
}

function getChunkFilterDisplayString(filter: ObjectFilterDisplayChunk): string {
  if (filter.isNull) {
    return `${filter.field.name} ${filter.operator === '==' ? 'is' : 'is not'} none`;
  }

  const operator = FORMULA_BOOLEAN_OP_TO_DISPLAY[filter.operator];
  if (filter.value === CONTEXT_ATTR) {
    return `${filter.field.name} ${operator} context attribute`;
  }
  return `${filter.field.name} ${operator} ${filter.value}`;
}

export default BusinessObjectChunk;
