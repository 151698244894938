import { useCallback, useState } from 'react';

import { SelectItem } from 'components/SelectMenu/SelectMenu';

export function useSubmenuState<T>(defaultSubmenu: T | null) {
  const [submenu, setSubmenu] = useState<T | null>(defaultSubmenu);

  const onSelect = useCallback((item: SelectItem & { id: T }) => {
    setSubmenu(item.id);
  }, []);

  const clearSubmenu = useCallback(() => {
    setSubmenu(null);
  }, []);

  return { submenu, setSubmenu, onSelect, clearSubmenu };
}
