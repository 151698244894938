import React, { useCallback, useContext, useMemo } from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { SelectMenuContext } from 'components/SelectMenu/SelectMenuContext';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import useAppDispatch from 'hooks/useAppDispatch';
import { DEFAULT_EVENT_GROUP_ID } from 'reduxStore/models/events';
import { openDeleteEventGroupDialog } from 'reduxStore/reducers/alertDialogSlice';
import { openPlanDetailPane } from 'reduxStore/reducers/openDetailPane';
import { closePopover } from 'reduxStore/reducers/pageSlice';
import Trash from 'vectors/Trash';
import TrendArrow from 'vectors/TrendArrow';

interface Props {
  eventGroupId: string;
}

const PlanMoreMenu: React.FC<Props> = ({ eventGroupId }: { eventGroupId: string }) => {
  const dispatch = useAppDispatch();
  const { closeParentSelectMenu } = useContext(SelectMenuContext);

  const onSelect = useCallback(
    (item: SelectItem) => {
      switch (item.id) {
        case 'detail-pane':
          dispatch(openPlanDetailPane({ eventGroupId }));
          closeParentSelectMenu?.();
          break;
        case 'delete-plan':
          dispatch(openDeleteEventGroupDialog(eventGroupId));
          dispatch(closePopover());
          break;
        default:
          break;
      }
    },
    [closeParentSelectMenu, dispatch, eventGroupId],
  );

  const items = useMemo(
    () => [
      {
        id: 'detail-pane',
        icon: <TrendArrow direction="up" />,
        name: 'Open Detail Pane',
      },
      {
        id: 'delete-plan',
        icon: <Trash />,
        name: 'Delete Plan',
        isDisabled: eventGroupId === DEFAULT_EVENT_GROUP_ID,
      },
    ],
    [eventGroupId],
  );

  return (
    <SelectMenuContainer>
      <SelectMenu
        maxHeight="auto"
        onSelect={onSelect}
        items={items}
        width="12rem"
        startFocusIdx={-1}
      >
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default React.memo(PlanMoreMenu);
