import { sum } from 'lodash';

import { ExtDriver, ExtDriverEntry, ExtDriverKey } from 'reduxStore/models/extDrivers';

const ROOT_PATH_KEY = '';

export type ExtDriverRow = {
  key: ExtDriverKey;
  depth: number;
  isExpanded: boolean;
  isExpandable: boolean;
  isEmpty: boolean;
};

function driverParentPathKey(extDriver: ExtDriver) {
  return pathToKey(extDriver.path.slice(0, -1));
}

// Special character escaped strings with " " delimeter to prevent any ambiguity
export function pathToKey(path: string[]) {
  return path.map(encodeURIComponent).join(' ');
}

export function keyToPath(key: string) {
  return key.split(' ').map(decodeURIComponent);
}

// TODO: Migrate to using the extDriverNodeTree generated from the backend
// instead of rebuilding the tree here.
export function extDriverEntriesByParentPathKey(
  extDrivers: ExtDriver[],
): NullableRecord<string, ExtDriverEntry[]> {
  const extDriverEntryByParentPathKey: NullableRecord<string, ExtDriverEntry[]> = {};
  extDrivers.forEach((d) => {
    const driverKey = pathToKey(d.path);
    const driverParentKey = driverParentPathKey(d);
    const parentEntries = extDriverEntryByParentPathKey[driverParentKey] ?? [];
    // if we already added a folder for the driver, add the driver to it
    const existingFolder = parentEntries.find((e) => e.pathKey === driverKey);
    if (existingFolder) {
      existingFolder.key = d.id;
      existingFolder.driver = d;
    } else {
      extDriverEntryByParentPathKey[driverParentKey] = [
        ...parentEntries,
        {
          key: d.id,
          path: [...d.path],
          pathKey: pathToKey(d.path),
          driver: d,
        },
      ];
    }

    let path = d.path.slice(0, -1);
    while (path.length >= 1) {
      const parentPath = path.slice(0, -1);
      const key = pathToKey(path);
      const parentKey = pathToKey(parentPath);
      const existing = extDriverEntryByParentPathKey[parentKey];
      // add containing folder as an entry if it does not already exist as a driver
      if (existing == null || !existing.some((e) => e.pathKey === key)) {
        extDriverEntryByParentPathKey[parentKey] = [
          ...(existing ?? []),
          {
            key,
            path: [...path],
            pathKey: key,
            driver: null,
          },
        ];
      }

      path = parentPath;
    }
  });

  return extDriverEntryByParentPathKey;
}

export function getVisibleExtDriverRows(
  entriesByParentPathKey: NullableRecord<ExtDriverKey, ExtDriverEntry[]>,
  expandedDriverKeys: ExtDriverKey[],
): ExtDriverRow[] {
  const rootEntries = entriesByParentPathKey[ROOT_PATH_KEY] ?? [];
  const rows = rootEntries.flatMap((entry) =>
    getVisibleDriverRowsForRoot(entriesByParentPathKey, expandedDriverKeys, entry),
  );

  return rows;
}

function getVisibleDriverRowsForRoot(
  entriesByParentPathKey: NullableRecord<string, ExtDriverEntry[]>,
  expandedDriverKeys: ExtDriverKey[],
  entry: ExtDriverEntry,
): ExtDriverRow[] {
  const children = entriesByParentPathKey[pathToKey(entry.path)] ?? [];
  const isExpanded = expandedDriverKeys.includes(entry.key);
  const rows: ExtDriverRow[] = [
    {
      key: entry.key,
      depth: entry.path.length - 1,
      isExpandable: children.length > 0,
      isExpanded,
      isEmpty: entry.driver == null,
    },
  ];

  if (isExpanded) {
    const childrenRows = children.flatMap((child) =>
      getVisibleDriverRowsForRoot(entriesByParentPathKey, expandedDriverKeys, child),
    );
    rows.push(...childrenRows);
  }

  return rows;
}

export function getTotalNumberOfExternalDriverRows(
  entriesByParentPathKey: NullableRecord<ExtDriverKey, ExtDriverEntry[]>,
): number {
  const rootEntries = entriesByParentPathKey[ROOT_PATH_KEY] ?? [];
  return sum(
    rootEntries.map((entry) =>
      getTotalNumberOfExternalDriverRowsForRoot(entriesByParentPathKey, entry),
    ),
  );
}

function getTotalNumberOfExternalDriverRowsForRoot(
  entriesByParentPathKey: NullableRecord<string, ExtDriverEntry[]>,
  entry: ExtDriverEntry,
): number {
  let numRows = 1;
  const children = entriesByParentPathKey[pathToKey(entry.path)] ?? [];

  numRows =
    numRows +
    sum(
      children.map((child) =>
        getTotalNumberOfExternalDriverRowsForRoot(entriesByParentPathKey, child),
      ),
    );

  return numRows;
}
