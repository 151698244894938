import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, IconButton, Text } from '@chakra-ui/react';

import CloseIcon from 'vectors/Close';

interface Props {
  title: string;
  onClose?: () => void;
  onBack?: () => void;
  showDivider?: boolean;
}

const MenuHeader: React.FC<Props> = ({ title, onClose, onBack, showDivider = true }) => {
  return (
    <Flex flexDir="column" mb="0.125rem" userSelect="none">
      <Flex w="full" alignItems="center" justifyContent="space-between" pb={1}>
        <Flex alignItems="center" columnGap={1}>
          {onBack != null && (
            <IconButton
              variant="text"
              borderRadius="md"
              icon={<ArrowBackIcon color="gray.500" boxSize={4} />}
              aria-label="Back"
              boxSize={6}
              onClick={onBack}
            />
          )}
          <Text fontSize="xs" fontWeight="semibold" ml={onBack == null ? 2 : 0}>
            {title}
          </Text>
        </Flex>
        {onClose != null && (
          <IconButton
            data-testid="menu-header-close-button"
            variant="text"
            borderRadius="md"
            icon={<CloseIcon boxSize={4} />}
            aria-label="Close"
            boxSize={6}
            onClick={onClose}
          />
        )}
      </Flex>
      {showDivider && (
        <Box px={1} mt="0.125rem">
          <Divider />
        </Box>
      )}
    </Flex>
  );
};

export default MenuHeader;
