import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <path
      d="M3.20033 1.6001C3.05888 1.6001 2.92322 1.65629 2.8232 1.75631C2.72318 1.85633 2.66699 1.99198 2.66699 2.13343C2.66699 2.13413 2.66699 2.13482 2.66699 2.13551V8.0001V13.8647C2.66699 13.8654 2.66699 13.8661 2.66699 13.8668C2.66699 14.0082 2.72318 14.1439 2.8232 14.2439C2.92322 14.3439 3.05888 14.4001 3.20033 14.4001C3.31135 14.3998 3.4195 14.3648 3.5097 14.3001L3.51074 14.3011L14.0889 8.48447C14.1817 8.44201 14.2604 8.37379 14.3156 8.28793C14.3708 8.20208 14.4002 8.10218 14.4003 8.0001C14.4004 7.89516 14.3695 7.79254 14.3115 7.70508C14.2535 7.61762 14.171 7.54922 14.0743 7.50843L3.51074 1.69906H3.5097C3.41937 1.63471 3.31123 1.60011 3.20033 1.6001Z"
      fill="currentColor"
    />
  ),
});
