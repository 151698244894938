import React, { useContext, useEffect, useMemo, useState } from 'react';

import CellStyleContextProvider from 'components/CellContextProvider/CellStyleContextProvider';
import { CellSelectionManagerContext } from 'components/CellSelectionManager/CellSelectionManagerContext';
import {
  CellRef,
  CellRefContext,
  CellSelectionStateContext,
  CellValueContext,
  CellValueTooltipData,
  DEFAULT_CELL_SELECTION_STATE,
} from 'config/cells';
import { uuidv4 } from 'helpers/uuidv4';
import useBlockContext from 'hooks/useBlockContext';

interface Props {
  cellRef: CellRef;
  valueTooltip?: CellValueTooltipData;
  children: JSX.Element;
}

const CellContextProvider: React.FC<Props> = ({ cellRef, valueTooltip, children }) => {
  const { blockId } = useBlockContext();
  const [selectionState, setSelectionState] = useState(DEFAULT_CELL_SELECTION_STATE);
  const [cellId, setCellId] = useState(uuidv4());
  const { subscribe, unsubscribe } = useContext(CellSelectionManagerContext);

  const cellRefCtx = useMemo(() => {
    return {
      cellRef,
      cellId,
    };
  }, [cellRef, cellId]);

  // the blockId/cellRef combination should never change, so this useEffect should not get called
  // but this is here to maintain the invariant that the opaque ID is tied to a unique blockId/cellRef combination
  useEffect(() => {
    const id = uuidv4();
    setCellId(id);
    subscribe(id, blockId, cellRef, setSelectionState);
    return () => {
      unsubscribe(id);
    };
  }, [blockId, cellRef, subscribe, unsubscribe]);

  return (
    <CellRefContext.Provider value={cellRefCtx}>
      <CellSelectionStateContext.Provider value={selectionState}>
        <CellStyleContextProvider>
          <CellValueContextProvider valueTooltip={valueTooltip}>
            {children}
          </CellValueContextProvider>
        </CellStyleContextProvider>
      </CellSelectionStateContext.Provider>
    </CellRefContext.Provider>
  );
};

const CellValueContextProvider: React.FC<Pick<Props, 'children' | 'valueTooltip'>> = ({
  children,
  valueTooltip,
}) => {
  const valueCtx = useMemo(() => {
    return {
      valueTooltip,
    };
  }, [valueTooltip]);

  return <CellValueContext.Provider value={valueCtx}>{children}</CellValueContext.Provider>;
};

export default React.memo(CellContextProvider);
