import { Box, WrapItem } from '@chakra-ui/react';
import React from 'react';

import DriverBlockEmptyStateButton from 'components/DriverBlockEmptyState/DriverBlockEmptyStateButton';
import DriverChartBorder from 'components/DriverCharts/DriverChartBorder';
import ARRChartIcon from 'vectors/blockEmptyState/ARRChart';
import CashChartIcon from 'vectors/blockEmptyState/CashChart';
import RunwayChartIcon from 'vectors/blockEmptyState/RunwayChart';

interface Props {
  onOpenDriverSelect: () => void;
  isAdding: boolean;
}

const DriverChartsBlockEmptyState: React.FC<Props> = ({ onOpenDriverSelect, isAdding }) => {
  return (
    <>
      <Box
        pointerEvents="none"
        position="absolute"
        top={0}
        w="full"
        borderColor="gray.300"
        borderStyle="dashed"
        borderTopWidth="px"
      />
      {[ARRChartIcon, RunwayChartIcon, CashChartIcon].map((Icon, idx) => (
        <WrapItem key={idx} role="group" position="relative" pointerEvents="none">
          <Box padding={2}>
            <Icon />
          </Box>
          <DriverChartBorder />
        </WrapItem>
      ))}
      <DriverBlockEmptyStateButton onOpenDriverSelect={onOpenDriverSelect} isAdding={isAdding} />
    </>
  );
};

export default DriverChartsBlockEmptyState;
