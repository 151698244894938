import sum from 'lodash/sum';
import zipObject from 'lodash/zipObject';
import { DateTime } from 'luxon';
import { createCachedSelector } from 're-reselect';

import { ValueType } from 'generated/graphql';
import { getDateTimeFromMonthKey, getMonthKey, getMonthKeysForRange } from 'helpers/dates';
import { calculate } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { CacheKey } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaCalculationContext } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCalculationContext';
import { FormulaEvaluator } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import { EvaluatorDriver } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { addLayerParams, layerParamMemo } from 'helpers/layerSelectorFactory';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { EventId } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import {
  ErrorTimeSeries,
  NonNumericTimeSeries,
  NonValueTimeSeries,
  NumericTimeSeries,
  valueToNonNumericTimeSeries,
  valueToNumericTimeSeries,
  valuesWithContextToNonValueTimeSeries,
} from 'reduxStore/models/timeSeries';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  entityErrorTimeSeriesSelector,
  entityTimeSeriesSelector,
  impactTimeSeriesSelector,
} from 'selectors/calculationsSelector';
import { fieldSelector } from 'selectors/constSelectors';
import { chartDisplayMonthKeyWithDefaultSelector } from 'selectors/driverChartSelectors';
import {
  DriverForLayerProps,
  cacheKeyForDriverForLayerSelector,
  driverActualsOverrideMonthKeysSelector,
  driverHasActualsFormulaSelector,
  evaluatorDriverSelector,
} from 'selectors/driversSelector';
import { formulaCalculationContextSelector } from 'selectors/formulaCalculationContextSelector';
import {
  formulaEvaluatorForLayerSelector,
  formulaEvaluatorWithoutLiveEditingSelector,
} from 'selectors/formulaEvaluatorSelector';
import { shouldDoSynchronousCalculationsSelector } from 'selectors/inspectorSelector';
import {
  datasetFiveYearsFromLastActualsDateTimeRangeSelector,
  datasetLastActualsDateTimeSelector,
  lastActualsMonthKeyForLayerSelector,
} from 'selectors/lastActualsSelector';
import { currentLayerIdSelector, getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import {
  monthKeysForPageDateRangeSelector,
  pageDateRangeDateTimeSelector,
} from 'selectors/pageDateRangeSelector';
import {
  blockImpactSortByExtensionForBlockSelector,
  selectedEventIdsMaybeWithRowEventsSelector,
} from 'selectors/planTimelineSelector';
import { ValueWithCalculationContext, isCalculationValue } from 'types/dataset';
import { MonthKey } from 'types/datetime';
import { ParametricSelector } from 'types/redux';

export function checkNonNumericDriverTimeseriesCalculationSupport(timeseries: NonValueTimeSeries) {
  if (Object.values(timeseries).some((val) => typeof val !== 'number')) {
    throw new Error('Expected all calculations to be numbers');
  }

  return timeseries as NumericTimeSeries;
}

export function runCalculations({
  driverId,
  dateRange,
  evaluator,
  context,
  newlyAddedCacheKeys,
  options: { selectedEventIds } = {},
}: {
  driverId: DriverId;
  dateRange: DateTime[];
  evaluator: FormulaEvaluator;
  context?: FormulaCalculationContext;
  newlyAddedCacheKeys: Set<CacheKey>;
  options?: {
    selectedEventIds?: EventId[];
  };
}): Map<MonthKey, ValueWithCalculationContext> {
  const [startDateTime, endDateTime] = dateRange;

  // When viewing the graph, the line should extend to the y-axis line, so we
  // need to ensure we have one data point preceding the start month.
  const monthKeys = getMonthKeysForRange(startDateTime.minus({ months: 1 }), endDateTime);

  evaluator.invalidateCache();

  const ignoreEventIds = new Set<EventId>(selectedEventIds);

  const values = new Map<MonthKey, ValueWithCalculationContext>();
  monthKeys.forEach((monthKey: MonthKey) => {
    const calcResult = calculate({
      evaluator,
      context,
      entityId: { type: 'driver', id: driverId },
      monthKey,
      visited: new Set(),
      ignoreEventIds,
      newlyAddedCacheKeys,
    });

    const value = isCalculationValue(calcResult) ? calcResult : undefined;

    if (value != null) {
      values.set(monthKey, value);
    }
  });

  return values;
}

export function totalImpactOnSortDriver({
  evaluatorDriversById,
  impactSort,
  evaluator,
  context,
  eventIds,
}: {
  evaluatorDriversById: Record<DriverId, EvaluatorDriver | undefined>;
  impactSort: ReturnType<typeof blockImpactSortByExtensionForBlockSelector>;
  evaluator: FormulaEvaluator;
  context?: FormulaCalculationContext;
  eventIds: EventId[];
}) {
  if (impactSort == null) {
    throw new Error('impact sort undefined');
  }

  const { driverId, startMonthKey, endMonthKey } = impactSort;
  const dateRange = [startMonthKey, endMonthKey];
  const driver = evaluatorDriversById[driverId];
  if (driver?.type !== DriverType.Basic) {
    throw new Error(`no basic driver found with ID ${driverId}`);
  }

  const [startDateTime, endDateTime] = dateRange.map((mk) => getDateTimeFromMonthKey(mk));
  const timeSeriesWithoutEvents = runCalculations({
    driverId,
    dateRange: [startDateTime, endDateTime],
    evaluator,
    context,
    newlyAddedCacheKeys: new Set(),
    options: {
      selectedEventIds: eventIds,
    },
  });

  const timeSeriesWithEvents = runCalculations({
    driverId,
    dateRange: [startDateTime, endDateTime],
    evaluator,
    context,
    newlyAddedCacheKeys: new Set(),
    options: {},
  });

  return sum(Object.values(timeSeriesWithEvents)) - sum(Object.values(timeSeriesWithoutEvents));
}
const EMPTY_TIMESERIES: NumericTimeSeries = {};

/**
 * The entire driver time series, including both actuals & forecasts. Forecasts always ignore actuals
 * that are after "last close". Forecasts have live-updated events applied.
 *
 */
interface DriverTimeSeriesForLayerWithMonthKeysProps extends DriverForLayerProps {
  start: MonthKey;
  end: MonthKey;
}

type DriverTimeSeriesSyncProps = DriverTimeSeriesForLayerWithMonthKeysProps;

const driverTimeSeriesForLayerSyncCalculationsSelector: ParametricSelector<
  DriverTimeSeriesSyncProps,
  NumericTimeSeries
> = createCachedSelector(
  (state: RootState, props: DriverTimeSeriesSyncProps) => evaluatorDriverSelector(state, props.id),
  fieldSelector('start'),
  fieldSelector('end'),
  addLayerParams(formulaEvaluatorForLayerSelector),
  formulaCalculationContextSelector,
  // eslint-disable-next-line max-params
  function driverTimeSeriesForLayerSyncCalculationsSelector(
    driver,
    startMonthKey,
    endMonthKey,
    evaluator,
    context,
  ) {
    if (driver == null || driver.type !== DriverType.Basic) {
      return EMPTY_TIMESERIES;
    }

    const dateRange: [DateTime, DateTime] = [
      getDateTimeFromMonthKey(startMonthKey),
      getDateTimeFromMonthKey(endMonthKey),
    ];

    const calculations = runCalculations({
      driverId: driver.id,
      dateRange,
      evaluator,
      context,
      newlyAddedCacheKeys: new Set(),
      options: {},
    });

    const nonValueTimeSeries = valuesWithContextToNonValueTimeSeries(calculations);
    return checkNonNumericDriverTimeseriesCalculationSupport(nonValueTimeSeries);
  },
)(
  (state, props: DriverTimeSeriesForLayerWithMonthKeysProps) =>
    `${cacheKeyForDriverForLayerSelector(state, props)},${props.start},${props.end}`,
);

// Takes an optional start and end and will default to the page date range. The
// range is inclusive on both ends.
export const driverTimeSeriesForLayerSelector: ParametricSelector<
  DriverForLayerProps & {
    start?: MonthKey;
    end?: MonthKey;
  },
  NumericTimeSeries
> = function driverTimeSeriesForLayerSelector(state, props) {
  const { id, layerId } = props;
  const shouldDoSynchronousCalculations = shouldDoSynchronousCalculationsSelector(state);

  const dateRange = pageDateRangeDateTimeSelector(state);
  const start = props.start ?? getMonthKey(dateRange[0]);
  const end = props.end ?? getMonthKey(dateRange[1]);

  if (!shouldDoSynchronousCalculations) {
    return valueToNumericTimeSeries(
      entityTimeSeriesSelector(state, {
        id,
        start,
        end,
        layerId: getGivenOrCurrentLayerId(state, props),
      }),
    );
  }

  return driverTimeSeriesForLayerSyncCalculationsSelector(state, {
    id,
    layerId,
    start,
    end,
  });
};

export const driverNonNumericTimeSeriesForLayerSelector: ParametricSelector<
  DriverForLayerProps & {
    start?: MonthKey;
    end?: MonthKey;
  },
  NonNumericTimeSeries
> = (state, { id, layerId, start, end }) => {
  const dateRange = pageDateRangeDateTimeSelector(state);

  if (start == null) {
    start = getMonthKey(dateRange[0]);
  }
  if (end == null) {
    end = getMonthKey(dateRange[1]);
  }
  if (layerId == null) {
    layerId = currentLayerIdSelector(state);
  }

  const timeSeries = entityTimeSeriesSelector(state, {
    id,
    start,
    end,
    layerId,
  });
  return valueToNonNumericTimeSeries(timeSeries);
};

/*
 * NOTE: Only works for backend powered calcs
 */
export const driverErrorTimeSeriesForLayerSelector: ParametricSelector<
  DriverForLayerProps & {
    start?: MonthKey;
    end?: MonthKey;
  },
  ErrorTimeSeries
> = function driverErrorTimeSeriesForLayerSelector(state, props) {
  const { id } = props;

  const dateRange = pageDateRangeDateTimeSelector(state);
  const start = props.start ?? getMonthKey(dateRange[0]);
  const end = props.end ?? getMonthKey(dateRange[1]);

  return entityErrorTimeSeriesSelector(state, {
    id,
    start,
    end,
    layerId: getGivenOrCurrentLayerId(state, props),
  });
};

type DriverValueForMonthKeyProps = {
  driverId: DriverId;
  monthKey: MonthKey;
  layerId?: LayerId;
};

export const driverValueForMonthKeySelector: ParametricSelector<
  DriverValueForMonthKeyProps,
  number | undefined
> = createCachedSelector(
  fieldSelector('monthKey'),
  (state: RootState, props: DriverValueForMonthKeyProps) =>
    driverTimeSeriesForLayerSelector(state, {
      id: props.driverId,
      start: props.monthKey,
      end: props.monthKey,
      layerId: props.layerId,
    }),
  function driverValueForMonthKeySelector(monthKey, ts) {
    const value = ts[monthKey];
    return typeof value === 'number' ? value : undefined;
  },
)(
  (state, props: DriverValueForMonthKeyProps) =>
    `${props.monthKey},${props.driverId},${props.layerId}`,
);

export const driverTimeSeriesSourceByMonthKeySelector: ParametricSelector<
  DriverForLayerProps,
  Record<MonthKey, 'actuals' | 'forecast'>
> = createCachedSelector(
  (state: RootState) => monthKeysForPageDateRangeSelector(state),
  (state: RootState, { layerId }: DriverForLayerProps) =>
    lastActualsMonthKeyForLayerSelector(state, layerParamMemo(layerId)),
  driverHasActualsFormulaSelector,
  driverActualsOverrideMonthKeysSelector,
  function driverTimeSeriesSourceByMonthKeySelector(
    monthKeys,
    lastActualsMonthKey,
    hasActualsFormula,
    actualsOverrideMonthKeys,
  ) {
    const sources = monthKeys.map((mk) => {
      if (mk > lastActualsMonthKey) {
        return 'forecast';
      } else if (hasActualsFormula) {
        return 'actuals';
      }

      return actualsOverrideMonthKeys.has(mk) ? 'actuals' : 'forecast';
    });

    return zipObject(monthKeys, sources);
  },
)(cacheKeyForDriverForLayerSelector);

export const driverTimeSeriesSelector = driverTimeSeriesForLayerSelector;

/**
 * An extended driver time series, meant for computing when tagged drivers hit a given milestone. Should be
 * avoided when presenting data, since we can compute a more limited subset of timeseries in that case
 */
const extendedDriverTimeSeriesForLayerSyncCalculationsSelector: ParametricSelector<
  DriverId,
  NumericTimeSeries
> = createCachedSelector(
  evaluatorDriverSelector,
  datasetLastActualsDateTimeSelector,
  (state: RootState) =>
    formulaEvaluatorForLayerSelector(state, {
      layerId: state.dataset.currentLayerId,
    }),
  (state: RootState) => formulaCalculationContextSelector(state),
  function extendedDriverTimeSeriesForLayerSyncCalculationsSelector(
    driver,
    lastActual,
    evaluator,
    context,
  ) {
    if (driver == null || driver.type !== DriverType.Basic) {
      return EMPTY_TIMESERIES;
    }

    const forecastTimeSeries = runCalculations({
      driverId: driver.id,
      dateRange: [lastActual, lastActual.plus({ years: 5 })],
      evaluator,
      context,
      newlyAddedCacheKeys: new Set(),
    });

    const nonValueTimeSeries = valuesWithContextToNonValueTimeSeries(forecastTimeSeries);
    return checkNonNumericDriverTimeseriesCalculationSupport(nonValueTimeSeries);
  },
)((_state, driverId) => driverId);

export const extendedDriverTimeSeriesSelector: ParametricSelector<DriverId, NumericTimeSeries> =
  function extendedDriverTimeSeriesSelector(state, driverId) {
    const shouldDoSynchronousCalculations = shouldDoSynchronousCalculationsSelector(state);
    if (!shouldDoSynchronousCalculations) {
      const [start, end]: [DateTime, DateTime] =
        datasetFiveYearsFromLastActualsDateTimeRangeSelector(state);
      const startMonthKey = getMonthKey(start);
      const endMonthKey = getMonthKey(end);
      return valueToNumericTimeSeries(
        entityTimeSeriesSelector(state, {
          id: driverId,
          start: startMonthKey,
          end: endMonthKey,
          layerId: getGivenOrCurrentLayerId(state, undefined),
        }),
      );
    }

    return extendedDriverTimeSeriesForLayerSyncCalculationsSelector(state, driverId);
  };

function driverTimeSeriesWithoutLiveEdits(
  driver: EvaluatorDriver | undefined,
  dateRange: [DateTime, DateTime],
  evaluator: FormulaEvaluator,
  context: FormulaCalculationContext | undefined,
) {
  if (driver == null || driver.type !== DriverType.Basic) {
    return EMPTY_TIMESERIES;
  }

  const forecastTimeSeries = runCalculations({
    driverId: driver.id,
    dateRange,
    evaluator,
    context,
    newlyAddedCacheKeys: new Set(),
  });
  const nonValueTimeSeries = valuesWithContextToNonValueTimeSeries(forecastTimeSeries);
  return checkNonNumericDriverTimeseriesCalculationSupport(nonValueTimeSeries);
}

/**
 * The entire driver time series, including both actuals & forecasts. Forecasts always ignore actuals
 * that are after "last close". Forecasts have persisted events applied.
 */
const driverTimeSeriesWithoutLiveEditsSyncCalculationsSelector: ParametricSelector<
  DriverId,
  NumericTimeSeries
> = createCachedSelector(
  evaluatorDriverSelector,
  pageDateRangeDateTimeSelector,
  // This evaluator does not use the live updating values
  (state: RootState) => formulaEvaluatorWithoutLiveEditingSelector(state),
  (state: RootState) => formulaCalculationContextSelector(state),
  driverTimeSeriesWithoutLiveEdits,
)((_state, driverId) => driverId);

export const driverTimeSeriesWithoutLiveEditsSelector: ParametricSelector<
  { driverId: DriverId; start?: MonthKey; end?: MonthKey },
  NumericTimeSeries
> = function driverTimeSeriesWithoutLiveEditsSelector(state, props) {
  const { driverId } = props;

  const dateRange = pageDateRangeDateTimeSelector(state);
  const start = props.start ?? getMonthKey(dateRange[0]);
  const end = props.end ?? getMonthKey(dateRange[1]);
  const shouldDoSynchronousCalculations = shouldDoSynchronousCalculationsSelector(state);
  if (!shouldDoSynchronousCalculations) {
    return valueToNumericTimeSeries(
      entityTimeSeriesSelector(state, {
        id: driverId,
        start,
        end,
        layerId: state.dataset.currentLayerId,
      }),
    );
  }

  return driverTimeSeriesWithoutLiveEditsSyncCalculationsSelector(state, driverId);
};

type DriverTimeSeriesForImpactShadingSyncProps = {
  driverId: DriverId;
  blockId: BlockId;
  includeEventsInSameRow: boolean;
};
const cacheKeyForDriverTimeSeriesForImpactShadingProps = (
  _state: RootState,
  { driverId, blockId, includeEventsInSameRow }: DriverTimeSeriesForImpactShadingSyncProps,
) => `${blockId}:${driverId}:${includeEventsInSameRow}`;

const driverTimeSeriesForImpactShadingSyncSelector: ParametricSelector<
  DriverTimeSeriesForImpactShadingSyncProps,
  NumericTimeSeries
> = createCachedSelector(
  (state: RootState, props: DriverTimeSeriesForImpactShadingSyncProps) =>
    evaluatorDriverSelector(state, props.driverId),
  pageDateRangeDateTimeSelector,
  (state) => formulaEvaluatorForLayerSelector(state),
  (state) => formulaCalculationContextSelector(state),
  (state: RootState, props: DriverTimeSeriesForImpactShadingSyncProps) =>
    selectedEventIdsMaybeWithRowEventsSelector(state, {
      blockId: props.blockId,
      includeEventsInSameRow: props.includeEventsInSameRow,
    }),
  // eslint-disable-next-line max-params
  function driverTimeSeriesForImpactShadingSyncSelector(
    driver,
    dateRange,
    evaluator,
    context,
    selectedEventIds,
  ) {
    if (driver == null || driver.type !== DriverType.Basic) {
      return EMPTY_TIMESERIES;
    }

    const forecastTimeSeries = runCalculations({
      driverId: driver.id,
      dateRange,
      evaluator,
      context,
      newlyAddedCacheKeys: new Set(),
      options: {
        selectedEventIds,
      },
    });

    const nonValueTimeSeries = valuesWithContextToNonValueTimeSeries(forecastTimeSeries);
    return checkNonNumericDriverTimeseriesCalculationSupport(nonValueTimeSeries);
  },
)(cacheKeyForDriverTimeSeriesForImpactShadingProps);

type DriverTimeSeriesForImpactShadingProps = DriverTimeSeriesForImpactShadingSyncProps;

export const driverTimeSeriesForImpactShadingSelector: ParametricSelector<
  DriverTimeSeriesForImpactShadingProps,
  NumericTimeSeries
> = function driverTimeSeriesForImpactShadingSelector(
  state,
  { driverId, includeEventsInSameRow, blockId },
) {
  const shouldDoSynchronousCalculations = shouldDoSynchronousCalculationsSelector(state);

  if (!shouldDoSynchronousCalculations) {
    const dateRange = pageDateRangeDateTimeSelector(state);
    const start = getMonthKey(dateRange[0]);
    const end = getMonthKey(dateRange[1]);

    const ignoreEventIds = selectedEventIdsMaybeWithRowEventsSelector(state, {
      blockId,
      includeEventsInSameRow,
    });

    return valueToNumericTimeSeries(
      impactTimeSeriesSelector(state, {
        id: driverId,
        start,
        end,
        ignoreEventIds,
      }),
    );
  }

  return driverTimeSeriesForImpactShadingSyncSelector(state, {
    driverId,
    blockId,
    includeEventsInSameRow,
  });
};

export const driverImpactShadingCursorValueSelector: ParametricSelector<
  DriverTimeSeriesForImpactShadingProps,
  number | undefined
> = createCachedSelector(
  driverTimeSeriesForImpactShadingSelector,
  chartDisplayMonthKeyWithDefaultSelector,
  function driverImpactShadingCursorValueSelector(timeSeries, monthKey) {
    const value = timeSeries[monthKey];
    return typeof value === 'number' ? value : undefined;
  },
)(cacheKeyForDriverTimeSeriesForImpactShadingProps);

type DriverValueForImpactDisplayProps = {
  driverId: DriverId;
  monthKey: MonthKey;
  eventIdToIgnore: EventId;
};

const driverValueForImpactDisplaySyncSelector: ParametricSelector<
  DriverValueForImpactDisplayProps,
  number | undefined
> = createCachedSelector(
  (state: RootState, { driverId }: DriverValueForImpactDisplayProps) =>
    evaluatorDriverSelector(state, driverId),
  fieldSelector('monthKey'),
  fieldSelector('eventIdToIgnore'),
  (state: RootState, _props: DriverValueForImpactDisplayProps) =>
    formulaEvaluatorWithoutLiveEditingSelector(state),
  (state) => formulaCalculationContextSelector(state),
  // eslint-disable-next-line max-params
  function driverValueForImpactDisplaySyncSelector(
    driver,
    monthKey,
    eventIdToIgnore,
    evaluator,
    context,
  ) {
    if (driver == null || driver.type !== DriverType.Basic) {
      return undefined;
    }

    const dateTime = getDateTimeFromMonthKey(monthKey);
    const forecastTimeSeries = runCalculations({
      driverId: driver.id,
      dateRange: [dateTime, dateTime],
      evaluator,
      context,
      newlyAddedCacheKeys: new Set(),
      options: {
        selectedEventIds: [eventIdToIgnore],
      },
    });

    const value = forecastTimeSeries.get(monthKey);
    return value?.type === ValueType.Number ? value?.value : undefined;
  },
)(
  (_state, props: DriverValueForImpactDisplayProps) =>
    `${props.eventIdToIgnore},${props.monthKey},${props.driverId}`,
);

export const driverValueForImpactDisplaySelector: ParametricSelector<
  DriverValueForImpactDisplayProps,
  number | undefined
> = function driverValueForImpactDisplaySelector(
  state: RootState,
  props: DriverValueForImpactDisplayProps,
) {
  const shouldDoSynchronousCalculations = shouldDoSynchronousCalculationsSelector(state);
  if (!shouldDoSynchronousCalculations) {
    return valueToNumericTimeSeries(
      impactTimeSeriesSelector(state, {
        id: props.driverId,
        start: props.monthKey,
        end: props.monthKey,
        ignoreEventIds: [props.eventIdToIgnore],
      }),
    )[props.monthKey];
  }
  return driverValueForImpactDisplaySyncSelector(state, props);
};
