import { Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

import {
  AccessControlEntry as GQLAccessControlEntry,
  AccessorEntity as GQLAccessorEntity,
  AccessResource as GQLAccessResource,
  AccessResourcesQuery as GQLAccessResourcesForEntityQuery,
} from 'generated/graphql';
import {
  AccessControlEntry,
  AccessorEntity,
  AccessResource,
} from 'reduxStore/models/accessResources';
import { SharingSliceState } from 'reduxStore/reducers/sharingSlice';

export function setAccessResourcesFromInitialLoad(
  state: Draft<SharingSliceState>,
  initialData: GQLAccessResourcesForEntityQuery['accessResources'],
) {
  const accessResourcesList = initialData.map((gqlRes) => {
    const res: AccessResource = mapAccessResource(gqlRes);
    return res;
  });
  state.accessResources = {
    byId: keyBy(accessResourcesList, 'id'),
    allIds: accessResourcesList.map((o) => o.id),
  };
}

function mapAccessControlEntry(gqlEntry: GQLAccessControlEntry): AccessControlEntry {
  const { accessRule, grantedAt, grantedBy } = gqlEntry;
  return {
    accessRule,
    grantedAt,
    grantedBy,
    entityWithAccess: mapAccessorEntity(gqlEntry.entityWithAccess),
  };
}

function mapAccessorEntity(gqlAccessorEntity: GQLAccessorEntity): AccessorEntity {
  const { id, type } = gqlAccessorEntity;
  return { id, type };
}

function mapAccessResource(gqlResource: GQLAccessResource): AccessResource {
  const { id, resourceId, parentId, orgId, shouldInherit, type } = gqlResource;
  return {
    id,
    resourceId,
    parentId: parentId ?? undefined,
    orgId,
    shouldInherit,
    type,
    accessControlList: gqlResource.accessControlList.map((gqlEntry) =>
      mapAccessControlEntry(gqlEntry),
    ),
  };
}
