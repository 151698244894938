import { deepEqual } from 'fast-equals';
import { fromPairs, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { getHeight, getWidth, SERIES_COLORS } from 'components/AgGridComponents/AgChart/agCharts';
import { ChartDisplay } from 'generated/graphql';
import { getMonthKey, getMonthKeysForRange } from 'helpers/dates';
import { applyRollupReducer } from 'helpers/rollups';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useAppStore from 'hooks/useAppStore';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { DEFAULT_DISPLAY_CONFIGURATION, DisplayConfiguration } from 'reduxStore/models/value';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { entityLoadingAnyMonthInRangeSelector } from 'selectors/calculationsSelector';
import {
  attributesBySubDriverIdSelector,
  driverNamesByIdSelector,
} from 'selectors/driversSelector';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { currentLayerIdSelector, layersSelector } from 'selectors/layerSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { driverRollupReducerSelector } from 'selectors/rollupSelector';

function isDisplayConfigurationsEqual(
  a: DisplayConfiguration | undefined,
  b: DisplayConfiguration | undefined,
) {
  return (
    a != null &&
    b != null &&
    a.comparisonType === b.comparisonType &&
    a.currency === b.currency &&
    a.decimalPlaces === b.decimalPlaces &&
    a.format === b.format &&
    a.negativeDisplay === b.negativeDisplay
  );
}

/**
 * Custom hook that returns the configuration for the provided chart drivers.
 *
 * @param blockId - The ID of the block.
 * @param driverIds - An array of driver IDs.
 * @returns An object containing the configuration for the chart driver.
 */
export function useChartDriverConfig(
  blockId: string,
  driverIds: DriverId[],
  chartDisplay: ChartDisplay,
) {
  // Global State
  const { getState } = useAppStore();
  const driverNamesById = useAppSelector(driverNamesByIdSelector);
  const attributesBySubDriverId = useAppSelector(attributesBySubDriverIdSelector);
  const chartConfig = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));

  // Computed State
  const width = useMemo(() => getWidth(chartConfig.chartSize), [chartConfig.chartSize]);
  const height = useMemo(() => getHeight(chartConfig.chartSize), [chartConfig.chartSize]);

  const driverIdBySeriesId = useMemo(() => {
    return chartDisplay.series.reduce<Record<string, string>>((acc, series) => {
      acc[series.id] = series.driverId;
      return acc;
    }, {});
  }, [chartDisplay.series]);

  const driverDisplayConfigurationsById = useMemo(() => {
    return fromPairs(
      driverIds.map((driverId) => [
        driverId,
        driverDisplayConfigurationSelector(getState(), driverId),
      ]),
    );
  }, [driverIds, getState]);

  const groupDisplayConfigurationsById = useMemo(() => {
    return chartDisplay.groups.reduce(
      (acc, group) => {
        const consensusDisplayConfig = group.seriesIds
          .map((seriesId) => safeObjGet(driverIdBySeriesId[seriesId]))
          .filter(isNotNull)
          .map((id) => safeObjGet(driverDisplayConfigurationsById[id]))
          .reduce<DisplayConfiguration | undefined>((displayConfiguration, childDisplayConfig) => {
            if (typeof displayConfiguration === 'undefined') {
              return childDisplayConfig;
            }
            if (isDisplayConfigurationsEqual(childDisplayConfig, displayConfiguration)) {
              return childDisplayConfig;
            }
            return DEFAULT_DISPLAY_CONFIGURATION;
          }, undefined);

        acc[group.id] = consensusDisplayConfig ?? DEFAULT_DISPLAY_CONFIGURATION;

        return acc;
      },
      {} as Record<string, DisplayConfiguration>,
    );
  }, [chartDisplay.groups, driverDisplayConfigurationsById, driverIdBySeriesId]);

  const totalDisplayConfiguration = useMemo(() => {
    const consensusDisplayConfig = chartDisplay.groups
      .map((group) => groupDisplayConfigurationsById[group.id])
      .reduce<DisplayConfiguration | undefined>((displayConfiguration, childDisplayConfig) => {
        if (typeof displayConfiguration === 'undefined') {
          return childDisplayConfig;
        }
        if (isDisplayConfigurationsEqual(childDisplayConfig, displayConfiguration)) {
          return childDisplayConfig;
        }
        return DEFAULT_DISPLAY_CONFIGURATION;
      }, undefined);

    return consensusDisplayConfig ?? DEFAULT_DISPLAY_CONFIGURATION;
  }, [chartDisplay.groups, groupDisplayConfigurationsById]);

  const colorById = useMemo(() => {
    const colorables = [...chartDisplay.series, ...chartDisplay.groups];
    const colors = colorables.map((colorable) => colorable.color as string | null | undefined);
    const groupIds = chartDisplay.groups.map((g) => g.id);

    return [...driverIds, ...groupIds].reduce<Record<string, string>>((acc, id, index) => {
      acc[id] = colors[index] ?? SERIES_COLORS[index % SERIES_COLORS.length].value;
      return acc;
    }, {});
  }, [chartDisplay.series, chartDisplay.groups, driverIds]);

  const groupNamesById = useMemo(() => {
    return chartDisplay.groups.reduce<Record<string, string>>((acc, group, index) => {
      acc[group.id] = group.name ?? `Unnamed Group ${index + 1}`;
      return acc;
    }, {});
  }, [chartDisplay.groups]);

  return {
    attributesBySubDriverId,
    chartConfig,
    colorById,
    currentLayerId,
    dateRange,
    driverDisplayConfigurationsById,
    driverNamesById,
    groupDisplayConfigurationsById,
    groupNamesById,
    height,
    totalDisplayConfiguration,
    width,
  };
}

/**
 * Custom hook for retrieving time series data for a given set of driver IDs and date range.
 *
 * @param driverIds - An array of driver IDs.
 * @param dateRange - A tuple containing the start and end date.
 * @param chartDisplay - Optional chart display configuration.
 * @returns An object containing the loading status, driver time series data, and attributes by sub-driver ID.
 */
export function useChartDriverTimeSeriesData(
  driverIds: DriverId[],
  [start, end]: [DateTime, DateTime],
  chartDisplay?: ChartDisplay,
) {
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const layersById = useAppSelector(layersSelector);
  const isAnyDriverLoading = useAppSelector((state) =>
    driverIds.some((driverId) =>
      entityLoadingAnyMonthInRangeSelector(state, {
        id: driverId,
        monthKeys: getMonthKeysForRange(start, end),
      }),
    ),
  );
  const allLayerIds = useMemo(
    () =>
      uniq([
        currentLayerId,
        ...(chartDisplay?.groups
          .map((g) => g.layerId)
          .filter(isNotNull)
          .filter((id) => layersById[id] != null && !layersById[id].isDeleted) ?? []),
      ]),
    [chartDisplay?.groups, currentLayerId, layersById],
  );
  const driverTimeSeries = useAppSelector((state) => {
    if (isAnyDriverLoading) {
      return null;
    }
    return Object.fromEntries(
      allLayerIds.map((layerId) => [
        layerId,
        Object.fromEntries(
          driverIds.map((id) => [
            id,
            driverTimeSeriesForLayerSelector(state, {
              id,
              start: getMonthKey(start),
              end: getMonthKey(end),
              layerId,
            }),
          ]),
        ),
      ]),
    );
  }, deepEqual);

  useRequestCellValue({
    ids: driverIds,
    type: 'driver',
    dateRange: [start, end],
  });

  const data = useMemo(() => {
    if (!driverTimeSeries || !chartDisplay) {
      return null;
    }

    const seriesData: Array<Record<string, any>> = [];

    const monthKeys = getMonthKeysForRange(start, end);
    for (const monthKey of monthKeys) {
      const point: Record<string, any> = {
        monthKey,
      };

      const dataSeries = chartDisplay.series
        // Only keep drivers relevant to this chart.
        .filter((s) =>
          s.driverId == null
            ? // Backwards compatible use.
              driverIds.includes(s.id)
            : // Preferred use.
              driverIds.includes(s.driverId),
        );
      for (const { id, driverId } of dataSeries) {
        const group = chartDisplay.groups.find(
          (g) =>
            g.seriesIds.includes(id) ||
            // backwards compatible
            g.seriesIds.includes(driverId),
        );
        if (!group) {
          continue;
        }

        let layerId: LayerId;
        if (group.layerId != null) {
          layerId = group.layerId;
        } else {
          layerId = currentLayerId;
        }

        point[driverId] = driverTimeSeries[layerId]?.[driverId]?.[monthKey];
      }

      seriesData.push(point);
    }

    return seriesData;
  }, [driverTimeSeries, start, end, driverIds, currentLayerId, chartDisplay]);

  return {
    isAnyDriverLoading,
    driverTimeSeries,
    currentLayerId,
    data,
  };
}

/**
 * Custom hook that retrieves aggregated data for a chart driver.
 *
 * @param driverIds - An array of driver IDs.
 * @param [start, end] - A tuple representing the start and end date/time range.
 * @param chartDisplay - Optional chart display configuration.
 * @returns An object containing the aggregated data, along with other related properties.
 */
export function useChartDriverAggregatedData(
  driverIds: DriverId[],
  [start, end]: [DateTime, DateTime],
  chartDisplay?: ChartDisplay,
) {
  const { isAnyDriverLoading, driverTimeSeries, currentLayerId } = useChartDriverTimeSeriesData(
    driverIds,
    [start, end],
    chartDisplay,
  );

  const rollupReducers = useAppSelector((state) => {
    return Object.fromEntries(
      driverIds.map((driverId) => {
        return [driverId, driverRollupReducerSelector(state, { id: driverId })];
      }),
    );
  }, deepEqual);

  const data = useMemo(() => {
    if (!driverTimeSeries) {
      return null;
    }

    const monthKeys = getMonthKeysForRange(start, end);

    const result = driverIds.map((driverId) => {
      const value = applyRollupReducer({
        monthKeys,
        values: driverTimeSeries[currentLayerId]?.[driverId],
        reducer: rollupReducers[driverId],
      }).value;

      return {
        value,
        id: driverId,
      };
    });

    return result;
  }, [rollupReducers, driverTimeSeries, currentLayerId, driverIds, start, end]);

  return {
    isAnyDriverLoading,
    currentLayerId,
    data,
  };
}
