import { Text } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';

const MAX_ITEMS = 3;

interface Props<T> {
  items: T[] | undefined;
  maxItems?: number;
  allowShowLess?: boolean;
  children: (props: {
    itemsToShow: T[] | undefined;
    numExtraItems: number;
    show: () => void;
    hide: () => void;
  }) => React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const WithShowMore = <T extends any>(props: Props<T>) => {
  const items = props.items;
  const maxItems = props.maxItems ?? MAX_ITEMS;
  const allowShowLess = props.allowShowLess ?? false;
  const children = props.children;

  const [hideExtraItems, setHideExtraItems] = useState((items?.length ?? 0) > maxItems);
  const show = useCallback(() => setHideExtraItems(false), []);
  const hide = useCallback(() => setHideExtraItems(true), []);

  const itemsToShow = useMemo(
    () => (hideExtraItems ? items?.slice(0, maxItems) : items),
    [hideExtraItems, items, maxItems],
  );

  const numExtraItems = Math.max((items?.length ?? 0) - maxItems, 0);

  return (
    <>
      {children({ itemsToShow, numExtraItems, show, hide })}
      {hideExtraItems && numExtraItems > 0 ? (
        <Text onClick={show} color="gray.500" fontSize="xxs" cursor="pointer">
          Show {numExtraItems} more
        </Text>
      ) : allowShowLess ? (
        <Text onClick={hide} color="gray.500" fontSize="xxs" cursor="pointer">
          Show less
        </Text>
      ) : null}
    </>
  );
};

export default React.memo(WithShowMore) as typeof WithShowMore;
