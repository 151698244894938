import { useMemo } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import { BusinessObjectFieldCellRef, BusinessObjectTimeSeriesCellRef } from 'config/cells';
import {
  isBusinessObjectFieldSpecColumnKey,
  isBusinessObjectNameFieldCellRef,
} from 'helpers/cells';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useOpenDetailViewContextMenu from 'hooks/useOpenDetailViewContextMenuItem';
import { toggleShowColumnAsTimeSeries } from 'reduxStore/actions/blockMutations';
import { deleteSelected } from 'reduxStore/actions/deleteSelected';
import { duplicateSelectedBusinessObjects } from 'reduxStore/actions/duplicateSelectedBusinessObjects';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import {
  blockConfigBusinessObjectSpecStartFieldIdSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  isObjectTimeseriesViewSelector,
} from 'selectors/blocksSelector';
import {
  canDeleteSelectedObjectsSelector,
  numSelectedObjectsSelector,
} from 'selectors/objectContextMenuItemSelector';
import ChartIcon from 'vectors/Chart';
import DuplicateIcon from 'vectors/Duplicate';
import TrashIcon from 'vectors/Trash';

export function useObjectContextMenuItems(
  cellRef: BusinessObjectTimeSeriesCellRef | BusinessObjectFieldCellRef,
) {
  const dispatch = useAppDispatch();
  const { blockId, readOnly } = useBlockContext();
  const canDeleteAll = useAppSelector(canDeleteSelectedObjectsSelector);
  const numSelectedObjects = useAppSelector(numSelectedObjectsSelector);
  const asTimeSeriesFieldSpecId = useAppSelector((state) =>
    blockConfigObjectFieldSpecAsTimeSeriesIdSelector(state, blockId),
  );

  const objectId = cellRef.rowKey.objectId ?? '';
  const objectFieldSpecId = isBusinessObjectFieldSpecColumnKey(cellRef.columnKey)
    ? cellRef.columnKey.objectFieldSpecId
    : null;

  const objectSpecStartFieldId = useAppSelector((state) =>
    blockConfigBusinessObjectSpecStartFieldIdSelector(state, blockId),
  );

  const canShowSingleFieldAsTimeSeries = useAppSelector(
    (state) => !isObjectTimeseriesViewSelector(state, blockId),
  );

  const canShowAsTimeseries =
    canShowSingleFieldAsTimeSeries &&
    objectFieldSpecId != null &&
    objectSpecStartFieldId !== objectFieldSpecId;

  const isShowingAsTimeSeries =
    asTimeSeriesFieldSpecId != null && asTimeSeriesFieldSpecId === objectFieldSpecId;

  const openDetailPaneOption = useOpenDetailViewContextMenu({
    type: PaneType.Object,
    // This is a non-multiselect item, so we can assume there is only one object id.
    id: objectId,
  });

  const isNameColumn = isBusinessObjectNameFieldCellRef(cellRef);
  const items = useMemo<ContextMenuItem[]>(() => {
    let contextItems: ContextMenuItem[] = [];
    if (isNameColumn) {
      contextItems = [
        ...(openDetailPaneOption ? [openDetailPaneOption] : []),
        {
          text: 'Duplicate',
          icon: <DuplicateIcon />,
          isEdit: true,
          multiSelect: true,
          onSelect: () => {
            dispatch(duplicateSelectedBusinessObjects());
          },
          shortcut: 'duplicate' as const,
        },
        {
          text: 'Delete',
          icon: <TrashIcon />,
          isEdit: true,
          multiSelect: true,
          destructive: true,
          onSelect: () => {
            dispatch(deleteSelected());
          },
          shortcut: 'delete' as const,
        },
      ];
    } else {
      contextItems = [
        ...(openDetailPaneOption ? [openDetailPaneOption] : []),
        ...(canShowAsTimeseries
          ? [
              {
                text: isShowingAsTimeSeries ? 'Hide time series' : 'Show as time series',
                icon: <ChartIcon />,
                isEdit: false,
                multiSelect: false,
                onSelect: () => {
                  if (objectFieldSpecId != null) {
                    dispatch(
                      toggleShowColumnAsTimeSeries({ blockId, columnKey: objectFieldSpecId }),
                    );
                  }
                },
              },
            ]
          : []),
      ];
    }

    return contextItems.filter(
      (item) =>
        (!item.isEdit || !readOnly) &&
        (!item.destructive || canDeleteAll) &&
        (numSelectedObjects <= 1 || item.multiSelect),
    );
  }, [
    isNameColumn,
    openDetailPaneOption,
    canShowAsTimeseries,
    isShowingAsTimeSeries,
    objectFieldSpecId,
    dispatch,
    blockId,
    readOnly,
    canDeleteAll,
    numSelectedObjects,
  ]);

  return items;
}
