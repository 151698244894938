import React, { useCallback, useContext, useState } from 'react';

import {
  CellSelectionManagerContext,
  CellSubscriber,
} from 'components/CellSelectionManager/CellSelectionManagerContext';
import ContextMenu, {
  ContextMenuContent,
  useContextMenu,
} from 'components/ContextMenu/ContextMenu';
import { BusinessObjectFieldCellRef, BusinessObjectTimeSeriesCellRef, CellRef } from 'config/cells';
import { BlockType } from 'generated/graphql';
import { isBusinessObjectFieldCellRef } from 'helpers/cells';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useObjectContextMenuItems } from 'hooks/useObjectContextMenuItems';
import { selectCellIfUnselected } from 'reduxStore/actions/cellSelection';
import { blockTypeSelector } from 'selectors/blocksSelector';

function getObjectCellRef(
  ev: React.MouseEvent,
  subscribersByOpaqueCellId: Record<string, CellSubscriber>,
): BusinessObjectTimeSeriesCellRef | BusinessObjectFieldCellRef | null {
  const target = ev.target as HTMLElement;
  const tableCell = target.closest('[data-cellid]');
  const cellId = tableCell?.getAttribute('data-cellid');
  if (cellId == null) {
    return null;
  }

  const cellRef = subscribersByOpaqueCellId[cellId]?.cellRef as CellRef | undefined;
  if (
    cellRef == null ||
    !isBusinessObjectFieldCellRef(cellRef) ||
    cellRef.rowKey.objectId == null
  ) {
    return null;
  }

  return cellRef;
}

function isObjectCellEvent(
  ev: React.MouseEvent,
  subscribersByOpaqueCellId: Record<string, CellSubscriber>,
): boolean {
  return getObjectCellRef(ev, subscribersByOpaqueCellId) != null;
}

interface ObjectContextMenuContentProps {
  cellRef: BusinessObjectTimeSeriesCellRef | BusinessObjectFieldCellRef;
  closeMenu?: () => void;
}

export const ObjectContextMenuContent: React.FC<ObjectContextMenuContentProps> = ({
  cellRef,
  closeMenu,
}) => {
  const items = useObjectContextMenuItems(cellRef);
  const { closeContextMenu } = useContextMenu();
  return <ContextMenuContent items={items} closeMenu={closeMenu ?? closeContextMenu} />;
};

interface Props {
  children: React.ReactElement;
}

const ObjectContextMenu: React.FC<Props> = ({ children }) => {
  const { blockId, readOnly } = useBlockContext();
  const { subscribersByOpaqueCellId } = useContext(CellSelectionManagerContext);
  const blockType = useAppSelector((state) => blockTypeSelector(state, blockId));

  const dispatch = useAppDispatch();
  const [contextMenuCellRef, setCellRef] = useState<
    BusinessObjectTimeSeriesCellRef | BusinessObjectFieldCellRef | null
  >(null);

  const shouldHandleEvent = useCallback(
    (ev: React.MouseEvent) => {
      return isObjectCellEvent(ev, subscribersByOpaqueCellId);
    },
    [subscribersByOpaqueCellId],
  );

  const onContextMenuOpen = useCallback(
    (ev?: React.MouseEvent) => {
      if (ev == null) {
        return;
      }
      const cellRef = getObjectCellRef(ev, subscribersByOpaqueCellId);
      if (cellRef == null) {
        return;
      }

      setCellRef(cellRef);
      dispatch(selectCellIfUnselected(blockId, cellRef));
    },
    [dispatch, blockId, subscribersByOpaqueCellId],
  );

  if (readOnly || blockType === BlockType.ObjectGrid) {
    return children;
  }

  return (
    <ContextMenu
      shouldHandleEvent={shouldHandleEvent}
      onContextMenuOpen={onContextMenuOpen}
      content={
        contextMenuCellRef != null ? (
          <ObjectContextMenuContent cellRef={contextMenuCellRef} />
        ) : undefined
      }
    >
      {children}
    </ContextMenu>
  );
};

export default React.memo(ObjectContextMenu);
