import retry from 'async-retry';

import {
  BlockEvalRequest,
  CalculationRequestBatch,
  EnqueueBlockEvalDocument,
  EnqueueBlockEvalMutation,
  EnqueueBlockEvalMutationVariables,
  EnqueueCalculationsDocument,
  EnqueueCalculationsMutation,
  EnqueueCalculationsMutationVariables,
  FormulaEntityType,
} from 'generated/graphql';
import { getISOTimeWithoutMsFromMonthKey } from 'helpers/dates';
import { isNotNull } from 'helpers/typescript';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { selectedOrgIdSelector } from 'selectors/selectedOrgSelector';
import { AddToCalculationsQueueMessage } from 'workers/formulaCalculator/types';

const clientIdSelector = (state: RootState) => state.calculations.clientId;
const MAX_RETRIES = 5;
const DEFAULT_RETRY_DELAY_MS = 2000;
const CALC_MANAGER_NOT_RUNNING_ERROR = 'calculation manager not running';

export const enqueueBlockEvaluation =
  (request: BlockEvalRequest): AppThunk =>
  (dispatch, getState, { urqlClient }) => {
    const state = getState();
    const orgId = selectedOrgIdSelector(state);
    const clientId = clientIdSelector(state);
    if (orgId == null || clientId == null) {
      return;
    }
    urqlClient
      .mutation<EnqueueBlockEvalMutation, EnqueueBlockEvalMutationVariables>(
        EnqueueBlockEvalDocument,
        {
          orgId,
          clientId,
          request,
        },
      )
      .toPromise();
  };

export const enqueueCalculations =
  (requestBatch: CalculationRequestBatch): AppThunk =>
  (dispatch, getState, { urqlClient }) => {
    const state = getState();
    const orgId = selectedOrgIdSelector(state);
    const clientId = clientIdSelector(state);
    if (orgId == null || clientId == null) {
      return;
    }

    retry(
      async () => {
        const res = await urqlClient.mutation<
          EnqueueCalculationsMutation,
          EnqueueCalculationsMutationVariables
        >(EnqueueCalculationsDocument, {
          orgId,
          clientId,
          requestBatch,
        });

        const error = res.error?.graphQLErrors[0];
        if (error && error.message.includes(CALC_MANAGER_NOT_RUNNING_ERROR)) {
          // Trigger retry
          throw new Error(CALC_MANAGER_NOT_RUNNING_ERROR);
        }
      },
      {
        randomize: false,
        retries: MAX_RETRIES,
        minTimeout: DEFAULT_RETRY_DELAY_MS,
      },
    );
  };

export const webworkerCalcBatchToBackendCalcBatch = ({
  data,
  requestId,
}: {
  data: AddToCalculationsQueueMessage['data'];
  requestId: string;
}): CalculationRequestBatch => {
  const calculationBatch: CalculationRequestBatch = {
    requests: data
      .map(
        ({ entityId, dateRange, layerId, includeObjectSpecEvaluation, ignoreEventIds, opts }) => {
          let formulaType;
          if (entityId.type === 'driver') {
            formulaType = FormulaEntityType.Driver;
          } else if (entityId.type === 'objectField') {
            formulaType = FormulaEntityType.ObjectField;
          } else {
            return null;
          }

          return {
            requestId,
            dateRange: {
              start: getISOTimeWithoutMsFromMonthKey(dateRange[0]),
              end: getISOTimeWithoutMsFromMonthKey(dateRange[1]),
            },
            entityId: entityId.id,
            layerId,
            entityType: formulaType,
            includeObjectSpecEvaluations: includeObjectSpecEvaluation,
            calculationOpts: opts,
            ignoreEventIDs: ignoreEventIds,
          };
        },
      )
      .filter(isNotNull),
  };

  return calculationBatch;
};
