import { Box } from '@chakra-ui/react';
import React, { useCallback, useContext, useState } from 'react';

import {
  CellSelectionManagerContext,
  CellSubscriber,
} from 'components/CellSelectionManager/CellSelectionManagerContext';
import ContextMenu, {
  ContextMenuContent,
  useContextMenu,
} from 'components/ContextMenu/ContextMenu';
import { CellRef, DriverCellRef, DriverDataCellRef } from 'config/cells';
import { isDriverCellRef, isDriverDataCellRef } from 'helpers/cells';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import useDriverContextMenuItems from 'hooks/useDriverContextMenuItems';
import useDriverDataCellContextMenuItems from 'hooks/useDriverDataCellContextMenuItems';
import { selectCellIfUnselected } from 'reduxStore/actions/cellSelection';
import { BlockId } from 'reduxStore/models/blocks';

function getDriverCellRef(
  ev: React.MouseEvent,
  subscribersByCellId: NullableRecord<string, CellSubscriber>,
): DriverCellRef | null {
  const target = ev.target as HTMLElement;
  const tableCell = target.closest('[data-cellid]');
  const cellId = tableCell?.getAttribute('data-cellid');
  if (cellId == null) {
    return null;
  }

  const cellRef: CellRef | undefined = subscribersByCellId[cellId]?.cellRef;
  if (cellRef == null || !isDriverCellRef(cellRef) || cellRef.rowKey.driverId == null) {
    return null;
  }

  return cellRef;
}

function isDriverCellEvent(
  ev: React.MouseEvent,
  subscribersByCellId: NullableRecord<string, CellSubscriber>,
): boolean {
  return getDriverCellRef(ev, subscribersByCellId) != null;
}

interface DriverPropertyContextMenuContentProps {
  cellRef: DriverCellRef;
  blockId: BlockId;
  closeMenu?: () => void;
}

export const DriverPropertyContextMenuContent: React.FC<DriverPropertyContextMenuContentProps> = ({
  cellRef,
  blockId,
  closeMenu,
}) => {
  const items = useDriverContextMenuItems(cellRef, blockId);
  const { closeContextMenu } = useContextMenu();
  return <ContextMenuContent items={items} closeMenu={closeMenu ?? closeContextMenu} />;
};

interface DriverContextMenuContentProps {
  cellRef: DriverDataCellRef;
}

const DriverDataContextMenuContent: React.FC<DriverContextMenuContentProps> = ({ cellRef }) => {
  const items = useDriverDataCellContextMenuItems(cellRef);
  const { closeContextMenu } = useContextMenu();
  return <ContextMenuContent items={items} closeMenu={closeContextMenu} />;
};

interface Props {
  children: React.ReactElement;
}

const DriverContextMenu: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ children }, ref) => {
    const { blockId, readOnly } = useBlockContext();
    const { subscribersByOpaqueCellId } = useContext(CellSelectionManagerContext);

    const dispatch = useAppDispatch();
    const [contextMenuCellRef, setCellRef] = useState<DriverCellRef | null>(null);

    const shouldHandleEvent = useCallback(
      (ev: React.MouseEvent) => {
        return isDriverCellEvent(ev, subscribersByOpaqueCellId);
      },
      [subscribersByOpaqueCellId],
    );

    const onContextMenuOpen = useCallback(
      (ev?: React.MouseEvent) => {
        if (ev == null) {
          return;
        }
        const cellRef = getDriverCellRef(ev, subscribersByOpaqueCellId);
        if (cellRef == null) {
          return;
        }

        setCellRef(cellRef);
        dispatch(selectCellIfUnselected(blockId, cellRef));
      },
      [dispatch, blockId, subscribersByOpaqueCellId],
    );

    if (readOnly) {
      return <Box ref={ref}>{children}</Box>;
    }

    return (
      <ContextMenu
        shouldHandleEvent={shouldHandleEvent}
        onContextMenuOpen={onContextMenuOpen}
        content={
          isDriverDataCellRef(contextMenuCellRef) ? (
            <DriverDataContextMenuContent cellRef={contextMenuCellRef} />
          ) : isDriverCellRef(contextMenuCellRef) ? (
            <DriverPropertyContextMenuContent cellRef={contextMenuCellRef} blockId={blockId} />
          ) : undefined
        }
      >
        <Box ref={ref}>{children}</Box>
      </ContextMenu>
    );
  },
);

export default React.memo(DriverContextMenu);
