import { useMemo } from 'react';
import { EqualityFn, TypedUseSelectorHook } from 'react-redux';

import { isDevelopment, isSSR, isUserBrowser } from 'helpers/environment';

// Developer configuration options. Edit these when you want to profile selectors.
// How to use this profiler: https://www.loom.com/share/742e19d5a7ef42b5bd5ac31bd67fdd74
const enableProfling = false;
const sortBy: ('callCount' | 'totalTimeMs') & keyof SelectorMetrics = 'callCount';

interface SelectorMetrics {
  name: string | null;
  callers: Set<string>;

  callCount: number;
  totalTimeMs: number;
}

const selectorMetrics = new Map<string, SelectorMetrics>();
const selectorKey = (fn: (_: any) => unknown) => fn.toString();
let selectorCallCount = 0;

const getMetrics = <R>(selector: (state: R) => unknown) => {
  const key = selectorKey(selector);
  let metrics = selectorMetrics.get(key);
  if (metrics == null) {
    metrics = {
      name: selector.name === '' ? null : selector.name,
      callCount: 0,
      totalTimeMs: 0,
      callers: new Set(),
    };
    selectorMetrics.set(key, metrics);
  }
  return metrics;
};

function createProfiledUseSelector<R>(useSelector: TypedUseSelectorHook<R>) {
  return function useProfiledSelector<S>(
    selector: (state: R) => S,
    equalityFn?: EqualityFn<S> | undefined,
  ) {
    const metrics = getMetrics(selector);
    metrics.callers.add(new Error().stack?.split('\n')[2] ?? 'unknown');

    const wrappedSelector = useMemo(() => {
      return (state: R) => {
        const start = performance.now();
        const res = selector(state);
        const end = performance.now();

        selectorCallCount++;
        const m = getMetrics(selector);
        m.callCount += 1;
        m.totalTimeMs += end - start;

        return res;
      };
    }, [selector]);

    return useSelector(wrappedSelector, equalityFn);
  };
}

export function conditionallyWrapUseSelector<S>(useSelector: TypedUseSelectorHook<S>) {
  return isSelectorProfilingEnabled() ? createProfiledUseSelector(useSelector) : useSelector;
}

export function resetSelectorProfile() {
  [...selectorMetrics.values()].forEach((v) => {
    v.callCount = 0;
    v.totalTimeMs = 0;
  });
}

let timer: NodeJS.Timer | null = null;
export function printSelectorProfileIfChanged() {
  if (!isSelectorProfilingEnabled()) {
    return;
  }

  if (timer != null) {
    clearInterval(timer);
    timer = null;
  }

  let last = selectorCallCount;
  timer = setInterval(() => {
    if (last === selectorCallCount) {
      printSelectorProfile('post-action selectors');
      if (timer != null) {
        clearInterval(timer);
        timer = null;
      }
      return;
    }
    // eslint-disable-next-line no-console
    console.log(`still profiling... (${selectorCallCount} selectors and counting)`);
    last = selectorCallCount;
  }, 3000);
}

export function printSelectorProfile(label?: string) {
  if (!isSelectorProfilingEnabled()) {
    return;
  }

  const sorted = [...selectorMetrics.entries()].sort((a, b) => b[1][sortBy] - a[1][sortBy]);

  const totalTime = sorted.reduce((acc, [_, v]) => acc + v.totalTimeMs, 0);

  /* eslint-disable no-console */
  console.groupCollapsed(
    `Selector profile${label == null ? '' : ` (${label})`}, totalTime=${totalTime.toPrecision(
      5,
    )}ms`,
  );

  sorted.slice(0, 15).forEach(([k, v], idx) => {
    console.groupCollapsed(
      `Selector #${idx} totalTime=${v.totalTimeMs.toPrecision(5)}ms, callCount=${v.callCount}`,
    );
    console.log('  Name: ', v.name ?? 'anonymous');
    console.log('  Signature: ', k);
    console.group('  Callers:');
    v.callers.forEach((caller) => {
      console.log(caller);
    });
    console.groupEnd();
    console.groupEnd();
  });

  console.groupEnd();
  /* eslint-enable no-console */
}

if (!isSSR()) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  (window as any).printSelectorProfile = printSelectorProfile;
}

function isSelectorProfilingEnabled() {
  return !isSSR() && isDevelopment && isUserBrowser() && enableProfling;
}
