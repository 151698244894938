import { Flex } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ManageDimensionsMenuButton from 'components/DimensionalDriverMenu/ManageDimensionsMenuButton';
import SearchInput from 'components/SearchInput/SearchInput';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuSection from 'components/SelectMenu/SelectMenuSection';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';
import { checkNameIsUniqueCaseInsensitive } from 'helpers/naming';
import useAppSelector from 'hooks/useAppSelector';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { DimensionId } from 'reduxStore/models/dimensions';
import { validDimensionsSelector } from 'selectors/dimensionsSelector';

const DIMENSIONS_SECTIONS: Section[] = [
  {
    id: 'dimensions',
    maxResults: 5,
    showMore: true,
  },
];
const SEARCH_KEYS = ['name'];

interface DimensionSearchProps {
  onSelectDimension: (dimensionId: DimensionId) => void;
  onCreateDimension: (newDimensionName: string) => void;
  onCancel: () => void;
  excludeDimensionIds?: DimensionId[];
}
const DimensionSearchMenu: React.FC<DimensionSearchProps> = ({
  onSelectDimension,
  onCreateDimension,
  onCancel,
  excludeDimensionIds,
}) => {
  const dimensions = useAppSelector(validDimensionsSelector);
  const allDimNames = dimensions.map((d) => d.name);
  const items: SelectItem[] = useMemo(() => {
    return dimensions
      .filter((d) => excludeDimensionIds == null || !excludeDimensionIds.includes(d.id))
      .map((d) => ({
        id: d.id,
        name: d.name,
        sectionId: 'dimensions' as const,
        meta: `${d.attributes.filter((a) => !a.deleted).length} items`,
      }));
  }, [dimensions, excludeDimensionIds]);

  const [query, setQuery] = useState('');

  const onSelect = useCallback(
    ({ id }: SelectItem) => {
      onSelectDimension(id);
    },
    [onSelectDimension],
  );
  const customOptions = useMemo(
    () => [
      {
        id: 'create',
        position: 'last',
        onSelect: (newDimensionName: string) => {
          if (newDimensionName.trim().length > 0) {
            onCreateDimension(newDimensionName);
          }
        },
        errorMessage: (q: string) =>
          checkNameIsUniqueCaseInsensitive(q, allDimNames)
            ? q.trim().length > 0
              ? null
              : ''
            : 'Dimension name must be unique',
        render: (q: string) => <CustomCreateOption label="Create dimension" helperText={q} />,
      },
    ],
    [onCreateDimension, allDimNames],
  );
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, onCancel);

  return (
    <SelectMenuContainer ref={ref}>
      <Flex
        data-testid="dimensions-search-menu"
        p={1}
        pt={2}
        rowGap={1}
        direction="column"
        width="22rem"
      >
        <SelectMenuSection name="" isLast>
          <Flex columnGap={1} px={2} pb={1}>
            <SearchInput
              placeholder="Select or create a new dimension"
              query={query}
              setQuery={setQuery}
              ref={inputRef}
            />
            <ManageDimensionsMenuButton inputRef={inputRef} />
          </Flex>
          <SelectMenu
            items={items}
            sections={DIMENSIONS_SECTIONS}
            onSelect={onSelect}
            width="unset"
            query={query}
            searchKeys={SEARCH_KEYS}
            customOptions={customOptions}
          >
            {BaseSelectMenuItem}
          </SelectMenu>
        </SelectMenuSection>
      </Flex>
    </SelectMenuContainer>
  );
};
export default DimensionSearchMenu;
