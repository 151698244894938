import { createStateContext } from 'react-use';

import { CELL_DATA_COLUMN_WIDTH_IN_PX } from 'config/cells';
import { EventGroupId, PlanTimelineItemRef } from 'reduxStore/models/events';

export const PLAN_TIMELINE_MONTH_WIDTH_PX = CELL_DATA_COLUMN_WIDTH_IN_PX;
export const PILL_HEIGHT_PX = 28;
export const TIMELINE_ROW_MARGIN_BOTTOM_PX = 4;
export const TIMELINE_PILL_BORDER_RADIUS_PX = 6;
export const PLAN_TIMELINE_ROW_HEIGHT = PILL_HEIGHT_PX + TIMELINE_ROW_MARGIN_BOTTOM_PX;

export const INSPECTOR_HEADER_HEIGHT = 72;
export const PLAN_TIMELINE_INDENT_DEPTH_PX = 20;
export const IMPACT_TIMELINE_NAV_ROW_INDENTATION_PX = 16;

export const INITIATIVE_DRAG_ITEM_TYPE = 'roadmapInitiative';

export const MAX_EVENTS_TO_RENDER_IN_PLAN = 250;

export const DEFAULT_PLAN_MONTHS = 1;
export const TIMELINE_NAV_DRAG_ITEM_TYPE = 'roadmapNavRow';

export const Y_AXIS_OVERHEAD_FACTOR = 1.6;
export const Y_AXIS_OVERHEAD_FACTOR_NEGATIVE = 0.5;

export const [usePlanTimelinePanel, PlanTimelinePanelContextProvider] = createStateContext<{
  isDragging: boolean;
  targetGroupId?: EventGroupId;
}>({ isDragging: false });

type InitiativeDragState = PlanTimelineItemRef & {
  blockSize: number;
  rowIdx: number;
  columnIdx: number;
  dragOffset: number;
};

export const [usePlanTimelineDragState, PlanTimelineDragStateContextProvider] =
  createStateContext<InitiativeDragState | null>(null);

export enum PlanTimelineStackingOrder {
  Preview = 0,
  SwimLane = 1,
  DropTargetBackground = 2,
  DropTargetOverlay = 3,
  TimelinePill = 4,
  DragElement = 5,
  WarningPopover = 6,
}
