import { isCypress, isJest } from './environment';

export enum AnalyticsEvent {
  // App
  SessionStart = 'session_start',
  SessionEnd = 'session_end',
  ToggleFeatureFlag = 'feature_flag_toggle',

  // Scenarios
  CreateScenario = 'create_scenario',
  SwitchScenario = 'switch_scenario',
  DeleteScenario = 'delete_scenario',
  // Snapshot
  CreateSnapshot = 'create_snapshot',
  // Pages
  CreatePage = 'create_page',
  ViewPage = 'view_page',
  DeletePage = 'delete_page',
  // Mutations
  SubmitMutation = 'submit_mutation',
  UndoMutation = 'undo_mutation',
  // Integrations
  LinkIntegration = 'link_integration',
  IntegrationUnderConstruction = 'integration_under_construction',
  // MultiCurrency, Color Change, Negative formatting
  EditFormat = 'edit_format',
  // Database Improvements
  DetailPaneNav = 'detail_pane_nav',
  Filter = 'filter',
  // Chart
  EditChart = 'edit_chart',
  // Plans
  PlansPage = 'plans_page',
  // Uncategorized Drivers
  UnlistedDriversPage = 'unlisted_drivers_page',
  // Pages
  CreateBlock = 'create_block',
  UploadFile = 'upload_file',
  AddMediaLink = 'add_media_link',
  MoveBlock = 'move_block',
  ToggleBlockHeader = 'toggle_block_header',

  // Copilot
  CopilotStart = 'copilot_start',
  CopilotRetry = 'copilot_retry',
  CopilotRefinement = 'copilot_refinement',
  CopilotEnd = 'copilot_end',

  // Data Shaping
  DatabaseConfigurationUpdate = 'database_configuration_update',
}

export function trackEvent(
  event: AnalyticsEvent,
  properties?: NullableRecord<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void,
) {
  if (isJest() || isCypress()) {
    return;
  }
  if (window.analytics == null) {
    return;
  }
  window.analytics.track(event, properties, options, callback);
}

export function trackSessionStart() {
  if (window.analytics == null) {
    return;
  }
  window.analytics.track(
    AnalyticsEvent.SessionStart,
    {},
    {
      integrations: {
        All: false,
        Amplitude: {
          session_id: Date.now(),
        },
      },
    },
  );
}

export function trackSessionEnd() {
  if (window.analytics == null) {
    return;
  }
  window.analytics.track(
    AnalyticsEvent.SessionEnd,
    {},
    {
      integrations: {
        All: false,
        Amplitude: true,
      },
    },
  );
}
