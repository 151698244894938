import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';

import { CompareScenariosModal as NewCompareScenariosModalContent } from '@features/CompareScenarios';
import CompareScenariosModalContent from 'components/CompareScenariosModalContent/CompareScenariosModalContent';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { closeScenarioComparisonModal } from 'reduxStore/actions/navigateTo';
import { isCompareScenariosModalOpenSelector } from 'selectors/compareScenariosModalSelector';
import { enableNewMergeScreenSelector } from 'selectors/launchDarklySelector';

const CompareScenariosModal: React.FC = () => {
  const enableNewMergeScreen = useAppSelector(enableNewMergeScreenSelector);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isCompareScenariosModalOpenSelector);
  const onClose = useCallback(() => {
    dispatch(closeScenarioComparisonModal());
  }, [dispatch]);
  const initialRef = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      variant="overlay"
      blockScrollOnMount={false}
      returnFocusOnClose={false}
      onEsc={onClose}
      initialFocusRef={initialRef}
    >
      <ModalOverlay />
      {enableNewMergeScreen ? (
        isOpen && <NewCompareScenariosModalContent ref={initialRef} />
      ) : (
        <ModalContent top="2rem" left="4rem" right="4rem" minWidth="fit-content">
          <ModalBody padding={0} overflow="hidden" display="flex">
            {isOpen && <CompareScenariosModalContent />}
          </ModalBody>
        </ModalContent>
      )}
    </Modal>
  );
};

export default CompareScenariosModal;
