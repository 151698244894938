import { Flex, Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import HelpMenu from 'components/HelpMenu/HelpMenu';
import { GlobalNavIcon, TOOLTIP_OPEN_DELAY } from 'components/RunwayApp/GlobalNavigationMenu';
import useControlledPopover from 'hooks/useControlledPopover';
import HelpIcon from 'vectors/Help';

const HelpMenuSidebarItem: React.FC = () => {
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();

  return (
    <Popover
      isLazy
      placement="top-start"
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <>
        <Tooltip
          label="Help"
          shouldWrapChildren
          placement="bottom-end"
          openDelay={TOOLTIP_OPEN_DELAY}
          closeOnPointerDown
          isDisabled={isOpen}
        >
          <PopoverTrigger>
            <Flex ref={triggerRef} data-testid="help-menu-sidebar-item">
              <GlobalNavIcon aria-label="Show versions" icon={<HelpIcon boxSize={4} />} />
            </Flex>
          </PopoverTrigger>
        </Tooltip>
        <Portal>
          <PopoverContent ref={contentRef} padding={0}>
            <HelpMenu closeMenu={onClose} />
          </PopoverContent>
        </Portal>
      </>
    </Popover>
  );
};

export default HelpMenuSidebarItem;
