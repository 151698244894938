import { Flex, Text } from '@chakra-ui/react';
import isString from 'lodash/isString';
import React from 'react';

import DriverAttributeBadges from 'components/DriverAttributeBadges/DriverAttributeBadges';
import DriverPageIcon from 'components/DriverSearchResult/DriverPageIcon';
import DimensionBadge from 'components/Formula/DimensionBadge';
import SelectMenuItem, { SelectMenuItemProps } from 'components/SelectMenu/SelectMenuItem';
import { DriverReference } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import { Attribute } from 'reduxStore/models/dimensions';
import { Driver, DriverType } from 'reduxStore/models/drivers';
import { attributesForSubDriverIdSelector } from 'selectors/driversSelector';
import { AttributeFilters } from 'types/formula';

interface Props extends Omit<SelectMenuItemProps, 'name' | 'icon'> {
  driver: Driver;
  filters?: AttributeFilters;
}

const EMPTY_ARRAY: Attribute[] = [];
const EMPTY_REFERENCES: DriverReference[] = [];

const DriverSearchResult: React.FC<Props> = ({ driver, filters, ...rest }) => {
  const { name, id, driverReferences } = driver;

  const resolvedAttributes = useAppSelector((state) =>
    driver.type !== DriverType.Dimensional
      ? attributesForSubDriverIdSelector(state, id)
      : EMPTY_ARRAY,
  );

  return (
    <SelectMenuItem
      {...rest}
      icon={<DriverPageIcon refs={driverReferences ?? EMPTY_REFERENCES} />}
      name={
        <Flex flexDirection="column" alignItems="start" columnGap={1}>
          <Text noOfLines={1}>{name}</Text>
          {(resolvedAttributes.length > 0 ||
            (driver.type === DriverType.Dimensional && driver.dimensions.length > 0)) && (
            <Flex
              paddingTop="0.25rem"
              columnGap={1}
              pb={1}
              rowGap="0.25rem"
              flexWrap="wrap"
              pt={0.5}
            >
              {resolvedAttributes.length > 0 && (
                <DriverAttributeBadges
                  driverId={id}
                  flexWrap="wrap"
                  rowGap="0.25rem"
                  size="xs"
                  overflow="visible"
                />
              )}
              {driver.type === DriverType.Dimensional &&
                driver.dimensions.map((d) => {
                  const dimFilter = filters?.byDimId[d.id]?.find((f) => isString(f));

                  return (
                    <DimensionBadge
                      dimension={d}
                      key={d.id}
                      type={isString(dimFilter) ? dimFilter : undefined}
                    />
                  );
                })}
            </Flex>
          )}
        </Flex>
      }
    />
  );
};

export default React.memo(DriverSearchResult);
