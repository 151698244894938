import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deepEqual } from 'fast-equals';
import uniqWith from 'lodash/uniqWith';

import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import {
  DriverRefMetadata,
  EntityType,
  ExtDriverRefMetadata,
  ObjectSpecRefMetadata,
  SubmodelRefMetadata,
} from 'types/formula';

type DriverEntityData = {
  type: EntityType.Driver;
  data: Pick<DriverRefMetadata, 'id'>;
};

type SubmodelEntityData = {
  type: EntityType.Submodel;
  data: Pick<SubmodelRefMetadata, 'id'>;
};

type ExtDriverEntityData = {
  type: EntityType.ExtDriver;
  data: Pick<ExtDriverRefMetadata, 'id' | 'source'>;
};

type ObjectSpecEntityData = {
  type: EntityType.ObjectSpec;
  data: Pick<ObjectSpecRefMetadata, 'id' | 'fieldId'>;
};

export type FormulaEntity =
  | DriverEntityData
  | SubmodelEntityData
  | ExtDriverEntityData
  | ObjectSpecEntityData;

export type FormulaInputState = {
  canInsertFormulaEntityReference?: boolean;
  formulaEntityReference?: FormulaEntityTypedId;
  recentEntities: FormulaEntity[];
};

const initialState = {
  recentEntities: [],
} as FormulaInputState;

const maxEntities = 50;
const formulaInputSlice = createSlice({
  name: 'formulaInput',
  initialState,
  reducers: {
    setCanInsertFormulaEntityReference(state, action: PayloadAction<boolean>) {
      state.canInsertFormulaEntityReference = action.payload;
      state.formulaEntityReference = undefined;
    },
    insertFormulaEntityReference(state, action: PayloadAction<FormulaEntityTypedId>) {
      state.canInsertFormulaEntityReference = false;
      state.formulaEntityReference = action.payload;
    },
    clearFormulaEntityReference(state) {
      state.formulaEntityReference = undefined;
      state.canInsertFormulaEntityReference = undefined;
    },
    addRecentEntities(state, action: PayloadAction<FormulaEntity[]>) {
      const entities = action.payload;
      if (entities.length >= maxEntities) {
        state.recentEntities = entities.slice(0, maxEntities);
      } else {
        state.recentEntities.unshift(...entities);
        state.recentEntities = uniqWith(state.recentEntities, deepEqual).slice(0, maxEntities);
      }
    },
  },
});

export const {
  addRecentEntities,
  clearFormulaEntityReference,
  insertFormulaEntityReference,
  setCanInsertFormulaEntityReference,
} = formulaInputSlice.actions;

export default formulaInputSlice.reducer;
