import { StoreNames, openDB } from 'idb';

import {
  LATEST_VERSION,
  LatestDatabase,
  LatestSchema,
  PrefixedLatestSchema,
  SchemaVersionNumber,
  getSchemaVersion,
} from 'indexeddb/schema';

const DB_NAME = 'runway';
type OrgId = string;
type DbName = `${OrgId}:${typeof DB_NAME}`;

export class IndexedDB {
  private static instance: IndexedDB;
  private db?: LatestDatabase;
  private orgId: string;

  private constructor(orgId: string) {
    this.orgId = orgId;
  }

  public static get(orgId: string): IndexedDB {
    if (this.instance != null) {
      return this.instance;
    }

    this.instance = new IndexedDB(orgId);
    return this.instance;
  }

  private async open() {
    const { orgId } = this;
    try {
      // Every org has its own database for data segmentation.
      // Every object store is also prefixed with the org ID to make it even more obvious what you're working with.
      // IndexedDB instances are isolated by domain so there is no need to segment further by environment.
      const dbName: DbName = `${orgId}:${DB_NAME}`;
      this.db = await openDB<PrefixedLatestSchema>(dbName, LATEST_VERSION, {
        upgrade(database, oldVersion, newVersion, transaction, event) {
          if (newVersion == null) {
            return;
          }

          const currentSchema = getSchemaVersion(newVersion as typeof LATEST_VERSION);
          const previousSchema = getSchemaVersion(oldVersion as SchemaVersionNumber);

          if (currentSchema == null) {
            throw new Error(`schema version #${newVersion} is undefined`);
          }

          if (currentSchema.upgrade != null) {
            currentSchema.upgrade(
              orgId,
              database,
              transaction,
              event,
              currentSchema,
              previousSchema,
            );
          }
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error('Error opening IndexedDB', { cause: e });
    }
  }

  public async setOne<
    TStore extends StoreNames<LatestSchema>,
    TKey extends LatestSchema[TStore]['key'],
    TValue extends LatestSchema[TStore]['value'],
  >(store: StoreNames<LatestSchema>, key: TKey, value: TValue): Promise<void> {
    if (!this.db) {
      await this.open();
    }

    await this.db?.put(`${this.orgId}:${store}`, value, key);
  }

  public async getOne<
    TStore extends StoreNames<PrefixedLatestSchema>,
    TKey extends PrefixedLatestSchema[TStore]['key'],
    TValue extends PrefixedLatestSchema[TStore]['value'],
  >(store: StoreNames<LatestSchema>, key: TKey): Promise<TValue | null | undefined> {
    if (!this.db) {
      await this.open();
    }

    return this.db?.get(`${this.orgId}:${store}`, key) as TValue | null | undefined;
  }

  public async clear(store: StoreNames<LatestSchema>) {
    if (!this.db) {
      await this.open();
    }

    await this.db?.clear(`${this.orgId}:${store}`);
  }

  public async deleteOne<
    TStore extends StoreNames<PrefixedLatestSchema>,
    TKey extends PrefixedLatestSchema[TStore]['key'],
  >(store: StoreNames<LatestSchema>, key: TKey): Promise<void> {
    if (!this.db) {
      await this.open();
    }

    await this.db?.delete(`${this.orgId}:${store}`, key);
  }
}
