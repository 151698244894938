import { Skeleton } from '@chakra-ui/react';
import React from 'react';

import CellContextProviderLight from 'components/CellContextProvider/CellContextProviderLight';
import DriverPropertyTableCellLight from 'components/DriverPropertyTableCell/DriverPropertyTableCellLight';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import useDriverCellRef from 'hooks/useDriverCellRef';

const COLUMN_TYPE: ModelViewColumnType = 'graph';

type Props = {
  columnLayerId: ColumnLayerId;
};

const DriverSparklineTableCellLight: React.FC<Props> = ({ columnLayerId }) => {
  const cellRef = useDriverCellRef({ columnType: COLUMN_TYPE, columnLayerId });
  return (
    <CellContextProviderLight cellRef={cellRef}>
      <DriverPropertyTableCellLight columnType={COLUMN_TYPE} columnLayerId={columnLayerId} isSticky>
        <Skeleton startColor="gray.200" endColor="gray.300" width="75%" height={3} />
      </DriverPropertyTableCellLight>
    </CellContextProviderLight>
  );
};

export default React.memo(DriverSparklineTableCellLight);
