import { createIcon, IconProps } from '@chakra-ui/react';
import React from 'react';

const DirectionalArrow = createIcon({
  displayName: 'ArrowRightIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <g clipPath="url(#clip0_1764_97355)">
        <path
          d="M8 13L13 8L8 3"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 8L3 8"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1764_97355">
          <rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 14 2)" />
        </clipPath>
      </defs>
    </>
  ),
});

const ROTATION_BY_DIRECTION = {
  right: 0,
  down: 90,
  up: -90,
  left: 180,
} as const;

interface ArrowProps extends Omit<IconProps, 'css'> {
  direction?: keyof typeof ROTATION_BY_DIRECTION;
}

const DirectionalArrowIcon: React.FC<ArrowProps> = ({
  direction = 'left',
  ...props
}: ArrowProps) => {
  return (
    <DirectionalArrow {...props} transform={`rotate(${ROTATION_BY_DIRECTION[direction]}deg)`} />
  );
};

export default DirectionalArrowIcon;
