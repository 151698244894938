import { difference, keyBy, uniq } from 'lodash';
import mapValues from 'lodash/mapValues';

import {
  DatasetMutationInput,
  EventCreateInput,
  EventGroupCreateInput,
  EventUpdateInput,
  ImpactType,
} from 'generated/graphql';
import { extractMonthKey } from 'helpers/dates';
import { convertTimeSeriesToGql } from 'helpers/gqlDataset';
import { mergeMutations } from 'helpers/mergeMutations';
import {
  peekMutationStateChange,
  sortIndexMutationsForInsertion,
  stripInsertBeforeId,
} from 'helpers/sortIndex';
import { EventGroupId, EventId, isDriverEvent } from 'reduxStore/models/events';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import {
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  eventSelector,
  eventsForDriverSelector,
} from 'selectors/eventsAndGroupsSelector';
import { enableStackedImpactsSelector } from 'selectors/launchDarklySelector';

// If mutations include an event (set OR delta) on a month which has another event and that
// event will have no curve points after clearing the stacked impacts, then we delete it
export const getDeleteEventsToClearStackedEvents = (
  state: RootState,
  eventChanges: EventCreateInput[] | EventUpdateInput[],
) => {
  const enableStackedImpacts = enableStackedImpactsSelector(state);

  const eventIdsToDelete = new Set<EventId>();
  const remainingMonthKeysByEventId: Record<EventId, string[]> = {};

  eventChanges.forEach((item) => {
    if (item.time == null) {
      return;
    }

    const event = eventSelector(state, item.id);

    const newEventType = event?.impactType ?? item.impactType;

    const monthKey = extractMonthKey(item.time);

    let driverId = item.driverId;
    if (driverId == null) {
      if (event != null && isDriverEvent(event)) {
        driverId = event.driverId;
      }
    }

    const eventsForDriver =
      driverId != null ? eventsForDriverSelector(state, { id: driverId }) : [];

    if (eventsForDriver.length > 0) {
      // Events to be cleared for months that overlap with updates
      const otherEvents = eventsForDriver.filter((ev) => ev.id !== item.id);

      otherEvents.forEach((otherEvent) => {
        if (
          enableStackedImpacts &&
          newEventType === ImpactType.Delta &&
          otherEvent.impactType === ImpactType.Delta
        ) {
          return;
        }

        // If we're also updating curve points for the other event in the mutation, we
        // don't consider it for deletion
        const otherEventUpdateEventInput = eventChanges.find(
          (e) => e.id === otherEvent.id && e.value != null,
        );
        if (otherEventUpdateEventInput != null) {
          return;
        }

        let originalRemainingMonthKeys = remainingMonthKeysByEventId[otherEvent.id];
        if (originalRemainingMonthKeys == null) {
          const otherEventCurvePoints = convertTimeSeriesToGql(otherEvent.customCurvePoints) ?? [];
          originalRemainingMonthKeys = otherEventCurvePoints.map((point) =>
            extractMonthKey(point.time),
          );
        }

        // If the other event started with no curve points, we leave it
        if (originalRemainingMonthKeys.length === 0) {
          return;
        }

        const newRemainingMonthKeys = uniq(difference(originalRemainingMonthKeys, [monthKey]));

        if (newRemainingMonthKeys.length === 0) {
          eventIdsToDelete.add(otherEvent.id);
        }

        remainingMonthKeysByEventId[otherEvent.id] = newRemainingMonthKeys;
      });
    }
  });

  const deleteEvents = Array.from(eventIdsToDelete).map((id) => ({ id }));

  return deleteEvents;
};

// Given that newEventGroups...
// - can be provided in any order
// - can have parentIds that are not yet created, but that should be created in the same batch
// - can have child eventGroupIds that are not yet created, but that should be created in the same batch
// This function sorts the newEventGroups in the order they should be created.
// Exported for testing
export function sortNewEventGroupsAndNewEvents(
  newEventGroups: EventGroupCreateInput[],
  // newEvents has its objects modified in place, which is not ideal but it's
  // probably not a big deal + expensive to clone the array.
  newEvents: EventCreateInput[],
) {
  if (newEventGroups.length === 0) {
    return { newEventGroups, newEvents };
  }

  const newEventGroupsById = keyBy(newEventGroups, 'id');

  const newEventGroupsByParentId: Record<EventGroupId, EventGroupCreateInput[]> = {};
  const newEventGroupsWithParentInBatch = new Set();

  // This is lazily created only if needed, since it could be a potentially large object
  let newEventsById: Record<EventId, EventCreateInput> | undefined;

  newEventGroups.forEach((eventGroup) => {
    // Only consider this event group as having a parent if its parent is in the batch
    // If the parent is not in the batch, this means the parent must already exist.
    if (eventGroup.parentId != null && newEventGroupsById[eventGroup.parentId] != null) {
      newEventGroupsByParentId[eventGroup.parentId] ??= [];
      newEventGroupsByParentId[eventGroup.parentId].push(eventGroup);
      newEventGroupsWithParentInBatch.add(eventGroup.id);
    }

    eventGroup.eventGroupIds?.forEach((childId) => {
      newEventGroupsByParentId[eventGroup.id] ??= [];
      if (newEventGroupsById[childId] != null) {
        newEventGroupsByParentId[eventGroup.id].push(newEventGroupsById[childId]);
        newEventGroupsWithParentInBatch.add(childId);
      }
    });

    eventGroup.eventIds?.forEach((eventId) => {
      newEventsById = newEventsById ?? keyBy(newEvents, 'id');
      const event = newEventsById[eventId];
      if (event != null) {
        // This means the child event is being created in the same mutation batch.
        // If this is the case, the event's parentId should fall back to the event group's id.
        // This is necessary because event groups are created before events, so setting the
        // event parentId ensures the event will get reparented into the event group when created.

        event.parentId = event.parentId ?? eventGroup.id;
      }
    });
  });

  const visitedEventGroupIds = new Set();
  const sortedNewEventGroups = [];

  // Start with the root event groups / ones whose parents already exist
  const queue: EventGroupCreateInput[] = newEventGroups.filter(
    (eg) => !newEventGroupsWithParentInBatch.has(eg.id),
  );

  // There should be at least one event group that has either an undefined parent (root-level)
  // or an existing parent. If not, we have a circular dependency and throw an error.
  if (queue.length === 0) {
    throw new Error('Circular dependency in new event groups');
  }

  while (queue.length > 0) {
    const eg = queue.shift()!;

    if (visitedEventGroupIds.has(eg.id)) {
      continue;
    }

    sortedNewEventGroups.push(eg);
    visitedEventGroupIds.add(eg.id);
    const children = newEventGroupsByParentId[eg.id] ?? [];
    queue.push(...children);
  }

  return { newEventGroups: sortedNewEventGroups, newEvents };
}

export type BulkCreateEventsAndGroupsReturnType = Pick<
  DatasetMutationInput,
  'newEvents' | 'newEventGroups' | 'updateEvents' | 'updateEventGroups' | 'deleteEvents'
>;
export const bulkCreateEventsAndGroupsMutations = (
  state: RootState,
  newItems: {
    events: EventCreateInput[];
    groups: EventGroupCreateInput[];
    extras?: DatasetMutationInput;
  },
  insertBeforeId: EventId | EventGroupId | 'start' | 'end' = 'end',
): BulkCreateEventsAndGroupsReturnType => {
  const hiddenByEventGroupId = mapValues(
    eventGroupsWithoutLiveEditsByIdForLayerSelector(state),
    'hidden',
  );

  const addHiddenField = <T extends EventCreateInput | EventGroupCreateInput>(entity: T): T => ({
    ...entity,
    hidden: entity.parentId == null ? undefined : hiddenByEventGroupId[entity.parentId],
  });

  const { newEventGroups, newEvents } = sortNewEventGroupsAndNewEvents(
    newItems.groups.map(addHiddenField),
    newItems.events.map(addHiddenField),
  );
  const deleteEvents = getDeleteEventsToClearStackedEvents(state, newEvents);

  let mutations: BulkCreateEventsAndGroupsReturnType = {
    newEvents,
    newEventGroups,
    deleteEvents,
  };
  mutations = newItems.extras != null ? mergeMutations(mutations, newItems.extras) : mutations;

  if (insertBeforeId === 'end') {
    // If sortIndex is not included in the mutation but parentId is, the entitiy is
    // automatically assigned a a sortIndex at the end of its parent group as a
    // side-effect. See `reparent` function in event mutation handling.
    return mutations;
  }

  // In order to bulk backfill sortIndexes, it is easier to update the state
  // and then apply sort index updates (if necessary) afterwards.
  state = peekMutationStateChange(state, mutations);

  // N.B. mutations for events are handled before mutations for event groups
  // we want to maintain the sort order passed in the arguments
  const createInputs =
    insertBeforeId === 'end'
      ? [...newEvents, ...newEventGroups]
      : [...newEvents.toReversed(), ...newEventGroups.toReversed()];

  createInputs.forEach((create) => {
    const backfillData = sortIndexMutationsForInsertion(state, create.id, insertBeforeId);
    // TODO(COL-275): Peeking at mutation state change in this loop is extremely bad for performance.
    // Ideas on how to remove in the linked ticket.
    state = peekMutationStateChange(state, backfillData);
    mutations = mergeMutations(mutations, backfillData);
  });

  return stripInsertBeforeId(mutations);
};

export default bulkCreateEventsAndGroupsMutations;
