import { CheckIcon } from '@chakra-ui/icons';
import { Box, List, ListItem } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef } from 'react';

import DriverPropertyTableCell from 'components/DriverPropertyTableCell/DriverPropertyTableCell';
import TableCellPopover from 'components/TableCellPopover/TableCellPopover';
import { ColumnLayerId, ModelViewColumnType } from 'config/modelView';
import { preventEventDefault } from 'helpers/browserEvent';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useDriverCellRef from 'hooks/useDriverCellRef';
import { useRovingFocus } from 'hooks/useRovingFocus';
import useTableCellPopover from 'hooks/useTableCellPopover';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

interface PropertyPickerItem<T> {
  item: T;
  isSelected: boolean;
}

interface Props<T> {
  options: Array<PropertyPickerItem<T>>;
  onSelect: (item: T) => void;
  renderItem: React.JSXElementConstructor<{ item: T }>;
  title: string;
  columnType: ModelViewColumnType;
  columnLayerId: ColumnLayerId;
  disabled?: boolean;
  children: React.ReactNode;
}

const PropertyPicker = <T,>({
  options,
  onSelect,
  renderItem,
  children,
  columnType,
  columnLayerId,
  disabled = false,
}: Props<T>) => {
  const { blockId } = useBlockContext();
  const width = useAppSelector((state) => columnWidthSelector(state, { columnType, blockId }));
  const cellRef = useDriverCellRef({ columnType, columnLayerId });
  const { selectCellAndOpenPopover, showPopover, closePopover } = useTableCellPopover(cellRef);
  const listRef = useRef<HTMLUListElement | null>(null);
  const Item = renderItem;

  // TODO: @jlack -- factor out some of the common code with SelectMenu
  const { focusIdx, setFocusIdx, onKeyDown } = useRovingFocus(options.length);
  const onMenuKeyDown = useCallback(
    (ev: React.KeyboardEvent) => {
      onKeyDown(ev);
      switch (ev.key) {
        case 'Escape': {
          ev.preventDefault();
          closePopover();
          break;
        }
        case 'Enter': {
          ev.preventDefault();
          if (focusIdx <= options.length - 1) {
            const option = options[focusIdx];
            if (option != null) {
              onSelect(option.item);
              closePopover();
            }
          }

          break;
        }
        default: {
          break;
        }
      }
    },
    [focusIdx, onKeyDown, onSelect, closePopover, options],
  );

  useEffect(() => {
    if (showPopover) {
      // N.B. wait until the popover content renders
      window.requestAnimationFrame(() => listRef.current?.focus());
    }
  }, [showPopover]);

  return (
    <TableCellPopover
      disabled={disabled}
      visible={showPopover}
      onClose={closePopover}
      autoFocus={false}
      width={width}
      minWidth="min-content"
      content={
        <List variant="select" ref={listRef} tabIndex={0} onKeyDown={onMenuKeyDown} py={1} px={0}>
          {options.map((opt, idx) => (
            <ListItem
              key={idx}
              color="inherit"
              bg={focusIdx === idx ? 'gray.100' : undefined}
              onMouseOver={() => setFocusIdx(idx)}
              onMouseDown={(ev) => {
                preventEventDefault(ev);
                closePopover();
                onSelect(opt.item);
              }}
            >
              <Box pr={2} visibility={opt.isSelected ? 'visible' : 'hidden'}>
                <CheckIcon fill="gray.600" />
              </Box>
              <Item item={opt.item} />
            </ListItem>
          ))}
        </List>
      }
    >
      <DriverPropertyTableCell
        columnType={columnType}
        columnLayerId={columnLayerId}
        isSticky
        onMouseDown={selectCellAndOpenPopover}
      >
        {children}
      </DriverPropertyTableCell>
    </TableCellPopover>
  );
};

export default PropertyPicker;
