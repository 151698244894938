import { createIcon } from '@chakra-ui/react';

const Logo = createIcon({
  displayName: 'RunwayLogoArrow',
  viewBox: '0 0 18 14',
  path: (
    <path
      fill="#1D2122"
      d="M11.526 13.99h3.184a.81.81 0 0 0 .787-.638L17.946 2.53c.294-1.29-.643-2.527-1.94-2.527L6.777 0c-.309 0-.59.18-.726.463l-1.574 3.3h6.681L0 14h4.683c.297 0 .584-.112.805-.313l7.594-6.907-1.556 7.21Z"
    />
  ),
});

export default Logo;
