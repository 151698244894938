import { createSelector } from 'reselect';

import { AccessCapabilitiesProvider } from 'helpers/accessCapabilities/AccessCapabilitiesProvider';
import { accessResourcesByIdSelector } from 'selectors/accessResourcesByIdSelector';
import { layerCreatedByUserIdByIdSelector } from 'selectors/layerSelector';
import { isSuperPermissionSelector, loggedInUserSelector } from 'selectors/loginSelector';
import { orgRoleSelector } from 'selectors/selectedOrgSelector';

export const accessCapabilitiesSelector = createSelector(
  loggedInUserSelector,
  orgRoleSelector,
  isSuperPermissionSelector,
  accessResourcesByIdSelector,
  layerCreatedByUserIdByIdSelector,
  (user, orgRole, hasSuperPermission, accessResourcesById, layerCreatedByUserIdById) => {
    const provider = new AccessCapabilitiesProvider({
      orgRole,
      userId: user?.id,
      isRunwaySuperUser: hasSuperPermission,
      accessResources: Object.values(accessResourcesById),
      layerCreatedByUserIdById,
    });

    return provider;
  },
);
