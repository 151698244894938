import borders from './borders';
import colors from './colors';
import shadows from './shadows';
import textStyles from './textStyles';
import typography from './typography';

const foundations = {
  ...typography,
  ...borders,
  semanticTokens: { colors, shadows },
  colors,
  textStyles,
  shadows,
};

export default foundations;
