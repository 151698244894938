import { datadogLogs } from '@datadog/browser-logs';

import { isDevelopment, isUserBrowser } from 'helpers/environment';

const DATASET_FETCHED_ELAPSED_TIME_HEADER = 'DATASET FETCHED';
const DATASET_INITIALIZED_ELAPSED_TIME_HEADER = 'DATASET INITIALIZED';
const DATASET_RELOAD_HEADER = 'DATASET RELOAD';

const NAMED_VERSIONS_ELAPSED_TIME_HEADER = 'NAMED VERSIONS FETCHED';

const MUTATION_SUBMISSION_ISSUE_HEADER = 'MUTATION SUBMISSION ISSUE';

const MULTI_LAYER_MUTATION_SUBMISSION_HEADER = 'MULTI-LAYER MUTATION SUBMISSION';

const WEBWORKER_INITIALIZED_HEADER = 'WEBWORKER INITIALIZED';

const logInfo = (log: string, context?: NullableRecord<string, any>) => {
  if (!self.isMainThread || !isUserBrowser()) {
    return;
  }
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.info(log, context);
  }

  datadogLogs.logger.info(log, context);
};

const logError = (log: string, context?: NullableRecord<string, any>) => {
  if (!self.isMainThread || !isUserBrowser()) {
    return;
  }
  if (isDevelopment) {
    console.error(log);
  }
  datadogLogs.logger.error(log, context);
};

export const logDatasetFetchedElapsedTimeMs = (elapsed: number) => {
  logInfo(`[${DATASET_FETCHED_ELAPSED_TIME_HEADER}]`, { elapsed: Math.floor(elapsed) });
};

export const logDatasetInitializedElapsedTimeMs = (elapsed: number) =>
  logInfo(`[${DATASET_INITIALIZED_ELAPSED_TIME_HEADER}]`, { elapsed: Math.floor(elapsed) });

export const logNamedVersionsFetchedElapsedTimeMs = (elapsed: number, count: number) => {
  logInfo(`[${NAMED_VERSIONS_ELAPSED_TIME_HEADER}]`, { elapsed: Math.floor(elapsed), count });
};

export const logDatasetReload = (context: {
  cause:
    | 'datasetSubscription'
    | 'applyRemoteMutation'
    | 'submitMutation'
    | 'submitMutationStale'
    | 'missingLayer'
    | 'undoLatest'
    | 'redoLatest';
  serverError?: string;
}) => {
  logError(`[${DATASET_RELOAD_HEADER}]`, context);
};

export const logMutationSubmissionIssue = (context: {
  cause: 'serverError' | 'datasetStale';
  serverError?: string;
}) => {
  logError(`[${MUTATION_SUBMISSION_ISSUE_HEADER}]`, context);
};

export const logMultiLayerMutationSubmission = (context: {
  action: string | null;
  defaultLayerMutationFields: string[];
  currentLayerMutationFields: string[];
}) => {
  logInfo(`[${MULTI_LAYER_MUTATION_SUBMISSION_HEADER}]`, context);
};

export const logWebworkerInitialized = (context: { orgId: string }) => {
  logInfo(`[${WEBWORKER_INITIALIZED_HEADER}]`, context);
};

export const logWebworkerEmittedMessage = ({
  level,
  msg,
  context,
}: {
  level: 'info' | 'error';
  msg: string;
  context?: NullableRecord<string, any>;
}) => {
  const contextWithWebworkerFlag = { isWebworker: true, ...context };
  if (level === 'info') {
    logInfo(msg, contextWithWebworkerFlag);
  } else {
    logError(msg, contextWithWebworkerFlag);
  }
};
