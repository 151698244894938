import { ComparisonTimePeriod, RollupType } from 'generated/graphql';

export const ROLLUP_TO_LABEL: Record<RollupType, string> = {
  [RollupType.Month]: 'Monthly',
  [RollupType.Quarter]: 'Quarterly',
  [RollupType.Annual]: 'Annually',
  // --
  [RollupType.QuarterToDate]: 'Quarter to date',
  [RollupType.HalfYearToDate]: 'Half-year to date',
  [RollupType.YearToDate]: 'Year to date',
  // --
  [RollupType.QuarterToLastClose]: 'Quarter to last close',
  [RollupType.HalfYearToLastClose]: 'Half-year to last close',
  [RollupType.YearToLastClose]: 'Year to last close',
  // --
  [RollupType.Trailing_3Months]: 'Trailing 3 months',
  [RollupType.Trailing_6Months]: 'Trailing 6 months',
  [RollupType.Trailing_12Months]: 'Trailing 12 months',
  // --
  [RollupType.MonthlyAnnually]: 'Monthly -> Annually',
  [RollupType.MonthlyQuarterly]: 'Monthly -> Quarterly',
  [RollupType.MonthlyQuarterlyAnnually]: 'Monthly -> Quarterly -> Annually',
  // --
  [RollupType.LastClose]: 'Last close',
  [RollupType.CurrentMonth]: 'Current month',
};

export const ROLLUP_TO_LABEL_SHORT: Record<RollupType, string> = {
  [RollupType.Month]: "May '24",
  [RollupType.Quarter]: "Q3 '24",
  [RollupType.Annual]: "FY '24",
  // --
  [RollupType.QuarterToDate]: 'QTD',
  [RollupType.HalfYearToDate]: 'HYTD',
  [RollupType.YearToDate]: 'YTD',
  // --
  [RollupType.QuarterToLastClose]: 'QLC',
  [RollupType.HalfYearToLastClose]: 'HYLC',
  [RollupType.YearToLastClose]: 'YLC',
  // --
  [RollupType.Trailing_3Months]: 'T3M',
  [RollupType.Trailing_6Months]: 'T6M',
  [RollupType.Trailing_12Months]: 'TTM',
  // --
  [RollupType.MonthlyAnnually]: '',
  [RollupType.MonthlyQuarterly]: '',
  [RollupType.MonthlyQuarterlyAnnually]: '',
  // --
  [RollupType.LastClose]: '',
  [RollupType.CurrentMonth]: '',
};

const CORE_ROLLUP_TYPES = [RollupType.Month, RollupType.Quarter, RollupType.Annual];

export const BASIC_ROLLUP_TYPES = [
  ...CORE_ROLLUP_TYPES,
  RollupType.MonthlyQuarterly,
  RollupType.MonthlyAnnually,
  RollupType.MonthlyQuarterlyAnnually,
];

export const CUSTOM_ROLLUP_TYPES = [
  ...CORE_ROLLUP_TYPES,
  // --
  RollupType.QuarterToDate,
  RollupType.HalfYearToDate,
  RollupType.YearToDate,
  // --
  RollupType.QuarterToLastClose,
  RollupType.HalfYearToLastClose,
  RollupType.YearToLastClose,
  // --
  RollupType.Trailing_3Months,
  RollupType.Trailing_6Months,
  RollupType.Trailing_12Months,
  RollupType.LastClose,
  RollupType.CurrentMonth,
];

export const ALL_TIME_PERIODS = [
  ComparisonTimePeriod.CurrentPeriod,
  ComparisonTimePeriod.PreviousPeriod,
  ComparisonTimePeriod.ThreeMonthsAgo,
  ComparisonTimePeriod.SixMonthsAgo,
  ComparisonTimePeriod.TwelveMonthsAgo,
];

export const TIME_PERIOD_LABEL = {
  [ComparisonTimePeriod.CurrentPeriod]: 'Current period',
  [ComparisonTimePeriod.PreviousPeriod]: 'Previous period',
  [ComparisonTimePeriod.ThreeMonthsAgo]: '3 months ago',
  [ComparisonTimePeriod.SixMonthsAgo]: '6 months ago',
  [ComparisonTimePeriod.TwelveMonthsAgo]: '12 months ago',
};
