import { DriverPropertyCreateData } from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { createNewBusinessObjectDriverProperty } from 'reduxStore/actions/businessObjectSpecMutations';
import { createNewDriversInContext } from 'reduxStore/actions/driverMutations';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { DriverId } from 'reduxStore/models/drivers';
import { AppThunk } from 'reduxStore/store';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectsBySpecIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { computedAttributePropertiesForBusinessObjectSelector } from 'selectors/collectionSelector';
import { dimDriverSelector } from 'selectors/driversSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';

export const convertFieldSpecToDimensionalDriver =
  ({
    blockId,
    fieldSpecId,
  }: {
    blockId: BlockId;
    fieldSpecId: BusinessObjectFieldSpecId;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const objectSpecId = businessObjectSpecForBlockSelector(state, blockId)?.id;
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });

    if (objectSpecId == null) {
      return;
    }
    const onCreateSuccess = ({ driverId }: DriverPropertyCreateData) => {
      dispatch(
        createSubDriverForBusinessObjects({
          objectSpecId,
          dimDriverId: driverId,
          fieldSpecId,
          blockId,
        }),
      );
    };
    /** It is very important to create a driver property with the same id as the fieldSpec we are converting
     * See /src/helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator.ts#L345 This is important in order to gradually
     * mitigrate to using only drivers for databases
     */
    dispatch(
      createNewBusinessObjectDriverProperty({
        objectSpecId,
        propertyName: fieldSpec?.name,
        driverPropertyId: fieldSpec?.id,
        renameOldColumn: true,
        onSuccess: onCreateSuccess,
      }),
    );
  };

const createSubDriverForBusinessObjects =
  ({
    blockId,
    objectSpecId,
    dimDriverId,
    fieldSpecId,
  }: {
    blockId: BlockId;
    objectSpecId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId;
    dimDriverId: DriverId;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const objectsBySpecId = businessObjectsBySpecIdForLayerSelector(state);
    const objects = objectsBySpecId[objectSpecId] ?? [];
    const dimDriverForProperty = dimDriverSelector(state, { id: dimDriverId });
    if (dimDriverForProperty != null) {
      const newDriversInput = objects
        .map((object) => {
          const allAttributesForObject = computedAttributePropertiesForBusinessObjectSelector(
            state,
            object.id,
          ).map((p) => p.attribute);

          const objectField = object.fields.find((field) => field.fieldSpecId === fieldSpecId);
          if (objectField != null) {
            return {
              id: uuidv4(),
              name: dimDriverForProperty.name,
              ...(allAttributesForObject.length > 0
                ? {
                    dimDriver: {
                      driverId: dimDriverForProperty.id,
                      attributes: allAttributesForObject,
                    },
                    actuals: {
                      timeSeries: objectField.value?.actuals.timeSeries,
                      //Note this is not quite right if object has a startDate but we can't write a formula that says this is the value after this date
                      formula: objectField.value?.initialValue,
                    },
                  }
                : {}),
            };
          }
          return null;
        })
        .filter(isNotNull);
      dispatch(
        createNewDriversInContext({
          // create new subdriver for each business object
          newDrivers: newDriversInput,
          context: {
            belowDriverId: dimDriverForProperty.id,
            // there are no driver groups in databases
            groupId: undefined,
            blockId,
            skipAddingToSubmodel: true,
          },
          select: false,
        }),
      );
    }
  };
