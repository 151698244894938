import { mapValues, uniq } from 'lodash';
import countBy from 'lodash/countBy';
import intersection from 'lodash/intersection';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import { createCachedSelector } from 're-reselect';

import {
  getSubmodelIdFromInternalPageType,
  getSubmodelInternalPageType,
} from 'config/internalPages/modelPage';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { BlocksPage } from 'reduxStore/models/blocks';
import { Driver, DriverId, DriverType } from 'reduxStore/models/drivers';
import { ExtDriverId } from 'reduxStore/models/extDrivers';
import { DEFAULT_SUBMODEL_ID, Submodel, SubmodelId } from 'reduxStore/models/submodels';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { blocksPageByInternalPageTypeSelector } from 'selectors/blocksPagesTableSelector';
import { extDriverIdSelector } from 'selectors/constSelectors';
import {
  dimensionalDriversBySubDriverIdSelector,
  driversByIdForLayerSelector,
  driversForLayerSelector,
} from 'selectors/driversSelector';
import {
  extDriverDependencyMatrixSelector,
  extDriversByIdSelector,
} from 'selectors/extDriversSelector';
import { submodelIdByBlockIdSelector } from 'selectors/submodelPageSelector';
import { submodelsByIdSelector } from 'selectors/submodelsByIdSelector';
import { ParametricSelector, Selector } from 'types/redux';

export const submodelIdsByDriverIdSelector: SelectorWithLayerParam<Record<DriverId, SubmodelId[]>> =
  createCachedSelector(
    addLayerParams(driversForLayerSelector),
    dimensionalDriversBySubDriverIdSelector,
    submodelIdByBlockIdSelector,
    (drivers, dimDriverBySubdriverId, submodelIdByBlockId) => {
      const submodelIdsByDriverId = Object.fromEntries(
        drivers.map((d) => {
          const submodelIds = (d.driverReferences ?? [])
            .map((ref) => {
              const submodelId = submodelIdByBlockId[ref.blockId];
              if (submodelId == null) {
                return null;
              }
              return submodelId;
            })
            .filter(isNotNull);

          return [d.id, uniq(submodelIds)];
        }),
      );
      drivers.forEach((d) => {
        if (submodelIdsByDriverId[d.id] != null) {
          return;
        }
        const dimDriver = safeObjGet(dimDriverBySubdriverId[d.id]);
        const dimDriverSubdrivers = dimDriver == null ? null : submodelIdsByDriverId[dimDriver.id];
        if (dimDriver != null && dimDriverSubdrivers != null) {
          submodelIdsByDriverId[d.id] = [...dimDriverSubdrivers];
          return;
        }
        submodelIdsByDriverId[d.id] = [DEFAULT_SUBMODEL_ID];
      });

      return submodelIdsByDriverId;
    },
  )(getCacheKeyForLayerSelector);

export const driverIdToSubmodelIdSelector: SelectorWithLayerParam<Record<DriverId, SubmodelId>> =
  createCachedSelector(addLayerParams(submodelIdsByDriverIdSelector), (submodelIdsByDriverId) => {
    return mapValues(submodelIdsByDriverId, (submodelIds) => submodelIds[0]);
  })(getCacheKeyForLayerSelector);

export const driversBySubmodelIdSelector: SelectorWithLayerParam<
  NullableRecord<SubmodelId, Driver[]>
> = createCachedSelector(
  addLayerParams(submodelIdsByDriverIdSelector),
  addLayerParams(submodelsByIdSelector),
  addLayerParams(driversByIdForLayerSelector),
  (submodelIdsByDriverId, submodelsById, driversById) => {
    const result: NullableRecord<SubmodelId, Driver[]> = {};
    Object.keys(submodelsById).forEach((submodelId) => {
      result[submodelId] = [];
    });
    Object.entries(submodelIdsByDriverId).forEach(([driverId, submodelIds]) => {
      submodelIds.forEach((submodelId) => {
        const driversForSubmodel = result[submodelId] ?? [];
        result[submodelId] = driversForSubmodel;

        const driver = driversById[driverId];
        if (driver != null) {
          driversForSubmodel.push(driver);
        }
      });
    });

    return result;
  },
)(getCacheKeyForLayerSelector);

export const submodelsWithDefaultByIdSelector: SelectorWithLayerParam<
  Record<SubmodelId, Submodel>
> = createCachedSelector(
  submodelsByIdSelector,
  driversBySubmodelIdSelector,
  function submodelsWithDefaultByIdSelector(submodels, driversBySubmodelId) {
    // Only add default submodel if it has basic drivers.
    // we don't show dimensional drivers in the submodel right now.
    // This is also not needed if all drivers belong to submodels
    const defaultSubmodelBasicDrivers = (driversBySubmodelId[DEFAULT_SUBMODEL_ID] ?? []).filter(
      (d) => d.type !== DriverType.Dimensional,
    );
    if (defaultSubmodelBasicDrivers.length > 0) {
      const allSubmodels = { ...submodels };

      return allSubmodels;
    }

    return submodels;
  },
)(getCacheKeyForLayerSelector);

export const allSubmodelsSelector: SelectorWithLayerParam<Submodel[]> = createCachedSelector(
  addLayerParams(submodelsWithDefaultByIdSelector),
  (submodelsById) => Object.values(submodelsById),
)(getCacheKeyForLayerSelector);

const BG_COLORS = ['#F7CDCD', '#CCEDFF', '#FCEED4', '#E9E2F2'];

type SubmodelStyleMetadata = {
  bgColor: string;
};

export const submodelStylesByIdSelector: SelectorWithLayerParam<
  Record<SubmodelId, SubmodelStyleMetadata>
> = createDeepEqualSelector(allSubmodelsSelector, (allSubmodels) => {
  const result: Record<SubmodelId, SubmodelStyleMetadata> = {};
  allSubmodels.forEach((submodel, idx) => {
    result[submodel.id] = {
      bgColor: BG_COLORS[idx % BG_COLORS.length],
    };
  });
  return result;
});

export const sortedAccessibleSubmodelPagesSelector: SelectorWithLayerParam<
  Array<BlocksPage & { submodelId: string }>
> = createCachedSelector(
  addLayerParams(submodelsByIdSelector),
  (state) => blocksPageByInternalPageTypeSelector(state),
  (state) => accessCapabilitiesSelector(state),
  (datasetSubmodels, blocksPageByInternalPageType, capabilitiesProvider) => {
    const submodels = Object.values(datasetSubmodels);
    const submodelPages = submodels
      .map(({ id }) => blocksPageByInternalPageType[getSubmodelInternalPageType(id)])
      .filter(isNotNull)
      .map((page) => ({
        ...page,
        submodelId: getSubmodelIdFromInternalPageType(page.internalPageType ?? ''),
      }))
      .filter((page) => capabilitiesProvider.canReadPage(page.id));

    return sortBy(submodelPages, (page) => page.sortIndex);
  },
)(getCacheKeyForLayerSelector);

export const sortedAccessibleSubmodelIdsSelector: Selector<SubmodelId[]> = createDeepEqualSelector(
  sortedAccessibleSubmodelPagesSelector,
  (sortedSubmodelPages) => {
    const sortedSubmodelIds = sortedSubmodelPages.map((submodelPage) => submodelPage.submodelId);
    return sortedSubmodelIds;
  },
);

export const defaultSubmodelIdForExtDriver: ParametricSelector<
  ExtDriverId,
  SubmodelId | undefined
> = createCachedSelector(
  extDriverDependencyMatrixSelector,
  (state) => extDriversByIdSelector(state),
  (state) => driversByIdForLayerSelector(state),
  (state) => submodelIdByBlockIdSelector(state),
  sortedAccessibleSubmodelIdsSelector,
  extDriverIdSelector,
  // eslint-disable-next-line max-params
  function defaultSubmodelIdForExtDriver(
    extDriverDependencyMatrix,
    extDriverById,
    driversById,
    submodelIdByBlockId,
    sortedSubmodelIds,
    extDriverId,
  ) {
    const extDriver = extDriverById[extDriverId];
    const firstSubmodelId = sortedSubmodelIds.length > 0 ? sortedSubmodelIds[0] : undefined;
    if (extDriver == null) {
      return firstSubmodelId;
    }
    const { source, path } = extDriver;
    // Max path match is the longest common path between the driver being linked and another linked ext driver
    let maxPathMatch = 0;
    const driverToMatchLength = Object.entries(extDriverDependencyMatrix)
      .map(([driverId, dependencies]) => {
        const filteredDeps = dependencies.filter(
          (depId) => extDriverById[depId]?.source === source,
        );
        return { driverId, filteredDeps };
      })
      .filter(({ filteredDeps }) => filteredDeps.length > 0)
      .map(({ driverId, filteredDeps }) => {
        return {
          driverId,
          matchLength: filteredDeps.reduce((max, depId) => {
            const pathMatch = intersection(path, extDriverById[depId]?.path).length;
            if (pathMatch > maxPathMatch) {
              maxPathMatch = pathMatch;
            }
            if (pathMatch > max) {
              return pathMatch;
            }
            return max;
          }, 0),
        };
      });

    // Each driver which is linked to ext driver at the max path gets 1 vote for its submodel
    // Submodel with most votes will be the submodel of the new driver
    const submodelIdVotes = driverToMatchLength
      .filter(({ matchLength }) => matchLength === maxPathMatch)
      .flatMap(({ driverId }) => {
        const driver = driversById[driverId];
        if (driver == null || driver.driverReferences == null) {
          return null;
        }

        return driver.driverReferences.map(({ blockId }) => submodelIdByBlockId[blockId]);
      })
      .filter(isNotNull);
    const submodelId = maxBy(
      Object.entries(countBy(submodelIdVotes)),
      ([_submodelId, count]) => count,
    )?.[0];

    // If no submodel is linked, return first submodel according to sort.
    return submodelId ?? firstSubmodelId;
  },
)((_state, source) => source);
