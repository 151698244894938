import { useMemo } from 'react';

import { ContextMenuItem } from 'components/ContextMenuItems/ContextMenuItem';
import DriverFormatSelectMenu from 'components/DriverFormatSelectMenu/DriverFormatSelectMenu';
import { DriverDataCellRef } from 'config/cells';
import { DRIVER_FORMAT_ICONS, DRIVER_FORMAT_NAMES } from 'config/drivers';
import { DriverFormat } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import { driverSelector } from 'selectors/driversSelector';
import {
  driverDecimalPlacesSelector,
  driverDisplayConfigurationSelector,
} from 'selectors/entityDisplayConfigurationSelector';

export default function useDriverDataCellContextMenuItems(
  cellRef: DriverDataCellRef,
): ContextMenuItem[] {
  const driverId = cellRef.rowKey.driverId ?? '';
  const format = useAppSelector(
    (state) => driverSelector(state, { id: driverId })?.format ?? DriverFormat.Auto,
  );

  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );

  const driverDecimalPlaces = useAppSelector((state) =>
    driverDecimalPlacesSelector(state, driverId),
  );

  const items = useMemo(() => {
    const { currency } = displayConfiguration;
    const allItems: ContextMenuItem[] = [
      ...[
        {
          text: 'Format',
          icon: DRIVER_FORMAT_ICONS[format],
          meta: DRIVER_FORMAT_NAMES[format],
          multiSelect: true,
          isEdit: true,
          onSelect: null,
          submenu: () => (
            <DriverFormatSelectMenu
              driverId={driverId}
              cellRef={cellRef}
              currency={currency}
              decimalPlaces={driverDecimalPlaces}
              format={format}
            />
          ),
        },
      ],
    ];

    return allItems;
  }, [displayConfiguration, format, driverId, cellRef, driverDecimalPlaces]);

  return items;
}
