import {
  BlockViewAsType,
  BlockViewOptions,
  ChartGroupingType,
  ChartSeriesType,
} from 'generated/graphql';

export const isLineChart = (blockView: BlockViewOptions): boolean => {
  return (
    blockView.viewAsType == null ||
    blockView.viewAsType === BlockViewAsType.Chart ||
    blockView.viewAsType === BlockViewAsType.LineChart
  );
};

const isAreaChart = (blockView: BlockViewOptions): boolean => {
  return blockView.viewAsType === BlockViewAsType.AreaChart;
};

export const isBarChart = (blockView: BlockViewOptions): boolean => {
  return blockView.viewAsType === BlockViewAsType.BarChart;
};

export const isComboChart = (blockView: BlockViewOptions): boolean => {
  return blockView.viewAsType === BlockViewAsType.ComboChart;
};

const isPieChart = (blockView: BlockViewOptions): boolean => {
  return blockView.viewAsType === BlockViewAsType.PieChart;
};

const isDonutChart = (blockView: BlockViewOptions): boolean => {
  return blockView.viewAsType === BlockViewAsType.DonutChart;
};

const isNightingaleChart = (blockView: BlockViewOptions): boolean => {
  return blockView.viewAsType === BlockViewAsType.NightingaleChart;
};

const isTreemapChart = (blockView: BlockViewOptions): boolean => {
  return blockView.viewAsType === BlockViewAsType.TreemapChart;
};

export const isWaterfallChart = (blockView: BlockViewOptions): boolean => {
  return blockView.viewAsType === BlockViewAsType.WaterfallChart;
};

export const isMultiDriverChart = (blockView: BlockViewOptions): boolean => {
  if (blockView.chartGroupingType == null) {
    return false;
  }

  if (
    !isLineChart(blockView) &&
    !isBarChart(blockView) &&
    !isAreaChart(blockView) &&
    !isComboChart(blockView) &&
    !isPieChart(blockView) &&
    !isDonutChart(blockView) &&
    !isNightingaleChart(blockView) &&
    !isTreemapChart(blockView) &&
    !isWaterfallChart(blockView)
  ) {
    return false;
  }

  return blockView.chartGroupingType === ChartGroupingType.Multi;
};

export const isGroupComparisonChart = (blockView: BlockViewOptions): boolean => {
  const driverIds = new Set<string>();
  return (
    blockView.chartDisplay?.groups.some((g) => {
      return g.seriesIds.some((id) => {
        const series = blockView.chartDisplay?.series.find((s) => s.id === id);
        if (!series) {
          return false;
        }
        if (driverIds.has(series.driverId)) {
          return true;
        }

        // Track unique driver IDs added to the chart.
        driverIds.add(series.driverId);

        return false;
      });
    }) ?? false
  );
};

export const isDriverValueChart = (blockView: BlockViewOptions): boolean => {
  return blockView.viewAsType === BlockViewAsType.CurrentValue;
};

export const isAggregatedValueChart = (blockView: BlockViewOptions): boolean => {
  return blockView.aggregateValues || false;
};

export const everySeriesType = (blockView: BlockViewOptions, ...types: ChartSeriesType[]) => {
  return types.some((type) =>
    blockView.chartDisplay?.series.every((series) => series.type === type),
  );
};
