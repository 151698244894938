import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';

import PlusIcon from 'vectors/Plus';

interface Props
  extends Pick<
    IconButtonProps,
    | 'visibility'
    | 'onClick'
    | 'aria-label'
    | 'color'
    | 'isActive'
    | 'disabled'
    | 'margin'
    | 'padding'
  > {
  'data-testid'?: string;
}

const PlusIconButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  return (
    <IconButton
      icon={<PlusIcon />}
      ref={ref}
      borderRadius="md"
      variant="text"
      color="gray.500"
      _hover={{ bgColor: 'gray.200', color: 'gray.500' }}
      _active={{ bgColor: 'gray.300', color: 'gray.500' }}
      padding={1}
      {...props}
    />
  );
});

export default PlusIconButton;
