import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'XeroIcon',
  viewBox: '0 0 787 787',
  path: (
    <>
      <path
        d="M393.411 785.32C609.857 785.32 785.32 609.857 785.32 393.409C785.32 176.964 609.857 1.50001 393.411 1.50001C176.964 1.50001 1.5 176.964 1.5 393.409C1.5 609.857 176.964 785.32 393.411 785.32Z"
        fill="#1AB4D7"
      />
      <path
        d="M189.101 392.277L255.984 325.229C258.201 322.969 259.439 319.977 259.439 316.804C259.439 310.191 254.063 304.82 247.449 304.82C244.228 304.82 241.208 306.083 238.931 308.388C238.924 308.401 172.108 375.179 172.108 375.179L104.987 308.288C102.719 306.052 99.7133 304.82 96.5267 304.82C89.9213 304.82 84.544 310.188 84.544 316.795C84.544 320.017 85.8307 323.041 88.14 325.32L155.013 392.184L88.1747 459.148C85.8253 461.461 84.544 464.492 84.544 467.729C84.544 474.341 89.9213 479.705 96.5267 479.705C99.7187 479.705 102.727 478.467 104.987 476.205L172.004 409.279L238.767 475.961C241.135 478.409 244.189 479.713 247.449 479.713C254.056 479.713 259.432 474.341 259.432 467.729C259.432 464.539 258.196 461.541 255.947 459.281L189.101 392.277Z"
        fill="white"
      />
      <path
        d="M586.708 392.257C586.708 404.265 596.472 414.033 608.495 414.033C620.489 414.033 630.257 404.265 630.257 392.257C630.257 380.247 620.489 370.48 608.495 370.48C596.472 370.48 586.708 380.247 586.708 392.257Z"
        fill="white"
      />
      <path
        d="M545.407 392.268C545.407 357.495 573.695 329.201 608.472 329.201C643.232 329.201 671.527 357.495 671.527 392.268C671.527 427.033 643.232 455.315 608.472 455.315C573.695 455.315 545.407 427.033 545.407 392.268M520.599 392.268C520.599 440.713 560.019 480.128 608.472 480.128C656.924 480.128 696.364 440.713 696.364 392.268C696.364 343.816 656.924 304.391 608.472 304.391C560.019 304.391 520.599 343.816 520.599 392.268Z"
        fill="white"
      />
      <path
        d="M514.361 305.893L510.675 305.883C499.607 305.883 488.932 309.373 480.016 316.244C478.841 310.868 474.036 306.823 468.312 306.823C461.724 306.823 456.445 312.101 456.429 318.703C456.429 318.727 456.469 466.703 456.469 466.703C456.487 473.292 461.861 478.648 468.453 478.648C475.044 478.648 480.417 473.292 480.435 466.688C480.435 466.659 480.44 375.687 480.44 375.687C480.44 345.356 483.213 333.107 509.195 329.86C511.596 329.561 514.208 329.608 514.219 329.608C521.328 329.364 526.38 324.479 526.38 317.876C526.38 311.269 520.987 305.893 514.361 305.893"
        fill="white"
      />
      <path
        d="M284.263 377.801C284.263 377.473 284.288 377.132 284.304 376.795C291.261 349.292 316.169 328.94 345.833 328.94C375.856 328.94 400.992 349.792 407.593 377.801H284.263ZM432.133 375.539C426.969 351.089 413.584 331.007 393.201 318.109C363.407 299.193 324.063 300.24 295.284 320.705C271.809 337.405 258.259 364.724 258.259 392.865C258.259 399.921 259.109 407.044 260.88 414.055C269.744 448.924 299.72 475.32 335.459 479.695C346.064 480.979 356.387 480.364 367.076 477.596C376.261 475.361 385.149 471.636 393.339 466.388C401.839 460.924 408.94 453.719 415.817 445.093C415.956 444.935 416.096 444.795 416.235 444.628C421.008 438.708 420.123 430.289 414.877 426.271C410.453 422.88 403.027 421.505 397.183 428.989C395.927 430.779 394.525 432.62 392.983 434.461C388.336 439.596 382.571 444.571 375.663 448.429C366.872 453.125 356.855 455.809 346.205 455.869C311.349 455.481 292.697 431.151 286.063 413.784C284.904 410.541 284.011 407.177 283.388 403.713C283.307 403.065 283.247 402.44 283.223 401.864C290.429 401.864 408.305 401.844 408.305 401.844C425.453 401.484 434.683 389.38 432.133 375.539Z"
        fill="white"
      />
    </>
  ),
});
