import { CombinedError } from '@urql/core';
import isString from 'lodash/isString';
import { NextPageContext } from 'next';
import { NextUrqlPageContext } from 'next-urql';
import Router from 'next/router';

import {
  AccessShareLinkDocument,
  AccessShareLinkMutation,
  AccessShareLinkMutationVariables,
  DoesShareLinkExistDocument,
  DoesShareLinkExistMutation,
  DoesShareLinkExistMutationVariables,
  MeDocument,
  MeQuery,
  Org,
  OrgCreateDocument,
  OrgCreateMutation,
  OrgCreateMutationVariables,
  OrgDocument,
  OrgQuery,
} from 'generated/graphql';
import {
  destroyImpersonationCookieIfInvalid,
  destroyOrgCookie,
  getOrgCookie,
  setLoginRedirect,
  setOrgCookie,
} from 'helpers/cookies';
import { isSSR } from 'helpers/environment';
import { isRunwayEmployee } from 'helpers/user';
import { LoggedInUser } from 'reduxStore/models/user';
import { setSelectedOrg } from 'reduxStore/reducers/selectedOrgSlice';
import { selectedOrgSelector } from 'selectors/selectedOrgSelector';
import { RunwayPageContext } from 'types/RunwayPageContext';

export const NextRedirect = (ctx: NextPageContext, location: string) => {
  if (ctx.res) {
    ctx.res.writeHead(302, {
      Location: location,
    });

    ctx.res.end();
    return;
  }

  Router.replace(location);
};

interface RedirectToLoginOpts {
  setRedirect?: boolean;
  shareNonce?: string;
}

export const RedirectToLogin = (
  ctx: NextPageContext,
  { setRedirect = true, shareNonce }: RedirectToLoginOpts = { setRedirect: true },
) => {
  if (setRedirect) {
    setLoginRedirect(ctx);
  }

  let redir = '/login';

  // If the user is visiting a share link but isn't authed, we should forward
  // the nonce to the login endpoint so we can grant them the permissions
  // needed.
  if (shareNonce != null) {
    redir += `?shareNonce=${shareNonce}`;
  }

  NextRedirect(ctx, redir);
};

export async function queryLoggedInUser(ctx: NextUrqlPageContext): Promise<{
  loggedInUser: MeQuery['me'] | undefined;
  error: CombinedError | undefined;
}> {
  const defaultOrg = getOrgCookie(ctx);
  destroyImpersonationCookieIfInvalid(ctx, defaultOrg);
  const { data, error } = await ctx.urqlClient
    .query<MeQuery>(MeDocument, { orgSlug: defaultOrg })
    .toPromise();

  return { loggedInUser: data?.me, error };
}

async function orgSlugFromID(
  ctx: NextUrqlPageContext,
  id: string,
): Promise<{
  slug: string | undefined;
  error: CombinedError | undefined;
}> {
  if (!isFinite(parseInt(id, 10))) {
    return { slug: undefined, error: undefined };
  }
  const { data, error } = await ctx.urqlClient.query<OrgQuery>(OrgDocument, { id }).toPromise();
  const slug = data?.org?.slug;
  return { slug, error };
}

export async function createOrg(
  ctx: NextUrqlPageContext,
  name: string,
  slug?: string,
  isDemoOrg?: boolean,
): Promise<{
  org: Pick<Org, 'id' | 'name' | 'slug'> | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await ctx.urqlClient
    .mutation<OrgCreateMutation, OrgCreateMutationVariables>(OrgCreateDocument, {
      orgName: name,
      slug,
      isDemoOrg,
    })
    .toPromise();
  const org =
    data != null && data.orgCreate != null
      ? {
          id: data.orgCreate.id,
          name: data.orgCreate.name,
          slug: data.orgCreate.slug,
        }
      : undefined;
  return { org, error };
}

export async function accessShareLink(
  ctx: NextUrqlPageContext,
  nonce: string,
): Promise<
  Partial<
    Pick<NonNullable<AccessShareLinkMutation['accessShareLink']>, 'orgSlug' | 'pageId' | 'layerIds'>
  > & {
    error?: CombinedError;
  }
> {
  const { data, error } = await ctx.urqlClient
    .mutation<AccessShareLinkMutation, AccessShareLinkMutationVariables>(AccessShareLinkDocument, {
      nonce,
    })
    .toPromise();

  return { ...data?.accessShareLink, error };
}

export async function doesShareLinkExist(
  ctx: NextUrqlPageContext,
  nonce: string,
): Promise<{
  doesShareLinkExist?: boolean | null;
  error: CombinedError | undefined;
}> {
  const { data, error } = await ctx.urqlClient
    .mutation<DoesShareLinkExistMutation, DoesShareLinkExistMutationVariables>(
      DoesShareLinkExistDocument,
      {
        nonce,
      },
    )
    .toPromise();
  return { doesShareLinkExist: data?.doesShareLinkExist, error };
}

export async function setOrgFromSlug(
  ctx: RunwayPageContext,
  loggedInUser: LoggedInUser,
): Promise<boolean> {
  const { query, reduxStore } = ctx;
  let { orgSlug } = query;

  if (!isString(orgSlug)) {
    NextRedirect(ctx, '/');
    return false;
  }

  const selectedOrg = selectedOrgSelector(reduxStore.getState());
  const lowerCaseSlug = orgSlug.toLowerCase();
  const selectedOrgSlug = selectedOrg?.slug;
  const userOrgs = loggedInUser?.orgs ?? [];

  // URL state change on client
  // => set cookie and hard reload
  if (!isSSR()) {
    if (selectedOrgSlug === orgSlug) {
      // it's a URL state change but the same org
      return true;
    }
    setOrgCookie(ctx, orgSlug);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    window.location.href = ctx.asPath || `/${orgSlug}`;
    return false;
  }

  // URL same as cookie, or cookie is absent
  // => set cookie (maybe redundant)
  // => set in store
  // => if not in store, then reset state
  const cookieValue = getOrgCookie(ctx);
  if (orgSlug === cookieValue || cookieValue == null) {
    setOrgCookie(ctx, orgSlug);
    // make slug match case insensitive
    const userOrgWithSlug = userOrgs.find((org) => org.slug.toLowerCase() === lowerCaseSlug);
    if (userOrgWithSlug) {
      reduxStore.dispatch(setSelectedOrg({ id: userOrgWithSlug.id, slug: orgSlug }));
      return true;
    }

    if (!isRunwayEmployee(loggedInUser)) {
      // URL same as cookie but org doesn't exist / no access
      destroyOrgCookie(ctx);
      NextRedirect(ctx, '/');
      return false;
    }
  }

  // Shortcut — if the org slug happens to be an orgID, fetch the corresponding slug
  // if any, and route to _that_.
  const response = await orgSlugFromID(ctx, orgSlug);
  if (response.error == null && response.slug != null && response.slug !== '') {
    orgSlug = response.slug;
  }

  // URL differs from cookies.
  // => update slug and 302 to new slug
  setOrgCookie(ctx, orgSlug);
  NextRedirect(ctx, `/${orgSlug}`);
  return false;
}
