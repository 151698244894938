import { FocusLock } from '@chakra-ui/focus-lock';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, Flex, IconButton, useBoolean } from '@chakra-ui/react';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import DriverSearchResult from 'components/DriverSearchResult/DriverSearchResult';
import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import FilterPills from 'components/FilterPills/FilterPills';
import SearchInput from 'components/SearchInput/SearchInput';
import DriverSelectMenuFooter from 'components/SelectMenu/DriverSelectMenuFooter';
import SelectMenu, { CustomOption, Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuFooter from 'components/SelectMenu/SelectMenuFooter';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import { stopEventPropagation } from 'helpers/browserEvent';
import { getSearchName } from 'helpers/drivers';
import { extractEmoji } from 'helpers/emoji';
import useAppSelector from 'hooks/useAppSelector';
import useFilteredDriverSearch from 'hooks/useFilteredDriverSearch';
import { BusinessObjectSpec } from 'reduxStore/models/businessObjectSpecs';
import { Attribute } from 'reduxStore/models/dimensions';
import { Driver } from 'reduxStore/models/drivers';
import { attributesBySubDriverIdSelector } from 'selectors/driversSelector';
import FilterIcon from 'vectors/Filter';
import PlusIcon from 'vectors/Plus';

interface Props {
  onBack?: () => void;
  onSelectItem: (item: Item) => void;
  placeholder?: string;
  onClose: () => void;
  excludeItemIds?: Array<Item['id']>;
  includeObjects?: boolean;
  multiselectOpts?: { onRemove: (item: Item) => void };
  customOptions?: CustomOption[];
}

export type Item = SelectItem &
  (
    | { type: 'driver'; driver: Driver; attributes: Attribute[] }
    | { type: 'objectSpec'; spec: BusinessObjectSpec }
  );

const SECTIONS: Section[] = [
  {
    id: 'drivers',
    name: 'Drivers',
    maxResults: 5,
    showMore: true,
  },
  {
    id: 'databases',
    name: 'Databases',
    maxResults: 5,
    showMore: true,
  },
];

const SEARCH_KEYS = ['searchableName', 'name', 'placeholder'];

const ItemSelectMenu: React.FC<Props> = ({
  customOptions,
  placeholder,
  onClose,
  onBack,
  onSelectItem,
  excludeItemIds,
  multiselectOpts,
  includeObjects = false,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const attributesBySubDriverId = useAppSelector(attributesBySubDriverIdSelector);
  const [showFilters, setShowFilters] = useBoolean(false);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const selectedItemIds = selectedItems.map((item) => item.id);
  const toExclude = excludeItemIds?.filter(
    (el) => selectedItems.length === 0 || !selectedItemIds.includes(el),
  );

  const { filters, setFilters, query, setQuery, drivers, objectSpecs } = useFilteredDriverSearch({
    excludeItemIds: toExclude,
  });

  const onRemove = useCallback(
    (item: Item) => {
      setSelectedItems((items) => items.filter((el) => el.id !== item.id));

      multiselectOpts?.onRemove(item);
    },
    [multiselectOpts],
  );

  const onSelect = useCallback(
    (item: Item) => {
      if (item.isChecked) {
        onRemove(item);
        return;
      }

      onSelectItem(item);

      if (multiselectOpts?.onRemove == null) {
        return;
      }

      setSelectedItems([...selectedItems, item]);
    },
    [multiselectOpts?.onRemove, onRemove, onSelectItem, selectedItems],
  );

  const items: Item[] = useMemo(
    () => [
      ...drivers.map((driver) => ({
        type: 'driver' as const,
        id: driver.id,
        name: driver.name,
        attributes: attributesBySubDriverId[driver.id],
        searchableName: getSearchName({
          name: driver.name,
          attributes: attributesBySubDriverId[driver.id],
        }),
        footer: <DriverSelectMenuFooter driverId={driver.id} />,
        sectionId: 'drivers',
        isChecked: selectedItems.find((el) => el.type === 'driver' && el.id === driver.id) != null,
        driver,
      })),
      ...(includeObjects
        ? objectSpecs.map((spec) => {
            const [emoji, name] = extractEmoji(spec.name);
            return {
              type: 'objectSpec' as const,
              id: spec.id,
              name,
              icon: <EmojiIcon size="sm" emoji={emoji} />,
              footer: (
                <SelectMenuFooter
                  icon={<PlusIcon />}
                  title="Create new"
                  subtitle={`Create new record in the ${name} database.`}
                />
              ),
              sectionId: 'databases',
              isChecked:
                selectedItems.find((el) => el.type === 'objectSpec' && el.id === spec.id) != null,
              spec,
            };
          })
        : []),
    ],
    [drivers, includeObjects, objectSpecs, attributesBySubDriverId, selectedItems],
  );

  return (
    <FocusLock initialFocusRef={inputRef}>
      <Box
        data-testid="item-select-menu"
        bg="surface"
        borderRadius="md"
        boxShadow="menu"
        width="28rem"
        onClick={stopEventPropagation}
        onMouseDown={stopEventPropagation}
        zIndex="popover"
        role="dialog"
        tabIndex={0}
      >
        <Flex flexDir="column" w="full">
          <Flex padding={2} columnGap={1} alignItems="center">
            {onBack != null && (
              <IconButton
                aria-label="Go back"
                variant="icon"
                color="gray.500"
                boxSize={6}
                flexShrink={0}
                onClick={onBack}
                _hover={{ bgColor: 'gray.200', color: 'gray.500' }}
                icon={<ArrowBackIcon />}
              />
            )}
            <SearchInput
              ref={inputRef}
              placeholder={placeholder ?? ''}
              query={query}
              setQuery={setQuery}
            />
            <IconButton
              aria-label="Toggle show filters"
              variant="icon"
              color={showFilters ? 'selection.500' : 'gray.400'}
              padding={1}
              flexShrink={0}
              onClick={setShowFilters.toggle}
              icon={<FilterIcon boxSize={5} />}
              borderRadius="md"
              _hover={{
                bgColor: showFilters ? 'selection.100' : 'gray.200',
                color: showFilters ? 'selection.600' : 'gray.500',
              }}
            />
            <Button
              size="sm"
              onClick={onClose}
              variant="accent"
              flexShrink={0}
              data-testid="done-button"
            >
              Done
            </Button>
          </Flex>
          {showFilters ? (
            <FilterPills
              onClose={() => inputRef.current?.focus()}
              filters={filters}
              updateFilter={(update) => setFilters({ ...filters, ...update })}
            />
          ) : (
            <Divider />
          )}
        </Flex>
        <SelectMenu
          items={items}
          query={query}
          searchKeys={SEARCH_KEYS}
          onSelect={onSelect}
          onClose={onClose}
          customOptions={customOptions}
          sections={SECTIONS}
        >
          {({ item, isFocused, idx }) => {
            switch (item.type) {
              case 'driver': {
                return (
                  <DriverSearchResult
                    driver={item.driver}
                    isFocused={isFocused}
                    idx={idx}
                    isChecked={item.isChecked}
                  />
                );
              }
              case 'objectSpec': {
                return (
                  <SelectMenuItem
                    icon={item.icon}
                    name={item.name}
                    isFocused={isFocused}
                    isChecked={item.isChecked}
                    idx={idx}
                  />
                );
              }
              default: {
                return null;
              }
            }
          }}
        </SelectMenu>
      </Box>
    </FocusLock>
  );
};

export default ItemSelectMenu;
