import { Flex } from '@chakra-ui/react';
import { noop } from 'lodash';
import React, { useContext } from 'react';

import DatabaseTableHeaderCell from 'components/BusinessObjectTable/DatabaseTableHeaderCell';
import DatabaseNameSettingsPopoverContent from 'components/BusinessObjectTable/SettingsPopoverContents/DatabaseNameSettingsPopoverContent';
import { FIELD_HEADER_GUTTER_PX } from 'components/BusinessObjectTable/TimeSeriesFieldHeader';
import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import { ColumnHeaderCellWithMenu } from 'components/InteractiveColumnHeaderCell/InteractiveColumnHeaderCell';
import {
  DatabaseGroupKey,
  OBJECT_ID_FIELD_NAME,
  OBJECT_NAME_FIELD_NAME,
} from 'config/businessObjects';
import { CELL_DATA_COLUMN_WIDTH_IN_PX, COLUMN_HEADER_CELL_HEIGHT_PX } from 'config/cells';
import { DatabaseTableContext } from 'config/databaseTableContext';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { toPxString } from 'helpers/styles';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useObjectCellRef from 'hooks/useObjectCellRef';
import { OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY } from 'reduxStore/models/blocks';
import { hasBusinessObjectNameRestrictionsSelector } from 'selectors/businessObjectNameRestrictionsSelector';
import { isNewDimensionalTableSelector } from 'selectors/collectionBlocksSelector';
import { visibleFieldSpecTimeSeriesSelector } from 'selectors/visibleFieldSpecTimeSeriesSelector';

const DatabaseNameHeaderCell: React.FC<{ groupKey: DatabaseGroupKey }> = ({ groupKey }) => {
  const { blockId } = useBlockContext();
  const { objectSpecId } = useContext(DatabaseTableContext);
  const objFieldTimeSeriedFieldSpecId = useAppSelector((state) =>
    visibleFieldSpecTimeSeriesSelector(state, blockId),
  );
  const hasTimeSeries = objFieldTimeSeriedFieldSpecId != null;
  const height = (hasTimeSeries ? 2 : 1) * COLUMN_HEADER_CELL_HEIGHT_PX;

  const cellRef = useObjectCellRef(null, NAME_COLUMN_TYPE, groupKey);
  const { canWritePermissions } = useAccessCapabilities();

  const hasRestrictions = useAppSelector((state) =>
    hasBusinessObjectNameRestrictionsSelector(state, objectSpecId),
  );

  // TODO: this check is only needed to avoid affecting existing non-dimensional tables
  // Once all models are migrated, we can remove this.
  // This flag effectively turns this cell into an ID header.
  const isDimensionalTable = useAppSelector((state) =>
    isNewDimensionalTableSelector(state, blockId),
  );

  const isReadOnly = !canWritePermissions || isDimensionalTable;

  return (
    <ColumnHeaderCellWithMenu
      // Just disable the menu entirely if permissions are disabled as it is the only option
      disabled={isReadOnly}
      popoverContent={<DatabaseNameSettingsPopoverContent specId={objectSpecId} />}
    >
      <CellContextProvider cellRef={cellRef}>
        <Flex cursor={!isReadOnly ? 'pointer' : 'default'}>
          <DatabaseTableHeaderCell
            groupKey={groupKey}
            height={toPxString(height)}
            columnKey={OBJECT_TABLE_BLOCK_NAME_COLUMN_KEY}
            fixedWidth={isDimensionalTable ? CELL_DATA_COLUMN_WIDTH_IN_PX : undefined}
            title={isDimensionalTable ? OBJECT_ID_FIELD_NAME : OBJECT_NAME_FIELD_NAME}
            topSpacingPx={hasTimeSeries ? FIELD_HEADER_GUTTER_PX : undefined}
            orientation="horizontal"
            // We wrap this cell with a popover with click action, and want the cell to look like its clickable
            // So we pass a empty click handler to the cell for styling purposes
            onClick={noop}
            hasRestrictions={hasRestrictions}
          />
        </Flex>
      </CellContextProvider>
    </ColumnHeaderCellWithMenu>
  );
};

export default DatabaseNameHeaderCell;
