import { createSelector } from '@reduxjs/toolkit';
import { keyBy, sortBy } from 'lodash';
import { createCachedSelector } from 're-reselect';

import {
  AccountingAccount,
  AccountingContact,
  AccountingTrackingCategory,
  TransactionField,
} from 'generated/graphql';
import { paramSelector } from 'selectors/constSelectors';
import { navLinkedAccountSelector } from 'selectors/dataSourceSelector';
import { mergeIntegrationsSelector } from 'selectors/integrationsSelector';
import { ParametricSelector, Selector } from 'types/redux';

export const allAccountingAccountsSelector: Selector<AccountingAccount[]> = createSelector(
  mergeIntegrationsSelector,
  (integrations) => integrations.accountingProperties.accounts ?? [],
);

const accountingAccountsForNavSourceSelector: Selector<AccountingAccount[]> = createSelector(
  mergeIntegrationsSelector,
  navLinkedAccountSelector,
  (integrations, navLinkedAccount) =>
    integrations.accountingProperties.accounts?.filter((a) =>
      navLinkedAccount == null
        ? a.linkedAccountId === ''
        : a.linkedAccountId === navLinkedAccount.id,
    ) ?? [],
);

export const defaultAccountingAccountSelector: Selector<AccountingAccount | undefined> =
  createSelector(accountingAccountsForNavSourceSelector, (accounts) => {
    if (accounts.length === 0) {
      return undefined;
    }
    return accounts.find((a) => a.name === 'Expense') ?? accounts[0];
  });

const accountingContactsForNavSourceSelector: Selector<AccountingContact[]> = createSelector(
  mergeIntegrationsSelector,
  navLinkedAccountSelector,
  (integrations, navLinkedAccount) =>
    integrations.accountingProperties.contacts?.filter(
      (c) => c.linkedAccountId === navLinkedAccount?.id,
    ) ?? [],
);

const accountingTrackingCategoriesSelector: Selector<AccountingTrackingCategory[]> = createSelector(
  mergeIntegrationsSelector,
  (integrations) => integrations.accountingProperties.trackingCategories ?? [],
);

export const accountingAccountByRemoteIdSelector: Selector<
  NullableRecord<string, AccountingAccount>
> = createSelector(accountingAccountsForNavSourceSelector, (accounts) =>
  keyBy(accounts, 'remoteId'),
);

export const accountingContactsByRemoteIdSelector: Selector<
  NullableRecord<string, AccountingContact>
> = createSelector(accountingContactsForNavSourceSelector, (contacts) =>
  keyBy(contacts, 'remoteId'),
);

export const accountingTrackingCategoriesByRemoteIdSelector: Selector<
  NullableRecord<string, AccountingTrackingCategory>
> = createSelector(accountingTrackingCategoriesSelector, (categories) =>
  keyBy(categories, 'remoteId'),
);

const transactionFieldSelector = paramSelector<TransactionField>();

export type FieldOption = { remoteId: string; name: string };

export const transactionFieldOptionsByFieldSelector: Selector<
  Partial<Record<TransactionField, FieldOption[] | undefined>>
> = createSelector(
  accountingAccountsForNavSourceSelector,
  accountingContactsForNavSourceSelector,
  accountingTrackingCategoriesSelector,
  (accounts, contacts, categories) => {
    return {
      [TransactionField.AccountId]: sortBy(accounts, 'name'),
      [TransactionField.CategoryId]: sortBy(categories, 'name'),
      [TransactionField.ContactId]: sortBy(contacts, 'name'),
    };
  },
);

export const transactionFieldOptionsSelector: ParametricSelector<
  TransactionField,
  Array<{ remoteId: string; name: string }> | undefined
> = createCachedSelector(
  transactionFieldOptionsByFieldSelector,
  transactionFieldSelector,
  (optionsMap, field) => {
    return optionsMap[field];
  },
)((_state, field) => field);
