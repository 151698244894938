import { Draft } from '@reduxjs/toolkit';
import remove from 'lodash/remove';

import {
  ExtObjectSpecCreateInput,
  ExtObjectSpecDeleteInput,
  ExtObjectSpecUpdateInput,
} from 'generated/graphql';
import { toExtSource } from 'helpers/integrations';
import { isNotNull } from 'helpers/typescript';
import { ExtObjectSpec } from 'reduxStore/models/extObjectSpecs';
import { DEFAULT_LAYER_ID, DefaultLayer } from 'reduxStore/models/layers';
import { mapExtObjectFieldSpec } from 'reduxStore/reducers/helpers/extObjects';

export function handleCreateExtObjectSpec(
  defaultLayer: Draft<DefaultLayer>,
  newExtObjectSpecInput: ExtObjectSpecCreateInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not create extObjectSpec on non-default layer');
  }
  const {
    extKey,
    source,
    model,
    startFieldExtKey,
    fields,
    integrationQueryId,
    propagateDataForward,
    overrideForecastData,
  } = newExtObjectSpecInput;

  const objSpec: ExtObjectSpec = {
    extKey,
    source: toExtSource(source),
    model,
    startFieldExtKey: startFieldExtKey ?? undefined,
    fields: fields
      .map((field) => {
        return mapExtObjectFieldSpec(field);
      })
      .filter(isNotNull),
    integrationQueryId: integrationQueryId ?? undefined,
    propagateDataForward,
    overrideForecastData,
  };

  defaultLayer.extObjectSpecs.allKeys.push(extKey);
  defaultLayer.extObjectSpecs.byKey[extKey] = objSpec;
}

export function handleDeleteExtObjectSpec(
  defaultLayer: Draft<DefaultLayer>,
  deleteExtObjectSpecInput: ExtObjectSpecDeleteInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not create extObjectSpec on non-default layer');
  }
  const { extKey } = deleteExtObjectSpecInput;
  const object = defaultLayer.extObjectSpecs.byKey[extKey];
  if (object == null) {
    return;
  }

  delete defaultLayer.extObjectSpecs.byKey[extKey];
  remove(defaultLayer.extObjectSpecs.allKeys, (k) => k === extKey);
}

export function handleUpdateExtObjectSpec(
  defaultLayer: Draft<DefaultLayer>,
  updateExtObjectSpecInput: ExtObjectSpecUpdateInput,
) {
  if (defaultLayer.id !== DEFAULT_LAYER_ID) {
    throw new Error('Can not update extObjectSpec on non-default layer');
  }
  const { extKey, propagateDataForward, overrideForecastData } = updateExtObjectSpecInput;
  const object = defaultLayer.extObjectSpecs.byKey[extKey];
  if (object == null) {
    return;
  }

  if (propagateDataForward != null) {
    object.propagateDataForward = propagateDataForward;
  }
  if (overrideForecastData != null) {
    object.overrideForecastData = overrideForecastData;
  }
}
