import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'HelpIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        d="M7.98 1C4.13 1 1 4.13 1 7.99C1 11.85 4.13 14.96 7.98 14.96C11.83 14.96 14.96 11.83 14.96 7.98C14.96 4.13 11.83 1 7.98 1ZM7.97 13.46C4.95 13.46 2.5 11.01 2.5 7.98C2.5 4.95 4.96 2.5 7.98 2.5C11 2.5 13.46 4.96 13.46 7.98C13.46 11 11 13.46 7.97 13.46Z"
        fill="currentColor"
      />
      <path
        d="M7.98 12.2101C8.42735 12.2101 8.79 11.8474 8.79 11.4001C8.79 10.9527 8.42735 10.5901 7.98 10.5901C7.53265 10.5901 7.17 10.9527 7.17 11.4001C7.17 11.8474 7.53265 12.2101 7.98 12.2101Z"
        fill="currentColor"
      />
      <path
        d="M8.1 3.8501C6.65 3.8501 5.47 5.0301 5.47 6.4801C5.47 6.8901 5.81 7.2301 6.22 7.2301C6.63 7.2301 6.97 6.8901 6.97 6.4801C6.97 5.8601 7.48 5.3501 8.1 5.3501C8.72 5.3501 9.23 5.8601 9.23 6.4801C9.23 7.1001 8.63 7.6101 7.98 7.6101C7.57 7.6101 7.23 7.9501 7.23 8.3601V9.2401C7.23 9.6501 7.57 9.9901 7.98 9.9901C8.39 9.9901 8.73 9.6501 8.73 9.2401V9.0101C9.87 8.6901 10.73 7.6601 10.73 6.4801C10.73 5.0301 9.55 3.8501 8.1 3.8501Z"
        fill="currentColor"
      />
    </>
  ),
});
