import { Flex, Text } from '@chakra-ui/react';

interface Props {
  label: string;
  helperText?: string;
  'data-testid'?: string;
}

const CustomCreateOption: React.FC<Props> = ({ label, helperText, 'data-testid': dataTestId }) => {
  return (
    <Flex alignItems="center" columnGap={1} width="full" data-testid={dataTestId}>
      <Text flexShrink={0} noOfLines={1}>
        {label}
      </Text>
      {helperText != null && (
        <Text flexShrink={1} whiteSpace="nowrap" textOverflow="ellipsis" fontWeight="bold">
          &quot;{helperText}&quot;
        </Text>
      )}
    </Flex>
  );
};

export default CustomCreateOption;
