import { augmentValueWithLiveEditImpact } from 'helpers/events';
import { uuidv4 } from 'helpers/uuidv4';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverId } from 'reduxStore/models/drivers';
import { EventId } from 'reduxStore/models/events';
import { Value } from 'reduxStore/models/value';
import { setResponse } from 'reduxStore/reducers/calculationsSlice';
import { LiveEditImpact } from 'reduxStore/reducers/liveEditSlice';
import { setSelectedEventsAndGroups } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import { entityMonthKeyValueForLayerSelector } from 'selectors/calculationsSelector';
import { shouldDoSynchronousCalculationsSelector } from 'selectors/inspectorSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { parentRowRefForEventSelector } from 'selectors/planTimelineSelector';
import { isCalculationValue } from 'types/dataset';
import { MonthKey } from 'types/datetime';
import { RunCalculationsResponseMessage } from 'workers/formulaCalculator/types';

export const setCalculationResponseInAdvanceForLiveEdit =
  ({
    driverId,
    impact,
    monthKey,
  }: {
    driverId: DriverId;
    impact: LiveEditImpact;
    monthKey: MonthKey;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const shouldDoSynchronousCalculations = shouldDoSynchronousCalculationsSelector(state);
    if (shouldDoSynchronousCalculations) {
      return;
    }

    const driverVal = entityMonthKeyValueForLayerSelector(state, {
      id: driverId,
      monthKey,
    });
    if (!isCalculationValue(driverVal)) {
      return;
    }

    const newValue: Value = augmentValueWithLiveEditImpact(driverVal, impact);
    const currentLayerId = currentLayerIdSelector(state);
    const response: RunCalculationsResponseMessage = {
      type: 'runCalculations',
      requestId: uuidv4(),
      data: [
        {
          id: driverId,
          monthKey,
          layerId: currentLayerId,
          value: newValue,
        },
      ],
    };

    dispatch(setResponse({ batch: [response], isBackendCalculation: true }));
  };

export const selectPlanTimelineEntityRowForEvent = ({
  eventId,
  blockId,
}: {
  eventId: EventId;
  blockId: BlockId;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const ref = parentRowRefForEventSelector(state, { blockId, eventId });

    if (ref == null) {
      return;
    }

    dispatch(
      setSelectedEventsAndGroups({
        blockId,
        active: ref,
        refs: [ref],
      }),
    );
  };
};
