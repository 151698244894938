import { Box } from '@chakra-ui/react';
import classNames from 'classnames';
import React from 'react';

import { BadgeTheme, ThemeColors, THEMES_TO_COLORS } from 'config/badge';
import { formatLabel } from 'helpers/formatting';

import styles from './ObjectAttributeBadge.module.scss';

const ObjectAttributeBadge = ({
  label,
  badgeTheme = 'gray',
}: {
  label: string;
  badgeTheme?: BadgeTheme | ThemeColors;
}) => {
  const themeProps = typeof badgeTheme === 'string' ? THEMES_TO_COLORS[badgeTheme] : badgeTheme;

  const shownLabel = formatLabel(label);
  return (
    <Box
      data-sentry-mask
      as="p"
      className={classNames(styles.objectAttributeBadge)}
      {...themeProps}
    >
      {shownLabel}
    </Box>
  );
};

export default React.memo(ObjectAttributeBadge);
