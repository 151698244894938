import React from 'react';

import AddDriversPopover from 'components/AddDriversPopover/AddDriversPopover';
import { useAddDriversPopoverContext } from 'config/driverGridContext';

interface Props {
  isAdding: boolean;
  onClose: () => void;
}

const AddDriversToBlockPopover: React.FC<Props> = ({ onClose, isAdding }) => {
  const [{ reference, groupId, offset, belowDriverId, appendToParent, placement, canFlip }] =
    useAddDriversPopoverContext();

  return (
    <AddDriversPopover
      isAdding={isAdding}
      onClose={onClose}
      reference={reference}
      groupId={groupId}
      belowDriverId={belowDriverId}
      appendToParent={appendToParent}
      placement={placement}
      offset={offset}
      canFlip={canFlip}
    />
  );
};

export default AddDriversToBlockPopover;
