import { useToken } from '@chakra-ui/react';
import { Text as VisxText } from '@visx/text';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import ChartLinePath from 'components/DriverChart/ChartLinePath';
import theme from 'config/theme';
import { ThresholdDirection } from 'generated/graphql';
import { getMonthKey } from 'helpers/dates';
import { formatDriverValue } from 'helpers/formatting';
import useAppSelector from 'hooks/useAppSelector';
import useChartContext from 'hooks/useChartContext';
import { DriverId } from 'reduxStore/models/drivers';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';

const { colors, fonts, fontWeights, fontSizes } = theme;

interface Props {
  value: number;
  thresholdDirection: ThresholdDirection;
  driverId: DriverId;
}

const MilestoneChartLine: React.FC<Props> = ({ value, thresholdDirection, driverId }) => {
  const { timeScale, valueScale, width, height } = useChartContext();
  const [start, end] = timeScale.domain();
  const [startDateTime, endDateTime] = [start, end].map((date) => DateTime.fromJSDate(date));
  const displayConfiguration = useAppSelector((state) =>
    driverDisplayConfigurationSelector(state, driverId),
  );
  const label = formatDriverValue(value, displayConfiguration, { abbreviate: true });

  const valueX = valueScale(value);

  const shadedRegion = useMemo(
    () => ({
      x: 0,
      y: thresholdDirection === ThresholdDirection.AboveOrEqual ? 0 : valueX,
      width,
      height: thresholdDirection === ThresholdDirection.AboveOrEqual ? valueX : height - valueX,
    }),
    [width, height, valueX, thresholdDirection],
  );

  const verticalOffsetPx = thresholdDirection === ThresholdDirection.AboveOrEqual ? -2 : 12;
  const [resolvedStrokeColor] = useToken('colors', ['purple.300']);

  return (
    <>
      <rect pointerEvents="none" fill={colors.milestone} opacity="0.1" {...shadedRegion} />
      <VisxText
        x={2}
        y={valueX + verticalOffsetPx}
        textAnchor="start"
        verticalAnchor="end"
        fontSize={fontSizes.xxs}
        fontFamily={fonts.body}
        fontWeight={fontWeights.medium}
        fill={colors.milestone}
        paintOrder="stroke"
        strokeWidth={3}
        stroke="white"
        style={{ userSelect: 'none', background: 'white' }}
      >
        {label}
      </VisxText>
      <ChartLinePath
        data={[
          { x: start, y: value, monthKey: getMonthKey(startDateTime) },
          { x: end, y: value, monthKey: getMonthKey(endDateTime) },
        ]}
        strokeColor={resolvedStrokeColor}
        strokeWidth={1}
        strokeDasharray="2"
      />
    </>
  );
};

export default MilestoneChartLine;
