import { DateTime } from 'luxon';

import { DriverChartDatum } from 'config/driverChart';
import { ThresholdDirection } from 'generated/graphql';
import { extractMonthKey, shortMonthFormat } from 'helpers/dates';
import { formatDriverValue } from 'helpers/formatting';
import { Milestone } from 'reduxStore/models/milestones';
import { DisplayConfiguration } from 'reduxStore/models/value';

export const meetsGoal = (milestone: Milestone, calculatedValue: number): boolean => {
  if (milestone.thresholdDirection === ThresholdDirection.AboveOrEqual) {
    return calculatedValue >= milestone.value;
  }
  return calculatedValue <= milestone.value;
};

export const PREFIX_TO_THRESHOLD_DIRECTION = {
  above: ThresholdDirection.AboveOrEqual,
  below: ThresholdDirection.BelowOrEqual,
} as const;

export const THRESHOLD_DIRECTION_TO_MET_PREFIX: Record<ThresholdDirection, 'above' | 'below'> = {
  [ThresholdDirection.AboveOrEqual]: 'above',
  [ThresholdDirection.BelowOrEqual]: 'below',
} as const;

const THRESHOLD_DIRECTION_TO_MISSED_PREFIX: Record<ThresholdDirection, string> = {
  [ThresholdDirection.AboveOrEqual]: 'below',
  [ThresholdDirection.BelowOrEqual]: 'above',
} as const;

const distanceToMilestoneDateText = (
  milestoneThresholdDirection: ThresholdDirection,
  milestoneValue: number,
  forecastValue: number,
  displayConfiguration: DisplayConfiguration,
): { label: string; success: boolean } => {
  // Fetch the bordering date between actuals and forecast
  let distanceToMilestone = milestoneValue - forecastValue;
  if (milestoneThresholdDirection === ThresholdDirection.BelowOrEqual) {
    distanceToMilestone *= -1;
  }
  const label =
    distanceToMilestone === 0
      ? 'At goal'
      : `${formatDriverValue(Math.abs(distanceToMilestone), displayConfiguration)} ${
          distanceToMilestone > 0
            ? THRESHOLD_DIRECTION_TO_MISSED_PREFIX[milestoneThresholdDirection]
            : THRESHOLD_DIRECTION_TO_MET_PREFIX[milestoneThresholdDirection]
        } goal`;

  return { label, success: distanceToMilestone <= 0 };
};

export const progressTowardsMilestoneLabel = (
  milestone: Milestone,
  driverTimeSeries: DriverChartDatum[],
  displayConfiguration: DisplayConfiguration,
): { label: string; labelPosition: Date; success: boolean } | null => {
  if (driverTimeSeries.length === 0) {
    return null;
  }
  const metGoalWord = THRESHOLD_DIRECTION_TO_MET_PREFIX[milestone.thresholdDirection];
  const missedGoalWord = THRESHOLD_DIRECTION_TO_MISSED_PREFIX[milestone.thresholdDirection];
  if (milestone.date == null) {
    let success = false;
    let label = '';

    // N.B: If we don't floor, this will break when the time series has a
    // length of 1 as Math.round(0.5) === 1 which would be out of bounds.
    let labelPosition = driverTimeSeries[Math.floor(driverTimeSeries.length / 2)].x;

    const firstValue = driverTimeSeries[0];
    if (meetsGoal(milestone, firstValue.y)) {
      const brokeConstraint = driverTimeSeries.find(({ y }) => !meetsGoal(milestone, y));
      if (brokeConstraint != null) {
        label = `⚠ ${missedGoalWord} goal by ${shortMonthFormat(
          DateTime.fromJSDate(brokeConstraint.x),
        )}`;
        labelPosition = brokeConstraint.x;
      } else {
        label = `⚠ never ${missedGoalWord} goal`;
        success = true;
      }
    } else {
      const metGoal = driverTimeSeries.find(({ y }) => meetsGoal(milestone, y));
      if (metGoal != null) {
        label = `${metGoalWord} goal by ${shortMonthFormat(DateTime.fromJSDate(metGoal.x))} `;
        labelPosition = metGoal.x;
        success = true;
      } else {
        label = `⚠ never ${metGoalWord} goal`;
      }
    }

    return { label, labelPosition, success };
  }

  const milestoneMonthKey = extractMonthKey(milestone.date);
  const eventPoint = driverTimeSeries.find((point) => point.monthKey === milestoneMonthKey);
  if (eventPoint == null) {
    return null;
  }
  const { label, success } = distanceToMilestoneDateText(
    milestone.thresholdDirection,
    milestone.value,
    eventPoint.y,
    displayConfiguration,
  );

  return { label: `${label}`, labelPosition: eventPoint.x, success };
};
