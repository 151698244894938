import { Box, StyleProps, theme as globalTheme, useMergeRefs, useTheme } from '@chakra-ui/react';
import { getCSSVar } from '@chakra-ui/utils';
import classNames from 'classnames';
import React from 'react';

import CellBorder from 'components/CellBorder/CellBorder';
import { CELL_HEIGHT_IN_PX_STR } from 'config/cells';
import { useBGColorForCell } from 'hooks/useBgColorForCell';
import { useCellRef } from 'hooks/useCellRefContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import useScrollToCell from 'hooks/useScrollToCell';

import styles from './TableCell.module.scss';

interface Props {
  children?: React.ReactNode;
  isLastSticky?: boolean;
  isSticky?: boolean;
  isExpandable?: boolean;
  left?: StyleProps['left'];
  width?: StyleProps['width'];
  isGroupHeading?: boolean;
  testId?: string;
  onContextMenu?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
  onDoubleClick?: React.MouseEventHandler;
  onMouseDown?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  disablePointerEventsOnContents?: boolean;
  justifyContent?: StyleProps['justifyContent'] & 'center';
}

const TableCell = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      isLastSticky = false,
      isSticky = false,
      isExpandable = false,
      left,
      width,
      isGroupHeading = false,
      testId,
      onContextMenu,
      onClick,
      onDoubleClick,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      disablePointerEventsOnContents,
      justifyContent,
    },
    passedRef,
  ) => {
    const { isActive, isSelected, isBackgroundSelected } = useCellSelectionStateContext();
    const { cellId } = useCellRef();
    const bgColor = useBGColorForCell();
    const theme = useTheme();
    const bgColorVar = getCSSVar(theme, 'colors', bgColor) as string;

    const isSelectable = onClick != null || onMouseDown != null;

    const scrollRef = useScrollToCell(isSelected && !isBackgroundSelected);
    const ref = useMergeRefs(scrollRef, passedRef);

    const dataTestId = `table-cell${testId != null ? `-${testId}` : ''}`;
    return (
      <div
        ref={ref}
        data-sentry-mask
        data-cellid={cellId}
        onClick={onClick}
        onContextMenu={onContextMenu}
        onDoubleClick={onDoubleClick}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid={dataTestId}
        style={{
          width: `${width}px`,
          backgroundColor: bgColorVar,
          height: CELL_HEIGHT_IN_PX_STR,
          left: isSticky ? `${left}px` : undefined,
          // avoid borders being clipped when active
          zIndex: isActive
            ? globalTheme.zIndices.docked + 1
            : isSticky
              ? globalTheme.zIndices.docked
              : undefined,
        }}
        className={classNames(styles.tableCellBox, {
          [styles.isExpandable]: isExpandable,
          [styles.isSelectable]: isSelectable,
          [styles.isSticky]: isSticky,
          [styles.isGroupHeading]: isGroupHeading,
        })}
        tabIndex={0}
      >
        <div
          className={classNames(styles.tableCell, {
            [styles.noPointerEvents]: disablePointerEventsOnContents,
          })}
          style={justifyContent != null ? { justifyContent } : undefined}
        >
          {children}
        </div>
        <CellBorder />
        {isLastSticky && !isActive && (
          <Box
            h="full"
            w="px"
            zIndex="sticky"
            position="absolute"
            right={0}
            bgColor="gray.400"
            top={0}
          />
        )}
      </div>
    );
  },
);

TableCell.displayName = 'TableCell';

export default React.memo(TableCell);
