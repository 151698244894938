import debounce from 'lodash/debounce';
import React, {
  Ref,
  cloneElement,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import ListItemMeasurer, { SizeInfo } from 'components/VirtualizedList/ListItemMeasurer';
import ScrollToItem from 'components/VirtualizedList/ScrollToItem';
import {
  findInitialOffsetLeft,
  findInitialOffsetTop,
} from 'components/VirtualizedList/findInitialOffset';
import { ListItemContextProvider } from 'components/VirtualizedList/listItemContext';
import {
  VirtualizedListBaseProps,
  VirtualizedListBaseRefProps,
  VirtualizedListItemProps,
} from 'components/VirtualizedList/types';
import { useGetSlidingWindowInternal } from 'components/VirtualizedList/useGetSlidingWindowInternal';
import { useListItemSizeInternal } from 'components/VirtualizedList/useListItemSizeInternal';
import { useListScrollToInternal } from 'components/VirtualizedList/useListScrollToInternal';
import { isDevelopment } from 'helpers/environment';
import { findNearestHorizontalScrollParent, findNearestVerticalScrollParent } from 'helpers/scroll';
import { toPxString } from 'helpers/styles';
import { useIsScrolling } from 'hooks/useIsScrolling';

interface BaseVirtualizedListState {
  isScrolling: boolean; //whether the list is currently scrolling
  sizeWasSet: boolean; //whether the size of the list was set
}

type ScrollDirection = 'up' | 'down' | 'left' | 'right' | 'none';
const defaultItemKey = (index: number) => index;

const DEFAULT_SCROLL_BUFFER_MS = 500;
const DEBOUNCE_MS = 1000 / 60;

const DEFAULT_OVERSCAN_COUNT = 100;
const DEFAULT_ESTIMATED_ITEM_SIZE = 1;
const VirtualizedListBase = forwardRef(
  (
    {
      scrollTargetRef,
      children,
      getItemSize,
      estimatedItemSize = DEFAULT_ESTIMATED_ITEM_SIZE,
      gapPx = 0,
      overscanCount = DEFAULT_OVERSCAN_COUNT,
      onItemsRendered,
      listType,
      itemKey,
      onScrollStart,
      onScrollEnd,
      onBottomReached,
      onBottomReachedNumItemsOffset = 0,
      scrollEndDelayMs = DEFAULT_SCROLL_BUFFER_MS,
    }: VirtualizedListBaseProps,
    ref: Ref<VirtualizedListBaseRefProps>,
  ) => {
    const isFixedSized = getItemSize != null;
    const [state, setState] = useState<BaseVirtualizedListState>({
      isScrolling: false,
      sizeWasSet: false,
    });
    const virtualizedListRef = useRef<HTMLDivElement>(null);
    const spaceFillerRef = useRef<HTMLDivElement>(null);
    const listSizeRef = useRef<number>(0);
    const numListItems = React.Children.count(children);
    const isScrollingDisposer = useRef<(() => void) | null>(null);
    const scrollerTargetRef = useRef<HTMLElement | null>(null);
    const prevScrollOffsetRef = useRef<number>(0);
    const scrollDirectionRef = useRef<ScrollDirection>('none');
    const hasBottomReachedBeenFiredRef = useRef<boolean>(false);

    const {
      totalSize,
      getItemSizeInternal,
      itemPositionsInList,
      setItemSizeForIndex,
      isItemSizeForIndexAvailable,
    } = useListItemSizeInternal({
      listType,
      getItemSize,
      numListItems,
      estimatedItemSize,
      gapPx,
    });

    const {
      slidingWindow,
      spaceFillerSize,
      updateScrollOffset,
      setRefs,
      setListOffsetFromBoundaryForSlidingWindowHook,
    } = useGetSlidingWindowInternal({
      listType,
      getItemSize: getItemSizeInternal,
      numListItems,
      overscanCount,
    });

    const {
      scrollToItem,
      onScrollItemInRange,
      scrollToInfo,
      setListOffsetFromBoundaryForScrollToHook,
    } = useListScrollToInternal({
      numListItems,
      scrollerTargetRef,
      itemPositionsInList,
      listType,
      isFixedSized,
    });

    const onScrollEndInternal = useCallback(() => {
      setState((prevState) => ({ ...prevState, isScrolling: false }));
      onScrollEnd?.();
    }, [onScrollEnd]);

    const { setIsScrolling } = useIsScrolling({
      onScrollEnd: onScrollEndInternal,
      scrollBufferMs: scrollEndDelayMs,
    });

    const setScrollDirection = useCallback(
      (scrollOffset: number) => {
        const oldScrollOffset = prevScrollOffsetRef.current;
        if (oldScrollOffset === scrollOffset) {
          scrollDirectionRef.current = 'none';
          return;
        }
        if (listType === 'vertical') {
          if (oldScrollOffset < scrollOffset) {
            scrollDirectionRef.current = 'down';
          } else {
            scrollDirectionRef.current = 'up';
          }
        } else if (oldScrollOffset < scrollOffset) {
          scrollDirectionRef.current = 'right';
        } else {
          scrollDirectionRef.current = 'left';
        }
        prevScrollOffsetRef.current = scrollOffset;
      },
      [listType],
    );

    const onScroll = useCallback(() => {
      const scroller = scrollerTargetRef?.current;
      if (scroller == null) {
        return;
      }
      let scrollOffset: number = 0;
      if (listType === 'vertical') {
        scrollOffset = scroller.scrollTop;
      } else {
        scrollOffset = scroller.scrollLeft;
      }
      const isScrollingDisposerFn = isScrollingDisposer.current;
      if (isScrollingDisposerFn != null) {
        isScrollingDisposerFn();
      }
      setScrollDirection(scrollOffset);
      isScrollingDisposer.current = setIsScrolling();
      if (!state.isScrolling) {
        onScrollStart?.();
      }
      setState((prevState) =>
        prevState.isScrolling ? prevState : { ...prevState, isScrolling: true },
      );
      updateScrollOffset(scrollOffset);
    }, [
      listType,
      onScrollStart,
      setIsScrolling,
      setScrollDirection,
      state.isScrolling,
      updateScrollOffset,
    ]);

    const getRenderPropsForItem = useCallback(
      (index: number): VirtualizedListItemProps & { style: React.CSSProperties } => {
        let style: React.CSSProperties = {};
        const key = itemKey != null ? itemKey(index) : defaultItemKey(index);
        if (slidingWindow == null) {
          return {
            key,
            scrolling: state.isScrolling,
            visible: false,
            style,
          };
        }
        // For fixed size lists, we set the size of the item to the size from the getItemSize prop callback.
        // We don't use getItemSizeInternal because that incorporates gapPx in size calculation.
        if (listType === 'vertical') {
          style = {
            height: isFixedSized ? toPxString(getItemSize(index)) : undefined,
            width: '100%',
          };
        } else {
          style = {
            width: isFixedSized ? toPxString(getItemSize(index)) : undefined,
            height: '100%',
          };
        }
        return {
          key,
          scrolling: state.isScrolling,
          visible:
            slidingWindow.firstVisibleAnchor.index <= index &&
            index <= slidingWindow.lastVisibleAnchor.index,
          style,
        };
      },
      [getItemSize, isFixedSized, itemKey, listType, slidingWindow, state.isScrolling],
    );

    const getItemsToRenderInDom = useCallback((): React.ReactElement[] => {
      const scroller = scrollerTargetRef?.current;
      if (scroller === null || slidingWindow == null) {
        return [];
      }
      const onListItemSizeMeasurement = (index: number) => (size: SizeInfo) => {
        if (!isFixedSized) {
          setItemSizeForIndex({ index, size });
        }
      };
      const itemsToRender: React.ReactElement[] = [];
      for (let i = slidingWindow.windowStart; i <= slidingWindow.windowEnd; i++) {
        if (i < numListItems) {
          const element = children[i];
          const propsForElement = getRenderPropsForItem(i);
          const { style, ...propsForElementWithoutStyle } = propsForElement;
          let node: React.ReactNode = element;
          if (React.isValidElement(element)) {
            node = cloneElement(element, propsForElementWithoutStyle);
          }
          const itemSizeAvailable = isItemSizeForIndexAvailable(i);
          itemsToRender.push(
            <ListItemContextProvider index={i} numItems={numListItems} key={propsForElement.key}>
              <ScrollToItem
                scrollInfo={scrollToInfo?.indexToScrollTo === i ? scrollToInfo : null}
                listType={listType}
                id={propsForElement.key}
                key={propsForElement.key}
                onScrollItemInRange={onScrollItemInRange}
                scroller={scrollerTargetRef.current}
                style={style}
              >
                {isFixedSized ? (
                  node
                ) : (
                  <ListItemMeasurer
                    isVisible={itemSizeAvailable}
                    key={propsForElement.key}
                    onSize={onListItemSizeMeasurement(i)}
                  >
                    {node}
                  </ListItemMeasurer>
                )}
              </ScrollToItem>
            </ListItemContextProvider>,
          );
        }
      }
      return itemsToRender;
    }, [
      children,
      getRenderPropsForItem,
      isFixedSized,
      isItemSizeForIndexAvailable,
      listType,
      numListItems,
      onScrollItemInRange,
      scrollToInfo,
      setItemSizeForIndex,
      slidingWindow,
    ]);

    /**
     * In the case of vertical list, when the virtualized is nested within a scrollable container,
     * we need to set the initial offset top to obtain the scrollDelta from scroller.scrollTop
     * For eg, a list, 1000px from the top of the scrollable container, only has a scroll delta for anchor calculations only if we have scrolled at least 1000px
     *
     */
    const setInitialOffsetFromScrollBoundaryAndScrollOffset = useCallback(() => {
      const scroller = scrollerTargetRef?.current;
      const virtualizedListRefElement = virtualizedListRef?.current;
      if (scroller != null && virtualizedListRefElement != null) {
        let scrollOffset: number = 0;
        let boundaryOffset: number = -1;
        if (listType === 'vertical') {
          boundaryOffset = findInitialOffsetTop(scroller, virtualizedListRefElement);
          scrollOffset = scroller.scrollTop;
        } else {
          scrollOffset = scroller.scrollLeft;
          boundaryOffset = findInitialOffsetLeft(scroller, virtualizedListRefElement);
        }
        setListOffsetFromBoundaryForScrollToHook(boundaryOffset);
        setListOffsetFromBoundaryForSlidingWindowHook(boundaryOffset);
        updateScrollOffset(scrollOffset);
      }
    }, [
      listType,
      setListOffsetFromBoundaryForScrollToHook,
      setListOffsetFromBoundaryForSlidingWindowHook,
      updateScrollOffset,
    ]);

    const scrollTo = useCallback(
      (position: number) => {
        const scroller = scrollerTargetRef.current;
        if (scroller == null) {
          return;
        }
        if (listType === 'vertical') {
          scroller.scrollTo({ top: position, behavior: 'smooth' });
        } else {
          scroller.scrollTo({ left: position, behavior: 'smooth' });
        }
      },
      [listType],
    );

    /**
     * Observe scroller for changes in size as initial offset top can change if elements
     * above the list change size or new nodes are added/removed above the list. This is very important as
     * we use the offset top for calculations and we need an accurate value at all times
     */
    const setObservers = useCallback(
      (scroller: HTMLElement, listElement: HTMLElement) => {
        const observeCallback = debounce(() => {
          setInitialOffsetFromScrollBoundaryAndScrollOffset();
        }, DEBOUNCE_MS);
        const resizeObserver: ResizeObserver = new ResizeObserver(observeCallback);
        const mutationObserver: MutationObserver = new MutationObserver(observeCallback);
        let elm: HTMLElement | null = listElement;
        //for a given element, observe size changes for siblings above it within a parent element
        //guaranteed to terminate because we will eventually reach the scroller element
        while (elm != null && !elm.isSameNode(scroller)) {
          if (elm.parentElement != null) {
            const childrenArr = Array.from(elm.parentElement.children);
            const idx = childrenArr.findIndex((child) => child.isSameNode(elm));
            for (let i = 0; i < idx; i++) {
              resizeObserver.observe(childrenArr[i]);
            }
          }
          elm = elm.parentElement;
        }
        mutationObserver.observe(scroller, {
          childList: true,
          subtree: true,
        });
        return () => {
          resizeObserver.disconnect();
          mutationObserver.disconnect();
        };
      },
      [setInitialOffsetFromScrollBoundaryAndScrollOffset],
    );

    const isItemVisible = useCallback(
      (itemIndex: number) =>
        slidingWindow != null &&
        slidingWindow.firstVisibleAnchor.index <= itemIndex &&
        itemIndex <= slidingWindow.lastVisibleAnchor.index,
      [slidingWindow],
    );

    const isItemInDOM = useCallback(
      (itemIndex: number) =>
        slidingWindow != null &&
        slidingWindow.windowStart <= itemIndex &&
        itemIndex <= slidingWindow.windowEnd,
      [slidingWindow],
    );

    useImperativeHandle(
      ref,
      () => ({
        scrollToItem,
        scrollTo,
        isItemVisible,
        isItemInDOM,
      }),
      [isItemInDOM, isItemVisible, scrollTo, scrollToItem],
    );

    // we want to initialize the list before the list is painted on the screen
    useLayoutEffect(() => {
      //initialize the list - find scroll target, set listeners, observers, etc
      const initializeList = () => {
        // only initialize the list if the size has been set. This is because it's tough to accurately find the scrollable parent if the list does not occupy space
        if (!state.sizeWasSet) {
          return () => {};
        }
        if (scrollTargetRef != null) {
          scrollerTargetRef.current = scrollTargetRef.current;
        } else {
          let nearestScrollParent: HTMLElement | null;
          if (listType === 'vertical') {
            nearestScrollParent = findNearestVerticalScrollParent(virtualizedListRef.current);
          } else {
            nearestScrollParent = findNearestHorizontalScrollParent(virtualizedListRef.current);
          }
          if (nearestScrollParent != null) {
            scrollerTargetRef.current = nearestScrollParent;
          } else if (virtualizedListRef.current != null) {
            scrollerTargetRef.current = virtualizedListRef.current?.parentElement;
            const isScrollable = ['scroll', 'auto'].includes(
              scrollerTargetRef.current?.style?.overflowY ?? '',
            );
            const hasHeight = scrollerTargetRef.current?.style?.height != null;
            if (isDevelopment && !(isScrollable || hasHeight)) {
              console.error(
                "Couldn't accurately find scroll parent. Virtualization might not work as expected if parent does not have overflowY property set to 'auto' or 'scroll and does not have a fixed size",
              );
            }
          }
        }
        const scroller = scrollerTargetRef.current;
        const listElement = virtualizedListRef.current;
        if (isDevelopment && scroller == null) {
          console.error('Cannot find scroller for virtualized list');
          return () => {};
        }
        if (scroller != null && listElement != null) {
          const setListeners = () => {
            scroller.addEventListener('scroll', onScroll, { passive: true });
            return () => {
              scroller?.removeEventListener('scroll', onScroll);
            };
          };

          setRefs({ listElement, scrollElement: scroller });
          setInitialOffsetFromScrollBoundaryAndScrollOffset();
          const scrollListenerDisposeFn = setListeners();
          const observerDisposeFn = setObservers(scroller, listElement);

          return () => {
            scrollListenerDisposeFn();
            observerDisposeFn();
          };
        }
        return () => {};
      };

      return initializeList();
    }, [
      onScroll,
      setInitialOffsetFromScrollBoundaryAndScrollOffset,
      setObservers,
      setRefs,
      state.sizeWasSet,
      scrollTargetRef,
      listType,
    ]);

    /**
     * useLayoutEffect lets us set the initital size of the list before the list is repaints the screen.
     * With useEffect, we would get a flash of the list with size=0 before the list
     * repaints with the correct size in the next frame.
     */
    useLayoutEffect(() => {
      const listRef = virtualizedListRef?.current;
      listSizeRef.current = totalSize;
      if (listRef != null) {
        if (listType === 'vertical') {
          listRef.style.height = toPxString(totalSize);
        } else {
          listRef.style.width = toPxString(totalSize);
        }
        setState((prevState) => {
          if (prevState.sizeWasSet) {
            return prevState;
          }
          return { ...prevState, sizeWasSet: true };
        });
      }
    }, [listType, totalSize]);

    //Same reason for using useLayoutEffect as above
    useLayoutEffect(() => {
      const spaceFiller = spaceFillerRef.current;
      if (spaceFiller != null) {
        if (listType === 'vertical') {
          spaceFiller.style.height = toPxString(spaceFillerSize);
        } else {
          spaceFiller.style.width = toPxString(spaceFillerSize);
        }
      }
    }, [listType, spaceFillerSize]);

    useEffect(() => {
      if (!state.isScrolling) {
        if (onItemsRendered != null && slidingWindow != null) {
          const { windowStart, windowEnd, firstVisibleAnchor, lastVisibleAnchor } = slidingWindow;
          onItemsRendered?.({
            overscanStartIndex: windowStart,
            overscanStopIndex: windowEnd,
            startIndex: firstVisibleAnchor.index,
            stopIndex: lastVisibleAnchor.index,
          });
        }
      }
    }, [onItemsRendered, slidingWindow, state.isScrolling]);

    useEffect(() => {
      if (slidingWindow == null) {
        return;
      }
      const { lastVisibleAnchor } = slidingWindow;
      const bottomThreshold = Math.max(0, numListItems - 1 - onBottomReachedNumItemsOffset);
      if (lastVisibleAnchor.index >= bottomThreshold) {
        if (!hasBottomReachedBeenFiredRef.current) {
          onBottomReached?.();
          hasBottomReachedBeenFiredRef.current = true;
        }
      } else {
        hasBottomReachedBeenFiredRef.current = false;
      }
    }, [numListItems, onBottomReached, onBottomReachedNumItemsOffset, slidingWindow]);

    return (
      <div
        ref={virtualizedListRef}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: listType === 'vertical' ? 'column' : 'row',
        }}
      >
        {/* this is a dummy div that fills the space above or to the left of adjacent to the sliding window to push the items into the visible range as you scroll */}
        <div ref={spaceFillerRef} />
        <div
          style={{
            rowGap: listType === 'vertical' ? `${gapPx}px` : undefined,
            columnGap: listType === 'horizontal' ? `${gapPx}px` : undefined,
            display: 'flex',
            flexDirection: listType === 'vertical' ? 'column' : 'row',
          }}
        >
          {getItemsToRenderInDom()}
        </div>
      </div>
    );
  },
);

export default memo(VirtualizedListBase);
