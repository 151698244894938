import { Line } from '@visx/shape';

import { CHART_SIZE_OPTION_TO_BAR_WIDTH } from 'components/DriverChart/BarChart';
import { ChartSize } from 'generated/graphql';
import { getDateTimeFromMonthKey } from 'helpers/dates';
import useAppSelector from 'hooks/useAppSelector';
import useChartContext from 'hooks/useChartContext';
import {
  chartDisplayMonthKeySelector,
  chartDisplayMonthKeyWithDefaultSelector,
} from 'selectors/driverChartSelectors';

interface Props {
  size: ChartSize;
}

const CurrentBar: React.FC<Props> = ({ size }) => {
  const { timeScale, height } = useChartContext();
  const cursorMonthKey = useAppSelector(chartDisplayMonthKeySelector);
  const monthKey = useAppSelector(chartDisplayMonthKeyWithDefaultSelector);
  if (cursorMonthKey == null) {
    return null;
  }

  const x = timeScale(
    getDateTimeFromMonthKey(monthKey).endOf('month').startOf('second').toJSDate(),
  );
  const barWidth = CHART_SIZE_OPTION_TO_BAR_WIDTH[size];

  return (
    <Line
      // need to find the middle of the bar since stroke width increases from the center
      from={{ x: x + barWidth / 2.0, y: 0 }}
      to={{ x: x + barWidth / 2.0, y: height }}
      strokeWidth={barWidth * 2}
      strokeDasharray={0}
      stroke="black"
      opacity="5%"
      pointerEvents="none"
    />
  );
};

export default CurrentBar;
