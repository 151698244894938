import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DriverDataCellRef, EventGroupCellRef } from 'config/cells';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { DriverId } from 'reduxStore/models/drivers';
import { EventGroupId } from 'reduxStore/models/events';
import { closeModal } from 'reduxStore/reducers/pageSlice';

export enum PaneType {
  Driver = 'driver',
  DimensionalDriver = 'dimensional driver',
  Object = 'object',
  Plan = 'plan',
}

export type DriverDetailPaneTab = 'inputs' | 'usedBy' | 'plans';
export type PlanDetailPaneTab = 'timeline' | 'drivers';

type DriverDetailPaneState = {
  type: PaneType.Driver | PaneType.DimensionalDriver;
  driverId: DriverId;
  // TODO: this should be a cell selection with the below generic types
  selectedCell?: DriverDataCellRef | EventGroupCellRef;
  tab: DriverDetailPaneTab;
  showIndirectInputs: boolean;
};

type ObjectPaneState = {
  type: PaneType.Object;
  objectSpecId: BusinessObjectSpecId;
  objectId: BusinessObjectId;
};

type PlanPaneState = {
  type: PaneType.Plan;
  eventGroupId: EventGroupId;
  tab: PlanDetailPaneTab;
};

export type DetailPaneState = DriverDetailPaneState | ObjectPaneState | PlanPaneState | null;

export const isDriverDetailPane = (state: DetailPaneState): state is DriverDetailPaneState =>
  state?.type === PaneType.Driver || state?.type === PaneType.DimensionalDriver;

export const isPlanDetailPane = (state: DetailPaneState): state is PlanPaneState =>
  state?.type === PaneType.Plan;

const detailPane = createSlice({
  name: 'detailPane',
  initialState: null as DetailPaneState,
  reducers: {
    setDetailPaneSelectedCell(state, action: PayloadAction<DriverDetailPaneState['selectedCell']>) {
      if (!isDriverDetailPane(state)) {
        return;
      }
      state.selectedCell = action.payload;
    },
    changeDriverDetailPaneTab(state, action: PayloadAction<DriverDetailPaneTab>) {
      if (!isDriverDetailPane(state)) {
        return;
      }
      state.tab = action.payload;
    },
    changePlanDetailPaneTab(state, action: PayloadAction<PlanDetailPaneTab>) {
      if (!isPlanDetailPane(state)) {
        return;
      }
      state.tab = action.payload;
    },
    setDriverDetailIndirectInputs(state, action: PayloadAction<boolean>) {
      if (!isDriverDetailPane(state)) {
        return;
      }
      state.showIndirectInputs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeModal, () => {
      return null;
    });
  },
});

export const {
  setDetailPaneSelectedCell,
  changeDriverDetailPaneTab,
  changePlanDetailPaneTab,
  setDriverDetailIndirectInputs,
} = detailPane.actions;

export default detailPane.reducer;
