import { Button, Flex, Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { createSelector } from '@reduxjs/toolkit';
import { deepEqual } from 'fast-equals';
import { useCallback, useEffect, useMemo, useState } from 'react';

import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import LayerName from 'components/LayerName/LayerName';
import DefaultScenarioIcon from 'components/ScenarioManagement/DefaultScenarioIcon';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { stopEventPropagation } from 'helpers/browserEvent';
import { extractEmoji } from 'helpers/emoji';
import { isNotNull } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useControlledPopover from 'hooks/useControlledPopover';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { layerMetadataSelector } from 'selectors/layerSelector';
import Arrowhead from 'vectors/Arrowhead';
import Trash from 'vectors/Trash';

const LAYERS_SECTION_ID = 'layers';
const ADDITIONAL_ACTIONS_SECTION_ID = 'additional_actions';
const SECTIONS = [{ id: LAYERS_SECTION_ID }, { id: ADDITIONAL_ACTIONS_SECTION_ID }];

const CompareToLayerDropdown = ({
  layerIds: passedLayerIds,
  selectedLayerId,
  onChange,
  onRemove,
}: {
  layerIds: LayerId[];
  selectedLayerId: LayerId;
  onChange: (layerId: LayerId) => void;
  onRemove?: () => void;
}) => {
  const [layerIds, setLayerIds] = useState(passedLayerIds);

  useEffect(() => {
    setLayerIds((currentLayerIds) => {
      if (deepEqual(passedLayerIds, currentLayerIds)) {
        return currentLayerIds;
      }
      return passedLayerIds;
    });
  }, [passedLayerIds]);

  const layer = useAppSelector((state) => layerMetadataSelector(state, selectedLayerId));
  const layersMetadata = useAppSelector((state) => multiLayerMetadataSelector(state, layerIds));
  const items = useMemo<SelectItem[]>(() => {
    return [
      ...layersMetadata.filter(isNotNull).map(({ id, name }) => {
        const [emoji, displayName] = extractEmoji(name);
        const isChecked = id === selectedLayerId;

        const item: SelectItem = {
          id,
          name: displayName,
          sectionId: LAYERS_SECTION_ID,
          isChecked,
          icon: isChecked ? undefined : (
            <EmojiIcon size="sm" emoji={emoji} emptyIcon={<DefaultScenarioIcon />} />
          ),
          checkedStyle: isChecked ? 'check' : 'none',
        };

        return item;
      }),
      ...(onRemove != null
        ? [
            {
              id: 'remove',
              name: 'Remove',
              sectionId: ADDITIONAL_ACTIONS_SECTION_ID,
              icon: <Trash />,
            },
          ]
        : []),
    ];
  }, [layersMetadata, onRemove, selectedLayerId]);

  const { isOpen, onOpen, onClose, triggerRef, contentRef } = useControlledPopover();

  const onSelectLayer = useCallback(
    (item: SelectItem) => {
      onChange(item.id);
      onClose();
    },
    [onChange, onClose],
  );

  if (layer == null) {
    return null;
  }

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      placement="bottom-start"
      closeOnBlur={false}
      returnFocusOnClose={false}
      onClose={onClose}
      autoFocus={false}
    >
      <PopoverTrigger>
        <Button height="1.75rem" px={2}>
          <Flex
            flexDirection="row"
            alignItems="center"
            alignContent="center"
            justifyContent="space-between"
            minWidth="0"
            flexGrow={1}
            ref={triggerRef}
            fontSize="xs"
          >
            <Flex alignItems="center" w="full" columnGap={1}>
              <LayerName layerId={selectedLayerId} enableIconBgColor />
              <Arrowhead boxSize={2} direction="down" color="currentColor" />
            </Flex>
          </Flex>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent onClick={stopEventPropagation} ref={contentRef} p={0}>
          <SelectMenu
            items={items}
            sections={SECTIONS}
            dismissIfEmpty
            onSelect={onSelectLayer}
            width="18rem"
            onClose={onClose}
          >
            {BaseSelectMenuItem}
          </SelectMenu>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const multiLayerMetadataSelector = createSelector(
  [(state: RootState, _: LayerId[]) => state, (state: RootState, layerIds: LayerId[]) => layerIds],
  (state, layerIds) => layerIds.map((layerId) => layerMetadataSelector(state, layerId)),
);

export default CompareToLayerDropdown;
