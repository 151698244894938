import { singular } from 'pluralize';

import { GroupInfo, NONE_GROUP_INFO } from 'config/businessObjects';
import { ValueType } from 'generated/graphql';
import { extractEmoji } from 'helpers/emoji';
import { newNameDeduper } from 'helpers/naming';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BASE_DEFAULT_VALUE_KEY } from 'reduxStore/models/businessObjects';
import { AttributeId } from 'reduxStore/models/dimensions';
import { NullableValue, toAttributeValue, toValueType } from 'reduxStore/models/value';
import { defaultValueForField } from 'reduxStore/reducers/helpers/businessObjectSpecs';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { businessObjectSpecSelector } from 'selectors/businessObjectSpecsSelector';
import { businessObjectNamesByIdForSpecSelector } from 'selectors/businessObjectsSelector';

function getDefaultValueAttributeScope(attributeId: AttributeId): string {
  return `attributeId=${attributeId}`;
}

interface DefaultFieldValuesParams {
  objectSpec: BusinessObjectSpec;
  groupInfo: GroupInfo;
  objectTableGroupByFieldSpecId: BusinessObjectFieldSpecId | undefined;
  objectTableFilterDefaultValueByFieldSpecId: Record<BusinessObjectFieldSpecId, NullableValue>;
}

export function getDefaultFieldValuesByFieldSpecId({
  objectSpec,
  groupInfo,
  objectTableGroupByFieldSpecId,
  objectTableFilterDefaultValueByFieldSpecId,
}: DefaultFieldValuesParams): Record<BusinessObjectFieldSpecId, NullableValue> {
  const getGroupAttribute = (fieldSpecId: BusinessObjectFieldSpecId) => {
    return groupInfo.groupingType === 'attributeObjectField' &&
      groupInfo.attributeId !== NONE_GROUP_INFO.attributeId &&
      objectTableGroupByFieldSpecId != null &&
      fieldSpecId === objectTableGroupByFieldSpecId
      ? toAttributeValue(groupInfo.attributeId)
      : undefined;
  };
  /**
   * Use the following rules in order to determine default values for object fields:
   * 1. If the object table is grouped by this field, use the group attribute ID
   * 2. Default Value persisted to the field's `defaultValues` field in the backend.
   * 3. Default value for this type of object field
   */
  return [
    ...objectSpec.fields,
    ...(objectSpec.collection?.dimensionalProperties ?? []),
    ...(objectSpec.collection?.driverProperties ?? []),
  ].reduce(
    (res, f) => {
      let defaultValueFromObjectSpec: NullableValue | undefined;
      let fallbackDefaultValue: NullableValue;
      if ('dimension' in f) {
        fallbackDefaultValue = { type: ValueType.Attribute, value: undefined };
      } else if ('driver' in f) {
        fallbackDefaultValue = { type: ValueType.Number, value: undefined };
      } else {
        const getValueForKey = (key: string): NullableValue =>
          // TODO: check if type is same
          toValueType(f.defaultValues[key]?.actuals?.formula ?? '', f.type);

        defaultValueFromObjectSpec = getDefaultValueFromObjectSpec({
          groupInfo,
          defaultValues: f.defaultValues,
          getValueForKey,
        });

        fallbackDefaultValue = defaultValueForField(f);
      }
      // If you're in a group for a particular dimension attribute (e.g. Location
      // = San Francisco), then the default value for the associated field should
      // be that value even if the user has a default otherwise set on this
      // dimension.
      const groupByFieldDefaultValue = getGroupAttribute(f.id);

      // If you're in a filtered view, then the default value should be a value that keeps the item
      // in the filtered view.
      const defaultValueFromFilter = objectTableFilterDefaultValueByFieldSpecId[f.id];

      const value =
        defaultValueFromFilter ??
        groupByFieldDefaultValue ??
        defaultValueFromObjectSpec ??
        fallbackDefaultValue;

      res[f.id] = value;

      return res;
    },
    {} as Record<BusinessObjectFieldSpecId, NullableValue>,
  );
}

export function getDefaultNameForObject({
  state,
  objectSpecId,
  groupInfo,
}: {
  state: RootState;
  objectSpecId: BusinessObjectSpecId;
  groupInfo?: GroupInfo;
}) {
  const res = getDefaultNamesForObjects({ state, objectSpecId, count: 1, groupInfo });
  return res[0];
}

export function getDefaultNamesForObjects({
  state,
  objectSpecId,
  groupInfo,
  count,
}: {
  state: RootState;
  objectSpecId: BusinessObjectSpecId;
  groupInfo?: GroupInfo;
  count: number;
}) {
  const objectSpec = businessObjectSpecSelector(state, objectSpecId);
  if (objectSpec == null) {
    throw new Error('expected object spec to exist');
  }
  const allObjectNamesById = businessObjectNamesByIdForSpecSelector(state, objectSpec.id);

  const nameDeduper = newNameDeduper(Object.values(allObjectNamesById));
  const getDefaultName = () => {
    /**
     * Use the following rules in order to determine default name for object:
     * 1. Default Value persisted to the spec's `defaultNameEntries` field in the backend.
     * 2. Fallback to `{object spec name} {N + 1}` where N = number of objects for this spec.
     *    For example, if there are 2 Employees, the default would be "Employee 3".
     */
    const { defaultNameEntries } = objectSpec;
    const getValueForKey = (key: string): string => {
      const namePrefix = defaultNameEntries[key];
      if (namePrefix == null) {
        return objectSpec.name;
      }
      return nameDeduper.dedupe(namePrefix);
    };

    const defaultValueFromObjectSpec = getDefaultValueFromObjectSpec({
      groupInfo: groupInfo ?? NONE_GROUP_INFO,
      defaultValues: defaultNameEntries,
      getValueForKey,
    });

    const [, objectSpecName] = extractEmoji(objectSpec.name);
    const fallbackDefaultValue = nameDeduper.dedupe(singular(objectSpecName));

    const name = defaultValueFromObjectSpec ?? fallbackDefaultValue;
    nameDeduper.add(name);
    return name;
  };

  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(getDefaultName());
  }
  return result;
}

function getDefaultValueFromObjectSpec<D, V>({
  groupInfo,
  defaultValues,
  getValueForKey,
}: {
  groupInfo: GroupInfo;
  defaultValues: Record<string, D>;
  getValueForKey: (key: string) => V;
}) {
  /**
   *  - If the table is grouped by a dimension and this group is for a non-null attribute,
   *    get the default value using the attribute as the scope key.
   *  - If the table is not grouped by a dimension, use the base default value.
   */
  const scopeKey =
    groupInfo.groupingType === 'attributeObjectField'
      ? getDefaultValueAttributeScope(groupInfo.attributeId)
      : BASE_DEFAULT_VALUE_KEY;

  const defaultValueFromObjectSpec =
    scopeKey in defaultValues ? getValueForKey(scopeKey) : undefined;

  return defaultValueFromObjectSpec;
}
