import { createSelector } from 'reselect';

import { PreviewDatabaseState } from 'reduxStore/reducers/previewDatabaseSlice';
import { Selector } from 'types/redux';

const selectPreviewDatabaseSlice: Selector<PreviewDatabaseState> = (state) => state.previewDatabase;

export const previewDatabaseSelector = createSelector(
  [selectPreviewDatabaseSlice],
  (preview) => preview.id,
);
