import { createCachedSelector } from 're-reselect';

import { INTEGRATION_SOURCE_FIELD_NAME } from 'config/integrations';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { BlockColumnOptions } from 'generated/graphql';
import { toSortedArray } from 'helpers/array';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpec,
} from 'reduxStore/models/businessObjectSpecs';
import {
  blockCanHidePropertiesSelector,
  blockConfigBusinessObjectSpecIdSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  blockConfigSelector,
} from 'selectors/blocksSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { blockIdSelector } from 'selectors/constSelectors';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { ParametricSelector } from 'types/redux';

// TODO: deprecate this selector in favor of configurableColumnsSelector
export const configurableObjectTableColumnsSelector: ParametricSelector<
  BlockId,
  BlockColumnOptions[]
> = createCachedSelector(blockConfigSelector, (blockConfig) => {
  return (blockConfig?.columns ?? []).filter((col) => col.key !== NAME_COLUMN_TYPE);
})({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const businessObjectSpecForBlockSelector: ParametricSelector<
  BlockId,
  BusinessObjectSpec | null
> = createCachedSelector(
  blockConfigBusinessObjectSpecIdSelector,
  (state) => businessObjectSpecsByIdForLayerSelector(state),
  (objectSpecId, objectSpecsById) => {
    if (objectSpecId == null) {
      return null;
    }
    return objectSpecsById[objectSpecId];
  },
)(blockIdSelector);

const EMPTY_FIELD_SPEC_ID: BusinessObjectFieldSpecId[] = [];
export const businessObjectFieldSpecIdsSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId[]
> = createCachedSelector(
  businessObjectSpecForBlockSelector,
  driversByIdForLayerSelector,
  (spec, driversById) => {
    if (spec == null) {
      return EMPTY_FIELD_SPEC_ID;
    }

    // Dimensional properties are not included in the fields array, so we need to add them
    const allObjectFields = [
      ...spec.fields,
      ...(spec?.collection?.dimensionalProperties ?? []),
      ...(spec?.collection?.driverProperties
        ?.map(({ id, driverId }) => {
          const driver = driversById[driverId];
          if (driver == null) {
            return null;
          }
          return { ...driver, id };
        })
        .filter(isNotNull) ?? []),
    ];

    // N.B. we don't show the integration source field in the object table, since it's an internal field that should not be user-facing
    // TODO: replace the field name check with an internal flag on the field spec
    return allObjectFields.filter((f) => f.name !== INTEGRATION_SOURCE_FIELD_NAME).map((f) => f.id);
  },
)(blockIdSelector);

const businessObjectFieldSpecIdsToShowSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId[]
> = createCachedSelector(
  blockCanHidePropertiesSelector,
  businessObjectFieldSpecIdsSelector,
  configurableObjectTableColumnsSelector,
  (canHide, fieldIds, columns) => {
    if (!canHide) {
      return fieldIds;
    }
    const hiddenColumnKeys = columns.filter((c) => c.visible === false).map((c) => c.key);
    return fieldIds.filter((id) => !hiddenColumnKeys.includes(id));
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

const orderedFieldSpecIdsForObjectTableSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId[]
> = createCachedSelector(
  businessObjectFieldSpecIdsSelector,
  configurableObjectTableColumnsSelector,
  (fieldSpecIds, columns) => {
    return toSortedArray(
      fieldSpecIds,
      columns.map((c) => c.key),
    );
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const orderedFieldSpecIdsToShowForObjectTableSelector: ParametricSelector<
  BlockId,
  BusinessObjectFieldSpecId[]
> = createCachedSelector(
  businessObjectFieldSpecIdsToShowSelector,
  orderedFieldSpecIdsForObjectTableSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  (fieldSpecIds, orderedColumns, fieldSpecAsTimeSeriesId) => {
    return orderedColumns.filter((c) => fieldSpecIds.includes(c) && c !== fieldSpecAsTimeSeriesId);
  },
)(blockIdSelector);
