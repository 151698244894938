import { useMemo } from 'react';

import CurrencySelectMenu from 'components/CurrencyMenu/CurrencySelectMenu';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { orgDefaultCurrencyISOSelector } from 'selectors/selectedOrgSelector';
import BankIcon from 'vectors/Bank';

import useAppSelector from './useAppSelector';

export const useCurrencySelectMenuItem = (
  selectedCurrency: string | null,
  onChangeCurrency: (currency: string) => void,
): SelectItem => {
  const defaultCurrency = useAppSelector(orgDefaultCurrencyISOSelector);
  const selectedWithDefault = selectedCurrency ?? defaultCurrency;
  const item: SelectItem = useMemo(
    () => ({
      id: 'currency',
      icon: <BankIcon />,
      submenu: () => (
        <CurrencySelectMenu onSelectCurrency={onChangeCurrency} selected={selectedWithDefault} />
      ),
      meta: selectedWithDefault,
      name: 'Currency',
      sectionId: 'format-options',
    }),
    [selectedWithDefault, onChangeCurrency],
  );

  return item;
};
