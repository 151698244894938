import { Driver } from 'reduxStore/models/drivers';

type DriverFilterFn = (driver: Driver) => boolean;

const filterBySubmodels = (
  submodelIds: string[],
  blockIdBySubmodelId: NullableRecord<string, string>,
): DriverFilterFn => {
  return (driver: Driver) => {
    if (submodelIds.length === 0 || driver.driverReferences == null) {
      return true;
    }
    const blockIds = new Set(submodelIds.map((submodelId) => blockIdBySubmodelId[submodelId]));
    return driver.driverReferences.some((ref) => blockIds.has(ref.blockId));
  };
};

export const applyDriverFilters = (
  driver: Driver,
  blockIdBySubmodelId: NullableRecord<string, string>,
  filters: Filters,
): boolean => {
  const { submodels } = filters;
  return [filterBySubmodels(submodels, blockIdBySubmodelId)].every((filterFn) => filterFn(driver));
};

export type Filters = {
  submodels: string[];
};
