import { ExtModel, ExtStaticSource } from 'generated/graphql';
import { ExtSource, toExtSource } from 'helpers/integrations';

export const CANONICAL_MODEL_ORDER = [
  ExtModel.IncomeStatement,
  ExtModel.BalanceSheet,
  ExtModel.CashFlowStatement,
];

// This must be kept in sync with the field name generated by the backend integration syncer.
export const INTEGRATION_SOURCE_FIELD_NAME = 'Integration Source';

const FIVETRAN_QBO = toExtSource('FIVETRAN_QUICKBOOKS');

export const FEATURED_INTEGRATION_DESCRIPTIONS: Partial<Record<ExtSource, string>> = {
  [FIVETRAN_QBO]:
    'Use QuickBooks data to automatically update actuals for your drivers and track cash.',
  [ExtStaticSource.MergeXero]:
    'Use Xero data to automatically update actuals for your drivers and track cash.',
  [ExtStaticSource.Rippling]:
    'Bring Rippling employees into Runway to calculate headcount expenses and plan for new hires.',
  [ExtStaticSource.MergeGusto]:
    'Bring Gusto employees into Runway to calculate headcount expenses and plan for new hires.',
  [ExtStaticSource.WorkatoGoogleSheets]:
    'Bring Google Sheets data into Runway for custom planning and reporting.',
  [ExtStaticSource.RunwayApi]:
    'Push data from any business application into Runway, or build your own!',
} as const;

export const FEATURED_INTEGRATIONS = Object.keys(FEATURED_INTEGRATION_DESCRIPTIONS);

export const MULTI_ACCOUNT_ENABLED_INTEGRATIONS: ExtSource[] = [
  ExtStaticSource.MergeXero,
  ExtStaticSource.MergeQuickbooksOnline,
  ExtStaticSource.MergeZenefits,
];

const RUNWAY_API_DOCS_URL =
  'https://help.runway.com/api-reference/tbzXnwKFBfTzmqnrhyttv2/pushing-data-to-runway/ghP8EPTSfWHeumUYBdLREt';

export const openRunwayAPIDocs = () => {
  window.open(RUNWAY_API_DOCS_URL, '_blank');
};
