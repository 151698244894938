import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { SelectMenuContext } from 'components/SelectMenu/SelectMenuContext';
import { SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS } from 'config/selectMenu';
import { preventEventDefault } from 'helpers/browserEvent';

interface Props {
  isFocused: boolean;
  showSubmenuOnHover?: boolean;
  idx: number;
}

export function useSelectMenuItem({ isFocused, idx, showSubmenuOnHover = true }: Props) {
  const itemRef = useRef<HTMLLIElement>(null);
  const isHoveredRef = useRef(false);
  const { setFocusIdx, onSelectIdx, setShowSubmenu, getIsScrolling, showSubmenu } =
    useContext(SelectMenuContext);
  const onMouseDown: React.MouseEventHandler = useCallback(
    (ev) => {
      const target = ev.target as HTMLElement;
      const triggerSelection =
        !('closest' in target) || target.closest(`.${SELECT_MENU_IGNORE_MOUSE_DOWN_CLASS}`) == null;
      // prevent a blur event from being triggered when selecting a list item
      preventEventDefault(ev);
      if (triggerSelection) {
        onSelectIdx(idx, ev);
      }
    },
    [idx, onSelectIdx],
  );

  const onOptionsMouseDown: React.MouseEventHandler = useCallback(
    (ev) => {
      setShowSubmenu((showing) => !showing);
      ev.stopPropagation();
    },
    [setShowSubmenu],
  );

  const onMouseEnter: React.MouseEventHandler = useCallback(() => {
    if (getIsScrolling()) {
      return;
    }
    setFocusIdx(idx);
    if (showSubmenuOnHover) {
      setShowSubmenu(true);
    }
    isHoveredRef.current = true;
  }, [idx, setFocusIdx, setShowSubmenu, getIsScrolling, showSubmenuOnHover]);

  useEffect(() => {
    // This is for keyboard navigation - if you've gone out of the visible window, scroll into view
    if (isFocused && !isHoveredRef.current) {
      itemRef.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [idx, isFocused]);

  const onMouseLeave: React.MouseEventHandler = useCallback(() => {
    isHoveredRef.current = false;
  }, []);

  return {
    itemRef,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onOptionsMouseDown,
    showSubmenu,
  };
}
