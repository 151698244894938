import { Middleware, PayloadAction, isAction } from '@reduxjs/toolkit';
import { deepEqual } from 'fast-equals';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

import { APPLY_MUTATION_ACTION_TYPE, UNDO_MUTATION_ACTION_TYPE } from 'config/reduxMiddleware';
import { devAlert } from 'helpers/devAlert';
import { isCypress } from 'helpers/environment';
import { MutationBatch } from 'reduxStore/models/mutations';
import {
  ApplyMutationArgs,
  DatasetSliceState,
  UndoLatestArgs,
} from 'reduxStore/reducers/datasetSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { featureFlagsSelector } from 'selectors/featureFlagsSelector';

const MIN_TIME_BETWEEN_MUTATIONS_MS = 200;

function registerError(message: string, ...mutationBatch: MutationBatch[]) {
  if (isCypress()) {
    console.error(message);
  } else if (mutationBatch.length > 0) {
    console.error(message, ...mutationBatch);
    message = message + '\n\nSee console for more details.';
  }
  devAlert(message);
}

/**
 * Extracts the user-visible data from the dataset slice; it omits fields that are only used for internal
 * bookkeeping. This helps us alert on no-op mutations, which we want to avoid because they pollute the
 * mutation history and make undo/redo feel broken.
 */
function getUserDataFromDataset(dataset: DatasetSliceState) {
  const { layers, currentLayerId, blocksPages, folders, blocks, lastActualsTime } = dataset;

  return {
    layers: mapValues(layers, (l) => omit(l, 'mutationBatches')),
    currentLayerId,
    folders,
    blocksPages,
    blocks,
    lastActualsTime,
  };
}

/**
 * Ensures that we are not sending excessive numbers of mutations from the client, and logs mutations to help debug.
 * This should only be run in development mode, since it will log to the console.
 */
export const mutationsMonitor: Middleware<unknown, RootState> =
  (store) => (next) => (action: unknown) => {
    if (
      !isAction(action) ||
      (action.type !== APPLY_MUTATION_ACTION_TYPE && action.type !== UNDO_MUTATION_ACTION_TYPE)
    ) {
      return next(action);
    }

    const state = store.getState();
    const { logMutations } = featureFlagsSelector(state);
    const result = next(action);
    const nextState = store.getState();

    if (action.type === APPLY_MUTATION_ACTION_TYPE) {
      const mutationAction = action as PayloadAction<ApplyMutationArgs>;
      const { isEphemeral } = mutationAction.payload;
      if (isEphemeral) {
        return result;
      }

      const mutationBatch = (action as PayloadAction<ApplyMutationArgs>).payload.mutationBatch;

      window.performance.mark(mutationBatch.id);
      if (
        mutationBatch?.mutation?.changeId == null &&
        deepEqual(getUserDataFromDataset(state.dataset), getUserDataFromDataset(nextState.dataset))
      ) {
        registerError(
          'Detected no-op mutation; mutations that do not produce a user-visible impact should be skipped.',
          mutationBatch,
        );
      }

      const { undoStack } = state.undoRedo;
      if (undoStack.length !== 0) {
        const lastMutationAction = undoStack[undoStack.length - 1];
        const lastMutation =
          lastMutationAction.mutationBatches[lastMutationAction.mutationBatches.length - 1];
        const timeSinceLastMutation = window.performance.measure(mutationBatch.id, lastMutation.id);

        if (timeSinceLastMutation.duration < MIN_TIME_BETWEEN_MUTATIONS_MS) {
          const shouldSkipDevCheck =
            lastMutation.mutation.changeId === mutationBatch.mutation.changeId &&
            mutationBatch.mutation.changeId != null;

          // Should allow back to back mutations only in the special case of pasting actuals and forecasts for drivers
          if (
            !shouldSkipDevCheck &&
            (lastMutation.layerId === mutationBatch.layerId ||
              (lastMutation.mutation.updateDrivers ?? []).length === 0 ||
              [
                ...(mutationBatch.mutation.newEvents ?? []),
                ...(mutationBatch.mutation.updateEvents ?? []),
              ].length === 0)
          ) {
            registerError(
              'Violated minimum time (200ms) between mutations; ' +
                'ensure that mutations are being batched & dispatched correctly before proceeding.',
              lastMutation,
              mutationBatch,
            );
          }
        }
      }
    }

    if (logMutations) {
      if (action.type === APPLY_MUTATION_ACTION_TYPE) {
        const mutationAction = action as PayloadAction<ApplyMutationArgs>;
        /* eslint-disable no-console */
        console.group('locally applied mutation');
        const { layerId, mutationBatch, isRemote, isRedo, isEphemeral } = mutationAction.payload;
        console.log({
          layerId,
          mutationId: mutationBatch.id,
          isRemote: isRemote ?? false,
          isRedo: isRedo ?? false,
          isEphemeral: isEphemeral ?? false,
        });
        console.log(JSON.stringify(mutationBatch.mutation, null, 2));
        console.groupEnd();
        /* eslint-enable no-console */
      } else if (action.type === UNDO_MUTATION_ACTION_TYPE) {
        const mutationAction = action as PayloadAction<UndoLatestArgs>;
        /* eslint-disable no-console */
        console.group('undo mutation');
        const { layerId, toUndoId, isRemote, updatedPreviousMutationId } = mutationAction.payload;
        console.log({
          layerId,
          toUndoId,
          isRemote: isRemote ?? false,
          updatedPreviousMutationId,
        });
        console.groupEnd();
        /* eslint-enable no-console */
      }
    }

    return result;
  };
