import { isObject } from 'lodash';
import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import isString from 'lodash/isString';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';

import { BuiltInDimensionType, ValueType } from 'generated/graphql';
import {
  getLuxonUnitFromText,
  parseLiteralDateString,
  weekendMaskStrInvalidReason,
} from 'helpers/dates';
import {
  attributeFilterForSubDriver,
  getSubDriverBySubDriverId,
  isAttribute,
} from 'helpers/dimensionalDrivers';
import { extractEmoji } from 'helpers/emoji';
import { extDriverDisplayName } from 'helpers/extDrivers';
import { getFormulaDateRangeDisplay } from 'helpers/formula';
import {
  AddSubContext,
  AddSubDurationContext,
  AddSubTimestampContext,
  AllContextAttributesFilterContext,
  AndExpressionContext,
  BlockFilterContext,
  CoalesceContext,
  ConditionalContext,
  DateContext,
  DateDiffContext,
  DateRangeContext,
  DateRelativeMonthsContext,
  DateRelativeQuartersContext,
  DateRelativeYearsContext,
  DimDriverRefContext,
  DriverFilterViewContext,
  DriverRefContext,
  DurationValueContext,
  ExtDriverRefContext,
  ExtQueryRefContext,
  MatchFilterViewContext,
  MinusContext,
  MulDivContext,
  NetWorkDaysContext,
  NumberContext,
  ObjectFieldFilterContext,
  ObjectFieldRefContext,
  ObjectRefContext,
  ObjectSpecRefContext,
  ObjectUUIDFilterContext,
  PowContext,
  ReducerContext,
  RoundContext,
  RoundDownContext,
  RoundPlacesContext,
  SimpleExpressionContext,
  SubmodelViewContext,
  TimestampBooleanExpressionContext,
  TimestampValueContext,
  VariableRelativeTimeContext,
} from 'helpers/formulaEvaluation/ForecastCalculator/CalculatorParser';
import {
  AttributeIdFilter,
  getAttributeIdFilters,
} from 'helpers/formulaEvaluation/ForecastCalculator/DependencyListener';
import { Listener } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import {
  cleanString,
  getDurationUnit,
} from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculatorListener';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { ExtSource } from 'helpers/integrations';
import { isNotNull } from 'helpers/typescript';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectFieldId, BusinessObjectId } from 'reduxStore/models/businessObjects';
import { Attribute, AttributeId, Dimension, DimensionId } from 'reduxStore/models/dimensions';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DimensionalDriver, DriverId } from 'reduxStore/models/drivers';
import {
  ExtDriverId,
  ExtDriverSource,
  ExtDriver as ExtDriverType,
} from 'reduxStore/models/extDrivers';
import { ExtQueryDisplay } from 'reduxStore/models/extQueries';
import { SubmodelId } from 'reduxStore/models/submodels';
import { EXPLICIT_NULL_DISPLAY } from 'reduxStore/models/value';
import { TimeUnit } from 'types/datetime';
import { FilterValueTypes } from 'types/filtering';
import {
  AtomicNumberIcon,
  AttributeFilters,
  COHORT_MONTH,
  CONTEXT_ATTR,
  DateReference,
  FormulaBooleanOperator,
  FormulaTimeRange,
  getEmptyAttributeFilters,
} from 'types/formula';

export const getUuidListFromContext = (
  ctx: ObjectFieldFilterContext,
): Array<{ attributeId: string } | { dimensionId: string }> => {
  const attributeCtxes = ctx.attributeGroup()?.attribute();
  if (attributeCtxes != null) {
    return attributeCtxes
      .map((attributeCtx) => {
        const attributeId = attributeCtx.UUID()?.text;
        if (attributeId != null) {
          return { attributeId };
        }
        const dimensionId = attributeCtx.contextAttribute()?.UUID()?.text;
        if (dimensionId != null) {
          return { dimensionId };
        }
        return null;
      })
      .filter(isNotNull);
  }

  return (
    ctx
      .uuidGroup()
      ?.UUID()
      .map((uuidCtx) => ({ attributeId: uuidCtx.text })) ?? []
  );
};

interface EvaluationContext {
  getDriverName(driverId: DriverId): string | undefined;
  getDeletedIdentifier(id: string): string | undefined;
  getAttribute(attrId: AttributeId): Attribute | undefined;
  getParentDimensionalDriver(subDriverId: DriverId): DimensionalDriver | undefined;
  getSubDriverId(dimDriverId: DriverId, attrIds: AttributeId[]): string | undefined;
  getObjectSpecName(objectSpecId: BusinessObjectSpecId): string | undefined;
  getObjectFieldName(objectFieldSpecId: BusinessObjectFieldSpecId): string | undefined;
  getObjectFieldType(objectFieldSpecId: BusinessObjectFieldSpecId): ValueType | undefined;
  getObjectFieldDimensionId(objectFieldSpecId: BusinessObjectFieldSpecId): DimensionId | undefined;
  getObjectName(objectId: BusinessObjectId): string | undefined;
  getExtDriver(id: ExtDriverId): ExtDriverType | undefined;
  getDimension(id: DimensionId): Dimension | undefined;
  getDriverGroupName(driverGroupId: DriverGroupId): string | undefined;
  getSubmodelName(submodelId: SubmodelId): string | undefined;
  getObjectSpecId(objectId: BusinessObjectId): BusinessObjectSpecId | undefined;
  getObjectSpecIdByFieldSpecId(
    fieldSpecId: BusinessObjectFieldSpecId,
  ): BusinessObjectSpecId | undefined;
  getExtQueryDisplay(id: string): ExtQueryDisplay | undefined;

  entityId: FormulaEntityTypedId;
}

export enum FormulaDisplayChunkType {
  Driver,
  Submodel,
  ExtDriver,
  ExtQuery,
  Operation,
  Number,
  String,
  Object,
  Invalid,
  AtomicNumber,
}

type NumberFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Number;
  text: string;
};

type StringFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.String;
  text: string;
  error?: string;
};

type InvalidFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Invalid;
  text: string;
  error: string;
};

type OperationFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Operation;
  text: string;
};

export type ExtDriverFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.ExtDriver;
  id: ExtDriverId;
  source?: ExtDriverSource;
  displayName: string;
  timeRangeDisplay?: string;
  timeRange?: FormulaTimeRange;
};

export type DriverFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Driver;
  driverId: DriverId;
  displayName: string;
  timeRangeDisplay?: string;
  timeRange?: FormulaTimeRange;
  attributeFilters?: AttributeFilters;
  placeholder?: string;
};

export type SubmodelFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Submodel;
  submodelId: SubmodelId;
  driverGroupId?: DriverGroupId;
  displayName: string;
  timeRangeDisplay?: string;
  timeRange?: FormulaTimeRange;
};

export type ObjectFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Object;
  objectSpecId: BusinessObjectSpecId;
  // Set if referring to a specific object directly
  objectId?: BusinessObjectId;
  isThisRef?: boolean;
  displayName: string;
  field?: ObjectFieldDisplayChunk;
  timeRangeDisplay?: string;
  timeRange?: FormulaTimeRange;
  filters: {
    includeAllContextAttributes: boolean;
    matchToSingleResult: boolean;
    propertyFilters: ObjectFilterDisplayChunk[];
  };
  error?: string;
};

export type ObjectFilterDisplayChunk = {
  field: ObjectFieldDisplayChunk;
  operator: FormulaBooleanOperator;
  value: string;
  valueType: ValueType | FilterValueTypes.ENTITY;
  attributeIds?: AttributeId[];
  dateRangeValue?: FormulaTimeRange;
  ids?: BusinessObjectId[];
  isNull?: boolean;
  error?: string;
};

type ObjectFieldDisplayChunk = {
  fieldId: BusinessObjectFieldId;
  name: string;
  timeRange?: FormulaTimeRange;
  timeRangeDisplay?: string;
  error?: string;
};

export type ExtQueryFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.ExtQuery;
  id: string;
  displayName: string;
  source?: ExtSource;
  attributeFilters?: AttributeFilters;
  timeRangeDisplay?: string;
  timeRange?: FormulaTimeRange;
};

export type AtomicNumberDisplayChunk = {
  type: FormulaDisplayChunkType.AtomicNumber;
  value: string;
  tooltip?: string;
  icon?: AtomicNumberIcon;
};

export type FormulaDisplayChunk = (
  | OperationFormulaDisplayChunk
  | ExtDriverFormulaDisplayChunk
  | DriverFormulaDisplayChunk
  | ObjectFormulaDisplayChunk
  | ExtQueryFormulaDisplayChunk
  | NumberFormulaDisplayChunk
  | SubmodelFormulaDisplayChunk
  | StringFormulaDisplayChunk
  | InvalidFormulaDisplayChunk
  | AtomicNumberDisplayChunk
) & { error?: string };

export type FormulaDisplay = {
  chunks: FormulaDisplayChunk[];
  isEditingSupported?: boolean;
  error?: string;
};

const COMMA_CHUNK: OperationFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Operation,
  text: ', ',
};

const OPEN_PAREN_CHUNK: OperationFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Operation,
  text: '(',
};

const CLOSE_PAREN_CHUNK: OperationFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Operation,
  text: ')',
};

const NULL_CHUNK: OperationFormulaDisplayChunk = {
  type: FormulaDisplayChunkType.Operation,
  text: EXPLICIT_NULL_DISPLAY,
};

export default class FormulaDisplayListener implements Listener<FormulaDisplay> {
  private argumentStack: FormulaDisplay[];
  // Keep dates separately since they will be tacked onto their relevant driver chunks.
  private timeRangeStack: Array<Pick<DriverFormulaDisplayChunk, 'timeRange' | 'timeRangeDisplay'>>;
  private dateStack: DateReference[];
  // Keep filters separately since they will be tacked onto their relevant object chunks.
  private objectFilterStack: ObjectFilterDisplayChunk[];
  private includeAllContextAttributes: boolean;
  private evaluationContext: EvaluationContext;
  private isEditingSupported: boolean;
  private error?: string;

  private entityId: FormulaEntityTypedId;

  constructor(evalContext: EvaluationContext) {
    this.argumentStack = [];
    this.timeRangeStack = [];
    this.dateStack = [];
    this.objectFilterStack = [];
    this.includeAllContextAttributes = false;
    this.evaluationContext = evalContext;
    this.isEditingSupported = true;
    this.entityId = evalContext.entityId;
  }

  getResult(): FormulaDisplay {
    const result = this.argumentStack.pop() as FormulaDisplay | null;

    if (result == null) {
      return {
        chunks: [],
        isEditingSupported: this.isEditingSupported,
        error: this.error,
      };
    }

    const toReturn = {
      ...result,
      isEditingSupported: this.isEditingSupported,
      error: this.error,
    };

    return toReturn;
  }

  getObjectDisplay(): ObjectFormulaDisplayChunk | undefined {
    const result = this.argumentStack.pop() as FormulaDisplay | null;
    return result != null && result.chunks.length === 1
      ? (result.chunks[0] as ObjectFormulaDisplayChunk)
      : undefined;
  }

  getFormulaTimeRange(): FormulaTimeRange | undefined {
    const result = this.timeRangeStack.pop();
    return result?.timeRange;
  }

  exitMinus(_ctx: MinusContext) {
    const [expression] = this.getLastNArgumentsFromStack(this.argumentStack, 1);
    this.argumentStack.push({
      chunks: [{ text: '-', type: FormulaDisplayChunkType.Operation }, ...expression.chunks],
    });
  }

  exitDriverRef(ctx: DriverRefContext) {
    const uuid = ctx.UUID().text;
    const chunk = this.getChunkForDriverRef({ type: 'Driver', driverId: uuid });
    this.argumentStack.push({
      chunks: [chunk],
    });
  }

  exitSubmodelView(ctx: SubmodelViewContext) {
    const submodelId = ctx.submodelRef().UUID().text;
    // TODO: parse this in separate listener instead of checking type
    const groupIdFilter = ctx.submodelFilter().driverGroupFilter().UUID().text;

    // TODO: Remove when we make the time range non optional
    const hasSetTimeRange = ctx.submodelRef().timeRange() != null;

    const chunk = this.getChunkForSubmodelRef(submodelId, groupIdFilter, hasSetTimeRange);
    this.argumentStack.push({
      chunks: [chunk],
    });
  }

  exitDriverFilterView(ctx: DriverFilterViewContext) {
    // Do this first so that we clear state even if we return early
    const includeAllContextAttributes = this.getAndClearIncludeAllContextAttributes();

    const dimDriverChunks = this.argumentStack.pop();
    if (dimDriverChunks == null) {
      return;
    }

    const firstChunk = dimDriverChunks.chunks[0] as DriverFormulaDisplayChunk;
    const driverName = firstChunk.displayName;
    const { attributeIdFilters } = getAttributeIdFilters(ctx, this.evaluationContext);
    const { attributeFilters, error } = this.getAttributeFiltersFromAttributeIdFilters(
      attributeIdFilters,
      includeAllContextAttributes,
    );
    if (error != null) {
      this.error = error;
    }

    this.argumentStack.push({
      chunks: [
        {
          displayName: driverName,
          timeRangeDisplay: firstChunk.timeRangeDisplay,
          timeRange: firstChunk.timeRange,
          driverId: firstChunk.driverId,
          attributeFilters,
          type: FormulaDisplayChunkType.Driver,
          error,
        },
        ...dimDriverChunks.chunks.slice(1),
      ],
    });
  }

  exitDimDriverRef(ctx: DimDriverRefContext) {
    const uuid = ctx.UUID().text;
    const chunk = this.getChunkForDriverRef({ type: 'Driver', driverId: uuid });
    if (chunk.type !== FormulaDisplayChunkType.Driver) {
      return;
    }
    this.argumentStack.push({
      chunks: [
        {
          ...chunk,
          attributeFilters: getEmptyAttributeFilters(),
        },
      ],
    });
  }

  exitSimpleExpression(ctx: SimpleExpressionContext | TimestampBooleanExpressionContext) {
    const operator = ctx.BOOLEAN_OPERATOR().text;
    const [lhs, rhs] = this.getLastNArgumentsFromStack(this.argumentStack, 2);
    this.argumentStack.push({
      chunks: [
        ...lhs.chunks,
        { text: operator, type: FormulaDisplayChunkType.Operation },
        ...rhs.chunks,
      ],
    });
  }

  exitTimestampBooleanExpression(ctx: TimestampBooleanExpressionContext) {
    return this.exitSimpleExpression(ctx);
  }

  exitAndExpression(_ctx: AndExpressionContext) {
    const [lhs, rhs] = this.getLastNArgumentsFromStack(this.argumentStack, 2);
    this.argumentStack.push({
      chunks: [
        ...lhs.chunks,
        { text: ' AND ', type: FormulaDisplayChunkType.Operation },
        ...rhs.chunks,
      ],
    });
  }

  exitOrExpression(_ctx: AndExpressionContext) {
    const [lhs, rhs] = this.getLastNArgumentsFromStack(this.argumentStack, 2);
    this.argumentStack.push({
      chunks: [
        ...lhs.chunks,
        { text: ' OR ', type: FormulaDisplayChunkType.Operation },
        ...rhs.chunks,
      ],
    });
  }

  exitConditional(_ctx: ConditionalContext) {
    this.evaluateConditionalExpression();
  }

  exitIfError() {
    const [lhs, rhs] = this.getLastNArgumentsFromStack(this.argumentStack, 2);
    this.argumentStack.push({
      chunks: [
        { text: 'ifError', type: FormulaDisplayChunkType.Operation },
        OPEN_PAREN_CHUNK,
        ...lhs.chunks,
        COMMA_CHUNK,
        ...rhs.chunks,
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  // TODO: This should be combined with #exitConditional() once we have a better way of standardizing
  // the return type of general expressions.
  exitStringIf() {
    this.evaluateConditionalExpression();
  }

  // TODO: This should be combined with #exitConditional() once we have a better way of standardizing
  // the return type of general expressions.
  exitTimestampIf() {
    this.evaluateConditionalExpression();
  }

  evaluateConditionalExpression() {
    const [expression, lhs, rhs] = this.getLastNArgumentsFromStack(this.argumentStack, 3);
    this.argumentStack.push({
      chunks: [
        { text: 'if', type: FormulaDisplayChunkType.Operation },
        OPEN_PAREN_CHUNK,
        ...expression.chunks,
        COMMA_CHUNK,
        ...lhs.chunks,
        COMMA_CHUNK,
        ...rhs.chunks,
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitCoalesce(ctx: CoalesceContext) {
    const numExpressions = ctx.coalesceRef().expression().length;
    const args = this.getLastNArgumentsFromStack(this.argumentStack, numExpressions);
    this.argumentStack.push({
      chunks: [
        { text: 'coalesce', type: FormulaDisplayChunkType.Operation },
        OPEN_PAREN_CHUNK,
        ...args.flatMap((arg, idx) =>
          idx < numExpressions - 1 ? [...arg.chunks, COMMA_CHUNK] : [...arg.chunks],
        ),
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitEndOfMonth() {
    const date = this.argumentStack.pop();
    this.argumentStack.push({
      chunks: [
        {
          text: 'endOfMonth',
          type: FormulaDisplayChunkType.Operation,
        },
        OPEN_PAREN_CHUNK,
        ...(date?.chunks || []),
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitStartOfMonth() {
    const date = this.argumentStack.pop();
    this.argumentStack.push({
      chunks: [
        {
          text: 'startOfMonth',
          type: FormulaDisplayChunkType.Operation,
        },
        OPEN_PAREN_CHUNK,
        ...(date?.chunks || []),
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitReducer(ctx: ReducerContext) {
    const reducerFn = ctx.reduceExpressionsOrViews().reducerFn().text;
    const numParams = ctx.reduceExpressionsOrViews().expressionOrView().length;

    const params = this.getLastNArgumentsFromStack(this.argumentStack, numParams);
    if (params == null) {
      return;
    }

    const paramsChunks: FormulaDisplayChunk[] = params
      .map((p, i) => [...p.chunks, ...(i !== params.length - 1 ? [COMMA_CHUNK] : [])])
      .flat();

    this.argumentStack.push({
      chunks: [
        { text: camelCase(reducerFn), type: FormulaDisplayChunkType.Operation },
        OPEN_PAREN_CHUNK,
        ...paramsChunks,
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitNumber(ctx: NumberContext) {
    this.argumentStack.push({
      chunks: [
        {
          text: ctx.text, // Not formatted because formatted causes issues with editing
          type: FormulaDisplayChunkType.Number,
        },
      ],
    });
  }

  exitNull() {
    this.argumentStack.push({
      chunks: [NULL_CHUNK],
    });
  }

  exitRound(_ctx: RoundContext) {
    const toRound = this.argumentStack.pop();
    this.argumentStack.push({
      chunks: [
        {
          text: 'round',
          type: FormulaDisplayChunkType.Operation,
        },
        OPEN_PAREN_CHUNK,
        ...(toRound?.chunks || []),
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitRoundDown(_ctx: RoundDownContext) {
    const toRound = this.argumentStack.pop();
    this.argumentStack.push({
      chunks: [
        {
          text: 'roundDown',
          type: FormulaDisplayChunkType.Operation,
        },
        OPEN_PAREN_CHUNK,
        ...(toRound?.chunks || []),
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitRoundPlaces(_ctx: RoundPlacesContext) {
    const places = this.argumentStack.pop();
    const toRound = this.argumentStack.pop();
    this.argumentStack.push({
      chunks: [
        {
          text: 'round',
          type: FormulaDisplayChunkType.Operation,
        },
        OPEN_PAREN_CHUNK,
        ...(toRound?.chunks || []),
        COMMA_CHUNK,
        ...(places?.chunks || []),
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  handleAddSub(ctx: AddSubContext | AddSubTimestampContext | AddSubDurationContext) {
    const isSubtraction = !!ctx.SUB();
    const [lhs, rhs] = this.getLastNArgumentsFromStack(this.argumentStack, 2);
    const lhsChunks = lhs != null ? lhs.chunks : []; // extra null check for safety. nulls have slipped through before
    const rhsChunks = rhs != null ? rhs.chunks : [];
    this.argumentStack.push({
      chunks: [
        ...lhsChunks,
        { text: isSubtraction ? ' - ' : ' + ', type: FormulaDisplayChunkType.Operation },
        ...rhsChunks,
      ],
    });
  }

  exitAddSub(ctx: AddSubContext) {
    this.handleAddSub(ctx);
  }

  exitAddSubTimestamp(ctx: AddSubTimestampContext) {
    this.handleAddSub(ctx);
  }

  exitAddSubDuration(ctx: AddSubDurationContext) {
    this.handleAddSub(ctx);
  }

  exitTimestampValue(ctx: TimestampValueContext) {
    const str = ctx.STRING();

    if (str != null) {
      const stringVal = cleanString(str.text);
      this.argumentStack.push({
        chunks: [
          {
            text: stringVal,
            type: FormulaDisplayChunkType.String,
          },
        ],
      });
      return;
    }

    const timeRange = this.timeRangeStack.pop();
    if (timeRange?.timeRange == null) {
      return;
    }

    const { start, end, type } = timeRange.timeRange;
    if (start !== end) {
      this.error = `Timestamp values currently only support single months`;
      return;
    }
    if (start !== 0 || type !== 'relative') {
      this.error = `Timestamp values currently only support this month`;
      return;
    }

    this.argumentStack.push({
      chunks: [
        {
          text: `thisMonth`,
          type: FormulaDisplayChunkType.Operation,
        },
        OPEN_PAREN_CHUNK,
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitDurationValue(ctx: DurationValueContext) {
    const number = this.argumentStack.pop();
    this.argumentStack.push({
      chunks: [
        {
          text: `as${capitalize(getDurationUnit(ctx))}`,
          type: FormulaDisplayChunkType.Operation,
        },
        OPEN_PAREN_CHUNK,
        ...(number?.chunks || []),
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitMulDiv(ctx: MulDivContext) {
    const isDivision = !!ctx.DIV();
    const [lhs, rhs] = this.getLastNArgumentsFromStack(this.argumentStack, 2);
    this.argumentStack.push({
      chunks: [
        ...lhs.chunks,
        { text: isDivision ? ' / ' : ' * ', type: FormulaDisplayChunkType.Operation },
        ...rhs.chunks,
      ],
    });
  }

  exitPow(_ctx: PowContext) {
    const [base, exp] = this.getLastNArgumentsFromStack(this.argumentStack, 2);
    this.argumentStack.push({
      chunks: [
        ...base.chunks,
        { text: ' ^ ', type: FormulaDisplayChunkType.Operation },
        ...exp.chunks,
      ],
    });
  }

  exitParenthesis() {
    const [expressionA] = this.getLastNArgumentsFromStack(this.argumentStack, 1);
    this.argumentStack.push({
      chunks: [OPEN_PAREN_CHUNK, ...expressionA.chunks, CLOSE_PAREN_CHUNK],
    });
  }

  exitDate(ctx: DateContext) {
    const date = ctx.DATE().text;
    this.dateStack.push({ type: 'absolute', val: date });
  }

  exitDateRelativeMonths(ctx: DateRelativeMonthsContext) {
    const relativeNum = ctx.NUMBER()?.text;
    if (relativeNum == null) {
      this.error = `Relative months date missing number`;
      return;
    }
    const signedRelativeNum = (ctx.SUB() == null ? 1 : -1) * parseInt(relativeNum);
    this.dateStack.push({
      type: 'relative',
      val: { val: signedRelativeNum, unit: TimeUnit.Month, reference: 'today' },
    });
  }

  exitDateRelativeQuarters(ctx: DateRelativeQuartersContext) {
    const relativeNum = ctx.NUMBER()?.text;
    if (relativeNum == null) {
      this.error = `Relative quarters date missing number`;
      return;
    }
    const signedRelativeNum = (ctx.SUB() == null ? 1 : -1) * parseInt(relativeNum);
    this.dateStack.push({
      type: 'relative',
      val: { val: signedRelativeNum, unit: TimeUnit.Quarter, reference: 'today' },
    });
  }

  exitDateRelativeYears(ctx: DateRelativeYearsContext) {
    const relativeNum = ctx.NUMBER()?.text;
    if (relativeNum == null) {
      this.error = `Relative quarters date missing number`;
      return;
    }
    const signedRelativeNum = (ctx.SUB() == null ? 1 : -1) * parseInt(relativeNum);
    this.dateStack.push({
      type: 'relative',
      val: { val: signedRelativeNum, unit: TimeUnit.Year, reference: 'yearStart' },
    });
  }

  exitDateRange(_ctx: DateRangeContext) {
    const end = this.requireDate();
    const start = this.requireDate();

    const timeRange: FormulaTimeRange = {
      type: 'range' as const,
      start,
      end,
    };
    this.timeRangeStack.push({
      timeRange,
      timeRangeDisplay: getFormulaDateRangeDisplay(timeRange, {}),
    });
  }

  exitVariableRelativeTime(_ctx: VariableRelativeTimeContext) {
    const end = this.requirePop();
    const start = this.requirePop();

    const [startNumber, endNumber] = [this.toNumberFragment(start), this.toNumberFragment(end)];
    if (startNumber != null && endNumber != null) {
      const timeRange = {
        type: 'relative' as const,
        start: startNumber,
        end: endNumber,
      };
      this.timeRangeStack.push({
        timeRange,
        timeRangeDisplay: getFormulaDateRangeDisplay(timeRange, {}),
      });
      return;
    }

    const startDriverId = this.getNegativeDriverRef(start);
    const endDriverId = this.getNegativeDriverRef(end);
    let timeRange: FormulaTimeRange | undefined;
    if (startDriverId != null && endDriverId != null && startDriverId === endDriverId) {
      this.isEditingSupported = true;
      timeRange = {
        type: 'relativeVariable',
        start: startDriverId,
        end: endDriverId,
      };
    } else {
      this.isEditingSupported = false;
    }
    this.timeRangeStack.push({
      timeRange,
      timeRangeDisplay: this.getVariableTimeRangeDisplay(start, end),
    });
  }

  exitExtDriverRef(ctx: ExtDriverRefContext) {
    const timeRange = this.timeRangeStack.pop();
    const id = ctx.UUID().text;

    const extDriver = this.evaluationContext.getExtDriver(id);

    this.argumentStack.push({
      chunks: [
        {
          type: FormulaDisplayChunkType.ExtDriver,
          id,
          source: extDriver?.source,
          displayName: extDriverDisplayName(extDriver),
          ...timeRange,
        },
      ],
    });
  }

  exitMatchFilterView(ctx: MatchFilterViewContext): void {
    if (ctx.driverFilterView() != null) {
      const driverFilterViewChunk = this.argumentStack.pop();
      const dimDriverChunk = driverFilterViewChunk?.chunks[0] as
        | DriverFormulaDisplayChunk
        | undefined;
      if (driverFilterViewChunk == null || dimDriverChunk == null) {
        throw new Error('missing driver chunk in match filter');
      }
      dimDriverChunk.attributeFilters ??= getEmptyAttributeFilters();
      dimDriverChunk.attributeFilters.matchToSingleResult = true;
      this.argumentStack.push(driverFilterViewChunk);
      return;
    }

    if (ctx.objectSpecRef() != null) {
      const objectSpecRefChunk = this.argumentStack.pop();
      const objectChunk = objectSpecRefChunk?.chunks[0] as ObjectFormulaDisplayChunk | undefined;
      if (objectSpecRefChunk == null || objectChunk == null) {
        throw new Error('missing object chunk in match filter');
      }
      objectChunk.filters ??= {
        matchToSingleResult: true,
        includeAllContextAttributes: false,
        propertyFilters: [],
      };
      objectChunk.filters.matchToSingleResult = true;
      this.argumentStack.push(objectSpecRefChunk);
    }
  }

  chunksToString(displayItem: FormulaDisplay | undefined) {
    if (displayItem == null) {
      return '';
    }

    return displayItem.chunks
      .map((chunk) => {
        switch (chunk.type) {
          case FormulaDisplayChunkType.Operation:
          case FormulaDisplayChunkType.Number:
          case FormulaDisplayChunkType.Invalid:
          case FormulaDisplayChunkType.String:
            return chunk.text;
          case FormulaDisplayChunkType.AtomicNumber:
            return chunk.value;
          default:
            return chunk.displayName;
        }
      })
      .join('');
  }

  exitAddProduct() {
    const right = this.argumentStack.pop();
    const left = this.argumentStack.pop();

    this.argumentStack.push({
      chunks: [
        {
          text: 'sumProduct',
          type: FormulaDisplayChunkType.Operation,
        },
        OPEN_PAREN_CHUNK,
        ...(left?.chunks || []),
        COMMA_CHUNK,
        ...(right?.chunks || []),
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitBlockFilter(ctx: BlockFilterContext) {
    // Do this first so that we clear state even if we return early
    const includeAllContextAttributes = this.getAndClearIncludeAllContextAttributes();
    const filters = this.getAndClearObjectFilterStack();

    const objectSpecId = ctx.UUID().text;
    const chunk: ObjectFormulaDisplayChunk = {
      type: FormulaDisplayChunkType.Object,
      objectSpecId,
      displayName: '',
      filters: {
        includeAllContextAttributes,
        matchToSingleResult: false,
        propertyFilters: filters,
      },
      error: filters.find((f) => f.error != null)?.error,
    };
    this.argumentStack.push({
      chunks: [chunk],
    });
  }

  exitObjectSpecRef(ctx: ObjectSpecRefContext) {
    // Do this first so that we clear state even if we return early
    const includeAllContextAttributes = this.getAndClearIncludeAllContextAttributes();
    const filters = this.getAndClearObjectFilterStack();

    const objectSpecId = ctx.UUID().text;
    let objectSpecName: string | undefined = this.evaluationContext.getObjectSpecName(objectSpecId);
    if (objectSpecName == null) {
      objectSpecName = this.evaluationContext.getDeletedIdentifier(objectSpecId);
      this.error = `Formula contains deleted database type "${objectSpecName}"`;
    }
    objectSpecName = objectSpecName != null ? extractEmoji(objectSpecName)[1] : objectSpecName;
    const field =
      ctx.objectFieldRef() != null
        ? this.getChunkForObjectFieldRef(ctx.objectFieldRef() as ObjectFieldRefContext, false)
        : undefined;
    const fieldName = ctx.objectFieldRef() != null ? (field?.name ?? 'deleted field') : undefined;
    const timeRange = ctx.timeRange() != null ? this.timeRangeStack.pop() : undefined;

    // Field and filter errors take precedence
    const hasFilterErr = filters.some((f) => f.field.error != null || f.error != null);
    if (!hasFilterErr && (field == null || field.error == null)) {
      const attrFilterError = this.getAttributeFilterError(filters);
      if (attrFilterError != null) {
        this.error = attrFilterError;
      }
    }

    const chunk: ObjectFormulaDisplayChunk = {
      type: FormulaDisplayChunkType.Object,
      objectSpecId,
      // TODO: add filters in display name. For now, we leave it out for brevity.
      displayName: `${objectSpecName}${fieldName != null ? `.${fieldName}` : ''}`,
      field,
      filters: {
        includeAllContextAttributes,
        matchToSingleResult: false,
        propertyFilters: filters,
      },
      error: hasFilterErr ? this.error : undefined,
      ...timeRange,
    };

    this.argumentStack.push({
      chunks: [chunk],
    });
  }

  exitObjectRef(ctx: ObjectRefContext) {
    // Do this first so that we clear state even if we return early
    const includeAllContextAttributes = this.getAndClearIncludeAllContextAttributes();
    const filters = this.getAndClearObjectFilterStack();

    const objectId = ctx.UUID()?.text;
    let objectSpecId: BusinessObjectId | undefined =
      objectId != null ? this.evaluationContext.getObjectSpecId(objectId) : undefined;

    let isThisRef = false;
    if (ctx.THIS() != null) {
      if (this.entityId.type !== 'objectFieldSpec') {
        throw new Error('expected context to be provided for "this object" reference');
      }

      objectSpecId = this.evaluationContext.getObjectSpecIdByFieldSpecId(this.entityId.id);

      isThisRef = true;
    }

    if (objectSpecId == null) {
      return;
    }

    const objectSpecName = this.evaluationContext.getObjectSpecName(objectSpecId);
    if (objectSpecName == null) {
      return;
    }

    let directObjectName: string | undefined;
    if (!isThisRef && objectId != null) {
      const objectName = this.evaluationContext.getObjectName(objectId);
      if (objectName == null) {
        return;
      }
    }

    const specNameWithoutEmoji = extractEmoji(objectSpecName)[1];
    const specDisplayName = isThisRef
      ? `This ${pluralize.singular(specNameWithoutEmoji)}`
      : directObjectName;
    if (specDisplayName == null) {
      return;
    }

    const fieldChunk = this.getChunkForObjectFieldRef(ctx.objectFieldRef(), false);
    const fieldName = fieldChunk?.name;
    const displayName = `${specDisplayName}${fieldName != null ? `.${fieldName}` : ''}`;

    const hasFilterErr = filters.some((f) => f.field.error != null || f.error != null);

    if (!hasFilterErr) {
      const attrFilterError = this.getAttributeFilterError(filters);
      if (attrFilterError != null) {
        this.error = attrFilterError;
      }
    }

    const chunk: ObjectFormulaDisplayChunk = {
      type: FormulaDisplayChunkType.Object,
      objectSpecId,
      displayName,
      field: fieldChunk,
      filters: {
        includeAllContextAttributes,
        matchToSingleResult: false,
        propertyFilters: filters,
      },
      error: hasFilterErr ? this.error : undefined,
      timeRange: fieldChunk?.timeRange,
      isThisRef,
    };

    this.argumentStack.push({ chunks: [chunk] });
  }

  exitObjectUUIDFilter(ctx: ObjectUUIDFilterContext) {
    const uuids = ctx.UUID().map((uuid) => uuid.text);
    const names = uuids.map(
      (uuid) => this.evaluationContext.getObjectName(uuid) ?? 'deleted object',
    );

    const missingObject = uuids.find((uuid) => this.evaluationContext.getObjectName(uuid) == null);
    // TODO(sam): get deleted item name
    if (missingObject != null) {
      this.error = `Formula contains deleted object in filter`;
    }

    this.objectFilterStack.push({
      field: {
        fieldId: FilterValueTypes.ENTITY,
        name: 'Name',
        error: missingObject != null ? this.error : undefined,
      },
      operator: FormulaBooleanOperator.Equals,
      value: names.join(', '),
      valueType: FilterValueTypes.ENTITY,
      ids: uuids,
    });
  }

  exitObjectFieldFilter(ctx: ObjectFieldFilterContext) {
    let value = '';
    let dateRangeValue: FormulaTimeRange | undefined;
    let attributeIds: AttributeId[] | undefined;
    const timeRangeCtx = ctx.timeRange();
    // TODO what happens if the filter value type of filter and field don't match
    let valueType = ValueType.Number;
    if (timeRangeCtx != null) {
      const dateValue = this.timeRangeStack.pop();
      value = dateValue?.timeRangeDisplay ?? timeRangeCtx.text;
      dateRangeValue = dateValue?.timeRange;
      valueType = ValueType.Timestamp;
    }

    const stringGroupCtx = ctx.stringGroup();
    if (stringGroupCtx != null) {
      value =
        ctx
          .stringGroup()
          ?.STRING()
          .map((s) => cleanString(s.text))
          .join('') ?? stringGroupCtx.text;
    }

    const uuidItems = getUuidListFromContext(ctx);

    if (uuidItems.length > 0) {
      valueType = ValueType.Attribute;
      attributeIds = uuidItems
        .map((item) => ('attributeId' in item ? item.attributeId : CONTEXT_ATTR))
        .filter(isNotNull);
      value = uuidItems
        .map((item) => {
          if ('dimensionId' in item) {
            const dimension = this.evaluationContext.getDimension(item.dimensionId);
            if (dimension == null) {
              return 'Unknown dimension {Match conext}';
            }
            return `${dimension.name} {Match context}`;
          }

          const evaluatedAttribute = this.evaluationContext.getAttribute(item.attributeId);

          const deletedIdentifier = this.evaluationContext.getDeletedIdentifier(item.attributeId);
          const defaultDisplayValue = deletedIdentifier ?? item.attributeId;

          return evaluatedAttribute == null
            ? defaultDisplayValue
            : evaluatedAttribute.value.toString();
        })
        .join(', ');
    }

    const fieldChunk = this.getChunkForObjectFieldRef(ctx.objectFieldRef(), true);
    if (fieldChunk == null) {
      return;
    }

    // Let field errors take precedence
    let missingAttr = false;
    if (fieldChunk.error == null && valueType === ValueType.Attribute && attributeIds != null) {
      const errors = attributeIds
        .map((attrId) => {
          if (attrId === CONTEXT_ATTR) {
            return undefined;
          }
          const attr = this.evaluationContext.getAttribute(attrId);
          const deletedIdentifier = this.evaluationContext.getDeletedIdentifier(attrId);
          const displayValue = attr?.value ?? deletedIdentifier ?? '';
          if (attr == null || attr.deleted) {
            missingAttr = true;
            return `attribute "${displayValue}" was deleted`;
          }
          return undefined;
        })
        .filter(isNotNull);
      if (errors.length > 0) {
        this.error = errors[0];
      }
    }

    const none = ctx.NULL();
    let isNull = false;
    if (none != null) {
      value = 'none';
      isNull = true;
      valueType = this.evaluationContext.getObjectFieldType(fieldChunk.fieldId) ?? valueType;
    }

    this.objectFilterStack.push({
      field: fieldChunk,
      operator: ctx.BOOLEAN_OPERATOR().text as FormulaBooleanOperator,
      value,
      valueType,
      attributeIds,
      dateRangeValue,
      isNull,
      error: missingAttr ? this.error : undefined,
    });
  }

  exitAllContextAttributesFilter(_: AllContextAttributesFilterContext) {
    this.includeAllContextAttributes = true;
  }

  exitExtQueryRef(ctx: ExtQueryRefContext) {
    // Do this first so that we clear state even if we return early
    const includeAllContextAttributes = this.getAndClearIncludeAllContextAttributes();

    const timeRange = this.timeRangeStack.pop();
    const id = ctx.UUID().text;
    // TODO: Handle filters
    const extQueryDisplay = this.evaluationContext.getExtQueryDisplay(id);

    const filterCtx = ctx.extQueryFilterView();
    let filters: AttributeFilters | undefined;
    if (filterCtx != null) {
      const { attributeIdFilters } = getAttributeIdFilters(filterCtx, this.evaluationContext);
      const { attributeFilters, error } = this.getAttributeFiltersFromAttributeIdFilters(
        attributeIdFilters,
        includeAllContextAttributes,
      );
      if (error != null) {
        this.error = error;
      }
      filters = attributeFilters;
    }

    this.argumentStack.push({
      chunks: [
        {
          type: FormulaDisplayChunkType.ExtQuery,
          id,
          displayName: extQueryDisplay?.name ?? 'deleted ext query',
          source: extQueryDisplay?.source,
          attributeFilters: filters,
          ...timeRange,
        },
      ],
    });
  }

  exitDaysInMonth() {
    this.argumentStack.push({
      chunks: [
        { text: 'daysInMonth', type: FormulaDisplayChunkType.Operation },
        OPEN_PAREN_CHUNK,
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitDateDiff(ctx: DateDiffContext) {
    const unit = cleanString(ctx.STRING().text);
    const end = this.argumentStack.pop();
    const start = this.argumentStack.pop();

    const luxonUnit = getLuxonUnitFromText(unit);
    const error = luxonUnit == null ? `Invalid unit "${unit}"` : undefined;
    if (error != null) {
      this.error = error;
    }

    this.argumentStack.push({
      chunks: [
        { text: 'dateDiff', type: FormulaDisplayChunkType.Operation },
        OPEN_PAREN_CHUNK,
        ...(start?.chunks || []),
        COMMA_CHUNK,
        ...(end?.chunks || []),
        COMMA_CHUNK,
        { type: FormulaDisplayChunkType.String, text: unit, error },
        CLOSE_PAREN_CHUNK,
      ],
    });
  }

  exitNetWorkDays(ctx: NetWorkDaysContext) {
    const strings = ctx.STRING().map((token) => cleanString((token?.text ?? '').trim()));
    const weekendString = strings[0] ?? '';
    const holidaysString = strings[1] ?? '';

    const end = this.argumentStack.pop();
    const start = this.argumentStack.pop();

    const chunks: FormulaDisplayChunk[] = [
      { text: 'netWorkDays', type: FormulaDisplayChunkType.Operation },
      OPEN_PAREN_CHUNK,
      ...(start?.chunks || []),
      COMMA_CHUNK,
      ...(end?.chunks || []),
    ];

    if (weekendString.length > 0 || holidaysString.length > 0) {
      const invalidWeekendStr = weekendMaskStrInvalidReason(weekendString);
      if (invalidWeekendStr != null) {
        this.error = 'invalidWeekendStr';
      }
      chunks.push(COMMA_CHUNK, { type: FormulaDisplayChunkType.String, text: weekendString });
    }

    if (holidaysString.length > 0) {
      const holidays = holidaysString.split(',').map((s) => s.trim());
      const holidayDates: DateTime[] = [];

      for (const h of holidays) {
        const date = parseLiteralDateString(h);
        if (date == null) {
          this.error = `Invalid date "${h}" in holiday list`;
          break;
        }
        holidayDates.push(date);
      }

      chunks.push(COMMA_CHUNK, { type: FormulaDisplayChunkType.String, text: holidaysString });
    }

    chunks.push(CLOSE_PAREN_CHUNK);

    this.argumentStack.push({ chunks });
  }

  private requirePop(): FormulaDisplay {
    const arg = this.argumentStack.pop();
    if (arg == null) {
      throw new Error('missing argument in formula');
    }
    return arg;
  }

  private requireDate(): DateReference {
    const arg = this.dateStack.pop();
    if (arg == null) {
      throw new Error('missing date in formula');
    }
    return arg;
  }

  private getLastNArgumentsFromStack<T extends FormulaDisplay | string | null>(
    stack: T[],
    argumentCount: number,
  ): T[] {
    if (stack.length < argumentCount) {
      throw new Error('Not enough arguments on the stack');
    }
    const lastArgumentIndex = stack.length - 1;
    const zeroBasedArgumentCount = argumentCount - 1;
    return stack.splice(lastArgumentIndex - zeroBasedArgumentCount, argumentCount);
  }

  private getChunkForObjectFieldRef(
    ctx: ObjectFieldRefContext,
    isFilter: boolean,
  ): ObjectFieldDisplayChunk | undefined {
    const fieldId = ctx.UUID().text;
    const date = this.timeRangeStack.pop();
    const timeRange = date != null ? date.timeRange : undefined;
    let fieldName = this.evaluationContext.getObjectFieldName(fieldId);
    const deletedField = fieldName == null;

    if (fieldName == null) {
      fieldName = this.evaluationContext.getDeletedIdentifier(fieldId);
      if (fieldName != null) {
        this.error = `Formula contains deleted object field "${fieldName}"${
          isFilter ? ' in filter' : ''
        }`;
      } else {
        this.error = `Formula contains unknown object field${isFilter ? ' in filter' : ''}`;
      }
    }

    if (timeRange == null) {
      this.error = `Database field reference missing date "${fieldName}"`;
      return undefined;
    }

    // Check if field is referencing a deleted dimension
    let hasDeletedDim = false;
    const fieldType = this.evaluationContext.getObjectFieldType(fieldId);
    if (fieldType === ValueType.Attribute) {
      const dimId = this.evaluationContext.getObjectFieldDimensionId(fieldId);
      if (dimId != null) {
        const dim = this.evaluationContext.getDimension(dimId);
        if (dim == null || dim.deleted) {
          hasDeletedDim = true;
          this.error = 'References database field with deleted dimension';
          if (dim != null) {
            this.error += ` "${dim.name}"`;
          }
        }
      }
    }

    return {
      fieldId,
      name: fieldName ?? 'unknown field',
      timeRange,
      timeRangeDisplay: date?.timeRangeDisplay,
      error: deletedField || hasDeletedDim ? this.error : undefined,
    };
  }

  private getChunkForSubmodelRef(
    submodelId: SubmodelId,
    driverGroupId: DriverGroupId,
    hasSetTimeRange: boolean,
  ): SubmodelFormulaDisplayChunk {
    const submodelName = this.evaluationContext.getSubmodelName(submodelId);
    const driverGroupName =
      driverGroupId != null ? this.evaluationContext.getDriverGroupName(driverGroupId) : '';

    const setTimeRange = hasSetTimeRange ? this.timeRangeStack.pop() : null;

    const timeRange: FormulaTimeRange = setTimeRange?.timeRange ?? {
      type: 'relative',
      start: 0,
      end: 0,
    };

    const [emoji, name] = extractEmoji(submodelName ?? '');
    return {
      type: FormulaDisplayChunkType.Submodel,
      submodelId,
      driverGroupId,
      displayName: `${emoji} ${name} / ${driverGroupName ?? 'unknown driver group'}`,
      timeRange,
      timeRangeDisplay: getFormulaDateRangeDisplay(timeRange, {}),
    };
  }

  private getChunkForDriverRef(
    driverRef:
      | {
          type: 'Driver';
          driverId: DriverId;
        }
      | {
          type: 'DeletedSubDriver';
          parentDimDriverId: DriverId;
          attributeIds: AttributeId[];
        },
  ): FormulaDisplayChunk {
    let displayName = 'unknown driver';
    let driverIsDeleted = driverRef.type === 'DeletedSubDriver';

    const timeRange = this.timeRangeStack.pop();
    let parentDimDriver: DimensionalDriver | undefined;
    let attributeFilters: AttributeFilters | undefined;
    let chunkDriverId =
      driverRef.type === 'Driver' ? driverRef.driverId : driverRef.parentDimDriverId;

    if (driverRef.type === 'Driver') {
      const { driverId } = driverRef;
      let driverName = this.evaluationContext.getDriverName(driverId);

      driverIsDeleted = driverName == null;
      if (driverIsDeleted) {
        driverName = this.evaluationContext.getDeletedIdentifier(driverId);
        if (driverName != null) {
          this.error = `Formula contains deleted dependency "${driverName}"`;
        } else {
          this.error = `Formula contains unknown dependency`;
        }
      }

      displayName = driverName ?? displayName;
      parentDimDriver = this.evaluationContext.getParentDimensionalDriver(driverId);
      if (parentDimDriver != null) {
        chunkDriverId = parentDimDriver.id;
        const subDriver = getSubDriverBySubDriverId(parentDimDriver, driverId);
        displayName = parentDimDriver.name;
        if (subDriver != null) {
          attributeFilters = attributeFilterForSubDriver(parentDimDriver, subDriver);
        } else {
          throw Error('Should find parent driver');
        }
      }
    } else {
      const { parentDimDriverId, attributeIds } = driverRef;
      displayName = this.evaluationContext.getDriverName(parentDimDriverId) ?? displayName;
      this.error = `Formula contains deleted dependency "${displayName} subdriver"`;
      const attributes = attributeIds.map((attrId) => this.evaluationContext.getAttribute(attrId));
      attributeFilters = {
        byDimId: Object.fromEntries(
          attributes.filter(isNotNull).map((attr) => [attr.dimensionId, [attr]]),
        ),
      };
    }

    // Ignore missing attributes for cohort built in dimensions because we may
    // have not actually made those attributes yet
    let hasDeletedAttr = false;
    hasDeletedAttr = Object.values(attributeFilters?.byDimId ?? {})
      .flat()
      .filter(isAttribute)
      .some((filter) => {
        // Can't really delete built in dimensions
        if (
          filter.dimensionId === BuiltInDimensionType.CalendarTime ||
          filter.dimensionId === BuiltInDimensionType.RelativeTime
        ) {
          return false;
        }
        const dim = this.evaluationContext.getDimension(filter.dimensionId);
        if (dim == null) {
          this.error = 'References attribute for unknown dimension';
          return true;
        } else if (filter.deleted || dim.deleted) {
          this.error = `References deleted attribute "${filter.value}" for dimension "${dim.name}"`;
          return true;
        }
        return false;
      });

    return {
      displayName,
      ...timeRange,
      driverId: chunkDriverId,
      type: FormulaDisplayChunkType.Driver,
      attributeFilters,
      error: driverIsDeleted || hasDeletedAttr ? this.error : undefined,
    };
  }

  private getAttributeFiltersFromAttributeIdFilters(
    attributeIdFilters: AttributeIdFilter[],
    includeAllContextAttributes: boolean,
  ): {
    attributeFilters: AttributeFilters;
    error: string | undefined;
  } {
    const attributeFilters: AttributeFilters = getEmptyAttributeFilters();
    let error: string | undefined;

    attributeIdFilters.forEach((filter) => {
      let hasDeletedDim = false;
      const { dimId } = filter;
      const dim = this.evaluationContext.getDimension(dimId);
      if (dim == null || dim.deleted) {
        hasDeletedDim = true;
        error = `References deleted dimension`;
        if (dim != null) {
          error += ` "${dim.name}"`;
        }
      }

      const attrId = filter.attrId;
      attributeFilters.byDimId[dimId] ??= [];
      if (!isString(attrId)) {
        if (attrId.type === COHORT_MONTH) {
          error = `References cohort month`;
        } else {
          attributeFilters.byDimId[dimId].push(attrId.type);
        }
      } else {
        const attr = this.evaluationContext.getAttribute(attrId);
        if (attr != null) {
          attributeFilters.byDimId[dimId].push(attr);
          if (attr.deleted && !hasDeletedDim) {
            if (dim == null) {
              error = 'References attribute for unknown dimension';
            } else {
              error = `References deleted attribute "${attr.value}" for dimension "${dim.name}"`;
            }
          }
        }
      }
    });

    if (includeAllContextAttributes) {
      attributeFilters.includeAllContextAttributes = true;
    }

    return { attributeFilters, error };
  }

  private getNegativeDriverRef(formula: FormulaDisplay): DriverId | undefined {
    if (
      formula.chunks.length === 2 &&
      formula.chunks[0].type === FormulaDisplayChunkType.Operation &&
      formula.chunks[0].text === '-' &&
      formula.chunks[1].type === FormulaDisplayChunkType.Driver
    ) {
      const attributeFilters = formula.chunks[1].attributeFilters;
      const chunkDriverId = formula.chunks[1].driverId;
      if (attributeFilters == null) {
        return chunkDriverId;
      }
      const attributes = Object.values(attributeFilters.byDimId)
        .flatMap((a) => a.map((f) => (isObject(f) && 'id' in f ? f.id : undefined)))
        .filter(isNotNull);
      const subdriverId = this.evaluationContext.getSubDriverId(chunkDriverId, attributes);
      return subdriverId;
    }
    return undefined;
  }

  private getVariableTimeRangeDisplay(start: FormulaDisplay, end: FormulaDisplay) {
    const timeRangeStart = this.chunksToString(start);
    const timeRangeEnd = this.chunksToString(end);
    const suffix = 'month offset';
    if (timeRangeStart === '' || timeRangeStart === timeRangeEnd) {
      return `(${timeRangeEnd}) ${suffix}`;
    } else if (timeRangeEnd === '') {
      this.isEditingSupported = false;
      return `(${timeRangeStart}) ${suffix}`;
    }

    this.isEditingSupported = false;
    return `${timeRangeStart} to ${timeRangeEnd} ${suffix}`;
  }

  private toNumberFragment(fragment: FormulaDisplay) {
    const text = this.chunksToString(fragment);
    return Number.isNaN(Number(text)) ? null : Number(text);
  }

  private getAttributeFilterError(filters: ObjectFilterDisplayChunk[]) {
    const attrFilters = filters.filter((f) => f.valueType === ValueType.Attribute);
    let error: string | undefined;

    attrFilters.some((attrFilter) => {
      const firstNullOrDeletedAttrId = attrFilter.attributeIds?.find((id) => {
        const attr = this.evaluationContext.getAttribute(id);
        return attr == null || attr.deleted;
      });
      if (firstNullOrDeletedAttrId != null) {
        const attr = this.evaluationContext.getAttribute(firstNullOrDeletedAttrId);
        error = 'Database filter references deleted attribute';
        if (attr != null) {
          error += ` "${attr.value}"`;
          const dim = this.evaluationContext.getDimension(attr.dimensionId);
          if (dim != null) {
            error += ` of${dim.deleted ? ' deleted ' : ' '}dimension "${dim.name}"`;
          }
        }
        return true;
      }

      return false;
    });

    return error;
  }

  private getAndClearIncludeAllContextAttributes() {
    const includeAllContextAttributes = this.includeAllContextAttributes;
    this.includeAllContextAttributes = false;
    return includeAllContextAttributes;
  }

  private getAndClearObjectFilterStack() {
    const objectFilterStack = this.objectFilterStack;
    this.objectFilterStack = [];
    return objectFilterStack;
  }
}
