import { Flex, Text } from '@chakra-ui/react';
import Joi from 'joi';
import { useCallback, useContext, useMemo } from 'react';

import { Tooltip } from 'chakra/tooltip';
import EscapableInput from 'components/EscapableInput/EscapableInput';
import { ICON_BORDER_RADIUS, LIGHT_GRAY } from 'components/RunwayApp/GlobalNavigationMenu';
import { SCENARIO_BUTTON_HEIGHT } from 'components/ScenarioManagement/ScenarioManagement';
import { ScenarioContext } from 'components/ScenarioManagement/ScenariosContextProvider';
import { joiDefault } from 'helpers/errorMessages';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useIsTruncated from 'hooks/useIsTruncated';
import { updateCurrentLayerDescription } from 'reduxStore/actions/layerMutations';
import { canUserRenameCurrentLayerSelector } from 'selectors/layerAccessResourcesSelector';
import {
  currentLayerDescriptionSelector,
  currentLayerIdSelector,
  currentLayerIsDraftSelector,
} from 'selectors/layerSelector';

const DEFAULT_DESCRIPTION = 'Description';

const ScenarioDescription: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isEditingCurrentLayerDescription, setIsEditingCurrentLayerDescription } =
    useContext(ScenarioContext);
  const { ref: textRef, isTruncated } = useIsTruncated();

  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const currentLayerDescription = useAppSelector(currentLayerDescriptionSelector);
  const currentLayerIsDraft = useAppSelector(currentLayerIsDraftSelector);
  const canRenameCurrentLayer = useAppSelector(canUserRenameCurrentLayerSelector);

  const reset = useCallback(() => {
    setIsEditingCurrentLayerDescription.off();
  }, [setIsEditingCurrentLayerDescription]);

  const onSaveDescriptionCallback = useCallback(
    (newLayerDescription: string): void => {
      dispatch(updateCurrentLayerDescription(newLayerDescription));
      reset();
    },
    [dispatch, reset],
  );

  const handleClick = () => {
    if (!canRenameCurrentLayer || isEditingCurrentLayerDescription) {
      return;
    }
    setIsEditingCurrentLayerDescription.on();
  };

  const descriptionValidator = useMemo(() => {
    return Joi.object({
      layerDescription: Joi.string()
        .insensitive()
        .min(0)
        .max(1000)
        .trim()
        .messages(joiDefault('scenario description')),
    });
  }, []);

  const currentDescriptionDisplayText =
    currentLayerDescription != null && currentLayerDescription !== ''
      ? currentLayerDescription
      : DEFAULT_DESCRIPTION;

  // We only show the description container if the description is non-empty, the user is editing
  // the description, or the user is hovering over it
  const opacity =
    isEditingCurrentLayerDescription ||
    (currentLayerDescription != null && currentLayerDescription.length > 0)
      ? 1
      : 0;

  return (
    <Flex
      onClick={handleClick}
      borderRadius={ICON_BORDER_RADIUS}
      height={SCENARIO_BUTTON_HEIGHT}
      alignItems="center"
      px="6px"
      color={!currentLayerIsDraft || isEditingCurrentLayerDescription ? 'gray.500' : '#E8AA30'}
      opacity={opacity}
      _hover={{
        bgColor: isEditingCurrentLayerDescription ? 'surface' : LIGHT_GRAY,
        cursor: 'pointer',
        opacity: 1,
      }}
      sx={{
        bgColor: isEditingCurrentLayerDescription ? 'surface' : 'inherit',
      }}
    >
      {isEditingCurrentLayerDescription ? (
        <EscapableInput
          key={currentLayerId}
          autoSizing
          validationSchema={descriptionValidator}
          onSave={onSaveDescriptionCallback}
          onCancel={reset}
          isRequired
          name="layerDescription"
          variant="inline"
          placeholder="Description"
          defaultValue={currentLayerDescription ?? ''}
          autoFocus
          fontSize="xs"
          fontWeight="medium"
          borderWidth={0}
          height={SCENARIO_BUTTON_HEIGHT}
          maxWidth="400px"
          isReadOnly={!canRenameCurrentLayer}
          color="gray.600"
        />
      ) : (
        <Tooltip label={isTruncated ? currentDescriptionDisplayText : undefined}>
          <Text
            lineHeight="initial"
            p={0}
            fontSize="xs"
            fontWeight="medium"
            noOfLines={1}
            ref={textRef}
          >
            {currentDescriptionDisplayText}
          </Text>
        </Tooltip>
      )}
    </Flex>
  );
};

export default ScenarioDescription;
