import { createSelector } from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';

import { LinkedAccount } from 'generated/graphql';
import { ExtSource, getExtSource } from 'helpers/integrations';
import { EntityTable } from 'reduxStore/models/common';
import { IntegrationQuery, IntegrationQueryId } from 'reduxStore/models/integrationQueries';
import { paramSelector } from 'selectors/constSelectors';
import { defaultLayerSelector } from 'selectors/layerSelector';
import { ParametricSelector } from 'types/redux';

const EMPTY_ENTITY_TABLE: EntityTable<IntegrationQuery> = { byId: {}, allIds: [] };

const integrationQueriesEntityTableSelector = createSelector(
  defaultLayerSelector,
  (layer) => layer?.integrationQueries ?? EMPTY_ENTITY_TABLE,
);

export const integrationQueriesByIdSelector = createSelector(
  integrationQueriesEntityTableSelector,
  (entityTable) => entityTable.byId,
);

export const integrationQueriesForLinkedAccount: ParametricSelector<
  LinkedAccount | undefined,
  IntegrationQuery[]
> = createSelector(
  integrationQueriesEntityTableSelector,
  paramSelector<LinkedAccount | undefined>(),
  ({ byId }, linkedAccount) => {
    if (linkedAccount == null) {
      return [];
    }

    const source = getExtSource(linkedAccount.integration);
    return Object.values(byId).filter((query) => {
      if (query.source !== source) {
        return false;
      }

      if (query.linkedAccountId === linkedAccount.id) {
        return true;
      }

      // Older queries won't have a linkedAccountId, so we'll revert to the old behavior of showing
      // those queries whenever the source matches.
      if (query.linkedAccountId == null || query.linkedAccountId === '') {
        return true;
      }

      return false;
    });
  },
);

export const integrationQueriesBySourceSelector = createSelector(
  integrationQueriesByIdSelector,
  (queriesById) => {
    const result: Partial<Record<ExtSource, IntegrationQuery[]>> = {};
    for (const query of Object.values(queriesById)) {
      const queriesForSource = result[query.source] ?? [];
      queriesForSource.push(query);
      result[query.source] = queriesForSource;
    }
    return result;
  },
);

export const integrationQueryIdsForSourceSelector: ParametricSelector<
  ExtSource | undefined,
  string[]
> = createSelector(
  integrationQueriesEntityTableSelector,
  paramSelector<ExtSource | undefined>(),
  ({ byId }, source) => {
    return Object.values(byId)
      .filter((query) => source === undefined || query.source === source)
      .map((query) => query.id);
  },
);

export const integrationQuerySelector: ParametricSelector<string, IntegrationQuery> =
  createSelector(
    integrationQueriesByIdSelector,
    paramSelector<IntegrationQueryId>(),
    (queriesById, queryId) => queriesById[queryId],
  );

export const integrationQueryNameByIdSelector = createSelector(
  integrationQueriesByIdSelector,
  (queriesById) => mapValues(queriesById, 'name'),
);
