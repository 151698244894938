import { snakeCase } from 'lodash';
import React, { useContext } from 'react';

import DriverRowContextProvider from 'components/DriverRowContextProvider/DriverRowContextProvider';
import DriverTimeSeriesRow from 'components/DriverTimeSeriesRow/DriverTimeSeriesRow';
import RowContainer from 'components/Table/RowContainer';
import { ListItemContext } from 'components/VirtualizedList/listItemContext';
import { ComparisonColumn, ComparisonTimePeriod } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { isDataVisibleSelector } from 'selectors/isDataVisibleSelector';

import { DriverTableRowColumns } from './DriverTableRowColumns';
interface Props {
  driverId: DriverId;
  comparisonRowLayerId?: string;
  groupId?: DriverGroupId;
  isLast?: boolean;
  isFirst?: boolean;
  comparisonType?: ComparisonColumn;
  comparisonTimePeriod?: ComparisonTimePeriod;
}

const DriverTableRow: React.FC<Props> = ({
  driverId,
  comparisonRowLayerId,
  groupId,
  isFirst: givenIsFirst,
  isLast: givenIsLast,
  comparisonType,
  comparisonTimePeriod,
}) => {
  const { blockId } = useBlockContext();
  const isDataVisible = useAppSelector((state) => isDataVisibleSelector(state, blockId));
  const { index, numItems } = useContext(ListItemContext);
  const isFirst = givenIsFirst ?? index === 0;
  const isLast = givenIsLast ?? index === numItems - 1;
  const testId = comparisonType != null ? `driver-row-${snakeCase(comparisonType)}` : 'driver-row';
  return (
    <DriverRowContextProvider
      driverId={driverId}
      groupId={groupId}
      comparisonRowLayerId={comparisonRowLayerId}
      comparisonType={comparisonType}
      comparisonTimePeriod={comparisonTimePeriod}
    >
      <RowContainer testId={testId} isFirstRow={isFirst}>
        <DriverTableRowColumns isLast={isLast} />
        {isDataVisible && <DriverTimeSeriesRow />}
      </RowContainer>
    </DriverRowContextProvider>
  );
};

export default React.memo(DriverTableRow);
