import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import ExtSourceImage from 'components/BusinessObjectTable/ExtSourceImage';
import { Integration } from 'generated/graphql';
import { ExtSource } from 'helpers/integrations';
import useAppSelector from 'hooks/useAppSelector';
import { availableIntegrationsByExtSourceSelector } from 'selectors/integrationsSelector';

interface Props {
  extSource?: ExtSource;
}

const ExtSourceTooltip: React.FC<Props> = ({ extSource }) => {
  const integration: Integration | undefined = useAppSelector((state) => {
    if (extSource == null) {
      return undefined;
    }
    return availableIntegrationsByExtSourceSelector(state).get(extSource);
  });

  if (integration == null) {
    return null;
  }

  return (
    <Flex alignItems="center">
      <Text mr={1}> Imported from {integration.name}</Text>
      <ExtSourceImage sourceSlug={integration.slug} />
    </Flex>
  );
};

export default React.memo(ExtSourceTooltip);
