import partition from 'lodash/partition';
import uniq from 'lodash/uniq';

import { CellType, DriverRowKey } from 'config/cells';
import {
  getCellRefDriverId,
  isBusinessObjectFieldCellRef,
  isBusinessObjectFieldSpecColumnKey,
  isBusinessObjectFieldTimeSeriesCellRef,
  isBusinessObjectPropertyFieldCellRef,
  isDriverCellRef,
  isDriverDataCellRef,
  isExtDriverCellRef,
  isMonthColumnKey,
  isObjectFieldNameOrOptionsColumnKey,
} from 'helpers/cells';
import { isNotNull } from 'helpers/typescript';
import {
  deleteBusinessObjectFieldInitialValues,
  deleteBusinessObjectFieldValues,
  deleteSelectedBusinessObjects,
  updateBusinessObjects,
} from 'reduxStore/actions/businessObjectMutations';
import { deleteBusinessObjectCell } from 'reduxStore/actions/deleteSelected/deleteBusinessObjectCell';
import {
  clearActualsFormulas,
  clearForecastFormulas,
  updateDriverActuals,
} from 'reduxStore/actions/driverMutations';
import { removeDriversFromBlock } from 'reduxStore/actions/driverReferenceMutations';
import { deleteEventIds, deleteEventsAndGroups } from 'reduxStore/actions/eventMutations';
import { closeDetailPane } from 'reduxStore/actions/navigateTo';
import { roadmapCellDeleteSelected } from 'reduxStore/actions/timelineCells';
import { DEFAULT_EVENT_GROUP_ID } from 'reduxStore/models/events';
import { openDeleteDriverDialog } from 'reduxStore/reducers/alertDialogSlice';
import { openDeleteExtDriversDialog } from 'reduxStore/reducers/dataSourcesSlice';
import { ModalType } from 'reduxStore/reducers/pageSlice';
import { AppThunk } from 'reduxStore/store';
import { businessObjectSelector } from 'selectors/businessObjectsSelector';
import { openDetailPaneSelector } from 'selectors/detailPaneSelectors';
import { eventsImpactingEventEntityAndMonthKeySelector } from 'selectors/eventsAndGroupsSelector';
import { hasExistingEntitySelectedSelector } from 'selectors/hasExistingEntitySelectedSelector';
import { datasetLastActualsMonthKeySelector } from 'selectors/lastActualsSelector';
import { isCurrentPageWritableSelector } from 'selectors/pageAccessResourcesSelector';
import { openModalTypeSelector } from 'selectors/pageBaseSelector';
import { isViewingUnlistedDriversPageSelector } from 'selectors/pageSelector';
import { prevailingSelectedEventsAndEventGroupsIdsSelector } from 'selectors/prevailingCellSelectionSelector';
import { prevailingSelectionSelector } from 'selectors/selectionSelector';

export const deleteSelected = ({ shift = false }: { shift?: boolean } = {}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const openModalType = openModalTypeSelector(state);
    const hasExistingEntitySelected = hasExistingEntitySelectedSelector(state);
    const isViewingUnlistedDriversPage = isViewingUnlistedDriversPageSelector(state);

    if (
      !hasExistingEntitySelected ||
      (openModalType != null && openModalType !== ModalType.DetailPane)
    ) {
      return;
    }

    const selection = prevailingSelectionSelector(state);
    if (selection == null) {
      return;
    }

    const isCurrentPageWritable = isCurrentPageWritableSelector(state);
    if (!isCurrentPageWritable) {
      return;
    }

    const lastActualMonthKey = datasetLastActualsMonthKeySelector(state);
    switch (selection.type) {
      case 'eventsAndGroups': {
        if (selection.cellSelection != null) {
          dispatch(roadmapCellDeleteSelected());
          return;
        }

        const { eventIds: eventRefIds, eventGroupIds } =
          prevailingSelectedEventsAndEventGroupsIdsSelector(state);
        const eventGroupRefIds = eventGroupIds.filter((id) => id !== DEFAULT_EVENT_GROUP_ID);
        if (eventRefIds.length > 0 || eventGroupRefIds.length > 0) {
          dispatch(
            deleteEventsAndGroups({
              events: eventRefIds.map((id) => ({ id })),
              groups: eventGroupRefIds.map((id) => ({ id })),
            }),
          );
        }

        // if plan detail pane for deleted plan is open, close it
        const detailPane = openDetailPaneSelector(state);
        if (detailPane?.type === 'plan' && eventGroupRefIds.includes(detailPane.eventGroupId)) {
          dispatch(closeDetailPane());
        }

        break;
      }
      case 'cell': {
        const { activeCell } = selection;
        // some cells (e.g. transition values) should not be cleared
        const selectedCells = selection.selectedCells.filter((cell) => !cell.disableClearing);
        if (activeCell.type === CellType.Driver) {
          const { columnKey } = activeCell;
          const driverId = getCellRefDriverId(activeCell);
          if (driverId == null) {
            return;
          }
          if (isMonthColumnKey(columnKey)) {
            const selectedDataCells = selectedCells.filter(isDriverDataCellRef);

            const [selectedActualsCells, selectedForcastCells] = partition(
              selectedDataCells,
              (ref) => ref.columnKey.monthKey <= lastActualMonthKey,
            );

            const driverActualsUpdates = selectedActualsCells
              .map((cell) => {
                const actualsCellDriverId = getCellRefDriverId(cell);
                if (actualsCellDriverId == null) {
                  return null;
                }
                return {
                  id: actualsCellDriverId,
                  values: { [cell.columnKey.monthKey]: undefined },
                  newFormat: undefined,
                };
              })
              .filter(isNotNull);

            const forecastedEventUpdates = selectedForcastCells
              .flatMap((cell) => {
                const cellDriverId = getCellRefDriverId(cell);
                if (cellDriverId == null) {
                  return undefined;
                }
                const events = eventsImpactingEventEntityAndMonthKeySelector(state, {
                  type: 'driver',
                  driverId: cellDriverId,
                  monthKey: cell.columnKey.monthKey,
                });

                return events.map((event) => event.id);
              })
              .filter(isNotNull);

            if (driverActualsUpdates.length !== 0) {
              dispatch(updateDriverActuals(driverActualsUpdates));
            }

            if (forecastedEventUpdates.length !== 0) {
              dispatch(deleteEventIds(forecastedEventUpdates));
            }
          } else {
            const { columnType } = columnKey;
            const selectedDriverCells = selectedCells.filter(isDriverCellRef);
            const driverIds = uniq(
              selectedDriverCells.map((ref) => ref.rowKey.driverId).filter(isNotNull),
            );

            const subdriverIds = selectedCells
              .map((cell) => (cell.rowKey as DriverRowKey).subDriverId)
              .filter(isNotNull);

            switch (columnType) {
              case 'name': {
                if (selection.blockId != null) {
                  if (shift || isViewingUnlistedDriversPage) {
                    dispatch(openDeleteDriverDialog(driverIds));
                  } else {
                    dispatch(removeDriversFromBlock({ blockId: selection.blockId, driverIds }));
                  }
                }
                break;
              }
              case 'formula': {
                dispatch(clearForecastFormulas({ driverIds }));
                break;
              }
              case 'actualsFormula': {
                dispatch(clearActualsFormulas({ driverIds }));
                break;
              }
              default: {
                if (subdriverIds.length > 0) {
                  dispatch(clearActualsFormulas({ driverIds: subdriverIds }));
                }
                break;
              }
            }
          }
        } else if (activeCell.type === CellType.ObjectField) {
          if (activeCell.rowKey.objectId == null) {
            return;
          }
          if (isObjectFieldNameOrOptionsColumnKey(activeCell.columnKey)) {
            const selectedNameCells = selectedCells.filter((c) =>
              isObjectFieldNameOrOptionsColumnKey(c.columnKey),
            );
            const canDeleteAll = selectedNameCells.length === selectedCells.length;
            if (canDeleteAll) {
              const selectedObjectIds = selectedNameCells
                .map((c) => (isBusinessObjectFieldCellRef(c) ? c.rowKey.objectId : null))
                .filter(isNotNull);
              dispatch(deleteSelectedBusinessObjects(selectedObjectIds));
            }
          } else if (isMonthColumnKey(activeCell.columnKey)) {
            const selectedTimeSeriesCells = selectedCells
              .filter(isBusinessObjectPropertyFieldCellRef)
              .filter((ref) => isMonthColumnKey(ref.columnKey));

            const actualsUpdates = selectedTimeSeriesCells
              .map((cell) => {
                if (
                  isMonthColumnKey(cell.columnKey) &&
                  cell.columnKey.monthKey <= lastActualMonthKey &&
                  'objectFieldSpecId' in cell.columnKey &&
                  cell.rowKey.objectId != null
                ) {
                  return {
                    objectId: cell.rowKey.objectId,
                    fieldSpecId: cell.columnKey.objectFieldSpecId,
                    monthKey: cell.columnKey.monthKey,
                  };
                }

                return null;
              })
              .filter(isNotNull);

            const forecastedEventUpdates = selectedTimeSeriesCells
              .flatMap((cell) => {
                const monthKey = isMonthColumnKey(cell.columnKey) ? cell.columnKey.monthKey : null;
                const businessObjectId = cell.rowKey.objectId;

                if (
                  monthKey == null ||
                  monthKey <= lastActualMonthKey ||
                  !('objectFieldSpecId' in cell.columnKey) ||
                  businessObjectId == null
                ) {
                  return null;
                }

                const businessObject = businessObjectSelector(state, businessObjectId);

                const fieldId = businessObject?.fields.find(
                  (f) => f.fieldSpecId === cell.columnKey.objectFieldSpecId,
                )?.id;
                if (fieldId == null) {
                  return null;
                }

                const events = eventsImpactingEventEntityAndMonthKeySelector(state, {
                  type: 'objectField',
                  objectId: businessObjectId,
                  objectFieldId: fieldId,
                  fieldSpecId: cell.columnKey.objectFieldSpecId,
                  monthKey,
                });

                return events.map((event) => event.id);
              })
              .filter(isNotNull);

            // TODO: these should be consolidated into one action
            if (actualsUpdates.length > 0) {
              dispatch(deleteBusinessObjectFieldValues(actualsUpdates));
            }

            if (forecastedEventUpdates.length > 0) {
              dispatch(deleteEventIds(forecastedEventUpdates));
            }
          } else if (isBusinessObjectFieldSpecColumnKey(activeCell.columnKey)) {
            const selectedFieldCells = selectedCells
              .filter(isBusinessObjectPropertyFieldCellRef)
              .filter((ref) => !isMonthColumnKey(ref.columnKey));
            const canDeleteAll = selectedFieldCells.length === selectedCells.length;

            const activeCellBusinessObjectId = activeCell.rowKey.objectId;
            const activeCellBusinessObject = businessObjectSelector(
              state,
              activeCellBusinessObjectId,
            );

            if (canDeleteAll && activeCellBusinessObject != null) {
              const updates = deleteBusinessObjectCell(state, selectedFieldCells);
              if (updates.length > 0) {
                dispatch(updateBusinessObjects(updates));
              }
            }
          }
        } else if (activeCell.type === CellType.ObjectFieldTimeSeries) {
          const { rowKey, columnKey } = activeCell;
          if (isMonthColumnKey(columnKey)) {
            const selectedDataCells = selectedCells.filter(isBusinessObjectFieldTimeSeriesCellRef);

            const canDeleteAll =
              selectedDataCells.length === selectedCells.length &&
              selectedDataCells.every(
                (ref) =>
                  isMonthColumnKey(ref.columnKey) && ref.columnKey.monthKey <= lastActualMonthKey,
              );
            if (canDeleteAll) {
              const updates = selectedDataCells
                .map((cell) => {
                  if (isMonthColumnKey(cell.columnKey) && cell.rowKey.fieldSpecId != null) {
                    return {
                      objectId: cell.rowKey.objectId,
                      fieldSpecId: cell.rowKey.fieldSpecId,
                      monthKey: cell.columnKey.monthKey,
                    };
                  }
                  return null;
                })
                .filter(isNotNull);
              dispatch(deleteBusinessObjectFieldValues(updates));
            }

            const businessObjectId = activeCell.rowKey.objectId;
            const businessObject = businessObjectSelector(state, businessObjectId);
            const forecastedEventUpdates = selectedDataCells
              .flatMap((cell) => {
                const monthKey = isMonthColumnKey(cell.columnKey) ? cell.columnKey.monthKey : null;
                if (
                  monthKey == null ||
                  monthKey <= lastActualMonthKey ||
                  cell.rowKey.fieldSpecId == null
                ) {
                  return null;
                }

                const fieldId = businessObject?.fields.find(
                  (f) => f.fieldSpecId === cell.rowKey.fieldSpecId,
                )?.id;
                if (fieldId == null) {
                  return null;
                }

                const events = eventsImpactingEventEntityAndMonthKeySelector(state, {
                  type: 'objectField',
                  objectId: businessObjectId,
                  objectFieldId: fieldId,
                  fieldSpecId: cell.rowKey.fieldSpecId,
                  monthKey,
                });

                return events.map((event) => event.id);
              })
              .filter(isNotNull);

            if (forecastedEventUpdates.length > 0) {
              dispatch(deleteEventIds(forecastedEventUpdates));
            }
          } else {
            switch (columnKey.columnType) {
              case 'initialValue': {
                if (rowKey.fieldSpecId != null) {
                  dispatch(
                    deleteBusinessObjectFieldInitialValues({
                      objectId: rowKey.objectId,
                      fieldSpecId: rowKey.fieldSpecId,
                    }),
                  );
                }
                break;
              }
              default: {
                break;
              }
            }
          }
        } else if (activeCell.type === CellType.ExtDriver) {
          const { columnKey } = activeCell;
          if (!isMonthColumnKey(columnKey)) {
            const { columnType } = columnKey;
            const selectedDriverCells = selectedCells.filter(isExtDriverCellRef);
            const extDriverIds = uniq(
              selectedDriverCells.map((ref) => ref.rowKey.extDriverId).filter(isNotNull),
            );
            switch (columnType) {
              case 'name': {
                dispatch(openDeleteExtDriversDialog({ extDriverIdsToDelete: extDriverIds }));
                break;
              }
              default:
                break;
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };
};
