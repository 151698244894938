import React, { ReactNode, useContext, useState } from 'react';

import { Driver } from 'reduxStore/models/drivers';

const FocusedDriverContext = React.createContext<{
  focusedDriver: Driver | null;
  setFocusedDriver: (driver: Driver | null) => void;
}>({
  focusedDriver: null,
  setFocusedDriver: () => {},
});

export const FocusedDriverProvider = ({ children }: { children: ReactNode }) => {
  const [focusedDriver, setFocusedDriver] = useState<Driver | null>(null);
  const context = { focusedDriver, setFocusedDriver };

  return <FocusedDriverContext.Provider value={context}>{children}</FocusedDriverContext.Provider>;
};

export function useFocusedDriver() {
  return useContext(FocusedDriverContext);
}
