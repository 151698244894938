import { Draft } from '@reduxjs/toolkit';

import {
  CollectionEntryUpdateInput,
  CollectionInput,
  DimensionalPropertyCreateInput,
  DriverType,
  Collection as GQLCollection,
  CollectionEntry as GQLCollectionEntry,
  Maybe,
  Scalars,
} from 'generated/graphql';
import { isNotNull } from 'helpers/typescript';
import {
  AttributeProperty,
  Collection,
  CollectionEntry,
  DimensionalProperty,
  DriverProperty,
} from 'reduxStore/models/collections';
import { Attribute, DimensionId, NormalizedDimension } from 'reduxStore/models/dimensions';
import { DriverId, NormalizedDriver } from 'reduxStore/models/drivers';
import { DefaultLayer, LightLayer } from 'reduxStore/models/layers';

export function updateCollectionFromInput(
  layer: Draft<LightLayer>,
  defaultLayer: Draft<DefaultLayer>,
  update: CollectionInput,
  existingCollection?: Collection,
): Collection {
  const collection = existingCollection ?? {
    dimensionalProperties: [],
    driverProperties: [],
  };
  const dimById = defaultLayer.dimensions.byId;

  const {
    addDimensionalProperties,
    updateDimensionalProperties,
    removeDimensionalProperties,
    addDriverProperties,
    removeDriverProperties,
  } = update;

  if (addDimensionalProperties != null) {
    collection.dimensionalProperties.push(
      ...mapDimensionalProperties(addDimensionalProperties, dimById),
    );
  }

  if (updateDimensionalProperties != null) {
    for (const updateProp of updateDimensionalProperties) {
      const prop = collection.dimensionalProperties.find((p) => p.id === updateProp.id);
      if (prop == null) {
        continue;
      }
      if (updateProp.name != null) {
        prop.name = updateProp.name;
      }
      if (updateProp.dimensionId != null) {
        const dimension = dimById[updateProp.dimensionId];
        if (dimension == null) {
          throw Error(
            `Cannot find dimension with ID ${updateProp.dimensionId} for dimensional property ${updateProp.id}`,
          );
        }
        prop.dimension = dimension;
      }
      if (updateProp.removeMapping === true) {
        prop.mapping = undefined;
      }
      if (updateProp.mapping != null) {
        prop.mapping = updateProp.mapping;
      }
      if (updateProp.extFieldSpecKey != null) {
        prop.extFieldSpecKey = updateProp.extFieldSpecKey;
      }
      if (updateProp.isDatabaseKey != null) {
        prop.isDatabaseKey = updateProp.isDatabaseKey;
      }
      if (updateProp.extQueryId != null) {
        prop.extQueryId = updateProp.extQueryId;
      }
    }
  }

  if (removeDimensionalProperties != null) {
    collection.dimensionalProperties = collection.dimensionalProperties.filter(
      (p) => !removeDimensionalProperties.includes(p.id),
    );
  }

  if (addDriverProperties != null) {
    collection.driverProperties.push(
      ...mapDriverProperties(addDriverProperties, layer.drivers.byId),
    );
  }

  if (removeDriverProperties != null) {
    collection.driverProperties = collection.driverProperties.filter(
      (p) => !removeDriverProperties.includes(p.id),
    );
  }

  return collection;
}

export function mapCollection(
  driversById: NullableRecord<DriverId, NormalizedDriver>,
  dimensionsById: NullableRecord<DimensionId, NormalizedDimension>,
  gql: GQLCollection,
): Collection {
  return {
    dimensionalProperties: gql.dimensionalProperties
      ? mapDimensionalProperties(gql.dimensionalProperties, dimensionsById, true)
      : [],
    driverProperties: gql.driverProperties
      ? mapDriverProperties(gql.driverProperties, driversById)
      : [],
  };
}

function mapDriverProperties(
  driverProperties: Array<{
    driverId: Scalars['String'];
    id: Scalars['ID'];
    extQueryId?: Maybe<string> | undefined;
  }>,
  driversById: NullableRecord<string, NormalizedDriver>,
): DriverProperty[] {
  return driverProperties
    .map((d) => {
      const driver = driversById[d.driverId];
      if (driver == null || driver.type !== DriverType.Dimensional) {
        return null;
      }
      const dimProperty: DriverProperty = {
        id: d.id,
        driverId: driver.id,
        driver,
        ...(d.extQueryId != null && { extQueryId: d.extQueryId }),
      };
      return dimProperty;
    })
    .filter(isNotNull);
}

function mapDimensionalProperties(
  dimProperties: DimensionalPropertyCreateInput[],
  dimensionsById: NullableRecord<string, NormalizedDimension>,
  defaultIsDatabaseKey?: boolean,
): DimensionalProperty[] {
  return dimProperties
    .map((d) => {
      const dimension = dimensionsById[d.dimensionId];
      // Maybe create dimension if it doesn't exist?
      if (dimension == null) {
        return null;
      }
      return {
        id: d.id,
        name: d.name,
        dimension,
        mapping: d.mapping ?? undefined,
        extFieldSpecKey: d.extFieldSpecKey ?? undefined,
        isDatabaseKey: d.isDatabaseKey ?? defaultIsDatabaseKey,
        extQueryId: d.extQueryId ?? undefined,
      } as DimensionalProperty;
    })
    .filter(isNotNull);
}

export function mapCollectionEntry(
  gql: GQLCollectionEntry,
  attributesById: NullableRecord<string, Attribute>,
): CollectionEntry {
  return {
    attributeProperties: gql.attributeProperties
      ? mapAttributeProperties(gql.attributeProperties, attributesById)
      : [],
  };
}

export function updateCollectionEntryFromInput(
  defaultLayer: Draft<DefaultLayer>,
  update: CollectionEntryUpdateInput,
  existingCollectionEntry?: CollectionEntry,
  collection?: Collection,
): CollectionEntry {
  const collectionEntry = existingCollectionEntry ?? {
    attributeProperties: [],
  };
  const attrById = defaultLayer.attributes.byId;

  const { addAttributeProperties, updateAttributeProperties, removeAttributeProperties } = update;
  const dimensionalPropertyIds =
    collection == null ? [] : collection.dimensionalProperties.map((p) => p.id);

  if (addAttributeProperties != null) {
    const validAttributeProperties = addAttributeProperties.filter((p) =>
      dimensionalPropertyIds.includes(p.dimensionalPropertyId),
    );
    collectionEntry.attributeProperties.push(
      ...mapAttributeProperties(validAttributeProperties, attrById),
    );
  }

  if (updateAttributeProperties != null) {
    const validAttributeProperties = updateAttributeProperties.filter((p) =>
      dimensionalPropertyIds.includes(p.dimensionalPropertyId),
    );
    for (const updateProp of validAttributeProperties) {
      const prop = collectionEntry.attributeProperties.find(
        (p) => p.dimensionalPropertyId === updateProp.dimensionalPropertyId,
      );
      if (prop == null) {
        continue;
      }
      if (updateProp.attributeId != null) {
        const attribute = attrById[updateProp.attributeId];
        if (attribute == null) {
          continue;
        }
        prop.attribute = attribute;
      }
    }
  }

  if (removeAttributeProperties != null) {
    collectionEntry.attributeProperties = collectionEntry.attributeProperties.filter(
      (p) => !removeAttributeProperties.includes(p.dimensionalPropertyId),
    );
  }

  return collectionEntry;
}

function mapAttributeProperties(
  attributeProperties: Array<{
    attributeId: Scalars['String'];
    dimensionalPropertyId: Scalars['String'];
  }>,
  attributesById: NullableRecord<string, Attribute>,
): AttributeProperty[] {
  return attributeProperties
    .map((a) => {
      const attribute = attributesById[a.attributeId];
      // Maybe create attribute if it doesn't exist?
      if (attribute == null) {
        return null;
      }
      return {
        dimensionalPropertyId: a.dimensionalPropertyId,
        attribute,
      };
    })
    .filter(isNotNull);
}
