import { Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import ColumnResizer from 'components/ColumnResizer/ColumnResizer';
import TableHeaderCell from 'components/Table/TableHeaderCell';
import { BlockHeaderCellRef, COLUMN_HEADER_CELL_HEIGHT_PX, CellType } from 'config/cells';
import { ModelViewColumnType, NAME_COLUMN_TYPE } from 'config/modelView';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { isComparingTimePeriodsSelector } from 'selectors/comparisonTimePeriodsSelector';
import { isComparingLayersSelector } from 'selectors/scenarioComparisonSelector';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';
import { MonthKey } from 'types/datetime';

interface Props {
  isData?: boolean;
  onClick?: React.MouseEventHandler;
  columnType: ModelViewColumnType | MonthKey;
  label: string;
  groupId?: string;
}

const BlockHeaderCell: React.FC<Props> = ({
  label,
  columnType,
  isData = false,
  onClick,
  groupId,
}) => {
  const { blockId } = useBlockContext();

  const isComparingLayers = useAppSelector((state) => isComparingLayersSelector(state, blockId));
  const isComparingTimePeriods = useAppSelector((state) =>
    isComparingTimePeriodsSelector(state, blockId),
  );
  const isComparisonLayout = isComparingLayers || isComparingTimePeriods;

  const width = useAppSelector((state) => columnWidthSelector(state, { blockId, columnType }));
  const cellRef = useMemo<BlockHeaderCellRef>(() => {
    return {
      type: CellType.BlockHeaderCell,
      rowKey: { groupId },
      columnKey: { columnType },
    };
  }, [columnType, groupId]);

  const isNameColumn = columnType === NAME_COLUMN_TYPE;
  const height = isNameColumn ? 'inherit' : toPxString(COLUMN_HEADER_CELL_HEIGHT_PX);
  return (
    <CellContextProvider cellRef={cellRef}>
      <TableHeaderCell height={height} isData={isData} width={toPxString(width)} onClick={onClick}>
        <Text
          noOfLines={1}
          whiteSpace="nowrap"
          fontWeight="medium"
          color="gray.500"
          fontSize={isComparisonLayout ? 'xxs' : 'xs'}
        >
          {label}
        </Text>
        <ColumnResizer columnType={columnType} />
      </TableHeaderCell>
    </CellContextProvider>
  );
};

export default BlockHeaderCell;
