import { Box, Flex, Heading, Wrap, WrapItem } from '@chakra-ui/react';
import { uniq } from 'lodash';
import React, { useMemo } from 'react';

import AgComboChart from 'components/AgGridComponents/AgChart/AgComboChart';
import DriverChartCardHeader from 'components/DriverChartCardHeader/DriverChartCardHeader';
import DriverCharts from 'components/DriverCharts/DriverCharts';
import { ALL_COMPARISON_LAYER_COLORS } from 'config/scenarioComparison';
import {
  ChartAxis,
  ChartAxisType,
  ChartDisplay,
  ChartElementPosition,
  ChartGroup,
  ChartGroupingType,
  ChartPlotByType,
  ChartRollupType,
  ChartSeries,
  ChartSeriesType,
  ChartSize,
} from 'generated/graphql';
import { toPxString } from 'helpers/styles';
import { uuidv4 } from 'helpers/uuidv4';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useRequestDriverGridDataView } from 'hooks/useRequestDriverGridDataView';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { DRIVER_CHART_CARD_DIMENSIONS } from 'selectors/driverChartCardDimensionsSelector';
import { kpiTypeDriverIdsForLayerSelector } from 'selectors/driversSelector';
import { enableAgChartsSelector } from 'selectors/launchDarklySelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { GroupWithDrivers } from 'selectors/submodelTableGroupsSelector';

const deriveChartDisplay = (
  driverIds: DriverId[],
  comparisonLayerIds: LayerId[],
  options: { hasLegend: boolean } = { hasLegend: false },
): ChartDisplay => {
  const series: ChartSeries[] = [];
  const groups: ChartGroup[] = [];
  const axes: ChartAxis[] = [];

  const yAxisId = uuidv4();
  const xAxisId = uuidv4();

  for (const [index, layerId] of comparisonLayerIds.entries()) {
    for (const driverId of driverIds) {
      const seriesItem: ChartSeries = {
        id: uuidv4(),
        driverId,
        type: ChartSeriesType.Line,
        color: ALL_COMPARISON_LAYER_COLORS[index % ALL_COMPARISON_LAYER_COLORS.length],
      };
      const groupItem: ChartGroup = {
        id: uuidv4(),
        seriesIds: [seriesItem.id],
        layerId,
        isDefault: index === 0,
      };

      series.push(seriesItem);
      groups.push(groupItem);
    }
  }

  axes.push(
    {
      id: xAxisId,
      type: ChartAxisType.Time,
      position: ChartElementPosition.Bottom,
      time: {
        rollup: ChartRollupType.Monthly,
        plotBy: ChartPlotByType.Time,
      },
    },
    {
      id: yAxisId,
      type: ChartAxisType.Driver,
      position: ChartElementPosition.Left,
      driver: {
        groupIds: groups.map((g) => g.id),
      },
    },
  );

  return {
    axes,
    series,
    groups,
    ...(options.hasLegend && { legend: { showLegend: true } }),
  };
};

const KpiChart = ({
  driverIds,
  chartIndex,
  chartDisplay,
}: {
  driverIds: DriverId[];
  chartIndex: number;
  chartDisplay: ChartDisplay;
}) => {
  const { width, height } = DRIVER_CHART_CARD_DIMENSIONS[ChartSize.Medium];

  return (
    <Box width={toPxString(width)} height={toPxString(height)}>
      <Flex direction="column" h="full" w="full" py={3}>
        <DriverChartCardHeader
          showDriverDetailsButton
          disableRemoving
          showCursorValue={false}
          driverId={driverIds[chartIndex]}
        />
        <AgComboChart
          driverIds={driverIds}
          chartDisplay={chartDisplay}
          chartIndex={chartIndex}
          groupingType={ChartGroupingType.Single}
          size={ChartSize.Medium}
          stacked={false}
        />
      </Flex>
    </Box>
  );
};

export const KpiCharts = ({
  driverIds,
  hasLegend = false,
}: {
  driverIds: DriverId[];
  hasLegend?: boolean;
}) => {
  const { blockId } = useBlockContext();
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  const chartDisplay = useMemo(() => {
    return deriveChartDisplay(driverIds, uniq([currentLayerId, ...comparisonLayerIds]), {
      hasLegend,
    });
  }, [driverIds, currentLayerId, comparisonLayerIds, hasLegend]);

  return (
    <Wrap>
      {driverIds.map((driverId, index) => (
        <WrapItem key={driverId} position="relative">
          <KpiChart driverIds={driverIds} chartDisplay={chartDisplay} chartIndex={index} />
        </WrapItem>
      ))}
    </Wrap>
  );
};

const KpisComparison: React.FC = () => {
  const { blockId } = useBlockContext();
  const allKpiIds = useAppSelector(kpiTypeDriverIdsForLayerSelector);
  const enabledAgCharts = useAppSelector(enableAgChartsSelector);

  const groups: GroupWithDrivers[] = useMemo(() => {
    return [{ name: '', driverIds: allKpiIds }];
  }, [allKpiIds]);

  useRequestDriverGridDataView({
    groups,
    blockId,
    pageSize: allKpiIds.length,
  });

  return (
    <Flex flexDir="column" rowGap={4} pr={8} data-testid="kpi-comparison">
      <Heading fontSize="lg" data-intercomid="impacted-kpis">
        Impacted KPIs
      </Heading>
      {enabledAgCharts ? (
        <KpiCharts driverIds={allKpiIds} />
      ) : (
        <DriverCharts driverIds={allKpiIds} isEditable={false} showEventImpact={false} />
      )}
    </Flex>
  );
};

export default KpisComparison;
