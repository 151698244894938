import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/nextjs';
import { GraphQLError, Kind, OperationDefinitionNode } from 'graphql';
import { CombinedError, Operation } from 'urql';

import { isNotNull } from 'helpers/typescript';

class RunwayGraphQLError extends Error {
  private graphQLErrors: GraphQLError[];
  constructor(errors: GraphQLError[]) {
    const firstErr = errors.length > 0 ? errors[0] : undefined;
    const message =
      firstErr?.path != null ? `${firstErr.path.join('.')}: ${firstErr.message}` : 'GraphQL Error';
    super(message);
    this.name = 'RunwayGraphQLError';
    this.graphQLErrors = [...errors];
  }
}

function isCombinedError(err: Error | CombinedError): err is CombinedError {
  return err instanceof CombinedError;
}

function getRunwayGraphQLError(err: Error | CombinedError): RunwayGraphQLError | undefined {
  return isCombinedError(err) && err.graphQLErrors != null && err.graphQLErrors.length > 0
    ? new RunwayGraphQLError(err.graphQLErrors)
    : undefined;
}

/**
 * Logs GraphQL errors to Sentry.
 * This is not a generic logging function - only for use by errorExchange.
 */
const handleUrqlError = (error: CombinedError, operation?: Operation) => {
  const graphQLError = getRunwayGraphQLError(error);
  if (graphQLError == null) {
    return;
  }

  console.error(graphQLError);
  Sentry.withScope((scope) => {
    // Avoid default Sentry error grouping by making distinct error groups by fingerprint.
    let fingerprint: string[];
    if (operation != null) {
      const defs = operation.query.definitions
        .filter((def): def is OperationDefinitionNode => def.kind === Kind.OPERATION_DEFINITION)
        .map((def) => def.name?.value)
        .filter(isNotNull);
      fingerprint = [operation.kind, ...defs, graphQLError.message];
    } else {
      fingerprint = [graphQLError.message];
    }

    if (operation) {
      scope.setTag('kind', operation.kind);
      scope.setExtras({
        query: JSON.stringify(operation.query),
        variables: operation.variables,
      });
    }

    scope.setFingerprint(fingerprint);
    Sentry.captureException(graphQLError);
    datadogRum.addError(graphQLError);
  });
};

export default handleUrqlError;
