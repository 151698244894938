import { Draft } from '@reduxjs/toolkit';

import {
  NamedDatasetVersionCreateInput,
  NamedDatasetVersionDeleteInput,
  NamedDatasetVersionUpdateInput,
} from 'generated/graphql';
import { DatasetSliceState } from 'reduxStore/reducers/datasetSlice';
import { emptyLayer } from 'reduxStore/reducers/helpers/layers';

export function handleCreateNamedDatasetVersion(
  state: Draft<DatasetSliceState>,
  newVersionInput: NamedDatasetVersionCreateInput,
) {
  const { mutationId, name } = newVersionInput;
  if (state.layers[mutationId] != null) {
    return;
  }
  state.layers[mutationId] = emptyLayer(mutationId, name, mutationId);
}

export function handleUpdateNamedDatasetVersion(
  state: Draft<DatasetSliceState>,
  updateVersionInput: NamedDatasetVersionUpdateInput,
) {
  const { mutationId, name } = updateVersionInput;
  if (state.layers[mutationId] == null) {
    return;
  }
  state.layers[mutationId].name = name;
}

export function handleDeleteNamedDatasetVersion(
  state: Draft<DatasetSliceState>,
  { mutationId }: NamedDatasetVersionDeleteInput,
) {
  const layer = state.layers[mutationId];
  if (layer != null) {
    layer.isDeleted = true;
  }
  state.snapshots[mutationId] = null;
}
