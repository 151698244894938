import { Box } from '@chakra-ui/react';
import React from 'react';

import { Tooltip } from 'chakra/tooltip';
import ExtSourceTooltip from 'components/BusinessObjectTable/ExtSourceTooltip';
import CalcErrTooltip from 'components/CalcErrTooltip/CalcErrTooltip';
import { CellValueTooltipData } from 'config/cells';
import { CELL_TOOLTIP_OPEN_DELAY } from 'config/tooltips';

const CellValueTooltip: React.FC<CellValueTooltipData & { children: JSX.Element }> = React.memo(
  (props) => {
    const { content, children } = props;
    const { type } = content;

    switch (type) {
      case 'driver_calc_error':
        return (
          <CalcErrTooltip error={content.error} detailDriverId={content.detailDriverId}>
            {children}
          </CalcErrTooltip>
        );
      case 'ext_source':
        return (
          <Tooltip
            openDelay={CELL_TOOLTIP_OPEN_DELAY}
            placement="top"
            label={<ExtSourceTooltip extSource={content.extSource} />}
          >
            <Box w="inherit" h="full">
              {children}
            </Box>
          </Tooltip>
        );
      default:
        if (content.msg != null) {
          return (
            <Tooltip openDelay={CELL_TOOLTIP_OPEN_DELAY} placement="top" label={content.msg}>
              <Box w="inherit" h="full">
                {children}
              </Box>
            </Tooltip>
          );
        }
        return null;
    }
  },
);

const CellValueTooltipContainer: React.FC<{
  tooltipData: CellValueTooltipData | undefined;
  children: JSX.Element;
}> = ({ tooltipData, children }) => {
  if (tooltipData != null) {
    return <CellValueTooltip {...tooltipData}>{children}</CellValueTooltip>;
  }

  return children;
};

export default React.memo(CellValueTooltipContainer);
