import { createCachedSelector } from 're-reselect';

import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { PopulatedBusinessObjectSpec } from 'reduxStore/models/businessObjectSpecs';
import { businessObjectSpecsForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import { businessObjectsBySpecIdForLayerSelector } from 'selectors/businessObjectsSelector';
import { extObjectSpecsByKeyForLayerSelector } from 'selectors/extObjectSpecsSelector';

export const populatedBusinessObjectSpecsForLayerSelector: SelectorWithLayerParam<
  PopulatedBusinessObjectSpec[]
> = createCachedSelector(
  addLayerParams(extObjectSpecsByKeyForLayerSelector),
  addLayerParams(businessObjectSpecsForLayerSelector),
  addLayerParams(businessObjectsBySpecIdForLayerSelector),
  (extObjectSpecs, objSpecs, objectsBySpecId) => {
    return objSpecs.map((objSpec) => {
      const extSpecKeys = [];
      if (objSpec.extSpecKey != null) {
        extSpecKeys.push(objSpec.extSpecKey);
      }
      if (objSpec.extSpecKeys != null) {
        extSpecKeys.push(...objSpec.extSpecKeys);
      }
      return {
        extObjectSpecs: extSpecKeys
          .map((extSpecKey) => safeObjGet(extObjectSpecs[extSpecKey]))
          .filter(isNotNull),
        ...objSpec,
        objects: objectsBySpecId[objSpec.id] ?? [],
      };
    });
  },
)(getCacheKeyForLayerSelector);
