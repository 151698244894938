import { noop } from 'lodash';
import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { TimeseriesColumnValueType } from 'components/AgGridComponents/types/TimeseriesColumnDef';
import DatabaseDriverPropertySettingsPopoverContent from 'components/BusinessObjectTable/SettingsPopoverContents/DatabaseDriverPropertySettingsPopoverContent';
import DatabaseNameSettingsPopoverContent from 'components/BusinessObjectTable/SettingsPopoverContents/DatabaseNameSettingsPopoverContent';
import DatabasePropertySettingsPopoverContent from 'components/BusinessObjectTable/SettingsPopoverContents/DatabasePropertySettingsPopoverContent';
import DimensionalDatabaseNewProperty from 'components/BusinessObjectTable/SettingsPopoverContents/DimensionalDatabaseNewProperty';
import DimensionalPropertySettingsPopoverContent from 'components/BusinessObjectTable/SettingsPopoverContents/DimensionalPropertySettingsPopoverContent';
import TimeseriesColumnPropertySettingsPopoverContent from 'components/BusinessObjectTable/SettingsPopoverContents/TimeseriesColumnPropertySettingsPopoverContent';
import ContextMenu, { useContextMenu } from 'components/ContextMenu/ContextMenu';
import DatabasePropertyFormulaPopoverContents from 'components/DatabasePropertyFormulaPopoverContents/DatabasePropertyFormulaPopoverContents';
import {
  EditFormulasPopoverContent,
  useEditFormulasStateAndEventHandlers,
} from 'components/EditFormulasPopover/EditFormulasPopover';
import { ObjectContextMenuContent } from 'components/ObjectContextMenu/ObjectContextMenu';
import { FORMULA_DROPDOWN_CLASS } from 'config/formula';
import { INITIAL_VALUE_COLUMN_TYPE } from 'config/modelView';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useDatabasePropertyType } from 'hooks/useDatabasePropertyType';
import useObjectCellRef from 'hooks/useObjectCellRef';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { clearEditingPropertyFormula } from 'reduxStore/reducers/blockLocalStateSlice';
import { isEditingPropertyFormulaForColumnSelector } from 'selectors/blockLocalStateSelector';
import { driverPropertySelector } from 'selectors/collectionSelector';

interface ContextMenuContentProps {
  blockId: BlockId;
  fieldSpecId: BusinessObjectFieldSpecId;
  objectSpecId: BusinessObjectSpecId;
  objectId?: BusinessObjectId;
  columnType?: TimeseriesColumnValueType;
}

const ContextMenuContent: React.FC<ContextMenuContentProps> = React.memo(
  ({ fieldSpecId, objectSpecId, objectId, blockId, columnType }) => {
    const { closeContextMenu, isOpen } = useContextMenu();
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    const setDatabaseContextMenuIds = useContext(DatabaseContextMenuContext);

    const onClose = useCallback(() => {
      closeContextMenu?.();
      setDatabaseContextMenuIds(null);
      dispatch(clearEditingPropertyFormula());
    }, [closeContextMenu, dispatch, setDatabaseContextMenuIds]);

    useOnClickOutside(ref, onClose, { selector: `.${FORMULA_DROPDOWN_CLASS}` });

    const commonProps = useMemo(() => {
      return {
        ref,
        isAutoFocused: true,
        onClose,
        isOpen: isOpen ?? false,
      };
    }, [isOpen, onClose]);

    const isEditingFormula = useAppSelector((state) =>
      isEditingPropertyFormulaForColumnSelector(state, {
        blockId,
        columnKey: fieldSpecId,
      }),
    );

    const propertyType = useDatabasePropertyType({ fieldSpecId });

    const columnInfo =
      propertyType === 'nameProperty'
        ? 'name'
        : propertyType === 'optionsProperty'
          ? 'name'
          : propertyType === 'addNewProperty'
            ? 'addNewColumn'
            : { fieldSpecId };

    // TODO `groupKey` needs to be set correctly
    const cellRef = useObjectCellRef(objectId ?? '', columnInfo, 'none');

    const dimDriverIdForProperty = useAppSelector((state) =>
      driverPropertySelector(state, fieldSpecId),
    )?.driverId;

    if (objectId != null) {
      return <ObjectContextMenuContent cellRef={cellRef} />;
    }

    if (isEditingFormula) {
      if (dimDriverIdForProperty != null) {
        return <EditFormulasContent driverId={dimDriverIdForProperty} onClose={onClose} />;
      }
      return (
        <DatabasePropertyFormulaPopoverContents
          ref={ref}
          fieldSpecId={fieldSpecId}
          onClose={onClose}
        />
      );
    }

    switch (propertyType) {
      case 'optionsProperty':
        return null;
      case 'nameProperty':
        return <DatabaseNameSettingsPopoverContent {...commonProps} specId={objectSpecId} />;
      case 'dimensionalProperty':
        return (
          <DimensionalPropertySettingsPopoverContent
            {...commonProps}
            dimensionalPropertyId={fieldSpecId}
          />
        );
      case 'driverProperty':
        return (
          <DatabaseDriverPropertySettingsPopoverContent
            {...commonProps}
            driverPropertyId={fieldSpecId}
            businessObjectId={objectId}
            onClose={onClose}
          />
        );
      case 'addNewProperty':
        return <DimensionalDatabaseNewProperty {...commonProps} />;
      case 'legacyProperty':
      default:
        if (columnType === INITIAL_VALUE_COLUMN_TYPE) {
          return (
            <TimeseriesColumnPropertySettingsPopoverContent
              {...commonProps}
              columnType={columnType}
            />
          );
        }
        return (
          <DatabasePropertySettingsPopoverContent
            {...commonProps}
            objectId={undefined}
            groupKey="string"
            fieldSpecId={fieldSpecId}
          />
        );
    }
  },
);

ContextMenuContent.displayName = 'ContextMenuContent';

type DatabaseContextMenuIds = {
  fieldSpecId: BusinessObjectFieldSpecId;
  objectSpecId: BusinessObjectSpecId;
  objectId?: BusinessObjectId;
  columnType?: TimeseriesColumnValueType;
} | null;

type SetDatabaseContextMenuIds = (newVal: DatabaseContextMenuIds | null) => void;
const DatabaseContextMenuContext = createContext<SetDatabaseContextMenuIds>(noop);

interface Props {
  children: React.ReactElement;
}

export const DatabaseContextMenu: React.FC<Props> = React.memo(({ children }) => {
  const { blockId, readOnly } = useBlockContext();
  const [databaseContextMenuIds, setDatabaseContextMenuIds] =
    useState<DatabaseContextMenuIds | null>(null);

  const shouldHandleEvent = useCallback(() => false, []);
  if (readOnly) {
    return children;
  }

  return (
    <DatabaseContextMenuContext.Provider value={setDatabaseContextMenuIds}>
      <ContextMenu
        shouldShowOverlay={false}
        shouldHandleEvent={shouldHandleEvent}
        content={
          databaseContextMenuIds != null ? (
            <ContextMenuContent
              blockId={blockId}
              fieldSpecId={databaseContextMenuIds.fieldSpecId}
              objectSpecId={databaseContextMenuIds.objectSpecId}
              objectId={databaseContextMenuIds.objectId}
              columnType={databaseContextMenuIds.columnType}
            />
          ) : undefined
        }
      >
        {children}
      </ContextMenu>
    </DatabaseContextMenuContext.Provider>
  );
});

DatabaseContextMenu.displayName = 'DatabaseContextMenu';

export const useDatabaseContextMenu = () => {
  return useContext(DatabaseContextMenuContext);
};

interface EditFormulasContentProps {
  driverId: string;
  onClose: () => void;
}
const EditFormulasContent = ({ driverId, onClose }: EditFormulasContentProps) => {
  const editFormulasPopoverContentProps = useEditFormulasStateAndEventHandlers({
    driverId,
    onClose,
  });
  const contentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(contentRef, editFormulasPopoverContentProps.onClickOutside);

  return (
    <EditFormulasPopoverContent
      ref={contentRef}
      driverId={driverId}
      onClose={onClose}
      {...editFormulasPopoverContentProps}
    />
  );
};
