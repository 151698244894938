import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

import {
  getDatabaseInternalPageType,
  getObjectSpecIdFromInternalPageType,
  isDatabasePage,
} from 'config/internalPages/databasePage';
import {
  getSubmodelIdFromInternalPageType,
  getSubmodelInternalPageType,
  isSubmodelBlockPage,
} from 'config/internalPages/modelPage';
import { BlocksPage, DatabaseConfig, Folder, Submodel } from 'generated/graphql';
import { AccessCapabilitiesProvider } from 'helpers/accessCapabilities/AccessCapabilitiesProvider';
import { extractEmoji } from 'helpers/emoji';
import { DEFAULT_ICON_MAP, buildNavigationTree } from 'helpers/folders';
import { isNotNull } from 'helpers/typescript';
import { BusinessObjectSpec, BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import {
  DatabaseEntity,
  Entity,
  EntityImage,
  EntityPermissions,
  EntityType,
  EntityTypePermissions,
  FolderType,
  ModelEntity,
  NonFolderEntityType,
  PageEntity,
} from 'reduxStore/models/folder';
import { SubmodelId } from 'reduxStore/models/submodels';
import { emptyFolders } from 'reduxStore/reducers/helpers/folders';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { blocksPagesTableSelector } from 'selectors/blocksPagesTableSelector';
import { databaseConfigBySpecIdSelector } from 'selectors/databaseConfigSelector';
import { datasetSelector } from 'selectors/datasetSelector';
import { currentLayerSelector } from 'selectors/layerSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { submodelsByIdSelector } from 'selectors/submodelsByIdSelector';
import { Selector } from 'types/redux';

const businessObjectSpecsByIdSelector = createSelector(currentLayerSelector, (layer) => {
  return layer.businessObjectSpecs.byId;
});

const blocksPagesByIdSelector = createSelector(blocksPagesTableSelector, (pages) => pages.byId);

const foldersByIdSelector: Selector<Record<string, Folder>> = createSelector(
  datasetSelector,
  (dataset) => {
    if (dataset.folders.allIds.length === 0) {
      return keyBy(emptyFolders, 'id');
    } else {
      return dataset.folders.byId;
    }
  },
);

const EMPTY_LIST: string[] = [];
export const selectedPageIdsSelector = createSelector(
  pageSelector,
  blocksPagesByIdSelector,
  (page, blocksPagesById) => {
    if (page === null) {
      return EMPTY_LIST;
    }
    if (page.type !== 'submodelPage' && page.type !== 'blocksPage') {
      return EMPTY_LIST;
    }

    if (page.type === 'blocksPage') {
      return [page.id].filter(Boolean);
    } else {
      const { id } = page;
      const pagesFromModel = Object.values(blocksPagesById).filter((current) => {
        return parseContext('model', current.internalPageType) === id;
      });
      return pagesFromModel.map((current) => current.id);
    }
  },
);

export type ResourceType = 'entity' | 'plans' | 'templates' | 'integration';
export const resourceTypeSelector = createSelector(pageSelector, (page) => {
  const pageType = page?.type;
  if (pageType === 'blocksPage' || pageType === 'submodelPage') {
    return 'entity';
  } else if (pageType === 'plansPage') {
    return 'plans';
  } else if (pageType === 'templatesPage') {
    return 'templates';
  } else if (pageType === 'dataSourcePage') {
    return 'integration';
  }
  return null;
});

export const navigationEntitiesSelector = createSelector(
  accessCapabilitiesSelector,
  businessObjectSpecsByIdSelector,
  blocksPagesByIdSelector,
  submodelsByIdSelector,
  foldersByIdSelector,
  databaseConfigBySpecIdSelector,
  (
    access,
    businessObjectSpecsById,
    blocksPagesById,
    submodelsById,
    foldersById,
    databaseConfigBySpecId,
    // eslint-disable-next-line max-params
  ) => {
    const blocksEntities = getBlocksPageEntities(
      Object.values(blocksPagesById),
      businessObjectSpecsById,
      submodelsById,
      databaseConfigBySpecId,
      access,
    );

    const folderEntities = getFolderEntitites(Object.values(foldersById), access);

    return [...folderEntities, ...blocksEntities];
  },
);

export const accessibleDatabasesSelector: Selector<BusinessObjectSpecId[]> = createSelector(
  navigationEntitiesSelector,
  (entities) => {
    return entities
      .filter((entity) => entity.type === 'database' && entity.permissions.read)
      .map((entity) => entity.context)
      .filter(isNotNull);
  },
);

export const navigationEntitiesTreeSelector = createSelector(
  navigationEntitiesSelector,
  (entities) => {
    return buildNavigationTree(entities);
  },
);

function getFolderEntitites(folders: Folder[], access: AccessCapabilitiesProvider) {
  return folders.map((folder) => {
    const [emoji, name] = extractEmoji(folder.name ?? '');
    let image: EntityImage = {
      type: 'emoji' as const,
      content: emoji,
    };

    const type: FolderType = folder.type;
    const icon = DEFAULT_ICON_MAP[type];
    if (emoji === null && icon != null) {
      image = {
        type: 'icon' as const,
        content: icon,
      };
    }
    return {
      id: folder.id,
      type: 'folder' as const,
      name,
      image,
      context: type,
      permissions: {
        read: access.canReadFoldersWithChildren,
        index: access.canReadFoldersWithoutChildren,
        edit: access.canWriteFolders,
        create: access.canCreateFolders,
        destroy: access.canDestroyFolders && type === 'workspace',
      },
      sortIndex: folder.sortIndex ?? null,
      parentId: null,
      parent: null,
      children: [],
    };
  });
}

function getBlocksPageEntities(
  pages: BlocksPage[],
  businessObjectSpecs: Record<BusinessObjectSpecId, BusinessObjectSpec>,
  submodels: Record<SubmodelId, Submodel>,
  databaseConfigBySpecId: Record<BusinessObjectSpecId, DatabaseConfig>,
  access: AccessCapabilitiesProvider,
): Entity[] {
  const pagesByInternalPageType = pages.reduce(
    (acc, page) => {
      if (page.internalPageType == null) {
        return acc;
      }
      acc[page.internalPageType] = page;
      return acc;
    },
    {} as Record<string, BlocksPage>,
  );

  const relevantPages = [];
  for (const id of Object.keys(businessObjectSpecs)) {
    const page = pagesByInternalPageType[getDatabaseInternalPageType(id)];
    if (page != null) {
      relevantPages.push(page);
    }
  }

  for (const id of Object.keys(submodels)) {
    const page: BlocksPage = pagesByInternalPageType[getSubmodelInternalPageType(id)];
    if (page != null) {
      relevantPages.push(page);
    }
  }

  for (const page of pages) {
    if (page.internalPageType == null) {
      relevantPages.push(page);
    }
  }

  return relevantPages.flatMap((page) => {
    const type = parsePageType(page.internalPageType);
    if (type === null) {
      return [];
    }

    if (type === 'model') {
      return getModelEntity(page, submodels, access) ?? [];
    } else if (type === 'database') {
      return getDatabaseEntity(page, businessObjectSpecs, databaseConfigBySpecId, access) ?? [];
    }
    return getPageEntity(page, access) ?? [];
  });
}

function getDatabaseEntity(
  page: BlocksPage,
  businessObjectSpecs: Record<BusinessObjectSpecId, BusinessObjectSpec>,
  databaseConfigBySpecId: Record<BusinessObjectSpecId, DatabaseConfig>,
  access: AccessCapabilitiesProvider,
): DatabaseEntity | null {
  const context = parseContext('database', page.internalPageType);
  if (context == null) {
    return null;
  }

  const database = businessObjectSpecs[context] == null;
  if (database == null) {
    return null;
  }

  const [emoji, name] = extractEmoji(businessObjectSpecs[context].name);
  const image = {
    type: 'emoji' as const,
    content: emoji,
  };

  return {
    id: page.id,
    type: 'database',
    name,
    image,
    context,
    permissions: getPermissions({ id: page.id, type: 'database' }, access),
    config: databaseConfigBySpecId[context],
    sortIndex: page.sortIndex ?? null,
    parentId: page.parent ?? null,
    parent: null,
    children: [],
  };
}

function getModelEntity(
  page: BlocksPage,
  submodels: Record<SubmodelId, Submodel>,
  access: AccessCapabilitiesProvider,
): ModelEntity | null {
  const context = parseContext('model', page.internalPageType);
  if (context === null || !(context in submodels)) {
    return null;
  }

  const [emoji, name] = extractEmoji(page.name);
  return {
    id: page.id,
    type: 'model',
    name,
    image: {
      type: 'emoji' as const,
      content: emoji,
    },
    context,
    permissions: getPermissions({ id: page.id, type: 'model' }, access),
    sortIndex: page.sortIndex ?? null,
    parentId: page.parent ?? null,
    parent: null,
    children: [],
  };
}

function getPageEntity(page: BlocksPage, access: AccessCapabilitiesProvider): PageEntity {
  const [emoji, name] = extractEmoji(page.name);

  return {
    id: page.id,
    type: 'page',
    name,
    image: {
      type: 'emoji' as const,
      content: emoji,
    },
    context: null,
    permissions: getPermissions({ id: page.id, type: 'page' }, access),
    sortIndex: page.sortIndex ?? null,
    parentId: page.parent ?? null,
    parent: null,
    children: [],
  };
}

function getPermissions(
  page: { id: string; type: EntityType },
  access: AccessCapabilitiesProvider,
): EntityPermissions {
  const typePermissions = getEntityTypePermissions(page.type, access);

  return {
    ...typePermissions,
    read: access.canReadPage(page.id),
    edit: access.canWritePage(page.id),
    destroy: access.hasFullPageAccess(page.id),
  };
}

export function getEntityTypePermissions(
  type: EntityType,
  access: AccessCapabilitiesProvider,
): EntityTypePermissions {
  if (type === 'database') {
    return { index: access.canReadDatabases, create: access.canCreateDatabases };
  } else if (type === 'model') {
    return { index: access.canReadModels, create: access.canCreateModels };
  }
  return { index: true, create: access.canCreatePages };
}

function parsePageType(internalPageType: string | undefined | null): NonFolderEntityType | null {
  if (isDatabasePage(internalPageType)) {
    return 'database';
  } else if (isSubmodelBlockPage(internalPageType)) {
    return 'model';
  } else if (internalPageType == null) {
    return 'page';
  }
  return null;
}

function parseContext(type: EntityType, internalPageType: string | undefined | null) {
  if (internalPageType == null) {
    return null;
  }

  if (type === 'database') {
    return getObjectSpecIdFromInternalPageType(internalPageType);
  } else if (type === 'model') {
    return getSubmodelIdFromInternalPageType(internalPageType);
  }
  return null;
}
