import React, { PropsWithChildren, useState } from 'react';

export const CellPopoverReferenceContext = React.createContext<HTMLElement | null>(null);
export const SetCellPopoverReferenceContext = React.createContext<
  React.Dispatch<React.SetStateAction<HTMLElement | null>>
>(() => {});

const CellPopoverReferenceContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [popoverReference, setPopoverReference] = useState<HTMLElement | null>(null);
  return (
    <CellPopoverReferenceContext.Provider value={popoverReference}>
      <SetCellPopoverReferenceContext.Provider value={setPopoverReference}>
        {children}
      </SetCellPopoverReferenceContext.Provider>
    </CellPopoverReferenceContext.Provider>
  );
};

export default CellPopoverReferenceContextProvider;
