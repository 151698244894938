import { Flex, Text } from '@chakra-ui/react';
import { sampleSize } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import { useComparedLayers } from '@features/CompareScenarios';
import { ToggleSection } from '@features/CompareScenarios/blocks/common/ToggleSection';
import {
  OutstandingCalculations,
  useCalculations,
} from '@features/CompareScenarios/blocks/common/useCalculations';
import {
  BasicDriverChange,
  DimensionalDriverChange,
  DriverChange,
} from '@features/CompareScenarios/comparators/DriverComparator';
import DriverGroupHeaderRows from 'components/DriverGroupHeaderRows/DriverGroupHeaderRows';
import DriverGroupSectionContent from 'components/DriverGroupSectionContent/DriverGroupSectionContent';
import {
  DriverCellValueCacheContext,
  DriverCellValueInfo,
} from 'components/DriverTimeSeriesRow/LayerDriverTimeSeriesCell';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import { DriverGridContext, DriverGridContextValue } from 'config/driverGridContext';
import { SCENARIO_COMPARISON_STICKY_HEADER_HEIGHT_IN_PX } from 'config/scenarioComparison';
import { DriverType, FormulaEntityType } from 'generated/graphql';
import { DriverId } from 'reduxStore/models/drivers';
import { Delta } from 'vectors';

const driverGridContext: DriverGridContextValue = {
  canEditGroups: false,
  hasCreateButton: false,
  hasDimensionsButton: false,
  canDelete: false,
  canCreateDriverGroup: false,
};

const MAX_DRIVERS_TO_SHOW_IN_DATA = 50;
const DIMENSIONAL_DRIVER_SAMPLE_SIZE = 10;

export const DataChanges = ({ changes }: { changes: DriverChange[] }) => {
  const { isSampled, driverIds, totalChanges } = useSampledDriverIds(changes);
  const { currentLayer, mergeLayer } = useComparedLayers();
  const outstandingCalculations = useCalculations(
    { type: FormulaEntityType.Driver, ids: driverIds },
    currentLayer,
    mergeLayer,
  );

  const totalDrivers = driverIds.length;

  if (totalChanges === 0) {
    return null;
  }

  return (
    <ToggleSection
      heading={
        <Flex alignItems="baseline">
          <Text fontSize="sm" fontWeight="600" marginRight="2">
            Data changes
          </Text>
          {isSampled ? (
            <Text fontSize="xs" fontWeight="medium" color="gray.500">
              Showing {totalDrivers} of {totalChanges} {pluralize('change', totalChanges)}
            </Text>
          ) : (
            <Flex
              fontSize="xs"
              fontWeight="medium"
              color="gray.500"
              gap="2px"
              alignItems="baseline"
            >
              <Delta alignSelf="center" />
              <Text>{totalChanges}</Text>
            </Flex>
          )}
        </Flex>
      }
      fontSize="xs"
      sectionProps={{
        overflowX: 'scroll',
        overflowY: 'scroll',
        marginLeft: '6',
        height: `${
          // 32px header
          32 +
          // 96px * rows
          96 * totalDrivers +
          // 2px padding
          2
        }px`,
        paddingLeft: 0,
        minWidth: '1100px',
      }}
      isNotRenderedUntilOpen
    >
      <DriverGridContext.Provider value={driverGridContext}>
        <StickyHeader stickyTop={SCENARIO_COMPARISON_STICKY_HEADER_HEIGHT_IN_PX}>
          <DriverGroupHeaderRows groupName={pluralize('Driver', driverIds.length)} />
        </StickyHeader>
        <DataRequestRenderer calculations={outstandingCalculations} driverIds={driverIds} />
      </DriverGridContext.Provider>
    </ToggleSection>
  );
};

const DataRequestRenderer = ({
  calculations,
  driverIds,
}: {
  calculations: OutstandingCalculations;
  driverIds: DriverId[];
}) => {
  const driverValueCache = useMemo(() => {
    const cache: Record<string, DriverCellValueInfo> = {};
    const saveToCache = (key: string, value: DriverCellValueInfo) => {
      cache[key] = value;
    };
    return { cache, saveToCache };
    // NOTE: this is intentionally done to handle the re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculations]);

  return (
    <DriverCellValueCacheContext.Provider value={driverValueCache}>
      <DriverGroupSectionContent driverIds={driverIds} />
    </DriverCellValueCacheContext.Provider>
  );
};

type Sample = { isSampled: boolean; totalChanges: number; driverIds: DriverId[] };
function useSampledDriverIds(changes: DriverChange[]): Sample {
  return useMemo(() => {
    const basicDriverIds: Set<DriverId> = new Set();
    const dimensionalSubdriverDriverIds: Record<DriverId, Set<DriverId>> = {};
    for (let change of changes) {
      if (change.status === 'deleted') {
        continue;
      }

      if (change.object.type === DriverType.Dimensional) {
        change = change as DimensionalDriverChange;

        for (const driverChange of change.updatesByField.basicDrivers?.changes ?? []) {
          if (driverChange.updatesByField.data !== null) {
            dimensionalSubdriverDriverIds[change.object.id] ??= new Set();
            dimensionalSubdriverDriverIds[change.object.id].add(driverChange.object.id);
          }
        }
      } else if (change.object.type === DriverType.Basic) {
        change = change as BasicDriverChange;
        if (change.updatesByField.data != null) {
          basicDriverIds.add(change.object.id);
        }
      }
    }

    const sample: Sample = {
      isSampled: false,
      totalChanges: 0,
      driverIds: [],
    };

    if (basicDriverIds.size > MAX_DRIVERS_TO_SHOW_IN_DATA) {
      sample.isSampled = true;
      sample.driverIds = sampleSize([...basicDriverIds], MAX_DRIVERS_TO_SHOW_IN_DATA);
    } else {
      sample.driverIds.push(...basicDriverIds);
    }
    sample.totalChanges += basicDriverIds.size;

    for (const driverIds of Object.values(dimensionalSubdriverDriverIds)) {
      const size = Math.min(
        MAX_DRIVERS_TO_SHOW_IN_DATA - sample.driverIds.length,
        DIMENSIONAL_DRIVER_SAMPLE_SIZE,
      );

      sample.totalChanges += driverIds.size;
      if (driverIds.size > size) {
        sample.isSampled = true;
      }
      sample.driverIds.push(...sampleSize([...driverIds], size));
    }
    sample.driverIds = [...new Set(sample.driverIds)];
    return sample;
  }, [changes]);
}
