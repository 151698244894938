import { last } from 'lodash';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import { RUNWAY_ERROR_MESSAGES } from 'config/errors';
import {
  BlockConfig,
  BlockCreateInput,
  BlockUpdateInput,
  DatasetMutationInput,
  EventGroupCreateInput,
  UndoDocument,
  UndoMutation,
  UndoMutationInput,
  UndoMutationVariables,
} from 'generated/graphql';
import { isStorybook } from 'helpers/environment';
import {
  logDatasetReload,
  logMultiLayerMutationSubmission,
  logMutationSubmissionIssue,
} from 'helpers/logging';
import { mergeAllMutations } from 'helpers/mergeMutations';
import { enqueueRequest } from 'helpers/requests';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import {
  applyMutationLocallyForActiveLayers,
  undoMutationLocallyForActiveLayers,
} from 'reduxStore/actions/applyMutations';
import { clearLocalEphemeralMutation } from 'reduxStore/actions/clearLocalEphemeralMutation';
import { createDraftLayerAndApplyMutation } from 'reduxStore/actions/layerMutations';
import { navigateToLayer } from 'reduxStore/actions/navigateTo/navigateToLayer';
import { applyMutationTransforms } from 'reduxStore/actions/submitDatasetMutation/applyMutationTransforms';
import { makeMutationGqlRequest } from 'reduxStore/actions/submitDatasetMutation/makeMutationGqlRequest';
import {
  trackCopilotEnd,
  trackSubmitMutation,
  trackSubmitMutationFailure,
  trackSubmitMutationSuccess,
  trackUndoMutation,
  trackUndoMutationFailure,
  trackUndoMutationSuccess,
} from 'reduxStore/actions/trackEvent';
import { BlockId } from 'reduxStore/models/blocks';
import { DEFAULT_EVENT_GROUP_ID } from 'reduxStore/models/events';
import { DEFAULT_LAYER_ID, LayerId } from 'reduxStore/models/layers';
import { updateBlockConfigs } from 'reduxStore/reducers/blockLocalStateSlice';
import {
  PendingOrFailedMutation,
  addPendingMutation,
  setConfirmedPreviousMutationId,
  setDatasetIsStale,
  undoIncomingMutationForAlreadyUndoneChange,
} from 'reduxStore/reducers/datasetSlice';
import { showErrorModal } from 'reduxStore/reducers/errorSlice';
import { addRecentEntities } from 'reduxStore/reducers/formulaInputSlice';
import { endCopilotSession } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppDispatch, AppThunk } from 'reduxStore/store';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { copilotRequestStatusSelector } from 'selectors/copilotSelector';
import { datasetIsFreshSelector, localEphemeralMutationSelector } from 'selectors/datasetSelector';
import { enableBranchingFromLayersSelector } from 'selectors/launchDarklySelector';
import {
  currentLayerIdSelector,
  currentLayerIsDefaultSelector,
  currentLayerIsDraftSelector,
  currentLayerIsNamedVersionSelector,
  currentLayerSelector,
} from 'selectors/layerSelector';
import { isWritableByBlockIdSelector } from 'selectors/pageAccessResourcesSelector';
import { previousMutationIdSelector } from 'selectors/previousMutationIdSelector';
import { selectedOrgIdSelector, selectedOrgSelector } from 'selectors/selectedOrgSelector';
import { EntityType } from 'types/formula';

/*
 * These are the actions which handle any change to the mutations state either
 * by adding a new mutation or undoing/redoing a prior mutation. Any changes to
 * how mutation submission or undo/redo should be done here.
 */

type DatasetMutationType = keyof DatasetMutationInput;

const MAX_RETRIES = 2;

// Note: this has to be done before filtering out mutations
const dispatchLocalBlockConfigUpdates = (dispatch: AppDispatch, mutation: DatasetMutationInput) => {
  if (mutation.updateBlocks != null && mutation.updateBlocks.length > 0) {
    const updateBlockConfigInput: Array<{ id: BlockId; blockConfig: BlockConfig }> =
      mutation.updateBlocks
        .map((updateBlockConfig) => {
          const { id, blockConfig } = updateBlockConfig;
          if (blockConfig == null) {
            return null;
          }
          return { id, blockConfig };
        })
        .filter(isNotNull);

    if (updateBlockConfigInput.length > 0) {
      dispatch(updateBlockConfigs(updateBlockConfigInput));
    }
  }
};

/**
 * Filters out:
 *  - any update mutations for blocks that are not writable
 */
const filterOutDisallowedBlockMutations = (state: RootState, mutation: DatasetMutationInput) => {
  const isWritableByBlockId = isWritableByBlockIdSelector(state);
  const filteredUpdateBlocks = mutation.updateBlocks?.filter(({ id }) => isWritableByBlockId[id]);

  let filteredMutation: DatasetMutationInput;
  if (filteredUpdateBlocks != null && filteredUpdateBlocks.length > 0) {
    filteredMutation = { ...mutation, updateBlocks: filteredUpdateBlocks };
  } else {
    filteredMutation = { ...mutation, updateBlocks: undefined };
  }

  return filteredMutation;
};

function sanitizeCreateOrUpdateBlockMutation<T extends BlockCreateInput | BlockUpdateInput>(
  blockInput: T,
): T {
  let sanitizedBlockConfig =
    blockInput.blockConfig == null ? undefined : { ...blockInput.blockConfig };

  if (blockInput.blockConfig?.columns != null) {
    sanitizedBlockConfig = {
      ...blockInput.blockConfig,
      columns: blockInput.blockConfig.columns.filter((c) => c.key != null && c.key !== ''),
    };
  }

  return { ...blockInput, blockConfig: sanitizedBlockConfig };
}

const sanitizeMalformedBlockMutations = (mutation: DatasetMutationInput) => {
  return {
    ...mutation,
    newBlocks: mutation.newBlocks?.map(sanitizeCreateOrUpdateBlockMutation),
    updateBlocks: mutation.updateBlocks?.map(sanitizeCreateOrUpdateBlockMutation),
  };
};

const filterOutEmptyMutations = (mutation: DatasetMutationInput) => {
  const filteredMutation = { ...mutation };

  // filter out any empty mutations
  Object.keys(filteredMutation).forEach((key) => {
    const mutationType = key as DatasetMutationType;
    const subMutation = filteredMutation[mutationType];
    if (subMutation == null || (Array.isArray(subMutation) && subMutation.length === 0)) {
      delete filteredMutation[mutationType];
    }
  });
  if (Object.keys(filteredMutation).length === 0) {
    return null;
  }

  return filteredMutation;
};

const UNKNOWN_ERROR = 'unknown error';
// Optimistically updates the local state and sends off the mutation to the
// backend.
const submitMutationHelper =
  (
    unfilteredMutation: DatasetMutationInput,
    {
      forceLayerId = undefined,
      isEphemeral = false,
      isRedo = false,
      pendingOrFailedMutation = undefined,
    }: {
      forceLayerId?: LayerId;
      isEphemeral?: boolean;
      isRedo?: boolean;
      pendingOrFailedMutation?: PendingOrFailedMutation;
    } = {},
  ): AppThunk<Promise<boolean>> =>
  (dispatch, getState, { urqlClient, requestQueue }) => {
    const state = getState();
    const prevMutationId = previousMutationIdSelector(state);
    const currentLayerId = currentLayerIdSelector(state);

    // Write the given mutation to the current layer unless `mutateMainLayer` is specified.
    const layerId = forceLayerId != null ? forceLayerId : currentLayerId;

    const orgId = selectedOrgIdSelector(state);
    const retryCount = pendingOrFailedMutation?.retryCount ?? 0;
    const { isOrgGuest } = accessCapabilitiesSelector(state);

    // if it's a pending mutation, we want to use the original id since it was never submitted before
    const id = pendingOrFailedMutation?.pendingMutationId ?? uuidv4();

    const datasetIsFresh = datasetIsFreshSelector(state);

    let mutation: DatasetMutationInput | null = unfilteredMutation;
    if (isOrgGuest) {
      dispatchLocalBlockConfigUpdates(dispatch, unfilteredMutation);
      mutation = filterOutDisallowedBlockMutations(state, mutation);
    }
    mutation = sanitizeMalformedBlockMutations(mutation);
    mutation = filterOutEmptyMutations(mutation);

    if (mutation == null) {
      return Promise.resolve(true);
    }

    let promise: Promise<boolean>;
    if (!isStorybook && !isEphemeral) {
      if (datasetIsFresh) {
        promise = new Promise((resolve) => {
          enqueueRequest(requestQueue, () =>
            makeMutationGqlRequest({
              urqlClient,
              orgId,
              mutationId: id,
              prevMutationId,
              mutation,
              // The backend expects no layer id for the default layer. We have to
              // use an id on the frontend to be able so we have a key for the
              // layer map.
              layerId: layerId === DEFAULT_LAYER_ID ? undefined : layerId,
            })
              .then((res) => {
                const error = res.error ?? res.data?.datasetMutation?.errors?.[0];

                if (!res.data?.datasetMutation?.success || error != null) {
                  console.error(error);

                  // Do not log to Sentry.
                  // Call GraphQL errors (res.error) are automatically logged via handleUrqlError.
                  // When datasetMutation() returns any DatasetMutationError error (res.error) will be null.
                  logDatasetReload({
                    cause: isRedo ? 'redoLatest' : 'submitMutation',
                    serverError: error?.message ?? UNKNOWN_ERROR,
                  });
                  if (
                    retryCount < MAX_RETRIES &&
                    error?.message !== RUNWAY_ERROR_MESSAGES.NotAuthorized
                  ) {
                    dispatch(
                      setDatasetIsStale({
                        layerId,
                        mutation,
                        retryCount,
                      }),
                    );
                    logMutationSubmissionIssue({
                      cause: 'serverError',
                      serverError: error?.message ?? UNKNOWN_ERROR,
                    });
                    trackSubmitMutation({ layerId, mutation, status: 'Retrying' });
                  } else {
                    dispatch(setDatasetIsStale());
                  }

                  dispatch(
                    trackSubmitMutationFailure({
                      layerId,
                      mutation,
                      error: error ?? { message: UNKNOWN_ERROR },
                    }),
                  );
                  resolve(false);
                } else {
                  dispatch(trackSubmitMutationSuccess({ layerId, mutation }));
                  resolve(true);
                }
              })
              .catch((error: Error) => {
                // This is not a server error, so we should not relad the dataset and we should not retry the mutation.
                dispatch(trackSubmitMutationFailure({ layerId, mutation, error }));
                dispatch(showErrorModal(error.message));
              }),
          );
        });
      } else {
        // In theory when dataset is not fresh the user's mouse and keyboard events should be blocked so you shouldn't
        // be able to get here. But just in case let's save the mutation and retry it when the dataset is fresh again.
        dispatch(
          addPendingMutation({
            layerId,
            mutation,
            retryCount,
            pendingMutationId: id,
          }),
        );
        logMutationSubmissionIssue({
          cause: 'datasetStale',
        });
        trackSubmitMutation({ layerId, mutation, status: 'Pending' });
        promise = Promise.resolve(false);
      }
    } else if (isEphemeral) {
      // clear any existing ephemeral mutation before applying the new one
      dispatch(clearLocalEphemeralMutation());
      promise = Promise.resolve(true);
    } else {
      promise = Promise.resolve(true);
    }

    navigateToLayerIfChanged(dispatch, getState, () => {
      dispatch(
        applyMutationLocallyForActiveLayers({
          layerId,
          mutationBatch: { id, mutation, layerId },
          isEphemeral,
          isRedo,
        }),
      );
    });

    return promise;
  };

export const submitMutation =
  (
    mutation: DatasetMutationInput,
    {
      forceLayerId = undefined,
      isEphemeral = false,
      pendingOrFailedMutation = undefined,
      isRedo = false,
    }: {
      forceLayerId?: LayerId;
      isEphemeral?: boolean;
      pendingOrFailedMutation?: PendingOrFailedMutation;
      isRedo?: boolean;
    } = {},
  ): AppThunk<Promise<boolean>> =>
  (dispatch, getState) => {
    const state = getState();
    const currentLayerId = currentLayerIdSelector(state);

    // Write the given mutation to the current layer unless `mutateMainLayer` is specified.
    const layerId = forceLayerId != null ? forceLayerId : currentLayerId;

    // don't validate layers if we are creating a new layer not on main, because layer data is not created yet.
    // This can happen if we create a layer and apply more mutations in one mutation batch
    if ((mutation.newLayers ?? []).length === 0 || layerId === DEFAULT_LAYER_ID) {
      validateLayer(state, layerId);
    }

    // Skip mutations when on a saved layer
    const currentLayerIsSavedVersion = currentLayerIsNamedVersionSelector(state);
    if (currentLayerIsSavedVersion) {
      return Promise.resolve(true);
    }

    if (
      (mutation.newDrivers ?? []).length > 0 ||
      (mutation.newBusinessObjectSpecs ?? []).length > 0
    ) {
      const newDrivers = (mutation.newDrivers ?? []).map((input) => ({
        type: EntityType.Driver as const,
        data: { id: input.id },
      }));
      const newDatabases = (mutation.newBusinessObjectSpecs ?? []).map((input) => ({
        type: EntityType.ObjectSpec as const,
        data: { id: input.id, fieldId: undefined },
      }));
      dispatch(addRecentEntities([...newDrivers, ...newDatabases]));
    }

    return dispatch(
      submitMutationHelper(mutation, {
        forceLayerId,
        isEphemeral,
        pendingOrFailedMutation,
        isRedo,
      }),
    );
  };

export const revertEphemeralMutations = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const mutationBatch = localEphemeralMutationSelector(state);
    const currentLayerId = currentLayerIdSelector(state);
    const newLayers = mutationBatch?.mutation.newLayers;
    if (newLayers != null && newLayers.length > 0 && currentLayerId === newLayers[0].id) {
      dispatch(navigateToLayer(DEFAULT_LAYER_ID));
    }

    dispatch(trackCopilotEnd('reject'));
    dispatch(endCopilotSession({ applyChanges: false }));
  };
};

export const submitEphemeralMutations = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const mutationBatch = localEphemeralMutationSelector(state);

    if (mutationBatch != null) {
      dispatch(
        submitMutation(mutationBatch.mutation, {
          forceLayerId: mutationBatch.layerId ?? undefined,
        }),
      );
    }

    dispatch(trackCopilotEnd('accept'));
    dispatch(endCopilotSession({ applyChanges: true }));
  };
};

function shouldScopeToLayer(
  scopeToDefaultLayer: boolean | keyof DatasetMutationInput,
  scopeToDefaultLayerByMutationType: Partial<Record<keyof DatasetMutationInput, boolean>>,
): boolean {
  if (typeof scopeToDefaultLayer === 'string') {
    return !(safeObjGet(scopeToDefaultLayerByMutationType[scopeToDefaultLayer]) ?? false);
  }
  return !scopeToDefaultLayer;
}

export const submitMutationWithDrafts =
  (
    mutation: DatasetMutationInput,
    {
      scopeToDefaultLayer,
    }: {
      scopeToDefaultLayer: boolean;
    },
  ): AppThunk<Promise<boolean>> =>
  (dispatch, getState) => {
    const state = getState();
    const enableBranchingFromLayers = enableBranchingFromLayersSelector(state);
    const doesLayerSupportDrafts =
      (enableBranchingFromLayers && !currentLayerIsDraftSelector(state)) ||
      currentLayerIsDefaultSelector(state);

    if (scopeToDefaultLayer) {
      // All non-scoped mutations go directly to the default layer
      return dispatch(submitMutation(mutation, { forceLayerId: DEFAULT_LAYER_ID }));
    }

    if (doesLayerSupportDrafts) {
      // if the current layer is the default layer, create a draft layer and apply the mutation in
      // one mutation batch. Also navigate to the new layer. This happens the first time we make a
      // change to the default layer

      // 'forceLayerId === DEFAULT_LAYER_ID' is usually for layer-related mutations that only make
      // sense to be on main.. For other mutations on main, we use the draft layer
      const currentLayer = currentLayerSelector(state);
      const newLayerId = uuidv4();
      if (currentLayer.id != null) {
        DataArbiter.get().cloneLayerCache(currentLayer.id, newLayerId);
      }
      return dispatch(createDraftLayerAndApplyMutation(currentLayer, newLayerId, mutation));
    }

    // At this point, this mutation is scoped to layer but we are not on the default_layer, so
    // just apply mutation directly to current layer
    return dispatch(submitMutation(mutation));
  };

export const undoMutation =
  ({
    forceLayerId,
    undoMutationId,
    mutation,
    pendingOrFailedMutation = undefined,
    isChangeAlreadyUndone = false,
    undoMutationInput = undefined,
  }: {
    forceLayerId: LayerId;
    undoMutationId: string;
    mutation: DatasetMutationInput;
    pendingOrFailedMutation?: PendingOrFailedMutation;
    isChangeAlreadyUndone?: boolean;
    undoMutationInput?: UndoMutationInput;
  }): AppThunk<Promise<boolean>> =>
  (dispatch, getState, { urqlClient, requestQueue }) => {
    const state = getState();
    const copilotRequestStatus = copilotRequestStatusSelector(state);
    if (copilotRequestStatus != null) {
      const ephemeralMutation = localEphemeralMutationSelector(state);
      if (ephemeralMutation != null || copilotRequestStatus !== 'pending') {
        dispatch(endCopilotSession({ applyChanges: false }));
      }
      return Promise.resolve(true);
    }

    if (state.dataset.snapshots[DEFAULT_LAYER_ID] == null) {
      return Promise.resolve(true);
    }

    const selectedOrg = selectedOrgSelector(state);
    const prevMutationId = previousMutationIdSelector(state);
    if (!selectedOrg || prevMutationId == null) {
      return Promise.resolve(true);
    }

    const orgId = selectedOrg.id;
    const retryCount = pendingOrFailedMutation?.retryCount ?? 0;
    // if it's a pending mutation, we want to use the original id since it was never submitted before
    const id = pendingOrFailedMutation?.pendingMutationId ?? uuidv4();
    const layerId = forceLayerId ?? DEFAULT_LAYER_ID;

    validateLayer(state, layerId);

    const datasetIsFresh = datasetIsFreshSelector(state);
    let promise: Promise<boolean>;

    if (datasetIsFresh) {
      promise = new Promise((resolve) =>
        enqueueRequest(requestQueue, () =>
          urqlClient
            .mutation<UndoMutation, UndoMutationVariables>(UndoDocument, {
              orgId,
              mutationId: id,
              prevMutationId,
              undoMutationId,
              undoMutationInput,
            })
            .toPromise()
            .then((res) => {
              if (res.error) {
                // Do not log to Sentry.
                // Call GraphQL errors are automatically logged via handleUrqlError.
                logDatasetReload({ cause: 'undoLatest', serverError: res.error.message });
                if (retryCount < MAX_RETRIES) {
                  dispatch(
                    setDatasetIsStale({
                      layerId,
                      mutation,
                      retryCount,
                      undoMutationId,
                    }),
                  );
                  logMutationSubmissionIssue({
                    cause: 'serverError',
                    serverError: res.error.message,
                  });
                  trackUndoMutation({ layerId, mutation, undoMutationId, status: 'Retrying' });
                } else {
                  dispatch(setDatasetIsStale());
                }
                dispatch(
                  trackUndoMutationFailure({ layerId, mutation, error: res.error, undoMutationId }),
                );
                resolve(false);
              } else {
                dispatch(setConfirmedPreviousMutationId(id));
                trackUndoMutationSuccess({ layerId, mutation, undoMutationId });
                resolve(true);
              }
            })
            .catch((error: Error) => {
              // Do not log to Sentry.
              // Call GraphQL errors are automatically logged via handleUrqlError.
              dispatch(trackUndoMutationFailure({ layerId, mutation, error, undoMutationId }));
              dispatch(showErrorModal(error.message));
            }),
        ),
      );
    } else {
      // In theory when dataset is not fresh the user's mouse and keyboard events should be blocked so you shouldn't
      // be able to get here. But just in case let's save the mutation and retry it when the dataset is fresh again.
      dispatch(
        addPendingMutation(
          pendingOrFailedMutation ?? {
            layerId,
            mutation,
            retryCount,
            undoMutationId,
          },
        ),
      );
      logMutationSubmissionIssue({
        cause: 'datasetStale',
      });
      trackUndoMutation({ layerId, mutation, undoMutationId, status: 'Pending' });
      promise = Promise.resolve(false);
    }

    if (isChangeAlreadyUndone) {
      dispatch(undoIncomingMutationForAlreadyUndoneChange({ mutationId: id, mutation }));
    } else {
      navigateToLayerIfChanged(dispatch, getState, () => {
        dispatch(
          undoMutationLocallyForActiveLayers({
            layerId,
            toUndoId: undoMutationId,
            updatedPreviousMutationId: id,
          }),
        );
      });
    }

    return promise;
  };

export const undoLatest = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const undoStack = state.undoRedo.undoStack;

  if (undoStack.length === 0) {
    return;
  }

  const mutationChangeToUndo = last(undoStack);
  if (mutationChangeToUndo == null) {
    return;
  }

  const mutationBatches = mutationChangeToUndo.mutationBatches.slice().reverse();
  const lastMutationBatchId = last(mutationBatches)?.id;
  for (const mutationToUndo of mutationBatches) {
    const isLastChangeItemMutation =
      mutationToUndo.mutation.changeId != null && mutationToUndo.id === lastMutationBatchId;

    const undoMutationInput: UndoMutationInput | undefined = isLastChangeItemMutation
      ? { changeId: mutationToUndo.mutation.changeId ?? '', shouldUndo: true }
      : undefined;

    const layerId = mutationToUndo.layerId ?? DEFAULT_LAYER_ID;
    // Use setTimeout to make the redo action asynchronous for smoother UI updates and allow time for redo UI actions (e.g., toast messages) to render properly.
    setTimeout(() => {
      dispatch(
        undoMutation({
          undoMutationId: mutationToUndo.id,
          mutation: mutationToUndo.mutation,
          forceLayerId: layerId,
          undoMutationInput,
        }),
      );
    }, 0);
  }
};

export const redoLatest = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const redoStack = state.undoRedo.redoStack;

  if (redoStack.length === 0) {
    return;
  }
  const prevMutationId = previousMutationIdSelector(state);
  if (prevMutationId == null) {
    throw new Error('expected previous mutation for undo');
  }

  const mutationActionToRedo = redoStack[redoStack.length - 1];
  const mutationBatches = mutationActionToRedo.mutationBatches.slice().reverse();

  for (const mutationToRedo of mutationBatches) {
    const layerId = mutationToRedo.layerId ?? state.dataset.currentLayerId;
    // Use setTimeout to make the redo action asynchronous for smoother UI updates and allow time for redo UI actions (e.g., toast messages) to render properly.
    setTimeout(() => {
      dispatch(
        submitMutation(mutationToRedo.mutation, {
          forceLayerId: layerId,
          isRedo: true,
        }),
      );
    }, 0);
  }
};

function navigateToLayerIfChanged(
  dispatch: AppDispatch,
  getState: () => RootState,
  fn: () => void,
) {
  const prevLayerId = getState().dataset.currentLayerId;
  fn();
  const newLayerId = getState().dataset.currentLayerId;
  if (prevLayerId !== newLayerId) {
    dispatch(navigateToLayer(newLayerId));
  }
}

function validateLayer(state: RootState, layerId: LayerId) {
  const layer = state.dataset.layers[layerId];
  if (layer == null) {
    throw new Error('layer not found');
  }
  if (layer.isDeleted) {
    throw new Error('trying to modify deleted layer');
  }
}

type mutationTransformerFnType<T> = (
  input: T,
  getState: () => RootState,
  dispatch: AppDispatch,
) => DatasetMutationInput | null;

export const getMutationThunkAction = <T = void>(
  transformerFn: mutationTransformerFnType<T>,
  extraAction?: (input: T) => AppThunk,
): ((input: T) => AppThunk) => {
  return (input: T) => (dispatch, getState) => {
    const mutation = transformerFn(input, getState, dispatch);

    if (mutation) {
      dispatch(submitAutoLayerizedMutations(null, [mutation]));
      if (extraAction != null) {
        dispatch(extraAction(input));
      }
    }
  };
};

export type MutationAction =
  | 'create-layer'
  | 'add-events-and-objects-from-timeline'
  | 'duplicate-block'
  | 'create-blocks-page'
  | 'delete-blocks-page'
  | 'duplicate-blocks-page'
  | 'update-layer'
  | 'update-business-object-field-actuals'
  | 'create-business-objects'
  | 'create-business-object'
  | 'create-business-object-spec'
  | 'delete-business-object-spec'
  | 'update-database-property-type'
  | 'update-business-object-spec'
  | 'create-dimension-for-object-spec-field'
  | 'create-business-object-spec-field'
  | 'create-business-object-spec-dimensional-property'
  | 'autofill-dimensional-property'
  | 'clear-autofill-dimensional-property'
  | 'update-driver-property-mapping'
  | 'add-existing-driver-to-business-object-spec'
  | 'create-business-object-driver-property'
  | 'cell-paste-string'
  | 'create-business-objects-from-paste'
  | 'create-drivers-from-paste'
  | 'rename-dimension'
  | 'create-driver-group'
  | 'delete-driver-group'
  | 'create-drivers-in-context'
  | 'update-driver-formats'
  | 'remove-driver-mapping'
  | 'update-driver-currencies'
  | 'update-driver-decimal-places'
  | 'update-driver-value-types'
  | 'add-driver-references-to-block'
  | 'remove-driver-reference-from-block'
  | 'remove-drivers-from-block'
  | 'clear-drivers-from-block'
  | 'update-driver-position-in-block'
  | 'update-driver-submodel'
  | 'add-driver-submodel'
  | 'remove-driver-submodel'
  | 'update-submodel-for-group'
  | 'update-selected-drivers-submodel'
  | 'duplicate-selected-business-objects'
  | 'duplicate-selected-drivers'
  | 'create-events-and-group'
  | 'create-events-and-groups'
  | 'create-business-object-field-event'
  | 'create-business-object-field'
  | 'delete-business-object-spec-dimensional-property'
  | 'delete-business-object-spec-driver-property'
  | 'update-dimensional-property-entry'
  | 'toggle-dimensional-property-is-database-key'
  | 'create-folder'
  | 'delete-folder'
  | 'rename-folder'
  | 'update-folder-navigation'
  | 'update-page-navigation'
  | 'move-object-across-grouped-attribute'
  | 'edit-database-cell-value'
  | 'paste-in-ag-grid-database'
  | 'paste-in-ag-grid-timeseries'
  | 'change-business-object-field'
  | 'change-driver-cell'
  | 'create-subdriver'
  | 'create-submodel'
  | 'update-submodel'
  | 'delete-submodel'
  | 'delete-block'
  | 'create-block'
  | 'update-curve-point-on-entity'
  | 'delete-events'
  | 'rename-driver-property'
  | 'rename-dimensional-property'
  | 'update-and-create-events'
  | 'update-object-field-timeseries'
  | 'delete-orphan-ext-tables'
  | 'update-database-segments'
  | 'live-edit';
// Automatically routes mutation fields to the appropriate layer
export function submitAutoLayerizedMutations(
  action: MutationAction | null,
  mutationsArr: DatasetMutationInput[],
  // Sometimes it's helpful to manually override the default routing.  For example,
  // usually newDrivers are routed to the correct layer based on the driver FF.
  // But when creating a new DB driver property, we want to route the newDrivers
  // mutation based on the DB feature flag instead.
  overrides: Partial<Record<keyof DatasetMutationInput, boolean | keyof DatasetMutationInput>> = {},
): AppThunk<void> {
  return (dispatch, getState) => {
    const mutation = mergeAllMutations(mutationsArr);

    applyMutationTransforms(getState(), mutation);

    const { defaultLayerMutations, currentLayerMutations } = routeMutationsToLayer(mutation, {
      overrides,
    });

    const hasDefaultLayerMutation = Object.keys(defaultLayerMutations).length > 0;
    const hasCurrentLayerMutation = Object.keys(currentLayerMutations).length > 0;

    // We want to log when we submit mutations split between the default layer and the current layer
    // so that we have visibility into when this happens
    if (hasDefaultLayerMutation && hasCurrentLayerMutation) {
      logMultiLayerMutationSubmission({
        action,
        defaultLayerMutationFields: Object.keys(defaultLayerMutations),
        currentLayerMutationFields: Object.keys(currentLayerMutations),
      });
    }

    if (hasDefaultLayerMutation && hasCurrentLayerMutation) {
      const changeId = uuidv4();
      defaultLayerMutations.changeId = changeId;
      currentLayerMutations.changeId = changeId;
    }

    // N.B. Important to submit default layer mutations before current layer mutations, since
    // current layer mutations may reference entities on the default layer.
    // TODO: Submitting two separate mutations breaks undo/redo stack. Need to fix.
    if (hasDefaultLayerMutation) {
      dispatch(
        submitMutation(defaultLayerMutations, {
          forceLayerId: DEFAULT_LAYER_ID,
        }),
      );
    }

    if (hasCurrentLayerMutation) {
      dispatch(
        submitMutationWithDrafts(currentLayerMutations, {
          scopeToDefaultLayer: false,
        }),
      );
    }
  };
}

// Ideally this value type would be more strict, but iterating over the
// input mutation fields causes us to lose type information. So we just
// shove the segmented mutations into this loose type, and type assert
// before actually submitting the mutations.
type DatasetMutationInputLoose = Partial<
  Record<keyof DatasetMutationInput, DatasetMutationInput[keyof DatasetMutationInput]>
>;

// Default config for which mutations get routed to which layers.
// N.B. When modifying default layer mutations (i.e. `mutationName: true`), you must also
// make the relevant change in the BE (`validateMutationLayerization` in mutations_v2.go).
export const DEFAULT_MUTATION_ROUTING: Record<keyof DatasetMutationInput, boolean> = {
  //
  // Always scope to default layer
  //
  // Layers
  newLayers: true,
  updateLayers: true,
  commitLayerId: true,
  deleteLayers: true,

  // Blocks
  newBlocks: true,
  updateBlocks: true,
  deleteBlocks: true,

  // Block pages
  newBlocksPages: true,
  updateBlocksPages: true,
  deleteBlocksPages: true,

  // Dimensions
  newDimensions: true,
  updateDimensions: true,
  restoreDimensions: true,
  deleteDimensions: true,

  // Named versions
  createNamedDatasetVersions: true,
  updateNamedDatasetVersions: true,
  importNamedVersionSnapshot: true,
  deleteNamedDatasetVersions: true,
  importSnapshot: true,

  // Integrations
  createIntegrationQueries: true,
  updateIntegrationQueries: true,
  deleteIntegrationQueries: true,

  // Ext stuff
  createExtObjectSpecs: true,
  updateExtObjectSpecs: true,
  deleteExtObjectSpecs: true,

  createExtTables: true,
  updateExtTables: true,
  deleteExtTables: true,

  createExtDrivers: true,
  updateExtDrivers: true,
  deleteExtDrivers: true,

  createExtObjects: true,
  deleteExtObjects: true,

  createExtTableRuns: true,

  // Last Actuals
  updateLastActualsTime: true,

  // Folders
  newFolders: true,
  updateFolders: true,
  deleteFolders: true,

  //
  // Always scope to current layer
  //
  // Drivers
  newDrivers: false,
  updateDrivers: false,
  deleteDrivers: false,

  // Driver groups
  newDriverGroups: false,
  renameDriverGroups: false,
  deleteDriverGroups: false,

  // Submodels
  newSubmodels: false,
  updateSubmodels: false,
  deleteSubmodels: false,

  // Objects
  newBusinessObjects: false,
  updateBusinessObjects: false,
  deleteBusinessObjects: false,

  // Events
  newEvents: false,
  updateEvents: false,
  deleteEvents: false,

  // Event groups
  newEventGroups: false,
  updateEventGroups: false,
  deleteEventGroups: false,

  // Milestones
  newMilestones: false,
  updateMilestones: false,
  deleteMilestones: false,

  // ExtQueries
  createExtQueries: false,
  updateExtQueries: false,
  deleteExtQueries: false,

  createDatabaseConfigs: false,
  updateDatabaseConfigs: false,
  deleteDatabaseConfigs: false,

  //
  // Scope to current layer, behind db feature flag
  //
  // Object specs
  newBusinessObjectSpecs: false,
  updateBusinessObjectSpecs: false,
  deleteBusinessObjectSpecs: false,

  // Mutation Change
  changeId: false,

  //
  // Deprecated
  //
  newExports: true,
  updateExports: true,
  deleteExports: true,

  newDriverFieldSpecs: true,
  updateDriverFieldSpecs: true,

  setDriverFields: true,
};

// In very rare cases, we want to route mutations based on the mutation itself.
// e.g. We always want newEventGroup mutations with type Default to go to the default layer.
const DEFAULT_FIELD_BASED_OVERRIDES: Partial<
  Record<
    keyof DatasetMutationInput,
    (v: DatasetMutationInput) => {
      defaultLayerValues: DatasetMutationInputLoose[keyof DatasetMutationInputLoose];
      currentLayerValues: DatasetMutationInputLoose[keyof DatasetMutationInputLoose];
    }
  >
> = {
  newEventGroups: ({ newEventGroups }) => {
    let defaultLayerValues: EventGroupCreateInput[] | null = null;
    let currentLayerValues: EventGroupCreateInput[] | null = null;

    newEventGroups?.forEach((eventGroup) => {
      if (eventGroup.id === DEFAULT_EVENT_GROUP_ID) {
        // All event groups of type Default should go to the default layer
        const existing = defaultLayerValues ?? [];
        existing.push(eventGroup);
        defaultLayerValues = existing;
        return;
      }

      const existing = currentLayerValues ?? [];
      existing.push(eventGroup);
      currentLayerValues = existing;
    });

    return { defaultLayerValues, currentLayerValues };
  },
};

/**
 * For overrides which are dependent on other mutation keys, pushes the dependency mutations to the front
 * of the array. This function not smart and does handle dependency chains. For example, if you have
 * 'updateBlocksPages', 'updateBlocks' and 'updateDrivers', and you want the dependency
 * 'updateBlocksPages' <- 'updateBlocks' <- 'updateDrivers', set all the overrides to the root, i.e.:
 * ```
 * {
 *     updateBlocks: 'updateBlocksPages',
 *     updateDrivers: 'updateBlocksPages',
 * }
 * ```
 *
 * and NOT
 * ```
 * {
 *     updateBlocks: 'updateBlocksPages',
 *     updateDrivers: 'updateBlocks',
 * }
 * ```
 */
function orderMutationsBasedOnRoutingDependencies(
  mutations: DatasetMutationInput,
  overrides: RouteMutationsToLayerOverrides | undefined,
): Array<keyof DatasetMutationInput> {
  const mutationTypes = Object.keys(mutations) as Array<keyof DatasetMutationInput>;
  if (overrides == null) {
    return mutationTypes;
  }

  // First, add the mutations that the overrides depend on to the front of the ordered list
  const addedMutationTypes: Set<string> = new Set();
  const orderedMutations: Array<keyof DatasetMutationInput> = [];
  Object.values(overrides).forEach((value) => {
    if (typeof value !== 'string') {
      return;
    }

    const dependentMutationType = value;
    if (addedMutationTypes.has(dependentMutationType)) {
      // This is a cycle!
      throw new Error('Cycle detected in mutation routing');
    }
    orderedMutations.push(dependentMutationType);
    addedMutationTypes.add(dependentMutationType);
  });

  // Next, add the rest of the mutations that aren't already in the ordered list
  mutationTypes.forEach((mutationType) => {
    if (addedMutationTypes.has(mutationType)) {
      return;
    }
    orderedMutations.push(mutationType);
  });

  return orderedMutations;
}

// The keyof DatasetMutationInput type is used to indicate that the mutation should be scoped
// to whichever layer the indicated mutation type is scoped to.
type RouteMutationsToLayerOverrides = Partial<
  Record<keyof DatasetMutationInput, boolean | keyof DatasetMutationInput>
>;

// Exported for testing
export function routeMutationsToLayer(
  mutation: DatasetMutationInput,
  {
    overrides,
  }: {
    overrides?: RouteMutationsToLayerOverrides;
  },
): { defaultLayerMutations: DatasetMutationInput; currentLayerMutations: DatasetMutationInput } {
  const defaultLayerMutations: DatasetMutationInputLoose = {};
  const currentLayerMutations: DatasetMutationInputLoose = {};

  const scopeToDefaultLayerByMutationType: Partial<Record<keyof DatasetMutationInput, boolean>> =
    {};

  orderMutationsBasedOnRoutingDependencies(mutation, overrides).forEach((mutationType) => {
    const mutationValue = mutation[mutationType];
    if (mutationValue == null || (Array.isArray(mutationValue) && mutationValue.length === 0)) {
      return;
    }

    let scopeToDefaultLayer: boolean | keyof DatasetMutationInput;

    const manualOverride = overrides?.[mutationType];
    const perFieldOverrides = DEFAULT_FIELD_BASED_OVERRIDES[mutationType];

    if (manualOverride != null) {
      // 1. Prioritize overrides passed in by consumer
      scopeToDefaultLayer = manualOverride;
    } else if (perFieldOverrides != null) {
      // 2. Check field-based overrides - note that this early-exits
      const { currentLayerValues, defaultLayerValues } = perFieldOverrides({
        [mutationType]: mutationValue,
      });

      if (currentLayerValues != null) {
        currentLayerMutations[mutationType] = currentLayerValues;
      }
      if (defaultLayerValues != null) {
        defaultLayerMutations[mutationType] = defaultLayerValues;
      }

      return;
    } else {
      // 3. Fallback to the default layer
      scopeToDefaultLayer = DEFAULT_MUTATION_ROUTING[mutationType];
    }

    if (shouldScopeToLayer(scopeToDefaultLayer, scopeToDefaultLayerByMutationType)) {
      currentLayerMutations[mutationType] = mutationValue;
      scopeToDefaultLayerByMutationType[mutationType] = false;
    } else {
      defaultLayerMutations[mutationType] = mutationValue;
      scopeToDefaultLayerByMutationType[mutationType] = true;
    }
  });

  return {
    defaultLayerMutations: defaultLayerMutations as DatasetMutationInput,
    currentLayerMutations: currentLayerMutations as DatasetMutationInput,
  };
}
