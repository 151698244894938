import { clearLocalEphemeralMutation as webworkerClearLocalEphemeralMutation } from 'reduxStore/reducers/calculationsSlice';
import { clearLocalEphemeralMutation_INTERNAL } from 'reduxStore/reducers/datasetSlice';
import { AppThunk } from 'reduxStore/store';

/**
 * Clears the local ephemeral mutation. Ensures that the main thread and webworker are in sync.
 */
export const clearLocalEphemeralMutation = (): AppThunk<void> => (dispatch) => {
  dispatch(webworkerClearLocalEphemeralMutation());
  dispatch(clearLocalEphemeralMutation_INTERNAL());
};
