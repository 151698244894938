import { Box, BoxProps, StyleProps } from '@chakra-ui/react';

const SIZE_TO_VALUE: Record<Size, BoxProps['width']> = {
  lg: '4',
  md: '3',
  sm: '2',
};

export const Circle = ({
  color,
  size,
  ...props
}: {
  color: StyleProps['backgroundColor'];
  size: Size;
} & BoxProps) => {
  const value = SIZE_TO_VALUE[size];
  return (
    <Box width={value} height={value} borderRadius="100%" backgroundColor={color} {...props} />
  );
};

type Size = 'sm' | 'md' | 'lg';
