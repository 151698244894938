import { createSelector } from '@reduxjs/toolkit';

import { AccessResource } from 'reduxStore/models/accessResources';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { Selector } from 'types/redux';

export const sharingSelector = (state: RootState) => state.sharing;

export const allAccessResourcesSelector: Selector<AccessResource[]> = createSelector(
  sharingSelector,
  (sharing) => Object.values(sharing.accessResources.byId),
);
