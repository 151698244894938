import { useCallback } from 'react';

import { useWebWorkerDataProviderContext } from 'components/WebWorkerDataProvider/Context';
import useAppSelector from 'hooks/useAppSelector';
import { BlockId } from 'reduxStore/models/blocks';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { isCurrentPageWritableSelector } from 'selectors/pageAccessResourcesSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { prevailingSelectedDriverIdsSelector } from 'selectors/prevailingCellSelectionSelector';
import { pageSelectionSelector } from 'selectors/selectionSelector';

export default function useRecalculateSelected(blockId: BlockId) {
  const selection = useAppSelector(pageSelectionSelector);
  const isPageWritable = useAppSelector(isCurrentPageWritableSelector);

  const { requestValue } = useWebWorkerDataProviderContext();
  const blockDateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const modelLayerId = useAppSelector(currentLayerIdSelector);
  const selectedDriverIDs = useAppSelector(prevailingSelectedDriverIdsSelector);

  return useCallback(() => {
    if (selection == null || !isPageWritable) {
      return;
    }
    for (const driverId of selectedDriverIDs) {
      requestValue({
        id: driverId,
        type: 'driver',
        dateRange: blockDateRange,
        layerId: modelLayerId,
        opts: {
          ignoreAllCaches: true,
        },
      });
    }
  }, [blockDateRange, isPageWritable, modelLayerId, requestValue, selectedDriverIDs, selection]);
}
