import { useContext } from 'react';

import { CustomizeDateRangeMenuContext } from 'components/CustomizeDateRangeMenu/CustomizeDateRangeMenuContext';
import DatePicker from 'components/DateRangePicker/DateRangePicker';
import MenuHeader from 'components/MenuHeader/MenuHeader';
import SubmenuListItem from 'components/SubmenuListItem/SubmenuListItem';
import { MAX_DATE, MIN_DATE } from 'config/datetime';
import { RollupType } from 'generated/graphql';
import { offsetDateTimeForFY } from 'helpers/rollups';
import useEnum from 'hooks/useEnum';

export interface CustomizeDateRangeMenuProps {
  onBack: () => void;
  onClose: () => void;
}

const MENU_OPTIONS = ['dateRangeStart' as const, 'dateRangeEnd' as const];

const CustomizeDateRangeMenu: React.FC<CustomizeDateRangeMenuProps> = ({ onClose, onBack }) => {
  const { start, end } = useContext(CustomizeDateRangeMenuContext);
  const [submenu, setSubmenu, clearSubmenu] = useEnum(MENU_OPTIONS);

  return (
    <>
      {submenu == null && (
        <>
          <MenuHeader title="Date range" onClose={onClose} onBack={onBack} />
          <SubmenuListItem
            name="Start date"
            submenuLabel={start.toFormat('MMM yyyy')}
            onClick={setSubmenu.dateRangeStart}
          />
          <SubmenuListItem
            name="End date"
            submenuLabel={end.toFormat('MMM yyyy')}
            onClick={setSubmenu.dateRangeEnd}
          />
        </>
      )}
      {submenu === 'dateRangeStart' && (
        <>
          <MenuHeader title="Date range (start)" onClose={onClose} onBack={clearSubmenu} />
          <CustomizeDateRangeDatePicker bound="start" onDone={clearSubmenu} />
        </>
      )}
      {submenu === 'dateRangeEnd' && (
        <>
          <MenuHeader title="Date range (end)" onClose={onClose} onBack={clearSubmenu} />
          <CustomizeDateRangeDatePicker bound="end" onDone={clearSubmenu} />
        </>
      )}
    </>
  );
};

interface CustomizeDateRangeDatePickerProps {
  bound: 'start' | 'end';
  onDone?: () => void;
}

export const CustomizeDateRangeDatePicker: React.FC<CustomizeDateRangeDatePickerProps> = ({
  onDone,
  bound,
}) => {
  const {
    start,
    end,
    rollupType = [RollupType.Month],
    fiscalYearStartMonth = 0,
    setDateRange,
  } = useContext(CustomizeDateRangeMenuContext);

  const currentDate = bound === 'start' ? start : end;
  const currentOtherDate = bound === 'start' ? end : start;

  const selected = offsetDateTimeForFY(currentDate, rollupType, fiscalYearStartMonth, 'in');
  const minDate =
    bound === 'start'
      ? MIN_DATE
      : offsetDateTimeForFY(currentOtherDate, rollupType, fiscalYearStartMonth, 'in');
  const maxDate =
    bound === 'end'
      ? MAX_DATE
      : offsetDateTimeForFY(currentOtherDate, rollupType, fiscalYearStartMonth, 'in');

  return (
    <DatePicker
      onDateSelect={(newDate) => {
        const newDateOffset = offsetDateTimeForFY(newDate, rollupType, fiscalYearStartMonth, 'out');
        const newRange =
          bound === 'start' ? [newDateOffset, currentOtherDate] : [currentOtherDate, newDateOffset];
        setDateRange(newRange[0], newRange[1]);
        onDone?.();
      }}
      selected={selected}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default CustomizeDateRangeMenu;
