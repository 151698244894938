import { Draft } from '@reduxjs/toolkit';
import { keyBy, remove } from 'lodash';

import { ExtQueryCreateInput, ExtQueryDeleteInput, ExtQueryUpdateInput } from 'generated/graphql';
import { DatasetSnapshot } from 'reduxStore/models/dataset';
import { ExtQuery, ParsedExtTableQuery } from 'reduxStore/models/extQueries';
import { Layer } from 'reduxStore/models/layers';

export function setExtQueriesFromDatasetSnapshot(layer: Draft<Layer>, dataset: DatasetSnapshot) {
  if (dataset == null || dataset.extQueries == null) {
    layer.extQueries = { byId: {}, allIds: [] };
    return;
  }

  const extQueries = dataset.extQueries.map((extQuery) => {
    const { id, raw, source } = extQuery;
    const extQueryCopy: ExtQuery = {
      id,
      raw,
      source,
      parsed: parseExtQuery(raw),
    };
    return extQueryCopy;
  });

  layer.extQueries = {
    byId: keyBy(extQueries, 'id'),
    allIds: extQueries.map((t) => t.id),
  };
}

export function handleCreateExtQuery(layer: Draft<Layer>, input: ExtQueryCreateInput) {
  const { id, raw, source } = input;
  const extQuery: ExtQuery = {
    id,
    raw,
    source,
    parsed: parseExtQuery(raw),
  };
  layer.extQueries.byId[id] = extQuery;
  layer.extQueries.allIds.push(id);
}

export function handleUpdateExtQuery(layer: Draft<Layer>, input: ExtQueryUpdateInput) {
  const { id, raw } = input;
  const extQuery = layer.extQueries.byId[id];
  if (extQuery == null) {
    return;
  }
  if (raw != null) {
    extQuery.raw = raw;
    extQuery.parsed = parseExtQuery(raw);
  }
}

export function handleDeleteExtQuery(layer: Draft<Layer>, input: ExtQueryDeleteInput) {
  const { id } = input;
  const extQuery = layer.extQueries.byId[id];
  if (extQuery == null) {
    return;
  }
  delete layer.extQueries.byId[id];
  remove(layer.extQueries.allIds, (k) => k === id);
}

function parseExtQuery(raw: string): ParsedExtTableQuery | undefined {
  try {
    return JSON.parse(raw) as ParsedExtTableQuery;
  } catch (e) {
    console.error('Failed to parse ext query', e);
  }
  return undefined;
}
