import keyBy from 'lodash/keyBy';

// Keep this file in sync with currency.go

type Currency = {
  isoCode: string;
  symbol: string;
  name: string;
};

export const CURRENCY_CONFIGS: Currency[] = [
  { isoCode: 'USD', symbol: '$', name: 'US Dollar' },
  { isoCode: 'AUD', symbol: 'A$', name: 'Australian Dollar' },
  { isoCode: 'CAD', symbol: 'C$', name: 'Canadian Dollar' },
  { isoCode: 'EUR', symbol: '€', name: 'Euro' },
  { isoCode: 'GBP', symbol: '£', name: 'British Pound' },
  { isoCode: 'INR', symbol: '₹', name: 'Indian Rupee' },
  { isoCode: 'JPY', symbol: '¥', name: 'Japanese Yen' },
  { isoCode: 'NZD', symbol: 'NZ$', name: 'New Zealand Dollar' },
];

export const CURRENCY_BY_ISO = keyBy(CURRENCY_CONFIGS, 'isoCode');

export const DEFAULT_CURRENCY = 'USD';
