import { Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';

import ActiveCellBorder from 'components/ActiveCellBorder/ActiveCellBorder';
import { AddButton } from 'components/PlanTimelineNavigationPanelContents/AddRowButton';
import { CELL_HEIGHT_IN_PX } from 'config/cells';
import { useAddDriversPopoverContext } from 'config/driverGridContext';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { toPxString } from 'helpers/styles';
import useAddDriverRow from 'hooks/useAddDriverRow';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';

const OFFSET: [number, number] = [0, -CELL_HEIGHT_IN_PX];

interface Props {
  onOpenDriverSelect: () => void;
  isAdding: boolean;
  groupId?: DriverGroupId;
  belowDriverId?: DriverId;
}

const DriverGridAddRowButton: React.FC<Props> = ({
  groupId,
  onOpenDriverSelect,
  isAdding,
  belowDriverId,
}) => {
  const { blockId, gutterWidthInPxString, readOnly } = useBlockContext();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [, setAddDriverPopoverContext] = useAddDriversPopoverContext();
  const nameColumnWidth = useAppSelector((state) =>
    columnWidthSelector(state, { columnType: NAME_COLUMN_TYPE, blockId }),
  );

  const onOpen = useCallback(() => {
    setAddDriverPopoverContext({
      reference: anchorRef.current,
      offset: OFFSET,
      placement: 'bottom-start',
      groupId,
      belowDriverId,
      canFlip: true,
    });
    onOpenDriverSelect();
  }, [onOpenDriverSelect, setAddDriverPopoverContext, belowDriverId, groupId]);

  // use a ref so that we don't end up opening the menu when onOpen's reference changes
  const onOpenRef = useRef(onOpen);
  onOpenRef.current = onOpen;
  useEffect(() => {
    if (isAdding) {
      onOpenRef.current();
    }
  }, [isAdding]);

  const { isSelected } = useCellSelectionStateContext();
  useAddDriverRow({
    isSelected,
    isAdding,
    onEnter: onOpen,
  });

  if (readOnly) {
    return null;
  }

  return (
    <Flex
      zIndex="docked"
      bg={isSelected ? 'selection.100' : 'surface'}
      width="full"
      height={toPxString(CELL_HEIGHT_IN_PX)}
    >
      <Flex position="sticky" width={gutterWidthInPxString} left={0} bg="surface" />
      <Flex
        position="sticky"
        left={gutterWidthInPxString}
        width={nameColumnWidth}
        data-testid="add-driver-cell"
      >
        {isSelected && <ActiveCellBorder />}
        <AddButton ref={anchorRef} text="Add driver" onClick={onOpen} />
      </Flex>
    </Flex>
  );
};

export default DriverGridAddRowButton;
