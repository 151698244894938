import { RootState } from 'reduxStore/reducers/sliceReducers';

export const restorableStateSelector = (state: RootState) => {
  // N.B. not using _.pick so we have some type safety.
  return {
    dataset: { currentLayerId: state.dataset.currentLayerId },
    roadmap: {
      roadmapDimensions: state.roadmap.roadmapDimensions,
      expandedPlans: state.roadmap.expandedPlans,
    },
    modelView: {
      ...state.modelView,
      scrollLeft: undefined,
    },
    formulaInput: {
      recentEntities: state.formulaInput?.recentEntities ?? [],
    },
    navigationSidebar: {
      isCollapsed: state.navigationSidebar.isCollapsed,
      width: state.navigationSidebar.width,
      sections: state.navigationSidebar.sections,
    },
    blockLocalState: {
      localBlockConfigs: state.blockLocalState.localBlockConfigs,
    },
  } as const;
};

export type RestorableState = ReturnType<typeof restorableStateSelector>;
