import { createIcon } from '@chakra-ui/react';

const Lock = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 12 12',
  path: (
    <>
      <rect x="2" y="6" width="8" height="6" rx="1" fill="currentColor" />
      <path
        d="M4 6V3C4 2 4.5 1 6 1C7.5 1 8 2 8 3V6"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default Lock;
