import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Integration, LinkedAccount } from 'generated/graphql';

export type IntegrationToLink =
  | {
      integration: Integration;
      linkedAccount?: never;
    }
  | {
      integration?: never;
      linkedAccount: LinkedAccount;
    };

const initialState = null as IntegrationToLink | null;

const integrationLinkerSlice = createSlice({
  name: 'integrationLinker',
  initialState,
  reducers: {
    startIntegrationLink(state, action: PayloadAction<Integration>) {
      return {
        integration: action.payload,
      };
    },
    relinkIntegration(state, action: PayloadAction<LinkedAccount>) {
      return {
        linkedAccount: action.payload,
      };
    },
    clearIntegrationLink() {
      return null;
    },
  },
});
export const { startIntegrationLink, relinkIntegration, clearIntegrationLink } =
  integrationLinkerSlice.actions;

export default integrationLinkerSlice.reducer;
