import * as Sentry from '@sentry/nextjs';

import {
  DatabaseChange,
  DatabaseComparator,
} from '@features/CompareScenarios/comparators/DatabaseComparator';
import {
  DriverChange,
  DriverComparator,
} from '@features/CompareScenarios/comparators/DriverComparator';
import {
  ActivityLog,
  getActivityFromLayer,
} from '@features/CompareScenarios/comparators/getActivityFromLayer';
import { Layer } from 'reduxStore/models/layers';

const databaseComparator = new DatabaseComparator();
const driverComparator = new DriverComparator();

export function compareLayers(
  currentLayer: Layer,
  mergeLayer: Layer,
): { databases: DatabaseChange[]; drivers: DriverChange[]; activity: ActivityLog } | null {
  try {
    const comparison = {
      databases: databaseComparator.compare(currentLayer, mergeLayer),
      drivers: driverComparator.compare(currentLayer, mergeLayer),
      activity: getActivityFromLayer(currentLayer),
    };

    if (
      comparison.databases.length === 0 &&
      comparison.drivers.length === 0 &&
      Object.keys(comparison.activity).length === 0
    ) {
      return null;
    }
    return comparison;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    return null;
  }
}

export type ChangeStatus = 'created' | 'updated' | 'deleted';

export type Change<ObjectType, FieldsType extends Record<string, Update> = any> = {
  object: ObjectType;
  status: ChangeStatus | null;
  updatesByField: Partial<FieldsType>;
};

type Update<T = any, U = any> =
  | TransitionUpdate<T>
  | ChangesUpdate<U>
  | ChangeUpdate<U>
  | TouchUpdate;

export type TransitionUpdate<T = string> = {
  field: string;
  from: T | null | undefined;
  to: T | null | undefined;
};

export type ChangesUpdate<UpdateChange = Change<any>> = {
  field: string;
  changes: UpdateChange[];
};

export type ChangeUpdate<UpdateChange = Change<any>> = {
  field: string;
  change: UpdateChange;
};

export type TouchUpdate = {
  field: string;
};
