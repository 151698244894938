import { uniq } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { findPlanTimelineRowWithEvent, isPlanTimelineEntity } from 'helpers/planTimeline';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldId } from 'reduxStore/models/businessObjects';
import { DriverId } from 'reduxStore/models/drivers';
import {
  DriverEvent,
  Event,
  EventGroup,
  EventGroupId,
  EventId,
  PlanTimelineItemRef,
  getFlattenedEventIdsForGroup,
  isDriverEvent,
} from 'reduxStore/models/events';
import { DetailPaneState, PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { SelectionState } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { paramSelector } from 'selectors/constSelectors';
import { openDetailPaneSelector } from 'selectors/detailPaneSelectors';
import {
  allRefsForGroupSelector,
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  eventsByIdForLayerSelector,
  parentIdsByEventAndGroupIdsSelector,
} from 'selectors/eventsAndGroupsSelector';
import { pageSelector } from 'selectors/pageBaseSelector';
import { PlanTimelineRow } from 'selectors/planTimelineSelector';
import {
  pageSelectionSelector,
  selectedEventIdsSelector,
  selectedPlanTimelineItemRefSelector,
} from 'selectors/selectionSelector';
import { OptionalParametricSelector, Selector } from 'types/redux';

function isEntityType(ref: PlanTimelineItemRef): ref is PlanTimelineItemRef & { type: 'entity' } {
  return ref.type === 'entity';
}

export const selectedPlanItemsSelector: OptionalParametricSelector<BlockId, PlanTimelineItemRef[]> =
  createCachedSelector(
    pageSelectionSelector,
    paramSelector<BlockId | undefined>(),
    (selection, blockId) => {
      if (selection?.type !== 'eventsAndGroups') {
        return [];
      }

      if (blockId != null && selection.blockId !== blockId) {
        return [];
      }

      return selection.refs;
    },
  )({
    keySelector: (state, blockId) => `${blockId}`,
    selectorCreator: createDeepEqualSelector,
  });

export const selectedEntitiesSelector: OptionalParametricSelector<
  BlockId,
  Array<PlanTimelineItemRef & { type: 'entity' }>
> = createCachedSelector(selectedPlanItemsSelector, (planItems) => {
  return planItems.filter(isEntityType);
})((_, blockId) => `${blockId}`);

export const selectedEntityIdsSelector: OptionalParametricSelector<
  BlockId,
  Array<DriverId | BusinessObjectFieldId>
> = createCachedSelector(selectedEntitiesSelector, (entities) => {
  return entities.map((e) => e.id);
})({
  keySelector: (state, blockId) => `${blockId}`,
  selectorCreator: createDeepEqualSelector,
});

export const blockScopedSelectedEventIdsSelector: OptionalParametricSelector<BlockId, EventId[]> =
  createCachedSelector(
    pageSelectionSelector,
    selectedEventIdsSelector,
    paramSelector<BlockId | undefined>(),
    (selection, selectedEventIds, blockId) => {
      if (selection?.type !== 'eventsAndGroups') {
        return [];
      }

      if (blockId != null && selection.blockId !== blockId) {
        return [];
      }

      return selectedEventIds;
    },
  )({
    keySelector: (state, blockId) => `${blockId}`,
    selectorCreator: createDeepEqualSelector,
  });

export const selectedEventGroupIdsSelector: OptionalParametricSelector<BlockId, EventGroupId[]> =
  createCachedSelector(
    pageSelectionSelector,
    paramSelector<BlockId | undefined>(),
    (selection, blockId) => {
      if (selection?.type !== 'eventsAndGroups') {
        return [];
      }

      if (blockId != null && selection.blockId !== blockId) {
        return [];
      }

      return selection.refs.filter((ref) => ref.type === 'group').map((ref) => ref.id);
    },
  )({
    keySelector: (state, blockId) => `${blockId}`,
    selectorCreator: createDeepEqualSelector,
  });

export const activeInitiativeIdSelector: Selector<EventGroupId | EventId | undefined> =
  createSelector(selectedPlanTimelineItemRefSelector, (ref) => ref?.active.id);

function allParentIds(
  entityId: EventId | EventGroupId,
  parentIdsById: Record<EventId | EventGroupId, EventGroupId | undefined>,
): EventGroupId[] {
  const parentIds: EventGroupId[] = [];
  let currParentId: string | undefined = parentIdsById[entityId];
  while (currParentId != null) {
    parentIds.push(currParentId);
    currParentId = parentIdsById[currParentId];
  }
  return parentIds;
}

export const selectedEventIdsWithoutSelectedParentSelector: Selector<EventId[]> =
  createDeepEqualSelector(
    blockScopedSelectedEventIdsSelector,
    selectedEventGroupIdsSelector,
    parentIdsByEventAndGroupIdsSelector,
    (selectedEventIds, selectedEventGroupIds, parentIdsById) => {
      return selectedEventIds.filter((eventId) => {
        const parentIds = allParentIds(eventId, parentIdsById);
        return !parentIds.some((parentId) => selectedEventGroupIds.includes(parentId));
      });
    },
  );

export const selectedEventGroupIdsWithoutSelectedParentSelector: Selector<EventGroupId[]> =
  createDeepEqualSelector(
    selectedEventGroupIdsSelector,
    parentIdsByEventAndGroupIdsSelector,
    (selectedEventGroupIds, parentIdsById) => {
      return selectedEventGroupIds.filter((eventGroupId) => {
        const parentIds = allParentIds(eventGroupId, parentIdsById);
        return !parentIds.some((parentId) => selectedEventGroupIds.includes(parentId));
      });
    },
  );

const singleSelectedEventIdSelector: OptionalParametricSelector<BlockId, EventId | undefined> =
  createCachedSelector(
    (state: RootState, blockId: BlockId | undefined) =>
      blockScopedSelectedEventIdsSelector(state, blockId),
    (state: RootState, blockId: BlockId | undefined) =>
      selectedEventGroupIdsSelector(state, blockId),
    (selectedEventIds, selectedEventGroupIds) =>
      selectedEventGroupIds.length === 0 ? selectedEventIds[0] : undefined,
  )((state, blockId) => `${blockId}`);

const singleSelectedEventSelector: Selector<Event | undefined> = createSelector(
  singleSelectedEventIdSelector,
  eventsByIdForLayerSelector,
  (selectedEventId, eventsById) =>
    selectedEventId != null ? eventsById[selectedEventId] : undefined,
);

export const singleSelectedDriverEventSelector: Selector<DriverEvent | undefined> = createSelector(
  singleSelectedEventSelector,
  (singleSelectedEvent) =>
    singleSelectedEvent != null && isDriverEvent(singleSelectedEvent)
      ? singleSelectedEvent
      : undefined,
);

export function getFlattenedSelectedEventIds({
  selection,
  eventGroupsById,
  detailPane,
  includeEventsInSameRow,
  timelineRows,
}: {
  selection: SelectionState | null;
  eventGroupsById: Record<EventGroupId, EventGroup>;
  detailPane: DetailPaneState | null;
  includeEventsInSameRow: boolean;
  timelineRows: PlanTimelineRow[] | undefined;
}): EventId[] {
  if (selection?.type === 'eventsAndGroups') {
    const eventIds = selection.refs.flatMap((ref) => {
      if (ref.type === 'event') {
        if (timelineRows == null || !includeEventsInSameRow) {
          return [ref.id];
        }

        const row = findPlanTimelineRowWithEvent(timelineRows, ref.id);
        if (row == null) {
          return [ref.id];
        }

        return isPlanTimelineEntity(row) ? row.events.map((e) => e.id) : [];
      }

      if (ref.type === 'group') {
        return getFlattenedEventIdsForGroup(ref.id, eventGroupsById);
      }

      if (ref.type === 'entity') {
        return ref.refs.map((r) => r.id);
      }

      return [];
    });

    return uniq(eventIds);
  }

  if (detailPane?.type === PaneType.Plan) {
    return getFlattenedEventIdsForGroup(detailPane.eventGroupId, eventGroupsById);
  }

  return [];
}

export const flattenedSelectedEventIdsSelector: Selector<EventId[]> = createDeepEqualSelector(
  pageSelectionSelector,
  eventGroupsWithoutLiveEditsByIdForLayerSelector,
  openDetailPaneSelector,
  (selection, eventGroupsById, detailPane) => {
    return getFlattenedSelectedEventIds({
      timelineRows: undefined,
      selection,
      eventGroupsById,
      detailPane,
      includeEventsInSameRow: false,
    });
  },
);

export const canAutoGroupEventSelection: Selector<boolean> = createSelector(
  selectedEventIdsWithoutSelectedParentSelector,
  selectedEventGroupIdsWithoutSelectedParentSelector,
  flattenedSelectedEventIdsSelector,
  (selectedEventIds, selectedEventGroupIds, flattenedSelectedEventIds) => {
    return (
      (selectedEventIds.length > 0 || selectedEventGroupIds.length > 0) &&
      flattenedSelectedEventIds.length > 0
    );
  },
);

export const hasSelectedEventsSelector: Selector<boolean> = createSelector(
  flattenedSelectedEventIdsSelector,
  (ids) => ids.length > 0,
);

export const autoFocusedEventGroupSelector = createSelector(pageSelector, (page) =>
  page?.autoFocus?.type === 'eventGroup' ? page.autoFocus : null,
);

export const allDescendentRefsOfSelectedEventsAndGroupsSelector: Selector<PlanTimelineItemRef[]> =
  createSelector(
    (state: RootState) => state,
    selectedEventGroupIdsSelector,
    (state, selectedEventGroupIds) => {
      const refs = selectedEventGroupIds.flatMap(
        (groupId) => allRefsForGroupSelector(state, groupId) ?? [],
      );
      return uniqBy(refs, (ref) => ref.id);
    },
  );
