import {
  Avatar,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';

import { ReferralContent } from 'components/ReferralSideBarItem/ReferralSideBarItem';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import { NAVIGATION_PANEL_MIN_WIDTH_PX } from 'config/layout/navigationPanel';
import { toPxString } from 'helpers/styles';
import { useAccessCapabilities } from 'hooks/useAccessCapabilities';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useControlledPopover from 'hooks/useControlledPopover';
import { openSettingsModal, SETTINGS_TABS } from 'reduxStore/reducers/pageSlice';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import BoardingPassIcon from 'vectors/BoardingPass';
import CogIcon from 'vectors/Cog';
import PeaceSignIcon from 'vectors/PeaceSign';

interface SettingsMenuItem extends SelectItem {
  onSelect: () => void;
}

const UserSettingsMenu = () => {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose, contentRef, triggerRef } = useControlledPopover();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const { isOrgAdmin } = useAccessCapabilities();
  const onSelect = useCallback((item: SettingsMenuItem) => {
    item.onSelect?.();
  }, []);

  const authenticatedUser = useAppSelector(authenticatedUserSelector);
  const items = useMemo<SettingsMenuItem[]>(
    () => [
      {
        id: 'invite-to-early-access',
        name: 'Share Runway',
        icon: <BoardingPassIcon marginTop="px" boxSize={5} transition="all .2s" />,
        onSelect: () => onModalOpen(),
      },
      ...(isOrgAdmin
        ? [
            {
              id: 'settings',
              name: 'Settings',
              icon: <CogIcon />,
              onSelect: () => {
                dispatch(
                  openSettingsModal({
                    tabIndex: SETTINGS_TABS[0],
                  }),
                );
                onClose();
              },
            },
          ]
        : []),
      {
        id: 'logout',
        name: 'Log out',
        icon: <PeaceSignIcon />,
        onSelect: () => {
          window.location.href = '/logout';
          onClose();
        },
      },
    ],
    [isOrgAdmin, onModalOpen, dispatch, onClose],
  );

  if (authenticatedUser == null) {
    return null;
  }

  const { name, profileImage } = authenticatedUser;

  return (
    <>
      <Popover
        isLazy
        closeOnBlur={false}
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Flex ref={triggerRef} alignItems="center" cursor="pointer" userSelect="none">
            <Avatar size="xs" name={name} src={profileImage ?? undefined} flexShrink={0} />
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent padding={0} zIndex="popover" ref={contentRef}>
            <SelectMenu
              items={items}
              onSelect={onSelect}
              onClose={onClose}
              width={toPxString(NAVIGATION_PANEL_MIN_WIDTH_PX)}
              startFocusIdx={-1}
            >
              {BaseSelectMenuItem}
            </SelectMenu>
          </PopoverContent>
        </Portal>
      </Popover>
      <Modal isCentered isOpen={isModalOpen} onClose={onModalClose} variant="overlay">
        <ModalOverlay />
        <ModalContent background="transparent" maxWidth="fit-content" boxShadow="unset">
          <ReferralContent />
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserSettingsMenu;
