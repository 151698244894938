import { useContext } from 'react';

import { CellSelectionStateContext, DEFAULT_CELL_SELECTION_STATE } from 'config/cells';

export const useCellSelectionStateContext = () => {
  const cellState = useContext(CellSelectionStateContext);
  if (cellState == null) {
    return DEFAULT_CELL_SELECTION_STATE;
  }

  return cellState;
};
