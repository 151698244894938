import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PreviewDatabaseState = {
  id: string | null;
};

export const initialState: PreviewDatabaseState = {
  id: null,
};

const previewDatabaseSlice = createSlice({
  name: 'previewDatabaseSlice',
  initialState,
  reducers: {
    setPreviewDatabaseBlockId(state, action: PayloadAction<string | null>) {
      state.id = action.payload;
    },
    clearPreviewDatabaseBlockId(state) {
      state.id = null;
    },
  },
});

export const { clearPreviewDatabaseBlockId, setPreviewDatabaseBlockId } =
  previewDatabaseSlice.actions;
export default previewDatabaseSlice.reducer;
