import Joi from 'joi';
import React, { useCallback, useMemo } from 'react';

import EditSelectItemMenu from 'components/EditSelectItemMenu/EditSelectItemMenu';
import { getAttributeValueString, isUserAddedAttribute } from 'helpers/dimensionalDrivers';
import { joiDefault } from 'helpers/errorMessages';
import { isNotNull } from 'helpers/typescript';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { renameAttribute } from 'reduxStore/actions/dimensionMutations';
import { AttributeId } from 'reduxStore/models/dimensions';
import { openDeleteAttributeDialog } from 'reduxStore/reducers/alertDialogSlice';
import { clearEditingDriver } from 'reduxStore/reducers/modelViewSlice';
import { attributesByIdSelector } from 'selectors/dimensionsSelector';

interface Props {
  id: AttributeId;
}

const AttributeEditMenu: React.FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const allAttributes = useAppSelector(attributesByIdSelector);
  const attribute = allAttributes[id];
  const attrValue = getAttributeValueString(attribute);
  const { blockId } = useBlockContext();

  const validator = useMemo(() => {
    const otherAttrNames = Object.values(allAttributes)
      .map((attr) => (attr.id === id ? null : attr.value))
      .filter(isNotNull);
    return Joi.object({
      value: Joi.string()
        .required()
        .min(1)
        .insensitive()
        .invalid(...otherAttrNames)
        .trim()
        .messages(joiDefault('attribute')),
    });
  }, [allAttributes, id]);

  const onDelete = useCallback(() => {
    dispatch(clearEditingDriver());
    dispatch(openDeleteAttributeDialog({ attributeId: id, blockId }));
  }, [id, dispatch, blockId]);

  const saveRename = useCallback(
    (newValue: string) => {
      dispatch(renameAttribute({ id, value: newValue }));
    },
    [id, dispatch],
  );

  return (
    <EditSelectItemMenu
      onRename={saveRename}
      defaultValue={attrValue}
      onDelete={onDelete}
      validationSchema={validator}
      isDisabled={!isUserAddedAttribute(attribute)}
    />
  );
};

export default AttributeEditMenu;
