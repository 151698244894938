import { deepEqual } from 'fast-equals';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import uniqWith from 'lodash/uniqWith';
import { DateTime } from 'luxon';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { ValueType } from 'generated/graphql';
import { getMonthKey, getMonthKeysForRange } from 'helpers/dates';
import { getDriverDependencies } from 'helpers/driverDependencies';
import { calculate } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { FormulaEvaluator } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import { EvaluatorDriver } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { SelectorWithLayerParam, getCacheKeyForLayerSelector } from 'helpers/layerSelectorFactory';
import { isNotNull } from 'helpers/typescript';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectId } from 'reduxStore/models/businessObjects';
import { DriverId, DriverType } from 'reduxStore/models/drivers';
import { NumericTimeSeriesWithEmpty } from 'reduxStore/models/timeSeries';
import { ObjectFieldEvaluation, ObjectSpecEvaluation } from 'reduxStore/models/value';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { accessResourcesByIdSelector } from 'selectors/accessResourcesByIdSelector';
import { entityMonthlyObjectSpecEvaluationsSelector } from 'selectors/calculationsSelector';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';
import { dependenciesListenerEvaluatorSelector } from 'selectors/dependenciesListenerEvaluatorSelector';
import { mustDetailPaneDriverIdSelector } from 'selectors/driverDetailPaneSelector';
import { evaluatorDriversByIdForLayerSelector } from 'selectors/driversSelector';
import { formulaEvaluatorWithoutLiveEditingSelector } from 'selectors/formulaEvaluatorSelector';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import { detailPaneDateRangeSelector } from 'selectors/pageDateRangeSelector';
import { isCalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';
import { Dependency } from 'types/dependencies';

import { shouldDoSynchronousCalculationsSelector } from './inspectorSelector';

function calculateObjectDependencies(
  driver: EvaluatorDriver & { type: DriverType.Basic },
  dateRange: DateTime[],
  evaluator: FormulaEvaluator,
): Map<MonthKey, ObjectSpecEvaluation[]> {
  const [startDateTime, endDateTime] = dateRange;
  const monthKeys = getMonthKeysForRange(startDateTime, endDateTime);
  const evaluationsByMonthKey = monthKeys.reduce(
    (values: Map<MonthKey, ObjectSpecEvaluation[]>, monthKey: MonthKey) => {
      const calcResult = calculate({
        evaluator,
        entityId: { type: 'driver', id: driver.id },
        monthKey,
        visited: new Set(),
        newlyAddedCacheKeys: new Set(),
        // we want to calculate dependencies for actuals months as well
        forceComputeForecast: true,
      });

      const objectSpecEvaluations =
        isCalculationError(calcResult) || calcResult == null
          ? undefined
          : calcResult.objectSpecEvaluations;
      if (objectSpecEvaluations != null) {
        return values.set(monthKey, objectSpecEvaluations);
      }

      return values;
    },
    new Map(),
  );

  return evaluationsByMonthKey;
}

const directDependencyMatrixSelector: SelectorWithLayerParam<Record<DriverId, Dependency[]>> =
  createCachedSelector(
    evaluatorDriversByIdForLayerSelector,
    dependenciesListenerEvaluatorSelector,
    dimensionalPropertyEvaluatorSelector,
    (driversById, evaluator, dimensionalPropertyEvaluator) => {
      return mapValues(driversById, (driver) =>
        driver == null
          ? []
          : getDriverDependencies({
              driver,
              evaluator,
              dimensionalPropertyEvaluator,
            }),
      );
    },
  )(getCacheKeyForLayerSelector);

type ObjectDependencyMap = Map<
  // see getObjectCollectionKeyString
  string,
  {
    specId: BusinessObjectSpecId;
    fieldSpecId: BusinessObjectFieldSpecId | undefined;
    timeSeries: NumericTimeSeriesWithEmpty;
    objectTimeSeriesById: Record<BusinessObjectId, NumericTimeSeriesWithEmpty>;
    // This is only populated if the evaluated field is a driver property
    subDriverIds?: DriverId[];
  }
>;

const detailPaneEntityMonthlyObjectSpecEvaluationsSelector: SelectorWithLayerParam<
  Map<MonthKey, ObjectSpecEvaluation[]>
> = (state, params) => {
  const id = mustDetailPaneDriverIdSelector(state);
  const range = detailPaneDateRangeSelector(state);
  const start = getMonthKey(range[0]);
  const end = getMonthKey(range[1]);
  const layerId = getGivenOrCurrentLayerId(state, params);
  return entityMonthlyObjectSpecEvaluationsSelector(state, {
    id,
    start,
    end,
    layerId,
  });
};

export const getObjectCollectionKeyString = (
  specId: string,
  fieldSpecId: string | undefined,
): string => `${specId},${fieldSpecId}`;

type ProcessedFieldEvaluation = Omit<ObjectFieldEvaluation, 'evaluation'> & {
  value: number | undefined;
};

type ProcessedSpecEvaulation = Omit<ObjectSpecEvaluation, 'fieldEvaluations'> & {
  fieldEvaluations: ProcessedFieldEvaluation[];
};
export const objectDependenciesSelectorForDetailPaneDriver: SelectorWithLayerParam<ObjectDependencyMap> =
  createCachedSelector(
    evaluatorDriversByIdForLayerSelector,
    mustDetailPaneDriverIdSelector,
    detailPaneDateRangeSelector,
    formulaEvaluatorWithoutLiveEditingSelector,
    accessResourcesByIdSelector,
    accessCapabilitiesSelector,
    detailPaneEntityMonthlyObjectSpecEvaluationsSelector,
    shouldDoSynchronousCalculationsSelector,
    (
      driversById,
      driverId,
      [start, end],
      evaluator,
      accessResourcesById,
      accessCapabilities,
      asyncMonthlyObjectSpecEvaluations,
      shouldDoSynchronousCalculations,
      // eslint-disable-next-line max-params
    ) => {
      let objectFieldEvaluationsByMonthKey: Map<MonthKey, ObjectSpecEvaluation[]>;

      if (!shouldDoSynchronousCalculations) {
        objectFieldEvaluationsByMonthKey = asyncMonthlyObjectSpecEvaluations;
      } else {
        // We can't do this driver existence/type check in the case where the
        // webworkers are on as it won't work for named versions. This is
        // because we are not loading named version data into the main thread.
        const driver = driversById[driverId];
        if (driver == null || driver.type !== DriverType.Basic) {
          return new Map();
        }
        objectFieldEvaluationsByMonthKey = calculateObjectDependencies(
          driver,
          [start, end],
          evaluator,
        );
      }

      const processedEvals = new Map<string, ProcessedSpecEvaulation[]>();

      objectFieldEvaluationsByMonthKey.forEach((deps, monthKey) => {
        const processed = deps.map((d) => {
          return {
            specId: d.specId,
            evaluation: d.evaluation,
            fieldEvaluations: d.fieldEvaluations
              .filter((ev) => ev.evaluation.every((v) => v != null && v.type === ValueType.Number))
              .map((ev) => {
                const shouldRedact =
                  ev.fieldSpecId != null &&
                  accessCapabilities.shouldDenyRoleAccessByAccessEntity(
                    accessResourcesById[ev.fieldSpecId],
                  );

                return {
                  fieldSpecId: ev.fieldSpecId,
                  objectId: ev.objectId,
                  value: shouldRedact ? undefined : (ev.evaluation[0]?.value as number | undefined),
                  subDriverId: ev.subDriverId,
                };
              })
              .filter(isNotNull),
          };
        });
        processedEvals.set(monthKey, processed);
      });

      // flatten out all our evaluations so that we can put it into a more ergonomic form to render in the UI
      const allEvaluations = [...processedEvals.entries()].flatMap(([monthKey, deps]) => {
        return deps.flatMap(({ specId, evaluation, fieldEvaluations }) => {
          return fieldEvaluations.flatMap(({ fieldSpecId, objectId, value, subDriverId }) => ({
            objectId,
            specEvaluation: evaluation,
            fieldSpecId,
            value,
            specId,
            monthKey,
            subDriverId,
          }));
        });
      });

      // all the specId/fieldSpecId combinations. we group objects with the same key together in the UI
      const objectSpecKeys = uniqWith(
        allEvaluations.map(({ specId, fieldSpecId }) => ({
          specId,
          fieldSpecId,
        })),
        deepEqual,
      );

      const evaluationsByKey = groupBy(allEvaluations, (ev) =>
        getObjectCollectionKeyString(ev.specId, ev.fieldSpecId),
      );

      const map: ObjectDependencyMap = new Map();
      objectSpecKeys.forEach((k) => {
        const { specId, fieldSpecId } = k;
        const evaluationsForKey =
          evaluationsByKey[getObjectCollectionKeyString(specId, fieldSpecId)];
        const specEvaluationTimeSeries: NumericTimeSeriesWithEmpty = {};
        let hasSingleEvaluationTimeSeries = true;
        evaluationsForKey.forEach(({ monthKey, specEvaluation }) => {
          const existingValue = specEvaluationTimeSeries[monthKey];
          if (existingValue != null && existingValue !== specEvaluation) {
            // if there are multiple time series for a given specId/fieldSpecId pair, omit the time series
            hasSingleEvaluationTimeSeries = false;
          }
          specEvaluationTimeSeries[monthKey] = specEvaluation;
        });
        const byObjectId = groupBy(evaluationsForKey, (ev) => ev.objectId);
        const byObjectIdByMonthKey = mapValues(byObjectId, (values) =>
          keyBy(values, (ev) => ev.monthKey),
        );
        const objectIds = Object.keys(byObjectIdByMonthKey);

        // build a time series for each object from its month key evaluations
        const objectTimeSeriesById = objectIds.reduce((agg, objectId) => {
          const objectTimeSeries = mapValues(byObjectIdByMonthKey[objectId], (v) => v.value);
          return { ...agg, [objectId]: objectTimeSeries };
        }, {});

        // Build a de-duped set of driverIds that were evaluated if this field was a driver property
        const driverIds = Array.from(
          new Set(evaluationsForKey.map((e) => e.subDriverId).filter(isNotNull)),
        );

        map.set(getObjectCollectionKeyString(k.specId, k.fieldSpecId), {
          specId: k.specId,
          fieldSpecId: k.fieldSpecId,
          timeSeries: hasSingleEvaluationTimeSeries ? specEvaluationTimeSeries : {},
          objectTimeSeriesById,
          subDriverIds: driverIds,
        });
      });

      return map;
    },
  )(getGivenOrCurrentLayerId);

// Maps BusinessObjectFieldSpecs to all Drivers that directly depend on it.
export const reverseDirectObjectFieldDependencyMatrixSelector = createSelector(
  directDependencyMatrixSelector,
  (directDriverDependencyMatrix) => {
    return getReverseDependenciesOfType(['businessObjectFieldSpec'], directDriverDependencyMatrix);
  },
);

// Maps BusinessObjects to all Drivers that directly depend on it.
export const reverseDirectObjectSpecDependencyMatrixSelector = createSelector(
  directDependencyMatrixSelector,
  (directDriverDependencyMatrix) => {
    return getReverseDependenciesOfType(['businessObjectSpec'], directDriverDependencyMatrix);
  },
);

// Maps Drivers to all Drivers that directly depend on it.
export const reverseDirectDriverDependencyMatrixSelector: SelectorWithLayerParam<
  Record<DriverId, Array<Dependency['id']>>
> = createCachedSelector(directDependencyMatrixSelector, (directDriverDependencyMatrix) => {
  return getReverseDependenciesOfType(['driver', 'extDriver'], directDriverDependencyMatrix);
})(getCacheKeyForLayerSelector);

export const reverseDirectDimensionDependencyMatrixSelector = createSelector(
  directDependencyMatrixSelector,
  (directDriverDependencyMatrix) => {
    return getReverseDependenciesOfType(['dimension'], directDriverDependencyMatrix);
  },
);

export const reverseDirectAttributeDependencyMatrixSelector = createSelector(
  directDependencyMatrixSelector,
  (directDriverDependencyMatrix) => {
    return getReverseDependenciesOfType(['attribute'], directDriverDependencyMatrix);
  },
);

export const reverseDirectDriverDependencyFlattenedDimMatrixSelector: SelectorWithLayerParam<
  Record<DriverId, DriverId[]>
> = createCachedSelector(
  reverseDirectDriverDependencyMatrixSelector,
  evaluatorDriversByIdForLayerSelector,
  (reverseDirectDriverDependencyMatrix, driversById) => {
    return flattenMatrixDimDrivers(reverseDirectDriverDependencyMatrix, driversById);
  },
)(getCacheKeyForLayerSelector);

const getReverseDependenciesOfType = (
  allowedTypes: Array<Dependency['type']>,
  depMatrix: Record<DriverId, Dependency[]>,
) => {
  const result: Record<string, DriverId[]> = {};
  for (const driverId in depMatrix) {
    for (const dep of depMatrix[driverId]) {
      if (!allowedTypes.includes(dep.type)) {
        continue;
      }
      if (result[dep.id] == null) {
        result[dep.id] = [driverId];
      } else if (!result[dep.id].includes(driverId)) {
        result[dep.id].push(driverId);
      }
    }
  }
  return result;
};

const flattenMatrixDimDrivers = (
  matrix: Record<string, DriverId[]>,
  driversById: Record<string, EvaluatorDriver | undefined>,
): Record<DriverId, DriverId[]> => {
  const flattenDimDriverIds = (driverIds: DriverId[]) => {
    return driverIds.flatMap((id) => {
      // could be a deleted driver
      const driver = driversById[id];
      if (driver == null) {
        return [];
      }
      if (driver.type === DriverType.Dimensional) {
        return matrix[id] ?? [];
      }
      return id;
    });
  };

  return mapValues(matrix, (driverIds) => flattenDimDriverIds(driverIds));
};
