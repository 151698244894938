import { Box, Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import DriverSearchResult from 'components/DriverSearchResult/DriverSearchResult';
import MenuHeader from 'components/MenuHeader/MenuHeader';
import SearchInput from 'components/SearchInput/SearchInput';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import DriverSelectMenuFooter from 'components/SelectMenu/DriverSelectMenuFooter';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuItem from 'components/SelectMenu/SelectMenuItem';
import { DriverType } from 'generated/graphql';
import { stopEventPropagation } from 'helpers/browserEvent';
import { getSearchName } from 'helpers/drivers';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useFilteredDriverSearch from 'hooks/useFilteredDriverSearch';
import { BusinessObjectSpec } from 'reduxStore/models/businessObjectSpecs';
import { Attribute } from 'reduxStore/models/dimensions';
import { DimensionalDriver, Driver } from 'reduxStore/models/drivers';
import {
  attributesBySubDriverIdSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import PlusIcon from 'vectors/Plus';

const SECTIONS: Section[] = [
  {
    id: 'drivers',
    name: 'Drivers',
    maxResults: 5,
    showMore: true,
  },
  {
    id: 'databases',
    name: 'Databases',
    maxResults: 5,
    showMore: true,
  },
];

type Item = SelectItem &
  (
    | { type: 'driver'; driver: Driver; attributes: Attribute[] }
    | { type: 'objectSpec'; spec: BusinessObjectSpec }
  );

const SEARCH_KEYS = ['name'];
interface DriverSubMenuProps {
  existingDriverId?: string;
  onBack?: () => void;
  onClose: () => void;
  onAddExistingDriver: (driverId: string) => void;
  onCreateNewDriver: (newDriverName?: string) => void;
  // This is to disable the "Create new driver" option
  disableNewDriver?: boolean;
}

function isDimensionalDriver(driver: Driver): driver is DimensionalDriver {
  return driver.type === DriverType.Dimensional;
}

const DriverSubMenu = ({
  existingDriverId,
  onBack,
  onClose,
  onAddExistingDriver,
  onCreateNewDriver,
  disableNewDriver,
}: DriverSubMenuProps) => {
  const { query, setQuery, drivers } = useFilteredDriverSearch({
    // should exlcude drivers already addded to db
    // this will be needed when we add the ability to add existing drivers to a db
    excludeItemIds: [],
    // This is to ensure we can use the top-level drivers (dimensional drivers + basic drivers that do not have a parent dimensional driver)
    shouldUseTopLevelDrivers: true,
  });

  const dimDrivers = drivers.filter(isDimensionalDriver);
  const { blockId } = useBlockContext();
  const businessObjectSpec = useAppSelector((state) =>
    businessObjectSpecForBlockSelector(state, blockId),
  );

  const databaseDimensionKeyIds = useMemo(() => {
    if (businessObjectSpec == null) {
      return new Set();
    }
    const dimIds = businessObjectSpec.collection?.dimensionalProperties
      .filter((p) => p.isDatabaseKey)
      .map((p) => p.dimension.id);
    return new Set(dimIds);
  }, [businessObjectSpec]);
  const driversById = useAppSelector(driversByIdForLayerSelector);

  const mappedDriverId = useMemo(() => {
    if (existingDriverId == null) {
      return undefined;
    }
    const driver = driversById[existingDriverId];
    if (driver == null) {
      return undefined;
    }
    return driver.driverMapping?.driverId;
  }, [existingDriverId, driversById]);

  const attributesBySubDriverId = useAppSelector(attributesBySubDriverIdSelector);

  const createDriverOption = useMemo(() => {
    if (disableNewDriver) {
      return [];
    }
    return [
      {
        id: 'customOption',
        icon: <PlusIcon />,
        render: (q: string) => <CustomCreateOption label="Create driver" helperText={q} />,
        onSelect: (newDriverName: string) => {
          onCreateNewDriver(newDriverName);
          onClose();
        },
      },
    ];
  }, [disableNewDriver, onClose, onCreateNewDriver]);

  const onSelectDriver = useCallback(
    (item: Item) => onAddExistingDriver(item.id),
    [onAddExistingDriver],
  );

  const onSelect = useCallback((item: Item) => onSelectDriver(item), [onSelectDriver]);

  const items: Item[] = useMemo(
    () => [
      ...dimDrivers
        .filter(
          (driver) => driver.id !== existingDriverId && driver.type === DriverType.Dimensional,
        )
        .map((driver) => ({
          type: 'driver' as const,
          id: driver.id,
          name: driver.name,
          isChecked: driver.id === mappedDriverId,
          attributes: attributesBySubDriverId[driver.id],
          searchableName: getSearchName({
            name: driver.name,
            attributes: attributesBySubDriverId[driver.id],
          }),
          footer: <DriverSelectMenuFooter driverId={driver.id} />,
          driver,
        }))
        .sort((a, b) => {
          // Selected Driver should go to the top
          if (a.isChecked && !b.isChecked) {
            return -1;
          }
          if (!a.isChecked && b.isChecked) {
            return 1;
          }

          // After sort by the number of shared driver and business spec dimensions
          const aDims = a.driver.dimensions.map(({ id }) => id);
          const bDims = b.driver.dimensions.map(({ id }) => id);

          const aNumSharedDimsWithSpec = aDims.filter((dimId) =>
            databaseDimensionKeyIds.has(dimId),
          ).length;
          const bNumSharedDimsWithSpec = bDims.filter((dimId) =>
            databaseDimensionKeyIds.has(dimId),
          ).length;

          return bNumSharedDimsWithSpec - aNumSharedDimsWithSpec;
        }),
    ],
    [
      dimDrivers,
      existingDriverId,
      mappedDriverId,
      attributesBySubDriverId,
      databaseDimensionKeyIds,
    ],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Box py={1} px={0}>
      <MenuHeader
        title="Select a driver"
        // Disable the back button when there is no existing driver as we are showing this in a submenu popover
        onClose={existingDriverId == null ? onClose : undefined}
        onBack={existingDriverId == null ? onBack : undefined}
      />
      <Box py={0} px={0}>
        <Flex flexDir="column" w="full" onClick={stopEventPropagation}>
          <Flex padding={2} pb="px" columnGap={1} alignItems="center">
            <SearchInput
              ref={inputRef}
              placeholder="Add driver…"
              query={query}
              setQuery={setQuery}
            />
          </Flex>
        </Flex>
        <SelectMenu
          items={items}
          query={query}
          searchKeys={SEARCH_KEYS}
          onSelect={onSelect}
          onClose={onClose}
          customOptions={createDriverOption}
          sections={SECTIONS}
          maxHeight="15rem"
        >
          {({ item, isFocused, idx }) => {
            switch (item.type) {
              case 'driver': {
                return (
                  <DriverSearchResult
                    driver={item.driver}
                    isFocused={isFocused}
                    idx={idx}
                    isChecked={item.isChecked}
                  />
                );
              }
              case 'objectSpec': {
                return (
                  <SelectMenuItem
                    icon={item.icon}
                    name={item.name}
                    isFocused={isFocused}
                    isChecked={item.isChecked}
                    idx={idx}
                  />
                );
              }
              default: {
                return null;
              }
            }
          }}
        </SelectMenu>
      </Box>
    </Box>
  );
};
export default DriverSubMenu;
