import * as Sentry from '@sentry/nextjs';
import { createCachedSelector } from 're-reselect';

import {
  DATABASE_BLOCK_NAME,
  OBJECT_DETAIL_BLOCK_NAME,
  getDatabaseInternalPageType,
} from 'config/internalPages/databasePage';
import { BlockType } from 'generated/graphql';
import { BlockId, BlocksPage } from 'reduxStore/models/blocks';
import { BusinessObjectSpecId } from 'reduxStore/models/businessObjectSpecs';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blocksPageForInternalPageTypeSelector } from 'selectors/blocksPagesSelector';
import { blocksByIdSelector } from 'selectors/blocksSelector';
import { ParametricSelector } from 'types/redux';

const databasePageSelector: ParametricSelector<BusinessObjectSpecId, BlocksPage | undefined> = (
  state: RootState,
  specId: BusinessObjectSpecId,
) => blocksPageForInternalPageTypeSelector(state, getDatabaseInternalPageType(specId));

export const databaseBlockIdSelector: ParametricSelector<BusinessObjectSpecId, BlockId | null> =
  createCachedSelector(databasePageSelector, blocksByIdSelector, (page, blocksById) => {
    const blockId = page?.blockIds.find((id) => blocksById[id]?.name === DATABASE_BLOCK_NAME);
    if (blockId == null) {
      Sentry.withScope((scope: Sentry.Scope) => {
        scope.setLevel('warning');
        Sentry.captureMessage('Missing database block on database page');
      });
      return null;
    }

    return blockId;
  })((_state, objectSpecId) => objectSpecId);

export const databaseDriverBlockIdSelector: ParametricSelector<
  BusinessObjectSpecId,
  BlockId | undefined
> = createCachedSelector(databasePageSelector, blocksByIdSelector, (page, blocksById) => {
  const blockId = page?.blockIds.find((id) => blocksById[id]?.type === BlockType.DriverGrid);

  return blockId;
})((_state, objectSpecId) => objectSpecId);

export const businessObjectDetailBlockIdSelector: ParametricSelector<
  BusinessObjectSpecId,
  BlockId
> = createCachedSelector(databasePageSelector, blocksByIdSelector, (page, blocksById) => {
  const blockId = page?.blockIds.find((id) => blocksById[id]?.name === OBJECT_DETAIL_BLOCK_NAME);
  if (blockId == null) {
    throw new Error('could not find detail block on database page');
  }

  return blockId;
})((_state, objectSpecId) => objectSpecId);
