import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'TemplateIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 4,
  },
  path: (
    <g fill="currentColor">
      <path d="M6.17 2H3.536C2.693 2 2 2.698 2 3.546v2.651c0 .849.693 1.547 1.536 1.547H6.17c.842 0 1.536-.698 1.536-1.547v-2.65C7.706 2.697 7.012 2 6.17 2Zm.22 4.197c0 .124-.097.221-.22.221H3.536a.218.218 0 0 1-.22-.22V3.545c0-.123.097-.22.22-.22H6.17c.123 0 .22.097.22.22v2.651ZM13.543 9.89l-1.457-1.466a1.53 1.53 0 0 0-2.168 0L8.461 9.89a1.565 1.565 0 0 0 0 2.191l1.457 1.467c.299.3.694.45 1.089.45s.79-.15 1.088-.45l1.457-1.467c.597-.6.597-1.582 0-2.191h-.008Zm-.93 1.255-1.457 1.467a.211.211 0 0 1-.307 0L9.39 11.146c-.087-.089-.087-.23 0-.31L10.85 9.37s.096-.062.158-.062a.21.21 0 0 1 .158.062l1.457 1.467a.214.214 0 0 1 0 .31h-.009ZM9.33 7.744h3.265c.5 0 .94-.257 1.186-.69a1.38 1.38 0 0 0 0-1.378l-1.633-2.854a1.352 1.352 0 0 0-1.185-.69c-.492 0-.94.257-1.185.69L8.145 5.676a1.38 1.38 0 0 0 0 1.378c.246.433.693.69 1.185.69Zm1.633-4.286s.044.017.044.026l1.632 2.855-.043.079H9.33l-.044-.08 1.686-2.88h-.01ZM4.853 8.185C3.282 8.185 2 9.475 2 11.057c0 1.582 1.282 2.872 2.853 2.872 1.571 0 2.853-1.29 2.853-2.872 0-1.582-1.282-2.872-2.853-2.872Zm0 4.419a1.549 1.549 0 0 1-1.536-1.547c0-.848.693-1.546 1.536-1.546.843 0 1.536.698 1.536 1.546 0 .848-.693 1.547-1.536 1.547Z" />
    </g>
  ),
});
