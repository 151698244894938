import { useCallback, useRef } from 'react';

export const SCROLL_BUFFER_MS = 250;

export function useIsScrolling(
  options: { onScrollEnd?: () => void; scrollBufferMs?: number } = {},
) {
  const { onScrollEnd, scrollBufferMs } = options;
  const isScrollingTimeoutRef = useRef<number | null>(null);
  const setIsScrolling = useCallback(() => {
    function clearIsScrollingTimeout() {
      if (isScrollingTimeoutRef.current != null) {
        window.clearTimeout(isScrollingTimeoutRef.current);
      }
    }

    clearIsScrollingTimeout();
    isScrollingTimeoutRef.current = window.setTimeout(() => {
      isScrollingTimeoutRef.current = null;
      onScrollEnd?.();
    }, scrollBufferMs ?? SCROLL_BUFFER_MS);

    return clearIsScrollingTimeout;
  }, [onScrollEnd, scrollBufferMs]);

  const getIsScrolling = useCallback(() => isScrollingTimeoutRef.current != null, []);

  return {
    getIsScrolling,
    setIsScrolling,
  };
}
