import { noop } from 'lodash';
import React from 'react';

import SettingsPopoverContentBaseComponent from 'components/BusinessObjectTable/SettingsPopoverContents/SettingsPopoverContentBaseComponent';
import {
  DELETE_SECTION_ID,
  EDIT_SECTION_ID,
  INTEGRATION_SECTION_ID,
  useDimensionalPropertyContextMenuItems,
} from 'components/BusinessObjectTable/SettingsPopoverContents/useContextMenuItems';
import { Section } from 'components/SelectMenu/SelectMenu';
import { DimensionalPropertyId } from 'reduxStore/models/collections';

const SECTIONS: Section[] = [
  { id: EDIT_SECTION_ID },
  { id: INTEGRATION_SECTION_ID },
  { id: DELETE_SECTION_ID },
];

interface Props {
  isAutoFocused: boolean;
  dimensionalPropertyId: DimensionalPropertyId;
  onClose?: () => void;
  isOpen?: boolean;
}

const DimensionalPropertySettingsPopoverContent = React.forwardRef<HTMLDivElement, Props>(
  ({ isAutoFocused, dimensionalPropertyId, onClose, isOpen }, ref) => {
    const items = useDimensionalPropertyContextMenuItems({
      dimensionalPropertyId,
      onClose: onClose ?? noop,
    });

    return (
      <SettingsPopoverContentBaseComponent
        ref={ref}
        isAutoFocused={isAutoFocused}
        isOpen={isOpen}
        items={items}
        onClose={onClose}
        sections={SECTIONS}
        dimPropertyId={dimensionalPropertyId}
      />
    );
  },
);

export default React.memo(DimensionalPropertySettingsPopoverContent);
