import * as Sentry from '@sentry/nextjs';
import { isError } from 'lodash';

import { RUNWAY_ERROR_MESSAGES } from 'config/errors';
import { OrgRole } from 'generated/graphql';
import { downloadNamedVersions } from 'helpers/downloadNamedVersions';
import { DatasetResult } from 'helpers/fetchNamedVersion';
import { isRunwayEmployee } from 'helpers/user';
import { navigateToLayer } from 'reduxStore/actions/navigateTo';
import {
  convertDatasetGQLToDatasetSnapshot,
  filterLayersAndBlocksPages,
} from 'reduxStore/models/dataset';
import { initializeLockedSnapshot } from 'reduxStore/reducers/datasetSlice';
import { showErrorModal } from 'reduxStore/reducers/errorSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppDispatch, AsyncAppThunk } from 'reduxStore/store';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { loggedInUserSelector } from 'selectors/loginSelector';
import { orgRoleSelector, selectedOrgSelector } from 'selectors/selectedOrgSelector';
import { allAccessResourcesSelector } from 'selectors/sharingSelector';
import { InitializeNamedVersion } from 'workers/formulaCalculator/types';

const makeDownloadHandler = ({
  dispatch,
  getState,
  userId,
  orgRole,
  isEmployee,
}: {
  dispatch: AppDispatch;
  getState: () => RootState;
  userId: string;
  orgRole?: OrgRole;
  isEmployee: boolean;
}) => {
  return ({ dataset, mutationId }: DatasetResult) => {
    const state = getState();
    const accessResources = allAccessResourcesSelector(state);
    const currentLayerId = currentLayerIdSelector(state);
    const args = {
      snapshot: convertDatasetGQLToDatasetSnapshot(
        filterLayersAndBlocksPages({
          dataset,
          userId,
          isEmployee,
          orgRole,
          accessResources,
          requestedLayerId: mutationId,
        }),
      ),
      mutationId,
      isSelectedLayer: mutationId === currentLayerId,
    };
    dispatch(initializeLockedSnapshot(args));

    const msg: InitializeNamedVersion = { type: 'initializeNamedVersion', data: mutationId };
    window.formulaCalculator?.postMessage(msg);
  };
};

export const jumpToNamedVersion =
  (mutationId: string): AsyncAppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { orgId } = state.dataset;
    const snapshot = state.dataset.snapshots[mutationId];

    const currentUser = loggedInUserSelector(state);
    const orgRole = orgRoleSelector(state);
    const selectedOrg = selectedOrgSelector(state);
    if (!currentUser || !selectedOrg) {
      return;
    }
    const isEmployee = isRunwayEmployee(currentUser?.authenticatedUser);

    if (orgId == null) {
      Sentry.captureException(new Error('Expected orgId to be defined'));
      return;
    }
    if (snapshot != null) {
      Sentry.addBreadcrumb({
        message: 'Attempting to time travel to named version that is available on client',
        data: {
          mutationId,
          orgId,
        },
      });
      dispatch(navigateToLayer(mutationId));
      return;
    }

    // TODO: put up banner

    const handler = makeDownloadHandler({
      dispatch,
      getState,
      userId: currentUser.id,
      isEmployee,
      orgRole,
    });

    const [downloadedSnapshot] = await downloadNamedVersions([mutationId], orgId, handler);
    if (isError(downloadedSnapshot)) {
      console.error(downloadedSnapshot);
      Sentry.captureException(new Error('Failed to download named version when time traveling'));
      dispatch(showErrorModal(RUNWAY_ERROR_MESSAGES.NetworkError));
      return;
    }

    dispatch(navigateToLayer(mutationId));
  };
