import { DEFAULT_PLAN_NAME } from '@features/Plans';
import { DatasetMutationInput } from 'generated/graphql';
import { DEFAULT_EVENT_GROUP_ID } from 'reduxStore/models/events';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { defaultEventGroupSelector } from 'selectors/eventsAndGroupsSelector';

const NEW_DEFAULT_EVENT_GROUP_MUTATION = {
  id: DEFAULT_EVENT_GROUP_ID,
  name: DEFAULT_PLAN_NAME,
  ownerId: '',
  ownerName: '',
};

// Creates the default event group if necessary
// The reason this exists as a mutation transform right before submission instead of during
// mutation generation is so the developer can just assume that it exists, and doesn't have
// to worry about creating it / checking if it exists before creating it.
// Practically speaking, we ran into bugs where we were trying to create the default event
// group multiple times when doing mutation generation in loops. By doing it here, it ensures
// that it only gets created once.
function applyDefaultEventGroupMutationTransform(
  state: RootState,
  mutation: DatasetMutationInput,
): void {
  const existingDefaultEventGroup = defaultEventGroupSelector(state);
  if (existingDefaultEventGroup != null) {
    return;
  }

  const hasNewDefaultEventGroup = mutation.newEventGroups?.some(
    (eg) => eg.id === DEFAULT_EVENT_GROUP_ID,
  );
  if (hasNewDefaultEventGroup) {
    return;
  }

  // Do this check last, as it's the least performant and likely that one of the above
  // checks will short-circuit anyways.
  const hasNewEventInDefaultEventGroup = mutation.newEvents?.some(
    (e) => e.parentId === DEFAULT_EVENT_GROUP_ID,
  );
  if (!hasNewEventInDefaultEventGroup) {
    return;
  }

  mutation.newEventGroups ??= [];
  mutation.newEventGroups.push(NEW_DEFAULT_EVENT_GROUP_MUTATION);
}

// Applies transforms to the submitted mutation.
// This is an opaque pattern and should be used sparingly.
// It can be useful in cases where generating the parts of the mutation before submission
// is not practical, or relying on the developer to properly generate the mutation is fragile.
export function applyMutationTransforms(state: RootState, mutation: DatasetMutationInput): void {
  applyDefaultEventGroupMutationTransform(state, mutation);
}
