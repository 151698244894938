import { useEffect } from 'react';

import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { clearAutoFocus } from 'reduxStore/reducers/pageSlice';
import { autoFocusedBlockIdSelector } from 'selectors/blocksPagesSelector';

export const useBlockAutoFocus = (onAutofocus: () => void) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const autoFocusedBlockId = useAppSelector(autoFocusedBlockIdSelector);
  const isAutoFocused = blockId === autoFocusedBlockId;
  useEffect(() => {
    if (isAutoFocused) {
      onAutofocus();
      dispatch(clearAutoFocus());
    }
  }, [dispatch, onAutofocus, isAutoFocused]);
};
