import React from 'react';

import DriverBeforeAfterImpactBadge from 'components/DriverBeforeAfterImpactBadge/DriverBeforeAfterImpactBadge';
import ObjectImpactBadge from 'components/ObjectImpactBadge/ObjectImpactBadge';
import TotalDriverImpactBadge from 'components/TotalDriverImpactBadge/TotalDriverImpactBadge';
import { ImpactDisplayType } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { Event, isDriverEvent } from 'reduxStore/models/events';
import { impactDisplayTypeForBlockSelector } from 'selectors/blocksSelector';

interface Props {
  event: Event | undefined;
}

const EventImpactBadge: React.FC<Props> = ({ event }) => {
  const { blockId } = useBlockContext();
  const displayType = useAppSelector((state) => impactDisplayTypeForBlockSelector(state, blockId));
  if (event == null) {
    return null;
  }

  if (isDriverEvent(event)) {
    switch (displayType) {
      case ImpactDisplayType.BeforeAfter: {
        return <DriverBeforeAfterImpactBadge event={event} />;
      }
      case ImpactDisplayType.Total: {
        return <TotalDriverImpactBadge event={event} />;
      }
      default: {
        return null;
      }
    }
  }

  return <ObjectImpactBadge event={event} />;
};

export default React.memo(EventImpactBadge);
