import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'DriverIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 5.25C10.5858 5.25 10.25 5.58579 10.25 6C10.25 6.41421 10.5858 6.75 11 6.75H12.1893L10 8.93934L6.74246 5.6818C6.33241 5.27175 5.66759 5.27175 5.25754 5.6818L1.46967 9.46967C1.17678 9.76256 1.17678 10.2374 1.46967 10.5303C1.76256 10.8232 2.23744 10.8232 2.53033 10.5303L6 7.06066L9.25754 10.3182C9.66759 10.7282 10.3324 10.7282 10.7425 10.3182L13.25 7.81066V9C13.25 9.41421 13.5858 9.75 14 9.75C14.4142 9.75 14.75 9.41421 14.75 9V6.3C14.75 5.7201 14.2799 5.25 13.7 5.25H11Z"
      fill="currentColor"
    />
  ),
});
