import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'RoundedArrowIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        d="M4.25 9C4.25 9.41421 4.58579 9.75 5 9.75C5.41421 9.75 5.75 9.41421 5.75 9H4.25ZM12.25 9C12.25 9.41421 12.5858 9.75 13 9.75C13.4142 9.75 13.75 9.41421 13.75 9H12.25ZM5.75 9C5.75 7.20507 7.20507 5.75 9 5.75V4.25C6.37665 4.25 4.25 6.37665 4.25 9H5.75ZM9 5.75C10.7949 5.75 12.25 7.20507 12.25 9H13.75C13.75 6.37665 11.6234 4.25 9 4.25V5.75Z"
        fill="currentColor"
      />
      <path
        d="M3 8L4.5 10L7 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
