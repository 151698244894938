import { Flex, StyleProps, Text } from '@chakra-ui/react';
import React from 'react';

import ArrowToggleButton from 'components/ArrowToggleButton/ArrowToggleButton';
interface Props {
  name: string;
  numItemsCollapsed: number;
  isCollapsed: boolean;
  onToggle: () => void;
  bgColor?: StyleProps['bgColor'];
  textColor?: StyleProps['textColor'];
  onDoubleClick?: () => void;
}

const CollapsibleHeader: React.FC<Props> = ({
  name,
  isCollapsed,
  onToggle,
  numItemsCollapsed,
  bgColor,
  textColor,
  onDoubleClick,
}) => {
  return (
    <Flex bgColor={bgColor ?? 'gray.200'} borderRadius="md" onDoubleClick={onDoubleClick}>
      <ArrowToggleButton onToggle={onToggle} isActive={!isCollapsed} />
      <Flex pr={2}>
        <Text
          noOfLines={1}
          textColor={textColor}
          fontWeight="medium"
          whiteSpace="nowrap"
          fontSize="xs"
          lineHeight="1.5rem"
        >
          {name}
        </Text>
        {isCollapsed && (
          <Text
            noOfLines={1}
            fontWeight="medium"
            whiteSpace="nowrap"
            textColor="gray.500"
            fontSize="xxs"
            pl={1}
            lineHeight="1.6rem"
          >
            {numItemsCollapsed}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default React.memo(CollapsibleHeader);
