import { Flex } from '@chakra-ui/react';
import React from 'react';

import DriverAttributeBadge from 'components/DriverAttributeBadges/DriverAttributeBadge';
import useAppSelector from 'hooks/useAppSelector';
import { Attribute } from 'reduxStore/models/dimensions';
import { DriverId } from 'reduxStore/models/drivers';
import {
  attributesForSubDriverIdSelector,
  pseudoAttributeSelectorBySubDriverIdSelector,
} from 'selectors/driversSelector';

interface Props {
  driverId?: DriverId;
  attributes?: Attribute[];
  size?: 'sm' | 'xs' | 'md';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  flexShrink?: number;
  rowGap?: string;
  overflow?: string;
  fallbackTheme?: 'gray' | 'white';
}

const DriverAttributeBadges: React.FC<Props> = ({
  driverId,
  attributes,
  size,
  flexWrap,
  rowGap,
  flexShrink = 1,
  overflow = 'hidden',
  fallbackTheme,
}) => {
  const reduxAttributes = useAppSelector((state) =>
    driverId != null ? attributesForSubDriverIdSelector(state, driverId) : undefined,
  );
  const emptyAttributes = useAppSelector((state) =>
    driverId != null ? pseudoAttributeSelectorBySubDriverIdSelector(state, driverId) : undefined,
  );
  const resolvedAttributes = attributes ?? [...(reduxAttributes ?? []), ...(emptyAttributes ?? [])];

  return (
    <Flex
      columnGap={1}
      flexShrink={flexShrink}
      overflow={overflow}
      flexWrap={flexWrap}
      rowGap={rowGap}
    >
      {resolvedAttributes.map((attr) => (
        <DriverAttributeBadge
          key={attr.id}
          attribute={attr}
          size={size}
          fallbackTheme={fallbackTheme}
        />
      ))}
    </Flex>
  );
};

export default React.memo(DriverAttributeBadges);
