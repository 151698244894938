import { Flex } from '@chakra-ui/react';
import React from 'react';

const UnsupportedFormula: React.FC = () => {
  return (
    <Flex fontSize="sm" py={1} px={2} alignItems="center">
      Editing this formula in-app is not yet supported. Contact success@runway.com.
    </Flex>
  );
};

export default UnsupportedFormula;
