import React, { useContext, useMemo } from 'react';

import BusinessObjectFieldTimeSeriesCell from 'components/BusinessObjectFieldTimeSeriesCell/BusinessObjectFieldTimeSeriesCell';
import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import PlaceholderTimeSeriesCell from 'components/DriverTimeSeriesCell/PlaceholderTimeSeriesCell';
import EmptyObjectFieldTimeSeriesCell from 'components/EmptyObjectFieldTimeSeriesCell/EmptyObjectFieldTimeSeriesCell';
import TimeSeriesRowContainer from 'components/TimeSeriesRowContainer/TimeSeriesRowContainer';
import { BusinessObjectTimeSeriesCellRef, CellType } from 'config/cells';
import { ObjectFieldTimeSeriesRowContext } from 'config/objectFieldTimeSeriesRowContext';
import { getMonthColumnKey } from 'helpers/cells';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useObjectFieldValueTooltip } from 'hooks/useValueTooltip';
import { EventId } from 'reduxStore/models/events';
import { fieldIdForFieldSpecIdAndObjectIdSelector } from 'selectors/businessObjectsSelector';
import { entityLoadingByMonthKeySelector } from 'selectors/calculationsSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { blockMonthKeysSelector } from 'selectors/pageDateRangeSelector';
import { MonthKey } from 'types/datetime';

interface Props {
  eventIdsByMonthKey: NullableRecord<MonthKey, EventId>;
  isEmpty?: boolean;
}

const ObjectFieldInspectorTimeSeriesRow: React.FC<Props> = ({ eventIdsByMonthKey, isEmpty }) => {
  const { blockId } = useBlockContext();
  const monthKeys = useAppSelector((state) => blockMonthKeysSelector(state, blockId));
  const { layerId, fieldSpecId, businessObjectId } = useContext(ObjectFieldTimeSeriesRowContext);

  const fieldId = useAppSelector((state) =>
    fieldIdForFieldSpecIdAndObjectIdSelector(state, {
      businessObjectFieldSpecId: fieldSpecId,
      businessObjectId,
    }),
  );

  const isTimeSeriesLoadingByMonthKey = useAppSelector((state) =>
    entityLoadingByMonthKeySelector(state, { id: fieldId, monthKeys }),
  );

  return (
    <TimeSeriesRowContainer>
      {monthKeys.map((monthKey) => {
        if (isTimeSeriesLoadingByMonthKey[monthKey]) {
          return <PlaceholderTimeSeriesCell key={monthKey} columnKey={monthKey} color="actuals" />;
        } else if (isEmpty) {
          return (
            <EmptyObjectFieldTimeSeriesCell
              key={monthKey}
              monthKey={monthKey}
              layerId={layerId}
              subLabel={undefined}
            />
          );
        }

        return (
          <ObjectFieldInspectorTimeSeriesCell
            key={monthKey}
            monthKey={monthKey}
            eventIdToUpdate={eventIdsByMonthKey[monthKey]}
          />
        );
      })}
    </TimeSeriesRowContainer>
  );
};

interface ObjectFieldInspectorTimeSeriesCellProps {
  eventIdToUpdate?: EventId;
  monthKey: MonthKey;
}

const ObjectFieldInspectorTimeSeriesCell: React.FC<ObjectFieldInspectorTimeSeriesCellProps> = ({
  eventIdToUpdate,
  monthKey,
}) => {
  const { blockId } = useBlockContext();
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const { fieldSpecId, businessObjectId, layerId } = useContext(ObjectFieldTimeSeriesRowContext);
  const cellRef = useMemo<BusinessObjectTimeSeriesCellRef>(() => {
    return {
      type: CellType.ObjectFieldTimeSeries,
      rowKey: { fieldSpecId, objectId: businessObjectId, layerId },
      columnKey: getMonthColumnKey(monthKey),
    };
  }, [monthKey, fieldSpecId, businessObjectId, layerId]);

  const valueTooltip = useObjectFieldValueTooltip({
    objectId: businessObjectId,
    fieldSpecId,
    monthKey,
    blockId,
    layerId: layerId ?? currentLayerId,
  });

  return (
    <CellContextProvider cellRef={cellRef} valueTooltip={valueTooltip}>
      <BusinessObjectFieldTimeSeriesCell
        businessObjectId={businessObjectId}
        fieldSpecId={fieldSpecId}
        monthKey={monthKey}
        eventIdToUpdate={eventIdToUpdate}
        blockId={blockId}
      />
    </CellContextProvider>
  );
};

ObjectFieldInspectorTimeSeriesCell.displayName = 'ObjectFieldInspectorTimeSeriesCell';

export default React.memo(ObjectFieldInspectorTimeSeriesRow);
