import { FormulaEvaluator } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import { FormulaEntityTypedId } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import { Attribute } from 'reduxStore/models/dimensions';
import { Driver, DriverId, DriverType } from 'reduxStore/models/drivers';
import { DateRange } from 'types/dataset';

type CallStackEntry = {
  id: FormulaEntityTypedId;
  name: string;
  dateRange: DateRange;
  invoked: CallStackEntry[];
  elapsed?: number;
  cacheHit?: boolean;
};

const markKey = (id: string, dateRange: DateRange) =>
  `${id}-${dateRange.start}-${dateRange.end}-start`;
const measureKey = (id: string, dateRange: DateRange) =>
  `${id}-${dateRange.start}-${dateRange.end}`;

/**
 * Context to gather formula caclution execution context.
 */
export class FormulaCalculationContext {
  private driversById: NullableRecord<string, Driver>;
  private attributesBySubDriverId: Record<DriverId, Attribute[]>;
  private evaluator: FormulaEvaluator;
  private callStack: CallStackEntry[];

  public constructor({
    driversById,
    attributesBySubDriverId,
    evaluator,
  }: {
    driversById: NullableRecord<string, Driver>;
    attributesBySubDriverId: Record<DriverId, Attribute[]>;
    evaluator: FormulaEvaluator;
  }) {
    this.driversById = driversById;
    this.attributesBySubDriverId = attributesBySubDriverId;
    this.evaluator = evaluator;
    this.callStack = [];
  }

  public push(entry: { id: FormulaEntityTypedId; dateRange: DateRange }): void {
    const name = this.getEntityName(entry.id) ?? '';
    performance.mark(markKey(entry.id.id, entry.dateRange));
    this.callStack.push({
      ...entry,
      name,
      invoked: [],
    });
  }

  public pop({ cacheHit }: { cacheHit: boolean }): void {
    const item = this.callStack.pop();
    if (item == null) {
      return;
    }

    const measure = performance.measure(
      measureKey(item.id.id, item.dateRange),
      markKey(item.id.id, item.dateRange),
    );
    item.elapsed = measure.duration;
    item.cacheHit = cacheHit;

    const parent = this.callStack.length > 0 ? this.callStack[this.callStack.length - 1] : null;

    if (parent == null) {
      return;
    }

    parent.invoked.push(item);
  }

  private getEntityName(entityId: FormulaEntityTypedId): string | undefined {
    if (entityId.type === 'driver') {
      const driver = this.driversById[entityId.id];

      if (driver?.type === DriverType.Basic) {
        const attributes = this.attributesBySubDriverId[driver.id];
        if (attributes != null) {
          return attributes.reduce((out, attr) => `${out} (${attr.value})`, driver.name);
        }
        return driver.name;
      }

      return driver?.name;
    }

    const objectName = this.evaluator.getBusinessObjectByFieldId(entityId.id)?.name;
    const fieldName = this.evaluator.getBusinessObjectFieldSpecByFieldId(entityId.id)?.name;
    if (objectName == null || fieldName == null) {
      return undefined;
    }

    return `${objectName}.${fieldName}`;
  }
}
