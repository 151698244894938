import classnames from 'classnames';
import React from 'react';

import { TimeSeriesStyle } from 'components/AgGridComponents/CellRenderer/types';
import DisplayValue, {
  DisplayValueProps,
} from 'components/BusinessObjectsEditableValues/DisplayValue';
import displayValueStyles from 'components/BusinessObjectsEditableValues/DisplayValue.module.scss';
import CellPaletteWithPlanPicker from 'components/CellPalette/CellPaletteWithPlanPicker';

import styles from './TimeSeriesDisplayValue.module.scss';

type CellAlignment = 'left' | 'right' | 'absolute';

export type TimeSeriesDisplayValueProps = DisplayValueProps & {
  timeSeriesStyle: TimeSeriesStyle;
  cellAlignment: CellAlignment | undefined;
  left?: number;
  showCellPalette?: boolean;
};

const CELL_ALIGNMENT_TO_CLASS_NAME: Record<CellAlignment, string> = {
  left: styles['aligned-left'],
  right: styles['aligned-right'],
  absolute: styles.absolute,
};

const TimeSeriesDisplayValue: React.FC<TimeSeriesDisplayValueProps> = ({
  timeSeriesStyle,
  cellAlignment = 'left',
  left,
  showCellPalette = false,
  ...displayValueProps
}) => {
  const cellRef = React.useRef<HTMLDivElement>(null);

  const { value, error } = displayValueProps;
  if (value.value == null && error == null) {
    return null;
  }

  return (
    <>
      {showCellPalette ? (
        <CellPaletteWithPlanPicker
          tableCellRef={cellRef}
          omitColorPicker
          isEditingActiveCell={false}
        />
      ) : null}
      <div
        ref={cellRef}
        data-sentry-mask
        className={classnames(
          timeSeriesStyle != null ? styles[timeSeriesStyle] : undefined,
          cellAlignment != null ? CELL_ALIGNMENT_TO_CLASS_NAME[cellAlignment] : undefined,
          displayValueProps.style != null ? displayValueStyles[displayValueProps.style] : undefined,
        )}
        style={{ left: left != null ? `${left}px` : undefined }}
      >
        <DisplayValue {...displayValueProps} />
      </div>
    </>
  );
};

export default TimeSeriesDisplayValue;
