import keyBy from 'lodash/keyBy';
import { DateTime } from 'luxon';
import { createSelector } from 'reselect';

import { DEFAULT_CURRENCY } from 'config/currency';
import { GeneralSettings, NegativeDisplay, OrgRole } from 'generated/graphql';
import { OrgUser, UserId } from 'reduxStore/models/user';
import type { LoadedSelectedOrg, SelectedOrg } from 'reduxStore/reducers/selectedOrgSlice';
import { loggedInUserSelector } from 'selectors/loginSelector';
import { Selector } from 'types/redux';

export const selectedOrgSelector: Selector<SelectedOrg> = (state) => state.selectedOrg;

export const requireSelectedOrgSelector: Selector<NonNullable<SelectedOrg>> = (state) => {
  const org = selectedOrgSelector(state);

  if (org == null) {
    throw new Error('expected user to have org');
  }

  return org;
};

export const selectedOrgIdSelector: Selector<string> = createSelector(
  requireSelectedOrgSelector,
  (selectedOrg) => {
    return selectedOrg.id;
  },
);

export const isSelectedOrgLoadedSelector: Selector<boolean> = (state) =>
  state.selectedOrg != null && state.selectedOrg.loaded;

export const loadedSelectedOrgSelector: Selector<LoadedSelectedOrg | null> = (state) => {
  return state.selectedOrg == null || !state.selectedOrg.loaded ? null : state.selectedOrg;
};

export const isTemplateOrgSelectedSelector: Selector<boolean> = createSelector(
  loadedSelectedOrgSelector,
  (selectedOrg) => {
    return selectedOrg?.isTemplateOrg ?? false;
  },
);

const NO_USERS: OrgUser[] = [];
export const usersInSelectedOrgSelector: Selector<OrgUser[]> = createSelector(
  selectedOrgSelector,
  loggedInUserSelector,
  (selectedOrg, loggedInUser) => {
    return loggedInUser?.orgs?.find((org) => org.id === selectedOrg?.id)?.users ?? NO_USERS;
  },
);

export const usersInSelectedOrgByIdSelector: Selector<Record<UserId, OrgUser>> = createSelector(
  usersInSelectedOrgSelector,
  (usersInSelectedOrg) => {
    return keyBy(usersInSelectedOrg, 'id');
  },
);

export const userEmailsInSelectedOrgSelector: Selector<string[]> = createSelector(
  usersInSelectedOrgSelector,
  (usersInSelectedOrg) => {
    return usersInSelectedOrg.map((user) => user.email);
  },
);

export const orgRoleSelector: Selector<OrgRole | undefined> = createSelector(
  loadedSelectedOrgSelector,
  (org) => org?.myOrgRole,
);

export const orgSettingsSelector: Selector<GeneralSettings | null> = createSelector(
  loadedSelectedOrgSelector,
  (org) => org?.generalSettings ?? null,
);

export const orgDefaultCurrencyISOSelector: Selector<string> = createSelector(
  orgSettingsSelector,
  (settings) => settings?.defaultCurrencyISOCode ?? DEFAULT_CURRENCY,
);

export const orgDefaultDecimalPrecisionSelector: Selector<number | null> = createSelector(
  orgSettingsSelector,
  (settings) => settings?.defaultDecimalPrecision ?? null,
);

export const orgNegativeDisplaySelector: Selector<NegativeDisplay> = createSelector(
  orgSettingsSelector,
  (settings) => settings?.negativeDisplay ?? NegativeDisplay.NegativeSign,
);

export const orgImportStartTimeSelector: Selector<DateTime | undefined> = createSelector(
  loadedSelectedOrgSelector,
  (org) => org?.importStartTime,
);
