import React from 'react';

import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import SelectMenu, { SelectItem } from 'components/SelectMenu/SelectMenu';
import SelectMenuContainer from 'components/SelectMenuContainer/SelectMenuContainer';

interface Props {
  items: SelectItem[];
}

const AutofillDimensionalPropertySelectMenu: React.FC<Props> = ({ items }) => {
  return (
    <SelectMenuContainer>
      <SelectMenu items={items} onSelect={() => {}} width="18rem">
        {BaseSelectMenuItem}
      </SelectMenu>
    </SelectMenuContainer>
  );
};

export default AutofillDimensionalPropertySelectMenu;
