import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'PlusIcon',
  defaultProps: { boxSize: 4 },
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3a.75.75 0 0 0-.75.75v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5A.75.75 0 0 0 8 3Z"
      fill="currentColor"
    />
  ),
});
