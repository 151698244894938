import { useCallback, useMemo, useState } from 'react';

import { SizeInfo } from 'components/VirtualizedList/ListItemMeasurer';
import { GetItemSize, ListType } from 'components/VirtualizedList/types';

interface Props {
  listType: ListType;
  getItemSize?: GetItemSize;
  estimatedItemSize: number;
  numListItems: number;
  gapPx?: number;
}

export const useListItemSizeInternal = ({
  listType,
  getItemSize,
  estimatedItemSize,
  numListItems,
  gapPx,
}: Props) => {
  const [sizeCache, setSizeCache] = useState<Record<number, number>>({});

  const getItemSizeInternal = useCallback(
    (index: number) => {
      let size: number;
      if (sizeCache[index] != null) {
        size = sizeCache[index];
      } else if (getItemSize != null) {
        size = getItemSize(index);
        sizeCache[index] = size;
      } else {
        size = estimatedItemSize;
      }
      // last item does not need gap
      if (index === numListItems - 1) {
        return size;
      } else {
        return size + (gapPx ?? 0);
      }
    },
    [estimatedItemSize, getItemSize, numListItems, gapPx, sizeCache],
  );

  const setItemSizeForIndex = useCallback(
    ({ index, size }: { index: number; size: SizeInfo }) => {
      setSizeCache((prev) => {
        let newVal: number | undefined;
        if (listType === 'vertical') {
          newVal = size.height;
        } else {
          newVal = size.width;
        }
        const oldVal = prev[index];
        if (oldVal === newVal) {
          return prev;
        }
        return { ...prev, [index]: newVal };
      });
    },
    [listType],
  );

  const isItemSizeForIndexAvailable = useCallback(
    (index: number) => {
      //if getItemSize is defined, we can assume that the size is available all the times, otherwise we need to check the cache
      return getItemSize != null || sizeCache[index] != null;
    },
    [getItemSize, sizeCache],
  );

  const totalSize = useMemo(() => {
    let finalSize = 0;
    for (let i = 0; i < numListItems; i++) {
      const size = getItemSizeInternal(i);
      finalSize += size;
    }
    return finalSize;
  }, [getItemSizeInternal, numListItems]);

  const itemPositionsInList = useMemo((): Record<number, number> => {
    const positionsMap: Record<number, number> = {};
    let curPos = 0;
    for (let index = 0; index < numListItems; index++) {
      positionsMap[index] = curPos;
      curPos += getItemSizeInternal(index);
    }
    return positionsMap;
  }, [getItemSizeInternal, numListItems]);

  return useMemo(
    () => ({
      getItemSizeInternal,
      itemPositionsInList,
      setItemSizeForIndex,
      isItemSizeForIndexAvailable,
      totalSize,
    }),
    [
      getItemSizeInternal,
      isItemSizeForIndexAvailable,
      itemPositionsInList,
      setItemSizeForIndex,
      totalSize,
    ],
  );
};
