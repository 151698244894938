import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 10 10',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <path
      d="M1 6L5 9L9 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
