import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { useComparedLayers } from '@features/CompareScenarios';
import { ChangeCell } from '@features/CompareScenarios/blocks/common/ChangeCell';
import { LayerName } from '@features/CompareScenarios/common/LayerName';
import { SchemaChange } from '@features/CompareScenarios/comparators/DatabaseComparator';
import { Cube, Dimension, Formula } from 'vectors';

export const SchemaChangesBlock = ({ changes }: { changes: SchemaChange[] }) => {
  const { currentLayer, mergeLayer } = useComparedLayers();

  return (
    <Box marginBottom="4" overflowX="scroll">
      <Table width="fit-content">
        <Thead>
          <Tr>
            <Th
              rowSpan={2}
              borderRight="1px solid"
              borderBottom="1px solid"
              borderColor="gray.400"
              padding="0"
              width="200px"
            />
            {changes.map((change, i) => {
              return (
                <Th
                  key={`compare_scenarios_modal-header-${change.object.data.id}`}
                  colSpan={Object.keys(change.updatesByField).length}
                  borderBottom="1px solid"
                  borderColor="gray.400"
                  backgroundColor={i % 2 === 0 ? undefined : 'gray.50'}
                  _notLast={{
                    borderRight: '1px solid',
                    borderColor: 'gray.400',
                  }}
                  paddingX="3"
                  paddingY="2"
                >
                  <HeaderCell change={change} />
                </Th>
              );
            })}
          </Tr>
          <Tr>
            {changes.flatMap((change, i) => {
              const updateCount = Object.keys(change.updatesByField).length;

              return Object.values(change.updatesByField).map((column, j) => {
                return (
                  <Th
                    key={`compare_scenarios_modal-header-${change.object.data.id}-field-${column.field}`}
                    borderBottom="1px solid"
                    borderColor="gray.400"
                    backgroundColor={i % 2 === 0 ? undefined : 'gray.50'}
                    _notLast={{
                      borderRight: j !== updateCount - 1 ? '1px dashed' : '1px solid',
                      borderRightColor: j !== updateCount - 1 ? 'gray.300' : 'gray.400',
                    }}
                    paddingX="3"
                    paddingY="2"
                    fontSize="xs"
                    color="gray.500"
                    fontWeight="500"
                    whiteSpace="nowrap"
                    textTransform="none"
                    letterSpacing="none"
                  >
                    {column.field}
                  </Th>
                );
              });
            })}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td
              borderRight="1px solid"
              borderRightColor="gray.400"
              borderBottom="1px dashed"
              borderBottomColor="gray.300"
              paddingX="3"
              paddingY="2"
            >
              <LayerName layerType="merge" layer={mergeLayer} color="gray.600" />
            </Td>
            {changes.flatMap((change, i) => {
              const updateCount = Object.keys(change.updatesByField).length;

              return Object.values(change.updatesByField).map((column, j) => {
                return (
                  <Td
                    key={`compare_scenarios_modal-${change.object.data.id}-${mergeLayer.id}-${column.field}`}
                    borderBottom="1px dashed"
                    borderBottomColor="gray.300"
                    backgroundColor={i % 2 === 0 ? undefined : 'gray.50'}
                    _notLast={{
                      borderRight: j !== updateCount - 1 ? '1px dashed' : '1px solid',
                      borderRightColor: j !== updateCount - 1 ? 'gray.300' : 'gray.400',
                    }}
                    paddingX="3"
                    paddingY="2"
                    fontSize="xs"
                    fontWeight="500"
                    overflow="ellipsis"
                  >
                    <ChangeCell value={column.from} />
                  </Td>
                );
              });
            })}
          </Tr>
          <Tr>
            <Td
              borderRight="1px solid"
              borderColor="gray.400"
              borderBottom="none"
              paddingX="3"
              paddingY="2"
            >
              <LayerName layerType="current" layer={currentLayer} color="gray.600" />
            </Td>
            {changes.flatMap((change, i) => {
              const updateCount = Object.keys(change.updatesByField).length;
              return Object.values(change.updatesByField).map((column, j) => {
                return (
                  <Td
                    key={`compare_scenarios_modal-${change.object.data.id}-${currentLayer.id}-${column.field}`}
                    borderBottom="none"
                    backgroundColor={i % 2 === 0 ? undefined : 'gray.100'}
                    _notLast={{
                      borderRight: j !== updateCount - 1 ? '1px dashed' : '1px solid',
                      borderRightColor: j !== updateCount - 1 ? 'gray.300' : 'gray.400',
                    }}
                    paddingX="3"
                    paddingY="2"
                    fontSize="xs"
                    fontWeight="500"
                    overflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    <ChangeCell value={column.to} />
                  </Td>
                );
              });
            })}
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

const HeaderCell = ({ change }: { change: SchemaChange }) => {
  const text = change.object.name;
  let icon: ReactNode = null;
  if (change.object.type === 'dimensionalProperty') {
    if (change.object.data.isDatabaseKey) {
      icon = <Dimension color="gray.500" />;
    } else {
      icon = <Cube color="gray.500" />;
    }
  } else if (change.object.type === 'driverProperty' || change.object.type === 'field') {
    icon = <Formula color="gray.500" />;
  }

  return (
    <Flex gap="6px" alignItems="center">
      {icon}
      <Text
        fontSize="xs"
        color="gray.500"
        whiteSpace="nowrap"
        textTransform="none"
        letterSpacing={0}
        fontWeight="600"
      >
        {text}
      </Text>
    </Flex>
  );
};
