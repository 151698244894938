import { AreaStack, LinePath } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import React, { Fragment } from 'react';

import { DASH_ARRAY_BY_SIZE, STROKE_WIDTH_BY_SIZE } from 'config/driverChart';
import useChartContext from 'hooks/useChartContext';
import { useColorMapForDriverCharts } from 'hooks/useColorMapForDriverCharts';

const getDate = (d: Datum) => d.date;
const getY0 = (d: number[]) => d[0];
const getY1 = (d: number[]) => d[1];
const defined = (d: SeriesPoint<Datum>, key: string) => d.data[key] != null;

type Datum = {
  date: Date;
  [key: string]: number | Date;
};

type Props = {
  data: Datum[];
  type?: 'actuals' | 'layer' | 'forecast';
};

export const AreaChart: React.FC<Props> = ({ type = 'actuals', data }) => {
  const { driverIds, valueScale, timeScale, colorScale } = useChartContext();

  const { size } = useChartContext();
  const strokeWidth = STROKE_WIDTH_BY_SIZE[size];
  const strokeDasharray =
    type === 'forecast' || type === 'layer' ? DASH_ARRAY_BY_SIZE[size] : 'initial';

  // Charts are graphed based on the ordering of keys, so we need to reverse the order of the
  // keys to make it match sidebar ordering.
  const reversedKeys = [...driverIds].reverse();

  const colorMap = useColorMapForDriverCharts({
    keys: reversedKeys,
    shades: [300, 500],
    colorScale,
  });

  return (
    <AreaStack
      keys={reversedKeys}
      data={data}
      x={(d) => timeScale(getDate(d.data)) ?? 0}
      y0={(d) => valueScale(getY0(d)) ?? 0}
      y1={(d) => valueScale(getY1(d)) ?? 0}
    >
      {(props) => {
        const { stacks, path } = props;

        return (
          <>
            {stacks.map((stack) => {
              const fillColorToken = colorScale ? `${colorScale(stack.key)}.300` : 'blue.100';
              const fillColor = colorMap[fillColorToken];
              const drawnPath = path(stack);

              return (
                <Fragment key={`stack-${stack.key}`}>
                  <path
                    d={drawnPath != null ? drawnPath : ''}
                    fill={fillColor}
                    stroke={fillColor}
                    strokeWidth={1}
                    pointerEvents="none"
                  />
                </Fragment>
              );
            })}
            {stacks.map((stack) => {
              const lineColorToken = colorScale ? `${colorScale(stack.key)}.500` : 'blue.100';
              const lineColor = colorMap[lineColorToken];

              return (
                <Fragment key={`stack-${stack.key}`}>
                  <LinePath
                    data={stack}
                    x={(d) => timeScale(getDate(d.data)) ?? 0}
                    y={(d) => valueScale(getY1(d)) ?? 0}
                    defined={(d) => defined(d, stack.key)}
                    stroke={lineColor}
                    strokeDasharray={strokeDasharray}
                    strokeWidth={strokeWidth}
                    pointerEvents="none"
                  />
                </Fragment>
              );
            })}
          </>
        );
      }}
    </AreaStack>
  );
};
