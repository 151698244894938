import React, { useMemo } from 'react';

import { CellStyleContext, CellType, DriverCellRef, MonthColumnKey } from 'config/cells';
import { isDriverDataCellRef } from 'helpers/cells';
import { ColorLocation, formatColorForUsage } from 'helpers/color';
import useAppSelector from 'hooks/useAppSelector';
import { useCellRef } from 'hooks/useCellRefContext';
import useCellTextStyles from 'hooks/useCellTextStyles';
import { driverColorSelector } from 'selectors/driversSelector';

const DEFAULT_CELL_STYLE_CONTEXT = {
  defaultBgColor: 'surface',
  definedBgColor: undefined,
  richTextStyle: undefined,
};

interface Props {
  children: JSX.Element;
}

const CellStyleContextProvider: React.FC<Props> = ({ children }) => {
  const { cellRef } = useCellRef();
  switch (cellRef.type) {
    case CellType.Driver: {
      return (
        <DriverCellStyleContextProvider cellRef={cellRef}>
          {children}
        </DriverCellStyleContextProvider>
      );
    }
    case CellType.ObjectTrace: {
      return (
        <ObjectDependencyCellStyleContextProvider>
          {children}
        </ObjectDependencyCellStyleContextProvider>
      );
    }
    default: {
      return (
        <CellStyleContext.Provider value={DEFAULT_CELL_STYLE_CONTEXT}>
          {children}
        </CellStyleContext.Provider>
      );
    }
  }
};

const DriverCellStyleContextProvider: React.FC<Props & { cellRef: DriverCellRef }> = ({
  children,
  cellRef,
}) => {
  const { driverId } = cellRef.rowKey;
  const monthKey = (cellRef.columnKey as MonthColumnKey).monthKey ?? undefined;
  const coloring = useAppSelector((state) =>
    driverId != null ? driverColorSelector(state, { id: driverId }) : undefined,
  );

  let definedBgColor: string | undefined;
  if (coloring != null) {
    const coloredCells = coloring.cells ?? {};
    const cellColor = coloredCells[monthKey ?? ''];
    if (cellColor != null) {
      // A color set on a specific cell is always primary
      definedBgColor = formatColorForUsage(`${cellColor.value}`, ColorLocation.Primary);
    } else if (coloring.row != null && coloring.row !== '') {
      const colorLocation = isDriverDataCellRef(cellRef)
        ? ColorLocation.Secondary
        : ColorLocation.Primary;
      definedBgColor = formatColorForUsage(`${coloring.row}`, colorLocation);
    }
  }

  const richText = useCellTextStyles(driverId, monthKey);

  const ctx = useMemo(
    () => ({
      defaultBgColor: 'surface',
      definedBgColor,
      richTextStyle: richText,
    }),
    [definedBgColor, richText],
  );

  return <CellStyleContext.Provider value={ctx}>{children}</CellStyleContext.Provider>;
};

const ObjectDependencyCellStyleContextProvider: React.FC<Props> = ({ children }) => {
  const ctx = useMemo(
    () => ({
      defaultBgColor: 'gray.50',
      definedBgColor: undefined,
      richTextStyle: undefined,
    }),
    [],
  );

  return <CellStyleContext.Provider value={ctx}>{children}</CellStyleContext.Provider>;
};

export default React.memo(CellStyleContextProvider);
