import { useAnimation } from 'framer-motion';
import { useCallback } from 'react';

export function useShakeControls() {
  const controls = useAnimation();
  const shake = useCallback(() => {
    controls.start({
      x: [-4, 3, -2, 1, 0],
      transition: {
        delay: -(Math.random() * 0.7 + 0.05),
        ease: 'easeInOut',
        duration: 0.2,
      },
    });
  }, [controls]);
  return { controls, shake };
}
