import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { CurveType, ImpactType } from 'generated/graphql';
import { deepClone } from 'helpers/clone';
import { numMonthsBetweenDates } from 'helpers/dates';
import { getCustomCurvePoints, updateEvent } from 'helpers/events';
import { BlockId } from 'reduxStore/models/blocks';
import {
  DeltaImpact,
  DriverEvent,
  EventCommon,
  EventGroupId,
  // Eslint is complaining because there's an Event enum value below
  Event as EventModel,
  isDeltaImpactEvent,
  isDriverEvent,
  ObjectFieldEvent,
} from 'reduxStore/models/events';
import { ValueTimeSeries } from 'reduxStore/models/timeSeries';
import { toNumberValue } from 'reduxStore/models/value';
import {
  applyMutationLocally_INTERNAL,
  undoMutationLocally_INTERNAL,
} from 'reduxStore/reducers/datasetSlice';
import { closePopover, selectSingleCell, setSelectedCells } from 'reduxStore/reducers/pageSlice';
import { ISOTime, MonthKey } from 'types/datetime';

export enum LiveEditType {
  Event,
  EventGroup,
  MultiEvent,
  Forecast,
  BlockColumnResize,
}

type EventGroupLiveEdit = {
  type: LiveEditType.EventGroup;
  updates: EventGroupLiveUpdate[];
};

export type LiveEditImpact = {
  type: ImpactType;
  value: number;
};

export type EventLiveEdit = {
  type: LiveEditType.Event;
  event: EventModel;

  // TODO: Use a discriminated union to optionally include these
  originalCustomCurvePoints?: ValueTimeSeries;
  originalTotalImpact?: number;
  impactedMonthKey?: MonthKey;
  impact?: LiveEditImpact;

  isDragging: boolean;
  warning: string | null;
};

type MultiEventLiveEdit = {
  type: LiveEditType.MultiEvent;
  events: EventModel[];
  warning: string | null;
};

type ForecastLiveEdit = {
  type: LiveEditType.Forecast;
  value: number | undefined;
};

export type BlockColumnResizeLiveEdit = {
  type: LiveEditType.BlockColumnResize;
  blockId: BlockId;
  columnKey: string;
  width: number;
};

export type EventLiveUpdate = Partial<EventCommon> &
  Partial<DeltaImpact> &
  Partial<Omit<DriverEvent, 'customCurvePoints'>> &
  Partial<ObjectFieldEvent>;

export type EventGroupLiveUpdate = {
  id: EventGroupId;
  defaultStart: ISOTime;
  defaultEnd: ISOTime;
};

export type LiveEdit =
  | EventLiveEdit
  | EventGroupLiveEdit
  | MultiEventLiveEdit
  | ForecastLiveEdit
  | BlockColumnResizeLiveEdit
  | null;

const initialState = null as LiveEdit;

const liveEditingSlice = createSlice({
  name: 'liveEdit',
  initialState,
  reducers: {
    // Can only update the default start/end times right now
    liveEditEventGroups(_state, action: PayloadAction<EventGroupLiveUpdate[]>) {
      return {
        updates: action.payload,
        type: LiveEditType.EventGroup,
      };
    },
    liveResizeBlockColumn(state, action: PayloadAction<Omit<BlockColumnResizeLiveEdit, 'type'>>) {
      return {
        type: LiveEditType.BlockColumnResize,
        ...action.payload,
      };
    },
    startLiveEditingExistingEvent(
      _state,
      action: PayloadAction<{
        originalEvent: EventModel;
        updates: EventLiveUpdate;
        makeCustom?: boolean;
        isDragging: boolean;
      }>,
    ) {
      const { originalEvent, makeCustom, updates, isDragging } = action.payload;

      const eventCopy = deepClone<EventModel>(originalEvent);
      const start = updates.start ?? eventCopy.start;
      const end = updates.end ?? eventCopy.end;
      const startDate = DateTime.fromISO(start);
      const endDate = DateTime.fromISO(end);

      if (
        isDriverEvent(originalEvent) &&
        isDeltaImpactEvent(eventCopy) &&
        makeCustom &&
        eventCopy.curveType !== CurveType.Custom &&
        numMonthsBetweenDates(startDate, endDate) > 1
      ) {
        updates.curveType = CurveType.Custom;
        if (updates.customCurvePoints == null) {
          updates.customCurvePoints = getCustomCurvePoints(originalEvent);
        }
      }

      updateEvent(eventCopy, updates);

      return {
        event: eventCopy,
        type: LiveEditType.Event,
        isNew: false,
        isDragging,
        ...(isDeltaImpactEvent(originalEvent)
          ? {
              originalCustomCurvePoints: originalEvent.customCurvePoints,
              originalTotalImpact: originalEvent.totalImpact,
            }
          : {}),
        warning: null,
      };
    },
    startLiveEditingMultipleEvents(
      _state,
      action: PayloadAction<{
        updates: Array<{ originalEvent: EventModel; update: EventLiveUpdate }>;
      }>,
    ) {
      const { updates } = action.payload;
      const updatedEvents = updates.map(({ originalEvent, update }) => {
        const eventCopy = { ...originalEvent };
        updateEvent(eventCopy, update);
        return eventCopy;
      });
      return {
        type: LiveEditType.MultiEvent,
        events: updatedEvents,
        warning: null,
      };
    },
    eventLiveEditPoint(
      state,
      action: PayloadAction<{
        monthKey: MonthKey;
        originalImpact: number;
        newImpact: number;
      }>,
    ) {
      if (!state || state.type !== LiveEditType.Event) {
        return;
      }

      const liveEditingEvent = state.event;
      const { monthKey, newImpact, originalImpact } = action.payload;
      const isDeltaEvent = isDeltaImpactEvent(liveEditingEvent);

      const eventUpdate: EventLiveUpdate = {};
      if (isDeltaEvent && liveEditingEvent.curveType !== CurveType.Custom) {
        eventUpdate.totalImpact = newImpact;
      } else {
        // All curve types are custom now, so this is the primary code path
        const customCurvePoints = liveEditingEvent.customCurvePoints ?? {};

        if (newImpact != null) {
          eventUpdate.customCurvePoints = {
            ...customCurvePoints,
            [monthKey]: toNumberValue(newImpact),
          };
        } else {
          const newTimeSeries = { ...customCurvePoints };
          delete newTimeSeries[monthKey];
          eventUpdate.customCurvePoints = newTimeSeries;
        }
      }

      updateEvent(state.event, eventUpdate);
      state.impactedMonthKey = monthKey;
      state.impact = isDeltaEvent
        ? {
            type: ImpactType.Delta,
            value: newImpact - originalImpact,
          }
        : {
            type: ImpactType.Set,
            value: newImpact,
          };
    },
    setEventLiveEditWarning(
      state,
      action: PayloadAction<{
        warning: string;
      }>,
    ) {
      if (
        state == null ||
        (state.type !== LiveEditType.MultiEvent && state.type !== LiveEditType.Event)
      ) {
        return;
      }

      const { warning } = action.payload;

      state.warning = warning;
    },
    endLiveEditing() {
      return null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(undoMutationLocally_INTERNAL, () => {
      return null;
    });

    builder.addCase(selectSingleCell, () => {
      return null;
    });

    builder.addCase(setSelectedCells, () => {
      return null;
    });

    builder.addCase(closePopover, () => {
      return null;
    });

    builder.addCase(applyMutationLocally_INTERNAL, (state, action) => {
      // N.B. we don't want to clear the liveEdit when someone else does something.
      if (action.payload.isRemote || state == null) {
        return state;
      }

      let id: string | undefined;
      if (state.type === LiveEditType.Event) {
        id = state.event.id;
      } else if (state.type === LiveEditType.EventGroup && state.updates.length === 1) {
        id = state.updates[0].id;
      } else if (state.type === LiveEditType.MultiEvent && state.events.length > 0) {
        id = state.events[0].id;
      }
      if (id != null) {
        // The mutation will contain the live edit id if the mutation is related to the live edit
        // Used the string so that we do not need to look into each of the distinct types of mutations
        // e.g newEvents will have the new id if the live edit created a new event
        if (JSON.stringify(action.payload.mutationBatch).includes(id)) {
          return null;
        }
        return state;
      }

      return null;
    });
  },
});

export const {
  endLiveEditing,
  eventLiveEditPoint,
  liveEditEventGroups,
  liveResizeBlockColumn,
  startLiveEditingExistingEvent,
  startLiveEditingMultipleEvents,
  setEventLiveEditWarning,
} = liveEditingSlice.actions;

export default liveEditingSlice.reducer;
