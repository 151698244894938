import { Box, Flex, StyleProps, useMergeRefs } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import MenuHeader from 'components/MenuHeader/MenuHeader';
import SearchInput from 'components/SearchInput/SearchInput';
import BaseSelectMenuItem from 'components/SelectMenu/BaseSelectMenuItem';
import CustomCreateOption from 'components/SelectMenu/CustomCreateOption';
import SelectMenu, { Section, SelectItem } from 'components/SelectMenu/SelectMenu';
import { DatabaseTableContext } from 'config/databaseTableContext';
import { ValueType } from 'generated/graphql';
import { extractEmoji } from 'helpers/emoji';
import { checkNameIsUniqueCaseInsensitive } from 'helpers/naming';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import {
  createNewBusinessObjectSpecDimensionalProperty,
  createNewBusinessObjectSpecField,
} from 'reduxStore/actions/businessObjectSpecMutations';
import { DimensionId } from 'reduxStore/models/dimensions';
import { validDimensionsSelector } from 'selectors/dimensionsSelector';

const DIMENSIONS_SECTIONS: Section[] = [
  {
    id: 'dimensions',
    showMore: true,
  },
];

const SEARCH_KEYS = ['name'];
interface DimensionSubMenuProps {
  onBack?: () => void;
  onClose?: () => void;
  groupKey?: string;
  isLegacyField?: boolean;
  width?: StyleProps['width'];
  isDatabaseKey?: boolean;
}

const DimensionSubMenu = React.forwardRef<HTMLInputElement, DimensionSubMenuProps>(
  (
    { onClose, onBack, groupKey, isLegacyField, width, isDatabaseKey }: DimensionSubMenuProps,
    ref,
  ) => {
    const dimensions = useAppSelector(validDimensionsSelector);
    const { blockId } = useBlockContext();
    const { objectSpecId } = useContext(DatabaseTableContext);

    const dispatch = useAppDispatch();

    const [query, setQuery] = useState<string>('');
    const internalRef = useRef<HTMLInputElement>(null);
    const inputRef = useMergeRefs(internalRef, ref);

    const allDimNames = useMemo(() => dimensions.map((d) => d.name), [dimensions]);

    const onCreateDimension = useCallback(
      (newDimensionName: string) => {
        if (newDimensionName.length > 0) {
          if (!isLegacyField) {
            dispatch(
              createNewBusinessObjectSpecDimensionalProperty({
                objectSpecId,
                blockId,
                groupKey,
                newDimensionName,
                isDatabaseKey,
              }),
            );
          } else {
            dispatch(
              createNewBusinessObjectSpecField({
                objectSpecId,
                blockId,
                type: ValueType.Attribute,
                groupKey,
                newDimensionName,
              }),
            );
          }
          onClose?.();
        }
      },
      [isLegacyField, onClose, dispatch, objectSpecId, blockId, groupKey, isDatabaseKey],
    );

    const items: SelectItem[] = useMemo(() => {
      return dimensions.map((d) => {
        const [emoji, label] = extractEmoji(d.name);
        return {
          id: d.id,
          name: label,
          ...(emoji != null
            ? {
                icon: <Box mr={2}>{emoji}</Box>,
              }
            : {}),
          sectionId: 'dimensions' as const,
        };
      });
    }, [dimensions]);

    const onSelectDimension = useCallback(
      (id: DimensionId) => {
        if (!isLegacyField) {
          dispatch(
            createNewBusinessObjectSpecDimensionalProperty({
              objectSpecId,
              blockId,
              groupKey,
              dimensionId: id,
              isDatabaseKey,
            }),
          );
        } else {
          dispatch(
            createNewBusinessObjectSpecField({
              objectSpecId,
              blockId,
              type: ValueType.Attribute,
              groupKey,
              dimensionId: id,
            }),
          );
        }

        onClose?.();
      },
      [isLegacyField, onClose, dispatch, objectSpecId, blockId, groupKey, isDatabaseKey],
    );

    const customOptions = useMemo(
      () => [
        {
          id: 'create',
          position: 'first',
          onSelect: (newDimensionName: string) => {
            if (newDimensionName.trim().length > 0) {
              onCreateDimension(newDimensionName);
            }
          },
          errorMessage: (q: string) =>
            checkNameIsUniqueCaseInsensitive(q, allDimNames)
              ? q.trim().length > 0
                ? null
                : ''
              : 'Dimension name must be unique',
          render: (q: string) => <CustomCreateOption label="Create dimension" helperText={q} />,
        },
      ],
      [onCreateDimension, allDimNames],
    );
    const onSelect = useCallback(
      ({ id }: SelectItem) => {
        onSelectDimension(id);
      },
      [onSelectDimension],
    );

    useEffect(() => {
      internalRef.current?.focus();
    }, []);

    return (
      <Box py={1} px={0}>
        <Box px={1}>
          <MenuHeader title="Select a dimension" onClose={onClose} onBack={onBack} />
        </Box>
        <Flex pt={2} rowGap={1} direction="column" width={width ?? '22rem'}>
          <Flex columnGap={1} px={2} py={1}>
            <SearchInput
              placeholder="Select or create a new dimension"
              query={query}
              setQuery={setQuery}
              ref={inputRef}
            />
          </Flex>
          <SelectMenu
            items={items}
            sections={DIMENSIONS_SECTIONS}
            onSelect={onSelect}
            width="unset"
            query={query}
            searchKeys={SEARCH_KEYS}
            customOptions={customOptions}
          >
            {BaseSelectMenuItem}
          </SelectMenu>
        </Flex>
      </Box>
    );
  },
);

export default DimensionSubMenu;
